/**
 * INSPINIA - Responsive Admin Theme
 * 2.3
 *
 * Custom scripts
 */

$(document).ready(function () {


    // Full height
    function fix_height() {
        var heightWithoutNavbar = $("body > #wrapper").height() - 61;
        $(".sidebard-panel").css("min-height", heightWithoutNavbar + "px");

        var navbarHeigh = $('nav.navbar-default').height();
        var wrapperHeigh = $('#page-wrapper').height();

        if(navbarHeigh > wrapperHeigh){
            $('#page-wrapper').css("min-height", navbarHeigh + "px");
        }

        if(navbarHeigh < wrapperHeigh){
            $('#page-wrapper').css("min-height", $(window).height()  + "px");
        }

        if ($('body').hasClass('fixed-nav')) {
            $('#page-wrapper').css("min-height", $(window).height() - 60 + "px");
        }
    }

    $(window).bind("load resize scroll", function() {
        if(!$("body").hasClass('body-small')) {
            fix_height();
        }
    });

    setTimeout(function(){
        fix_height();
    });
});

// Minimalize menu when screen is less than 768px
$(function() {
    $(window).bind("load resize", function() {
        if ($(this).width() < 769) {
            $('body').addClass('body-small')
        } else {
            $('body').removeClass('body-small')
        }
    })
});

(function (angular) {
    "use strict";

    angular.module('vendor', [
        'ui.router', // Routing
        'ui.bootstrap', // Bootstrap
        'ngStorage',
        'ngTable',
        'ngResource',
        'ui.gravatar',
        'angular.filter',
        'ngAnimate',
        'toaster',
        'daterangepicker',
        'ngSanitize',
        'ui.select',
        'ngDropzone',
        'ui.event',
        'ui.map',
        'summernote',
        'ui.sortable',
        'focus-if',
        'bootstrapLightbox',
        'ui.grid',
        'ui.grid.pagination',
        'ui.grid.selection',
        'ui.grid.resizeColumns',
        'ui.grid.draggable-rows',
        'ui.grid.autoResize',
        'ui.grid.exporter',
        'ngTouch',
        'xdLocalStorage',
        'ui.tab.scroll',
        'angular-clipboard',
        'ngCookies',
        'chart.js',
        'tandibar/ng-rollbar'
    ]);

    angular.module('qsHub.shared', []).constant('errors', {
        401 : 'Unauthorized',
        403 : 'Forbidden'
    });
    angular.module('qsHub.qa', []);
    angular.module('qsHub.stars', []);
    angular.module('qsHub.igauge', []);
    angular.module('qsHub.messages', []);
    angular.module('qsHub.rankings', []);
    angular.module('qsHub.profiles', []);
    angular.module('qsHub.members-db', []);
    angular.module('qsHub', [
        'qsHubTemplates',
        'vendor',
        'qsHub.shared',
        'qsHub.rankings',
        'qsHub.messages',
        'qsHub.profiles',
        'qsHub.stars',
        'qsHub.igauge',
        'qsHub.qa',
        'qsHub.members-db'
    ]);
}(window.angular));

(function (angular) {
    'use strict';

    var env = 'local', shared = {
        datagridInputDelay: 1000,
        datagridSelectDelay: 1000,
        globalAdminRole: 'global_admin.global_admin',
        gridNameSpaces: {
            qsUserGridFilters: 'qsUserGridFilters',
            schoolUserGridFilters: 'schoolUserGridFilters',
            institutionsGridFilters: 'institutionsGridFilters',
            tmDirectoryGridFilters: 'tmDirectoryGridFilters',
            departmentsGridFilters: 'departmentsGridFilters',
            departmentsOvGridFilters: 'departmentsOvGridFilters',
            programsGridFilters: 'programsGridFilters',
            programsOvGridFilters: 'programsOvGridFilters',
            programsTmGridFilters: 'programsTmGridFilters',
            contactsGridFilters: 'contactsGridFilters',
            rankDashPerGridFilters: 'rankDashPerGridFilters',
            rankDashFinGridFilters: 'rankDashFinGridFilters',
            rankDashNumGridFilters: 'rankDashNumGridFilters',
            matchingToolGridFilters: 'matchingToolGridFilters',
            qsUserGridVisibility: 'qsUserGridVisibility',
            schoolUserGridVisibility: 'schoolUserGridVisibility',
            institutionsGridVisibility: 'institutionsGridVisibility',
            departmentsGridVisibility: 'departmentsGridVisibility',
            departmentsOvGridVisibility: 'departmentsOvGridVisibility',
            programsGridVisibility: 'programsGridVisibility',
            programsOvGridVisibility: 'programsOvGridVisibility',
            programsTmGridVisibility: 'programsTmGridVisibility',
            tmDirectoryGridVisibility: 'tmDirectoryGridVisibility',
            contactsGridVisibility: 'contactsGridVisibility',
            rankingFilter: 'rankingFilter',
            rankDashPerGridVisibility: 'rankDashPerGridVisibility',
            rankDashFinGridVisibility: 'rankDashFinGridVisibility',
            rankDashNumGridVisibility: 'rankDashNumGridVisibility',
            editDefinitions: 'editDefinitions',
            qsUserMessagesCenterFilters: 'qsUserMessagesCenterFilters',
            qsUserMessagesCenterVisibility: 'qsUserMessagesCenterVisibility',
            matchingToolGridVisibility : 'matchingToolGridVisibility'
        },
        coreFlag: {
            value: 'session?url='
        },
        drupal: {
            tu: {
                url: 'http://qs-hub-topu.pantheonsite.io'
            },
            tuChina: {
                url: 'https://dev.qschina.cn'
            },
            tm: {
                url: 'http://test-qs-platform.pantheonsite.io',
            }
        },
        customRole: 'custom',
        events: {
            institutionTuProfileChanges: 'institutionTuProfileChanges',
            institutionSharedProfileChanges: 'institutionSharedProfileChanges',
            loadMoreHistoryLogs: 'loadMoreHistoryLogs',
            logout: 'logout',
            closingInstitutionsUsers: 'closingInstitutionsUsers',
            institutionsUserPrimaryInstitutionChanges: 'institutionsUserPrimaryInstitutionChanges'
        },
        publicPages: [
            '/',
            '/logout',
            '/forgot-password'
        ],
        profileTypes: {
            ug: 'ug',
            pg: 'pg',
            master: 'master',
            ugChina: 'ugChina',
            pgChina: 'pgChina',
            masterChina: 'masterChina'
        },
        starsTypes: {
            stars: 'stars',
            starsChina: 'starsChina'
        },
        programTypes: {
            ug: 'ug',
            pg: 'pg',
            all: 'all'
        },
        typeId: {
            topLevelInstitutionId: 2,
            clientDepartmentId: 3,
            simpleDepartmentId: 5,
            advancedProgramId: 7,
            internalId: 1
        },
        subscriptions: {
            logsPerPage: 5,
            limit: 5
        },
        datagrid: {
            contacts: {
                defaultRowsNumber: 25
            },
            qsUsersMessages: {
                defaultRowsNumber: 250
            },
            rankings: {
                defaultRowsNumber: 25
            },
            rankingsPreview: {
                defaultRowsNumber: 5
            }
        },
        defaultClientPage: '/profiles/institution-details',
        newDefaultClientPage: '/statistics/data-submission',
        noTuSubtypes: false,
        support: {
            tu: 'tusupport@qs.com',
            tm: 'tmsupport@qs.com'
        },
        xStorage: {
            activeSource: 'hub',
            allowedSources: [
                'core',
                'hub2'
            ],
            keys: {
                source: 'x-source',
                data: 'x-currentUser',
                logout: 'x-logout',
                sendToSession: 'x-sendToSession'
            }
        },
        publishStatus: {
            pending: 'pending',
            progress: 'progress',
            success: 'success',
            failure: 'failure'
        },
        profileOverview: {
            tu:{
                basic: {
                    maxWords: 700
                },
                advanced: {
                    maxWords: 1000
                }
            },
            tm:{
                basic: {
                    maxWords: 700
                },
                advanced: {
                    maxWords: 1000
                },
                faq: {
                    maxWords: 200
                }
            }
        }
    }, constants = {
        local: {
            api: {
                usersPermissions: {
                    url: '//users.qs-hub.local:8087'
                },
                institutions: {
                    url: '//institutions.qs-hub.local:8086'
                },
                rankings: {
                    url: '//rankings.qs-hub.local:8085'
                },
                messages: {
                    url: '//messages.qs-hub.local:8084'
                },
                stars: {
                    url: '//stars.qs-hub.local:8083'
                },
                igauge: {
                    url: '//igauge.qs-hub.local:8082'
                }
            },
            dev: false,
            emails: {
                upgradeTu: 'core.it@qs.com',
                upgradeTm: 'core.it@qs.com'
            },
            webSockets: {
                host: '//websocket',
                port: 3000
            }
        },
        dev: {
            drupal: {
                tu: {
                    url: 'https://dev.topuniversities.com'
                },
                tuChina: {
                    url: 'https://dev.qschina.cn'
                },
                tm: {
                    url: 'https://dev.topmba.com'
                }
            },
            api: {
                usersPermissions: {
                    url: '//users-qs-hub-dev.qs.com'
                },
                institutions: {
                    url: '//institutions-qs-hub-dev.qs.com'
                },
                rankings: {
                    url: '//rankings-qs-hub-dev.qs.com'
                },
                messages: {
                    url: '//messages-qs-hub-dev.qs.com'
                },
                stars: {
                    url: '//stars-qs-hub-dev.qs.com'
                },
                igauge: {
                    url: '//igauge-qs-hub-dev.qs.com'
                }
            },
            dev: false,
            emails: {
                upgradeTu: 'tusupport@qs.com',
                upgradeTm: 'tmsupport@qs.com'
            },
            webSockets: {
                host: '//websocket-dev.qs.com',
                port: null
            }
        },
        dev2: {
            drupal: {
                tu: {
                    url: 'https://dev.topuniversities.com'
                },
                tuChina: {
                    url: 'https://www.qschina.cn'
                },
                tm: {
                    url: 'https://dev.topmba.com'
                }
            },
            api: {
                usersPermissions: {
                    url: '//users-qs-hub-dev2.qs.com'
                },
                institutions: {
                    url: '//institutions-qs-hub-dev2.qs.com'
                },
                rankings: {
                    url: '//rankings-qs-hub-dev2.qs.com'
                },
                messages: {
                    url: '//messages-qs-hub-dev2.qs.com'
                },
                stars: {
                    url: '//stars-qs-hub-dev2.qs.com'
                },
                igauge: {
                    url: '//igauge-qs-hub-dev2.qs.com'
                }
            },
            dev: false,
            emails: {
                upgradeTu: 'tusupport@qs.com',
                upgradeTm: 'tmsupport@qs.com'
            },
            webSockets: {
                host: '//websocket-dev2.qs.com',
                port: null
            }
        },
        qa_test: {
            drupal: {
                tu: {
                    url: 'https://dev.topuniversities.com'
                },
                tuChina: {
                    url: 'https://dev.qschina.cn'
                },
                tm: {
                    url: 'https://dev.topmba.com'
                }
            },
            api: {
                usersPermissions: {
                    url: '//users-qs-hub-qa-test.qs.com'
                },
                institutions: {
                    url: '//institutions-qs-hub-qa-test.qs.com'
                },
                rankings: {
                    url: '//rankings-qs-hub-qa-test.qs.com'
                },
                messages: {
                    url: '//messages-qs-hub-qa-test.qs.com'
                },
                stars: {
                    url: '//stars-qs-hub-qa-test.qs.com'
                },
                igauge: {
                    url: '//igauge-qs-hub-qa-test.qs.com'
                }
            },
            dev: false,
            emails: {
                upgradeTu: 'tusupport@qs.com',
                upgradeTm: 'tmsupport@qs.com'
            },
            webSockets: {
                host: '//websocket-qa-test.qs.com',
                port: null
            }
        },
        qa_prod: {
            drupal: {
                tu: {
                    url: 'https://dev.topuniversities.com'
                },
                tuChina: {
                    url: 'https://dev.qschina.cn'
                },
                tm: {
                    url: 'https://dev.topmba.com'
                }
            },
            api: {
                usersPermissions: {
                    url: '//users-qs-hub-qa-prod.qs.com'
                },
                institutions: {
                    url: '//institutions-qs-hub-qa-prod.qs.com'
                },
                rankings: {
                    url: '//rankings-qs-hub-qa-prod.qs.com'
                },
                messages: {
                    url: '//messages-qs-hub-qa-prod.qs.com'
                },
                stars: {
                    url: '//stars-qs-hub-qa-prod.qs.com'
                },
                igauge: {
                    url: '//igauge-qs-hub-qa-prod.qs.com'
                }
            },
            dev: false,
            emails: {
                upgradeTu: 'tusupport@qs.com',
                upgradeTm: 'tmsupport@qs.com'
            },
            webSockets: {
                host: '//websocket-qa-prod.qs.com',
                port: null
            }
        },
        staging: {
            drupal: {
                tu: {
                    url: 'https://dev.topuniversities.com'
                },
                tuChina: {
                    url: 'https://dev.qschina.cn'
                },
                tm: {
                    url: 'https://dev.topmba.com'
                }
            },
            api: {
                usersPermissions: {
                    url: '//users-qs-hub-staging.qs.com'
                },
                institutions: {
                    url: '//institutions-qs-hub-staging.qs.com'
                },
                rankings: {
                    url: '//rankings-qs-hub-staging.qs.com'
                },
                messages: {
                    url: '//messages-qs-hub-staging.qs.com'
                },
                stars: {
                    url: '//stars-qs-hub-staging.qs.com'
                },
                igauge: {
                    url: '//igauge-qs-hub-staging.qs.com'
                }
            },
            dev: false,
            emails: {
                upgradeTu: 'tusupport@qs.com',
                upgradeTm: 'tmsupport@qs.com'
            },
            webSockets: {
                host: '//websocket-staging.qs.com',
                port: null
            }
        },
        automation: {
            drupal: {
                tu: {
                    url: 'https://dev.topuniversities.com'
                },
                tuChina: {
                    url: 'https://dev.qschina.cn'
                },
                tm: {
                    url: 'https://dev.topmba.com'
                }
            },
            api: {
                usersPermissions: {
                    url: '//users-qs-hub-automation.qs.com'
                },
                institutions: {
                    url: '//institutions-qs-hub-automation.qs.com'
                },
                rankings: {
                    url: '//rankings-qs-hub-automation.qs.com'
                },
                messages: {
                    url: '//messages-qs-hub-automation.qs.com'
                },
                stars: {
                    url: '//stars-qs-hub-automation.qs.com'
                },
                igauge: {
                    url: '//igauge-qs-hub-automation.qs.com'
                }
            },
            dev: false,
            emails: {
                upgradeTu: 'tusupport@qs.com',
                upgradeTm: 'tmsupport@qs.com'
            },
            webSockets: {
                host: '//websocket-automation.qs.com',
                port: null
            }
        },
        live: {
            drupal: {
                tu: {
                    url: '//www.topuniversities.com'
                },
                tuChina: {
                    url: 'https://www.qschina.cn'
                },
                tm: {
                    url: '//www.topmba.com'
                }
            },
            api: {
                usersPermissions: {
                    url: '//users-qs-hub.qs.com'
                },
                institutions: {
                    url: '//institutions-qs-hub.qs.com'
                },
                rankings: {
                    url: '//rankings-qs-hub.qs.com'
                },
                messages: {
                    url: '//messages-qs-hub.qs.com'
                },
                stars: {
                    url: '//stars-qs-hub.qs.com'
                },
                igauge: {
                    url: '//igauge-qs-hub.qs.com'
                }
            },
            dev: false,
            emails: {
                upgradeTu: 'tusupport@qs.com',
                upgradeTm: 'tmsupport@qs.com'
            },
            webSockets: {
                host: 'https://websocket-qs-hub.qs.com',
                port: null
            }
        }
    };

    if (window && window.location && window.location.hostname) {
        switch (window.location.hostname) {
            case 'qs-hub.dev.qs.com':
            case 'qs-hub-dev.qs.com':
                env = 'dev';
                break;
            case 'qs-hub.dev2.qs.com':
            case 'qs-hub-dev2.qs.com':
                env = 'dev2';
                break;
            case 'qs-hub.qa-test.qs.com':
            case 'qs-hub-qa-test.qs.com':
                env = 'qa_test';
                break;
            case 'qs-hub.qa-prod.qs.com':
            case 'qs-hub-qa-prod.qs.com':
                env = 'qa_prod';
                break;
            case 'qs-hub.automation.qs.com':
            case 'qs-hub-automation.qs.com':
                env = 'automation';
                break;
            case 'qs-hub.staging.qs.com':
            case 'qs-hub-staging.qs.com':
                env = 'staging';
                break;
            case 'qs-hub.qs.com':
                env = 'live';
                break;
        }
    }
    shared.env = env;

    angular
        .module('qsHub')
        .constant('constants', angular.extend(shared, constants[env]));

}(window.angular));

(function (angular, Pace) {
    'use strict';

    var App = App || {
            cancelMessage: 'cancel',
            organisationId: 0,
            services: {}
        };

    /**
     *
     * @param $q
     * @param $location
     * @param $localStorage
     * @returns {App.services}
     */
    App.services.authInterceptor = function ($q, $location, $localStorage) {
        var service = this,
            url = '';
        service.lastError = null;

        service.request = function (config) {
            if (!config.timeout) {
                config.cancel = $q.defer();
                config.timeout = config.cancel.promise;
            }

            return config;
        };

        service.resetUrl = function () {
            url = '';
            service.lastError = null;
        };

        service.hasErrors = function () {
            return !!service.lastError;
        };

        service.getOrganisationId = function () {
            return App.organisationId;
        };

        service.setOrganisationId = function (organisationId) {
            organisationId = parseInt(organisationId, 10);
            if (organisationId && !angular.equals(App.organisationId, organisationId)) {
                App.organisationId = organisationId;
            }
        };

        service.responseError = function (rejection) {
            // if its 401 error and not a cancel request
            if (
                rejection.config &&
                rejection.config.timeout.$$state.value !== App.cancelMessage &&
                rejection.status === 401 &&
                (!service.lastError || service.lastError.status !== 401)
            ) {
                // remove user from local storage
                delete $localStorage.currentUser;
                var redirectUrl = decodeURIComponent($location.url()).replace('/?url=', '');

                App.services.authInterceptor().setOrganisationId($location.search().coreId);
                // if url is not yet set
                if (!url.length) {
                    url = redirectUrl;
                }
                $location.path('/').search({url: url});
                $location.replace();
            }
            if (typeof rejection.status === 'undefined' || rejection.status !== -1) {
                service.lastError = rejection;
            }

            return $q.reject(rejection);
        };

        return service;
    };

    App.config = function ($stateProvider,
                           $urlRouterProvider,
                           $httpProvider,
                           errors,
                           ngTableFilterConfigProvider,
                           gravatarServiceProvider,
                           LightboxProvider,
                           RollbarProvider) {
        if (Pace) {
            Pace.options.ajax.trackWebSockets = false;
        }
        $httpProvider.defaults.useXDomain = true;
        $httpProvider.interceptors.push('authInterceptor');

        // For any unmatched url, redirect to state /index/main
        $urlRouterProvider.otherwise('/dashboard');
        $stateProvider
            .state('logout', {
                url: '/',
                templateUrl: '/scripts/layouts/login.html',
                data: {
                    login: true,
                    page: 'login'
                },
                resolve: {
                    security: [
                        function () {
                            if (window && window.location && window.location.hostname) {
                                switch (window.location.hostname) {
                                    case 'qs-hub-dev2.qs.com':
                                        window.location.replace('https://qsconnect.qs.com/');
                                        break;
                                    case 'qs-hub.qs.com':
                                        window.location.replace('https://qs-hub2.qs.com/');
                                        break;
                                }
                            }
                        }
                    ]
                }
            })
            .state('forgot-password', {
                url: '/forgot-password',
                templateUrl: '/scripts/layouts/login.html',
                data: {
                    login: true,
                    page: 'forgot-password'
                },
                resolve: {
                    security: [
                        function () {
                            if (window && window.location && window.location.hostname) {
                                switch (window.location.hostname) {
                                    case 'qs-hub-dev2.qs.com':
                                        window.location.replace('https://qsconnect.qs.com/');
                                        break;
                                    case 'qs-hub.qs.com':
                                        window.location.replace('https://qs-hub2.qs.com/');
                                        break;
                                }
                            }
                        }
                    ]
                }
            })
            .state('password-reset', {
                url: '/password-reset',
                templateUrl: '/scripts/layouts/login.html',
                data: {
                    login: true,
                    page: 'password-reset'
                },
                resolve: {
                    security: [
                        function () {
                            if (window && window.location && window.location.hostname) {
                                switch (window.location.hostname) {
                                    case 'qs-hub-dev2.qs.com':
                                        window.location.replace('https://qsconnect.qs.com/');
                                        break;
                                    case 'qs-hub.qs.com':
                                        window.location.replace('https://qs-hub2.qs.com/');
                                        break;
                                }
                            }
                        }
                    ]
                }
            })
            .state('reset-password', {
                url: '/reset-password?id',
                templateUrl: '/scripts/layouts/login.html',
                data: {
                    login: true,
                    page: 'reset-password'
                },
                resolve: {
                    security: [
                        function () {
                            if (window && window.location && window.location.hostname) {
                                switch (window.location.hostname) {
                                    case 'qs-hub-dev2.qs.com':
                                        window.location.replace('https://qsconnect.qs.com/');
                                        break;
                                    case 'qs-hub.qs.com':
                                        window.location.replace('https://qs-hub2.qs.com/');
                                        break;
                                }
                            }
                        }
                    ]
                },
                controller: function ($scope, $stateParams) {
                    $scope.requestId = $stateParams.id;
                }
            })
            .state('user', {
                url: '/profiles',
                abstract: true,
                templateUrl: '/scripts/layouts/main.html'
            })
            .state('user.my-profile', {
                url: '/profile',
                templateUrl: '/scripts/components/profile/profileView.html',
                data: {
                    pageTitle: 'My Profile',
                    label: 'My Profile'
                },
                resolve: {
                    security: [
                        '$q',
                        function ($q) {
                                return $q.reject(errors[401]);
                        }
                    ]
                }
            })
            .state('clients', {
                abstract: true,
                templateUrl: '/scripts/layouts/main.html',
                data: {
                    label: 'Institutions'
                }
            }).state('clients.profiles', {
            url: '/profiles',
            abstract: true,
            template: '<div ui-view></div>',
            data: {
                label: 'Profiles'
            }
        }).state('clients.profiles.shared', {
            url: '/institution-details',
            templateUrl: '/scripts/components/profiles/shared/sharedProfileView.html',
            data: {
                pageTitle: 'Institution Details',
                label: 'Institution Details',
                name: 'Profiles'
            },
            resolve: {
                security: [
                    '$q', 'UserFactory', 'AuthenticationService',
                    function ($q, UserFactory, AuthenticationService) {
                        UserFactory.setData(AuthenticationService.getUserData());
                        if (!UserFactory.hasData()) {
                            return $q.reject(errors[401]);
                        } else if (!UserFactory.hasSharedProfileAccess()) {
                            return $q.reject(errors[403]);
                        } else if (UserFactory.isClient()) {
                            return $q.reject(errors[402]);
                        } 
                    }
                ]
            }
        }).state('clients.profiles.shared.general', {
            url: '/general',
            data: {
                label: 'General'
            }
        }).state('clients.profiles.shared.campuses', {
            url: '/campuses',
            data: {
                label: 'Campuses'
            },
            resolve: {
                security: [
                    '$q', 'UserFactory', 'AuthenticationService', 'TuProfileFactory',
                    function ($q) {
                            return $q.reject(errors[403]);
                    }
                ]
            }
        }).state('clients.profiles.tu', {
            url: '/topuniversities',
            templateUrl: '/scripts/components/profiles/tu/tuProfileView.html',
            data: {
                pageTitle: 'Undergraduate / Postgraduate',
                label: 'Undergraduate / Postgraduate',
                name: 'TopUniversities'
            },
            resolve: {
                security: [
                    '$q', 'UserFactory', 'AuthenticationService', 'TuProfileFactory',
                    function ($q, UserFactory, AuthenticationService, TuProfileFactory) {
                        UserFactory.setData(AuthenticationService.getUserData());
                        if (!UserFactory.hasData()) {
                            return $q.reject(errors[401]);
                        } else if (!UserFactory.hasTuProfileAccess()) {
                            return $q.reject(errors[403]);
                        }
                        
                        TuProfileFactory.setIsDepartmentOverview(false);
                        TuProfileFactory.setIsProgramOverview(false);
                        return $q.reject(errors[403]);
                    }
                ]
            }
        }).state('clients.profiles.tu.overview', {
            url: '/overview',
            data: {
                label: 'Overview'
            }
        }).state('clients.profiles.tu.departments', {
            url: '/departments',
            data: {
                label: 'Departments'
            }
        }).state('clients.profiles.tu.subjects', {
            url: '/subjects',
            data: {
                label: 'Subjects'
            }
        }).state('clients.profiles.tu.programs', {
            url: '/programs',
            data: {
                label: 'Programs'
            }
        }).state('clients.profiles.tu.media', {
            url: '/media',
            data: {
                label: 'Media'
            }
        }).state('clients.profiles.tu.publish', {
            url: '/publish',
            data: {
                label: 'Publish'
            }
        }).state('clients.profiles.tu.matchingTool', {
            url: '/matching-tool',
            data: {
                label: 'Matching Tool'
            }
        }).state('clients.profiles.tm', {
            url: '/topmba',
            templateUrl: '/scripts/components/profiles/tm/tmProfileView.html',
            data: {
                pageTitle: 'MBA',
                label: 'MBA',
                name: 'TopMba'
            },
            resolve: {
                security: [
                    '$q', 'UserFactory', 'AuthenticationService',
                    function ($q, UserFactory, AuthenticationService) {
                        UserFactory.setData(AuthenticationService.getUserData());
                        if (!UserFactory.hasData()) {
                            return $q.reject(errors[401]);
                        } else if (!UserFactory.hasTmProfileAccess()) {
                            return $q.reject(errors[403]);
                        }
                        return $q.reject(errors[403]);
                    }
                ]
            }
        }).state('clients.profiles.tm.overview', {
            url: '/overview',
            data: {
                label: 'Overview'
            }
        }).state('clients.profiles.tm.programs', {
            url: '/programs',
            data: {
                label: 'Programs'
            }
        }).state('clients.profiles.tm.program-stats', {
            url: '/program-statistics',
            data: {
                label: 'Program Statistics'
            }
        }).state('clients.profiles.tm.media', {
            url: '/media',
            data: {
                label: 'Media'
            }
        }).state('clients.profiles.tm.publish', {
            url: '/publish',
            data: {
                label: 'Publish'
            }
        }).state('clients.contacts', {
            url: '/contacts',
            templateUrl: '/scripts/components/contacts/contactsView.html',
            data: {
                pageTitle: 'Contacts',
                label: 'Contacts',
                name: 'Contacts'
            },
            resolve: {
                security: [
                    '$q', 'UserFactory', 'AuthenticationService',
                    function ($q, UserFactory, AuthenticationService) {
                        UserFactory.setData(AuthenticationService.getUserData());
                        if (!UserFactory.hasData()) {
                            return $q.reject(errors[401]);
                        } else if (!UserFactory.hasContactsAccess()) {
                            return $q.reject(errors[403]);
                        }
                        return $q.reject(errors[403]);
                    }
                ]
            }
        }).state('staff', {
            abstract: true,
            templateUrl: '/scripts/layouts/main.html',
            data: {
                label: 'QS Staff'
            }
        }).state('staff.dashboard', {
            url: '/dashboard',
            templateUrl: '/scripts/components/dashboard/admin/dashboardAdminView.html',
            data: {
                pageTitle: 'QS Staff Dashboard',
                label: 'QS Staff Dashboard'
            },
            resolve: {
                security: [
                    '$q', 'UserFactory', 'AuthenticationService',
                    function ($q, UserFactory, AuthenticationService) {
                        UserFactory.setData(AuthenticationService.getUserData());
                        if (!UserFactory.hasData()) {
                           return $q.reject(errors[401]);
                        } else if (!UserFactory.hasAdminDashboardAccess()) {
                           return $q.reject(errors[403]);
                        }
                    }
                ]
            }
        }).state('staff.users', {
            url: '/users',
            abstract: true,
            template: '<div ui-view></div>',
            data: {
                label: 'Users & Permissions'
            }
        }).state('staff.users.qs', {
            url: '/qs',
            templateUrl: '/scripts/components/users/list/qs/qsUsersListView.html',
            data: {
                pageTitle: 'QS Users List',
                label: 'QS Users List',
                name: 'QS Users'
            },
            resolve: {
                security: [
                    '$q', 'UserFactory', 'AuthenticationService',
                    function ($q, UserFactory, AuthenticationService) {
                        UserFactory.setData(AuthenticationService.getUserData());
                        if (!UserFactory.hasData()) {
                            return $q.reject(errors[401]);
                        } else if (!UserFactory.hasQsUsersAccess()) {
                            return $q.reject(errors[403]);
                        }
                    }
                ]
            }
        }).state('staff.users.institutions', {
            url: '/institutions',
            templateUrl: '/scripts/components/users/list/institutions/institutionsUsersView.html',
            data: {
                pageTitle: 'Institution Users List',
                label: 'Institution Users List',
                name: 'Institution Users'
            },
            resolve: {
                security: [
                    '$q', 'UserFactory', 'AuthenticationService',
                    function ($q, UserFactory, AuthenticationService) {
                        UserFactory.setData(AuthenticationService.getUserData());
                        if (!UserFactory.hasData()) {
                            return $q.reject(errors[401]);
                        } else if (!UserFactory.hasInstitutionsUsersAccess()) {
                            return $q.reject(errors[403]);
                        }
                    }
                ]
            }
        }).state('staff.institutions', {
            url: '/institutions',
            template: '<div ui-view></div>',
            data: {
                label: 'Institutions'
            }
        }).state('staff.institutions.list', {
            url: '/institutions/list',
            templateUrl: '/scripts/components/institutions/list/institutionsListView.html',
            data: {
                pageTitle: 'Institutions List',
                label: 'Institutions List'
            },
            params: {
                coreId: null
            },
            resolve: {
                security: [
                    '$q', 'UserFactory', 'AuthenticationService',
                    function ($q, UserFactory, AuthenticationService) {
                        UserFactory.setData(AuthenticationService.getUserData());
                        if (!UserFactory.hasData()) {
                            return $q.reject(errors[401]);
                        } else if (!UserFactory.hasInstitutionsListAccess()) {
                            return $q.reject(errors[403]);
                        }
                    }
                ]
            }
        }).state('staff.institutions.department', {
            url: '/department/overview',
            templateUrl: '/scripts/components/institutions/department/departmentOverview.html',
            data: {
                pageTitle: 'Department Overview',
                label: 'Department Overview'
            },
            params: {
                coreId: null,
                name: null
            },
            resolve: {
                security: [
                    '$q', 'UserFactory', 'AuthenticationService', 'TuProfileFactory',
                    function ($q, UserFactory, AuthenticationService, TuProfileFactory) {
                        UserFactory.setData(AuthenticationService.getUserData());
                        TuProfileFactory.setIsDepartmentOverview(true);
                        if (!UserFactory.hasDepartmentsOverviewAccess()) {
                            return $q.reject(errors[401]);
                        } else if (!UserFactory.hasDepartmentsOverviewAccess()) {
                            return $q.reject(errors[403]);
                        }
                        return $q.reject(errors[403]);
                    }
                ]
            }
        }).state('staff.institutions.tu-programs', {
            url: '/institutions/tu-programs/overview',
            templateUrl: '/scripts/components/institutions/tu-programs/institutionsTuProgramsView.html',
            data: {
                pageTitle: 'TU Programs Overview',
                label: 'TU Programs Overview'
            },
            resolve: {
                security: [
                    '$q', 'UserFactory', 'AuthenticationService', 'TuProfileFactory',
                    function ($q, UserFactory, AuthenticationService, TuProfileFactory) {
                        UserFactory.setData(AuthenticationService.getUserData());
                        TuProfileFactory.setIsProgramOverview(true);
                        if (!UserFactory.hasTuProgramsOverviewAccess()) {
                            return $q.reject(errors[401]);
                        } else if (!UserFactory.hasTuProgramsOverviewAccess()) {
                            return $q.reject(errors[403]);
                        }
                        return $q.reject(errors[403]);
                    }
                ]
            }
        }).state('staff.tm-directory', {
            url: '/tm-directory',
            templateUrl: '/scripts/components/tmDirectory/tmDirectoryView.html',
            data: {
                pageTitle: 'TM Directory',
                label: 'TM Directory'
            },
            resolve: {
                security: [
                    '$q', 'UserFactory', 'AuthenticationService',
                    function ($q, UserFactory, AuthenticationService) {
                        UserFactory.setData(AuthenticationService.getUserData());
                        if (!UserFactory.hasData()) {
                            return $q.reject(errors[401]);
                        } else if (!UserFactory.hasTmDirectoryAccess()) {
                            return $q.reject(errors[403]);
                        }
                        return $q.reject(errors[403]);
                    }
                ]
            }
        }).state('staff.master-password', {
            url: '/master-password',
            templateUrl: '/scripts/components/master-password/masterPasswordView.html',
            data: {
                pageTitle: 'Master Password',
                label: 'Master Password',
                name: 'Master Password'
            },
            resolve: {
                security: [
                    '$q', 'UserFactory', 'AuthenticationService',
                    function ($q, UserFactory, AuthenticationService) {
                        UserFactory.setData(AuthenticationService.getUserData());
                        if (!UserFactory.hasData()) {
                            return $q.reject(errors[401]);
                        } else if (!UserFactory.hasMasterPasswordAccess()) {
                            return $q.reject(errors[403]);
                        }
                    }
                ]
            }
        });

        var filterPath = '/scripts/shared/ng-table/filters/';
        var filterAliasUrls = {
            'date-range-picker': filterPath + 'dateRangeFilter.html'
        };
        ngTableFilterConfigProvider.setConfig({
            aliasUrls: filterAliasUrls
        });
        gravatarServiceProvider.protocol = 'https';
        gravatarServiceProvider.defaults = {
            "default": 'mm'
        };

        LightboxProvider.templateUrl = '/scripts/shared/modal/modalTemplate.html';

        var rollbar = {
            accessTokens: {
                qaProd: "529620bc9b9d4eecaddac0148766972f",
                live: 'ca906a6af2414146ad6b4e95f4237054'
            },
            env: null,
            captureUncaught: true
        };
        if (window && window.location && window.location.hostname) {
            switch (window.location.hostname) {
                case 'qs-hub.qa-prod.qs.com':
                case 'qs-hub-qa-prod.qs.com':
                rollbar.env = 'qaProd';
                    break;
                case 'qs-hub.qs.com':
                rollbar.env = 'live';
                    break;
            }
        }

        RollbarProvider.init({
            accessToken: rollbar.accessTokens[rollbar.env],
            captureUncaught: rollbar.captureUncaught,
            payload: {
                environment: rollbar.env
            }
        });
    };

    App.run = function run($rootScope,
                           $state,
                           $http,
                           $location,
                           $localStorage,
                           $timeout,
                           $window,
                           constants,
                           errors,
                           AuthenticationService,
                           InstitutionsListService,
                           UserFactory,
                           UnsavedChangesFactory,
                           ModalService,
                           WatchService,
                           TuProfileFactory,
                           version,
                           InstitutionService) {
        var dataLayer = $window.dataLayer = $window.dataLayer || [];
        $rootScope.$state = $state;

        // keep user logged in after page refresh
        if ($localStorage.currentUser) {
            AuthenticationService.setAuthorizationHeader($localStorage.currentUser.token);
        }

        function getPrevState(){
            var count = 0;
            for(var i = dataLayer.length-1; i >= 0; i--){
                if(dataLayer[i].event==='ngRouteChange'){
                    count++;
                    if(count === 2){
                        return dataLayer[i].attributes.state;
                    }
                }
            }
        }

        var checkUnsavedChanges = function (event, toState, toParams) {
            var prevState = null;
            if (toState.name === $state.current.name){
                //its a back button press, get the proper last state
                prevState = getPrevState();
            }
            if (UnsavedChangesFactory.hasChanges()) {
                event.preventDefault();
                var modalOptions = {
                    handleNoClick: function () {
                        UnsavedChangesFactory.resetChanges();
                        ModalService.close();
                        if(prevState){
                            $state.go(prevState.name);
                        } else {
                            $state.go(toState.name);
                        }
                    },
                    handleYesClick: function () {
                        var context = this;
                        UnsavedChangesFactory.handleYesClickFn(context, prevState || toState);
                    }
                }, modalDefaults = {
                    backdrop: 'static',
                    keyboard: false,
                    modalFade: true,
                    templateUrl: '/scripts/shared/unsavedChanges/unsavedChangesView.html'
                };
                ModalService.show(modalDefaults, modalOptions);
            }
            else {
                $timeout(function() {
                    angular.element('.modal-dialog .modal-footer button.btn.btn-default').triggerHandler('click');
                }); 
            }
        };

        $window.onbeforeunload = function () {
            if (UnsavedChangesFactory.hasChanges()) {
                return 'Changes that you made may not be saved';
            }
        };

        $rootScope.$on('$stateChangeStart', function (event, toState, toParams) {
            version = typeof version !== 'string' ? version.toString() : version;
            var localStorageVersion = $localStorage.version && typeof $localStorage.version !== 'string' ? $localStorage.version.toString() : $localStorage.version;
            if (version !== localStorageVersion) {
                AuthenticationService.logout();
                $window.localStorage.clear();
                $localStorage.$reset();
                $localStorage.version = version;
            }
           
            if (toState.name !== 'logout' && AuthenticationService.getPublicPages().indexOf($location.path()) === -1) { 
                if (toState.name.indexOf('data-submission') !== -1 &&
                    toParams && toParams.messageId) {
                        UserFactory.setRequestedStateParams(toParams);
                }
                UserFactory.setRequestedStateBeforeLogin(toState);
            }
            checkUnsavedChanges(event, toState, toParams);

            if ($window && $window.localStorage && $window.localStorage[constants.xStorage.keys.logout] &&
                constants.xStorage.allowedSources.indexOf($window.localStorage[constants.xStorage.keys.logout]) !== -1
            ) {
                event.preventDefault();
                AuthenticationService.logout();
                $state.go('logout');
                return false;
            } else if ($window && $window.localStorage && $window.localStorage[constants.xStorage.keys.data] &&
                $window.localStorage[constants.xStorage.keys.source] &&
                constants.xStorage.allowedSources.indexOf($window.localStorage[constants.xStorage.keys.source]) !== -1 && !AuthenticationService.hasUserData()
            ) {
                var userData;
                try {
                    userData = angular.fromJson($window.localStorage[constants.xStorage.keys.data]);
                } catch (e) {
                    AuthenticationService.logout();
                    return false;
                }
                AuthenticationService.setUserData(userData.userData);
                AuthenticationService.setData(userData);
            }
            else if ($window && $window.localStorage && $window.localStorage[constants.xStorage.keys.data] &&
                $window.localStorage[constants.xStorage.keys.source] &&
                constants.xStorage.allowedSources.indexOf($window.localStorage[constants.xStorage.keys.source]) !== -1 && 
                $window.localStorage[constants.xStorage.keys.source] === 'hub2'
            ) {
                var localStorageUserData;
                try {
                    localStorageUserData = angular.fromJson($window.localStorage[constants.xStorage.keys.data]);
                } catch (e) {
                    AuthenticationService.logout();
                    return false;
                }
                AuthenticationService.setData(localStorageUserData);
            }
            if ($window && $window.localStorage && $window.localStorage[constants.xStorage.keys.sendToSession]) {
                if ($window.localStorage[constants.xStorage.keys.sendToSession] === 'true') {
                    $window.localStorage[constants.xStorage.keys.sendToSession] = 'false';
                }
            }
        });

        $rootScope.$on('$stateChangeSuccess', function (event, toState) {
            dataLayer.push({
                event: 'ngRouteChange',
                attributes: {
                    route: $location.path(),
                    state: toState
                }
            });
            var restrictedPage = AuthenticationService.getPublicPages().indexOf($location.path()) === -1,
                isClientPage = $state.includes('clients') || $state.includes('user');
            App.services.authInterceptor().setOrganisationId($location.search().coreId);
            // if its restricted page and user is not logged in
            if (restrictedPage && !$localStorage.currentUser) {
                var redirectUrl = $location.url();
                // send user to login page
                $location.path('/').search({url: redirectUrl});
                $location.replace();
                // cancel all ajax requests
                $http.pendingRequests.forEach(function (pendingRequest) {
                    if (pendingRequest.cancel) {
                        pendingRequest.cancel.resolve(App.cancelMessage);
                    }
                });
            } else if (// logged in user is client and accessing backend page
            !isClientPage && $localStorage.currentUser &&
            $localStorage.currentUser.info.isClient &&
            $location.path() !== '/'
            ) {
                $location.path(constants.newDefaultClientPage).search({
                    coreId: App.services.authInterceptor().getOrganisationId() ?
                        App.services.authInterceptor().getOrganisationId() :
                        $localStorage.currentUser.info.primaryInstitution
                });
                $location.replace();
            } else if ( // logged in user is client
            $localStorage.currentUser &&
            $localStorage.currentUser.info.isClient
            ) {
                // check if user has access to institution he's trying to access
                var hasAccessTo = $localStorage.currentUser.info.institutions;
                if ($localStorage.currentUser.info.primaryInstitution &&
                    hasAccessTo.indexOf(parseInt($localStorage.currentUser.info.primaryInstitution, 10)) === -1
                ) {
                    hasAccessTo.push(parseInt($localStorage.currentUser.info.primaryInstitution, 10));
                }
                // if currently requested page is not in the allowed list, send user back to his primary institution
                if (hasAccessTo.indexOf(parseInt($localStorage.currentInstitutionId, 10)) === -1) {
                    if (!$localStorage.currentUser.info.passwordReset) {
                        var userHasAccessTo = false;
                        if ($location.path() !== '/') {
                            userHasAccessTo = $localStorage.currentUser.info.accessTo.indexOf($state.current.name.replace('clients.', '')) !== -1;
                        }
                        var path = userHasAccessTo ? $location.path() : constants.newDefaultClientPage;
                        $location.path(path).search({
                            coreId: $localStorage.currentUser.info.primaryInstitution
                        });
                        $location.replace();
                    }
                }
            }
        });

        $rootScope.$on('$stateChangeError', function (e, toState, toParams, fromState, fromParams, error) {
            if (error === errors[401]) {
                AuthenticationService.logout();
                $state.go('logout');
            }
            if (error === errors[403]) {
                InstitutionService.handle403();
            }
        });
    };

    angular
        .module('qsHub')
        .service('authInterceptor', [
            '$q',
            '$location',
            '$localStorage',
            App.services.authInterceptor
        ])
        .config(App.config, [
            '$stateProvider',
            '$urlRouterProvider',
            '$httpProvider',
            'errors',
            'ngTableFilterConfigProvider',
            'gravatarServiceProvider',
            'LightboxProvider'
        ])
        .run(App.run, [
            '$rootScope',
            '$state',
            '$http',
            '$location',
            '$localStorage',
            '$timeout',
            '$window',
            'constants',
            'errors',
            'AuthenticationService',
            'InstitutionsListService',
            'UserFactory',
            'UnsavedChangesFactory',
            'ModalService',
            'TuProfileFactory',
            'version',
            'InstitutionService'
        ]);

}(window.angular, window.Pace));

(function (angular) {
    'use strict';

    var App = App || {};
    App = angular.extend({}, App, {directives: {}});

    /**
     * Directive for set Page title - mata title
     */
    App.directives.pageTitle = function ($rootScope, $timeout, $state) {
        return {
            restrict: 'A',
            link: function (scope, element) {
                var listener = function (event, toState, toParams, fromState, fromParams) {
                    // Default title - load on Dashboard 1
                    var title = 'QS Hub';
                    $timeout(function () {
                        // Create your own title pattern
                        if (toState.data && toState.data.pageTitle) {
                            if ($state.current &&
                                $state.current.data &&
                                $state.current.data.label &&
                                $state.current.data.label === 'QS Staff Dashboard') {
                                title += ' | ' + $state.current.data.label;
                            }
                            else {
                                title += ' | ' + toState.data.pageTitle;
                            }
                        }
                        element.text(title);
                    });
                };
                $rootScope.$on('$stateChangeStart', listener);
            }
        };
    };

    /**
     * Directive to add fancy styles to checkbox or radiobutton
     * - The directive should has true or false attribute E.g: (my-fancy-check-box="true")
     * - This would check (true) or uncheck (false) the element
     *
     * @param $parse
     * @returns {{restrict: string, require: string, link: link}}
     */
    App.directives.iCheckbox = function ($parse) {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function ($scope, element, $attrs, ngModel) {
                var value = $attrs.value;

                // Update the value on load
                $scope.$watch($attrs.ngModel, function () {
                    $(element).iCheck('update');
                });

                return $(element).iCheck({
                    checkboxClass: 'icheckbox_square-green',
                    radioClass: 'iradio_square-green'

                }).on('ifChanged', function (event) {
                    if ($(element).attr('type') === 'checkbox' && $attrs.ngModel) {
                        $scope.$apply(function () {
                            return ngModel.$setViewValue(event.target.checked);
                        });
                    }
                    if ($(element).attr('type') === 'radio' && $attrs.ngModel) {
                        return $scope.$apply(function () {
                            return ngModel.$setViewValue(value);
                        });
                    }
                }).on('ifClicked', function (event) {
                    //run any ngClick function we added on the original checkbox element
                    var clickFn = $scope.$eval($attrs.ngClick);
                    if(clickFn){
                        clickFn();
                    }
                });
            }
        };
    };

    /**
     * touchSpin - Directive for Bootstrap TouchSpin
     */
    App.directives.touchSpin = function () {
        return {
            restrict: 'A',
            scope: {
                spinOptions: '='
            },
            link: function (scope, element, attrs) {
                scope.$watch(scope.spinOptions, function () {
                    render();
                });
                var render = function () {
                    $(element).TouchSpin(scope.spinOptions);
                };
            }
        };
    };

    /**
     * iboxTools - Directive for iBox tools elements in right corner of ibox
     */
    App.directives.iboxTools = function ($timeout) {
        return {
            restrict: 'C',
            scope: true,
            link: function ($scope, $element, $attrs) {
                var show = [],
                    id = $attrs.id || null,
                    animationDuration = 200;
                $scope.$watch($attrs.expand, function (expand) {
                    if (id) {
                        show[id] = expand;
                        if (expand) {
                            $scope.showHide();
                        }
                    }
                });
                $scope.showHide = function () {
                    var ibox = $element.closest('div.ibox'),
                        icon = $element.find('i:first'),
                        content = ibox.find('div.ibox-content');

                    if ($attrs.expand && show[id]) {
                        content.slideDown(animationDuration);
                        icon.addClass('fa-chevron-up').removeClass('fa-chevron-down');
                        ibox.toggleClass('').addClass('border-bottom');
                    } else {
                        content.slideToggle(animationDuration);
                        // Toggle icon from up to down
                        icon.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
                        ibox.toggleClass('').toggleClass('border-bottom');
                    }
                    $timeout(function () {
                        ibox.resize();
                        ibox.find('[id^=map-]').resize();
                    }, 50);
                };
                $scope.showHideRankingsRHS = function () {
                    var ibox = $element.closest('div.ibox'),
                        icon = $element.find('i:first'),
                        LHS = $element.closest('div.parent-div').children('.left-hand-panel'),
                        RHS = $element.closest('div.right-hand-panel'),
                        title = $element.closest('div.row').find('h5'),
                        content = ibox.find('div.ibox-content');
                    if ($attrs.expand && show[id]) {
                        content.slideDown(animationDuration);
                        icon.addClass('fa-chevron-up').removeClass('fa-chevron-down');
                        ibox.toggleClass('').addClass('border-bottom');
                        title.toggleClass('').toggleClass('d-none');
                        RHS.addClass('col-lg-3').removeClass('col-lg-1');
                        LHS.addClass('col-lg-9').removeClass('col-lg-11');
                    } else {
                        content.slideToggle(animationDuration);
                        // Toggle icon from up to down
                        icon.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
                        ibox.toggleClass('').toggleClass('border-bottom');
                        title.toggleClass('').toggleClass('d-none');
                        RHS.toggleClass('col-lg-3').toggleClass('col-lg-1');
                        LHS.toggleClass('col-lg-9').toggleClass('col-lg-11');
                    }
                    $timeout(function () {
                        ibox.resize();
                        ibox.find('[id^=map-]').resize();
                    }, 50);
                };
                // Function for close ibox
                $scope.closebox = function () {
                    var ibox = $element.closest('div.ibox');
                    ibox.remove();
                };
            }
        };
    };

    /**
     * Toggle element class on click.
     * eg: toggle-class-on-click
     *
     * @returns {{restrict: string, link: link}}
     */
    App.directives.toggleClassOnClick = function () {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                element.bind('click', function () {
                    element.toggleClass(attrs.toggleClassOnClick);
                });
            }
        };
    };

    /**
     * Directive for Sparkline chart
     */
    App.directives.sparkline = function () {
        return {
            restrict: 'A',
            scope: {
                sparkData: '=',
                sparkOptions: '=',
            },
            link: function (scope, element, attrs) {
                scope.$watch(scope.sparkData, function () {
                    render();
                });
                scope.$watch(scope.sparkOptions, function () {
                    render();
                });
                var render = function () {
                    $(element).sparkline(scope.sparkData, scope.sparkOptions);
                };
            }
        };
    };

    App.directives.currentDate = function ($filter) {
        return {
            restrict: 'A',
            link: function ($scope, $element, $attrs) {
                $element.text($filter('date')(new Date(), $attrs.currentDate));
            }
        };
    };

    App.directives.convertToNumber = function () {
        return {
            require: 'ngModel',
            link: function (scope, element, attrs, ngModel) {
                ngModel.$parsers.push(function (val) {
                    return parseInt(val, 10);
                });
                ngModel.$formatters.push(function (val) {
                    return '' + val;
                });
            }
        };
    };

    App.directives.waveSpinner = function () {
        return {
            restrict: 'A',
            template: ' <div class="sk-spinner sk-spinner-wave">' +
            '<div class="sk-rect1"></div>&nbsp;' +
            '<div class="sk-rect2"></div>&nbsp;' +
            '<div class="sk-rect3"></div>&nbsp;' +
            '<div class="sk-rect4"></div>&nbsp;' +
            '<div class="sk-rect5"></div>' +
            '</div>'
        };
    };

    /**
     * Directive to attach bootstrap tooltip on an element
     */
    App.directives.customPopover = function ($animateCss) {
        return {
            restrict: 'A',
            link: function (scope, el, attrs) {
                $(el).popover({
                    trigger: attrs.popoverTrigger || 'hover focus',
                    html: true,
                    content: attrs.popoverHtml,
                    container: attrs.popoverContainer || '',
                    placement: attrs.popoverPlacement
                });
                scope.$watch(function () {
                    return attrs.popoverVisibility;
                }, function (newValue, oldValue) {
                    if (newValue !== oldValue) {
                        if (newValue === 'true') {
                            $(el).popover('show');
                            el.next("div").addClass("animated fadeInDown");
                            el.next("div").css("top", "0px");
                        } else {
                            $(el).popover('hide');
                        }
                    }
                });
                scope.$watch(function () {
                    return attrs.popoverHtml;
                }, function (newValue, oldValue) {
                    if (newValue !== oldValue) {
                        var popover = $(el).attr('data-content', newValue).data('bs.popover');
                        popover.setContent();
                        popover.$tip.addClass(popover.options.placement);
                    }
                });
            }
        };
    };

    /**
     * Directive for Angular Ui Switch
     */
    App.directives.switch = function () {
        return {
            restrict: 'AE',
            replace: true,
            transclude: true,
            template: function (element, attrs) {
                var html = '';
                html += '<span';
                html += ' class="switch' + (attrs.class ? ' ' + attrs.class : '') + '"';
                html += attrs.ngModel ? ' ng-click="' + attrs.ngDisabled + ' ? ' + attrs.ngModel + ' : ' + attrs.ngModel + '=!' + attrs.ngModel + (attrs.ngChange ? '; ' + attrs.ngChange + '()"' : '"') : '';
                html += ' ng-class="{checked:' + attrs.ngModel + ', disabled:' + attrs.ngDisabled + ' }"';
                html += '>';
                html += '<small></small>';
                html += '<input type="checkbox"';
                html += attrs.id ? ' id="' + attrs.id + '"' : '';
                html += attrs.name ? ' name="' + attrs.name + '"' : '';
                html += attrs.ngModel ? ' ng-model="' + attrs.ngModel + '"' : '';
                html += attrs.ngModel ? ' ng-change="' + attrs.ngChange + '"' : '';
                html += attrs.ngDisabled ? ' ng-disabled="' + attrs.ngDisabled + '"' : '';
                html += ' style="display:none" />';
                html += '<span class="switch-text">';
                /*adding new container for switch text*/
                html += attrs.on ? '<span class="on">' + attrs.on + '</span>' : '';
                /*switch text on value set by user in directive html markup*/
                html += attrs.off ? '<span class="off">' + attrs.off + '</span>' : ' ';
                /*switch text off value set by user in directive html markup*/
                html += '</span>';
                return html;
            }
        };
    };

    App.directives.includeReplace = function () {
        return {
            require: 'ngInclude',
            restrict: 'A',
            link: function (scope, el, attrs) {
                el.replaceWith(el.children());
            }
        };
    };

    App.directives.dzHiddenInputFile = function () {
        return {
            restrict: 'A',
            link: function (scope, el, attrs) {
                scope.$watch(function () {
                    return attrs.uploadEnabled;
                }, function (newValue, oldValue) {
                    if (newValue !== oldValue) {
                        if (newValue === 'true') {
                            $(".dz-hidden-input").prop("disabled", true);
                        } else {
                            $(".dz-hidden-input").prop("disabled", false);
                        }
                    }
                });
            }
        };
    };

    /**
     * Datagrid totals.
     *
     * @returns {{restrict: string, scope: {tableParams: string}, template: string}}
     */
    App.directives.datagridTotals = function () {
        return {
            restrict: 'A',
            scope: {tableParams: '=datagridTotals'},
            template: '{{ tableParams.count() > tableParams.total() ? tableParams.total() : tableParams.count() }} of {{ tableParams.total() }} entries'
        };
    };

    /**
     * Directive to Confirm Password.
     */
    App.directives.verifyPassword = function () {
        return {
            require: 'ngModel',
            link: function (scope, elem, attrs, ctrl) {
                var password = '#' + attrs.verifyPassword;
                elem.add(password).on('keyup', function () {
                    scope.$apply(function () {
                        var isEmpty = $(password).val() === '';
                        var invalidLen = !isEmpty && ($(password).val().length < 8 || $(password).val().length > 20);
                        ctrl.$setValidity('isEmpty', !isEmpty);
                        ctrl.$setValidity('invalidLen', !invalidLen);
                        ctrl.$setValidity('pwmatch', elem.val() === $(password).val());
                    });
                });
            }
        };
    };

     /**
     * Directive to check element ready.
     */
    App.directives.elemReady = function ($parse, $timeout) {
        return {
            restrict: 'A',
            link: function( $scope, elem, attrs ) {    
               elem.ready(function(){
                $timeout(function () {
                    $scope.$apply(function(){
                        var func = $parse(attrs.elemReady);
                        func($scope);
                    });
                },60);
               });
            }
         };
    };

    angular
        .module('qsHub')
        .directive('pageTitle', App.directives.pageTitle)
        .directive('toggleClassOnClick', App.directives.toggleClassOnClick)
        .directive('iCheckbox', App.directives.iCheckbox)
        .directive('sparkline', App.directives.sparkline)
        .directive('currentDate', App.directives.currentDate)
        .directive('iboxTools', App.directives.iboxTools)
        .directive('convertToNumber', App.directives.convertToNumber)
        .directive('touchSpin', App.directives.touchSpin)
        .directive('waveSpinner', App.directives.waveSpinner)
        .directive('switch', App.directives.switch)
        .directive('includeReplace', App.directives.includeReplace)
        .directive('dzHiddenInputFile', App.directives.dzHiddenInputFile)
        .directive('datagridTotals', App.directives.datagridTotals)
        .directive('verifyPassword', App.directives.verifyPassword)
        .directive('elemReady', App.directives.elemReady)
        .directive('customPopover', App.directives.customPopover);

}(window.angular));

(function (angular) {
    'use strict';

    var App = App || {};
    App = angular.extend({}, App, {filters: {}});

    /**
     * Filter for fixing ui-select bug where the label does not update to match edits made to the options on currently
     * selected options
     *
     * @returns {Function}
     */
    App.filters.getCurrentLabel = function () {
        return function (value, options, valueProperty, labelProperty) {
            var option = null;
            valueProperty = valueProperty || 'value';
            labelProperty = labelProperty || 'label';
            if (options) {
                for (var i = 0; i < options.length; i++) {
                    if (options[i][valueProperty] === value) {
                        option = options[i];
                        break;
                    }
                }
            }

            if (option) {
                return option[labelProperty];
            } else {
                return null;
            }

        };
    };

    /**
     * Filter for removing tags from a string.
     *
     * @returns {Function}
     */
    App.filters.htmlToPlaintext = function () {
        return function (text) {
            if (text && text.length) {
                text = angular.element('<div />').html(text).text();
                text = String(text).replace(/(\r)?\n/g, '').replace(/<[^>]+>/gm, '').replace('&nbsp;', '');
            } else {
                text = '';
            }
            return text;
        };
    };

    App.filters.boolToText = function () {
        return function (text, options) {
            for (var option in options) {
                if (options[option].value === text) {
                    return options[option].label;
                }
            }
        };
    };

    App.filters.boldText = function () {
        return function (text) {
            var boldText, 
                textArray = text.split(";");
            for (var i =0; i < textArray.length; i++) {
                if (i === 0) {
                    boldText = "<b>" + textArray[0] + "</b> ";
                }
                else {
                    boldText = boldText + textArray[i];
                }
            }
            
            return boldText;
        };
    };

    App.filters.campusArrayToText = function () {
        return function (campusArray, options) {
            var campuses = [];
            if (campusArray.length) {
                for (var key in options) {
                    if (campusArray.indexOf(options[key].value) !== -1) {
                        campuses.push(options[key].label);
                        continue;
                    }
                }
            }
            return campuses.join(', ');
        };
    };

    App.filters.dotToDash = function () {
        return function (input) {
            var result = input.toString().split('.').join('-');
            return result;
        };
    };

    App.filters.orderObject = function () {
        return function (object, reverse) {
            var ordered = {};
            if (object) {
                //Object.values does not work well in IE
                var values = Object.keys(object).map(function(key) {
                    return object[key];
                }).sort(),
                key = null;
                if (reverse) {
                    values.reverse();
                }
                angular.forEach(values, function (value) {
                    for (key in object) {
                        if (object[key] === value) {
                            ordered[key] = value;
                            break;
                        }
                    }
                });
            }

            return ordered;
        };
    };

    App.filters.orderArray = function () {
        return function (items, reverse, orderColumn) {
            var values = {},
                ordered = [],
                keys;

            angular.forEach(items, function (item) {
                if (typeof item[orderColumn] !== 'undefined') {
                    values[item[orderColumn]] = item;
                }
            });
            keys = Object.keys(values);
            if (reverse) {
                keys.reverse();
            } else {
                keys.sort();
            }

            angular.forEach(keys, function (key) {
                ordered.push(values[key]);
            });

            return ordered;
        };
    };

    App.filters.dashToSpace = function () {
        return function (input) {
            return input.replace(/_/g, ' ');
        };
    };

    App.filters.publishedStatus = function () {
        return function (input) {
            switch (input) {
                case 'pending':
                    return 'Pending';
                case 'failure':
                    return 'Failure';
                case 'progress':
                    return 'Progress';
                case 'success':
                    return 'Success';
                case 'not-published':
                    return 'Not Published Yet';
                
            }
        };
    };

    App.filters.ratings = function () {
        return function (input) {
            switch (input) {
                case -1:
                    return 'Empty/Do Not Display';
                case 5:
                    return 'Diamond+';
                case 4:
                    return 'Diamond';
                case 3:
                    return 'Gold';
                case 2:
                    return 'Silver';
                case 1:
                    return 'Bronze';
                case 0:
                    return 'Provisional';
                case -2:
                    return 'Unassigned';

            }
        };
    };

    App.filters.capitalize = function () {
        return function (input) {
            if (typeof input === 'boolean') {
                return null;
            }
            if (input.indexOf(' ') !== -1) {
                var inputPieces,
                    i;

                input = input.toLowerCase();
                inputPieces = input.split(' ');

                for (i = 0; i < inputPieces.length; i++) {
                    inputPieces[i] = capitalizeString(inputPieces[i]);
                }

                return inputPieces.toString().replace(/,/g, ' ');
            }
            else {
                input = input.toLowerCase();
                return capitalizeString(input);
            }

            function capitalizeString(inputString) {
                return inputString.substring(0, 1).toUpperCase() + inputString.substring(1);
            }
        };
    };

    angular
        .module('qsHub')
        .filter('htmlToPlaintext', App.filters.htmlToPlaintext)
        .filter('boolToText', App.filters.boolToText)
        .filter('boldText', App.filters.boldText)
        .filter('campusArrayToText', App.filters.campusArrayToText)
        .filter('dotToDash', App.filters.dotToDash)
        .filter('orderObject', App.filters.orderObject)
        .filter('orderArray', App.filters.orderArray)
        .filter('dashToSpace', App.filters.dashToSpace)
        .filter('capitalize', App.filters.capitalize)
        .filter('getCurrentLabel', App.filters.getCurrentLabel)
        .filter('ratings', App.filters.ratings)
        .filter('publishedStatus', App.filters.publishedStatus);
}(window.angular));

(function(angular) {
    'use strict';

    var App = App || {};
    App = angular.extend({}, App, {factories:{}});

    /**
     * Factory to use notifications.
     * For more info: https://github.com/jirikavi/AngularJS-Toaster
     *
     * @param toaster
     * @returns {{show: show}}
     * @constructor
     */
    App.factories.Notifier = function (toaster) {
        return {
            show: function (type, body, title, sticky, id) {
                var notification = {
                    type: type,
                    body: body,
                    showCloseButton: true
                };
                if (title) {
                    notification.title = title;
                }
                if (id) {
                    notification.id = id;
                }
                if (sticky) {
                    notification.timeout = 0;
                    notification.extendedTimeOut = 0;
                }
                toaster.pop(notification);
            }
        };
    };

    App.factories.TuProfile = function () {
        var data = {},
            program = {},
            profileTabs = {
                overview : 0,
                subjects : 1,
                programs : 2,
                media : 3,
                publish : 4,
                departments : 6,
                matchingTool : 7,
                entryRequirements : 5
            },
            programBeforeChanges = {},
            departments = {},
            videos = null,

            upgradeFormVisible = false,
            historyLogVisible = false,
            programAddFormVisible = false,
            programEditFormVisible = false,
            departmentAddFormVisible = false,
            departmentEditFormVisible = false,

            newProgram = false,
            triggerResetProgramForm = false,
            department = {},
            departmentBeforeChanges = {},
            isDepartmentNameUpdated = false,
            newDepartment = false,
            clearDepartmentFormAfterSave = false,
            selectedMediaTabId = null,
            selectedTabId = null,
            availableSubjects = {},
            isProgramUpdated = false,
            updateDepartmentsGrid = false,
            rowSelected = false,
            activeOverviewSubTabs = {
                'master': true,
                'ug': false,
                'pg': false
            },
            clientDepartmentUpgradeFormVisible = false,
            isDepartmentOverview = false,
            isProgramOverview = false,
            isRequestInfoTypeMasterUrl = false,
            isRequestInfoTypeUgUrl = false,
            isRequestInfoTypePgUrl = false,
            matchingToolRightSidePanelActive = false,
            isTuMediaUpdated = false;

        var closeSidebars = function () {
            upgradeFormVisible = false;
            historyLogVisible = false;
            programAddFormVisible = false;
            programEditFormVisible = false;
            departmentAddFormVisible = false;
            departmentEditFormVisible = false;
            clientDepartmentUpgradeFormVisible = false;
            matchingToolRightSidePanelActive = false;
            newProgram = null;
            newDepartment = null;
            resetProgramForm();
        };

        var resetProgramForm = function () {
            triggerResetProgramForm = !triggerResetProgramForm;
        };

        return {
            closeSidebars: closeSidebars,
            resetProgramForm : resetProgramForm,
            getProfileTabs : function () {
                return profileTabs;
            },
            getData: function () {
                return data;
            },
            setData: function (newData) {
                data = newData;
            },
            reset: function () {
                data = {};
                department = {};
                program = {};
                availableSubjects = {};
                newDepartment = false;
                newProgram = false;
                selectedMediaTabId = null;
                selectedTabId = null;
                closeSidebars();
            },
            getId: function () {
                return data !== null && data.id || '';
            },
            getProgram: function () {
                return program;
            },
            setProgram: function (selectedProgram) {
                program = selectedProgram;
            },
            getIsDepartmentOverview: function () {
                return isDepartmentOverview;
            },
            setIsDepartmentOverview: function (newValue) {
                isDepartmentOverview = newValue;
            },
            getIsProgramOverview: function () {
                return isProgramOverview;
            },
            setIsProgramOverview: function (newValue) {
                isProgramOverview = newValue;
            },
            getProgramId: function () {
                return program && program.id ? program.id : null;
            },
            /**
             * Is program form reset.
             *
             * @returns {Boolean}
             */
            IsResetProgramForm : function () {
                return triggerResetProgramForm;
            },
            /**
             * Is history logs visible?
             *
             * @param {Integer} activeTab
             * @returns {boolean}
             */
            isHistoryLogVisible: function (activeTab) {
                if (typeof activeTab === 'undefined') {
                    return false;
                }
                return (activeTab === profileTabs.overview) && historyLogVisible;
            },
            /**
             * Set history logs visibility.
             *
             * @param {boolean|null} visibility
             */
            setHistoryLogVisibility: function (visibility) {
                if (typeof visibility === 'undefined') {
                    visibility = !historyLogVisible;
                }
                historyLogVisible = visibility;

                // hide upgrade form
                upgradeFormVisible = false;
                // hide program edit form
                programEditFormVisible = false;
                // hide program add form
                programAddFormVisible = false;
                // hide department edit form
                departmentEditFormVisible = false;
                // hide department add form
                departmentAddFormVisible = false;
                //hide upgrade department form for client
                clientDepartmentUpgradeFormVisible = false;
            },
            /**
             * Is upgrade form visible?
             *
             * @param {Integer} activeTab
             * @returns {boolean}
             */
            isUpgradeFormVisible: function (activeTab) {
                if (typeof activeTab === 'undefined') {
                    return false;
                }
                return ((activeTab === profileTabs.overview) ||
                    (activeTab === profileTabs.programs) ||
                    (activeTab === profileTabs.media) ||
                    (activeTab === profileTabs.departments)) &&
                    upgradeFormVisible;
            },
            /**
             * Set upgrade form visibility.
             *
             * @param {boolean|null} visibility
             */
            setUpgradeFormVisibility: function (visibility) {
                if (typeof visibility === 'undefined') {
                    visibility = !upgradeFormVisible;
                }
                upgradeFormVisible = visibility;

                // hide history logs
                historyLogVisible = false;
                // hide program edit form
                programEditFormVisible = false;
                // hide program add form
                programAddFormVisible = false;
                // hide department edit form
                departmentEditFormVisible = false;
                // hide department add form
                departmentAddFormVisible = false;
                //hide upgrade department form for client
                clientDepartmentUpgradeFormVisible = false;
            },
            /**
             * Is new program form visible?
             *
             * @param {Integer} activeTab
             * @returns {boolean}
             */
            isProgramAddFormVisible: function (activeTab) {
                if (typeof activeTab === 'undefined') {
                    return false;
                }
                resetProgramForm();
                return (activeTab === profileTabs.programs) && programAddFormVisible;
            },
            /**
             * Set new program form visibility.
             *
             * @param {boolean|null} visibility
             */
            setProgramAddFormVisibility: function (visibility) {
                if (typeof visibility === 'undefined') {
                    visibility = !programAddFormVisible;
                }
                programAddFormVisible = visibility;

                // hide history logs
                historyLogVisible = false;
                // hide program edit form
                programEditFormVisible = false;
                // hide upgrade form
                upgradeFormVisible = false;
                // hide department edit form
                departmentEditFormVisible = false;
                // hide department add form
                departmentAddFormVisible = false;
                //hide upgrade department form for client
                clientDepartmentUpgradeFormVisible = false;
            },
            /**
             * Is program edit form visible?
             *
             * @param {Integer} activeTab
             * @returns {boolean}
             */
            isProgramEditFormVisible: function (activeTab) {
                if (typeof activeTab === 'undefined') {
                    return false;
                }
                resetProgramForm();
                return (activeTab === profileTabs.programs) && programEditFormVisible;
            },
            /**
             * Set program edit form visibility.
             *
             * @param {boolean|null} visibility
             */
            setProgramEditFormVisibility: function (visibility) {
                if (typeof visibility === 'undefined') {
                    visibility = !programEditFormVisible;
                }
                programEditFormVisible = visibility;

                // hide history logs
                historyLogVisible = false;
                // hide program add form
                programAddFormVisible = false;
                // hide upgrade form
                upgradeFormVisible = false;
                // hide department edit form
                departmentEditFormVisible = false;
                // hide department add form
                departmentAddFormVisible = false;
                //hide upgrade department form for client
                clientDepartmentUpgradeFormVisible = false;
            },
            isClientDepartmentUpgradeFormVisible: function(activeTab) {
                if (typeof activeTab === 'undefined') {
                    return false;
                }

                return activeTab === profileTabs.departments && clientDepartmentUpgradeFormVisible;
            },
            setClientDepartmentUpgradeFormVisibility: function(visibility) {
                if (typeof visibility === 'undefined') {
                    visibility = !clientDepartmentUpgradeFormVisible;
                }
                clientDepartmentUpgradeFormVisible = visibility;

                // hide history logs
                historyLogVisible = false;
                // hide program add form
                programAddFormVisible = false;
                // hide upgrade form
                upgradeFormVisible = false;
                // hide department edit form
                departmentEditFormVisible = false;
                // hide department add form
                departmentAddFormVisible = false;
                // hide program edit form
                programEditFormVisible = false;
            },
            /**
             * Is new department form visible?
             *
             * @param {Integer} activeTab
             * @returns {boolean}
             */
            isDepartmentAddFormVisible: function (activeTab) {
                if (typeof activeTab === 'undefined') {
                    return false;
                }
                return (activeTab === profileTabs.departments) && departmentAddFormVisible;
            },
            /**
             * Set new department form visibility.
             *
             * @param {boolean|null} visibility
             */
            setDepartmentAddFormVisibility: function (visibility) {
                if (typeof visibility === 'undefined') {
                    visibility = !departmentAddFormVisible;
                }
                departmentAddFormVisible = visibility;

                // hide upgrade form
                upgradeFormVisible = false;
                // hide history logs
                historyLogVisible = false;
                // hide program edit form
                programEditFormVisible = false;
                // hide program add form
                programAddFormVisible = false;
                // hide department edit form
                departmentEditFormVisible = false;
                //hide upgrade department form for client
                clientDepartmentUpgradeFormVisible = false;
            },
            /**
             * Is department edit form visible?
             *
             * @param {Integer} activeTab
             * @returns {boolean}
             */
            isDepartmentEditFormVisible: function (activeTab) {
                if (typeof activeTab === 'undefined') {
                    return false;
                }
                return (activeTab === profileTabs.departments) && departmentEditFormVisible;
            },
            /**
             * Set department edit form visibility.
             *
             * @param {boolean|null} visibility
             */
            setDepartmentEditFormVisibility: function (visibility) {
                if (typeof visibility === 'undefined') {
                    visibility = !departmentEditFormVisible;
                }
                departmentEditFormVisible = visibility;

                // hide upgrade form
                upgradeFormVisible = false;
                // hide history logs
                historyLogVisible = false;
                // hide program edit form
                programEditFormVisible = false;
                // hide program add form
                programAddFormVisible = false;
                // hide department add form
                departmentAddFormVisible = false;
                //hide upgrade department form for client
                clientDepartmentUpgradeFormVisible = false;
            },
            setIsDepartmentRowSelected: function (value) {
                rowSelected = value;
            },
            getIsDepartmentRowSelected: function () {
                return rowSelected;
            },
            announceNewProgram: function (newProgramId) {
                newProgram = newProgramId;
            },
            hasNewProgram: function () {
                return newProgram;
            },
            setProgramBeforeChanges: function (programInstance) {
                programBeforeChanges = programInstance;
            },
            getProgramBeforeChanges: function () {
                return programBeforeChanges;
            },
            getDepartment: function () {
                return department;
            },
            setDepartment: function (selectedDepartment) {
                department = selectedDepartment;
            },
            getDepartmentId: function () {
                return department && department.id ? department.id : null;
            },
            setIsDepartmentUpdated: function (newValue) {
                isDepartmentNameUpdated = newValue;
            },
            getIsDepartmentUpdated: function () {
                return isDepartmentNameUpdated;
            },
            announceNewDepartment: function (newDepartmentId) {
                newDepartment = newDepartmentId;
            },
            hasNewDepartment: function () {
                return newDepartment;
            },
            setDepartmentBeforeChanges: function (departmentInstance) {
                departmentBeforeChanges = departmentInstance;
            },
            getDepartmentBeforeChanges: function () {
                return departmentBeforeChanges;
            },
            getDepartments: function () {
                return departments;
            },
            setDepartments: function (departmentsList) {
                departments = departmentsList;
            },
            getSelectedMediaTabId: function () {
                return selectedMediaTabId;
            },
            setSelectedMediaTabId: function (newSelectedMediaTabId) {
                selectedMediaTabId = newSelectedMediaTabId;
            },
            setSelectedTabId: function (newSelectedTabId) {
                selectedTabId = newSelectedTabId;
            },
            getSelectedTabId: function () {
                return selectedTabId;
            },
            isOverviewTabSelected: function () {
                return (typeof selectedTabId !== 'undefined') && selectedTabId === profileTabs.overview;
            },
            isDepartmentsTabSelected: function () {
                return (typeof selectedTabId !== 'undefined')  && selectedTabId === profileTabs.departments;
            },
            isSubjectsTabSelected: function () {
                return (typeof selectedTabId !== 'undefined')  && selectedTabId === profileTabs.subjects;
            },
            isProgramsTabSelected: function () {
                return (typeof selectedTabId !== 'undefined')  && selectedTabId === profileTabs.programs;
            },
            isMatchingToolTabSelected: function() {
                return (typeof selectedTabId !== 'undefined') && selectedTabId === profileTabs.matchingTool;
            },
            setMatchingToolRightSidePanelActive: function (active) {
                matchingToolRightSidePanelActive = active;
            },
            isMatchingToolRightSidePanelActive: function () {
                return matchingToolRightSidePanelActive;
            },
            isMediaTabSelected: function () {
                return (typeof selectedTabId !== 'undefined')  && selectedTabId === profileTabs.media;
            },
            setMediaVideos: function (NewVideos) {
                videos = NewVideos;
            },
            getMediaVideos: function () {
                return videos;
            },
            isPublishTabSelected: function () {
                return (typeof selectedTabId !== 'undefined')  && selectedTabId === profileTabs.publish;
            },
            setAvailableSubjects : function (newSubjects) {
                availableSubjects = newSubjects;
            },
            getAvailableSubjects : function () {
                return availableSubjects;
            },
            setActiveOverviewSubTabs : function (newSubTabs) {
                if (angular.isDefined(newSubTabs)) {
                    if (angular.equals(0, newSubTabs)) {
                        activeOverviewSubTabs.master = true;
                        activeOverviewSubTabs.ug = false;
                        activeOverviewSubTabs.pg = false;
                    }
                    if (angular.equals(1, newSubTabs)) {
                        activeOverviewSubTabs.master = false;
                        activeOverviewSubTabs.ug = true;
                        activeOverviewSubTabs.pg = false;
                    }
                    if (angular.equals(2, newSubTabs)) {
                        activeOverviewSubTabs.master = false;
                        activeOverviewSubTabs.ug = false;
                        activeOverviewSubTabs.pg = true;
                    }
                }
            },
            getActiveOverviewSubTabs : function () {
                return activeOverviewSubTabs;
            },
            setUpdateProgramGrid: function (newValue) {
                isProgramUpdated = newValue;
            },
            getUpdateProgramGrid: function () {
                return isProgramUpdated;
            },
            setUpdateDepartmentsGrid: function (newValue) {
                updateDepartmentsGrid = newValue;
            },
            getUpdateDepartmentsGrid: function () {
                return updateDepartmentsGrid;
            },
            isProgramsAlphabeticalOrder: function () {
                return data && data.programsAlphabeticalOrder;
            },
            isRequestInfoTypeMasterUrl: function () {
                return isRequestInfoTypeMasterUrl;
            },
            setRequestInfoTypeMasterUrl: function(newValue) {
                isRequestInfoTypeMasterUrl = newValue;
            },
            isRequestInfoTypeUgUrl: function () {
                return isRequestInfoTypeUgUrl;
            },
            setRequestInfoTypeUgUrl: function(newValue) {
                isRequestInfoTypeUgUrl = newValue;
            },
            isRequestInfoTypePgUrl: function () {
                return isRequestInfoTypePgUrl;
            },
            setRequestInfoTypePgUrl: function(newValue) {
                isRequestInfoTypePgUrl = newValue;
            },
            isTuMediaUpdated: function () {
                return isTuMediaUpdated;
            },
            setTuMediaUpdated: function(newValue) {
                isTuMediaUpdated = newValue;
            }
        };
    };

    App.factories.SharedProfile = function () {
        var data = {},
            SharedProfileTabs = {
                general : 0,
                campuses : 1
            },
            loadInProgress = false,
            hasCampusesChanges = false,
            campusFormVisible = false,
            campusAddFormVisible = false,
            campusEditFormVisible = false,
            editMode = null,
            selectedTabId = null,
            campus = {},
            rootShowCampusInfoBlock = null,
            showCampusInfoBlock = null,
            campusList = {},
            columns = {},
            mapUpdateRequest = null,
            loadMoreInProgress = false,
            limit = 1,
            updateButtonClicked = false;
        return {
            getData: function () {
                return data;
            },
            setData: function (newData) {
                data = newData;
            },
            getActiveTabs: function () {
                return SharedProfileTabs;
            },
            closeSidebars: function () {
                campusFormVisible = false;
                campusAddFormVisible = false;
                campusEditFormVisible = false;
            },
            isCampusFormVisible: function () {
                return campusFormVisible;
            },
            isCampusEditForm: function () {
                return editMode;
            },
            setCampusFormVisibility: function (visibility) {
                campusFormVisible = visibility && !campusFormVisible;
            },
            setCampusAddFormVisibility: function (visibility) {
                editMode = false;
                if (campusEditFormVisible) {
                    campusEditFormVisible = false;
                    visibility = true;
                }
                campusAddFormVisible = visibility || !campusAddFormVisible;
                campusFormVisible = visibility || !campusFormVisible;
            },
            setCampusEditFormVisibility: function (visibility) {
                editMode = true;
                if (campusAddFormVisible) {
                    campusAddFormVisible = false;
                    visibility = true;
                }
                campusEditFormVisible = visibility || !campusAddFormVisible;
                campusAddFormVisible = false;
                campusFormVisible = visibility || !campusFormVisible;
            },
            getCampus: function () {
                return campus;
            },
            setCampus: function (newData) {
                campus = newData;
            },
            getInstitutionListShowCampusInfoBlock: function () {
                return rootShowCampusInfoBlock;
            },
            setInstitutionListShowCampusInfoBlock: function (showCampusInfoBlock) {
                rootShowCampusInfoBlock = showCampusInfoBlock;
            },
            setColumns: function (setColumns) {
                columns = setColumns;
            },
            getColumns: function () {
                return columns;
            },
            announceCampusesChanges: function (campusId) {
                campusId = campusId || true;
                hasCampusesChanges = campusId;
            },
            hasCampusesChanges: function () {
                var hasChanges = angular.copy(hasCampusesChanges);
                if (hasCampusesChanges) {
                    hasCampusesChanges = false;
                }
                return hasChanges;
            },
            loadInProgress: function () {
                return loadInProgress;
            },
            setLoadInProgress: function (status) {
                loadInProgress = status;
            },
            getId: function () {
                return data.id || '';
            },
            setSelectedTabId: function (newSelectedTabId) {
                selectedTabId = newSelectedTabId;
            },
            hasSelectedTab: function () {
                return selectedTabId !== null;
            },
            isCampusesTabSelected: function () {
                return (typeof selectedTabId !== 'undefined') && (selectedTabId === SharedProfileTabs.campuses);
            },
            isGeneralTabSelected: function () {
                return (typeof selectedTabId !== 'undefined')  && (selectedTabId === SharedProfileTabs.general);
            },
            mapUpdateRequest: function () {
                return mapUpdateRequest;
            },
            setMapUpdateRequest: function (value) {
                mapUpdateRequest = value;
            },
            setLogLimit: function (logLimit) {
                if (!logLimit || logLimit < 1 || typeof logLimit === 'undefined') {
                    logLimit = 1;
                }
                limit = logLimit;
            },
            getLogLimit: function () {
                return limit;
            },
            isLoadMoreInProgress: function () {
                return loadMoreInProgress;
            },
            setLoadMoreInProgress: function (status) {
                loadMoreInProgress = status;
            },
            isUpdateButtonClicked: function () {
                return updateButtonClicked;
            },
            setUpdateButtonClicked: function (value) {
                updateButtonClicked = value;
            }
        };
    };

    App.factories.Institution = function (TimeService) {
        var defaultData = {},
            departmentOverview = {
                institutionId: null,
                data: {}
            },
            data = angular.copy(defaultData),
            defaultCoreId = 700,
            clientDepartmentId = 3,
            advancedProgramId = 7,
            inactive,
            isSubscriptionTabActive = false,
            tuProgramsOverview = {
                institutionId: null
            },
            isProfileAdvanced = function (subscriptions, profile) {
                var isAdvanced = false,
                    subsProfile = profile === 'tu' ? subscriptions.tu : subscriptions.tm;

                if (subsProfile) {
                    isAdvanced = subsProfile.advanced && subsProfile.active;
                    if (!isAdvanced) {
                        var today = TimeService.getMilliSeconds(TimeService.now()),
                            weekLater = TimeService.getMilliSeconds(TimeService.add(7, 'days'));
                        if (subsProfile.startDate > today &&
                            subsProfile.endDate > subsProfile.startDate
                        ) {
                            var result = weekLater - subsProfile.startDate,
                                range = weekLater - today;
                            // Check if date is one week before it will be activated
                            if (result >= 0 && result <= range) {
                                isAdvanced = true;
                            }
                        }
                    }
                }

                return isAdvanced;
            },
            checkProfileCrimsonSubscription = function (subscriptions,subscriptionType) {
                var isAdvanced = false,
                    subsProfile = subscriptions.tu;
                if (subsProfile) {
                    isAdvanced = subscriptionType === 'basic' ? subsProfile.crimsonBasic  : subsProfile.crimsonAdvance;
                }

                return isAdvanced;
            };
        return {
            getData: function () {
                return data;
            },
            setData: function (newData) {
                data = newData;
            },
            resetData: function () {
                data = angular.copy(defaultData);
            },
            hasData: function () {
                return !angular.equals(data, defaultData);
            },
            isDepartmentsAlphabeticalOrder: function () {
                return data && data.departmentsAlphabeticalOrder;
            },
            isEmpty: function () {
                return !data || angular.equals(data, defaultData);
            },
            setCampuses: function (campuses) {
                data.campus = campuses;
            },
            addCampus: function (campus) {
                if (!data) {
                    data = angular.copy(defaultData);
                }
                if (!data.campus) {
                    data.campus = [];
                }
                data.campus.push(campus);
            },
            getCampusData: function () {
                return data && data.campus || [];
            },
            setSubscriptions: function (newData) {
                data.subscriptions = newData;
            },
            getSubscriptions: function () {
                 return data && data.hasOwnProperty('subscriptions') ? data.subscriptions : null;
            },
            getCoreId: function () {
                return data && data.coreId ? parseInt(data.coreId, 10) : null;
            },
            getDisplayName: function () {
                return data && data.name ? data.name : '';
            },
            getCountryCode: function () {
                return data && data.countryCode ? data.countryCode : null;
            },
            getId: function () {
                return data && data.id ? data.id : '';
            },
            getParentCoreId: function () {
                return data && data.parentCoreId ? parseInt(data.parentCoreId, 10) : null;
            },
            getParentName: function () {
                return data && data.parentName ? data.parentName : null;
            },
            getDefaultCoreId: function () {
                return defaultCoreId;
            },
            isTuAdvanced: function () {
                if (data && data.subscriptions) {
                    return isProfileAdvanced(data.subscriptions, 'tu');
                }
                return null;
            },
            isCrimsonAdvanced: function () {
                if (data && data.subscriptions) {
                    return checkProfileCrimsonSubscription(data.subscriptions,'advance');
                }
                return null;
            },
            isCrimsonBasic: function () {
                if (data && data.subscriptions) {
                    return checkProfileCrimsonSubscription(data.subscriptions,'basic');
                }
                return null;
            },
            isBasicProgramsEnabled: function () {
                if (data && data.subscriptions && data.subscriptions.tu) {
                    return data.subscriptions.tu.isBasicProgramsEnabled ? true : false;
                }
                return null;
            },
            isTmAdvanced: function () {
                if (data && data.subscriptions) {
                    return isProfileAdvanced(data.subscriptions, 'tm');
                }
                return null;
            },
            isTuSubscribed: function () {
                return !!(data &&
                    data.subscriptions &&
                    data.subscriptions.tu &&
                    data.subscriptions.tu.subscribed);
            },
            isTuEnabled: function () {
                if (data && data.enabled) {
                    return data.enabled.tu;
                }
                return null;
            },
            isUgEnabled: function () {
                if (data && data.enabled) {
                    return data.enabled.ug;
                }
                return null;
            },
            isPgEnabled: function () {
                //console.log(data.enabled)
                if (data && data.enabled) {
                    return data.enabled.pg;
                }
                return null;
            },
            isUgChinaEnabled: function () {
                if (data && data.enabled) {
                    return data.enabled.ugChina;
                }
                return null;
            },
            isPgChinaEnabled: function () {
                if (data && data.enabled) {
                    return data.enabled.pgChina;
                }
                return null;
            },
            isStarsChinaEnabled: function () {
                if (data && data.enabled) {
                    return data.enabled.starsChina;
                }
                return null;
            },
            isTmSubscribed: function () {
                return data &&
                    data.subscriptions &&
                    data.subscriptions.tm &&
                    data.subscriptions.tm.subscribed;
            },
            isTmEnabled: function () {
                if (data && data.enabled) {
                    return data.enabled.tm;
                }
                return null;
            },
            isClientDepartment: function () {
                return data && parseInt(data.typeId, 10) === clientDepartmentId;
            },
            getClientDepartmentId : function () {
                return clientDepartmentId;
            },
            isAdvancedProgram: function () {
                return data && parseInt(data.typeId, 10) === advancedProgramId;
            },
            hasNoDepartments: function () {
                return data && data.hasNoDepartments ? data.hasNoDepartments : false;
            },
            getCampuses: function () {
                return data && data.campus ? data.campus : [];
            },
            isActive: function () {
                return data ? !!(data.active && (data.deleted === 'undefined' || !data.deleted)) : null;
            },
            getInstitutionIdTuProgramsOverview: function () {
                return tuProgramsOverview.institutionId;
            },
            setInstitutionIdTuProgramsOverview: function (institutionId) {
                tuProgramsOverview.institutionId = institutionId ? institutionId : null;
            },
            setDataDepartmentOverview: function (data) {
                departmentOverview.data = data;
            },
            getDataDepartmentOverview: function () {
                return departmentOverview.data;
            },
            getCampusDataDepartmentOverview: function () {
                return departmentOverview.data && departmentOverview.data.campus || [];
            },
            getInstitutionIdDepartmentOverview: function () {
                return departmentOverview.institutionId;
            },
            setInstitutionIdDepartmentOverview: function (institutionId) {
                departmentOverview.institutionId = institutionId ? institutionId : null;
            },
            resetDepartmentOverview: function () {
                departmentOverview = {
                    institutionId: null,
                    data: {}
                };
            },
            setActiveTab: function(active){
                isSubscriptionTabActive = active;
            },
            getActiveTab: function(){
                return isSubscriptionTabActive;
            }
        };
    };

    App.factories.User = function (DataHandlerFactory) {
        var data = {},
            handles = {
                'clients.profiles.shared': ['profiles.institution_details'],
                'clients.profiles.tu': ['profiles.tu'],
                'clients.profiles.tm': ['profiles.tm'],
                'clients.statistics': ['statistics.statistics'],
                'staff.users.qs': ['users.qs_users'],
                'staff.users.institutions': ['users.schools_users'],
                'staff.institutions.list': ['institutions.list'],
                'staff.institutions.tu-programs' : ['institutions.tu_programs_overview'],
                'staff.institutions.department' : ['institutions.department_overview'],
                'staff.master-password' : ['master_password.master_password'],
                'staff.tm-directory' : ['tm_directory.tm_directory'],
                'staff.iu.rankings-dashboard' : ['admin_statistics.statistics'],
                'clients.statistics.data-submission' : ['statistics.statistics'],
                'staff.iu.rankings-information' : ['admin_statistics.statistics'],
                'staff.iu.nomination' : ['admin_statistics.statistics'],
                'clients.statistics.data-submission.all' : ['statistics.statistics'],
                'clients.statistics.data-submission.aw' : ['statistics.statistics'],
                'clients.statistics.data-submission.aur' : ['statistics.statistics'],
                'clients.statistics.data-submission.brics' : ['statistics.statistics'],
                'clients.statistics.data-submission.eeca' : ['statistics.statistics'],
                'clients.statistics.data-submission.lat' : ['statistics.statistics'],
                'clients.statistics.data-submission.ger' : ['statistics.statistics'],
                'clients.statistics.data-submission.wur' : ['statistics.statistics'],
                'clients.statistics.data-submission.esg' : ['statistics.statistics'],
                'clients.statistics.stars' : ['statistics.statistics'],
                'staff.iu' : ['admin_statistics.statistics'],
                'staff.iu.messages': ['admin_statistics.statistics'],
                'clients.statistics.messages' : ['statistics.statistics'],
                'clients.statistics.nomination' : ['statistics.statistics'],
                'clients.statistics.nominations.upload' : ['statistics.statistics'],
                'clients.statistics.nominations.lists' : ['statistics.statistics'],
                'staff.iu.id-checker' : ['admin_statistics.statistics'],
                'staff.iu.nominations' : ['admin_statistics.statistics'],
                'staff.iu.nominations.reports' : ['admin_statistics.statistics'],
                'staff.iu.nominations.lists' : ['admin_statistics.statistics'],
                'staff.iu.rankings-score': ['admin_statistics.statistics'],
                'staff.iu.classifications-overview': ['admin_statistics.statistics'],
                'staff.iu.classifications-publish': ['admin_statistics.statistics'],
                'staff.qs-stars': [
                    'stars.institutions_list',
                    'stars.badges'
                ],
                'staff.qs-stars.upload.start': ['stars.badges'],
                'staff.qs-stars.institutions-list' : ['stars.institutions_list'],
                'staff.qs-stars.badges-overview' : ['stars.badges'],
                'staff.iu.rankings-html': ['rankings.pr'],
                'staff.members-db.leads-export' : ['members-db.leads-export'],
                'clients.statistics.data-submission.ger.employers-connections' : ['statistics.statistics'],
                'clients.statistics.data-submission.ger.employers-partnerships' : ['statistics.statistics'],
                'clients.statistics.data-submission.ger.employment-rate' : ['statistics.statistics'],
                'clients.statistics.data-submission.ger.faculty-student-data' : ['statistics.statistics'],
                'clients.statistics.data-submission.ger.statistics' : ['statistics.statistics'],
                'staff.igauge': [
                    'igauge.igauge_list',
                    'igauge.igauge_sync'
                ],
                'staff.igauge.igauge_list' : ['igauge.igauge_list'],
                'staff.igauge.igauge_sync' : ['igauge.igauge_sync']
            },
            defaultPages = [
                'user.my-profile'
            ],
            defaultInstitutions = null,
            institutions = angular.copy(defaultInstitutions),
            requestedStateBeforeLogin = null,
            requestedStateParams = null,
            gerAllowedUsersIds = [
                10037, // Chema
                11466, // Drago
                11662, // Rabia
                14159 // Ken
            ],
            subscribedToDataUpdate = {
                'sharedProfile': false,
                'tu': false,
                'tm': false
            };

        function userHasAccessToPage(sections) {
            var has = false;
            angular.forEach(sections, function (section) {
                if (data.accessTo && data.accessTo.indexOf(section) !== -1) {
                    has = true;
                    return;
                }
            });
            return has;
        }

        return {
            getData: function () {
                return data;
            },
            setData: function (newData) {
                data = newData;
            },
            isClient: function () {
                return data && data.isClient ? true : false;
            },
            hasData: function () {
                return !(angular.equals(data, {}) || angular.equals(data, null));
            },
            getFullName: function () {
                return data && data.fullName ? data.fullName : null;
            },
            getFirstName: function () {
                return data && data.firstName ? data.firstName : null;
            },
            getLastName: function () {
                return data && data.lastName ? data.lastName : null;
            },
            getTitle: function () {
                return data && data.title ? data.title : null;
            },
            setFullName: function (fullName) {
                data.fullName = fullName;
            },
            setFirstName: function (firstName) {
                data.firstName = firstName;
            },
            setLastName: function (lastName) {
                data.lastName = lastName;
            },
            setTitle: function (title) {
                data.title = title;
            },
            setPhone: function (phone) {
                data.phone = phone;
            },
            setPosition: function (position) {
                data.position = position;
            },
            getUserName: function () {
                return data && data.userName ? data.userName : null;
            },
            getCoreId: function () {
                return data && data.coreId ? data.coreId : null;
            },
            getProfileLogo: function () {
                return data && data.profileLogo ? data.profileLogo : '';
            },
            setProfileLogo: function (profileLogo) {
                data.profileLogo = profileLogo;
            },
            getPrimaryInstitutionCoreId: function () {
                return data && data.primaryInstitution ? data.primaryInstitution : 0;
            },
            getPosition: function () {
                return data && data.position ? data.position : null;
            },
            isTuEnabled: function () {
                return data && data.accessTo && data.accessTo.indexOf('profiles.tu') !== -1;
            },
            hasTuProgramsOverviewAccess: function () {
                return data && data.accessTo && data.accessTo.indexOf('institutions.tu_programs_overview') !== -1;
            },
            hasDepartmentsOverviewAccess: function () {
                return data && data.accessTo && data.accessTo.indexOf('institutions.department_overview') !== -1;
            },
            hasTmDirectoryAccess: function () {
                return data && data.accessTo && data.accessTo.indexOf('tm_directory.tm_directory') !== -1;
            },
            hasMasterPasswordAccess: function () {
                return data && data.accessTo && data.accessTo.indexOf('master_password.master_password') !== -1;
            },
            hasContactsAccess: function () {
                return data && data.accessTo && data.accessTo.indexOf('users.schools_users') !== -1;
            },
            hasSharedProfileAccess: function () {
                return data && data.accessTo && data.accessTo.indexOf('profiles.institution_details') !== -1;
            },
            hasTuProfileAccess: function () {
                return data && data.accessTo && data.accessTo.indexOf('profiles.tu') !== -1;
            },
            hasTmProfileAccess: function () {
                return data && data.accessTo && data.accessTo.indexOf('profiles.tm') !== -1;
            },
            hasInstitutionsListAccess: function () {
                return data && data.accessTo && data.accessTo.indexOf('institutions.list') !== -1;
            },
            hasQsUsersAccess: function () {
                return data && data.accessTo && data.accessTo.indexOf('users.qs_users') !== -1;
            },
            hasStatisticsAccess: function () {
                return data && data.accessTo && data.accessTo.indexOf('statistics.statistics') !== -1;
            },
            hasClientStarsAccess: function () {
                return data && data.accessTo && data.accessTo.indexOf('statistics.statistics') !== -1;
            },
            hasStarsListAccess: function () {
                return userHasAccessToPage(this.getHandles()['staff.qs-stars.institutions-list']);
            },
            hasLeadsExportAccess: function () {
                return userHasAccessToPage(this.getHandles()['staff.members-db.leads-export']);
            },
            hasIgaugeListAccess: function () {
                return data && data.accessTo && data.accessTo.indexOf('igauge.igauge_list') !== -1;
            },
            hasIgaugeSyncAccess: function () {
                return data && data.accessTo && data.accessTo.indexOf('igauge.igauge_sync') !== -1;
            },
            hasPRAccess: function () {
                return userHasAccessToPage(this.getHandles()['staff.iu.rankings-html']);
            },
            hasBadgesAndDocumentsAccess: function () {
                return userHasAccessToPage(this.getHandles()['staff.qs-stars.upload.start']);
            },
            hasBadgesOverviewAccess: function () {
                return userHasAccessToPage(this.getHandles()['staff.qs-stars.badges-overview']);
            },
            hasIuAccess: function () {
                return data && data.accessTo && data.accessTo.indexOf('admin_statistics.statistics') !== -1;
            },
            hasInstitutionsUsersAccess: function () {
                return data && data.accessTo && data.accessTo.indexOf('users.schools_users') !== -1;
            },
            hasAdminDashboardAccess: function () {
                return data && data.accessTo && data.accessTo.indexOf('admin_dashboard.dashboard') !== -1;
            },
            isTmEnabled: function () {
                return data && data.accessTo && data.accessTo.indexOf('profiles.tm') !== -1;
            },
            getAllowedInstitutions: function () {
                return data && data.institutions ? data.institutions : [];
            },
            isAllowedToLogin: function (institutionCoreId) {
                var allowed = this.getAllowedInstitutions();
                if (this.getPrimaryInstitutionCoreId() && allowed.indexOf(parseInt(institutionCoreId, 10)) === -1) {
                    allowed.push(parseInt(this.getPrimaryInstitutionCoreId(), 10));
                }

                return allowed.indexOf(parseInt(institutionCoreId, 10)) !== -1;
            },
            isStarsAnalyst: function () {
                return data &&
                    data.roles &&
                    data.roles.indexOf('iu.stars_analyst') !== -1 &&
                    data.roles.indexOf('iu.stars_admin') === -1 &&
                    data.roles.indexOf('global_admin.global_admin') === -1;
            },
            isStarsAdmin: function () {
                return data && data.roles && 
                    (data.roles.indexOf('iu.stars_admin') !== -1 ||
                    data.roles.indexOf('global_admin.global_admin') !== -1);
            },
            hasAccess: function () {
                return data && data.accessTo && angular.isObject(data.accessTo) && !angular.equals({}, data.accessTo) &&
                    !angular.equals([], data.accessTo);
            },
            noAccess: function (page) {
                if (page && page.indexOf('clients.profiles.tu') !== -1) {
                    page = 'clients.profiles.tu';
                }
                if (page && page.indexOf('clients.profiles.tm') !== -1) {
                    page = 'clients.profiles.tm';
                }
                if (page && page.indexOf('clients.profiles.shared') !== -1) {
                    page = 'clients.profiles.shared';
                }

                return this.isClient() && defaultPages.indexOf(page) === -1 &&
                    !userHasAccessToPage(this.getHandles()[page]);
            },
            hasAccessToInstitutionDetails: function () {
                return data && data.accessTo && data.accessTo.indexOf('profiles.institution_details') !== -1;
            },
            getFirstAllowedState: function (subscriptionsPrimaryInstitution) {
                var firstAllowedState = 'logout';
                if (this.hasAccess()) {
                    // search for state name
                    /**
                     * @todo remove when pages are implemented as it's a temporary fix
                     */
                    var notImplementedAccessTo = [
                        "dashboard.dashboard",
                        "contact_us.contact_us",
                        "faq.faq",
                        "messages.messages"
                    ];
                    // Exclude not implemented areas
                    var result = DataHandlerFactory.getDiffArray(data.accessTo, notImplementedAccessTo).sort(),
                        bestStateForUser = this.getBestStateForUser(subscriptionsPrimaryInstitution, result);

                    angular.forEach(handles, function (sections, key) {
                        angular.forEach (sections, function (section) {
                            if (angular.equals(bestStateForUser, section) &&
                                key.indexOf("staff") === -1
                            ) {
                                firstAllowedState = key;
                                return;
                            }
                        });
                    });
                }

                return firstAllowedState;
            },
            getBestStateForUser: function (subscriptions, states) {
                var hasTu = false,
                    hasTm = false;
                if (subscriptions) {
                    hasTu = (angular.isDefined(subscriptions.tu) &&
                                 subscriptions.tu.active);
                    hasTm = (angular.isDefined(subscriptions.tm) &&
                                 subscriptions.tm.active);
                }

                        for (var i = 0; i < states.length; i++) {
                            if (states[i].indexOf('tu') !== -1 && hasTu) {
                                return states[i];
                            } else if (states[i].indexOf('tm') !== -1 && hasTm) {
                                return states[i];
                            }
                        }
                        return states[0];
            },
            getHandles: function () {
                return handles;
            },
            isPasswordReset: function () {
                return !!(data && data.passwordReset);
            },
            clearPasswordReset: function () {
                data.passwordReset = false;
            },
            getInstitutions: function () {
                return institutions;
            },
            hasInstitutions: function () {
                return institutions !== defaultInstitutions;
            },
            setInstitutions: function (data) {
                institutions = data;
            },
            setRequestedStateBeforeLogin: function (state) {
                requestedStateBeforeLogin = state;
            },
            getRequestedStateBeforeLogin: function () {
               return requestedStateBeforeLogin;
            },
            setRequestedStateParams: function (params) {
                requestedStateParams = params;
            },
            getRequestedStateParams: function () {
               return requestedStateParams;
            },
            getGerAllowedUsersIds: function () {
                return gerAllowedUsersIds;
            },
            getSubscribedToDataUpdateSharedProfile: function () {
                return subscribedToDataUpdate.sharedProfile;
            },
            setSubscribedToDataUpdateSharedProfile: function (value) {
                subscribedToDataUpdate.sharedProfile = value;
            },
            getSubscribedToDataUpdateTu: function () {
                return subscribedToDataUpdate.tu;
            },
            setSubscribedToDataUpdateTu: function (value) {
                subscribedToDataUpdate.tu = value;
            },
            isGlobalAdmin: function () {
                return data &&
                    data.roles &&
                    data.roles.indexOf('global_admin.global_admin') !== -1;
            },
        };
    };

    App.factories.TuProfileHistoryLog = function () {
        var data = {},
            limit = 1,
            profileType = null,
            inProgress = false,
            loadMoreInProgress = false,
            visible = false,
            reload = false,
            isAdvanced = false,
            triggeredBy = null;

        return {
            getData: function () {
                return data;
            },
            setData: function (newData) {
                data = newData;
            },
            setLogLimit: function (logLimit) {
                if (!logLimit || logLimit < 1 || typeof logLimit === 'undefined') {
                    logLimit = 1;
                }
                limit = logLimit;
            },
            getLogLimit: function () {
                return limit;
            },
            setProfileType: function (newProfileType) {
                profileType = newProfileType;
            },
            getProfileType: function () {
                return profileType;
            },
            isInProgress: function () {
                return inProgress;
            },
            setInProgress: function (status) {
                inProgress = status;
            },
            isLoadMoreInProgress: function () {
                return loadMoreInProgress;
            },
            setLoadMoreInProgress: function (status) {
                loadMoreInProgress = status;
            },
            isVisible: function () {
                return visible;
            },
            setTriggeredBy: function (trigger) {
                triggeredBy = trigger;
            },
            shouldBeVisible: function (trigger) {
                if (triggeredBy === trigger) {
                    triggeredBy = null;
                    return false;
                }
                return true;
            },
            resetTriggeredBy: function () {
                triggeredBy = '';
            },
            setAdvanced: function (advanced) {
                isAdvanced = advanced;
            },
            isAdvanced: function () {
                return isAdvanced;
            },
            setReload: function(triggerReload) {
                reload = triggerReload;
            },
            isReloadRequired: function() {
                return reload;
            }
        };
    };

    App.factories.MapInitializer = function ($window, $q) {
        var loaded = false;
        // maps loader deferred object
        var mapsDefer = $q.defer();

        // Google's url for async maps initialization accepting callback function
        var asyncUrl = 'https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyDN-E7GkRH050mItjaG6s2XIsDmi1OUsYg&callback=';

        // async loader
        var asyncLoad = function (asyncUrl, callbackName) {
            if (!loaded) {
                var script = document.createElement('script');
                //script.type = 'text/javascript';
                script.src = asyncUrl + callbackName;
                document.body.appendChild(script);
            }
        };

        // callback function - resolving promise after maps successfully loaded
        $window.googleMapsInitialized = function () {
            mapsDefer.resolve();
            loaded = true;
        };

        return {
            // usage: Initializer.mapsInitialized.then(callback)
            initStart: function () {
                asyncLoad(asyncUrl, 'googleMapsInitialized');
            },
            mapsInitialized: mapsDefer.promise
        };
    };

    App.factories.DataHandler = function () {
        return {
            objectToArray: function (obj) {
                var result = obj;
                if (!angular.isArray(obj)) {
                    result = [];
                    angular.forEach(obj, function (val) {
                        result.push(val);
                    });
                }

                return result;
            },
            getDiffArray: function(array1, array2) {
                return array1.filter(function(value) {
                    for (var i = 0; i <= array2.length; i++) {
                        if (value === array2[i]) {
                            return false;
                        }
                    }
                    return true;
                });
            }
        };
    };

    angular
        .module('qsHub')
        .factory('NotifierFactory', ['toaster', App.factories.Notifier])
        .factory('InstitutionFactory', ['TimeService', App.factories.Institution])
        .factory('UserFactory', ['DataHandlerFactory', App.factories.User])
        .factory('TuProfileFactory', [App.factories.TuProfile])
        .factory('SharedProfileFactory', [App.factories.SharedProfile])
        .factory('TuProfileHistoryLogFactory', [App.factories.TuProfileHistoryLog])
        .factory('MapInitializerFactory', ['$window', '$q', App.factories.MapInitializer])
        .factory('DataHandlerFactory', [App.factories.DataHandler]);

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    App.services.InstitutionsService = function ($resource, $log, constants, RequestsQueueService) {
        var service = {};

        /**
         * Get institutions list API endpoint.
         *
         * @returns {$resource}
         */
        service.getInstitutionListModel = function () {
            return $resource(constants.api.institutions.url + '/v1/list/all/:id', null, {
                get: {
                    isArray:false,
                    cancellable : true
                }
            });
        };

        service.getInstitutionData = function (coreId, columnsToFetch, exactMatch) {
            if (!coreId) {
                return false;
            }
            coreId = parseInt(coreId, 10);
            if (exactMatch) {
                coreId = "=" + coreId;
            }
            var queueName = 'getInstitutionData',
                filters = {
                    'filter[coreId]': coreId,
                    limit: 1
                };
            if (columnsToFetch && columnsToFetch.length) {
                filters['columns[]'] = columnsToFetch;
            }
            queueName += angular.toJson(filters);
            // cancel currently running requests
            RequestsQueueService.cancelAll(queueName);

            var Api = service.getInstitutionListModel().get(filters);
            //console.log('getInstitutionData', coreId, columnsToFetch, new Date(),  new Error().stack);
            // add new request to the queue
            RequestsQueueService.add(Api, queueName);

            return Api.$promise.then(function(data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return data;
            }, function(error) {
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return [];
            });
        };

        return service;
    };

    /**
     * Requests queues.
     *
     * @constructor
     */
    App.services.RequestsQueue = function () {
        var queue = [],
            defaultQueue = 'default';

        /**
         * Add request to the queue.
         *
         * @param {Object} request
         * @param {null|String} queueName
         */
        this.add = function(request, queueName) {
            queueName = queueName || defaultQueue;
            if (!queue[queueName]) {
                queue[queueName] = [];
            }
            queue[queueName].push(request);
        };

        /**
         * Cancel queue requests.
         *
         * @param {null|String} queueName
         * @returns {boolean}
         */
        this.cancelAll = function (queueName) {
            queueName = queueName || defaultQueue;
            if (!queue[queueName]) {
                return false;
            }
            queue[queueName].forEach(function (request) {
                request.$cancelRequest();
            });
            delete queue[queueName];
        };
    };

    angular
        .module('qsHub')
        .service('InstitutionsService', [
            '$resource',
            '$log',
            'constants',
            'RequestsQueueService',
            App.services.InstitutionsService
        ])
        .service('RequestsQueueService', [
            App.services.RequestsQueue
        ]);
}(window.angular));

(function(angular) {
'use strict';
angular.module('qsHub').constant('version', '1.19');
}(window.angular));
(function(angular) {
    'use strict';
    angular.module('qsHub').constant(
        'CROSS_DOMAIN_IFRAME_URL',
        '/bower_components/xdLocalStorage/app/views/cross-domain-local-storage.html'
    );
}(window.angular));

(function(angular) {
'use strict';
angular.module('qsHubTemplates', []).run([
'$templateCache',
function($templateCache) {
  

  $templateCache.put('/scripts/components/contacts/contactsView.html',
    "<div class=\"page-contacts\" ng-controller=\"ContactsListController as ContactsListController\">\n" +
    "    <div ng-include src=\"'/scripts/components/contacts/institutionsUsersListCloneView.html'\"></div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/components/contacts/institutionsUsersListCloneView.html',
    "<div class=\"wrapper wrapper-content animated fadeInRight page-institutions-users-list\" ng-controller=\"InstitutionsUsersListCloneController as UsersList\">\n" +
    "    <div class=\"alert alert-info\">\n" +
    "        <p>\n" +
    "            <i class=\"fa fa-info-circle\"></i>\n" +
    "            <span>QS Staff have access to this section only</span>\n" +
    "            <span ng-if=\"!UsersList.hasWriteAccess\">. You don't have permissions to make changes.</span>\n" +
    "        </p>\n" +
    "    </div>\n" +
    "    <p>\n" +
    "        <button type=\"button\" class=\"btn btn-primary\"\n" +
    "                ng-click=\"UsersList.handleAddInstitutionsUserClick()\"\n" +
    "                ng-disabled=\"!UsersList.hasWriteAccess\">\n" +
    "            <i class=\"fa fa-user-plus\"></i>\n" +
    "            Add User\n" +
    "        </button>\n" +
    "    </p>\n" +
    "    <div class=\"row\">\n" +
    "        <div id=\"schoolUsersTable\" ng-class=\"showInfoBlock ? 'col-sm-4 col-md-3 hide-ng-table-pager' : 'col-sm-12'\">\n" +
    "            <div class=\"ibox float-e-margins\">\n" +
    "                <div class=\"ibox-content table-responsive\">\n" +
    "                    <div wave-spinner class=\"wave-spinner\" ng-show=\"UsersList.isDatagridReloading || !UsersList.isDatagridRendered\"></div>\n" +
    "\n" +
    "                    <div class=\"full-width scroll-horizontal hide-vertical-overflow\">\n" +
    "                        <div ng-if=\"UsersList.showDatagrid\" ng-class=\"{'modal-overlay-35': UsersList.isDatagridReloading}\">\n" +
    "                            <ui-grid-info ng-if=\"!showInfoBlock\"></ui-grid-info>\n" +
    "                            <div class=\"grid\"\n" +
    "                                 ui-grid=\"UsersList.grid.options\"\n" +
    "                                 ui-grid-pagination\n" +
    "                                 ui-grid-selection\n" +
    "                                 ui-grid-resize-columns\n" +
    "                                 ui-grid-auto-resize></div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div fixed-element-while-scrolling=\"#schoolUsersTable\" ng-show=\"showInfoBlock\" class=\"col-sm-8 col-md-9 scroll-floating-element right-side-container\">\n" +
    "            <div class=\"ibox block-user\">\n" +
    "                <div class=\"ibox-content clearfix\">\n" +
    "                    <div class=\"ibox-tools\">\n" +
    "                        <a class=\"close-link\" ng-click=\"UsersList.handleEditCloseClick()\">\n" +
    "                            <i class=\"fa fa-times\"></i>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "\n" +
    "                    <div class=\"tab-content\">\n" +
    "                        <div class=\"tab-pane active\">\n" +
    "\n" +
    "                            <div class=\"row m-b-lg\" ng-show=\"user.id\">\n" +
    "                                <div class=\"col-lg-4 text-center\">\n" +
    "                                    <div class=\"m-b-sm\">\n" +
    "                                        <img alt=\"image\" class=\"img-circle\" gravatar-src=\"user.email\" gravatar-size=\"100\">\n" +
    "                                    </div>\n" +
    "                                    <div class=\"m-b-sm\">\n" +
    "                                    </div>\n" +
    "                                </div>\n" +
    "                                <div class=\"col-lg-8\">\n" +
    "                                    <h2>\n" +
    "                                        {{user.fullname}}\n" +
    "                                    </h2>\n" +
    "\n" +
    "                                    <br class=\"visibal-sm\">\n" +
    "\n" +
    "                                    <a href=\"mailto:{{user.email}}\" class=\"btn btn-primary btn-sm\" ng-class=\"{disabled: !UsersList.hasWriteAccess}\">\n" +
    "                                        <i class=\"fa fa-envelope\"></i> Send Email\n" +
    "                                    </a>\n" +
    "                                    <a class=\"btn btn-warning btn-sm\"\n" +
    "                                       ng-show=\"!user.active\"\n" +
    "                                       ng-click=\"handleActivateClick(user)\"\n" +
    "                                       ng-class=\"{'disabled':activateInProgress || !UsersList.hasWriteAccess}\">\n" +
    "                                        <i class=\"fa fa-undo\"></i> Activate\n" +
    "                                    </a>\n" +
    "                                    <a class=\"btn btn-danger btn-sm\"\n" +
    "                                       ng-show=\"user.active\"\n" +
    "                                       ng-click=\"handleDeactivateClick(user)\"\n" +
    "                                       ng-class=\"{'disabled':deactivateInProgress || !UsersList.hasWriteAccess}\">\n" +
    "                                        <i class=\"fa fa-ban\"></i> Deactivate\n" +
    "                                    </a>\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "\n" +
    "                            <div class=\"client-detail\">\n" +
    "                                <div class=\"full-height-scroll\">\n" +
    "                                    <div class=\"row\">\n" +
    "                                        <div class=\"col-lg-12\">\n" +
    "                                            <div class=\"tabs-container\">\n" +
    "                                                <uib-tabset active=\"activeTab\">\n" +
    "                                                    <uib-tab heading=\"Personal details\" disable=\"disabledInstitutionsUserListSubTabs['personalDetails']\">\n" +
    "                                                        <div ng-include src=\"'/scripts/components/users/list/institutions/personal-details/institutionsUsersListPersonalDetailsView.html'\"></div>\n" +
    "                                                    </uib-tab>\n" +
    "                                                    <uib-tab heading=\"Permissions\" disable=\"disabledInstitutionsUserListSubTabs['permissions']\">\n" +
    "                                                        <div ng-include src=\"'/scripts/components/users/list/institutions/permissions/institutionsUsersListPermissionsView.html'\"></div>\n" +
    "                                                    </uib-tab>\n" +
    "                                                    <uib-tab heading=\"Institutions\" disable=\"disabledInstitutionsUserListSubTabs['institutions']\">\n" +
    "                                                        <div ng-include src=\"'/scripts/components/users/list/institutions/institutions/institutionsUsersListInstitutionsView.html'\"></div>\n" +
    "                                                    </uib-tab>\n" +
    "                                                </uib-tabset>\n" +
    "                                            </div>\n" +
    "                                        </div>\n" +
    "                                    </div>\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <pre ng-show=\"UsersList.devMode\">{{user|json}}</pre>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/dashboard/admin/dashboardAdminView.html',
    "<div class=\"admin-dashboard wrapper wrapper-content\" ng-controller=\"AdminDashboardController as AdminDashboard\">\n" +
    "    <div class=\"row\">\n" +
    "        <div class=\"col-lg-3\">\n" +
    "            <div class=\"widget style1 navy-bg\">\n" +
    "                <div class=\"row\">\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                        <i class=\"fa fa-user fa-5x\"></i>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-sm-8 text-right\">\n" +
    "                        <span> QS Users </span>\n" +
    "                        <h2 class=\"font-bold\">{{AdminDashboard.totalQsUsers}}</h2>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"col-lg-3\">\n" +
    "            <div class=\"widget style1 navy-bg\">\n" +
    "                <div class=\"row\">\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                        <i class=\"fa fa-user fa-5x\"></i>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-sm-8 text-right\">\n" +
    "                        <span> Institution Users </span>\n" +
    "                        <h2 class=\"font-bold\">{{AdminDashboard.totalSchoolUsers}}</h2>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"col-lg-3\">\n" +
    "            <div class=\"widget style1 lazur-bg\">\n" +
    "                <div class=\"row\">\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                        <i class=\"fa fa-bank fa-5x\"></i>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-sm-8 text-right\">\n" +
    "                        <span> Institutions </span>\n" +
    "                        <h2 class=\"font-bold\">{{AdminDashboard.totalSchools}}</h2>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"col-lg-3\">\n" +
    "            <div class=\"widget style1 yellow-bg\">\n" +
    "                <div class=\"row\">\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                        <i class=\"fa fa-star fa-5x\"></i>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-sm-8 text-right\">\n" +
    "                        <span> QS Stars Institutions </span>\n" +
    "                        <h2 class=\"font-bold\">{{AdminDashboard.totalStarsSchools}}</h2>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row\">\n" +
    "        <div class=\"ibox-content\" ng-repeat=\"item in AdminDashboard.institutionsByTypes\">\n" +
    "            <div>\n" +
    "                <span>{{item.key}}</span>\n" +
    "                <small class=\"float-right\">{{item.value}}/{{AdminDashboard.totalSchools}}</small>\n" +
    "            </div>\n" +
    "            <div class=\"progress progress-small\">\n" +
    "                    <div ng-style=\"{'width': item.percent}\" class=\"progress-bar\"></div>\n" +
    "                </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row\">\n" +
    "        <div ng-repeat=\"item in AdminDashboard.totalTuProgramsByType\">\n" +
    "            <div class=\"col-lg-2\">\n" +
    "                <div class=\"widget style1 navy-bg\">\n" +
    "                    <div class=\"row\">\n" +
    "                        <div class=\"col-sm-4\">\n" +
    "                            <i class=\"fa fa-user-secret fa-3x\"></i>\n" +
    "                        </div>\n" +
    "                        <div class=\"col-sm-8 text-right\">\n" +
    "                            <span> {{item.key}} </span>\n" +
    "                            <h3 class=\"font-bold\">{{item.value}}</h3>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"col-lg-2\">\n" +
    "            <div class=\"widget style1 lazur-bg\">\n" +
    "                <div class=\"row\">\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                        <i class=\"fa fa-book fa-3x\"></i>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-sm-8 text-right\">\n" +
    "                        <span> TM Programs </span>\n" +
    "                        <h3 class=\"font-bold\">{{AdminDashboard.totalTmPrograms}}</h3>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"col-lg-2\">\n" +
    "            <div class=\"widget style1 lazur-bg\">\n" +
    "                <div class=\"row\">\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                        <i class=\"fa fa-building fa-3x\"></i>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-sm-8 text-right\">\n" +
    "                        <span> Departments</span>\n" +
    "                        <h3 class=\"font-bold\">{{AdminDashboard.totalDepartments}}</h3>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"col-lg-2\">\n" +
    "            <div class=\"widget style1 yellow-bg\">\n" +
    "                <div class=\"row\">\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                        <i class=\"fa fa-search fa-3x\"></i>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-sm-8 text-right\">\n" +
    "                        <span> MT Courses </span>\n" +
    "                        <h3 class=\"font-bold\">{{AdminDashboard.totalCourses}}</h3>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row\">\n" +
    "        <div class=\"col-lg-6\">\n" +
    "            <div class=\"ibox\">\n" +
    "                <div class=\"ibox-title\">\n" +
    "                    <h5>You have access to the following institutions</h5>\n" +
    "                    <div class=\"ibox-tools\">\n" +
    "                        <a ng-click=\"showHide()\" class=\"collapse-link\">\n" +
    "                            <i class=\"fa fa-chevron-up\"></i>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"ibox-content\">\n" +
    "                    <ul class=\"list-group clear-list m-t\">\n" +
    "                        <li class=\"list-group-item fist-item\">\n" +
    "                            All institutions\n" +
    "                        </li>\n" +
    "                    </ul>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"col-lg-6\">\n" +
    "            <div class=\"ibox\">\n" +
    "                <div class=\"ibox-title\">\n" +
    "                    <h5>Your roles are</h5>\n" +
    "                    <div class=\"ibox-tools\">\n" +
    "                        <a ng-click=\"showHide()\" class=\"collapse-link\">\n" +
    "                            <i class=\"fa fa-chevron-up\"></i>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"ibox-content\">\n" +
    "                    <ul class=\"list-group clear-list m-t\">\n" +
    "                        <li ng-if=\"!userRoles.length\" class=\"list-group-item fist-item\">\n" +
    "                            None\n" +
    "                        </li>\n" +
    "                        <li ng-if=\"isCustom\" class=\"list-group-item fist-item\">\n" +
    "                            Custom Permissions\n" +
    "                        </li>\n" +
    "                        <li ng-if=\"userRoles.length && !isCustom\" class=\"list-group-item\" ng-class=\"{'fist-item': key === 0}\" ng-repeat=\"(key, role) in userRoles\">\n" +
    "                            {{role}}\n" +
    "                        </li>\n" +
    "                    </ul>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "   \n" +
    "    <div class=\"row\">\n" +
    "        <div class=\"col-lg-4\">\n" +
    "            <div class=\"ibox\">\n" +
    "                <div class=\"ibox-title\">\n" +
    "                    <h5>Last 5 QS Logins</h5>\n" +
    "                    <div class=\"ibox-tools\">\n" +
    "                        <a ng-click=\"showHide()\" class=\"collapse-link\">\n" +
    "                            <i class=\"fa fa-chevron-up\"></i>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"ibox-content\">\n" +
    "                    <div class=\"feed-activity-list\">\n" +
    "                        <div class=\"feed-element\" ng-repeat=\"item in AdminDashboard.qsLastLogins\">\n" +
    "                            <img alt=\"image\" class=\"rounded-circle\" ng-show=\"!item.profileLogo\" class=\"img-circle\" gravatar-src=\"item.email\" gravatar-size=\"38\">\n" +
    "                            <img alt=\"image\" class=\"rounded-circle\" ng-show=\"item.profileLogo\" class=\"img-circle\" ng-src=\"{{item.profileLogo}}\" width=\"38px\" height=\"38px\">\n" +
    "                            <div class=\"media-body \">\n" +
    "                                <small class=\"float-right text-navy\"><i class=\"fa fa-clock-o\"></i> {{AdminDashboard.getTimeAgo(item.lastLoginAt)}}</small>\n" +
    "                                <strong> {{ item.fullname }}</strong><br>\n" +
    "                                <small class=\"text-muted\">{{ item.lastLoginAt | date:'shortTime' }} - {{ item.lastLoginAt | date }}</small>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"ibox\">\n" +
    "                <div class=\"ibox-title\">\n" +
    "                    <h5>Last 5 Updated Institutions</h5>\n" +
    "                    <div class=\"ibox-tools\">\n" +
    "                        <a ng-click=\"showHide()\" class=\"collapse-link\">\n" +
    "                            <i class=\"fa fa-chevron-up\"></i>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"ibox-content\">\n" +
    "                        <div class=\"row institution-detail\" ng-repeat=\"item in AdminDashboard.lastUpdatedInstitutions\">\n" +
    "                            <div class=\"col-sm-3\">\n" +
    "                                <img alt=\"image\" class=\"rounded-circle\" src=\"https://upload.wikimedia.org/wikipedia/en/thumb/2/29/Harvard_shield_wreath.svg/270px-Harvard_shield_wreath.svg.png\">\n" +
    "                            </div>\n" +
    "                            <div class=\"col-sm-7 detail\">\n" +
    "                                <strong>{{ item.name }}</strong>\n" +
    "                                <div class=\"agile-detail\">\n" +
    "                                    <small class=\"text-muted\"> by {{ item.modifiedByFullName }}</small><br>\n" +
    "                                    <small class=\"text-muted\">{{ item.modifiedAt | date:'shortTime' }} - {{ item.modifiedAt | date }}</small>\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"ibox\">\n" +
    "                <div class=\"ibox-title\">\n" +
    "                    <h5>New MT Courses Per Month</h5>\n" +
    "                    <div class=\"ibox-tools\">\n" +
    "                        <a ng-click=\"showHide()\" class=\"collapse-link\">\n" +
    "                            <i class=\"fa fa-chevron-up\"></i>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"ibox-content\">\n" +
    "                    <div ng-show=\"!AdminDashboard.coursesPerMonth\" wave-spinner class=\"wave-spinner\"></div>\n" +
    "                    <canvas ng-show=\"AdminDashboard.coursesPerMonth\" class=\"chart chart-line\" height=\"140\"\n" +
    "                            chart-data=\"AdminDashboard.coursesPerMonth.data\"\n" +
    "                            chart-labels=\"AdminDashboard.monthsNames\"\n" +
    "                            chart-series=\"AdminDashboard.coursesPerMonth.series\">\n" +
    "                    </canvas>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"col-lg-4\">\n" +
    "            <div class=\"ibox\">\n" +
    "                    <div class=\"ibox-title\">\n" +
    "                        <h5>Last 5 Clients Logins</h5>\n" +
    "                        <div class=\"ibox-tools\">\n" +
    "                            <a ng-click=\"showHide()\" class=\"collapse-link\">\n" +
    "                                <i class=\"fa fa-chevron-up\"></i>\n" +
    "                            </a>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"ibox-content\">\n" +
    "                        <div class=\"feed-activity-list\">\n" +
    "                            <div class=\"feed-element\" ng-repeat=\"item in AdminDashboard.clientLastLogins\">\n" +
    "                                <img alt=\"image\" class=\"rounded-circle\" ng-show=\"!item.profileLogo\" class=\"img-circle\" gravatar-src=\"item.email\" gravatar-size=\"38\">\n" +
    "                                <img alt=\"image\" class=\"rounded-circle\" ng-show=\"item.profileLogo\" class=\"img-circle\" ng-src=\"{{item.profileLogo}}\" width=\"38px\" height=\"38px\">\n" +
    "                                <div class=\"media-body \">\n" +
    "                                    <small class=\"float-right text-navy\"><i class=\"fa fa-clock-o\"></i>{{AdminDashboard.getTimeAgo(item.lastLoginAt)}}</small>\n" +
    "                                    <strong> {{ item.fullname }}</strong> with primary institution <br><strong>{{ item.primaryInstitutionName }}</strong><br>\n" +
    "                                    <small class=\"text-muted\">{{ item.lastLoginAt | date:'shortTime' }} - {{ item.lastLoginAt | date }}</small>\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "            </div>\n" +
    "            <div class=\"ibox\">\n" +
    "                <div class=\"ibox-title\">\n" +
    "                    <h5>New Users Per Month</h5>\n" +
    "                    <div class=\"ibox-tools\">\n" +
    "                        <a ng-click=\"showHide()\" class=\"collapse-link\">\n" +
    "                            <i class=\"fa fa-chevron-up\"></i>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"ibox-content\">\n" +
    "                    <div ng-show=\"!AdminDashboard.usersPerMonth\" wave-spinner class=\"wave-spinner\"></div>\n" +
    "                    <canvas ng-show=\"AdminDashboard.usersPerMonth\" class=\"chart chart-line\" height=\"140\"\n" +
    "                            chart-data=\"AdminDashboard.usersPerMonth.data\"\n" +
    "                            chart-labels=\"AdminDashboard.monthsNames\"\n" +
    "                            chart-series=\"AdminDashboard.usersPerMonth.series\">\n" +
    "                    </canvas>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"ibox\">\n" +
    "                <div class=\"ibox-title\">\n" +
    "                    <h5>Messages Per Month</h5>\n" +
    "                    <div class=\"ibox-tools\">\n" +
    "                        <a ng-click=\"showHide()\" class=\"collapse-link\">\n" +
    "                            <i class=\"fa fa-chevron-up\"></i>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"ibox-content\">\n" +
    "                    <div ng-show=\"!AdminDashboard.messagesPerMonth\" wave-spinner class=\"wave-spinner\"></div>\n" +
    "                    <canvas ng-show=\"AdminDashboard.messagesPerMonth\" class=\"chart chart-line\" height=\"140\"\n" +
    "                            chart-data=\"AdminDashboard.messagesPerMonth.data\"\n" +
    "                            chart-labels=\"AdminDashboard.monthsNames\"\n" +
    "                            chart-series=\"AdminDashboard.messagesPerMonth.series\">\n" +
    "                    </canvas>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"ibox\">\n" +
    "                <div class=\"ibox-title\">\n" +
    "                    <h5>Logins Per Month</h5>\n" +
    "                    <div class=\"ibox-tools\">\n" +
    "                        <a ng-click=\"showHide()\" class=\"collapse-link\">\n" +
    "                            <i class=\"fa fa-chevron-up\"></i>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"ibox-content\">\n" +
    "                    <div ng-show=\"!AdminDashboard.loginsPerMonth\" wave-spinner class=\"wave-spinner\"></div>\n" +
    "                    <canvas ng-show=\"AdminDashboard.loginsPerMonth\" class=\"chart chart-line\" height=\"140\"\n" +
    "                            chart-data=\"AdminDashboard.loginsPerMonth.data\"\n" +
    "                            chart-labels=\"AdminDashboard.monthsNames\"\n" +
    "                            chart-series=\"AdminDashboard.loginsPerMonth.series\">\n" +
    "                    </canvas>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"col-lg-4\">\n" +
    "            <div class=\"ibox\">\n" +
    "                    <div class=\"ibox-title\">\n" +
    "                        <h5>Last 5 Profiles Publishes</h5>\n" +
    "                        <div class=\"ibox-tools\">\n" +
    "                            <a ng-click=\"showHide()\" class=\"collapse-link\">\n" +
    "                                <i class=\"fa fa-chevron-up\"></i>\n" +
    "                            </a>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"ibox-content inspinia-timeline\">\n" +
    "                        <div class=\"timeline-item\" ng-repeat=\"item in AdminDashboard.lastestPublishes\">\n" +
    "                            <div class=\"row\">\n" +
    "                                <div class=\"col-sm-5 date\">\n" +
    "                                    <i ng-if=\"item.type==='pg'\" class=\"fa fa-graduation-cap bg-warning\"></i>\n" +
    "                                    <i ng-if=\"item.type==='ug'\" class=\"fa fa-university bg-info\"></i>\n" +
    "                                    <i ng-if=\"item.type==='master' || item.type==='tm'\" class=\"fa fa-book bg-primary\"></i>\n" +
    "                                    {{ item.createdAt | date:'shortTime' }}\n" +
    "                                    <br>\n" +
    "                                    \n" +
    "                                    <small class=\"text-navy\"> {{ item.createdAt | date }}</small>\n" +
    "                                </div>\n" +
    "                                <div class=\"col-sm-7 content no-top-border\">\n" +
    "                                    <p class=\"m-b-xs\">\n" +
    "                                        <strong>\n" +
    "                                            <span ng-if=\"item.type==='pg' || item.type==='ug' || item.type==='master'\"> TU </span>\n" +
    "                                            <span ng-if=\"item.type==='tm'\">TM</span> | {{ item.name }}\n" +
    "                                        </strong>\n" +
    "                                    </p>\n" +
    "                                    <small class=\"text-muted\">by {{ item.createdByFullName }}</small>\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "    \n" +
    "    \n" +
    "    \n" +
    "                        <!-- <div ng-show=\"!AdminDashboard.lastestPublishes\" wave-spinner class=\"wave-spinner\"></div>\n" +
    "                        <ul class=\"list-group clear-list m-t\">\n" +
    "                            <li class=\"list-group-item fist-item\" ng-repeat=\"item in AdminDashboard.lastestPublishes\">\n" +
    "                                <span ng-if=\"item.type==='pg'\"> TU Postgraduate</span>\n" +
    "                                <span ng-if=\"item.type==='ug'\">TU Undergraduate</span>\n" +
    "                                <span ng-if=\"item.type==='master'\">TU Overview</span>\n" +
    "                                <span ng-if=\"item.type==='tm'\">TM</span>\n" +
    "                                    of <code class=\"text-muted white-space-normal\">{{ item.name }}</code>\n" +
    "                                <br>by {{ item.createdByFullName }} at <small class=\"time\">{{ item.createdAt | date:'shortTime' }}</small>\n" +
    "                            </li>\n" +
    "                        </ul> -->\n" +
    "                    </div>\n" +
    "            </div>\n" +
    "            <div class=\"ibox\">\n" +
    "                <div class=\"ibox-title\">\n" +
    "                    <h5>New Institutions Per Month</h5>\n" +
    "                    <div class=\"ibox-tools\">\n" +
    "                        <a ng-click=\"showHide()\" class=\"collapse-link\">\n" +
    "                            <i class=\"fa fa-chevron-up\"></i>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"ibox-content\">\n" +
    "                    <div ng-show=\"!AdminDashboard.institutionsPerMonth\" wave-spinner class=\"wave-spinner\"></div>\n" +
    "                    <canvas ng-show=\"AdminDashboard.institutionsPerMonth\" class=\"chart chart-line\" height=\"140\"\n" +
    "                            chart-data=\"AdminDashboard.institutionsPerMonth.data\"\n" +
    "                            chart-labels=\"AdminDashboard.monthsNames\"\n" +
    "                            chart-series=\"AdminDashboard.institutionsPerMonth.series\">\n" +
    "                    </canvas>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"ibox\">\n" +
    "                <div class=\"ibox-title\">\n" +
    "                    <h5>New Stars Institutions Per Month</h5>\n" +
    "                    <div class=\"ibox-tools\">\n" +
    "                        <a ng-click=\"showHide()\" class=\"collapse-link\">\n" +
    "                            <i class=\"fa fa-chevron-up\"></i>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"ibox-content\">\n" +
    "                    <div ng-show=\"!AdminDashboard.starsPerMonth\" wave-spinner class=\"wave-spinner\"></div>\n" +
    "                    <canvas ng-show=\"AdminDashboard.starsPerMonth\" class=\"chart chart-line\" height=\"140\"\n" +
    "                            chart-data=\"AdminDashboard.starsPerMonth.data\"\n" +
    "                            chart-labels=\"AdminDashboard.monthsNames\"\n" +
    "                            chart-series=\"AdminDashboard.starsPerMonth.series\">\n" +
    "                    </canvas>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row\">\n" +
    "        <div class=\"col-lg-4\" ng-repeat=\"item in AdminDashboard.rankingsList\">\n" +
    "            <div class=\"ibox\">\n" +
    "                <div class=\"ibox-title\">\n" +
    "                    <h5><span class=\"text-uppercase\">{{ item.handle }}</span> Answers Per Month</h5>\n" +
    "                    <div class=\"ibox-tools\">\n" +
    "                        <a ng-click=\"showHide()\" class=\"collapse-link\">\n" +
    "                            <i class=\"fa fa-chevron-up\"></i>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"ibox-content\">\n" +
    "                    <div ng-show=\"!AdminDashboard.answersPerRankingPerMonth\" wave-spinner class=\"wave-spinner\"></div>\n" +
    "                    <canvas ng-show=\"AdminDashboard.answersPerRankingPerMonth\" class=\"chart chart-line\" height=\"140\"\n" +
    "                            chart-data=\"AdminDashboard.answersPerRankingPerMonth[item.handle].data\"\n" +
    "                            chart-labels=\"AdminDashboard.monthsNames\"\n" +
    "                            chart-series=\"AdminDashboard.answersPerRankingPerMonth[item.handle].series\">\n" +
    "                    </canvas>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/institutions/department/departmentOverview.html',
    "<div  class=\"wrapper wrapper-content animated fadeInRight page-institution\" ng-controller=\"DepartmentOverviewController as DepartmentOverviewController\">\n" +
    "    <div class=\"row\">\n" +
    "        <div id=\"departmentOverview\" ng-class=\"DepartmentOverviewController.isRightSidePanelActive() ? 'col-sm-8' : 'col-sm-12'\">\n" +
    "            <!-- SECTION -->\n" +
    "            <div class=\"section\">\n" +
    "              <!-- SECTION HEADER -->\n" +
    "              <div class=\"section-header\">\n" +
    "                <!-- Institution switch for QS users -->\n" +
    "                <div class=\"search-dropdown w-430 pull-left\">\n" +
    "                    <ui-select\n" +
    "                        name=\"institution\"\n" +
    "                        ng-model=\"DepartmentOverviewController.institution.selected\"\n" +
    "                        theme=\"bootstrap\"\n" +
    "                        ng-change=\"DepartmentOverviewController.handleSearchInstitutionClick(DepartmentOverviewController.institution.selected)\">\n" +
    "                        <ui-select-match placeholder=\"Institution search...\">\n" +
    "                            <i class=\"fa fa-building\"></i>\n" +
    "                            <span>{{$select.selected.name}}</span>\n" +
    "                        </ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                          refresh-delay=\"500\"\n" +
    "                          refresh=\"DepartmentOverviewController.searchInstitution($select.search)\"\n" +
    "                          position=\"down\"\n" +
    "                          repeat=\"option in DepartmentOverviewController.institutionsList | filter: $select.search\">\n" +
    "                          <div class=\"test\" ng-bind-html=\"option.name | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                    </ui-select>\n" +
    "                    <div class=\"spinner\" ng-show=\"DepartmentOverviewController.searchInProgress\" wave-spinner></div>\n" +
    "                </div>\n" +
    "\n" +
    "                <button class=\"btn btn-primary pull-right\"\n" +
    "                        ng-disabled=\"DepartmentOverviewController.isAddButtonDisabled()\"\n" +
    "                        ng-click=\"handleAddDepartmentClick()\">\n" +
    "                    <i class=\"fa fa-plus\"></i>\n" +
    "                    <span>Add Department</span>\n" +
    "                </button>\n" +
    "              </div><!-- /SECTION HEADER -->\n" +
    "\n" +
    "              <!-- SECTION BODY -->\n" +
    "              <div class=\"section-body\">\n" +
    "                <div id=\"departmentsTable\" ng-class=\"{'locked': !DepartmentOverviewController.isInstitutionSelected()}\">\n" +
    "                    <div wave-spinner class=\"wave-spinner\" ng-show=\"DepartmentOverviewController.isDatagridReloading || !isDatagridRendered\"></div>\n" +
    "\n" +
    "                    <div ng-if=\"!DepartmentOverviewController.isDatagridReloading && gridOptions\">\n" +
    "                        <ui-grid-info ng-if=\"!DepartmentOverviewController.isRightSidePanelActive()\"></ui-grid-info>\n" +
    "                        <div class=\"grid\"\n" +
    "                           ui-grid=\"gridOptions\"\n" +
    "                           ui-grid-draggable-rows\n" +
    "                           ui-grid-selection\n" +
    "                           ui-grid-resize-columns\n" +
    "                           ui-grid-auto-resize>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "              </div><!-- /SECTION BODY -->\n" +
    "            </div><!-- /SECTION -->\n" +
    "        </div>\n" +
    "\n" +
    "        <div id=\"departmentOverviewForm\">\n" +
    "          <div ng-show=\"showDepartmentEditForm || showDepartmentAddForm\"\n" +
    "            id=\"departmentBlockPanel\"\n" +
    "            fixed-element-while-scrolling=\"#departmentsTable\"\n" +
    "            class=\"col-sm-4 right-side-container\"\n" +
    "            ng-controller=\"DepartmentOverviewFormController as DepartmentFormController\">\n" +
    "            <div class=\"department-form\" ng-show=\"showDepartmentEditForm\" ng-include src=\"'/scripts/components/profiles/tu/departments/tuProfileEditDepartmentFormView.html'\"></div>\n" +
    "            <div class=\"department-form\" ng-show=\"showDepartmentAddForm\" ng-include src=\"'/scripts/components/profiles/tu/departments/tuProfileAddDepartmentFormView.html'\"></div>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/components/institutions/list/datagrid/rowTemplate.html',
    "<div grid=\"grid\">\n" +
    "    <div class=\"ui-grid-cell pointer\"\n" +
    "        ng-repeat=\"(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name\"\n" +
    "        ng-class=\"{ 'ui-grid-row-header-cell': col.isRowHeader, 'active': row.entity.id == grid.appScope.selectedInstitutionId }\"\n" +
    "        role=\"{{col.isRowHeader ? 'rowheader' : 'gridcell'}}\"\n" +
    "        ui-grid-cell>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/institutions/list/datagrid/selectCellTemplate.html',
    "<div class=\"ui-grid-cell-contents\">{{COL_FIELD CUSTOM_FILTERS | boolToText:col.filter.selectOptions}}</div>\n"
  );


  $templateCache.put('/scripts/components/institutions/list/institutionsListView.html',
    "<div class=\"wrapper wrapper-content animated fadeInRight page-institution-list\" ng-controller=\"InstitutionsListController as InstitutionsController\">\n" +
    "\n" +
    "    <p>\n" +
    "        <button type=\"button\" class=\"btn btn-primary\"\n" +
    "                ng-click=\"InstitutionsController.handleAddInstitutionClick()\"\n" +
    "                ng-class=\"{'modal-overlay-35': !isDatagridRendered}\">\n" +
    "            <i class=\"fa fa-building-o\"></i>\n" +
    "            Add Institution\n" +
    "        </button>\n" +
    "    </p>\n" +
    "    <div class=\"alert alert-info\">\n" +
    "        <p>\n" +
    "            <i class=\"fa fa-info-circle\"></i>\n" +
    "            <span>Clicking on Add Institution button will take you to new HUB 2 interface to add a new profile.</span>\n" +
    "        </p>\n" +
    "        <p>\n" +
    "            <i class=\"fa fa-info-circle\"></i>\n" +
    "            <span>On this Institutions List - you can only edit the Rankings and Stars subscriptions. Rest all fields can only be edited from HUB 2 interface.</span>\n" +
    "        </p>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row\">\n" +
    "        <div id=\"institutionsTable\" ng-class=\"showInfoBlock ? 'col-sm-3 col-xs-4 hide-ng-table-pager' : 'col-sm-12'\">\n" +
    "            <div class=\"ibox float-e-margins\">\n" +
    "                <div class=\"ibox-content\">\n" +
    "                    <div wave-spinner class=\"wave-spinner\" ng-show=\"isDatagridReloading || !isDatagridRendered\"></div>\n" +
    "\n" +
    "                    <div ng-if=\"!isDatagridReloading && gridOptions\" ng-class=\"{'modal-overlay-35': isDatagridReloading || !isDatagridRendered}\">\n" +
    "                        <ui-grid-info ng-if=\"!showInfoBlock\"></ui-grid-info>\n" +
    "                        <div class=\"grid\"\n" +
    "                             ui-grid=\"gridOptions\"\n" +
    "                             ui-grid-selection\n" +
    "                             ui-grid-resize-columns\n" +
    "                             ui-grid-auto-resize\n" +
    "                             ui-grid-cellnav\n" +
    "                             ui-grid-exporter\n" +
    "                             ui-grid-pagination></div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div fixed-element-while-scrolling=\"#institutionsTable\" ng-show=\"showInfoBlock\" class=\"col-sm-9 col-xs-8 right-side-container\">\n" +
    "            <div class=\"ibox block-institution\">\n" +
    "                <div class=\"ibox-content\">\n" +
    "                    <div class=\"ibox-tools\">\n" +
    "                        <a class=\"close-link\" ng-click=\"InstitutionsController.handleEditCloseClick()\">\n" +
    "                            <i class=\"fa fa-times\"></i>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                    <div wave-spinner class=\"wave-spinner\" ng-show=\"InstitutionsController.scopusIdsFetchInProgress\"></div>\n" +
    "                    <div class=\"tab-content\" ng-class=\"{'modal-overlay-35': InstitutionsController.scopusIdsFetchInProgress}\">\n" +
    "                        <div class=\"tab-pane active\">\n" +
    "                            <div class=\"row m-b-lg\" ng-show=\"institution.id\">\n" +
    "                                <div class=\"col-lg-12\">\n" +
    "                                    <h2>\n" +
    "                                        <sup class=\"badge badge-warning\">{{ institutionBeforeChanges.coreId }}</sup> {{institutionBeforeChanges.name}}\n" +
    "                                    </h2>\n" +
    "\n" +
    "                                    <a ng-show=\"!institution.active\" ng-click=\"handleActivateClick(institution)\" class=\"btn btn-warning btn-sm pull-left m-b-xs modal-overlay-35\" ng-class=\"{'disabled':activateInProgress}\">\n" +
    "                                        <i class=\"fa fa-undo\"></i> Activate\n" +
    "                                    </a>\n" +
    "\n" +
    "                                    <a ng-show=\"institution.active\" ng-click=\"handleDeactivateClick(institution)\" class=\"btn btn-danger btn-sm pull-left m-b-xs modal-overlay-35\" ng-class=\"{'disabled':deactivateInProgress}\">\n" +
    "                                        <i class=\"fa fa-ban\"></i> Deactivate\n" +
    "                                    </a>\n" +
    "\n" +
    "                                    <a class=\"btn btn-default btn-sm pull-left m-b-xs modal-overlay-35\" ng-click=\"handleResetClick(institution)\">\n" +
    "                                        <i class=\"fa fa-refresh\"></i> Reset unsaved changes\n" +
    "                                    </a>\n" +
    "\n" +
    "                                    <a ng-if=\"allowLoginAs()\" class=\"btn btn-default btn-sm pull-left m-b-xs modal-overlay-35\" ng-click=\"handleLoginAsClick()\">\n" +
    "                                        <i class=\"fa fa-sign-in\"></i> Login as institution\n" +
    "                                    </a>\n" +
    "\n" +
    "                                    <a class=\"btn btn-default btn-sm pull-left m-b-xs modal-overlay-35\"\n" +
    "                                       uib-popover=\"{{InstitutionsController.downgradeProgramTooltipText()}}\"\n" +
    "                                       popover-placement=\"bottom\"\n" +
    "                                       popover-enable=\"isProgramDowngradeDisabled\"\n" +
    "                                       popover-trigger=\"'mouseenter'\"\n" +
    "                                       ng-if=\"InstitutionsController.allowProgramDowngrade()\"\n" +
    "                                       ng-click=\"!isProgramDowngradeDisabled && InstitutionsController.handleProgramDowngradeClick($event)\"\n" +
    "                                       ng-disabled=\"isProgramDowngradeDisabled\">\n" +
    "                                        <i class=\"fa fa-arrow-circle-o-down\"></i> Downgrade to Basic Program\n" +
    "                                    </a>\n" +
    "\n" +
    "                                    <a class=\"btn btn-default btn-sm pull-left m-b-xs modal-overlay-35\"\n" +
    "                                       ng-if=\"InstitutionsController.isSimple\"\n" +
    "                                       ng-disabled=\"InstitutionsController.isUpgradeButtonDisabled()\"\n" +
    "                                       ng-click=\"!InstitutionsController.isUpgradeButtonDisabled() &&InstitutionsController.handleUpgradeClick()\"\n" +
    "\n" +
    "                                       uib-popover=\"Mandatory fields must be entered first to upgrade.\"\n" +
    "                                       popover-placement=\"bottom\"\n" +
    "                                       popover-enable=\"InstitutionsController.isUpgradeButtonDisabled()\"\n" +
    "                                       popover-trigger=\"'mouseenter'\">\n" +
    "                                        <i class=\"fa fa-arrow-circle-o-up\"></i> Upgrade to Client Department\n" +
    "                                    </a>\n" +
    "\n" +
    "                                    <a class=\"btn btn-default btn-sm pull-left m-b-xs modal-overlay-35\"\n" +
    "                                       ng-if=\"InstitutionsController.isClient\"\n" +
    "                                       ng-disabled=\"InstitutionsController.isClientDepartmentDowngradeDisabled()\"\n" +
    "                                       ng-click=\"!InstitutionsController.isClientDepartmentDowngradeDisabled() && InstitutionsController.handleDowngradeClick()\"\n" +
    "\n" +
    "                                       uib-popover=\"Unable to downgrade due to current TU/TM subscription.\"\n" +
    "                                       popover-placement=\"bottom\"\n" +
    "                                       popover-enable=\"InstitutionsController.isClientDepartmentDowngradeDisabled()\"\n" +
    "                                       popover-trigger=\"'mouseenter'\">\n" +
    "                                        <i class=\"fa fa-arrow-circle-o-down\"></i> Downgrade to Simple Department\n" +
    "                                    </a>\n" +
    "\n" +
    "\n" +
    "                                    <a class=\"btn pull-left btn-default btn-sm m-b-xs modal-overlay-35\" target=\"_blank\"\n" +
    "                                       ng-href=\"{{InstitutionsController.isViewTuSiteEnabled() ? InstitutionsController.urls.tu + institution.nids.master : ''}}\"\n" +
    "                                       ng-if=\"InstitutionsController.allowViewTuSite()\"\n" +
    "                                       ng-disabled=\"!InstitutionsController.isViewTuSiteEnabled()\"\n" +
    "\n" +
    "                                       uib-popover=\"{{institution.nids.master ? 'Display on topuniversities.com is disabled under Subscriptions tab.' : 'Please publish TU profiles first'}}\"\n" +
    "                                       popover-placement=\"bottom\"\n" +
    "                                       popover-enable=\"!InstitutionsController.isViewTuSiteEnabled()\"\n" +
    "                                       popover-trigger=\"'mouseenter'\">\n" +
    "                                        <i class=\"fa fa-share\"></i> View TU Site\n" +
    "                                    </a>\n" +
    "\n" +
    "                                    <a class=\"btn pull-left btn-default btn-sm m-b-xs modal-overlay-35\" target=\"_blank\"\n" +
    "                                       ng-if=\"InstitutionsController.allowViewTmSite()\"\n" +
    "                                       ng-href=\"{{InstitutionsController.allowViewTmSiteButtonEnabled() ? InstitutionsController.urls.tm + institution.nids.tm : ''}}\"\n" +
    "                                       ng-disabled=\"!InstitutionsController.allowViewTmSiteButtonEnabled()\"\n" +
    "\n" +
    "                                       uib-popover=\"{{institution.nids.master ? 'Display on topmba.com  is disabled under Subscriptions tab.' : 'Please publish TM profile first'}}\"\n" +
    "                                       popover-placement=\"bottom\"\n" +
    "                                       popover-enable=\"!InstitutionsController.allowViewTmSiteButtonEnabled()\"\n" +
    "                                       popover-trigger=\"'mouseenter'\">\n" +
    "                                        <i class=\"fa fa-share\"></i> View TM Site\n" +
    "                                    </a>\n" +
    "                                    \n" +
    "                                    <button ng-if=\"InstitutionsController.isAdvancedProgram || InstitutionsController.isTopLevelInstitution(institution.typeId)\"\n" +
    "                                       class=\"btn btn-default btn-sm modal-overlay-35\"\n" +
    "                                       ui-sref=\"staff.institutions.department({coreId: institution.coreId, name: institution.name})\"\n" +
    "                                       ng-disabled=\"!InstitutionsController.isDisplayDepartmentEnabled\">Display Departments\n" +
    "                                    </button>\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                            <div class=\"institution-detail\">\n" +
    "                                <div class=\"full-height-scroll\">\n" +
    "                                    <div class=\"row\">\n" +
    "                                        <div class=\"col-lg-12\">\n" +
    "                                            <div class=\"tabs-container\">\n" +
    "                                                <uib-tabset active=\"activeTab\">\n" +
    "                                                    <uib-tab heading=\"Basic Details\" disable=\"disabledInstitutionListTabs['basicDetails']\">\n" +
    "                                                        <div ng-include src=\"'/scripts/components/institutions/list/partial/basicDetails.html'\"></div>\n" +
    "                                                    </uib-tab>\n" +
    "\n" +
    "                                                    <uib-tab heading=\"Subscriptions\" disable=\"disabledInstitutionListTabs['subscriptions']\">\n" +
    "                                                        <div ng-include src=\"'/scripts/components/institutions/list/subscriptions/institutionsListSubscriptionsView.html'\"></div>\n" +
    "                                                    </uib-tab>\n" +
    "\n" +
    "                                                    <uib-tab heading=\"Campuses\" class=\"modal-overlay-35\" disable=\"disabledInstitutionListTabs['campuses']\">\n" +
    "                                                        <div ng-include src=\"'/scripts/components/institutions/list/partial/campuses.html'\"></div>\n" +
    "                                                    </uib-tab>\n" +
    "\n" +
    "                                                    <uib-tab heading=\"Group Institutions\" class=\"modal-overlay-35\" disable=\"disabledInstitutionListTabs['institutionGroups']\">\n" +
    "                                                        <div ng-include src=\"'/scripts/components/institutions/list/partial/groupInstitutions.html'\"></div>\n" +
    "                                                    </uib-tab>\n" +
    "\n" +
    "                                                    <uib-tab heading=\"Drupal\" class=\"modal-overlay-35\" disable=\"disabledInstitutionListTabs['drupal']\">\n" +
    "                                                        <div ng-include src=\"'/scripts/components/institutions/list/partial/drupal.html'\"></div>\n" +
    "                                                    </uib-tab>\n" +
    "                                                    \n" +
    "                                                    <uib-tab disable=\"disabledInstitutionListTabs['status']\">\n" +
    "                                                        <uib-tab-heading>\n" +
    "                                                            <span ng-class=\"{'text-danger': InstitutionsController.hasMissingFields()}\">Status</span>\n" +
    "                                                        </uib-tab-heading>\n" +
    "                                                        <div ng-include src=\"'/scripts/components/institutions/list/status/institutionsListStatusView.html'\"></div>\n" +
    "                                                    </uib-tab>\n" +
    "                                                </uib-tabset>\n" +
    "                                            </div>\n" +
    "                                        </div>\n" +
    "                                    </div>\n" +
    "\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "    </div>\n" +
    "    <pre ng-show=\"InstitutionsController.devMode\">{{institution|json}}</pre>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/institutions/list/partial/basicDetails.html',
    "<div class=\"panel-body modal-overlay-75\">\n" +
    "    <div class=\"row\">\n" +
    "        <form name=\"forms.basicDetailsForm\" class=\"clearfix\" novalidate>\n" +
    "            <div class=\"col-lg-6 block-basic-details\">\n" +
    "                <div class=\"form-group\" ng-class=\"{'has-errors': InstitutionsController.isInvalidNewSchoolName}\">\n" +
    "                    <label>Name *</label>\n" +
    "\n" +
    "                    <div ng-if=\"institution.id\">\n" +
    "                        <input type=\"text\" class=\"form-control\"\n" +
    "                            name=\"name\"\n" +
    "                            ng-model=\"institution.name\"\n" +
    "                            ng-required=\"true\"\n" +
    "                            custom-popover\n" +
    "                            popover-html=\"Add institution name\"\n" +
    "                            popover-placement=\"left\"\n" +
    "                            popover-trigger=\"manual\"\n" +
    "                            popover-visibility=\"{{InstitutionsController.isInvalidNewSchoolName}}\"\n" +
    "                            ng-focus=\"InstitutionsController.setIsInvalidNewSchoolName(false)\">\n" +
    "                    </div>\n" +
    "                    <div ng-if=\"!institution.id\">\n" +
    "                        <input type=\"text\" class=\"form-control\"\n" +
    "                            name=\"name\"\n" +
    "                            ng-model=\"institution.name\"\n" +
    "                            ng-required=\"true\"\n" +
    "                            ng-change=\"InstitutionsController.findSimilar(institution)\"\n" +
    "                            ng-model-options=\"{ debounce: 750 }\"\n" +
    "                            custom-popover\n" +
    "                            popover-html=\"Add institution name\"\n" +
    "                            popover-placement=\"left\"\n" +
    "                            popover-trigger=\"manual\"\n" +
    "                            popover-visibility=\"{{InstitutionsController.isInvalidNewSchoolName}}\"\n" +
    "                            ng-focus=\"InstitutionsController.setIsInvalidNewSchoolName(false)\">\n" +
    "                        <div wave-spinner class=\"wave-spinner\" ng-show=\"InstitutionsController.similarNames.loading\"></div>\n" +
    "\n" +
    "                        <div class=\"similar-names\" ng-show=\"!institution.id && InstitutionsController.similarNames.display\">\n" +
    "                            <small class=\"m-b block\">\n" +
    "                                <span class=\"text-muted\">Similarly named existing institutions:</span>\n" +
    "                                <span class=\"text-navy\" ng-show=\"!InstitutionsController.similarNames.results.length\">no results</span>\n" +
    "                            </small>\n" +
    "                            <div ng-show=\"!InstitutionsController.similarNames.loading\" class=\"text-center\">\n" +
    "                                <div ng-repeat=\"similarInstitution in InstitutionsController.similarNames.results\">\n" +
    "                                    <div><a target=\"_blank\" ng-href=\"/#/profiles/institution-details?coreId={{similarInstitution.coreId}}\">{{similarInstitution.name}} {{similarInstitution.active ? '' : '[inactive]'}}</a></div>\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group\" ng-class=\"{'has-errors': InstitutionsController.isInvalidType}\">\n" +
    "                    <label>Type *</label>\n" +
    "                    <ui-select\n" +
    "                        name=\"typeId\"\n" +
    "                        ng-required=\"true\"\n" +
    "                        ng-disabled=\"institution.id\"\n" +
    "                        ng-model=\"selectedItem.selectedOptionType\"\n" +
    "                        on-select=\"InstitutionsController.setSelectedOptionType(selectedItem.selectedOptionType)\"\n" +
    "                        theme=\"bootstrap\"\n" +
    "                        custom-popover\n" +
    "                        popover-html=\"Select an option\"\n" +
    "                        popover-placement=\"left\"\n" +
    "                        popover-trigger=\"manual\"\n" +
    "                        popover-visibility=\"{{InstitutionsController.isInvalidType ? true : false}}\"\n" +
    "                        >\n" +
    "                        <ui-select-match placeholder=\"Select an option\">{{$select.selected.name}}</ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                            position=\"down\"\n" +
    "                            ui-disable-choice=\"option.disabled\"\n" +
    "                            repeat=\"option in InstitutionsController.typesList | filter:$select.search track by option.uniqueId\">\n" +
    "                            <div ng-bind-html=\"option.name | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group\" ng-show=\"InstitutionsController.isClientDepartment(institution.typeId)\" ng-class=\"{'has-errors': InstitutionsController.isInvalidBelongsTo}\">\n" +
    "                    <label>\n" +
    "                        Belongs to *\n" +
    "                    </label>\n" +
    "                    <ui-select\n" +
    "                        name=\"belongsTo\"\n" +
    "                        ng-required=\"InstitutionsController.isClientDepartment(institution.typeId)\"\n" +
    "                        ng-model=\"institution.belongsTo\"\n" +
    "                        on-select=\"InstitutionsController.setIsInvalidBelongsTo(false)\"\n" +
    "                        theme=\"bootstrap\"\n" +
    "                        reset-search-input=\"true\"\n" +
    "                        custom-popover\n" +
    "                        popover-html=\"Select an option\"\n" +
    "                        popover-placement=\"left\"\n" +
    "                        popover-trigger=\"manual\"\n" +
    "                        popover-visibility=\"{{InstitutionsController.isInvalidBelongsTo ? true : false}}\"\n" +
    "                        >\n" +
    "                        <ui-select-match placeholder=\"Select an option\">{{$select.selected.title}}</ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                            position=\"down\"\n" +
    "                            repeat=\"option.id as option in InstitutionsController.belongsToList | filter:$select.search\">\n" +
    "                            <div ng-bind-html=\"option.title | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group\">\n" +
    "                    <switch class=\"green\"\n" +
    "                            ng-model=\"institution.businessSchool\"\n" +
    "                            ng-disabled=\"InstitutionsController.isSimpleDepartment(institution.typeId)\"></switch>\n" +
    "                    Business School\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group\">\n" +
    "                    <switch class=\"green\" name=\"hasNoDepartments\"\n" +
    "                            ng-model=\"institution.hasNoDepartments\"\n" +
    "                            ng-disabled=\"InstitutionsController.isClientDepartment(institution.typeId) || InstitutionsController.isSimpleDepartment(institution.typeId)\"></switch> Has No Departments\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"col-lg-6 block-contact-types\">\n" +
    "                <div class=\"form-group\">\n" +
    "                    <label>Local Name</label>\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"institution.localName\" name=\"localName\">\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group force-width-100\"\n" +
    "                     ng-show=\"InstitutionsController.showParentInstitution(institution.typeId)\"\n" +
    "                     ng-class=\"{'has-errors': InstitutionsController.isInvalidParentInstitution}\">\n" +
    "                    <label>Parent institution *</label>\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"institution.parentName\" ng-show=\"institution.id\" disabled/>\n" +
    "                    <div class=\"search-institutions\" ng-if=\"!institution.id\">\n" +
    "                        <ui-select\n" +
    "                            close-on-select=\"true\"\n" +
    "                            ng-model=\"institution.parent\"\n" +
    "                            ng-required=\"InstitutionsController.isClientDepartment(institution.typeId)\"\n" +
    "                            on-select=\"InstitutionsController.handleSearchInstitutionClick($item)\"\n" +
    "                            theme=\"bootstrap\"\n" +
    "                            name=\"parentInstitution\"\n" +
    "                            reset-search-input=\"true\"\n" +
    "                            custom-popover\n" +
    "                            popover-html=\"Type parent institution name\"\n" +
    "                            popover-placement=\"left\"\n" +
    "                            popover-trigger=\"manual\"\n" +
    "                            popover-visibility=\"{{InstitutionsController.isInvalidParentInstitution}}\"\n" +
    "                            ng-click=\"InstitutionsController.setIsInvalidParentInstitution(false)\"\n" +
    "                            >\n" +
    "                            <ui-select-match placeholder=\"Type parent institution name...\">{{$select.selected.name}}</ui-select-match>\n" +
    "                            <ui-select-choices\n" +
    "                                refresh-delay=\"1000\"\n" +
    "                                refresh=\"searchTopLevelInstitutions($select.search)\"\n" +
    "                                position=\"down\"\n" +
    "                                repeat=\"option in parentInstitutionSearchResults | filter:$select.search\">\n" +
    "                                <div ng-bind-html=\"option.name | highlight: $select.search\"></div>\n" +
    "                            </ui-select-choices>\n" +
    "                            <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                        </ui-select>\n" +
    "\n" +
    "                        <div class=\"show-departments mt-5px\" ng-show=\"institution.parentCoreId\">\n" +
    "                            <div class=\"row\">\n" +
    "                                <div class=\"col-lg-12\">\n" +
    "                                    <div class=\"ibox-tools\">\n" +
    "                                        <button class=\"btn btn-default btn-xs\"\n" +
    "                                                ng-show=\"!InstitutionsController.showDepartments.display\"\n" +
    "                                                ng-click=\"InstitutionsController.showDepartments()\">Show All Departments</button>\n" +
    "                                        <a class=\"close-link\"\n" +
    "                                           ng-show=\"InstitutionsController.showDepartments.display\"\n" +
    "                                           ng-click=\"InstitutionsController.handleDepartmentsListCloseClick()\">\n" +
    "                                            <i class=\"fa fa-times\"></i>\n" +
    "                                        </a>\n" +
    "\n" +
    "                                        <div class=\"pull-left\" ng-show=\"!InstitutionsController.showDepartments.loading && InstitutionsController.showDepartments.display\">\n" +
    "                                            <small class=\"m-b block\">\n" +
    "                                                <span class=\"text-muted\">Current Client departments:</span>\n" +
    "                                                <span class=\"text-navy\" ng-show=\"!InstitutionsController.showDepartments.results.length\">no results</span>\n" +
    "                                            </small>\n" +
    "                                        </div>\n" +
    "                                    </div>\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                            <div class=\"row\">\n" +
    "                                <div class=\"col-lg-12 text-center\">\n" +
    "                                    <div wave-spinner class=\"wave-spinner\" ng-show=\"InstitutionsController.showDepartments.loading\"></div>\n" +
    "                                    <div ng-show=\"InstitutionsController.showDepartments.display && InstitutionsController.showDepartments.results.length\">\n" +
    "                                        <div ng-repeat=\"clientDeparmtent in InstitutionsController.showDepartments.results\">\n" +
    "                                            <div>\n" +
    "                                                <a target=\"_blank\" ng-href=\"/#/profiles/institution-details?coreId={{clientDeparmtent.coreId}}\">{{clientDeparmtent.name}}</a>\n" +
    "                                            </div>\n" +
    "                                        </div>\n" +
    "                                    </div>\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group\">\n" +
    "                    <label>FileMaker ID</label>\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"institution.filemakerId\" name=\"filemakerId\">\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group\" ng-class=\"{'has-errors': InstitutionsController.isInvalidCountry}\">\n" +
    "                    <label>Country *</label>\n" +
    "                    <ui-select\n" +
    "                        id=\"campusCountry\"\n" +
    "                        name=\"countryCode\"\n" +
    "                        ng-model=\"institution.countryCode\"\n" +
    "                        on-select=\"updateCountryName()\"\n" +
    "                        ng-required=\"true\"\n" +
    "                        theme=\"bootstrap\"\n" +
    "                        custom-popover\n" +
    "                        popover-html=\"Select an option\"\n" +
    "                        popover-placement=\"left\"\n" +
    "                        popover-trigger=\"manual\"\n" +
    "                        popover-visibility=\"{{InstitutionsController.isInvalidCountry ? true : false}}\"\n" +
    "                        >\n" +
    "                        <ui-select-match placeholder=\"Select an option\">{{$select.selected.name}}</ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                            position=\"auto\"\n" +
    "                            ui-disable-choice=\"option.disabled\"\n" +
    "                            repeat=\"option.countryCode as option in InstitutionsController.countriesList | filter:$select.search\">\n" +
    "                            <div ng-bind-html=\"option.name | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "\n" +
    "                <button class=\"btn btn-primary btn-block\"\n" +
    "                        ng-disabled=\"basicDetailsSubmitInProgress\"\n" +
    "                        ng-click=\"handleBasicDetailsSubmit()\">{{institution.id ? 'Update' : 'Save'}}</button>\n" +
    "            </div>\n" +
    "\n" +
    "\n" +
    "        </form>\n" +
    "    </div>\n" +
    "\n" +
    "</div>"
  );


  $templateCache.put('/scripts/components/institutions/list/partial/campuses.html',
    "<script id=\"ng-table/templates/campus/delete-header.html\" type=\"text/ng-template\">\n" +
    "    <th class=\"header\" ng-if=\"$column.show(this)\">\n" +
    "        <button type=\"button\" class=\"btn btn-danger btn-sm\" ng-class=\"{'disabled': isDeleteButtonDisabled()}\" ng-click=\"handleDeleteClick()\">\n" +
    "            <span class=\"glyphicon glyphicon-trash\"></span> {{$column.title(this)}}\n" +
    "        </button>\n" +
    "    </th>\n" +
    "</script>\n" +
    "<div class=\"panel-body\">\n" +
    "    <div class=\"row\" ng-controller=\"CampusesListController as CampusesController\">\n" +
    "        <div class=\"form-group\">\n" +
    "            <button type=\"button\" class=\"btn btn-primary\" ng-click=\"handleAddClick()\" ng-disabled=\"InstitutionsController.isSimpleDepartment(institution.typeId)\">\n" +
    "                <i class=\"fa fa-globe\"></i>\n" +
    "                Add Campus\n" +
    "            </button>\n" +
    "        </div>\n" +
    "        <div class=\"row\">\n" +
    "            <div id=\"campusesTable\" ng-class=\"showCampusInfoBlock ? 'col-sm-3' : 'col-sm-12'\">\n" +
    "                <div class=\"ibox float-e-margins\">\n" +
    "                    <display-filtering class=\"pull-right\" columns=\"hiddenColumns\" active=\"filterActive\"></display-filtering>\n" +
    "\n" +
    "                    <table class=\"table table-striped table-bordered table-hover\"\n" +
    "                           ng-show=\"institution.campus.length\"\n" +
    "                           ng-table-dynamic=\"tableParams with columns\"\n" +
    "                           show-filter=\"false\">\n" +
    "                        <tbody ui-sortable=\"sortableOptions\" ng-model=\"institution.campus\">\n" +
    "\n" +
    "                        <tr ng-repeat=\"campus in $data\" ng-class=\"{'active': campus.id == selectedCampusId}\">\n" +
    "                            <td ng-click=\"col.field === 'delete'? '' : handleDataGridRowClickCampus(campus, false)\" ng-repeat=\"col in $columns\">\n" +
    "                                <input ng-if=\"col.field === 'delete'\" ng-model=\"campusesToDelete[campus.id]\" i-checkbox type=\"checkbox\">\n" +
    "                                <p ng-if=\"col.field !== 'delete'\">\n" +
    "                                    {{ campus[col.field] }}\n" +
    "                                </p>\n" +
    "                            </td>\n" +
    "                        </tr>\n" +
    "                        </tbody>\n" +
    "                    </table>\n" +
    "                    <p ng-show=\"!institution.campus.length && !showCampusInfoBlock\" class=\"text-center text-muted\">\n" +
    "                        List is empty\n" +
    "                    </p>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div ng-show=\"showCampusInfoBlock\" class=\"col-sm-9 valuesTable\">\n" +
    "                <div class=\"ibox block-campus\">\n" +
    "                    <div class=\"tab-content\">\n" +
    "                        <div class=\"tab-pane active\">\n" +
    "                            <div class=\"client-detail\">\n" +
    "                                <div class=\"full-height-scroll scroll-floating-element\" full-scroll>\n" +
    "                                    <div class=\"row\">\n" +
    "                                        <form name=\"forms.campusDetailsForm\" id=\"campusDetailsForm\" class=\"clearfix\" ng-class=\"{submitted:submitted}\" novalidate>\n" +
    "                                            <div class=\"col-lg-6\">\n" +
    "\n" +
    "                                                <div class=\"form-group\" ng-class=\"{'has-errors': !isValidField('name',true)}\">\n" +
    "                                                    <label for=\"campusName\">Campus Name *</label>\n" +
    "                                                    <input id=\"campusName\" name=\"name\" type=\"text\" class=\"form-control\" \n" +
    "                                                           ng-model=\"campus.name\" \n" +
    "                                                           ng-required=\"true\" \n" +
    "                                                           ng-disabled=\"InstitutionsController.isSimpleDepartment(institution.typeId)\"\n" +
    "                                                           ng-focus=\"setValid('name')\"\n" +
    "                                                           focus-delay=\"250\"\n" +
    "                                                           \n" +
    "                                                           custom-popover\n" +
    "                                                           popover-html=\"Add a {{isEmpty(campus.name) ? '' : 'valid' }} campus name\"\n" +
    "                                                           popover-placement=\"left\"\n" +
    "                                                           popover-trigger=\"manual\"\n" +
    "                                                           popover-visibility=\"{{!isValidField('name',true)}}\"/>\n" +
    "                                                </div>\n" +
    "\n" +
    "                                                <div class=\"form-group\" ng-class=\"{'has-errors': !isValidField('addressLine1',true)}\">\n" +
    "                                                    <label for=\"campusAddressLine1\">Address Line 1 *</label>\n" +
    "                                                    <input id=\"campusAddressLine1\" name=\"addressLine1\" type=\"text\" class=\"form-control\" \n" +
    "                                                           ng-model=\"campus.addressLine1\" \n" +
    "                                                           ng-required=\"true\" \n" +
    "                                                           ng-disabled=\"InstitutionsController.isSimpleDepartment(institution.typeId)\"\n" +
    "                                                           ng-focus=\"setValid('addressLine1')\"\n" +
    "                                                           focus-delay=\"250\"\n" +
    "                                                           \n" +
    "                                                           custom-popover\n" +
    "                                                           popover-html=\"Add a {{isEmpty(campus.addressLine1) ? '' : 'valid' }} Address\"\n" +
    "                                                           popover-placement=\"left\"\n" +
    "                                                           popover-trigger=\"manual\"\n" +
    "                                                           popover-visibility=\"{{!isValidField('addressLine1',true)}}\"/>\n" +
    "                                                </div>\n" +
    "\n" +
    "                                                <div class=\"form-group\" ng-class=\"{'has-errors': !isValidField('city',true)}\">\n" +
    "                                                    <label for=\"campusCity\">Town / City *</label>\n" +
    "                                                    <input id=\"campusCity\" name=\"city\" type=\"text\" class=\"form-control\" \n" +
    "                                                           ng-model=\"campus.city\" \n" +
    "                                                           ng-required=\"true\" \n" +
    "                                                           ng-disabled=\"InstitutionsController.isSimpleDepartment(institution.typeId)\"\n" +
    "                                                           ng-focus=\"setValid('city')\"\n" +
    "                                                           focus-delay=\"250\"\n" +
    "                                                           \n" +
    "                                                           custom-popover\n" +
    "                                                           popover-html=\"Add a {{isEmpty(campus.city) ? '' : 'valid' }} town/city\"\n" +
    "                                                           popover-placement=\"left\"\n" +
    "                                                           popover-trigger=\"manual\"\n" +
    "                                                           popover-visibility=\"{{!isValidField('city',true)}}\"/>\n" +
    "                                                </div>\n" +
    "\n" +
    "                                                <div class=\"form-group\" ng-class=\"{'has-errors': !isValidField('postcode')}\">\n" +
    "                                                    <label for=\"campusPostCode\">Postcode</label>\n" +
    "                                                    <input id=\"campusPostCode\" name=\"postcode\" type=\"text\" class=\"form-control\" \n" +
    "                                                           ng-model=\"campus.postcode\" \n" +
    "                                                           ng-disabled=\"InstitutionsController.isSimpleDepartment(institution.typeId)\"\n" +
    "                                                           ng-focus=\"setValid('postcode')\"\n" +
    "                                                           focus-delay=\"250\"\n" +
    "                                                           \n" +
    "                                                           custom-popover\n" +
    "                                                           popover-html=\"Add a valid postcode\"\n" +
    "                                                           popover-placement=\"left\"\n" +
    "                                                           popover-trigger=\"manual\"\n" +
    "                                                           popover-visibility=\"{{!isValidField('postcode')}}\"/>\n" +
    "                                                </div>\n" +
    "\n" +
    "                                                <div class=\"form-group\" ng-class=\"{'has-errors': !isValidLatitude()}\">\n" +
    "                                                    <label for=\"campusLatitude\">Latitude</label>\n" +
    "                                                    <input id=\"campusLatitude\" name=\"latitude\" type=\"text\" class=\"form-control\" \n" +
    "                                                           ng-model=\"campus.latitude\" \n" +
    "                                                           ng-change=\"coordinatesChanged()\" \n" +
    "                                                           ng-readonly=\"campus.autoGenerate || InstitutionsController.isSimpleDepartment(institution.typeId)\"\n" +
    "                                                           focus-delay=\"250\"\n" +
    "                                                           \n" +
    "                                                           custom-popover\n" +
    "                                                           popover-html=\"Add a valid latitude\"\n" +
    "                                                           popover-placement=\"left\"\n" +
    "                                                           popover-trigger=\"manual\"\n" +
    "                                                           popover-visibility=\"{{!isValidLatitude()}}\"/>\n" +
    "                                                </div>\n" +
    "\n" +
    "                                                <div class=\"form-group\">\n" +
    "                                                    <label class=\"full-width\">Auto Generate</label>\n" +
    "                                                    <switch ng-model=\"campus.autoGenerate\" class=\"green\" ng-disabled=\"InstitutionsController.isSimpleDepartment(institution.typeId)\"></switch>\n" +
    "                                                    <span>Use the address to auto generate latitude & longitude values.</span>\n" +
    "                                                </div>\n" +
    "\n" +
    "                                                <div class=\"form-group\">\n" +
    "                                                    <label class=\"full-width\">Display In Frontend</label>\n" +
    "                                                    <switch ng-model=\"campus.displayInFrontEnd\" ng-change=\"CampusesController.displayOnFrontEndClick()\" class=\"green\" ng-disabled=\"InstitutionsController.isSimpleDepartment(institution.typeId)\"></switch>\n" +
    "                                                    <span>If selected, campus will be sent to profile. (Latitude and longitude is required)</span>\n" +
    "                                                </div>\n" +
    "\n" +
    "                                            </div>\n" +
    "\n" +
    "                                            <div class=\"col-lg-6\">\n" +
    "\n" +
    "                                                <div class=\"form-group\" ng-class=\"{'has-errors': !isValidField('country', true)}\">\n" +
    "                                                    <label>Country *</label>\n" +
    "                                                    <ui-select\n" +
    "                                                        name=\"country\"\n" +
    "                                                        ng-model=\"campus.country\"\n" +
    "                                                        ng-required=\"true\"\n" +
    "                                                        ng-disabled=\"InstitutionsController.isSimpleDepartment(institution.typeId)\"\n" +
    "                                                        theme=\"bootstrap\"\n" +
    "                                                        on-select=\"setValid('country')\"\n" +
    "                                                        focus-delay=\"250\"\n" +
    "                                                           \n" +
    "                                                        custom-popover\n" +
    "                                                        popover-html=\"Select an option\"\n" +
    "                                                        popover-placement=\"left\"\n" +
    "                                                        popover-trigger=\"manual\"\n" +
    "                                                        popover-visibility=\"{{!isValidField('country',true)}}\">\n" +
    "                                                        <ui-select-match placeholder=\"Select an Option\">{{$select.selected.name}}</ui-select-match>\n" +
    "                                                        <ui-select-choices \n" +
    "                                                            position='down'\n" +
    "                                                            refresh-delay=\"1000\"\n" +
    "                                                            repeat=\"option.countryCode as option in CampusesController.countriesList | filter:$select.search\">\n" +
    "                                                            <div ng-bind-html=\"option.name | highlight: $select.search\"></div>\n" +
    "                                                        </ui-select-choices>\n" +
    "                                                        <ui-select-no-choice>\n" +
    "                                                            Not found\n" +
    "                                                        </ui-select-no-choice>\n" +
    "                                                    </ui-select>\n" +
    "                                                </div>\n" +
    "\n" +
    "                                                <div class=\"form-group\" ng-class=\"{'has-errors': !isValidField('addressLine2')}\">\n" +
    "                                                    <label for=\"campusAddressLine2\">Address Line 2</label>\n" +
    "                                                    <input id=\"campusAddressLine2\" name=\"addressLine2\" type=\"text\" class=\"form-control\" \n" +
    "                                                           ng-model=\"campus.addressLine2\" \n" +
    "                                                           ng-disabled=\"InstitutionsController.isSimpleDepartment(institution.typeId)\"\n" +
    "                                                           ng-focus=\"setValid('addressLine2')\"\n" +
    "                                                           focus-delay=\"250\"\n" +
    "                                                           \n" +
    "                                                           custom-popover\n" +
    "                                                           popover-html=\"Add a valid address\"\n" +
    "                                                           popover-placement=\"left\"\n" +
    "                                                           popover-trigger=\"manual\"\n" +
    "                                                           popover-visibility=\"{{!isValidField('addressLine2')}}\"/>\n" +
    "                                                </div>\n" +
    "\n" +
    "                                                <div class=\"form-group\" ng-class=\"{'has-errors': !isValidField('state')}\">\n" +
    "                                                    <label for=\"campusState\">State / Province</label>\n" +
    "                                                    <input id=\"campusState\" name=\"state\" type=\"text\" class=\"form-control\" \n" +
    "                                                           ng-model=\"campus.state\" \n" +
    "                                                           ng-disabled=\"InstitutionsController.isSimpleDepartment(institution.typeId)\"\n" +
    "                                                           ng-focus=\"setValid('state')\"\n" +
    "                                                           focus-delay=\"250\"\n" +
    "                                                           \n" +
    "                                                           custom-popover\n" +
    "                                                           popover-html=\"Add a valid state/province\"\n" +
    "                                                           popover-placement=\"left\"\n" +
    "                                                           popover-trigger=\"manual\"\n" +
    "                                                           popover-visibility=\"{{!isValidField('state')}}\"/>\n" +
    "                                                </div>\n" +
    "\n" +
    "                                                <div class=\"form-group\" ng-class=\"{'has-errors': !isValidLongitude()}\">\n" +
    "                                                    <label for=\"campusLongitude\">Longitude</label>\n" +
    "                                                    <input id=\"campusLongitude\" name=\"longitude\" type=\"text\" class=\"form-control\"\n" +
    "                                                           ng-model=\"campus.longitude\" \n" +
    "                                                           ng-change=\"coordinatesChanged()\" \n" +
    "                                                           ng-readonly=\"campus.autoGenerate || InstitutionsController.isSimpleDepartment(institution.typeId)\"\n" +
    "                                                           focus-delay=\"250\"\n" +
    "                                                           \n" +
    "                                                           custom-popover\n" +
    "                                                           popover-html=\"Add a valid longitude\"\n" +
    "                                                           popover-placement=\"left\"\n" +
    "                                                           popover-trigger=\"manual\"\n" +
    "                                                           popover-visibility=\"{{!isValidLongitude()}}\"/>\n" +
    "                                                </div>\n" +
    "\n" +
    "                                                 <div class=\"form-group\">\n" +
    "                                                    <label class=\"full-width\">Primary Campus</label>\n" +
    "                                                    <switch id=\"campusPrimary\" ng-model=\"campus.primary\" class=\"green\" ng-disabled=\"InstitutionsController.isSimpleDepartment(institution.typeId)\"></switch>\n" +
    "                                                </div>\n" +
    "\n" +
    "                                                <div class=\"form-group\">\n" +
    "                                                    <a class=\"btn btn-primary btn-sm btn-block\" ng-class=\"{'disabled':campusSubmitInProgress || InstitutionsController.isSimpleDepartment(institution.typeId)}\" ng-click=\"handleCampusSubmit()\">{{getMode() ? 'Update' : 'Save'}}</a>\n" +
    "                                                </div>\n" +
    "                                            </div>\n" +
    "                                        </form>\n" +
    "                                    </div>\n" +
    "                                    <div class=\"row\">\n" +
    "                                        <div class=\"col-lg-12\">\n" +
    "                                            <section id=\"map\" ng-controller=\"CampusesListController\" class=\"form-group\">\n" +
    "                                                <div id=\"campusMap\" class=\"h-166\"></div>\n" +
    "                                            </section>\n" +
    "                                            <p class=\"text-center\">\n" +
    "                                                <button type=\"button\" class=\"btn btn-default btn-xs\"\n" +
    "                                                        ng-click=\"CampusesController.refreshMap()\"\n" +
    "                                                        ng-disabled=\"refreshMapInProgress\">Refresh Map</button>\n" +
    "                                            </p>\n" +
    "                                        </div>\n" +
    "                                    </div>\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/institutions/list/partial/drupal.html',
    "<div class=\"panel-body\">\n" +
    "    <div class=\"row\">\n" +
    "        <form name=\"forms.drupalForm\" class=\"clearfix\" novalidate>\n" +
    "\n" +
    "            <div class=\"col-lg-6\">\n" +
    "                <div class=\"form-group\">\n" +
    "                    <label>Overview node ID</label>\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"institution.nids.master\" disabled=\"disabled\">\n" +
    "                </div>\n" +
    "                <div class=\"form-group\">\n" +
    "                    <label>UG node ID</label>\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"institution.nids.ug\" disabled=\"disabled\">\n" +
    "                </div>\n" +
    "                <div class=\"form-group\">\n" +
    "                    <label>PG node ID</label>\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"institution.nids.pg\" disabled=\"disabled\">\n" +
    "                </div>\n" +
    "                <div class=\"form-group\">\n" +
    "                    <label>TM node ID</label>\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"institution.nids.tm\" disabled=\"disabled\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"col-lg-6\">\n" +
    "                <div class=\"form-group\" ng-class=\"{'has-errors': InstitutionsController.isInvalidTuRegion}\" ng-if=\"!InstitutionsController.isAdvancedProgram\">\n" +
    "                    <label>TU Region *</label>\n" +
    "                    <ui-select\n" +
    "                        name=\"tuRegion\"\n" +
    "                        ng-disabled=\"InstitutionsController.isSimpleDepartment(institution.typeId)\"\n" +
    "                        ng-model=\"institution.drupalTuRegionId\"\n" +
    "                        ng-required=\"true\"\n" +
    "                        ng-disabled=\"InstitutionsController.isSimpleDepartment(institution.typeId)\"\n" +
    "                        on-select=\"InstitutionsController.setIsInvalidTuRegion(false)\"\n" +
    "                        ng-required=\"true\"\n" +
    "                        theme=\"bootstrap\"\n" +
    "                        custom-popover\n" +
    "                        popover-html=\"Select an option\"\n" +
    "                        popover-placement=\"left\"\n" +
    "                        popover-trigger=\"manual\"\n" +
    "                        popover-visibility=\"{{InstitutionsController.isInvalidTuRegion}}\"\n" +
    "                        >\n" +
    "                        <ui-select-match placeholder=\"Select an option\">{{$select.selected.name}}</ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                            position=\"down\"\n" +
    "                            ui-disable-choice=\"option.disabled\"\n" +
    "                            repeat=\"option.tid as option in InstitutionsController.tuRegionsList\">\n" +
    "                            <div ng-bind-html=\"option.name | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group\" ng-class=\"{'has-errors': InstitutionsController.isInvalidTmRegion}\">\n" +
    "                    <label>TM Region *</label>\n" +
    "                    <ui-select\n" +
    "                        name=\"tmRegion\"\n" +
    "                        ng-model=\"institution.drupalTmRegionId\"\n" +
    "                        ng-required=\"true\"\n" +
    "                        ng-disabled=\"InstitutionsController.isSimpleDepartment(institution.typeId)\"\n" +
    "                        on-select=\"InstitutionsController.setIsInvalidTmRegion(false)\"\n" +
    "                        ng-required=\"true\"\n" +
    "                        theme=\"bootstrap\"\n" +
    "                        custom-popover\n" +
    "                        popover-html=\"Select an option\"\n" +
    "                        popover-placement=\"left\"\n" +
    "                        popover-trigger=\"manual\"\n" +
    "                        popover-visibility=\"{{InstitutionsController.isInvalidTmRegion}}\"\n" +
    "                        >\n" +
    "                        <ui-select-match placeholder=\"Select an option\">{{$select.selected.name}}</ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                            position=\"down\"\n" +
    "                            ui-disable-choice=\"option.disabled\"\n" +
    "                            repeat=\"option.tid as option in InstitutionsController.tmRegionsList\">\n" +
    "                            <div ng-bind-html=\"option.name | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "\n" +
    "                <button class=\"btn btn-primary btn-sm btn-block\"\n" +
    "                   ng-disabled=\"drupalSubmitInProgress || InstitutionsController.isSimpleDepartment(institution.typeId)\"\n" +
    "                   ng-click=\"handleDrupalSubmit()\">Update</button>\n" +
    "\n" +
    "            </div>\n" +
    "\n" +
    "        </form>\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/components/institutions/list/partial/groupInstitutions.html',
    "<div class=\"panel-body\">\n" +
    "    <div class=\"row\">\n" +
    "        <div wave-spinner class=\"wave-spinner\" ng-show=\"isSelectReloading\"></div>\n" +
    "\n" +
    "        <div class=\"col-lg-12 block-group-institutions-search\">\n" +
    "         <label>Access to Outreach for C121 and CM only</label>\n" +
    "            <ui-select\n" +
    "                      ng-disabled=\"InstitutionsController.isSimpleDepartment(institution.typeId)\"\n" +
    "                      multiple\n" +
    "                       close-on-select=\"false\"\n" +
    "                       ng-model=\"institution.groupMembers\"\n" +
    "                       theme=\"bootstrap\"\n" +
    "                       reset-search-input=\"true\">\n" +
    "                <ui-select-match placeholder=\"Type institution name...\">{{$item.name}}</ui-select-match>\n" +
    "                <ui-select-choices refresh-delay=\"1000\"\n" +
    "                                   refresh=\"searchInstitutionsWithoutGroup($select.search)\"\n" +
    "                                   repeat=\"option in institutionsWithoutGroup | filter:$select.search\">\n" +
    "                    <div ng-bind-html=\"option.name | highlight: $select.search\"></div>\n" +
    "                </ui-select-choices>\n" +
    "            </ui-select>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <br>\n" +
    "    <div class=\"row\">\n" +
    "        <div class=\"col-lg-6 block-group-institutions-warning\">\n" +
    "            <i>Institution \"{{institution.name}}\" will be included by default</i>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"col-lg-6 block-group-institutions-update\">\n" +
    "            <a class=\"btn btn-primary btn-sm btn-block\" ng-class=\"{'disabled': disallowGroupsSubmit() || InstitutionsController.isSimpleDepartment(institution.typeId)}\" ng-click=\"handleInstitutionGroupSubmit()\">Update</a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/components/institutions/list/status/institutionsListStatusView.html',
    "<div class=\"panel-body\" ng-controller=\"InstitutionsListStatusController as InstitutionsListStatusController\">\n" +
    "    <div class=\"row\">\n" +
    "\n" +
    "        <div class=\"col-lg-4\">\n" +
    "            <div class=\"ibox float-e-margins\">\n" +
    "                <div class=\"ibox-title\">\n" +
    "                    <h5>Basic details</h5>\n" +
    "                </div>\n" +
    "                <div class=\"ibox-content no-padding\">\n" +
    "                    <ul class=\"list-group\">\n" +
    "                        <li class=\"list-group-item\">\n" +
    "                            <i ng-class=\"InstitutionsListStatusController.institution.name ? 'fa-check text-navy' : 'fa-times text-danger'\" class=\"fa\"></i>\n" +
    "                            <span class=\"m-l-xs\">Name</span>\n" +
    "                            <span class=\"m-l-xs text-muted\"\n" +
    "                                  ng-show=\"InstitutionsListStatusController.institution.name\"> - {{InstitutionsListStatusController.institution.name}}</span>\n" +
    "                            <i class=\"fa fa-info-circle pull-right m-t-xs\" tooltip-placement=\"top\" uib-tooltip=\"Name is a compulsory field.\"></i>\n" +
    "                        </li>\n" +
    "                        <li class=\"list-group-item\">\n" +
    "                            <i ng-class=\"InstitutionsListStatusController.institution.type ? 'fa-check text-navy' : 'fa-times text-danger'\" class=\"fa\"></i>\n" +
    "                            <span class=\"m-l-xs\">Type</span>\n" +
    "                            <span class=\"m-l-xs text-muted\"\n" +
    "                                  ng-show=\"InstitutionsListStatusController.institution.type\"> - {{InstitutionsListStatusController.institution.type}}</span>\n" +
    "                            <i class=\"fa fa-info-circle pull-right m-t-xs\" tooltip-placement=\"top\" uib-tooltip=\"Type is a a compulsory field.\"></i>\n" +
    "                        </li>\n" +
    "                        <li class=\"list-group-item\">\n" +
    "                            <i ng-class=\"InstitutionsListStatusController.institution.country ? 'fa-check text-navy' : 'fa-times text-danger'\" class=\"fa\"></i>\n" +
    "                            <span class=\"m-l-xs\">Country</span>\n" +
    "                            <span class=\"m-l-xs text-muted\"\n" +
    "                                  ng-show=\"InstitutionsListStatusController.institution.country\"> - {{InstitutionsListStatusController.institution.country}}</span>\n" +
    "                            <i class=\"fa fa-info-circle pull-right m-t-xs\" tooltip-placement=\"top\" uib-tooltip=\"Country is a compulsory field.\"></i>\n" +
    "                        </li>\n" +
    "                        <li class=\"list-group-item\"\n" +
    "                            ng-show=\"InstitutionsController.isClientDepartment(InstitutionsListStatusController.institution.typeId)\">\n" +
    "                            <i ng-class=\"InstitutionsListStatusController.institution.belongsToName ? 'fa-check text-navy' : 'fa-times text-danger'\" class=\"fa\"></i>\n" +
    "                            <span class=\"m-l-xs\">Belongs to</span>\n" +
    "                            <span class=\"m-l-xs text-muted\"\n" +
    "                                  ng-show=\"InstitutionsListStatusController.institution.belongsToName\"> - {{InstitutionsListStatusController.institution.belongsToName}}</span>\n" +
    "                            <i class=\"fa fa-info-circle pull-right m-t-xs\" tooltip-placement=\"top\" uib-tooltip=\"Belongs to is a compulsory field.\"></i>\n" +
    "                        </li>\n" +
    "                        <li class=\"list-group-item\"\n" +
    "                            ng-show=\"InstitutionsController.showParentInstitution(InstitutionsListStatusController.institution.typeId)\">\n" +
    "                            <i ng-class=\"InstitutionsListStatusController.institution.parentName ? 'fa-check text-navy' : 'fa-times text-danger'\" class=\"fa\"></i>\n" +
    "                            <span class=\"m-l-xs\">Parent institution</span>\n" +
    "                            <span class=\"m-l-xs text-muted\"\n" +
    "                                  ng-show=\"InstitutionsListStatusController.institution.parentName\"> - {{InstitutionsListStatusController.institution.parentName}}</span>\n" +
    "                            <i class=\"fa fa-info-circle pull-right m-t-xs\" tooltip-placement=\"top\" uib-tooltip=\"Parent Institution is a compulsory field.\"></i>\n" +
    "                        </li>\n" +
    "                    </ul>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"col-lg-4\" ng-if=\"InstitutionsController.accessAllowed\">\n" +
    "            <div class=\"ibox float-e-margins\">\n" +
    "                <div class=\"ibox-title\">\n" +
    "                    <h5>Subscriptions</h5>\n" +
    "                </div>\n" +
    "                <div class=\"ibox-content no-padding\">\n" +
    "                    <div class=\"ibox-title\" ng-if=\"!InstitutionsListStatusController.isAdvancedProgram\">\n" +
    "                        <h5>TU</h5>\n" +
    "                    </div>\n" +
    "                    <ul class=\"list-group\" ng-if=\"!InstitutionsListStatusController.isAdvancedProgram\">\n" +
    "                        <li class=\"list-group-item\">\n" +
    "                            <i ng-class=\"InstitutionsListStatusController.institution.subscriptions.tu.subscribed ? 'fa-check text-navy' : 'fa-times'\" class=\"fa\"></i>\n" +
    "                            <span class=\"m-l-xs\">UG / PG enabled</span>\n" +
    "                            <i class=\"fa fa-info-circle pull-right m-t-xs\" tooltip-placement=\"top\" uib-tooltip=\"This enable the institution to have UG/PG profile in hub\"></i>\n" +
    "                        </li>\n" +
    "                        <li class=\"list-group-item\">\n" +
    "                            <i ng-class=\"InstitutionsListStatusController.institution.subscriptions.tu.advanced ? 'fa-check text-navy' : 'fa-times'\" class=\"fa\"></i>\n" +
    "                            <span class=\"m-l-xs\">TU advanced</span>\n" +
    "                            <span class=\"m-l-xs text-muted\"\n" +
    "                                  ng-show=\"InstitutionsListStatusController.institution.subscriptions.tu.advanced\">\n" +
    "                                {{InstitutionsListStatusController.institution.subscriptions.tu.startDate| date:'mediumDate'}} - {{InstitutionsListStatusController.institution.subscriptions.tu.endDate| date:'mediumDate'}}\n" +
    "                            </span>\n" +
    "                            <i class=\"fa fa-info-circle pull-right m-t-xs\" tooltip-placement=\"top\" uib-tooltip=\"Subscription date is compulsory if the UG/PG profile is advanced\"></i>\n" +
    "                        </li>\n" +
    "                        <li class=\"list-group-item\">\n" +
    "                            <i ng-class=\"InstitutionsListStatusController.institution.enabled.ug ? 'fa-check text-navy' : 'fa-times'\" class=\"fa\"></i>\n" +
    "                            <span class=\"m-l-xs\">Display UG on TopUniversities.com</span>\n" +
    "                            <i class=\"fa fa-info-circle pull-right m-t-xs\" tooltip-placement=\"top\" uib-tooltip=\"Enable to display UG profile on frontend site\"></i>\n" +
    "                        </li>\n" +
    "                        <li class=\"list-group-item\">\n" +
    "                            <i ng-class=\"InstitutionsListStatusController.institution.enabled.pg ? 'fa-check text-navy' : 'fa-times'\" class=\"fa\"></i>\n" +
    "                            <span class=\"m-l-xs\">Display PG on TopUniversities.com</span>\n" +
    "                            <i class=\"fa fa-info-circle pull-right m-t-xs\" tooltip-placement=\"top\" uib-tooltip=\"Enable to display PG profile on frontend site\"></i>\n" +
    "                        </li>\n" +
    "                    </ul>\n" +
    "                    <div class=\"ibox-title\">\n" +
    "                        <h5>TM</h5>\n" +
    "                    </div>\n" +
    "                    <ul class=\"list-group\">\n" +
    "                        <li class=\"list-group-item\">\n" +
    "                            <i ng-class=\"InstitutionsListStatusController.institution.subscriptions.tm.subscribed ? 'fa-check text-navy' : 'fa-times'\" class=\"fa\"></i>\n" +
    "                            <span class=\"m-l-xs\">MBA enabled</span>\n" +
    "                            <i class=\"fa fa-info-circle pull-right m-t-xs\" tooltip-placement=\"top\" uib-tooltip=\"This enable the institution to have MBA profile in hub\"></i>\n" +
    "                        </li>\n" +
    "                        <li class=\"list-group-item\">\n" +
    "                            <i ng-class=\"InstitutionsListStatusController.institution.subscriptions.tm.advanced ? 'fa-check text-navy' : 'fa-times'\" class=\"fa\"></i>\n" +
    "                            <span class=\"m-l-xs\">MBA advanced</span>\n" +
    "                            <span class=\"m-l-xs text-muted\"\n" +
    "                                  ng-show=\"InstitutionsListStatusController.institution.subscriptions.tm.advanced\">\n" +
    "                                {{InstitutionsListStatusController.institution.subscriptions.tm.startDate| date:'mediumDate'}} - {{InstitutionsListStatusController.institution.subscriptions.tm.endDate| date:'mediumDate'}}\n" +
    "                            </span>\n" +
    "                            <i class=\"fa fa-info-circle pull-right m-t-xs\" tooltip-placement=\"top\" uib-tooltip=\"Subscription date is compulsory if the MBA profile is advanced\"></i>\n" +
    "                        </li>\n" +
    "                        <li class=\"list-group-item\">\n" +
    "                            <i ng-class=\"InstitutionsListStatusController.institution.enabled.tm ? 'fa-check text-navy' : 'fa-times'\" class=\"fa\"></i>\n" +
    "                            <span class=\"m-l-xs\">Display on TopMba.com</span>\n" +
    "                            <i class=\"fa fa-info-circle pull-right m-t-xs\" tooltip-placement=\"top\" uib-tooltip=\"Enable to display MBA profile on frontend site\"></i>\n" +
    "                        </li>\n" +
    "                    </ul>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"col-lg-4\" ng-if=\"InstitutionsController.accessAllowed\" ng-class=\"{'modal-overlay':InstitutionsController.isSimpleDepartment(institution.typeId)}\">\n" +
    "            <div class=\"ibox float-e-margins\">\n" +
    "                <div class=\"ibox-title\">\n" +
    "                    <h5>Drupal</h5>\n" +
    "                </div>\n" +
    "                <div class=\"ibox-content no-padding\">\n" +
    "                    <ul class=\"list-group\">\n" +
    "                        <li class=\"list-group-item\" ng-if=\"!InstitutionsListStatusController.isAdvancedProgram\">\n" +
    "                            <i ng-class=\"InstitutionsListStatusController.institution.tuRegion ? 'fa-check text-navy' : 'fa-times text-danger'\" class=\"fa\"></i>\n" +
    "                            <span class=\"m-l-xs\">TU Region</span>\n" +
    "                            <span class=\"m-l-xs text-muted\"\n" +
    "                                  ng-show=\"InstitutionsListStatusController.institution.tuRegion\">\n" +
    "                                - {{InstitutionsListStatusController.institution.tuRegion}}\n" +
    "                            </span>\n" +
    "                            <i class=\"fa fa-info-circle pull-right m-t-xs\" tooltip-placement=\"top\" uib-tooltip=\"TU region is compulsory if profile has UG/PG subscription\"></i>\n" +
    "                        </li>\n" +
    "                        <li class=\"list-group-item\">\n" +
    "                            <i ng-class=\"InstitutionsListStatusController.institution.tmRegion ? 'fa-check text-navy' : 'fa-times text-danger'\" class=\"fa\"></i>\n" +
    "                            <span class=\"m-l-xs\">TM Region</span>\n" +
    "                            <span class=\"m-l-xs text-muted\"\n" +
    "                                  ng-show=\"InstitutionsListStatusController.institution.tmRegion\">\n" +
    "                                - {{InstitutionsListStatusController.institution.tmRegion}}\n" +
    "                            </span>\n" +
    "                            <i class=\"fa fa-info-circle pull-right m-t-xs\" tooltip-placement=\"top\" uib-tooltip=\"TM region is compulsory if profile has MBA subscription\"></i>\n" +
    "                        </li>\n" +
    "                    </ul>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/institutions/list/subscriptions/institutionsListSubscriptionsView.html',
    "<div class=\"panel-body\" ng-controller=\"InstitutionsSubscriptionsController as InstitutionsSubscriptionsController\">\n" +
    "    <div class=\"row\">\n" +
    "        <form name=\"forms.subscriptionForm\">\n" +
    "            <div class=\"col-lg-6 modal-overlay-35\">\n" +
    "                <div wave-spinner class=\"wave-spinner\" ng-show=\"InstitutionsSubscriptionsController.isProfilesLoading()\"></div>\n" +
    "                <div class=\"ibox float-e-margins\" ng-show=\"!InstitutionsSubscriptionsController.isProfilesLoading()\">\n" +
    "                    <div class=\"ibox-title\">\n" +
    "                        <h5>Profiles</h5>\n" +
    "                        <div class=\"ibox-tools\">\n" +
    "                            <a ng-click=\"showHide()\" class=\"collapse-link\">\n" +
    "                                <i class=\"fa fa-chevron-up\"></i>\n" +
    "                            </a>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"ibox-content\">\n" +
    "                        <div class=\"subscription-tu modal-overlay-35\" ng-if=\"InstitutionsSubscriptionsController.allowTu()\">\n" +
    "                            <label class=\"m-t-none m-b\">QS Hub</label>\n" +
    "\n" +
    "                            <div class=\"form-group subscriptions\">\n" +
    "                                <switch\n" +
    "                                    class=\"green\"\n" +
    "                                    ng-model=\"institution.subscriptions.tu.subscribed\"\n" +
    "                                    ng-disabled=\"InstitutionsController.isSimpleDepartment(institution.typeId) || !InstitutionsSubscriptionsController.isDowngradeAllowedTu(institution.subscriptions.tu.subscribed)\"\n" +
    "                                    uib-popover=\"Disabled due to associated child institutions. Child institutions must be switched off first.\"\n" +
    "                                    popover-placement=\"bottom\"\n" +
    "                                    popover-enable=\"!InstitutionsSubscriptionsController.isDowngradeAllowedTu(institution.subscriptions.tu.subscribed)\"\n" +
    "                                    popover-trigger=\"'mouseenter'\">\n" +
    "                                </switch> Enable UG / PG\n" +
    "                            </div>\n" +
    "\n" +
    "                            <div class=\"form-inline\" ng-class=\"{'modal-overlay-35': !institution.subscriptions.tu.subscribed}\">\n" +
    "                                <div class=\"form-group col-md-offset-1\">\n" +
    "                                    <switch\n" +
    "                                        class=\"green\"\n" +
    "                                        ng-model=\"institution.subscriptions.tu.advanced\"\n" +
    "                                        ng-disabled=\"InstitutionsController.isSimpleDepartment(institution.typeId)\"></switch> Advanced\n" +
    "                                    <a class=\"btn btn-default btn-xs\"\n" +
    "                                        ng-click=\"InstitutionsSubscriptionsController.handleHistoryLogClick('tu')\">\n" +
    "                                        <i class=\"fa fa-clock-o\"></i>\n" +
    "                                    </a>\n" +
    "                                    <span ng-if=\"institution.subscriptions.tu.startDate > InstitutionsSubscriptionsController.getCurrentDate() && !institution.subscriptions.tu.advanced\">\n" +
    "                                        <small class=\"badge badge-info\">\n" +
    "                                            {{institution.subscriptions.tu.startDate | mDate}} - {{institution.subscriptions.tu.endDate| mDate}}\n" +
    "                                        </small>\n" +
    "                                        <small>\n" +
    "                                            <p>Profile will become advanced 1 week prior to the subscription date for editing and publishing purposes.</p>\n" +
    "                                        </small>\n" +
    "                                    </span>\n" +
    "                                    <span ng-if=\"!InstitutionsController.isSimpleDepartment(institution.typeId)\"\n" +
    "                                          class=\"date-picker form-control btn m-t\" type=\"text\"\n" +
    "                                          date-range-picker\n" +
    "                                          options=\"datePickerTu.options\"\n" +
    "                                          ng-model=\"datePickerTu.date\"\n" +
    "                                          ng-style=\"{'display': institution.subscriptions.tu.advanced ? 'block' : 'none'}\">\n" +
    "                                        {{institution.subscriptions.tu.startDate| mDate}} - {{institution.subscriptions.tu.endDate| mDate}}\n" +
    "                                    </span>\n" +
    "                                    <span ng-if=\"InstitutionsController.isSimpleDepartment(institution.typeId)\" class=\"form-control\" disabled=\"disabled\">\n" +
    "                                        {{institution.subscriptions.tu.startDate| mDate}} - {{institution.subscriptions.tu.endDate| mDate}}\n" +
    "                                    </span>\n" +
    "                                    <p>\n" +
    "                                        <i class=\"text-red\" ng-show=\"InstitutionsController.isTuSubscriptionDatesInvalid()\">\n" +
    "                                            Add the date before you save\n" +
    "                                        </i>\n" +
    "                                    </p>\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "\n" +
    "                            <div class=\"form-group\" ng-show=\"InstitutionsSubscriptionsController.isHistoryLogVisible('tu')\">\n" +
    "                                <div wave-spinner class=\"wave-spinner\" ng-show=\"InstitutionsSubscriptionsController.isSubscriptionsLogLoading('tu')\"></div>\n" +
    "                                <div class=\"history-log tu col-md-offset-1 text-muted\">\n" +
    "                                    <div class=\"history-log-content\" ng-show=\"!InstitutionsSubscriptionsController.isSubscriptionsLogLoading('tu')\">\n" +
    "                                        <label class=\"m-t-none m-b\">Subscription History</label>\n" +
    "                                        <div class=\"history-items\"\n" +
    "                                             ng-show=\"InstitutionsSubscriptionsController.getSubscriptionsLog('tu').length\"\n" +
    "                                             ng-repeat=\"item in InstitutionsSubscriptionsController.getSubscriptionsLog('tu')\">\n" +
    "                                            <i class=\"fa fa-bell\" aria-hidden=\"true\" ng-if=\"item.future\"></i> {{item.startDate | mDate}} - {{item.endDate | mDate}}\n" +
    "                                            <span\n" +
    "                                                class=\"info\"\n" +
    "                                                ng-if=\"item.createdAt\"\n" +
    "                                                uib-tooltip='Submitted by {{item.createdByFullName}} at {{item.createdAt | mDate}} '\n" +
    "                                                tooltip-placement=\"top\">\n" +
    "                                                <i class=\"fa fa-info-circle\"></i>\n" +
    "                                            </span>\n" +
    "                                        </div>\n" +
    "                                        <div class=\"history-items-empty\" ng-show=\"!InstitutionsSubscriptionsController.getSubscriptionsLog('tu').length\">\n" +
    "                                            Log is empty\n" +
    "                                        </div>\n" +
    "                                        <div class=\"row\" ng-show=\"InstitutionsSubscriptionsController.isMoreLogsAvailable()\">\n" +
    "                                            <div wave-spinner class=\"text-right\" ng-show=\"InstitutionsSubscriptionsController.isLoadMoreTuSubscriptionsLogsInProgress\"></div>\n" +
    "                                            <div class=\" col-lg-8 col-lg-offset-2\">\n" +
    "                                                <a class=\"btn btn-primary btn-block\" ng-click=\"InstitutionsSubscriptionsController.handleLoadMoreHistoryLogs()\" ng-disabled=\"InstitutionsSubscriptionsController.isLoadMoreTuSubscriptionsLogsInProgress\">\n" +
    "                                                   Load more\n" +
    "                                                </a>\n" +
    "                                            </div>\n" +
    "                                        </div>\n" +
    "                                    </div>\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "    \n" +
    "                            <label class=\"m-t-none m-b\">Display on topuniversities.com</label>\n" +
    "\n" +
    "                            <div class=\"form-group\">\n" +
    "                                <switch ng-model=\"institution.enabled.ug\" ng-disabled=\"!institution.subscriptions.tu.subscribed || InstitutionsController.isSimpleDepartment(institution.typeId)\" class=\"green\"></switch> Undergraduate\n" +
    "                            </div>\n" +
    "\n" +
    "                            <div class=\"form-group\">\n" +
    "                                <switch ng-model=\"institution.enabled.pg\" ng-disabled=\"!institution.subscriptions.tu.subscribed || InstitutionsController.isSimpleDepartment(institution.typeId)\" class=\"green\"></switch> Postgraduate\n" +
    "                            </div>\n" +
    "\n" +
    "                            <label class=\"m-t-none m-b\">Display on QSChina.cn</label>\n" +
    "\n" +
    "                            <div class=\"form-group\">\n" +
    "                                <switch ng-model=\"institution.enabled.ugChina\" ng-disabled=\"!institution.subscriptions.tu.subscribed || InstitutionsController.isSimpleDepartment(institution.typeId)\" class=\"green\"></switch> Undergraduate\n" +
    "                            </div>\n" +
    "\n" +
    "                            <div class=\"form-group\">\n" +
    "                                <switch ng-model=\"institution.enabled.pgChina\" ng-disabled=\"!institution.subscriptions.tu.subscribed || InstitutionsController.isSimpleDepartment(institution.typeId)\" class=\"green\"></switch> Postgraduate\n" +
    "                            </div>\n" +
    "\n" +
    "                            <div class=\"alert alert-warning\" ng-show=\"InstitutionsController.hasTuWarning()\">\n" +
    "                                {{InstitutionsController.getTuWarningMessage()}}\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "\n" +
    "                        <div class=\"hr-line-dashed\" ng-show=\"!institution.subscriptions.tu.advanced\"></div>\n" +
    "\n" +
    "                        <div class=\"subscription-tu modal-overlay-35\" ng-show=\"!institution.subscriptions.tu.advanced\">\n" +
    "                            <label class=\"m-t-none m-b\">QS Hub</label>\n" +
    "\n" +
    "                            <div class=\"form-group subscriptions\">\n" +
    "                               <switch class=\"green\"\n" +
    "                                    ng-model=\"institution.subscriptions.tu.isBasicProgramsEnabled\">\n" +
    "                                </switch> Enable Basic Programs\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "\n" +
    "                        <div class=\"hr-line-dashed\"></div>\n" +
    "\n" +
    "                        <div class=\"subscription-tu modal-overlay-35\">\n" +
    "                            <label class=\"m-t-none m-b\">QS Hub</label>\n" +
    "\n" +
    "                            <div class=\"form-group subscriptions\">\n" +
    "                               <switch class=\"green\"\n" +
    "                                    ng-model=\"institution.subscriptions.tu.crimsonBasic\">\n" +
    "                                </switch> Crimson Basic\n" +
    "                            </div>\n" +
    "                            <div class=\"form-group subscriptions\">\n" +
    "                                 <switch class=\"green\"\n" +
    "                                     ng-model=\"institution.subscriptions.tu.crimsonAdvance\">\n" +
    "                                 </switch> Crimson Advance\n" +
    "                             </div>\n" +
    "                        </div>\n" +
    "                        \n" +
    "                        <div class=\"hr-line-dashed\"></div>\n" +
    "\n" +
    "                        <div class=\"subscription-tm modal-overlay-35\">\n" +
    "                            <label class=\"m-t-none m-b\">QS Hub</label>\n" +
    "\n" +
    "                            <div class=\"form-group subscriptions\">\n" +
    "                                <switch class=\"green\"\n" +
    "                                        ng-model=\"institution.subscriptions.tm.subscribed\"\n" +
    "                                        ng-change=\"InstitutionsController.handleTmSubscribed()\"\n" +
    "                                        ng-disabled=\"InstitutionsController.isSimpleDepartment(institution.typeId) || !InstitutionsSubscriptionsController.isDowngradeAllowedTm(institution.subscriptions.tm.subscribed)\"\n" +
    "                                        uib-popover=\"Disabled due to associated child institutions. Child institutions must be switched off first.\"\n" +
    "                                        popover-placement=\"bottom\"\n" +
    "                                        popover-enable=\"!InstitutionsSubscriptionsController.isDowngradeAllowedTm(institution.subscriptions.tm.subscribed)\"\n" +
    "                                        popover-trigger=\"'mouseenter'\">\n" +
    "                                </switch> Enable MBA\n" +
    "                                <a class=\"btn btn-default btn-xs\"\n" +
    "                                    ng-click=\"InstitutionsSubscriptionsController.handleHistoryLogClick('tm')\">\n" +
    "                                    <i class=\"fa fa-clock-o\"></i>\n" +
    "                                </a>\n" +
    "                            </div>\n" +
    "\n" +
    "                            <div class=\"form-inline\">\n" +
    "                                <div class=\"form-group col-md-offset-1\" ng-class=\"{'modal-overlay-35': !institution.subscriptions.tm.subscribed}\">\n" +
    "                                    <switch class=\"green\"\n" +
    "                                            ng-model=\"institution.subscriptions.tm.advanced\"\n" +
    "                                            ng-disabled=\"InstitutionsController.isSimpleDepartment(institution.typeId)\"></switch> Advanced\n" +
    "                                    <span ng-if=\"institution.subscriptions.tm.startDate > InstitutionsSubscriptionsController.getCurrentDate() && !institution.subscriptions.tm.advanced\">\n" +
    "                                        <small class=\"badge badge-info\">\n" +
    "                                            {{institution.subscriptions.tm.startDate | mDate}} - {{institution.subscriptions.tm.endDate| mDate}}\n" +
    "                                        </small>\n" +
    "                                        <small>\n" +
    "                                            <p>Profile will become advanced 1 week prior to the subscription date for editing and publishing purposes.</p>\n" +
    "                                        </small>\n" +
    "                                    </span>\n" +
    "                                    <span class=\"date-picker form-control btn m-t\" type=\"text\"\n" +
    "                                            ng-if=\"!InstitutionsController.isSimpleDepartment(institution.typeId)\"\n" +
    "                                            date-range-picker\n" +
    "                                            options=\"datePickerTm.options\"\n" +
    "                                            ng-model=\"datePickerTm.date\"\n" +
    "                                            ng-style=\"{'display': institution.subscriptions.tm.advanced  ? 'block' : 'none'}\">\n" +
    "                                        {{institution.subscriptions.tm.startDate| mDate}} - {{institution.subscriptions.tm.endDate| mDate}}\n" +
    "                                    </span>\n" +
    "                                    <span ng-if=\"InstitutionsController.isSimpleDepartment(institution.typeId)\" class=\"form-control\" disabled=\"disabled\">\n" +
    "                                        {{institution.subscriptions.tm.startDate| mDate}} - {{institution.subscriptions.tm.endDate| mDate}}\n" +
    "                                    </span>\n" +
    "                                    <p>\n" +
    "                                        <i class=\"text-red\" ng-show=\"InstitutionsController.isTmSubscriptionDatesInvalid()\">\n" +
    "                                            Add the date before you save\n" +
    "                                        </i>\n" +
    "                                    </p>\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "\n" +
    "                            <div class=\"form-group\" ng-show=\"InstitutionsSubscriptionsController.isHistoryLogVisible('tm')\">\n" +
    "                                <div wave-spinner class=\"wave-spinner\" ng-show=\"InstitutionsSubscriptionsController.isSubscriptionsLogLoading('tm')\"></div>\n" +
    "                                <div class=\"history-log tm col-md-offset-1 text-muted\">\n" +
    "                                    <div class=\"history-log-content\" ng-show=\"!InstitutionsSubscriptionsController.isSubscriptionsLogLoading('tm')\">\n" +
    "                                        <label class=\"m-t-none m-b\">Subscription History</label>\n" +
    "                                        <div class=\"history-items\"\n" +
    "                                                ng-show=\"InstitutionsSubscriptionsController.getSubscriptionsLog('tm').length\"\n" +
    "                                                ng-repeat=\"item in InstitutionsSubscriptionsController.getSubscriptionsLog('tm')\">\n" +
    "                                            <i class=\"fa fa-bell\" aria-hidden=\"true\" ng-if=\"item.future\"></i> {{item.startDate | mDate}} - {{item.endDate | mDate}}\n" +
    "                                            <span\n" +
    "                                                class=\"info\"\n" +
    "                                                ng-if=\"item.createdAt\"\n" +
    "                                                uib-tooltip='Submitted by {{item.createdByFullName}} at {{item.createdAt | mDate}} '\n" +
    "                                                tooltip-placement=\"top\">\n" +
    "                                                <i class=\"fa fa-info-circle\"></i>\n" +
    "                                            </span>\n" +
    "                                        </div>\n" +
    "                                        <div class=\"history-items-empty\" ng-show=\"!InstitutionsSubscriptionsController.getSubscriptionsLog('tm').length\">\n" +
    "                                            Log is empty\n" +
    "                                        </div>\n" +
    "                                        <div class=\"row\" ng-show=\"InstitutionsSubscriptionsController.isMoreTmLogsAvailable()\">\n" +
    "                                            <div wave-spinner class=\"text-right\" ng-show=\"InstitutionsSubscriptionsController.isLoadMoreTmSubscriptionsLogsInProgress\"></div>\n" +
    "                                            <div class=\" col-lg-8 col-lg-offset-2\">\n" +
    "                                                <a class=\"btn btn-primary btn-block\" ng-click=\"InstitutionsSubscriptionsController.handleLoadMoreTmHistoryLogs()\" ng-disabled=\"InstitutionsSubscriptionsController.isLoadMoreTmSubscriptionsLogsInProgress\">\n" +
    "                                                    Load more\n" +
    "                                                </a>\n" +
    "                                            </div>\n" +
    "                                        </div>\n" +
    "                                    </div>\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "\n" +
    "                            <div ng-show=\"InstitutionsController.isTopLevelInstitution(institution.typeId) || InstitutionsController.isClientDepartment(institution.typeId)\">\n" +
    "                                <div class=\"form-group\">\n" +
    "                                    <switch class=\"green\"\n" +
    "                                            ng-model=\"institution.linkedWithAdvancedPrograms\"\n" +
    "                                            ng-disabled=\"!institution.subscriptions.tm.subscribed\"></switch>\n" +
    "                                    Link with advanced programs subscriptions\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "\n" +
    "                            <label class=\"m-t-none m-b\">Display on topmba.com</label>\n" +
    "\n" +
    "                            <div class=\"form-group\">\n" +
    "                                <switch ng-model=\"institution.enabled.tm\" ng-disabled=\"!institution.subscriptions.tm.subscribed || InstitutionsController.isSimpleDepartment(institution.typeId)\" class=\"green\"></switch> MBA\n" +
    "                            </div>\n" +
    "\n" +
    "                            <div class=\"alert alert-warning\" ng-show=\"InstitutionsController.hasTmWarning()\">\n" +
    "                                {{InstitutionsController.getTmWarningMessage()}}\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "    \n" +
    "                        <div class=\"hr-line-dashed\"></div>\n" +
    "\n" +
    "                        <div class=\"subscription-accord\">\n" +
    "                                <div class=\"form-group subscriptions\">\n" +
    "                                    <switch class=\"green\"\n" +
    "                                            ng-model=\"institution.subscriptions.accord.subscribed\">\n" +
    "                                    </switch> QS Accord\n" +
    "                                   \n" +
    "                                    <a class=\"btn btn-default btn-xs\"\n" +
    "                                       ng-click=\"InstitutionsSubscriptionsController.handleHistoryLogClick('accord')\">\n" +
    "                                        <i class=\"fa fa-clock-o\"></i>\n" +
    "                                    </a>\n" +
    "                                </div>\n" +
    "                                <div class=\"row\">\n" +
    "                                    <div class=\"col-lg-7 form-subgroup width-90-percent\" ng-class=\"{'modal-overlay-35': !institution.subscriptions.accord.subscribed}\">\n" +
    "                                        <span class=\"date-picker form-control btn block\" type=\"text\"\n" +
    "                                              date-range-picker\n" +
    "                                              options=\"InstitutionsController.datePickerAccord.options\"\n" +
    "                                              ng-model=\"InstitutionsController.datePickerAccord.date\"\n" +
    "                                              ng-show=\"institution.subscriptions.accord.subscribed\">\n" +
    "                                            {{institution.subscriptions.accord.startDate| mDate}} - {{institution.subscriptions.accord.endDate| mDate}}\n" +
    "                                        </span>\n" +
    "                                        <p>\n" +
    "                                            <i class=\"text-red\" ng-show=\"InstitutionsController.isAccordSubscriptionDatesInvalid()\">\n" +
    "                                                Add the date before you save\n" +
    "                                            </i>\n" +
    "                                        </p>\n" +
    "                                    </div>\n" +
    "                                </div>\n" +
    "                                <div class=\"form-group\" ng-show=\"InstitutionsSubscriptionsController.isHistoryLogVisible('accord')\">\n" +
    "                                    <div wave-spinner class=\"wave-spinner\" ng-show=\"InstitutionsSubscriptionsController.isSubscriptionsLogLoading('accord')\"></div>\n" +
    "                                    <div class=\"history-log accord col-md-offset-1 text-muted\">\n" +
    "                                        <div class=\"history-log-content\" ng-show=\"!InstitutionsSubscriptionsController.isSubscriptionsLogLoading('accord')\">\n" +
    "                                            <label class=\"m-t-none m-b\">Subscription History</label>\n" +
    "                                            <div class=\"history-items\"\n" +
    "                                                 ng-show=\"InstitutionsSubscriptionsController.getSubscriptionsLog('accord').length\"\n" +
    "                                                 ng-repeat=\"item in InstitutionsSubscriptionsController.getSubscriptionsLog('accord')\">\n" +
    "                                                <i class=\"fa fa-bell\" aria-hidden=\"true\" ng-if=\"item.future\"></i> {{item.startDate | mDate}} - {{item.endDate | mDate}}\n" +
    "                                                <span\n" +
    "                                                    class=\"info\"\n" +
    "                                                    ng-if=\"item.createdAt\"\n" +
    "                                                    uib-tooltip='Submitted by {{item.createdByFullName}} at {{item.createdAt | mDate}} '\n" +
    "                                                    tooltip-placement=\"top\">\n" +
    "                                                    <i class=\"fa fa-info-circle\"></i>\n" +
    "                                                </span>\n" +
    "                                            </div>\n" +
    "                                            <div class=\"history-items-empty\" ng-show=\"!InstitutionsSubscriptionsController.getSubscriptionsLog('accord').length\">\n" +
    "                                                Log is empty\n" +
    "                                            </div>\n" +
    "                                            <div class=\"row\" ng-show=\"InstitutionsSubscriptionsController.isMoreAccordLogsAvailable()\">\n" +
    "                                                <div wave-spinner class=\"text-right\" ng-show=\"InstitutionsSubscriptionsController.isLoadMoreAccordSubscriptionsLogsInProgress\"></div>\n" +
    "                                                <div class=\" col-lg-8 col-lg-offset-2\">\n" +
    "                                                    <a class=\"btn btn-primary btn-block\" ng-click=\"InstitutionsSubscriptionsController.handleLoadMoreAccordHistoryLogs()\" ng-disabled=\"InstitutionsSubscriptionsController.isLoadMoreAccordSubscriptionsLogsInProgress\">\n" +
    "                                                       Load more\n" +
    "                                                    </a>\n" +
    "                                                </div>\n" +
    "                                            </div>\n" +
    "                                        </div>\n" +
    "                                    </div>\n" +
    "                                </div>\n" +
    "                        </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "    \n" +
    "                <div class=\"col-lg-6\">\n" +
    "                    <div class=\"ibox float-e-margins\">\n" +
    "                        <div class=\"ibox-title\">\n" +
    "                            <h5>Statistics</h5>\n" +
    "                            <div class=\"ibox-tools\">\n" +
    "                                <a ng-click=\"showHide()\">\n" +
    "                                    <i class=\"fa fa-chevron-up\"></i>\n" +
    "                                </a>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                        <div class=\"ibox-content\">\n" +
    "                            <div ng-repeat=\"(key, value) in InstitutionsController.subscriptionsList\">\n" +
    "                                <div class=\"form-group subscriptions\" ng-if=\"value.handle !== 'ger'\"\n" +
    "                                     ng-class=\"{'modal-overlay-35': value.handle === 'stars' && InstitutionsSubscriptionsController.isStarsAnalyst()}\">\n" +
    "                                    <switch\n" +
    "                                        ng-change=\"InstitutionsSubscriptionsController.handleSubscribed(value.handle, institution.subscriptions[value.handle].subscribed)\"\n" +
    "                                        ng-model=\"institution.subscriptions[value.handle].subscribed\"\n" +
    "                                        class=\"green\"\n" +
    "                                        ng-disabled=\"InstitutionsController.isSimpleDepartment(institution.typeId) ||\n" +
    "                                        InstitutionsController.isAllStatistic(value.handle) || institution.subscriptions[value.handle].disabled\"\n" +
    "                                        \n" +
    "                                        uib-popover=\"To remove WUR for this profile, regional rankings must be removed too.\"\n" +
    "                                        popover-placement=\"bottom\"\n" +
    "                                        popover-enable=\"institution.subscriptions[value.handle].disabled\"\n" +
    "                                        popover-trigger=\"'mouseenter'\">\n" +
    "                                    </switch> {{value.name}}\n" +
    "                                    <a class=\"btn btn-default btn-xs\"  ng-if=\"value.handle == 'stars'\"\n" +
    "                                        ng-click=\"InstitutionsSubscriptionsController.handleHistoryLogClick('stars')\">\n" +
    "                                        <i class=\"fa fa-clock-o\"></i>\n" +
    "                                    </a>\n" +
    "                                </div>\n" +
    "                                <div ng-if=\"value.handle === 'stars'\"\n" +
    "                                     ng-class=\"{ 'modal-overlay-35': InstitutionsSubscriptionsController.isStarsAnalyst() }\">\n" +
    "                                    <div class=\"form-inline\">\n" +
    "                                        <div class=\"form-group col-md-offset-1\" ng-class=\"{'modal-overlay-35': !institution.subscriptions.stars.subscribed}\">\n" +
    "                                            <switch class=\"green\"\n" +
    "                                                    ng-model=\"institution.subscriptions.stars.advanced\">\n" +
    "                                            </switch> Show in client section\n" +
    "                                            <br/>\n" +
    "                                            <switch class=\"green\"\n" +
    "                                                    ng-model=\"institution.subscriptions.stars.publish\">\n" +
    "                                            </switch> Display on topuniversities.com\n" +
    "                                            <br/>\n" +
    "                                            <switch class=\"green\"\n" +
    "                                                    ng-model=\"institution.enabled.starsChina\">\n" +
    "                                            </switch> Display on QSChina.cn\n" +
    "                                            <div class=\"row\">\n" +
    "                                                <div class=\"col-lg-9 form-subgroup\" ng-class=\"{'has-errors': InstitutionsController.isStarsSubscriptionDatesInvalid()}\">\n" +
    "                                                    <span class=\"date-picker form-control btn m-t block\" type=\"text\"\n" +
    "                                                          date-range-picker\n" +
    "                                                          options=\"InstitutionsController.datePickerStars.options\"\n" +
    "                                                          ng-model=\"InstitutionsController.datePickerStars.date\">\n" +
    "                                                        {{institution.subscriptions.stars.startDate| mDate}} - {{institution.subscriptions.stars.endDate| mDate}}\n" +
    "                                                    </span>\n" +
    "                                                    <div focus-delay=\"250\"\n" +
    "                                                        custom-popover\n" +
    "                                                        popover-html=\"Add a date\"\n" +
    "                                                        popover-placement=\"left\"\n" +
    "                                                        popover-trigger=\"manual\"\n" +
    "                                                        popover-visibility=\"{{InstitutionsController.isStarsSubscriptionDatesInvalid() ? true : false}}\">\n" +
    "                                                    </div>\n" +
    "                                                </div>\n" +
    "                                                <div class=\"col-lg-3\">\n" +
    "                                                    <span class=\"m-t block\" ng-class=\"{'modal-overlay-35': InstitutionsController.isEditStarsEditDisabled}\">\n" +
    "                                                        <switch class=\"green\"\n" +
    "                                                                ng-model=\"institution.subscriptions.stars.edit\"\n" +
    "                                                                ng-disabled=\"InstitutionsController.isEditStarsEditDisabled\"\n" +
    "                                                                ng-change=\"InstitutionsController.handleStarsSubscriptionEditModeClick()\">\n" +
    "                                                        </switch>  Edit\n" +
    "                                                    </span>\n" +
    "                                                </div>\n" +
    "                                            </div>\n" +
    "                                            <div class=\"row form-subgroup m-b-xs\" ng-if=\"!institution.businessSchool\" ng-class=\"{'has-errors': InstitutionsController.isStarsSubscriptionVersionInvalid()}\">\n" +
    "                                                <div class=\"col-lg-7\">\n" +
    "                                                    <ui-select\n" +
    "                                                        name=\"starsVersion\"\n" +
    "                                                        ng-model=\"institution.subscriptions.stars.version\"\n" +
    "                                                        theme=\"bootstrap\"\n" +
    "                                                        ng-disabled=\"InstitutionsController.isVersionEnabled()\"\n" +
    "                                                        custom-popover\n" +
    "                                                        popover-html=\"Add a version\"\n" +
    "                                                        popover-placement=\"left\"\n" +
    "                                                        popover-trigger=\"manual\"\n" +
    "                                                        popover-visibility=\"{{InstitutionsController.isStarsSubscriptionVersionInvalid() ? true : false}}\">\n" +
    "                                                        <ui-select-match placeholder=\"Select version\">{{$select.selected.label}}</ui-select-match>\n" +
    "                                                        <ui-select-choices\n" +
    "                                                            position=\"down\"\n" +
    "                                                            repeat=\"option.value as option in InstitutionsSubscriptionsController.starsVersions\">\n" +
    "                                                            <div ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                                                        </ui-select-choices>\n" +
    "                                                        <ui-select-no-choice>\n" +
    "                                                            Not found\n" +
    "                                                        </ui-select-no-choice>\n" +
    "                                                    </ui-select>\n" +
    "                                                </div>\n" +
    "                                            </div>\n" +
    "                                        </div>\n" +
    "                                    </div>\n" +
    "                                    <div class=\"form-group\" ng-show=\"InstitutionsSubscriptionsController.isHistoryLogVisible('stars')\">\n" +
    "                                        <div wave-spinner class=\"wave-spinner\" ng-show=\"InstitutionsSubscriptionsController.isSubscriptionsLogLoading('stars')\"></div>\n" +
    "                                        <div class=\"history-log tm col-md-offset-1 text-muted\">\n" +
    "                                            <div class=\"history-log-content\" ng-show=\"!InstitutionsSubscriptionsController.isSubscriptionsLogLoading('stars')\">\n" +
    "                                                <label class=\"m-t-none m-b\">Subscription History</label>\n" +
    "                                                <div class=\"history-items\"\n" +
    "                                                    ng-show=\"InstitutionsSubscriptionsController.getSubscriptionsLog('stars').length\"\n" +
    "                                                    ng-repeat=\"item in InstitutionsSubscriptionsController.getSubscriptionsLog('stars')\">\n" +
    "                                                    <i class=\"fa fa-bell\" aria-hidden=\"true\" ng-if=\"item.future\"></i>\n" +
    "                                                    <span ng-if=\"item.version\">\n" +
    "                                                        {{InstitutionsSubscriptionsController.mapVersion(item.version)}}, \n" +
    "                                                    </span>\n" +
    "                                                    {{item.startDate | mDate}} - {{item.endDate | mDate}}\n" +
    "                                                    <span\n" +
    "                                                        class=\"info\"\n" +
    "                                                        ng-if=\"item.createdByFullName\"\n" +
    "                                                        uib-tooltip='Submitted by {{item.createdByFullName}} at {{item.createdAt | mDate}} '\n" +
    "                                                        tooltip-placement=\"top\">\n" +
    "                                                        <i class=\"fa fa-info-circle\"></i>\n" +
    "                                                    </span>\n" +
    "                                                </div>\n" +
    "                                                <div class=\"history-items-empty\" ng-show=\"!InstitutionsSubscriptionsController.getSubscriptionsLog('stars').length\">\n" +
    "                                                    Log is empty\n" +
    "                                                </div>\n" +
    "                                                <div class=\"row\" ng-show=\"InstitutionsSubscriptionsController.isMoreStarsLogsAvailable()\">\n" +
    "                                                    <div wave-spinner class=\"text-right\" ng-show=\"InstitutionsSubscriptionsController.isLoadMoreStarsSubscriptionsLogsInProgress\"></div>\n" +
    "                                                    <div class=\" col-lg-8 col-lg-offset-2\">\n" +
    "                                                        <a class=\"btn btn-primary btn-block\" ng-click=\"InstitutionsSubscriptionsController.handleLoadMoreStarsHistoryLogs()\" ng-disabled=\"InstitutionsSubscriptionsController.isLoadMoreStarsSubscriptionsLogsInProgress\">\n" +
    "                                                           Load more\n" +
    "                                                        </a>\n" +
    "                                                    </div>\n" +
    "                                                </div>\n" +
    "                                            </div>\n" +
    "                                        </div>\n" +
    "                                    </div>\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                        <a class=\"btn btn-primary btn-block\"\n" +
    "                               ng-class=\"{'disabled': disabledSubmit() || InstitutionsController.isSimpleDepartment(institution.typeId)}\"\n" +
    "                               ng-click=\"handleSubscriptionSubmit()\">Update</a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </form>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    "
  );


  $templateCache.put('/scripts/components/institutions/tu-programs/institutionsTuProgramsView.html',
    "<div class=\"wrapper wrapper-content animated fadeInRight page-institution\" ng-controller=\"InstitutionsTuProgramsController as ProgramsController\">\n" +
    "    <div class=\"row\">\n" +
    "        <div ng-class=\"isRightSidePanelActive() ? 'col-sm-8' : 'col-sm-12'\">\n" +
    "            <!-- SECTION -->\n" +
    "            <div class=\"section\">\n" +
    "                <!-- SECTION HEADER -->\n" +
    "                <div class=\"section-header\">\n" +
    "                    <!-- Institution switch for QS users -->\n" +
    "                    <div class=\"search-dropdown pull-left\">\n" +
    "                        <ui-select\n" +
    "                            class=\"search-dropdown\"\n" +
    "                            ng-model=\"selectedInstitution.selected\"\n" +
    "                            theme=\"bootstrap\"\n" +
    "                            ng-change=\"handleSearchInstitutionClick(selectedInstitution.selected)\">\n" +
    "                            <ui-select-match placeholder=\"Institution search...\">\n" +
    "                                <i class=\"fa fa-building\"></i>\n" +
    "                                <span>{{$select.selected.name}}</span>\n" +
    "                            </ui-select-match>\n" +
    "                            <ui-select-choices\n" +
    "                                refresh-delay=\"500\"\n" +
    "                                refresh=\"ProgramsController.searchInstitution($select.search)\"\n" +
    "                                position=\"down\"\n" +
    "                                repeat=\"option in ProgramsController.institutionsDropdownList | filter: $select.search\">\n" +
    "                                <div class=\"test\" ng-bind-html=\"option.name | highlight: $select.search\"></div>\n" +
    "                            </ui-select-choices>\n" +
    "                        </ui-select>\n" +
    "                        <div class=\"spinner\" ng-show=\"ProgramsController.searchInProgress\" wave-spinner></div>\n" +
    "                    </div>\n" +
    "\n" +
    "                    <button class=\"btn btn-primary pull-right\"\n" +
    "                        type=\"button\"\n" +
    "                        ng-click=\"handleAddProgramClick()\"\n" +
    "                        ng-disabled=\"programsTabSubmitInProgress || !isInstitutionSelected() || isRightSidePanelActive()\">\n" +
    "                        <i class=\"fa fa-plus\"></i>\n" +
    "                        <span>Add Program</span>\n" +
    "                    </button>\n" +
    "\n" +
    "                </div><!-- /SECTION HEADER -->\n" +
    "\n" +
    "                <!-- SECTION BODY -->\n" +
    "                <div class=\"section-body\">\n" +
    "                    <div id=\"programsTable\" ng-class=\"{'locked': !isInstitutionSelected()}\">\n" +
    "                        <div wave-spinner class=\"wave-spinner\" ng-show=\"isDatagridReloading || !isDatagridRendered\"></div>\n" +
    "\n" +
    "                        <div ng-if=\"!isDatagridReloading && gridOptions\">\n" +
    "                            <ui-grid-info ng-if=\"!isRightSidePanelActive()\"></ui-grid-info>\n" +
    "                            <div class=\"grid\"\n" +
    "                                ui-grid=\"gridOptions\"\n" +
    "                                ui-grid-draggable-rows\n" +
    "                                ui-grid-selection\n" +
    "                                ui-grid-resize-columns\n" +
    "                                ui-grid-auto-resize>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "              </div><!-- /SECTION BODY -->\n" +
    "            </div><!-- /SECTION -->\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"col-sm-4 right-side-container\"\n" +
    "            ng-show=\"showProgramEditForm || showProgramAddForm\"\n" +
    "            fixed-element-while-scrolling=\"#programsTable\"\n" +
    "            ng-controller=\"InstitutionsTuProgramsFormController as ProgramFormController\">\n" +
    "            <div ng-show=\"showProgramEditForm\" ng-include src=\"'/scripts/components/profiles/tu/programs/tuProfileEditProgramFormView.html'\"></div>\n" +
    "            <div ng-show=\"showProgramAddForm\" ng-include src=\"'/scripts/components/profiles/tu/programs/tuProfileAddProgramFormView.html'\"></div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/components/login/loginFormView.html',
    "<div class=\"animated fadeInDown w-300 box-middle\">\n" +
    "    <form class=\"m-t\" name=\"forms.loginForm\" ng-submit=\"handleLogin()\" novalidate autocomplete=\"off\">\n" +
    "        <div class=\"form-group\">\n" +
    "            <input name=\"email\" type=\"email\" class=\"form-control\" ng-class=\"{'border-red': error}\" placeholder=\"Username\" ng-model=\"credentials.username\" ng-focus=\"resetErrors()\" ng-required=\"true\">\n" +
    "        </div>\n" +
    "        <div class=\"form-group\">\n" +
    "            <input name=\"password\" type=\"password\" class=\"form-control\" ng-class=\"{'border-red': error}\" placeholder=\"Password\" ng-model=\"credentials.password\" ng-focus=\"resetErrors()\" ng-required=\"true\">\n" +
    "        </div>\n" +
    "        <div class=\"form-group\">\n" +
    "            <button ng-disabled=\"loginInProgress\" class=\"btn btn-primary block full-width m-b\">Login</button>\n" +
    "            <div ng-show=\"error\" class=\"alert alert-danger\" ng-class=\"{'animated shake': animate}\">{{error}}</div>\n" +
    "            <div ng-show=\"loginInProgress\" wave-spinner class=\"text-right\"></div>\n" +
    "        </div>\n" +
    "\n" +
    "        <a ng-click=\"toggleForgottenPasswordForm()\"><small>Forgot password?</small></a>\n" +
    "    </form>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/login/passwordForgotView.html',
    "<div class=\"password-box animated fadeInDown w-300 box-middle\">\n" +
    "    <h2 class=\"font-bold\">Forgot password</h2>\n" +
    "    <div ng-show=\"!showResetPasswordConfirmation\">\n" +
    "        <p>\n" +
    "            Enter your email address and your password will be reset and emailed to you.\n" +
    "        </p>\n" +
    "        <form name=\"forms.forgotPasswordForm\" class=\"m-t clearfix\" novalidate role=\"form\" autocomplete=\"off\">\n" +
    "            <div class=\"form-group\">\n" +
    "                <input type=\"email\" name=\"email\"\n" +
    "                       class=\"form-control\"\n" +
    "                       ng-class=\"{'red-border': forms.forgotPasswordForm.$submitted && forms.forgotPasswordForm.$invalid}\"\n" +
    "                       placeholder=\"Email address\"\n" +
    "                       ng-required=\"true\"\n" +
    "                       ng-model=\"forgottenPassword.email\">\n" +
    "            </div>\n" +
    "            <div class=\"form-group\">\n" +
    "                <button type=\"submit\" class=\"btn btn-primary btn-block full-width m-b\" ng-class=\"{'disabled':resetSubmitInProgress}\" ng-click=\"handleResetPasswordSubmitClick()\"><i class=\"fa fa-envelope\"></i> Request password reset</button>\n" +
    "                <div ng-show=\"forgottenPasswordError\" class=\"alert alert-danger\" ng-class=\"animate ? 'animated shake' : ''\">{{forgottenPasswordErrorMessage}}</div>\n" +
    "            </div>\n" +
    "        </form>\n" +
    "    </div>\n" +
    "    <div ng-show=\"showResetPasswordConfirmation\">\n" +
    "        <p>Thank you. An email with instructions to reset your password has been sent to you.</p>\n" +
    "    </div>\n" +
    "    <a ng-click=\"toggleForgottenPasswordForm()\">\n" +
    "        <small>Go back</small>\n" +
    "    </a>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/login/passwordResetView.html',
    "<div class=\"password-box animated fadeInDown\">\n" +
    "    <div class=\"row\">\n" +
    "        <div class=\"col-md-12\">\n" +
    "\n" +
    "                <div class=\"row\">\n" +
    "                    <div class=\"col-lg-12\"><h2 class=\"font-bold\">Reset password</h2></div>\n" +
    "                    <div class=\"col-lg-7 col-md-7 col-sm-6 col-xs-6\">\n" +
    "                        <form name=\"forms.resetPasswordForm\" class=\"m-t clearfix\" novalidate role=\"form\" autocomplete=\"off\">\n" +
    "                            <div class=\"form-group\">\n" +
    "                                <input ng-class=\"LoginController.isPasswordValid() ? 'valid' : 'invalid'\" type=\"password\" name=\"password\" class=\"form-control\" placeholder=\"New password\" required=\"\" ng-model=\"password\" ng-change=\"validatePassword()\" autocomplete=\"off\">\n" +
    "                            </div>\n" +
    "                            <div class=\"form-group\">\n" +
    "                                <input ng-class=\"LoginController.isPasswordValid() ? 'valid' : 'invalid'\" type=\"password\" name=\"repeatPassword\" class=\"form-control\" placeholder=\"Repeat password\" required=\"\" ng-model=\"repeatPassword\" ng-change=\"validatePassword()\" autocomplete=\"off\">\n" +
    "                            </div>\n" +
    "                            <div class=\"form-group\">\n" +
    "                                <input type=\"text\" ng-model=\"requestId\" required class=\"hidden\">\n" +
    "                                <div class=\"text-red\" ng-hide=\"requestId.length > 1\">\n" +
    "                                    <p>Invalid Link, please try to request a new reset link</p>\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                            <button type=\"submit\" class=\"btn btn-primary btn-block full-width m-b\" ng-class=\"{'disabled':resetSubmitInProgress}\" ng-click=\"handleResetSubmitPasswordSubmitClick()\">Reset</button>\n" +
    "                            <a ng-click=\"handleForgotPasswordClick()\" ng-hide=\"requestId.length > 1\">\n" +
    "                                <small>Go back</small>\n" +
    "                            </a>\n" +
    "                        </form>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-lg-5 col-md-5 col-sm-6 col-xs-6 text-left\">\n" +
    "                        <div class=\"form-group m-t\">\n" +
    "                            <div class=\"validation-items\">\n" +
    "                                <div class=\"validation-item\" ng-repeat=\"item in validationItems\"><i class=\"fa fa-circle\" ng-class=\"item.status ? 'text-navy' : 'text-danger'\"></i> {{item.name}}</div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/master-password/masterPasswordView.html',
    "<div class=\"wrapper wrapper-content animated fadeInRight page-master-password\" ng-controller=\"MasterPasswordController as MasterPasswordController\">\n" +
    "    <div class=\"section\" ng-show=\"MasterPasswordController.masterPassword.loaded\">\n" +
    "        <div class=\"row\">\n" +
    "            <div class=\"col-md-4\" ng-show=\"!MasterPasswordController.masterPassword.enabled\">\n" +
    "                <div class=\"content-box\">\n" +
    "                    <h5 class=\"m-b-md\">Master password</h5>\n" +
    "                    <h2 class=\"text-danger\">\n" +
    "                        <i class=\"fa fa-play fa-rotate-90\"></i>\n" +
    "                        <span ng-if=\"!MasterPasswordController.masterPassword.success\">Not available</span>\n" +
    "                    </h2>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div ng-show=\"MasterPasswordController.masterPassword.enabled\">\n" +
    "                <div class=\"col-md-3 b-r\">\n" +
    "                    <div class=\"content-box\">\n" +
    "                        <h3>Password expires in</h3>\n" +
    "                        <h1 class=\"text-navy\">\n" +
    "                            {{MasterPasswordController.isExpired() ? 'Password expired' : MasterPasswordController.secondsLeft() | date:'HH:mm:ss':'UTC'}}\n" +
    "                        </h1>\n" +
    "                        <div class=\"progress progress-mini\">\n" +
    "                            <div class=\"progress-bar\" style=\"width: {{ MasterPasswordController.timeLeftPercentage() }}%;\"></div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"col-md-9\">\n" +
    "                    <div class=\"row\">\n" +
    "                        <div class=\"col-md-6 b-r\" ng-show=\"!MasterPasswordController.isExpired()\">\n" +
    "                            <div class=\"content-box\">\n" +
    "                                <h3>Password valid for 24 hours</h3>\n" +
    "                                <ul class=\"list-group clear-list m-t\">\n" +
    "                                    <li class=\"list-group-item fist-item\">\n" +
    "                                        <strong>From:</strong>\n" +
    "                                        <span class=\"pull-right\">{{MasterPasswordController.masterPassword.from | date:'HH:mm:ss, dd MMMM, yyyy':'UTC'}} UK/GMT </span>\n" +
    "                                    </li>\n" +
    "                                    <li class=\"list-group-item\">\n" +
    "                                        <strong>To:</strong>\n" +
    "                                        <span class=\"pull-right\">{{MasterPasswordController.masterPassword.to | date:'HH:mm:ss, dd MMMM, yyyy':'UTC'}} UK/GMT </span>\n" +
    "                                    </li>\n" +
    "                                    <li class=\"list-group-item\">\n" +
    "                                        <strong>Current time:</strong>\n" +
    "                                        <span class=\"pull-right\">{{MasterPasswordController.getTime() | date:'HH:mm:ss, dd MMMM, yyyy':'UTC'}} UK/GMT </span>\n" +
    "                                    </li>\n" +
    "                                </ul>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "\n" +
    "                        <div class=\"col-md-6\" ng-show=\"!MasterPasswordController.isExpired()\">\n" +
    "                            <div class=\"content-box\">\n" +
    "                                <h3>Master password</h3>\n" +
    "                                <p class=\"text-navy\">\n" +
    "                                    <input class=\"no-borders\" \n" +
    "                                        ng-show=\"!showPassword\"\n" +
    "                                        type=\"password\"\n" +
    "                                        value=\"{{MasterPasswordController.masterPassword.password}}\"\n" +
    "                                        select-on-click readonly />\n" +
    "                                    <input class=\"no-borders\" \n" +
    "                                        ng-show=\"showPassword\"\n" +
    "                                        type=\"text\"\n" +
    "                                        value=\"{{MasterPasswordController.masterPassword.password}}\"\n" +
    "                                        select-on-click readonly />\n" +
    "                                </p>\n" +
    "                                <small>\n" +
    "                                    <switch class=\"green\" ng-model=\"showPassword\"></switch>\n" +
    "                                    <span>Show Password</span>\n" +
    "                                </small>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div ng-show=\"!MasterPasswordController.masterPassword.loaded\">\n" +
    "            <div wave-spinner class=\"text-right\"></div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/modal/helpModal.html',
    "<div class=\"modal-header\">\n" +
    "    <h3>{{modalOptions.headerText}}</h3>\n" +
    "</div>\n" +
    "<div class=\"modal-body\">\n" +
    "    <h4>While we work on help section, please use the instructions below:</h4>\n" +
    "    <p>\n" +
    "        <p>For help with TopUniversities profiles, please email\n" +
    "            <a href=\"mailto:tusupport@qs.com\">tusupport@qs.com</a>\n" +
    "        </p>\n" +
    "        <p>\n" +
    "            For help with TopMBA profiles, please email \n" +
    "            <a href=\"mailto:tmsupport@qs.com\">tmsupport@qs.com</a>\n" +
    "        </p>\n" +
    "        <p>For all other queries, please email \n" +
    "            <a href=\"mailto:web-support@qs.com\">web-support@qs.com</a>\n" +
    "        </p>\n" +
    "    </p>\n" +
    "</div>\n" +
    "<div class=\"modal-footer\">\n" +
    "    <button type=\"button\" class=\"btn btn-primary\" data-ng-click=\"modalOptions.close()\">{{modalOptions.closeButtonText}}</button>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/modal/viewPrograms.html',
    "<div class=\"modal-header\">\n" +
    "    <h3>{{modalOptions.headerText}}</h3>\n" +
    "</div>\n" +
    "<div class=\"modal-body text-left\">\n" +
    "    <ol>\n" +
    "        <li ng-repeat=\"item in modalOptions.data\">\n" +
    "            {{item.name}}\n" +
    "            <span\n" +
    "                class=\"info\"\n" +
    "                uib-tooltip='{{item.response.code}} : {{item.response.message}}'\n" +
    "                tooltip-class=\"word-break-all\"\n" +
    "                tooltip-placement=\"top\">\n" +
    "                <i class=\"fa fa-info-circle\"></i>\n" +
    "            </span>\n" +
    "        </li>\n" +
    "    </ol>\n" +
    "</div>\n" +
    "<div class=\"modal-footer\">\n" +
    "    <button type=\"button\" class=\"btn btn-primary\" data-ng-click=\"modalOptions.close()\">{{modalOptions.closeButtonText}}</button>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profile/logo/profileLogoView.html',
    "<div ng-controller=\"ProfileLogoController as ProfileLogoController\">\n" +
    "    <div class=\"col-lg-12 block-spinner\">\n" +
    "        <div wave-spinner class=\"text-right\" ng-show=\"displaySpinner\"></div>\n" +
    "    </div>\n" +
    "    <div class=\"\">\n" +
    "        <div class=\"pull-left\">\n" +
    "            <div class=\"img-list\">\n" +
    "                <form name=\"forms.imageForm\"\n" +
    "                      class=\"clearfix dropzone image-form no-background\"\n" +
    "                      enctype=\"multipart/form-data\"\n" +
    "                      ng-dropzone\n" +
    "                      dropzone=\"ProfileLogoController.dropZoneImageInstance\"\n" +
    "                      dropzone-config=\"ProfileLogoController.imageConfig.dropzone\"\n" +
    "                      event-handlers=\"ProfileLogoController.imageConfig.eventHandlers\"\n" +
    "                      novalidate>\n" +
    "                    <div class=\"fallback\">\n" +
    "                        <input name=\"file\" type=\"file\" />\n" +
    "                    </div>\n" +
    "\n" +
    "                    <span class=\"dz-message\" ng-show=\"uploadEnabled\"></span>\n" +
    "\n" +
    "                    <div ng-show=\"displayRemoveLink\" class=\"dropzone-previews\" ng-class=\"{dzPreview: displayRemoveLink}\"></div>\n" +
    "                </form>\n" +
    "            </div>\n" +
    "            <div class=\"default-image\" ng-show=\"!displayRemoveLink\">\n" +
    "                <img alt=\"image\" ng-show=\"gravatar\" class=\"img-circle\" gravatar-src=\"user.email\" gravatar-size=\"100\">\n" +
    "                <img alt=\"image\" ng-show=\"!gravatar\" class=\"img-circle\" ng-src=\"{{user.profileLogo}}\" width=\"100px\" height=\"100px\">\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"profile-info-header pull-left\">\n" +
    "            <h3>{{user.title}} {{user.fullName}}</h3>\n" +
    "            <p>{{user.position}}</p>\n" +
    "            <a class=\"btn btn-primary btn-sm upload-button\" ng-show=\"!displayRemoveLink\" ng-class=\"{'disabled': generalSubmitDisabled}\">\n" +
    "                <i class=\"fa fa-undo\"></i> Upload Image\n" +
    "            </a>\n" +
    "            <a class=\"btn btn-warning btn-sm\" ng-click=\"removeUploaderImage()\" ng-show=\"displayRemoveLink\">\n" +
    "                <i class=\"fa fa-times\"></i>\n" +
    "                <span>Click here to remove uploaded logo</span>\n" +
    "            </a>\n" +
    "            <a class=\"btn btn-primary btn-sm\" ng-click=\"handleGeneralSubmit()\" ng-show=\"displayRemoveLink\" ng-class=\"{'disabled': generalSubmitDisabled}\">\n" +
    "                <i class=\"fa fa-check-circle\"></i> Save\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profile/password/profilePasswordView.html',
    "<div class=\"password\" ng-controller=\"ProfilePasswordController as ProfilePasswordController\">\n" +
    "    <div class=\"ibox\">\n" +
    "        <div class=\"ibox-title\">\n" +
    "            <h5>{{isPasswordReset ? 'Password reset is required' : 'Password'}}</h5>\n" +
    "        </div>\n" +
    "        <div class=\"ibox-content\">\n" +
    "            <form name=\"ProfilePasswordController.forms.password\" class=\"clearfix form-horizontal\" novalidate\n" +
    "                  autocomplete=\"off\">\n" +
    "                <div class=\"form-group\"\n" +
    "                     ng-show=\"!ProfilePasswordController.isPasswordFieldsVisible() && !isPasswordReset\">\n" +
    "                    <label class=\"col-lg-12 col-sm-12 col-md-12\">Password</label>\n" +
    "                    <div class=\"col-lg-7 col-sm-7 col-md-7\">\n" +
    "                        <input name=\"password\" type=\"text\" class=\"form-control\" value=\"**********\"\n" +
    "                               ng-focus=\"ProfilePasswordController.togglePasswordFields()\">\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div ng-show=\"ProfilePasswordController.isPasswordFieldsVisible() || isPasswordReset\">\n" +
    "                    <div class=\"col-lg-12\">\n" +
    "                        <user-password user=\"user\"\n" +
    "                                       reset-validators=\"!ProfilePasswordController.isPasswordFieldsVisible() && !isPasswordReset\"\n" +
    "                                       config=\"{resetOnLogin: false, label: 'New Password', labelClass: 'control-label col-sm-12', blockClass: null}\"></user-password>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-sm-12\">\n" +
    "                        <div class=\"form-group\">\n" +
    "                            <div class=\"col-sm-12\">\n" +
    "                                <a class=\"btn btn-primary pull-right\"\n" +
    "                                   ng-class=\"{'disabled': ProfilePasswordController.isDisabled()}\"\n" +
    "                                   ng-click=\"ProfilePasswordController.update()\">\n" +
    "                                    <i class=\"fa fa-check-circle\"></i>\n" +
    "                                    <span>Update</span>\n" +
    "                                </a>\n" +
    "                                <a class=\"btn btn-default pull-right\"\n" +
    "                                   ng-if=\"!isPasswordReset\"\n" +
    "                                   ng-class=\"{'disabled': ProfilePasswordController.isDisabled()}\"\n" +
    "                                   ng-click=\"ProfilePasswordController.togglePasswordFields()\">\n" +
    "                                    <i class=\"fa fa-ban\"></i>\n" +
    "                                    <span>Cancel</span>\n" +
    "                                </a>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </form>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profile/personalDetails/profilePersonalDetailsView.html',
    "<div class=\"personal-details\" ng-controller=\"ProfilePersonalDetailsController as ProfilePersonalDetailsController\">\n" +
    "    <div class=\"ibox\">\n" +
    "        <div class=\"ibox-title\">\n" +
    "            <h5>Personal Details</h5>\n" +
    "        </div>\n" +
    "        <div class=\"ibox-content\">\n" +
    "            <form name=\"ProfilePersonalDetailsController.forms.personalDetails\" class=\"clearfix form-horizontal\" novalidate autocomplete=\"off\">\n" +
    "                <div class=\"form-group\">\n" +
    "                    <label class=\"control-label col-sm-5 col-md-4\">Title</label>\n" +
    "                    <div class=\"col-sm-7 col-md-8\">\n" +
    "                        <input name=\"title\" type=\"text\" class=\"form-control\" ng-model=\"ProfilePersonalDetailsController.user.title\">\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group\" ng-class=\"{'has-errors': !ProfilePersonalDetailsController.isValidFirstName()}\">\n" +
    "                    <label class=\"control-label col-sm-5 col-md-4\">First (Given) Name *</label>\n" +
    "                    <div class=\"col-sm-7 col-md-8\">\n" +
    "                        <input type=\"text\"\n" +
    "                               class=\"form-control\"\n" +
    "                               name=\"firstName\"\n" +
    "                               ng-model=\"ProfilePersonalDetailsController.user.firstName\"\n" +
    "                               ng-required=\"true\"\n" +
    "                               ng-focus=\"ProfilePersonalDetailsController.setValid('firstName')\"\n" +
    "                               focus-delay=\"250\"\n" +
    "                               custom-popover popover-html=\"Add a first (given) name\"\n" +
    "                               popover-placement=\"left\"\n" +
    "                               popover-trigger=\"manual\"\n" +
    "                               popover-visibility=\"{{!ProfilePersonalDetailsController.isValidFirstName()}}\">\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group\" ng-class=\"{'has-errors': !ProfilePersonalDetailsController.isValidLastName()}\">\n" +
    "                    <label class=\"control-label col-sm-5 col-md-4\">Last (Family) Name *</label>\n" +
    "                    <div class=\"col-sm-7 col-md-8\">\n" +
    "                        <input type=\"text\"\n" +
    "                               class=\"form-control\"\n" +
    "                               name=\"lastName\"\n" +
    "                               ng-model=\"ProfilePersonalDetailsController.user.lastName\"\n" +
    "                               ng-required=\"true\"\n" +
    "\n" +
    "                               focus-delay=\"250\"\n" +
    "                               custom-popover\n" +
    "                               popover-html=\"Add a last (family) name\"\n" +
    "                               popover-placement=\"left\"\n" +
    "                               popover-trigger=\"manual\"\n" +
    "                               popover-visibility=\"{{!ProfilePersonalDetailsController.isValidLastName()}}\"\n" +
    "                               ng-focus=\"ProfilePersonalDetailsController.setValid('lastName')\">\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group\">\n" +
    "                    <label class=\"control-label col-sm-5 col-md-4\">Position</label>\n" +
    "                    <div class=\"col-sm-7 col-md-8\">\n" +
    "                        <input name=\"position\" type=\"text\" class=\"form-control\" ng-model=\"ProfilePersonalDetailsController.user.position\">\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div ng-if=\"ProfilePersonalDetailsController.user.isClient\" class=\"form-group\">\n" +
    "                    <label class=\"control-label col-sm-5 col-md-4\">Phone</label>\n" +
    "                    <div class=\"col-sm-7 col-md-8\">\n" +
    "                        <input name=\"phone\" type=\"text\" class=\"form-control\" ng-model=\"ProfilePersonalDetailsController.user.phone\">\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group\">\n" +
    "                    <label class=\"control-label col-sm-5 col-md-4\">Email *</label>\n" +
    "                    <div class=\"col-sm-7 col-md-8\">\n" +
    "                        <input type=\"email\"\n" +
    "                               class=\"form-control\"\n" +
    "                               name=\"email\"\n" +
    "                               ng-model=\"ProfilePersonalDetailsController.user.email\"\n" +
    "                               ng-required=\"true\"\n" +
    "                               ng-disabled=\"true\">\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div ng-if=\"ProfilePersonalDetailsController.user.isClient\" class=\"form-group\">\n" +
    "                    <label class=\"control-label col-sm-5 col-md-4\">Primary Institution *</label>\n" +
    "                    <div class=\"col-sm-7 col-md-8\">\n" +
    "                        <input type=\"email\"\n" +
    "                               class=\"form-control\"\n" +
    "                               name=\"email\"\n" +
    "                               ng-model=\"ProfilePersonalDetailsController.user.primaryInstitutionName\"\n" +
    "                               ng-required=\"true\"\n" +
    "                               ng-disabled=\"true\">\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group\">\n" +
    "                    <div class=\"col-sm-12\">\n" +
    "                        <a class=\"btn btn-primary pull-right\"\n" +
    "                           ng-class=\"{'disabled': ProfilePersonalDetailsController.isDisabled()}\"\n" +
    "                           ng-click=\"ProfilePersonalDetailsController.update()\">\n" +
    "                            <i class=\"fa fa-check-circle\"></i>\n" +
    "                            <span>Update</span>\n" +
    "                        </a>\n" +
    "                        <a class=\"btn btn-default pull-right\"\n" +
    "                           ng-class=\"{'disabled': ProfilePasswordController.isDisabled()}\"\n" +
    "                           ng-click=\"ProfilePersonalDetailsController.cancel()\">\n" +
    "                            <i class=\"fa fa-ban\"></i>\n" +
    "                            <span>Cancel</span>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "            </form>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profile/profileView.html',
    "<div class=\"wrapper wrapper-content animated fadeInRight page-profile\" ng-controller=\"ProfileController as ProfileController\">\n" +
    "    <div class=\"row profile-logo\" ng-class=\"{'modal-overlay-35': isPasswordReset}\">\n" +
    "        <div class=\"col-sm-12\">\n" +
    "            <div class=\"panel-body\" ng-include src=\"'/scripts/components/profile/logo/profileLogoView.html'\"></div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row\">\n" +
    "        <div class=\"col-sm-6\" ng-class=\"{'modal-overlay-35': isPasswordReset}\">\n" +
    "            <div class=\"panel-body profile-personal-details\">\n" +
    "                <div ng-include src=\"'/scripts/components/profile/personalDetails/profilePersonalDetailsView.html'\"></div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"col-sm-6\">\n" +
    "            <div class=\"panel-body profile-password\">\n" +
    "                <div ng-include src=\"'/scripts/components/profile/password/profilePasswordView.html'\"></div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/shared/campuses/campusDeletionDialogView.html',
    "<div class=\"modal-header\">\n" +
    "    <h3>{{ modalOptions.headerText }}</h3>\n" +
    "</div>\n" +
    "<div class=\"modal-body\">\n" +
    "    <div wave-spinner class=\"text-right\" ng-show=\"modalOptions.isLoading()\"></div>\n" +
    "    <div ng-show=\"!modalOptions.isLoading()\">\n" +
    "        <div ng-show=\"modalOptions.getProgramsList().length || modalOptions.getTmProgramsList().length\">\n" +
    "            <ul class=\"list-group clear-list m-t\">\n" +
    "                <li ng-repeat=\"(key, item) in modalOptions.getProgramsList()\" ng-show=\"item.total > 0\" class=\"list-group-item\" ng-class=\"{ 'fist-item' : key === 0 }\">\n" +
    "                    Campus <b>{{ item.name }}</b> is assigned to <b>{{ item.total }}</b> TU matching tool program<ng-pluralize count=\"item.total\" when=\"{'one': '','other': 's'}\"></ng-pluralize>.\n" +
    "                </li>\n" +
    "            </ul>\n" +
    "            <div ng-show=\"modalOptions.getProgramsList().length\" class=\"hr-line-dashed\"></div>\n" +
    "            <ul class=\"list-group clear-list m-t\">\n" +
    "                <li ng-repeat=\"(key, item) in modalOptions.getTmProgramsList()\" class=\"list-group-item\" ng-class=\"{ 'fist-item' : key === 0 }\">\n" +
    "                    Campus <b>{{ item.name }}</b> is assigned to <b>{{ item.total }}</b> TM program<ng-pluralize count=\"item.total\" when=\"{'one': '','other': 's'}\"></ng-pluralize>.\n" +
    "                </li>\n" +
    "            </ul>\n" +
    "            <div class=\"hr-line-dashed\"></div>\n" +
    "        </div>\n" +
    "        <p>\n" +
    "            Are you sure you wish to delete <ng-pluralize count=\"modalOptions.getProgramsList().length || 0\" when=\"{'one': 'campus','other': 'these campuses'}\"></ng-pluralize>?\n" +
    "        </p>\n" +
    "    </div>\n" +
    "</div>\n" +
    "<div class=\"modal-footer\" ng-show=\"!modalOptions.isLoading()\">\n" +
    "    <button type=\"button\" class=\"btn btn-default\" ng-click=\"modalOptions.close()\">\n" +
    "        {{ modalOptions.closeButtonText }}\n" +
    "    </button>\n" +
    "    <button class=\"btn {{ modalOptions.actionButtonClass || 'btn-primary'}}\" ng-click=\"modalOptions.confirm()\">\n" +
    "        {{ modalOptions.actionButtonText }}\n" +
    "    </button>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/shared/campuses/sharedProfileCampusesView.html',
    "<script id=\"ng-table/templates/campus/delete-header.html\" type=\"text/ng-template\">\n" +
    "    <th class=\"header\" ng-if=\"$column.show(this)\">\n" +
    "        <button type=\"button\" class=\"btn btn-danger btn-sm\" ng-class=\"{'disabled': isDeleteButtonDisabled()}\" ng-click=\"handleDeleteClick()\">\n" +
    "            <span class=\"glyphicon glyphicon-trash\"></span> {{$column.title(this)}}\n" +
    "        </button>\n" +
    "    </th>\n" +
    "</script>\n" +
    "<div ng-controller=\"SharedProfileCampusesController as ProfileCampusesController\">\n" +
    "    <!-- START CAMPUS TAB SECTION -->\n" +
    "    <div class=\"campus-tab\">\n" +
    "        <div class=\"tab-header\">\n" +
    "            <div class=\"btn-toggle\" ng-show=\"isQsUser\">\n" +
    "                <switch class=\"green\"\n" +
    "                    ng-disabled=\"campusesInEventsInclusionDisbaled\"\n" +
    "                    ng-model=\"sharedProfile.campusesOnEvents\"\n" +
    "                    ng-change=\"!campusesInEventsInclusionDisbaled ? handleIncludeInEventsClick() : null\">\n" +
    "                </switch>\n" +
    "                <span class=\"switch-text\">Include all campuses in events page</span>\n" +
    "            </div>\n" +
    "            <button class=\"btn btn-primary pull-right\" type=\"button\" ng-click=\"handleAddClick()\">\n" +
    "                <i class=\"fa fa-plus\"></i>\n" +
    "                <span>Add Campus</span>\n" +
    "            </button>\n" +
    "        </div>\n" +
    "        <div class=\"tab-body\">\n" +
    "            <div id=\"campusesTable\">\n" +
    "                <div class=\"ibox float-e-margins scroll-horizontal hide-vertical-overflow\">\n" +
    "                    <table ng-table-dynamic=\"tableParams with columns\" show-filter=\"false\" class=\"table table-striped table-bordered table-hover pointer\">\n" +
    "                        <tbody ui-sortable=\"sortableOptions\" ng-model=\"$data\">\n" +
    "                            <tr ng-repeat=\"campus in $data track by $index\" ng-class=\"{'active': campus.id == selectedCampusId}\">\n" +
    "                                <td ng-click=\"col.field === 'delete'? '' : handleDatagridRowClick(campus)\" ng-repeat=\"col in $columns\">\n" +
    "                                    <input ng-if=\"col.field === 'delete'\" ng-model=\"campusesToDelete[campus.id]\" i-checkbox type=\"checkbox\">\n" +
    "                                    <p ng-if=\"col.field !== 'delete'\">\n" +
    "                                        {{ col.field === 'createdAt' && campus[col.field] ? (campus[col.field] | date:'medium') : campus[col.field] }}\n" +
    "                                    </p>\n" +
    "                                </td>\n" +
    "                            </tr>\n" +
    "                        </tbody>\n" +
    "                    </table>\n" +
    "                    showing {{ tableParams.total() }} entries out of {{  tableParams.totalEntries }}\n" +
    "                </div>\n" +
    "                <div id=\"mapContainer\" class=\"visibility\" ng-class=\"displayMapBlock ? 'visibility-visible' : 'visibility-hidden'\">\n" +
    "                    <h1>Locations</h1>\n" +
    "                    <div id=\"map\">\n" +
    "                        <div id=\"campusMap\"></div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        \n" +
    "    </div><!-- END CAMPUS TAB SECTION -->\n" +
    "</div>\n" +
    "\n"
  );


  $templateCache.put('/scripts/components/profiles/shared/campuses/sharedProfileCampusFormButtonsView.html',
    "<div class=\"col-sm-12\">\n" +
    "    <a class=\"btn btn-primary pull-right btn-float-fix\" ng-if=\"editMode\" ng-class=\"{'disabled':campusSubmitInProgress}\" ng-click=\"handleUpdateClick()\">\n" +
    "        <i class=\"fa fa-check-circle\"></i>\n" +
    "        <span>Update</span>\n" +
    "    </a>\n" +
    "    \n" +
    "    <div class=\"pull-right m-lr-sm btn-float-fix\" ng-show=\"displayDelete\">\n" +
    "        <a class=\"btn btn-danger btn-float-fix\" ng-class=\"{'disabled':campusSubmitInProgress}\" ng-click=\"handleDeleteClick()\">\n" +
    "            <i class=\"fa fa-trash\"></i>\n" +
    "            <span>Delete</span>\n" +
    "        </a>\n" +
    "    </div>\n" +
    "\n" +
    "    <a class=\"btn btn-primary pull-right btn-float-fix\" ng-if=\"!editMode\" ng-class=\"{'disabled':campusSubmitInProgress}\" ng-click=\"handleCreateClick()\">\n" +
    "        <i class=\"fa fa-check-circle\"></i>\n" +
    "        <span>Save</span>\n" +
    "    </a>\n" +
    "\n" +
    "    <a class=\"btn btn-default pull-right btn-float-fix\" ng-class=\"{'disabled':campusSubmitInProgress}\" ng-click=\"handleCancelClick()\">\n" +
    "        <i class=\"fa fa-ban\"></i>\n" +
    "        <span>Cancel</span>\n" +
    "    </a>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/components/profiles/shared/campuses/sharedProfileCampusFormView.html',
    "<div class=\"ibox campus-right-side-panel\" ng-controller=\"SharedProfileCampusFormController as SharedProfileCampusFormController\">\n" +
    "    <div class=\"\">\n" +
    "    <div class=\"ibox-title m-t-xl\">\n" +
    "        <h5>{{editMode ? 'Edit' : 'Add'}} Campus</h5>\n" +
    "        <div class=\"ibox-tools\">\n" +
    "            <a class=\"close-link\" ng-click=\"handleCancelClick()\">\n" +
    "                <i class=\"fa fa-times\"></i>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"ibox-content\">\n" +
    "        <div wave-spinner ng-show=\"isFetchInProgress()\"></div>\n" +
    "\n" +
    "        <form class=\"form-horizontal add-campus\" name=\"forms.campusForm\" ng-class=\"{submitted:submitted}\" novalidate autocomplete=\"off\">\n" +
    "            <div class=\"form-group text-muted\" ng-if=\"!SharedProfileCampusFormController.isClient && editMode\">\n" +
    "                <label class=\"col-sm-5 col-md-4\">ID</label>\n" +
    "                <div class=\"col-sm-7 col-md-8 word-break-all\">\n" +
    "                    {{ campus.id }}\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\" ng-class=\"{'has-errors': !SharedProfileCampusFormController.isValidName()}\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">Campus Name *</label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <input type=\"text\" class=\"form-control\" name=\"name\"\n" +
    "                           ng-model=\"campus.name\"\n" +
    "                           ng-required=\"true\"\n" +
    "                           ng-focus=\"SharedProfileCampusFormController.setValid('name')\"\n" +
    "                           focus-delay=\"250\"\n" +
    "\n" +
    "                           custom-popover\n" +
    "                           popover-html=\"Add a {{SharedProfileCampusFormController.isEmpty(campus.name) ? '' : 'valid' }} campus name\"\n" +
    "                           popover-placement=\"left\"\n" +
    "                           popover-trigger=\"manual\"\n" +
    "                           popover-visibility=\"{{!SharedProfileCampusFormController.isValidName()}}\"/>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\" ng-class=\"{'has-errors': !SharedProfileCampusFormController.isValidCountry()}\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">Country *</label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <ui-select\n" +
    "                        name=\"country\"\n" +
    "                        ng-model=\"campus.country\"\n" +
    "                        ng-required=\"true\"\n" +
    "                        theme=\"bootstrap\"\n" +
    "                        on-select=\"SharedProfileCampusFormController.setValid('country')\"\n" +
    "                        focus-delay=\"250\"\n" +
    "                        custom-popover\n" +
    "                        popover-html=\"Select an option\"\n" +
    "                        popover-placement=\"left\"\n" +
    "                        popover-trigger=\"manual\"\n" +
    "                        popover-visibility=\"{{!SharedProfileCampusFormController.isValidCountry()}}\">\n" +
    "                        <ui-select-match placeholder=\"Select an Option\">{{$select.selected.name}}</ui-select-match>\n" +
    "                        <ui-select-choices \n" +
    "                            refresh-delay=\"1000\"\n" +
    "                            repeat=\"option.countryCode as option in countriesList | filter:$select.search\">\n" +
    "                            <div ng-bind-html=\"option.name | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\" ng-class=\"{'has-errors': !SharedProfileCampusFormController.isValidAddress()}\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">Address *</label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <input type=\"text\" class=\"form-control\" name=\"addressLine1\"\n" +
    "                           ng-model=\"campus.addressLine1\"\n" +
    "                           ng-required=\"true\"\n" +
    "                           ng-focus=\"SharedProfileCampusFormController.setValid('addressLine1')\"\n" +
    "                           focus-delay=\"250\"\n" +
    "\n" +
    "                           custom-popover\n" +
    "                           popover-html=\"Add an {{SharedProfileCampusFormController.isEmpty(campus.addressLine1) ? '' : 'valid' }} address\"\n" +
    "                           popover-placement=\"left\"\n" +
    "                           popover-trigger=\"manual\"\n" +
    "                           popover-visibility=\"{{!SharedProfileCampusFormController.isValidAddress()}}\"/>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\" ng-class=\"{'has-errors': !SharedProfileCampusFormController.isValidSecondAddress()}\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">&nbsp;</label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <input type=\"text\" class=\"form-control\" \n" +
    "                           ng-model=\"campus.addressLine2\" \n" +
    "                           name=\"addressLine2\"\n" +
    "                           focus-delay=\"250\"\n" +
    "\n" +
    "                           custom-popover\n" +
    "                           popover-html=\"Add a valid address\"\n" +
    "                           popover-placement=\"left\"\n" +
    "                           popover-trigger=\"manual\"\n" +
    "                           popover-visibility=\"{{!SharedProfileCampusFormController.isValidSecondAddress()}}\"/>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\" ng-class=\"{'has-errors': !SharedProfileCampusFormController.isValidCity()}\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">Town / City *</label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <input type=\"text\" class=\"form-control\" name=\"city\"\n" +
    "                           ng-model=\"campus.city\"\n" +
    "                           ng-required=\"true\"\n" +
    "                           ng-focus=\"SharedProfileCampusFormController.setValid('city')\"\n" +
    "                           focus-delay=\"250\"\n" +
    "\n" +
    "                           custom-popover\n" +
    "                           popover-html=\"Add a {{SharedProfileCampusFormController.isEmpty(campus.city) ? '' : 'valid' }} town/city\"\n" +
    "                           popover-placement=\"left\"\n" +
    "                           popover-trigger=\"manual\"\n" +
    "                           popover-visibility=\"{{!SharedProfileCampusFormController.isValidCity()}}\"/>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\" ng-class=\"{'has-errors': !SharedProfileCampusFormController.isValidState()}\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\" for=\"campusState\">State / Province</label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <input id=\"campusState\" type=\"text\" class=\"form-control\" \n" +
    "                           ng-model=\"campus.state\" \n" +
    "                           name=\"state\"focus-delay=\"250\"\n" +
    "\n" +
    "                           custom-popover\n" +
    "                           popover-html=\"Add a valid state/province\"\n" +
    "                           popover-placement=\"left\"\n" +
    "                           popover-trigger=\"manual\"\n" +
    "                           popover-visibility=\"{{!SharedProfileCampusFormController.isValidState()}}\"/>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\" ng-class=\"{'has-errors': !SharedProfileCampusFormController.isValidPostcode()}\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\" for=\"campusPostCode\">Postcode</label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <input id=\"campusPostCode\" type=\"text\" class=\"form-control\"\n" +
    "                           ng-model=\"campus.postcode\" \n" +
    "                           name=\"postcode\"\n" +
    "                           focus-delay=\"250\"\n" +
    "\n" +
    "                           custom-popover\n" +
    "                           popover-html=\"Add a valid postcode\"\n" +
    "                           popover-placement=\"left\"\n" +
    "                           popover-trigger=\"manual\"\n" +
    "                           popover-visibility=\"{{!SharedProfileCampusFormController.isValidPostcode()}}\"/>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"separator\"></div>\n" +
    "\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col-md-12 col-lg-6\">\n" +
    "                    <div class=\"form-group\" ng-class=\"{'has-errors': !SharedProfileCampusFormController.isValidLatitude()}\">\n" +
    "                        <label class=\"control-label col-sm-5\" for=\"sharedProfileCampusLatitude\">Latitude</label>\n" +
    "                        <div class=\"col-sm-7\">\n" +
    "                            <input id=\"sharedProfileCampusLatitude\" type=\"text\" class=\"form-control\" \n" +
    "                                   ng-model=\"campus.latitude\" \n" +
    "                                   ng-readonly=\"campus.autoGenerate\" \n" +
    "                                   name=\"latitude\"\n" +
    "                                   focus-delay=\"250\"\n" +
    "\n" +
    "                                   custom-popover\n" +
    "                                   popover-html=\"Add a valid latitude\"\n" +
    "                                   popover-placement=\"left\"\n" +
    "                                   popover-trigger=\"manual\"\n" +
    "                                   popover-visibility=\"{{!SharedProfileCampusFormController.isValidLatitude()}}\"/>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"col-md-12 col-lg-6\">\n" +
    "                    <div class=\"form-group\" ng-class=\"{'has-errors': !SharedProfileCampusFormController.isValidLongitude()}\">\n" +
    "                        <label class=\"control-label col-sm-5\" for=\"sharedProfileCampusLongitude\">Longitude</label>\n" +
    "                        <div class=\"col-sm-7\">\n" +
    "                            <input id=\"sharedProfileCampusLongitude\" type=\"text\" class=\"form-control\" \n" +
    "                                   ng-model=\"campus.longitude\" \n" +
    "                                   ng-readonly=\"campus.autoGenerate\" \n" +
    "                                   name=\"longitude\"\n" +
    "                                   focus-delay=\"250\"\n" +
    "                                   \n" +
    "                                   custom-popover\n" +
    "                                   popover-html=\"Add a valid longitude\"\n" +
    "                                   popover-placement=\"left\"\n" +
    "                                   popover-trigger=\"manual\"\n" +
    "                                   popover-visibility=\"{{!SharedProfileCampusFormController.isValidLongitude()}}\">\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"separator dashed text-center\">\n" +
    "                <span class=\"text\">or</span>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">Auto Generate</label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <div class=\"btn-toggle\">\n" +
    "                        <switch ng-model=\"campus.autoGenerate\" class=\"green\"></switch>\n" +
    "                        <span class=\"switch-text\">Use address to auto generate latitude and longitude.</span>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\"></label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <button type=\"button\" class=\"btn btn-default btn-xs pull-right\"\n" +
    "                        ng-click=\"SharedProfileCampusFormController.refreshMap()\"\n" +
    "                        ng-disabled=\"refreshMapInProgress || !campus.autoGenerate\">\n" +
    "                        <i class=\"fa fa-refresh\"></i>\n" +
    "                        <span>Refresh Map</span>\n" +
    "                    </button>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"separator\"></div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">Display In Frontend</label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <div class=\"btn-toggle\">\n" +
    "                        <switch ng-model=\"campus.displayInFrontEnd\" ng-change=\"SharedProfileCampusFormController.displayOnFrontEndClick()\" class=\"green\"></switch>\n" +
    "                        <span class=\"switch-text\">If selected, campus will be sent to profile. (Latitude and longitude is required)</span>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">Primary Campus</label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <div class=\"btn-toggle\">\n" +
    "                        <switch id=\"campusPrimary\" ng-model=\"campus.primary\" class=\"green\"></switch>\n" +
    "                        <span class=\"switch-text\">Please only check if it's the main campus</span>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"white-space\"></div>\n" +
    "\n" +
    "            <div class=\"form-group\" ng-include=\"'/scripts/components/profiles/shared/campuses/sharedProfileCampusFormButtonsView.html'\"></div>\n" +
    "        </form>\n" +
    "    </div>\n" +
    "\n" +
    "    <pre ng-if=\"SharedProfileCampusFormController.devMode\">{{campus|json}}</pre>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/shared/general/sharedProfileGeneralHistoryView.html',
    "<div class=\"ibox\">\n" +
    "    <div class=\"ibox-title m-t-xl\">\n" +
    "        <h5>History Log <small>showing last {{SharedProfileController.historyLog.totalReturned}} records of {{SharedProfileController.historyLog.total}}</small></h5>\n" +
    "        <div class=\"ibox-tools\">\n" +
    "            <a class=\"close-link\" ng-click=\"handleGeneralHistoryCloseClick()\">\n" +
    "                <i class=\"fa fa-times\"></i>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"ibox block-institution\">\n" +
    "        <div class=\"ibox-content\">\n" +
    "            <div wave-spinner class=\"text-right\" ng-show=\"fetchingHistory\"></div>\n" +
    "            <div ng-repeat=\"log in generalHistoryLog\">\n" +
    "                <ul class=\"list-unstyled list-history break-word\">\n" +
    "                    <li>\n" +
    "                        <span class=\"bold\">Modified on:</span>\n" +
    "                        <span>{{log.modifiedAt| date:'medium'}}</span>\n" +
    "                    </li>\n" +
    "                    <li>\n" +
    "                        <span class=\"bold\">Submitted by:</span>\n" +
    "                        <span>{{log.modifiedByFullName}}</span>\n" +
    "                    </li>\n" +
    "                    <li>\n" +
    "                        <span class=\"bold\">Institution Profile Name Display:</span>\n" +
    "                        <span>{{log.fullName}}</span>\n" +
    "                    </li>\n" +
    "                    <li>\n" +
    "                        <span class=\"bold\">Foundation Year:</span>\n" +
    "                        <span>{{log.foundationYear}}</span>\n" +
    "                    </li>\n" +
    "                    <li>\n" +
    "                        <span class=\"bold\">Logo:</span>\n" +
    "                        <span>{{log.smallLogo.path && log.mediumLogo.path && log.largeLogo.path ? 'Yes' : 'No'}}</span>\n" +
    "                    </li>\n" +
    "                </ul>\n" +
    "                <div class=\"hr-line-dashed\"></div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"row\" ng-show=\"SharedProfileController.handleHistoryLogVisibility()\">\n" +
    "                <div wave-spinner class=\"text-right\" ng-show=\"SharedProfileController.fetchingMoreHistoryLog()\"></div>\n" +
    "                <div class=\"col-sm-8 col-sm-offset-2\">\n" +
    "                    <a class=\"btn btn-primary btn-block\" ng-click=\"SharedProfileController.handleLoadMoreHistoryLog()\" ng-disabled=\"SharedProfileController.fetchingMoreHistoryLog()\">Load more</a>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/components/profiles/shared/general/sharedProfileGeneralView.html',
    "<div ng-controller=\"SharedProfileGeneralController as SharedProfileGeneralController\">\n" +
    "    <div class=\"general-tab\">\n" +
    "        <form class=\"form-horizontal\" name=\"forms.generalForm\" novalidate=\"\">\n" +
    "\n" +
    "            <a class=\"btn btn-warning btn-history\" ng-class=\"{'disabled':generalHistoryDisabled}\" ng-click=\"handleHistoryLogClick()\">\n" +
    "                <i class=\"fa fa-clock-o\"></i>\n" +
    "            </a>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-3 col-md-2\">\n" +
    "                    {{SharedProfileGeneralController.logoDropzoneInstance.files[0].status === 'success' ? 'Preview Logo' : 'Upload Logo'}}\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-4 col-md-10\">\n" +
    "                    <div class=\"upload\">\n" +
    "                        <div class=\"dropzone\"\n" +
    "                             name=\"forms.logosForm\"\n" +
    "                             enctype=\"multipart/form-data\"\n" +
    "                             ng-dropzone\n" +
    "                             dropzone=\"SharedProfileGeneralController.logoDropzoneInstance\"\n" +
    "                             dropzone-config=\"SharedProfileGeneralController.logoConfig.dropzone\"\n" +
    "                             event-handlers=\"SharedProfileGeneralController.logoConfig.eventHandlers\"\n" +
    "                             novalidate >\n" +
    "                            <div class=\"fallback\">\n" +
    "                                <input name=\"file\" type=\"file\" />\n" +
    "                            </div>\n" +
    "                            <div class=\"dz-message\" ng-show=\"uploadEnabled\">\n" +
    "                                <i class=\"fa fa-upload\"></i>\n" +
    "                            </div>\n" +
    "                            <div class=\"dropzone-previews\"></div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"note\">\n" +
    "                        <div class=\"note-header\">\n" +
    "                            <span class=\"bold\">Note:</span>\n" +
    "                        </div>\n" +
    "                        <div class=\"note-body\">\n" +
    "                            <p>\n" +
    "                                <span class=\"bold\">File types:</span>\n" +
    "                                <small>.jpeg &nbsp; .jpg</small>\n" +
    "                            </p>\n" +
    "                            <p>\n" +
    "                                <span class=\"bold\">Image dimensions:</span>\n" +
    "                                <small>200px x 200px</small>\n" +
    "                            </p>\n" +
    "                            <p>\n" +
    "                                <span class=\"bold\">Maximum size:</span>\n" +
    "                                <small>400 KB</small>\n" +
    "                            </p>\n" +
    "                        </div>\n" +
    "                        <div class=\"note-footer\" ng-show=\"displayRemoveLink\">\n" +
    "                            <p>\n" +
    "                                <span> Press \"Update\" to apply changes.</span>\n" +
    "                                <span class=\"or bold\">OR</span>\n" +
    "                            </p>\n" +
    "                            <a class=\"btn btn-warning btn-sm\" ng-click=\"removeUploaderImage()\">\n" +
    "                                <i class=\"fa fa-times\"></i>\n" +
    "                                <span>Click here to remove uploaded logo.</span>\n" +
    "                            </a>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-3 col-md-2\">Current Logo</label>\n" +
    "                <div class=\"col-sm-4 col-md-10\">\n" +
    "                    <div class=\"preview-group\">\n" +
    "                        <div class=\"logo-preview\">\n" +
    "                            <div class=\"logo-img\">\n" +
    "                                <span\n" +
    "                                    class=\"info\"\n" +
    "                                    uib-tooltip='Used in profile pages'\n" +
    "                                    tooltip-placement=\"top\">\n" +
    "                                    <i class=\"fa fa-info-circle\"></i>\n" +
    "                                </span>\n" +
    "                                <img class=\"large-logo img-responsive\" alt=\"Large Logo\" ng-src=\"{{profile.largeLogoPath ? profile.largeLogoPath : '/images/profile/logo-large.jpg'}}\" ng-class=\"{'shadow-1px': !profile.largeLogoPath}\" />\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                        <div class=\"logo-preview\">\n" +
    "                            <div class=\"logo-img\">\n" +
    "                                <span\n" +
    "                                    class=\"info\"\n" +
    "                                    uib-tooltip='Used in ranking widget, search results page, featured profile widget'\n" +
    "                                    tooltip-placement=\"top\">\n" +
    "                                    <i class=\"fa fa-info-circle\"></i>\n" +
    "                                </span>\n" +
    "                                <img class=\"medium-logo img-responsive\" alt=\"Medium Logo\" ng-src=\"{{profile.mediumLogoPath ? profile.mediumLogoPath : '/images/profile/logo-medium.jpg'}}\" ng-class=\"{'shadow-1px': !profile.mediumLogoPath}\" />\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                        <div class=\"logo-preview\">\n" +
    "                            <div class=\"logo-img\">\n" +
    "                                <span\n" +
    "                                    class=\"info\"\n" +
    "                                    uib-tooltip='Used in ranking tables'\n" +
    "                                    tooltip-placement=\"top\">\n" +
    "                                    <i class=\"fa fa-info-circle\"></i>\n" +
    "                                </span>\n" +
    "                                <img class=\"small-logo img-responsive\" alt=\"Small Logo\" ng-src=\"{{profile.smallLogoPath ? profile.smallLogoPath : '/images/profile/logo-small.jpg'}}\" ng-class=\"{'shadow-1px': !profile.smallLogoPath}\" />\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-3 col-md-2\">\n" +
    "                    <span>Institution Profile Name Display</span>\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                        uib-tooltip='This will change the display on your institution profile only. For other changes contact tusupport@qs.com (TopUniversities) or tmsupport@qs.com (TopMBA)'\n" +
    "                        tooltip-placement=\"top\"></i>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-9 col-md-10\">\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"profile.fullName\" />\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-3 col-md-2\">Foundation Year</label>\n" +
    "                <div class=\"col-sm-4 col-md-2\">\n" +
    "                    <input type=\"text\" class=\"form-control\" touch-spin=\"\" spin-options=\"foundationYearSpinOptions\" ng-model=\"profile.foundationYear\" />\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div wave-spinner ng-show=\"generalSubmitDisabled && displaySpinner\"></div>\n" +
    "\n" +
    "            <div class=\"clearfix\">\n" +
    "                <a class=\"btn btn-primary pull-right\" ng-class=\"{'disabled':generalSubmitDisabled}\" ng-click=\"handleGeneralSubmit()\">\n" +
    "                    <i class=\"fa fa-check-circle\" aria-hidden=\"true\"></i>\n" +
    "                    <span>Update</span>\n" +
    "                </a>\n" +
    "                <a class=\"btn btn-danger pull-right\" ng-class=\"{'disabled':SharedProfileGeneralController.isRemoveLogosDisabled()}\" ng-click=\"handleClearLogosClick()\">\n" +
    "                    <i class=\"fa fa-times\"></i>\n" +
    "                    <span>Remove logos</span>\n" +
    "                </a>\n" +
    "            </div>\n" +
    "\n" +
    "        </form>\n" +
    "    </div>\n" +
    "\n" +
    "    <p><pre ng-show=\"SharedProfileGeneralController.devMode\">{{profile|json}}</pre></p>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/components/profiles/shared/sharedProfileView.html',
    "<div class=\"wrapper wrapper-content animated fadeInDown\" ng-controller=\"SharedProfileController as SharedProfileController\">\n" +
    "    <div class=\"row\">\n" +
    "        <div ng-class=\"isRightSidePanelActive() ? 'col-sm-8' : 'col-sm-12'\">\n" +
    "            <div class=\"tabs-container\" ng-class=\"{'modal-overlay': loadInProgress}\">\n" +
    "                <uib-tabset active=\"activeTab\">\n" +
    "                    <uib-tab heading=\"General\" ui-sref=\"clients.profiles.shared.general\">\n" +
    "                        <div class=\"panel-body modal-overlay-75\" >\n" +
    "                            <div ng-include=\"'/scripts/components/profiles/shared/general/sharedProfileGeneralView.html'\"></div>\n" +
    "                        </div>\n" +
    "                    </uib-tab>\n" +
    "\n" +
    "                    <uib-tab heading=\"Campuses\" ng-if=\"SharedProfileController.accessAllowed\" ui-sref=\"clients.profiles.shared.campuses\">\n" +
    "                        <div class=\"panel-body\">\n" +
    "                            <div ng-include=\"'/scripts/components/profiles/shared/campuses/sharedProfileCampusesView.html'\"></div>\n" +
    "                        </div>\n" +
    "                    </uib-tab>\n" +
    "                </uib-tabset>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div ng-show=\"isGeneralTabActive() && showGeneralHistoryBlock\" class=\"col-sm-4 right-side-container\">\n" +
    "            <div ng-include=\"'/scripts/components/profiles/shared/general/sharedProfileGeneralHistoryView.html'\"></div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div ng-show=\"isCampusesTabActive() && showCampusForm\" class=\"col-sm-4 right-side-container\" fixed-element-while-scrolling=\"#campusesTable\">\n" +
    "            <div ng-include=\"'/scripts/components/profiles/shared/campuses/sharedProfileCampusFormView.html'\"></div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tm/media/subtabs/brochures/tmProfileMediaBrochuresSidebarView.html',
    "<div class=\"ibox\" ng-class=\"getSelected() === 4 ? 'selected' : 'collapsed'\"  ng-controller=\"TmMediaBrochuresSidebarController as TmMediaBrochuresSidebarController\">\n" +
    "    <div class=\"ibox-title clickable\" ng-click=\"setSelected(4)\">\n" +
    "        <h5>Brochures ({{getBrochureItems().length}})</h5>\n" +
    "        <div class=\"ibox-tools\">\n" +
    "            <a>\n" +
    "                <i class=\"fa fa-chevron-up\"></i>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"ibox-content\">\n" +
    "        <div class=\"addForm\">\n" +
    "            <form class=\"form-horizontal\" name=\"forms.formBrochure\" id=\"form-brochure\" novalidate>\n" +
    "                <div class=\"alert alert-info\">\n" +
    "                    <p>\n" +
    "                        <i class=\"fa fa-info-circle\"></i>\n" +
    "                        <span>Please add your brochure title and link.</span><br />\n" +
    "                        <span>Drag and drop brochures from left to right to change the order.</span><br>\n" +
    "                    </p>\n" +
    "                </div>\n" +
    "\n" +
    "                <div>\n" +
    "                    <div class=\"form-group\" ng-class=\"{'has-errors': isInvalidTitle}\">\n" +
    "                        <label class=\"control-label col-sm-3 col-md-2\">\n" +
    "                            <span>Title *</span>\n" +
    "                            <small>110 characters</small>\n" +
    "                        </label>\n" +
    "                        <div class=\"col-sm-9 col-md-10\">\n" +
    "                            <input class=\"form-control\"\n" +
    "                                   name=\"brochure-title\"\n" +
    "                                   maxlength=\"110\"\n" +
    "                                   ng-required=\"required\"\n" +
    "                                   type=\"text\"\n" +
    "                                   focus-if=\"isHighlighted\"\n" +
    "                                   focus-delay=\"250\"\n" +
    "                                   ng-focus=\"setIsInvalidTitle(false)\"\n" +
    "                                   ng-model=\"selectedBrochure.name\"\n" +
    "\n" +
    "                                   custom-popover\n" +
    "                                   popover-html=\"Add a brochure title\"\n" +
    "                                   popover-placement=\"left\"\n" +
    "                                   popover-trigger=\"manual\"\n" +
    "                                   popover-visibility=\"{{isInvalidTitle ? true : false}}\"/>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "\n" +
    "                    <div class=\"form-group\" ng-class=\"{'has-errors': isInvalidUrl}\">\n" +
    "                        <label class=\"control-label col-sm-3 col-md-2\">\n" +
    "                            <span>URL *</span>\n" +
    "                        </label>\n" +
    "                        <div class=\"col-sm-9 col-md-10\">\n" +
    "                            <input class=\"form-control\"\n" +
    "                                   name=\"url\"\n" +
    "                                   type=\"url\"\n" +
    "                                   placeholder=\"http://\"\n" +
    "                                   ng-required=\"required\"\n" +
    "                                   ng-model=\"selectedBrochure.url\"\n" +
    "                                   ng-pattern=\"TmMediaBrochuresSidebarController.urlPattern\"\n" +
    "                                   ng-focus=\"setIsInvalidUrl(false)\"\n" +
    "                                   ng-keyup=\"TmMediaBrochuresSidebarController.onKeyUp($event)\"\n" +
    "                                   maxlength=\"2011\"\n" +
    "\n" +
    "                                   custom-popover\n" +
    "                                   popover-html=\"Add a valid brochure link\"\n" +
    "                                   popover-placement=\"left\"\n" +
    "                                   popover-trigger=\"manual\"\n" +
    "                                   popover-visibility=\"{{isInvalidUrl ? true : false}}\"/>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"form-group m-b-none\">\n" +
    "                        <div class=\"col-sm-12\">\n" +
    "                            <button class=\"btn btn-primary pull-right\" ng-click=\"saveBrochureForm(selectedBrochure)\">\n" +
    "                                <i class=\"fa fa-check-circle\"></i>\n" +
    "                                <span>{{isEditMode() ? 'Update' : 'Save'}}</span>\n" +
    "                            </button>\n" +
    "                            <button class=\"btn btn-default pull-right\" ng-click=\"clearBrochureForm(selectedBrochure)\">\n" +
    "                                <i class=\"fa fa-ban\"></i>\n" +
    "                                <span>Clear</span>\n" +
    "                            </button>\n" +
    "                            \n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </form>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tm/media/subtabs/brochures/tmProfileMediaBrochuresView.html',
    "<div ng-controller=\"TmMediaBrochuresController\">\n" +
    "    <h3 class=\"heading\">Brochures ({{brochureItems.length}})</h3>\n" +
    "    <div class=\"tab-body\">\n" +
    "        <div class=\"gallery\">\n" +
    "            <div class=\"grid-view upload\" ng-class=\"selectedItem().id === '' ? 'selected' : 'unselected'\" ng-click=\"selectBrochure()\" ng-click=\"selectBrochure()\">\n" +
    "                <div class=\"source-link\">\n" +
    "                    <i class=\"fa fa-plus\"></i>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div ui-sortable=\"sortableOptions\" ng-model=\"filteredBrochureItems\">\n" +
    "                <div class=\"grid-view\" ng-class=\"selectedItem().id === item.id ? 'selected' : 'unselected'\"\n" +
    "                     ng-click=\"selectBrochure(item)\" ng-repeat=\"item in filteredBrochureItems\">\n" +
    "                    \n" +
    "                    <div class=\"preview\" ng-class=\"item.imageUrl ? '' : 'overlay' \">\n" +
    "                        <img ng-src=\"{{item.imageUrl || '/images/media/pdf-thumbnail.jpg'}}\"/>\n" +
    "                    </div>\n" +
    "                    \n" +
    "                    <div class=\"info\">\n" +
    "                        <div class=\"title\" item-order=\"{{item['orderType'][type]}}\">{{item.name}}</div>\n" +
    "                        <div class=\"types\">\n" +
    "                            <i class=\"fa fa-book\" ng-show=\"item.master\"></i>\n" +
    "                        </div>\n" +
    "                        <div class=\"actions\">\n" +
    "                            <a href=\"{{item.url}}\" target=\"_blank\" title=\"View {{item.name}}\">\n" +
    "                                <i class=\"fa fa-search\"></i>\n" +
    "                            </a>\n" +
    "                            <a ng-click=\"deleteBrochure(item)\" title=\"Delete {{item.name}}\">\n" +
    "                                <i class=\"fa fa-times-circle\"></i>\n" +
    "                            </a>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tm/media/subtabs/images/tmProfileMediaImagesSidebarView.html',
    "<div class=\"ibox\" ng-class=\"getSelected() === 1 ? 'selected' : 'collapsed'\" ng-controller=\"TmMediaImagesSidebarController\">\n" +
    "    <div class=\"ibox-title clickable\" ng-click=\"setSelected(1)\">\n" +
    "        <h5>Images ({{getImageItems().length}})</h5>\n" +
    "        <div class=\"ibox-tools\">\n" +
    "            <a>\n" +
    "                <i class=\"fa fa-chevron-up\"></i>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"ibox-content\">\n" +
    "        <div class=\"addForm\" ng-class=\"getImageUploadingInProgress() ? 'modal-overlay' : '' \">\n" +
    "            <form class=\"form-horizontal\" name=\"forms.formImage\" id=\"form-image\" novalidate>\n" +
    "                <div class=\"alert alert-info\" ng-hide=\"selectedImage.id.length > 0\">\n" +
    "                    <p>\n" +
    "                        <i class=\"fa fa-info-circle\"></i>\n" +
    "                        <span>Please click on the upload sign to upload an image or drag and drop image into it.</span>\n" +
    "                    </p>\n" +
    "                </div>\n" +
    "                <div class=\"alert alert-info\">\n" +
    "                    <p>\n" +
    "                        <i class=\"fa fa-info-circle\"></i>\n" +
    "                        <span>File must be: Less than 400KB / in jpg, jpeg format.</span><br />\n" +
    "                        <span>Maximum total size of images is 12800kb.</span><br />\n" +
    "                        <span>Images are scaled to 703 x 398 on front-end site.</span><br />\n" +
    "                        <span>Drag and drop images from left to right to change the order.</span>\n" +
    "                    </p>\n" +
    "                </div>\n" +
    "\n" +
    "                <div ng-show=\"selectedImage.id.length > 0\">\n" +
    "                    <div class=\"form-group\">\n" +
    "                        <label class=\"control-label col-sm-3 col-md-2\" for=\"title\">\n" +
    "                            <span>Title</span>\n" +
    "                            <small>76 characters</small>\n" +
    "                        </label>\n" +
    "                        <div class=\"col-sm-9 col-md-10\">\n" +
    "                            <input class=\"form-control\" name=\"title\" id=\"title\" placeHolder=\"Add title\" maxlength=\"76\" type=\"text\" ng-model=\"selectedImage.name\" focus-if=\"isHighlighted\" focus-delay=\"250\" />\n" +
    "                        </div> \n" +
    "                    </div>\n" +
    "\n" +
    "                    <div class=\"form-group\">\n" +
    "                        <label class=\"control-label col-sm-3 col-md-2\" for=\"description\">\n" +
    "                            <span>Description</span>\n" +
    "                            <small>96 characters</small>\n" +
    "                        </label>\n" +
    "                        <div class=\"col-sm-9 col-md-10\">\n" +
    "                            <textarea class=\"form-control\" name=\"description\" placeHolder=\"Add description\" id=\"description\" maxlength=\"96\" ng-model=\"selectedImage.description\"></textarea>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "\n" +
    "                    <div class=\"form-group  m-b-none\">\n" +
    "                        <div class=\"col-sm-12\">\n" +
    "                            <button class=\"btn btn-primary pull-right\" ng-class=\"{'disabled': submitInProgress()}\" ng-click=\"saveImageForm(selectedImage)\">\n" +
    "                                <i class=\"fa fa-check-circle\"></i>\n" +
    "                                <span>Update</span>\n" +
    "                            </button>\n" +
    "                            <button class=\"btn btn-default pull-right\" ng-class=\"{'disabled': submitInProgress()}\" ng-click=\"clearImageForm(selectedImage)\">\n" +
    "                                <i class=\"fa fa-ban\"></i>\n" +
    "                                <span>{{isEditMode() ? 'Clear' : 'Cancel'}}</span>\n" +
    "                            </button>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </form>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tm/media/subtabs/images/tmProfileMediaImagesView.html',
    "<div ng-controller=\"TmMediaImagesController as TmMediaImagesController\">\n" +
    "    <h3 class=\"heading\">Images ({{imageItems.length}}/40)</h3>\n" +
    "    <div class=\"tab-body\">\n" +
    "        <div class=\"gallery\">\n" +
    "            <div class=\"grid-view upload\" ng-class=\"isSelected() ? 'selected' : 'unselected'\" ng-click=\"selectImage()\">\n" +
    "                <div ng-show=\"!isTemporary()\" ng-if=\"isLoaded\">\n" +
    "                    <form name=\"forms.imageForm\"\n" +
    "                        class=\"clearfix dropzone image-form\"\n" +
    "                        enctype=\"multipart/form-data\"\n" +
    "                        ng-dropzone dropzone=\"TmMediaImagesController.dropZoneImageInstance\"\n" +
    "                        dropzone-config=\"imageConfig.dropzone\"\n" +
    "                        event-handlers=\"imageConfig.eventHandlers\"\n" +
    "                        novalidate >\n" +
    "                        <div class=\"upload-image\">\n" +
    "                            <i class=\"fa fa-upload\" aria-hidden=\"true\"></i>\n" +
    "                        </div>\n" +
    "                        <div class=\"fallback\">\n" +
    "                            <input name=\"file\" type=\"file\" />\n" +
    "                        </div>\n" +
    "                        <div class=\"dz-message\" ng-show=\"uploadEnabled\">\n" +
    "                            <i class=\"fa fa-upload\"></i>\n" +
    "                        </div>\n" +
    "                        <div class=\"dropzone-previews\"></div>\n" +
    "                    </form>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"preview\" ng-show=\"isTemporary()\">\n" +
    "                    <img ng-src=\"{{item.thumbnailUrl || '/images/media/media-image.png'}}\" />\n" +
    "                </div>\n" +
    "                \n" +
    "                <div class=\"info\" ng-show=\"isTemporary()\" ng-class=\"getImageUploadingInProgress() ? 'modal-overlay' : '' \">\n" +
    "                    <div class=\"title\" item-order=\"{{item['orderType'][type]}}\">\n" +
    "                        {{item.name}}\n" +
    "                    </div>\n" +
    "                    <div class=\"types\">\n" +
    "                        <i class=\"fa fa-book\" ng-show=\"item.master\"></i>\n" +
    "                    </div>\n" +
    "                    <div class=\"actions\">\n" +
    "                        <a href=\"{{item.url}}\" target=\"_blank\" title=\"View {{item.name}}\">\n" +
    "                            <i class=\"fa fa-search\"></i>\n" +
    "                        </a>\n" +
    "                        <a ng-click=\"deleteImage(item)\" title=\"Delete {{item.name}}\">\n" +
    "                            <i class=\"fa fa-times-circle\"></i>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div ui-sortable=\"sortableOptions\" ng-model=\"imageItems\">\n" +
    "                <div class=\"grid-view\" ng-class=\"selectedItem().id === item.id ? 'selected' : 'unselected'\" ng-click=\"selectImage(item)\" ng-repeat=\"item in imageItems\">\n" +
    "                    <div class=\"preview\">\n" +
    "                        <img ng-src=\"{{item.thumbnailUrl || '/images/media/media-image.png'}}\" />\n" +
    "                    </div>\n" +
    "                    <div class=\"info\">\n" +
    "                        <div class=\"title\" item-order=\"{{item['orderType']}}\">\n" +
    "                            {{item.name}}\n" +
    "                        </div>\n" +
    "                        <div class=\"types\">\n" +
    "                            <i class=\"fa fa-book\"></i>\n" +
    "                        </div>\n" +
    "                        <div class=\"actions\">\n" +
    "                            <a ng-click=\"openLightboxModal($index, item)\" title=\"View {{item.name}}\">\n" +
    "                                <i class=\"fa fa-search\"></i>\n" +
    "                            </a\n" +
    "                            >\n" +
    "                            <a ng-click=\"deleteImage(item)\" title=\"Delete {{item.name}}\">\n" +
    "                                <i class=\"fa fa-times-circle\"></i>\n" +
    "                            </a>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tm/media/subtabs/socialMedia/tmProfileMediaSocialMediaSidebarView.html',
    "<div class=\"ibox\" ng-class=\"getSelected() === 3 ? 'selected' : 'collapsed'\" ng-controller=\"TmMediaSocialMediasSidebar as TmMediaSocialMediasSidebarController\">\n" +
    "    <div class=\"ibox-title clickable\" ng-click=\"setSelected(3)\">\n" +
    "        <h5>Social Media</h5>\n" +
    "        <div class=\"ibox-tools\">\n" +
    "            <a>\n" +
    "                <i class=\"fa fa-chevron-up\"></i>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"ibox-content\">\n" +
    "        <p class=\"bold text-capitalize\">{{type()}}</p>\n" +
    "\n" +
    "        <div class=\"addForm social-media-form\">\n" +
    "            <form class=\"form-horizontal\" name=\"forms.formSocialMedia\" id=\"form-social-media\" novalidate>\n" +
    "                <div class=\"alert alert-info\">\n" +
    "                    <p>\n" +
    "                        <i class=\"fa fa-info-circle\"></i>\n" +
    "                        <span>Please add your</span>\n" +
    "                        <span class=\"text-capitalize\">{{type() === \"other\" ? \"Website\" : type()}}</span>\n" +
    "                        <span>link, e.g.</span>\n" +
    "                        <span class=\"text-nowrap\">{{TmMediaSocialMediasSidebarController.selectedSocialMediaUrl}}</span><br>\n" +
    "                    </p>\n" +
    "                </div>\n" +
    "\n" +
    "                <div ng-repeat=\"selectedSocialMedia in selectedSocialMedia[type()]\">\n" +
    "                    <div ng-show=\"selectedSocialMedia.display\">\n" +
    "                        \n" +
    "                        <div class=\"form-group\" ng-class=\"{'has-errors': invalidFields[$index]}\">\n" +
    "                            <label class=\"control-label col-sm-3 col-md-2\">\n" +
    "                                <span>URL</span>\n" +
    "                            </label>\n" +
    "                            <div class=\"col-sm-9 col-md-10\">\n" +
    "                                <input class=\"form-control\"\n" +
    "                                       name=\"url{{$index}}\"\n" +
    "                                       type=\"url\"\n" +
    "                                       focus-if=\"(isHighlighted && $index === 0) ? true : false\"\n" +
    "                                       focus-delay=\"250\"\n" +
    "                                       placeholder=\"http://\"\n" +
    "                                       ng-pattern=\"TmMediaSocialMediasSidebarController.urlPattern\"\n" +
    "                                       ng-focus=\"resetInvalidField($index)\"\n" +
    "                                       ng-model=\"selectedSocialMedia.url\"\n" +
    "                                       ng-keyup=\"TmMediaSocialMediasSidebarController.onKeyUp($event, type(), $index)\"\n" +
    "                                       maxlength=\"2011\"\n" +
    "\n" +
    "                                       custom-popover\n" +
    "                                       popover-trigger=\"manual\"\n" +
    "                                       popover-visibility=\"{{invalidFields[$index]}}\"\n" +
    "                                       popover-html=\"Add a valid <span class='text-capitalize'>{{type() === 'other' ? 'Website' : type()}}</span> link\"\n" +
    "                                       popover-placement=\"left\"/>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"form-group m-b-none\">\n" +
    "                    <div class=\"col-sm-12\">\n" +
    "                        <button class=\"btn btn-primary pull-right\" ng-click=\"saveSocialMediaForm()\">\n" +
    "                            <i class=\"fa fa-check-circle\"></i>\n" +
    "                            <span>{{isEditMode(type()) ? 'Update' : 'Save'}}</span>\n" +
    "                        </button>\n" +
    "                        <button class=\"btn btn-default pull-right\" ng-click=\"clearSocialMediaForm()\">\n" +
    "                            <i class=\"fa fa-ban\"></i>\n" +
    "                            <span>Clear</span>\n" +
    "                        </button>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </form>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tm/media/subtabs/socialMedia/tmProfileMediaSocialMediaView.html',
    "<div ng-controller=\"TmMediaSocialMediasController\">\n" +
    "    <h3 class=\"heading\">Social Media</h3>\n" +
    "\n" +
    "    <div class=\"tab-body\">\n" +
    "        <div class=\"gallery\">\n" +
    "            <div class=\"grid-view\" ng-repeat=\"(key, item) in socialMediaItems\" ng-class=\"type === key ? 'selected' : 'unselected'\" ng-click=\"selectSocialMedia(key)\">\n" +
    "                <div class=\"add-link\" ng-class=\"item.master ? 'hidden' : 'visible'\">\n" +
    "                    <i class=\"fa fa-plus\"></i>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"preview\" ng-class=\"item.master ? '' : 'overlay'\">\n" +
    "                    <img ng-src=\"/images/media/{{key}}-thumbnail.jpg\"/>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"info\">\n" +
    "                    <div class=\"title text-capitalize\">{{key}}</div>\n" +
    "                    <div class=\"types\">\n" +
    "                        <i class=\"fa fa-book\" ng-show=\"item.master\"></i>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tm/media/subtabs/videos/tmProfileMediaVideosSidebarView.html',
    "<div class=\"ibox\" ng-class=\"getSelected() === 2 ? 'selected' : 'collapsed'\" ng-controller=\"TmMediaVideosSidebarController as TmMediaVideosSidebarController\">\n" +
    "    <div class=\"ibox-title clickable\" ng-click=\"setSelected(2)\">\n" +
    "        <h5>Videos ({{getVideoItems().length}})</h5>\n" +
    "        <div class=\"ibox-tools\">\n" +
    "            <a>\n" +
    "                <i class=\"fa fa-chevron-up\"></i>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"ibox-content\">\n" +
    "        <div class=\"addForm\">\n" +
    "            <form class=\"form-horizontal\" name=\"forms.formVideo\" id=\"form-video\" novalidate>\n" +
    "                <div class=\"alert alert-info\">\n" +
    "                    <p>\n" +
    "                        <i class=\"fa fa-info-circle\"></i>\n" +
    "                        <span>Please add a YouTube link. Shortened video links with .be extension are not allowed.</span><br />\n" +
    "                        <span>Video title & description will be retrieved from YouTube.</span><br />\n" +
    "                        <span>Drag and drop videos from left to right to change the order.</span>\n" +
    "                    </p>\n" +
    "                </div>\n" +
    "                <div>\n" +
    "                    <div class=\"form-group\" ng-class=\"{'has-errors': getHasErrors()}\">\n" +
    "                        <label class=\"control-label col-sm-3 col-md-2\" for=\"url\">\n" +
    "                            <span>URL *</span>\n" +
    "                        </label>\n" +
    "                        <div class=\"col-sm-9 col-md-10\">\n" +
    "                            <input name=\"url\" id=\"url\" class=\"form-control\"\n" +
    "                                   ng-required=\"required\"\n" +
    "                                   focus-if=\"isHighlighted\"\n" +
    "                                   focus-delay=\"250\"\n" +
    "                                   type=\"url\"\n" +
    "                                   placeholder=\"http://\"\n" +
    "                                   ng-model=\"selectedVideo.url\"\n" +
    "                                   ng-pattern=\"youtubeUrlPattern\"\n" +
    "                                   ng-keyup=\"TmMediaVideosSidebarController.onKeyUp($event)\"\n" +
    "\n" +
    "                                   custom-popover\n" +
    "                                   popover-html=\"Add a valid YouTube link\"\n" +
    "                                   popover-placement=\"left\"\n" +
    "                                   popover-trigger=\"manual\"\n" +
    "                                   popover-visibility=\"{{getHasErrors() ? true : false}}\"\n" +
    "                                   ng-focus=\"setHasErrors(false)\" />\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"form-group\">\n" +
    "                        <div class=\"col-sm-12\">\n" +
    "                            <button class=\"btn btn-primary pull-right\" ng-click=\"saveVideoForm(selectedVideo)\">\n" +
    "                                <i class=\"fa fa-check-circle\"></i>\n" +
    "                                <span>{{isEditMode() ? 'Update' : 'Save'}}</span>\n" +
    "                            </button>\n" +
    "                            <button class=\"btn btn-default pull-right\" ng-click=\"clearVideoForm(selectedVideo)\">\n" +
    "                                <i class=\"fa fa-ban\"></i>\n" +
    "                                <span>Clear</span>\n" +
    "                            </button>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </form>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tm/media/subtabs/videos/tmProfileMediaVideosView.html',
    "<div ng-controller=\"TmMediaVideosController\">\n" +
    "    <h3 class=\"heading\">Videos ({{videoItems.length}})</h3>\n" +
    "    <div class=\"tab-body\">\n" +
    "        <div class=\"gallery\">\n" +
    "            <div class=\"grid-view upload\" ng-class=\"selectedItem().id === '' ? 'selected' : 'unselected'\" ng-click=\"selectVideo()\" ng-click=\"selectVideo()\">\n" +
    "                <div class=\"source-link\">\n" +
    "                    <i class=\"fa fa-plus\"></i>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div ui-sortable=\"sortableOptions\" ng-model=\"filteredVideoItems\">\n" +
    "                <div class=\"grid-view\" ng-class=\"selectedItem().id === item.id ? 'selected' : 'unselected'\" ng-click=\"selectVideo(item)\" ng-repeat=\"item in filteredVideoItems\">\n" +
    "                    <div class=\"preview\" ng-class=\"item.imageUrl ? '' : 'overlay' \">\n" +
    "                        <img ng-src=\"{{item.imageUrl || '/images/media/youtube-thumbnail.jpg'}}\" />\n" +
    "                    </div>\n" +
    "\n" +
    "                    <div class=\"info\">\n" +
    "                        <div class=\"title\" item-order=\"{{item['orderType'][type]}}\">\n" +
    "                            {{item.name || 'Retrieving title...' }}\n" +
    "                        </div>\n" +
    "                        <div class=\"types\">\n" +
    "                            <i class=\"fa fa-book\" ng-show=\"item.master\"></i>\n" +
    "                        </div>\n" +
    "                        <div class=\"actions\">\n" +
    "                            <a href=\"{{item.url}}\" target=\"_blank\" title=\"View {{item.name}}\">\n" +
    "                                <i class=\"fa fa-search\"></i>\n" +
    "                            </a>\n" +
    "                            <a ng-click=\"deleteVideo(item)\" title=\"Delete {{item.name}}\">\n" +
    "                                <i class=\"fa fa-times-circle\"></i>\n" +
    "                            </a>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tm/media/tmProfileMediaLinkManagerView.html',
    "<div class=\"nested-ibox\" ng-hide=\"showUpgradeForm\" ng-controller=\"TmMediaCommonSidebarController\">\n" +
    "    <div class=\"ibox-title m-t-xl\">\n" +
    "        <h4>Media Manager ({{getGeneralCounter()}})</h4>\n" +
    "    </div>\n" +
    "    <div class=\"ibox\">\n" +
    "        <div class=\"ibox-content\">\n" +
    "            <div include-replace ng-include src=\"'/scripts/components/profiles/tm/media/subtabs/images/tmProfileMediaImagesSidebarView.html'\"></div>\n" +
    "            <div include-replace ng-include src=\"'/scripts/components/profiles/tm/media/subtabs/videos/tmProfileMediaVideosSidebarView.html'\"></div>\n" +
    "            <div include-replace ng-include src=\"'/scripts/components/profiles/tm/media/subtabs/socialMedia/tmProfileMediaSocialMediaSidebarView.html'\"></div>\n" +
    "            <div include-replace ng-include src=\"'/scripts/components/profiles/tm/media/subtabs/brochures/tmProfileMediaBrochuresSidebarView.html'\"></div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tm/media/tmProfileMediaView.html',
    "<div ng-controller=\"TmProfileMediaController as TmProfileMediaController\">\n" +
    "    <div upgrade-banner\n" +
    "         info-block-class=\"TmProfileMediaController.isRightSidePanelActive() ? 'col-lg-9' : 'col-lg-10'\"\n" +
    "         buttons-block-class=\"TmProfileMediaController.isRightSidePanelActive() ? 'col-lg-3' : 'col-lg-2'\"\n" +
    "         basic-profile=\"!tmIsAdvanced ? true : false\"\n" +
    "         tm-profile=\"true\"\n" +
    "         upgrade-email=\"{{upgradeEmailsTo}}\"\n" +
    "         upgrade-click=\"TmProfileController.toggleUpgradeForm()\"></div>\n" +
    "\n" +
    "    <div ng-class=\"tmIsAdvanced ? '': 'modal-overlay-35'\">\n" +
    "        <div wave-spinner class=\"wave-spinner\" ng-show=\"isMediaReloading\"></div>\n" +
    "        <div ng-if=\"!isMediaReloading\">\n" +
    "            <div ng-show=\"TmProfileMediaController.isImagesTabActive()\" ng-include src=\"'/scripts/components/profiles/tm/media/subtabs/images/tmProfileMediaImagesView.html'\"></div>\n" +
    "            <div ng-show=\"TmProfileMediaController.isVideosTabActive()\" ng-include src=\"'/scripts/components/profiles/tm/media/subtabs/videos/tmProfileMediaVideosView.html'\"></div>\n" +
    "            <div ng-show=\"TmProfileMediaController.isSocialMediaTabActive()\" ng-include src=\"'/scripts/components/profiles/tm/media/subtabs/socialMedia/tmProfileMediaSocialMediaView.html'\"></div>\n" +
    "            <div ng-show=\"TmProfileMediaController.isBrochuresTabActive()\" ng-include src=\"'/scripts/components/profiles/tm/media/subtabs/brochures/tmProfileMediaBrochuresView.html'\"></div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tm/overview/faq/tmProfileOverviewFaqHistoryLogView.html',
    "<div class=\"ibox\">\n" +
    "    <div class=\"ibox-title m-t-xl\">\n" +
    "        <h5>History Log <small>showing last {{TmProfileController.overviewLog.totalFiltered}} records of {{TmProfileController.overviewLog.totalMatching}}</small></h5>\n" +
    "        <div class=\"ibox-tools\">\n" +
    "            <a class=\"close-link\" ng-click=\"TmProfileController.closeHistoryLogs()\">\n" +
    "                <i class=\"fa fa-times\"></i>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"ibox-content\">\n" +
    "        <div wave-spinner ng-show=\"TmProfileController.fetchingHistoryLogsInProgress\"></div>\n" +
    "\n" +
    "        <p class=\"text-muted text-center\" ng-show=\"!TmProfileController.fetchingHistoryLogsInProgress && !TmProfileController.overviewLog.total\">\n" +
    "            History log is empty\n" +
    "        </p>\n" +
    "\n" +
    "        <div ng-show=\"!TmProfileController.fetchingHistoryLogsInProgress\" ng-repeat=\"logs in TmProfileController.overviewLog.results\">\n" +
    "            <ul class=\"list-unstyled list-history break-word\">\n" +
    "                <li ng-if=\"logs.modifiedAt\">\n" +
    "                    <span class=\"bold\">Modified on:</span>\n" +
    "                    <span>{{logs.modifiedAt| date:'medium'}}</span>\n" +
    "                </li>\n" +
    "                <li ng-if=\"logs.modifiedByFullName\">\n" +
    "                    <span class=\"bold\">Submitted by:</span>\n" +
    "                    <span>{{logs.modifiedByFullName}}</span>\n" +
    "                </li>\n" +
    "                <li ng-repeat=\"log in logs.faq\">\n" +
    "                    <div>\n" +
    "                        <span class=\"bold\">Question {{$index + 1}}/{{TmProfileController.maxFaqItems}}:</span>\n" +
    "                        <span>{{log.question | htmlToPlaintext}}</span>\n" +
    "                    </div>\n" +
    "                    <div>\n" +
    "                        <span class=\"bold\">Answer {{$index + 1}}/{{TmProfileController.maxFaqItems}}:</span>\n" +
    "                        <span>{{log.answer | htmlToPlaintext}}</span>\n" +
    "                    </div>\n" +
    "                </li>\n" +
    "            </ul>\n" +
    "            \n" +
    "            <div class=\"separator dashed\"></div>\n" +
    "\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"row\" ng-show=\"TmProfileController.isMoreLogsAvailable()\">\n" +
    "            <div wave-spinner class=\"text-right\" ng-show=\"TmProfileController.isLoadMoreHistoryLogsInProgress\"></div>\n" +
    "            <div class=\" col-lg-8 col-lg-offset-2\">\n" +
    "                <a class=\"btn btn-primary btn-block\" ng-click=\"TmProfileController.handleLoadMoreHistoryLogs()\" ng-disabled=\"TmProfileController.isLoadMoreHistoryLogsInProgress\">\n" +
    "                   Load more\n" +
    "                </a>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tm/overview/faq/tmProfileOverviewFaqView.html',
    "<div class=\"alert alert-info\">\n" +
    "    <p>\n" +
    "        <i class=\"fa fa-info-circle\"></i>\n" +
    "        <span>You can add up to five FAQs. These will display in the order they are added below.</span>\n" +
    "    </p>\n" +
    "</div>\n" +
    "<div class=\"tab-header\">\n" +
    "    <div wave-spinner ng-show=\"historyDisabled\"></div>\n" +
    "    <h3 class=\"heading\"><span class=\"sr-only\">FAQ</span></h3>\n" +
    "    <a class=\"btn btn-warning btn-history\" ng-show=\"!historyDisabled\" ng-click=\"TmProfileOverviewController.handleTmFaqHistoryLogClick()\">\n" +
    "        <i class=\"fa fa-clock-o\"></i>\n" +
    "    </a>\n" +
    "</div>\n" +
    "<div class=\"tab-body\">\n" +
    "    <div ng-controller=\"TmProfileOverviewFaqController as TmProfileOverviewFaqController\">\n" +
    "        <form name=\"TmProfileOverviewFaqController.faqForm\" novalidate>\n" +
    "            <div ng-repeat=\"item in TmProfileOverviewFaqController.items track by $index\">\n" +
    "                <div ng-if=\"TmProfileOverviewFaqController.isItemVisible($index)\">\n" +
    "                    <div class=\"form-group\" ng-class=\"{'has-errors': TmProfileOverviewFaqController.invalidFaq[$index].q}\">\n" +
    "                        <label>Question {{$index + 1}}/{{TmProfileOverviewFaqController.maxItems}}</label>\n" +
    "                        <input type=\"text\" \n" +
    "                        name=\"question\"\n" +
    "                        class=\"form-control\" \n" +
    "                        ng-required=\"true\"\n" +
    "                        placeholder=\"e.g. How do I apply for the X program at X Business School?\" \n" +
    "                        ng-model=\"faq[$index].question\"\n" +
    "                        ng-focus=\"TmProfileOverviewFaqController.setQuestionInvalid(false, $index)\"\n" +
    "                         />\n" +
    "                         <div\n" +
    "                            focus-delay=\"250\"\n" +
    "                            custom-popover\n" +
    "                            popover-html=\"Please add a question\"\n" +
    "                            popover-placement=\"left\"\n" +
    "                            popover-trigger=\"manual\"\n" +
    "                            popover-visibility=\"{{TmProfileOverviewFaqController.invalidFaq[$index].q}}\">\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    \n" +
    "                    <div class=\"summernote-container form-group\" ng-class=\"{'has-errors': TmProfileOverviewFaqController.invalidFaq[$index].a}\">\n" +
    "                        <label>Answer</label>\n" +
    "                        <summernote\n" +
    "                            name=\"answer\"\n" +
    "                            ng-required=\"true\"\n" +
    "                            config=\"TmProfileOverviewFaqController.textEditorAdvancedOptions\"\n" +
    "                            ng-model=\"faq[$index].answer\"\n" +
    "                            on-focus=\"TmProfileOverviewFaqController.setAnswerInvalid(false, $index)\"\n" +
    "                            on-init=\"TmProfileOverviewFaqController.handleTextEditorChanges($index, faq[$index].answer)\"\n" +
    "                            on-change=\"TmProfileOverviewFaqController.handleTextEditorChanges($index, contents)\"\n" +
    "                            on-paste=\"TmProfileOverviewFaqController.handleClipboardPaste($index)\"\n" +
    "                            count-word=\"controller.wordsCounters[$index]\">\n" +
    "                        </summernote>\n" +
    "                    </div>\n" +
    "                    <div class=\"editor-note form-group\">\n" +
    "                        <span ng-class=\"{'text-red': TmProfileOverviewFaqController.isInvalidAnswer($index, faq[$index].answer)}\">\n" +
    "                            <span class=\"bold\">\n" +
    "                                <ng-pluralize count=\"TmProfileOverviewFaqController.wordsCounters[$index] || 0\" when=\"{'one': '1 word inserted','other': '{} words inserted'}\"></ng-pluralize>\n" +
    "                            </span>\n" +
    "                        </span>\n" +
    "                        <span class=\"pull-right\">Maximum {{TmProfileOverviewFaqController.wordsLimit}} words</span>\n" +
    "                        <div\n" +
    "                            focus-delay=\"250\"\n" +
    "                            custom-popover\n" +
    "                            popover-html=\"Please add answer\"\n" +
    "                            popover-placement=\"left\"\n" +
    "                            popover-trigger=\"manual\"\n" +
    "                            popover-visibility=\"{{TmProfileOverviewFaqController.invalidFaq[$index].a}}\">\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"actions m-b-md\">\n" +
    "                        <a class=\"btn btn-danger\"\n" +
    "                           ng-class=\"{'disabled': TmProfileOverviewFaqController.isRemoveDisabled($index)}\"\n" +
    "                           ng-click=\"TmProfileOverviewFaqController.handleRemoveClick($index)\">\n" +
    "                            <i class=\"fa fa-ban\"></i>\n" +
    "                            <span>Delete FAQ</span>\n" +
    "                        </a>\n" +
    "                        <a class=\"btn btn-primary\"\n" +
    "                           ng-show=\"TmProfileOverviewFaqController.showAddFaq($index)\"\n" +
    "                           ng-class=\"{'disabled': programsTabSubmitInProgress || faq.length === 5}\"\n" +
    "                           ng-click=\"TmProfileOverviewFaqController.handleAddClick()\">\n" +
    "                            <i class=\"fa fa-plus\"></i>\n" +
    "                            <span>Add FAQ</span>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"tab-footer\">\n" +
    "                <a class=\"btn btn-primary pull-right\" ng-click=\"TmProfileOverviewFaqController.handleUpdateClick()\">\n" +
    "                    <i class=\"fa fa-check-circle\"></i>\n" +
    "                    <span>Update</span>\n" +
    "                </a>\n" +
    "            </div>\n" +
    "        </form>\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/components/profiles/tm/overview/overview/tmProfileOverviewAdvancedOverviewView.html',
    "<form name=\"forms.overviewAdvancedForm\" enctype=\"multipart/form-data\">\n" +
    "    <div class=\"tab-header\">\n" +
    "        <div wave-spinner ng-show=\"historyDisabled\"></div>\n" +
    "        <h3 class=\"heading\">Advanced Description *</h3>\n" +
    "        <a class=\"btn btn-warning btn-history\" ng-show=\"!historyDisabled\" ng-click=\"TmProfileOverviewController.handleTmOverviewHistoryLogClick(true)\">\n" +
    "            <i class=\"fa fa-clock-o\"></i>\n" +
    "        </a>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"tab-body\">\n" +
    "        <div class=\"summernote-container\" ng-class=\"{'has-errors': TmProfileOverviewController.isOverviewInvalid()}\">\n" +
    "            <summernote\n" +
    "                config=\"TmProfileOverviewController.textEditorAdvancedOptions\"\n" +
    "                ng-model=\"tmProfile.advancedOverview\"\n" +
    "                on-focus=\"TmProfileOverviewController.setOverviewInvalid('advancedTmOverview')\"\n" +
    "                on-change=\"TmProfileOverviewController.advancedOverviewWords = countWords(contents)\"\n" +
    "                on-init=\"TmProfileOverviewController.advancedOverviewWords = countWords(tmProfile.advancedOverview)\"\n" +
    "                on-paste=\"TmProfileOverviewController.handleClipboardPaste('advancedOverview')\"\n" +
    "                count-word=\"TmProfileOverviewController.advancedOverviewWords\">\n" +
    "            </summernote>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"editor-note form-group\">\n" +
    "            <span ng-class=\"{'text-red': TmProfileOverviewController.isAdvancedOverviewInvalid(TmProfileOverviewController.advancedOverviewWords)}\">\n" +
    "                <span class=\"bold\">{{TmProfileOverviewController.advancedOverviewWords}}</span>\n" +
    "                <span>words inserted</span>\n" +
    "            </span>\n" +
    "            <span class=\"pull-right\">Maximum {{TmProfileOverviewController.advancedDescriptionWordLimit}} words</span>\n" +
    "\n" +
    "            <div\n" +
    "                focus-delay=\"250\"\n" +
    "                custom-popover\n" +
    "                popover-html=\"Add a general description about your business school\"\n" +
    "                popover-placement=\"left\"\n" +
    "                popover-trigger=\"manual\"\n" +
    "                popover-visibility=\"{{isAdvancedTmOverviewEmpty ? true : false}}\">\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</form>\n" +
    "\n" +
    "<div class=\"separator\"></div>\n" +
    "\n" +
    "<div  class=\"row\">\n" +
    "    <div class=\"col-lg-6 form-group\">\n" +
    "        <div>\n" +
    "            <h3>University Website</h3>\n" +
    "        </div>\n" +
    "        <input type=\"text\"\n" +
    "           placeholder=\"http://\"\n" +
    "           ng-model=\"tmProfile.websiteUrl\"\n" +
    "           ng-keyup=\"TmProfileOverviewController.onKeyUp($event)\"\n" +
    "           class=\"form-control\" />\n" +
    "    </div>\n" +
    "    <div class=\"col-lg-6 form-group\">\n" +
    "        <div class=\"display-inline-request-info margin-right-30\">\n" +
    "            <h3 class=\"inline\">Request info - Email</h3>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"form-group\">\n" +
    "            <input type=\"email\" placeholder=\"Email\" ng-model=\"tmProfile.requestInfoEmail\" class=\"form-control\" >\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"tab-footer\">\n" +
    "    <a class=\"btn btn-primary pull-right\"\n" +
    "       ng-class=\"{'disabled': overviewAdvancedFormSubmitInProgress}\"\n" +
    "       ng-click=\"handleOverviewAdvancedDataSubmit()\">\n" +
    "       <i class=\"fa fa-check-circle\"></i>\n" +
    "       <span>Update</span>\n" +
    "    </a>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/components/profiles/tm/overview/overview/tmProfileOverviewBasicOverviewView.html',
    "<form name=\"forms.overviewBasicForm\" enctype=\"multipart/form-data\">\n" +
    "    <div class=\"tab-header\">\n" +
    "      <div wave-spinner ng-show=\"historyDisabled\"></div>\n" +
    "      <h3 class=\"heading\">Basic Description *</h3>\n" +
    "      <a class=\"btn btn-warning btn-history\" ng-show=\"!historyDisabled\" ng-click=\"TmProfileOverviewController.handleTmOverviewHistoryLogClick()\">\n" +
    "        <i class=\"fa fa-clock-o\"></i>\n" +
    "      </a>\n" +
    "    </div>\n" +
    "    <div class=\"tab-body\">\n" +
    "        <div class=\"summernote-container\" ng-class=\"{'has-errors': TmProfileOverviewController.isOverviewInvalid()}\">\n" +
    "            <summernote\n" +
    "                config=\"TmProfileOverviewController.textEditorBasicOptions\"\n" +
    "                ng-model=\"tmProfile.basicOverview\"\n" +
    "                on-focus=\"TmProfileOverviewController.setOverviewInvalid('basicTmOverview')\"\n" +
    "                on-change=\"TmProfileOverviewController.basicOverviewWords = countWords(contents)\"\n" +
    "                on-init=\"TmProfileOverviewController.basicOverviewWords = countWords(tmProfile.basicOverview)\"\n" +
    "                on-paste=\"TmProfileOverviewController.handleClipboardPaste('basicOverview')\"\n" +
    "                count-word=\"TmProfileOverviewController.basicOverviewWords\">\n" +
    "            </summernote>\n" +
    "        </div>\n" +
    "\n" +
    "      <div class=\"editor-note form-group\">\n" +
    "        <span ng-class=\"{'text-red': TmProfileOverviewController.isBasicOverviewInvalid(TmProfileOverviewController.basicOverviewWords)}\">\n" +
    "          <span class=\"bold\">{{TmProfileOverviewController.basicOverviewWords}}</span>\n" +
    "          <span>words inserted</span>\n" +
    "        </span>\n" +
    "        <span class=\"pull-right\">Maximum {{TmProfileOverviewController.basicDescriptionWordLimit}} words</span>\n" +
    "        \n" +
    "        <div\n" +
    "            focus-delay=\"250\"\n" +
    "            custom-popover\n" +
    "            popover-html=\"Add a general description about your business school\"\n" +
    "            popover-placement=\"left\"\n" +
    "            popover-trigger=\"manual\"\n" +
    "            popover-visibility=\"{{isBasicTmOverviewEmpty ? true : false}}\">\n" +
    "        </div>\n" +
    "      </div>\n" +
    "\n" +
    "      <div class=\"tab-footer\">\n" +
    "        <a class=\"btn btn-primary pull-right\"\n" +
    "          ng-class=\"{'disabled': overviewBasicFormSubmitInProgress}\"\n" +
    "          ng-click=\"handleOverviewBasicDataSubmit()\">\n" +
    "          <i class=\"fa fa-check-circle\"></i>\n" +
    "          <span>Update</span>\n" +
    "        </a>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "</form>\n" +
    "\n" +
    "<div upgrade-banner\n" +
    "     info-block-class=\"isRightSidePanelActive() ? 'col-lg-9' : 'col-lg-10'\"\n" +
    "     buttons-block-class=\"isRightSidePanelActive() ? 'col-lg-3' : 'col-lg-2'\"\n" +
    "     basic-profile=\"true\"\n" +
    "     tm-profile=\"true\"\n" +
    "     upgrade-email=\"{{upgradeEmailsTo}}\"\n" +
    "     upgrade-click=\"TmProfileController.toggleUpgradeForm()\"></div>\n" +
    "\n" +
    "<div class=\"separator\"></div>\n" +
    "\n" +
    "<div class=\"locked\">\n" +
    "    <div  class=\"row\">\n" +
    "        <div class=\"col-lg-6 form-group\">\n" +
    "            <div>\n" +
    "                <h3>University Website</h3>\n" +
    "            </div>\n" +
    "            <input type=\"text\"\n" +
    "               disabled\n" +
    "               placeholder=\"http://\"\n" +
    "               ng-model=\"tmProfile.websiteUrl\"\n" +
    "               class=\"form-control\" />\n" +
    "        </div>\n" +
    "        <div class=\"col-lg-6 form-group\">\n" +
    "            <div class=\"display-inline-request-info margin-right-30\">\n" +
    "                <h3 class=\"inline\">Request info - Email</h3>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "                <input type=\"email\"\n" +
    "                   disabled\n" +
    "                   placeholder=\"Email\"\n" +
    "                   ng-model=\"tmProfile.requestInfoEmail\"\n" +
    "                   class=\"form-control\">\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tm/overview/overview/tmProfileOverviewHistoryLogView.html',
    "<div class=\"ibox\">\n" +
    "    <div class=\"ibox-title m-t-xl\">\n" +
    "        <h5>History Log <small>showing last {{TmProfileController.overviewLog.totalFiltered}} records of {{TmProfileController.overviewLog.totalMatching}}</small></h5>\n" +
    "        <div class=\"ibox-tools\">\n" +
    "            <a class=\"close-link\" ng-click=\"TmProfileController.closeHistoryLogs()\">\n" +
    "                <i class=\"fa fa-times\"></i>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"ibox-content\">\n" +
    "        <div wave-spinner ng-show=\"TmProfileController.fetchingHistoryLogsInProgress\"></div>\n" +
    "\n" +
    "        <p class=\"text-muted text-center\" ng-show=\"!TmProfileController.fetchingHistoryLogsInProgress && !TmProfileController.overviewLog.total\">\n" +
    "            History log is empty\n" +
    "        </p>\n" +
    "\n" +
    "        <div ng-show=\"!TmProfileController.fetchingHistoryLogsInProgress\" ng-repeat=\"log in TmProfileController.overviewLog.results\">\n" +
    "            <ul class=\"list-unstyled list-history break-word\">\n" +
    "                <li ng-if=\"log.modifiedAt\">\n" +
    "                    <span class=\"bold\">Modified on:</span>\n" +
    "                    <span>{{log.modifiedAt| date:'medium'}}</span>\n" +
    "                </li>\n" +
    "                <li ng-if=\"log.modifiedByFullName\">\n" +
    "                    <span class=\"bold\">Submitted by:</span>\n" +
    "                    <span>{{log.modifiedByFullName}}</span>\n" +
    "                </li>\n" +
    "                <li ng-show=\"!log.advanced\">\n" +
    "                    <span class=\"bold\">Basic Description:</span>\n" +
    "                    <span>{{log.basicOverview | htmlToPlaintext}}</span>\n" +
    "                </li>\n" +
    "                <li ng-show=\"log.advanced\">\n" +
    "                    <span class=\"bold\">Advanced Description:</span>\n" +
    "                    <span>{{log.advancedOverview | htmlToPlaintext}}</span>\n" +
    "                </li>\n" +
    "                <li ng-show=\"log.advanced\">\n" +
    "                    <span class=\"bold\">University Website:</span>\n" +
    "                    <a ng-show=\"log.websiteUrl\" href=\"{{log.websiteUrl}}\" target=\"_blank\">{{log.websiteUrl}}</a>\n" +
    "                </li>\n" +
    "                <li ng-show=\"log.advanced\">\n" +
    "                    <span class=\"bold\">Request info - Email:</span>\n" +
    "                    <span>{{log.requestInfoEmail}}</span>\n" +
    "                </li>\n" +
    "            </ul>\n" +
    "\n" +
    "            <div class=\"separator dashed\"></div>\n" +
    "\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"row\" ng-show=\"TmProfileController.isMoreLogsAvailable()\">\n" +
    "            <div wave-spinner ng-show=\"TmProfileController.isLoadMoreHistoryLogsInProgress\"></div>\n" +
    "            <div class=\" col-lg-8 col-lg-offset-2\">\n" +
    "                <a class=\"btn btn-primary btn-block\" ng-click=\"TmProfileController.handleLoadMoreHistoryLogs()\" ng-disabled=\"TmProfileController.isLoadMoreHistoryLogsInProgress\">\n" +
    "                   Load more\n" +
    "                </a>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tm/overview/tmProfileOverviewView.html',
    "<div ng-controller=\"TmProfileOverviewController as TmProfileOverviewController\">\n" +
    "    <div class=\"tabs-container\">\n" +
    "        <uib-tabset active=\"activeOverviewSubTab\">\n" +
    "            <uib-tab heading=\"Overview\">\n" +
    "                <div class=\"panel-body\">\n" +
    "                    <div ng-if=\"tmIsAdvanced\" ng-include src=\"'/scripts/components/profiles/tm/overview/overview/tmProfileOverviewAdvancedOverviewView.html'\"></div>\n" +
    "                    <div ng-if=\"!tmIsAdvanced\" ng-include src=\"'/scripts/components/profiles/tm/overview/overview/tmProfileOverviewBasicOverviewView.html'\"></div>\n" +
    "                </div>\n" +
    "            </uib-tab>\n" +
    "            <uib-tab heading=\"FAQ\">\n" +
    "                <div class=\"panel-body\">\n" +
    "                    <div ng-if=\"!tmIsAdvanced\"\n" +
    "                         upgrade-banner\n" +
    "                         info-block-class=\"isRightSidePanelActive() ? 'col-lg-9' : 'col-lg-10'\"\n" +
    "                         buttons-block-class=\"isRightSidePanelActive() ? 'col-lg-3' : 'col-lg-2'\"\n" +
    "                         basic-profile=\"true\"\n" +
    "                         tm-profile=\"true\"\n" +
    "                         upgrade-email=\"{{upgradeEmailsTo}}\"\n" +
    "                         upgrade-click=\"TmProfileController.toggleUpgradeForm()\"></div>\n" +
    "\n" +
    "                    <div ng-class=\"{'modal-overlay-35': !tmIsAdvanced}\"\n" +
    "                         ng-include src=\"'/scripts/components/profiles/tm/overview/faq/tmProfileOverviewFaqView.html'\"></div>\n" +
    "                </div>\n" +
    "            </uib-tab>\n" +
    "        </uib-tabset>\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/components/profiles/tm/program-stats/tmProfileProgramStatsView.html',
    "<div class=\"ibox m-b-none\" ng-controller=\"TmProfileProgramStatsController as TmProfileProgramStatsController\">\n" +
    "    <div class=\"ibox-content\">\n" +
    "        <ng-form name=\"TmProfileProgramStatsController.forms.addProgramStatsForm\" class=\"clearfix\" novalidate autocomplete=\"off\">\n" +
    "\n" +
    "            <div class=\"form-group row\" ng-if=\"!TmProfileProgramStatsController.isSchoolUser\">\n" +
    "                <div class=\"col-md-3\"><label>Program ID</label>  {{programId}}</div>\n" +
    "                <div class=\"col-md-2\"><label>Node ID</label>  {{program.nodeId}}</div>\n" +
    "                <div class=\"col-md-2\"><label>Core ID</label>  {{program.coreId}}</div>\n" +
    "                <div class=\"col-md-2\"><label>Institution Core ID</label>  {{program.institutionCoreId}}</div>\n" +
    "                <div class=\"col-md-3\"><label>Status</label>  {{program.status}}</div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"hr-line-dashed\"></div>\n" +
    "            <div class=\"form-group row\">\n" +
    "                <label class=\"col-sm-2\">Specialisation/s</label>\n" +
    "                <div class=\"col-sm-10\">\n" +
    "                    <ui-select\n" +
    "                        multiple\n" +
    "                        close-on-select=\"false\"\n" +
    "                        ng-model=\"program.specialisations\"\n" +
    "                        theme=\"bootstrap\">\n" +
    "                        <ui-select-match placeholder=\"Select an Option\">{{$item.name}}</ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                            refresh-delay=\"1000\"\n" +
    "                            repeat=\"option.handle as option in TmProfileProgramStatsController.specialisationsList | filter:$select.search\">\n" +
    "                            <div ng-bind-html=\"option.name | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"hr-line-dashed\"></div>\n" +
    "\n" +
    "            <div class=\"form-group row\">\n" +
    "                <label class=\"col-sm-2\">Average GMAT score for your cohort</label>\n" +
    "                <div class=\"col-sm-4\">\n" +
    "                    <input type=\"text\" class=\"form-control\" name=\"avgGmat\"\n" +
    "                           ng-model=\"stats.avgGmat\"\n" +
    "                           ng-class=\"{'border-red': !TmProfileProgramStatsController.isValidAverageGmat()}\"\n" +
    "                           ng-focus=\"TmProfileProgramStatsController.setValid('avgGmat', true)\"\n" +
    "                           custom-popover\n" +
    "                           popover-html=\"Enter values between 400-800\"\n" +
    "                           popover-placement=\"bottom\"\n" +
    "                           popover-trigger=\"manual\"\n" +
    "                           popover-visibility=\"{{!TmProfileProgramStatsController.isValidAverageGmat()}}\">\n" +
    "                </div>\n" +
    "                <label class=\"col-sm-2\">Tuition fee range for the full MBA course (USD)</label>\n" +
    "                <div class=\"col-sm-4\">\n" +
    "                    <ui-select\n" +
    "                        ng-model=\"stats.tuitionRange\"\n" +
    "                        theme=\"bootstrap\">\n" +
    "                        <ui-select-match placeholder=\"Select an Option\">{{$select.selected.label}}</ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                            refresh-delay=\"1000\"\n" +
    "                            repeat=\"option.value as option in TmProfileProgramStatsController.feesRangesList | filter:$select.search\">\n" +
    "                            <div ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"hr-line-dashed\"></div>\n" +
    "            <div class=\"form-group row\">\n" +
    "                <label class=\"col-sm-2\">Start month/s</label>\n" +
    "                <div class=\"col-sm-4\">\n" +
    "                    <ui-select\n" +
    "                        multiple\n" +
    "                        limit=\"4\"\n" +
    "                        close-on-select=\"false\"\n" +
    "                        ng-model=\"stats.startDates\"\n" +
    "                        theme=\"bootstrap\"\n" +
    "                        search-enabled=\"true\">\n" +
    "                        <ui-select-match placeholder=\"Select an Option\">{{$item.label}}</ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                            refresh-delay=\"1000\"\n" +
    "                            repeat=\"option.value as option in TmProfileProgramStatsController.monthsList | filter:$select.search\">\n" +
    "                            <div ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "                <label class=\"col-sm-2\">Length of your program in months</label>\n" +
    "                <div class=\"col-sm-4\">\n" +
    "                    <ui-select\n" +
    "                        ng-model=\"stats.programLength\"\n" +
    "                        theme=\"bootstrap\"\n" +
    "                        search-enabled=\"true\">\n" +
    "                        <ui-select-match placeholder=\"Select an Option\">{{$select.selected.label}}</ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                            refresh-delay=\"1000\"\n" +
    "                            repeat=\"option.value as option in TmProfileProgramStatsController.lengthList | filter:$select.search\">\n" +
    "                            <div ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"hr-line-dashed\"></div>\n" +
    "\n" +
    "            <div class=\"form-group row\">\n" +
    "                <label class=\"col-sm-2\">Accreditation/s</label>\n" +
    "                <div class=\"col-sm-4\">\n" +
    "                    <ui-select\n" +
    "                        multiple\n" +
    "                        limit=\"3\"\n" +
    "                        close-on-select=\"false\"\n" +
    "                        ng-model=\"stats.accreditations\"\n" +
    "                        theme=\"bootstrap\"\n" +
    "                        search-enabled=\"true\">\n" +
    "                        <ui-select-match placeholder=\"Select an Option\">{{$item.label}}</ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                            refresh-delay=\"1000\"\n" +
    "                            repeat=\"option.value as option in TmProfileProgramStatsController.accreditationsList | filter:$select.search\">\n" +
    "                            <div ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "                <label class=\"col-sm-2\">\n" +
    "                    Number of students on your program (Class size)\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-4\">\n" +
    "                    <input type=\"text\" class=\"form-control\" name=\"classSize\"\n" +
    "                       ng-model=\"stats.classSize\"\n" +
    "                       ng-class=\"{'border-red': !TmProfileProgramStatsController.isValidClassSize()}\"\n" +
    "                       ng-focus=\"TmProfileProgramStatsController.setValid('classSize', true)\"\n" +
    "                       custom-popover\n" +
    "                       popover-html=\"Enter values between 1-2000\"\n" +
    "                       popover-placement=\"bottom\"\n" +
    "                       popover-trigger=\"manual\"\n" +
    "                       popover-visibility=\"{{!TmProfileProgramStatsController.isValidClassSize()}}\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"hr-line-dashed\"></div>\n" +
    "\n" +
    "            <div class=\"form-group row\">\n" +
    "                <label class=\"col-sm-2\">\n" +
    "                    % of International students\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-4\">\n" +
    "                    <ui-select\n" +
    "                        ng-model=\"stats.percentInternationalStudents\"\n" +
    "                        theme=\"bootstrap\"\n" +
    "                        search-enabled=\"true\">\n" +
    "                        <ui-select-match placeholder=\"Select an Option\">{{$select.selected.label}}</ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                            refresh-delay=\"1000\"\n" +
    "                            repeat=\"option.value as option in TmProfileProgramStatsController.percentList | filter:$select.search\">\n" +
    "                            <div ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "                <label class=\"col-sm-2\">\n" +
    "                    Average age of students in your cohort\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-4\">\n" +
    "                    <ui-select\n" +
    "                        ng-model=\"stats.avgStudentAge\"\n" +
    "                        theme=\"bootstrap\"\n" +
    "                        search-enabled=\"true\">\n" +
    "                        <ui-select-match placeholder=\"Select an Option\">{{$select.selected.label}}</ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                            refresh-delay=\"1000\"\n" +
    "                            repeat=\"option.value as option in TmProfileProgramStatsController.yearList1850 | filter:$select.search\">\n" +
    "                            <div ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"hr-line-dashed\"></div>\n" +
    "\n" +
    "            <div class=\"form-group row\">\n" +
    "                <label class=\"col-sm-2\">\n" +
    "                    % of women in your cohort\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-4\">\n" +
    "                    <ui-select\n" +
    "                        ng-model=\"stats.percentWomenStudents\"\n" +
    "                        theme=\"bootstrap\"\n" +
    "                        search-enabled=\"true\">\n" +
    "                        <ui-select-match placeholder=\"Select an Option\">{{$select.selected.label}}</ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                            refresh-delay=\"1000\"\n" +
    "                            repeat=\"option.value as option in TmProfileProgramStatsController.percentList | filter:$select.search\">\n" +
    "                            <div ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "\n" +
    "                <label class=\"col-sm-2\">\n" +
    "                    Average years of work experience\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-4\">\n" +
    "                    <ui-select\n" +
    "                        ng-model=\"stats.avgWorkExperience\"\n" +
    "                        theme=\"bootstrap\"\n" +
    "                        search-enabled=\"true\">\n" +
    "                        <ui-select-match placeholder=\"Select an Option\">{{$select.selected.label}}</ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                            refresh-delay=\"1000\"\n" +
    "                            repeat=\"option.value as option in TmProfileProgramStatsController.yearList020 | filter:$select.search\">\n" +
    "                            <div ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"hr-line-dashed\"></div>\n" +
    "\n" +
    "            <div class=\"form-group row\">\n" +
    "                <label class=\"col-sm-2\">\n" +
    "                    Average salary 3 months post study (USD)\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-4\">\n" +
    "                    <input type=\"number\" class=\"form-control\" name=\"avgSalaryAfterGraduation\"\n" +
    "                       ng-model=\"stats.avgSalaryAfterGraduation\"\n" +
    "                       ng-class=\"{'border-red': !TmProfileProgramStatsController.isValidAverageSalaryAfterGraduation()}\"\n" +
    "                       ng-focus=\"TmProfileProgramStatsController.setValid('avgSalaryAfterGraduation', true)\"\n" +
    "\n" +
    "                       custom-popover\n" +
    "                       popover-html=\"Enter values between 0-300000\"\n" +
    "                       popover-placement=\"bottom\"\n" +
    "                       popover-trigger=\"manual\"\n" +
    "                       popover-visibility=\"{{!TmProfileProgramStatsController.isValidAverageSalaryAfterGraduation()}}\">\n" +
    "                </div>\n" +
    "\n" +
    "                <label class=\"col-sm-2\">\n" +
    "                    % employed 3 months post study\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-4\">\n" +
    "                    <ui-select\n" +
    "                        ng-model=\"stats.percentEmploymentAfterGraduation\"\n" +
    "                        theme=\"bootstrap\"\n" +
    "                        search-enabled=\"true\">\n" +
    "                        <ui-select-match placeholder=\"Select an Option\">{{$select.selected.label}}</ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                            refresh-delay=\"1000\"\n" +
    "                            repeat=\"option.value as option in TmProfileProgramStatsController.percentList | filter:$select.search\">\n" +
    "                            <div ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"hr-line-dashed\"></div>\n" +
    "\n" +
    "            <div class=\"form-group row\">\n" +
    "                <label class=\"col-sm-2\">\n" +
    "                    Do you offer a scholarship/s?\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-4\">\n" +
    "                    <ui-select\n" +
    "                        name=\"scholorship\"\n" +
    "                        ng-model=\"stats.offerScholarships\"\n" +
    "                        theme=\"bootstrap\">\n" +
    "                        <ui-select-match placeholder=\"Select an Option\">{{$select.selected.label}}</ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                            refresh-delay=\"1000\"\n" +
    "                            repeat=\"option.value as option in TmProfileProgramStatsController.offerScholarshipsList | filter:$select.search\">\n" +
    "                            <div ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "\n" +
    "                <label class=\"col-sm-2\">\n" +
    "                    Application Deadline\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-4\">\n" +
    "                    <span class=\"date-picker form-control btn\" type=\"text\"\n" +
    "                        date-range-picker\n" +
    "                        options=\"datePickerAppDead.options\"\n" +
    "                        ng-model=\"datePickerAppDead.date\">\n" +
    "                      {{stats.applicationDeadline | mDate}}\n" +
    "                  </span>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div ng-if=\"TmProfileProgramStatsController.isBmProgram()\">\n" +
    "                <div class=\"hr-line-dashed\"></div>\n" +
    "\n" +
    "                <div class=\"form-group row\">\n" +
    "                    <label class=\"col-sm-2\">\n" +
    "                        Delivery Method\n" +
    "                    </label>\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                        <ui-select\n" +
    "                            ng-model=\"stats.deliveryMethod\"\n" +
    "                            theme=\"bootstrap\"\n" +
    "                            search-enabled=\"true\">\n" +
    "                            <ui-select-match placeholder=\"Select an Option\">{{$select.selected.label}}</ui-select-match>\n" +
    "                            <ui-select-choices\n" +
    "                                refresh-delay=\"1000\"\n" +
    "                                repeat=\"option.value as option in TmProfileProgramStatsController.deliveryMethodsList | filter:$select.search\">\n" +
    "                                <div ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                            </ui-select-choices>\n" +
    "                            <ui-select-no-choice>\n" +
    "                                Not found\n" +
    "                            </ui-select-no-choice>\n" +
    "                        </ui-select>\n" +
    "                    </div>\n" +
    "                    <label class=\"col-sm-2\">\n" +
    "                        Foundation Year of Program\n" +
    "                    </label>\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                        <input type=\"number\" class=\"form-control\" name=\"foundationYear\"\n" +
    "                               ng-model=\"stats.foundationYear\"\n" +
    "                               ng-focus=\"TmProfileProgramStatsController.setValid('foundationYear', true)\">\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"hr-line-dashed\"></div>\n" +
    "                <div class=\"form-group row\">\n" +
    "                        <label class=\"col-sm-2\">\n" +
    "                            Total Tuition Fees (Domestic) (USD)\n" +
    "                        </label>\n" +
    "                        <div class=\"col-sm-4\">\n" +
    "                            <input type=\"number\" class=\"form-control\" name=\"tuitionFeesDom\"\n" +
    "                                   ng-model=\"stats.tuitionFeesDom\"\n" +
    "                                   ng-focus=\"TmProfileProgramStatsController.setValid('tuitionFeesDom', true)\">\n" +
    "                        </div>\n" +
    "\n" +
    "                        <label class=\"col-sm-2\">\n" +
    "                            Total Tuition Fees (International) (USD)\n" +
    "                        </label>\n" +
    "                        <div class=\"col-sm-4\">\n" +
    "                            <input type=\"number\" class=\"form-control\" name=\"tuitionFeesInt\"\n" +
    "                                   ng-model=\"stats.tuitionFeesInt\"\n" +
    "                                   ng-focus=\"TmProfileProgramStatsController.setValid('tuitionFeesInt', true)\">\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "\n" +
    "                    <div class=\"hr-line-dashed\"></div>\n" +
    "                <div class=\"form-group row\">\n" +
    "                    <label class=\"col-sm-2\">\n" +
    "                        Number of Nationalities in Current Cohort\n" +
    "                    </label>\n" +
    "                    <div class=\"col-sm-4\">\n" +
    "                        <input type=\"number\" class=\"form-control\" name=\"nationalitiesCount\"\n" +
    "                               ng-model=\"stats.nationalitiesCount\"\n" +
    "                               ng-focus=\"TmProfileProgramStatsController.setValid('nationalitiesCount', true)\">\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"hr-line-dashed\"></div>\n" +
    "\n" +
    "            <div class=\"row\" ng-class=\"{'modal-overlay': TmProfileProgramStatsController.editInProgress}\">\n" +
    "                <div class=\"col-sm-12 text-right\">\n" +
    "                    <a ng-click=\"TmProfileProgramStatsController.handleUpdateClick()\" class=\"btn btn-sm btn-primary\">\n" +
    "                        <i class=\"fa fa-check-circle\"></i> {{TmProfileProgramStatsController.getEditButtonTitle()}}\n" +
    "                    </a>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </ng-form>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tm/programs/datagrid/advancedCellTemplate.html',
    "<div class=\"ui-grid-cell-contents no-select text-center\" ng-click=\"$event.stopPropagation()\">\n" +
    "    <i ng-if=\"row.entity.advanced\" class=\"fa fa-star-o\"></i>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/components/profiles/tm/programs/datagrid/rowTemplate.html',
    "<div grid=\"grid\">\n" +
    "    <div class=\"ui-grid-cell pointer\"\n" +
    "        ng-repeat=\"(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name\"\n" +
    "        ng-class=\"{ 'ui-grid-row-header-cell': col.isRowHeader, 'active': row.entity.id == grid.appScope.selectedProgramId }\"\n" +
    "        role=\"{{col.isRowHeader ? 'rowheader' : 'gridcell'}}\"\n" +
    "        ui-grid-cell>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tm/programs/modalAdvancedProgramDetails.html',
    "<div class=\"modal-header\">\n" +
    "    <h3>{{modalOptions.headerText}}</h3>\n" +
    "</div>\n" +
    "<div class=\"modal-body\">\n" +
    "    <div class=\"table-responsive program-details-table\">\n" +
    "        <table class=\"table table-bordered\">\n" +
    "            <tbody>\n" +
    "                <tr>\n" +
    "                    <td class=\"col-md-6\">Program Name</td>\n" +
    "                    <td class=\"col-md-6\">{{modalOptions.programDetails.name}}</td>\n" +
    "                </tr>\n" +
    "                <tr>\n" +
    "                    <td>Institution Core ID</td>\n" +
    "                    <td>{{modalOptions.programDetails.institutionCoreId}}</td>\n" +
    "                </tr>\n" +
    "                <tr>\n" +
    "                    <td>Core ID</td>\n" +
    "                    <td>{{modalOptions.programDetails.coreId}}</td>\n" +
    "                </tr>\n" +
    "                <tr>\n" +
    "                    <td>Node ID</td>\n" +
    "                    <td>{{modalOptions.programDetails.nodeId || '-'}}</td>\n" +
    "                </tr>\n" +
    "                <tr>\n" +
    "                    <td>Program Type</td>\n" +
    "                    <td>\n" +
    "                        {{modalOptions.typesArray[modalOptions.programDetails.type]}}\n" +
    "                    </td>\n" +
    "                </tr>\n" +
    "                <tr ng-if=\"modalOptions.programDetails.advanced\">\n" +
    "                    <td>Advanced Program</td>\n" +
    "                    <td>\n" +
    "                        <i class=\"fa fa-check\"></i>\n" +
    "                    </td>\n" +
    "                </tr>\n" +
    "                <tr ng-if=\"modalOptions.programDetails.stats || (modalOptions.programDetails.specialisations.length > 0)\"\n" +
    "                    class=\"transparent-border\">\n" +
    "                    <td colspan=\"2\"></td>\n" +
    "                </tr>\n" +
    "                <tr ng-if=\"modalOptions.programDetails.specialisations && modalOptions.programDetails.specialisations.length\">\n" +
    "                    <td>Specialisation/s</td>\n" +
    "                    <td>\n" +
    "                        <span class=\"label m-r-xs inline\" ng-repeat=\"item in modalOptions.programDetails.specialisations\">{{modalOptions.specialisationsArray[item]}}</span>\n" +
    "                    </td>\n" +
    "                </tr>\n" +
    "                <tr ng-if=\"modalOptions.programDetails.stats.offerScholarships\">\n" +
    "                    <td>Do you offer a scholarship/s</td>\n" +
    "                    <td>{{modalOptions.programDetails.stats.offerScholarships | capitalize}}</td>\n" +
    "                </tr>\n" +
    "                <tr ng-if=\"modalOptions.programDetails.stats.classSize\">\n" +
    "                    <td>Number of students on your program (Class size)</td>\n" +
    "                    <td>{{modalOptions.programDetails.stats.classSize}}</td>\n" +
    "                </tr>\n" +
    "                <tr ng-if=\"modalOptions.programDetails.stats.tuitionRange\">\n" +
    "                    <td>Tuition fee range for the full MBA course (USD)</td>\n" +
    "                    <td>{{modalOptions.programDetails.stats.tuitionRange}}</td>\n" +
    "                </tr>\n" +
    "                <tr ng-if=\"modalOptions.programDetails.stats.avgGmat\">\n" +
    "                    <td>Average GMAT score for your cohort</td>\n" +
    "                    <td>{{modalOptions.programDetails.stats.avgGmat}}</td>\n" +
    "                </tr>\n" +
    "                <tr ng-if=\"modalOptions.programDetails.stats.programLength\">\n" +
    "                    <td>Length of Programs in months</td>\n" +
    "                    <td>{{modalOptions.programDetails.stats.programLength}}</td>\n" +
    "                </tr>\n" +
    "                <tr ng-if=\"modalOptions.programDetails.stats.avgSalaryAfterGraduation\">\n" +
    "                    <td>Average salary 3 months post study (USD)</td>\n" +
    "                    <td>{{modalOptions.programDetails.stats.avgSalaryAfterGraduation}}</td>\n" +
    "                </tr>\n" +
    "                <tr ng-if=\"modalOptions.programDetails.stats.percentEmploymentAfterGraduation.toString().length > 0\">\n" +
    "                    <td>% employed 3 months post study</td>\n" +
    "                    <td>{{modalOptions.programDetails.stats.percentEmploymentAfterGraduation}} %</td>\n" +
    "                </tr>\n" +
    "                <tr ng-if=\"modalOptions.programDetails.stats.avgWorkExperience.toString().length > 0\">\n" +
    "                    <td>Average years of work experience</td>\n" +
    "                    <td>{{modalOptions.programDetails.stats.avgWorkExperience}} years</td>\n" +
    "                </tr>\n" +
    "                <tr ng-if=\"modalOptions.programDetails.stats.percentInternationalStudents.toString().length > 0\">\n" +
    "                    <td>% of International students</td>\n" +
    "                    <td>{{modalOptions.programDetails.stats.percentInternationalStudents}} %</td>\n" +
    "                </tr>\n" +
    "                <tr ng-if=\"modalOptions.programDetails.stats.percentWomenStudents.toString().length > 0\">\n" +
    "                    <td>% of women in your cohort</td>\n" +
    "                    <td>{{modalOptions.programDetails.stats.percentWomenStudents}} %</td>\n" +
    "                </tr>\n" +
    "                <tr ng-if=\"modalOptions.programDetails.stats.accreditations.length > 0\">\n" +
    "                    <td>Accreditation/s</td>\n" +
    "                    <td><span class=\"label m-r-xs\" ng-repeat=\"item in modalOptions.programDetails.stats.accreditations\">{{item}}</span>\n" +
    "                    </td>\n" +
    "                </tr>\n" +
    "                <tr ng-if=\"modalOptions.programDetails.stats.startDates.length > 0\">\n" +
    "                    <td>Start month/s</td>\n" +
    "                    <td><span class=\"label m-r-xs\" ng-repeat=\"months in modalOptions.programDetails.stats.startDates\">{{months}}</span>\n" +
    "                    </td>\n" +
    "                </tr>\n" +
    "                <tr ng-if=\"modalOptions.programDetails.stats.avgStudentAge\">\n" +
    "                    <td>Average age of students in your cohort</td>\n" +
    "                    <td>{{modalOptions.programDetails.stats.avgStudentAge}} years</td>\n" +
    "                </tr>\n" +
    "\n" +
    "                <tr ng-if=\"modalOptions.programDetails.stats.deliveryMethod\">\n" +
    "                    <td>Delivery Method</td>\n" +
    "                    <td>{{modalOptions.deliveryMethods[modalOptions.programDetails.stats.deliveryMethod]}}</td>\n" +
    "                </tr>\n" +
    "                <tr ng-if=\"modalOptions.programDetails.stats.foundationYear\">\n" +
    "                    <td>Foundation Year of Program</td>\n" +
    "                    <td>{{modalOptions.programDetails.stats.foundationYear}}</td>\n" +
    "                </tr>\n" +
    "                <tr ng-if=\"modalOptions.programDetails.stats.tuitionFeesDom\">\n" +
    "                    <td>Total Tuition Fees (Domestic) (USD)</td>\n" +
    "                    <td>{{modalOptions.programDetails.stats.tuitionFeesDom}}</td>\n" +
    "                </tr>\n" +
    "                <tr ng-if=\"modalOptions.programDetails.stats.tuitionFeesInt\">\n" +
    "                    <td>Total Tuition Fees (International) (USD)</td>\n" +
    "                    <td>{{modalOptions.programDetails.stats.tuitionFeesInt}}</td>\n" +
    "                </tr>\n" +
    "                <tr ng-if=\"modalOptions.programDetails.stats.nationalitiesCount\">\n" +
    "                    <td>Number of Nationalities in Current Cohort</td>\n" +
    "                    <td>{{modalOptions.programDetails.stats.nationalitiesCount}}</td>\n" +
    "                </tr>\n" +
    "                <tr ng-if=\"modalOptions.programDetails.stats.applicationDeadline\">\n" +
    "                    <td>Application Deadline</td>\n" +
    "                    <td>{{modalOptions.programDetails.stats.applicationDeadline | mDate}}</td>\n" +
    "                </tr>\n" +
    "\n" +
    "            </tbody>\n" +
    "        </table>\n" +
    "    </div>\n" +
    "</div>\n" +
    "<div class=\"modal-footer\">\n" +
    "    <button type=\"button\" class=\"btn btn-default\" ng-click=\"modalOptions.close()\">\n" +
    "        {{modalOptions.closeButtonText}}\n" +
    "    </button>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/components/profiles/tm/programs/modalDowngradeProgramView.html',
    "<div class=\"modal-header\">\n" +
    "    <h3>{{modalOptions.headerText}}</h3>\n" +
    "</div>\n" +
    "<div class=\"modal-body\">\n" +
    "    <div ng-show=\"modalOptions.showContent()\">\n" +
    "        <p>\n" +
    "            The advance program currently has an active subscription. The subscription will need to be turned off in order to downgrade.\n" +
    "            If you wish to continue, press the downgrade button and you will be navigated to the backend of the advance profile\n" +
    "        </p>\n" +
    "        <hr>\n" +
    "        <p>\n" +
    "            Advanced TM : from <strong>{{modalOptions.tmSubscription.startDate | date:'short'}}</strong> to <strong>{{modalOptions.tmSubscription.endDate | date:'short'}}</strong>\n" +
    "        </p>\n" +
    "    </div>\n" +
    "\n" +
    "    <div ng-show=\"!modalOptions.showContent()\">\n" +
    "        <p>\n" +
    "            Advanced program does not have an active subscription. Press the downgrade button to downgrade to basic now.\n" +
    "        </p>\n" +
    "    </div>\n" +
    "\n" +
    "</div>\n" +
    "<div class=\"modal-footer\">\n" +
    "    <button type=\"button\" class=\"btn btn-default\" ng-click=\"modalOptions.close()\">\n" +
    "        {{modalOptions.closeButtonText}}\n" +
    "    </button>\n" +
    "    <button class=\"btn btn-primary\" ng-click=\"modalOptions.completeDowngradeClick()\">\n" +
    "        {{modalOptions.showContent() ? 'Downgrade from Backend' : 'Downgrade now'}}\n" +
    "    </button>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tm/programs/tmProfileAddProgramFormCampusView.html',
    "<div class=\"ibox tm-profile-add-program-campus-page\">\n" +
    "    <div class=\"ibox-title\">\n" +
    "        <h5>Campuses</h5>\n" +
    "        <div id=\"program-campuses\" class=\"ibox-tools\" expand=\"TmProfileProgramFormController.showCampus()\">\n" +
    "            <a ng-click=\"showHide()\" class=\"collapse-link\">\n" +
    "                <i class=\"fa fa-chevron-down\"></i>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"ibox-content pace-inactive p-b-none\">\n" +
    "        <p ng-class=\"{'text-danger': !TmProfileProgramFormController.isValidCampusAssigned()}\">\n" +
    "            <i class=\"fa fa-info-circle\"></i>\n" +
    "            <span>Add at least one campus to this program. Editing a Campus does not require Admin permissions and will also edit the Institution campus.</span>\n" +
    "        </p>\n" +
    "\n" +
    "        <div class=\"form-group\">\n" +
    "            <div class=\"col-sm-12\">\n" +
    "                <p ng-show=\"TmProfileProgramFormController.programCampuses.length\">\n" +
    "                    <strong>Campuses currently added to the program:</strong>\n" +
    "                </p>\n" +
    "                <ul class=\"list-unstyled list-campus\">\n" +
    "                    <li class=\"clearfix\"\n" +
    "                        ng-repeat=\"campus in TmProfileProgramFormController.programCampuses\"\n" +
    "                        ng-class=\"{'locked': TmProfileProgramFormController.isReadOnly}\">\n" +
    "                        <span>\n" +
    "                            {{campus.name}}\n" +
    "                            <i class=\"fa fa-star\"\n" +
    "                               uib-tooltip='Program primary campus'\n" +
    "                               tooltip-placement=\"top\"\n" +
    "                               ng-show=\"campus.id === TmProfileProgramFormController.program.primaryCampusId\">\n" +
    "                            </i>\n" +
    "                        </span>\n" +
    "                        <a class=\"btn btn-sm btn-outline btn-default pull-right\"\n" +
    "                            ng-click=\"TmProfileProgramFormController.editCampus($index)\">\n" +
    "                            <span>Edit</span>\n" +
    "                        </a>\n" +
    "                    </li>\n" +
    "                </ul>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"col-sm-12\">\n" +
    "                <ui-select\n" +
    "                    name=\"campus\"\n" +
    "                    multiple\n" +
    "                    close-on-select=\"false\"\n" +
    "                    ng-required=\"true\"\n" +
    "                    ng-model=\"TmProfileProgramFormController.newProgram.campus\"\n" +
    "                    theme=\"bootstrap\"\n" +
    "                    search-enabled=\"true\"\n" +
    "                    >\n" +
    "                    <ui-select-match placeholder=\"Select an Option\">\n" +
    "                        <span>{{$item.value | getCurrentLabel:TmProfileProgramFormController.campusesList}}</span>\n" +
    "                    </ui-select-match>\n" +
    "                    <ui-select-choices \n" +
    "                        refresh-delay=\"1000\"\n" +
    "                        ui-disable-choice=\"option.disabled\"\n" +
    "                        repeat=\"option.value as option in TmProfileProgramFormController.campusesList | filter:$select.search\">\n" +
    "                        <div ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                    </ui-select-choices>\n" +
    "                    <ui-select-no-choice>\n" +
    "                        Not found\n" +
    "                    </ui-select-no-choice>\n" +
    "                </ui-select>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"separator dashed text-center\">\n" +
    "            <span class=\"text\">OR</span>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"form-group\">\n" +
    "            <div class=\"col-sm-12\">\n" +
    "                <a class=\"btn btn-default btn-block\"\n" +
    "                    ng-class=\"{'locked': TmProfileProgramFormController.campusSubmitInProgress}\"\n" +
    "                    ng-click=\"TmProfileProgramFormController.handleAddCampusClick()\">\n" +
    "                    <i class=\"fa fa-plus\"></i>\n" +
    "                    <span>Create new campus</span>\n" +
    "                </a>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div ng-show=\"TmProfileProgramFormController.isCampusFormVisible()\">\n" +
    "            <div ng-include src=\"'/scripts/components/profiles/tm/programs/tmProfileProgramsAddCampusFormView.html'\"></div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div ng-show=\"TmProfileProgramFormController.isEditCampusFormVisible()\">\n" +
    "            <div ng-include src=\"'/scripts/components/profiles/tm/programs/tmProfileProgramsEditCampusFormView.html'\"></div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/components/profiles/tm/programs/tmProfileAddProgramFormDetailsView.html',
    "<div class=\"ibox\">\n" +
    "    <div class=\"ibox-title\">\n" +
    "        <h5>Program Details</h5>\n" +
    "        <div id=\"program-details\" class=\"ibox-tools\" expand=\"TmProfileProgramFormController.showDetails()\">\n" +
    "            <a ng-click=\"showHide()\" class=\"collapse-link\">\n" +
    "                <i class=\"fa fa-chevron-up\"></i>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"ibox-content\">\n" +
    "        <ng-form name=\"TmProfileProgramFormController.forms.addProgramDetailsForm\" novalidate autocomplete=\"off\">\n" +
    "            <div class=\"form-group\" ng-class=\"{'has-errors': !TmProfileProgramFormController.isValidName()}\">\n" +
    "                 <label class=\"control-label col-sm-5 col-md-4\">\n" +
    "                  <span>Program Name *</span>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <input type=\"text\"\n" +
    "                      name=\"name\"\n" +
    "                      placeholder=\"e.g. ESSEC Mannheim Executive MBA\"\n" +
    "                      ng-model=\"TmProfileProgramFormController.newProgram.name\"\n" +
    "                      class=\"form-control\"\n" +
    "                      ng-required=\"true\"\n" +
    "                      ng-class=\"isInvalidName ? 'border-red' : ''\"\n" +
    "                      ng-focus=\"TmProfileProgramFormController.setValid('name')\"\n" +
    "                      focus-delay=\"250\"\n" +
    "                      custom-popover popover-html=\"Add a program title\"\n" +
    "                      popover-placement=\"left\"\n" +
    "                      popover-trigger=\"manual\"\n" +
    "                      popover-visibility=\"{{!TmProfileProgramFormController.isValidName()}}\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\" ng-class=\"{'has-errors': !TmProfileProgramFormController.isValidType()}\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">\n" +
    "                  <span>Program Type *</span>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <ui-select\n" +
    "                        name=\"type\"\n" +
    "                        ng-model=\"TmProfileProgramFormController.newProgram.type\"\n" +
    "                        theme=\"bootstrap\"\n" +
    "                        focus-delay=\"250\"\n" +
    "                        ng-required=\"true\"\n" +
    "                        custom-popover popover-html=\"Select an option\"\n" +
    "                        popover-placement=\"left\"\n" +
    "                        popover-trigger=\"manual\"\n" +
    "                        popover-visibility=\"{{!TmProfileProgramFormController.isValidType()}}\"\n" +
    "                        on-select=\"TmProfileProgramFormController.setValid('type')\">\n" +
    "                        <ui-select-match placeholder=\"Select an option\"><span>{{$select.selected.label}}</span></ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                          position=\"down\"\n" +
    "                          repeat=\"option.value as option in TmProfileProgramFormController.typesList | filter: $select.search\">\n" +
    "                          <div class=\"test\" ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">\n" +
    "                    <span>Product</span>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <ui-select\n" +
    "                    ng-disabled=\"TmProfileProgramFormController.isReadOnly\"\n" +
    "                    multiple\n" +
    "                    close-on-select=\"false\"\n" +
    "                    ng-model=\"TmProfileProgramFormController.newProgram.products\"\n" +
    "                    theme=\"bootstrap\">\n" +
    "                        <ui-select-match placeholder=\"Select an Option\">{{$item.label}}</ui-select-match>\n" +
    "                        <ui-select-choices refresh-delay=\"1000\"\n" +
    "                                        repeat=\"option.value as option in TmProfileProgramFormController.productsList | filter:$select.search\">\n" +
    "                            <div ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"form-group row summernote-container\"\n" +
    "                 ng-class=\"{'has-errors': !TmProfileProgramFormController.isValidDescription() || TmProfileProgramFormController.descriptionWords > TmProfileProgramFormController.programDescriptionWordLimit}\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">\n" +
    "                  <span>Program Description *</span>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <summernote\n" +
    "                            config=\"TmProfileProgramFormController.textEditorOptions\"\n" +
    "                            ng-model=\"TmProfileProgramFormController.newProgram.description\"\n" +
    "                            on-focus=\"TmProfileProgramFormController.setValid('description')\"\n" +
    "                            on-change=\"TmProfileProgramFormController.descriptionWords = TmProfileProgramFormController.countWords(TmProfileProgramFormController.newProgram.description)\"\n" +
    "                            on-init=\"TmProfileProgramFormController.descriptionWords = TmProfileProgramFormController.countWords(TmProfileProgramFormController.newProgram.description)\"\n" +
    "                            on-paste=\"TmProfileProgramFormController.descriptionWords = TmProfileProgramFormController.countWords(TmProfileProgramFormController.newProgram.description)\"\n" +
    "                            count-word=\"TmProfileProgramFormController.descriptionWords\">\n" +
    "                    </summernote>\n" +
    "                    <div\n" +
    "                        focus-delay=\"250\"\n" +
    "                        custom-popover\n" +
    "                        popover-html=\"Add a program description\"\n" +
    "                        popover-placement=\"left\"\n" +
    "                        popover-trigger=\"manual\"\n" +
    "                        popover-visibility=\"{{!TmProfileProgramFormController.isValidDescription()}}\">\n" +
    "                    </div>\n" +
    "                    <div class=\"editor-note text-right\">\n" +
    "                        <span class=\"bold\" ng-class=\"{'text-red': TmProfileProgramFormController.descriptionWords > TmProfileProgramFormController.programDescriptionWordLimit}\">\n" +
    "                          {{TmProfileProgramFormController.descriptionWords}}/500\n" +
    "                        </span>\n" +
    "                    </div>\n" +
    "                    <p class=\"text-right\" ng-show=\"newProgram.description.length >= programDescriptionWordLimit\">\n" +
    "                        <span class=\"text-danger\">Reached maximum words limit</span>\n" +
    "                    </p>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\" ng-show=\"TmProfileProgramFormController.isSchoolUser\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">\n" +
    "                  <span>Comments</span>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                  <textarea class=\"form-control no-resize\" rows=\"4\" cols=\"50\"\n" +
    "                    name=\"comments\"\n" +
    "                    placeholder=\"Please add comments supporting your request\"\n" +
    "                    ng-model=\"TmProfileProgramFormController.newProgram.comments\">\n" +
    "                  </textarea>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </ng-form>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tm/programs/tmProfileAddProgramFormStatisticsView.html',
    "<div class=\"ibox\">\n" +
    "    <div class=\"ibox-title\">\n" +
    "        <h5>Program Statistics</h5>\n" +
    "        <div id=\"program-stats\" class=\"ibox-tools\" expand=\"TmProfileProgramFormController.showStatistics()\">\n" +
    "            <a ng-click=\"showHide()\" class=\"collapse-link\">\n" +
    "                <i class=\"fa fa-chevron-down\"></i>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"ibox-content pace-inactive\">\n" +
    "        <ng-form name=\"TmProfileProgramFormController.forms.addProgramStatsForm\" novalidate autocomplete=\"off\">\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-5\">\n" +
    "                    <span>Specialisation/s</span>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-7\">\n" +
    "                    <ui-select\n" +
    "                            multiple\n" +
    "                            close-on-select=\"false\"\n" +
    "                            ng-model=\"TmProfileProgramFormController.newProgram.specialisations\"\n" +
    "                            theme=\"bootstrap\"\n" +
    "                            >\n" +
    "                        <ui-select-match placeholder=\"Select an Option\">{{$item.name}}</ui-select-match>\n" +
    "                        <ui-select-choices refresh-delay=\"1000\"\n" +
    "                            repeat=\"option.handle as option in TmProfileProgramFormController.specialisationsList | filter:$select.search\">\n" +
    "                            <div ng-bind-html=\"option.name | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\" ng-class=\"{'has-errors': !TmProfileProgramFormController.isValidAverageGmat()}\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-5\">\n" +
    "                    <span>Average GMAT score for your cohort</span>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-7\">\n" +
    "                    <input type=\"text\"\n" +
    "                        class=\"form-control\"\n" +
    "                        name=\"avgGmat\"\n" +
    "                        ng-model=\"TmProfileProgramFormController.newProgram.stats.avgGmat\"\n" +
    "\n" +
    "                        ng-focus=\"TmProfileProgramFormController.setValid('avgGmat', true)\"\n" +
    "                        custom-popover\n" +
    "                        popover-html=\"Enter values between 400-800\"\n" +
    "                        popover-placement=\"bottom\"\n" +
    "                        popover-trigger=\"manual\"\n" +
    "                        popover-visibility=\"{{!TmProfileProgramFormController.isValidAverageGmat()}}\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-5\">\n" +
    "                    <span>Tuition fee range for the full MBA course (USD)</span>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-7\">\n" +
    "                    <ui-select\n" +
    "                        placeholder=\"Select an option\"\n" +
    "                        ng-model=\"TmProfileProgramFormController.newProgram.stats.tuitionRange\"\n" +
    "                        theme=\"bootstrap\">\n" +
    "                        <ui-select-match placeholder=\"Select an option\">\n" +
    "                            <span>{{$select.selected.label}}</span>\n" +
    "                        </ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                          position=\"down\"\n" +
    "                          repeat=\"option.value as option in TmProfileProgramFormController.feesRangesList | filter: $select.search\">\n" +
    "                          <div class=\"test\" ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-5\">\n" +
    "                    <span>Start month/s</span>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-7\">\n" +
    "                    <ui-select\n" +
    "                        multiple\n" +
    "                        close-on-select=\"false\"\n" +
    "                        limit= \"4\"\n" +
    "                        ng-model=\"TmProfileProgramFormController.newProgram.stats.startDates\"\n" +
    "                        theme=\"bootstrap\">\n" +
    "                        <ui-select-match placeholder=\"Select an option\"><span>{{$item.label}}</span></ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                          position=\"down\"\n" +
    "                          repeat=\"option.value as option in TmProfileProgramFormController.monthsList | filter: $select.search\">\n" +
    "                          <div class=\"test\" ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-5\">\n" +
    "                    <span>Length of your program in months</span>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-7\">\n" +
    "                    <ui-select\n" +
    "                        placeholder=\"Select an option\"\n" +
    "                        ng-model=\"TmProfileProgramFormController.newProgram.stats.programLength\"\n" +
    "                        theme=\"bootstrap\">\n" +
    "                        <ui-select-match placeholder=\"Select an option\"><span>{{$select.selected.label}}</span></ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                          position=\"down\"\n" +
    "                          repeat=\"option.value as option in TmProfileProgramFormController.lengthList | filter: $select.search\">\n" +
    "                          <div class=\"test\" ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-5\">\n" +
    "                    <span>Accreditation/s</span>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-7\">\n" +
    "                    <ui-select\n" +
    "                        multiple\n" +
    "                        close-on-select=\"false\"\n" +
    "                        limit= \"3\",\n" +
    "                        ng-model=\"TmProfileProgramFormController.newProgram.stats.accreditations\"\n" +
    "                        theme=\"bootstrap\">\n" +
    "                        <ui-select-match placeholder=\"Select an option\"><span>{{$item.label}}</span></ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                          position=\"down\"\n" +
    "                          repeat=\"option.value as option in TmProfileProgramFormController.accreditationsList | filter: $select.search\">\n" +
    "                          <div class=\"test\" ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\" ng-class=\"{'has-errors': !TmProfileProgramFormController.isValidClassSize()}\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-5\">\n" +
    "                    <span>Number of students on your program (Class size)</span>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-7\">\n" +
    "                    <input type=\"text\"\n" +
    "                        class=\"form-control\"\n" +
    "                        name=\"classSize\"\n" +
    "                        ng-model=\"TmProfileProgramFormController.newProgram.stats.classSize\"\n" +
    "                        ng-focus=\"TmProfileProgramFormController.setValid('classSize', true)\"\n" +
    "                        custom-popover\n" +
    "                        popover-html=\"Enter values between 1-2000\"\n" +
    "                        popover-placement=\"bottom\"\n" +
    "                        popover-trigger=\"manual\"\n" +
    "                        popover-visibility=\"{{!TmProfileProgramFormController.isValidClassSize()}}\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-5\">\n" +
    "                    <span>% of International students</span>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-7\">\n" +
    "                    <ui-select\n" +
    "                        ng-model=\"TmProfileProgramFormController.newProgram.stats.percentInternationalStudents\"\n" +
    "                        theme=\"bootstrap\">\n" +
    "                        <ui-select-match placeholder=\"Select an option\"><span>{{$select.selected.label}}</span></ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                          position=\"down\"\n" +
    "                          repeat=\"option.value as option in TmProfileProgramFormController.percentList | filter: $select.search\">\n" +
    "                          <div class=\"test\" ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-5\">\n" +
    "                    <span>Average age of students in your cohort</span>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-7\">\n" +
    "                    <ui-select\n" +
    "                        ng-model=\"TmProfileProgramFormController.newProgram.stats.avgStudentAge\"\n" +
    "                        theme=\"bootstrap\">\n" +
    "                        <ui-select-match placeholder=\"Select an option\"><span>{{$select.selected.label}}</span></ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                          position=\"down\"\n" +
    "                          repeat=\"option.value as option in TmProfileProgramFormController.yearList1850 | filter: $select.search\">\n" +
    "                          <div class=\"test\" ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-5\">\n" +
    "                    <span>% of women in your cohort</span>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-7\">\n" +
    "                    <ui-select\n" +
    "                        placeholder=\"Select an option\"\n" +
    "                        ng-model=\"TmProfileProgramFormController.newProgram.stats.percentWomenStudents\"\n" +
    "                        theme=\"bootstrap\">\n" +
    "                        <ui-select-match placeholder=\"Select an option\"><span>{{$select.selected.label}}</span></ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                          position=\"down\"\n" +
    "                          repeat=\"option.value as option in TmProfileProgramFormController.percentList | filter: $select.search\">\n" +
    "                          <div class=\"test\" ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-5\">\n" +
    "                    <span>Average years of work experience</span>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-7\">\n" +
    "                    <ui-select\n" +
    "                        ng-model=\"TmProfileProgramFormController.newProgram.stats.avgWorkExperience\"\n" +
    "                        theme=\"bootstrap\">\n" +
    "                        <ui-select-match placeholder=\"Select an option\"><span>{{$select.selected.label}}</span></ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                          position=\"down\"\n" +
    "                          repeat=\"option.value as option in TmProfileProgramFormController.yearList020 | filter: $select.search\">\n" +
    "                          <div class=\"test\" ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-5\">\n" +
    "                    <span>Do you offer a scholarship/s?</span>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-7\">\n" +
    "                    <ui-select\n" +
    "                        name=\"scholorship\"\n" +
    "                        ng-model=\"TmProfileProgramFormController.newProgram.stats.offerScholarships\"\n" +
    "                        theme=\"bootstrap\">\n" +
    "                        <ui-select-match placeholder=\"Select an option\"><span>{{$select.selected.label}}</span></ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                          position=\"down\"\n" +
    "                          repeat=\"option.value as option in TmProfileProgramFormController.offerScholarshipsList | filter: $select.search\">\n" +
    "                          <div class=\"test\" ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\" ng-class=\"{'has-errors': !TmProfileProgramFormController.isValidAverageSalaryAfterGraduation()}\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-5\">\n" +
    "                    <span>Average salary 3 months post study (USD)</span>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-7\">\n" +
    "                    <input type=\"number\"\n" +
    "                        class=\"form-control\"\n" +
    "                        name=\"avgSalaryAfterGraduation\"\n" +
    "                        ng-model=\"TmProfileProgramFormController.newProgram.stats.avgSalaryAfterGraduation\"\n" +
    "                        ng-focus=\"TmProfileProgramFormController.setValid('avgSalaryAfterGraduation', true)\"\n" +
    "                        custom-popover\n" +
    "                        popover-html=\"Enter values between 0-300000\"\n" +
    "                        popover-placement=\"bottom\"\n" +
    "                        popover-trigger=\"manual\"\n" +
    "                        popover-visibility=\"{{!TmProfileProgramFormController.isValidAverageSalaryAfterGraduation()}}\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-5\">\n" +
    "                    <span>% employed 3 months post study</span>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-7\">\n" +
    "                    <ui-select\n" +
    "                        ng-model=\"TmProfileProgramFormController.newProgram.stats.percentEmploymentAfterGraduation\"\n" +
    "                        theme=\"bootstrap\">\n" +
    "                        <ui-select-match placeholder=\"Select an option\"><span>{{$select.selected.label}}</span></ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                          position=\"down\"\n" +
    "                          repeat=\"option.value as option in TmProfileProgramFormController.percentList | filter: $select.search\">\n" +
    "                          <div class=\"test\" ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-5\">\n" +
    "                    <span>Application Deadline</span>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-7\">\n" +
    "                    <span class=\"date-picker form-control btn\" type=\"text\"\n" +
    "                        date-range-picker\n" +
    "                        options=\"datePickerAppDead.options\"\n" +
    "                        ng-model=\"datePickerAppDead.date\">\n" +
    "                      {{TmProfileProgramFormController.newProgram.stats.applicationDeadline | mDate}}\n" +
    "                  </span>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </ng-form>\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/components/profiles/tm/programs/tmProfileAddProgramFormView.html',
    "<div class=\"ibox\">\n" +
    "    <div class=\"ibox-title m-t-xl\">\n" +
    "        <h5>{{TmProfileProgramFormController.isSchoolUser ? 'Request to Add a Program' : 'Add Program'}}</h5>\n" +
    "        <div class=\"ibox-tools\">\n" +
    "            <a class=\"close-link\" ng-click=\"TmProfileProgramFormController.closeAddProgramForm()\">\n" +
    "                <i class=\"fa fa-times\"></i>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"ibox-content\">        \n" +
    "        <form class=\"form-horizontal\" name=\"forms.addProgramForm\" novalidate autocomplete=\"off\">\n" +
    "            <div ng-include src=\"'/scripts/components/profiles/tm/programs/tmProfileAddProgramFormDetailsView.html'\"></div>\n" +
    "            <div ng-include src=\"'/scripts/components/profiles/tm/programs/tmProfileAddProgramFormStatisticsView.html'\"></div>\n" +
    "            <div ng-include src=\"'/scripts/components/profiles/tm/programs/tmProfileAddProgramFormCampusView.html'\"></div>\n" +
    "            <div class=\"hr-line-dashed\"></div>\n" +
    "            <div class=\"form-group\" ng-class=\"{'locked': TmProfileProgramFormController.isProgramSubmitDisabled()}\">\n" +
    "                <div class=\"col-sm-12\">\n" +
    "                    <a class=\"btn btn-primary pull-right\"\n" +
    "                       ng-click=\"TmProfileProgramFormController.handleProgramCreateClick()\">\n" +
    "                        <i class=\"fa fa-check-circle\"></i>\n" +
    "                        <span>{{TmProfileProgramFormController.isSchoolUser ? 'Send' : 'Save'}}</span>\n" +
    "                    </a>\n" +
    "                    <a class=\"btn btn-default pull-right\" ng-click=\"TmProfileProgramFormController.closeAddProgramForm()\">\n" +
    "                        <i class=\"fa fa-ban\"></i>\n" +
    "                        <span>Cancel</span>\n" +
    "                    </a>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </form>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tm/programs/tmProfileEditProgramFormCampusView.html',
    "<div class=\"ibox tm-profile-edit-program-campus-page\">\n" +
    "    <div class=\"ibox-title\">\n" +
    "        <h5>Campuses</h5>\n" +
    "        <div id=\"program-campuses\" class=\"ibox-tools\" expand=\"TmProfileProgramFormController.showCampus()\">\n" +
    "            <a ng-click=\"showHide()\" class=\"collapse-link\">\n" +
    "                <i class=\"fa fa-chevron-down\"></i>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"ibox-content pace-inactive p-b-none\">\n" +
    "        <p ng-class=\"{'text-danger': !TmProfileProgramFormController.isValidCampusAssigned()}\">\n" +
    "            <i class=\"fa fa-info-circle\"></i>\n" +
    "            <span>Add at least one campus to this program. Editing a Campus does not require Admin permissions and will also edit the Institution campus.</span>\n" +
    "        </p>\n" +
    "\n" +
    "        <div ng-class=\"{'modal-overlay-35': TmProfileProgramFormController.campusSubmitInProgress || TmProfileProgramFormController.isReadOnly}\">\n" +
    "            <div class=\"form-group\">\n" +
    "                <div class=\"col-sm-12\">\n" +
    "                    <p ng-show=\"TmProfileProgramFormController.programCampuses.length\">\n" +
    "                        <strong>Campuses currently added to the program:</strong>\n" +
    "                    </p>\n" +
    "                    <ul class=\"list-unstyled list-campus\">\n" +
    "                        <li class=\"clearfix\"\n" +
    "                            ng-repeat=\"campus in TmProfileProgramFormController.programCampuses\">\n" +
    "                        <span>\n" +
    "                            {{campus.name}}\n" +
    "                            <i class=\"fa fa-star\"\n" +
    "                               uib-tooltip='Program primary campus'\n" +
    "                               tooltip-placement=\"top\"\n" +
    "                               ng-show=\"campus.id === TmProfileProgramFormController.program.primaryCampusId\">\n" +
    "                            </i>\n" +
    "                        </span>\n" +
    "                            <a class=\"btn btn-sm btn-outline btn-default pull-right\"\n" +
    "                               ng-click=\"TmProfileProgramFormController.editCampus($index)\">\n" +
    "                                <span>Edit</span>\n" +
    "                            </a>\n" +
    "                        </li>\n" +
    "                    </ul>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"col-sm-12\">\n" +
    "                    <ui-select\n" +
    "                        name=\"campus\"\n" +
    "                        multiple\n" +
    "                        ng-required=\"true\"\n" +
    "                        ng-disabled=\"TmProfileProgramFormController.isReadOnly\"\n" +
    "                        close-on-select=\"false\"\n" +
    "                        ng-model=\"TmProfileProgramFormController.program.campus\"\n" +
    "                        focus-delay=\"250\"\n" +
    "                        theme=\"bootstrap\">\n" +
    "                        <ui-select-match placeholder=\"Select an option\">\n" +
    "                            <span>{{$item.value | getCurrentLabel:TmProfileProgramFormController.campusesList}}</span>\n" +
    "                        </ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                            position=\"down\"\n" +
    "                            name=\"campus\"\n" +
    "                            ui-disable-choice=\"option.disabled\"\n" +
    "                            repeat=\"option.value as option in TmProfileProgramFormController.campusesList | filter: $select.search\">\n" +
    "                            <div ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"separator dashed text-center\">\n" +
    "                <span class=\"text\">OR</span>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "                <div class=\"col-sm-12\">\n" +
    "                    <a class=\"btn btn-default btn-block\"\n" +
    "                       ng-click=\"TmProfileProgramFormController.handleAddCampusClick()\">\n" +
    "                        <i class=\"fa fa-plus\"></i>\n" +
    "                        <span>Create new campus</span>\n" +
    "                    </a>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "\n" +
    "        <div ng-show=\"TmProfileProgramFormController.isCampusFormVisible()\">\n" +
    "            <div ng-include src=\"'/scripts/components/profiles/tm/programs/tmProfileProgramsAddCampusFormView.html'\"></div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div ng-show=\"TmProfileProgramFormController.isEditCampusFormVisible()\">\n" +
    "            <div ng-include src=\"'/scripts/components/profiles/tm/programs/tmProfileProgramsEditCampusFormView.html'\"></div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/components/profiles/tm/programs/tmProfileEditProgramFormDetailsView.html',
    "<div class=\"ibox\">\n" +
    "    <div class=\"ibox-title\">\n" +
    "        <h5>Program Details</h5>\n" +
    "        <div id=\"program-details\" class=\"ibox-tools\" expand=\"TmProfileProgramFormController.showDetails()\">\n" +
    "            <a ng-click=\"showHide()\" class=\"collapse-link\">\n" +
    "                <i class=\"fa fa-chevron-up\"></i>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"ibox-content\">\n" +
    "        <ng-form name=\"TmProfileProgramFormController.forms.editProgramDetailsForm\" novalidate autocomplete=\"off\">\n" +
    "            <div class=\"form-group\" ng-class=\"{'has-errors': !TmProfileProgramFormController.isValidName()}\">\n" +
    "                <label class=\"control-label col-sm-5  col-md-4\">\n" +
    "                    <i ng-if=\"TmProfileProgramFormController.programHistory.name\"\n" +
    "                       class=\"fa fa-info-circle cursor-default\"\n" +
    "                       uib-tooltip='{{TmProfileProgramFormController.programHistory.name}}'\n" +
    "                       tooltip-placement=\"left\">\n" +
    "                    </i>\n" +
    "                    <span>Program Name *</span>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <input type=\"text\"\n" +
    "                       name=\"name\"\n" +
    "                       class=\"form-control\"\n" +
    "                       placeholder=\"e.g. ESSEC Mannheim Executive MBA\"\n" +
    "                       ng-model=\"TmProfileProgramFormController.program.name\"\n" +
    "                       ng-disabled=\"TmProfileProgramFormController.isReadOnly\"\n" +
    "                       ng-required=\"true\"\n" +
    "                       ng-class=\"isInvalidName ? 'border-red' : ''\"\n" +
    "                       ng-focus=\"TmProfileProgramFormController.setValid('name')\"\n" +
    "                       focus-delay=\"250\"\n" +
    "                       custom-popover popover-html=\"Add a program title\"\n" +
    "                       popover-placement=\"left\"\n" +
    "                       popover-trigger=\"manual\"\n" +
    "                       popover-visibility=\"{{!TmProfileProgramFormController.isValidName()}}\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\" ng-class=\"{'has-errors': !TmProfileProgramFormController.isValidType()}\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">\n" +
    "                    <i ng-if=\"TmProfileProgramFormController.programHistory.type\"\n" +
    "                       class=\"fa fa-info-circle cursor-default\"\n" +
    "                       uib-tooltip='{{TmProfileProgramFormController.programHistory.type}}'\n" +
    "                       tooltip-placement=\"left\">\n" +
    "                    </i>\n" +
    "                    <span>Program Type *</span>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <ui-select\n" +
    "                        name=\"type\"\n" +
    "                        ng-model=\"TmProfileProgramFormController.program.type\"\n" +
    "                        ng-disabled=\"TmProfileProgramFormController.isReadOnly\"\n" +
    "                        theme=\"bootstrap\"\n" +
    "                        focus-delay=\"250\"\n" +
    "                        custom-popover popover-html=\"Select an option\"\n" +
    "                        popover-placement=\"left\"\n" +
    "                        popover-trigger=\"manual\"\n" +
    "                        popover-visibility=\"{{!TmProfileProgramFormController.isValidType()}}\">\n" +
    "                        <ui-select-match placeholder=\"Select an option\"><span>{{$select.selected.label}}</span></ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                          position=\"down\"\n" +
    "                          repeat=\"option.value as option in TmProfileProgramFormController.typesList | filter: $select.search\">\n" +
    "                          <div class=\"test\" ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">\n" +
    "                    <span>Product</span>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <ui-select\n" +
    "                    ng-disabled=\"TmProfileProgramFormController.isReadOnly\"\n" +
    "                    multiple\n" +
    "                    close-on-select=\"false\"\n" +
    "                    ng-model=\"TmProfileProgramFormController.program.products\"\n" +
    "                    theme=\"bootstrap\">\n" +
    "                        <ui-select-match placeholder=\"Select an Option\">{{$item.label}}</ui-select-match>\n" +
    "                        <ui-select-choices refresh-delay=\"1000\"\n" +
    "                                        repeat=\"option.value as option in TmProfileProgramFormController.productsList | filter:$select.search\">\n" +
    "                            <div ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"form-group summernote-container\"\n" +
    "                 ng-class=\"{'has-errors': !TmProfileProgramFormController.isValidDescription() || TmProfileProgramFormController.descriptionWords > TmProfileProgramFormController.programDescriptionWordLimit}\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">\n" +
    "                    <i ng-if=\"TmProfileProgramFormController.programHistory.description\"\n" +
    "                       class=\"fa fa-info-circle cursor-default\"\n" +
    "                       uib-tooltip-html=\"TmProfileProgramFormController.programHistory.description\"\n" +
    "                       tooltip-placement=\"left\">\n" +
    "                    </i>\n" +
    "                    <span>Program Description *</span>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <summernote\n" +
    "                            config=\"TmProfileProgramFormController.textEditorOptions\"\n" +
    "                            editor=\"TmProfileProgramFormController.editor\"\n" +
    "                            ng-model=\"TmProfileProgramFormController.program.description\"\n" +
    "                            on-focus=\"TmProfileProgramFormController.setValid('description')\"\n" +
    "                            on-change=\"TmProfileProgramFormController.descriptionWords = TmProfileProgramFormController.countWords(TmProfileProgramFormController.program.description)\"\n" +
    "                            on-init=\"TmProfileProgramFormController.descriptionWords = TmProfileProgramFormController.countWords(TmProfileProgramFormController.program.description)\"\n" +
    "                            on-paste=\"TmProfileProgramFormController.descriptionWords = TmProfileProgramFormController.countWords(TmProfileProgramFormController.program.description)\"\n" +
    "                            count-word=\"TmProfileProgramFormController.descriptionWords\">\n" +
    "                    </summernote>\n" +
    "                    <div\n" +
    "                        focus-delay=\"250\"\n" +
    "                        custom-popover\n" +
    "                        popover-html=\"Add a program description\"\n" +
    "                        popover-placement=\"left\"\n" +
    "                        popover-trigger=\"manual\"\n" +
    "                        popover-visibility=\"{{!TmProfileProgramFormController.isValidDescription()}}\">\n" +
    "                    </div>\n" +
    "\n" +
    "                    <div class=\"editor-note text-right\">\n" +
    "                        <span class=\"bold\" ng-class=\"{'text-red': TmProfileProgramFormController.descriptionWords > TmProfileProgramFormController.programDescriptionWordLimit}\">\n" +
    "                          {{TmProfileProgramFormController.descriptionWords}}/500\n" +
    "                        </span>\n" +
    "                    </div>\n" +
    "                  <p class=\"text-right\" ng-show=\"program.description.length >= programDescriptionWordLimit\">\n" +
    "                    <span class=\"text-danger\">Reached maximum words limit</span>\n" +
    "                  </p>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\" ng-show=\"(TmProfileProgramFormController.isSchoolUser) || (TmProfileProgramFormController.isDirectory)\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">\n" +
    "                    <i class=\"fa fa-info-circle cursor-default\"\n" +
    "                      ng-if=\"TmProfileProgramFormController.programHistory.comments\"\n" +
    "                      uib-tooltip='{{TmProfileProgramFormController.programHistory.comments}}'\n" +
    "                      tooltip-placement=\"left\">\n" +
    "                    </i>\n" +
    "                    <span>Comments</span>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                  <textarea class=\"form-control no-resize\" rows=\"4\" cols=\"50\"\n" +
    "                        placeholder=\"Please add comments supporting your request\"\n" +
    "                        ng-model=\"TmProfileProgramFormController.program.comments\"\n" +
    "                        ng-disabled=\"TmProfileProgramFormController.isReadOnly || TmProfileProgramFormController.isDirectory\">\n" +
    "                    </textarea>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </ng-form>\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/components/profiles/tm/programs/tmProfileEditProgramFormStatisticsView.html',
    "<div class=\"ibox\">\n" +
    "    <div class=\"ibox-title\">\n" +
    "        <h5>Program Statistics</h5>\n" +
    "        <div id=\"program-stats\" class=\"ibox-tools\" expand=\"TmProfileProgramFormController.showStatistics()\">\n" +
    "            <a ng-click=\"showHide()\" class=\"collapse-link\">\n" +
    "                <i class=\"fa fa-chevron-down\"></i>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"ibox-content pace-inactive\">\n" +
    "        <ng-form name=\"TmProfileProgramFormController.forms.editProgramStatsForm\" novalidate autocomplete=\"off\">\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-5\">\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                        ng-if=\"TmProfileProgramFormController.programHistory.specialisations\"\n" +
    "                        uib-tooltip='{{TmProfileProgramFormController.programHistory.specialisations}}'\n" +
    "                        tooltip-placement=\"left\">\n" +
    "                    </i>\n" +
    "                    <span>Specialisation/s</span>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-7\">\n" +
    "                    <ui-select\n" +
    "                    ng-disabled=\"TmProfileProgramFormController.isReadOnly\"\n" +
    "                    multiple\n" +
    "                    close-on-select=\"false\"\n" +
    "                    ng-model=\"TmProfileProgramFormController.program.specialisations\"\n" +
    "                    theme=\"bootstrap\">\n" +
    "                        <ui-select-match placeholder=\"Select an Option\">{{$item.name}}</ui-select-match>\n" +
    "                        <ui-select-choices refresh-delay=\"1000\"\n" +
    "                                        repeat=\"option.handle as option in TmProfileProgramFormController.specialisationsList | filter:$select.search\">\n" +
    "                            <div ng-bind-html=\"option.name | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\" ng-class=\"{'has-errors': !TmProfileProgramFormController.isValidAverageGmat()}\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-5\">\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                        ng-if=\"TmProfileProgramFormController.programHistory.stats.avgGmat\"\n" +
    "                        uib-tooltip='{{TmProfileProgramFormController.programHistory.stats.avgGmat}}'\n" +
    "                        tooltip-placement=\"left\">\n" +
    "                    </i>\n" +
    "                    <span>Average GMAT score for your cohort</span>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-7\">\n" +
    "                    <input type=\"text\"\n" +
    "                        class=\"form-control\"\n" +
    "                        name=\"avgGmat\"\n" +
    "                        ng-model=\"TmProfileProgramFormController.program.stats.avgGmat\"\n" +
    "                        ng-focus=\"TmProfileProgramFormController.setValid('avgGmat', true)\"\n" +
    "                        ng-disabled=\"TmProfileProgramFormController.isReadOnly\"\n" +
    "                        custom-popover\n" +
    "                        popover-html=\"Enter values between 400-800\"\n" +
    "                        popover-placement=\"left\"\n" +
    "                        popover-trigger=\"manual\"\n" +
    "                        popover-visibility=\"{{!TmProfileProgramFormController.isValidAverageGmat()}}\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-5\">\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                        ng-if=\"TmProfileProgramFormController.programHistory.stats.tuitionRange\"\n" +
    "                        uib-tooltip='{{TmProfileProgramFormController.programHistory.stats.tuitionRange}}'\n" +
    "                        tooltip-placement=\"left\">\n" +
    "                    </i>\n" +
    "                    <span>Tuition fee range for the full MBA course (USD)</span>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-7\">\n" +
    "                    <ui-select\n" +
    "                    ng-disabled=\"TmProfileProgramFormController.isReadOnly\"\n" +
    "                    ng-model=\"TmProfileProgramFormController.program.stats.tuitionRange\"\n" +
    "                    theme=\"bootstrap\">\n" +
    "                        <ui-select-match placeholder=\"Select an option\"><span>{{$select.selected.label}}</span></ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                          position=\"down\"\n" +
    "                          repeat=\"option.value as option in TmProfileProgramFormController.feesRangesList | filter: $select.search\">\n" +
    "                          <div class=\"test\" ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-5\">\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                        ng-if=\"TmProfileProgramFormController.programHistory.stats.startDates\"\n" +
    "                        uib-tooltip='{{TmProfileProgramFormController.programHistory.stats.startDates}}'\n" +
    "                        tooltip-placement=\"left\">\n" +
    "                    </i>\n" +
    "                    <span>Start month/s</span>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-7\">\n" +
    "                    <ui-select\n" +
    "                        multiple\n" +
    "                        close-on-select=\"false\"\n" +
    "                        ng-disabled=\"TmProfileProgramFormController.isReadOnly\"\n" +
    "                        limit= \"4\",\n" +
    "                        ng-model=\"TmProfileProgramFormController.program.stats.startDates\"\n" +
    "                        theme=\"bootstrap\">\n" +
    "                        <ui-select-match placeholder=\"Select an option\"><span>{{$item.label}}</span></ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                          position=\"down\"\n" +
    "                          repeat=\"option.value as option in TmProfileProgramFormController.monthsList | filter: $select.search\">\n" +
    "                          <div class=\"test\" ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-5\">\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                        ng-if=\"TmProfileProgramFormController.programHistory.stats.programLength\"\n" +
    "                        uib-tooltip='{{TmProfileProgramFormController.programHistory.stats.programLength}}'\n" +
    "                        tooltip-placement=\"left\">\n" +
    "                    </i>\n" +
    "                    <span>Length of your program in months</span>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-7\">\n" +
    "                    <ui-select\n" +
    "                        ng-disabled=\"TmProfileProgramFormController.isReadOnly\"\n" +
    "                        ng-model=\"TmProfileProgramFormController.program.stats.programLength\"\n" +
    "                        theme=\"bootstrap\">\n" +
    "                        <ui-select-match placeholder=\"Select an option\"><span>{{$select.selected.label}}</span></ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                          position=\"down\"\n" +
    "                          repeat=\"option.value as option in TmProfileProgramFormController.lengthList | filter: $select.search\">\n" +
    "                          <div class=\"test\" ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-5\">\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                        ng-if=\"TmProfileProgramFormController.programHistory.stats.accreditations\"\n" +
    "                        uib-tooltip='{{TmProfileProgramFormController.programHistory.stats.accreditations}}'\n" +
    "                        tooltip-placement=\"left\">\n" +
    "                    </i>\n" +
    "                    <span>Accreditation/s</span>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-7\">\n" +
    "                    <ui-select\n" +
    "                        multiple\n" +
    "                        ng-disabled=\"TmProfileProgramFormController.isReadOnly\"\n" +
    "                        close-on-select=\"false\"\n" +
    "                        limit=\"3\",\n" +
    "                        ng-model=\"TmProfileProgramFormController.program.stats.accreditations\"\n" +
    "                        theme=\"bootstrap\">\n" +
    "                        <ui-select-match placeholder=\"Select an option\"><span>{{$item.label}}</span></ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                          position=\"down\"\n" +
    "                          repeat=\"option.value as option in TmProfileProgramFormController.accreditationsList | filter: $select.search\">\n" +
    "                          <div class=\"test\" ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\" ng-class=\"{'has-errors': !TmProfileProgramFormController.isValidClassSize()}\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-5\">\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                        ng-if=\"TmProfileProgramFormController.programHistory.stats.classSize\"\n" +
    "                        uib-tooltip='{{TmProfileProgramFormController.programHistory.stats.classSize}}'\n" +
    "                        tooltip-placement=\"left\">\n" +
    "                    </i>\n" +
    "                    <span>Number of students on your program (Class size)</span>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-7\">\n" +
    "                    <input type=\"text\"\n" +
    "                        class=\"form-control\"\n" +
    "                        name=\"classSize\"\n" +
    "                        ng-disabled=\"TmProfileProgramFormController.isReadOnly\"\n" +
    "                        ng-model=\"TmProfileProgramFormController.program.stats.classSize\"\n" +
    "                        ng-focus=\"TmProfileProgramFormController.setValid('classSize', true)\"\n" +
    "                        custom-popover\n" +
    "                        popover-html=\"Enter values between 1-2000\"\n" +
    "                        popover-placement=\"left\"\n" +
    "                        popover-trigger=\"manual\"\n" +
    "                        popover-visibility=\"{{!TmProfileProgramFormController.isValidClassSize()}}\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-5\">\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                        ng-if=\"TmProfileProgramFormController.programHistory.stats.percentInternationalStudents\"\n" +
    "                        uib-tooltip='{{TmProfileProgramFormController.programHistory.stats.percentInternationalStudents}} %'\n" +
    "                        tooltip-placement=\"left\">\n" +
    "                    </i>\n" +
    "                    <span>% of International students</span>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-7\">\n" +
    "                    <ui-select\n" +
    "                    ng-disabled=\"TmProfileProgramFormController.isReadOnly\"\n" +
    "                    ng-model=\"TmProfileProgramFormController.program.stats.percentInternationalStudents\"\n" +
    "                    theme=\"bootstrap\">\n" +
    "                        <ui-select-match placeholder=\"Select an option\"><span>{{$select.selected.label}}</span></ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                          position=\"down\"\n" +
    "                          repeat=\"option.value as option in TmProfileProgramFormController.percentList | filter: $select.search\">\n" +
    "                          <div class=\"test\" ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-5\">\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                        ng-if=\"TmProfileProgramFormController.programHistory.stats.avgStudentAge\"\n" +
    "                        uib-tooltip='{{TmProfileProgramFormController.programHistory.stats.avgStudentAge}} years'\n" +
    "                        tooltip-placement=\"left\">\n" +
    "                    </i>\n" +
    "                    <span>Average age of students in your cohort</span>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-7\">\n" +
    "                    <ui-select\n" +
    "                        ng-disabled=\"TmProfileProgramFormController.isReadOnly\"\n" +
    "                        ng-model=\"TmProfileProgramFormController.program.stats.avgStudentAge\"\n" +
    "                        theme=\"bootstrap\">\n" +
    "                        <ui-select-match placeholder=\"Select an option\"><span>{{$select.selected.label}}</span></ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                          position=\"down\"\n" +
    "                          repeat=\"option.value as option in TmProfileProgramFormController.yearList1850 | filter: $select.search\">\n" +
    "                          <div class=\"test\" ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-5\">\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                        ng-if=\"TmProfileProgramFormController.programHistory.stats.percentWomenStudents\"\n" +
    "                        uib-tooltip='{{TmProfileProgramFormController.programHistory.stats.percentWomenStudents}} %'\n" +
    "                        tooltip-placement=\"left\">\n" +
    "                    </i>\n" +
    "                    <span>% of women in your cohort</span>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-7\">\n" +
    "                    <ui-select\n" +
    "                        ng-model=\"TmProfileProgramFormController.program.stats.percentWomenStudents\"\n" +
    "                        ng-disabled=\"TmProfileProgramFormController.isReadOnly\"\n" +
    "                        theme=\"bootstrap\">\n" +
    "                        <ui-select-match placeholder=\"Select an option\"><span>{{$select.selected.label}}</span></ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                          position=\"down\"\n" +
    "                          repeat=\"option.value as option in TmProfileProgramFormController.percentList | filter: $select.search\">\n" +
    "                          <div class=\"test\" ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-5\">\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                        ng-if=\"TmProfileProgramFormController.programHistory.stats.avgWorkExperience\"\n" +
    "                        uib-tooltip='{{TmProfileProgramFormController.programHistory.stats.avgWorkExperience}} years'\n" +
    "                        tooltip-placement=\"left\">\n" +
    "                    </i>\n" +
    "                    <span>Average years of work experience</span>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-7\">\n" +
    "                    <ui-select\n" +
    "                        ng-disabled=\"TmProfileProgramFormController.isReadOnly\"\n" +
    "                        ng-model=\"TmProfileProgramFormController.program.stats.avgWorkExperience\"\n" +
    "                        theme=\"bootstrap\">\n" +
    "                        <ui-select-match placeholder=\"Select an option\"><span>{{$select.selected.label}}</span></ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                          position=\"down\"\n" +
    "                          repeat=\"option.value as option in TmProfileProgramFormController.yearList020 | filter: $select.search\">\n" +
    "                          <div class=\"test\" ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-5\">\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                        ng-if=\"!TmProfileProgramFormController.isUndefined(TmProfileProgramFormController.programHistory.stats.offerScholarships)\"\n" +
    "                        uib-tooltip='{{TmProfileProgramFormController.programHistory.stats.offerScholarships ? \"Yes\" : \"No\"}}'\n" +
    "                        tooltip-placement=\"left\">\n" +
    "                    </i>\n" +
    "                    <span>Do you offer a scholarship/s?</span>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-7\">\n" +
    "                    <ui-select\n" +
    "                        ng-disabled=\"TmProfileProgramFormController.isReadOnly\"\n" +
    "                        ng-model=\"TmProfileProgramFormController.program.stats.offerScholarships\"\n" +
    "                        theme=\"bootstrap\">\n" +
    "                        <ui-select-match placeholder=\"Select an option\"><span>{{$select.selected.label}}</span></ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                          position=\"down\"\n" +
    "                          repeat=\"option.value as option in TmProfileProgramFormController.offerScholarshipsList | filter: $select.search\">\n" +
    "                          <div class=\"test\" ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\" ng-class=\"{'has-errors': !TmProfileProgramFormController.isValidAverageSalaryAfterGraduation()}\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-5\">\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                        ng-if=\"TmProfileProgramFormController.programHistory.stats.avgSalaryAfterGraduation\"\n" +
    "                        uib-tooltip='{{TmProfileProgramFormController.programHistory.stats.avgSalaryAfterGraduation}}'\n" +
    "                        tooltip-placement=\"left\">\n" +
    "                    </i>\n" +
    "                    <span>Average salary 3 months post study (USD)</span>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-7\">\n" +
    "                    <input type=\"number\"\n" +
    "                        class=\"form-control\"\n" +
    "                        name=\"avgSalaryAfterGraduation\"\n" +
    "                        ng-model=\"TmProfileProgramFormController.program.stats.avgSalaryAfterGraduation\"\n" +
    "                        ng-disabled=\"TmProfileProgramFormController.isReadOnly\"\n" +
    "                        ng-focus=\"TmProfileProgramFormController.setValid('avgSalaryAfterGraduation', true)\"\n" +
    "                        custom-popover\n" +
    "                        popover-html=\"Enter values between 0-300000\"\n" +
    "                        popover-placement=\"left\"\n" +
    "                        popover-trigger=\"manual\"\n" +
    "                        popover-visibility=\"{{!TmProfileProgramFormController.isValidAverageSalaryAfterGraduation()}}\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-5\">\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                        ng-if=\"TmProfileProgramFormController.programHistory.stats.percentEmploymentAfterGraduation\"\n" +
    "                        uib-tooltip='{{TmProfileProgramFormController.programHistory.stats.percentEmploymentAfterGraduation}} %'\n" +
    "                        tooltip-placement=\"left\">\n" +
    "                    </i>\n" +
    "                    <span>% employed 3 months post study</span>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-7\">\n" +
    "                    <ui-select\n" +
    "                        ng-model=\"TmProfileProgramFormController.program.stats.percentEmploymentAfterGraduation\"\n" +
    "                        ng-disabled=\"TmProfileProgramFormController.isReadOnly\"\n" +
    "                        theme=\"bootstrap\">\n" +
    "                        <ui-select-match placeholder=\"Select an option\"><span>{{$select.selected.label}}</span></ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                          position=\"down\"\n" +
    "                          repeat=\"option.value as option in TmProfileProgramFormController.percentList | filter: $select.search\">\n" +
    "                          <div class=\"test\" ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-5\">\n" +
    "                    <span>Application Deadline</span>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-7\">\n" +
    "                    <span class=\"date-picker form-control btn\" type=\"text\"\n" +
    "                        ng-disabled=\"TmProfileProgramFormController.isReadOnly\"\n" +
    "                        ng-class=\"{'modal-overlay': !TmProfileProgramFormController.allowUpdate()}\"\n" +
    "                        date-range-picker\n" +
    "                        options=\"datePickerAppDead.options\"\n" +
    "                        ng-model=\"datePickerAppDead.date\">\n" +
    "                      {{TmProfileProgramFormController.program.stats.applicationDeadline | mDate}}\n" +
    "                  </span>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div ng-if=\"TmProfileProgramFormController.isBmProgram()\"\n" +
    "                 ng-include src=\"'/scripts/components/profiles/tm/programs/tmProfileProgramsBmStatsView.html'\"></div>\n" +
    "\n" +
    "        </ng-form>\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/components/profiles/tm/programs/tmProfileEditProgramFormView.html',
    "<div class=\"ibox\">\n" +
    "    <div class=\"ibox-title m-t-xl\">\n" +
    "        <h5 ng-show=\"showEdiProgramForm\">{{TmProfileProgramFormController.getEditTitle()}}</h5>\n" +
    "        <h5 ng-show=\"showSubscriptionForm\">{{TmProfileProgramFormController.getSubscriptionTitle()}}</h5>\n" +
    "        <div class=\"ibox-tools\">\n" +
    "            <a ng-click=\"TmProfileProgramFormController.closeEditProgramForm()\">\n" +
    "                <i class=\"fa fa-times\"></i>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"ibox-content\">\n" +
    "        <div ng-show=\"showEdiProgramForm\">\n" +
    "            <form class=\"form-horizontal\" name=\"forms.editProgramForm\" novalidate autocomplete=\"off\">\n" +
    "                <div class=\"alert alert-info\" ng-if=\"TmProfileProgramFormController.allowDowngrade()\">\n" +
    "                    <span>Login as Program to edit Advanced Program</span>\n" +
    "                </div>\n" +
    "                <a class=\"btn btn-default btn-sm btn-block m-b-sm\"\n" +
    "                   ng-show=\"TmProfileProgramFormController.isProgramAdvanced\"\n" +
    "                   ng-click=\"TmProfileProgramFormController.getProgramDetails()\">\n" +
    "                    <i class=\"fa fa-info\"></i>\n" +
    "                    <span>View Program Details</span>\n" +
    "                </a>\n" +
    "                <div class=\"form-group\" ng-if=\"TmProfileProgramFormController.allowUpgrade()\">\n" +
    "                    <div class=\"col-sm-12\">\n" +
    "                        <a class=\"btn btn-warning btn-block\" ng-click=\"TmProfileProgramFormController.handleUpgradeClick()\">\n" +
    "                            <i class=\"fa fa-star\"></i>\n" +
    "                            <span>{{TmProfileProgramFormController.getUpgradeButtonTitle()}}</span>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div ng-include src=\"'/scripts/components/profiles/tm/programs/tmProfileEditProgramFormDetailsView.html'\"></div>\n" +
    "                <div ng-include src=\"'/scripts/components/profiles/tm/programs/tmProfileEditProgramFormStatisticsView.html'\"></div>\n" +
    "                <div ng-show=\"!TmProfileProgramFormController.isProgramAdvanced\" ng-include src=\"'/scripts/components/profiles/tm/programs/tmProfileEditProgramFormCampusView.html'\"></div>\n" +
    "\n" +
    "\n" +
    "                <div class=\"separator\"></div>\n" +
    "\n" +
    "                <div class=\"form-group\" ng-class=\"{'locked': TmProfileProgramFormController.isProgramSubmitDisabled()}\">\n" +
    "                    <div class=\"col-sm-12\">\n" +
    "                        <a class=\"btn btn-primary pull-right\" ng-if=\"TmProfileProgramFormController.allowUpdate()\" ng-click=\"TmProfileProgramFormController.handleProgramEditClick()\">\n" +
    "                            <i class=\"fa fa-check-circle\"></i>\n" +
    "                            <span>{{TmProfileProgramFormController.getEditButtonTitle()}}</span>\n" +
    "                        </a>\n" +
    "\n" +
    "                        <a class=\"btn btn-default pull-right\" ng-click=\"TmProfileProgramFormController.closeEditProgramForm()\">\n" +
    "                            <i class=\"fa fa-ban\"></i>\n" +
    "                            <span>Cancel</span>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"separator\"></div>\n" +
    "\n" +
    "                <div class=\"form-group\" ng-if=\"TmProfileProgramFormController.allowLoginAs()\">\n" +
    "                    <div class=\"col-sm-12\">\n" +
    "                        <a class=\"btn btn-default btn-block\" ng-class=\"{'disabled': !TmProfileProgramFormController.isAllowedToLogin()}\" ng-click=\"TmProfileProgramFormController.isAllowedToLogin() && TmProfileProgramFormController.handleLoginAsClick()\"\n" +
    "                            uib-tooltip='Login has been disabled due to permission. Contact {{TmProfileProgramFormController.tmSupportEmail}}'\n" +
    "                            tooltip-enable=\"!TmProfileProgramFormController.isAllowedToLogin()\" tooltip-placement=\"left\">\n" +
    "                            <i class=\"fa fa-sign-in\"></i>\n" +
    "                            <span>Login as Program</span>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group\" ng-if=\"TmProfileProgramFormController.allowDowngrade()\" ng-class=\"{'locked': TmProfileProgramFormController.downgradeInProgress}\">\n" +
    "                    <div class=\"col-sm-12\">\n" +
    "                        <a class=\"btn btn-danger btn-block\" ng-click=\"TmProfileProgramFormController.handleDowngradeClick()\">\n" +
    "                            <i class=\"fa fa-arrow-circle-o-down\"></i>\n" +
    "                            <span>Downgrade to simple program</span>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group\" ng-if=\"TmProfileProgramFormController.allowDelete()\" ng-class=\"{'locked': TmProfileProgramFormController.deleteInProgress}\">\n" +
    "                    <div class=\"col-sm-12\">\n" +
    "                        <a class=\"btn btn-danger btn-block\" ng-click=\"TmProfileProgramFormController.handleDeleteClick()\">\n" +
    "                            <i class=\"fa fa-arrow-circle-o-down\"></i>\n" +
    "                            <span>{{TmProfileProgramFormController.getDeleteButtonTitle()}}</span>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "            </form>\n" +
    "        </div>\n" +
    "\n" +
    "        <div ng-show=\"showSubscriptionForm\">\n" +
    "            <div ng-include src=\"'/scripts/components/profiles/tm/programs/tmProfileProgramAddSubscriptionView.html'\"></div>\n" +
    "        </div>\n" +
    "\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/components/profiles/tm/programs/tmProfileProgramAddSubscriptionView.html',
    "<form class=\"form-horizontal\" name=\"TmProfileProgramFormController.forms.subscriptionForm\">\n" +
    "    <div class=\"form-group\">\n" +
    "        <div class=\"col-sm-12\">\n" +
    "            <span date-range-picker\n" +
    "                class=\"btn btn-default btn-block\"\n" +
    "                type=\"text\"\n" +
    "                ng-model=\"datePickerTm.date\"\n" +
    "                options=\"datePickerTm.options\">\n" +
    "                {{(TmProfileProgramFormController.subscription.startDate| date:'mediumDate') || \"Start Date\"}} - {{(TmProfileProgramFormController.subscription.endDate| date:'mediumDate') || \"End Date\"}}\n" +
    "            </span>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "        <div class=\"col-sm-12\">\n" +
    "            <a class=\"btn btn-primary pull-right\"\n" +
    "               ng-class=\"{'disabled' : !TmProfileProgramFormController.subscription.startDate}\"\n" +
    "               ng-click=\"TmProfileProgramFormController.handleUpgradeSubmit()\">\n" +
    "                <i class=\"fa fa-check-circle\"></i>\n" +
    "                <span>Save</span>\n" +
    "            </a>\n" +
    "            <a class=\"btn btn-default pull-right\"\n" +
    "               ng-click=\"TmProfileProgramFormController.closeSubscribeProgramForm()\">\n" +
    "               <i class=\"fa fa-ban\"></i>\n" +
    "               <span>Cancel</span>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</form>\n"
  );


  $templateCache.put('/scripts/components/profiles/tm/programs/tmProfileProgramsAddCampusFormView.html',
    "<ng-form name=\"TmProfileProgramFormController.forms.addCampusForm\" novalidate autocomplete=\"off\">\n" +
    "  <div class=\"form-group\" ng-class=\"{'has-errors': !TmProfileProgramFormController.isValidCampusName()}\">\n" +
    "      <label class=\"control-label col-sm-5 col-md-4\">\n" +
    "        <span>Campus Name *</span>\n" +
    "      </label>\n" +
    "      <div class=\"col-sm-7 col-md-8\">\n" +
    "          <input type=\"text\"\n" +
    "            name=\"name\"\n" +
    "            class=\"form-control\"\n" +
    "            ng-required=\"true\"\n" +
    "            ng-model=\"TmProfileProgramFormController.newCampus.name\"\n" +
    "            ng-class=\"{'border-red': !TmProfileProgramFormController.isValidCampusName()}\"\n" +
    "            ng-focus=\"TmProfileProgramFormController.setValid('name', false, true)\"\n" +
    "            focus-delay=\"250\"\n" +
    "            custom-popover\n" +
    "            popover-html=\"Add a {{TmProfileProgramFormController.isEmpty(TmProfileProgramFormController.newCampus.name) ? '' : 'valid' }} campus name\"\n" +
    "            popover-placement=\"left\"\n" +
    "            popover-trigger=\"manual\"\n" +
    "            popover-visibility=\"{{!TmProfileProgramFormController.isValidCampusName()}}\">\n" +
    "      </div>\n" +
    "  </div>\n" +
    "  <div class=\"form-group\" ng-class=\"{'has-errors': !TmProfileProgramFormController.isValidCampusCountry()}\">\n" +
    "      <label class=\"control-label col-sm-5 col-md-4\">\n" +
    "        <span>Country *</span>\n" +
    "      </label>\n" +
    "      <div class=\"col-sm-7 col-md-8\">\n" +
    "          <ui-select\n" +
    "           name=\"country\"\n" +
    "           ng-model=\"TmProfileProgramFormController.newCampus.country\"\n" +
    "           focus-delay=\"250\"\n" +
    "           custom-popover\n" +
    "           popover-html=\"Select an option\"\n" +
    "           popover-placement=\"left\"\n" +
    "           popover-trigger=\"manual\"\n" +
    "           popover-visibility=\"{{!TmProfileProgramFormController.isValidCampusCountry()}}\"\n" +
    "           theme=\"bootstrap\">\n" +
    "            <ui-select-match placeholder=\"Select an option\"><span>{{$select.selected.name}}</span></ui-select-match>\n" +
    "            <ui-select-choices\n" +
    "                position=\"down\"\n" +
    "                repeat=\"option.countryCode as option in TmProfileProgramFormController.countryList | filter: $select.search\">\n" +
    "                <div class=\"test\" ng-bind-html=\"option.name | highlight: $select.search\"></div>\n" +
    "            </ui-select-choices>\n" +
    "            <ui-select-no-choice>\n" +
    "                Not found\n" +
    "            </ui-select-no-choice>\n" +
    "         </ui-select>\n" +
    "      </div>\n" +
    "  </div>\n" +
    "  <div class=\"form-group\" ng-class=\"{'has-errors': !TmProfileProgramFormController.isValidCampusAddressLine1()}\">\n" +
    "      <label class=\"control-label col-sm-5 col-md-4\">\n" +
    "        <span>Address *</span>\n" +
    "      </label>\n" +
    "      <div class=\"col-sm-7 col-md-8\">\n" +
    "          <input type=\"text\"\n" +
    "            class=\"form-control\"\n" +
    "            name=\"addressLine1\"\n" +
    "            ng-model=\"TmProfileProgramFormController.newCampus.addressLine1\"\n" +
    "            ng-required=\"true\"\n" +
    "            ng-class=\"{'border-red': !TmProfileProgramFormController.isValidCampusAddressLine1()}\"\n" +
    "            ng-focus=\"TmProfileProgramFormController.setValid('addressLine1', false, true)\"\n" +
    "            focus-delay=\"250\"\n" +
    "            custom-popover\n" +
    "            popover-html=\"Add an {{TmProfileProgramFormController.isEmpty(TmProfileProgramFormController.newCampus.addressLine1) ? '' : 'valid' }} address\"\n" +
    "            popover-placement=\"left\"\n" +
    "            popover-trigger=\"manual\"\n" +
    "            popover-visibility=\"{{!TmProfileProgramFormController.isValidCampusAddressLine1()}}\">\n" +
    "      </div>\n" +
    "  </div>\n" +
    "  <div class=\"form-group\" ng-class=\"{'has-errors': !TmProfileProgramFormController.isValidSecondAddress()}\">\n" +
    "      <label class=\"control-label col-sm-5 col-md-4\">&nbsp;</label>\n" +
    "      <div class=\"col-sm-7 col-md-8\">\n" +
    "        <input type=\"text\"\n" +
    "          class=\"form-control\" \n" +
    "          ng-model=\"TmProfileProgramFormController.newCampus.addressLine2\"\n" +
    "          custom-popover popover-html=\"Add an valid address\"\n" +
    "          popover-placement=\"left\"\n" +
    "          popover-trigger=\"manual\"\n" +
    "          popover-visibility=\"{{!TmProfileProgramFormController.isValidSecondAddress()}}\">\n" +
    "      </div>\n" +
    "  </div>\n" +
    "  <div class=\"form-group\" ng-class=\"{'has-errors': !TmProfileProgramFormController.isValidCampusCity()}\">\n" +
    "      <label class=\"control-label col-sm-5 col-md-4\">\n" +
    "        <span>Town / City *</span>\n" +
    "      </label>\n" +
    "      <div class=\"col-sm-7 col-md-8\">\n" +
    "          <input type=\"text\"\n" +
    "            class=\"form-control\"\n" +
    "            name=\"city\"\n" +
    "            ng-model=\"TmProfileProgramFormController.newCampus.city\"\n" +
    "            ng-required=\"true\"\n" +
    "            ng-class=\"{'border-red': !TmProfileProgramFormController.isValidCampusCity()}\"\n" +
    "            ng-focus=\"TmProfileProgramFormController.setValid('city', false, true)\"\n" +
    "            focus-delay=\"250\"\n" +
    "            custom-popover\n" +
    "            popover-html=\"Add a {{TmProfileProgramFormController.isEmpty(TmProfileProgramFormController.newCampus.city) ? '' : 'valid' }} town/city\"\n" +
    "            popover-placement=\"left\"\n" +
    "            popover-trigger=\"manual\"\n" +
    "            popover-visibility=\"{{!TmProfileProgramFormController.isValidCampusCity()}}\">\n" +
    "      </div>\n" +
    "  </div>\n" +
    "  <div class=\"form-group\" ng-class=\"{'has-errors': !TmProfileProgramFormController.isValidState()}\">\n" +
    "      <label class=\"control-label col-sm-5 col-md-4\">\n" +
    "        <span>State / Province</span>\n" +
    "      </label>\n" +
    "      <div class=\"col-sm-7 col-md-8\">\n" +
    "        <input type=\"text\" \n" +
    "          class=\"form-control\" \n" +
    "          ng-model=\"TmProfileProgramFormController.newCampus.state\"\n" +
    "          custom-popover popover-html=\"Add a valid state / province\"\n" +
    "          popover-placement=\"left\"\n" +
    "          popover-trigger=\"manual\"\n" +
    "          popover-visibility=\"{{!TmProfileProgramFormController.isValidState()}}\">\n" +
    "      </div>\n" +
    "  </div>\n" +
    "  <div class=\"form-group\" ng-class=\"{'has-errors': !TmProfileProgramFormController.isValidPostcode()}\">\n" +
    "      <label class=\"control-label col-sm-5 col-md-4\">\n" +
    "        <span>Postcode</span>\n" +
    "      </label>\n" +
    "      <div class=\"col-sm-7 col-md-8\">\n" +
    "        <input type=\"text\" \n" +
    "          class=\"form-control\" \n" +
    "          ng-model=\"TmProfileProgramFormController.newCampus.postcode\"\n" +
    "          custom-popover popover-html=\"Add a valid postcode\"\n" +
    "          popover-placement=\"left\"\n" +
    "          popover-trigger=\"manual\"\n" +
    "          popover-visibility=\"{{!TmProfileProgramFormController.isValidPostcode()}}\">\n" +
    "      </div>\n" +
    "  </div>\n" +
    "  <div class=\"form-group\" ng-class=\"{'has-errors': !TmProfileProgramFormController.isValidLatitude()}\">\n" +
    "    <label for=\"addFormCampusLatitude\" class=\"control-label col-sm-5 col-md-4\">\n" +
    "      <span>Latitude</span>\n" +
    "    </label>\n" +
    "    <div class=\"control-label col-sm-7 col-md-8\">\n" +
    "      <input id=\"addFormCampusLatitude\" \n" +
    "        type=\"text\" \n" +
    "        class=\"form-control\" \n" +
    "        ng-model=\"TmProfileProgramFormController.newCampus.latitude\" \n" +
    "        ng-readonly=\"TmProfileProgramFormController.newCampus.autoGenerate\"\n" +
    "        custom-popover popover-html=\"Add a valid latitude\"\n" +
    "        popover-placement=\"left\"\n" +
    "        popover-trigger=\"manual\"\n" +
    "        popover-visibility=\"{{!TmProfileProgramFormController.isValidLatitude()}}\">\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <div class=\"form-group\" ng-class=\"{'has-errors': !TmProfileProgramFormController.isValidLongitude()}\">\n" +
    "    <label for=\"addFormCampusLongitude\" class=\"control-label col-sm-5 col-md-4\">\n" +
    "      <span>Longitude</span>\n" +
    "    </label>\n" +
    "    <div class=\"control-label col-sm-7 col-md-8\">\n" +
    "      <input type=\"text\" \n" +
    "        class=\"form-control\" \n" +
    "        id=\"addFormCampusLongitude\"\n" +
    "        ng-model=\"TmProfileProgramFormController.newCampus.longitude\"\n" +
    "        ng-readonly=\"TmProfileProgramFormController.newCampus.autoGenerate\"\n" +
    "        custom-popover popover-html=\"Add a valid longitude\"\n" +
    "        popover-placement=\"left\"\n" +
    "        popover-trigger=\"manual\"\n" +
    "        popover-visibility=\"{{!TmProfileProgramFormController.isValidLongitude()}}\">\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5 col-md-4\" >Auto Generate</label>\n" +
    "    <div class=\"col-sm-7 col-md-8\">\n" +
    "      <switch ng-model=\"TmProfileProgramFormController.newCampus.autoGenerate\" class=\"green\" ></switch>\n" +
    "      <span>Use the address to auto generate latitude & longitude values.</span>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  \n" +
    "  <div class=\"form-group\">\n" +
    "      <label class=\"control-label col-sm-5 col-md-4\">\n" +
    "        <span>Program Primary Campus</span>\n" +
    "      </label>\n" +
    "      <div class=\"col-sm-7 col-md-8\">\n" +
    "          <switch class=\"green\" ng-model=\"TmProfileProgramFormController.newCampus.primary\"></switch>\n" +
    "          <span class=\"switch-text\">Please only check if it's the main campus</span>\n" +
    "      </div>\n" +
    "  </div>\n" +
    "  <div class=\"form-group\">\n" +
    "      <label class=\"control-label col-sm-5 col-md-4\">\n" +
    "        <span>Display In Frontend</span>\n" +
    "      </label>\n" +
    "      <div class=\"col-sm-7 col-md-8\">\n" +
    "          <switch class=\"green\" ng-model=\"TmProfileProgramFormController.newCampus.displayInFrontEnd\"></switch>\n" +
    "          <span class=\"switch-text\">If selected, campus will be sent to profile. (Latitude and longitude is required)</span>\n" +
    "      </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"form-group\"></div>\n" +
    "\n" +
    "  <div class=\"form-group\" ng-class=\"{'locked': TmProfileProgramFormController.campusSubmitInProgress}\">\n" +
    "      <div class=\"col-sm-12\">\n" +
    "          <a class=\"btn btn-primary pull-right btn-float-fix\" ng-click=\"TmProfileProgramFormController.handleNewCampusSubmit()\">\n" +
    "            <i class=\"fa fa-check-circle\"></i>\n" +
    "            <span>Add Campus to Program</span>\n" +
    "          </a>\n" +
    "          <a class=\"btn btn-default pull-right btn-float-fix\" ng-click=\"TmProfileProgramFormController.handleAddCampusClick()\">\n" +
    "            <i class=\"fa fa-ban\"></i>\n" +
    "            <span>Cancel</span>\n" +
    "          </a>\n" +
    "      </div>\n" +
    "  </div>\n" +
    "</ng-form>\n"
  );


  $templateCache.put('/scripts/components/profiles/tm/programs/tmProfileProgramsBmStatsView.html',
    "<div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5 col-md-5\">\n" +
    "        <span>Delivery Method</span>\n" +
    "    </label>\n" +
    "    <div class=\"col-sm-7 col-md-7\">\n" +
    "        <ui-select\n" +
    "            ng-model=\"TmProfileProgramFormController.program.stats.deliveryMethod\"\n" +
    "            ng-disabled=\"TmProfileProgramFormController.isReadOnly\"\n" +
    "            theme=\"bootstrap\">\n" +
    "            <ui-select-match placeholder=\"Select an option\"><span>{{$select.selected.label}}</span></ui-select-match>\n" +
    "            <ui-select-choices\n" +
    "                position=\"down\"\n" +
    "                repeat=\"option.value as option in TmProfileProgramFormController.deliveryMethodsList | filter: $select.search\">\n" +
    "              <div class=\"test\" ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "            </ui-select-choices>\n" +
    "            <ui-select-no-choice>\n" +
    "                Not found\n" +
    "            </ui-select-no-choice>\n" +
    "        </ui-select>\n" +
    "    </div>\n" +
    "</div>\n" +
    "<div class=\"form-group\" >\n" +
    "    <label class=\"control-label col-sm-5 col-md-5\">\n" +
    "        <span>Foundation Year of Program</span>\n" +
    "    </label>\n" +
    "    <div class=\"col-sm-7 col-md-7\">\n" +
    "        <input type=\"number\"\n" +
    "               class=\"form-control\"\n" +
    "               name=\"foundationYear\"\n" +
    "               ng-model=\"TmProfileProgramFormController.program.stats.foundationYear\"\n" +
    "               ng-disabled=\"TmProfileProgramFormController.isReadOnly\">\n" +
    "    </div>\n" +
    "</div>\n" +
    "<div class=\"form-group\" >\n" +
    "    <label class=\"control-label col-sm-5 col-md-5\">\n" +
    "        <span>Total Tuition Fees (Domestic) (USD)</span>\n" +
    "    </label>\n" +
    "    <div class=\"col-sm-7 col-md-7\">\n" +
    "        <input type=\"number\"\n" +
    "               class=\"form-control\"\n" +
    "               name=\"tuitionFeesDom\"\n" +
    "               ng-model=\"TmProfileProgramFormController.program.stats.tuitionFeesDom\"\n" +
    "               ng-disabled=\"TmProfileProgramFormController.isReadOnly\">\n" +
    "    </div>\n" +
    "</div>\n" +
    "<div class=\"form-group\" >\n" +
    "    <label class=\"control-label col-sm-5 col-md-5\">\n" +
    "        <span>Total Tuition Fees (International) (USD)</span>\n" +
    "    </label>\n" +
    "    <div class=\"col-sm-7 col-md-7\">\n" +
    "        <input type=\"number\"\n" +
    "               class=\"form-control\"\n" +
    "               name=\"tuitionFeesInt\"\n" +
    "               ng-model=\"TmProfileProgramFormController.program.stats.tuitionFeesInt\"\n" +
    "               ng-disabled=\"TmProfileProgramFormController.isReadOnly\">\n" +
    "    </div>\n" +
    "</div>\n" +
    "<div class=\"form-group\">\n" +
    "    <label class=\"control-label col-sm-5 col-md-5\">\n" +
    "        <span>Number of Nationalities in Current Cohort</span>\n" +
    "    </label>\n" +
    "    <div class=\"col-sm-7 col-md-7\">\n" +
    "        <input type=\"number\"\n" +
    "               class=\"form-control\"\n" +
    "               name=\"nationalitiesCount\"\n" +
    "               ng-model=\"TmProfileProgramFormController.program.stats.nationalitiesCount\"\n" +
    "               ng-disabled=\"TmProfileProgramFormController.isReadOnly\">\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tm/programs/tmProfileProgramsEditCampusFormView.html',
    "<ng-form name=\"TmProfileProgramFormController.forms.editCampusForm\" novalidate autocomplete=\"off\">\n" +
    "  <div class=\"form-group\" ng-class=\"{'has-errors': !TmProfileProgramFormController.isValidCampusName()}\">\n" +
    "      <label class=\"control-label col-sm-5 col-md-4\">\n" +
    "        <span>Campus Name *</span>\n" +
    "      </label>\n" +
    "      <div class=\"col-sm-7 col-md-8\">\n" +
    "          <input type=\"text\"\n" +
    "            name=\"name\"\n" +
    "            class=\"form-control\"\n" +
    "            ng-required=\"true\"\n" +
    "            ng-model=\"TmProfileProgramFormController.oldCampus.name\"\n" +
    "            ng-focus=\"TmProfileProgramFormController.setValid('name', false, true)\"\n" +
    "            custom-popover\n" +
    "            popover-html=\"Add a {{TmProfileProgramFormController.isEmpty(TmProfileProgramFormController.oldCampus.name) ? '' : 'valid' }} campus name\"\n" +
    "            popover-placement=\"left\"\n" +
    "            popover-trigger=\"manual\"\n" +
    "            popover-visibility=\"{{!TmProfileProgramFormController.isValidCampusName()}}\">\n" +
    "      </div>\n" +
    "  </div>\n" +
    "  <div class=\"form-group\" ng-class=\"{'has-errors': !TmProfileProgramFormController.isValidCampusCountry()}\">\n" +
    "      <label class=\"control-label col-sm-5 col-md-4\">\n" +
    "        <span>Country *</span>\n" +
    "      </label>\n" +
    "      <div class=\"col-sm-7 col-md-8\">\n" +
    "          <ui-select\n" +
    "           name=\"country\"\n" +
    "           ng-model=\"TmProfileProgramFormController.oldCampus.country\"\n" +
    "           focus-delay=\"250\"\n" +
    "           custom-popover\n" +
    "           popover-html=\"Select an option\"\n" +
    "           popover-placement=\"left\"\n" +
    "           popover-trigger=\"manual\"\n" +
    "           popover-visibility=\"{{!TmProfileProgramFormController.isValidCampusCountry()}}\"\n" +
    "           theme=\"bootstrap\">\n" +
    "            <ui-select-match placeholder=\"Select an option\"><span>{{$select.selected.name}}</span></ui-select-match>\n" +
    "            <ui-select-choices\n" +
    "                position=\"down\"\n" +
    "                repeat=\"option.countryCode as option in TmProfileProgramFormController.countryList | filter: $select.search\">\n" +
    "                <div class=\"test\" ng-bind-html=\"option.name | highlight: $select.search\"></div>\n" +
    "            </ui-select-choices>\n" +
    "            <ui-select-no-choice>\n" +
    "                Not found\n" +
    "            </ui-select-no-choice>\n" +
    "         </ui-select>\n" +
    "      </div>\n" +
    "  </div>\n" +
    "  <div class=\"form-group\" ng-class=\"{'has-errors': !TmProfileProgramFormController.isValidCampusAddressLine1()}\">\n" +
    "      <label class=\"control-label col-sm-5 col-md-4\">\n" +
    "        <span>Address *</span>\n" +
    "      </label>\n" +
    "      <div class=\"col-sm-7 col-md-8\">\n" +
    "          <input type=\"text\"\n" +
    "            name=\"addressLine1\"\n" +
    "            class=\"form-control\"\n" +
    "            ng-required=\"true\"\n" +
    "            ng-model=\"TmProfileProgramFormController.oldCampus.addressLine1\"\n" +
    "            custom-popover popover-html=\"Add an {{TmProfileProgramFormController.isEmpty(TmProfileProgramFormController.oldCampus.addressLine1) ? '' : 'valid' }} address\"\n" +
    "            popover-placement=\"left\"\n" +
    "            popover-trigger=\"manual\"\n" +
    "            popover-visibility=\"{{!TmProfileProgramFormController.isValidCampusAddressLine1()}}\">\n" +
    "      </div>\n" +
    "  </div>\n" +
    "  <div class=\"form-group\" ng-class=\"{'has-errors': !TmProfileProgramFormController.isValidSecondAddress()}\">\n" +
    "      <label class=\"control-label col-sm-5 col-md-4\">&nbsp;</label>\n" +
    "      <div class=\"col-sm-7 col-md-8\">\n" +
    "          <input type=\"text\"\n" +
    "            class=\"form-control\" \n" +
    "            ng-model=\"TmProfileProgramFormController.oldCampus.addressLine2\"\n" +
    "            custom-popover popover-html=\"Add an valid address\"\n" +
    "            popover-placement=\"left\"\n" +
    "            popover-trigger=\"manual\"\n" +
    "            popover-visibility=\"{{!TmProfileProgramFormController.isValidSecondAddress()}}\">\n" +
    "      </div>\n" +
    "  </div>\n" +
    "  <div class=\"form-group\" ng-class=\"{'has-errors': !TmProfileProgramFormController.isValidCampusCity()}\">\n" +
    "      <label class=\"control-label col-sm-5 col-md-4\">\n" +
    "        <span>Town / City *</span>\n" +
    "      </label>\n" +
    "      <div class=\"col-sm-7 col-md-8\">\n" +
    "          <input type=\"text\"\n" +
    "            class=\"form-control\"\n" +
    "            name=\"city\"\n" +
    "            ng-required=\"true\"\n" +
    "            ng-model=\"TmProfileProgramFormController.oldCampus.city\"\n" +
    "            custom-popover popover-html=\"Add a {{TmProfileProgramFormController.isEmpty(TmProfileProgramFormController.oldCampus.city) ? '' : 'valid' }} town/city\"\n" +
    "            popover-placement=\"left\"\n" +
    "            popover-trigger=\"manual\"\n" +
    "            popover-visibility=\"{{!TmProfileProgramFormController.isValidCampusCity()}}\">\n" +
    "      </div>\n" +
    "  </div>\n" +
    "  <div class=\"form-group\" ng-class=\"{'has-errors': !TmProfileProgramFormController.isValidState()}\">\n" +
    "      <label class=\"control-label col-sm-5 col-md-4\">\n" +
    "        <span>State / Province</span>\n" +
    "      </label>\n" +
    "      <div class=\"col-sm-7 col-md-8\">\n" +
    "          <input type=\"text\" \n" +
    "            class=\"form-control\" \n" +
    "            ng-model=\"TmProfileProgramFormController.oldCampus.state\"\n" +
    "            custom-popover popover-html=\"Add a valid state / province\"\n" +
    "            popover-placement=\"left\"\n" +
    "            popover-trigger=\"manual\"\n" +
    "            popover-visibility=\"{{!TmProfileProgramFormController.isValidState()}}\">\n" +
    "      </div>\n" +
    "  </div>\n" +
    "  <div class=\"form-group\" ng-class=\"{'has-errors': !TmProfileProgramFormController.isValidPostcode()}\">\n" +
    "      <label class=\"control-label col-sm-5 col-md-4\">\n" +
    "        <span>Postcode</span>\n" +
    "      </label>\n" +
    "      <div class=\"col-sm-7 col-md-8\">\n" +
    "          <input type=\"text\" \n" +
    "            class=\"form-control\" \n" +
    "            ng-model=\"TmProfileProgramFormController.oldCampus.postcode\"\n" +
    "            custom-popover popover-html=\"Add a valid postcode\"\n" +
    "            popover-placement=\"left\"\n" +
    "            popover-trigger=\"manual\"\n" +
    "            popover-visibility=\"{{!TmProfileProgramFormController.isValidPostcode()}}\">\n" +
    "      </div>\n" +
    "  </div>\n" +
    "  <div class=\"form-group\" ng-class=\"{'has-errors': !TmProfileProgramFormController.isValidLatitude()}\">\n" +
    "    <label for=\"editFormCampusLatitude\" class=\"control-label col-sm-5 col-md-4\"><span>Latitude</span></label>\n" +
    "    <div class=\"col-sm-7 col-md-8\">\n" +
    "      <input id=\"editFormCampusLatitude\" \n" +
    "        type=\"text\" \n" +
    "        class=\"form-control\" \n" +
    "        ng-model=\"TmProfileProgramFormController.oldCampus.latitude\" \n" +
    "        ng-readonly=\"TmProfileProgramFormController.oldCampus.autoGenerate\"\n" +
    "        custom-popover popover-html=\"Add a valid latitude\"\n" +
    "        popover-placement=\"left\"\n" +
    "        popover-trigger=\"manual\"\n" +
    "        popover-visibility=\"{{!TmProfileProgramFormController.isValidLatitude()}}\">\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <div class=\"form-group\" ng-class=\"{'has-errors': !TmProfileProgramFormController.isValidLongitude()}\">\n" +
    "    <label for=\"editFormCampusLongitude\" class=\"control-label col-sm-5 col-md-4\">\n" +
    "        <span>Longitude</span>\n" +
    "    </label>\n" +
    "    <div class=\"col-sm-7 col-md-8\">\n" +
    "      <input type=\"text\"\n" +
    "        class=\"form-control\" \n" +
    "        id=\"editFormCampusLongitude\"\n" +
    "        ng-model=\"TmProfileProgramFormController.oldCampus.longitude\"\n" +
    "        ng-readonly=\"TmProfileProgramFormController.oldCampus.autoGenerate\"\n" +
    "        custom-popover popover-html=\"Add a valid longitude\"\n" +
    "        popover-placement=\"left\"\n" +
    "        popover-trigger=\"manual\"\n" +
    "        popover-visibility=\"{{!TmProfileProgramFormController.isValidLongitude()}}\">\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <div class=\"form-group\">\n" +
    "    <label class=\"full-width\" class=\"control-label col-sm-5 col-md-4\" >Auto Generate</label>\n" +
    "    <div class=\"col-sm-7 col-md-8\">\n" +
    "      <switch ng-model=\"TmProfileProgramFormController.oldCampus.autoGenerate\" class=\"green\" ></switch>\n" +
    "      <span>Use the address to auto generate latitude & longitude values.</span>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <div class=\"form-group\">\n" +
    "      <label class=\"control-label col-sm-5 col-md-4\">\n" +
    "        <span>Program Primary Campus</span>\n" +
    "      </label>\n" +
    "      <div class=\"col-sm-7 col-md-8\">\n" +
    "          <switch class=\"green\" ng-model=\"TmProfileProgramFormController.oldCampus.primary\"></switch>\n" +
    "          <span class=\"switch-text\">Please only check if it's the main campus</span>\n" +
    "      </div>\n" +
    "  </div>\n" +
    "  <div class=\"form-group\">\n" +
    "      <label class=\"control-label col-sm-5 col-md-4\">\n" +
    "        <span>Display In Frontend</span>\n" +
    "      </label>\n" +
    "      <div class=\"col-sm-7 col-md-8\">\n" +
    "          <switch class=\"green\" ng-model=\"TmProfileProgramFormController.oldCampus.displayInFrontEnd\"></switch>\n" +
    "          <span class=\"switch-text\">If selected, campus will be sent to profile. (Latitude and longitude is required)</span>\n" +
    "      </div>\n" +
    "  </div>\n" +
    "  <div class=\"form-group\" ng-class=\"{'locked': TmProfileProgramFormController.campusSubmitInProgress}\">\n" +
    "      <div class=\"col-sm-12\">\n" +
    "          <a class=\"btn btn-primary pull-right\" ng-click=\"TmProfileProgramFormController.handleUpdateCampus()\">\n" +
    "            <i class=\"fa fa-check-circle\"></i>\n" +
    "            <span>Update</span>\n" +
    "          </a>\n" +
    "          <a class=\"btn btn-default pull-right\" ng-click=\"TmProfileProgramFormController.handleCancelCampusClick()\">\n" +
    "            <i class=\"fa fa-ban\"></i>\n" +
    "            <span>Cancel</span>\n" +
    "          </a>\n" +
    "      </div>\n" +
    "  </div>\n" +
    "  <div class=\"separator dashed\"></div>\n" +
    "</ng-form>"
  );


  $templateCache.put('/scripts/components/profiles/tm/programs/tmProfileProgramsView.html',
    "<div ng-controller=\"TmProfileProgramsController as ProgramsController\">\n" +
    "    <div class=\"tab-header\">\n" +
    "        <a class=\"btn btn-primary pull-right\"\n" +
    "           ng-class=\"{'disabled': programsTabSubmitInProgress}\"\n" +
    "           ng-click=\"ProgramsController.handleAddProgramClick()\">\n" +
    "            <i class=\"fa fa-plus\"></i>\n" +
    "            <span>{{ProgramsController.isClient() ? 'Request to add Program' : 'Add Program'}}</span>\n" +
    "        </a>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"tab-body\">\n" +
    "        <div id=\"programsTable\">\n" +
    "            <div wave-spinner class=\"wave-spinner\" ng-show=\"ProgramsController.isDatagridReloading || !ProgramsController.isDatagridRendered\"></div>\n" +
    "\n" +
    "            <div ng-if=\"!ProgramsController.isDatagridReloading && gridOptions\">\n" +
    "                <ui-grid-info ng-if=\"!showProgramEditForm && !showProgramAddForm\"></ui-grid-info>\n" +
    "                <div class=\"grid\"\n" +
    "                     ui-grid=\"gridOptions\"\n" +
    "                     ui-grid-selection\n" +
    "                     ui-grid-resize-columns\n" +
    "                     ui-grid-auto-resize>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tm/publish/tmProfilePublishHistoryView.html',
    "<div class=\"ibox publish-logs\" ng-controller=\"TmProfilePublishLogsController as PublishLogsController\">\n" +
    "    <div class=\"ibox-title m-t-xl\">\n" +
    "        <h5>Publish Logs</h5>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"ibox-content\">\n" +
    "        <div wave-spinner ng-show=\"fetchingPublishLog()\"></div>\n" +
    "        <div ng-show=\"!fetchingPublishLog()\" ng-repeat=\"logs in publishLogs\">\n" +
    "            <div ng-repeat=\"logsPerDay in logs\">\n" +
    "                <div class=\"panel panel-default\">\n" +
    "                    <div class=\"panel-heading\">\n" +
    "                        <span>{{logsPerDay.date | date:'mediumDate'}}</span>\n" +
    "                    </div>\n" +
    "                    <div class=\"panel-body\">\n" +
    "                        <ul class=\"list-unstyled\">\n" +
    "                            <li ng-repeat=\"log in logsPerDay.logs\">\n" +
    "                                <span class=\"status\">\n" +
    "                                    <span class=\"icon text-success\" ng-if=\"log.status === 'success'\">\n" +
    "                                        <i class=\"fa fa-check\"></i>\n" +
    "                                    </span>\n" +
    "                                    <span class=\"icon text-danger\" ng-if=\"log.status === 'failure'\">\n" +
    "                                        <i class=\"fa fa-exclamation\"></i>\n" +
    "                                    </span>\n" +
    "                                    <span class=\"icon text-info\" ng-if=\"log.status === 'progress'\">\n" +
    "                                        <i class=\"fa fa-spinner fa-spin\"></i>\n" +
    "                                    </span>\n" +
    "                                    <span class=\"icon text-warning\" ng-if=\"log.status === 'pending'\">\n" +
    "                                        <i class=\"fa fa-clock-o\"></i>\n" +
    "                                    </span>\n" +
    "                                </span>\n" +
    "                                \n" +
    "                                <span>{{log.status === 'failure' ? 'Failed' : 'Published'}}:</span>\n" +
    "                                <span class=\"bold\">{{log.createdByFullName}}</span>\n" +
    "                                <span class=\"time\">{{log.createdAt | date:'shortTime'}}</span>\n" +
    "                                \n" +
    "                                <span class=\"program\">\n" +
    "                                    <span class=\"icon round bg-primary\" ng-if=\"log.type === 'tm'\">\n" +
    "                                        <i class=\"fa fa-book\"></i>\n" +
    "                                    </span>\n" +
    "                                </span>\n" +
    "                            </li>\n" +
    "                        </ul>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div ng-show=\"!publishLogs.results.length\">No publish history</div>\n" +
    "        <div class=\"row\" ng-show=\"PublishLogsController.isMoreLogsAvailable()\">\n" +
    "            <div wave-spinner class=\"text-right\" ng-show=\"PublishLogsController.isLoadMoreHistoryLogsInProgress\"></div>\n" +
    "            <div class=\" col-lg-8 col-lg-offset-2\">\n" +
    "                <a class=\"btn btn-primary btn-block\" ng-click=\"PublishLogsController.handleLoadMoreHistoryLogs()\" ng-disabled=\"PublishLogsController.isLoadMoreHistoryLogsInProgress\">\n" +
    "                   Load more\n" +
    "                </a>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tm/publish/tmProfilePublishView.html',
    "<div ng-controller=\"TmProfilePublishController as PublishController\">\n" +
    "    <div class=\"alert alert-info\">\n" +
    "        <p>\n" +
    "            <i class=\"fa fa-info-circle\"></i>\n" +
    "            <span>Please be aware that published changes will not appear straight away and could take up to 6 hours to be visible on the frontend website.</span>\n" +
    "        </p>\n" +
    "        <p>\n" +
    "            <i class=\"fa fa-info-circle\"></i>\n" +
    "            <span>Whilst a publish is occurring, you may navigate away from this page and it will still progress in the background.</span>\n" +
    "        </p>\n" +
    "    </div>\n" +
    "    <div id=\"publishContainer\" class=\"tab-body tm\">\n" +
    "        <div class=\"row\">\n" +
    "            <div class=\"col-lg-4 meter\" ng-repeat=\"section in sections\">\n" +
    "                <div class=\"panel panel-default\">\n" +
    "                    <div class=\"panel-heading\">\n" +
    "                        <h5 class=\"pull-left\">\n" +
    "                            <span class=\"icon round bg-primary\" ng-if=\"isTm(section.type)\">\n" +
    "                                <i class=\"fa fa-book\"></i>\n" +
    "                            </span>\n" +
    "                            <span>{{section.name}}</span>\n" +
    "                        </h5>\n" +
    "\n" +
    "                        <a class=\"btn btn-primary btn-xs pull-right m-b-xs\"\n" +
    "                           ng-class=\"{'disabled': section.publishDisabled || PublishController.isProfileEnabled()}\"\n" +
    "                           ng-click=\"handlePublish(section, section.publishProgramsBasic, section.publishProgramsAdvanced)\">\n" +
    "                            <i class=\"fa fa-cloud-upload\"></i>\n" +
    "                            <span>Publish</span>\n" +
    "                        </a>\n" +
    "                        <div class=\"clearfix\" ng-if=\"institutionHasAdvancedPrograms() && tmIsAdvanced\">\n" +
    "                            <input ng-model=\"section.publishProgramsAdvanced\" i-checkbox type=\"checkbox\">\n" +
    "                            <span>Include all Advanced Programs</span>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "\n" +
    "                    <div class=\"panel-body\">\n" +
    "                        <div class=\"clearfix m-b-md\">\n" +
    "                            <a class=\"btn btn-default btn-sm btn-block\"\n" +
    "                                target=\"_blank\"\n" +
    "                                ng-href=\"{{section.url}}\"\n" +
    "                                ng-class=\"{'disabled': section.viewDisabled}\">\n" +
    "                                <i class=\"fa fa-eye\"></i>\n" +
    "                                <span>View webpage</span>\n" +
    "                            </a>\n" +
    "                            <a class=\"btn btn-default btn-sm btn-block\"\n" +
    "                                ng-if=\"PublishController.devMode\"\n" +
    "                                target=\"_blank\"\n" +
    "                                ng-href=\"{{section.feedPreviewUrl}}\"\n" +
    "                                ng-class=\"{'disabled': section.viewDisabled}\">\n" +
    "                                <i class=\"fa fa-eye\"></i>\n" +
    "                                <span>Preview feed</span>\n" +
    "                            </a>\n" +
    "                        </div>\n" +
    "                        <i ng-if=\"section.statusMessage\" class=\"fa fa-info-circle\"\n" +
    "                            uib-tooltip=\"{{section.statusMessage}}\"\n" +
    "                            tooltip-placement=\"top\">\n" +
    "                        </i>\n" +
    "                        <div class=\"response\" ng-if=\"section.statusMessage\">\n" +
    "                            <p><strong>Reason for Failed Publish:</strong></p>\n" +
    "                            <span class=\"truncate\"><strong>{{section.statusMessage}}</strong></span>\n" +
    "                        </div>\n" +
    "                        <div class=\"p-c\" progress-circle=\"section.status\"></div>\n" +
    "                        <div class=\"programs\" ng-if=\"displayProgramStatus(section)\">\n" +
    "                            <span ng-show=\"section.status === 'failure' || (section.status === 'success' && section.advancedPrograms.failed > 0)\">\n" +
    "                                {{section.advancedPrograms.failed}} out of {{section.advancedPrograms.total}} advanced program/s failed to publish.\n" +
    "                            </span>\n" +
    "                            <span ng-show=\"section.status === 'success' && section.advancedPrograms.failed === 0 && section.advancedPrograms.total > 0\">\n" +
    "                                {{section.advancedPrograms.total}} out of {{section.advancedPrograms.total}} advanced program/s publish successfully.\n" +
    "                            </span>\n" +
    "                            <a href=\"javascript:void(0);\"\n" +
    "                                ng-show=\"section.advancedPrograms.failed\"\n" +
    "                                ng-click=\"viewPrograms('md', section.type, section.advancedPrograms.data)\"\n" +
    "                                title=\"See full list of {{section.type | uppercase}} programs that failed publishing\">See full list.</a>\n" +
    "                            <br>\n" +
    "                            <span ng-show=\"section.status === 'failure' || (section.status === 'success' && section.simplePrograms.failed > 0)\">\n" +
    "                                {{section.simplePrograms.failed}} out of {{section.simplePrograms.total}} simple program/s failed to publish.\n" +
    "                            </span>\n" +
    "                            <span ng-show=\"section.status === 'success' && section.simplePrograms.failed === 0 && section.simplePrograms.total > 0\">\n" +
    "                                {{section.simplePrograms.total}} out of {{section.simplePrograms.total}} simple program/s publish successfully.\n" +
    "                            </span>\n" +
    "                            <a href=\"javascript:void(0);\"\n" +
    "                                ng-show=\"section.simplePrograms.failed\"\n" +
    "                                ng-click=\"viewPrograms('md', section.type, section.simplePrograms.data)\"\n" +
    "                                title=\"See full list of {{section.type | uppercase}} programs that failed publishing\">See full list.</a>\n" +
    "                            \n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tm/publish/tmProfileResubscribeView.html',
    "<div class=\"resubscribe\" ng-controller=\"TmProfileResubscribeController as TmResubscribeController\">\n" +
    "    <div class=\"ibox m-t-xl\" ng-show=\"TmResubscribeController.isAdvanced\">\n" +
    "        <div class=\"panel panel-default\">\n" +
    "            <div class=\"panel-body\">\n" +
    "                <div class=\"subscribe\">\n" +
    "                    <div class=\"pull-left\">\n" +
    "                        <p>Your subscription expires:</p>\n" +
    "                        <span class=\"bold\">{{TmResubscribeController.expiresDate | date:'dd MMMM yyyy'}}</span>\n" +
    "                    </div>\n" +
    "                    <button class=\"btn btn-warning btn-lg pull-right\"\n" +
    "                            ng-click=\"TmResubscribeController.resubscribeClick()\"\n" +
    "                            ng-show=\"TmResubscribeController.showResubscribeButton()\">\n" +
    "                        <i class=\"fa fa-envelope-o\"></i>\n" +
    "                        <span>Resubscribe</span>\n" +
    "                    </button>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    \n" +
    "    <div class=\"ibox m-t-xl\" ng-show=\"TmResubscribeController.displayResubscribeForm\">\n" +
    "        <div class=\"ibox-title\">\n" +
    "            <h5>Resubscribe to Advance Profile</h5>\n" +
    "        </div>\n" +
    "        <div class=\"ibox-content\">\n" +
    "            <form class=\"form-horizontal preview\" name=\"forms.resubscribeForm\" novalidate>\n" +
    "                <div class=\"form-group\">\n" +
    "                    <label class=\"control-label col-sm-5 col-md-4\">To:</label>\n" +
    "                    <div class=\"col-sm-7 col-md-8\">\n" +
    "                        <span class=\"text-label\">{{TmResubscribeController.formData.to}}</span>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                \n" +
    "                <div class=\"form-group\">\n" +
    "                    <label class=\"control-label col-sm-5 col-md-4\">Subject:</label>\n" +
    "                    <div class=\"col-sm-7 col-md-8\">\n" +
    "                        <span class=\"text-label\">{{TmResubscribeController.formData.subject}}</span>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group\">\n" +
    "                    <label class=\"control-label col-sm-5 col-md-4\">Name:</label>\n" +
    "                    <div class=\"col-sm-7 col-md-8\">\n" +
    "                        <span class=\"text-label\">{{TmResubscribeController.formData.name}}</span>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group\">\n" +
    "                    <label class=\"control-label col-sm-5 col-md-4\">Email:</label>\n" +
    "                    <div class=\"col-sm-7 col-md-8\">\n" +
    "                        <span class=\"text-label\">{{TmResubscribeController.formData.email}}</span>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group\">\n" +
    "                    <label class=\"control-label col-sm-5 col-md-4\">Comments:</label>\n" +
    "                    <div class=\"col-sm-7 col-md-8\">\n" +
    "                        <div class=\"textarea\"><textarea class=\"form-control full-width\" ng-model=\"TmResubscribeController.formData.comments\" name=\"comments\" ng-trim=\"true\" rows=\"5\"></textarea></div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                \n" +
    "                <div class=\"form-group\">\n" +
    "                    <div class=\"col-sm-12\">\n" +
    "                        <a class=\"btn btn-primary pull-right\" ng-class=\"{'disabled': TmResubscribeController.inProgress}\" ng-click=\"TmResubscribeController.submitClick()\">\n" +
    "                           <i class=\"fa fa-check-circle\"></i>\n" +
    "                           <span>Submit</span>\n" +
    "                        </a>\n" +
    "                        <a class=\"btn btn-default pull-right\" ng-click=\"TmResubscribeController.cancelClick()\">\n" +
    "                            <i class=\"fa fa-ban\"></i>\n" +
    "                            <span>Cancel</span>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </form>\n" +
    "        </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/components/profiles/tm/tmProfileDeleteFormView.html',
    "<div class=\"ibox-title m-t-xl\">\n" +
    "    <h5>Request to Delete Basic Program</h5>\n" +
    "    <div class=\"ibox-tools\">\n" +
    "        <a class=\"close-link\" ng-click=\"TmProfileController.handleCloseDeleteForm()\">\n" +
    "            <i class=\"fa fa-times\"></i>\n" +
    "        </a>\n" +
    "    </div>\n" +
    "</div>\n" +
    "<div class=\"ibox\">\n" +
    "    <div class=\"ibox-content\">\n" +
    "        <form class=\"form-horizontal preview\" name=\"forms.upgradeProfile\" novalidate>\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">To</label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <span class=\"text-label\">{{upgradeEmailsTo}}</span>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            \n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">Subject</label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <span class=\"text-label\">I would like program <strong>{{deleteRequest.program.name}}</strong> to be deleted</span>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            \n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">Name</label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <span class=\"text-label\">{{deleteRequest.name}}</span>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            \n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">Email</label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <span class=\"text-label\">{{deleteRequest.email}}</span>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            \n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">Comments</label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <div class=\"textarea\"><textarea class=\"form-control no-resize\" rows=\"7\" cols=\"50\" placeholder=\"Please add comments supporting your request\" ng-model=\"deleteRequest.comments\"></textarea></div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "                <div class=\"col-sm-12\">\n" +
    "                    <a class=\"btn btn-primary pull-right\"\n" +
    "                       ng-class=\"{'disabled': TmProfileController.deleteRequestInProgress}\"\n" +
    "                       ng-click=\"TmProfileController.handleSendDeleteRequestClick()\">\n" +
    "                       <i class=\"fa fa-check-circle\"></i>\n" +
    "                       <span>Send</span>\n" +
    "                    </a>\n" +
    "                    <a class=\"btn btn-default pull-right\" ng-click=\"TmProfileController.handleCloseDeleteForm()\">\n" +
    "                        <i class=\"fa fa-ban\"></i>\n" +
    "                        <span>Close</span>\n" +
    "                    </a>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </form>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tm/tmProfileProgramUpgradeFormView.html',
    "<div class=\"ibox-title m-t-xl\">\n" +
    "    <h5>Upgrade to Advanced Program</h5>\n" +
    "    <div class=\"ibox-tools\">\n" +
    "        <a class=\"close-link\" ng-click=\"TmProfileController.toggleUpgradeProgramForm()\">\n" +
    "            <i class=\"fa fa-times\"></i>\n" +
    "        </a>\n" +
    "    </div>\n" +
    "</div>\n" +
    "<div class=\"ibox\">\n" +
    "    <div class=\"ibox-content\">\n" +
    "        <form class=\"form-horizontal preview\" name=\"forms.upgradeProgramProfile\" novalidate>\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">To</label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <span class=\"text-label\">{{upgradeEmailsTo}}</span>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            \n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">Subject</label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <span class=\"text-label\">I would like more information about Upgrading to an Advanced Program</span>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            \n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">Name</label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <span class=\"text-label\">{{upgradeRequest.name}}</span>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            \n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">Email</label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <span class=\"text-label\">{{upgradeRequest.email}}</span>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            \n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">Comments</label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <div class=\"textarea\"><textarea class=\"form-control no-resize\" rows=\"7\" cols=\"50\" placeholder=\"Comments...\" ng-model=\"upgradeRequest.comments\"></textarea></div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            \n" +
    "            <div class=\"form-group\">\n" +
    "                <div class=\"col-sm-12\">\n" +
    "                    <a class=\"btn btn-primary pull-right\"\n" +
    "                       ng-class=\"{'disabled': TmProfileController.upgradeInProgress}\"\n" +
    "                       ng-click=\"TmProfileController.handleProgramUpgradeRequestClick()\">\n" +
    "                       <i class=\"fa fa-check-circle\"></i>\n" +
    "                       <span>Send</span>\n" +
    "                    </a>\n" +
    "                    <a class=\"btn btn-default pull-right\" ng-click=\"TmProfileController.toggleUpgradeProgramForm()\">\n" +
    "                        <i class=\"fa fa-ban\"></i>\n" +
    "                        <span>Close</span>\n" +
    "                    </a>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </form>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tm/tmProfileView.html',
    "<div id=\"profile-page\" class=\"wrapper wrapper-content animated fadeInRight page-profiles-tm\" ng-controller=\"TmProfileController as TmProfileController\">\n" +
    "    <div class=\"row\">\n" +
    "        <div ng-class=\"TmProfileController.isRightSidePanelActive() ? 'col-sm-8' : 'col-sm-12'\">\n" +
    "            <div class=\"tabs-container\" ng-class=\"{'modal-overlay': TmProfileController.loadInProgress()}\">\n" +
    "                <uib-tabset active=\"activeTab\">\n" +
    "                    <uib-tab index=\"0\" heading=\"Overview\" ui-sref=\"clients.profiles.tm.overview\">\n" +
    "                        <div class=\"panel-body overview\">\n" +
    "                            <div ng-include src=\"'/scripts/components/profiles/tm/overview/tmProfileOverviewView.html'\"></div>\n" +
    "                        </div>\n" +
    "                    </uib-tab>\n" +
    "                    <uib-tab index=\"1\" heading=\"Programs\" ui-sref=\"clients.profiles.tm.programs\" ng-if=\"!TmProfileController.showProgramStasTab\">\n" +
    "                        <div class=\"panel-body programs\">\n" +
    "                            <div ng-include src=\"'/scripts/components/profiles/tm/programs/tmProfileProgramsView.html'\"></div>\n" +
    "                        </div>\n" +
    "                    </uib-tab>\n" +
    "                    <uib-tab index=\"4\" heading=\"Program Statistics\" ui-sref=\"clients.profiles.tm.program-stats\" ng-if=\"TmProfileController.showProgramStasTab\">\n" +
    "                        <div class=\"panel-body program-stats\">\n" +
    "                            <div ng-include src=\"'/scripts/components/profiles/tm/program-stats/tmProfileProgramStatsView.html'\"></div>\n" +
    "                        </div>\n" +
    "                    </uib-tab>\n" +
    "                    <uib-tab index=\"2\" heading=\"Media\" ui-sref=\"clients.profiles.tm.media\">\n" +
    "                        <div class=\"panel-body media\">\n" +
    "                            <div ng-include src=\"'/scripts/components/profiles/tm/media/tmProfileMediaView.html'\" id=\"mediaTab\"></div>\n" +
    "                        </div>\n" +
    "                    </uib-tab>\n" +
    "                    <uib-tab index=\"3\" heading=\"Publish\" ui-sref=\"clients.profiles.tm.publish\">\n" +
    "                        <div class=\"panel-body publish\">\n" +
    "                            <div ng-include src=\"'/scripts/components/profiles/tm/publish/tmProfilePublishView.html'\"></div>\n" +
    "                        </div>\n" +
    "                    </uib-tab>\n" +
    "                </uib-tabset>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div ng-show=\"showUpgradeForm\" class=\"col-sm-4 right-side-container\">\n" +
    "            <profile-upgrade-form toggle-upgrade-form=\"TmProfileController.toggleUpgradeForm()\"></profile-upgrade-form>\n" +
    "        </div>\n" +
    "        <div ng-show=\"showUpgradeProgramRequest\" class=\"col-sm-4 right-side-container\">\n" +
    "            <div ng-include src=\"'/scripts/components/profiles/tm/tmProfileProgramUpgradeFormView.html'\"></div>\n" +
    "        </div>\n" +
    "        <div ng-show=\"showDeleteForm\" class=\"col-sm-4 right-side-container\">\n" +
    "            <div ng-include src=\"'/scripts/components/profiles/tm/tmProfileDeleteFormView.html'\"></div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div ng-show=\"TmProfileController.showOverviewHistory\" class=\"col-sm-4 right-side-container\">\n" +
    "            <div ng-include src=\"'/scripts/components/profiles/tm/overview/overview/tmProfileOverviewHistoryLogView.html'\"></div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div ng-show=\"TmProfileController.showFaqHistory\" class=\"col-sm-4 right-side-container\">\n" +
    "            <div ng-include src=\"'/scripts/components/profiles/tm/overview/faq/tmProfileOverviewFaqHistoryLogView.html'\"></div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div ng-show=\"showProgramEditForm || showProgramAddForm\"\n" +
    "             fixed-element-while-scrolling=\"#programsTable\"\n" +
    "             class=\"col-sm-4 right-side-container\"\n" +
    "             ng-controller=\"TmProfileProgramFormController as TmProfileProgramFormController\">\n" +
    "            <div class=\"nested-ibox\" ng-show=\"showProgramAddForm\" ng-include src=\"'/scripts/components/profiles/tm/programs/tmProfileAddProgramFormView.html'\"></div>\n" +
    "            <div class=\"nested-ibox\" ng-show=\"showProgramEditForm\" ng-include src=\"'/scripts/components/profiles/tm/programs/tmProfileEditProgramFormView.html'\"></div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div ng-show=\"isMediaTabActive()\" class=\"col-sm-4\" ng-class=\"tmIsAdvanced ? '': 'modal-overlay-35'\" fixed-element-while-scrolling=\"#mediaTab\">\n" +
    "            <div id=\"mediaSidebar\" ng-include src=\"'/scripts/components/profiles/tm/media/tmProfileMediaLinkManagerView.html'\"></div>\n" +
    "        </div>\n" +
    "        <div ng-show=\"isPublishTabActive()\" class=\"col-sm-4\">\n" +
    "            <div ng-include src=\"'/scripts/components/profiles/tm/publish/tmProfileResubscribeView.html'\"></div>\n" +
    "            <div ng-include src=\"'/scripts/components/profiles/tm/publish/tmProfilePublishHistoryView.html'\"></div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tu/departments/datagrid/createdAtFilterHeaderTemplate.html',
    "<div class=\"ui-grid-filter-container {{'ui-grid-filter-input-' + $index}}\" ng-repeat=\"colFilter in col.filters\" ng-if=\"colFilter.visible\" >\n" +
    "    <input type=\"text\" class=\"ui-grid-filter-input date-picker\"\n" +
    "           ng-model=\"grid.appScope.filters.createdAt\"\n" +
    "           date-range-picker=\"\"\n" +
    "           date-range-fixer\n" +
    "           readonly\n" +
    "           options=\"{eventHandlers: {'apply.daterangepicker': grid.appScope.handleCreatedAtDateRange}, opens: 'left'}\" />\n" +
    "    \n" +
    "    <div class=\"ui-grid-filter-button\" ng-click=\"removeFilter(colFilter, $index);grid.appScope.filters.createdAt={}\">\n" +
    "            <i class=\"ui-grid-icon-cancel\"  ng-show=\"colFilter.term !== undefined && colFilter.term != null && colFilter.term != ''\"></i>\n" +
    "            <span class=\"ui-grid-icon-date\"  ng-show=\"colFilter.term == undefined || colFilter.term == null || colFilter.term == ''\"></span>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tu/departments/datagrid/createdAtFilterHeaderTemplateWithDropDown.html',
    "<div class=\"ui-grid-filter-container {{'ui-grid-filter-input-' + $index}}\" ng-repeat=\"colFilter in col.filters\" ng-if=\"colFilter.visible\">\n" +
    "    <input type=\"text\" class=\"ui-grid-filter-input date-picker ui-grid-filter-input-{{$index}}\"\n" +
    "           ng-model=\"grid.appScope.filters.createdAt\"\n" +
    "           date-range-picker=\"\"\n" +
    "           date-range-fixer\n" +
    "           readonly\n" +
    "           options=\"{eventHandlers: {'apply.daterangepicker': grid.appScope.handleCreatedAtDateRange}, opens: 'left',showDropdowns: true}\" />\n" +
    "          \n" +
    "    <div class=\"ui-grid-filter-button\" ng-click=\"removeFilter(colFilter, $index);grid.appScope.filters.createdAt={}\" >\n" +
    "            <i class=\"ui-grid-icon-cancel\"  ng-show=\"colFilter.term !== undefined && colFilter.term != null && colFilter.term != ''\"></i>\n" +
    "            <span class=\"ui-grid-icon-date\"  ng-show=\"colFilter.term == undefined || colFilter.term == null || colFilter.term == ''\"></span>\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n"
  );


  $templateCache.put('/scripts/components/profiles/tu/departments/datagrid/deleteCellTemplate.html',
    "<div class=\"ui-grid-cell-contents no-select text-center\" ng-click=\"$event.stopPropagation()\">\n" +
    "    <div class=\"inline\" ng-if=\"row.entity.typeId === 3\">\n" +
    "        <span custom-popover\n" +
    "              popover-html=\"{{grid.appScope.customCheckboxMessage()}}\"\n" +
    "              popover-placement=\"right\"\n" +
    "              popover-container=\"body\"\n" +
    "              popover-trigger=\"hover\">\n" +
    "            <input\n" +
    "                disabled=\"disabled\"\n" +
    "                ng-model=\"grid.appScope.departmentsToDelete[row.entity.id]\"\n" +
    "                i-checkbox\n" +
    "                type=\"checkbox\">\n" +
    "        </span>\n" +
    "    </div>\n" +
    "    <div class=\"inline\" ng-if=\"row.entity.typeId === 5 && !row.entity.allowDelete\">\n" +
    "        <span custom-popover\n" +
    "              popover-html=\"Department/s with program/s assigned to it can't be deleted\"\n" +
    "              popover-placement=\"right\"\n" +
    "              popover-container=\"body\"\n" +
    "              popover-trigger=\"hover\">\n" +
    "            <input\n" +
    "                    disabled=\"disabled\"\n" +
    "                    ng-model=\"grid.appScope.departmentsToDelete[row.entity.id]\"\n" +
    "                    i-checkbox\n" +
    "                    type=\"checkbox\">\n" +
    "        </span>\n" +
    "    </div>\n" +
    "    <div class=\"inline\" ng-if=\"row.entity.typeId !== 3 && !(row.entity.typeId === 5 && !row.entity.allowDelete)\">\n" +
    "        <input\n" +
    "            ng-model=\"grid.appScope.departmentsToDelete[row.entity.id]\"\n" +
    "            i-checkbox\n" +
    "            type=\"checkbox\">\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tu/departments/datagrid/deleteHeaderCellTemplate.html',
    "<button type=\"button\"\n" +
    "        ng-disabled=\"grid.appScope.isDeleteButtonDisabled()\"\n" +
    "        class=\"btn btn-danger btn-sm\"\n" +
    "        ng-class=\"{'disabled': grid.appScope.isDeleteButtonDisabled()}\"\n" +
    "        ng-click=\"grid.appScope.handleDeleteClick()\">\n" +
    "    <span class=\"glyphicon glyphicon-trash\"></span><!--  {{col.displayName}} -->\n" +
    "</button>\n"
  );


  $templateCache.put('/scripts/components/profiles/tu/departments/datagrid/modifiedAtFilterHeaderTemplate.html',
    "<div class=\"ui-grid-filter-container {{'ui-grid-filter-input-' + $index}}\" ng-repeat=\"colFilter in col.filters\" ng-if=\"colFilter.visible\" >\n" +
    "    <input type=\"text\" class=\"ui-grid-filter-input date-picker\"\n" +
    "           ng-model=\"grid.appScope.filters.modifiedAt\"\n" +
    "           date-range-picker=\"\"\n" +
    "           date-range-fixer\n" +
    "           readonly\n" +
    "           options=\"{eventHandlers: {'apply.daterangepicker': grid.appScope.handleModifiedAtDateRange}, opens: 'left'}\" />\n" +
    "    \n" +
    "    <div class=\"ui-grid-filter-button\" ng-click=\"removeFilter(colFilter, $index);grid.appScope.filters.modifiedAt={}\">\n" +
    "            <i class=\"ui-grid-icon-cancel\"  ng-show=\"colFilter.term !== undefined && colFilter.term != null && colFilter.term != ''\"></i>\n" +
    "            <span class=\"ui-grid-icon-date\"  ng-show=\"colFilter.term == undefined || colFilter.term == null || colFilter.term == ''\"></span>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tu/departments/datagrid/rowTemplate.html',
    "<div grid=\"grid\" class=\"ui-grid-draggable-row\" draggable=\"true\">\n" +
    "    <div class=\"ui-grid-cell pointer\"\n" +
    "        ng-repeat=\"(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name\"\n" +
    "        ng-class=\"{ 'ui-grid-row-header-cell': col.isRowHeader, 'active': row.entity.id == grid.appScope.selectedDepartmentId }\"\n" +
    "        role=\"{{col.isRowHeader ? 'rowheader' : 'gridcell'}}\"\n" +
    "        ui-grid-cell>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tu/departments/modalDowngradeDepartmentView.html',
    "<div class=\"modal-header\">\n" +
    "    <h3>{{modalOptions.headerText}}</h3>\n" +
    "</div>\n" +
    "<div class=\"modal-body\">\n" +
    "    <p ng-show=\"!modalOptions.subscriptions\">\n" +
    "        Client department has no active subscription. Press Downgrade Now to downgrade to simple department.\n" +
    "    </p>\n" +
    "    <div ng-show=\"modalOptions.subscriptions\">\n" +
    "        <p>This client department currently has an active subscription. The subscription will need to be turned off in order to downgrade and delete the profile. If you wish to downgrade the client department and delete the profile in Drupal (front end) please select accordingly and press update to continue.</p>\n" +
    "        <hr>\n" +
    "        <p>Enabled:\n" +
    "            <code ng-if=\"modalOptions.department.enabled.pg\">PG</code>\n" +
    "            <code ng-if=\"modalOptions.department.enabled.ug\">UG</code>\n" +
    "            <code ng-if=\"modalOptions.department.enabled.tm\">MBA</code>\n" +
    "        </p>\n" +
    "        <p ng-if=\"modalOptions.department.subscriptions.tu.advanced\">\n" +
    "            Advanced TU : <strong>From {{modalOptions.department.subscriptions.tu.startDate | date:'short'}}</strong> to <strong>{{modalOptions.department.subscriptions.tu.endDate | date:'short'}}</strong>\n" +
    "        </p>\n" +
    "        <p ng-if=\"modalOptions.department.subscriptions.tm.advanced\">\n" +
    "            Advanced TM : <strong>From {{modalOptions.department.subscriptions.tm.startDate | date:'short'}}</strong> to <strong>{{modalOptions.department.subscriptions.tm.endDate| date:'short'}}</strong>\n" +
    "        </p>\n" +
    "        <switch ng-model=\"modalOptions.downgradeAndDelete\" class=\"green\"></switch>\n" +
    "        Downgrade the client department and delete the profile in Drupal (front end)\n" +
    "    </div>\n" +
    "</div>\n" +
    "<div class=\"modal-footer\">\n" +
    "    <button type=\"button\" class=\"btn btn-default\" ng-click=\"modalOptions.close()\">\n" +
    "        {{modalOptions.closeButtonText}}\n" +
    "    </button>\n" +
    "    <button class=\"btn btn-primary\" ng-click=\"modalOptions.completeDowngradeClick(modalOptions.downgradeAndDelete)\">\n" +
    "        {{modalOptions.subscriptions ? (modalOptions.downgradeAndDelete ? 'Downgrade & Delete' : 'Downgrade in Backend') : 'Downgrade Now'}}\n" +
    "    </button>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tu/departments/modalUpgradeDepartmentView.html',
    "<div class=\"modal-header\">\n" +
    "    <button type=\"button\" class=\"close\" data-ng-click=\"modalOptions.close()\">&times;</button>\n" +
    "    <h3>{{modalOptions.headerText}}</h3>\n" +
    "</div>\n" +
    "<div class=\"modal-body\">\n" +
    "    <p>\n" +
    "        To complete this department upgrade you must enter subscription dates, country, etc.\n" +
    "    </p>\n" +
    "    <p>\n" +
    "        Click on the button below to navigate to Institution List backend to enter these details.\n" +
    "    </p>\n" +
    "    <p>\n" +
    "        Please note that without these details, the upgrade will not be complete.\n" +
    "    </p>\n" +
    "</div>\n" +
    "<div class=\"modal-footer\">\n" +
    "    <a class=\"btn btn-primary btn-sm\"\n" +
    "           ng-click=\"modalOptions.completeUpgradeClick()\"\n" +
    "           ui-sref=\"staff.institutions.list({coreId: modalOptions.institutionCoreId})\">Complete upgrade now</a>\n" +
    "    <button type=\"button\" class=\"btn btn-sm\"\n" +
    "            ng-click=\"modalOptions.close()\">{{modalOptions.closeButtonText}}</button>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tu/departments/tuProfileAddDepartmentFormView.html',
    "<div class=\"ibox\">\n" +
    "    <div class=\"ibox-title m-t-xl\">\n" +
    "        <h5>Add Department</h5>\n" +
    "        <div class=\"ibox-tools\">\n" +
    "            <a class=\"close-link\" ng-click=\"toggleDepartmentAddForm()\">\n" +
    "                <i class=\"fa fa-times\"></i>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"ibox-content\">\n" +
    "        <div wave-spinner class=\"text-right\" ng-show=\"fetchingHistoryLog()\"></div>\n" +
    "\n" +
    "        <form class=\"form-horizontal add-department\" name=\"forms.addDepartmentForm\" novalidate>\n" +
    "            <div class=\"form-group\" ng-if=\"DepartmentOverviewController.isInstitutionSelected()\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">Institution Name *</label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <input type=\"text\" class=\"form-control\" name=\"institutionName\"\n" +
    "                           ng-model=\"DepartmentOverviewController.institution.selected.name\"\n" +
    "                           ng-disabled=\"true\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\" ng-class=\"{'has-errors': isInvalidNewDepartmentName}\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">\n" +
    "                    Department Name *\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <input type=\"text\" name=\"name\"\n" +
    "                        placeholder=\"Add name\"\n" +
    "                        ng-model=\"newDepartment.name\"\n" +
    "                        class=\"form-control\"\n" +
    "                        ng-required=\"true\"\n" +
    "                        focus-delay=\"250\"\n" +
    "                        custom-popover\n" +
    "                        popover-html=\"Add a department name\"\n" +
    "                        popover-placement=\"left\"\n" +
    "                        popover-trigger=\"manual\"\n" +
    "                        popover-visibility=\"{{isInvalidNewDepartmentName ? true : false}}\"\n" +
    "                        ng-focus=\"setIsInvalidNewDepartmentName(false)\" />\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\" ng-class=\"{'has-errors': isInvalidNewBelongsTo}\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">Belongs to *</label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <ui-select\n" +
    "                        name=\"belongsTo\"\n" +
    "                        ng-model=\"newDepartment.belongsTo\"\n" +
    "                        theme=\"bootstrap\"\n" +
    "                        focus-delay=\"250\"\n" +
    "                        custom-popover popover-html=\"Select an option\"\n" +
    "                        popover-placement=\"left\"\n" +
    "                        popover-trigger=\"manual\"\n" +
    "                        popover-visibility=\"{{isInvalidNewBelongsTo ? true : false}}\"\n" +
    "                        on-select=\"setIsInvalidNewBelongsTo(false)\">\n" +
    "                        <ui-select-match placeholder=\"Select an option\">\n" +
    "                            <i class=\"fa fa-building\"></i>\n" +
    "                            <span>{{$select.selected.title}}</span>\n" +
    "                        </ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                          position=\"down\"\n" +
    "                          repeat=\"option.id as option in belongsToList | filter: $select.search\">\n" +
    "                          <div class=\"test\" ng-bind-html=\"option.title | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"white-space\"></div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "                <div class=\"col-sm-12\">\n" +
    "                    <a class=\"btn btn-primary pull-right\" ng-class=\"{'disabled': addInProgress}\" ng-click=\"handleDepartmentCreateClick()\">\n" +
    "                        <i class=\"fa fa-check-circle\"></i>\n" +
    "                        <span>Save</span>\n" +
    "                    </a>\n" +
    "                    <a class=\"btn btn-default pull-right\" ng-click=\"toggleDepartmentAddForm()\">\n" +
    "                        <i class=\"fa fa-ban\"></i>\n" +
    "                        <span>Cancel</span>\n" +
    "                    </a>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </form>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tu/departments/tuProfileDepartmentsView.html',
    "<div ng-controller=\"TuProfileDepartmentsController as DepartmentsController\">\n" +
    "    <div class=\"alert alert-info\">\n" +
    "        <p>\n" +
    "            <i class=\"fa fa-info-circle\"></i>\n" +
    "            <span>Only the first top 50 departments will be published.</span>\n" +
    "        </p>\n" +
    "        <p>\n" +
    "            <i class=\"fa fa-info-circle\"></i>\n" +
    "            <span>Departments can be published only for advanced profiles.</span>\n" +
    "        </p>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"tab-header\">\n" +
    "        <div class=\"btn-toggle\">\n" +
    "            <switch class=\"green\"\n" +
    "                ng-disabled=\"alphabeticalOrderingInProgress\"\n" +
    "                ng-model=\"institutionData.departmentsAlphabeticalOrder\"\n" +
    "                ng-change=\"!alphabeticalOrderingInProgress ? handleAlphabeticalOrderClick(institutionData.departmentsAlphabeticalOrder) : null\"></switch>\n" +
    "            <span class=\"switch-text\"> Display in alphabetical order?</span>\n" +
    "        </div>\n" +
    "\n" +
    "        <button class=\"btn btn-primary pull-right\" type=\"button\" \n" +
    "            ng-class=\"{'disabled': departmentsTabSubmitInProgress}\"\n" +
    "            ng-click=\"handleAddDepartmentClick()\">\n" +
    "            <i class=\"fa fa-plus\"></i>\n" +
    "            <span>Add Department</span>\n" +
    "        </button>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"tab-body\">\n" +
    "        <div wave-spinner class=\"wave-spinner\" ng-show=\"DepartmentsController.isgridRendering\"></div>\n" +
    "\n" +
    "        <div ng-if=\"!isDatagridReloading && gridOptions\">\n" +
    "            <ui-grid-info ng-if=\"DepartmentsController.isDepartmentsTabSelected\"></ui-grid-info>\n" +
    "            <div class=\"grid\"\n" +
    "                ng-class=\"{'modal-overlay-35': DepartmentsController.isgridRendering}\"\n" +
    "                ui-grid=\"gridOptions\"\n" +
    "                ui-grid-draggable-rows\n" +
    "                ui-grid-selection\n" +
    "                ui-grid-resize-columns\n" +
    "                ui-grid-auto-resize></div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tu/departments/tuProfileEditDepartmentFormView.html',
    "<div class=\"ibox\">\n" +
    "    <div class=\"ibox-title m-t-xl\">\n" +
    "        <h5>Edit Department</h5>\n" +
    "        <div class=\"ibox-tools\">\n" +
    "            <a class=\"close-link\" ng-click=\"toggleDepartmentEditForm()\">\n" +
    "                <i class=\"fa fa-times\"></i>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"ibox-content\">\n" +
    "        <div wave-spinner class=\"text-right\" ng-show=\"isFetchInProgress()\"></div>\n" +
    "        <div ng-show=\"!isFetchInProgress()\" ng-class=\"{'modal-overlay-35': !allowEdit()}\">\n" +
    "            <form class=\"form-horizontal edit-department\" name=\"forms.editDepartmentForm\" novalidate>\n" +
    "                <div class=\"form-group\" ng-if=\"DepartmentOverviewController.isInstitutionSelected()\">\n" +
    "                    <label class=\"control-label col-sm-5 col-md-4\">Institution Name *</label>\n" +
    "                    <div class=\"col-sm-7 col-md-8\">\n" +
    "                        <input type=\"text\" class=\"form-control\" name=\"institutionName\"\n" +
    "                               ng-model=\"DepartmentOverviewController.institution.selected.name\"\n" +
    "                               ng-required=\"true\"\n" +
    "                               ng-disabled=\"true\">\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group\" ng-class=\"{'has-errors': isInvalidDepartmentNameUpdate}\">\n" +
    "                    <label class=\"control-label col-sm-5 col-md-4\">Department Name *</label>\n" +
    "                    <div class=\"col-sm-7 col-md-8\">\n" +
    "                        <input type=\"text\" name=\"name\"\n" +
    "                            placeholder=\"Add name\"\n" +
    "                            ng-model=\"department.name\"\n" +
    "                            class=\"form-control\"\n" +
    "                            ng-required=\"true\"\n" +
    "                            focus-delay=\"250\"\n" +
    "                            custom-popover\n" +
    "\n" +
    "                            popover-html=\"Add a department name\"\n" +
    "                            popover-placement=\"left\"\n" +
    "                            popover-trigger=\"manual\"\n" +
    "                            popover-visibility=\"{{isInvalidDepartmentNameUpdate ? true : false}}\"\n" +
    "                            ng-focus=\"setIsInvalidDepartmentNameUpdate(false)\" />\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "               <div class=\"form-group\">\n" +
    "                    <label class=\"control-label col-sm-5 col-md-4\">Department Type *</label>\n" +
    "                    <div class=\"col-sm-7 col-md-8\">\n" +
    "                        <input type=\"text\" class=\"form-control\" name=\"type\"\n" +
    "                               ng-model=\"department.typeName\"\n" +
    "                               ng-required=\"true\"\n" +
    "                               ng-disabled=\"true\">\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group\" ng-class=\"{'has-errors': isInvalidBelongsToUpdate}\">\n" +
    "                    <label class=\"control-label col-sm-5 col-md-4\">Belongs to *</label>\n" +
    "                    <div class=\"col-sm-7 col-md-8\">\n" +
    "                        <ui-select name=\"belongsTo\"\n" +
    "                            ng-model=\"department.belongsTo\"\n" +
    "                            theme=\"bootstrap\"\n" +
    "                            focus-delay=\"250\"\n" +
    "                            custom-popover popover-html=\"Select an option\"\n" +
    "                            popover-placement=\"left\"\n" +
    "                            popover-trigger=\"manual\"\n" +
    "                            popover-visibility=\"{{isInvalidBelongsToUpdate ? true : false}}\"\n" +
    "                            ng-change=\"setIsInvalidBelongsToUpdate(false)\">\n" +
    "                            <ui-select-match placeholder=\"Select an option\">\n" +
    "                                <i class=\"fa fa-building\"></i>\n" +
    "                                <span>{{$select.selected.title}}</span>\n" +
    "                            </ui-select-match>\n" +
    "                            <ui-select-choices\n" +
    "                            position=\"down\"\n" +
    "                            repeat=\"option.id as option in belongsToList | filter: $select.search\">\n" +
    "                            <div class=\"test\" ng-bind-html=\"option.title | highlight: $select.search\"></div>\n" +
    "                            </ui-select-choices>\n" +
    "                        </ui-select>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"white-space\"></div>\n" +
    "\n" +
    "                <div class=\"form-group\">\n" +
    "                    <div class=\"col-sm-12\">\n" +
    "                        <a class=\"btn btn-primary pull-right\"\n" +
    "                           ng-class=\"{'disabled': editInProgress}\"\n" +
    "                           ng-click=\"handleDepartmentUpdateClick()\">\n" +
    "                            <i class=\"fa fa-check-circle\"></i>\n" +
    "                            <span>Update</span>\n" +
    "                        </a>\n" +
    "                        <a class=\"btn btn-default pull-right\" ng-click=\"toggleDepartmentEditForm()\">\n" +
    "                            <i class=\"fa fa-ban\"></i>\n" +
    "                            <span>Cancel</span>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"separator dashed\"></div>\n" +
    "\n" +
    "                <div class=\"btn-adjustment\" ng-if=\"allowUpgrade()\">\n" +
    "                    <a class=\"btn btn-warning col-lg-10 col-lg-offset-1\"\n" +
    "                       ng-disabled=\"DepartmentFormController.isUpgradeDisabled()\"\n" +
    "                       ng-click=\"!DepartmentFormController.isUpgradeDisabled() && DepartmentFormController.handleUpgradeClick()\"\n" +
    "                       uib-popover=\"Mandatory fields must be entered first to upgrade\"\n" +
    "                       popover-placement=\"left\"\n" +
    "                       popover-enable=\"DepartmentFormController.isUpgradeDisabled()\"\n" +
    "                       popover-trigger=\"'mouseenter'\">\n" +
    "                        <i class=\"fa fa-star\"></i>\n" +
    "                        <span>Upgrade Department</span>\n" +
    "                    </a>\n" +
    "                </div>\n" +
    "                \n" +
    "                <div class=\"form-group\" ng-if=\"allowDowngrade()\">\n" +
    "                    <a class=\"btn btn-danger col-lg-10 col-lg-offset-1\"\n" +
    "                       ng-click=\"handleDowngradeClick()\">\n" +
    "                        <i class=\"fa fa-arrow-circle-o-down\"></i>\n" +
    "                        <span>Downgrade to simple department</span>\n" +
    "                    </a>\n" +
    "                </div>\n" +
    "                <div class=\"form-group\" ng-if=\"allowLoginAs()\">\n" +
    "                    <a class=\"btn btn-info col-lg-10 col-lg-offset-1\"\n" +
    "                       ng-disabled=\"DepartmentFormController.isLoginRestricted()\"\n" +
    "                       ng-click=\"!DepartmentFormController.isLoginRestricted() && handleLoginAsClick()\"\n" +
    "\n" +
    "                       uib-tooltip='Login has been disabled due to permission. Contact {{DepartmentFormController.tuSupportEmail}}'\n" +
    "                       tooltip-enable=\"DepartmentFormController.isLoginRestricted()\"\n" +
    "                       tooltip-placement=\"left\">\n" +
    "                        <i class=\"fa fa-sign-in\"></i>\n" +
    "                        <span>Login as Department</span>\n" +
    "                    </a>\n" +
    "                </div>\n" +
    "            </form>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tu/departments/tuProfileUpgradeClientDepartmentFormView.html',
    "<div class=\"ibox-title\">\n" +
    "    <h5>Upgrade Department</h5>\n" +
    "    <div class=\"ibox-tools\">\n" +
    "        <a class=\"close-link\" ng-click=\"DepartmentFormController.toggleClientUpgradeForm()\">\n" +
    "            <i class=\"fa fa-times\"></i>\n" +
    "        </a>\n" +
    "    </div>\n" +
    "</div>\n" +
    "<div class=\"ibox block-institution\">\n" +
    "    <div class=\"ibox-content\">\n" +
    "        <form name=\"forms.upgradeTuDepartment\" class=\"clearfix form-horizontal\" novalidate>\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"col-sm-3\">To</label>\n" +
    "                <div class=\"col-sm-9\">{{upgradeEmailsTo}}</div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"col-sm-3\">Subject</label>\n" +
    "                <div class=\"col-sm-9\">I would like more information about Upgrading the Department.</div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"col-sm-3\">Name</label>\n" +
    "                <div class=\"col-sm-9\">\n" +
    "                    {{upgradeRequest.name}}\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"col-sm-3\">Email</label>\n" +
    "                <div class=\"col-sm-9\">\n" +
    "                    {{upgradeRequest.email}}\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"col-sm-3\">Comments</label>\n" +
    "                <div class=\"col-sm-9\">\n" +
    "                    <textarea rows=\"7\" cols=\"50\" placeholder=\"Comments...\" ng-model=\"upgradeRequest.comments\" class=\"form-control no-resize\" ></textarea>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"white-space\"></div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "                <div class=\"col-sm-12\">\n" +
    "                    <a class=\"btn btn-primary pull-right\" ng-class=\"{'disabled': TopUniversitiesController.upgradeInProgress}\" ng-click=\"TopUniversitiesController.handleSendUpgradeRequestClick()\">\n" +
    "                        <i class=\"fa fa-arrow-circle-o-right\"></i> \n" +
    "                        <span>Send</span>\n" +
    "                    </a>\n" +
    "                    <a class=\"btn btn-default pull-right\" ng-click=\"DepartmentFormController.toggleClientUpgradeForm()\">\n" +
    "                        <i class=\"fa fa-close\"></i> \n" +
    "                        <span>Close</span>\n" +
    "                    </a>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </form>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tu/matchingTool/datagrid/rowTemplate.html',
    "<div grid=\"grid\">\n" +
    "    <div class=\"ui-grid-cell pointer\"\n" +
    "         ng-repeat=\"(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name\"\n" +
    "         ng-class=\"{ 'ui-grid-row-header-cell': col.isRowHeader, 'active': row.entity.id == grid.appScope.selectedRowId }\"\n" +
    "         role=\"{{col.isRowHeader ? 'rowheader' : 'gridcell'}}\"\n" +
    "         ui-grid-cell>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tu/matchingTool/datagrid/selectFilterHeaderTemplate.html',
    "<div class=\"ui-grid-filter-container\"\n" +
    "     ng-repeat=\"colFilter in col.filters\"\n" +
    "     ng-class=\"{'ui-grid-filter-cancel-button-hidden' : colFilter.disableCancelFilterButton === true }\">\n" +
    "\n" +
    "<ui-select  class=\"ui-grid-filter-select ui-grid-filter-input-{{$index}} select-{{col.field}}\"\n" +
    "   ng-model=\"colFilter.term\"\n" +
    "   theme=\"bootstrap\"\n" +
    "   search-enabled=\"{{colFilter.searchEnabled || false}}\"\n" +
    "   append-to-body=\"false\">\n" +
    "   <ui-select-match placeholder=\"{{colFilter.placeholder}}\">\n" +
    "       <span ng-bind-html=\"$select | uiGridSelectedLabel\"></span>\n" +
    "   </ui-select-match>\n" +
    "   <ui-select-choices\n" +
    "           repeat=\"item in (colFilter.selectOptions | filter: $select.search | orderBy: colFilter.orderBy) track by item.value\"\n" +
    "           ui-disable-choice=\"item.disabled\">\n" +
    "       <div ng-bind-html=\"item.label\"></div>\n" +
    "   </ui-select-choices>\n" +
    "</ui-select>\n" +
    "\n" +
    "<div role=\"button\" class=\"ui-grid-filter-button-select\"\n" +
    "     ng-click=\"removeFilter(colFilter, $index)\"\n" +
    "     ng-if=\"!colFilter.disableCancelFilterButton\" \n" +
    "     ng-disabled=\"colFilter.term === undefined || colFilter.term === null || colFilter.term.length === 0\"\n" +
    "     ng-show=\"colFilter.term !== undefined && colFilter.term != null && colFilter.term.length !== 0\">\n" +
    "   <i class=\"ui-grid-icon-cancel\" ui-grid-one-bind-aria-label=\"aria.removeFilter\">&nbsp;</i>\n" +
    "</div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tu/matchingTool/rightSidePanelView.html',
    "<div class=\"ibox matching-tool-right-side-panel\" ng-controller=\"MatchingToolRightSidePanelController as MatchingToolRightSidePanelController\">\n" +
    "    <div class=\"ibox-title m-t-xl\">\n" +
    "        <h5>{{MatchingToolRightSidePanelController.isEdit() ? 'Edit' : 'Add'}} Program</h5>\n" +
    "        <div class=\"ibox-tools\">\n" +
    "            <a ng-click=\"TopUniversitiesController.closeMatchingToolRightSidePanel()\">\n" +
    "                <i class=\"fa fa-times\"></i>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"ibox-content\">\n" +
    "        <course-edit ng-if=\"MatchingToolRightSidePanelController.isEdit()\"\n" +
    "                     course-data=\"MatchingToolRightSidePanelController.getData()\">\n" +
    "        </course-edit>\n" +
    "        <course-add ng-if=\"MatchingToolRightSidePanelController.isAdd()\"\n" +
    "                    course-data=\"MatchingToolRightSidePanelController.getNewData()\">\n" +
    "        </course-add>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tu/matchingTool/tuProfileMatchingToolView.html',
    "<div id=\"coursesTable\" ng-controller=\"TuProfileMatchingToolController as TuProfileMatchingToolController\">\n" +
    "    <div class=\"tab-header\">\n" +
    "        <a class=\"btn btn-primary pull-right\"\n" +
    "           ng-class=\"{'disabled': TuProfileMatchingToolController.submitInProgress}\"\n" +
    "           ng-click=\"TuProfileMatchingToolController.handleAddProgramClick()\">\n" +
    "            <i class=\"fa fa-plus\"></i>\n" +
    "            <span>Add Program</span>\n" +
    "        </a>\n" +
    "    </div>\n" +
    "    <div wave-spinner class=\"wave-spinner\" ng-show=\"TuProfileMatchingToolController.isLoading()\"></div>\n" +
    "    <div class=\"tab-body\" ng-class=\"{'modal-overlay-35': TuProfileMatchingToolController.isLoading()}\">\n" +
    "        <div ng-class=\"{'hide-grid-menu':TuProfileMatchingToolController.isRightSidePanelActive()}\"\n" +
    "             ng-if=\"TuProfileMatchingToolController.gridOptions\">\n" +
    "            <ui-grid-info ng-if=\"TuProfileMatchingToolController.isMatchingToolTabSelected\"></ui-grid-info>\n" +
    "            <div class=\"grid\"\n" +
    "                 ui-grid=\"TuProfileMatchingToolController.gridOptions\"\n" +
    "                 ui-grid-pagination\n" +
    "                 ui-grid-selection\n" +
    "                 ui-grid-resize-columns\n" +
    "                 ui-grid-auto-resize\n" +
    "                 ui-grid-exporter>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tu/media/subtabs/brochures/tuProfileMediaBrochuresSidebarView.html',
    "<div class=\"ibox\" ng-class=\"getSelected() === 4 ? 'selected' : 'collapsed'\"  ng-controller=\"TuMediaBrochuresSidebarController as TuMediaBrochuresSidebarController\">\n" +
    "    <div class=\"ibox-title clickable\" ng-click=\"setSelected(4)\">\n" +
    "        <h5>Brochures ({{getBrochureItems().length}})</h5>\n" +
    "        <div class=\"ibox-tools\">\n" +
    "            <a>\n" +
    "                <i class=\"fa fa-chevron-up\"></i>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"ibox-content\">\n" +
    "        <div class=\"addForm\">\n" +
    "            <form class=\"form-horizontal\" name=\"forms.formBrochure\" id=\"form-brochure\" novalidate>\n" +
    "                <div class=\"alert alert-info\">\n" +
    "                    <p>\n" +
    "                        <i class=\"fa fa-info-circle\"></i>\n" +
    "                        <span>Please add your brochure title and link.</span><br />\n" +
    "                        <span>Drag and drop brochures from left to right to change the order.</span><br>\n" +
    "                    </p>\n" +
    "                </div>\n" +
    "\n" +
    "                <div>\n" +
    "                    <div class=\"form-group\" ng-class=\"{'has-errors': getHasErrors()}\">\n" +
    "                        <div class=\"col-sm-12\" use-tu-subtypes>\n" +
    "                            <div class=\"checkbox-inline i-checkbox\">\n" +
    "                                <label for=\"brochure-overview\">\n" +
    "                                    <input i-checkbox type=\"checkbox\" name=\"overview\" id=\"brochure-overview\" focus-if=\"getDisplayTypesValidation()\" focus-delay=\"250\" ng-model=\"selectedBrochure.master\"  ng-change=\"selectedBrochure.master? setDisplayTypesValidation(false) : ''\" />\n" +
    "                                    <span>Overview</span>\n" +
    "                                </label>\n" +
    "                            </div>\n" +
    "\n" +
    "                            <div class=\"checkbox-inline i-checkbox\">\n" +
    "                                <label for=\"brochure-undergraduate\">\n" +
    "                                    <input i-checkbox type=\"checkbox\" name=\"undergraduate\" id=\"brochure-undergraduate\" ng-model=\"selectedBrochure.ug\" ng-change=\"selectedBrochure.ug? setDisplayTypesValidation(false) : ''\" />\n" +
    "                                    <span>Undergraduate</span>\n" +
    "                                </label>\n" +
    "                            </div>\n" +
    "\n" +
    "                            <div class=\"checkbox-inline i-checkbox\">\n" +
    "                                <label for=\"brochure-postgraduate\">\n" +
    "                                    <input i-checkbox type=\"checkbox\" name=\"postgraduate\" id=\"brochure-postgraduate\" ng-model=\"selectedBrochure.pg\" ng-change=\"selectedBrochure.pg? setDisplayTypesValidation(false) : ''\" />\n" +
    "                                    <span>Postgraduate</span>\n" +
    "                                </label>\n" +
    "                            </div>\n" +
    "\n" +
    "                            <div ng-class=\"getDisplayTypesValidation()? 'has-errors' : ''\" >\n" +
    "                                <span\n" +
    "                                    custom-popover popover-html=\"Assign brochure to at least one of the three profiles\" \n" +
    "                                    popover-placement=\"left\"\n" +
    "                                    popover-trigger=\"manual\"\n" +
    "                                    popover-visibility=\"{{getDisplayTypesValidation() ? true : false}}\"></span>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "\n" +
    "                    <div class=\"form-group\" ng-class=\"{'has-errors': isInvalidTitle}\">\n" +
    "                        <label class=\"control-label col-sm-3 col-md-2\" for=\"brochure-title\">\n" +
    "                            <span>Title *</span>\n" +
    "                            <small>110 characters</small>\n" +
    "                        </label>\n" +
    "                        <div class=\"col-sm-9 col-md-10\">\n" +
    "                            <input class=\"form-control\"\n" +
    "                                   name=\"brochure-title\"\n" +
    "                                   id=\"brochure-title\"\n" +
    "                                   maxlength=\"110\"\n" +
    "                                   ng-required=\"required\"\n" +
    "                                   type=\"text\"\n" +
    "                                   focus-if=\"isHighlighted\"\n" +
    "                                   focus-delay=\"250\"\n" +
    "\n" +
    "                                   custom-popover\n" +
    "                                   popover-html=\"Add a brochure title\"\n" +
    "                                   popover-placement=\"left\"\n" +
    "                                   popover-trigger=\"manual\"\n" +
    "                                   popover-visibility=\"{{isInvalidTitle ? true : false}}\"\n" +
    "                                   ng-focus=\"setIsInvalidTitle(false)\"\n" +
    "                                   ng-model=\"selectedBrochure.name\" />\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "\n" +
    "                    <div class=\"form-group\" ng-class=\"{'has-errors': isInvalidUrl}\">\n" +
    "                        <label class=\"control-label col-sm-3 col-md-2\">\n" +
    "                            <span>URL *</span>\n" +
    "                        </label>\n" +
    "                        <div class=\"col-sm-9 col-md-10\">\n" +
    "                            <input class=\"form-control\"\n" +
    "                                   name=\"url\"\n" +
    "                                   type=\"url\"\n" +
    "                                   placeholder=\"http://\"\n" +
    "                                   ng-required=\"required\"\n" +
    "                                   ng-model=\"selectedBrochure.url\"\n" +
    "                                   ng-pattern=\"TuMediaBrochuresSidebarController.urlPattern\"\n" +
    "                                   ng-keyup=\"TuMediaBrochuresSidebarController.onKeyUp($event)\"\n" +
    "                                   ng-focus=\"setIsInvalidUrl(false)\"\n" +
    "                                   maxlength=\"2011\"\n" +
    "\n" +
    "                                   custom-popover\n" +
    "                                   popover-html=\"Add a valid brochure link\"\n" +
    "                                   popover-placement=\"left\"\n" +
    "                                   popover-trigger=\"manual\"\n" +
    "                                   popover-visibility=\"{{isInvalidUrl ? true : false}}\"/>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "\n" +
    "                    <div class=\"form-group m-b-none\">\n" +
    "                        <div class=\"col-sm-12\">\n" +
    "                            <button class=\"btn btn-primary pull-right btn-float-fix\" ng-click=\"saveBrochureForm(selectedBrochure)\">\n" +
    "                                <i class=\"fa fa-check-circle\"></i>\n" +
    "                                <span>{{isEditMode() ? 'Update' : 'Save'}}</span>\n" +
    "                            </button>\n" +
    "                            <button class=\"btn btn-default pull-right btn-float-fix\" ng-click=\"clearBrochureForm(selectedBrochure)\">\n" +
    "                                <i class=\"fa fa-ban\"></i>\n" +
    "                                <span>Clear</span>\n" +
    "                            </button>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </form>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tu/media/subtabs/brochures/tuProfileMediaBrochuresView.html',
    "<div ng-controller=\"TuMediaBrochuresController\">\n" +
    "    <h3 class=\"heading\">Brochures ({{brochureItems.length}})</h3>\n" +
    "    <div class=\"tab-body\">\n" +
    "        <div class=\"sort-filter\" use-tu-subtypes>\n" +
    "            <a class=\"btn btn-link btn-overview\" ng-click=\"filter(typeOverview())\" ng-class=\"type === typeOverview() ? 'active' : 'inactive'\">\n" +
    "                <i class=\"fa fa-book\"></i>\n" +
    "                <span>Overview ({{(brochureItems | filter:{master:true}).length}})</span>\n" +
    "            </a> | \n" +
    "            <a class=\"btn btn-link btn-university\" ng-click=\"filter(typeUndergraduate())\" ng-class=\"type === typeUndergraduate() ? 'active' : 'inactive'\">\n" +
    "                <i class=\"fa fa-university\"></i>\n" +
    "                <span>Undergraduate ({{(brochureItems | filter:{ug:true}).length}})</span>\n" +
    "            </a> | \n" +
    "            <a class=\"btn btn-link btn-graduation\" ng-click=\"filter(typePostgraduate())\" ng-class=\"type === typePostgraduate() ? 'active' : 'inactive'\">\n" +
    "                <i class=\"fa fa-graduation-cap\"></i>\n" +
    "                <span>Postgraduate ({{(brochureItems | filter:{pg:true}).length}})</span>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"gallery\">\n" +
    "            <div class=\"grid-view upload\" ng-class=\"selectedItem().id === '' ? 'selected' : 'unselected'\" ng-click=\"selectBrochure()\" ng-click=\"selectBrochure()\">\n" +
    "                <div class=\"source-link\">\n" +
    "                    <i class=\"fa fa-plus\"></i>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div ui-sortable=\"sortableOptions\" ng-model=\"filteredBrochureItems\">\n" +
    "                <div class=\"grid-view\" ng-class=\"selectedItem().id === item.id ? 'selected' : 'unselected'\" ng-click=\"selectBrochure(item)\" ng-repeat=\"item in filteredBrochureItems\">\n" +
    "                    \n" +
    "                    <div class=\"preview\" ng-class=\"item.imageUrl ? '' : 'overlay' \">\n" +
    "                        <img ng-src=\"{{item.imageUrl || '/images/media/pdf-thumbnail.jpg'}}\"/>\n" +
    "                    </div>\n" +
    "                    \n" +
    "                    <div class=\"info\">\n" +
    "                        <div class=\"title\" item-order=\"{{item['orderType'][type]}}\">{{item.name}}</div>\n" +
    "                        <div class=\"types\" use-tu-subtypes>\n" +
    "                            <i class=\"fa fa-book\" ng-show=\"item.master\"></i>\n" +
    "                            <i class=\"fa fa-university\" ng-show=\"item.ug\"></i>\n" +
    "                            <i class=\"fa fa-graduation-cap\" ng-show=\"item.pg\"></i>\n" +
    "                        </div>\n" +
    "                        <div class=\"actions\">\n" +
    "                            <a href=\"{{item.url}}\" target=\"_blank\" title=\"View {{item.name}}\">\n" +
    "                                <i class=\"fa fa-search\"></i>\n" +
    "                            </a>\n" +
    "                            <a ng-click=\"deleteBrochure(item)\" title=\"Delete {{item.name}}\">\n" +
    "                                <i class=\"fa fa-times-circle\"></i>\n" +
    "                            </a>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tu/media/subtabs/images/tuProfileMediaImagesSidebarView.html',
    "<div class=\"ibox\" ng-class=\"getSelected() === 1 ? 'selected' : 'collapsed'\" ng-controller=\"TuMediaImagesSidebarController\">\n" +
    "    <div class=\"ibox-title clickable\" ng-click=\"setSelected(1)\">\n" +
    "        <h5>Images ({{getImageItems().length}})</h5>\n" +
    "        <div class=\"ibox-tools\">\n" +
    "            <a>\n" +
    "                <i class=\"fa fa-chevron-up\"></i>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"ibox-content\">\n" +
    "        <div class=\"addForm\" ng-class=\"getImageUploadingInProgress() ? 'modal-overlay' : '' \">\n" +
    "            <form class=\"form-horizontal\" name=\"forms.formImage\" id=\"form-image\" novalidate>\n" +
    "                <div class=\"alert alert-info\" ng-hide=\"selectedImage.id.length > 0\">\n" +
    "                    <p>\n" +
    "                        <i class=\"fa fa-info-circle\"></i>\n" +
    "                        <span>Please click on the upload sign to upload an image or drag and drop image into it.</span>\n" +
    "                    </p>\n" +
    "                </div>\n" +
    "                <div class=\"alert alert-info\">\n" +
    "                    <p>\n" +
    "                        <i class=\"fa fa-info-circle\"></i>\n" +
    "                        <span>File must be: Less than 400KB / in jpg, jpeg format.</span><br />\n" +
    "                        <span>Maximum total size of images is 12800kb.</span><br />\n" +
    "                        <span>Images are scaled to 703 x 398 on front-end site.</span><br />\n" +
    "                        <span>Drag and drop images from left to right to change the order.</span>\n" +
    "                    </p>\n" +
    "                </div>\n" +
    "                \n" +
    "                <div ng-show=\"selectedImage.id.length > 0\">\n" +
    "                    <div class=\"form-group\">\n" +
    "                        <div class=\"col-sm-12\" use-tu-subtypes>\n" +
    "                            <div class=\"checkbox-inline i-checkbox\">\n" +
    "                                <label for=\"overviewVideo\">\n" +
    "                                    <input i-checkbox type=\"checkbox\" name=\"overview\" id=\"overviewVideo\" ng-model=\"selectedImage.master\" focus-if=\"getDisplayIsValidType()\" focus-delay=\"250\" ng-change=\"selectedImage.master? setDisplayIsValidType(false) : ''\"/>\n" +
    "                                    <span>Overview</span>\n" +
    "                                </label>\n" +
    "                            </div>\n" +
    "                            <div class=\"checkbox-inline i-checkbox\">\n" +
    "                                <label for=\"undergraduateVideo\">\n" +
    "                                    <input i-checkbox type=\"checkbox\" name=\"undergraduate\" id=\"undergraduateVideo\" ng-model=\"selectedImage.ug\" ng-change=\"selectedImage.ug? setDisplayIsValidType(false) : ''\"/>\n" +
    "                                    <span>Undergraduate</span>\n" +
    "                                </label>\n" +
    "                            </div>\n" +
    "                            <div class=\"checkbox-inline i-checkbox\">\n" +
    "                                <label for=\"postgraduateVideo\">\n" +
    "                                    <input i-checkbox type=\"checkbox\" name=\"postgraduate\" id=\"postgraduateVideo\" ng-model=\"selectedImage.pg\" ng-change=\"selectedImage.pg? setDisplayIsValidType(false) : ''\"/>\n" +
    "                                    <span>Postgraduate</span>\n" +
    "                                </label>\n" +
    "                            </div>\n" +
    "                            <div ng-class=\"getDisplayIsValidType()? 'has-errors' : ''\" >\n" +
    "                                <span \n" +
    "                                    custom-popover popover-html=\"Assign image to at least one of the three profiles\"\n" +
    "                                    popover-placement=\"left\"\n" +
    "                                    popover-trigger=\"manual\"\n" +
    "                                    popover-visibility=\"{{getDisplayIsValidType() ? true : false}}\"></span>   \n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "\n" +
    "                    <div class=\"form-group\">\n" +
    "                        <label class=\"control-label col-xs-5 col-lg-3\" for=\"title\">\n" +
    "                            <span>Title</span>\n" +
    "                            <small>76 characters</small>\n" +
    "                        </label>\n" +
    "                        <div class=\"col-xs-7 col-lg-9\">\n" +
    "                            <input class=\"form-control\" name=\"title\" id=\"title\" placeHolder=\"Add title\" maxlength=\"76\" type=\"text\" ng-model=\"selectedImage.name\" focus-if=\"isHighlighted\" focus-delay=\"250\"/>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    \n" +
    "                    <div class=\"form-group\">\n" +
    "                        <label class=\"control-label col-xs-5 col-lg-3\" for=\"description\">\n" +
    "                            <span>Description</span>\n" +
    "                            <small>96 characters</small>\n" +
    "                        </label>\n" +
    "                        <div class=\"col-xs-7 col-lg-9\">\n" +
    "                            <textarea id=\"description\" class=\"form-control\" name=\"description\" placeHolder=\"Add description\" maxlength=\"96\" ng-model=\"selectedImage.description\"></textarea>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "\n" +
    "                    <div class=\"form-group m-b-none\">\n" +
    "                        <div class=\"col-sm-12\">\n" +
    "                            <button class=\"btn btn-primary pull-right btn-float-fix\" ng-class=\"{'disabled': submitInProgress}\" ng-click=\"saveImageForm(selectedImage)\">\n" +
    "                                <i class=\"fa fa-check-circle\"></i>\n" +
    "                                <span>{{isEditMode() ? 'Update' : 'Save'}}</span>\n" +
    "                            </button>\n" +
    "                            <button class=\"btn btn-default pull-right btn-float-fix\" ng-class=\"{'disabled': submitInProgress}\" ng-click=\"clearImageForm(selectedImage)\">\n" +
    "                                <i class=\"fa fa-ban\"></i>\n" +
    "                                <span>{{isEditMode() ? 'Clear' : 'Cancel'}}</span>\n" +
    "                            </button>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "\n" +
    "                </div>\n" +
    "            </form>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "</div>"
  );


  $templateCache.put('/scripts/components/profiles/tu/media/subtabs/images/tuProfileMediaImagesView.html',
    "<div ng-controller=\"TuMediaImagesController as TuMediaImagesController\">\n" +
    "\n" +
    "    <h3 class=\"heading\">Images ({{imageItems.length}})</h3>\n" +
    "    <div class=\"tab-body\">\n" +
    "        <div class=\"sort-filter\" use-tu-subtypes>\n" +
    "            <a class=\"btn btn-link btn-overview\" ng-click=\"filter(typeOverview(), true)\" ng-class=\"type === typeOverview() ? 'active' : 'inactive'\">\n" +
    "                <i class=\"fa fa-book\"></i>\n" +
    "                <span>Overview ({{uploadedImagesCount[typeOverview()]}}/{{TuMediaImagesController.maxImages}})</span>\n" +
    "            </a> | \n" +
    "            <a class=\"btn btn-link btn-university\" ng-click=\"filter(typeUndergraduate(), true)\" ng-class=\"type === typeUndergraduate() ? 'active' : 'inactive'\">\n" +
    "                <i class=\"fa fa-university\"></i>\n" +
    "                <span>Undergraduate ({{uploadedImagesCount[typeUndergraduate()]}}/{{TuMediaImagesController.maxImages}})</span>\n" +
    "            </a> | \n" +
    "            <a class=\"btn btn-link btn-graduation\" ng-click=\"filter(typePostgraduate(), true)\" ng-class=\"type === typePostgraduate() ? 'active' : 'inactive'\">\n" +
    "                <i class=\"fa fa-graduation-cap\"></i>\n" +
    "                <span>Postgraduate ({{uploadedImagesCount[typePostgraduate()]}}/{{TuMediaImagesController.maxImages}})</span>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"gallery\">\n" +
    "            <div class=\"grid-view upload\" ng-class=\"isSelected() ? 'selected' : 'unselected'\" ng-click=\"selectImage()\">\n" +
    "                <div ng-show=\"!isTemporary()\" ng-if=\"isLoaded\">\n" +
    "                    <form name=\"forms.imageForm\" ng-if=\"isMediaTabActive()\"\n" +
    "                          class=\"dropzone image-form\"\n" +
    "                          enctype=\"multipart/form-data\"\n" +
    "                          ng-dropzone\n" +
    "                          dropzone=\"TuMediaImagesController.dropZoneImageInstance\"\n" +
    "                          dropzone-config=\"imageConfig.dropzone\"\n" +
    "                          event-handlers=\"imageConfig.eventHandlers\"\n" +
    "                          novalidate>\n" +
    "                        <div class=\"upload-image\">\n" +
    "                            <i class=\"fa fa-upload\"></i>\n" +
    "                        </div>\n" +
    "                        <div class=\"fallback\">\n" +
    "                            <input name=\"file\" type=\"file\" />\n" +
    "                        </div>\n" +
    "                        <div class=\"dz-message\" ng-show=\"uploadEnabled\">\n" +
    "                            <i class=\"fa fa-upload\"></i>\n" +
    "                        </div>\n" +
    "                        <div class=\"dropzone-previews\"></div>\n" +
    "                    </form>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"preview\" ng-show=\"isTemporary()\">\n" +
    "                    <img ng-src=\"{{item.thumbnailUrl || '/images/media/media-image.png'}}\"/>\n" +
    "                </div>\n" +
    "                \n" +
    "                <div class=\"info\" ng-show=\"isTemporary()\" ng-class=\"getImageUploadingInProgress() ? 'modal-overlay' : '' \">\n" +
    "                    <div class=\"title\" item-order=\"{{item['orderType'][type]}}\">\n" +
    "                        {{item.name}}\n" +
    "                    </div>\n" +
    "                    <div class=\"types\">\n" +
    "                        <i class=\"fa fa-book\" ng-show=\"item.master\"></i>\n" +
    "                        <i class=\"fa fa-university\" ng-show=\"item.ug\"></i>\n" +
    "                        <i class=\"fa fa-graduation-cap\" ng-show=\"item.pg\"></i>\n" +
    "                    </div>\n" +
    "                    <div class=\"actions\">\n" +
    "                        <a href=\"{{item.url}}\" target=\"_blank\" title=\"View {{item.name}}\">\n" +
    "                            <i class=\"fa fa-search\"></i>\n" +
    "                        </a>\n" +
    "                        <a ng-click=\"deleteImage(item)\" title=\"Delete {{item.name}}\">\n" +
    "                            <i class=\"fa fa-times-circle\"></i>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div ui-sortable=\"sortableOptions\" ng-model=\"filteredImageItems\">\n" +
    "                <div class=\"grid-view\" ng-class=\"selectedItem().id === item.id ? 'selected' : 'unselected'\" ng-click=\"selectImage(item)\" ng-repeat=\"item in filteredImageItems\">\n" +
    "                    <div class=\"preview\">\n" +
    "                        <img ng-src=\"{{item.thumbnailUrl || '/images/media/media-image.png'}}\"/>\n" +
    "                    </div>\n" +
    "                    <div class=\"info\">\n" +
    "                        <div class=\"title\" item-order=\"{{item['orderType'][type]}}\">\n" +
    "                            {{item.name}}\n" +
    "                        </div>\n" +
    "                        <div class=\"types\" use-tu-subtypes>\n" +
    "                            <i class=\"fa fa-book\" ng-show=\"item.master\"></i>\n" +
    "                            <i class=\"fa fa-university\" ng-show=\"item.ug\"></i>\n" +
    "                            <i class=\"fa fa-graduation-cap\" ng-show=\"item.pg\"></i>\n" +
    "                        </div>\n" +
    "                        <div class=\"actions\">\n" +
    "                            <a ng-click=\"openLightboxModal($index, item)\" title=\"View {{item.name}}\">\n" +
    "                                <i class=\"fa fa-search\"></i>\n" +
    "                            </a>\n" +
    "                            <a ng-click=\"deleteImage(item)\" title=\"Delete {{item.name}}\">\n" +
    "                                <i class=\"fa fa-times-circle\"></i>\n" +
    "                            </a>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <ul>\n" +
    "                <li ng-repeat=\"image in images\">\n" +
    "                    <a ng-click=\"openLightboxModal($index)\">\n" +
    "                        <img ng-src=\"{{image.thumbUrl}}\" class=\"img-thumbnail\">\n" +
    "                    </a>\n" +
    "                </li>\n" +
    "            </ul>\n" +
    "        </div>\n" +
    "\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/components/profiles/tu/media/subtabs/socialMedia/tuProfileMediaSocialMediaSidebarView.html',
    "<div class=\"ibox\" ng-class=\"getSelected() === 3 ? 'selected' : 'collapsed'\" ng-controller=\"TuMediaSocialMediasSidebarController as TuMediaSocialMediasSidebarController\">\n" +
    "    <div class=\"ibox-title clickable\" ng-click=\"setSelected(3)\">\n" +
    "        <h5>Social Media</h5>\n" +
    "        <div class=\"ibox-tools\">\n" +
    "            <a>\n" +
    "                <i class=\"fa fa-chevron-up\"></i>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"ibox-content\">\n" +
    "        <p class=\"bold text-capitalize\">{{type()}}</p>\n" +
    "        \n" +
    "        <div class=\"addForm\">\n" +
    "            <form class=\"form-horizontal\" name=\"forms.formSocialMedia\" id=\"form-social-media\" novalidate>\n" +
    "                <div class=\"alert alert-info\">\n" +
    "                    <p>\n" +
    "                        <i class=\"fa fa-info-circle\"></i>\n" +
    "                        <span>Please add your</span>\n" +
    "                        <span class=\"text-capitalize\">{{type() === \"other\" ? \"Website\" : type()}}</span>\n" +
    "                        <span>link, e.g.</span>\n" +
    "                        <span>{{TuMediaSocialMediasSidebarController.selectedSocialMediaUrl}}</span><br>\n" +
    "                    </p>\n" +
    "                </div>\n" +
    "\n" +
    "                <div ng-repeat=\"selectedSocialMedia in selectedSocialMedia[type()]\">\n" +
    "                    <div ng-show=\"selectedSocialMedia.display\">\n" +
    "                        <div class=\"form-group\">\n" +
    "                            <div class=\"col-sm-12\">\n" +
    "                                <div class=\"checkbox-inline i-checkbox\">\n" +
    "                                    <label>\n" +
    "                                        <input i-checkbox type=\"checkbox\" ng-change=\"checkboxChanged(selectedSocialMedia, $index, 'master')\" ng-click=\"resetInvalidCheckbox($index)\" name=\"overview\" id=\"social-media-overview-{{$index}}\" focus-if=\"getDisplayTypesValidation()\" ng-blur=\"setDisplayTypesValidation(false)\" focus-delay=\"250\" ng-model=\"selectedSocialMedia.master\" />\n" +
    "                                        <span>Overview</span>\n" +
    "                                    </label>\n" +
    "                                </div>\n" +
    "\n" +
    "                                <div class=\"checkbox-inline i-checkbox\">\n" +
    "                                    <label>\n" +
    "                                        <input i-checkbox type=\"checkbox\" ng-change=\"checkboxChanged(selectedSocialMedia, $index, 'ug')\" ng-click=\"resetInvalidCheckbox($index)\"  name=\"undergraduate\" id=\"social-media-undergraduate-{{$index}}\" ng-model=\"selectedSocialMedia.ug\" />\n" +
    "                                        <span>Undergraduate</span>\n" +
    "                                    </label>\n" +
    "                                </div>\n" +
    "\n" +
    "                                <div class=\"checkbox-inline i-checkbox\">\n" +
    "                                    <label>\n" +
    "                                        <input i-checkbox type=\"checkbox\" ng-change=\"checkboxChanged(selectedSocialMedia, $index, 'pg')\" ng-click=\"resetInvalidCheckbox($index)\"  name=\"postgraduate\" id=\"social-media-postgraduate-{{$index}}\" ng-model=\"selectedSocialMedia.pg\" />\n" +
    "                                        <span>Postgraduate</span>\n" +
    "                                    </label>\n" +
    "                                </div>\n" +
    "\n" +
    "                                <div ng-class=\"invalidCheckboxes[$index] ? 'has-errors' : ''\" >\n" +
    "                                    <span \n" +
    "                                        custom-popover popover-html=\"Assign social media to at least one of the three profiles\" \n" +
    "                                        popover-placement=\"left\" \n" +
    "                                        popover-trigger=\"manual\" \n" +
    "                                        popover-visibility=\"{{invalidCheckboxes[$index]}}\"></span>\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "\n" +
    "                        <div class=\"form-group\" ng-class=\"invalidFields[$index] ? 'has-errors' : ''\">\n" +
    "                            <label class=\"control-label col-sm-3 col-md-2\">\n" +
    "                                <span>URL *</span>\n" +
    "                            </label>\n" +
    "                            <div class=\"col-sm-9 col-md-10\">\n" +
    "                            <input class=\"form-control\"\n" +
    "                                   name=\"url{{$index}}\"\n" +
    "                                   type=\"url\"\n" +
    "                                   placeholder=\"http://\"\n" +
    "                                   focus-if=\"(isHighlighted && $index === 0) ? true : false\"\n" +
    "                                   focus-delay=\"250\"\n" +
    "                                   ng-pattern=\"TuMediaSocialMediasSidebarController.urlPattern\"\n" +
    "                                   ng-model=\"selectedSocialMedia.url\"\n" +
    "                                   ng-focus = \"resetInvalidField($index)\"\n" +
    "                                   ng-keyup=\"TuMediaSocialMediasSidebarController.onKeyUp($event, type(), $index)\"\n" +
    "                                   maxlength=\"2011\"\n" +
    "\n" +
    "                                   custom-popover\n" +
    "                                   popover-trigger = \"manual\"\n" +
    "                                   popover-visibility = \"{{invalidFields[$index]}}\"\n" +
    "                                   popover-html=\"Add a valid <span class='text-capitalize'>{{type() === 'other' ? 'Website' : type()}}</span> link\"\n" +
    "                                   popover-placement=\"left\"/>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                        <div class=\"separator dashed\"></div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group m-b-none\">\n" +
    "                    <div class=\"col-sm-12\">\n" +
    "                        <button class=\"btn btn-primary pull-right btn-float-fix\" ng-click=\"saveSocialMediaForm()\">\n" +
    "                            <i class=\"fa fa-check-circle\"></i>\n" +
    "                            <span>{{isEditMode(type()) ? 'Update' : 'Save'}}</span>\n" +
    "                        </button>\n" +
    "                        <button class=\"btn btn-default pull-right btn-float-fix\" ng-click=\"clearSocialMediaForm()\">\n" +
    "                            <i class=\"fa fa-ban\"></i>\n" +
    "                            <span>Clear</span>\n" +
    "                        </button>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </form>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tu/media/subtabs/socialMedia/tuProfileMediaSocialMediaView.html',
    "<div ng-controller=\"TuMediaSocialMediasController\">\n" +
    "    <h3 class=\"heading\">Social Media</h3>\n" +
    "    <div class=\"tab-body\">\n" +
    "        <div class=\"sort-filter\">\n" +
    "            <a class=\"btn btn-link btn-overview\" ng-click=\"filter(typeOverview())\" ng-class=\"type === typeOverview() ? 'active' : 'inactive'\">\n" +
    "                <i class=\"fa fa-book\"></i>\n" +
    "                <span>Overview</span>\n" +
    "            </a> | \n" +
    "            <a class=\"btn btn-link btn-university\" ng-click=\"filter(typeUndergraduate())\" ng-class=\"type === typeUndergraduate() ? 'active' : 'inactive'\">\n" +
    "                <i class=\"fa fa-university\"></i>\n" +
    "                <span>Undergraduate</span>\n" +
    "            </a> | \n" +
    "            <a class=\"btn btn-link btn-graduation\" ng-click=\"filter(typePostgraduate())\" ng-class=\"type === typePostgraduate() ? 'active' : 'inactive'\">\n" +
    "                <i class=\"fa fa-graduation-cap\"></i>\n" +
    "                <span>Postgraduate</span>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"gallery\">\n" +
    "            <div class=\"grid-view\" ng-repeat=\"(key, item) in socialMediaItems\" ng-class=\"type === key ? 'selected' : 'unselected'\" ng-click=\"selectSocialMedia(key)\">\n" +
    "                \n" +
    "                <div class=\"add-link\" ng-class=\"item.master || item.ug || item.pg ? 'hidden' : 'visible'\">\n" +
    "                    <i class=\"fa fa-plus\"></i>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"preview\" ng-class=\"item.master || item.ug || item.pg ? '' : 'overlay'\">\n" +
    "                    <img ng-src=\"/images/media/{{key}}-thumbnail.jpg\"/>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"info\">\n" +
    "                    <div class=\"title text-capitalize\">{{key}}</div>\n" +
    "                    <div class=\"types\">\n" +
    "                        <i class=\"fa fa-book\" ng-show=\"item.master\"></i>\n" +
    "                        <i class=\"fa fa-university\" ng-show=\"item.ug\"></i>\n" +
    "                        <i class=\"fa fa-graduation-cap\" ng-show=\"item.pg\"></i>\n" +
    "                    </div>\n" +
    "                    <div class=\"actions hidden\">\n" +
    "                        <a href=\"{{item.url}}\" target=\"_blank\" title=\"View {{item.name}}\">\n" +
    "                            <i class=\"fa fa-search\"></i>\n" +
    "                        </a>\n" +
    "                        <a ng-click=\"deleteVideo(item)\" title=\"Delete {{item.name}}\">\n" +
    "                            <i class=\"fa fa-times-circle\"></i>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tu/media/subtabs/videos/tuProfileMediaVideosSidebarView.html',
    "<div class=\"ibox\" ng-class=\"getSelected() === 2 ? 'selected' : 'collapsed'\" ng-controller=\"TuMediaVideosSidebarController as TuMediaVideosSidebarController\">\n" +
    "    <div class=\"ibox-title clickable\" ng-click=\"setSelected(2)\">\n" +
    "        <h5>Videos ({{getVideoItems().length}})</h5>\n" +
    "        <div class=\"ibox-tools\">\n" +
    "            <a>\n" +
    "                <i class=\"fa fa-chevron-up\"></i>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"ibox-content\">\n" +
    "        <div class=\"addForm\">\n" +
    "            <form id=\"form-video\" class=\"form-horizontal\" name=\"forms.formVideo\" novalidate>\n" +
    "                <div class=\"alert alert-info\">\n" +
    "                    <p>\n" +
    "                        <i class=\"fa fa-info-circle\"></i>\n" +
    "                        <span>Please add a YouTube link. Shortened video links with .be extension are not allowed.</span><br />\n" +
    "                        <span>Video title & description will be retrieved from YouTube.</span><br />\n" +
    "                        <span>Drag and drop videos from left to right to change the order.</span>\n" +
    "                    </p>\n" +
    "                </div>\n" +
    "                <div ng-class=\"getHasErrors()? 'has-errors' : ''\">\n" +
    "                    <div class=\"form-group\">\n" +
    "                        <div class=\"col-sm-12\" use-tu-subtypes>\n" +
    "                            <div class=\"checkbox-inline i-checkbox\">\n" +
    "                                <label for=\"overviewImage\">\n" +
    "                                    <input i-checkbox type=\"checkbox\" name=\"overview\" id=\"overviewImage\" focus-if=\"getDisplayTypesValidation()\" focus-delay=\"250\" ng-model=\"selectedVideo.master\" ng-change=\"selectedVideo.master? setDisplayTypesValidation(false) : ''\"/>\n" +
    "                                    <span>Overview</span>\n" +
    "                                </label>\n" +
    "                            </div>\n" +
    "\n" +
    "                            <div class=\"checkbox-inline i-checkbox\">\n" +
    "                                <label for=\"undergraduateImage\">\n" +
    "                                    <input i-checkbox type=\"checkbox\" name=\"undergraduate\" id=\"undergraduateImage\" ng-model=\"selectedVideo.ug\" ng-change=\"selectedVideo.ug? setDisplayTypesValidation(false) : ''\" />\n" +
    "                                    <span>Undergraduate</span>\n" +
    "                                </label>\n" +
    "                            </div>\n" +
    "                            \n" +
    "                            <div class=\"checkbox-inline i-checkbox\">\n" +
    "                                <label for=\"postgraduateImage\">\n" +
    "                                    <input i-checkbox type=\"checkbox\" name=\"postgraduate\" id=\"postgraduateImage\" ng-model=\"selectedVideo.pg\" ng-change=\"selectedVideo.pg? setDisplayTypesValidation(false) : ''\"/>\n" +
    "                                    <span>Postgraduate</span>\n" +
    "                                </label>\n" +
    "                            </div>\n" +
    "\n" +
    "                            <div ng-class=\"getDisplayTypesValidation()? 'has-errors' : ''\" >\n" +
    "                                <span \n" +
    "                                    custom-popover popover-html=\"Assign video to at least one of the three profiles\"\n" +
    "                                    popover-placement=\"left\"\n" +
    "                                    popover-trigger=\"manual\"\n" +
    "                                    popover-visibility=\"{{getDisplayTypesValidation() ? true : false}}\"></span>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "\n" +
    "                    <div class=\"form-group\" ng-class=\"{'has-errors': getHasErrors()}\">\n" +
    "                        <label class=\"control-label col-sm-3 col-md-2\">\n" +
    "                            <span>URL *</span>\n" +
    "                        </label>\n" +
    "                        <div class=\"col-sm-9 col-md-10\">\n" +
    "                            <input class=\"form-control\"\n" +
    "                                   name=\"url\"\n" +
    "                                   type=\"url\"\n" +
    "                                   placeholder=\"http://\"\n" +
    "                                   ng-required=\"required\"\n" +
    "                                   focus-if=\"isHighlighted\"\n" +
    "                                   focus-delay=\"250\"\n" +
    "                                   ng-model=\"selectedVideo.url\"\n" +
    "                                   ng-pattern=\"youtubeUrlPattern\"\n" +
    "                                   ng-keyup=\"TuMediaVideosSidebarController.onKeyUp($event)\"\n" +
    "                                   ng-focus=\"setHasErrors(false)\"\n" +
    "\n" +
    "                                   custom-popover\n" +
    "                                   popover-html=\"Add a valid YouTube link\"\n" +
    "                                   popover-placement=\"left\"\n" +
    "                                   popover-trigger=\"manual\"\n" +
    "                                   popover-visibility=\"{{getHasErrors() ? true : false}}\"/>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    \n" +
    "                    <div class=\"form-group m-b-none\">\n" +
    "                        <div class=\"col-sm-12\">\n" +
    "                            <button class=\"btn btn-primary pull-right btn-float-fix\" ng-click=\"saveVideoForm(selectedVideo)\">\n" +
    "                                <i class=\"fa fa-check-circle\"></i>\n" +
    "                                <span>{{isEditMode() ? 'Update' : 'Save'}}</span>\n" +
    "                            </button>\n" +
    "                             <button class=\"btn btn-default pull-right btn-float-fix\" ng-click=\"clearVideoForm(selectedVideo)\">\n" +
    "                                <i class=\"fa fa-ban\"></i>\n" +
    "                                <span>Clear</span>\n" +
    "                            </button>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </form>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tu/media/subtabs/videos/tuProfileMediaVideosView.html',
    "<div ng-controller=\"TuMediaVideosController\">\n" +
    "    <h3 class=\"heading\">Videos ({{videoItems.length}})</h3>\n" +
    "    <div class=\"tab-body\">\n" +
    "        <div class=\"sort-filter\" use-tu-subtypes>\n" +
    "            <a class=\"btn btn-link btn-overview\" ng-click=\"filter(typeOverview())\" ng-class=\"type === typeOverview() ? 'active' : 'inactive'\">\n" +
    "                <i class=\"fa fa-book\"></i>\n" +
    "                <span>Overview ({{(videoItems | filter:{master:true}).length}})</span>\n" +
    "            </a> |\n" +
    "            <a class=\"btn btn-link btn-university\" ng-click=\"filter(typeUndergraduate())\" ng-class=\"type === typeUndergraduate() ? 'active' : 'inactive'\">\n" +
    "                <i class=\"fa fa-university\"></i>\n" +
    "                <span>Undergraduate ({{(videoItems | filter:{ug:true}).length}})</span>\n" +
    "            </a> |\n" +
    "            <a class=\"btn btn-link btn-graduation\" ng-click=\"filter(typePostgraduate())\" ng-class=\"type === typePostgraduate() ? 'active' : 'inactive'\">\n" +
    "                <i class=\"fa fa-graduation-cap\"></i>\n" +
    "                <span>Postgraduate ({{(videoItems | filter:{pg:true}).length}})</span>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"gallery\">\n" +
    "            <div class=\"grid-view upload\" ng-class=\"selectedItem().id === '' ? 'selected' : 'unselected'\" ng-click=\"selectVideo()\" ng-click=\"selectVideo()\">\n" +
    "                <div class=\"source-link\">\n" +
    "                    <i class=\"fa fa-plus\"></i>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div ui-sortable=\"sortableOptions\" ng-model=\"filteredVideoItems\">\n" +
    "                <div class=\"grid-view\" ng-class=\"selectedItem().id === item.id ? 'selected' : 'unselected'\" ng-click=\"selectVideo(item)\" ng-repeat=\"item in filteredVideoItems\">\n" +
    "                    \n" +
    "                    <div class=\"preview\" ng-class=\"item.imageUrl ? '' : 'overlay' \">\n" +
    "                        <img ng-src=\"{{item.imageUrl || '/images/media/youtube-thumbnail.jpg'}}\"/>\n" +
    "                    </div>\n" +
    "                    \n" +
    "                    <div class=\"info\">\n" +
    "                        <div class=\"title\" item-order=\"{{item['orderType'][type]}}\">\n" +
    "                            {{item.name || 'Retrieving title...' }}\n" +
    "                        </div>\n" +
    "                        <div class=\"types\" use-tu-subtypes>\n" +
    "                            <i class=\"fa fa-book\" ng-show=\"item.master\"></i>\n" +
    "                            <i class=\"fa fa-university\" ng-show=\"item.ug\"></i>\n" +
    "                            <i class=\"fa fa-graduation-cap\" ng-show=\"item.pg\"></i>\n" +
    "                        </div>\n" +
    "                        <div class=\"actions\">\n" +
    "                            <a href=\"{{item.url}}\" target=\"_blank\" title=\"View {{item.name}}\">\n" +
    "                                <i class=\"fa fa-search\"></i>\n" +
    "                            </a>\n" +
    "                            <a ng-click=\"deleteVideo(item)\" title=\"Delete {{item.name}}\">\n" +
    "                                <i class=\"fa fa-times-circle\"></i>\n" +
    "                            </a>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/components/profiles/tu/media/tuProfileMediaLinkManagerView.html',
    "<div class=\"nested-ibox\" ng-hide=\"showUpgradeForm\" ng-controller=\"TuMediaCommonSidebarController\">\n" +
    "    <div class=\"ibox-title m-t-xl\">\n" +
    "        <h4>Media Manager ({{getGeneralCounter()}})</h4>\n" +
    "    </div>\n" +
    "    <div class=\"ibox\">\n" +
    "        <div class=\"ibox-content\">\n" +
    "            <div include-replace ng-include src=\"'/scripts/components/profiles/tu/media/subtabs/images/tuProfileMediaImagesSidebarView.html'\"></div>\n" +
    "            <div include-replace ng-include src=\"'/scripts/components/profiles/tu/media/subtabs/videos/tuProfileMediaVideosSidebarView.html'\"></div>\n" +
    "            <div include-replace ng-include src=\"'/scripts/components/profiles/tu/media/subtabs/socialMedia/tuProfileMediaSocialMediaSidebarView.html'\"></div>\n" +
    "            <div ng-if=\"!crimsonIsAdvanced\" include-replace ng-include src=\"'/scripts/components/profiles/tu/media/subtabs/brochures/tuProfileMediaBrochuresSidebarView.html'\"></div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tu/media/tuProfileMediaView.html',
    "<div ng-controller=\"TuProfileMediaController as TuProfileMediaController\">\n" +
    "    <div upgrade-banner\n" +
    "         info-block-class=\"isRightSidePanelActive() ? 'col-lg-9' : 'col-lg-10'\"\n" +
    "         buttons-block-class=\"isRightSidePanelActive() ? 'col-lg-3' : 'col-lg-2'\"\n" +
    "         basic-profile=\"!tuIsAdvanced && !crimsonIsAdvanced ? true : false\"\n" +
    "         tm-profile=\"false\"\n" +
    "         upgrade-email=\"{{upgradeEmailsTo}}\"\n" +
    "         upgrade-click=\"toggleUpgradeForm()\"></div>\n" +
    "\n" +
    "    <div ng-class=\"tuIsAdvanced || crimsonIsAdvanced? '': 'modal-overlay-35'\">\n" +
    "        <div wave-spinner class=\"wave-spinner\" ng-show=\"isMediaReloading\"></div>\n" +
    "        <div ng-if=\"!isMediaReloading\">\n" +
    "            <div ng-show=\"TuProfileMediaController.isImagesTabActive()\" ng-include src=\"'/scripts/components/profiles/tu/media/subtabs/images/tuProfileMediaImagesView.html'\"></div>\n" +
    "            <div ng-show=\"TuProfileMediaController.isVideosTabActive()\" ng-include src=\"'/scripts/components/profiles/tu/media/subtabs/videos/tuProfileMediaVideosView.html'\"></div>\n" +
    "            <div ng-show=\"TuProfileMediaController.isSocialMediaTabActive()\" ng-include src=\"'/scripts/components/profiles/tu/media/subtabs/socialMedia/tuProfileMediaSocialMediaView.html'\"></div>\n" +
    "            <div ng-show=\"TuProfileMediaController.isBrochuresTabActive()\" ng-include src=\"'/scripts/components/profiles/tu/media/subtabs/brochures/tuProfileMediaBrochuresView.html'\"></div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tu/overview/partial/advancedMasterSubTab.html',
    "<form name=\"forms.overviewAdvancedMasterForm\" enctype=\"multipart/form-data\">\n" +
    "\n" +
    "    <div class=\"row\">\n" +
    "        <div class=\"col-sm-12\">\n" +
    "            <div class=\"tab-header\">\n" +
    "                <h3 class=\"heading\">About *</h3>\n" +
    "                <a class=\"btn btn-warning btn-history\" ng-show=\"!historyDisabled\" ng-click=\"handleTuOverviewHistoryLogClick('master', true)\">\n" +
    "                    <i class=\"fa fa-clock-o\"></i>\n" +
    "                </a>\n" +
    "                <div wave-spinner class=\"text-right\" ng-show=\"historyDisabled\"></div>\n" +
    "            </div>\n" +
    "            <div class=\"summernote-container\" ng-class=\"{'has-errors': isAdvancedOverviewEmpty}\">\n" +
    "                <summernote\n" +
    "                    config=\"TuProfileOverviewController.textEditorAdvancedOptions\"\n" +
    "                    ng-model=\"tuProfile.advancedMasterOverview\"\n" +
    "                    on-focus=\"TuProfileOverviewController.setOverviewInvalid('advancedOverview')\"\n" +
    "                    on-change=\"TuProfileOverviewController.advancedMasterOverviewWords = countWords(contents)\"\n" +
    "                    on-init=\"TuProfileOverviewController.advancedMasterOverviewWords = countWords(tuProfile.advancedMasterOverview)\"\n" +
    "                    on-paste=\"TuProfileOverviewController.handleClipboardPaste('advancedMasterOverview')\"\n" +
    "                    count-word=\"TuProfileOverviewController.advancedMasterOverviewWords\">\n" +
    "                </summernote>\n" +
    "            </div>\n" +
    "            <div class=\"editor-note form-group\">\n" +
    "                <span ng-class=\"{'text-red': TuProfileOverviewController.isAdvancedSectionInvalid(TuProfileOverviewController.advancedMasterOverviewWords)}\">\n" +
    "                    <span class=\"bold\">{{TuProfileOverviewController.advancedMasterOverviewWords}}</span>\n" +
    "                    <span>words inserted</span>\n" +
    "                </span>\n" +
    "                <span class=\"pull-right\">Maximum {{TuProfileOverviewController.advancedDescriptionWordLimit}} words</span>\n" +
    "\n" +
    "                <div\n" +
    "                    focus-delay=\"250\"\n" +
    "                    custom-popover\n" +
    "                    popover-html=\"Add a general description about your university\"\n" +
    "                    popover-placement=\"left\"\n" +
    "                    popover-trigger=\"manual\"\n" +
    "                    popover-visibility=\"{{isAdvancedOverviewEmpty ? true : false}}\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row\">\n" +
    "        <div class=\"col-sm-12\">\n" +
    "            <div class=\"tab-header\">\n" +
    "                <h3 class=\"heading\">Facilities</h3>\n" +
    "            </div>\n" +
    "            <div class=\"summernote-container\">\n" +
    "                <summernote\n" +
    "                    config=\"TuProfileOverviewController.textEditorAdvancedOptions\"\n" +
    "                    ng-model=\"tuProfile.advancedMasterFacilities\"\n" +
    "                    on-change=\"TuProfileOverviewController.advancedMasterFacilitiesWords = countWords(contents)\"\n" +
    "                    on-init=\"TuProfileOverviewController.advancedMasterFacilitiesWords = countWords(tuProfile.advancedMasterFacilities)\"\n" +
    "                    on-paste=\"TuProfileOverviewController.handleClipboardPaste('advancedMasterFacilities')\"\n" +
    "                    count-word=\"TuProfileOverviewController.advancedMasterFacilitiesWords\">\n" +
    "                </summernote>\n" +
    "            </div>\n" +
    "            <div class=\"editor-note form-group\">\n" +
    "                <span ng-class=\"{'text-red': TuProfileOverviewController.isAdvancedSectionInvalid(TuProfileOverviewController.advancedMasterFacilitiesWords)}\">\n" +
    "                    <span class=\"bold\">{{TuProfileOverviewController.advancedMasterFacilitiesWords}}</span>\n" +
    "                    <span>words inserted</span>\n" +
    "                </span>\n" +
    "                <span class=\"pull-right\">Maximum {{TuProfileOverviewController.advancedDescriptionWordLimit}} words</span>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row\">\n" +
    "        <div class=\"col-sm-12\">\n" +
    "            <div class=\"tab-header\">\n" +
    "                <h3 class=\"heading\">Student Life</h3>\n" +
    "            </div>\n" +
    "            <div class=\"summernote-container\">\n" +
    "                <summernote\n" +
    "                    config=\"TuProfileOverviewController.textEditorAdvancedOptions\"\n" +
    "                    ng-model=\"tuProfile.advancedMasterStudentLife\"\n" +
    "                    on-change=\"TuProfileOverviewController.advancedMasterStudentLifeWords = countWords(contents)\"\n" +
    "                    on-init=\"TuProfileOverviewController.advancedMasterStudentLifeWords = countWords(tuProfile.advancedMasterStudentLife)\"\n" +
    "                    on-paste=\"TuProfileOverviewController.handleClipboardPaste('advancedMasterStudentLife')\"\n" +
    "                    count-word=\"TuProfileOverviewController.advancedMasterStudentLifeWords\">\n" +
    "                </summernote>\n" +
    "            </div>\n" +
    "            <div class=\"editor-note form-group\">\n" +
    "                <span ng-class=\"{'text-red': TuProfileOverviewController.isAdvancedSectionInvalid(TuProfileOverviewController.advancedMasterStudentLifeWords)}\">\n" +
    "                    <span class=\"bold\">{{TuProfileOverviewController.advancedMasterStudentLifeWords}}</span>\n" +
    "                    <span>words inserted</span>\n" +
    "                </span>\n" +
    "                <span class=\"pull-right\">Maximum {{TuProfileOverviewController.advancedDescriptionWordLimit}} words</span>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row\">\n" +
    "        <div class=\"col-sm-12\">\n" +
    "            <div class=\"tab-header\">\n" +
    "                <h3 class=\"heading\">Admissions</h3>\n" +
    "            </div>\n" +
    "            <div class=\"summernote-container\">\n" +
    "                <summernote\n" +
    "                    config=\"TuProfileOverviewController.textEditorAdvancedOptions\"\n" +
    "                    ng-model=\"tuProfile.advancedMasterAdmissions\"\n" +
    "                    on-change=\"TuProfileOverviewController.advancedMasterAdmissionsWords = countWords(contents)\"\n" +
    "                    on-init=\"TuProfileOverviewController.advancedMasterAdmissionsWords = countWords(tuProfile.advancedMasterAdmissions)\"\n" +
    "                    on-paste=\"TuProfileOverviewController.handleClipboardPaste('advancedMasterAdmissions')\"\n" +
    "                    count-word=\"TuProfileOverviewController.advancedMasterAdmissionsWords\">\n" +
    "                </summernote>\n" +
    "            </div>\n" +
    "            <div class=\"editor-note form-group\">\n" +
    "                <span ng-class=\"{'text-red': TuProfileOverviewController.isAdvancedSectionInvalid(TuProfileOverviewController.advancedMasterAdmissionsWords)}\">\n" +
    "                    <span class=\"bold\">{{TuProfileOverviewController.advancedMasterAdmissionsWords}}</span>\n" +
    "                    <span>words inserted</span>\n" +
    "                </span>\n" +
    "                <span class=\"pull-right\">Maximum {{TuProfileOverviewController.advancedDescriptionWordLimit}} words</span>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row\">\n" +
    "        <div class=\"col-sm-12\">\n" +
    "            <div class=\"tab-header\">\n" +
    "                <h3 class=\"heading\">Careers</h3>\n" +
    "            </div>\n" +
    "            <div class=\"summernote-container\">\n" +
    "                <summernote\n" +
    "                    config=\"TuProfileOverviewController.textEditorAdvancedOptions\"\n" +
    "                    ng-model=\"tuProfile.advancedMasterCareers\"\n" +
    "                    on-change=\"TuProfileOverviewController.advancedMasterCareersWords = countWords(contents)\"\n" +
    "                    on-init=\"TuProfileOverviewController.advancedMasterCareersWords = countWords(tuProfile.advancedMasterCareers)\"\n" +
    "                    on-paste=\"TuProfileOverviewController.handleClipboardPaste('advancedMasterCareers')\"\n" +
    "                    count-word=\"TuProfileOverviewController.advancedMasterCareersWords\">\n" +
    "                </summernote>\n" +
    "            </div>\n" +
    "            <div class=\"editor-note form-group\">\n" +
    "                <span ng-class=\"{'text-red': TuProfileOverviewController.isAdvancedSectionInvalid(TuProfileOverviewController.advancedMasterCareersWords)}\">\n" +
    "                    <span class=\"bold\">{{TuProfileOverviewController.advancedMasterCareersWords}}</span>\n" +
    "                    <span>words inserted</span>\n" +
    "                </span>\n" +
    "                <span class=\"pull-right\">Maximum {{TuProfileOverviewController.advancedDescriptionWordLimit}} words</span>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"separator\"></div>\n" +
    "\n" +
    "    <div  class=\"row\">\n" +
    "        <div class=\"form-group\" ng-class=\"TuProfileOverviewController.isClient ? 'col-lg-6' : 'col-lg-4'\">\n" +
    "            <div>\n" +
    "                <h3>University Website</h3>\n" +
    "            </div>\n" +
    "            <input type=\"text\"\n" +
    "                placeholder=\"http://\"\n" +
    "                ng-model=\"tuProfile.masterWebsiteUrl\"\n" +
    "                ng-keyup=\"TuProfileOverviewController.onKeyUp($event)\"\n" +
    "                class=\"form-control\" />\n" +
    "        </div>\n" +
    "        <div class=\"form-group\" ng-class=\"TuProfileOverviewController.isClient ? 'col-lg-6' : 'col-lg-4'\">\n" +
    "            <div class=\"display-inline-request-info margin-right-30\">\n" +
    "                <h3 class=\"inline\">Get in touch</h3>\n" +
    "                <span ng-if=\"TuProfileOverviewController.isRequestInfoTypeMasterUrl()\"\n" +
    "                        class=\"small dfn text-left text-nowrap\"> (please select one) </span>\n" +
    "            </div>\n" +
    "            <div ng-if=\"TuProfileOverviewController.isRequestInfoTypeMasterUrl()\" class=\"display-inline-request-info request-info-title\">\n" +
    "                <div class=\"display-inline-request-info\">\n" +
    "                    <input i-checkbox type=\"radio\" value=\"email\" ng-model=\"tuProfile.requestInfoTypeMaster\"> Email\n" +
    "                </div>\n" +
    "                <span class=\"or-checkboxes-request-info\">OR</span>\n" +
    "                <div class=\"display-inline-request-info\">\n" +
    "                    <input i-checkbox type=\"radio\" value=\"url\" ng-model=\"tuProfile.requestInfoTypeMaster\"> URL\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div ng-show=\"tuProfile.requestInfoTypeMaster === 'url'\" class=\"url-option\">\n" +
    "                <div class=\"form-group\">\n" +
    "                    <input ng-disabled=\"true\"\n" +
    "                        type=\"text\"\n" +
    "                        placeholder=\"URL\"\n" +
    "                        ng-model=\"tuProfile.masterRequestInfoUrl\"\n" +
    "                        ng-keyup=\"TuProfileOverviewController.onKeyUp($event)\"\n" +
    "                        class=\"form-control\" />\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div ng-show=\"!tuProfile.requestInfoTypeMaster || tuProfile.requestInfoTypeMaster === 'email'\" class=\"email-option\">\n" +
    "                <div class=\"form-group\">\n" +
    "                    <div class=\"form-group\" ng-class=\"{'has-errors': !TuProfileOverviewController.isValidRequestInfoEmail('masterRequestInfoEmail') && tuProfile.masterRequestInfoEmail !== ''}\">\n" +
    "                        <input\n" +
    "                            type=\"email\"\n" +
    "                            placeholder=\"Email\"\n" +
    "                            name=\"masterRequestInfoEmail\"\n" +
    "                            ng-model=\"tuProfile.masterRequestInfoEmail\"\n" +
    "                            class=\"form-control\"\n" +
    "                            ng-focus=\"TuProfileOverviewController.setValid('masterRequestInfoEmail')\"\n" +
    "                            custom-popover\n" +
    "                            popover-html=\"Add an email\"\n" +
    "                            popover-placement=\"left\"\n" +
    "                            popover-trigger=\"manual\"\n" +
    "                            popover-visibility=\"{{!TuProfileOverviewController.isValidRequestInfoEmail('masterRequestInfoEmail') && tuProfile.masterRequestInfoEmail !== ''}}\" >\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div ng-if=\"TuProfileOverviewController.isRequestInfoTypeMasterUrl()\" ng-include src=\"'/scripts/components/profiles/tu/overview/partial/urlBanner.html'\"></div>\n" +
    "        </div>\n" +
    "        <div class=\"col-lg-4 form-group\" ng-if=\"!TuProfileOverviewController.isClient\" ng-class=\"{'has-errors': forms.overviewAdvancedMasterForm.mtLeadGenFormEmail.$invalid && tuProfile.mtLeadGenFormEmail !== ''}\">\n" +
    "            <h3>MT Lead gen form email</h3>\n" +
    "            <input type=\"email\"\n" +
    "                placeholder=\"Email\"\n" +
    "                name=\"mtLeadGenFormEmail\"\n" +
    "                ng-model=\"tuProfile.mtLeadGenFormEmail\"\n" +
    "                ng-focus=\"TuProfileOverviewController.setValid('mtLeadGenFormEmail')\"\n" +
    "                class=\"form-control\"\n" +
    "                custom-popover\n" +
    "                popover-html=\"Add an email\"\n" +
    "                popover-placement=\"left\"\n" +
    "                popover-trigger=\"manual\"\n" +
    "                popover-visibility=\"{{forms.overviewAdvancedMasterForm.mtLeadGenFormEmail.$invalid && tuProfile.mtLeadGenFormEmail !== ''}}\" />\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"tab-footer\">\n" +
    "            <a class=\"btn btn-primary pull-right m-r\"\n" +
    "                ng-class=\"{'disabled': overviewAdvancedMasterFormSubmitInProgress}\"\n" +
    "                ng-click=\"handleOverviewAdvancedMasterDataSubmit()\">\n" +
    "                <i class=\"fa fa-check-circle\"></i>\n" +
    "                <span>Update</span>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</form>\n"
  );


  $templateCache.put('/scripts/components/profiles/tu/overview/partial/advancedPgSubTab.html',
    "<form name=\"forms.overviewAdvancedPgForm\" enctype=\"multipart/form-data\">\n" +
    "    <div class=\"row\">\n" +
    "        <div class=\"col-sm-12\">\n" +
    "            <div class=\"tab-header\">\n" +
    "                    <h3 class=\"heading\">Advanced Description *</h3>\n" +
    "                    <a class=\"btn btn-warning btn-history\" ng-show=\"!historyDisabled\" ng-click=\"handleTuOverviewHistoryLogClick('pg', true)\">\n" +
    "                        <i class=\"fa fa-clock-o\"></i>\n" +
    "                    </a>\n" +
    "                    <div wave-spinner=\"\" class=\"text-right\" ng-show=\"historyDisabled\"></div>\n" +
    "            </div>\n" +
    "            <div class=\"tab-body\">\n" +
    "                <div class=\"summernote-container\" ng-class=\"{'has-errors': isAdvancedPgOverviewEmpty}\">\n" +
    "                    <summernote\n" +
    "                        config=\"TuProfileOverviewController.textEditorAdvancedOptions\"\n" +
    "                        ng-model=\"tuProfile.advancedPgOverview\"\n" +
    "                        on-focus=\"TuProfileOverviewController.setOverviewInvalid('advancedPgOverview')\"\n" +
    "                        on-change=\"TuProfileOverviewController.advancedPgOverviewWords = countWords(contents)\"\n" +
    "                        on-init=\"TuProfileOverviewController.advancedPgOverviewWords = countWords(tuProfile.advancedPgOverview)\"\n" +
    "                        on-paste=\"TuProfileOverviewController.handleClipboardPaste('advancedPgOverview')\"\n" +
    "                        count-word=\"TuProfileOverviewController.advancedPgOverviewWords\">\n" +
    "                    </summernote>\n" +
    "                </div>\n" +
    "        \n" +
    "                <div class=\"editor-note form-group\">\n" +
    "                    <span ng-class=\"{'text-red': TuProfileOverviewController.isAdvancedSectionInvalid(TuProfileOverviewController.advancedPgOverviewWords)}\">\n" +
    "                        <span class=\"bold\">{{TuProfileOverviewController.advancedPgOverviewWords}}</span>\n" +
    "                        <span>words inserted</span>\n" +
    "                    </span>\n" +
    "                    <span class=\"pull-right\">Maximum {{TuProfileOverviewController.advancedDescriptionWordLimit}} words</span>\n" +
    "                    <div\n" +
    "                        focus-delay=\"250\"\n" +
    "                        custom-popover\n" +
    "                        popover-html=\"Add a general description about the Postgraduate Courses you offer\"\n" +
    "                        popover-placement=\"left\"\n" +
    "                        popover-trigger=\"manual\"\n" +
    "                        popover-visibility=\"{{isAdvancedPgOverviewEmpty ? true : false}}\">\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row\">\n" +
    "            <div class=\"col-sm-12\">\n" +
    "                <div class=\"tab-header\">\n" +
    "                    <h3 class=\"heading\">Facilities (Only for Qschina.cn)</h3>\n" +
    "                </div>\n" +
    "                <div class=\"summernote-container\">\n" +
    "                    <summernote\n" +
    "                        config=\"TuProfileOverviewController.textEditorAdvancedOptions\"\n" +
    "                        ng-model=\"tuProfile.advancedPgFacilities\"\n" +
    "                        on-change=\"TuProfileOverviewController.advancedPgFacilitiesWords = countWords(contents)\"\n" +
    "                        on-init=\"TuProfileOverviewController.advancedPgFacilitiesWords = countWords(tuProfile.advancedPgFacilities)\"\n" +
    "                        on-paste=\"TuProfileOverviewController.handleClipboardPaste('advancedPgFacilities')\"\n" +
    "                        count-word=\"TuProfileOverviewController.advancedPgFacilitiesWords\">\n" +
    "                    </summernote>\n" +
    "                </div>\n" +
    "                <div class=\"editor-note form-group\">\n" +
    "                    <span ng-class=\"{'text-red': TuProfileOverviewController.isAdvancedSectionInvalid(TuProfileOverviewController.advancedPgFacilitiesWords)}\">\n" +
    "                        <span class=\"bold\">{{TuProfileOverviewController.advancedPgFacilitiesWords}}</span>\n" +
    "                        <span>words inserted</span>\n" +
    "                    </span>\n" +
    "                    <span class=\"pull-right\">Maximum {{TuProfileOverviewController.advancedDescriptionWordLimit}} words</span>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row\">\n" +
    "        <div class=\"col-sm-12\">\n" +
    "            <div class=\"tab-header\">\n" +
    "                <h3 class=\"heading\">Student Life (Only for Qschina.cn)</h3>\n" +
    "            </div>\n" +
    "            <div class=\"summernote-container\">\n" +
    "                <summernote\n" +
    "                    config=\"TuProfileOverviewController.textEditorAdvancedOptions\"\n" +
    "                    ng-model=\"tuProfile.advancedPgStudentLife\"\n" +
    "                    on-change=\"TuProfileOverviewController.advancedPgStudentLifeWords = countWords(contents)\"\n" +
    "                    on-init=\"TuProfileOverviewController.advancedPgStudentLifeWords = countWords(tuProfile.advancedPgStudentLife)\"\n" +
    "                    on-paste=\"TuProfileOverviewController.handleClipboardPaste('advancedPgStudentLife')\"\n" +
    "                    count-word=\"TuProfileOverviewController.advancedPgStudentLifeWords\">\n" +
    "                </summernote>\n" +
    "            </div>\n" +
    "            <div class=\"editor-note form-group\">\n" +
    "                <span ng-class=\"{'text-red': TuProfileOverviewController.isAdvancedSectionInvalid(TuProfileOverviewController.advancedPgStudentLifeWords)}\">\n" +
    "                    <span class=\"bold\">{{TuProfileOverviewController.advancedPgStudentLifeWords}}</span>\n" +
    "                    <span>words inserted</span>\n" +
    "                </span>\n" +
    "                <span class=\"pull-right\">Maximum {{TuProfileOverviewController.advancedDescriptionWordLimit}} words</span>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row\">\n" +
    "        <div class=\"col-sm-12\">\n" +
    "            <div class=\"tab-header\">\n" +
    "                <h3 class=\"heading\">Admissions (Only for Qschina.cn)</h3>\n" +
    "            </div>\n" +
    "            <div class=\"summernote-container\">\n" +
    "                <summernote\n" +
    "                    config=\"TuProfileOverviewController.textEditorAdvancedOptions\"\n" +
    "                    ng-model=\"tuProfile.advancedPgAdmissions\"\n" +
    "                    on-change=\"TuProfileOverviewController.advancedPgAdmissionsWords = countWords(contents)\"\n" +
    "                    on-init=\"TuProfileOverviewController.advancedPgAdmissionsWords = countWords(tuProfile.advancedPgAdmissions)\"\n" +
    "                    on-paste=\"TuProfileOverviewController.handleClipboardPaste('advancedPgAdmissions')\"\n" +
    "                    count-word=\"TuProfileOverviewController.advancedPgAdmissionsWords\">\n" +
    "                </summernote>\n" +
    "            </div>\n" +
    "            <div class=\"editor-note form-group\">\n" +
    "                <span ng-class=\"{'text-red': TuProfileOverviewController.isAdvancedSectionInvalid(TuProfileOverviewController.advancedPgAdmissionsWords)}\">\n" +
    "                    <span class=\"bold\">{{TuProfileOverviewController.advancedPgAdmissionsWords}}</span>\n" +
    "                    <span>words inserted</span>\n" +
    "                </span>\n" +
    "                <span class=\"pull-right\">Maximum {{TuProfileOverviewController.advancedDescriptionWordLimit}} words</span>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row\">\n" +
    "        <div class=\"col-sm-12\">\n" +
    "            <div class=\"tab-header\">\n" +
    "                <h3 class=\"heading\">Careers (Only for Qschina.cn)</h3>\n" +
    "            </div>\n" +
    "            <div class=\"summernote-container\">\n" +
    "                <summernote\n" +
    "                    config=\"TuProfileOverviewController.textEditorAdvancedOptions\"\n" +
    "                    ng-model=\"tuProfile.advancedPgCareers\"\n" +
    "                    on-change=\"TuProfileOverviewController.advancedPgCareersWords = countWords(contents)\"\n" +
    "                    on-init=\"TuProfileOverviewController.advancedPgCareersWords = countWords(tuProfile.advancedPgCareers)\"\n" +
    "                    on-paste=\"TuProfileOverviewController.handleClipboardPaste('advancedPgCareers')\"\n" +
    "                    count-word=\"TuProfileOverviewController.advancedPgCareersWords\">\n" +
    "                </summernote>\n" +
    "            </div>\n" +
    "            <div class=\"editor-note form-group\">\n" +
    "                <span ng-class=\"{'text-red': TuProfileOverviewController.isAdvancedSectionInvalid(TuProfileOverviewController.advancedPgCareersWords)}\">\n" +
    "                    <span class=\"bold\">{{TuProfileOverviewController.advancedPgCareersWords}}</span>\n" +
    "                    <span>words inserted</span>\n" +
    "                </span>\n" +
    "                <span class=\"pull-right\">Maximum {{TuProfileOverviewController.advancedDescriptionWordLimit}} words</span>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"separator\"></div>\n" +
    "\n" +
    "    <div class=\"row\">\n" +
    "        <div class=\"col-lg-6 form-group\">\n" +
    "            <div>\n" +
    "                <h3>University Website</h3>\n" +
    "            </div>\n" +
    "            <input type=\"text\"\n" +
    "                    placeholder=\"http://\"\n" +
    "                    ng-model=\"tuProfile.pgWebsiteUrl\"\n" +
    "                    ng-keyup=\"TuProfileOverviewController.onKeyUp($event)\"\n" +
    "                    class=\"form-control\" />\n" +
    "        </div>\n" +
    "        <div class=\"col-lg-6 form-group\">\n" +
    "            <div class=\"display-inline-request-info margin-right-30\">\n" +
    "                <h3 class=\"inline\">Get in touch</h3>\n" +
    "                <span ng-if=\"TuProfileOverviewController.isRequestInfoTypePgUrl()\"\n" +
    "                        class=\"small dfn text-left text-nowrap\"> (please select one) </span>\n" +
    "            </div>\n" +
    "            <div ng-if=\"TuProfileOverviewController.isRequestInfoTypePgUrl()\" class=\"display-inline-request-info request-info-title\">\n" +
    "                <div class=\"display-inline-request-info\">\n" +
    "                    <input i-checkbox type=\"radio\" name=\"requestInfoOverview\" value=\"email\" ng-model=\"tuProfile.requestInfoTypePg\"> Email\n" +
    "                </div>\n" +
    "                <span class=\"or-checkboxes-request-info\">OR</span>\n" +
    "                <div class=\"display-inline-request-info\">\n" +
    "                    <input i-checkbox type=\"radio\" name=\"requestInfoOverview\" value=\"url\" ng-model=\"tuProfile.requestInfoTypePg\"> URL\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div ng-show=\"tuProfile.requestInfoTypePg === 'url'\" class=\"url-option\">\n" +
    "                <div class=\"form-group\">\n" +
    "                    <input ng-disabled=\"true\" type=\"text\"\n" +
    "                        name=\"tuProfile.pgRequestInfoUrl\"\n" +
    "                        placeholder=\"URL\"\n" +
    "                        ng-model=\"tuProfile.pgRequestInfoUrl\"\n" +
    "                        ng-keyup=\"TuProfileOverviewController.onKeyUp($event)\"\n" +
    "                        class=\"form-control\" />\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div ng-show=\"!tuProfile.requestInfoTypePg || tuProfile.requestInfoTypePg === 'email'\" class=\"email-option\">\n" +
    "                    <div class=\"form-group\" ng-class=\"{'has-errors': !TuProfileOverviewController.isValidRequestInfoEmail('pgRequestInfoEmail') && tuProfile.pgRequestInfoEmail !== ''}\">\n" +
    "                            <input\n" +
    "                            type=\"email\"\n" +
    "                            placeholder=\"Email\"\n" +
    "                            ng-model=\"tuProfile.pgRequestInfoEmail\"\n" +
    "                            class=\"form-control\"\n" +
    "                            ng-focus=\"TuProfileOverviewController.setValid('pgRequestInfoEmail')\"\n" +
    "                            custom-popover\n" +
    "                            popover-html=\"Add an email\"\n" +
    "                            popover-placement=\"left\"\n" +
    "                            popover-trigger=\"manual\"\n" +
    "                            popover-visibility=\"{{!TuProfileOverviewController.isValidRequestInfoEmail('pgRequestInfoEmail') && tuProfile.pgRequestInfoEmail !== ''}}\" />\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div ng-if=\"TuProfileOverviewController.isRequestInfoTypePgUrl()\" ng-include src=\"'/scripts/components/profiles/tu/overview/partial/urlBanner.html'\"></div>\n" +
    "        </div>\n" +
    "        <div class=\"tab-footer\">\n" +
    "            <a class=\"btn btn-primary pull-right m-r\"\n" +
    "                ng-class=\"{'disabled': overviewAdvancedPgFormSubmitInProgress}\"\n" +
    "                ng-click=\"handleOverviewAdvancedPgDataSubmit()\">\n" +
    "                <i class=\"fa fa-check-circle\"></i>\n" +
    "                <span>Update</span>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</form>"
  );


  $templateCache.put('/scripts/components/profiles/tu/overview/partial/advancedUgSubTab.html',
    "<form name=\"forms.overviewAdvancedUgForm\" enctype=\"multipart/form-data\">\n" +
    "    <div class=\"row\">\n" +
    "        <div class=\"col-sm-12\">\n" +
    "            <div class=\"tab-header\">\n" +
    "                    <h3 class=\"heading\">Advanced Description *</h3>\n" +
    "                    <a class=\"btn btn-warning btn-history\" ng-show=\"!historyDisabled\" ng-click=\"handleTuOverviewHistoryLogClick('ug', true)\">\n" +
    "                        <i class=\"fa fa-clock-o\"></i>\n" +
    "                    </a>\n" +
    "                    <div wave-spinner class=\"text-right\" ng-show=\"historyDisabled\"></div>\n" +
    "            </div>\n" +
    "            <div class=\"tab-body\">\n" +
    "                <div class=\"summernote-container\" ng-class=\"{'has-errors': isAdvancedUgOverviewEmpty}\">\n" +
    "                    <summernote\n" +
    "                        config=\"TuProfileOverviewController.textEditorAdvancedOptions\"\n" +
    "                        ng-model=\"tuProfile.advancedUgOverview\"\n" +
    "                        on-focus=\"TuProfileOverviewController.setOverviewInvalid('advancedUgOverview')\"\n" +
    "                        on-change=\"TuProfileOverviewController.advancedUgOverviewWords = countWords(contents)\"\n" +
    "                        on-init=\"TuProfileOverviewController.advancedUgOverviewWords = countWords(tuProfile.advancedUgOverview)\"\n" +
    "                        on-paste=\"TuProfileOverviewController.handleClipboardPaste('advancedUgOverview')\"\n" +
    "                        count-word=\"TuProfileOverviewController.advancedUgOverviewWords\">\n" +
    "                    </summernote>\n" +
    "                </div>\n" +
    "        \n" +
    "                <div class=\"editor-note form-group\">\n" +
    "                    <span ng-class=\"{'text-red': TuProfileOverviewController.isAdvancedSectionInvalid(TuProfileOverviewController.advancedUgOverviewWords)}\">\n" +
    "                        <span class=\"bold\">{{TuProfileOverviewController.advancedUgOverviewWords}}</span>\n" +
    "                        <span>words inserted</span>\n" +
    "                    </span>\n" +
    "                    <span class=\"pull-right\">Maximum {{TuProfileOverviewController.advancedDescriptionWordLimit}} words</span>\n" +
    "        \n" +
    "                    <div\n" +
    "                        focus-delay=\"250\"\n" +
    "                        custom-popover\n" +
    "                        popover-html=\"Add a general description about the Undergraduate Courses you offer\"\n" +
    "                        popover-placement=\"left\"\n" +
    "                        popover-trigger=\"manual\"\n" +
    "                        popover-visibility=\"{{isAdvancedUgOverviewEmpty ? true : false}}\">\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row\">\n" +
    "            <div class=\"col-sm-12\">\n" +
    "                <div class=\"tab-header\">\n" +
    "                    <h3 class=\"heading\">Facilities (Only for Qschina.cn)</h3>\n" +
    "                </div>\n" +
    "                <div class=\"summernote-container\">\n" +
    "                    <summernote\n" +
    "                        config=\"TuProfileOverviewController.textEditorAdvancedOptions\"\n" +
    "                        ng-model=\"tuProfile.advancedUgFacilities\"\n" +
    "                        on-change=\"TuProfileOverviewController.advancedUgFacilitiesWords = countWords(contents)\"\n" +
    "                        on-init=\"TuProfileOverviewController.advancedUgFacilitiesWords = countWords(tuProfile.advancedUgFacilities)\"\n" +
    "                        on-paste=\"TuProfileOverviewController.handleClipboardPaste('advancedUgFacilities')\"\n" +
    "                        count-word=\"TuProfileOverviewController.advancedUgFacilitiesWords\">\n" +
    "                    </summernote>\n" +
    "                </div>\n" +
    "                <div class=\"editor-note form-group\">\n" +
    "                    <span ng-class=\"{'text-red': TuProfileOverviewController.isAdvancedSectionInvalid(TuProfileOverviewController.advancedUgFacilitiesWords)}\">\n" +
    "                        <span class=\"bold\">{{TuProfileOverviewController.advancedUgFacilitiesWords}}</span>\n" +
    "                        <span>words inserted</span>\n" +
    "                    </span>\n" +
    "                    <span class=\"pull-right\">Maximum {{TuProfileOverviewController.advancedDescriptionWordLimit}} words</span>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row\">\n" +
    "        <div class=\"col-sm-12\">\n" +
    "            <div class=\"tab-header\">\n" +
    "                <h3 class=\"heading\">Student Life (Only for Qschina.cn)</h3>\n" +
    "            </div>\n" +
    "            <div class=\"summernote-container\">\n" +
    "                <summernote\n" +
    "                    config=\"TuProfileOverviewController.textEditorAdvancedOptions\"\n" +
    "                    ng-model=\"tuProfile.advancedUgStudentLife\"\n" +
    "                    on-change=\"TuProfileOverviewController.advancedUgStudentLifeWords = countWords(contents)\"\n" +
    "                    on-init=\"TuProfileOverviewController.advancedUgStudentLifeWords = countWords(tuProfile.advancedUgStudentLife)\"\n" +
    "                    on-paste=\"TuProfileOverviewController.handleClipboardPaste('advancedUgStudentLife')\"\n" +
    "                    count-word=\"TuProfileOverviewController.advancedUgStudentLifeWords\">\n" +
    "                </summernote>\n" +
    "            </div>\n" +
    "            <div class=\"editor-note form-group\">\n" +
    "                <span ng-class=\"{'text-red': TuProfileOverviewController.isAdvancedSectionInvalid(TuProfileOverviewController.advancedUgStudentLifeWords)}\">\n" +
    "                    <span class=\"bold\">{{TuProfileOverviewController.advancedUgStudentLifeWords}}</span>\n" +
    "                    <span>words inserted</span>\n" +
    "                </span>\n" +
    "                <span class=\"pull-right\">Maximum {{TuProfileOverviewController.advancedDescriptionWordLimit}} words</span>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row\">\n" +
    "        <div class=\"col-sm-12\">\n" +
    "            <div class=\"tab-header\">\n" +
    "                <h3 class=\"heading\">Admissions (Only for Qschina.cn)</h3>\n" +
    "            </div>\n" +
    "            <div class=\"summernote-container\">\n" +
    "                <summernote\n" +
    "                    config=\"TuProfileOverviewController.textEditorAdvancedOptions\"\n" +
    "                    ng-model=\"tuProfile.advancedUgAdmissions\"\n" +
    "                    on-change=\"TuProfileOverviewController.advancedUgAdmissionsWords = countWords(contents)\"\n" +
    "                    on-init=\"TuProfileOverviewController.advancedUgAdmissionsWords = countWords(tuProfile.advancedUgAdmissions)\"\n" +
    "                    on-paste=\"TuProfileOverviewController.handleClipboardPaste('advancedUgAdmissions')\"\n" +
    "                    count-word=\"TuProfileOverviewController.advancedUgAdmissionsWords\">\n" +
    "                </summernote>\n" +
    "            </div>\n" +
    "            <div class=\"editor-note form-group\">\n" +
    "                <span ng-class=\"{'text-red': TuProfileOverviewController.isAdvancedSectionInvalid(TuProfileOverviewController.advancedUgAdmissionsWords)}\">\n" +
    "                    <span class=\"bold\">{{TuProfileOverviewController.advancedUgAdmissionsWords}}</span>\n" +
    "                    <span>words inserted</span>\n" +
    "                </span>\n" +
    "                <span class=\"pull-right\">Maximum {{TuProfileOverviewController.advancedDescriptionWordLimit}} words</span>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row\">\n" +
    "        <div class=\"col-sm-12\">\n" +
    "            <div class=\"tab-header\">\n" +
    "                <h3 class=\"heading\">Careers (Only for Qschina.cn)</h3>\n" +
    "            </div>\n" +
    "            <div class=\"summernote-container\">\n" +
    "                <summernote\n" +
    "                    config=\"TuProfileOverviewController.textEditorAdvancedOptions\"\n" +
    "                    ng-model=\"tuProfile.advancedUgCareers\"\n" +
    "                    on-change=\"TuProfileOverviewController.advancedUgCareersWords = countWords(contents)\"\n" +
    "                    on-init=\"TuProfileOverviewController.advancedUgCareersWords = countWords(tuProfile.advancedUgCareers)\"\n" +
    "                    on-paste=\"TuProfileOverviewController.handleClipboardPaste('advancedUgCareers')\"\n" +
    "                    count-word=\"TuProfileOverviewController.advancedUgCareersWords\">\n" +
    "                </summernote>\n" +
    "            </div>\n" +
    "            <div class=\"editor-note form-group\">\n" +
    "                <span ng-class=\"{'text-red': TuProfileOverviewController.isAdvancedSectionInvalid(TuProfileOverviewController.advancedUgCareersWords)}\">\n" +
    "                    <span class=\"bold\">{{TuProfileOverviewController.advancedUgCareersWords}}</span>\n" +
    "                    <span>words inserted</span>\n" +
    "                </span>\n" +
    "                <span class=\"pull-right\">Maximum {{TuProfileOverviewController.advancedDescriptionWordLimit}} words</span>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"separator\"></div>\n" +
    "\n" +
    "    <div  class=\"row\">\n" +
    "        <div class=\"col-lg-6 form-group\">\n" +
    "            <div>\n" +
    "                <h3>University Website</h3>\n" +
    "            </div>\n" +
    "            <input type=\"text\"\n" +
    "                    placeholder=\"http://\"\n" +
    "                    ng-model=\"tuProfile.ugWebsiteUrl\"\n" +
    "                    ng-keyup=\"TuProfileOverviewController.onKeyUp($event)\"\n" +
    "                    class=\"form-control\" />\n" +
    "        </div>\n" +
    "        <div class=\"col-lg-6 form-group\">\n" +
    "            <div class=\"display-inline-request-info margin-right-30\">\n" +
    "                <h3 class=\"inline\">Get in touch</h3>\n" +
    "                <span ng-if=\"TuProfileOverviewController.isRequestInfoTypeUgUrl()\"\n" +
    "                        class=\"small dfn text-left text-nowrap\"> (please select one) </span>\n" +
    "            </div>\n" +
    "            <div ng-if=\"TuProfileOverviewController.isRequestInfoTypeUgUrl()\" class=\"display-inline-request-info request-info-title\">\n" +
    "                <div class=\"display-inline-request-info\">\n" +
    "                    <input i-checkbox type=\"radio\" name=\"requestInfoOverview\" value=\"email\" ng-model=\"tuProfile.requestInfoTypeUg\"> Email\n" +
    "                </div>\n" +
    "                <span class=\"or-checkboxes-request-info\">OR</span>\n" +
    "                <div class=\"display-inline-request-info\">\n" +
    "                    <input i-checkbox type=\"radio\" name=\"requestInfoOverview\" value=\"url\" ng-model=\"tuProfile.requestInfoTypeUg\"> URL\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div ng-show=\"tuProfile.requestInfoTypeUg === 'url'\" class=\"url-option\">\n" +
    "                <div class=\"form-group\">\n" +
    "                    <input ng-disabled=\"true\" type=\"text\"\n" +
    "                        name=\"tuProfile.ugRequestInfoUrl\"\n" +
    "                        placeholder=\"URL\"\n" +
    "                        ng-model=\"tuProfile.ugRequestInfoUrl\"\n" +
    "                        ng-keyup=\"TuProfileOverviewController.onKeyUp($event)\"\n" +
    "                        class=\"form-control\" />\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div ng-show=\"!tuProfile.requestInfoTypeUg || tuProfile.requestInfoTypeUg === 'email'\" class=\"email-option\">\n" +
    "                    <div class=\"form-group\" ng-class=\"{'has-errors': !TuProfileOverviewController.isValidRequestInfoEmail('ugRequestInfoEmail') && tuProfile.ugRequestInfoEmail !== ''}\">\n" +
    "                            <input\n" +
    "                            type=\"email\"\n" +
    "                            placeholder=\"Email\"\n" +
    "                            ng-model=\"tuProfile.ugRequestInfoEmail\"\n" +
    "                            class=\"form-control\"\n" +
    "                            ng-focus=\"TuProfileOverviewController.setValid('ugRequestInfoEmail')\"\n" +
    "                            custom-popover\n" +
    "                            popover-html=\"Add an email\"\n" +
    "                            popover-placement=\"left\"\n" +
    "                            popover-trigger=\"manual\"\n" +
    "                            popover-visibility=\"{{!TuProfileOverviewController.isValidRequestInfoEmail('ugRequestInfoEmail') && tuProfile.ugRequestInfoEmail !== ''}}\" />\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div ng-if=\"TuProfileOverviewController.isRequestInfoTypeUgUrl()\" ng-include src=\"'/scripts/components/profiles/tu/overview/partial/urlBanner.html'\"></div>\n" +
    "        </div>\n" +
    "        <div class=\"tab-footer\">\n" +
    "            <a class=\"btn btn-primary pull-right m-r\"\n" +
    "                ng-class=\"{'disabled': overviewBasicUgFormSubmitInProgress}\"\n" +
    "                ng-click=\"handleOverviewAdvancedUgDataSubmit()\">\n" +
    "                <i class=\"fa fa-check-circle\"></i>\n" +
    "                <span>Update</span>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</form>"
  );


  $templateCache.put('/scripts/components/profiles/tu/overview/partial/basicMasterSubTab.html',
    "<form name=\"forms.overviewBasicMasterForm\" enctype=\"multipart/form-data\">\n" +
    "    <div class=\"tab-header\">\n" +
    "      <h3 class=\"heading\">Basic Description *</h3>\n" +
    "      <a class=\"btn btn-warning btn-history\" ng-show=\"!historyDisabled\" ng-click=\"handleTuOverviewHistoryLogClick('master')\">\n" +
    "          <i class=\"fa fa-clock-o\"></i>\n" +
    "      </a>\n" +
    "      <div wave-spinner=\"\" class=\"text-right\" ng-show=\"historyDisabled\"></div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"tab-body\">\n" +
    "        <div class=\"summernote-container\" ng-class=\"{'has-errors': isBasicOverviewEmpty}\">\n" +
    "            <summernote\n" +
    "                config=\"TuProfileOverviewController.textEditorBasicOptions\"\n" +
    "                ng-model=\"tuProfile.basicMasterOverview\"\n" +
    "                on-focus=\"TuProfileOverviewController.setOverviewInvalid('basicOverview')\"\n" +
    "                on-change=\"TuProfileOverviewController.basicMasterOverviewWords = countWords(contents)\"\n" +
    "                on-init=\"TuProfileOverviewController.basicMasterOverviewWords = countWords(tuProfile.basicMasterOverview)\"\n" +
    "                on-paste=\"TuProfileOverviewController.handleClipboardPaste('basicMasterOverview')\"\n" +
    "                count-word=\"TuProfileOverviewController.basicMasterOverviewWords\">\n" +
    "            </summernote>\n" +
    "        </div>\n" +
    "\n" +
    "      <div class=\"editor-note form-group\">\n" +
    "        <span ng-class=\"{'text-red': TuProfileOverviewController.isBasicOverviewInvalid(TuProfileOverviewController.basicMasterOverviewWords)}\">\n" +
    "          <span class=\"bold\">{{TuProfileOverviewController.basicMasterOverviewWords}}</span>\n" +
    "          <span>words inserted</span>\n" +
    "        </span>\n" +
    "        <span class=\"pull-right\">Maximum {{TuProfileOverviewController.basicDescriptionWordLimit}} words</span>\n" +
    "        <div\n" +
    "            focus-delay=\"250\"\n" +
    "            custom-popover\n" +
    "            popover-html=\"Add a general description about your university\"\n" +
    "            popover-placement=\"left\"\n" +
    "            popover-trigger=\"manual\"\n" +
    "            popover-visibility=\"{{isBasicOverviewEmpty ? true : false}}\">\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "<div upgrade-banner\n" +
    "     info-block-class=\"isRightSidePanelActive() ? 'col-lg-9' : 'col-lg-10'\"\n" +
    "     buttons-block-class=\"isRightSidePanelActive() ? 'col-lg-3' : 'col-lg-2'\"\n" +
    "     basic-profile=\"true\"\n" +
    "     tm-profile=\"false\"\n" +
    "     upgrade-email=\"{{upgradeEmailsTo}}\"\n" +
    "     upgrade-click=\"toggleUpgradeForm()\"></div>\n" +
    "\n" +
    "<div class=\"separator\"></div>\n" +
    "\n" +
    "<div  class=\"row\">\n" +
    "    <div class=\"form-group\" ng-class=\"{'col-lg-6': TuProfileOverviewController.isClient,'col-lg-4': !TuProfileOverviewController.isClient,'locked': !crimsonIsBasic}\">\n" +
    "        <div>\n" +
    "            <h3>University Website</h3>\n" +
    "        </div>\n" +
    "        <input type=\"text\"\n" +
    "                ng-disabled = \"!crimsonIsBasic\"\n" +
    "                placeholder=\"http://\"\n" +
    "                ng-model=\"tuProfile.masterWebsiteUrl\"\n" +
    "                class=\"form-control\" />\n" +
    "    </div>\n" +
    "    <div class=\"form-group locked\" ng-class=\"TuProfileOverviewController.isClient ? 'col-lg-6' : 'col-lg-4'\">\n" +
    "        <div class=\"display-inline-request-info margin-right-30\">\n" +
    "            <h3 class=\"inline\">Get in touch</h3>\n" +
    "            <span ng-if=\"TuProfileOverviewController.isRequestInfoTypeMasterUrl()\"\n" +
    "                    class=\"small dfn text-left text-nowrap\"> (please select one) </span>\n" +
    "        </div>\n" +
    "        <div ng-if=\"TuProfileOverviewController.isRequestInfoTypeMasterUrl()\"\n" +
    "                class=\"display-inline-request-info request-info-title\">\n" +
    "            <div class=\"display-inline-request-info\">\n" +
    "                <input i-checkbox\n" +
    "                    disabled\n" +
    "                    type=\"radio\"\n" +
    "                    value=\"email\"\n" +
    "                    ng-model=\"tuProfile.requestInfoTypeMaster\"> Email\n" +
    "            </div>\n" +
    "            <span class=\"or-checkboxes-request-info\">OR</span>\n" +
    "            <div class=\"display-inline-request-info\">\n" +
    "                <input i-checkbox\n" +
    "                    disabled\n" +
    "                    type=\"radio\"\n" +
    "                    value=\"url\"\n" +
    "                    ng-model=\"tuProfile.requestInfoTypeMaster\"> URL\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div ng-if=\"TuProfileOverviewController.isRequestInfoTypeMasterUrl()\" class=\"url-option\">\n" +
    "            <div class=\"form-group\">\n" +
    "                <input type=\"url\"\n" +
    "                        disabled\n" +
    "                        placeholder=\"URL\"\n" +
    "                        ng-model=\"tuProfile.masterRequestInfoUrl\"\n" +
    "                        class=\"form-control\">\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div ng-if=\"!TuProfileOverviewController.isRequestInfoTypeMasterUrl()\" class=\"email-option\">\n" +
    "            <div class=\"form-group\">\n" +
    "                <input type=\"email\"\n" +
    "                        disabled\n" +
    "                        placeholder=\"Email\"\n" +
    "                        ng-model=\"tuProfile.masterRequestInfoEmail\"\n" +
    "                        class=\"form-control\">\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"col-lg-4 form-group\" ng-if=\"!TuProfileOverviewController.isClient\" ng-class=\"{'has-errors': forms.overviewBasicMasterForm.mtLeadGenFormEmail.$invalid && tuProfile.mtLeadGenFormEmail !== ''}\">\n" +
    "        <h3>MT Lead gen form email</h3>\n" +
    "        <input type=\"email\"\n" +
    "            placeholder=\"Email\"\n" +
    "            name=\"mtLeadGenFormEmail\"\n" +
    "            ng-model=\"tuProfile.mtLeadGenFormEmail\"\n" +
    "            ng-focus=\"TuProfileOverviewController.setBasicValid('mtLeadGenFormEmail')\"\n" +
    "            class=\"form-control\"\n" +
    "            custom-popover\n" +
    "            popover-html=\"Add an email\"\n" +
    "            popover-placement=\"left\"\n" +
    "            popover-trigger=\"manual\"\n" +
    "            popover-visibility=\"{{forms.overviewBasicMasterForm.mtLeadGenFormEmail.$invalid && tuProfile.mtLeadGenFormEmail !== ''}}\" />\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"tab-footer\">\n" +
    "        <a class=\"btn btn-primary pull-right m-r\"\n" +
    "            ng-class=\"{'disabled': overviewBasicMasterFormSubmitInProgress}\"\n" +
    "            ng-click=\"handleOverviewBasicMasterDataSubmit()\">\n" +
    "            <i class=\"fa fa-check-circle\"></i>\n" +
    "            <span>Update</span>\n" +
    "        </a>\n" +
    "    </div>\n" +
    "</div>\n" +
    "</form>\n"
  );


  $templateCache.put('/scripts/components/profiles/tu/overview/partial/basicPgSubTab.html',
    "<form name=\"forms.overviewBasicPgForm\" enctype=\"multipart/form-data\">\n" +
    "    <div class=\"tab-header\">\n" +
    "      <h3 class=\"heading\">Basic Description *</h3>\n" +
    "      <a class=\"btn btn-warning btn-history\"\n" +
    "        ng-class=\"{'disabled': historyDisabled}\"\n" +
    "        ng-click=\"handleTuOverviewHistoryLogClick('pg')\">\n" +
    "        <i class=\"fa fa-clock-o\"></i>\n" +
    "      </a>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"tab-body\">\n" +
    "        <div class=\"summernote-container\" ng-class=\"{'has-errors': isBasicPgOverviewEmpty}\">\n" +
    "            <summernote\n" +
    "                config=\"TuProfileOverviewController.textEditorBasicOptions\"\n" +
    "                ng-model=\"tuProfile.basicPgOverview\"\n" +
    "                on-focus=\"TuProfileOverviewController.setOverviewInvalid('basicPgOverview')\"\n" +
    "                on-change=\"TuProfileOverviewController.basicPgOverviewWords = countWords(contents)\"\n" +
    "                on-init=\"TuProfileOverviewController.basicPgOverviewWords = countWords(tuProfile.basicPgOverview)\"\n" +
    "                on-paste=\"TuProfileOverviewController.handleClipboardPaste('basicPgOverview')\"\n" +
    "                count-word=\"TuProfileOverviewController.basicPgOverviewWords\">\n" +
    "            </summernote>\n" +
    "        </div>\n" +
    "\n" +
    "      <div class=\"editor-note form-group\">\n" +
    "        <span ng-class=\"{'text-red': TuProfileOverviewController.isBasicOverviewInvalid(TuProfileOverviewController.basicPgOverviewWords)}\">\n" +
    "          <span class=\"bold\">{{TuProfileOverviewController.basicPgOverviewWords}}</span>\n" +
    "          <span>words inserted</span>\n" +
    "        </span>\n" +
    "        <span class=\"pull-right\">Maximum {{TuProfileOverviewController.basicDescriptionWordLimit}} words</span>\n" +
    "\n" +
    "        <div\n" +
    "            focus-delay=\"250\"\n" +
    "            custom-popover\n" +
    "            popover-html=\"Add a general description about the Postgraduate Courses you offer\"\n" +
    "            popover-placement=\"left\"\n" +
    "            popover-trigger=\"manual\"\n" +
    "            popover-visibility=\"{{isBasicPgOverviewEmpty ? true : false}}\">\n" +
    "        </div>\n" +
    "      </div>\n" +
    "\n" +
    "      <div class=\"tab-footer\">\n" +
    "        <a class=\"btn btn-primary pull-right\"\n" +
    "          ng-class=\"{'disabled': overviewTabOverviewSubTabSubmitInProgress}\"\n" +
    "          ng-click=\"handleOverviewBasicPgDataSubmit()\">\n" +
    "          <i class=\"fa fa-check-circle\"></i>\n" +
    "          <span>Update</span>\n" +
    "        </a>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "</form>\n" +
    "\n" +
    "<div upgrade-banner\n" +
    "     info-block-class=\"isRightSidePanelActive() ? 'col-lg-9' : 'col-lg-10'\"\n" +
    "     buttons-block-class=\"isRightSidePanelActive() ? 'col-lg-3' : 'col-lg-2'\"\n" +
    "     basic-profile=\"true\"\n" +
    "     tm-profile=\"false\"\n" +
    "     upgrade-email=\"{{upgradeEmailsTo}}\"\n" +
    "     upgrade-click=\"toggleUpgradeForm()\"></div>\n" +
    "\n" +
    "<div class=\"separator\"></div>\n" +
    "\n" +
    "<div class=\"locked\">\n" +
    "    <div  class=\"row\">\n" +
    "        <div class=\"col-lg-6 form-group\">\n" +
    "            <div>\n" +
    "                <h3>University Website</h3>\n" +
    "            </div>\n" +
    "            <input type=\"text\"\n" +
    "                   disabled\n" +
    "                   placeholder=\"http://\"\n" +
    "                   ng-model=\"tuProfile.pgWebsiteUrl\"\n" +
    "                   class=\"form-control\" />\n" +
    "        </div>\n" +
    "        <div class=\"col-lg-6 form-group\">\n" +
    "            <div class=\"display-inline-request-info margin-right-30\">\n" +
    "                <h3 class=\"inline\">Get in touch</h3>\n" +
    "                <span ng-if=\"TuProfileOverviewController.isRequestInfoTypePgUrl()\"\n" +
    "                      class=\"small dfn text-left text-nowrap\"> (please select one) </span>\n" +
    "            </div>\n" +
    "            <div ng-if=\"TuProfileOverviewController.isRequestInfoTypePgUrl()\"\n" +
    "                 class=\"display-inline-request-info request-info-title\">\n" +
    "                <div class=\"display-inline-request-info\">\n" +
    "                    <input i-checkbox\n" +
    "                       disabled\n" +
    "                       type=\"radio\"\n" +
    "                       value=\"email\"\n" +
    "                       ng-model=\"tuProfile.requestInfoTypePg\"> Email\n" +
    "                </div>\n" +
    "                <span class=\"or-checkboxes-request-info\">OR</span>\n" +
    "                <div class=\"display-inline-request-info\">\n" +
    "                    <input i-checkbox\n" +
    "                       disabled\n" +
    "                       type=\"radio\"\n" +
    "                       value=\"url\"\n" +
    "                       ng-model=\"tuProfile.requestInfoTypePg\"> URL\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div ng-if=\"TuProfileOverviewController.isRequestInfoTypePgUrl()\" class=\"url-option\">\n" +
    "                <div class=\"form-group\">\n" +
    "                    <input type=\"url\"\n" +
    "                           disabled\n" +
    "                           placeholder=\"URL\"\n" +
    "                           ng-model=\"tuProfile.pgRequestInfoUrl\"\n" +
    "                           class=\"form-control\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div ng-if=\"!TuProfileOverviewController.isRequestInfoTypePgUrl()\" class=\"email-option\">\n" +
    "                <div class=\"form-group\">\n" +
    "                    <input type=\"email\"\n" +
    "                           disabled\n" +
    "                           placeholder=\"Email\"\n" +
    "                           ng-model=\"tuProfile.pgRequestInfoEmail\"\n" +
    "                           class=\"form-control\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tu/overview/partial/basicUgSubTab.html',
    "<form name=\"forms.overviewBasicUgForm\" enctype=\"multipart/form-data\">\n" +
    "    <div class=\"tab-header\">\n" +
    "      <h3 class=\"heading\">Basic Description *</h3>\n" +
    "      <a class=\"btn btn-warning btn-history\" ng-class=\"{'disabled': historyDisabled}\" ng-click=\"handleTuOverviewHistoryLogClick('ug')\">\n" +
    "        <i class=\"fa fa-clock-o\"></i>\n" +
    "      </a>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"tab-body\">\n" +
    "        <div class=\"summernote-container\" ng-class=\"{'has-errors': isBasicUgOverviewEmpty}\">\n" +
    "            <summernote\n" +
    "                config=\"TuProfileOverviewController.textEditorBasicOptions\"\n" +
    "                ng-model=\"tuProfile.basicUgOverview\"\n" +
    "                on-focus=\"TuProfileOverviewController.setOverviewInvalid('basicUgOverview')\"\n" +
    "                on-change=\"TuProfileOverviewController.basicUgOverviewWords = countWords(contents)\"\n" +
    "                on-init=\"TuProfileOverviewController.basicUgOverviewWords = countWords(tuProfile.basicUgOverview)\"\n" +
    "                on-paste=\"TuProfileOverviewController.handleClipboardPaste('basicUgOverview')\"\n" +
    "                count-word=\"TuProfileOverviewController.basicUgOverviewWords\">\n" +
    "            </summernote>\n" +
    "        </div>\n" +
    "\n" +
    "      <div class=\"editor-note form-group\">\n" +
    "        <span ng-class=\"{'text-red': TuProfileOverviewController.isBasicOverviewInvalid(TuProfileOverviewController.basicUgOverviewWords)}\">\n" +
    "          <span class=\"bold\">{{TuProfileOverviewController.basicUgOverviewWords}}</span>\n" +
    "          <span>words inserted</span>\n" +
    "        </span>\n" +
    "        <span class=\"pull-right\">Maximum {{TuProfileOverviewController.basicDescriptionWordLimit}} words</span>\n" +
    "        <div\n" +
    "            focus-delay=\"250\"\n" +
    "            custom-popover\n" +
    "            popover-html=\"Add a general description about the Undergraduate Courses you offer\"\n" +
    "            popover-placement=\"left\"\n" +
    "            popover-trigger=\"manual\"\n" +
    "            popover-visibility=\"{{isBasicUgOverviewEmpty ? true : false}}\">\n" +
    "        </div>\n" +
    "      </div>\n" +
    "\n" +
    "      <div class=\"tab-footer\">\n" +
    "        <a class=\"btn btn-primary pull-right\"\n" +
    "          ng-class=\"{'disabled': overviewBasicUgFormSubmitInProgress}\"\n" +
    "          ng-click=\"handleOverviewBasicUgDataSubmit()\">\n" +
    "          <i class=\"fa fa-check-circle\"></i>\n" +
    "          <span>Update</span>\n" +
    "        </a>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "</form>\n" +
    "\n" +
    "<div upgrade-banner\n" +
    "     info-block-class=\"isRightSidePanelActive() ? 'col-lg-9' : 'col-lg-10'\"\n" +
    "     buttons-block-class=\"isRightSidePanelActive() ? 'col-lg-3' : 'col-lg-2'\"\n" +
    "     basic-profile=\"true\"\n" +
    "     tm-profile=\"false\"\n" +
    "     upgrade-email=\"{{upgradeEmailsTo}}\"\n" +
    "     upgrade-click=\"toggleUpgradeForm()\"></div>\n" +
    "\n" +
    "<div class=\"separator\"></div>\n" +
    "\n" +
    "<div class=\"locked\">\n" +
    "    <div  class=\"row\">\n" +
    "        <div class=\"col-lg-6 form-group\">\n" +
    "            <div>\n" +
    "                <h3>University Website</h3>\n" +
    "            </div>\n" +
    "            <input type=\"text\"\n" +
    "                   disabled\n" +
    "                   placeholder=\"http://\"\n" +
    "                   ng-model=\"tuProfile.ugWebsiteUrl\"\n" +
    "                   class=\"form-control\" />\n" +
    "        </div>\n" +
    "        <div class=\"col-lg-6 form-group\">\n" +
    "            <div class=\"display-inline-request-info margin-right-30\">\n" +
    "                <h3 class=\"inline\">Get in touch</h3>\n" +
    "                <span ng-if=\"TuProfileOverviewController.isRequestInfoTypeUgUrl()\"\n" +
    "                      class=\"small dfn text-left text-nowrap\"> (please select one) </span>\n" +
    "            </div>\n" +
    "            <div ng-if=\"TuProfileOverviewController.isRequestInfoTypeUgUrl()\"\n" +
    "                 class=\"display-inline-request-info request-info-title\">\n" +
    "                <div class=\"display-inline-request-info\">\n" +
    "                    <input i-checkbox\n" +
    "                       disabled\n" +
    "                       type=\"radio\"\n" +
    "                       value=\"email\"\n" +
    "                       ng-model=\"tuProfile.requestInfoTypeUg\"> Email\n" +
    "                </div>\n" +
    "                <span class=\"or-checkboxes-request-info\">OR</span>\n" +
    "                <div class=\"display-inline-request-info\">\n" +
    "                    <input i-checkbox\n" +
    "                       disabled\n" +
    "                       type=\"radio\"\n" +
    "                       value=\"url\"\n" +
    "                       ng-model=\"tuProfile.requestInfoTypeUg\"> URL\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div ng-if=\"TuProfileOverviewController.isRequestInfoTypeUgUrl()\" class=\"url-option\">\n" +
    "                <div class=\"form-group\">\n" +
    "                    <input type=\"url\"\n" +
    "                           disabled\n" +
    "                           placeholder=\"URL\"\n" +
    "                           ng-model=\"tuProfile.ugRequestInfoUrl\"\n" +
    "                           class=\"form-control\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div ng-if=\"!TuProfileOverviewController.isRequestInfoTypeUgUrl()\" class=\"email-option\">\n" +
    "                <div class=\"form-group\">\n" +
    "                    <input type=\"email\"\n" +
    "                           disabled\n" +
    "                           placeholder=\"Email\"\n" +
    "                           ng-model=\"tuProfile.ugRequestInfoEmail\"\n" +
    "                           class=\"form-control\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tu/overview/partial/urlBanner.html',
    "<div class=\"alert alert-warning ng-scope col-lg-12 \">\n" +
    "    <i class=\"fa fa-info-circle\"></i> If you successfully update the Email field, the URL field will no longer be available.\n" +
    "</div>\n" +
    "<div class=\"alert alert-warning ng-scope col-lg-12 \">\n" +
    "    <i class=\"fa fa-info-circle\"></i> To edit the URL field please contact tusupport@qs.com.\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tu/overview/tuProfileOverviewHistoryView.html',
    "<div class=\"ibox\">\n" +
    "    <div class=\"ibox-title m-t-xl\">\n" +
    "        <h5>History Log <small>showing last {{historyLog.totalReturned}} records of {{historyLog.total}}</small></h5>\n" +
    "        <div class=\"ibox-tools\">\n" +
    "            <a class=\"close-link\" ng-click=\"toggleHistory(false)\">\n" +
    "                <i class=\"fa fa-times\"></i>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"ibox-content\">\n" +
    "        <div wave-spinner=\"\" ng-show=\"fetchingHistoryLog()\"></div>\n" +
    "\n" +
    "        <p class=\"text-muted text-center\" ng-show=\"!fetchingHistoryLog() && !historyLog.total\">\n" +
    "            History log is empty\n" +
    "        </p>\n" +
    "\n" +
    "        <div ng-show=\"!fetchingHistoryLog()\" ng-repeat=\"log in historyLog.log\">\n" +
    "            <ul class=\"list-unstyled list-history break-word\">\n" +
    "                <li ng-if=\"log.modifiedAt\">\n" +
    "                    <span class=\"bold\">Modified on:</span>\n" +
    "                    <span>{{log.modifiedAt| date:'medium'}}</span>\n" +
    "                </li>\n" +
    "                <li ng-if=\"log.modifiedByFullName\">\n" +
    "                    <span class=\"bold\">Submitted by:</span>\n" +
    "                    <span>{{log.modifiedByFullName}}</span>\n" +
    "                </li>\n" +
    "                <li ng-show=\"!log.advanced\">\n" +
    "                    <span class=\"bold\">Basic Description:</span>\n" +
    "                    <span>\n" +
    "                        <read-more-less text=\"log.basicMasterOverview\"></read-more-less>\n" +
    "                        <read-more-less text=\"log.basicUgOverview\"></read-more-less>\n" +
    "                        <read-more-less text=\"log.basicPgOverview\"></read-more-less>\n" +
    "                    </span>\n" +
    "                </li>\n" +
    "                <li ng-show=\"log.advanced\">\n" +
    "                    <span class=\"bold\">{{ log.advancedMasterOverview ? 'About' : 'Advanced Description' }}:</span>\n" +
    "                    <span>\n" +
    "                        <read-more-less text=\"log.advancedMasterOverview\"></read-more-less>\n" +
    "                        <read-more-less text=\"log.advancedUgOverview\"></read-more-less>\n" +
    "                        <read-more-less text=\"log.advancedPgOverview\"></read-more-less>\n" +
    "                    </span>\n" +
    "                </li>\n" +
    "                <li ng-show=\"log.advanced\">\n" +
    "                    <span class=\"bold\">Facilities:</span>\n" +
    "                    <span>\n" +
    "                        <read-more-less text=\"log.advancedMasterFacilities\"></read-more-less>\n" +
    "                        <read-more-less text=\"log.advancedUgFacilities\"></read-more-less>\n" +
    "                        <read-more-less text=\"log.advancedPgFacilities\"></read-more-less>\n" +
    "                    </span>\n" +
    "                </li>\n" +
    "                <li ng-show=\"log.advanced\">\n" +
    "                    <span class=\"bold\">Student Life:</span>\n" +
    "                    <span>\n" +
    "                        <read-more-less text=\"log.advancedMasterStudentLife\"></read-more-less>\n" +
    "                        <read-more-less text=\"log.advancedUgStudentLife\"></read-more-less>\n" +
    "                        <read-more-less text=\"log.advancedPgStudentLife\"></read-more-less>\n" +
    "                    </span>\n" +
    "                </li>\n" +
    "                <li ng-show=\"log.advanced\">\n" +
    "                    <span class=\"bold\">Admissions:</span>\n" +
    "                    <span>\n" +
    "                        <read-more-less text=\"log.advancedMasterAdmissions\"></read-more-less>\n" +
    "                        <read-more-less text=\"log.advancedUgAdmissions\"></read-more-less>\n" +
    "                        <read-more-less text=\"log.advancedPgAdmissions\"></read-more-less>\n" +
    "                    </span>\n" +
    "                </li>\n" +
    "                <li ng-show=\"log.advanced\">\n" +
    "                    <span class=\"bold\">Careers:</span>\n" +
    "                    <span>\n" +
    "                        <read-more-less text=\"log.advancedMasterCareers\"></read-more-less>\n" +
    "                        <read-more-less text=\"log.advancedUgCareers\"></read-more-less>\n" +
    "                        <read-more-less text=\"log.advancedPgCareers\"></read-more-less>\n" +
    "                    </span>\n" +
    "                </li>\n" +
    "\n" +
    "                <li ng-show=\"log.advanced\">\n" +
    "                    <span class=\"bold\">University Website:</span>\n" +
    "                    <span>\n" +
    "                        <a ng-show=\"log.masterWebsiteUrl\" href=\"{{log.masterWebsiteUrl}}\" target=\"_blank\">{{log.masterWebsiteUrl}}</a>\n" +
    "                        <a ng-show=\"log.ugWebsiteUrl\" href=\"{{log.ugWebsiteUrl}}\" target=\"_blank\">{{log.ugWebsiteUrl}}</a>\n" +
    "                        <a ng-show=\"log.pgWebsiteUrl\" href=\"{{log.pgWebsiteUrl}}\" target=\"_blank\">{{log.pgWebsiteUrl}}</a>\n" +
    "                    </span>\n" +
    "                </li>\n" +
    "                <li ng-show=\"log.advanced\">\n" +
    "                    <div ng-show=\"!TopUniversitiesController.isRequestInfoTypeUrl(log)\">\n" +
    "                        <span class=\"bold\">Request info - Email:</span>\n" +
    "                        <span>\n" +
    "                            {{log.masterRequestInfoEmail}}\n" +
    "                            {{log.ugRequestInfoEmail}}\n" +
    "                            {{log.pgRequestInfoEmail}}\n" +
    "                        </span>\n" +
    "                    </div>\n" +
    "                    <div ng-show=\"TopUniversitiesController.isRequestInfoTypeUrl(log)\">\n" +
    "                        <span class=\"bold\">Request info - Title:</span>\n" +
    "                        <span>\n" +
    "                            {{log.masterRequestInfoUrlTitle}}\n" +
    "                            {{log.ugRequestInfoUrlTitle}}\n" +
    "                            {{log.pgRequestInfoUrlTitle}}\n" +
    "                        </span>\n" +
    "                        \n" +
    "                        <br>\n" +
    "                        \n" +
    "                        <span class=\"bold\">Request info - URL:</span>\n" +
    "                        <a ng-show=\"log.masterRequestInfoUrl\" href=\"{{log.masterRequestInfoUrl}}\" target=\"_blank\">{{log.masterRequestInfoUrl}}</a>\n" +
    "                        <a ng-show=\"log.ugRequestInfoUrl\" href=\"{{log.ugRequestInfoUrl}}\" target=\"_blank\">{{log.ugRequestInfoUrl}}</a>\n" +
    "                        <a ng-show=\"log.pgRequestInfoUrl\" href=\"{{log.pgRequestInfoUrl}}\" target=\"_blank\">{{log.pgRequestInfoUrl}}</a>\n" +
    "                    </div>\n" +
    "                </li>\n" +
    "            </ul>\n" +
    "\n" +
    "            <div class=\"separator dashed\"></div>\n" +
    "\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"row\" ng-show=\"handleHistoryLogVisibility()\">\n" +
    "            <div wave-spinner=\"\" ng-show=\"fetchingMoreHistoryLog()\"></div>\n" +
    "            <div class=\" col-lg-8 col-lg-offset-2\">\n" +
    "                <a class=\"btn btn-primary btn-block\" ng-click=\"handleLoadMoreHistoryLog()\" ng-disabled=\"fetchingMoreHistoryLog()\">\n" +
    "                    Load more\n" +
    "                </a>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tu/overview/tuProfileOverviewView.html',
    "<div ng-controller=\"TuProfileOverviewController as TuProfileOverviewController\">\n" +
    "    <div class=\"tabs-container\">\n" +
    "        <uib-tabset active=\"activeOverviewSubTab\">\n" +
    "            <uib-tab heading=\"Overview\">\n" +
    "                <div class=\"panel-body\">\n" +
    "                    <div ng-if=\"tuIsAdvanced || crimsonIsAdvanced\" ng-include src=\"'/scripts/components/profiles/tu/overview/partial/advancedMasterSubTab.html'\"></div>\n" +
    "                    <div ng-if=\"!tuIsAdvanced && !crimsonIsAdvanced\" ng-include src=\"'/scripts/components/profiles/tu/overview/partial/basicMasterSubTab.html'\"></div>\n" +
    "                </div>\n" +
    "            </uib-tab>\n" +
    "            <uib-tab heading=\"Undergraduate\">\n" +
    "                <div class=\"panel-body\">\n" +
    "                    <div ng-if=\"tuIsAdvanced || crimsonIsAdvanced\" ng-include src=\"'/scripts/components/profiles/tu/overview/partial/advancedUgSubTab.html'\"></div>\n" +
    "                    <div ng-if=\"!tuIsAdvanced && !crimsonIsAdvanced\" ng-include src=\"'/scripts/components/profiles/tu/overview/partial/basicUgSubTab.html'\"></div>\n" +
    "                </div>\n" +
    "            </uib-tab>\n" +
    "            <uib-tab heading=\"Postgraduate\">\n" +
    "                <div class=\"panel-body\">\n" +
    "                    <div ng-if=\"tuIsAdvanced || crimsonIsAdvanced\" ng-include src=\"'/scripts/components/profiles/tu/overview/partial/advancedPgSubTab.html'\"></div>\n" +
    "                    <div ng-if=\"!tuIsAdvanced && !crimsonIsAdvanced\" ng-include src=\"'/scripts/components/profiles/tu/overview/partial/basicPgSubTab.html'\"></div>\n" +
    "                </div>\n" +
    "            </uib-tab>\n" +
    "        </uib-tabset>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tu/programs/datagrid/deleteCellTemplate.html',
    "<div class=\"ui-grid-cell-contents no-select text-center\" ng-click=\"$event.stopPropagation()\">\n" +
    "    <input ng-model=\"grid.appScope.programsToDelete[row.entity.id]\" i-checkbox type=\"checkbox\">\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tu/programs/datagrid/deleteHeaderCellTemplate.html',
    "<button type=\"button\"\n" +
    "        ng-disabled=\"grid.appScope.isDeleteButtonDisabled()\"\n" +
    "        class=\"btn btn-danger btn-sm\"\n" +
    "        ng-class=\"{'disabled': grid.appScope.isDeleteButtonDisabled()}\"\n" +
    "        ng-click=\"grid.appScope.handleDeleteClick()\">\n" +
    "    <span class=\"glyphicon glyphicon-trash\"></span> <!-- {{col.displayName}} -->\n" +
    "</button>\n"
  );


  $templateCache.put('/scripts/components/profiles/tu/programs/datagrid/rowTemplate.html',
    "<div grid=\"grid\" class=\"ui-grid-draggable-row\" draggable=\"true\">\n" +
    "    <div class=\"ui-grid-cell pointer\"\n" +
    "        ng-repeat=\"(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name\"\n" +
    "        ng-class=\"{ 'ui-grid-row-header-cell': col.isRowHeader, 'active': row.entity.id == grid.appScope.selectedProgramId }\"\n" +
    "        role=\"{{col.isRowHeader ? 'rowheader' : 'gridcell'}}\"\n" +
    "        ui-grid-cell>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tu/programs/tuProfileAddProgramFormView.html',
    "<div class=\"ibox\">\n" +
    "    <div class=\"ibox-title m-t-xl\">\n" +
    "        <h5>Add Program</h5>\n" +
    "        <div class=\"ibox-tools\">\n" +
    "            <a ng-click=\"toggleProgramAddForm()\">\n" +
    "                <i class=\"fa fa-times\"></i>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"ibox-content\">\n" +
    "        <div wave-spinner class=\"text-right\" ng-show=\"fetchingHistoryLog()\"></div>\n" +
    "\n" +
    "        <form class=\"form-horizontal add-program\" name=\"forms.addProgramForm\" novalidate autocomplete=\"off\">\n" +
    "            <div class=\"form-group\" ng-if=\"InstitutionNameTuPrograms\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">Institution Name *</label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <input type=\"text\" class=\"form-control\"\n" +
    "                           ng-model=\"InstitutionNameTuPrograms\"\n" +
    "                           ng-disabled=\"true\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\" ng-class=\"{'has-errors' : isInvalidName}\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">Program Name *\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                    uib-tooltip=\"Maximun 255 characters\"\n" +
    "                    tooltip-placement=\"top\"></i>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <input type=\"text\" placeholder=\"Add name\" class=\"form-control\"\n" +
    "                           ng-model=\"newProgram.name\"\n" +
    "                           ng-required=\"true\"\n" +
    "\n" +
    "                           focus-delay=\"250\"\n" +
    "                           custom-popover popover-html=\"Add a valid Program name.Maximum 255 characters\"\n" +
    "                           popover-placement=\"left\"\n" +
    "                           popover-trigger=\"manual\"\n" +
    "                           popover-visibility=\"{{isInvalidName ? true : false}}\"\n" +
    "                           ng-focus=\"setIsInvalidName(false)\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\" ng-class=\"{'has-errors' : isInvalidUrl}\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">URL Landing Page *</label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <input type=\"text\" class=\"form-control\" placeholder=\"Add a Landing Page\"\n" +
    "                        ng-model=\"newProgram.url\"\n" +
    "                        ng-required=\"true\"\n" +
    "                        ng-maxlength=\"255\"\n" +
    "                        ng-pattern=\"ProgramFormController.urlPattern\"\n" +
    "                        ng-keyup=\"ProgramFormController.onKeyUp($event)\"\n" +
    "                        focus-delay=\"250\"\n" +
    "                        custom-popover popover-html=\"{{basicProgramsEnabled || basicProgramsEnabledTuProgramsOverview ? 'Add a valid CMT URL.' : 'Add a valid landing page link.'}} Maximum 255 characters\"\n" +
    "                        popover-placement=\"left\"\n" +
    "                        popover-trigger=\"manual\"\n" +
    "                        popover-visibility=\"{{isInvalidUrl ? true : false}}\"\n" +
    "                        ng-focus=\"setIsInvalidUrl(false)\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\" ng-class=\"{'has-errors' : isInvalidDepartment}\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">Department *</label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <ui-select\n" +
    "                        ng-model=\"newProgram.departmentCoreId\"\n" +
    "                        ng-required=\"true\"\n" +
    "                        theme=\"bootstrap\"\n" +
    "                        ng-disabled=\"isClientDepartment\"\n" +
    "                        on-select=\"ProgramFormController.handleDepartmentChanges(newProgram.departmentCoreId)\"\n" +
    "                        focus-delay=\"250\"\n" +
    "                        custom-popover popover-html=\"Select an option\"\n" +
    "                        popover-placement=\"left\"\n" +
    "                        popover-trigger=\"manual\"\n" +
    "                        popover-visibility=\"{{isInvalidDepartment ? true : false}}\"\n" +
    "                        >\n" +
    "                        <ui-select-match placeholder=\"Select an Option\">{{$select.selected.name}}</ui-select-match>\n" +
    "                        <ui-select-choices \n" +
    "                            refresh-delay=\"1000\"\n" +
    "                            repeat=\"option.coreId as option in departmentsListArr | filter:$select.search\">\n" +
    "                            <div ng-bind-html=\"option.name | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\" ng-class=\"{'has-errors' : isInvalidBelongsTo}\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">Belongs to *</label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <ui-select\n" +
    "                        placeholder=\"Select an option\"\n" +
    "                        ng-model=\"newProgram.belongsTo\"\n" +
    "                        ng-disabled=\"!newProgram.departmentCoreId\"\n" +
    "                        ng-required=\"true\"\n" +
    "                        theme=\"bootstrap\"\n" +
    "                        focus-delay=\"250\"\n" +
    "                        custom-popover popover-html=\"Select an option\"\n" +
    "                        popover-placement=\"left\"\n" +
    "                        popover-trigger=\"manual\"\n" +
    "                        popover-visibility=\"{{isInvalidBelongsTo ? true : false}}\"\n" +
    "                        ng-change=\"setIsInvalidBelongsTo(false)\">\n" +
    "                        <ui-select-match placeholder=\"Select an option\">\n" +
    "                            <span>{{$select.selected.title}}</span>\n" +
    "                        </ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                          position=\"down\"\n" +
    "                          repeat=\"option.id as option in belongsToList | filter: $select.search\"\n" +
    "                          ui-disable-choice=\"option.disabled\">\n" +
    "                          <div class=\"test\" ng-bind-html=\"option.title | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "                <div class=\"col-sm-12\">\n" +
    "                    <a class=\"btn btn-primary pull-right\" ng-class=\"{'disabled': addInProgress}\" ng-click=\"handleProgramCreateClick()\">\n" +
    "                        <i class=\"fa fa-check-circle\"></i>\n" +
    "                        <span>Save</span>\n" +
    "                    </a>\n" +
    "                    <a class=\"btn btn-default pull-right\" ng-click=\"toggleProgramAddForm()\">\n" +
    "                        <i class=\"fa fa-ban\"></i>\n" +
    "                        <span>Cancel</span>\n" +
    "                    </a>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </form>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tu/programs/tuProfileEditProgramFormView.html',
    "<div class=\"ibox\">\n" +
    "    <div class=\"ibox-title\">\n" +
    "        <h5>Edit Program</h5>\n" +
    "        <div class=\"ibox-tools\">\n" +
    "            <a ng-click=\"toggleProgramEditForm()\">\n" +
    "                <i class=\"fa fa-times\"></i>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"ibox-content\">\n" +
    "        <div wave-spinner class=\"text-right\" ng-show=\"isFetchInProgress()\"></div>\n" +
    "        <form class=\"form-horizontal add-program\" ng-show=\"!isFetchInProgress()\" name=\"forms.editProgramForm\" novalidate>\n" +
    "            <div class=\"form-group\" ng-if=\"InstitutionNameTuPrograms\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">Institution Name *</label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <input type=\"text\" ng-model=\"InstitutionNameTuPrograms\" class=\"form-control\" ng-required=\"true\" ng-disabled=\"true\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\" ng-class=\"{'has-errors' : isInvalidName}\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">Program Name *\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                    uib-tooltip=\"Maximun 255 characters\"\n" +
    "                    tooltip-placement=\"top\"></i>\n" +
    "                </label>\n" +
    "               \n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <input type=\"text\" placeholder=\"Add name\" ng-model=\"program.name\" class=\"form-control\"\n" +
    "                    ng-required=\"true\"\n" +
    "                    focus-delay=\"250\"\n" +
    "                    custom-popover popover-html=\"Add a valid Program name.Maximum 255 characters\"\n" +
    "                    popover-placement=\"left\"\n" +
    "                    popover-trigger=\"manual\"\n" +
    "                    popover-visibility=\"{{isInvalidName ? true : false}}\"\n" +
    "                    ng-focus=\"setIsInvalidName(false)\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\" ng-class=\"{'has-errors' : isInvalidUrl}\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">URL Landing Page *</label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <input type=\"text\" class=\"form-control\" placeholder=\"Add a Landing Page\"\n" +
    "                        ng-model=\"program.url\"\n" +
    "                        ng-keyup=\"ProgramFormController.onKeyUp($event)\"\n" +
    "                        ng-required=\"true\"\n" +
    "                        ng-maxlength=\"255\"\n" +
    "                        focus-delay=\"250\"\n" +
    "                        custom-popover popover-html=\"{{basicProgramsEnabled || basicProgramsEnabledTuProgramsOverview ? 'Add a valid CMT URL.' : 'Add a valid landing page link.'}} Maximum 255 characters\"\n" +
    "                        popover-placement=\"left\"\n" +
    "                        popover-trigger=\"manual\"\n" +
    "                        popover-visibility=\"{{isInvalidUrl ? true : false}}\"\n" +
    "                        ng-focus=\"setIsInvalidUrl(false)\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\" ng-class=\"{'has-errors' : isInvalidDepartment}\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">Department *</label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <ui-select\n" +
    "                        ng-required=\"true\"\n" +
    "                        ng-model=\"program.departmentCoreId\"\n" +
    "                        theme=\"bootstrap\"\n" +
    "                        ng-disabled=\"isClientDepartment\"\n" +
    "                        on-select=\"ProgramFormController.handleDepartmentChanges(newProgram.departmentCoreId)\"\n" +
    "                        focus-delay=\"250\"\n" +
    "                        custom-popover popover-html=\"Select an option\"\n" +
    "                        popover-placement=\"left\"\n" +
    "                        popover-trigger=\"manual\"\n" +
    "                        popover-visibility=\"{{isInvalidDepartment ? true : false}}\"\n" +
    "                        >\n" +
    "                        <ui-select-match placeholder=\"Select an Option\">{{$select.selected.name}}</ui-select-match>\n" +
    "                        <ui-select-choices \n" +
    "                            refresh-delay=\"1000\"\n" +
    "                            repeat=\"option.coreId as option in departmentsListArr | filter:$select.search\">\n" +
    "                            <div ng-bind-html=\"option.name | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\" ng-class=\"{'has-errors' : isInvalidBelongsTo}\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">Belongs to *</label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <ui-select\n" +
    "                        placeholder=\"Select an option\"\n" +
    "                        ng-model=\"program.belongsTo\"\n" +
    "                        ng-required=\"true\"\n" +
    "                        theme=\"bootstrap\"\n" +
    "                        focus-delay=\"250\"\n" +
    "                        custom-popover popover-html=\"Select an option\"\n" +
    "                        popover-placement=\"left\"\n" +
    "                        popover-trigger=\"manual\"\n" +
    "                        popover-visibility=\"{{isInvalidBelongsTo ? true : false}}\"\n" +
    "                        ng-change=\"setIsInvalidBelongsTo(false)\">\n" +
    "                        <ui-select-match placeholder=\"Select an option\">\n" +
    "                            <span>{{$select.selected.title}}</span>\n" +
    "                        </ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                          position=\"down\"\n" +
    "                          repeat=\"option.id as option in belongsToList | filter: $select.search\"\n" +
    "                          ui-disable-choice=\"option.disabled\">\n" +
    "                          <div class=\"test\" ng-bind-html=\"option.title | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "                <div class=\"col-sm-12\">\n" +
    "                    <a class=\"btn btn-primary pull-right\"\n" +
    "                       ng-class=\"{'disabled': editInProgress}\"\n" +
    "                       ng-click=\"handleProgramUpdateClick()\">\n" +
    "                        <i class=\"fa fa-check-circle\"></i>\n" +
    "                        <span>Update</span>\n" +
    "                    </a>\n" +
    "                    <a class=\"btn btn-default pull-right\" ng-click=\"toggleProgramEditForm()\">\n" +
    "                        <i class=\"fa fa-ban\"></i>\n" +
    "                        <span>Cancel</span>\n" +
    "                    </a>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "        </form>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tu/programs/tuProfileProgramsView.html',
    "<div ng-controller=\"TuProfileProgramsController as ProgramsController\">\n" +
    "    <div upgrade-banner\n" +
    "         info-block-class=\"isRightSidePanelActive() ? 'col-sm-9' : 'col-sm-10'\"\n" +
    "         buttons-block-class=\"isRightSidePanelActive() ? 'col-sm-3' : 'col-sm-2'\"\n" +
    "         basic-profile=\"!tuIsAdvanced && !crimsonIsAdvanced? true : false\"\n" +
    "         tm-profile=\"false\"\n" +
    "         upgrade-email=\"{{upgradeEmailsTo}}\"\n" +
    "         upgrade-click=\"toggleUpgradeForm()\">\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"alert alert-info\">\n" +
    "        <p>\n" +
    "            <i class=\"fa fa-info-circle\"></i>\n" +
    "            <span>Only the first top 400 programs will be published.</span>\n" +
    "        </p>\n" +
    "        <p>\n" +
    "            <i class=\"fa fa-info-circle\"></i>\n" +
    "            <span>Drag and Drop Rows to Order Program List or profiles.</span>\n" +
    "        </p>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"tab-header\" ng-class=\"{'locked': !tuIsAdvanced && !basicProgramsEnabled && !crimsonIsAdvanced}\">\n" +
    "        <div class=\"btn-toggle\">\n" +
    "            <switch class=\"green\"\n" +
    "                ng-disabled=\"alphabeticalOrderingInProgress || (!tuIsAdvanced && !basicProgramsEnabled && !crimsonIsAdvanced)\"\n" +
    "                ng-model=\"tuProfileData.programsAlphabeticalOrder\"\n" +
    "                ng-change=\"!alphabeticalOrderingInProgress && (basicProgramsEnabled || tuIsAdvanced || crimsonIsAdvanced) ? handleAlphabeticalOrderClick(tuProfileData.programsAlphabeticalOrder) : null\">\n" +
    "            </switch>\n" +
    "            <span> Display in alphabetical order?</span>\n" +
    "        </div>\n" +
    "        <a class=\"btn btn-primary pull-right\"\n" +
    "            ng-class=\"{'disabled': programsTabSubmitInProgress}\"\n" +
    "            ng-click=\"(basicProgramsEnabled || tuIsAdvanced || crimsonIsAdvanced) && handleAddProgramClick()\">\n" +
    "            <i class=\"fa fa-plus\"></i>\n" +
    "            <span>Add Program</span>\n" +
    "        </a>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"tab-body\" ng-class=\"{'locked': !tuIsAdvanced && !basicProgramsEnabled && !crimsonIsAdvanced}\">\n" +
    "        <div wave-spinner class=\"wave-spinner\" ng-show=\"isDatagridReloading || !isDatagridRendered\"></div>\n" +
    "\n" +
    "        <div id=\"programsTable\" \n" +
    "            ng-class=\"{'overlay-white': alphabeticalOrderingInProgress}\"\n" +
    "            ng-if=\"!isDatagridReloading && gridOptions\">\n" +
    "            <ui-grid-info ng-if=\"!isRightSidePanelActive()\"></ui-grid-info>\n" +
    "            <div class=\"grid\"\n" +
    "                ng-class=\"{'modal-overlay-35': !isDatagridRendered}\"\n" +
    "                ui-grid=\"gridOptions\"\n" +
    "                ui-grid-draggable-rows\n" +
    "                ui-grid-selection\n" +
    "                ui-grid-resize-columns\n" +
    "                ui-grid-auto-resize>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tu/publish/tuProfilePublishHistoryView.html',
    "<div class=\"ibox publish-logs\" ng-controller=\"TuProfilePublishLogsController as PublishLogsController\">\n" +
    "    <div class=\"ibox-title m-t-xl\">\n" +
    "        <h5>Publish Logs</h5>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"ibox-content\">\n" +
    "        <div wave-spinner ng-show=\"fetchingPublishLog()\"></div>\n" +
    "        <div ng-show=\"!fetchingPublishLog()\" ng-repeat=\"logs in publishLogs\">\n" +
    "            <div class=\"panel panel-default\" ng-repeat=\"logsPerDay in logs\">\n" +
    "                <div class=\"panel-heading\">\n" +
    "                    <span>{{logsPerDay.date | date:'mediumDate'}}</span>\n" +
    "                </div>\n" +
    "                <div class=\"panel-body\">\n" +
    "                    <ul class=\"list-unstyled\">\n" +
    "                        <li ng-repeat=\"log in logsPerDay.logs\">\n" +
    "                            <span class=\"status\">\n" +
    "                                <span class=\"icon text-success\" ng-if=\"log.status === 'success'\">\n" +
    "                                    <i class=\"fa fa-check\"></i>\n" +
    "                                </span>\n" +
    "                                <span class=\"icon text-danger\" ng-if=\"log.status === 'failure'\">\n" +
    "                                    <i class=\"fa fa-exclamation\"></i>\n" +
    "                                </span>\n" +
    "                                <span class=\"icon text-info\" ng-if=\"log.status === 'progress'\">\n" +
    "                                    <i class=\"fa fa-spinner fa-spin\"></i>\n" +
    "                                </span>\n" +
    "                                <span class=\"icon text-warning\" ng-if=\"log.status === 'pending'\">\n" +
    "                                    <i class=\"fa fa-clock-o\"></i>\n" +
    "                                </span>\n" +
    "                            </span>\n" +
    "                            \n" +
    "                            <span>{{PublishLogsController.getPublishStatusMapped(log.status)}} {{(log.type === 'pgChina' || log.type ===  'ugChina' || log.type ===  'masterChina' || log.type ===  'starsChina' ) ? 'to QSChina' : 'to TU'}}:</span>\n" +
    "                            <span class=\"bold\">{{log.createdByFullName}}</span>\n" +
    "                            <span class=\"time\">[{{log.createdAt | date:'shortTime'}}]</span>\n" +
    "\n" +
    "                            <span class=\"program\">\n" +
    "                                <span class=\"icon round bg-info\" ng-if=\"log.type === 'ug' || log.type === 'ugChina'\">\n" +
    "                                    <i class=\"fa fa-university\"></i>\n" +
    "                                </span>\n" +
    "                                <span class=\"icon round bg-warning\" ng-if=\"log.type === 'pg' || log.type === 'pgChina'\">\n" +
    "                                    <i class=\"fa fa-graduation-cap\"></i>\n" +
    "                                </span>\n" +
    "                                <span class=\"icon round bg-primary\" ng-if=\"log.type === 'master' || log.type === 'masterChina'\">\n" +
    "                                    <i class=\"fa fa-book\"></i>\n" +
    "                                </span>\n" +
    "                                <span class=\"icon icon-star\" ng-if=\"log.type === 'stars' || log.type === 'starsChina'\">\n" +
    "                                    <i></i>\n" +
    "                                </span>\n" +
    "                                <span class=\"fa fa-search\" ng-if=\"log.type === 'courses-mass'\">\n" +
    "                                    <i></i>\n" +
    "                                </span>\n" +
    "                            </span>\n" +
    "                        </li>\n" +
    "                    </ul>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div ng-show=\"!publishLogs.results.length\">\n" +
    "            No publish history\n" +
    "        </div>\n" +
    "        <div class=\"row\" ng-show=\"PublishLogsController.isMoreLogsAvailable()\">\n" +
    "            <div wave-spinner class=\"text-right\" ng-show=\"PublishLogsController.isLoadMoreHistoryLogsInProgress\"></div>\n" +
    "            <div class=\" col-lg-8 col-lg-offset-2\">\n" +
    "                <a class=\"btn btn-primary btn-block\" ng-click=\"PublishLogsController.handleLoadMoreHistoryLogs()\" ng-disabled=\"PublishLogsController.isLoadMoreHistoryLogsInProgress\">\n" +
    "                   Load more\n" +
    "                </a>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tu/publish/tuProfilePublishView.html',
    "<div ng-controller=\"TuProfilePublishController as PublishController\"\n" +
    "     ng-class=\"{'modal-overlay-35': PublishController.isLoading() }\">\n" +
    "    <div class=\"clearfix\" ng-if=\"ugOrPgDisabled\" ng-include=\"'/scripts/shared/banners/ugPgProfileDisable.html'\"></div>\n" +
    "    <div class=\"alert alert-info\">\n" +
    "        <p>\n" +
    "            <i class=\"fa fa-info-circle\"></i>\n" +
    "            <span>Please be aware that published changes will not appear straight away and could take up to 6 hours to be visible on the frontend website.</span>\n" +
    "        </p>\n" +
    "        <p>\n" +
    "            <i class=\"fa fa-info-circle\"></i>\n" +
    "            <span>Whilst a publish is occurring, you may navigate away from this page and it will still progress in the background.</span>\n" +
    "        </p>\n" +
    "    </div>\n" +
    "    <div id=\"publishContainer\" class=\"tab-body\">\n" +
    "        <div class=\"row\">\n" +
    "            <div class=\"col-lg-4 meter\"\n" +
    "                 ng-repeat=\"section in sections\"\n" +
    "                 ng-class=\"{'modal-overlay-35': section.publishDisabled || isProfileEnabled(section.type)}\">\n" +
    "                <div class=\"panel panel-default section-{{section.type}}\">\n" +
    "                    <div class=\"panel-heading\">\n" +
    "                        <h5 class=\"pull-left\">\n" +
    "                            <span class=\"icon round bg-info\" ng-if=\"section.type == 'ug' || section.type == 'ugChina'\">\n" +
    "                                <i class=\"fa fa-university\"></i>\n" +
    "                            </span>\n" +
    "                            <span class=\"icon round bg-warning\" ng-if=\"section.type == 'pg' || section.type == 'pgChina'\">\n" +
    "                                <i class=\"fa fa-graduation-cap\"></i>\n" +
    "                            </span>\n" +
    "                            <span class=\"icon round bg-primary\" ng-if=\"section.type == 'master' || section.type == 'masterChina'\">\n" +
    "                                <i class=\"fa fa-book\"></i>\n" +
    "                            </span>\n" +
    "                            <span class=\"icon icon-star\" ng-if=\"section.type == 'stars' || section.type == 'starsChina'\">\n" +
    "                                <i></i>\n" +
    "                            </span>\n" +
    "                            <span class=\"fa fa-search\" ng-if=\"section.type === 'courses'\">\n" +
    "                                <i></i>\n" +
    "                            </span>\n" +
    "                            <span>{{section.name}}</span>\n" +
    "                        </h5>\n" +
    "                        <a class=\"btn btn-primary btn-xs pull-right\" ng-class=\"{'m-t-xs': section.type === 'ugChina' || section.type === 'pgChina'}\"\n" +
    "                           ng-click=\"handlePublish(section)\">\n" +
    "                            <i class=\"fa fa-cloud-upload\"></i>\n" +
    "                            <span>Publish</span>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "\n" +
    "                    <div class=\"panel-body\">\n" +
    "                        <div class=\"clearfix m-b-md\" ng-hide=\"section.type === 'courses'\">\n" +
    "                            <a class=\"btn btn-default btn-sm btn-block\"\n" +
    "                                target=\"_blank\"\n" +
    "                                ng-href=\"{{section.url}}\"\n" +
    "                                ng-class=\"{'disabled': section.viewDisabled}\">\n" +
    "                                <i class=\"fa fa-eye\"></i>\n" +
    "                                <span>View webpage</span>\n" +
    "                            </a>\n" +
    "                        </div>\n" +
    "                        <div class=\"clearfix m-b-md\" ng-if=\"PublishController.devMode\">\n" +
    "                            <a class=\"btn btn-default btn-sm btn-block\"\n" +
    "                               target=\"_blank\"\n" +
    "                               ng-href=\"{{section.feedPreviewUrl}}\"\n" +
    "                               ng-class=\"{'disabled': section.viewDisabled}\">\n" +
    "                                <i class=\"fa fa-eye\"></i>\n" +
    "                                <span>Preview feed</span>\n" +
    "                            </a>\n" +
    "                        </div>\n" +
    "\n" +
    "                        <i ng-if=\"section.statusMessage\" class=\"fa fa-info-circle\"\n" +
    "                            uib-tooltip=\"{{section.statusMessage}}\"\n" +
    "                            tooltip-placement=\"top\">\n" +
    "                        </i>\n" +
    "                        <div class=\"response\" ng-if=\"section.statusMessage\">\n" +
    "                            <p><strong>Reason for Failed Publish:</strong></p>\n" +
    "                            <span class=\"block\" ng-class=\"{'truncate': section.type !== 'courses'}\"><strong>{{section.statusMessage}}</strong></span>\n" +
    "                        </div>\n" +
    "                        <div class=\"p-c\" progress-circle=\"section.status\"></div>\n" +
    "                        <div class=\"programs\" ng-if=\"displayProgramStatus(section)\">\n" +
    "                            <span ng-show=\"section.status === 'failure' || (section.status === 'success' && section.programs.failed > 0)\">\n" +
    "                                {{section.programs.failed}} out of {{section.programs.total}} program/s failed to publish.\n" +
    "                            </span>\n" +
    "                            <span ng-show=\"section.status === 'success' && section.programs.failed === 0\">\n" +
    "                                {{section.programs.total}} out of {{section.programs.total}} program/s publish successfully.\n" +
    "                            </span>\n" +
    "                            <a href=\"javascript:void(0);\"\n" +
    "                               ng-show=\"section.programs.failed\"\n" +
    "                               ng-click=\"viewPrograms('md', section.type, section.data)\"\n" +
    "                               title=\"See full list of {{section.type | uppercase}} programs that failed publishing\">See full list.</a>\n" +
    "                        </div>\n" +
    "                        <div class=\"text-center\" ng-if=\"PublishController.totalCoursesToPublish && section.type === 'courses'\">\n" +
    "                            {{ PublishController.totalCoursesToPublish - PublishController.coursesLeftToPublish }} out of {{ PublishController.totalCoursesToPublish }} course(s) processed.\n" +
    "                            <span ng-show=\"PublishController.failedCourses\">{{ PublishController.failedCourses }} failed</span>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tu/publish/tuProfileResubscribeView.html',
    "<div class=\"resubscribe\" ng-controller=\"TuProfileResubscribeController as TuResubscribeController\">\n" +
    "    <div class=\"ibox m-t-xl\" ng-show=\"TuResubscribeController.isAdvanced\">\n" +
    "        <div class=\"panel panel-default\">\n" +
    "            <div class=\"panel-body\">\n" +
    "                <div class=\"subscribe\">\n" +
    "                    <div class=\"pull-left\">\n" +
    "                        <p>Your subscription expires:</p>\n" +
    "                        <span class=\"bold\">{{TuResubscribeController.expiresDate | date:'dd MMMM yyyy'}}</span>\n" +
    "                    </div>\n" +
    "                    <button class=\"btn btn-warning btn-lg pull-right\"\n" +
    "                            ng-show=\"TuResubscribeController.showResubscribeButton()\"\n" +
    "                            ng-click=\"TuResubscribeController.resubscribeClick()\">\n" +
    "                        <i class=\"fa fa-envelope-o\"></i>\n" +
    "                        <span>Resubscribe</span>\n" +
    "                    </button>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"ibox m-t-xl\" ng-show=\"TuResubscribeController.displayResubscribeForm\">\n" +
    "        <div class=\"ibox-title\">\n" +
    "            <h5>Resubscribe to Advance Profile</h5>\n" +
    "        </div>\n" +
    "        <div class=\"ibox-content\">\n" +
    "            <form class=\"form-horizontal preview\" name=\"forms.resubscribeForm\" novalidate>\n" +
    "                <div class=\"form-group\">\n" +
    "                    <label class=\"control-label col-sm-5 col-md-4\">To:</label>\n" +
    "                    <div class=\"col-sm-7 col-md-8\">\n" +
    "                        <span class=\"text-label\">{{TuResubscribeController.formData.to}}</span>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group\">\n" +
    "                    <label class=\"control-label col-sm-5 col-md-4\">Subject:</label>\n" +
    "                    <div class=\"col-sm-7 col-md-8\">\n" +
    "                        <span class=\"text-label\">{{TuResubscribeController.formData.subject}}</span>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group\">\n" +
    "                    <label class=\"control-label col-sm-5 col-md-4\">Name:</label>\n" +
    "                    <div class=\"col-sm-7 col-md-8\">\n" +
    "                        <span class=\"text-label\">{{TuResubscribeController.formData.name}}</span>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group\">\n" +
    "                    <label class=\"control-label col-sm-5 col-md-4\">Email:</label>\n" +
    "                    <div class=\"col-sm-7 col-md-8\">\n" +
    "                        <span class=\"text-label\">{{TuResubscribeController.formData.email}}</span>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group\">\n" +
    "                    <label class=\"control-label col-sm-5 col-md-4\">Comments:</label>\n" +
    "                    <div class=\"col-sm-7 col-md-8\">\n" +
    "                        <div class=\"textarea\"><textarea class=\"form-control full-width\" ng-model=\"TuResubscribeController.formData.comments\" name=\"comments\" ng-trim=\"true\" rows=\"5\" ></textarea></div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group\">\n" +
    "                    <div class=\"col-sm-12\">\n" +
    "                        <a class=\"btn btn-primary pull-right\" ng-class=\"{'disabled': TuResubscribeController.inProgress}\" ng-click=\"TuResubscribeController.submitClick()\">\n" +
    "                           <i class=\"fa fa-check-circle\"></i>\n" +
    "                           <span>Submit</span>\n" +
    "                        </a>\n" +
    "                        <a class=\"btn btn-default pull-right\" ng-click=\"TuResubscribeController.cancelClick()\">\n" +
    "                            <i class=\"fa fa-ban\"></i>\n" +
    "                            <span>Cancel</span>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </form>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/components/profiles/tu/subjects/tuProfileSubjectsListsView.html',
    "<div class=\"ibox-title m-t-xl\">\n" +
    "    <h5>Selected Subjects</h5>\n" +
    "</div>\n" +
    "<div class=\"ibox block-institution\">\n" +
    "    <div class=\"ibox-content\">\n" +
    "        <div class=\"subject-selected\" ng-repeat=\"(groupName, groupSubjects) in TopUniversitiesController.subjects\">\n" +
    "            <h5 class=\"title bold\">\n" +
    "                <div class=\"name\">\n" +
    "                    <span>{{groupName}}</span>\n" +
    "                </div>\n" +
    "                <div class=\"program\">\n" +
    "                    <span>\n" +
    "                        UG\n" +
    "                        <span> / </span>\n" +
    "                        PG\n" +
    "                    </span>\n" +
    "                </div>\n" +
    "            </h5>\n" +
    "            \n" +
    "            <div class=\"subject\" ng-repeat=\"subject in groupSubjects | orderBy:'name'\" ng-show=\"tuProfile.ugSubjects[subject.handle] || tuProfile.pgSubjects[subject.handle]\">\n" +
    "                <div class=\"name\">\n" +
    "                    <span>{{subject.name}}</span>\n" +
    "                </div>\n" +
    "                <div class=\"program\">\n" +
    "                    <span><i ng-show=\"tuProfile.ugSubjects[subject.handle]\" class=\"fa fa-check-square-o\"></i></span>\n" +
    "                    <span><i ng-show=\"tuProfile.pgSubjects[subject.handle]\" class=\"fa fa-check-square-o\"></i></span>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"hr-line-dashed\"></div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/components/profiles/tu/subjects/tuProfileSubjectsView.html',
    "<div class=\"subject-tab\" ng-controller=\"TuProfileSubjectsController as SubjectsController\">\n" +
    "    <form name=\"forms.subjectsForm\">\n" +
    "        <div class=\"tab-header\">\n" +
    "            <div class=\"btn-toggle\">\n" +
    "                <switch class=\"green\" ng-model=\"tuProfile.hasPhdSubjects\"></switch>\n" +
    "                <span>Do you do PhD for any of the subjects selected below?</span>\n" +
    "            </div>\n" +
    "            <a class=\"btn btn-primary pull-right\" ng-class=\"{'disabled': subjectsTabSubmitInProgress}\" ng-click=\"handleSubjectTabSubmit()\">\n" +
    "                <i class=\"fa fa-check-circle\"></i>\n" +
    "                <span>Update</span>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "        <div class=\"tab-body\">\n" +
    "            <div wave-spinner class=\"wave-spinner\" ng-show=\"isDatagridReloading\"></div>\n" +
    "            <div class=\"col-wrap\">\n" +
    "                <div class=\"col\" ng-repeat=\"(index, subjectsChunk) in SubjectsController.subjects\">\n" +
    "                    <div ng-repeat=\"(groupName, groupSubjects) in subjectsChunk\">\n" +
    "                        <h4 class=\"title\">\n" +
    "                            <div class=\"name\">\n" +
    "                                <span>{{groupName}}</span>\n" +
    "                            </div>\n" +
    "                            <div class=\"program\">\n" +
    "                                <span>\n" +
    "                                    UG\n" +
    "                                    <span>/</span>\n" +
    "                                    PG\n" +
    "                                </span>\n" +
    "                            </div>\n" +
    "                        </h4>\n" +
    "\n" +
    "                        <div class=\"subject\" ng-repeat=\"subject in groupSubjects | orderBy:'name'\">\n" +
    "                            <div class=\"name\">\n" +
    "                                <span>{{subject.name}}</span>\n" +
    "                            </div>\n" +
    "                            <div class=\"program\">\n" +
    "                                <input i-checkbox type=\"checkbox\" ng-model=\"tuProfile.ugSubjects[subject.handle]\" />\n" +
    "                                <input i-checkbox type=\"checkbox\" ng-model=\"tuProfile.pgSubjects[subject.handle]\" />\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"tab-footer\">\n" +
    "                <a class=\"btn btn-primary pull-right\" ng-class=\"{'disabled': subjectsTabSubmitInProgress}\" ng-click=\"handleSubjectTabSubmit()\">\n" +
    "                    <i class=\"fa fa-check-circle\"></i>\n" +
    "                    <span>Update</span>\n" +
    "                </a>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </form>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/profiles/tu/tuProfileView.html',
    "<div id=\"profile-page\" class=\"wrapper wrapper-content animated fadeInRight page-profiles-tu\"\n" +
    "     ng-controller=\"TuProfileController as TopUniversitiesController\">\n" +
    "    <div class=\"row\">\n" +
    "        <div ng-class=\"isRightSidePanelActive() ? (TopUniversitiesController.isMatchingToolRightSidePanelActive() ? 'col-sm-3' : 'col-sm-8') : 'col-sm-12'\">\n" +
    "            <div class=\"tabs-container\" ng-class=\"{'modal-overlay': loadInProgress()}\">\n" +
    "                <scrollable-tabset api=\"TopUniversitiesController.scrollTabsApi\" show-tooltips=\"false\" show-drop-down=\"false\">\n" +
    "                    <uib-tabset active=\"TopUniversitiesController.tuActiveTab\">\n" +
    "                        <uib-tab heading=\"Overview\" ui-sref=\"clients.profiles.tu.overview\">\n" +
    "                            <div class=\"panel-body overview\">\n" +
    "                                <div ng-include\n" +
    "                                     src=\"'/scripts/components/profiles/tu/overview/tuProfileOverviewView.html'\"></div>\n" +
    "                            </div>\n" +
    "                        </uib-tab>\n" +
    "\n" +
    "                        <uib-tab heading=\"Departments\" ng-if=\"showDepartmentsTab()\"\n" +
    "                                 ui-sref=\"clients.profiles.tu.departments\">\n" +
    "                            <div class=\"panel-body departments\">\n" +
    "                                <div ng-include\n" +
    "                                     src=\"'/scripts/components/profiles/tu/departments/tuProfileDepartmentsView.html'\"></div>\n" +
    "                            </div>\n" +
    "                        </uib-tab>\n" +
    "\n" +
    "                        <uib-tab heading=\"Subjects\" ui-sref=\"clients.profiles.tu.subjects\">\n" +
    "                            <div class=\"panel-body subjects\">\n" +
    "                                <div ng-include\n" +
    "                                     src=\"'/scripts/components/profiles/tu/subjects/tuProfileSubjectsView.html'\"></div>\n" +
    "                            </div>\n" +
    "                        </uib-tab>\n" +
    "\n" +
    "                        <uib-tab heading=\"Programs\" ui-sref=\"clients.profiles.tu.programs\">\n" +
    "                            <div class=\"panel-body programs\">\n" +
    "                                <div ng-include\n" +
    "                                     src=\"'/scripts/components/profiles/tu/programs/tuProfileProgramsView.html'\"></div>\n" +
    "                            </div>\n" +
    "                        </uib-tab>\n" +
    "\n" +
    "                        <uib-tab heading=\"Media\" ui-sref=\"clients.profiles.tu.media\">\n" +
    "                            <div class=\"panel-body media\">\n" +
    "                                <div ng-include src=\"'/scripts/components/profiles/tu/media/tuProfileMediaView.html'\"\n" +
    "                                     id=\"mediaTab\"></div>\n" +
    "                            </div>\n" +
    "                        </uib-tab>\n" +
    "\n" +
    "                        <uib-tab heading=\"Publish\" ui-sref=\"clients.profiles.tu.publish\">\n" +
    "                            <div class=\"panel-body publish\">\n" +
    "                                <div ng-include\n" +
    "                                     src=\"'/scripts/components/profiles/tu/publish/tuProfilePublishView.html'\"></div>\n" +
    "                            </div>\n" +
    "                        </uib-tab>\n" +
    "\n" +
    "                        <uib-tab id=\"mt-tab\" ng-if=\"!TopUniversitiesController.isClient\" heading=\"Matching Tool\" ui-sref=\"clients.profiles.tu.matchingTool\">\n" +
    "                            <div class=\"panel-body matching-tool\">\n" +
    "                                <div ng-include\n" +
    "                                     src=\"'/scripts/components/profiles/tu/matchingTool/tuProfileMatchingToolView.html'\"></div>\n" +
    "                            </div>\n" +
    "                        </uib-tab>\n" +
    "                        <uib-tab heading=\"Entry Requirements\" ng-show=\"!TopUniversitiesController.isClient\" ui-sref=\"clients.profiles.tu.entryRequirements\">\n" +
    "                            <entry-requirements ng-if=\"institutionId\" institution-id=\"institutionId\"></entry-requirements>\n" +
    "                        </uib-tab>\n" +
    "\n" +
    "                    </uib-tabset>\n" +
    "                </scrollable-tabset>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div ng-show=\"showUpgradeForm\" class=\"col-sm-4 right-side-container\">\n" +
    "            <profile-upgrade-form toggle-upgrade-form=\"toggleUpgradeForm()\"></profile-upgrade-form>\n" +
    "        </div>\n" +
    "\n" +
    "        <div ng-show=\"showHistory\" class=\"col-sm-4 right-side-container\">\n" +
    "            <div ng-include src=\"'/scripts/components/profiles/tu/overview/tuProfileOverviewHistoryView.html'\"></div>\n" +
    "        </div>\n" +
    "        <div ng-show=\"showDepartmentEditForm || showDepartmentAddForm || showClientUpgradeForm\"\n" +
    "             id=\"departmentBlockPanel\"\n" +
    "             fixed-element-while-scrolling=\"#departmentsTable\"\n" +
    "             class=\"col-sm-4 right-side-container\"\n" +
    "             ng-controller=\"TuProfileDepartmentForm as DepartmentFormController\">\n" +
    "            <div class=\"department-form\" ng-show=\"showDepartmentEditForm\" ng-include\n" +
    "                 src=\"'/scripts/components/profiles/tu/departments/tuProfileEditDepartmentFormView.html'\"></div>\n" +
    "            <div class=\"department-form\" ng-show=\"showDepartmentAddForm\" ng-include\n" +
    "                 src=\"'/scripts/components/profiles/tu/departments/tuProfileAddDepartmentFormView.html'\"></div>\n" +
    "            <div class=\"department-form\" ng-show=\"showClientUpgradeForm\" ng-include\n" +
    "                 src=\"'/scripts/components/profiles/tu/departments/tuProfileUpgradeClientDepartmentFormView.html'\"></div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div ng-if=\"isSubjectsTabActive()\" class=\"col-sm-4\">\n" +
    "            <div ng-include src=\"'/scripts/components/profiles/tu/subjects/tuProfileSubjectsListsView.html'\"></div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div ng-show=\"showProgramEditForm || showProgramAddForm\" id=\"programBlockPanel\"\n" +
    "             fixed-element-while-scrolling=\"#programsTable\"\n" +
    "             class=\"col-sm-4 right-side-container\"\n" +
    "             ng-controller=\"TuProfileProgramForm as ProgramFormController\">\n" +
    "            <div ng-show=\"showProgramAddForm\" ng-include\n" +
    "                 src=\"'/scripts/components/profiles/tu/programs/tuProfileAddProgramFormView.html'\"></div>\n" +
    "            <div ng-show=\"showProgramEditForm\" ng-include\n" +
    "                 src=\"'/scripts/components/profiles/tu/programs/tuProfileEditProgramFormView.html'\"></div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div ng-show=\"isMediaTabActive()\" class=\"col-sm-4\" ng-class=\"tuIsAdvanced || crimsonIsAdvanced? '': 'modal-overlay-35'\"\n" +
    "             fixed-element-while-scrolling=\"#mediaTab\">\n" +
    "            <div id=\"mediaSidebar\" ng-include\n" +
    "                 src=\"'/scripts/components/profiles/tu/media/tuProfileMediaLinkManagerView.html'\"></div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div ng-show=\"isPublishTabActive()\" class=\"col-sm-4\">\n" +
    "            <div ng-include src=\"'/scripts/components/profiles/tu/publish/tuProfileResubscribeView.html'\"></div>\n" +
    "            <div ng-include src=\"'/scripts/components/profiles/tu/publish/tuProfilePublishHistoryView.html'\"></div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div scrolling-course=\"#coursesTable\" first-selector=\".matching-tool-title\" second-selector=\".btn-row\"\n" +
    "             ng-show=\"TopUniversitiesController.isMatchingToolRightSidePanelActive()\" class=\"col-sm-9 right-side-container\">\n" +
    "            <div ng-include src=\"'/scripts/components/profiles/tu/matchingTool/rightSidePanelView.html'\"></div>\n" +
    "        </div>\n" +
    "\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/tmDirectory/datagrid/campusCellTemplate.html',
    "<div class=\"ui-grid-cell-contents\">{{COL_FIELD CUSTOM_FILTERS | campusArrayToText:col.filter.selectOptions}}</div>\n"
  );


  $templateCache.put('/scripts/components/tmDirectory/datagrid/selectCellTemplate.html',
    "<div class=\"ui-grid-cell-contents\">{{COL_FIELD CUSTOM_FILTERS | boolToText:col.filter.selectOptions}}</div>\n"
  );


  $templateCache.put('/scripts/components/tmDirectory/edit/tmDirectoryEditProgramFormView.html',
    "<div class=\"ibox\" ng-controller=\"TmProfileProgramFormController as TmProfileProgramFormController\">\n" +
    "    <div class=\"ibox-title\">\n" +
    "        <h5>{{ TmDirectoryController.getFormTitle() }}</h5>\n" +
    "        <div class=\"ibox-tools\">\n" +
    "            <a class=\"close-link\" ng-click=\"TmDirectoryController.closeEditForm()\">\n" +
    "                <i class=\"fa fa-times\"></i>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"ibox-content\">\n" +
    "        <div wave-spinner class=\"text-right\" ng-show=\"TmDirectoryController.isParentActiveLoading\"></div>\n" +
    "        <div ng-show=\"!TmDirectoryController.isParentActiveLoading\">\n" +
    "            <form class=\"form-horizontal\" name=\"forms.editProgramForm\" novalidate autocomplete=\"off\">\n" +
    "                <div ng-include src=\"'/scripts/components/profiles/tm/programs/tmProfileEditProgramFormDetailsView.html'\"></div>\n" +
    "                <div ng-include src=\"'/scripts/components/profiles/tm/programs/tmProfileEditProgramFormStatisticsView.html'\"></div>\n" +
    "                <div ng-show=\"!TmDirectoryController.isProgramAdvanced\" ng-include src=\"'/scripts/components/profiles/tm/programs/tmProfileEditProgramFormCampusView.html'\"></div>\n" +
    "\n" +
    "                <div class=\"separator dashed\"></div>\n" +
    "\n" +
    "                <div class=\"form-group\">\n" +
    "                    <div class=\"col-sm-12\">\n" +
    "                        <switch value=\"yes\"\n" +
    "                            class=\"green\"\n" +
    "                            ng-model=\"TmDirectoryController.sendEmailToClient\"\n" +
    "                            ng-disabled=\"TmDirectoryController.approved\"></switch>\n" +
    "                            <span>Include program requester in status update email</span>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"form-group\" ng-class=\"{'modal-overlay': TmProfileProgramFormController.editInProgress}\">\n" +
    "                    <div class=\"pull-right btn-float-fix\" ng-if=\"!TmDirectoryController.pendingDeletion\">\n" +
    "                        <a class=\"btn btn-primary btn-float-fix\"\n" +
    "                           ng-click=\"TmDirectoryController.handleProgramApproveClick(TmProfileProgramFormController, false)\">\n" +
    "                            <i class=\"fa fa-check-circle\"></i>\n" +
    "                            <span>{{TmDirectoryController.approveButtonTitle}}</span>\n" +
    "                        </a>\n" +
    "                        <a class=\"btn btn-default btn-float-fix width-90-percent no-margin-right inline-block\" ng-disabled=\"!TmDirectoryController.isParentActive || !TmDirectoryController.enableUpdatePublishButton(TmProfileProgramFormController)\"\n" +
    "                           ng-click=\"TmDirectoryController.handleProgramApproveClick(TmProfileProgramFormController, true)\">\n" +
    "                            <i class=\"fa fa-check-circle\"></i>\n" +
    "                            <span>{{TmDirectoryController.approveButtonTitle}} & Publish</span>\n" +
    "                        </a>\n" +
    "                        <span ng-show=\"!TmDirectoryController.isParentActive\" class=\"clickable fa fa-info-circle pull-right m-r-xs line-height-40-px\"\n" +
    "                              uib-tooltip=\"Parent institution is disabled\"\n" +
    "                              tooltip-placement=\"top-right\">\n" +
    "                        </span>\n" +
    "                        <span ng-show=\"!TmDirectoryController.enableUpdatePublishButton(TmProfileProgramFormController) && TmDirectoryController.isParentActive\" class=\"clickable fa fa-info-circle pull-right m-r-xs line-height-40-px\"\n" +
    "                              uib-tooltip=\"Publishing is only for TopMBA.com\"\n" +
    "                              tooltip-placement=\"top-right\">\n" +
    "                        </span>\n" +
    "                    </div>\n" +
    "\n" +
    "                    <div class=\"pull-right btn-float-fix\" ng-if=\"TmDirectoryController.pendingDeletion\">\n" +
    "                        <a class=\"btn btn-default btn-float-fix\"\n" +
    "                           ng-class=\"{'disabled': TmDirectoryController.isProgramAdvanced}\"\n" +
    "                           ng-click=\"TmDirectoryController.handleCancelDeletionClick()\">\n" +
    "                            <i class=\"fa fa-check-circle\"></i>\n" +
    "                            <span>Cancel Deletion</span>\n" +
    "                        </a>\n" +
    "                        <a class=\"btn btn-danger btn-float-fix\"\n" +
    "                           ng-class=\"{'disabled': TmDirectoryController.isProgramAdvanced}\"\n" +
    "                           ng-click=\"TmDirectoryController.handleDeleteAndPublishClick()\">\n" +
    "                            <i class=\"fa fa-check-circle\"></i>\n" +
    "                            <span>Delete & Publish</span>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "\n" +
    "                    <a class=\"btn btn-default pull-right m-r-xs btn-float-fix\" ng-click=\"TmDirectoryController.closeEditForm()\">\n" +
    "                        <i class=\"fa fa-ban\"></i>\n" +
    "                        <span>Cancel</span>\n" +
    "                    </a>\n" +
    "                </div>\n" +
    "            </form>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/tmDirectory/tmDirectoryView.html',
    "<div class=\"wrapper wrapper-content animated fadeInRight page-tm-directory\" ng-controller=\"TmDirectoryController as TmDirectoryController\">\n" +
    "    <div class=\"row\">\n" +
    "        <div id=\"tmDirectoryTable\" ng-class=\"TmDirectoryController.showInfoBlock ? 'col-sm-8 hide-ng-table-pager' : 'col-sm-12'\">\n" +
    "            <div class=\"section\">\n" +
    "                <div class=\"section-body\">\n" +
    "                    <div wave-spinner class=\"wave-spinner\" ng-show=\"!TmDirectoryController.isDatagridRendered\"></div>\n" +
    "\n" +
    "                    <div ng-if=\"gridOptions\" ng-class=\"{'modal-overlay-35': !TmDirectoryController.isDatagridRendered}\">\n" +
    "                        <ui-grid-info ng-if=\"!TmDirectoryController.showInfoBlock\"></ui-grid-info>\n" +
    "                        <div class=\"grid\"\n" +
    "                             ui-grid=\"gridOptions\"\n" +
    "                             ui-grid-selection\n" +
    "                             ui-grid-resize-columns\n" +
    "                             ui-grid-auto-resize\n" +
    "                             ui-grid-cellnav\n" +
    "                             ui-grid-exporter\n" +
    "                             ui-grid-pagination></div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div ng-show=\"TmDirectoryController.showInfoBlock\"\n" +
    "             fixed-element-while-scrolling=\"#tmDirectoryTable\"\n" +
    "             class=\"col-sm-4 edit-program-container right-side-container\">\n" +
    "            <div class=\"nested-ibox\" ng-show=\"TmDirectoryController.showProgramEditForm\" ng-include src=\"'/scripts/components/tmDirectory/edit/tmDirectoryEditProgramFormView.html'\"></div>\n" +
    "        </div>\n" +
    "\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/users/list/institutions/datagrid/createdAtFilterHeaderTemplate.html',
    "<div class=\"ui-grid-filter-container {{'ui-grid-filter-input-' + $index}}\" ng-repeat=\"colFilter in col.filters\" ng-if=\"colFilter.visible\">\n" +
    "    <input type=\"text\" class=\"ui-grid-filter-input date-picker ui-grid-filter-input-{{$index}}\"\n" +
    "           ng-model=\"grid.appScope.filters.createdAtRange\"\n" +
    "           date-range-picker=\"\"\n" +
    "           date-range-fixer\n" +
    "           readonly\n" +
    "           options=\"{eventHandlers: {'apply.daterangepicker': grid.appScope.handleCreatedAtDateRange}, opens: 'left'}\" />\n" +
    "\n" +
    "    <div class=\"ui-grid-filter-button\" ng-click=\"removeFilter(colFilter, $index);grid.appScope.filters.createdAtRange={}\" >\n" +
    "        <i class=\"ui-grid-icon-cancel\"   ng-show=\"colFilter.term !== undefined && colFilter.term != null && colFilter.term != ''\"></i>\n" +
    "        <span class=\"ui-grid-icon-date\"  ng-show=\"colFilter.term == undefined || colFilter.term == null || colFilter.term == ''\"></span>\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "\n"
  );


  $templateCache.put('/scripts/components/users/list/institutions/datagrid/dateFilterHeaderTemplate.html',
    "<div class=\"ui-grid-filter-container {{'ui-grid-filter-input-' + $index}}\" ng-repeat=\"colFilter in col.filters\" ng-if=\"colFilter.visible\">\n" +
    "    <input type=\"text\" class=\"ui-grid-filter-input date-picker ui-grid-filter-input-{{$index}}\"\n" +
    "           ng-model=\"grid.appScope.filters.lastLoginAtRange\"\n" +
    "           date-range-picker=\"\"\n" +
    "           date-range-fixer\n" +
    "           readonly\n" +
    "           options=\"{eventHandlers: {'apply.daterangepicker': grid.appScope.handleLastLoginAtDateRange}, opens: 'left'}\" />\n" +
    "          \n" +
    "    <div class=\"ui-grid-filter-button\" ng-click=\"removeFilter(colFilter, $index);grid.appScope.filters.lastLoginAtRange={}\" >\n" +
    "            <i class=\"ui-grid-icon-cancel\"  ng-show=\"colFilter.term !== undefined && colFilter.term != null && colFilter.term != ''\"></i>\n" +
    "            <span class=\"ui-grid-icon-date\"  ng-show=\"colFilter.term == undefined || colFilter.term == null || colFilter.term == ''\"></span>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/users/list/institutions/datagrid/exportAllMenuItem.html',
    "<li>\n" +
    "    <button type=\"button\" class=\"ui-grid-menu-item\"\n" +
    "            ng-click=\"itemAction($event, title)\"\n" +
    "            ng-show=\"itemShown()\"\n" +
    "            ng-class=\"{ 'ui-grid-menu-item-active': active(), 'ui-grid-sr-only': (!focus && screenReaderOnly) }\"\n" +
    "            aria-pressed=\"{{active()}}\"\n" +
    "            tabindex=\"0\"\n" +
    "            ng-focus=\"focus=true\"\n" +
    "            ng-blur=\"focus=false\">\n" +
    "        <i ng-class=\"icon\" aria-hidden=\"true\">&nbsp;</i> {{ name }}\n" +
    "        <i class=\"fa fa-info-circle pull-right\"\n" +
    "           aria-hidden=\"true\"\n" +
    "           uib-tooltip=\"Export is for the 20,000 most recent rows. Otherwise, create a Jira ticket for additional rows.\"\n" +
    "           tooltip-placement=\"left\"></i>\n" +
    "    </button>\n" +
    "</li>\n"
  );


  $templateCache.put('/scripts/components/users/list/institutions/datagrid/lastLoginAtFilterHeaderTemplate.html',
    "<div class=\"ui-grid-filter-container {{'ui-grid-filter-input-' + $index}}\" ng-repeat=\"colFilter in col.filters\" ng-if=\"colFilter.visible\">\n" +
    "    <input type=\"text\" class=\"ui-grid-filter-input date-picker  ui-grid-filter-input-{{$index}}\"\n" +
    "           ng-model=\"grid.appScope.filters.lastLoginAtRange\"\n" +
    "           date-range-picker=\"\"\n" +
    "           date-range-fixer\n" +
    "           readonly\n" +
    "           options=\"{eventHandlers: {'apply.daterangepicker': grid.appScope.handleLastLoginAtDateRange}, opens: 'left'}\" />\n" +
    "\n" +
    "    <div class=\"ui-grid-filter-button\" ng-click=\"removeFilter(colFilter, $index);grid.appScope.filters.lastLoginAtRange={}\" >\n" +
    "            <i class=\"ui-grid-icon-cancel\"  ng-show=\"colFilter.term !== undefined && colFilter.term != null && colFilter.term != ''\"></i>\n" +
    "            <span class=\"ui-grid-icon-date\"  ng-show=\"colFilter.term == undefined || colFilter.term == null || colFilter.term == ''\"></span>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/users/list/institutions/datagrid/rowTemplate.html',
    "<div grid=\"grid\" draggable=\"true\">\n" +
    "    <div class=\"ui-grid-cell pointer\"\n" +
    "         ng-repeat=\"(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name\"\n" +
    "         ng-class=\"{ 'ui-grid-row-header-cell': col.isRowHeader, 'active': row.entity.id == grid.appScope.selectedUserId }\"\n" +
    "         role=\"{{col.isRowHeader ? 'rowheader' : 'gridcell'}}\"\n" +
    "         ui-grid-cell>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/users/list/institutions/institutions/institutionsUsersListInstitutionsView.html',
    "<div  class=\"panel-body institutions\" ng-controller=\"InstitutionsUsersInstitutionsController as InstitutionsController\">\n" +
    "    <div class=\"row\">\n" +
    "        <div wave-spinner class=\"load-bar col-lg-12\" ng-show=\"InstitutionsController.isProgressBarVisible()\"></div>\n" +
    "\n" +
    "        <div class=\"col-lg-12\" ng-show=\"selectedInstitutionsNamesLoaded\">\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col-lg-12\">\n" +
    "                    <div class=\"form-group\">\n" +
    "                        <label>Access to Hub and Outreach *</label>\n" +
    "                        <ui-select multiple\n" +
    "                                   close-on-select=\"false\"\n" +
    "                                   ng-model=\"userInstitutions.assigned\"\n" +
    "                                   theme=\"bootstrap\"\n" +
    "                                   reset-search-input=\"true\"\n" +
    "                                   on-remove=\"InstitutionsController.handleItemRemove($item)\"\n" +
    "                                   on-select=\"InstitutionsController.handleItemSelect($item)\"\n" +
    "                                   ng-required=\"true\">\n" +
    "                            <ui-select-match placeholder=\"Type institution name...\">{{$item.name}}</ui-select-match>\n" +
    "                            <ui-select-choices refresh-delay=\"1000\"\n" +
    "                                               position=\"down\"\n" +
    "                                               refresh=\"InstitutionsController.searchInstitutions($select.search)\"\n" +
    "                                               repeat=\"option in institutionsSearchResults | filter:$select.search\">\n" +
    "                                <div ng-bind-html=\"option.name | highlight: $select.search\"></div>\n" +
    "                            </ui-select-choices>\n" +
    "                        </ui-select>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col-lg-12\">\n" +
    "                    <div class=\"form-group\">\n" +
    "                        <label>Primary Institution *</label>\n" +
    "                        <ui-select\n" +
    "                            id=\"primary-institution-dropdown\"\n" +
    "                            ng-model=\"user.primaryInstitutionCoreId\"\n" +
    "                            ng-required=\"true\"\n" +
    "                            on-select=\"InstitutionsController.handlePrimaryInstitutionChanges()\"\n" +
    "                            theme=\"bootstrap\"\n" +
    "                            >\n" +
    "                            <ui-select-match placeholder=\"Select an Option\">{{$select.selected.name}}</ui-select-match>\n" +
    "                            <ui-select-choices \n" +
    "                                refresh-delay=\"1000\"\n" +
    "                                repeat=\"option.coreId as option in userInstitutions.assigned | filter:$select.search\">\n" +
    "                                <div ng-bind-html=\"option.name | highlight: $select.search\"></div>\n" +
    "                            </ui-select-choices>\n" +
    "                            <ui-select-no-choice>\n" +
    "                                Not found\n" +
    "                            </ui-select-no-choice>\n" +
    "                        </ui-select>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row\">\n" +
    "        <div class=\"col-lg-6\"></div>\n" +
    "        <div class=\"col-lg-6\">\n" +
    "            <a ng-disabled=\"true\"  class=\"btn btn-primary btn-sm btn-block\"\n" +
    "               ng-class=\"{'disabled': InstitutionsController.disallowSubmit() || !UsersList.hasWriteAccess}\"\n" +
    "               ng-click=\"InstitutionsController.handleInstitutionSubmit()\">Update</a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/users/list/institutions/institutionsUsersListView.html',
    "<div class=\"wrapper wrapper-content animated fadeInRight page-institutions-users-list\" ng-controller=\"InstitutionsUsersListController as UsersList\">\n" +
    "    <div class=\"row m-b-sm\">\n" +
    "        <div class=\"col-lg-12\">\n" +
    "            <button type=\"button\"  class=\"btn btn-primary pull-left\" ng-click=\"UsersList.handleAddInstitutionsUserClick()\">\n" +
    "                <i class=\"fa fa-user-plus\"></i>\n" +
    "                Add User\n" +
    "            </button>\n" +
    "            <div class=\"wi-350 pull-left\">\n" +
    "                <ui-select\n" +
    "                    name=\"ranking\"\n" +
    "                    ng-model=\"UsersList.rankingFilter\"\n" +
    "                    search-enabled=\"false\"\n" +
    "                    ng-disabled=\"true\"\n" +
    "                    on-select=\"UsersList.handleRankingFilterChanges($item, $model)\"\n" +
    "\n" +
    "                    theme=\"bootstrap\">\n" +
    "                    <ui-select-match placeholder=\"Select ranking\">{{$select.selected.label}}</ui-select-match>\n" +
    "                    <ui-select-choices\n" +
    "                        position=\"down\"\n" +
    "                        ui-disable-choice=\"option.disabled\"\n" +
    "                        repeat=\"item in (UsersList.rankingsList | filter: $select.search) track by item.value\">\n" +
    "                        <div ng-bind-html=\"item.label | highlight: $select.search\"></div>\n" +
    "                    </ui-select-choices>\n" +
    "                    <ui-select-no-choice>\n" +
    "                        Not found\n" +
    "                    </ui-select-no-choice>\n" +
    "                </ui-select>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"alert alert-info\">\n" +
    "        <p>\n" +
    "            <i class=\"fa fa-info-circle\"></i>\n" +
    "            <span>Add User button will take you the HUB 2 interface where you can add new details or edit existing details for Institution Users.</span>\n" +
    "        </p>\n" +
    "        <p>\n" +
    "            <i class=\"fa fa-info-circle\"></i>\n" +
    "            <span>User details on this page cannot be edited. These are left here for reference purpose only.</span>\n" +
    "        </p>\n" +
    "    </div>\n" +
    "    <div class=\"row\">\n" +
    "        <div id=\"schoolUsersTable\" ng-class=\"showInfoBlock ? 'col-sm-3 hide-ng-table-pager' : 'col-sm-12'\">\n" +
    "            <div class=\"ibox float-e-margins\">\n" +
    "                <div class=\"ibox-content table-responsive\">\n" +
    "                    <div wave-spinner class=\"wave-spinner\" ng-show=\"UsersList.isDatagridReloading || !UsersList.isDatagridRendered\"></div>\n" +
    "\n" +
    "                    <div class=\"full-width scroll-horizontal hide-vertical-overflow\">\n" +
    "                        <div ng-if=\"UsersList.showDatagrid && UsersList.grid.options.data\" ng-class=\"{'modal-overlay-35': UsersList.isDatagridReloading || !UsersList.isDatagridRendered}\">\n" +
    "                            <ui-grid-info ng-if=\"!showInfoBlock\"></ui-grid-info>\n" +
    "                            <div class=\"grid\"\n" +
    "                                 ui-grid=\"UsersList.grid.options\"\n" +
    "                                 ui-grid-pagination\n" +
    "                                 ui-grid-selection\n" +
    "                                 ui-grid-resize-columns\n" +
    "                                 ui-grid-exporter\n" +
    "                                 ui-grid-auto-resize></div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div fixed-element-while-scrolling=\"#schoolUsersTable\" ng-show=\"showInfoBlock\" class=\"col-sm-9 scroll-floating-element right-side-container\">\n" +
    "            <div class=\"ibox block-user\">\n" +
    "                <div class=\"ibox-content\">\n" +
    "                    <div class=\"ibox-tools\">\n" +
    "                        <a class=\"close-link\" ng-click=\"UsersList.handleEditCloseClick()\">\n" +
    "                            <i class=\"fa fa-times\"></i>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "\n" +
    "                    <div class=\"tab-content\">\n" +
    "                        <div class=\"tab-pane active\">\n" +
    "\n" +
    "                            <div class=\"row m-b-lg\" ng-show=\"user.id\">\n" +
    "                                <div class=\"col-lg-4 text-center\">\n" +
    "                                    <div class=\"m-b-sm\">\n" +
    "                                        <img alt=\"image\" class=\"img-circle\" gravatar-src=\"user.email\" gravatar-size=\"100\">\n" +
    "                                    </div>\n" +
    "                                    <div class=\"m-b-sm\">\n" +
    "                                    </div>\n" +
    "                                </div>\n" +
    "                                <div class=\"col-lg-8\">\n" +
    "                                    <h2>\n" +
    "                                        {{user.fullname}}\n" +
    "                                    </h2>\n" +
    "                                    <a href=\"mailto:{{user.email}}\" ng-disabled=\"true\"  class=\"btn btn-primary btn-sm\">\n" +
    "                                        <i class=\"fa fa-envelope\"></i> Send Email\n" +
    "                                    </a>\n" +
    "                                    <a ng-show=\"!user.active\" ng-click=\"handleActivateClick(user)\" ng-disabled=\"true\"  class=\"btn btn-warning btn-sm\" ng-class=\"{'disabled':activateInProgress}\">\n" +
    "                                        <i class=\"fa fa-undo\"></i> Activate\n" +
    "                                    </a>\n" +
    "                                    <a ng-show=\"user.active\" ng-click=\"handleDeactivateClick(user)\" ng-disabled=\"true\"  class=\"btn btn-danger btn-sm\" ng-class=\"{'disabled':deactivateInProgress}\">\n" +
    "                                        <i class=\"fa fa-ban\"></i> Deactivate\n" +
    "                                    </a>\n" +
    "                                    <!--\n" +
    "                                    <a ng-disabled=\"true\"  class=\"btn btn-default btn-sm\" ng-click=\"handleResetClick(user)\">\n" +
    "                                        <i class=\"fa fa-refresh\"></i> Reset unsaved changes\n" +
    "                                    </a>-->\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "\n" +
    "                            <div class=\"client-detail\">\n" +
    "                                <div class=\"full-height-scroll\">\n" +
    "                                    <div class=\"row\">\n" +
    "                                        <div class=\"col-lg-12\">\n" +
    "                                            <div class=\"tabs-container\">\n" +
    "                                                <uib-tabset active=\"activeTab\">\n" +
    "                                                    <uib-tab heading=\"Personal details\" disable=\"disabledInstitutionsUserListSubTabs['personalDetails']\">\n" +
    "                                                        <div ng-include src=\"'/scripts/components/users/list/institutions/personal-details/institutionsUsersListPersonalDetailsView.html'\"></div>\n" +
    "                                                    </uib-tab>\n" +
    "                                                    <uib-tab heading=\"Permissions\" disable=\"disabledInstitutionsUserListSubTabs['permissions']\">\n" +
    "                                                        <div ng-include src=\"'/scripts/components/users/list/institutions/permissions/institutionsUsersListPermissionsView.html'\"></div>\n" +
    "                                                    </uib-tab>\n" +
    "                                                    <uib-tab heading=\"Institutions\" disable=\"disabledInstitutionsUserListSubTabs['institutions']\">\n" +
    "                                                        <div ng-include src=\"'/scripts/components/users/list/institutions/institutions/institutionsUsersListInstitutionsView.html'\"></div>\n" +
    "                                                    </uib-tab>\n" +
    "                                                </uib-tabset>\n" +
    "                                            </div>\n" +
    "                                        </div>\n" +
    "                                    </div>\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <pre ng-show=\"UsersList.devMode\">{{user|json}}</pre>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/users/list/institutions/institutionsUsersView.html',
    "<div class=\"page-contacts\" ng-controller=\"InstitutionsUsersController as InstitutionsUsersController\">\n" +
    "    <div ng-include src=\"'/scripts/components/users/list/institutions/institutionsUsersListView.html'\"></div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/components/users/list/institutions/permissions/institutionsUsersListPermissionsView.html',
    "<div class=\"panel-body\">\n" +
    "    <div class=\"row\">\n" +
    "        <div class=\"col-lg-6 block-permissions\">\n" +
    "            <p>\n" +
    "                <strong>Departments / Roles</strong>\n" +
    "            </p>\n" +
    "            <div ng-repeat=\"(groupName, roles) in UsersList.rolesList | groupBy: 'groupName'\">\n" +
    "                <label>{{groupName}}</label>\n" +
    "                <div ng-repeat=\"role in roles\" class=\"permission-row\">\n" +
    "                    <switch ng-model=\"user.roles[role.roleHandle]\"\n" +
    "                            ng-disabled=\"role.roleHandle !== 'custom' && user.roles.custom\"\n" +
    "                            name=\"{{role.roleHandle}}\"\n" +
    "                            class=\"{{role.roleHandle=='custom' ? 'red': 'green'}}\"\n" +
    "                            ng-change=\"handleRoleClick(role)\"\n" +
    "                    ></switch>{{role.roleName}}\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"col-lg-6\">\n" +
    "            <h3 ng-show=\"user.globalAdmin\" class=\"btn-sm badge-info\">\n" +
    "                Full Access\n" +
    "            </h3>\n" +
    "            <div ng-class=\"{'modal-overlay': user.globalAdmin}\">\n" +
    "                <p>\n" +
    "                    <strong>Assign Pages</strong>\n" +
    "                </p>\n" +
    "                <div class=\"table-responsive\">\n" +
    "                    <table class=\"table table-striped\">\n" +
    "                        <thead>\n" +
    "                        <tr>\n" +
    "                            <th>Section</th>\n" +
    "                            <th>Page</th>\n" +
    "                            <th>Assign</th>\n" +
    "                        </tr>\n" +
    "                        </thead>\n" +
    "                        <tbody>\n" +
    "                        <tr ng-repeat=\"(key, page) in UsersList.sectionsList\">\n" +
    "                            <td>{{page.sectionName}}</td>\n" +
    "                            <td>{{page.pageName}}</td>\n" +
    "                            <td>\n" +
    "                                <input i-checkbox type=\"checkbox\" name=\"{{page.pageHandle}}\" ng-model=\"user.accessTo[page.pageHandle]\" ng-change=\"activateCustomRole()\"  />\n" +
    "                            </td>\n" +
    "                        </tr>\n" +
    "                        </tbody>\n" +
    "                    </table>\n" +
    "                    <a ng-disabled=\"true\"  class=\"btn btn-primary btn-sm btn-block\"\n" +
    "                       ng-class=\"{'disabled':permissionsSubmitInProgress || !UsersList.hasWriteAccess}\"\n" +
    "                       ng-click=\"handlePermissionsSubmit()\">Update</a>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/users/list/institutions/personal-details/institutionsUsersListPersonalDetailsView.html',
    "<div class=\"panel-body\">\n" +
    "    <div class=\"row\">\n" +
    "        <form name=\"forms.personalDetailsForm\" class=\"clearfix\" novalidate>\n" +
    "            <div class=\"col-lg-6 block-personal-details\">\n" +
    "                <div class=\"form-group\">\n" +
    "                    <label>User type *</label>\n" +
    "                    <ui-select\n" +
    "                        name=\"typeId\"\n" +
    "                        ng-required=\"true\"\n" +
    "                        ng-disabled=\"true\"\n" +
    "                        ng-model=\"selectedItem.selectedOptionType\"\n" +
    "                        theme=\"bootstrap\">\n" +
    "                        <ui-select-match placeholder=\"Select an Option\">{{$select.selected.name}}</ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                            refresh-delay=\"1000\"\n" +
    "                            repeat=\"option in UsersList.userTypes | filter:$select.search\">\n" +
    "                            <div ng-bind-html=\"option.name | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group\">\n" +
    "                    <label>Title</label>\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"user.title\">\n" +
    "                </div>\n" +
    "                <div class=\"form-group\" ng-class=\"{'has-errors': !UsersList.isValidFirstName()}\">\n" +
    "                    <label>First (Given) Name *</label>\n" +
    "                    <input name=\"firstname\"\n" +
    "                        type=\"text\"\n" +
    "                        class=\"form-control\"\n" +
    "                        ng-model=\"user.firstname\"\n" +
    "                        ng-required=\"true\"\n" +
    "                        ng-focus=\"UsersList.setValid('firstname')\"\n" +
    "                        custom-popover\n" +
    "                        popover-html=\"Add a first (given) name\"\n" +
    "                        popover-placement=\"left\"\n" +
    "                        popover-trigger=\"manual\"\n" +
    "                        popover-visibility=\"{{!UsersList.isValidFirstName()}}\">\n" +
    "                </div>\n" +
    "                <div class=\"form-group\" ng-class=\"{'has-errors': !UsersList.isValidLastName()}\">\n" +
    "                    <label>Last (Family Name) *</label>\n" +
    "                    <input name=\"lastname\"\n" +
    "                        type=\"text\"\n" +
    "                        class=\"form-control\"\n" +
    "                        ng-model=\"user.lastname\"\n" +
    "                        ng-required=\"true\"\n" +
    "                        ng-focus=\"UsersList.setValid('lastname')\"\n" +
    "                        custom-popover\n" +
    "                        popover-html=\"Add a last (family) name\"\n" +
    "                        popover-placement=\"left\"\n" +
    "                        popover-trigger=\"manual\"\n" +
    "                        popover-visibility=\"{{!UsersList.isValidLastName()}}\">\n" +
    "                </div>\n" +
    "                <div class=\"form-group\" ng-class=\"{'has-errors': !UsersList.isValidEmail()}\">\n" +
    "                    <label>Email *</label>\n" +
    "                    <input name=\"email\"\n" +
    "                        type=\"email\"\n" +
    "                        class=\"form-control\"\n" +
    "                        ng-model=\"user.email\"\n" +
    "                        ng-required=\"true\"\n" +
    "                        ng-focus=\"UsersList.setValid('email')\"\n" +
    "                        custom-popover\n" +
    "                        popover-html=\"Add an email\"\n" +
    "                        popover-placement=\"left\"\n" +
    "                        popover-trigger=\"manual\"\n" +
    "                        popover-visibility=\"{{!UsersList.isValidEmail()}}\">\n" +
    "                </div>\n" +
    "\n" +
    "                <user-password user=\"user\"></user-password>\n" +
    "\n" +
    "                <div class=\"form-group\">\n" +
    "                    <label>Position</label>\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"user.position\">\n" +
    "                </div>\n" +
    "                <div class=\"form-group\">\n" +
    "                    <label>Phone</label>\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"user.phone\">\n" +
    "                </div>\n" +
    "                <div class=\"form-group\">\n" +
    "                    <label>Primary Institution</label>\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"user.primaryInstitutionName\" disabled=\"disabled\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"col-lg-6 block-contact-types\">\n" +
    "                <table class=\"table table-striped\">\n" +
    "                    <thead>\n" +
    "                    <tr>\n" +
    "                        <th>Contact Type</th>\n" +
    "                        <th>Assign</th>\n" +
    "                        <th>Primary</th>\n" +
    "                    </tr>\n" +
    "                    </thead>\n" +
    "                    <tbody>\n" +
    "                    <tr ng-repeat=\"types in UsersList.contactTypes\">\n" +
    "                        <td>\n" +
    "                            {{types.name}} <span ng-if=\"UsersList.contactTypeHasTooltip(types.handle)\"\n" +
    "                                                ng-include src=\"UsersList.getContactTypeTooltipUrl(types.handle)\"></span>\n" +
    "                        </td>\n" +
    "                        <td>\n" +
    "                            <input ng-model=\"user.contactTypes[types.handle].assigned\" ng-change=\"handleContactTypeClick(types.handle)\" i-checkbox=\"{{types.assign}}\" type=\"checkbox\" />\n" +
    "                        </td>\n" +
    "                        <td>\n" +
    "                            <input ng-model=\"user.contactTypes[types.handle].primary\" ng-change=\"handlePrimaryContactTypeClick(types.handle)\" i-checkbox=\"{{types.primary}}\" type=\"checkbox\" />\n" +
    "                        </td>\n" +
    "                    </tr>\n" +
    "                    </tbody>\n" +
    "                </table>\n" +
    "\n" +
    "                <a ng-disabled=\"true\"  class=\"btn btn-primary btn-block\"\n" +
    "                   ng-class=\"{'disabled':personalDetailsSubmitInProgress || !UsersList.hasWriteAccess}\"\n" +
    "                   ng-click=\"handlePersonalDetailsSubmit()\">{{user.id ? 'Update' : 'Save'}}</a>\n" +
    "            </div>\n" +
    "        </form>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/users/list/qs/partial/permissions.html',
    "<div class=\"panel-body\">\n" +
    "    <div class=\"row\">\n" +
    "        <div class=\"col-lg-6 block-permissions\">\n" +
    "            <p>\n" +
    "                <strong>Departments / Roles</strong>\n" +
    "            </p>\n" +
    "            <div ng-repeat=\"(groupName, roles) in UsersList.rolesList | groupBy: 'groupName'\">\n" +
    "                <label>{{groupName}}</label>\n" +
    "                <div ng-repeat=\"role in roles\" class=\"permission-row\">\n" +
    "                    <switch ng-model=\"user.roles[role.roleHandle]\"\n" +
    "                            ng-disabled=\"(role.roleHandle !== 'custom' && user.roles.custom) ||\n" +
    "                                        (user.roles['global_admin.global_admin'] && role.roleHandle !== 'global_admin.global_admin')\"\n" +
    "                            name=\"{{role.roleHandle}}\"\n" +
    "                            class=\"{{role.roleHandle=='custom' ? 'red': 'green'}}\"\n" +
    "                            ng-change=\"handleRoleClick(role)\"\n" +
    "                    ></switch>{{role.roleName}}\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"col-lg-6\">\n" +
    "            <div>\n" +
    "                <p>\n" +
    "                    <strong>Assign Pages</strong>\n" +
    "                </p>\n" +
    "\n" +
    "                <div class=\"table-responsive panel\">\n" +
    "                    <div class=\"clickable panel-heading panel-primary-light\" ng-click=\"toggleClientSections()\">\n" +
    "                        Clients <i class=\"pull-right glyphicon\" ng-class=\"{'glyphicon-chevron-down': showClientSections, 'glyphicon-chevron-right': !showClientSections}\"></i>\n" +
    "                    </div>\n" +
    "                    <table ng-show=\"showClientSections\" class=\"table table-striped\">\n" +
    "                        <thead>\n" +
    "                        <tr>\n" +
    "                            <th>Section</th>\n" +
    "                            <th>Page</th>\n" +
    "                            <th>Assign</th>\n" +
    "                        </tr>\n" +
    "                        </thead>\n" +
    "                        <tbody>\n" +
    "                        <tr ng-repeat=\"(key, page) in UsersList.clientSectionsList\">\n" +
    "                            <td>{{page.sectionName}}</td>\n" +
    "                            <td>{{page.pageName}}</td>\n" +
    "                            <td>\n" +
    "                                <input i-checkbox type=\"checkbox\" name=\"{{page.pageHandle}}\" ng-model=\"user.accessTo[page.pageHandle]\" ng-change=\"activateCustomRole()\" />\n" +
    "                            </td>\n" +
    "                        </tr>\n" +
    "                        </tbody>\n" +
    "                    </table>\n" +
    "\n" +
    "                    <div class=\"clickable panel-heading panel-primary-light\" ng-click=\"toggleQsSections()\">\n" +
    "                        QS Staff <i class=\"pull-right glyphicon\" ng-class=\"{'glyphicon-chevron-down': showQsSections, 'glyphicon-chevron-right': !showQsSections}\"></i>\n" +
    "                    </div>\n" +
    "                    <table ng-show=\"showQsSections\" class=\"table table-striped\">\n" +
    "                        <thead>\n" +
    "                        <tr>\n" +
    "                            <th>Section</th>\n" +
    "                            <th>Page</th>\n" +
    "                            <th>Assign</th>\n" +
    "                        </tr>\n" +
    "                        </thead>\n" +
    "                        <tbody>\n" +
    "                        <tr ng-repeat=\"(key, page) in UsersList.qsSectionsList\">\n" +
    "                            <td>{{page.sectionName}}</td>\n" +
    "                            <td>{{page.pageName}}</td>\n" +
    "                            <td>\n" +
    "                                <input i-checkbox type=\"checkbox\" name=\"{{page.pageHandle}}\"\n" +
    "                                       ng-model=\"user.accessTo[page.pageHandle]\"\n" +
    "                                       ng-change=\"activateCustomRole()\"\n" +
    "                                       ng-disabled=\"{{page.disabled}}\"/>\n" +
    "                            </td>\n" +
    "                        </tr>\n" +
    "                        </tbody>\n" +
    "                    </table>\n" +
    "                </div>\n" +
    "                <a ng-disabled=\"true\"  class=\"btn btn-primary btn-sm btn-block\" ng-class=\"{'disabled':permissionsSubmitInProgress}\" ng-click=\"handlePermissionsSubmit()\">Update</a>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/users/list/qs/partial/personalDetails.html',
    "<div class=\"panel-body\">\n" +
    "    <div class=\"row\">\n" +
    "        <form name=\"forms.personalDetailsForm\" class=\"clearfix\" novalidate>\n" +
    "            <div class=\"col-lg-6 block-personal-details\">\n" +
    "                <div class=\"form-group\">\n" +
    "                    <label>Title</label>\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"user.title\">\n" +
    "                </div>\n" +
    "                <div class=\"form-group\" ng-class=\"{'has-errors': !UsersList.isValidFirstName()}\">\n" +
    "                    <label>First (Given) Name *</label>\n" +
    "                    <input name=\"firstname\"\n" +
    "                        type=\"text\"\n" +
    "                        class=\"form-control\"\n" +
    "                        ng-model=\"user.firstname\"\n" +
    "                        ng-required=\"true\"\n" +
    "                        ng-focus=\"UsersList.setValid('firstname')\"\n" +
    "                        custom-popover\n" +
    "                        popover-html=\"Add a first (given) name\"\n" +
    "                        popover-placement=\"left\"\n" +
    "                        popover-trigger=\"manual\"\n" +
    "                        popover-visibility=\"{{!UsersList.isValidFirstName()}}\">\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"form-group\" ng-class=\"{'has-errors': !UsersList.isValidLastName()}\">\n" +
    "                    <label>Last (Family Name) *</label>\n" +
    "                    <input name=\"lastname\"\n" +
    "                        type=\"text\"\n" +
    "                        class=\"form-control\"\n" +
    "                        ng-model=\"user.lastname\"\n" +
    "                        ng-required=\"true\"\n" +
    "                        ng-focus=\"UsersList.setValid('lastname')\"\n" +
    "                        custom-popover\n" +
    "                        popover-html=\"Add a last (family) name\"\n" +
    "                        popover-placement=\"left\"\n" +
    "                        popover-trigger=\"manual\"\n" +
    "                        popover-visibility=\"{{!UsersList.isValidLastName()}}\">\n" +
    "                </div>\n" +
    "                <div class=\"form-group\">\n" +
    "                    <label>Position</label>\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"user.position\">\n" +
    "                </div>\n" +
    "                <div class=\"form-group\" ng-class=\"{'has-errors': !UsersList.isValidEmail()}\">\n" +
    "                    <label>Email *</label>\n" +
    "                    <input name=\"email\"\n" +
    "                        type=\"email\"\n" +
    "                        class=\"form-control\"\n" +
    "                        ng-model=\"user.email\"\n" +
    "                        ng-required=\"true\"\n" +
    "                        ng-focus=\"UsersList.setValid('email')\"\n" +
    "                        custom-popover\n" +
    "                        popover-html=\"Add an email\"\n" +
    "                        popover-placement=\"left\"\n" +
    "                        popover-trigger=\"manual\"\n" +
    "                        popover-visibility=\"{{!UsersList.isValidEmail()}}\">\n" +
    "                </div>\n" +
    "\n" +
    "                <user-password user=\"user\"></user-password>\n" +
    "            </div>\n" +
    "            <div class=\"col-lg-6 block-contact-types\">\n" +
    "                <table class=\"table table-striped\">\n" +
    "                    <thead>\n" +
    "                    <tr>\n" +
    "                        <th>Contact Type</th>\n" +
    "                        <th>Assign</th>\n" +
    "                        <th>Primary</th>\n" +
    "                    </tr>\n" +
    "                    </thead>\n" +
    "                    <tbody>\n" +
    "                    <tr ng-repeat=\"types in UsersList.contactTypes\">\n" +
    "                        <td>\n" +
    "                            {{types.name}} <span ng-if=\"UsersList.contactTypeHasTooltip(types.handle)\"\n" +
    "                                                ng-include src=\"UsersList.getContactTypeTooltipUrl(types.handle)\"></span>\n" +
    "                        </td>\n" +
    "                        <td>\n" +
    "                            <input ng-model=\"user.contactTypes[types.handle].assigned\" i-checkbox=\"{{types.assign}}\" type=\"checkbox\" />\n" +
    "                        </td>\n" +
    "                        <td>\n" +
    "                            <input ng-model=\"user.contactTypes[types.handle].primary\" ng-change=\"handlePrimaryContactTypeClick(types.handle)\" i-checkbox=\"{{types.primary}}\" type=\"checkbox\" />\n" +
    "                        </td>\n" +
    "                    </tr>\n" +
    "                    </tbody>\n" +
    "                </table>\n" +
    "                <a ng-disabled=\"true\"  class=\"btn btn-primary btn-block\" ng-class=\"{'disabled':personalDetailsSubmitInProgress}\" ng-click=\"handlePersonalDetailsSubmit()\">{{user.id ? 'Update' : 'Save'}}</a>\n" +
    "            </div>\n" +
    "        </form>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/users/list/qs/qsUsersListView.html',
    "<div class=\"wrapper wrapper-content animated fadeInRight page-qs-users-list\" ng-controller=\"QsUsersListController as UsersList\">\n" +
    "    <p>\n" +
    "        <button type=\"button\"  class=\"btn btn-primary\" ng-click=\"UsersList.handleAddQsUserClick()\">\n" +
    "            <i class=\"fa fa-user-plus\"></i>\n" +
    "            Add User\n" +
    "        </button>\n" +
    "    </p>\n" +
    "    <div class=\"alert alert-info\">\n" +
    "        <p>\n" +
    "            <i class=\"fa fa-info-circle\"></i>\n" +
    "            <span>Add User button will take you the HUB 2 interface where you can add new details or edit existing details for QS Users.</span>\n" +
    "        </p>\n" +
    "        <p>\n" +
    "            <i class=\"fa fa-info-circle\"></i>\n" +
    "            <span>User details on this page cannot be edited. These are left here for reference purpose only.</span>\n" +
    "        </p>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row\">\n" +
    "        <div id=\"usersTable\" ng-class=\"showInfoBlock ? 'col-sm-4 hide-ng-table-pager' : 'col-sm-12'\">\n" +
    "            <div class=\"ibox float-e-margins\">\n" +
    "                <div class=\"ibox-content table-responsive\">\n" +
    "                    <div wave-spinner class=\"wave-spinner\" ng-show=\"isDatagridReloading || !isDatagridRendered\"></div>\n" +
    "\n" +
    "                    <div class=\"full-width scroll-horizontal hide-vertical-overflow\" mouse-scroll-horizontal>\n" +
    "                        <div ng-if=\"UsersList.grid.options\" ng-class=\"{'modal-overlay-35': isDatagridReloading}\">\n" +
    "                            <ui-grid-info ng-if=\"!showInfoBlock\"></ui-grid-info>\n" +
    "                            <div class=\"grid\"\n" +
    "                                 ui-grid=\"UsersList.grid.options\"\n" +
    "                                 ui-grid-pagination\n" +
    "                                 ui-grid-selection\n" +
    "                                 ui-grid-resize-columns\n" +
    "                                 ui-grid-exporter\n" +
    "                                 ui-grid-auto-resize></div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div fixed-element-while-scrolling=\"#usersTable\" ng-show=\"showInfoBlock\" class=\"col-sm-8 scroll-floating-element right-side-container\">\n" +
    "            <div class=\"ibox block-user\">\n" +
    "                <div class=\"ibox-content\">\n" +
    "                    <div class=\"ibox-tools\">\n" +
    "                        <a class=\"close-link\" ng-click=\"UsersList.handleEditCloseClick()\">\n" +
    "                            <i class=\"fa fa-times\"></i>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "\n" +
    "                    <div class=\"tab-content\">\n" +
    "                        <div class=\"tab-pane active\">\n" +
    "                            <div class=\"row m-b-lg text-center\" ng-show=\"user.id\">\n" +
    "                                <div class=\"col-lg-4\">\n" +
    "                                    <div class=\"m-b-sm\">\n" +
    "                                        <img alt=\"image\" class=\"img-circle\" gravatar-src=\"user.email\" gravatar-size=\"100\">\n" +
    "                                    </div>\n" +
    "\n" +
    "                                    <div class=\"m-b-sm\">\n" +
    "                                    </div>\n" +
    "                                </div>\n" +
    "                                <div class=\"col-lg-8\">\n" +
    "                                    <h2>\n" +
    "                                        {{user.fullname}}\n" +
    "                                    </h2>\n" +
    "\n" +
    "                                    <a href=\"mailto:{{user.email}}\" ng-disabled=\"true\"  class=\"btn btn-primary btn-sm\">\n" +
    "                                        <i class=\"fa fa-envelope\"></i> Send Email\n" +
    "                                    </a>\n" +
    "\n" +
    "                                    <a ng-show=\"!user.active\" ng-click=\"handleActivateClick(user)\" ng-disabled=\"true\"  class=\"btn btn-warning btn-sm\" ng-class=\"{'disabled':activateInProgress}\">\n" +
    "                                        <i class=\"fa fa-undo\"></i> Activate\n" +
    "                                    </a>\n" +
    "\n" +
    "                                    <a ng-show=\"user.active\" ng-click=\"handleDeactivateClick(user)\" ng-disabled=\"true\"  class=\"btn btn-danger btn-sm\" ng-class=\"{'disabled':deactivateInProgress}\">\n" +
    "                                        <i class=\"fa fa-ban\"></i> Deactivate\n" +
    "                                    </a>\n" +
    "\n" +
    "                                    <a ng-disabled=\"true\"  class=\"btn btn-default btn-sm\" ng-click=\"handleResetClick(user)\">\n" +
    "                                        <i class=\"fa fa-refresh\"></i> Reset unsaved changes\n" +
    "                                    </a>\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                            <div class=\"client-detail\">\n" +
    "                                <div class=\"full-height-scroll scroll-floating-element\" full-scroll>\n" +
    "                                    <div class=\"row\">\n" +
    "                                        <div class=\"col-lg-12\">\n" +
    "                                            <div class=\"tabs-container\">\n" +
    "                                                <uib-tabset active=\"activeQsUserListSubTabs\">\n" +
    "                                                    <uib-tab heading=\"Personal details\" disable=\"disabledQsUserListSubTabs['personalDetails']\">\n" +
    "                                                        <div ng-include src=\"'/scripts/components/users/list/qs/partial/personalDetails.html'\"></div>\n" +
    "                                                    </uib-tab>\n" +
    "                                                    <uib-tab heading=\"Permissions\" disable=\"disabledQsUserListSubTabs['permissions']\">\n" +
    "                                                        <div ng-include src=\"'/scripts/components/users/list/qs/partial/permissions.html'\"></div>\n" +
    "                                                    </uib-tab>\n" +
    "                                                </uib-tabset>\n" +
    "                                            </div>\n" +
    "                                        </div>\n" +
    "                                    </div>\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <pre ng-show=\"UsersList.devMode\">{{user|json}}</pre>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/components/users/list/templates/databaseAccessOutreachTooltip.html',
    "<i class=\"fa fa-info-circle cursor-default\"\n" +
    "    uib-tooltip='User has access to all functionalities for clients in Outreach, including connections, databases, candidates, team management, etc based on what type of events user has access to'\n" +
    "    tooltip-placement=\"top\">"
  );


  $templateCache.put('/scripts/components/users/list/templates/wgstOutreachTooltip.html',
    "<i class=\"fa fa-info-circle cursor-default\"\n" +
    "    uib-tooltip='User has access to their profile, WGST events including Community/Activities and the Logistics tab only in Outreach'\n" +
    "    tooltip-placement=\"top\">"
  );


  $templateCache.put('/scripts/components/users/list/templates/wmtOutreachTooltip.html',
    "<i class=\"fa fa-info-circle cursor-default\"\n" +
    "    uib-tooltip='User has access to their profile, WMT events including Community/Activities and the Logistics tab only in Outreach'\n" +
    "    tooltip-placement=\"top\">"
  );


  $templateCache.put('/scripts/components/users/list/templates/wutOutreachTooltip.html',
    "<i class=\"fa fa-info-circle cursor-default\"\n" +
    "    uib-tooltip='User has access to their profile, WUT events including Community/Activities and the Logistics tab only in Outreach'\n" +
    "    tooltip-placement=\"top\">"
  );


  $templateCache.put('/scripts/components/users/shared/password/passwordView.html',
    "<div class=\"password-box-outer form-group\" ng-class=\"{'has-errors': !$ctrl.isPassLen}\">\n" +
    "    <label>\n" +
    "        {{$ctrl.cnf.label || 'Password'}} <span ng-show=\"$ctrl.user.password || $ctrl.isRequired()\">*</span>\n" +
    "    </label>\n" +
    "    <div class=\"password-box\">\n" +
    "        <div class=\"col-lg-7 col-md-12 col-sm-12 col-xs-12 no-pad-left\">\n" +
    "            <div ng-class=\"$ctrl.cnf.blockClass\">\n" +
    "                <div ng-class=\"{'has-errors': !$ctrl.isPassLen}\">\n" +
    "                    <input ng-class=\"$ctrl.isValid() ? 'valid' : 'invalid'\"\n" +
    "                           type=\"password\"\n" +
    "                           name=\"password\"\n" +
    "                           class=\"form-control\"\n" +
    "                           placeholder=\"{{$ctrl.isRequired() ? 'Enter password' : ' New password'}}\"\n" +
    "                           ng-model=\"$ctrl.user.password\"\n" +
    "                           ng-change=\"$ctrl.handleChanges()\"\n" +
    "                           autocomplete=\"new-password\"\n" +
    "                           ng-focus=\"$ctrl.data\"\n" +
    "                           custom-popover\n" +
    "                           popover-html=\"Add a password\"\n" +
    "                           popover-placement=\"left\"\n" +
    "                           popover-trigger=\"manual\"\n" +
    "                           popover-visibility=\"{{!$ctrl.isPassLen}}\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div ng-class=\"$ctrl.cnf.blockClass\" ng-if=\"$ctrl.cnf.resetOnLogin\">\n" +
    "                <div class=\"margin-top-15\">\n" +
    "                    <input id=\"reset-on-login\" ng-class=\"reset-on-login\" ng-model=\"$ctrl.user.passwordReset\"\n" +
    "                           type=\"checkbox\" i-checkbox/>\n" +
    "                    <label for=\"reset-on-login\" class=\"bold300\">&nbsp; Reset password on login</label>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"col-lg-5 col-md-12 col-sm-12 col-xs-12\">\n" +
    "            <div ng-class=\"$ctrl.cnf.blockClass\">\n" +
    "                <div class=\" text-left\" ng-if=\"$ctrl.user.password || $ctrl.isRequired()\">\n" +
    "                    <div class=\"validation-items\">\n" +
    "                        <div class=\"validation-item\" ng-repeat=\"item in $ctrl.validators\">\n" +
    "                            <i class=\"fa fa-circle\" ng-class=\"item.status ? 'text-navy' : 'text-danger'\"></i>\n" +
    "                            {{item.name}}\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/shared/appUpdateMessage/appUpdateMessageView.html',
    "<div id=\"info-msg\" ng-if=\"$ctrl.isVisible()\">\n" +
    "    New version of QS Hub is available. Please reload the page.\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/shared/areYouSure/areYouSure.html',
    "<div class=\"modal-body\">\n" +
    "    <p>{{modalOptions.bodyText}}</p>\n" +
    "</div>\n" +
    "<div class=\"modal-footer\">\n" +
    "    <button type=\"button\" class=\"btn btn-danger\" ng-click=\"modalOptions.handleYesClick();\">\n" +
    "        Yes\n" +
    "    </button>\n" +
    "    <button class=\"btn btn-default\" ng-click=\"modalOptions.handleNoClick()\">\n" +
    "        No\n" +
    "    </button>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/shared/areYouSure/deleteAreYouSure.html',
    "<div class=\"modal-header ng-scope\">\n" +
    "    <h3>{{modalOptions.headerText}}</h3>\n" +
    "</div>\n" +
    "<div class=\"modal-body\">\n" +
    "    <p>{{modalOptions.bodyText}}</p>\n" +
    "</div>\n" +
    "<div class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-default\" ng-click=\"modalOptions.handleNoClick()\">\n" +
    "        Cancel\n" +
    "    </button>\n" +
    "    <button type=\"button\" class=\"btn btn-danger\" ng-click=\"modalOptions.handleYesClick();\">\n" +
    "        Yes\n" +
    "    </button>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/shared/banners/tmProfileView.html',
    "<div class=\"alert alert-warning ng-scope col-lg-12 \">\n" +
    "    <i class=\"fa fa-star\"></i> You are not subscribed currently for <strong>{{profileDisableName}}</strong> profile. If you like to do so, please contact:<strong> tusupportqs.com </strong>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/shared/banners/ugPgProfileDisable.html',
    "<div class=\"alert alert-warning ng-scope col-lg-12 \">\n" +
    "    <i class=\"fa fa-star\"></i> You are not subscribed currently for <strong>{{profileDisableName}}</strong> profile. If you like to do so, please contact:<strong> tusupport@qs.com </strong>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/shared/breadcrumbs/breadcrumbsView.html',
    "<div class=\"page-heading row\" >\n" +
    "    <div class=\"col-lg-12\">\n" +
    "        <h2>{{$state.current.data.label}}</h2>\n" +
    "        <ol class=\"breadcrumb\">\n" +
    "            <li ng-if=\"$state.$current.parent.parent.parent.data.label\">\n" +
    "                <span>{{$state.$current.parent.parent.parent.data.label}}</span>\n" +
    "            </li>\n" +
    "            <li ng-if=\"$state.$current.parent.parent.data.label\">\n" +
    "                <span>{{$state.$current.parent.parent.data.label}}</span>\n" +
    "            </li>\n" +
    "            <li ng-if=\"$state.$current.parent.data.label && $state.$current.parent.data.label !== $state.current.data.label\">\n" +
    "                <span>{{$state.$current.parent.data.label}}</span>\n" +
    "            </li>\n" +
    "            <li class=\"active\">\n" +
    "                <strong>{{$state.current.data.label}}</strong>\n" +
    "            </li>\n" +
    "        </ol>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/shared/cookiesNotice/cookiesNoticeView.html',
    "<span ng-if=\"$ctrl.visible\">\n" +
    "    This website uses cookies to improve your experience. We'll assume you're ok with this, but you can opt-out if you wish.\n" +
    "    <button ng-click=\"$ctrl.accept()\" class=\"btn btn-primary btn-xs\">Accept</button>\n" +
    "    <a class=\"btn btn-default btn-xs\" href=\"http://www.qs.com/cookies-policy\" target=\"_blank\">Read more</a>\n" +
    "</span>\n"
  );


  $templateCache.put('/scripts/shared/disabledInstitution/disabledInstitutionView.html',
    "<div class=\"widget red-bg p-lg text-center disabled-institution col-lg-4\">\n" +
    "    <div class=\"m-b-md\">\n" +
    "        <i class=\"fa fa-warning fa-4x\"></i>\n" +
    "        <h1 class=\"m-xs\">Institution is disabled</h1>\n" +
    "        <span class=\"font-bold no-margins\">\n" +
    "            Please contact <a href=\"mailto:tuonlinesupport@qs.com\"><strong>tuonlinesupport@qs.com</strong></a>\n" +
    "        </span>\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/shared/disabledInstitution/disabledTmBasicProgramView.html',
    "<div class=\"widget red-bg p-lg text-center disabled-institution col-lg-4\">\n" +
    "    <div class=\"m-b-md\">\n" +
    "        <i class=\"fa fa-warning fa-4x\"></i>\n" +
    "        <h1 class=\"m-xs\">Institution is disabled</h1>\n" +
    "        <span class=\"font-bold no-margins\">\n" +
    "            Please contact <a href=\"mailto:tmonlinesupport@qs.com\"><strong>tmonlinesupport@qs.com</strong></a>\n" +
    "        </span>\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/shared/displayFiltering/displayFilteringView.html',
    "<div class=\"ibox-tools dropdown\" uib-dropdown>\n" +
    "    <a aria-expanded=\"false\" aria-haspopup=\"true\" class=\"dropdown-toggle\" href=\"#\" uib-dropdown-toggle=\"\" ng-show=\"active\">\n" +
    "        <i class=\"fa fa-wrench\"></i>\n" +
    "    </a>\n" +
    "    <ul class=\"dropdown-menu dropdown-filter\" uib-dropdown-menu>\n" +
    "        <li class=\"hidden-column\" ng-repeat=\"column in columns\">\n" +
    "            <label><input type=\"checkbox\" ng-model-options=\"{getterSetter: true}\" ng-model=\"column.show\" ng-click=\"callback()\" />{{column.title}}</label>\n" +
    "        </li>\n" +
    "        <li class=\"display-filtering-buttons\" ng-if=\"columns.length > 0\">\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col-lg-4\">\n" +
    "                    <a class=\"btn btn-sm btn-primary\" ng-click=\"displayFilteringSelectAll()\">Select All</a>\n" +
    "                </div>\n" +
    "                <div class=\"col-lg-4 col-lg-offset-2\">\n" +
    "                    <a class=\"btn btn-sm btn-danger\" ng-click=\"closeDisplayFiltering($event)\">Close</a>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </li>\n" +
    "    </ul>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/shared/footer/footerView.html',
    "<p>\n" +
    "    <span>\n" +
    "        <strong>Copyright</strong>\n" +
    "        QS Quacquarelli Symonds Ltd &copy;\n" +
    "        <span current-date=\"yyyy\"></span>\n" +
    "    </span>\n" +
    "    <span> | <a href=\"http://www.qs.com/privacy-policy\" class=\"text-info\" target=\"_blank\">Privacy Policy</a></span>\n" +
    "    <span> | <a href=\"http://www.qs.com/cookies-policy\" class=\"text-info\" target=\"_blank\">Cookies Policy</a></span>\n" +
    "    <cookies-notice class=\"m-l-md\"></cookies-notice>\n" +
    "    <span class=\"pull-right\">\n" +
    "        Version <strong>{{version}}</strong>\n" +
    "    </span>\n" +
    "</p>\n"
  );


  $templateCache.put('/scripts/shared/modal/modalTemplate.html',
    "<div class=modal-body ng-swipe-left=Lightbox.nextImage() ng-swipe-right=Lightbox.prevImage()>\n" +
    "    <div class=\"lightbox-nav no-margins\">\n" +
    "        <div class=btn-group ng-if=\"Lightbox.images.length > 1\">\n" +
    "            <a class=\"btn btn-xs btn-default previous\" ng-click=Lightbox.prevImage()>‹</a>\n" +
    "            <a class=\"btn btn-xs btn-default next\" ng-click=Lightbox.nextImage()>›</a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=lightbox-image-container>\n" +
    "        <div class=lightbox-image-caption><span>{{Lightbox.imageCaption}}</span></div>\n" +
    "        <img ng-if=!Lightbox.isVideo(Lightbox.image) lightbox-src={{Lightbox.imageUrl}}>\n" +
    "        <div ng-if=Lightbox.isVideo(Lightbox.image) class=\"embed-responsive embed-responsive-16by9\">\n" +
    "            <video ng-if=!Lightbox.isSharedVideo(Lightbox.image) lightbox-src={{Lightbox.imageUrl}} controls autoplay></video>\n" +
    "            <embed-video ng-if=Lightbox.isSharedVideo(Lightbox.image) lightbox-src={{Lightbox.imageUrl}} ng-href={{Lightbox.imageUrl}} iframe-id=lightbox-video class=embed-responsive-item>\n" +
    "                <a ng-href={{Lightbox.imageUrl}}>Watch video</a>\n" +
    "            </embed-video>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "  "
  );


  $templateCache.put('/scripts/shared/modal/modalView.html',
    "<div class=\"modal-header\">\n" +
    "    <h3>{{modalOptions.headerText}}</h3>\n" +
    "</div>\n" +
    "<div class=\"modal-body\">\n" +
    "    <p>{{modalOptions.bodyText}}</p>\n" +
    "</div>\n" +
    "<div class=\"modal-footer\">\n" +
    "    <button type=\"button\" class=\"btn btn-default\" data-ng-click=\"modalOptions.close()\">\n" +
    "        {{modalOptions.closeButtonText}}\n" +
    "    </button>\n" +
    "    <button class=\"btn btn-{{modalOptions.actionButtonClass}}\" data-ng-click=\"modalOptions.ok();\">\n" +
    "        {{modalOptions.actionButtonText}}\n" +
    "    </button>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/shared/ng-table/filters/dateRangeFilter.html',
    "<input\n" +
    "    date-range-picker\n" +
    "    class=\"form-control date-picker filter\"\n" +
    "    class=\"ui-grid-filter-input-0\"\n" +
    "    type=\"text\"\n" +
    "    ng-model=\"params.filter()[name]\"\n" +
    "    clearable=\"true\"\n" +
    "    options=\"{locale: {format: 'D MMMM, YYYY'}}\"\n" +
    "    readonly />"
  );


  $templateCache.put('/scripts/shared/noAccess/noAccessView.html',
    "<div class=\"widget red-bg p-lg text-center no-access-institution col-lg-4\">\n" +
    "    <div class=\"m-b-md\">\n" +
    "        <i class=\"fa fa-warning fa-4x\"></i>\n" +
    "        <h1 class=\"m-xs\">No access to this section</h1>\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/shared/progressCircle/progressCircleView.html',
    "<div class=\"c100 p{{percent}} {{colour}}\">\n" +
    "    <span class=\"text-label\">{{text}}</span>\n" +
    "    <div class=\"slice\">\n" +
    "        <div class=\"bar\"></div>\n" +
    "        <div class=\"fill\"></div>\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/shared/publishLogs/cellTemplates/publishedDateCellTemplate.html',
    "<div class=\"ui-grid-cell-contents\">\n" +
    "    <span ng-if=\"row.entity.modifiedAt\">\n" +
    "         {{row.entity.modifiedAt | date:'fullDate'}}, {{row.entity.modifiedAt | date:'mediumTime'}}\n" +
    "    </span>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/shared/publishLogs/cellTemplates/rowTemplate.html',
    "<div grid=\"grid\" ng-class=\"{unread: grid.appScope.isUnread(row.entity)}\">\n" +
    "    <div class=\"ui-grid-cell\"\n" +
    "        ng-repeat=\"(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name\"\n" +
    "        ng-class=\"{'ui-grid-row-header-cell': col.isRowHeader, 'active': row.entity.id && row.entity.id == grid.appScope.selectedRowId, 'pointer': col.colDef.field === 'status'}\"\n" +
    "        role=\"{{col.isRowHeader ? 'rowheader' : 'gridcell'}}\"\n" +
    "        ui-grid-cell>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/shared/publishLogs/cellTemplates/statusCellTemplate.html',
    "<div class=\"ui-grid-cell-contents\" ng-click=\"grid.appScope.getRowFeed(row.entity)\">\n" +
    "    <span class=\"status\">\n" +
    "        <span class=\"icon text-success\" ng-if=\"row.entity.status === 'success'\">\n" +
    "            <i class=\"fa fa-check\"></i>\n" +
    "        </span>\n" +
    "        <span class=\"icon text-danger\"\n" +
    "              ng-if=\"row.entity.status === 'failure' || row.entity.status === 'cancelled'\">\n" +
    "            <i class=\"fa fa-exclamation\"></i>\n" +
    "        </span>\n" +
    "        <span class=\"icon text-info\" ng-if=\"row.entity.status === 'progress'\">\n" +
    "            <i class=\"fa fa-spinner fa-spin\"></i>\n" +
    "        </span>\n" +
    "        <span class=\"icon text-warning\" ng-if=\"row.entity.status === 'pending'\">\n" +
    "            <i class=\"fa fa-clock-o\"></i>\n" +
    "        </span>\n" +
    "    </span>\n" +
    "    <span>\n" +
    "         {{row.entity.status | capitaliseFirstLetter}}\n" +
    "    </span>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/shared/publishLogs/cellTemplates/statusHeaderCellTemplate.html',
    "<span class=\"classification-custom-popover\">\n" +
    "    <i class=\"fa fa-download\"></i> {{col.displayName}}\n" +
    "    <span custom-popover\n" +
    "          popover-html=\"JSON files can be downloaded except for 'Pending' publishes.\"\n" +
    "          popover-placement=\"top\"\n" +
    "          popover-container=\"body\"\n" +
    "          popover-trigger=\"hover\">\n" +
    "        <i class=\"fa fa-exclamation-circle\"></i>\n" +
    "    </span>\n" +
    "    <ui-grid-filter></ui-grid-filter>\n" +
    "</span>\n"
  );


  $templateCache.put('/scripts/shared/publishLogs/moreInfoModal.html',
    "<div class=\"more-info-modal\">\n" +
    "    <div class=\"modal-header\">\n" +
    "        <button type=\"button\" class=\"close\" ng-click=\"$dismiss()\">&times;</button>\n" +
    "        <h3>{{$ctrl.headerText}}</h3>\n" +
    "        <a ng-show=\"$ctrl.gridOptions.data.length && !$ctrl.isClassification\" ng-click=\"$ctrl.downloadParentFeed()\">Download Ranking Set(s)</a>\n" +
    "    </div>\n" +
    "    <div class=\"modal-body\">\n" +
    "        <div class=\"grid\"\n" +
    "             ui-grid=\"$ctrl.gridOptions\"\n" +
    "             ui-grid-selection\n" +
    "             ui-grid-cellnav\n" +
    "             ui-grid-exporter\n" +
    "             ui-grid-resize-columns\n" +
    "             ui-grid-auto-resize\n" +
    "             ui-grid-pagination>\n" +
    "        </div>\n" +
    "        <div class=\"row m-t\">\n" +
    "            <div class=\"col-xs-12\">\n" +
    "                <button ng-click=\"$ctrl.$close()\" class=\"btn btn-primary text-center\">\n" +
    "                    Close\n" +
    "                </button>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/shared/publishLogs/publishLogsView.html',
    "<div class=\"publish-logs\">\n" +
    "    <b ng-show=\"$ctrl.logs.length===0\">No results</b>\n" +
    "    <div ng-repeat=\"logPerDay in $ctrl.logs\" class=\"record border-bottom m-b\">\n" +
    "        <div class=\"panel panel-default\">\n" +
    "            <div class=\"panel-heading\">\n" +
    "                <span>{{logPerDay.date | date:'mediumDate'}}</span>\n" +
    "            </div>\n" +
    "            <div class=\"panel-body\">\n" +
    "                <ul class=\"list-unstyled\" >\n" +
    "                    <li ng-repeat=\"logItem in logPerDay.logs\" ng-class=\"{'p-r-xs': !!$ctrl.buttonLabel && $ctrl.isLatestLogSuccess() && logItem.status === 'success' && $parent.$index === 0 && $index === 0}\">\n" +
    "                        <span class=\"status\">\n" +
    "                            <span ng-if=\"!logItem.isDelete\">\n" +
    "                                <span class=\"icon text-success\" ng-if=\"logItem.status === 'success'\">\n" +
    "                                    <i class=\"fa fa-check\"></i>\n" +
    "                                </span>\n" +
    "                                <span class=\"icon text-danger\"\n" +
    "                                      ng-if=\"logItem.status === 'failure' || logItem.status === 'cancelled'\">\n" +
    "                                    <i class=\"fa fa-exclamation\"></i>\n" +
    "                                </span>\n" +
    "                                <span class=\"icon text-info\" ng-if=\"logItem.status === 'progress'\">\n" +
    "                                    <i class=\"fa fa-spinner fa-spin\"></i>\n" +
    "                                </span>\n" +
    "                                <span class=\"icon text-warning\" ng-if=\"logItem.status === 'pending'\">\n" +
    "                                    <i class=\"fa fa-clock-o\"></i>\n" +
    "                                </span>\n" +
    "                            </span>\n" +
    "                            <span ng-if=\"logItem.isDelete\">\n" +
    "                                <span class=\"icon text-success\" ng-if=\"logItem.status === 'success'\">\n" +
    "                                    <i class=\"fa fa-arrow-circle-o-left\"></i>\n" +
    "                                </span>\n" +
    "                                <span class=\"icon text-danger\"\n" +
    "                                      ng-if=\"logItem.status === 'failure' || logItem.status === 'cancelled'\">\n" +
    "                                    <i class=\"fa fa-arrow-circle-o-left\"></i>\n" +
    "                                </span>\n" +
    "                                <span class=\"icon text-info\" ng-if=\"logItem.status === 'progress'\">\n" +
    "                                    <i class=\"fa fa-arrow-circle-o-left fa-spin\"></i>\n" +
    "                                </span>\n" +
    "                                <span class=\"icon text-warning\" ng-if=\"logItem.status === 'pending'\">\n" +
    "                                    <i class=\"fa fa-arrow-circle-o-left\"></i>\n" +
    "                                </span>\n" +
    "                            </span>\n" +
    "                        </span>\n" +
    "\n" +
    "                        <span ng-if=\"logItem.isIndividualPublish\">Individual</span>\n" +
    "                        <span ng-if=\"logItem.isDelete\">Unpublished</span>\n" +
    "                        <span ng-switch=\"logItem.status\">\n" +
    "                            <span ng-switch-when=\"failure\">Failed</span>\n" +
    "                            <span ng-switch-when=\"success\">Published</span>\n" +
    "                            <span ng-switch-when=\"pending\">Pending</span>\n" +
    "                            <span ng-switch-when=\"cancelled\">Cancelled</span>\n" +
    "                            <span ng-switch-default>{{logItem.status | capitalize}}</span>\n" +
    "                        </span>\n" +
    "                        <span class=\"bold\">{{logItem.modifiedByFullName}}</span>\n" +
    "                        <small class=\"time\">\n" +
    "                            {{logItem.createdAt | date:'shortTime'}} - {{logItem.modifiedAt | date:'shortTime'}}\n" +
    "                            <span ng-show=\"!$ctrl.isSameDate(logPerDay.date, logItem.modifiedAt) && $ctrl.ranking === 'scores'\">( {{logItem.modifiedAt | date:'mediumDate'}} )</span>\n" +
    "                        </small>\n" +
    "                        <span>Scores Published: {{logItem.scoresPublished}}</span>\n" +
    "                        <span>Scores Failed: {{logItem.scoresFailed}}</span>\n" +
    "                        <span ng-if=\"logItem.status === 'cancelled'\">Scores Cancelled {{logItem.scoresTotal - (logItem.scoresPublished + logItem.scoresFailed)}}</span>\n" +
    "                        <span>Total: {{logItem.scoresTotal}} </span>\n" +
    "                        <div class=\"pull-right\">\n" +
    "                            <a ng-click=\"$ctrl.getMoreDetails(logItem)\">More info</a>\n" +
    "                            <a ng-if=\"!!$ctrl.buttonLabel && $ctrl.isLatestLogSuccess() && logItem.status === 'success' && $parent.$index === 0 && $index === 0\"\n" +
    "                                class=\"btn btn-primary btn-xs\" ng-disabled=\"logItem.isRankingsAppPublished\"\n" +
    "                                ng-click=\"$ctrl.handlePublish(logItem.rankingId, logItem.year, logItem.site, logItem.isRankingsAppPublished)\">\n" +
    "                                <i class=\"fa fa-cloud-upload\"></i>\n" +
    "                                <span>{{$ctrl.buttonLabel}}</span>\n" +
    "                            </a>\n" +
    "                        </div>\n" +
    "                    </li>\n" +
    "                </ul>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/shared/readMoreLess/readMoreLessView.html',
    "<span>\n" +
    "    <span ng-show=\"$ctrl.showReadMore()\">\n" +
    "        {{ $ctrl.text | htmlToPlaintext | limitTo : $ctrl.getLimit() }}... <a ng-click=\"$ctrl.handleClick()\">read more</a>\n" +
    "    </span>\n" +
    "    <span ng-show=\"$ctrl.showReadLess()\">\n" +
    "        {{ $ctrl.text | htmlToPlaintext }} <a ng-click=\"$ctrl.handleClick()\">read less</a>\n" +
    "    </span>\n" +
    "    <span ng-show=\"!$ctrl.showReadLess() && !$ctrl.showReadMore()\">{{ $ctrl.text | htmlToPlaintext }}</span>\n" +
    "</span>\n"
  );


  $templateCache.put('/scripts/shared/sidebar/sidebarView.html',
    "<nav class=\"navbar-default navbar-static-side\" role=\"navigation\" ng-controller=\"SidebarController as SidebarController\">\n" +
    "    <div class=\"sidebar-collapse\">\n" +
    "        <ul id=\"side-menu\" class=\"nav vnav media\" side-navigation=\"\">\n" +
    "            <li class=\"nav-header\">\n" +
    "                <div class=\"profile-element\">\n" +
    "                    <img src=\"/images/logo-white.svg\" alt=\"Logo\" />\n" +
    "                </div>\n" +
    "                <div class=\"logo-element\">\n" +
    "                    <img src=\"/images/logo-small.svg\" alt=\"Logo\" />\n" +
    "                </div>\n" +
    "            </li>\n" +
    "            <li class=\"institutions-menu\" ui-sref-active=\"{'active':'clients'}\">\n" +
    "                <a>\n" +
    "                    <span class=\"icon\"><i class=\"fa fa-user\"></i></span>\n" +
    "                    <span class=\"text\">Institutions</span>\n" +
    "                    <span class=\"fa arrow\"></span>\n" +
    "                </a>\n" +
    "                <ul class=\"nav nav-second-level collapse\">\n" +
    "                    <li ui-sref-active=\"{'active':'clients.profiles'}\">\n" +
    "                        <a ui-sref=\"clients.profiles\">\n" +
    "                            <span class=\"icon\"><i class=\"fa fa-book\"></i></span>\n" +
    "                            <span class=\"text\">Profiles</span>\n" +
    "                            <span class=\"fa arrow\"></span>\n" +
    "                        </a>\n" +
    "                        <ul class=\"nav nav-third-level\" ui-sref-active=\"{in: 'clients.profiles'}\">\n" +
    "                            <li>\n" +
    "                                <a href=\"https://qs-hub2.qs.com/#generaldetails\" ng-show=\"MainController.userHasAccessTo(MainController.handles['clients.profiles.shared'])\">\n" +
    "                                    <span class=\"icon\"><i class=\"fa fa-info\"></i></span>\n" +
    "                                    <span class=\"text\">Institution Details</span>\n" +
    "                                </a>\n" +
    "                            </li>\n" +
    "                            <!-- <li ui-sref-active=\"{'active':'clients.profiles.tu'}\" ng-show=\"isTuEnabled\" ng-if=\"MainController.isAdmin()\">\n" +
    "                                <a ui-sref=\"clients.profiles.tu\" ng-show=\"MainController.userHasAccessTo(MainController.handles['clients.profiles.tu'])\">\n" +
    "                                    <span class=\"icon\"><i class=\"fa fa-graduation-cap\"></i></span>\n" +
    "                                    <span class=\"text\">Undergraduate / Postgraduate</span>\n" +
    "                                </a>\n" +
    "                            </li> -->\n" +
    "                            <!-- <li ui-sref-active=\"{'active':'clients.profiles.tm'}\" ng-show=\"isTmEnabled\" ng-if=\"MainController.isAdmin()\">\n" +
    "                                <a ui-sref=\"clients.profiles.tm\" ng-show=\"MainController.userHasAccessTo(MainController.handles['clients.profiles.tm'])\">\n" +
    "                                    <span class=\"icon\"><i class=\"fa fa-suitcase\"></i></span>\n" +
    "                                    <span class=\"text\">MBA</span>\n" +
    "                                </a>\n" +
    "                            </li> -->\n" +
    "                        </ul>\n" +
    "                    </li>\n" +
    "\n" +
    "                    <li ui-sref-active=\"{'active':'clients.statistics'}\">\n" +
    "                        <a ui-sref=\"clients.statistics\">\n" +
    "                            <span class=\"icon\"><i class=\"fa fa-bar-chart\"></i></span>\n" +
    "                            <span class=\"text\">Statistics</span>\n" +
    "                            <span class=\"fa arrow\"></span>\n" +
    "                        </a>\n" +
    "                        <ul class=\"nav nav-third-level\" ui-sref-active=\"{in: 'clients.statistics'}\">\n" +
    "                            <li ui-sref-active=\"{'active':'clients.statistics.data-submission'}\">\n" +
    "                                <a ui-sref=\"clients.statistics.data-submission\" ng-show=\"MainController.userHasAccessTo(MainController.handles['clients.statistics'])\">\n" +
    "                                    <span class=\"icon\"><i class=\"fa fa-table\"></i></span>\n" +
    "                                    <span class=\"text\">Data submission</span>\n" +
    "                                </a>\n" +
    "                            </li>\n" +
    "\n" +
    "                            <li class=\"active rankings\" ng-show=\"MainController.subscribedRankingsList.length > 0\">\n" +
    "                                <a>\n" +
    "                                    <span class=\"icon\"><i class=\"fa fa-cube\"></i></span>\n" +
    "                                    <span class=\"text\">QS World University Rankings</span>\n" +
    "                                    <span class=\"fa arrow\"></span>\n" +
    "                                </a>\n" +
    "                                <ul class=\"nav nav-fourth-level collapse in\">\n" +
    "                                    <li ui-sref-active=\"active\"\n" +
    "                                        ng-if=\"MainController.userHasAccessTo(MainController.handles['clients.statistics'])\"\n" +
    "                                        ng-hide=\"(item.handle=== 'ger')\"\n" +
    "                                        ng-repeat=\"item in MainController.subscribedRankingsList | orderArray:false:'abbreviation' track by item.id\">\n" +
    "                                        <a ui-sref=\"clients.statistics.data-submission.{{item.handle}}\">\n" +
    "                                            <span class=\"icon\"><i class=\"fa fa-cube\"></i></span>\n" +
    "                                            <span class=\"text\">{{item.abbreviation}}</span>\n" +
    "                                        </a>\n" +
    "                                    </li>\n" +
    "                                </ul>\n" +
    "                            </li>\n" +
    "\n" +
    "                            <li ui-sref-active=\"{'active':'clients.statistics.stars'}\"\n" +
    "                                ng-show=\"MainController.userHasAccessToStars()\">\n" +
    "                                <a ui-sref=\"clients.statistics.stars\">\n" +
    "                                    <span class=\"icon\"><i class=\"fa fa-star\"></i></span>\n" +
    "                                    <span class=\"text\">QS Stars</span>\n" +
    "                                </a>\n" +
    "                            </li>\n" +
    "\n" +
    "                            <li ui-sref-active=\"{'active': 'clients.statistics.nominations'}\"\n" +
    "                                ng-show=\"MainController.userHasAccessTo(MainController.handles['clients.statistics'])\">\n" +
    "                                <a ui-sref=\"clients.statistics.nominations\">\n" +
    "                                    <span class=\"icon\"><i class=\"fa fa-list-ul\"></i></span>\n" +
    "                                    <span class=\"text\">Contact Lists</span>\n" +
    "                                    <span class=\"fa arrow\"></span>\n" +
    "                                </a>\n" +
    "                                <ul class=\"nav nav-fourth-level\" ui-sref-active=\"{'in': 'clients.statistics.nominations'}\">\n" +
    "                                    <li ui-sref-active=\"{'active':'clients.statistics.nominations.upload'}\">\n" +
    "                                        <a ui-sref=\"clients.statistics.nominations.upload\">\n" +
    "                                            <span class=\"icon\"><i class=\"fa fa-upload\"></i></span>\n" +
    "                                            <span class=\"text\">Upload</span>\n" +
    "                                        </a>\n" +
    "                                    </li>\n" +
    "                                    <li ui-sref-active=\"{'active':'clients.statistics.nominations.lists'}\">\n" +
    "                                        <a ui-sref=\"clients.statistics.nominations.lists\">\n" +
    "                                            <span class=\"icon\"><i class=\"fa fa-list-ul\"></i></span>\n" +
    "                                            <span class=\"text\">Lists</span>\n" +
    "                                        </a>\n" +
    "                                    </li>\n" +
    "                                </ul>\n" +
    "                            </li>\n" +
    "\n" +
    "                            <li ui-sref-active=\"{'active':'clients.statistics.messages'}\">\n" +
    "                                <a ui-sref=\"clients.statistics.messages\" ng-show=\"MainController.userHasAccessTo(MainController.handles['clients.statistics'])\">\n" +
    "                                    <span class=\"icon\"><i class=\"fa fa-envelope\"></i></span>\n" +
    "                                    <span class=\"text\">Messages</span>\n" +
    "                                </a>\n" +
    "                            </li>\n" +
    "\n" +
    "                        </ul>\n" +
    "                    </li>\n" +
    "                    <!-- <li ng-if=\"MainController.isAdmin()\" class=\"custom-popover\" ui-sref-active=\"{'active':'clients.contacts'}\">\n" +
    "                        <a ui-sref=\"clients.contacts\">\n" +
    "                            <span class=\"icon\"><i class=\"fa fa-users\"></i></span>\n" +
    "                            <span class=\"text\">Contacts</span>\n" +
    "                            <i class=\"fa fa-lock\" aria-hidden=\"true\"></i>\n" +
    "                        </a>\n" +
    "                    </li> -->\n" +
    "                </ul>\n" +
    "            </li>\n" +
    "            <li class=\"qs-menu\" ng-show=\"!isClient\" ui-sref-active=\"{'active':'staff'}\">\n" +
    "                <a>\n" +
    "                    <span class=\"icon\"><i class=\"fa fa-star\"></i></span>\n" +
    "                    <span class=\"text\">QS Staff</span>\n" +
    "                    <span class=\"fa arrow\"></span>\n" +
    "                </a>\n" +
    "                <ul class=\"nav nav-second-level collapse\">\n" +
    "                    <li ui-sref-active=\"{'active':'staff.dashboard'}\">\n" +
    "                        <a ui-sref=\"staff.dashboard\">\n" +
    "                            <span class=\"icon\"><i class=\"fa fa-th-large\"></i></span>\n" +
    "                            <span class=\"text\">Dashboard</span>\n" +
    "                        </a>\n" +
    "                    </li>\n" +
    "                    <li ui-sref-active=\"{'active':'staff.users'}\"\n" +
    "                        ng-show=\"MainController.userHasAccessToAny([ MainController.handles['staff.users.qs'], MainController.handles['staff.users.institutions'] ])\">\n" +
    "                        <a ui-sref=\"staff.users\">\n" +
    "                            <span class=\"icon\"><i class=\"fa fa-users\"></i></span>\n" +
    "                            <span class=\"text\">Users &amp; Permissions</span>\n" +
    "                            <span class=\"fa arrow\"></span>\n" +
    "                        </a>\n" +
    "                        <ul class=\"nav nav-third-level\" ui-sref-active=\"{in: 'staff.users'}\">\n" +
    "                            <li ui-sref-active=\"{'active':'staff.users.qs'}\">\n" +
    "                                <a ui-sref=\"staff.users.qs\" ng-show=\"MainController.userHasAccessTo(MainController.handles['staff.users.qs'])\">\n" +
    "                                    <span class=\"icon\"><i class=\"fa fa-user-secret\"></i></span>\n" +
    "                                    <span class=\"text\">QS Users</span>\n" +
    "                                </a>\n" +
    "                            </li>\n" +
    "                            <li ui-sref-active=\"{'active':'staff.users.institutions'}\">\n" +
    "                                <a ui-sref=\"staff.users.institutions\" ng-show=\"MainController.userHasAccessTo(MainController.handles['staff.users.institutions'])\">\n" +
    "                                    <span class=\"icon\"><i class=\"fa fa-graduation-cap\"></i></span>\n" +
    "                                    <span class=\"text\">Institution Users</span>\n" +
    "                                </a>\n" +
    "                            </li>\n" +
    "                        </ul>\n" +
    "                    </li>\n" +
    "                    <li ui-sref-active=\"{'active':'staff.institutions'}\"\n" +
    "                        ng-show=\"MainController.userHasAccessToAny([ MainController.handles['staff.institutions.list'], MainController.handles['staff.institutions.tu-programs'], MainController.handles['staff.institutions.department'] ])\">\n" +
    "                        <a ui-sref=\"staff.institutions\">\n" +
    "                            <span class=\"icon\"><i class=\"fa fa-bank\"></i></span>\n" +
    "                            <span class=\"text\">Institutions</span>\n" +
    "                            <span class=\"fa arrow\"></span>\n" +
    "                        </a>\n" +
    "                        <ul class=\"nav nav-third-level\" ui-sref-active=\"{in: 'staff.institutions'}\">\n" +
    "                            <li ui-sref-active=\"{'active':'staff.institutions.list'}\">\n" +
    "                                <a ui-sref=\"staff.institutions.list\" ng-show=\"MainController.userHasAccessTo(MainController.handles['staff.institutions.list'])\">\n" +
    "                                    <span class=\"icon\"><i class=\"fa fa-bars\"></i></span>\n" +
    "                                    <span class=\"text\">List</span>\n" +
    "                                </a>\n" +
    "                            </li>\n" +
    "                            <!-- <li ui-sref-active=\"{'active':'staff.institutions.department'}\" ng-if=\"SidebarController.hasDepartmentsOverviewAccess() && MainController.isAdmin()\">\n" +
    "                                <a ui-sref=\"staff.institutions.department\">\n" +
    "                                    <span class=\"icon\"><i class=\"fa fa-tasks\"></i></span>\n" +
    "                                    <span class=\"text\">Department Overview</span>\n" +
    "                                </a>\n" +
    "                            </li>\n" +
    "                            <li ui-sref-active=\"{'active':'staff.institutions.tu-programs'}\" ng-if=\"SidebarController.hasTuProgramsOverviewAccess() && MainController.isAdmin()\">\n" +
    "                                <a ui-sref=\"staff.institutions.tu-programs\">\n" +
    "                                    <span class=\"icon\"><i class=\"fa fa-server\"></i></span>\n" +
    "                                    <span class=\"text\">TU Programs Overview</span>\n" +
    "                                </a>\n" +
    "                            </li>\n" +
    "                            <li ui-sref-active=\"{'active':'staff.institutions.courses'}\" ng-if=\"SidebarController.hasTuProgramsOverviewAccess() && MainController.isAdmin()\">\n" +
    "                                <a ui-sref=\"staff.institutions.courses\">\n" +
    "                                    <span class=\"icon\"><i class=\"fa fa-server\"></i></span>\n" +
    "                                    <span class=\"text\">Matching Tool Overview</span>\n" +
    "                                </a>\n" +
    "                            </li>\n" +
    "                            <li ui-sref-active=\"{'active':'staff.institutions.tu-programs-url-checker'}\" ng-if=\"SidebarController.hasTuProgramsOverviewAccess() && MainController.isAdmin()\">\n" +
    "                                <a ui-sref=\"staff.institutions.tu-programs-url-checker\">\n" +
    "                                    <span class=\"icon\"><i class=\"fa fa-link\"></i></span>\n" +
    "                                    <span class=\"text\">TU Programs URL Checker</span>\n" +
    "                                </a>\n" +
    "                            </li> -->\n" +
    "                        </ul>\n" +
    "                    </li>\n" +
    "                    <!-- <li ui-sref-active=\"{'active':'staff.tm-directory'}\" ng-if=\"MainController.isAdmin()\"\n" +
    "                        ng-show=\"MainController.userHasAccessTo(MainController.handles['staff.tm-directory'])\">\n" +
    "                        <a ui-sref=\"staff.tm-directory\">\n" +
    "                            <span class=\"icon\"><i class=\"fa fa-tumblr\"></i></span>\n" +
    "                            <span class=\"text\">TM Directory</span>\n" +
    "                        </a>\n" +
    "                    </li> -->\n" +
    "                    <!-- <li ui-sref-active=\"{'active':'staff.master-password'}\" ng-if=\"MainController.isAdmin()\"\n" +
    "                        ng-show=\"MainController.userHasAccessTo(MainController.handles['staff.master-password'])\">\n" +
    "                        <a ui-sref=\"staff.master-password\">\n" +
    "                            <span class=\"icon\"><i class=\"fa fa-key\"></i></span>\n" +
    "                            <span class=\"text\">Master Password</span>\n" +
    "                        </a>\n" +
    "                    </li> -->\n" +
    "                    <li ui-sref-active=\"{'active':'staff.iu'}\"\n" +
    "                        ng-show=\"MainController.userHasAccessTo(MainController.handles['staff.iu']) ||\n" +
    "                        MainController.userHasAccessTo(MainController.handles['staff.iu.rankings-html'])\">\n" +
    "                        <a ui-sref=\"staff.iu\">\n" +
    "                            <span class=\"icon\"><i class=\"fa fa-users\"></i></span>\n" +
    "                            <span class=\"text\">IU Data</span>\n" +
    "                            <span class=\"fa arrow\"></span>\n" +
    "                        </a>\n" +
    "                        <ul class=\"nav nav-third-level\" ui-sref-active=\"{in: 'staff.iu'}\">\n" +
    "                            <li ui-sref-active=\"{'active':'staff.iu.rankings-dashboard'}\">\n" +
    "                                <a ui-sref=\"staff.iu.rankings-dashboard\" ng-show=\"MainController.userHasAccessTo(MainController.handles['staff.iu.rankings-dashboard'])\">\n" +
    "                                    <span class=\"icon\"><i class=\"fa fa-user-secret\"></i></span>\n" +
    "                                    <span class=\"text\">Rankings Dashboard</span>\n" +
    "                                </a>\n" +
    "                            </li>\n" +
    "                            <li ui-sref-active=\"{'active': 'staff.iu.progress-reports'}\">\n" +
    "                                <a ui-sref=\"staff.iu.progress-reports\">\n" +
    "                                    <span class=\"icon\"><i class=\"fa fa-database\"></i></span>\n" +
    "                                    <span class=\"text\">Progress Reports</span>\n" +
    "                                    <span class=\"fa arrow\"></span>\n" +
    "                                </a>\n" +
    "                                <ul class=\"nav nav-fourth-level\" ui-sref-active=\"{in: 'staff.iu.progress-reports'}\">\n" +
    "                                    <li ui-sref-active=\"{'active': 'staff.iu.progress-reports.ranking'}\">\n" +
    "                                        <a ui-sref=\"staff.iu.progress-reports.ranking\">\n" +
    "                                            <span class=\"icon\"><i class=\"fa fa-database\"></i></span>\n" +
    "                                            <span class=\"text\">By Ranking</span>\n" +
    "                                        </a>\n" +
    "                                    </li>\n" +
    "                                    <li ui-sref-active=\"{'active': 'staff.iu.progress-reports.country'}\">\n" +
    "                                        <a ui-sref=\"staff.iu.progress-reports.country\">\n" +
    "                                            <span class=\"icon\"><i class=\"fa fa-database\"></i></span>\n" +
    "                                            <span class=\"text\">By Country</span>\n" +
    "                                        </a>\n" +
    "                                    </li>\n" +
    "                                    <li ui-sref-active=\"{'active': 'staff.iu.progress-reports.institution'}\">\n" +
    "                                        <a ui-sref=\"staff.iu.progress-reports.institution\">\n" +
    "                                            <span class=\"icon\"><i class=\"fa fa-database\"></i></span>\n" +
    "                                            <span class=\"text\">By Institution</span>\n" +
    "                                        </a>\n" +
    "                                    </li>\n" +
    "                                </ul>\n" +
    "                            </li>\n" +
    "                            <li ui-sref-active=\"{'active':'staff.iu.rankings-information'}\">\n" +
    "                                <a ui-sref=\"staff.iu.rankings-information\"\n" +
    "                                   ng-show=\"MainController.userHasAccessTo(MainController.handles['staff.iu.rankings-information'])\">\n" +
    "                                    <span class=\"icon\"><i class=\"fa fa-cube\"></i></span>\n" +
    "                                    <span class=\"text\">Edit rankings information</span>\n" +
    "                                </a>\n" +
    "                            </li>\n" +
    "                            <li ui-sref-active=\"{'active': 'staff.iu.nominations'}\"\n" +
    "                                ng-show=\"MainController.userHasAccessTo(MainController.handles['staff.iu.nominations'])\">\n" +
    "                                <a ui-sref=\"staff.iu.nominations\">\n" +
    "                                    <span class=\"icon\"><i class=\"fa fa-list-ul\"></i></span>\n" +
    "                                    <span class=\"text\">Academic and Employer Contacts</span>\n" +
    "                                    <span class=\"fa arrow\"></span>\n" +
    "                                </a>\n" +
    "                                <ul class=\"nav nav-fourth-level\" ui-sref-active=\"{in: 'staff.iu.nominations'}\">\n" +
    "                                    <li ui-sref-active=\"{'active': 'staff.iu.nominations.reports'}\"\n" +
    "                                        ng-show=\"MainController.userHasAccessTo(MainController.handles['staff.iu.nominations.reports'])\">\n" +
    "                                        <a ui-sref=\"staff.iu.nominations.reports\">\n" +
    "                                            <span class=\"icon\"><i class=\"fa fa-database\"></i></span>\n" +
    "                                            <span class=\"text\">Uploaded Reports</span>\n" +
    "                                        </a>\n" +
    "                                    </li>\n" +
    "                                    <li ui-sref-active=\"{'active': 'staff.iu.nominations.lists'}\"\n" +
    "                                        ng-show=\"MainController.userHasAccessTo(MainController.handles['staff.iu.nominations.lists'])\">\n" +
    "                                        <a ui-sref=\"staff.iu.nominations.lists\">\n" +
    "                                            <span class=\"icon\"><i class=\"fa fa-list-ul\"></i></span>\n" +
    "                                            <span class=\"text\">Lists</span>\n" +
    "                                        </a>\n" +
    "                                    </li>\n" +
    "                                </ul>\n" +
    "                            </li>\n" +
    "                            <li ui-sref-active=\"{'active':'staff.iu.messages'}\">\n" +
    "                                <a ui-sref=\"staff.iu.messages\"\n" +
    "                                   ng-show=\"MainController.userHasAccessTo(MainController.handles['staff.iu.messages'])\">\n" +
    "                                      <span class=\"icon\"><i class=\"fa fa-envelope\"></i></span>\n" +
    "                                      <span class=\"text\">Messages</span>\n" +
    "                                </a>\n" +
    "                            </li>\n" +
    "                            <!-- <li ui-sref-active=\"{'active':'staff.iu.id-checker'}\"ng-if=\"MainController.isAdmin()\">\n" +
    "                                <a ui-sref=\"staff.iu.id-checker\" ng-show=\"MainController.userHasAccessTo(MainController.handles['staff.iu.id-checker'])\">\n" +
    "                                    <span class=\"icon\"><i class=\"fa fa-calendar-check-o\"></i></span>\n" +
    "                                    <span class=\"text\">Rankings ID Checker</span>\n" +
    "                                </a>\n" +
    "                            </li>\n" +
    "                            <li ui-sref-active=\"{'active':'staff.iu.rankings-scores'}\" ng-if=\"MainController.isAdmin()\">\n" +
    "                                <a ui-sref=\"staff.iu.rankings-scores\"\n" +
    "                                   ng-show=\"MainController.userHasAccessTo(MainController.handles['staff.iu.rankings-score'])\">\n" +
    "                                    <span class=\"icon\"><i class=\"fa fa-calculator\"></i></span>\n" +
    "                                    <span class=\"text\">Rankings Scores</span>\n" +
    "                                </a>\n" +
    "                            </li> -->\n" +
    "                            <li ui-sref-active=\"{'active':'staff.iu.classifications-publish'}\">\n" +
    "                                <a ui-sref=\"staff.iu.classifications-publish\"\n" +
    "                                   ng-show=\"MainController.userHasAccessTo(MainController.handles['staff.iu.classifications-publish'])\">\n" +
    "                                    <span class=\"icon\"><i class=\"fa fa-archive\"></i></span>\n" +
    "                                    <span class=\"text\">Classifications</span>\n" +
    "                                </a>\n" +
    "                            </li>\n" +
    "                            <li ui-sref-active=\"{'active':'staff.iu.classifications-overview'}\">\n" +
    "                                <a ui-sref=\"staff.iu.classifications-overview\"\n" +
    "                                   ng-show=\"MainController.userHasAccessTo(MainController.handles['staff.iu.classifications-overview'])\">\n" +
    "                                    <span class=\"icon\"><i class=\"fa fa-contao\"></i></span>\n" +
    "                                    <span class=\"text\">Classifications Overview</span>\n" +
    "                                </a>\n" +
    "                            </li>\n" +
    "                            <li ui-sref-active=\"{'active': 'staff.iu.rankings-html'}\"\n" +
    "                                ng-show=\"MainController.userHasAccessTo(MainController.handles['staff.iu.rankings-html'])\">\n" +
    "                                <a ui-sref=\"staff.iu.rankings-html\">\n" +
    "                                    <span class=\"icon\"><i class=\"fa fa-html5\"></i></span>\n" +
    "                                    <span class=\"text\">Rankings HTML</span>\n" +
    "                                </a>\n" +
    "                            </li>\n" +
    "                            <li ui-sref-active=\"{'active': 'staff.iu.bulk-ger-upload'}\"\n" +
    "                                ng-show=\"MainController.userHasAccessToGerBulk()\">\n" +
    "                                <a ui-sref=\"staff.iu.bulk-ger-upload\">\n" +
    "                                    <span class=\"icon\"><i class=\"fa fa-upload\"></i></span>\n" +
    "                                    <span class=\"text\">GER Bulk Upload</span>\n" +
    "                                </a>\n" +
    "                            </li>\n" +
    "                            <li ui-sref-active=\"{'active': 'staff.iu.scopus'}\"\n" +
    "                                ng-show=\"MainController.userHasAccessTo(MainController.handles['staff.iu'])\">\n" +
    "                                <a ui-sref=\"staff.iu.scopus\">\n" +
    "                                    <span class=\"icon\"><i class=\"fa fa-database\"></i></span>\n" +
    "                                    <span class=\"text\">Scopus</span>\n" +
    "                                    <span class=\"fa arrow\"></span>\n" +
    "                                </a>\n" +
    "                                <ul class=\"nav nav-fourth-level\" ui-sref-active=\"{in: 'staff.iu.scopus'}\">\n" +
    "                                    <li ui-sref-active=\"{'active': 'staff.iu.scopus.manage-ids'}\">\n" +
    "                                        <a ui-sref=\"staff.iu.scopus.manage-ids\">\n" +
    "                                            <span class=\"icon\"><i class=\"fa fa-list-ul\"></i></span>\n" +
    "                                            <span class=\"text\">Manage ID's</span>\n" +
    "                                        </a>\n" +
    "                                    </li>\n" +
    "                                    <li ui-sref-active=\"{'active': 'staff.iu.scopus.statistics'}\">\n" +
    "                                        <a ui-sref=\"staff.iu.scopus.statistics\">\n" +
    "                                            <span class=\"icon\"><i class=\"fa fa-database\"></i></span>\n" +
    "                                            <span class=\"text\">Statistics</span>\n" +
    "                                        </a>\n" +
    "                                    </li>\n" +
    "                                </ul>\n" +
    "                            </li>\n" +
    "                        </ul>\n" +
    "                    </li>\n" +
    "                    <li ui-sref-active=\"{active: 'staff.qs-stars'}\"\n" +
    "                        ng-show=\"MainController.userHasAccessTo(MainController.handles['staff.qs-stars'])\">\n" +
    "                        <a ui-sref=\"staff.iu.qs-stars\">\n" +
    "                            <span class=\"icon\"><i class=\"fa fa-star\"></i></span>\n" +
    "                            <span class=\"text\">QS Stars</span>\n" +
    "                            <span class=\"fa arrow\"></span>\n" +
    "                        </a>\n" +
    "                        <ul class=\"nav nav-third-level\" ui-sref-active=\"{in: 'staff.qs-stars'}\">\n" +
    "                            <li ui-sref-active=\"{'active': 'staff.qs-stars.institutions-list'}\"\n" +
    "                                ng-show=\"MainController.userHasAccessTo(MainController.handles['staff.qs-stars.institutions-list'])\">\n" +
    "                                <a ui-sref=\"staff.qs-stars.institutions-list\">\n" +
    "                                    <span class=\"icon\"><i class=\"fa fa-list-ul\"></i></span>\n" +
    "                                    <span class=\"text\">Institutions List</span>\n" +
    "                                </a>\n" +
    "                            </li>\n" +
    "                            <li ui-sref-active=\"{'active': 'staff.qs-stars.upload.start'}\"\n" +
    "                                ng-show=\"MainController.userHasAccessTo(MainController.handles['staff.qs-stars.upload.start'])\">\n" +
    "                                <a ui-sref=\"staff.qs-stars.upload.start\">\n" +
    "                                    <span class=\"icon\"><i class=\"fa fa-upload\"></i></span>\n" +
    "                                    <span class=\"text\">Upload Badges & Documents</span>\n" +
    "                                </a>\n" +
    "                            </li>\n" +
    "                            <li ui-sref-active=\"{'active': 'staff.qs-stars.badges-overview'}\"\n" +
    "                                ng-show=\"MainController.userHasAccessTo(MainController.handles['staff.qs-stars.badges-overview'])\">\n" +
    "                                <a ui-sref=\"staff.qs-stars.badges-overview\">\n" +
    "                                    <span class=\"icon\"><i class=\"fa fa-server\"></i></span>\n" +
    "                                    <span class=\"text\">Badges Overview</span>\n" +
    "                                </a>\n" +
    "                            </li>\n" +
    "                            <li ui-sref-active=\"{'active': 'staff.qs-stars.publish-to-site'}\">\n" +
    "                                <a ui-sref=\"staff.qs-stars.publish-to-site\">\n" +
    "                                    <span class=\"icon\"><i class=\"fa fa-server\"></i></span>\n" +
    "                                    <span class=\"text\">Publish to Site</span>\n" +
    "                                </a>\n" +
    "                            </li>\n" +
    "                        </ul>\n" +
    "                    </li>\n" +
    "                    <li ui-sref-active=\"{active: 'staff.igauge'}\"\n" +
    "                        ng-show=\"MainController.userHasAccessTo(MainController.handles['staff.igauge'])\">\n" +
    "                        <a ui-sref=\"staff.igauge\">\n" +
    "                            <span class=\"icon\"><i class=\"fa fa-clipboard\"></i></span>\n" +
    "                            <span class=\"text\">IGauge</span>\n" +
    "                            <span class=\"fa arrow\"></span>\n" +
    "                        </a>\n" +
    "                        <ul class=\"nav nav-third-level\" ui-sref-active=\"{in: 'staff.igauge'}\">\n" +
    "                            <li ui-sref-active=\"{'active': 'staff.igauge.igauge_list'}\"\n" +
    "                                ng-show=\"MainController.userHasAccessTo(MainController.handles['staff.igauge.igauge_list'])\">\n" +
    "                                <a ui-sref=\"staff.igauge.igauge_list\">\n" +
    "                                    <span class=\"icon\"><i class=\"fa fa-list-ul\"></i></span>\n" +
    "                                    <span class=\"text\">List</span>\n" +
    "                                </a>\n" +
    "                            </li>\n" +
    "                            <li ui-sref-active=\"{'active': 'staff.igauge.igauge_sync'}\"\n" +
    "                                ng-show=\"MainController.userHasAccessTo(MainController.handles['staff.igauge.igauge_sync'])\">\n" +
    "                                <a ui-sref=\"staff.igauge.igauge_sync\">\n" +
    "                                    <span class=\"icon\"><i class=\"fa fa-refresh\"></i></i></span>\n" +
    "                                    <span class=\"text\">Sync</span>\n" +
    "                                </a>\n" +
    "                            </li>\n" +
    "                        </ul>\n" +
    "                    </li>\n" +
    "                    <li ui-sref-active=\"{'active':'staff.members-db'}\"\n" +
    "                        ng-show=\"MainController.userHasAccessTo(MainController.handles['staff.members-db.leads-export'])\">\n" +
    "                        <a ui-sref=\"staff.members-db\">\n" +
    "                            <span class=\"icon\"><i class=\"fa fa-database\"></i></span>\n" +
    "                            <span class=\"text\">Members DB</span>\n" +
    "                            <span class=\"fa arrow\"></span>\n" +
    "                        </a>\n" +
    "                        <ul class=\"nav nav-third-level\" ui-sref-active=\"{in: 'staff.members-db'}\">\n" +
    "                            <li ui-sref-active=\"{'active': 'staff.members-db.leads-export'}\">\n" +
    "                                <a ui-sref=\"staff.members-db.leads-export\">\n" +
    "                                    <span class=\"icon\"><i class=\"fa fa-download\"></i></span>\n" +
    "                                    <span class=\"text\">School Leads Export</span>\n" +
    "                                </a>\n" +
    "                            </li>\n" +
    "                            <li ui-sref-active=\"{'active': 'staff.members-db.matching-tool-leads-export'}\">\n" +
    "                                <a ui-sref=\"staff.members-db.matching-tool-leads-export\">\n" +
    "                                    <span class=\"icon\"><i class=\"fa fa-download\"></i></span>\n" +
    "                                    <span class=\"text\">Matching Tool Leads Export (Until 11/02/2021)</span>\n" +
    "                                </a>\n" +
    "                            </li>\n" +
    "                            <li ui-sref-active=\"{'active': 'staff.members-db.matching-tool-leads-export-new'}\">\n" +
    "                                <a ui-sref=\"staff.members-db.matching-tool-leads-export-new\">\n" +
    "                                    <span class=\"icon\"><i class=\"fa fa-download\"></i></span>\n" +
    "                                    <span class=\"text\">Matching Tool Leads Export (From 12/07/2021)</span>\n" +
    "                                </a>\n" +
    "                            </li>\n" +
    "                            <li ui-sref-active=\"{'active': 'staff.members-db.student-guide-export'}\">\n" +
    "                                <a ui-sref=\"staff.members-db.student-guide-export\">\n" +
    "                                    <span class=\"icon\"><i class=\"fa fa-download\"></i></span>\n" +
    "                                    <span class=\"text\">Student Guide Export</span>\n" +
    "                                </a>\n" +
    "                            </li>\n" +
    "                        </ul>\n" +
    "                    </li>\n" +
    "                </ul>\n" +
    "            </li>\n" +
    "            <li ng-if=\"SidebarController.showQaSection\" class=\"qa-menu\" ui-sref-active=\"{'active':'qa'}\">\n" +
    "                <a>\n" +
    "                    <span class=\"icon\"><i class=\"fa fa-android\"></i></span>\n" +
    "                    <span class=\"text\">QA</span>\n" +
    "                    <span class=\"fa arrow\"></span>\n" +
    "                </a>\n" +
    "                <ul class=\"nav nav-second-level collapse\" ui-sref-active=\"{'in':'qa'}\">\n" +
    "                    <li ui-sref-active=\"{'active':'qa.publish-config'}\">\n" +
    "                        <a ui-sref=\"qa.publish-config\">\n" +
    "                            <span class=\"icon\"><i class=\"fa fa-connectdevelop\"></i></span>\n" +
    "                            <span class=\"text\">Publish Config</span>\n" +
    "                        </a>\n" +
    "                    </li>\n" +
    "                </ul>\n" +
    "            </li>\n" +
    "\n" +
    "        </ul>\n" +
    "    </div>\n" +
    "</nav>\n"
  );


  $templateCache.put('/scripts/shared/topNavigationBar/topNavigationBarView.html',
    "<nav class=\"navbar navbar-static-top\" role=\"navigation\">\n" +
    "\n" +
    "    <!-- START NAVBAR HEADFER -->\n" +
    "    <div class=\"navbar-header\">\n" +
    "        <span minimaliza-sidebar=\"\"></span>\n" +
    "    </div><!-- END NAVBAR HEADFER -->\n" +
    "\n" +
    "    <!-- START USER ACCOUNT/PROFILE -->\n" +
    "    <div class=\"user-profile\" uib-dropdown>\n" +
    "        <a class=\"truncate\" href=\"#\" title=\"{{MainController.fullName}}\" uib-dropdown-toggle>\n" +
    "            <span class=\"user-dp\">\n" +
    "                <img class=\"img-circle\" ng-hide=\"MainController.profileLogo()\" alt=\"image\" gravatar-src=\"email\" gravatar-size=\"30\">\n" +
    "                <img class=\"img-circle\" ng-show=\"MainController.profileLogo()\" ng-src=\"{{MainController.profileLogo()}}\" alt=\"image\" style=\"width: 30px; height: 30px;\">\n" +
    "            </span>\n" +
    "            <span class=\"option\" ng-bind=\"MainController.fullName\"></span>\n" +
    "            <i class=\"fa fa-angle-down\"></i>\n" +
    "        </a>\n" +
    "        <ul class=\"dropdown-menu\">\n" +
    "            <!-- <li>\n" +
    "                <a ui-sref=\"user.my-profile\">\n" +
    "                    <i class=\"fa fa-user\"></i>\n" +
    "                    <span>My Profile</span>\n" +
    "                </a>\n" +
    "            </li> -->\n" +
    "            <li>\n" +
    "                <a href=\"https://qs-hub2.qs.com/wp-login.php\" ng-click=\"MainController.handleLogout()\">\n" +
    "                  <i class=\"fa fa-sign-out\"></i>\n" +
    "                  <span>Log out</span>\n" +
    "                </a>\n" +
    "            </li>\n" +
    "        </ul>\n" +
    "    </div><!-- END USER ACCOUNT/PROFILE -->\n" +
    "\n" +
    "    <!-- START SEARCH HELP -->\n" +
    "    <div class=\"user-help\">\n" +
    "        <a href=\"javascript:void(0)\" ng-click=\"helpIcon('md')\" title=\"Help\">\n" +
    "          <span>Help</span>\n" +
    "          <i class=\"fa fa-question-circle-o\"></i>\n" +
    "        </a>\n" +
    "    </div><!-- END SEARCH HELP -->\n" +
    "\n" +
    "    <!-- START SEARCHBAR -->\n" +
    "    <div class=\"search-dropdown\" title=\"Institution search\">\n" +
    "        <!-- Institution switch for QS users -->\n" +
    "        <ui-select\n" +
    "            theme=\"bootstrap\"\n" +
    "            ng-if=\"!isClient\"\n" +
    "            ng-model=\"institution.selected\"\n" +
    "            ng-change=\"handleSearchInstitutionClick(institution.selected)\"\n" +
    "            ng-disabled=\"!MainController.qsUserHasAccessToClientSections()\">\n" +
    "\n" +
    "            <ui-select-match placeholder=\"Institution search...\">\n" +
    "                <i class=\"fa fa-building\" aria-hidden=\"true\"></i>\n" +
    "                {{$select.selected.name}}\n" +
    "            </ui-select-match>\n" +
    "\n" +
    "            <ui-select-choices\n" +
    "              refresh-delay=\"500\"\n" +
    "              refresh=\"searchInstitutions($select.search)\"\n" +
    "              position=\"down\"\n" +
    "              repeat=\"option in institutionsDropdownList | filter: $select.search\">\n" +
    "              <span class=\"option\" ng-bind-html=\"option.name | highlight: $select.search\"></span>\n" +
    "            </ui-select-choices>\n" +
    "\n" +
    "        </ui-select>\n" +
    "\n" +
    "        <!-- Institution switch for Clients -->\n" +
    "        <ui-select\n" +
    "          theme=\"bootstrap\"\n" +
    "          ng-if=\"isClient\"\n" +
    "          ng-model=\"institution.selected\"\n" +
    "          on-select=\"handleSearchInstitutionClick(institution.selected)\">\n" +
    "\n" +
    "            <ui-select-match placeholder=\"Institution search...\">\n" +
    "                <i class=\"fa fa-building\" aria-hidden=\"true\"></i>\n" +
    "                {{$select.selected.name}}\n" +
    "            </ui-select-match>\n" +
    "\n" +
    "            <ui-select-choices class=\"search-dropdown-results\"\n" +
    "              repeat=\"option in institutionsDropdownList | filter: $select.search\"\n" +
    "              position=\"down\">\n" +
    "              <span class=\"option\" ng-bind-html=\"option.name | highlight: $select.search\"></span>\n" +
    "            </ui-select-choices>\n" +
    "\n" +
    "        </ui-select>\n" +
    "        <div class=\"spinner\" ng-show=\"searchInProgress\" wave-spinner=\"\"></div>\n" +
    "    </div><!-- END SEARCHBAR -->\n" +
    "\n" +
    "    <!-- START GO BACK BUTTON -->\n" +
    "    \n" +
    "    <div class=\"go-back\" ng-if=\"MainController.allowParent()\">\n" +
    "      <button type=\"button\" class=\"btn btn-warning\" ng-click=\"handleGoToParentInstitution()\" title=\"Go to Parent Institution\">\n" +
    "          <i class=\"glyphicon glyphicon-upload\"></i>\n" +
    "          <span>Go to Parent Institution</span>\n" +
    "      </button>\n" +
    "    </div><!-- END GO BACK BUTTON -->\n" +
    "</nav>"
  );


  $templateCache.put('/scripts/shared/ui-grid/templates/dateCellTemplate.html',
    "<div class=\"ui-grid-cell-contents\">{{COL_FIELD ? (COL_FIELD CUSTOM_FILTERS | date:\"medium\") : ''}}</div>\n"
  );


  $templateCache.put('/scripts/shared/ui-grid/templates/dateCellTemplateLastCreatedAt.html',
    "<div class=\"ui-grid-cell-contents\">\n" +
    "    <span>{{row.entity.lastCreatedAt | date:\"medium\"}}</span>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/shared/ui-grid/templates/dateFilterCellTemplate.html',
    "<div class=\"ui-grid-cell-contents\">{{COL_FIELD CUSTOM_FILTERS | date:\"medium\"}}</div>"
  );


  $templateCache.put('/scripts/shared/ui-grid/templates/dateFilterHeaderTemplate.html',
    "<div class=\"ui-grid-filter-container\" ng-repeat=\"colFilter in col.filters\" ng-if=\"colFilter.visible\">\n" +
    "    <input type=\"text\" class=\"ui-grid-filter-input date-picker ui-grid-filter-input-{{$index}}\"\n" +
    "           ng-model=\"grid.appScope.filters[col.field + 'Range']\"\n" +
    "           date-range-picker=\"\"\n" +
    "           date-range-fixer\n" +
    "           readonly\n" +
    "           options=\"{eventHandlers: {'apply.daterangepicker': grid.appScope['handle' + (col.field | capitaliseFirstLetter) + 'Range']}, opens: 'left'}\"/>\n" +
    "\n" +
    "    <div class=\"ui-grid-filter-button\" ng-click=\"removeFilter(colFilter, $index);grid.appScope.filters[col.field + 'Range'] = {};\" >\n" +
    "            <i class=\"ui-grid-icon-cancel\"   ng-show=\"colFilter.term !== undefined && colFilter.term != null && colFilter.term != ''\"></i>\n" +
    "            <span class=\"ui-grid-icon-date\"  ng-show=\"colFilter.term == undefined || colFilter.term == null || colFilter.term == ''\"></span>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/shared/ui-grid/templates/deleteCellTemplate.html',
    "<div class=\"ui-grid-cell-contents no-select text-center\" ng-click=\"$event.stopPropagation()\">\n" +
    "    <input ng-model=\"grid.appScope.itemsToDelete[row.entity.id]\" i-checkbox type=\"checkbox\">\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/shared/ui-grid/templates/deleteHeaderCellTemplate.html',
    "<button type=\"button\"\n" +
    "        ng-disabled=\"grid.appScope.isDeleteButtonDisabled()\"\n" +
    "        class=\"btn btn-danger btn-sm\"\n" +
    "        ng-class=\"{'disabled': grid.appScope.isDeleteButtonDisabled()}\"\n" +
    "        ng-click=\"grid.appScope.handleDeleteClick()\">\n" +
    "    <span class=\"glyphicon glyphicon-trash\"></span>\n" +
    "</button>\n"
  );


  $templateCache.put('/scripts/shared/ui-grid/templates/multiSelectFilterHeaderTemplate.html',
    "<div class=\"ui-grid-filter-container\" ng-repeat=\"colFilter in col.filters\">\n" +
    "    <ui-select ng-if=\"!colFilter.bindToField\"\n" +
    "        multiple\n" +
    "        class=\"ui-grid-filter-select ui-grid-filter-input-{{$index}} select-{{col.field}}\"\n" +
    "        close-on-select=\"false\"\n" +
    "        ng-model=\"colFilter.term\"\n" +
    "        theme=\"bootstrap\"\n" +
    "        append-to-body=\"true\">\n" +
    "            <ui-select-match>{{$item.label}}</ui-select-match>\n" +
    "            <ui-select-choices repeat=\"item in (colFilter.selectOptions | filter: $select.search) track by item.value\">\n" +
    "                <div ng-bind=\"item.label\"></div>\n" +
    "            </ui-select-choices>\n" +
    "            <ui-select-no-choice>\n" +
    "                Not found\n" +
    "            </ui-select-no-choice>\n" +
    "    </ui-select>\n" +
    "\n" +
    "    <ui-select ng-if=\"colFilter.bindToField\"\n" +
    "        multiple\n" +
    "        class=\"ui-grid-filter-select ui-grid-filter-input-{{$index}} select-{{col.field}}\"\n" +
    "        ng-model=\"colFilter.term\"\n" +
    "        theme=\"bootstrap\"\n" +
    "        search-enabled=\"{{colFilter.searchEnabled || false}}\"\n" +
    "        append-to-body=\"true\">\n" +
    "        <ui-select-match placeholder=\"{{colFilter.placeholder}}\">\n" +
    "            {{$item.label}}\n" +
    "        </ui-select-match>\n" +
    "        <ui-select-choices repeat=\"item.value as item in (colFilter.selectOptions | filter: $select.search) track by $index\">\n" +
    "            <div ng-bind=\"item.label\"></div>\n" +
    "        </ui-select-choices>\n" +
    "    </ui-select>\n" +
    "\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/shared/ui-grid/templates/percentageCellTemplate.html',
    "<div class=\"ui-grid-cell-contents\">{{COL_FIELD CUSTOM_FILTERS}} %</div>\n"
  );


  $templateCache.put('/scripts/shared/ui-grid/templates/publishCellTemplate.html',
    "<div class=\"ui-grid-cell-contents no-select text-center\" ng-click=\"$event.stopPropagation()\">\n" +
    "    <a class=\"btn btn-primary btn-xs\" ng-click=\"grid.appScope.handlePublish(row.entity)\">\n" +
    "        <i class=\"fa fa-cloud-upload\"></i>\n" +
    "        <span>Publish</span>\n" +
    "    </a>\n" +
    "</div>\n" +
    "    "
  );


  $templateCache.put('/scripts/shared/ui-grid/templates/rowTemplate.html',
    "<div grid=\"grid\" ng-class=\"{unread: row.entity.unread}\">\n" +
    "    <div class=\"ui-grid-cell pointer\"\n" +
    "        ng-repeat=\"(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name\"\n" +
    "        ng-class=\"{'ui-grid-row-header-cell': col.isRowHeader, 'active': grid.appScope.selectedRowId && row.entity.id === grid.appScope.selectedRowId}\"\n" +
    "        role=\"{{col.isRowHeader ? 'rowheader' : 'gridcell'}}\"\n" +
    "        ui-grid-cell>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/shared/ui-grid/templates/selectCellTemplate.html',
    "<div class=\"ui-grid-cell-contents\" ng-bind-html=\"COL_FIELD CUSTOM_FILTERS | boolToText:col.filter.selectOptions\"></div>\n"
  );


  $templateCache.put('/scripts/shared/ui-grid/templates/selectCountCellTemplate.html',
    "<div class=\"ui-grid-cell-contents\" ng-bind-html=\"((COL_FIELD).length ? true : false) CUSTOM_FILTERS | boolToText:col.filter.selectOptions\"></div>\n"
  );


  $templateCache.put('/scripts/shared/ui-grid/templates/selectFilterHeaderTemplate.html',
    "<div class=\"ui-grid-filter-container\"\n" +
    "     ng-repeat=\"colFilter in col.filters\"\n" +
    "     ng-class=\"{'ui-grid-filter-cancel-button-hidden' : colFilter.disableCancelFilterButton === true }\">\n" +
    "\n" +
    "    <ui-select ng-if=\"!colFilter.bindToField\" class=\"ui-grid-filter-select ui-grid-filter-input-{{$index}} select-{{col.field}}\"\n" +
    "        ng-model=\"colFilter.term\"\n" +
    "        theme=\"bootstrap\"\n" +
    "        search-enabled=\"{{colFilter.searchEnabled || false}}\"\n" +
    "        append-to-body=\"true\">\n" +
    "        <ui-select-match placeholder=\"{{colFilter.placeholder}}\">\n" +
    "            <span ng-bind-html=\"$select | uiGridSelectedLabel\"></span>\n" +
    "        </ui-select-match>\n" +
    "        <ui-select-choices\n" +
    "                repeat=\"item in (colFilter.selectOptions | filter: $select.search | orderBy: colFilter.orderBy) track by item.value\"\n" +
    "                ui-disable-choice=\"item.disabled\">\n" +
    "            <div ng-bind-html=\"item.label\"></div>\n" +
    "        </ui-select-choices>\n" +
    "    </ui-select>\n" +
    "\n" +
    "    <ui-select ng-if=\"colFilter.bindToField\" class=\"ui-grid-filter-select ui-grid-filter-input-{{$index}} select-{{col.field}}\"\n" +
    "               ng-model=\"colFilter.term\"\n" +
    "               theme=\"bootstrap\"\n" +
    "               search-enabled=\"{{colFilter.searchEnabled || false}}\"\n" +
    "               append-to-body=\"true\">\n" +
    "        <ui-select-match placeholder=\"{{colFilter.placeholder}}\">\n" +
    "            {{$select | uiGridSelectedLabel}}\n" +
    "        </ui-select-match>\n" +
    "        <ui-select-choices repeat=\"item.value as item in (colFilter.selectOptions | filter: $select.search) track by item.value\">\n" +
    "            <div ng-bind=\"item.label\"></div>\n" +
    "        </ui-select-choices>\n" +
    "    </ui-select>\n" +
    "\n" +
    "    <div role=\"button\" class=\"ui-grid-filter-button-select\"\n" +
    "         ng-click=\"removeFilter(colFilter, $index)\"\n" +
    "         ng-if=\"!colFilter.disableCancelFilterButton\"\n" +
    "         ng-disabled=\"colFilter.term === undefined || colFilter.term === null || colFilter.term.length === 0\"\n" +
    "         ng-show=\"colFilter.term !== undefined && colFilter.term != null && colFilter.term.length !== 0\">\n" +
    "        <i class=\"ui-grid-icon-cancel\" ui-grid-one-bind-aria-label=\"aria.removeFilter\">&nbsp;</i>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/shared/ui-grid/templates/YesNoCellTemplate.html',
    "<div class=\"ui-grid-cell-contents\">{{COL_FIELD ? 'Yes' : 'No'}}</div>"
  );


  $templateCache.put('/scripts/shared/ui-grid/uiGridInfo/uiGridInfoPopupView.html',
    "<h5 ng-if=\"!$ctrl.isNominationsReportsPage()\">\n" +
    "    Special search characters\n" +
    "</h5>\n" +
    "<ul ng-if=\"$ctrl.isNominationsReportsPage()\">\n" +
    "    <li><h5>Click into a record to see more detailed information</h5></li>\n" +
    "    <li><h5>Special search characters</h5></li>\n" +
    "</ul>\n" +
    "<table class=\"table table-striped no-margins\">\n" +
    "    <thead>\n" +
    "        <tr>\n" +
    "            <th>Character</th>\n" +
    "            <th>Explanation</th>\n" +
    "            <th>Example</th>\n" +
    "        </tr>\n" +
    "    </thead>\n" +
    "    <tbody>\n" +
    "        <tr>\n" +
    "            <td>=</td>\n" +
    "            <td>search for exact result</td>\n" +
    "            <td>=John Smith</td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    "            <td>%</td>\n" +
    "            <td>search using word pattern matching</td>\n" +
    "            <td>Test Univ%sity</td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    "            <td>,</td>\n" +
    "            <td>search for one or more</td>\n" +
    "            <td>John,Tom,Olga</td>\n" +
    "        </tr>\n" +
    "        <tr>\n" +
    "            <td>&amp;</td>\n" +
    "            <td>search for combination of</td>\n" +
    "            <td>London&University</td>\n" +
    "        </tr>\n" +
    "    </tbody>\n" +
    "</table>\n"
  );


  $templateCache.put('/scripts/shared/ui-grid/uiGridInfo/uiGridInfoView.html',
    "<span class=\"info pull-right clickable\"\n" +
    "      popover-placement=\"left-top\"\n" +
    "      popover-append-to-body=\"true\"\n" +
    "      popover-class=\"ui-grid-info\"\n" +
    "      uib-popover-template=\"'/scripts/shared/ui-grid/uiGridInfo/uiGridInfoPopupView.html'\">\n" +
    "    <i class=\"fa fa-info-circle\"></i>\n" +
    "</span>"
  );


  $templateCache.put('/scripts/shared/unsavedChanges/unsavedChangesView.html',
    "<div class=\"modal-body\">\n" +
    "    <p>Do you want to save changes?</p>\n" +
    "</div>\n" +
    "<div class=\"modal-footer\">\n" +
    "    <button ng-click=\"modalOptions.handleNoClick()\" type=\"button\" class=\"btn btn-default\" >\n" +
    "        No\n" +
    "    </button>\n" +
    "    <button class=\"btn btn-primary\" ng-click=\"modalOptions.handleYesClick();\">\n" +
    "        Yes\n" +
    "    </button>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/shared/upgradeBanner/upgradeBannerView.html',
    "<div class=\"alert alert-success\" ng-if=\"basicProfile()\">\n" +
    "    <div class=\"row\">\n" +
    "        <div ng-class=\"getInfoBlockClass()\">\n" +
    "            <span class=\"block m-t-xs\">\n" +
    "                <i class=\"fa fa-star\"></i>\n" +
    "                Unlock 20+ features with our paid Advanced Profile options and give candidates access to key decision-making information about your institution. Speak to a representative to discuss upgrade options and pricing.\n" +
    "                Explore full list of features of an Advanced Profile \n" +
    "                <a ng-if=\"!tmProfile()\" href=\" http://www.qs.com/topuniversity-solutions-overview/tu-advanced-profile\" target=\"_blank\">here</a>\n" +
    "                <a ng-if=\"tmProfile()\" href=\" http://www.qs.com/business-school-marketing/tm-advanced-profile/\" target=\"_blank\">here</a>\n" +
    "            </span>\n" +
    "        </div>\n" +
    "        <div ng-class=\"getButtonsBlockClass()\">\n" +
    "            <a class=\"btn btn-primary btn-sm btn-block\" ng-click=\"upgradeClick()\">\n" +
    "                <i class=\"fa fa-star\"></i>\n" +
    "                <span>Request Pricing</span>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/iGauge/components/institutionsList/iGaugeInstitutionsListView.html',
    "<div class=\"wrapper wrapper-content animated fadeInRight igauge-institution-list\">\n" +
    "    <div class=\"row\">\n" +
    "        <div id=\"igaugeTable\" ng-class=\"InstitutionListController.isRightSidePanelActive() ? 'col-lg-8 col-sm-6' : 'col-sm-12'\">\n" +
    "            <div class=\"section\">\n" +
    "                <div wave-spinner class=\"absolute-wave-spinner\" ng-show=\"InstitutionListController.fetchInProgress\"></div>\n" +
    "                <div class=\"section-body float-e-margins\" ng-class=\"{ 'modal-overlay-35': InstitutionListController.fetchInProgress }\">\n" +
    "                    <div ng-if=\"InstitutionListController.gridOptions\">\n" +
    "                        <ui-grid-info></ui-grid-info>\n" +
    "                        <div class=\"grid\"\n" +
    "                                ui-grid=\"InstitutionListController.gridOptions\"\n" +
    "                                ui-grid-selection\n" +
    "                                ui-grid-resize-columns\n" +
    "                                ui-grid-auto-resize\n" +
    "                                ui-grid-cellnav\n" +
    "                                ui-grid-exporter\n" +
    "                                ui-grid-pagination></div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <igauge-right-side-panel class=\"col-lg-4 col-sm-6 right-side-container\" fixed-element-while-scrolling=\"#igaugeTable\"\n" +
    "            ng-if=\"InstitutionListController.isRightSidePanelActive()\"\n" +
    "            selected-item=\"InstitutionListController.selectedItem\"\n" +
    "            handle-publish=\"InstitutionListController.handlePublish()\">\n" +
    "        </igauge-right-side-panel>\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n"
  );


  $templateCache.put('/scripts/modules/iGauge/components/institutionsList/rightSidePanel/iGaugeRightSidePanelView.html',
    "<div class=\"ibox matching-tool-right-side-panel\">\n" +
    "    <div class=\"ibox-title m-t-xl\">\n" +
    "        <h5>IGauge Rating</h5>\n" +
    "        <div class=\"ibox-tools\">\n" +
    "            <a ng-click=\"$ctrl.closeRightSidePanel()\">\n" +
    "                <i class=\"fa fa-times\"></i>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"ibox-content\">\n" +
    "        <h2 class=\"m-b\">{{ $ctrl.selectedItem.institutionName }}</h2>\n" +
    "        <div class=\"form-group no-margins\">\n" +
    "            <div class=\"row\">\n" +
    "                <label class=\"control-label col-sm-6\">\n" +
    "                    Core ID:\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-6 word-break-all\">\n" +
    "                    {{ $ctrl.selectedItem.coreId }}\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "                <label class=\"control-label col-sm-6\">\n" +
    "                    Institute Type:\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-6 word-break-all\">\n" +
    "                    {{ $ctrl.selectedItem.institutionType | capitalize }}\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "                <label class=\"control-label col-sm-6\">\n" +
    "                    State or Union Territory:\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-6 word-break-all\">\n" +
    "                    {{ $ctrl.selectedItem.state }}\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "                <label class=\"control-label col-sm-6\">\n" +
    "                    Is New:\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-6 word-break-all\">\n" +
    "                    {{ $ctrl.selectedItem.isNew ? 'Yes' : 'No' }}\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "                <label class=\"control-label col-sm-6\">\n" +
    "                    Overall Rating:\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-6 word-break-all\">\n" +
    "                    {{ $ctrl.selectedItem.overallRating | ratings}}\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "                <label class=\"control-label col-sm-6\">\n" +
    "                    Teaching and Learning:\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-6 word-break-all\">\n" +
    "                    {{ $ctrl.selectedItem.teachingAndLearning | ratings}}\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "                <label class=\"control-label col-sm-6\">\n" +
    "                    Faculty Quality:\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-6 word-break-all\">\n" +
    "                    {{ $ctrl.selectedItem.facultyQuality | ratings}}\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "                <label class=\"control-label col-sm-6\">\n" +
    "                    Employability:\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-6 word-break-all\">\n" +
    "                    {{ $ctrl.selectedItem.employability | ratings}}\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "                <label class=\"control-label col-sm-6\">\n" +
    "                    Student Diversity:\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-6 word-break-all\">\n" +
    "                    {{ $ctrl.selectedItem.studentDiversity | ratings}}\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "                <label class=\"control-label col-sm-6\">\n" +
    "                    Facilities:\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-6 word-break-all\">\n" +
    "                    {{ $ctrl.selectedItem.facilities | ratings}}\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "                <label class=\"control-label col-sm-6\">\n" +
    "                    Social Responsibilities:\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-6 word-break-all\">\n" +
    "                    {{ $ctrl.selectedItem.socialResponsibilities | ratings}}\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "                <label class=\"control-label col-sm-6\">\n" +
    "                    Accreditation:\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-6 word-break-all\">\n" +
    "                    {{ $ctrl.selectedItem.accreditation | ratings}}\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "                <label class=\"control-label col-sm-6\">\n" +
    "                    Arts and Culture:\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-6 word-break-all\">\n" +
    "                    {{ $ctrl.selectedItem.artsAndCulture | ratings}}\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "                <label class=\"control-label col-sm-6\">\n" +
    "                    Faculty Diversity:\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-6 word-break-all\">\n" +
    "                    {{ $ctrl.selectedItem.facultyDiversity | ratings}}\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "                <label class=\"control-label col-sm-6\">\n" +
    "                    Entrepreneurship:\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-6 word-break-all\">\n" +
    "                    {{ $ctrl.selectedItem.entrepreneurship | ratings}}\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "                <label class=\"control-label col-sm-6\">\n" +
    "                    Research:\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-6 word-break-all\">\n" +
    "                    {{ $ctrl.selectedItem.research | ratings}}\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "                <label class=\"control-label col-sm-6\">\n" +
    "                    Innovation:\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-6 word-break-all\">\n" +
    "                    {{ $ctrl.selectedItem.innovation | ratings}}\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "                <label class=\"control-label col-sm-6\">\n" +
    "                    Received from IGauge:\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-6 word-break-all\">\n" +
    "                    {{ $ctrl.selectedItem.recievedFromIgauge | date: 'medium' : 'UTC' }}\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"row\" ng-show=\"$ctrl.selectedItem.publishedAt\">\n" +
    "                <label class=\"control-label col-sm-6\">\n" +
    "                    Published Date:\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-6 word-break-all\">\n" +
    "                    {{ $ctrl.selectedItem.publishedAt | date: 'medium' : 'UTC'  }}\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "                <label class=\"control-label col-sm-6\">\n" +
    "                    Publish Status:\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-6 word-break-all\">\n" +
    "                    {{ $ctrl.selectedItem.publishedStatus | publishedStatus }}\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "                <label class=\"control-label col-sm-6\">\n" +
    "                    Display In Frontend:\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-6 word-break-all\">\n" +
    "                    {{ $ctrl.selectedItem.displayInFrontend ? 'Yes' : 'No' }}\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"row m-t\">\n" +
    "                <a class=\"btn btn-primary pull-right m-r\" ng-click=\"$ctrl.handlePublish($ctrl.selectedItem)\">\n" +
    "                    <i class=\"fa fa-cloud-upload\"></i>\n" +
    "                    <span>Publish</span>\n" +
    "                </a>\n" +
    "                <button class=\"btn btn-default pull-right m-r\"\n" +
    "                    ng-click=\"$ctrl.closeRightSidePanel()\">\n" +
    "                    <i class=\"fa fa-ban\"></i>\n" +
    "                    <span>Cancel</span>\n" +
    "                </button>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/iGauge/components/sync/iGaugeSyncLogs/iGaugeSyncLogsView.html',
    "<div class=\"ibox\">\n" +
    "    <div class=\"ibox-title\">\n" +
    "        <h5>IGauge Sync Logs</h5>\n" +
    "    </div>\n" +
    "    <div class=\"ibox-content publish-logs\">\n" +
    "        <div wave-spinner class=\"text-right\" ng-show=\"$ctrl.isFetchInProgress()\"></div>\n" +
    "\n" +
    "        <div ng-show=\"!$ctrl.isFetchInProgress()\">\n" +
    "            <strong ng-show=\"!$ctrl.logsLength\" class=\"text-navy\">No results</strong>\n" +
    "\n" +
    "            <div ng-show=\"$ctrl.logs\">\n" +
    "                <div ng-repeat=\"logsPerDay in $ctrl.logs track by $index\" class=\"record border-bottom m-b\">\n" +
    "                    <div class=\"panel panel-default\">\n" +
    "                        <div class=\"panel-heading\">\n" +
    "                            <span>{{logsPerDay[0].createdAt | date:'mediumDate'}}</span>\n" +
    "                        </div>\n" +
    "                        <div class=\"panel-body\">\n" +
    "                            <ul class=\"list-unstyled\">\n" +
    "                                <li ng-repeat=\"item in logsPerDay track by $index\">\n" +
    "                                    <span class=\"icon\" ng-class=\"$ctrl.getLogClassColor(item.status)\">\n" +
    "                                        <i class=\"fa fa-check\" ng-class=\"$ctrl.getLogClassFaIcon(item.status)\"></i>\n" +
    "                                    </span>\n" +
    "                                    IGauge Sync initiated by <span class=\"bold\">{{ item.createdByFullName }}</span>\n" +
    "                                    with <span class=\"bold\">{{ item.ratingsSyncTotal }}</span> rating imported at <small class=\"time\">{{ item.createdAt | date:'shortTime'}}</small>\n" +
    "                                </li>\n" +
    "                            </ul>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"row\" ng-show=\"$ctrl.isMoreLogsAvailable()\">\n" +
    "                    <div class=\"col-lg-2 col-lg-offset-5\">\n" +
    "                        <a class=\"btn btn-primary btn-block\"\n" +
    "                           ng-click=\"$ctrl.handleLoadMore()\">\n" +
    "                            Load more\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/modules/iGauge/components/sync/iGaugeSyncView.html',
    "<div  class=\"wrapper wrapper-content animated fadeInRight page-igauge-sync\">\n" +
    "    <div class=\"ibox\">\n" +
    "        <div class=\"ibox-title form-group\">\n" +
    "            <h5>Sync Data with IGauge Platform</h5><br>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"ibox-content\">\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col-lg-3\">\n" +
    "                    <div progress-circle=\"IGaugeSyncController.syncStatus\"></div>\n" +
    "                    <div class=\"m-t text-center\">\n" +
    "                        <div class=\"col-lg-12\">\n" +
    "                            <button class=\"m-t btn btn-primary\"\n" +
    "                                ng-click=\"IGaugeSyncController.handleSync()\"\n" +
    "                                ng-disabled=\"IGaugeSyncController.syncing\">\n" +
    "                                <i class=\"fa fa-refresh\"></i>\n" +
    "                                Sync Data\n" +
    "                            </button>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <igauge-sync-logs></igauge-sync-logs>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/modules/membersDb/components/leadsExport/leadsExportView.html',
    "<div  class=\"wrapper wrapper-content animated fadeInRight page-members-db-leads-export\">\n" +
    "    <div class=\"row\">\n" +
    "        <div class=\"col-sm-12\">\n" +
    "            <div class=\"section\">\n" +
    "                <div wave-spinner class=\"absolute-wave-spinner\" ng-show=\"LeadsExportController.fetchInProgress\"></div>\n" +
    "                <div class=\"section-body float-e-margins\" ng-class=\"{ 'modal-overlay-35': LeadsExportController.fetchInProgress }\">\n" +
    "                    <div class=\"row form-inline\">\n" +
    "\n" +
    "                        <div class=\"col-lg-3 col-xs-6\">\n" +
    "                            <ui-select\n" +
    "                                class=\"ui-grid-filter-select no-padding\"\n" +
    "                                ng-model=\"LeadsExportController.selectedInstitution\"\n" +
    "                                theme=\"bootstrap\">\n" +
    "                                <ui-select-match\n" +
    "                                    placeholder=\"Add Institution\" allow-clear=\"true\">\n" +
    "                                    <i class=\"fa fa-building\"></i>\n" +
    "                                    <span>{{$select.selected.name}}</span>\n" +
    "                                </ui-select-match>\n" +
    "                                <ui-select-choices\n" +
    "                                    refresh-delay=\"500\"\n" +
    "                                    refresh=\"LeadsExportController.handleSearchInstitution($select.search)\"\n" +
    "                                    position=\"down\"\n" +
    "                                    minimum-input-length=\"1\"\n" +
    "                                    repeat=\"option in LeadsExportController.institutionsDropdownList | filter: $select.search\">\n" +
    "                                    <div class=\"test\" ng-bind-html=\"option.name | highlight: $select.search\"></div>\n" +
    "                                </ui-select-choices>\n" +
    "                            </ui-select>\n" +
    "                            <div class=\"spinner inner-spinner\" ng-show=\"LeadsExportController.searchInProgress\" wave-spinner></div>\n" +
    "                        </div>\n" +
    "\n" +
    "                        <div class=\"col-lg-2 col-xs-6\">\n" +
    "                            <ui-select class=\"ui-grid-filter-select no-padding\"\n" +
    "                                       on-select=\"LeadsExportController.handleWebsiteChanges()\"\n" +
    "                                       ng-required=\"true\"\n" +
    "                                       ng-model=\"LeadsExportController.selectedWebsite\"\n" +
    "                                       theme=\"bootstrap\"\n" +
    "                                       search-enabled=\"true\">\n" +
    "                                <ui-select-match placeholder=\"Website\">\n" +
    "                                    {{ $select.selected.label }}\n" +
    "                                </ui-select-match>\n" +
    "                                <ui-select-choices\n" +
    "                                    position=\"down\"\n" +
    "                                    repeat=\"item in (LeadsExportController.websitesList | filter: $select.search) track by item.value\">\n" +
    "                                    <div ng-bind-html=\"item.label | highlight: $select.search\"></div>\n" +
    "                                </ui-select-choices>\n" +
    "                            </ui-select>\n" +
    "                        </div>\n" +
    "                        <div class=\"col-lg-2 col-xs-4 p-r-none\">\n" +
    "                            <input class=\"form-control w-100p\" ng-disabled=\"LeadsExportController.dateFilter.startDate\" type=\"number\" ng-model=\"LeadsExportController.days\" placeholder=\"In The Last Days\" min=\"1\" step=\"1\">\n" +
    "                        </div>\n" +
    "                        <div class=\"col-lg-1 col-xs-1 text-center no-padding\">\n" +
    "                            OR\n" +
    "                        </div>\n" +
    "\n" +
    "                        <div class=\"col-lg-2 col-xs-4 inner-addon\">\n" +
    "                            <input type=\"text\"\n" +
    "                                   readonly\n" +
    "                                   ng-model=\"LeadsExportController.dateFilter\"\n" +
    "                                   placeholder=\"From Date - To Date\" \n" +
    "                                   class=\"form-control clickable date-picker w-100p\"\n" +
    "                                   ng-disabled=\"LeadsExportController.days\" \n" +
    "                                   date-range-picker\n" +
    "                                   options=\"LeadsExportController.datePicker.options\">\n" +
    "                            <i class=\"fa fa-close\" ng-click=\"LeadsExportController.dateFilter = {};\"  ng-show=\"LeadsExportController.dateFilter.startDate\"></i>\n" +
    "                            <i class=\"fa fa-calendar\" ng-class=\"{'disabled': LeadsExportController.days}\" ng-show=\"!LeadsExportController.dateFilter.startDate\"></i>\n" +
    "                        </div>\n" +
    "                        <div class=\"col-lg-2 col-xs-2\">\n" +
    "                            <button class=\"btn btn-primary\"\n" +
    "                            ng-disabled=\"!LeadsExportController.fetchEnabled()\"\n" +
    "                            ng-click=\"LeadsExportController.handleFetch()\">Generate</button>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"alert alert-warning m-t\" ng-show=\"LeadsExportController.noResults\">\n" +
    "                        <span>No results are available for the specified parameters</span>\n" +
    "                    </div>\n" +
    "\n" +
    "                    <div ng-show=\"!LeadsExportController.noResults\">\n" +
    "                        <div ng-if=\"LeadsExportController.gridOptions.data\">\n" +
    "                                <div wave-spinner class=\"absolute-wave-spinner\" ng-show=\"LeadsExportController.showLoadBar()\"></div>\n" +
    "\n" +
    "                                <div ng-class=\"{'modal-overlay-35': LeadsExportController.showLoadBar()}\">\n" +
    "                                    <div class=\"grid\"\n" +
    "                                         ui-grid=\"LeadsExportController.gridOptions\"\n" +
    "                                         ui-grid-selection\n" +
    "                                         ui-grid-resize-columns\n" +
    "                                         ui-grid-auto-resize\n" +
    "                                         ui-grid-cellnav\n" +
    "                                         ui-grid-exporter\n" +
    "                                         ui-grid-pagination\n" +
    "                                         ng-show=\"LeadsExportController.isRowsRendered\"></div>\n" +
    "                                </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/membersDb/components/matchingToolLeadsExport/matchingToolLeadsExportView.html',
    "<div class=\"wrapper wrapper-content animated fadeInRight page-members-db-leads-export\">\n" +
    "    <div class=\"row\">\n" +
    "        <div class=\"col-sm-12\">\n" +
    "            <div class=\"section\">\n" +
    "                <div wave-spinner class=\"absolute-wave-spinner\" ng-show=\"MatchingToolLeadsExportController.fetchInProgress\"></div>\n" +
    "                <div class=\"alert alert-success\">\n" +
    "                    <div class=\"row\">\n" +
    "                        <div class=\"col-lg-12\">\n" +
    "                            <span class=\"block m-t-xs\"><i class=\"fa fa-star-o\"></i>\n" +
    "                                Please Note: On this page, you can extract leads generated until 11/02/2021 only. Program Name and URLs will be fetched from HUB 1 database.\n" +
    "                            </span>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"section-body float-e-margins\" ng-class=\"{ 'modal-overlay-35': MatchingToolLeadsExportController.fetchInProgress }\">\n" +
    "                    <div class=\"row form-inline\">\n" +
    "\n" +
    "                        <div class=\"col-lg-3 col-xs-6\">\n" +
    "                            <ui-select\n" +
    "                                class=\"ui-grid-filter-select no-padding\"\n" +
    "                                ng-model=\"MatchingToolLeadsExportController.selectedInstitution\"\n" +
    "                                theme=\"bootstrap\">\n" +
    "                                <ui-select-match\n" +
    "                                    placeholder=\"Add Institution\" allow-clear=\"true\">\n" +
    "                                    <i class=\"fa fa-building\"></i>\n" +
    "                                    <span>{{$select.selected.name}}</span>\n" +
    "                                </ui-select-match>\n" +
    "                                <ui-select-choices\n" +
    "                                    refresh-delay=\"500\"\n" +
    "                                    refresh=\"MatchingToolLeadsExportController.handleSearchInstitution($select.search)\"\n" +
    "                                    position=\"down\"\n" +
    "                                    minimum-input-length=\"1\"\n" +
    "                                    repeat=\"option in MatchingToolLeadsExportController.institutionsDropdownList | filter: $select.search\">\n" +
    "                                    <div class=\"test\" ng-bind-html=\"option.name | highlight: $select.search\"></div>\n" +
    "                                </ui-select-choices>\n" +
    "                            </ui-select>\n" +
    "                            <div class=\"spinner inner-spinner\" ng-show=\"MatchingToolLeadsExportController.searchInProgress\" wave-spinner></div>\n" +
    "                        </div>\n" +
    "                        <div class=\"col-lg-2 col-xs-4 m-b-xs\" \n" +
    "                                uib-popover=\"Please provide From Date - To Date range.\"\n" +
    "                                popover-placement=\"bottom\"\n" +
    "                                popover-enable=\"1===1\"\n" +
    "                                popover-trigger=\"'mouseenter'\">\n" +
    "                                <input class=\"form-control w-100p modal-overlay-35\" type=\"number\" ng-model=\"MatchingToolLeadsExportController.days\" placeholder=\"In The Last Days\" min=\"1\" step=\"1\">\n" +
    "                                </div>\n" +
    "                        <div class=\"col-lg-1 col-xs-2 text-center no-padding\">\n" +
    "                            OR\n" +
    "                        </div>\n" +
    "\n" +
    "                        <div class=\"col-lg-2 col-xs-4 inner-addon m-l\">\n" +
    "                            <input type=\"text\"\n" +
    "                                   readonly\n" +
    "                                   ng-model=\"MatchingToolLeadsExportController.dateFilter\"\n" +
    "                                   placeholder=\"From Date - To Date\"\n" +
    "                                   class=\"form-control clickable date-picker w-100p\"\n" +
    "                                   ng-disabled=\"MatchingToolLeadsExportController.days\"\n" +
    "                                   date-range-picker\n" +
    "                                   options=\"MatchingToolLeadsExportController.datePicker.options\">\n" +
    "                            <i class=\"fa fa-close\" ng-click=\"MatchingToolLeadsExportController.dateFilter = {};\" ng-show=\"MatchingToolLeadsExportController.dateFilter.startDate\"></i>\n" +
    "                            <i class=\"fa fa-calendar\" ng-class=\"{'disabled': MatchingToolLeadsExportController.days}\" ng-show=\"!MatchingToolLeadsExportController.dateFilter.startDate\"></i>\n" +
    "                        </div>\n" +
    "                        <div class=\"col-lg-2 col-xs-2\">\n" +
    "                            <button class=\"btn btn-primary\"\n" +
    "                                    ng-disabled=\"!MatchingToolLeadsExportController.fetchEnabled()\"\n" +
    "                                    ng-click=\"MatchingToolLeadsExportController.handleFetch()\">Generate\n" +
    "                            </button>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"alert alert-warning m-t\" ng-show=\"MatchingToolLeadsExportController.noResults\">\n" +
    "                        <span>No results are available for the specified parameters</span>\n" +
    "                    </div>\n" +
    "\n" +
    "                    <div ng-show=\"!MatchingToolLeadsExportController.noResults\">\n" +
    "                        <div ng-if=\"MatchingToolLeadsExportController.gridOptions.data\">\n" +
    "                            <div wave-spinner class=\"absolute-wave-spinner\" ng-show=\"MatchingToolLeadsExportController.showLoadBar()\"></div>\n" +
    "\n" +
    "                            <div ng-class=\"{'modal-overlay-35': MatchingToolLeadsExportController.showLoadBar()}\">\n" +
    "                                <div class=\"grid\"\n" +
    "                                        ui-grid=\"MatchingToolLeadsExportController.gridOptions\"\n" +
    "                                        ui-grid-selection\n" +
    "                                        ui-grid-resize-columns\n" +
    "                                        ui-grid-auto-resize\n" +
    "                                        ui-grid-cellnav\n" +
    "                                        ui-grid-exporter\n" +
    "                                        ui-grid-pagination\n" +
    "                                        ng-show=\"MatchingToolLeadsExportController.isRowsRendered\"></div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    \n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/membersDb/components/matchingToolLeadsExportDynamoDB/matchingToolLeadsExportDynamoDBView.html',
    "<div class=\"wrapper wrapper-content animated fadeInRight page-members-db-leads-export\">\n" +
    "    <div class=\"row\">\n" +
    "        <div class=\"col-sm-12\">\n" +
    "            <div class=\"section\">\n" +
    "                <div wave-spinner class=\"absolute-wave-spinner\" ng-show=\"MatchingToolLeadsExportDynamoDBController.fetchInProgress\"></div>\n" +
    "                <div class=\"alert alert-success\">\n" +
    "                    <div class=\"row\">\n" +
    "                        <div class=\"col-lg-12\">\n" +
    "                            <span class=\"block m-t-xs\"><i class=\"fa fa-star-o\"></i>\n" +
    "                                Please Note: On this page, you can extract leads generated from 12/07/2021 only.\n" +
    "                            </span>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"section-body float-e-margins\" ng-class=\"{ 'modal-overlay-35': MatchingToolLeadsExportDynamoDBController.fetchInProgress }\">\n" +
    "                    <div class=\"row form-inline\">\n" +
    "\n" +
    "                        <div class=\"col-lg-3 col-xs-6\">\n" +
    "                            <ui-select\n" +
    "                                class=\"ui-grid-filter-select no-padding\"\n" +
    "                                ng-model=\"MatchingToolLeadsExportDynamoDBController.selectedInstitution\"\n" +
    "                                theme=\"bootstrap\">\n" +
    "                                <ui-select-match\n" +
    "                                    placeholder=\"Add Institution\" allow-clear=\"true\">\n" +
    "                                    <i class=\"fa fa-building\"></i>\n" +
    "                                    <span>{{$select.selected.name}}</span>\n" +
    "                                </ui-select-match>\n" +
    "                                <ui-select-choices\n" +
    "                                    refresh-delay=\"500\"\n" +
    "                                    refresh=\"MatchingToolLeadsExportDynamoDBController.handleSearchInstitution($select.search)\"\n" +
    "                                    position=\"down\"\n" +
    "                                    minimum-input-length=\"1\"\n" +
    "                                    repeat=\"option in MatchingToolLeadsExportDynamoDBController.institutionsDropdownList | filter: $select.search\">\n" +
    "                                    <div class=\"test\" ng-bind-html=\"option.name | highlight: $select.search\"></div>\n" +
    "                                </ui-select-choices>\n" +
    "                            </ui-select>\n" +
    "                            <div class=\"spinner inner-spinner\" ng-show=\"MatchingToolLeadsExportDynamoDBController.searchInProgress\" wave-spinner></div>\n" +
    "                        </div>\n" +
    "                        <div class=\"col-lg-2 col-xs-4 m-b-xs\"\n" +
    "                                uib-popover=\"Please provide From Date - To Date range.\"\n" +
    "                                popover-placement=\"bottom\"\n" +
    "                                popover-enable=\"1===1\"\n" +
    "                                popover-trigger=\"'mouseenter'\">\n" +
    "                                <input class=\"form-control w-100p modal-overlay-35\" type=\"number\" ng-model=\"MatchingToolLeadsExportDynamoDBController.days\" placeholder=\"In The Last Days\" min=\"1\" step=\"1\">\n" +
    "                        </div>\n" +
    "                        <div class=\"col-lg-1 col-xs-2 text-center no-padding\">\n" +
    "                            OR\n" +
    "                        </div>\n" +
    "\n" +
    "                        <div class=\"col-lg-2 col-xs-4 inner-addon m-l\">\n" +
    "                            <input type=\"text\"\n" +
    "                                   readonly\n" +
    "                                   ng-model=\"MatchingToolLeadsExportDynamoDBController.dateFilter\"\n" +
    "                                   placeholder=\"From Date - To Date\"\n" +
    "                                   class=\"form-control clickable date-picker w-100p\"\n" +
    "                                   ng-disabled=\"MatchingToolLeadsExportDynamoDBController.days\"\n" +
    "                                   date-range-picker\n" +
    "                                   options=\"MatchingToolLeadsExportDynamoDBController.datePicker.options\">\n" +
    "                            <i class=\"fa fa-close\" ng-click=\"MatchingToolLeadsExportDynamoDBController.dateFilter = {};\" ng-show=\"MatchingToolLeadsExportDynamoDBController.dateFilter.startDate\"></i>\n" +
    "                            <i class=\"fa fa-calendar\" ng-class=\"{'disabled': MatchingToolLeadsExportDynamoDBController.days}\" ng-show=\"!MatchingToolLeadsExportDynamoDBController.dateFilter.startDate\"></i>\n" +
    "                        </div>\n" +
    "                        <div class=\"col-lg-2 col-xs-2\">\n" +
    "                            <button class=\"btn btn-primary\"\n" +
    "                                    ng-disabled=\"!MatchingToolLeadsExportDynamoDBController.fetchEnabled()\"\n" +
    "                                    ng-click=\"MatchingToolLeadsExportDynamoDBController.handleFetch()\">Generate\n" +
    "                            </button>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"alert alert-warning m-t\" ng-show=\"MatchingToolLeadsExportDynamoDBController.noResults\">\n" +
    "                        <span>No results are available for the specified parameters</span>\n" +
    "                    </div>\n" +
    "\n" +
    "                    <div ng-show=\"!MatchingToolLeadsExportDynamoDBController.noResults\">\n" +
    "                        <div ng-if=\"MatchingToolLeadsExportDynamoDBController.gridOptions.data\">\n" +
    "                            <div wave-spinner class=\"absolute-wave-spinner\" ng-show=\"MatchingToolLeadsExportDynamoDBController.showLoadBar()\"></div>\n" +
    "\n" +
    "                            <div ng-class=\"{'modal-overlay-35': MatchingToolLeadsExportDynamoDBController.showLoadBar()}\">\n" +
    "                                <div class=\"grid\"\n" +
    "                                        ui-grid=\"MatchingToolLeadsExportDynamoDBController.gridOptions\"\n" +
    "                                        ui-grid-selection\n" +
    "                                        ui-grid-resize-columns\n" +
    "                                        ui-grid-auto-resize\n" +
    "                                        ui-grid-cellnav\n" +
    "                                        ui-grid-exporter\n" +
    "                                        ui-grid-pagination\n" +
    "                                        ng-show=\"MatchingToolLeadsExportDynamoDBController.isRowsRendered\"></div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    \n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/membersDb/components/studentGuideExport/StudentGuideExportView.html',
    "<div class=\"wrapper wrapper-content animated fadeInRight page-members-db-leads-export\">\n" +
    "    <div class=\"row\">\n" +
    "        <div class=\"col-sm-12\">\n" +
    "            <div class=\"section\">\n" +
    "                <div wave-spinner class=\"absolute-wave-spinner\" ng-show=\"StudentGuideExportController.fetchInProgress\"></div>\n" +
    "                <div class=\"section-body float-e-margins\" ng-class=\"{ 'modal-overlay-35': StudentGuideExportController.fetchInProgress }\">\n" +
    "                    <div class=\"row form-inline\">\n" +
    "\n" +
    "                        <div class=\"col-lg-4 col-xs-6\">\n" +
    "                            <ui-select\n" +
    "                                name=\"studentGuide\"\n" +
    "                                ng-model=\"StudentGuideExportController.studentGuide\"\n" +
    "                                ng-required=\"true\"\n" +
    "                                theme=\"bootstrap\"\n" +
    "                                focus-delay=\"250\">\n" +
    "                                <ui-select-match placeholder=\"Select Student Guide\" allow-clear=\"true\">{{$select.selected.label}}</ui-select-match>\n" +
    "                                <ui-select-choices \n" +
    "                                    refresh-delay=\"500\"\n" +
    "                                    repeat=\"option.value as option in StudentGuideExportController.studentsGuideDropDown | filter:$select.search\">\n" +
    "                                    <div ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                                </ui-select-choices>\n" +
    "                                <ui-select-no-choice>\n" +
    "                                    Not found\n" +
    "                                </ui-select-no-choice>\n" +
    "                            </ui-select>\n" +
    "                            <div class=\"spinner inner-spinner\" ng-show=\"StudentGuideExportController.searchInProgress\" wave-spinner></div>\n" +
    "                        </div>\n" +
    "                        <div class=\"col-lg-2 col-xs-4 m-b-xs\">\n" +
    "                            <input class=\"form-control w-100p\" ng-disabled=\"StudentGuideExportController.dateFilter.startDate\" type=\"number\" ng-model=\"StudentGuideExportController.days\" placeholder=\"In The Last Days\" min=\"1\" step=\"1\">\n" +
    "                        </div>\n" +
    "                        <div class=\"col-lg-1 col-xs-2 text-center no-padding m-t-xs\">\n" +
    "                            OR\n" +
    "                        </div>\n" +
    "\n" +
    "                        <div class=\"col-lg-2 col-xs-4 inner-addon m-l\">\n" +
    "                            <input type=\"text\"\n" +
    "                                   readonly\n" +
    "                                   ng-model=\"StudentGuideExportController.dateFilter\"\n" +
    "                                   placeholder=\"From Date - To Date\"\n" +
    "                                   class=\"form-control clickable date-picker w-100p\"\n" +
    "                                   ng-disabled=\"StudentGuideExportController.days\"\n" +
    "                                   date-range-picker\n" +
    "                                   options=\"StudentGuideExportController.datePicker.options\">\n" +
    "                            <i class=\"fa fa-close\" ng-click=\"StudentGuideExportController.dateFilter = {};\" ng-show=\"StudentGuideExportController.dateFilter.startDate\"></i>\n" +
    "                            <i class=\"fa fa-calendar\" ng-class=\"{'disabled': StudentGuideExportController.days}\" ng-show=\"!StudentGuideExportController.dateFilter.startDate\"></i>\n" +
    "                        </div>\n" +
    "                        <div class=\"col-lg-2 col-xs-2\">\n" +
    "                            <button class=\"btn btn-primary\"\n" +
    "                                    ng-disabled=\"!StudentGuideExportController.fetchEnabled()\"\n" +
    "                                    ng-click=\"StudentGuideExportController.handleFetch()\">Generate\n" +
    "                            </button>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"alert alert-warning m-t\" ng-show=\"StudentGuideExportController.noResults\">\n" +
    "                        <span>No results are available for the specified parameters</span>\n" +
    "                    </div>\n" +
    "\n" +
    "                    <div ng-show=\"!StudentGuideExportController.noResults\">\n" +
    "                        <div ng-if=\"StudentGuideExportController.gridOptions\">\n" +
    "\n" +
    "                            <div>\n" +
    "                                <div class=\"grid\"\n" +
    "                                        ui-grid=\"StudentGuideExportController.gridOptions\"\n" +
    "                                        ui-grid-selection\n" +
    "                                        ui-grid-resize-columns\n" +
    "                                        ui-grid-auto-resize\n" +
    "                                        ui-grid-cellnav\n" +
    "                                        ui-grid-exporter\n" +
    "                                        ui-grid-pagination></div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    \n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/messages/components/institutionUserMessagesCenter/institutionUserMessagesCenterView.html',
    "<div class=\"wrapper wrapper-content animated fadeInRight institution-users-message-center\">\n" +
    "\n" +
    "    <div wave-spinner class=\"wave-spinner m-b-sm\"\n" +
    "         ng-show=\"InstitutionUsersMessagesCenterController.isLoading()\">\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row\">\n" +
    "        <div id=\"messages-data-center\" class=\"datagrid\"\n" +
    "             ng-class=\"{'col-md-7 col-sm-12':InstitutionUsersMessagesCenterController.selectedRowId, 'modal-overlay-35': InstitutionUsersMessagesCenterController.searching}\"\n" +
    "             ng-show=\"InstitutionUsersMessagesCenterController.isDatagridVisible() || InstitutionUsersMessagesCenterController.initialLoaded\">\n" +
    "            <div class=\"ibox\">\n" +
    "                <div class=\"ibox-content\">\n" +
    "                    <ui-grid-info></ui-grid-info>\n" +
    "                    <div ng-class=\"{'modal-overlay-35': InstitutionUsersMessagesCenterController.isLoading()}\"\n" +
    "                         class=\"grid\"\n" +
    "                         ui-grid=\"InstitutionUsersMessagesCenterController.grid.options\"\n" +
    "                         ui-grid-selection\n" +
    "                         ui-grid-cellnav\n" +
    "                         ui-grid-exporter\n" +
    "                         ui-grid-resize-columns\n" +
    "                         ui-grid-auto-resize\n" +
    "                         ui-grid-pagination>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"message-view right-side-container animated fadeInRight\"\n" +
    "             fixed-element-while-scrolling=\"#messages-data-center\"\n" +
    "             ng-class=\"{'col-md-5 col-sm-12':InstitutionUsersMessagesCenterController.selectedRowId}\"\n" +
    "             ng-show=\"InstitutionUsersMessagesCenterController.selectedRowId\">\n" +
    "            <div class=\"ibox\">\n" +
    "                <div class=\"ibox-title\">\n" +
    "                    <h5>Message</h5>\n" +
    "                    <div class=\"ibox-tools ng-scope\">\n" +
    "                        <a class=\"close-link\" ng-click=\"InstitutionUsersMessagesCenterController.closeMessage()\">\n" +
    "                            <i class=\"fa fa-times\"></i>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"ibox-content\">\n" +
    "                    <single-message\n" +
    "                        type=\"client\"\n" +
    "                        replies-limit=\"InstitutionUsersMessagesCenterController.repliesLimit\"\n" +
    "                        ng-show=\"InstitutionUsersMessagesCenterController.selectedRowId\"\n" +
    "                        state=\"InstitutionUsersMessagesCenterController.state\"\n" +
    "                        message=\"InstitutionUsersMessagesCenterController.activeMessage\"\n" +
    "                        message-sent-handler=\"InstitutionUsersMessagesCenterController.messageSentHandler()\"\n" +
    "                        message-deleted-handler=\"InstitutionUsersMessagesCenterController.messageDeletedHandler()\">\n" +
    "                    </single-message>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n"
  );


  $templateCache.put('/scripts/modules/messages/components/qsUsersMessagesCenter/qsUsersMessagesCenterView.html',
    "<div class=\"wrapper wrapper-content animated fadeInRight qs-staff-message-center\">\n" +
    "\n" +
    "    <div wave-spinner class=\"wave-spinner m-b-sm\"\n" +
    "         ng-show=\"QsUsersMessagesCenterController.isLoading()\">\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row\">\n" +
    "        <div id=\"messages-data-center\" class=\"datagrid grid-view\"\n" +
    "             ng-class=\"{'col-md-7 col-sm-12':QsUsersMessagesCenterController.selectedRowId, 'modal-overlay-35': QsUsersMessagesCenterController.searching}\"\n" +
    "             ng-show=\"QsUsersMessagesCenterController.isDatagridVisible() || QsUsersMessagesCenterController.initialLoaded\">\n" +
    "            <div class=\"ibox\">\n" +
    "                <div class=\"ibox-content\">\n" +
    "                    <ui-grid-info></ui-grid-info>\n" +
    "                    <div ng-class=\"{'modal-overlay-35': QsUsersMessagesCenterController.isLoading()}\"\n" +
    "                         class=\"grid\"\n" +
    "                         ui-grid=\"QsUsersMessagesCenterController.gridOptions\"\n" +
    "                         ui-grid-selection\n" +
    "                         ui-grid-cellnav\n" +
    "                         ui-grid-exporter\n" +
    "                         ui-grid-resize-columns\n" +
    "                         ui-grid-auto-resize\n" +
    "                         ui-grid-pagination>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"message-view right-side-container animated fadeInRight\"\n" +
    "             fixed-element-while-scrolling=\"#messages-data-center\"\n" +
    "             ng-class=\"{'col-md-5 col-sm-12':QsUsersMessagesCenterController.selectedRowId}\"\n" +
    "             ng-if=\"QsUsersMessagesCenterController.selectedRowId\">\n" +
    "            <div class=\"ibox\">\n" +
    "                <div class=\"ibox-title\">\n" +
    "                    <h5>Message</h5>\n" +
    "                    <div class=\"ibox-tools ng-scope\">\n" +
    "                        <a class=\"close-link\" ng-click=\"QsUsersMessagesCenterController.closeMessage()\">\n" +
    "                            <i class=\"fa fa-times\"></i>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"ibox-content\">\n" +
    "                    <single-message\n" +
    "                        ng-show=\"QsUsersMessagesCenterController.selectedRowId\"\n" +
    "                        replies-limit=\"QsUsersMessagesCenterController.repliesLimit\"\n" +
    "                        state=\"QsUsersMessagesCenterController.state\"\n" +
    "                        message=\"QsUsersMessagesCenterController.activeMessage\"\n" +
    "                        message-sent-handler=\"QsUsersMessagesCenterController.messageSentHandler()\"\n" +
    "                        message-deleted-handler=\"QsUsersMessagesCenterController.messageDeletedHandler()\">\n" +
    "                    </single-message>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n"
  );


  $templateCache.put('/scripts/modules/messages/components/sendMessage/messageTemplate.html',
    "<div class=\"message-history\">\n" +
    "    <div class=\"form-group reply-border\">\n" +
    "        <div>\n" +
    "            <label class=\"control-label col-sm-5 col-md-4\">\n" +
    "                From:\n" +
    "            </label>\n" +
    "            <div class=\"col-sm-7 col-md-8 text-label word-break-all\">\n" +
    "                {{message.createdByFullName}}\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div>\n" +
    "            <label class=\"control-label col-sm-5 col-md-4\">\n" +
    "                Sent:\n" +
    "            </label>\n" +
    "            <div class=\"col-sm-7 col-md-8 text-label\">\n" +
    "                {{message.createdAt | date : (message.today ? 'MMM dd, yyyy h:mm a' : 'MMM dd, yyyy')}}\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div>\n" +
    "            <label class=\"control-label col-sm-5 col-md-4\">\n" +
    "                Subject:\n" +
    "            </label>\n" +
    "            <div class=\"col-sm-7 col-md-8 text-label\">\n" +
    "                {{$ctrl.replyMessage.subject}}\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"col-xs-12 m-t\" ng-bind-html=\"message.message\">\n" +
    "\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/messages/components/sendMessage/sendMessageComponent.html',
    "<div class=\"send-message-component\">\n" +
    "\n" +
    "    <div ng-show=\"$ctrl.loading\" wave-spinner class=\"wave-spinner\"></div>\n" +
    "    <div ng-show=\"!$ctrl.loading\">\n" +
    "        <div class=\"font-size-md\">\n" +
    "            Send new message\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"separator subtitle-separator\"></div>\n" +
    "\n" +
    "        <form name=\"$ctrl.newMessageForm\" ng-submit=\"$ctrl.sendNewMessage()\" novalidate>\n" +
    "            <div class=\"new-message-table m-b-sm\">\n" +
    "\n" +
    "                <div>\n" +
    "                    <span class=\"message-label\">From</span>\n" +
    "                    <span><strong>{{$ctrl.newMessage.userFullName}}</strong></span>\n" +
    "                </div>\n" +
    "                <div>\n" +
    "                    <span class=\"message-label subject-label\">Subject *</span>\n" +
    "                    <span class=\"form-group\" ng-class=\"{'has-errors': $ctrl.newMessageForm.subject.$invalid}\">\n" +
    "                    <div class=\"subject-wrapper\">\n" +
    "                        <input class=\"form-control subject-input\" ng-disabled=\"$ctrl.sendingMessage\" name=\"subject\"\n" +
    "                               ng-required=\"{{true}}\" ng-model-options=\"{debounce: 500}\"\n" +
    "                               custom-popover\n" +
    "                               popover-html=\"Required\"\n" +
    "                               popover-placement=\"left\"\n" +
    "                               popover-trigger=\"manual\"\n" +
    "                               popover-visibility=\"{{$ctrl.newMessageForm.subject.$invalid}}\"\n" +
    "                               ng-model=\"$ctrl.newMessage.subject\" type=\"text\">\n" +
    "                    </div>\n" +
    "                </span>\n" +
    "                </div>\n" +
    "\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group summernote-message-input\"\n" +
    "                 ng-class=\"{'has-errors': $ctrl.newMessageForm.message.$invalid}\">\n" +
    "                <div class=\"m-b-sm\">Body *</div>\n" +
    "                <summernote\n" +
    "                        class=\"form-control\"\n" +
    "                        id=\"messageBody\"\n" +
    "                        name=\"message\"\n" +
    "                        required\n" +
    "                        config=\"$ctrl.textEditorBasicOptions\"\n" +
    "                        ng-model=\"$ctrl.newMessage.message\"\n" +
    "                        ng-disabled=\"$ctrl.sendingMessage\">\n" +
    "                </summernote>\n" +
    "                <input type=\"text\"\n" +
    "                       class=\"form-control\"\n" +
    "                       required\n" +
    "                       custom-popover\n" +
    "                       popover-html=\"Required\"\n" +
    "                       popover-placement=\"left\"\n" +
    "                       popover-trigger=\"manual\"\n" +
    "                       popover-visibility=\"{{($ctrl.newMessageForm.message.$dirty || $ctrl.submitted) && $ctrl.newMessageForm.message.$invalid}}\"\n" +
    "                       ng-model=\"$ctrl.newMessage.message\"\n" +
    "                       ng-hide=\"true\">\n" +
    "            </div>\n" +
    "\n" +
    "\n" +
    "            <button type=\"submit\" class=\"btn btn-sm btn-primary\" ng-disabled=\"$ctrl.sendingMessage\">Send</button>\n" +
    "            <button type=\"button\" class=\"btn btn-sm btn-default\" ng-disabled=\"$ctrl.sendingMessage\"\n" +
    "                    ng-click=\"$ctrl.cancelNewMessage()\">Cancel\n" +
    "            </button>\n" +
    "\n" +
    "        </form>\n" +
    "    </div>\n" +
    "\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/messages/components/sendMessage/sendMessageView.html',
    "<div class=\"send-message-component\">\n" +
    "\n" +
    "    <h2 class=\"heading\">\n" +
    "        <small>{{$ctrl.state==='reply' ? 'Reply': 'New message'}}</small>\n" +
    "    </h2>\n" +
    "\n" +
    "    <form class=\"form-horizontal add-message\" name=\"$ctrl.newMessageForm\" ng-submit=\"$ctrl.sendNewMessage()\" novalidate>\n" +
    "\n" +
    "        <div class=\"form-group\">\n" +
    "            <label class=\"control-label col-sm-5 col-md-4\">\n" +
    "                From: *\n" +
    "            </label>\n" +
    "            <div class=\"col-sm-7 col-md-8 text-label word-break-all\">\n" +
    "                {{$ctrl.userFullName}}\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"form-group\" ng-class=\"{'has-errors': $ctrl.isInvalidSubject()}\">\n" +
    "            <label class=\"control-label col-sm-5 col-md-4\">\n" +
    "                Subject: *\n" +
    "            </label>\n" +
    "            <div class=\"col-sm-7 col-md-8\">\n" +
    "                <div class=\"text-label\"\n" +
    "                     ng-if=\"$ctrl.state==='reply'\"\n" +
    "                     ng-bind=\"$ctrl.replyMessage.subject\">\n" +
    "                </div>\n" +
    "                <input class=\"form-control subject-input\" name=\"subject\" type=\"text\"\n" +
    "                       ng-if=\"$ctrl.state==='newMessage'\"\n" +
    "                       ng-required=\"true\"\n" +
    "                       ng-model=\"$ctrl.newMessage.subject\"\n" +
    "                       ng-model-options=\"{debounce: 500}\"\n" +
    "                       ng-focus=\"$ctrl.resetValidation()\"\n" +
    "                       focus-delay=\"250\"\n" +
    "                       custom-popover\n" +
    "                       popover-html=\"Add subject\"\n" +
    "                       popover-placement=\"left\"\n" +
    "                       popover-trigger=\"manual\"\n" +
    "                       popover-visibility=\"{{$ctrl.isInvalidSubject()}}\">\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"form-group\">\n" +
    "            <div class=\"col-sm-12 col-md-12\">\n" +
    "                <div class=\"summernote-container\" ng-class=\"{'has-errors': $ctrl.isInvalidMessage()}\">\n" +
    "                    <summernote class=\"form-control\" name=\"message\"\n" +
    "                                config=\"$ctrl.textEditorBasicOptions\"\n" +
    "                                ng-focus=\"$ctrl.resetValidation()\"\n" +
    "                                ng-required=\"true\"\n" +
    "                                ng-model=\"$ctrl.newMessage.message\"\n" +
    "                                on-paste=\"$ctrl.handleClipboardPaste()\"\n" +
    "                                on-change=\"$ctrl.messageWords = $ctrl.countWords(contents)\"\n" +
    "                                on-init=\"$ctrl.messageWords = $ctrl.countWords($ctrl.newMessage.message)\"\n" +
    "                                count-word=\"$ctrl.messageWords\">\n" +
    "                    </summernote>\n" +
    "                </div>\n" +
    "\n" +
    "                <div focus-delay=\"250\"\n" +
    "                     custom-popover\n" +
    "                     popover-html=\"Add message\"\n" +
    "                     popover-placement=\"left\"\n" +
    "                     popover-trigger=\"manual\"\n" +
    "                     popover-visibility=\"{{$ctrl.isInvalidMessage()}}\">\n" +
    "                </div>\n" +
    "\n" +
    "                <span class=\"pull-right\" ng-class=\"{'text-red': $ctrl.isMessageCountInvalid()}\">{{$ctrl.messageWords}}/{{$ctrl.maxWords}} words</span>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        \n" +
    "        <div class=\"form-group\">\n" +
    "            <label class=\"col-lg-12 control-label\">\n" +
    "                File Attachment\n" +
    "            </label>\n" +
    "            <div ng-class=\"{'col-lg-12 col-sm-12': !$ctrl.uploadEnabled, 'col-lg-1 col-sm-3' : $ctrl.uploadEnabled && !$ctrl.isImageBoxEmpty,'col-lg-3 col-sm-6' : $ctrl.isImageBoxEmpty && !$ctrl.displayRemoveLink ? true : false}\">\n" +
    "                <div class=\"clearfix\"\n" +
    "                     name=\"$ctrl.forms.uploadForm\"\n" +
    "                     enctype=\"multipart/form-data\"\n" +
    "                     ng-dropzone\n" +
    "                     ng-class=\"{'has-errors': !$ctrl.displayRemoveLink && $ctrl.isImageBoxEmpty}\"\n" +
    "                     dropzone=\"$ctrl.dropZoneInstance\"\n" +
    "                     dropzone-config=\"$ctrl.uploadConfig.dropzone\"\n" +
    "                     event-handlers=\"$ctrl.uploadConfig.eventHandlers\"\n" +
    "                     novalidate\n" +
    "                     focus-delay=\"250\"\n" +
    "                     dz-hidden-input-file\n" +
    "                     upload-enabled=\"{{!$ctrl.uploadEnabled ? true : false}}\">\n" +
    "                    <div class=\"fallback form-group\" ng-class=\"{'has-errors': $ctrl.isImageBoxEmpty}\">\n" +
    "                        <input name=\"file\" type=\"file\"/>\n" +
    "                    </div>\n" +
    "                    <div class=\"btn btn-default btn-outline btn-sm pull-left dz-message\" ng-show=\"$ctrl.uploadEnabled\">\n" +
    "                        <i class=\"fa fa-upload\"></i> Upload\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"form-group\">\n" +
    "            <div class=\"col-sm-12\">\n" +
    "                <button type=\"submit\" class=\"btn btn-sm btn-primary\"\n" +
    "                        ng-disabled=\"$ctrl.sendingMessage || $ctrl.uploadInProgress\">Send\n" +
    "                </button>\n" +
    "                <a class=\"btn btn-sm btn-default\"\n" +
    "                   ng-disabled=\"$ctrl.sendingMessage || $ctrl.uploadInProgress\"\n" +
    "                   ng-click=\"$ctrl.cancelNewMessage()\">Cancel\n" +
    "                </a>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div ng-show=\"$ctrl.state === 'reply' && $ctrl.showReplies !== false\">\n" +
    "\n" +
    "            <div class=\"separator\"></div>\n" +
    "\n" +
    "            <div ng-repeat=\"message in $ctrl.replyMessage.replies | orderBy: 'createdAt':true\"\n" +
    "                 ng-include=\"'/scripts/modules/messages/components/sendMessage/messageTemplate.html'\">\n" +
    "            </div>\n" +
    "\n" +
    "\n" +
    "            <div ng-if=\"$ctrl.replyMessage\" ng-init=\"message=$ctrl.replyMessage\" class=\"last-message\"\n" +
    "                 ng-include=\"'/scripts/modules/messages/components/sendMessage/messageTemplate.html'\">\n" +
    "            </div>\n" +
    "\n" +
    "        </div>\n" +
    "\n" +
    "\n" +
    "    </form>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/messages/components/shareButton/shareButtonView.html',
    "<div class=\"share-button\" uib-dropdown auto-close=\"outsideClick\" is-open=\"$ctrl.isOpen\">\n" +
    "    <a class=\"btn btn-default btn-sm\" uib-dropdown-toggle>\n" +
    "        <i class=\"fa fa-share-alt\" aria-hidden=\"true\"></i> Share\n" +
    "    </a>\n" +
    "\n" +
    "    <div uib-dropdown-menu class=\"share-dropdown dropdown-menu bg-muted border-bottom border-left-right\">\n" +
    "        <div class=\"p-xs\">\n" +
    "            <ui-select\n" +
    "                name=\"share\"\n" +
    "                ng-click=\"$event.stopPropagation();\"\n" +
    "                close-on-select=\"true\"\n" +
    "                ng-model=\"$ctrl.selectedUsers\"\n" +
    "                multiple\n" +
    "                theme=\"bootstrap\">\n" +
    "                <ui-select-match placeholder=\"Select an Option\">{{$item.email}}</ui-select-match>\n" +
    "                <ui-select-choices\n" +
    "                    refresh-delay=\"1000\"\n" +
    "                    position=\"down\"\n" +
    "                    repeat=\"user.email as user in $ctrl.users | filter:$select.search\">\n" +
    "                    <div ng-bind-html=\"user.email | highlight: $select.search\"></div>\n" +
    "                </ui-select-choices>\n" +
    "                <ui-select-no-choice>\n" +
    "                    Not found\n" +
    "                </ui-select-no-choice>\n" +
    "            </ui-select>\n" +
    "\n" +
    "            <button class=\"btn btn-primary btn-block m-t\"\n" +
    "                    ng-disabled=\"$ctrl.selectedUsers.length < 1\"\n" +
    "                    ng-click=\"$ctrl.sendShare()\">Send share</button>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/messages/components/singleMessage/oldMessage/oldMessageView.html',
    "<div class=\"message-wrapper\">\n" +
    "\n" +
    "    <div ng-if=\"$ctrl.state === 'listMessages'\" class=\"form-group\">\n" +
    "        <share-button\n" +
    "            ng-if=\"$ctrl.qsUserOptions\"\n" +
    "            message-sent-handler=\"$ctrl.messageSentHandler()\"\n" +
    "            this-message=\"$ctrl.message\"\n" +
    "            parent-message=\"$ctrl.parentMessage\"\n" +
    "            users=\"$ctrl.qsUserOptions\"></share-button>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "        <div class=\"row\">\n" +
    "            <label class=\"control-label col-sm-5 col-md-3\">From: </label>\n" +
    "            <div class=\"col-sm-7 col-md-9 word-break-all\">\n" +
    "                {{$ctrl.message.createdByFullName}}\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"row\">\n" +
    "            <label class=\"control-label col-sm-5 col-md-3\">Date: </label>\n" +
    "            <div class=\"col-sm-7 col-md-9\">\n" +
    "                {{$ctrl.message.createdAt | date:'EEE dd MMM yyyy hh:mm:ss'}}\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"row\" ng-if=\"$ctrl.message.institutionName\">\n" +
    "            <label class=\"control-label col-sm-5 col-md-3\">Institution: </label>\n" +
    "            <div class=\"col-sm-7 col-md-9\">\n" +
    "                {{$ctrl.message.institutionName}}\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"row\">\n" +
    "            <label class=\"control-label col-sm-5 col-md-3\">Subject: </label>\n" +
    "            <div class=\"col-sm-7 col-md-9\">{{$ctrl.parentMessage.subject}}</div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"row\">\n" +
    "            <label class=\"control-label col-sm-5 col-md-3\">Question: </label>\n" +
    "            <div class=\"col-sm-7 col-md-9\">{{$ctrl.parentMessage.question}}</div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"row\" ng-if=\"$ctrl.message.attachment\">\n" +
    "            <label class=\"control-label col-sm-5 col-md-3\">Attachment: </label>\n" +
    "            <div class=\"col-sm-7 col-md-9\">\n" +
    "                <a href=\"{{$ctrl.message.attachment | prependHttps}}\" download target=\"_self\">\n" +
    "                    <i class=\"fa fa-paperclip\"></i>\n" +
    "                </a>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"feed-element\">\n" +
    "        <div class=\"well word-break-all\" ng-bind-html=\"$ctrl.message.message\"></div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div ng-if=\"$ctrl.message.sharedWith\" class=\"row\">\n" +
    "        <small class=\"col-xs-12 text-muted\">\n" +
    "            <span>Message was shared with:</span>\n" +
    "            <span ng-repeat=\"email in $ctrl.message.sharedWith | unique\">\n" +
    "                {{email}}{{$index < ($ctrl.message.sharedWith | unique ).length -1 ? ', ': ''}}\n" +
    "            </span>\n" +
    "        </small>\n" +
    "    </div>\n" +
    "\n" +
    "</div>\n" +
    "\n"
  );


  $templateCache.put('/scripts/modules/messages/components/singleMessage/singleMessageComponentView.html',
    "<div class=\"single-message-component\">\n" +
    "    <div class=\"message-wrapper\" ng-if=\"$ctrl.state==='reply'||$ctrl.state==='newMessage'\">\n" +
    "        <send-message\n" +
    "            message-sent-handler=\"$ctrl.messageSentHandler()\"\n" +
    "            show-replies=\"false\"\n" +
    "            state=\"$ctrl.state\"\n" +
    "            reply-message=\"$ctrl.message\">\n" +
    "        </send-message>\n" +
    "    </div>\n" +
    "\n" +
    "    <div ng-if=\"$ctrl.state!=='reply'&&$ctrl.state!=='newMessage'\"\n" +
    "         ng-include=\"'/scripts/modules/messages/components/singleMessage/singleMessageView.html'\">\n" +
    "    </div>\n" +
    "\n" +
    "    <div ng-if=\"$ctrl.state==='reply'\">\n" +
    "        <old-message\n" +
    "            message-sent-handler=\"$ctrl.messageSentHandler()\"\n" +
    "            message=\"$ctrl.message.replies.length > 0 ? $ctrl.message.replies[0] : $ctrl.message\"\n" +
    "            parent-message=\"$ctrl.message\"\n" +
    "            state=\"$ctrl.state\"\n" +
    "            qs-user-options=\"$ctrl.qsUserOptions\">\n" +
    "        </old-message>\n" +
    "    </div>\n" +
    "\n" +
    "    <div ng-repeat=\"oldMessage in $ctrl.message.replies | orderBy:'createdAt':true  | limitTo : $ctrl.repliesLimit\"\n" +
    "         ng-if=\"$index!==0\">\n" +
    "        <old-message\n" +
    "            message-sent-handler=\"$ctrl.messageSentHandler()\"\n" +
    "            message=\"oldMessage\"\n" +
    "            parent-message=\"$ctrl.message\"\n" +
    "            state=\"$ctrl.state\"\n" +
    "            qs-user-options=\"$ctrl.qsUserOptions\">\n" +
    "        </old-message>\n" +
    "    </div>\n" +
    "\n" +
    "    <div ng-if=\"$ctrl.repliesLimit >  $ctrl.message.replies.length\">\n" +
    "        <old-message\n" +
    "            message-sent-handler=\"$ctrl.messageSentHandler()\"\n" +
    "            message=\"$ctrl.message\"\n" +
    "            parent-message=\"$ctrl.message\"\n" +
    "            state=\"$ctrl.state\"\n" +
    "            qs-user-options=\"$ctrl.qsUserOptions\">\n" +
    "        </old-message>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"m-t\" ng-show=\"$ctrl.repliesLimit < $ctrl.message.replies.length + 1\">\n" +
    "        <div class=\"m-l-lg m-r-lg\">\n" +
    "            <a class=\"btn btn-primary btn-block\" ng-click=\"$ctrl.loadMoreReplies()\">\n" +
    "                <i class=\"fa fa-arrow-down\"></i> Load more\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/messages/components/singleMessage/singleMessageView.html',
    "<div class=\"message-wrapper\" ng-if=\"$ctrl.message\">\n" +
    "\n" +
    "    <div class=\"m-b clearfix float-e-margins\">\n" +
    "        <a class=\"btn btn-default btn-sm\" ng-click=\"$ctrl.state='reply'\">\n" +
    "            <i class=\"fa fa-reply\" aria-hidden=\"true\"></i> Reply\n" +
    "        </a>\n" +
    "\n" +
    "        <share-button\n" +
    "            ng-if=\"$ctrl.qsUserOptions\"\n" +
    "            message-sent-handler=\"$ctrl.messageSentHandler()\"\n" +
    "            this-message=\"$ctrl.latestMessage\"\n" +
    "            parent-message=\"$ctrl.message\"\n" +
    "            users=\"$ctrl.qsUserOptions\"></share-button>\n" +
    "\n" +
    "        <a class=\"btn btn-danger btn-sm\"\n" +
    "                ng-disabled=\"$ctrl.isDeleteDisabled()\"\n" +
    "                ng-click=\"!$ctrl.isDeleteDisabled() && $ctrl.deleteMessage($ctrl.message.id)\"\n" +
    "                uib-popover=\"You can't delete this message as it was created by another user, has replies or is read\"\n" +
    "                popover-trigger=\"'mouseenter'\"\n" +
    "                popover-placement=\"top\"\n" +
    "                popover-enable=\"$ctrl.isDeleteDisabled()\">\n" +
    "            <i class=\"fa fa-trash-o\" aria-hidden=\"true\"></i> Delete\n" +
    "        </a>\n" +
    "\n" +
    "        <a class=\"btn btn-default btn-sm\"\n" +
    "           ng-if=\"$ctrl.message.source.ranking && $ctrl.message.source.question\"\n" +
    "           ng-click=\"$ctrl.goToSubmissionsPage($ctrl.message)\">\n" +
    "            <i class=\"fa fa-question-circle-o\" aria-hidden=\"true\"></i> Go to Question\n" +
    "        </a>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "        <div class=\"row\">\n" +
    "            <label class=\"control-label col-sm-5 col-md-3\">From: </label>\n" +
    "            <div class=\"col-sm-7 col-md-9 word-break-all\">\n" +
    "                {{$ctrl.latestMessage.createdByFullName}}\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"row\">\n" +
    "            <label class=\"control-label col-sm-5 col-md-3\">Date: </label>\n" +
    "            <div class=\"col-sm-7 col-md-9\">\n" +
    "                {{$ctrl.latestMessage.createdAt | date:'EEE dd MMM yyyy hh:mm:ss'}}\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"row\" ng-if=\"$ctrl.message.institutionName\">\n" +
    "            <label class=\"control-label col-sm-5 col-md-3\">Institution: </label>\n" +
    "            <div class=\"col-sm-7 col-md-9\">\n" +
    "                {{$ctrl.message.institutionName}}\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"row\">\n" +
    "            <label class=\"control-label col-sm-5 col-md-3\">Subject: </label>\n" +
    "            <div class=\"col-sm-7 col-md-9\">{{$ctrl.message.subject}}</div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"row\">\n" +
    "            <label class=\"control-label col-sm-5 col-md-3\">Question: </label>\n" +
    "            <div class=\"col-sm-7 col-md-9\">{{$ctrl.message.question}}</div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"row\" ng-if=\"$ctrl.latestMessage.attachment\">\n" +
    "            <label class=\"control-label col-sm-5 col-md-3\">Attachment: </label>\n" +
    "            <div class=\"col-sm-7 col-md-9\">\n" +
    "                <a href=\"{{$ctrl.latestMessage.attachment | prependHttps}}\" download target=\"_self\">\n" +
    "                    <i class=\"fa fa-paperclip\"></i>\n" +
    "                </a>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"feed-element\">\n" +
    "        <div class=\"well word-break-all\" ng-bind-html=\"$ctrl.latestMessage.message\"></div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div ng-if=\"$ctrl.latestMessage.sharedWith\" class=\"row\">\n" +
    "        <small class=\"col-xs-12 text-muted\">\n" +
    "            <span>Message was shared with:</span>\n" +
    "            <span ng-repeat=\"email in $ctrl.latestMessage.sharedWith | unique\">\n" +
    "                {{email}}{{$index < (oldMessage.sharedWith | unique ).length -1 ? ', ': ''}}\n" +
    "            </span>\n" +
    "        </small>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/messages/components/statisticsMessages/statisticsMessageContentView.html',
    "<div class=\"row m-t-sm m-b-sm\">\n" +
    "    <div class=\"col-lg-12\">\n" +
    "\n" +
    "        <a class=\"btn btn-danger btn-xs\"\n" +
    "           ng-disabled=\"!$ctrl.isDeleteAllowed(message)\"\n" +
    "           ng-click=\"$ctrl.deleteMessage(message)\"\n" +
    "           uib-popover=\"You can't delete this message as it was created by another user, has replies or is read\"\n" +
    "           popover-trigger=\"'mouseenter'\"\n" +
    "           popover-placement=\"top\"\n" +
    "           popover-enable=\"!$ctrl.isDeleteAllowed(message)\">\n" +
    "            <i class=\"fa fa-trash-o\" aria-hidden=\"true\"></i>\n" +
    "            <span>Delete</span>\n" +
    "        </a>\n" +
    "\n" +
    "        <a ng-click=\"$ctrl.reply(message)\" class=\"btn btn-default btn-xs\">\n" +
    "            <i class=\"fa fa-reply\" aria-hidden=\"true\"></i>\n" +
    "            <span>Reply</span>\n" +
    "        </a>\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"hr-line-dashed\"></div>\n" +
    "\n" +
    "<div class=\"reply-message reply-border m-b\"\n" +
    "     ng-class=\"{'alert-success': $ctrl.replyId === reply.id || ($ctrl.messageId === message.id && !$ctrl.replyId && key === 0)}\"\n" +
    "     ng-repeat=\"(key, reply) in message.replies | orderBy: 'createdAt': true\">\n" +
    "\n" +
    "    <div class=\"row text-muted\">\n" +
    "        <div class=\"col-lg-3\">\n" +
    "            From:\n" +
    "        </div>\n" +
    "        <div class=\"col-lg-9 break-word\">\n" +
    "            <strong>{{reply.createdByFullName}}</strong>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row text-muted\">\n" +
    "        <div class=\"col-lg-3\">\n" +
    "            Email:\n" +
    "        </div>\n" +
    "        <div class=\"col-lg-9 break-word text-lowercase\">\n" +
    "            <strong>{{reply.createdByUserName}}</strong>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row text-muted\">\n" +
    "        <div class=\"col-lg-3\">\n" +
    "            Sent:\n" +
    "        </div>\n" +
    "        <div class=\"col-lg-9\">\n" +
    "            <strong>\n" +
    "                {{reply.createdAt | date : (reply.today ? 'MMM dd, yyyy h:mm a' : 'MMM dd, yyyy')}}\n" +
    "            </strong>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row text-muted\">\n" +
    "        <div class=\"col-lg-3\">Subject: </div>\n" +
    "        <div class=\"col-lg-9\">\n" +
    "            <strong>{{message.subject}}</strong>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row text-muted\">\n" +
    "        <div class=\"col-lg-3\">Question: </div>\n" +
    "        <div class=\"col-lg-9\">\n" +
    "            <strong>{{message.question}}</strong>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row text-muted\" ng-if=\"reply.attachment\">\n" +
    "        <div class=\" col-lg-3\">Attachment:</div>\n" +
    "        <div class=\"col-lg-9\">\n" +
    "            <a href=\"{{reply.attachment | prependHttps}}\" download target=\"_self\">\n" +
    "                <i class=\"fa fa-paperclip\"></i>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row\">\n" +
    "        <div class=\"col-lg-12\">\n" +
    "            <div class=\"m-t m-b-sm\" ng-bind-html=\"reply.message\"></div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"original-message\" ng-class=\"{'alert-success': !$ctrl.replyId && $ctrl.messageId === message.id && !message.replies.length}\">\n" +
    "\n" +
    "    <div class=\"row text-muted\">\n" +
    "        <div class=\"col-lg-3\">\n" +
    "            From:\n" +
    "        </div>\n" +
    "        <div class=\"col-lg-9 break-word\">\n" +
    "            <strong>{{message.createdByFullName}}</strong>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row text-muted\">\n" +
    "        <div class=\"col-lg-3\">\n" +
    "            Email:\n" +
    "        </div>\n" +
    "        <div class=\"col-lg-9 break-word text-lowercase\">\n" +
    "            <strong>{{message.createdByUserName}}</strong>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row text-muted\">\n" +
    "        <div class=\"col-lg-3\">\n" +
    "            Sent:\n" +
    "        </div>\n" +
    "        <div class=\"col-lg-9\">\n" +
    "            <strong>\n" +
    "                {{message.createdAt | date : (message.today ? 'MMM dd, yyyy h:mm a' : 'MMM dd, yyyy')}}\n" +
    "            </strong>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row text-muted\">\n" +
    "        <div class=\"col-lg-3\">\n" +
    "            Subject:\n" +
    "        </div>\n" +
    "        <div class=\"col-lg-9\">\n" +
    "            <strong>{{message.subject}}</strong>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row text-muted\">\n" +
    "        <div class=\"col-lg-3\">Question: </div>\n" +
    "        <div class=\"col-lg-9\">\n" +
    "            <strong>{{message.question}}</strong>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row text-muted\" ng-if=\"message.attachment\">\n" +
    "        <div class=\" col-lg-3\">Attachment:</div>\n" +
    "        <div class=\"col-lg-9\">\n" +
    "            <a href=\"{{message.attachment | prependHttps}}\" download target=\"_self\">\n" +
    "                <i class=\"fa fa-paperclip\"></i>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row\">\n" +
    "        <div class=\"col-lg-12\">\n" +
    "            <div class=\"m-t m-b-sm break-word\" ng-bind-html=\"message.message\"></div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/messages/components/statisticsMessages/statisticsMessagesComponentView.html',
    "<div class=\"statistics-messaging-component block panel-body\">\n" +
    "\n" +
    "    <div ng-show=\"$ctrl.state === 'newMessage' || $ctrl.state === 'reply'\">\n" +
    "        <send-message state=\"$ctrl.state\" message-sent-handler=\"$ctrl.handleMessageSent()\" reply-message=\"$ctrl.replyMessage\"></send-message>\n" +
    "    </div>\n" +
    "\n" +
    "    <div ng-show=\"$ctrl.state === 'listMessages'\">\n" +
    "        <div class=\"m-b float-e-margins\">\n" +
    "            <a class=\"btn btn-sm btn-primary\"\n" +
    "               ng-click=\"$ctrl.goToNewMessage()\">\n" +
    "                <i class=\"fa fa-plus\"></i> New Message\n" +
    "            </a>\n" +
    "\n" +
    "            <a class=\"btn btn-sm btn-default\"\n" +
    "               ng-if=\"$ctrl.showMessagesCenter()\"\n" +
    "               ui-sref=\"clients.statistics.messages\">\n" +
    "                <i class=\"fa fa-comments\" aria-hidden=\"true\"></i> Go to messages center\n" +
    "            </a>\n" +
    "        </div>\n" +
    "\n" +
    "        <div ng-show=\"$ctrl.loading\" wave-spinner class=\"wave-spinner\"></div>\n" +
    "\n" +
    "        <div ng-show=\"!$ctrl.loading\" class=\"messages-table\">\n" +
    "\n" +
    "            <div ng-show=\"$ctrl.messages.length !== 0\" class=\"table table-hover table-mail font-size-sm no-margins\">\n" +
    "                <div class=\"hr-line-dashed m-n\"></div>\n" +
    "\n" +
    "                <div ng-repeat=\"(key, message) in $ctrl.messages\" ng-show=\"$ctrl.showVariationLogMessages(message.subject)\">\n" +
    "\n" +
    "                    <div ng-show=\"key < $ctrl.logsToDisplay\">\n" +
    "                        <div class=\"message-row row clickable hover-greenish\"\n" +
    "                             ng-click=\"$ctrl.expandMessage(message)\"\n" +
    "                             ng-class=\"{unread: $ctrl.isUnread(message)}\">\n" +
    "\n" +
    "                            <div class=\"col-xs-4 p-h-xs p-l-xs break-word\">\n" +
    "                            <span class=\"label label-warning\"\n" +
    "                                  ng-if=\"message.replies.length > 0\"\n" +
    "                                  ng-class=\"{'label-danger': $ctrl.isUnread(message), 'label-warning':!$ctrl.isUnread(message)}\">\n" +
    "                                {{message.replies.length + 1}}\n" +
    "                            </span>\n" +
    "                                <span ng-if=\"message.replies.length > 0\">&nbsp;</span>\n" +
    "                                <span>{{message.subject}}</span>\n" +
    "                            </div>\n" +
    "                            <div class=\"col-xs-3 p-h-xs middle-col\">\n" +
    "                                <div ng-if=\"message.replies.length > 0\">\n" +
    "                                    {{message.replies[0].createdAt | date : (message.replies[0].today ? 'MMM dd, yyyy h:mm a' : 'MMM dd, yyyy')}}\n" +
    "                                </div>\n" +
    "                                <div ng-if=\"!message.replies\">\n" +
    "                                    {{message.createdAt | date : (message.today ? 'MMM dd, yyyy h:mm a' : 'MMM dd, yyyy')}}\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                            <div class=\"col-xs-5 p-h-xs p-r-xs\">\n" +
    "                                <div class=\"break-word\">\n" +
    "                                    {{message.replies.length > 0 ? message.replies[0].createdByFullName : message.createdByFullName}}\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "\n" +
    "                        <div class=\"no-hover row\" ng-class=\"{'ui-state-hover': message.open}\">\n" +
    "                            <div ng-show=\"message.open\"\n" +
    "                                 class=\"col-xs-12\"\n" +
    "                                 ng-class=\"{unread: $ctrl.isUnread(message)}\">\n" +
    "                                <div ng-include src=\"'/scripts/modules/messages/components/statisticsMessages/statisticsMessageContentView.html'\"></div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "\n" +
    "                        <div class=\"hr-line-dashed m-n\"></div>\n" +
    "                    </div>\n" +
    "\n" +
    "                </div>\n" +
    "\n" +
    "            </div>\n" +
    "\n" +
    "            <div ng-show=\"$ctrl.messages.length === 0\">No results</div>\n" +
    "\n" +
    "            <div ng-show=\"$ctrl.loadingMoreMessages\" wave-spinner class=\"wave-spinner\"></div>\n" +
    "\n" +
    "            <div class=\"m-l-lg m-r-lg\" ng-show=\"$ctrl.isMoreLogsAvailable()\">\n" +
    "                <a class=\"btn btn-block btn-primary btn-sm load-more\"\n" +
    "                   ng-click=\"$ctrl.handleLoadMoreHistoryLogs()\"><i class=\"fa fa-arrow-down\"></i> Load more\n" +
    "                </a>\n" +
    "            </div>\n" +
    "\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/messages/datagrid/createdAtFilterHeaderTemplate.html',
    "<div class=\"ui-grid-filter-container {{'ui-grid-filter-input-' + $index}}\" ng-repeat=\"colFilter in col.filters\" ng-if=\"colFilter.visible\">\n" +
    "    <input type=\"text\" class=\"ui-grid-filter-input date-picker ui-grid-filter-input-{{$index}}\"\n" +
    "           ng-model=\"grid.appScope.filters.createdAtRange\"\n" +
    "           date-range-picker=\"\"\n" +
    "           date-range-fixer\n" +
    "           readonly\n" +
    "           options=\"{eventHandlers: {'apply.daterangepicker': grid.appScope.handleCreatedAtDateRange}, opens: 'left'}\"/>\n" +
    "\n" +
    "    <div class=\"ui-grid-filter-button\" ng-click=\"removeFilter(colFilter, $index);grid.appScope.filters.createdAtRange={};\" >\n" +
    "            <i class=\"ui-grid-icon-cancel\"   ng-show=\"colFilter.term !== undefined && colFilter.term != null && colFilter.term != ''\"></i>\n" +
    "            <span class=\"ui-grid-icon-date\"  ng-show=\"colFilter.term == undefined || colFilter.term == null || colFilter.term == ''\"></span>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/messages/datagrid/dateCellTemplate.html',
    "<div class=\"ui-grid-cell-contents\">\n" +
    "  {{row.entity.modifiedAt | date:\"medium\"}}\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/messages/datagrid/exportAllMenuItem.html',
    "<li>\n" +
    "    <button type=\"button\" class=\"ui-grid-menu-item\"\n" +
    "            ng-click=\"itemAction($event, title)\"\n" +
    "            ng-show=\"itemShown()\"\n" +
    "            ng-class=\"{ 'ui-grid-menu-item-active': active(), 'ui-grid-sr-only': (!focus && screenReaderOnly) }\"\n" +
    "            aria-pressed=\"{{active()}}\"\n" +
    "            tabindex=\"0\"\n" +
    "            ng-focus=\"focus=true\"\n" +
    "            ng-blur=\"focus=false\">\n" +
    "        <i ng-class=\"icon\" aria-hidden=\"true\">&nbsp;</i> {{ name }} <i class=\"fa fa-info-circle pull-right\"\n" +
    "                                                                       aria-hidden=\"true\"\n" +
    "                                                                       uib-tooltip=\"Export is for the 20,000 most recent rows. Otherwise, create a Jira ticket for additional messages.\"\n" +
    "                                                                       tooltip-placement=\"left\"></i>\n" +
    "    </button>\n" +
    "</li>\n"
  );


  $templateCache.put('/scripts/modules/messages/datagrid/fullNameCellTemplateView.html',
    "<div class=\"ui-grid-cell-contents\">\n" +
    "    {{row.entity.replies && row.entity.replies.length > 0 ? row.entity.replies[0].createdByFullName : row.entity.createdByFullName}}\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/messages/datagrid/messageCellTemplateView.html',
    "<div class=\"ui-grid-cell-contents\">\n" +
    "    {{(row.entity.replies && row.entity.replies.length > 0 ? row.entity.replies[0].message : row.entity.message)  | htmlToPlaintext}}\n" +
    "</div>"
  );


  $templateCache.put('/scripts/modules/messages/datagrid/repliesCellTemplateView.html',
    "<div class=\"ui-grid-cell-contents\">\n" +
    "    <span ng-class=\"{'label-danger': grid.appScope.isUnread(row.entity), 'label-warning':!grid.appScope.isUnread(row.entity)}\"\n" +
    "          ng-if=\"row.entity.replies.length > 0\"\n" +
    "          class=\"label label-default\">{{row.entity.replies.length + 1}}</span>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/messages/datagrid/rowTemplate.html',
    "<div grid=\"grid\" ng-class=\"{unread: grid.appScope.isUnread(row.entity)}\">\n" +
    "    <div class=\"ui-grid-cell pointer\"\n" +
    "        ng-repeat=\"(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name\"\n" +
    "        ng-class=\"{'ui-grid-row-header-cell': col.isRowHeader, 'active': row.entity.id && row.entity.id == grid.appScope.selectedRowId}\"\n" +
    "        role=\"{{col.isRowHeader ? 'rowheader' : 'gridcell'}}\"\n" +
    "        ui-grid-cell>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/profiles/components/courses/courseAdd/courseAddView.html',
    "<div wave-spinner class=\"wave-spinner\" ng-show=\"$ctrl.isLoading()\"></div>\n" +
    "\n" +
    "<div ng-if=\"!$ctrl.isLoading()\" ng-class=\"{'modal-overlay-35' : $ctrl.updateInProgress}\">\n" +
    "    <form class=\"form-horizontal\" name=\"$ctrl.forms.newCourse\" novalidate>\n" +
    "        <div class=\"row\">\n" +
    "            <h2 class=\"col-sm-12 matching-tool-title\">\n" +
    "                <div class=\"form-group\" ng-class=\"{'has-errors': $ctrl.isInvalidName}\">\n" +
    "                    <input type=\"text\" class=\"form-control\" ng-model=\"$ctrl.course.name\" placeholder=\"Program Name*\" autofocus\n" +
    "                           focus-delay=\"250\"\n" +
    "                           custom-popover\n" +
    "                           popover-html=\"This field is mandatory\"\n" +
    "                           popover-placement=\"left\"\n" +
    "                           popover-trigger=\"manual\"\n" +
    "                           popover-visibility=\"{{ $ctrl.isInvalidName }}\"\n" +
    "                           ng-focus=\"$ctrl.isInvalidName = false\">\n" +
    "                </div>\n" +
    "            </h2>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"row m-b-xs\">\n" +
    "            <div class=\"pull-left\">\n" +
    "                <switch class=\"green\"\n" +
    "                        ng-disabled=\"$ctrl.massPublishInProgress\"\n" +
    "                        ng-model=\"$ctrl.course.massPublish\"\n" +
    "                        ng-change=\"$ctrl.handleMassPublishChanges()\">\n" +
    "                </switch>\n" +
    "                <span> Mass publish from the Publish Tab</span>\n" +
    "                <i class=\"fa fa-info-circle\"\n" +
    "                   ng-show=\"$ctrl.course.massPublish\"\n" +
    "                   uib-tooltip=\"The Mass publish from the Publish Tab is enabled. To publish proceed to the Publish tab, otherwise disable this option for this button to become active\"\n" +
    "                   tooltip-class=\"break-word\"\n" +
    "                   tooltip-placement=\"top\"></i>\n" +
    "            </div>\n" +
    "            <button class=\"btn btn-default btn-xs m-b-xs pull-right\"\n" +
    "                    ng-click=\"$ctrl.toggleTabs()\">\n" +
    "                <i class=\"fa\" ng-class=\"$ctrl.isAllTabsExpanded ? 'fa-arrow-up' : 'fa-arrow-down'\"></i>\n" +
    "                <span>{{$ctrl.isAllTabsExpanded ? 'Collapse all tabs' : 'Expand all tabs'}}</span>\n" +
    "            </button>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"row\">\n" +
    "                <uib-accordion class=\"accordion-with-arrows\" close-others=\"false\">\n" +
    "                    <div uib-accordion-group class=\"panel-default\"\n" +
    "                         ng-repeat=\"item in $ctrl.sections\"\n" +
    "                         heading=\"{{item.name}}\"\n" +
    "                         is-disabled=\"item.hide\"\n" +
    "                         is-open=\"item.open\">\n" +
    "                        <div ng-if=\"item.template\" ng-include src=\"'/scripts/modules/profiles/components/courses/courseEdit/templates/' + item.template\"></div>\n" +
    "                    </div>\n" +
    "                </uib-accordion>\n" +
    "        </div>\n" +
    "    </form>\n" +
    "\n" +
    "    <div class=\"row btn-row\">\n" +
    "        <div class=\"col-sm-12\">\n" +
    "            <div class=\"pull-right\">\n" +
    "                <button class=\"btn btn-default btn-xs m-b-xs\"\n" +
    "                        ng-click=\"$ctrl.cancel()\">\n" +
    "                    <i class=\"fa fa-ban\"></i>\n" +
    "                    <span>Cancel</span>\n" +
    "                </button>\n" +
    "\n" +
    "                <button class=\"btn btn-primary btn-xs m-b-xs\"\n" +
    "                        ng-click=\"$ctrl.handleAdd()\">\n" +
    "                    <i class=\"fa fa-check-circle\"></i>\n" +
    "                    <span>Save</span>\n" +
    "                </button>\n" +
    "\n" +
    "                <button class=\"btn btn-primary btn-xs m-b-xs save-and-publish\"\n" +
    "                        ng-class=\"{disabled: $ctrl.isUpdateAndPublishDisabled()}\"\n" +
    "\n" +
    "                        uib-tooltip=\"The Mass publish from the Publish Tab is enabled. To publish proceed to the Publish tab, otherwise disable this option for this button to become active\"\n" +
    "                        tooltip-enable=\"$ctrl.course.massPublish\"\n" +
    "                        tooltip-placement=\"left\"\n" +
    "\n" +
    "                        ng-click=\"!$ctrl.isUpdateAndPublishDisabled() && $ctrl.handleAdd(true)\">\n" +
    "                    <i class=\"fa fa-cloud-upload\"></i>\n" +
    "                    <span>Save & Publish</span>\n" +
    "                </button>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/profiles/components/courses/courseBrochures/courseBrochuresView.html',
    "<h3 class=\"heading\">Brochure ({{ $ctrl.item.url ? 1:0 }} / 1)</h3> \n" +
    "\n" +
    "<div class=\"alert alert-info\">\n" +
    "    <p>\n" +
    "        <i class=\"fa fa-info-circle\"></i>\n" +
    "        <span>File must be: Less than 1MB / in pdf format.</span>\n" +
    "    </p>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"form-horizontal m-t media\">\n" +
    "    <div class=\"grid-view upload unselected\" ng-class=\"{'modal-overlay-35': $ctrl.isUploadDisabled()}\">\n" +
    "        <div name=\"forms.imageForm\"\n" +
    "              class=\"clearfix dropzone image-form\"\n" +
    "              enctype=\"multipart/form-data\"\n" +
    "              ng-dropzone dropzone=\"$ctrl.dropZoneInstance\"\n" +
    "              dropzone-config=\"$ctrl.config.dropzone\"\n" +
    "              event-handlers=\"$ctrl.config.eventHandlers\"\n" +
    "              novalidate>\n" +
    "            <div class=\"upload-brochure\">\n" +
    "                <i class=\"fa fa-upload\" aria-hidden=\"true\"></i>\n" +
    "            </div>\n" +
    "            <div class=\"fallback\">\n" +
    "                <input name=\"file\" type=\"file\" />\n" +
    "            </div>\n" +
    "            <span class=\"dz-message hidden\"></span>\n" +
    "            <span class=\"dropzone-previews hidden\"></span>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"grid-view unselected\" ng-if=\"$ctrl.item.url\">\n" +
    "        <div class=\"preview overlay\">\n" +
    "            <img ng-src=\"/images/media/pdf-thumbnail.jpg\"/>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"info\">\n" +
    "            <input ng-show=\"$ctrl.isEdit\" type=\"text\" ng-model=\"$ctrl.course.brochure.title\" class=\"form-control input-sm\" placeholder=\"Title\">\n" +
    "            <div ng-show=\"!$ctrl.isEdit\" class=\"title\">{{ $ctrl.course.brochure.title }}</div>\n" +
    "            <div class=\"actions\">\n" +
    "                <a ng-show=\"!$ctrl.isEdit\" ng-click=\"$ctrl.handleEdit()\" title=\"Edit title\">\n" +
    "                    <i class=\"fa fa-pencil\"></i>\n" +
    "                </a>\n" +
    "                <a ng-show=\"$ctrl.isEdit\" ng-click=\"$ctrl.handleCancel()\" title=\"Cancel Edit\">\n" +
    "                    <i class=\"fa fa-times-circle\"></i>\n" +
    "                </a>\n" +
    "                <a href=\"{{$ctrl.item.url}}\" target=\"_blank\" title=\"View brochure\">\n" +
    "                    <i class=\"fa fa-search\"></i>\n" +
    "                </a>\n" +
    "                <a ng-click=\"$ctrl.handleDelete()\" title=\"Delete brochure\">\n" +
    "                    <i class=\"fa  fa-trash-o\"></i>\n" +
    "                </a>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/profiles/components/courses/courseEdit/courseEditView.html',
    "<div wave-spinner class=\"wave-spinner\" ng-show=\"$ctrl.isLoading()\"></div>\n" +
    "\n" +
    "<div ng-if=\"!$ctrl.isLoading()\" ng-class=\"{'modal-overlay-35' : $ctrl.updateInProgress}\">\n" +
    "    <form class=\"form-horizontal\" name=\"$ctrl.forms.course\" novalidate>\n" +
    "    <div class=\"row\">\n" +
    "        <h2 class=\"matching-tool-title\">\n" +
    "            <div ng-show=\"$ctrl.isNameEdit\" class=\"input-group\" ng-class=\"{'has-errors': $ctrl.isInvalidName}\">\n" +
    "                <input type=\"text\" class=\"form-control\" ng-model=\"$ctrl.tmp.name\"\n" +
    "                       focus-delay=\"250\"\n" +
    "                       custom-popover\n" +
    "                       popover-html=\"This field is mandatory\"\n" +
    "                       popover-placement=\"left\"\n" +
    "                       popover-trigger=\"manual\"\n" +
    "                       popover-visibility=\"{{ $ctrl.isInvalidName }}\"\n" +
    "                       ng-focus=\"$ctrl.isInvalidName = false\">\n" +
    "                <span class=\"input-group-addon\">\n" +
    "                    <i class=\"fa fa-ban clickable\" ng-click=\"$ctrl.toggleNameEdit()\"></i>\n" +
    "                </span>\n" +
    "            </div>\n" +
    "            <div ng-show=\"!$ctrl.isNameEdit\">\n" +
    "                <sup class=\"badge badge-warning\" ng-if=\"!$ctrl.isClient\">{{$ctrl.course.coreId}}</sup>\n" +
    "                <span class=\"publish-field\"></span><span>{{$ctrl.tmp.name}}</span>\n" +
    "                <i class=\"fa fa-pencil clickable\" ng-click=\"$ctrl.toggleNameEdit()\"></i>\n" +
    "            </div>\n" +
    "        </h2>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row m-b-xs\">\n" +
    "        <div class=\"pull-left\">\n" +
    "            <switch class=\"green\"\n" +
    "                    ng-disabled=\"$ctrl.massPublishInProgress\"\n" +
    "                    ng-model=\"$ctrl.course.massPublish\"\n" +
    "                    ng-change=\"$ctrl.handleMassPublishChanges()\">\n" +
    "            </switch>\n" +
    "            <span> Mass publish from the Publish Tab</span>\n" +
    "            <i class=\"fa fa-info-circle\"\n" +
    "               ng-show=\"$ctrl.course.massPublish\"\n" +
    "               uib-tooltip=\"The Mass publish from the Publish Tab is enabled. To publish proceed to the Publish tab, otherwise disable this option for this button to become active\"\n" +
    "               tooltip-class=\"break-word\"\n" +
    "               tooltip-placement=\"top\"></i>\n" +
    "        </div>\n" +
    "        <button class=\"btn btn-default btn-xs pull-right\"\n" +
    "                ng-click=\"$ctrl.toggleTabs()\">\n" +
    "            <i class=\"fa\" ng-class=\"$ctrl.isAllTabsExpanded ? 'fa-arrow-up' : 'fa-arrow-down'\"></i>\n" +
    "            <span>{{$ctrl.isAllTabsExpanded ? 'Collapse all tabs' : 'Expand all tabs'}}</span>\n" +
    "        </button>\n" +
    "        <span class=\"clickable fa fa-info-circle pull-right m-r-sm mt-5px\"\n" +
    "              uib-tooltip=\"An 'Update' needs to be performed before the 'Clone' button is enabled. \"\n" +
    "              tooltip-placement=\"top-right\"\n" +
    "              ng-show=\"$ctrl.isCloneDisabled()\">\n" +
    "        </span>\n" +
    "        <button class=\"btn btn-primary btn-xs pull-right \"\n" +
    "                ng-class=\"{disabled: $ctrl.isCloneDisabled(),'m-r-sm':!$ctrl.isCloneDisabled()}\"\n" +
    "                ng-click=\"$ctrl.cloneCourse()\">\n" +
    "            <i class=\"fa fa-clone\" ></i>\n" +
    "           Clone\n" +
    "        </button>   \n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row\">\n" +
    "            <uib-accordion class=\"accordion-with-arrows\" close-others=\"false\">\n" +
    "                <div uib-accordion-group class=\"panel-default\"\n" +
    "                     ng-repeat=\"item in $ctrl.sections\"\n" +
    "                     heading=\"{{item.name}}\"\n" +
    "                     is-disabled=\"item.hide\"\n" +
    "                     is-open=\"item.open\">\n" +
    "                    <div ng-if=\"item.template\" ng-include src=\"'/scripts/modules/profiles/components/courses/courseEdit/templates/' + item.template\"></div>\n" +
    "                </div>\n" +
    "            </uib-accordion>\n" +
    "\n" +
    "    </div>\n" +
    "    </form>\n" +
    "\n" +
    "    <div class=\"row btn-row\">\n" +
    "        <div class=\"col-sm-12\">\n" +
    "            <div class=\"pull-left\">\n" +
    "                <button class=\"btn btn-danger btn-xs m-b-xs\"\n" +
    "                        ng-if=\"!$ctrl.isClient\"\n" +
    "                        ng-class=\"{disabled: $ctrl.isUpdateAndPublishDisabled()}\"\n" +
    "                        ng-disabled=\"$ctrl.deleteInProgress\"\n" +
    "                        ng-click=\"!$ctrl.isUpdateAndPublishDisabled() && $ctrl.handleDeleteClick()\">\n" +
    "                    <i class=\"fa fa-trash-o\"></i>\n" +
    "                    <span>Delete & Unpublish</span>\n" +
    "                </button>\n" +
    "\n" +
    "                <course-unpublish course=\"$ctrl.course\" is-client=\"$ctrl.isClient\" ng-class=\"{disabled: $ctrl.isUpdateAndPublishDisabled()}\"></course-unpublish>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"pull-right\">\n" +
    "                <button class=\"btn btn-default btn-xs m-b-xs\"\n" +
    "                        ng-click=\"$ctrl.cancel()\">\n" +
    "                    <i class=\"fa fa-ban\"></i>\n" +
    "                    <span>Cancel</span>\n" +
    "                </button>\n" +
    "\n" +
    "                <button class=\"btn btn-primary btn-xs m-b-xs\"\n" +
    "                        ng-click=\"$ctrl.handleUpdate()\">\n" +
    "                    <i class=\"fa fa-check-circle\"></i>\n" +
    "                    <span>Update</span>\n" +
    "                </button>\n" +
    "\n" +
    "                <button class=\"btn btn-primary btn-xs m-b-xs update-and-publish\"\n" +
    "                        ng-class=\"{disabled: $ctrl.isUpdateAndPublishDisabled() || $ctrl.course.massPublish}\"\n" +
    "\n" +
    "                        uib-tooltip=\"The Mass publish from the Publish Tab is enabled. To publish proceed to the Publish tab, otherwise disable this option for this button to become active\"\n" +
    "                        tooltip-enable=\"$ctrl.course.massPublish\"\n" +
    "                        tooltip-placement=\"left\"\n" +
    "\n" +
    "                        ng-click=\"!$ctrl.isUpdateAndPublishDisabled() && !$ctrl.course.massPublish && $ctrl.handleUpdateAndPublish()\">\n" +
    "                    <i class=\"fa fa-cloud-upload\"></i>\n" +
    "                    <span>Update & Publish</span>\n" +
    "                </button>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/profiles/components/courses/courseEdit/templates/addressTemplate.html',
    "<div class=\"form-horizontal m-t\">\n" +
    "    <div class=\"form-group m-b-none\" ng-class=\"{'has-errors': $ctrl.isInvalidCampus}\">\n" +
    "        <label class=\"col-sm-3 control-label\">\n" +
    "            Campus(es)<span class=\"legendLabel publish-field text-nowrap\">*</span>\n" +
    "        </label>\n" +
    "        <div class=\"col-sm-9\">\n" +
    "            <ui-select class=\"ui-grid-filter-select\"\n" +
    "                       on-remove=\"$ctrl.handleCampusRemoved($item)\"\n" +
    "                       on-select=\"$ctrl.handleCampusChanges()\"\n" +
    "                       ng-required=\"true\"\n" +
    "                       ng-model=\"$ctrl.tmp.campus\"\n" +
    "                       theme=\"bootstrap\"\n" +
    "                       search-enabled=\"false\"\n" +
    "                       append-to-body=\"true\"\n" +
    "                       multiple\n" +
    "                       close-on-select=\"true\"\n" +
    "                       reset-search-input=\"true\"\n" +
    "\n" +
    "                       focus-delay=\"250\"\n" +
    "                       custom-popover\n" +
    "                       popover-html=\"This field is mandatory\"\n" +
    "                       popover-placement=\"left\"\n" +
    "                       popover-trigger=\"manual\"\n" +
    "                       popover-visibility=\"{{ $ctrl.isInvalidCampus }}\"\n" +
    "                       ng-change=\"$ctrl.isInvalidCampus = false\">\n" +
    "                <ui-select-match>\n" +
    "                    {{ $item.label }}\n" +
    "                </ui-select-match>\n" +
    "                <ui-select-choices repeat=\"item in ($ctrl.campusList | filter: $select.search) track by item.value\">\n" +
    "                    <div ng-bind=\"item.label\"></div>\n" +
    "                </ui-select-choices>\n" +
    "            </ui-select>\n" +
    "\n" +
    "            <a class=\"btn-default btn-xs m-b-xs pull-right m-t-xs\"\n" +
    "               ui-sref=\"clients.profiles.shared.campuses\">\n" +
    "                <span>View campuses</span>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/profiles/components/courses/courseEdit/templates/admissionContactTemplate.html',
    "<div class=\"form-horizontal m-t\">\n" +
    "    <div class=\"form-group m-b-none\">\n" +
    "        <label class=\"col-sm-3 control-label\">\n" +
    "            Program URL\n" +
    "        </label>\n" +
    "        <div class=\"col-sm-9\">\n" +
    "            <input type=\"text\" class=\"form-control\"\n" +
    "                   placeholder=\"http://\"\n" +
    "                   ng-model=\"$ctrl.course.url\"\n" +
    "                   ng-keyup=\"$ctrl.onKeyUp($event, type(), $index)\">\n" +
    "            <a class=\"btn-default btn-xs m-b-xs pull-right m-t-xs\"\n" +
    "               ng-show=\"$ctrl.course.url\"\n" +
    "               target=\"_blank\"\n" +
    "               ng-href=\"{{$ctrl.course.url}}\">\n" +
    "                <i class=\"fa fa-external-link\"></i>\n" +
    "                <span>Open Link</span>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/profiles/components/courses/courseEdit/templates/admissionProcessTemplate.html',
    "<div class=\"form-horizontal m-t\">\n" +
    "    <div class=\"form-group\" ng-class=\"{'has-errors': $ctrl.isInvalidStartDate}\">\n" +
    "        <label class=\"col-sm-4 control-label\">\n" +
    "            Program Start Date<span class=\"legendLabel publish-field text-nowrap\">*</span>\n" +
    "        </label>\n" +
    "        <div class=\"col-sm-8\">\n" +
    "            <input type=\"text\" class=\"form-control\"\n" +
    "                   ng-model=\"$ctrl.course.startDate\"\n" +
    "                   ng-required=\"true\"\n" +
    "\n" +
    "                   focus-delay=\"250\"\n" +
    "                   custom-popover\n" +
    "                   popover-html=\"This field is mandatory\"\n" +
    "                   popover-placement=\"left\"\n" +
    "                   popover-trigger=\"manual\"\n" +
    "                   popover-visibility=\"{{ $ctrl.isInvalidStartDate }}\"\n" +
    "                   ng-focus=\"$ctrl.isInvalidStartDate = false\">\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/profiles/components/courses/courseEdit/templates/descriptionTemplate.html',
    "<div class=\"form-horizontal m-t\">\n" +
    "    <div class=\"form-group\">\n" +
    "        <label class=\"col-lg-2 col-sm-3 control-label publish-field\">\n" +
    "            Introduction\n" +
    "        </label>\n" +
    "        <div class=\"col-lg-10 col-sm-9\">\n" +
    "            <summernote\n" +
    "                ng-model=\"$ctrl.course.introduction\"\n" +
    "                config=\"$ctrl.textEditorOptions\"\n" +
    "                on-paste=\"$ctrl.handleClipboardPaste('introduction')\">\n" +
    "            </summernote>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "        <label class=\"col-lg-2 col-sm-3 control-label\">\n" +
    "            List of Modules\n" +
    "        </label>\n" +
    "        <div class=\"col-lg-10 col-sm-9\">\n" +
    "            <summernote\n" +
    "                ng-model=\"$ctrl.course.modules\"\n" +
    "                config=\"$ctrl.textEditorOptions\"\n" +
    "                on-paste=\"$ctrl.handleClipboardPaste('modules')\">\n" +
    "            </summernote>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/profiles/components/courses/courseEdit/templates/entryRequirementsTemplate.html',
    "<div class=\"form-horizontal m-t\">\n" +
    "    <div ng-if=\"$ctrl.course.courseLevel\">\n" +
    "        <div ng-if=\"$ctrl.course.courseLevel !== 'pc'\" ng-include src=\"'/scripts/modules/profiles/components/courses/courseEdit/templates/entryRequirmentsForUgAndPgCourseTemplate.html'\"></div>\n" +
    "        <div ng-if=\"$ctrl.course.courseLevel === 'pg'\" ng-include src=\"'/scripts/modules/profiles/components/courses/courseEdit/templates/entryRequirmentsForPostgraduateCourseTemplate.html'\"></div>\n" +
    "        <div ng-if=\"$ctrl.course.courseLevel === 'ug'\" ng-include src=\"'/scripts/modules/profiles/components/courses/courseEdit/templates/entryRequirmentsForUndergraduateCourseTemplate.html'\"></div>\n" +
    "        <div class=\"form-group\">\n" +
    "            <label class=\"col-sm-3 control-label publish-field\">\n" +
    "                Entry Requirements (Other)\n" +
    "            </label>\n" +
    "            <div class=\"col-sm-9\">\n" +
    "                <summernote\n" +
    "                    ng-model=\"$ctrl.course.entryRequirements.other\"\n" +
    "                    config=\"$ctrl.textEditorOptions\"\n" +
    "                    on-paste=\"$ctrl.handleClipboardPaste('introduction')\">\n" +
    "                </summernote>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div ng-if=\"!$ctrl.course.courseLevel\">\n" +
    "        Please fill \"Program Overview\" section first\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/profiles/components/courses/courseEdit/templates/entryRequirmentsForPostgraduateCourseTemplate.html',
    "<div class=\"form-group\">\n" +
    "    <label class=\"col-sm-3 control-label\">\n" +
    "        GRE\n" +
    "    </label>\n" +
    "    <div class=\"col-sm-2 col-md-2 form-subgroup\" ng-class=\"{'has-errors': $ctrl.forms[$ctrl.courseFormName].greMin.$invalid} || $ctrl.isValidEntryReq('gre')\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Minimum required</small></label>\n" +
    "        <input type=\"number\" min=\"260\" max=\"340\" string-to-number\n" +
    "               ng-model=\"$ctrl.course.entryRequirements.gre.value\" \n" +
    "               class=\"form-control\"\n" +
    "               name=\"greMin\"\n" +
    "               ng-keydown=\"$ctrl.handleInvalidNumber($event)\"\n" +
    "               focus-delay=\"250\"\n" +
    "               custom-popover\n" +
    "               popover-html=\"Value must be between 260 and 340\"\n" +
    "               popover-placement=\"left\"\n" +
    "               popover-trigger=\"manual\"\n" +
    "               popover-visibility=\"{{ $ctrl.forms[$ctrl.courseFormName].greMin.$invalid }}\">\n" +
    "        <span custom-popover\n" +
    "            popover-html=\"Minimum required must be less than Maximum expected\"\n" +
    "            popover-placement=\"left\"\n" +
    "            popover-trigger=\"manual\"\n" +
    "            popover-visibility=\"{{ $ctrl.isValidEntryReq('gre') }}\"></span>\n" +
    "    </div>\n" +
    "    <div class=\"col-sm-2 col-md-2 form-subgroup\" ng-class=\"{'has-errors': $ctrl.forms[$ctrl.courseFormName].greMax.$invalid}\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Maximum expected</small></label>\n" +
    "        <input type=\"number\" min=\"260\" max=\"340\" string-to-number  \n" +
    "               ng-model=\"$ctrl.course.entryRequirements.gre.maximumExpected\" \n" +
    "               class=\"form-control\"\n" +
    "               name=\"greMax\"\n" +
    "               ng-keydown=\"$ctrl.handleInvalidNumber($event)\"\n" +
    "               focus-delay=\"250\"\n" +
    "               custom-popover\n" +
    "               popover-html=\"Value must be between 260 and 340\"\n" +
    "               popover-placement=\"left\"\n" +
    "               popover-trigger=\"manual\"\n" +
    "               popover-visibility=\"{{ $ctrl.forms[$ctrl.courseFormName].greMax.$invalid }}\">\n" +
    "    </div>\n" +
    "    <div class=\"col-sm-5 col-md-5\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Comments</small></label>\n" +
    "        <input type=\"text\" ng-model=\"$ctrl.course.entryRequirements.gre.comment\" class=\"form-control\">\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"form-group visible-xs\"></div>\n" +
    "\n" +
    "<div class=\"form-group\">\n" +
    "    <label class=\"col-sm-3 control-label\">\n" +
    "        GMAT\n" +
    "    </label>\n" +
    "    <div class=\"col-sm-2 col-md-2 form-subgroup\" ng-class=\"{'has-errors': $ctrl.forms[$ctrl.courseFormName].gmatMin.$invalid || $ctrl.isValidEntryReq('gmat')}\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Minimum required</small></label>\n" +
    "        <input type=\"number\" min=\"200\" max=\"800\" string-to-number \n" +
    "               ng-model=\"$ctrl.course.entryRequirements.gmat.value\"\n" +
    "               name=\"gmatMin\"\n" +
    "               ng-keydown=\"$ctrl.handleInvalidNumber($event)\"\n" +
    "               class=\"form-control\"\n" +
    "               focus-delay=\"250\"\n" +
    "               custom-popover\n" +
    "               popover-html=\"Value must be between 200 and 800\"\n" +
    "               popover-placement=\"left\"\n" +
    "               popover-trigger=\"manual\"\n" +
    "               popover-visibility=\"{{ $ctrl.forms[$ctrl.courseFormName].gmatMin.$invalid }}\">\n" +
    "        <span custom-popover\n" +
    "              popover-html=\"Minimum required must be less than Maximum expected\"\n" +
    "              popover-placement=\"left\"\n" +
    "              popover-trigger=\"manual\"\n" +
    "              popover-visibility=\"{{ $ctrl.isValidEntryReq('gmat') }}\"></span>\n" +
    "    </div>\n" +
    "    <div class=\"col-sm-2 col-md-2 form-subgroup\" ng-class=\"{'has-errors': $ctrl.forms[$ctrl.courseFormName].gmatMax.$invalid}\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Maximum expected</small></label>\n" +
    "        <input type=\"number\" min=\"200\" max=\"800\" string-to-number \n" +
    "               ng-model=\"$ctrl.course.entryRequirements.gmat.maximumExpected\"\n" +
    "               name=\"gmatMax\" \n" +
    "               ng-keydown=\"$ctrl.handleInvalidNumber($event)\"\n" +
    "               class=\"form-control\"\n" +
    "               focus-delay=\"250\"\n" +
    "               custom-popover\n" +
    "               popover-html=\"Value must be between 200 and 800\"\n" +
    "               popover-placement=\"left\"\n" +
    "               popover-trigger=\"manual\"\n" +
    "               popover-visibility=\"{{ $ctrl.forms[$ctrl.courseFormName].gmatMax.$invalid }}\">\n" +
    "    </div>\n" +
    "    <div class=\"col-sm-5 col-md-5\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Comments</small></label>\n" +
    "        <input type=\"text\" ng-model=\"$ctrl.course.entryRequirements.gmat.comment\" class=\"form-control\">\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"form-group visible-xs\"></div>\n" +
    "\n" +
    "<div class=\"form-group\">\n" +
    "    <label class=\"col-sm-3 control-label\">\n" +
    "        Work Experience\n" +
    "    </label>\n" +
    "    <div class=\"col-sm-2 col-md-2 form-subgroup\" ng-class=\"{'has-errors': $ctrl.forms[$ctrl.courseFormName].workExperienceMin.$invalid}\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Minimum required</small></label>\n" +
    "        <input type=\"number\" min=\"0\" string-to-number \n" +
    "               ng-model=\"$ctrl.course.entryRequirements.workExperience.value\"\n" +
    "               name=\"workExperienceMin\" \n" +
    "               ng-keydown=\"$ctrl.handleInvalidNumber($event)\"\n" +
    "               class=\"form-control\"\n" +
    "               focus-delay=\"250\"\n" +
    "               custom-popover\n" +
    "               popover-html=\"Value must be greater than 0\"\n" +
    "               popover-placement=\"left\"\n" +
    "               popover-trigger=\"manual\"\n" +
    "               popover-visibility=\"{{ $ctrl.forms[$ctrl.courseFormName].workExperienceMin.$invalid }}\">\n" +
    "    </div>\n" +
    "    <div class=\"col-sm-2 col-md-2 form-subgroup\" ng-class=\"{'has-errors': $ctrl.forms[$ctrl.courseFormName].workExperienceMax.$invalid}\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Maximum expected</small></label>\n" +
    "        <input type=\"number\" min=\"0\" string-to-number \n" +
    "               ng-model=\"$ctrl.course.entryRequirements.workExperience.maximumExpected\"\n" +
    "               name=\"workExperienceMax\" \n" +
    "               ng-keydown=\"$ctrl.handleInvalidNumber($event)\"\n" +
    "               class=\"form-control\"\n" +
    "               focus-delay=\"250\"\n" +
    "               custom-popover\n" +
    "               popover-html=\"Value must be greater than 0\"\n" +
    "               popover-placement=\"left\"\n" +
    "               popover-trigger=\"manual\"\n" +
    "               popover-visibility=\"{{ $ctrl.forms[$ctrl.courseFormName].workExperienceMax.$invalid }}\">\n" +
    "    </div>\n" +
    "    <div class=\"col-sm-5 col-md-5\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Comments</small></label>\n" +
    "        <input type=\"text\" ng-model=\"$ctrl.course.entryRequirements.workExperience.comment\" class=\"form-control\">\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"form-group visible-xs\"></div>\n" +
    "\n" +
    "<div class=\"form-group\">\n" +
    "    <label class=\"col-sm-3 control-label\">\n" +
    "        Weighted Average Mark\n" +
    "    </label>\n" +
    "    <div class=\"col-sm-2 col-md-2 form-subgroup\" ng-class=\"{'has-errors': $ctrl.forms[$ctrl.courseFormName].weightedAverageMin.$invalid}\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Minimum required</small></label>\n" +
    "        <input type=\"number\" min=\"0\" string-to-number \n" +
    "               ng-model=\"$ctrl.course.entryRequirements.weightedAverageMark.value\"\n" +
    "               name=\"weightedAverageMin\"\n" +
    "               ng-keydown=\"$ctrl.handleInvalidNumber($event)\" \n" +
    "               class=\"form-control\"\n" +
    "               focus-delay=\"250\"\n" +
    "               custom-popover\n" +
    "               popover-html=\"Value must be greater than 0\"\n" +
    "               popover-placement=\"left\"\n" +
    "               popover-trigger=\"manual\"\n" +
    "               popover-visibility=\"{{ $ctrl.forms[$ctrl.courseFormName].weightedAverageMin.$invalid }}\">\n" +
    "    </div>\n" +
    "    <div class=\"col-sm-2 col-md-2 form-subgroup\" ng-class=\"{'has-errors': $ctrl.forms[$ctrl.courseFormName].weightedAverageMax.$invalid}\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Maximum expected</small></label>\n" +
    "        <input type=\"number\" min=\"0\" string-to-number  \n" +
    "               ng-model=\"$ctrl.course.entryRequirements.weightedAverageMark.maximumExpected\"\n" +
    "               name=\"weightedAverageMax\"\n" +
    "               ng-keydown=\"$ctrl.handleInvalidNumber($event)\" \n" +
    "               class=\"form-control\"\n" +
    "               focus-delay=\"250\"\n" +
    "               custom-popover\n" +
    "               popover-html=\"Value must be greater than 0\"\n" +
    "               popover-placement=\"left\"\n" +
    "               popover-trigger=\"manual\"\n" +
    "               popover-visibility=\"{{ $ctrl.forms[$ctrl.courseFormName].weightedAverageMax.$invalid }}\">\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"col-sm-5 col-md-5\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Comments</small></label>\n" +
    "        <input type=\"text\" ng-model=\"$ctrl.course.entryRequirements.weightedAverageMark.comment\" class=\"form-control\">\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/profiles/components/courses/courseEdit/templates/entryRequirmentsForUgAndPgCourseTemplate.html',
    "<div class=\"form-group hidden-xs\">\n" +
    "    <label class=\"col-sm-2 col-md-2 control-label col-md-offset-3 col-sm-offset-3\">\n" +
    "        Minimum required\n" +
    "    </label>\n" +
    "    <label class=\"col-sm-2 col-md-2 control-label\">\n" +
    "        Maximum expected\n" +
    "    </label>\n" +
    "    <label class=\"col-sm-5 col-md-5 control-label\">\n" +
    "        Comments\n" +
    "    </label>\n" +
    "</div>\n" +
    "<div class=\"form-group\">\n" +
    "    <label class=\"col-sm-3 control-label publish-field\">\n" +
    "        IELTS\n" +
    "    </label>\n" +
    "    <div class=\"col-sm-2 col-md-2 form-subgroup\" ng-class=\"{'has-errors': $ctrl.forms[$ctrl.courseFormName].ieltsMin.$invalid || $ctrl.isValidEntryReq('ielts')}\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Minimum required</small></label>\n" +
    "        <input type=\"number\" min=\"0\" max=\"9\" string-to-number \n" +
    "               ng-model=\"$ctrl.course.entryRequirements.ielts.value\" \n" +
    "               class=\"form-control\"\n" +
    "               name=\"ieltsMin\"\n" +
    "               ng-keydown=\"$ctrl.handleInvalidNumber($event)\"\n" +
    "               focus-delay=\"250\"\n" +
    "               custom-popover\n" +
    "               popover-html=\"Value must be between 0 and 9\"\n" +
    "               popover-placement=\"left\"\n" +
    "               popover-trigger=\"manual\"\n" +
    "               popover-visibility=\"{{ $ctrl.forms[$ctrl.courseFormName].ieltsMin.$invalid }}\">\n" +
    "        <span custom-popover\n" +
    "            popover-html=\"Minimum required must be less than Maximum expected\"\n" +
    "            popover-placement=\"left\"\n" +
    "            popover-trigger=\"manual\"\n" +
    "            popover-visibility=\"{{ $ctrl.isValidEntryReq('ielts') }}\"></span>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"col-sm-2 col-md-2 form-subgroup\" ng-class=\"{'has-errors': $ctrl.forms[$ctrl.courseFormName].ieltsMax.$invalid}\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Maximum expected</small></label>\n" +
    "        <input type=\"number\" min=\"0\" max=\"9\" string-to-number \n" +
    "               ng-model=\"$ctrl.course.entryRequirements.ielts.maximumExpected\" \n" +
    "               class=\"form-control\"\n" +
    "               name=\"ieltsMax\"\n" +
    "               ng-keydown=\"$ctrl.handleInvalidNumber($event)\"\n" +
    "               focus-delay=\"250\"\n" +
    "               custom-popover\n" +
    "               popover-html=\"Value must be between 0 and 9\"\n" +
    "               popover-placement=\"left\"\n" +
    "               popover-trigger=\"manual\"\n" +
    "               popover-visibility=\"{{ $ctrl.forms[$ctrl.courseFormName].ieltsMax.$invalid }}\">\n" +
    "    </div>\n" +
    "    <div class=\"col-sm-5 col-md-5\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Comments</small></label>\n" +
    "        <input type=\"text\" ng-model=\"$ctrl.course.entryRequirements.ielts.comment\" class=\"form-control\">\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"form-group visible-xs\"></div>\n" +
    "\n" +
    "<div class=\"form-group\">\n" +
    "    <label class=\"col-sm-3 control-label\">\n" +
    "        Bachelor GPA\n" +
    "    </label>\n" +
    "    <div class=\"col-sm-2 col-md-2 form-subgroup\" ng-class=\"{'has-errors': $ctrl.forms[$ctrl.courseFormName].gpaMin.$invalid || $ctrl.isValidEntryReq('bachelorGpa')}\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Minimum required</small></label>\n" +
    "        <input type=\"number\" min=\"0\" max=\"7\" string-to-number \n" +
    "               ng-model=\"$ctrl.course.entryRequirements.bachelorGpa.value\" \n" +
    "               class=\"form-control\"\n" +
    "               name=\"gpaMin\"\n" +
    "               ng-keydown=\"$ctrl.handleInvalidNumber($event)\"\n" +
    "               focus-delay=\"250\"\n" +
    "               custom-popover\n" +
    "               popover-html=\"Value must be between 0 and 7\"\n" +
    "               popover-placement=\"left\"\n" +
    "               popover-trigger=\"manual\"\n" +
    "               popover-visibility=\"{{ $ctrl.forms[$ctrl.courseFormName].gpaMin.$invalid }}\">\n" +
    "        <span custom-popover\n" +
    "            popover-html=\"Minimum required must be less than Maximum expected\"\n" +
    "            popover-placement=\"left\"\n" +
    "            popover-trigger=\"manual\"\n" +
    "            popover-visibility=\"{{ $ctrl.isValidEntryReq('bachelorGpa') }}\"></span>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"col-sm-2 col-md-2 form-subgroup\" ng-class=\"{'has-errors': $ctrl.forms[$ctrl.courseFormName].gpaMax.$invalid}\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Maximum expected</small></label>\n" +
    "        <input type=\"number\" min=\"0\" max=\"7\" string-to-number \n" +
    "               ng-model=\"$ctrl.course.entryRequirements.bachelorGpa.maximumExpected\" \n" +
    "               class=\"form-control\"\n" +
    "               name=\"gpaMax\"\n" +
    "               ng-keydown=\"$ctrl.handleInvalidNumber($event)\"\n" +
    "               focus-delay=\"250\"\n" +
    "               custom-popover\n" +
    "               popover-html=\"Value must be between 0 and 7\"\n" +
    "               popover-placement=\"left\"\n" +
    "               popover-trigger=\"manual\"\n" +
    "               popover-visibility=\"{{ $ctrl.forms[$ctrl.courseFormName].gpaMax.$invalid }}\">\n" +
    "    </div>\n" +
    "    <div class=\"col-sm-5 col-md-5\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Comments</small></label>\n" +
    "        <input type=\"text\" ng-model=\"$ctrl.course.entryRequirements.bachelorGpa.comment\" class=\"form-control\">\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"form-group visible-xs\"></div>\n" +
    "\n" +
    "<div class=\"form-group\">\n" +
    "    <label class=\"col-sm-3 control-label\">\n" +
    "        TOEFL\n" +
    "    </label>\n" +
    "    <div class=\"col-sm-2 col-md-2 form-subgroup\" ng-class=\"{'has-errors': $ctrl.forms[$ctrl.courseFormName].toeflMin.$invalid || $ctrl.isValidEntryReq('toefl')}\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Minimum required</small></label>\n" +
    "        <input type=\"number\" min=\"0\" max=\"120\" string-to-number \n" +
    "               ng-model=\"$ctrl.course.entryRequirements.toefl.value\" \n" +
    "               class=\"form-control\"\n" +
    "               name=\"toeflMin\"\n" +
    "               ng-keydown=\"$ctrl.handleInvalidNumber($event)\"\n" +
    "               focus-delay=\"250\"\n" +
    "               custom-popover\n" +
    "               popover-html=\"Value must be between 0 and 120\"\n" +
    "               popover-placement=\"left\"\n" +
    "               popover-trigger=\"manual\"\n" +
    "               popover-visibility=\"{{ $ctrl.forms[$ctrl.courseFormName].toeflMin.$invalid }}\">\n" +
    "        <span custom-popover\n" +
    "            popover-html=\"Minimum required must be less than Maximum expected\"\n" +
    "            popover-placement=\"left\"\n" +
    "            popover-trigger=\"manual\"\n" +
    "            popover-visibility=\"{{ $ctrl.isValidEntryReq('toefl') }}\"></span>\n" +
    "    </div>\n" +
    "    <div class=\"col-sm-2 col-md-2 form-subgroup\" ng-class=\"{'has-errors': $ctrl.forms[$ctrl.courseFormName].toeflMax.$invalid}\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Maximum expected</small></label>\n" +
    "        <input type=\"number\" min=\"0\" max=\"120\" string-to-number \n" +
    "               ng-model=\"$ctrl.course.entryRequirements.toefl.maximumExpected\" \n" +
    "               class=\"form-control\"\n" +
    "               name=\"toeflMax\"\n" +
    "               ng-keydown=\"$ctrl.handleInvalidNumber($event)\"\n" +
    "               focus-delay=\"250\"\n" +
    "               custom-popover\n" +
    "               popover-html=\"Value must be between 0 and 120\"\n" +
    "               popover-placement=\"left\"\n" +
    "               popover-trigger=\"manual\"\n" +
    "               popover-visibility=\"{{ $ctrl.forms[$ctrl.courseFormName].toeflMax.$invalid }}\">\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"col-sm-5 col-md-5\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Comments</small></label>\n" +
    "        <input type=\"text\" ng-model=\"$ctrl.course.entryRequirements.toefl.comment\" class=\"form-control\">\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"form-group visible-xs\"></div>\n" +
    "\n" +
    "<div class=\"form-group\">\n" +
    "    <label class=\"col-sm-3 control-label\">\n" +
    "        Cambridge CAE Advanced\n" +
    "    </label>\n" +
    "    <div class=\"col-sm-2 col-md-2 form-subgroup\" ng-class=\"{'has-errors': $ctrl.forms[$ctrl.courseFormName].cambridgeMin.$invalid}\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Minimum required</small></label>\n" +
    "        <input type=\"number\" min=\"0\" string-to-number \n" +
    "               ng-model=\"$ctrl.course.entryRequirements.cambridgeCaeAdvanced.value\" \n" +
    "               name=\"cambridgeMin\"\n" +
    "               ng-keydown=\"$ctrl.handleInvalidNumber($event)\"\n" +
    "               class=\"form-control\"\n" +
    "               focus-delay=\"250\"\n" +
    "               custom-popover\n" +
    "               popover-html=\"Value must be greater than 0\"\n" +
    "               popover-placement=\"left\"\n" +
    "               popover-trigger=\"manual\"\n" +
    "               popover-visibility=\"{{ $ctrl.forms[$ctrl.courseFormName].cambridgeMin.$invalid }}\">\n" +
    "    </div>\n" +
    "    <div class=\"col-sm-2 col-md-2 form-subgroup\" ng-class=\"{'has-errors': $ctrl.forms[$ctrl.courseFormName].cambridgeMax.$invalid}\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Maximum expected</small></label>\n" +
    "        <input type=\"number\" min=\"0\" string-to-number \n" +
    "               ng-model=\"$ctrl.course.entryRequirements.cambridgeCaeAdvanced.maximumExpected\"\n" +
    "               name=\"cambridgeMax\" \n" +
    "               ng-keydown=\"$ctrl.handleInvalidNumber($event)\"\n" +
    "               class=\"form-control\"\n" +
    "               focus-delay=\"250\"\n" +
    "               custom-popover\n" +
    "               popover-html=\"Value must be greater than 0\"\n" +
    "               popover-placement=\"left\"\n" +
    "               popover-trigger=\"manual\"\n" +
    "               popover-visibility=\"{{ $ctrl.forms[$ctrl.courseFormName].cambridgeMax.$invalid }}\">\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"col-sm-5 col-md-5\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Comments</small></label>\n" +
    "        <input type=\"text\" ng-model=\"$ctrl.course.entryRequirements.cambridgeCaeAdvanced.comment\" class=\"form-control\">\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"form-group visible-xs\"></div>\n" +
    "\n" +
    "<div class=\"form-group\">\n" +
    "    <label class=\"col-sm-3 control-label\">\n" +
    "        PTE Academic\n" +
    "    </label>\n" +
    "    <div class=\"col-sm-2 col-md-2 form-subgroup\" ng-class=\"{'has-errors': $ctrl.forms[$ctrl.courseFormName].pteAcademicMin.$invalid}\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Minimum required</small></label>\n" +
    "        <input type=\"number\" min=\"0\" string-to-number \n" +
    "               ng-model=\"$ctrl.course.entryRequirements.pteAcademic.value\"\n" +
    "               name=\"pteAcademicMin\" \n" +
    "               ng-keydown=\"$ctrl.handleInvalidNumber($event)\"\n" +
    "               class=\"form-control\"\n" +
    "               focus-delay=\"250\"\n" +
    "               custom-popover\n" +
    "               popover-html=\"Value must be greater than 0\"\n" +
    "               popover-placement=\"left\"\n" +
    "               popover-trigger=\"manual\"\n" +
    "               popover-visibility=\"{{ $ctrl.forms[$ctrl.courseFormName].pteAcademicMin.$invalid }}\">\n" +
    "    </div>\n" +
    "    <div class=\"col-sm-2 col-md-2 form-subgroup\" ng-class=\"{'has-errors': $ctrl.forms[$ctrl.courseFormName].pteAcademicMax.$invalid}\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Maximum expected</small></label>\n" +
    "        <input type=\"number\" min=\"0\" string-to-number \n" +
    "               ng-model=\"$ctrl.course.entryRequirements.pteAcademic.maximumExpected\"\n" +
    "               name=\"pteAcademicMax\"\n" +
    "               ng-keydown=\"$ctrl.handleInvalidNumber($event)\" \n" +
    "               class=\"form-control\"\n" +
    "               focus-delay=\"250\"\n" +
    "               custom-popover\n" +
    "               popover-html=\"Value must be greater than 0\"\n" +
    "               popover-placement=\"left\"\n" +
    "               popover-trigger=\"manual\"\n" +
    "               popover-visibility=\"{{ $ctrl.forms[$ctrl.courseFormName].pteAcademicMax.$invalid }}\">\n" +
    "    </div>\n" +
    "    <div class=\"col-sm-5 col-md-5\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Comments</small></label>\n" +
    "        <input type=\"text\" ng-model=\"$ctrl.course.entryRequirements.pteAcademic.comment\" class=\"form-control\">\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"form-group visible-xs\"></div>\n"
  );


  $templateCache.put('/scripts/modules/profiles/components/courses/courseEdit/templates/entryRequirmentsForUndergraduateCourseTemplate.html',
    "<div class=\"form-group\">\n" +
    "    <label class=\"col-sm-3 control-label\">\n" +
    "        A-levels\n" +
    "    </label>\n" +
    "    <div class=\"col-sm-2 col-md-2 form-subgroup\" ng-class=\"{'has-errors': $ctrl.forms[$ctrl.courseFormName].aLevelsMin.$invalid}\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Minimum required</small></label>\n" +
    "        <input type=\"number\" min=\"0\" string-to-number \n" +
    "               ng-model=\"$ctrl.course.entryRequirements.aLevels.value\" \n" +
    "               name=\"aLevelsMin\"\n" +
    "               ng-keydown=\"$ctrl.handleInvalidNumber($event)\"\n" +
    "               class=\"form-control\"\n" +
    "               focus-delay=\"250\"\n" +
    "               custom-popover\n" +
    "               popover-html=\"Value must be greater than 0\"\n" +
    "               popover-placement=\"left\"\n" +
    "               popover-trigger=\"manual\"\n" +
    "               popover-visibility=\"{{ $ctrl.forms[$ctrl.courseFormName].aLevelsMin.$invalid }}\">\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"col-sm-2 col-md-2 form-subgroup\" ng-class=\"{'has-errors': $ctrl.forms[$ctrl.courseFormName].aLevelsMax.$invalid}\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Maximum expected</small></label>\n" +
    "        <input type=\"number\" min=\"0\" string-to-number \n" +
    "               ng-model=\"$ctrl.course.entryRequirements.aLevels.maximumExpected\"\n" +
    "               name=\"aLevelsMax\"\n" +
    "               ng-keydown=\"$ctrl.handleInvalidNumber($event)\" \n" +
    "               class=\"form-control\"\n" +
    "               focus-delay=\"250\"\n" +
    "               custom-popover\n" +
    "               popover-html=\"Value must be greater than 0\"\n" +
    "               popover-placement=\"left\"\n" +
    "               popover-trigger=\"manual\"\n" +
    "               popover-visibility=\"{{ $ctrl.forms[$ctrl.courseFormName].aLevelsMax.$invalid }}\">\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"col-sm-5 col-md-5\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Comments</small></label>\n" +
    "        <input type=\"text\" ng-model=\"$ctrl.course.entryRequirements.aLevels.comment\" class=\"form-control\">\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"form-group visible-xs\"></div>\n" +
    "\n" +
    "<div class=\"form-group\">\n" +
    "    <label class=\"col-sm-3 control-label\">\n" +
    "        UCAS Tariff\n" +
    "    </label>\n" +
    "    <div class=\"col-sm-2 col-md-2 form-subgroup\" ng-class=\"{'has-errors': $ctrl.forms[$ctrl.courseFormName].ucasTariffMin.$invalid}\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Minimum required</small></label>\n" +
    "        <input type=\"number\" min=\"0\" string-to-number \n" +
    "               ng-model=\"$ctrl.course.entryRequirements.ucasTariff.value\"\n" +
    "               name=\"ucasTariffMin\" \n" +
    "               ng-keydown=\"$ctrl.handleInvalidNumber($event)\"\n" +
    "               class=\"form-control\"\n" +
    "               focus-delay=\"250\"\n" +
    "               custom-popover\n" +
    "               popover-html=\"Value must be greater than 0\"\n" +
    "               popover-placement=\"left\"\n" +
    "               popover-trigger=\"manual\"\n" +
    "               popover-visibility=\"{{ $ctrl.forms[$ctrl.courseFormName].ucasTariffMin.$invalid }}\">\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"col-sm-2 col-md-2 form-subgroup\" ng-class=\"{'has-errors': $ctrl.forms[$ctrl.courseFormName].ucasTariffMax.$invalid}\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Maximum expected</small></label>\n" +
    "        <input type=\"number\" min=\"0\" string-to-number \n" +
    "               ng-model=\"$ctrl.course.entryRequirements.ucasTariff.maximumExpected\"\n" +
    "               name=\"ucasTariffMax\" \n" +
    "               ng-keydown=\"$ctrl.handleInvalidNumber($event)\"\n" +
    "               class=\"form-control\"\n" +
    "               focus-delay=\"250\"\n" +
    "               custom-popover\n" +
    "               popover-html=\"Value must be greater than 0\"\n" +
    "               popover-placement=\"left\"\n" +
    "               popover-trigger=\"manual\"\n" +
    "               popover-visibility=\"{{ $ctrl.forms[$ctrl.courseFormName].ucasTariffMax.$invalid }}\">\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"col-sm-5 col-md-5\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Comments</small></label>\n" +
    "        <input type=\"text\" ng-model=\"$ctrl.course.entryRequirements.ucasTariff.comment\" class=\"form-control\">\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"form-group visible-xs\"></div>\n" +
    "\n" +
    "<div class=\"form-group\">\n" +
    "    <label class=\"col-sm-3 control-label\">\n" +
    "        AS-levels\n" +
    "    </label>\n" +
    "    <div class=\"col-sm-2 col-md-2 form-subgroup\" ng-class=\"{'has-errors': $ctrl.forms[$ctrl.courseFormName].asLevelsMin.$invalid}\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Minimum required</small></label>\n" +
    "        <input type=\"number\" min=\"0\" string-to-number \n" +
    "               ng-model=\"$ctrl.course.entryRequirements.asLevels.value\"\n" +
    "               name=\"asLevelsMin\" \n" +
    "               ng-keydown=\"$ctrl.handleInvalidNumber($event)\"\n" +
    "               class=\"form-control\"\n" +
    "               focus-delay=\"250\"\n" +
    "               custom-popover\n" +
    "               popover-html=\"Value must be greater than 0\"\n" +
    "               popover-placement=\"left\"\n" +
    "               popover-trigger=\"manual\"\n" +
    "               popover-visibility=\"{{ $ctrl.forms[$ctrl.courseFormName].asLevelsMin.$invalid }}\">\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"col-sm-2 col-md-2 form-subgroup\" ng-class=\"{'has-errors': $ctrl.forms[$ctrl.courseFormName].asLevelsMax.$invalid}\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Maximum expected</small></label>\n" +
    "        <input type=\"number\" min=\"0\" string-to-number \n" +
    "               ng-model=\"$ctrl.course.entryRequirements.asLevels.maximumExpected\"\n" +
    "               name=\"asLevelsMax\" \n" +
    "               ng-keydown=\"$ctrl.handleInvalidNumber($event)\"\n" +
    "               class=\"form-control\"\n" +
    "               focus-delay=\"250\"\n" +
    "               custom-popover\n" +
    "               popover-html=\"Value must be greater than 0\"\n" +
    "               popover-placement=\"left\"\n" +
    "               popover-trigger=\"manual\"\n" +
    "               popover-visibility=\"{{ $ctrl.forms[$ctrl.courseFormName].asLevelsMax.$invalid }}\">\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"col-sm-5 col-md-5\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Comments</small></label>\n" +
    "        <input type=\"text\" ng-model=\"$ctrl.course.entryRequirements.asLevels.comment\" class=\"form-control\">\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"form-group visible-xs\"></div>\n" +
    "\n" +
    "<div class=\"form-group\">\n" +
    "    <label class=\"col-sm-3 control-label\">\n" +
    "        SQA Highers\n" +
    "    </label>\n" +
    "    <div class=\"col-sm-2 col-md-2 form-subgroup\" ng-class=\"{'has-errors': $ctrl.forms[$ctrl.courseFormName].sqaHighersMin.$invalid}\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Minimum required</small></label>\n" +
    "        <input type=\"number\" min=\"0\" string-to-number \n" +
    "               ng-model=\"$ctrl.course.entryRequirements.sqaHighers.value\"\n" +
    "               name=\"sqaHighersMin\" \n" +
    "               ng-keydown=\"$ctrl.handleInvalidNumber($event)\"\n" +
    "               class=\"form-control\"\n" +
    "               focus-delay=\"250\"\n" +
    "               custom-popover\n" +
    "               popover-html=\"Value must be greater than 0\"\n" +
    "               popover-placement=\"left\"\n" +
    "               popover-trigger=\"manual\"\n" +
    "               popover-visibility=\"{{ $ctrl.forms[$ctrl.courseFormName].sqaHighersMin.$invalid }}\">\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"col-sm-2 col-md-2 form-subgroup\" ng-class=\"{'has-errors': $ctrl.forms[$ctrl.courseFormName].sqaHighersMax.$invalid}\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Maximum expected</small></label>\n" +
    "        <input type=\"number\" min=\"0\" string-to-number \n" +
    "               ng-model=\"$ctrl.course.entryRequirements.sqaHighers.maximumExpected\"\n" +
    "               name=\"sqaHighersMax\" \n" +
    "               ng-keydown=\"$ctrl.handleInvalidNumber($event)\"\n" +
    "               class=\"form-control\"\n" +
    "               focus-delay=\"250\"\n" +
    "               custom-popover\n" +
    "               popover-html=\"Value must be greater than 0\"\n" +
    "               popover-placement=\"left\"\n" +
    "               popover-trigger=\"manual\"\n" +
    "               popover-visibility=\"{{ $ctrl.forms[$ctrl.courseFormName].sqaHighersMax.$invalid }}\">\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"col-sm-5 col-md-5\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Comments</small></label>\n" +
    "        <input type=\"text\" ng-model=\"$ctrl.course.entryRequirements.sqaHighers.comment\" class=\"form-control\">\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"form-group visible-xs\"></div>\n" +
    "\n" +
    "<div class=\"form-group\">\n" +
    "    <label class=\"col-sm-3 control-label\">\n" +
    "        SQA Advanced\n" +
    "    </label>\n" +
    "    <div class=\"col-sm-2 col-md-2 form-subgroup\" ng-class=\"{'has-errors': $ctrl.forms[$ctrl.courseFormName].sqaAdvancedMin.$invalid}\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Minimum required</small></label>\n" +
    "        <input type=\"number\" min=\"0\" string-to-number \n" +
    "               ng-model=\"$ctrl.course.entryRequirements.sqaAdvanced.value\" \n" +
    "               name=\"sqaAdvancedMin\"\n" +
    "               ng-keydown=\"$ctrl.handleInvalidNumber($event)\"\n" +
    "               class=\"form-control\"\n" +
    "               focus-delay=\"250\"\n" +
    "               custom-popover\n" +
    "               popover-html=\"Value must be greater than 0\"\n" +
    "               popover-placement=\"left\"\n" +
    "               popover-trigger=\"manual\"\n" +
    "               popover-visibility=\"{{ $ctrl.forms[$ctrl.courseFormName].sqaAdvancedMin.$invalid }}\">\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"col-sm-2 col-md-2 form-subgroup\" ng-class=\"{'has-errors': $ctrl.forms[$ctrl.courseFormName].sqaAdvancedMax.$invalid}\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Maximum expected</small></label>\n" +
    "        <input type=\"number\" min=\"0\" string-to-number \n" +
    "               ng-model=\"$ctrl.course.entryRequirements.sqaAdvanced.maximumExpected\"\n" +
    "               name=\"sqaAdvancedMax\"\n" +
    "               ng-keydown=\"$ctrl.handleInvalidNumber($event)\" \n" +
    "               class=\"form-control\"\n" +
    "               focus-delay=\"250\"\n" +
    "               custom-popover\n" +
    "               popover-html=\"Value must be greater than 0\"\n" +
    "               popover-placement=\"left\"\n" +
    "               popover-trigger=\"manual\"\n" +
    "               popover-visibility=\"{{ $ctrl.forms[$ctrl.courseFormName].sqaAdvancedMax.$invalid }}\">\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"col-sm-5 col-md-5\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Comments</small></label>\n" +
    "        <input type=\"text\" ng-model=\"$ctrl.course.entryRequirements.sqaAdvanced.comment\" class=\"form-control\">\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"form-group visible-xs\"></div>\n" +
    "\n" +
    "<div class=\"form-group\">\n" +
    "    <label class=\"col-sm-3 control-label\">\n" +
    "        BTEC Qualifications\n" +
    "    </label>\n" +
    "    <div class=\"col-sm-2 col-md-2 form-subgroup\" ng-class=\"{'has-errors': $ctrl.forms[$ctrl.courseFormName].btecQualificationsMin.$invalid}\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Minimum required</small></label>\n" +
    "        <input type=\"number\" min=\"0\" string-to-number \n" +
    "               ng-model=\"$ctrl.course.entryRequirements.btecQualifications.value\"\n" +
    "               name=\"btecQualificationsMin\" \n" +
    "               ng-keydown=\"$ctrl.handleInvalidNumber($event)\"\n" +
    "               class=\"form-control\"\n" +
    "               focus-delay=\"250\"\n" +
    "               custom-popover\n" +
    "               popover-html=\"Value must be greater than 0\"\n" +
    "               popover-placement=\"left\"\n" +
    "               popover-trigger=\"manual\"\n" +
    "               popover-visibility=\"{{ $ctrl.forms[$ctrl.courseFormName].btecQualificationsMin.$invalid }}\">\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"col-sm-2 col-md-2 form-subgroup\" ng-class=\"{'has-errors': $ctrl.forms[$ctrl.courseFormName].btecQualificationsMax.$invalid}\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Maximum expected</small></label>\n" +
    "        <input type=\"number\" min=\"0\" string-to-number \n" +
    "               ng-model=\"$ctrl.course.entryRequirements.btecQualifications.maximumExpected\"\n" +
    "               name=\"btecQualificationsMax\"\n" +
    "               ng-keydown=\"$ctrl.handleInvalidNumber($event)\" \n" +
    "               class=\"form-control\"\n" +
    "               focus-delay=\"250\"\n" +
    "               custom-popover\n" +
    "               popover-html=\"Value must be greater than 0\"\n" +
    "               popover-placement=\"left\"\n" +
    "               popover-trigger=\"manual\"\n" +
    "               popover-visibility=\"{{ $ctrl.forms[$ctrl.courseFormName].btecQualificationsMax.$invalid }}\">\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"col-sm-5 col-md-5\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Comments</small></label>\n" +
    "        <input type=\"text\" ng-model=\"$ctrl.course.entryRequirements.btecQualifications.comment\" class=\"form-control\">\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"form-group visible-xs\"></div>\n" +
    "\n" +
    "<div class=\"form-group\">\n" +
    "    <label class=\"col-xs-12 col-sm-3 control-label\">\n" +
    "        Own admission test\n" +
    "    </label>\n" +
    "    <div class=\"col-sm-2 col-md-2 form-subgroup\" ng-class=\"{'has-errors': $ctrl.forms[$ctrl.courseFormName].ownAdmissionMin.$invalid}\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Minimum required</small></label>\n" +
    "        <input type=\"number\" min=\"0\" string-to-number \n" +
    "               ng-model=\"$ctrl.course.entryRequirements.ownAdmission.value\"\n" +
    "               name=\"ownAdmissionMin\" \n" +
    "               ng-keydown=\"$ctrl.handleInvalidNumber($event)\"\n" +
    "               class=\"form-control\"\n" +
    "               focus-delay=\"250\"\n" +
    "               custom-popover\n" +
    "               popover-html=\"Value must be greater than 0\"\n" +
    "               popover-placement=\"left\"\n" +
    "               popover-trigger=\"manual\"\n" +
    "               popover-visibility=\"{{ $ctrl.forms[$ctrl.courseFormName].ownAdmissionMin.$invalid }}\">\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"col-sm-2 col-md-2 form-subgroup\" ng-class=\"{'has-errors': $ctrl.forms[$ctrl.courseFormName].ownAdmissionMax.$invalid}\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Maximum expected</small></label>\n" +
    "        <input type=\"number\" min=\"0\" string-to-number \n" +
    "               ng-model=\"$ctrl.course.entryRequirements.ownAdmission.maximumExpected\"\n" +
    "               name=\"ownAdmissionMax\" \n" +
    "               ng-keydown=\"$ctrl.handleInvalidNumber($event)\"\n" +
    "               class=\"form-control\"\n" +
    "               focus-delay=\"250\"\n" +
    "               custom-popover\n" +
    "               popover-html=\"Value must be greater than 0\"\n" +
    "               popover-placement=\"left\"\n" +
    "               popover-trigger=\"manual\"\n" +
    "               popover-visibility=\"{{ $ctrl.forms[$ctrl.courseFormName].ownAdmissionMax.$invalid }}\">\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"col-sm-5 col-md-5\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Comments</small></label>\n" +
    "        <input type=\"text\" ng-model=\"$ctrl.course.entryRequirements.ownAdmission.comment\" class=\"form-control\">\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"form-group visible-xs\"></div>\n" +
    "\n" +
    "<div class=\"form-group\">\n" +
    "    <label class=\"col-xs-12 col-sm-3 control-label\">\n" +
    "        International Baccalaureate\n" +
    "    </label>\n" +
    "    <div class=\"col-sm-2 col-md-2 form-subgroup\" ng-class=\"{'has-errors': $ctrl.forms[$ctrl.courseFormName].internationalBaccalaureateMin.$invalid}\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Minimum required</small></label>\n" +
    "        <input type=\"number\" min=\"0\" string-to-number \n" +
    "               ng-model=\"$ctrl.course.entryRequirements.internationalBaccalaureate.value\"\n" +
    "               name=\"internationalBaccalaureateMin\"\n" +
    "               ng-keydown=\"$ctrl.handleInvalidNumber($event)\" \n" +
    "               class=\"form-control\"\n" +
    "               focus-delay=\"250\"\n" +
    "               custom-popover\n" +
    "               popover-html=\"Value must be greater than 0\"\n" +
    "               popover-placement=\"left\"\n" +
    "               popover-trigger=\"manual\"\n" +
    "               popover-visibility=\"{{ $ctrl.forms[$ctrl.courseFormName].internationalBaccalaureateMin.$invalid }}\">\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"col-sm-2 col-md-2 form-subgroup\" ng-class=\"{'has-errors': $ctrl.forms[$ctrl.courseFormName].internationalBaccalaureateMax.$invalid}\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Maximum expected</small></label>\n" +
    "        <input type=\"number\" min=\"0\" string-to-number \n" +
    "               ng-model=\"$ctrl.course.entryRequirements.internationalBaccalaureate.maximumExpected\"\n" +
    "               name=\"internationalBaccalaureateMax\"\n" +
    "               ng-keydown=\"$ctrl.handleInvalidNumber($event)\" \n" +
    "               class=\"form-control\"\n" +
    "               focus-delay=\"250\"\n" +
    "               custom-popover\n" +
    "               popover-html=\"Value must be greater than 0\"\n" +
    "               popover-placement=\"left\"\n" +
    "               popover-trigger=\"manual\"\n" +
    "               popover-visibility=\"{{ $ctrl.forms[$ctrl.courseFormName].internationalBaccalaureateMax.$invalid }}\">\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"col-sm-5 col-md-5\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Comments</small></label>\n" +
    "        <input type=\"text\" ng-model=\"$ctrl.course.entryRequirements.internationalBaccalaureate.comment\" class=\"form-control\">\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"form-group\">\n" +
    "    <label class=\"col-xs-12 col-sm-3 control-label\">\n" +
    "        SAT\n" +
    "    </label>\n" +
    "    <div class=\"col-sm-2 col-md-2 form-subgroup\" ng-class=\"{'has-errors': $ctrl.forms[$ctrl.courseFormName].satMin.$invalid || $ctrl.isValidEntryReq('sat')}\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Minimum required</small></label>\n" +
    "        <input type=\"number\" min=\"400\" max=\"1600\" string-to-number \n" +
    "               ng-model=\"$ctrl.course.entryRequirements.sat.value\" \n" +
    "               class=\"form-control\"\n" +
    "               name=\"satMin\"\n" +
    "               ng-keydown=\"$ctrl.handleInvalidNumber($event)\"\n" +
    "               focus-delay=\"250\"\n" +
    "               custom-popover\n" +
    "               popover-html=\"Value must be between 400 and 1600\"\n" +
    "               popover-placement=\"left\"\n" +
    "               popover-trigger=\"manual\"\n" +
    "               popover-visibility=\"{{ $ctrl.forms[$ctrl.courseFormName].satMin.$invalid }}\">\n" +
    "        <span custom-popover\n" +
    "            popover-html=\"Minimum required must be less than Maximum expected\"\n" +
    "            popover-placement=\"left\"\n" +
    "            popover-trigger=\"manual\"\n" +
    "            popover-visibility=\"{{ $ctrl.isValidEntryReq('sat') }}\"></span>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"col-sm-2 col-md-2 form-subgroup\" ng-class=\"{'has-errors': $ctrl.forms[$ctrl.courseFormName].satMax.$invalid}\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Maximum expected</small></label>\n" +
    "        <input type=\"number\" min=\"400\" max=\"1600\" string-to-number \n" +
    "               ng-model=\"$ctrl.course.entryRequirements.sat.maximumExpected\" \n" +
    "               class=\"form-control\"\n" +
    "               name=\"satMax\"\n" +
    "               ng-keydown=\"$ctrl.handleInvalidNumber($event)\"\n" +
    "               focus-delay=\"250\"\n" +
    "               custom-popover\n" +
    "               popover-html=\"Value must be between 400 and 1600\"\n" +
    "               popover-placement=\"left\"\n" +
    "               popover-trigger=\"manual\"\n" +
    "               popover-visibility=\"{{ $ctrl.forms[$ctrl.courseFormName].satMax.$invalid }}\">\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"col-sm-5 col-md-5\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Comments</small></label>\n" +
    "        <input type=\"text\" ng-model=\"$ctrl.course.entryRequirements.sat.comment\" class=\"form-control\">\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"form-group visible-xs\"></div>\n" +
    "\n" +
    "<div class=\"form-group\">\n" +
    "    <label class=\"col-sm-3 control-label\">\n" +
    "        Year 12 scores\n" +
    "    </label>\n" +
    "    <div class=\"col-sm-2 col-md-2 form-subgroup\" ng-class=\"{'has-errors': $ctrl.forms[$ctrl.courseFormName].yearTwelveScoresMin.$invalid}\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Minimum required</small></label>\n" +
    "        <input type=\"number\" min=\"0\" string-to-number \n" +
    "               ng-model=\"$ctrl.course.entryRequirements.yearTwelveScores.value\"\n" +
    "               name=\"yearTwelveScoresMin\" \n" +
    "               ng-keydown=\"$ctrl.handleInvalidNumber($event)\"\n" +
    "               class=\"form-control\"\n" +
    "               focus-delay=\"250\"\n" +
    "               custom-popover\n" +
    "               popover-html=\"Value must be greater than 0\"\n" +
    "               popover-placement=\"left\"\n" +
    "               popover-trigger=\"manual\"\n" +
    "               popover-visibility=\"{{ $ctrl.forms[$ctrl.courseFormName].yearTwelveScoresMin.$invalid }}\">\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"col-sm-2 col-md-2 form-subgroup\" ng-class=\"{'has-errors': $ctrl.forms[$ctrl.courseFormName].yearTwelveScoresMax.$invalid}\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Maximum expected</small></label>\n" +
    "        <input type=\"number\" min=\"0\" string-to-number \n" +
    "               ng-model=\"$ctrl.course.entryRequirements.yearTwelveScores.maximumExpected\"\n" +
    "               name=\"yearTwelveScoresMax\"\n" +
    "               ng-keydown=\"$ctrl.handleInvalidNumber($event)\" \n" +
    "               class=\"form-control\"\n" +
    "               focus-delay=\"250\"\n" +
    "               custom-popover\n" +
    "               popover-html=\"Value must be greater than 0\"\n" +
    "               popover-placement=\"left\"\n" +
    "               popover-trigger=\"manual\"\n" +
    "               popover-visibility=\"{{ $ctrl.forms[$ctrl.courseFormName].yearTwelveScoresMax.$invalid }}\">\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"col-sm-5 col-md-5\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Comments</small></label>\n" +
    "        <input type=\"text\" ng-model=\"$ctrl.course.entryRequirements.yearTwelveScores.comment\" class=\"form-control\">\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"form-group visible-xs\"></div>\n" +
    "\n" +
    "<div class=\"form-group\">\n" +
    "    <label class=\"col-xs-12 col-sm-3\">\n" +
    "        <ui-select class=\"ui-grid-filter-select\"\n" +
    "                   ng-required=\"true\"\n" +
    "                   ng-change=\"$ctrl.handleFirstTestTypeChanges()\"\n" +
    "                   ng-model=\"$ctrl.tmp.entryRequirements.firstTestType.type\"\n" +
    "                   theme=\"bootstrap\"\n" +
    "                   search-enabled=\"false\"\n" +
    "                   append-to-body=\"true\">\n" +
    "            <ui-select-match placeholder=\"Select test type\">\n" +
    "                {{ $select.selected.label }}\n" +
    "            </ui-select-match>\n" +
    "            <ui-select-choices\n" +
    "                ui-disable-choice=\"item.value === $ctrl.course.entryRequirements.thirdTestType.type || item.value === $ctrl.course.entryRequirements.secondTestType.type\"\n" +
    "                repeat=\"item in ($ctrl.testTypeList | filter: $select.search) track by item.value\">\n" +
    "                <div ng-bind=\"item.label\"></div>\n" +
    "            </ui-select-choices>\n" +
    "        </ui-select>\n" +
    "    </label>\n" +
    "    <div class=\"col-sm-2 col-md-2 form-subgroup\" ng-class=\"{'has-errors': $ctrl.forms[$ctrl.courseFormName].firstTestMin.$invalid || $ctrl.isValidEntryReq('firstTestType')}\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Minimum required</small></label>\n" +
    "        <input type=\"number\" min=\"{{$ctrl.minFirst}}\" max=\"{{$ctrl.maxFirst}}\" string-to-number \n" +
    "               ng-model=\"$ctrl.course.entryRequirements.firstTestType.value\" \n" +
    "               class=\"form-control\"\n" +
    "               name=\"firstTestMin\"\n" +
    "               ng-keydown=\"$ctrl.handleInvalidNumber($event)\"\n" +
    "               focus-delay=\"250\"\n" +
    "               custom-popover\n" +
    "               popover-html=\"Value must be {{$ctrl.maxFirst? 'between '+$ctrl.minFirst + ' and ' + $ctrl.maxFirst :  'greater than 0'}}\"\n" +
    "               popover-placement=\"left\"\n" +
    "               popover-trigger=\"manual\"\n" +
    "               popover-visibility=\"{{ $ctrl.forms[$ctrl.courseFormName].firstTestMin.$invalid }}\">\n" +
    "        <span custom-popover\n" +
    "              popover-html=\"Minimum required must be less than Maximum expected\"\n" +
    "              popover-placement=\"left\"\n" +
    "              popover-trigger=\"manual\"\n" +
    "              popover-visibility=\"{{ $ctrl.isValidEntryReq('firstTestType') }}\"></span>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"col-sm-2 col-md-2 form-subgroup\" ng-class=\"{'has-errors': $ctrl.forms[$ctrl.courseFormName].firstTestMax.$invalid}\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Maximum expected</small></label>\n" +
    "        <input type=\"number\" min=\"{{$ctrl.minFirst}}\" max=\"{{$ctrl.maxFirst}}\" string-to-number \n" +
    "               ng-model=\"$ctrl.course.entryRequirements.firstTestType.maximumExpected\" \n" +
    "               class=\"form-control\"\n" +
    "               name=\"firstTestMax\"\n" +
    "               ng-keydown=\"$ctrl.handleInvalidNumber($event)\"\n" +
    "               focus-delay=\"250\"\n" +
    "               custom-popover\n" +
    "               popover-html=\"Value must be {{$ctrl.maxFirst? 'between '+$ctrl.minFirst + ' and ' + $ctrl.maxFirst :  'greater than 0'}}\"\n" +
    "               popover-placement=\"left\"\n" +
    "               popover-trigger=\"manual\"\n" +
    "               popover-visibility=\"{{ $ctrl.forms[$ctrl.courseFormName].firstTestMax.$invalid }}\">\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"col-sm-5 col-md-5\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Comments</small></label>\n" +
    "        <input type=\"text\" ng-model=\"$ctrl.course.entryRequirements.firstTestType.comment\" class=\"form-control\">\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"form-group visible-xs\"></div>\n" +
    "\n" +
    "<div class=\"form-group\">\n" +
    "    <label class=\"col-xs-12 col-sm-3\">\n" +
    "        <ui-select class=\"ui-grid-filter-select\"\n" +
    "                   ng-required=\"true\"\n" +
    "                   ng-change=\"$ctrl.handleSecondTestTypeChanges()\"\n" +
    "                   ng-model=\"$ctrl.tmp.entryRequirements.secondTestType.type\"\n" +
    "                   theme=\"bootstrap\"\n" +
    "                   search-enabled=\"false\"\n" +
    "                   append-to-body=\"true\">\n" +
    "            <ui-select-match placeholder=\"Select test type\">\n" +
    "                {{ $select.selected.label }}\n" +
    "            </ui-select-match>\n" +
    "            <ui-select-choices\n" +
    "                ui-disable-choice=\"item.value === $ctrl.course.entryRequirements.thirdTestType.type ||  item.value === $ctrl.course.entryRequirements.firstTestType.type\"\n" +
    "                repeat=\"item in ($ctrl.testTypeList | filter: $select.search) track by item.value\">\n" +
    "                <div ng-bind=\"item.label\"></div>\n" +
    "            </ui-select-choices>\n" +
    "        </ui-select>\n" +
    "    </label>\n" +
    "    <div class=\"col-sm-2 col-md-2 form-subgroup\" ng-class=\"{'has-errors': $ctrl.forms[$ctrl.courseFormName].secondTestMin.$invalid || $ctrl.isValidEntryReq('secondTestType')}\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Minimum required</small></label>\n" +
    "        <input type=\"number\" min=\"{{$ctrl.minSecond}}\" max=\"{{$ctrl.maxSecond}}\" string-to-number \n" +
    "               ng-model=\"$ctrl.course.entryRequirements.secondTestType.value\" \n" +
    "               class=\"form-control\"\n" +
    "               name=\"secondTestMin\"\n" +
    "               ng-keydown=\"$ctrl.handleInvalidNumber($event)\"\n" +
    "               focus-delay=\"250\"\n" +
    "               custom-popover\n" +
    "               popover-html=\"Value must be {{$ctrl.maxSecond? 'between '+$ctrl.minSecond + ' and ' + $ctrl.maxSecond :  'greater than 0'}}\"\n" +
    "               popover-placement=\"left\"\n" +
    "               popover-trigger=\"manual\"\n" +
    "               popover-visibility=\"{{ $ctrl.forms[$ctrl.courseFormName].secondTestMin.$invalid }}\">\n" +
    "        <span custom-popover\n" +
    "              popover-html=\"Minimum required must be less than Maximum expected\"\n" +
    "              popover-placement=\"left\"\n" +
    "              popover-trigger=\"manual\"\n" +
    "              popover-visibility=\"{{ $ctrl.isValidEntryReq('secondTestType') }}\"></span>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"col-sm-2 col-md-2 form-subgroup\" ng-class=\"{'has-errors': $ctrl.forms[$ctrl.courseFormName].secondTestMax.$invalid}\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Maximum expected</small></label>\n" +
    "        <input type=\"number\" min=\"{{$ctrl.minSecond}}\" max=\"{{$ctrl.maxSecond}}\" string-to-number \n" +
    "               ng-model=\"$ctrl.course.entryRequirements.secondTestType.maximumExpected\" \n" +
    "               class=\"form-control\"\n" +
    "               name=\"secondTestMax\"\n" +
    "               ng-keydown=\"$ctrl.handleInvalidNumber($event)\"\n" +
    "               focus-delay=\"250\"\n" +
    "               custom-popover\n" +
    "               popover-html=\"Value must be {{$ctrl.maxSecond? 'between '+$ctrl.minSecond + ' and ' + $ctrl.maxSecond :  'greater than 0'}}\"\n" +
    "               popover-placement=\"left\"\n" +
    "               popover-trigger=\"manual\"\n" +
    "               popover-visibility=\"{{ $ctrl.forms[$ctrl.courseFormName].secondTestMax.$invalid }}\">\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"col-sm-5 col-md-5\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Comments</small></label>\n" +
    "        <input type=\"text\" ng-model=\"$ctrl.course.entryRequirements.secondTestType.comment\" class=\"form-control\">\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"form-group visible-xs\"></div>\n" +
    "\n" +
    "<div class=\"form-group\">\n" +
    "    <label class=\"col-xs-12 col-sm-3\">\n" +
    "        <ui-select class=\"ui-grid-filter-select\"\n" +
    "                   ng-required=\"true\"\n" +
    "                   ng-change=\"$ctrl.handleThirdTestTypeChanges()\"\n" +
    "                   ng-model=\"$ctrl.tmp.entryRequirements.thirdTestType.type\"\n" +
    "                   theme=\"bootstrap\"\n" +
    "                   search-enabled=\"false\"\n" +
    "                   append-to-body=\"true\">\n" +
    "            <ui-select-match placeholder=\"Select test type\">\n" +
    "                {{ $select.selected.label }}\n" +
    "            </ui-select-match>\n" +
    "            <ui-select-choices\n" +
    "                ui-disable-choice=\"item.value === $ctrl.course.entryRequirements.secondTestType.type || item.value === $ctrl.course.entryRequirements.firstTestType.type\"\n" +
    "                repeat=\"item in ($ctrl.testTypeList | filter: $select.search) track by item.value\">\n" +
    "                <div ng-bind=\"item.label\"></div>\n" +
    "            </ui-select-choices>\n" +
    "        </ui-select>\n" +
    "    </label>\n" +
    "    <div class=\"col-sm-2 col-md-2 form-subgroup\" ng-class=\"{'has-errors': $ctrl.forms[$ctrl.courseFormName].thirdTestMin.$invalid || $ctrl.isValidEntryReq('thirdTestType')}\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Minimum required</small></label>\n" +
    "        <input type=\"number\" min=\"{{$ctrl.minThird}}\" max=\"{{$ctrl.maxThird}}\" string-to-number \n" +
    "               ng-model=\"$ctrl.course.entryRequirements.thirdTestType.value\" \n" +
    "               class=\"form-control\"\n" +
    "               name=\"thirdTestMin\"\n" +
    "               ng-keydown=\"$ctrl.handleInvalidNumber($event)\"\n" +
    "               focus-delay=\"250\"\n" +
    "               custom-popover\n" +
    "               popover-html=\"Value must be {{$ctrl.maxThird? 'between '+$ctrl.minThird + ' and ' + $ctrl.maxThird :  'greater than 0'}}\"\n" +
    "               popover-placement=\"left\"\n" +
    "               popover-trigger=\"manual\"\n" +
    "               popover-visibility=\"{{ $ctrl.forms[$ctrl.courseFormName].thirdTestMin.$invalid }}\">\n" +
    "        <span custom-popover\n" +
    "              popover-html=\"Minimum required must be less than Maximum expected\"\n" +
    "              popover-placement=\"left\"\n" +
    "              popover-trigger=\"manual\"\n" +
    "              popover-visibility=\"{{ $ctrl.isValidEntryReq('thirdTestType') }}\"></span>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"col-sm-2 col-md-2 form-subgroup\" ng-class=\"{'has-errors': $ctrl.forms[$ctrl.courseFormName].thirdTestMax.$invalid}\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Maximum expected</small></label>\n" +
    "        <input type=\"number\" min=\"{{$ctrl.minThird}}\" max=\"{{$ctrl.maxThird}}\" string-to-number \n" +
    "               ng-model=\"$ctrl.course.entryRequirements.thirdTestType.maximumExpected\" \n" +
    "               class=\"form-control\"\n" +
    "               name=\"thirdTestMax\"\n" +
    "               ng-keydown=\"$ctrl.handleInvalidNumber($event)\"\n" +
    "               focus-delay=\"250\"\n" +
    "               custom-popover\n" +
    "               popover-html=\"Value must be {{$ctrl.maxThird? 'between '+$ctrl.minThird + ' and ' + $ctrl.maxThird :  'greater than 0'}}\"\n" +
    "               popover-placement=\"left\"\n" +
    "               popover-trigger=\"manual\"\n" +
    "               popover-visibility=\"{{ $ctrl.forms[$ctrl.courseFormName].thirdTestMax.$invalid }}\">\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"col-sm-5 col-md-5\">\n" +
    "        <label class=\"control-label visible-xs\" aria-hidden=\"true\"><small>Comments</small></label>\n" +
    "        <input type=\"text\" ng-model=\"$ctrl.course.entryRequirements.thirdTestType.comment\" class=\"form-control\">\n" +
    "    </div>\n" +
    "</div>\n" +
    "    "
  );


  $templateCache.put('/scripts/modules/profiles/components/courses/courseEdit/templates/lengthTemplate.html',
    "<div class=\"form-horizontal m-t\">\n" +
    "    <div class=\"form-group panel-no-margin-bottom\" ng-class=\"{'has-errors': $ctrl.isInvalidDegreeLength}\">\n" +
    "        <label class=\"col-lg-3 col-sm-5 control-label no-tb-padding\">\n" +
    "            Select one to publish <span class=\"legendLabel publish-field text-nowrap\">*</span>\n" +
    "        </label>\n" +
    "        <div class=\"col-lg-9 col-sm-7\">\n" +
    "          <div class=\"select-oneto-publish\">\n" +
    "              <label class=\"no-tb-padding bold400 panel-no-margin-bottom margin-right-15-px\">\n" +
    "              <input ng-class=\"{'has-errors': $ctrl.isInvalidSelectOneToPublish}\" ng-model=\"$ctrl.course.degreeLength.publish\" name=\"publishNumeric\" value=\"numeric\" i-checkbox type=\"radio\">\n" +
    "              Degree Length</label>\n" +
    "              <label class=\"no-tb-padding bold400 panel-no-margin-bottom\"><input ng-class=\"{'has-errors': $ctrl.isInvalidSelectOneToPublish}\" ng-model=\"$ctrl.course.degreeLength.publish\" name=\"publishNumeric\" value=\"custom\" i-checkbox type=\"radio\">\n" +
    "              Degree Length (free text)</label>\n" +
    "              <span\n" +
    "                      focus-delay=\"250\"\n" +
    "                      custom-popover\n" +
    "                      popover-html=\"This field is mandatory\"\n" +
    "                      popover-placement=\"left\"\n" +
    "                      popover-trigger=\"manual\"\n" +
    "                      popover-visibility=\"{{$ctrl.isInvalidSelectOneToPublish}}\">\n" +
    "              </span>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "  </div>\n" +
    "    <div class=\"hr-line-dashed\"></div>\n" +
    "    <div class=\"form-group\">\n" +
    "        <label class=\"col-lg-3 col-sm-5 control-label\">\n" +
    "            Degree Length <span class=\"legendLabel publish-field text-nowrap\" ng-hide=\"$ctrl.course.degreeLength.publish === 'custom'\">*</span>\n" +
    "        </label>\n" +
    "        <div class=\"col-lg-3 col-sm-3 form-subgroup\" ng-class=\"{'has-errors': $ctrl.isInvalidDegreeLength}\">\n" +
    "            <input type=\"number\" step=\"any\" min=\"0\" string-to-number class=\"form-control\"\n" +
    "                   ng-model=\"$ctrl.course.degreeLength.numeric\"\n" +
    "                   ng-change=\"$ctrl.handleDegreeLengthNumericChanges()\"\n" +
    "                   ng-keydown=\"$ctrl.handleKeyDown($event)\"\n" +
    "                   focus-delay=\"250\"\n" +
    "                   custom-popover\n" +
    "                   popover-html=\"This field is mandatory\"\n" +
    "                   popover-placement=\"left\"\n" +
    "                   popover-trigger=\"manual\"\n" +
    "                   popover-visibility=\"{{ $ctrl.isInvalidDegreeLength }}\"\n" +
    "                   ng-focus=\"$ctrl.isInvalidDegreeLength = false\">\n" +
    "        </div>\n" +
    "        <div class=\"col-lg-3 col-sm-4 form-subgroup\" ng-class=\"{'has-errors': $ctrl.isInvalidDegreeLengthUnit}\">\n" +
    "            <ui-select class=\"ui-grid-filter-select\"\n" +
    "                       ng-change=\"$ctrl.handleDegreeLengthUnitChanges()\"\n" +
    "                       ng-required=\"true\"\n" +
    "                       ng-model=\"$ctrl.tmp.degreeLength.unit\"\n" +
    "                       theme=\"bootstrap\"\n" +
    "                       search-enabled=\"true\"\n" +
    "                       append-to-body=\"true\"\n" +
    "                       focus-delay=\"250\"\n" +
    "                       custom-popover\n" +
    "                       popover-html=\"This field is mandatory\"\n" +
    "                       popover-placement=\"left\"\n" +
    "                       popover-trigger=\"manual\"\n" +
    "                       popover-visibility=\"{{ $ctrl.isInvalidDegreeLengthUnit }}\">\n" +
    "                <ui-select-match>\n" +
    "                    {{ $select.selected.label }}\n" +
    "                </ui-select-match>\n" +
    "                <ui-select-choices repeat=\"item in ($ctrl.degreeLengthList | filter: $select.search) track by item.value\">\n" +
    "                    <div ng-bind=\"item.label\"></div>\n" +
    "                </ui-select-choices>\n" +
    "            </ui-select>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group\" ng-class=\"{'has-errors': $ctrl.isInvalidDegreeCustomLength}\">\n" +
    "        <label class=\"col-lg-3 col-sm-5 control-label\">\n" +
    "            Degree Length <small> (free text)</small><span class=\"legendLabel publish-field text-nowrap\" ng-hide=\"$ctrl.course.degreeLength.publish === 'numeric'\">*</span>\n" +
    "        </label>\n" +
    "        <div class=\"col-lg-9 col-sm-7\">\n" +
    "            <input type=\"text\" class=\"form-control\"\n" +
    "                   ng-model=\"$ctrl.course.degreeLength.custom\"\n" +
    "                   ng-change=\"$ctrl.handleDegreeLengthCustomChanges()\"\n" +
    "                   focus-delay=\"250\"\n" +
    "                   custom-popover\n" +
    "                   popover-html=\"This field is mandatory\"\n" +
    "                   popover-placement=\"left\"\n" +
    "                   popover-trigger=\"manual\"\n" +
    "                   popover-visibility=\"{{ $ctrl.isInvalidDegreeCustomLength }}\"\n" +
    "                   ng-focus=\"$ctrl.isInvalidDegreeCustomLength = false\">\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/profiles/components/courses/courseEdit/templates/mediaTemplate.html',
    "<course-images course=\"$ctrl.course\"></course-images>\n" +
    "<course-brochures course=\"$ctrl.course\"></course-brochures>\n"
  );


  $templateCache.put('/scripts/modules/profiles/components/courses/courseEdit/templates/overviewTemplate.html',
    "<div class=\"form-horizontal m-t\">\n" +
    "    <div class=\"form-group\">\n" +
    "        <label class=\"col-sm-4 col-lg-2 control-label\">\n" +
    "            Course Level<span class=\"legendLabel publish-field text-nowrap\">*</span>\n" +
    "        </label>\n" +
    "        <div class=\"col-sm-8 col-lg-4 form-subgroup\" ng-class=\"{'has-errors': $ctrl.isInvalidCourseLevel}\">\n" +
    "            <ui-select class=\"ui-grid-filter-select\"\n" +
    "                       on-select=\"$ctrl.handleLevelChanges()\"\n" +
    "                       ng-required=\"true\"\n" +
    "                       ng-model=\"$ctrl.tmp.courseLevel\"\n" +
    "                       theme=\"bootstrap\"\n" +
    "                       search-enabled=\"false\"\n" +
    "                       append-to-body=\"true\"\n" +
    "                       focus-delay=\"250\"\n" +
    "                       custom-popover\n" +
    "                       popover-html=\"This field is mandatory\"\n" +
    "                       popover-placement=\"left\"\n" +
    "                       popover-trigger=\"manual\"\n" +
    "                       popover-visibility=\"{{ $ctrl.isInvalidCourseLevel }}\"\n" +
    "                       ng-change=\"$ctrl.isInvalidCourseLevel = false\">\n" +
    "                <ui-select-match>\n" +
    "                    {{ $select.selected.label }}\n" +
    "                </ui-select-match>\n" +
    "                <ui-select-choices repeat=\"item in ($ctrl.levelsList | filter: $select.search) track by item.value\">\n" +
    "                    <div ng-bind=\"item.label\"></div>\n" +
    "                </ui-select-choices>\n" +
    "            </ui-select>\n" +
    "        </div>\n" +
    "\n" +
    "        <label class=\"col-sm-4 col-lg-2 control-label\">\n" +
    "            Program Type<span class=\"legendLabel publish-field text-nowrap\">*</span>\n" +
    "        </label>\n" +
    "        <div class=\"col-sm-8 col-lg-4 form-subgroup\" ng-class=\"{'has-errors': $ctrl.isInvalidType}\">\n" +
    "            <ui-select class=\"ui-grid-filter-select\"\n" +
    "                       ng-required=\"true\"\n" +
    "                       on-select=\"$ctrl.handleTypeChanges()\"\n" +
    "                       ng-model=\"$ctrl.tmp.type\"\n" +
    "                       theme=\"bootstrap\"\n" +
    "                       search-enabled=\"false\"\n" +
    "                       append-to-body=\"true\"\n" +
    "                       focus-delay=\"250\"\n" +
    "                       custom-popover\n" +
    "                       popover-html=\"This field is mandatory\"\n" +
    "                       popover-placement=\"left\"\n" +
    "                       popover-trigger=\"manual\"\n" +
    "                       popover-visibility=\"{{ $ctrl.isInvalidType }}\"\n" +
    "                       ng-change=\"$ctrl.isInvalidType = false\">\n" +
    "                <ui-select-match>\n" +
    "                    {{ $select.selected.label }}\n" +
    "                </ui-select-match>\n" +
    "                <ui-select-choices repeat=\"item in ($ctrl.typesList | filter: $select.search) track by item.value\">\n" +
    "                    <div ng-bind=\"item.label\"></div>\n" +
    "                </ui-select-choices>\n" +
    "            </ui-select>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "        <label class=\"col-sm-4 col-lg-2 control-label\">\n" +
    "            Degree<span class=\"legendLabel publish-field text-nowrap\">*</span>\n" +
    "        </label>\n" +
    "        <div class=\"col-sm-8 col-lg-4 form-subgroup\" ng-class=\"{'has-errors': $ctrl.isInvalidDegree}\">\n" +
    "            <ui-select class=\"ui-grid-filter-select\"\n" +
    "                       on-select=\"$ctrl.handleDegreeChanges()\"\n" +
    "                       ng-required=\"true\"\n" +
    "                       ng-model=\"$ctrl.tmp.degree\"\n" +
    "                       theme=\"bootstrap\"\n" +
    "                       search-enabled=\"false\"\n" +
    "                       append-to-body=\"false\"\n" +
    "                       focus-delay=\"250\"\n" +
    "                       custom-popover\n" +
    "                       popover-html=\"This field is mandatory\"\n" +
    "                       popover-placement=\"left\"\n" +
    "                       popover-trigger=\"manual\"\n" +
    "                       popover-visibility=\"{{ $ctrl.isInvalidDegree }}\"\n" +
    "                       ng-change=\"$ctrl.isInvalidDegree = false\">\n" +
    "                <ui-select-match>\n" +
    "                    {{ $select.selected.label }}\n" +
    "                </ui-select-match>\n" +
    "                <ui-select-choices\n" +
    "                    repeat=\"item in ($ctrl.degreesList | filter: $select.search) track by item.value\"\n" +
    "                    ui-disable-choice=\"item.disabled\">\n" +
    "                    <div ng-bind=\"item.label\"></div>\n" +
    "                </ui-select-choices>\n" +
    "            </ui-select>\n" +
    "        </div>\n" +
    "\n" +
    "        <label class=\"col-sm-4 col-lg-2 control-label\">\n" +
    "            Degree Sub-type\n" +
    "        </label>\n" +
    "        <div class=\"col-sm-8 col-lg-4\">\n" +
    "            <ui-select class=\"ui-grid-filter-select\"\n" +
    "                       on-select=\"$ctrl.handleDegreeSubtypeChanges()\"\n" +
    "                       ng-model=\"$ctrl.tmp.degreeSubtype\"\n" +
    "                       theme=\"bootstrap\"\n" +
    "                       search-enabled=\"true\"\n" +
    "                       append-to-body=\"false\"\n" +
    "                       close-on-select=\"true\">\n" +
    "                <ui-select-match allow-clear=\"true\">\n" +
    "                    {{ $select.selected.label }}\n" +
    "                </ui-select-match>\n" +
    "                <ui-select-choices\n" +
    "                    repeat=\"item in ($ctrl.degreeSubtypeList | filter: $select.search) track by item.value\"\n" +
    "                    ui-disable-choice=\"item.disabled\">\n" +
    "                    <div ng-bind=\"item.label\"></div>\n" +
    "                </ui-select-choices>\n" +
    "            </ui-select>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "        <label class=\"col-sm-4 col-lg-2 control-label\">\n" +
    "            Main Subject<span class=\"legendLabel publish-field text-nowrap\">*</span>\n" +
    "        </label>\n" +
    "        <div class=\"col-sm-8 col-lg-4 form-subgroup\" ng-class=\"{'has-errors': $ctrl.isInvalidSubject}\">\n" +
    "            <ui-select class=\"ui-grid-filter-select\"\n" +
    "                       on-select=\"$ctrl.handleSubjectChanges()\"\n" +
    "                       ng-required=\"true\"\n" +
    "                       ng-model=\"$ctrl.tmp.subject\"\n" +
    "                       theme=\"bootstrap\"\n" +
    "                       search-enabled=\"true\"\n" +
    "                       append-to-body=\"false\"\n" +
    "\n" +
    "                       focus-delay=\"250\"\n" +
    "                       custom-popover\n" +
    "                       popover-html=\"This field is mandatory\"\n" +
    "                       popover-placement=\"left\"\n" +
    "                       popover-trigger=\"manual\"\n" +
    "                       popover-visibility=\"{{ $ctrl.isInvalidSubject }}\"\n" +
    "                       ng-change=\"$ctrl.isInvalidSubject = false\">\n" +
    "                <ui-select-match>\n" +
    "                    {{ $select.selected.label }}\n" +
    "                </ui-select-match>\n" +
    "                <ui-select-choices\n" +
    "                    group-by=\"'groupName'\"\n" +
    "                    repeat=\"item in ($ctrl.subjectsList | filter: $select.search) track by item.value\"\n" +
    "                    ui-disable-choice=\"item.disabled\">\n" +
    "                    <div ng-bind=\"item.label\"></div>\n" +
    "                </ui-select-choices>\n" +
    "            </ui-select>\n" +
    "        </div>\n" +
    "\n" +
    "        <label class=\"col-sm-4 col-lg-2 control-label\">\n" +
    "            Custom Subjects\n" +
    "        </label>\n" +
    "        <div class=\"col-sm-8 col-lg-4\">\n" +
    "            <ui-select class=\"ui-grid-filter-select\"\n" +
    "                       on-remove=\"$ctrl.handleCustomSubjectRemoved($item)\"\n" +
    "                       on-select=\"$ctrl.handleCustomSubjectsChanges()\"\n" +
    "                       ng-class=\"{'disable-search': $ctrl.tmp.customSubjects.length === 4}\"\n" +
    "                       multiple\n" +
    "                       limit=\"4\"\n" +
    "                       close-on-select=\"true\"\n" +
    "                       reset-search-input=\"true\"\n" +
    "                       ng-model=\"$ctrl.tmp.customSubjects\"\n" +
    "                       theme=\"bootstrap\"\n" +
    "                       append-to-body=\"false\">\n" +
    "                <ui-select-match>\n" +
    "                    {{ $item.label }}\n" +
    "                </ui-select-match>\n" +
    "                <ui-select-choices\n" +
    "                    repeat=\"item in ($ctrl.customSubjectsList | filter: $select.search) track by item.value\"\n" +
    "                    ui-disable-choice=\"item.disabled\">\n" +
    "                    <div ng-bind=\"item.label\"></div>\n" +
    "                </ui-select-choices>\n" +
    "            </ui-select>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/profiles/components/courses/courseEdit/templates/seoTemplate.html',
    "<div class=\"form-horizontal m-t\" ng-class=\"{'modal-overlay-35': $ctrl.fetchInProgress}\">\n" +
    "    <div class=\"row\">\n" +
    "        <div class=\"col-sm-12\">\n" +
    "            <fetch-meta-tags course=\"$ctrl.course\" fetch-in-progress=\"$ctrl.fetchInProgress\"></fetch-meta-tags>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "        <label class=\"col-sm-3 col-lg-2 control-label\">\n" +
    "            Meta Title\n" +
    "        </label>\n" +
    "        <div class=\"col-sm-9 col-lg-10\">\n" +
    "            <input type=\"text\" ng-model=\"$ctrl.course.seo.title\" class=\"form-control\">\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "        <label class=\"col-sm-3 col-lg-2 control-label\">\n" +
    "            Meta Keywords\n" +
    "        </label>\n" +
    "        <div class=\"col-sm-9 col-lg-10\">\n" +
    "            <summernote\n" +
    "                ng-model=\"$ctrl.course.seo.keywords\"\n" +
    "                config=\"$ctrl.textEditorOptions\"\n" +
    "                height=\"150\"\n" +
    "                on-paste=\"$ctrl.handleClipboardPaste('seo.keywords')\">\n" +
    "            </summernote>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "        <label class=\"col-sm-3 col-lg-2 control-label\">\n" +
    "            Meta Description\n" +
    "        </label>\n" +
    "        <div class=\"col-sm-9 col-lg-10\">\n" +
    "            <summernote\n" +
    "                ng-model=\"$ctrl.course.seo.description\"\n" +
    "                config=\"$ctrl.textEditorOptions\"\n" +
    "                height=\"150\"\n" +
    "                on-paste=\"$ctrl.handleClipboardPaste('seo.description')\">\n" +
    "            </summernote>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/profiles/components/courses/courseEdit/templates/tuitionFeesTemplate.html',
    "<div class=\"form-horizontal m-t\">\n" +
    "    <div class=\"form-group\">\n" +
    "        <div class=\"form-subgroup\" ng-class=\"{'has-errors': $ctrl.isInvalidTuitionFeesCurrency}\">\n" +
    "            <label class=\"col-sm-5 control-label\">\n" +
    "                Currency<span class=\"legendLabel\">*</span>\n" +
    "                <i class=\"fa fa-info-circle\"\n" +
    "                uib-tooltip=\"All currencies will automatically be converted to USD for use on the Matching Tool\"\n" +
    "                tooltip-class=\"break-word\"\n" +
    "                tooltip-placement=\"top\"></i>\n" +
    "            </label>\n" +
    "            <div class=\"col-sm-7 col-md-4\">\n" +
    "                <ui-select class=\"ui-grid-filter-select\"\n" +
    "                           ng-required=\"true\"\n" +
    "                           on-select=\"$ctrl.handleCurrencyChanges()\"\n" +
    "                           ng-model=\"$ctrl.tmp.tuitionFees.currency\"\n" +
    "                           theme=\"bootstrap\"\n" +
    "                           search-enabled=\"false\"\n" +
    "                           append-to-body=\"true\"\n" +
    "                           focus-delay=\"250\"\n" +
    "                           custom-popover\n" +
    "                           popover-html=\"This field is mandatory\"\n" +
    "                           popover-placement=\"left\"\n" +
    "                           popover-trigger=\"manual\"\n" +
    "                           popover-visibility=\"{{ $ctrl.isInvalidTuitionFeesCurrency }}\"\n" +
    "                           ng-change=\"$ctrl.isInvalidTuitionFeesCurrency = false\">\n" +
    "                    <ui-select-match>\n" +
    "                        {{ $select.selected.label }}\n" +
    "                    </ui-select-match>\n" +
    "                    <ui-select-choices repeat=\"item in ($ctrl.currencyList | filter: $select.search) track by item.value\">\n" +
    "                        <div ng-bind=\"item.label\"></div>\n" +
    "                    </ui-select-choices>\n" +
    "                </ui-select>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "        <div class=\"form-subgroup\" ng-class=\"{'has-errors': $ctrl.isInvalidTuitionFeesInternationalStudents && $ctrl.isInvalidTuitionFeesDomesticStudents}\">\n" +
    "            <label class=\"col-sm-5 control-label publish-field align-lbl\">\n" +
    "                Total Program Cost International Students \n" +
    "                <span class=\"legendLabel text-nowrap\" \n" +
    "                      ng-show=\"!$ctrl.course.tuitionFees.domesticStudents || \n" +
    "                               ($ctrl.course.tuitionFees.internationalStudents && $ctrl.course.tuitionFees.domesticStudents)\">*</span>\n" +
    "               <i class=\"fa fa-info-circle\"\n" +
    "               uib-tooltip=\"Used for calculations on the Matching Tool\"\n" +
    "               tooltip-class=\"break-word\"\n" +
    "               tooltip-placement=\"top\"></i>\n" +
    "            </label>\n" +
    "\n" +
    "            <div class=\"col-sm-7 col-md-4\">\n" +
    "                <input type=\"number\" step=\"any\" min=\"0\" string-to-number class=\"form-control\"\n" +
    "                       ng-model=\"$ctrl.course.tuitionFees.internationalStudents\"\n" +
    "                       ng-keydown=\"$ctrl.handleKeyDown($event)\"\n" +
    "                       focus-delay=\"250\"\n" +
    "                       custom-popover\n" +
    "                       popover-html=\"This field is mandatory\"\n" +
    "                       popover-placement=\"left\"\n" +
    "                       popover-trigger=\"manual\"\n" +
    "                       popover-visibility=\"{{ $ctrl.isInvalidTuitionFeesInternationalStudents && $ctrl.isInvalidTuitionFeesDomesticStudents}}\"\n" +
    "                       ng-focus=\"$ctrl.isInvalidTuitionFeesInternationalStudents = false\">\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "        <div class=\"form-subgroup\" ng-class=\"{'has-errors': $ctrl.isInvalidTuitionFeesDomesticStudents && $ctrl.isInvalidTuitionFeesInternationalStudents}\">\n" +
    "            <label class=\"col-sm-5 control-label align-lbl\">\n" +
    "                Total Program Cost Domestic Students \n" +
    "                <span class=\"legendLabel\" \n" +
    "                      ng-show=\"!$ctrl.course.tuitionFees.internationalStudents || \n" +
    "                               (!$ctrl.course.tuitionFees.internationalStudents && $ctrl.course.tuitionFees.domesticStudents)\">*</span>\n" +
    "            </label>\n" +
    "            <div class=\"col-sm-7 col-md-4\">\n" +
    "                <input type=\"number\" step=\"any\" min=\"0\" string-to-number class=\"form-control\"\n" +
    "                       ng-model=\"$ctrl.course.tuitionFees.domesticStudents\"\n" +
    "                       ng-keydown=\"$ctrl.handleKeyDown($event)\"\n" +
    "                       focus-delay=\"250\"\n" +
    "                       custom-popover\n" +
    "                       popover-html=\"This field is mandatory\"\n" +
    "                       popover-placement=\"left\"\n" +
    "                       popover-trigger=\"manual\"\n" +
    "                       popover-visibility=\"{{ $ctrl.isInvalidTuitionFeesDomesticStudents && $ctrl.isInvalidTuitionFeesInternationalStudents }}\"\n" +
    "                       ng-focus=\"$ctrl.isInvalidTuitionFeesDomesticStudents = false\">\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "        <label class=\"col-sm-5 control-label publish-field\">\n" +
    "            <span>Price information </span>\n" +
    "            <i class=\"fa fa-info-circle\"\n" +
    "            uib-tooltip=\"Free text display on the programs' page\"\n" +
    "            tooltip-class=\"break-word\"\n" +
    "            tooltip-placement=\"top\"></i>\n" +
    "        </label>\n" +
    "        <div class=\"col-sm-7 col-md-4\">\n" +
    "            <input type=\"text\" ng-model=\"$ctrl.course.tuitionFees.priceInformation\" class=\"form-control\">\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "        <label class=\"col-sm-5 control-label publish-field\">\n" +
    "            Scholarships\n" +
    "        </label>\n" +
    "        <div class=\"col-sm-7\">\n" +
    "            <summernote\n" +
    "                ng-model=\"$ctrl.course.scholarships\"\n" +
    "                config=\"$ctrl.textEditorOptions\"\n" +
    "                height=\"150\"\n" +
    "                on-paste=\"$ctrl.handleClipboardPaste('scholarships')\">\n" +
    "            </summernote>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/profiles/components/courses/courseImages/courseImagesView.html',
    "<h3 class=\"heading\">Images ({{ $ctrl.items.length }} / 4)</h3>\n" +
    "\n" +
    "<div class=\"alert alert-info\">\n" +
    "    <p>\n" +
    "        <i class=\"fa fa-info-circle\"></i>\n" +
    "        <span>File must be: Less than 400KB / in jpg, jpeg format.</span>\n" +
    "    </p>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"form-horizontal m-t media\" ng-class=\"{'modal-overlay-35': $ctrl.uploadInProgress}\">\n" +
    "    <div class=\"grid-view upload unselected\" ng-class=\"{'modal-overlay-35': $ctrl.isUploadDisabled()}\">\n" +
    "        <div name=\"forms.imageForm\"\n" +
    "              class=\"clearfix dropzone image-form clickable\"\n" +
    "              enctype=\"multipart/form-data\"\n" +
    "              ng-dropzone dropzone=\"$ctrl.dropZoneInstance\"\n" +
    "              dropzone-config=\"$ctrl.config.dropzone\"\n" +
    "              event-handlers=\"$ctrl.config.eventHandlers\"\n" +
    "              novalidate >\n" +
    "            <div class=\"upload-image\">\n" +
    "                <i class=\"fa fa-upload\" aria-hidden=\"true\"></i>\n" +
    "            </div>\n" +
    "            <div class=\"fallback\">\n" +
    "                <input name=\"file\" type=\"file\" />\n" +
    "            </div>\n" +
    "            <span class=\"dz-message hidden\"></span>\n" +
    "            <span class=\"dropzone-previews hidden\"></span>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"grid-view unselected\" ng-repeat=\"item in $ctrl.items\">\n" +
    "        <div class=\"preview\">\n" +
    "            <img ng-src=\"{{ item.thumbnail || (item.url || '/images/media/media-image.png') }}\" onerror=\"this.src='/images/not-found.svg';\" />\n" +
    "        </div>\n" +
    "        <div class=\"info\">\n" +
    "            <div class=\"actions\">\n" +
    "                <a href=\"{{item.url}}\" target=\"_blank\" title=\"View image\">\n" +
    "                    <i class=\"fa fa-search\"></i>\n" +
    "                </a>\n" +
    "                <a ng-click=\"$ctrl.handleDelete($index)\" title=\"Delete image\">\n" +
    "                    <i class=\"fa  fa-trash-o\"></i>\n" +
    "                </a>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/profiles/components/courses/coursesOverview/coursesOverviewView.html',
    "<div class=\"wrapper wrapper-content animated fadeInRight page-courses-overview\"\n" +
    "     ng-controller=\"CoursesOverviewController as CoursesOverviewController\">\n" +
    "    <div class=\"row\">\n" +
    "        <div ng-class=\"CoursesOverviewController.isRightSidePanelActive() ? 'col-sm-8' : 'col-sm-12'\">\n" +
    "            <div class=\"section\">\n" +
    "                <div class=\"section-body\">\n" +
    "                    <div id=\"coursesTable\">\n" +
    "                        <div wave-spinner class=\"wave-spinner\" ng-show=\"CoursesOverviewController.isLoading()\"></div>\n" +
    "\n" +
    "                        <div ng-class=\"{'modal-overlay-35': CoursesOverviewController.isLoading()}\">\n" +
    "                            <ui-grid-info></ui-grid-info>\n" +
    "                            <div class=\"grid\"\n" +
    "                                 ui-grid=\"CoursesOverviewController.gridOptions\"\n" +
    "                                 ui-grid-selection\n" +
    "                                 ui-grid-resize-columns\n" +
    "                                 ui-grid-auto-resize\n" +
    "                                 ui-grid-pagination>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"course-view col-sm-4 right-side-container\"\n" +
    "             fixed-element-while-scrolling=\"#messages-data-center\"\n" +
    "             ng-if=\"CoursesOverviewController.isRightSidePanelActive()\">\n" +
    "            <div class=\"ibox\">\n" +
    "                <div class=\"ibox-title\">\n" +
    "                    <h5>Add Course</h5>\n" +
    "                    <div class=\"ibox-tools ng-scope\">\n" +
    "                        <a class=\"close-link\" ng-click=\"CoursesOverviewController.closeRightSidePanel()\">\n" +
    "                            <i class=\"fa fa-times\"></i>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"ibox-content\">\n" +
    "\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/profiles/components/courses/courseUnpublish/courseUnpublishConfirmationView.html',
    "<div class=\"modal-body\">\n" +
    "    <p>Are you sure you want to unpublish the program?</p>\n" +
    "</div>\n" +
    "<div class=\"modal-footer\">\n" +
    "    <button ng-click=\"modalOptions.handleNoClick()\" type=\"button\" class=\"btn btn-default\" >\n" +
    "        No\n" +
    "    </button>\n" +
    "    <button class=\"btn btn-primary\" ng-click=\"modalOptions.handleYesClick();\">\n" +
    "        Yes\n" +
    "    </button>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/profiles/components/courses/courseUnpublish/courseUnpublishView.html',
    "<button class=\"btn btn-default btn-xs m-b-xs\"\n" +
    "        ng-if=\"$ctrl.isUnpublishAllowed()\"\n" +
    "        ng-disabled=\"$ctrl.unpublishInProgress\"\n" +
    "        ng-click=\"$ctrl.handleUnpublishClick()\">\n" +
    "    <i class=\"fa fa-cloud-upload\"></i>\n" +
    "    <span>Unpublish</span>\n" +
    "</button>"
  );


  $templateCache.put('/scripts/modules/profiles/components/courses/fetchMetaTags/fetchMetaTagsView.html',
    "<button class=\"btn btn-default btn-xs m-b-xs pull-right\"\n" +
    "        ng-class=\"{'disabled': $ctrl.isFetchMetaTagsDisabled()}\"\n" +
    "        ng-click=\"$ctrl.handleFetchMetaTags()\"\n" +
    "        uib-popover=\"Program URL is invalid\"\n" +
    "        popover-trigger=\"'mouseenter'\"\n" +
    "        popover-placement=\"top\"\n" +
    "        popover-enable=\"!$ctrl.isValidUrl()\">\n" +
    "    <i class=\"fa fa-cloud-download\"></i>\n" +
    "    <span>Fetch meta tags</span>\n" +
    "</button>\n"
  );


  $templateCache.put('/scripts/modules/profiles/components/entryRequirements/entryRequirements/entryRequirementsView.html',
    "<div class=\"panel-body\" ng-class=\"{ 'modal-overlay-35': $ctrl.fetchInProgress }\">\n" +
    "    <div wave-spinner class=\"absolute-wave-spinner\" ng-show=\"$ctrl.fetchInProgress\"></div>\n" +
    "\n" +
    "    <div class=\"tabs-container\">\n" +
    "        <uib-tabset>\n" +
    "            <uib-tab heading=\"Undergraduate\">\n" +
    "                <div class=\"panel-body\">\n" +
    "                    <entry-requirements-form type=\"'ug'\" institution-id=\"$ctrl.institutionId\" data=\"$ctrl.ugData\"></entry-requirements-form>\n" +
    "                </div>\n" +
    "            </uib-tab>\n" +
    "            <uib-tab heading=\"Postgraduate\">\n" +
    "                <div class=\"panel-body\">\n" +
    "                    <entry-requirements-form type=\"'pg'\" institution-id=\"$ctrl.institutionId\" data=\"$ctrl.pgData\"></entry-requirements-form>\n" +
    "                </div>\n" +
    "            </uib-tab>\n" +
    "        </uib-tabset>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/profiles/components/entryRequirements/entryRequirementsForm/entryRequirementsFormView.html',
    "<form class=\"form-horizontal m-t\" name=\"$ctrl.form\" novalidate>\n" +
    "    <div class=\"form-group hidden-xs\">\n" +
    "        <label class=\"col-sm-2 col-md-2 control-label col-md-offset-1\">\n" +
    "            Minimum required\n" +
    "        </label>\n" +
    "        <label class=\"col-sm-2 col-md-2 control-label\">\n" +
    "            Maximum expected\n" +
    "        </label>\n" +
    "        <label class=\"col-sm-5 col-md-5 control-label\">\n" +
    "            Comments\n" +
    "        </label>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group\" ng-repeat=\"item in $ctrl.questions\">\n" +
    "        <label class=\"col-sm-1 control-label\">\n" +
    "            {{ item.name }}\n" +
    "        </label>\n" +
    "        <div class=\"col-sm-2 col-md-2 form-subgroup\"\n" +
    "             ng-class=\"{'has-errors': $ctrl.showError(item.handle + '_minRequired')}\">\n" +
    "            <input type=\"number\" class=\"form-control\" name=\"{{ item.handle }}_minRequired\"\n" +
    "                   ng-min=\"item.min\"\n" +
    "                   ng-max=\"item.max\"\n" +
    "                   ng-model=\"$ctrl.data[item.handle].minRequired\"\n" +
    "                   ng-keydown=\"$ctrl.handleKeyDown($event)\"\n" +
    "                   ng-focus=\"$ctrl.handleFocus()\"\n" +
    "\n" +
    "                   focus-delay=\"250\"\n" +
    "                   custom-popover\n" +
    "                   popover-html=\"{{ $ctrl.getValidationError(item) }}\"\n" +
    "                   popover-placement=\"left\"\n" +
    "                   popover-trigger=\"manual\"\n" +
    "                   popover-visibility=\"{{ $ctrl.showError(item.handle + '_minRequired') }}\">\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"col-sm-2 col-md-2 form-subgroup\"\n" +
    "             ng-class=\"{'has-errors': $ctrl.showError(item.handle + '_maxRequired')}\">\n" +
    "            <input type=\"number\" class=\"form-control\" name=\"{{ item.handle }}_maxRequired\"\n" +
    "                   ng-min=\"item.min\"\n" +
    "                   ng-max=\"item.max\"\n" +
    "                   ng-model=\"$ctrl.data[item.handle].maxRequired\"\n" +
    "                   ng-focus=\"$ctrl.handleFocus()\"\n" +
    "\n" +
    "                   focus-delay=\"250\"\n" +
    "                   custom-popover\n" +
    "                   popover-html=\"{{ $ctrl.getValidationError(item) }}\"\n" +
    "                   popover-placement=\"left\"\n" +
    "                   popover-trigger=\"manual\"\n" +
    "                   popover-visibility=\"{{ $ctrl.showError(item.handle + '_maxRequired') }}\">\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"col-sm-7 col-md-7 form-subgroup\"\n" +
    "            ng-class=\"{'has-errors': $ctrl.showCommentError(item.handle + '_comment')}\">\n" +
    "            <input type=\"text\"\n" +
    "                name=\"{{item.handle}}_comment\"\n" +
    "                ng-model=\"$ctrl.data[item.handle].comment\"\n" +
    "                ng-max=\"300\"\n" +
    "                class=\"form-control\"\n" +
    "                focus-delay=\"250\"\n" +
    "                custom-popover\n" +
    "                popover-html=\"Comment has exceeded the limit of 255 characters. Please reduce the number of characters\"\n" +
    "                popover-placement=\"left\"\n" +
    "                popover-trigger=\"manual\"\n" +
    "                popover-visibility=\"{{ $ctrl.showCommentError(item.handle + '_comment') }}\"\n" +
    "                ng-focus=\"$ctrl.handleFocus()\">\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"tab-footer\">\n" +
    "        <butto class=\"btn btn-primary pull-right\"\n" +
    "               ng-disabled=\"$ctrl.submitDisabled()\"\n" +
    "               ng-click=\"$ctrl.handleSubmit()\">\n" +
    "            <i class=\"fa fa-check-circle\"></i>\n" +
    "            <span>Update</span>\n" +
    "        </butto>\n" +
    "    </div>\n" +
    "</form>\n"
  );


  $templateCache.put('/scripts/modules/profiles/components/profileUpgradeForm/profileUpgradeFormView.html',
    "<div class=\"ibox-title\">\n" +
    "    <h5>Please Confirm Contact Details For Price Quote</h5>\n" +
    "    <div class=\"ibox-tools\">\n" +
    "        <a class=\"close-link\" ng-click=\"$ctrl.toggleUpgradeForm()\">\n" +
    "            <i class=\"fa fa-times\"></i>\n" +
    "        </a>\n" +
    "    </div>\n" +
    "</div>\n" +
    "<div class=\"ibox\">\n" +
    "    <div class=\"ibox-content\">\n" +
    "        <script>\n" +
    "            (function(w, d, s) {\n" +
    "                var f = d.getElementsByTagName(s)[0],\n" +
    "                    j = d.createElement(s),\n" +
    "                    controllerElement = d.getElementById('profile-page'),\n" +
    "                    isTmProfile = controllerElement.classList.contains('page-profiles-tm')\n" +
    "                    mktoForm = d.getElementById('mktoForm_1547'),\n" +
    "                    formId = 1547;\n" +
    "                    if (isTmProfile) {\n" +
    "                        formId = 1647;\n" +
    "                        mktoForm.id = 'mktoForm_'+formId;\n" +
    "                    }\n" +
    "\n" +
    "                j.async = true;\n" +
    "                j.src = '//app-lon03.marketo.com/js/forms2/js/forms2.min.js';\n" +
    "                j.onload = function () {\n" +
    "                    MktoForms2.loadForm(\"//app-lon03.marketo.com\", \"335-VIN-535\", formId, function () {\n" +
    "                        var companyInput = d.getElementById('Company'),\n" +
    "                            emailInput = d.getElementById('Email'),\n" +
    "                            firstNameInput = d.getElementById('FirstName'),\n" +
    "                            lastNameInput = d.getElementById('LastName'),\n" +
    "                            titleInput = d.getElementById('Title'),\n" +
    "                            phoneInput = d.getElementById('Phone');\n" +
    "                            \n" +
    "                        if (controllerElement) {\n" +
    "                            var scope = w.angular.element(controllerElement).scope();\n" +
    "                            if (companyInput && scope && scope.institutionData.name && scope.institutionData.name) {\n" +
    "                                companyInput.value = scope.institutionData.name;\n" +
    "                            }\n" +
    "                            if (scope && scope.userData) {\n" +
    "                                if (emailInput && scope.userData.email) {\n" +
    "                                    emailInput.value = scope.userData.email;\n" +
    "                                }\n" +
    "                                if (firstNameInput && scope.userData.firstName) {\n" +
    "                                    firstNameInput.value = scope.userData.firstName;\n" +
    "                                }\n" +
    "                                if (lastNameInput && scope.userData.lastName) {\n" +
    "                                    lastNameInput.value = scope.userData.lastName;\n" +
    "                                }\n" +
    "                                if (titleInput && scope.userData.position) {\n" +
    "                                    titleInput.value = scope.userData.position;\n" +
    "                                }\n" +
    "                                if (phoneInput && scope.userData.phone) {\n" +
    "                                    phoneInput.value = scope.userData.phone;\n" +
    "                                }\n" +
    "                            }\n" +
    "                        }\n" +
    "                    });\n" +
    "                };\n" +
    "                f.parentNode.insertBefore(j, f);\n" +
    "            })(window, document, 'script');\n" +
    "        </script>\n" +
    "        <form id=\"mktoForm_1547\"></form>\n" +
    "        <p class=\"text-muted p-xs\">By submitting this form you agree to receive an email from us with your quote. You can unsubscribe at anytime by clicking on the link in the email.</p>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/profiles/components/publishField/publishFieldView.html',
    "<small class=\"text-warning\" ng-if=\"isQs\">\n" +
    "    <i class=\"fa fa-plus clickable\"\n" +
    "       aria-hidden=\"true\"\n" +
    "       uib-tooltip=\"This field is published to the Matching Tool\"\n" +
    "       tooltip-class=\"break-word\"\n" +
    "       tooltip-placement=\"top\"></i>\n" +
    "</small>\n"
  );


  $templateCache.put('/scripts/modules/profiles/components/tu-programs-url-checker/tuProgramsUrlCheckerView.html',
    "<div class=\"wrapper wrapper-content animated fadeInRight page-institution\">\n" +
    "    <div class=\"row\">\n" +
    "        <div ng-class=\"col-sm-12\">\n" +
    "            <div class=\"section\">\n" +
    "                <div class=\"row\">\n" +
    "                    <div class=\"section-header\" ng-class=\"{'modal-overlay': TuProgramsUrlCheckerController.isDisabled()}\">\n" +
    "                        <button class=\"btn btn-primary\" type=\"button\"\n" +
    "                                ng-click=\"TuProgramsUrlCheckerController.handleClick()\">\n" +
    "                            <i class=\"fa fa-link\"></i>\n" +
    "                            <span>Start Process</span>\n" +
    "                        </button>\n" +
    "                        <switch class=\"green\"\n" +
    "                            ng-model=\"TuProgramsUrlCheckerController.includeAdvancedProfiles\"></switch>\n" +
    "                            Only Advanced Profiles\n" +
    "                    </div>\n" +
    "\n" +
    "                    <div class=\"section-body\">\n" +
    "                        <div ng-if=\"TuProgramsUrlCheckerController.error\" class=\"alert alert-danger\">\n" +
    "                            {{ TuProgramsUrlCheckerController.error }}\n" +
    "                        </div>\n" +
    "                        <div ng-if=\"TuProgramsUrlCheckerController.info &&\n" +
    "                        !TuProgramsUrlCheckerController.error &&\n" +
    "                        TuProgramsUrlCheckerController.info.load\" class=\"alert alert-info\">\n" +
    "                            <p ng-repeat=\"message in TuProgramsUrlCheckerController.info\">\n" +
    "                                {{ message }}\n" +
    "                            </p>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"row\">\n" +
    "                    <div class=\"panel panel-default\" ng-show=\"TuProgramsUrlCheckerController.showFailures()\">\n" +
    "                        <div class=\"panel-heading\">\n" +
    "                            TU programs with invalid URLs - <strong>{{ TuProgramsUrlCheckerController.failedPrograms.length }}</strong>\n" +
    "                            <button class=\"btn btn-xs btn-default pull-right\" type=\"button\"\n" +
    "                                    ng-disabled=\"TuProgramsUrlCheckerController.processedRecords() !== TuProgramsUrlCheckerController.total\"\n" +
    "                                    ng-click=\"TuProgramsUrlCheckerController.download()\">\n" +
    "                                <i class=\"fa fa-download\"></i>\n" +
    "                                <span>Download</span>\n" +
    "                            </button>\n" +
    "                        </div>\n" +
    "                        <div class=\"panel-body pre-scrollable\">\n" +
    "                            <ul>\n" +
    "                                <li ng-repeat=\"item in TuProgramsUrlCheckerController.failedPrograms track by $index\">\n" +
    "                                    <span class=\"text-muted\">{{ item.institutionCoreId }}</span> -\n" +
    "                                    <span class=\"text-navy\">{{ item.name }}</span>\n" +
    "                                    <a target=\"_blank\" href=\"{{item.url}}\">{{ item.url }}</a>\n" +
    "                                    <span class=\"text-muted\">{{ item.code }}</span>\n" +
    "                                </li>\n" +
    "                            </ul>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"row\">\n" +
    "                    <div class=\"panel panel-default\">\n" +
    "                        <div class=\"panel-heading\">\n" +
    "                            Logs\n" +
    "                        </div>\n" +
    "                        <div class=\"panel-body pre-scrollable\">\n" +
    "                            <div ng-if=\"TuProgramsUrlCheckerController.logs.length\">\n" +
    "                                <ul>\n" +
    "                                    <li ng-repeat=\"item in TuProgramsUrlCheckerController.logs track by $index\">\n" +
    "                                        <small class=\"time\">{{ item.createdAt | date:\"dd MMM, yyyy '['h:mm a']'\"  }}</small>\n" +
    "                                        by <span class=\"bold\">{{ item.createdBy }}</span>\n" +
    "                                        checked <span class=\"bold\"> {{ item.total }} </span>\n" +
    "                                        <ng-pluralize count=\"item.total\" when=\"{'one': 'url','other': 'urls'}\"></ng-pluralize>\n" +
    "                                        <span> of which <span class=\"bold\">{{ item.invalid }}</span> were invalid</span>\n" +
    "                                        <i ng-if=\"item.advanced\">(Advanced Profiles)</i>\n" +
    "                                    </li>\n" +
    "                                </ul>\n" +
    "                            </div>\n" +
    "                            <div ng-if=\"!TuProgramsUrlCheckerController.logs.length\">\n" +
    "                                No Logs\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/qa/components/publishConfig/publishConfigView.html',
    "<div class=\"wrapper wrapper-content animated fadeInRight page-publish-config\">\n" +
    "    <div class=\"section\" ng-class=\"{ 'modal-overlay-35': PublishConfigController.fetchInProgress }\">\n" +
    "        <div class=\"row\">\n" +
    "            <div wave-spinner class=\"absolute-wave-spinner\" ng-show=\"PublishConfigController.fetchInProgress\"></div>\n" +
    "\n" +
    "            <div class=\"col-md-9\">\n" +
    "                    <div class=\"row\">\n" +
    "                        <div class=\"col-md-6 b-r\">\n" +
    "                            <div class=\"content-box\">\n" +
    "                                <h3>TopMba.com</h3>\n" +
    "                                <ul class=\"list-group clear-list m-t\">\n" +
    "                                    <li class=\"list-group-item fist-item\">\n" +
    "                                        <strong>UI:</strong>\n" +
    "                                        <span class=\"pull-right\">{{ PublishConfigController.ui.tm }}</span>\n" +
    "                                    </li>\n" +
    "                                    <li class=\"list-group-item\">\n" +
    "                                        <strong>Backend:</strong>\n" +
    "                                        <span class=\"pull-right\">\n" +
    "                                            <a href=\"{{ PublishConfigController.backend.profiles.tm }}/admin/config/system/qs-feeds\" target=\"_blank\">\n" +
    "                                                <i class=\"fa fa-share\"></i>\n" +
    "                                            </a>\n" +
    "                                        </span>\n" +
    "                                        <span class=\"pull-right m-r-sm\">{{ PublishConfigController.backend.profiles.tm }}</span>\n" +
    "                                    </li>\n" +
    "                                    <li class=\"list-group-item\">\n" +
    "                                        <strong>Rankings:</strong>\n" +
    "                                        <span class=\"pull-right\">\n" +
    "                                            <a href=\"{{ PublishConfigController.backend.rankings.tm }}/admin/config/system/qs-feeds\" target=\"_blank\">\n" +
    "                                                <i class=\"fa fa-share\"></i>\n" +
    "                                            </a>\n" +
    "                                        </span>\n" +
    "                                        <span class=\"pull-right m-r-sm\">{{ PublishConfigController.backend.rankings.tm }}</span>\n" +
    "                                    </li>\n" +
    "                                </ul>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "\n" +
    "                        <div class=\"col-md-6\" ng-show=\"!MasterPasswordController.isExpired()\">\n" +
    "                            <div class=\"content-box\">\n" +
    "                                <h3>TopUniversities.com</h3>\n" +
    "                                <ul class=\"list-group clear-list m-t\">\n" +
    "                                    <li class=\"list-group-item fist-item\">\n" +
    "                                        <strong>UI:</strong>\n" +
    "                                        <span class=\"pull-right\">{{ PublishConfigController.ui.tu }}</span>\n" +
    "                                    </li>\n" +
    "                                    <li class=\"list-group-item\">\n" +
    "                                        <strong>Backend:</strong>\n" +
    "                                        <span class=\"pull-right\">\n" +
    "                                            <a href=\"{{ PublishConfigController.backend.profiles.tu }}/admin/config/system/qs-feeds\" target=\"_blank\">\n" +
    "                                                 <i class=\"fa fa-share\"></i>\n" +
    "                                            </a>\n" +
    "                                        </span>\n" +
    "                                        <span class=\"pull-right m-r-sm\">{{ PublishConfigController.backend.profiles.tu }} </span>\n" +
    "                                    </li>\n" +
    "                                     <li class=\"list-group-item\">\n" +
    "                                        <strong>Rankings:</strong>\n" +
    "                                        <span class=\"pull-right\">\n" +
    "                                            <a href=\"{{ PublishConfigController.backend.rankings.tu }}/admin/config/system/qs-feeds\" target=\"_blank\">\n" +
    "                                                 <i class=\"fa fa-share\"></i>\n" +
    "                                            </a>\n" +
    "                                        </span>\n" +
    "                                        <span class=\"pull-right m-r-sm\">{{ PublishConfigController.backend.rankings.tu }} </span>\n" +
    "                                    </li>\n" +
    "                                </ul>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <!-- New block-->\n" +
    "                <div class=\"col-md-9 row\">\n" +
    "                    <div class=\"col-md-6\" ng-show=\"!MasterPasswordController.isExpired()\">\n" +
    "                        <div class=\"content-box\">\n" +
    "                            <h3>QSChina.cn</h3>\n" +
    "                            <ul class=\"list-group clear-list m-t\">\n" +
    "                                <li class=\"list-group-item fist-item\">\n" +
    "                                    <strong>UI:</strong>\n" +
    "                                    <span class=\"pull-right\">{{ PublishConfigController.ui.tuChina }}</span>\n" +
    "                                </li>\n" +
    "                                <li class=\"list-group-item\">\n" +
    "                                    <strong>Backend:</strong>\n" +
    "                                    <span class=\"pull-right\">\n" +
    "                                        <a href=\"{{ PublishConfigController.backend.profiles.tuChina }}/admin/config/system/qs-feeds\" target=\"_blank\">\n" +
    "                                            <i class=\"fa fa-share\"></i>\n" +
    "                                        </a>\n" +
    "                                    </span>\n" +
    "                                    <span class=\"pull-right m-r-sm\">{{ PublishConfigController.backend.profiles.tuChina }} </span>\n" +
    "                                </li>\n" +
    "                            </ul>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/bulkGerUpload/bulkGerUpload.html',
    "<div class=\"wrapper wrapper-content animated fadeInRight page-ger-bulk-upload\">\n" +
    "    <div class=\"row\">\n" +
    "        <div class=\"col-sm-12\">\n" +
    "            <div class=\"section form-inline\">\n" +
    "                <!-- SECTION HEADER -->\n" +
    "                <div class=\"row\">\n" +
    "                    <div class=\"col-lg-6\">\n" +
    "                        Please upload a CSV file <span class=\"required\">&nbsp;</span>\n" +
    "                        <form class=\"clearfix\"\n" +
    "                              name=\"BulkGerUploadController.forms.uploadForm\"\n" +
    "                              enctype=\"multipart/form-data\"\n" +
    "                              ng-dropzone\n" +
    "                              dropzone=\"BulkGerUploadController.dropZoneInstance\"\n" +
    "                              dropzone-config=\"BulkGerUploadController.uploadConfig.dropzone\"\n" +
    "                              event-handlers=\"BulkGerUploadController.uploadConfig.eventHandlers\"\n" +
    "                              novalidate\n" +
    "                              focus-delay=\"250\"\n" +
    "                              dz-hidden-input-file>\n" +
    "\n" +
    "                            <div class=\"btn btn-default btn-outline btn-sm pull-left dz-message\">\n" +
    "                                <i class=\"fa fa-upload\"></i> Upload\n" +
    "                            </div>\n" +
    "                        </form>\n" +
    "                        <br>\n" +
    "                        <a class=\"btn btn-primary\"\n" +
    "                           ng-click=\"BulkGerUploadController.handleCheckFile()\"\n" +
    "                           ng-show=\"BulkGerUploadController.showBulkUploadButton\">\n" +
    "                            <i class=\"fa fa-reply reverse-fa\"></i> Click to populate data\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div wave-spinner class=\"absolute-wave-spinner\" ng-show=\"BulkGerUploadController.isLoading\"></div>\n" +
    "                </br>\n" +
    "                <div class=\"alert alert-danger\" ng-show=\"BulkGerUploadController.isFileErrors\">\n" +
    "                    <ul>\n" +
    "                        <li ng-repeat=\"item in BulkGerUploadController.fileErrors\">\n" +
    "                            Incorrect value: <strong> \"{{ item.value }}\" </strong> in column <strong> \"{{ item.column }}\" </strong> and row number <strong> \"{{ item.row }}\" </strong>\n" +
    "                            <ul>\n" +
    "                                <li ng-if=\"item.message !== undefined\"><strong> Error Message: </strong> {{ item.message }}</li>\n" +
    "                            </ul>\n" +
    "                        </li>\n" +
    "                    </ul>\n" +
    "                </div>\n" +
    "                <div class=\"alert alert-danger\" ng-show=\"BulkGerUploadController.isColumnErrors\">\n" +
    "                    <ul>\n" +
    "                        <li>\n" +
    "                            <p ng-bind-html=\"BulkGerUploadController.fileErrors.errorMessage\"></p>\n" +
    "                        </li>\n" +
    "                    </ul>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/classification/classificationView.html',
    "<div class=\"ibox classification-component\">\n" +
    "    <div class=\"ibox-title\">\n" +
    "        <h5>{{ $ctrl.data.id ? 'Edit' : 'Add' }} Classification</h5>\n" +
    "        <div class=\"ibox-tools\">\n" +
    "            <a ng-click=\"$ctrl.close()\">\n" +
    "                <i class=\"fa fa-times\"></i>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"ibox-content\">\n" +
    "        <form class=\"form-horizontal edit-classification\" name=\"forms.editClassification\" novalidate>\n" +
    "            <div class=\"form-group\" ng-class=\"{'has-errors': $ctrl.isInstitutionEmpty}\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">Institution Name *</label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <ui-select\n" +
    "                        ng-if=\"!$ctrl.data.id\"\n" +
    "                        ng-model=\"$ctrl.institution\"\n" +
    "                        ng-required=\"true\"\n" +
    "                        on-select=\"$ctrl.handleInstitutionChanges()\"\n" +
    "                        reset-search-input=\"true\"\n" +
    "                        theme=\"bootstrap\">\n" +
    "                        <ui-select-match allow-clear=\"true\" placeholder=\"Select an Option\">{{$select.selected.name}}</ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                            refresh-delay=\"800\"\n" +
    "                            refresh=\"$ctrl.searchInstitution($select.search)\"\n" +
    "                            position=\"down\"\n" +
    "                            repeat=\"option in $ctrl.searchResults | filter:$select.search\">\n" +
    "                            <div ng-bind-html=\"option.name | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                    <div wave-spinner class=\"absolute-wave-spinner institution-search\" ng-show=\"$ctrl.searchInProgress\"></div>\n" +
    "\n" +
    "                    <input type=\"text\" class=\"form-control\"\n" +
    "                           value=\"{{$ctrl.data.institutionName}} [{{ $ctrl.data.institutionCoreId }}]\"\n" +
    "                           ng-required=\"true\"\n" +
    "                           ng-disabled=\"true\"\n" +
    "                           ng-if=\"$ctrl.data.id\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">Last Updated\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <span class=\"text-muted date-field\">\n" +
    "                        {{$ctrl.data.modifiedAt|  date:\"medium\"}}\n" +
    "                    </span>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">Size</label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <input type=\"text\" ng-model=\"$ctrl.data.size\" class=\"form-control\" >\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">Subject Range\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <input type=\"text\" ng-model=\"$ctrl.data.subjectRange\" class=\"form-control\" >\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">Age</label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <input type=\"text\" ng-model=\"$ctrl.data.age\" class=\"form-control\" >\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4 align-lbl\">Research Intensity</label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <input type=\"text\" ng-model=\"$ctrl.data.researchIntensity\" class=\"form-control\" >\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4 align-lbl\">Tuition Fees Domestic Undergrad\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                        uib-tooltip=\"Add dollar range\"\n" +
    "                        tooltip-placement=\"top\">\n" +
    "                    </i>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <input type=\"text\" ng-model=\"$ctrl.data.domesticUgTuitionFees\" class=\"form-control\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4 align-lbl\">Tuition Fees Domestic Postgrad\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                        uib-tooltip=\"Add dollar range\"\n" +
    "                        tooltip-placement=\"top\">\n" +
    "                    </i>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <input type=\"text\" ng-model=\"$ctrl.data.domesticPgTuitionFees\" class=\"form-control\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4 align-lbl\">Tuition Fees International Undergrad\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                        uib-tooltip=\"Add dollar range\"\n" +
    "                        tooltip-placement=\"top\">\n" +
    "                    </i>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <input type=\"text\" ng-model=\"$ctrl.data.internationalUgTuitionFees\" class=\"form-control\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4 align-lbl\">Tuition Fees International Postgrad\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                        uib-tooltip=\"Add dollar range\"\n" +
    "                        tooltip-placement=\"top\">\n" +
    "                    </i>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <input type=\"text\" ng-model=\"$ctrl.data.internationalPgTuitionFees\" class=\"form-control\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">Status\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                        uib-tooltip=\"Add letter\"\n" +
    "                        tooltip-placement=\"top\">\n" +
    "                    </i>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <input type=\"text\" ng-model=\"$ctrl.data.status\" class=\"form-control\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4 align-lbl\">Number of Academic Faculty Staff\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                        uib-tooltip=\"Add number\"\n" +
    "                        tooltip-placement=\"top\">\n" +
    "                    </i>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <input type=\"text\" ng-model=\"$ctrl.data.faculty\" class=\"form-control\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4 align-lbl\">Number of Academic Faculty Staff with PhD\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                        uib-tooltip=\"Add number\"\n" +
    "                        tooltip-placement=\"top\">\n" +
    "                    </i>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <input type=\"text\" ng-model=\"$ctrl.data.facultyPhd\" class=\"form-control\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4 align-lbl\">Number of International Academic Faculty Staff / Year\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                        uib-tooltip=\"Add number\"\n" +
    "                        tooltip-placement=\"top\">\n" +
    "                    </i>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <input type=\"text\" ng-model=\"$ctrl.data.facultyInternational\" class=\"form-control\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4 align-lbl\">Number of Students\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                        uib-tooltip=\"Add number\"\n" +
    "                        tooltip-placement=\"top\">\n" +
    "                    </i>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <input type=\"text\" ng-model=\"$ctrl.data.students\" class=\"form-control\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4 align-lbl\">Number of Undergraduate Students\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                        uib-tooltip=\"Add percentage\"\n" +
    "                        tooltip-placement=\"top\">\n" +
    "                    </i>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <input type=\"text\" ng-model=\"$ctrl.data.studentsUg\" class=\"form-control\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4 align-lbl\">Number of Postgraduate Students\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                        uib-tooltip=\"Add percentage\"\n" +
    "                        tooltip-placement=\"top\">\n" +
    "                    </i>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <input type=\"text\" ng-model=\"$ctrl.data.studentsPg\" class=\"form-control\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4 align-lbl\">Number of International Students\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                        uib-tooltip=\"Add number\"\n" +
    "                        tooltip-placement=\"top\">\n" +
    "                    </i>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <input type=\"text\" ng-model=\"$ctrl.data.studentsInternational\" class=\"form-control\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4 align-lbl\">Number of International Undergraduate Students\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                        uib-tooltip=\"Add percentage\"\n" +
    "                        tooltip-placement=\"top\">\n" +
    "                    </i>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <input type=\"text\" ng-model=\"$ctrl.data.studentsUgInternational\" class=\"form-control\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4 align-lbl\">Number of International Postgraduate Students\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                        uib-tooltip=\"Add percentage\"\n" +
    "                        tooltip-placement=\"top\">\n" +
    "                    </i>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <input type=\"text\" ng-model=\"$ctrl.data.studentsPgInternational\" class=\"form-control\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4 align-lbl\">Student / Faculty ratio\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                        uib-tooltip=\"Auto-calculated\"\n" +
    "                        tooltip-placement=\"top\">\n" +
    "                    </i>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <input type=\"text\" ng-model=\"$ctrl.data.studentFacultyRatio\" class=\"form-control\" ng-disabled=\"true\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4 align-lbl\">Domestic Students Percentage\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                        uib-tooltip=\"Auto-calculated\"\n" +
    "                        tooltip-placement=\"top\">\n" +
    "                    </i>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <input type=\"text\" ng-model=\"$ctrl.data.domesticStudentsPercentage\" class=\"form-control\" ng-disabled=\"true\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">Gender mix\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                        uib-tooltip=\"Add number\"\n" +
    "                        tooltip-placement=\"top\">\n" +
    "                    </i>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <input type=\"text\" ng-model=\"$ctrl.data.genderMix\" class=\"form-control\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">Graduation rate\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                        uib-tooltip=\"Add number\"\n" +
    "                        tooltip-placement=\"top\">\n" +
    "                    </i>\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-8\">\n" +
    "                    <input type=\"text\" ng-model=\"$ctrl.data.graduationRate\" class=\"form-control\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"form-group m-t-lg\">\n" +
    "                <div class=\"col-md-12 col-lg-6 m-b-lg\">\n" +
    "                    <a class=\"btn btn-default btn-block m-b-sm\"\n" +
    "                       ng-show=\"$ctrl.data.id\"\n" +
    "                       ng-class=\"{'disabled': !$ctrl.data.id}\"\n" +
    "                       ng-click=\"$ctrl.publish()\"\n" +
    "                       ng-disabled=\"!$ctrl.data.hasDrupalProfile\"\n" +
    "                       uib-tooltip=\"TU Drupal Profile doesn't exist for this institution and needs to be published\"\n" +
    "                       tooltip-append-to-body=\"true\"\n" +
    "                       tooltip-placement=\"top\"\n" +
    "                       tooltip-enable=\"!$ctrl.data.hasDrupalProfile\">\n" +
    "                        <i class=\"fa fa-cloud-upload\"></i>\n" +
    "                        <span>Publish Tu</span>\n" +
    "                    </a>\n" +
    "                </div>\n" +
    "                <div class=\"col-md-12 col-lg-6 m-b-lg\">\n" +
    "                    <a class=\"btn btn-danger btn-block\"\n" +
    "                        ng-show=\"$ctrl.data.id\"\n" +
    "                        ng-class=\"{'disabled': !$ctrl.data.id}\"\n" +
    "                        ng-click=\"$ctrl.publish(true)\"\n" +
    "                        ng-disabled=\"!$ctrl.data.lastPublished\"\n" +
    "                        uib-tooltip=\"{{$ctrl.getDeleteTooltip()}}\"\n" +
    "                        tooltip-append-to-body=\"true\"\n" +
    "                        tooltip-placement=\"top\">\n" +
    "                        <i class=\"fa fa-close\"></i>\n" +
    "                        <span>Unpublish Tu</span>\n" +
    "                    </a>\n" +
    "                    <!-- <a class=\"btn btn-default btn-block m-b-sm\"\n" +
    "                       ng-show=\"$ctrl.data.id\"\n" +
    "                       ng-class=\"{'disabled': !$ctrl.data.id}\"\n" +
    "                       ng-click=\"$ctrl.publish(false, true)\"\n" +
    "                       ng-disabled=\"!$ctrl.data.hasDrupalProfile\"\n" +
    "                       uib-tooltip=\"TU Drupal Profile doesn't exist for this institution and needs to be published\"\n" +
    "                       tooltip-append-to-body=\"true\"\n" +
    "                       tooltip-placement=\"top\"\n" +
    "                       tooltip-enable=\"!$ctrl.data.hasDrupalProfile\">\n" +
    "                        <i class=\"fa fa-cloud-upload\"></i>\n" +
    "                        <span>Publish Cn</span>\n" +
    "                    </a>\n" +
    "                    <a class=\"btn btn-danger btn-block\"\n" +
    "                       ng-show=\"$ctrl.data.id\"\n" +
    "                       ng-class=\"{'disabled': !$ctrl.data.id}\"\n" +
    "                       ng-click=\"$ctrl.publish(true, true)\"\n" +
    "                       ng-disabled=\"!$ctrl.data.lastPublished\"\n" +
    "                       uib-tooltip=\"{{$ctrl.getDeleteTooltip()}}\"\n" +
    "                       tooltip-append-to-body=\"true\"\n" +
    "                       tooltip-placement=\"top\">\n" +
    "                        <i class=\"fa fa-close\"></i>\n" +
    "                        <span>Unpublish Cn</span>\n" +
    "                    </a> -->\n" +
    "                </div>\n" +
    "                <div class=\"col-md-12\">\n" +
    "                    <a class=\"btn btn-primary btn-block m-b-sm\"\n" +
    "                        ng-class=\"{'disabled': $ctrl.isUpdateInProgress}\"\n" +
    "                        ng-click=\"$ctrl.data.id ? $ctrl.update() : $ctrl.add()\">\n" +
    "                         <i class=\"fa fa-check-circle\"></i>\n" +
    "                         <span>{{ $ctrl.data.id ? 'Update' : 'Add' }}</span>\n" +
    "                     </a>\n" +
    "                        <a class=\"btn btn-default btn-block\" ng-click=\"$ctrl.close()\">\n" +
    "                            <i class=\"fa fa-ban\"></i>\n" +
    "                            <span>Cancel</span>\n" +
    "                        </a>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "        </form>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/classificationsOverview/classificationsOverviewView.html',
    "<div class=\"wrapper wrapper-content animated fadeInRight page-classifications-overview\">\n" +
    "\n" +
    "    <div class=\"row\" ng-class=\"{'modal-overlay-35' : ClassificationsOverviewController.isLoading}\">\n" +
    "\n" +
    "        <div ng-class=\"ClassificationsOverviewController.isRightSidePanelActive() ? 'col-sm-8' : 'col-sm-12'\">\n" +
    "            <div class=\"ibox float-e-margins\">\n" +
    "                <div class=\"ibox-content table-responsive\">\n" +
    "\n" +
    "                    <div class=\"row\">\n" +
    "                        <div class=\"col-sm-12\">\n" +
    "                            <a class=\"btn btn-primary\"\n" +
    "                               ng-click=\"ClassificationsOverviewController.add()\">\n" +
    "                                <i class=\"fa fa-plus\"></i>\n" +
    "                                <span>Add New</span>\n" +
    "                            </a>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "\n" +
    "                    <div wave-spinner class=\"absolute-wave-spinner\" ng-show=\"ClassificationsOverviewController.isLoading\"></div>\n" +
    "\n" +
    "                    <div ng-if=\"gridOptions\" ng-class=\"{'modal-overlay-35': ClassificationsOverviewController.isLoading}\">\n" +
    "                        <ui-grid-info></ui-grid-info>\n" +
    "                        <div class=\"grid\"\n" +
    "                             ui-grid=\"gridOptions\"\n" +
    "                             ui-grid-selection\n" +
    "                             ui-grid-cellnav\n" +
    "                             ui-grid-exporter\n" +
    "                             ui-grid-pagination\n" +
    "                             ui-grid-resize-columns\n" +
    "                             ui-grid-auto-resize></div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"col-sm-4 right-side-container\"\n" +
    "             ng-show=\"ClassificationsOverviewController.isRightSidePanelActive()\"\n" +
    "             fixed-element-while-scrolling=\"#programsTable\">\n" +
    "            <classification data=\"ClassificationsOverviewController.selectedRow\"\n" +
    "                            selected=\"ClassificationsOverviewController.selectedRowId\"\n" +
    "                            reload=\"ClassificationsOverviewController.reloadRequest\"\n" +
    "                            search-results=\"ClassificationsOverviewController.searchResults\"\n" +
    "                            institution=\"ClassificationsOverviewController.institution\"></classification>\n" +
    "        </div>\n" +
    "\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/modules/rankings/components/classificationsOverview/datagrid/lastPublishedFilterHeaderTemplate.html',
    "<div class=\"ui-grid-filter-container {{'ui-grid-filter-input-' + $index}}\" ng-repeat=\"colFilter in col.filters\" ng-if=\"colFilter.visible\">\n" +
    "    <input type=\"text\" class=\"ui-grid-filter-input date-picker ui-grid-filter-input-{{$index}}\"\n" +
    "           ng-model=\"grid.appScope.filters.lastPublishedRange\"\n" +
    "           date-range-picker=\"\"\n" +
    "           date-range-fixer\n" +
    "           readonly\n" +
    "           options=\"{eventHandlers: {'apply.daterangepicker': grid.appScope.handleLastLoginAtDateRange}, opens: 'left'}\" />\n" +
    "          \n" +
    "    <div class=\"ui-grid-filter-button\" ng-click=\"removeFilter(colFilter, $index)\" >\n" +
    "            <i class=\"ui-grid-icon-cancel\"  ng-show=\"colFilter.term !== undefined && colFilter.term != null && colFilter.term != ''\"></i>\n" +
    "            <span class=\"ui-grid-icon-date\"  ng-show=\"colFilter.term == undefined || colFilter.term == null || colFilter.term == ''\"></span>\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/classificationsOverview/datagrid/statusCellTemplate.html',
    "<div class=\"ui-grid-cell-contents\">\n" +
    "    <i ng-class=\"{'hidden': COL_FIELD !== true}\" class=\"fa fa-check-circle text-navy\" aria-hidden=\"true\"></i>\n" +
    "    <i ng-class=\"{'hidden': COL_FIELD !== false}\" class=\"fa fa-times-circle text-danger\" aria-hidden=\"true\"></i>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/classificationsPublish/classificationsPublishView.html',
    "<div  class=\"wrapper wrapper-content animated fadeInRight page-classifications-publish\">\n" +
    "    <div class=\"section\">\n" +
    "        <div class=\"publish-rankings-history stage-headings row\">\n" +
    "            <div class=\"col-xs-12 m-b\">\n" +
    "                <ol class=\"breadcrumb\">\n" +
    "                  <li class=\"active font-bold\" ng-show=\"ClassificationsPublishController.getCurrentState() >= 0\">Import CSV</li>\n" +
    "                  <li ng-class=\"{'active font-bold':ClassificationsPublishController.getCurrentState() >= 1}\">Preview Matching Columns</li>\n" +
    "                  <li ng-class=\"{'active font-bold':ClassificationsPublishController.getCurrentState() >= 2}\">Save and Confirm</li>\n" +
    "                  <li ng-class=\"{'active font-bold':ClassificationsPublishController.getCurrentState() >= 3}\">Publish Matching Data</li>\n" +
    "                </ol>\n" +
    "                <hr class=\"clearfix m-b\">\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"row\" ng-show=\"ClassificationsPublishController.showUpload()\">\n" +
    "            <div class=\"col-lg-6\">\n" +
    "                Please upload a CSV file\n" +
    "                <span class=\"clickable fa fa-info-circle\"\n" +
    "                    uib-tooltip=\"A comma-separated values (CSV) file stores tabular data (numbers and text) in plain text. Each line of the file is a data record. Each record consists of one or more fields, separated by commas. The use of the comma as a field separator is the source of the name for this file format.\"\n" +
    "                    tooltip-placement=\"top\">\n" +
    "                </span>\n" +
    "                <span class=\"required\">&nbsp;</span>\n" +
    "                <form class=\"clearfix\"\n" +
    "                        name=\"ClassificationsPublishController.forms.uploadForm\"\n" +
    "                        enctype=\"multipart/form-data\"\n" +
    "                        ng-dropzone\n" +
    "                        dropzone=\"ClassificationsPublishController.dropZoneInstance\"\n" +
    "                        dropzone-config=\"ClassificationsPublishController.uploadConfig.dropzone\"\n" +
    "                        event-handlers=\"ClassificationsPublishController.uploadConfig.eventHandlers\"\n" +
    "                        novalidate\n" +
    "                        focus-delay=\"250\"\n" +
    "                        dz-hidden-input-file>\n" +
    "\n" +
    "                        <div class=\"btn btn-default btn-outline btn-sm pull-left dz-message\" ng-show=\"ClassificationsPublishController.uploadEnabled\">\n" +
    "                            <i class=\"fa fa-upload\"></i> Upload\n" +
    "                        </div>\n" +
    "                    </form>\n" +
    "                <br>\n" +
    "                    <a class=\"btn btn-primary\"\n" +
    "                       ng-click=\"ClassificationsPublishController.handleCheckFile()\"\n" +
    "                       ng-show=\"ClassificationsPublishController.showStepTwoButton\">\n" +
    "                        <i class=\"fa fa-reply reverse-fa\"></i> Step 2 - Upload and Preview\n" +
    "                    </a>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div wave-spinner class=\"absolute-wave-spinner\" ng-show=\"ClassificationsPublishController.isLoading()\"></div>\n" +
    "\n" +
    "        <div class=\"row\" ng-show=\"ClassificationsPublishController.showPreview()\">\n" +
    "            <classifications-publish-pre-view data=\"ClassificationsPublishController.data\"\n" +
    "                                              section=\"ClassificationsPublishController.section\"\n" +
    "            ></classifications-publish-pre-view>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"row\" ng-show=\"ClassificationsPublishController.showPrePublish()\">\n" +
    "            <classifications-publish-pre-publish section=\"ClassificationsPublishController.section\"\n" +
    "                                                 data=\"ClassificationsPublishController.data\"></classifications-publish-pre-publish>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"row\" ng-show=\"ClassificationsPublishController.showPublish()\">\n" +
    "            <classifications-publish-publish section=\"ClassificationsPublishController.section\"></classifications-publish-publish>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"section m-t\">\n" +
    "           <div>\n" +
    "               <div class=\"m-b\">\n" +
    "                   <h2>Publish Logs</h2>\n" +
    "               </div>\n" +
    "               <publish-logs ng-show=\"ClassificationsPublishController.gridOptions.data\"\n" +
    "                             logs=\"ClassificationsPublishController.logs\"\n" +
    "                             ranking=\"ClassificationsPublishController.ranking\"></publish-logs>\n" +
    "               <div ng-show=\"ClassificationsPublishController.loadingMorePublishLogs\" wave-spinner class=\"wave-spinner m-b-sm\">\n" +
    "                </div>\n" +
    "\n" +
    "                <div\n" +
    "                    ng-show=\"ClassificationsPublishController.showLoadMoreLogsButton()\"\n" +
    "                    class=\"text-center\">\n" +
    "                    <button class=\"btn btn-primary\" ng-click=\"ClassificationsPublishController.loadMorePublishLogs()\">\n" +
    "                        <i class=\"fa fa-refresh\"></i>\n" +
    "                        Load More\n" +
    "                    </button>\n" +
    "                </div>\n" +
    "           </div>\n" +
    "       </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/classificationsPublish/rankingsPrePublish/classificationsPublishPrePublishView.html',
    "<div class=\"col-lg-12\">\n" +
    "    <p>The data was successfully stored into the data and is ready to be published. Please choose one the following\n" +
    "        options</p>\n" +
    "    <div class=\"m-t\">\n" +
    "        <div class=\"text-center\">\n" +
    "            <button class=\"btn btn-primary\" ng-click=\"$ctrl.handlePreviewClassificationsClick()\">Review Scores Before Publishing\n" +
    "            </button>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"m-t row\">\n" +
    "        <div class=\"col-xs-6\">\n" +
    "            <button class=\"btn btn-default\" ng-click=\"$ctrl.handleBackToStepPreview()\"><i class=\"fa fa-reply\"></i> Go back to step 2 to export preview</button>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-6\">\n" +
    "            <button class=\"pull-right btn btn-primary\" ng-click=\"$ctrl.handlePublishClick()\">\n" +
    "                Publish Data\n" +
    "                <i class=\"fa fa-cloud-upload\"></i></button>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/classificationsPublish/rankingsPreview/classificationsPublishPreViewView.html',
    "\n" +
    "<div class=\"col-lg-12\">\n" +
    "    <p>Preview Data - Please make sure that your data columns match the template columns.</p>\n" +
    "    <br>\n" +
    "    <div ng-if=\"$ctrl.gridOptions\">\n" +
    "        <div class=\"grid\"\n" +
    "             ui-grid=\"$ctrl.gridOptions\"\n" +
    "             ui-grid-cellnav\n" +
    "             ui-grid-resize-columns\n" +
    "             ui-grid-auto-resize></div>\n" +
    "    </div>\n" +
    "\n" +
    "    <h2>What do you want to do next?</h2>\n" +
    "    <div class=\"text-center m-t\">\n" +
    "        <b>Note:</b> There is no automate way to check if the columns match correctly so please check if the first 10 rows are matching correctly and then choose one of the following options. This step is only for you to confirm that the columns are matching correctly. At the next step all the data will be validated and if there is any mistake at your data the system will throw a validation error.\n" +
    "    </div>\n" +
    "    <div class=\"m-t\">\n" +
    "        <button class=\"btn btn-default\" ng-click=\"$ctrl.handleBackToStepUpload()\">\n" +
    "            <i class=\"fa fa-reply\"></i> Columns don't match, go back and import a different file\n" +
    "        </button>\n" +
    "        <button class=\"btn btn-primary pull-right\" ng-click=\"$ctrl.handleGoToPrePublishStep()\">\n" +
    "            Columns match, validate and save data to database <i class=\"fa fa-share\"></i>\n" +
    "        </button>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/classificationsPublish/rankingsPublish/classificationsPublishPublishView.html',
    "<div class=\"col-lg-12\">\n" +
    "    <div class=\"m-t\" progress-circle=\"$ctrl.section.status\"\n" +
    "         percentage=\"$ctrl.section.percent\"></div>\n" +
    "\n" +
    "    <div class=\"m-t text-center\">\n" +
    "        <div class=\"col-lg-12\">\n" +
    "            <div>\n" +
    "                <span>\n" +
    "                    {{$ctrl.section.totalPublished}} of {{$ctrl.section.totalScores}}\n" +
    "                </span>\n" +
    "                <span>\n" +
    "                    ({{$ctrl.section.progress}}%)\n" +
    "                </span>\n" +
    "            </div>\n" +
    "            <button ng-show=\"$ctrl.publishingIsInProgress() || $ctrl.publishingIsPending()\"\n" +
    "                    class=\"m-t btn btn-danger\"\n" +
    "                    ng-click=\"$ctrl.cancelPublishing()\">\n" +
    "                <i class=\"fa fa-ban\"></i>\n" +
    "                Cancel Publish\n" +
    "            </button>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"container\" ng-show=\"$ctrl.publishingIsFinished() && $ctrl.section.status === 'failure'\">\n" +
    "        <div class=\"col-lg-6\">\n" +
    "            <button class=\"m-t btn btn-danger pull-right\"\n" +
    "                    ng-click=\"$ctrl.viewFailedRecords()\">\n" +
    "                <i class=\"fa fa-eye\"></i>\n" +
    "                View Failed Records\n" +
    "            </button>\n" +
    "        </div>\n" +
    "        <div class=\"col-lg-6\">\n" +
    "            <button class=\"m-t btn btn-primary pull-left\"\n" +
    "                    ng-click=\"$ctrl.publishFailedRecords()\">\n" +
    "                <i class=\"fa fa-repeat\"></i>\n" +
    "                Republish all failed institutions\n" +
    "            </button>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div>\n" +
    "        <a ng-show=\"$ctrl.publishingIsFinished()\" class=\"btn btn-primary pull-right\"\n" +
    "           ng-click=\"$ctrl.goStepUpload()\">\n" +
    "            <i class=\"fa fa-reply\"></i> Upload New File\n" +
    "        </a>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/ger/employerPartnerships/gerEmployerPartnershipsView.html',
    "<ger-question-header is-client=\"$ctrl.isClient\"></ger-question-header>\n" +
    "<form  name=\"$ctrl.forms.employerPartnerships\" novalidate>\n" +
    "<ger-question\n" +
    "    categories=\"$ctrl.categories\"\n" +
    "    questions=\"$ctrl.questions\"\n" +
    "    title=\"'Employer partnerships'\"\n" +
    "    handle=\"'employer_partnerships_question'\"\n" +
    "    active-question=\"$ctrl.activeQuestion\"\n" +
    "    values=\"$ctrl.values\"\n" +
    "    new-data=\"$ctrl.newData\"\n" +
    "    is-client=\"$ctrl.isClient\">\n" +
    "</ger-question>\n" +
    "</form>\n" +
    "<ger-upload\n" +
    "    categories=\"$ctrl.categories\"\n" +
    "    questions=\"$ctrl.questions\"\n" +
    "    upload-type=\"'employer-partnerships'\"\n" +
    "    handle=\"'employer_partnerships'\"\n" +
    "    upload-type-name=\"'Employer Partnerships'\"\n" +
    "    max-size=\"10\"\n" +
    "    active-question=\"$ctrl.activeQuestion\"\n" +
    "    new-data=\"$ctrl.newData\">\n" +
    "</ger-upload>\n" +
    "<employer-partnerships-datagrid\n" +
    "    categories=\"$ctrl.categories\"\n" +
    "    new-data=\"$ctrl.newData\"\n" +
    "    values=\"$ctrl.values\"\n" +
    "    form=\"$ctrl.forms.employerPartnerships\">\n" +
    "</employer-partnerships-datagrid>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/ger/employerPartnershipsDatagrid/employerPartnershipsDatagridView.html',
    "<div wave-spinner class=\"absolute-wave-spinner\" ng-show=\"$ctrl.showLoadBar()\"></div>\n" +
    "\n" +
    "<div ng-class=\"{'modal-overlay-35': $ctrl.showLoadBar()}\">\n" +
    "    <!-- <div ng-bind-html=\"$ctrl.categories.employer_partnerships.definition\"></div> -->\n" +
    "\n" +
    "    <div class=\"grid\"\n" +
    "         ui-grid=\"$ctrl.gridOptions\"\n" +
    "         ui-grid-resize-columns\n" +
    "         ui-grid-auto-resize\n" +
    "         ui-grid-cellnav\n" +
    "         ui-grid-pagination></div>\n" +
    "</div>\n" +
    "<button class=\"btn btn-primary btn-sm pull-right m-t\" ng-disabled=\"$ctrl.isSavingDisabled()\" ng-click=\"$ctrl.handleSaveClick()\">\n" +
    "    <i class=\"fa fa-check-circle\"></i> Save\n" +
    "</button>"
  );


  $templateCache.put('/scripts/modules/rankings/components/ger/employersConnections/gerEmployersConnectionsView.html',
    "<ger-question-header is-client=\"$ctrl.isClient\"></ger-question-header>\n" +
    "<form  name=\"$ctrl.forms.employersConnection\" novalidate>\n" +
    "<ger-question\n" +
    "    categories=\"$ctrl.categories\"\n" +
    "    questions=\"$ctrl.questions\"\n" +
    "    title=\"'Employer connections online'\"\n" +
    "    handle=\"'employers_connection_online'\"\n" +
    "    active-question=\"$ctrl.activeQuestion\"\n" +
    "    values=\"$ctrl.values\"\n" +
    "    new-data=\"$ctrl.newData\"\n" +
    "    is-client=\"$ctrl.isClient\">\n" +
    "</ger-question>\n" +
    "<ger-question\n" +
    "    categories=\"$ctrl.categories\"\n" +
    "    questions=\"$ctrl.questions\"\n" +
    "    title=\"'Employer connections on-campus'\"\n" +
    "    handle=\"'employers_connection_on_campus'\"\n" +
    "    active-question=\"$ctrl.activeQuestion\"\n" +
    "    values=\"$ctrl.values\"\n" +
    "    new-data=\"$ctrl.newData\"\n" +
    "    is-client=\"$ctrl.isClient\">\n" +
    "</ger-question>\n" +
    "</form>\n" +
    "<ger-upload\n" +
    "    categories=\"$ctrl.categories\"\n" +
    "    questions=\"$ctrl.questions\"\n" +
    "    upload-type=\"'employers-connections'\"\n" +
    "    handle=\"'employers_connections'\"\n" +
    "    upload-type-name=\"'Employers\\' Connections'\"\n" +
    "    max-size=\"10\"\n" +
    "    active-question=\"$ctrl.activeQuestion\"\n" +
    "    new-data=\"$ctrl.newData\">\n" +
    "</ger-upload>\n" +
    "<employers-connections-datagrid\n" +
    "    categories=\"$ctrl.categories\"\n" +
    "    new-data=\"$ctrl.newData\"\n" +
    "    values=\"$ctrl.values\"\n" +
    "    form=\"$ctrl.forms.employersConnection\">\n" +
    "</employers-connections-datagrid>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/ger/employersConnectionsDatagrid/employersConnectionsDatagridView.html',
    "<div wave-spinner class=\"absolute-wave-spinner\" ng-show=\"$ctrl.showLoadBar()\"></div>\n" +
    "\n" +
    "<div ng-class=\"{'modal-overlay-35': $ctrl.showLoadBar()}\">\n" +
    "    <div ng-bind-html=\"$ctrl.categories.employers_connections.definition\"></div>\n" +
    "\n" +
    "    <div class=\"grid\"\n" +
    "         ui-grid=\"$ctrl.gridOptions\"\n" +
    "         ui-grid-resize-columns\n" +
    "         ui-grid-auto-resize\n" +
    "         ui-grid-cellnav\n" +
    "         ui-grid-pagination></div>\n" +
    "</div>\n" +
    "<button class=\"btn btn-primary btn-sm pull-right m-t\" ng-disabled=\"$ctrl.isSavingDisabled()\" ng-click=\"$ctrl.handleSaveClick()\">\n" +
    "    <i class=\"fa fa-check-circle\"></i> Save\n" +
    "</button>"
  );


  $templateCache.put('/scripts/modules/rankings/components/ger/employmentRate/gerEmploymentRateView.html',
    "<div ng-bind-html=\"$ctrl.categories.employment_rate.definition\"></div>\n" +
    "\n" +
    "<div class=\"form form-horizontal statistics-submission\" ng-class=\"{'modal-overlay-35' : $ctrl.showOverlay()}\">\n" +
    "    <div wave-spinner class=\"absolute-wave-spinner\" ng-show=\"$ctrl.showOverlay()\"></div>\n" +
    "\n" +
    "    <div class=\"row font-bold question-header\">\n" +
    "        <div class=\"col-xs-2\"><span ng-if=\"$ctrl.isOtherRanking\">Employment Statistics</span></div>\n" +
    "        <div class=\"col-xs-1 text-muted p-w-xxs break-word\">\n" +
    "            Total grad stdts<span class=\"legendLabel\" ng-show=\"!$ctrl.isOtherRanking\">*</span>\n" +
    "            <span uib-tooltip='Number of students who have graduated from your institution within the past 12 months. This should include both undergraduates and postgraduates.' tooltip-placement=\"top\">\n" +
    "                <i class=\"fa fa-info-circle\"></i>\n" +
    "            </span>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-1 text-muted p-w-xxs break-word\">\n" +
    "            Total respondents\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-1 text-muted p-w-xxs break-word\">\n" +
    "            Employed grads<span class=\"legendLabel\" ng-show=\"!$ctrl.isOtherRanking\">*</span>\n" +
    "            <i class=\"fa fa-info-circle\"\n" +
    "               uib-tooltip=\"Employed F/T and/or P/T is the total number of Graduates (both undergraduates and postgraduates) who are employed within 12 months of graduation. Please enter just one figure for the total number of graduates who are employed.\"\n" +
    "               tooltip-class=\"break-word\"\n" +
    "               tooltip-placement=\"top\"></i>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-1 text-muted p-w-xxs break-word\">\n" +
    "            Unemp.<span class=\"legendLabel\" ng-show=\"!$ctrl.isOtherRanking\">*</span>\n" +
    "            <span uib-tooltip='Graduates who are currently seeking work (but will not be progressing to further study).' tooltip-placement=\"top\">\n" +
    "                <i class=\"fa fa-info-circle\"></i>\n" +
    "            </span>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-1 text-muted p-w-xxs\">\n" +
    "            In FT further study\n" +
    "            <i class=\"fa fa-info-circle\"\n" +
    "            uib-tooltip=\"IMPORTANT: Please note graduates pursuing further study/unavailable for employment will be excluded from the calculation.\"\n" +
    "            tooltip-class=\"break-word\"\n" +
    "            tooltip-placement=\"top\"></i>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-1 text-muted p-w-xxs break-word\">\n" +
    "            Unavail. for work \n" +
    "            <span uib-tooltip='Unavailable for work, for reasons external to job market pressures (such as military service / long term disability).' tooltip-placement=\"top\">\n" +
    "                <i class=\"fa fa-info-circle\"></i>\n" +
    "            </span>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-1 text-muted p-w-xxs break-word\">\n" +
    "            Est.\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-2 text-muted p-w-xxs\" ng-if=\"!$ctrl.isClient\">\n" +
    "            Source\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-1 text-muted\">\n" +
    "            Status\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"row question-content clickable\"\n" +
    "         ng-class=\"{'active': $ctrl.isActive('number_of_students')}\"\n" +
    "         ng-click=\"$ctrl.handleFocus('number_of_students')\">\n" +
    "        <div class=\"col-xs-2 question-name no-outline\">Number of Students</div>\n" +
    "        <div class=\"col-xs-1 question-answer p-w-xxs\" ng-class=\"{'has-error': $ctrl.isMandatory($ctrl.data.numberOfStudents.totalGraduatedStudents)}\">\n" +
    "            <input class=\"form-control p-xxs\" type=\"text\"\n" +
    "                   ng-model=\"$ctrl.data.numberOfStudents.totalGraduatedStudents\"\n" +
    "                   ng-change=\"$ctrl.calculatePercentages()\"\n" +
    "                   ng-required=\"!$ctrl.isOtherRanking\"\n" +
    "                   is-valid-int>\n" +
    "            <span\n" +
    "                focus-delay=\"250\"\n" +
    "                custom-popover\n" +
    "                popover-html=\"This field is mandatory\"\n" +
    "                popover-placement=\"bottom\"\n" +
    "                popover-trigger=\"hover\"\n" +
    "                popover-visibility=\"{{ $ctrl.isMandatory($ctrl.data.numberOfStudents.totalGraduatedStudents) }}\"></span>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-1 question-answer p-w-xxs\" ng-class=\"{'has-error': $ctrl.isGreater($ctrl.data.numberOfStudents.totalRespondents),'has-warning': $ctrl.isLessThanTwentyPercent($ctrl.data.numberOfStudents.totalRespondents) }\" >\n" +
    "            <input class=\"form-control p-xxs\" type=\"text\"\n" +
    "                   ng-model=\"$ctrl.data.numberOfStudents.totalRespondents\"\n" +
    "                   ng-change=\"$ctrl.calculatePercentages()\"\n" +
    "                   is-valid-int>\n" +
    "            <span\n" +
    "                focus-delay=\"250\"\n" +
    "                custom-popover\n" +
    "                popover-html=\"This can't be greater than 'Total graduated students'\"\n" +
    "                popover-placement=\"bottom\"\n" +
    "                popover-trigger=\"hover\"\n" +
    "                popover-visibility=\"{{ $ctrl.isGreater($ctrl.data.numberOfStudents.totalRespondents) }}\"></span>\n" +
    "            <span\n" +
    "                focus-delay=\"250\"\n" +
    "                custom-popover\n" +
    "                popover-html=\"If Total Respondents is less than 20% of Total Graduates, such a survey is treated as not representative\"\n" +
    "                popover-placement=\"bottom\"\n" +
    "                popover-trigger=\"hover\"\n" +
    "                popover-visibility=\"{{ $ctrl.isLessThanTwentyPercent($ctrl.data.numberOfStudents.totalRespondents) }}\"></span>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-1 question-answer p-w-xxs\" ng-class=\"{'has-error': $ctrl.isMandatory($ctrl.data.numberOfStudents.employed) || $ctrl.isGreater($ctrl.data.numberOfStudents.employed) }\">\n" +
    "            <input class=\"form-control p-xxs\" type=\"text\"\n" +
    "                   ng-model=\"$ctrl.data.numberOfStudents.employed\"\n" +
    "                   ng-change=\"$ctrl.calculatePercentages()\"\n" +
    "                   ng-required=\"!$ctrl.isOtherRanking\"\n" +
    "                   is-valid-int>\n" +
    "            <span\n" +
    "                focus-delay=\"250\"\n" +
    "                custom-popover\n" +
    "                popover-html=\"This field is mandatory\"\n" +
    "                popover-placement=\"bottom\"\n" +
    "                popover-trigger=\"hover\"\n" +
    "                popover-visibility=\"{{ $ctrl.isMandatory($ctrl.data.numberOfStudents.employed) }}\"></span>\n" +
    "            <span\n" +
    "                focus-delay=\"250\"\n" +
    "                custom-popover\n" +
    "                popover-html=\"This can't be greater than 'Total graduated students' or 'Total respondents'\"\n" +
    "                popover-placement=\"bottom\"\n" +
    "                popover-trigger=\"hover\"\n" +
    "                popover-visibility=\"{{ $ctrl.isGreater($ctrl.data.numberOfStudents.employed) }}\"></span>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-1 question-answer p-w-xxs\" ng-class=\"{'has-error': $ctrl.isMandatory($ctrl.data.numberOfStudents.unemployed) || $ctrl.isGreater($ctrl.data.numberOfStudents.unemployed) }\">\n" +
    "            <input class=\"form-control p-xxs\" type=\"text\"\n" +
    "                   ng-model=\"$ctrl.data.numberOfStudents.unemployed\"\n" +
    "                   ng-change=\"$ctrl.calculatePercentages()\"\n" +
    "                   is-valid-int>\n" +
    "            <span\n" +
    "                focus-delay=\"250\"\n" +
    "                custom-popover\n" +
    "                popover-html=\"This field is mandatory\"\n" +
    "                popover-placement=\"bottom\"\n" +
    "                popover-trigger=\"hover\"\n" +
    "                popover-visibility=\"{{ $ctrl.isMandatory($ctrl.data.numberOfStudents.unemployed) }}\"></span>\n" +
    "            <span\n" +
    "                focus-delay=\"250\"\n" +
    "                custom-popover\n" +
    "                popover-html=\"This can't be greater than 'Total graduated students' or 'Total respondents'\"\n" +
    "                popover-placement=\"bottom\"\n" +
    "                popover-trigger=\"hover\"\n" +
    "                popover-visibility=\"{{ $ctrl.isGreater($ctrl.data.numberOfStudents.unemployed) }}\"></span>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-1 question-answer p-w-xxs\" ng-class=\"{'has-error': $ctrl.isGreater($ctrl.data.numberOfStudents.inFurtherStudy) }\">\n" +
    "            <input class=\"form-control p-xxs\" type=\"text\"\n" +
    "                   ng-model=\"$ctrl.data.numberOfStudents.inFurtherStudy\"\n" +
    "                   ng-change=\"$ctrl.calculatePercentages()\"\n" +
    "                   ng-required=\"!$ctrl.isOtherRanking\"\n" +
    "                   is-valid-int>\n" +
    "            <span\n" +
    "                focus-delay=\"250\"\n" +
    "                custom-popover\n" +
    "                popover-html=\"This can't be greater than 'Total graduated students' or 'Total respondents'\"\n" +
    "                popover-placement=\"bottom\"\n" +
    "                popover-trigger=\"hover\"\n" +
    "                popover-visibility=\"{{ $ctrl.isGreater($ctrl.data.numberOfStudents.inFurtherStudy) }}\"></span>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-1 question-answer p-w-xxs\" ng-class=\"{'has-error': $ctrl.isGreater($ctrl.data.numberOfStudents.unavailable) }\">\n" +
    "            <input class=\"form-control p-xxs\" type=\"text\"\n" +
    "                   ng-model=\"$ctrl.data.numberOfStudents.unavailable\"\n" +
    "                   ng-change=\"$ctrl.calculatePercentages()\"\n" +
    "                   is-valid-int>\n" +
    "            <span\n" +
    "                focus-delay=\"250\"\n" +
    "                custom-popover\n" +
    "                popover-html=\"This can't be greater than 'Total graduated students' or 'Total respondents'\"\n" +
    "                popover-placement=\"bottom\"\n" +
    "                popover-trigger=\"hover\"\n" +
    "                popover-visibility=\"{{ $ctrl.isGreater($ctrl.data.numberOfStudents.unavailable) }}\"></span>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-1 question-estimate p-w-xxs\">\n" +
    "            <input i-checkbox ng-click=\"$ctrl.handleFocus('number_of_students')\" type=\"checkbox\" ng-model=\"$ctrl.data.numberOfStudents.estimate\" value=\"true\">\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-2 question-source p-w-xxs\" ng-if=\"!$ctrl.isClient\">\n" +
    "            <statistics-source source=\"$ctrl.data.numberOfStudents.source\" enabled=\"$ctrl.hasAnswers('number_of_students')\"></statistics-source>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-1 question-status\">\n" +
    "            <statistics-status-popup status=\"$ctrl.data.numberOfStudents.status\" enabled=\"$ctrl.hasAnswers('number_of_students')\"></statistics-status-popup>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row question-content clickable\" ng-class=\"{'active': $ctrl.isActive('median_grad_salary')}\"\n" +
    "        ng-click=\"$ctrl.handleFocus('median_grad_salary')\">\n" +
    "        <div class=\"col-xs-2 question-name no-outline\">Median Grad Salary (USD)</div>\n" +
    "        <div class=\"col-xs-6 question-answer p-w-xxs\">\n" +
    "            <input class=\"form-control p-xxs\" type=\"text\" ng-model=\"$ctrl.data.medianGradSalary.salary\"\n" +
    "                ng-change=\"$ctrl.calculatePercentages()\" ng-required=\"!$ctrl.isOtherRanking\" is-valid-int>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-1 question-estimate p-w-xxs\">\n" +
    "            <input i-checkbox ng-click=\"$ctrl.handleFocus('median_grad_salary')\" type=\"checkbox\"\n" +
    "                ng-model=\"$ctrl.data.medianGradSalary.estimate\" value=\"true\">\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-2 question-source p-w-xxs\" ng-if=\"!$ctrl.isClient\">\n" +
    "            <statistics-source source=\"$ctrl.data.medianGradSalary.source\"\n" +
    "                enabled=\"$ctrl.hasAnswers('median_grad_salary')\"></statistics-source>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-1 question-status\">\n" +
    "            <statistics-status-popup status=\"$ctrl.data.medianGradSalary.status\"\n" +
    "                enabled=\"$ctrl.hasAnswers('median_grad_salary')\"></statistics-status-popup>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row question-content clickable\"\n" +
    "         ng-class=\"{'active': $ctrl.isActive('number_of_students_auto')}\"\n" +
    "         ng-click=\"$ctrl.handleFocus('number_of_students_auto')\">\n" +
    "        <div class=\"col-xs-2 question-name no-outline\">\n" +
    "            Percentage (Auto) %\n" +
    "            <i class=\"fa fa-info-circle\"\n" +
    "               uib-tooltip=\"Automatically calculated.\"\n" +
    "               tooltip-class=\"break-word\"\n" +
    "               tooltip-placement=\"top\"></i>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-1\"></div>\n" +
    "        <div class=\"col-xs-1 question-answer p-w-xxs\">\n" +
    "            <input class=\"form-control p-xxs\" type=\"text\" readonly=\"readonly\" ng-model=\"$ctrl.data.percentages.totalRespondents\">\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-1 question-answer p-w-xxs\">\n" +
    "            <input class=\"form-control p-xxs\" type=\"text\" readonly=\"readonly\" ng-model=\"$ctrl.data.percentages.employed\">\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-1 question-answer p-w-xxs\">\n" +
    "            <input class=\"form-control p-xxs\" type=\"text\" readonly=\"readonly\" ng-model=\"$ctrl.data.percentages.unemployed\">\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-1 question-answer p-w-xxs\">\n" +
    "            <input class=\"form-control p-xxs\" type=\"text\" readonly=\"readonly\" ng-model=\"$ctrl.data.percentages.inFurtherStudy\">\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-1 question-answer p-w-xxs\">\n" +
    "            <input class=\"form-control p-xxs\" type=\"text\" readonly=\"readonly\" ng-model=\"$ctrl.data.percentages.unavailable\">\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"row question-content clickable\"\n" +
    "         ng-class=\"{'active': $ctrl.isActive('qs_employment_rate_auto')}\"\n" +
    "         ng-click=\"$ctrl.handleFocus('qs_employment_rate_auto')\">\n" +
    "        <div class=\"col-xs-2 question-name no-outline\">\n" +
    "            QS Employment Rate: (Auto) %\n" +
    "            <i class=\"fa fa-info-circle\"\n" +
    "               uib-tooltip=\"Automatically calculated.\"\n" +
    "               tooltip-class=\"break-word\"\n" +
    "               tooltip-placement=\"top\"></i>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-6 question-answer p-w-xxs m-t-sm\" ng-class=\"{'has-error': $ctrl.isInvalidEmploymentRate() }\">\n" +
    "            <input class=\"form-control p-xxs\" type=\"text\" readonly=\"readonly\" ng-model=\"$ctrl.data.percentages.employmentRate\">\n" +
    "            <span\n" +
    "                focus-delay=\"250\"\n" +
    "                custom-popover\n" +
    "                popover-html='The value entered is significantly different from the previous entry. If correct, please provide an explanation in the messages box <i class=\"fa fa-arrow-right\" aria-hidden=\"true\"></i>'\n" +
    "                popover-placement=\"bottom\"\n" +
    "                popover-visibility=\"{{ $ctrl.isInvalidEmploymentRate() }}\"></span>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-1 question-estimate p-w-xxs m-t-sm\">\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-2 question-source p-w-xxs\" ng-if=\"!$ctrl.isClient\">\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-1 question-status m-t-sm\">\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <!--- -->\n" +
    "    <div class=\"row font-bold question-header\">\n" +
    "        <div class=\"col-xs-4\">{{$ctrl.evidenceHeader}}</div>\n" +
    "        <div class=\"col-xs-3 text-muted p-w-xxs\"></div>\n" +
    "    </div>\n" +
    "    <div class=\"row question-content clickable\"\n" +
    "         ng-class=\"{'active': $ctrl.isActive('evidence_when_shared')}\"\n" +
    "         ng-click=\"$ctrl.handleFocus('evidence_when_shared')\">\n" +
    "        <div class=\"col-xs-2 question-name no-outline\">When was the survey shared with students?</div>\n" +
    "        <div class=\"col-xs-6 question-source p-w-xxs m-t-sm\">\n" +
    "            <ui-select\n" +
    "                ng-model=\"$ctrl.whenShared\"\n" +
    "                on-select=\"$ctrl.handleWhenSharedChanges()\"\n" +
    "                theme=\"bootstrap\">\n" +
    "                <ui-select-match placeholder=\"Please choose one of the options\">{{$select.selected.label}}</ui-select-match>\n" +
    "                <ui-select-choices\n" +
    "                    position=\"down\"\n" +
    "                    ui-disable-choice=\"option.disabled\"\n" +
    "                    repeat=\"option in $ctrl.surveySharedWithStudentsOptions | filter:$select.search\">\n" +
    "                    <div ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                </ui-select-choices>\n" +
    "                <ui-select-no-choice>\n" +
    "                    Not found\n" +
    "                </ui-select-no-choice>\n" +
    "            </ui-select>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-1 question-estimate p-w-xxs m-t-sm\">\n" +
    "            <input i-checkbox ng-click=\"$ctrl.handleFocus('evidence_when_shared')\" type=\"checkbox\" ng-model=\"$ctrl.data.evidence.whenSharedEstimate\" value=\"true\">\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-2 question-source p-w-xxs m-t-sm\" ng-if=\"!$ctrl.isClient\">\n" +
    "            <statistics-source source=\"$ctrl.data.evidence.whenSharedStats.source\" enabled=\"$ctrl.hasAnswers('evidence_when_shared')\"></statistics-source>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-1 question-status m-t-sm\">\n" +
    "            <statistics-status-popup status=\"$ctrl.data.evidence.whenSharedStats.status\" enabled=\"$ctrl.hasAnswers('evidence_when_shared')\"></statistics-status-popup>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"row question-content clickable\"\n" +
    "         ng-class=\"{'active': $ctrl.isActive('evidence_which_year_conducted')}\"\n" +
    "         ng-click=\"$ctrl.handleFocus('evidence_which_year_conducted')\">\n" +
    "        <div class=\"col-xs-2 question-name no-outline\">Which year was the survey conducted?</div>\n" +
    "        <div class=\"col-xs-6 question-source p-w-xxs m-t-sm\">\n" +
    "            <ui-select\n" +
    "                ng-model=\"$ctrl.whichYearConducted\"\n" +
    "                on-select=\"$ctrl.handleWhichYearConductedChanges()\"\n" +
    "                theme=\"bootstrap\">\n" +
    "                <ui-select-match placeholder=\"Please choose one of the options\">{{$select.selected.label}}</ui-select-match>\n" +
    "                <ui-select-choices\n" +
    "                    position=\"down\"\n" +
    "                    ui-disable-choice=\"option.disabled\"\n" +
    "                    repeat=\"option in $ctrl.yearsOptions | filter:$select.search\">\n" +
    "                    <div ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                </ui-select-choices>\n" +
    "                <ui-select-no-choice>\n" +
    "                    Not found\n" +
    "                </ui-select-no-choice>\n" +
    "            </ui-select>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-1 question-estimate p-w-xxs m-t-sm\">\n" +
    "            <input i-checkbox ng-click=\"$ctrl.handleFocus('evidence_which_year_conducted')\" type=\"checkbox\" ng-model=\"$ctrl.data.evidence.whichYearConductedEstimate\" value=\"true\">\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-2 question-source p-w-xxs m-t-sm\" ng-if=\"!$ctrl.isClient\">\n" +
    "            <statistics-source source=\"$ctrl.data.evidence.whichYearConductedStats.source\" enabled=\"$ctrl.hasAnswers('evidence_which_year_conducted')\"></statistics-source>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-1 question-status m-t-sm\">\n" +
    "            <statistics-status-popup status=\"$ctrl.data.evidence.whichYearConductedStats.status\" enabled=\"$ctrl.hasAnswers('evidence_which_year_conducted')\"></statistics-status-popup>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"row question-content clickable\"\n" +
    "         ng-class=\"{'active': $ctrl.isActive('evidence_link_or_screenshot')}\"\n" +
    "         ng-click=\"$ctrl.handleFocus('evidence_link_or_screenshot')\">\n" +
    "        <div class=\"col-xs-2 question-name no-outline\">\n" +
    "            Link or screenshot of report evidencing these numbers:\n" +
    "            <i class=\"fa fa-info-circle\"\n" +
    "            uib-tooltip=\"Only PDFs, jpeg, png, csv, xlsx, doc, or docx files no bigger than 10 MB are accepted.\"\n" +
    "            tooltip-class=\"break-word\"\n" +
    "            tooltip-placement=\"top\"></i>\n" +
    "        </div>\n" +
    "        <div class=\"question-answer p-w-xxs m-t-sm\"\n" +
    "             ng-class=\"{'modal-overlay-35': $ctrl.uploadInProgress,'col-xs-2':!$ctrl.uploadEnabled , 'col-xs-1':$ctrl.uploadEnabled}\">\n" +
    "            <form class=\"clearfix\"\n" +
    "                  name=\"$ctrl.forms.uploadForm\"\n" +
    "                  enctype=\"multipart/form-data\"\n" +
    "                  ng-dropzone\n" +
    "                  dropzone=\"$ctrl.dropZoneInstance\"\n" +
    "                  dropzone-config=\"$ctrl.config.dropzone\"\n" +
    "                  event-handlers=\"$ctrl.config.eventHandlers\"\n" +
    "                  novalidate\n" +
    "                  focus-delay=\"250\">\n" +
    "                <div class=\"fallback form-group\" ng-class=\"{'has-errors': $ctrl.isImageBoxEmpty}\">\n" +
    "                    <input name=\"file\" type=\"file\"/>\n" +
    "                </div>\n" +
    "                <div class=\"btn btn-default btn-outline btn-sm pull-left dz-message\" ng-show=\"$ctrl.uploadEnabled\">\n" +
    "                    <i class=\"fa fa-upload\"></i> Upload\n" +
    "                </div>\n" +
    "            </form>\n" +
    "            <div class=\"mt-5px file-name\" ng-if=\"!$ctrl.uploadEnabled && $ctrl.upload\">\n" +
    "                <a href=\"{{ $ctrl.upload.path }}\" target=\"_blank\" class=\"no-text-transform inline\">\n" +
    "                    <span class=\"label label-warning-light\">{{ $ctrl.upload.name }}</span>\n" +
    "                </a>\n" +
    "                <button ng-click=\"$ctrl.handleRemove()\" class=\"btn btn-xs btn-danger\">\n" +
    "                    <i class=\"fa fa-times\"></i>\n" +
    "                </button>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-1 question-answer m-t text-center p-l-30\">\n" +
    "            OR\n" +
    "        </div>\n" +
    "        <div class=\"question-answer no-padding m-t-sm\"\n" +
    "        ng-class=\"{'col-xs-2':!$ctrl.uploadEnabled , 'col-xs-3':$ctrl.uploadEnabled}\">\n" +
    "            <input class=\"form-control p-xxs\" ng-model=\"$ctrl.data.evidence.url\" type=\"text\" placeholder=\"http://\" ng-keyup=\"$ctrl.onKeyUp($event)\">\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-2 question-answer p-l-none  m-t-sm\" ng-class=\"{'modal-overlay-35': !$ctrl.data.evidence.url}\">\n" +
    "            <a class=\"btn btn-default btn-sm m-b-xs ml-10\" target=\"_blank\" href=\"{{$ctrl.data.evidence.url}}\">\n" +
    "                <i class=\"fa fa-share\"></i> Open link\n" +
    "            </a>\n" +
    "        </div>\n" +
    "        <!-- <div class=\"col-xs-1 question-estimate p-w-xxs m-t-sm\" ng-show=\"$ctrl.uploadEnabled\">\n" +
    "        </div> -->\n" +
    "        <div class=\"col-xs-2 question-source p-w-xxs m-t-sm\" ng-if=\"!$ctrl.isClient\">\n" +
    "            <statistics-source source=\"$ctrl.data.evidence.urlStats.source\" enabled=\"$ctrl.hasAnswers('evidence_link_or_screenshot')\"></statistics-source>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-1 question-status m-t-sm\">\n" +
    "            <statistics-status-popup status=\"$ctrl.data.evidence.urlStats.status\" enabled=\"$ctrl.hasAnswers('evidence_link_or_screenshot')\"></statistics-status-popup>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <!--- -->\n" +
    "    <div class=\"row font-bold question-header\" ng-if=\"!$ctrl.isOtherRanking\">\n" +
    "        <div class=\"col-xs-4\">Additional Data - Entrepreneurship</div>\n" +
    "    </div>\n" +
    "    <div ng-if=\"!$ctrl.isOtherRanking\" class=\"row question-content clickable\"\n" +
    "         ng-class=\"{'active': $ctrl.isActive('number_of_students_entrepreneurship')}\"\n" +
    "         ng-click=\"$ctrl.handleFocus('number_of_students_entrepreneurship')\">\n" +
    "        <div class=\"col-xs-2 question-name no-outline\">\n" +
    "            Number of Students who started own business after graduation\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-6 question-answer p-w-xxs m-t-sm\" ng-class=\"{'has-error': $ctrl.isGreater($ctrl.data.numberOfStudents.entrepreneurship) }\">\n" +
    "            <input class=\"form-control p-xxs\" type=\"text\"\n" +
    "                   ng-model=\"$ctrl.data.numberOfStudents.entrepreneurship\"\n" +
    "                   ng-change=\"$ctrl.calculatePercentages()\"\n" +
    "                   is-valid-int>\n" +
    "            <span\n" +
    "                focus-delay=\"250\"\n" +
    "                custom-popover\n" +
    "                popover-html=\"This can't be greater than 'Total graduated students' or 'Total respondents'\"\n" +
    "                popover-placement=\"bottom\"\n" +
    "                popover-trigger=\"hover\"\n" +
    "                popover-visibility=\"{{ $ctrl.isGreater($ctrl.data.numberOfStudents.entrepreneurship) }}\"></span>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-1 question-estimate p-w-xxs m-t-sm\">\n" +
    "            <input i-checkbox ng-click=\"$ctrl.handleFocus('number_of_students_entrepreneurship')\" type=\"checkbox\" ng-model=\"$ctrl.data.numberOfStudents.entrepreneurshipEstimate\" value=\"true\">\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-2 question-source p-w-xxs m-t-sm\" ng-if=\"!$ctrl.isClient\">\n" +
    "            <statistics-source source=\"$ctrl.data.numberOfStudents.entrepreneurshipStats.source\" enabled=\"$ctrl.hasAnswers('number_of_students_entrepreneurship')\"></statistics-source>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-1 question-status m-t-sm\">\n" +
    "            <statistics-status-popup status=\"$ctrl.data.numberOfStudents.entrepreneurshipStats.status\" enabled=\"$ctrl.hasAnswers('number_of_students_entrepreneurship')\"></statistics-status-popup>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div ng-if=\"!$ctrl.isOtherRanking\" class=\"row question-content clickable\"\n" +
    "         ng-class=\"{'active': $ctrl.isActive('number_of_students_entrepreneurship_auto')}\"\n" +
    "         ng-click=\"$ctrl.handleFocus('number_of_students_entrepreneurship_auto')\">\n" +
    "        <div class=\"col-xs-2 question-name no-outline\">\n" +
    "			Percentage (Auto) %\n" +
    "			<i class=\"fa fa-info-circle\"\n" +
    "            uib-tooltip=\"Automatically calculated.\"\n" +
    "            tooltip-class=\"break-word\"\n" +
    "            tooltip-placement=\"top\"></i>\n" +
    "		</div>\n" +
    "        <div class=\"col-xs-6 question-answer p-w-xxs\">\n" +
    "            <input class=\"form-control p-xxs\" type=\"text\" readonly=\"readonly\" ng-model=\"$ctrl.data.percentages.entrepreneurship\">\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-1 question-estimate p-w-xxs\">\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-2 question-source p-w-xxs\" ng-if=\"!$ctrl.isClient\">\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-1 question-status\">\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <button class=\"btn btn-primary btn-sm pull-right submit\" ng-disabled=\"$ctrl.isSavingDisabled()\" ng-click=\"$ctrl.handleSaveClick()\">\n" +
    "        <i class=\"fa fa-check-circle\"></i> Save\n" +
    "    </button>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/ger/employmentRateLog/employmentRateLogView.html',
    "<div class=\"row\" ng-if=\"handle\">\n" +
    "    <div ng-if=\"handle === 'number_of_students'\" class=\"col-xs-12\">\n" +
    "        <span>Total graduated students</span> : {{ data.answers.totalGraduatedStudents.value }}<br/>\n" +
    "        <span>Total respondents</span> : {{ data.answers.totalRespondents.value }}<br/>\n" +
    "        <span>Employed FT and/or PT</span> : {{ data.answers.employed.value }}<br/>\n" +
    "        <span>Unemployed but seeking employment</span> : {{ data.answers.unemployed.value }}<br/>\n" +
    "        <span>In FT further study</span> : {{ data.answers.inFurtherStudy.value }}<br/>\n" +
    "        <span>Unavailable for work (e.g. military service)</span> : {{ data.answers.unavailable.value }}<br/>\n" +
    "        <span>Estimate</span> : {{ data.answers.totalGraduatedStudents.estimate ? \"Yes\": \"No\"  }}\n" +
    "    </div>\n" +
    "    <div ng-if=\"handle === 'number_of_students_auto'\" class=\"col-xs-12\">\n" +
    "        <span>Total respondents</span> : {{ data.answers.totalRespondentsAuto.value }}<br>\n" +
    "        <span>Employed FT and/or PT</span> : {{ data.answers.employedAuto.value }}<br>\n" +
    "        <span>Unemployed but seeking employment</span> : {{ data.answers.unemployedAuto.value }}<br>\n" +
    "        <span>In FT further study</span> : {{ data.answers.inFurtherStudyAuto.value }}<br>\n" +
    "        <span>Unavailable for work (e.g. military service)</span> : {{ data.answers.unavailableAuto.value }}\n" +
    "    </div>\n" +
    "    <div ng-if=\"handle === 'qs_employment_rate_auto'\" class=\"col-xs-12\">\n" +
    "        <span>QS Employment Rate: (Auto) %</span> : {{ data.answers.employmentRate.value }}<br/>\n" +
    "    </div>\n" +
    "    <div ng-if=\"handle === 'median_grad_salary'\" class=\"col-xs-12\">\n" +
    "        <span>Median Grad Salary</span> : {{ data.answers.salary.value }}<br/>\n" +
    "        <span>Estimate</span> : {{ data.answers.salary.estimate ? \"Yes\": \"No\"  }}\n" +
    "    </div>\n" +
    "    <div ng-if=\"handle === 'evidence_when_shared'\" class=\"col-xs-12\">\n" +
    "        <span>When was the survey shared with students?</span> : {{ data.answers.whenShared.value }}<br/>\n" +
    "        <span>Estimate</span> : {{ data.answers.whenShared.estimate ? \"Yes\": \"No\"  }}\n" +
    "    </div>\n" +
    "    <div ng-if=\"handle === 'evidence_which_year_conducted'\" class=\"col-xs-12\">\n" +
    "        <span>Which year was the survey conducted?</span> : {{ data.answers.whichYearConducted.value }}<br/>\n" +
    "        <span>Estimate</span> : {{ data.answers.whichYearConducted.estimate ? \"Yes\": \"No\"  }}\n" +
    "    </div>\n" +
    "    <div ng-if=\"handle === 'evidence_link_or_screenshot'\" class=\"col-xs-12\">\n" +
    "        <span class=\"download\" ng-if=\"data.answers.url.value\">\n" +
    "            <a class=\"icon clickable\" target=\"_blank\" href=\"{{data.answers.url.value}}\">\n" +
    "                <i class=\"fa fa-download\"></i>\n" +
    "            </a>\n" +
    "        </span>\n" +
    "        <span class=\"icon clickable\" ng-if=\"data.answers.url.value\">\n" +
    "            <i clipboard supported=\"supported\" text=\"data.answers.url.value\" class=\"fa fa-link\"></i>\n" +
    "        </span>\n" +
    "    </div>\n" +
    "    <div ng-if=\"handle === 'number_of_students_entrepreneurship'\" class=\"col-xs-12\">\n" +
    "        <span>Number of Students who started own business after graduation</span> : {{ data.answers.entrepreneurship.value }}<br/>\n" +
    "        <span>Estimate</span> : {{ data.answers.entrepreneurship.estimate ? \"Yes\": \"No\"  }}\n" +
    "    </div>\n" +
    "    <div ng-if=\"handle === 'number_of_students_entrepreneurship_auto'\" class=\"col-xs-12\">\n" +
    "        <span>Number of Students who started own business after graduation (Auto) %</span> : {{ data.answers.entrepreneurshipAuto.value }}<br/>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/ger/question/gerQuestionView.html',
    "<div class=\"row question-content clickable\"\n" +
    "     ng-class=\"{'active': $ctrl.isActive($ctrl.handle)}\"\n" +
    "     ng-click=\"$ctrl.handleFocus($ctrl.handle)\">\n" +
    "    <div class=\"col-xs-4 question-name no-outline\">{{$ctrl.title}}</div>\n" +
    "    <div class=\"col-xs-2 question-answer p-w-xxs\">\n" +
    "        <!-- <input class=\"form-control p-xxs\" type=\"text\" is-valid-int\n" +
    "               ng-model=\"$ctrl.current.value\"/> -->\n" +
    "            <input class=\"form-control p-xxs\" ng-model=\"$ctrl.current.value\"  \n" +
    "                validate-data-submission \n" +
    "                validate-data-submission-value=\"{{$ctrl.current.value}}\"\n" +
    "                validate-data-submission-original=\"{{$ctrl.answersBeforeChanges.value}}\"\n" +
    "                validate-data-submission-rules=\"{{$ctrl.validators}}\"\n" +
    "                validate-data-submission-Question=\"{{$ctrl.isExplanationProvided}}\"\n" +
    "           />\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-2 question-source p-w-xxs\" ng-if=\"!$ctrl.isClient\">\n" +
    "        <statistics-source source=\"$ctrl.current.source\" enabled=\"$ctrl.hasAnswers()\"></statistics-source>\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-1 question-status\">\n" +
    "        <statistics-status-popup status=\"$ctrl.current.status\" enabled=\"$ctrl.hasAnswers()\"></statistics-status-popup>\n" +
    "    </div>\n" +
    "</div>\n" +
    "<div class=\"clearfix row m-t\">\n" +
    "    <div class=\"col-lg-12\">\n" +
    "        <p class=\"disregard-formatting m-b\">\n" +
    "            <span>Please double check that your list contains only distinct companies which meet the following criteria</span>\n" +
    "            <a ng-href=\"https://support.qs.com/hc/en-gb/articles/4405356758418\" target=\"_blank\">\n" +
    "                <span>Employer Student Partnerships</span>\n" +
    "            </a>\n" +
    "        </p>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/ger/questionHeader/gerQuestionHeaderView.html',
    "<div class=\"row font-bold question-header\">\n" +
    "    <div class=\"col-xs-4\">\n" +
    "\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-2\">\n" +
    "        Value *\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-2\" ng-if=\"!$ctrl.isClient\">\n" +
    "        Source\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-1\">\n" +
    "        Status\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/modules/rankings/components/ger/upload/gerUploadView.html',
    "<div class=\"row font-bold border-top-grey m-3px\">\n" +
    "    <div class=\"col-xs-12 m-t\">Provide evidence below for the data submitted above</div>\n" +
    "</div>\n" +
    "<div class=\"row general-tab question-content clickable\"\n" +
    "     ng-class=\"{'active': $ctrl.isActive($ctrl.handle)}\"\n" +
    "     ng-click=\"$ctrl.handleFocus($ctrl.handle)\">\n" +
    "    <div class=\"control-label question-name no-outline col-xs-4\">\n" +
    "        Upload data file\n" +
    "        <span class=\"clickable fa fa-info-circle\"\n" +
    "              uib-tooltip=\"Only CSV files are accepted & up to {{ $ctrl.maxSize }}MB in size\"\n" +
    "              tooltip-placement=\"top\">\n" +
    "        </span> :\n" +
    "        <br>\n" +
    "        <a class=\"btn btn-xs btn-default\" target=\"_self\" href=\"{{$ctrl.getDataTemplateUrl()}}\">Download template</a>\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-8 p-w-xxs m-t-sm\">\n" +
    "        <div class=\"clearfix\"\n" +
    "             ng-show=\"$ctrl.showUpload()\"\n" +
    "             ng-class=\"{'modal-overlay-35': $ctrl.uploadInProgress}\"\n" +
    "             name=\"$ctrl.forms.uploadForm\"\n" +
    "             enctype=\"multipart/form-data\"\n" +
    "             ng-dropzone\n" +
    "             dropzone=\"$ctrl.dropZoneInstance\"\n" +
    "             dropzone-config=\"$ctrl.config.dropzone\"\n" +
    "             event-handlers=\"$ctrl.config.eventHandlers\"\n" +
    "             novalidate\n" +
    "             dz-hidden-input-file>\n" +
    "            <div class=\"fallback form-group\">\n" +
    "                <input name=\"file\" type=\"file\"/>\n" +
    "            </div>\n" +
    "            <button class=\"btn btn-default btn-outline btn-sm dz-message\">\n" +
    "                <i class=\"fa fa-upload\"></i> Upload\n" +
    "            </button>\n" +
    "        </div>\n" +
    "        <div class=\"clearfix\" ng-show=\"!$ctrl.showUpload()\">\n" +
    "            <button ng-click=\"$ctrl.removeFile()\" class=\"btn btn-xs btn-danger\">\n" +
    "                {{ $ctrl.uploadedFileName }} <i class=\"fa fa-times\"></i>\n" +
    "            </button>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/idChecker/idCheckerView.html',
    "<div  class=\"wrapper wrapper-content animated fadeInRight page-rankings-id-checker\">\n" +
    "    <div class=\"row\">\n" +
    "        <div class=\"section\">\n" +
    "            <div class=\"section-header row\">\n" +
    "                <summernote\n" +
    "                    placeholder=\" Copy & Paste Core ids comma separated from rankings file\"\n" +
    "                    ng-model=\"IdCheckerController.ids\"\n" +
    "                    config=\"IdCheckerController.textEditorOptions\">\n" +
    "                </summernote>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col-lg-3\">\n" +
    "                    <button type=\"button\" class=\"btn btn-block btn-primary\"\n" +
    "                            ng-click=\"IdCheckerController.handleClick()\"\n" +
    "                            ng-disabled=\"IdCheckerController.isDisabled()\">\n" +
    "                        <i class=\"fa fa-clock-o\"></i> Check\n" +
    "                    </button>\n" +
    "                </div>\n" +
    "                <div class=\"col-lg-3 m-xs m-l-none\">\n" +
    "                    <switch class=\"green\" ng-model=\"IdCheckerController.tmPrograms\" ng-disabled=\"IdCheckerController.isDisabled()\"></switch>TM Programs\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row\" ng-if=\"IdCheckerController.results\" ng-hide=\"IdCheckerController.tmPrograms\">\n" +
    "        <div class=\"col-lg-4\">\n" +
    "            <div class=\"ibox float-e-margins\">\n" +
    "                <div class=\"ibox-title\">\n" +
    "                    <h5>Inactive Institutions - {{IdCheckerController.results.inactive.length}}</h5>\n" +
    "                    <div class=\"ibox-tools\">\n" +
    "                        <a class=\"collapse-link\">\n" +
    "                            <i class=\"fa fa-chevron-up\"></i>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"ibox-content\">\n" +
    "                    <code class=\"white-space-normal\">{{IdCheckerController.results.inactive | json}}</code>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"col-lg-4\">\n" +
    "            <div class=\"ibox float-e-margins\">\n" +
    "                <div class=\"ibox-title\">\n" +
    "                    <h5>Missing Country Code - {{IdCheckerController.results.missingCountryCode.length}}</h5>\n" +
    "                    <div class=\"ibox-tools\">\n" +
    "                        <a class=\"collapse-link\">\n" +
    "                            <i class=\"fa fa-chevron-up\"></i>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"ibox-content\">\n" +
    "                    <code class=\"white-space-normal\">{{IdCheckerController.results.missingCountryCode | json}}</code>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"col-lg-4\">\n" +
    "            <div class=\"ibox float-e-margins\">\n" +
    "                <div class=\"ibox-title\">\n" +
    "                    <h5>TU Master/Overview profile not published - {{IdCheckerController.results.missingMasterNid.length}}</h5>\n" +
    "                    <div class=\"ibox-tools\">\n" +
    "                        <a class=\"collapse-link\">\n" +
    "                            <i class=\"fa fa-chevron-up\"></i>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"ibox-content\">\n" +
    "                    <code class=\"white-space-normal\">{{IdCheckerController.results.missingMasterNid | json}}</code>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"col-lg-4\">\n" +
    "            <div class=\"ibox float-e-margins\">\n" +
    "                <div class=\"ibox-title\">\n" +
    "                    <h5>Missing Small Logo - {{IdCheckerController.results.missingSmallLogo.length}}</h5>\n" +
    "                    <div class=\"ibox-tools\">\n" +
    "                        <a class=\"collapse-link\">\n" +
    "                            <i class=\"fa fa-chevron-up\"></i>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"ibox-content\">\n" +
    "                    <code class=\"white-space-normal\">{{IdCheckerController.results.missingSmallLogo | json}}</code>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"col-lg-4\">\n" +
    "            <div class=\"ibox float-e-margins\">\n" +
    "                <div class=\"ibox-title\">\n" +
    "                    <h5>TU Master/Overview profile missing basic overview - {{IdCheckerController.results.missingTuMasterBasicOverview.length}}</h5>\n" +
    "                    <div class=\"ibox-tools\">\n" +
    "                        <a class=\"collapse-link\">\n" +
    "                            <i class=\"fa fa-chevron-up\"></i>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"ibox-content\">\n" +
    "                    <code class=\"white-space-normal\">{{IdCheckerController.results.missingTuMasterBasicOverview| json}}</code>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"col-lg-4\">\n" +
    "            <div class=\"ibox float-e-margins\">\n" +
    "                <div class=\"ibox-title\">\n" +
    "                    <h5>TU Master/Overview profile missing advanced overview - {{IdCheckerController.results.missingTuMasterAdvancedOverview.length}}</h5>\n" +
    "                    <div class=\"ibox-tools\">\n" +
    "                        <a class=\"collapse-link\">\n" +
    "                            <i class=\"fa fa-chevron-up\"></i>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"ibox-content\">\n" +
    "                    <code class=\"white-space-normal\">{{IdCheckerController.results.missingTuMasterAdvancedOverview| json}}</code>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"col-lg-4\">\n" +
    "            <div class=\"ibox float-e-margins\">\n" +
    "                <div class=\"ibox-title\">\n" +
    "                    <h5>Missing TU Subscription - {{IdCheckerController.results.missingTuSubscription.length}}</h5>\n" +
    "                    <div class=\"ibox-tools\">\n" +
    "                        <a class=\"collapse-link\">\n" +
    "                            <i class=\"fa fa-chevron-up\"></i>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"ibox-content\">\n" +
    "                    <code class=\"white-space-normal\">{{IdCheckerController.results.missingTuSubscription | json}}</code>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"col-lg-4\">\n" +
    "            <div class=\"ibox float-e-margins\">\n" +
    "                <div class=\"ibox-title\">\n" +
    "                    <h5>TM Master/Overview profile missing basic overview - {{IdCheckerController.results.missingTmMasterBasicOverview.length}}</h5>\n" +
    "                    <div class=\"ibox-tools\">\n" +
    "                        <a class=\"collapse-link\">\n" +
    "                            <i class=\"fa fa-chevron-up\"></i>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"ibox-content\">\n" +
    "                    <code class=\"white-space-normal\">{{IdCheckerController.results.missingTmMasterBasicOverview| json}}</code>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"col-lg-4\">\n" +
    "            <div class=\"ibox float-e-margins\">\n" +
    "                <div class=\"ibox-title\">\n" +
    "                    <h5>TM Master/Overview profile missing advanced overview - {{IdCheckerController.results.missingTmMasterAdvancedOverview.length}}</h5>\n" +
    "                    <div class=\"ibox-tools\">\n" +
    "                        <a class=\"collapse-link\">\n" +
    "                            <i class=\"fa fa-chevron-up\"></i>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"ibox-content\">\n" +
    "                    <code class=\"white-space-normal\">{{IdCheckerController.results.missingTmMasterAdvancedOverview| json}}</code>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"col-lg-4\">\n" +
    "            <div class=\"ibox float-e-margins\">\n" +
    "                <div class=\"ibox-title\">\n" +
    "                    <h5>TM Overview profile not published - {{IdCheckerController.results.missingTmNid.length}}</h5>\n" +
    "                    <div class=\"ibox-tools\">\n" +
    "                        <a class=\"collapse-link\">\n" +
    "                            <i class=\"fa fa-chevron-up\"></i>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"ibox-content\">\n" +
    "                    <code class=\"white-space-normal\">{{IdCheckerController.results.missingTmNid | json}}</code>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"col-lg-4\">\n" +
    "            <div class=\"ibox float-e-margins\">\n" +
    "                <div class=\"ibox-title\">\n" +
    "                    <h5>Missing TM Subscription - {{IdCheckerController.results.missingTmSubscription.length}}</h5>\n" +
    "                    <div class=\"ibox-tools\">\n" +
    "                        <a class=\"collapse-link\">\n" +
    "                            <i class=\"fa fa-chevron-up\"></i>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"ibox-content\">\n" +
    "                    <code class=\"white-space-normal\">{{IdCheckerController.results.missingTmSubscription | json}}</code>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row\" ng-if=\"IdCheckerController.results\" ng-show=\"IdCheckerController.tmPrograms\">\n" +
    "        <div class=\"col-lg-4\">\n" +
    "            <div class=\"ibox float-e-margins\">\n" +
    "                <div class=\"ibox-title\">\n" +
    "                    <h5>Inactive Programs - {{IdCheckerController.results.inActivePrograms.length}}</h5>\n" +
    "                    <div class=\"ibox-tools\">\n" +
    "                        <a class=\"collapse-link\">\n" +
    "                            <i class=\"fa fa-chevron-up\"></i>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"ibox-content\">\n" +
    "                    <code class=\"white-space-normal\">{{IdCheckerController.results.inActivePrograms | json}}</code>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"col-lg-4\">\n" +
    "            <div class=\"ibox float-e-margins\">\n" +
    "                <div class=\"ibox-title\">\n" +
    "                    <h5>Programs not published - {{IdCheckerController.results.missingTmProgramNid.length}}</h5>\n" +
    "                    <div class=\"ibox-tools\">\n" +
    "                        <a class=\"collapse-link\">\n" +
    "                            <i class=\"fa fa-chevron-up\"></i>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"ibox-content\">\n" +
    "                    <code class=\"white-space-normal\">{{IdCheckerController.results.missingTmProgramNid | json}}</code>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"col-lg-4\">\n" +
    "            <div class=\"ibox float-e-margins\">\n" +
    "                <div class=\"ibox-title\">\n" +
    "                    <h5>Program profile not published - {{IdCheckerController.results.missingAdvancedTmProgramNid.length}}</h5>\n" +
    "                    <div class=\"ibox-tools\">\n" +
    "                        <a class=\"collapse-link\">\n" +
    "                            <i class=\"fa fa-chevron-up\"></i>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"ibox-content\">\n" +
    "                    <code class=\"white-space-normal\">{{IdCheckerController.results.missingAdvancedTmProgramNid | json}}</code>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"col-lg-4\">\n" +
    "            <div class=\"ibox float-e-margins\">\n" +
    "                <div class=\"ibox-title\">\n" +
    "                    <h5>Missing program overview - {{IdCheckerController.results.missingProgramDescription.length}}</h5>\n" +
    "                    <div class=\"ibox-tools\">\n" +
    "                        <a class=\"collapse-link\">\n" +
    "                            <i class=\"fa fa-chevron-up\"></i>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"ibox-content\">\n" +
    "                    <code class=\"white-space-normal\">{{IdCheckerController.results.missingProgramDescription | json}}</code>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"col-lg-4\">\n" +
    "            <div class=\"ibox float-e-margins\">\n" +
    "                <div class=\"ibox-title\">\n" +
    "                    <h5>Missing profile advanced overview (advanced programs only) - {{IdCheckerController.results.missingAdvancedTmProgramOverview.length}}</h5>\n" +
    "                    <div class=\"ibox-tools\">\n" +
    "                        <a class=\"collapse-link\">\n" +
    "                            <i class=\"fa fa-chevron-up\"></i>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"ibox-content\">\n" +
    "                    <code class=\"white-space-normal\">{{IdCheckerController.results.missingAdvancedTmProgramOverview | json}}</code>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/nominations/components/client/nominationsListClientUpload/nominationsListClientUploadLogs/nominationsListClientUploadLogsView.html',
    "<div class=\"ibox\">\n" +
    "    <div class=\"ibox-title\">\n" +
    "        <h5>Contact Lists Upload Logs</h5>\n" +
    "    </div>\n" +
    "    <div class=\"ibox-content publish-logs\">\n" +
    "        <div wave-spinner class=\"text-right\" ng-show=\"$ctrl.isFetchInProgress()\"></div>\n" +
    "\n" +
    "        <div ng-show=\"!$ctrl.isFetchInProgress()\">\n" +
    "            <b ng-show=\"!$ctrl.logsList\">No results</b>\n" +
    "\n" +
    "            <div ng-show=\"$ctrl.logsList\">\n" +
    "                <div ng-repeat=\"logsPerDay in $ctrl.logs\" class=\"record border-bottom m-b\">\n" +
    "                    <div class=\"panel panel-default\">\n" +
    "                        <div class=\"panel-heading\">\n" +
    "                            <button type=\"button\"\n" +
    "                                ng-if=\"$index === 0 && !$ctrl.isClient()\"\n" +
    "                                ng-disabled=\"$ctrl.isDeleteButtonDisabled()\"\n" +
    "                                ng-class=\"{'disabled': $ctrl.isDeleteButtonDisabled()}\"\n" +
    "                                class=\"btn btn-danger btn-sm m-l-n-xs\"\n" +
    "                                ng-click=\"$ctrl.handleDeleteClick()\">\n" +
    "                                <span class=\"glyphicon glyphicon-trash\"></span>\n" +
    "                            </button>\n" +
    "                        \n" +
    "                            <span ng-class=\"{'m-l': $index === 0 && !$ctrl.isClient()}\">{{logsPerDay[0].createdAt | date:'mediumDate'}}</span>\n" +
    "                        </div>\n" +
    "                        <div class=\"panel-body\">\n" +
    "                            <ul class=\"list-unstyled\">\n" +
    "                                <li ng-repeat=\"item in logsPerDay | orderBy : '-createdAt'\">\n" +
    "                                    <input ng-model=\"$ctrl.itemsToDelete[item.id]\" ng-if=\"!$ctrl.isClient()\" i-checkbox type=\"checkbox\">\n" +
    "                                    <span class=\"icon text-success\">\n" +
    "                                        <i class=\"fa fa-check\"></i>\n" +
    "                                    </span>\n" +
    "                                    <span class=\"bold\">{{ item.filterType | strReplace:'_':' ' | strReplace:'([A-Z])':' $1' | capitalize }}</span> contact details file <b>{{item.fileName}}</b> uploaded by <span class=\"bold\">{{ item.createdByFullName }}</span> at <small class=\"time\">{{ item.createdAt | date:'shortTime'}}</small>\n" +
    "                                </li>\n" +
    "                            </ul>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"row\" ng-show=\"$ctrl.isMoreLogsAvailable()\">\n" +
    "                    <div class=\"col-lg-2 col-lg-offset-5\">\n" +
    "                        <a class=\"btn btn-primary btn-block\"\n" +
    "                            ng-click=\"$ctrl.handleLoadMore()\">\n" +
    "                            Load more\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/modules/rankings/components/nominations/components/client/nominationsListClientUpload/nominationsListClientUploadView.html',
    "<div  class=\"wrapper wrapper-content animated fadeInRight page-nomination-client\">\n" +
    "    <div class=\"ibox\">\n" +
    "        <div class=\"ibox-title form-group\">\n" +
    "            <h5> Please Upload Contact Lists\n" +
    "                <i class=\"fa fa-info-circle\"\n" +
    "                    uib-tooltip=\"Only CSV files are accepted\"\n" +
    "                    tooltip-placement=\"top\">\n" +
    "                </i>\n" +
    "            </h5>\n" +
    "        </div>\n" +
    "        <div class=\"ibox-content\">\n" +
    "            <div class=\"form-horizontal m-b-none\">\n" +
    "                <div class=\"form-group no-margins\">\n" +
    "                    <label class=\"col-lg-2 col-sm-3 control-label\">\n" +
    "                        Select document type\n" +
    "                        <br>\n" +
    "                        <small class=\"text-muted\">Maximum size {{NominationsListClientUploadController.maxSize }}MB</small>\n" +
    "                    </label>\n" +
    "\n" +
    "                    <div class=\"col-lg-3 col-sm-3\">\n" +
    "                        <ui-select\n" +
    "                            close-on-select=\"true\"\n" +
    "                            name=\"files\"\n" +
    "                            ng-required=\"true\"\n" +
    "                            ng-model=\"NominationsListClientUploadController.fileType\"\n" +
    "                            on-select=\"NominationsListClientUploadController.generateUrl()\"\n" +
    "                            theme=\"bootstrap\">\n" +
    "                            <ui-select-match placeholder=\"Select Type File\">{{$select.selected.name}}</ui-select-match>\n" +
    "                            <ui-select-choices\n" +
    "                                position=\"down\"\n" +
    "                                repeat=\"option in NominationsListClientUploadController.fileTypes | filter:$select.search track by option.handle\">\n" +
    "                                <div ng-bind-html=\"option.name | highlight: $select.search\"></div>\n" +
    "                            </ui-select-choices>\n" +
    "                            <ui-select-no-choice>\n" +
    "                                Not found\n" +
    "                            </ui-select-no-choice>\n" +
    "                        </ui-select>\n" +
    "                    </div>\n" +
    "\n" +
    "                    <div ng-class=\"{'col-lg-1 col-sm-3': NominationsListClientUploadController.uploadEnabled , 'col-lg-3 col-sm-3': !NominationsListClientUploadController.uploadEnabled, }\" ng-show=\"NominationsListClientUploadController.fileType\">\n" +
    "                        <form class=\"clearfix\"\n" +
    "                            name=\"NominationsListClientUploadController.forms.uploadForm\"\n" +
    "                            enctype=\"multipart/form-data\"\n" +
    "                            ng-dropzone\n" +
    "                            dropzone=\"NominationsListClientUploadController.dropZoneInstance\"\n" +
    "                            dropzone-config=\"NominationsListClientUploadController.uploadConfig.dropzone\"\n" +
    "                            event-handlers=\"NominationsListClientUploadController.uploadConfig.eventHandlers\"\n" +
    "                            novalidate\n" +
    "                            focus-delay=\"250\"\n" +
    "                            dz-hidden-input-file\n" +
    "                            upload-enabled=\"{{!NominationsListClientUploadController.uploadEnabled ? true : false}}\">\n" +
    "\n" +
    "                            <div class=\"btn btn-default btn-outline btn-sm pull-left dz-message\" ng-show=\"NominationsListClientUploadController.uploadEnabled\">\n" +
    "                                <i class=\"fa fa-upload\"></i> Upload\n" +
    "                            </div>\n" +
    "                        </form>\n" +
    "                        <br>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-lg-4 col-sm-6\" >\n" +
    "                        <a class=\"btn btn-default btn-xs\" ng-show=\"NominationsListClientUploadController.fileType\"\n" +
    "                            ng-href=\"{{NominationsListClientUploadController.fileType.handle === 'academic' ?\n" +
    "                            'https://files-core.qs.com/nominations/academic_template_V3.csv' :\n" +
    "                            'https://files-core.qs.com/nominations/employer_template_2024_V1.csv'}}\"\n" +
    "                            target=\"_blank\" >\n" +
    "                            Download template\n" +
    "                        </a>\n" +
    "                        <a class=\"btn btn-default btn-xs\"\n" +
    "                            ng-href=\"https://s3.amazonaws.com/files.core.qs.com/nominations/QS-Rankings-How-to-upload-academic-and-employer-contacts-to-HUB-guide-v7.pdf\"\n" +
    "                            target=\"_blank\" >\n" +
    "                            Download instructions\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"form-group no-margins\">\n" +
    "                    <div class=\"col-lg-offset-2 col-md-10 m-t\">\n" +
    "                       <input  i-checkbox type=\"checkbox\" ng-model=\"NominationsListClientUploadController.isGdprChecked\" />\n" +
    "                       <span class=\"ml-10\">I confirm that I have the consent of those contacts to share their information with QS.</span>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"form-group no-margins\">\n" +
    "                    <div class=\"col-lg-offset-2 col-md-10 m-t\">\n" +
    "                        <div wave-spinner ng-show=\"NominationsListClientUploadController.uploadInprogress\"></div>\n" +
    "                        <a class=\"btn btn-primary m-t\" ng-click=\"NominationsListClientUploadController.handleCheckFile()\"\n" +
    "                            ng-class=\"{'disabled': NominationsListClientUploadController.uploadEnabled ||\n" +
    "                                        !NominationsListClientUploadController.isGdprChecked ||\n" +
    "                                        NominationsListClientUploadController.uploadInprogress}\">\n" +
    "                            <i class=\"fa fa-check-circle\"></i> Save\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <br/>\n" +
    "                <div class=\"alert alert-danger\" ng-show=\"NominationsListClientUploadController.isFileErrors\">\n" +
    "                    <ul>\n" +
    "                        <li ng-repeat=\"item in NominationsListClientUploadController.fileErrors\">\n" +
    "                            Incorrect value: <span ng-bind-html=\"NominationsListClientUploadController.getErrorMessage(item.value, item.column, item.row)\"></span>\n" +
    "                            <ul>\n" +
    "                                <li ng-if=\"item.message !== undefined\"><strong> Error Message: </strong> {{ item.message }}</li>\n" +
    "                            </ul>\n" +
    "                        </li>\n" +
    "                    </ul>\n" +
    "                </div>\n" +
    "                <div class=\"alert alert-danger\" ng-show=\"NominationsListClientUploadController.isColumnErrors\">\n" +
    "                    <ul>\n" +
    "                        <li>\n" +
    "                            <p>{{NominationsListClientUploadController.fileErrors.errorMessage}}</p>\n" +
    "                        </li>\n" +
    "                    </ul>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <nominations-List-Client-Upload-Logs></nominations-List-Client-Upload-Logs>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/nominations/components/nominationsListDatagrid/nominationsListDatagridView.html',
    "<div  class=\"wrapper wrapper-content animated fadeInRight page-members-db-leads-export\">\n" +
    "        <div class=\"row\">\n" +
    "            <div class=\"col-sm-12\">\n" +
    "                <div class=\"section\">\n" +
    "                    <div wave-spinner class=\"absolute-wave-spinner\" ng-show=\"NominationsListDatagridController.fetchInProgress\"></div>\n" +
    "                    <div class=\"section-body float-e-margins\" ng-class=\"{ 'modal-overlay-35': NominationsListDatagridController.fetchInProgress }\">\n" +
    "                        <div class=\"row form-inline\">\n" +
    "    \n" +
    "                            <div class=\"col-lg-2 col-xs-6\">\n" +
    "                                <ui-select class=\"ui-grid-filter-select no-padding\"\n" +
    "                                            on-select=\"NominationsListDatagridController.handleFileTypeChanges()\"\n" +
    "                                            ng-required=\"true\"\n" +
    "                                            ng-model=\"NominationsListDatagridController.fileType\"\n" +
    "                                            theme=\"bootstrap\"\n" +
    "                                            search-enabled=\"true\">\n" +
    "                                    <ui-select-match placeholder=\"Select File Type\">\n" +
    "                                        {{ $select.selected.label }}\n" +
    "                                    </ui-select-match>\n" +
    "                                    <ui-select-choices\n" +
    "                                        position=\"down\"\n" +
    "                                        repeat=\"item in (NominationsListDatagridController.fileTypes | filter: $select.search) track by item.handle\">\n" +
    "                                        <div ng-bind-html=\"item.label | highlight: $select.search\"></div>\n" +
    "                                    </ui-select-choices>\n" +
    "                                </ui-select>\n" +
    "                            </div>\n" +
    "    \n" +
    "                            <div class=\"col-lg-2 col-xs-2\">\n" +
    "                                <button class=\"btn btn-primary\"\n" +
    "                                    ng-disabled=\"!NominationsListDatagridController.fileType\"\n" +
    "                                    ng-click=\"NominationsListDatagridController.handleFetch()\">\n" +
    "                                    <i class=\"fa fa-table\"></i>\n" +
    "                                    <span>Generate Table</span>\n" +
    "                                </button>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "\n" +
    "                        <div class=\"alert alert-warning m-t\" ng-show=\"!NominationsListDatagridController.isFileTypeChange && NominationsListDatagridController.fileType &&\n" +
    "                            (NominationsListDatagridController.noResults && !NominationsListDatagridController.isFiltersExist)\">\n" +
    "                            <span>No results are available for the specified parameters</span>\n" +
    "                        </div>\n" +
    "                        <div ng-show=\"!NominationsListDatagridController.isFileTypeChange && NominationsListDatagridController.fileType &&\n" +
    "                            (!NominationsListDatagridController.noResults || NominationsListDatagridController.isFiltersExist )\">\n" +
    "                            <div ng-if=\"NominationsListDatagridController.gridOptions\">\n" +
    "                                <div ng-class=\"{'modal-overlay-35': NominationsListDatagridController.showLoadBar()}\">\n" +
    "                                    <ui-grid-info></ui-grid-info>\n" +
    "                                    <div class=\"grid\"\n" +
    "                                            ui-grid=\"NominationsListDatagridController.gridOptions\"\n" +
    "                                            ui-grid-selection\n" +
    "                                            ui-grid-resize-columns\n" +
    "                                            ui-grid-auto-resize\n" +
    "                                            ui-grid-cellnav\n" +
    "                                            ui-grid-exporter\n" +
    "                                            ui-grid-pagination\n" +
    "                                            ng-show=\"NominationsListDatagridController.isRowsRendered\"></div>\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>  \n" +
    "\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/nominations/components/qs/nominationsListReportsDatagrid/nominationsListReportsDatagridView.html',
    "<div  class=\"wrapper wrapper-content animated fadeInRight\">\n" +
    "    <div class=\"section\">\n" +
    "        <div wave-spinner class=\"absolute-wave-spinner\" ng-show=\"NominationsListReportsDatagridController.showLoadBar()\"></div>\n" +
    "\n" +
    "        <div ng-if=\"NominationsListReportsDatagridController.gridOptions\" ng-class=\"{'modal-overlay-35': NominationsListReportsDatagridController.showLoadBar()}\">\n" +
    "            <ui-grid-info></ui-grid-info>\n" +
    "        \n" +
    "            <div class=\"grid\"\n" +
    "                    ui-grid=\"NominationsListReportsDatagridController.gridOptions\"\n" +
    "                    ui-grid-selection\n" +
    "                    ui-grid-resize-columns\n" +
    "                    ui-grid-auto-resize\n" +
    "                    ui-grid-cellnav\n" +
    "                    ui-grid-exporter\n" +
    "                    ui-grid-pagination></div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/progressReports/byCountry/byCountryView.html',
    "<div  class=\"wrapper wrapper-content animated fadeInRight page-progress-report-by-country\">\n" +
    "    <div class=\"ibox\">\n" +
    "        <div class=\"ibox-content\">\n" +
    "                <div class=\"text-center\" ng-show=\"ProgressReportByCountryController.isgridRendering\">\n" +
    "                        <div wave-spinner class=\"wave-spinner\"></div>\n" +
    "                        <span>Calculating</span>\n" +
    "                   </div>\n" +
    "\n" +
    "            <div ng-if=\"!ProgressReportByCountryController.isDatagridReloading && gridOptions\">\n" +
    "                <div class=\"clearfix\">\n" +
    "                    <div class=\"wi-350 pull-left m-r\">\n" +
    "                        <ui-select\n" +
    "                            name=\"rankings\"\n" +
    "                            ng-required=\"true\"\n" +
    "                            ng-model=\"ProgressReportByCountryController.selectedItem.ranking\"\n" +
    "                            on-select=\"ProgressReportByCountryController.selectedRankingChanged()\"\n" +
    "                            theme=\"bootstrap\">\n" +
    "                            <ui-select-match placeholder=\"Select Ranking\">{{$select.selected.abbreviation}}</ui-select-match>\n" +
    "                            <ui-select-choices\n" +
    "                                position=\"down\"\n" +
    "                                ui-disable-choice=\"option.disabled\"\n" +
    "                                repeat=\"option in ProgressReportByCountryController.rankingsList | filter:$select.search | orderArray:false:'abbreviation' track by option.handle\">\n" +
    "                                <div ng-bind-html=\"option.abbreviation | highlight: $select.search\"></div>\n" +
    "                            </ui-select-choices>\n" +
    "                            <ui-select-no-choice>\n" +
    "                                Not found\n" +
    "                            </ui-select-no-choice>\n" +
    "                        </ui-select>\n" +
    "                    </div>\n" +
    "                    <div class=\"pull-left\">\n" +
    "                        <span date-range-picker\n" +
    "                              class=\"btn btn-w-m btn-primary\"\n" +
    "                              type=\"text\"\n" +
    "                              ng-model=\"ProgressReportByCountryController.datePicker.date\"\n" +
    "                              options=\"ProgressReportByCountryController.datePicker.options\">\n" +
    "                            <span class=\"fa fa-calendar m-r\"></span>\n" +
    "                    {{ (ProgressReportByCountryController.dateFilter.startDate | date:'mediumDate') || \"Start Date\"}} - {{ (ProgressReportByCountryController.dateFilter.endDate| date:'mediumDate') || \"End Date\"}}\n" +
    "                    </span>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <ui-grid-info></ui-grid-info>\n" +
    "                <div class=\"grid\"\n" +
    "                    ng-class=\"{'modal-overlay-35': ProgressReportByCountryController.isgridRendering}\"\n" +
    "                     ui-grid=\"gridOptions\"\n" +
    "                     ui-grid-selection\n" +
    "                     ui-grid-cellnav\n" +
    "                     ui-grid-exporter\n" +
    "                     ui-grid-resize-columns\n" +
    "                     ui-grid-auto-resize></div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/progressReports/byInstitution/byInstitutionView.html',
    "<div  class=\"wrapper wrapper-content animated fadeInRight page-progress-report-by-institution\">\n" +
    "    <div class=\"ibox\">\n" +
    "        <div class=\"ibox-content\">\n" +
    "                <div class=\"text-center\"  ng-show=\"ProgressReportByInstitutionController.isLoading()\">\n" +
    "                        <div wave-spinner class=\"wave-spinner\"></div>\n" +
    "                        <span>Calculating</span>\n" +
    "                   </div>\n" +
    "\n" +
    "            <div class=\"datagrid-filters\" ng-class=\"{'modal-overlay-35': ProgressReportByInstitutionController.isLoading()}\">\n" +
    "                <div class=\"wi-350 pull-left m-r\">\n" +
    "                    <ui-select\n" +
    "                        name=\"ranking\"\n" +
    "                        ng-model=\"ProgressReportByInstitutionController.rankingFilter\"\n" +
    "                        search-enabled=\"false\"\n" +
    "                        on-select=\"ProgressReportByInstitutionController.handleRankingFilterChanges()\"\n" +
    "                        theme=\"bootstrap\">\n" +
    "                        <ui-select-match placeholder=\"Select ranking\">{{$select.selected.label}}</ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                            position=\"down\"\n" +
    "                            ui-disable-choice=\"option.disabled\"\n" +
    "                            repeat=\"item in (ProgressReportByInstitutionController.rankingsList | filter: $select.search) track by item.value\">\n" +
    "                            <div ng-bind-html=\"item.label | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"wi-350 pull-left m-r\">\n" +
    "                    <ui-select\n" +
    "                        name=\"country\"\n" +
    "                        multiple\n" +
    "                        ng-model=\"ProgressReportByInstitutionController.countryFilter\"\n" +
    "                        theme=\"bootstrap\"\n" +
    "                        search-enabled=\"true\"\n" +
    "                        ng-change=\"ProgressReportByInstitutionController.handleCountryFilterChanges()\">\n" +
    "                        <ui-select-match placeholder=\"Select country\">\n" +
    "                            {{$item.label}}\n" +
    "                        </ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                            position=\"down\"\n" +
    "                            repeat=\"item in (ProgressReportByInstitutionController.countryList | filter: $select.search) track by item.value\">\n" +
    "                            <div ng-bind-html=\"item.label | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "\n" +
    "                <p>\n" +
    "                    <span date-range-picker\n" +
    "                          class=\"btn btn-w-m btn-primary\"\n" +
    "                          type=\"text\"\n" +
    "                          ng-model=\"ProgressReportByInstitutionController.datePicker.date\"\n" +
    "                          options=\"ProgressReportByInstitutionController.datePicker.options\">\n" +
    "                        <span class=\"fa fa-calendar m-r\"></span>\n" +
    "                    {{ (ProgressReportByInstitutionController.dateFilter.startDate | date:'mediumDate') || \"Start Date\"}} - {{ (ProgressReportByInstitutionController.dateFilter.endDate| date:'mediumDate') || \"End Date\"}}\n" +
    "                    </span>\n" +
    "                </p>\n" +
    "\n" +
    "            </div>\n" +
    "\n" +
    "            <div wave-spinner class=\"wave-spinner\"\n" +
    "                 ng-show=\"ProgressReportByInstitutionController.isgridRendering\">\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"datagrid\"\n" +
    "                 ng-if=\"ProgressReportByInstitutionController.isDatagridVisible()\"\n" +
    "                 ng-class=\"{'modal-overlay-35': ProgressReportByInstitutionController.isgridRendering}\">\n" +
    "                <ui-grid-info></ui-grid-info>\n" +
    "                <div class=\"grid\"\n" +
    "                     ui-grid=\"ProgressReportByInstitutionController.gridOptions\"\n" +
    "                     ui-grid-selection\n" +
    "                     ui-grid-cellnav\n" +
    "                     ui-grid-exporter\n" +
    "                     ui-grid-resize-columns\n" +
    "                     ui-grid-auto-resize></div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/progressReports/byInstitution/datagrid/lastCreatedAtFilterHeaderTemplate.html',
    "<div class=\"ui-grid-filter-container {{'ui-grid-filter-input-' + $index}}\" ng-repeat=\"colFilter in col.filters\" ng-if=\"colFilter.visible\">\n" +
    "    <input type=\"text\" class=\"ui-grid-filter-input date-picker ui-grid-filter-input-{{$index}}\"\n" +
    "           ng-model=\"grid.appScope.filters.lastCreatedAt\"\n" +
    "           date-range-picker=\"\"\n" +
    "           date-range-fixer\n" +
    "           options=\"{eventHandlers: {'apply.daterangepicker': grid.appScope.handleCreatedAtDateRange, 'hide.daterangepicker': grid.appScope.handleCreatedAtDateRange}, opens: 'left'}\" />\n" +
    "    <div class=\"ui-grid-filter-button\" ng-click=\"removeFilter(colFilter, $index);grid.appScope.filters.lastCreatedAt={};\">\n" +
    "        <i class=\"ui-grid-icon-cancel\" ng-if=\"grid.appScope.filters.lastCreatedAt && grid.appScope.filters.lastCreatedAt.startDate\">&nbsp;</i>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/progressReports/byRanking/byRankingView.html',
    "<div  class=\"wrapper wrapper-content animated fadeInRight page-progress-report-by-ranking\">\n" +
    "    <div class=\"ibox\">\n" +
    "        <div class=\"ibox-content\">\n" +
    "            <div class=\"text-center\" ng-show=\"ProgressReportByRankingController.isDatagridReloading || !ProgressReportByRankingController.isRendered\">\n" +
    "                 <div wave-spinner class=\"wave-spinner\"></div>\n" +
    "                 <span>Calculating</span>\n" +
    "            </div>\n" +
    "\n" +
    "            <div ng-if=\"!ProgressReportByRankingController.isDatagridReloading && gridOptions\">\n" +
    "                <p>\n" +
    "                    <span date-range-picker\n" +
    "                          class=\"btn btn-w-m btn-primary\"\n" +
    "                          type=\"text\"\n" +
    "                          ng-model=\"ProgressReportByRankingController.datePicker.date\"\n" +
    "                          options=\"ProgressReportByRankingController.datePicker.options\">\n" +
    "                        <span class=\"fa fa-calendar m-r\"></span>\n" +
    "                    {{ (ProgressReportByRankingController.dateFilter.startDate | date:'mediumDate') || \"Start Date\"}} - {{ (ProgressReportByRankingController.dateFilter.endDate| date:'mediumDate') || \"End Date\"}}\n" +
    "                    </span>\n" +
    "                </p>\n" +
    "\n" +
    "                <ui-grid-info></ui-grid-info>\n" +
    "                <div class=\"grid\"\n" +
    "                     ui-grid=\"gridOptions\"\n" +
    "                     ui-grid-selection\n" +
    "                     ui-grid-cellnav\n" +
    "                     ui-grid-exporter\n" +
    "                     ui-grid-resize-columns\n" +
    "                     ui-grid-auto-resize></div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/rankingsDashboard/datagrid/currencyCellTemplate.html',
    "<div class=\"ui-grid-cell-contents text-uppercase\">{{COL_FIELD CUSTOM_FILTERS}}</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/rankingsDashboard/datagrid/exportAllMenuItem.html',
    "<li>\n" +
    "    <button type=\"button\" class=\"ui-grid-menu-item\"\n" +
    "            ng-click=\"itemAction($event, title)\"\n" +
    "            ng-show=\"itemShown()\"\n" +
    "            ng-class=\"{ 'ui-grid-menu-item-active': active(), 'ui-grid-sr-only': (!focus && screenReaderOnly) }\"\n" +
    "            aria-pressed=\"{{active()}}\"\n" +
    "            tabindex=\"0\"\n" +
    "            ng-focus=\"focus=true\"\n" +
    "            ng-blur=\"focus=false\">\n" +
    "        <i ng-class=\"icon\" aria-hidden=\"true\">&nbsp;</i> {{ name }} <i class=\"fa fa-info-circle pull-right\"\n" +
    "                                                                       aria-hidden=\"true\"\n" +
    "                                                                       uib-tooltip=\"Export is for the 40,000 most recent rows. Otherwise, create a Jira ticket for additional statistics.\"\n" +
    "                                                                       tooltip-placement=\"left\"></i>\n" +
    "    </button>\n" +
    "</li>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/rankingsDashboard/datagrid/financialCellTemplate.html',
    "<div class=\"ui-grid-cell-contents\">{{COL_FIELD CUSTOM_FILTERS}}</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/rankingsDashboard/datagrid/institutionLoginTemplate.html',
    "<div class=\"ui-grid-cell-contents no-select text-center\" ng-click=\"$event.stopPropagation()\">\n" +
    "    <a class=\"btn btn-default btn-sm pull-left m-b-xs ng-scope\" ng-click=\"loginAsInstitution()\">\n" +
    "        Login <i class=\"fa fa-sign-in\"></i>\n" +
    "    </a>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/rankingsDashboard/datagrid/numericCellTemplate.html',
    "<div class=\"ui-grid-cell-contents\">\n" +
    "        {{ grid.appScope.getQuestionValue(row.entity.questionHandle,COL_FIELD) }}\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/rankingsDashboard/datagrid/personnelCellTemplate.html',
    "<div class=\"ui-grid-cell-contents\">\n" +
    "    {{ COL_FIELD.value}}\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/rankingsDashboard/datagrid/questionCellTemplate.html',
    "<div class=\"ui-grid-cell-contents\">\n" +
    "    {{grid.appScope.getQuestionName(COL_FIELD)}}\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/rankingsDashboard/datagrid/rowTemplate.html',
    "<div grid=\"grid\">\n" +
    "    <div class=\"ui-grid-cell pointer\"\n" +
    "        ng-repeat=\"(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name\"\n" +
    "        ng-class=\"{ 'ui-grid-row-header-cell': col.isRowHeader, 'active': row.entity.institutionCoreIdAsString == grid.appScope.selectedRankingCoreId }\"\n" +
    "        role=\"{{col.isRowHeader ? 'rowheader' : 'gridcell'}}\"\n" +
    "        ui-grid-cell>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/rankingsDashboard/datagrid/statusCellTemplate.html',
    "<div class=\"ui-grid-cell-contents\">\n" +
    "    <i ng-class=\"{'hidden': COL_FIELD !== grid.appScope.status.accepted}\" class=\"fa fa-check-circle text-navy\" aria-hidden=\"true\"></i>\n" +
    "    <i ng-class=\"{'hidden': COL_FIELD !== grid.appScope.status.rejected}\" class=\"fa fa-times-circle text-danger\" aria-hidden=\"true\"></i>\n" +
    "    <i ng-class=\"{'hidden': COL_FIELD !== grid.appScope.status.pending}\" class=\"fa fa-hourglass text-warning\" aria-hidden=\"true\"></i>\n" +
    "    <i ng-class=\"{'hidden': COL_FIELD !== grid.appScope.status.queried}\" class=\"fa fa-question-circle text-info\" aria-hidden=\"true\"></i>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/rankingsDashboard/rankingsDashboardView.html',
    "<div  class=\"wrapper wrapper-content animated fadeInRight page-institution\">\n" +
    "    <div class=\"row\">\n" +
    "        <div class=\"section\">\n" +
    "            <div class=\"section-header row\">\n" +
    "\n" +
    "                <div class=\"col-lg-3 col-md-3 col-sm-6 m-b-xs\">\n" +
    "                    <ui-select\n" +
    "                        name=\"rankings\"\n" +
    "                        ng-required=\"true\"\n" +
    "                        ng-model=\"RankingsDashboardController.selectedItem.ranking\"\n" +
    "                        on-select=\"\"\n" +
    "                        theme=\"bootstrap\">\n" +
    "                        <ui-select-match placeholder=\"Select Ranking\">{{$select.selected.abbreviation}}</ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                            position=\"down\"\n" +
    "                            ui-disable-choice=\"option.disabled\"\n" +
    "                            repeat=\"option in RankingsDashboardController.rankings | filter:$select.search track by option.handle\">\n" +
    "                            <div ng-bind-html=\"option.abbreviation | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"col-lg-9 col-md-6 col-sm-12 m-b-xs\">\n" +
    "                    <ul class=\"list-inline pull-left\" ng-show=\"RankingsDashboardController.selectedItem.ranking\">\n" +
    "                        <li ng-show=\"RankingsDashboardController.isPersonnelVisible()\">\n" +
    "                            <input i-checkbox type=\"radio\" value=\"{{RankingsDashboardController.rankingsDataTypes.personal}}\"\n" +
    "                                   ng-disabled=\"!RankingsDashboardController.selectedItem.ranking\"\n" +
    "                                   ng-model=\"RankingsDashboardController.selectedItem.dataType\">\n" +
    "                            <span>Personnel</span>\n" +
    "                        </li>\n" +
    "                        <li ng-show=\"RankingsDashboardController.isGerPersonnelVisible()\">\n" +
    "                            <input i-checkbox type=\"radio\" value=\"{{RankingsDashboardController.rankingsDataTypes.gerPersonal}}\"\n" +
    "                                   ng-disabled=\"!RankingsDashboardController.selectedItem.ranking\"\n" +
    "                                   ng-model=\"RankingsDashboardController.selectedItem.dataType\">\n" +
    "                            <span>GER Personnel</span>\n" +
    "                        </li>\n" +
    "                        <li ng-show=\"RankingsDashboardController.isFinancialVisible()\">\n" +
    "                            <input i-checkbox type=\"radio\" value=\"{{RankingsDashboardController.rankingsDataTypes.financial}}\"\n" +
    "                                   ng-disabled=\"!RankingsDashboardController.selectedItem.ranking\"\n" +
    "                                   ng-model=\"RankingsDashboardController.selectedItem.dataType\">\n" +
    "                            <span>Financial</span>\n" +
    "                        </li>\n" +
    "                        <li ng-show=\"RankingsDashboardController.isNumericVisible()\">\n" +
    "                            <input i-checkbox type=\"radio\" value=\"{{RankingsDashboardController.rankingsDataTypes.total}}\"\n" +
    "                                   ng-disabled=\"!RankingsDashboardController.selectedItem.ranking\"\n" +
    "                                   ng-model=\"RankingsDashboardController.selectedItem.dataType\">\n" +
    "                            <span>Numeric</span>\n" +
    "                        </li>\n" +
    "                        <li ng-show=\"RankingsDashboardController.isEmployementRatePersonnelVisible()\">\n" +
    "                            <input i-checkbox type=\"radio\" value=\"{{RankingsDashboardController.rankingsDataTypes.gerPersonal}}\"\n" +
    "                                   ng-disabled=\"!RankingsDashboardController.selectedItem.ranking\"\n" +
    "                                   ng-model=\"RankingsDashboardController.selectedItem.dataType\">\n" +
    "                            <span>Employment Statistics Personnel</span>\n" +
    "                        </li>\n" +
    "                        <li ng-show=\"RankingsDashboardController.isEmployementRateNumericVisible()\">\n" +
    "                            <input i-checkbox type=\"radio\" value=\"{{RankingsDashboardController.rankingsDataTypes.total}}\"\n" +
    "                                   ng-disabled=\"!RankingsDashboardController.selectedItem.ranking\"\n" +
    "                                   ng-model=\"RankingsDashboardController.selectedItem.dataType\">\n" +
    "                            <span>Employment Statistics Numeric</span>\n" +
    "                        </li>\n" +
    "                    </ul>\n" +
    "\n" +
    "                    <div class=\"pull-right\">\n" +
    "                        <a class=\"btn btn-default btn-xs m-b-xs ng-scope\"\n" +
    "                           ng-click=\"RankingsDashboardController.handleClearFilters()\"\n" +
    "                           ng-show=\"RankingsDashboardController.selectedItem.ranking\">\n" +
    "                            Reset data grid <i class=\"fa fa-times\"></i>\n" +
    "                        </a>\n" +
    "                        <a class=\"btn btn-default btn-xs m-b-xs ng-scope\"\n" +
    "                           ng-click=\"RankingsDashboardController.handleReloadDatagrid()\"\n" +
    "                           ng-show=\"RankingsDashboardController.selectedItem.dataType && !RankingsDashboardController.isLoading\">\n" +
    "                            Refresh current table <i class=\"fa fa-refresh\"></i>\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <rankings-financial-datagrid ng-show=\"RankingsDashboardController.isFinancial\"></rankings-financial-datagrid>\n" +
    "                <rankings-personnel-datagrid ng-show=\"RankingsDashboardController.isPersonnel\"></rankings-personnel-datagrid>\n" +
    "                <rankings-personnel-ger-datagrid ng-show=\"RankingsDashboardController.isGerPersonnel\"></rankings-personnel-ger-datagrid>\n" +
    "                <rankings-numeric-datagrid ng-show=\"RankingsDashboardController.isNumeric\"></rankings-numeric-datagrid>\n" +
    "\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/rankingsDashboard/rankingsFinancialDatagrid/rankingsFinancialDatagridView.html',
    "<div class=\"tab-body\">\n" +
    "    <div wave-spinner class=\"wave-spinner\" ng-show=\"$ctrl.isReloading || !$ctrl.isRendered\"></div>\n" +
    "\n" +
    "    <div ng-if=\"!$ctrl.isReloading && gridOptions\" ng-class=\"{'modal-overlay-35': $ctrl.isReloading || !$ctrl.isRendered}\">\n" +
    "        <ui-grid-info></ui-grid-info>\n" +
    "        <div class=\"grid\"\n" +
    "             ui-grid=\"gridOptions\"\n" +
    "             ui-grid-selection\n" +
    "             ui-grid-cellnav\n" +
    "             ui-grid-exporter\n" +
    "             ui-grid-pagination\n" +
    "             ui-grid-resize-columns\n" +
    "             ui-grid-auto-resize></div>\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/modules/rankings/components/rankingsDashboard/rankingsNumericDatagrid/rankingsNumericDatagridView.html',
    "<div class=\"tab-body\">\n" +
    "    <div wave-spinner class=\"wave-spinner\" ng-show=\"$ctrl.isReloading || !$ctrl.isRendered\"></div>\n" +
    "\n" +
    "    <div ng-if=\"!$ctrl.isReloading && gridOptions\" ng-class=\"{'modal-overlay-35': $ctrl.isReloading || !$ctrl.isRendered}\">\n" +
    "        <ui-grid-info></ui-grid-info>\n" +
    "        <div class=\"grid\"\n" +
    "             ui-grid=\"gridOptions\"\n" +
    "             ui-grid-selection\n" +
    "             ui-grid-cellnav\n" +
    "             ui-grid-exporter\n" +
    "             ui-grid-pagination\n" +
    "             ui-grid-resize-columns\n" +
    "             ui-grid-auto-resize></div>\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/modules/rankings/components/rankingsDashboard/rankingsPersonnelDatagrid/rankingsPersonnelDatagridView.html',
    "<div class=\"tab-body\">\n" +
    "    <div wave-spinner class=\"wave-spinner\" ng-show=\"$ctrl.isReloading || !$ctrl.isRendered\"></div>\n" +
    "\n" +
    "    <div ng-if=\"!$ctrl.isReloading && gridOptions\" ng-class=\"{'modal-overlay-35': $ctrl.isReloading || !$ctrl.isRendered}\">\n" +
    "        <ui-grid-info></ui-grid-info>\n" +
    "        <div class=\"grid\"\n" +
    "             ui-grid=\"gridOptions\"\n" +
    "             ui-grid-selection\n" +
    "             ui-grid-cellnav\n" +
    "             ui-grid-exporter\n" +
    "             ui-grid-pagination\n" +
    "             ui-grid-resize-columns\n" +
    "             ui-grid-auto-resize></div>\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/modules/rankings/components/rankingsDashboard/rankingsPersonnelGerDatagrid/rankingsPersonnelGerDatagridView.html',
    "<div class=\"tab-body\">\n" +
    "    <div wave-spinner class=\"wave-spinner\" ng-show=\"$ctrl.isReloading || !$ctrl.isRendered\"></div>\n" +
    "\n" +
    "    <div ng-if=\"!$ctrl.isReloading && gridOptions\" ng-class=\"{'modal-overlay-35': $ctrl.isReloading || !$ctrl.isRendered}\">\n" +
    "        <ui-grid-info></ui-grid-info>\n" +
    "        <div class=\"grid\"\n" +
    "             ui-grid=\"gridOptions\"\n" +
    "             ui-grid-selection\n" +
    "             ui-grid-cellnav\n" +
    "             ui-grid-exporter\n" +
    "             ui-grid-pagination\n" +
    "             ui-grid-resize-columns\n" +
    "             ui-grid-auto-resize></div>\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/modules/rankings/components/rankingsHtml/downloadButtonTemplate.html',
    "<a href=\"javascript:void(0)\"\" class=\"download-btn\" onclick=\"triggerDownload();\"\" id=\"downloadAsHtmlFile\">Save all data as HTML</a>"
  );


  $templateCache.put('/scripts/modules/rankings/components/rankingsHtml/rankingsHtmlView.html',
    "<div class=\"wrapper wrapper-content animated fadeInRight page-marcomms-rankings-html\">\n" +
    "    <div class=\"row\">\n" +
    "        <div class=\"col-sm-12\">\n" +
    "            <div class=\"section form-inline\">\n" +
    "                <!-- SECTION HEADER -->\n" +
    "                <div wave-spinner class=\"absolute-wave-spinner\" ng-show=\"RankingsHtmlController.loading()\"></div>\n" +
    "                <div class=\"section-header\" ng-class=\"{ 'modal-overlay-35': RankingsHtmlController.loading() }\">\n" +
    "\n" +
    "                    <div class=\"row\">\n" +
    "                        <div class=\"form-group\">\n" +
    "                            <div class=\"wi-150 m-r\">\n" +
    "                                <ui-select\n" +
    "                                    ng-required=\"true\"\n" +
    "                                    name=\"ranking\"\n" +
    "                                    ng-required=\"true\"\n" +
    "                                    ng-model=\"RankingsHtmlController.selectedItem.website\"\n" +
    "                                    on-select=\"RankingsHtmlController.selectedWebsiteChanged()\"\n" +
    "                                    theme=\"bootstrap\">\n" +
    "                                    <ui-select-match placeholder=\"Website\">{{$select.selected.label}}</ui-select-match>\n" +
    "                                    <ui-select-choices\n" +
    "                                        position=\"down\"\n" +
    "                                        ui-disable-choice=\"option.disabled\"\n" +
    "                                        repeat=\"option in RankingsHtmlController.websitesList | filter:$select.search\">\n" +
    "                                        <div ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                                    </ui-select-choices>\n" +
    "                                    <ui-select-no-choice>\n" +
    "                                        Not found\n" +
    "                                    </ui-select-no-choice>\n" +
    "                                </ui-select>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "\n" +
    "                        <div class=\"form-group\" ng-class=\"{ 'modal-overlay-35': !RankingsHtmlController.selectedItem.website }\">\n" +
    "                            <div class=\"wi-325 m-r\">\n" +
    "                                <ui-select\n" +
    "                                    ng-required=\"true\"\n" +
    "                                    name=\"ranking\"\n" +
    "                                    ng-required=\"true\"\n" +
    "                                    ng-model=\"RankingsHtmlController.selectedItem.ranking\"\n" +
    "                                    on-select=\"RankingsHtmlController.selectedRankingChanged()\"\n" +
    "                                    theme=\"bootstrap\">\n" +
    "                                    <ui-select-match placeholder=\"Ranking\">{{$select.selected.label}}</ui-select-match>\n" +
    "                                    <ui-select-choices\n" +
    "                                        position=\"down\"\n" +
    "                                        ui-disable-choice=\"option.disabled\"\n" +
    "                                        repeat=\"option in RankingsHtmlController.rankingsList | filter:$select.search | orderArray:false:'label'\">\n" +
    "                                        <div ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                                    </ui-select-choices>\n" +
    "                                    <ui-select-no-choice>\n" +
    "                                        Not found\n" +
    "                                    </ui-select-no-choice>\n" +
    "                                </ui-select>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "\n" +
    "                        <div class=\"form-group\">\n" +
    "                            <div class=\"wi-115 m-r\">\n" +
    "                                <ui-select\n" +
    "                                    ng-required=\"true\"\n" +
    "                                    name=\"year\"\n" +
    "                                    ng-required=\"true\"\n" +
    "                                    ng-model=\"RankingsHtmlController.selectedItem.year\"\n" +
    "                                    on-select=\"RankingsHtmlController.selectedYearChanged()\"\n" +
    "                                    theme=\"bootstrap\">\n" +
    "                                    <ui-select-match placeholder=\"Year\">{{$select.selected.label}}</ui-select-match>\n" +
    "                                    <ui-select-choices\n" +
    "                                        position=\"down\"\n" +
    "                                        ui-disable-choice=\"option.disabled\"\n" +
    "                                        repeat=\"option in RankingsHtmlController.yearsList | filter:$select.search | orderArray:true:'label'\">\n" +
    "                                        <div ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                                    </ui-select-choices>\n" +
    "                                    <ui-select-no-choice>\n" +
    "                                        Not found\n" +
    "                                    </ui-select-no-choice>\n" +
    "                                </ui-select>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "\n" +
    "                        <div class=\"form-group\">\n" +
    "                            <div class=\"wi-115 m-r\">\n" +
    "                                <ui-select\n" +
    "                                    name=\"year\"\n" +
    "                                    ng-required=\"true\"\n" +
    "                                    ng-model=\"RankingsHtmlController.selectedItem.range\"\n" +
    "                                    on-select=\"RankingsHtmlController.selectedRangeChanged()\"\n" +
    "                                    theme=\"bootstrap\">\n" +
    "                                    <ui-select-match placeholder=\"Range\">{{$select.selected.label}}</ui-select-match>\n" +
    "                                    <ui-select-choices\n" +
    "                                        position=\"down\"\n" +
    "                                        ui-disable-choice=\"option.disabled\"\n" +
    "                                        repeat=\"option in RankingsHtmlController.rangeList | filter:$select.search\">\n" +
    "                                        <div ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                                    </ui-select-choices>\n" +
    "                                    <ui-select-no-choice>\n" +
    "                                        Not found\n" +
    "                                    </ui-select-no-choice>\n" +
    "                                </ui-select>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "\n" +
    "                        <div class=\"form-group\" ng-show=\"RankingsHtmlController.showSubjects\">\n" +
    "                            <div class=\"max-wi-295 m-r\">\n" +
    "                                <span class=\"text-warning\" ng-show=\"!RankingsHtmlController.subjectsList.length\">No subjects assigned to the ranking for selected years.</span>\n" +
    "                                <ui-select\n" +
    "                                    ng-show=\"RankingsHtmlController.subjectsList.length\"\n" +
    "                                    ng-required=\"true\"\n" +
    "                                    multiple\n" +
    "                                    close-on-select=\"true\"\n" +
    "                                    reset-search-input=\"true\"\n" +
    "                                    name=\"year\"\n" +
    "                                    ng-required=\"true\"\n" +
    "                                    ng-model=\"RankingsHtmlController.selectedItem.subject\"\n" +
    "                                    on-select=\"RankingsHtmlController.selectedSubjectChanged()\"\n" +
    "                                    ng-change=\"RankingsHtmlController.handleSubjectChanges()\"\n" +
    "                                    theme=\"bootstrap\">\n" +
    "                                    <ui-select-match placeholder=\"Subject\">{{ $item.label }}</ui-select-match>\n" +
    "                                    <ui-select-choices\n" +
    "                                        position=\"down\"\n" +
    "                                        ui-disable-choice=\"option.disabled\"\n" +
    "                                        repeat=\"option in RankingsHtmlController.subjectsList | filter:$select.search | orderArray:true:'label'\">\n" +
    "                                        <div ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                                    </ui-select-choices>\n" +
    "                                    <ui-select-no-choice>\n" +
    "                                        Not found\n" +
    "                                    </ui-select-no-choice>\n" +
    "                                </ui-select>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                        <button class=\"btn btn-primary\"\n" +
    "                            ng-disabled=\"RankingsHtmlController.isFetchDisabled()\"\n" +
    "                            ng-click=\"RankingsHtmlController.generateTable()\">\n" +
    "                            <i class=\"fa fa-table\"></i>\n" +
    "                            <span>Generate Table</span>\n" +
    "                        </button>\n" +
    "                        <button ng-if=\"RankingsHtmlController.dataLaoded && RankingsHtmlController.isAdmin() && RankingsHtmlController.showPublishBtn\" class=\"btn btn-primary\"\n" +
    "                            ng-disabled=\"RankingsHtmlController.isFetchDisabled() || RankingsHtmlController.disablePublishButton\"\n" +
    "                            ng-click=\"RankingsHtmlController.handlePublish(RankingsHtmlController.selectedItem.ranking.value,RankingsHtmlController.selectedItem.year.value, RankingsHtmlController.selectedItem.website.value)\">\n" +
    "                            <i class=\"fa fa-cloud-upload\"></i>\n" +
    "                            <span>{{RankingsHtmlController.showSubjects ? 'Publish all subjects to mobile app': 'Publish to mobile app'}}</span>\n" +
    "                        </button>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"section-body\" ng-if=\"RankingsHtmlController.gridOptions\" ng-class=\"{'modal-overlay-35': RankingsHtmlController.loading()}\">\n" +
    "                    <div ng-repeat=\"gridOptions in RankingsHtmlController.gridOptions\">\n" +
    "                        <p class=\"bold m-t-md text-muted\"> {{ gridOptions.label }} </p>\n" +
    "                        <div class=\"grid\" id=\"printTable\"\n" +
    "                             ui-grid=\"gridOptions\"\n" +
    "                             ui-grid-selection\n" +
    "                             ui-grid-resize-columns\n" +
    "                             ui-grid-auto-resize\n" +
    "                             ui-grid-cellnav\n" +
    "                             ui-grid-exporter\n" +
    "                             ui-grid-pagination></div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/rankingsHtml/tableTemplateView.html',
    "<!DOCTYPE html>\n" +
    "<html>\n" +
    "<head>\n" +
    "    <meta charset=\"utf-8\">\n" +
    "    <title>{{ tableTitle }}</title>\n" +
    "    <style>\n" +
    "        .download-btn {\n" +
    "            font-family: \"open sans\", \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n" +
    "            font-size: 14px;\n" +
    "            line-height: 1.42857143;\n" +
    "            background: #1ab394;\n" +
    "            padding: 6px 12px;\n" +
    "            color: white;\n" +
    "            text-decoration: none;\n" +
    "            border-color: #1ab394;\n" +
    "            border-radius: 3px;\n" +
    "            border: 1px solid transparent;\n" +
    "            float: right;\n" +
    "        }\n" +
    "        .m-b-25 {\n" +
    "            margin-bottom: 25px;\n" +
    "        }\n" +
    "        table {\n" +
    "            border: 1pt solid #DDD;\n" +
    "        }\n" +
    "        th, td {\n" +
    "            padding: 5px;\n" +
    "            color:#000;\n" +
    "            font-size: 11.0pt;\n" +
    "            font-weight: 400;\n" +
    "            font-style: normal;\n" +
    "            text-decoration: none;\n" +
    "            font-family: \"Gill Sans MT\", sans-serif;\n" +
    "            text-align: general;\n" +
    "            vertical-align: bottom;\n" +
    "            border: 1pt SOLID #DDD;\n" +
    "            white-space: nowrap;\n" +
    "            display: table-cell;\n" +
    "        }\n" +
    "    </style>\n" +
    "    <script>\n" +
    "        function triggerDownload() {\n" +
    "            var text = \"<!DOCTYPE html><html><head><style>\" +\n" +
    "                \"table, th, td {border: 1px solid #ccc; border-collapse: collapse;} \" +\n" +
    "                \"#downloadAsHtmlFile {display:none;}}</style>\"\n" +
    "            \"</style>\";\n" +
    "            text += \"<title>{{ tableTitle }}</title>\";\n" +
    "            text += \"</head><body>\";\n" +
    "            text += document.getElementById(\"rankings\").innerHTML;\n" +
    "            text += \"</body></html>\";\n" +
    "            blob = new Blob([text], { type: 'text/html' });\n" +
    "            anchor = document.createElement('a');\n" +
    "            anchor.download = \"Rankings\";\n" +
    "            anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);\n" +
    "            anchor.dataset.downloadurl = ['text/html', anchor.download, anchor.href].join(':');\n" +
    "            document.body.appendChild(anchor);\n" +
    "            anchor.click();\n" +
    "            anchor.remove();\n" +
    "        }\n" +
    "    </script>\n" +
    "</head>\n" +
    "<body id=\"rankings\">\n" +
    "    <p class=\"m-b-25\">{{ tableTitle }}  {{ button }}</p>\n" +
    "    <table>\n" +
    "        <thead>\n" +
    "        <tr>\n" +
    "            {{ header }}\n" +
    "        </tr>\n" +
    "        </thead>\n" +
    "        <tbody>\n" +
    "            {{ body }}\n" +
    "        </tbody>\n" +
    "    </table>\n" +
    "    <p>Copyright QS Quacquarelli Symonds Ltd &copy; {{ currentYear }}</p>\n" +
    "</body>\n" +
    "</html>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/rankingsInformation/editRankingsDefinitions/editRankingsDefinitionsView.html',
    "<div class=\"ibox edit-rankings-definitions\">\n" +
    "    <div class=\"ibox-title no-borders\">\n" +
    "        <div class=\"col-lg-11 no-padding no-margins\">\n" +
    "            <p>Select ranking, category or question to edit</p>\n" +
    "        </div>\n" +
    "        <div class=\"col-lg-1 no-padding no-margins\">\n" +
    "            <div class=\"pull-right m-l valign-fix\">\n" +
    "                <a class=\"btn btn-default btn-xs m-b-xs ng-scope\" ng-click=\"$ctrl.clearFilters()\">\n" +
    "                    Clear filters <i class=\"fa fa-times\"></i>\n" +
    "                </a>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"ibox-content\">\n" +
    "        <div wave-spinner ng-show=\"$ctrl.isLoading()\"></div>\n" +
    "        <div ng-show=\"!$ctrl.isLoading()\">\n" +
    "\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col-md-2\">\n" +
    "                    <label>\n" +
    "                        <i class=\"fa fa-info-circle cursor-default\"\n" +
    "                            uib-tooltip='Displayed in the Definition tab within each ranking'\n" +
    "                            tooltip-placement=\"top-left\"></i> Select definition:\n" +
    "                    </label>\n" +
    "                </div>\n" +
    "                <div class=\"col-md-10\">\n" +
    "                    <div class=\"row\">\n" +
    "                        <div class=\"col-lg-4 m-b-xs\">\n" +
    "                            <div class=\"search-dropdown\">\n" +
    "                                <ui-select\n" +
    "                                    name=\"categories\"\n" +
    "                                    ng-required=\"true\"\n" +
    "                                    ng-model=\"selectedItem.ranking\"\n" +
    "                                    on-select=\"\"\n" +
    "                                    theme=\"bootstrap\">\n" +
    "                                    <ui-select-match allow-clear=\"true\" placeholder=\"Select Ranking\">{{$select.selected.name}}</ui-select-match>\n" +
    "                                    <ui-select-choices\n" +
    "                                        position=\"down\"\n" +
    "                                        ui-disable-choice=\"option.disabled\"\n" +
    "                                        repeat=\"option in $ctrl.rankingsFiltered | filter:$select.search | orderArray:false:'name' track by option.handle\">\n" +
    "                                        <div ng-bind-html=\"option.name | highlight: $select.search\"></div>\n" +
    "                                    </ui-select-choices>\n" +
    "                                    <ui-select-no-choice>\n" +
    "                                        Not found\n" +
    "                                    </ui-select-no-choice>\n" +
    "                                </ui-select>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                        <div class=\"col-lg-4 m-b-xs\">\n" +
    "                            <div class=\"search-dropdown\">\n" +
    "                                <ui-select\n" +
    "                                    name=\"categories\"\n" +
    "                                    ng-required=\"true\"\n" +
    "                                    ng-model=\"selectedItem.category\"\n" +
    "                                    on-select=\"\"\n" +
    "                                    theme=\"bootstrap\">\n" +
    "                                    <ui-select-match allow-clear=\"true\" placeholder=\"Select Category\">{{$select.selected.name}}</ui-select-match>\n" +
    "                                    <ui-select-choices\n" +
    "                                        position=\"down\"\n" +
    "                                        ui-disable-choice=\"option.disabled\"\n" +
    "                                        repeat=\"option in $ctrl.categoriesFiltered | filter:$select.search track by option.handle\">\n" +
    "                                        <div ng-bind-html=\"option.name | highlight: $select.search\"></div>\n" +
    "                                    </ui-select-choices>\n" +
    "                                    <ui-select-no-choice>\n" +
    "                                        Not found\n" +
    "                                    </ui-select-no-choice>\n" +
    "                                </ui-select>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                        <div class=\"col-lg-4 m-b-xs\" ng-show=\"$ctrl.showQuestions()\">\n" +
    "                            <div class=\"search-dropdown\">\n" +
    "                                <ui-select\n" +
    "                                    name=\"categories\"\n" +
    "                                    ng-required=\"true\"\n" +
    "                                    ng-model=\"selectedItem.question\"\n" +
    "                                    on-select=\"\"\n" +
    "                                    theme=\"bootstrap\">\n" +
    "                                    <ui-select-match allow-clear=\"true\" placeholder=\"Select Question\">{{$select.selected.name}}</ui-select-match>\n" +
    "                                    <ui-select-choices\n" +
    "                                        position=\"down\"\n" +
    "                                        ui-disable-choice=\"option.disabled\"\n" +
    "                                        repeat=\"option in $ctrl.questionsFiltered | filter:$select.search track by option.handle\">\n" +
    "                                        <div ng-bind-html=\"option.name | highlight: $select.search\"></div>\n" +
    "                                    </ui-select-choices>\n" +
    "                                    <ui-select-no-choice>\n" +
    "                                        Not found\n" +
    "                                    </ui-select-no-choice>\n" +
    "                                </ui-select>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"row\">\n" +
    "                        <div class=\"col-lg-12\">\n" +
    "                            <p class=\"valign-fix text-center no-margins\">OR</p>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"row\">\n" +
    "                        <div class=\"col-lg-12\">\n" +
    "                            <div class=\"search-dropdown m-b\">\n" +
    "                                <ui-select\n" +
    "                                    close-on-select=\"true\"\n" +
    "                                    ng-model=\"$ctrl.search\"\n" +
    "                                    on-select=\"$ctrl.handleSearchCategoryQuestionClick($item)\"\n" +
    "                                    theme=\"bootstrap\"\n" +
    "                                    name=\"searchCategoryQuestion\"\n" +
    "                                    reset-search-input=\"true\">\n" +
    "                                    <ui-select-match placeholder=\"Search for category or question...\">{{$select.selected.name}}\n" +
    "                                    </ui-select-match>\n" +
    "                                    <ui-select-choices\n" +
    "                                        refresh-delay=\"1000\"\n" +
    "                                        refresh=\"$ctrl.searchCategoryQuestion($select.search)\"\n" +
    "                                        position=\"down\"\n" +
    "                                        repeat=\"option in $ctrl.searchResults | filter:$select.search\">\n" +
    "                                        <div ng-bind-html=\"option.searchName | highlight: $select.search\"></div>\n" +
    "                                    </ui-select-choices>\n" +
    "                                    <ui-select-no-choice>\n" +
    "                                        Not found\n" +
    "                                    </ui-select-no-choice>\n" +
    "                                </ui-select>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <hr class=\"clearfix\">\n" +
    "\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col-lg-12\">\n" +
    "                    <form name=\"$ctrl.forms.definitions\" class=\"m-t clearfix no-top-margin\" novalidate role=\"form\" autocomplete=\"off\">\n" +
    "                        <div ng-repeat=\"(key, category) in $ctrl.categoriesWithQuestions\">\n" +
    "                            <div class=\"category-group\" ng-show=\"$ctrl.isCategoryVisible(category.categoryHandle)\">\n" +
    "                                <div class=\"row form-group\">\n" +
    "                                    <div class=\"col-lg-12\">\n" +
    "                                        <h4>Category:</h4>\n" +
    "                                    </div>\n" +
    "                                </div>\n" +
    "                                <div class=\"row\">\n" +
    "                                    <div class=\"col-lg-2\">\n" +
    "                                        <p><i class=\"fa fa-info-circle cursor-default\"\n" +
    "                                              uib-tooltip='Displayed in the rankings’ data submission pages next to the category'\n" +
    "                                              tooltip-placement=\"top\"></i>\n" +
    "                                            {{category.categoryName}}</p>\n" +
    "                                    </div>\n" +
    "                                    <div class=\"col-lg-10 form-group\">\n" +
    "                                        <div class=\"pointer-events modal-overlay-common\"\n" +
    "                                             ng-class=\"{'modal-overlay-75' : category.disable}\"\n" +
    "                                             ng-click=\"$ctrl.toggleEditCategory(category.categoryHandle, false)\">\n" +
    "                                            <summernote\n" +
    "                                                config=\"$ctrl.configCategoryTextEditor\"\n" +
    "                                                on-paste=\"$ctrl.handleClipboardPaste(category.categoryHandle)\"\n" +
    "                                                ng-model=\"$ctrl.categoriesWithQuestions[category.categoryHandle].definition\">\n" +
    "                                            </summernote>\n" +
    "                                        </div>\n" +
    "                                        <div class=\"clearfix m-b\">\n" +
    "                                            <div ng-show=\"!$ctrl.categoriesWithQuestions[category.categoryHandle].disable\">\n" +
    "                                                <a class=\"btn btn-primary btn-sm m-r\"\n" +
    "                                                   ng-class=\"{'disabled': $ctrl.categoryHasChanges()}\"\n" +
    "                                                   ng-click=\"$ctrl.handleCategorySubmit(category.categoryHandle)\">\n" +
    "                                                    <i class=\"fa fa-check-circle\"></i>\n" +
    "                                                    <span>Update</span>\n" +
    "                                                </a>\n" +
    "                                                <a class=\"btn btn-default btn-sm\"\n" +
    "                                                   ng-click=\"$ctrl.toggleEditCategory(category.categoryHandle)\">\n" +
    "                                                    <i class=\"fa fa-ban\"></i>\n" +
    "                                                    <span>Cancel</span>\n" +
    "                                                </a>\n" +
    "                                            </div>\n" +
    "                                        </div>\n" +
    "                                    </div>\n" +
    "                                </div>\n" +
    "                                <div class=\"row form-group\">\n" +
    "                                    <div class=\"col-lg-2\">\n" +
    "                                        <h4>Appears in:</h4>\n" +
    "                                    </div>\n" +
    "                                    <div class=\"col-lg-10\">\n" +
    "                                        <ul class=\"list-inline\">\n" +
    "                                            <li ng-repeat=\"(key, ranking) in category.rankings\">\n" +
    "                                                <span ng-hide=\"$ctrl.hiddenRankings(ranking)\" class=\"label\">{{ranking.name}}</span>\n" +
    "                                            </li>\n" +
    "                                        </ul>\n" +
    "                                    </div>\n" +
    "                                </div>\n" +
    "                                <hr class=\"hr-line-dashed\">\n" +
    "                            </div>\n" +
    "                            <div class=\"row form-group title-questions2\" ng-hide=\"!$ctrl.isCategoryVisible(category.categoryHandle) || selectedItem.question\">\n" +
    "                                <div class=\"col-lg-12\">\n" +
    "                                    <h4>Questions:</h4>\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                            <div ng-repeat=\"(key, question) in category.questions\" class=\"category-questions\">\n" +
    "                                <div ng-show=\"$ctrl.isQuestionVisible(category.categoryHandle, question.questionHandle)\">\n" +
    "                                    <div class=\"row form-group title-questions\" ng-show=\"selectedItem.question\">\n" +
    "                                        <div class=\"col-lg-2\">\n" +
    "                                            <h4>Question:</h4>\n" +
    "                                        </div>\n" +
    "                                        <div class=\"col-lg-10\">\n" +
    "                                            <span class=\"label\">{{$ctrl.parentCategoryName}} > {{selectedItem.question.name}}</span>\n" +
    "                                        </div>\n" +
    "                                    </div>\n" +
    "\n" +
    "                                    <div class=\"row\">\n" +
    "                                        <div class=\"col-lg-2\">\n" +
    "                                            <p><i class=\"fa fa-info-circle cursor-default\"\n" +
    "                                                  uib-tooltip='Displayed in the rankings’ data submission pages in the Definitions tab'\n" +
    "                                                  tooltip-placement=\"top\"></i>\n" +
    "                                                {{question.questionName}}</p>\n" +
    "                                        </div>\n" +
    "                                        <div class=\"col-lg-10 form-group\">\n" +
    "                                            <div class=\"pointer-events modal-overlay-common\"\n" +
    "                                                 ng-class=\"{'modal-overlay-75' : question.disable}\"\n" +
    "                                                 ng-click=\"$ctrl.toggleEditQuestion(category.categoryHandle, question.questionHandle, false)\">\n" +
    "                                                <summernote\n" +
    "                                                    config=\"$ctrl.configCategoryTextEditor\"\n" +
    "                                                    ng-model=\"$ctrl.categoriesWithQuestions[category.categoryHandle].questions[question.questionHandle].definition\"\n" +
    "                                                    on-paste=\"$ctrl.handleClipboardPaste(category.categoryHandle, question.questionHandle)\">\n" +
    "                                                </summernote>\n" +
    "                                            </div>\n" +
    "                                            <div class=\"learfix m-b\">\n" +
    "                                                <div ng-show=\"!$ctrl.categoriesWithQuestions[category.categoryHandle].questions[question.questionHandle].disable\">\n" +
    "                                                    <a class=\"btn btn-primary btn-sm m-r\"\n" +
    "                                                       ng-class=\"{'disabled': $ctrl.questionHasChanges()}\"\n" +
    "                                                       ng-click=\"$ctrl.handleQuestionSubmit(category.categoryHandle, question.questionHandle)\">\n" +
    "                                                        <i class=\"fa fa-check-circle\"></i>\n" +
    "                                                        <span>Update</span>\n" +
    "                                                    </a>\n" +
    "                                                    <a class=\"btn btn-default btn-sm\"\n" +
    "                                                       ng-click=\"$ctrl.toggleEditQuestion(category.categoryHandle, question.questionHandle)\">\n" +
    "                                                        <i class=\"fa fa-ban\"></i>\n" +
    "                                                        <span>Cancel</span>\n" +
    "                                                    </a>\n" +
    "                                                </div>\n" +
    "                                            </div>\n" +
    "                                        </div>\n" +
    "                                        <hr class=\"hr-line-dashed\" ng-hide=\"selectedItem.question && !selectedItem.category\">\n" +
    "                                        <div class=\"col-lg-2\">\n" +
    "                                            <h4>Appears in: </h4>\n" +
    "                                        </div>\n" +
    "                                        <div class=\"col-lg-10\">\n" +
    "                                            <ul class=\"list-inline\">\n" +
    "                                                <li ng-repeat=\"(key, ranking) in question.rankings\">\n" +
    "                                                    <span class=\"label\">{{ranking.name}}</span>\n" +
    "                                                </li>\n" +
    "                                            </ul>\n" +
    "                                        </div>\n" +
    "                                        <hr class=\"hr-line-dashed clearfix\">\n" +
    "                                    </div>\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </form>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/modules/rankings/components/rankingsInformation/editRankingsInfo/editRankingsInfoView.html',
    "<div class=\"ibox publish-logs\">\n" +
    "    <div class=\"ibox-content\">\n" +
    "        <div wave-spinner ng-show=\"$ctrl.isLoading()\"></div>\n" +
    "        <div ng-show=\"!$ctrl.isLoading()\">\n" +
    "            <p>Select ranking to edit</p>\n" +
    "            <uib-accordion>\n" +
    "                <div uib-accordion-group class=\"panel-default\"\n" +
    "                     ng-repeat=\"item in $ctrl.statisticsAvailable | orderArray:false:'name'\"\n" +
    "                     heading=\"{{item.name}}\"\n" +
    "                     is-open=\"item.open\">\n" +
    "                    <ranking-info-form info=\"item\"></ranking-info-form>\n" +
    "                </div>\n" +
    "            </uib-accordion>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/rankingsInformation/editRankingsInfo/rankingInfoForm/previewTemplateView.html',
    "<div class=\"dz-preview dz-file-preview\">\n" +
    "    <div class=\"dz-details\">\n" +
    "          <span class=\"ml-10 dz-size\" data-dz-size></span>\n" +
    "          <span class=\"ml-10 dz-filename\" data-dz-name></span>\n" +
    "          <button class=\"ml-10 btn btn-danger\" data-dz-remove><i class=\"fa fa-trash-o\" aria-hidden=\"true\"></i></button>\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/modules/rankings/components/rankingsInformation/editRankingsInfo/rankingInfoForm/rankingInfoFormView.html',
    "<div class=\"form-horizontal m-t\">\n" +
    "    <div class=\"form-group\">\n" +
    "        <label class=\"col-lg-2 control-label\">\n" +
    "            <span class=\"clickable fa fa-info-circle\"\n" +
    "                  uib-tooltip=\"Displayed in the data submission landing page within each ranking\"\n" +
    "                  tooltip-placement=\"top\">\n" +
    "            </span> Data deadline *\n" +
    "        </label>\n" +
    "        <div class=\"col-lg-10\">\n" +
    "                <div class=\"inner-addon col-md-3 col-sm-12\">\n" +
    "                    <input date-range-picker type=\"text\" class=\"form-control clickable\"\n" +
    "                           ng-model=\"$ctrl.info.deadline\"\n" +
    "                           options=\"$ctrl.datePickerOptions\"\n" +
    "                           ng-class=\"{'has-errors-datepicker': $ctrl.invalidDeadLine}\">\n" +
    "                    <i class=\"fa fa-calendar\"></i>\n" +
    "                </div>\n" +
    "            <div\n" +
    "                focus-delay=\"250\"\n" +
    "                custom-popover\n" +
    "                popover-html=\"Enter a future date\"\n" +
    "                popover-placement=\"left\"\n" +
    "                popover-trigger=\"manual\"\n" +
    "                popover-visibility=\"{{$ctrl.invalidDeadLine ? true : false}}\">\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group m-b-none\">\n" +
    "        <label class=\"col-lg-2 control-label\">\n" +
    "            <span class=\"clickable fa fa-info-circle\"\n" +
    "                  uib-tooltip=\"Downloadable from the data submission landing page within each ranking\"\n" +
    "                  tooltip-placement=\"top\">\n" +
    "            </span> Guidelines (PDF)\n" +
    "            <br>\n" +
    "            <small class=\"text-muted\">Maximum size {{$ctrl.maxSize}}MB</small>\n" +
    "        </label>\n" +
    "        <div class=\"col-lg-10\">\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col-md-3 col-sm-12\">\n" +
    "                    <ui-select\n" +
    "                        close-on-select=\"true\"\n" +
    "                        ng-model=\"$ctrl.guideline.language\"\n" +
    "                        ng-change=\"$ctrl.submitted = false\"\n" +
    "                        search-enabled=\"true\"\n" +
    "                        theme=\"bootstrap\">\n" +
    "                        <ui-select-match placeholder=\"Language\" allow-clear=\"true\">\n" +
    "                            {{$select.selected.value}}\n" +
    "                        </ui-select-match>\n" +
    "                        <ui-select-choices refresh-delay=\"1000\"\n" +
    "                                           repeat=\"option.label as option in $ctrl.languages | filter:$select.search | orderBy:'label'\">\n" +
    "                            <div ng-bind-html=\"option.value | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                        <ui-select-no-choice>\n" +
    "                            Not found\n" +
    "                        </ui-select-no-choice>\n" +
    "                    </ui-select>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"clearfix visible-sm visible-xs\">\n" +
    "                    <br>\n" +
    "                </div>\n" +
    "\n" +
    "                <div ng-class=\"{'col-md-9 col-sm-12': !$ctrl.uploadEnabled, 'col-md-1 col-sm-3' : $ctrl.uploadEnabled && !$ctrl.isImageBoxEmpty,'col-md-3 col-sm-6' : $ctrl.isImageBoxEmpty && !$ctrl.displayRemoveLink ? true : false}\">\n" +
    "                    <form class=\"clearfix\"\n" +
    "                        name=\"$ctrl.forms.uploadForm\"\n" +
    "                        enctype=\"multipart/form-data\"\n" +
    "                        ng-dropzone\n" +
    "                        ng-class=\"{'modal-overlay-35' : !$ctrl.guideline.language, 'has-errors': !$ctrl.displayRemoveLink && $ctrl.isImageBoxEmpty}\"\n" +
    "                        dropzone=\"$ctrl.dropZoneInstance\"\n" +
    "                        dropzone-config=\"$ctrl.uploadConfig.dropzone\"\n" +
    "                        event-handlers=\"$ctrl.uploadConfig.eventHandlers\"\n" +
    "                        novalidate\n" +
    "                        focus-delay=\"250\"\n" +
    "                        custom-popover\n" +
    "                        popover-html=\"Upload Ranking Guidelines\"\n" +
    "                        popover-placement=\"left\"\n" +
    "                        popover-trigger=\"manual\"\n" +
    "                        popover-visibility=\"{{$ctrl.mustUploadGuidelines()}}\"\n" +
    "                        dz-hidden-input-file\n" +
    "                        upload-enabled=\"{{!$ctrl.uploadEnabled ? true : false}}\">\n" +
    "                        <div class=\"fallback form-group\" ng-class=\"{'has-errors': $ctrl.isImageBoxEmpty}\">\n" +
    "                            <input name=\"file\" type=\"file\"/>\n" +
    "                        </div>\n" +
    "\n" +
    "                        <div class=\"pull-left m-r\"\n" +
    "                             ng-show=\"$ctrl.displayRemoveLink\"\n" +
    "                             ng-class=\"{'has-errors': $ctrl.isVersionEmpty && !$ctrl.guideline.version}\">\n" +
    "                            <div class=\"pull-left version\">\n" +
    "                                <label>Ver</label>\n" +
    "                            </div>\n" +
    "                            <div class=\"pull-left\">\n" +
    "                                <input class=\"form-control pull-left\" type=\"number\" step=\"0.01\" name=\"version\" min=\"0.01\"\n" +
    "                                       ng-model=\"$ctrl.guideline.version\"\n" +
    "                                       ng-disabled=\"!$ctrl.guideline.url\"\n" +
    "                                       focus-delay=\"250\"\n" +
    "                                       custom-popover\n" +
    "                                       popover-html=\"Please enter the version\"\n" +
    "                                       popover-placement=\"left\"\n" +
    "                                       popover-trigger=\"manual\"\n" +
    "                                       popover-visibility=\"{{$ctrl.isVersionEmpty ? true : false}}\">\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                        <div class=\"btn btn-default btn-outline btn-sm pull-left dz-message\" ng-show=\"$ctrl.uploadEnabled\">\n" +
    "                            <i class=\"fa fa-upload\"></i> Upload\n" +
    "                        </div>\n" +
    "                    </form>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "        <div class=\"col-lg-10 col-lg-offset-2\">\n" +
    "            <label class=\"control-label text-muted\">Current versions:</label>\n" +
    "            <span ng-if=\"!$ctrl.info.guidelines.length\">none</span>\n" +
    "            <ul ng-if=\"$ctrl.info.guidelines.length\" class=\"list-inline list-border\">\n" +
    "                <li ng-hide=\"item.isNotSaved\" ng-repeat=\"item in $ctrl.info.guidelines\">\n" +
    "                    <a href=\"http://{{item.url}}\" target=\"_blank\" class=\"no-text-transform\">\n" +
    "                        <span class=\"label label-warning-light\">{{item.language}} - {{item.version}}</span>\n" +
    "                        <small class=\"text-muted\">{{item.createdAt | date:'medium'}}</small> by\n" +
    "                        <small class=\"text-muted\">{{item.createdByFullName}}</small>\n" +
    "                    </a>\n" +
    "                </li>\n" +
    "            </ul>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "        <label class=\"col-lg-2 control-label\">\n" +
    "            <span class=\"clickable fa fa-info-circle\"\n" +
    "                  uib-tooltip=\"Displayed in the data submission page within each ranking\"\n" +
    "                  tooltip-placement=\"top\">\n" +
    "            </span> Description *\n" +
    "        </label>\n" +
    "        <div class=\"summernote-container col-lg-10\" ng-class=\"{'has-errors': $ctrl.isDescriptionEmpty && !$ctrl.info.definition}\">\n" +
    "            <summernote\n" +
    "                config=\"$ctrl.textEditorOptions\"\n" +
    "                on-focus=\"$ctrl.setInvalidDescription(countWords($ctrl.info.definition))\"\n" +
    "                ng-model=\"$ctrl.info.definition\"\n" +
    "                on-change=\"$ctrl.descriptionWords = countWords(contents)\"\n" +
    "                on-init=\"$ctrl.descriptionWords = countWords($ctrl.info.definition)\"\n" +
    "                on-paste=\"$ctrl.handleClipboardPaste('definition')\"\n" +
    "                count-word=\"$ctrl.descriptionWords\">\n" +
    "            </summernote>\n" +
    "\n" +
    "             <div class=\"editor-note\">\n" +
    "                <span ng-class=\"{'text-red': $ctrl.isDescriptionInvalid($ctrl.descriptionWords)}\">\n" +
    "                  <span class=\"bold\">{{$ctrl.descriptionWords}}</span>\n" +
    "                  <span>words inserted</span>\n" +
    "                </span>\n" +
    "                <span class=\"pull-right\">Maximum {{$ctrl.maxWords}} words</span>\n" +
    "                <div\n" +
    "                    focus-delay=\"250\"\n" +
    "                    custom-popover\n" +
    "                    popover-html=\"add ranking description\"\n" +
    "                    popover-placement=\"left\"\n" +
    "                    popover-trigger=\"manual\"\n" +
    "                    popover-visibility=\"{{$ctrl.isDescriptionEmpty && !$ctrl.info.definition ? true : false}}\">\n" +
    "                </div>\n" +
    "              </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group\">\n" +
    "        <div class=\"col-lg-offset-2 col-md-10\">\n" +
    "            <a class=\"btn btn-primary\" ng-click=\"$ctrl.handleAdd()\"\n" +
    "               ng-class=\"{'disabled': $ctrl.submitDisabled}\">\n" +
    "                <i class=\"fa fa-check-circle\"></i> Update\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/rankingsInformation/rankingsInformationView.html',
    "<div class=\"wrapper wrapper-content animated fadeInDown page-rankings-information\">\n" +
    "    <div class=\"row\">\n" +
    "\n" +
    "        <div class=\"col-sm-12\">\n" +
    "            <div class=\"tabs-container\" ng-class=\"{'modal-overlay': loadInProgress}\">\n" +
    "                <uib-tabset active=\"activeTab\">\n" +
    "                    <uib-tab heading=\"Edit Ranking Info\" ui-sref=\"staff.iu.rankings-information.info\">\n" +
    "                        <div class=\"panel-body\">\n" +
    "                            <edit-rankings-info></edit-rankings-info>\n" +
    "                        </div>\n" +
    "                    </uib-tab>\n" +
    "\n" +
    "                    <uib-tab heading=\"Edit Definitions\" ui-sref=\"staff.iu.rankings-information.definitions\">\n" +
    "                        <div class=\"panel-body\">\n" +
    "                            <edit-rankings-definitions></edit-rankings-definitions>\n" +
    "                        </div>\n" +
    "                    </uib-tab>\n" +
    "                </uib-tabset>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/rankingsScores/cellTemplates/indicatorCellTemplate.html',
    "<div class=\"ui-grid-cell-contents\">{{row.entity.indicatorCombinedName}}</div>"
  );


  $templateCache.put('/scripts/modules/rankings/components/rankingsScores/cellTemplates/subjectIndicatorsTemplate.html',
    "<div class=\"subject-indicators ui-grid-cell-contents\">\n" +
    "    <span ng-if=\"value.value===0||value.value>0\" class=\"comma\" ng-repeat-start=\"(name, value) in row.entity.subjectIndicators\">,&nbsp;</span>\n" +
    "   <span ng-if=\"value.value===0||value.value>0\" class=\"indicator\" ng-repeat-end>\n" +
    "    {{value.acronym}} ({{value.value}}%)\n" +
    "\n" +
    "    </span>\n" +
    "\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/rankingsScores/cellTemplates/weightingTemplate.html',
    "<div class=\"ui-grid-cell-contents\">{{row.entity.value}}%</div>"
  );


  $templateCache.put('/scripts/modules/rankings/components/rankingsScores/directives/editScoresIndicator/editScoresIndicatorView.html',
    "<div class=\"ibox\">\n" +
    "    <div class=\"ibox-title\">\n" +
    "        <h5>Indicator Score</h5>\n" +
    "        <div class=\"ibox-tools ng-scope\">\n" +
    "            <a class=\"close-link\" ng-click=\"$ctrl.closeEditor()\">\n" +
    "                <i class=\"fa fa-times\"></i>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"ibox-content\">\n" +
    "\n" +
    "        <form class=\"form-horizontal\" name=\"$ctrl.newScoreForm\" ng-submit=\"$ctrl.save()\"\n" +
    "              novalidate>\n" +
    "            <div class=\"row m-b form-group\" ng-class=\"{'has-errors':$ctrl.fieldIsInvalid('indicator')}\">\n" +
    "                <div class=\"col-xs-12\">\n" +
    "                    <label>Indicator *</label>\n" +
    "                    <div class=\"row\">\n" +
    "                        <div class=\"col-xs-10\" ng-class=\"{'modal-overlay': $ctrl.addFormVisible}\">\n" +
    "                            <ui-select name=\"indicator\"\n" +
    "                                       ng-required=\"!$ctrl.addFormVisible\"\n" +
    "                                       ng-model=\"$ctrl.scoreData.indicatorId\">\n" +
    "                                <ui-select-match placeholder=\"Indicator\">\n" +
    "                                    <span>{{$select.selected.name + ' (' + $select.selected.acronym + ')'}}</span>\n" +
    "                                </ui-select-match>\n" +
    "                                <ui-select-choices\n" +
    "                                    ui-disable-choice=\"item.locked&&item.coreId!==$ctrl.scoreIndicator.indicatorId\"\n" +
    "                                    repeat=\"item.coreId as item in ($ctrl.indicatorOptions | filter: $select.search) track by item.coreId\">\n" +
    "                                    <span>{{item.name + ' (' + item.acronym + ')'}}</span>\n" +
    "                                </ui-select-choices>\n" +
    "                            </ui-select>\n" +
    "                            <div focus-delay=\"250\"\n" +
    "                                 custom-popover\n" +
    "                                 popover-html=\"Required\"\n" +
    "                                 popover-placement=\"left\"\n" +
    "                                 popover-trigger=\"manual\"\n" +
    "                                 popover-visibility=\"{{$ctrl.fieldIsInvalid('indicator')}}\">\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                        <div class=\"col-xs-2\" ng-class=\"{'modal-overlay': $ctrl.scoreData.id}\">\n" +
    "                            <button type=\"button\" ng-click=\"$ctrl.toggleAddForm()\" class=\"btn btn-default pull-right\">\n" +
    "                                <i ng-show=\"!$ctrl.addFormVisible\" class=\"fa fa-plus\"></i>\n" +
    "                                <i ng-show=\"$ctrl.addFormVisible\" class=\"fa fa-times\"></i>\n" +
    "                            </button>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div ng-show=\"$ctrl.addFormVisible\">\n" +
    "\n" +
    "                <div class=\"row m-b form-group\" ng-class=\"{'has-errors':$ctrl.fieldIsInvalid('name')}\">\n" +
    "                    <div class=\"col-xs-12\">\n" +
    "                        <label class=\"text-muted\">Name *</label>\n" +
    "                        <input class=\"form-control\" type=\"text\" name=\"name\"\n" +
    "                               ng-model=\"$ctrl.scoreData.name\"\n" +
    "                               ng-required=\"$ctrl.addFormVisible\">\n" +
    "\n" +
    "                        <div focus-delay=\"250\"\n" +
    "                             custom-popover\n" +
    "                             popover-html=\"Required\"\n" +
    "                             popover-placement=\"left\"\n" +
    "                             popover-trigger=\"manual\"\n" +
    "                             popover-visibility=\"{{$ctrl.fieldIsInvalid('name')}}\">\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"row m-b form-group\" ng-class=\"{'has-errors':$ctrl.fieldIsInvalid('acronym')}\">\n" +
    "                    <div class=\"col-xs-12\">\n" +
    "                        <label class=\"text-muted\">Acronym *</label>\n" +
    "                        <input class=\"form-control\" type=\"text\" name=\"acronym\"\n" +
    "                               ng-model=\"$ctrl.scoreData.acronym\"\n" +
    "                               ng-required=\"$ctrl.addFormVisible\">\n" +
    "                        <div focus-delay=\"250\"\n" +
    "                             custom-popover\n" +
    "                             popover-html=\"{{ $ctrl.newScoreForm.acronym.$error.unique ? 'Duplicate': 'Required' }}\"\n" +
    "                             popover-placement=\"left\"\n" +
    "                             popover-trigger=\"manual\"\n" +
    "                             popover-visibility=\"{{$ctrl.fieldIsInvalid('acronym')}}\">\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "\n" +
    "                <div class=\"separator\"></div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"row m-b form-group\" ng-class=\"{'has-errors':$ctrl.fieldIsInvalid('description')}\">\n" +
    "                <div class=\"col-xs-12\">\n" +
    "                    <label>Description *</label>\n" +
    "                    <div>\n" +
    "                        <textarea rows=\"10\"\n" +
    "                                  name=\"description\" ng-required=\"true\" class=\"form-control\"\n" +
    "                                  ng-model=\"$ctrl.scoreData.description\"\n" +
    "                                  placeholder=\"Description\">\n" +
    "                        </textarea>\n" +
    "                    </div>\n" +
    "\n" +
    "                    <div focus-delay=\"250\"\n" +
    "                         custom-popover\n" +
    "                         popover-html=\"Required\"\n" +
    "                         popover-placement=\"left\"\n" +
    "                         popover-trigger=\"manual\"\n" +
    "                         popover-visibility=\"{{$ctrl.fieldIsInvalid('description')}}\">\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div ng-if=\"$ctrl.hasWeightings\" ng-class=\"{'has-errors':$ctrl.fieldIsInvalid('value')}\" class=\"row m-b form-group\">\n" +
    "                <div class=\"col-xs-12\">\n" +
    "                    <label>Default Weight *</label>\n" +
    "                    <div class=\"input-group\">\n" +
    "                        <input name=\"value\" ng-max=\"100\" ng-min=\"0\"\n" +
    "                               ng-required=\"true\" class=\"form-control\" type=\"number\" ng-model=\"$ctrl.scoreData.value\">\n" +
    "                        <span class=\"input-group-addon\">%</span>\n" +
    "\n" +
    "                    </div>\n" +
    "                    <div focus-delay=\"250\"\n" +
    "                         custom-popover\n" +
    "                         popover-html=\"Minimum value is 0\"\n" +
    "                         popover-placement=\"left\"\n" +
    "                         popover-trigger=\"manual\"\n" +
    "                         popover-visibility=\"{{$ctrl.newScoreForm.value.$error.min}}\">\n" +
    "                    </div>\n" +
    "                    <div focus-delay=\"250\"\n" +
    "                         custom-popover\n" +
    "                         popover-html=\"Maximum value is 100\"\n" +
    "                         popover-placement=\"left\"\n" +
    "                         popover-trigger=\"manual\"\n" +
    "                         popover-visibility=\"{{$ctrl.newScoreForm.value.$error.max}}\">\n" +
    "                    </div>\n" +
    "                    <div focus-delay=\"250\"\n" +
    "                         custom-popover\n" +
    "                         popover-html=\"Required\"\n" +
    "                         popover-placement=\"left\"\n" +
    "                         popover-trigger=\"manual\"\n" +
    "                         popover-visibility=\"{{$ctrl.valueIsRequired()}}\">\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div ng-show=\"$ctrl.saving\" wave-spinner class=\"wave-spinner m-b-sm\">\n" +
    "            </div>\n" +
    "            <div ng-show=\"!$ctrl.saving\" class=\"row\">\n" +
    "                <div class=\"col-xs-6\">\n" +
    "                    <button type=\"button\" ng-show=\"$ctrl.scoreData.id\" ng-click=\"$ctrl.deleteScore()\" class=\"btn btn-danger\">\n" +
    "                        <i class=\"fa fa-trash\"></i> \n" +
    "                        Delete\n" +
    "                    </button>\n" +
    "                </div>\n" +
    "                <div class=\"col-xs-6\">\n" +
    "                    <button type=\"submit\" class=\"btn btn-primary pull-right\">\n" +
    "                        <i class=\"fa fa-check-circle\"></i> \n" +
    "                        {{$ctrl.scoreData.id ? 'Update' : 'Save'}}\n" +
    "                    </button>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </form>\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/modules/rankings/components/rankingsScores/directives/editSubject/editSubjectView.html',
    "<div class=\"ibox\">\n" +
    "    <div class=\"ibox-title\">\n" +
    "        <h5>Subject</h5>\n" +
    "        <div class=\"ibox-tools ng-scope\">\n" +
    "            <a class=\"close-link\" ng-click=\"$ctrl.closeEditor()\">\n" +
    "                <i class=\"fa fa-times\"></i>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"ibox-content\">\n" +
    "\n" +
    "        <form class=\"form-horizontal\" name=\"$ctrl.subjectForm\" ng-submit=\"$ctrl.save()\"\n" +
    "              novalidate>\n" +
    "            <div class=\"row m-b form-group\" ng-class=\"{'has-errors':$ctrl.fieldIsInvalid('subject')}\">\n" +
    "                <div class=\"col-xs-12\">\n" +
    "                    <label>Subject *</label>\n" +
    "                    <ui-select name=\"subject\" ng-required=\"true\" ng-model=\"$ctrl.newSubject.subjectId\">\n" +
    "                        <ui-select-match placeholder=\"Subject\">\n" +
    "                            <span>{{$select.selected.name + ' (' + $select.selected.acronym + ')'}}</span>\n" +
    "                        </ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                            ui-disable-choice=\"item.locked&&item.coreId!==$ctrl.subject.coreId\"\n" +
    "                            repeat=\"item.coreId as item in ($ctrl.subjectOptions | filter: $select.search | orderBy:'name') track by item.coreId\">\n" +
    "                            <span>{{item.name + ' (' + item.acronym + ')'}}</span>\n" +
    "                        </ui-select-choices>\n" +
    "                    </ui-select>\n" +
    "                    <div focus-delay=\"250\"\n" +
    "                         custom-popover\n" +
    "                         popover-html=\"Required\"\n" +
    "                         popover-placement=\"left\"\n" +
    "                         popover-trigger=\"manual\"\n" +
    "                         popover-visibility=\"{{$ctrl.fieldIsInvalid('subject')}}\">\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"row m-b\">\n" +
    "                <div class=\"col-xs-12\">\n" +
    "                    <div class=\"row\">\n" +
    "                        <div class=\"col-xs-6\">\n" +
    "                            <label>Indicator</label>\n" +
    "                        </div>\n" +
    "                        <div class=\"col-xs-6\">\n" +
    "                            <label>Default Weighting</label>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"row m-t-sm form-group\" ng-class=\"{'has-errors':$ctrl.fieldIsInvalid(indicator.indicatorName)}\" ng-repeat=\"indicator in $ctrl.indicatorOptions\">\n" +
    "                        <div class=\"col-xs-6\">\n" +
    "                            <label class=\"control-label\">{{indicator.indicatorName}}</label>\n" +
    "                        </div>\n" +
    "                        <div class=\"col-xs-6\">\n" +
    "                            <input ng-model=\"$ctrl.indicators[indicator.indicatorId].value\"\n" +
    "                                   ng-min=\"0\" ng-max=\"100\"\n" +
    "                                   name=\"{{indicator.indicatorName}}\" class=\"form-control\"\n" +
    "                                   type=\"number\">\n" +
    "                            <div focus-delay=\"250\"\n" +
    "                                 custom-popover\n" +
    "                                 popover-html=\"Required\"\n" +
    "                                 popover-placement=\"left\"\n" +
    "                                 popover-trigger=\"manual\"\n" +
    "                                 popover-visibility=\"{{$ctrl.subjectForm[indicator.indicatorName].$error.required}}\">\n" +
    "                            </div>\n" +
    "                            <div focus-delay=\"250\"\n" +
    "                                 custom-popover\n" +
    "                                 popover-html=\"Maximum value is 0\"\n" +
    "                                 popover-placement=\"left\"\n" +
    "                                 popover-trigger=\"manual\"\n" +
    "                                 popover-visibility=\"{{$ctrl.subjectForm[indicator.indicatorName].$error.min}}\">\n" +
    "                            </div>\n" +
    "                            <div focus-delay=\"250\"\n" +
    "                                 custom-popover\n" +
    "                                 popover-html=\"Maximum value is 100\"\n" +
    "                                 popover-placement=\"left\"\n" +
    "                                 popover-trigger=\"manual\"\n" +
    "                                 popover-visibility=\"{{$ctrl.subjectForm[indicator.indicatorName].$error.max}}\">\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"row m-t-sm form-group\" ng-class=\"{'has-errors':$ctrl.hasTotalError(indicator.indicatorName)}\">\n" +
    "                        <div class=\"col-xs-6\">\n" +
    "                            <label class=\"control-label\">Total</label>\n" +
    "                        </div>\n" +
    "                        <div class=\"offset-xs-6 col-xs-6\">\n" +
    "                            <total-indicator-values total=\"$ctrl.total\" indicators=\"$ctrl.indicators\"></total-indicator-values>\n" +
    "                            <div focus-delay=\"250\"\n" +
    "                                 custom-popover\n" +
    "                                 popover-html=\"Total values must equal 100\"\n" +
    "                                 popover-placement=\"left\"\n" +
    "                                 popover-trigger=\"manual\"\n" +
    "                                 popover-visibility=\"{{$ctrl.hasTotalError(indicator.indicatorName)}}\">\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div ng-show=\"$ctrl.saving\" wave-spinner class=\"wave-spinner m-b-sm\">\n" +
    "            </div>\n" +
    "            <div ng-show=\"!$ctrl.saving\" class=\"row\">\n" +
    "                <div class=\"col-xs-6\">\n" +
    "                    <button type=\"button\" ng-show=\"$ctrl.newSubject.id\" ng-click=\"$ctrl.delete()\" class=\"btn btn-danger\">\n" +
    "                        <i class=\"fa fa-trash\"></i> \n" +
    "                        Delete\n" +
    "                    </button>\n" +
    "                </div>\n" +
    "                <div class=\"col-xs-6\">\n" +
    "                    <button type=\"submit\" class=\"btn btn-primary pull-right\">\n" +
    "                        <i class=\"fa fa-check-circle\"></i> \n" +
    "                        {{$ctrl.newSubject.id ? 'Update' : 'Save'}}\n" +
    "                    </button>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </form>\n" +
    "\n" +
    "\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/modules/rankings/components/rankingsScores/directives/publishRankingsHistory/cellTemplate.html',
    "<div class=\"ui-grid-cell-contents\" ng-if=\"COL_FIELD CUSTOM_FILTERS !== 'error'\">{{COL_FIELD CUSTOM_FILTERS}}</div>\n" +
    "<div class=\"ui-grid-cell-contents red-color\" ng-if=\"COL_FIELD CUSTOM_FILTERS === 'error'\">\n" +
    "    Error\n" +
    "    <span\n" +
    "        class=\"info\"\n" +
    "        uib-tooltip=\"Indicator not allocated to this ranking\"\n" +
    "        tooltip-placement=\"left\">\n" +
    "        <i class=\"fa fa-info-circle\"></i>\n" +
    "    </span>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/modules/rankings/components/rankingsScores/directives/publishRankingsHistory/nameColumnTemplate.html',
    "<div class=\"ui-grid-cell-contents\" ng-bind-html=\"COL_FIELD CUSTOM_FILTERS | boldText\"></div>\n" +
    "\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/rankingsScores/directives/publishRankingsHistory/paginationTemplate.html',
    "<div class=\"ui-grid-pager-panel\" ui-grid-pager ng-show=\"grid.options.enablePaginationControls\">\n" +
    "    <div class=\"ui-grid-pager-container\">\n" +
    "        <div class=\"ui-grid-pager-control\">\n" +
    "            <button type=\"button\" ng-click=\"paginationApi.seek(1)\" ng-disabled=\"cantPageBackward()\">\n" +
    "                <div class=\"first-triangle\">\n" +
    "                    <div class=\"first-bar\">\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </button> \n" +
    "            <button type=\"button\" ng-click=\"paginationApi.previousPage()\" ng-disabled=\"cantPageBackward()\">\n" +
    "                <div class=\"first-triangle prev-triangle\">\n" +
    "                </div>\n" +
    "            </button>\n" +
    "            <input type=\"number\" ng-model=\"grid.options.paginationCurrentPage\" min=\"1\" max=\"{{ paginationApi.getTotalPages() }}\" required>\n" +
    "            <span class=\"ui-grid-pager-max-pages-number\" ng-show=\"paginationApi.getTotalPages() > 0\">\n" +
    "                {{ paginationApi.getTotalPages()}}\n" +
    "            </span> \n" +
    "            <button type=\"button\" ng-click=\"paginationApi.nextPage()\" ng-disabled=\"cantPageForward()\">\n" +
    "                <div class=\"last-triangle next-triangle\">\n" +
    "                </div>\n" +
    "            </button> \n" +
    "            <button type=\"button\" ng-click=\"paginationApi.seek(paginationApi.getTotalPages())\" ng-disabled=\"cantPageToLast()\">\n" +
    "                <div class=\"last-triangle\"><div class=\"last-bar\"></div></div>\n" +
    "            </button>\n" +
    "        </div>\n" +
    "        <div class=\"ui-grid-pager-row-count-picker\">\n" +
    "            <select ng-model=\"grid.options.paginationPageSize\"\n" +
    "                    ng-options=\"o.value as o.label for o in grid.options.paginationPageSizes\" ng-init=\"grid.options.paginationPageSize = grid.options.paginationPageSizes[0].value\">\n" +
    "            </select>\n" +
    "            <span class=\"ui-grid-pager-row-count-label\\\">\n" +
    "                {{sizesLabel}}\n" +
    "            </span>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"ui-grid-pager-count-container\">\n" +
    "        <div class=\"ui-grid-pager-count\">\n" +
    "            <span ng-show=\"grid.options.totalItems > 0\">\n" +
    "                {{ 1 + paginationApi.getFirstRowIndex() }}\n" +
    "                <abbr ui-grid-one-bind-title=\"paginationThrough\">-</abbr> \n" +
    "                {{ 1 + paginationApi.getLastRowIndex() }} {{paginationOf}} {{grid.options.totalItems}} {{totalItemsLabel}}\n" +
    "            </span>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/modules/rankings/components/rankingsScores/directives/publishRankingsHistory/previewDataModal.html',
    "<div class=\"modal-header\">\n" +
    "    <button type=\"button\" class=\"close\" ng-click=\"$dismiss()\">&times;</button>\n" +
    "    <h3>{{$ctrl.headerText}}</h3>\n" +
    "</div>\n" +
    "<div class=\"modal-body\">\n" +
    "    <div ng-show=\"$ctrl.loadingResults\" wave-spinner class=\"wave-spinner m-b-sm\">\n" +
    "    </div>\n" +
    "    <div  ng-class=\"{'modal-overlay-35': $ctrl.loadingResults}\" class=\"grid\"\n" +
    "          ui-grid=\"$ctrl.gridOptions\"\n" +
    "          ui-grid-selection\n" +
    "          ui-grid-cellnav\n" +
    "          ui-grid-exporter\n" +
    "          ui-grid-resize-columns\n" +
    "          ui-grid-auto-resize\n" +
    "          ui-grid-pagination>\n" +
    "    </div>\n" +
    "    <div class=\"row m-t\">\n" +
    "        <div class=\"col-xs-12\">\n" +
    "            <button ng-click=\"$ctrl.$close()\" class=\"btn btn-primary text-center\">\n" +
    "                <i class=\"fa fa-times\"></i>\n" +
    "                Close\n" +
    "            </button>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/rankingsScores/directives/publishRankingsHistory/publishRankingsHistory.html',
    "<div class=\"publish-rankings-history\" width-dependant-class=\"{lessThan:{'separate-buttons':795}}\">\n" +
    "\n" +
    "    <div class=\"stage-headings row\">\n" +
    "        <div class=\"col-xs-12 m-b\">\n" +
    "            <span class=\"pull-left active stage\" ng-show=\"$ctrl.stage>0\"><h3>Import CSV&nbsp;</h3></span>\n" +
    "            <span class=\"pull-left stage\" ng-class=\"{'active':$ctrl.stage>1}\"><h3> > Preview Matching Columns&nbsp;</h3></span>\n" +
    "            <span class=\"pull-left stage\" ng-class=\"{'active':$ctrl.stage>2}\"><h3> > Save and Confirm&nbsp;</h3></span>\n" +
    "            <span class=\"pull-left stage\"\n" +
    "                  ng-class=\"{'active':$ctrl.stage>3}\"><h3> > Publish Matching Data&nbsp;</h3></span>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div ng-show=\"$ctrl.loading\" wave-spinner class=\"wave-spinner m-t\">\n" +
    "    </div>\n" +
    "    <div ng-show=\"$ctrl.stage===1\">\n" +
    "        <form\n" +
    "            ng-if=\"$ctrl.uploadConfig\"\n" +
    "            class=\"clearfix\"\n" +
    "            name=\"$ctrl.forms.uploadForm\"\n" +
    "            enctype=\"multipart/form-data\"\n" +
    "            ng-dropzone\n" +
    "            dropzone=\"$ctrl.dropZoneInstance\"\n" +
    "            dropzone-config=\"$ctrl.uploadConfig.dropzone\"\n" +
    "            event-handlers=\"$ctrl.uploadConfig.eventHandlers\"\n" +
    "            novalidate\n" +
    "            focus-delay=\"250\"\n" +
    "            dz-hidden-input-file>\n" +
    "\n" +
    "            <div class=\"btn btn-default btn-outline btn-sm pull-left dz-message upload-file\"\n" +
    "                ng-class=\"{'disabled-button':$ctrl.dropZoneInstance.files.length&&!$ctrl.uploading}\"\n" +
    "                ng-hide=\"$ctrl.uploading\"\n" +
    "                ng-click=\"$ctrl.resetSidePanel()\">\n" +
    "                <i class=\"fa fa-upload\"></i> Upload\n" +
    "            </div>\n" +
    "        </form>\n" +
    "        <div class=\"row m-t\">\n" +
    "            <div class=\"col-xs-12\">\n" +
    "                    <span class=\"pull-right col-xs-2\" ng-show=\"$ctrl.uploading\" wave-spinner class=\"wave-spinner m-t\">\n" +
    "                    </span>\n" +
    "                <button ng-show=\"$ctrl.dropZoneInstance.files.length&&!$ctrl.uploading\"\n" +
    "                        ng-click=\"$ctrl.handleCheckFile()\"\n" +
    "                        class=\"btn btn-primary pull-right\">\n" +
    "                    Step 2 - Upload and preview <i class=\"fa fa-share\"></i>\n" +
    "                </button>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div ng-show=\"$ctrl.stage===2&&!$ctrl.loading\">\n" +
    "        <div class=\"text-center\" ng-show=\"$ctrl.gridOptionsDrupalPreview\">\n" +
    "            <button class=\"btn btn-primary\" ng-click=\"$ctrl.previewScores()\">View data file\n" +
    "            </button>\n" +
    "        </div>\n" +
    "        <div class=\"m-t\" ng-show=\"$ctrl.gridOptionsDrupalPreview\">\n" +
    "            <b>Note:</b> Please review the data. The indicators are displayed in alphabetical order and doesn't follow the Drupal order. If it's correct proceed to the next step.\n" +
    "        </div>\n" +
    "        <div class=\"row m-t\" ng-if=\"$ctrl.subjectsList.length\">\n" +
    "            <ui-select ng-model=\"$ctrl.subject\"\n" +
    "                       class=\"col-xs-4\"\n" +
    "                       ng-change=\"$ctrl.handleSubjectChange()\">\n" +
    "                <ui-select-match placeholder=\"Subject\">\n" +
    "                    <span ng-bind=\"$select.selected.subjectName\"></span>\n" +
    "                </ui-select-match>\n" +
    "                <ui-select-choices\n" +
    "                    repeat=\"item in ($ctrl.subjectsList | filter: $select.search) track by item.id\">\n" +
    "                    <span ng-bind=\"item.subjectName\"></span>\n" +
    "                </ui-select-choices>\n" +
    "            </ui-select>\n" +
    "        </div>\n" +
    "        <div ng-show=\"$ctrl.fetchInProgress\" wave-spinner class=\"wave-spinner m-t\"></div>\n" +
    "        <div ng-show=\"!$ctrl.noResults\" ng-class=\"{'modal-overlay-35': $ctrl.fetchInProgress}\">\n" +
    "            <div ng-if=\"$ctrl.gridOptionsDrupalPreview\">\n" +
    "                <div class=\"grid m-t\"\n" +
    "                     ui-grid=\"$ctrl.gridOptionsDrupalPreview\"\n" +
    "                     ui-grid-selection\n" +
    "                     ui-grid-cellnav\n" +
    "                     ui-grid-exporter\n" +
    "                     ui-grid-pagination\n" +
    "                     ui-grid-resize-columns\n" +
    "                     ui-grid-auto-resize\n" +
    "                     ng-show=\"$ctrl.isRowsRendered\"></div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"alert alert-warning m-t\" ng-show=\"$ctrl.noResults\">\n" +
    "            <span>Could not get data for drupal preview. Please go back and try again.</span>\n" +
    "        </div>\n" +
    "        <div class=\"m-t stage-2-buttons\" >\n" +
    "            <button class=\"btn btn-default\" ng-click=\"$ctrl.back()\" ng-show=\"$ctrl.gridOptionsDrupalPreview || $ctrl.noResults\">\n" +
    "                <i class=\"fa fa-reply\"></i> Columns don't match, go back and import a different file\n" +
    "            </button>\n" +
    "            <button class=\"btn btn-primary validate-button pull-right\" ng-click=\"$ctrl.validateAndSave()\" ng-show=\"$ctrl.gridOptionsDrupalPreview\">\n" +
    "                Columns match, validate and save data to database <i class=\"fa fa-share\"></i>\n" +
    "            </button>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div ng-show=\"$ctrl.stage===3&&!$ctrl.loading\">\n" +
    "        <p>The data was successfully stored into the data and is ready to be published. Please choose one the following\n" +
    "            options</p>\n" +
    "        <div class=\"m-t\">\n" +
    "\n" +
    "        </div>\n" +
    "        <div class=\"m-t row\">\n" +
    "            <div class=\"col-xs-6\">\n" +
    "                <button class=\"btn btn-default\" ng-if=\"!$ctrl.isPending\" ng-click=\"$ctrl.back()\"><i class=\"fa fa-reply\"></i> Go back to step 2 to\n" +
    "                    export preview\n" +
    "                </button>\n" +
    "                <button class=\"btn btn-default\" ng-if=\"$ctrl.isPending\" ng-click=\"$ctrl.stageOne()\">\n" +
    "                    <i class=\"fa fa-reply\"></i> Import a different file\n" +
    "                </button>\n" +
    "            </div>\n" +
    "            <div class=\"col-xs-6\">\n" +
    "                <button class=\"pull-right btn btn-primary\" ng-click=\"$ctrl.publish()\">\n" +
    "                    Publish data\n" +
    "                    <i class=\"fa fa-cloud-upload\"></i></button>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div ng-show=\"$ctrl.stage===4\">\n" +
    "        <div class=\"m-t\" progress-circle=\"$ctrl.publishStatus\" percentage=\"$ctrl.percentage\"></div>\n" +
    "\n" +
    "        <div class=\"m-t text-center\">\n" +
    "            <div class=\"col-lg-12\">\n" +
    "                <div ng-show=\"$ctrl.percentage!==null\">\n" +
    "                    <span ng-show=\"$ctrl.total\">\n" +
    "                         {{$ctrl.totalProgress}} of {{$ctrl.total}}\n" +
    "                    </span>\n" +
    "                    <span>\n" +
    "                        ({{$ctrl.percentage}}%)\n" +
    "                    </span>\n" +
    "                </div>\n" +
    "                <button ng-hide=\"$ctrl.publishStatus==='failure' || $ctrl.publishStatus==='cancelled' || $ctrl.publishStatus==='success' || $ctrl.cancelling\" ng-disabled=\"$ctrl.publishing\"\n" +
    "                        class=\"m-t btn btn-danger\"\n" +
    "                        ng-click=\"$ctrl.cancelPublish()\">\n" +
    "                    <i class=\"fa fa-ban\"></i>\n" +
    "                    Cancel Publish\n" +
    "                </button>\n" +
    "            </div>\n" +
    "            <div class=\"container\" ng-show=\"$ctrl.publishStatus==='failure'&&!$ctrl.loadingFailedRecords\">\n" +
    "                <div class=\"col-lg-6\">\n" +
    "                    <button class=\"m-t btn btn-danger pull-right\"\n" +
    "                            ng-click=\"$ctrl.viewFailedRecords()\">\n" +
    "                        <i class=\"fa fa-eye\"></i>\n" +
    "                        View Failed Records\n" +
    "                    </button>\n" +
    "                </div>\n" +
    "                <div class=\"col-lg-6\">\n" +
    "                    <button class=\"m-t btn btn-primary pull-left\"\n" +
    "                            ng-click=\"$ctrl.publishFailedRecords()\">\n" +
    "                        <i class=\"fa fa-repeat\"></i>\n" +
    "                        Republish all failed institutions\n" +
    "                    </button>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div ng-show=\"$ctrl.loadingFailedRecords\" wave-spinner class=\"wave-spinner m-t\"></div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div ng-show=\"$ctrl.publishStatus==='failure'||$ctrl.publishStatus=='success'||$ctrl.publishStatus=='cancelled'\">\n" +
    "        <button class=\"btn btn-primary pull-right\" ng-click=\"$ctrl.reimport()\">\n" +
    "            Step 1 - Import\n" +
    "        </button>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/rankingsScores/rankingsScoresView.html',
    "<div class=\"wrapper wrapper-content animated fadeInDown rankings-scores\">\n" +
    "\n" +
    "\n" +
    "    <div class=\"row\">\n" +
    "        <div id=\"ranking-scores-datagrid\" class=\"\"\n" +
    "             ng-class=\"{'col-md-12':!RankingsScoresCtrl.selectedRow&&!RankingsScoresCtrl.selectedSubject, 'col-md-8': RankingsScoresCtrl.selectedRow||RankingsScoresCtrl.selectedSubject}\">\n" +
    "            <div class=\"section\">\n" +
    "                <div ng-show=\"!RankingsScoresCtrl.loaded\" wave-spinner class=\"wave-spinner m-b-sm\">\n" +
    "                </div>\n" +
    "                <div ng-show=\"RankingsScoresCtrl.loaded\">\n" +
    "                    <div class=\"section-header\">\n" +
    "                        <div class=\"row\">\n" +
    "\n" +
    "                            <div class=\"col-xs-12\">\n" +
    "                                <div class=\"row\">\n" +
    "                                    <div class=\"col-xs-12\">\n" +
    "                                        <h2>Choose ranking</h2>\n" +
    "                                    </div>\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "\n" +
    "                            <div class=\"col-xs-12\">\n" +
    "                                <div class=\"row\">\n" +
    "                                    <div class=\"col-lg-3 col-xs-6 m-t\">\n" +
    "                                        <ui-select ng-model=\"RankingsScoresCtrl.filters.site\"\n" +
    "                                                   ng-change=\"RankingsScoresCtrl.handleSiteChange()\">\n" +
    "                                            <ui-select-match placeholder=\"Site\">\n" +
    "                                                <span ng-bind=\"$select.selected.label\"></span>\n" +
    "                                            </ui-select-match>\n" +
    "                                            <ui-select-choices\n" +
    "                                                repeat=\"item in (RankingsScoresCtrl.siteOptions | filter: $select.search) track by item.key\">\n" +
    "                                                <span ng-bind=\"item.label\"></span>\n" +
    "                                            </ui-select-choices>\n" +
    "\n" +
    "                                        </ui-select>\n" +
    "\n" +
    "                                    </div>\n" +
    "\n" +
    "                                    <div class=\"col-lg-4 col-xs-6 m-t\">\n" +
    "                                        <ui-select ng-model=\"RankingsScoresCtrl.filters.ranking\"\n" +
    "                                                   ng-change=\"RankingsScoresCtrl.getScoresInfo()\"\n" +
    "                                                   ng-disabled=\"!RankingsScoresCtrl.filters.site\">\n" +
    "                                            <ui-select-match placeholder=\"Ranking\">\n" +
    "                                                <span ng-bind=\"$select.selected.name\"></span>\n" +
    "                                            </ui-select-match>\n" +
    "                                            <ui-select-choices\n" +
    "                                                repeat=\"item.coreId as item in (RankingsScoresCtrl.rankingsOptions | rankingScoresFilter: RankingsScoresCtrl.filters.site  | filter: $select.search | orderBy:'name') track by item.id\"\n" +
    "                                                ui-disable-choice=\"!item.enabled\">\n" +
    "                                                <span ng-bind=\"item.name\"></span>\n" +
    "                                            </ui-select-choices>\n" +
    "\n" +
    "                                        </ui-select>\n" +
    "\n" +
    "                                    </div>\n" +
    "\n" +
    "                                    <div class=\"col-lg-2 col-xs-6 m-t\">\n" +
    "                                        <ui-select ng-model=\"RankingsScoresCtrl.filters.year\"\n" +
    "                                                   ng-change=\"RankingsScoresCtrl.getScoresInfo()\">\n" +
    "                                            <ui-select-match placeholder=\"Year\">\n" +
    "                                                <span ng-bind=\"$select.selected.label\"></span>\n" +
    "                                            </ui-select-match>\n" +
    "                                            <ui-select-choices\n" +
    "                                                repeat=\"item.key as item in (RankingsScoresCtrl.yearOptions | filter: $select.search) track by item.key\"\n" +
    "                                                ui-disable-choice=\"item.disabled\">\n" +
    "                                                <span ng-bind=\"item.label\"></span>\n" +
    "                                            </ui-select-choices>\n" +
    "\n" +
    "                                        </ui-select>\n" +
    "\n" +
    "                                    </div>\n" +
    "\n" +
    "                                    <div class=\"col-lg-3 col-xs-6 m-t\" ng-show=\"RankingsScoresCtrl.filters.site || RankingsScoresCtrl.filters.ranking || RankingsScoresCtrl.filters.year\">\n" +
    "                                        <button class=\"btn btn-warning\" ng-click=\"RankingsScoresCtrl.reset()\">\n" +
    "                                            <i class=\"fa fa-refresh\"></i>\n" +
    "                                            Reset Filters\n" +
    "                                        </button>\n" +
    "                                    </div>\n" +
    "                                </div>\n" +
    "\n" +
    "                            </div>\n" +
    "\n" +
    "\n" +
    "                        </div>\n" +
    "                        \n" +
    "                        <div ng-show=\"!RankingsScoresCtrl.loadingResults&&RankingsScoresCtrl.filtersComplete()\"\n" +
    "                             class=\"row m-t\">\n" +
    "                            <div class=\"col-xs-12\">\n" +
    "                                <div ng-show=\"RankingsScoresCtrl.filters.ranking && RankingsScoresCtrl.filters.year\"\n" +
    "                                     class=\"pull-left\">\n" +
    "                                    <button class=\"btn btn-primary\" ng-click=\"RankingsScoresCtrl.addIndicator()\">\n" +
    "                                        <i class=\"fa fa-plus\"></i> \n" +
    "                                        Add Indicator\n" +
    "                                    </button>\n" +
    "                                </div>\n" +
    "\n" +
    "                                <div ng-show=\"RankingsScoresCtrl.gridOptions.data.length===0\"\n" +
    "                                     class=\"pull-left m-l\">\n" +
    "                                    <button class=\"btn btn-primary\" ng-click=\"RankingsScoresCtrl.cloneLastYearData()\">\n" +
    "                                        <i class=\"fa fa-clone\"></i> \n" +
    "                                        <span\n" +
    "                                            ng-show=\"!RankingsScoresCtrl.hasSubjects\">Clone indicators from last year</span>\n" +
    "                                        <span ng-show=\"RankingsScoresCtrl.hasSubjects\">Clone indicators and subjects from last year</span>\n" +
    "                                    </button>\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div ng-show=\"RankingsScoresCtrl.loadingResults\" wave-spinner class=\"wave-spinner m-b-sm\">\n" +
    "                    </div>\n" +
    "                    <div class=\"section-body\"\n" +
    "                         ng-show=\"RankingsScoresCtrl.filtersComplete() && !RankingsScoresCtrl.loadingResults\">\n" +
    "                        <div class=\"grid\"\n" +
    "                             ui-grid=\"RankingsScoresCtrl.gridOptions\"\n" +
    "                             ui-grid-selection\n" +
    "                             ui-grid-cellnav\n" +
    "                             ui-grid-exporter\n" +
    "                             ui-grid-resize-columns\n" +
    "                             ui-grid-auto-resize\n" +
    "                             ui-grid-pagination>\n" +
    "                        </div>\n" +
    "                        <div ng-show=\"RankingsScoresCtrl.hasWeightings\" class=\"total-bar\">\n" +
    "                            <div class=\"total-label\">\n" +
    "                                <b>Total</b>\n" +
    "                            </div>\n" +
    "                            <div class=\"total\" ng-class=\"{'total-error':RankingsScoresCtrl.total!==100}\">\n" +
    "                                {{RankingsScoresCtrl.total}}%\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"section m-t\"\n" +
    "                 ng-show=\"RankingsScoresCtrl.filtersComplete() && !RankingsScoresCtrl.loadingResults && RankingsScoresCtrl.hasSubjects\">\n" +
    "                <div>\n" +
    "                    <div class=\"section-header\">\n" +
    "                        <div class=\"m-b\">\n" +
    "                            <h2>Subjects</h2>\n" +
    "                        </div>\n" +
    "                        <div class=\"m-b\">\n" +
    "                            <button ng-show=\"!RankingsScoresCtrl.loadingSubjects\" class=\"btn btn-primary\" ng-click=\"RankingsScoresCtrl.addSubject()\">\n" +
    "                                <i class=\"fa fa-plus\"></i> \n" +
    "                                Add subject\n" +
    "                            </button>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "\n" +
    "                    <div ng-show=\"RankingsScoresCtrl.loadingSubjects\" wave-spinner class=\"wave-spinner m-b-sm\">\n" +
    "                    </div>\n" +
    "\n" +
    "                    <div class=\"grid\"\n" +
    "                         ng-show=\"!RankingsScoresCtrl.loadingSubjects\"\n" +
    "                         ui-grid=\"RankingsScoresCtrl.subjectGridOptions\"\n" +
    "                         ui-grid-selection\n" +
    "                         ui-grid-cellnav\n" +
    "                         ui-grid-exporter\n" +
    "                         ui-grid-resize-columns\n" +
    "                         ui-grid-auto-resize\n" +
    "                         ui-grid-pagination>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"section m-t\"\n" +
    "                 ng-show=\"RankingsScoresCtrl.filtersComplete() && !RankingsScoresCtrl.loadingResults\">\n" +
    "                <div class=\"section-header\">\n" +
    "                    <h2>Import Rankings</h2>\n" +
    "                </div>\n" +
    "                <div class=\"section-body\">\n" +
    "                    <publish-rankings-history\n" +
    "                        ng-if=\"RankingsScoresCtrl.filtersComplete()\"\n" +
    "                        ranking-id=\"RankingsScoresCtrl.filters.ranking\"\n" +
    "                        year=\"RankingsScoresCtrl.filters.year\"\n" +
    "                        site=\"RankingsScoresCtrl.filters.site.key\"\n" +
    "                        import-complete-callback=\"RankingsScoresCtrl.importCompleteCallback()\">\n" +
    "                    </publish-rankings-history>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"section m-t\"\n" +
    "                 ng-show=\"RankingsScoresCtrl.filtersComplete() && !RankingsScoresCtrl.loadingResults\">\n" +
    "                <div ng-show=\"RankingsScoresCtrl.gettingPublishLogs\" wave-spinner class=\"wave-spinner m-b-sm\">\n" +
    "                </div>\n" +
    "                <div ng-show=\"!RankingsScoresCtrl.gettingPublishLogs\">\n" +
    "                    <div class=\"m-b\">\n" +
    "                        <h2>Publish Logs</h2>\n" +
    "                    </div>\n" +
    "                    <publish-logs ng-show=\"RankingsScoresCtrl.gridOptions.data\"\n" +
    "                                  logs=\"RankingsScoresCtrl.publishLogs\"\n" +
    "                                  ranking=\"RankingsScoresCtrl.ranking\"\n" +
    "                                  button-label=\"RankingsScoresCtrl.publishButtonLabel()\"></publish-logs>\n" +
    "\n" +
    "                    <div ng-show=\"RankingsScoresCtrl.loadingMorePublishLogs\" wave-spinner class=\"wave-spinner m-b-sm\">\n" +
    "                    </div>\n" +
    "\n" +
    "                    <div\n" +
    "                        ng-show=\"!RankingsScoresCtrl.loadingMorePublishLogs && RankingsScoresCtrl.publishLogs.length !==0 && RankingsScoresCtrl.hidePublishLoadMore!==true\"\n" +
    "                        class=\"text-center\">\n" +
    "                        <button class=\"btn btn-primary\" ng-click=\"RankingsScoresCtrl.loadMorePublishLogs()\">\n" +
    "                            <i class=\"fa fa-refresh\"></i>\n" +
    "                            Load More\n" +
    "                        </button>\n" +
    "                    </div>\n" +
    "\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "        </div>\n" +
    "        <div fixed-element-while-scrolling=\"#ranking-scores-datagrid\"\n" +
    "             id=\"edit-sidebar\"\n" +
    "             ng-show=\"RankingsScoresCtrl.selectedRow || RankingsScoresCtrl.selectedSubject\"\n" +
    "             class=\"edit-sidebar col-md-4 right-side-container\">\n" +
    "            <edit-scores-indicator\n" +
    "                ng-if=\"RankingsScoresCtrl.selectedRow\"\n" +
    "                has-weightings=\"RankingsScoresCtrl.hasWeightings\"\n" +
    "                year=\"RankingsScoresCtrl.filters.year\"\n" +
    "                ranking-id=\"RankingsScoresCtrl.filters.ranking\"\n" +
    "                indicator-options=\"RankingsScoresCtrl.indicators\"\n" +
    "                score-indicator=\"RankingsScoresCtrl.selectedRow\"\n" +
    "                delete-callback=\"RankingsScoresCtrl.deleteIndicatorCallback(id)\"\n" +
    "                update-callback=\"RankingsScoresCtrl.updateIndicatorCallback(id)\"\n" +
    "                close-callback=\"RankingsScoresCtrl.closeSidebarCallback()\">\n" +
    "            </edit-scores-indicator>\n" +
    "            <edit-subject\n" +
    "                ng-if=\"RankingsScoresCtrl.selectedSubject\"\n" +
    "                subject=\"RankingsScoresCtrl.selectedSubject\"\n" +
    "                year=\"RankingsScoresCtrl.filters.year\"\n" +
    "                ranking-id=\"RankingsScoresCtrl.filters.ranking\"\n" +
    "                subject-options=\"RankingsScoresCtrl.subjects\"\n" +
    "                indicator-options=\"RankingsScoresCtrl.gridOptions.data\"\n" +
    "                delete-callback=\"RankingsScoresCtrl.deleteSubjectCallback(id)\"\n" +
    "                update-callback=\"RankingsScoresCtrl.updateSubjectCallback()\"\n" +
    "                close-callback=\"RankingsScoresCtrl.closeSidebarCallback()\">\n" +
    "            </edit-subject>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/scopus/components/manageIds/manageIdsView.html',
    "<div class=\"wrapper wrapper-content animated fadeInRight\">\n" +
    "    <div class=\"row\">\n" +
    "        <div id=\"scopusTable\" ng-class=\"ManageIdsController.isRightSidePanelActive() ? 'col-sm-4 col-lg-3' : 'col-sm-12'\">\n" +
    "            <div class=\"section\">\n" +
    "                <div class=\"section-header\" ng-show=\"!ManageIdsController.isRightSidePanelActive()\">\n" +
    "                        <h2 class=\"no-top-margin\">Main institution data in Scopus</h2>\n" +
    "                        <p>Below are the main institutions in the Scopus data with a Scopus ID that starts with 6</p>\n" +
    "                        <p>Scopus name will be empty for all newly added Scopus ID's</p>\n" +
    "                    <div class=\"row form-inline m-t\">\n" +
    "                        <div class=\"col-lg-4 col-xs-6\">\n" +
    "                            <ui-select\n" +
    "                                class=\"ui-grid-filter-select no-padding\"\n" +
    "                                ng-model=\"ManageIdsController.selectedInstitution\"\n" +
    "                                theme=\"bootstrap\">\n" +
    "                                <ui-select-match\n" +
    "                                    placeholder=\"Search Institution\" allow-clear=\"true\">\n" +
    "                                    <i class=\"fa fa-building\"></i>\n" +
    "                                    <span>{{$select.selected.name}}</span>\n" +
    "                                </ui-select-match>\n" +
    "                                <ui-select-choices\n" +
    "                                    refresh-delay=\"500\"\n" +
    "                                    refresh=\"ManageIdsController.handleSearchInstitution($select.search)\"\n" +
    "                                    position=\"down\"\n" +
    "                                    minimum-input-length=\"1\"\n" +
    "                                    repeat=\"option in ManageIdsController.institutionsDropdownList | filter: $select.search\">\n" +
    "                                    <div class=\"test\" ng-bind-html=\"option.name | highlight: $select.search\"></div>\n" +
    "                                </ui-select-choices>\n" +
    "                            </ui-select>\n" +
    "                            <div class=\"spinner inner-spinner\" ng-show=\"ManageIdsController.searchInProgress\" wave-spinner></div>\n" +
    "                        </div>\n" +
    "                        <div class=\"col-lg-2 col-xs-2\">\n" +
    "                            <button class=\"btn btn-primary\"\n" +
    "                                    ng-disabled=\"!ManageIdsController.selectedInstitution\"\n" +
    "                                    ng-click=\"ManageIdsController.handleAdd()\"><i class=\"fa fa-plus\"></i><span>Add Scopus ID</span></button>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"section-body float-e-margins\">\n" +
    "                    <scopus-list selected-institution=\"ManageIdsController.selectedInstitution\">\n" +
    "                    </scopus-list>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"col-sm-8 col-lg-9\" ng-if=\"ManageIdsController.isRightSidePanelActive()\" fixed-element-while-scrolling=\"#scopusTable\">\n" +
    "            <scopus-add-edit selected-institution=\"ManageIdsController.selectedInstitution\"></scopus-add-edit>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/scopus/components/manageIds/scopusAddEdit/duplicateScopusDialogView.html',
    "<div class=\"modal-header\">\n" +
    "    <h3>{{ modalOptions.headerText }}</h3>\n" +
    "</div>\n" +
    "<div class=\"modal-body\">\n" +
    "    <div wave-spinner class=\"text-right\" ng-show=\"modalOptions.isLoading()\"></div>\n" +
    "    <div ng-show=\"!modalOptions.isLoading()\">\n" +
    "        <div ng-show=\"modalOptions.getAlreadyAssignedScopusIds().length\">\n" +
    "            <p>The following Scopus IDs are already assigned to the following Core IDs:</p>\n" +
    "            <ul class=\"list-group clear-list m-t\">\n" +
    "                <li ng-repeat=\"item in modalOptions.getAlreadyAssignedScopusIds()\" class=\"list-group-item\" ng-show=\"item.coreIds\">\n" +
    "                    <b>{{ item.scopusId }}</b> is assigned to <b>{{ item.coreIds }}</b>\n" +
    "                </li>\n" +
    "            </ul>\n" +
    "            <p>\n" +
    "                Please confirm that those Scopus IDs jointly owned by all these institutions.\n" +
    "            </p>\n" +
    "        </div>\n" +
    "       \n" +
    "    </div>\n" +
    "</div>\n" +
    "<div class=\"modal-footer\" ng-show=\"!modalOptions.isLoading()\">\n" +
    "    <button type=\"button\" class=\"btn btn-default\" ng-click=\"modalOptions.close()\">\n" +
    "        {{ modalOptions.closeButtonText }}\n" +
    "    </button>\n" +
    "    <button class=\"btn {{ modalOptions.actionButtonClass || 'btn-primary'}}\" ng-click=\"modalOptions.confirm()\">\n" +
    "        {{ modalOptions.actionButtonText }}\n" +
    "    </button>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/scopus/components/manageIds/scopusAddEdit/scopusAddEditView.html',
    "<div class=\"ibox\">\n" +
    "    <div class=\"ibox-title\">\n" +
    "        <h5>{{$ctrl.selectedItem ? 'Edit Scopus' : 'Add Scopus'}}</h5>\n" +
    "        <div class=\"ibox-tools\">\n" +
    "            <a ng-click=\"$ctrl.closeRightSidePanel()\">\n" +
    "                <i class=\"fa fa-times\"></i>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"ibox-content\">\n" +
    "        <form id=\"scopusForm\" name=\"$ctrl.scopusForm\">\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col-sm-4\">\n" +
    "                    <label class=\"control-label\">\n" +
    "                        Institution Name:\n" +
    "                    </label><br>\n" +
    "                    {{ $ctrl.scopusData.institutionName }}<br>\n" +
    "                    <label class=\"control-label\">\n" +
    "                        Core ID:\n" +
    "                    </label><br>\n" +
    "                    {{ $ctrl.scopusData.coreId }}<br>\n" +
    "                    <div class=\"row\">\n" +
    "                        <div class=\"col-sm-12 m-b-md\">\n" +
    "                            <label class=\"control-label\">\n" +
    "                                Scopus ID's assigned:\n" +
    "                            </label><br>\n" +
    "                            <div wave-spinner class=\"absolute-wave-spinner\" ng-show=\"$ctrl.showLoadBar()\"></div>\n" +
    "                            <span ng-show=\"!$ctrl.showLoadBar()\" ng-repeat=\"item in $ctrl.selectedInstitutionScopusIds track by $index\">\n" +
    "                                {{$last ? item.scopusId : item.scopusId+',' }}\n" +
    "                            </span>\n" +
    "                            <span ng-show=\"!$ctrl.showLoadBar() && !$ctrl.selectedInstitutionScopusIds.length\">\n" +
    "                                None\n" +
    "                            </span>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"col-sm-8\">\n" +
    "                    <div class=\"row\" ng-repeat=\"item in $ctrl.scopusData.scopus track by $index\">\n" +
    "                        <div class=\"col-lg-3 col-sm-5 form-group\" ng-class=\"{'has-errors': !$ctrl.isValidScopusId(item.scopusId) || item.isAlreadyExist}\">\n" +
    "                            <label ng-if=\"$index === 0\">Scopus ID*</label>\n" +
    "                            <input type=\"number\"\n" +
    "                                name=\"scopusId\"\n" +
    "                                ng-model=\"item.scopusId\"\n" +
    "                                class=\"form-control hide-arrows\"\n" +
    "                                placeholder=\"Scopus ID\"\n" +
    "                                min=\"1\"\n" +
    "                                ng-required=\"true\"\n" +
    "                                ng-change=\"item.isAlreadyExist = false\"\n" +
    "         \n" +
    "                                custom-popover\n" +
    "                                popover-html=\"{{item.isAlreadyExist ? 'Scopus ID is already assigned to this institution' : 'Value cannot be empty, zero, decimal and negative'}}\"\n" +
    "                                popover-placement=\"left\"\n" +
    "                                popover-trigger=\"manual\"\n" +
    "                                popover-visibility=\"{{!$ctrl.isValidScopusId(item.scopusId) || item.isAlreadyExist ? true : false}}\">\n" +
    "                        </div>\n" +
    "                        <div class=\"col-lg-8 col-sm-5 form-group\" ng-class=\"{'has-errors': !$ctrl.isValidScopusName(item.scopusName)}\">\n" +
    "                            <label ng-if=\"$index === 0\">Scopus Name</label>\n" +
    "                            <input type=\"text\"\n" +
    "                                class=\"form-control\"\n" +
    "                                placeholder=\"Scopus Name\"\n" +
    "                                ng-model=\"item.scopusName\"\n" +
    "\n" +
    "                                custom-popover\n" +
    "                                popover-html=\"Invalid value\"\n" +
    "                                popover-placement=\"left\"\n" +
    "                                popover-trigger=\"manual\"\n" +
    "                                popover-visibility=\"{{!$ctrl.isValidScopusName(item.scopusName)}}\">\n" +
    "                        </div>\n" +
    "                        <div class=\"col-sm-1\" ng-if=\"!$ctrl.selectedItem\">\n" +
    "                            <i class=\"fa fa-plus-circle fa-lg mt-35px pointer\"\n" +
    "                                ng-click=\"$ctrl.addNewItem()\"\n" +
    "                                ng-if=\"$index === 0\"></i>\n" +
    "                            <i class=\"fa fa-minus-circle fa-lg m-t-sm pointer\"\n" +
    "                                ng-click=\"$ctrl.removeItem($index)\"\n" +
    "                                ng-if=\"$index !== 0\"></i>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"row m-t\">\n" +
    "                        <div class=\"form-group\">\n" +
    "                            <div class=\"col-sm-8 col-sm-offset-3\">\n" +
    "                                <a ng-if=\"!$ctrl.selectedItem\"\n" +
    "                                    class=\"btn btn-primary btn-sm pull-right\"\n" +
    "                                    ng-class=\"{'disabled': $ctrl.buttonDisabled()}\"\n" +
    "                                    ng-click=\"$ctrl.handleSave()\">\n" +
    "                                    <span>Save</span>\n" +
    "                                </a>\n" +
    "                \n" +
    "                                <a ng-if=\"$ctrl.selectedItem\"\n" +
    "                                    class=\"btn btn-primary btn-sm pull-right\"\n" +
    "                                    ng-class=\"{'disabled': $ctrl.buttonDisabled()}\"\n" +
    "                                    ng-click=\"$ctrl.handleUpdate()\">\n" +
    "                                    <i class=\"fa fa-check-circle\"></i>\n" +
    "                                    <span> Update </span>\n" +
    "                                </a>\n" +
    "                                <a class=\"btn btn-default btn-sm pull-right\"\n" +
    "                                    ng-click=\"$ctrl.closeRightSidePanel()\">\n" +
    "                                    <i class=\"fa fa-ban\"></i>\n" +
    "                                    <span>Cancel</span>\n" +
    "                                </a>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            \n" +
    "        </form>\n" +
    "    </div>\n" +
    "</div>\n" +
    "    "
  );


  $templateCache.put('/scripts/modules/rankings/components/scopus/components/manageIds/scopusList/scopusListView.html',
    "<div wave-spinner class=\"absolute-wave-spinner\" ng-show=\"$ctrl.showLoadBar()\"></div>\n" +
    "<div ng-class=\"{'modal-overlay-35': $ctrl.showLoadBar()}\" ng-if=\"$ctrl.gridOptions.data\">\n" +
    "    <ui-grid-info ng-show=\"$ctrl.showInfoBlock\"></ui-grid-info>\n" +
    "\n" +
    "    <div class=\"grid\"\n" +
    "         ui-grid=\"$ctrl.gridOptions\"\n" +
    "         ui-grid-selection\n" +
    "         ui-grid-resize-columns\n" +
    "         ui-grid-auto-resize\n" +
    "         ui-grid-cellnav\n" +
    "         ui-grid-exporter\n" +
    "         ui-grid-pagination></div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/scopus/components/statistics/scopusStatisticsPublishLogs/scopusStatisticsPublishLogsView.html',
    "<div class=\"ibox\">\n" +
    "    <div class=\"ibox-title\">\n" +
    "        <h5>Scopus Statistics Publish Logs</h5>\n" +
    "    </div>\n" +
    "    <div class=\"ibox-content publish-logs\">\n" +
    "        <div wave-spinner class=\"text-right\" ng-show=\"$ctrl.isFetchInProgress()\"></div>\n" +
    "\n" +
    "        <div ng-show=\"!$ctrl.isFetchInProgress()\">\n" +
    "            <strong ng-show=\"!$ctrl.logsLength\" class=\"text-navy\">No results</strong>\n" +
    "\n" +
    "            <div ng-show=\"$ctrl.logs\">\n" +
    "                <div ng-repeat=\"logsPerDay in $ctrl.logs track by $index\" class=\"record border-bottom m-b\">\n" +
    "                    <div class=\"panel panel-default\">\n" +
    "                        <div class=\"panel-heading\">\n" +
    "                            <span>{{logsPerDay[0].createdAt | date:'mediumDate'}}</span>\n" +
    "                        </div>\n" +
    "                        <div class=\"panel-body\">\n" +
    "                            <ul class=\"list-unstyled\">\n" +
    "                                <li ng-repeat=\"item in logsPerDay track by $index\">\n" +
    "                                    <span class=\"icon\" ng-class=\"$ctrl.getLogClassColor(item.status)\">\n" +
    "                                        <i class=\"fa fa-check\" ng-class=\"$ctrl.getLogClassFaIcon(item.status)\"></i>\n" +
    "                                    </span>\n" +
    "                                    Scopus statistics published  by <span class=\"bold\">{{ item.createdByFullName }}</span> at <small class=\"time\">{{ item.createdAt | date:'shortTime'}}</small>\n" +
    "                                </li>\n" +
    "                            </ul>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"row\" ng-show=\"$ctrl.isMoreLogsAvailable()\">\n" +
    "                    <div class=\"col-lg-2 col-lg-offset-5\">\n" +
    "                        <a class=\"btn btn-primary btn-block\"\n" +
    "                           ng-click=\"$ctrl.handleLoadMore()\">\n" +
    "                            Load more\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/modules/rankings/components/scopus/components/statistics/scopusStatisticsView.html',
    "<div  class=\"wrapper wrapper-content animated fadeInRight\">\n" +
    "        <div class=\"ibox\">\n" +
    "            <div class=\"ibox-title form-group\">\n" +
    "                <h5> Scopus Mapped Institution Stats </h5><br>\n" +
    "               <p class=\"clearfix\">Below are few statistics based on the mapping of Scopus ID's to Core ID's</p>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"ibox-content\">\n" +
    "                <div wave-spinner ng-show=\"ScopusStatisticsController.fetchInProgress\"></div>\n" +
    "                <div class=\"row\" ng-show=\"ScopusStatisticsController.statistics\">\n" +
    "                    <div class=\"col-lg-6 col-sm-12\">\n" +
    "                        <table class=\"table table-bordered\">\n" +
    "                            <thead>\n" +
    "                                <tr>\n" +
    "                                <th>Statistics Type</th>\n" +
    "                                <th>Result</th>\n" +
    "                                </tr>\n" +
    "                            </thead>\n" +
    "                            <tbody>\n" +
    "                                <tr>\n" +
    "                                    <td>Total Number Of Mappings</td>\n" +
    "                                    <td>{{ScopusStatisticsController.statistics.totalNumberOfMappings}}</td>\n" +
    "                                </tr>\n" +
    "                                <tr>\n" +
    "                                    <td>Total Number Of Institutions</td>\n" +
    "                                    <td>{{ScopusStatisticsController.statistics.totalNumberOfInstitutions}}</td>\n" +
    "                                </tr>\n" +
    "                                <tr>\n" +
    "                                    <td>Average Number Of Mappings Per Institution</td>\n" +
    "                                    <td>{{ScopusStatisticsController.statistics.averageNumberOfMappingsPerInstitution}}</td>\n" +
    "                                </tr>\n" +
    "                                <tr>\n" +
    "                                    <td>New Mappings Added By QS</td>\n" +
    "                                    <td>{{ScopusStatisticsController.statistics.newMappingsAddedByQs}}</td>\n" +
    "                                </tr>\n" +
    "                                <tr>\n" +
    "                                    <td>New Institutions Added By QS</td>\n" +
    "                                    <td>{{ScopusStatisticsController.statistics.newInstitutionsAddedByQs}}</td>\n" +
    "                                </tr>\n" +
    "                                <tr>\n" +
    "                                    <td>New mappings/institutions added since last published</td>\n" +
    "                                    <td>{{ScopusStatisticsController.statistics.changesSinceLastPublished ? 'Yes' : 'No'}}</td>\n" +
    "                                </tr>\n" +
    "                            </tbody>\n" +
    "                        </table>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-lg-6 col-sm-12 publish\">\n" +
    "                        <div progress-circle=\"ScopusStatisticsController.publishStatus\"></div>\n" +
    "                        <div class=\"m-t text-center\">\n" +
    "                            <div class=\"col-lg-12\">\n" +
    "                                <button class=\"m-t btn btn-primary\"\n" +
    "                                    ng-click=\"ScopusStatisticsController.handlePublish()\"\n" +
    "                                    ng-disabled=\"!!ScopusStatisticsController.publishing\">\n" +
    "                                    <i class=\"fa fa-cloud-upload\"></i>\n" +
    "                                    Publish\n" +
    "                                </button>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <scopus-statistics-publish-logs></scopus-statistics-publish-logs>\n" +
    "    </div>"
  );


  $templateCache.put('/scripts/modules/rankings/components/statisticsDashboard/enquiryForm/enquiryFormView.html',
    "<div class=\"ibox-title\">\n" +
    "    <h5>Enquiry Form</h5>\n" +
    "    <div class=\"ibox-tools\">\n" +
    "        <a class=\"close-link\" ng-click=\"$ctrl.closeForm()\">\n" +
    "            <i class=\"fa fa-times\"></i>\n" +
    "        </a>\n" +
    "    </div>\n" +
    "</div>\n" +
    "<div class=\"ibox-content\">\n" +
    "    <div wave-spinner class=\"wave-spinner\" ng-show=\"$ctrl.isLoading()\"></div>\n" +
    "    <form class=\"form-horizontal enquiryForm\" name=\"forms.enquiryForm\" novalidate autocomplete=\"off\" ng-hide=\"$ctrl.isLoading()\">\n" +
    "        <div class=\"form-group\">\n" +
    "            <label class=\"control-label col-lg-2 col-md-2 col-sm-6\">Name</label>\n" +
    "            <div class=\"col-lg-10 col-md-10 col-sm-6\">\n" +
    "                <span class=\"text-label\">{{$ctrl.enquiryFormData.name}}</span>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"form-group\">\n" +
    "            <label class=\"control-label col-lg-2 col-md-2 col-sm-6\">Email</label>\n" +
    "            <div class=\"col-lg-10 col-md-10 col-sm-6\">\n" +
    "                <span class=\"text-label word-break-all\">{{$ctrl.enquiryFormData.email}}</span>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"form-group\">\n" +
    "            <div class=\"col-lg-12 col-md-12 col-sm-12\">\n" +
    "                <hr />\n" +
    "                <p><strong>I would like to enquire about:</strong></p>\n" +
    "                <span class=\"text-label\">\n" +
    "                    <div class=\"rankings\" ng-repeat=\"(key, value) in $ctrl.rankings\">\n" +
    "                        <div class=\"checkbox-inline i-checkbox\" ng-class=\"{'invalid-checkbox': $ctrl.invalidRankingSelection}\">\n" +
    "                            <label>\n" +
    "                                <input i-checkbox type=\"checkbox\" ng-model=\"$ctrl.enquiryFormData.rankings[key]\" ng-change=\"$ctrl.rankingsChanged()\" />\n" +
    "                                <span> {{value}}</span>\n" +
    "                            </label>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </span>\n" +
    "                <div\n" +
    "                    focus-delay=\"250\"\n" +
    "                    custom-popover\n" +
    "                    popover-html=\"please select at least one\"\n" +
    "                    popover-placement=\"left\"\n" +
    "                    popover-trigger=\"manual\"\n" +
    "                    popover-visibility=\"{{$ctrl.invalidRankingSelection  ? true : false}}\">\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"form-group\" ng-class=\"{'has-errors': $ctrl.isInvalidMessage()}\">\n" +
    "            <div class=\"col-lg-12 col-md-12 col-sm-12\">\n" +
    "                <div class=\"textarea\">\n" +
    "                    <textarea rows=\"7\" cols=\"50\" placeholder=\"Please add your message\" ng-model=\"$ctrl.enquiryFormData.comments\" class=\"form-control no-resize\"\n" +
    "                     custom-popover\n" +
    "                     popover-html=\"Please add a message\"\n" +
    "                     popover-placement=\"left\"\n" +
    "                     popover-trigger=\"manual\"\n" +
    "                     popover-visibility=\"{{$ctrl.isInvalidMessage()}}\"\n" +
    "                     ng-change=\"$ctrl.messageWords = $ctrl.countWords($ctrl.enquiryFormData.comments)\"></textarea>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"pull-right\">\n" +
    "                <span ng-class=\"{'text-red': $ctrl.isMessageCountInvalid()}\">{{$ctrl.messageWords}}/{{$ctrl.maxWords}} words</span>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"form-group\">\n" +
    "            <div class=\"col-sm-12\">\n" +
    "                <a class=\"btn btn-primary pull-right\"\n" +
    "                   ng-click=\"$ctrl.sendEnquiry()\">\n" +
    "                    <i class=\"fa fa-check-circle\"></i>\n" +
    "                    <span>Send</span>\n" +
    "                </a>\n" +
    "                <a class=\"btn btn-default pull-right\" ng-click=\"$ctrl.closeForm()\">\n" +
    "                    <i class=\"fa fa-ban\"></i>\n" +
    "                    <span>Close</span>\n" +
    "                </a>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </form>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/modules/rankings/components/statisticsDashboard/statisticsAddNote/statisticsAddNoteView.html',
    "<div class=\"ibox publish-logs\">\n" +
    "    <div class=\"m-t\">\n" +
    "\n" +
    "        <div wave-spinner ng-show=\"$ctrl.isLoading()\"></div>\n" +
    "        <div ng-show=\"!$ctrl.isLoading()\">\n" +
    "            <p>Add new note</p>\n" +
    "\n" +
    "            <div class=\"summernote-container\" ng-class=\"{'has-errors': !$ctrl.isValid()}\">\n" +
    "                <summernote\n" +
    "                    config=\"$ctrl.textEditorOptions\"\n" +
    "                    on-focus=\"$ctrl.handleNoteFocus()\"\n" +
    "                    ng-model=\"$ctrl.note\"\n" +
    "                    on-change=\"$ctrl.noteWords = $ctrl.countWords(contents)\"\n" +
    "                    on-init=\"$ctrl.noteWords = $ctrl.countWords($ctrl.note)\"\n" +
    "                    count-word=\"$ctrl.noteWords\"\n" +
    "                    placeholder=\"Add message...\">\n" +
    "                </summernote>\n" +
    "            </div>\n" +
    "\n" +
    "            <div>\n" +
    "                <div class=\"pull-left\">\n" +
    "                    <a class=\"btn btn-primary btn-xs\"\n" +
    "                       ng-click=\"$ctrl.handleAdd()\">\n" +
    "                       <span class=\"fa fa-plus\"></span> Add\n" +
    "                    </a>\n" +
    "                    <a class=\"btn btn-default btn-xs\"\n" +
    "                       ng-click=\"$ctrl.handleCancel()\">\n" +
    "                        Cancel\n" +
    "                    </a>\n" +
    "                </div>\n" +
    "                <div class=\"pull-right\">\n" +
    "                    <span ng-class=\"{'text-red': !$ctrl.isValid()}\">{{$ctrl.noteWords}}/{{$ctrl.maxWords}} words</span>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "        </div>\n" +
    "\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/statisticsDashboard/statisticsAvailable/statisticsAvailableView.html',
    "<div ng-show=\"$ctrl.statisticsUnSubscribed.length\">\n" +
    "    <div class=\"page-header clearfix\">\n" +
    "        <h3>All QS rankings - Not eligible</h3>\n" +
    "    </div>\n" +
    "    <div class=\"clearfix\">\n" +
    "        <div class=\"row\">\n" +
    "            <div class=\"col-sm-6 col-md-3\" ng-repeat=\"item in $ctrl.statisticsUnSubscribed\">\n" +
    "                <div class=\"ibox ibox-inverse grid-view\">\n" +
    "                    <div class=\"ibox-content product-box\">\n" +
    "                        <div class=\"preview\">\n" +
    "                            <img ng-src=\"{{'/images/rankings/' + item.handle + '.png'}}\"/>\n" +
    "                        </div>\n" +
    "                        <div class=\"product-desc info clearfix\">\n" +
    "                            <h3 class=\"product-name title\">{{item.name}}</h3>\n" +
    "\n" +
    "                            <div class=\"clearfix\">\n" +
    "                                <p class=\"pull-left\"><i class=\"fa fa-exclamation-triangle\" aria-hidden=\"true\"></i> <span>Not Included</span></p>\n" +
    "                                <a class=\"pull-right\" ng-if=\"item.handle !== 'ger'\" ng-href=\"https://support.qs.com/hc/en-gb/articles/4405027974290-Data-Appendix\" target=\"_blank\">\n" +
    "                                    <span>Click Here For Definitions & Guidelines</span>\n" +
    "                                </a>\n" +
    "                                <a class=\"pull-right\" ng-if=\"item.handle === 'ger'\" ng-href=\"https://support.qs.com/hc/en-gb/articles/4409192555282-Graduate-Employability-Ranking-Guidelines\" target=\"_blank\">\n" +
    "                                    <span>Click Here For Definitions & Guidelines</span>\n" +
    "                                </a>\n" +
    "                                <!-- <div class=\"pull-right\" uib-dropdown>\n" +
    "                                    <a ng-click=\"\" uib-dropdown-toggle>\n" +
    "                                        <i class=\"fa fa-download\" aria-hidden=\"true\"></i> Guideline\n" +
    "                                        <i class=\"fa fa-angle-down\"></i>\n" +
    "                                    </a>\n" +
    "                                    <statistics-languages ranking-handle=\"item.handle\" class=\"dropdown-menu\"></statistics-languages>\n" +
    "                                </div> -->\n" +
    "                            </div>\n" +
    "\n" +
    "                            <hr class=\"clearfix\">\n" +
    "\n" +
    "                            <button class=\"btn btn-primary btn-xs pull-left\" ng-click=\"$ctrl.toggleEnquiryForm(item.handle)\">\n" +
    "                                <i class=\"fa fa-chevron-right\"></i>\n" +
    "                                <span>Make an enquiry</span>\n" +
    "                            </button>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/statisticsDashboard/statisticsDashboardView.html',
    "<div ng-show=\"!$state.$current.data.rankings\" class=\"wrapper wrapper-content animated fadeInRight page-statistics-dashboard\">\n" +
    "    <div class=\"row\">\n" +
    "        <div id=\"statisticsDashboard\" ng-class=\"StatisticsDashboardController.isEnquiryFormActive() ? 'col-lg-9' : 'col-lg-12'\">\n" +
    "            <div class=\"ibox float-e-margins\">\n" +
    "                <div class=\"ibox-content table-responsive\">\n" +
    "                    <div wave-spinner class=\"wave-spinner\" ng-show=\"StatisticsDashboardController.isLoading()\"></div>\n" +
    "\n" +
    "                    <div ng-show=\"!StatisticsDashboardController.isLoading()\">\n" +
    "                        <div class=\"alert alert-success\">\n" +
    "                            <div class=\"row\">\n" +
    "                                <div class=\"col-lg-9\">\n" +
    "                                <span class=\"block m-t-xs\"><i class=\"fa fa-star-o\"></i>\n" +
    "                                    You can provide a complete QS data profile here\n" +
    "                                </span>\n" +
    "                                </div>\n" +
    "                                <div class=\"col-lg-3\">\n" +
    "                                    <a class=\"btn btn-primary btn-sm btn-block\" ui-sref=\"clients.statistics.data-submission.all\">\n" +
    "                                        <i class=\"fa fa-star\"></i><span>Let's get started</span>\n" +
    "                                    </a>\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                        <div class=\"alert alert-success\">\n" +
    "                            <div class=\"row\">\n" +
    "                                <div class=\"col-lg-12\">\n" +
    "                                    <span class=\"block m-t-xs\"><i class=\"fa fa-star-o\"></i>\n" +
    "                                        The QS World University Ranking 2024 launches on June 27th, 2023. Please check our calendar for dates and times of all upcoming rankings <a href=\"https://www.qs.com/rankings-calendar/\" target=\"_blank\">Rankings Calendar - QS</a> \n" +
    "                                    </span>\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "\n" +
    "                        <statistics-subscribed></statistics-subscribed>\n" +
    "                        <statistics-available></statistics-available>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div fixed-element-while-scrolling=\"#statisticsDashboard\" class=\"col-lg-3 scroll-floating-element right-side-container\"\n" +
    "             ng-show=\"StatisticsDashboardController.isEnquiryFormActive()\">\n" +
    "                <statistics-enquiry-form></statistics-enquiry-form>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "<div ng-show=\"$state.$current.data.rankings\" class=\"page-statistics-submission\" ui-view></div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/statisticsDashboard/statisticsDefinition/statisticsDefinitionView.html',
    "<div class=\"ibox\">\n" +
    "    <div class=\"panel-body\">\n" +
    "\n" +
    "        <div wave-spinner ng-show=\"$ctrl.isLoading()\"></div>\n" +
    "        \n" +
    "        <div ng-show=\"!$ctrl.isLoading()\">\n" +
    "            <div ng-show=\"$ctrl.definition.length\">\n" +
    "                <div ng-bind-html=\"$ctrl.definition\"></div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div ng-show=\"!$ctrl.definition.length\">\n" +
    "                No definition\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/statisticsDashboard/statisticsHistory/statisticsHistoryView.html',
    "<div class=\"ibox statistics-logs\">\n" +
    "    <div class=\"panel-body p-b-none\">\n" +
    "\n" +
    "        <div wave-spinner ng-show=\"$ctrl.isLoading()\"></div>\n" +
    "\n" +
    "        <div ng-show=\"!$ctrl.isLoading()\">\n" +
    "            <div ng-show=\"$ctrl.logs.results.length\">\n" +
    "                <ul class=\"list-unstyled list-history break-word\">\n" +
    "                    <li ng-show=\"key < $ctrl.logsToDisplay\" ng-repeat=\"(key, log) in $ctrl.logs.results\">\n" +
    "                        <div>\n" +
    "                            <span class=\"status\">\n" +
    "                                <span class=\"icon text-navy\" ng-if=\"log.status === $ctrl.status.accepted\">\n" +
    "                                    <i class=\"fa fa-check-circle\"></i>\n" +
    "                                </span>\n" +
    "                                <span class=\"icon text-danger\" ng-if=\"log.status === $ctrl.status.rejected\">\n" +
    "                                    <i class=\"fa fa-times-circle\"></i>\n" +
    "                                </span>\n" +
    "                                <span class=\"icon text-info\" ng-if=\"log.status === $ctrl.status.queried\">\n" +
    "                                    <i class=\"fa fa-question-circle\"></i>\n" +
    "                                </span>\n" +
    "                                <span class=\"icon text-warning\" ng-if=\"log.status === $ctrl.status.pending\">\n" +
    "                                    <i class=\"fa fa-hourglass-half\"></i>\n" +
    "                                </span>\n" +
    "                                <span class=\"icon\" ng-if=\"!log.status\">\n" +
    "                                    <i class=\"fa fa fa-chevron-down\"></i>\n" +
    "                                </span>\n" +
    "                            </span>\n" +
    "                            <span class=\"download\" ng-if=\"log.sourceFile\">\n" +
    "                                <a class=\"icon clickable\" target=\"_self\" href=\"https://{{log.sourceFile}}\">\n" +
    "                                    <i class=\"fa fa-download\"></i>\n" +
    "                                </a>\n" +
    "                            </span>\n" +
    "                            <span class=\"bold\">{{log.createdAt | date:'longDate'}}</span> by: <span class=\"bold\">{{log.createdByFullName}}</span>\n" +
    "                            <small class=\"text-muted text-lowercase\">{{log.createdByUserName}}</small>\n" +
    "                        </div>\n" +
    "\n" +
    "                        <div class=\"row\" ng-if=\"!$ctrl.isSustainabilityQuestion(log.questionHandle) && ($ctrl.activeQuestionType === $ctrl.type.personal || $ctrl.activeQuestionType === $ctrl.type.socialImpact \n" +
    "                        || $ctrl.activeQuestionType === $ctrl.type.environmentalImpact || $ctrl.activeQuestionType === $ctrl.type.governance\n" +
    "                        || $ctrl.activeQuestionType === $ctrl.type.additionalInformation)\">\n" +
    "                            <div class=\"answers\"\n" +
    "                                 ng-class=\"type === 'fte' || type === 'url' || type === 'checked' ? 'col-xs-12' : 'col-xs-4'\"\n" +
    "                                 ng-repeat=\"(type, answer) in log.answers\">\n" +
    "                                 <!-- {{type}} -->\n" +
    "                                 <div ng-show=\"type === 'checkBox'\">\n" +
    "                                    <span>{{answer.value === true? 'Yes' : 'No'}}</span>\n" +
    "                                 </div>\n" +
    "                                 <div ng-show=\"type !== 'checkBox' && type !== 'url' && type !== 'fileUrl' && type !== 'checked' \">\n" +
    "                                    <span class=\"text-uppercase\">{{type}} : </span><span>{{answer.value}}</span> \n" +
    "                                    <span ng-if=\"answer.estimate\">(estimated)</span>\n" +
    "                                    <!-- <span ng-if=\"answer.notAvailable\">(N/A)</span>\n" +
    "                                    <span ng-if=\"answer.publiclyAvailable\">(Publicly Available)</span> -->\n" +
    "                                </div>\n" +
    "                                <!-- <div >\n" +
    "                                    <span class=\"download\" ng-show=\"type === 'fileUrl'\">\n" +
    "                                        <a class=\"icon clickable\" target=\"_self\" href=\"{{answer.value}}\">\n" +
    "                                            <i class=\"fa fa-download\"></i>\n" +
    "                                        </a>\n" +
    "                                    </span>\n" +
    "                                </div> -->\n" +
    "                                <div ng-show=\"type !== 'checkBox' && $ctrl.isUrlField(type, log.questionHandle)\">\n" +
    "                                    <span class=\"text-uppercase\">{{type}} : </span><span>{{answer.value}}</span> \n" +
    "                                    <!-- <span ng-if=\"answer.notAvailable\">(N/A)</span>\n" +
    "                                    <span ng-if=\"answer.publiclyAvailable\">(Publicly Available)</span> -->\n" +
    "                                </div>\n" +
    "                                <div ng-show=\"type === 'checked'\">\n" +
    "                                    <span class=\"text-uppercase\">{{type}} : </span><span>{{answer.value === true? 'Yes' : 'No'}}</span>\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                        <div class=\"row\" ng-if=\"$ctrl.isSustainabilityQuestion(log.questionHandle)\">\n" +
    "                            <div class=\"answers col-xs-12\" ng-repeat=\"(type, answer) in log.answers\">\n" +
    "                                <div ng-show=\"type === 'currentEdi' && answer.value\">\n" +
    "                                    {{answer.label}}\n" +
    "                                    <b> {{answer.valueAsString}}</b>\n" +
    "                                    </br>\n" +
    "                                    <span ng-show=\"answer.url\"><b>Evidence:</b> {{answer.url}}</span><br><br>\n" +
    "                                    <span>Which of the following main protected characteristics are included in this\n" +
    "                                        policy?</span><br>\n" +
    "\n" +
    "                                </div>\n" +
    "                                <div\n" +
    "                                    ng-if=\"answer.value === true && type !== 'currentEdi' && log.questionHandle === 'cuurent_equality_diversity_and_inclusionn'\">\n" +
    "                                    <b>-</b> {{answer.label}}\n" +
    "                                    </br>\n" +
    "                                </div>\n" +
    "                                <div\n" +
    "                                    ng-if=\"log.questionHandle !== 'cuurent_equality_diversity_and_inclusionn' && type !== 'url'\">\n" +
    "                                    <div ng-if=\"type !== 'presenceOfSearchText'\">\n" +
    "                                        {{type === 'checked'? 'Checked' : answer.label}}\n" +
    "                                        <b> : <span ng-bind-html=\"answer.valueAsString\"></span></b>\n" +
    "                                        </br>\n" +
    "                                    </div>\n" +
    "                                    <div ng-if=\"answer.url || answer.secondUrl\">\n" +
    "                                        <span ng-show=\"answer.url\">\n" +
    "                                            <b>Evidence:</b> {{answer.url}}\n" +
    "                                            </br>\n" +
    "                                        </span>\n" +
    "\n" +
    "                                        <span ng-show=\"answer.secondUrl\">\n" +
    "                                            <b>Evidence:</b> {{answer.secondUrl}}\n" +
    "                                        </span>\n" +
    "                                    </div>\n" +
    "                                    <div ng-if=\"answer.text\">\n" +
    "                                        <b>Evidence:</b> <span ng-bind-html=\"answer.text\"></span>\n" +
    "                                        </br>\n" +
    "                                    </div>\n" +
    "                                    <div ng-if=\"type === 'presenceOfSearchText'\">\n" +
    "                                        <b>Evidence:</b> <span ng-bind-html=\"answer.value\"></span>\n" +
    "                                    </div>\n" +
    "                                    <hr>\n" +
    "                                </div>\n" +
    "\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "\n" +
    "                        <div ng-if=\"$ctrl.activeQuestionType === $ctrl.type.financial\">\n" +
    "                            <span>{{log.answers.value}}</span><span ng-if=\"log.answers.notAvailable\"> (N/A)</span>  <span class=\"text-uppercase\">{{log.answers.currency}}</span>\n" +
    "                        </div>\n" +
    "                        <div ng-if=\"$ctrl.activeQuestionType === $ctrl.type.total\">\n" +
    "                            <span>{{log.answers.value}}</span>\n" +
    "                        </div>\n" +
    "\n" +
    "                        <div ng-if=\"log.answers.employers_connection_online\">\n" +
    "                            Value: {{log.answers.employers_connection_online.value}}\n" +
    "                        </div>\n" +
    "\n" +
    "                        <div ng-if=\"log.answers.employers_connection_on_campus\">\n" +
    "                            Value: {{log.answers.employers_connection_on_campus.value}}\n" +
    "                        </div>\n" +
    "\n" +
    "                        <div ng-if=\"log.answers.employer_partnerships_question\">\n" +
    "                            Value: {{log.answers.employer_partnerships_question.value}}\n" +
    "                        </div>\n" +
    "\n" +
    "                        <ger-employment-rate-log handle=\"$ctrl.gerQuestionHandle\" data=\"log\"></ger-employment-rate-log>\n" +
    "                        <!-- <div ng-if=\"log.previouslySubmitted === true ? true : log.previouslySubmitted === false ? true : false\"\n" +
    "                            class=\"text-capitalize\">Previously submitted data:\n" +
    "                            {{log.previouslySubmitted === true ? 'Yes' : 'No'}}</div> -->\n" +
    "                        <div ng-if=\"log.source && $ctrl.hasSource\" class=\"text-capitalize\">Source: {{log.source}}</div>\n" +
    "                        <div ng-if=\"log.sourceFile\">File Uploaded</div>\n" +
    "                     </li>\n" +
    "                </ul>\n" +
    "\n" +
    "            </div>\n" +
    "\n" +
    "            <div ng-show=\"!$ctrl.logs.results.length\">\n" +
    "                No history logs\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"row load-more\" ng-show=\"$ctrl.isMoreLogsAvailable()\">\n" +
    "                <div class=\"m-l-lg m-r-lg\">\n" +
    "                    <a class=\"btn btn-primary btn-block\"\n" +
    "                       ng-click=\"$ctrl.handleLoadMoreHistoryLogs()\">\n" +
    "                        <i class=\"fa fa-arrow-down\"></i> Load more\n" +
    "                    </a>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/statisticsDashboard/statisticsInfoIcons/statisticsInfoIconsView.html',
    "<ul class=\"list-inline\">\n" +
    "    <li><i class=\"fa fa-check-circle text-navy\" aria-hidden=\"true\"></i> Accepted</li>\n" +
    "    <li><i class=\"fa fa-times-circle text-danger\" aria-hidden=\"true\"></i> Rejected</li>\n" +
    "    <li><i class=\"fa fa-hourglass-half text-warning\" aria-hidden=\"true\"></i> Pending</li>\n" +
    "    <li><i class=\"fa fa-question-circle text-info\" aria-hidden=\"true\"></i> Queried</li>\n" +
    "    <li><i class=\"fa fa-chevron-down\" aria-hidden=\"true\"></i> No Status</li>\n" +
    "</ul>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/statisticsDashboard/statisticsLanguages/statisticsLanguagesView.html',
    "<div ng-show=\"$ctrl.hasGuidelines\">\n" +
    "    <ul>\n" +
    "        <li ng-show=\"$ctrl.guidelines[key]\" ng-repeat=\"(key, item) in $ctrl.languages | orderObject\">\n" +
    "            <a ng-href=\"http://{{$ctrl.guidelines[key].url}}\" target=\"_blank\">\n" +
    "                <span>{{item}}</span>\n" +
    "            </a>\n" +
    "        </li>\n" +
    "    </ul>\n" +
    "</div>\n" +
    "<div ng-show=\"!$ctrl.hasGuidelines\">\n" +
    "    No guidelines yet\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/statisticsDashboard/statisticsNotes/statisticsNotesView.html',
    "<div class=\"panel-body content-notes\">\n" +
    "\n" +
    "    <div wave-spinner ng-show=\"$ctrl.isLoading()\"></div>\n" +
    "\n" +
    "    <div ng-show=\"!$ctrl.isLoading()\">\n" +
    "\n" +
    "        <div ng-show=\"!$ctrl.isAddFormVisible()\">\n" +
    "            <div class=\"m-b clearfix float-e-margins\">\n" +
    "                <a class=\"btn btn-sm btn-primary m-b pull-left\"\n" +
    "                   ng-if=\"$ctrl.isClient\"\n" +
    "                   ng-click=\"$ctrl.handleNewNoteClick()\">\n" +
    "                    <i class=\"fa fa-plus\"></i> New Note\n" +
    "                </a>\n" +
    "\n" +
    "                <div class=\"alert alert-success alert-sm pull-right\">\n" +
    "                    <i class=\"fa fa-info-circle\"></i> <span>These notes are internal only</span>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"clearfix notes-list\" ng-show=\"$ctrl.notes.results.length\">\n" +
    "                <div class=\"row note-row clickable hover-greenish border-top word-break-all\"\n" +
    "                    ng-click=\"$ctrl.expand(key)\"\n" +
    "                    ng-repeat-start=\"(key, note) in $ctrl.notes.results\">\n" +
    "                    <div class=\"col-xs-5 no-padding break-word\">\n" +
    "                        {{note.text | htmlToPlaintext | limitTo : 100}}{{(note.text | htmlToPlaintext).length > 100 ? '...' : ''}}\n" +
    "                    </div>\n" +
    "                    <div class=\"col-xs-3 p-w-5\">\n" +
    "                        {{note.createdAt | date: 'MMM dd'}}\n" +
    "                    </div>\n" +
    "                    <div class=\"col-xs-4 no-padding break-word\">\n" +
    "                        {{note.createdByFullName}}\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"row bg-muted note-row no-padding border-top\"\n" +
    "                    ng-show=\"note.open\">\n" +
    "                    <div class=\"col-xs-12\" ng-bind-html=\"note.text\"></div>\n" +
    "                </div>\n" +
    "                <div ng-repeat-end></div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div ng-show=\"!$ctrl.notes.results.length\">\n" +
    "                No notes\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"row\" ng-show=\"$ctrl.isMoreAvailable()\">\n" +
    "                <div class=\" col-lg-8 col-lg-offset-2\">\n" +
    "                    <a class=\"btn btn-primary btn-block\"\n" +
    "                       ng-click=\"$ctrl.handleLoadMore()\">\n" +
    "                        <i class=\"fa fa-arrow-down\"></i> Load more\n" +
    "                    </a>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div ng-show=\"$ctrl.isAddFormVisible()\">\n" +
    "            <statistics-add-note></statistics-add-note>\n" +
    "        </div>\n" +
    "\n" +
    "    </div>\n" +
    "\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/statisticsDashboard/statisticsRightPanel/statisticsRightPanelView.html',
    "<div class=\"ibox\">\n" +
    "    <div class=\"ibox-title clear clickable\">\n" +
    "        <div class=\"row\">\n" +
    "            <div class=\"col-lg-9\">\n" +
    "                <h5>{{$ctrl.activeQuestionName}}</h5>\n" +
    "            </div>\n" +
    "            <div class=\"col-lg-3\">\n" +
    "                <div class=\"ibox-tools\" style=\"margin-left: -4px;\">\n" +
    "                    <a class=\"btn btn-primary\"  ng-click=\"showHideRankingsRHS()\"><i class=\"fa fa-bars\"></i></a>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"ibox-content\">\n" +
    "        <div class=\"row\">\n" +
    "            <div class=\"col-lg-12\">\n" +
    "                <div class=\"tabs-container right-panel-tabs\">\n" +
    "                    <scrollable-tabset show-tooltips=\"false\" show-drop-down=\"false\">\n" +
    "                        <uib-tabset active=\"$ctrl.activeTab\">\n" +
    "                            <uib-tab heading=\"Definition\">\n" +
    "                                <statistics-definition></statistics-definition>\n" +
    "                            </uib-tab>\n" +
    "                            <uib-tab heading=\"History\">\n" +
    "                                <statistics-history></statistics-history>\n" +
    "                            </uib-tab>\n" +
    "                            <uib-tab heading=\"Messages\">\n" +
    "                                <statistics-messages></statistics-messages>\n" +
    "                            </uib-tab>\n" +
    "                            <uib-tab heading=\"Notes\" ng-if=\"$ctrl.isClientUser\">\n" +
    "                                <statistics-notes></statistics-notes>\n" +
    "                            </uib-tab>\n" +
    "                        </uib-tabset>\n" +
    "                    </scrollable-tabset>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/statisticsDashboard/statisticsSubscribed/statisticsSubscribedView.html',
    "<div ng-show=\"$ctrl.statisticsSubscribed.length\">\n" +
    "    <div class=\"page-header clearfix\">\n" +
    "        <h3 class=\"pull-left text-normal\">My rankings - Subscribed</h3>\n" +
    "        <statistics-info-icons class=\"pull-right\"></statistics-info-icons>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"clearfix\">\n" +
    "        <div class=\"row\">\n" +
    "            <div class=\"col-sm-6 col-md-3\" ng-repeat=\"item in $ctrl.statisticsSubscribed\">\n" +
    "                <div class=\"ibox ibox-inverse grid-view\">\n" +
    "                    <div class=\"ibox-content product-box\">\n" +
    "                        <div class=\"preview\">\n" +
    "                            <img ng-src=\"{{'/images/rankings/' + item.handle + '.png'}}\"/>\n" +
    "                        </div>\n" +
    "                        <div class=\"product-desc info clearfix\">\n" +
    "                            <h3 class=\"product-name title\">{{item.name}}</h3>\n" +
    "\n" +
    "                            <div class=\"clearfix\">\n" +
    "                                <p class=\"pull-left\">\n" +
    "                                    <i class=\"fa fa-exclamation-triangle\" aria-hidden=\"true\"></i>\n" +
    "                                    <span>Date deadline: {{($ctrl.deadlines[item.handle] | date:'longDate') || 'none'}}</span>\n" +
    "                                </p>\n" +
    "                                <a class=\"pull-right\" ng-if=\"item.handle !== 'ger' && item.handle !== 'esg'\" ng-href=\"https://support.qs.com/hc/en-gb/articles/4405027974290-Data-Appendix\" target=\"_blank\">\n" +
    "                                    <span>Click Here For Definitions & Guidelines</span>\n" +
    "                                </a>\n" +
    "                                <a class=\"pull-right\" ng-if=\"item.handle === 'ger'\" ng-href=\"https://support.qs.com/hc/en-gb/articles/4409192555282-Graduate-Employability-Ranking-Guidelines\" target=\"_blank\">\n" +
    "                                    <span>Click Here For Definitions & Guidelines</span>\n" +
    "                                </a>\n" +
    "                                <a class=\"pull-right\" ng-if=\"item.handle === 'esg'\" ng-href=\"https://support.qs.com/hc/en-gb/articles/8551503200668-QS-Sustainability-Ranking-Edition-2\" target=\"_blank\">\n" +
    "                                    <span>Click Here For Definitions & Guidelines</span>\n" +
    "                                </a>\n" +
    "                                <!-- commented for cls-273 -->\n" +
    "                                <!-- <div class=\"pull-right\" uib-dropdown>\n" +
    "                                    <a ng-click=\"\" uib-dropdown-toggle>\n" +
    "                                        <i class=\"fa fa-download\" aria-hidden=\"true\"></i> Guideline\n" +
    "                                        <i class=\"fa fa-angle-down\"></i>\n" +
    "                                    </a>\n" +
    "                                    <statistics-languages ranking-handle=\"item.handle\" class=\"dropdown-menu\"></statistics-languages>\n" +
    "                                </div> -->\n" +
    "                            </div>\n" +
    "\n" +
    "                            <hr class=\"clearfix\">\n" +
    "\n" +
    "                            <ul class=\"list-inline pull-left\">\n" +
    "                                <li><i class=\"fa fa-check-circle text-navy\" aria-hidden=\"true\"></i> {{$ctrl.totals[item.handle][$ctrl.statuses.accepted] || 0}}</li>\n" +
    "                                <li><i class=\"fa fa-times-circle text-danger\" aria-hidden=\"true\"></i> {{$ctrl.totals[item.handle][$ctrl.statuses.rejected] || 0}}</li>\n" +
    "                                <li><i class=\"fa fa-hourglass-half text-warning\" aria-hidden=\"true\"></i> {{$ctrl.totals[item.handle][$ctrl.statuses.pending] || 0}}</li>\n" +
    "                                <li><i class=\"fa fa-question-circle text-info\" aria-hidden=\"true\"></i> {{$ctrl.totals[item.handle][$ctrl.statuses.queried] || 0}}</li>\n" +
    "                            </ul>\n" +
    "                            <a class=\"btn btn-default btn-xs pull-right\"\n" +
    "                               ui-sref=\"clients.statistics.data-submission.{{item.handle}}\">\n" +
    "                                <i class=\"fa fa-plus-circle\" aria-hidden=\"true\"></i>\n" +
    "                                <span>Add data</span>\n" +
    "                            </a>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/modules/rankings/components/statisticsSubmissions/gerView.html',
    "<div class=\"wrapper wrapper-content animated fadeInRight page-statistics-submissions\">\n" +
    "    <div class=\"row parent-div\">\n" +
    "        <div id=\"gerStatisticsSubmissions\" class=\"col-lg-9 left-hand-panel\">\n" +
    "            <div class=\"ibox float-e-margins\">\n" +
    "                <div wave-spinner class=\"wave-spinner\" ng-show=\"GerController.showLoadBar()\"></div>\n" +
    "\n" +
    "                <div ng-include=\"'/scripts/shared/noAccess/noAccessView.html'\"\n" +
    "                     ng-if=\"GerController.showNoAccess()\"></div>\n" +
    "\n" +
    "                <stats-submissions-not-subscribed\n" +
    "                    ng-if=\"GerController.notSubscribedToRanking\"\n" +
    "                    handle-no-ranking-access-close=\"GerController.handleNoRankingAccessClose()\"\n" +
    "                    is-client=\"GerController.isClient\">\n" +
    "                </stats-submissions-not-subscribed>\n" +
    "\n" +
    "                <!-- <div ng-include=\"'/scripts/modules/rankings/noSubscribedRanking/noSubscribedRankingView.html'\"\n" +
    "                     ng-if=\"!StatisticsSubmissionsController.isLoading() && StatisticsSubmissionsController.noSubscribedToRankings()\"></div> -->\n" +
    "\n" +
    "                <div class=\"ibox-content table-responsive\" ng-class=\"{'modal-overlay-35' : GerController.showOverlay()}\">\n" +
    "\n" +
    "                    <div class=\"clearfix m-b\">\n" +
    "                        <div class=\"pull-left\" ng-class=\"{'modal-overlay-35': MainController.hasUnsavedChanges()}\">\n" +
    "                            <stats-submissions-back-button></stats-submissions-back-button>\n" +
    "\n" +
    "                            <stats-submissions-ranking-selection\n" +
    "                                subscribed=\"GerController.subscribedStatistics\"\n" +
    "                                available=\"GerController.statisticsAvailable\">\n" +
    "                            </stats-submissions-ranking-selection>\n" +
    "                        </div>\n" +
    "\n" +
    "                        <statistics-info-icons class=\"pull-right\"></statistics-info-icons>\n" +
    "                    </div>\n" +
    "\n" +
    "                    <div class=\"clearfix row\">\n" +
    "                        <div class=\"col-lg-12\">\n" +
    "                            <p class=\"disregard-formatting m-b\">\n" +
    "                                <span ng-bind-html=\"GerController.rankingDefinition\"></span>\n" +
    "                                <a ng-href=\"https://support.qs.com/hc/en-gb/articles/4409192555282-Graduate-Employability-Ranking-Guidelines\" target=\"_blank\">\n" +
    "                                    <span>Click Here For Definitions & Guidelines</span>\n" +
    "                                </a>\n" +
    "                                <!-- <span class=\"guideline-dropdown\" uib-dropdown>\n" +
    "                                    <a  uib-dropdown-toggle>\n" +
    "                                        <i class=\"fa fa-download\" aria-hidden=\"true\"></i> Refer Guidelines\n" +
    "                                        <i class=\"fa fa-angle-down\"></i>\n" +
    "                                    </a>\n" +
    "                                    <statistics-languages ranking-handle=\"'ger'\" class=\"dropdown-menu\"></statistics-languages>\n" +
    "                                </span> -->\n" +
    "                            </p>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "\n" +
    "                    <div class=\"alert alert-success\">\n" +
    "                        <div class=\"row\">\n" +
    "                            <div class=\"col-lg-12\">\n" +
    "                                <span class=\"block m-t-xs\" ng-show=\"GerController.activeTab !== 2\"><i class=\"fa fa-star-o\"></i>\n" +
    "                                    <!-- Please note that indicators below with an asterisk (*) will automatically update if that indicator also appears in other QS rankings -->\n" +
    "                                    Please note that indicators below with an asterisk (*) are mandatory. For any other indicators with<span style=\"font-size: 25px\">↔</span> these will automatically update if that indicator also appears in other QS rankings forms\n" +
    "                                </span>\n" +
    "                                <span class=\"block m-t-xs\" ng-show=\"GerController.activeTab === 2\"><i class=\"fa fa-star-o\"></i>\n" +
    "                                    Please note that indicators below with this symbol<span style=\"font-size: 25px\">↔</span> will automatically update if that indicator also appears in other QS rankings forms\n" +
    "                                </span>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"alert alert-success\">\n" +
    "                        <div class=\"row\">\n" +
    "                            <div class=\"col-lg-12\">\n" +
    "                                <span class=\"block m-t-xs\"><i class=\"fa fa-star-o\"></i>\n" +
    "                                    If your submitted numbers are the same as your previous year's, please select the indicator and click on SAVE. There is no need to edit the field. This must be done for every indicator that you wish to use previous year's data for.\n" +
    "                                </span>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"alert alert-success\">\n" +
    "                        <div class=\"row\">\n" +
    "                            <div class=\"col-lg-12\">\n" +
    "                                <span class=\"block m-t-xs\"><i class=\"fa fa-star-o\"></i>\n" +
    "                                    Please complete as much information as you can provide. This strengthens the validity of our analysis, and the integrity of the insights we can provide.\n" +
    "                                </span>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "\n" +
    "                    <div class=\"tabs-container\">\n" +
    "                        <div id=\"statisticsSubmissions\">\n" +
    "                            <uib-tabset active=\"GerController.activeTab\">\n" +
    "                                <!-- <uib-tab heading=\"Employer Connections\"\n" +
    "                                         ui-sref=\"clients.statistics.data-submission.ger.employers-connections\">\n" +
    "                                    <div class=\"panel-body ger-employers-connections\" ng-if=\"GerController.activeTab === 0\">\n" +
    "                                        <ger-employers-connections categories=\"GerController.categories\"\n" +
    "                                                                   questions=\"GerController.questions\"></ger-employers-connections>\n" +
    "                                    </div>\n" +
    "                                </uib-tab> -->\n" +
    "\n" +
    "                                <uib-tab heading=\"Employer Partnerships\" ui-sref=\"clients.statistics.data-submission.ger.employers-partnerships\">\n" +
    "                                    <div class=\"panel-body ger-employer-partnerships\" ng-if=\"GerController.activeTab === 0\">\n" +
    "                                        <ger-employer-partnerships categories=\"GerController.categories\"\n" +
    "                                                                   questions=\"GerController.questions\"></ger-employer-partnerships>\n" +
    "                                    </div>\n" +
    "                                </uib-tab>\n" +
    "\n" +
    "                                <uib-tab heading=\"Employment Statistics\" ui-sref=\"clients.statistics.data-submission.ger.employment-rate\">\n" +
    "                                    <div class=\"panel-body ger-employment-rate\" ng-if=\"GerController.activeTab === 1\">\n" +
    "                                        <ger-employment-rate categories=\"GerController.categories\"\n" +
    "                                                             questions=\"GerController.questions\"></ger-employment-rate>\n" +
    "                                    </div>\n" +
    "                                </uib-tab>\n" +
    "\n" +
    "                                <uib-tab heading=\"Faculty/Student Data\" ui-sref=\"clients.statistics.data-submission.ger.faculty-student-data\">\n" +
    "                                    <div class=\"panel-body\" ng-if=\"GerController.activeTab === 2\">\n" +
    "                                        <form class=\"form-horizontal statistics-submission\" name=\"GerController.forms.faculty-student-data\" novalidate>\n" +
    "                                            <div ng-repeat=\"(categoryHandle, categoryQuestions) in GerController.gerStatisticsQuestions\">\n" +
    "                                                <statistics-personal-header ng-if=\"categoryHandle === 'faculty_staff'\" ranking=\"'ger'\" category-handle=\"categoryHandle\"></statistics-personal-header>\n" +
    "                                                <div ng-repeat=\"question in categoryQuestions\">\n" +
    "                                                    <statistics-personal-question question=\"question\" ng-if=\"question.type === 'personal'\"></statistics-personal-question>\n" +
    "                                                </div>\n" +
    "                                            </div>\n" +
    "                                            <a class=\"btn btn-primary btn-sm pull-right m-t\"\n" +
    "                                            ng-class=\"{'disabled': GerController.isSavingDisabled()}\"\n" +
    "                                            ng-click=\"GerController.handleSaveClick()\">\n" +
    "                                                <i class=\"fa fa-check-circle\"></i> Save\n" +
    "                                            </a>\n" +
    "                                        </form>\n" +
    "                                    </div>\n" +
    "                                </uib-tab>\n" +
    "\n" +
    "                            </uib-tabset>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div fixed-element-while-scrolling=\"#gerStatisticsSubmissions\" class=\"col-lg-3 scroll-floating-element right-hand-panel\"\n" +
    "             ng-class=\"{'modal-overlay-35' : GerController.showOverlay()}\">\n" +
    "            <statistics-right-panel></statistics-right-panel>\n" +
    "        </div>\n" +
    "\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/statisticsSubmissions/statisticsEmploymentRateHeader/statisticsEmploymentRateHeaderView.html',
    "<div class=\"row font-bold question-header\">\n" +
    "    <div class=\"col-xs-2\">\n" +
    "        {{$ctrl.categoryName}}<span ng-if=\"$ctrl.categoryDefinition.length\" uib-tooltip-html=\"$ctrl.categoryDefinition\" tooltip-placement=\"top\"><i class=\"fa fa-info-circle\"></i></span>\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-1 text-muted p-w-xxs break-word\">\n" +
    "        Total graduated students<span class=\"legendLabel\">*</span>\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-1 text-muted p-w-xxs break-word\">\n" +
    "        Total respondents\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-1 text-muted p-w-xxs break-word\">\n" +
    "        Employed FT and/or PT<span class=\"legendLabel\">*</span>\n" +
    "        <i class=\"fa fa-info-circle\"\n" +
    "           uib-tooltip=\"Employed F/T and/or P/T is the total number of Graduates (both undergraduates and postgraduates) who are employed within 12 months of graduation. Please enter just one figure for the total number of graduates who are employed.\"\n" +
    "           tooltip-class=\"break-word\"\n" +
    "           tooltip-placement=\"top\"></i>\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-1 text-muted p-w-xxs break-word\">\n" +
    "        Unemployed but seeking employment<span class=\"legendLabel\">*</span>\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-1 text-muted p-w-xxs\">\n" +
    "        In FT further study\n" +
    "        <i class=\"fa fa-info-circle\"\n" +
    "        uib-tooltip=\"IMPORTANT: Please note graduates pursuing further study/unavailable for employment will be excluded from the calculation.\"\n" +
    "        tooltip-class=\"break-word\"\n" +
    "        tooltip-placement=\"top\"></i>\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-1 text-muted p-w-xxs break-word\">\n" +
    "        Unavailable for work (e.g. military service)\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-1 text-muted p-w-xxs break-word\">\n" +
    "        Estimate\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-2 text-muted p-w-xxs\" ng-if=\"!$ctrl.isClient\">\n" +
    "        Source\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-1 text-muted\">\n" +
    "        Status\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/modules/rankings/components/statisticsSubmissions/statisticsEmploymentRateQuestion/statisticsEmploymentRateQuestionView.html',
    "<div class=\"row question-content clickable\" ng-class=\"{'active': $ctrl.isActive()}\" ng-click=\"$ctrl.handleFocus()\">\n" +
    "    <div class=\"col-xs-4 question-name no-outline\">\n" +
    "        {{$ctrl.question.name}}<span class=\"legendLabel\" ng-show=\"$ctrl.question.rankings.indexOf('all') !== -1\">*</span>\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-3 p-w-xxs\">\n" +
    "        <input class=\"form-control\"\n" +
    "               ng-model=\"$ctrl.answer\"\n" +
    "               validate-data-submission\n" +
    "               validate-data-submission-value=\"{{$ctrl.answer}}\"\n" +
    "               validate-data-submission-original=\"{{$ctrl.answerBeforeChanges}}\"\n" +
    "               validate-data-submission-rules=\"{{$ctrl.question.validators}}\">\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-2 question-currency p-w-xxs\">\n" +
    "        <ui-select\n" +
    "            ng-model=\"$ctrl.selectedCurrency\"\n" +
    "            search-enabled=\"true\"\n" +
    "            theme=\"bootstrap\">\n" +
    "            <ui-select-match placeholder=\"Select option\">{{$select.selected.label}}</ui-select-match>\n" +
    "            <ui-select-choices\n" +
    "                position=\"down\"\n" +
    "                repeat=\"item in ($ctrl.currencyList | filter: $select.search) track by item.value\">\n" +
    "                <div ng-bind-html=\"item.label | highlight: $select.search\"></div>\n" +
    "            </ui-select-choices>\n" +
    "            <ui-select-no-choice>\n" +
    "                Not found\n" +
    "            </ui-select-no-choice>\n" +
    "        </ui-select>\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-2 question-source p-w-xxs\" ng-if=\"!$ctrl.isClient\">\n" +
    "        <statistics-source source=\"$ctrl.source\" enabled=\"$ctrl.hasAnswers()\"></statistics-source>\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-1 question-status\">\n" +
    "        <statistics-status-popup status=\"$ctrl.status\" enabled=\"$ctrl.hasAnswers()\"></statistics-status-popup>\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/modules/rankings/components/statisticsSubmissions/statisticsFinancialHeader/statisticsFinancialHeaderView.html',
    "<div class=\"row font-bold question-header\">\n" +
    "    <div class=\"col-xs-5\">\n" +
    "        {{$ctrl.categoryName}}<span ng-if=\"$ctrl.categoryDefinition.length\" uib-tooltip-html=\"$ctrl.categoryDefinition\" tooltip-placement=\"top\"><i class=\"fa fa-info-circle\"></i></span>\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-2 text-muted p-w-xxs\">\n" +
    "        Total\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-2 text-muted p-w-xxs\">\n" +
    "        Currency\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-2 text-muted p-w-xxs\" ng-if=\"!$ctrl.isClient\">\n" +
    "        Source\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-1 text-muted\" ng-if=\"!$ctrl.isClient\">\n" +
    "        Status\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/modules/rankings/components/statisticsSubmissions/statisticsFinancialQuestion/statisticsFinancialQuestionView.html',
    "<div class=\"row question-content clickable\" ng-class=\"{'active': $ctrl.isActive()}\" ng-click=\"$ctrl.handleFocus()\">\n" +
    "    <div class=\"col-xs-5 question-name no-outline\">\n" +
    "        {{$ctrl.question.name}}<span class=\"legendLabel\" ng-show=\"$ctrl.question.rankings.indexOf('all') !== -1\"><span style=\"font-size: 20px\">↔</span></span>\n" +
    "    </div>\n" +
    "    <div class=\"p-w-xxs\" ng-class=\"$ctrl.isClient && $ctrl.ranking === 'esg' ? 'col-xs-3 ' : 'col-xs-2'\">\n" +
    "        <input class=\"form-control\"\n" +
    "               ng-model=\"$ctrl.answer\"\n" +
    "               validate-data-submission\n" +
    "               validate-data-submission-value=\"{{$ctrl.answer}}\"\n" +
    "               validate-data-submission-original=\"{{$ctrl.answerBeforeChanges}}\"\n" +
    "               validate-data-submission-rules=\"{{$ctrl.question.validators}}\">\n" +
    "    </div>\n" +
    "    <div ng-show=\"$ctrl.question.handle === 'students_receive_scholarship_covering_hundred_percent' || $ctrl.question.handle === 'students_receive_scholarship_covering_fifty_percent'\">\n" +
    "        <div class=\"col-xs-1 question-name p-w-xxs\">\n" +
    "            N/A\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-1 question-estimate p-w-xxs\">\n" +
    "            <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.notAvailable\" value=\"true\">\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-2 question-currency p-w-xxs\" ng-show=\"$ctrl.question.handle !== 'students_receive_scholarship_covering_hundred_percent' && $ctrl.question.handle !== 'students_receive_scholarship_covering_fifty_percent'\">\n" +
    "        <ui-select\n" +
    "            ng-model=\"$ctrl.selectedCurrency\"\n" +
    "            search-enabled=\"true\"\n" +
    "            theme=\"bootstrap\">\n" +
    "            <ui-select-match placeholder=\"Select option\">{{$select.selected.label}}</ui-select-match>\n" +
    "            <ui-select-choices\n" +
    "                position=\"down\"\n" +
    "                repeat=\"item in ($ctrl.currencyList | filter: $select.search) track by item.value\">\n" +
    "                <div ng-bind-html=\"item.label | highlight: $select.search\"></div>\n" +
    "            </ui-select-choices>\n" +
    "            <ui-select-no-choice>\n" +
    "                Not found\n" +
    "            </ui-select-no-choice>\n" +
    "        </ui-select>\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-2 question-source p-w-xxs\" ng-if=\"!$ctrl.isClient\">\n" +
    "        <statistics-source source=\"$ctrl.source\" enabled=\"$ctrl.hasAnswers() || $ctrl.hasAnswersWithoutCurrency()\"></statistics-source>\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-1 question-status\" ng-if=\"!$ctrl.isClient\">\n" +
    "        <statistics-status-popup status=\"$ctrl.status\" enabled=\"$ctrl.hasAnswers() || $ctrl.hasAnswersWithoutCurrency()\"></statistics-status-popup>\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/modules/rankings/components/statisticsSubmissions/statisticsNumericHeader/statisticsNumericHeaderView.html',
    "<div class=\"row font-bold question-header\">\n" +
    "    <div class=\"col-xs-4\">\n" +
    "        {{$ctrl.categoryName}}<span ng-if=\"$ctrl.categoryDefinition.length\" uib-tooltip-html=\"$ctrl.categoryDefinition\" tooltip-placement=\"top\"><i class=\"fa fa-info-circle\"></i></span>\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-5 text-muted p-w-xxs\">\n" +
    "        Total\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-2 text-muted p-w-xxs\" ng-if=\"!$ctrl.isClient\">\n" +
    "        Source\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-1 text-muted\">\n" +
    "        Status\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/modules/rankings/components/statisticsSubmissions/statisticsNumericQuestion/statisticsNumericQuestionView.html',
    "<div class=\"row question-content clickable\" ng-class=\"{'active': $ctrl.isActive()}\" ng-click=\"$ctrl.handleFocus()\">\n" +
    "    <div class=\"col-xs-4 question-name no-outline\">\n" +
    "        {{$ctrl.question.name}}<span class=\"legendLabel\" ng-show=\"$ctrl.question.rankings.indexOf('all') !== -1\"><span style=\"font-size: 20px\">↔</span></span>\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-5 question-answer p-w-xxs\">\n" +
    "        <input class=\"form-control\"\n" +
    "               ng-model=\"$ctrl.answer\"\n" +
    "               validate-data-submission\n" +
    "               validate-data-submission-value=\"{{$ctrl.answer}}\"\n" +
    "               validate-data-submission-original=\"{{$ctrl.answerBeforeChanges}}\"\n" +
    "               validate-data-submission-rules=\"{{$ctrl.question.validators}}\">\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-2 question-source p-w-xxs\" ng-if=\"!$ctrl.isClient\">\n" +
    "        <statistics-source source=\"$ctrl.source\" enabled=\"$ctrl.hasAnswers()\"></statistics-source>\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-1 question-status\">\n" +
    "        <statistics-status-popup status=\"$ctrl.status\" enabled=\"$ctrl.hasAnswers()\"></statistics-status-popup>\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/modules/rankings/components/statisticsSubmissions/statisticsPersonalHeader/statisticsPersonalHeaderView.html',
    "<div class=\"row font-bold question-header\">\n" +
    "    <div class=\"col-xs-5\">\n" +
    "      <span ng-hide=\"$ctrl.ranking === 'ger'\">{{$ctrl.categoryName}}<span ng-if=\"$ctrl.categoryDefinition.length\" uib-tooltip-html=\"$ctrl.categoryDefinition\" tooltip-placement=\"top\"><i class=\"fa fa-info-circle\"></i></span></span> \n" +
    "      <span ng-show=\"$ctrl.ranking === 'ger'\">\n" +
    "        Faculty/Student Data\n" +
    "      </span>\n" +
    "    </div>\n" +
    "    <!-- <div class=\"col-xs-1 text-muted p-w-xxs break-word\">\n" +
    "        <span ng-show=\"$ctrl.ranking !== 'lat'\">Prev. Submitted Data</span>\n" +
    "    </div> -->\n" +
    "    <div class=\"col-xs-5 text-muted p-w-xxs break-word\" ng-show=\"$ctrl.categoryName === 'Sustainability'\">\n" +
    "        <span >Climate Action Plan (URL)</span>\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-1 text-muted p-w-xxs\" ng-show=\"$ctrl.categoryName !== 'Sustainability' && $ctrl.ranking !== 'esg'\">\n" +
    "        Full Time\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-1 text-muted p-w-xxs\" ng-show=\"$ctrl.categoryName !== 'Sustainability' && $ctrl.ranking !== 'esg'\">\n" +
    "        Part Time\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-1 text-muted p-w-xxs break-word\" ng-show=\"$ctrl.categoryName !== 'Sustainability' && $ctrl.ranking !== 'esg'\">\n" +
    "        HC\n" +
    "        <span ng-if=\"$ctrl.categoryName === 'Faculty Staff'\" uib-tooltip='Total number of FT and PT faculty staff.' tooltip-placement=\"top\">\n" +
    "            <i class=\"fa fa-info-circle\"></i>\n" +
    "        </span>\n" +
    "        <span ng-if=\"$ctrl.categoryName !== 'Faculty Staff'\" uib-tooltip='Total number of FT and PT students.' tooltip-placement=\"top\">\n" +
    "            <i class=\"fa fa-info-circle\"></i>\n" +
    "        </span>\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-1 text-muted fte-column p-w-xxs\" ng-show=\"$ctrl.categoryName !== 'Sustainability' && $ctrl.ranking !== 'esg'\">\n" +
    "        FTE\n" +
    "        <span uib-tooltip='Full Time Equivalent (FTE) is the total number of full-time personnel it would take to meet the commitments currently met by both the full-time and part-time personnel. If there are no part-time personnel, then FTE figure is equal to the headcount figure. A student/staff can be represented more than once as an FTE – if a student/staff is taking a full-time program and a part-time program, he/she will be counted into the Full-Time Headcount AND Part- Time Headcount.' tooltip-placement=\"top\">\n" +
    "            <i class=\"fa fa-info-circle\"></i>\n" +
    "        </span>\n" +
    "    </div>\n" +
    "    <div class=\"text-muted fte-column p-w-xxs\" ng-show=\"$ctrl.ranking === 'esg'\" ng-class=\"$ctrl.getFieldClass('esgURL')\">\n" +
    "        {{$ctrl.categoryHandle === 'environmental_institutions' || $ctrl.categoryHandle === 'good_governance'? 'URL' : ($ctrl.categoryHandle === 'knowledge_exchange' && $ctrl.categoryHandle === 'health_and_wellbeing' && $ctrl.categoryHandle === 'environmental_education'? '' : 'Total Number' ) }}\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-1 text-muted p-w-xxs break-word\" ng-show=\"$ctrl.categoryName !== 'Sustainability' && $ctrl.categoryHandle !== 'environmental_institutions'\n" +
    "    && $ctrl.categoryHandle !== 'good_governance' && $ctrl.categoryHandle !== 'knowledge_exchange' && $ctrl.categoryHandle !== 'health_and_wellbeing' && $ctrl.categoryHandle !== 'environmental_education'\">\n" +
    "        Est.\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-1 text-muted p-w-xxs\" ng-if=\"!$ctrl.isClient\">\n" +
    "        Source\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-1 text-muted\" ng-hide=\"$ctrl.categoryHandle === 'additional_statistics' && $ctrl.isClient\">\n" +
    "        Status\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/modules/rankings/components/statisticsSubmissions/statisticsPersonalQuestion/statisticsPersonalQuestionView.html',
    "<div ng-if=\"$ctrl.question.handle !== 'number_of_female_students'\" class=\"row question-content clickable\"\n" +
    "    ng-class=\"{'active': $ctrl.isActive()}\" ng-click=\"$ctrl.handleFocus()\">\n" +
    "    <div class=\"col-xs-5 question-name no-outline\" tabindex=\"1\">\n" +
    "        {{$ctrl.question.name}}<span class=\"legendLabel\"\n" +
    "            ng-show=\"$ctrl.question.rankings.indexOf('all') !== -1 && $ctrl.isNotInOtherRanking($ctrl.question.handle)\"><span style=\"font-size: 20px\">↔</span></span>\n" +
    "            <i class=\"fa fa-info-circle\" ng-show=\"$ctrl.isFileField()\"\n" +
    "            uib-tooltip=\"Only csv, xlsx, or docx files no bigger than 10 MB are accepted.\"\n" +
    "            tooltip-class=\"break-word\"\n" +
    "            tooltip-placement=\"top\"></i>\n" +
    "    </div>\n" +
    "    <!-- <div class=\"col-xs-1 question-answer p-w-xxs\" >\n" +
    "        <input ng-if=\"$ctrl.isAllowedPreviouslySubmit($ctrl.question)\" i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.previouslySubmitted\" value=\"true\">\n" +
    "    </div> -->\n" +
    "    <!-- <div class=\"col-xs-1\"></div> -->\n" +
    "    <!-- <div class=\"col-xs-4 question-answer p-w-xxs\" ng-show=\"$ctrl.isSingleNumericField()\">\n" +
    "        <input class=\"form-control p-xxs\" ng-model=\"$ctrl.answers.tsn\" validate-data-submission\n" +
    "            validate-data-submission-value=\"{{$ctrl.answers.tsn}}\"\n" +
    "            validate-data-submission-original=\"{{$ctrl.answersBeforeChanges.tsn}}\"\n" +
    "            validate-data-submission-rules=\"{{$ctrl.question.validators}}\">\n" +
    "    </div> -->\n" +
    "    <div class=\"col-xs-4 question-answer p-w-xxs\" ng-show=\"$ctrl.isSingleNumericField()\">\n" +
    "        <input class=\"form-control p-xxs\" ng-model=\"$ctrl.answers.fte\" validate-data-submission\n" +
    "            validate-data-submission-value=\"{{$ctrl.answers.fte}}\"\n" +
    "            validate-data-submission-original=\"{{$ctrl.answersBeforeChanges.fte}}\"\n" +
    "            validate-data-submission-rules=\"{{$ctrl.question.validators}}\">\n" +
    "     </div>\n" +
    "     <div class=\"question-answer p-w-xxs col-xs-5\" ng-show=\"$ctrl.isUrlField()\">\n" +
    "        <div class=\"row\">\n" +
    "            <div class=\"col-xs-12 checkbox question-name\" ng-show=\"$ctrl.isUrlAndCheckBoxField()\">\n" +
    "                <label>\n" +
    "                    <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.checked\"  value=\"true\">\n" +
    "                    Tick if applicable\n" +
    "                </label>\n" +
    "                <hr>\n" +
    "              </div>\n" +
    "            <div class=\"col-xs-12\">\n" +
    "                <input class=\"form-control p-xxs\" ng-model=\"$ctrl.answers.url\" type=\"text\" placeholder=\"http://\"\n" +
    "                        ng-keyup=\"$ctrl.onKeyUp($event)\">\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        \n" +
    "    </div>\n" +
    "    <!-- <div class=\"question-answer p-w-xxs\" ng-show=\"$ctrl.isUrlField()\" ng-class=\"$ctrl.isPubliclyAvailableQuestion() || $ctrl.isNotAvailableQuestion()? 'col-xs-3' : 'col-xs-5 '\">\n" +
    "        <input class=\"form-control p-xxs\" ng-model=\"$ctrl.answers.url\" type=\"text\" placeholder=\"http://\"\n" +
    "            ng-keyup=\"$ctrl.onKeyUp($event)\">\n" +
    "    </div> -->\n" +
    "    <!-- <div class=\"col-xs-1 question-name p-w-xxs\" ng-show=\"$ctrl.isPubliclyAvailableQuestion() || $ctrl.isNotAvailableQuestion()\">\n" +
    "        {{$ctrl.isPubliclyAvailableQuestion() ? 'Available?' : 'N/A'}}\n" +
    "        <span uib-tooltip='Is it publicly available?' tooltip-placement=\"top\"  ng-show=\"$ctrl.isPubliclyAvailableQuestion()\">\n" +
    "            <i class=\"fa fa-info-circle\"></i>\n" +
    "        </span>\n" +
    "    </div> -->\n" +
    "    <!-- <div class=\"col-xs-1 question-estimate p-w-xxs\" ng-show=\"$ctrl.isNotAvailableQuestion()\">\n" +
    "        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.notAvailable\" value=\"true\">\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-1 question-estimate p-w-xxs\" ng-class=\"{'modal-overlay-35' :!$ctrl.answers.url}\" ng-show=\"$ctrl.isPubliclyAvailableQuestion()\">\n" +
    "        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.publiclyAvailable\" value=\"true\">\n" +
    "    </div> -->\n" +
    "    <!-- <div class=\"col-xs-2 question-answer p-l-none\" ng-show=\"$ctrl.question.name === 'Climate Action Plan (URL)'\" ng-class=\"{'modal-overlay-35': !$ctrl.answers.url}\">\n" +
    "        <a class=\"btn btn-default btn-sm m-b-xs ml-10\" target=\"_blank\" href=\"{{$ctrl.answers.url}}\">\n" +
    "            <i class=\"fa fa-share\"></i> Open link\n" +
    "        </a>\n" +
    "    </div> -->\n" +
    "    <div ng-show=\"$ctrl.isCheckBoxField()\">\n" +
    "        <div class=\"col-xs-1 question-name\">Yes</div>\n" +
    "        <div class=\"col-xs-1 question-estimate p-w-xxs\">\n" +
    "            <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.checked\" value=\"true\">\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-2\"></div>\n" +
    "    </div>\n" +
    "    <div ng-if=\"$ctrl.isSustaiabilityField()\">\n" +
    "        <div class=\"col-xs-4\" ng-if=\"$ctrl.question.handle === 'cuurent_equality_diversity_and_inclusionn'\">\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col question-name\"><b>Please tick all answers that apply.</b></div>\n" +
    "                <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.currentEdi\"  value=\"true\">\n" +
    "                        We have a current EDI policy or equivalent\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"col question-name mt-10\">\n" +
    "                    Please provide evidence\n" +
    "                  </div>\n" +
    "                  <div class=\"col\">\n" +
    "                    <input class=\"form-control p-xxs\" ng-model=\"$ctrl.answers.currentEdiUrl\" type=\"text\" placeholder=\"http://\"\n" +
    "                        ng-keyup=\"$ctrl.onKeyUp($event)\">\n" +
    "                  </div>\n" +
    "                  <hr>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col question-name\">Which of the following main protected characteristics are included in this policy?</div>\n" +
    "                <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.age\"  value=\"true\">\n" +
    "                        Age\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.genderReassignment\"  value=\"true\">\n" +
    "                        Gender or gender reassignment\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.disability\"  value=\"true\">\n" +
    "                        Disability\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.race\"  value=\"true\">\n" +
    "                        Race\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.religionOrBelief\"  value=\"true\">\n" +
    "                        Religion or belief\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.sexualOrientation\"  value=\"true\">\n" +
    "                        Sexual orientation\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.marriageAndCivilPatnership\"  value=\"true\">\n" +
    "                        Marriage and civil partnership\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.refugeeAndAsylumSeekers\"  value=\"true\">\n" +
    "                        Refugee and asylum seekers\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.pregnancyAndMaternity\"  value=\"true\">\n" +
    "                        Pregnancy and maternity\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  \n" +
    "                  <hr>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-4\" ng-if=\"$ctrl.question.handle === 'institution_formed_sustainability_committee'\">\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.checked\"\n" +
    "                            value=\"true\">\n" +
    "                        Tick if applicable\n" +
    "                    </label>\n" +
    "                    <hr>\n" +
    "                </div>\n" +
    "                <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.executiveLeadership\"  value=\"true\">\n" +
    "                        Does a member of your executive leadership team sit on this committee?\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                <div class=\"col question-name\">Please provide evidence to support your answers for the above\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                    uib-tooltip=\"Please do not add images.\"\n" +
    "                    tooltip-class=\"break-word\" tooltip-placement=\"top\"></i>\n" +
    "                </div>\n" +
    "                <div class=\"summernote-container col question-name\">\n" +
    "                        <summernote\n" +
    "                            config=\"$ctrl.textEditorOptions\"\n" +
    "                            on-focus=\"$ctrl.setInvalidDescription(countWords($ctrl.answers.formedSustainabilityCommitteeEvd))\"\n" +
    "                            ng-model=\"$ctrl.answers.formedSustainabilityCommitteeEvd\"\n" +
    "                            on-change=\"$ctrl.descriptionWords = countWords(contents)\"\n" +
    "                            on-init=\"$ctrl.descriptionWords = countWords($ctrl.answers.formedSustainabilityCommitteeEvd)\"\n" +
    "                            on-paste=\"$ctrl.handleClipboardPaste('formedSustainabilityCommitteeEvd')\"\n" +
    "                            count-word=\"$ctrl.descriptionWords\">\n" +
    "                        </summernote>\n" +
    "            \n" +
    "                         <div class=\"editor-note\">\n" +
    "                            <span ng-class=\"{'text-red': $ctrl.descriptionWords > 200}\">\n" +
    "                              <span class=\"bold\">{{$ctrl.descriptionWords}}</span>\n" +
    "                              <span>words inserted</span>\n" +
    "                            </span>\n" +
    "                            <span class=\"pull-right\">Maximum 200 words</span>\n" +
    "                          </div>\n" +
    "                  </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-4\" ng-if=\"$ctrl.question.handle === 'instituion_provide_dedicated_training_on_social_impacts'\">\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.checked\"\n" +
    "                            value=\"true\">\n" +
    "                        Tick if applicable\n" +
    "                    </label>\n" +
    "                    <hr>\n" +
    "                </div>\n" +
    "                <div class=\"row\">\n" +
    "                    <div class=\"col question-name mt-10\">\n" +
    "                        How many hours of training on these Social aspects per employee?\n" +
    "                        <i class=\"fa fa-info-circle\"\n" +
    "                            uib-tooltip=\"Please add whole number\"\n" +
    "                            tooltip-class=\"break-word\" tooltip-placement=\"top\"></i>\n" +
    "                    </div>\n" +
    "                    <div class=\"col\">\n" +
    "                        <input class=\"form-control p-xxs\" ng-model=\"$ctrl.answers.hoursOfTrainingSA\" type=\"number\" min=\"0\" step=\"0\" oninput=\"validity.valid||(value='');\">\n" +
    "                    </div>\n" +
    "                    <hr>\n" +
    "                </div>\n" +
    "                <div class=\"col question-name mt-10\">\n" +
    "                    Which of the follwing groups receive this training?\n" +
    "                </div>\n" +
    "                <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.isForStudentsSI\"  value=\"true\">\n" +
    "                        Students \n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.isForStaffSI\"  value=\"true\">\n" +
    "                        Staff \n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.isForbothSI\"  value=\"true\">\n" +
    "                        Both \n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "               \n" +
    "                <div class=\"col question-name\">Please provide evidence to support your answers for the above\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                    uib-tooltip=\"Please do not add images.\"\n" +
    "                    tooltip-class=\"break-word\" tooltip-placement=\"top\"></i>\n" +
    "                </div>\n" +
    "                <div class=\"summernote-container col question-name\">\n" +
    "                        <summernote\n" +
    "                            config=\"$ctrl.textEditorOptions\"\n" +
    "                            on-focus=\"$ctrl.setInvalidDescription(countWords($ctrl.answers.dedicatedTrainingSocialAspectsEvd))\"\n" +
    "                            ng-model=\"$ctrl.answers.dedicatedTrainingSocialAspectsEvd\"\n" +
    "                            on-change=\"$ctrl.descriptionWords = countWords(contents)\"\n" +
    "                            on-init=\"$ctrl.descriptionWords = countWords($ctrl.answers.dedicatedTrainingSocialAspectsEvd)\"\n" +
    "                            on-paste=\"$ctrl.handleClipboardPaste('dedicatedTrainingSocialAspectsEvd')\"\n" +
    "                            count-word=\"$ctrl.descriptionWords\">\n" +
    "                        </summernote>\n" +
    "            \n" +
    "                         <div class=\"editor-note\">\n" +
    "                            <span ng-class=\"{'text-red': $ctrl.descriptionWords > 200}\">\n" +
    "                              <span class=\"bold\">{{$ctrl.descriptionWords}}</span>\n" +
    "                              <span>words inserted</span>\n" +
    "                            </span>\n" +
    "                            <span class=\"pull-right\">Maximum 200 words</span>\n" +
    "                          </div>\n" +
    "                  </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-4\" ng-if=\"$ctrl.question.handle === 'institution_provide_dedicated_training_on_environmental_aspects'\">\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.checked\"\n" +
    "                            value=\"true\">\n" +
    "                        Tick if applicable\n" +
    "                    </label>\n" +
    "                    <hr>\n" +
    "                </div>\n" +
    "                <div class=\"row\">\n" +
    "                    <div class=\"col question-name mt-10\">\n" +
    "                        How many hours of training on these Environmental aspects per employee?\n" +
    "                        <i class=\"fa fa-info-circle\"\n" +
    "                            uib-tooltip=\"Please add whole number\"\n" +
    "                            tooltip-class=\"break-word\" tooltip-placement=\"top\"></i>\n" +
    "                    </div>\n" +
    "                    <div class=\"col\">\n" +
    "                        <input class=\"form-control p-xxs\" ng-model=\"$ctrl.answers.hoursOfTrainingEA\" type=\"number\" min=\"0\" step=\"0\" oninput=\"validity.valid||(value='');\">\n" +
    "                    </div>\n" +
    "                    <hr>\n" +
    "                </div>\n" +
    "                <div class=\"col question-name mt-10\">\n" +
    "                    Which of the follwing groups receive this training?\n" +
    "                </div>\n" +
    "                <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.isForStudentsEA\"  value=\"true\">\n" +
    "                        Students \n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.isForStaffEA\"  value=\"true\">\n" +
    "                        Staff \n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.isForbothEA\"  value=\"true\">\n" +
    "                        Both \n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                <div class=\"col question-name\">Please provide evidence to support your answers for the above\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                    uib-tooltip=\"Please do not add images.\"\n" +
    "                    tooltip-class=\"break-word\" tooltip-placement=\"top\"></i>\n" +
    "                </div>\n" +
    "                <div class=\"summernote-container col question-name\">\n" +
    "                        <summernote\n" +
    "                            config=\"$ctrl.textEditorOptions\"\n" +
    "                            on-focus=\"$ctrl.setInvalidDescription(countWords($ctrl.answers.dedicatedTrainingEnvironmentalAspectsEvd))\"\n" +
    "                            ng-model=\"$ctrl.answers.dedicatedTrainingEnvironmentalAspectsEvd\"\n" +
    "                            on-change=\"$ctrl.descriptionWords = countWords(contents)\"\n" +
    "                            on-init=\"$ctrl.descriptionWords = countWords($ctrl.answers.dedicatedTrainingEnvironmentalAspectsEvd)\"\n" +
    "                            on-paste=\"$ctrl.handleClipboardPaste('dedicatedTrainingEnvironmentalAspectsEvd')\"\n" +
    "                            count-word=\"$ctrl.descriptionWords\">\n" +
    "                        </summernote>\n" +
    "            \n" +
    "                         <div class=\"editor-note\">\n" +
    "                            <span ng-class=\"{'text-red': $ctrl.descriptionWords > 200}\">\n" +
    "                              <span class=\"bold\">{{$ctrl.descriptionWords}}</span>\n" +
    "                              <span>words inserted</span>\n" +
    "                            </span>\n" +
    "                            <span class=\"pull-right\">Maximum 200 words</span>\n" +
    "                          </div>\n" +
    "                  </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-4\" ng-if=\"$ctrl.question.handle === 'support_services_for_disabilities'\">\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col question-name\"><b>Please tick all answers that apply.</b></div>\n" +
    "                <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.existenceOfDisabilitySupport\"  value=\"true\">\n" +
    "                        Existence of Disability Support Office\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"col question-name mt-10\">\n" +
    "                    Please provide evidence\n" +
    "                  </div>\n" +
    "                  <div class=\"col\">\n" +
    "                    <input class=\"form-control p-xxs\" ng-model=\"$ctrl.answers.existenceOfDisabilitySupportUrl\" type=\"text\" placeholder=\"http://\"\n" +
    "                        ng-keyup=\"$ctrl.onKeyUp($event)\">\n" +
    "                  </div>\n" +
    "                  <hr>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.campusAccessibility\"  value=\"true\">\n" +
    "                        Campus is easily accessible by people with disabilities\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"col question-name mt-10\">\n" +
    "                    Please provide evidence\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                    uib-tooltip=\"Please do not add images.\"\n" +
    "                    tooltip-class=\"break-word\" tooltip-placement=\"top\"></i>\n" +
    "                  </div>\n" +
    "                  <div class=\"summernote-container col question-name\">\n" +
    "                    <div class=\"\">\n" +
    "                        <summernote\n" +
    "                            config=\"$ctrl.textEditorOptions\"\n" +
    "                            on-focus=\"$ctrl.setInvalidDescription(countWords($ctrl.answers.campusAccessibilityText))\"\n" +
    "                            ng-model=\"$ctrl.answers.campusAccessibilityText\"\n" +
    "                            on-change=\"$ctrl.descriptionWords = countWords(contents)\"\n" +
    "                            on-init=\"$ctrl.descriptionWords = countWords($ctrl.answers.campusAccessibilityText)\"\n" +
    "                            on-paste=\"$ctrl.handleClipboardPaste('campusAccessibilityText')\"\n" +
    "                            count-word=\"$ctrl.descriptionWords\">\n" +
    "                        </summernote>\n" +
    "            \n" +
    "                         <div class=\"editor-note\">\n" +
    "                            <span ng-class=\"{'text-red': $ctrl.descriptionWords > 50}\">\n" +
    "                              <span class=\"bold\">{{$ctrl.descriptionWords}}</span>\n" +
    "                              <span>words inserted</span>\n" +
    "                            </span>\n" +
    "                            <span class=\"pull-right\">Maximum 50 words</span>\n" +
    "                          </div>\n" +
    "                    </div>\n" +
    "                    <!-- <textarea class=\"form-control p-xxs\" ng-model=\"$ctrl.answers.campusAccessibilityText\"  placeholder=\"\"></textarea> -->\n" +
    "                  </div>\n" +
    "                  <hr>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.accessScheme\"  value=\"true\">\n" +
    "                        Access schemes for people with disabilities such as mentoring or other targeted support\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"col question-name mt-10\">\n" +
    "                    Please provide evidence\n" +
    "                  </div>\n" +
    "                  <div class=\"col\">\n" +
    "                    <input class=\"form-control p-xxs\" ng-model=\"$ctrl.answers.accessSchemeUrl\" type=\"text\" placeholder=\"http://\"\n" +
    "                        ng-keyup=\"$ctrl.onKeyUp($event)\">\n" +
    "                  </div>\n" +
    "                  <hr>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.universityAccomodation\"  value=\"true\">\n" +
    "                        Our university offers on-campus accommodation\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.reasonableAccommodation\"  value=\"true\">\n" +
    "                        We have a reasonable accommodation policy or strategy for people with disabilities, including adequate funding\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"col question-name mt-10\">\n" +
    "                    Please provide evidence\n" +
    "                  </div>\n" +
    "                  <div class=\"col\">\n" +
    "                    <input class=\"form-control p-xxs\" ng-model=\"$ctrl.answers.reasonableAccommodationUrl\" type=\"text\" placeholder=\"http://\"\n" +
    "                        ng-keyup=\"$ctrl.onKeyUp($event)\">\n" +
    "                  </div>\n" +
    "                  <hr>\n" +
    "            </div>\n" +
    "           \n" +
    "        </div>\n" +
    "        <div class=\"col-xs-4\" ng-if=\"$ctrl.question.handle === 'manage_or_deliver_outreach_projects'\">\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.checked\"\n" +
    "                            value=\"true\">\n" +
    "                        Tick if applicable\n" +
    "                    </label>\n" +
    "                    <hr>\n" +
    "                </div>\n" +
    "                <div class=\"col question-name\">Please provide evidence\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                    uib-tooltip=\"Please do not add images.\"\n" +
    "                    tooltip-class=\"break-word\" tooltip-placement=\"top\"></i>\n" +
    "                </div>\n" +
    "                <div class=\"summernote-container col question-name\">\n" +
    "                        <summernote\n" +
    "                            config=\"$ctrl.textEditorOptions\"\n" +
    "                            on-focus=\"$ctrl.setInvalidDescription(countWords($ctrl.answers.manageOrDeliverOutrechProjects))\"\n" +
    "                            ng-model=\"$ctrl.answers.manageOrDeliverOutrechProjects\"\n" +
    "                            on-change=\"$ctrl.descriptionWords = countWords(contents)\"\n" +
    "                            on-init=\"$ctrl.descriptionWords = countWords($ctrl.answers.manageOrDeliverOutrechProjects)\"\n" +
    "                            on-paste=\"$ctrl.handleClipboardPaste('manageOrDeliverOutrechProjects')\"\n" +
    "                            count-word=\"$ctrl.descriptionWords\">\n" +
    "                        </summernote>\n" +
    "            \n" +
    "                         <div class=\"editor-note\">\n" +
    "                            <span ng-class=\"{'text-red': $ctrl.descriptionWords > 200}\">\n" +
    "                              <span class=\"bold\">{{$ctrl.descriptionWords}}</span>\n" +
    "                              <span>words inserted</span>\n" +
    "                            </span>\n" +
    "                            <span class=\"pull-right\">Maximum 200 words</span>\n" +
    "                          </div>\n" +
    "                  </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-4\" ng-if=\"$ctrl.question.handle === 'does_institution_have_dedicated_staff_member'\">\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.checked\"\n" +
    "                            value=\"true\">\n" +
    "                        Tick if applicable\n" +
    "                    </label>\n" +
    "                    <hr>\n" +
    "                </div>\n" +
    "                <div class=\"col question-name\">Please provide evidence\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                    uib-tooltip=\"Please do not add images.\"\n" +
    "                    tooltip-class=\"break-word\" tooltip-placement=\"top\"></i>\n" +
    "                </div>\n" +
    "                <div class=\"summernote-container col question-name\">\n" +
    "                        <summernote\n" +
    "                            config=\"$ctrl.textEditorOptions\"\n" +
    "                            on-focus=\"$ctrl.setInvalidDescription(countWords($ctrl.answers.institutionDedicatedStaffMember))\"\n" +
    "                            ng-model=\"$ctrl.answers.institutionDedicatedStaffMember\"\n" +
    "                            on-change=\"$ctrl.descriptionWords = countWords(contents)\"\n" +
    "                            on-init=\"$ctrl.descriptionWords = countWords($ctrl.answers.institutionDedicatedStaffMember)\"\n" +
    "                            on-paste=\"$ctrl.handleClipboardPaste('institutionDedicatedStaffMember')\"\n" +
    "                            count-word=\"$ctrl.descriptionWords\">\n" +
    "                        </summernote>\n" +
    "            \n" +
    "                         <div class=\"editor-note\">\n" +
    "                            <span ng-class=\"{'text-red': $ctrl.descriptionWords > 200}\">\n" +
    "                              <span class=\"bold\">{{$ctrl.descriptionWords}}</span>\n" +
    "                              <span>words inserted</span>\n" +
    "                            </span>\n" +
    "                            <span class=\"pull-right\">Maximum 200 words</span>\n" +
    "                          </div>\n" +
    "                  </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-4\" ng-if=\"$ctrl.question.handle === 'health_and_wellbeing_services'\">\n" +
    "            \n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col question-name\"><b>Please tick all answers that apply.</b></div>\n" +
    "                <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.healthyAndAffordableFood\"  value=\"true\">\n" +
    "                        Provision of healthy and affordable food choices for all on campus\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"col question-name mt-10\">\n" +
    "                    Please provide evidence\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                    uib-tooltip=\"Please do not add images.\"\n" +
    "                    tooltip-class=\"break-word\" tooltip-placement=\"top\"></i>\n" +
    "                  </div>\n" +
    "                  <div class=\"summernote-container col question-name\">\n" +
    "                    <div class=\"\">\n" +
    "                        <summernote\n" +
    "                            config=\"$ctrl.textEditorOptions\"\n" +
    "                            on-focus=\"$ctrl.setInvalidDescription(countWords($ctrl.answers.healthyAndAffordableFoodText))\"\n" +
    "                            ng-model=\"$ctrl.answers.healthyAndAffordableFoodText\"\n" +
    "                            on-change=\"$ctrl.descriptionWords = countWords(contents)\"\n" +
    "                            on-init=\"$ctrl.descriptionWords = countWords($ctrl.answers.healthyAndAffordableFoodText)\"\n" +
    "                            on-paste=\"$ctrl.handleClipboardPaste('healthyAndAffordableFoodText')\"\n" +
    "                            count-word=\"$ctrl.descriptionWords\">\n" +
    "                        </summernote>\n" +
    "            \n" +
    "                         <div class=\"editor-note\">\n" +
    "                            <span ng-class=\"{'text-red': $ctrl.descriptionWords > 200}\">\n" +
    "                              <span class=\"bold\">{{$ctrl.descriptionWords}}</span>\n" +
    "                              <span>words inserted</span>\n" +
    "                            </span>\n" +
    "                            <span class=\"pull-right\">Maximum 200 words</span>\n" +
    "                          </div>\n" +
    "                    </div>\n" +
    "                  </div>\n" +
    "                  <hr>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "                \n" +
    "                <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.physicalHealth\"  value=\"true\">\n" +
    "                        Access to physical health-care services including information and education services\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"col question-name mt-10\">\n" +
    "                    Please provide evidence\n" +
    "                  </div>\n" +
    "                  <div class=\"col\">\n" +
    "                    <input class=\"form-control p-xxs\" ng-model=\"$ctrl.answers.physicalHealthUrl\" type=\"text\" placeholder=\"http://\"\n" +
    "                        ng-keyup=\"$ctrl.onKeyUp($event)\">\n" +
    "                  </div>\n" +
    "                  <hr>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.reproductiveHealthCare\"  value=\"true\">\n" +
    "                        Access to sexual and reproductive health-care services including information and education services\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"col question-name mt-10\">\n" +
    "                    Please provide evidence\n" +
    "                  </div>\n" +
    "                  <div class=\"col\">\n" +
    "                    <input class=\"form-control p-xxs\" ng-model=\"$ctrl.answers.reproductiveHealthCareUrl\" type=\"text\" placeholder=\"http://\"\n" +
    "                        ng-keyup=\"$ctrl.onKeyUp($event)\">\n" +
    "                  </div>\n" +
    "                  <hr>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.mentalHealth\"  value=\"true\">\n" +
    "                        Access to mental health support for both staff and students\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"col question-name mt-10\">\n" +
    "                    Please provide evidence\n" +
    "                  </div>\n" +
    "                  <div class=\"col\">\n" +
    "                    <input class=\"form-control p-xxs\" ng-model=\"$ctrl.answers.mentalHealthUrl\" type=\"text\" placeholder=\"http://\"\n" +
    "                        ng-keyup=\"$ctrl.onKeyUp($event)\">\n" +
    "                  </div>\n" +
    "                  <hr>\n" +
    "            </div>\n" +
    "          \n" +
    "           \n" +
    "        </div>\n" +
    "        <div class=\"col-xs-4\" ng-if=\"$ctrl.question.handle === 'equality_diversity_and_inclusionn_comittee'\">\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.existenceOfCommiteeOffice\"  value=\"true\">\n" +
    "                        Existence of committee, office or officer\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"col question-name mt-10\">\n" +
    "                    Please provide evidence\n" +
    "                  </div>\n" +
    "                  <div class=\"col\">\n" +
    "                    <input class=\"form-control p-xxs\" ng-model=\"$ctrl.answers.existenceOfCommiteeOfficeUrl\" type=\"text\" placeholder=\"http://\"\n" +
    "                        ng-keyup=\"$ctrl.onKeyUp($event)\">\n" +
    "                  </div>\n" +
    "                  <hr>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.existenceOfAntiDiscrimination\"  value=\"true\">\n" +
    "                        Existence of anti-discrimination and anti-harassment policies\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"col question-name mt-10\">\n" +
    "                    Please provide Policy URLs\n" +
    "                  </div>\n" +
    "                  <div class=\"col\">\n" +
    "                    <input class=\"form-control p-xxs\" ng-model=\"$ctrl.answers.existenceOfAntiDiscriminationUrl\" type=\"text\" placeholder=\"http://\"\n" +
    "                        ng-keyup=\"$ctrl.onKeyUp($event)\">\n" +
    "                  </div>\n" +
    "                  <div class=\"col\">\n" +
    "                    <input class=\"form-control p-xxs\" ng-model=\"$ctrl.answers.existenceOfAntiDiscriminationSecondUrl\" type=\"text\" placeholder=\"http://\"\n" +
    "                        ng-keyup=\"$ctrl.onKeyUp($event)\">\n" +
    "                  </div>\n" +
    "                  <hr>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-4\" ng-if=\"$ctrl.question.handle === 'anti_bribery_policy'\">\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.existenceOfAntiBribery\"  value=\"true\">\n" +
    "                        Existence of anti-bribery and corruption policy or equivalent\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"col question-name mt-10\">\n" +
    "                    Please provide evidence\n" +
    "                  </div>\n" +
    "                  <div class=\"col\">\n" +
    "                    <input class=\"form-control p-xxs\" ng-model=\"$ctrl.answers.existenceOfAntiBriberyUrl\" type=\"text\" placeholder=\"http://\"\n" +
    "                        ng-keyup=\"$ctrl.onKeyUp($event)\">\n" +
    "                  </div>\n" +
    "                  <hr>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.policyReviewed\"  value=\"true\">\n" +
    "                        This policy has been reviewed in the last 3 years, i.e. on or after 2020 (tick if apply)\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <hr>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-4\" ng-if=\"$ctrl.question.handle === 'proportion_of_papers_publihsed'\">\n" +
    "            <div class=\"row\">\n" +
    "                  <div class=\"col question-name mt-10\">\n" +
    "                    Please provide this as a percentage of total papers published \n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                        uib-tooltip=\"Please add whole number\"\n" +
    "                        tooltip-class=\"break-word\" tooltip-placement=\"top\"></i>\n" +
    "                  </div>\n" +
    "                  <div class=\"col\">\n" +
    "                    <input class=\"form-control p-xxs\" ng-model=\"$ctrl.answers.papersPublishedPercentage\" type=\"number\" min=\"0\" step=\"0\" oninput=\"validity.valid||(value='');\">\n" +
    "                  </div>\n" +
    "                  <hr>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-4\" ng-if=\"$ctrl.question.handle === 'holistic_ethical_organisational_culture'\">\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col checkbox question-name\">\n" +
    "                    <b>Please tick all answers that apply.</b>\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.ethicalValues\"  value=\"true\">\n" +
    "                        Our organisation develops clear ethical values (e.g., diversity, honesty, respect, fairness) and these are enshrined in a publicly available strategic document.\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"col question-name mt-10\">\n" +
    "                    Please provide evidence\n" +
    "                  </div>\n" +
    "                  <div class=\"col\">\n" +
    "                    <input class=\"form-control p-xxs\" ng-model=\"$ctrl.answers.ethicalValuesUrl\" type=\"text\" placeholder=\"http://\"\n" +
    "                        ng-keyup=\"$ctrl.onKeyUp($event)\">\n" +
    "                  </div>\n" +
    "                  <hr>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.providesTraning\"  value=\"true\">\n" +
    "                        Our university provides training based on those values at all levels of the organisation. \n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"col question-name mt-10\">\n" +
    "                    Please provide evidence\n" +
    "                  </div>\n" +
    "                  <div class=\"col\">\n" +
    "                    <input class=\"form-control p-xxs\" ng-model=\"$ctrl.answers.providesTraningUrl\" type=\"text\" placeholder=\"http://\"\n" +
    "                        ng-keyup=\"$ctrl.onKeyUp($event)\">\n" +
    "                  </div>\n" +
    "                  <hr>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.ethicalCompliance\"  value=\"true\">\n" +
    "                        There is an office for ethical compliance within our institution, with a designated official with oversight on ethical matters across the institution.\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"col question-name mt-10\">\n" +
    "                    Please provide evidence\n" +
    "                  </div>\n" +
    "                  <div class=\"col\">\n" +
    "                    <input class=\"form-control p-xxs\" ng-model=\"$ctrl.answers.ethicalComplianceUrl\" type=\"text\" placeholder=\"http://\"\n" +
    "                        ng-keyup=\"$ctrl.onKeyUp($event)\">\n" +
    "                  </div>\n" +
    "                  <hr>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.internalReporting\"  value=\"true\">\n" +
    "                        Our organisation has an internal reporting system to assure the confidentiality of whistleblowers or a grievance procedure for staff concerning an employment matter\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <div class=\"col question-name mt-10\">\n" +
    "                    Please provide evidence\n" +
    "                  </div>\n" +
    "                  <div class=\"col\">\n" +
    "                    <input class=\"form-control p-xxs\" ng-model=\"$ctrl.answers.internalReportingUrl\" type=\"text\" placeholder=\"http://\"\n" +
    "                        ng-keyup=\"$ctrl.onKeyUp($event)\">\n" +
    "                  </div>\n" +
    "                  <hr>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-4\" ng-if=\"$ctrl.question.handle === 'university_student_union'\">\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col checkbox question-name\">\n" +
    "                    <b>Please tick all answers that apply.</b>\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.representUgAndPgStudent\"  value=\"true\">\n" +
    "                        Our university has a recognised student union that represents both undergraduate and postgraduate students at university level\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <hr>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.studentUnionConnected\"  value=\"true\">\n" +
    "                        This student union is connected/affiliated to a wider national student union body\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <hr>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.studentUnionAdheres\"  value=\"true\">\n" +
    "                        The student union elects its leadership, allowing students to vote.\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <hr>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "                  <div class=\"col question-name mt-10\">\n" +
    "                    Please provide evidence\n" +
    "                  </div>\n" +
    "                  <div class=\"col\">\n" +
    "                    <input class=\"form-control p-xxs\" ng-model=\"$ctrl.answers.UniversityStudentUnionUrl\" type=\"text\" placeholder=\"http://\"\n" +
    "                        ng-keyup=\"$ctrl.onKeyUp($event)\">\n" +
    "                  </div>\n" +
    "                  <hr>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-4\" ng-if=\"$ctrl.question.handle === 'publish_financial_reports'\">\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.incomeAndExpenditure\"  value=\"true\">\n" +
    "                        Income\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.financialPosition\"  value=\"true\">\n" +
    "                        Expenditure\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.cashFlowInformation\"  value=\"true\">\n" +
    "                        Borrowing\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.yearForecast\"  value=\"true\">\n" +
    "                        Surplus\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "                  <div class=\"col question-name mt-10\">\n" +
    "                    Please provide evidence\n" +
    "                  </div>\n" +
    "                  <div class=\"col\">\n" +
    "                    <input class=\"form-control p-xxs\" ng-model=\"$ctrl.answers.financialReportsUrl\" type=\"text\" placeholder=\"http://\"\n" +
    "                        ng-keyup=\"$ctrl.onKeyUp($event)\">\n" +
    "                  </div>\n" +
    "                  <hr>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-4\" ng-if=\"$ctrl.question.handle === 'presence_of_research_center'\">\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col checkbox question-name\">\n" +
    "                    <b>Please tick all answers that apply.</b>\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.checked\"\n" +
    "                            value=\"true\">\n" +
    "                        Tick if applicable\n" +
    "                    </label>\n" +
    "                    <hr>\n" +
    "                </div>\n" +
    "                <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.dedicatedFteStaff\"  value=\"true\">\n" +
    "                        The Research Centre has dedicated FTE staff.\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <hr>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.researchCenterContribute\"  value=\"true\">\n" +
    "                        This Research Centre contributes (through curriculum, teaching and supervision) to the teaching of undergraduate programmes and/or postgraduate programmes.\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <hr>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "                  <div class=\"col question-name mt-10\">\n" +
    "                    Please provide evidence or supporting statement \n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                    uib-tooltip=\"Please do not add images.\"\n" +
    "                    tooltip-class=\"break-word\" tooltip-placement=\"top\"></i>\n" +
    "                  </div>\n" +
    "                  <div class=\"summernote-container col question-name\">\n" +
    "                      <summernote config=\"$ctrl.textEditorOptions\"\n" +
    "                          on-focus=\"$ctrl.setInvalidDescription(countWords($ctrl.answers.presenceOfSearchText))\"\n" +
    "                          ng-model=\"$ctrl.answers.presenceOfSearchText\"\n" +
    "                          on-change=\"$ctrl.descriptionWords = countWords(contents)\"\n" +
    "                          on-init=\"$ctrl.descriptionWords = countWords($ctrl.answers.presenceOfSearchText)\"\n" +
    "                          on-paste=\"$ctrl.handleClipboardPaste('presenceOfSearchText')\"\n" +
    "                          count-word=\"$ctrl.descriptionWords\">\n" +
    "                      </summernote>\n" +
    "                      <div class=\"editor-note\">\n" +
    "                        <span ng-class=\"{'text-red': $ctrl.descriptionWords > 100}\">\n" +
    "                          <span class=\"bold\">{{$ctrl.descriptionWords}}</span>\n" +
    "                          <span>words inserted</span>\n" +
    "                        </span>\n" +
    "                        <span class=\"pull-right\">Maximum 100 words</span>\n" +
    "                      </div>\n" +
    "                  </div>\n" +
    "                  <hr>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-4\" ng-if=\"$ctrl.question.handle === 'institution_have_an_assessment_tool_for_assessing'\">\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col checkbox question-name\">\n" +
    "                    <b>Please tick all answers that apply.</b>\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.checked\"\n" +
    "                            value=\"true\">\n" +
    "                        Tick if applicable\n" +
    "                    </label>\n" +
    "                    <hr>\n" +
    "                </div>\n" +
    "                <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.isSuitestTask\"  value=\"true\">\n" +
    "                        If yes, is this tool Sulitest TASK?\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <hr>\n" +
    "            </div>\n" +
    "            \n" +
    "            <div class=\"row\">\n" +
    "                  <div class=\"col question-name mt-10\">\n" +
    "                    If no, please provide evidence of the assessment tool used\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                    uib-tooltip=\"Please do not add images.\"\n" +
    "                    tooltip-class=\"break-word\" tooltip-placement=\"top\"></i>\n" +
    "                  </div>\n" +
    "                  <div class=\"summernote-container col question-name\">\n" +
    "                      <summernote config=\"$ctrl.textEditorOptions\"\n" +
    "                          on-focus=\"$ctrl.setInvalidDescription(countWords($ctrl.answers.assessmentToolEv))\"\n" +
    "                          ng-model=\"$ctrl.answers.assessmentToolEv\"\n" +
    "                          on-change=\"$ctrl.descriptionWords = countWords(contents)\"\n" +
    "                          on-init=\"$ctrl.descriptionWords = countWords($ctrl.answers.assessmentToolEv)\"\n" +
    "                          on-paste=\"$ctrl.handleClipboardPaste('assessmentToolEv')\"\n" +
    "                          count-word=\"$ctrl.descriptionWords\">\n" +
    "                      </summernote>\n" +
    "                      <div class=\"editor-note\">\n" +
    "                        <span ng-class=\"{'text-red': $ctrl.descriptionWords > 100}\">\n" +
    "                          <span class=\"bold\">{{$ctrl.descriptionWords}}</span>\n" +
    "                          <span>words inserted</span>\n" +
    "                        </span>\n" +
    "                        <span class=\"pull-right\">Maximum 100 words</span>\n" +
    "                      </div>\n" +
    "                  </div>\n" +
    "                  <hr>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-4\" ng-if=\"$ctrl.question.handle === 'climate_science_environmental_sustainability'\">\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col checkbox question-name\">\n" +
    "                    <b>Please tick all answers that apply.</b>\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.checked\"\n" +
    "                            value=\"true\">\n" +
    "                        Tick if applicable\n" +
    "                    </label>\n" +
    "                    <hr>\n" +
    "                </div>\n" +
    "                <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.officiallyRecognised\"  value=\"true\">\n" +
    "                        We offer these courses and they are linked to officially recognised credits (e.g., European Credit Transfer and Accumulation System - ECTS, in Europe)\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <hr>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col checkbox question-name\">\n" +
    "                    <label>\n" +
    "                        <input i-checkbox ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.recognisedQualification\"  value=\"true\">\n" +
    "                        They lead to the award of an officially recognised qualification that specifically refers to climate science and environmental sustainability (e.g., MSc in Climate Change: Science, Society and Solutions at the University of Manchester in the UK; BSc in Climate Science and Adaptation at the University of Newcastle, Australia)\n" +
    "                    </label>\n" +
    "                  </div>\n" +
    "                  <hr>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "                  <div class=\"col question-name mt-10\">\n" +
    "                    Please provide evidence\n" +
    "                  </div>\n" +
    "                  <div class=\"col\">\n" +
    "                    <input class=\"form-control p-xxs\" ng-model=\"$ctrl.answers.climateScienceUrl\" type=\"text\" placeholder=\"http://\"\n" +
    "                        ng-keyup=\"$ctrl.onKeyUp($event)\">\n" +
    "                  </div>\n" +
    "                  \n" +
    "                  <hr>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-4\" ng-if=\"$ctrl.question.handle === 'report_carbon_emissions'\">\n" +
    "            <div class=\"row\">\n" +
    "                  <div class=\"col question-name mt-10\">\n" +
    "                    Please provide evidence of the last conducted inventory\n" +
    "                  </div>\n" +
    "                  <div class=\"col\">\n" +
    "                    <input class=\"form-control p-xxs\" ng-model=\"$ctrl.answers.lastConductedSurvey\" type=\"text\" placeholder=\"http://\"\n" +
    "                        ng-keyup=\"$ctrl.onKeyUp($event)\">\n" +
    "                  </div>\n" +
    "                  <hr>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-4\" ng-if=\"$ctrl.question.handle === 'carbon_emissions_in_kilograms'\">\n" +
    "            <div class=\"row\">\n" +
    "                  <div class=\"col question-name mt-10\">\n" +
    "                    Total Scope 1 & 2 emissions in tCO2e\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                        uib-tooltip=\"Please add whole number\"\n" +
    "                        tooltip-class=\"break-word\" tooltip-placement=\"top\"></i>\n" +
    "                  </div>\n" +
    "                  <div class=\"col\">\n" +
    "                    <input class=\"form-control p-xxs\" ng-model=\"$ctrl.answers.emissionsScope\" type=\"number\" min=\"0\" step=\"0\" oninput=\"validity.valid||(value='');\">\n" +
    "                  </div>\n" +
    "                  <hr>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col question-name mt-10\">\n" +
    "                    If you also report on Scope 3 emissions, please list your estimate here, in tCO2e\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                        uib-tooltip=\"Please add whole number\"\n" +
    "                        tooltip-class=\"break-word\" tooltip-placement=\"top\"></i>\n" +
    "                </div>\n" +
    "                <div class=\"col\">\n" +
    "                  <input class=\"form-control p-xxs\" ng-model=\"$ctrl.answers.reportOnScope\" type=\"number\" min=\"0\" step=\"0\" oninput=\"validity.valid||(value='');\">\n" +
    "                </div>\n" +
    "                <hr>\n" +
    "          </div>\n" +
    "          <div class=\"row\">\n" +
    "            <div class=\"col question-name mt-10\">\n" +
    "                Please also provide a URL that supports the above figures\n" +
    "            </div>\n" +
    "            <div class=\"col\">\n" +
    "                <input class=\"form-control p-xxs\" ng-model=\"$ctrl.answers.carbonEmissionUrl\" type=\"text\" placeholder=\"http://\"\n" +
    "                ng-keyup=\"$ctrl.onKeyUp($event)\">\n" +
    "            </div>\n" +
    "            <hr>\n" +
    "      </div>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-4\" ng-if=\"$ctrl.question.handle === 'same_ffigure_for_base_line'\">\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col question-name mt-10\">\n" +
    "                    Baseline year\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                        uib-tooltip=\"Please add whole number\"\n" +
    "                        tooltip-class=\"break-word\" tooltip-placement=\"top\"></i>\n" +
    "                </div>\n" +
    "                <div class=\"col\">\n" +
    "                    <input class=\"form-control p-xxs\" ng-model=\"$ctrl.answers.baseLineYear\" type=\"number\" min=\"0\" step=\"0\" oninput=\"validity.valid||(value='');\">\n" +
    "                </div>\n" +
    "                <hr>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col question-name mt-10\">\n" +
    "                    Total Scope 1 & 2 for the baseline year, in tco2e\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                        uib-tooltip=\"Please add whole number\"\n" +
    "                        tooltip-class=\"break-word\" tooltip-placement=\"top\"></i>\n" +
    "                </div>\n" +
    "                <div class=\"col\">\n" +
    "                    <input class=\"form-control p-xxs\" ng-model=\"$ctrl.answers.totalScopeEmission\" type=\"number\" min=\"0\" step=\"0\" oninput=\"validity.valid||(value='');\">\n" +
    "                </div>\n" +
    "                <hr>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-4\" ng-if=\"$ctrl.question.handle === 'carbon_redduction_target'\">\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col question-name mt-10\">\n" +
    "                    Please provide evidence\n" +
    "                </div>\n" +
    "                <div class=\"col\">\n" +
    "                    <input class=\"form-control p-xxs\" ng-model=\"$ctrl.answers.carbonReductionUrl\" type=\"text\"\n" +
    "                        placeholder=\"http://\" ng-keyup=\"$ctrl.onKeyUp($event)\">\n" +
    "                </div>\n" +
    "                <hr>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-4\" ng-if=\"$ctrl.question.handle === 'enery_generated_in_campus'\">\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col question-name mt-10\">\n" +
    "                    Total energy from renewables\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                        uib-tooltip=\"Please add whole number\"\n" +
    "                        tooltip-class=\"break-word\" tooltip-placement=\"top\"></i>\n" +
    "                </div>\n" +
    "                <div class=\"col\">\n" +
    "                    <input class=\"form-control p-xxs\" ng-model=\"$ctrl.answers.energyGenerated\" type=\"number\" min=\"0\" step=\"0\" oninput=\"validity.valid||(value='');\">\n" +
    "                </div>\n" +
    "                <hr>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-4\" ng-if=\"$ctrl.question.handle === 'campus_building_footprint'\">\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col question-name mt-10\">\n" +
    "                    Total campus building footprint in square meters (sq^2)\n" +
    "                    <i class=\"fa fa-info-circle\"\n" +
    "                        uib-tooltip=\"Please add whole number\"\n" +
    "                        tooltip-class=\"break-word\" tooltip-placement=\"top\"></i>\n" +
    "                </div>\n" +
    "                <div class=\"col\">\n" +
    "                    <input class=\"form-control p-xxs\" ng-model=\"$ctrl.answers.buildingFootPrints\" type=\"number\" min=\"0\" step=\"0\" oninput=\"validity.valid||(value='');\">\n" +
    "                </div>\n" +
    "                <hr>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-4\" ng-if=\"$ctrl.question.handle === 'institution_publicaly_committed_to_reaching_net_zero'\">\n" +
    "            <div class=\"row\">\n" +
    "                <div class=\"col question-name mt-10\">\n" +
    "                    Please provide evidence\n" +
    "                </div>\n" +
    "                <div class=\"col\">\n" +
    "                    <input class=\"form-control p-xxs\" ng-model=\"$ctrl.answers.publicalyComittedUrl\" type=\"text\"\n" +
    "                        placeholder=\"http://\" ng-keyup=\"$ctrl.onKeyUp($event)\">\n" +
    "                </div>\n" +
    "                <hr>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-1\"></div>\n" +
    "    </div>\n" +
    "    <div ng-show=\"!$ctrl.isUrlField() && !$ctrl.isSingleNumericField() && !$ctrl.isCheckBoxField() && !$ctrl.isSustaiabilityField()\" >\n" +
    "        <div class=\"question-answer p-w-xxs\"  ng-class=\"{'has-manual-error' : $ctrl.isInvalidField('ft'), 'col-xs-1': !$ctrl.isSingleBox(), 'col-xs-4': $ctrl.isSingleBox()}\"\n" +
    "            ng-hide=\"($ctrl.ranking === 'esg' && $ctrl.question.handle !== 'total_students_nationalities') || $ctrl.question.handle === 'first_generation_leners_percentage'\">\n" +
    "            <input class=\"form-control p-xxs\"  id=\"ft_column_{{$ctrl.question.handle}}\"\n" +
    "                 ng-model=\"$ctrl.answers.ft\"\n" +
    "                validate-data-submission validate-data-submission-hc=\"hc_column_{{ $ctrl.question.id }}\"\n" +
    "                validate-data-submission-ft=\"$ctrl.answers.ft\" validate-data-submission-pt=\"$ctrl.answers.pt\"\n" +
    "                validate-data-submission-value=\"{{$ctrl.answers.ft}}\"\n" +
    "                validate-data-submission-value-type=\"ft\"\n" +
    "                validate-data-submission-original=\"{{$ctrl.answersBeforeChanges.ft}}\"\n" +
    "                validate-data-submission-rules=\"{{$ctrl.question.validators}}\"\n" +
    "                validate-data-submission-Question=\"{{$ctrl.question.isExplanationProvided}}\"\n" +
    "                validate-data-submission-Question-Handle=\"{{$ctrl.question.handle}}\"\n" +
    "                validate-data-submission-answers=\"$ctrl.answers\"\n" +
    "                validate-data-submission-answers-before-changes=\"$ctrl.answersBeforeChanges\">\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-1 question-answer p-w-xxs\" ng-hide=\"$ctrl.ranking === 'esg' || $ctrl.question.handle === 'first_generation_leners_percentage'\" ng-class=\"$ctrl.isInvalidField('pt') ? 'has-manual-error' : ''\">\n" +
    "            <input class=\"form-control p-xxs\" id=\"pt_column_{{$ctrl.question.handle}}\"\n" +
    "                ng-hide=\"$ctrl.isSingleBox()\"\n" +
    "                ng-model=\"$ctrl.answers.pt\" validate-data-submission\n" +
    "                validate-data-submission-ft=\"$ctrl.answers.ft\" validate-data-submission-pt=\"$ctrl.answers.pt\"\n" +
    "                validate-data-submission-hc=\"hc_column_{{ $ctrl.question.id }}\"\n" +
    "                validate-data-submission-value=\"{{$ctrl.answers.pt}}\"\n" +
    "                validate-data-submission-value-type=\"pt\"\n" +
    "                validate-data-submission-original=\"{{$ctrl.answersBeforeChanges.pt}}\"\n" +
    "                validate-data-submission-rules=\"{{$ctrl.question.validators}}\"\n" +
    "                validate-data-submission-Question=\"{{$ctrl.question.isExplanationProvided}}\"\n" +
    "                validate-data-submission-Question-Handle=\"{{$ctrl.question.handle}}\"\n" +
    "                validate-data-submission-answers=\"$ctrl.answers\"\n" +
    "                validate-data-submission-answers-before-changes=\"$ctrl.answersBeforeChanges\">\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-1 question-answer p-w-xxs\" ng-hide=\"$ctrl.ranking === 'esg' || $ctrl.question.handle === 'first_generation_leners_percentage'\" ng-class=\"$ctrl.isInvalidField('hc') ? 'has-manual-error' : ''\">\n" +
    "            <input class=\"form-control p-xxs\" id=\"hc_column_{{$ctrl.question.handle}}\"\n" +
    "                ng-hide=\"$ctrl.isSingleBox()\"\n" +
    "                name=\"hc_column_{{ $ctrl.question.id }}\" ng-model=\"$ctrl.answers.hc\" validate-data-submission\n" +
    "                validate-data-submission-ft=\"$ctrl.answers.ft\" validate-data-submission-pt=\"$ctrl.answers.pt\"\n" +
    "                validate-data-submission-value=\"{{$ctrl.answers.hc}}\"\n" +
    "                validate-data-submission-original=\"{{$ctrl.answersBeforeChanges.hc}}\"\n" +
    "                validate-data-submission-value-type=\"hc\"\n" +
    "                validate-data-submission-rules=\"{{$ctrl.question.validators}}\"\n" +
    "                validate-data-submission-Question=\"{{$ctrl.question.isExplanationProvided}}\"\n" +
    "                validate-data-submission-Question-Handle=\"{{$ctrl.question.handle}}\"\n" +
    "                validate-data-submission-answers=\"$ctrl.answers\"\n" +
    "                validate-data-submission-answers-before-changes=\"$ctrl.answersBeforeChanges\">\n" +
    "        </div>\n" +
    "        <div class=\"question-answer p-w-xxs\" \n" +
    "             ng-class=\"$ctrl.getClassForFte()\">\n" +
    "            <input class=\"form-control p-xxs\" id=\"fte_column_{{$ctrl.question.handle}}\"\n" +
    "                ng-hide=\"$ctrl.isSingleBox()\"\n" +
    "                ng-model=\"$ctrl.answers.fte\" validate-data-submission\n" +
    "                validate-data-submission-value=\"{{$ctrl.answers.fte}}\"\n" +
    "                validate-data-submission-original=\"{{$ctrl.answersBeforeChanges.fte}}\"\n" +
    "                validate-data-submission-value-type=\"fte\"\n" +
    "                validate-data-submission-rules=\"{{$ctrl.question.validators}}\"\n" +
    "                validate-data-submission-Question=\"{{$ctrl.question.isExplanationProvided}}\"\n" +
    "                validate-data-submission-Question-Handle=\"{{$ctrl.question.handle}}\"\n" +
    "                validate-data-submission-answers=\"$ctrl.answers\"\n" +
    "                validate-data-submission-answers-before-changes=\"$ctrl.answersBeforeChanges\">\n" +
    "        </div>\n" +
    "        <div class=\"col-xs-1 question-estimate p-w-xxs\"  ng-hide=\"$ctrl.ranking === 'esg'\">\n" +
    "            <input i-checkbox  ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.estimate\" value=\"true\">\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-1 question-estimate p-w-xxs\" ng-show=\"!$ctrl.isUrlField() && !$ctrl.isCheckBoxField() && !$ctrl.isSustaiabilityField() && ($ctrl.ranking === 'esg' || $ctrl.isSingleNumericField())\">\n" +
    "        <input i-checkbox  ng-click=\"$ctrl.handleFocus\" type=\"checkbox\" ng-model=\"$ctrl.answers.estimate\" value=\"true\">\n" +
    "    </div>\n" +
    "<div class=\"question-source p-w-xxs\" ng-if=\"!$ctrl.isClient\" ng-class=\"$ctrl.isCheckBoxField() ? 'col-xs-2 ' : 'col-xs-1'\">\n" +
    "    <statistics-source source=\"$ctrl.source\"\n" +
    "        enabled=\"$ctrl.hasAnswers() || ($ctrl.isSingleNumericField() && $ctrl.answers.tsn)\">\n" +
    "    </statistics-source>\n" +
    "</div>\n" +
    "<div class=\"col-xs-1 question-status\" ng-hide=\"$ctrl.isAdditionalStatisticQuestion() && $ctrl.isClient\">\n" +
    "    <statistics-status-popup status=\"$ctrl.status\"\n" +
    "        enabled=\"$ctrl.hasAnswers() || ($ctrl.isSingleNumericField() && $ctrl.answers.tsn)\">\n" +
    "    </statistics-status-popup>\n" +
    "</div>\n" +
    "</div>\n" +
    " <div ng-if=\"$ctrl.question.handle !== 'number_of_female_students' && $ctrl.isFileField()\"\n" +
    "    class=\"row question-content clickable\" ng-class=\"{'active': $ctrl.isActive()}\" ng-click=\"$ctrl.handleFocus()\">\n" +
    "    <div class=\"col-xs-5 question-name no-outline\"></div>\n" +
    "    <div class=\"col-xs-3 question-answer p-l-none  m-t-sm\">\n" +
    "        <a class=\"btn btn-default btn-sm m-b-xs ml-10\" target=\"_blank\" href=\"https://s3.eu-west-1.amazonaws.com/files.core-dev.qs.com/iu/ranking/Country+List.xlsx\">\n" +
    "            <i class=\"fa fa-share\"></i> Download template\n" +
    "        </a>\n" +
    "    </div>\n" +
    "    <div class=\"question-answer p-w-xxs m-t-sm col-xs-2\" ng-class=\"{'modal-overlay-35': $ctrl.uploadInProgress}\">\n" +
    "        <form class=\"clearfix\" name=\"$ctrl.forms.uploadForm\" enctype=\"multipart/form-data\" ng-dropzone\n" +
    "            dropzone=\"$ctrl.dropZoneInstance\" dropzone-config=\"$ctrl.config.dropzone\"\n" +
    "            event-handlers=\"$ctrl.config.eventHandlers\" novalidate focus-delay=\"250\">\n" +
    "            <div class=\"fallback form-group\">\n" +
    "                <input name=\"file\" type=\"file\" />\n" +
    "            </div>\n" +
    "            <div class=\"btn btn-default btn-outline btn-sm pull-left dz-message\" ng-show=\"$ctrl.uploadEnabled\">\n" +
    "                <i class=\"fa fa-upload\"></i> Upload\n" +
    "            </div>\n" +
    "        </form>\n" +
    "        <div class=\"mt-5px file-name\" ng-if=\"!$ctrl.uploadEnabled && $ctrl.upload\">\n" +
    "            <a href=\"{{ $ctrl.upload.path }}\" target=\"_blank\" class=\"no-text-transform inline\">\n" +
    "                <span class=\"label label-warning-light\">{{ $ctrl.upload.name }}</span>\n" +
    "            </a>\n" +
    "            <button ng-click=\"$ctrl.handleRemove()\" class=\"btn btn-xs btn-danger\">\n" +
    "                <i class=\"fa fa-times\"></i>\n" +
    "            </button>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-2 question-answer p-l-none  m-t-sm\" ng-class=\"{'modal-overlay-35': !$ctrl.answers.fileUrl}\">\n" +
    "        <a class=\"btn btn-sm m-b-xs ml-10\" ng-class=\"{'btn-default': !$ctrl.answers.fileUrl, 'btn-info': $ctrl.answers.fileUrl}\" target=\"_blank\" href=\"{{$ctrl.answers.fileUrl}}\">\n" +
    "            <i class=\"fa fa-share\"></i> Open link\n" +
    "        </a>\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/modules/rankings/components/statisticsSubmissions/statisticsSource/statisticsSourceView.html',
    "<ui-select\n" +
    "    ng-class=\"{'modal-overlay-35': !enabled}\"\n" +
    "    ng-disabled=\"!enabled\"\n" +
    "    ng-model=\"selectedSource.selectedItem\"\n" +
    "    search-enabled=\"true\"\n" +
    "    ng-change=\"handleOptionClick(selectedSource.selectedItem)\"\n" +
    "    theme=\"bootstrap\">\n" +
    "    <ui-select-match placeholder=\"Select option\">{{$select.selected.label}}</ui-select-match>\n" +
    "    <ui-select-choices\n" +
    "        position=\"auto\"\n" +
    "        repeat=\"item in sourceList | filter: $select.search\">\n" +
    "        <div ng-bind-html=\"item.label | highlight: $select.search\"></div>\n" +
    "    </ui-select-choices>\n" +
    "    <ui-select-no-choice>\n" +
    "        Not found\n" +
    "    </ui-select-no-choice>\n" +
    "</ui-select>\n" +
    "\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/statisticsSubmissions/statisticsStatusPopup/statisticsStatusPopupContentView.html',
    "<div class='animated fadeInDown'>\n" +
    "    <a ng-click='handleStatus(StatisticsStatusPopupController.statusList.accepted)' ng-class=\"status === StatisticsStatusPopupController.statusList.accepted ? 'disabled' : ''\">\n" +
    "        <i class='fa fa-check-circle fa-2x' aria-hidden='true' ng-class=\"status === StatisticsStatusPopupController.statusList.accepted ? 'text-grey' : 'text-navy'\"></i>\n" +
    "    </a>\n" +
    "    <a ng-click='handleStatus(StatisticsStatusPopupController.statusList.rejected)' ng-class=\"status === StatisticsStatusPopupController.statusList.rejected ? 'disabled' : ''\">\n" +
    "        <i class='fa fa-times-circle fa-2x' aria-hidden='true' ng-class=\"status === StatisticsStatusPopupController.statusList.rejected ? 'text-grey' : 'text-danger'\"></i>\n" +
    "    </a>\n" +
    "    <a ng-click='handleStatus(StatisticsStatusPopupController.statusList.pending)' ng-class=\"status === StatisticsStatusPopupController.statusList.pending ? 'disabled' : ''\">\n" +
    "        <i class='fa fa-hourglass-half fa-2x text-warning' aria-hidden='true' ng-class=\"status === StatisticsStatusPopupController.statusList.pending ? 'text-grey' : 'text-warning'\"></i>\n" +
    "    </a> \n" +
    "    <a ng-click='handleStatus(StatisticsStatusPopupController.statusList.queried)' ng-class=\"status === StatisticsStatusPopupController.statusList.queried ? 'disabled' : ''\">\n" +
    "        <i class='fa fa-question-circle fa-2x text-info' aria-hidden='true' ng-class=\"status === StatisticsStatusPopupController.statusList.queried ? 'text-grey' : 'text-info'\"></i>\n" +
    "    </a>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/modules/rankings/components/statisticsSubmissions/statisticsStatusPopup/statisticsStatusPopupView.html',
    "<span class=\"statistics-status-popup\">\n" +
    "    <span class=\"info\" ng-class=\"{'modal-overlay-35': !StatisticsStatusPopupController.isClient && !enabled}\">\n" +
    "        <i class=\"fa current-status\" aria-hidden=\"true\"\n" +
    "            ng-class=\"{\n" +
    "            'clickable': !StatisticsStatusPopupController.isClient,\n" +
    "            'fa-check-circle text-navy' : status === StatisticsStatusPopupController.statusList.accepted,\n" +
    "            'fa-times-circle text-danger': status === StatisticsStatusPopupController.statusList.rejected,\n" +
    "            'fa-hourglass-half text-warning': status === StatisticsStatusPopupController.statusList.pending,\n" +
    "            'fa-question-circle text-info': status === StatisticsStatusPopupController.statusList.queried,\n" +
    "            'fa-chevron-down': !status\n" +
    "            }\"\n" +
    "            popover-placement=\"bottom\"\n" +
    "            uib-popover-template=\"'/scripts/modules/rankings/components/statisticsSubmissions/statisticsStatusPopup/statisticsStatusPopupContentView.html'\"\n" +
    "            popover-trigger=\"'click'\"\n" +
    "            popover-is-open=\"StatisticsStatusPopupController.isOpen\"\n" +
    "            popover-enable=\"!StatisticsStatusPopupController.isClient && enabled\"></i>\n" +
    "    </span>\n" +
    "</span>"
  );


  $templateCache.put('/scripts/modules/rankings/components/statisticsSubmissions/statisticsSubmissionsView.html',
    "<div class=\"row\" ng-show=\"StatisticsSubmissionsController.validationErrors.length > 0\">\n" +
    "    <div class=\"col-lg-12\">\n" +
    "        <div id=\"errorBanner\">\n" +
    "            <p ng-repeat=\"error in StatisticsSubmissionsController.validationErrors\">\n" +
    "                <i class=\"fa fa-exclamation-triangle m-r-xs\" aria-hidden=\"true\"></i>\n" +
    "                {{StatisticsSubmissionsController.validationMessages[error]}}\n" +
    "            </p>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "<div id=\"esgHeader\" class=\"wrapper wrapper-content animated fadeInRight page-statistics-submissions\">\n" +
    "    <div class=\"row parent-div\">\n" +
    "        <div class=\"row font-bold question-header\" ng-show=\"StatisticsSubmissionsController.rankingHandle === 'esg'\">\n" +
    "            <div class=\"col-xs-5\"></div>\n" +
    "            <div class=\"col-xs-4 text-muted fte-column p-w-xxs\">\n" +
    "                Total Number\n" +
    "            </div>\n" +
    "            <div class=\"col-xs-1 text-muted p-w-xxs break-word\">\n" +
    "                Est.\n" +
    "            </div>\n" +
    "            <div class=\"col-xs-1 text-muted p-w-xxs\" ng-if=\"!StatisticsSubmissionsController.isClient\">\n" +
    "                Source\n" +
    "            </div>\n" +
    "            <div class=\"col-xs-1 text-muted\">\n" +
    "                Status\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"wrapper wrapper-content animated fadeInRight page-statistics-submissions\">\n" +
    "    <div wave-spinner class=\"wave-spinner\" ng-show=\"StatisticsSubmissionsController.isLoading()\"></div>\n" +
    "\n" +
    "    <div ng-include=\"'/scripts/shared/noAccess/noAccessView.html'\"\n" +
    "         ng-if=\"!StatisticsSubmissionsController.isLoading() && StatisticsSubmissionsController.noRankingsAccess()\"></div>\n" +
    "\n" +
    "    <stats-submissions-not-subscribed \n" +
    "        is-client=\"StatisticsSubmissionsController.isClient\"\n" +
    "        handle-no-ranking-access-close=\"StatisticsSubmissionsController.handleNoRankingAccessClose()\"\n" +
    "        ng-show=\"!StatisticsSubmissionsController.isLoading() && StatisticsSubmissionsController.noSubscribedToRankings()\">\n" +
    "    </stats-submissions-not-subscribed>\n" +
    "\n" +
    "    <div class=\"row parent-div\" ng-class=\"{'modal-overlay-35' : StatisticsSubmissionsController.noRankingsAccess() || StatisticsSubmissionsController.noSubscribedToRankings() || StatisticsSubmissionsController.isLoading()}\">\n" +
    "        <div id=\"statisticsSubmissions\" class=\"col-lg-9 left-hand-panel\">\n" +
    "            <div class=\"ibox float-e-margins\">\n" +
    "                <div class=\"ibox-content table-responsive\">\n" +
    "\n" +
    "                    <div class=\"clearfix\" ng-show=\"!StatisticsSubmissionsController.isLoading()\">\n" +
    "                        <div class=\"clearfix m-b\">\n" +
    "                            <div class=\"pull-left\" ng-class=\"{'modal-overlay-35': MainController.hasUnsavedChanges()}\">\n" +
    "                                <stats-submissions-back-button></stats-submissions-back-button>\n" +
    "\n" +
    "                                <stats-submissions-ranking-selection\n" +
    "                                    subscribed=\"StatisticsSubmissionsController.subscribedStatistics\"\n" +
    "                                    available=\"StatisticsSubmissionsController.statisticsAvailable\">\n" +
    "                                </stats-submissions-ranking-selection>\n" +
    "                            </div>\n" +
    "\n" +
    "                            <statistics-info-icons class=\"pull-right\"></statistics-info-icons>\n" +
    "                        </div>\n" +
    "\n" +
    "                        <div class=\"clearfix row\">\n" +
    "                            <div class=\"col-lg-12\">\n" +
    "                                <p class=\"disregard-formatting m-b\">\n" +
    "                                    <span ng-bind-html=\"StatisticsSubmissionsController.rankingDefinition\"></span> </br>\n" +
    "                                    <a ng-hide=\"StatisticsSubmissionsController.rankingHandle === 'esg'\" ng-href=\"https://support.qs.com/hc/en-gb/articles/4405027974290-Data-Appendix\" target=\"_blank\">\n" +
    "                                        <span>Click Here For Definitions & Guidelines</span>\n" +
    "                                    </a>\n" +
    "                                    <a ng-show=\"StatisticsSubmissionsController.rankingHandle === 'esg'\" download \n" +
    "                                    ng-href=\"https://s3.amazonaws.com/files.core.qs.com/rankings/QS_Hub_Sustainability_Form_2025_updated.xlsx\" target=\"_blank\">\n" +
    "                                        <span>Please click here to download an excel version of the form</span>\n" +
    "                                    </a>\n" +
    "                                    <!-- <span class=\"guideline-dropdown\" uib-dropdown>\n" +
    "                                        <a  uib-dropdown-toggle>\n" +
    "                                            <i class=\"fa fa-download\" aria-hidden=\"true\"></i> Refer Guidelines\n" +
    "                                            <i class=\"fa fa-angle-down\"></i>\n" +
    "                                        </a>\n" +
    "                                        <statistics-languages ranking-handle=\"StatisticsSubmissionsController.rankingHandle\" class=\"dropdown-menu\"></statistics-languages>\n" +
    "                                    </span> -->\n" +
    "                                </p>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                        <div>\n" +
    "\n" +
    "                        <div class=\"alert alert-success\">\n" +
    "                            <div class=\"row\">\n" +
    "                                <div class=\"col-lg-12\">\n" +
    "                                    <span class=\"block m-t-xs\"><i class=\"fa fa-star-o\"></i>\n" +
    "                                        Please note that indicators below with this symbol<span style=\"font-size: 25px\">↔</span> will automatically update if that indicator also appears in other QS rankings forms\n" +
    "                                    </span>\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                        <div class=\"alert alert-success\" ng-hide=\"StatisticsSubmissionsController.rankingHandle === 'esg'\">\n" +
    "                            <div class=\"row\">\n" +
    "                                <div class=\"col-lg-12\">\n" +
    "                                    <span class=\"block m-t-xs\"><i class=\"fa fa-star-o\"></i>\n" +
    "                                        If your submitted numbers are the same as your previous year's, please select the indicator and click on SAVE. There is no need to edit the field. This must be done for every indicator that you wish to use previous year's data for.\n" +
    "                                    </span>\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                        <!-- <div class=\"alert alert-success\" ng-hide=\"StatisticsSubmissionsController.rankingHandle === 'esg'\">\n" +
    "                            <div class=\"row\">\n" +
    "                                <div class=\"col-lg-12\">\n" +
    "                                    <span class=\"block m-t-xs\"><i class=\"fa fa-star-o\"></i>\n" +
    "                                        Please complete as much information as you can provide. This strengthens the validity of our analysis, and the integrity of the insights we can provide.\n" +
    "                                    </span>\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                        </div> -->\n" +
    "                        <div class=\"alert alert-success\" ng-show=\"StatisticsSubmissionsController.rankingHandle === 'esg'\">\n" +
    "                            <div class=\"row\">\n" +
    "                                <div class=\"col-lg-12\">\n" +
    "                                    <span class=\"block m-t-xs\"><i class=\"fa fa-star-o\"></i>\n" +
    "                                        Welcome to the 2nd edition of the QS Sustainability Ranking data collection cycle. We have given the Sustainability sector a bit of a facelift. You should now find it easier to navigate with the lenses of the ranking clearly separated, and the relevant questions included as dropdown options. Please provide as much accurate data as you can - this data will be used for the upcoming 2nd Edition of the Sustainability Ranking. Where data is not provided, QS will attempt to source it externally, but if this is not possible and we have empty cells this will affect the performance of your institution.\n" +
    "                                    </span>\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                        <div class=\"alert alert-success\">\n" +
    "                            <div class=\"row\">\n" +
    "                                <div class=\"col-lg-12\">\n" +
    "                                    <span class=\"block m-t-xs\"><i class=\"fa fa-star-o\"></i>\n" +
    "                                        All submissions are taken to be a true confirmation of that data point by the university, and a ‘signature’ from that university as to its veracity. QS reserves the right to ask for further evidence where we are not confident in the submission. Any submissions found to be inaccurate will be rejected. <b>Deliberate attempts by an institution to submit false data will result in rankings penalties or exclusion from the ranking.</b> \n" +
    "                                    </span>\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "\n" +
    "                        <form class=\"form-horizontal statistics-submission\" name=\"StatisticsSubmissionsController.forms.statistics\" novalidate>\n" +
    "                            <div ng-if=\"StatisticsSubmissionsController.rankingHandle === 'esg'\">\n" +
    "                                <uib-accordion class=\"accordion-with-arrows\" close-others=\"false\">\n" +
    "                                    <div uib-accordion-group class=\"panel-default\"\n" +
    "                                        ng-repeat=\"item in StatisticsSubmissionsController.sustainabilityHeaders\"\n" +
    "                                        heading=\"{{item.name}}\" is-open=\"item.open\">\n" +
    "                                        <div ng-repeat=\"(categoryHandle, categoryQuestions) in StatisticsSubmissionsController.questions\">\n" +
    "\n" +
    "                                            <statistics-personal-header\n" +
    "                                                ranking=\"StatisticsSubmissionsController.rankingHandle\"\n" +
    "                                                category-handle=\"categoryHandle\"\n" +
    "                                                ng-if=\"(categoryQuestions[0].type === StatisticsSubmissionsController.type.socialImpact ||\n" +
    "                                                categoryQuestions[0].type === StatisticsSubmissionsController.type.environmentalImpact ||\n" +
    "                                                categoryQuestions[0].type === StatisticsSubmissionsController.type.governance ||\n" +
    "                                                categoryQuestions[0].type === StatisticsSubmissionsController.type.additionalInformation) && categoryQuestions[0].type === item.handle\">\n" +
    "                                            </statistics-personal-header>\n" +
    "                                            <!-- <statistics-financial-header\n" +
    "                                                category-handle=\"categoryHandle\"\n" +
    "                                                ng-if=\"categoryQuestions[0].type === StatisticsSubmissionsController.type.additionalInformation && categoryQuestions[0].type === item.handle\">\n" +
    "                                            </statistics-financial-header> -->\n" +
    "\n" +
    "                                            <div ng-repeat=\"question in categoryQuestions\">\n" +
    "                                                <statistics-personal-question question=\"question\"\n" +
    "                                                    ranking=\"StatisticsSubmissionsController.rankingHandle\"\n" +
    "                                                    ng-if=\"(question.type === StatisticsSubmissionsController.type.socialImpact ||\n" +
    "                                                    question.type === StatisticsSubmissionsController.type.environmentalImpact ||\n" +
    "                                                    question.type === StatisticsSubmissionsController.type.governance ||\n" +
    "                                                    question.type === StatisticsSubmissionsController.type.additionalInformation) && question.type === item.handle\">\n" +
    "                                                </statistics-personal-question>\n" +
    "                                                <statistics-financial-question question=\"question\"\n" +
    "                                                    ranking=\"StatisticsSubmissionsController.rankingHandle\"\n" +
    "                                                    ng-if=\"question.subType === StatisticsSubmissionsController.type.additionalInformation && question.subType === item.handle\">\n" +
    "                                                </statistics-financial-question>\n" +
    "                                            </div>\n" +
    "                                        </div>\n" +
    "                                    </div>\n" +
    "                                </uib-accordion>\n" +
    "                                <a class=\"btn btn-primary btn-sm pull-right\"\n" +
    "                                    ng-show=\"StatisticsSubmissionsController.rankingHandle === 'esg'\"\n" +
    "                                    ng-class=\"{'disabled': StatisticsSubmissionsController.isSavingDisabled()}\"\n" +
    "                                    ng-click=\"StatisticsSubmissionsController.handleSaveClick()\">\n" +
    "                                    <i class=\"fa fa-check-circle\"></i> Save\n" +
    "                                </a>\n" +
    "                            </div>\n" +
    "                            <div ng-if=\"!StatisticsSubmissionsController.handleOverallVisibilityForClient() && StatisticsSubmissionsController.rankingHandle !== 'esg'\">\n" +
    "                                <div\n" +
    "                                    ng-repeat=\"(categoryHandle, categoryQuestions) in StatisticsSubmissionsController.questions\">\n" +
    "                                    <statistics-personal-header\n" +
    "                                        ng-hide=\"StatisticsSubmissionsController.rankingHandle === 'esg'\"\n" +
    "                                        ranking=\"StatisticsSubmissionsController.rankingHandle\"\n" +
    "                                        category-handle=\"categoryHandle\"\n" +
    "                                        ng-if=\"categoryQuestions[0].type === StatisticsSubmissionsController.type.personal\">\n" +
    "                                    </statistics-personal-header>\n" +
    "                                    <statistics-numeric-header\n" +
    "                                        ng-hide=\"StatisticsSubmissionsController.rankingHandle === 'esg'\"\n" +
    "                                        category-handle=\"categoryHandle\"\n" +
    "                                        ng-if=\"categoryQuestions[0].type === StatisticsSubmissionsController.type.total\">\n" +
    "                                    </statistics-numeric-header>\n" +
    "                                    <statistics-financial-header\n" +
    "                                        ng-hide=\"StatisticsSubmissionsController.rankingHandle === 'esg'\"\n" +
    "                                        category-handle=\"categoryHandle\"\n" +
    "                                        ng-if=\"categoryQuestions[0].type === StatisticsSubmissionsController.type.financial\">\n" +
    "                                    </statistics-financial-header>\n" +
    "\n" +
    "                                    <div ng-repeat=\"question in categoryQuestions\">\n" +
    "                                        <statistics-personal-question question=\"question\"\n" +
    "                                            ranking=\"StatisticsSubmissionsController.rankingHandle\"\n" +
    "                                            ng-if=\"question.type === StatisticsSubmissionsController.type.personal\">\n" +
    "                                        </statistics-personal-question>\n" +
    "                                        <statistics-financial-question question=\"question\"\n" +
    "                                            ranking=\"StatisticsSubmissionsController.rankingHandle\"\n" +
    "                                            ng-if=\"question.type === StatisticsSubmissionsController.type.financial\">\n" +
    "                                        </statistics-financial-question>\n" +
    "                                        <statistics-numeric-question question=\"question\"\n" +
    "                                            ng-if=\"question.type === StatisticsSubmissionsController.type.total\">\n" +
    "                                        </statistics-numeric-question>\n" +
    "                                    </div>\n" +
    "                                </div>\n" +
    "\n" +
    "                                <a class=\"btn btn-primary btn-sm pull-right\"\n" +
    "                                    ng-show=\"StatisticsSubmissionsController.rankingHandle === 'esg'\"\n" +
    "                                    ng-class=\"{'disabled': StatisticsSubmissionsController.isSavingDisabled()}\"\n" +
    "                                    ng-click=\"StatisticsSubmissionsController.handleSaveClick()\">\n" +
    "                                    <i class=\"fa fa-check-circle\"></i> Save\n" +
    "                                </a>\n" +
    "                            </div>\n" +
    "\n" +
    "                            <div ng-if=\"StatisticsSubmissionsController.handleOverallVisibilityForClient() && StatisticsSubmissionsController.rankingHandle !== 'esg'\">\n" +
    "                                <div ng-repeat=\"(categoryHandle, categoryQuestions) in StatisticsSubmissionsController.questions\" ng-show=\"StatisticsSubmissionsController.handleCategoryVisibilityForClient(categoryHandle)\">\n" +
    "                                    \n" +
    "                                    <statistics-personal-header\n" +
    "                                        ng-hide=\"StatisticsSubmissionsController.rankingHandle === 'esg'\"\n" +
    "                                        ranking=\"StatisticsSubmissionsController.rankingHandle\"\n" +
    "                                        category-handle=\"categoryHandle\"\n" +
    "                                        ng-if=\"categoryQuestions[0].type === StatisticsSubmissionsController.type.personal\">\n" +
    "                                    </statistics-personal-header>\n" +
    "\n" +
    "                                    <div ng-repeat=\"question in categoryQuestions\" ng-show=\"StatisticsSubmissionsController.handleCategoryVisibilityForClient(categoryHandle,question)\">\n" +
    "                                        <statistics-personal-question question=\"question\"\n" +
    "                                            ranking=\"StatisticsSubmissionsController.rankingHandle\"\n" +
    "                                            ng-if=\"question.type === StatisticsSubmissionsController.type.personal\">\n" +
    "                                        </statistics-personal-question>\n" +
    "                                        <statistics-financial-question question=\"question\"  ng-hide=\"StatisticsSubmissionsController.handleOverallVisibilityForClient()\"\n" +
    "                                            ranking=\"StatisticsSubmissionsController.rankingHandle\"\n" +
    "                                            ng-if=\"question.type === StatisticsSubmissionsController.type.financial\">\n" +
    "                                        </statistics-financial-question>\n" +
    "                                        <statistics-numeric-question question=\"question\" ng-hide=\"StatisticsSubmissionsController.handleOverallVisibilityForClient()\"\n" +
    "                                            ng-if=\"question.type === StatisticsSubmissionsController.type.total\">\n" +
    "                                        </statistics-numeric-question>\n" +
    "                                    </div>\n" +
    "                                </div>\n" +
    "\n" +
    "                                <a class=\"btn btn-primary btn-sm pull-right\"\n" +
    "                                    ng-class=\"{'disabled': StatisticsSubmissionsController.isSavingDisabled()}\"\n" +
    "                                    ng-click=\"StatisticsSubmissionsController.handleSaveClick()\">\n" +
    "                                    <i class=\"fa fa-check-circle\"></i> Save\n" +
    "                                </a>\n" +
    "                            </div>\n" +
    "                           \n" +
    "                        </form>\n" +
    "                        <div class=\"ger-employment-rate\" ng-hide=\"StatisticsSubmissionsController.handleOverallVisibilityForClient()\">\n" +
    "                            <ger-employment-rate ng-hide=\"StatisticsSubmissionsController.rankingHandle === 'esg'\" categories=\"\" is-other-ranking='true' handle-parent-save = \"StatisticsSubmissionsController.handleSave()\" is-Parent-valid=\"StatisticsSubmissionsController.isValid()\"\n" +
    "                            questions=\"\"></ger-employment-rate>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    </div>\n" +
    "\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div fixed-element-while-scrolling=\"#statisticsSubmissions\" class=\"col-lg-3 scroll-floating-element right-hand-panel\">\n" +
    "            <statistics-right-panel></statistics-right-panel>\n" +
    "        </div>\n" +
    "\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<script>\n" +
    "    $.fn.isInViewport = function () {\n" +
    "        var elementTop = $(this).offset().top;\n" +
    "        var elementBottom = elementTop + $(this).outerHeight();\n" +
    "        var viewportTop = $(window).scrollTop();\n" +
    "        var viewportBottom = viewportTop + $(window).height();\n" +
    "        return elementBottom > viewportTop && elementTop < viewportBottom;\n" +
    "    };\n" +
    "\n" +
    "    // window.onscroll = function () {\n" +
    "    //     myFunction()\n" +
    "    // };\n" +
    "\n" +
    "    // var header = document.getElementById(\"esgHeader\");\n" +
    "    // var sticky = header.offsetTop;\n" +
    "\n" +
    "    // function myFunction() {\n" +
    "    //     if(!$('#esgFirstHeader').length) {\n" +
    "    //         return;\n" +
    "    //     }\n" +
    "    //     $('#esgHeader').width($('#esgFirstHeader').width())\n" +
    "    //     if (window.pageYOffset > sticky && $('#esgFirstHeader').isInViewport()) {\n" +
    "    //         header.classList.remove(\"sticky\");\n" +
    "    //     } else {\n" +
    "\n" +
    "    //         header.classList.add(\"sticky\");\n" +
    "    //     }\n" +
    "    // }\n" +
    "</script>"
  );


  $templateCache.put('/scripts/modules/rankings/components/statisticsSubmissions/statsSubmissionsBackButton/statsSubmissionsBackButtonView.html',
    "<div class=\"pull-left go-back m-r\">\n" +
    "    <a class=\"btn btn-default btn-sm \" ui-sref=\"clients.statistics.data-submission\">\n" +
    "        <i class=\"fa fa-arrow-left\"></i> Back to landing page\n" +
    "    </a>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/statisticsSubmissions/statsSubmissionsNotSubscribed/statsSubmissionsNotSubscribedView.html',
    "<div class=\"widget red-bg p-lg text-center no-access-institution col-lg-4\">\n" +
    "    <div class=\"m-b-md\">\n" +
    "        <i class=\"fa fa-warning fa-4x\"></i>\n" +
    "        <h1 class=\"m-xs\">No subscription to this ranking</h1>\n" +
    "        <p ng-if=\"!$ctrl.isClient\">\n" +
    "            If you wish to continue submitting data <a ng-click=\"$ctrl.handleNoRankingAccessClose()\">click here</a>\n" +
    "        </p>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/statisticsSubmissions/statsSubmissionsRankingSelection/statsSubmissionsRankingSelectionView.html',
    "<div class=\"pull-left\">\n" +
    "    <ui-select\n" +
    "        class=\"wi-235\"\n" +
    "        ng-model=\"$ctrl.rankingFilter\"\n" +
    "        search-enabled=\"true\"\n" +
    "        on-select=\"$ctrl.handleChanges()\"\n" +
    "        theme=\"bootstrap\">\n" +
    "        <ui-select-match placeholder=\"Select ranking\">{{$select.selected.label}}</ui-select-match>\n" +
    "        <ui-select-choices\n" +
    "            position=\"down\"\n" +
    "            ui-disable-choice=\"option.disabled\"\n" +
    "            repeat=\"item in ($ctrl.subscribed | filter: $select.search) | orderArray:false:'label' track by item.value\">\n" +
    "            <div ng-bind-html=\"item.label | highlight: $select.search\"></div>\n" +
    "        </ui-select-choices>\n" +
    "        <ui-select-no-choice>\n" +
    "            Not found\n" +
    "        </ui-select-no-choice>\n" +
    "    </ui-select>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/rankings/components/statisticsSubmissions/unEdittedIndicatorsDialog.html',
    "<div class=\"modal-header\">\n" +
    "    <h3>Data Submission</h3>\n" +
    "</div>\n" +
    "<div class=\"modal-body\">\n" +
    "    <p>Unedited indicators will not be submitted. If you would like to submit unedited indicators as a new data, please follow the instructions above the table.</p>\n" +
    "</div>\n" +
    "<div class=\"modal-footer\">\n" +
    "    <button ng-click=\"modalOptions.handleNoClick()\" type=\"button\" class=\"btn btn-default\" >\n" +
    "        Cancel\n" +
    "    </button>\n" +
    "    <button class=\"btn btn-primary\" ng-click=\"modalOptions.handleYesClick();\">\n" +
    "        OK\n" +
    "    </button>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/stars/components/badgesLogs/starsBadgesLogsView.html',
    "<div class=\"ibox\">\n" +
    "    <div class=\"ibox-title\">\n" +
    "        <h5>Badges Upload Logs</h5>\n" +
    "    </div>\n" +
    "    <div class=\"ibox-content publish-logs\">\n" +
    "        <div wave-spinner class=\"text-right\" ng-show=\"$ctrl.isFetchInProgress()\"></div>\n" +
    "\n" +
    "        <div ng-show=\"!$ctrl.isFetchInProgress()\">\n" +
    "            <b ng-show=\"!$ctrl.logs\">No results</b>\n" +
    "\n" +
    "            <div ng-show=\"$ctrl.logs\">\n" +
    "                <div ng-repeat=\"logsPerDay in $ctrl.logs\" class=\"record border-bottom m-b\">\n" +
    "                    <div class=\"panel panel-default\">\n" +
    "                        <div class=\"panel-heading\">\n" +
    "                            <span>{{logsPerDay[0].createdAt | date:'mediumDate'}}</span>\n" +
    "                        </div>\n" +
    "                        <div class=\"panel-body\">\n" +
    "                            <ul class=\"list-unstyled\">\n" +
    "                                <li ng-repeat=\"item in logsPerDay\">\n" +
    "                                    <span class=\"icon\" ng-class=\"$ctrl.getLogClassColor(item.status)\">\n" +
    "                                        <i class=\"fa fa-check\" ng-class=\"$ctrl.getLogClassFaIcon(item.status)\"></i>\n" +
    "                                    </span>\n" +
    "                                    Badges of <span class=\"bold\">{{ item.type | strReplace:'_':' ' | strReplace:'([A-Z])':' $1' | capitalize }}</span> uploaded by <span class=\"bold\">{{ item.createdByFullName }}</span> at <small class=\"time\">{{ item.createdAt | date:'shortTime'}}</small> Succeeded: {{ item.totalBadges || 0 }} Failed: {{ item.totalFiles - item.totalBadges }} Total: {{ item.totalFiles || 0 }}\n" +
    "                                </li>\n" +
    "                            </ul>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div class=\"row\" ng-show=\"$ctrl.isMoreLogsAvailable()\">\n" +
    "                    <div class=\"col-lg-2 col-lg-offset-5\">\n" +
    "                        <a class=\"btn btn-primary btn-block\"\n" +
    "                           ng-click=\"$ctrl.handleLoadMore()\">\n" +
    "                            Load more\n" +
    "                        </a>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/stars/components/badgesOverview/starsBadgesOverviewPageView.html',
    "<div class=\"wrapper wrapper-content animated fadeInRight page-stars-badges-overview\">\n" +
    "    <div class=\"row\">\n" +
    "        <div class=\"section\">\n" +
    "            <div class=\"section-body\">\n" +
    "                <stars-badges-overview></stars-badges-overview>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/stars/components/badgesOverview/starsBadgesOverviewView.html',
    "<div wave-spinner class=\"absolute-wave-spinner\" ng-show=\"$ctrl.showLoadBar()\"></div>\n" +
    "\n" +
    "<div ng-class=\"{'modal-overlay-35': $ctrl.showLoadBar()}\" ng-if=\"$ctrl.gridOptions\">\n" +
    "    <div class=\"grid\"\n" +
    "         ui-grid=\"$ctrl.gridOptions\"\n" +
    "         ui-grid-resize-columns\n" +
    "         ui-grid-auto-resize\n" +
    "         ui-grid-pagination>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/stars/components/badgesPreview/starsBadgesPreviewView.html',
    "<div wave-spinner class=\"absolute-wave-spinner\" ng-show=\"$ctrl.showLoadBar()\"></div>\n" +
    "\n" +
    "<div ng-class=\"{'modal-overlay-35': $ctrl.showLoadBar()}\" ng-if=\"$ctrl.gridOptions\">\n" +
    "    <div class=\"grid\"\n" +
    "         ui-grid=\"$ctrl.gridOptions\"\n" +
    "         ui-grid-resize-columns\n" +
    "         ui-grid-auto-resize>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"control-buttons m-t\">\n" +
    "        <button class=\"btn btn-default\" ng-click=\"$ctrl.back()\">\n" +
    "            <i class=\"fa fa-reply\"></i> Go back\n" +
    "        </button>\n" +
    "        <button class=\"btn btn-primary pull-right\"\n" +
    "                ng-class=\"{'modal-overlay-35': !$ctrl.allowNext()}\"\n" +
    "                ng-click=\"$ctrl.next()\">\n" +
    "            <i class=\"fa fa-cloud-upload\"></i> Validate & Save\n" +
    "        </button>\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n"
  );


  $templateCache.put('/scripts/modules/stars/components/badgesPreview/starsCellTemplate.html',
    "<div class=\"ui-grid-cell-contents no-select text-center\">\n" +
    "    <span ng-if=\"row.entity.errors.length\" class=\"text-red\">\n" +
    "        {{ row.entity.errors.join(', ') }}\n" +
    "    </span>\n" +
    "    <i ng-if=\"!row.entity.errors.length\" class=\"fa fa-star\" ng-repeat=\"i in grid.appScope.getEmptyArray(COL_FIELD) track by $index\"></i>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/stars/components/badgesSteps/starsBadgesStepsView.html',
    "<div class=\"wizard-big wizard\">\n" +
    "    <div class=\"steps clearfix\">\n" +
    "        <ul>\n" +
    "            <li class=\"first\" ng-class=\"$ctrl.isActive(1) ? 'current' : 'disabled'\">\n" +
    "                <a>\n" +
    "                    <span class=\"number\">1.</span> Upload\n" +
    "                </a>\n" +
    "            </li>\n" +
    "            <li ng-class=\"$ctrl.isActive(2) ? 'current' : 'disabled'\">\n" +
    "                <a>\n" +
    "                    <span class=\"number\">2.</span> Preview & Save\n" +
    "                </a>\n" +
    "            </li>\n" +
    "        </ul>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/stars/components/badgesUpload/starsBadgesUploadView.html',
    "<form class=\"form-horizontal row\" name=\"forms.badgesUpload\" novalidate=\"\">\n" +
    "    <div class=\"form-group general-tab no-margins\">\n" +
    "\n" +
    "        <div class=\"col-sm-5 col-md-4 col-lg-2 p-w-md\" ng-class=\"{'modal-overlay-35' : !$ctrl.uploadEnabled}\">\n" +
    "            <div class=\"upload\">\n" +
    "                <div class=\"dropzone\"\n" +
    "                     name=\"uploadBadgeForm\"\n" +
    "                     enctype=\"multipart/form-data\"\n" +
    "                     ng-dropzone\n" +
    "                     dropzone=\"$ctrl.dropZoneInstance\"\n" +
    "                     dropzone-config=\"$ctrl.config.dropzone\"\n" +
    "                     event-handlers=\"$ctrl.config.eventHandlers\"\n" +
    "                     novalidate >\n" +
    "                    <div class=\"fallback\">\n" +
    "                        <input name=\"file\" type=\"file\" />\n" +
    "                    </div>\n" +
    "                    <div class=\"dz-message\">\n" +
    "                        <i class=\"fa fa-upload\"></i>\n" +
    "                    </div>\n" +
    "                    <div class=\"dropzone-previews\"></div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"col-sm-4 col-md-3  col-lg-3 col-xs-12 p-w-md\">\n" +
    "            <div class=\"m-t-xl p-w-xl\">\n" +
    "                <ui-select\n" +
    "                    ng-required=\"true\"\n" +
    "                    ng-model=\"$ctrl.type\"\n" +
    "                    on-select=\"$ctrl.handleTypeChanges()\"\n" +
    "                    theme=\"bootstrap\">\n" +
    "                    <ui-select-match placeholder=\"Select badge type\">{{$select.selected.label}}</ui-select-match>\n" +
    "                    <ui-select-choices\n" +
    "                        position=\"down\"\n" +
    "                        ui-disable-choice=\"option.disabled\"\n" +
    "                        repeat=\"option in $ctrl.list | filter:$select.search track by option.value\">\n" +
    "                        <div ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                    </ui-select-choices>\n" +
    "                    <ui-select-no-choice>\n" +
    "                        Not found\n" +
    "                    </ui-select-no-choice>\n" +
    "                </ui-select>\n" +
    "                <p>\n" +
    "                    <span class=\"bold text-muted\">Note:</span><br>\n" +
    "                    <span class=\"bold text-muted\">File types:</span>\n" +
    "                    <small>.zip</small><br>\n" +
    "                    <span class=\"bold text-muted\">Maximum size:</span>\n" +
    "                    <small>{{ $ctrl.maxSize }} MB</small>\n" +
    "                </p>\n" +
    "                <a class=\"btn btn-default btn-xs\"\n" +
    "                   href=\"https://docs.google.com/spreadsheets/d/1Oz8SPzeUHrgbNzWEenzThkHan17CCOhXrBqnbNV72_E\" \n" +
    "                   target=\"_blank\" >\n" +
    "                     Download naming convention\n" +
    "                </a>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "    </div>\n" +
    "</form>\n"
  );


  $templateCache.put('/scripts/modules/stars/components/clients/starsClientDownloadBadges/starsClientDownloadBadgesView.html',
    "<div wave-spinner class=\"absolute-wave-spinner\" ng-show=\"$ctrl.isLoading()\"></div>\n" +
    "\n" +
    "<div ng-class=\"{'modal-overlay-35': $ctrl.isLoading()}\"\n" +
    "     ng-if=\"$ctrl.hasData()\">\n" +
    "    <div class=\"page-header clearfix\">\n" +
    "        <h3 class=\"pull-left text-normal\">Download Badges</h3>\n" +
    "    </div>\n" +
    "    <div>\n" +
    "        <ul>\n" +
    "            <li ng-if=\"$ctrl.data.answers[0].zip.overall\">\n" +
    "                Overall\n" +
    "                <a download href=\"{{ $ctrl.data.answers[0].zip.overall }}\"><i class=\"fa fa-download\"></i></a>\n" +
    "            </li>\n" +
    "            <li ng-if=\"$ctrl.data.answers[0].zip.category\">\n" +
    "                Category\n" +
    "                <a download href=\"{{ $ctrl.data.answers[0].zip.category }}\"><i class=\"fa fa-download\"></i></a>\n" +
    "            </li>\n" +
    "            <li ng-if=\"$ctrl.data.answers[0].zip.subject\">\n" +
    "                Subject\n" +
    "                <a download href=\"{{ $ctrl.data.answers[0].zip.subject }}\"><i class=\"fa fa-download\"></i></a>\n" +
    "            </li>\n" +
    "            <li ng-if=\"$ctrl.data.answers[0].zip.paid\">\n" +
    "                Specialist\n" +
    "                <a download href=\"{{ $ctrl.data.answers[0].zip.paid }}\"><i class=\"fa fa-download\"></i></a>\n" +
    "            </li>\n" +
    "            <li ng-if=\"$ctrl.data.answers[0].zip.academic_development\">\n" +
    "                Academic Development\n" +
    "                <a download href=\"{{ $ctrl.data.answers[0].zip.academic_development }}\"><i class=\"fa fa-download\"></i></a>\n" +
    "            </li>\n" +
    "        </ul>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/stars/components/clients/starsClientDownloadFiles/starsClientDownloadFilesView.html',
    "<div wave-spinner class=\"absolute-wave-spinner\" ng-show=\"$ctrl.isLoading()\"></div>\n" +
    "\n" +
    "<div ng-class=\"{'modal-overlay-35': $ctrl.isLoading()}\"\n" +
    "     ng-show=\"$ctrl.hasData()\">\n" +
    "    <div class=\"page-header clearfix\">\n" +
    "        <h3 class=\"pull-left text-normal\">Download Files</h3>\n" +
    "    </div>\n" +
    "    <div>\n" +
    "        <ul>\n" +
    "            <li ng-if=\"$ctrl.data.answers[0].certificateUrl\">\n" +
    "                {{ $ctrl.getFileName($ctrl.data.answers[0].certificateUrl) }}\n" +
    "                <a download target=\"_blank\" href=\"{{$ctrl.data.answers[0].certificateUrl}}\"><i class=\"fa fa-download\"></i></a>\n" +
    "            </li>\n" +
    "            <li ng-if=\"$ctrl.data.answers[0].developmentRoadmapUrl\">\n" +
    "                {{ $ctrl.getFileName($ctrl.data.answers[0].developmentRoadmapUrl) }}\n" +
    "                <a download target=\"_blank\" href=\"{{$ctrl.data.answers[0].developmentRoadmapUrl}}\"><i class=\"fa fa-download\"></i></a>\n" +
    "            </li>\n" +
    "            <li ng-if=\"$ctrl.latestGuideline\">\n" +
    "                {{ $ctrl.latestGuideline.name }}\n" +
    "                <a download target=\"_blank\" href=\"{{ $ctrl.latestGuideline.url }}\"><i class=\"fa fa-download\"></i></a>\n" +
    "            </li>\n" +
    "            <li ng-if=\"$ctrl.latestMethodology\">\n" +
    "                {{ $ctrl.latestMethodology.name }}\n" +
    "                <a download target=\"_blank\" href=\"{{ $ctrl.latestMethodology.url }}\"><i class=\"fa fa-download\"></i></a>\n" +
    "            </li>\n" +
    "            <li ng-if=\"$ctrl.latestRoadmap\">\n" +
    "                {{ $ctrl.latestRoadmap.name }}\n" +
    "                <a download target=\"_blank\" href=\"{{ $ctrl.latestRoadmap.url }}\"><i class=\"fa fa-download\"></i></a>\n" +
    "            </li>\n" +
    "        </ul>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/stars/components/clients/starsClientsView.html',
    "<div class=\"wrapper wrapper-content animated fadeInRight page-clients-stars\">\n" +
    "    <div wave-spinner class=\"absolute-wave-spinner\" ng-show=\"ClientStarsController.isLoading()\"></div>\n" +
    "\n" +
    "    <div ng-include=\"'/scripts/shared/noAccess/noAccessView.html'\"\n" +
    "         ng-if=\"!ClientStarsController.isLoading() && ClientStarsController.noSubscribedToStars()\"></div>\n" +
    "\n" +
    "    <div class=\"row\" ng-class=\"{'modal-overlay-35' : ClientStarsController.noSubscribedToStars() || ClientStarsController.isLoading()}\">\n" +
    "    <div class=\"ibox float-e-margins\">\n" +
    "        <div class=\"ibox-content\">\n" +
    "            <div class=\"row\" ng-class=\"{'modal-overlay-35': ClientStarsController.isLoading()}\">\n" +
    "                <div class=\"col-lg-12\">\n" +
    "                    <div class=\"form-group\" >\n" +
    "                        <div class=\"row\">\n" +
    "                            <label class=\"control-label col-lg-1 col-sm-2\">\n" +
    "                                Star Rating:\n" +
    "                            </label>\n" +
    "                            <div class=\"col-lg-2 word-break-all\">\n" +
    "                                <div ng-if=\"ClientStarsController.data\">\n" +
    "                                    {{ ClientStarsController.data.answers[0].totalStarsRating }} / 1000\n" +
    "                                    <span ng-bind-html=\"ClientStarsController.getStars()\"></span>\n" +
    "                                </div>\n" +
    "                                <div ng-if=\"!ClientStarsController.data\">\n" +
    "                                    Not Evaluated Yet\n" +
    "                                </div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                        <div class=\"row\" ng-if=\"ClientStarsController.data.version\">\n" +
    "                            <label class=\"control-label col-lg-1 col-sm-2\">\n" +
    "                                Version:\n" +
    "                            </label>\n" +
    "                            <div class=\"col-lg-2 word-break-all\">\n" +
    "                                {{ClientStarsController.data.version}}\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "\n" +
    "                        <div class=\"row\" ng-if=\"ClientStarsController.isMiniCoreData === false && (ClientStarsController.data.modifiedAt || ClientStarsController.data.createdAt)\">\n" +
    "                            <label class=\"control-label col-lg-1 col-sm-2\">\n" +
    "                                Date updated:\n" +
    "                            </label>\n" +
    "                            <div class=\"col-lg-2 word-break-all\">\n" +
    "                                {{ ClientStarsController.data.modifiedAt || ClientStarsController.data.createdAt | date }}\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <stars-client-download-files data=\"ClientStarsController.data\"></stars-client-download-files>\n" +
    "                    <stars-client-download-badges data=\"ClientStarsController.data\"></stars-client-download-badges>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"row m-b-xs\">\n" +
    "                <button class=\"btn btn-default btn-xs pull-right\"\n" +
    "                        ng-click=\"ClientStarsController.toggleTabs()\">\n" +
    "                    <i class=\"fa\" ng-class=\"ClientStarsController.isAllTabsExpanded ? 'fa-arrow-up' : 'fa-arrow-down'\"></i>\n" +
    "                    <span>{{ClientStarsController.isAllTabsExpanded ? 'Collapse all tabs' : 'Expand all tabs'}}</span>\n" +
    "                </button>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "                <stars-sections selected-item=\"ClientStarsController.data\"\n" +
    "                    sections=\"ClientStarsController.sections\"\n" +
    "                    categories=\"ClientStarsController.categories\"></stars-sections>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/stars/components/commonFiles/starsCommonFilesView.html',
    "<div class=\"form-horizontal m-b-none\" ng-class=\"{'modal-overlay-35': $ctrl.toLoad !== 0}\">\n" +
    "    <div class=\"form-group no-margins\">\n" +
    "        <label class=\"col-lg-2 col-sm-3 control-label\">\n" +
    "            Select document type\n" +
    "            <br>\n" +
    "            <small class=\"text-muted\">Maximum size {{ $ctrl.maxSize }}MB</small>\n" +
    "        </label>\n" +
    "\n" +
    "        <div class=\"col-lg-3 col-sm-3\">\n" +
    "            <ui-select\n" +
    "                close-on-select=\"true\"\n" +
    "                ng-model=\"$ctrl.type\"\n" +
    "                search-enabled=\"true\"\n" +
    "                theme=\"bootstrap\">\n" +
    "                <ui-select-match placeholder=\"Type\" allow-clear=\"true\">\n" +
    "                    {{$select.selected.label}}\n" +
    "                </ui-select-match>\n" +
    "                <ui-select-choices\n" +
    "                    refresh-delay=\"1000\"\n" +
    "                    repeat=\"option in $ctrl.list | filter:$select.search\">\n" +
    "                    <div ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                </ui-select-choices>\n" +
    "                <ui-select-no-choice>\n" +
    "                    Not found\n" +
    "                </ui-select-no-choice>\n" +
    "            </ui-select>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"col-lg-4 col-sm-6\">\n" +
    "            <label class=\"control-label pull-left m-r mb-m-t-10\">\n" +
    "                Version\n" +
    "            </label>\n" +
    "            <input class=\"form-control pull-left m-r wi-82 mb-m-t-10\" type=\"number\" step=\"0.01\" min=\"0\" name=\"version\"\n" +
    "                   ng-model=\"$ctrl.version\">\n" +
    "            <form class=\"pull-left m-l mb-m-t-10 mb-m-l-none\"\n" +
    "                  name=\"$ctrl.forms.uploadForm\"\n" +
    "                  enctype=\"multipart/form-data\"\n" +
    "                  ng-dropzone\n" +
    "                  dropzone=\"$ctrl.dropZoneInstance\"\n" +
    "                  dropzone-config=\"$ctrl.config.dropzone\"\n" +
    "                  event-handlers=\"$ctrl.config.eventHandlers\"\n" +
    "                  novalidate\n" +
    "                  dz-hidden-input-file>\n" +
    "                <div class=\"fallback form-group\">\n" +
    "                    <input ng-disabled=\"$ctrl.isUploadDisabled()\" name=\"file\" type=\"file\"/>\n" +
    "                </div>\n" +
    "\n" +
    "                <button class=\"btn btn-default btn-outline btn-sm dz-message\" ng-disabled=\"$ctrl.isUploadDisabled()\">\n" +
    "                    <i class=\"fa fa-upload\"></i> Upload\n" +
    "                </button>\n" +
    "            </form>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group no-margins mb-m-t-10\">\n" +
    "        <label class=\"col-lg-offset-2 col-lg-2 control-label text-muted\">\n" +
    "            Current Versions\n" +
    "        </label>\n" +
    "        <div class=\"col-lg-offset-2 col-lg-10\">\n" +
    "            <span ng-if=\"!$ctrl.documents.length\">none</span>\n" +
    "            <ul ng-if=\"$ctrl.documents.length\" class=\"list-inline list-border\">\n" +
    "                <li ng-repeat=\"item in $ctrl.documents\">\n" +
    "                    <a href=\"{{item.url}}\" target=\"_blank\" class=\"no-text-transform inline\">\n" +
    "                        <span class=\"label label-warning-light\">{{item.name}} - {{item.version}}</span>\n" +
    "                        <small class=\"text-muted\">{{item.createdAt | date:'medium'}}</small> by\n" +
    "                        <small class=\"text-muted\">{{item.createdByFullName}} </small>\n" +
    "                    </a>\n" +
    "                    <button ng-click=\"$ctrl.handleRemove($index)\" class=\"btn btn-xs btn-danger\">\n" +
    "                        <i class=\"fa fa-times\"></i>\n" +
    "                    </button>\n" +
    "                </li>\n" +
    "            </ul>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group no-margins\">\n" +
    "        <div class=\"col-lg-offset-2 col-md-10\">\n" +
    "            <a class=\"btn btn-primary\" ng-click=\"$ctrl.handleSave()\"\n" +
    "               ng-class=\"{'disabled': $ctrl.submitDisabled}\">\n" +
    "                <i class=\"fa fa-check-circle\"></i> Save\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/stars/components/filesUpload/starsFilesUploadBadgesPreviewView.html',
    "<div class=\"wrapper wrapper-content animated fadeInRight page-stars-badges-preview\">\n" +
    "    <div class=\"ibox\">\n" +
    "        <div class=\"ibox-title\">\n" +
    "            <h5>Please upload badges as zip archive</h5>\n" +
    "        </div>\n" +
    "        <div class=\"ibox-content\">\n" +
    "            <stars-badges-steps></stars-badges-steps>\n" +
    "            <stars-badges-preview></stars-badges-preview>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/stars/components/filesUpload/starsFilesUploadView.html',
    "<div class=\"wrapper wrapper-content animated fadeInRight page-stars-files-upload\">\n" +
    "\n" +
    "    <div class=\"ibox\">\n" +
    "        <div class=\"ibox-title form-group\">\n" +
    "            <h5> Please add Common Files\n" +
    "                <i class=\"fa fa-info-circle\"\n" +
    "                    uib-tooltip=\"Common files are distributed for all schools from Stars list\"\n" +
    "                    tooltip-placement=\"top\">\n" +
    "                </i>\n" +
    "            </h5>\n" +
    "        </div>\n" +
    "        <div class=\"ibox-content\">\n" +
    "            <stars-common-files></stars-common-files>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"ibox\">\n" +
    "        <div class=\"ibox-title\">\n" +
    "            <h5>Please upload badges as zip archive</h5>\n" +
    "        </div>\n" +
    "        <div class=\"ibox-content\">\n" +
    "            <stars-badges-steps></stars-badges-steps>\n" +
    "            <stars-badges-upload></stars-badges-upload>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <stars-badges-logs></stars-badges-logs>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/stars/components/institutionsList/starsInstitutionsListView.html',
    "<div  class=\"wrapper wrapper-content animated fadeInRight page-qs-stars-institutions-list\">\n" +
    "    <div class=\"row\">\n" +
    "        <div id=\"starsTable\" ng-class=\"StarsInstitutionsListController.isRightSidePanelActive() ? 'col-sm-2' : 'col-sm-12'\">\n" +
    "            <div class=\"section\">\n" +
    "                <!-- SECTION HEADER -->\n" +
    "                <div class=\"section-header\">\n" +
    "                    <stars-search-dropdown ng-show=\"!StarsInstitutionsListController.isRightSidePanelActive()\"></stars-search-dropdown>\n" +
    "                </div>\n" +
    "                <div class=\"section-body float-e-margins\">\n" +
    "                    <stars-datagrid selected-row-id=\"StarsInstitutionsListController.selectedItemId\"\n" +
    "                                    selected-item=\"StarsInstitutionsListController.selectedItem\"></stars-datagrid>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <stars-right-side-panel class=\"col-sm-10 right-side-container\" fixed-element-while-scrolling=\"#starsTable\"\n" +
    "            ng-if=\"StarsInstitutionsListController.isRightSidePanelActive()\"\n" +
    "            selected-item-id=\"StarsInstitutionsListController.selectedItemId\"\n" +
    "            selected-item=\"StarsInstitutionsListController.selectedItem\">\n" +
    "        </stars-right-side-panel>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/stars/components/publishToSite/publishToSiteLogs/publishToSiteLogsView.html',
    "<div class=\"ibox publish-logs\">\n" +
    "    <div class=\"ibox-title\">\n" +
    "        <h5>Publish Logs</h5>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"ibox-content\">\n" +
    "        <div wave-spinner ng-show=\"$ctrl.fetchingPublishLog()\"></div>\n" +
    "        <div ng-show=\"!$ctrl.fetchingPublishLog()\" ng-repeat=\"logs in $ctrl.publishLogs\">\n" +
    "            <div class=\"panel panel-default\" ng-repeat=\"logsPerDay in logs\">\n" +
    "                <div class=\"panel-heading\">\n" +
    "                    <span>{{logsPerDay.date | date:'mediumDate'}}</span>\n" +
    "                </div>\n" +
    "                <div class=\"panel-body\">\n" +
    "                    <ul class=\"list-unstyled\">\n" +
    "                        <li ng-repeat=\"log in logsPerDay.logs\">\n" +
    "                            <span class=\"status\">\n" +
    "                                <span class=\"icon text-success\" ng-if=\"log.status === 'success'\">\n" +
    "                                    <i class=\"fa fa-check\"></i>\n" +
    "                                </span>\n" +
    "                                <span class=\"icon text-danger\" ng-if=\"log.status === 'failure'\">\n" +
    "                                    <i class=\"fa fa-exclamation\"></i>\n" +
    "                                </span>\n" +
    "                                <span class=\"icon text-info\" ng-if=\"log.status === 'progress'\">\n" +
    "                                    <i class=\"fa fa-spinner fa-spin\"></i>\n" +
    "                                </span>\n" +
    "                                <span class=\"icon text-warning\" ng-if=\"log.status === 'pending'\">\n" +
    "                                    <i class=\"fa fa-clock-o\"></i>\n" +
    "                                </span>\n" +
    "                            </span>\n" +
    "                            \n" +
    "                            <span>{{$ctrl.getPublishStatusMapped(log.status)}} {{(log.type ===  'starsChina' ) ? 'to QSChina' : 'to TU'}}:</span>\n" +
    "                            <span class=\"bold\">{{log.createdByFullName}}</span>\n" +
    "                            <span class=\"time\">[{{log.createdAt | date:'shortTime'}}]</span>\n" +
    "\n" +
    "                            <span class=\"program\">\n" +
    "                                <span class=\"icon icon-star\">\n" +
    "                                    <i></i>\n" +
    "                                </span>\n" +
    "                            </span>\n" +
    "                        </li>\n" +
    "                    </ul>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div ng-show=\"!$ctrl.publishLogs.results.length\">\n" +
    "            No publish history\n" +
    "        </div>\n" +
    "        <div class=\"row\" ng-show=\"$ctrl.isMoreLogsAvailable()\">\n" +
    "            <div wave-spinner class=\"text-right\" ng-show=\"$ctrl.isLoadMoreHistoryLogsInProgress\"></div>\n" +
    "            <div class=\" col-lg-8 col-lg-offset-2\">\n" +
    "                <a class=\"btn btn-primary btn-block\" ng-click=\"$ctrl.handleLoadMoreHistoryLogs()\" ng-disabled=\"$ctrl.isLoadMoreHistoryLogsInProgress\">\n" +
    "                   Load more\n" +
    "                </a>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/stars/components/publishToSite/publishToSitePageView.html',
    "<div class=\"wrapper wrapper-content animated fadeInRight page-stars-publish-to-site\">\n" +
    "    <div class=\"row\">\n" +
    "        <div class=\"col-md-8\">\n" +
    "            <div class=\"section\">\n" +
    "                <div class=\"section-body publish\">\n" +
    "                    <publish-to-site></publish-to-site>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"col-md-4\">\n" +
    "            <publish-to-site-logs></publish-to-site-logs>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/stars/components/publishToSite/publishToSiteView.html',
    "<div class=\"row m-b\">\n" +
    "    <div class=\"col-md-12\">\n" +
    "        <div class=\"search-dropdown w-430 pull-left\">\n" +
    "            <ui-select\n" +
    "                name=\"institution\"\n" +
    "                ng-model=\"$ctrl.selectedInstitution\"\n" +
    "                theme=\"bootstrap\"\n" +
    "                ng-change=\"$ctrl.handleSearchInstitutionClick($ctrl.selectedInstitution)\">\n" +
    "                <ui-select-match placeholder=\"Institution search...\">\n" +
    "                    <i class=\"fa fa-building\"></i>\n" +
    "                    <span>{{$select.selected.name}}</span>\n" +
    "                </ui-select-match>\n" +
    "                <ui-select-choices\n" +
    "                    refresh-delay=\"500\"\n" +
    "                    refresh=\"$ctrl.searchInstitution($select.search)\"\n" +
    "                    position=\"down\"\n" +
    "                    repeat=\"option in $ctrl.institutionsList | filter: $select.search\">\n" +
    "                    <div class=\"test\" ng-bind-html=\"option.name | highlight: $select.search\"></div>\n" +
    "                </ui-select-choices>\n" +
    "            </ui-select>\n" +
    "            <div class=\"spinner\" ng-show=\"$ctrl.searchInProgress\" wave-spinner></div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "<div class=\"row\" ng-if=\"!$ctrl.isLoading && !$ctrl.isStarsSubscription\">\n" +
    "    <div class=\"col-md-12\">\n" +
    "        <div class=\"alert alert-info\">\n" +
    "            <p>\n" +
    "                <i class=\"fa fa-info-circle\"></i>\n" +
    "                <span>This university does not have a active Stars subscription.</span>\n" +
    "            </p>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"row\"  ng-class=\"{'modal-overlay-35':  $ctrl.isLoading || (!$ctrl.isLoading && !$ctrl.isStarsSubscription)}\">\n" +
    "    <div class=\"col-lg-4 meter\"\n" +
    "            ng-repeat=\"section in $ctrl.sections\"\n" +
    "            ng-class=\"{'modal-overlay-35': section.publishDisabled}\">\n" +
    "        <div class=\"panel panel-default section-{{section.type}}\">\n" +
    "            <div class=\"panel-heading\">\n" +
    "                <h5 class=\"pull-left\">\n" +
    "                    <span class=\"icon icon-star\">\n" +
    "                        <i></i>\n" +
    "                    </span>\n" +
    "                    <span>{{section.name}}</span>\n" +
    "                </h5>\n" +
    "                <a class=\"btn btn-primary btn-xs pull-right\"\n" +
    "                    ng-click=\"$ctrl.handlePublish(section)\">\n" +
    "                    <i class=\"fa fa-cloud-upload\"></i>\n" +
    "                    <span>Publish</span>\n" +
    "                </a>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"panel-body\">\n" +
    "                <div class=\"clearfix m-b-md\">\n" +
    "                    <a class=\"btn btn-default btn-sm btn-block\"\n" +
    "                        target=\"_blank\"\n" +
    "                        ng-href=\"{{section.url}}\"\n" +
    "                        ng-class=\"{'disabled': section.viewDisabled}\">\n" +
    "                        <i class=\"fa fa-eye\"></i>\n" +
    "                        <span>View webpage</span>\n" +
    "                    </a>\n" +
    "                </div>\n" +
    "\n" +
    "                <i ng-if=\"section.statusMessage\" class=\"fa fa-info-circle\"\n" +
    "                    uib-tooltip=\"{{section.statusMessage}}\"\n" +
    "                    tooltip-placement=\"top\">\n" +
    "                </i>\n" +
    "                <div class=\"response\" ng-if=\"section.statusMessage\">\n" +
    "                    <p><strong>Reason for Failed Publish:</strong></p>\n" +
    "                    <span class=\"block truncate\"><strong>{{section.statusMessage}}</strong></span>\n" +
    "                </div>\n" +
    "                <div class=\"p-c\" progress-circle=\"section.status\"></div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/stars/components/searchDropdown/starsSearchDropdownView.html',
    "<div class=\"search-dropdown pull-left\">\n" +
    "    <ui-select\n" +
    "        class=\"search-dropdown\"\n" +
    "        ng-model=\"$ctrl.starsSelectedInstitution\"\n" +
    "        theme=\"bootstrap\"\n" +
    "        ng-change=\"$ctrl.handleAddInstitution($ctrl.starsSelectedInstitution)\">\n" +
    "        <ui-select-match\n" +
    "            placeholder=\"Add Institution\">\n" +
    "            <i class=\"fa fa-building\"></i>\n" +
    "            <span>{{$select.selected.name}}</span>\n" +
    "        </ui-select-match>\n" +
    "        <ui-select-choices\n" +
    "            refresh-delay=\"500\"\n" +
    "            refresh=\"$ctrl.handleSearchInstitution($select.search)\"\n" +
    "            position=\"down\"\n" +
    "            minimum-input-length=\"1\"\n" +
    "            repeat=\"option in $ctrl.institutionsDropdownList | filter: $select.search\">\n" +
    "            <div class=\"test\" ng-bind-html=\"option.institutionName | highlight: $select.search\"></div>\n" +
    "        </ui-select-choices>\n" +
    "    </ui-select>\n" +
    "    <div class=\"spinner\" ng-show=\"$ctrl.searchInProgress\" wave-spinner></div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/modules/stars/components/starsCertificate/starsCertificateView.html',
    "<div class=\"form-group\">\n" +
    "    <div class=\"row general-tab\">\n" +
    "        <label class=\"col-lg-4 control-label\">\n" +
    "            <span class=\"clickable fa fa-info-circle\"\n" +
    "                  uib-tooltip=\"Only PDFs are accepted & up to {{ $ctrl.maxSize }}MB in size\"\n" +
    "                  tooltip-placement=\"top\">\n" +
    "            </span> Upload Certificate\n" +
    "        </label>\n" +
    "        <div class=\"col-lg-8\">\n" +
    "            <div class=\"clearfix\"\n" +
    "                  ng-class=\"{'modal-overlay-35': $ctrl.uplooadInProgress || $ctrl.isReadOnly}\"\n" +
    "                  ng-show=\"!$ctrl.selectedItem.answers[0].certificateUrl\"\n" +
    "                  name=\"$ctrl.forms.uploadForm\"\n" +
    "                  enctype=\"multipart/form-data\"\n" +
    "                  ng-dropzone\n" +
    "                  dropzone=\"$ctrl.dropZoneInstance\"\n" +
    "                  dropzone-config=\"$ctrl.config.dropzone\"\n" +
    "                  event-handlers=\"$ctrl.config.eventHandlers\"\n" +
    "                  novalidate\n" +
    "                  dz-hidden-input-file>\n" +
    "                <div class=\"fallback form-group\">\n" +
    "                    <input name=\"file\" type=\"file\"/>\n" +
    "                </div>\n" +
    "                <button class=\"btn btn-default btn-outline btn-sm dz-message\">\n" +
    "                    <i class=\"fa fa-upload\"></i> Upload\n" +
    "                </button>\n" +
    "            </div>\n" +
    "\n" +
    "            <ul ng-show=\"$ctrl.selectedItem.answers[0].certificateUrl\" class=\"list-inline list-border\">\n" +
    "                <li>\n" +
    "                    <a href=\"{{ $ctrl.selectedItem.answers[0].certificateUrl }}\" target=\"_blank\" class=\"no-text-transform inline\">\n" +
    "                        <small class=\"text-muted\">{{ $ctrl.fileName }}</small>\n" +
    "                    </a>\n" +
    "                    <button ng-click=\"$ctrl.handleRemove()\" class=\"btn btn-xs btn-danger\">\n" +
    "                        <i class=\"fa fa-times\"></i>\n" +
    "                    </button>\n" +
    "                </li>\n" +
    "            </ul>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/stars/components/starsDatagrid/institutionTypeCellTemplate.html',
    "<div class=\"ui-grid-cell-contents\">\n" +
    "    {{ COL_FIELD === \"businessSchool\" ? \"Business School\" : (COL_FIELD === \"university\" ? \"University\": COL_FIELD) }}\n" +
    "</div>'"
  );


  $templateCache.put('/scripts/modules/stars/components/starsDatagrid/ratingTotalCellTemplate.html',
    "<div class=\"ui-grid-cell-contents no-select text-center\">\n" +
    "    <span ng-if=\"row.entity.answers[0].totalStarsRating\"> {{COL_FIELD}} </span>\n" +
    "    <span ng-if=\"!row.entity.answers[0].totalStarsRating\"> Not Evaluated </span>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/stars/components/starsDatagrid/starsDatagridView.html',
    "<div wave-spinner class=\"absolute-wave-spinner\" ng-show=\"$ctrl.showLoadBar()\"></div>\n" +
    "\n" +
    "<div ng-class=\"{'modal-overlay-35': $ctrl.showLoadBar()}\">\n" +
    "    <ui-grid-info ng-show=\"$ctrl.showInfoBlock\"></ui-grid-info>\n" +
    "\n" +
    "    <div class=\"grid\"\n" +
    "         ui-grid=\"$ctrl.gridOptions\"\n" +
    "         ui-grid-selection\n" +
    "         ui-grid-resize-columns\n" +
    "         ui-grid-auto-resize\n" +
    "         ui-grid-cellnav\n" +
    "         ui-grid-exporter\n" +
    "         ui-grid-pagination></div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/stars/components/starsDatagrid/versionCellTemplate.html',
    "<div class=\"ui-grid-cell-contents\">\n" +
    "    {{ COL_FIELD === 4.2 ? \"v4.2\" : (COL_FIELD === 5 ? \"v5.0\": COL_FIELD) }}\n" +
    "</div>'"
  );


  $templateCache.put('/scripts/modules/stars/components/starsDevelopmentRoadmap/starsDevelopmentRoadmapView.html',
    "<div class=\"form-group\">\n" +
    "    <div class=\"row general-tab\">\n" +
    "        <label class=\"col-lg-4 control-label\">\n" +
    "            <span class=\"clickable fa fa-info-circle\"\n" +
    "                  uib-tooltip=\"Only PDFs are accepted & up to {{ $ctrl.maxSize }}MB in size\"\n" +
    "                  tooltip-placement=\"top\">\n" +
    "            </span> Upload Development Roadmap\n" +
    "        </label>\n" +
    "        <div class=\"col-lg-8\">\n" +
    "            <div class=\"clearfix\"\n" +
    "                  ng-class=\"{'modal-overlay-35': $ctrl.uplooadInProgress || $ctrl.isReadOnly}\"\n" +
    "                  ng-show=\"!$ctrl.selectedItem.answers[0].developmentRoadmapUrl\"\n" +
    "                  name=\"$ctrl.forms.uploadForm\"\n" +
    "                  enctype=\"multipart/form-data\"\n" +
    "                  ng-dropzone\n" +
    "                  dropzone=\"$ctrl.dropZoneInstance\"\n" +
    "                  dropzone-config=\"$ctrl.config.dropzone\"\n" +
    "                  event-handlers=\"$ctrl.config.eventHandlers\"\n" +
    "                  novalidate\n" +
    "                  dz-hidden-input-file>\n" +
    "                <div class=\"fallback form-group\">\n" +
    "                    <input name=\"file\" type=\"file\"/>\n" +
    "                </div>\n" +
    "\n" +
    "                <button class=\"btn btn-default btn-outline btn-sm dz-message\">\n" +
    "                    <i class=\"fa fa-upload\"></i> Upload\n" +
    "                </button>\n" +
    "            </div>\n" +
    "\n" +
    "            <ul ng-show=\"$ctrl.selectedItem.answers[0].developmentRoadmapUrl\" class=\"list-inline list-border\">\n" +
    "                <li>\n" +
    "                    <a href=\"{{ $ctrl.selectedItem.answers[0].developmentRoadmapUrl }}\" target=\"_blank\" class=\"no-text-transform inline\">\n" +
    "                        <small class=\"text-muted\">{{ $ctrl.fileName }} </small>\n" +
    "                    </a>\n" +
    "                    <button ng-click=\"$ctrl.handleRemove()\" class=\"btn btn-xs btn-danger\">\n" +
    "                        <i class=\"fa fa-times\"></i>\n" +
    "                    </button>\n" +
    "                </li>\n" +
    "            </ul>\n" +
    "\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/stars/components/starsEdit/components/scoresPerCategory/scoresPerCategoryView.html',
    "<div class=\"row\" ng-repeat=\"item in $ctrl.indicators\" ng-class=\"{'dashed-border': item.name === 'total'}\">\n" +
    "    <div class=\"col-lg-10 no-padding scores-per-category client\" ng-class=\"$ctrl.isClientSection() ? 'col-sm-12' : 'col-sm-10'\">\n" +
    "        <div class=\"row\">\n" +
    "            <div class=\"col-lg-4 col-sm-4\" ng-class=\"{'text-capitalize font-bold': item.name === 'total'}\">{{item.name}}<span ng-if=\"item.name === 'total'\">:</span></div>\n" +
    "            <div ng-if=\"item.name !== 'total' && !item.primarySelect && !item.primaryInput\"\n" +
    "                 class=\"col-lg-2 col-sm-2 form-group score\"\n" +
    "                 ng-class=\"{'has-errors': !$ctrl.isFieldValid(item.handle),\n" +
    "                            'client-empty': $ctrl.isEmptyValue($ctrl.selectedItem.answers[0].scores[$ctrl.category][item.handle].value)}\">\n" +
    "                <input type=\"number\"\n" +
    "                       ng-model=\"$ctrl.selectedItem.answers[0].scores[$ctrl.category][item.handle].value\"\n" +
    "                       ng-disabled=\"(item.children && !item.notDependant) || item.checkboxList\"\n" +
    "                       ng-readonly=\"$ctrl.isReadOnly\"\n" +
    "                       min=\"0\"\n" +
    "                       onkeypress=\"return event.charCode != 45\"\n" +
    "                       onpaste=\"return false\"\n" +
    "                       ng-keyup=\"$ctrl.validateCategory(item.handle, $ctrl.selectedItem.answers[0].scores[$ctrl.category][item.handle], item.maxPoints, item.notDependant, $event)\"\n" +
    "                       class=\"form-control\"\n" +
    "\n" +
    "                       custom-popover\n" +
    "                       popover-html=\"Value can't be decimal, invalid or larger than {{item.maxPoints}}\"\n" +
    "                       popover-placement=\"left\"\n" +
    "                       popover-trigger=\"manual\"\n" +
    "                       popover-visibility=\"{{!$ctrl.isFieldValid(item.handle) ? true : false}}\">\n" +
    "            </div>\n" +
    "            <div ng-if=\"item.primarySelect\" \n" +
    "                 class=\"col-lg-3 col-sm-3 form-group\"\n" +
    "                 ng-class=\"{'client-empty': $ctrl.isEmptyFreeText($ctrl.selectedItem.answers[0].scores[$ctrl.category][item.handle].dropdownValue)}\">\n" +
    "                <ui-select\n" +
    "                        placeholder=\"Select an option\"\n" +
    "                        ng-model=\"$ctrl.selectedItem.answers[0].scores[$ctrl.category][item.handle].dropdownValue\"\n" +
    "                        ng-disabled=\"$ctrl.isReadOnly && !$ctrl.selectedItem.isClient ||\n" +
    "                                     $ctrl.selectedItem.isSubjectDisabled[item.handle]\"\n" +
    "                        ng-change=\"$ctrl.subjectsState($ctrl.category, item.handle)\"\n" +
    "                        theme=\"bootstrap\"\n" +
    "                        ng-class=\"{'hide-close-icon': !$select.selected.label}\">\n" +
    "                    <ui-select-match allow-clear=\"true\" placeholder=\"Choose subject\">\n" +
    "                        <span ng-show=\"$select.selected.label\">{{$select.selected.label}}</span>\n" +
    "                        <span ng-show=\"!$select.selected.label\" class=\"text-muted\">Choose subject</span>\n" +
    "                    </ui-select-match>\n" +
    "                    <ui-select-choices\n" +
    "                            position=\"down\"\n" +
    "                            repeat=\"option.value as option in $ctrl.subjectsLists[item.typeList] | filter:$select.search | orderBy:'label'\">\n" +
    "                        <div ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                    </ui-select-choices>\n" +
    "                </ui-select>\n" +
    "            </div>\n" +
    "            <div ng-if=\"item.primaryInput\"\n" +
    "                 class=\"col-lg-3 col-sm-3 form-group\"\n" +
    "                 ng-class=\"{'client-empty': $ctrl.isEmptyFreeText($ctrl.selectedItem.answers[0].scores[$ctrl.category][item.handle].value)}\">\n" +
    "                <input type=\"text\"\n" +
    "                       ng-readonly=\"$ctrl.isReadOnly\"\n" +
    "                       ng-model=\"$ctrl.selectedItem.answers[0].scores[$ctrl.category][item.handle].value\"\n" +
    "                       class=\"form-control\">\n" +
    "            </div>\n" +
    "            <div ng-if=\"item.name === 'total'\" class=\"col-lg-3 col-sm-3\">\n" +
    "                <span class=\"pull-right total font-bold\">\n" +
    "                        {{$ctrl.calculateCurrentTotal($ctrl.selectedItem.answers[0].scores[$ctrl.category])}} / {{item.points}}\n" +
    "                </span>\n" +
    "            </div>\n" +
    "            <div ng-if=\"item.name !== 'total' && !item.primarySelect && !item.primaryInput\" class=\"col-lg-1 col-sm-1\" ng-class=\"{'no-padding' : !$ctrl.isClientSection(), 'p-r-none' : $ctrl.isClientSection() }\">\n" +
    "                <span class=\"\">\n" +
    "                    / {{item.maxPoints}}\n" +
    "                </span>\n" +
    "            </div>\n" +
    "            <div class=\"col-lg-5 col-sm-5\">\n" +
    "                <ui-select\n" +
    "                        ng-if=\"item.selectOptions\"\n" +
    "                        placeholder=\"Select an option\"\n" +
    "                        ng-model=\"$ctrl.selectedItem.answers[0].scores[$ctrl.category][item.handle].dropdownValue\"\n" +
    "                        ng-disabled=\"$ctrl.isReadOnly\"\n" +
    "                        ng-change=\"$ctrl.handleSelectOptionsChange($ctrl.category, item, $select.selected)\"\n" +
    "                        theme=\"bootstrap\">\n" +
    "                    <ui-select-match placeholder=\"Choose indicator\">\n" +
    "                        <span ng-show=\"$select.selected.name\">{{$select.selected.name}}</span>\n" +
    "                        <span ng-show=\"!$select.selected.name\" class=\"text-muted\">Choose indicator</span>\n" +
    "                    </ui-select-match>\n" +
    "                    <ui-select-choices\n" +
    "                            position=\"down\"\n" +
    "                            repeat=\"option.handle as option in item.selectOptions | filter: $select.search\">\n" +
    "                        <div class=\"test\" ng-bind-html=\"option.name | highlight: $select.search\"></div>\n" +
    "                    </ui-select-choices>\n" +
    "                </ui-select>\n" +
    "                <div ng-if=\"item.children\">\n" +
    "                    <div class=\"row\" ng-repeat=\"child in item.children\">\n" +
    "                        <div class=\"col-lg-7 col-sm-6 p-w-xxs\">{{child.name}}</div>\n" +
    "                        <div class=\"col-lg-3 col-sm-3 p-w-xxs form-group child-score\"\n" +
    "                             ng-class=\"{'has-errors': !$ctrl.isFieldValid(child.handle),\n" +
    "                                        'client-empty': $ctrl.isEmptyValue($ctrl.selectedItem.answers[0].scores[$ctrl.category][item.handle].children[child.handle])}\">\n" +
    "                            <input type=\"number\"\n" +
    "                                   ng-readonly=\"($ctrl.isReadOnly && !$ctrl.selectedItem.isClient) || $ctrl.isDependantChildren[child.handle]\"\n" +
    "                                   min=\"0\"\n" +
    "                                   onkeypress=\"return event.charCode != 45\"\n" +
    "                                   onpaste=\"return false\"\n" +
    "                                   ng-model=\"$ctrl.selectedItem.answers[0].scores[$ctrl.category][item.handle].children[child.handle]\"\n" +
    "                                   ng-keyup=\"$ctrl.validateCategoryChildren(item, $ctrl.selectedItem.answers[0].scores[$ctrl.category][item.handle], child.handle, item.maxPoints, true)\"\n" +
    "                                   class=\"form-control\"/>\n" +
    "                            <div class=\"popover animated fadeInDown fade left in\" ng-class=\"!$ctrl.isFieldValid(child.handle) ? 'display-validation':'hide-validation'\">\n" +
    "                                <div class=\"popover-content\">{{$ctrl.childErrorMessages[item.handle+child.handle]}}</div>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                        <div class=\"col-lg-2 col-sm-3 p-w-xxs\">\n" +
    "                            / {{child.maxPoints}}\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "                <div ng-if=\"item.checkboxList\" ng-class=\"{'modal-overlay-35': $ctrl.isReadOnly && !$ctrl.selectedItem.isClient}\">\n" +
    "                    <div class=\"row\"  ng-repeat=\"checkBox in item.checkboxList\">\n" +
    "                        <span ng-class=\"{'modal-overlay-35': $ctrl.checkBoxDisabled[checkBox.handle]}\"\n" +
    "                              ng-disabled=\"$ctrl.isCheckboxDisabled(item, item.checkboxList, $ctrl.selectedItem.answers[0].scores[$ctrl.category][item.handle],checkBox.handle)\">\n" +
    "                            <input ng-model=\"$ctrl.selectedItem.answers[0].scores[$ctrl.category][item.handle].checkboxList[checkBox.handle].assigned\"\n" +
    "                                i-checkbox=\"{{checkBox.assign}}\"\n" +
    "                                ng-change=\"$ctrl.validateCategoryCheckboxes(item, item.checkboxList, $ctrl.selectedItem.answers[0].scores[$ctrl.category][item.handle], checkBox.handle)\"\n" +
    "                                type=\"checkbox\" />\n" +
    "                            {{checkBox.name}} {{$ctrl.selectedItem.answers[0].scores[$ctrl.category][item.handle].checkboxList[checkBox.handle].assigned ? $ctrl.getCheckBoxPoints(item) : '0'}}/{{$ctrl.getCheckBoxPoints(item)}}\n" +
    "                        </span>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <div class=\"col-lg-2 col-sm-2 form-group scores-per-category number-per-indicator\"\n" +
    "    ng-class=\"{'has-errors': !$ctrl.isNumberValid(item.handle + '_number')}\">\n" +
    "        <input ng-if=\"item.name !== 'total' &&\n" +
    "                      item.typeList !=='subjects' &&\n" +
    "                      item.valueType !== 'freeText' &&\n" +
    "                      !item.primarySelect &&\n" +
    "                      !item.checkboxList\"\n" +
    "               type=\"number\"\n" +
    "               name=\"{{item.handle}}-number\"\n" +
    "               ng-readonly=\"$ctrl.isReadOnly\"\n" +
    "               ng-model=\"$ctrl.selectedItem.answers[0].scores[$ctrl.category][item.handle].number\"\n" +
    "               min=\"0\"\n" +
    "               onkeypress=\"return event.charCode != 45\"\n" +
    "               onpaste=\"return false\"\n" +
    "               ng-keyup=\"$ctrl.validateNumber(item.handle + '_number', $ctrl.selectedItem.answers[0].scores[$ctrl.category][item.handle].number)\"\n" +
    "               class=\"form-control wi-65\"\n" +
    "\n" +
    "               custom-popover\n" +
    "               popover-html=\"Please enter valid value\"\n" +
    "               popover-placement=\"left\"\n" +
    "               popover-trigger=\"manual\"\n" +
    "               popover-visibility=\"{{!$ctrl.isNumberValid(item.handle + '_number')}}\">\n" +
    "    </div>\n" +
    "</div>"
  );


  $templateCache.put('/scripts/modules/stars/components/starsEdit/components/starsPerCategory/starsPerCategoryView.html',
    "<div ng-class=\"{'client-empty': $ctrl.isEmptyValue($ctrl.selectedItem.answers[0].starsPerCategory[$ctrl.category === 'online_distance_learning' ? $ctrl.onlineCategory : $ctrl.category])}\">\n" +
    "    <ui-select\n" +
    "        class=\"stars-dropdown\"\n" +
    "        placeholder=\"Select an option\"\n" +
    "        ng-model=\"$ctrl.selectedItem.answers[0].starsPerCategory[$ctrl.category === 'online_distance_learning' && $ctrl.isClientSection() ? $ctrl.onlineCategory : $ctrl.category]\"\n" +
    "        ng-disabled=\"$ctrl.isReadOnly && !$ctrl.selectedItem.isClient ||\n" +
    "                     $ctrl.selectedItem.isRatingDisabled[$ctrl.category]\"\n" +
    "        theme=\"bootstrap\"\n" +
    "        ng-change=\"$ctrl.ratingState($ctrl.category)\">\n" +
    "        <ui-select-match allow-clear=\"true\" placeholder=\"Select an option\">\n" +
    "            <span ng-bind-html=\"$select.selected.label\">{{$select.selected.label}}</span>\n" +
    "        </ui-select-match>\n" +
    "        <ui-select-choices\n" +
    "          position=\"down\"\n" +
    "          repeat=\"option.value as option in $ctrl.starsRatingList | filter: $select.search\">\n" +
    "          <div class=\"test\" ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "        </ui-select-choices>\n" +
    "    </ui-select>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/stars/components/starsEdit/components/starsSections/starsSectionsView.html',
    "<uib-accordion class=\"accordion-with-arrows\" close-others=\"false\">\n" +
    "    <div uib-accordion-group class=\"panel-default\"\n" +
    "         ng-repeat=\"item in $ctrl.sections\"\n" +
    "         heading=\"{{item.name}}\"\n" +
    "         is-disabled=\"item.hide\"\n" +
    "         is-open=\"item.open\">\n" +
    "        <div ng-if=\"item.template\" ng-include src=\"'/scripts/modules/stars/components/starsEdit/sections/' + item.template\"></div>\n" +
    "    </div>\n" +
    "</uib-accordion>\n"
  );


  $templateCache.put('/scripts/modules/stars/components/starsEdit/sections/starsAdvancedCriteriaView.html',
    "<div ng-class=\"col-sm-12\">\n" +
    "    <div class=\"section\">\n" +
    "        <span class=\"top-lbl\">Submit data for two of the categories</span> \n" +
    "        <div class=\"row\">\n" +
    "            <div class=\"col-lg-6\">\n" +
    "                <switch ng-model=\"$ctrl.selectedItem.answers[0]['scores']['arts_and_culture'].show\" class=\"green\"></switch>\n" +
    "                Arts & Culture\n" +
    "            </div>\n" +
    "            <div class=\"col-lg-6\">\n" +
    "                <switch ng-model=\"$ctrl.selectedItem.answers[0]['scores']['social_responsibility'].show\" class=\"green\"></switch>\n" +
    "                Social Responsibility\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"row\">\n" +
    "            <div class=\"col-lg-6\">\n" +
    "                <switch ng-model=\"$ctrl.selectedItem.answers[0]['scores']['innovation'].show\" class=\"green\"></switch>\n" +
    "                Innovation\n" +
    "            </div>\n" +
    "            <div class=\"col-lg-6\">\n" +
    "                <switch ng-model=\"$ctrl.selectedItem.answers[0]['scores']['inclusiveness'].show\" class=\"green\"></switch>\n" +
    "                Inclusiveness\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <br>\n" +
    "        <div ng-repeat=\"item in $ctrl.categories['advancedCriteria']\">\n" +
    "            <div class=\"section-header\" ng-show=\"$ctrl.selectedItem.answers[0]['scores'][item.handle].show\">\n" +
    "                <div class=\"row border-btm\">\n" +
    "                    <div class=\"col-lg-10 no-padding\"><h4>{{item.name}}</h4></div>\n" +
    "                    <div class=\"col-lg-2 number-per-indicator\"><h4 ng-show=\"$first\">&nbsp;</h4></div>\n" +
    "                </div>\n" +
    "                <div class=\"row\">\n" +
    "                    <div class=\"col-lg-10 no-padding\">\n" +
    "                        <div class=\"row m-t\">\n" +
    "                            <div class=\"col-lg-3 col-sm-4\">QS Stars category rating</div>\n" +
    "                            <div class=\"col-lg-4 col-sm-4\">\n" +
    "                                <stars-per-category selected-item=\"$ctrl.selectedItem\"\n" +
    "                                                    is-read-only=\"$ctrl.isReadOnly\"\n" +
    "                                                    category=\"item.handle\"></stars-per-category>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-lg-2\">\n" +
    "\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"section-body\" ng-show=\"$ctrl.selectedItem.answers[0]['scores'][item.handle].show\">\n" +
    "                <scores-per-category selected-item=\"$ctrl.selectedItem\"\n" +
    "                                     is-read-only=\"$ctrl.isReadOnly\"\n" +
    "                                     indicators=\"item.indicators\"\n" +
    "                                     category=\"item.handle\"></scores-per-category>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/stars/components/starsEdit/sections/starsCoreCriteriaView.html',
    "<div ng-class=\"col-sm-12\">\n" +
    "    <div class=\"section\">\n" +
    "        <div ng-repeat=\"item in $ctrl.categories['coreCriteria']\">\n" +
    "            <div class=\"section-header\">\n" +
    "                <div class=\"row border-btm\">\n" +
    "                    <div class=\"col-lg-10 col-sm-10 no-padding\"><h4>{{item.name}}</h4></div>\n" +
    "                    <div class=\"col-lg-2 col-sm-2 number-per-indicator\"><h4 ng-show=\"$first\">Numbers per indicator</h4></div>\n" +
    "                </div>\n" +
    "                <div class=\"row\">\n" +
    "                    <div class=\"col-lg-10 no-padding\">\n" +
    "                        <div class=\"row m-t\">\n" +
    "                            <div class=\"col-lg-3 col-sm-4\">QS Stars category rating</div>\n" +
    "                            <div class=\"col-lg-4 col-sm-4\">\n" +
    "                                <stars-per-category selected-item=\"$ctrl.selectedItem\"\n" +
    "                                                    is-read-only=\"$ctrl.isReadOnly\"\n" +
    "                                                    category=\"item.handle\"></stars-per-category>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"section-body\">\n" +
    "                <scores-per-category selected-item=\"$ctrl.selectedItem\"\n" +
    "                                     is-read-only=\"$ctrl.isReadOnly\"\n" +
    "                                     indicators=\"item.indicators\"\n" +
    "                                     category=\"item.handle\"></scores-per-category>\n" +
    "            </div>\n" +
    "\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/stars/components/starsEdit/sections/starsLearnEnvironmentView.html',
    "<div ng-class=\"col-sm-12\">\n" +
    "    <div class=\"section\">\n" +
    "       <span class=\"top-lbl\">Submit data for one of the categories</span> \n" +
    "        <div class=\"row\">\n" +
    "            <div class=\"col-lg-6\">\n" +
    "                <switch ng-model=\"$ctrl.selectedItem.answers[0]['scores']['facilities'].show\" class=\"green\"></switch>\n" +
    "                Facilities\n" +
    "            </div>\n" +
    "            <div class=\"col-lg-6\">\n" +
    "                <switch ng-model=\"$ctrl.selectedItem.answers[0]['scores']['online_distance_learning'].show\" class=\"green\"></switch>\n" +
    "                Online<span ng-show=\"$ctrl.getVersion() !== '5.0'\">/Distance</span> Learning\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <br />\n" +
    "        <div ng-repeat=\"item in $ctrl.categories['learningEnvironment']\">\n" +
    "            <div class=\"section-header\" ng-show=\"$ctrl.selectedItem.answers[0]['scores'][item.handle].show\">\n" +
    "                <div class=\"row border-btm\">\n" +
    "                    <div class=\"col-lg-10 col-sm-10 no-padding\"><h4>{{item.name}}</h4></div>\n" +
    "                    <div class=\"col-lg-2 col-sm-2 number-per-indicator\"><h4 ng-show=\"$first\">Numbers per indicator</h4></div>\n" +
    "                </div>\n" +
    "                <div class=\"row\">\n" +
    "                    <div class=\"col-lg-10 no-padding\">\n" +
    "                        <div class=\"row m-t\">\n" +
    "                            <div class=\"col-lg-3 col-sm-4\">QS Stars category rating</div>\n" +
    "                            <div class=\"col-lg-4 col-sm-4\">\n" +
    "                                <stars-per-category selected-item=\"$ctrl.selectedItem\"\n" +
    "                                                    is-read-only=\"$ctrl.isReadOnly\"\n" +
    "                                                    category=\"item.handle\"></stars-per-category>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-lg-2\">\n" +
    "\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"section-body\" ng-show=\"$ctrl.selectedItem.answers[0]['scores'][item.handle].show\">\n" +
    "                <scores-per-category selected-item=\"$ctrl.selectedItem\"\n" +
    "                                     is-read-only=\"$ctrl.isReadOnly\"\n" +
    "                                     indicators=\"item.indicators\"\n" +
    "                                     category=\"item.handle\"></scores-per-category>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/stars/components/starsEdit/sections/starsOutputCriteriaBusinessView.html',
    "<div ng-class=\"col-sm-12\">\n" +
    "    <div class=\"section\">\n" +
    "        <span class=\"top-lbl\">\n" +
    "            Choose three of the sections to fill the data\n" +
    "        </span>\n" +
    "        <div class=\"row\">\n" +
    "            <div class=\"col-lg-6\">\n" +
    "                <switch ng-model=\"$ctrl.selectedItem.answers[0]['scores']['employability'].show\" class=\"green\"></switch>\n" +
    "                Employability\n" +
    "            </div>\n" +
    "            <div class=\"col-lg-6\">\n" +
    "                <switch ng-model=\"$ctrl.selectedItem.answers[0]['scores']['research'].show\" class=\"green\"></switch>\n" +
    "                Research\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"row\">\n" +
    "            <div class=\"col-lg-6\">\n" +
    "                <switch ng-model=\"$ctrl.selectedItem.answers[0]['scores']['innovation_and_entrepreneurship'].show\" class=\"green\"></switch>\n" +
    "                Innovation & Entrepreneurship\n" +
    "            </div>\n" +
    "            <div class=\"col-lg-6\">\n" +
    "                <switch ng-model=\"$ctrl.selectedItem.answers[0]['scores']['engagement'].show\" class=\"green\"></switch>\n" +
    "                Engagement\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <br>\n" +
    "        <div ng-repeat=\"item in $ctrl.categories['businessOutputCriteria']\"\n" +
    "             ng-show=\"$ctrl.selectedItem.answers[0]['scores'][item.handle].show\">\n" +
    "            <div class=\"section-header\">\n" +
    "                <div class=\"row\">\n" +
    "                    <div class=\"col-lg-10 col-sm-10 no-padding\"><h4>{{item.name}}</h4></div>\n" +
    "                    <div class=\"col-lg-2 col-sm-2 number-per-indicator\"><h4 ng-show=\"$first\">Numbers per indicator</h4></div>\n" +
    "                </div>\n" +
    "                <div class=\"row\">\n" +
    "                    <div class=\"col-lg-10 no-padding\">\n" +
    "                        <div class=\"row\">\n" +
    "                            <div class=\"col-lg-3 col-sm-4\">QS Stars category rating</div>\n" +
    "                            <div class=\"col-lg-4 col-sm-4\">\n" +
    "                                <stars-per-category selected-item=\"$ctrl.selectedItem\"\n" +
    "                                                    is-read-only=\"$ctrl.isReadOnly\"\n" +
    "                                                    category=\"item.handle\"></stars-per-category>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-lg-2\">\n" +
    "\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"section-body\">\n" +
    "                <scores-per-category selected-item=\"$ctrl.selectedItem\"\n" +
    "                                     is-read-only=\"$ctrl.isReadOnly\"\n" +
    "                                     indicators=\"item.indicators\"\n" +
    "                                     category=\"item.handle\"></scores-per-category>\n" +
    "            </div>\n" +
    "\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/stars/components/starsEdit/sections/starsSchoolCriteriaBusinessView.html',
    "<div ng-class=\"col-sm-12\">\n" +
    "    <div class=\"section\">\n" +
    "        <div ng-repeat=\"item in $ctrl.categories['businessSchoolCriteria']\">\n" +
    "            <div class=\"section-header\">\n" +
    "                <div class=\"row\">\n" +
    "                    <div class=\"col-lg-10 col-sm-10 no-padding\"><h4>{{item.name}}</h4></div>\n" +
    "                    <div class=\"col-lg-2 col-sm-2 number-per-indicator\"><h4 ng-show=\"$first\">Numbers per indicator</h4></div>\n" +
    "                </div>\n" +
    "                <div class=\"row\">\n" +
    "                    <div class=\"col-lg-10 no-padding\">\n" +
    "                        <div class=\"row\">\n" +
    "                            <div class=\"col-lg-3 col-sm-4\">QS Stars category rating</div>\n" +
    "                            <div class=\"col-lg-4 col-sm-4\">\n" +
    "                                <stars-per-category selected-item=\"$ctrl.selectedItem\"\n" +
    "                                                    is-read-only=\"$ctrl.isReadOnly\"\n" +
    "                                                    category=\"item.handle\"></stars-per-category>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-lg-2\">\n" +
    "\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"section-body\">\n" +
    "                <scores-per-category selected-item=\"$ctrl.selectedItem\"\n" +
    "                                     is-read-only=\"$ctrl.isReadOnly\"\n" +
    "                                     indicators=\"item.indicators\"\n" +
    "                                     category=\"item.handle\"></scores-per-category>\n" +
    "            </div>\n" +
    "\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/stars/components/starsEdit/sections/starsSpecialistCriteriaBusinessView.html',
    "<div ng-class=\"col-sm-12\">\n" +
    "    <div class=\"section\">\n" +
    "        <div class=\"row\">\n" +
    "            <div class=\"col-lg-6\">\n" +
    "                <switch ng-model=\"$ctrl.selectedItem.answers[0]['scores']['rankings_and_public_evaluations'].show\" class=\"green\"></switch>\n" +
    "                Rankings & Public Evaluations\n" +
    "            </div>\n" +
    "            <div class=\"col-lg-6\">\n" +
    "                <switch ng-model=\"$ctrl.selectedItem.answers[0]['scores']['program_strength'].show\" class=\"green\"></switch>\n" +
    "                Program Strength\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <br />\n" +
    "        <div ng-repeat=\"item in $ctrl.categories['businessSpecialistCriteria']\"\n" +
    "             ng-show=\"$ctrl.selectedItem.answers[0]['scores'][item.handle].show\">\n" +
    "            <div class=\"section-header\">\n" +
    "                <div class=\"row\">\n" +
    "                    <div class=\"col-lg-10 no-padding\"><h4>{{item.name}}</h4></div>\n" +
    "                    <div class=\"col-lg-2 number-per-indicator\"><h4 ng-show=\"$first\">&nbsp;</h4></div>\n" +
    "                </div>\n" +
    "                <div class=\"row\">\n" +
    "                    <div class=\"col-lg-10 no-padding\">\n" +
    "                        <div class=\"row\">\n" +
    "                            <div class=\"col-lg-3 col-sm-4\">QS Stars category rating</div>\n" +
    "                            <div class=\"col-lg-4 col-sm-4\">\n" +
    "                                <stars-per-category selected-item=\"$ctrl.selectedItem\"\n" +
    "                                                    is-read-only=\"$ctrl.isReadOnly\"\n" +
    "                                                    category=\"item.handle\"></stars-per-category>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-lg-2\">\n" +
    "\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"section-body\">\n" +
    "                <scores-per-category selected-item=\"$ctrl.selectedItem\"\n" +
    "                                     is-read-only=\"$ctrl.isReadOnly\"\n" +
    "                                     indicators=\"item.indicators\"\n" +
    "                                     category=\"item.handle\"></scores-per-category>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/stars/components/starsEdit/sections/starsSpecialistCriteriaView.html',
    "<div ng-class=\"col-sm-12\">\n" +
    "    <div class=\"section\">\n" +
    "        <span class=\"top-lbl\">Submit data for one of the categories</span> \n" +
    "        <div class=\"row\">\n" +
    "            <div class=\"col-lg-6\">\n" +
    "                <switch ng-model=\"$ctrl.selectedItem.answers[0]['scores']['subject_rankings'].show\" class=\"green\"></switch>\n" +
    "                Subject Rankings\n" +
    "            </div>\n" +
    "            <div class=\"col-lg-6\">\n" +
    "                <switch ng-model=\"$ctrl.selectedItem.answers[0]['scores']['program_strength'].show\" class=\"green\"></switch>\n" +
    "                Program Strength\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <br>\n" +
    "        <div ng-repeat=\"item in $ctrl.categories['specialistCriteria']\">\n" +
    "            <div class=\"section-header\" ng-show=\"$ctrl.selectedItem.answers[0]['scores'][item.handle].show\">\n" +
    "                <div class=\"row border-btm\">\n" +
    "                    <div class=\"col-lg-10 no-padding\"><h4>{{item.name}}</h4></div>\n" +
    "                    <div class=\"col-lg-2 number-per-indicator\"><h4 ng-show=\"$first\">&nbsp;</h4></div>\n" +
    "                </div>\n" +
    "                <div class=\"row\">\n" +
    "                    <div class=\"col-lg-10 no-padding\">\n" +
    "                        <div class=\"row m-t\">\n" +
    "                            <div class=\"col-lg-3 col-sm-4\">QS Stars category rating</div>\n" +
    "                            <div class=\"col-lg-4 col-sm-4\">\n" +
    "                                <stars-per-category selected-item=\"$ctrl.selectedItem\"\n" +
    "                                                    is-read-only=\"$ctrl.isReadOnly\"\n" +
    "                                                    category=\"item.handle\"></stars-per-category>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                    <div class=\"col-lg-2\">\n" +
    "\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"section-body\" ng-show=\"$ctrl.selectedItem.answers[0]['scores'][item.handle].show\">\n" +
    "                <scores-per-category selected-item=\"$ctrl.selectedItem\"\n" +
    "                                     is-read-only=\"$ctrl.isReadOnly\"\n" +
    "                                     indicators=\"item.indicators\"\n" +
    "                                     category=\"item.handle\"></scores-per-category>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/stars/components/starsEdit/sections/upload.html',
    "<stars-certificate selected-item=\"$ctrl.selectedItem\"></stars-certificate>\n" +
    "<stars-development-roadmap selected-item=\"$ctrl.selectedItem\"></stars-development-roadmap>\n" +
    "<stars-special-badges selected-item=\"$ctrl.selectedItem\"></stars-special-badges>\n"
  );


  $templateCache.put('/scripts/modules/stars/components/starsEdit/starsEditView.html',
    "<div wave-spinner class=\"wave-spinner\" ng-show=\"$ctrl.isLoading\"></div>\n" +
    "<div ng-class=\"{'modal-overlay-35': $ctrl.isLoading}\">\n" +
    "    <h2>\n" +
    "        {{ $ctrl.selectedItem.institutionName }}\n" +
    "        <button class=\"btn btn-default btn-xs pull-right m-t-xs\"\n" +
    "           ng-click=\"$ctrl.handleViewAsClient()\">\n" +
    "            <i class=\"fa fa-sign-in\"></i>\n" +
    "            <span>View as client</span>\n" +
    "        </button>\n" +
    "    </h2>\n" +
    "    <div class=\"form-group no-margins\">\n" +
    "        <div class=\"row\">\n" +
    "            <label class=\"control-label col-sm-5 col-md-4\">\n" +
    "                Core ID:\n" +
    "            </label>\n" +
    "            <div class=\"col-sm-7 col-md-8 word-break-all\">\n" +
    "                {{ $ctrl.selectedItem.institutionCoreId }}\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"row\">\n" +
    "            <label class=\"control-label col-sm-5 col-md-4\">\n" +
    "                Star Rating:\n" +
    "            </label>\n" +
    "            <div class=\"col-sm-7 col-md-8 word-break-all\">\n" +
    "                {{$ctrl.selectedItem.answers[0].totalStarsRating ?  $ctrl.calculateStarRating() +'/1000' : 'Not Evaluated'}}\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div class=\"row\" ng-if=\"!$ctrl.hasSubscription()\">\n" +
    "            <label class=\"control-label col-sm-5 col-md-4\">\n" +
    "                Choose by audit validity date:\n" +
    "            </label>\n" +
    "            <div class=\"col-sm-7 col-md-8 word-break-al\">\n" +
    "                <a class=\"btn btn-xs btn-primary\" target=\"_self\"\n" +
    "                   uib-tooltip=\"Contact team member with stars admin access to assign subscription\"\n" +
    "                   tooltip-append-to-body=\"true\"\n" +
    "                   tooltip-placement=\"top\"\n" +
    "                   tooltip-enable=\"$ctrl.noSubscriptionsAccess()\"\n" +
    "\n" +
    "                   ng-disabled=\"$ctrl.noSubscriptionsAccess()\"\n" +
    "                   ng-click=\"$ctrl.handleRedirectClick()\">Add subscription</a>\n" +
    "                   <span class=\"clickable fa fa-info-circle btn-sub\"\n" +
    "                         ng-if=\"!$ctrl.noSubscriptionsAccess()\"\n" +
    "                         uib-tooltip=\"You will be taken to Institution section to edit subscription period\"\n" +
    "                         tooltip-placement=\"top\">\n" +
    "                  </span>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "        <div ng-if=\"$ctrl.hasSubscription()\">\n" +
    "            <div class=\"row\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">\n" +
    "                    Date updated:\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-8 word-break-all\">\n" +
    "                    {{ $ctrl.getDateUpdated() | date }}\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "                <label class=\"control-label col-sm-5 col-md-4\">\n" +
    "                    Choose by audit validity date:\n" +
    "                </label>\n" +
    "                <div class=\"col-sm-7 col-md-6\">\n" +
    "                    <ui-select class=\"ui-grid-filter-select col-sm-5 col-md-7 no-padding\"\n" +
    "                               on-select=\"$ctrl.handleSubscriptionChanges()\"\n" +
    "                               ng-required=\"true\"\n" +
    "                               ng-model=\"$ctrl.subscription\"\n" +
    "                               theme=\"bootstrap\"\n" +
    "                               search-enabled=\"true\">\n" +
    "                        <ui-select-match>\n" +
    "                            {{ $select.selected.label }}\n" +
    "                        </ui-select-match>\n" +
    "                        <ui-select-choices\n" +
    "                            position=\"down\"\n" +
    "                            repeat=\"item in ($ctrl.subscriptionList | filter: $select.search) track by item.value\">\n" +
    "                            <div ng-bind-html=\"item.label | highlight: $select.search\"></div>\n" +
    "                        </ui-select-choices>\n" +
    "                    </ui-select>\n" +
    "                    <span class=\"clickable fa fa-info-circle pull-right m-t-sm\"\n" +
    "                          ng-if=\"!$ctrl.noSubscriptionsAccess() && $ctrl.isOnlyMiniCoreSubscription()\"\n" +
    "                          uib-tooltip=\"You will be taken to Institution section to edit subscription period\"\n" +
    "                          tooltip-placement=\"top\">\n" +
    "                    </span>\n" +
    "\n" +
    "                    <a class=\"btn btn-xs btn-primary pull-right m-t-xs\" target=\"_self\"\n" +
    "                       uib-tooltip=\"Contact team member with stars admin access to assign subscription\"\n" +
    "                       tooltip-append-to-body=\"true\"\n" +
    "                       tooltip-placement=\"top\"\n" +
    "                       tooltip-enable=\"$ctrl.noSubscriptionsAccess()\"\n" +
    "\n" +
    "                       ng-disabled=\"$ctrl.noSubscriptionsAccess()\"\n" +
    "                       ng-if=\"$ctrl.isOnlyMiniCoreSubscription()\"\n" +
    "                       ng-click=\"$ctrl.handleRedirectClick()\">Add subscription</a>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <form id=\"starsEditForm\" class=\"institution-stars-edit\" name=\"$ctrl.forms.starsEdit\" novalidate>\n" +
    "    <div class=\"form-group m-t\" ng-if=\"$ctrl.hasSubscription()\">\n" +
    "        <div class=\"row\">\n" +
    "            <label class=\"control-label col-sm-5 col-md-4\">\n" +
    "                Overall Rating:\n" +
    "            </label>\n" +
    "            <div class=\"col-sm-7 col-md-8\">\n" +
    "                <ui-select\n" +
    "                    class=\"ui-grid-filter-select stars-dropdown no-padding\"\n" +
    "                    placeholder=\"Select an option\"\n" +
    "                    ng-model=\"$ctrl.selectedItem.answers[0].overallStarsRating\"\n" +
    "                    ng-disabled=\"$ctrl.isReadOnly()\"\n" +
    "                    theme=\"bootstrap\">\n" +
    "                    <ui-select-match allow-clear=\"true\" placeholder=\"Select an option\">\n" +
    "                        <span ng-bind-html=\"$select.selected.label\">{{$select.selected.label}}</span>\n" +
    "                    </ui-select-match>\n" +
    "                    <ui-select-choices\n" +
    "                      position=\"down\"\n" +
    "                      repeat=\"option.value as option in $ctrl.starsList | filter: $select.search\">\n" +
    "                      <div class=\"test\" ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                    </ui-select-choices>\n" +
    "                </ui-select>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <stars-results-upload selected-item=\"$ctrl.selectedItem\" is-read-only=\"$ctrl.isReadOnly()\" selected-subscription=\"$ctrl.subscription\"></stars-results-upload>\n" +
    "        <span ng-show=\"$ctrl.historyLog.modifiedAt\">\n" +
    "           Data last updated by {{$ctrl.historyLog.isUpdatedByCsv ? '.csv upload' : 'API'}} by {{$ctrl.historyLog.modifiedByFullName}} at {{$ctrl.historyLog.modifiedAt | date:'shortTime'}}, {{$ctrl.historyLog.modifiedAt | date:'mediumDate'}}\n" +
    "        </span>\n" +
    "    </div>\n" +
    "\n" +
    "\n" +
    "    <div class=\"row m-b-xs\" ng-if=\"$ctrl.hasSubscription()\">\n" +
    "        <button class=\"btn btn-default btn-xs pull-right\"\n" +
    "                ng-click=\"$ctrl.toggleTabs()\">\n" +
    "            <i class=\"fa\" ng-class=\"$ctrl.isAllTabsExpanded ? 'fa-arrow-up' : 'fa-arrow-down'\"></i>\n" +
    "            <span>{{$ctrl.isAllTabsExpanded ? 'Collapse all tabs' : 'Expand all tabs'}}</span>\n" +
    "        </button>\n" +
    "    </div>\n" +
    "\n" +
    "<div class=\"row\" ng-if=\"$ctrl.hasSubscription()\">\n" +
    "    <stars-sections selected-item=\"$ctrl.selectedItem\"\n" +
    "                    is-read-only=\"$ctrl.isReadOnly()\"\n" +
    "                    sections=\"$ctrl.sections\"\n" +
    "                    categories=\"$ctrl.categories\"\n" +
    "                    subscription =\"$ctrl.subscription\"></stars-sections>\n" +
    "</div>\n" +
    "    <div class=\"row\">\n" +
    "        <div class=\"form-group\">\n" +
    "            <div class=\"col-sm-12\">\n" +
    "                <span ng-if=\"$ctrl.hasSubscription()\" class=\"clickable fa fa-info-circle pull-right m-r-xs\"\n" +
    "                      uib-tooltip=\"An 'Update' needs to be performed before clicking 'Submit to Institution'\"\n" +
    "                      tooltip-placement=\"top-right\">\n" +
    "                </span>\n" +
    "                <a ng-if=\"$ctrl.hasSubscription()\"\n" +
    "                   class=\"btn btn-primary btn-sm pull-right\"\n" +
    "                   ng-class=\"{'disabled': $ctrl.isReadOnly() || !$ctrl.isSubmitEnabled() || $ctrl.buttonsDisabled()}\"\n" +
    "                   ng-click=\"$ctrl.handleSubmitToInstitution()\"\n" +
    "				   uib-tooltip=\"Mini Core submission is already submitted to Client section\"\n" +
    "                   tooltip-append-to-body=\"true\"\n" +
    "                   tooltip-placement=\"top\"\n" +
    "                   tooltip-enable=\"$ctrl.isReadOnly() || !$ctrl.isSubmitEnabled()\">\n" +
    "                    <i class=\"fa fa-eye\"></i>\n" +
    "                    <span>Submit to Institution</span>\n" +
    "                </a>\n" +
    "\n" +
    "                <a ng-if=\"$ctrl.hasSubscription()\"\n" +
    "                   class=\"btn btn-primary btn-sm pull-right\"\n" +
    "                   ng-class=\"{'disabled': $ctrl.buttonsDisabled()}\"\n" +
    "                   ng-click=\"$ctrl.handleSaveClick()\">\n" +
    "                    <i class=\"fa fa-check-circle\"></i>\n" +
    "                    <span> Update </span>\n" +
    "                </a>\n" +
    "                <a class=\"btn btn-default btn-sm pull-right\"\n" +
    "                   ng-click=\"$ctrl.handleClose()\">\n" +
    "                    <i class=\"fa fa-ban\"></i>\n" +
    "                    <span>Cancel</span>\n" +
    "                </a>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</form>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/stars/components/starsResultsUpload/starsResultsUploadView.html',
    "<div class=\"row general-tab m-t\">\n" +
    "    <label class=\"control-label col-sm-5 col-md-4\">\n" +
    "        <span class=\"clickable fa fa-info-circle\"\n" +
    "              uib-tooltip=\"Only CSV files are accepted & up to {{ $ctrl.maxSize }}MB in size\"\n" +
    "              tooltip-placement=\"top\">\n" +
    "        </span> Upload data via .csv:\n" +
    "        <br>\n" +
    "        <a class=\"btn btn-xs btn-default\" target=\"_self\" href=\"{{$ctrl.getDataTemplateUrl()}}\">Download template</a>\n" +
    "    </label>\n" +
    "    <div class=\"col-lg-8\">\n" +
    "        <div class=\"clearfix\"\n" +
    "             ng-show=\"$ctrl.showUpload()\"\n" +
    "             ng-class=\"{'modal-overlay-35': $ctrl.uplooadInProgress || $ctrl.isReadOnly}\"\n" +
    "             name=\"$ctrl.forms.uploadForm\"\n" +
    "             enctype=\"multipart/form-data\"\n" +
    "             ng-dropzone\n" +
    "             dropzone=\"$ctrl.dropZoneInstance\"\n" +
    "             dropzone-config=\"$ctrl.config.dropzone\"\n" +
    "             event-handlers=\"$ctrl.config.eventHandlers\"\n" +
    "             novalidate\n" +
    "             dz-hidden-input-file>\n" +
    "            <div class=\"fallback form-group\">\n" +
    "                <input name=\"file\" type=\"file\"/>\n" +
    "            </div>\n" +
    "\n" +
    "            <button class=\"btn btn-default btn-outline btn-sm dz-message\">\n" +
    "                <i class=\"fa fa-upload\"></i> Upload\n" +
    "            </button>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"clearfix\" ng-show=\"!$ctrl.showUpload()\">\n" +
    "            <button ng-click=\"$ctrl.removeFile()\" class=\"btn btn-xs btn-danger\">\n" +
    "                {{ $ctrl.uploadedFileName }} <i class=\"fa fa-times\"></i>\n" +
    "            </button>\n" +
    "        </div>\n" +
    "\n" +
    "    </div>\n" +
    "\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/stars/components/starsRightSidePanel/starsRightSidePanelView.html',
    "<div class=\"ibox\">\n" +
    "    <div class=\"ibox-title\">\n" +
    "        <h5>Edit Submission</h5>\n" +
    "        <div class=\"ibox-tools\">\n" +
    "            <a ng-click=\"$ctrl.closeRightSidePanel()\">\n" +
    "                <i class=\"fa fa-times\"></i>\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"ibox-content\">\n" +
    "        <stars-edit selected-item=\"$ctrl.selectedItem\"\n" +
    "                    selected-item-id=\"$ctrl.selectedItemId\"></stars-edit>\n" +
    "    </div>\n" +
    "</div>\n"
  );


  $templateCache.put('/scripts/modules/stars/components/starsSpecialBadges/starsSpecialBadgesView.html',
    "<div class=\"form-group m-b-none\">\n" +
    "    <div class=\"row\">\n" +
    "        <label class=\"col-lg-2 control-label\">\n" +
    "            Assign specialist badges\n" +
    "        </label>\n" +
    "        <div class=\"col-lg-10\">\n" +
    "            <div class=\"col-md-6 col-sm-6 no-padding\">\n" +
    "                <ui-select\n" +
    "                    close-on-select=\"true\"\n" +
    "                    ng-model=\"$ctrl.subject\"\n" +
    "                    ng-disabled=\"$ctrl.isReadOnly\"\n" +
    "                    search-enabled=\"true\"\n" +
    "                    theme=\"bootstrap\">\n" +
    "                    <ui-select-match placeholder=\"Specialist Badges\" allow-clear=\"true\">\n" +
    "                        <span ng-bind-html=\"$select.selected.label\"></span>\n" +
    "                    </ui-select-match>\n" +
    "                    <ui-select-choices\n" +
    "                        refresh-delay=\"1000\"\n" +
    "                        position=\"up\"\n" +
    "                        ui-disable-choice=\"option.disabled\"\n" +
    "                        repeat=\"option in $ctrl.subjectsList | filter:$select.search | orderBy:'label'\">\n" +
    "                        <div ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                    </ui-select-choices>\n" +
    "                    <ui-select-no-choice>\n" +
    "                        Not found\n" +
    "                    </ui-select-no-choice>\n" +
    "                </ui-select>\n" +
    "            </div>\n" +
    "\n" +
    "            <div class=\"col-md-4 col-sm-4\">\n" +
    "                <ui-select\n" +
    "                    close-on-select=\"true\"\n" +
    "                    ng-model=\"$ctrl.stars\"\n" +
    "                    ng-disabled=\"$ctrl.isReadOnly\"\n" +
    "                    search-enabled=\"true\"\n" +
    "                    theme=\"bootstrap\">\n" +
    "                    <ui-select-match placeholder=\"Stars\" allow-clear=\"true\">\n" +
    "                        <span ng-bind-html=\"$select.selected.label\"></span>\n" +
    "                    </ui-select-match>\n" +
    "                    <ui-select-choices\n" +
    "                        refresh-delay=\"1000\"\n" +
    "                        position=\"up\"\n" +
    "                        ui-disable-choice=\"option.disabled\"\n" +
    "                        repeat=\"option in $ctrl.starsList | filter:$select.search | orderBy:'label'\">\n" +
    "                        <div ng-bind-html=\"option.label | highlight: $select.search\"></div>\n" +
    "                    </ui-select-choices>\n" +
    "                    <ui-select-no-choice>\n" +
    "                        Not found\n" +
    "                    </ui-select-no-choice>\n" +
    "                </ui-select>\n" +
    "            </div>\n" +
    "\n" +
    "            <button class=\"btn btn-primary col-md-2 col-sm-2\"\n" +
    "                    ng-disabled=\"$ctrl.isAddButtonDisabled()\"\n" +
    "                    ng-click=\"$ctrl.handleAdd()\">\n" +
    "                <i class=\"fa fa-plus\"></i>\n" +
    "                Add\n" +
    "            </button>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row\">\n" +
    "        <div class=\"col-lg-10 col-lg-offset-2\">\n" +
    "            <ul class=\"list-inline list-border\">\n" +
    "                <li ng-repeat=\"item in $ctrl.badges\">\n" +
    "                    {{ item.subject }} <span ng-bind-html=\"item.stars\"></span>\n" +
    "                    <span class=\"btn btn-xs btn-link pull-right\" ng-click=\"$ctrl.handleRemove($index)\">\n" +
    "                        <i class=\"glyphicon glyphicon-remove\" aria-hidden=\"true\"></i>\n" +
    "                    </span>\n" +
    "                </li>\n" +
    "            </ul>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "\n" +
    "</div>\n"
  );

}]);
}(window.angular));
(function(angular) {
    "use strict";

    angular
        .module('qsHub')
        .service('ReleaseVersionService', [
           'version',
            Service
        ]);

    function Service(version) {
        var service = {};

        service.isVisible = function (releaseVersion) {
          releaseVersion = typeof releaseVersion !== 'string' ? releaseVersion.toString() : releaseVersion;
          version = typeof version !== 'string' ? version.toString() : version;
          var currentVersionParts = version.split('.'),
              newVersionParts = releaseVersion.split('.'),
              currentVersion = {
                  major : typeof currentVersionParts[0] !== 'undefined' ? parseInt(currentVersionParts[0], 10) : 0,
                  minor : typeof currentVersionParts[1] !== 'undefined' ? parseInt(currentVersionParts[1], 10) : 0,
                  patch : typeof currentVersionParts[2] !== 'undefined' ? parseInt(currentVersionParts[2], 10) : 0
              },
              newVersion = {
                  major : typeof newVersionParts[0] !== 'undefined' ? parseInt(newVersionParts[0], 10) : 0,
                  minor : typeof newVersionParts[1] !== 'undefined' ? parseInt(newVersionParts[1], 10) : 0,
                  patch : typeof newVersionParts[2] !== 'undefined' ? parseInt(newVersionParts[2], 10) : 0
              };

          return (newVersion.major > currentVersion.major) ||
          (newVersion.major === currentVersion.major && newVersion.minor > currentVersion.minor) ||
          (newVersion.major === currentVersion.major && newVersion.minor === currentVersion.minor && newVersion.patch > currentVersion.patch);
        };

        return service;
    }
}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    App.services.AuthenticationService = function (
        $resource,
        $q,
        $localStorage,
        $http,
        $window,
        constants,
        UserFactory,
        $rootScope,
        authInterceptor
    ) {
        var service = {
                username: null
            },
            deferred = null;

        /**
         * Get programs list API endpoint.
         *
         * @returns {$resource}
         */
        service.getAuthenticationModel = function () {
            return $resource(constants.api.usersPermissions.url + '/v1/signin');
        };

        service.setData = function (response) {
            var token = response.token,
                userData = {
                    coreId: response.userData.coreId,
                    isClient: response.userData.isClient,
                    title: response.userData.title,
                    userName: service.username,
                    email: response.userData.email,
                    firstName: response.userData.firstName,
                    phone: response.userData.phone,
                    profileLogo: response.userData.profileLogo,
                    primaryInstitutionName: response.userData.primaryInstitutionName,
                    lastName: response.userData.lastName,
                    fullName: response.userData.fullName,
                    primaryInstitution: response.userData.primaryInstitution,
                    accessTo: response.userData.accessTo,
                    roles: response.userData.roles,
                    position: response.userData.position,
                    institutions: angular.isArray(response.userData.institutions) ? response.userData.institutions : [],
                    passwordReset: !!response.userData.passwordReset,
                };
            // store username and token in local storage to keep user logged in between page refreshes
            $localStorage.currentUser = {
                token: token,
                info: userData
            };
            UserFactory.setData(userData);

            // add jwt token to auth header for all requests made by the $http service
            service.setAuthorizationHeader(token);
        };

        service.handleSuccess = function (response) {
            if (response.error && response.message) {
                return service.handleFailure(response);
            }
            authInterceptor.resetUrl();

            service.setData(response);

            service.clearXLocalStorage();
            $window.localStorage.setItem(constants.xStorage.keys.source, 'hub');
            $window.localStorage.setItem(constants.xStorage.keys.data, angular.toJson({
                token: $localStorage.currentUser.token,
                userData: $localStorage.currentUser.info
            }));

            deferred.resolve(true);
            deferred = null;
        };

        service.handleFailure = function (response) {
            deferred.resolve(response);
            deferred = null;
        };

        service.login = function (username, password) {
            deferred = $q.defer();

            if (!username || !username.length || !password || !password.length) {
                deferred.resolve(false);
            } else {
                service.username = username;
                var AuthModel = service.getAuthenticationModel();
                AuthModel.save(null, {
                    username: username,
                    password: password
                }, service.handleSuccess, service.handleFailure);
            }

            return deferred.promise;
        };

        service.logout = function () {
            // remove user from local storage
            delete $localStorage.currentUser;
            // clear institutions list for client users
            UserFactory.setInstitutions(null);
            // clear http auth header
            $http.defaults.headers.common.Authorization = '';
            // clear app data
            $rootScope.$emit(constants.events.logout);

            angular.forEach(constants.xStorage.keys, function (itemKey) {
                $window.localStorage.removeItem(itemKey);
            });
        };

        service.setAuthorizationHeader = function(token) {
            $http.defaults.headers.common.Authorization = token;
        };

        service.getUserData = function () {
            return $localStorage.currentUser && $localStorage.currentUser.info ? $localStorage.currentUser.info : null;
        };

        service.setUserData = function (data) {
            if (typeof $localStorage.currentUser === 'undefined') {
                $localStorage.currentUser = {};
            }
            $localStorage.currentUser.info = data;
        };

        service.clearXLocalStorage = function () {
            angular.forEach(constants.xStorage.keys, function (itemKey) {
                $window.localStorage.removeItem(itemKey);
            });
        };

        service.setXLocalStorageLogout = function () {
            $window.localStorage.setItem(constants.xStorage.keys.logout, 'hub');
        };

        service.hasUserData = function () {
            return $localStorage.currentUser ? true: false;
        };

        service.getAuthorizationHeader = function () {
            return $localStorage.currentUser && $localStorage.currentUser.token ?
                { 'Authorization' : $localStorage.currentUser.token } : null;
        };

        service.getPublicPages = function () {
            return ['/', '/forgot-password', '/reset-password','/password-reset'];
        };

        return service;
    };

    angular
        .module('qsHub')
        .service('AuthenticationService', [
            '$resource',
            '$q',
            '$localStorage',
            '$http',
            '$window',
            'constants',
            'UserFactory',
            '$rootScope',
            'authInterceptor',
            App.services.AuthenticationService
        ]);
}(window.angular));

(function (angular) {
    'use strict';
    angular.module('qsHub')
        .config(['$provide', function ($provide) {
            $provide.decorator('Grid', function ($delegate,$timeout) {
                $delegate.prototype.renderingComplete = function () {
                    if (angular.isFunction(this.options.onRegisterApi)) {
                        this.options.onRegisterApi(this.api);
                    }
                    this.api.core.raise.renderingComplete(this.api);
                    $timeout(function () {
                        var $viewport =  angular.element(document.querySelectorAll('.ui-grid-render-container'));
                        [
                            'touchstart',
                            'touchmove',
                            'touchend',
                            'keydown',
                            'wheel',
                            'mousewheel',
                            'DomMouseScroll',
                            'MozMousePixelScroll'
                        ].forEach(function (eventName) {
                            $viewport.unbind(eventName);
                        });
                    }.bind(this));
                };
                return $delegate;
            });
        }]);
}(window.angular));

(function(angular) {
    'use strict';

    var App = App || {};
    App = angular.extend({}, App, {filters:{}});

    App.filters.uiGridSelectedLabel = function () {
        return function($select) {
            if (!$select ||
                typeof $select.selected === 'undefined' ||
                $select.selected === null
            ) {
                return null;
            }
            if ($select.selected.label) {
                return $select.selected.label;
            }

            var i = 0,
                total = $select.items.length,
                selectedValue = angular.copy($select.selected);
            if (isNaN(selectedValue) && selectedValue.indexOf('=') === 0) {
                selectedValue = parseInt(selectedValue.replace('=', ''), 10);
            }

            for (i; i < total ;i++) {
                if ($select.items[i].value === selectedValue) {
                    return $select.items[i].label;
                }
            }
            return null;
        };
    };

    angular
        .module('qsHub')
        .filter('uiGridSelectedLabel', App.filters.uiGridSelectedLabel);
}(window.angular));


(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {
        components:{}
    });

    App.components.uiGridInfo = {
        templateUrl: '/scripts/shared/ui-grid/uiGridInfo/uiGridInfoView.html',
        bindToController: true,
        controller: 'UiGridInfoController',
    };

    angular.module('qsHub').component('uiGridInfo', App.components.uiGridInfo);

}(window.angular));




(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers:{}});
    App.controllers.UiGridInfo = function ($state) {
        var controller = this;

        controller.isNominationsReportsPage = function () {
            return $state.current.name === 'staff.iu.nominations.reports' ? true : false;
        };
    };

    angular
        .module('qsHub')
        .controller('UiGridInfoController', [
            '$state',
            App.controllers.UiGridInfo
        ]);

}(window.angular));

(function (angular) {
    'use strict';

    var App = App || {};
    App = angular.extend({}, App, { services: {} });

    App.services.UiGrid = function (
        $localStorage,
        Grid,
        UserFactory,
        uiGridConstants
    ) {
        var service = {},
            exploded,
            clear = Grid.prototype.clearAllFilters;

        service.getKey = function (nameSpace) {
            return nameSpace + UserFactory.getData().coreId;
        };

        /**
        * Set qs users datagrid columns filters.
        *
        * @param {string} nameSpace
        * @param {Object} columnsFilters
        */
        service.storeColumnsFilters = function (nameSpace, columnsFilters) {
            $localStorage[service.getKey(nameSpace)] = columnsFilters;
        };

        /**
         * Get qs users datagrid columns filters.
         *
         * @param {string} nameSpace
         * @param {array} dateFilters
         */
        service.getColumnsFilters = function (nameSpace, dateFilters) {
            if (typeof $localStorage[service.getKey(nameSpace)] !== 'undefined') {
                return $localStorage[service.getKey(nameSpace)];
            }

            var filters = {};
            if (angular.isDefined(dateFilters) &&
                angular.isArray(dateFilters) &&
                dateFilters.length > 0
            ) {
                angular.forEach(dateFilters, function (filterName) {
                    filters[filterName] = {
                        startDate: null,
                        endDate: null
                    };
                });
            }

            return filters;
        };

        /**
         * Set qs users datagrid columns visibility.
         *
         * @param {string} nameSpace
         * @param {Object} columnsVisibility
         */
        service.storeColumnsVisibility = function (nameSpace, columnsVisibility) {
            if (!nameSpace) {
                throw 'Missing namespace';
            }
            $localStorage[service.getKey(nameSpace)] = columnsVisibility;

            // Clear filters for the hidden columns
            var filterNameSpace = nameSpace.replace('Visibility', 'Filters'),
                columnsFilters = service.getColumnsFilters(filterNameSpace);

            if (columnsFilters) {
                angular.forEach(columnsVisibility, function (value, key) {
                    if (value === false) {
                        columnsFilters['filter[' + key + ']'] = null;
                    }
                });
                service.storeColumnsFilters(columnsFilters);
            }

            return columnsFilters;
        };

        /**
         * Get qs users datagrid columns visibility.
         *
         * @param {string} nameSpace
         * @returns {Object|*}
         */
        service.getColumnsVisibility = function (nameSpace) {
            return $localStorage[service.getKey(nameSpace)];
        };

        /**
         * Returns the names of the columns with its visibility (true visible, false not visible)
         *
         * @param {object|$scope.gridOptions.columnDefs} columns
         * @param {function} convertFunction function to convert columns names (optional)
         * @returns {object}
         */
        service.getGridColumnsVisibility = function (columns, convertFunction) {
            var visibility = {},
                fieldName;
            angular.forEach(columns, function (column) {
                if (angular.isDefined(column.visible) && angular.isDefined(column.field)) {
                    fieldName = angular.isDefined(convertFunction) && typeof convertFunction === 'function' ?
                                convertFunction(column.field) : column.field;
                    visibility[fieldName] = column.visible;
                }
            });

            return visibility;
        };

        /**
         * Gets visibility based on field name
         *
         * @param {object} columns
         * @param {string} fieldName
         * @param {boolean} defaultValue
         * @returns boolean
         */
        service.getVisibilityByField = function (columns, fieldName, defaultValue) {
            return angular.isDefined(columns) && angular.isDefined(columns[fieldName]) ?
                columns[fieldName] : defaultValue;
        };

        /**
         * Gets filter based on field name
         *
         * @param {Object} filters
         * @param {String} fieldName
         * @param {mixed|null} defaultValue
         * @param {boolean|null} isArray - return as array
         * @returns {Object}
         */
        service.getFilterByField = function (filters, fieldName, defaultValue, isArray) {
            var filter = 'filter[' + fieldName + ']',
                defaultVal = typeof defaultValue === 'undefined' ? null : defaultValue;

            filter = angular.isDefined(isArray) && isArray ? filter + '[]' : filter;

            return typeof filters[filter] === 'undefined' ? defaultVal : filters[filter];
        };

        /**
         * Removes localstorage by key
         *
         * @param {string} nameSpace
         */
        service.removeByNameSpace = function (nameSpace) {
            $localStorage.removeItem(service.getKey(nameSpace));
        };

        /**
         *
         * @param {type} column
         * @returns {undefined}
         */
        service.applyFilters = function (column) {
            if (column.filters[0].type !== 'select') {
                if (column.filters[0].term.indexOf('=') > -1) {
                    column.filters[0].condition = new RegExp('^' + column.filters[0].term.replace(' ', '\\s').replace('=', '') + '$', 'i');
                } else if (column.filters[0].term.indexOf('%') > -1) {
                    column.filters[0].condition = new RegExp(column.filters[0].term.replace('%', '(.*).+'), 'i');
                } else if (column.filters[0].term.indexOf(',') > -1) {
                    exploded = column.filters[0].term.split(',');
                    angular.forEach(exploded, function (values, key) {
                        exploded[key].replace(' ', '\\s');
                        exploded[key] = '(\\b' + values + '\\b)';
                    });
                    column.filters[0].condition = new RegExp(exploded.join('|'), 'i');
                } else if (column.filters[0].term.indexOf('&') > -1) {
                    exploded = column.filters[0].term.split('&');
                    angular.forEach(exploded, function (values, key) {
                        exploded[key].replace(' ', '\\s');
                        exploded[key] = '(?=.*' + values + ')';
                    });
                    column.filters[0].condition = new RegExp(exploded.join(''), 'i');
                }
            }
        };

        /**
         *
         * @param {type} columns
         * @returns {undefined}
         */
        service.resetConditions = function (columns) {
            angular.forEach(columns, function (column) {
                if (!angular.equals(column.filters[0].condition, uiGridConstants.filter.CONTAINS)) {
                    delete (column.filters[0].condition);
                }
            });
        };

        /**
         * Extend clear all filters u-grid function
         *
         * @param {object} columnBefore
         * @param {object} columnDefs
         * @param {function} defaultFilters
         */
        service.resetExtend = function (columnBefore, columnDefs, defaultFilters) {
            Grid.prototype.clearAllFilters = function extendedClearAllFilters() {
                clear.apply(this, Grid.prototype.clearAllFilters);
                columnBefore.forEach(function (element, index) {
                    if (element.visible) {
                        if (defaultFilters && columnDefs[index].filters) {
                            defaultFilters.lastLoginAtRange = {
                                startDate: null,
                                endDate: null
                            };
                            if (defaultFilters.hasOwnProperty('createdAtRange')) {
                                defaultFilters.createdAtRange = {
                                    startDate: null,
                                    endDate: null
                                };
                            }
                        }
                    }
                });
            };
        };

        service.getCsvData = function (columns, rows) {
            var data = [],
                cellValue,
                extractedRow,
                column,
                columnParts,
                node,
                key,
                total;

            rows.forEach(function (row) {
                extractedRow = [];
                columns.forEach(function (gridCol) {
                    if (gridCol.visible &&
                        gridCol.colDef.exporterSuppressExport !== true
                    ) {
                        cellValue = '';
                        column = gridCol.colDef.field ? gridCol.colDef.field : gridCol.colDef.name;
                        if (column) {
                            if (row[column]) {
                                cellValue = row[column];
                            } else if (column.indexOf('.') !== -1) {
                                columnParts = column.split('.');
                                total = columnParts.length;
                                node = null;
                                for (var i = 0; i < total; i++) {
                                    key = columnParts[i];
                                    if (i === 0) {
                                        if (typeof row[key] === 'undefined') {
                                            break;
                                        }
                                        node = row[key];
                                    } else if (i === total - 1) {
                                        if (!node || typeof node[key] === 'undefined') {
                                            break;
                                        }
                                        cellValue = node[key];
                                    } else {
                                        if (!node || typeof node[key] === 'undefined') {
                                            break;
                                        }
                                        node = node[key];
                                    }
                                }

                            }
                        }
                        var extractedField = {
                            value: cellValue
                        };
                        extractedRow.push(extractedField);
                    }
                });

                data.push(extractedRow);
            });

            return data;
        };

        service.addHeaderClass = function (columnDefs) {
            angular.forEach(columnDefs, function (column) {
                column.headerCellClass = column.displayName.replace(' ', '_').toLowerCase();
            });
        };

        service.generateSelectValues = function (columns, selectValues) {
            angular.forEach(columns, function (column) {
                if (column.filters[0].type === uiGridConstants.filter.SELECT) {
                    selectValues[column.filters[0].column ? column.filters[0].column : column.filters[0].field] = column.filters[0].term;
                }
            });
        };

        return service;
    };

    angular
        .module('qsHub')
        .service('UiGridService', [
            '$localStorage',
            'Grid',
            'UserFactory',
            'uiGridConstants',
            App.services.UiGrid
        ]);
} (window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    App.services.UiSelect = function () {
        var service = {};

        service.getYesNoOptions = function (excludeNa) {
            excludeNa = excludeNa || false;
            var options = [
                { value: '', label: 'N/A' },
                { value: 'yes', label: 'Yes' },
                { value: 'no', label: 'No' },
            ];
            if (excludeNa) {
                delete options[0];
            }
            return options;
        };

        service.getYesNoOptionsForTrueFalse = function (excludeNa) {
            excludeNa = excludeNa || false;
            var options = [
                { value: '', label: 'N/A' },
                { value: true, label: 'Yes' },
                { value: false, label: 'No' },
            ];
            if (excludeNa) {
                delete options[0];
            }
            return options;
        };

        return service;
    };

    angular
        .module('qsHub')
        .service('UiSelectService', [
            App.services.UiSelect
        ]);
}(window.angular));

(function (angular) {
    'use strict';

    var App = App || {};
    App = angular.extend({}, App, {services: {}});

    App.services.LocalStorage = function ($localStorage,
                                          UserFactory) {
        var service = {};

        service.getKey = function (nameSpace) {
            return nameSpace + UserFactory.getData().coreId;
        };

        /**
         * Store variable in local storage.
         *
         * @param {string} nameSpace
         * @param {*} columnsFilters
         */
        service.storeObject = function (nameSpace, columnsFilters) {
            $localStorage[service.getKey(nameSpace)] = columnsFilters;
        };

        /**
         * Get variable from local storage.
         *
         * @param {string} nameSpace
         * @returns {*}
         */
        service.getObject = function (nameSpace) {
            if (typeof $localStorage[service.getKey(nameSpace)] !== 'undefined') {
                return $localStorage[service.getKey(nameSpace)];
            }
        };

        return service;
    };

    angular
        .module('qsHub')
        .service('LocalStorageService', [
            '$localStorage',
            'UserFactory',
            App.services.LocalStorage
        ]);
}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {factories: {}});

    App.factories.unsavedChanges = function (WatchService, $state) {
        var hasChanges = false,
         isSaved = false,
         saveFn = null,
         currentScope,
         errorCallback;

        var svc = {
            getScope: function () {
                return currentScope;
            },
            setSaveFunction: function (fn, scope) {
                saveFn = fn;
                currentScope = scope;
            },
            runSaveFunction: function () {
                saveFn();
            },
            getIsSaved: function () {
                return isSaved;
            },
            setIsSaved: function () {
                isSaved = true;
            },
            clearIsSaved: function () {
                isSaved = false;
            },
            setChanges: function (has) {
                hasChanges = !!has;
            },
            setSaveError: function () {
                isSaved = 'error';
            },
            hasChanges: function () {
                return hasChanges;
            },
            resetChanges: function () {
                hasChanges = false;
            },
            setErrorCallback: function (callback) {
                errorCallback = callback;
            },
            handleYesClickFn: function (context, toState, customCallback) {
                var firstRun = true;
                var watcher = WatchService.create(svc.getScope(), svc.getIsSaved, function (value) {
                    if (!firstRun || value === 'error') {
                        watcher();
                        if (value === 'error') {
                            svc.clearIsSaved();
                            if (errorCallback) {
                                errorCallback();
                            }
                            context.close();
                        } else {
                            svc.clearIsSaved();
                            svc.resetChanges();
                            context.close();
                            if (toState) {
                                $state.go(toState.name);
                            } else if (customCallback) {
                                customCallback();
                            }
                        }

                    }
                    if (firstRun) {
                        firstRun = false;
                    }
                });
                svc.runSaveFunction();
            }
        };
        return svc;
    };

    angular
        .module('qsHub.rankings')
        .factory('UnsavedChangesFactory', [
            'WatchService',
            '$state',
            App.factories.unsavedChanges
        ]);

}(window.angular));

(function (angular) {
    'use strict';
    angular
        .module('qsHub').directive('fixedElementWhileScrolling', [
        '$timeout',
        '$window',
        'WatchService',
        elementScrolling
    ]);

    /**
     * Makes an element floatable while scrolling based on the table close to it
     */
    function elementScrolling($timeout, $window, WatchService) {
        return {
            restrict: 'A',
            link: function ($scope, $element, $attrs) {

                var timeout, firstRun = true;

                /**
                 * Actions to do when floatable element visibility changes.
                 *
                 * @param {int} elementHeight
                 * @returns {boolean}
                 */
                function handleVisibilityChanges() {
                    //set a timeout so we don't run calculations too many times
                    if (timeout) {
                        $timeout.cancel(timeout);
                    }
                    timeout = $timeout(function () {
                        // attach scroll listener
                        $(window).scroll(handleWindowScroll);
                        centerElement();
                    }, 200);

                }

                /**
                 * Actions to do when window scroll occurs.
                 */
                function handleWindowScroll() {
                    centerElement();
                }

                /**
                 * Center floatable element in the middle of the screen.
                 */
                function centerElement() {

                    var targetHeight = angular.element(document.querySelector($attrs.fixedElementWhileScrolling)).height();
                    var elementHeight = $element.height(),
                        windowScroll = $(window).scrollTop(),
                        windowHeight = $(window).height(),
                        targetElementTopOffset = $($attrs.fixedElementWhileScrolling).offset() ?
                            $($attrs.fixedElementWhileScrolling).offset().top : 0;

                    var mobile = false;
                    var marginTop;
                    if (windowScroll > windowHeight) {
                        marginTop = (windowHeight - elementHeight) / 2 + windowScroll - targetElementTopOffset;
                    }
                    if (windowScroll < windowHeight) {
                        marginTop = (windowScroll - targetElementTopOffset) + ((windowHeight - elementHeight) / 2);
                    }

                    if ($(window).width() < 1200) {
                        mobile = true;
                    } else {
                        if (windowScroll === 0 || windowScroll < targetElementTopOffset) {
                            marginTop = 0;
                        }
                        if (targetHeight < (elementHeight + marginTop)) {
                            marginTop = targetHeight - elementHeight;
                        }
                        if (marginTop < 0 || targetHeight === elementHeight) {
                            marginTop = 0;
                        }
                    }

                    if (mobile) {
                        marginTop = 0;
                        $($element).css('position', 'static');
                    } else {
                        $($element).css('position', 'relative');
                    }

                    if (($scope.$eval($attrs.ngShow) || $scope.$eval($attrs.ngIf)) ||
                        ($scope.$eval($attrs.ngShow) === undefined  &&  $scope.$eval($attrs.ngIf) === undefined) ) {

                        if (firstRun) {
                            $($element).css('top', marginTop + 'px');
                            firstRun = false;
                        } else {
                            $($element).stop().animate({
                                'top': marginTop + 'px'
                            }, 'slow');
                        }
                    }
                }

                var firstShowRun = true;

                WatchService.create($scope, $attrs.ngShow, function () {
                    if (firstShowRun) {
                        firstShowRun = false;
                    } else {
                        handleVisibilityChanges();
                    }

                });

                var firstIfRun = true;

                WatchService.create($scope, $attrs.ngIf, function () {
                    if (firstIfRun) {
                        firstIfRun = false;
                    } else {
                        handleVisibilityChanges();
                    }
                });

                // listen to element visibility changes
                $scope.$watchCollection(function () {
                    // if not visible will return 0
                    return $element.height();
                }, handleVisibilityChanges);
            }
        };
    }
}(window.angular));

(function(angular) {
    'use strict';

    var App = App || {};
    App = angular.extend({}, App, {directives:{}});

    /**
     * Animated Highlighter
     *
     * @returns {{restrict: string, template: string, transclude: boolean, link: method}}
     */
    App.directives.animatedHighlight = function ($animateCss) {
        return {
            restrict: 'A',
            template:'<animated-highlight></animated-highlight><div ng-transclude></div>',
            transclude:true,
            link: function (scope, element, attrs) {
                var colorBefore = "#23a4f6";
                var colorAfter = "none";
                var opacityBefore = 0.3;
                var opacityAfter = 1;
                var delay = 1; //one second
                element.addClass("custom-highlight");
                element.css({
                    position: "relative"
                });
                opacityBefore = attrs.highlightOpacityBefore || opacityBefore;
                opacityAfter = attrs.highlightOpacityAfter || opacityAfter;
                colorBefore = attrs.highlightColorBefore || colorBefore;
                colorAfter = attrs.highlightColorAfter || colorAfter;
                delay = attrs.highlightDelay || delay;
                scope.$watch(attrs.animatedHighlight, function(value){
                    if (value) {
                        var animator = $animateCss(element.find("animated-highlight"), {
                            from: {
                                background: colorBefore,
                                opacity: opacityBefore,
                                position: "absolute",
                                height: "100%",
                                width: "100%",
                                "z-index": 1},
                            to: {
                                background: colorAfter,
                                opacity: opacityAfter,
                                "z-index": -1
                            },
                            duration: delay
                        });
                        animator.start();
                    }
                });
            }
        };
    };

    angular
        .module('qsHub')
        .directive('animatedHighlight', ["$animateCss", App.directives.animatedHighlight]);

}(window.angular));



(function(angular) {
    'use strict';

    var App = App || {};
    App = angular.extend({}, App, {directives:{}});

    /**
     * Directive for run metsiMenu on sidebar navigation
     *
     * @param $timeout
     * @param InstitutionFactory
     * @param UserFactory
     * @param WatchService
     * @returns {{restrict: string, link: link}}
     */
    App.directives.sideNavigation = function ($timeout, InstitutionFactory, UserFactory, WatchService) {
        return {
            restrict: 'A',
            link: function($scope, element) {
                // Call the metisMenu plugin and plug it to sidebar navigation
                $timeout(function(){
                    element.metisMenu();
                });

                var tuEnabledForInstitution = false,
                    tuEnabledForUser = false,
                    tmEnabledForInstitution = false,
                    tmEnabledForUser = false,
                    watches = [];

                $scope.isTuEnabled = false;
                $scope.isTmEnabled = false;

                var toggleTuAccess = function () {
                    $scope.isTuEnabled = UserFactory.isClient() ?
                        tuEnabledForUser && tuEnabledForInstitution : tuEnabledForInstitution;
                };
                var toggleTmAccess = function () {
                    $scope.isTmEnabled = UserFactory.isClient() ?
                        tmEnabledForUser && tmEnabledForInstitution : tmEnabledForInstitution;
                };

                WatchService.create($scope, InstitutionFactory.isAdvancedProgram, function (is) {
                    if (is) {
                        $scope.isTuEnabled = false;
                    }
                });

                var initWatches = function () {
                    angular.forEach(watches, function (watch) {
                        watch();
                    });
                    watches.push(WatchService.create($scope, InstitutionFactory.isTuSubscribed, function (isTuSubscribed) {
                        tuEnabledForInstitution = !!(InstitutionFactory.getCoreId() && isTuSubscribed);
                        toggleTuAccess();
                    }));

                    watches.push(WatchService.create($scope, UserFactory.isTuEnabled, function (isTuEnabled) {
                        tuEnabledForUser = !!(InstitutionFactory.getCoreId() && isTuEnabled);
                        toggleTuAccess();
                    }));

                    watches.push(WatchService.create($scope, InstitutionFactory.isTmSubscribed, function (isTmSubscribed) {
                        tmEnabledForInstitution = !!(InstitutionFactory.getCoreId() && isTmSubscribed);
                        toggleTmAccess();
                    }));

                    watches.push(WatchService.create($scope, UserFactory.isTmEnabled, function (isTmEnabled) {
                        tmEnabledForUser = !!(InstitutionFactory.getCoreId() && isTmEnabled);
                        toggleTmAccess();
                    }));
                };

                WatchService.create($scope, InstitutionFactory.getCoreId, function (coreId) {
                    // trigger watches only when institution data is loaded
                    if (coreId) {
                        initWatches();
                        toggleTuAccess();
                        toggleTmAccess();
                    }
                });
            }
        };
    };

    /**
     * Directive for minimalize sidebar
     *
     * @returns {{restrict: string, template: string, controller: controller}}
     */
    App.directives.minimalizeSidebar = function () {
        return {
            restrict: 'A',
            template: '<a class="navbar-minimalize minimalize-styl-2 btn btn-primary " href="" ng-click="minimalize()"><i class="fa fa-bars"></i></a>',
            controller: function ($scope) {
                $scope.minimalize = function () {
                    $("body").toggleClass("mini-navbar");
                    if (!$('body').hasClass('mini-navbar') || $('body').hasClass('body-small')) {
                        // Hide menu in order to smoothly turn on when maximize menu
                        $('#side-menu').hide();
                        // For smoothly turn on menu
                        setTimeout(
                            function () {
                                $('#side-menu').fadeIn(500);
                            }, 100);
                    } else if ($('body').hasClass('fixed-sidebar')){
                        $('#side-menu').hide();
                        setTimeout(
                            function () {
                                $('#side-menu').fadeIn(500);
                            }, 300);
                    } else {
                        // Remove all inline style from jquery fadeIn function to reset menu state
                        $('#side-menu').removeAttr('style');
                    }
                };
            }
        };
    };

    angular
        .module('qsHub')
        .directive('sideNavigation', ['$timeout', 'InstitutionFactory', 'UserFactory', 'WatchService', App.directives.sideNavigation])
        .directive('minimalizaSidebar', App.directives.minimalizeSidebar);

}(window.angular));

(function(angular) {
    'use strict';

    var App = App || {};
    App = angular.extend({}, App, {directives:{}});


    /**
     * Horizontal Scroll with mouse wheel and with click and drag mouse.
     */
    App.directives.mouseScrollHorizontal = function($swipe, $window) {
      return {
        restrict: 'EA',
        link: function(scope, element, attrs, ctrl) {
            var pointX, pointY, startX, startY;
            var isMouseDraged = false;
            var isMouseDown = false;
            //drag with mouse move
            $swipe.bind(element, {
                'start': function(coords,event) {
                    isMouseDraged = false;
                    isMouseDown = true;
                    startX = coords.x;
                    startY = coords.y;
                },
                'move': function(coords,event) {
                    isMouseDraged = true;
                    if (isMouseDown) {
                        pointX = coords.x;
                        pointY = coords.y;
                        
                        var delta =  startX - pointX;
                        element[0].scrollLeft = delta;
                    }
                },
                'end': function (coords, event) {
                    isMouseDown = false;
                    if (isMouseDraged) {
                        angular.element(event.target).unbind('click');
                    }
                }
            });

            //scroll with mouse wheel
            element.bind("DOMMouseScroll mousewheel onmousewheel", function(event) {
                var delta = 0;
                event = $window.event || event;
                if (event.originalEvent) {
                    delta = Math.max(-1, Math.min(1, (event.originalEvent.wheelDelta || -event.originalEvent.detail)));
                } else {
                    delta = Math.max(-1, Math.min(1, (event.wheelDelta || -event.detail)));
                }
        
                scope.$apply(function(){
                    element[0].scrollLeft -= delta * 40;
                });
                
                // for IE
                event.returnValue = false;
                // for Chrome and Firefox
                if(event.preventDefault) {
                    event.preventDefault();                        
                }
            });
        }
      };
    };
    
    angular
        .module('qsHub')
        .directive('mouseScrollHorizontal', ["$swipe", "$window", App.directives.mouseScrollHorizontal]);

}(window.angular));



(function(angular) {
    'use strict';

    var App = App || {};
    App = angular.extend({}, App, {directives:{}});

    App.directives.displayFiltering = function ($timeout) {
        return {
            restrict: 'E',
            scope: {
                columns:'=',
                active:'=',
                callback: '&'
            },
            templateUrl: '/scripts/shared/displayFiltering/displayFilteringView.html',
            link: function (scope, element, attrs) {
                element.find(".dropdown-filter").click(function(event){
                    event.stopPropagation();
                });
                scope.displayFilteringSelectAll = function () {
                    angular.forEach(scope.columns, function (column) {
                        column.show = true;
                    });
                };
                scope.closeDisplayFiltering = function (event) {
                    $timeout(function() {
                        element.click().trigger(event);
                    },0);
                };
            }
        };
    };

    angular
        .module('qsHub')
        .directive('displayFiltering', ["$timeout", App.directives.displayFiltering]);

}(window.angular));



(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    App.services.InstitutionSwitchService = function ($resource, $q, $log, constants) {
        var service = {};

        /**
         * Get institution search API endpoint.
         *
         * @returns $resource
         */
        service.getInstitutionSearchModel = function () {
            return $resource(
                constants.api.institutions.url + '/v1/list/search/:searchPhrase?sorting[coreId]=asc',
                null,
                {
                    get: {
                        cancellable: true
                    }
                }
            );
        };

        service.getClientInstitutionsModel = function () {
            return $resource(constants.api.institutions.url + '/v1/list/allowed', null, {
                get: {
                    cancellable: true
                }
            });
        };

        /**
         * Search for institution.
         *
         * @param {String} searchPhrase
         * @returns {Promise.array}
         */
        service.searchInstitutions = function (searchPhrase) {
            var deferred = $q.defer();

            if (!searchPhrase || searchPhrase.length < 1) {
                deferred.resolve([]);
            } else {
                var InstitutionsSearchModel = service.getInstitutionSearchModel();
                InstitutionsSearchModel.get(
                    {searchPhrase: searchPhrase},
                    function (data) {
                        if (constants.dev) {
                            $log.log('success, got data: ', data);
                        }
                        deferred.resolve(data.results);
                        deferred = null;
                    },
                    function (error) {
                        //@todo log error
                        if (constants.dev) {
                            $log.log('request failed' + error);
                        }
                        deferred.resolve([]);
                        deferred = null;
                    }
                );
            }

            return deferred.promise;
        };

        /**
         * Get client user institutions.
         *
         * @returns {Promise}
         */
        service.getClientInstitutions = function () {
            var ClientInstitutionsModel = service.getClientInstitutionsModel();
            return ClientInstitutionsModel.get().$promise.then(
                function (data) {
                    if (constants.dev) {
                        $log.log('success, got data: ', data);
                    }
                    return data.results;
                },
                function (error) {
                    //@todo log error
                    if (constants.dev) {
                        $log.log('request failed' + error);
                    }
                    return [];
                }
            );
        };

        return service;
    };

    angular
        .module('qsHub')
        .service('InstitutionSwitchService', [
            '$resource',
            '$q',
            '$log',
            'constants',
            App.services.InstitutionSwitchService
        ]);
}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    App.services.LoginService = function (constants, $location) {
        var service = {};

        /**
         * Allowed to login as institution?
         *
         * @param {Object} institution
         * @returns {boolean}
         */
        service.allowLoginAs = function (institution) {
            return institution && institution.active && institution.typeId && (
                institution.typeId === constants.typeId.topLevelInstitutionId ||
                institution.typeId === constants.typeId.clientDepartmentId ||
                institution.typeId === constants.typeId.advancedProgramId ||
                institution.typeId === constants.typeId.internalId
            ) ? true : false;
        };

        /**
         * Actions to do when login as button clicked
         * @param  {[Object]} parameters
         * @returns {boolean}
         */
        service.getTuProfilesRedirect = function (parameters) {
            $location.path(constants.defaultClientPage).search(parameters);
        };

        return service;
    };

    angular
        .module('qsHub')
        .service('LoginService', [
            'constants',
            '$location',
            App.services.LoginService
        ]);
}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    App.services.Url = function () {
        var service = {},
            youtubeIdPattern = /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?(?=.*v=((\w|-){11}))(?:\S+)?$/,
            youtubePattern = /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([A-Za-z0-9\-_]){11}$|(\&t=\d+s)$/,
            cmtPattern = /https:\/\/www.topuniversities.com\/university-finder\/university\/\D+[0-9 ]+/,
            pattern = /^((?:http|ftp)s?:\/\/)(?:(?:[A-Z0-9](?:[A-Z0-9-]{0,1998}[A-Z0-9])?\.)+(?:[A-Z]{2,6}\.?|[A-Z0-9-]{2,}\.?)|localhost|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(?::\d+)?(?:\/?|[\/?]\S+)$/i;
        
        /**
         * Prepend http into the url.
         *
         * @params {String} url
         * @returns {String}
         */
        service.prependHttp = function (url) {
            if (url && url.length && !/^(f|ht)tps?:\/\//i.test(url)) {
                url = 'http://' + url;
            }
            return url;
        };

        /**
         * Get the youtube id from url
         *
         * @param {string} url
         * @returns {string}
         */
        service.extractYoutubeIdFromUrl = function (url) {
            var match = url.match(youtubeIdPattern);
            if (match && match[1].length === 11) {
                return match[1];
            }
        };

        service.getCmtPattern = function() {
            return cmtPattern;
        };

        service.getPattern = function() {
            return pattern;
        };

        service.getYoutubePattern = function() {
            return youtubePattern;
        };

        /**
         * Prepend https into the url.
         *
         * @params {String} url
         * @returns {String}
         */
        service.prependHttps = function (url) {
            if (url && url.length && !/^(f|ht)tps?:\/\//i.test(url)) {
                url = 'https://' + url;
            }
            return url;
        };

        return service;
    };

    angular
        .module('qsHub')
        .service('UrlService', [
            App.services.Url
        ]);
}(window.angular));

(function(angular) {
    'use strict';

    function prependHttps (UrlService) {
        return function (url) {
            return UrlService.prependHttps(url);
        };
    }

    angular
        .module('qsHub')
        .filter('prependHttps', ['UrlService', prependHttps]);
}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    App.services.Text = function () {
        var service = {};

        service.countWords = function (text) {
            if (text && text.length > 0) {
                text = text.replace(/<(?:.|\n)*?>/gm, ' ').replace(/&amp;nbsp;/g, '').replace(/&nbsp;/g, '').trim();
                return text.length ? text.split(/\s+/).length : 0;
            }

            return 0;
        };

        service.cleanHtmlTags = function (text) {
            if (text && text.length > 0 && service.countWords(text) !== 0) {
                return text.replace(/<[^>]+>/gm, "").trim();
            }

            return null;
        };

        service.clearAttributes = function (text) {
            if (!text) {
                return;
            }
            // 1. remove line breaks / Mso classes
            var i,
                output = text.replace(/(\n|\r| class=(")?Mso[a-zA-Z]+(")?)/g, ' ');
            // 2. strip Word generated HTML comments
            output = output.replace(new RegExp('<!--(.*?)-->','g'), '');
            // 3. remove tags leave content if any
            output = output.replace(new RegExp('<(/)*(meta|link|img|span|\\?xml:|st1:|o:|font)(.*?)>','gi'), '');
            // 4. Remove everything in between and including tags '<style(.)style(.)>'
            var badTags = ['style', 'script','applet','embed','noframes','noscript'];
            for (i = 0; i < badTags.length; i++) {
                output = output.replace(new RegExp('<'+badTags[i]+'.*?'+badTags[i]+'(.*?)>', 'gi'), '');
            }
            // 5. remove attributes ' style="..."'
            var badAttributes = ['style', 'start'];
            for (i = 0; i < badAttributes.length; i++) {
                output = output.replace(new RegExp(' ' + badAttributes[i] + '="(.*?)"','gi'), '');
            }

            return output;
        };

        service.underScoreToCamelCase = function (text) {
            if (text) {
                return text.replace(/(\_\w)/g, function(camel) {
                    return camel[1].toUpperCase();
                });
            }
        };

        service.removeAnchorTag = function (text) {
            if (text) {
                return text.replace( /<a\b[^>]*>/g, "");
            }
        };

        return service;
    };

    angular
        .module('qsHub')
        .service('TextService', [
            App.services.Text
        ]);
}(window.angular));

(function (angular) {
    'use strict';

    angular
        .module('qsHub')
        .filter('strReplace', [strReplace]);

    function strReplace() {
        return function (input, from, to) {
            input = input || '';
            from = from || '';
            to = to || '';
            return input.replace(new RegExp(from, 'g'), to);
        };
    }

} (window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    App.services.Object = function () {
        var service = {};

        /**
         * Set object value.
         *
         * @param {Object} obj
         * @param {string} prop
         * @param {mixed} value
         * @returns {boolean}
         */
        service.set = function (obj, prop, value) {
            if (typeof obj === 'undefined' || typeof prop === 'undefined' || typeof value === 'undefined') {
                return false;
            }

            var index = prop.indexOf('.');
            if (index > -1) {
                return service.set(obj[prop.substring(0, index)], prop.substr(index + 1), value);
            }

            obj[prop] = value;
        };

        return service;
    };

    angular
        .module('qsHub')
        .service('ObjectService', [
            App.services.Object
        ]);
}(window.angular));

(function(angular, moment) {
    "use strict";

    angular
        .module('qsHub')
        .service('TimeService', [
            Time
        ]);

    function Time() {
        var service = {};

        service.now = function (unix) {
            unix = typeof unix === 'undefined' ? false : unix;
            return unix ? parseInt(moment().format('x')) : moment();
        };

        service.nowUnix = function () {
            return service.now(true);
        };

        service.add = function (number, unit) {
            return moment().add(number, unit);
        };

        service.getCurrentYear = function () {
           return moment().year();
        };

        service.sub = function (number, unit) {
            return moment().subtract(number, unit);
        };

        service.startOfYear = function () {
            return moment().startOf('year');
        };

        service.formatInUnix = function (date) {
            return moment(date, 'x');
        };

        service.getInUnix = function (date, utc) {
            utc = utc || false;
            if (utc) {
                return (date.unix() + (date.utcOffset() * 60)) * 1000;
            }

            return parseInt(date.format('x'));
        };

        service.isValid = function (date) {
            return moment(date, 'x').isValid();
        };

        service.isValidYear = function (year) {
            return moment(moment(year).format('YYYY'),'YYYY',true).isValid();
        };

        service.getMilliSeconds = function (momentObject) {
            return momentObject.format('x');
        };

        service.getMomentObject = function (milliSeconds, endOfTheday) {
            return angular.isDefined(endOfTheday) && endOfTheday ?
                    moment(milliSeconds).endOf('day') :moment(milliSeconds);
        };

        service.getInThePastYears = function (years) {
            return service.getMilliSeconds(moment().add('-' + years, 'years'));
        };

        service.isToday = function (date) {
            return moment().diff(moment.unix(date), 'days') === 0;
        };

        service.isTodayOrLater = function (momentObject) {
            return momentObject &&
                angular.isObject(momentObject) &&
                !angular.equals({}, momentObject) &&
                momentObject._isAMomentObject &&
                (momentObject.diff(moment()) >= 0 ||
                momentObject.isSame(moment(), 'd'));
        };

        service.isSameDay = function(date1, date2) {
            date1 = moment(date1);
            date2 = moment(date2);

            return moment(date1).isSame(date2, 'day');
        };

        service.getStartOfToday = function () {
            return moment().startOf('day');
        };

        service.getEndOfToday = function () {
            return moment().endOf('day');
        };

        service.isMoment = function (date) {
            return moment.isMoment(date);
        };

        service.format = function (date, format) {
            return date ? moment(date).utc().format(format) : null;
        };

        service.gmtFormat = function (date, format) {
            return date ? moment(date).format(format) : null;
        };

        service.isSame = function(date, type) {
            type = type || 'year';
            return moment().isSame(moment(date), type);
        };

        service.getInstance = function() {
            return moment;
        };

        service.timeAgo = function(time) {
            return moment(time).fromNow();
        };

        return service;
    }

}(window.angular, window.moment));

(function (angular) {
    'use strict';

    var App = App || {};
    App = angular.extend({}, App, { filters: {} });

    App.filters.mDate = function (constants, $filter) {
        return function (input) {
            return input ? $filter('date')(input, 'mediumDate', 'UTC') : '';
        };
    };

    angular
        .module('qsHub')
        .filter('mDate', ['constants', '$filter', App.filters.mDate]);
} (window.angular));

(function(angular) {
    "use strict";

    angular
        .module('qsHub')
        .service('InstitutionService', [
            '$localStorage',
            'InstitutionsListService',
            'UserFactory',
            '$state',
            Service
        ]);

    function Service(
        $localStorage,
        InstitutionsListService,
        UserFactory,
        $state

    ) {
        var service = {};

        function userHasAccessTo(sections) {
            var userHasAccessTo = false;
            if (angular.isDefined($localStorage.currentUser) &&
                angular.isArray($localStorage.currentUser.info.accessTo) &&
                $localStorage.currentUser.info.accessTo.length > 0
            ) {
                angular.forEach(sections, function (section) {
                    if ($localStorage.currentUser.info.accessTo.indexOf(section) !== -1) {
                        userHasAccessTo = true;
                        return;
                    }
                });
            }
            return userHasAccessTo;
        }

        /**
         *
         * @param {Object} institution
         * @returns {boolean}
         */
        service.isTmViewingEnabled = function (institution) {
            return !!(
                angular.isDefined(institution) &&
                institution &&
                institution.enabled &&
                institution.enabled.tm &&
                institution.hasOwnProperty('nids') &&
                institution.nids !== null &&
                institution.nids.hasOwnProperty('tm') &&
                institution.nids.tm
            );
        };

        service.handle403 = function () {
            if ($localStorage.currentUser.info.isClient) {
                InstitutionsListService.getInstitution(
                    $localStorage.currentUser.info.primaryInstitution
                ).then(function (response) {
                    var allowState = UserFactory.getFirstAllowedState(response.subscriptions);
                    allowState = allowState === '/' ? 'user.my-profile' : allowState;
                    $state.go(allowState);
                });
            } else {
                $state.go('staff.dashboard');
            }
        };

        service.hasAccessToStars = function (subscriptions) {
            // check if
            // + user has access to statistics
            // + institution has active stars subscription
            // + "Show in client section" is enabled
            return userHasAccessTo(UserFactory.getHandles()['clients.statistics']) &&
                subscriptions &&
                subscriptions.stars &&
                subscriptions.stars.advanced &&
                subscriptions.stars.subscribed;
        };

        return service;
    }
}(window.angular));

(function(angular) {
    'use strict';

    angular
        .module('qsHub')
        .directive('stringToNumber', Directive);

    function Directive() {
        function stringToInt (value) {
            return value ? parseFloat(value, 10) : value;
        }

        return {
            require: 'ngModel',
            link: function(scope, element, attrs, ngModel) {
                ngModel.$parsers.push(function(value) {
                    return stringToInt (value);
                });
                ngModel.$formatters.push(function(value) {
                    return stringToInt (value);
                });
            }
        };
    }

}(window.angular));

(function (angular) {
    "use strict";


    angular.module('qsHub').directive('dateRangeFixer', [dateRangeFixer]);

    function dateRangeFixer() {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function(scope, elem, attr, ngModel){
                scope.$watch(function(){ return ngModel.$modelValue;}, function(){
                    if(ngModel.$modelValue.startDate === null && ngModel.$modelValue.endDate === null){
                        var picker = elem.data('daterangepicker');
                        picker.setStartDate(new Date());
                        picker.setEndDate(new Date());
                    }
                });

            }
        };
    }

})(window.angular);
(function(angular){
    "use strict";

    angular.module('qsHub').service('DateRangeFixerService', ['TimeService', dateRangeFixerService]);

    function dateRangeFixerService(TimeService){
        var svc = {};

        svc.sameDayAndNoModel = sameDayAndNoModel;
        svc.todayRangeAndNoModel = todayRangeAndNoModel;

        function sameDayAndNoModel(event, dateRangeObject, lastFilterObject){

            var sameDay = null;
            var modelIsSet = !!(dateRangeObject.startDate && dateRangeObject.endDate);
            var eventModelIsSet = !!(event.model.startDate && event.model.endDate);

            if(lastFilterObject && lastFilterObject.startDate && lastFilterObject.endDate){
                sameDay = TimeService.isSameDay(lastFilterObject.startDate, lastFilterObject.endDate);
            }

            return (sameDay && !eventModelIsSet && !modelIsSet);

        }

        function todayRangeAndNoModel(event, lastFilterObject){

            var sameDay = null;
            var eventModelIsSet = !!(event.model.startDate && event.model.endDate);

            if(lastFilterObject && lastFilterObject.startDate && lastFilterObject.endDate){
                sameDay = TimeService.isSameDay(lastFilterObject.startDate, lastFilterObject.endDate);
            }

            var lastModelIsSet = null;
            if (lastFilterObject) {
                lastModelIsSet = !!(lastFilterObject.startDate && lastFilterObject.endDate);
            }

            return !eventModelIsSet && ((lastFilterObject && sameDay) || !lastModelIsSet);
        }

        return svc;
    }

})(window.angular);
(function(angular){
    "use strict";

    angular.module('qsHub').filter('capitaliseFirstLetter', capitaliseFirstLetterFilter);

    function capitaliseFirstLetterFilter () {
        return function(value) {
            return value ? value.charAt(0).toUpperCase() + value.substr(1) : '';
        };
    }

}(window.angular));
(function(angular){
    "use strict";

    angular.module('qsHub').directive('widthDependantClass', widthDependantClass);

    function widthDependantClass(){
        return {
            scope: {
              widthDependantClass: '='
            },
            restrict: 'A',
            bindToController: true,
            controllerAs: '$ctrl',
            controller: function($scope, $element, WatchService){
                var controller = this;

                function init(){
                    WatchService.create($scope, function(){
                        return $element.width();
                    }, function(width){

                        check('greaterThan');
                        check('lessThan');

                        function check(operator){

                            for (var key in controller.widthDependantClass[operator]) {
                                // skip loop if the property is from prototype
                                if (controller.widthDependantClass[operator].hasOwnProperty(key)){
                                    var value = controller.widthDependantClass[operator][key];
                                    if(operator === 'greaterThan'){
                                        if(width > value){
                                            applyClass(key);
                                        } else{
                                            removeClass(key);
                                        }
                                    }
                                    if(operator === 'lessThan'){
                                        if(width < value){
                                            applyClass(key);
                                        } else{
                                            removeClass(key);
                                        }
                                    }
                                }
                            }
                        }

                        function applyClass(classKey){

                            $element.addClass(classKey);
                        }

                        function removeClass(classKey){

                            $element.removeClass(classKey);
                        }

                    });
                }

                init();

            }

        };
    }

}(window.angular));
(function (angular) {
    "use strict";

    angular
        .module('qsHub')
        .controller('MainController', [
            'version',
            '$scope',
            '$state',
            '$location',
            '$localStorage',
            'constants',
            'InstitutionsService',
            'InstitutionSwitchService',
            'AuthenticationService',
            'WatchService',
            'WebSocketsService',
            'InstitutionFactory',
            'TuProfileFactory',
            'UserFactory',
            'SharedProfileFactory',
            'AppUpdateMessageFactory',
            'ModalService',
            'authInterceptor',
            'UnsavedChangesFactory',
            'InstitutionsListService',
            'InstitutionService',
            '$timeout',
            'ReleaseVersionService',
            Controller
        ]);

    function Controller(
        version,
        $scope,
        $state,
        $location,
        $localStorage,
        constants,
        InstitutionsService,
        InstitutionSwitchService,
        AuthenticationService,
        WatchService,
        WebSocketsService,
        InstitutionFactory,
        TuProfileFactory,
        UserFactory,
        SharedProfileFactory,
        AppUpdateMessageFactory,
        ModalService,
        AuthInterceptor,
        UnsavedChangesFactory,
        InstitutionsListService,
        InstitutionService,
        $timeout,
        ReleaseVersionService
    ) {
        var controller = this;
        var lastInstitution = null;
        controller.handles = UserFactory.getHandles();
        controller.subscribedRankingsList = [];

        $scope.version = version;
        $scope.institution = {
            selected: {
                name: InstitutionFactory.getDisplayName(),
                coreId: InstitutionFactory.getCoreId()
            }
        };
        $scope.institutionsDropdownList = null;
        $scope.email = null;
        $scope.profileLogo = UserFactory.getProfileLogo();
        controller.isActiveCoreId = true;
        controller.isDisabled = false;
        controller.isTmAdvanced = false;
        controller.isValidSchool = false;

        controller.showModalOverlay = function() {
            return controller.isDisabled || controller.noAccess() || !controller.isActiveCoreId ;
        };

        controller.showTmPopup = function() {
            return (controller.isDisabled || !controller.isActiveCoreId) &&
                !controller.isTmAdvanced && !controller.isValidSchool && $scope.isClient;
        };

        controller.showTuPopup = function() {
            return (controller.isDisabled || !controller.isActiveCoreId) &&
                (controller.isValidSchool || controller.isTmAdvanced);
        };

        /**
         * Action to do on search for institution.
         *
         * @param {String} searchPhrase
         */
        $scope.searchInstitutions = function searchInstitutions(searchPhrase) {
            $scope.searchInProgress = true;
            InstitutionSwitchService.searchInstitutions(searchPhrase).then(function (results) {
                $scope.institutionsDropdownList = results;
                $scope.searchInProgress = false;
            });
        };

        /**
         * Action to do on select a searched institution.
         *
         * @param {Object} institution
         */
        $scope.handleSearchInstitutionClick = function (institution) {
            if (UnsavedChangesFactory.hasChanges()) {
                var modalOptions = {
                    handleNoClick: function () {
                        this.close();
                        UnsavedChangesFactory.clearIsSaved();
                        UnsavedChangesFactory.resetChanges();
                        cont();
                    },
                    handleYesClick: function () {
                        var context = this;
                        UnsavedChangesFactory.setErrorCallback(resetInstitution);
                        UnsavedChangesFactory.handleYesClickFn(context, null, cont);
                    }
                }, modalDefaults = {
                    backdrop: 'static',
                    keyboard: false,
                    modalFade: true,
                    templateUrl: '/scripts/shared/unsavedChanges/unsavedChangesView.html'
                };
                ModalService.show(modalDefaults, modalOptions);
            } else {
                cont();
            }

            function resetInstitution(){
                $scope.institution.selected = lastInstitution;
            }

            function cont() {
                if (!institution || !institution.coreId) {
                    return false;
                }
                if ($state.$current.includes.staff) {
                    $location.path(getDefaultClientPage()).search({coreId: institution.coreId});
                } else {
                    $location.path($location.path()).search({coreId: institution.coreId});
                }
            }

        };

        function getDefaultClientPage() {

            return UserFactory.isClient()? constants.newDefaultClientPage : constants.defaultClientPage;
        }

        /**
         * Actions to do on goto parent institution button click.
         */
        $scope.handleGoToParentInstitution = function () {
            if (!$scope.parentCoreId) {
                return false;
            }

            $location.path(getDefaultClientPage()).search({coreId: $scope.parentCoreId});
        };

        /**
         * Checks if user has access to page
         *
         * @param Array sections
         * @returns {boolean}
         */
        controller.userHasAccessTo = function (sections) {
            var userHasAccessTo = false;
            if (angular.isDefined($localStorage.currentUser) &&
                angular.isArray($localStorage.currentUser.info.accessTo) &&
                $localStorage.currentUser.info.accessTo.length > 0
            ) {
                angular.forEach(sections, function (section) {
                    if ($localStorage.currentUser.info.accessTo.indexOf(section) !== -1) {
                        userHasAccessTo = true;
                        return;
                    }
                });
            }
            return userHasAccessTo;
        };

        controller.isAdmin = function() {
            return UserFactory.isGlobalAdmin();
        };

        controller.userHasAccessToGerBulk = function () {
            var allowUsers = UserFactory.getGerAllowedUsersIds();
            if (angular.isDefined($localStorage.currentUser)) {
                return allowUsers.indexOf($localStorage.currentUser.info.coreId) !== -1;
            }

            return false;
        };

        controller.userHasAccessToStars = function () {
            return InstitutionService.hasAccessToStars(controller.subscribedTo);
        };

        controller.userHasAccessToAny = function (pages) {
            var userHasAccessTo = false;
            angular.forEach(pages, function (page) {
                if (page && controller.userHasAccessTo(page)) {
                    userHasAccessTo = true;
                }
            });
            return userHasAccessTo;
        };

        controller.qsUserHasAccessToClientSections = function () {
            var userHasAccessTo = false;
            angular.forEach(controller.handles, function (handle, key) {
                if (key.indexOf('clients') === 0 && controller.userHasAccessTo(handle)) {
                    userHasAccessTo = true;
                }
            });
            return userHasAccessTo;
        };

        /**
         * Is user allowed to login into institution
         *
         * @param {Object} institutionData
         */
        controller.isInstitutionAllowed = function (institutionData) {
            // institution data is loaded
            if (institutionData && institutionData.typeId) {
                // if it's a simple department - login to parent institution
                if (institutionData.typeId === constants.typeId.simpleDepartmentId) {
                    $location.path($location.path()).search({coreId: institutionData.parentCoreId});
                    return false;
                }
                // Check if user is accessing allowed institution
                if (UserFactory.isClient() && !UserFactory.isAllowedToLogin($localStorage.currentInstitutionId)) {
                    $location.path($location.path()).search({coreId: $localStorage.currentUser.info.primaryInstitution});
                    return false;
                }
                // if the institution is marked as deleted or inactive
                if (
                    institutionData.deleted ||
                    (typeof institutionData.active !== 'undefined' && !institutionData.active)
                ) {
                    // show info message to a user about inactive institution
                    return false;
                }

                return true;
            } else {
                // if its qs user
                if (UserFactory.hasData() && !UserFactory.isClient()) {
                    // send user to qs dashboard
                    controller.resetInstitutionAndSendToQsDashboard();
                    return false;
                }
                // logout user
                AuthenticationService.logout();
                InstitutionFactory.resetData();
                $location.path('/');
                $location.search('coreId', null);
                $location.replace();
                return false;
            }
        };

        controller.handleLogout = function () {
            AuthenticationService.logout();
            };

        /**
         * Check if logged in user is allowed to login to the selected institution
         *
         * @returns {Boolean}
         */
        controller.isAllowed = function () {
            return UserFactory.isAllowedToLogin($localStorage.currentInstitutionId);
        };

        /**
         * Check if client user has access to parent institution
         *
         * @returns {Boolean}
         */
        controller.allowParent = function () {
            var allowedInsitutes = UserFactory.getAllowedInstitutions();
            var hasAccessTo = false;
            if ($scope.parentCoreId) {
                if (UserFactory.isClient()) {
                    allowedInsitutes.forEach(function (item) {
                        if (item === $scope.parentCoreId) {
                            return (hasAccessTo = true);
                        }
                    });
                }
                else {
                    hasAccessTo = true;
                }
                return hasAccessTo;
            }
        };

        /**
         * When user with specific access is logged in
         * and navigates institution that doesn't have that specific access
         *
         * @returns {boolean|*}
         */
        controller.noAccess = function () {
            // if current page is TU/TM and institution doesn't have TU/TM
            if (UserFactory.isClient() &&
                ((typeof $state.current.name !== 'undefined' && $state.current.name.indexOf('.tu') !== -1 && !InstitutionFactory.isTuSubscribed()) ||
                (typeof $state.current.name !== 'undefined' && $state.current.name.indexOf('.tm') !== -1 && !InstitutionFactory.isTmSubscribed()))
            ) {
                return true;
            }

            return UserFactory.noAccess($state.current.name);
        };

        controller.resetInstitutionAndSendToQsDashboard = function () {
            // reset institution to default
            $localStorage.currentInstitutionId = InstitutionFactory.getDefaultCoreId();
            // send user to qs dashboard
            $location.path('/admin/dashboard');
            $location.search('coreId', InstitutionFactory.getDefaultCoreId());
            $location.replace();
        };

        /**
         * help Icon action handler.
         *
         * @param item
         */
        $scope.helpIcon = function (size) {
            var modalOptions = {
                closeButtonText: 'Ok',
                headerText: 'Need help?'

            };
            var modalDefaults = {
                backdrop: true,
                keyboard: true,
                modalFade: true,
                size: size,
                templateUrl: '/scripts/components/modal/helpModal.html'
            };
            ModalService.show(modalDefaults, modalOptions);
        };

        controller.coreIdWatch = function (newCoreId, oldCoreId) {
            if (AuthInterceptor.hasErrors()) {
                return false;
            }
            if (
                constants.publicPages.indexOf($location.path()) !== -1 ||
                (
                    !newCoreId && !UserFactory.hasData() && !AuthenticationService.hasUserData()
                )
            ) {
                return false;
            }
            if (!$localStorage.currentUser) {
                AuthenticationService.logout();
                $state.go('logout');
                
                return false;
            }
            TuProfileFactory.closeSidebars();
            SharedProfileFactory.closeSidebars();

            // if user data is not loaded
            if (!UserFactory.hasData()) {
                // check if user data exists in the session
                if (AuthenticationService.getUserData()) {
                    UserFactory.setData(AuthenticationService.getUserData());
                } else {
                    // temporary fix as this file will be excluded from project.
                    var publicPages = AuthenticationService.getPublicPages(),
                        restrictedPage = publicPages.indexOf($location.path()) === -1;
                    if (restrictedPage) {
                        // send user to login page
                        return $location.path('/');
                    }

                    return true;
                }
            }
            $scope.email = UserFactory.getUserName();
            $scope.profileLogo = UserFactory.getProfileLogo();
            $scope.isClient = UserFactory.isClient();
            //controller.fullName = UserFactory.getFullName();
            // if no Core id in the URL
            if (!newCoreId) {
                // if user is client
                if ($scope.isClient) {
                    if (oldCoreId) {
                        newCoreId = oldCoreId;
                    } else if ($localStorage.currentInstitutionId && controller.isAllowed()) {
                        newCoreId = $localStorage.currentInstitutionId;
                        if (typeof oldCoreId === 'undefined') {
                            oldCoreId = newCoreId;
                        }
                    } else {
                        newCoreId = UserFactory.getPrimaryInstitutionCoreId();
                    }
                } else {
                    if (oldCoreId) {
                        newCoreId = oldCoreId;
                    } else if ($localStorage.currentInstitutionId) {
                        newCoreId = $localStorage.currentInstitutionId;
                    } else {
                        newCoreId = InstitutionFactory.getDefaultCoreId();
                    }
                }
            }
            if ($scope.isClient) {
                if (UserFactory.hasInstitutions()) {
                    $scope.institutionsDropdownList = UserFactory.getInstitutions();
                } else {
                    InstitutionSwitchService.getClientInstitutions().then(function (institutionsList) {
                        // update institutions dropdown options
                        $scope.institutionsDropdownList = institutionsList;
                        UserFactory.setInstitutions(institutionsList);
                    });
                }

                // Check if user has access to new core id, if not, sent to primary institution
                if ($localStorage.currentUser.info.institutions.indexOf(parseInt(newCoreId, 10)) === -1) {
                    newCoreId = $localStorage.currentUser.info.primaryInstitution;
                }
                if (newCoreId && parseInt(newCoreId, 10) !== parseInt(oldCoreId, 10)) {
                    $location.search('coreId', newCoreId);
                }
            }
            $localStorage.currentInstitutionId = newCoreId;
            if (UserFactory.getTitle()) {
                controller.fullName =  UserFactory.getTitle() + ' ' + UserFactory.getFullName();
            }
            else {
                controller.fullName = UserFactory.getFullName();
            }
            

            controller.profileLogo = function () {
                return UserFactory.getProfileLogo();
            };
            if (newCoreId && parseInt(newCoreId, 10) === parseInt(oldCoreId, 10) && newCoreId === InstitutionFactory.getCoreId() && InstitutionFactory.hasData()) {
                processInstitutionData();
            } else {
                InstitutionsService.getInstitutionData(newCoreId, false, true).then(function (data) {
                    var institutionData = data && data.results ? data.results[0] : null;
                    if (institutionData) {
                        if(!angular.equals(institutionData, InstitutionFactory.getData())){
                            InstitutionFactory.setData(institutionData);
                        }
                        processInstitutionData();
                    }
                    $timeout(function (){
                        controller.isActiveCoreId = InstitutionFactory.isActive();
                        controller.isDisabled = !InstitutionFactory.isActive() && $state.includes('clients');
                        if (institutionData && institutionData.typeId !== constants.typeId.simpleDepartmentId) {
                            controller.isValidSchool = true;
                        } else if (!institutionData) {
                            controller.isValidSchool = true;
                        }
                        if (UserFactory.isClient()) {
                            if (institutionData && institutionData.typeId !== constants.typeId.advancedProgramId) {
                                controller.isTmAdvanced = false;
                            } else {
                                controller.isTmAdvanced = !!InstitutionFactory.isTmAdvanced();
                                controller.isValidSchool = false;
                            }
                        }
                    });
                });
            }
        };

        function processInstitutionData() {
            controller.subscribedTo = InstitutionFactory.getSubscriptions();
            controller.isInstitutionAllowed(InstitutionFactory.getData());
            controller.loadSubscriptions();

            var displayName = InstitutionFactory.getDisplayName();
            if (!UserFactory.isClient()) {
                displayName = displayName + ' [' + InstitutionFactory.getCoreId() + ']';
            }
            if (InstitutionFactory.hasData() && InstitutionFactory.getParentName()) {
                $scope.institution.selected = {
                    name: displayName,
                    coreId: InstitutionFactory.getCoreId(),
                    parentInstitutionName: InstitutionFactory.getParentName()
                };
            } else {
                $scope.institution.selected = {
                    name: displayName,
                    coreId: InstitutionFactory.getCoreId()
                };
            }
            $scope.parentCoreId = InstitutionFactory.getParentCoreId();
        }

        controller.releaseVersionWatch = function (releaseVersion) {
            AppUpdateMessageFactory.setVisible(ReleaseVersionService.isVisible(releaseVersion));
        };

        controller.initWatches = function () {
            // watch for coreId changes in the URL
            WatchService.create($scope, function () {
                return $location.search().coreId;
            }, function (newVal, oldVal) {
                controller.coreIdWatch(newVal, oldVal);
            });
            if (typeof $localStorage.currentUser !== 'undefined') {
                WebSocketsService.subscribe('release', 'version', controller.releaseVersionWatch);
            }

            // watch for Username changes changes at profile page
            WatchService.groupWatch($scope, [UserFactory.getTitle, UserFactory.getFullName], function (values) {
                if (UserFactory.getTitle()) {
                    controller.fullName = values.join(" ");
                }
                else {
                    controller.fullName = UserFactory.getFullName();
                }
            });

            // watch for subscriptions changes
            WatchService.create($scope, InstitutionFactory.getSubscriptions, function (data) {
                if (data) {
                    controller.subscribedTo = data;
                    controller.loadSubscriptions();
                }
            });

            // watch for Username changes changes at profile page
            WatchService.create($scope, UserFactory.getProfileLogo, function (profileLogo) {
                if (profileLogo) {
                    $scope.profileLogo = profileLogo;
                }
            });

            WatchService.create($scope, 'institution.selected', function(newVal, oldVal){
                lastInstitution = oldVal;
            }, true);
        };

        controller.loadSubscriptions = function () {
            InstitutionsListService.getSubscriptions().then(function (response) {
                if (!response || !response.length) {
                    return false;
                }
                controller.subscribedRankingsList = [];
                angular.forEach(response, function (item) {
                    if (item.handle !== 'all' &&
                        item.handle !== 'stars' &&
                        controller.subscribedTo[item.handle] &&
                        controller.subscribedTo[item.handle].subscribed
                    ) {
                        controller.subscribedRankingsList.push(item);
                    }
                });
            });
        };

        controller.init = function () {
            controller.initWatches();
        };
        controller.init();
    }
}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers:{}});

    App.controllers.Login = function (
        $rootScope,
        $scope,
        $location,
        $localStorage,
        $state,
        constants,
        AuthenticationService,
        PasswordService,
        UserFactory,
        InstitutionFactory,
        NotifierFactory,
        InstitutionsListService
    ) {
        var controller = this;
        $scope.forms = {};
        $scope.credentials = {};
        $scope.forgottenPassword = {};
        $scope.showForgotPassword = false;
        $scope.showResetPassword = false;
        $scope.showResetPasswordConfirmation = false;
        $scope.showAfterResetPasswordConfirmation = false;
        $scope.showLoginForm = false;
        $scope.loginInProgress = false;
        $scope.animate = false;
        controller.getPasswordValidator = function () {
            return [
                {name: 'Minimum 8 characters', status: false}, // 0
                {name: 'Maximum 32 characters', status: false}, // 1
                {name: 'Lowercase letter (a-z)', status: false}, // 2
                {name: 'Uppercase letter (A-Z)', status: false}, // 3
                {name: 'Number (0-9)', status: false}, // 4
                {name: 'Special characters', status: false}, // 5
                {name: 'Both passwords match', status: false} // 6
            ];
        };

        controller.isPasswordValid = function () {
            var result = true;
            angular.forEach($scope.validationItems, function (item) {
                if (result && !item.status) {
                    result = false;
                }
            });

            return result;
        };

        controller.errors = {
            missingRequired: 'Username and password are required',
            invalidUsername: 'Please enter a valid username',
            incorrectCredentials: 'Username or password is incorrect',
            accountNotComplete: 'Your account setup is not completed',
        };

        if ($state.current.data.page === 'forgot-password' || $state.current.data.page === 'password-reset') {
            $scope.showResetPassword = false;
            $scope.showForgotPassword = true;
            $scope.showResetPasswordConfirmation = false;
            $scope.showAfterResetPasswordConfirmation = false;
        }

        if ($state.current.data.page === 'reset-password') {
            $scope.showResetPassword = true;
            $scope.showForgotPassword = false;
            $scope.showResetPasswordConfirmation = false;
            $scope.showAfterResetPasswordConfirmation = false;
            $scope.validationItems = controller.getPasswordValidator();
            $scope.password = null;
            $scope.repeatPassword = null;
        }

        $scope.validatePassword = function () {
            if ($scope.showResetPassword) {
                $scope.validationItems = controller.getPasswordValidator();
                var pattern = null;
                // 0
                if ($scope.forms.resetPasswordForm.password.$viewValue !== null &&
                    $scope.forms.resetPasswordForm.password.$viewValue.length > 7
                ) {
                    $scope.validationItems[0].status = true;
                }
                // 1
                if ($scope.forms.resetPasswordForm.password.$viewValue !== null &&
                    $scope.forms.resetPasswordForm.password.$viewValue.length < 33 &&
                    $scope.forms.resetPasswordForm.password.$viewValue.length > 0
                ) {
                    $scope.validationItems[1].status = true;
                }
                // 2
                pattern = new RegExp(/(?=.*?[a-z])/);
                if (pattern.test($scope.forms.resetPasswordForm.password.$viewValue)
                ) {
                    $scope.validationItems[2].status = true;
                }
                // 3
                pattern = new RegExp(/(?=.*?[A-Z])/);
                if (pattern.test($scope.forms.resetPasswordForm.password.$viewValue)
                ) {
                    $scope.validationItems[3].status = true;
                }
                // 4
                pattern = new RegExp(/(?=.*?[0-9])/);
                if (pattern.test($scope.forms.resetPasswordForm.password.$viewValue)
                ) {
                    $scope.validationItems[4].status = true;
                }
                // 5
                pattern = new RegExp(/(?=.*?[\s\!\"\#\$\%\&\'\(\)\*\+\,\-\.\/\:\;<\=\>\?\@\[\\\]\^\_\`\{\|\}\~\£\¬\€])/);
                if (pattern.test($scope.forms.resetPasswordForm.password.$viewValue)
                ) {
                    $scope.validationItems[5].status = true;
                }
                // 6
                if ((angular.isDefined($scope.forms.resetPasswordForm.password.$viewValue) &&
                    angular.isDefined($scope.forms.resetPasswordForm.repeatPassword.$viewValue)) &&
                    angular.equals(
                        $scope.forms.resetPasswordForm.password.$viewValue,
                        $scope.forms.resetPasswordForm.repeatPassword.$viewValue
                    ) && ($scope.forms.resetPasswordForm.password.$viewValue.length > 0 &&
                    $scope.forms.resetPasswordForm.repeatPassword.$viewValue.length > 0)) {
                    $scope.validationItems[6].status = true;
                }
            }
        };

        $scope.handleForgotPasswordClick = function () {
            $scope.showResetPasswordConfirmation = false;
            $scope.resetSubmitInProgress = false;
            $scope.email = null;
            $scope.showForgotPassword = !$scope.showForgotPassword;
            if ($scope.showResetPassword) {
                $scope.showForgotPassword = false;
                $scope.showResetPassword = false;
                $state.go('logout');
            } else {
                $scope.showForgotPassword = !$scope.showForgotPassword;
                if ($scope.showForgotPassword) {
                    $state.go('forgot-password');
                } else {
                    $state.go('logout');
                }
            }
        };

        controller.showError = function (errorMessage) {
            $scope.animate = true;
            $scope.error = errorMessage;
            setTimeout(function() {
                $scope.animate = false;
            }, 1000);
        };

        /**
         * Reset errors.
         */
        $scope.resetErrors = function () {
            $scope.error = false;
        };

        /**
         * Actions to do when login button is clicked.
         *
         * @returns {boolean}
         */
        $scope.handleLogin = function () {
            $scope.forms.loginForm.$setSubmitted();
            if (
                $scope.loginInProgress ||
                !$scope.forms.loginForm ||
                !$scope.forms.loginForm.$valid
            ) {
                var error = $scope.forms.loginForm.$error.email ?
                    controller.errors.invalidUsername : controller.errors.missingRequired;
                controller.showError(error);

                return false;
            }
            $scope.loginInProgress = true;
            $scope.animate = false;
            AuthenticationService.login(
                $scope.credentials.username,
                $scope.credentials.password
            ).then(controller.loginCallback);
        };

        /**
         * Actions to do on login callback.
         *
         * @param {boolean} response
         */
        controller.loginCallback = function (response) {
            if (angular.equals(response, true)) {
                // if (UserFactory.isPasswordReset()) {
                //     // send user to my profile
                //     //return $state.go('user.my-profile');
                // }
                var redirectUrl = $location.search().url,
                    coreId = null,
                    requestedStateBeforeLogin = UserFactory.getRequestedStateBeforeLogin();
                   

                if (UserFactory.isClient()) {
                    coreId = UserFactory.getPrimaryInstitutionCoreId();
                    // if user has access to institution details page, send him there after login
                    if (!angular.isDefined(redirectUrl) &&
                        UserFactory.hasAccessToInstitutionDetails()
                    ) {
                        redirectUrl = constants.newDefaultClientPage;
                        if (requestedStateBeforeLogin && requestedStateBeforeLogin.name !== "logout") {
                            redirectUrl = requestedStateBeforeLogin.url;
                            if( redirectUrl.indexOf('profiles') === -1) {
                                if (requestedStateBeforeLogin.name.indexOf('profiles') !== -1) {
                                    redirectUrl = "profiles" + redirectUrl;
                                }
                                else if (requestedStateBeforeLogin.name.indexOf('statistics') !== -1) {
                                    redirectUrl = "statistics" + redirectUrl;
                                }
                            }
                         }
                       
                    } else {
                        if (angular.isDefined(redirectUrl) &&
                            redirectUrl.indexOf('profiles') === -1
                        ) {
                            // Get subscriptions for the primary institutuion
                            InstitutionsListService.getInstitution(coreId).then(function (response) {
                                var allowState = UserFactory.getFirstAllowedState(response.subscriptions);
                                // otherwise, send user to the first page in his access list
                                if (!angular.equals('/', allowState)) {
                                    $state.go(allowState);
                                } else {
                                    $scope.animate = false;
                                    $scope.loginInProgress = false;
                                    controller.showError(controller.errors.accountNotComplete);

                                    return false;
                                }

                                return true;
                            });
                        } else {
                            InstitutionsListService.getInstitution(coreId).then(function (response) {
                                var allowState = UserFactory.getFirstAllowedState(response.subscriptions);
                                $state.go(allowState);
                                return true;
                            });
                        }
                    }
                    if (!UserFactory.hasAccess()) {
                        $scope.loginInProgress = false;
                        redirectUrl = '/';
                        controller.showError('Your account setup is not completed');
                    }
                } else {
                  
                    if ($localStorage.currentInstitutionId) {
                        coreId = $localStorage.currentInstitutionId;
                    } else {
                        coreId = InstitutionFactory.getDefaultCoreId();
                    }
                    
                    if (redirectUrl) {
                        // Check if the QS user has access to the area matching the
                        var urlStates = $state.get().map(function(state) {
                                var result = {};
                                if ($state.href(state.name) && !state.abstract) {
                                    result[$state.href(state.name).replace('#', '')] = state.name;
                                } else {
                                    result = null;
                                }
                                return result;
                            }).filter(function(e){return e;}),
                            resultUrlStates = {},
                            keyResult = '';

                        angular.forEach(urlStates, function (value, key) {
                            keyResult = Object.keys(value)[0];
                            resultUrlStates[keyResult] = value[keyResult];
                        });
                        var qsUserhasAccess = userHasAccessTo(UserFactory.getHandles()[resultUrlStates[redirectUrl]]);
                        if (qsUserhasAccess) {
                            $location.url(redirectUrl);
                        } else {
                            redirectUrl = '/admin/dashboard';
                            if (requestedStateBeforeLogin && requestedStateBeforeLogin.name !== "logout") {
                                redirectUrl = requestedStateBeforeLogin.url;
                             }
                        }
                    } else {
                        InstitutionsListService.getInstitution(coreId).then(function (response) {
                            var state = 'staff.dashboard';
                            if (requestedStateBeforeLogin && requestedStateBeforeLogin.name !== "logout") {
                                state = requestedStateBeforeLogin.name;
                            }
                            if (state.indexOf('data-submission') !== -1) {
                                $state.go(state,UserFactory.getRequestedStateParams());
                            }
                            else {
                                $state.go(state);
                            }
                        });

                        return true;
                    }
                }
                var path = UserFactory.isClient() ? constants.newDefaultClientPage : constants.defaultClientPage;
                redirectUrl = redirectUrl ? redirectUrl : path;
                redirectUrl = decodeURIComponent(redirectUrl);
                $location.path(redirectUrl).search({coreId: coreId});
            } else {
                $scope.animate = true;
                $scope.error = 'Username or password is incorrect';
                $scope.loginInProgress = false;
                var displayMessage = controller.errors.invalidUsername;
                if (response.hasOwnProperty('message')) {
                    displayMessage = response.message;
                }
                controller.showError(displayMessage);
            }
        };

        /**
         * Checks if user has access to page
         *
         * @param array sections
         * @returns {boolean}
         */
        function userHasAccessTo(sections) {
            var userHasAccessTo = false;
            if (angular.isDefined($localStorage.currentUser) &&
                angular.isArray($localStorage.currentUser.info.accessTo) &&
                $localStorage.currentUser.info.accessTo.length > 0
            ) {
                angular.forEach(sections, function (section) {
                    if ($localStorage.currentUser.info.accessTo.indexOf(section) !== -1) {
                        userHasAccessTo = true;
                        return;
                    }
                });
            }
            return userHasAccessTo;
        }

        /**
         * Actions to do when send password button is clicked.
         *
         * @returns {boolean}
         */
        $scope.handleResetPasswordSubmitClick = function () {
            $scope.forgottenPasswordError = false;
            $scope.animate = false;
            if (
                $scope.resetSubmitInProgress ||
                !$scope.forms.forgotPasswordForm ||
                !$scope.forms.forgotPasswordForm.$valid
            ) {
                $scope.forgottenPasswordErrorMessage = "Enter a valid email";
                $scope.animate = true;
                $scope.forgottenPasswordError = true;
                return false;
            }
            $scope.resetSubmitInProgress = true;
            PasswordService.create($scope.forgottenPassword.email).then(controller.resetPasswordCallback);
        };

        controller.resetPasswordCallback = function (response) {
            var responseObject = {
                'status' : false,
                'message' : 'Request failed, please try again'
            };
            if (response && response.hasOwnProperty('status')) {
                responseObject.status = response.status;
                if (!response.status) {
                    responseObject.message = response.message;
                }
            }
            $scope.resetSubmitInProgress = false;
            if (response.status) {
                // display confirmation
                $scope.showResetPasswordConfirmation = true;
                $scope.animate = false;
                $scope.forgottenPasswordError = false;
            } else {
                $scope.animate = true;
                $scope.forgottenPasswordError = true;
                $scope.forgottenPasswordErrorMessage = responseObject.message;
            }
        };

        controller.updateResetCallback = function (response) {
            $scope.resetSubmitInProgress = false;
            if (response) {
                // login user with controller.loginPassword
                // response will contain the email and the status
                NotifierFactory.show(
                    'success',
                    'New Password Saved',
                    'Password Reset'
                );
                AuthenticationService.login(response.email, controller.loginPassword).then(controller.loginCallback);
            }
        };

        $scope.handleResetSubmitPasswordSubmitClick = function () {
            if ($scope.resetSubmitInProgress ||
                !$scope.forms.resetPasswordForm ||
                !$scope.forms.resetPasswordForm.$valid
            ) {
                return false;
            }
            if (!controller.isPasswordValid()) {
                return false;
            }

            $scope.resetSubmitInProgress = true;
            controller.loginPassword = $scope.forms.resetPasswordForm.password.$modelValue;
            PasswordService.update($scope.requestId, controller.loginPassword).then(controller.updateResetCallback);
        };

        $scope.toggleForgottenPasswordForm = function () {
            $scope.forgottenPassword.email = '';
            $scope.showResetPasswordConfirmation = false;
            $scope.showForgotPassword = !$scope.showForgotPassword;
            $scope.forgottenPasswordError = false;
            $scope.animate = false;
            $scope.forms.forgotPasswordForm.$setPristine();
            if($state.current.name === 'password-reset') {
                $location.path('/');
            }
        };

        controller.destruct = function () {
            if($state.current.name !== 'password-reset') {
                $location.path('/');
            }
        };

        controller.init = function () {
            // reset login status
            AuthenticationService.logout();
            AuthenticationService.setXLocalStorageLogout();
            InstitutionFactory.resetData();
            $rootScope.$on(constants.events.logout, controller.destruct);
            if (window && window.location && window.location.hostname) {
                switch (window.location.hostname) {
                    case 'localhost':
                        $scope.showLoginForm = true;
                        break;
                }
            }
        };

        controller.init();
    };

    angular
        .module('qsHub')
        .controller('LoginController', [
            '$rootScope',
            '$scope',
            '$location',
            '$localStorage',
            '$state',
            'constants',
            'AuthenticationService',
            'PasswordService',
            'UserFactory',
            'InstitutionFactory',
            'NotifierFactory',
            'InstitutionsListService',
            App.controllers.Login
        ]);

}(window.angular));

(function(angular) {
    'use strict';

    angular
        .module('qsHub')
        .controller('SidebarController', [
            '$window',
            'constants',
            'UserFactory',
            Controller
        ]);

    function Controller($window, constants , UserFactory) {
        var controller = this;
        controller.hasTuProgramsOverviewAccess = function () {
            return UserFactory.hasTuProgramsOverviewAccess();
        };
        controller.hasDepartmentsOverviewAccess = function () {
            return UserFactory.hasDepartmentsOverviewAccess();
        };
        controller.showQaSection = constants.env !== 'live' && !UserFactory.isClient();
    }
}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    /**
     * Service for comun functionality between InstitutionsUsersListService and QsUsersListService
     */
    App.services.usersList = function () {
        var service = {};

        /**
         * Get contact type name by its handle.
         *
         * @param handle
         * @param contactTypesList
         * @returns {string}
         */
        service.getContactTypeNameByHandle = function (handle, contactTypesList) {
            var contactTypeName = '';
            if (contactTypesList) {
                angular.forEach(contactTypesList, function(contactType) {
                    if (contactType && contactType.handle && contactType.name && contactType.handle === handle) {
                        contactTypeName = contactType.name;
                        return;
                    }
                });
            }

            return contactTypeName;
        };

        /**
         * Format sections list.
         *
         * @param {Object} sectionsList
         * @returns {Array}
         */
        service.formatSections = function (sectionsList) {
            var flatArray = [],
                firstElement;

            angular.forEach(sectionsList, function (section) {
                firstElement = true;
                angular.forEach(section.pages, function (page) {
                    flatArray.push({
                        'sectionName' : firstElement ? section.name : null,
                        'pageName' : page.name,
                        'pageHandle' : section.handle + '.' + page.handle,
                        'disabled' : page.handle === 'dashboard',
                        'assigned' : page.handle === 'dashboard'
                    });
                    firstElement = false;
                });
            });
            return flatArray;
        };

        /**
         * Assing sections and pages based on user's roles
         *
         * @param {object} userData
         * @param {object} roleList
         * @returns {object}
         */
        service.updateSectionsAndPages = function (userData, roleList) {
            if (roleList !== 'undefined') {
                if (userData.roles &&
                    userData.roles.custom !== 'undefined' &&
                    !userData.roles.custom
                ) {
                    for (var userRole in userData.roles) {
                        if (userData.roles[userRole]) {
                            for (var role in roleList) {
                                if (roleList[role]) {
                                    if (roleList[role].roleHandle !== userRole) {
                                        continue;
                                    }
                                    for (var section in roleList[role].sections) {
                                        if (roleList[role].sections[section]) {
                                            userData.accessTo[roleList[role].sections[section]] = true;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            return userData;
        };

        /**
         * Select all the role children for those users that have All selected and not all the children selected on load
         *
         * @param {object} userData
         * @param {object} roleList
         * @returns {object}
         */
        service.updateRolesWithAll = function (userData, roleList) {
            var childrenRolesHandles = null,
                groupHandle = null;

            for (var userRole in userData.roles) {
                if (userRole.indexOf("all") !== -1 &&
                    userData.roles[userRole]
                ) {
                    groupHandle = userRole.replace('.all', '');
                    childrenRolesHandles = service.getChildrenRoles(groupHandle, roleList);
                    if (childrenRolesHandles) {
                        for (var childrenRoleHandle in childrenRolesHandles) {
                            if (childrenRoleHandle) {
                                userData.roles[childrenRolesHandles[childrenRoleHandle]] = true;
                            }
                        }
                    }
                }
            }
            return userData;
        };

        /**
         * Gets children roles for a given parent (all) role
         *
         * @param {string} groupHandle
         * @param {object} rolesList
         */
        service.getChildrenRoles = function (groupHandle, rolesList) {
            var childrenRoles = [];
            angular.forEach(rolesList, function (role) {

                if (role.all || role.groupHandle !== groupHandle) {
                    return false;
                }

                childrenRoles.push(role.roleHandle);
            });

            return childrenRoles;
        };

        /**
         * Gets parent role for a given child role
         *
         * @param {string} groupHandle
         * @param {object} rolesList
         */
        service.getParentRoleHandle = function (groupHandle, rolesList) {
            var parentRoleHandle = null;

            if (rolesList) {
                angular.forEach(rolesList, function (role) {
                    if (!role.all || role.groupHandle !== groupHandle) {
                        return false;
                    }
                    parentRoleHandle = role.roleHandle;
                });
            }

            return parentRoleHandle;
        };

        return service;
    };

    angular
        .module('qsHub')
        .service('UsersListService', [
            App.services.usersList
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    App.services.userTypes = function (
    ) {
        var service = {
            userTypes: [
                {
                    uniqueId: 1,
                    name: 'Contact',
                    disabled: true
                },
                {
                    uniqueId: 2,
                    name: 'User',
                    disabled: false
                }
            ]
        };

        service.getUserTypes = function () {
            return service.userTypes;
        };

        return service;
    };

    angular
        .module('qsHub')
        .service('UserTypesService', [
            App.services.userTypes
        ]);
}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers:{}});
    App.controllers.Password = function (
        $scope,
        PasswordService,
        WatchService
    ) {
        var controller = this;

        controller.defaultConfig = {
            resetOnLogin: true,
            label: null,
            labelClass: null
            //blockClass: 'row'
        };
        PasswordService.setHasErrors(true);
        controller.isValid = function () {
            return PasswordService.isPasswordValid(controller.user.password);
        };

        controller.isRequired = function () {
            return controller.user && controller.user.id || controller.user.coreId ? false : true;
        };

        controller.handleChanges = function () {
            controller.isValid();
            controller.user.password = controller.user.password.split(' ').join('');
            controller.validators = PasswordService.validatePassword(controller.user.password);
            PasswordService.setHasErrors(!!controller.user.password.length);
        };

        controller.initValidators = function () {
            // get validation rules without password match
            var validators = PasswordService.getPasswordValidator();
            controller.validators = validators.splice(0, validators.length - 1);
        };

        controller.userWatch = function () {
            controller.initValidators();
        };

        controller.initWatches = function () {
            WatchService.create($scope, function () {
                return controller.user;
            }, controller.userWatch);

            WatchService.create($scope, function () {
                return controller.resetValidators;
            }, controller.userWatch);

            //watch for password entered
            WatchService.create($scope, PasswordService.getHasErrors, function (newValue) {
                newValue = controller.isRequired() ?  newValue : true;
                controller.isPassLen = newValue;
            });
        };

        controller.$onInit = function () {
            controller.initValidators();
            controller.initWatches();
            controller.cnf = angular.extend({}, controller.defaultConfig, controller.config);
        };
    };

    angular
        .module('qsHub')
        .controller('PasswordController', [
            '$scope',
            'PasswordService',
            'WatchService',
            App.controllers.Password
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {
        components:{}
    });

    App.components.userPassword = {
        templateUrl: '/scripts/components/users/shared/password/passwordView.html',
        bindings: {
            user: '=',
            config: '<',
            resetValidators: '<'
        },
        controller: 'PasswordController'
    };

    angular.module('qsHub').component('userPassword', App.components.userPassword);

}(window.angular));

(function(angular) {
    "use strict";

    angular.module('qsHub').component('readMoreLess', {
        templateUrl: '/scripts/shared/readMoreLess/readMoreLessView.html',
        bindings: {
            limit: '<',
            text: '<'
        },
        bindToController: true,
        controller: 'ReadMoreLessController'
    });

}(window.angular));

(function(angular) {
    "use strict";

    function ReadMoreLess() {
        var controller = this,
            readMore = false;

        controller.showReadMore = function () {
            return controller.text && controller.text.length > controller.getLimit() && !readMore;
        };

        controller.showReadLess = function () {
            return !!readMore;
        };

        controller.handleClick = function () {
            readMore = !readMore;
        };

        controller.getLimit = function() {
            return controller.limit || 200;
        };
    }

    angular
        .module('qsHub')
        .controller('ReadMoreLessController', [
            ReadMoreLess
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers:{}});

    App.controllers.Profile = function (
        $scope,
        WatchService,
        UserFactory,
        AuthenticationService
    ) {
        var controller = this;
        if (!UserFactory.hasData()) {
            UserFactory.setData(AuthenticationService.getUserData());
        }

        WatchService.create($scope, UserFactory.isPasswordReset, function (isPasswordReset) {
            $scope.isPasswordReset = isPasswordReset;
        });
    };

    angular
        .module('qsHub')
        .controller('ProfileController', [
            '$scope',
            'WatchService',
            'UserFactory',
            'AuthenticationService',
            App.controllers.Profile
        ]);

}(window.angular));

/* global logoType */

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers:{}});

    App.controllers.profilePassword = function (
        $scope,
        constants,
        ProfilePasswordService,
        AuthenticationService,
        PasswordService,
        NotifierFactory,
        UserFactory
    ) {
        var controller = this;
        controller.devMode = constants.dev;
        controller.forms = {};
        controller.passwordFieldsVisible = false;
        controller.updateInProgress = false;
        controller.password = null;
        controller.confirmPassword = null;
        controller.submitted = false;

        $scope.user = angular.extend({}, UserFactory.getData());

        controller.getForm = function() {
            return controller.forms.password;
        };

        controller.togglePasswordFields = function () {
            $scope.user.password = null;
            controller.password = null;
            controller.confirmPassword = null;
            controller.passwordFieldsVisible = !controller.passwordFieldsVisible;
        };

        controller.isPasswordFieldsVisible = function () {
            return controller.passwordFieldsVisible;
        };

        controller.isDisabled = function () {
            return controller.updateInProgress;
        };

        controller.update = function () {
            controller.submitted = true;

            // validate program data
            if (controller.updateInProgress ||
                !PasswordService.isPasswordValid($scope.user.password)
            ) {
                NotifierFactory.show(
                    'error',
                    'Please set a valid password'
                );
                return false;
            }
            controller.updateInProgress = true;
            ProfilePasswordService.update($scope.user.password).then(controller.updatePasswordCallback);
        };

        controller.updatePasswordCallback = function (success) {
            controller.updateInProgress = false;
            NotifierFactory.show(
                success ? 'success' : 'error',
                success ? 'Updated successfully!' : 'Update failed!',
                'Password'
            );
            if (success) {
                UserFactory.clearPasswordReset();
                AuthenticationService.setUserData(UserFactory.getData());
                controller.passwordFieldsVisible = false;
            }
        };
    };

    angular
        .module('qsHub')
        .controller('ProfilePasswordController', [
            '$scope',
            'constants',
            'ProfilePasswordService',
            'AuthenticationService',
            'PasswordService',
            'NotifierFactory',
            'UserFactory',
            App.controllers.profilePassword
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    App.services.ProfilePasswordService = function (
        $resource,
        $log,
        constants
    ) {
        var service = {};

        /**
         * Get user update API endpoint.
         *
         * @returns {$resource}
         */
        service.getUpdateModel = function () {
             return $resource(constants.api.usersPermissions.url + '/v1/user-profile', null, {
                update: { method:'PATCH' }
            });
        };

        /**
         * Update user Details | Password.
         *
         * @param {String} password
         * @returns {Promise}
         */
        service.update = function(password) {
            var UpdateModel = service.getUpdateModel(),
                dataToUpdate = {
                    password : password
                };

            return UpdateModel.update(dataToUpdate).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        return service;
    };

    angular
        .module('qsHub')
        .service('ProfilePasswordService', [
            '$resource',
            '$log',
            'constants',
            App.services.ProfilePasswordService
        ]);

}(window.angular));

/* global logoType */

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers:{}});

    App.controllers.ProfilePersonalDetails = function (
        $scope,
        constants,
        ProfilePersonalDetailsService,
        AuthenticationService,
        UserFactory,
        NotifierFactory,
        PasswordService
    ) {
        var controller = this;
        controller.devMode = constants.dev;
        controller.submitted = false;
        controller.updateInProgress = false;
        controller.forms = {};

        $scope.user = angular.extend({}, UserFactory.getData());

        controller.getForm = function() {
            return controller.forms.personalDetails;
        };

        controller.isValidFirstName = function () {
            return PasswordService.isValidFirstName(controller.getForm(), controller.user, 'firstName');
        };

        controller.isValidLastName = function () {
            return PasswordService.isValidLastName(controller.getForm(), controller.user, 'lastName');
        };

        controller.isValid = function () {
            return controller.isValidFirstName() &&
                controller.isValidLastName();
        };

        controller.setValid = function (fieldName) {
            controller.submitted = false;
            return PasswordService.setValid(controller.getForm(), fieldName);
        };
        controller.isDisabled = function () {
            return controller.updateInProgress;
        };

        /**
         * Actions to do when personal details update button is clicked.
         *
         * @returns {boolean}
         */
        controller.update = function () {
            controller.submitted = true;
            controller.getForm().$setDirty();
            controller.getForm().firstName.$setDirty();
            controller.getForm().lastName.$setDirty();

            // validate program data
            if (controller.updateInProgress ||
                !controller.isValid()
            ) {
                return false;
            }

            controller.updateInProgress = true;
            ProfilePersonalDetailsService.update(controller.user, true).then(controller.updateDetailsCallback);
        };

        /**
         * Actions to do when update request is finished.
         *
         * @param {boolean} success
         */
        controller.updateDetailsCallback = function (userObject) {
            controller.updateInProgress = false;
            NotifierFactory.show(
                userObject ? 'success' : 'error',
                userObject ? 'Updated successfully!' : 'Update failed!',
                'Personal Details'
            );
            if (userObject) {
                UserFactory.setFirstName(controller.user.firstName);
                $scope.user.firstName = controller.user.firstName;
                UserFactory.setLastName(controller.user.lastName);
                $scope.user.lastName = controller.user.lastName;
                UserFactory.setFullName(userObject.fullName);
                $scope.user.fullName = controller.user.fullName = userObject.fullName;
                UserFactory.setPosition(controller.user.position);
                $scope.user.position = controller.user.position;
                UserFactory.setTitle(controller.user.title);
                $scope.user.title = controller.user.title;
                if (controller.user.isClient) {
                    UserFactory.setPhone(controller.user.phone);
                    $scope.user.phone = controller.user.phone;
                }
                AuthenticationService.setUserData(UserFactory.getData());
            }
        };

        controller.copyScopeUserToControllerUser = function () {
            controller.user = angular.copy($scope.user);
        };
        controller.copyScopeUserToControllerUser();

        // Personal details cancel button
        controller.cancel = function () {
            controller.copyScopeUserToControllerUser();
        };
    };

    angular
        .module('qsHub')
        .controller('ProfilePersonalDetailsController', [
            '$scope',
            'constants',
            'ProfilePersonalDetailsService',
            'AuthenticationService',
            'UserFactory',
            'NotifierFactory',
            'PasswordService',
            App.controllers.ProfilePersonalDetails
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    App.services.profilePersonalDetailsService = function (
        $resource,
        $q,
        $log,
        constants
    ) {
        var service = {};

        /**
         * Get user update API endpoint.
         *
         * @returns {$resource}
         */
        service.getUpdateModel = function () {
             return $resource(constants.api.usersPermissions.url + '/v1/user-profile', null, {
                update: { method:'PATCH' }
            });
        };

        /**
         * Update user Details | Password.
         *
         * @param {Object} user
         * @returns {Promise}
         */
        service.update = function(user) {
            var UpdateModel = service.getUpdateModel(),
                dataToUpdate = {
                    title: user.title,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    position: user.position
                };
                if (user.isClient) {
                    dataToUpdate.phone = user.phone;
                }

            return UpdateModel.update(dataToUpdate).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return data.user;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        return service;
    };

    angular
        .module('qsHub')
        .service('ProfilePersonalDetailsService', [
            '$resource',
            '$q',
            '$log',
            'constants',
            App.services.profilePersonalDetailsService
        ]);

}(window.angular));

/* global logoType */

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers:{}});

    App.controllers.profileLogo = function (
        $scope,
        constants,
        ProfileLogoService,
        UserFactory,
        NotifierFactory,
        AuthenticationService,
        $timeout
    ) {
        var controller = this;
        controller.devMode = constants.dev;
        $scope.forms = {};
        $scope.gravatar = false;
        $scope.user = UserFactory.getData();

        if (angular.isDefined($scope.user) &&
            $scope.user !== null &&
            !$scope.user.profileLogo
        ) {
            $scope.gravatar = true;
            $scope.user.profileLogo = '';
        }

        $scope.uploadEnabled = true;
        controller.acceptedFiles = 'image/jpeg, image/pjpeg';
        // enable update button
        $scope.generalSubmitDisabled = false;
        $scope.displayRemoveLink = false;
        $scope.profilePic = null;

        /**
         * Actions to do when image is removed.
         */
        $scope.handleRemoveLogoClick = function () {
            $scope.profile[logoType].path = null;
        };

        /**
         * Actions to do when update request is finished.
         *
         * @param {boolean} success
         */
        controller.updateDetailsCallback = function (success) {
            $scope.updateDetailsInProgress = false;
            NotifierFactory.show(
                success ? 'success' : 'error',
                success ? 'Updated successfully!' : 'Update failed!',
                'User Details'
            );
            if (success) {
                UserFactory.announceNameChanges($scope.user);
            }
        };

        /**
         * Modify scope variable on logo remove.
         */
        controller.handleLogoRemoved = function () {
            // show upload area
            $scope.uploadEnabled = true;
            // enable update button
            $scope.generalSubmitDisabled = false;
            // hide remove link below uploader
            $scope.displayRemoveLink = false;
        };

        /**
         * Actions to do when profile form is submitted.
         *
         * @returns {boolean}
         */
        $scope.handleGeneralSubmit = function () {
            $scope.displaySpinner = true;
            if (
                //$scope.generalSubmitDisabled ||
                !$scope.forms.imageForm ||
                !$scope.forms.imageForm.$valid
            ) {
                return false;
            }
            $scope.generalSubmitDisabled = true;
            ProfileLogoService.updateProfilePicture($scope.profilePic).then(function (response) {
                NotifierFactory.show(
                    response.status ? 'success' : 'error',
                    response.status ? 'Saved successfully!' : 'Saving failed!',
                    'User Profile image'
                );
                $scope.displaySpinner = false;
                if (response.status) {
                    $scope.user.profileLogo = response.url;
                    $scope.gravatar = false;
                    UserFactory.setProfileLogo($scope.user.profileLogo);
                } else {
                    if ($scope.user.profileLogo === '' ||
                        !$scope.user.profileLogo ||
                        $scope.user.profileLogo === undefined
                    ) {
                        $scope.user.profileLogo = '';
                        $scope.gravatar = true;
                    }
                }
                $scope.generalSubmitDisabled = false;
                $scope.removeUploaderImage();
            });
        };

        /**
         * Remove dropZone files
         */
        controller.removeDropZoneUploadedImage = function () {
            if (controller.dropZoneImageInstance &&
                controller.dropZoneImageInstance.file &&
                (controller.dropZoneImageInstance.file.length > 1)) {
                controller.dropZoneImageInstance.removeFile(controller.dropZoneImageInstance.files[0]);
            } else {
                controller.dropZoneImageInstance.removeAllFiles();
                $scope.displayRemoveLink = false;
            }
        };

        /**
         * Set of actions to remove uploader image
         */
        $scope.removeUploaderImage = function () {
            if (!$scope.generalSubmitDisabled) {
                $timeout(function() {
                    controller.removeDropZoneUploadedImage();
                    controller.handleLogoRemoved();
                });
            } else {
                NotifierFactory.show(
                    'info',
                    'Image upload in progress, please wait.',
                    ''
                );
            }
        };

        /**
         * DropZone init.
         */
        controller.handleInit = function () {
            this.isValid = true;
            this.globalAccept = false;
            this.localAccept = false;
        };

        /**
         * DropZone handle accept.
         *
         * @param file
         * @param done
         */
        controller.handleAccept = function(file, done) {
            file.acceptFile = function () {
                $scope.uploadEnabled = false;
                $scope.displayRemoveLink = !$scope.displayRemoveLink;
                $scope.$apply();
                done();
            };
        };

        /**
         * Actions to do when file is uploaded to server.
         *
         * @param {Object} file - instance of dropzone file
         * @param {Object} response - server response object
         */
        controller.handleImageUploaded = function(file, response) {
            $scope.$apply(function() {
                $scope.generalSubmitDisabled = false;
                $scope.profilePic = response.path;
                // display link below uploader
                $scope.displayRemoveLink = true;
            });
        };

        /**
         * Actions to do when file uploaded fails.
         */
        controller.handleImageUploadError = function(error) {
            var errorMessage = error.xhr !== undefined ? JSON.parse(error.xhr.responseText) : false;
            errorMessage = errorMessage ? errorMessage.message : 'Image upload failed!';
            if (this.files.length > 0) {
                this.removeFile(this.files[0]);
            }
            $scope.$apply(function() {
                $scope.displayRemoveLink = false;
                $scope.generalSubmitDisabled = false;
                NotifierFactory.show(
                    'error',
                    errorMessage,
                    'Profile image'
                );
            });
        };

        /**
         * Actions to do when file is added to upload queue.
         */
        controller.handleImageAdded = function (file) {
            $scope.displayRemoveLink = true;
            file.rejectExtension = function () {
                NotifierFactory.show(
                    'error',
                    'Allowed types: ' + controller.acceptedFiles,
                    'File type is unacceptable.'
                );
                controller.removeDropZoneUploadedImage();
                controller.handleLogoRemoved();
            };
            if (this.files.length === 1) {
                this.globalAccept = false;
                this.localAccept = false;
            }
            if (!this.globalAccept && !this.localAccept && this.files.length === 1) {
                this.isValid = true;
                this.localAccept = true;
            }
            if (this.files.length > 1 && this.globalAccept) {
                this.isValid = false;
                this.globalAccept = true;
                this.removeFile(this.files[1]);
                $scope.$apply();
            }
            this.isValid = true;
            // Validate image extension for drag and drop
            var allowedExtensionsArray = controller.acceptedFiles.split(',');
            if (allowedExtensionsArray.indexOf(file.type) === -1) {
                this.isValid = false;
                file.rejectExtension();
            }
            if (this.isValid) {
                // disable update button
                $scope.generalSubmitDisabled = true;
                // hide upload area
                $scope.uploadEnabled = false;
            }
        };

        /**
         * DropZone handle thumbnail.
         *
         * @param file
         */
        controller.handleThumbnail = function(file) {
            file.rejectDimensions = function (globalAccept) {
                globalAccept = (typeof globalAccept === 'undefined') ? true : globalAccept;
                if (!globalAccept) {
                    NotifierFactory.show(
                        'error',
                        'Image dimension is incorrect.',
                        'Please upload 100px by 100px image.'
                    );
                    controller.removeDropZoneUploadedImage();
                    controller.handleLogoRemoved();
                    $scope.$apply();
                }
            };
            file.rejectSize = function (globalAccept) {
                globalAccept = (typeof globalAccept === 'undefined') ? true : globalAccept;
                if (!globalAccept) {
                    NotifierFactory.show(
                        'error',
                        'File size has exceeded the 400 KB limit.',
                        'Please upload again'
                    );
                    $scope.$apply();
                }
            };
            // Validate image dimensions
            if (file.height < 100 || file.width < 100) {
                this.isValid = false;
                file.rejectDimensions(this.globalAccept);
            }
            // Validate image size
            if (file.size > 400000) {
                this.isValid = false;
                file.rejectSize(this.globalAccept);
            }
            if (this.isValid && !this.globalAccept && this.localAccept) {
                this.globalAccept = true;
                file.acceptFile(this.localAccept);
            } else {
                $scope.generalSubmitDisabled = false;
                if (this.files.length > 1 && this.globalAccept) {
                    this.removeFile(this.files[1]);
                }
            }
            if (false === this.isValid) {
                this.isValid = true;
            }

        };

        controller.imageConfig = {
            // http://www.dropzonejs.com/#configuration-options
            dropzone: {
                url: ProfileLogoService.getUrl(),
                maxFilesize: 0.4,
                maxFiles: 1,
                uploadMultiple: 0,
                parallelUploads: 1,
                autoProcessQueue: true,
                addRemoveLinks: false,
                acceptedFiles: controller.acceptedFiles,
                previewsContainer: '.dropzone-previews',
                headers: AuthenticationService.getAuthorizationHeader(),
                thumbnailWidth: 100,
                thumbnailHeight: 100,
                clickable: '.upload-button',
                paramName: "file",
                init: controller.handleInit,
                accept: controller.handleAccept
            },
            // http://www.dropzonejs.com/#event-list
            eventHandlers: {
                success: controller.handleImageUploaded,
                addedfile: controller.handleImageAdded,
                thumbnail: controller.handleThumbnail,
                error: controller.handleImageUploadError
            }
        };

        controller.initWatches = function () {
            // watch for Username changes changes at profile page
            $scope.$watch(UserFactory.getData, function (data) {
                if (data) {
                    $scope.user = data;
                }
            });
        };

        controller.init = function () {
            controller.initWatches();
        };

        controller.init();
    };

    angular
        .module('qsHub')
        .controller('ProfileLogoController', [
            '$scope',
            'constants',
            'ProfileLogoService',
            'UserFactory',
            'NotifierFactory',
            'AuthenticationService',
            '$timeout',
            App.controllers.profileLogo
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    App.services.ProfileLogo = function (
        $resource,
        $q,
        $log,
        constants
    ) {
        var service = {};

        service.getUrl = function () {
            return constants.api.usersPermissions.url + '/v1/user-profile-logo';
        };

        /**
         * Get user update API endpoint.
         *
         * @returns {$resource}
         */
        service.getUpdateModel = function () {
             return $resource(service.getUrl(), null, {
                update: { method:'PATCH' }
            });
        };

        /**
         * Update user.
         *
         * @param {String} logoUrl
         * @returns {Promise}
         */
        service.updateProfilePicture = function (logoUrl) {
            var UpdateModel = service.getUpdateModel();
            return UpdateModel.update({
                userImage: logoUrl
            }).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return {
                    status: data.$resolved,
                    url: data.url
                };
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        return service;
    };

    angular
        .module('qsHub')
        .service('ProfileLogoService', [
            '$resource',
            '$q',
            '$log',
            'constants',
            App.services.ProfileLogo
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {
        components:{}
    });

    App.components.appUpdate = {
        templateUrl: '/scripts/shared/appUpdateMessage/appUpdateMessageView.html',
        controller: 'AppUpdateMessageController'
    };

    angular.module('qsHub').component('appUpdate', App.components.appUpdate);

}(window.angular));




(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers:{}});
    App.controllers.AppUpdate = function (
        AppUpdateMessageFactory
    ) {
        var controller = this;

        controller.isVisible = function () {
            return AppUpdateMessageFactory.isVisible();
        };
    };

    angular
        .module('qsHub')
        .controller('AppUpdateMessageController', [
            'AppUpdateMessageFactory',
            App.controllers.AppUpdate
        ]);

}(window.angular));

(function(angular) {
    'use strict';

    var App = App || {};
    App = angular.extend({}, App, {factories:{}});

    App.factories.AppUpdateMessage = function () {
        var visible;
        return {
            setVisible: function (isVisible) {
                visible = typeof isVisible === 'undefined' ? true : isVisible;
            },
            isVisible: function () {
                return !!visible;
            }
        };
    };

    angular
        .module('qsHub')
        .factory('AppUpdateMessageFactory', [
            App.factories.AppUpdateMessage
        ]);

}(window.angular));

(function (angular) {
    'use strict';

    angular
        .module('qsHub')
        .directive('isValidFloat', [
            'IsValidFloatService',
            Directive
        ]);

    function Directive(
        IsValidFloatService
    ) {
        var message = 'Please enter a numeric value with up to 2 decimal places',
            errorSelector = 'invalid-float';

        return {
            restrict: 'A',
            require: 'ngModel',
            link: function ($scope, $elem, $attrs) {
                // init element popover
                $($elem).popover({
                    trigger: 'hover',
                    html: true,
                    content: '',
                    container: '',
                    placement: 'bottom',
                    delay: 250
                });

                $scope.$watch($attrs.ngModel, function (value) {
                    if (IsValidFloatService.isValid(value)) {
                        $elem.context.parentElement.className = $elem.context.parentElement.className.replace(errorSelector, '');
                        $($elem).data('bs.popover').options.content = '';
                        $($elem).popover('hide');
                    } else {
                        if ($elem.context.parentElement.className.indexOf(errorSelector) === -1) {
                            $elem.context.parentElement.className += ' ' + errorSelector;
                            $($elem).data('bs.popover').options.content = message;
                            $($elem).popover('show');
                        }
                    }
                });
            }
        };
    }

}(window.angular));

(function(angular, Math) {
    "use strict";

    angular
        .module('qsHub')
        .service('IsValidFloatService', [
            Service
        ]);

    function Service() {
        var service = {};

        function decimalPlaces(num) {
            var match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
            if (!match) { return 0; }
            return Math.max(0, (match[1] ? match[1].length : 0) - (match[2] ? + match[2] : 0));
        }

        service.isValid = function (value) {
            return typeof value === 'undefined' ||
                value === '' ||
                (String(value) === String(parseFloat(value)) && parseFloat(value) >= 0 && decimalPlaces(value) <= 2);
        };

        return service;
    }

}(window.angular, window.Math));

(function (angular) {
    'use strict';

    angular
        .module('qsHub')
        .directive('isValidInt', [
            'IsValidIntService',
            Directive
        ]);

    function Directive(
        IsValidIntService
    ) {
        var message = 'Only integers are accepted',
            errorSelector = 'invalid-int';

        return {
            restrict: 'A',
            require: 'ngModel',
            link: function ($scope, $elem, $attrs) {
                // init element popover
                $($elem).popover({
                    trigger: 'hover',
                    html: true,
                    content: '',
                    container: '',
                    placement: 'bottom',
                    delay: 250
                });

                $scope.$watch($attrs.ngModel, function (value) {
                    if (IsValidIntService.isValid(value)) {
                        $elem.context.parentElement.className = $elem.context.parentElement.className.replace(errorSelector, '');
                        $($elem).data('bs.popover').options.content = '';
                        $($elem).popover('hide');
                    } else {
                        if ($elem.context.parentElement.className.indexOf(errorSelector) === -1) {
                            $elem.context.parentElement.className += ' ' + errorSelector;
                            $($elem).data('bs.popover').options.content = message;
                            $($elem).popover('show');
                        }
                    }
                });
            }
        };
    }

}(window.angular));

(function(angular, Math) {
    "use strict";

    angular
        .module('qsHub')
        .service('IsValidIntService', [
            Service
        ]);

    function Service() {
        var service = {};

        service.isValid = function (value) {
            return typeof value === 'undefined' ||
                value === null ||
                value === '' ||
                (String(value) === String(parseInt(value, 10)) && parseInt(value) >= 0);
        };

        return service;
    }

}(window.angular, window.Math));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers: {}});

    App.controllers.qsUsersList = function (
        $scope,
        $timeout,
        $localStorage,
        constants,
        uiGridConstants,
        QsUsersService,
        UsersListService,
        NotifierFactory,
        GridService,
        PasswordService,
        TimeService,
        DateRangeFixerService,
        uiGridExporterService,
        uiGridExporterConstants,
        TextService,
        ModalService,
        UserFactory
    ) {
        var controller = this;
        var prevLastLoginAtDateFilter = null,
        filterChangesInProgress = false;
        var selectValues = {},
            lastSelectValues = {};
        var filterTimeoutMilliseconds = constants.datagridInputDelay;
        controller.devMode = constants.dev;
        controller.grid = {};
        controller.defaultPaginationOptions = {
            page: 1,
            limit: constants.datagrid.contacts.defaultRowsNumber
        };

        controller.paginationOptions = GridService.getColumnsFilters(
            constants.gridNameSpaces.qsUserGridFilters,
            ['lastLoginAtRange']
        );

        controller.columnsVisibility = GridService.getColumnsVisibility(
            constants.gridNameSpaces.qsUserGridVisibility
        );
        controller.defaultFilters = {
            lastLoginAtRange: {
                startDate: null,
                endDate: null
            }
        };

        $scope.user = {};
        $scope.userBeforeChanges = {};
        $scope.forms = {};
        $scope.filterActive = true;
        $scope.isDatagridReloading = false;
        $scope.showQsSections = false;
        $scope.showClientSections = false;
        $scope.activeQsUserListSubTabs = 0;
        $scope.disabledQsUserListSubTabs = {
            'personalDetails': false,
            'permissions': true
        };
        $scope.newUser = {};
        $scope.newUserPanelNotClosed = false;
        $scope.filters = controller.defaultFilters;
        $scope.columnsBeforeHide = [];
        $scope.visible = {};

        controller.contactTypesHasTooltips = [
            'wut_outreach',
            'wmt_outreach',
            'wgst_outreach'
        ];

        /**
         * Update active user data in datagrid.
         */
        controller.updateInDatagrid = function (item) {
            var totalItems = controller.grid.options.data.length,
                key = 0;
            for (key; key < totalItems; ++key) {
                if (controller.grid.options.data[key].id === item.id) {
                    controller.grid.options.data[key] = angular.copy($scope.userBeforeChanges);
                    return true;
                }
                $scope.columnsBeforeEdit = null;
                $scope.filterActive = true;
                $scope.newUserPanelNotClosed = false;
            }
            return false;
        };

        controller.getColumnNumber = function (columnName) {
            var key = 0,
                total = controller.grid.options.columnDefs.length;
            for (key; key < total; key++) {
                if (controller.grid.options.columnDefs[key] &&
                    controller.grid.options.columnDefs[key].field === columnName
                ) {
                    return key;
                }
            }
            return null;
        };

        $scope.handleLastLoginAtDateRange = function (event) {
            //Handle selecting today only / one day date range

            if (DateRangeFixerService.sameDayAndNoModel(event, $scope.filters.lastLoginAtRange, prevLastLoginAtDateFilter)) {
                event.model.startDate = prevLastLoginAtDateFilter.startDate;
                event.model.endDate = prevLastLoginAtDateFilter.endDate;
                $scope.filters.lastLoginAtRange = {
                    startDate: prevLastLoginAtDateFilter.startDate,
                    endDate: prevLastLoginAtDateFilter.endDate
                };
            } else if (DateRangeFixerService.todayRangeAndNoModel(event, prevLastLoginAtDateFilter)) {
                event.model.startDate = TimeService.getStartOfToday();
                event.model.endDate = TimeService.getEndOfToday();
                $scope.filters.lastLoginAtRange = {
                    startDate: event.model.startDate.toISOString(),
                    endDate: event.model.endDate.toISOString()
                };
            }

            if (event.model.startDate && event.model.endDate) {
                controller.grid.options.columnDefs[controller.getColumnNumber('lastLoginAt')].filters[0].term = typeof event.model.startDate === 'object' ? event.model.startDate.format('x') : event.model.startDate;
                controller.grid.options.columnDefs[controller.getColumnNumber('lastLoginAt')].filters[1].term = typeof event.model.endDate === 'object' ? event.model.endDate.format('x') : event.model.endDate;
            }

            prevLastLoginAtDateFilter = angular.copy({
                startDate: event.model.startDate,
                endDate: event.model.endDate
            });
        };

        $scope.handleDatagridRowClick = function (user, addUser) {
            PasswordService.setHasErrors(true);
            $scope.disabledQsUserListSubTabs.permissions = false;
            $scope.disabledQsUserListSubTabs.institutions = false;
            if (angular.isDefined(addUser) && addUser) {
                $scope.disabledQsUserListSubTabs.permissions = true;
            }
            if ($scope.selectedUserId === user.id) {
                $scope.selectedUserId = 0;
                $scope.showInfoBlock = false;
                $scope.filterActive = true;
                controller.toggleColumns(false);
            } else { //edit mode
                $scope.selectedUserId = user.id;
                $scope.showInfoBlock = true;
                $scope.filterActive = false;

                $scope.user = angular.copy(user);
                $scope.userBeforeChanges = user;
                $scope.user = UsersListService.updateRolesWithAll($scope.user, controller.rolesList);
                $scope.user = UsersListService.updateSectionsAndPages($scope.user, controller.rolesList);

                controller.rolesBeforeChanges = [];
                for (var role in user.roles) {
                    if (user.roles[role]) {
                        controller.rolesBeforeChanges[role] = true;
                    }
                }
                controller.toggleColumns(true);
            }
        };

        /**
         * Toggle datagrid columns visibility.
         *
         * @param {boolean} hide
         */
        controller.toggleColumns = function (hide) {
            if (!controller.grid.options) {
                return;
            }
            if (hide) {
                angular.forEach(controller.grid.options.columnDefs, function (column) {
                    if (angular.isDefined(column.visible) && column.visible === true) {
                        $scope.columnsBeforeHide.push(column);
                    }
                    $scope.visible[column.field] = column.visible;
                });
                // hide all columns except name
                angular.forEach(controller.grid.options.columnDefs, function (column) {
                    column.visible = column.field === 'email';
                });
            } else {
                // show columns visible before hide
                angular.forEach($scope.columnsBeforeHide, function (column) {
                    column.visible = true;
                });
                $scope.columnsBeforeHide = [];

                $scope.$broadcast(constants.events.closingInstitutionsUsers);
            }

            controller.grid.options.enableGridMenu = !hide;
            controller.grid.options.enableColumnMenus = !hide;
            controller.grid.options.enableColumnResizing = !hide;
            controller.grid.options.enablePaginationControls = !hide;
        };

        /**
         * Reset user form.
         */
        $scope.handleResetClick = function () {
            return;
            $scope.user = angular.copy($scope.userBeforeChanges);
        };

        /**
         * Actions to do when deactivation is triggered.
         *
         * @param {Object} user
         * @returns {boolean}
         */
        $scope.handleDeactivateClick = function (user) {
            return;
            if (!user.id || $scope.deactivateInProgress) {
                return false;
            }
            var modalOptions = {
                closeButtonText: 'Cancel',
                actionButtonText: 'Yes',
                actionButtonClass: 'danger',
                headerText: 'Deactivation',
                bodyText: 'Are you sure you wish to deactivate QS user?'
            };
            ModalService.show({}, modalOptions).then(function () {
                $scope.deactivateInProgress = true;
                QsUsersService.deactivate(user.id).then(controller.deactivationCallback);
            });
        };

        $scope.handleActivateClick = function (user) {
            return;
            if (!user.id || $scope.activateInProgress) {
                return false;
            }
            $scope.activateInProgress = true;
            QsUsersService.activate(user.id).then(controller.activationCallback);
        };

        controller.deactivationCallback = function (success) {
            $scope.user.active = success ? false : true;
            if (success) {
                $scope.userBeforeChanges.active = $scope.user.active;
                // update user data in datagrid
                controller.updateInDatagrid($scope.user);
            }
            $scope.deactivateInProgress = false;

            NotifierFactory.show(
                success ? 'success' : 'error',
                success ? 'Deactivated successfully!' : 'Deactivation failed!',
                'Deactivation'
            );
        };

        controller.activationCallback = function (success) {
            $scope.user.active = success ? true : false;
            if (success) {
                $scope.userBeforeChanges.active = $scope.user.active;
                // update user data in datagrid
                controller.updateInDatagrid($scope.user);
            }
            $scope.activateInProgress = false;

            NotifierFactory.show(
                success ? 'success' : 'error',
                success ? 'Activated successfully!' : 'Activation failed!',
                'Activation'
            );
        };

        $scope.handleNewUserResponse = function (response) {
            if (response.hasOwnProperty('insertedId') && response.insertedId.length > 0) {
                $scope.user.id = response.insertedId;
                $scope.user.active = true;
                angular.copy($scope.user, $scope.userBeforeChanges);
                $scope.disabledQsUserListSubTabs.permissions = false;
                // switch tab after user add
                $scope.activeQsUserListSubTabs = 1;
                angular.copy($scope.user, $scope.newUser);
                controller.reloadDatagrid();
                $scope.selectedUserId = $scope.user.id;
                $scope.newUserPanelNotClosed = true;
            }
        };

        /**
         * Store user data.
         */
        $scope.handlePersonalDetailsSubmit = function () {
            return;
            $scope.forms.personalDetailsForm.firstname.$setDirty();
            $scope.forms.personalDetailsForm.lastname.$setDirty();
            $scope.forms.personalDetailsForm.email.$setDirty();
            PasswordService.setHasErrors(
                $scope.user.id || !!$scope.forms.personalDetailsForm.password.$viewValue ? true : false
            );
            if (
                $scope.personalDetailsSubmitInProgress || !$scope.forms.personalDetailsForm || !$scope.forms.personalDetailsForm.$valid || !PasswordService.isValid($scope.forms.personalDetailsForm, $scope.user) || !PasswordService.getHasErrors()

            ) {
                return false;
            }
            var newUser = typeof $scope.user.id === 'undefined';
            // if is new user and the password is not set or setting password for existing user
            if (
                (newUser && !PasswordService.isPasswordValid($scope.user.password)) ||
                (!newUser && typeof $scope.user.password !== 'undefined' && !PasswordService.isPasswordValid($scope.user.password))
            ) {
                NotifierFactory.show(
                    'error',
                    'Please set a valid password'
                );

                return false;
            }
            $scope.personalDetailsSubmitInProgress = true;
            QsUsersService.savePersonalDetails($scope.user).then(function (response) {
                var message = 'Personal Details';
                var success = false;
                if (response) {
                    success = !response.error;
                    if (!success) {
                        if (response.hasOwnProperty('message') && response.message.length > 0) {
                            message = response.message;
                        }
                    } else {
                        $scope.user.fullname = QsUsersService.getFullName($scope.user);
                        if ($scope.user.contactTypes) {
                            QsUsersService.getContactTypes().then(function (contactTypesList) {
                                if (!contactTypesList) {
                                    return false;
                                }
                                var contactTypeNames = [],
                                    contactTypeName = null;
                                angular.forEach($scope.user.contactTypes, function (contactTypeData, handle) {
                                    if (!contactTypeData.assigned) {
                                        return false;
                                    }
                                    contactTypeName = UsersListService.getContactTypeNameByHandle(
                                        handle, contactTypesList
                                    );
                                    contactTypeNames.push(contactTypeName);
                                });
                                $scope.user.contactTypesAsString = contactTypeNames.join(', ');
                                $scope.handleNewUserResponse(response);
                                // update original user object with changes
                                $scope.userBeforeChanges = angular.copy($scope.user);
                                controller.updateInDatagrid($scope.user);
                            });
                        } else {
                            $scope.handleNewUserResponse(response);
                        }
                        //reset personalDetailsForm
                        controller.resetPersonalDetailsForm();
                    }
                }
                if (success) {
                    delete $scope.user.password;
                    // update original user object with changes
                    $scope.userBeforeChanges = angular.copy($scope.user);
                    // update user data in datagrid
                    controller.updateInDatagrid($scope.user);
                    if ($scope.user.coreId === $localStorage.currentUser.info.coreId) {
                        $localStorage.currentUser.info.firstName = $scope.user.firstname;
                        $localStorage.currentUser.info.lastName = $scope.user.lastname;
                        $localStorage.currentUser.info.fullName = $scope.user.fullname;
                        $localStorage.currentUser.info.email = $scope.user.email;
                        $localStorage.currentUser.info.position = $scope.user.position;
                        $localStorage.currentUser.info.title = $scope.user.title;
                        UserFactory.setData($localStorage.currentUser.info);
                    }
                }
                $scope.personalDetailsSubmitInProgress = false;

                NotifierFactory.show(
                    success ? 'success' : 'error',
                    success ? 'Saved successfully!' : 'Saving failed!',
                    message
                );
            });
        };

        $scope.handlePermissionsSubmit = function () {
            return;
            $scope.permissionsSubmitInProgress = true;
            QsUsersService.savePermissions($scope.user).then(function (success) {
                $scope.permissionsSubmitInProgress = false;
                $scope.userBeforeChanges = angular.copy($scope.user);
                // update user data in datagrid
                controller.updateInDatagrid($scope.user);
                controller.reloadDatagrid();
                NotifierFactory.show(
                    success ? 'success' : 'error',
                    success ? 'Saved successfully!' : 'Saving failed!',
                    'Permissions'
                );
            });
        };

        $scope.handlePrimaryContactTypeClick = function (handle) {
            $scope.user.contactTypes[handle].assigned = true;
        };

        $scope.handleAssignedContactTypeClick = function (handle) {
            if ($scope.user.contactTypes[handle].assigned === false) {
                $scope.user.contactTypes[handle].primary = false;
            }
        };

        $scope.handleRoleClick = function (clickedRole) {
            // do nothing if user has custom role
            if ($scope.user.roles.custom || typeof clickedRole === 'undefined') {
                return;
            }

            $scope.user.accessTo = {
                'admin_dashboard.dashboard': true
            };
            var clickedRoleAssigned = $scope.user.roles[clickedRole.roleHandle],
                parentRoleClicked = clickedRole.all,
                globalAdminRoleClicked = clickedRole.roleHandle === 'global_admin.global_admin',
                childrenRolesHandles = UsersListService.getChildrenRoles(clickedRole.groupHandle, controller.rolesList),
                parentRoleHandle = null;

            // check if it's global admin
            if (globalAdminRoleClicked && clickedRoleAssigned) {
                // walk threw all system roles
                angular.forEach(controller.rolesList, function (role) {
                    if (role.roleHandle === 'custom') {
                        return false;
                    }
                    // assign all role sections to a user
                    angular.forEach(role.sections, function (section) {
                        $scope.user.accessTo[section] = true;
                    });
                });
            } else {
                if (!globalAdminRoleClicked) {
                    // if role is assigned
                    if (clickedRoleAssigned) {
                        // if its parent role
                        if (parentRoleClicked) {
                            // assign children roles to a user
                            childrenRolesHandles = UsersListService.getChildrenRoles(clickedRole.groupHandle, controller.rolesList);
                            if (childrenRolesHandles) {
                                angular.forEach(childrenRolesHandles, function (childrenRoleHandle) {
                                    $scope.user.roles[childrenRoleHandle] = true;
                                });
                            }
                        } else {
                            if (childrenRolesHandles) {
                                // Select parent if all children were selected
                                var allDepartments = true;
                                angular.forEach(childrenRolesHandles, function (childrenRoleHandle) {
                                    if ($scope.user.roles[childrenRoleHandle] === false ||
                                        $scope.user.roles[childrenRoleHandle] === undefined
                                    ) {
                                        allDepartments = false;
                                        return;
                                    }
                                });
                                if (allDepartments) {
                                    parentRoleHandle = UsersListService.getParentRoleHandle(clickedRole.groupHandle, controller.rolesList);
                                    if (parentRoleHandle) {
                                        $scope.user.roles[parentRoleHandle] = true;
                                    }
                                }
                            }
                        }
                    } else {
                        if (!parentRoleClicked) {
                            // Uncheck parent if it was selected when unselecting one children
                            parentRoleHandle = UsersListService.getParentRoleHandle(clickedRole.groupHandle, controller.rolesList);
                            if (parentRoleHandle) {
                                $scope.user.roles[parentRoleHandle] = false;
                            }
                        } else {
                            // check if all children where selected to select the parent
                            if (childrenRolesHandles) {
                                angular.forEach(childrenRolesHandles, function (childrenRoleHandle) {
                                    $scope.user.roles[childrenRoleHandle] = false;
                                });
                            }
                        }
                    }
                }
                // Update sections and pages based on the new roles
                $scope.user = UsersListService.updateSectionsAndPages($scope.user, controller.rolesList);
            }
        };

        $scope.toggleQsSections = function () {
            $scope.showQsSections = !$scope.showQsSections;
        };

        $scope.toggleClientSections = function () {
            $scope.showClientSections = !$scope.showClientSections;
        };

        $scope.activateCustomRole = function () {
            if (!angular.isDefined($scope.user.roles)) {
                $scope.user.roles = {
                    custom: true
                };
            }
            $scope.user.roles = {
                custom: true
            };
        };

        controller.loadData = function () {
            QsUsersService.getContactTypes().then(function (contactTypes) {
                controller.contactTypes = contactTypes;
                for (var i = 0; i < controller.contactTypes.length; i++) {
                    if (controller.contactTypes[i].handle === 'database_access_outreach' ||
                        controller.contactTypes[i].handle === 'wmt_outreach' ||
                        controller.contactTypes[i].handle === 'wut_outreach' ||
                        controller.contactTypes[i].handle === 'wgst_outreach' 
                    ) {
                        controller.contactTypes.splice(i, 1);
                        i--;
                    }
                }
            });
            QsUsersService.getRoles().then(function (rolesList) {
                controller.rolesList = rolesList;
            });
            QsUsersService.getSections().then(function (sectionsList) {
                controller.qsSectionsList = sectionsList;
            });
            QsUsersService.getSections(true).then(function (sectionsList) {
                controller.clientSectionsList = sectionsList;
            });
        };

        /**
         * Actions to do when column is clicked under visible columns dropdown.
         */
        $scope.handleColumnClick = function () {
            var columnsVisibility = {};
            angular.forEach($scope.columns, function (column) {
                columnsVisibility[column.field] = column.show;
            });
            QsUsersService.storeColumnsVisibility(columnsVisibility);
        };

        /**
         * Apply datagrid columns visibility from browser cache.
         */
        controller.loadDatagridColumnsVisibility = function () {
            var columnsVisibility = QsUsersService.getColumnsVisibility();
            if (columnsVisibility) {
                angular.forEach($scope.columns, function (column) {
                    if (columnsVisibility[column.field] !== 'undefined') {
                        column.show = columnsVisibility[column.field] ? true : false;
                    }
                });
            }
        };

        controller.handleGridFiltersChanges = function () {
            filterChangesInProgress = true;
            if($scope.gridApi.pagination.getPage() > 1){
                $scope.gridApi.pagination.seek(1);
            }
            controller.paginationOptions = angular.copy(controller.defaultPaginationOptions);
            var filterColumn, sortColumn, sortKeys = [];

            angular.forEach($scope.gridApi.grid.columns, function (column) {
                if (typeof column.filters !== 'undefined' && column.filters[0].term !== 'undefined') {
                    if (
                        column.filters[0].type === uiGridConstants.filter.SELECT &&
                        typeof column.filters[0].term === 'object' &&
                        column.filters[0].term !== null &&
                        typeof column.filters[0].term.value !== 'undefined'
                    ) {
                        column.filters[0].term = column.filters[0].term.value;
                    }
                }
                if (typeof column.sort.priority !== 'undefined') {
                    sortKeys[column.sort.priority] = column.name;
                }
            });
            if (sortKeys.length > 1) {
                var latestSortKey = sortKeys.pop();
                angular.forEach($scope.gridApi.grid.columns, function (column) {
                    if (typeof column.sort.priority !== 'undefined' && column.name !== latestSortKey) {
                        column.sort = {};
                    }
                });
            }

            angular.forEach($scope.gridApi.grid.columns, function (column) {
                filterColumn = column.filters[0].column ? column.filters[0].column : column.field;
                if (column.field === 'lastLoginAt') {
                    if (
                        typeof column.filters !== 'undefined' &&
                        typeof column.filters[0].term !== 'undefined' &&
                        typeof column.filters[1].term !== 'undefined'
                    ) {
                        controller.paginationOptions['filter[' + filterColumn + ']'] = {
                            startDate: column.filters[0].term && isNaN(column.filters[0].term) && typeof column.filters[0].term === 'object' ?
                                column.filters[0].term.format('x') : column.filters[0].term,
                            endDate: column.filters[1].term && isNaN(column.filters[1].term) && typeof column.filters[1].term === 'object' ?
                                column.filters[1].term.format('x') : column.filters[1].term
                        };
                    } else {
                        controller.paginationOptions['filter[' + filterColumn + ']'] = null;
                    }
                } else if ((column.field === 'contactTypesAsString' ||
                    column.field === 'contactTypes.handle') &&
                    typeof column.filters !== 'undefined' &&
                    column.filters[0].term !== 'undefined'
                ) {
                    controller.paginationOptions['filter[contactTypes.handle]'] = column.filters[0].term;
                } else {
                    if (typeof column.filters !== 'undefined' && column.filters[0].term !== 'undefined') {
                        controller.paginationOptions['filter[' + filterColumn + ']'] = column.filters[0].term;
                    }
                }

                if (typeof column.sort.direction !== 'undefined') {
                    sortColumn = column.sort.column ? column.sort.column : column.field;
                    controller.paginationOptions['sorting[' + sortColumn + ']'] = column.sort.direction;
                }
            });
            if (controller.paginationOptions["filter[lastLoginAt]"] === null) {
                controller.paginationOptions["filter[lastLoginAt]"] = controller.defaultFilters.lastLoginAtRange;
            }
            if (controller.paginationOptions["filter[lastLoginAt]"].startDate === null) {
                controller.paginationOptions["filter[lastLoginAt]"].endDate = null;
                controller.defaultFilters.lastLoginAtRange = {
                    startDate: null,
                    endDate: null
                };
            }
            GridService.storeColumnsFilters(
                constants.gridNameSpaces.qsUserGridFilters,
                controller.paginationOptions
            );

            if (angular.isDefined($scope.filterTimeout)) {
                $timeout.cancel($scope.filterTimeout);
            }

            GridService.generateSelectValues($scope.gridApi.grid.columns, selectValues);

            if (!angular.equals(selectValues, lastSelectValues)) {
                filterTimeoutMilliseconds = constants.datagridSelectDelay;
            }

            $scope.filterTimeout = $timeout(function () {
                controller.getPage();
                filterChangesInProgress = false;
                lastSelectValues = angular.copy(selectValues);
                filterTimeoutMilliseconds = constants.datagridInputDelay;
            }, filterTimeoutMilliseconds);
        };

        controller.getPage = function () {
            $scope.isDatagridReloading = true;
            return QsUsersService.getAllUsers(
                angular.merge({}, controller.paginationOptions, controller.defaultPaginationOptions)
            ).then(function (response) {
                controller.grid.options.totalItems = response.totalMatching;
                controller.grid.options.data = response.results;
                controller.grid.options.minRowsToShow = response.totalFiltered;
                $scope.isDatagridReloading = false;
            });
        };

        controller.reloadDatagrid = function () {
            controller.getPage();
        };

        controller.initDataGrid = function () {
            var selectTemplate = '/scripts/shared/ui-grid/templates/selectFilterHeaderTemplate.html',
                selectCellTemplate = '/scripts/shared/ui-grid/templates/selectCellTemplate.html',
                dateFilterCellTemplate = '/scripts/shared/ui-grid/templates/dateFilterCellTemplate.html';

            controller.grid.options = {
                enableSorting: true,
                enableGridMenu: true,
                showGridFooter: false,
                selectedItems: $scope.selectedUserId,
                enableFiltering: true,
                enableColumnResize: true,
                enableFullRowSelection: true,
                enableRowSelection: true,
                multiSelect: false,
                enableRowHeaderSelection: false,
                paginationPageSizes: [constants.datagrid.contacts.defaultRowsNumber, 50, 100],
                paginationPageSize: constants.datagrid.contacts.defaultRowsNumber,
                useExternalPagination: true,
                useExternalSorting: true,
                useExternalFiltering: true,
                exporterMenuCsv: false,
                exporterMenuAllData: false,
                exporterMenuPdf: false,
                exporterCsvFilename: 'qs-users-export.' + TimeService.now() + '.csv',
                gridMenuCustomItems: [
                    {
                        title: ' Export visible data as csv',
                        action: controller.exportVisible,
                        order: 209
                    },
                    {
                        title: ' Export all data as csv',
                        action: controller.exportAll,
                        order: 210
                    }
                ],
                rowTemplate: '/scripts/components/users/list/institutions/datagrid/rowTemplate.html',
                columnDefs: [
                    {
                        displayName: 'Email',
                        field: 'email',
                        cellFilter: 'lowercase',
                        visible: true,
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'email', ''),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        displayName: 'Full Name',
                        field: 'fullname',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'fullname', true),
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'fullname', ''),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        displayName: 'Contact Types',
                        filter: {
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: [],
                            term: GridService.getFilterByField(controller.paginationOptions, 'contactTypes.handle')
                        },
                        field: 'contactTypesAsString',
                        enableSorting: false,
                        maxWidth: 130,
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'contactTypes.handle', true),
                        filterHeaderTemplate: selectTemplate
                    },
                    {
                        displayName: 'Last Login',
                        field: 'lastLoginAt',
                        filterCellFiltered: true,
                        filters: [
                            {
                                visible: true,
                                condition: uiGridConstants.filter.GREATER_THAN
                            },
                            {
                                condition: uiGridConstants.filter.LESS_THAN
                            }
                        ],
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'lastLoginAt', ''),
                            applyTextFilter: controller.applyDateFilter('lastLoginAt', '')
                        },
                        cellTemplate: dateFilterCellTemplate,
                        filterHeaderTemplate: '/scripts/components/users/list/institutions/datagrid/dateFilterHeaderTemplate.html',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'lastLoginAt', true)
                    },
                    {
                        displayName: 'Active',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'active', true),
                        field: 'active',
                        filter: {
                            column: 'active',
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: [
                                {value: true, label: 'Yes'},
                                {value: false, label: 'No'}
                            ],
                            term: GridService.getFilterByField(controller.paginationOptions, 'active')
                        },
                        maxWidth: 80,
                        filterHeaderTemplate: selectTemplate,
                        cellTemplate: selectCellTemplate
                    }
                ],
                onRegisterApi: function (gridApi) {
                    var columnsVisibilityBeforeChanges = angular.copy(controller.grid.options.columnDefs);
                    $scope.gridApi = gridApi;
                    gridApi.selection.on.rowSelectionChanged($scope, function (row) {
                        $scope.handleDatagridRowClick(row.entity);
                    });
                    gridApi.core.on.rowsRendered($scope, function () {
                        $scope.isDatagridRendered = true;
                        GridService.generateSelectValues($scope.gridApi.grid.columns, selectValues);
                        GridService.generateSelectValues($scope.gridApi.grid.columns, lastSelectValues);
                    });
                    gridApi.core.on.filterChanged($scope, controller.handleGridFiltersChanges);
                    gridApi.core.on.rowsVisibleChanged($scope, controller.handleGridVisibleChanges);

                    gridApi.pagination.on.paginationChanged($scope, function (newPage, limit) {
                        controller.defaultPaginationOptions.page = newPage;
                        controller.defaultPaginationOptions.limit = limit;
                        if(!filterChangesInProgress) { 
                            controller.getPage();
                        }
                    });
                    $scope.gridApi.core.on.sortChanged($scope, controller.handleGridFiltersChanges);
                    // reset columns visibility
                    GridService.resetExtend(columnsVisibilityBeforeChanges, controller.grid.options.columnDefs, controller.defaultFilters);
                }
            };

            // This is needed to add 'x' icon in date field when prepopulating dates
            var lastLogin = GridService.getFilterByField(controller.paginationOptions, 'lastLoginAt', '');
            if (lastLogin !== null && controller.paginationOptions["filter[lastLoginAt]"] !== null) {
                if (angular.isDefined(lastLogin) &&
                    angular.isDefined(controller.paginationOptions["filter[lastLoginAt]"]) &&
                    angular.isDefined(controller.paginationOptions["filter[lastLoginAt]"].startDate) &&
                    angular.isDefined(controller.paginationOptions["filter[lastLoginAt]"].endDate)
                ) {
                    controller.grid.options.columnDefs[3].filters[0].term = controller.paginationOptions["filter[lastLoginAt]"].startDate;
                    controller.grid.options.columnDefs[3].filters[1].term = controller.paginationOptions["filter[lastLoginAt]"].endDate;
                }
            }

            controller.getPage().then(function () {
                QsUsersService.getContactTypesFilter().then(function (options) {
                    var list = [];

                    angular.forEach(options, function (option) {
                        list.push({
                            value: option.id,
                            label: option.title
                        });
                    });
                    controller.grid.options.columnDefs[2].filter.selectOptions = list;
                });
            });
        };

        controller.exportVisible = function () {
            var results = controller.grid.options.data;
            results = QsUsersService.formatCsvResults(results, TimeService);
            if (typeof results === 'undefined') {
                return false;
            }
            if (!angular.isDefined($scope.gridOptions)) {
                $scope.gridOptions = controller.grid.options;
            }
            var gridApi = $scope.gridApi,
                gridOptions = $scope.gridOptions,
                exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                gridApi.grid,
                uiGridExporterConstants.VISIBLE
                ),
                exportData = GridService.getCsvData(
                    gridApi.grid.columns,
                    results
                ),
                csvContent = uiGridExporterService.formatAsCsv(
                    exportColumnHeaders,
                    exportData,
                    gridOptions.exporterCsvColumnSeparator
                );
            uiGridExporterService.downloadFile(
                $scope.gridOptions.exporterCsvFilename,
                csvContent,
                gridOptions.exporterOlderExcelCompatibility
            );
        };

        controller.exportAll = function () {
            QsUsersService.getAllUsers(
                angular.merge({}, controller.paginationOptions, {
                    page: 1,
                    limit: 250000
                })
            ).then(function (response) {
                if (typeof response.results === 'undefined') {
                    return false;
                }
                response = QsUsersService.formatCsvResults(response.results, TimeService);
                if (!angular.isDefined($scope.gridOptions)) {
                    $scope.gridOptions = controller.grid.options;
                }
                var  gridApi = $scope.gridApi,
                     gridOptions = $scope.gridOptions,
                     exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                     gridApi.grid,
                    uiGridExporterConstants.VISIBLE
                    ),
                    exportData = GridService.getCsvData(
                        gridApi.grid.columns,
                        response
                    ),
                    csvContent = uiGridExporterService.formatAsCsv(
                        exportColumnHeaders,
                        exportData,
                        gridOptions.exporterCsvColumnSeparator
                    );
                uiGridExporterService.downloadFile(
                    gridOptions.exporterCsvFilename,
                    csvContent,
                    gridOptions.exporterOlderExcelCompatibility
                );
            });
        };

        controller.handleGridVisibleChanges = function () {
            var columnsVisibility = GridService.getColumnsVisibility(constants.gridNameSpaces.qsUserGridVisibility),
            reload = false;
            if (columnsVisibility && $scope.filterActive) {
                angular.forEach(controller.grid.options.columnDefs, function (column, key) {
                    if ((columnsVisibility[column.field] === true || typeof columnsVisibility[column.field] === 'undefined') &&
                        column.visible === false
                    ) {
                        if (typeof  controller.grid.options.columnDefs[key].filter === 'undefined') {
                            controller.grid.options.columnDefs[key].filter = {};
                        }
                        if (typeof  controller.grid.options.columnDefs[key].filter !== 'undefined' &&
                            controller.grid.options.columnDefs[key].filter.hasOwnProperty('term') &&
                            (controller.grid.options.columnDefs[key].filter.term || controller.grid.options.columnDefs[key].filter.term === false)) {
                            reload = true;
                        }
                        controller.grid.options.columnDefs[key].filter.term = null;
                        controller.paginationOptions['filter[' + column.field + ']'] = null;
                        if (column.field === 'lastLoginAt') {
                            delete controller.grid.options.columnDefs[key].filters[0].term;
                            reload =  controller.defaultFilters.lastLoginAtRange.startDate? true : false;
                            controller.defaultFilters.lastLoginAtRange = {
                                startDate: null,
                                endDate: null
                            };
                        }
                    }
                });
            }
            if ($scope.filterActive) {
                controller.saveStateVisibility();
            }
            if (reload) {
                controller.reloadDatagrid();
            }
        };

        /**
         * Populates input text field for date field
         *
         * @param {string} filterName
         * @param {mixed} defaultValue
         */
        controller.applyDateFilter = function (filterName, defaultValue) {
            var filter = GridService.getFilterByField(controller.paginationOptions, filterName, defaultValue);
            if (angular.isDefined(filter) &&
                filter !== null
            ) {
                if (angular.isDefined(filter.startDate) &&
                    angular.isDefined(filter.endDate)
                ) {
                    if (filter.startDate !== null &&
                        filter.startDate !== null
                    ) {
                        controller.defaultFilters.lastLoginAtRange = {
                            startDate: parseInt(filter.startDate, 10),
                            endDate: parseInt(filter.endDate, 10)
                        };
                    } else {
                        controller.defaultFilters.lastLoginAtRange = {
                            startDate: null,
                            endDate: null
                        };
                    }
                }
            }
        };

        controller.handleAddQsUserClick = function () {
            window.location.replace('https://qs-hub2.qs.com/user-permission/');
            return;
            var user = {
                accessTo: {
                    'admin_dashboard.dashboard': true
                }
            };
            user.id = null;
            $scope.activeQsUserListSubTabs = 0;
            $scope.handleDatagridRowClick(user, true);
            controller.resetPersonalDetailsForm();
        };

        //reset personalDetailsForm
        controller.resetPersonalDetailsForm = function () {
            if ($scope.forms.personalDetailsForm) {
                $scope.forms.personalDetailsForm.$setPristine();
            }
        };
        controller.handleEditCloseClick = function () {
            var user = {};
            user.id = $scope.selectedUserId;
            $scope.handleDatagridRowClick(user, true);
        };

        controller.convertVisibleColumnsField = function (value) {
            switch (value) {
                case 'contactTypesAsString':
                    return 'contactTypes.handle';
            }
        };

        controller.contactTypeHasTooltip = function (handle) {
            return controller.contactTypesHasTooltips.indexOf(handle) !== -1;
        };

        controller.getContactTypeTooltipUrl = function (handle) {
            return "/scripts/components/users/list/templates/" + TextService.underScoreToCamelCase(handle) + "Tooltip.html";
        };

        controller.isValidFirstName = function () {
            return PasswordService.isValidFirstName($scope.forms.personalDetailsForm, $scope.user);
        };

        controller.isValidLastName = function () {
            return PasswordService.isValidLastName($scope.forms.personalDetailsForm, $scope.user);
        };

        controller.isValidEmail = function () {
            return PasswordService.isValidEmail($scope.forms.personalDetailsForm, $scope.user);
        };

        controller.setValid = function (fieldName) {
            return PasswordService.setValid($scope.forms.personalDetailsForm, fieldName);
        };

        controller.saveStateVisibility = function () {
            var visibility = angular.copy(controller.grid.options.columnDefs);
            angular.forEach(visibility, function (column) {
                if (['contactTypesAsString'].indexOf(column.field) !== -1) {
                    column.field = controller.convertVisibleColumnsField(column.field);
                }
            });
            GridService.storeColumnsVisibility(
                constants.gridNameSpaces.qsUserGridVisibility,
                GridService.getGridColumnsVisibility(visibility)
            );
        };

        controller.init = function () {
            controller.loadData();
            controller.initDataGrid();
            controller.loadDatagridColumnsVisibility();
        };

        controller.init();
    };

    angular
        .module('qsHub')
        .controller('QsUsersListController', [
            '$scope',
            '$timeout',
            '$localStorage',
            'constants',
            'uiGridConstants',
            'QsUsersListService',
            'UsersListService',
            'NotifierFactory',
            'UiGridService',
            'PasswordService',
            'TimeService',
            'DateRangeFixerService',
            'uiGridExporterService',
            'uiGridExporterConstants',
            'TextService',
            'ModalService',
            'UserFactory',
            App.controllers.qsUsersList
        ]);

}(window.angular));

(function(angular, moment) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    App.services.QsUsers = function (
        $resource,
        $q,
        $log,
        $localStorage,
        constants,
        UsersListService,
        RequestsQueueService
    ) {
        var service = {
            contactTypes : null,
            roles : null,
            sections : null,
            deferred : {
                getContactTypes: null,
                getRoles: null,
                getSections: []
            }
        };

        service.getUsersListModel = function () {
            return $resource(constants.api.usersPermissions.url + '/v1/list/qs-users', {}, {
                get: {
                    isArray:false,
                    cancellable : true
                }
            });
        };

        service.getAllUsers = function (filters) {
            if (constants.dev) {
                var startTime = new Date().getTime(), endTime;
            }
            // cancel currently running requests
            RequestsQueueService.cancelAll('getAllQsUsers');
            var hasSorting = false,
                allowedFilters = [
                    'limit',
                    'page',
                    'columns'
                ];
            if (filters) {
                for (var key in filters) {
                    if (key.indexOf('sorting') !== -1) {
                        hasSorting = true;
                        break;
                    } else {
                        if (key.indexOf('filter') !== 0 && allowedFilters.indexOf(key) === -1) {
                            delete filters[key];
                        }
                    }
                }
            }
            if (!hasSorting) {
                filters['sorting[createdAt]'] = 'desc';
            }
            var Api = service.getUsersListModel().get(filters);

            // add new request to the queue
            RequestsQueueService.add(Api, 'getAllQsUsers');

            return Api.$promise.then(function (data) {
                if (constants.dev) {
                    endTime = new Date().getTime();
                    $log.log('success, got data: ', data, 'in ' + (endTime - startTime) + ' ms');
                }

                // cancel currently running requests
                RequestsQueueService.cancelAll('getAllQsUsers');
                data.results = service.formatList(data.results);
                return data;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return [];
            }).then(function (data) {
                data.results = service.appendAccessData(data.results);
                return data;
            }).then(function (data) {
                data.results = service.appendContactsData(data.results);
                return data;
            });
        };

        service.formateDate = function (date, format)
        {
            var formatedDate = '';
            if (date && moment(date, 'x').isValid()) {
                formatedDate = moment(date,  'x');
                if (format) {
                    formatedDate = formatedDate.format(format);
                }
            }

            return formatedDate;
        };
        service.appendAccessData = function (userData) {
            if (userData) {
                service.getRoles().then(function (systemRoles) {
                    if (systemRoles) {
                        var hasCustomRole,
                            originalRoles = null;
                        angular.forEach(userData, function(userObject) {
                            if (!userObject.roles) {
                                return false;
                            }
                            originalRoles = angular.copy(userObject.roles);
                            userObject.roles = {};
                            //check if user has custom role
                            hasCustomRole = false;
                            angular.forEach(originalRoles, function(value) {
                                if (value === constants.customRole) {
                                    hasCustomRole = true;
                                }
                            });

                            angular.forEach(originalRoles, function(value) {
                                userObject.roles[value] = true;
                                if (!hasCustomRole && value === constants.globalAdminRole) {
                                    userObject.globalAdmin = true;
                                    angular.forEach(systemRoles, function(role) {
                                        angular.forEach(role.sections, function(section) {
                                            userObject.accessTo[section] = true;
                                        });
                                    });
                                }
                            });
                        });
                    }
                });
            }

            return userData;
        };

        service.formatCsvResults = function (data, TimeService) {
            data = angular.copy(data);
            angular.forEach(data, function (column, dataKey) {
                angular.forEach(column, function (item, key) {
                    if(key === 'lastLoginAt'  && item ){
                        data[dataKey][key] = TimeService.format(item, "YYYY-MM-DD HH:mm:ss");
                    }
                    else if(key === 'active'){
                         data[dataKey][key] = item ? 'Yes' : 'No';
                    }
                });
            });

            return data;
        };

        service.appendContactsData = function (userData) {
            if (userData) {
                var contactTypeName = null,
                    originalContactTypes = null;
                service.getContactTypes().then(function(contactTypesList) {
                    if (contactTypesList && contactTypesList.length) {
                        angular.forEach(userData, function(userObject) {
                            if (!userObject.contactTypesAsArray) {
                                userObject.contactTypesAsArray = [];
                            }
                            if (!userObject.contactTypes) {
                                return false;
                            }
                            originalContactTypes = angular.copy(userObject.contactTypes);
                            userObject.contactTypes = {};
                            angular.forEach(originalContactTypes, function(contactType, key) {
                                contactTypeName = UsersListService.getContactTypeNameByHandle(
                                    contactType.handle, contactTypesList
                                );
                                if (!contactTypeName) {
                                    return false;
                                }
                                userObject.contactTypesAsArray.push(contactTypeName);
                                userObject.contactTypesAsString = userObject.contactTypesAsArray.length ?
                                    userObject.contactTypesAsArray.join(', ') : null;

                                userObject.contactTypes[contactType.handle] = {
                                    primary : contactType.primary,
                                    assigned : true
                                };
                            });
                        });
                    }
                });
            }

            return userData;
        };

        service.getContactTypesFilter = function () {
            return service.getContactTypes().then(function(contactTypesList) {
                var contactTypeFilters = [];
                if (contactTypesList) {
                    angular.forEach(contactTypesList, function(contactType) {
                        contactTypeFilters.push({
                            id: contactType.handle,
                            title: contactType.name
                        });
                    });
                }

                return contactTypeFilters;
            });
        };

        /**
         * Format QS users list.
         *
         * @param {Object} userData
         * @returns {Object}
         */
        service.formatList = function (userData) {
            if (userData) {
                var accessTo;
                angular.forEach(userData, function(userObject) {
                    if (userObject.accessTo) {
                        accessTo = {};
                        angular.forEach(userObject.accessTo, function(value) {
                            accessTo[value] = true;
                        });
                        userObject.accessTo = angular.copy(accessTo);
                    }
                });
            }
            return userData;
        };

        /**
         * Get contact types list.
         *
         * @returns {Promise:Object}
         */
        service.getContactTypes = function () {
            // process already running
            if (!service.deferred.getContactTypes) {
                service.deferred.getContactTypes = $q.defer();
            } else {
                return service.deferred.getContactTypes.promise;
            }
            var deferred = service.deferred.getContactTypes;

            // check if data exists in cache
            if (service.contactTypes) {
                deferred.resolve(service.contactTypes);
            } else {
                var Api = $resource(constants.api.usersPermissions.url + '/v1/list/contact-types');
                Api.get(function (data) {
                    if (constants.dev) {
                        $log.log('success, got data: ', data);
                    }
                    service.contactTypes = data.results;
                    deferred.resolve(service.contactTypes);
                    deferred = null;
                }, function (error) {
                    //@todo log error
                    if (constants.dev) {
                        $log.log('request failed' + error);
                    }
                    service.contactTypes = [];
                    deferred.resolve(service.contactTypes);
                    deferred = null;
                });
            }

            return deferred.promise;
        };

        /**
         * Format roles list.
         *
         * @param {Object} rolesList
         * @returns {Array}
         */
        service.formatRoles = function (rolesList) {
            var flatArray = [];

            angular.forEach(rolesList, function (rolesGroup) {
                angular.forEach(rolesGroup.roles, function (role) {
                    flatArray.push({
                        'groupHandle' : rolesGroup.handle,
                        'groupName' : rolesGroup.name,
                        'roleName' : role.name,
                        'roleHandle' : rolesGroup.handle + '.' + role.handle,
                        'all' : role.all ? role.all : false,
                        'sections': role.sections
                    });
                });
            });

            flatArray.push({
                'groupName' : 'Custom',
                'roleName' : 'Switching off will revert changes to default',
                'roleHandle' : 'custom',
                'all' : false
            });
            return flatArray;
        };

        /**
         * Get QS users roles list.
         *
         * @returns {Promise}
         */
        service.getRoles = function () {
            // process already running
            if (!service.deferred.getRoles) {
                service.deferred.getRoles = $q.defer();
            } else {
                return service.deferred.getRoles.promise;
            }
            var deferred = service.deferred.getRoles;

            // check if data exists in cache
            if (service.roles) {
                deferred.resolve(service.roles);
            } else {
                var Api = $resource(constants.api.usersPermissions.url + '/v1/list/qs-roles');
                Api.get(function (data) {
                    if (constants.dev) {
                        $log.log('success, got data: ', data);
                    }
                    service.roles = service.formatRoles(data.results);
                    deferred.resolve(service.roles);
                    deferred = null;
                    delete service.deferred.getRoles;
                }, function (error) {
                    //@todo log error
                    if (constants.dev) {
                        $log.log('request failed' + error);
                    }
                    if (error.status !== 401) {
                        service.roles = [];
                    }
                    deferred.resolve(service.roles);
                    deferred = null;
                    delete service.deferred.getRoles;
                });
            }

            return deferred.promise;
        };

        /**
         * Get sections list
         *
         * @param {boolean} client
         * @returns {Promise:Array}
         */
        service.getSections = function (client) {
            var type = client ? 'client' : 'qs',
                Api = $resource(constants.api.usersPermissions.url + '/v1/list/' + type + '-sections');

            // process already running
            if (!service.deferred.getSections[type]) {
                service.deferred.getSections[type] = $q.defer();
            } else {
                return service.deferred.getSections[type].promise;
            }
            var deferred = service.deferred.getSections[type];

            // check if data exists in cache
            if (service.sections) {
                deferred.resolve(service.sections);
            } else {
                Api.get(function (data) {
                    if (constants.dev) {
                        $log.log('success, got data: ', data);
                    }
                    service.sections = UsersListService.formatSections(data.results);
                    deferred.resolve(service.sections);
                    deferred = null;
                }, function (error) {
                    //@todo log error
                    if (constants.dev) {
                        $log.log('request failed' + error);
                    }
                    service.sections = [];
                    deferred.resolve(service.sections);
                    deferred = null;
                });
            }

            return deferred.promise;
        };

        /**
         * Mark user as active.
         *
         * @param {String} id
         * @returns {Promise:boolean}
         */
        service.activate = function (id) {
            var Api = $resource(constants.api.usersPermissions.url + '/v1/user/:id/activate', {id:id});
            return Api.get().$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        /**
         * Mark user as inactive.
         *
         * @param {String} id
         * @returns {Promise:boolean}
         */
        service.deactivate = function (id) {
            var Api = $resource(constants.api.usersPermissions.url + '/v1/user/:id/deactivate', {id:id});
            return Api.get().$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        service.getFullName = function(userObject) {
            return [
                userObject.firstname,
                userObject.lastname
            ].filter(Boolean).join(' ');
        };

        service.getUserModel = function () {
            return $resource(constants.api.usersPermissions.url + '/v1/user/:id', null, {
                create:  { method: 'POST' },
                update: { method:'PATCH' }
            });
        };

        service.initQsUser = function (user) {
            if (!user.hasOwnProperty('contactTypes')) {
                user.contactTypes = [];
            }
            user.roles = [];
            user.accessTo = [];
            user.deleted = false;
            user.active = true;
            user.qs = true;

            return user;
        };

        service.savePersonalDetails = function (userData) {
            var UserModel = service.getUserModel(),
                dataToUpdate = angular.copy(userData);
            if (dataToUpdate.contactTypes) {
                var formattedContactTypes = [];
                angular.forEach(dataToUpdate.contactTypes, function(contactTypeData, handle) {
                    if (contactTypeData.assigned) {
                        formattedContactTypes.push({
                            handle: handle,
                            primary: contactTypeData.primary ? true : false
                        });
                    }
                });
                dataToUpdate.contactTypes = formattedContactTypes;

                delete dataToUpdate.contactTypesAsString;
            }

            delete dataToUpdate.accessTo;
            delete dataToUpdate.roles;
            delete dataToUpdate.fullname;

            if (userData.id) { // update
                return UserModel.update({id: userData.id}, dataToUpdate).$promise.then(function (data) {
                    if (constants.dev) {
                        $log.log('success, got data: ', data);
                    }
                    return data;
                }, function (error) {
                    //@todo log error
                    if (constants.dev) {
                        $log.log('request failed' + error);
                    }
                    return false;
                });
            } else { // create
                dataToUpdate = service.initQsUser(dataToUpdate);
                return UserModel.create({}, dataToUpdate).$promise.then(function (data) {
                    if (constants.dev) {
                        $log.log('success, got data: ', data);
                    }
                    return data;
                }, function (error) {
                    //@todo log error
                    if (constants.dev) {
                        $log.log('request failed' + error);
                    }
                    return false;
                });
            }
        };

        service.savePermissions = function (userData) {
            var UserModel = service.getUserModel(),
                dataToUpdate = {
                    accessTo : [],
                    roles : [],
                    qs: true
                };

            if (userData.accessTo) {
                for (var section in userData.accessTo) {
                    if (userData.accessTo[section]) {
                        dataToUpdate.accessTo.push(section);
                    }
                }
            }

            if (userData.roles) {
                for (var role in userData.roles) {
                    if (userData.roles[role]) {
                        dataToUpdate.roles.push(role);
                    }
                }
            }

            return UserModel.update({id: userData.id}, dataToUpdate).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        /**
         * Set qs users datagrid columns visibility.
         *
         * @param {Object} columnsVisibility
         */
        service.storeColumnsVisibility = function (columnsVisibility) {
            $localStorage.qsUsersGridColumnsVisibility = columnsVisibility;
        };

        /**
         * Get qs users datagrid columns visibility.
         * @returns {Object|*}
         */
        service.getColumnsVisibility = function () {
            return $localStorage.qsUsersGridColumnsVisibility;
        };

        return service;
    };

    angular
        .module('qsHub')
        .service('QsUsersListService', [
            '$resource',
            '$q',
            '$log',
            '$localStorage',
            'constants',
            'UsersListService',
            'RequestsQueueService',
            App.services.QsUsers
        ]);

}(window.angular, window.moment));

(function (angular) {
    "use strict";

    angular
        .module('qsHub')
        .controller('AdminDashboardController', [
            '$scope',
            'constants',
            'UserFactory',
            'QsUsersListService',
            'AdminDashboardService',
            'InstitutionsUsersListService',
            'TimeService',
            'RankingInfoFormService',
            'StarsInstitutionsListService',
            'TmProfileProgramsService',
            Service
        ]);


    function Service(
        $scope,
        constants,
        UserFactory,
        QsUsersListService,
        AdminDashboardService,
        InstitutionsUsersListService,
        TimeService,
        RankingInfoFormService,
        StarsInstitutionsListService,
        TmProfileProgramsService
    )  {
        var controller = this;

        function limitTo(results, limit) {
            limit = limit * -1;
            var data = {};
            angular.forEach(results, function (item) {
                if (typeof data[item.year] === 'undefined') {
                    data[item.year] = Array.apply(null, Array(12)).map(Number.prototype.valueOf, 0);
                }
                data[item.year][item.month - 1] = item.total;
            });
            return {
                data : Object.keys(data).map(function(item) { return data[item]; }).slice(limit),
                series : Object.keys(data).slice(limit)
            };
        }

        controller.getTimeAgo = function(time) {
                return TimeService.timeAgo(time);
        };

        function loadData() {
            RankingInfoFormService.getData().then(function (response) {
                controller.rankingsList = response.results;
            });

            QsUsersListService.getRoles().then(function (rolesList) {
                var userData = UserFactory.getData();
                if (userData && typeof userData.roles !== 'undefined' && userData.roles) {
                    $scope.userRoles = AdminDashboardService.getUserRoleNames(
                        userData.roles,
                        rolesList
                    );
                    $scope.isCustom = userData.roles.indexOf(constants.customRole) !== -1;
                } else {
                    $scope.isCustom = false;
                    $scope.userRoles = [];
                }
            });

            InstitutionsUsersListService.getAllUsers({
                limit : 5,
                'sorting[lastLoginAt]': 'desc',
                'columns[]': [
                    'fullname',
                    'lastLoginAt',
                    'profileLogo',
                    'email',
                    'primaryInstitutionName'
                ]
            }).then(function (response) {
                controller.clientLastLogins = response.results;
                controller.totalSchoolUsers = response.totalMatching;
            });

            TmProfileProgramsService.getAllProgramsByCoreId(700,{'columns[]': 'coreId'}, true).then(function (response) {
                controller.totalTmPrograms = angular.copy(response.total);
            });

            QsUsersListService.getAllUsers({
                limit : 5,
                'sorting[lastLoginAt]': 'desc',
                'columns[]': [
                    'fullname',
                    'lastLoginAt',
                    'profileLogo',
                    'email'
                ]
            }).then(function (response) {
                controller.qsLastLogins = response.results;
                controller.totalQsUsers = response.totalMatching;
            });

            AdminDashboardService.getLatestProfilesFeeds().then(function (response) {
                controller.lastestPublishes = response.results;
            });

            AdminDashboardService.getLastUpdatedInstitutions().then(function (response) {
                controller.totalSchools = response.totalMatching;
                controller.lastUpdatedInstitutions = response.results;
                AdminDashboardService.getInstitutionsByTypes(controller.totalSchools).then(function(response) {
                    var index = response.indexOf('totalDepartments');
                    controller.totalDepartments = response.splice(index, 1)[0].totalDepartments;
                    controller.institutionsByTypes = response;
                });
            });

            AdminDashboardService.getTuProgramsByType().then(function(response) {
                controller.totalTuProgramsByType = response;
            });

            AdminDashboardService.getNumberOfCourses().then(function(response) {
                controller.totalCourses = response;
            });
            controller.monthsNames = TimeService.getInstance().months();

            AdminDashboardService.getInstitutionsPerMonth().then(function (response) {
                controller.institutionsPerMonth = limitTo(response.results, 3);
            });

            AdminDashboardService.getUsersPerMonth().then(function (response) {
                controller.usersPerMonth = limitTo(response.results, 3);
            });

            AdminDashboardService.getCoursesPerMonth().then(function (response) {
                controller.coursesPerMonth = limitTo(response.results, 3);
            });

            AdminDashboardService.getStarsPerMonth().then(function (response) {
                controller.starsPerMonth = limitTo(response.results, 3);
            });

            AdminDashboardService.getMessagesPerMonth().then(function (response) {
                controller.messagesPerMonth = limitTo(response.results, 3);
            });

            AdminDashboardService.getLoginsPerMonth().then(function (response) {
                controller.loginsPerMonth = limitTo(response.results, 3);
            });

            AdminDashboardService.getAnswersPerRankingPerMonth().then(function (response) {
                var grouped = {};
                angular.forEach(response.results, function (item) {
                    if (typeof grouped[item.ranking] === 'undefined') {
                        grouped[item.ranking] = [];
                    }
                    grouped[item.ranking].push(item);
                });
                angular.forEach(grouped, function (data, year) {
                    grouped[year] = limitTo(data, 3);
                });
                controller.answersPerRankingPerMonth = grouped;
            });

            StarsInstitutionsListService.search({
                raw : true,
                'columns[]' : [
                    'name'
                ],
                limit: 1,
                'sorting[modifiedAt]': 'desc'
            }).then(function (response) {
                controller.totalStarsSchools = response.totalMatching;
            });
        }

        function init() {
            loadData();
        }

        init();
    }

} (window.angular));

(function(angular) {
    "use strict";

    angular
        .module('qsHub')
        .service('AdminDashboardService', [
            'constants',
            '$resource',
            'RequestsQueueService',
            'InstitutionsListService',
            Service
        ]);

    function Service(
        constants,
        $resource,
        RequestsQueueService,
        InstitutionsListService
    ) {
        var service = {};


        function getFeedsModel() {
            return $resource(constants.api.institutions.url + '/v1/list/feeds', {}, {
                get: {
                    isArray: false,
                    cancellable : true
                }
            });
        }

        function getInstitutionsPerMonthModel() {
            return $resource(constants.api.institutions.url + '/v1/admin/view/institutions-per-month', {}, {
                get: {
                    isArray: false,
                    cancellable : true
                }
            });
        }

        function getUsersPerMonthModel() {
            return $resource(constants.api.usersPermissions.url + '/v1/admin/view/users-per-month', {}, {
                get: {
                    isArray: false,
                    cancellable : true
                }
            });
        }

        function getLoginsPerMonthModel() {
            return $resource(constants.api.usersPermissions.url + '/v1/admin/view/logins-per-month', {}, {
                get: {
                    isArray: false,
                    cancellable : true
                }
            });
        }

        function getCoursesPerMonthModel() {
            return $resource(constants.api.institutions.url + '/v1/admin/view/courses-per-month/programs', {}, {
                get: {
                    isArray: false,
                    cancellable : true
                }
            });
        }

        function getStarsPerMonthModel() {
            return $resource(constants.api.stars.url + '/v1/admin/view/stars-per-month', {}, {
                get: {
                    isArray: false,
                    cancellable : true
                }
            });
        }

        function getMessagesPerMonthModel() {
            return $resource(constants.api.messages.url + '/v1/admin/view/messages-per-month', {}, {
                get: {
                    isArray: false,
                    cancellable : true
                }
            });
        }

        function getAnswersPerRankingPerMonthModel() {
            return $resource(constants.api.rankings.url + '/v1/admin/view/answers-per-ranking-per-month', {}, {
                get: {
                    isArray: false,
                    cancellable : true
                }
            });
        }

        function getInstitutionsByTypes() {
            return $resource(constants.api.institutions.url + '/v1/list/institutions/types', {}, {
                get: {
                    isArray: false,
                    cancellable : true
                }
            });
        }

        function getTuProgramsByType() {
            return $resource(constants.api.institutions.url + '/v1/list/tu-programs/type', {}, {
                get: {
                    isArray: false,
                    cancellable : true
                }
            });
        }

        function getNumberOfCourses() {
            return $resource(constants.api.institutions.url + '/v1/total/courses-count', {}, {
                get: {
                    isArray: false,
                    cancellable : true
                }
            });
        }

        service.getUserRoleNames = function (userRoles, rolesList) {
            var result = [],
                totalUserRoles = userRoles ? userRoles.length : 0,
                totalRoles = rolesList ? rolesList.length : 0,
                i,
                y;

            if (totalUserRoles) {
                for (i = 0; i < totalUserRoles; i++) {
                    for (y = 0; y < totalRoles; y++) {
                        if (rolesList[y].roleHandle === userRoles[i]) {
                            if (rolesList[y].roleHandle === constants.globalAdminRole) {
                                return [rolesList[y].roleName];
                            }
                            result.push(rolesList[y].roleName);
                        }

                    }
                }
            }

            return result.sort();
        };

        service.getLatestProfilesFeeds = function () {
            var key = 'getLatestProfilesFeeds',
                matches;
            // cancel currently running requests
            RequestsQueueService.cancelAll(key);
            var Api = getFeedsModel().get({
                raw : true,
                'columns[]' : [
                    'createdAt',
                    'createdByFullName',
                    'feed',
                    'type'
                ],
                limit: 5,
                'sorting[createdAt]': 'desc',
                'filter[status]': 'success',
                'advanced[type][$in][]': ['pg', 'ug', 'master']
            });

            // add new request to the queue
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (response) {
                angular.forEach(response.results, function (item) {
                    matches = atob(item.feed).match(/s:5:"title";s:\d+:"(.*?)";s/m);
                    item.name = matches && typeof matches[1] !== 'undefined' ? matches[1] : '';
                });
                return response;
            });
        };

        service.getLastUpdatedInstitutions = function () {
            var key = 'getLastUpdatedInstitutions';
            // cancel currently running requests
            RequestsQueueService.cancelAll(key);
            var Api = InstitutionsListService.getInstitutionListModel().get({
                raw : true,
                'columns[]' : [
                    'modifiedAt',
                    'modifiedByFullName',
                    'name'
                ],
                limit: 5,
                'sorting[modifiedAt]': 'desc'
            });

            // add new request to the queue
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (response) {
                return response;
            });
        };

        service.getInstitutionsPerMonth = function () {
            var key = 'getInstitutionsPerMonth';
            // cancel currently running requests
            RequestsQueueService.cancelAll(key);
            var Api = getInstitutionsPerMonthModel().get();

            // add new request to the queue
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (response) {
                return response;
            });
        };

        service.getUsersPerMonth = function () {
            var key = 'getUsersPerMonth';
            // cancel currently running requests
            RequestsQueueService.cancelAll(key);
            var Api = getUsersPerMonthModel().get();

            // add new request to the queue
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (response) {
                return response;
            });
        };

        service.getCoursesPerMonth = function () {
            var key = 'getCoursesPerMonth';
            // cancel currently running requests
            RequestsQueueService.cancelAll(key);
            var Api = getCoursesPerMonthModel().get();

            // add new request to the queue
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (response) {
                return response;
            });
        };

        service.getStarsPerMonth = function () {
            var key = 'getStarsPerMonth';
            // cancel currently running requests
            RequestsQueueService.cancelAll(key);
            var Api = getStarsPerMonthModel().get();

            // add new request to the queue
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (response) {
                return response;
            });
        };

        service.getMessagesPerMonth = function () {
            var key = 'getMessagesPerMonth';
            // cancel currently running requests
            RequestsQueueService.cancelAll(key);
            var Api = getMessagesPerMonthModel().get();

            // add new request to the queue
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (response) {
                return response;
            });
        };

        service.getLoginsPerMonth = function () {
            var key = 'getLoginsPerMonth';
            // cancel currently running requests
            RequestsQueueService.cancelAll(key);
            var Api = getLoginsPerMonthModel().get();

            // add new request to the queue
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (response) {
                return response;
            });
        };

        service.getAnswersPerRankingPerMonth = function () {
            var key = 'getAnswersPerRankingPerMonth';
            // cancel currently running requests
            RequestsQueueService.cancelAll(key);
            var Api = getAnswersPerRankingPerMonthModel().get();

            // add new request to the queue
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (response) {
                return response;
            });
        };


        service.getInstitutionsByTypes = function (totalSchools) {
            var key = 'getInstitutionsByTypes';
            // cancel currently running requests
            RequestsQueueService.cancelAll(key);
            var Api = getInstitutionsByTypes().get();

            // add new request to the queue
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (response) {
                var data = [], totalDepartments = 0;
                angular.forEach(response.results, function (column) {
                    var percent = (column.total*100 / totalSchools) + "%";
                    if (column.institutionTypeId === constants.typeId.topLevelInstitutionId) {
                        data.push({'key': 'Top Level Institution', 'value' : column.total, 'percent': percent});
                    } else if (column.institutionTypeId === constants.typeId.clientDepartmentId) {
                        totalDepartments = totalDepartments + column.total;
                        data.push({'key': 'Client Department', 'value' : column.total, 'percent': percent});
                    } else if (column.institutionTypeId === constants.typeId.simpleDepartmentId) {
                        totalDepartments = totalDepartments + column.total;
                        data.push({'key': 'Simple Department', 'value' : column.total, 'percent': percent});
                    } else if (column.institutionTypeId === constants.typeId.advancedProgramId) {
                        data.push({'key': 'Advance Program', 'value' : column.total, 'percent': percent});
                    } else if (column.institutionTypeId === constants.typeId.internalId) {
                        data.push({'key': 'Internal Institution', 'value' : column.total, 'percent': percent});
                    }
                });

                data.push({'totalDepartments': totalDepartments});

                return data;
            });
        };

        service.getTuProgramsByType = function (totalSchools) {
            var key = 'getTuProgramsByType';
            // cancel currently running requests
            RequestsQueueService.cancelAll(key);
            var Api = getTuProgramsByType().get();

            // add new request to the queue
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (response) {
                var data = [];
                angular.forEach(response.results, function (column) {
                    if (column.belongsTo === constants.programTypes.ug) {
                        data.push({'key': 'UG Programs', 'value' : column.total});
                    } else if (column.belongsTo === constants.programTypes.pg) {
                        data.push({'key': 'PG Programs', 'value' : column.total});
                    } else if (column.belongsTo === constants.programTypes.all) {
                        data.push({'key': 'All Programs', 'value' : column.total});
                    }
                });

                return data;
            });
        };

        service.getNumberOfCourses = function (totalSchools) {
            var key = 'getNumberOfCourses';
            // cancel currently running requests
            RequestsQueueService.cancelAll(key);
            var Api = getNumberOfCourses().get();

            // add new request to the queue
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (response) {
                return response.results;
            });
        };

        return service;
    }

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers:{}});

    App.controllers.usersInstitutions = function (
        constants,
        InstitutionsUsersFactory
    ) {
        var controller = this;
        controller.devMode = constants.dev;

        /**
         * Class constructor.
         */
        controller.init = function () {
            InstitutionsUsersFactory.setRequestFilters({});
        };

        controller.init();
    };

    angular
        .module('qsHub')
        .controller('InstitutionsUsersController', [
            'constants',
            'InstitutionsUsersFactory',
            App.controllers.usersInstitutions
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {factories:{}});

    App.factories.InstitutionsUsers = function () {
        var defaultRequestFilters = null,
            requestFilters;
        return {
            getRequestFilters: function () {
                return requestFilters || angular.copy(defaultRequestFilters);
            },
            setRequestFilters: function (newRequestFilters) {
                requestFilters = newRequestFilters;
            },
            resetRequestFilters : function () {
                requestFilters = angular.copy(defaultRequestFilters);
            }
        };
    };

    angular
        .module('qsHub')
        .factory('InstitutionsUsersFactory', [App.factories.InstitutionsUsers]);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { controllers: {} });

    App.controllers.institutionsUsersList = function (
        $scope,
        $timeout,
        constants,
        uiGridConstants,
        uiGridExporterConstants,
        uiGridExporterService,
        InstitutionsUsersListService,
        UsersListService,
        NotifierFactory,
        InstitutionsUsersListFactory,
        WatchService,
        UserTypesService,
        PasswordService,
        GridService,
        UserFactory,
        TimeService,
        DateRangeFixerService,
        TextService,
        ModalService
    ) {
        var controller = this;
        var prevLastLoginAtDateFilter = null,
        lastCreatedAtDateFilter = null,
        filterChangesInProgress = false;
        var selectValues = {},
            lastSelectValues = {};
        var filterTimeoutMilliseconds = constants.datagridInputDelay;
        controller.devMode = constants.dev;
        controller.defaultFilters = {
            lastLoginAtRange: {
                startDate: null,
                endDate: null
            },
            createdAtRange: {
                startDate: null,
                endDate: null
            }
        };
        controller.showDatagrid = false;
        controller.grid = {
            options: {
                data: []
            }
        };
        controller.defaultPaginationOptions = {
            page: 1,
            limit: constants.datagrid.contacts.defaultRowsNumber
        };

        controller.rankingFilter = GridService.getColumnsFilters(
            constants.gridNameSpaces.rankingFilter
        );
        if (angular.equals({}, controller.rankingFilter)) {
            controller.rankingFilter = {
                "value": null,
                "label": "---"
            };
        }

        controller.paginationOptions = GridService.getColumnsFilters(
            constants.gridNameSpaces.schoolUserGridFilters,
            ['lastLoginAtRange', 'createdAtRange']
        );

        controller.columnsVisibility = GridService.getColumnsVisibility(
            constants.gridNameSpaces.schoolUserGridVisibility
        );

        controller.isDatagridReloading = false;
        controller.isDatagridRendered = false;
        controller.rolesBeforeChanges = [];

        $scope.user = {};
        $scope.newUser = {};
        $scope.newUserPanelNotClosed = false;
        $scope.userBeforeChanges = {};
        $scope.forms = {};
        $scope.showInfoBlock = false;
        $scope.filterActive = true;
        $scope.disabledInstitutionsUserListSubTabs = {
            'personalDetails': false,
            'permissions': true,
            'institutions': true
        };
        $scope.filters = null;
        $scope.columnsBeforeHide = [];
        $scope.visible = {};

        controller.hasWriteAccess = UserFactory.hasInstitutionsUsersAccess();
        controller.userTypes = UserTypesService.getUserTypes();
        controller.initSelectedItem = function () {
            $scope.selectedItem = {};
            $scope.selectedItem.selectedOptionType = controller.userTypes[1];
        };
        controller.initSelectedItem();
        controller.contactTypesHasTooltips = [
            'wut_outreach',
            'wmt_outreach',
            'wgst_outreach',
            'database_access_outreach'
        ];

        controller.loadData = function () {
            InstitutionsUsersListService.getContactTypes().then(function (contactTypes) {
                controller.contactTypes = contactTypes;
                var i, to, from;
                for (i = 0; i < controller.contactTypes.length; i++) {
                    if (controller.contactTypes[i]) {
                        if (controller.contactTypes[i].handle === 'wgst_outreach') {
                            to = i + 1;
                        }
                        if (controller.contactTypes[i].handle === 'database_access_outreach') {
                            from = i;
                        }
                    }
                }
                controller.contactTypes.splice(to, 0, controller.contactTypes.splice(from, 1)[0]);
            });
            InstitutionsUsersListService.getRoles().then(function (rolesList) {
                controller.rolesList = rolesList;
            });
            InstitutionsUsersListService.getSections().then(function (sectionsList) {
                controller.sectionsList = sectionsList;
            });
        };

        $scope.handleLastLoginAtDateRange = function (event) {

            if (DateRangeFixerService.sameDayAndNoModel(event, $scope.filters.lastLoginAtRange, prevLastLoginAtDateFilter)) {
                event.model.startDate = prevLastLoginAtDateFilter.startDate;
                event.model.endDate = prevLastLoginAtDateFilter.endDate;
                $scope.filters.lastLoginAtRange = {
                    startDate: prevLastLoginAtDateFilter.startDate,
                    endDate: prevLastLoginAtDateFilter.endDate
                };
            } else if (DateRangeFixerService.todayRangeAndNoModel(event, prevLastLoginAtDateFilter)) {
                event.model.startDate = TimeService.getStartOfToday();
                event.model.endDate = TimeService.getEndOfToday();
                $scope.filters.lastLoginAtRange = {
                    startDate: event.model.startDate.toISOString(),
                    endDate: event.model.endDate.toISOString()
                };
            }

            if (event.model.startDate && event.model.endDate) {
                controller.grid.options.columnDefs[controller.getColumnNumber('lastLoginAt')].filters[0].term = typeof event.model.startDate === 'object' ? event.model.startDate.format('x') : event.model.startDate;
                controller.grid.options.columnDefs[controller.getColumnNumber('lastLoginAt')].filters[1].term = typeof event.model.endDate === 'object' ? event.model.endDate.format('x') : event.model.endDate;
            }

            prevLastLoginAtDateFilter = angular.copy({
                startDate: event.model.startDate,
                endDate: event.model.endDate
            });
        };

        $scope.handleCreatedAtDateRange = function (event) {

            if (DateRangeFixerService.sameDayAndNoModel(event, $scope.filters.createdAtRange, lastCreatedAtDateFilter)) {
                event.model.startDate = lastCreatedAtDateFilter.startDate;
                event.model.endDate = lastCreatedAtDateFilter.endDate;
                $scope.filters.createdAtRange = {
                    startDate: lastCreatedAtDateFilter.startDate,
                    endDate: lastCreatedAtDateFilter.endDate
                };
            } else if (DateRangeFixerService.todayRangeAndNoModel(event, lastCreatedAtDateFilter)) {
                event.model.startDate = TimeService.getStartOfToday();
                event.model.endDate = TimeService.getEndOfToday();
                $scope.filters.createdAtRange = {
                    startDate: event.model.startDate.toISOString(),
                    endDate: event.model.endDate.toISOString()
                };
            }

            if (event.model.startDate && event.model.endDate) {
                controller.grid.options.columnDefs[controller.getColumnNumber('createdAt')].filters[0].term = typeof event.model.startDate === 'object' ? event.model.startDate.format('x') : event.model.startDate;
                controller.grid.options.columnDefs[controller.getColumnNumber('createdAt')].filters[1].term = typeof event.model.endDate === 'object' ? event.model.endDate.format('x') : event.model.endDate;
            }

            lastCreatedAtDateFilter = angular.copy({
                startDate: event.model.startDate,
                endDate: event.model.endDate
            });
        };

        /**
         * Actions to do when datagrid row is clicked.
         *
         * @param {object} user
         */
        $scope.handleDatagridRowClick = function (user) {
            PasswordService.setHasErrors(true);
            $scope.disabledInstitutionsUserListSubTabs.permissions = false;
            $scope.disabledInstitutionsUserListSubTabs.institutions = false;
            if ($scope.selectedUserId === user.id) {
                $scope.selectedUserId = null;
                $scope.showInfoBlock = false;
                $scope.filterActive = true;
                InstitutionsUsersListFactory.resetActiveUserId();
                controller.toggleColumns(false);
            } else { //edit mode
                $scope.selectedUserId = user.id;
                $scope.showInfoBlock = true;
                $scope.filterActive = false;

                $scope.user = angular.copy(user);
                $scope.userBeforeChanges = user;
                $scope.user = UsersListService.updateRolesWithAll($scope.user, controller.rolesList);
                $scope.user = UsersListService.updateSectionsAndPages($scope.user, controller.rolesList);

                controller.rolesBeforeChanges = [];
                for (var role in user.roles) {
                    if (user.roles[role]) {
                        controller.rolesBeforeChanges[role] = true;
                    }
                }

                InstitutionsUsersListFactory.setActiveUserId($scope.selectedUserId);
                controller.toggleColumns(true);
            }
        };

        controller.updateInDatagrid = function (item) {
            var totalItems = controller.grid.options.data.length,
                key = 0;
            for (key; key < totalItems; ++key) {
                if (controller.grid.options.data[key].id === item.id) {
                    controller.grid.options.data[key] = angular.copy($scope.userBeforeChanges);
                    return true;
                }
                $scope.columnsBeforeEdit = null;
                $scope.filterActive = true;
                InstitutionsUsersListFactory.resetActiveUserId();
                $scope.newUserPanelNotClosed = false;
            }
            return false;
        };

        /**
         * Toggle datagrid columns visibility.
         *
         * @param {boolean} hide
         */
        controller.toggleColumns = function (hide) {
            if (!controller.grid.options) {
                return;
            }
            if (hide) {
                angular.forEach(controller.grid.options.columnDefs, function (column) {
                    if (angular.isDefined(column.visible) && column.visible === true) {
                        $scope.columnsBeforeHide.push(column);
                    }
                    $scope.visible[column.field] = column.visible;
                });
                // hide all columns except name
                angular.forEach(controller.grid.options.columnDefs, function (column) {
                    column.visible = column.field === 'email';
                });
            } else {
                // show columns visible before hide
                angular.forEach($scope.columnsBeforeHide, function (column) {
                    column.visible = true;
                });
                $scope.columnsBeforeHide = [];

                $scope.$broadcast(constants.events.closingInstitutionsUsers);
            }

            controller.grid.options.enableGridMenu = !hide;
            controller.grid.options.enableColumnMenus = !hide;
            controller.grid.options.enableColumnResizing = !hide;
            controller.grid.options.enablePaginationControls = !hide;
        };

        /**
         * Reset user form.
         */
        $scope.handleResetClick = function () {
            $scope.user = angular.copy($scope.userBeforeChanges);
        };

        /**
         * Actions to do when deactivation is triggered.
         *
         * @param {Object} user
         * @returns {boolean}
         */
        $scope.handleDeactivateClick = function (user) {
            return;
            if (!user || !user.id || $scope.deactivateInProgress) {
                return false;
            }
            var modalOptions = {
                closeButtonText: 'Cancel',
                actionButtonText: 'Yes',
                actionButtonClass: 'danger',
                headerText: 'Deactivation',
                bodyText: 'Are you sure you wish to deactivate institution user?'
            };
            ModalService.show({}, modalOptions).then(function () {
                $scope.deactivateInProgress = true;
                InstitutionsUsersListService.deactivate(user.id).then(controller.deactivationCallback);
            });
        };

        /**
         * Handle activate click
         *
         * @param {object} user
         * @returns {Boolean}
         */
        $scope.handleActivateClick = function (user) {
            return;
            if (!user.id || $scope.activateInProgress) {
                return false;
            }
            $scope.activateInProgress = true;
            InstitutionsUsersListService.activate(user.id).then(controller.activationCallback);
        };

        controller.deactivationCallback = function (success) {
            $scope.user.active = success ? false : true;
            if (success) {
                $scope.userBeforeChanges.active = $scope.user.active;
                // update user data in datagrid
                controller.updateInDatagrid($scope.userBeforeChanges);
            }
            $scope.deactivateInProgress = false;

            NotifierFactory.show(
                success ? 'success' : 'error',
                success ? 'Deactivated successfully!' : 'Deactivation failed!',
                'Deactivation'
            );
        };

        controller.activationCallback = function (success) {
            $scope.user.active = success ? true : false;
            if (success) {
                $scope.userBeforeChanges.active = $scope.user.active;
                // update user data in datagrid
                controller.updateInDatagrid($scope.userBeforeChanges);
            }
            $scope.activateInProgress = false;

            NotifierFactory.show(
                success ? 'success' : 'error',
                success ? 'Activated successfully!' : 'Activation failed!',
                'Activation'
            );
        };

        $scope.handleNewUserResponse = function (response) {
            if (response.hasOwnProperty('insertedId') && response.insertedId.length > 0) {
                $scope.user.id = response.insertedId;
                $scope.user.active = true;
                angular.copy($scope.user, $scope.userBeforeChanges);
                // switch tab after user add
                $scope.activeTab = 1;
                $scope.disabledInstitutionsUserListSubTabs.permissions = false;
                $scope.disabledInstitutionsUserListSubTabs.institutions = false;
                angular.copy($scope.user, $scope.newUser);
                $scope.selectedUserId = $scope.user.id;
                $scope.newUserPanelNotClosed = true;
                // reload datagrid
                controller.reloadDatagrid();
            }
        };

        /**
         * Store user data.
         */
        $scope.handlePersonalDetailsSubmit = function () {
            return;
            $scope.forms.personalDetailsForm.firstname.$setDirty();
            $scope.forms.personalDetailsForm.lastname.$setDirty();
            $scope.forms.personalDetailsForm.email.$setDirty();
            PasswordService.setHasErrors(
                $scope.user.id ||
                !!$scope.forms.personalDetailsForm.password.$viewValue ? true : false
            );
            var newUser = typeof $scope.user.id === 'undefined';
            if (
                $scope.personalDetailsSubmitInProgress ||
                !$scope.forms.personalDetailsForm ||
                !$scope.forms.personalDetailsForm.$valid ||
                !PasswordService.isValid($scope.forms.personalDetailsForm, $scope.user) ||
                !PasswordService.getHasErrors()
            ) {
                return false;
            }

            // if is new user and the password is not set or setting password for existing user
            if (
                (newUser && !PasswordService.isPasswordValid($scope.user.password)) ||
                (!newUser && typeof $scope.user.password !== 'undefined' && !PasswordService.isPasswordValid($scope.user.password))
            ) {
                NotifierFactory.show(
                    'error',
                    'Please set a valid password'
                );

                return false;
            }
            $scope.personalDetailsSubmitInProgress = true;
            InstitutionsUsersListService.savePersonalDetails($scope.user).then(function (response) {
                var message = 'Personal Details',
                    success = false;
                if (response) {
                    success = !response.error;
                    if (!success) {
                        if (response.hasOwnProperty('message') && response.message.length) {
                            message = response.message;
                        }
                    } else {
                        $scope.user.fullname = InstitutionsUsersListService.getFullName($scope.user);
                        if (response.hasOwnProperty('insertedId') && response.insertedId.length) {
                            $scope.user.id = response.insertedId;
                            $scope.user.active = true;
                            angular.copy($scope.user, $scope.userBeforeChanges);
                            // switch tab after user add
                            $scope.activeTab = 1;
                            $scope.disabledInstitutionsUserListSubTabs.permissions = false;
                            $scope.disabledInstitutionsUserListSubTabs.institutions = false;
                            angular.copy($scope.user, $scope.newUser);
                            $scope.selectedUserId = $scope.user.id;
                        }
                        //reset personalDetailsForm
                        controller.resetPersonalDetailsForm();
                    }

                    if (success && $scope.user.contactTypes) {
                        InstitutionsUsersListService.getContactTypes().then(function (contactTypesList) {
                            if (!contactTypesList) {
                                return false;
                            }
                            var contactTypeNames = [],
                                contactTypeName = null;

                            for (var contactType in $scope.user.contactTypes) {
                                if ($scope.user.contactTypes[contactType].assigned) {
                                    contactTypeName = UsersListService.getContactTypeNameByHandle(
                                        contactType, contactTypesList
                                    );
                                    contactTypeNames.push(contactTypeName);
                                }
                            }
                            $scope.user.contactTypesAsString = contactTypeNames.join(', ');
                        });
                    }
                    if (success) {
                        delete $scope.user.password;
                        // update original user object with changes
                        $scope.userBeforeChanges = angular.copy($scope.user);
                        // reload  datagrid
                        controller.reloadDatagrid();
                    }
                }
                $scope.personalDetailsSubmitInProgress = false;
                NotifierFactory.show(
                    success ? 'success' : 'error',
                    success ? 'Saved successfully!' : 'Saving failed!',
                    message
                );
            });
        };

        $scope.handlePermissionsSubmit = function () {
            return;
            $scope.permissionsSubmitInProgress = true;
            InstitutionsUsersListService.savePermissions($scope.user).then(function (success) {
                $scope.permissionsSubmitInProgress = false;
                $scope.userBeforeChanges = angular.copy($scope.user);
                controller.rolesBeforeChanges = [];
                for (var role in $scope.user.roles) {
                    if ($scope.user.roles[role]) {
                        controller.rolesBeforeChanges[role] = true;
                    }
                }
                if (success) {
                    controller.updateInDatagrid($scope.user);
                }
                NotifierFactory.show(
                    success ? 'success' : 'error',
                    success ? 'Saved successfully!' : 'Saving failed!',
                    'Permissions'
                );
            });
        };

        $scope.handlePrimaryContactTypeClick = function (handle) {
            $scope.user.contactTypes[handle].assigned = true;
        };

        $scope.handleContactTypeClick = function (handle) {
            if ($scope.user.contactTypes[handle] &&
                $scope.user.contactTypes[handle].primary &&
                !$scope.user.contactTypes[handle].assigned
            ) {
                $scope.user.contactTypes[handle].primary = false;
            }
        };

        $scope.handleAssignedContactTypeClick = function (handle) {
            if ($scope.user.contactTypes[handle].assigned === false) {
                $scope.user.contactTypes[handle].primary = false;
            }
        };

        $scope.handleRoleClick = function (clickedRole) {
            if ($scope.user.roles.custom || typeof clickedRole === 'undefined') {
                return;
            }

            $scope.user.accessTo = {};
            var clickedRoleAssigned = $scope.user.roles[clickedRole.roleHandle],
                parentRoleClicked = clickedRole.all,
                childrenRolesHandles = UsersListService.getChildrenRoles(clickedRole.groupHandle, controller.rolesList),
                parentRoleHandle = null;

            // if role is assigned
            if (clickedRoleAssigned) {
                // if its parent role
                if (parentRoleClicked) {
                    // assign children roles to a user
                    if (childrenRolesHandles) {
                        angular.forEach(childrenRolesHandles, function (childrenRoleHandle) {
                            $scope.user.roles[childrenRoleHandle] = true;
                        });
                    }
                } else {
                    if (childrenRolesHandles) {
                        // Select parent if all children were selected
                        var allDepartments = true;
                        angular.forEach(childrenRolesHandles, function (childrenRoleHandle) {
                            if ($scope.user.roles[childrenRoleHandle] === false ||
                                $scope.user.roles[childrenRoleHandle] === undefined
                            ) {
                                allDepartments = false;
                                return;
                            }
                        });
                        if (allDepartments) {
                            parentRoleHandle = UsersListService.getParentRoleHandle(clickedRole.groupHandle, controller.rolesList);
                            if (parentRoleHandle) {
                                $scope.user.roles[parentRoleHandle] = true;
                            }
                        }
                    }
                }
            } else {
                if (!parentRoleClicked) {
                    // Uncheck parent if it was selected when unselecting one children
                    parentRoleHandle = UsersListService.getParentRoleHandle(clickedRole.groupHandle, controller.rolesList);
                    if (parentRoleHandle) {
                        $scope.user.roles[parentRoleHandle] = false;
                    }
                } else {
                    // check if all children where selected to select the parent
                    if (childrenRolesHandles) {
                        angular.forEach(childrenRolesHandles, function (childrenRoleHandle) {
                            $scope.user.roles[childrenRoleHandle] = false;
                        });
                    }
                }
            }
            // Update sections and pages based on the new roles
            $scope.user = UsersListService.updateSectionsAndPages($scope.user, controller.rolesList);
        };

        $scope.activateCustomRole = function () {
            if (!angular.isDefined($scope.user.roles)) {
                $scope.user.roles = {};
            }
            $scope.user.roles.custom = true;
        };

        /**
         * Actions to do when column is clicked under visible columns dropdown.
         */
        $scope.handleColumnClick = function () {
            var columnsVisibility = {};
            angular.forEach($scope.columns, function (column) {
                columnsVisibility[column.field] = column.show;
            });
            InstitutionsUsersListService.storeColumnsVisibility(columnsVisibility);
        };

        controller.handleAddInstitutionsUserClick = function () {
            window.location.replace('https://qs-hub2.qs.com/user-permission/');
            return;
            controller.resetPersonalDetailsForm();
            $scope.selectedUserId = null;
            $scope.user = {};
            InstitutionsUsersListFactory.resetActiveUserId();
            // set personal details tab as active
            $scope.activeTab = 0;
            $scope.disabledInstitutionsUserListSubTabs.permissions = true;
            $scope.disabledInstitutionsUserListSubTabs.institutions = true;
            // trigger edit mode
            controller.toggleColumns(true);
            $scope.showInfoBlock = true;
            $scope.filterActive = false;
        };

        //reset personalDetailsForm
        controller.resetPersonalDetailsForm = function () {
            if ($scope.forms.personalDetailsForm) {
                $scope.forms.personalDetailsForm.$setPristine();
            }
        };

        controller.handleEditCloseClick = function () {
            controller.toggleColumns(false);
            $scope.showInfoBlock = false;
            $scope.selectedUserId = null;
            $scope.activeTab = 0;
        };

        /**
         * Apply datagrid columns visibility from browser cache.
         */
        controller.loadDatagridColumnsVisibility = function () {
            var columnsVisibility = InstitutionsUsersListService.getColumnsVisibility();
            if (columnsVisibility) {
                angular.forEach($scope.columns, function (column) {
                    if (columnsVisibility[column.field] !== 'undefined') {
                        column.show = columnsVisibility[column.field] ? true : false;
                    }
                });
            }
        };

        controller.getPage = function () {
            controller.isDatagridReloading = true;
            controller.paginationOptions['filter[ranking]'] = controller.rankingFilter.value;
            return InstitutionsUsersListService.getAllUsers(
                angular.merge({}, controller.paginationOptions, controller.defaultPaginationOptions)
            ).then(function (response) {
                controller.grid.options.totalItems = response.totalMatching;
                controller.grid.options.data = response.results;
                controller.grid.options.minRowsToShow = response.totalFiltered;
                controller.isDatagridReloading = false;
                controller.showDatagrid = true;
            });
        };

        controller.reloadDatagrid = function () {
            controller.getPage();
        };

        controller.initDataGridOptions = function () {
            var selectTemplate = '/scripts/shared/ui-grid/templates/selectFilterHeaderTemplate.html',
                selectCellTemplate = '/scripts/shared/ui-grid/templates/selectCellTemplate.html',
                dateFilterCellTemplate = '/scripts/shared/ui-grid/templates/dateFilterCellTemplate.html';
            controller.grid.options = {
                data: [],
                enableSorting: true,
                enableGridMenu: true,
                showGridFooter: false,
                selectedItems: $scope.selectedUserId,
                enableFiltering: true,
                enableColumnResize: true,
                enableFullRowSelection: true,
                enableRowSelection: true,
                multiSelect: false,
                enableRowHeaderSelection: false,
                paginationPageSizes: [constants.datagrid.contacts.defaultRowsNumber, 50, 100],
                paginationPageSize: constants.datagrid.contacts.defaultRowsNumber,
                useExternalPagination: true,
                useExternalSorting: true,
                useExternalFiltering: true,
                exporterMenuCsv: false,
                exporterMenuAllData: false,
                exporterMenuPdf: false,
                exporterCsvFilename: 'institution-users-export.' + TimeService.now() + '.csv',
                gridMenuCustomItems: [
                    {
                        title: ' Export visible data as csv',
                        action: controller.exportVisible,
                        order: 209
                    },
                    {
                        templateUrl: '/scripts/components/users/list/institutions/datagrid/exportAllMenuItem.html',
                        title: ' Export all data as csv',
                        action: controller.exportAll,
                        order: 210
                    }
                ],
                rowTemplate: '/scripts/components/users/list/institutions/datagrid/rowTemplate.html',
                columnDefs: [
                    {
                        displayName: 'Email',
                        field: 'email',
                        cellFilter: 'lowercase',
                        visible:true,
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'email', ''),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        displayName: 'Full Name',
                        field: 'fullname',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'fullname', true),
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'fullname', ''),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        displayName: 'Position',
                        field: 'position',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'position', false),
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'position', ''),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        displayName: 'Phone',
                        field: 'phone',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'phone', false),
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'phone', ''),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        displayName: 'Primary Institution Core Id',
                        field: 'primaryInstitutionCoreId',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'primaryInstitutionCoreId', true),
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'primaryInstitutionCoreId', ''),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        displayName: 'Primary Institution',
                        field: 'primaryInstitutionName',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'primaryInstitutionName', true),
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'primaryInstitutionName', ''),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        displayName: 'Contact Types',
                        filter: {
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: [],
                            term: GridService.getFilterByField(controller.paginationOptions, 'contactTypes.handle')
                        },
                        field: 'contactTypesAsString',
                        enableSorting: false,
                        maxWidth: 130,
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'contactTypes.handle', true),
                        filterHeaderTemplate: selectTemplate
                    },
                    {
                        displayName: 'Last Login',
                        field: 'lastLoginAt',
                        filterCellFiltered: true,
                        filters: [
                            {
                                visible: true,
                                condition: uiGridConstants.filter.GREATER_THAN
                            },
                            {
                                condition: uiGridConstants.filter.LESS_THAN
                            }
                        ],
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'lastLoginAt', ''),
                            applyTextFilter: controller.applyDateFilter('lastLoginAt', '')
                        },
                        cellTemplate: dateFilterCellTemplate,
                        filterHeaderTemplate: '/scripts/components/users/list/institutions/datagrid/lastLoginAtFilterHeaderTemplate.html',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'lastLoginAt', true)
                    },
                    {
                        displayName: 'Date Created',
                        field: 'createdAt',
                        filterCellFiltered: true,
                        filters: [
                            {
                                visible: true,
                                condition: uiGridConstants.filter.GREATER_THAN
                            },
                            {
                                condition: uiGridConstants.filter.LESS_THAN
                            }
                        ],
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'createdAt', ''),
                            applyTextFilter: controller.applyDateFilter('createdAt', '')
                        },
                        cellTemplate: dateFilterCellTemplate,
                        filterHeaderTemplate: '/scripts/components/users/list/institutions/datagrid/createdAtFilterHeaderTemplate.html',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'createdAt', true)
                    },
                    {
                        displayName: 'Active',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'active', true),
                        field: 'active',
                        filter: {
                            column: 'active',
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: [
                                { value: true, label: 'Yes' },
                                { value: false, label: 'No' }
                            ],
                            term: GridService.getFilterByField(controller.paginationOptions, 'active')
                        },
                        maxWidth: 80,
                        filterHeaderTemplate: selectTemplate,
                        cellTemplate: selectCellTemplate
                    }
                ],
                onRegisterApi: function (gridApi) {
                    var columnsVisibilityBeforeChanges = angular.copy(controller.grid.options.columnDefs);
                    $scope.gridApi = gridApi;
                    gridApi.selection.on.rowSelectionChanged($scope, function (row) {
                        $scope.handleDatagridRowClick(row.entity);
                    });
                    gridApi.core.on.rowsRendered($scope, function () {
                        $timeout(function(){
                            controller.isDatagridRendered = true;
                        });
                        GridService.generateSelectValues($scope.gridApi.grid.columns, selectValues);
                        GridService.generateSelectValues($scope.gridApi.grid.columns, lastSelectValues);
                    });
                    gridApi.core.on.filterChanged($scope, controller.handleGridFiltersChanges);
                    gridApi.core.on.rowsVisibleChanged($scope, controller.handleGridVisibleChanges);

                    gridApi.pagination.on.paginationChanged($scope, function (newPage, limit) {
                        controller.defaultPaginationOptions.page = newPage;
                        controller.defaultPaginationOptions.limit = limit;
                        if(!filterChangesInProgress) { 
                            controller.getPage();
                        }
                    });
                    $scope.gridApi.core.on.sortChanged($scope, controller.handleGridFiltersChanges);
                    // reset columns visibility
                    GridService.resetExtend(columnsVisibilityBeforeChanges, controller.grid.options.columnDefs, $scope.filters);
                }
            };
            // This is needed to add 'x' icon in date field when prepopulating dates
            var lastLogin = GridService.getFilterByField(controller.paginationOptions, 'lastLoginAt', '');
            if (lastLogin !== null && controller.paginationOptions["filter[lastLoginAt]"] !== null) {
                if (angular.isDefined(lastLogin) &&
                    angular.isDefined(controller.paginationOptions["filter[lastLoginAt]"]) &&
                    angular.isDefined(controller.paginationOptions["filter[lastLoginAt]"].startDate) &&
                    angular.isDefined(controller.paginationOptions["filter[lastLoginAt]"].endDate)
                ) {
                    controller.grid.options.columnDefs[controller.getColumnNumber('lastLoginAt')].filters[0].term = controller.paginationOptions["filter[lastLoginAt]"].startDate;
                    controller.grid.options.columnDefs[controller.getColumnNumber('lastLoginAt')].filters[1].term = controller.paginationOptions["filter[lastLoginAt]"].endDate;
                }
            }

            var createdAt = GridService.getFilterByField(controller.paginationOptions, 'createdAt', '');
            if (createdAt !== null && controller.paginationOptions["filter[createdAt]"] !== null) {
                if (angular.isDefined(createdAt) &&
                    angular.isDefined(controller.paginationOptions["filter[createdAt]"]) &&
                    angular.isDefined(controller.paginationOptions["filter[createdAt]"].startDate) &&
                    angular.isDefined(controller.paginationOptions["filter[createdAt]"].endDate)
                ) {
                    controller.grid.options.columnDefs[controller.getColumnNumber('createdAt')].filters[0].term = controller.paginationOptions["filter[createdAt]"].startDate;
                    controller.grid.options.columnDefs[controller.getColumnNumber('createdAt')].filters[1].term = controller.paginationOptions["filter[createdAt]"].endDate;
                }
            }
        };

        controller.exportAll = function () {
            InstitutionsUsersListService.getAllUsers(
                angular.merge({}, controller.paginationOptions, {
                'filter[export]': true
            })).then(function (response) {
                if (typeof response.results === 'undefined') {
                    return false;
                }
                response = InstitutionsUsersListService.formatCsvResults(response.results, TimeService);
                if (!angular.isDefined($scope.gridOptions)) {
                    $scope.gridOptions = controller.grid.options;
                }
                var  gridApi = $scope.gridApi,
                     gridOptions = $scope.gridOptions,
                     exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                     gridApi.grid,
                    uiGridExporterConstants.VISIBLE
                    ),
                    exportData = GridService.getCsvData(
                        gridApi.grid.columns,
                        response
                    ),
                    csvContent = uiGridExporterService.formatAsCsv(
                        exportColumnHeaders,
                        exportData,
                        gridOptions.exporterCsvColumnSeparator
                    );
                uiGridExporterService.downloadFile(
                    gridOptions.exporterCsvFilename,
                    csvContent,
                    gridOptions.exporterOlderExcelCompatibility
                );
            });
        };

        controller.exportVisible = function () {
            var results = controller.grid.options.data;
            results = InstitutionsUsersListService.formatCsvResults(results, TimeService);
            if (typeof results === 'undefined') {
                return false;
            }
            if (!angular.isDefined($scope.gridOptions)) {
                $scope.gridOptions = controller.grid.options;
            }
            var gridApi = $scope.gridApi,
                gridOptions = $scope.gridOptions,
                exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                gridApi.grid,
                uiGridExporterConstants.VISIBLE
                ),
                exportData = GridService.getCsvData(
                    gridApi.grid.columns,
                    results
                ),
                csvContent = uiGridExporterService.formatAsCsv(
                    exportColumnHeaders,
                    exportData,
                    gridOptions.exporterCsvColumnSeparator
                );
            uiGridExporterService.downloadFile(
                $scope.gridOptions.exporterCsvFilename,
                csvContent,
                gridOptions.exporterOlderExcelCompatibility
            );
        };

        controller.getColumnNumber = function (columnName) {
            var key = 0,
                total = controller.grid.options.columnDefs.length;
            for (key; key < total; key++) {
                if (controller.grid.options.columnDefs[key] &&
                    controller.grid.options.columnDefs[key].field === columnName
                ) {
                    return key;
                }
            }
            return null;
        };

        controller.handleGridFiltersChanges = function () {
            filterChangesInProgress = true;
            if($scope.gridApi.pagination.getPage() > 1){
                $scope.gridApi.pagination.seek(1);
            }
            controller.paginationOptions = angular.copy(controller.defaultPaginationOptions);
            var filterColumn, sortColumn, sortKeys = [];

            angular.forEach($scope.gridApi.grid.columns, function (column) {
                if (typeof column.filters !== 'undefined' && column.filters[0].term !== 'undefined') {
                    if (
                        column.filters[0].type === uiGridConstants.filter.SELECT &&
                        typeof column.filters[0].term === 'object' &&
                        column.filters[0].term !== null &&
                        typeof column.filters[0].term.value !== 'undefined'
                    ) {
                        column.filters[0].term = column.filters[0].term.value;
                    }
                }
                if (typeof column.sort.priority !== 'undefined') {
                    sortKeys[column.sort.priority] = column.name;
                }
            });
            if (sortKeys.length > 1) {
                var latestSortKey = sortKeys.pop();
                angular.forEach($scope.gridApi.grid.columns, function (column) {
                    if (typeof column.sort.priority !== 'undefined' && column.name !== latestSortKey) {
                        column.sort = {};
                    }
                });
            }

            angular.forEach($scope.gridApi.grid.columns, function (column) {
                filterColumn = column.filters[0].column ? column.filters[0].column : column.field;
                if (column.field === 'lastLoginAt' || column.field === 'createdAt') {
                    if (
                        typeof column.filters !== 'undefined' &&
                        typeof column.filters[0].term !== 'undefined' &&
                        typeof column.filters[1].term !== 'undefined'
                    ) {
                        controller.paginationOptions['filter[' + filterColumn + ']'] = {
                            startDate: column.filters[0].term && isNaN(column.filters[0].term) && typeof column.filters[0].term === 'object' ?
                                column.filters[0].term.format('x') : column.filters[0].term,
                            endDate: column.filters[1].term && isNaN(column.filters[1].term) && typeof column.filters[1].term === 'object' ?
                                column.filters[1].term.format('x') : column.filters[1].term
                        };
                    } else {
                        controller.paginationOptions['filter[' + filterColumn + ']'] = null;
                    }
                } else if ((column.field === 'contactTypesAsString' ||
                    column.field === 'contactTypes.handle') &&
                    typeof column.filters !== 'undefined' &&
                    column.filters[0].term !== 'undefined'
                ) {
                    controller.paginationOptions['filter[contactTypes.handle]'] = column.filters[0].term;
                } else {
                    if (typeof column.filters !== 'undefined' && column.filters[0].term !== 'undefined') {
                        controller.paginationOptions['filter[' + filterColumn + ']'] = column.filters[0].term;
                    }
                }
                if (typeof column.sort.direction !== 'undefined') {
                    sortColumn = column.sort.column ? column.sort.column : column.field;
                    controller.paginationOptions['sorting[' + sortColumn + ']'] = column.sort.direction;
                }
            });

            if (controller.paginationOptions["filter[lastLoginAt]"] === null) {
                controller.paginationOptions["filter[lastLoginAt]"] = $scope.filters.lastLoginAtRange;
            }
            if (controller.paginationOptions["filter[lastLoginAt]"].startDate === null) {
                controller.paginationOptions["filter[lastLoginAt]"].endDate = null;
                $scope.filters.lastLoginAtRange = {
                    startDate: null,
                    endDate: null
                };
            }

            if (controller.paginationOptions["filter[createdAt]"] === null) {
                controller.paginationOptions["filter[createdAt]"] = $scope.filters.createdAtRange;
            }
            if (controller.paginationOptions["filter[createdAt]"].startDate === null) {
                controller.paginationOptions["filter[createdAt]"].endDate = null;
                $scope.filters.createdAtRange = {
                    startDate: null,
                    endDate: null
                };
            }

            GridService.storeColumnsFilters(
                constants.gridNameSpaces.schoolUserGridFilters,
                controller.paginationOptions
            );

            if (angular.isDefined($scope.filterTimeout)) {
                $timeout.cancel($scope.filterTimeout);
            }

            GridService.generateSelectValues($scope.gridApi.grid.columns, selectValues);

            if (!angular.equals(selectValues, lastSelectValues)) {
                filterTimeoutMilliseconds = constants.datagridSelectDelay;
            }

            $scope.filterTimeout = $timeout(function () {
                controller.getPage();
                filterChangesInProgress = false;
                lastSelectValues = angular.copy(selectValues);
                filterTimeoutMilliseconds = constants.datagridInputDelay;
            }, filterTimeoutMilliseconds);
        };

        controller.handleGridVisibleChanges = function () {
            var columnsVisibility = GridService.getColumnsVisibility(constants.gridNameSpaces.schoolUserGridVisibility),
                reload = false;

            if (columnsVisibility && $scope.filterActive) {
                angular.forEach(controller.grid.options.columnDefs, function (column, key) {
                    if ((columnsVisibility[column.field] === true || typeof columnsVisibility[column.field] === 'undefined') &&
                        column.visible === false
                    ) {
                        if (typeof  controller.grid.options.columnDefs[key].filter === 'undefined') {
                            controller.grid.options.columnDefs[key].filter = {};
                        }
                        if (typeof  controller.grid.options.columnDefs[key].filter !== 'undefined' &&
                            controller.grid.options.columnDefs[key].filter.hasOwnProperty('term') &&
                            (controller.grid.options.columnDefs[key].filter.term || controller.grid.options.columnDefs[key].filter.term === false)) {
                            reload = true;
                        }
                        controller.grid.options.columnDefs[key].filter.term = null;
                        controller.paginationOptions['filter[' + column.field + ']'] = null;
                        if (column.field === 'lastLoginAt') {
                            delete controller.grid.options.columnDefs[key].filters[0].term;
                            reload =   $scope.filters.lastLoginAtRange.startDate? true : false;
                            $scope.filters.lastLoginAtRange = {
                                startDate: null,
                                endDate: null
                            };
                        } else if (column.field === 'createdAt') {
                            delete controller.grid.options.columnDefs[key].filters[0].term;
                            reload =   $scope.filters.createdAtRange.startDate? true : false;
                            $scope.filters.createdAtRange = {
                                startDate: null,
                                endDate: null
                            };
                        }
                    }
                });
            }
            if ($scope.filterActive) {
                controller.saveStateVisibility();
            }
            if (reload) {
                controller.getPage();
            }
        };

        /**
         * Populates input text field for date field
         *
         * @param {string} filterName
         * @param {mixed} defaultValue
         */
        controller.applyDateFilter = function (filterName, defaultValue) {
            var filter = GridService.getFilterByField(controller.paginationOptions, filterName, defaultValue);
            if (angular.isDefined(filter) &&
                filter !== null
            ) {
                if (angular.isDefined(filter.startDate) &&
                    angular.isDefined(filter.endDate)
                ) {
                    if (filter.startDate !== null &&
                        filter.startDate !== null
                    ) {
                        controller.defaultFilters[filterName + 'Range'] = {
                            startDate: parseInt(filter.startDate, 10),
                            endDate: parseInt(filter.endDate, 10)
                        };
                    } else {
                        controller.defaultFilters[filterName + 'Range'] = {
                            startDate: null,
                            endDate: null
                        };
                    }
                }
            }
        };

        controller.handleRankingFilterChanges = function () {
            var selectedRanking = null;
            if (controller.rankingFilter && controller.rankingFilter.value) {
                selectedRanking = controller.rankingFilter.value;
            }
            GridService.storeColumnsFilters(
                constants.gridNameSpaces.rankingFilter,
                controller.rankingFilter
            );
            controller.paginationOptions['filter[ranking]'] = selectedRanking;
            controller.reloadDatagrid();
        };

        controller.initDataGrid = function () {
            $scope.filters = angular.extend({}, controller.defaultFilters);
            controller.handleRankingFilterChanges();
            controller.getPage().then(function () {
                InstitutionsUsersListService.getContactTypesFilter(true).then(function (options) {
                    var list = [];
                    angular.forEach(options, function (option) {
                        list.push({
                            value: option.id,
                            label: option.title
                        });
                    });
                    controller.grid.options.columnDefs[controller.getColumnNumber('contactTypesAsString')].filter.selectOptions = list;
                });

                InstitutionsUsersListService.getRankingsList().then(function (list) {
                    controller.rankingsList = list? list : [];
                });
            });
            controller.handleRankingFilterChanges();
        };

        controller.convertVisibleColumnsField = function (value) {
            switch (value) {
                case 'contactTypesAsString':
                    return 'contactTypes.handle';
            }
        };

        controller.saveStateVisibility = function () {
            var visibility = angular.copy(controller.grid.options.columnDefs);
            angular.forEach(visibility, function (column) {
                if (['contactTypesAsString'].indexOf(column.field) !== -1) {
                    column.field = controller.convertVisibleColumnsField(column.field);
                }
            });
            GridService.storeColumnsVisibility(
                constants.gridNameSpaces.schoolUserGridVisibility,
                GridService.getGridColumnsVisibility(visibility)
            );
        };

        controller.contactTypeHasTooltip = function (handle) {
            return controller.contactTypesHasTooltips.indexOf(handle) !== -1;
        };

        controller.getContactTypeTooltipUrl = function (handle) {
            return "/scripts/components/users/list/templates/" + TextService.underScoreToCamelCase(handle) + "Tooltip.html";
        };

        controller.isValidFirstName = function () {
            return PasswordService.isValidFirstName($scope.forms.personalDetailsForm, $scope.user);
        };

        controller.isValidLastName = function () {
            return PasswordService.isValidLastName($scope.forms.personalDetailsForm, $scope.user);
        };

        controller.isValidEmail = function () {
            return PasswordService.isValidEmail($scope.forms.personalDetailsForm, $scope.user);
        };

        controller.setValid = function (fieldName) {
            return PasswordService.setValid($scope.forms.personalDetailsForm, fieldName);
        };

        controller.initWatches = function () {
            // listen to active profile tab changes
            WatchService.create($scope, 'activeTab', function (newValue) {
                InstitutionsUsersListFactory.setSelectedTabId(newValue);
            });

            $scope.$on(constants.events.institutionsUserPrimaryInstitutionChanges, function () {
                $scope.userBeforeChanges = angular.copy($scope.user);
                controller.updateInDatagrid($scope.user);
            });
        };

        /**
         * Class constructor.
         */
        controller.$onInit = function () {
            controller.loadData();
            controller.initDataGridOptions();
            controller.initWatches();
            controller.loadDatagridColumnsVisibility();
            controller.initDataGrid();
        };

        controller.$onDestroy = function () {

        };
    };

    angular
        .module('qsHub')
        .controller('InstitutionsUsersListController', [
            '$scope',
            '$timeout',
            'constants',
            'uiGridConstants',
            'uiGridExporterConstants',
            'uiGridExporterService',
            'InstitutionsUsersListService',
            'UsersListService',
            'NotifierFactory',
            'InstitutionsUsersListFactory',
            'WatchService',
            'UserTypesService',
            'PasswordService',
            'UiGridService',
            'UserFactory',
            'TimeService',
            'DateRangeFixerService',
            'TextService',
            'ModalService',
            App.controllers.institutionsUsersList
        ]);

} (window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    App.services.InstitutionsUsers = function (
        $resource,
        $q,
        $log,
        $localStorage,
        constants,
        UsersListService,
        RequestsQueueService
    ) {
        var service = {
            contactTypes : null,
            roles : null,
            sections : null,
            deferred : {
                getContactTypes: null,
                getRoles: null,
                getSections: null
            },
            cache: {}
        };

        service.getInstitutionsUsersListModel = function () {
            return $resource(constants.api.usersPermissions.url + '/v1/list/client-users', {}, {
                get: {
                    isArray:false,
                    cancellable : true
                }
            });
        };

        service.getUserModel = function () {
            return $resource(constants.api.usersPermissions.url + '/v1/user/:id', null, {
                update: { method:'PATCH' },
                replace: { method:'PUT' }
            });
        };

        service.getUserInstitutionModel = function () {
            return $resource(constants.api.usersPermissions.url + '/v1/user/institution', null, {
                create: { method: 'POST' }
            });
        };

        service.getRankingsModel = function () {
            return $resource(constants.api.institutions.url + '/v1/list/subscriptions?filter[type]=statistics&sorting[abbreviation]=asc', null, {
                get: {
                    isArray: false,
                    cancellable : true
                }
            });
        };

        service.getRankingsList = function () {
            if (constants.dev) {
                var startTime = new Date().getTime(),
                    endTime;
            }
            var list = [
                {value: null, label: '---'}
            ];
            return service.getRankingsModel().get().$promise.then(function (data) {
                if (constants.dev) {
                    endTime = new Date().getTime();
                    $log.log('success, got data: ', data, 'in ' + (endTime - startTime) + ' ms');
                }

                if (data && data.results) {
                    angular.forEach(data.results, function (item) {
                        if (item.handle !== 'all' && item.handle !== 'stars') {
                            list.push({
                                value : item.handle,
                                label : item.abbreviation
                            });
                        }
                    });
                }
                return list;
            }, function (error) {
                if (constants.dev) {
                    $log.log('request failed' + error);
                }

                return false;
            });
        };

        /**
         * Get all QS users.
         *
         * @param {Object} params
         * @returns {Promise:Array}
         */
        service.getAllUsers = function (filters) {
            if (constants.dev) {
                var startTime = new Date().getTime(), endTime;
            }

            // cancel currently running requests
            RequestsQueueService.cancelAll('getAllInstitutionsUsers');
            var hasSorting = false,
                 allowedFilters = [
                     'limit',
                     'page'
                 ];
            if (filters) {
                for (var key in filters) {
                    if (key.indexOf('sorting') !== -1) {
                        hasSorting = true;
                        break;
                    } else {
                        if (key.indexOf('filter') !== 0 && allowedFilters.indexOf(key) === -1) {
                            delete filters[key];
                        }
                    }
                }
            }
            if (!hasSorting) {
                filters['sorting[createdAt]'] = 'desc';
            }
            var Api = service.getInstitutionsUsersListModel().get(filters);

            // add new request to the queue
            RequestsQueueService.add(Api, 'getAllInstitutionsUsers');

            return Api.$promise.then(function (data) {
                if (constants.dev) {
                    endTime = new Date().getTime();
                    $log.log('success, got data: ', data, 'in ' + (endTime - startTime) + ' ms');
                }

                // cancel currently running requests
                RequestsQueueService.cancelAll('getAllInstitutionsUsers');
                data.results = service.formatList(data.results);
                return data;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return [];
            }).then(function (data) {
                data.results = service.appendContactsData(data.results);
                return data;
            });
        };

        service.appendContactsData = function (userData) {
            if (userData) {
                var contactTypeName = null;

                service.getContactTypes().then(function(contactTypesList) {
                    if (contactTypesList) {
                        angular.forEach(userData, function(userObject) {
                            if (!userObject.contactTypesAsArray) {
                                userObject.contactTypesAsArray = [];
                            }
                            if (!userObject.contactTypes) {
                                return false;
                            }
                            var contactTypes = angular.copy(userObject.contactTypes);
                            userObject.contactTypes = {};
                            angular.forEach(contactTypes, function(contactType) {
                                contactTypeName = UsersListService.getContactTypeNameByHandle(
                                    contactType.handle, contactTypesList
                                );
                                userObject.contactTypesAsArray.push(contactTypeName);
                                userObject.contactTypesAsString = userObject.contactTypesAsArray.length ?
                                    userObject.contactTypesAsArray.join(', ') : null;

                                userObject.contactTypes[contactType.handle] = {
                                    primary : contactType.primary,
                                    assigned : true
                                };
                            });
                        });
                    }
                });
            }

            return userData;
        };

        service.getContactTypesFilter = function (useCache) {
            useCache = useCache || false;
            var deferred = $q.defer(),
                cacheKey = 'getContactTypesFilter';
            if (useCache && service.cache[cacheKey]) {
                deferred = $q.defer();
                deferred.resolve(service.cache[cacheKey]);
                return deferred.promise;
            }
            service.getContactTypes().then(function(contactTypesList) {
                var contactTypeFilters = [];
                if (contactTypesList && typeof contactTypesList[0] !== 'undefined') {
                    angular.forEach(contactTypesList, function(contactType) {
                        contactTypeFilters.push({
                            id: contactType.handle,
                            title: contactType.name
                        });
                    });
                }

                deferred.resolve(contactTypeFilters);
            });

            return deferred.promise;
        };

        /**
         * Format QS users list.
         *
         * @param {Object} userData
         * @returns {Object}
         */
        service.formatList = function (userData) {
            if (userData) {
                var accessTo, roles;
                angular.forEach(userData, function(userObject) {
                    accessTo = userObject.accessTo ? angular.copy(userObject.accessTo) : [];
                    userObject.accessTo = {};
                    angular.forEach(accessTo, function(value) {
                        userObject.accessTo[value] = true;
                    });

                    roles = userObject.roles ? angular.copy(userObject.roles) : [];
                    userObject.roles = {};
                    angular.forEach(roles, function(value) {
                        userObject.roles[value] = true;
                        if (value === constants.globalAdminRole) {
                            userObject.globalAdmin = true;
                        }
                    });
                });
            }

            return userData;
        };

        /**
         * Get contact types list.
         *
         * @returns {Promise:Object}
         */
        service.getContactTypes = function () {
            // process already running
            if (!service.deferred.getContactTypes) {
                service.deferred.getContactTypes = $q.defer();
            } else {
                return service.deferred.getContactTypes.promise;
            }
            var deferred = service.deferred.getContactTypes;

            // check if data exists in cache
            if (service.contactTypes) {
                deferred.resolve(service.contactTypes);
            } else {
                var Api = $resource(constants.api.usersPermissions.url + '/v1/list/contact-types');
                Api.get(function (data) {
                    if (constants.dev) {
                        $log.log('success, got data: ', data);
                    }
                    service.contactTypes = data.results;
                    deferred.resolve(service.contactTypes);
                    deferred = null;
                }, function (error) {
                    //@todo log error
                    if (constants.dev) {
                        $log.log('request failed' + error);
                    }
                    service.contactTypes = [];
                    deferred.resolve(service.contactTypes);
                    deferred = null;
                });
            }

            return deferred.promise;
        };

        /**
         * Format roles list.
         *
         * @param {Object} rolesList
         * @returns {Array}
         */
        service.formatRoles = function (rolesList) {
            var flatArray = [];

            angular.forEach(rolesList, function (rolesGroup) {
                angular.forEach(rolesGroup.roles, function (role) {
                    flatArray.push({
                        'groupHandle' : rolesGroup.handle,
                        'groupName' : rolesGroup.name,
                        'roleName' : role.name,
                        'roleHandle' : rolesGroup.handle + '.' + role.handle,
                        'all' : role.all ? role.all : false,
                        'sections': role.sections
                    });
                });
            });

            flatArray.push({
                'groupName' : 'Custom',
                'roleName' : 'Switching off will revert changes to default',
                'roleHandle' : 'custom',
                'all' : false
            });

            return flatArray;
        };

        service.formatCsvResults = function (data, TimeService) {
            data = angular.copy(data);
            angular.forEach(data, function (column, dataKey) {
                angular.forEach(column, function (item, key) {
                    if (key === 'createdAt') {
                        data[dataKey][key] = TimeService.format(item, "YYYY-MM-DD HH:mm:ss");
                    } 
                    else if(key === 'lastLoginAt'  && item ){
                        data[dataKey][key] = TimeService.format(item, "YYYY-MM-DD HH:mm:ss");
                    }
                    else if(key === 'active'){
                         data[dataKey][key] = item ? 'Yes' : 'No';
                    }
                });
            });

            return data;
        };

        /**
         * Get Institutions users roles list.
         *
         * @returns {Promise}
         */
        service.getRoles = function () {
            // process already running
            if (!service.deferred.getRoles) {
                service.deferred.getRoles = $q.defer();
            } else {
                return service.deferred.getRoles.promise;
            }
            var deferred = service.deferred.getRoles;

            // check if data exists in cache
            if (service.roles) {
                deferred.resolve(service.roles);
            } else {
                var Api = $resource(constants.api.usersPermissions.url + '/v1/list/client-roles');
                Api.get(function (data) {
                    if (constants.dev) {
                        $log.log('success, got data: ', data);
                    }
                    service.roles = service.formatRoles(data.results);
                    deferred.resolve(service.roles);
                    deferred = null;
                    delete service.deferred.getRoles;
                }, function (error) {
                    //@todo log error
                    if (constants.dev) {
                        $log.log('request failed' + error);
                    }
                    if (error.status !== 401) {
                        service.roles = [];
                    }
                    deferred.resolve(service.roles);
                    deferred = null;
                    delete service.deferred.getRoles;
                });
            }

            return deferred.promise;
        };

        /**
         * Get sections list.
         *
         * @returns {Promise:Array}
         */
        service.getSections = function () {
            // process already running
            if (!service.deferred.getSections) {
                service.deferred.getSections = $q.defer();
            } else {
                return service.deferred.getSections.promise;
            }
            var deferred = service.deferred.getSections;

            // check if data exists in cache
            if (service.sections) {
                deferred.resolve(service.sections);
            } else {
                var Api = $resource(constants.api.usersPermissions.url + '/v1/list/client-sections');
                Api.get(function (data) {
                    if (constants.dev) {
                        $log.log('success, got data: ', data);
                    }
                    service.sections = UsersListService.formatSections(data.results);
                    deferred.resolve(service.sections);
                    deferred = null;
                }, function (error) {
                    //@todo log error
                    if (constants.dev) {
                        $log.log('request failed' + error);
                    }
                    service.sections = [];
                    deferred.resolve(service.sections);
                    deferred = null;
                });
            }

            return deferred.promise;
        };

        /**
         * Mark user as active.
         *
         * @param {String} id
         * @returns {Promise:boolean}
         */
        service.activate = function (id) {
            var Api = $resource(constants.api.usersPermissions.url + '/v1/user/:id/activate', {id:id});
            return Api.get().$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        /**
         * Mark user as inactive.
         *
         * @param {String} id
         * @returns {Promise:boolean}
         */
        service.deactivate = function (id) {
            var Api = $resource(constants.api.usersPermissions.url + '/v1/user/:id/deactivate', {id:id});
            return Api.get().$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        service.getFullName = function(userObject) {
            return [
                userObject.title,
                userObject.firstname,
                userObject.lastname
            ].filter(Boolean).join(' ');
        };

        service.initInstitutionsUser = function (user) {
            if (!user.hasOwnProperty('contactTypes')) {
                user.contactTypes = [];
            }
            user.roles = [];
            user.accessTo = [];
            user.deleted = false;
            user.active = true;
            user.qs = false;

            return user;
        };

        service.savePersonalDetails = function (userData) {
            var UserModel = service.getUserModel(),
                dataToUpdate = angular.extend({}, userData);
            if (dataToUpdate.contactTypes) {
                var formattedContactTypes = [];

                for (var contactType in dataToUpdate.contactTypes) {
                    if (dataToUpdate.contactTypes[contactType].assigned) {
                        formattedContactTypes.push({
                            handle: contactType,
                            primary: dataToUpdate.contactTypes[contactType].primary ? true : false
                        });
                    }
                }

                dataToUpdate.contactTypes = formattedContactTypes;
            }

            delete dataToUpdate.accessTo;
            delete dataToUpdate.roles;
            delete dataToUpdate.fullname;

            if (userData.id) { // update
                return UserModel.update({id: userData.id}, dataToUpdate).$promise.then(function (data) {
                    if (constants.dev) {
                        $log.log('success, got data: ', data);
                    }
                    return data;
                }, function (error) {
                    //@todo log error
                    if (constants.dev) {
                        $log.log('request failed' + error);
                    }
                    return false;
                });
            } else { // create
                UserModel = service.getUserInstitutionModel();
                dataToUpdate = service.initInstitutionsUser(dataToUpdate);
                delete dataToUpdate.id;
                return UserModel.create(dataToUpdate).$promise.then(function (data) {
                    if (constants.dev) {
                        $log.log('success, got data: ', data);
                    }
                    return data;
                }, function (error) {
                    //@todo log error
                    if (constants.dev) {
                        $log.log('request failed' + error);
                    }
                    return false;
                });
            }
        };

        service.savePermissions = function (userData) {
            var UserModel = service.getUserModel(),
                dataToUpdate = {
                    accessTo : [],
                    roles : []
                };

            if (userData.accessTo) {
                for (var section in userData.accessTo) {
                    if (userData.accessTo[section]) {
                        dataToUpdate.accessTo.push(section);
                    }
                }
            }
            if (userData.roles) {
                for (var role in userData.roles) {
                    if (userData.roles[role]) {
                        dataToUpdate.roles.push(role);
                    }
                }
            }
            return UserModel.update({id: userData.id}, dataToUpdate).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        /**
         * Set institutions users datagrid columns visibility.
         *
         * @param {Object} columnsVisibility
         */
        service.storeColumnsVisibility = function (columnsVisibility) {
            $localStorage.institutionUsersGridColumnsVisibility = columnsVisibility;
        };

        /**
         * Get institutions users datagrid columns visibility.
         * @returns {Object|*}
         */
        service.getColumnsVisibility = function () {
            return $localStorage.institutionUsersGridColumnsVisibility;
        };

        return service;
    };

    angular
        .module('qsHub')
        .service('InstitutionsUsersListService', [
            '$resource',
            '$q',
            '$log',
            '$localStorage',
            'constants',
            'UsersListService',
            'RequestsQueueService',
            App.services.InstitutionsUsers
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {factories:{}});

    App.factories.InstitutionsUsersListFactory = function () {
        var selectedTabId = null,
            activeUserId = null,
            tabs = {
                personal : 0,
                permissions : 1,
                institutions : 2
            };

        return {
            setSelectedTabId: function (newSelectedTabId) {
                selectedTabId = newSelectedTabId;
            },
            isInstitutionsTabSelected: function () {
                return (typeof selectedTabId !== 'undefined') && selectedTabId === tabs.institutions;
            },
            setActiveUserId: function (newActiveUserId) {
                activeUserId = newActiveUserId;
            },
            resetActiveUserId: function () {
                activeUserId = null;
            },
            getActiveUserId: function () {
                return activeUserId;
            }
        };
    };

    angular
        .module('qsHub')
        .factory('InstitutionsUsersListFactory', [App.factories.InstitutionsUsersListFactory]);

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers:{}});

    App.controllers.tuProfilePrograms = function (
        $scope,
        constants,
        InstitutionsUsersListInstitutionsService,
        InstitutionsUsersListFactory,
        NotifierFactory,
        WatchService,
        UserFactory
    ) {
        var controller = this,
            alreadyInitialised = false;

        controller.devMode = constants.dev;
        controller.searchInProgress = false;

        controller.submitInProgress = false;
        
        $scope.selectedInstitutionsNamesLoaded = false;
        $scope.userInstitutions = {
            assigned : []
        };
        controller.newAssignedValues = {};
        $scope.institutionsSearchResults = [];

        controller.disallowSubmit = function () {
            // submit process is on going
            if (!$scope.user.id || controller.submitInProgress || !$scope.selectedInstitutionsNamesLoaded) {
                return true;
            }
            // missing primary institution
            if (!$scope.user.primaryInstitutionCoreId) {
                return true;
            }
            // current list is not empty & assigned institutions already in the list
            if (
                $scope.user.institutions &&
                $scope.user.institutions.length &&
                $scope.userBeforeChanges.institutions &&
                $scope.userBeforeChanges.institutions.length &&
                $scope.userInstitutions.assigned &&
                $scope.userInstitutions.assigned.length
            ) {
                var hasChanges = InstitutionsUsersListInstitutionsService.hasChanges(
                    $scope.user.institutions,
                    $scope.userBeforeChanges.institutions,
                    $scope.userInstitutions.assigned
                );
                if (
                    !hasChanges &&
                    $scope.user.primaryInstitutionCoreId === $scope.userBeforeChanges.primaryInstitutionCoreId
                ) {
                    return true;
                }
            }
            // current & assigned lists are empty
            if (
                $scope.user.institutions &&
                !$scope.user.institutions.length &&
                $scope.userBeforeChanges.institutions &&
                !$scope.userBeforeChanges.institutions.length &&
                $scope.userInstitutions.assigned &&
                !$scope.userInstitutions.assigned.length
            ) {
                return true;
            }

            return false;
        };

        /**
         * Is progress bar visible?
         *
         * @returns {boolean}
         */
        controller.isProgressBarVisible = function () {
            return !$scope.selectedInstitutionsNamesLoaded || controller.searchInProgress;
        };


        /**
         * Actions to do when institutions changes are submitted.
         */
        controller.handleInstitutionSubmit = function () {
            return;
            controller.submitInProgress = true;

            // fetch user institutions groups
            InstitutionsUsersListInstitutionsService.getInstitutionsGroups(
                $scope.user.primaryInstitutionCoreId
            ).then(function (institutionsGroups) {
                // re-format institution groups
                if (angular.isArray(institutionsGroups) && institutionsGroups.length > 0) {
                    var institutionsGroupsFormatted = {};
                    institutionsGroupsFormatted[0] = {};
                    angular.forEach(institutionsGroups, function (institutionsGroup) {
                        institutionsGroupsFormatted[0][institutionsGroup.coreId] = {
                            coreId: institutionsGroup.coreId,
                            name: institutionsGroup.name
                        };
                    });
                    institutionsGroups = institutionsGroupsFormatted;
                }
                $scope.user.institutionsGroups = institutionsGroups;
                // update user institutions
                InstitutionsUsersListInstitutionsService.saveInstitutions(
                    $scope.user.id,
                    $scope.userInstitutions.assigned,
                    {
                        primaryInstitutionCoreId : $scope.user.primaryInstitutionCoreId,
                        primaryInstitutionCoreIdAsString : '' + $scope.user.primaryInstitutionCoreId,
                        primaryInstitutionName : $scope.user.primaryInstitutionName,
                        qs : $scope.user.qs
                    },
                    institutionsGroups
                ).then(controller.saveInstitutionsCallback);
            });
        };

        /**
         * Actions to do after institutions changes are submission.
         *
         * @param {Boolean} success
         */
        controller.saveInstitutionsCallback = function (success) {
            var assignedIds = InstitutionsUsersListInstitutionsService.getObjectColumn(
                $scope.userInstitutions.assigned, 'coreId'
            );
            $scope.user.institutions = angular.copy(assignedIds);
            $scope.userBeforeChanges.institutions = angular.copy(assignedIds);
            $scope.userBeforeChanges.primaryInstitutionCoreId = $scope.user.primaryInstitutionCoreId;
            controller.submitInProgress = false;

            if (success) {
                controller.userInstitutionsBeforeChanges = angular.copy($scope.userInstitutions);
                UserFactory.setInstitutions($scope.userInstitutions.assigned);
                $scope.user.primaryInstitutionCoreIdAsString = '' + $scope.user.primaryInstitutionCoreId;
                $scope.$emit(constants.events.institutionsUserPrimaryInstitutionChanges);
            }
            controller.newAssignedValues = angular.copy($scope.userInstitutions.assigned);
            NotifierFactory.show(
                success ? 'success' : 'error',
                success ? 'Updated successfully!' : 'Update failed!',
                'User Institutions'
            );
        };

        /**
         * Actions to do after institutions search.
         *
         * @param {Array} results
         */
        controller.searchInstitutionsCallback = function (results) {
            controller.searchInProgress = false;
            $scope.institutionsSearchResults = results;
        };

        /**
         * Search for institution by search phrase.
         *
         * @param {String} searchPhrase
         */
        controller.searchInstitutions = function (searchPhrase) {
            controller.searchInProgress = true;
            InstitutionsUsersListInstitutionsService.searchInstitutions(
                searchPhrase,
                $scope.user.primaryInstitutionCoreId,
                $scope.userInstitutions.assigned
            ).then(controller.searchInstitutionsCallback);
        };

        /**
         * Actions to do after institutions full names search.
         *
         * @param {Array} results
         */
        controller.getInstitutionsNamesCallback = function (results) {
            $scope.selectedInstitutionsNamesLoaded = true;
            $scope.userInstitutions.assigned = results;
            var addPrimary = true;
            for (var r in results) {
                if (results[r].coreId === $scope.user.primaryInstitutionCoreId) {
                    addPrimary = false;
                    break;
                }
            }
            if (addPrimary &&
                $scope.user.primaryInstitutionCoreId &&
                $scope.user.primaryInstitutionName !== '' &&
                $scope.user.primaryInstitutionName
            ) {
                $scope.userInstitutions.assigned.push({
                    coreId : $scope.user.primaryInstitutionCoreId,
                    name : $scope.user.primaryInstitutionName + ' [' + $scope.user.primaryInstitutionCoreId + ']'
                });
            }
            controller.userInstitutionsBeforeChanges = angular.copy($scope.userInstitutions);
        };

        controller.handleItemSelect = function (item) {
            if ($scope.userInstitutions.assigned.length === 1) {
                $scope.user.primaryInstitutionCoreId = item.coreId;
                $scope.user.primaryInstitutionName = item.name.replace(' [' + item.coreId + ']','');
            }
        };

        controller.handleItemRemove = function (item) {
            if (item && $scope.user.primaryInstitutionCoreId === item.coreId) {
                $scope.user.primaryInstitutionCoreId = null;
            }
        };

        controller.handlePrimaryInstitutionChanges = function () {
            for (var i in  $scope.userInstitutions.assigned) {
                // find primary institution object in assigned institutions list
                if ($scope.userInstitutions.assigned[i].coreId === $scope.user.primaryInstitutionCoreId) {
                    $scope.user.primaryInstitutionName = $scope.userInstitutions.assigned[i].name.replace(' [' + $scope.user.primaryInstitutionCoreId + ']', '');
                    $scope.user.primaryInstitutionCoreIdAsString = ''+ $scope.userInstitutions.assigned[i].coreId;
                    break;
                }
            }
        };

        /**
         * Initialise user institutions.
         */
        controller.initUserInstitutions = function () {
            $scope.selectedInstitutionsNamesLoaded = false;
            if ($scope.user && $scope.user.institutions && $scope.user.institutions.length) {
                InstitutionsUsersListInstitutionsService.getInstitutionNames(
                    $scope.user.institutions
                ).then(controller.getInstitutionsNamesCallback);
            } else {
                controller.getInstitutionsNamesCallback([]);
            }
        };

        /**
         * Initialise watches.
         */
        controller.initWatches = function () {
            // listen to active user changes
            WatchService.create($scope, InstitutionsUsersListFactory.getActiveUserId, function (userId) {
                if (userId) {
                    if (InstitutionsUsersListFactory.isInstitutionsTabSelected()) {
                        controller.initUserInstitutions();
                    }
                } else {
                    $scope.selectedInstitutionsNamesLoaded = true;
                    controller.getInstitutionsNamesCallback([]);
                }
            });

            /**
             * Restore value for primaryInstitutionCoreId if it gets undefined
             */
            WatchService.create($scope, 'user.primaryInstitutionCoreId', function(newValue, oldValue) {
                if (newValue === undefined) {
                    $scope.user.primaryInstitutionCoreId = oldValue;
                }
            });

            /**
             * Restore value for primaryInstitutionCoreId if it gets undefined
             */
            WatchService.create($scope, 'userInstitutions.assigned', function(newValue, oldValue) {
                if (newValue.length === 0 && oldValue.length > 0) {
                    $scope.user.primaryInstitutionName = null;
                    $scope.user.primaryInstitutionCoreId = null;
                    $scope.user.primaryInstitutionCoreIdAsString = null;
                }
            });

            WatchService.create($scope, function() {return controller.newAssignedValues;}, function(newValue, oldValue) {
                if (newValue !== oldValue) {
                    $scope.userInstitutions.assigned = angular.copy(newValue);
                }
            });

            $scope.$on(constants.events.closingInstitutionsUsers, function () {
                // reset unsaved changes
                $scope.userInstitutions = angular.copy(controller.userInstitutionsBeforeChanges);
                $scope.selectedInstitutionsNamesLoaded = false;
            });

            // listen if institution tab is selected, init user institutions
            WatchService.create($scope, InstitutionsUsersListFactory.isInstitutionsTabSelected, function (isSelected) {
                if (isSelected) {
                    controller.initUserInstitutions();
                }
            });
        };

        controller.init = function () {
            controller.initWatches();
        };

        // listen to institutions tab visibility changes
        WatchService.create($scope, InstitutionsUsersListFactory.isInstitutionsTabSelected, function (isActive, wasActive) {
            // if not already initialized and tab is active
            if (!alreadyInitialised && isActive && !wasActive) {
                controller.init();
                alreadyInitialised = true;
            }
            if (isActive && InstitutionsUsersListFactory.getActiveUserId() && !$scope.selectedInstitutionsNamesLoaded) {
                controller.initUserInstitutions();
            }
        });
    };

    angular
        .module('qsHub')
        .controller('InstitutionsUsersInstitutionsController', [
            '$scope',
            'constants',
            'InstitutionsUsersListInstitutionsService',
            'InstitutionsUsersListFactory',
            'NotifierFactory',
            'WatchService',
            'UserFactory',
            App.controllers.tuProfilePrograms
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    App.services.InstitutionsUsersListInstitutionsService = function (
        $resource,
        $q,
        $log,
        constants,
        RequestsQueueService
    ) {
        var service = {
            institutionsNames : []
        };

        /**
         * Institutions full names by core id(s) endpoint.
         *
         * @returns {$resource.*}
         */
        service.getInstitutionNamesByCoreIdsModel = function () {
            return $resource(
                constants.api.institutions.url + '/v1/list/all/parent-name?sorting[coreId]=asc',
                null,
                {
                    get: {
                        cancellable: true
                    }
                }
            );
        };

        service.getInstitutionsGroupsModel = function () {
            return $resource(
                constants.api.institutions.url + '/v1/list/all?filter[coreId]=:id',
                {
                    'limit': 1,
                    'columns[]' : 'groupMembers'
                },
                {
                    get: {
                        cancellable: true
                    }
                }
            );
        };



        /**
         * Institutions search by full name endpoint.
         *
         * @returns {$resource.*}
         */
        service.getInstitutionsSearchModel = function () {
            return $resource(
                constants.api.institutions.url + '/v1/list/all/parent-name',
                {
                    'sorting[coreId]' : 'asc',
                    'field[]': [
                        'name',
                        'coreId'
                    ]
                },
                {
                    get: {
                        cancellable: true
                    }
                }
            );
        };

        /**
         * User update endpoint.
         *
         * @returns {$resource.*}
         */
        service.getUserModel = function () {
            return $resource(constants.api.usersPermissions.url + '/v1/user/:id', null, {
                update: {method:'PATCH', cancellable: true}
            });
        };

        /**
         * Search for institution by full name field.
         *
         * @param {String} searchPhrase
         * @param {Number} primaryInstitutionCoreId
         * @param {Array} assignedInstitutionsCoreIds
         * @returns {Promise.Array}
         */
        service.searchInstitutions = function (searchPhrase, primaryInstitutionCoreId, assignedInstitutionsCoreIds)
        {
            var deferred = $q.defer(),
                institutions = [];

            if (!primaryInstitutionCoreId) {
                primaryInstitutionCoreId = 0;
            }

            if (!searchPhrase || searchPhrase.length < 1) {
                deferred.resolve(institutions);
            } else {
                if (constants.dev) {
                    var startTime = new Date().getTime(), endTime;
                }
                var queryString = {
                    'filter[name]' : searchPhrase,
                    'exclude[coreIdAsString][]' : [
                        primaryInstitutionCoreId
                    ]
                };
                if (assignedInstitutionsCoreIds.length) {
                    angular.forEach(assignedInstitutionsCoreIds, function (value) {
                        if (value.coreId) {
                            queryString['exclude[coreIdAsString][]'].push(value.coreId);
                        }
                    });
                }

                // cancel currently running requests
                RequestsQueueService.cancelAll('searchInstitutions');

                var Api = service.getInstitutionsSearchModel().get(queryString);

                // add new request to the queue
                RequestsQueueService.add(Api, 'searchInstitutions');

                Api.$promise.then(function (response) {
                    if (constants.dev) {
                        endTime = new Date().getTime();
                        $log.log('success, got data: ', response, 'in ' + (endTime - startTime) + ' ms');
                    }

                    // cancel currently running requests
                    RequestsQueueService.cancelAll('searchInstitutions');

                    deferred.resolve(response.results);
                }, function (error) {
                    //@todo log error
                    if (constants.dev) {
                        $log.log('request failed' + error);
                    }

                    deferred.resolve([]);
                });
            }

            return deferred.promise;
        };

        service.getObjectColumn = function (dataObject, column) {
            var filteredData = [];
            angular.forEach(dataObject, function (data) {
                if (data[column]) {
                    filteredData.push(data[column]);
                }

            });
            return filteredData;
        };

        service.hasChanges = function (sourceOne, sourceTwo, assigned) {
            var assignedIds = service.getObjectColumn(
                assigned,
                'coreId'
            );
            sourceOne = sourceOne.sort();
            sourceTwo = sourceTwo.sort();
            assignedIds = assignedIds.sort();

            if (!angular.equals(sourceOne, sourceTwo)) {
                return true;
            }
            if (!angular.equals(sourceTwo, assignedIds)) {
                return true;
            }
            return false;
        };

        /**
         * Save user institutions.
         *
         * @param {Integer} userId
         * @param {Array} institutionsData
         * @param {object} primaryInstitutionData
         * @returns {Promise.boolean}
         */
        service.saveInstitutions = function (userId, institutionsData, primaryInstitutionData, institutionsGroups)
        {
            var deferred = $q.defer(),
                institutions = [];

            if (!userId || !institutionsData || !primaryInstitutionData) {
                deferred.resolve(false);
            } else {
                if (constants.dev) {
                    var startTime = new Date().getTime(), endTime;
                }

                if (institutionsData.length) {
                    institutions = service.getObjectColumn(institutionsData, 'coreId');
                }

                // cancel currently running requests
                RequestsQueueService.cancelAll('saveInstitutions');

                var Api = service.getUserModel().update({
                    id : userId
                }, {
                    institutions : institutions,
                    primaryInstitutionCoreId : primaryInstitutionData.primaryInstitutionCoreId,
                    primaryInstitutionCoreIdAsString : primaryInstitutionData.primaryInstitutionCoreIdAsString,
                    primaryInstitutionName : primaryInstitutionData.primaryInstitutionName,
                    institutionsGroups: institutionsGroups
                });

                // add new request to the queue
                RequestsQueueService.add(Api, 'saveInstitutions');

                Api.$promise.then(function (response) {
                    if (constants.dev) {
                        endTime = new Date().getTime();
                        $log.log('success, got data: ', response, 'in ' + (endTime - startTime) + ' ms');
                    }

                    // cancel currently running requests
                    RequestsQueueService.cancelAll('saveInstitutions');

                    deferred.resolve(response.modifiedCount === 1);
                }, function (error) {
                    //@todo log error
                    if (constants.dev) {
                        $log.log('request failed' + error);
                    }

                    deferred.resolve(false);
                });
            }

            return deferred.promise;
        };

        service.getInstitutionsGroups = function (institutionCoreId) {
            if (!institutionCoreId) {
                throw 'Missing Required';
            }

            var Api = service.getInstitutionsGroupsModel();
            return Api.get({ id: institutionCoreId }).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return (data && data.results && data.results[0] && data.results[0].groupMembers) ?
                    data.results[0].groupMembers : [];
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        /**
         * Get institutions names by their core ids.
         *
         * @param {Array} coreIds
         * @returns {Promise.Array}
         */
        service.getInstitutionNames = function (coreIds) {
            var deferred = $q.defer(),
                names = [];

            // check if core ids are provided
            if (!coreIds || !coreIds.length) {
                deferred.resolve(names);
            } else {
                var cacheKey = coreIds.join(':');
                // check if in cache
                if (typeof service.institutionsNames[cacheKey] !== 'undefined') {
                    if (constants.dev) {
                        $log.log('serving from cache');
                    }
                    deferred.resolve(service.institutionsNames[cacheKey]);
                } else {
                    if (constants.dev) {
                        var startTime = new Date().getTime(), endTime;
                    }
                    // cancel currently running requests
                    RequestsQueueService.cancelAll('getInstitutionNames');

                    var Api = service.getInstitutionNamesByCoreIdsModel().get(
                        {
                            'filter[coreId][]': coreIds,
                            'field[]': ['coreId', 'name']
                        }
                    );

                    // add new request to the queue
                    RequestsQueueService.add(Api, 'getInstitutionNames');

                    Api.$promise.then(function (response) {
                        if (constants.dev) {
                            endTime = new Date().getTime();
                            $log.log('success, got data: ', response, 'in ' + (endTime - startTime) + ' ms');
                        }

                        // cancel currently running requests
                        RequestsQueueService.cancelAll('getInstitutionNames');

                        service.institutionsNames[cacheKey] = response.results;
                        deferred.resolve(response.results);
                    }, function (error) {
                        //@todo log error
                        if (constants.dev) {
                            $log.log('request failed' + error);
                        }

                        deferred.resolve(names);
                    });
                }
            }

            return deferred.promise;
        };

        return service;
    };

    angular
        .module('qsHub')
        .service('InstitutionsUsersListInstitutionsService', [
            '$resource',
            '$q',
            '$log',
            'constants',
            'RequestsQueueService',
            App.services.InstitutionsUsersListInstitutionsService
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers:{}});

    App.controllers.departmentOverview = function (
        $scope,
        $rootScope,
        $controller,
        constants,
        InstitutionFactory,
        TuProfileFactory,
        TuProfileDepartmentsService,
        InstitutionsService,
        InstitutionSwitchService,
        WatchService,
        GridService,
        $stateParams
    ) {
        $controller('TuProfileDepartmentsController', {$scope: $scope});
        var controller = this,
            departmentControllerAs = $scope.getTuProfileDepartmentController();
        $scope.showDepartmentEditForm = false;
        $scope.showDepartmentAddForm = false;
        controller.searchInProgress = false;
        controller.isDatagridReloading = false;
        controller.institution = {};
        controller.institutionsList = [];
        controller.showInstitutionDeps = false;

        controller.searchInstitution = function (searchPhrase) {
            controller.searchInProgress = true;
            InstitutionSwitchService.searchInstitutions(searchPhrase).then(function (results) {
                controller.institutionsList = results;
                controller.searchInProgress = false;
            });
        };

        controller.isAddButtonDisabled = function () {
            return !controller.isInstitutionSelected() || controller.isRightSidePanelActive();
        };

        $scope.isDeleteButtonDisabled = function () {
            return $scope.deleteInProgress ||
                !InstitutionFactory.getInstitutionIdDepartmentOverview() ||
                !departmentControllerAs.hasDepartmentsToDelete();
        };

        controller.handleSearchInstitutionClick = function (institution) {
            if (institution && institution.coreId) {
                InstitutionFactory.setInstitutionIdDepartmentOverview(institution.coreId);
                controller.coreId = institution.coreId;
                departmentControllerAs.clearDepartmentsToDelete();
            }
        };

        controller.isInstitutionSelected = function () {
            if(controller.showInstitutionDeps) {
                return true;
            }
            if ((InstitutionFactory.getInstitutionIdDepartmentOverview() === null) ||
                (typeof InstitutionFactory.getInstitutionIdDepartmentOverview() === 'undefined')
            ) {
                return false;
            }
            return true;
        };

        controller.isRightSidePanelActive = function () {
            // departments tab is active and edit or add form is active
            if ($scope.showDepartmentEditForm || $scope.showDepartmentAddForm) {
                return true;
            }
            return false;
        };

        controller.reloadDatagrid = function (coreId) {
            if (!coreId) {
                return false;
            }
            controller.isDatagridReloading = true;
            return TuProfileDepartmentsService.getAllDepartmentsByCoreId(coreId).then(function(response) {
                controller.isDatagridReloading = false;

                $scope.gridOptions.data = response;

                return response;
            });
        };

        controller.hasNewDepartmentWatch = function (newDepartmentId) {
            if (newDepartmentId) {
                // reload datagrid
                controller.reloadDatagrid(controller.coreId).then(function (departmentsList) {
                    if (departmentsList) {
                        var i = 0,
                            department = null,
                            total = departmentsList.length;
                        for (i; i < total; i++) {
                            department = departmentsList[i];
                            if (department.id === newDepartmentId) {
                                TuProfileFactory.setDepartmentBeforeChanges(department);
                                break;
                            }
                        }
                    }
                    // mark new department as selected in the datagrid
                    $scope.selectedDepartmentId = newDepartmentId;

                }).finally(function () {
                    // open new department in edit mode
                    TuProfileFactory.setDepartmentEditFormVisibility(true);
                });
            }
        };

        controller.isDepartmentAddFormVisibleWatch = function (visible) {
            $scope.showDepartmentAddForm = visible;
            visible = visible || TuProfileFactory.isDepartmentEditFormVisible(TuProfileFactory.getProfileTabs().departments);
            departmentControllerAs.toggleColumns(visible);
        };

        controller.isDepartmentEditFormVisible = function (visible) {
            $scope.showDepartmentEditForm = visible;
            if (!visible) {
                $scope.selectedDepartmentId = null;
            } else {
                $scope.selectedDepartmentId = TuProfileFactory.getDepartmentId();
            }
            visible = visible || TuProfileFactory.isDepartmentAddFormVisible(TuProfileFactory.getProfileTabs().departments);
            departmentControllerAs.toggleColumns(visible);
        };

        controller.getInstitutionIdDepartmentOverviewWatch = function (newValue, oldValue) {
            if ($stateParams.coreId) {
                newValue = $stateParams.coreId;
            }
            if (newValue !== oldValue && newValue) {
                controller.destruct();
                InstitutionsService.getInstitutionData(newValue).then(function (data) {
                    var institutionData = data && data.results ? data.results[0] : null;
                    InstitutionFactory.setDataDepartmentOverview(institutionData);
                    InstitutionFactory.setInstitutionIdDepartmentOverview(newValue);
                });
                controller.initWatches(newValue);
                departmentControllerAs.initDataGrid(newValue);
            }
        };

        controller.initWatches = function (coreId) {
            if (!coreId) {
                return false;
            }

            // watch for new departments
            WatchService.create($scope, TuProfileFactory.hasNewDepartment, controller.hasNewDepartmentWatch);
            // watch for department add form visibility changes
            WatchService.create($scope, function () {
                return TuProfileFactory.isDepartmentAddFormVisible(TuProfileFactory.getProfileTabs().departments);
            }, controller.isDepartmentAddFormVisibleWatch);
            // watch for department edit form visibility changes
            WatchService.create($scope, function () {
                return TuProfileFactory.isDepartmentEditFormVisible(TuProfileFactory.getProfileTabs().departments);
            }, controller.isDepartmentEditFormVisible);

            $rootScope.$on(constants.events.logout, controller.destruct);

            // Listen for changes in the visibility of the columns
            /*WatchService.create($scope, 'gridOptions.columnDefs',function (newValue, oldValue) {
                if (newValue !== oldValue) {
                    departmentControllerAs.columnsVisibility = GridService.getGridColumnsVisibility(newValue);
                    GridService.storeColumnsVisibility(
                        departmentControllerAs.gridVisibilityNameSpace,
                        departmentControllerAs.columnsVisibility
                    );
                    // Clear filter when column is hidden
                    if (angular.isDefined(newValue) &&
                        angular.isDefined(oldValue)
                    ) {
                        for (var i = 0; i < newValue.length; i++) {
                            if (newValue[i].visible === false &&
                                oldValue[i].visible === true &&
                                angular.isDefined($scope.gridOptions.columnDefs[i].filter)
                            ) {
                                $scope.gridOptions.columnDefs[i].filter.term = null;
                            }
                        }
                    }
                }
            },true);*/
        };

        controller.destruct = function () {
            TuProfileFactory.reset();
            TuProfileFactory.setDepartmentAddFormVisibility(false);
            TuProfileFactory.setDepartmentEditFormVisibility(false);
        };

        /*
        show departments for institution
         */
        controller.showInstitutionDepartments = function (){
            if ($stateParams.coreId &&
                $stateParams.name &&
                typeof($stateParams.coreId) !== "undefined"
            ) {
                controller.initWatches($stateParams.coreId);
                controller.institution.selected = {
                    name: $stateParams.name +  ' [' + $stateParams.coreId + ']',
                    coreId: $stateParams.coreId
                };
                departmentControllerAs.initDataGrid($stateParams.coreId);
                controller.showInstitutionDeps = true;
                controller.coreId = $stateParams.coreId;
            }
        };
        controller.init = function () {
            controller.showInstitutionDepartments();
            InstitutionFactory.resetDepartmentOverview();
            controller.destruct();
            controller.activeInstitutionCoreId = $stateParams.coreId;

        };

        controller.init();

        WatchService.create(
            $scope,
            InstitutionFactory.getInstitutionIdDepartmentOverview,
            controller.getInstitutionIdDepartmentOverviewWatch
        );

    };

    angular
        .module('qsHub')
        .controller('DepartmentOverviewController', [
            '$scope',
            '$rootScope',
            '$controller',
            'constants',
            'InstitutionFactory',
            'TuProfileFactory',
            'TuProfileDepartmentsService',
            'InstitutionsService',
            'InstitutionSwitchService',
            'WatchService',
            'UiGridService',
            '$stateParams',
            App.controllers.departmentOverview
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers:{}});

    App.controllers.departmentOverviewForm = function (
        $scope,
        $controller,
        TuProfileFactory,
        InstitutionFactory,
        TuProfileDepartmentsService,
        WatchService,
        $stateParams
    ) {
        var TuProfileDepartmentFormController = $controller('TuProfileDepartmentForm', {$scope: $scope});
        var controller = this,
            departmentFormControllerAs = $scope.getTuProfileDepartmentForm();
        
        controller.handleUpgradeClick = departmentFormControllerAs.handleUpgradeClick;
        controller.isUpgradeDisabled = departmentFormControllerAs.isUpgradeDisabled;
        controller.tuSupportEmail = departmentFormControllerAs.tuSupportEmail;
        controller.isLoginRestricted = departmentFormControllerAs.isLoginRestricted;

        $scope.toggleDepartmentEditForm = function () {
            TuProfileFactory.setDepartmentEditFormVisibility();
        };

        /**
         * Actiosn to do on cancel button click in add form
         */
        $scope.toggleDepartmentAddForm = function () {
            TuProfileDepartmentFormController.resetAddForm();
            TuProfileFactory.setDepartmentAddFormVisibility();
        };

        controller.initWatches = function () {
            WatchService.create($scope, InstitutionFactory.getCampusData, function (newValue) {
                if (newValue) {
                    $scope.campusList = TuProfileDepartmentsService.formatCampusList(newValue);
                }
            }, true);

            WatchService.create($scope, InstitutionFactory.getInstitutionIdDepartmentOverview, function (newValue, oldValue) {
                if (newValue !== oldValue && newValue) {
                    $scope.newDepartmentBeforeChanges.parentCoreId = newValue;
                    $scope.newDepartment.parentCoreId = newValue;
                }
            });
            
            WatchService.create($scope, TuProfileFactory.getDepartment, function (newValue, oldValue) {
                if (newValue !== oldValue) {
                    $scope.department = newValue;
                    $scope.isInvalidDepartmentNameUpdate = false;
                    $scope.isInvalidBelongsToUpdate = false;
                    $scope.isInvalidNewDepartmentName = false;
                    $scope.isInvalidNewBelongsTo = false;
                }
            }, true);

            WatchService.create($scope, function () {
                return TuProfileFactory.isDepartmentAddFormVisible(TuProfileFactory.getProfileTabs().departments);
            }, function () {
                if ($stateParams.coreId && typeof($stateParams.coreId) !== "undefined") {
                    $scope.newDepartmentBeforeChanges.parentCoreId = $stateParams.coreId;
                    $scope.newDepartment.parentCoreId = $stateParams.coreId;
                    $stateParams.coreId = null;
                }
                TuProfileDepartmentFormController.resetAddForm();
            });
        };

        controller.init = function () {
            controller.initWatches();
            $scope.newDepartment.parentCoreId = $stateParams.coreId;
            departmentFormControllerAs.initialiseForm();
            if ($scope.forms && $scope.forms.addDepartmentForm) {
                $scope.forms.addDepartmentForm.$setUntouched();
            }
        };

        controller.init();

    };

    angular
        .module('qsHub')
        .controller('DepartmentOverviewFormController', [
            '$scope',
            '$controller',
            'TuProfileFactory',
            'InstitutionFactory',
            'TuProfileDepartmentsService',
            'WatchService',
            '$stateParams',
            App.controllers.departmentOverviewForm
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers:{}});

    App.controllers.InstitutionsTuPrograms = function (
        $scope,
        $rootScope,
        $controller,
        constants,
        TuProfileFactory,
        TuProfileService,
        InstitutionSwitchService,
        InstitutionFactory,
        WatchService,
        InstitutionsListService,
        GridService
    ) {
        $controller('TuProfileProgramsController', {$scope: $scope});
        var controller = this,
            programsControllerAs = $scope.getTuProfileProgramsController();
        $scope.showProgramEditForm = false;
        $scope.showProgramAddForm = false;
        controller.searchInProgress = false;
        controller.institutionsDropdownList = [];

        controller.searchInstitution = function (searchPhrase) {
            controller.searchInProgress = true;
            InstitutionSwitchService.searchInstitutions(searchPhrase).then(function (results) {
                controller.institutionsDropdownList = results;
                controller.searchInProgress = false;
            });
        };
        /**
         * Actions to do when programs table row is clicked.
         *
         * @param {object} program
         */
        $scope.handleDatagridRowClick = function (program) {
            TuProfileFactory.resetProgramForm();
            $scope.selectedProgramId = $scope.selectedProgramId === program.id ? null : program.id;
            // store link to program instance
            TuProfileFactory.setProgramBeforeChanges(program);
            // create new copy of program for making changes in
            program = angular.copy(program);
            TuProfileFactory.setProgramEditFormVisibility(
                $scope.selectedProgramId === program.id && program.id !== 'undefined'
            );
            TuProfileFactory.setProgram(program);
        };

        // load TU profile data
        controller.loadTuProfileData = function (coreId) {
            TuProfileService.getTuProfileData(coreId).then(function (data) {
                $scope.profileDataLoaded = true;
                $scope.tuProfile = data;
                TuProfileFactory.setData(data);
                TuProfileFactory.setProgramAddFormVisibility(false);
                TuProfileFactory.setProgramEditFormVisibility(false);
                $scope.tuProfileBeforeChanges = angular.copy(data);
                $scope.$broadcast(constants.events.institutionTuProfileChanges);
            });
        };

        $scope.handleSearchInstitutionClick = function (institution) {
            if (institution && institution.coreId) {
                InstitutionFactory.setInstitutionIdTuProgramsOverview(institution.coreId);
                programsControllerAs.clearProgramsToDelete();
                    InstitutionsListService.getTuSubscriptions(institution.coreId).then(function (data) {
                        $scope.basicProgramsEnabledTuProgramsOverview = data ? data.isBasicProgramsEnabled : false;
                    });
            }
        };

        $scope.isInstitutionSelected = function () {
            if ((InstitutionFactory.getInstitutionIdTuProgramsOverview() === null) ||
                !angular.isDefined(InstitutionFactory.getInstitutionIdTuProgramsOverview())) {
                return false;
            }
            return true;
        };

        // check if Right side pannel is active for add/edit programme
        $scope.isRightSidePanelActive = function () {
            if ($scope.showProgramEditForm || $scope.showProgramAddForm) {
                return true;
            }
            return false;
        };

        controller.getInstitutionIdTuProgramsOverviewWatch = function (newValue, oldValue) {
            if (newValue !== oldValue && newValue) {
                controller.coreId = newValue;
                controller.initWatches(newValue);
                controller.loadTuProfileData(newValue);
                programsControllerAs.initDataGrid(newValue);
            }
            else{
                InstitutionFactory.setInstitutionIdTuProgramsOverview(null);
            }
        };

        controller.hasNewProgramWatch = function (newProgramId) {
            if (newProgramId) {
                // reload datagrid
                programsControllerAs.reloadDatagrid(controller.coreId).then(function (programsList) {
                    if (programsList) {
                        var i = 0,
                            program = null,
                            total = programsList.length;
                        for (i; i < total; i++) {
                            program = programsList[i];
                            if (program.id === newProgramId) {
                                TuProfileFactory.setProgramBeforeChanges(program);
                                break;
                            }
                        }
                    }
                    // mark new program as selected in the datagrid
                    $scope.selectedProgramId = newProgramId;

                }).finally(function () {
                    // open new program in edit mode
                    TuProfileFactory.setProgramEditFormVisibility(true);
                });
            }
        };

        controller.isProgramAddFormVisibleWatch = function (visible) {
            $scope.showProgramAddForm = visible;
            visible = visible || TuProfileFactory.isProgramEditFormVisible(TuProfileFactory.getProfileTabs().programs);
            programsControllerAs.toggleColumns(visible);
        };

        controller.isProgramEditFormVisibleWatch = function (visible) {
            $scope.showProgramEditForm = visible;
            if (!visible) {
                $scope.selectedProgramId = null;
            } else {
                $scope.selectedProgramId = TuProfileFactory.getProgramId();
            }
            visible = visible || TuProfileFactory.isProgramAddFormVisible(TuProfileFactory.getProfileTabs().programs);
            programsControllerAs.toggleColumns(visible);
        };

        controller.initWatches = function (coreId) {
            if (!coreId) {
                return false;
            }
            // watch for new programs
            WatchService.create($scope, TuProfileFactory.hasNewProgram, controller.hasNewProgramWatch);

            // watch for program add form visibility changes
            WatchService.create($scope, function () {
                return TuProfileFactory.isProgramAddFormVisible(TuProfileFactory.getProfileTabs().programs);
            }, controller.isProgramAddFormVisibleWatch);

            // watch for program edit form visibility changes
            WatchService.create($scope, function () {
                return TuProfileFactory.isProgramEditFormVisible(TuProfileFactory.getProfileTabs().programs);
            }, controller.isProgramEditFormVisibleWatch);

            // Listen for changes in the visibility of the columns
            /*WatchService.create($scope, 'gridOptions.columnDefs',function (newValue, oldValue) {
                if (newValue !== oldValue) {
                    programsControllerAs.columnsVisibility = GridService.getGridColumnsVisibility(newValue);
                    GridService.storeColumnsVisibility(
                        programsControllerAs.gridVisibilityNameSpace,
                        programsControllerAs.columnsVisibility
                    );
                    // Clear filter when column is hidden
                    if (angular.isDefined(newValue) &&
                        angular.isDefined(oldValue)
                    ) {
                        for (var i = 0; i < newValue.length; i++) {
                            if (newValue[i].visible === false &&
                                oldValue[i].visible === true &&
                                angular.isDefined($scope.gridOptions.columnDefs[i].filter)
                            ) {
                                $scope.gridOptions.columnDefs[i].filter.term = null;
                            }
                        }
                    }
                }
            },true);*/

            $rootScope.$on(constants.events.logout, controller.destruct);
        };

        controller.destruct = function () {
            TuProfileFactory.reset();
            $scope.showProgramEditForm = false;
            $scope.showProgramAddForm = false;
        };

       WatchService.create(
            $scope,
            InstitutionFactory.getInstitutionIdTuProgramsOverview,
            controller.getInstitutionIdTuProgramsOverviewWatch
        );
    };

    angular
        .module('qsHub')
        .controller('InstitutionsTuProgramsController', [
            '$scope',
            '$rootScope',
            '$controller',
            'constants',
            'TuProfileFactory',
            'TuProfileService',
            'InstitutionSwitchService',
            'InstitutionFactory',
            'WatchService',
            'InstitutionsListService',
            'UiGridService',
            App.controllers.InstitutionsTuPrograms
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers:{}});

    App.controllers.InstitutionsTuProgramsForm = function (
        $scope,
        $controller,
        InstitutionFactory,
        TuProfileDepartmentsService,
        InstitutionsService,
        WatchService
    ) {
        $controller('TuProfileProgramForm', {$scope: $scope});
        var controller = this,
        programFormControllerAs = $scope.getTuProfileProgramFormController();
        controller.initWatches = function () {
            WatchService.create($scope, InstitutionFactory.getInstitutionIdTuProgramsOverview, function (newValue) {
                if (newValue) {
                    
                    $scope.newProgram.institutionCoreId = newValue;
                    $scope.newProgramBeforeChanges.institutionCoreId = newValue;

                    //get institution data
                    InstitutionsService.getInstitutionData(newValue).then(function (data) {
                        var institutionData = data && data.results ? data.results[0] : null;
                        $scope.InstitutionNameTuPrograms = institutionData.name;
                        // check if institution is client department
                        $scope.isClientDepartment = institutionData.typeId ===  InstitutionFactory.getClientDepartmentId();
                        TuProfileDepartmentsService.getAllDepartments(
                            $scope.isClientDepartment ?
                            institutionData.parentCoreId :
                            institutionData.coreId,
                            {
                                page: 1, // show first page
                                count: 1000, // count per page
                            }
                        );
                        if ($scope.isClientDepartment) {
                            $scope.newProgram.departmentCoreId = newValue;
                            $scope.departmentsList = [{
                                coreId: newValue,
                                name: $scope.InstitutionNameTuPrograms
                            }];
                            $scope.newProgramBeforeChanges = {
                                institutionCoreId: newValue,
                                departmentCoreId: newValue
                            };
                        }
                    });
                }
            });
        };
        controller.init = function () {
            programFormControllerAs.init();
            programFormControllerAs.initForm();
            if ($scope.forms && $scope.forms.addProgramForm) {
                $scope.forms.addProgramForm.$setUntouched();
            }
            controller.initWatches();
            angular.extend(controller, programFormControllerAs);
        };

        controller.init();

    };

    angular
        .module('qsHub')
        .controller('InstitutionsTuProgramsFormController', [
            '$scope',
            '$controller',
            'InstitutionFactory',
            'TuProfileDepartmentsService',
            'InstitutionsService',
            'WatchService',
            App.controllers.InstitutionsTuProgramsForm
        ]);

}(window.angular));
(function (angular) {
    "use strict";
  
    var App = App || {};
    App = angular.extend({}, App, { controllers: {} });
  
    App.controllers.institutionsList = function (
        $scope,
        $stateParams,
        constants,
        InstitutionsListService,
        SharedProfileFactory,
        NotifierFactory,
        InstitutionFactory,
        InstitutionsListFactory,
        orderBy,
        LoginService,
        TuProfileDepartmentsService,
        uiGridConstants,
        $timeout,
        WatchService,
        ModalService,
        TmProfileProgramsService,
        InstitutionService,
        GridService,
        InstitutionsListSubscriptionsFactory,
        TimeService,
        uiGridExporterConstants,
        uiGridExporterService,
        ManageIdsService,
        starsConstants,
        UserFactory
    ) {
        var controller = this;
        var selectValues = {},
            lastSelectValues = {};
        var filterTimeoutMilliseconds = constants.datagridInputDelay,
        filterChangesInProgress = false;
        controller.devMode = constants.dev;
        controller.filters = {};
        controller.urls = {
            'tu': constants.drupal.tu.url + '/node/',
            'tm': constants.drupal.tm.url + '/node/'
        };
        controller.newInstitution = false;
        controller.isClient = false;
        controller.isSimple = false;
        controller.isAdvancedProgram = false;
        controller.subscriptionDates = [];
        controller.isDisplayDepartmentEnabled = false;
        controller.isEditStarsEditDisabled = true;
        controller.starsVersionDisabled = true;
        $scope.institution = {};
        $scope.institutionBeforeChanges = {};
        $scope.columnsBeforeHide = [];
        $scope.forms = {};
        $scope.filterActive = true;
        $scope.isDatagridReloading = false;
        $scope.isSelectReloading = false;
        $scope.basicDetailsSubmitInProgress = false;
        $scope.subscriptionProfilesSubmitInProgress = false;
        $scope.subscriptionStatisticsSubmitInProgress = false;
        $scope.drupalSubmitInProgress = false;
        $scope.institutionGroupSubmitInProgress = false;
        $scope.institutionsWithoutGroup = [];
        $scope.isDatagridRendered = false;
        $scope.isProgramDowngradeDisabled = true;
        $scope.noChanges = true;
        $scope.visible = {};
        $scope.datePickerTu = {
            date: {
                startDate: null,
                endDate: null
            },
            options: {
                ranges: {
                    '6 months from today': [TimeService.now(), TimeService.add(6, 'months')],
                    '12 months from today': [TimeService.now(), TimeService.add(12, 'months')]
                },
                eventHandlers: {
                    'apply.daterangepicker': function (ev) {
                        $scope.handleDateRangePickerApplyClick(ev, 'tu');
                    }
                },
                opens: 'center',
                alwaysShowCalendars: true
            }
        };
        /**
         * Initialize vars for selected item and type
         */
        controller.setSelectedItem = function (typeId) {
            $scope.selectedItem = {
                selectedOptionType: {},
                parentCoreId: null
            };
            if (typeId) {
                angular.forEach(controller.typesList, function (item) {
                    if (item.uniqueId === typeId) {
                        $scope.selectedItem.selectedOptionType = item;
                    }
                });
            }
        };
  
        $scope.datePickerTm = {
            date: {
                startDate: null,
                endDate: null
            },
            options: {
                ranges: {
                    '6 months from today': [TimeService.now(), TimeService.add(6, 'months')],
                    '12 months from today': [TimeService.now(), TimeService.add(12, 'months')]
                },
                eventHandlers: {
                    'apply.daterangepicker': function (ev) {
                        $scope.handleDateRangePickerApplyClick(ev, 'tm');
                    }
                },
                opens: 'center',
                alwaysShowCalendars: true
            }
        };
  
       controller.datePickerStars = {
            date: {
                startDate: null,
                endDate: null
            },
            options: {
                ranges: {
                    '6 months from today': [TimeService.now(), TimeService.add(6, 'months')],
                    '12 months from today': [TimeService.now(), TimeService.add(12, 'months')],
                    '36 months from today': [TimeService.now(), TimeService.add(36, 'months')]
                },
                eventHandlers: {
                    'apply.daterangepicker': function (ev) {
                        $scope.handleDateRangePickerApplyClick(ev, 'stars');
                    }
                },
                parentEl:'.page-institution-list',
                opens: 'center',
                alwaysShowCalendars: true
            }
        };
  
        controller.datePickerAccord = {
            date: {
                startDate: null,
                endDate: null
            },
            options: {
                ranges: {
                    '6 months from today': [TimeService.now(), TimeService.add(6, 'months')],
                    '12 months from today': [TimeService.now(), TimeService.add(12, 'months')],
                    '36 months from today': [TimeService.now(), TimeService.add(36, 'months')]
                },
                eventHandlers: {
                    'apply.daterangepicker': function (ev) {
                        $scope.handleDateRangePickerApplyClick(ev, 'accord');
                    }
                },
                parentEl:'.page-institution-list',
                opens: 'center',
                alwaysShowCalendars: true
            }
        };
  
        $scope.activeTab = 0;
  
        $scope.disabledInstitutionListTabs = {
            'basicDetails': false,
            'subscriptions': false,
            'campuses': false,
            'institutionGroups': false,
            'drupal': false
        };
  
        $scope.handleDateRangePickerApplyClick = function (element, type) {
            if (!element.model.startDate) {
                element.model.startDate = TimeService.now();
            }
            if (!element.model.endDate) {
                element.model.endDate = TimeService.now();
            }
  
            var startDate = TimeService.getInUnix(element.model.startDate, true),
                endDate = TimeService.getInUnix(element.model.endDate, true);
  
            if (type === 'stars') {
                if (startDate !== $scope.institution.subscriptions[type].startDate ||
                    endDate !== $scope.institution.subscriptions[type].endDate
                ) {
                    controller.starsVersionDisabled = false;
                    controller.starsVersionNoChanges = $scope.institution.subscriptions.stars.version;
                }
            }
  
            $scope.institution.subscriptions[type].startDate = startDate;
            $scope.institution.subscriptions[type].endDate = endDate;
        };
  
        controller.similarNames = {
            'display': false,
            'loading': false,
            'results': []
        };
  
        controller.showDepartments = {
            'display': false,
            'loading': false,
            'results': []
        };
  
        SharedProfileFactory.setInstitutionListShowCampusInfoBlock(false);
  
        $scope.disabledSubmit = function () {
            return (
                $scope.subscriptionProfilesSubmitInProgress ||
                (
                    $scope.institution.subscriptions &&
                    $scope.institution.subscriptions.tm &&
                    $scope.institution.subscriptions.tm.subscribed &&
                    $scope.institution.subscriptions.tm.advanced &&
                    (!$scope.institution.subscriptions.tm.startDate ||
                        !$scope.institution.subscriptions.tm.endDate)
                ) ||
                (
                    $scope.institution.subscriptions &&
                    $scope.institution.subscriptions.tu &&
                    $scope.institution.subscriptions.tu.subscribed &&
                    $scope.institution.subscriptions.tu.advanced &&
                    (!$scope.institution.subscriptions.tu.startDate ||
                        !$scope.institution.subscriptions.tu.endDate)
                ) ||
                (
                    $scope.institution.subscriptions &&
                    $scope.institution.subscriptions.stars &&
                    $scope.institution.subscriptions.stars.subscribed &&
                    $scope.institution.subscriptions.stars.advanced &&
                    (!$scope.institution.subscriptions.stars.startDate ||
                        !$scope.institution.subscriptions.stars.endDate)
                 ) ||
                 (
                     $scope.institution.subscriptions &&
                     $scope.institution.subscriptions.accord &&
                     $scope.institution.subscriptions.accord.subscribed &&
                     (!$scope.institution.subscriptions.accord.startDate ||
                         !$scope.institution.subscriptions.accord.endDate)
                  )
            );
        };
  
        controller.reloadDatagrid = function () {
            controller.getPage();
        };
  
        controller.isVersionEnabled = function () {
            return ($scope.institution.subscriptions &&
                   $scope.institution.subscriptions.stars &&
                   $scope.institution.subscriptions.stars.edit) ||
                   controller.starsVersionDisabled;
        };
  
        controller.populateSubscriptionsDateDatepickers = function () {
            if ($scope.institution && $scope.institution.subscriptions) {
                if (
                    $scope.institution.subscriptions.tu &&
                    $scope.institution.subscriptions.tu.startDate &&
                    $scope.institution.subscriptions.tu.endDate
                ) {
                    $scope.datePickerTu.date.startDate = InstitutionsListService.formatSubscriptionDate(
                        $scope.institution.subscriptions.tu.startDate
                    );
                    $scope.datePickerTu.date.endDate = InstitutionsListService.formatSubscriptionDate(
                        $scope.institution.subscriptions.tu.endDate
                    );
                }
                if (
                    $scope.institution.subscriptions.tm &&
                    $scope.institution.subscriptions.tm.startDate &&
                    $scope.institution.subscriptions.tm.endDate
                ) {
                    $scope.datePickerTm.date.startDate = InstitutionsListService.formatSubscriptionDate(
                        $scope.institution.subscriptions.tm.startDate
                    );
                    $scope.datePickerTm.date.endDate = InstitutionsListService.formatSubscriptionDate(
                        $scope.institution.subscriptions.tm.endDate
                    );
                }
            }
        };
  
        controller.toggleColumns = function (hide, forceClose) {
            if (angular.isUndefined(forceClose)) {
                forceClose = false;
            }
            if (!$scope.gridOptions) {
                return;
            }
            if (hide && !forceClose) {
                // hide all columns except name
                angular.forEach($scope.gridOptions.columnDefs, function (column) {
                    $scope.visible[column.field] = column.visible;
                    column.visible = column.field === 'name';
                });
            } else {
                var columns = [];
                // show columns visible before hide
                angular.forEach($scope.columnsBeforeHide, function (column) {
                    column.visible = true;
                    columns.push(column.field);
                });
                angular.forEach($scope.gridOptions.columnDefs, function (column) {
                    if (columns.indexOf(column.field) !== -1) {
                        column.visible = true;
                    }
                });
                $scope.columnsBeforeHide = [];
                $scope.comesFromProfile = false;
            }
        };
  
        controller.getTuWarningMessage = function () {
            // if UG & PG front-end profile were enabled but now disabled
            if ($scope.institution.subscriptions &&
                $scope.institution.subscriptions.tu &&
                $scope.institution.subscriptions.tu.subscribed &&
                $scope.institution.enabled &&
                !$scope.institution.enabled.ug &&
                !$scope.institution.enabled.pg &&
                $scope.institutionBeforeChanges.enabled &&
                $scope.institutionBeforeChanges.enabled.ug &&
                $scope.institutionBeforeChanges.enabled.pg
            ) {
                return 'Institution will be unpublished from topuniversities.com. Press update to continue.';
            }
            // If UG front-end profile was enabled but now disabled
            if ($scope.institution.subscriptions &&
                $scope.institution.subscriptions.tu &&
                $scope.institution.subscriptions.tu.subscribed &&
                $scope.institution.enabled &&
                !$scope.institution.enabled.ug &&
                $scope.institutionBeforeChanges.enabled &&
                $scope.institutionBeforeChanges.enabled.ug
            ) {
                return 'Undergraduate profile will be unpublished on topuniversities.com. You can access profile in Qs Hub, however will not be able to publish. Press update to continue';
            }
            // if PG front-end profile was enabled but now disabled
            if ($scope.institution.subscriptions &&
                $scope.institution.subscriptions.tu &&
                $scope.institution.subscriptions.tu.subscribed &&
                $scope.institution.enabled &&
                !$scope.institution.enabled.pg &&
                $scope.institutionBeforeChanges.enabled &&
                $scope.institutionBeforeChanges.enabled.pg
            ) {
                return 'Postgraduate profile will be unpublished on topuniversities.com. You can access profile in Qs Hub, however will not be able to publish. Press update to continue';
            }
            // if TU hub profile was enabled but now disabled
            if ($scope.institution.subscriptions &&
                $scope.institution.subscriptions.tu &&
                !$scope.institution.subscriptions.tu.subscribed &&
                $scope.institutionBeforeChanges.subscriptions &&
                $scope.institutionBeforeChanges.subscriptions.tu &&
                $scope.institutionBeforeChanges.subscriptions.tu.subscribed
            ) {
                return 'Disabling in QS Hub will also disable profile in topuniversities.com. If you wish to continue please press update.';
            }
            return false;
        };
  
        controller.hasTuWarning = function () {
            return !!controller.getTuWarningMessage();
        };
  
        controller.getTmWarningMessage = function () {
            // if MBA front-end profile were enabled but now disabled
            if ($scope.institution.subscriptions &&
                $scope.institution.subscriptions.tm &&
                $scope.institution.subscriptions.tm.subscribed &&
                $scope.institution.enabled &&
                !$scope.institution.enabled.tm &&
                $scope.institutionBeforeChanges.enabled &&
                $scope.institutionBeforeChanges.enabled.tm
            ) {
                return 'Mba profile will be unpublished on topmba.com. You can access profile in Qs Hub, however will not be able to publish. Press update to continue.';
            }
            // if TM hub profile was enabled but now disabled
            if ($scope.institution.subscriptions &&
                $scope.institution.subscriptions.tm &&
                !$scope.institution.subscriptions.tm.subscribed &&
                $scope.institutionBeforeChanges.subscriptions &&
                $scope.institutionBeforeChanges.subscriptions.tm &&
                $scope.institutionBeforeChanges.subscriptions.tm.subscribed
            ) {
                return 'Disabling in QS Hub will also disable profile in topmba.com. If you wish to continue please press update.';
            }
            return false;
        };
  
        controller.hasTmWarning = function () {
            return !!controller.getTmWarningMessage();
        };
  
        controller.isTuSubscriptionDatesInvalid = function () {
            return $scope.institution.subscriptions &&
                $scope.institution.subscriptions.tu &&
                $scope.institution.subscriptions.tu.subscribed &&
                $scope.institution.subscriptions.tu.advanced && (
                    !$scope.institution.subscriptions.tu.startDate ||
                    $scope.institution.subscriptions.tu.startDate === '' ||
                    !$scope.institution.subscriptions.tu.endDate ||
                    $scope.institution.subscriptions.tu.endDate === '');
        };
  
        controller.isTmSubscriptionDatesInvalid = function () {
            return $scope.institution.subscriptions &&
                $scope.institution.subscriptions.tm &&
                $scope.institution.subscriptions.tm.subscribed &&
                $scope.institution.subscriptions.tm.advanced && (
                    !$scope.institution.subscriptions.tm.startDate ||
                    $scope.institution.subscriptions.tm.startDate === '' ||
                    !$scope.institution.subscriptions.tm.endDate ||
                    $scope.institution.subscriptions.tm.endDate === '');
        };
  
        controller.isStarsSubscriptionDatesInvalid = function () {
            return $scope.institution.subscriptions &&
                $scope.institution.subscriptions.stars &&
                $scope.institution.subscriptions.stars.subscribed &&
                ($scope.institution.subscriptions.stars.advanced ||
                $scope.institution.subscriptions.stars.version) &&
                (!$scope.institution.subscriptions.stars.startDate ||
                $scope.institution.subscriptions.stars.startDate === '' ||
                !$scope.institution.subscriptions.stars.endDate ||
                $scope.institution.subscriptions.stars.endDate === '');
        };
  
        controller.isAccordSubscriptionDatesInvalid = function () {
            return $scope.institution.subscriptions &&
                $scope.institution.subscriptions.accord &&
                $scope.institution.subscriptions.accord.subscribed && (
                    !$scope.institution.subscriptions.accord.startDate ||
                    $scope.institution.subscriptions.accord.startDate === '' ||
                    !$scope.institution.subscriptions.accord.endDate ||
                    $scope.institution.subscriptions.accord.endDate === '');
        };
  
        controller.isStarsSubscriptionVersionInvalid = function () {
            return $scope.institution.subscriptions &&
                $scope.institution.subscriptions.stars &&
                (!$scope.institution.subscriptions.stars.version &&
                !$scope.institution.businessSchool) &&
                $scope.institution.subscriptions.stars.startDate &&
                $scope.institution.subscriptions.stars.startDate !== '' &&
                $scope.institution.subscriptions.stars.endDate &&
                $scope.institution.subscriptions.stars.endDate !== '';
        };
  
        /**
         * Actions to do when dataGrid row is clicked.
         *
         * @param {object} institution
         * @param {boolean|null} forceClose
         */
        $scope.handleDatagridRowClick = function (institution, forceClose) {
            controller.resetBasicDetailsForm();
            controller.resetDrupalForm();
            controller.isClient = InstitutionsListService.isClientDepartment(institution.typeId);
            controller.isSimple = InstitutionsListService.isSimpleDepartment(institution.typeId);
            if (angular.isUndefined(forceClose)) {
                forceClose = false;
            }
            $scope.institutionBeforeChanges = institution;
            $scope.noChanges = true;
            $scope.selectedInstitutionId = $scope.selectedInstitutionId === institution.id ? null : institution.id;
            if (institution.groupMembers) {
                // filter out current institution
                institution.groupMembers = InstitutionsListService.excludeGroupMember(
                    institution.coreId,
                    institution.groupMembers
                );
                $scope.institutionsWithoutGroup = institution.groupMembers;
                $scope.institutionBeforeChanges.groupMembers = angular.copy(institution.groupMembers);
            }
            $scope.institution = angular.copy(institution);
            // on load, order by order value that comes from database
            $scope.institution.campus = orderBy($scope.institution.campus, 'order', false);
            $scope.showInfoBlock = forceClose ? false : $scope.selectedInstitutionId === institution.id;
            $scope.gridOptions.enableGridMenu = !$scope.showInfoBlock;
            $scope.gridOptions.enableColumnResizing = $scope.selectedInstitutionId === null;
            $scope.gridOptions.enablePaginationControls = !$scope.showInfoBlock;
  
            SharedProfileFactory.setInstitutionListShowCampusInfoBlock(false);
            if ($scope.selectedInstitutionId) {
                controller.setSelectedItem(institution.typeId);
                controller.allowedInstitutionTypes(true);
                $scope.disabledInstitutionListTabs.subscriptions = false;
                $scope.disabledInstitutionListTabs.campuses = false;
                $scope.disabledInstitutionListTabs.institutionGroups = false;
                $scope.disabledInstitutionListTabs.drupal = false;
                controller.isEditStarsSubscriptionDisabled();
            } else {
                controller.allowedInstitutionTypes();
                $scope.disabledInstitutionListTabs.subscriptions = true;
                $scope.disabledInstitutionListTabs.campuses = true;
                $scope.disabledInstitutionListTabs.institutionGroups = true;
                $scope.disabledInstitutionListTabs.drupal = true;
            }
            $scope.filterActive = true;
            if ($scope.selectedInstitutionId === institution.id && !forceClose) {
                angular.forEach($scope.gridOptions.columnDefs, function (column) {
                    if (angular.isDefined(column.visible) && column.visible === true) {
                        $scope.columnsBeforeHide.push(column);
                    }
                });
                $scope.filterActive = false;
                controller.populateSubscriptionsDateDatepickers();
  
                $scope.array = [];
  
                $scope.institutionsWithoutGroup = [];
  
            }
  
            if ($scope.institution.subscriptions &&
                $scope.institution.subscriptions.stars &&
                $scope.institution.subscriptions.stars.version
            ) {
                controller.starsVersionNoChanges = $scope.institution.subscriptions.stars.version;
                controller.starsVersionDisabled = true;
            } else {
                controller.starsVersionDisabled = false;
            }
  
            controller.isAdvancedProgram = InstitutionsListService.isAdvancedProgram(institution.typeId);
            if (controller.isAdvancedProgram) {
                controller.isProgramDowngradeEnabled();
            }
            //enable Display department button conditionally
            if (controller.isAdvancedProgram || controller.isTopLevelInstitution(institution.typeId)) {
                TuProfileDepartmentsService.getAllDepartmentsByCoreId(institution.coreId).then(function (response) {
                    controller.isDisplayDepartmentEnabled = Object.keys(response).length ? true : false;
                });
            }
            controller.toggleColumns(institution.id !== 'undefined' && $scope.selectedInstitutionId === institution.id, forceClose);
        };
  
        function getMessageStarsEdit() {
            return $scope.institution.subscriptions.stars.edit ?
                'start' : 'exit';
        }
  
        controller.isEditStarsSubscriptionDisabled = function () {
            if ($scope.institution) {
                controller.isEditStarsEditDisabled =
                    !$scope.institution.subscriptions ||
                    !$scope.institution.subscriptions.stars ||
                    (!$scope.institution.subscriptions.stars.startDate &&
                    !$scope.institution.subscriptions.stars.endDate);
            }
        };
  
        controller.handleStarsSubscriptionEditModeClick = function () {
            var modalOptions = {
                    closeButtonText: 'Cancel',
                    actionButtonText: 'Yes',
                    actionButtonClass: 'primary',
                    headerText: 'Edit existing stars subscription',
                    bodyText: 'You are going to ' + getMessageStarsEdit() + ' edit mode for existing Stars subscription period'
                };
            ModalService.show({}, modalOptions).then(function () {},
                function() {
                    $scope.institution.subscriptions.stars.edit = !$scope.institution.subscriptions.stars.edit;
                    if (!$scope.institution.subscriptions.stars.edit) {
                        controller.starsVersionDisabled = true;
                    }
                }
            );
        };
  
        controller.allowProgramDowngrade = function () {
            return !controller.programDowngradeInProgress &&
                $scope.institution && $scope.institution.active &&
                controller.isAdvancedProgram;
        };
  
        controller.handleProgramDowngradeClick = function (e) {
            if ($scope.isProgramDowngradeDisabled) {
                e.preventDefault();
                return false;
            }
            var params = {
                'filter[coreId]' : '=' + $scope.institution.coreId,
                'columns[]' : 'scopusId'
            };
            controller.scopusIdsFetchInProgress = true;
            ManageIdsService.search(params).then(function (data) {
                controller.scopusIdsFetchInProgress = false;
                var text = data.results && data.results.length? 'This advanced profile/program has a Scopus ID which will be impacted by downgrading. Are you sure you want to downgrade?' : 'Are you sure you want to downgrade this program?';
                var modalOptions = {
                    closeButtonText: 'Cancel',
                    actionButtonText: 'Yes',
                    actionButtonClass: 'danger',
                    headerText: 'Program Downgrade',
                    bodyText: text
                };
                ModalService.show({}, modalOptions).then(function () {
                    controller.programDowngradeInProgress = true;
                    // make downgrade request
                    TmProfileProgramsService.downgrade($scope.institution.coreId).then(controller.programDowngradeCallback);
                });
            });
        };
  
        /**
         * Actions to do when downgrade request is finished.
         *
         * @param {boolean} success
         */
        controller.programDowngradeCallback = function (success) {
            if (success) {
                // Fake "click" to close info panel
                $scope.handleDatagridRowClick($scope.institution);
                // reload datagrid
                controller.reloadDatagrid();
            }
            controller.programDowngradeInProgress = false;
            NotifierFactory.show(
                success ? 'success' : 'error',
                success ? 'Downgrade successfully!' : 'Downgrade failed!',
                'Downgrade to Simple'
            );
        };
  
        /**
         * Set flag for downgrade program profile button
         */
        controller.isProgramDowngradeEnabled = function () {
            $scope.isProgramDowngradeDisabled = ($scope.institution.subscriptions.tm.subscribed &&
                $scope.institution.subscriptions.tm.advanced &&
                $scope.institution.subscriptions.tm.startDate !== null &&
                $scope.institution.subscriptions.tm.endDate !== null &&
                $scope.institution.subscriptions.tm.startDate < TimeService.nowUnix() &&
                $scope.institution.subscriptions.tm.endDate > TimeService.nowUnix()) ||
                controller.isFutureTmSubscription();
        };
  
        /**
         * Check if Tm subscription is in the future
         *
         * @returns {Boolean}
         */
        controller.isFutureTmSubscription = function () {
            return $scope.institution.subscriptions.tm.subscribed &&
                $scope.institution.subscriptions.tm.startDate !== null &&
                $scope.institution.subscriptions.tm.startDate > TimeService.nowUnix();
        };
  
        /**
         * Gets tooltip text for downgrade program profile button
         *
         * @returns {String}
         */
        controller.downgradeProgramTooltipText = function () {
            var text = controller.isFutureTmSubscription() ? 'future' : 'current';
  
            return 'Disabled due to tm ' + text + ' subscription';
        };
  
  
        /**
         * Reset user form.
         */
        $scope.handleResetClick = function () {
            $scope.institution = angular.copy($scope.institutionBeforeChanges);
            $scope.noChanges = true;
        };
  
        /**
         * Allowed to login as institution?
         *
         * @returns {boolean}
         */
        $scope.allowLoginAs = function () {
            return LoginService.allowLoginAs($scope.institution);
        };
  
        /**
         * Actions to do when login as button clicked.
         *
         * @returns {boolean}
         */
        $scope.handleLoginAsClick = function () {
            LoginService.getTuProfilesRedirect({ coreId: $scope.institution.coreId });
        };
  
        /**
         * Actions to do when deactivation is triggered.
         *
         * @param {Object} institution
         * @returns {boolean}
         */
        $scope.handleDeactivateClick = function (institution) {
            if (!institution || !institution.id || $scope.deactivateInProgress) {
                return false;
            }
            var params = {
                'filter[coreId]' : '=' + $scope.institution.coreId,
                'columns[]' : 'scopusId'
            };
            controller.scopusIdsFetchInProgress = true;
            ManageIdsService.search(params).then(function (data) {
                controller.scopusIdsFetchInProgress = false;
                var modalOptions = {
                    closeButtonText: 'Cancel',
                    actionButtonText: 'Yes',
                    actionButtonClass: 'danger',
                    headerText: 'Deactivate',
                    bodyText: data.results && data.results.length ? 'This advanced profile/program has a Scopus ID which will be impacted by deactivating. Are you sure you want to deactivate?' : 'Are you sure you wish to deactivate institution?',
                };
                ModalService.show({}, modalOptions).then(function () {
                    $scope.deactivateInProgress = true;
                    InstitutionsListService.deactivate(institution.id).then(controller.deactivationCallback);
                });
            });
        };
  
        $scope.handleActivateClick = function (institution) {
            if (!institution.id || $scope.activateInProgress) {
                return false;
            }
            $scope.activateInProgress = true;
            InstitutionsListService.activate(institution.id).then(controller.activationCallback);
        };
  
        controller.deactivationCallback = function (success) {
            $scope.institution.active = success ? false : true;
            if (success) {
                $scope.institutionBeforeChanges.active = $scope.institution.active;
                controller.reloadDatagrid();
            }
            $scope.deactivateInProgress = false;
  
            NotifierFactory.show(
                success ? 'success' : 'error',
                success ? 'Deactivated successfully!' : 'Deactivation failed!',
                'Deactivation'
            );
        };
  
        controller.activationCallback = function (success) {
            $scope.institution.active = success ? true : false;
            if (success) {
                $scope.institutionBeforeChanges.active = $scope.institution.active;
                controller.reloadDatagrid();
            }
            $scope.activateInProgress = false;
  
            NotifierFactory.show(
                success ? 'success' : 'error',
                success ? 'Activated successfully!' : 'Activation failed!',
                'Activation'
            );
        };
  
        controller.basicDetailsSubmitCallback = function (response) {
            var message = 'Basic Details';
            var success = false;
            if (response) {
                success = !response.error;
                if (!success) {
                    if (response.hasOwnProperty('message') && response.message.length > 0) {
                        message = response.message;
                    }
                } else {
                    if (response.hasOwnProperty('insertedId') && response.insertedId.length > 0) {
                        $scope.institution.id = response.insertedId;
                        $scope.institution.coreId = response.insertedCoreId;
                        $scope.institution.coreIdAsString = response.insertedCoreId.toString();
                        $scope.institution.tuAdvanced = false;
                        $scope.institution.tmAdvanced = false;
                        $scope.institution.active = true;
                        $scope.institution.subscriptions = { all: { subscribed: true } };
                        $scope.activeTab = 1;
                        $scope.disabledInstitutionListTabs.subscriptions = false;
                        $scope.disabledInstitutionListTabs.campuses = false;
                        $scope.disabledInstitutionListTabs.institutionGroups = false;
                        $scope.disabledInstitutionListTabs.drupal = false;
                        controller.newInstitution = true;
                        $scope.noChanges = true;
                        $scope.selectedInstitutionId = $scope.institution.id;
                        message = 'Institution added successfully!';
                        controller.starsVersionDisabled = false;
                    }
                    // update original institution object with changes
                    $scope.institutionBeforeChanges = angular.copy($scope.institution);
                    controller.reloadDatagrid();
                    controller.isClient = InstitutionsListService.isClientDepartment($scope.institution.typeId);
                }
            }
            $scope.basicDetailsSubmitInProgress = false;
  
            NotifierFactory.show(
                success ? 'success' : 'error',
                success ? 'Saved successfully!' : 'Saving failed!',
                message
            );
        };
  
        /**
         * Store basic details data.
         */
        $scope.handleBasicDetailsSubmit = function () {
            controller.resetBasicDetailsForm();
            if ($scope.basicDetailsSubmitInProgress ||
                !$scope.forms.basicDetailsForm ||
                !$scope.forms.basicDetailsForm.$valid
            ) {
                if (!$scope.forms.basicDetailsForm.name.$valid) {
                    controller.isInvalidNewSchoolName = true;
                }
                if (!$scope.forms.basicDetailsForm.typeId.$valid || (Object.getOwnPropertyNames($scope.selectedItem.selectedOptionType).length === 0)) {
                    controller.isInvalidType = true;
                }
                if (!$scope.forms.basicDetailsForm.countryCode.$valid) {
                    controller.isInvalidCountry = true;
                }
                if (!controller.isInvalidType && !$scope.forms.basicDetailsForm.belongsTo.$valid) {
                    controller.isInvalidBelongsTo = true;
                }
                if (!controller.isInvalidType && typeof $scope.institution.parentName === "undefined") {
                    controller.isInvalidParentInstitution = true;
                }
            }
            if (controller.isInvalidType ||
                controller.isInvalidNewSchoolName ||
                controller.isInvalidCountry ||
                controller.isInvalidBelongsTo ||
                controller.isInvalidParentInstitution
            ) {
                return false;
            }
  
            $scope.basicDetailsSubmitInProgress = true;
            if ($scope.institution.id) {
                InstitutionsListService.saveBasicDetails($scope.institution).then(
                    controller.basicDetailsSubmitCallback
                );
            } else {
                InstitutionsListService.insertBasicDetails($scope.institution).then(
                    controller.basicDetailsSubmitCallback
                );
            }
        };
  
        controller.resetBasicDetailsForm = function () {
            controller.isInvalidNewSchoolName = false;
            controller.isInvalidType = false;
            controller.isInvalidCountry = false;
            controller.isInvalidBelongsTo = false;
            controller.isInvalidParentInstitution = false;
        };
  
        /**
         * Validate Name field on Basic Details tab
         * @param {boolean} flag
         * @return {boolean}
         */
        controller.setIsInvalidNewSchoolName = function (flag) {
            controller.isInvalidNewSchoolName = flag;
        };
  
        /**
         * Validate field Belongs dropdown on Basic Details tab
         * @param {boolean} value
         * @return {boolean}
         */
        controller.setIsInvalidBelongsTo = function (value) {
            controller.isInvalidBelongsTo = value;
        };
  
  
        /**
         * Validate Parent Institution dropdown on Basic Details tab
         * @param {boolean} flag
         * @return {boolean}
         */
        controller.setIsInvalidParentInstitution = function (value) {
            controller.isInvalidParentInstitution = value;
        };
  
        /**
         * Store drupal related data.
         */
        $scope.handleDrupalSubmit = function () {
            controller.resetDrupalForm();
            if ($scope.drupalSubmitInProgress ||
                !$scope.forms.drupalForm ||
                !$scope.forms.drupalForm.$valid
            ) {
                if ($scope.forms.drupalForm.tuRegion && !$scope.forms.drupalForm.tuRegion.$valid) {
                    controller.isInvalidTuRegion = true;
                }
                if ($scope.forms.drupalForm.tmRegion && !$scope.forms.drupalForm.tmRegion.$valid) {
                    controller.isInvalidTmRegion = true;
                }
            }
            if (controller.isInvalidTuRegion || controller.isInvalidTmRegion) {
                return false;
            }
            //$scope.drupalSubmitInProgress = true;
            InstitutionsListService.saveDrupal($scope.institution).then(function (success) {
                $scope.drupalSubmitInProgress = false;
                $scope.institutionBeforeChanges = angular.copy($scope.institution);
                controller.reloadDatagrid();
                NotifierFactory.show(
                    success ? 'success' : 'error',
                    success ? 'Saved successfully!' : 'Saving failed!',
                    'Drupal'
                );
            });
        };
  
        /**
         * Reset Drupal from feilds
         * @return {[type]} [description]
         */
        controller.resetDrupalForm = function () {
            controller.isInvalidTuRegion = false;
            controller.isInvalidTmRegion = false;
        };
  
        /**
         * Validate tuRegion dropdown on Drupal tab
         * @param {boolean} value
         * @return {boolean}
         */
        controller.setIsInvalidTuRegion = function (value) {
            controller.isInvalidTuRegion = value;
        };
  
        /**
         * Validate tmRegion dropdown on Drupal tab
         * @param {boolean} value
         * @return {boolean}
         */
        controller.setIsInvalidTmRegion = function (value) {
            controller.isInvalidTmRegion = value;
        };
  
        $scope.disallowGroupsSubmit = function () {
            // submit process is on going
            if ($scope.institutionGroupSubmitInProgress) {
                return true;
            }
            // current group members list is not empty & assigned members already in the list
            if (
                $scope.institutionBeforeChanges.groupMembers &&
                $scope.institution.groupMembers
            ) {
                return angular.equals($scope.institutionBeforeChanges.groupMembers, $scope.institution.groupMembers);
            }
            // current group members list is empty & no members assigned
            if (
                (
                    !$scope.institutionBeforeChanges.groupMembers ||
                    !$scope.institutionBeforeChanges.groupMembers.length
                ) &&
                (
                    !$scope.institution.groupMembers ||
                    !$scope.institution.groupMembers.length
                )
            ) {
                return true;
            }
            return false;
        };
  
        /**
         * Store basic Group Institutions.
         */
        $scope.handleInstitutionGroupSubmit = function () {
            if (
                $scope.institutionGroupSubmitInProgress ||
                !$scope.forms.basicDetailsForm ||
                !$scope.forms.basicDetailsForm.$valid
            ) {
                return false;
            }
            $scope.institutionGroupSubmitInProgress = true;
            InstitutionsListService.saveInstitutionGroup($scope.institution).then(function (success) {
                $scope.institutionGroupSubmitInProgress = false;
                if (success) {
                    $scope.institutionBeforeChanges.groupMembers = angular.copy($scope.institution.groupMembers);
                    // refresh table, it needs to update the new members as well
                    controller.reloadDatagrid();
                }
                NotifierFactory.show(
                    success ? 'success' : 'error',
                    success ? 'Saved successfully!' : 'Saving failed!',
                    'Group Institutions'
                );
            });
        };
  
        $scope.searchInstitutionsWithoutGroup = function (searchPhrase) {
            $scope.isSelectReloading = true;
            InstitutionsListService.searchInstitutionsWithoutGroup(
                $scope.institution.coreId, searchPhrase
            ).then(function (results) {
                $scope.isSelectReloading = false;
                $scope.institutionsWithoutGroup = results;
            });
        };
  
        controller.formatUtcStartDate = function (type) {
            if (angular.isDefined($scope.institution.subscriptions) &&
                angular.isDefined($scope.institution.subscriptions[type]) &&
                angular.isDefined($scope.institution.subscriptions[type].startDate)
            ) {
                if (!angular.isDefined($scope.institutionBeforeChanges.subscriptions) ||
                    !angular.isDefined($scope.institutionBeforeChanges.subscriptions[type]) ||
                    !angular.isDefined($scope.institutionBeforeChanges.subscriptions[type].startDate) ||
                    (angular.isDefined($scope.institutionBeforeChanges.subscriptions) &&
                    angular.isDefined($scope.institutionBeforeChanges.subscriptions[type]) &&
                    angular.isDefined($scope.institutionBeforeChanges.subscriptions[type].startDate) &&
                    $scope.institutionBeforeChanges.subscriptions[type].startDate !== $scope.institution.subscriptions[type].startDate)
                ) {
                    $scope.institution.subscriptions[type].startDate = TimeService.getInUnix(TimeService.getMomentObject($scope.institution.subscriptions[type].startDate), true);
                }
            }
        };
  
        controller.starsValidation = function () {
            if ($scope.institution.subscriptions &&
                $scope.institution.subscriptions.stars &&
                $scope.institution.subscriptions.stars.version &&
                $scope.institution.subscriptions.stars.edit &&
                $scope.institution.subscriptions.stars.version !== controller.starsVersionNoChanges
            ) {
                NotifierFactory.show(
                    'error',
                    'Version should be same when using Stars Edit Mode.',
                    'Stars Subscription'
                );
  
                return false;
            }
  
            if (controller.isStarsSubscriptionDatesInvalid() || controller.isStarsSubscriptionVersionInvalid()) {
                NotifierFactory.show(
                    'error',
                    "Some fields don't pass validation.Please correct the fields and try again.",
                    'Stars Subscription'
                );
  
                return false;
            }
  
            return true;
        };
  
        /**
         * Store Subscriptions data.
         */
        $scope.handleSubscriptionSubmit = function () {
            if (
                $scope.subscriptionProfilesSubmitInProgress ||
                !$scope.forms.subscriptionForm ||
                !$scope.forms.subscriptionForm.$valid
            ) {
                return false;
            }
  
            if (!controller.starsValidation()) {
                return false;
            }
           
            if ($scope.institution.subscriptions &&
                $scope.institution.subscriptions.stars &&
                $scope.institution.subscriptions.stars.subscribed &&
                $scope.institution.businessSchool &&
                !$scope.institution.subscriptions.stars.version) {
                    $scope.institution.subscriptions.stars.version = starsConstants.versions.v4;
            }
  
            $scope.subscriptionProfilesSubmitInProgress = true;
            controller.formatUtcStartDate('tu');
            controller.formatUtcStartDate('tm');
            InstitutionsListService.saveSubscription($scope.institution).then(function (success) {
                $scope.subscriptionProfilesSubmitInProgress = false;
                NotifierFactory.show(
                    success ? 'success' : 'error',
                    success ? 'Saved successfully!' : 'Saving failed!',
                    'Subscriptions'
                );
                $scope.institution.subscriptionsAsArray = [];
                $scope.institution.subscriptionsAsString = '';
                InstitutionsListService.getAllSubscriptions($scope.institution.coreId).then(function (res) {
                    controller.subscriptionDates = res;
                    var max = -Infinity,
                        key;
                    controller.subscriptionDates.forEach(function (v, k) {
                        if (max < +v) {
                            max = +v;
                            key = k;
                        }
                    });
                    InstitutionsListFactory.setInvalidDates(controller.subscriptionDates[key]);
                });
  
                if (success) {
                    // check if subscription data is in the past or in the future
                    if ($scope.institution.subscriptions) {
                        controller.isEditStarsSubscriptionDisabled();
                        angular.forEach($scope.institution.subscriptions, function (subscription) {
                            if (subscription.subscribed &&
                                subscription.advanced &&
                                subscription.endDate < TimeService.nowUnix()
                            ) {
                                subscription.advanced = false;
                            }
                            if (subscription.subscribed &&
                                subscription.advanced &&
                                subscription.startDate > TimeService.nowUnix()
                            ) {
                                subscription.advanced = false;
                            }
                        });
                    }
                    InstitutionsListService.appendSubscriptionsData([$scope.institution]);
                    // check if current active institution is same as edited one
                    var currentCoreId = $scope.institution.coreId;
                    if (currentCoreId) {
                        var activeCoreId = InstitutionFactory.getCoreId();
                        if (activeCoreId && currentCoreId === activeCoreId) {
                            InstitutionFactory.setSubscriptions(angular.copy($scope.institution.subscriptions));
                        }
                    }
                    if (controller.isAdvancedProgram) {
                        controller.isProgramDowngradeEnabled();
                    }
                    // re-set institution edit instance
                    $scope.institutionBeforeChanges = angular.copy($scope.institution);
                    controller.reloadDatagrid();
  
                    InstitutionsListSubscriptionsFactory.setHasSubscriptionsChanges(true);
                    controller.starsVersionDisabled = true;
                }
            });
        };
  
        $scope.updateCountryName = function () {
            controller.isInvalidCountry = false;
            InstitutionsListService.getCountryNameByCode($scope.institution.countryCode).then(function (countryName) {
                $scope.institution.countryName = countryName;
            });
        };
  
        controller.defaultPaginationOptions = {
            page: 1,
            limit: 25
        };
        controller.paginationOptions = GridService.getColumnsFilters(
            constants.gridNameSpaces.institutionsGridFilters,
            []
        );
        controller.columnsVisibility = GridService.getColumnsVisibility(
            constants.gridNameSpaces.institutionsGridVisibility
        );
  
        controller.getPage = function () {
            return InstitutionsListService.getAll(
                angular.merge({}, controller.paginationOptions, controller.defaultPaginationOptions)
            ).then(function (response) {
                if (response && response.length === 0) {
                    return false;
                }
                if (!$scope.gridOptions) {
                    $scope.gridOptions = {};
                }
                // if new institution added, check if we shall add it in result set
                if (controller.newInstitution) {
                    var found = false;
                    // search for institution in display results
                    angular.forEach(response.results, function (institution) {
                        if (angular.equals(institution.id, $scope.institution.id)) {
                            found = true;
                        }
                    });
                    // item was not found, add it into the result set
                    if (!found) {
                        // add new institution to top of the result set
                        response.results.unshift($scope.institution);
                        // if there are more results than a page can display, remove extra elements
                        while (response.results.length > $scope.gridOptions.paginationPageSize) {
                            response.results.pop();
                        }
                        // update total items
                        response.totalMatching = response.results.length;
                    }
                    controller.newInstitution = false;
                }
                $scope.gridOptions.totalItems = response.totalMatching;
                $scope.gridOptions.data = response.results;
                $scope.gridOptions.minRowsToShow = response.totalFiltered.length < 25 ? response.totalFiltered : 25;
            });
        };
  
        controller.handleGridVisibleChanges = function () {
            var columnsVisibility = GridService.getColumnsVisibility(constants.gridNameSpaces.institutionsGridVisibility),
                field = '',
                reload = false;
            if (columnsVisibility && !$scope.comesFromProfile && $scope.filterActive) {
                angular.forEach($scope.gridOptions.columnDefs, function (column, key) {
                    field = controller.convertVisibleColumnsField(column.field);
                    if ((columnsVisibility[column.field] === true || typeof columnsVisibility[column.field] === 'undefined') &&
                        column.visible === false
                    ) {
                        if (typeof  $scope.gridOptions.columnDefs[key].filter === 'undefined') {
                            $scope.gridOptions.columnDefs[key].filter = {};
                        }
                        if (typeof  $scope.gridOptions.columnDefs[key].filter !== 'undefined' &&
                            $scope.gridOptions.columnDefs[key].filter.hasOwnProperty('term') &&
                            ($scope.gridOptions.columnDefs[key].filter.term || $scope.gridOptions.columnDefs[key].filter.term === false)) {
                            reload = true;
                        }
                        $scope.gridOptions.columnDefs[key].filter.term = null;
                        controller.paginationOptions['filter[' + field + ']'] = null;
                    }
                });
            }
            if (!$scope.comesFromProfile && $scope.filterActive) {
                controller.saveStateVisibility();
            }
            if (reload) {
                controller.reloadDatagrid();
            }
        };
  
        controller.handleGridFiltersChanges = function () {
            if ($scope.gridApi !== null) {
                filterChangesInProgress = true;
                if($scope.gridApi.pagination.getPage() > 1){
                    $scope.gridApi.pagination.seek(1);
                }
                var sortKeys = [];
                angular.forEach($scope.gridApi.grid.columns, function (column) {
                    if (typeof column.filters !== 'undefined' && column.filters[0].term !== 'undefined') {
                        if (
                            column.filters[0].type === uiGridConstants.filter.SELECT &&
                            typeof column.filters[0].term === 'object' &&
                            column.filters[0].term !== null &&
                            typeof column.filters[0].term.value !== 'undefined'
                        ) {
                            column.filters[0].term = column.filters[0].term.value;
                        }
                    }
                    if (typeof column.sort.priority !== 'undefined') {
                        sortKeys[column.sort.priority] = column.name;
                    }
                });
                if (sortKeys.length > 1) {
                    var latestSortKey = sortKeys.pop();
                    angular.forEach($scope.gridApi.grid.columns, function (column) {
                        if (typeof column.sort.priority !== 'undefined' && column.name !== latestSortKey) {
                            column.sort = {};
                        }
                    });
                }
  
                controller.paginationOptions = angular.copy(controller.defaultPaginationOptions);
                var filterColumn, sortColumn;
                angular.forEach($scope.gridApi.grid.columns, function (column) {
                    if (typeof column.filters !== 'undefined' &&
                        column.filters[0].term !== 'undefined' &&
                        column.filters[0].term !== null &&
                        (
                            typeof column.filters[0].term === 'boolean' ||
                            typeof column.filters[0].term === 'number' ||
                            (typeof column.filters[0].term === 'string' && column.filters[0].term.length > 0)
                        )
                    ) {
                        filterColumn = column.filters[0].column ? column.filters[0].column : column.field;
                        controller.paginationOptions['filter[' + filterColumn + ']'] = column.filters[0].term;
                    }
                    if (typeof column.sort.direction !== 'undefined') {
                        sortColumn = column.sort.column ? column.sort.column : column.field;
                        controller.paginationOptions['sorting[' + sortColumn + ']'] = column.sort.direction;
                    }
                });
                if (!$scope.comesFromProfile) {
                    GridService.storeColumnsFilters(
                        constants.gridNameSpaces.institutionsGridFilters,
                        controller.paginationOptions
                    );
                }
  
                if (angular.isDefined($scope.filterTimeout)) {
                    $timeout.cancel($scope.filterTimeout);
                }
  
                GridService.generateSelectValues($scope.gridApi.grid.columns, selectValues);
  
                if (!angular.equals(selectValues, lastSelectValues)) {
                    filterTimeoutMilliseconds = constants.datagridSelectDelay;
                }
  
                $scope.filterTimeout = $timeout(function () {
                    controller.getPage();
                    filterChangesInProgress = false;
                    lastSelectValues = angular.copy(selectValues);
                    filterTimeoutMilliseconds = constants.datagridInputDelay;
                }, filterTimeoutMilliseconds);
            }
        };
  
        /**
         * Allowed to view TU site
         *
         * @returns {boolean}
         */
        controller.allowViewTuSite = function () {
            return $scope.institutionBeforeChanges &&
                $scope.institutionBeforeChanges.subscriptions &&
                $scope.institutionBeforeChanges.subscriptions.tu &&
                $scope.institutionBeforeChanges.subscriptions.tu.subscribed;
        };
  
        /**
         * Allowed to view TM site
         *
         * @returns {boolean}
         */
        controller.allowViewTmSite = function () {
            return $scope.institutionBeforeChanges &&
                $scope.institutionBeforeChanges.subscriptions &&
                $scope.institutionBeforeChanges.subscriptions.tm &&
                $scope.institutionBeforeChanges.subscriptions.tm.subscribed;
        };
  
        /**
         * View TU Site enabled?
         *
         * @returns {boolean}
         */
        controller.isViewTuSiteEnabled = function () {
            return angular.isDefined($scope.institutionBeforeChanges) &&
                $scope.institutionBeforeChanges &&
                $scope.institutionBeforeChanges.enabled &&
                (
                    $scope.institutionBeforeChanges.enabled.ug ||
                    $scope.institutionBeforeChanges.enabled.pg
                ) &&
                $scope.institutionBeforeChanges.hasOwnProperty('nids') &&
                $scope.institutionBeforeChanges.nids !== null &&
                $scope.institutionBeforeChanges.nids.hasOwnProperty('master') &&
                $scope.institutionBeforeChanges.nids.master;
        };
  
        /**
         * Check its client department with active profile subscription.
         * @returns {boolean}
         */
        controller.isClientDepartmentDowngradeDisabled = function () {
            return controller.isClientDepartment($scope.institutionBeforeChanges.typeId) &&
                $scope.institutionBeforeChanges &&
                $scope.institutionBeforeChanges.subscriptions && (
                    ($scope.institutionBeforeChanges.subscriptions.tu && $scope.institutionBeforeChanges.subscriptions.tu.subscribed) ||
                    ($scope.institutionBeforeChanges.subscriptions.tm && $scope.institutionBeforeChanges.subscriptions.tm.subscribed)
                );
        };
  
        /**
         * View TM Site enabled?
         *
         * @returns {boolean}
         */
        controller.allowViewTmSiteButtonEnabled = function () {
            return InstitutionService.isTmViewingEnabled($scope.institutionBeforeChanges);
        };
  
        controller.exportVisible = function () {
            var results = $scope.gridOptions.data;
            results = InstitutionsListService.formatCsvResults(results);
            if (typeof results === 'undefined') {
                return false;
            }
            var gridApi = $scope.gridApi,
                gridOptions = $scope.gridOptions,
                exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                gridApi.grid,
                uiGridExporterConstants.VISIBLE
                ),
                exportData = GridService.getCsvData(
                    gridApi.grid.columns,
                    results
                ),
                csvContent = uiGridExporterService.formatAsCsv(
                    exportColumnHeaders,
                    exportData,
                    gridOptions.exporterCsvColumnSeparator
                );
            uiGridExporterService.downloadFile(
                $scope.gridOptions.exporterCsvFilename,
                csvContent,
                gridOptions.exporterOlderExcelCompatibility
            );
        };
  
        controller.exportAll = function () {
            InstitutionsListService.getAll(
                angular.merge({}, controller.paginationOptions, {
                    page: 1,
                    limit: 6000,
                    export: true
                })
            ).then(function (response) {
                if (typeof response.results === 'undefined') {
                    return false;
                }
                response = InstitutionsListService.formatCsvResults(response.results);
                var  gridApi = $scope.gridApi,
                     gridOptions = $scope.gridOptions,
                     exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                     gridApi.grid,
                     uiGridExporterConstants.VISIBLE
                    ),
                    exportData = GridService.getCsvData(
                        gridApi.grid.columns,
                        response
                    ),
                    csvContent = uiGridExporterService.formatAsCsv(
                        exportColumnHeaders,
                        exportData,
                        gridOptions.exporterCsvColumnSeparator
                    );
                uiGridExporterService.downloadFile(
                    gridOptions.exporterCsvFilename,
                    csvContent,
                    gridOptions.exporterOlderExcelCompatibility
                );
            });
        };
  
        controller.initDataGrid = function () {
            $scope.isDatagridReloading = false;
  
            $scope.typesList = [];
            InstitutionsListService.getTypeFilterData(true).then(function (results) {
                $scope.typesList = results;
            });
  
            var rankingsOptions = [];
            angular.forEach(controller.subscriptionsList, function (item) {
                if (InstitutionsListService.isStatisticsSubscription(item.type)) {
                    rankingsOptions.push({
                        value: item.handle,
                        label: item.name
                    });
                }
            });
  
            // get core id from URL
            controller.activeInstitutionCoreId = $stateParams.coreId;
            if (controller.activeInstitutionCoreId) {
                $scope.comesFromProfile = true;
  
                var filters = angular.copy(controller.paginationOptions);
                // update data fetch filters
                angular.forEach(filters, function (item, key) {
                    if (key.indexOf('filter') !== -1) {
                        filters[key] = null;
                        controller.paginationOptions[key] = null;
                    }
                });
                GridService.storeColumnsFilters(
                    constants.gridNameSpaces.institutionsGridFilters,
                    filters
                );
                controller.activeInstitutionCoreId = '=' + controller.activeInstitutionCoreId;
                controller.paginationOptions['filter[coreIdAsString]'] = controller.activeInstitutionCoreId;
            }
  
            $scope.countriesList = [];
            InstitutionsListService.getCountries().then(function (results) {
                controller.countriesList = results;
                angular.forEach(results, function (data) {
                    $scope.countriesList.push({
                        value: data.countryCode,
                        label: data.name
                    });
                });
                InstitutionsListFactory.setCountries($scope.countriesList);
            });
            var selectTemplate = '/scripts/shared/ui-grid/templates/selectFilterHeaderTemplate.html',
                selectCellTemplate = '/scripts/shared/ui-grid/templates/selectCellTemplate.html';
            controller.getPage().then(function () {
                $scope.gridOptions = angular.extend({}, $scope.gridOptions, {
                    enableSorting: true,
                    exporterMenuCsv: false,
                    enableGridMenu: true,
                    showGridFooter: false,
                    selectedItems: $scope.selectedProgramId,
                    enableFiltering: true,
                    useExternalFiltering: true,
                    enableColumnResize: true,
                    enableFullRowSelection: true,
                    enableRowSelection: true,
                    multiSelect: false,
                    enableRowHeaderSelection: false,
                    paginationPageSizes: [25, 50, 100],
                    paginationPageSize: 25,
                    useExternalPagination: true,
                    useExternalSorting: true,
                    rowTemplate: '/scripts/components/institutions/list/datagrid/rowTemplate.html',
                    exporterMenuAllData: false,
                    exporterMenuPdf: false,
                    exporterCsvFilename: 'institution-list-export.' + TimeService.now() + '.csv',
                    gridMenuCustomItems: [
                        {
                            title: ' Export visible data as csv',
                            action: controller.exportVisible,
                            order: 209
                        },
                        {
                            templateUrl: '/scripts/components/users/list/institutions/datagrid/exportAllMenuItem.html',
                            title: ' Export all data as csv',
                            action: controller.exportAll,
                            order: 210
                        }
                    ],
                    columnDefs: [
                        {
                            displayName: 'Name',
                            visible: true, //GridService.getVisibilityByField(controller.columnsVisibility, 'name', true),
                            field: 'name',
                            filter: {
                                condition: uiGridConstants.filter.CONTAINS,
                                term: GridService.getFilterByField(controller.paginationOptions, 'name')
                            }
                        },
                        {
                            displayName: 'Local Name',
                            visible: GridService.getVisibilityByField(controller.columnsVisibility, 'localName', false),
                            field: 'localName',
                            filter: {
                                condition: uiGridConstants.filter.CONTAINS,
                                term: GridService.getFilterByField(controller.paginationOptions, 'localName')
                            }
                        },
                        {
                            displayName: 'Core ID',
                            visible: controller.activeInstitutionCoreId ?
                                true : GridService.getVisibilityByField(controller.columnsVisibility, 'coreId', true),
                            field: 'coreId',
                            maxWidth: 120,
                            filter: {
                                condition: uiGridConstants.filter.CONTAINS,
                                term: controller.activeInstitutionCoreId ?
                                    controller.activeInstitutionCoreId :
                                    GridService.getFilterByField(controller.paginationOptions, 'coreId')
                            }
                        },
                        {
                            displayName: 'Parent Institution Core ID',
                            visible: GridService.getVisibilityByField(controller.columnsVisibility, 'parentCoreId', false),
                            field: 'parentCoreId',
                            maxWidth: 120,
                            filter: {
                                condition: uiGridConstants.filter.CONTAINS,
                                term: GridService.getFilterByField(controller.paginationOptions, 'parentCoreId')
                            }
                        },
                        {
                            displayName: 'Parent Institution Name',
                            visible: GridService.getVisibilityByField(controller.columnsVisibility, 'parentName', false),
                            field: 'parentName',
                            maxWidth: 300,
                            filter: {
                                condition: uiGridConstants.filter.CONTAINS,
                                term: GridService.getFilterByField(controller.paginationOptions, 'parentName')
                            }
                        },
                        {
                            displayName: 'Type',
                            visible: GridService.getVisibilityByField(controller.columnsVisibility, 'typeId', true),
                            field: 'typeId',
                            enableSorting: false,
                            filter: {
                                column: 'typeId',
                                type: uiGridConstants.filter.SELECT,
                                selectOptions: $scope.typesList,
                                term: GridService.getFilterByField(controller.paginationOptions, 'typeId')
                            },
                            filterHeaderTemplate: selectTemplate,
                            cellTemplate: selectCellTemplate
                        },
                        {
                            displayName: 'Country Name',
                            visible: GridService.getVisibilityByField(controller.columnsVisibility, 'countryCode', true),
                            field: 'countryCode',
                            filter: {
                                column: 'countryCode',
                                type: uiGridConstants.filter.SELECT,
                                selectOptions: $scope.countriesList,
                                searchEnabled: true,
                                term: GridService.getFilterByField(controller.paginationOptions, 'countryCode')
                            },
                            filterHeaderTemplate: selectTemplate,
                            cellTemplate: selectCellTemplate
                        },
                        {
                            displayName: 'Filemaker ID',
                            visible: GridService.getVisibilityByField(controller.columnsVisibility, 'filemakerId', false),
                            field: 'filemakerId',
                            filter: {
                                condition: uiGridConstants.filter.CONTAINS,
                                term: GridService.getFilterByField(controller.paginationOptions, 'filemakerId')
                            }
                        },
                        {
                            displayName: 'Has No Departments',
                            visible: GridService.getVisibilityByField(controller.columnsVisibility, 'hasNoDepartments', false),
                            field: 'hasNoDepartments',
                            filter: {
                                type: uiGridConstants.filter.SELECT,
                                selectOptions: [
                                    { value: true, label: 'Yes' },
                                    { value: false, label: 'No' }
                                ],
                                term: GridService.getFilterByField(controller.paginationOptions, 'hasNoDepartments')
                            },
                            filterHeaderTemplate: selectTemplate,
                            cellTemplate: selectCellTemplate
                        },
                        {
                            displayName: 'Active',
                            visible: GridService.getVisibilityByField(controller.columnsVisibility, 'active', true),
                            field: 'active',
                            filter: {
                                column: 'active',
                                type: uiGridConstants.filter.SELECT,
                                selectOptions: [
                                    { value: true, label: 'Yes' },
                                    { value: false, label: 'No' }
                                ],
                                term: GridService.getFilterByField(controller.paginationOptions, 'active')
                            },
                            maxWidth: 80,
                            filterHeaderTemplate: selectTemplate,
                            cellTemplate: selectCellTemplate
                        },
                        // {
                        //     displayName: 'Advanced TU',
                        //     visible: GridService.getVisibilityByField(controller.columnsVisibility, 'tuAdvanced', true),
                        //     field: 'tuAdvanced',
                        //     filter: {
                        //         type: uiGridConstants.filter.SELECT,
                        //         selectOptions: [
                        //             { value: true, label: 'Yes' },
                        //             { value: false, label: 'No' }
                        //         ],
                        //         term: GridService.getFilterByField(controller.paginationOptions, 'tuAdvanced')
                        //     },
                        //     enableSorting: false,
                        //     maxWidth: 120,
                        //     filterHeaderTemplate: selectTemplate,
                        //     cellTemplate: selectCellTemplate
                        // },
                        // {
                        //     displayName: 'Advanced TM',
                        //     visible: GridService.getVisibilityByField(controller.columnsVisibility, 'tmAdvanced', true),
                        //     field: 'tmAdvanced',
                        //     filter: {
                        //         type: uiGridConstants.filter.SELECT,
                        //         selectOptions: [
                        //             { value: true, label: 'Yes' },
                        //             { value: false, label: 'No' }
                        //         ],
                        //         term: GridService.getFilterByField(controller.paginationOptions, 'tmAdvanced')
                        //     },
                        //     enableSorting: false,
                        //     maxWidth: 120,
                        //     filterHeaderTemplate: selectTemplate,
                        //     cellTemplate: selectCellTemplate
                        // },
                        {
                            displayName: 'Rankings',
                            visible: GridService.getVisibilityByField(controller.columnsVisibility, 'subscriptionsAsString', true),
                            field: 'subscriptionsAsString',
                            enableSorting: false,
                            filter: {
                                type: uiGridConstants.filter.SELECT,
                                selectOptions: rankingsOptions,
                                term: GridService.getFilterByField(controller.paginationOptions, 'subscriptionsAsString')
                            },
                            filterHeaderTemplate: selectTemplate
                        },
                        {
                            displayName: 'UG node ID',
                            visible: GridService.getVisibilityByField(controller.columnsVisibility, 'nids.ug', false),
                            field: 'nids.ug',
                            filter: {
                                condition: uiGridConstants.filter.CONTAINS,
                                term: GridService.getFilterByField(controller.paginationOptions, 'nids.ug')
                            }
                        },
                        {
                            displayName: 'PG node ID',
                            visible: GridService.getVisibilityByField(controller.columnsVisibility, 'nids.pg', false),
                            field: 'nids.pg',
                            filter: {
                                condition: uiGridConstants.filter.CONTAINS,
                                term: GridService.getFilterByField(controller.paginationOptions, 'nids.pg')
                            }
                        },
                        {
                            displayName: 'Overview node ID',
                            visible: GridService.getVisibilityByField(controller.columnsVisibility, 'nids.master', false),
                            field: 'nids.master',
                            filter: {
                                condition: uiGridConstants.filter.CONTAINS,
                                term: GridService.getFilterByField(controller.paginationOptions, 'nids.master')
                            }
                        },
                        {
                            displayName: 'TM node ID',
                            visible: GridService.getVisibilityByField(controller.columnsVisibility, 'nids.tm', false),
                            field: 'nids.tm',
                            filter: {
                                condition: uiGridConstants.filter.CONTAINS,
                                term: GridService.getFilterByField(controller.paginationOptions, 'nids.tm')
                            }
                        },
                        {
                            displayName: 'Has UG',
                            visible: GridService.getVisibilityByField(controller.columnsVisibility, 'enabled.ug', false),
                            field: 'enabledUg',
                            filter: {
                                column: 'enabled.ug',
                                type: uiGridConstants.filter.SELECT,
                                selectOptions: [
                                    { value: true, label: 'Yes' },
                                    { value: false, label: 'No' }
                                ],
                                term: GridService.getFilterByField(controller.paginationOptions, 'enabled.ug')
                            },
                            filterHeaderTemplate: selectTemplate,
                            cellTemplate: selectCellTemplate
                        },
                        {
                            displayName: 'Has PG',
                            visible: GridService.getVisibilityByField(controller.columnsVisibility, 'enabled.pg', false),
                            field: 'enabledPg',
                            filter: {
                                column: 'enabled.pg',
                                type: uiGridConstants.filter.SELECT,
                                selectOptions: [
                                    { value: true, label: 'Yes' },
                                    { value: false, label: 'No' }
                                ],
                                term: GridService.getFilterByField(controller.paginationOptions, 'enabled.pg')
                            },
                            filterHeaderTemplate: selectTemplate,
                            cellTemplate: selectCellTemplate
                        },
                        {
                            displayName: 'Has TM',
                            visible: GridService.getVisibilityByField(controller.columnsVisibility, 'enabled.tm', false),
                            field: 'enabledTm',
                            filter: {
                                column: 'enabled.tm',
                                type: uiGridConstants.filter.SELECT,
                                selectOptions: [
                                    { value: true, label: 'Yes' },
                                    { value: false, label: 'No' }
                                ],
                                term: GridService.getFilterByField(controller.paginationOptions, 'enabled.tm')
                            },
                            filterHeaderTemplate: selectTemplate,
                            cellTemplate: selectCellTemplate
                        }
                    ],
                    onRegisterApi: function (gridApi) {
                        var columnsVisibilityBeforeChanges = angular.copy($scope.gridOptions.columnDefs);
                        $scope.gridApi = gridApi;
                        gridApi.selection.on.rowSelectionChanged($scope, function (row) {
                            $scope.handleDatagridRowClick(row.entity);
                        });
                        gridApi.core.on.rowsRendered($scope, function () {
                            $timeout(function() {
                                $scope.isDatagridRendered = true;
                            },200);
                            GridService.generateSelectValues($scope.gridApi.grid.columns, selectValues);
                            GridService.generateSelectValues($scope.gridApi.grid.columns, lastSelectValues);
                        });
                        gridApi.pagination.on.paginationChanged($scope, function (newPage, limit) {
                            controller.defaultPaginationOptions.page = newPage;
                            controller.defaultPaginationOptions.limit = limit;
                            if(!filterChangesInProgress) {
                                controller.getPage();
                            }
                        });
                        // actions to do on filters changes
                        $scope.gridApi.core.on.filterChanged($scope, controller.handleGridFiltersChanges);
                        // actions to do on visible columns changes
                        $scope.gridApi.core.on.rowsVisibleChanged($scope, controller.handleGridVisibleChanges);
                        // actions to do on sort order changes
                        $scope.gridApi.core.on.sortChanged($scope, controller.handleGridFiltersChanges);
                        // reset columns visibility
                        GridService.resetExtend(columnsVisibilityBeforeChanges, $scope.gridOptions.columnDefs);
                        if (
                            controller.activeInstitutionCoreId &&
                            $scope.gridOptions.data &&
                            $scope.gridOptions.data.length === 1
                        ) {
                            // activate dit mode
                            $scope.handleDatagridRowClick($scope.gridOptions.data[0]);
                        }
                    }
                });
            });
        };
  
        /**
         * Disable institution types
         *
         * @param reset
         */
        controller.allowedInstitutionTypes = function (reset) {
            if (angular.isUndefined(reset)) {
                reset = false;
            }
            angular.forEach(controller.typesList, function (item) {
                if ((item.uniqueId !== constants.typeId.clientDepartmentId &&
                    item.uniqueId !== constants.typeId.topLevelInstitutionId) &&
                    !reset
                ) {
                    item.disabled = true;
                } else {
                    item.disabled = false;
                }
            });
        };
  
        /**
         * Add institution click action.
         */
        controller.handleAddInstitutionClick = function () {
            window.location.replace('https://qs-hub2.qs.com/profile-listing/');
            return;
            controller.isEditStarsEditDisabled = true;
            controller.setSelectedItem();
            controller.similarNames.display = false;
            controller.showDepartments.display = false;
            $scope.forms.basicDetailsForm.$setPristine();
            $scope.forms.basicDetailsForm.$setUntouched();
            $scope.activeTab = 0;
            var institution = {};
            institution.id = null;
            controller.similarNames.display = false;
            controller.similarNames.loading = false;
            controller.similarNames.results = [];
            $scope.handleDatagridRowClick(institution, $scope.selectedInstitutionId === null && $scope.showInfoBlock);
        };
  
  
        /**
         * Close sidebar
         */
        controller.handleEditCloseClick = function () {
            var institution = {};
            institution.id = $scope.selectedInstitutionId;
            $scope.handleDatagridRowClick(institution, true);
        };
  
        /**
         * @param {Object} selectedType
         */
        controller.setSelectedOptionType = function (selectedType) {
            controller.isInvalidType = false;
            if (angular.isDefined(selectedType) && selectedType.hasOwnProperty('uniqueId')) {
                $scope.institution.typeId = selectedType.uniqueId;
            }
        };
  
        /**
         * Search for similar institution names
         *
         * @param {Object} institution
         */
        controller.findSimilar = function (institution) {
            if (institution.id === null && angular.isDefined(institution.name) && institution.name.length > 0) {
                controller.similarNames.display = true;
                controller.similarNames.loading = true;
                InstitutionsListService.searchInstitutions(institution.name).then(function (results) {
                    controller.similarNames.results = results;
                    controller.similarNames.loading = false;
                });
            } else {
                controller.similarNames.display = false;
                controller.similarNames.loading = false;
                controller.similarNames.results = [];
            }
        };
  
        /**
         * Remove [coreId] from a string.
         *
         * @param institutionName
         * @returns {*}
         */
        controller.stripInstitutionId = function (institutionName) {
            return institutionName.replace(/\[[0-9]+]/g, '');
        };
  
        /**
         * Search for institutions (for client department parent institution)
         *
         * @param {Object} searchResult
         */
        controller.handleSearchInstitutionClick = function (selectedSearchResult) {
            $scope.institution.parentName = controller.stripInstitutionId(selectedSearchResult.name);
            $scope.institution.parentCoreId = selectedSearchResult.coreId;
            controller.showDepartments.display = false;
            controller.showDepartments.results = [];
            return true;
        };
  
        controller.initialiseBelongsTo = function () {
            // get belongs to list
            controller.belongsToList = InstitutionsListService.getBelongsToList();
        };
  
        controller.isClientDepartment = function (institutionTypeId) {
            return InstitutionsListService.isClientDepartment(institutionTypeId);
        };
  
        controller.showParentInstitution = function (institutionTypeId) {
            return InstitutionsListService.isClientDepartment(institutionTypeId) ||
                InstitutionsListService.isSimpleDepartment(institutionTypeId) ||
                InstitutionsListService.isAdvancedProgram(institutionTypeId);
        };
  
        controller.isTopLevelInstitution = function (institutionTypeId) {
            return InstitutionsListService.isTopLevelInstitution(institutionTypeId);
        };
  
        /**
         * Action to do on search for institution.
         *
         * @param {String} searchPhrase
         */
        $scope.searchInstitutions = function (searchPhrase) {
            $scope.searchInProgress = true;
            InstitutionsListService.searchTopLevelInstitutions(searchPhrase).then(function (results) {
                $scope.parentInstitutionSearchResults = results;
                $scope.searchInProgress = false;
            });
        };
  
        /**
         * Action to do on search for institution.
         *
         * @param {String} searchPhrase
         */
        $scope.searchTopLevelInstitutions = function (searchPhrase) {
            $scope.searchInProgress = true;
            InstitutionsListService.searchTopLevelInstitutions(searchPhrase).then(function (results) {
                $scope.parentInstitutionSearchResults = results;
                $scope.searchInProgress = false;
            });
        };
  
        /**
         * Show all departments button click (add institution, client department)
         */
        controller.showDepartments = function () {
            controller.showDepartments.loading = true;
            InstitutionsListService.getDepartments($scope.institution.parentCoreId).then(function (results) {
                controller.showDepartments.results = results;
                controller.showDepartments.loading = false;
                controller.showDepartments.display = true;
            });
        };
  
        controller.handleDepartmentsListCloseClick = function () {
            controller.showDepartments.display = false;
        };
  
        controller.handleGetCampusesWatch = function (newCampuses) {
            if ($scope.selectedInstitutionId) {
                $scope.institution.campus = newCampuses;
                $scope.institutionBeforeChanges.campus = newCampuses;
            }
        };
  
        controller.handleDowngradeClick = function () {
            if (controller.isClientDepartmentDowngradeDisabled()) {
                return false;
            }
            var params = {
                'filter[coreId]' : '=' + $scope.institution.coreId,
                'columns[]' : 'scopusId'
            };
            controller.scopusIdsFetchInProgress = true;
            ManageIdsService.search(params).then(function (data) {
                controller.scopusIdsFetchInProgress = false;
                var text = data.results && data.results.length? 'This advanced profile/program has a Scopus ID which will be impacted by downgrading. Are you sure you want to downgrade?' : 'Are you sure you want to downgrade this department?';
                var modalOptions = {
                    closeButtonText: 'Cancel',
                    actionButtonText: 'Yes',
                    actionButtonClass: 'danger',
                    headerText: 'Department Downgrade',
                    bodyText: text
                };
                ModalService.show({}, modalOptions).then(function () {
                    $scope.downgradeInProgress = true;
                    // make downgrade request
                    TuProfileDepartmentsService.downgrade($scope.institution.id).then(controller.downgradeCallback);
                });
            });
        };
  
        controller.hasMissingFields = function () {
            var uni = $scope.institutionBeforeChanges;
            if (angular.equals(uni, {})) {
                return;
            }
            if (InstitutionsListService.isSimpleDepartment(uni.typeId)) {
                // check if:
                // + name is valid
                // + parent is assigned
                // + country is valid
                return (!uni.name || !uni.parentCoreId || !uni.countryCode);
            } else if (InstitutionsListService.isAdvancedProgram(uni.typeId)) {
                // check if:
                // + name is valid
                // + parent is assigned
                // + country is valid
                // + TM drupal region is assigned
                return (!uni.name || !uni.parentCoreId || !uni.countryCode || !uni.drupalTmRegionId);
            } else if (InstitutionsListService.isClientDepartment(uni.typeId)) {
                // check if:
                // + name is valid
                // + parent is assigned
                // + country is valid
                // + belongs to is assigned
                // + TU drupal region is assigned
                // + TM drupal region is assigned
                return (!uni.name || !uni.parentCoreId || !uni.countryCode ||
                    !uni.belongsTo || !uni.belongsTo.length ||
                    !uni.drupalTuRegionId ||
                    !uni.drupalTmRegionId);
            } else if (InstitutionsListService.isTopLevelInstitution(uni.typeId)) {
                // check if:
                // + name is valid
                // + country is valid
                // + TU drupal region is assigned
                // + TM drupal region is assigned
                return (!uni.name || !uni.countryCode ||
                    !uni.drupalTuRegionId ||
                    !uni.drupalTmRegionId);
            }
        };
  
        /**
         * Actions to do when downgrade request is finished.
         *
         * @param {boolean} success
         */
        controller.downgradeCallback = function (success) {
            if (success) {
                controller.isClient = false;
                controller.isSimple = true;
                controller.setSelectedItem(constants.typeId.simpleDepartmentId);
                $scope.institution.typeId = constants.typeId.simpleDepartmentId;
                if ($scope.institution.enabled) {
                    $scope.institution.enabled.ug = false;
                    $scope.institution.enabled.pg = false;
                }
                if ($scope.institution.subscriptions && $scope.institution.subscriptions.tu) {
                    delete $scope.institution.subscriptions.tu;
                }
                $scope.institutionBeforeChanges = angular.copy($scope.institution);
                controller.reloadDatagrid();
            }
            $scope.downgradeInProgress = false;
            NotifierFactory.show(
                success ? 'success' : 'error',
                success ? 'Downgrade successfully!' : 'Downgrade failed!',
                'Downgrade to Simple'
            );
        };
  
        /**
         * Action to check if upgrade button at simple dipartment is disabled
         * @return {boolean}
         */
        controller.isUpgradeButtonDisabled = function () {
            return !$scope.institutionBeforeChanges.name ||
                !$scope.institutionBeforeChanges.name.length ||
                !$scope.institutionBeforeChanges.typeId ||
                parseInt($scope.institution.typeId, 10) !== constants.typeId.simpleDepartmentId ||
                !$scope.institutionBeforeChanges.countryCode ||
                !$scope.institutionBeforeChanges.countryCode.length;
        };
  
        /**
         * Actions to do when upgrade button clicked.
         *
         * @returns {boolean}
         */
        controller.handleUpgradeClick = function () {
            if (!$scope.institution || !$scope.institution.id) {
                return false;
            }
            $scope.upgradeInProgress = true;
            TuProfileDepartmentsService.upgrade($scope.institution.id).then(controller.upgradeCallback);
        };
  
        /**
         * Actions to do when upgrade request is finished.
         *
         * @param {boolean} success
         */
        controller.upgradeCallback = function (success) {
            if (success) {
                controller.isSimple = false;
                controller.isClient = true;
                $scope.institution.typeId = constants.typeId.clientDepartmentId;
                controller.setSelectedItem(constants.typeId.clientDepartmentId);
                $scope.institutionBeforeChanges = angular.copy($scope.institution);
  
                controller.reloadDatagrid();
            }
            $scope.upgradeInProgress = false;
  
            NotifierFactory.show(
                success ? 'success' : 'error',
                success ? 'Upgraded successfully!' : 'Upgrade failed!',
                'Upgrade to Client'
            );
        };
  
        /**
         * check if active institution is simple dipartment
         * @param  {string} institutionTypeId
         * @return {boolean}
         */
        controller.isSimpleDepartment = function (institutionTypeId) {
            return InstitutionsListService.isSimpleDepartment(institutionTypeId);
        };
  
        /**
         * Check if it is All statistics handle
         * @param  {string} handle
         * @return {boolean}
         */
        controller.isAllStatistic = function (handle) {
            return InstitutionsListService.isAllStatistic(handle);
        };
  
        controller.convertVisibleColumnsField = function (value) {
            switch (value) {
                case 'enabledTm':
                    return 'enabled.tm';
                case 'enabledUg':
                    return 'enabled.ug';
                case 'enabledPg':
                    return 'enabled.pg';
                default:
                    return value;
            }
        };
  
        controller.saveStateVisibility = function () {
            var visibility = angular.copy($scope.gridOptions.columnDefs);
            angular.forEach(visibility, function (column) {
                if ([
                    'enabledTm',
                    'enabledUg',
                    'enabledPg'
                ].indexOf(column.field) !== -1
                ) {
                    column.field = controller.convertVisibleColumnsField(column.field);
                }
            });
            GridService.storeColumnsVisibility(
                constants.gridNameSpaces.institutionsGridVisibility,
                GridService.getGridColumnsVisibility(visibility)
            );
        };
  
        controller.initWatches = function () {
            // listen to active profile tab changes
            WatchService.create($scope, 'activeTab', function (newValue, oldValue) {
                if (!InstitutionsListFactory.hasSelectedTab() || !angular.equals(newValue, oldValue)) {
                    InstitutionsListFactory.setSelectedTabId(newValue);
                }
            });
            // listen to institution campuses changes
            WatchService.create($scope, InstitutionsListFactory.getCampuses, controller.handleGetCampusesWatch);
  
            WatchService.create($scope, InstitutionFactory.getActiveTab, function (newValue) {
                if (newValue) {
                    $scope.activeTab = 1;
                }
            });
  
            WatchService.create($scope, InstitutionsListFactory.isInstitutionListReload, function (value) {
                if (value) {
                    controller.reloadDatagrid();
                    InstitutionsListFactory.setReloadInstitutionList(false);
                }
            });
  
            var dataLoadWatch = WatchService.create($scope, 'dataToLoad', function (leftToLoad) {
                if (leftToLoad === 0) {
                    dataLoadWatch();
                    controller.initDataGrid();
                }
            });

            WatchService.create($scope, 'institution.subscriptions.tu.advanced', function (value) {
                if (value) {
                    $scope.institution.subscriptions.tu.crimsonBasic = false;
                    $scope.institution.subscriptions.tu.crimsonAdvance = false;
                }
            });

            WatchService.create($scope, 'institution.subscriptions.tu.crimsonBasic', function (value) {
                if (value) {
                    $scope.institution.subscriptions.tu.advanced = false;
                    $scope.institution.subscriptions.tu.crimsonAdvance = false;
                }
            });

            WatchService.create($scope, 'institution.subscriptions.tu.crimsonAdvance', function (value) {
                if (value) {
                    $scope.institution.subscriptions.tu.crimsonBasic = false;
                    $scope.institution.subscriptions.tu.advanced = false;
                }
            });
        };
  
        controller.loadData = function () {
            $scope.dataToLoad = 4;
            InstitutionsListService.getSubscriptions().then(function (subscriptions) {
                controller.subscriptionsList = subscriptions;
                $scope.dataToLoad--;
            });
            InstitutionsListService.getTypes().then(function (list) {
                var newList = [];
                angular.forEach(list, function (type) {
                    if(type.uniqueId !== 5 && type.uniqueId !== 7) {
                        newList.push(type);
                    }
                });
                controller.typesList = newList;
                InstitutionsListFactory.setTypes(controller.typesList);
                $scope.dataToLoad--;
            });
            InstitutionsListService.getTuRegions().then(function (list) {
                controller.tuRegionsList = list;
                $scope.dataToLoad--;
            });
            InstitutionsListService.getTmRegions().then(function (list) {
                controller.tmRegionsList = list;
                $scope.dataToLoad--;
            });
        };
  
        controller.init = function () {
            if (!UserFactory.hasInstitutionsListAccess()) {
                return;
            }
            controller.loadData();
            controller.initWatches();
            controller.initialiseBelongsTo();
        };
  
        controller.init();
    };
  
    angular
        .module('qsHub')
        .controller('InstitutionsListController', [
            '$scope',
            '$stateParams',
            'constants',
            'InstitutionsListService',
            'SharedProfileFactory',
            'NotifierFactory',
            'InstitutionFactory',
            'InstitutionsListFactory',
            'orderByFilter',
            'LoginService',
            'TuProfileDepartmentsService',
            'uiGridConstants',
            '$timeout',
            'WatchService',
            'ModalService',
            'TmProfileProgramsService',
            'InstitutionService',
            'UiGridService',
            'InstitutionsListSubscriptionsFactory',
            'TimeService',
            'uiGridExporterConstants',
            'uiGridExporterService',
            'ManageIdsService',
            'stars.constants',
            'UserFactory',
            App.controllers.institutionsList
        ]);
  
  } (window.angular));
  
(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {factories:{}});

    App.factories.institutionsList = function () {
        var selectedTabId = null,
            tabIds = {
                subscriptions: 1,
                campuses : 2,
                status: 5
            },
            isReload = false,
            invalidDates,
            countries = [],
            types = [],
            campuses;
        return {
            getTabIds: function () {
                return tabIds;
            },
            setSelectedTabId: function (newSelectedTabId) {
                selectedTabId = newSelectedTabId;
            },
            hasSelectedTab: function () {
                return selectedTabId !== null;
            },
            isCampusesTabSelected: function () {
                return (typeof selectedTabId !== 'undefined') && (selectedTabId === tabIds.campuses);
            },
            isSubscriptionsTabSelected: function () {
                return (typeof selectedTabId !== 'undefined') && (selectedTabId === tabIds.subscriptions);
            },
            isStatusTabSelected: function () {
                return (typeof selectedTabId !== 'undefined') && (selectedTabId === tabIds.status);
            },
            setCampuses: function (newCampuses) {
                campuses = newCampuses;
            },
            getCampuses: function () {
                return campuses;
            },
            setInvalidDates: function(val) {
                invalidDates = val;
            },
            isInvalidDates: function() {
                return invalidDates;
            },
            setReloadInstitutionList: function(val) {
                isReload = val;
            },
            isInstitutionListReload: function() {
                return isReload;
            },
            setCountries: function (data) {
                countries = data;
            },
            getCountries: function () {
                return countries;
            },
            setTypes: function (data) {
                types = data;
            },
            getTypes: function () {
                return types;
            }
        };
    };

    angular
        .module('qsHub')
        .factory('InstitutionsListFactory', App.factories.institutionsList);

}(window.angular));

/* global google */
(function(angular) {
    "use strict";

    angular
        .module('qsHub')
        .controller('CampusesListController', [
            '$scope',
            'NgTableParams',
            'InstitutionsListService',
            'CampusesService',
            'NotifierFactory',
            'SharedProfileFactory',
            'MapInitializerFactory',
            'InstitutionsListFactory',
            'WatchService',
            'ModalService',
            Service,
        ]);

    function Service(
        $scope,
        NgTableParams,
        InstitutionsListService,
        CampusesService,
        NotifierFactory,
        SharedProfileFactory,
        MapInitializerFactory,
        InstitutionsListFactory,
        WatchService,
        ModalService
    ) {
        var controller = this,
            newlyAdded,
            alreadyInitialised = false,
            notificationTitle = 'Campus';

        $scope.refreshMapInProgress = false;

        InstitutionsListService.getCountries().then(function (list) {
            controller.countriesList = list;
        });
        /**
         * Required to prevent selecting campus with null id (had
         * ng-class="{'active': campus.id == selectedCampusId}
         */
        $scope.selectedCampusId = false;

        controller.resetColumns = function () {
            angular.forEach($scope.columns, function (column, key) {
                column.show = SharedProfileFactory.getColumns()[key].show;
            });
        };

        $scope.campusesToDelete = {};
        $scope.deleteInProgress = false;
        $scope.showCampusInfoBlock = false;
        // reset values if user visits repeatedly edit campus and navigates out without reload
        SharedProfileFactory.setInstitutionListShowCampusInfoBlock($scope.showCampusInfoBlock);

        $scope.columns = [
            {
                title: 'Delete',
                show: true,
                field: 'delete',
                headerTemplateURL: 'ng-table/templates/campus/delete-header.html'
            },
            {
                title: 'Name',
                show: true,
                field: 'name'
            },
            {
                title: 'Address',
                show: true,
                field: 'addressLine'
            },
            {
                title: 'Postcode',
                show: true,
                field: 'postcode'
            },
            {
                title: 'Town/City',
                show: true,
                field: 'city'
            },
            {
                title: 'Country',
                show: false,
                field: 'country'
            },
            {
                title: 'Country Name',
                show: true,
                field: 'countryName'
            },
            {
                title: 'Primary Campus',
                show: false,
                field: 'primary'
            },
            {
                title: 'Primary Campus',
                show: true,
                field: 'primaryName'
            },
            {
                title: 'Order',
                show: false,
                field: 'order'
            },
        ];

        SharedProfileFactory.setColumns($scope.columns);
        $scope.campusMap = null;

        var hiddenColumns = [];
        angular.forEach($scope.columns, function (column) {
            if (!column.show) {
                hiddenColumns.push(column);
            }
        });
        $scope.hiddenColumns = hiddenColumns;

        /**
         * Render campus in google map.
         */
        controller.renderMap = function () {
            var campus = SharedProfileFactory.getCampus();
            if (!campus.latitude) {
                campus.latitude = null;
            }
            if (!campus.longitude) {
                campus.longitude = null;
            }
            SharedProfileFactory.setCampus(campus);
            var campusLatitude = campus.latitude,
                campusLongitude = campus.longitude;
            if (campusLatitude === null || campusLongitude === null) {
                campusLatitude = 0;
                campusLongitude = 0;
            }
            if (!google) {
                return;
            }
            var googleMapsLatLng = new google.maps.LatLng({
                lat: parseFloat(campusLatitude),
                lng: parseFloat(campusLongitude)
            });
            $scope.campusMap.panTo(googleMapsLatLng);
            $scope.campusMap.setOptions({
                //center: googleMapsLatLng,
                zoom: 15,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                draggable: true
            });
            if (controller.googleMapsMarker) {
                controller.googleMapsMarker.setMap(null);
            }
            controller.googleMapsMarker = new google.maps.Marker({
                map: $scope.campusMap,
                position: googleMapsLatLng,
                draggable: true
            });

            google.maps.event.addListener(controller.googleMapsMarker, 'dragend', function (event) {
                $scope.campusMap.panTo(event.latLng);
                campus.autoGenerate = false;
                campus.latitude = event.latLng.lat();
                campus.longitude = event.latLng.lng();
                google.maps.event.trigger($scope.campusMap, 'resize');
                $scope.campusMap.setCenter(controller.googleMapsMarker.getPosition());
                $scope.campus = campus;
                $scope.$apply(); // otherwise the changes are not updated on sidebar
            });

            /**
             * This fixes the bug when user already visited campus edit page,
             * then navigated out, then returned to campuses edit page.
             */
            $scope.campusMap.addListener('idle', function() {
                if (newlyAdded && google) {
                    google.maps.event.trigger($scope.campusMap, 'resize');
                    $scope.campusMap.setCenter(controller.googleMapsMarker.getPosition());
                    newlyAdded = false;
                }
            });
        };

        /**
         * Initialise google maps.
         */
        controller.initialiseMap = function () {
            if (!$scope.campusMap) {
                MapInitializerFactory.initStart();
            }
            MapInitializerFactory.mapsInitialized
                .then(function() {
                    if (null === $scope.campusMap && google) {
                        $scope.campusMap = new google.maps.Map(document.getElementById("campusMap"), {
                            zoom: 15,
                            mapTypeId: google.maps.MapTypeId.ROADMAP,
                            draggable: true
                        });
                        newlyAdded = true;
                    }
                    controller.renderMap();
                });
        };

        /**
         * Actions to do when data grid row is clicked.
         *
         * @param {object} campus
         * @param {boolean} mapInit
         */
        $scope.handleDataGridRowClickCampus = function (campus) {
            $scope.submitted = false;
            // create new copy of campus for making changes in it
            var campusCopy = angular.copy(campus);
            SharedProfileFactory.setCampus(campusCopy);

            $scope.selectedCampusId = $scope.selectedCampusId === campusCopy.id ? false : campusCopy.id;
            $scope.campus = campusCopy;
            SharedProfileFactory.setCampus(campusCopy);
            $scope.lat = campusCopy.latitude;
            $scope.log = campusCopy.longitude;
            $scope.displayMap = true;
            $scope.showCampusInfoBlock = $scope.selectedCampusId === campusCopy.id;
            SharedProfileFactory.setInstitutionListShowCampusInfoBlock($scope.showCampusInfoBlock);
            if ($scope.selectedCampusId === campusCopy.id) {
                angular.forEach($scope.columns, function (column) {
                    if (column.field === 'name') {
                        return;
                    }
                    column.show = false;
                });
            } else {
                controller.resetColumns();
            }
            controller.initialiseMap();
        };

        function isFormValid() {
            var isValid = true,
            campusDetailsForm =  angular.element("#campusDetailsForm")[0];

            for (var i = 0; i < campusDetailsForm.length; i++) {
                if (campusDetailsForm[i].tagName === "INPUT") {
                    if (campusDetailsForm[i].parentElement.classList.contains("has-errors")) {
                        isValid = false;
                        break;
                   }
                }
            }

            return isValid;
        }

        /**
         * Store organisation details.
         */
        $scope.handleCampusSubmit = function () {
            $scope.submitted = true;
            $scope.forms.campusDetailsForm.$setDirty();
            $scope.forms.campusDetailsForm.name.$setDirty();
            $scope.forms.campusDetailsForm.addressLine1.$setDirty();
            $scope.forms.campusDetailsForm.city.$setDirty();
            $scope.forms.campusDetailsForm.country.$setDirty();
            if ($scope.campusSubmitInProgress ||
                !$scope.forms.campusDetailsForm ||
                !$scope.forms.campusDetailsForm.$valid ||
                !isFormValid() ||
                !$scope.isValidLatitude() ||
                !$scope.isValidLongitude()
            ) {
                NotifierFactory.show(
                    'error',
                    'Invalid data in the form',
                    notificationTitle
                );

                return false;
            }
            $scope.campusSubmitInProgress = true;
            InstitutionsListService.saveCampus($scope.institution.id, $scope.campus)
                .then(function (success) {
                    $scope.campusSubmitInProgress = false;
                    NotifierFactory.show(
                        success ? 'success' : 'error',
                        success ? 'Saved successfully!' : 'Saving failed!',
                        notificationTitle
                    );
                    /**
                     * Update generated data grid fields
                     */
                    if (success) {
                        if (angular.isUndefined($scope.campus.id)) {
                            $scope.campus.id = success.insertedId;
                            if (!angular.isArray($scope.institution.campus)) {
                                $scope.institution.campus = [];
                            }
                            $scope.institution.campus.push($scope.campus);
                            $scope.selectedCampusId = $scope.campus.id;
                            $scope.showCampusInfoBlock = true;
                        }
                        if (angular.isDefined(success.coordinates)) {
                            $scope.campus.latitude = success.coordinates.latitude;
                            $scope.campus.longitude = success.coordinates.longitude;
                            SharedProfileFactory.setMapUpdateRequest(Math.random());
                        }
                        angular.forEach($scope.institution.campus, function(campus, index) {
                            if (campus.id === $scope.campus.id) {
                                $scope.institution.campus[index] = angular.copy($scope.campus);
                                $scope.institution.campus[index].addressLine = InstitutionsListService.getCampusAddress(
                                    $scope.campus
                                );
                                $scope.campus.addressLine = $scope.institution.campus[index].addressLine;
                                $scope.institution.campus[index].primaryName = InstitutionsListService.getCampusIsPrimary(
                                    $scope.campus
                                );
                                $scope.campus.primaryName = $scope.institution.campus[index].primaryName;
                                InstitutionsListService.getCountryNameByCode($scope.campus.country).then(function (countryName) {
                                    $scope.institution.campus[index].countryName = countryName;
                                });
                            } else {
                                if ($scope.campus.primary) {
                                    $scope.institution.campus[index].primary = false;
                                    $scope.institution.campus[index].primaryName = InstitutionsListService.getCampusIsPrimary(
                                        $scope.institution.campus[index]
                                    );
                                }
                            }
                        });
                        controller.reloadDatagrid();
                        InstitutionsListService.syncCampuses($scope.institution);
                        InstitutionsListFactory.setReloadInstitutionList(true);
                    }
            });
        };

        $scope.isValidField = function (field, isRequired) {
            if ($scope.campus && !CampusesService.isValidAsciiCharacters($scope.campus[field])) {

                return false;
            }
            if (!isRequired || $scope.campus && typeof $scope.campus[field] === 'number' ) {

               return true;
            }
            
            return !!(
                $scope.forms.campusDetailsForm &&
                ($scope.forms.campusDetailsForm.$pristine ||
                ($scope.forms.campusDetailsForm[field] && $scope.forms.campusDetailsForm[field].$pristine)) ||
                ($scope.campus && $scope.campus[field] && $scope.campus[field].trim().length));
        };

        $scope.isValidLatitude = function () {
            return CampusesService.isValidLatitude($scope.campus ? $scope.campus.latitude : 0);
        };

        $scope.isValidLongitude = function () {
            return CampusesService.isValidLongitude($scope.campus ? $scope.campus.longitude : 0);
        };

        $scope.isEmpty = function (value) {

            return typeof value === 'undefined' || value === '';
        };

        $scope.setValid = function (fieldName) {
            
            $scope.submitted = null;
            if (fieldName) {
                if ($scope.forms.campusDetailsForm && $scope.forms.campusDetailsForm[fieldName]) {
                    $scope.forms.campusDetailsForm[fieldName].$setPristine();
                }
            } else {
                $scope.forms.campusDetailsForm.$setPristine();
            }
        };

        /**
         * Sortable options
         */
        $scope.sortableOptions = {
            disabled: false,
            'ui-floating': false,
            start: function () {
                controller.initialList = [];
                controller.initialList = angular.copy($scope.institution.campus);
            },
            stop: function () {
                $scope.sortableOptions.disabled = true;
                // change campuses order
                controller.reOrderCampuses();
                InstitutionsListService.saveOrder($scope.institution.id, $scope.institution.campus)
                    .then(function (success) {
                        NotifierFactory.show(
                            success ? 'success' : 'error',
                            success ? ' Order saved successfully!' : 'Saving order failed!',
                            notificationTitle
                        );
                        if (!success) {
                            $scope.institution.campus = controller.initialList;
                        } else {
                            InstitutionsListService.syncCampuses($scope.institution);
                        }
                    })
                    .finally(function () {
                        $scope.sortableOptions.disabled = false;
                    });
            }
        };

        controller.getCampusesToDelete = function () {
            var campusIds = [];
            if ($scope.campusesToDelete) {
                angular.forEach($scope.campusesToDelete, function (remove, id) {
                    if (remove) {
                        campusIds.push(id);
                    }
                });
            }

            return campusIds;
        };

        controller.hasCampusesToDelete = function () {
            var departmentsIds = controller.getCampusesToDelete();
            return departmentsIds.length !== 0;
        };

        $scope.isDeleteButtonDisabled = function () {
            return $scope.deleteInProgress ||
                !controller.hasCampusesToDelete();
        };

        function getCourseNameById (id) {
            var total = $scope.tableParams.data.length,
                i = 0;
            for (i; i < total; i++) {
                if ( $scope.tableParams.data[i].id === id) {
                    return $scope.tableParams.data[i].name;
                }
            }
            return null;
        }

        function performDeletion(campusIds) {
            // set deletion in progress
            $scope.deleteInProgress = true;

            // request deletion on server side
            InstitutionsListService.deleteCampuses($scope.institution.id, campusIds).then(function (success) {
                if (success) {
                    // reset deletable campuses list
                    $scope.campusesToDelete = {};
                    // remove campuses from institution instance
                    angular.forEach(campusIds, function (campusId) {
                        angular.forEach($scope.institution.campus, function (campus, key) {
                            if (campus.id === campusId) {
                                delete $scope.institution.campus[key];
                            }
                        });
                    });
                    $scope.institution.campus = $scope.institution.campus.filter(function (item) {
                        return item !== 'undefined';
                    });
                    // reload campuses datagrid
                    controller.reloadDatagrid();

                    InstitutionsListService.syncCampuses($scope.institution);
                }
                $scope.deleteInProgress = false;
                NotifierFactory.show(
                    success ? 'success' : 'error',
                    success ? 'Deleted successfully!' : 'Deletion failed!',
                    notificationTitle
                );
            });
        }

        /**
         * Actions to do when campuses delete button is clicked.
         *
         * @returns {boolean}
         */
        $scope.handleDeleteClick = function () {
            if ($scope.deleteInProgress || !controller.hasCampusesToDelete()) {
                return false;
            }
            var campusIds = controller.getCampusesToDelete(),
                programsList = null,
                modalOptions = {
                    closeButtonText: 'Cancel',
                    actionButtonText: 'Yes',
                    actionButtonClass: 'btn-danger',
                    headerText: 'Campuses Deletion',
                    close: function () {
                        this.close();
                    },
                    confirm: function () {
                        this.close();
                        performDeletion(campusIds);
                    },
                    isLoading: function () {
                        return programsList === null;
                    },
                    getProgramsList: function () {
                        return programsList;
                    }
                }, modalDefaults = {
                    backdrop: true,
                    keyboard: true,
                    modalFade: true,
                    templateUrl: '/scripts/components/profiles/shared/campuses/campusDeletionDialogView.html'
                };
            ModalService.show(modalDefaults, modalOptions);
            CampusesService.getCoursesCounters(campusIds).then(function (results) {
                programsList = [];
                angular.forEach(results, function (count, key) {
                    programsList.push({
                        'total' : count,
                        'id': key,
                        'name': getCourseNameById(key)
                    });
                });
            });
        };

        controller.reloadDatagrid = function () {
            $scope.tableParams.reload();
        };

        /**
         * Assigns new order for campuses starting with 1.
         */
        controller.reOrderCampuses = function () {
            var assignedOrder = 1;
            for (var index = 0; index < $scope.institution.campus.length; index++) {
                $scope.institution.campus[index].order = assignedOrder;
                assignedOrder++;
            }
        };

        $scope.getMode = function () {
            return $scope.showCampusInfoBlock && $scope.selectedCampusId;
        };

        /**
         * Actions to do on add button click.
         */
        $scope.handleAddClick = function () {
            if ($scope.selectedCampusId) {
                SharedProfileFactory.setInstitutionListShowCampusInfoBlock(true);
            } else {
                SharedProfileFactory.setInstitutionListShowCampusInfoBlock(!$scope.showCampusInfoBlock);
            }
            $scope.selectedCampusId = false;
            angular.forEach($scope.columns, function (column) {
                if (column.field === 'name') {
                    return;
                }
                column.show = false;
            });
            $scope.campus = {
                displayInFrontEnd: true
            };
            if (!$scope.campus.latitude) {
                $scope.lat = $scope.campus.latitude = null;
            }
            if (!$scope.campus.longitude) {
                $scope.log = $scope.campus.longitude = null;
            }
            SharedProfileFactory.setCampus($scope.campus);
            $scope.displayMap = true;
            controller.initialiseMap();
        };

        $scope.coordinatesChanged = function () {
            SharedProfileFactory.setMapUpdateRequest(Math.random());
        };

        /**
         * Displays a message based on success.
         *
         * @param {boolean} success
         * @param {string} message
         */
        controller.coordinatesUpdated = function (success, message) {
            NotifierFactory.show(
                success,
                message,
                'Address Search'
            );
        };

        /**
         * Get coordinates callback.
         *
         * @param {Array }response
         */
        controller.getCoordinatesByCampusCallback = function (response) {
            if (response) {
                if (angular.isArray(response) && !angular.isObject(response[0]) && !angular.isObject(response[1])) {
                    $scope.campus.latitude = response[0];
                    $scope.campus.longitude = response[1];
                    SharedProfileFactory.setMapUpdateRequest(Math.random());
                    SharedProfileFactory.announceCampusesChanges($scope.campus.id);
                    controller.coordinatesUpdated('success', 'Coordinates updated!');
                } else {
                    controller.coordinatesUpdated('warning', 'Address not found!');
                }
            } else {
                controller.coordinatesUpdated('error', 'Error requesting coordinates!');
            }
            $scope.refreshMapInProgress = false;
        };

        /**
         * Refresh map function.
         */
        controller.refreshMap = function () {
            $scope.refreshMapInProgress = true;
            if ($scope.campus.autoGenerate) { // generate map by address fields
                CampusesService.getCoordinatesByCampus($scope.campus).then(
                    controller.getCoordinatesByCampusCallback
                );
            } else {  // generate map by coordinates
                // announce map reload request
                SharedProfileFactory.setMapUpdateRequest(Math.random());
                //SharedProfileFactory.announceCampusesChanges($scope.campus.id);
                $scope.refreshMapInProgress = false;
            }
        };

        /**
         * Initialise datagrid.
         */
        controller.initDataGrid = function () {
            $scope.tableParams = new NgTableParams({
                page: 1, // show first page
                count: 1000 // count per page
            }, {
                filterDelay: 0,
                getData: function() {
                    return $scope.institution.campus;
                }
            });
            alreadyInitialised = true;
        };

        /**
         * Initialise watches & events.
         */
        controller.initWatches = function () {
            // re-check the campus values and trigger map update
            WatchService.create($scope, SharedProfileFactory.mapUpdateRequest, function (newValue) {
                if (newValue && newValue !== null) {
                    if (angular.isDefined($scope.campus) &&
                        angular.isDefined($scope.campus.latitude) &&
                        angular.isDefined($scope.campus.longitude) &&
                        null !== $scope.campus
                    ) {
                        var campus = $scope.campus;
                        var intLatitude = parseInt(campus.latitude, 10),
                            intLongitude = parseInt(campus.longitude, 10);
                        if (-90 <= intLatitude && intLatitude <= 90 &&
                            -180 <= intLongitude && intLongitude <= 180 &&
                            google) {
                            var googleMapsLatLng = new google.maps.LatLng({
                                lat: parseFloat(campus.latitude),
                                lng: parseFloat(campus.longitude)
                            });
                            if (angular.isDefined(controller.googleMapsMarker)) {
                                controller.googleMapsMarker.setPosition(googleMapsLatLng);
                                google.maps.event.trigger($scope.campusMap, 'resize');
                                $scope.campusMap.setCenter(controller.googleMapsMarker.getPosition());
                            }
                        }
                    }

                }
            });

            // watch to active institution changes
            WatchService.create($scope, 'institution.id', function (newId, oldId) {
                if (newId && newId !== oldId) {
                    $scope.campusesToDelete = {};
                    controller.reloadDatagrid();
                }
            });

            // listen to campus edit/add visibility changes
            WatchService.create($scope, SharedProfileFactory.getInstitutionListShowCampusInfoBlock, function (show) {
                if (!show) {
                    $scope.selectedCampusId = false;
                    controller.resetColumns();
                }
                $scope.showCampusInfoBlock = show;
            });
        };

        /**
         * Initialise controller.
         */
        controller.init = function () {
            controller.initWatches();
            controller.initDataGrid();
        };

        // listen to departments tab visibility changes
       
        var cancelInitWatch =  WatchService.create($scope, InstitutionsListFactory.isCampusesTabSelected, function (isActive) {
            // if not already initialized and tab is active
            if (!alreadyInitialised && isActive) {
                alreadyInitialised = true;
                controller.init();
                cancelInitWatch();
            }
        });

        /**
         * Display on front end switch clicked.
         */
        controller.displayOnFrontEndClick = function () {
            if ($scope.campus.displayInFrontEnd &&
                (($scope.campus.longitude === null || !$scope.campus.longitude) ||
                ($scope.campus.latitude === null || !$scope.campus.latitude))
            ) {
                $scope.campus.autoGenerate = true;
            }
        };
    }
}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { services: {} });

    App.services.InstitutionsList = function (
        $resource,
        $q,
        $log,
        $localStorage,
        constants,
        RequestsQueueService,
        InstitutionFactory,
        InstitutionsListFactory,
        TuProfileService,
        TimeService
    ) {
        var service = {
            types: null,
            tuRegions: null,
            subscriptions: null,
            countries: null,
            tmRegions: null,
            deferred: {
                getSubscriptions: null,
                searchInstitutionsWithoutGroup: null,
                getCountries: null,
                getTypes: null,
                getInstitution: null,
                getTmRegions: null
            },
            institutionGroupsRequest: null,
            country : {}
        };

        // @todo moveout models to standalone component/module

        service.getCampusesOrderingModel = function () {
            return $resource(constants.api.institutions.url + '/v1/institution/:id/campusesOrder', null, {
                update: { method: 'PATCH' }
            });
        };

        service.getInstitutionListModel = function () {
            return $resource(constants.api.institutions.url + '/v1/list/all', null, {
                get: {
                    isArray: false,
                    cancellable: true
                }
            });
        };

        service.getInstitutionModel = function () {
            return $resource(constants.api.institutions.url + '/v1/institution/:id', null, {
                create: { method: 'POST' },
                update: { method: 'PATCH' },
                replace: { method: 'PUT' }
            });
        };

        service.getInstitutionGroupsSaveModel = function () {
            return $resource(constants.api.institutions.url + '/v1/institution-groups/:id', null, {
                update: { method: 'PATCH' }
            });
        };

        service.getInstitutionGroupsModel = function () {
            return $resource(
                constants.api.institutions.url + '/v1/list/institution-groups/:searchPhrase?sorting[coreId]=asc',
                null,
                {
                    get: {
                        cancellable: true
                    }
                }
            );
        };

        /**
         * Returns campus model resource
         *
         * @returns {string}
         */
        service.getCampusModel = function () {
            return $resource(constants.api.institutions.url + '/v1/institution/:id/campus/:campusId', null, {
                update: { method: 'PATCH' },
                replace: { method: 'PUT' },
                create: { method: 'POST' },
                delete: { method: 'DELETE' },
            });
        };

        /**
         * Returns campuses model resource
         *
         * @returns {string}
         */
        service.getDeleteCampusesModel = function () {
            return $resource(constants.api.institutions.url + '/v1/institution/:id/delete-campus', null, {
                create: { method: 'POST' }
            });
        };

        /**
         * Get institution search API endpoint.
         *
         * @returns $resource
         */
        service.getTopLevelInstitutionSearchModel = function () {
            return $resource(
                constants.api.institutions.url + '/v1/list/search/:searchPhrase?sorting[coreId]=asc&filter[typeId]=' + constants.typeId.topLevelInstitutionId,
                null,
                {
                    get: {
                        cancellable: true
                    }
                }
            );
        };

        /**
         * Get institution departments search API endpoint.
         *
         * @returns $resource
         */
        service.getDepartmentsListModel = function () {
            return $resource(
                constants.api.institutions.url + '/v1/list/departments/core-id/:parentCoreId?filter[fieldName]=active&filter[typeId]=' + constants.typeId.clientDepartmentId,
                null,
                {
                    get: {
                        cancellable: true
                    }
                }
            );
        };

        /**
         * Search institutions model
         *
         * @returns {*}
         */
        service.getInstitutionSearchModel = function () {
            return $resource(
                constants.api.institutions.url + '/v1/list/search/:searchPhrase?sorting[coreId]=asc&filter[fieldName]=active',
                null,
                {
                    get: {
                        cancellable: true
                    }
                }
            );
        };

        service.getDepartmentModel = function () {
            return $resource(constants.api.institutions.url + '/v1/institution/:id', null, {
                create: { method: 'POST' }
            });
        };

        /**
         * Get all institutions.
         *
         * @param {object} params
         * @returns {Promise.<Array>}
         */
        service.getAll = function (filters) {
            if (constants.dev) {
                var startTime = new Date().getTime(), endTime;
            }
            var hasSorting = false;
            if (filters) {
                for (var key in filters) {
                    if (key.indexOf('sorting') !== -1) {
                        hasSorting = true;
                        break;
                    }
                }
            }
            if (!hasSorting) {
                filters['sorting[createdAt]'] = 'desc';
            }

            // cancel currently running requests
            RequestsQueueService.cancelAll('getAll');

            var Api = service.getInstitutionListModel().get(filters);

            // add new request to the queue
            RequestsQueueService.add(Api, 'getAll');

            return Api.$promise.then(function (data) {
                if (constants.dev) {
                    endTime = new Date().getTime();
                    $log.log('success, got data: ', data, 'in ' + (endTime - startTime) + ' ms');
                }

                // cancel currently running requests
                RequestsQueueService.cancelAll('getAll');

                data.results = service.formatList(data.results);
                return data;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                } else {
                    return [];
                }
            }).then(service.appendSubscriptionsData);
        };

        /**
         * Get all institutions subscriptions.
         *
         * @param {Number} institutionCoreId
         * @returns {Promise.<Array>}
         */
        service.getAllSubscriptions = function (institutionCoreId) {
            var Api = service.getInstitutionListModel().get({
                'filter[parentCoreId]': '=' + institutionCoreId,
                'limit' :  1,
                'columns[]' : 'subscriptions'
            });
            var subscriptions;
            // add new request to the queue
            return Api.$promise.then(function (data) {
                if (data.results) {
                    subscriptions = data.results.reduce(function (all, item, index) {
                        if (item.subscriptions.hasOwnProperty('tm') && item.subscriptions.tm.endDate !== null) {
                            all[item.coreIdAsString] = item.subscriptions.tm.endDate;
                        }
                        return all;
                    }, []);
                }
                return subscriptions;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                } else {
                    return [];
                }
            });
        };

        service.getTmSubscriptions = function (institutionCoreId) {
            var Api = service.getInstitutionListModel().get({
                'filter[coreId]': '=' + institutionCoreId,
                'limit' :  1,
                'columns[]' : 'subscriptions'
            });
            var subscriptions = [];
            // add new request to the queue
            return Api.$promise.then(function (data) {
                return data.results &&
                data.results[0] &&
                data.results[0].subscriptions &&
                data.results[0].subscriptions.tm ? data.results[0].subscriptions.tm : [];
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                } else {
                    return [];
                }
            });
        };

        service.getTuSubscriptions = function (institutionCoreId) {
            var Api = service.getInstitutionListModel().get({
                'filter[coreId]': '=' + institutionCoreId,
                'limit' :  1,
                'columns[]' : 'subscriptions'
            });
            var subscriptions = [];
            // add new request to the queue
            return Api.$promise.then(function (data) {
                return data.results &&
                data.results[0] &&
                data.results[0].subscriptions &&
                data.results[0].subscriptions.tu ? data.results[0].subscriptions.tu : [];
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                } else {
                    return [];
                }
            });
        };

        service.getStarsSubscription = function (institutionCoreId) {
            var Api = service.getInstitutionListModel().get({
                'filter[coreId]': '=' + institutionCoreId,
                'limit' :  1,
                'columns[]' : 'subscriptions.stars'
            });
            return Api.$promise.then(function (data) {
                return data;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                } else {
                    return [];
                }
            });
        };

        service.isStatisticsSubscription = function (handle, subscriptionsList) {
            var statsType = 'statistics';
            if (subscriptionsList) {
                var total = subscriptionsList.length,
                    i = 0;
                for (i; i < total; i++) {
                    if (subscriptionsList[i].handle === handle) {
                        return subscriptionsList[i].type === statsType;
                    }
                }
            } else {
                return handle === statsType;
            }

            return false;
        };

        service.appendSubscriptionsData = function (institutionsData) {
            if (!institutionsData) {
                return institutionsData;
            }
            var subscriptionName = null;
            return service.getSubscriptions().then(function (subscriptionsList) {
                if (subscriptionsList) {
                    angular.forEach(institutionsData.results, function (institutionData) {
                        if (!institutionData.subscriptionsAsArray) {
                            institutionData.subscriptionsAsArray = [];
                        }
                        if (!institutionData.subscriptions) {
                            return false;
                        }
                        angular.forEach(institutionData.subscriptions, function (subscription, key) {
                            if (
                                !subscription.subscribed ||
                                !service.isStatisticsSubscription(key, subscriptionsList)
                            ) {
                                return false;
                            }
                            subscriptionName = service.getSubscriptionNameByHandle(key, subscriptionsList);
                            if (!subscriptionName) {
                                return false;
                            }
                            institutionData.subscriptionsAsArray.push(subscriptionName);
                            institutionData.subscriptionsAsString = institutionData.subscriptionsAsArray ?
                                institutionData.subscriptionsAsArray.join(', ') : null;
                        });
                    });
                }
                return institutionsData;
            });
        };
        service.formatSubscriptionDate = function (date) {
            var formattedDate = '';
            if (date && TimeService.isValid(date)) {
                formattedDate = TimeService.formatInUnix(date);
            }

            return formattedDate;
        };

        service.getTypeFilterData = function (grid) {
            grid = grid || false;
            var deferred = $q.defer();
            service.getTypes().then(function (types) {
                var typesFilterData = [];
                if (types) {
                    angular.forEach(types, function (data) {
                        if(data.uniqueId !== 5 && data.uniqueId !== 7) {
                            if (grid) {
                                typesFilterData.push({
                                    value: data.uniqueId,
                                    label: data.name
                                });
                            } else {
                                typesFilterData.push({
                                    id: data.uniqueId,
                                    title: data.name
                                });
                            }
                        }
                    });
                }
                deferred.resolve(typesFilterData);
            });

            return deferred.promise;
        };

        service.getTypes = function () {
            // process already running
            if (!service.deferred.getTypes) {
                service.deferred.getTypes = $q.defer();
            } else {
                return service.deferred.getTypes.promise;
            }
            var deferred = service.deferred.getTypes;

            // check if data exists in cache
            if (service.types) {
                deferred.resolve(service.types);
            } else {
                var Api = $resource(constants.api.institutions.url + '/v1/list/types');
                Api.get(function (data) {
                    if (constants.dev) {
                        $log.log('success, got data: ', data);
                    }
                    service.types = data.results;
                    deferred.resolve(service.types);
                    deferred = null;
                }, function (error) {
                    //@todo log error
                    if (constants.dev) {
                        $log.log('request failed' + error);
                    }
                    service.types = [];
                    deferred.resolve(service.types);
                    deferred = null;
                });
            }

            return deferred.promise;
        };

        service.getTuRegions = function () {
            var deferred = $q.defer();
            if (service.tuRegions) {
                deferred.resolve(service.tuRegions);
            } else {
                var Api = $resource(constants.api.institutions.url + '/v1/list/tu-regions');
                Api.get(function (data) {
                    if (constants.dev) {
                        $log.log('success, got data: ', data);
                    }
                    service.tuRegions = data.results;
                    deferred.resolve(service.tuRegions);
                }, function (error) {
                    //@todo log error
                    if (constants.dev) {
                        $log.log('request failed' + error);
                        service.tuRegions = [];
                    }
                    deferred.resolve(service.tuRegions);
                });
            }

            return deferred.promise;
        };

        service.getTmRegions = function () {
            // process already running
            if (!service.deferred.getTmRegions) {
                service.deferred.getTmRegions = $q.defer();
            } else {
                return service.deferred.getTmRegions.promise;
            }
            var deferred = service.deferred.getTmRegions;

            if (service.tmRegions) {
                deferred.resolve(service.tmRegions);
            } else {
                var Api = $resource(constants.api.institutions.url + '/v1/list/tm-regions');
                Api.get(function (data) {
                    if (constants.dev) {
                        $log.log('success, got data: ', data);
                    }
                    service.tmRegions = data.results;
                    deferred.resolve(service.tmRegions);
                    deferred = null;
                }, function (error) {
                    //@todo log error
                    if (constants.dev) {
                        $log.log('request failed' + error);
                    }
                    service.tmRegions = [];
                    deferred.resolve(service.tmRegions);
                    deferred = null;
                });
            }

            return deferred.promise;
        };

        service.getTypeNameById = function (typeId) {
            var deferred = $q.defer(),
                typeName;

            service.getTypes().then(function (types) {
                angular.forEach(types, function (type) {
                    if (type.uniqueId === typeId) {
                        typeName = type.name;

                    }
                });
                deferred.resolve(typeName);
            });

            return deferred.promise;
        };

        /**
         * Format institutions list.
         *
         * @param {object} institutionsData
         * @returns {object}
         */
        service.formatList = function (institutionsData) {
            if (angular.isArray(institutionsData)) {
                angular.forEach(institutionsData, function (institutionData) {
                    service.processInstitution(institutionData);
                });
            } else {
                service.processInstitution(institutionsData);
            }

            return institutionsData;
        };

        /**
         * Format institution
         *
         * @param {object} institutionData
         * @returns {object}
         */
        service.processInstitution = function (institutionData) {
            service.getTypeNameById(institutionData.typeId).then(function (typeName) {
                institutionData.typeName = typeName;
            });
            service.getCountryNameByCode(institutionData.countryCode).then(function (countryName) {
                institutionData.countryName = countryName;
            });
            institutionData = service.processCampuses(institutionData);
            institutionData.isActive = institutionData.active ? 'Yes' : 'No';
            institutionData.enabledUg = institutionData.enabled && typeof institutionData.enabled.ug !== 'undefined' ? institutionData.enabled.ug : null;
            institutionData.enabledPg = institutionData.enabled && typeof institutionData.enabled.pg !== 'undefined' ? institutionData.enabled.pg : null;
            institutionData.enabledTm = institutionData.enabled && typeof institutionData.enabled.tm !== 'undefined' ? institutionData.enabled.tm : null;
            institutionData.tuAdvanced = !!institutionData.tuAdvanced;
            institutionData.tmAdvanced = !!institutionData.tmAdvanced;
            institutionData.hasNoDepartments = !!institutionData.hasNoDepartments;
            if (typeof institutionData.nids === 'undefined' || institutionData.nids === null) {
                institutionData.nids = {
                    ug: null,
                    pg: null,
                    master: null,
                    tm: null
                };
            }

            service.getTuRegions().then(function (list) {
                if (!institutionData.drupalTuRegionId) {
                    return false;
                }
                var validTid = false;
                angular.forEach(list, function (item) {
                    if (item.tid === institutionData.drupalTuRegionId && item.tu) {
                        validTid = true;
                    }
                });
                if (!validTid) {
                    institutionData.drupalTuRegionId = null;
                }
            });

            service.getTmRegions().then(function (list) {
                var validTid = false;
                if (!institutionData.drupalTmRegionId) {
                    return false;
                }
                angular.forEach(list, function (item) {
                    if (item.tid === institutionData.drupalTmRegionId && item.tm) {
                        validTid = true;
                    }
                });
                if (!validTid) {
                    institutionData.drupalTmRegionId = null;
                }
            });
        };

        /**
         * Format campuses
         *
         * @returns {object}
         * @param {object} institutionData
         * @param {any} campusId
         * @param {boolean} unCheckAll
         */
        service.processCampuses = function (institutionData, campusId, unCheckAll) {
            if (!angular.isDefined(campusId)) {
                campusId = null;
            }
            if (!angular.isDefined(unCheckAll)) {
                unCheckAll = false;
            }
            var primaryCount = 0;
            angular.forEach(institutionData.campus, function (campus, index) {
                service.getCountryNameByCode(campus.country).then(function (countryName) {
                    campus.countryName = countryName;
                });
                if (campusId === null) {
                    if (angular.isDefined(institutionData.campus[index].primary) && institutionData.campus[index].primary) {
                        primaryCount++;
                    } else {
                        institutionData.campus[index].primary = false;
                    }
                    if (primaryCount > 1) {
                        institutionData.campus[index].primary = false;
                        primaryCount--;
                    }
                    if (unCheckAll) {
                        institutionData.campus[index].primary = false;
                    }
                } else {
                    if (institutionData.campus[index].id === campusId) {
                        institutionData.campus[index].primary = true;
                    } else {
                        institutionData.campus[index].primary = false;
                    }
                }
                campus.primaryName = service.getCampusIsPrimary(campus);
                campus.addressLine = service.getCampusAddress(campus);
            });

            return institutionData;
        };

        /**
         *  Get subscription name by its handle.
         *
         * @param {string} handle
         * @param {object} subscriptionsList
         * @returns {string}
         */
        service.getSubscriptionNameByHandle = function (handle, subscriptionsList) {
            var subscriptionName = '';
            if (handle === 'ug') {
                return 'Undergraduate Profile';
            } else if (handle === 'pg') {
                return 'Postgraduate Profile';
            } else if (handle === 'tm') {
                return 'TopMBA Profile';
            }
            if (subscriptionsList) {
                angular.forEach(subscriptionsList, function (subscription) {
                    if (subscription.handle && subscription.name && subscription.handle === handle) {
                        subscriptionName = subscription.name;
                        return;
                    }
                });
            }
            return subscriptionName;
        };

        /**
         * Get subscriptions list.
         *
         * @returns {Promise:Object}
         */
        service.getSubscriptions = function () {
            // process already running
            if (!service.deferred.getSubscriptions) {
                service.deferred.getSubscriptions = $q.defer();
            } else {
                return service.deferred.getSubscriptions.promise;
            }
            var deferred = service.deferred.getSubscriptions,
                tempList = [];

            // check if data exists in cache
            if (service.subscriptions) {
                angular.forEach(service.subscriptions, function (item) {
                    if (item.handle !== "brics") {
                        tempList.push(item);
                    }
                });
                service.subscriptions = tempList;
            } else {
                var Api = $resource(constants.api.institutions.url + '/v1/list/subscriptions?filter[type]=statistics&sorting[order]=asc');
                Api.get(function (data) {
                    if (constants.dev) {
                        $log.log('success, got data: ', data);
                    }
                    angular.forEach(data.results, function (item) {
                        if (item.handle !== "brics") {
                            tempList.push(item);
                        }
                    });
                    // service.subscriptions = data.results;
                    service.subscriptions = tempList;
                    deferred.resolve(service.subscriptions);
                    deferred = null;
                }, function (error) {
                    //@todo log error
                    if (constants.dev) {
                        $log.log('request failed' + error);
                    }
                    service.subscriptions = [];
                    deferred.resolve(service.subscriptions);
                    deferred = null;
                });
            }

            return deferred.promise;
        };

        /**
         * Mark user as active.
         *
         * @param {string} id
         * @returns {Promise.<boolean>}
         */
        service.activate = function (id) {
            var Api = $resource(constants.api.institutions.url + '/v1/institution/:id/activate', { id: id });
            return Api.get().$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        /**
         * Mark user as inactive.
         *
         * @param {string} id
         * @returns {Promise.<boolean>}
         */
        service.deactivate = function (id) {
            var Api = $resource(constants.api.institutions.url + '/v1/institution/:id/deactivate', { id: id });
            return Api.get().$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        service.getInstitutionGroupsSaveModel = function () {
            return $resource(constants.api.institutions.url + '/v1/institution-groups/:id', null, {
                update: { method: 'PATCH' }
            });
        };

        service.getInstitutionGroupsModel = function () {
            return $resource(
                constants.api.institutions.url + '/v1/list/institution-groups/:searchPhrase?sorting[coreId]=asc',
                null,
                {
                    get: {
                        cancellable: true
                    }
                }
            );
        };

        service.filterObject = function (filters, object) {
            var filteredObject = angular.copy(filters);
            angular.forEach(filters, function (value, key) {
                if (value && typeof value === 'object') {
                    angular.forEach(value, function (subValue, subKey) {
                        if (subValue === null && object !== null && object.hasOwnProperty(key) && object[key] !== null && object[key].hasOwnProperty(subKey) && object[key][subKey] !== 'undefined') {
                            filteredObject[key][subKey] = object[key][subKey];
                        }
                    });
                } else if (value === null && object[key] !== 'undefined') {
                    filteredObject[key] = object[key];
                }
            });

            return filteredObject;
        };

        /**
         * Filter out non subscriptions related data.
         *
         * @param {Object} filters
         * @param {Object} object
         * @returns {Object}
         */
        service.filterObjectForSubscriptions = function (filters, object) {
            var filteredObject = angular.copy(filters);
            angular.forEach(filters, function (value, key) {
                if (value && typeof value === 'object') {
                    angular.forEach(value, function (subValue, subKey) {
                        if (subValue && typeof subValue === 'object') {
                            angular.forEach(subValue, function (subValue2, subKey2) {
                                if (subValue2 === null) {
                                    if (
                                        typeof object[key] !== 'undefined' &&
                                        object[key] !== null &&
                                        typeof object[key][subKey] !== 'undefined' &&
                                        object[key][subKey][subKey2] !== null &&
                                        typeof object[key][subKey][subKey2] !== 'undefined'
                                    ) {
                                        filteredObject[key][subKey][subKey2] = object[key][subKey][subKey2] ?
                                            object[key][subKey][subKey2] : false;
                                    } else {
                                        filteredObject[key][subKey][subKey2] = null;
                                    }
                                }
                            });
                        } else if (subValue === null && object[key] && object[key][subKey]) {
                            filteredObject[key][subKey] = object[key][subKey];
                        }
                    });
                } else if (value === null && object[key]) {
                    filteredObject[key] = object[key];
                }
            });

            return filteredObject;
        };

        service.isSimpleDepartment = function (institutionTypeId) {
            return institutionTypeId === constants.typeId.simpleDepartmentId;
        };

        service.isClientDepartment = function (institutionTypeId) {
            return institutionTypeId === constants.typeId.clientDepartmentId;
        };

        service.isTopLevelInstitution = function (institutionTypeId) {
            return institutionTypeId === constants.typeId.topLevelInstitutionId;
        };

        service.isAdvancedProgram = function (institutionTypeId) {
            return institutionTypeId === constants.typeId.advancedProgramId;
        };

        service.insertBasicDetails = function (institutionData) {
            var EndpointModel,
                dataToInsert = {};

            if (service.isClientDepartment(institutionData.typeId)) {
                EndpointModel = service.getDepartmentModel();
                dataToInsert = {
                    active: null,
                    belongsTo: null,
                    businessSchool: null,
                    countryCode: null,
                    countryName: null,
                    filemakerId: null,
                    localName: null,
                    name: null,
                    parentCoreId: null,
                    parentName: null,
                    typeId: null
                };
            } else {
                EndpointModel = service.getInstitutionModel();
                dataToInsert = {
                    active: null,
                    businessSchool: null,
                    countryCode: null,
                    countryName: null,
                    filemakerId: null,
                    hasNoDepartments: null,
                    localName: null,
                    name: null,
                    parentCoreId: null,
                    parentName: null,
                    typeId: null
                };
            }

            return EndpointModel.create(
                {},
                service.filterObject(dataToInsert, institutionData)
            ).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }

                return data;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }

                return false;
            });
        };

        service.saveBasicDetails = function (institutionData) {
            var InstitutionModel = service.getInstitutionModel(),
                dataToUpdate = {
                    name: null,
                    localName: null,
                    typeId: null,
                    filemakerId: null,
                    businessSchool: null,
                    hasNoDepartments: null,
                    countryCode: null
                };
            if (service.isClientDepartment(institutionData.typeId)) {
                dataToUpdate = {
                    name: null,
                    localName: null,
                    typeId: null,
                    filemakerId: null,
                    businessSchool: null,
                    hasNoDepartments: null,
                    countryCode: null,
                    belongsTo: null
                };
            }

            return InstitutionModel.update(
                { id: institutionData.id },
                service.filterObject(dataToUpdate, institutionData)
            ).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }

                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }

                return false;
            });
        };

        service.saveDrupal = function (institutionData) {
            var InstitutionModel = service.getInstitutionModel(),
                dataToUpdate = {
                    drupalTmRegionId: null,
                    drupalTuRegionId: null
                };

            return InstitutionModel.update(
                { id: institutionData.id },
                service.filterObject(dataToUpdate, institutionData)
            ).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        service.saveSubscription = function (institutionData) {
            var InstitutionModel = service.getInstitutionModel(),
                dataToUpdate = {
                    linkedWithAdvancedPrograms: null,
                    subscriptions: {
                        tu: {
                            subscribed: null,
                            advanced: null,
                            startDate: null,
                            endDate: null,
                            isBasicProgramsEnabled : null,
                            crimsonBasic : null,
                            crimsonAdvance : null
                        },
                        tm: {
                            subscribed: null,
                            advanced: null,
                            startDate: null,
                            endDate: null
                        },
                        all: {
                            subscribed: true
                        },
                        esg: {
                            subscribed: null
                        },
                        aw: {
                            subscribed: null
                        },
                        brics: {
                            subscribed: null
                        },
                        aur: {
                            subscribed: null
                        },
                        eeca: {
                            subscribed: null
                        },
                        wur: {
                            subscribed: null
                        },
                        ger: {
                            subscribed: null
                        },
                        lat: {
                            subscribed: null
                        },
                        stars: {
                            publish: null,
                            subscribed: null,
                            advanced: null,
                            startDate: null,
                            endDate: null,
                            edit: null,
                            version: null
                        },
                        accord: {
                            subscribed: null,
                            advanced: null,
                            startDate: null,
                            endDate: null
                        }
                    },
                    enabled: {
                        ug: null,
                        ugChina: null,
                        pg: null,
                        pgChina: null,
                        starsChina: null,
                        tm: null
                    }
                };

            return InstitutionModel.update(
                { id: institutionData.id },
                service.filterObjectForSubscriptions(dataToUpdate, institutionData)
            ).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        service.saveInstitutionGroup = function (institutionData) {
            var deferred = $q.defer(),
                institutionGroupsSaveModel = service.getInstitutionGroupsSaveModel();
            institutionGroupsSaveModel.update(
                { id: institutionData.id },
                institutionData.groupMembers
            ).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                deferred.resolve(true);
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                deferred.resolve(false);
            });

            return deferred.promise;
        };

        service.searchInstitutionsWithoutGroup = function (institutionId, searchPhrase) {

            if (service.deferred.searchInstitutionsWithoutGroup) {
                // cancel previous call
                service.deferred.searchInstitutionsWithoutGroup.resolve();
                if (service.institutionGroupsRequest) {
                    service.institutionGroupsRequest.$cancelRequest();
                }
            }
            service.deferred.searchInstitutionsWithoutGroup = $q.defer();

            var deferred = service.deferred.searchInstitutionsWithoutGroup,
                institutionsWithoutGroup = [];

            if (!searchPhrase || searchPhrase.length < 1) {
                deferred.resolve(institutionsWithoutGroup);
            } else {
                var InstitutionGroupsModel = service.getInstitutionGroupsModel();
                service.institutionGroupsRequest = InstitutionGroupsModel.get(
                    { searchPhrase: searchPhrase },
                    function (data) {
                        if (constants.dev) {
                            $log.log('success, got data: ', data);
                        }
                        if (data && data.results) {
                            angular.forEach(data.results, function (item) {
                                if (item.coreId !== institutionId) {
                                    institutionsWithoutGroup.push({
                                        coreId: item.coreId,
                                        name: item.name
                                    });
                                }
                            });
                        }
                        deferred.resolve(institutionsWithoutGroup);
                        deferred = null;
                        service.institutionGroupsRequest = null;
                    },
                    function (error) {
                        //@todo log error
                        if (constants.dev) {
                            $log.log('request failed' + error);
                        }
                        deferred.resolve(institutionsWithoutGroup);
                        deferred = null;
                        service.institutionGroupsRequest = null;
                    }
                );
            }

            return deferred.promise;
        };

        service.getCountryFilterData = function () {
            var deferred = $q.defer();
            service.getCountries().then(function (countries) {
                var countriesFilterData = [
                ];
                if (countries) {
                    angular.forEach(countries, function (data) {
                        countriesFilterData.push({
                            id: data.countryCode,
                            title: data.name
                        });
                    });
                }
                deferred.resolve(countriesFilterData);
            });

            return deferred.promise;
        };

        /**
         * List of countries, ordered by name, ascending
         *
         * @returns {Promise.<Array>}
         */
        service.getCountries = function () {
            // process already running
            if (!service.deferred.getCountries) {
                service.deferred.getCountries = $q.defer();
            } else {
                return service.deferred.getCountries.promise;
            }
            var deferred = service.deferred.getCountries;

            if (service.countries) {
                deferred.resolve(service.countries);
            } else {
                var Api = $resource(constants.api.institutions.url + '/v1/list/countries?sorting[name]=asc');
                Api.get(function (data) {
                    if (constants.dev) {
                        $log.log('success, got data: ', data);
                    }
                    service.countries = data.results;
                    deferred.resolve(service.countries);
                    deferred = null;
                }, function (error) {
                    //@todo log error
                    if (constants.dev) {
                        $log.log('request failed' + error);
                    }
                    service.countries = [];
                    deferred.resolve(service.countries);
                    deferred = null;
                });
            }

            return deferred.promise;
        };

        /**
         * Saving a campus for organisation in institutions collection
         *
         * @param {string} organisationId
         * @param {object} campus
         * @returns {boolean}
         */
        service.saveCampus = function (organisationId, campus) {
            var CampusModel = service.getCampusModel(),
                dataToUpdate = {
                    name: null,
                    addressLine1: null,
                    addressLine2: null,
                    postcode: null,
                    city: null,
                    state: null,
                    displayInFrontEnd: null,
                    country: null,
                    primary: null,
                    region: null,
                    latitude: null,
                    longitude: null,
                    autoGenerate: null,
                    primaryInPrograms: null
                };

            if (angular.isDefined(campus.id)) {
                return CampusModel.update(
                    { id: organisationId, campusId: campus.id },
                    service.filterObject(dataToUpdate, campus)
                ).$promise.then(function (data) {
                    if (constants.dev) {
                        $log.log('success, got data: ', data);
                    }
                    return data;
                }, function (error) {
                    //@todo log error
                    if (constants.dev) {
                        $log.log('request failed' + error);
                    }
                    return false;
                });
            } else {
                return CampusModel.create(
                    { id: organisationId },
                    service.filterObject(dataToUpdate, campus)
                ).$promise.then(function (data) {
                    if (constants.dev) {
                        $log.log('success, got data: ', data);
                    }
                    if (angular.isDefined(data.createdCount) && data.createdCount && angular.isDefined(data.insertedId)) {
                        return data;
                    }

                    return false;
                }, function (error) {
                    //@todo log error
                    if (constants.dev) {
                        $log.log('request failed' + error);
                    }
                    return false;
                });
            }
        };

        service.deleteCampuses = function (institutionId, campusIds) {
            var CampusModel = service.getDeleteCampusesModel();

            return CampusModel.save({
                id: institutionId
            }, campusIds).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });

        };

        /**
         * Returns country name by country code (UK=United Kingdom)
         *
         * @param {string} countryCode
         * @returns {Promise.<String>}
         */
        service.getCountryNameByCode = function (countryCode) {
            var deferred = $q.defer(),
                countryName;

            service.getCountries().then(function (countries) {
                angular.forEach(countries, function (country) {
                    if (country.countryCode === countryCode) {
                        countryName = country.name;
                    }
                });
                deferred.resolve(countryName);
            });

            return deferred.promise;
        };

        /**
         * Returns joined campus address
         *
         * @param {Object} campus
         * @returns {string}
         */
        service.getCampusAddress = function (campus) {
            campus.addressLine = [];
            if (angular.isDefined(campus.addressLine1) && campus.addressLine1 !== null) {
                campus.addressLine.push(campus.addressLine1);
            }
            if (angular.isDefined(campus.addressLine2) && campus.addressLine2 !== null) {
                campus.addressLine.push(campus.addressLine2);
            }

            return campus.addressLine.join(', ');
        };

        /**
         * Returns user friendly yes/no instead of true/false for campus.primary field
         *
         * @param {Object} campus
         * @returns {string}
         */
        service.getCampusIsPrimary = function (campus) {
            return campus.primary ? 'Yes' : 'No';
        };

        /**
         * Returns institution
         *
         * @param {string} id
         * @returns {Promise}
         */
        service.getInstitution = function (id) {
            // process already running
            if (!service.deferred.getInstitution) {
                service.deferred.getInstitution = $q.defer();
            } else {
                return service.deferred.getInstitution.promise;
            }
            var deferred = service.deferred.getInstitution;

            // check if data exists in cache
            if (service.institution) {
                deferred.resolve(service.institution);
            } else {
                var Api = $resource(constants.api.institutions.url + '/v1/list/all?filter[coreId]=:id&limit=1', { id: id });
                Api.get(function (data) {
                    if (constants.dev) {
                        $log.log('success, got data: ', data);
                    }
                    service.institution = service.formatList(data.results)[0];
                    deferred.resolve(service.institution);
                    deferred = null;
                }, function (error) {
                    //@todo log error
                    if (constants.dev) {
                        $log.log('request failed' + error);
                    }
                    service.institution = [];
                    deferred.resolve(service.institution);
                    deferred = null;
                });
            }

            return deferred.promise;
        };

        /**
         * Delete campus
         *
         * @param {string} organisationId
         * @param {object} campus
         * @returns {Promise}
         */
        service.deleteCampus = function (organisationId, campus) {
            var CampusModel = service.getCampusModel();
            if (angular.isDefined(campus.id)) {
                return CampusModel.delete(
                    { id: organisationId, campusId: campus.id }
                ).$promise.then(function (data) {
                    if (constants.dev) {
                        $log.log('success, got data: ', data);
                    }
                    return true;
                }, function (error) {
                    //@todo log error
                    if (constants.dev) {
                        $log.log('request failed' + error);
                    }
                    return false;
                });
            }
        };

        /**
         * Save campuses order
         *
         * @param organisationId
         * @param campuses
         * @returns {*}
         */
        service.saveOrder = function (organisationId, campuses) {
            var institutionModel = service.getCampusesOrderingModel();
            return institutionModel.update(
                { id: organisationId },
                {
                    'campus': service.formatForOrdering(campuses)
                }
            ).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        /**
         * Format campuses before sending API request to save order
         *
         * @param {Object} items
         * @returns {Array}
         */
        service.formatForOrdering = function (items) {
            var formattedItems = [];
            angular.forEach(items, function (item) {
                formattedItems.push({
                    'id': item.id,
                    'order': item.order
                });
            });

            return formattedItems;
        };

        service.excludeGroupMember = function (institutionCoreId, groupMembers) {
            var groupMembersClone = angular.copy(groupMembers);
            for (var i = 0; i < groupMembersClone.length; i++) {
                if (parseInt(groupMembersClone[i].coreId, 10) === parseInt(institutionCoreId, 10)) {
                    groupMembersClone.splice(i, 1);
                    break;
                }
            }

            return groupMembersClone;
        };

        /**
         * Get institutions datagrid columns visibility.
         *
         * @returns {Object|*}
         */
        service.getColumnsVisibility = function () {
            return $localStorage.institutionGridColumnsVisibility;
        };

        /**
         * Copy campuses from institution to currently logged institution.
         *
         * @param {Object} institution
         */
        service.syncCampuses = function (institution) {
            var currentCoreId = institution.coreId;
            if (currentCoreId) {
                var activeCoreId = InstitutionFactory.getCoreId();
                if (activeCoreId && currentCoreId === activeCoreId) {
                    InstitutionFactory.setCampuses(angular.copy(institution.campus));
                }
                InstitutionsListFactory.setCampuses(angular.copy(institution.campus));
            }
        };

        /**
         * Search for institution.
         *
         * @param {String} searchPhrase
         * @returns {Promise.array}
         */
        service.searchInstitutions = function (searchPhrase) {
            var deferred = $q.defer(),
                institutions = [];

            if (!searchPhrase || !searchPhrase.length) {
                deferred.resolve(institutions);
            } else {
                var InstitutionsSearchModel = service.getInstitutionSearchModel();
                InstitutionsSearchModel.get(
                    { searchPhrase: searchPhrase },
                    function (data) {
                        if (constants.dev) {
                            $log.log('success, got data: ', data);
                        }

                        if (data && data.results) {
                            angular.forEach(data.results, function (item) {
                                institutions.push({
                                    coreId: item.coreId,
                                    name: item.name,
                                    active: item.active,
                                });
                            });
                        }

                        deferred.resolve(institutions);
                        deferred = null;
                    },
                    function (error) {
                        //@todo log error
                        if (constants.dev) {
                            $log.log('request failed' + error);
                        }
                        deferred.resolve(institutions);
                        deferred = null;
                    }
                );
            }

            return deferred.promise;
        };

        /**
         * Gets the belongs to list
         *
         * @returns {array}
         */
        service.getBelongsToList = function () {
            var list = TuProfileService.getBelongsToList();
            //change in institutions list only
            for (var i=0; i<list.length; i++) {
                if (list[i].title === 'Undergraduate') {
                    list[i].title = 'Bachelors';
                } else if (list[i].title === 'Postgraduate') {
                    list[i].title = 'Masters';
                }
            }

            return list;
        };

        /**
         * Search for institution.
         *
         * @param {String} searchPhrase
         * @returns {Promise.array}
         */
        service.searchTopLevelInstitutions = function (searchPhrase) {
            var deferred = $q.defer();

            if (!searchPhrase || searchPhrase.length < 1) {
                deferred.resolve([]);
            } else {
                var InstitutionsSearchModel = service.getTopLevelInstitutionSearchModel();
                InstitutionsSearchModel.get(
                    { searchPhrase: searchPhrase },
                    function (data) {
                        if (constants.dev) {
                            $log.log('success, got data: ', data);
                        }
                        deferred.resolve(data.results);
                        deferred = null;
                    },
                    function (error) {
                        //@todo log error
                        if (constants.dev) {
                            $log.log('request failed' + error);
                        }
                        deferred.resolve([]);
                        deferred = null;
                    }
                );
            }

            return deferred.promise;
        };

        /**
         * Search for institution departments.
         *
         * @param {int} parentCoreId
         * @returns {Promise.array}
         */
        service.getDepartments = function (parentCoreId) {
            var deferred = $q.defer();

            if (!parentCoreId) {
                deferred.resolve([]);
            } else {
                var InstitutionsSearchModel = service.getDepartmentsListModel();
                InstitutionsSearchModel.get(
                    { parentCoreId: parentCoreId },
                    function (data) {
                        if (constants.dev) {
                            $log.log('success, got data: ', data);
                        }
                        deferred.resolve(data.results);
                        deferred = null;
                    },
                    function (error) {
                        //@todo log error
                        if (constants.dev) {
                            $log.log('request failed' + error);
                        }
                        deferred.resolve([]);
                        deferred = null;
                    }
                );
            }

            return deferred.promise;
        };

        service.isAllStatistic = function (handle) {
            return handle === 'all';
        };

        service.formatCsvResults = function (data) {
            data = angular.copy(data);
            angular.forEach(data, function (column, dataKey) {
                angular.forEach(column, function (item, key) {
                    if(key === 'countryCode') {
                        data[dataKey][key] = service.getCountryName(item);
                    } else if(key === 'active') {
                         data[dataKey][key] = item ? 'Yes' : 'No';
                    } else if(key === 'typeId') {
                        data[dataKey][key] = getTypeById(item);
                    }  else if(key === 'enabledTm' ||
                        key === 'enabledPg' ||
                        key === 'enabledUg' ||
                        key === 'tmAdvanced' ||
                        key === 'tuAdvanced'||
                        key === 'hasNoDepartments'
                    ) {
                        data[dataKey][key] = item ? 'Yes' : 'No';
                    }
                });
            });

            return data;
        };

        service.getCountryName = function (institutionCountryCode) {
            var list = InstitutionsListFactory.getCountries(),
                total = list.length,
                i = 0;
            for (i; i < total; i++) {
                if (list[i].value === institutionCountryCode) {
                    return list[i].label;
                }
            }
            return null;
        };

        function getTypeById (typeId) {
            var list = InstitutionsListFactory.getTypes(),
                total = list.length,
                i = 0;
            for (i; i < total; i++) {
                if (list[i].uniqueId === typeId) {
                    return list[i].name;
                }
            }
            return null;
        }

        return service;
    };

    angular
        .module('qsHub')
        .service('InstitutionsListService', [
            '$resource',
            '$q',
            '$log',
            '$localStorage',
            'constants',
            'RequestsQueueService',
            'InstitutionFactory',
            'InstitutionsListFactory',
            'TuProfileService',
            'TimeService',
            App.services.InstitutionsList
        ]);

} (window.angular));

(function (angular, moment) {
    "use strict";
  
    angular
        .module('qsHub')
        .controller('InstitutionsSubscriptionsController', [
            '$scope',
            'constants',
            'InstitutionsSubscriptionsService',
            'InstitutionsListFactory',
            'InstitutionFactory',
            'WatchService',
            'InstitutionsListSubscriptionsFactory',
            'InstitutionsListSubscriptionsHistoryLogFactory',
            'UserFactory',
            Controller
        ]);
  
    function Controller(
        $scope,
        constants,
        InstitutionsSubscriptionsService,
        InstitutionsListFactory,
        InstitutionFactory,
        WatchService,
        InstitutionsListSubscriptionsFactory,
        InstitutionsListSubscriptionsHistoryLogFactory,
        UserFactory
    ) {
        var controller = this;
        controller.isLogVisible = {};
        controller.subscriptionsLog = {};
        controller.subscriptionsLogLoading = {};
        controller.downgradeCheckInProgress = false;
        controller.maxDate = null;
        controller.isLoadMoreTuSubscriptionsLogsInProgress = false;
        controller.isLoadMoreTmSubscriptionsLogsInProgress = false;
        controller.types = {
            tu: 'tu',
            tm: 'tm',
            stars: 'stars',
            accord : 'accord'
        };
        controller.starsVersions = null;
  
        controller.getCurrentDate = function () {
            return moment().format('x');
        };
  
        controller.initDowngradeAllowed = function () {
            controller.downgradeAllowed = {
                'tu': true,
                'tm': true
            };
        };
  
        controller.isStarsAnalyst = function () {
            return UserFactory.isStarsAnalyst();
        };
  
        controller.allowStarsChanges = function () {
            return UserFactory.isStarsAdmin();
        };
  
        controller.initDowngradeAllowed();
  
        controller.handleHistoryLogClick = function (type) {
            if (!controller.isHistoryLogVisible(type)) {
                // load subscriptions log
                controller.loadSubscriptions(type, true);
            }
            controller.toggleSubscriptionsLog(type);
            InstitutionsListSubscriptionsHistoryLogFactory.setInstitutionType(type);
        };
  
        /**
         * loadSubscriptions for TU and TM
         * @param  string type
         * @param  bool logClicked
         * @return array
         */
        controller.loadSubscriptions = function (type, logClicked) {
            controller.subscriptionsLogLoading[type] = true;
            InstitutionsSubscriptionsService.getLog(
                $scope.institution.id,
                type,
                logClicked ? constants.subscriptions.logsPerPage : InstitutionsListSubscriptionsHistoryLogFactory.getTuLogLimit()).then(
                function (response) {
                    controller.subscriptionsLogLoading[type] = false;
                    controller.subscriptionsLog[type] = response.results;
                    if (type === controller.types.tu) {
                        InstitutionsListSubscriptionsHistoryLogFactory.setTuLogs(response);
                    } else if (type === controller.types.stars) {
                        InstitutionsListSubscriptionsHistoryLogFactory.setStarsLogs(response);
                    } else if (type === controller.types.accord) {
                        InstitutionsListSubscriptionsHistoryLogFactory.setAccordLogs(response);
                    } else {
                        InstitutionsListSubscriptionsHistoryLogFactory.setTmLogs(response);
                    }
                });
        };
  
        controller.getHistoryLogsDataWatch = function (data) {
            controller.tuLogs = data;
        };
  
        controller.isMoreLogsAvailable = function() {
            if (controller.tuLogs && controller.tuLogs.totalMatching) {
                return InstitutionsListSubscriptionsHistoryLogFactory.getTuLogLimit() < controller.tuLogs.totalMatching;
            }
            return false;
        };
  
        controller.isTuHistoryLogsLoadMoreInProgressWatch = function (isInProgress) {
            controller.isLoadMoreTuSubscriptionsLogsInProgress = isInProgress;
        };
  
        controller.handleLoadMoreHistoryLogs = function() {
            InstitutionsListSubscriptionsHistoryLogFactory.tuLoadMore();
        };
  
        controller.isTuLoadMoreRequestWatch = function (tuLoadMore) {
            if (tuLoadMore) {
                InstitutionsListSubscriptionsHistoryLogFactory.tuLoadMore(false);
                InstitutionsListSubscriptionsHistoryLogFactory.setTuLoadMoreInProgress(false);
                var limit = InstitutionsListSubscriptionsHistoryLogFactory.getTuLogLimit() + constants.subscriptions.logsPerPage,
                    type = InstitutionsListSubscriptionsHistoryLogFactory.getInstitutionType();
  
                InstitutionsSubscriptionsService.getLog($scope.institution.id, type, limit).then(function (response) {
                    controller.subscriptionsLogLoading[type] = false;
                    controller.subscriptionsLog[type] = response.results;
                    InstitutionsListSubscriptionsHistoryLogFactory.setTuLogs(response);
                    InstitutionsListSubscriptionsHistoryLogFactory.setTuLoadMoreInProgress(false);
                    InstitutionsListSubscriptionsHistoryLogFactory.setTuLogLimit(limit);
                });
            }
        };
  
        controller.getTmHistoryLogsDataWatch = function (data) {
            controller.tmLogs = data;
        };
  
        controller.isMoreTmLogsAvailable = function() {
            if(controller.tmLogs && controller.tmLogs.totalMatching) {
                return InstitutionsListSubscriptionsHistoryLogFactory.getTmLogLimit() < controller.tmLogs.totalMatching;
            }
            return false;
        };
  
        controller.getStarsHistoryLogsDataWatch = function (data) {
            controller.starsLogs = data;
        };
  
        controller.isMoreStarsLogsAvailable = function() {
            if(controller.starsLogs && controller.starsLogs.totalMatching) {
                return InstitutionsListSubscriptionsHistoryLogFactory.getStarsLogLimit() < controller.starsLogs.totalMatching;
            }
            return false;
        };
  
        controller.getAccordHistoryLogsDataWatch = function (data) {
            controller.accordLogs = data;
        };
  
        controller.isMoreAccordLogsAvailable = function() {
            if(controller.accordLogs && controller.accordLogs.totalMatching) {
                return InstitutionsListSubscriptionsHistoryLogFactory.getAccordLogLimit() < controller.accordLogs.totalMatching;
            }
            return false;
        };
  
        controller.isTmHistoryLogsLoadMoreInProgressWatch = function (isInProgress) {
            controller.isLoadMoreTmSubscriptionsLogsInProgress = isInProgress;
        };
  
        controller.isStarsHistoryLogsLoadMoreInProgressWatch = function (isInProgress) {
            controller.isLoadMoreStarsSubscriptionsLogsInProgress = isInProgress;
        };
  
        controller.isAccordHistoryLogsLoadMoreInProgressWatch = function (isInProgress) {
            controller.isLoadMoreAccordSubscriptionsLogsInProgress = isInProgress;
        };
  
        controller.handleLoadMoreTmHistoryLogs = function() {
            InstitutionsListSubscriptionsHistoryLogFactory.tmLoadMore();
        };
  
        controller.handleLoadMoreStarsHistoryLogs = function() {
            InstitutionsListSubscriptionsHistoryLogFactory.starsLoadMore();
        };
  
        controller.handleLoadMoreAccordHistoryLogs = function() {
            InstitutionsListSubscriptionsHistoryLogFactory.accordLoadMore();
        };
  
        controller.isTmLoadMoreRequestWatch = function (tmLoadMore) {
            if (tmLoadMore) {
                InstitutionsListSubscriptionsHistoryLogFactory.tmLoadMore(false);
                InstitutionsListSubscriptionsHistoryLogFactory.setTmLoadMoreInProgress(false);
                var limit = InstitutionsListSubscriptionsHistoryLogFactory.getTmLogLimit() + constants.subscriptions.logsPerPage,
                    type = InstitutionsListSubscriptionsHistoryLogFactory.getInstitutionType();
  
                InstitutionsSubscriptionsService.getLog($scope.institution.id, type, limit).then(function (response) {
                    controller.subscriptionsLogLoading[type] = false;
                    controller.subscriptionsLog[type] = response.results;
                    InstitutionsListSubscriptionsHistoryLogFactory.setTmLogs(response);
                    InstitutionsListSubscriptionsHistoryLogFactory.setTmLoadMoreInProgress(false);
                    InstitutionsListSubscriptionsHistoryLogFactory.setTmLogLimit(limit);
                });
            }
        };
  
        controller.isStarsLoadMoreRequestWatch = function (starsLoadMore) {
            if (starsLoadMore) {
                InstitutionsListSubscriptionsHistoryLogFactory.starsLoadMore(false);
                InstitutionsListSubscriptionsHistoryLogFactory.setStarsLoadMoreInProgress(false);
                var limit = InstitutionsListSubscriptionsHistoryLogFactory.getStarsLogLimit() + constants.subscriptions.logsPerPage,
                    type = InstitutionsListSubscriptionsHistoryLogFactory.getInstitutionType();
  
                InstitutionsSubscriptionsService.getLog($scope.institution.id, type, limit).then(function (response) {
                    controller.subscriptionsLogLoading[type] = false;
                    controller.subscriptionsLog[type] = response.results;
                    InstitutionsListSubscriptionsHistoryLogFactory.setStarsLogs(response);
                    InstitutionsListSubscriptionsHistoryLogFactory.setStarsLoadMoreInProgress(false);
                    InstitutionsListSubscriptionsHistoryLogFactory.setStarsLogLimit(limit);
                });
            }
        };
  
        controller.isAccordLoadMoreRequestWatch = function (accordLoadMore) {
            if (accordLoadMore) {
                InstitutionsListSubscriptionsHistoryLogFactory.accordLoadMore(false);
                InstitutionsListSubscriptionsHistoryLogFactory.setAccordLoadMoreInProgress(false);
                var limit = InstitutionsListSubscriptionsHistoryLogFactory.getAccordLogLimit() + constants.subscriptions.logsPerPage,
                    type = InstitutionsListSubscriptionsHistoryLogFactory.getInstitutionType();
  
                InstitutionsSubscriptionsService.getLog($scope.institution.id, type, limit).then(function (response) {
                    controller.subscriptionsLogLoading[type] = false;
                    controller.subscriptionsLog[type] = response.results;
                    InstitutionsListSubscriptionsHistoryLogFactory.setAccordLogs(response);
                    InstitutionsListSubscriptionsHistoryLogFactory.setAccordLoadMoreInProgress(false);
                    InstitutionsListSubscriptionsHistoryLogFactory.setAccordLogLimit(limit);
                });
            }
        };
  
  
        controller.toggleSubscriptionsLog = function (type) {
            controller.isLogVisible[type] = !controller.isLogVisible[type];
            if (type === controller.types.tu && !controller.isLogVisible[type]) {
                InstitutionsListSubscriptionsHistoryLogFactory.setTuLogLimit(constants.subscriptions.logsPerPage);
            } else if (type === controller.types.tm && !controller.isLogVisible[type]) {
                InstitutionsListSubscriptionsHistoryLogFactory.setTmLogLimit(constants.subscriptions.logsPerPage);
            } else if (type === controller.types.stars && !controller.isLogVisible[type]) {
                InstitutionsListSubscriptionsHistoryLogFactory.setStarsLogLimit(constants.subscriptions.logsPerPage);
            } else if (type === controller.types.accord && !controller.isLogVisible[type]) {
                InstitutionsListSubscriptionsHistoryLogFactory.setAccordLogLimit(constants.subscriptions.logsPerPage);
            }
        };
  
        controller.isHistoryLogVisible = function (type) {
            return controller.isLogVisible[type] || false;
        };
  
        controller.isSubscriptionsLogLoading = function (type) {
            return controller.subscriptionsLogLoading[type] || false;
        };
  
        controller.getSubscriptionsLog = function (type) {
            return controller.subscriptionsLog[type] || [];
        };
  
        /**
         * Check if should display loading screen for subscriptions tab - profiles side
         *
         * @returns {boolean}
         */
        controller.isProfilesLoading = function () {
            return controller.downgradeCheckInProgress;
        };
  
        /**
         * Gets whether TU/TM sliders has to be disabled in subscription tab
         * Triggers only for top level institutions and client departments
         */
        controller.isDowngradeAllowed = function () {
            var coreIdKey = 'coreId',
                typeIdKey = 'typeId';
            // reset check child data
            controller.initDowngradeAllowed();
            if ($scope.institution &&
                $scope.institution.hasOwnProperty(coreIdKey) &&
                $scope.institution[coreIdKey] !== null &&
                angular.isNumber($scope.institution[coreIdKey]) &&
                $scope.institution[coreIdKey] > 0 &&
                $scope.institution.hasOwnProperty(typeIdKey) &&
                $scope.institution[typeIdKey] !== null &&
                (
                    $scope.institution[typeIdKey] === constants.typeId.topLevelInstitutionId ||
                    $scope.institution[typeIdKey] === constants.typeId.clientDepartmentId
                )
            ) {
                controller.downgradeCheckInProgress = true;
                InstitutionsSubscriptionsService.isDowngradeAllowed($scope.institution[coreIdKey]).then(function (results) {
                    controller.downgradeAllowed = results;
                    controller.downgradeCheckInProgress = false;
                });
            }
        };
  
        /**
         * Is TU downgrade allowed on UI
         *
         * @returns {boolean}
         */
        controller.isDowngradeAllowedTu = function (isSubscribed) {
            // if the institution is not subscribed, allow downgrade.
            if (typeof isSubscribed !== 'undefined' && isSubscribed !== null && isSubscribed) {
                return controller.downgradeAllowed.tu && isSubscribed;
            }
  
            return true;
        };
  
        /**
         * Is TM downgrade allowed on UI
         *
         * @returns {boolean}
         */
        controller.isDowngradeAllowedTm = function (isSubscribed) {
            // if the institution is not subscribed, allow downgrade.
            if (typeof isSubscribed !== 'undefined' && isSubscribed !== null && isSubscribed) {
                return controller.downgradeAllowed.tm && isSubscribed;
            }
  
            return true;
        };
  
        controller.handleInstitutionChange = function () {
            checkRegionalRankingSubscription();
            controller.isDowngradeAllowed();
            controller.isLogVisible = {};
        };
  
        controller.maxDateWatch = function (value) {
            if (value) {
                controller.maxDate = value;
                if ($scope.institution.subscriptions &&
                    $scope.institution.subscriptions.tm &&
                    $scope.institution.subscriptions.tm.endDate
                ) {
                    $scope.institution.subscriptions.tm.endDate = value;
                }
            }
        };
  
        controller.subscriptionsWatch = function (value) {
            if (value &&
                $scope.institution.subscriptions &&
                $scope.institution.subscriptions.tm &&
                $scope.institution.subscriptions.tm.endDate
            ) {
                var currentDate = parseInt($scope.institution.subscriptions.tm.endDate);
                if (currentDate < controller.maxDate) {
                    $scope.institution.subscriptions.tm.endDate = controller.maxDate;
                }
            }
        };

        function checkRegionalRankingSubscription() {
            if ($scope.institution && $scope.institution.subscriptions) {
                if (!$scope.institution.subscriptions['wur']) {
                    $scope.institution.subscriptions['wur'] = {};
                }
                
                if ( ($scope.institution.subscriptions['aur'] && $scope.institution.subscriptions['aur'].subscribed) || 
                    ($scope.institution.subscriptions['lat'] && $scope.institution.subscriptions['lat'].subscribed) || 
                    ($scope.institution.subscriptions['eeca'] && $scope.institution.subscriptions['eeca'].subscribed) || 
                    ($scope.institution.subscriptions['aw'] && $scope.institution.subscriptions['aw'].subscribed) ) 
                     {
                        $scope.institution.subscriptions['wur'].disabled = true;
                        if (!$scope.institution.subscriptions['wur'].subscribed) {
                            $scope.institution.subscriptions['wur'].subscribed = true;
                        }
                }
                else {
                    $scope.institution.subscriptions['wur'].disabled = false;
                }
            }
        }
  
        controller.handleSubscribed = function (handle, subscribed) {
            if (handle === 'stars' && subscribed) {
                $scope.institution.subscriptions.stars.publish = true;
            }

            checkRegionalRankingSubscription();
        };

        /**
         * Allow to assign TU subscriptions.
         *
         * @returns {boolean}
         */
        controller.allowTu = function () {
            return !!($scope.institution && $scope.institution.typeId !== constants.typeId.advancedProgramId);
        };
  
        controller.hasSubscriptionsChangesWatch = function (has) {
            if (has) {
                InstitutionsListSubscriptionsFactory.setHasSubscriptionsChanges(false);
                controller.handleInstitutionChange();
            }
        };
  
        /**
         * map version
         * @param version int|float
         * return {string}
         */
        controller.mapVersion = function(version) {
            return InstitutionsSubscriptionsService.mapStarsVersions(version);
        };
  
        controller.initWatches = function () {
            WatchService.create($scope, 'institution.id', controller.handleInstitutionChange);
            WatchService.create($scope, InstitutionsListFactory.isInvalidDates, controller.maxDateWatch);
            WatchService.create($scope, InstitutionFactory.getSubscriptions, controller.subscriptionsWatch);
            WatchService.create($scope, InstitutionsListSubscriptionsFactory.hasSubscriptionsChanges, controller.hasSubscriptionsChangesWatch);
  
            // watch for tu subscriptions history logs data changes
            WatchService.create($scope, InstitutionsListSubscriptionsHistoryLogFactory.getTuLogs, controller.getHistoryLogsDataWatch);
            // watch for tu subscriptions history logs loading progress
            WatchService.create($scope, InstitutionsListSubscriptionsHistoryLogFactory.isTuLoadMoreInProgress, controller.isTuHistoryLogsLoadMoreInProgressWatch);
            // watch for tu subscriptions load more request
            WatchService.create($scope, InstitutionsListSubscriptionsHistoryLogFactory.isTuLoadMoreRequest, controller.isTuLoadMoreRequestWatch);
  
            // watch for tm subscriptions history logs data changes
            WatchService.create($scope, InstitutionsListSubscriptionsHistoryLogFactory.getTmLogs, controller.getTmHistoryLogsDataWatch);
            // watch for tm subscriptions history logs loading progress
            WatchService.create($scope, InstitutionsListSubscriptionsHistoryLogFactory.isTmLoadMoreInProgress, controller.isTmHistoryLogsLoadMoreInProgressWatch);
            // watch for tm subscriptions load more request
            WatchService.create($scope, InstitutionsListSubscriptionsHistoryLogFactory.isTmLoadMoreRequest, controller.isTmLoadMoreRequestWatch);
  
            // watch for qs stars subscriptions history logs data changes
            WatchService.create($scope, InstitutionsListSubscriptionsHistoryLogFactory.getStarsLogs, controller.getStarsHistoryLogsDataWatch);
            // watch for qs stars subscriptions history logs loading progress
            WatchService.create($scope, InstitutionsListSubscriptionsHistoryLogFactory.isStarsLoadMoreInProgress, controller.isStarsHistoryLogsLoadMoreInProgressWatch);
            // watch for qs stars subscriptions load more request
            WatchService.create($scope, InstitutionsListSubscriptionsHistoryLogFactory.isStarsLoadMoreRequest, controller.isStarsLoadMoreRequestWatch);
  
            // watch for accord subscriptions history logs data changes
            WatchService.create($scope, InstitutionsListSubscriptionsHistoryLogFactory.getAccordLogs, controller.getAccordHistoryLogsDataWatch);
            // watch for accord subscriptions history logs loading progress
            WatchService.create($scope, InstitutionsListSubscriptionsHistoryLogFactory.isAccordLoadMoreInProgress, controller.isAccordHistoryLogsLoadMoreInProgressWatch);
            // watch for accord subscriptions load more request
            WatchService.create($scope, InstitutionsListSubscriptionsHistoryLogFactory.isAccordLoadMoreRequest, controller.isAccordLoadMoreRequestWatch);
  
        };
  
        controller.init = function () {
            InstitutionsSubscriptionsService.getStarsVersions().then(function (starsVersions) {
                controller.starsVersions = starsVersions;
            });
            controller.initWatches();
        };
  
        // listen to subscriptions tab visibility changes
        var cancelSubscriptionTabWatch = WatchService.create(
            $scope,
            InstitutionsListFactory.isSubscriptionsTabSelected,
            function (selected) {
                if (selected) {
                    cancelSubscriptionTabWatch();
                    controller.init();
                }
            });
    }
  
  } (window.angular, window.moment));
  
(function(angular, moment) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    App.services.institutionsSubscriptions = function (
        $resource,
        $log,
        $q,
        constants
    ) {
        var service = {};

        service.getModel = function () {
            return $resource(constants.api.institutions.url + '/v1/subscription-history/:id?field[]=startDate&field[]=endDate&field[]=createdByFullName&field[]=id&sorting[createdAt]=desc&field[]=createdByFullName&field[]=createdAt&field[]=version', null, {
                update: {method: 'PATCH'}
            });
        };

        service.getDowngradeAllowedModel = function () {
            return $resource(constants.api.institutions.url + '/v1/institution/core-id/:id/allow-downgrade');
        };

        service.filterByDate = function (subscritpions) {
            var filtered = [];
            angular.forEach(subscritpions.results, function(subscritpion) {
                if (subscritpion.startDate && subscritpion.endDate) {
                    filtered.push(subscritpion);
                }
            });
            subscritpions.results = filtered;
            return subscritpions;
        };

        service.getLog = function (institutionId, type, limit) {
            if (!institutionId || !type) {
                throw 'Missing Required';
            }
            if (constants.dev) {
                var startTime = new Date().getTime(), endTime;
            }
            limit = limit || constants.subscriptions.limit;
            return service.getModel().get({
                id: institutionId,
                'filter[type]' : '=' + type,
                limit: limit
            }).$promise.then(function (data) {
                if (constants.dev) {
                    endTime = new Date().getTime();
                    $log.log('success, got data: ', data, 'in ' + (endTime - startTime) + ' ms');
                }

                return data;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                } else {
                    return [];
                }
            }).then(service.filterByDate);
        };

        /**
         * Checks if TU/TM downgrade is allowed for institution core id
         *
         * @param institutionId
         * @returns {*}
         */
        service.isDowngradeAllowed = function (institutionId) {
            if (!institutionId) {
                throw 'Missing Required';
            }
            if (constants.dev) {
                var startTime = new Date().getTime(), endTime;
            }

            return service.getDowngradeAllowedModel().get({
                id: institutionId
            }).$promise.then(function (data) {
                if (constants.dev) {
                    endTime = new Date().getTime();
                    $log.log('success, got data: ', data, 'in ' + (endTime - startTime) + ' ms');
                }

                return data;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                } else {
                    return [];
                }
            });
        };

        service.getStarsVersions = function () {
            var deferred = $q.defer();
            deferred.resolve(
                [
                    {
                        value: 4.2,
                        label: 'v4.2'
                    },
                    {
                        value: 5.0,
                        label: 'v5.0'
                    }
                ]
            );

            return deferred.promise;
        };

        service.mapStarsVersions = function (version) {
            if (!!version) {
                return version === 4.2 ? 'v4.2' : version === 5 ? 'v5.0' : '';

            }
        };

        return service;
    };

    angular
        .module('qsHub')
        .service('InstitutionsSubscriptionsService', [
            '$resource',
            '$log',
            '$q',
            'constants',
            App.services.institutionsSubscriptions
        ]);

}(window.angular, window.moment));

(function(angular, moment) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {factories:{}});

    App.factories.institutionsListSubscriptions = function () {
        var hasSubscriptionsChanges;
        return {
            setHasSubscriptionsChanges: function (has) {
                hasSubscriptionsChanges = has;
            },
            hasSubscriptionsChanges: function() {
                return hasSubscriptionsChanges;
            }
        };
    };

    angular
        .module('qsHub')
        .factory('InstitutionsListSubscriptionsFactory', App.factories.institutionsListSubscriptions);

}(window.angular, window.moment));

(function(angular) {
    "use strict";
  
    var App = App || {};
    App = angular.extend({}, App, { factories: {} });
  
    App.factories.institutionsListSubscriptionsHistoryLog = function(constants) {
        var loadMoreInProgress = false,
            tmLoadMoreInProgress = false,
            starsLoadMoreInProgress = false,
            accordLoadMoreInProgress = false,
            tuLoadMore = false,
            tmLoadMore = false,
            starsLoadMore = false,
            accordLoadMore = false,
            tuLogs = [],
            tmLogs = [],
            starsLogs = [],
            accordLogs = [],
            type = "",
            tuLimit,
            tmLimit,
            starsLimit,
            accordLimit;
  
        return {
            setTuLogs: function (data) {
                tuLogs = data;
            },
            getTuLogs: function () {
                return tuLogs;
            },
            setTuLogLimit: function (logLimit) {
                tuLimit = logLimit;
            },
            getTuLogLimit: function () {
                return tuLimit || constants.subscriptions.limit;
            },
            isTuLoadMoreInProgress: function () {
                return loadMoreInProgress;
            },
            setTuLoadMoreInProgress: function (inProgress) {
                loadMoreInProgress = inProgress;
            },
            tuLoadMore: function (more) {
                tuLoadMore = typeof more === 'undefined' ? true : more;
            },
            isTuLoadMoreRequest: function () {
                return tuLoadMore;
            },
            setTmLogs: function (data) {
                tmLogs = data;
            },
            getTmLogs: function () {
                return tmLogs;
            },
            setStarsLogs: function (data) {
                starsLogs = data;
            },
            getStarsLogs: function () {
                return starsLogs;
            },
            setAccordLogs: function (data) {
                accordLogs = data;
            },
            getAccordLogs: function () {
                return accordLogs;
            },
            setTmLogLimit: function (logLimit) {
                tmLimit = logLimit;
            },
            getTmLogLimit: function () {
                return tmLimit || constants.subscriptions.limit;
            },
            setStarsLogLimit: function (logLimit) {
                starsLimit = logLimit;
            },
            getStarsLogLimit: function () {
                return starsLimit || constants.subscriptions.limit;
            },
            setAccordLogLimit: function (logLimit) {
                accordLimit = logLimit;
            },
            getAccordLogLimit: function () {
                return accordLimit || constants.subscriptions.limit;
            },
            isTmLoadMoreInProgress: function () {
                return tmLoadMoreInProgress;
            },
            setTmLoadMoreInProgress: function (inProgress) {
                tmLoadMoreInProgress = inProgress;
            },
            isStarsLoadMoreInProgress: function () {
                return starsLoadMoreInProgress;
            },
            setStarsLoadMoreInProgress: function (inProgress) {
                starsLoadMoreInProgress = inProgress;
            },
            isAccordLoadMoreInProgress: function () {
                return accordLoadMoreInProgress;
            },
            setAccordLoadMoreInProgress: function (inProgress) {
                accordLoadMoreInProgress = inProgress;
            },
            tmLoadMore: function (more) {
                tmLoadMore = typeof more === 'undefined' ? true : more;
            },
            isTmLoadMoreRequest: function () {
                return tmLoadMore;
            },
            starsLoadMore: function (more) {
                starsLoadMore = typeof more === 'undefined' ? true : more;
            },
            isStarsLoadMoreRequest: function () {
                return starsLoadMore;
            },
            accordLoadMore: function (more) {
                accordLoadMore = typeof more === 'undefined' ? true : more;
            },
            isAccordLoadMoreRequest: function () {
                return accordLoadMore;
            },
            setInstitutionType: function (institutionType) {
                type = institutionType;
            },
            getInstitutionType: function () {
                return type;
            }
        };
    };
  
    angular
        .module('qsHub')
        .factory('InstitutionsListSubscriptionsHistoryLogFactory', [
            'constants',
            App.factories.institutionsListSubscriptionsHistoryLog
        ]);
  
  }(window.angular));
  
(function (angular, moment) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { controllers: {} });

    App.controllers.institutionsListStatus = function (
        $scope,
        InstitutionsListFactory,
        InstitutionsListService,
        WatchService
    ) {
        var controller = this;

        controller.handleInstitutionChange = function (institution) {
            if (!institution) {
                return;
            }
            controller.institution = angular.copy(institution);
            controller.setType();
            controller.setCountry();
            controller.setBelongsTo();
            controller.setTuRegion();
            controller.setTmRegion();

            controller.isAdvancedProgram = InstitutionsListService.isAdvancedProgram(controller.institution.typeId);
        };

        controller.setType = function () {
            angular.forEach(controller.typesList, function (item) {
                if (item.value === controller.institution.typeId) {
                    controller.institution.type = item.label;
                }
            });
        };

        controller.setCountry = function () {
            angular.forEach(controller.countryList, function (item) {
                if (item.countryCode === controller.institution.countryCode) {
                    controller.institution.country = item.name;
                }
            });
        };

        controller.setBelongsTo = function () {
            angular.forEach(controller.belongsToList, function (item) {
                if (item.id === controller.institution.belongsTo) {
                    controller.institution.belongsToName = item.title;
                }
            });
        };

        controller.setTuRegion = function () {
            angular.forEach(controller.tuRegionsList, function (item) {
                if (item.tid === controller.institution.drupalTuRegionId) {
                    controller.institution.tuRegion = item.name;
                }
            });
        };

        controller.setTmRegion = function () {
            angular.forEach(controller.tmRegionsList, function (item) {
                if (item.tid === controller.institution.drupalTmRegionId) {
                    controller.institution.tmRegion = item.name;
                }
            });
        };

        controller.initLists = function () {
            controller.typesList = [];
            InstitutionsListService.getTypeFilterData(true).then(function (results) {
                controller.typesList = results;
                controller.setType();
            });
            controller.countryList = [];
            InstitutionsListService.getCountries().then(function (results) {
                controller.countryList = results;
                controller.setCountry();
            });
            controller.belongsToList = InstitutionsListService.getBelongsToList();
            InstitutionsListService.getTuRegions().then(function (list) {
                controller.tuRegionsList = list;
                controller.setTuRegion();
            });
            InstitutionsListService.getTmRegions().then(function (list) {
                controller.tmRegionsList = list;
                controller.setTmRegion();
            });
        };

        controller.initWatches = function () {
            WatchService.create($scope, 'institutionBeforeChanges', controller.handleInstitutionChange);
        };

        controller.init = function () {
            controller.initWatches();
            controller.initLists();
        };

        // listen to subscriptions tab visibility changes
        var cancelTabWatch = WatchService.create(
            $scope,
            InstitutionsListFactory.isStatusTabSelected,
            function (selected) {
                if (selected) {
                    cancelTabWatch();
                    controller.init();
                }
            }
        );
    };

    angular
        .module('qsHub')
        .controller('InstitutionsListStatusController', [
            '$scope',
            'InstitutionsListFactory',
            'InstitutionsListService',
            'WatchService',
            App.controllers.institutionsListStatus
        ]);

} (window.angular, window.moment));

(function(angular) {
    'use strict';

    var App = App || {};
    App = angular.extend({}, App, {factories:{}});

    App.factories.ExceptionHandler = function ($log, constants) {

        return function (exception, cause) {
            /* jshint ignore:start */
            if (constants.env === 'qa_prod' || constants.env === 'live') {
                Rollbar.error(exception);
                Rollbar.info(cause);
            }
            /* jshint ignore:end */
            $log.error(exception);
        };
    };

    angular
        .module('qsHub')
        .factory('$exceptionHandler', [
            '$log',
            'constants',
            App.factories.ExceptionHandler
        ]);

}(window.angular, window.airbrakeJs));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    App.services.Modal = function ($uibModal) {
        var service = {
            modalDefaults : {
                backdrop: true,
                keyboard: true,
                modalFade: true,
                templateUrl: '/scripts/shared/modal/modalView.html'
            },
            modalOptions : {
                closeButtonText: 'Close',
                actionButtonText: 'OK',
                actionButtonClass: 'danger',
                headerText: 'Proceed?',
                bodyText: 'Perform this action?'
            }
        }, modalInstance;

        service.show = function (customModalDefaults, customModalOptions) {
            if (!customModalDefaults) {
                customModalDefaults = {};
            }

            // create temp objects to work with since we're in a singleton service
            var tempModalDefaults = {},
                tempModalOptions = {};

            //Map angular-ui modal custom defaults to modal defaults defined in service
            angular.extend(tempModalDefaults, service.modalDefaults, customModalDefaults);

            //Map modal.html $scope custom properties to defaults defined in service
            angular.extend(tempModalOptions, service.modalOptions, customModalOptions);

            if (!tempModalDefaults.controller) {
                tempModalDefaults.controller = function ($scope, $uibModalInstance) {
                    $scope.modalOptions = tempModalOptions;
                    $scope.modalOptions.ok = function (result) {
                        $uibModalInstance.close(result);
                    };
                    $scope.modalOptions.close = function (result) {
                        $uibModalInstance.dismiss('cancel');
                    };
                };
            }
            modalInstance = $uibModal.open(tempModalDefaults);

            return modalInstance.result;
        };

        service.close = function () {
            modalInstance.close();
        };

        return service;
    };

    angular
        .module('qsHub')
        .service('ModalService', [
            '$uibModal',
            App.services.Modal
        ]);
}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    App.services.Watch = function () {
        var service = {};

        service.create = function ($scope, watchExpression, listener, objectEquality, prettyPrintExpression) {
            if (typeof $scope !== 'object' || typeof listener !== 'function') {
                return false;
            }
            var stopWatch = $scope.$watch(watchExpression, function (newValue, oldValue) {
                try {
                    listener(newValue, oldValue);
                } catch (e) {
                    stopWatch();
                    throw e;
                }
            }, objectEquality, prettyPrintExpression);

            return stopWatch;
        };

        service.groupWatch = function ($scope, watchExpression, listener, objectEquality, prettyPrintExpression) {
            if (typeof $scope !== 'object' || typeof listener !== 'function') {
                return false;
            }
            var stopWatch = $scope.$watchGroup(watchExpression, function (newValue, oldValue) {
                try {
                    listener(newValue, oldValue);
                } catch (e) {
                    stopWatch();
                    throw e;
                }
            }, objectEquality, prettyPrintExpression);

            return stopWatch;
        };

        return service;
    };

    angular
        .module('qsHub')
        .service('WatchService', [
            App.services.Watch
        ]);
}(window.angular));

(function(angular) {
    'use strict';

    var App = App || {};
    App = angular.extend({}, App, {directives: {}});

    App.directives.progressCircle = function () {
        return {
            scope: {
                progressCircle: '=',
                percentage: '='
            },
            link: function (scope) {
                scope.getParams = function (progressCircle) {
                    var usePercentage = false;
                    if (scope.percentage !== undefined && scope.percentage !== null) {
                        usePercentage = true;
                    }
                    switch (progressCircle) {
                        case 'pending':
                            scope.percent = usePercentage ? scope.percentage : 0;
                            scope.colour = 'grey';
                            scope.text = 'Pending';
                            break;
                        case 'progress':
                            scope.percent = usePercentage ? scope.percentage : 50;
                            scope.colour = 'blue';
                            scope.text = 'Progress';
                            break;
                        case 'success':
                            scope.percent = usePercentage ? scope.percentage : 100;
                            scope.colour = 'green';
                            scope.text = 'Success';
                            break;
                        case 'failure':
                            scope.percent = usePercentage ? scope.percentage : 100;
                            scope.colour = 'red';
                            scope.text = 'Failed';
                            break;
                        case 'cancelled':
                            scope.percent = 100;
                            scope.colour = 'orange';
                            scope.text = 'Cancelled';
                            break;
                        default:
                            scope.percent = usePercentage ? scope.percentage : 0;
                            scope.colour = '';
                            scope.text = 'No Status';
                            break;
                    }
                };
                scope.$watch('progressCircle', function (value, oldValue) {
                    if (!angular.equals(value, oldValue)) {
                        scope.getParams(value);
                    }
                });

                scope.$watch('percentage', function (value) {
                    if (value !== null && value !== undefined) {
                        scope.percent = value;
                    }
                });

                scope.getParams(scope.progressCircle);
            },
            restrict: 'A',
            templateUrl: '/scripts/shared/progressCircle/progressCircleView.html'
        };
    };

    angular
        .module('qsHub')
        .directive('progressCircle', App.directives.progressCircle);

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers:{}});

    App.controllers.sharedProfile = function (
        $scope,
        $state,
        $stateParams,
        constants, 
        SharedProfileService,
        SharedProfileFactory,
        InstitutionFactory,
        UserFactory,
        WatchService,
        WebSocketsService
    ) {
        var controller = this;
        controller.devMode = constants.dev;

        $scope.loadInProgress = true;
        $scope.generalHistoryDisabled = false;
        $scope.fetchingHistory = false;
        $scope.showGeneralHistoryBlock = false;
        SharedProfileFactory.setCampusFormVisibility(false);
        $scope.showCampusForm = SharedProfileFactory.isCampusFormVisible();
        controller.historyLog = {
            totalReturned: 0,
            total: 0
        };

        /**
         * Request history log from service.
         */
        controller.loadHistoryLog = function () {
            if (!SharedProfileFactory.getId()) {
                return;
            }
            $scope.fetchingHistory = false;
            $scope.generalHistoryDisabled = true;
            if (!SharedProfileFactory.isLoadMoreInProgress()) {
                $scope.fetchingHistory = true;
            }
            SharedProfileService.getGeneralHistory(SharedProfileFactory.getId(), true, SharedProfileFactory.getLogLimit()).then(function (data) {
                $scope.generalHistoryDisabled = false;
                $scope.fetchingHistory = false;
                $scope.showGeneralHistoryBlock = true;
                $scope.generalHistoryLog = data.results;
                controller.historyLog.total = data.totalMatching;
                controller.historyLog.totalReturned = data.totalFiltered;
                SharedProfileFactory.setLoadMoreInProgress(false);
            });
        };

        /**
         * Handle history log load more click.
         * @param {boolean} isLimit
         */
        controller.handleLoadMoreHistoryLog = function(isLimit) {
            var limit = angular.isDefined(isLimit) ? SharedProfileFactory.getLogLimit() : SharedProfileFactory.getLogLimit() + 5;
            SharedProfileFactory.setLogLimit(limit);
            SharedProfileFactory.setLoadMoreInProgress(true);
            controller.loadHistoryLog();
        };

        /**
         * Get status of loading more history log .
         *
         * @returns {bool}
         */
        controller.fetchingMoreHistoryLog = function () {
            return SharedProfileFactory.isLoadMoreInProgress();
        };

        /**
         * Actions to do when general history log is activated.
         */
        $scope.handleHistoryLogClick = function () {
            $scope.handleGeneralHistoryCloseClick();
            if ($scope.showGeneralHistoryBlock) {
                controller.loadHistoryLog();
            }
        };

        $scope.isGeneralTabActive = function () {
            return SharedProfileFactory.isGeneralTabSelected();
        };

        $scope.isCampusesTabActive = function () {
            return SharedProfileFactory.isCampusesTabSelected();
        };

        $scope.isRightSidePanelActive = function () {
            // general tab is active
            if (SharedProfileFactory.isGeneralTabSelected() && $scope.showGeneralHistoryBlock) {
                return true;
            }
            // campus tab is active
            if (SharedProfileFactory.isCampusesTabSelected() && $scope.showCampusForm) {
                return true;
            }
        };

        /**
         * Actions to do when general history log is closed.
         */
        $scope.handleGeneralHistoryCloseClick = function () {
            SharedProfileFactory.setLogLimit(1);
            $scope.generalHistoryLog = {};
            $scope.showGeneralHistoryBlock = !$scope.showGeneralHistoryBlock;
        };

        controller.handleHistoryLogVisibility = function() {
            return SharedProfileFactory.getLogLimit() < controller.historyLog.total;
        };

        function getSharedProfileData (coreId) {
            if (!UserFactory.noAccess($state.current.name)) {
                // load institution's shared profile by Core ID
                SharedProfileService.getSharedProfile(coreId, true).then(function (data) {
                    $scope.loadInProgress = false;
                    // set shared profile for system wide use
                    SharedProfileFactory.setData(data);

                    $scope.sharedProfile = data;
                    $scope.showGeneralHistoryBlock = false;
                    $scope.$broadcast(constants.events.institutionSharedProfileChanges);
                });
            } else {
                // if user doesn't have access, empty previously loaded data
                $scope.loadInProgress = false;
                SharedProfileFactory.setData(null);
            }
        }

        controller.initWatches = function () {
            // listen to active profile tab changes
            WatchService.create($scope, 'activeTab', function (newValue, oldValue) {
                if (!SharedProfileFactory.hasSelectedTab() || !angular.equals(newValue, oldValue)) {
                    SharedProfileFactory.setSelectedTabId(newValue);
                }
            });

            // watch for core ID changes
            WatchService.create($scope, InstitutionFactory.getCoreId, function (coreId) {
                if (!coreId) {
                    return false;
                }
                $scope.loadInProgress = true;
                getSharedProfileData(coreId);
            });

            // watch for campus form visibility changes
            WatchService.create($scope, SharedProfileFactory.isCampusFormVisible, function (newValue, oldValue) {
                if (newValue !== oldValue) {
                    $scope.showCampusForm = newValue;
                }
            });

            WatchService.create($scope, function () {
                return $state.$current.name;
            }, function (newValue, oldValue) {
                if (newValue !== oldValue) {
                    controller.initActiveTab();
                }
            });
            if (!UserFactory.getSubscribedToDataUpdateSharedProfile()) {
                WebSocketsService.subscribe('data', 'update', controller.subscribeDataUpdate);
                UserFactory.setSubscribedToDataUpdateSharedProfile(true);
            }
        };

        controller.subscribeDataUpdate = function (data) {
            var split = data.split(':');
            if (split.length === 2 && split[0] === 'SharedProfile') {
                var coreId = parseInt(split[1]);
                if (angular.equals(InstitutionFactory.getCoreId(), coreId)) {
                    SharedProfileService.cleanCacheKey(coreId);
                    getSharedProfileData(coreId);
                }
            }
            
            WatchService.create($scope, function () {
                return SharedProfileFactory.isUpdateButtonClicked();
            }, function (updated) {
                if (updated && $scope.showGeneralHistoryBlock) {
                    controller.handleLoadMoreHistoryLog(true);
                }
                SharedProfileFactory.setUpdateButtonClicked(false);
            });
        };

        controller.initActiveTab = function () {
            switch ($state.current.name) {
                case 'clients.profiles.shared.campuses':
                    $scope.activeTab = SharedProfileFactory.getActiveTabs().campuses;
                    break;
                case 'clients.profiles.shared.general':
                case 'clients.profiles.shared':
                    $scope.activeTab = SharedProfileFactory.getActiveTabs().general;
                    break;
            }
        };

        controller.$onInit = function () {
            controller.initWatches();
            controller.initActiveTab();
        };
    };

    angular
        .module('qsHub')
        .controller('SharedProfileController', [
            '$scope',
            '$state',
            '$stateParams',
            'constants',
            'SharedProfileService',
            'SharedProfileFactory',
            'InstitutionFactory',
            'UserFactory',
            'WatchService',
            'WebSocketsService',
            App.controllers.sharedProfile
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    App.services.SharedProfileService = function ($resource, $q, $log, constants) {
        var service = {
            generalHistory: null,
            deferred : {
                getGeneralHistory: null
            },
            cache: {}
        };

        /**
         * Get shared profile model instance when Core ID is used.
         *
         * @returns {$resource}
         */
        service.getSharedProfilesCoreModel = function () {
            return $resource(constants.api.institutions.url + '/v1/shared-profile/core-id/:id');
        };

        /**
         * Get shared profile history model instance.
         * 
         * @returns {$resource}
         */
        service.getSharedProfilesHistoryModel = function () {
            return $resource(constants.api.institutions.url + '/v1/list/shared-profile-history?sorting[modifiedAt]=desc&count=10&filter[recordId]=:recordId');
        };

        /**
         * Get shared profile model instance.
         *
         * @returns {$resource}
         */
        service.getSharedProfilesModel = function () {
            return $resource(constants.api.institutions.url + '/v1/shared-profile/:id', null, {
                update: { method:'PATCH' }
            });
        };

        /**
         * Get shared profile data.
         *
         * @param {int} coreId
         * @param useCache
         * @returns {Promise}
         */
        service.getSharedProfile = function (coreId, useCache, getFoundationYearColumn) {
            if (!coreId) {
                throw 'Missing Required';
            }
            useCache = useCache || false;


            var SharedProfilesModel = service.getSharedProfilesCoreModel();

            var deferred = $q.defer(),
                cacheKey = 'SharedProfile:' + coreId;
            if (useCache && service.cache[cacheKey]) {
                deferred = $q.defer();
                deferred.resolve(service.cache[cacheKey]);
                return deferred.promise;
            }
            var filters = {
                id: coreId
            };
            if (getFoundationYearColumn) {
                filters['columns[]'] = 'foundationYear';
            }
            else {
                delete filters['columns[]'];
            }

            return SharedProfilesModel.get(filters).$promise.then(function (data) {
                    if (constants.dev) {
                        $log.log('success, got data: ', data);
                    }
                    service.cache[cacheKey] = data.toJSON();
                    return data.toJSON();
                }, function (error) {
                    //@todo log error
                    if (constants.dev) {
                        $log.log('request failed' + error);
                    }
                    return [];
                }
            );
        };

        /**
         * Get shared profile history log.
         * 
         * @param {string} recordId
         * @param {boolean} noCache
         * @param {int} limit
         * @returns {Promise}
         */
        service.getGeneralHistory = function (recordId, noCache, limit) {
            if (!recordId) {
                return false;
            }
            noCache = noCache || false;
            // process already running
            if (!service.deferred.getGeneralHistory) {
                service.deferred.getGeneralHistory = $q.defer();
            } else {
                return service.deferred.getGeneralHistory.promise;
            }
            var deferred = service.deferred.getGeneralHistory;

            if (!noCache && service.generalHistory) {
                deferred.resolve(service.generalHistory);
            } else {
                var SharedProfilesHistoryModel = service.getSharedProfilesHistoryModel();
                SharedProfilesHistoryModel.get(
                    {
                        recordId: recordId,
                        limit: limit
                    },
                    function (data) {
                        if (constants.dev) {
                            $log.log('success, got data: ', data);
                        }
                        service.generalHistory = data;
                        deferred.resolve(service.generalHistory);
                        service.deferred.getGeneralHistory = null;
                    }, function (error) {
                        //@todo log error
                        if (constants.dev) {
                            $log.log('request failed' + error);
                        }
                        service.generalHistory = [];
                        deferred.resolve(service.generalHistory);
                        service.deferred.getGeneralHistory = null;
                    }
                );
            }

            return deferred.promise;
        };

        /**
         * Submit shared profile general data.
         *
         * @param {Object} profileData
         * @returns {Promise}
         */
        service.saveGeneral = function (profileData) {
            var SharedProfilesModel = service.getSharedProfilesModel(),
                dataToUpdate = {
                    foundationYear:null,
                    fullName: null,
                    smallLogo: {
                        "path": null,
                        "title": null,
                        "description": null
                    },
                    mediumLogo: {
                        "path": null,
                        "title": null,
                        "description": null
                    },
                    largeLogo: {
                        "path": null,
                        "title": null,
                        "description": null
                    }
                };

            return SharedProfilesModel.update(
                {id: profileData.id},
                service.filterObject(dataToUpdate, profileData)
            ).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return data;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return error;
            });
        };

        service.update = function (sharedProfileId, dataToUpdate) {
            var UpdateModel = service.getSharedProfilesModel();
            return UpdateModel.update(
                {id: sharedProfileId},
                dataToUpdate
            ).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };
        
        /**
         * Fill one object keys with other object values.
         *
         * @param {Object} filters
         * @param {Object} object
         * @returns {Object}
         */
        service.filterObject = function (filters, object) {
            var filteredObject = {};
            if (filters && object && typeof filters === 'object' && typeof object === 'object') {
                filteredObject = angular.copy(filters);
                angular.forEach(filters, function (value, key) {
                    if (value && typeof value === 'object' && object[key]) {
                        angular.forEach(value, function (subValue, subKey) {
                            if (subValue === null && object[key][subKey]) {
                                filteredObject[key][subKey] = object[key][subKey];
                            }
                        });
                    } else if (value === null && object[key]) {
                        filteredObject[key] = object[key];
                    }
                });
            }

            return filteredObject;
        };

        service.cleanCacheKey = function (coreId) {
            var cacheKey = 'SharedProfile:' + coreId;
            if (service.cache[cacheKey]) {
                delete(service.cache[cacheKey]);
            }
        };

        return service;
    };

    angular
        .module('qsHub')
        .service('SharedProfileService', ['$resource', '$q', '$log', 'constants', App.services.SharedProfileService]);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { controllers: {} });

    App.controllers.sharedProfile = function (
        $scope,
        constants,
        SharedProfileService,
        NotifierFactory,
        SharedProfileFactory,
        InstitutionFactory,
        AuthenticationService,
        ModalService,
        WatchService,
        ClassificationsService,
        TimeService
    ) {
        var controller = this,
            alreadyInitialised = false;
        controller.devMode = constants.dev;
        controller.acceptedFiles = 'image/jpeg,image/pjpeg';
        controller.acceptedFilesFriendly = '.jpeg, .jpg';
        $scope.generalSubmitDisabled = false;
        $scope.displayRemoveLink = false;
        $scope.uploadEnabled = true;
        $scope.forms = {};
        $scope.profileBeforeChanges = {};
        $scope.foundationYearSpinOptions = {
            verticalbuttons: true,
            min: 0,
            max: new Date().getFullYear()
        };
        $scope.displaySpinner = false;

        /**
         * Last uploaded image holder
         *
         * @param {string} smallLogoPath
         * @param {string} mediumLogoPath
         * @param {string} largeLogoPath
         * @returns {{smallLogoPath: string, mediumLogoPath: string, largeLogoPath: string}}
         */
        controller.initLastUploaded = function (smallLogoPath, mediumLogoPath, largeLogoPath) {
            smallLogoPath = (typeof smallLogoPath === 'undefined') ? null : smallLogoPath;
            mediumLogoPath = (typeof mediumLogoPath === 'undefined') ? null : mediumLogoPath;
            largeLogoPath = (typeof largeLogoPath === 'undefined') ? null : largeLogoPath;

            return { 'smallLogoPath': smallLogoPath, 'mediumLogoPath': mediumLogoPath, 'largeLogoPath': largeLogoPath };
        };
        // init lastUploaded variable
        controller.lastUploaded = controller.initLastUploaded();

        /**
         * Modify scope variable on logo remove.
         */
        controller.handleLogoRemoved = function () {
            // show upload area
            $scope.uploadEnabled = true;
            // enable update button
            $scope.generalSubmitDisabled = false;
            // hide remove link below uploader
            $scope.displayRemoveLink = false;
        };

        /**
         * Check profile logo data and normalize it.
         */
        controller.checkProfileLogos = function () {
            if (!$scope.profile) {
                $scope.profile = {};
            }
            if (!$scope.profile.smallLogo) {
                $scope.profile.smallLogo = {};
            }
            if (!$scope.profile.mediumLogo) {
                $scope.profile.mediumLogo = {};
            }
            if (!$scope.profile.largeLogo) {
                $scope.profile.largeLogo = {};
            }
        };

        /**
         * Initialize display logo paths.
         */
        controller.assignDefaultDisplayLogos = function () {
            $scope.profile.smallLogoPath = null;
            $scope.profile.mediumLogoPath = null;
            $scope.profile.largeLogoPath = null;
        };

        /**
         * Copy profile logo details to display logo.
         */
        controller.copyProfileToDisplayLogos = function () {
            if (angular.isDefined($scope.profile.smallLogo) && $scope.profile.smallLogo.hasOwnProperty('path')) {
                $scope.profile.smallLogoPath = $scope.profile.smallLogo.path;
            }
            if (angular.isDefined($scope.profile.mediumLogo) && $scope.profile.mediumLogo.hasOwnProperty('path')) {
                $scope.profile.mediumLogoPath = $scope.profile.mediumLogo.path;
            }
            if (angular.isDefined($scope.profile.largeLogo) && $scope.profile.largeLogo.hasOwnProperty('path')) {
                $scope.profile.largeLogoPath = $scope.profile.largeLogo.path;
            }
        };

        /**
         * Copy last uploaded logo paths to profile logo.
         */
        controller.copyLastUploadedToProfileLogos = function () {
            controller.checkProfileLogos();
            if (controller.lastUploaded.smallLogoPath) {
                $scope.profile.smallLogo.path = controller.lastUploaded.smallLogoPath;
            }
            if (controller.lastUploaded.mediumLogoPath) {
                $scope.profile.mediumLogo.path = controller.lastUploaded.mediumLogoPath;
            }
            if (controller.lastUploaded.largeLogoPath) {
                $scope.profile.largeLogo.path = controller.lastUploaded.largeLogoPath;
            }
        };

        /**
         * Copy last uploaded logo paths to profile display logo paths
         */
        controller.copyLastUploadedToProfileDisplayLogos = function () {
            if (controller.lastUploaded.smallLogoPath) {
                $scope.profile.smallLogoPath = controller.lastUploaded.smallLogoPath;
            }
            if (controller.lastUploaded.mediumLogoPath) {
                $scope.profile.mediumLogoPath = controller.lastUploaded.mediumLogoPath;
            }
            if (controller.lastUploaded.largeLogoPath) {
                $scope.profile.largeLogoPath = controller.lastUploaded.largeLogoPath;
            }
        };

        /**
         * Assign display logos on init
         */
        controller.assignDisplayLogos = function () {
            controller.checkProfileLogos();
            controller.assignDefaultDisplayLogos();
            controller.copyProfileToDisplayLogos();
        };

        controller.copyResponseToLastUploaded = function (response) {
            if (response.small.path && response.small.status) {
                controller.lastUploaded.smallLogoPath = response.small.path;
            }
            if (response.medium.path && response.medium.status) {
                controller.lastUploaded.mediumLogoPath = response.medium.path;
            }
            if (response.large.path && response.large.status) {
                controller.lastUploaded.largeLogoPath = response.large.path;
            }
        };

        /**
         * Assign display logos on submit
         */
        controller.assignDisplayLogo = function (clearLogos, response) {
            response = (typeof response === 'undefined') ? false : response;
            controller.checkProfileLogos();
            if (response) {
                controller.copyResponseToLastUploaded(response);
                controller.copyLastUploadedToProfileLogos();
            }
            controller.copyLastUploadedToProfileDisplayLogos();
            if (!clearLogos) {
                controller.lastUploaded = controller.initLastUploaded();
            }
        };

        /**
         * DropZone options url function.
         *
         * @returns {string}
         */
        controller.getUploadUrl = function () {
            return constants.api.institutions.url + '/v1/shared-profile-logo/core-id/' + InstitutionFactory.getCoreId();
        };

        /**
         * Remove profile images from scope.
         */
        $scope.handleClearLogosClick = function () {
            var modalOptions = {
                closeButtonText: 'Cancel',
                actionButtonText: 'Yes',
                headerText: 'Logo Deletion',
                bodyText: 'Are you sure you wish to delete profile logos?'
            };
            ModalService.show({}, modalOptions).then(function (result) {
                $scope.handleGeneralSubmit(true);
            });
        };

        /**
         * Actions to do when profile form is submitted.
         *
         * @returns {boolean}
         */
        $scope.handleGeneralSubmit = function (clearLogos) {
            $scope.displaySpinner = true;
            clearLogos = angular.isDefined(clearLogos) ? clearLogos : false;
            if (
                $scope.generalSubmitDisabled ||
                !$scope.forms.generalForm ||
                !$scope.forms.generalForm.$valid
            ) {
                return false;
            }
            $scope.generalSubmitDisabled = true;
            controller.copyLastUploadedToProfileLogos();
            if (clearLogos) {
                $scope.profile.smallLogo.path = null;
                $scope.profile.mediumLogo.path = null;
                $scope.profile.largeLogo.path = null;
            }
            SharedProfileService.saveGeneral($scope.profile).then(function (response) {
                $scope.generalSubmitDisabled = false;
                // if successful update
                if (response.status === true) {
                SharedProfileFactory.setUpdateButtonClicked(true);
                    // update profile object
                    controller.assignDisplayLogo(clearLogos, response);
                    if (clearLogos) {
                        $scope.profile.smallLogoPath = null;
                        $scope.profile.mediumLogoPath = null;
                        $scope.profile.largeLogoPath = null;
                        // show status message
                        NotifierFactory.show(
                            'success',
                            'Logos removed successfully!',
                            'Institution Details General data'
                        );
                    } else {
                        // show status message
                        NotifierFactory.show(
                            response.status ? 'success' : 'error',
                            response.status ? 'Saved successfully!' : 'Saving failed!',
                            'Institution Details General data'
                        );
                    }
                    if ($scope.profileBeforeChanges.foundationYear !== $scope.profile.foundationYear) {
                        ClassificationsService.get(
                            {'filter[institutionCoreId]': $scope.profile.coreId, page: 1, limit: 1}
                        ).then(function (response) {
                            if (response.results[0] !== undefined) {
                                var classificationData = response.results[0];
                                if (TimeService.isValidYear($scope.profile.foundationYear)) {
                                    classificationData.age = ClassificationsService.getAge(TimeService.getCurrentYear(), $scope.profile.foundationYear);
                                }
                                else {
                                    classificationData.age = null;
                                }
                                ClassificationsService.update(classificationData.id, classificationData);
                            }
                        });
                        }
                    $scope.profileBeforeChanges = angular.copy($scope.profile);
                } else {
                    $scope.profile = angular.copy($scope.profileBeforeChanges);
                    if (response && response.data && response.data.error) {
                        NotifierFactory.show(
                            'error',
                            response.data.message ? response.data.message : 'Saving failed!',
                            response.data.error
                        );
                    }
                }

                if (!clearLogos) {
                    $scope.removeUploaderImage();
                }
                $scope.displaySpinner = false;
            });
        };

        /**
         * Remove dropZone files
         */
        controller.removeDropZoneUploadedImage = function () {
            if (controller.logoDropzoneInstance.files.length > 1) {
                controller.logoDropzoneInstance.removeFile(controller.logoDropzoneInstance.files[0]);
            } else {
                controller.logoDropzoneInstance.removeAllFiles();
            }
        };

        /**
         * Set of actions to remove uploader image
         */
        $scope.removeUploaderImage = function () {
            if (!$scope.generalSubmitDisabled) {
                controller.removeDropZoneUploadedImage();
                controller.handleLogoRemoved();
                controller.lastUploaded = controller.initLastUploaded();
            } else {
                NotifierFactory.show(
                    'info',
                    'Logo upload in progress, please wait.',
                    ''
                );
            }
        };

        /**
         * DropZone init.
         */
        controller.handleInit = function () {
            this.isValid = true;
            this.globalAccept = false;
            this.localAccept = false;
        };

        /**
         * DropZone handle accept.
         *
         * @param file
         * @param done
         */
        controller.handleAccept = function (file, done) {
            file.acceptFile = function () {
                $scope.uploadEnabled = false;
                $scope.$apply();
                done();
            };
        };

        /**
         * Actions to do when file is uploaded to server.
         *
         * @param {Object} file - instance of dropzone file
         * @param {Object} response - server response object
         */
        controller.handleLogoUploaded = function (file, response) {
            var success = response &&
                response.hasOwnProperty('large') &&
                response.hasOwnProperty('medium') &&
                response.hasOwnProperty('small');
            if (success) {
                angular.forEach(response, function (data) {
                    if (!data.status) {
                        success = false;
                    }
                });
            }
            if (success) {
                $scope.generalSubmitDisabled = false;
                controller.lastUploaded = controller.initLastUploaded();
                controller.copyResponseToLastUploaded(response);
                // display link below uploader
                $scope.displayRemoveLink = true;
            } else {
                var message = 'Logo image upload failed!';
                if (response !== null && response.hasOwnProperty('code')) {
                    message = response.message;
                }
                controller.removeDropZoneUploadedImage();
                controller.handleLogoRemoved();
                NotifierFactory.show(
                    'error',
                    message,
                    'Institution Details - General'
                );
            }
            $scope.$apply();
        };

        /**
         * Actions to do when file is added to upload queue.
         */
        controller.handleLogoAdded = function (file) {
            file.rejectExtension = function () {
                NotifierFactory.show(
                    'error',
                    'Allowed types: ' + controller.acceptedFilesFriendly,
                    'File type is unacceptable.'
                );
                controller.removeDropZoneUploadedImage();
                controller.handleLogoRemoved();
                $scope.$apply();
            };
            if (this.files.length === 1) {
                this.globalAccept = false;
                this.localAccept = false;
            }
            if (!this.globalAccept && !this.localAccept && this.files.length === 1) {
                this.isValid = true;
                this.localAccept = true;
            }
            if (this.files.length > 1 && this.globalAccept) {
                this.isValid = false;
                this.globalAccept = true;
                this.removeFile(this.files[1]);
                $scope.$apply();
            }
            this.isValid = true;
            // Validate image extension for drag and drop
            var allowedExtensionsArray = controller.acceptedFiles.split(',');
            if (allowedExtensionsArray.indexOf(file.type) === -1) {
                this.isValid = false;
                file.rejectExtension();
            }
            if (this.isValid) {
                // disable update button
                $scope.generalSubmitDisabled = true;
                // hide upload area
                $scope.uploadEnabled = false;
                $scope.$apply();
            }
        };

        /**
         * DropZone handle thumbnail.
         *
         * @param file
         */
        controller.handleThumbnail = function (file) {
            file.rejectDimensions = function (globalAccept) {
                globalAccept = (typeof globalAccept === 'undefined') ? true : globalAccept;
                if (!globalAccept) {
                    NotifierFactory.show(
                        'error',
                        'Logo dimension is incorrect.',
                        'Please upload 200px by 200px logo.'
                    );
                    controller.removeDropZoneUploadedImage();
                    controller.handleLogoRemoved();
                    $scope.$apply();
                }
            };
            file.rejectSize = function (globalAccept) {
                globalAccept = (typeof globalAccept === 'undefined') ? true : globalAccept;
                if (!globalAccept) {
                    NotifierFactory.show(
                        'error',
                        'Please upload less than 400 KB.',
                        'Uploaded Image is greater than the limit.'
                    );
                    controller.removeDropZoneUploadedImage();
                    controller.handleLogoRemoved();
                    $scope.$apply();
                }
            };
            // Validate image dimensions
            if (file.height !== 200 || file.width !== 200) {
                this.isValid = false;
                file.rejectDimensions(this.globalAccept);
            }
            // Validate image size
            if (file.size > 400000) {
                this.isValid = false;
                file.rejectSize(this.globalAccept);
            }
            if (this.isValid && !this.globalAccept && this.localAccept) {
                this.globalAccept = true;
                file.acceptFile(this.localAccept);
            } else {
                $scope.generalSubmitDisabled = false;
                if (this.files.length > 1 && this.globalAccept) {
                    this.removeFile(this.files[1]);
                }
                $scope.$apply();
            }
            if (false === this.isValid) {
                this.isValid = true;
            }
        };

        controller.logoConfig = {
            // http://www.dropzonejs.com/#configuration-options
            dropzone: {
                url: controller.getUploadUrl,
                maxFilesize: 1,
                maxFiles: 1,
                uploadMultiple: 0,
                parallelUploads: 1,
                autoProcessQueue: true,
                addRemoveLinks: false,
                acceptedFiles: controller.acceptedFiles,
                previewsContainer: '.dropzone-previews',
                headers: AuthenticationService.getAuthorizationHeader(),
                thumbnailWidth: 200,
                thumbnailHeight: 200,
                paramName: "file",
                init: controller.handleInit,
                accept: controller.handleAccept
            },
            // http://www.dropzonejs.com/#event-list
            eventHandlers: {
                success: controller.handleLogoUploaded,
                addedfile: controller.handleLogoAdded,
                thumbnail: controller.handleThumbnail
            }
        };

        controller.isRemoveLogosDisabled = function () {
            return !$scope.profile || (
                !$scope.profile.smallLogoPath && !$scope.profile.mediumLogoPath && !$scope.profile.largeLogoPath
            ) || $scope.generalSubmitDisabled;
        };

        controller.initWatches = function () {
            // watch for shared profile changes
            WatchService.create($scope, SharedProfileFactory.getData, function (newValue) {
                $scope.profile = newValue;
                controller.assignDisplayLogos();
                controller.removeDropZoneUploadedImage();
                controller.handleLogoRemoved();
                $scope.profileBeforeChanges = angular.copy(newValue);
            });
        };

        controller.init = function () {
            controller.initWatches();
        };

        // listen to departments tab visibility changes
        WatchService.create($scope, SharedProfileFactory.isGeneralTabSelected, function (isActive) {
            // if not already initialized and tab is active
            if (!alreadyInitialised && isActive) {
                controller.init();
                alreadyInitialised = true;
            }
        });
    };

    angular
        .module('qsHub')
        .controller('SharedProfileGeneralController', [
            '$scope',
            'constants',
            'SharedProfileService',
            'NotifierFactory',
            'SharedProfileFactory',
            'InstitutionFactory',
            'AuthenticationService',
            'ModalService',
            'WatchService',
            'ClassificationsService',
            'TimeService',
            App.controllers.sharedProfile
        ]);

} (window.angular));

(function(angular) {
    "use strict";

    angular
        .module('qsHub')
        .controller('SharedProfileCampusesController', [
            '$scope',
            'NgTableParams',
            'constants',
            'CampusesService',
            'SharedProfileService',
            'InstitutionsListService',
            'NotifierFactory',
            'SharedProfileFactory',
            'InstitutionFactory',
            'MapInitializerFactory',
            'WatchService',
            'UserFactory',
            'ModalService',
            SharedProfileCampuses
        ]);

    function SharedProfileCampuses(
        $scope,
        NgTableParams,
        constants,
        CampusesService,
        SharedProfileService,
        InstitutionsListService,
        NotifierFactory,
        SharedProfileFactory,
        InstitutionFactory,
        MapInitializerFactory,
        WatchService,
        UserFactory,
        ModalService
    ) {
        var controller = this,
            alreadyInitialised = false,
            newlyAdded = false;
        controller.devMode = constants.dev;
        controller.googleMapsMarker = null;
        controller.noCache = false;

        $scope.isQsUser = true; // @todo replace with real data
        $scope.campusesInEventsInclusionDisbaled = false;
        $scope.loadInProgress = true;
        $scope.displayMapBlock = false;
        $scope.selectedCampusId = null;
        $scope.mapMarkers = [];
        $scope.campusesToDelete = {};
        $scope.deleteInProgress = false;
        $scope.columns = [
            {
                title: 'Delete',
                show: true,
                field: 'delete',
                headerTemplateURL: 'ng-table/templates/campus/delete-header.html'
            },
            {
                title: 'Campus Name',
                show: true,
                field: 'name'
            },
            {
                title: 'Address',
                show: true,
                field: 'addressLine'
            },
            {
                title: 'Postcode',
                show: true,
                field: 'postcode'
            },
            {
                title: 'Town/City',
                show: true,
                field: 'city'
            },
            {
                title: 'Latitude',
                show: true,
                field: 'latitude'
            },
            {
                title: 'Longitude',
                show: true,
                field: 'longitude'
            },
            {
                title: 'Country Name',
                show: true,
                field: 'countryName'
            },
            {
                title: 'Primary Campus',
                show: true,
                field: 'primaryName'
            },
            {
                title: 'Order',
                show: false,
                field: 'order'
            }
        ];
        if (!UserFactory.isClient()) {
            $scope.columns.push({
                title: 'ID',
                show: true,
                field: 'id'
            });
        }

        $scope.campusMap = null;

        /**
         * Render campus in google map.
         */
        controller.renderMap = function () {
            var campus = SharedProfileFactory.getCampus();
            if (!campus.latitude) {
                campus.latitude = null;
            }
            if (!campus.longitude) {
                campus.longitude = null;
            }
            SharedProfileFactory.setCampus(campus);
            var campusLatitude = campus.latitude,
                campusLongitude = campus.longitude;
            if (campusLatitude === null || campusLongitude === null) {
                campusLatitude = 0;
                campusLongitude = 0;
            }
            if (!google) {
                return;
            }
            var googleMapsLatLng = new google.maps.LatLng({
                lat: parseFloat(campusLatitude),
                lng: parseFloat(campusLongitude)
            });

            $scope.campusMap.panTo(googleMapsLatLng);
            $scope.campusMap.setOptions({
                //center: googleMapsLatLng,
                zoom: 15,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                draggable: true
            });
            if (controller.googleMapsMarker) {
                controller.googleMapsMarker.setMap(null);
            }
            controller.googleMapsMarker = new google.maps.Marker({
                map: $scope.campusMap,
                position: googleMapsLatLng,
                draggable: true
            });

            google.maps.event.addListener(controller.googleMapsMarker, 'dragend', function (event) {
                $scope.campusMap.panTo(event.latLng);
                campus.autoGenerate = false;
                campus.latitude = event.latLng.lat();
                campus.longitude = event.latLng.lng();
                google.maps.event.trigger($scope.campusMap, 'resize');
                $scope.campusMap.setCenter(controller.googleMapsMarker.getPosition());
                $scope.$apply(); // otherwise the changes are not updated on sidebar
            });

            /**
             * This fixes the bug when user already visited campus edit page,
             * then navigated out, then returned to campuses edit page.
             */
            $scope.campusMap.addListener('idle', function() {
                if (newlyAdded && google) {
                    google.maps.event.trigger($scope.campusMap, 'resize');
                    $scope.campusMap.setCenter(controller.googleMapsMarker.getPosition());
                    newlyAdded = false;
                }
            });
        };

        /**
         * Initialise google maps.
         */
        controller.initialiseMap = function () {
            if (!$scope.campusMap) {
                MapInitializerFactory.initStart();
            }
            MapInitializerFactory.mapsInitialized
                .then(function() {
                    if (null === $scope.campusMap && document.getElementById("campusMap") && google) {
                        $scope.campusMap = new google.maps.Map(document.getElementById("campusMap"), {
                            zoom: 15,
                            mapTypeId: google.maps.MapTypeId.ROADMAP,
                            draggable: true
                        });
                        newlyAdded = true;
                    }
                    if ($scope.campusMap) {
                        controller.renderMap();
                    }
                });
        };

        /**
         * Actions to do on add button click
         */
        $scope.handleAddClick = function () {
            SharedProfileFactory.setCampus({
                displayInFrontEnd: true
            });
            SharedProfileFactory.setCampusAddFormVisibility();
            $scope.displayMapBlock = false;
            $scope.selectedCampusId = null;
            if (SharedProfileFactory.isCampusFormVisible() && !SharedProfileFactory.isCampusEditForm()) {
                controller.initialiseMap();
                $scope.displayMapBlock = true;
            }
        };

        /**
         * Actions to do when data grid row is clicked.
         *
         * @param {Object} campus
         */
        $scope.handleDatagridRowClick = function (campus) {
            $scope.selectedCampusId = ($scope.selectedCampusId === campus.id) ? null : campus.id;

            // create new copy of campus for making changes in it
            var campusCopy = angular.copy(campus);
            SharedProfileFactory.setCampus(campusCopy);

            SharedProfileFactory.setCampusEditFormVisibility(
                $scope.selectedCampusId === campus.id && campus.id !== 'undefined'
            );
            $scope.displayMapBlock = true;
            controller.initialiseMap();
        };

        /**
         * Actions to do when campuses are included/excluded from events pages.
         */
        $scope.handleIncludeInEventsClick = function () {
            // indicate progress
            $scope.campusesInEventsInclusionDisbaled = true;
            // store changes
            SharedProfileService.update($scope.sharedProfile.id, {
                campusesOnEvents: $scope.sharedProfile.campusesOnEvents
            }).then(function (success) {
                NotifierFactory.show(
                    success ? 'success' : 'error',
                    success ? 'Success!' : 'Failure!',
                    $scope.sharedProfile.campusesOnEvents ? 'Including campuses on events pages' : 'Excluding campuses on events pages'
                );
                // indicate progress end
                $scope.campusesInEventsInclusionDisbaled = false;
            });
        };

        /**
         * Sortable options
         */
        $scope.sortableOptions = {
            disabled: false,
            'ui-floating': false,
            stop: function () {
                $scope.sortableOptions.disabled = true;
                // change campuses order
                controller.reOrderCampuses();
                InstitutionsListService.saveOrder(InstitutionFactory.getId(), $scope.tableParams.data)
                    .then(function (success) {
                        NotifierFactory.show(
                            success ? 'success' : 'error',
                            success ? ' Order saved successfully!' : 'Saving order failed!',
                            'Campus'
                        );
                    })
                    .finally(function () {
                        $scope.sortableOptions.disabled = false;
                    });
            }
        };

        /**
         * Assigns new order for campuses starting with 1.
         */
        controller.reOrderCampuses = function () {
            var assignedOrder = 1;
            for (var index = 0; index < $scope.tableParams.data.length; index++) {
                $scope.tableParams.data[index].order = assignedOrder;
                assignedOrder++;
            }
        };

        controller.getCampusesToDelete = function () {
            var campusIds = [];
            if ($scope.campusesToDelete) {
                angular.forEach($scope.campusesToDelete, function (remove, id) {
                    if (remove) {
                        campusIds.push(id);
                    }
                });
            }

            return campusIds;
        };

        controller.hasCampusesToDelete = function () {
            var departmentsIds = controller.getCampusesToDelete();
            return departmentsIds.length !== 0;
        };

        $scope.isDeleteButtonDisabled = function () {
            return $scope.deleteInProgress ||
                !controller.hasCampusesToDelete();
        };

        function getCourseNameById (id) {
            var total = $scope.tableParams.data.length,
                i = 0;
            for (i; i < total; i++) {
                if ( $scope.tableParams.data[i].id === id) {
                    return $scope.tableParams.data[i].name;
                }
            }
            return null;
        }

        function performDeletion(campusIds) {
            $scope.deleteInProgress = true;
            CampusesService.deleteCampuses(InstitutionFactory.getId(), campusIds).then(function (success) {
                $scope.deleteInProgress = false;
                if (success) {
                    // Check if current campus form belonged to one of the deleted campuses
                    for (var objectId in $scope.campusesToDelete) {
                        if (objectId === $scope.selectedCampusId) {
                            SharedProfileFactory.setCampusFormVisibility(false);
                            break;
                        }
                    }
                    $scope.campusesToDelete = {};
                    // reload campus datagrid
                    controller.noCache = true;
                    $scope.tableParams.reload();
                }
                NotifierFactory.show(
                    success ? 'success' : 'error',
                    success ? 'Deleted successfully!' : 'Deletion failed!',
                    'Campus'
                );
            });
        }

        /**
         * Actions to do when campuses delete button is clicked.
         *
         * @returns {boolean}
         */
        $scope.handleDeleteClick = function () {
            if ($scope.deleteInProgress || !controller.hasCampusesToDelete()) {
                return false;
            }
            var campusIds = controller.getCampusesToDelete(),
                programsList = null,
                tmProgramsList = null,
                modalOptions = {
                    closeButtonText: 'Cancel',
                    actionButtonText: 'Yes',
                    actionButtonClass: 'btn-danger',
                    headerText: 'Campuses Deletion',
                    close: function () {
                        this.close();
                    },
                    confirm: function () {
                        this.close();
                        performDeletion(campusIds);
                    },
                    isLoading: function () {
                        return programsList === null || tmProgramsList === null;
                    },
                    getProgramsList: function () {
                        return programsList;
                    },
                    getTmProgramsList: function () {
                        return tmProgramsList;
                    }
                }, modalDefaults = {
                    backdrop: true,
                    keyboard: true,
                    modalFade: true,
                    templateUrl: '/scripts/components/profiles/shared/campuses/campusDeletionDialogView.html'
                };
            ModalService.show(modalDefaults, modalOptions);
            CampusesService.getTmProgramsCounters(campusIds).then(function(data) {
                tmProgramsList = [];
                angular.forEach(campusIds, function (id) {
                    var count = 0;
                    angular.forEach(data, function (campuses) {
                        if (campuses.campus.includes(id)) {
                            if (count > 0) {
                                angular.forEach(tmProgramsList, function(item){
                                    if (item.id === id) {
                                        count = count + 1;
                                        item.total = count;
                                    }
                                });
                            }
                            if (count === 0) {
                                count = count +1;
                                tmProgramsList.push({
                                    'total' : count,
                                    'id': id,
                                    'name': getCourseNameById(id)
                                });
                            }
                        }
                    });
                });
            });
            CampusesService.getCoursesCounters(campusIds).then(function (results) {
                programsList = [];
                angular.forEach(results, function (count, key) {
                    programsList.push({
                        'total' : count,
                        'id': key,
                        'name': getCourseNameById(key)
                    });
                });
            });
        };

        controller.initWatches = function () {
            // watch for campuses list changes
            WatchService.create($scope, SharedProfileFactory.hasCampusesChanges, function (newValue) {
                if (newValue) {
                    controller.noCache = true;
                    $scope.campusesToDelete = {};
                    $scope.tableParams.reload();
                    $scope.selectedCampusId = newValue;
                }
            });

            // watch for campus form changes
            WatchService.create($scope, SharedProfileFactory.isCampusFormVisible, function (newValue) {
                // actions to do when form is not visible anymore
                if (!newValue) {
                    // reset selected campus ID
                    $scope.selectedCampusId = null;
                    // hide google maps
                    $scope.displayMapBlock = false;
                }
            });

            // Re-check the campus values and trigger map update
            WatchService.create($scope, SharedProfileFactory.mapUpdateRequest, function (newValue) {
                if (newValue && newValue !== null) {
                    var campus = SharedProfileFactory.getCampus(),
                        intLatitude,
                        intLongitude;
                    if (campus.latitude === null || campus.longitude === null) {
                        intLatitude = null;
                        intLongitude = null;
                    } else {
                        intLatitude = parseInt(campus.latitude, 10);
                        intLongitude = parseInt(campus.longitude, 10);
                    }

                    // check if latitude and longitude are correct
                    if (intLatitude === null || intLongitude === null ||
                        intLatitude < -90 || intLatitude > 90 ||
                        intLongitude < -180 || intLongitude > 180
                    ) {
                        return;
                    }
                    if (!google) {
                        return;
                    }

                    var googleMapsLatLng = new google.maps.LatLng({
                        lat: parseFloat(campus.latitude),
                        lng: parseFloat(campus.longitude)
                    });
                    if (null !== controller.googleMapsMarker) {
                        controller.googleMapsMarker.setPosition(googleMapsLatLng);
                        google.maps.event.trigger($scope.campusMap, 'resize');
                        $scope.campusMap.setCenter(controller.googleMapsMarker.getPosition());
                    }
                }
            });
        };

        controller.initDataGrid = function () {
            // get institution campuses
            $scope.tableParams = new NgTableParams({
                page: 1, // show first page
                count: 200, // count per page
                coreId: InstitutionFactory.getCoreId()
            }, {
                counts: [],
                debugMode: constants.dev,
                getData: function (params) {
                    var results = CampusesService.getCampusList(params, controller.noCache);
                    controller.noCache = false;

                    return results;
                }
            });
        };

        controller.initEvents = function () {
            $scope.$on(constants.events.institutionSharedProfileChanges, function() {
                alreadyInitialised = false;
                if (SharedProfileFactory.isCampusesTabSelected()) {
                    controller.initDataGrid();
                    alreadyInitialised = true;
                }
                $scope.campusesToDelete = {};
            });
        };

        controller.init = function () {
            controller.initWatches();
            controller.initEvents();
            controller.initDataGrid();
        };

        // listen to departments tab visibility changes
        WatchService.create($scope, SharedProfileFactory.isCampusesTabSelected, function (isActive) {
            // if not already initialized and tab is active
            if (!alreadyInitialised && isActive) {
                controller.init();
                alreadyInitialised = true;
            }
        });
    }
}(window.angular));

/* global google */
(function(angular) {
    "use strict";

    angular
        .module('qsHub')
        .controller('SharedProfileCampusFormController', [
            '$scope',
            'CampusesService',
            'NotifierFactory',
            'InstitutionFactory',
            'SharedProfileFactory',
            'WatchService',
            'UserFactory',
            'ModalService',
            SharedProfileCampusForm
        ]);

     function SharedProfileCampusForm(
        $scope,
        CampusesService,
        NotifierFactory,
        InstitutionFactory,
        SharedProfileFactory,
        WatchService,
        UserFactory,
        ModalService
    ) {
        var controller = this,
            notificationTitle = 'Campus';

        controller.submitted = false;
        $scope.forms = {};
        $scope.refreshMapInProgress = false;

        controller.isClient = UserFactory.isClient();

        // watch for core ID changes
        var coreId = InstitutionFactory.getCoreId();

        // watch for active campus changes
        $scope.campus = SharedProfileFactory.getCampus();

        controller.isValidName = function () {
            if (!CampusesService.isValidAsciiCharacters($scope.campus.name)) {
                return false;
            }
            return !!(
                $scope.forms.campusForm &&
                ($scope.forms.campusForm.$pristine ||
                ($scope.forms.campusForm.name && $scope.forms.campusForm.name.$pristine)) ||
                ($scope.campus && $scope.campus.name && $scope.campus.name.trim().length));
        };

        controller.isValidCountry = function () {
            if (!CampusesService.isValidAsciiCharacters($scope.campus.country)) {
                return false;
            }
            return !!(
                $scope.forms.campusForm &&
                ($scope.forms.campusForm.$pristine ||
                ($scope.forms.campusForm.country && $scope.forms.campusForm.country.$pristine)) ||
                ($scope.campus && $scope.campus.country && $scope.campus.country.trim().length)
            );
        };

        controller.isValidAddress = function () {
            if (!CampusesService.isValidAsciiCharacters($scope.campus.addressLine1)) {
                return false;
            }
            return !!(
                $scope.forms.campusForm &&
                ($scope.forms.campusForm.$pristine ||
                ($scope.forms.campusForm.addressLine1 && $scope.forms.campusForm.addressLine1.$pristine)) ||
                ($scope.campus && $scope.campus.addressLine1 && $scope.campus.addressLine1.trim().length)
            );
        };

        controller.isValidCity = function () {
            if (!CampusesService.isValidAsciiCharacters($scope.campus.city)) {
                return false;
            }
            return !!(
                $scope.forms.campusForm &&
                ($scope.forms.campusForm.$pristine ||
                ($scope.forms.campusForm.city && $scope.forms.campusForm.city.$pristine)) ||
                ($scope.campus && $scope.campus.city && $scope.campus.city.trim().length)
            );
        };

        controller.isValidState = function () {

            return CampusesService.isValidAsciiCharacters($scope.campus.state);
        };

        controller.isValidPostcode = function () {

            return CampusesService.isValidAsciiCharacters($scope.campus.postcode);
        };

        controller.isValidSecondAddress = function () {

            return CampusesService.isValidAsciiCharacters($scope.campus.addressLine2);
        };

        controller.isValidLatitude = function () {
            return CampusesService.isValidLatitude($scope.campus.latitude);
        };

        controller.isValidLongitude = function () {
            return CampusesService.isValidLongitude($scope.campus.longitude);
        };

        controller.setValid = function (fieldName) {
            controller.submitted = null;
            if (fieldName) {
                if ($scope.forms.campusForm && $scope.forms.campusForm[fieldName]) {
                    $scope.forms.campusForm[fieldName].$setPristine();
                }
            } else {
                $scope.forms.campusForm.$setPristine();
            }
        };

        controller.isEmpty = function (value) {

            return typeof value === 'undefined' || value === '';
        };

        controller.isValid = function () {
            return controller.isValidName() &&
                controller.isValidCountry() &&
                controller.isValidAddress() &&
                controller.isValidCity() &&
                controller.isValidSecondAddress() &&
                controller.isValidPostcode() &&
                controller.isValidState() &&
                controller.isValidLatitude() &&
                controller.isValidLongitude();
        };

        /**
         * Toggle edit mode.
         *
         * @param edit
         */
        controller.editToggle = function (edit) {
            $scope.displayDelete = edit;
            $scope.editMode = edit;
        };

        controller.editToggle(SharedProfileFactory.isCampusEditForm());

        // get countries list
        CampusesService.getCountries().then(function (list) {
            $scope.countriesList = list;
        });

        // get TU regions list
        CampusesService.getTuRegions().then(function (list) {
            $scope.tuRegionsList = list;
        });

        /**
         * Actions to do when cancel button is clicked.
         */
        $scope.handleCancelClick = function () {
            SharedProfileFactory.setCampusFormVisibility();
        };

        /**
         * Actions to do when delete button is clicked.
         */
        $scope.handleDeleteClick = function () {
            var modalOptions = {
                closeButtonText: 'Cancel',
                actionButtonText: 'Yes',
                actionButtonClass: 'danger',
                headerText: 'Campus Deletion',
                bodyText: 'Are you sure you wish to delete campus?'
            };
            ModalService.show({}, modalOptions).then(function () {
                CampusesService.deleteCampus($scope.institutionId, $scope.campus.id).then(controller.deleteCallback, controller.isValidName());
            });
        };

        controller.deleteCallback = function (success) {
            $scope.campusSubmitInProgress = false;
            NotifierFactory.show(
                success ? 'success' : 'error',
                success ? 'Deleted successfully!' : 'Deletion failed!',
                notificationTitle
            );
            if (success) {
                SharedProfileFactory.setCampus({});
                SharedProfileFactory.setCampusFormVisibility(false);
                SharedProfileFactory.announceCampusesChanges(true);
            }
        };

        /**
         * Actiosn to do when save button is clicked.
         *
         * @returns {boolean}
         */
        $scope.handleCreateClick = function () {
            controller.submitted = true;
            $scope.forms.campusForm.$setDirty();
            $scope.forms.campusForm.name.$setDirty();
            $scope.forms.campusForm.country.$setDirty();
            $scope.forms.campusForm.addressLine1.$setDirty();
            $scope.forms.campusForm.city.$setDirty();

            if (
                $scope.campusSubmitInProgress ||
                !$scope.forms.campusForm ||
                !controller.isValid()
            ) {
                return false;
            }
            $scope.campusSubmitInProgress = true;
            CampusesService.create($scope.institutionId, $scope.campus).then(controller.createCallback);
        };

        controller.createCallback = function (response) {
            $scope.campusSubmitInProgress = false;
            NotifierFactory.show(
                response ? 'success' : 'error',
                response ? 'Saved successfully!' : 'Saving failed!',
                notificationTitle
            );

            if (response) {
                $scope.campus.id = response.insertedId;
                if (angular.isDefined(response.coordinates)) {
                    $scope.campus.latitude = response.coordinates.latitude;
                    $scope.campus.longitude = response.coordinates.longitude;
                    SharedProfileFactory.setMapUpdateRequest(Math.random());
                }
                SharedProfileFactory.setCampus($scope.campus);
                SharedProfileFactory.setCampusAddFormVisibility(false);
                SharedProfileFactory.setCampusEditFormVisibility(true);
                SharedProfileFactory.announceCampusesChanges($scope.campus.id);

                // Add new campus to factory
                var currentCampuses = InstitutionFactory.getCampuses();
                currentCampuses.push($scope.campus);
                InstitutionFactory.setCampuses(currentCampuses);
            }
        };

        /**
         * Reset campus form
         */
        controller.resetCampusForm = function () {
            if ($scope.forms.campusForm) {
                $scope.forms.campusForm.$setPristine();
            }
        };

        /**
         * Actions to do when update button is clicked.
         *
         * @returns {boolean}
         */
        $scope.handleUpdateClick = function () {
            controller.submitted = true;
            $scope.forms.campusForm.$setDirty();
            $scope.forms.campusForm.name.$setDirty();
            $scope.forms.campusForm.country.$setDirty();
            $scope.forms.campusForm.addressLine1.$setDirty();
            $scope.forms.campusForm.city.$setDirty();

            if (
                $scope.campusSubmitInProgress ||
                !$scope.forms.campusForm ||
                !$scope.forms.campusForm.$valid ||
                !controller.isValid()
            ) {
                return false;
            }
            $scope.campusSubmitInProgress = true;
            CampusesService.update($scope.institutionId, $scope.campus).then(controller.updateCallback);
            controller.resetCampusForm();
        };

        controller.updateCallback = function (response) {
            $scope.campusSubmitInProgress = false;
            NotifierFactory.show(
                response ? 'success' : 'error',
                response ? 'Updated successfully!' : 'Updated failed!',
                notificationTitle
            );
            if (response) {
                if (angular.isDefined(response.coordinates)) {
                    $scope.campus.latitude = response.coordinates.latitude;
                    $scope.campus.longitude = response.coordinates.longitude;
                    SharedProfileFactory.setMapUpdateRequest(Math.random());
                }
                SharedProfileFactory.announceCampusesChanges($scope.campus.id);
                var currentCampuses = InstitutionFactory.getCampuses(),
                    i = 0,
                    total = currentCampuses.length;
                for (i; i < total; i++) {
                    if (currentCampuses[i].id === $scope.campus.id) {
                        currentCampuses[i] = angular.copy($scope.campus);
                        InstitutionFactory.setCampuses(currentCampuses);
                        break;
                    }
                }
            }
        };

        /**
         * Displays a message based on success
         *
         * @param {boolean} success
         * @param {string} message
         */
        controller.coordinatesUpdated = function (success, message) {
            NotifierFactory.show(
                success,
                message,
                'Address Search'
            );
        };

        /**
         * Get coordinates callback
         *
         * @param response
         */
        controller.getCoordinatesByCampusCallback = function (response) {
            var success = false;
            if (response) {
                if (angular.isArray(response) && !angular.isObject(response[0]) && !angular.isObject(response[1])) {
                    success = true;
                    $scope.campus.latitude = response[0];
                    $scope.campus.longitude = response[1];
                    SharedProfileFactory.setMapUpdateRequest(Math.random());
                    SharedProfileFactory.announceCampusesChanges($scope.campus.id);
                    controller.coordinatesUpdated('success', 'Coordinates updated successfully!');
                } else {
                    controller.coordinatesUpdated('warning', 'Address not found!');
                }
            } else {
                controller.coordinatesUpdated('error', 'Error requesting coordinates!');
            }
            $scope.refreshMapInProgress = false;
        };

        /**
         * Refresh map function
         */
        controller.refreshMap = function () {
            $scope.refreshMapInProgress = true;
            if ($scope.campus.autoGenerate) { // generate map by address fields
                CampusesService.getCoordinatesByCampus($scope.campus).then(controller.getCoordinatesByCampusCallback);
            } else {  // generate map by coordinates
                // announce map reload request
                SharedProfileFactory.setMapUpdateRequest(Math.random());
                //SharedProfileFactory.announceCampusesChanges($scope.campus.id);
                $scope.refreshMapInProgress = false;
            }
        };

        /**
         * Display on front end switch clicked.
         */
        controller.displayOnFrontEndClick = function () {
            if ($scope.campus.displayInFrontEnd &&
                (($scope.campus.longitude === null || !$scope.campus.longitude) ||
                ($scope.campus.latitude === null || !$scope.campus.latitude))
            ) {
                $scope.campus.autoGenerate = true;
            }
        };

        controller.initWatches = function () {
            // watch for edit mode changes
            WatchService.create($scope, SharedProfileFactory.isCampusEditForm, function (newValue, oldValue) {
                if (newValue !== oldValue) {
                    controller.editToggle(newValue);
                }
            });

            WatchService.create($scope, SharedProfileFactory.getCampus, function (newValue, oldValue) {
                if (newValue !== oldValue) {
                    $scope.campus = newValue;
                    controller.resetCampusForm();
                }
            });

            WatchService.create($scope, InstitutionFactory.getCoreId, function (newValue) {
                $scope.institutionId = InstitutionFactory.getId();
            });
        };

        /**
         * Class constructor.
         */
        controller.init = function () {
            controller.initWatches();
        };

        controller.init();
    }
}(window.angular));

(function(angular) {
    "use strict";

    angular
        .module('qsHub')
        .service('CampusesService', [
            '$resource',
            '$q',
            '$log',
            'constants',
            'InstitutionsListService',
            'InstitutionsService',
            'TmProfileService',
            'InstitutionFactory',
            'DataHandlerFactory',
            'orderByFilter',
            'RequestsQueueService',
            Service
        ]);

    function Service(
        $resource,
        $q,
        $log,
        constants,
        InstitutionsListService,
        InstitutionsService,
        TmProfileService,
        InstitutionFactory,
        DataHandlerFactory,
        orderBy,
        RequestsQueueService
    ) {
        var service = {
            deferred: {
                getCampusList : null
            }
        };

        /**
         * Get countries list.
         *
         * @returns {Promise.<Array>}
         */
        service.getCountries = function () {
            return InstitutionsListService.getCountries();
        };

        /**
         * Get TU regions list.
         */
        service.getTuRegions = function () {
            return InstitutionsListService.getTuRegions();
        };

        service.getCampusList = function (params, noCache) {
            var coreId = params.parameters().coreId;
            // process already running
            if (!service.deferred.getCampusList) {
                service.deferred.getCampusList = $q.defer();
            } else {
                return service.deferred.getCampusList.promise;
            }
            var deferred = service.deferred.getCampusList,
                campuses = null,
                total = 0;
            if (!noCache) {
                // check if campuses are already loaded
                campuses = InstitutionFactory.getCampuses();
                if (campuses) {
                    campuses = DataHandlerFactory.objectToArray(campuses);
                    total = campuses.length;
                    params.total(total);
                    params.totalEntries = total;
                    deferred.resolve(service.formatCampusList(campuses));
                    service.deferred.getCampusList = null;
                } else {
                    service.deferred.getCampusList = null;
                    deferred.resolve([]);
                }
            }

            if (!campuses) {
                InstitutionsService.getInstitutionData(coreId, true).then(function (data) {
                    if (data.results && data.results[0] && data.results[0].campus) {
                        data.results[0].campus = DataHandlerFactory.objectToArray(data.results[0].campus);
                        total = data.results[0].campus.length;
                        params.total(total);
                        params.totalEntries = total;
                        var formatedCampusList = service.formatCampusList(data.results[0].campus);
                        InstitutionFactory.setCampuses(formatedCampusList);
                        TmProfileService.setCampusesCache(formatedCampusList,coreId);
                        deferred.resolve(formatedCampusList);
                        service.deferred.getCampusList = null;
                    } else {
                        service.deferred.getCampusList = null;
                        deferred.resolve([]);
                    }
                });
            }

            return deferred.promise;
        };

        service.formatCampusList = function (campusList) {
            var formatedCampusList = [];
            angular.forEach(campusList, function(campus) {
                InstitutionsListService.getCountryNameByCode(campus.country).then(function (countryName) {
                    campus.countryName = countryName;
                });
                campus.addressLine = InstitutionsListService.getCampusAddress(campus);
                campus.primaryName = InstitutionsListService.getCampusIsPrimary(campus);
                formatedCampusList.push(campus);
            });

            return orderBy(formatedCampusList, 'order', false);
        };

        service.getCreateModel = function () {
            return $resource(constants.api.institutions.url + '/v1/institution/:institutionId/campus');
        };

        service.create = function (institutionId, campusData) {
            var CreateModel = service.getCreateModel(),
                dataToInsert = {
                    name: null,
                    addressLine1: null,
                    addressLine2: null,
                    addressLine3: null,
                    postcode: null,
                    city: null,
                    state:null,
                    country: null,
                    primary: null,
                    displayInFrontEnd: null,
                    region: null,
                    latitude: null,
                    longitude: null,
                    autoGenerate: null,
                };
            return CreateModel.save(
                {institutionId: institutionId},
                service.filterObject(dataToInsert, campusData)
            ).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return data;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        service.deleteCampus = function (institutionId, campusId) {
            var CampusModel = InstitutionsListService.getCampusModel();

            return CampusModel.delete({
                    id: institutionId,
                    campusId: campusId
                }).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });

        };

        service.deleteCampuses = function (institutionId, campusIds) {
            var CampusModel = InstitutionsListService.getDeleteCampusesModel();

            return CampusModel.save({
                    id: institutionId
                }, campusIds).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });

        };

        service.update = function (institutionId, campusData) {
            var CreateModel = InstitutionsListService.getCampusModel(),
                dataToUpdate = {
                    name: null,
                    addressLine1: null,
                    addressLine2: null,
                    postcode: null,
                    city: null,
                    state:null,
                    country: null,
                    primary: null,
                    displayInFrontEnd: null,
                    region: null,
                    latitude: null,
                    longitude: null,
                    autoGenerate: null,
                };
            return CreateModel.update(
                {
                    id: institutionId,
                    campusId: campusData.id
                },
                service.filterObject(dataToUpdate, campusData)
            ).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return data;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        /**
         * Fill one object keys with other object values.
         *
         * @param {Object} filters
         * @param {Object} object
         * @returns {Object}
         */
        service.filterObject = function (filters, object) {
            var filteredObject = angular.copy(filters);
            angular.forEach(filters, function (value, key) {
                if (value && typeof value === 'object') {
                    angular.forEach(value, function (subValue, subKey) {
                        if (subValue === null && object[key][subKey] !== 'undefined') {
                            filteredObject[key][subKey] = object[key][subKey];
                        }
                    });
                } else if (value === null && object[key] !== 'undefined') {
                    filteredObject[key] = object[key];
                }
            });

            return filteredObject;
        };

        service.getCoordinatesModel = function () {
            return $resource(constants.api.institutions.url + '/v1/get-coordinates/:searchString', {}, {
                get: {
                    isArray: true,
                    cancellable : false
                }
            });
        };

        /**
         * Combine campus address info to google api search string.
         *
         * @param {Array} campus
         * @returns {string}
         */
        service.getSearchString = function (campus) {
            var searchValues = [],
                searchFields = ['addressLine1', 'addressLine2', 'city', 'postcode', 'country'];
            if (campus) {
                angular.forEach(searchFields, function (value) {
                    if (campus.hasOwnProperty(value) && campus[value] && campus[value].length > 0) {
                        searchValues.push(campus[value].trim());
                    }
                });
            }

            return searchValues.join(' ');
        };

        /**
         * Get coordinates by campus
         *
         * @param {Object} campus
         * @returns {false|array}
         */
        service.getCoordinatesByCampus = function (campus) {
            var searchFields = service.getSearchString(campus);
            if (!searchFields.length) {
                var deferred = $q.defer();
                deferred.resolve(false);
                return deferred.promise;
            }
            return service.getCoordinatesModel().get({searchString : searchFields}).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return data;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        function getModel() {
            return $resource(constants.api.institutions.url, {}, {
                getCoursesCounters: {
                    method: 'POST',
                    url:  constants.api.institutions.url + '/v1/courses-per-campus',
                    isArray: false,
                    cancellable: true
                },
                getTmProgramsCounters: {
                    method: 'GET',
                    url:  constants.api.institutions.url + '/v1/list/tm-programs',
                    isArray: false,
                    cancellable: true
                }
            });
        }

        service.getCoursesCounters = function (ids) {
            var key = 'CampusesService::getCoursesCounters',
                Api;

            RequestsQueueService.cancelAll(key);
            Api = getModel().getCoursesCounters(ids);
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                return data.results;
            }, function () {
                return false;
            });
        };

        service.getTmProgramsCounters = function (ids) {
            var key = 'CampusesService::getTmProgramsCounters',
                Api;

            RequestsQueueService.cancelAll(key);
            Api = getModel().getTmProgramsCounters({
                 'filter[campus]': ids.toString(),
                 'columns[]':'campus'
                });
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                return data.results;
            }, function () {
                return false;
            });
        };

        /**
         * Regex to check if only ASCII characters
         *
         * @param {string} value
         * @returns true or false
         */
        service.isValidAsciiCharacters = function (value) {
            var utf8 = new RegExp(/[\x00-\x1F\x7F\x80-\x9f]/g);

            return value && utf8.test(value) ? false : true;
        };

        function isValidNumber (value) {
            var regexp = new RegExp(/^\-?[0-9]\d*(\.\d+)?$/g); 
            
            return regexp.test(value);
        }

        service.isValidLatitude = function (value) {
            var lat = parseFloat(value);

            return value ? ((isValidNumber(value) && lat) ? lat >= -90 && lat <= 90 : false) : true;
        };

        service.isValidLongitude = function (value) {
            var lng = parseFloat(value);
            
            return value ? ((isValidNumber(value) && lng) ? lng >= -180 && lng <= 180 : false) : true;
        };

        return service;
    }
}(window.angular));

(function(angular) {
    "use strict";

    angular
        .module('qsHub')
        .controller('TuProfileController', [
            '$scope',
            '$state',
            '$rootScope',
            '$location',
            'constants',
            'TuProfileService',
            'NotifierFactory',
            'TuProfileFactory',
            'TuProfileHistoryLogFactory',
            'InstitutionFactory',
            'UserFactory',
            'WatchService',
            'TuProfileDepartmentsService',
            '$timeout',
            'MatchingToolFactory',
            'UnsavedChangesFactory',
            'WebSocketsService',
            'InstitutionsService',
            TuProfile
        ]);

    function TuProfile(
        $scope,
        $state,
        $rootScope,
        $location,
        constants,
        TuProfileService,
        NotifierFactory,
        TuProfileFactory,
        TuProfileHistoryLogFactory,
        InstitutionFactory,
        UserFactory,
        WatchService,
        TuProfileDepartmentsService,
        $timeout,
        MatchingToolFactory,
        UnsavedChangesFactory,
        WebSocketsService,
        InstitutionsService
    ) {
        var controller = this;
        controller.devMode = constants.dev;
        $scope.upgradeEmailsTo = constants.emails.upgradeTu;
        controller.subjects = {};
        controller.scrollTabsApi = {};
        controller.isClient = UserFactory.isClient();

        $scope.institutionDataLoaded = false;
        $scope.profileDataLoaded = false;
        $scope.tuProfile = {};
        $scope.upgradeRequest = {
            name: UserFactory.getFullName(),
            email: UserFactory.getUserName()
        };
        $scope.userData = UserFactory.getData();
        $scope.tuProfileBeforeChanges = {};
        $scope.forms = {};
        $scope.showUpgradeForm = false;
        $scope.showHistory = false;
        $scope.tuIsAdvanced = false;
        $scope.crimsonIsAdvanced  = false;
        $scope.basicProgramsEnabled = false;
        $scope.upgradeInProgress = false;
        $scope.historyLog = {
            log: {},
            totalReturned: 0,
            total: 0
        };
        $scope.showClientUpgradeForm = false;

        function handleTabsScrolling () {
            if (controller.scrollTabsApi.doRecalculate) {
                controller.scrollTabsApi.doRecalculate();
                controller.scrollTabsApi.scrollTabIntoView();
            }
        }

        $scope.handleHistoryLogVisibility = function() {
            return TuProfileHistoryLogFactory.getLogLimit() < $scope.historyLog.total;
        };

        $scope.handleLoadMoreHistoryLog = function() {
            TuProfileHistoryLogFactory.setLogLimit(TuProfileHistoryLogFactory.getLogLimit() + 5);
            //broadcast load more history log event
            $scope.$broadcast(
                constants.events.loadMoreHistoryLogs,
                TuProfileHistoryLogFactory.getProfileType(),
                InstitutionFactory.isTuAdvanced(),
                TuProfileHistoryLogFactory.getLogLimit()
            );
        };

        /**
         * Is all data loaded?
         *
         * @returns {boolean}
         */
        $scope.loadInProgress = function () {
            return $scope.institutionDataLoaded && $scope.profileDataLoaded ? false : true;
        };

        // load TU profile data
        controller.loadTuProfileData = function (coreId) {
            TuProfileService.getTuProfileData(coreId, TuProfileFactory.isTuMediaUpdated() ? false : true).then(function (data) {
                $scope.profileDataLoaded = true;
                $scope.tuProfile = data;
                TuProfileFactory.setData(angular.copy(data));
                $scope.tuProfileBeforeChanges = angular.copy(data);
                TuProfileFactory.setTuMediaUpdated(false);
                $scope.ugOrPgDisabled = !(InstitutionFactory.isUgEnabled() && InstitutionFactory.isPgEnabled());
                $scope.profileDisableName = !InstitutionFactory.isUgEnabled() && !InstitutionFactory.isPgEnabled() ?
                    'Undergraduate and Postgraduate' : (!InstitutionFactory.isUgEnabled() ? 'Undergraduate' : 'Postgraduate');
                TuProfileFactory.setRequestInfoTypeMasterUrl(
                    $scope.tuProfile.requestInfoTypeMaster ? $scope.tuProfile.requestInfoTypeMaster === 'url' : false
                );
                TuProfileFactory.setRequestInfoTypeUgUrl(
                    $scope.tuProfile.requestInfoTypeUg ? $scope.tuProfile.requestInfoTypeUg === 'url' : false
                );
                TuProfileFactory.setRequestInfoTypePgUrl(
                    $scope.tuProfile.requestInfoTypePg ? $scope.tuProfile.requestInfoTypePg === 'url' : false
                );
                $scope.$broadcast(constants.events.institutionTuProfileChanges);
            });
        };

        /**
         * Returns whether to disable the profile or not based on type
         *
         * @param {string} type
         * @returns {Boolean}
         */
        $scope.isProfileEnabled = function (type) {
            if (type === constants.profileTypes.ug) {
                return !InstitutionFactory.isUgEnabled();
            } else if (type === constants.profileTypes.pg) {
                return !InstitutionFactory.isPgEnabled();
            } else if (type === constants.profileTypes.master) {
                return !InstitutionFactory.isUgEnabled() &&
                       !InstitutionFactory.isPgEnabled();
            } else if (type === constants.profileTypes.ugChina) {
                return !InstitutionFactory.isUgChinaEnabled();
            } else if (type === constants.profileTypes.pgChina) {
                return !InstitutionFactory.isPgChinaEnabled();
            } else if (type === constants.profileTypes.masterChina) {
                return !InstitutionFactory.isUgChinaEnabled() &&
                       !InstitutionFactory.isPgChinaEnabled();
            }
            return false;
        };

        /**
         * Toggle upgrade form.
         */
        $scope.toggleUpgradeForm = function () {
            TuProfileFactory.setUpgradeFormVisibility();
        };

        $scope.toggleDepartmentEditForm = function () {
            TuProfileFactory.setDepartmentEditFormVisibility();
        };

        $scope.toggleDepartmentAddForm = function () {
            TuProfileFactory.setDepartmentAddFormVisibility();
        };

        /**
         * Toggle history details block.
         */
        $scope.toggleHistory = function (visibility) {
            if (!visibility) {
                TuProfileHistoryLogFactory.setTriggeredBy('');
                TuProfileHistoryLogFactory.setLogLimit(1);
            }
            TuProfileFactory.setHistoryLogVisibility(visibility);
        };

        /**
         * Get status of history log.
         *
         * @returns {bool}
         */
        $scope.fetchingHistoryLog = function () {
            return TuProfileHistoryLogFactory.isInProgress();
        };

        /**
         * Get status of loading more history log .
         *
         * @returns {bool}
         */
        $scope.fetchingMoreHistoryLog = function () {
            return TuProfileHistoryLogFactory.isLoadMoreInProgress();
        };

        $scope.showDepartmentsTab =  function () {
            var showDepartmentsTab = !InstitutionFactory.isClientDepartment() && !InstitutionFactory.hasNoDepartments();
            if (!showDepartmentsTab && TuProfileFactory.isDepartmentsTabSelected()) {
                $scope.activeTab = TuProfileFactory.getProfileTabs().overview;
            }
            return showDepartmentsTab;
        };

        controller.closeMatchingToolRightSidePanel = function() {
            if(!MatchingToolFactory.getIsCourseCloned()) {
                MatchingToolFactory.setIsAddProgramClick('true');
            }
            if (UnsavedChangesFactory.hasChanges()) {
                MatchingToolFactory.setUnsavedChanges(true);
            } else {
                TuProfileFactory.setMatchingToolRightSidePanelActive(false);
            }
        };

        $scope.isRightSidePanelActive = function () {
            // subjects tab is active
            if (TuProfileFactory.isSubjectsTabSelected()) {
                return true;
            }
            // publish tab is active
            if (TuProfileFactory.isPublishTabSelected()) {
                return true;
            }
            // upgrade form is visible
            if (
                $scope.showUpgradeForm &&
                (
                    TuProfileFactory.isOverviewTabSelected() ||
                    TuProfileFactory.isProgramsTabSelected() ||
                    TuProfileFactory.isMediaTabSelected() ||
                    TuProfileFactory.isDepartmentsTabSelected()
                )
            ) {
                return true;
            }
            // overview tab and history block is active
            if (TuProfileFactory.isOverviewTabSelected() && $scope.showHistory) {
                return true;
            }
            // departments tab is active and edit or add form is active
            if (
                TuProfileFactory.isDepartmentsTabSelected() &&
                (
                    $scope.showDepartmentEditForm ||
                    $scope.showDepartmentAddForm ||
                    $scope.showClientUpgradeForm
                )
            ) {
                return true;
            }
            // programs tab is active and edit or add form is active
            if (TuProfileFactory.isProgramsTabSelected() && ($scope.showProgramEditForm || $scope.showProgramAddForm)) {
                return true;
            }
            if (TuProfileFactory.isMatchingToolTabSelected() && controller.isMatchingToolRightSidePanelActive()) {
                return true;
            }
            // media tab is active
            if (TuProfileFactory.isMediaTabSelected()) {
                return true;
            }
            return false;
        };

        /**
         *
         * @param {Object} log
         */
        controller.isRequestInfoTypeUrl = function (log) {
            var activeOverviewSubTabs = TuProfileFactory.getActiveOverviewSubTabs();
            var searchField = 'requestInfoTypeMaster';
            if (activeOverviewSubTabs.ug) {
                searchField = 'requestInfoTypeUg';
            } else if (activeOverviewSubTabs.pg) {
                searchField = 'requestInfoTypePg';
            }

            return log[searchField] === 'url';
        };

        $scope.isSubjectsTabActive = function () {
            return TuProfileFactory.isSubjectsTabSelected();
        };

        $scope.isMediaTabActive = function () {
            return TuProfileFactory.isMediaTabSelected();
        };

        $scope.isPublishTabActive = function () {
            return TuProfileFactory.isPublishTabSelected();
        };

        controller.isMatchingToolRightSidePanelActive = function () {
            return TuProfileFactory.isMatchingToolRightSidePanelActive();
        };

        controller.handleSendUpgradeRequestClick = function () {
            controller.upgradeInProgress = true;
            TuProfileDepartmentsService.sendUpgradeRequest(
                $scope.upgradeRequest.department.id,
                $scope.upgradeRequest.comments
            ).then(controller.upgradeCallback);
        };

        controller.upgradeCallback = function (success) {
            controller.upgradeInProgress = false;

            NotifierFactory.show(
                success ? 'success' : 'error',
                success ? 'Upgraded Request sent successfully!' : 'Upgrade failed!',
                'Upgrade to Client'
            );

            if (success) {
                // close edit mode
                TuProfileFactory.setClientDepartmentUpgradeFormVisibility(false);
                TuProfileFactory.setDepartmentEditFormVisibility(false);
            }
        };

        controller.subscribeDataUpdate = function (data) {
            var split = data.split(':');
            if (split.length === 2 && split[0] === 'TuProfile') {
                var coreId = parseInt(split[1]);
                if (angular.equals(InstitutionFactory.getCoreId(), coreId)) {
                    TuProfileService.cleanCacheKey(coreId);
                    InstitutionsService.getInstitutionData(coreId, false, true).then(function (data) {
                        var institutionData = data && data.results ? data.results[0] : null;
                        if (institutionData) {
                            InstitutionFactory.setData(institutionData);
                        }
                    });
                }
            }
        };

        controller.initWatches = function () {
            WatchService.create($scope, InstitutionFactory.isTuAdvanced, function (newValue) {
                $scope.tuIsAdvanced = newValue;
            });
            WatchService.create($scope, InstitutionFactory.isCrimsonAdvanced, function (newValue) {
                $scope.crimsonIsAdvanced = newValue;
            });
            WatchService.create($scope, InstitutionFactory.isCrimsonBasic, function (newValue) {
                $scope.crimsonIsBasic = newValue;
            });
            WatchService.create($scope, InstitutionFactory.isBasicProgramsEnabled, function (newValue) {
                $scope.basicProgramsEnabled = newValue;
            });
            WatchService.create($scope, TuProfileFactory.isMatchingToolRightSidePanelActive, function (newValue) {
               $timeout(function() {
                    handleTabsScrolling();
                });
            });
            // listen to active profile tab changes
            WatchService.create($scope, function () {
                return $scope.activeTab;
            }, function (newValue) {
                $timeout(function() {
                    handleTabsScrolling();
                });
                controller.tuActiveTab = newValue;
                TuProfileFactory.setSelectedTabId(newValue);
                TuProfileFactory.closeSidebars();
                TuProfileHistoryLogFactory.resetTriggeredBy();

            });

            // watch active institution changes
            WatchService.create($scope, InstitutionFactory.getData, function (institutionData) {
                /**
                 * Fixes QCR-1404 #additionalScenario1
                 */
                TuProfileFactory.setHistoryLogVisibility(false);
                TuProfileHistoryLogFactory.setTriggeredBy('');
                if (InstitutionFactory.isEmpty()) {
                    return false;
                }
                $scope.institutionDataLoaded = true;
                $scope.institutionId = InstitutionFactory.getId();
                $scope.institutionData = institutionData;
                $scope.tuIsAdvanced = InstitutionFactory.isTuAdvanced();
                $scope.crimsonIsAdvanced = InstitutionFactory.isCrimsonAdvanced();
                $scope.basicProgramsEnabled = InstitutionFactory.isBasicProgramsEnabled();
                // check if TU is not enabled, redirect user to default client page.
                if (!InstitutionFactory.isTuSubscribed() && !UserFactory.isClient()) {
                    $location.path(constants.defaultClientPage);

                    return false;
                }
                if (angular.isDefined($state) && InstitutionFactory.isTuSubscribed() && !UserFactory.noAccess($state.current.name)) {
                    controller.loadTuProfileData(InstitutionFactory.getCoreId());
                } else {
                    $scope.tuProfile = {};
                    // if user doesn't have access, empty previously loaded data
                    $scope.profileDataLoaded = true;
                    TuProfileFactory.setData(null);
                    $scope.$broadcast(constants.events.institutionTuProfileChanges);
                }
            });

            // watch for upgrade form visibility changes
            WatchService.create($scope, function () {
                return TuProfileFactory.isUpgradeFormVisible($scope.activeTab);
            }, function (newValue, oldValue) {
                if (newValue !== oldValue) {
                    $scope.upgradeRequest.comments = null;
                    $scope.showUpgradeForm = newValue;
                }
            });

            // watch for history log visibility changes
            WatchService.create($scope, function () {
                return TuProfileFactory.isHistoryLogVisible($scope.activeTab);
            }, function (newValue, oldValue) {
                if (newValue !== oldValue) {
                    $scope.showHistory = newValue;
                }
            });

            // watch for program add form visibility changes
            WatchService.create($scope, function () {
                return TuProfileFactory.isProgramAddFormVisible($scope.activeTab);
            }, function (newValue, oldValue) {
                if (newValue !== oldValue) {
                    $scope.showProgramAddForm = newValue;
                }
            });

            // watch for program edit form visibility changes
            WatchService.create($scope, function () {
                return TuProfileFactory.isProgramEditFormVisible($scope.activeTab);
            }, function (newValue, oldValue) {
                if (newValue !== oldValue) {
                    $scope.showProgramEditForm = newValue;
                }
            });

            // watch for department add form visibility changes
            WatchService.create($scope, function () {
                return TuProfileFactory.isDepartmentAddFormVisible($scope.activeTab);
            }, function (newValue, oldValue) {
                if (newValue !== oldValue) {
                    $scope.showDepartmentAddForm = newValue;
                }
            });

            // watch for department edit form visibility changes
            WatchService.create($scope, function () {
                return TuProfileFactory.isDepartmentEditFormVisible($scope.activeTab);
            }, function (newValue, oldValue) {
                if (newValue !== oldValue) {
                    $scope.showDepartmentEditForm = newValue;
                }
            });

            WatchService.create($scope, TuProfileFactory.getAvailableSubjects, function (newValue, oldValue) {
                if (newValue !== oldValue || !angular.equals(controller.subjects, newValue)) {
                    controller.subjects = newValue;
                }
            });

            // watch for history log data changes
            WatchService.create($scope, TuProfileHistoryLogFactory.getData, function (newValue, oldValue) {
                if (!angular.equals(newValue, oldValue)) {
                    if (newValue) {
                        $scope.isAdvanced = TuProfileHistoryLogFactory.isAdvanced();
                        $scope.historyLog.total = newValue.totalMatching;
                        $scope.historyLog.totalReturned = newValue.totalFiltered;
                        $scope.historyLog.log = newValue.results;
                    }
                }
            });
            // watch for client upgrade form visibility changes
            WatchService.create($scope, function () {
                return TuProfileFactory.isClientDepartmentUpgradeFormVisible($scope.activeTab);
            }, function(newValue, oldValue) {
                if (newValue !== oldValue) {
                    $scope.upgradeRequest = {
                        department : TuProfileFactory.getDepartment(),
                        comments: null,
                        name: UserFactory.getFullName(),
                        email: UserFactory.getUserName()
                    };
                    $scope.showClientUpgradeForm = newValue;
                }
            });

            WatchService.create($scope, function () {
                return $state.$current.name;
            }, function (newValue, oldValue) {
                if (newValue !== oldValue) {
                    controller.initActiveTab();
                }
            });

            if (!UserFactory.getSubscribedToDataUpdateTu()) {
                WebSocketsService.subscribe('data', 'update', controller.subscribeDataUpdate);
                UserFactory.setSubscribedToDataUpdateTu(true);
            }

            $rootScope.$on(constants.events.logout, controller.destruct);
        };

        controller.destruct = function () {
            TuProfileFactory.reset();
            $scope.showDepartmentEditForm = false;
            $scope.showDepartmentAddForm = false;
            $scope.showProgramEditForm = false;
            $scope.showDProgramAddForm = false;
        };

        controller.initActiveTab = function () {
            switch ($state.current.name) {
                case 'clients.profiles.tu.departments':
                    $scope.activeTab = TuProfileFactory.getProfileTabs().departments;
                    break;
                case 'clients.profiles.tu.subjects':
                    $scope.activeTab = TuProfileFactory.getProfileTabs().subjects;
                    break;
                case 'clients.profiles.tu.programs':
                    $scope.activeTab = TuProfileFactory.getProfileTabs().programs;
                    break;
                case 'clients.profiles.tu.media':
                    $scope.activeTab = TuProfileFactory.getProfileTabs().media;
                    break;
                case 'clients.profiles.tu.publish':
                    $scope.activeTab = TuProfileFactory.getProfileTabs().publish;
                    break;
                case 'clients.profiles.tu.matchingTool':
                    $scope.activeTab = TuProfileFactory.getProfileTabs().matchingTool;
                    break;
                case 'clients.profiles.tu.entryRequirements':
                    $scope.activeTab = TuProfileFactory.getProfileTabs().entryRequirements;
                    break;
                case 'clients.profiles.tu.overview':
                /* falls through */
                default:
                    $scope.activeTab = TuProfileFactory.getProfileTabs().overview;
                    break;
            }
            controller.tuActiveTab = $scope.activeTab;
        };

        controller.isMasterProfile = function () {
            return TuProfileHistoryLogFactory.getProfileType() === 'master';
        };

        controller.$onInit = function () {
            controller.initWatches();
            controller.initActiveTab();
        };
    }

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    App.services.TuProfileService = function (
        $resource,
        $q,
        $log,
        constants
    ) {
        var service = {
            overviewData : [],
            institutionData: null,
            tuOverviewHistory : null,
            deferred : {
                getOverviewData: null,
                getInstitutionData: null,
                getTuOverviewHistory: null
            },
            belongsTo: {
                all : 'all',
                ug: 'ug',
                pg: 'pg',
                mba: 'mba',
                phd:'phd'
            },
            cache: {}
        };

        /**
         * Get institutions list API endpoint.
         *
         * @returns {$resource}
         */
        service.getInstitutionListModel = function () {
            return $resource(constants.api.institutions.url + '/v1/list/all/:id');
        };

        /**
         * Get institution API endpoint.
         *
         * @returns {$resource}
         */
        service.getTuProfileModel = function () {
            return $resource(constants.api.institutions.url + '/v1/tu-profile/:id', null, {
                 update: { method:'PATCH' }
            });
        };

        /**
         * Get institutions  API endpoint.
         *
         * @returns {$resource}
         */
        service.getInstitutionModel = function () {
            return $resource(constants.api.institutions.url + '/v1/institution/:id', null, {
                update: { method:'PATCH' }
            });
        };

        /**
         * Get tu profile upgrade requests end point.
         *
         * @returns {$resource}
         */
        service.getUpgradeRequestModel = function () {
            return $resource(constants.api.institutions.url + '/v1/tu-profile/:id/upgrade-request');
        };

        /**
         * Update tuProfile data.
         *
         * @param {string} id
         * @param {Object} data
         * @returns {Promise}
         */
        service.updateTuProfile = function (id, data) {
            // @todo do validation
            var TuProfileModel = service.getTuProfileModel();
            return TuProfileModel.update(
                {id: id},
                data
            ).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };


        /**
         * Update institution data.
         *
         * @param {string} id
         * @param {Object} data
         * @returns {Promise}
         */
        service.updateInstitution = function (id, data) {
            // @todo do validation
            var InstitutionModel = service.getInstitutionModel();
            return InstitutionModel.update(
                {id: id},
                data
            ).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        /**
         * Get institution profile API endpoint by Core ID.
         *
         * @param {int} coreId
         * @returns {$resource}
         */
        service.getTuByCoreIdModel = function () {
            return $resource(constants.api.institutions.url + '/v1/tu-profile/core-id/:id', null, {
                get: {
                    cancellable: true
                }
            });
        };
        
        /**
         * Get institution TU profile data by Core ID.
         *
         * @param {int} coreId
         * @param useCache
         * @returns {Promise}
         */
        service.getTuProfileData = function (coreId, useCache) {
            if (!coreId) {
                throw 'Missing Required';
            }
            useCache = useCache || false;

            var Api = service.getTuByCoreIdModel(coreId);

            var deferred = $q.defer(),
                cacheKey = 'TuProfile:' + coreId;
            if (useCache && service.cache[cacheKey]) {
                deferred = $q.defer();
                deferred.resolve(service.cache[cacheKey]);
                return deferred.promise;
            }

            return Api.get({id: coreId}).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                service.cache[cacheKey] = data.toJSON();
                return data.toJSON();
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return [];
            });
        };

        service.cleanCacheKey = function (coreId) {
            var cacheKey = 'TuProfile:' + coreId;
            if (service.cache[cacheKey]) {
                delete(service.cache[cacheKey]);
            }
        };

        /**
         * Send TU profile upgrade request.
         *
         * @param {String} tuProfileId
         * @param {String|null} comments
         * @returns {Promise}
         */
        service.sendUpgradeRequest = function (tuProfileId, comments) {
            if (!tuProfileId) {
                throw 'Missing Required';
            }

            var Api = service.getUpgradeRequestModel();
            return Api.save({id: tuProfileId}, {
                comments: comments
            }).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }

                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        /**
         * Get belongs to list.
         *
         * @returns {array}
         */
        service.getBelongsToList = function (grid) {
            grid = grid || false;

            return grid ? [
                {value: service.belongsTo.all, label: 'All'},
                {value: service.belongsTo.ug, label: 'Undergraduate'},
                {value: service.belongsTo.pg, label: 'Postgraduate'},
                {value: service.belongsTo.mba, label: 'MBA'}
            ] : [
                {id: service.belongsTo.all, title: 'All'},
                {id: service.belongsTo.ug, title: 'Undergraduate'},
                {id: service.belongsTo.pg, title: 'Postgraduate'},
                {id: service.belongsTo.mba, title: 'MBA'},
                {id: service.belongsTo.phd, title: 'PhD'}
            ];
        };

        /**
         * Get belongs to name by ID.
         *
         * @param {int} belongsTo
         * @returns {string}
         */
        service.getBelongsToName = function (belongsTo) {
            switch (belongsTo) {
                case service.belongsTo.ug:
                    return 'Undergraduate';
                case service.belongsTo.pg:
                    return 'Postgraduate';
                default:
                    return 'All';
            }
        };

        return service;
    };

    angular
        .module('qsHub')
        .service('TuProfileService', [
            '$resource',
            '$q',
            '$log',
            'constants',
            App.services.TuProfileService
        ]);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { controllers: {} });

    App.controllers.TuProfileOverview = function (
        $scope,
        $timeout,
        constants,
        TuProfileOverviewService,
        UrlService,
        ObjectService,
        TextService,
        NotifierFactory,
        TuProfileFactory,
        TuProfileHistoryLogFactory,
        WatchService,
        InstitutionFactory,
        UserFactory,
        PasswordService
    ) {
        var controller = this,
            alreadyInitialised = false;
        controller.devMode = constants.dev;
        controller.textEditorAdvancedOptions = {
            disableDragAndDrop: true,
            styleWithSpan: false,
            dialogsInBody: true,
            minHeight: 280,
            maxHeight: 280,
            toolbar: [
                ['style', ['bold', 'italic', 'underline', 'superscript', 'subscript', 'strikethrough']],
                ['alignment', ['ul', 'ol']],
                ['edit', ['undo', 'redo']],
                ['insert',['link','unlink']],
                ['view', ['codeview']]
            ],
            popover: {
                link: []
            }
        };
        controller.textEditorBasicOptions = angular.copy(controller.textEditorAdvancedOptions);
        controller.textEditorBasicOptions.toolbar.splice(3, 1);
        controller.isClient = UserFactory.isClient();

        controller.handleClipboardPaste = function (modelName) {
            $timeout(function() {
                if ($scope.tuIsAdvanced) {
                    $scope.tuProfile[modelName] = TextService.clearAttributes($scope.tuProfile[modelName]);
                } else {
                    $scope.tuProfile[modelName] = TextService.clearAttributes(TextService.removeAnchorTag($scope.tuProfile[modelName]));
                }
            }, 65);
        };

        controller.advancedDescriptionWordLimit = constants.profileOverview.tu.advanced.maxWords;

        controller.advancedMasterOverviewWords = 0;
        controller.advancedMasterFacilitiesWords = 0;
        controller.advancedMasterStudentLifeWords = 0;
        controller.advancedMasterAdmissionsWords = 0;
        controller.advancedMasterCareersWords = 0;
        controller.advancedUgOverviewWords = 0;
        controller.advancedUgFacilitiesWords = 0;
        controller.advancedUgStudentLifeWords = 0;
        controller.advancedUgAdmissionsWords = 0;
        controller.advancedUgCareersWords = 0;
        controller.advancedPgOverviewWords = 0;
        controller.advancedPgFacilitiesWords = 0;
        controller.advancedPgStudentLifeWords = 0;
        controller.advancedPgAdmissionsWords = 0;
        controller.advancedPgCareersWords = 0;
        controller.basicDescriptionWordLimit = constants.profileOverview.tu.basic.maxWords;
        controller.validationMessageBasicDescriptionWordLimit =
            'Profile basic description has exceeded the limit of ' +
            controller.basicDescriptionWordLimit +
            ' words. Please reduce number of words.';
        controller.basicMasterOverviewWords = 0;
        controller.basicUgOverviewWords = 0;
        controller.basicPgOverviewWords = 0;
        controller.submitted = {
            master: false,
            ug: false,
            pg: false
        };

        $scope.forms = {};
        $scope.overviewBasicMasterFormSubmitInProgress = false;
        $scope.overviewBasicUgFormSubmitInProgress = false;
        $scope.overviewBasicPgFormSubmitInProgress = false;
        $scope.activeOverviewSubTab = TuProfileOverviewService.getActiveTabs().master;

        function showValidationNotification (fieldName) {
            NotifierFactory.show(
                'error',
                controller.getValidationMessage(fieldName),
                'Saving failed!'
            );
        }

        controller.isInvalidUrl = function (profileType,urlType) {
            if (!controller.submitted[profileType]) {
                return false;
            }
            var url = $scope.tuProfile[profileType + urlType];
            return url && url.length > 1 && url.length < 7;
        };

        controller.getValidationMessage = function (fieldName) {
            return fieldName+' has exceeded the limit of ' +
            controller.advancedDescriptionWordLimit +
            ' words. Please reduce number of words.';
        };

        //watch for Overview tab active or not
        WatchService.create($scope, "activeOverviewSubTab", function (newValue, oldValue) {
            if ((newValue !== oldValue) && (typeof newValue !== 'undefined')) {
                TuProfileFactory.setActiveOverviewSubTabs(newValue);
                if ($scope.showHistory && (newValue === TuProfileOverviewService.getActiveTabs().master)) {
                    TuProfileHistoryLogFactory.setLogLimit(1);
                    if ($scope.tuIsAdvanced) {
                        $scope.handleTuOverviewHistoryLogClick(
                            'master',
                            true
                        );
                    } else {
                        $scope.handleTuOverviewHistoryLogClick('master');
                    }
                } else if ($scope.showHistory && (newValue === TuProfileOverviewService.getActiveTabs().ug)) {
                    TuProfileHistoryLogFactory.setLogLimit(1);
                    if ($scope.ugIsAdvanced) {
                        $scope.handleTuOverviewHistoryLogClick(
                            'ug',
                            true
                        );
                    }
                    else if ((!$scope.ugIsAdvanced)) {
                        $scope.handleTuOverviewHistoryLogClick('ug');
                    }
                } else if ($scope.showHistory && (newValue === TuProfileOverviewService.getActiveTabs().pg)) {
                    TuProfileHistoryLogFactory.setLogLimit(1);
                    if ($scope.tuIsAdvanced) {
                        $scope.handleTuOverviewHistoryLogClick(
                            'pg',
                            true
                        );
                    } else {
                        $scope.handleTuOverviewHistoryLogClick('pg');
                    }
                }
            }
        });

        // watch active institution coreId changes
        $scope.$watch(InstitutionFactory.getCoreId, function (newValue, oldValue) {
            if (newValue !== oldValue) {
                controller.resetDescriptionForm();
            }
        });

        /**
         * Count words in the string.
         *
         * @param {string} text
         */
        $scope.countWords = function (text) {
            return TextService.countWords(text);
        };

        /**
         * Handle basic master profile submission.
         *
         * @returns {boolean}
         */
        $scope.handleOverviewBasicMasterDataSubmit = function () {
            $scope.isBasicOverviewEmpty = false;
            var cleanString =  TextService.cleanHtmlTags($scope.tuProfile.basicMasterOverview);
            if (typeof ($scope.tuProfile.basicMasterOverview) !== "undefined" &&
                !cleanString ||
                (cleanString && cleanString.length === 0) ||
                $scope.tuProfile.basicMasterOverview === ""
            ) {
                $scope.isBasicOverviewEmpty = true;
            }
            /**
             * Validation 1:
             * Number of words in Basic Description is exceeding the limit of basicDescriptionWordLimit words
             */
            if ($scope.countWords($scope.tuProfile.basicMasterOverview) > controller.basicDescriptionWordLimit) {
                NotifierFactory.show(
                    'error',
                    controller.validationMessageBasicDescriptionWordLimit,
                    'Saving failed!'
                );

                return false;
            }
            if (
                $scope.overviewBasicMasterFormSubmitInProgress ||
                !$scope.forms.overviewBasicMasterForm ||
                !$scope.forms.overviewBasicMasterForm.$valid ||
                $scope.isBasicOverviewEmpty
            ) {
                return false;
            }
            $scope.overviewBasicMasterFormSubmitInProgress = true;

            TuProfileOverviewService.saveOverviewData($scope.tuProfile.id, {
                type: constants.profileTypes.master,
                requestInfoTypeMaster: $scope.tuProfile.requestInfoTypeMaster ? $scope.tuProfile.requestInfoTypeMaster : 'email',
                basicMasterOverview: $scope.tuProfile.basicMasterOverview,
                mtLeadGenFormEmail: $scope.tuProfile.mtLeadGenFormEmail
            }).then(function (success) {
                if ($scope.tuProfile.requestInfoTypeMaster === 'email') {
                    TuProfileFactory.setRequestInfoTypeMasterUrl(false);
                }
                $scope.overviewBasicMasterFormSubmitInProgress = false;
                NotifierFactory.show(
                    success ? 'success' : 'error',
                    success ? 'Saved successfully!' : 'Saving failed!',
                    'Overview Information'
                );
                loadInstantHisotry('master', false);
            });
        };

        $scope.setIsBasicOverviewEmpty = function (value) {
            $scope.isBasicOverviewEmpty = value;
        };

        controller.isValidRequestInfoEmail = function (fieldName) {
            if (($scope.forms.overviewAdvancedMasterForm &&
                $scope.forms.overviewAdvancedMasterForm.$dirty) ||
                ($scope.forms.overviewAdvancedUgForm &&
                $scope.forms.overviewAdvancedUgForm.$dirty) ||
                ($scope.forms.overviewAdvancedPgForm &&
                $scope.forms.overviewAdvancedPgForm.$dirty)
            ) {
                /**
                 * + When it is null means empty
                 * + When it is undefined means not empty but invalid
                 */
               return $scope.tuProfile[fieldName] !== undefined;
            } else {
                return true;
            }
        };

        controller.setValid = function (fieldName) {
            return PasswordService.setValid($scope.forms.overviewAdvancedMasterForm, fieldName);
        };

        controller.setBasicValid = function (fieldName) {
            return PasswordService.setValid($scope.forms.overviewBasicMasterForm, fieldName);
        };

        /**
         * Handle advanced master profile submission.
         *
         * @returns {boolean}
         */
        $scope.handleOverviewAdvancedMasterDataSubmit = function () {
            controller.submitted.master = true;
            $scope.isAdvancedOverviewEmpty = false;
            var cleanString =  TextService.cleanHtmlTags($scope.tuProfile.advancedMasterOverview);
            if (typeof ($scope.tuProfile.advancedMasterOverview) !== "undefined" &&
                !cleanString ||
                (cleanString && cleanString.length === 0) ||
                $scope.tuProfile.advancedMasterOverview === ""
            ) {
                $scope.isAdvancedOverviewEmpty = true;
            }
            /**
             * Validation 1:
             * Number of words in Advanced Description is exceeding the limit of advancedDescriptionWordLimit words
             */
            if ($scope.countWords($scope.tuProfile.advancedMasterOverview) > controller.advancedDescriptionWordLimit) {

                showValidationNotification('About');
                return false;
            }
            /**
             * Validation 2:
             * Number of words in Facilities is exceeding the limit of advancedDescriptionWordLimit words
             */
            if ($scope.countWords($scope.tuProfile.advancedMasterFacilities) > controller.advancedDescriptionWordLimit) {
                showValidationNotification('Facilities');
                return false;
            }
            /**
             * Validation 3:
             * Number of words in Student Life is exceeding the limit of advancedDescriptionWordLimit words
             */
            if ($scope.countWords($scope.tuProfile.advancedMasterStudentLife) > controller.advancedDescriptionWordLimit) {
                showValidationNotification('Student Life');
                return false;
            }
            /**
             * Validation 4:
             * Number of words in Admissions is exceeding the limit of advancedDescriptionWordLimit words
             */
            if ($scope.countWords($scope.tuProfile.advancedMasterAdmissions) > controller.advancedDescriptionWordLimit) {
                showValidationNotification('Admissions');
                return false;
            }
            /**
             * Validation 5:
             * Number of words in Careers is exceeding the limit of advancedDescriptionWordLimit words
             */
            if ($scope.countWords($scope.tuProfile.advancedMasterCareers) > controller.advancedDescriptionWordLimit) {
                showValidationNotification('Careers');
                return false;
            }
            $scope.tuProfile.masterWebsiteUrl = UrlService.prependHttp($scope.tuProfile.masterWebsiteUrl);
            $scope.tuProfile.masterRequestInfoUrl = UrlService.prependHttp($scope.tuProfile.masterRequestInfoUrl);
            if (
                $scope.overviewAdvancedMasterFormSubmitInProgress ||
                !$scope.forms.overviewAdvancedMasterForm ||
                !$scope.forms.overviewAdvancedMasterForm.$valid ||
                $scope.isAdvancedOverviewEmpty ||
                controller.isInvalidUrl('master','WebsiteUrl') ||
                controller.isInvalidUrl('master','RequestInfoUrl')
            ) {
                return false;
            }
            $scope.overviewAdvancedMasterFormSubmitInProgress = true;
            TuProfileOverviewService.saveOverviewData($scope.tuProfile.id, {
                type: constants.profileTypes.master,
                advanced: true,
                requestInfoTypeMaster: $scope.tuProfile.requestInfoTypeMaster ? $scope.tuProfile.requestInfoTypeMaster : 'email',
                advancedMasterOverview: $scope.tuProfile.advancedMasterOverview,
                advancedMasterFacilities:$scope.tuProfile.advancedMasterFacilities,
                advancedMasterStudentLife:$scope.tuProfile.advancedMasterStudentLife,
                advancedMasterAdmissions:$scope.tuProfile.advancedMasterAdmissions,
                advancedMasterCareers:$scope.tuProfile.advancedMasterCareers,
                masterWebsiteUrl: $scope.tuProfile.masterWebsiteUrl,
                masterRequestInfoUrl: $scope.tuProfile.masterRequestInfoUrl,
                masterRequestInfoUrlTitle: $scope.tuProfile.masterRequestInfoUrlTitle,
                masterRequestInfoEmail: $scope.tuProfile.masterRequestInfoEmail,
                mtLeadGenFormEmail: $scope.tuProfile.mtLeadGenFormEmail 
            }).then(function (success) {
                if ($scope.tuProfile.requestInfoTypeMaster === 'email') {
                    TuProfileFactory.setRequestInfoTypeMasterUrl(false);
                }
                $scope.overviewAdvancedMasterFormSubmitInProgress = false;
                NotifierFactory.show(
                    success ? 'success' : 'error',
                    success ? 'Saved successfully!' : 'Saving failed!',
                    'Overview Information'
                );
                loadInstantHisotry('master', true);
            });
        };

        $scope.setIsAdvancedOverviewEmpty = function (value) {
            $scope.isAdvancedOverviewEmpty = value;
        };

        /**
         * Handle basic UG profile submission.
         *
         * @returns {boolean}
         */
        $scope.handleOverviewBasicUgDataSubmit = function () {
            $scope.isBasicUgOverviewEmpty = false;
            var cleanString =  TextService.cleanHtmlTags($scope.tuProfile.basicUgOverview);
            if (typeof ($scope.tuProfile.basicUgOverview) !== "undefined" &&
                !cleanString ||
                (cleanString && cleanString.length === 0) ||
                $scope.tuProfile.basicUgOverview === ""
            ) {
                $scope.isBasicUgOverviewEmpty = true;
            }
            /**
             * Validation 1:
             * Number of words in Basic Description is exceeding the limit of basicDescriptionWordLimit words
             */
            if ($scope.countWords($scope.tuProfile.basicUgOverview) > controller.basicDescriptionWordLimit) {
                NotifierFactory.show(
                    'error',
                    controller.validationMessageBasicDescriptionWordLimit,
                    'Saving failed!'
                );

                return false;
            }
            if (
                $scope.overviewBasicUgFormSubmitInProgress ||
                !$scope.forms.overviewBasicUgForm ||
                !$scope.forms.overviewBasicUgForm.$valid ||
                $scope.isBasicUgOverviewEmpty
            ) {
                return false;
            }
            $scope.overviewBasicUgFormSubmitInProgress = true;

            TuProfileOverviewService.saveOverviewData($scope.tuProfile.id, {
                type: constants.profileTypes.ug,
                basicUgOverview: $scope.tuProfile.basicUgOverview
            }).then(function (success) {
                $scope.overviewBasicUgFormSubmitInProgress = false;
                NotifierFactory.show(
                    success ? 'success' : 'error',
                    success ? 'Saved successfully!' : 'Saving failed!',
                    'Overview Information'
                );
                loadInstantHisotry('ug', false);
            });
        };

        $scope.setIsBasicUgOverviewEmpty = function (value) {
            $scope.isBasicUgOverviewEmpty = value;
        };

        /**
         * Handle advanced UG profile submission.
         *
         * @returns {boolean}
         */
        $scope.handleOverviewAdvancedUgDataSubmit = function () {
            controller.submitted.ug = true;
            $scope.isAdvancedUgOverviewEmpty = false;
            var cleanString =  TextService.cleanHtmlTags($scope.tuProfile.advancedUgOverview);
            if (typeof ($scope.tuProfile.advancedUgOverview) !== "undefined" &&
                !cleanString ||
                (cleanString && cleanString.length === 0) ||
                $scope.tuProfile.advancedUgOverview === ""
            ) {
                $scope.isAdvancedUgOverviewEmpty = true;
            }

            /**
             * Validation 1:
             * Number of words in Advanced Description is exceeding the limit of advancedDescriptionWordLimit words
             */
            if ($scope.countWords($scope.tuProfile.advancedUgOverview) > controller.advancedDescriptionWordLimit) {
                showValidationNotification('Advanced Description');
                return false;
            }
            /**
             * Validation 2:
             * Number of words in Facilities is exceeding the limit of advancedDescriptionWordLimit words
             */
            if ($scope.countWords($scope.tuProfile.advancedUgFacilities) > controller.advancedDescriptionWordLimit) {
                showValidationNotification('Facilities');
                return false;
            }
            /**
             * Validation 3:
             * Number of words in Student Life is exceeding the limit of advancedDescriptionWordLimit words
             */
            if ($scope.countWords($scope.tuProfile.advancedUgStudentLife) > controller.advancedDescriptionWordLimit) {
                showValidationNotification('Student Life');
                return false;
            }
            /**
             * Validation 4:
             * Number of words in Admissions is exceeding the limit of advancedDescriptionWordLimit words
             */
            if ($scope.countWords($scope.tuProfile.advancedUgAdmissions) > controller.advancedDescriptionWordLimit) {
                showValidationNotification('Admissions');
                return false;
            }
            /**
             * Validation 5:
             * Number of words in Careers is exceeding the limit of advancedDescriptionWordLimit words
             */
            if ($scope.countWords($scope.tuProfile.advancedUgCareers) > controller.advancedDescriptionWordLimit) {
                showValidationNotification('Careers');
                return false;
            }
            $scope.tuProfile.ugWebsiteUrl = UrlService.prependHttp($scope.tuProfile.ugWebsiteUrl);
            $scope.tuProfile.ugRequestInfoUrl = UrlService.prependHttp($scope.tuProfile.ugRequestInfoUrl);
            if (
                $scope.overviewAdvancedUgFormSubmitInProgress ||
                !$scope.forms.overviewAdvancedUgForm ||
                !$scope.forms.overviewAdvancedUgForm.$valid ||
                $scope.isAdvancedUgOverviewEmpty ||
                controller.isInvalidUrl('ug','WebsiteUrl') ||
                controller.isInvalidUrl('ug','RequestInfoUrl')
            ) {
                return false;
            }
            $scope.overviewAdvancedUgFormSubmitInProgress = true;

            TuProfileOverviewService.saveOverviewData($scope.tuProfile.id, {
                type: constants.profileTypes.ug,
                advanced: true,
                requestInfoTypeUg: $scope.tuProfile.requestInfoTypeUg ? $scope.tuProfile.requestInfoTypeUg : 'email',
                advancedUgOverview: $scope.tuProfile.advancedUgOverview,
                advancedUgFacilities:$scope.tuProfile.advancedUgFacilities,
                advancedUgStudentLife:$scope.tuProfile.advancedUgStudentLife,
                advancedUgAdmissions:$scope.tuProfile.advancedUgAdmissions,
                advancedUgCareers:$scope.tuProfile.advancedUgCareers,
                ugWebsiteUrl: $scope.tuProfile.ugWebsiteUrl,
                ugRequestInfoUrlTitle: $scope.tuProfile.ugRequestInfoUrlTitle,
                ugRequestInfoUrl: $scope.tuProfile.ugRequestInfoUrl,
                ugRequestInfoEmail: $scope.tuProfile.ugRequestInfoEmail
            }).then(function (success) {
                if ($scope.tuProfile.requestInfoTypeUg === 'email') {
                    TuProfileFactory.setRequestInfoTypeUgUrl(false);
                }
                $scope.overviewAdvancedUgFormSubmitInProgress = false;
                NotifierFactory.show(
                    success ? 'success' : 'error',
                    success ? 'Saved successfully!' : 'Saving failed!',
                    'Overview Information'
                );
                loadInstantHisotry('ug', true);
            });
        };

        $scope.setIsAdvancedUgOverviewEmpty = function (value) {
            $scope.isAdvancedUgOverviewEmpty = value;
        };

        /**
         * Handle basic PG profile submission.
         *
         * @returns {boolean}
         */
        $scope.handleOverviewBasicPgDataSubmit = function () {
            $scope.isBasicPgOverviewEmpty = false;
            var cleanString =  TextService.cleanHtmlTags($scope.tuProfile.basicPgOverview);
            if (typeof ($scope.tuProfile.basicPgOverview) !== "undefined" &&
                !cleanString ||
                (cleanString && cleanString.length === 0) ||
                $scope.tuProfile.basicPgOverview === ""
            ) {
                $scope.isBasicPgOverviewEmpty = true;
            }
            /**
             * Validation 1:
             * Number of words in Basic Description is exceeding the limit of basicDescriptionWordLimit words
             */
            if ($scope.countWords($scope.tuProfile.basicPgOverview) > controller.basicDescriptionWordLimit) {
                NotifierFactory.show(
                    'error',
                    controller.validationMessageBasicDescriptionWordLimit,
                    'Saving failed!'
                );

                return false;
            }
            if (
                $scope.overviewBasicPgFormSubmitInProgress ||
                !$scope.forms.overviewBasicPgForm ||
                !$scope.forms.overviewBasicPgForm.$valid ||
                $scope.isBasicPgOverviewEmpty
            ) {
                return false;
            }
            $scope.overviewBasicPgFormSubmitInProgress = true;

            TuProfileOverviewService.saveOverviewData($scope.tuProfile.id, {
                type: constants.profileTypes.pg,
                basicPgOverview: $scope.tuProfile.basicPgOverview
            }).then(function (success) {
                $scope.overviewBasicPgFormSubmitInProgress = false;
                NotifierFactory.show(
                    success ? 'success' : 'error',
                    success ? 'Saved successfully!' : 'Saving failed!',
                    'Overview Information'
                );
                loadInstantHisotry('pg', false);
            });
        };

        $scope.setIsBasicPgOverviewEmpty = function (value) {
            $scope.isBasicPgOverviewEmpty = value;
        };

        controller.setOverviewInvalid = function (type) {
            switch (type) {
                case 'advancedOverview':
                    $scope.setIsAdvancedOverviewEmpty(false);
                    break;
                case 'advancedPgOverview':
                    $scope.setIsAdvancedPgOverviewEmpty(false);
                    break;
                case 'advancedUgOverview':
                    $scope.setIsAdvancedUgOverviewEmpty(false);
                    break;
                case 'basicOverview':
                    $scope.setIsBasicOverviewEmpty(false);
                    break;
                case 'basicUgOverview':
                    $scope.setIsBasicUgOverviewEmpty(false);
                    break;
                case 'basicpgOverview':
                    $scope.setIsBasicPgOverviewEmpty(false);
                    break;
            }
        };


        /**
         * Handle advanced PG profile submission.
         *
         * @returns {boolean}
         */
        $scope.handleOverviewAdvancedPgDataSubmit = function () {
            controller.submitted.pg = true;
            $scope.isAdvancedPgOverviewEmpty = false;
            var cleanString =  TextService.cleanHtmlTags($scope.tuProfile.advancedPgOverview);
            if (typeof ($scope.tuProfile.advancedPgOverview) !== "undefined" &&
                !cleanString ||
                (cleanString && cleanString.length === 0) ||
                $scope.tuProfile.advancedPgOverview === ""
            ) {
                $scope.isAdvancedPgOverviewEmpty = true;
            }

            /**
             * Validation 1:
             * Number of words in Advanced Description is exceeding the limit of advancedDescriptionWordLimit words
             */
            if ($scope.countWords($scope.tuProfile.advancedPgOverview) > controller.advancedDescriptionWordLimit) {
                showValidationNotification('Advanced Description');
                return false;
            }
            /**
             * Validation 2:
             * Number of words in Facilities is exceeding the limit of advancedDescriptionWordLimit words
             */
            if ($scope.countWords($scope.tuProfile.advancedPgFacilities) > controller.advancedDescriptionWordLimit) {
                showValidationNotification('Facilities');
                return false;
            }
            /**
             * Validation 3:
             * Number of words in Student Life is exceeding the limit of advancedDescriptionWordLimit words
             */
            if ($scope.countWords($scope.tuProfile.advancedPgStudentLife) > controller.advancedDescriptionWordLimit) {
                showValidationNotification('Student Life');
                return false;
            }
            /**
             * Validation 4:
             * Number of words in Admissions is exceeding the limit of advancedDescriptionWordLimit words
             */
            if ($scope.countWords($scope.tuProfile.advancedPgAdmissions) > controller.advancedDescriptionWordLimit) {
                showValidationNotification('Admissions');
                return false;
            }
            /**
             * Validation 5:
             * Number of words in Careers is exceeding the limit of advancedDescriptionWordLimit words
             */
            if ($scope.countWords($scope.tuProfile.advancedPgCareers) > controller.advancedDescriptionWordLimit) {
                showValidationNotification('Careers');
                return false;
            }
            $scope.tuProfile.pgWebsiteUrl = UrlService.prependHttp($scope.tuProfile.pgWebsiteUrl);
            $scope.tuProfile.pgRequestInfoUrl = UrlService.prependHttp($scope.tuProfile.pgRequestInfoUrl);
            if (
                $scope.overviewAdvancedPgFormSubmitInProgress ||
                !$scope.forms.overviewAdvancedPgForm ||
                !$scope.forms.overviewAdvancedPgForm.$valid ||
                $scope.isAdvancedPgOverviewEmpty ||
                controller.isInvalidUrl('pg','WebsiteUrl') ||
                controller.isInvalidUrl('pg','RequestInfoUrl')
            ) {
                return false;
            }
            $scope.overviewAdvancedPgFormSubmitInProgress = true;


            TuProfileOverviewService.saveOverviewData($scope.tuProfile.id, {
                type: constants.profileTypes.pg,
                advanced: true,
                requestInfoTypePg: $scope.tuProfile.requestInfoTypePg ? $scope.tuProfile.requestInfoTypePg : 'email',
                advancedPgOverview: $scope.tuProfile.advancedPgOverview,
                advancedPgFacilities:$scope.tuProfile.advancedPgFacilities,
                advancedPgStudentLife:$scope.tuProfile.advancedPgStudentLife,
                advancedPgAdmissions:$scope.tuProfile.advancedPgAdmissions,
                advancedPgCareers:$scope.tuProfile.advancedPgCareers,
                pgWebsiteUrl: $scope.tuProfile.pgWebsiteUrl,
                pgRequestInfoUrlTitle: $scope.tuProfile.pgRequestInfoUrlTitle,
                pgRequestInfoUrl: $scope.tuProfile.pgRequestInfoUrl,
                pgRequestInfoEmail: $scope.tuProfile.pgRequestInfoEmail
            }).then(function (success) {
                if ($scope.tuProfile.requestInfoTypePg === 'email') {
                    TuProfileFactory.setRequestInfoTypePgUrl(false);
                }
                $scope.overviewAdvancedPgFormSubmitInProgress = false;
                NotifierFactory.show(
                    success ? 'success' : 'error',
                    success ? 'Saved successfully!' : 'Saving failed!',
                    'Overview Information'
                );
                loadInstantHisotry('pg', true);
            });
        };

        $scope.setIsAdvancedPgOverviewEmpty = function (value) {
            $scope.isAdvancedPgOverviewEmpty = value;
        };

        /**
         * Listen the load more history log click event
         *
         * @param {event} event
         * @params {Integer} limit
         */
        $scope.$on(constants.events.loadMoreHistoryLogs, function (event, profileType, advanced, limit) {
            advanced = advanced || false;
            TuProfileHistoryLogFactory.setLoadMoreInProgress(true);
            TuProfileFactory.setHistoryLogVisibility(true);
            TuProfileOverviewService.getHistoryLog(
                $scope.tuProfile.id, profileType, advanced, limit
            ).then(function (data) {
                TuProfileHistoryLogFactory.setData(data);
                TuProfileHistoryLogFactory.setLoadMoreInProgress(false);
            });
        });

        /**
         * Actions to do when history log button os clicked.
         *
         * @param {string} profileType
         * @param {null|boolean} advanced
         */
        $scope.handleTuOverviewHistoryLogClick = function (profileType, advanced) {
            advanced = advanced || false;

            TuProfileHistoryLogFactory.setAdvanced(advanced);
            if ($scope.tuProfile.id !== TuProfileOverviewService.getProfileId()) {
                TuProfileHistoryLogFactory.setLogLimit(1);
            }

            if (TuProfileHistoryLogFactory.shouldBeVisible($scope.tuProfile.id)) {
                TuProfileHistoryLogFactory.setInProgress(true);
                TuProfileFactory.setHistoryLogVisibility(true);
                TuProfileHistoryLogFactory.setProfileType(profileType);

                TuProfileOverviewService.getHistoryLog(
                    $scope.tuProfile.id,
                    profileType,
                    advanced,
                    TuProfileHistoryLogFactory.getLogLimit()
                ).then(function (data) {
                    TuProfileHistoryLogFactory.setData(data);
                    TuProfileHistoryLogFactory.setInProgress(false);
                });

                TuProfileHistoryLogFactory.setTriggeredBy($scope.tuProfile.id);
            } else {
                TuProfileHistoryLogFactory.setLogLimit(1);
                TuProfileHistoryLogFactory.setTriggeredBy('');
                TuProfileFactory.setHistoryLogVisibility(false);
            }
        };

        controller.isRequestInfoTypeMasterUrl = function () {
            return TuProfileFactory.isRequestInfoTypeMasterUrl();
        };

        controller.isRequestInfoTypePgUrl = function () {
            return TuProfileFactory.isRequestInfoTypePgUrl();
        };

        controller.isRequestInfoTypeUgUrl = function () {
            return TuProfileFactory.isRequestInfoTypeUgUrl();
        };

        controller.isAdvancedSectionInvalid = function (words) {
            return words > controller.advancedDescriptionWordLimit;
        };

        controller.isBasicOverviewInvalid = function (basicOverviewWords) {
            return basicOverviewWords > controller.basicDescriptionWordLimit;
        };

        controller.onKeyUp = function ($event) {
            if ($event.keyCode === 13) {
                return;
            }
            var url = $event.target.value,
                variableName = $event.target.getAttribute('ng-model');

            if (url && url.length > 5 && (url.indexOf('http') !== 0 || url.indexOf('http') === -1)) {
                url = UrlService.prependHttp(url);
                if (variableName) {
                    ObjectService.set($scope, variableName, url);
                }
            }
        };

        function loadInstantHisotry(type, isAdvanced){
            if (TuProfileFactory.isHistoryLogVisible(TuProfileFactory.getProfileTabs().overview)) {
                TuProfileHistoryLogFactory.setTriggeredBy(false);
                $scope.handleTuOverviewHistoryLogClick(type, isAdvanced);
                TuProfileFactory.setHistoryLogVisibility(true);
            }
        }

        controller.initWatches = function () {
            // get tu profile data
            WatchService.create($scope, TuProfileFactory.getData, function (newValue, oldValue) {
                if (newValue !== oldValue) {
                    $scope.tuProfile = newValue;
                }
            });
        };

        controller.init = function () {
            controller.initWatches();
        };

        /**
         * reset description form for overview, ug, pg
         */
        controller.resetDescriptionForm = function () {
            $scope.setIsAdvancedOverviewEmpty(false);
            $scope.setIsAdvancedUgOverviewEmpty(false);
            $scope.setIsAdvancedPgOverviewEmpty(false);
            $scope.setIsBasicOverviewEmpty(false);
            $scope.setIsBasicUgOverviewEmpty(false);
            $scope.setIsBasicPgOverviewEmpty(false);
        };
        // listen to Overview tab visibility changes
        WatchService.create($scope, TuProfileFactory.isOverviewTabSelected, function (isActive, wasActive) {
            if (wasActive) {
                controller.resetDescriptionForm();
            }
            // if not already initialized and tab is active
            if (!alreadyInitialised && isActive && !wasActive) {
                controller.init();
                alreadyInitialised = true;
            }
        });
    };

    angular
        .module('qsHub')
        .controller('TuProfileOverviewController', [
            '$scope',
            '$timeout',
            'constants',
            'TuProfileOverviewService',
            'UrlService',
            'ObjectService',
            'TextService',
            'NotifierFactory',
            'TuProfileFactory',
            'TuProfileHistoryLogFactory',
            'WatchService',
            'InstitutionFactory',
            'UserFactory',
            'PasswordService',
            App.controllers.TuProfileOverview
        ]);

} (window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { services: {} });

    App.services.TuProfileOverviewService = function ($resource, $q, $log, constants) {
        var service = {
            overviewData: null,
            institutionData: null,
            historyLog: null,
            historyLogFieldNames: null,
            profileId: null,
            activeTabs: {
                master: 0,
                pg: 1,
                ug: 2
            },
            deferred: {
                getHistoryLog: null
            }
        };

        /**
         * Get institution profile API endpoint.
         *
         * @returns {$resource}
         */
        service.getTuModel = function () {
            return $resource(constants.api.institutions.url + '/v1/tu-profile/:id', null, {
                update: { method: 'PATCH' }
            });
        };

        /**
         * Save TU profile data.
         *
         * @param {string} profileId
         * @param {Object} overviewData
         * @returns {Promise}
         */
        service.saveOverviewData = function (profileId, overviewData) {
            var TuModel = service.getTuModel();
            return TuModel.update(
                { id: profileId },
                overviewData
            ).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        /**
         * Get institution profile history logs API endpoint.
         *
         * @returns {$resource}
         */
        service.getHistoryModel = function () {
            return $resource(constants.api.institutions.url + '/v1/list/tu-overview-history?sorting[modifiedAt]=desc&count=10&');
        };

        service.getActiveTabs = function () {
            return service.activeTabs;
        };

        service.getProfileId = function () {
            return service.profileId;
        };

         /**
         * Get TU overview tab history logs.
         *
         * @param {string} recordId
         * @param {string} fieldNames  - fields names to get the history for
         * @returns {Promise}
         */
        service.getHistoryLog = function (recordId, profileType, advanced, limit) {
            if (!recordId || !profileType || typeof advanced === 'undefined') {
                return false;
            }

            var fields;

            switch (profileType) {
                case 'master':
                    fields = [
                        'masterWebsiteUrl',
                        'masterRequestInfoEmail',
                        'masterRequestInfoUrlTitle',
                        'masterRequestInfoUrl',
                        'masterWebsiteUrl',
                        'masterRequestInfoEmail',
                        'masterRequestInfoUrlTitle',
                        'masterRequestInfoUrl',
                        'advancedMasterOverview',
                        'basicMasterOverview'
                    ];
                    if (advanced){
                        fields.push(
                            'advancedMasterFacilities',
                            'advancedMasterStudentLife',
                            'advancedMasterAdmissions',
                            'advancedMasterCareers'
                        );
                    }
                    break;
                case 'ug':
                    fields = [
                        'ugWebsiteUrl',
                        'ugRequestInfoEmail',
                        'ugRequestInfoUrlTitle',
                        'ugRequestInfoUrl',
                        'basicUgOverview',
                        'ugWebsiteUrl',
                        'ugRequestInfoEmail',
                        'ugRequestInfoUrlTitle',
                        'ugRequestInfoUrl',
                        'advancedUgOverview',
                        'basicUgOverview'
                    ];
                    if (advanced){
                        fields.push(
                            'advancedUgFacilities',
                            'advancedUgStudentLife',
                            'advancedUgAdmissions',
                            'advancedUgCareers'
                        );
                    }
                    break;
                case 'pg':
                    fields = [
                        'pgWebsiteUrl',
                        'pgRequestInfoEmail',
                        'pgRequestInfoUrlTitle',
                        'pgRequestInfoUrl',
                        'pgWebsiteUrl',
                        'pgRequestInfoEmail',
                        'pgRequestInfoUrlTitle',
                        'pgRequestInfoUrl',
                        'advancedPgOverview',
                        'basicPgOverview'
                    ];
                    if (advanced){
                        fields.push(
                            'advancedPgFacilities',
                            'advancedPgStudentLife',
                            'advancedPgAdmissions',
                            'advancedPgCareers'
                        );
                    }
                    break;
            }
            fields.push('advanced');

            // process already running
            if (!service.deferred.getHistoryLog) {
                service.deferred.getHistoryLog = $q.defer();
            } else {
                return service.deferred.getHistoryLog.promise;
            }
            var deferred = service.deferred.getHistoryLog;

            if (service.historyLog) {
                deferred.resolve(service.historyLog);
            } else {
                var HistoryModel = service.getHistoryModel();
                HistoryModel.get(
                    {
                        'filter[recordId]': recordId,
                        'field[]': fields,
                        'filter[type]': profileType,
                        'limit': limit
                    },
                    function (data) {
                        if (constants.dev) {
                            $log.log('success, got data: ', data);
                        }
                        service.historyLog = data;
                        service.profileId = recordId;

                        deferred.resolve(service.historyLog);
                        deferred = null;
                        service.deferred.getHistoryLog = null;
                        service.historyLog = null;
                    }, function (error) {
                        //@todo log error
                        if (constants.dev) {
                            $log.log('request failed' + error);
                        }
                        service.historyLog = null;
                        deferred.resolve(service.historyLog);
                        deferred = null;
                        service.deferred.getHistoryLog = null;
                    }
                );
            }

            return deferred.promise;
        };

        return service;
    };

    angular
        .module('qsHub')
        .service('TuProfileOverviewService', [
            '$resource',
            '$q',
            '$log',
            'constants',
            App.services.TuProfileOverviewService
        ]);

} (window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { controllers: {} });

    App.controllers.TmProfileOverview = function (
        $scope,
        constants,
        TmProfileOverviewService,
        UrlService,
        ObjectService,
        TextService,
        NotifierFactory,
        UserFactory,
        TmProfileFactory,
        TmProfileOverviewHistoryLogFactory,
        TmProfileOverviewFactory,
        InstitutionFactory,
        WatchService,
        $timeout
    ) {
        var controller = this,
            alreadyInitialised = false;

        controller.devMode = constants.dev;
        controller.fetchingHistoryLogsInProgress = false;
        controller.textEditorBasicOptions = {
            disableDragAndDrop: true,
            styleWithSpan: false,
            dialogsInBody: true,
            minHeight: 280,
            maxHeight: 280,
            toolbar: [
                ['alignment', ['ul', 'ol']],
                ['edit', ['undo', 'redo']],
                ['view', ['codeview']]
            ],
            popover: {
                link: []
            }
        };
        controller.textEditorAdvancedOptions = {
            disableDragAndDrop: true,
            styleWithSpan: false,
            dialogsInBody: true,
            minHeight: 280,
            maxHeight: 280,
            toolbar: [
                ['style', ['bold', 'italic', 'underline', 'superscript', 'subscript', 'strikethrough']],
                ['alignment', ['ul', 'ol']],
                ['insert', ['link', 'unlink']],
                ['edit', ['undo', 'redo']],
                ['view', ['codeview']]
            ],
            popover: {
                link: []
            }
        };
        controller.advancedDescriptionWordLimit = constants.profileOverview.tm.advanced.maxWords;
        controller.validationMessageAdvancedDescriptionWordLimit =
            'Profile advanced description has exceeded the limit of ' +
            controller.advancedDescriptionWordLimit +
            ' words. Please reduce number of words.';
        controller.advancedOverviewWords = 0;
        controller.basicDescriptionWordLimit = constants.profileOverview.tm.basic.maxWords;
        controller.validationMessageBasicDescriptionWordLimit =
            'Profile basic description has exceeded the limit of ' +
            controller.basicDescriptionWordLimit +
            ' words. Please reduce number of words.';
        controller.basicOverviewWords = 0;
        controller.submitted = false;

        $scope.overviewBasicFormSubmitInProgress = false;
        $scope.forms = {};
        $scope.upgradeRequest = {
            name: UserFactory.getFullName(),
            email: UserFactory.getUserName()
        };

        controller.handleClipboardPaste = function (modelName) {
            $timeout(function() {
                if (InstitutionFactory.isTmAdvanced()) {
                    $scope.tmProfile[modelName] = TextService.clearAttributes($scope.tmProfile[modelName]);
                } else {
                    $scope.tmProfile[modelName] = TextService.clearAttributes(TextService.removeAnchorTag($scope.tmProfile[modelName]));
                }
            }, 50);
        };

        controller.isInvalidUrl = function () {
            if (!controller.submitted) {
                return false;
            }
            var url = $scope.tmProfile.websiteUrl;
            return url && url.length > 1 && url.length < 7;
        };

        /**
         * Count words in the string.
         *
         * @param {string} text
         */
        $scope.countWords = function (text) {
            return TextService.countWords(text);
        };

        /**
         * Handle basic  profile submission.
         *
         * @returns {boolean}
         */
        $scope.handleOverviewBasicDataSubmit = function () {
            $scope.isBasicTmOverviewEmpty = false;
            var cleanString =  TextService.cleanHtmlTags($scope.tmProfile.basicOverview);
            if (typeof ($scope.tmProfile.basicOverview) !== "undefined" &&
                !cleanString ||
                (cleanString && cleanString.length === 0) ||
                $scope.tmProfile.basicOverview === ""
            ) {
                $scope.isBasicTmOverviewEmpty = true;
            }
            /**
             * Validation 1:
             * Number of words in Basic Description is exceeding the limit of basicDescriptionWordLimit words
             */
            if ($scope.countWords($scope.tmProfile.basicOverview) > controller.basicDescriptionWordLimit) {
                NotifierFactory.show(
                    'error',
                    controller.validationMessageBasicDescriptionWordLimit,
                    'Saving failed!'
                );

                return false;
            }
            if (
                $scope.overviewBasicFormSubmitInProgress ||
                !$scope.forms.overviewBasicForm ||
                !$scope.forms.overviewBasicForm.$valid ||
                $scope.isBasicTmOverviewEmpty
            ) {
                return false;
            }
            $scope.overviewBasicFormSubmitInProgress = true;

            TmProfileOverviewService.saveOverviewData($scope.tmProfile.id, {
                basicOverview: $scope.tmProfile.basicOverview
            }).then(function (success) {
                $scope.overviewBasicFormSubmitInProgress = false;
                NotifierFactory.show(
                    success ? 'success' : 'error',
                    success ? 'Saved successfully!' : 'Saving failed!',
                    'Overview Information'
                );
                if (TmProfileOverviewHistoryLogFactory.isOverviewVisible()) {
                    controller.handleTmOverviewHistoryLogClick(false, true);
                }
            });
        };

        $scope.setIsBasicTmOverviewEmpty = function (value) {
            $scope.isBasicTmOverviewEmpty = value;
        };

        /**
         * Handle advanced profile submission.
         *
         * @returns {boolean}
         */
        $scope.handleOverviewAdvancedDataSubmit = function () {
            controller.submitted = true;
            $scope.isAdvancedTmOverviewEmpty = false;
            var cleanString =  TextService.cleanHtmlTags($scope.tmProfile.advancedOverview);
            if (typeof ($scope.tmProfile.advancedOverview) !== "undefined" &&
                !cleanString ||
                (cleanString && cleanString.length === 0) ||
                $scope.tmProfile.advancedOverview === ""
            ) {
                $scope.isAdvancedTmOverviewEmpty = true;
            }
            /**
             * Validation 1:
             * Number of words in Advanced Description is exceeding the limit of advancedDescriptionWordLimit words
             */
            if ($scope.countWords($scope.tmProfile.advancedOverview) > controller.advancedDescriptionWordLimit) {
                NotifierFactory.show(
                    'error',
                    controller.validationMessageAdvancedDescriptionWordLimit,
                    'Saving failed!'
                );

                return false;
            }
            $scope.tmProfile.websiteUrl = UrlService.prependHttp($scope.tmProfile.websiteUrl);
            if (
                $scope.overviewAdvancedFormSubmitInProgress ||
                !$scope.forms.overviewAdvancedForm ||
                !$scope.forms.overviewAdvancedForm.$valid ||
                controller.isInvalidUrl() ||
                $scope.isAdvancedTmOverviewEmpty
            ) {
                return false;
            }
            $scope.overviewAdvancedFormSubmitInProgress = true;
            TmProfileOverviewService.saveOverviewData($scope.tmProfile.id, {
                advanced: true,
                advancedOverview: $scope.tmProfile.advancedOverview,
                websiteUrl: $scope.tmProfile.websiteUrl,
                requestInfoEmail: $scope.tmProfile.requestInfoEmail
            }).then(function (success) {
                $scope.overviewAdvancedFormSubmitInProgress = false;
                NotifierFactory.show(
                    success ? 'success' : 'error',
                    success ? 'Saved successfully!' : 'Saving failed!',
                    'Overview Information'
                );

                if (success && TmProfileOverviewHistoryLogFactory.isOverviewVisible()) {
                    controller.handleTmOverviewHistoryLogClick(true, true);
                }
            });
        };

        $scope.setIsAdvancedTmOverviewEmpty = function (value) {
            $scope.isAdvancedTmOverviewEmpty = value;
        };

        controller.handleTmOverviewHistoryLogClick = function (advanced, instantUPdate) {
            controller.fetchingHistoryLogsInProgress = true;
            advanced = advanced || false;
            instantUPdate = instantUPdate || false;
            var isVisible = instantUPdate || !TmProfileOverviewHistoryLogFactory.isOverviewVisible();
            TmProfileOverviewHistoryLogFactory.setOverviewVisible(isVisible);
            if (isVisible || instantUPdate) {
                // load logs
                TmProfileOverviewService.getOverviewHistoryLogs($scope.tmProfile.id, advanced, TmProfileOverviewHistoryLogFactory.getLimit()).then(function (data) {
                    TmProfileOverviewHistoryLogFactory.setLogs(data);
                    controller.fetchingHistoryLogsInProgress = false;
                });
                TmProfileFactory.setUpgradeFormVisibility(false);
            }
        };

        controller.handleTmFaqHistoryLogClick = function (instantUpdate) {
            controller.fetchingHistoryLogsInProgress = true;
            instantUpdate = instantUpdate || false;
            var isVisible = instantUpdate || !TmProfileOverviewHistoryLogFactory.isFaqVisible();
            TmProfileOverviewHistoryLogFactory.setFaqVisible(isVisible);
            if (isVisible) {
                // load logs
                TmProfileOverviewService.getFaqHistoryLogs($scope.tmProfile.id, TmProfileOverviewHistoryLogFactory.getLimit()).then(function (data) {
                    TmProfileOverviewHistoryLogFactory.setLogs(data);
                    controller.fetchingHistoryLogsInProgress = false;
                });
            }
        };

        controller.isAdvancedOverviewInvalid = function (advancedOverviewWords) {
            return advancedOverviewWords > controller.advancedDescriptionWordLimit;
        };

        controller.isBasicOverviewInvalid = function (basicOverviewWords) {
            return basicOverviewWords > controller.basicDescriptionWordLimit;
        };

        controller.onKeyUp = function ($event) {
            if ($event.keyCode === 13) {
                return;
            }
            var url = $event.target.value,
                variableName = $event.target.getAttribute('ng-model');

            if (url && url.length > 5 && (url.indexOf('http') !== 0 || url.indexOf('http') === -1)) {
                url = UrlService.prependHttp(url);
                if (variableName) {
                    ObjectService.set($scope, variableName, url);
                }
            }
        };

        $scope.isRightSidePanelActive = function () {
            // media tab is active
            if (TmProfileFactory.isOverviewTabSelected && $scope.showUpgradeForm) {
                return true;
            }
            return false;
        };

        controller.isOverviewInvalid = function () {
            return controller.submitted && ($scope.isAdvancedTmOverviewEmpty || $scope.isBasicTmOverviewEmpty);
        };

        controller.setOverviewInvalid = function (type) {
            if (type === 'advancedTmOverview') {
                $scope.setIsAdvancedTmOverviewEmpty(false);
            }
            else if (type === 'basicTmOverview') {
                $scope.setIsBasicTmOverviewEmpty(false);
            }
        };

        controller.activeOverviewSubTabWatch = function (selectedTabId) {
            // set selected tab id
            TmProfileOverviewFactory.setSelectedTabId(selectedTabId);
            // close right side panels
            TmProfileFactory.closeRightSidePanels();
        };

        controller.isLoadMoreRequestWatch = function (loadMore) {
            if (loadMore) {
                TmProfileOverviewHistoryLogFactory.loadMore(false);

                TmProfileOverviewHistoryLogFactory.setLoadMoreInProgress(true);
                var limit = TmProfileOverviewHistoryLogFactory.getLimit() + 5;

                if (TmProfileOverviewHistoryLogFactory.isOverviewVisible()) {
                    // load logs
                    TmProfileOverviewService.getOverviewHistoryLogs(
                        $scope.tmProfile.id,
                        InstitutionFactory.isTmAdvanced(),
                        limit
                    ).then(function (data) {
                        TmProfileOverviewHistoryLogFactory.setLogs(data);
                        TmProfileOverviewHistoryLogFactory.setLoadMoreInProgress(false);
                        TmProfileOverviewHistoryLogFactory.setLimit(limit);
                    });
                } else {
                    TmProfileOverviewService.getFaqHistoryLogs(
                        $scope.tmProfile.id, limit
                    ).then(function (data) {
                        TmProfileOverviewHistoryLogFactory.setLogs(data);
                        TmProfileOverviewHistoryLogFactory.setLoadMoreInProgress(false);
                        TmProfileOverviewHistoryLogFactory.setLimit(limit);
                    });
                }
            }
        };

        controller.initWatches = function () {
            WatchService.create($scope, 'activeOverviewSubTab', controller.activeOverviewSubTabWatch);
            WatchService.create($scope, TmProfileOverviewHistoryLogFactory.isLoadMoreRequest, controller.isLoadMoreRequestWatch);

            // get tu profile data
            WatchService.create($scope, TmProfileFactory.getData, function (newValue, oldValue) {
                if (newValue !== oldValue) {
                    $scope.tmProfile = newValue;
                }
            });

            WatchService.create($scope, TmProfileFactory.isUpgradeFormVisible($scope.activeTab), function (newValue, oldValue) {
                if (newValue !== oldValue) {
                    $scope.upgradeRequest.comments = null;
                    $scope.showUpgradeForm = newValue;
                }
            });

            // watch active institution coreId changes
            WatchService.create($scope, InstitutionFactory.getCoreId, function (newValue, oldValue) {
                if (newValue !== oldValue) {
                    controller.resetDescriptionForm();
                }
                controller.basicDescriptionWordLimit = InstitutionFactory.isAdvancedProgram() ? constants.profileOverview.tm.advanced.maxWords : constants.profileOverview.tm.basic.maxWords;
            });

            //watch faq history updates
            WatchService.create($scope, function () {
                return TmProfileOverviewFactory.isFaqUpdated();
            }, function (updated) {
                if (updated && TmProfileOverviewHistoryLogFactory.isFaqVisible()) {
                    controller.handleTmFaqHistoryLogClick(true);
                }
                TmProfileOverviewFactory.setFaqUpdated(false);
            });
        };

        controller.init = function () {
            controller.initWatches();
        };

        /**
         * reset description form for overview.
         */
        controller.resetDescriptionForm = function () {
            $scope.setIsBasicTmOverviewEmpty(false);
            $scope.setIsAdvancedTmOverviewEmpty(false);
        };

        // listen to Overview tab visibility changes
        WatchService.create($scope, TmProfileFactory.isOverviewTabSelected, function (isActive, wasActive) {
            if (wasActive) {
                controller.resetDescriptionForm();
            }
            // if not already initialized and tab is active
            if (!alreadyInitialised && isActive && wasActive) {
                controller.init();
                alreadyInitialised = true;
            }
        });
    };

    angular
        .module('qsHub')
        .controller('TmProfileOverviewController', [
            '$scope',
            'constants',
            'TmProfileOverviewService',
            'UrlService',
            'ObjectService',
            'TextService',
            'NotifierFactory',
            'UserFactory',
            'TmProfileFactory',
            'TmProfileOverviewHistoryLogFactory',
            'TmProfileOverviewFactory',
            'InstitutionFactory',
            'WatchService',
            '$timeout',
            App.controllers.TmProfileOverview
        ]);

} (window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { controllers: {} });

    App.controllers.TmProfileOverviewFaq = function (
        $scope,
        constants,
        TmProfileOverviewService,
        TextService,
        NotifierFactory,
        TmProfileFactory,
        TmProfileOverviewFactory,
        WatchService,
        ModalService,
        $timeout
    ) {
        var controller = this,
            alreadyInitialised = false;

        controller.devMode = constants.dev;
        controller.maxItems = TmProfileOverviewService.maxFaqItems;
        controller.updateInProgress = false;
        controller.wordsLimit = constants.profileOverview.tm.faq.maxWords;
        controller.defaultItem = {
            answer: '',
            question: null
        };
        controller.textEditorAdvancedOptions = {
            disableDragAndDrop: true,
            styleWithSpan: false,
            dialogsInBody: true,
            minHeight: 280,
            maxHeight: 280,
            toolbar: [
                ['alignment', ['ul', 'ol']],
                ['edit', ['undo', 'redo']],
                ['view', ['codeview']]
            ],
            popover: {
                link: []
            }
        };
        controller.wordsCounters = [];
        controller.items = new Array(controller.maxItems);
        controller.tmProfile = null;
        $scope.faq = [];
        controller.faqForm = {};
        var tempFaq = [];

        controller.initInvalidFaq = function () {
          controller.invalidFaq = [
            { q: false, a: false },
            { q: false, a: false },
            { q: false, a: false },
            { q: false, a: false },
            { q: false, a: false }
          ];
        };

        controller.handleTextEditorChanges = function (key, text) {
            controller.wordsCounters[key] = controller.countWords(text);
        };

        controller.isInvalidAnswer = function (key) {
            return controller.wordsCounters[key] > controller.wordsLimit;
        };

        controller.countWords = function (text) {
            return TextService.countWords(text);
        };

        controller.handleAddClick = function () {
            if ($scope.faq.length < controller.maxItems) {
                $scope.faq.push(angular.copy(controller.defaultItem));
            }
        };

        controller.handleClipboardPaste = function (index) {
            $timeout(function() {
                $scope.faq[index].answer = TextService.clearAttributes($scope.faq[index].answer);
            }, 50);
        };

        controller.handleUpdateClick = function (isDeleted) {
            // check if FAQ answers are valid
            if (!(controller.faqForm) || !(controller.faqForm.$valid)) {
                if (!$scope.faq.length) {
                    $scope.faq.push(angular.copy(controller.defaultItem));
                }
                if (angular.isDefined(controller.faqForm.question) &&
                    angular.isDefined(controller.faqForm.question.$valid) &&
                    !controller.faqForm.question.$valid ||
                    !angular.isDefined(controller.faqForm.question)
                ) {
                    for (var ques in $scope.faq) {
                        if (typeof($scope.faq[ques].question) === "undefined" ||
                            $scope.faq[ques].question === null ||
                            $scope.faq[ques].question === ""
                        ) {
                            var index = $scope.faq.indexOf($scope.faq[ques]);
                            controller.invalidFaq[index].q = true;
                        }
                    }
                }

                if (angular.isDefined(controller.faqForm.answer) &&
                    angular.isDefined(controller.faqForm.answer.$valid) &&
                    !controller.faqForm.answer.$valid ||
                    !angular.isDefined(controller.faqForm.answer)
                ) {
                    for (var ans in $scope.faq) {
                        if (typeof($scope.faq[ans].answer) === "undefined" ||
                            $scope.faq[ans].answer === null ||
                            $scope.faq[ans].answer === ""
                        ) {
                            var idx = $scope.faq.indexOf($scope.faq[ans]);
                            controller.invalidFaq[idx].a = true;
                        }
                    }
                }

                // User should be able to save empty Q&A when there is only one
                var valid = false;
                if ($scope.faq.length === 1 &&
                    ($scope.faq[0].question === null ||
                    $scope.faq[0].question === "" ||
                    !angular.isDefined($scope.faq[0].question)) &&
                    ($scope.faq[0].answer === null ||
                    $scope.faq[0].answer === "" ||
                    !angular.isDefined($scope.faq[0].answer))
                ) {
                    valid = true;
                    controller.invalidFaq[0].q = false;
                    controller.invalidFaq[0].a = false;
                    $scope.faq[0].answer = "";
                    $scope.faq[0].question = "";
                }

                if (!valid) {
                    return false;
                }
            }

            for (var key in $scope.faq) {
                if (controller.isInvalidAnswer(key)) {
                    NotifierFactory.show(
                        'error',
                        'FAQ answer has exceeded the limit of ' + controller.wordsLimit +
                        ' words. Please reduce number of words.',
                        'Saving failed!'
                    );
                    return false;
                }
            }
            controller.updateInProgress = true;
            TmProfileOverviewService.saveOverviewData(controller.tmProfileId, {
                faq: $scope.faq
            }).then(function (success) {
                controller.updateInProgress = false;
                NotifierFactory.show(
                    success ? 'success' : 'error',
                    success ? (isDeleted ? 'Deleted' : 'Updated') + ' successfully!' :
                    (isDeleted ? 'Delete' : 'Update') +' failed!',
                    'Overview FAQ'
                );
                if (success) {
                    controller.tmProfile.faq = angular.copy($scope.faq);
                    TmProfileFactory.setData(controller.tmProfile);
                    TmProfileOverviewFactory.setFaqUpdated(true);
                    tempFaq = [];
                } else {
                    $scope.faq = angular.copy(tempFaq);
                }
            });

        };

        controller.setQuestionInvalid = function (val, idx) {
            if (!val) {
                controller.invalidFaq[idx].q = false;
            }
        };

        controller.setAnswerInvalid = function (val, idx) {
            if (!val) {
                controller.invalidFaq[idx].a = false;
            }
        };

        controller.handleRemoveClick = function (key) {
            if (typeof $scope.faq === 'undefined' || !$scope.faq.length) {
                return false;
            }

            var modalOptions = {
                closeButtonText: 'Cancel',
                actionButtonText: 'Yes',
                actionButtonClass: 'danger',
                headerText: 'FAQ Deletion',
                bodyText: 'Are you sure you wish to delete FAQ?'
            };
            ModalService.show({}, modalOptions).then(function () {
                key = parseInt(key, 10);
                if (typeof $scope.faq[key] !== 'undefined') {
                    tempFaq = angular.copy($scope.faq);
                    $scope.faq.splice(key, 1);

                    if (key === 0 && $scope.faq.length === 0) {
                        $scope.faq.push(angular.copy(controller.defaultItem));
                    }
                    controller.handleUpdateClick(true);
                }
            });
        };

        controller.isRemoveDisabled = function () {
            return controller.updateInProgress;
        };

        controller.isItemVisible = function (key) {
            if (typeof $scope.faq === 'undefined') {
                return key === 0 ? true : false;
            }
            key = parseInt(key, 10);

            return (typeof $scope.faq !== 'undefined' && $scope.faq !== null &&
                typeof $scope.faq[key] !== 'undefined') || key === 0;
        };

        controller.showAddFaq = function (itemIndex) {
            return $scope.faq && $scope.faq.length === itemIndex + 1;
        };

        controller.resetFaq = function () {
            if (controller.tmProfile && controller.tmProfile.faq) {
                $scope.faq = angular.copy(controller.tmProfile.faq);
            }
        };

        controller.initWatches = function () {
            // get tm profile data
            WatchService.create($scope, TmProfileFactory.getData, function (newValue) {
                controller.tmProfile = angular.copy(newValue);
                $scope.faq = newValue && newValue.faq ? newValue.faq : null;
                controller.tmProfileId = newValue && newValue.id ? newValue.id : null;
                controller.initInvalidFaq();
            });

            // listen to over tab changes
            WatchService.create($scope, TmProfileOverviewFactory.isOverviewTabSelected, function (isActive) {
                if (!isActive) {
                    controller.resetFaq();
                }
            });
        };

        controller.init = function () {
            controller.initWatches();
        };


        // listen to Overview tab visibility changes
        WatchService.create($scope, TmProfileOverviewFactory.isFaqSelected, function (isActive) {
            // if not already initialized and tab is active
            if (!alreadyInitialised && isActive) {
                controller.init();
                alreadyInitialised = true;
            } else if (isActive) {
                controller.resetFaq();
            }
        });
    };

    angular
        .module('qsHub')
        .controller('TmProfileOverviewFaqController', [
            '$scope',
            'constants',
            'TmProfileOverviewService',
            'TextService',
            'NotifierFactory',
            'TmProfileFactory',
            'TmProfileOverviewFactory',
            'WatchService',
            'ModalService',
            '$timeout',
            App.controllers.TmProfileOverviewFaq
        ]);

} (window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    App.services.TmProfileOverviewService = function ($resource, $q, $log, constants) {
        var service = {
            overviewData : null,
            institutionData: null,
            historyLog : null,
            historyLogFieldNames: null,
            profileId: null,
            deferred : {
                getHistoryLog: null
            },
            maxFaqItems: 5
        };

        /**
         * Get institution profile API endpoint.
         *
         * @returns {$resource}
         */
        service.getTmModel = function () {
            return $resource(constants.api.institutions.url + '/v1/tm-profile/:id', null, {
                update: { method:'PATCH' }
            });
        };

        /**
         * Save TU profile data.
         *
         * @param {string} profileId
         * @param {Object} overviewData
         * @returns {Promise}
         */
        service.saveOverviewData = function(profileId, overviewData) {
            var TmModel = service.getTmModel();
            return TmModel.update(
                {id: profileId},
                overviewData
            ).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        /**
         * Get institution profile history logs API endpoint.
         *
         * @returns {$resource}
         */
        service.getHistoryModel = function () {
            return $resource(constants.api.institutions.url + '/v1/list/tm-overview-history?sorting[modifiedAt]=desc&count=10&');
         };

        service.getActiveTabs = function () {
            return service.activeTabs;
        };

        service.getProfileId = function () {
            return service.profileId;
        };

        service.getLogs = function (profileId, fields, limit) {
            if (!profileId) {
                return false;
            }
            limit = limit || 1;
            // process already running
            if (!service.deferred.getHistoryLog) {
                service.deferred.getHistoryLog = $q.defer();
            } else {
                return service.deferred.getHistoryLog.promise;

            }
            var deferred = service.deferred.getHistoryLog;

            if (service.historyLog) {
                deferred.resolve(service.historyLog);
            } else {
                var HistoryModel = service.getHistoryModel();
                HistoryModel.get(
                    {
                        'filter[recordId]': profileId,
                        'field[]': fields,
                        'limit': limit
                    },
                    function (data) {
                        if (constants.dev) {
                            $log.log('success, got data: ', data);
                        }
                        service.historyLog = data;
                        service.profileId = profileId;

                        deferred.resolve(service.historyLog);
                        deferred = null;
                        service.deferred.getHistoryLog = null;
                        service.historyLog = null;
                    }, function (error) {
                        //@todo log error
                        if (constants.dev) {
                            $log.log('request failed' + error);
                        }
                        service.historyLog = null;
                        deferred.resolve(service.historyLog);
                        deferred = null;
                        service.deferred.getHistoryLog = null;
                    }
                );
            }

            return deferred.promise;
        };

        service.getOverviewHistoryLogs = function (profileId, isAdvanced, limit) {
            if (!profileId || typeof isAdvanced === 'undefined') {
                return false;
            }
            var fields = [
                'websiteUrl',
                'requestInfoEmail',
                'websiteUrl',
                'advanced'
            ];
            if (isAdvanced) {
                fields.push('advancedOverview');
            } else {
                fields.push('basicOverview');
            }

            return service.getLogs(profileId, fields, limit);
        };

        service.getFaqHistoryLogs = function (profileId, limit) {
            if (!profileId) {
                return false;
            }

            return service.getLogs(profileId, ['faq'], limit);
        };

        return service;
    };

    angular
        .module('qsHub')
        .service('TmProfileOverviewService', [
            '$resource',
            '$q',
            '$log',
            'constants',
            App.services.TmProfileOverviewService
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { factories: {} });

    App.factories.TmProfileOverview = function() {
        var selectedTabId = 0,
            faqUpdated;
        return {
            setSelectedTabId : function (tabId) {
                selectedTabId = tabId;
            },
            isFaqSelected: function () {
                return selectedTabId === 1;
            },
            isOverviewSelected: function () {
                return selectedTabId === 0;
            },
            setFaqUpdated : function (value) {
                faqUpdated = value;
            },
            isFaqUpdated: function () {
                return faqUpdated;
            }
        };
    };

    angular
        .module('qsHub')
        .factory('TmProfileOverviewFactory', [
            App.factories.TmProfileOverview
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { factories: {} });

    App.factories.tmProfileOverviewHistoryLog = function() {
        var faqVisible = false,
            overviewVisible = false,
            loadMoreInProgress = false,
            loadMore = false,
            logs = [],
            limit;

        return {
            isFaqVisible: function () {
                return faqVisible;
            },
            isOverviewVisible: function () {
                return overviewVisible;
            },
            setFaqVisible: function (isVisible) {
                faqVisible = isVisible;
                if (faqVisible) {
                    overviewVisible = false;
                }
            },
            setOverviewVisible: function (isVisible) {
                overviewVisible = isVisible;
                if (overviewVisible) {
                    faqVisible = false;
                }
            },
            closeAll: function () {
                overviewVisible = false;
                faqVisible = false;
            },
            setLogs: function (data) {
                logs = data;
            },
            getLogs: function () {
                return logs;
            },
            setLimit: function (newLimit) {
                limit = newLimit;
            },
            getLimit: function () {
                return limit || 1;
            },
            isLoadMoreInProgress: function () {
                return loadMoreInProgress;
            },
            setLoadMoreInProgress: function (inProgress) {
                loadMoreInProgress = inProgress;
            },
            loadMore: function (more) {
                loadMore = typeof more === 'undefined' ? true : more;
            },
            isLoadMoreRequest: function () {
                return loadMore;
            }
        };
    };

    angular
        .module('qsHub')
        .factory('TmProfileOverviewHistoryLogFactory', [
            App.factories.tmProfileOverviewHistoryLog
        ]);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { controllers: {} });

    App.controllers.tuProfileDepartments = function (
        $scope,
        $rootScope,
        $timeout,
        constants,
        NotifierFactory,
        TuProfileFactory,
        InstitutionFactory,
        TuProfileDepartmentsService,
        TuProfileService,
        uiGridConstants,
        UserFactory,
        WatchService,
        GridService,
        TimeService,
        DateRangeFixerService,
        ModalService
    ) {
        var controller = this,
            alreadyInitialised = false,
            lastCreatedAtDateFilter = null;

        controller.devMode = constants.dev;
        controller.deleteField = 'delete';
        controller.modifiedAtField = 'modifiedAt';
        controller.isgridRendering = true;

        $scope.departmentsToDelete = {};
        $scope.alphabeticalOrderingInProgress = false;
        $scope.deleteInProgress = false;
        $scope.isDatagridReloading = false;
        $scope.isDatagridRendered = false;

        $scope.filters = {
            createdAt: {
                startDate: null,
                endDate: null
            }
        };

        controller.gridFilterNameSpace = TuProfileFactory.getIsDepartmentOverview() ?
            constants.gridNameSpaces.departmentsOvGridFilters :
            constants.gridNameSpaces.departmentsGridFilters;

        controller.gridVisibilityNameSpace = TuProfileFactory.getIsDepartmentOverview() ?
            constants.gridNameSpaces.departmentsOvGridVisibility :
            constants.gridNameSpaces.departmentsGridVisibility;

        controller.lastCreatedAt = null;
        controller.paginationOptions = GridService.getColumnsFilters(
            controller.gridFilterNameSpace,
            []
        );

        controller.columnsVisibility = GridService.getColumnsVisibility(
            controller.gridVisibilityNameSpace
        );

        $scope.getTuProfileDepartmentController = function () {
            return controller;
        };

        $scope.handleCreatedAtDateRange = function (event) {

            if (DateRangeFixerService.sameDayAndNoModel(event, $scope.filters.createdAt, lastCreatedAtDateFilter)) {
                event.model.startDate = lastCreatedAtDateFilter.startDate;
                event.model.endDate = lastCreatedAtDateFilter.endDate;
                $scope.filters.createdAt = {
                    startDate: lastCreatedAtDateFilter.startDate,
                    endDate: lastCreatedAtDateFilter.endDate
                };
            } else if (DateRangeFixerService.todayRangeAndNoModel(event, lastCreatedAtDateFilter)) {
                event.model.startDate = TimeService.getStartOfToday();
                event.model.endDate = TimeService.getEndOfToday();
                $scope.filters.createdAt = {
                    startDate: event.model.startDate.toISOString(),
                    endDate: event.model.endDate.toISOString()
                };
            }

            if (event === null || typeof event === 'undefined') {
                $scope.gridOptions.columnDefs[5].filters[0].term = null;
                $scope.gridOptions.columnDefs[5].filters[1].term = null;
            } else if (event.model.startDate && event.model.endDate) {
                $scope.gridOptions.columnDefs[5].filters[0].term = typeof event.model.startDate === 'object' ? event.model.startDate.format('x') : event.model.startDate;
                $scope.gridOptions.columnDefs[5].filters[1].term = typeof event.model.endDate === 'object' ? event.model.endDate.format('x') : event.model.endDate;
            }

            lastCreatedAtDateFilter = angular.copy({
                startDate: event.model.startDate,
                endDate: event.model.endDate
            });
        };

        /**
         * Actions to do when departments table row is clicked.
         *
         * @param {object} department
         */
        $scope.handleDatagridRowClick = function (department) {
            $scope.selectedDepartmentId = ($scope.selectedDepartmentId === department.id) ? null : department.id;
            // store link to department instance
            TuProfileFactory.setDepartmentBeforeChanges(department);
            // create new copy of department for making changes in
            department = angular.copy(department);
            TuProfileFactory.setDepartmentEditFormVisibility(
                $scope.selectedDepartmentId === department.id && department.id !== 'undefined'
            );
            TuProfileFactory.setDepartment(department);

            controller.toggleColumns($scope.selectedDepartmentId === department.id && department.id !== 'undefined');
            TuProfileFactory.setIsDepartmentRowSelected(true);
        };

        /**
         * Toggle datagrid columns visibility.
         *
         * @param {boolean} hide
         */
        controller.toggleColumns = function (hide) {
            if (!$scope.gridOptions) {
                return;
            }
            if (hide) {
                // hide delete column
                angular.forEach($scope.gridOptions.columnDefs, function (column) {
                    if (column.field !== controller.deleteField) {
                        column.visible = angular.isDefined(controller.columnsVisibility) ?
                            controller.columnsVisibility[column.field] : true;
                    } else {
                        column.visible = false;
                    }
                });
            } else {
                // show all columns
                angular.forEach($scope.gridOptions.columnDefs, function (column) {
                    if (column.field === controller.deleteField) {
                        column.visible = true;
                    } else {
                        column.visible = angular.isDefined(controller.columnsVisibility) ?
                            controller.columnsVisibility[column.field] : true;
                    }
                });
            }
            $scope.gridOptions.enableGridMenu = !hide;
        };

        /**
         * Actions to do when alphabetical ordering checked.
         *
         * @param {boolean} alphabetical
         */
        $scope.handleAlphabeticalOrderClick = function (alphabetical) {
            // indicate progress
            $scope.alphabeticalOrderingInProgress = true;
            // store changes
            TuProfileService.updateInstitution($scope.institutionId, {
                departmentsAlphabeticalOrder: alphabetical
            }).then(function (success) {
                if (success) {
                    // reload departments datagrid
                    controller.reloadDatagrid();
                }
                // indicate progress end
                $scope.alphabeticalOrderingInProgress = false;
            });
        };
        /**
         * Actions to do when add department button is clicked.
         */
        $scope.handleAddDepartmentClick = function () {
            // toggle add form
            TuProfileFactory.setDepartmentAddFormVisibility();
        };

        /**
         * Populates input text field for date field
         *
         * @param {string} filterName
         * @param {mixed} defaultValue
         */
        controller.applyDateFilter = function (filterName, defaultValue) {
            var filter = GridService.getFilterByField(controller.paginationOptions, filterName, defaultValue);
            if (angular.isDefined(filter) &&
                filter !== null
            ) {
                if (angular.isDefined(filter.startDate) &&
                    angular.isDefined(filter.endDate)
                ) {
                    if (filter.startDate !== null &&
                        filter.startDate !== null
                    ) {
                        $scope.filters.createdAt = {
                            startDate: parseInt(filter.startDate, 10),
                            endDate: parseInt(filter.endDate, 10)
                        };
                    } else {
                        $scope.filters.createdAt = {
                            startDate: null,
                            endDate: null
                        };
                    }
                }
            }
        };

        controller.getDepartmentsToDelete = function () {
            var departmentsIds = [];
            if ($scope.departmentsToDelete) {
                angular.forEach($scope.departmentsToDelete, function (remove, id) {
                    if (remove) {
                        departmentsIds.push(id);
                    }
                });
            }

            return departmentsIds;
        };

        controller.clearDepartmentsToDelete = function () {
            $scope.departmentsToDelete = {};
        };

        controller.hasDepartmentsToDelete = function () {
            var departmentsIds = controller.getDepartmentsToDelete();
            return departmentsIds.length !== 0;
        };

        $scope.isDeleteButtonDisabled = function () {

            return $scope.deleteInProgress ||
                !$scope.institutionId ||
                !controller.hasDepartmentsToDelete();
        };

        /**
         * Actions to do when department delete button is clicked.
         *
         * @returns {boolean}
         */
        $scope.handleDeleteClick = function () {
            if ($scope.deleteInProgress || !controller.hasDepartmentsToDelete()) {
                return false;
            }
            var modalOptions = {
                closeButtonText: 'Cancel',
                actionButtonText: 'Yes',
                actionButtonClass: 'danger',
                headerText: 'Departments Deletion',
                bodyText: 'Are you sure you wish to delete these departments?'
            };
            ModalService.show({}, modalOptions).then(function () {
                var departmentsIds = controller.getDepartmentsToDelete();
                $scope.deleteInProgress = true;
                TuProfileDepartmentsService.delete(departmentsIds).then(function (success) {
                    $scope.deleteInProgress = false;
                    if (success) {
                        $scope.departmentsToDelete = {};
                        // reload departments datagrid
                        //controller.reloadDatagrid();
                        var i = 0,
                            rows = $scope.gridOptions.data,
                            total = rows.length;

                        for (i; i < total; i++) {
                            if (rows[i] && rows[i].id && departmentsIds.indexOf(rows[i].id) !== -1) {
                                delete $scope.gridOptions.data[i];
                            }
                        }
                        TuProfileFactory.setIsDepartmentUpdated(true);
                    }
                    NotifierFactory.show(
                        success ? 'success' : 'error',
                        success ? 'Successfully!' : 'Failed!',
                        'Departments Deleted'
                    );
                });
            });
        };

        $scope.handleDatagridFiltersChanges = function (grid) {
            angular.forEach($scope.gridApi.grid.columns, function (column) {
                if (typeof column.filters !== 'undefined' && column.filters[0].term !== 'undefined') {
                    if (
                        column.filters[0].type === uiGridConstants.filter.SELECT &&
                        typeof column.filters[0].term === 'object' &&
                        column.filters[0].term !== null &&
                        typeof column.filters[0].term.value !== 'undefined'
                    ) {
                        column.filters[0].term = column.filters[0].term.value;
                    } else if (column.filters[0].term !== null &&
                        typeof column.filters[0].term !== 'undefined') {
                        GridService.applyFilters(column);
                    }
                }

                var filterColumn = column.filters[0].column ? column.filters[0].column : column.field;
                if (column.field === 'modifiedAt') {
                    if (
                        typeof column.filters !== 'undefined' &&
                        typeof column.filters[0].term !== 'undefined' &&
                        typeof column.filters[1].term !== 'undefined'
                    ) {
                        controller.paginationOptions['filter[' + filterColumn + ']'] = {
                            startDate: column.filters[0].term && isNaN(column.filters[0].term) && TimeService.isMoment(column.filters[0].term) ? column.filters[0].term.format('x') : column.filters[0].term,
                            endDate: column.filters[1].term && isNaN(column.filters[1].term)  && TimeService.isMoment(column.filters[1].term)  ? column.filters[1].term.format('x') : column.filters[1].term
                        };
                    } else {
                        controller.paginationOptions['filter[' + filterColumn + ']'] = null;
                    }
                } else if (typeof column.filters !== 'undefined' && column.filters[0].term !== 'undefined') {
                    controller.paginationOptions['filter[' + filterColumn + ']'] = column.filters[0].term;
                }
            });

            GridService.storeColumnsFilters(
                controller.gridFilterNameSpace,
                controller.paginationOptions
            );

            if (!$scope.gridOptions.columnDefs[5].filters[0].term) {
                $scope.filters.createdAt = {
                    startDate: null,
                    endDate: null
                };
            }

            var currentCreatedAt = null;
            for (var i = 0; i < grid.columns.length; i++) {
                for (var x = 0; x < grid.columns[i].filters.length; x++) {
                    // check if has active filters
                    if (grid.columns[i].filters[x].term !== undefined) {
                        controller.lastCreatedAt = angular.copy(currentCreatedAt);
                        return;
                    }
                }

                if (grid.columns[i].field === controller.modifiedAtField) {
                    currentCreatedAt = grid.columns[i].filters[0].term && grid.columns[i].filters[1].term ?
                        [grid.columns[i].filters[0].term, grid.columns[i].filters[1].term].join('|') : null;
                }
            }

            if (!currentCreatedAt && !controller.lastCreatedAt) {
                return;
            }


            controller.lastCreatedAt = angular.copy(currentCreatedAt);
        };

        $scope.handleDatagridDragAndDrop = function () {
            $scope.institutionData.departmentsAlphabeticalOrder = false;
            var i = 0,
                rows = $scope.gridApi.core.getVisibleRows(),
                total = rows.length,
                orderedIds = [];
            for (i; i < total; i++) {
                orderedIds.push(rows[i].entity.coreId);
            }
            TuProfileDepartmentsService.updateOrder($scope.institutionId, orderedIds).then(function (success) {
                NotifierFactory.show(
                    success ? 'success' : 'error',
                    success ? 'Successfully updated!' : 'Update failed!',
                    'Departments Order'
                );
            });
        };

        controller.reloadDatagrid = function () {
            var coreId = InstitutionFactory.getCoreId();
            if (!coreId) {
                return false;
            }
            $scope.isDatagridReloading = true;
            controller.isgridRendering = true;
            return TuProfileDepartmentsService.getAllDepartmentsByCoreId(coreId).then(function (response) {
                $scope.isDatagridReloading = false;
                $scope.gridOptions.data = response;
                $timeout(function(){
                    controller.isgridRendering = false;
                },200);

                return response;
            });
        };

        controller.handleGridVisibleChanges = function () {
            if ( $scope.gridApi.grid.selection.selectedCount === 1 && !$scope.selectedDepartmentId){
                     $scope.gridApi.selection.clearSelectedRows();
                 }
            var columnsVisibility = GridService.getColumnsVisibility(controller.gridVisibilityNameSpace),
            reload = false;
            if (columnsVisibility) {
                angular.forEach($scope.gridOptions.columnDefs, function (column, key) {
                    if (column.field !== 'delete' &&
                        columnsVisibility[column.field] === true &&
                        column.visible === false
                    ) {
                        if (typeof $scope.gridOptions.columnDefs[key].filter === 'undefined') {
                            $scope.gridOptions.columnDefs[key].filter = {};
                        }
                        if (typeof  $scope.gridOptions.columnDefs[key].filter !== 'undefined' &&
                            $scope.gridOptions.columnDefs[key].filter.hasOwnProperty('term') &&
                            $scope.gridOptions.columnDefs[key].filter.term) {
                            reload = true;
                        }
                        $scope.gridOptions.columnDefs[key].filter.term = null;
                        if (column.field === 'modifiedAt') {
                            delete $scope.gridOptions.columnDefs[key].filters[0].term;
                            reload = $scope.filters.createdAt.startDate? true : false;
                            $scope.filters.createdAt = {
                                startDate: null,
                                endDate: null
                            };
                        }
                    }
                });
            }
            controller.columnsVisibility = GridService.getGridColumnsVisibility($scope.gridOptions.columnDefs);
            GridService.storeColumnsVisibility(
                controller.gridVisibilityNameSpace,
                controller.columnsVisibility
            );
            if (reload) {
                var institutionCoreId = InstitutionFactory.getInstitutionIdDepartmentOverview();
                if (institutionCoreId) {
                    controller.initDataGrid(institutionCoreId);
                }
                else {
                    controller.reloadDatagrid();
                }
            }
        };

        controller.initWatches = function () {
            // watch for new departments
            WatchService.create($scope, TuProfileFactory.hasNewDepartment, function (newDepartmentId) {
                if (newDepartmentId) {
                    // reload datagrid
                    //controller.reloadDatagrid(InstitutionFactory.getCoreId());

                    controller.reloadDatagrid(InstitutionFactory.getCoreId()).then(function (departmentsList) {
                        if (departmentsList) {
                            var i = 0,
                                department = null,
                                total = departmentsList.length;
                            for (i; i < total; i++) {
                                department = departmentsList[i];
                                if (department.id === newDepartmentId) {
                                    TuProfileFactory.setDepartmentBeforeChanges(department);
                                    break;
                                }
                            }
                        }
                        // mark new department as selected in the datagrid
                        $scope.selectedDepartmentId = newDepartmentId;

                    }).finally(function () {
                        // open new department in edit mode
                        TuProfileFactory.setDepartmentEditFormVisibility(true);
                    });
                }
            });

            // watch for department add form visibility changes
            WatchService.create($scope, function () {
                return TuProfileFactory.isDepartmentAddFormVisible($scope.activeTab);
            }, function (visible) {
                visible = visible || TuProfileFactory.isDepartmentEditFormVisible($scope.activeTab);
                controller.toggleColumns(visible);
            });

            // watch for department edit form visibility changes
            WatchService.create($scope, function () {
                return TuProfileFactory.isDepartmentEditFormVisible($scope.activeTab);
            }, function (visible) {
                if (!visible) {
                    $scope.selectedDepartmentId = null;
                } else {
                    $scope.selectedDepartmentId = TuProfileFactory.getDepartmentId();
                }
                visible = visible || TuProfileFactory.isDepartmentAddFormVisible($scope.activeTab);
                controller.toggleColumns(visible);
            });
            // watch for client department upgrade form visibility changes
            WatchService.create($scope, function () {
                return TuProfileFactory.isClientDepartmentUpgradeFormVisible($scope.activeTab);
            }, function (visible) {
                visible = visible || TuProfileFactory.isDepartmentEditFormVisible($scope.activeTab);
                if (!visible) {
                    $scope.selectedDepartmentId = null;
                } else {
                    $scope.selectedDepartmentId = TuProfileFactory.getDepartmentId();
                }
                controller.toggleColumns(visible);
            });

            // listen to active institution changes
            WatchService.create($scope, InstitutionFactory.getCoreId, function (coreId) {
                if (coreId) {
                    controller.initDataGrid(coreId);
                }
            });

            //Reload grid on department update
            WatchService.create($scope, TuProfileFactory.getUpdateDepartmentsGrid, function (newValue, oldValue) {
                if (newValue !== oldValue) {
                    controller.clearDepartmentsToDelete();
                    controller.reloadDatagrid();
                    TuProfileFactory.setUpdateDepartmentsGrid(false);
                }
            });

            $rootScope.$on(constants.events.logout, controller.destruct);
        };

        var selectTemplate = '/scripts/shared/ui-grid/templates/selectFilterHeaderTemplate.html',
            selectCellTemplate = '/scripts/shared/ui-grid/templates/selectCellTemplate.html';
        $scope.gridOptions = {
            enableSorting: false,
            exporterMenuCsv: false,
            enableGridMenu: true,
            showGridFooter: true,
            selectedItems: $scope.selectedDepartmentId,
            enableFiltering: true,
            enableColumnResize: true,
            enableFullRowSelection: true,
            enableRowSelection: true,
            multiSelect: false,
            enableRowHeaderSelection: false,
            rowTemplate: '/scripts/components/profiles/tu/departments/datagrid/rowTemplate.html',
            columnDefs: [
                {
                    displayName: 'Delete',
                    field: controller.deleteField,
                    enableSorting: false,
                    enableHiding: false,
                    enableColumnResizing: false,
                    headerCellClass: 'text-center v-align',
                    headerCellTemplate: '/scripts/components/profiles/tu/departments/datagrid/deleteHeaderCellTemplate.html',
                    cellTemplate: '/scripts/components/profiles/tu/departments/datagrid/deleteCellTemplate.html',
                    maxWidth: 80
                },
                {
                    displayName: 'Core ID',
                    field: 'coreId',
                    maxWidth: 80,
                    visible: GridService.getVisibilityByField(controller.columnsVisibility, 'coreId', true),
                    filter: {
                        term: GridService.getFilterByField(controller.paginationOptions, 'coreId', ''),
                        condition: uiGridConstants.filter.CONTAINS
                    }
                },
                {
                    displayName: 'Full Name',
                    field: 'fullname',
                    visible: GridService.getVisibilityByField(controller.columnsVisibility, 'fullname', true),
                    filter: {
                        term: GridService.getFilterByField(controller.paginationOptions, 'fullname', ''),
                        condition: uiGridConstants.filter.CONTAINS
                    }
                },
                {
                    displayName: 'Department Type',
                    filter: {
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: TuProfileDepartmentsService.getDepartmentTypes(true),
                        term: GridService.getFilterByField(controller.paginationOptions, 'typeId')
                    },
                    visible: GridService.getVisibilityByField(controller.columnsVisibility, 'typeId', true),
                    field: 'typeId',
                    maxWidth: 160,
                    filterHeaderTemplate: selectTemplate,
                    cellTemplate: selectCellTemplate
                },
                {
                    displayName: 'Belongs To',
                    filter: {
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: TuProfileService.getBelongsToList(true),
                        term: GridService.getFilterByField(controller.paginationOptions, 'belongsTo')
                    },
                    field: 'belongsTo',
                    maxWidth: 130,
                    visible: GridService.getVisibilityByField(controller.columnsVisibility, 'belongsTo', true),
                    filterHeaderTemplate: selectTemplate,
                    cellTemplate: selectCellTemplate
                },
                {
                    displayName: 'Last update',
                    type: 'date',
                    field: controller.modifiedAtField,
                    cellFilter: 'date:\'medium\' : \'UTC\'',
                    filterCellFiltered: false,
                    visible: GridService.getVisibilityByField(controller.columnsVisibility, 'controller.modifiedAtField', true),
                    filters: [
                        {
                            visible: true,
                            condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL
                        },
                        {
                            condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL
                        }
                    ],
                    /*filter : {
                        term: GridService.getFilterByField(controller.paginationOptions, controller.modifiedAtField, ''),
                        applyTextFilter: controller.applyDateFilter(controller.modifiedAtField, '')
                    },*/
                    filterHeaderTemplate: '/scripts/components/profiles/tu/departments/datagrid/createdAtFilterHeaderTemplate.html'
                }
            ],
            onRegisterApi: function (gridApi) {
                var columnsVisibilityBeforeChanges = angular.copy($scope.gridOptions.columnDefs);
                $scope.gridApi = gridApi;
                gridApi.selection.on.rowSelectionChanged($scope, function (row) {
                    $scope.handleDatagridRowClick(row.entity);
                });
                gridApi.draggableRows.on.rowDropped($scope, function () {
                    $scope.handleDatagridDragAndDrop();
                });
                gridApi.core.on.rowsRendered($scope, function () {
                    $scope.isDatagridRendered = true;
                });
                gridApi.core.on.filterChanged($scope, function () {
                    $scope.handleDatagridFiltersChanges(this.grid);
                });
                gridApi.core.on.rowsVisibleChanged($scope, controller.handleGridVisibleChanges);
                // reset columns visibility
                GridService.resetExtend(columnsVisibilityBeforeChanges, $scope.gridOptions.columnDefs, $scope.filters);
            }
        };

        controller.initDataGrid = function (coreId) {
            if (!coreId) {
                return false;
            }
            controller.isgridRendering = true;
            TuProfileDepartmentsService.getAllDepartmentsByCoreId(coreId).then(function (response) {
                $scope.isDatagridReloading = false;
                $scope.gridOptions.data = response;
                $scope.gridOptions.minRowsToShow = response.length < 50 ? response.length : 50;
                $scope.departmentsToDelete = {};
                $timeout(function(){
                    controller.isgridRendering = false;
                },200);
            });
        };

        $scope.customCheckboxMessage = function () {
            if (UserFactory.isClient()) {
                return 'Deleting client departments is not permitted. Contact tusupport@qs.com to delete';
            }
            return 'To delete client departments go to Institution List backend';
        };

        controller.destruct = function () {
            alreadyInitialised = false;
        };

        controller.init = function () {
            controller.initWatches();
            InstitutionFactory.setInstitutionIdDepartmentOverview(null);
        };

        // listen to departments tab visibility changes
        WatchService.create($scope, TuProfileFactory.isDepartmentsTabSelected, function (isActive) {
            // if not already initialized and tab is active
            controller.isDepartmentsTabSelected = isActive;
            if (!alreadyInitialised && isActive) {
                controller.init();
                alreadyInitialised = true;
            }
        });
    };

    angular
        .module('qsHub')
        .controller('TuProfileDepartmentsController', [
            '$scope',
            '$rootScope',
            '$timeout',
            'constants',
            'NotifierFactory',
            'TuProfileFactory',
            'InstitutionFactory',
            'TuProfileDepartmentsService',
            'TuProfileService',
            'uiGridConstants',
            'UserFactory',
            'WatchService',
            'UiGridService',
            'TimeService',
            'DateRangeFixerService',
            'ModalService',
            App.controllers.tuProfileDepartments
        ]);

} (window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { controllers: {} });

    App.controllers.tuProfileDepartmentForm = function (
        $scope,
        $state,
        constants,
        NotifierFactory,
        TuProfileFactory,
        InstitutionFactory,
        UserFactory,
        TuProfileDepartmentsService,
        LoginService,
        ModalService,
        WatchService,
        InstitutionsListService
    ) {
        var controller = this,
            alreadyInitialised = false;

        controller.devMode = constants.dev;
        $scope.forms = {};
        $scope.upgradeInProgress = false;
        $scope.downgradeInProgress = false;
        $scope.addInProgress = false;
        $scope.editInProgress = false;
        $scope.newDepartment = {};
        $scope.newDepartmentBeforeChanges = {
            parentCoreId: null
        };
        $scope.campusList = [];
        $scope.department = {};
        $scope.belongsToList = [];
        controller.modifiedAtField = 'modifiedAt';
        controller.tuSupportEmail = constants.support.tu;

        $scope.getTuProfileDepartmentForm = function () {
            return controller;
        };

        /**
         * Is department data fetch in progress?
         *
         * @returns {boolean}
         */
        $scope.isFetchInProgress = function () {
            return !$scope.department || !$scope.department.id;
        };

        /**
         * Is department upgrade allowed?
         *
         * @returns {boolean}
         */
        $scope.allowUpgrade = function () {
            return TuProfileDepartmentsService.allowUpgrade($scope.department);
        };

        /**
         * Allowed to login as department?
         *
         * @returns {boolean}
         */
        $scope.allowLoginAs = function () {
            return TuProfileDepartmentsService.allowLoginAs($scope.department);
        };

        $scope.allowEdit = function () {
            if (!UserFactory.isClient() || !InstitutionsListService.isClientDepartment($scope.department.typeId)) {
                return true;
            }

            return UserFactory.isAllowedToLogin($scope.department.coreId);
        };

        controller.isLoginRestricted = function () {
            return UserFactory.isClient() && !UserFactory.isAllowedToLogin($scope.department.coreId);
        };

        /**
         * Is department downgrade allowed?
         *
         * @param {Object} department
         * @returns {boolean}
         */
        $scope.allowDowngrade = function () {
            return TuProfileDepartmentsService.allowDowngrade($scope.department);
        };

        /**
         * Is department downgrade disabled?
         *
         * @returns {boolean}
         */
        $scope.isDowngradeDisabled = function () {
            return TuProfileDepartmentsService.hasActiveSubscription($scope.department);
        };

        /**
         * Get department core ID.
         *
         * @returns {null|int}
         */
        controller.getDepartmentCoreId = function () {
            if (angular.isDefined($scope.department.coreId)) {
                return $scope.department.coreId;
            } else {
                var department = TuProfileFactory.getDepartmentBeforeChanges();
                return department && department.coreId ? department.coreId : null;
            }
        };

        /**
         * Actions to do when upgrade button clicked.
         *
         * @returns {boolean}
         */
        controller.handleUpgradeClick = function () {
            if (UserFactory.isClient()) {
                TuProfileFactory.setClientDepartmentUpgradeFormVisibility();
            } else {
                var modalOptions = {
                    closeButtonText: 'Cancel',
                    headerText: 'Upgrade To Client Department',
                    institutionCoreId: controller.getDepartmentCoreId(),
                    completeUpgradeClick: function () {
                        this.close();
                        if (!$scope.department || !$scope.department.id || $scope.upgradeInProgress) {
                            return false;
                        }
                        $scope.upgradeInProgress = true;
                        TuProfileDepartmentsService.upgrade($scope.department.id).then(controller.upgradeCallback);
                    }
                }, modalDefaults = {
                    backdrop: true,
                    keyboard: true,
                    modalFade: true,
                    templateUrl: '/scripts/components/profiles/tu/departments/modalUpgradeDepartmentView.html'
                };
                ModalService.show(modalDefaults, modalOptions);
            }
        };

        /**
         * Actions to do when upgrade request is finished.
         *
         * @param {boolean} success
         */
        controller.upgradeCallback = function (success) {
            if (success) {
                // get department instance before changes
                var departmentInstance = TuProfileFactory.getDepartmentBeforeChanges();
                // apply changes to original department
                departmentInstance.typeId = constants.typeId.clientDepartmentId;
                TuProfileDepartmentsService.formatList([departmentInstance]);
                // apply changes to active department
                $scope.department.typeId = constants.typeId.clientDepartmentId;
                $scope.department.typeName = TuProfileDepartmentsService.getTypeName(constants.typeId.clientDepartmentId);
                $scope.department.coreId = departmentInstance.coreId;
                InstitutionFactory.setActiveTab(true);
            }
            $scope.upgradeInProgress = false;

            NotifierFactory.show(
                success ? 'success' : 'error',
                success ? 'Upgraded successfully!' : 'Upgrade failed!',
                'Upgrade to Client'
            );
        };

        /**
         * Actions to do when downgrade button clicked.
         *
         * @returns {boolean}
         */
        $scope.handleDowngradeClick = function () {
            $scope.downgradeInProgress = true;
            var modalOptions = {
                closeButtonText: 'Cancel',
                headerText: 'Current Subscription(s)',
                institutionCoreId: controller.getDepartmentCoreId(),
                department: $scope.department,
                downgradeAndDelete: $scope.downgradeAndDelete,
                subscriptions: TuProfileDepartmentsService.hasActiveSubscription($scope.department),
                completeDowngradeClick: function (downgradeAndDelete) {
                    this.close();
                    if (modalOptions.subscriptions && !downgradeAndDelete) {
                        $state.go('staff.institutions.list', {coreId: modalOptions.institutionCoreId});
                    } else {
                        TuProfileDepartmentsService.downgrade($scope.department.id).then(controller.downgradeCallback);
                    }
                }
            }, modalDefaults = {
                backdrop: true,
                keyboard: true,
                modalFade: true,
                templateUrl: '/scripts/components/profiles/tu/departments/modalDowngradeDepartmentView.html'
            };
            ModalService.show(modalDefaults, modalOptions);
        };

        /**
         * Actions to do when downgrade request is finished.
         *
         * @param {boolean} success
         */
        controller.downgradeCallback = function (success) {
            if (success) {
                // get department instance before changes
                var departmentInstance = TuProfileFactory.getDepartmentBeforeChanges();
                // apply changes to original department
                departmentInstance.typeId = constants.typeId.simpleDepartmentId;
                TuProfileDepartmentsService.formatList([departmentInstance]);
                // apply changes to active department
                $scope.department.typeId = constants.typeId.simpleDepartmentId;
                $scope.department.typeName = TuProfileDepartmentsService.getTypeName(
                    constants.typeId.simpleDepartmentId
                );
            }
            $scope.downgradeInProgress = false;

            NotifierFactory.show(
                success ? 'success' : 'error',
                success ? 'Downgrade successfully!' : 'Downgrade failed!',
                'Downgrade to Simple'
            );
        };

        /**
         * Actions to do when login as button clicked.
         *
         * @returns {boolean}
         */
        $scope.handleLoginAsClick = function () {
            LoginService.getTuProfilesRedirect({ coreId: $scope.department.coreId });
            TuProfileFactory.setDepartmentEditFormVisibility();
            TuProfileFactory.setDepartment(null);
        };

        /**
         * Actions to do when department update button is clicked.
         *
         * @returns {boolean}
         */
        $scope.handleDepartmentUpdateClick = function () {
            if (!controller.isValid($scope.department)) {
                return;
            }
            $scope.editInProgress = true;
            TuProfileDepartmentsService.update($scope.department).then(controller.updateCallback);
        };

        $scope.setIsInvalidDepartmentNameUpdate = function (value) {
            $scope.isInvalidDepartmentNameUpdate = value;
        };

        $scope.setIsInvalidBelongsToUpdate = function (value) {
            $scope.isInvalidBelongsToUpdate = value;
        };

        /**
         * Actions to do when update request is finished.
         *
         * @param {Object} response
         */
        controller.updateCallback = function (response) {
            var success = false;
            if (response.hasOwnProperty('modifiedAt')) {
                success = true;
            }
            $scope.editInProgress = false;
            NotifierFactory.show(
                success ? 'success' : 'error',
                success ? 'Updated successfully!' : 'Update failed!',
                'Update Department'
            );
            if (success) {
                // get department instance before changes
                var departmentInstance = TuProfileFactory.getDepartmentBeforeChanges();
                // apply changes to department
                angular.forEach($scope.department, function (value, key) {
                    departmentInstance[key] = value;
                    if (key === controller.modifiedAtField) {
                        departmentInstance[key] = response.modifiedAt;
                    }
                });
                TuProfileDepartmentsService.formatList([departmentInstance]);
                // Update Programs grid
                TuProfileFactory.setIsDepartmentUpdated(true);
                //Update Departments grid
                if (InstitutionFactory.isDepartmentsAlphabeticalOrder()) {
                    TuProfileFactory.setUpdateDepartmentsGrid(true);
                }
            }
        };

        controller.isUpgradeDisabled = function () {
            return !controller.isValid($scope.department, false);
        };

        controller.isValid = function (item, triggerValidators)
        {
            triggerValidators = typeof triggerValidators === 'undefined' ? true : triggerValidators;

            var isEdit = !!item.id;
            if (triggerValidators) {
                if (isEdit) {
                    $scope.isInvalidDepartmentNameUpdate = false;
                    $scope.isInvalidBelongsToUpdate = false;
                } else {
                    $scope.isInvalidNewDepartmentName = false;
                    $scope.isInvalidNewBelongsTo = false;
                }
            }

            if (!item.name || !item.name.length) {
                if (triggerValidators) {
                    if (isEdit) {
                        $scope.isInvalidDepartmentNameUpdate = true;
                    } else {
                        $scope.isInvalidNewDepartmentName = true;
                    }
                } else {
                    return false;
                }
            }
            if (!item.belongsTo || !item.belongsTo.length) {
                if (triggerValidators) {
                    if (isEdit) {
                        $scope.isInvalidBelongsToUpdate = true;
                    } else {
                        $scope.isInvalidNewBelongsTo = true;
                    }
                } else {
                    return false;
                }
            }

            if (triggerValidators) {
                if (isEdit) {
                    if (
                        $scope.editInProgress ||
                        !$scope.forms.editDepartmentForm ||
                        !$scope.forms.editDepartmentForm.$valid ||
                        $scope.isInvalidBelongsToUpdate ||
                        $scope.isInvalidDepartmentNameUpdate
                    ) {
                        TuProfileFactory.setIsDepartmentRowSelected(false);
                        return false;
                    }
                } else {
                    if (
                        $scope.addInProgress ||
                        !$scope.forms.addDepartmentForm ||
                        !$scope.forms.addDepartmentForm.$valid ||
                        $scope.isInvalidNewDepartmentName ||
                        $scope.isInvalidNewBelongsTo
                    ) {
                        return false;
                    }
                }
            } else {
                return isEdit ?
                    (!$scope.editInProgress || !$scope.forms.editDepartmentForm.$valid) :
                    (!$scope.addInProgress || !$scope.forms.addDepartmentForm.$valid);
            }

            return true;
        };

        /**
         * Actions to do when new department create button is clicked.
         *
         * @returns {boolean}
         */
        $scope.handleDepartmentCreateClick = function () {
            if (!controller.isValid($scope.newDepartment)) {
                return;
            }

            $scope.addInProgress = true;
            $scope.newDepartment[controller.modifiedAtField] = null;
            TuProfileDepartmentsService.create($scope.newDepartment).then(controller.createCallback);
        };

        $scope.setIsInvalidNewDepartmentName = function (value) {
            $scope.isInvalidNewDepartmentName = value;
        };

        $scope.setIsInvalidNewBelongsTo = function (value) {
            $scope.isInvalidNewBelongsTo = value;
        };

        /**
         * Actions to do when create request is finished.
         *
         * @param {Object} response
         */
        controller.createCallback = function (response) {
            // enable add button
            $scope.addInProgress = false;
            // show notification message
            NotifierFactory.show(
                response ? 'success' : 'error',
                response ? 'Saved successfully!' : 'Saving failed!',
                'Department'
            );
            if (response) {
                var department = angular.copy($scope.newDepartment);
                department.id = response.insertedId;
                // set department type
                department.typeName = response.typeName;
                // set department typeId
                department.typeId = response.typeId;
                // set new department as active
                TuProfileFactory.setDepartment(department);
                // announce that new department was added
                TuProfileFactory.announceNewDepartment(department.id);
            }
        };

        controller.resetAddForm = function () {
            if ($scope.newDepartment.name) {
                $scope.newDepartment.name = null;
            }
            if ($scope.newDepartment.belongsTo) {
                $scope.newDepartment.belongsTo = null;
            }
            if ($scope.newDepartment.primaryCampusId) {
                $scope.newDepartment.primaryCampusId = null;
            }
            $scope.isInvalidNewDepartmentName = false;
            $scope.isInvalidNewBelongsTo = false;
        };

        /**
         * Toggle Client Department Upgrade Form
         */
        controller.toggleClientUpgradeForm = function () {
            TuProfileFactory.setDepartmentEditFormVisibility(true);
        };

        controller.initWatches = function () {
            // watch for core ID changes
            WatchService.create($scope, InstitutionFactory.getCoreId, function (parentCoreId) {
                $scope.newDepartmentBeforeChanges.parentCoreId = parentCoreId;
                $scope.newDepartment.parentCoreId = parentCoreId;
            });

            WatchService.create($scope, TuProfileFactory.getDepartment, function (newValue) {
                $scope.department = newValue;
                $scope.isInvalidDepartmentNameUpdate = false;
                $scope.isInvalidBelongsToUpdate = false;
                $scope.isInvalidNewDepartmentName = false;
                $scope.isInvalidNewBelongsTo = false;
            }, true);

            WatchService.create($scope, InstitutionFactory.getCampusData, function (newValue) {
                if (newValue) {
                    $scope.campusList = TuProfileDepartmentsService.formatCampusList(newValue);
                }
            }, true);

            // if department add form visible hide popovers
            WatchService.create($scope, function () {
                return TuProfileFactory.isDepartmentAddFormVisible($scope.activeTab);
            }, function (visible) {
                if (visible) {
                    controller.resetAddForm();
                }
            });

            // watch for department row selection changed
            WatchService.create($scope, function () {
                return TuProfileFactory.getIsDepartmentRowSelected();
            }, function (visible) {
                if (visible) {
                    $scope.isInvalidDepartmentNameUpdate = false;
                    $scope.isInvalidBelongsToUpdate = false;
                    $scope.isInvalidCampusUpdate = false;
                }
            });
        };

        controller.initialiseForm = function () {
            // get belongs to list
            $scope.belongsToList = TuProfileDepartmentsService.getBelongsToList();
            // prepare new department object
            $scope.newDepartment = angular.copy($scope.newDepartmentBeforeChanges);
        };

        controller.init = function () {
            controller.initWatches();
        };

        // listen to departments tab visibility changes
        WatchService.create($scope, TuProfileFactory.isDepartmentsTabSelected, function (isActive, wasActive) {
            // if not already initialized and tab is active
            if (!alreadyInitialised && isActive && !wasActive) {
                controller.init();
                alreadyInitialised = true;
            }
            if (isActive) {
                controller.initialiseForm();
                if ($scope.forms && $scope.forms.addDepartmentForm) {
                    $scope.forms.addDepartmentForm.$setUntouched();
                }
            }
        });
        controller.$onInit =function () {
            controller.init();
       };
    };

    angular
        .module('qsHub')
        .controller('TuProfileDepartmentForm', [
            '$scope',
            '$state',
            'constants',
            'NotifierFactory',
            'TuProfileFactory',
            'InstitutionFactory',
            'UserFactory',
            'TuProfileDepartmentsService',
            'LoginService',
            'ModalService',
            'WatchService',
            'InstitutionsListService',
            App.controllers.tuProfileDepartmentForm
        ]);

} (window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    App.services.TuProfileDepartmentsService = function (
        $resource,
        $q,
        $log,
        constants,
        TuProfileService,
        TuProfileFactory
    ) {
        var service = {
            deferred : {}
        };

        /**
         * Is department upgrade allowed?
         *
         * @param {Object} department
         * @returns {boolean}
         */
        service.allowUpgrade = function (department) {
            return department && department.typeId === constants.typeId.simpleDepartmentId;
        };

        /**
         * Allowed to login as department?
         *
         * @param {Object} department
         * @returns {boolean}
         */
        service.allowLoginAs = function (department) {
            return department && department.typeId === constants.typeId.clientDepartmentId;
        };

        /**
         * Is department downgrade allowed?
         *
         * @param {Object} department
         * @returns {boolean}
         */
        service.allowDowngrade = function (department) {
            return department && department.typeId === constants.typeId.clientDepartmentId;
        };

        /**
         * Get departments list API endpoint.
         *
         * @returns {$resource}
         */
        service.getDepartmentsListModel = function () {
            return $resource(constants.api.institutions.url + '/v1/list/departments/core-id/:coreId');
        };

        /**
         * Get departments upgarde API endpoint.
         *
         * @returns {$resource}
         */
        service.getUpgradeModel = function () {
            return $resource(constants.api.institutions.url + '/v1/department/:id/upgrade');
        };

        /**
         * Get departments downgrade API endpoint.
         *
         * @returns {$resource}
         */
        service.getDowngradeModel = function () {
            return $resource(constants.api.institutions.url + '/v1/department/:id/downgrade');
        };

        /**
         * Get departments update API endpoint.
         *
         * @returns {$resource}
         */
        service.getUpdateModel = function () {
            return $resource(constants.api.institutions.url + '/v1/department/:id', null, {
                update: {method:'PATCH'}
            });
        };

        /**
         * Get departments create API endpoint.
         *
         * @returns {$resource}
         */
        service.getCreateModel = function () {
            return $resource(constants.api.institutions.url + '/v1/department');
        };

        /**
         * Get departments delete API endpoint.
         *
         * @returns {$resource}
         */
        service.getDeleteModel = function () {
            return $resource(constants.api.institutions.url + '/v1/delete-department', null, {
                delete: {method:'POST'}
            });
        };

        /**
         * Get departments ordering API endpoint.
         *
         * @returns {$resource}
         */
        service.getOrderingModel = function () {
            return $resource(constants.api.institutions.url + '/v1/institution/:id/departmentsOrder', null, {
                update: {method:'PATCH'}
            });
        };

        /**
         * Get tu profile department upgrade requests end point.
         *
         * @returns {$resource}
         */
        service.getUpgradeRequestModel = function () {
            return $resource(constants.api.institutions.url + '/v1/department/:id/upgrade-request');
        };

        /**
         * Send TU profile department upgrade request.
         *
         * @param {String} departmentId
         * @param {String|null} comments
         * @returns {Promise}
         */
        service.sendUpgradeRequest = function (departmentId, comments) {
            if (!departmentId) {
                throw 'Missing Required';
            }

            var Api = service.getUpgradeRequestModel();
            return Api.save({id: departmentId}, {
                comments: comments
            }).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }

                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };


        /**
         * Get belongs to list.
         *
         * @returns {array}
         */
        service.getBelongsToList = function () {
            return TuProfileService.getBelongsToList();
        };

        /**
         * Format campus list to ID & name pairs.
         *
         * @param {array} campuses
         * @returns {array}
         */
        service.formatCampusList = function (campuses) {
            var campusList = [];
            angular.forEach(campuses, function (campus) {
                campusList.push({id: campus.id, title: campus.name});
            });

            return campusList;
        };

        /**
         * Upgrade department.
         *
         * @param {string} id
         * @returns {Promise}
         */
        service.upgrade = function (id) {
            var Api = service.getUpgradeModel();
            return Api.get({id:id}).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        /**
         * Downgrade department.
         *
         * @param {string} id
         * @returns {Promise}
         */
        service.downgrade = function (id) {
            var Api = service.getDowngradeModel();
            return Api.get({id:id}).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        /**
         * Update department.
         *
         * @param {Object} department
         * @returns {Promise}
         */
        service.update = function(department) {
            var UpdateModel = service.getUpdateModel(),
                dataToUpdate = {
                    name: department.name,
                    belongsTo: department.belongsTo,
                    primaryCampusId: department.primaryCampusId
                };
            return UpdateModel.update(
                {id: department.id},
                dataToUpdate
            ).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return data;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        /**
         * Create department.
         *
         * @param {Object} department
         * @returns {Promise}
         */
        service.create = function(department) {
            var CreateModel = service.getCreateModel(),
                dataToUpdate = {
                    name: department.name,
                    belongsTo: department.belongsTo,
                    primaryCampusId: department.primaryCampusId,
                    parentCoreId: department.parentCoreId
                };
            return CreateModel.save(
                null,
                dataToUpdate
            ).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                data.typeName = service.getTypeName(constants.typeId.simpleDepartmentId);
                data.typeId = constants.typeId.simpleDepartmentId;
                return data;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        /**
         * Delete department(s).
         *
         * @param {array} ids
         * @returns {Promise}
         */
        service.delete = function(ids) {
            var TuDepartmentModel = service.getDeleteModel();
            return TuDepartmentModel.delete(
                {},
                ids
            ).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        service.getAllDepartmentsByCoreId = function (coreId) {
            if (constants.dev) {
                var startTime = new Date().getTime(), endTime;
            }
            var Api = service.getDepartmentsListModel();
            return Api.get({
                coreId: coreId
            }).$promise.then(function (data) {
                if (constants.dev) {
                    endTime = new Date().getTime();
                    $log.log('success, got data: ', data, 'in ' + (endTime - startTime) + ' ms');
                }

                TuProfileFactory.setDepartments(data.results);

                return service.formatList(data.results);
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return [];
            });
        };

        /**
         * Delete department(s).
         *
         * @param {array} ids
         * @returns {Promise}
         */
        service.updateOrder = function(institutionId, orderedIds) {
            var TuDepartmentModel = service.getOrderingModel();
            return TuDepartmentModel.update(
                {id: institutionId},
                orderedIds
            ).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        /**
         * Get departments list.
         *
         * @param {Object} params
         * @returns {Promise}
         */
        service.getAllDepartments = function (institutionCoreId, queryParams, params) {
            if (constants.dev) {
                var startTime = new Date().getTime(), endTime;
            }
            queryParams.coreId = institutionCoreId;
            var Api = service.getDepartmentsListModel();
            return Api.get(queryParams).$promise.then(function (data) {
                if (constants.dev) {
                    endTime = new Date().getTime();
                    $log.log('success, got data: ', data, 'in ' + (endTime - startTime) + ' ms');
                }
                if (params) {
                    params.total(data.totalFiltered);
                    params.totalEntries = data.totalMatching;
                }

                TuProfileFactory.setDepartments(data.results);

                return service.formatList(data.results);
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return [];
            });
        };

        /**
         * Get department name by id.
         *
         * @param {int} departmentCoreId
         * @returns {string}
         */
        service.getDepartmentNameById = function (departmentCoreId) {
            var departmentsList = TuProfileFactory.getDepartments(),
                departmentName = '';
            angular.forEach(departmentsList, function (department) {
                if (department.coreId === departmentCoreId) {
                    departmentName = department.name;
                }
            });

            return departmentName;
        };
        
        /**
         * Format departments list.
         *
         * @param {Object} departmentsData
         * @returns {Object}
         */
        service.formatList = function (departmentsData) {
            if (departmentsData) {
                angular.forEach(departmentsData, function(departmentObject) {
                    departmentObject.fullname = departmentObject.parentName + ' > ' + departmentObject.name;
                     //@todo remove departmentObject.typeName
                    departmentObject.typeName = service.getTypeName(departmentObject.typeId);
                    if (departmentObject.typeId === service.simpleDepartmentId) {
                        departmentObject.clientDepartment = false;
                    }
                    else if(departmentObject.typeId === service.clientDepartmentId) {
                        departmentObject.clientDepartment = true;
                    }
                    //@todo remove departmentObject.belongsToName
                    departmentObject.belongsToName = TuProfileService.getBelongsToName(departmentObject.belongsTo);
                });
            }

            return departmentsData;
        };

        /**
         * Get Department Types list.
         *
         * @returns {array}
         */
        service.getDepartmentTypes = function (grid) {
            grid = grid || false;

            return grid ? [
                {value: constants.typeId.simpleDepartmentId, label: 'Simple Department'},
                {value: constants.typeId.clientDepartmentId, label: 'Client Department'}
            ] : [
                {id: constants.typeId.simpleDepartmentId, title: 'Simple Department'},
                {id: constants.typeId.clientDepartmentId, title: 'Client Department'}
            ];
        };

        /**
         * Get department type name by ID.
         *
         * @param {int} typeId
         * @returns {string}
         */
        service.getTypeName = function (typeId) {
            typeId = parseInt(typeId, 10);
            switch (typeId) {
                case constants.typeId.clientDepartmentId:
                    return 'Client Department';
                case constants.typeId.simpleDepartmentId:
                    return 'Simple Department';
                default:
                    return '';
            }
        };

        service.hasActiveSubscription = function (department) {
            if (department && department.subscriptions && department.subscriptions.hasOwnProperty('tu') &&
                department.subscriptions.tu.hasOwnProperty('advanced') &&
                department.subscriptions.tu.advanced &&
                department.subscriptions.tu.hasOwnProperty('startDate') &&
                department.subscriptions.tu.hasOwnProperty('endDate') &&
                department.subscriptions.tu.startDate > 0 &&
                department.subscriptions.tu.endDate > 0
            ) {
                return true;
            }

            return false;
        };

        return service;
    };

    angular
        .module('qsHub')
        .service('TuProfileDepartmentsService', [
            '$resource',
            '$q',
            '$log',
            'constants',
            'TuProfileService',
            'TuProfileFactory',
            App.services.TuProfileDepartmentsService
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers:{}});

    App.controllers.tuProfileSubjects = function (
        $scope,
        $rootScope,
        $state,
        $timeout,
        constants,
        TuProfileSubjectsService,
        TuProfileFactory,
        NotifierFactory,
        WatchService,
        InstitutionFactory,
        UserFactory,
        ClassificationsService
    ) {
        var controller = this,
            alreadyInitialised = false;

        controller.devMode = constants.dev;
        $scope.forms = {};
        $scope.subjectsTabSubmitInProgress = false;
        $scope.isDatagridReloading = true;

        /**
         * Store profile subjects data.
         *
         * @returns {boolean}
         */
        $scope.handleSubjectTabSubmit = function () {
            if (
                $scope.subjectsTabSubmitInProgress ||
                !$scope.forms.subjectsForm ||
                !$scope.forms.subjectsForm.$valid
            ) {
                return false;
            }
            $scope.subjectsTabSubmitInProgress = true;
            TuProfileSubjectsService.saveSubjectsTab($scope.tuProfile).then(function (success) {
                $scope.subjectsTabSubmitInProgress = false;
                NotifierFactory.show(
                    success ? 'success' : 'error',
                    success ? 'Saved successfully!' : 'Saving failed!',
                    'Selected Subjects'
                );
                ClassificationsService.get(
                    {'filter[institutionCoreId]=': $scope.tuProfile.coreId, page: 1, limit: 1}
                ).then(function (response) {
                    if (response && response.results && response.results.length) {
                        var classificationData = response.results[0];
                        var result = [];
                        angular.forEach(controller.subjects, function (subjectVal) {
                            angular.forEach(subjectVal, function (value) {
                                if (!!value && value.length) {
                                    angular.forEach(value, function (dvalue) {
                                        if ($scope.tuProfile.ugSubjects[dvalue.handle] ||
                                            $scope.tuProfile.pgSubjects[dvalue.handle]
                                        ) {
                                            if (dvalue.groupName === 'Business & Management') {
                                                result['Social Sciences'] = dvalue.groupName;
                                            } else {
                                                result[dvalue.groupName] = dvalue.groupName;
                                            }
                                        }
                                    });
                                }
                            });
                        });
                        var rangeObj = {1 : 'SP', 2 : 'FO', 3 : 'FO', 4 : 'FO', 5 : 'CO', 6 : 'FC'};
                        var range = Object.keys(result).length,
                            newSubjectRange = rangeObj[range] ? rangeObj[range] : "" ;
                        if (classificationData.subjectRange !== '' &&
                            (
                                classificationData.subjectRange !== newSubjectRange ||
                                newSubjectRange === 'SP'
                            )
                        ) {
                            classificationData.subjectRange = newSubjectRange;
                            ClassificationsService.calculateResearchIntensity(classificationData, result);
                            ClassificationsService.update(classificationData.id, classificationData);
                        }   
                    }
                });
            });
        };


        controller.reformatTuProfileSubjects = function () {
            if (angular.isDefined($state) && InstitutionFactory.isTuSubscribed() && !UserFactory.noAccess($state.current.name)) {
                $scope.isDatagridReloading = true;
                var data = TuProfileFactory.getData();
                if (data.ugSubjects) {
                    data.ugSubjects = TuProfileSubjectsService.formatSubjects(data.ugSubjects);
                }
                if (data.pgSubjects) {
                    data.pgSubjects = TuProfileSubjectsService.formatSubjects(data.pgSubjects);
                }
                $scope.isDatagridReloading = false;
                controller.resetTuProfileSubjects();
            } else {
                // if user doesn't have access, empty previously loaded data
                $scope.tuProfile = {};
                controller.subjects = {};
            }
        };

        controller.resetTuProfileSubjects = function () {
            var data = angular.copy($scope.tuProfileBeforeChanges);
            if (data.hasPhdSubjects) {
                $scope.tuProfile.hasPhdSubjects = data.hasPhdSubjects;
            }
            if (data.ugSubjects) {
                $scope.tuProfile.ugSubjects = angular.copy(TuProfileSubjectsService.formatSubjects(data.ugSubjects));
            }
            if (data.pgSubjects) {
                $scope.tuProfile.pgSubjects = angular.copy(TuProfileSubjectsService.formatSubjects(data.pgSubjects));
            }
        };

        controller.initData = function () {
            // get subjects list
            TuProfileSubjectsService.getSubjects().then(function(subjects) {
                // split subjects into chunks of 3
                controller.subjects = TuProfileSubjectsService.splitObject(subjects, 1);
                // share available subjects
                TuProfileFactory.setAvailableSubjects(subjects);
                // reformat TU profile subjects
                $timeout(function() {
                    controller.reformatTuProfileSubjects();
                },1000);
            });
        };

        controller.initEvents = function () {
            $scope.$on(constants.events.institutionTuProfileChanges, function() {
                alreadyInitialised = false;
                if (TuProfileFactory.isSubjectsTabSelected()) {
                    controller.reformatTuProfileSubjects();
                    alreadyInitialised = true;
                }
            });
        };

        controller.destruct = function () {
            alreadyInitialised = false;
        };

        controller.init = function () {
            controller.initData();
            controller.initEvents();
            $rootScope.$on(constants.events.logout, controller.destruct);
        };

        // listen to subjects tab visibility changes
        WatchService.create($scope, TuProfileFactory.isSubjectsTabSelected, function (isActive) {
            // if not already initialized and tab is active
            if (!alreadyInitialised && isActive) {
                controller.init();
                alreadyInitialised = true;
            }
            if (isActive && alreadyInitialised) {
                controller.resetTuProfileSubjects();
            }
        });
    };

    angular
        .module('qsHub')
        .controller('TuProfileSubjectsController', [
            '$scope',
            '$rootScope',
            '$state',
            '$timeout',
            'constants',
            'TuProfileSubjectsService',
            'TuProfileFactory',
            'NotifierFactory',
            'WatchService',
            'InstitutionFactory',
            'UserFactory',
            'ClassificationsService',
            App.controllers.tuProfileSubjects
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    App.services.TuProfileSubjectsService = function ($resource, $q, $log, constants) {
        var service = {
            overviewData : null,
            institutionData: null,
            subjects : null,
            deferred : {
                getOverviewData: null,
                getInstitutionData: null,
                getSubjects: null
            }
        };

        /**
         * Get subjects update API endpoint.
         *
         * @returns {$resource}
         */
        service.getTuSubjectsModel = function () {
            return $resource(constants.api.institutions.url + '/v1/tu-profile/:id', null, {
                update: { method:'PATCH' }
            });
        };

        /**
         * Call subjects API
         */
        service.getSubjectsModel = function () {
            return $resource(constants.api.institutions.url + '/v1/list/subjects?sorting[groupName]=asc');
        };

        /**
         * Fill one object keys with other object values.
         *
         * @param {Object} filters
         * @param {Object} object
         * @returns {Object}
         */
        service.filterObject = function (filters, object) {
            var filteredObject = angular.copy(filters);
            angular.forEach(filters, function (value, key) {
                if (value && typeof value === 'object') {
                    angular.forEach(value, function (subValue, subKey) {
                        if (subValue === null && object[key][subKey]) {
                            filteredObject[key][subKey] = object[key][subKey];
                        }
                    });
                } else if (value === null && object[key]) {
                    filteredObject[key] = object[key];
                }
            });

            return filteredObject;
        };

        /**
         * Get subjects list.
         *
         * @returns {Promise}
         */
        service.getSubjects = function () {
            // process already running
            if (!service.deferred.getSubjects) {
                service.deferred.getSubjects = $q.defer();
            } else {
                return service.deferred.getSubjects.promise;
            }
            var deferred = service.deferred.getSubjects;

            // check if data exists in cache
            if (service.roles) {
                deferred.resolve(service.roles);
            } else {
                var SubjectsModel = service.getSubjectsModel();
                SubjectsModel.get(function (data) {
                    var groupedSubjects = {};
                    if (data && data.results) {
                        // convert to groups
                        angular.forEach(data.results, function (subject) {
                            if (!groupedSubjects[subject.groupName]) {
                                groupedSubjects[subject.groupName] = [];
                            }
                            groupedSubjects[subject.groupName].push(subject);
                        });
                    }
                    service.subjects = groupedSubjects;
                    deferred.resolve(service.subjects);
                    deferred = null;
                }, function (error) {
                    //@todo log error
                    if (constants.dev) {
                        $log.log('request failed' + error);
                    }
                    if (error.status !== 401) {
                        service.subjects = [];
                    }
                    deferred.resolve(service.subjects);
                    deferred = null;
                    delete service.deferred.getSubjects;
                });
            }

            return deferred.promise;
        };

        /**
         * Format subjects to <handleName>:<bool> key value pairs.
         *
         * @param {Object} subjects
         * @returns {Object}
         */
        service.formatSubjects = function (subjects, justHandles) {
            var formattedSubjects = {};
            justHandles = justHandles || false;

            if (justHandles) {
                angular.forEach(subjects, function (assigned, subjectHandle) {
                    if (assigned) {
                        formattedSubjects[subjectHandle] = subjectHandle;
                    }
                });

            } else {
                var subjectKey;
                for (subjectKey in subjects) {
                    if (isNaN(subjectKey)) {
                        return subjects;
                    } else {
                        formattedSubjects[subjects[subjectKey]] = true;
                    }
                }
            }

            return formattedSubjects;
        };

        /**
         * Submit subjects tab data.
         *
         * @param {Object} profileData
         * @returns {Promise}
         */
        service.saveSubjectsTab = function (profileData) {
            var TuSubjectsModel = service.getTuSubjectsModel(),
                dataToUpdate = {
                    ugSubjects : profileData.ugSubjects,
                    pgSubjects : profileData.pgSubjects,
                    hasPhdSubjects : profileData.hasPhdSubjects
                };

            if (dataToUpdate.ugSubjects) {
                dataToUpdate.ugSubjects = service.formatSubjects(dataToUpdate.ugSubjects, true);
            }
            if (dataToUpdate.pgSubjects) {
                dataToUpdate.pgSubjects = service.formatSubjects(dataToUpdate.pgSubjects, true);
            }

            return TuSubjectsModel.update(
                {id: profileData.id},
                dataToUpdate
            ).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        service.splitObject = function (inputObject, size) {
            var index = 0,
                chunks = {},
                inChunks = [];

            angular.forEach(inputObject, function (value, key) {
                if (index === size) {
                    index = 0;
                    chunks = {};
                }
                if (index === size - 1) {
                    inChunks.push(chunks);
                }
                chunks[key] = value;
                index++;
            });

            return inChunks;
        };

        return service;
    };

    angular
        .module('qsHub')
        .service('TuProfileSubjectsService', [
            '$resource',
            '$q',
            '$log',
            'constants',
            App.services.TuProfileSubjectsService
        ]);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { controllers: {} });

    App.controllers.tuProfilePrograms = function (
        $scope,
        $rootScope,
        $state,
        $timeout,
        constants,
        NotifierFactory,
        TuProfileFactory,
        InstitutionFactory,
        TuProfileProgramsService,
        TuProfileService,
        uiGridConstants,
        WatchService,
        GridService,
        ModalService
    ) {
        var controller = this,
            alreadyInitialised = false;

        controller.devMode = constants.dev;
        controller.upgradeEmailsTo = constants.emails.upgradeTu;
        controller.deleteField = 'delete';
        $scope.institutionId = InstitutionFactory.getId();
        $scope.tuProfileId = TuProfileFactory.getId();
        $scope.programsToDelete = {};
        $scope.alphabeticalOrderingInProgress = false;
        $scope.deleteInProgress = false;
        $scope.isDatagridReloading = false;
        $scope.isDatagridRendered = false;

        controller.gridFilterNameSpace = TuProfileFactory.getIsProgramOverview() ?
            constants.gridNameSpaces.programsOvGridFilters :
            constants.gridNameSpaces.programsGridFilters;

        controller.gridVisibilityNameSpace = TuProfileFactory.getIsProgramOverview() ?
            constants.gridNameSpaces.programsOvGridVisibility :
            constants.gridNameSpaces.programsGridVisibility;

        controller.paginationOptions = GridService.getColumnsFilters(
            controller.gridFilterNameSpace,
            []
        );

        controller.columnsVisibility = GridService.getColumnsVisibility(
            controller.gridVisibilityNameSpace
        );

        $scope.getTuProfileProgramsController = function () {
            return controller;
        };
        // watch for institution changes
        WatchService.create($scope, InstitutionFactory.getId, function (id) {
            $scope.institutionId = id;
        });

        // watch for TU profile changes
        WatchService.create($scope, TuProfileFactory.getId, function (id) {
            $scope.tuProfileId = id;
            $scope.tuProfileData = TuProfileFactory.getData();
        });

        /**
         * Actions to do when programs table row is clicked.
         *
         * @param {object} program
         */
        $scope.handleDatagridRowClick = function (program) {
            if (!$scope.tuIsAdvanced && !$scope.basicProgramsEnabled && !$scope.crimsonIsAdvanced) {
                return false;
            }
            TuProfileFactory.resetProgramForm();
            $scope.selectedProgramId = ($scope.selectedProgramId === program.id) ? null : program.id;
            // store link to program instance
            TuProfileFactory.setProgramBeforeChanges(program);
            // create new copy of program for making changes in
            program = angular.copy(program);
            TuProfileFactory.setProgramEditFormVisibility(
                $scope.selectedProgramId === program.id && program.id !== 'undefined'
            );
            TuProfileFactory.setProgram(program);
        };

        /**
         * Toggle datagrid columns visibility.
         *
         * @param {boolean} hide
         */
        controller.toggleColumns = function (hide) {
            if (!$scope.gridOptions) {
                return;
            }

            if (hide) {
                // hide delete column
                angular.forEach($scope.gridOptions.columnDefs, function (column) {
                    if (column.field !== controller.deleteField) {
                        column.visible = angular.isDefined(controller.columnsVisibility) ?
                            controller.columnsVisibility[column.field] : true;
                    } else {
                        column.visible = false;
                    }
                });
            } else {
                // show all columns
                angular.forEach($scope.gridOptions.columnDefs, function (column) {
                    if (column.field === controller.deleteField) {
                        column.visible = true;
                    } else {
                        column.visible = angular.isDefined(controller.columnsVisibility) ?
                            controller.columnsVisibility[column.field] : true;
                    }
                });
            }
            $scope.gridOptions.enableGridMenu = !hide;
        };

        /**
         * Actions to do when alphabetical ordering checked.
         *
         * @param {boolean} alphabetical
         */
        $scope.handleAlphabeticalOrderClick = function (alphabetical) {
            // indicate progress
            $scope.alphabeticalOrderingInProgress = true;
            // store changes
            TuProfileService.updateTuProfile($scope.tuProfileId, {
                programsAlphabeticalOrder: alphabetical
            }).then(function (success) {
                if (success) {
                    // reload programs datagrid
                    controller.reloadDatagrid();
                }
                // indicate progress end
                $scope.alphabeticalOrderingInProgress = false;
            });
        };

        /**
         * Actions to do when add program button is clicked.
         */
        $scope.handleAddProgramClick = function () {
            TuProfileFactory.setProgramAddFormVisibility();
            TuProfileFactory.setProgram({});
        };

        controller.getProgramsToDelete = function () {
            var programsIds = [];
            if ($scope.programsToDelete) {
                angular.forEach($scope.programsToDelete, function (remove, id) {
                    if (remove) {
                        programsIds.push(id);
                    }
                });
            }

            return programsIds;
        };

        controller.hasProgramsToDelete = function () {
            var departmentsIds = controller.getProgramsToDelete();
            return departmentsIds.length !== 0;
        };

        $scope.isDeleteButtonDisabled = function () {
            return $scope.deleteInProgress ||
                !$scope.institutionId ||
                !controller.hasProgramsToDelete() || (!$scope.tuIsAdvanced && !$scope.basicProgramsEnabled && !$scope.crimsonIsAdvanced);
        };

        controller.clearProgramsToDelete = function () {
            $scope.programsToDelete = {};
        };

        /**
         * Actions to do when program delete button is clicked.
         *
         * @returns {boolean}
         */
        $scope.handleDeleteClick = function () {
            if ($scope.deleteInProgress || !controller.hasProgramsToDelete() || (!$scope.tuIsAdvanced && !$scope.basicProgramsEnabled && !$scope.crimsonIsAdvanced)) {
                return false;
            }
            var modalOptions = {
                closeButtonText: 'Cancel',
                actionButtonText: 'Yes',
                actionButtonClass: 'danger',
                headerText: 'Programs Deletion',
                bodyText: 'Are you sure you wish to delete these programs?'
            };
            ModalService.show({}, modalOptions).then(function () {
                var programsIds = controller.getProgramsToDelete();
                $scope.deleteInProgress = true;
                TuProfileProgramsService.delete(programsIds).then(function (success) {
                    $scope.deleteInProgress = false;
                    if (success) {
                        $scope.programsToDelete = {};
    
                        var i = 0,
                            rows = $scope.gridOptions.data,
                            total = rows.length;
    
                        for (i; i < total; i++) {
                            if (rows[i] && rows[i].id && programsIds.indexOf(rows[i].id) !== -1) {
                                delete $scope.gridOptions.data[i];
                            }
                        }
                    }
                    NotifierFactory.show(
                        success ? 'success' : 'error',
                        success ? 'Deleted successfully!' : 'Deletion failed!',
                        'Programs Deletion'
                    );
                });
            });
        };

        $scope.handleDatagridDragAndDrop = function () {
            if (!$scope.tuIsAdvanced && !$scope.basicProgramsEnabled && !$scope.crimsonIsAdvanced) {
                return false;
            }
            $scope.tuProfileData.programsAlphabeticalOrder = false;
            var i = 0,
                rows = $scope.gridApi.core.getVisibleRows(),
                total = rows.length,
                orderedIds = [];

            for (i; i < total; i++) {
                if (typeof orderedIds[rows[i].entity.belongsTo] === 'undefined') {
                  orderedIds[rows[i].entity.belongsTo] = [];
                }
                orderedIds[rows[i].entity.belongsTo].push({'order': i, 'id': rows[i].entity.coreId});
            }
            TuProfileProgramsService.updateOrder($scope.tuProfileId, orderedIds).then(function (success) {
                NotifierFactory.show(
                    success ? 'success' : 'error',
                    success ? 'Successfully updated!' : 'Update failed!',
                    'Programs Order'
                );
            });
        };

        $scope.handleDatagridFiltersChanges = function (grid) {
            GridService.resetConditions($scope.gridApi.grid.columns);
            angular.forEach($scope.gridApi.grid.columns, function (column) {
                if (typeof column.filters !== 'undefined' && column.filters[0].term !== 'undefined') {
                    if (
                        column.filters[0].type === uiGridConstants.filter.SELECT &&
                        typeof column.filters[0].term === 'object' &&
                        column.filters[0].term !== null &&
                        typeof column.filters[0].term.value !== 'undefined'
                    ) {
                        column.filters[0].term = column.filters[0].term.value;
                    } else if (column.filters[0].term !== null &&
                        typeof column.filters[0].term !== 'undefined') {
                        GridService.applyFilters(column);
                    }
                }
                var filterColumn = column.filters[0].column ? column.filters[0].column : column.field;
                if (typeof column.filters !== 'undefined' && column.filters[0].term !== 'undefined') {
                    controller.paginationOptions['filter[' + filterColumn + ']'] = column.filters[0].term;
                }
            });

            GridService.storeColumnsFilters(
                controller.gridFilterNameSpace,
                controller.paginationOptions
            );
        };

        controller.reloadDatagrid = function (coreId) {
            if (!coreId) {
                coreId = InstitutionFactory.getCoreId();
            }
            if (!coreId) {
                return false;
            }
            $scope.isDatagridReloading = true;
            return TuProfileProgramsService.getAllProgramsByCoreId(coreId).then(function (response) {
                $scope.isDatagridReloading = false;

                $scope.gridOptions.data = response;

                return response;
            });
        };

        controller.handleGridVisibleChanges = function () {
            if($scope.gridApi.grid.selection.selectedCount === 1 && !$scope.selectedProgramId){
                $scope.gridApi.selection.clearSelectedRows();
            }
            var columnsVisibility = GridService.getColumnsVisibility(controller.gridVisibilityNameSpace),
            reload = false;
            if (columnsVisibility) {
                angular.forEach($scope.gridOptions.columnDefs, function (column, key) {
                    if (column.field !== 'delete' &&
                        (columnsVisibility[column.field] === true || typeof columnsVisibility[column.field] === 'undefined') &&
                        column.visible === false
                    ) {
                        if (typeof $scope.gridOptions.columnDefs[key].filter === 'undefined') {
                            $scope.gridOptions.columnDefs[key].filter = {};
                        }
                        if (typeof  $scope.gridOptions.columnDefs[key].filter !== 'undefined' &&
                            $scope.gridOptions.columnDefs[key].filter.hasOwnProperty('term') &&
                            $scope.gridOptions.columnDefs[key].filter.term) {
                            reload = true;
                        }
                        $scope.gridOptions.columnDefs[key].filter.term = null;
                        if (column.field === 'modifiedAt') {
                            delete $scope.gridOptions.columnDefs[key].filters[0].term;
                            $scope.filters.lastLoginAtRange = {
                                startDate: null,
                                endDate: null
                            };
                        }
                    }
                });
            }

            controller.columnsVisibility = GridService.getGridColumnsVisibility($scope.gridOptions.columnDefs);
            GridService.storeColumnsVisibility(
                controller.gridVisibilityNameSpace,
                controller.columnsVisibility
            );
            if (reload) {
                var institutionCoreId = InstitutionFactory.getInstitutionIdTuProgramsOverview();
                if (institutionCoreId) {
                    controller.initDataGrid(institutionCoreId);
                }
                else {
                    controller.reloadDatagrid();
                }
            }
        };

        controller.initWatches = function () {
            // watch for new programs
            WatchService.create($scope, TuProfileFactory.hasNewProgram, function (newProgramId) {
                if (newProgramId) {
                    // reload datagrid
                    controller.reloadDatagrid().then(function (programsList) {
                        if (programsList) {
                            var i = 0,
                                program = null,
                                total = programsList.length;
                            for (i; i < total; i++) {
                                program = programsList[i];
                                if (program.id === newProgramId) {
                                    TuProfileFactory.setProgramBeforeChanges(program);
                                    break;
                                }
                            }
                        }
                        // mark new program as selected in the datagrid
                        $scope.selectedProgramId = newProgramId;
                        // open new program in edit mode
                        TuProfileFactory.setProgramEditFormVisibility(true);
                    });
                }
            });

            // watch for program add form visibility changes
            WatchService.create($scope, function () {
                return TuProfileFactory.isProgramAddFormVisible($scope.activeTab);
            }, function (visible) {
                visible = visible || TuProfileFactory.isProgramEditFormVisible($scope.activeTab);
                controller.toggleColumns(visible);
            });

            // watch for department update changes
            WatchService.create($scope, TuProfileFactory.getIsDepartmentUpdated, function (isUpdated) {
                if (isUpdated) {
                    if ($scope.gridOptions.data !== undefined &&
                        $scope.gridOptions.data.length
                    ) {
                        controller.reloadDatagrid();
                    }
                    TuProfileFactory.setIsDepartmentUpdated(false);
                }
            });

            // watch for program edit form visibility changes
            WatchService.create($scope, function () {
                return TuProfileFactory.isProgramEditFormVisible($scope.activeTab);
            }, function (visible) {
                if (!visible) {
                    $scope.selectedProgramId = null;
                } else {
                    $scope.selectedProgramId = TuProfileFactory.getProgramId();
                }
                visible = visible || TuProfileFactory.isProgramAddFormVisible($scope.activeTab);
                controller.toggleColumns(visible);
            });

            // listen to active institution changes
            WatchService.create($scope, InstitutionFactory.getCoreId, function (coreId) {
                if (coreId) {
                    controller.initDataGrid(coreId);
                }
            });

            //Reload programme grid on programme update
            WatchService.create($scope, TuProfileFactory.getUpdateProgramGrid, function (newValue, oldValue) {
                if (newValue !== oldValue) {
                    controller.reloadDatagrid();
                    TuProfileFactory.setUpdateProgramGrid(false);
                }
            });

            $rootScope.$on(constants.events.logout, controller.destruct);
        };

        controller.initEvents = function () {
            $scope.$on(constants.events.institutionTuProfileChanges, function () {
                alreadyInitialised = false;
                if (TuProfileFactory.isProgramsTabSelected()) {
                    controller.initDataGrid();
                    alreadyInitialised = true;
                }
            });
        };
        var selectTemplate = '/scripts/shared/ui-grid/templates/selectFilterHeaderTemplate.html',
            selectCellTemplate = '/scripts/shared/ui-grid/templates/selectCellTemplate.html';
        $scope.gridOptions = {
            enableSorting: false,
            exporterMenuCsv: false,
            enableGridMenu: true,
            showGridFooter: true,
            selectedItems: $scope.selectedProgramId,
            enableFiltering: true,
            enableColumnResize: true,
            enableFullRowSelection: true,
            enableRowSelection: true,
            multiSelect: false,
            enableRowHeaderSelection: false,
            rowTemplate: '/scripts/components/profiles/tu/programs/datagrid/rowTemplate.html',
            columnDefs: [
                {
                    displayName: 'Delete',
                    field: controller.deleteField,
                    enableSorting: false,
                    enableColumnResizing: false,
                    enableHiding: false,
                    headerCellClass: 'text-center vertical-align-middle',
                    headerCellTemplate: '/scripts/components/profiles/tu/programs/datagrid/deleteHeaderCellTemplate.html',
                    cellTemplate: '/scripts/components/profiles/tu/programs/datagrid/deleteCellTemplate.html',
                    maxWidth: 80
                },
                {
                    displayName: 'Program Name',
                    field: 'name',
                    filter: {
                        term: GridService.getFilterByField(controller.paginationOptions, 'name', ''),
                        condition: uiGridConstants.filter.CONTAINS
                    }
                },
                {
                    displayName: 'Belongs To',
                    filter: {
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: TuProfileService.getBelongsToList(true),
                        term: GridService.getFilterByField(controller.paginationOptions, 'belongsTo')
                    },
                    field: 'belongsTo',
                    maxWidth: 130,
                    visible: GridService.getVisibilityByField(controller.columnsVisibility, 'belongsTo', true),
                    filterHeaderTemplate: selectTemplate,
                    cellTemplate: selectCellTemplate
                },
                {
                    displayName: 'URL Landing Page',
                    field: 'url',
                    visible: GridService.getVisibilityByField(controller.columnsVisibility, 'url', true),
                    filter: {
                        term: GridService.getFilterByField(controller.paginationOptions, 'url', ''),
                        condition: uiGridConstants.filter.CONTAINS
                    }
                },
                {
                    displayName: 'Department',
                    field: 'departmentName',
                    visible: GridService.getVisibilityByField(controller.columnsVisibility, 'departmentName', true),
                    filter: {
                        term: GridService.getFilterByField(controller.paginationOptions, 'departmentName', ''),
                        condition: uiGridConstants.filter.CONTAINS
                    }
                }
            ],
            onRegisterApi: function (gridApi) {
                var columnsVisibilityBeforeChanges = angular.copy($scope.gridOptions.columnDefs);
                $scope.gridApi = gridApi;
                gridApi.selection.on.rowSelectionChanged($scope, function (row) {
                    $scope.handleDatagridRowClick(row.entity);
                });
                gridApi.draggableRows.on.rowDropped($scope, function () {
                    $scope.handleDatagridDragAndDrop();
                });
                gridApi.core.on.rowsRendered($scope, function () {
                    $timeout(function(){
                        $scope.isDatagridRendered = true;
                    },200);
                });
                gridApi.core.on.filterChanged($scope, function () {
                    $scope.handleDatagridFiltersChanges(this.grid);
                });
                gridApi.core.on.rowsVisibleChanged($scope, controller.handleGridVisibleChanges);
                // reset columns visibility
                GridService.resetExtend(columnsVisibilityBeforeChanges, $scope.gridOptions.columnDefs);
            }
        };

        controller.initDataGrid = function (coreId) {
            if (!coreId) {
                coreId = InstitutionFactory.getCoreId();
            }
            if (!coreId) {
                return false;
            }

            TuProfileProgramsService.getAllProgramsByCoreId(coreId).then(function (response) {
                $scope.isDatagridReloading = false;
                $scope.gridOptions.data = response;
                $scope.gridOptions.minRowsToShow = response.length < 50 ? response.length : 50;
                $scope.programsToDelete = {};
            });
        };

        controller.destruct = function () {
            alreadyInitialised = false;
        };

        controller.init = function () {
            controller.initWatches();
            controller.initEvents();
            InstitutionFactory.setInstitutionIdTuProgramsOverview(null);
        };

        // listen to departments tab visibility changes
        WatchService.create($scope, TuProfileFactory.isProgramsTabSelected, function (isActive) {
            // if not already initialized and tab is active
            if (!alreadyInitialised && isActive && $state.current.name === 'clients.profiles.tu.programs') {
                controller.init();
                alreadyInitialised = true;
            }
        });
    };

    angular
        .module('qsHub')
        .controller('TuProfileProgramsController', [
            '$scope',
            '$rootScope',
            '$state',
            '$timeout',
            'constants',
            'NotifierFactory',
            'TuProfileFactory',
            'InstitutionFactory',
            'TuProfileProgramsService',
            'TuProfileService',
            'uiGridConstants',
            'WatchService',
            'UiGridService',
            'ModalService',
            App.controllers.tuProfilePrograms
        ]);

} (window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { controllers: {} });

    App.controllers.tuProfileProgramForm = function (
        $scope,
        constants,
        NotifierFactory,
        TuProfileFactory,
        InstitutionFactory,
        TuProfileService,
        TuProfileProgramsService,
        TuProfileDepartmentsService,
        UrlService,
        ObjectService,
        WatchService
    ) {
        var controller = this,
            alreadyInitialised = false;

        controller.urlPattern = UrlService.getPattern();

        $scope.getTuProfileProgramFormController = function () {
            return controller;
        };
        $scope.InstitutionNameTuPrograms = null;
        controller.devMode = constants.dev;
        controller.upgradeProgramEmailsTo = constants.emails.upgradeTu;
        $scope.forms = {};
        $scope.addInProgress = false;
        $scope.editInProgress = false;
        $scope.newProgram = {
            belongsTo: null
        };
        $scope.newProgramBeforeChanges = {
            institutionCoreId: null
        };
        $scope.departmentsList = {};
        $scope.departmentsListArr = [];
        $scope.isClientDepartment = null;

        $scope.toggleProgramUpgradeForm = function () {
            TuProfileFactory.setProgramUpgradeFormVisibility();
        };

        $scope.toggleProgramAddForm = function () {
            controller.programFormVisibilityWatch();
            TuProfileFactory.setProgramAddFormVisibility();
        };

        $scope.toggleProgramEditForm = function () {
            TuProfileFactory.setProgramEditFormVisibility();
        };

        /**
         * Is program data fetch in progress?
         *
         * @returns {boolean}
         */
        $scope.isFetchInProgress = function () {
            return !$scope.program || !$scope.program.id;
        };

        /**
         * Get has errors flag.
         *
         * @returns {boolean|*}
         */
        $scope.getHasErrors = function () {
            return TuProfileProgramsService.getHasErrors();
        };

        $scope.setHasErrors = function (value) {
            TuProfileProgramsService.setHasErrors(value);
        };

        $scope.setIsInvalidName = function (value) {
            $scope.isInvalidName = value;
        };

        $scope.setIsInvalidUrl = function (value) {
            $scope.isInvalidUrl = value;
        };

        $scope.setIsInvalidDepartment = function (value) {
            $scope.isInvalidDepartment = value;
        };

        controller.restrictBelongsToOptions = function (departmentCoreId) {
            if (!departmentCoreId) {
                return false;
            }
            // reset array keys
            if (angular.isArray($scope.departmentsList)) {
                $scope.departmentsList = $scope.departmentsList.filter(function () { return true; });
            }

            var total = $scope.departmentsList.length,
                belongsTo,
                i = 0;
            for (i; i < total; ++i) {
                if ($scope.departmentsList[i].coreId === departmentCoreId) {
                    belongsTo = $scope.departmentsList[i].belongsTo;
                    break;
                }
            }
            if (!belongsTo) {
                return false;
            }
            $scope.belongsToList = TuProfileService.getBelongsToList();
            angular.forEach($scope.belongsToList, function (item) {
                if (belongsTo === 'all') {
                    item.disabled = false;
                } else if (belongsTo === 'ug') {
                    item.disabled = item.id !== 'ug';
                } else if (belongsTo === 'pg') {
                    item.disabled = item.id !== 'pg';
                } else if (belongsTo === 'mba') {
                    item.disabled = item.id !== 'mba';
                }
            });
            // on edit, when department belongs to changed, the program belongs to should become empty
            angular.forEach($scope.belongsToList, function (item) {
                if (item.disabled && $scope.program.belongsTo === item.id) {
                    delete($scope.program.belongsTo);
                }
            });
        };

        controller.handleDepartmentChanges = function (coreId) {
            $scope.isInvalidDepartment = false;
            controller.restrictBelongsToOptions(coreId);
        };

        $scope.setIsInvalidBelongsTo = function (value) {
            $scope.isInvalidBelongsTo = value;
        };

        controller.departmentExists = function (departmentCoreId) {
            var exists = false;
            angular.forEach($scope.departmentsList, function (item) {
                if (item.coreId && item.coreId === departmentCoreId) {
                    exists = true;
                }
            });
            return exists;
        };

        controller.validateProgram = function (program) {
            $scope.isInvalidName = false;
            $scope.isInvalidUrl = false;
            $scope.isInvalidDepartment = false;
            $scope.isInvalidBelongsTo = false;
            
            if (!angular.isDefined(program.name) || !(angular.isDefined(program.name) && program.name.length > 0)) {
                $scope.isInvalidName = true;
            }
            else {
                if (program.name.length > 255) {
                    $scope.isInvalidName = true;
                }
            }
            if (!angular.isDefined(program.url) || !program.url   || program.url.length < 1 || program.url.length > 255) {
                $scope.isInvalidUrl = true;
            }
            if (!!program.url && !(program.url.match(controller.urlPattern))) {
                $scope.isInvalidUrl = true;
            }
            if (!InstitutionFactory.getInstitutionIdTuProgramsOverview() && !!program.url && !(program.url.match(UrlService.getCmtPattern())) && $scope.basicProgramsEnabled) {
                $scope.isInvalidUrl = true;
            }
            if (!angular.isDefined(program.departmentCoreId) || !controller.departmentExists(program.departmentCoreId)) {
                $scope.isInvalidDepartment = true;
            }
            if (!angular.isDefined(program.belongsTo)) {
                $scope.isInvalidBelongsTo = true;
            }
            if (InstitutionFactory.getInstitutionIdTuProgramsOverview() && !!program.url && !(program.url.match(UrlService.getCmtPattern())) && $scope.basicProgramsEnabledTuProgramsOverview) {
                $scope.isInvalidUrl = true;
            }
           
            return $scope.isInvalidName ||
                $scope.isInvalidUrl ||
                $scope.isInvalidDepartment ||
                $scope.isInvalidBelongsTo;

        };

        controller.resetValidation = function () {
            $scope.isInvalidName = false;
            $scope.isInvalidUrl = false;
            $scope.isInvalidDepartment = false;
            $scope.isInvalidBelongsTo = false;
        };

        /**
         * Actions to do when program update button is clicked.
         *
         * @returns {boolean}
         */
        $scope.handleProgramUpdateClick = function () {
            TuProfileProgramsService.setHasErrors(false);
            var isInvalidProgram = controller.validateProgram($scope.program);
            if (
                $scope.editInProgress ||
                !$scope.forms.editProgramForm ||
                !$scope.forms.editProgramForm.$valid ||
                isInvalidProgram
            ) {
                $scope.setHasErrors(true);
                NotifierFactory.show(
                    'error',
                    "Some fields don't pass validation. Please correct the fields and try again",
                    'Program Update'
                );
                return false;
            }
            $scope.editInProgress = true;
            TuProfileProgramsService.update($scope.program).then(controller.updateCallback);
        };

        /**
         * Actions to do when update request is finished.
         *
         * @param {boolean} success
         */
        controller.updateCallback = function (success) {
            $scope.editInProgress = false;
            NotifierFactory.show(
                success ? 'success' : 'error',
                success ? 'successfully!' : 'failure!',
                'Program Updated'
            );
            if (success) {
                // get program instance before changes
                var programInstance = TuProfileFactory.getProgramBeforeChanges();
                // apply changes to program
                angular.forEach($scope.program, function (value, key) {
                    programInstance[key] = value;
                });
                if (!$scope.isClientDepartment) {
                    programInstance.departmentName = TuProfileDepartmentsService.getDepartmentNameById(
                        programInstance.departmentCoreId
                    );
                }
                TuProfileProgramsService.formatList([programInstance]);
                //Update Programs grid
                if (TuProfileFactory.isProgramsAlphabeticalOrder()) {
                    TuProfileFactory.setUpdateProgramGrid(true);
                }
                TuProfileFactory.setUpdateDepartmentsGrid(true);
            }
        };

        //
        /**
         * Actions to do when new program create button is clicked.
         *
         * @returns {boolean}
         */
        $scope.handleProgramCreateClick = function () {
            TuProfileProgramsService.setHasErrors(false);
            var isInvalidProgram = controller.validateProgram($scope.newProgram);
            if (
                $scope.addInProgress ||
                !$scope.forms.addProgramForm ||
                !$scope.forms.addProgramForm.$valid ||
                isInvalidProgram
            ) {
                $scope.setHasErrors(true);
                NotifierFactory.show(
                    'error',
                    "Some fields don't pass validation. Please correct the fields and try again",
                    'Program Add'
                );
                return false;
            }
            $scope.addInProgress = true;
            TuProfileProgramsService.create($scope.newProgram).then(controller.createCallback);
        };

        controller.onKeyUp = function ($event) {
            if ($event.keyCode === 13) {
                return;
            }
            var url = $event.target.value,
                variableName = $event.target.getAttribute('ng-model');

            if (url && url.length > 5 && (url.indexOf('http') !== 0 || url.indexOf('http') === -1)) {
                url = UrlService.prependHttp(url);
                if (variableName) {
                    ObjectService.set($scope, variableName, url);
                }
            }
        };

        /**
         * Actions to do when create request is finished.
         *
         * @param {Object} response
         */
        controller.createCallback = function (response) {
            // enable add button
            $scope.addInProgress = false;
            // show notification message
            NotifierFactory.show(
                response ? 'success' : 'error',
                response ? 'successfully!' : 'failure!',
                'Program Created'
            );
            if (response) {
                var program = angular.copy($scope.newProgram);
                program.id = response.insertedId;
                // set new program as active
                TuProfileFactory.setProgram(program);
                // reset add form fields
                controller.initForm();
                // announce that new program was added
                TuProfileFactory.announceNewProgram(program.id);
            }
            TuProfileFactory.setUpdateDepartmentsGrid(true);
        };

        controller.programFormVisibilityWatch = function () {
            controller.resetValidation();
            controller.initForm();
        };

        controller.initWatches = function () {
            WatchService.create($scope, InstitutionFactory.getCoreId, function (newCoreId, oldCoreId) {
                if (newCoreId) {
                    // check if departments list was not fetched
                        // fetch departments and store for later usage
                        TuProfileDepartmentsService.getAllDepartments(
                            InstitutionFactory.isClientDepartment() ?
                            InstitutionFactory.getParentCoreId() :
                            InstitutionFactory.getCoreId(),
                            {
                                page: 1, // show first page
                                count: 1000, // count per page
                            }
                        );
                    $scope.newProgram.institutionCoreId = newCoreId;
                    $scope.newProgramBeforeChanges.institutionCoreId = newCoreId;

                    // check if institution is client department
                    $scope.isClientDepartment = InstitutionFactory.isClientDepartment();
                    if ($scope.isClientDepartment) {
                        $scope.newProgram.departmentCoreId = newCoreId;
                        $scope.departmentsList = [{
                            coreId: newCoreId,
                            name: InstitutionFactory.getDisplayName()
                        }];
                        $scope.newProgramBeforeChanges = {
                            institutionCoreId: newCoreId,
                            departmentCoreId: newCoreId
                        };
                    }
                }
            });

            WatchService.create($scope, TuProfileFactory.getProgram, function (newValue, oldValue) {
                controller.resetValidation();
                if (newValue !== oldValue) {
                    $scope.program = newValue;
                    controller.restrictBelongsToOptions($scope.program.departmentCoreId);
                }
                else {
                    TuProfileFactory.setProgram({});
                }
            }, true);

            WatchService.create($scope, TuProfileFactory.getDepartments, function (departmentsList) {
                if (!InstitutionFactory.isClientDepartment()) {
                    if (Object.prototype.toString.call(departmentsList) === '[object Array]') {
                        $scope.departmentsListArr = TuProfileFactory.getDepartments();
                        // fixes scenario when switched to parent and clicked edit program, belongs to becomes empty
                        controller.handleDepartmentChanges(InstitutionFactory.getCoreId());
                    }
                    else{
                        $scope.departmentsList = TuProfileFactory.getDepartments();
                    }
                    $scope.departmentsList = departmentsList;
                } else {
                    // client department fix
                    if (Object.prototype.toString.call(departmentsList) === '[object Array]') {
                        $scope.departmentsListArr = TuProfileFactory.getDepartments();
                        $scope.departmentsList = angular.copy($scope.departmentsListArr);
                        controller.handleDepartmentChanges(InstitutionFactory.getCoreId());
                    }
                }
            }, true);

            // watch for program edit form visibility changes
            WatchService.create($scope, function () {
                return TuProfileFactory.isProgramEditFormVisible($scope.activeTab);
            }, controller.programFormVisibilityWatch);

            // watch for program add form visibility changes
            WatchService.create($scope, function () {
                return TuProfileFactory.isProgramAddFormVisible($scope.activeTab);
            }, controller.programFormVisibilityWatch);

            // watch for dpeartment update changes on edit program form
            WatchService.create($scope, TuProfileFactory.getIsDepartmentUpdated, function (isUpdated) {
                if (!InstitutionFactory.isClientDepartment() && isUpdated) {
                    $scope.departmentsList = TuProfileFactory.getDepartments();
                    $scope.belongsToList = [{}];
                }
            });
        };

        controller.initForm = function () {
            $scope.newProgram = angular.copy($scope.newProgramBeforeChanges);
            // watch for program changes
            $scope.program = TuProfileFactory.getProgram();
        };

        controller.init = function () {
            controller.initWatches();
        };

        // listen to departments tab visibility changes
        WatchService.create($scope, TuProfileFactory.isProgramsTabSelected, function (isActive) {
            // if not already initialized and tab is active
            if (!alreadyInitialised && isActive) {
                controller.init();
                alreadyInitialised = true;
            }
            if (isActive) {
                controller.initForm();
                if ($scope.forms && $scope.forms.addProgramForm) {
                    $scope.forms.addProgramForm.$setUntouched();
                }
            }
        });
    };

    angular
        .module('qsHub')
        .controller('TuProfileProgramForm', [
            '$scope',
            'constants',
            'NotifierFactory',
            'TuProfileFactory',
            'InstitutionFactory',
            'TuProfileService',
            'TuProfileProgramsService',
            'TuProfileDepartmentsService',
            'UrlService',
            'ObjectService',
            'WatchService',
            App.controllers.tuProfileProgramForm
        ]);

} (window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    App.services.TuProfileProgramsService = function (
        $resource,
        $q,
        $log,
        constants,
        RequestsQueueService,
        TuProfileService
    ) {
        var service = {
            deferred : {},
            departmentsList: {},
            belongsTo: {
                all : 'all',
                ug: 'ug',
                pg: 'pg'
            },
            hasErrors: false
        };

        /**
         * Get programs list API endpoint.
         *
         * @returns {$resource}
         */
        service.getProgramsListModel = function () {
            return $resource(constants.api.institutions.url + '/v1/list/tu-programs/core-id/:coreId', {}, {
                get: {
                    cancellable : true
                }
            });
        };

        /**
         * Get programs update API endpoint.
         *
         * @returns {$resource}
         */
        service.getUpdateModel = function () {
            return $resource(constants.api.institutions.url + '/v1/tu-program/:id', null, {
                update: {method:'PATCH'}
            });
        };

        /**
         * Get programs create API endpoint.
         *
         * @returns {$resource}
         */
        service.getCreateModel = function () {
            return $resource(constants.api.institutions.url + '/v1/tu-program');
        };

        /**
         * Get programs delete API endpoint.
         *
         * @returns {$resource}
         */
        service.getDeleteModel = function () {
            return $resource(constants.api.institutions.url + '/v1/delete-tu-programs', null, {
                delete: {method:'POST'}
            });
        };

        /**
         * Get belongs to list.
         *
         * @returns {array}
         */
        service.getBelongsToList = function () {
            return TuProfileService.getBelongsToList();
        };

        /**
         * Get programs ordering API endpoint.
         *
         * @returns {$resource}
         */
        service.getOrderingModel = function () {
            return $resource(constants.api.institutions.url + '/v1/tu-profile/:id/programsOrder', null, {
                update: {method:'PATCH'}
            });
        };
        
        /**
         * Sets has errors flag.
         *
         * @param {boolean} hasErrors
         */
        service.setHasErrors = function (hasErrors) {
            service.hasErrors = hasErrors;
        };

        /**
         * Gets has errors flag.
         *
         * @returns {boolean|*}
         */
        service.getHasErrors = function () {
            return service.hasErrors;
        };

        /**
         * Update program.
         *
         * @param {Object} program
         * @returns {Promise}
         */
        service.update = function(program) {
            var UpdateModel = service.getUpdateModel(),
                dataToUpdate = {
                    departmentCoreId: program.departmentCoreId,
                    belongsTo: program.belongsTo,
                    name: program.name,
                    url: program.url
                };
            return UpdateModel.update(
                {id: program.id},
                dataToUpdate
            ).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        /**
         * Create program.
         *
         * @param {Object} program
         * @returns {Promise}
         */
        service.create = function(program) {
            if (!program || !program.institutionCoreId) {
                throw 'Missing Required';
            }
            var UpdateModel = service.getCreateModel(),
                dataToUpdate = {
                    institutionCoreId: program.institutionCoreId,
                    institutionCoreIdAsString: program.institutionCoreId.toString(),
                    departmentCoreId: program.departmentCoreId,
                    belongsTo: program.belongsTo,
                    name: program.name,
                    url: program.url
                };
            return UpdateModel.save(
                null,
                dataToUpdate
            ).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return data;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        /**
         * Delete program(s).
         *
         * @param {array} ids
         * @returns {Promise}
         */
        service.delete = function(ids) {
            var TuProgramModel = service.getDeleteModel();
            return TuProgramModel.delete(
                {},
                ids
            ).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        /**
         * Update programs order.
         *
         * @param {String} tuProfileId
         * @param {Array} orderedIds
         * @returns {Promise.<TResult>}
         */
        service.updateOrder = function(tuProfileId, orderedIds) {
            var TuProgramsModel = service.getOrderingModel();
              var orderedIdsObject = {};
              angular.extend(orderedIdsObject, orderedIds);
            return TuProgramsModel.update(
                {id: tuProfileId},
                orderedIdsObject
            ).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        service.getAllProgramsByCoreId = function (coreId) {
            if (constants.dev) {
                var startTime = new Date().getTime(), endTime;
            }
            var Api = service.getProgramsListModel();
            return Api.get({
                coreId: coreId
            }).$promise.then(function (data) {
                if (constants.dev) {
                    endTime = new Date().getTime();
                    $log.log('success, got data: ', data, 'in ' + (endTime - startTime) + ' ms');
                }
                return service.formatList(data.results);
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return [];
            });
        };

        /**
         * Get programs list.
         *
         * @param {Object} params
         * @returns {Promise}
         */
        service.getAllPrograms = function (params) {  
            var coreId = params.parameters().coreId;
            if (constants.dev) {
                var startTime = new Date().getTime(), endTime;
            }

            // cancel currently running requests
            RequestsQueueService.cancelAll('getAllPrograms');

            var Api = service.getProgramsListModel().get(params.url(), { coreId: coreId });

            // add new request to the queue
            RequestsQueueService.add(Api, 'getAllPrograms');

            return Api.$promise.then(function (data) {
                if (constants.dev) {
                    endTime = new Date().getTime();
                    $log.log('success, got data: ', data, 'in ' + (endTime - startTime) + ' ms');
                }
                // cancel currently running requests
                RequestsQueueService.cancelAll('getAllPrograms');

                params.total(data.totalFiltered);
                params.totalEntries = data.totalMatching;

               return service.formatList(data.results);
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return [];
            });
        };
        
        /**
         * Format programs list.
         *
         * @param {Object} programsData
         * @returns {Object}
         */
        service.formatList = function (programsData) {
            if (programsData) {
                angular.forEach(programsData, function(programObject) {
                    programObject.belongsToName = service.getBelongsToName(programObject.belongsTo);
                });
            }

            return programsData;
        };

        /**
         * Get belongs to name by ID.
         *
         * @param {int} belongsTo
         * @returns {string}
         */
        service.getBelongsToName = function (belongsTo) {
            return TuProfileService.getBelongsToName(belongsTo);
        };

        return service;
    };

    angular
        .module('qsHub')
        .service('TuProfileProgramsService', [
            '$resource',
            '$q',
            '$log',
            'constants',
            'RequestsQueueService',
            'TuProfileService',
            App.services.TuProfileProgramsService
        ]);

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub')
        .controller('TuProfileMatchingToolController', [
            '$scope',
            'profiles.gridNamespaces',
            'CoursesService',
            'TuProfileFactory',
            'InstitutionFactory',
            'MatchingToolFactory',
            'uiGridConstants',
            'WatchService',
            'UiGridService',
            'TuProfileService',
            'TimeService',
            'constants',
            'DateRangeFixerService',
            'TuProfileSubjectsService',
            'UnsavedChangesFactory',
            '$timeout',
            'UserFactory',
            '$location',
            TuProfileMatchingTool
        ]);

    function TuProfileMatchingTool(
        $scope,
        gridNamespaces,
        CoursesService,
        TuProfileFactory,
        InstitutionFactory,
        MatchingToolFactory,
        uiGridConstants,
        WatchService,
        GridService,
        TuProfileService,
        TimeService,
        constants,
        DateRangeFixerService,
        TuProfileSubjectsService,
        UnsavedChangesFactory,
        $timeout,
        UserFactory,
        $location
    ) {
        var controller = this;

        var columnsFilters = GridService.getColumnsFilters(gridNamespaces.filters.matchingTool, []),
            isRendered = false,
            loading = false,
            GridApi = null,
            lastPublishedAtDateFilter = null,
            lastModifiedAtDateFilter = null,
            subjectsList = [],
            degreesList = [],
            typesList = [],
            levelsList = [],
            alreadyInitialised = false,
            filterChangesInProgress = false,
            columnsBeforeHide = [],
            selectValues = {},
            lastSelectValues = {},
            filterTimeoutMilliseconds = constants.datagridInputDelay,
            isSelectedRowExist = false,
            defaultFilters = {
                publishedAtRange: {
                    startDate: null,
                    endDate: null
                },
                modifiedAtRange: {
                    startDate: null,
                    endDate: null
                }
            };

        controller.alphabeticalOrderingInProgress = false;
        controller.alphabeticalOrder = null;
        controller.submitInProgress = false;
        controller.selectedRowId = null;
        controller.lastPublishedAt = null;
        controller.filters = {
            publishedAtRange: {
                startDate: null,
                endDate: null
            },
            modifiedAtRange: {
                startDate: null,
                endDate: null
            }
        };

        controller.defaultPaginationOptions = {
            page: 1,
            limit: 50,
            'sorting[createdAt]': 'desc'
        };
        controller.paginationOptions = GridService.getColumnsFilters(
            gridNamespaces.filters.matchingTool,
            ['publishedAtRange','modifiedAtRange']
        );
        controller.columnsVisibility = GridService.getColumnsVisibility(
            constants.gridNameSpaces.matchingToolGridVisibility
        );

        controller.handleAlphabeticalOrderClick = function () {
            if (controller.alphabeticalOrderingInProgress) {
                return false;
            }

            // indicate progress
            controller.alphabeticalOrderingInProgress = true;
            // store changes
            TuProfileService.updateTuProfile(TuProfileFactory.getId(), {
                coursesAlphabeticalOrder: controller.alphabeticalOrder
            }).then(function (success) {
                if (success) {
                    // reload departments datagrid
                    getPage();
                }
                // indicate progress end
                controller.alphabeticalOrderingInProgress = false;
            });
        };

        controller.handleAddProgramClick = function () {
            MatchingToolFactory.setIsAddProgramClick(true);
            if (UnsavedChangesFactory.hasChanges()) {
                MatchingToolFactory.setUnsavedChanges(true);
            } else {
                handleDatagridRowClick({
                    id: ''
                });
                resetSelectedRowCount();
                MatchingToolFactory.setIsAdd(
                    !MatchingToolFactory.isAdd()
                );
            }
        };

        controller.isRightSidePanelActive = function () {
            return controller.selectedRowId !== null;
        };

        controller.isLoading = function () {
            return !levelsList.length || !subjectsList.length || !typesList.length || !degreesList.length ||  !isRendered || loading;
        };

        function resetSelectedRowCount () {
            if (GridApi.grid.selection.selectedCount === 1) {
                GridApi.selection.clearSelectedRows();
            }
        }

        function initLists() {
            CoursesService.getDegrees().then(function (items) {
                angular.forEach(items, function (item) {
                    degreesList.push({
                        value: item.handle,
                        label: item.name
                    });
                });
            });

            CoursesService.getTypes().then(function (items) {
                angular.forEach(items, function (item) {
                    typesList.push({
                        value: item.handle,
                        label: item.name
                    });
                });
            });

            TuProfileSubjectsService.getSubjects().then(function (items) {
                angular.forEach(items, function (data) {
                    angular.forEach(data, function (item) {
                        subjectsList.push({
                            value: item.handle,
                            label: item.name
                        });
                    });
                });
            });

            CoursesService.getLevels().then(function (items) {
                angular.forEach(items, function (item) {
                    levelsList.push({
                        value: item.handle,
                        label: item.name
                    });
                });
            });
        }

        function getPage() {
            loading = true;
            return CoursesService.getByInstitutionId(
                InstitutionFactory.getCoreId(),
                angular.merge({}, controller.paginationOptions, controller.defaultPaginationOptions)
            ).then(function (response) {
                controller.gridOptions.totalItems = response.totalMatching;
                controller.gridOptions.data = response.results;
                controller.gridOptions.minRowsToShow = response.totalFiltered;
                controller.gridOptions.total = response.total;
                loading = false;

                var rowToSelect = MatchingToolFactory.getRowToSelect();
                if(!controller.selectedRowId && rowToSelect) {
                    isSelectedRowExist = false;
                    var totalItems = controller.gridOptions.data ? controller.gridOptions.data.length : 0;
                    for (var i = 0; i < totalItems; i++) {
                        if (controller.gridOptions.data[i].id === rowToSelect) {
                            isSelectedRowExist = true;
                            break;
                        }
                    }
                }

                if (columnsFilters) {
                    if (columnsFilters['filter[name]'] && columnsFilters['filter[name]'].indexOf("=") !== -1) {
                        GridService.applyFilters(GridApi.grid.columns[0]);
                    }
                    if (columnsFilters['filter[publishedByFullName]'] && columnsFilters['filter[publishedByFullName]'].indexOf("=") !== -1) {
                        GridService.applyFilters(GridApi.grid.columns[8]);
                    }
                 }
            });
        }

        function getDateField(value) {
            if (!value) {
                value = 'date';
            }
            var total = controller.gridOptions.columnDefs.length,
                i = 0;
            for (i; i < total; i++) {
                if (controller.gridOptions.columnDefs[i].type === value) {
                    return controller.gridOptions.columnDefs[i];
                }
            }
        }

        function handleGridFiltersChanges () {
            if (!controller.gridOptions.total) {
                return;
            }
            filterChangesInProgress = true;
            if(GridApi.pagination.getPage() > 1){
                GridApi.pagination.seek(1);
            }
            if (GridApi !== null) {
                angular.forEach(GridApi.grid.columns, function (column) {
                    if (typeof column.filters !== 'undefined' && column.filters[0].term !== 'undefined') {
                        if (
                            column.filters[0].type === uiGridConstants.filter.SELECT &&
                            typeof column.filters[0].term === 'object' &&
                            column.filters[0].term !== null &&
                            typeof column.filters[0].term.value !== 'undefined'
                        ) {
                            column.filters[0].term = column.filters[0].term.value;
                        }
                    }
                });

                controller.paginationOptions = angular.copy(controller.defaultPaginationOptions);
                var filterColumn;
                angular.forEach(GridApi.grid.columns, function (column) {
                    filterColumn = column.filters[0].column ? column.filters[0].column : column.field;
                    if (column.field === 'courseLevel') {
                        CoursesService.disableDegrees(degreesList, column.filters[0].term);
                    }
                    if (column.field === 'publishedAt' || column.field === 'createdAt' || column.field === 'modifiedAt') {
                        if (
                            typeof column.filters !== 'undefined' &&
                            typeof column.filters[0].term !== 'undefined' &&
                            typeof column.filters[1].term !== 'undefined'
                        ) {
                            controller.paginationOptions['filter[' + filterColumn + ']'] = {
                                startDate: column.filters[0].term && isNaN(column.filters[0].term) && typeof column.filters[0].term === 'object' ?
                                    column.filters[0].term.format('x') : column.filters[0].term,
                                endDate: column.filters[1].term && isNaN(column.filters[1].term) && typeof column.filters[1].term === 'object' ?
                                    column.filters[1].term.format('x') : column.filters[1].term
                            };
                        } else {
                            controller.paginationOptions['filter[' + filterColumn + ']'] = null;
                        }
                    } else if (typeof column.filters !== 'undefined' &&
                        column.filters[0].term !== 'undefined' &&
                        column.filters[0].term !== null &&
                        (
                            typeof column.filters[0].term === 'boolean' ||
                            typeof column.filters[0].term === 'number' ||
                            (typeof column.filters[0].term === 'string' && column.filters[0].term.length > 0)
                        )
                    ) {
                        filterColumn = column.filters[0].column ? column.filters[0].column : column.field;
                        controller.paginationOptions['filter[' + filterColumn + ']'] = column.filters[0].term;
                    }
                });

                if (controller.paginationOptions["filter[publishedAt]"] === null) {
                    controller.paginationOptions["filter[publishedAt]"] = controller.filters.publishedAtRange;
                }
                if (controller.paginationOptions["filter[publishedAt]"].startDate === null) {
                    controller.paginationOptions["filter[publishedAt]"].endDate = null;
                    controller.filters.publishedAtRange = {
                        startDate: null,
                        endDate: null
                    };
                }

                if (controller.paginationOptions["filter[modifiedAt]"] === null) {
                    controller.paginationOptions["filter[modifiedAt]"] = controller.filters.modifiedAtRange;
                }
                if (controller.paginationOptions["filter[modifiedAt]"].startDate === null) {
                    controller.paginationOptions["filter[modifiedAt]"].endDate = null;
                    controller.filters.modifiedAtRange = {
                        startDate: null,
                        endDate: null
                    };
                }

                GridService.storeColumnsFilters(gridNamespaces.filters.matchingTool, controller.paginationOptions);
                storeColumnsVisibility();

                if (angular.isDefined($scope.filterTimeout)) {
                    $timeout.cancel($scope.filterTimeout);
                }

                GridService.generateSelectValues(GridApi.grid.columns, selectValues);

                if (!angular.equals(selectValues, lastSelectValues)) {
                    filterTimeoutMilliseconds = constants.datagridSelectDelay;
                }

                $scope.filterTimeout = $timeout(function () {
                    getPage();
                    lastSelectValues = angular.copy(selectValues);
                    filterChangesInProgress = false;
                    filterTimeoutMilliseconds = constants.datagridInputDelay;
                }, filterTimeoutMilliseconds);
            }
        }

        function handleGridVisibleChanges () {
            if (!controller.selectedRowId) {
                resetSelectedRowCount();
            }
            var columnsVisibility = GridService.getColumnsVisibility(constants.gridNameSpaces.matchingToolGridVisibility),
            reload = false;
            if (columnsVisibility && !controller.selectedRowId) {
                angular.forEach(controller.gridOptions.columnDefs, function (column, key) {
                    if (columnsVisibility[column.field] === true &&
                        column.visible === false
                    ) {
                        if (typeof  controller.gridOptions.columnDefs[key].filter === 'undefined') {
                            controller.gridOptions.columnDefs[key].filter = {};
                        }
                        if (typeof  controller.gridOptions.columnDefs[key].filter !== 'undefined' &&
                            controller.gridOptions.columnDefs[key].filter.hasOwnProperty('term') &&
                            controller.gridOptions.columnDefs[key].filter.term) {
                            controller.paginationOptions['filter[' + column.field + ']'] = null;
                            reload = true;
                        }
                        controller.gridOptions.columnDefs[key].filter.term = null;
                        columnsFilters['filter[' + column.field + ']'] = null;
                        if (column.field === 'publishedAt') {
                            delete  controller.gridOptions.columnDefs[key].filters[0].term;
                            controller.filters.publishedAtRange = {
                                startDate: null,
                                endDate: null
                            };
                        }
                        if (column.field === 'modifiedAt') {
                            delete  controller.gridOptions.columnDefs[key].filters[0].term;
                            controller.filters.modifiedAtRange = {
                                startDate: null,
                                endDate: null
                            };
                        }
                    }
                });
                // GridService.storeColumnsFilters(gridNamespaces.filters.matchingTool, columnsFilters);
            }

            if (controller.gridOptions) {
                controller.columnsVisibility = GridService.getGridColumnsVisibility(controller.gridOptions.columnDefs);
                if(!controller.selectedRowId && !MatchingToolFactory.isAddProgramClick()){
                    GridService.storeColumnsVisibility(
                        constants.gridNameSpaces.matchingToolGridVisibility,
                        controller.columnsVisibility
                    );
                }
            }
            if (reload) {
                getPage();
            }
        }

        function storeColumnsVisibility() {
            if (!controller.gridOptions) {
                return false;
            }
            angular.forEach(controller.gridOptions.columnDefs, function (column) {
                if (angular.isDefined(column.visible) && column.visible === true) {
                    columnsBeforeHide.push(column);
                }
            });
        }

        function handleDatagridRowClick(row) {
            $location.search('courseId', null);
            controller.selectedRowId = controller.selectedRowId === row.id ? null : row.id;
            if(!TuProfileFactory.isMatchingToolRightSidePanelActive()) {
                toggleColumns(controller.selectedRowId !== null);
            }
            TuProfileFactory.setMatchingToolRightSidePanelActive(controller.selectedRowId !== null);
            MatchingToolFactory.setCourseData(row);
            if (MatchingToolFactory.isAdd()) {
                MatchingToolFactory.setIsAdd(false);
            }
            MatchingToolFactory.setSelectRowId(null);
        }

        function toggleColumns(hide) {
            if (!controller.gridOptions) {
                return;
            }
            TuProfileFactory.setMatchingToolRightSidePanelActive(hide);
            if (hide) {
                columnsBeforeHide = angular.copy(controller.gridOptions.columnDefs);
                // hide all columns except name
                angular.forEach(controller.gridOptions.columnDefs, function (column) {
                    column.visible = column.field === 'name';
                });
            } else {
                var columns = [];
                // show columns visible before hide
                angular.forEach(columnsBeforeHide, function (column) {
                    if (column.visible === true) {
                        columns.push(column.field);
                    }
                });
                angular.forEach(controller.gridOptions.columnDefs, function (column) {
                    if (columns.indexOf(column.field) !== -1) {
                        column.visible = true;
                        controller.columnsVisibility[column.field] = true;
                    }
                });
            }
            controller.gridOptions.enablePaginationControls = !TuProfileFactory.isMatchingToolRightSidePanelActive();
        }

        function getSelectLabel(list, value)
        {
            var total = list.length,
                i = 0;
            for (i; i < total; i++) {
                if (list[i].value === value) {
                    return list[i].label;
                }
            }
            return value;
        }

        function exporterFieldCallback(grid, row, col, value) {
            switch (col.name) {
                case 'published':
                case 'unpublished':
                    return value ? 'Yes' : 'No';
                case 'courseLevel':
                    return getSelectLabel(levelsList, value);
                case 'degree':
                    return getSelectLabel(degreesList, value);
                case 'subject':
                    return getSelectLabel(subjectsList, value);
                case 'type':
                    return getSelectLabel(typesList, value);
                case 'publishedAt':
                    return TimeService.format(value, "YYYY-MM-DD HH:mm:ss");
                case 'publishedSinceLastModified':
                    return value ? 'Yes' : 'No';
                case 'modifiedAt':
                    return TimeService.format(value, "YYYY-MM-DD HH:mm:ss");
                case 'campus':
                    return value && value.length ? 'Yes' : 'No';
                default:
                    return value;
            }
        }

        function initDatagridOptions () {
            var selectTemplate = '/scripts/shared/ui-grid/templates/selectFilterHeaderTemplate.html',
                selectCellTemplate = '/scripts/shared/ui-grid/templates/selectCellTemplate.html',
                selectCountCellTemplate = '/scripts/shared/ui-grid/templates/selectCountCellTemplate.html',
                degreeSelectTemplate = '/scripts/components/profiles/tu/matchingTool/datagrid/selectFilterHeaderTemplate.html',
                rowTemplate = '/scripts/components/profiles/tu/matchingTool/datagrid/rowTemplate.html',
                dateFilterCellTemplate = '/scripts/shared/ui-grid/templates/dateFilterCellTemplate.html',
                dateFilterTemplate = '/scripts/shared/ui-grid/templates/dateFilterHeaderTemplate.html';

            controller.gridOptions = angular.extend({}, controller.gridOptions, {
                appScopeProvider: controller,
                enableSorting: false,
                useExternalFiltering: true,
                enableGridMenu: true,
                showGridFooter: true,
                enableFiltering: true,
                enableColumnResize: true,
                enableFullRowSelection: true,
                enableRowSelection: true,
                multiSelect: false,
                enableRowHeaderSelection: false,
                rowTemplate: rowTemplate,
                exporterMenuCsv: true,
                exporterMenuAllData: false,
                exporterMenuSelectedData: false,
                exporterMenuPdf: false,
                exporterCsvFilename: 'matching-tool-export.' + TimeService.now() + '.csv',
                exporterFieldCallback: exporterFieldCallback,
                paginationPageSizes: [50, 100, 200],
                paginationPageSize: 50,
                useExternalPagination: true,
                columnDefs: [
                    {
                        displayName: 'Id',
                        field: 'coreId',
                        visible: !UserFactory.isClient(),
                        enableHiding: false,
                        enableColumnMenu: false,
                        filter: {
                            term: GridService.getFilterByField(columnsFilters, 'coreId', ''),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        displayName: 'Name',
                        field: 'name',
                        visible: true,
                        enableHiding: false,
                        enableColumnMenu: false,
                        filter: {
                            term: GridService.getFilterByField(columnsFilters, 'name', ''),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        displayName: 'Course Level',
                        field: 'courseLevel',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'courseLevel', true),
                        filter: {
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: levelsList,
                            searchEnabled: true,
                            term: GridService.getFilterByField(columnsFilters, 'courseLevel')
                        },
                        filterHeaderTemplate: selectTemplate,
                        cellTemplate: selectCellTemplate
                    },
                    {
                        displayName: 'Degree',
                        field: 'degree',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'degree', true),
                        filter: {
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: degreesList,
                            searchEnabled: true,
                            term: GridService.getFilterByField(columnsFilters, 'degree')
                        },
                        filterHeaderTemplate: degreeSelectTemplate,
                        cellTemplate: selectCellTemplate
                    },
                    {
                        displayName: 'Subject',
                        field: 'subject',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'subject', true),
                        filter: {
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: subjectsList,
                            orderBy: 'label',
                            searchEnabled: true,
                            term: GridService.getFilterByField(columnsFilters, 'subject')
                        },
                        filterHeaderTemplate: selectTemplate,
                        cellTemplate: selectCellTemplate
                    },
                    {
                        displayName: 'Type',
                        field: 'type',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'type', true),
                        filter: {
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: typesList,
                            searchEnabled: true,
                            term: GridService.getFilterByField(columnsFilters, 'type')
                        },
                        filterHeaderTemplate: selectTemplate,
                        cellTemplate: selectCellTemplate
                    },
                    {
                        displayName: 'Published / Unpublished',
                        field: 'published',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'published', true),
                        filter: {
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: [
                                { value: true, label: 'Yes' },
                                { value: false, label: 'No' },
                                { value: 'unpublished', label: 'Unpublished' },
                            ],
                            searchEnabled: true,
                            term: GridService.getFilterByField(columnsFilters, 'published')
                        },
                        filterHeaderTemplate: selectTemplate,
                        cellTemplate: selectCellTemplate
                    },
                    {
                        displayName: 'Last Published / Unpublished At',
                        type: 'date',
                        field: 'publishedAt',
                        cellFilter: 'date:\'medium\' : \'UTC\'',
                        filterCellFiltered: true,
                        filters: [
                            {
                                visible: true,
                                condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL
                            },
                            {
                                condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL
                            }
                        ],
                        filter: {
                            term: GridService.getFilterByField(columnsFilters, 'publishedAt', '',false),
                            applyTextFilter: controller.applyDateFilter('publishedAt', '')
                        },
                        cellTemplate: dateFilterCellTemplate,
                        filterHeaderTemplate: dateFilterTemplate,
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'publishedAt', true),
                    },
                    {
                        displayName: 'Last Published / Unpublished By',
                        field: 'publishedByFullName',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'publishedByFullName', true),
                        filter: {
                            term: GridService.getFilterByField(columnsFilters, 'publishedByFullName', ''),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        displayName: 'Last Modified',
                        type: 'dateModified',
                        field: 'modifiedAt',
                        cellFilter: 'date:\'medium\' : \'UTC\'',
                        filterCellFiltered: true,
                        filters: [
                            {
                                visible: true,
                                condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL
                            },
                            {
                                condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL
                            }
                        ],
                        filter: {
                            term: GridService.getFilterByField(columnsFilters, 'modifiedAt', '',false),
                            applyTextFilter: controller.applyDateFilter('modifiedAt', '')
                        },
                        cellTemplate: dateFilterCellTemplate,
                        filterHeaderTemplate: dateFilterTemplate,
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'modifiedAt', true)
                    },
                    {
                        displayName: 'Published Since Last Modified',
                        field: 'publishedSinceLastModified',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'publishedSinceLastModified', true),
                        filter: {
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: [
                                { value: true, label: 'Yes' },
                                { value: false, label: 'No' }
                            ],
                            searchEnabled: true,
                            term: GridService.getFilterByField(columnsFilters, 'publishedSinceLastModified')
                        },
                        filterHeaderTemplate: selectTemplate,
                        cellTemplate: selectCellTemplate
                    },
                    {
                        displayName: 'Has Campus',
                        field: 'campus',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'campus', false),
                        filter: {
                            column: 'hasCampus',
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: [
                                { value: true, label: 'Yes' },
                                { value: false, label: 'No' },
                            ],
                            searchEnabled: true,
                            term: GridService.getFilterByField(columnsFilters, 'hasCampus')
                        },
                        filterHeaderTemplate: selectTemplate,
                        cellTemplate: selectCountCellTemplate
                    },
                ],
                onRegisterApi: function (gridApi) {
                    GridApi = gridApi;

                    gridApi.selection.on.rowSelectionChanged($scope, function (row) {
                        if (!MatchingToolFactory.isAdd() ||
                            !UnsavedChangesFactory.hasChanges() && MatchingToolFactory.isAdd()
                        ) {
                            handleDatagridRowClick(row.entity);
                        } else {
                            // show unsaved dialog
                            MatchingToolFactory.setUnsavedChanges(true);
                            MatchingToolFactory.setUnsavedRowEntity(row.entity);
                        }
                        MatchingToolFactory.setIsAddProgramClick(false);
                    });

                    gridApi.core.on.rowsRendered($scope, function () {
                        $timeout(function() {
                            isRendered = true;
                        });
                        GridService.generateSelectValues(GridApi.grid.columns, selectValues);
                        GridService.generateSelectValues(GridApi.grid.columns, lastSelectValues);
                        WatchService.create($scope, function () {
                            return controller.gridOptions && controller.gridOptions.data ?
                                controller.gridOptions.data.length : null;
                        }, selectRowWatch);
                    });

                    gridApi.core.on.filterChanged($scope, handleGridFiltersChanges);
                    gridApi.pagination.on.paginationChanged($scope, function (newPage, limit) {
                        controller.defaultPaginationOptions.page = newPage;
                        controller.defaultPaginationOptions.limit = limit;
                        if(!filterChangesInProgress) {
                            getPage();
                        }
                    });
                    //gridApi.draggableRows.on.rowDropped($scope, handleDatagridDragAndDrop);
                    gridApi.core.on.rowsVisibleChanged($scope, handleGridVisibleChanges);

                    if (controller.selectedRowId) {
                        var cancelWatch = WatchService.create($scope, function () {
                            return controller.gridOptions && controller.gridOptions.data ?
                                controller.gridOptions.data.length : null;
                        }, function(length) {
                            if (!length) {
                                return;
                            }
                            cancelWatch();
                            var i = 0;
                            for (i; i < length; i++) {
                                if (controller.gridOptions.data[i].coreId === controller.selectedRowId) {
                                    return handleDatagridRowClick(controller.gridOptions.data[i]);
                                }
                            }
                        });
                    }
                }
            });

            datePickersFixes();
            controller.filters = angular.extend({}, defaultFilters);
            storeColumnsVisibility();
        }

        function datePickersFixes() {
            // This is needed to add 'x' icon in date field when prepopulating dates
            var publishedAtField = GridService.getFilterByField(columnsFilters, 'publishedAt', '');
            if (publishedAtField !== null && controller.paginationOptions["filter[publishedAt]"] !== null) {
                if (angular.isDefined(publishedAtField) &&
                    angular.isDefined(controller.paginationOptions["filter[publishedAt]"]) &&
                    angular.isDefined(controller.paginationOptions["filter[publishedAt]"].startDate) &&
                    angular.isDefined(controller.paginationOptions["filter[publishedAt]"].endDate)
                ) {
                    controller.gridOptions.columnDefs[getColumnNumber('publishedAt')].filters[0].term = controller.paginationOptions["filter[publishedAt]"].startDate;
                    controller.gridOptions.columnDefs[getColumnNumber('publishedAt')].filters[1].term = controller.paginationOptions["filter[publishedAt]"].endDate;
                }
            }

            var modifiedAtField = GridService.getFilterByField(columnsFilters, 'modifiedAt', '');
            if (modifiedAtField !== null && controller.paginationOptions["filter[modifiedAt]"] !== null) {
                if (angular.isDefined(modifiedAtField) &&
                    angular.isDefined(controller.paginationOptions["filter[modifiedAt]"]) &&
                    angular.isDefined(controller.paginationOptions["filter[modifiedAt]"].startDate) &&
                    angular.isDefined(controller.paginationOptions["filter[modifiedAt]"].endDate)
                ) {
                    controller.gridOptions.columnDefs[getColumnNumber('modifiedAt')].filters[0].term = controller.paginationOptions["filter[modifiedAt]"].startDate;
                    controller.gridOptions.columnDefs[getColumnNumber('modifiedAt')].filters[1].term = controller.paginationOptions["filter[modifiedAt]"].endDate;
                }
            }
        }

         function getColumnNumber(columnName) {
            var key = 0,
                total = controller.gridOptions.columnDefs.length;
            for (key; key < total; key++) {
                if (controller.gridOptions.columnDefs[key] &&
                    controller.gridOptions.columnDefs[key].field === columnName
                ) {
                    return key;
                }
            }

            return null;
        }

        /**
         * Populates input text field for date field
         *
         * @param {string} filterName
         * @param {mixed} defaultValue
         */
        controller.applyDateFilter = function (filterName, defaultValue) {
            var filter = GridService.getFilterByField(controller.paginationOptions, filterName, defaultValue);
            if (angular.isDefined(filter) &&
                filter !== null
            ) {
                if (angular.isDefined(filter.startDate) &&
                    angular.isDefined(filter.endDate)
                ) {
                    if (filter.startDate !== null &&
                        filter.startDate !== null
                    ) {
                        defaultFilters[filterName + 'Range'] = {
                            startDate: parseInt(filter.startDate, 10),
                            endDate: parseInt(filter.endDate, 10)
                        };
                    } else {
                        defaultFilters[filterName + 'Range'] = {
                            startDate: null,
                            endDate: null
                        };
                    }
                }
            }
        };
        controller.handlePublishedAtRange = function (event) {
            if (DateRangeFixerService.sameDayAndNoModel(event, controller.filters.publishedAtRange, lastPublishedAtDateFilter)) {
                event.model.startDate = lastPublishedAtDateFilter.startDate;
                event.model.endDate = lastPublishedAtDateFilter.endDate;
                controller.filters.publishedAtRange = {
                    startDate: lastPublishedAtDateFilter.startDate,
                    endDate: lastPublishedAtDateFilter.endDate
                };
            } else if (DateRangeFixerService.todayRangeAndNoModel(event, lastPublishedAtDateFilter)) {
                event.model.startDate = TimeService.getStartOfToday();
                event.model.endDate = TimeService.getEndOfToday();
                controller.filters.publishedAtRange = {
                    startDate: event.model.startDate.toISOString(),
                    endDate: event.model.endDate.toISOString()
                };
            }

            var publishedField = getDateField();
            if (publishedField) {
                if (event === null || typeof event === 'undefined') {
                    publishedField.filters[0].term = null;
                    publishedField.filters[1].term = null;
                } else if (event.model.startDate && event.model.endDate) {
                    publishedField.filters[0].term = typeof event.model.startDate === 'object' ? event.model.startDate.format('x') : event.model.startDate;
                    publishedField.filters[1].term = typeof event.model.endDate === 'object' ? event.model.endDate.format('x') : event.model.endDate;
                }
            }

            lastPublishedAtDateFilter = angular.copy({
                startDate: event.model.startDate,
                endDate: event.model.endDate
            });
        };

        controller.handleModifiedAtRange = function (event) {
            if (DateRangeFixerService.sameDayAndNoModel(event, controller.filters.modifiedAtRange, lastModifiedAtDateFilter)) {
                event.model.startDate = lastModifiedAtDateFilter.startDate;
                event.model.endDate = lastModifiedAtDateFilter.endDate;
                controller.filters.modifiedAtRange = {
                    startDate: lastModifiedAtDateFilter.startDate,
                    endDate: lastModifiedAtDateFilter.endDate
                };
            } else if (DateRangeFixerService.todayRangeAndNoModel(event, lastModifiedAtDateFilter)) {
                event.model.startDate = TimeService.getStartOfToday();
                event.model.endDate = TimeService.getEndOfToday();
                controller.filters.modifiedAtRange = {
                    startDate: event.model.startDate.toISOString(),
                    endDate: event.model.endDate.toISOString()
                };
            }

            var modifiedField = getDateField('dateModified');
            if (modifiedField) {
                if (event === null || typeof event === 'undefined') {
                    modifiedField.filters[0].term = null;
                    modifiedField.filters[1].term = null;
                } else if (event.model.startDate && event.model.endDate) {
                    modifiedField.filters[0].term = typeof event.model.startDate === 'object' ? event.model.startDate.format('x') : event.model.startDate;
                    modifiedField.filters[1].term = typeof event.model.endDate === 'object' ? event.model.endDate.format('x') : event.model.endDate;
                }
            }

            lastModifiedAtDateFilter = angular.copy({
                startDate: event.model.startDate,
                endDate: event.model.endDate
            });
        };

        function reset() {
            controller.selectedRowId = null;
            toggleColumns(false);
            MatchingToolFactory.setCourseData(null);
            TuProfileFactory.setMatchingToolRightSidePanelActive(false);
            MatchingToolFactory.setIsAdd(false);
        }

        function rightSidePanelWatch(active, wasActive) {
            if (active === false && wasActive) {
                reset();
            }
        }

        function tuProfileWatch(id) {
            if (!id) {
                return false;
            }
            if (GridApi && GridApi.pagination && GridApi.pagination.getPage() > 1){
                GridApi.pagination.seek(1);
            }
            var data = TuProfileFactory.getData();
            controller.alphabeticalOrder = data && data.coursesAlphabeticalOrder;
            getPage();
        }

        function datagridReloadWatch(reload) {
            if (!reload) {
                return false;
            }
            reset();
            getPage();
            MatchingToolFactory.reloadDatagrid(false);
        }

        function selectRowWatch() {
            if (MatchingToolFactory.isSelectRow() && controller.gridOptions && controller.gridOptions.data) {
                var id = MatchingToolFactory.getRowToSelect(),
                    totalItems = controller.gridOptions.data.length;

                for (var i = 0; i < totalItems; i++) {
                    if (controller.gridOptions.data[i].id === id && !isSelectedRowExist) {
                        handleDatagridRowClick(controller.gridOptions.data[i]);
                        MatchingToolFactory.setRowToSelect(null);
                        MatchingToolFactory.setIsSelectRow(false);
                        break;
                    }
                }
            }

            return false;
        }

        function datagridUpdateWatch (update) {
            if (!update) {
                return false;
            }

            var totalItems = controller.gridOptions.data ? controller.gridOptions.data.length : 0,
                key = 0,
                id = MatchingToolFactory.getUpdateId(),
                data = MatchingToolFactory.getUpdateData();

            MatchingToolFactory.updateInDatagrid(null, null);
            for (key; key < totalItems; ++key) {
                if (controller.gridOptions.data[key].id === id) {
                    controller.gridOptions.data[key] = angular.copy(data);
                    return true;
                }
            }
            return false;
        }

        function datagridTriggerRowWatch(trigger) {
            if (trigger) {
                handleDatagridRowClick(MatchingToolFactory.getUnsavedRowEntity());
                MatchingToolFactory.setTriggerRowClick(false);
                MatchingToolFactory.setUnsavedRowEntity(null);
            }
        }

        function selectRowIdWatch(id) {
            if (id) {
                controller.selectedRowId = id;
                MatchingToolFactory.setSelectRowId(null);
            }
        }

        function handleAddProgramClickWatch(open) {
            if (open) {
                controller.handleAddProgramClick();
                MatchingToolFactory.setOpenAddMode(false);
            }
        }

        function initWatches() {
            WatchService.create($scope, TuProfileFactory.getId, tuProfileWatch);
            WatchService.create($scope, TuProfileFactory.isMatchingToolRightSidePanelActive, rightSidePanelWatch);
            WatchService.create($scope, MatchingToolFactory.isDatagridReload, datagridReloadWatch);
            WatchService.create($scope, MatchingToolFactory.isUpdateInDatagrid, datagridUpdateWatch);
            WatchService.create($scope, MatchingToolFactory.getTriggerRowClick, datagridTriggerRowWatch);
            WatchService.create($scope, MatchingToolFactory.getSelectRowId, selectRowIdWatch);
            WatchService.create($scope, MatchingToolFactory.isOpenAddMode, handleAddProgramClickWatch);
        }

        function init() {
            if ($location.search().courseId && !isNaN($location.search().courseId)) {
                controller.selectedRowId = parseInt($location.search().courseId, 10);
                controller.paginationOptions["filter[coreId]"] = angular.copy(controller.selectedRowId);
            }
            initDatagridOptions();
            initLists();
            initWatches();
        }
        // listen to departments tab visibility changes
        WatchService.create($scope, TuProfileFactory.isMatchingToolTabSelected, function (isActive) {
            // if not already initialized and tab is active
            controller.isMatchingToolTabSelected = isActive;
            if (!alreadyInitialised && isActive) {
                alreadyInitialised = true;
                init();
            }
        });
    }

} (window.angular));

(function(angular) {
    'use strict';


    angular
        .module('qsHub')
        .factory('MatchingToolFactory', MatchingTool);

    function MatchingTool() {
        var data = null,
            tempCourseData = null,
            isCourseCloned = false,
            reloadDatagrid = false,
            itemToUpdateId,
            itemToUpdateData,
            isAdd = false,
            selectRow = null,
            isSelectRow = false,
            row = null,
            unsavedChanges = false,
            rowEntity = null,
            trigger = false,
            rowId = null,
            isAddProgramClick = null,
            isOpenAddMode = false,
            newCourseData = {
                tuitionFees:{},
                entryRequirements : {
                    firstTestType: {},
                    secondTestType: {},
                    thirdTestType: {}
                },
                brochure: null,
                images :[]
            };
        return {
            setCourseData: function (newData) {
                data = newData;
            },
            getCourseData: function () {
                return data ;
            },
            setNewCourseData: function (newData) {
                newCourseData = newData;
            },
            getNewCourseData: function () {
                return newCourseData ;
            },
            setTempCourseData: function (tempData) {
                tempCourseData = tempData;
            },
            getTempCourseData: function () {
                return tempCourseData ;
            },
            setIsCourseCloned: function (tempData) {
                isCourseCloned = tempData;
            },
            getIsCourseCloned: function () {
                return isCourseCloned ;
            },
            isEdit: function () {
                return data && data.id && data.id.length;
            },
            isAdd: function () {
                return isAdd;
            },
            setIsAdd: function (newIsAdd) {
                isAdd = newIsAdd;
            },
            reloadDatagrid: function (newData) {
                reloadDatagrid = newData;
            },
            isDatagridReload: function () {
                return reloadDatagrid;
            },
            updateInDatagrid: function (id, data) {
                itemToUpdateId = id;
                itemToUpdateData = data;
            },
            isUpdateInDatagrid: function () {
                return !!itemToUpdateId;
            },
            getUpdateId: function () {
                return itemToUpdateId;
            },
            getUpdateData: function () {
                return itemToUpdateData;
            },
            getSelectRow: function () {
                return selectRow;
            },
            setSelectRow: function (newSelectRow) {
                selectRow = newSelectRow;
            },
            isSelectRow: function () {
                return isSelectRow;
            },
            setIsSelectRow: function (newIsSelectRow) {
                isSelectRow = newIsSelectRow;
            },
            getRowToSelect: function () {
                return row;
            },
            setRowToSelect: function (newRow) {
                row = newRow;
            },
            isUnsavedDialog: function () {
                return unsavedChanges;
            },
            setUnsavedChanges: function (newValue) {
                unsavedChanges = newValue;
            },
            setUnsavedRowEntity: function (newRowEntity) {
                rowEntity = newRowEntity;
            },
            getUnsavedRowEntity: function () {
                return rowEntity;
            },
            setTriggerRowClick: function (newTrigger) {
                trigger = newTrigger;
            },
            getTriggerRowClick: function () {
                return trigger;
            },
            getSelectRowId: function () {
                return rowId;
            },
            setSelectRowId: function (newRowId) {
                rowId = newRowId;
            },
            isAddProgramClick: function () {
                return isAddProgramClick;
            },
            setIsAddProgramClick: function (newIsAddProgramClick) {
                isAddProgramClick = newIsAddProgramClick;
            },
            isOpenAddMode: function () {
                return isOpenAddMode;
            },
            setOpenAddMode: function (newIsOpenAddMode) {
                isOpenAddMode = newIsOpenAddMode;
            }
        };
    }

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub')
        .controller('MatchingToolRightSidePanelController', [
            'MatchingToolFactory',
            MatchingToolRightSidePanel
        ]);

    function MatchingToolRightSidePanel(
        MatchingToolFactory
    ) {
        var controller = this;

        controller.isEdit = function () {
            return MatchingToolFactory.isEdit();
        };

        controller.isAdd = function () {
            return MatchingToolFactory.isAdd();
        };

        controller.getData = function () {
            return MatchingToolFactory.getCourseData();
        };

        controller.getNewData = function () {
            return MatchingToolFactory.getNewCourseData();
        };
    }

} (window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services: {}});

    App.services.TuProfileMediaService = function () {
        var service = {
            types: {
                master : 'master',
                ug : 'ug',
                pg : 'pg'
            }
        };

        service.typeOverview = function () {
            return service.types.master;
        };

        service.typeUndergraduate = function () {
            return service.types.ug;
        };

        service.typePostgraduate = function () {
            return service.types.pg;
        };

        /**
         * Get TU profile subtypes.
         *
         * @returns {array}
         */
        service.getTypes = function () {
            return [
                service.typeOverview(),
                service.typeUndergraduate(),
                service.typePostgraduate()
            ];
        };

        service.isValidType = function (item) {
            var scopeTypes = service.getTypes(),
                valid = false;
            for (var typesIndex = 0; typesIndex < scopeTypes.length; typesIndex++) {
                if (item && item[scopeTypes[typesIndex]]) {
                    valid = true;
                }
            }

            return valid;
        };

        /**
         * Validate profile subtype.
         *
         * @param {Object} list
         * @returns {true|Object}
         */
        service.validateTypes = function (list) {
            for (var i = 0; i < list.length; i++) {
                // if invalid type
                if (!service.isValidType(list[i])) {
                    // return invalid item
                    return list[i];
                }
            }

            return true;
        };

        /**
         * Searches the object for a given value and returns the first corresponding key if successful.
         *
         * @param {Object} haystack
         * @param {String} needle
         * @param {null|String}column
         * @returns {boolean|int} - returns the key for needle if it is found in the array, FALSE otherwise.
         */
        service.objectSearch = function (haystack , needle, column) {
            column = column || 'id';
            for (var i = 0; i < haystack .length; i++) {
                if (haystack[i] && haystack[i][column] && haystack[i][column] === needle) {
                    return i;
                }
            }
            return false;
        };


        return service;
    };

    angular
        .module('qsHub')
        .service('TuMediaService', [
            App.services.TuProfileMediaService
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers:{}});

    App.controllers.TuProfileMedia = function (
        $scope,
        constants,
        InstitutionFactory,
        TuProfileFactory,
        WatchService
    ) {
        var controller = this,
            alreadyInitialised = false;
        controller.devMode = constants.dev;
        controller.upgradeEmailsTo = constants.emails.upgradeTu;
        $scope.isMediaReloading = true;

        controller.isImagesTabActive = function () {
            var selectedMediaTabId = TuProfileFactory.getSelectedMediaTabId();
            return selectedMediaTabId === 1 || selectedMediaTabId === null;
        };

        controller.isVideosTabActive = function () {
            return TuProfileFactory.getSelectedMediaTabId() === 2;
        };

        controller.isBrochuresTabActive = function () {
            return TuProfileFactory.getSelectedMediaTabId() === 4;
        };

        controller.isSocialMediaTabActive = function () {
            return TuProfileFactory.getSelectedMediaTabId() === 3;
        };

        controller.initWatches = function () {
            WatchService.create($scope, InstitutionFactory.isAdvanced, function (newValue, oldValue) {
                if (oldValue !== newValue) {
                    $scope.isAdvanced = newValue;
                }
            });
            // watch for active institution changes changes
            WatchService.create($scope, InstitutionFactory.getId, function (visible) {
                $scope.isMediaReloading = !visible;
            });
        };

        controller.init = function () {
            controller.initWatches();
        };

        // listen to departments tab visibility changes
        WatchService.create($scope, TuProfileFactory.isMediaTabSelected, function (isActive) {
            // if not already initialized and tab is active
            if (!alreadyInitialised && isActive) {
                controller.init();
                alreadyInitialised = true;
            }
        });
    };

    angular
        .module('qsHub')
        .controller('TuProfileMediaController', [
            '$scope',
            'constants',
            'InstitutionFactory',
            'TuProfileFactory',
            'WatchService',
            App.controllers.TuProfileMedia
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers:{}});

    App.controllers.TuMediaCommonSidebar = function (
        $scope,
        TuProfileFactory,
        TuMediaVideosService,
        TuMediaBrochuresService,
        TuMediaSocialMediasService,
        TuMediaImagesService
    ) {
        if(angular.isUndefined($scope.tabId)) {
            TuProfileFactory.setSelectedMediaTabId(1);
        }

        $scope.setSelected = function (tabId) {
            if ($scope.tabId !== tabId) {
                $scope.tabId = tabId;
                TuProfileFactory.setSelectedMediaTabId(tabId);
                return true;
            } else {
                return false;
            }
        };

        $scope.getSelected = function () {
            return TuProfileFactory.getSelectedMediaTabId();
        };

        $scope.getGeneralCounter = function ()
        {
            var totalCount = 0;
            var videoItems = TuMediaVideosService.getVideoItems();
            if (angular.isArray(videoItems)) {
                totalCount += videoItems.length;
            }
            var brochureItems = TuMediaBrochuresService.getBrochureItems();
            if (angular.isArray(brochureItems)) {
                totalCount += brochureItems.length;
            }
            var socialMediaItems = TuMediaSocialMediasService.getSocialMediaItems();
            if (angular.isArray(socialMediaItems)) {
                totalCount += socialMediaItems.length;
            }

            var imageItems = TuMediaImagesService.getImageItems();
            if (angular.isArray(imageItems)) {
                totalCount += imageItems.length;
            }

            return totalCount;
        };
    };

    angular
        .module('qsHub')
        .controller('TuMediaCommonSidebarController', [
            '$scope',
            'TuProfileFactory',
            'TuMediaVideosService',
            'TuMediaBrochuresService',
            'TuMediaSocialMediasService',
            'TuMediaImagesService',
            App.controllers.TuMediaCommonSidebar
        ]);

}(window.angular));

(function(angular) {
    'use strict';

    var App = App || {};
    App = angular.extend({}, App, { directives: {} });

    App.directives.useTuSubtypes = function(constants, ngIfDirective) {
        var ngIf = ngIfDirective[0];
        return {
            restrict: 'A',
            transclude: ngIf.transclude,
            priority: ngIf.priority - 1,
            terminal: ngIf.terminal,
            link: function($scope, $element, $attr) {
                $attr.ngIf = function() {
                    return !constants.noTuSubtypes;
                };
                ngIf.link.apply(ngIf, arguments);
            }
        };
    };

    angular
        .module('qsHub')
        .directive('useTuSubtypes', ['constants', 'ngIfDirective', App.directives.useTuSubtypes]);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers: {}});

    App.controllers.TuMediaVideos = function (
        $scope,
        constants,
        TuProfileFactory,
        NotifierFactory,
        TuMediaVideosService,
        TuMediaService,
        ModalService,
        $filter,
        orderBy,
        WatchService
    ) {
        var controller = this;
        controller.devMode = constants.dev;
        TuMediaVideosService.coreId = 0;
        $scope.videoItems = [];
        $scope.itemVideo = {};
        $scope.displayLocationDeletePopup = true;
        $scope.type = TuMediaService.typeOverview();

        TuMediaVideosService.setType(TuMediaService.typeOverview());
        TuMediaVideosService.setSelectedVideo(TuMediaVideosService.getItemVideo());
        WatchService.create($scope, function () {
            return TuProfileFactory.getData();
        }, function (newValue) {
            if (newValue !== null) {
                angular.copy(newValue.videos, $scope.videoItems);
                TuMediaVideosService.coreId = newValue.id;
                TuMediaVideosService.setVideoItems($scope.videoItems);
                $scope.filteredVideoItems = $filter('filter')($scope.videoItems, {master: true});
                $scope.filteredVideoItems = orderBy($scope.filteredVideoItems, 'orderType.' + $scope.type, false);
                TuMediaVideosService.setSelectedVideo(TuMediaVideosService.getItemVideo());
            }
        });

        WatchService.create($scope, TuMediaVideosService.getVideoItems, function (newValue, oldValue) {
            if (!angular.equals(oldValue, newValue)) {
                $scope.videoItems = newValue;
            }
        });

        WatchService.create($scope, TuMediaVideosService.getTriggerChange, function () {
            var selectedVideo = TuMediaVideosService.getSelectedVideo();
            if (
                ($scope.type === 'ug' && selectedVideo.ug) || 
                (!selectedVideo.master && selectedVideo.ug && !selectedVideo.pg) || 
                (!selectedVideo.master && selectedVideo.ug && selectedVideo.pg && ($scope.type === 'ug' || $scope.type === 'master'))
            ) {
                $scope.filter('ug');
            } else if (($scope.type === 'pg' && selectedVideo.pg) || (!selectedVideo.master && !selectedVideo.ug && selectedVideo.pg)) {
                $scope.filter('pg');
            } else {
                $scope.filter('master');
            }
            angular.forEach($scope.videoItems, function (value, key) {                
                if (selectedVideo.id === value.id) {
                    $scope.videoItems[key] = selectedVideo;
                }
            });
            $scope.filter($scope.type, true);
        });

        $scope.sortableOptions = {
            disabled: false,
            'ui-floating': false,
            start: function () {
                controller.initialList = [];
                angular.copy($scope.filteredVideoItems, controller.initialList);
            },
            update: function (e, ui) {
                var validateItems = TuMediaService.validateTypes($scope.videoItems);
                if (true !== validateItems) {
                    ui.item.sortable.cancel();
                }
            },
            stop: function () {
                var validateItems = TuMediaService.validateTypes($scope.videoItems);
                if (true === validateItems) {
                    var assignedOrder = 1;
                    // change videos order
                    for (var index = 0; index < $scope.filteredVideoItems.length; index++) {
                        if ($scope.filteredVideoItems[index][$scope.type]) {
                            $scope.filteredVideoItems[index].orderType[$scope.type] = assignedOrder;
                            assignedOrder++;
                        }
                    }

                    $scope.sortableOptions.disabled = false;
                    TuMediaVideosService.saveOrder(TuMediaVideosService.coreId, $scope.videoItems)
                        .then(function (success) {
                            NotifierFactory.show(
                                success ? 'success' : 'error',
                                success ? 'Success!' : 'Failure!',
                                'Video Order Save'
                            );
                            TuMediaVideosService.setSelectedVideo(TuMediaVideosService.getItemVideo());
                        })
                        .finally(function () {
                            $scope.sortableOptions.disabled = false;
                        });
                } else {
                    NotifierFactory.show(
                        'error',
                        'Failure!',
                        'Invalid items in video list'
                    );
                    TuMediaVideosService.setSelectedVideo(validateItems);
                    TuMediaVideosService.displayTypesValid = true;

                    return false;
                }
            }
        };

        /**
         * Filter change.
         *
         * @param type
         */
        $scope.filter = function (type) {
            var validateItems = TuMediaService.validateTypes($scope.videoItems);
            if (true === validateItems) {
                $scope.type = type;
                var params = {};
                params[type] = true;
                $scope.filteredVideoItems = $filter('filter')($scope.videoItems, params);
                $scope.filteredVideoItems = orderBy($scope.filteredVideoItems, 'orderType.' + type, false);
            } else {
                NotifierFactory.show(
                    'error',
                    'Failure!',
                    'Invalid items in video list'
                );
                TuMediaVideosService.setSelectedVideo(validateItems);
                TuMediaVideosService.displayTypesValid = true;

                return false;
            }
        };

        /**
         * Select video.
         *
         * @param {Object} item
         */
        $scope.selectVideo = function (item) {
            item = angular.copy(item);
            var validateItems = TuMediaService.validateTypes($scope.videoItems);
            if (true === validateItems) {
                TuMediaVideosService.setHasErrors(false);
                TuMediaVideosService.displayTypesValid = false;
                $scope.itemVideo = TuMediaVideosService.getItemVideo();
                if (angular.isDefined(item)) {
                    TuMediaVideosService.setSelectedVideo(item);
                } else {
                    TuMediaVideosService.setHasErrors(false);
                    TuMediaVideosService.setHighlighted(true);
                    TuMediaVideosService.setSelectedVideo($scope.itemVideo);
                }
            } else {
                NotifierFactory.show(
                    'error',
                    'Failure!',
                    'Invalid items in video list'
                );
                TuMediaVideosService.setSelectedVideo(validateItems);
                TuMediaVideosService.displayTypesValid = true;

                return false;
            }
        };

        $scope.selectedItem = function () {
            return TuMediaVideosService.getSelectedVideo();
        };

        /**
         * Delete video.
         *
         * @param item
         */
        $scope.deleteVideo = function (item) {
            var itemName = angular.isDefined(item.name) && item.name !== null ? ': ' + item.name : '';
            var modalOptions = {
                closeButtonText: 'Cancel',
                actionButtonText: 'Yes',
                headerText: 'Delete Video' + itemName + ' ?',
                bodyText: 'Are you sure you wish to delete this video?',
            };
            ModalService.show({}, modalOptions).then(function (result) {
                TuMediaVideosService.deleteVideo(TuMediaVideosService.coreId, item)
                    .then(function (success) {
                        $scope.submitInProgress = false;
                        NotifierFactory.show(
                            success ? 'success' : 'error',
                            success ? 'Success!' : 'Failure!',
                            'Delete Video'
                        );
                        if (success) {
                            TuMediaVideosService.setSelectedVideo(TuMediaVideosService.getItemVideo());
                            $scope.videoItems.splice(TuMediaService.objectSearch($scope.videoItems, item.id), 1);
                            $scope.filteredVideoItems.splice(TuMediaService.objectSearch($scope.filteredVideoItems, item.id), 1);

                            var assignedOrder = 1;
                            for (var index = 0; index < $scope.filteredVideoItems.length; index++) {
                                if ($scope.filteredVideoItems[index][$scope.type]) {
                                    $scope.filteredVideoItems[index].orderType[$scope.type] = assignedOrder;
                                    assignedOrder++;
                                }
                            }
                            $scope.filteredVideoItems = orderBy($scope.filteredVideoItems, 'orderType.' + $scope.type, false);
                            TuProfileFactory.setTuMediaUpdated(true);
                        }
                    });
            });
        };

        $scope.typeOverview = function () {
            return TuMediaService.typeOverview();
        };

        $scope.typeUndergraduate = function () {
            return TuMediaService.typeUndergraduate();
        };

        $scope.typePostgraduate = function () {
            return TuMediaService.typePostgraduate();
        };
    };

    angular
        .module('qsHub')
        .controller('TuMediaVideosController', [
            '$scope',
            'constants',
            'TuProfileFactory',
            'NotifierFactory',
            'TuMediaVideosService',
            'TuMediaService',
            'ModalService',
            '$filter',
            'orderByFilter',
            'WatchService',
            App.controllers.TuMediaVideos
        ]);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { controllers: {} });

    App.controllers.TuMediaVideosSidebar = function (
        $scope,
        $timeout,
        NotifierFactory,
        TuProfileFactory,
        TuMediaVideosService,
        TuMediaService,
        WebSocketsService,
        UrlService,
        WatchService,
        ObjectService
    ) {
        var controller = this,
            alreadyInitialised = false;
        $scope.hasErrors = true;
        TuMediaVideosService.displayTypesValid = false;
        TuMediaVideosService.setHasErrors(false);
        $scope.youtubeUrlPattern = UrlService.getYoutubePattern();

        controller.onKeyUp = function ($event) {
            if ($event.keyCode === 13) {
                return;
            }
            var url = $event.target.value,
                variableName = $event.target.getAttribute('ng-model');

            if (url && url.length > 5 && (url.indexOf('http') !== 0 || url.indexOf('http') === -1)) {
                url = UrlService.prependHttp(url);
                if (variableName) {
                    ObjectService.set($scope, variableName, url);
                }
            }
        };

        /**
         * Save form.
         *
         * @param item
         * @returns {boolean}
         */
        $scope.saveVideoForm = function (item) {
            if (!angular.isDefined(item.url) || !(angular.isDefined(item.url) && item.url.length > 0)) {
                $scope.setHasErrors(true);
            }
            if (!TuMediaService.isValidType(item)) {
                TuMediaVideosService.displayTypesValid = true;
            }
            if (TuMediaVideosService.displayTypesValid || $scope.getHasErrors()) {
                return false;
            }
            if (
                !$scope.forms.formVideo ||
                !$scope.forms.formVideo.$valid
            ) {
                TuMediaVideosService.setHasErrors(true);
                return false;
            }
            var videoItem = angular.copy(item);
            TuMediaVideosService.displayTypesValid = false;
            TuMediaVideosService.setHasErrors(false);
            var actionName = 'Added';
            if (videoItem.id.length > 0) {
                actionName = 'Updated';
            }
            if ($scope.submitInProgress || !$scope.forms.formVideo || !$scope.forms.formVideo.$valid
            ) {
                NotifierFactory.show(
                    'error',
                    'Error: Please fill in all required fields',
                    actionName + ' Video'
                );

                return false;
            }
            $scope.submitInProgress = true;

            // Update video and title to default while retrieving data from youtube
            var currentVideos = TuProfileFactory.getMediaVideos();
            for (var i = 0; i < currentVideos.length; i++) {
                if (item.id === currentVideos[i].id) {
                    currentVideos[i].name = null;
                    currentVideos[i].imageUrl = null;
                }
            }

            // start listening (open a room) to youtube video details changes
            var youtubeId = UrlService.extractYoutubeIdFromUrl(item.url);
            WebSocketsService.subscribe(youtubeId, 'VideoDetails' + youtubeId, function (response) {
                item.name = response && response.name || null;
                item.imageUrl = response && response.imageUrl || null;
                // mark as selected on the left side
                $scope.selectedVideo = angular.copy(TuMediaVideosService.getSelectedVideo());
            });
            TuMediaVideosService.saveVideo(TuMediaVideosService.coreId, videoItem)
                .then(function (success) {
                    $scope.submitInProgress = false;
                    NotifierFactory.show(
                        success ? 'success' : 'error',
                        success ? 'Successfully!' : 'Failure!',
                        'Video ' + actionName
                    );
                    if (success) {
                        if (videoItem.id.length === 0) {
                            item.id = success;
                            // add to the list
                            TuMediaVideosService.getVideoItems().unshift(item);
                            // mark as selected list
                            TuMediaVideosService.setSelectedVideo(item);
                            // mark as selected on the left side
                            $scope.selectedVideo = angular.copy(TuMediaVideosService.getSelectedVideo());
                        }
                        // mark as selected list
                        TuMediaVideosService.setSelectedVideo(item);
                        TuMediaVideosService.setHasErrors(false);
                    }
                    /**
                     * Tell other controllers to apply filter on changes
                     */
                    TuMediaVideosService.setTriggerChange(Math.random());
                });
        };

        /**
         * Clear item.
         *
         * @param item
         */
        $scope.clearVideoForm = function (item) {
            if (angular.isDefined(item)) {
                $scope.itemVideo = item;
            } else {
                $scope.itemVideo = TuMediaVideosService.getItemVideo();
            }

            $scope.itemVideo = TuMediaVideosService.resetItem($scope.itemVideo);
            TuMediaVideosService.setSelectedVideo($scope.itemVideo);
            $scope.setDisplayTypesValidation(false);
            $scope.setHasErrors(false);
        };

        /**
         * Get selected item.
         *
         * @returns {*}
         */
        $scope.getSelectedVideo = function () {
            return TuMediaVideosService.getSelectedVideo();
        };

        /**
         * Is edit mode?
         *
         * @returns {boolean}
         */
        $scope.isEditMode = function () {
            return $scope.selectedVideo && $scope.selectedVideo.id ? true : false;
        };

        /**
         * Get video items.
         *
         * @returns {*}
         */
        $scope.getVideoItems = function () {
            return TuMediaVideosService.getVideoItems();
        };

        /**
         * Get has errors flag.
         *
         * @returns {boolean|*}
         */
        $scope.getHasErrors = function () {
            return TuMediaVideosService.getHasErrors();
        };

        $scope.setHasErrors = function (value) {
            TuMediaVideosService.setHasErrors(value);
        };

        $scope.type = function () {
            return TuMediaVideosService.getType();
        };

        $scope.setDisplayTypesValidation = function (value) {
            TuMediaVideosService.displayTypesValid = value;
        };

        $scope.getDisplayTypesValidation = function () {
            return TuMediaVideosService.displayTypesValid;
        };

        controller.initWatches = function () {
            WatchService.create($scope, TuMediaVideosService.getSelectedVideo, function (selectedVideo) {
                $scope.selectedVideo = selectedVideo;
                $scope.selectedVideoBeforeChanges = angular.copy($scope.selectedVideo);
            });
            WatchService.create($scope, TuMediaVideosService.getHasErrors, function (newValue, oldValue) {
                if (!angular.equals(oldValue, newValue)) {
                    $scope.hasErrors = newValue;
                }
            });

            WatchService.create($scope, TuMediaVideosService.getHighlighted, function (newValue) {
                if (newValue) {
                    $scope.isHighlighted = newValue;
                    $timeout(function () {
                        TuMediaVideosService.setHighlighted(false);
                        $scope.isHighlighted = false;
                    }, 200);
                }
            });

            WatchService.create($scope, TuProfileFactory.getSelectedMediaTabId, function (tabId) {
                if (tabId === 2) {
                    // restore data on tab type switch
                    if (typeof $scope.selectedVideoBeforeChanges !== 'undefined') {
                        $scope.selectedVideo = angular.copy($scope.selectedVideoBeforeChanges);
                    }
                    $timeout(function () {
                        TuMediaVideosService.setHighlighted(true);
                    }, 200);
                } else {
                    delete TuMediaVideosService.displayTypesValid;
                    $scope.setHasErrors(false);
                }
            });
        };

        controller.init = function () {
            controller.initWatches();
        };

        // listen to departments tab visibility changes
        WatchService.create($scope, TuProfileFactory.isMediaTabSelected, function (isActive) {
            // if not already initialized and tab is active
            if (!alreadyInitialised && isActive) {
                controller.init();
                alreadyInitialised = true;
            }
        });
    };

    angular
        .module('qsHub')
        .controller('TuMediaVideosSidebarController', [
            '$scope',
            '$timeout',
            'NotifierFactory',
            'TuProfileFactory',
            'TuMediaVideosService',
            'TuMediaService',
            'WebSocketsService',
            'UrlService',
            'WatchService',
            'ObjectService',
            App.controllers.TuMediaVideosSidebar
        ]);

} (window.angular));
(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services: {}});

    App.services.TuMediaVideosService = function (
        $resource,
        $q,
        $log,
        constants,
        TuMediaService,
        TuProfileFactory
    ) {
        var service = {
            triggerChange: null,
            selectedVideo: null
        };

        service.getVideoModel = function () {
            return $resource(constants.api.institutions.url + '/v1/tu-profile/:id/video/:videoId', null, {
                update: {method: 'PATCH'}
            });
        };

        service.getVideosOrderingModel = function () {
            return $resource(constants.api.institutions.url + '/v1/tu-profile/:id/videosOrder', null, {
                update: {method: 'PATCH'}
            });
        };

        /**
         * Save Video
         *
         * @param institutionId
         * @param video
         * @returns {*}
         */
        service.saveVideo = function (institutionId, video) {
            var videoModel = service.getVideoModel(),
                videoSubmit = service.formatVideo(video);
            if (angular.isDefined(videoSubmit.id) && videoSubmit.id.length > 0) {
                // update video
                return videoModel.update(
                    {id: institutionId, videoId: video.id},
                    {
                        url: videoSubmit.url,
                        orderType: videoSubmit.orderType,
                        assignedTo: videoSubmit.assignedTo
                    }
                ).$promise.then(function (data) {
                    if (constants.dev) {
                        $log.log('success, got data: ', data);
                    }
                    return true;
                }, function (error) {
                    //@todo log error
                    if (constants.dev) {
                        $log.log('request failed' + error);
                    }
                    return false;
                });
            } else {
                // create new video
                return videoModel.save(
                    {id: institutionId, videoId: videoSubmit.id},
                    {
                        url: videoSubmit.url,
                        orderType: videoSubmit.orderType,
                        assignedTo: videoSubmit.assignedTo
                    }
                ).$promise.then(function (data) {
                    if (constants.dev) {
                        $log.log('success, got data: ', data);
                    }
                    return data.insertedId;
                }, function (error) {
                    //@todo log error
                    if (constants.dev) {
                        $log.log('request failed' + error);
                    }
                    return false;
                });
            }
        };

        /**
         * Save video order.
         *
         * @param {string} id - Institution TU profile mongo ID
         * @param {Object} videos
         * @returns {Promise}
         */
        service.saveOrder = function (id, videos) {
            var institutionModel = service.getVideosOrderingModel();
            return institutionModel.update(
                {id: id},
                {
                    'videos': service.formatForOrdering(videos)
                }
            ).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        service.formatForOrdering = function (items) {
            var formattedItems = [];
            angular.forEach(items, function (item) {
                formattedItems.push({
                    'id': item.id,
                    'orderType': item.orderType
                });
            });

            return formattedItems;
        };

        /**
         * Delete video.
         *
         * @param institutionId
         * @param video
         * @returns {*}
         */
        service.deleteVideo = function (institutionId, video) {
            service.setHasErrors(false);
            var videoModel = service.getVideoModel();
            return videoModel.delete(
                {id: institutionId, videoId: video.id}
            ).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        /**
         * Set selected video.
         *
         * @param {Object} item
         */
        service.setSelectedVideo = function (item) {
            service.selectedVideo = item;

        };

        /**
         * Get selected video
         *
         * @returns {Obejct|null}
         */
        service.getSelectedVideo = function () {
            return service.selectedVideo;
        };

        /**
         * Trigger filter.
         *
         * @param {float} triggerChange
         */
        service.setTriggerChange = function (triggerChange) {
            service.triggerChange = parseFloat(triggerChange);
        };

        /**
         * Get trigger filter value
         *
         * @returns {*}
         */
        service.getTriggerChange = function () {
            return service.triggerChange;
        };

        /**
         * Set video items.
         *
         * @param items
         * @param transform
         */
        service.setVideoItems = function (items, transform) {
            if (!angular.isDefined(transform)) {
                transform = true;
            }
            var typeUg = TuMediaService.typeUndergraduate(),
                typePg = TuMediaService.typePostgraduate(),
                typeMaster = TuMediaService.typeOverview();
            if (transform) {
                angular.forEach(items, function (value, key) {
                    items[key][typeMaster] = false;
                    items[key][typeUg] = false;
                    items[key][typePg] = false;
                    angular.forEach(items[key].assignedTo, function (aValue) {
                        if (aValue === typeMaster) {
                            items[key][typeMaster] = true;
                        } else if (aValue === typeUg) {
                            items[key][typeUg] = true;
                        } else if (aValue === typePg) {
                            items[key][typePg] = true;
                        }
                    });
                    /**
                     * Set initial order
                     * @type {{master: number, ug: number, pg: number}}
                     */
                    if (items[key].orderType === null) {
                        items[key].orderType = {
                            'master': 0,
                            'ug': 0,
                            'pg': 0
                        };
                    }
                    var orderOverview = 0;
                    var orderUndergraduate = 0;
                    var orderPostgraduate = 0;
                    if (angular.isDefined(items[key].orderType)) {
                        if (angular.isDefined(items[key].orderType[typeMaster])) {
                            orderOverview = items[key].orderType[typeMaster];
                        }
                        if (angular.isDefined(items[key].orderType[typeUg])) {
                            orderUndergraduate = items[key].orderType[typeUg];
                        }
                        if (angular.isDefined(items[key].orderType[typePg])) {
                            orderPostgraduate = items[key].orderType[typePg];
                        }
                    }
                    items[key].orderType = {
                        'master': orderOverview,
                        'ug': orderUndergraduate,
                        'pg': orderPostgraduate
                    };
                    if (angular.isDefined(items[key]._id)) {
                        items[key].id = items[key]._id;
                    }
                    delete items[key].assignedTo;
                    delete items[key]._id;
                });
            }
            TuProfileFactory.setMediaVideos(items);
            service.videoItems = items;
        };

        /**
         * Get video items.
         *
         * @returns {*}
         */
        service.getVideoItems = function () {
            return service.videoItems;
        };

        /**
         * Get video item.
         */
        service.getItemVideo = function () {
            var orderTypes = {};
            orderTypes[TuMediaService.typeOverview()] = 0;
            orderTypes[TuMediaService.typeUndergraduate()] = 0;
            orderTypes[TuMediaService.typePostgraduate()] = 0;
            return {
                'id': '',
                'name': '',
                'description': '',
                'master': true,
                'ug': true,
                'pg': true,
                'orderType': orderTypes,
                'url': '',
                'imageUrl': null
            };
        };

        /**
         * Reset video item.
         *
         * @param item
         * @returns {*}
         */
        service.resetItem = function (item) {
            item.master = true;
            item.ug = true;
            item.pg = true;
            item.url = '';
            item.name = '';
            item.imageUrl = null;

            return item;
        };

        /**
         * Format videos.
         *
         * @param videos
         * @returns {*}
         */
        service.formatVideos = function (videos) {
            angular.forEach(videos, function (value, key) {
                videos[key] = service.formatVideo(value);
            });

            return videos;
        };

        /**
         * Format to match db data.
         *
         * @param {Object} video
         * @returns {Object}
         */
        service.formatVideo = function (video) {
            if (video) {
                video.assignedTo = [];
                if (video.master) {
                    video.assignedTo.push('master');
                }
                if (video.ug) {
                    video.assignedTo.push('ug');
                }
                if (video.pg) {
                    video.assignedTo.push('pg');
                }
                // remove data we don't want to send.
            if (angular.isDefined(video.id) && parseInt(video.id, 10) === 0) {
                delete video.id;
            }
            delete video.master;
            delete video.ug;
            delete video.pg;
            delete video.order;
            }
            return video;
        };

        /**
         * Sets has errors flag.
         *
         * @param {boolean} hasErrors
         */
        service.setHasErrors = function (hasErrors) {
            service.hasErrors = hasErrors;
        };

        /**
         * Gets has errors flag.
         *
         * @returns {boolean|*}
         */
        service.getHasErrors = function () {
            return service.hasErrors;
        };

        /**
         * Sets is highlighted.
         *
         * @param {boolean} isHighlighted
         */
        service.setHighlighted = function (isHighlighted) {
            service.isHighlighted = isHighlighted;
        };

        /**
         * Gets is Highlighted.
         *
         * @returns {boolean|*}
         */
        service.getHighlighted = function () {
            return service.isHighlighted;
        };

        service.coreId = function (coreId) {
            service.coreId = coreId;
        };

        /**
         * Set type.
         *
         * @returns {*}
         */
        service.setType = function (type) {
            service.type = type;
        };

        /**
         * Get type.
         *
         * @returns {*}
         */
        service.getType = function () {
            return service.type;
        };

        return service;
    };

    angular
        .module('qsHub')
        .service('TuMediaVideosService', [
            '$resource',
            '$q',
            '$log',
            'constants',
            'TuMediaService',
            'TuProfileFactory',
            App.services.TuMediaVideosService
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { services: {} });

    App.services.TmMediaVideosService = function($resource, $q, $log, constants, TuMediaService) {
        var service = {
            triggerChange: null,
            selectedVideo: null
        };

        service.getVideoModel = function() {
            return $resource(constants.api.institutions.url + '/v1/tm-profile/:id/video/:videoId', null, {
                update: { method: 'PATCH' }
            });
        };

        service.getVideosOrderingModel = function() {
            return $resource(constants.api.institutions.url + '/v1/tm-profile/:id/videosOrder', null, {
                update: { method: 'PATCH' }
            });
        };

        /**
         * Save Video
         *
         * @param institutionId
         * @param video
         * @returns {*}
         */
        service.saveVideo = function(institutionId, video) {
            var videoModel = service.getVideoModel(),
                videoSubmit = service.formatVideo(video);
            if (angular.isDefined(videoSubmit.id) && videoSubmit.id.length > 0) {
                // update video
                return videoModel.update({ id: institutionId, videoId: video.id }, {
                    url: videoSubmit.url,
                    orderType: videoSubmit.orderType,
                    assignedTo: videoSubmit.assignedTo
                }).$promise.then(function(data) {
                    if (constants.dev) {
                        $log.log('success, got data: ', data);
                    }
                    return true;
                }, function(error) {
                    //@todo log error
                    if (constants.dev) {
                        $log.log('request failed' + error);
                    }
                    return false;
                });
            } else {
                // create new video
                return videoModel.save({ id: institutionId, videoId: videoSubmit.id }, {
                    url: videoSubmit.url,
                    orderType: videoSubmit.orderType,
                    assignedTo: videoSubmit.assignedTo
                }).$promise.then(function(data) {
                    if (constants.dev) {
                        $log.log('success, got data: ', data);
                    }
                    return data.insertedId;
                }, function(error) {
                    //@todo log error
                    if (constants.dev) {
                        $log.log('request failed' + error);
                    }
                    return false;
                });
            }
        };

        /**
         * Save video order.
         *
         * @param {string} id - Institution TU profile mongo ID
         * @param {Object} videos
         * @returns {Promise}
         */
        service.saveOrder = function(id, videos) {
            var institutionModel = service.getVideosOrderingModel();
            return institutionModel.update({ id: id }, {
                'videos': service.formatForOrdering(videos)
            }).$promise.then(function(data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function(error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        service.formatForOrdering = function(items) {
            var formattedItems = [];
            angular.forEach(items, function(item) {
                formattedItems.push({
                    'id': item.id,
                    'orderType': item.orderType
                });
            });

            return formattedItems;
        };

        /**
         * Delete video.
         *
         * @param institutionId
         * @param video
         * @returns {*}
         */
        service.deleteVideo = function(institutionId, video) {
            service.setHasErrors(false);
            var videoModel = service.getVideoModel();
            return videoModel.delete({ id: institutionId, videoId: video.id }).$promise.then(function(data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function(error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        /**
         * Set selected video.
         *
         * @param {Object} item
         */
        service.setSelectedVideo = function(item) {
            service.selectedVideo = item;
        };

        /**
         * Get selected video
         *
         * @returns {Obejct|null}
         */
        service.getSelectedVideo = function() {
            return service.selectedVideo;
        };

        /**
         * Trigger filter.
         *
         * @param {float} triggerChange
         */
        service.setTriggerChange = function(triggerChange) {
            service.triggerChange = parseFloat(triggerChange);
        };

        /**
         * Get trigger filter value
         *
         * @returns {*}
         */
        service.getTriggerChange = function() {
            return service.triggerChange;
        };

        /**
         * Set video items.
         *
         * @param items
         * @param transform
         */
        service.setVideoItems = function(items, transform) {
            if (!angular.isDefined(transform)) {
                transform = true;
            }
            var typeUg = TuMediaService.typeUndergraduate(),
                typePg = TuMediaService.typePostgraduate(),
                typeMaster = TuMediaService.typeOverview();
            if (transform) {
                angular.forEach(items, function(value, key) {
                    items[key][typeMaster] = true;
                    // items[key][typeUg] = false;
                    // items[key][typePg] = false;
                    angular.forEach(items[key].assignedTo, function(aValue) {
                        if (aValue === typeMaster) {
                            items[key][typeMaster] = true;
                        }
                    });
                    /**
                     * Set initial order
                     * @type {{master: number, ug: number, pg: number}}
                     */
                    if (items[key].orderType === null) {
                        items[key].orderType = {
                            'master': 0,
                            'ug': 0,
                            'pg': 0
                        };
                    }
                    var orderOverview = 0;
                    var orderUndergraduate = 0;
                    var orderPostgraduate = 0;
                    if (angular.isDefined(items[key].orderType)) {
                        if (angular.isDefined(items[key].orderType[typeMaster])) {
                            orderOverview = items[key].orderType[typeMaster];
                        }
                        if (angular.isDefined(items[key].orderType[typeUg])) {
                            orderUndergraduate = items[key].orderType[typeUg];
                        }
                        if (angular.isDefined(items[key].orderType[typePg])) {
                            orderPostgraduate = items[key].orderType[typePg];
                        }
                    }
                    items[key].orderType = {
                        'master': orderOverview,
                        'ug': orderUndergraduate,
                        'pg': orderPostgraduate
                    };
                    if (angular.isDefined(items[key]._id)) {
                        items[key].id = items[key]._id;
                    }
                    delete items[key].assignedTo;
                    delete items[key]._id;
                });
            }

            service.videoItems = items;
        };

        /**
         * Get video items.
         *
         * @returns {*}
         */

        service.getVideoItems = function() {
            return service.videoItems;
        };

        /**
         * Get video item.
         */
        service.getItemVideo = function() {
            var orderTypes = {};
            orderTypes[TuMediaService.typeOverview()] = 0;
            orderTypes[TuMediaService.typeUndergraduate()] = 0;
            orderTypes[TuMediaService.typePostgraduate()] = 0;
            return {
                'id': '',
                'name': '',
                'description': '',
                'master': true,
                'ug': true,
                'pg': true,
                'orderType': orderTypes,
                'url': '',
                'imageUrl': null
            };
        };

        /**
         * Reset video item.
         *
         * @param item
         * @returns {*}
         */
        service.resetItem = function(item) {
            item.master = true;
            item.ug = true;
            item.pg = true;
            item.url = '';
            item.name = '';
            item.imageUrl = null;

            return item;
        };

        /**
         * Format videos.
         *
         * @param videos
         * @returns {*}
         */
        service.formatVideos = function(videos) {
            angular.forEach(videos, function(value, key) {
                videos[key] = service.formatVideo(value);
            });

            return videos;
        };

        /**
         * Format to match db data.
         *
         * @param {Object} video
         * @returns {Object}
         */
        service.formatVideo = function(video) {
            video.assignedTo = [];
            if (video.master) {
                video.assignedTo.push('master');
            }
            if (video.ug) {
                video.assignedTo.push('ug');
            }
            if (video.pg) {
                video.assignedTo.push('pg');
            }
            // remove data we don't want to send.
            if (angular.isDefined(video.id) && parseInt(video.id, 10) === 0) {
                delete video.id;
            }
            delete video.master;
            delete video.ug;
            delete video.pg;
            delete video.order;

            return video;
        };

        /**
         * Sets has errors flag.
         *
         * @param {boolean} hasErrors
         */
        service.setHasErrors = function(hasErrors) {
            service.hasErrors = hasErrors;
        };

        /**
         * Gets has errors flag.
         *
         * @returns {boolean|*}
         */
        service.getHasErrors = function() {
            return service.hasErrors;
        };

        /**
         * Sets is highlighted.
         *
         * @param {boolean} isHighlighted
         */
        service.setHighlighted = function(isHighlighted) {
            service.isHighlighted = isHighlighted;
        };

        /**
         * Gets is Highlighted.
         *
         * @returns {boolean|*}
         */
        service.getHighlighted = function() {
            return service.isHighlighted;
        };

        service.setCoreId = function(coreId) {
            service.coreId = coreId;
        };

        service.getCoreId = function() {
            return service.coreId;
        };

        /**
         * Set type.
         *
         * @returns {*}
         */
        service.setType = function(type) {
            service.type = type;
        };

        /**
         * Get type.
         *
         * @returns {*}
         */
        service.getType = function() {
            return service.type;
        };

        return service;
    };

    angular
        .module('qsHub')
        .service('TmMediaVideosService', [
            '$resource',
            '$q',
            '$log',
            'constants',
            'TuMediaService',
            App.services.TmMediaVideosService
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { controllers: {} });

    App.controllers.TmMediaVideos = function(
        $scope,
        constants,
        TmProfileFactory,
        NotifierFactory,
        TmMediaVideosService,
        TmMediaService,
        ModalService,
        $filter,
        orderBy,
        WatchService
    ) {
        var controller = this;
        controller.devMode = constants.dev;
        TmMediaVideosService.coreId = 0;
        $scope.videoItems = [];
        $scope.itemVideo = {};
        $scope.displayLocationDeletePopup = true;
        $scope.type = TmMediaService.typeOverview();

        TmMediaVideosService.setType(TmMediaService.typeOverview());
        TmMediaVideosService.setSelectedVideo(TmMediaVideosService.getItemVideo());
        WatchService.create($scope, TmProfileFactory.getData, function(newValue) {
            if (newValue !== null) {
                angular.copy(newValue.videos, $scope.videoItems);
                TmMediaVideosService.coreId = newValue.id;
                TmMediaVideosService.setVideoItems($scope.videoItems);
                $scope.filteredVideoItems = $filter('filter')($scope.videoItems, { master: true });
                $scope.filteredVideoItems = orderBy($scope.filteredVideoItems, 'orderType.' + $scope.type, false);
            }
        });


        WatchService.create($scope, TmMediaVideosService.getVideoItems, function(newValue, oldValue) {
            if (!angular.equals(oldValue, newValue)) {
                $scope.videoItems = newValue;
            }
        });

        WatchService.create($scope, TmMediaVideosService.getTriggerChange, function() {
            var selectedVideo = TmMediaVideosService.getSelectedVideo();
            if (
                ($scope.type === 'ug' && selectedVideo.ug) ||
                (!selectedVideo.master && selectedVideo.ug && !selectedVideo.pg) ||
                (!selectedVideo.master && selectedVideo.ug && selectedVideo.pg && ($scope.type === 'ug' || $scope.type === 'master'))
            ) {
                $scope.filter('ug');
            } else if (($scope.type === 'pg' && selectedVideo.pg) || (!selectedVideo.master && !selectedVideo.ug && selectedVideo.pg)) {
                $scope.filter('pg');
            } else {
                $scope.filter('master');
            }
            angular.forEach($scope.videoItems, function(value, key) {
                if (selectedVideo.id === value.id) {
                    $scope.videoItems[key] = selectedVideo;
                }
            });
            $scope.filter($scope.type, true);
        });

        $scope.sortableOptions = {
            disabled: false,
            'ui-floating': false,
            start: function() {
                controller.initialList = [];
                angular.copy($scope.filteredVideoItems, controller.initialList);
            },
            update: function(e, ui) {
                var validateItems = TmMediaService.validateTypes($scope.videoItems);
                if (true !== validateItems) {
                    ui.item.sortable.cancel();
                }
            },
            stop: function() {
                var validateItems = TmMediaService.validateTypes($scope.videoItems);
                if (true === validateItems) {
                    var assignedOrder = 1;
                    // change videos order
                    for (var index = 0; index < $scope.filteredVideoItems.length; index++) {
                        if ($scope.filteredVideoItems[index][$scope.type]) {
                            $scope.filteredVideoItems[index].orderType[$scope.type] = assignedOrder;
                            assignedOrder++;
                        }
                    }

                    $scope.sortableOptions.disabled = false;
                    TmMediaVideosService.saveOrder(TmMediaVideosService.coreId, $scope.videoItems)
                        .then(function(success) {
                            NotifierFactory.show(
                                success ? 'success' : 'error',
                                success ? 'Success!' : 'Failure!',
                                'Video Order Save'
                            );
                            TmMediaVideosService.setSelectedVideo(TmMediaVideosService.getItemVideo());
                        })
                        .finally(function() {
                            $scope.sortableOptions.disabled = false;
                        });
                } else {
                    NotifierFactory.show(
                        'error',
                        'Failure!',
                        'Invalid items in video list'
                    );
                    TmMediaVideosService.setSelectedVideo(validateItems);
                    TmMediaVideosService.displayTypesValid = true;

                    return false;
                }
            }
        };

        /**
         * Filter change.
         *
         * @param type
         */
        $scope.filter = function(type) {
            var validateItems = TmMediaService.validateTypes($scope.videoItems);
            if (true === validateItems) {
                $scope.type = type;
                var params = {};
                params[type] = true;
                $scope.filteredVideoItems = $filter('filter')($scope.videoItems, params);
                $scope.filteredVideoItems = orderBy($scope.filteredVideoItems, 'orderType.' + type, false);
            } else {
                NotifierFactory.show(
                    'error',
                    'Failure!',
                    'Invalid items in video list'
                );
                TmMediaVideosService.setSelectedVideo(validateItems);
                TmMediaVideosService.displayTypesValid = true;

                return false;
            }
        };

        /**
         * Select video.
         *
         * @param {Object} item
         */
        $scope.selectVideo = function(item) {
            item = angular.copy(item);
            // var validateItems = TmMediaService.validateTypes($scope.videoItems);
            TmMediaVideosService.setHasErrors(false);
            $scope.itemVideo = TmMediaVideosService.getItemVideo();
            if (angular.isDefined(item)) {
                TmMediaVideosService.setSelectedVideo(item);
            } else {
                TmMediaVideosService.setHasErrors(false);
                TmMediaVideosService.setHighlighted(true);
                TmMediaVideosService.setSelectedVideo($scope.itemVideo);
            }
            // if (true === validateItems) {
            //     TmMediaVideosService.setHasErrors(false);
            //     TmMediaVideosService.displayTypesValid = false;
            //     $scope.itemVideo = TmMediaVideosService.getItemVideo();
            //     if (angular.isDefined(item)) {
            //         TmMediaVideosService.setSelectedVideo(item);
            //     } else {
            //         TmMediaVideosService.setHasErrors(false);
            //         TmMediaVideosService.setHighlighted(true);
            //         TmMediaVideosService.setSelectedVideo($scope.itemVideo);
            //     }
            // } else {
            //     NotifierFactory.show(
            //         'error',
            //         'Failure!',
            //         'Invalid items in video list'
            //     );
            //     TmMediaVideosService.setSelectedVideo(validateItems);
            //     TmMediaVideosService.displayTypesValid = true;

            //     return false;
            // }
        };

        $scope.selectedItem = function() {
            return TmMediaVideosService.getSelectedVideo();
        };

        /**
         * Delete video.
         *
         * @param item
         */
        $scope.deleteVideo = function(item) {
            var itemName = angular.isDefined(item.name) && item.name !== null ? ': ' + item.name : '';
            var modalOptions = {
                closeButtonText: 'Cancel',
                actionButtonText: 'Yes',
                headerText: 'Delete Video' + itemName + ' ?',
                bodyText: 'Are you sure you wish to delete this video?',
            };
            ModalService.show({}, modalOptions).then(function(result) {
                TmMediaVideosService.deleteVideo(TmMediaVideosService.coreId, item)
                    .then(function(success) {
                        $scope.submitInProgress = false;
                        NotifierFactory.show(
                            success ? 'success' : 'error',
                            success ? 'Success!' : 'Failure!',
                            'Delete Video'
                        );
                        if (success) {
                            TmMediaVideosService.setSelectedVideo(TmMediaVideosService.getItemVideo());
                            $scope.videoItems.splice(TmMediaService.objectSearch($scope.videoItems, item.id), 1);
                            $scope.filteredVideoItems.splice(TmMediaService.objectSearch($scope.filteredVideoItems, item.id), 1);


                            var assignedOrder = 1;
                            for (var index = 0; index < $scope.filteredVideoItems.length; index++) {
                                if ($scope.filteredVideoItems[index][$scope.type]) {
                                    $scope.filteredVideoItems[index].orderType[$scope.type] = assignedOrder;
                                    assignedOrder++;
                                }
                            }
                            $scope.filteredVideoItems = orderBy($scope.filteredVideoItems, 'orderType.' + $scope.type, false);
                        }
                    });
            });
        };

        $scope.typeOverview = function() {
            return TmMediaService.typeOverview();
        };

        $scope.typeUndergraduate = function() {
            return TmMediaService.typeUndergraduate();
        };

        $scope.typePostgraduate = function() {
            return TmMediaService.typePostgraduate();
        };
    };

    angular
        .module('qsHub')
        .controller('TmMediaVideosController', [
            '$scope',
            'constants',
            'TmProfileFactory',
            'NotifierFactory',
            'TmMediaVideosService',
            'TmMediaService',
            'ModalService',
            '$filter',
            'orderByFilter',
            'WatchService',
            App.controllers.TmMediaVideos
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { controllers: {} });

    App.controllers.TmMediaVideosSidebar = function(
        $scope,
        $timeout,
        NotifierFactory,
        TmProfileFactory,
        TmMediaVideosService,
        TmMediaService,
        WebSocketsService,
        UrlService,
        WatchService,
        ObjectService
    ) {
        var controller = this,
            alreadyInitialised = false;
        $scope.hasErrors = true;
        TmMediaVideosService.displayTypesValid = false;
        TmMediaVideosService.setHasErrors(false);
        $scope.youtubeUrlPattern = UrlService.getYoutubePattern();

        controller.onKeyUp = function ($event) {
            if ($event.keyCode === 13) {
                return;
            }
            var url = $event.target.value,
                variableName = $event.target.getAttribute('ng-model');

            if (url && url.length > 5 && (url.indexOf('http') !== 0 || url.indexOf('http') === -1)) {
                url = UrlService.prependHttp(url);
                if (variableName) {
                    ObjectService.set($scope, variableName, url);
                }
            }
        };

        /**
         * Save form.
         *
         * @param item
         * @returns {boolean}
         */
        $scope.saveVideoForm = function(item) {
            if (!angular.isDefined(item.url) || !(angular.isDefined(item.url) && item.url.length > 0)) {
                $scope.setHasErrors(true);
            }
            if (!TmMediaService.isValidType(item)) {
                TmMediaVideosService.displayTypesValid = true;
            }
            if (TmMediaVideosService.displayTypesValid || $scope.getHasErrors()) {
                return false;
            }

            if (!$scope.forms.formVideo ||
                !$scope.forms.formVideo.$valid
            ) {
                TmMediaVideosService.setHasErrors(true);
                return false;
            }
            var videoItem = angular.copy(item);
            TmMediaVideosService.displayTypesValid = false;
            TmMediaVideosService.setHasErrors(false);
            var actionName = 'Added';
            if (videoItem.id.length > 0) {
                actionName = 'Updated';
            }
            if ($scope.submitInProgress || !$scope.forms.formVideo || !$scope.forms.formVideo.$valid) {
                NotifierFactory.show(
                    'error',
                    'Error: Please fill in all required fields',
                    actionName + ' Video'
                );

                return false;
            }
            $scope.submitInProgress = true;

            // start listening (open a room) to youtube video details changes
            var youtubeId = UrlService.extractYoutubeIdFromUrl(item.url);
            WebSocketsService.subscribe(youtubeId, 'VideoDetails' + youtubeId, function (response) {
                item.name = response && response.name || null;
                item.imageUrl = response && response.imageUrl || null;
                // mark as selected on the left side
                $scope.selectedVideo = angular.copy(TmMediaVideosService.getSelectedVideo());
            });

            TmMediaVideosService.saveVideo(TmMediaVideosService.getCoreId(), videoItem)
                .then(function(success) {
                    $scope.submitInProgress = false;
                    NotifierFactory.show(
                        success ? 'success' : 'error',
                        success ? 'Successfully!' : 'Failure!',
                        'Video ' + actionName
                    );
                    if (success) {
                        if (videoItem.id.length === 0) {
                            item.id = success;
                            // add to the list
                            TmMediaVideosService.getVideoItems().unshift(item);
                            // mark as selected list
                            TmMediaVideosService.setSelectedVideo(item);
                            // mark as selected on the left side
                            $scope.selectedVideo = angular.copy(TmMediaVideosService.getSelectedVideo());
                        }
                        // mark as selected list
                        TmMediaVideosService.setSelectedVideo(item);
                        TmMediaVideosService.setHasErrors(false);
                    }
                    /**
                     * Tell other controllers to apply filter on changes
                     */
                    TmMediaVideosService.setTriggerChange(Math.random());
                });
        };

        /**
         * Clear item.
         *
         * @param item
         */
        $scope.clearVideoForm = function(item) {
            if (angular.isDefined(item)) {
                $scope.itemVideo = item;
            } else {
                $scope.itemVideo = TmMediaVideosService.getItemVideo();
            }

            $scope.itemVideo = TmMediaVideosService.resetItem($scope.itemVideo);
            TmMediaVideosService.setSelectedVideo($scope.itemVideo);
            $scope.setDisplayTypesValidation(false);
            $scope.setHasErrors(false);
        };

        /**
         * Get selected item.
         *
         * @returns {*}
         */
        $scope.getSelectedVideo = function() {
            return TmMediaVideosService.getSelectedVideo();
        };

        /**
         * Is edit mode?
         *
         * @returns {boolean}
         */
        $scope.isEditMode = function() {
            return $scope.selectedVideo && $scope.selectedVideo.id ? true : false;
        };

        /**
         * Get video items.
         *
         * @returns {*}
         */
        $scope.getVideoItems = function() {
            return TmMediaVideosService.getVideoItems();
        };

        /**
         * Get has errors flag.
         *
         * @returns {boolean|*}
         */
        $scope.getHasErrors = function() {
            return TmMediaVideosService.getHasErrors();
        };

        $scope.setHasErrors = function(value) {
            TmMediaVideosService.setHasErrors(value);
        };

        $scope.type = function() {
            return TmMediaVideosService.getType();
        };

        $scope.setDisplayTypesValidation = function(value) {
            TmMediaVideosService.displayTypesValid = value;
        };

        $scope.getDisplayTypesValidation = function() {
            return TmMediaVideosService.displayTypesValid;
        };

        controller.initWatches = function() {
            WatchService.create($scope, TmMediaVideosService.getSelectedVideo, function(selectedVideo) {
                $scope.selectedVideo = selectedVideo;
            });

            WatchService.create($scope, TmMediaVideosService.getHasErrors, function(newValue, oldValue) {
                if (!angular.equals(oldValue, newValue)) {
                    $scope.hasErrors = newValue;
                }
            });

            WatchService.create($scope, TmMediaVideosService.getHighlighted, function(newValue) {
                if (newValue) {
                    $scope.isHighlighted = newValue;
                    $timeout(function() {
                        TmMediaVideosService.setHighlighted(false);
                        $scope.isHighlighted = false;
                    }, 200);
                }
            });

            WatchService.create($scope, TmProfileFactory.getSelectedMediaSubTabId, function(tabId) {
                if (tabId === 2) {
                    $timeout(function() {
                        TmMediaVideosService.setHighlighted(true);
                    }, 200);
                } else {
                    delete TmMediaVideosService.displayTypesValid;
                    $scope.setHasErrors(false);
                }
            });
        };

        controller.$onInit = function () {
            // listen to videos tab visibility changes
            WatchService.create($scope, TmProfileFactory.isMediaTabSelected,function(isActive) {
                // if not already initialized and tab is active
                if (!alreadyInitialised && isActive) {
                    controller.initWatches();
                    alreadyInitialised = true;
                }
            });
        };

    };

    angular
        .module('qsHub')
        .controller('TmMediaVideosSidebarController', [
            '$scope',
            '$timeout',
            'NotifierFactory',
            'TmProfileFactory',
            'TmMediaVideosService',
            'TmMediaService',
            'WebSocketsService',
            'UrlService',
            'WatchService',
            'ObjectService',
            App.controllers.TmMediaVideosSidebar
        ]);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers: {}});

    App.controllers.TuMediaBrochures = function (
        $scope,
        constants,
        TuProfileFactory,
        NotifierFactory,
        TuMediaBrochuresService,
        TuMediaService,
        ModalService,
        $filter,
        orderBy,
        WatchService
    ) {
        var controller = this;
        controller.devMode = constants.dev;
        TuMediaBrochuresService.coreId = 0;
        $scope.brochureItems = [];
        $scope.itemBrochure = {};
        $scope.displayLocationDeletePopup = true;
        $scope.type = TuMediaService.typeOverview();

        TuMediaBrochuresService.setType(TuMediaService.typeOverview());

        WatchService.create($scope, function () {
            return TuProfileFactory.getData();
        }, function (newValue) {
            TuMediaBrochuresService.setSelectedBrochure(TuMediaBrochuresService.getItemBrochure());

            if (newValue && typeof newValue.brochures !== 'undefined') {
                angular.copy(newValue.brochures, $scope.brochureItems);
            } else {
                $scope.brochureItems = [];
            }
            if (newValue !== null) {
                TuMediaBrochuresService.coreId = newValue.id;
                TuMediaBrochuresService.setBrochureItems($scope.brochureItems);
                $scope.filteredBrochureItems = $filter('filter')($scope.brochureItems, {master: true});
                $scope.filteredBrochureItems = orderBy($scope.filteredBrochureItems, 'orderType.' + $scope.type, false);}
            }
        );

        WatchService.create($scope, TuMediaBrochuresService.getBrochureItems(), function (newValue, oldValue) {
            if (!angular.equals(oldValue, newValue)) {
                $scope.brochureItems = newValue;

            }
        });

        WatchService.create($scope, TuMediaBrochuresService.getTriggerChange, function () {
            if (angular.isDefined($scope.selectedItemId()) && $scope.selectedItemId().length > 0) {
                TuMediaBrochuresService.replaceByKey($scope.selectedItemId());
            }
            var selectedBrochure = TuMediaBrochuresService.getSelectedBrochure();
            if (
                ($scope.type === 'ug' && selectedBrochure.ug) || 
                (!selectedBrochure.master && selectedBrochure.ug && !selectedBrochure.pg) || 
                (!selectedBrochure.master && selectedBrochure.ug && selectedBrochure.pg && ($scope.type === 'ug' || $scope.type === 'master'))
            ) {
                $scope.filter('ug');
            } else if (($scope.type === 'pg' && selectedBrochure.pg) || (!selectedBrochure.master && !selectedBrochure.ug && selectedBrochure.pg)) {
                $scope.filter('pg');
            } else {
                $scope.filter('master');
            }
        });

        $scope.sortableOptions = {
            disabled: false,
            'ui-floating': false,
            start: function () {
                controller.initialList = [];
                angular.copy($scope.filteredBrochureItems, controller.initialList);
            },
            update: function (e, ui) {
                var validateItems = TuMediaService.validateTypes($scope.brochureItems);
                if (true !== validateItems) {
                    ui.item.sortable.cancel();
                }
            },
            stop: function () {
                var validateItems = TuMediaService.validateTypes($scope.brochureItems);
                if (true === validateItems) {
                    var assignedOrder = 1;
                    // change brochures order
                    for (var index = 0; index < $scope.filteredBrochureItems.length; index++) {
                        if ($scope.filteredBrochureItems[index][$scope.type]) {
                            $scope.filteredBrochureItems[index].orderType[$scope.type] = assignedOrder;
                            assignedOrder++;
                        }
                    }

                    $scope.sortableOptions.disabled = false;
                    TuMediaBrochuresService.saveOrder(TuMediaBrochuresService.coreId, $scope.brochureItems)
                        .then(function (success) {
                            NotifierFactory.show(
                                success ? 'success' : 'error',
                                success ? 'Success!' : 'Failure!',
                                'Brochure Order Save'
                            );
                            TuMediaBrochuresService.setSelectedBrochure(TuMediaBrochuresService.getItemBrochure());
                        })
                        .finally(function () {
                            $scope.sortableOptions.disabled = false;
                        });
                } else {
                    NotifierFactory.show(
                        'error',
                        'Failure!',
                        'Invalid items in brochure list'
                    );
                    TuMediaBrochuresService.setSelectedBrochure(validateItems);
                    TuMediaBrochuresService.displayTypesValid = true;

                    return false;
                }
            }
        };

        /**
         * Filter change.
         *
         * @param type
         */
        $scope.filter = function (type) {
            var validateItems = TuMediaService.validateTypes($scope.brochureItems);
            if (true === validateItems) {
                $scope.type = type;
                var params = {};
                params[type] = true;
                $scope.filteredBrochureItems = $filter('filter')($scope.brochureItems, params);
                $scope.filteredBrochureItems = orderBy($scope.filteredBrochureItems, 'orderType.' + type, false);
            } else {
                NotifierFactory.show(
                    'error',
                    'Failure!',
                    'Invalid items in brochure list'
                );
                TuMediaBrochuresService.setSelectedBrochure(validateItems);
                TuMediaBrochuresService.displayTypesValid = true;

                return false;
            }
        };

        /**
         * Select brochure.
         *
         * @param {Object} item
         */
        $scope.selectBrochure = function (item) {
            item = angular.copy(item);
            var validateItems = TuMediaService.validateTypes($scope.brochureItems);
            if (true === validateItems) {
                TuMediaBrochuresService.setHasErrors(false);
                TuMediaBrochuresService.displayTypesValid = false;
                $scope.itemBrochure = TuMediaBrochuresService.getItemBrochure();
                if (angular.isDefined(item)) {
                    TuMediaBrochuresService.setSelectedBrochure(item);
                } else {
                    TuMediaBrochuresService.setHasErrors(false);
                    TuMediaBrochuresService.setHighlighted(true);
                    TuMediaBrochuresService.setSelectedBrochure($scope.itemBrochure);
                }
                TuMediaBrochuresService.setTriggerReset(Math.random());
            } else {
                NotifierFactory.show(
                    'error',
                    'Failure!',
                    'Invalid items in brochure list'
                );
                TuMediaBrochuresService.setSelectedBrochure(validateItems);
                TuMediaBrochuresService.displayTypesValid = true;

                return false;
            }
        };

        $scope.selectedItem = function () {
            return TuMediaBrochuresService.getSelectedBrochure();
        };

        $scope.selectedItemId = function () {
            return TuMediaBrochuresService.getSelectedBrochure().id;
        };

        /**
         * Delete brochure.
         *
         * @param item
         */
        $scope.deleteBrochure = function (item) {
            var itemName = angular.isDefined(item.name) && item.name !== null ? ': ' + item.name : '';
            var modalOptions = {
                closeButtonText: 'Cancel',
                actionButtonText: 'Yes',
                headerText: 'Delete Brochure' + itemName + ' ?',
                bodyText: 'Are you sure you wish to delete this brochure?',
            };
            ModalService.show({}, modalOptions).then(function (result) {
                TuMediaBrochuresService.deleteBrochure(TuMediaBrochuresService.coreId, item)
                    .then(function (success) {
                        $scope.submitInProgress = false;
                        NotifierFactory.show(
                            success ? 'success' : 'error',
                            success ? 'Success!' : 'Failure!',
                            'Delete Brochure'
                        );
                        if (success) {
                            TuMediaBrochuresService.setSelectedBrochure(TuMediaBrochuresService.getItemBrochure());
                            $scope.brochureItems.splice(TuMediaService.objectSearch($scope.brochureItems, item.id), 1);
                            $scope.filteredBrochureItems.splice(TuMediaService.objectSearch($scope.filteredBrochureItems, item.id), 1);


                            var assignedOrder = 1;
                            for (var index = 0; index < $scope.filteredBrochureItems.length; index++) {
                                if ($scope.filteredBrochureItems[index][$scope.type]) {
                                    $scope.filteredBrochureItems[index].orderType[$scope.type] = assignedOrder;
                                    assignedOrder++;
                                }
                            }
                            $scope.filteredBrochureItems = orderBy($scope.filteredBrochureItems, 'orderType.' + $scope.type, false);
                            TuProfileFactory.setTuMediaUpdated(true);
                        }
                    });
            });
        };

        $scope.typeOverview = function () {
            return TuMediaService.typeOverview();
        };

        $scope.typeUndergraduate = function () {
            return TuMediaService.typeUndergraduate();
        };

        $scope.typePostgraduate = function () {
            return TuMediaService.typePostgraduate();
        };
    };

    angular
        .module('qsHub')
        .controller('TuMediaBrochuresController', [
            '$scope',
            'constants',
            'TuProfileFactory',
            'NotifierFactory',
            'TuMediaBrochuresService',
            'TuMediaService',
            'ModalService',
            '$filter',
            'orderByFilter',
            'WatchService',
            App.controllers.TuMediaBrochures
        ]);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { controllers: {} });

    App.controllers.TuMediaBrochuresSidebar = function (
        $scope,
        $timeout,
        NotifierFactory,
        TuProfileFactory,
        TuMediaBrochuresService,
        TuMediaService,
        WatchService,
        UrlService,
        ObjectService
    ) {
        var controller = this,
            alreadyInitialised = false;
        $scope.hasErrors = true;
        TuMediaBrochuresService.displayTypesValid = false;
        TuMediaBrochuresService.setHasErrors(false);

        controller.onKeyUp = function ($event) {
            if ($event.keyCode === 13) {
                return;
            }
            var url = $event.target.value,
                variableName = $event.target.getAttribute('ng-model');

            if (url && url.length > 5 && (url.indexOf('http') !== 0 || url.indexOf('http') === -1)) {
                url = UrlService.prependHttp(url);
                if (variableName) {
                    ObjectService.set($scope, variableName, url);
                }
            }
        };

        controller.urlPattern = UrlService.getPattern();

        /**
         * Save form.
         *
         * @param item
         * @returns {boolean}
         */
        $scope.saveBrochureForm = function (item) {
            var brochureItem = angular.copy(item);
            TuMediaBrochuresService.displayTypesValid = false;
            TuMediaBrochuresService.setHasErrors(false);
            $scope.isInvalidTitle = false;
            $scope.isInvalidUrl = false;
            var actionName = 'Added';
            if (brochureItem.id.length > 0) {
                actionName = 'Updated';
            }
            if (!TuMediaService.isValidType(item)) {
                TuMediaBrochuresService.displayTypesValid = true;
            }
            if (!angular.isDefined(item.name) || !(angular.isDefined(item.name) && item.name.length > 0)) {
                $scope.isInvalidTitle = true;
            }
            if (!angular.isDefined(item.url) || !(angular.isDefined(item.url) && item.url.length > 0)) {
                $scope.isInvalidUrl = true;
            }
            if ($scope.isInvalidTitle || $scope.isInvalidUrl || TuMediaBrochuresService.displayTypesValid) {
                $scope.setHasErrors(true);
                return false;
            }
            if (!(item.url.match(controller.urlPattern))) {
                $scope.isInvalidUrl = true;
                $scope.setHasErrors(true);
                return false;
            }
            if ($scope.submitInProgress || !$scope.forms.formBrochure || !$scope.forms.formBrochure.$valid
            ) {
                NotifierFactory.show(
                    'error',
                    'Error: Please fill in all required fields',
                    actionName + ' Brochure'
                );

                return false;
            }
            $scope.submitInProgress = true;
            TuMediaBrochuresService.saveBrochure(TuMediaBrochuresService.coreId, brochureItem)
                .then(function (success) {
                    $scope.submitInProgress = false;
                    NotifierFactory.show(
                        success ? 'success' : 'error',
                        success ? 'Successfully!' : 'Failure!',
                        'Brochure ' + actionName
                    );
                    if (success) {
                        if (brochureItem.id.length === 0) {
                            item.id = success;
                            TuMediaBrochuresService.getBrochureItems().unshift(item);
                            TuMediaBrochuresService.setSelectedBrochure(item);
                            $scope.selectedBrochure = angular.copy(TuMediaBrochuresService.getSelectedBrochure());
                        }

                        TuMediaBrochuresService.setSelectedBrochure(item);
                        TuMediaBrochuresService.setHasErrors(false);
                        $scope.selectedBrochureBeforeChanges = item;
                    }
                    /**
                     * Tell other controllers to apply filter on changes
                     */
                    TuMediaBrochuresService.setTriggerChange(Math.random());
                });
        };

        /**
         * Clear item.
         *
         * @param item
         */
        $scope.clearBrochureForm = function (item) {
            if (angular.isDefined(item)) {
                $scope.itemBrochure = item;
            } else {
                $scope.itemBrochure = TuMediaBrochuresService.getItemBrochure();
            }

            $scope.itemBrochure = TuMediaBrochuresService.resetItem($scope.itemBrochure);
            $scope.setDisplayTypesValidation(false);
            delete $scope.isInvalidTitle;
            delete $scope.isInvalidUrl;
        };

        /**
         * Get selected item.
         *
         * @returns {*}
         */
        $scope.getSelectedBrochure = function () {
            return TuMediaBrochuresService.getSelectedBrochure();
        };

        /**
         * Get brochure items.
         *
         * @returns {*}
         */
        $scope.getBrochureItems = function () {
            return TuMediaBrochuresService.getBrochureItems();
        };

        /**
         * Get has errors flag.
         *
         * @returns {boolean|*}
         */
        $scope.getHasErrors = function () {
            return TuMediaBrochuresService.getHasErrors();
        };

        $scope.setHasErrors = function (value) {
            TuMediaBrochuresService.setHasErrors(value);
        };

        $scope.setIsInvalidTitle = function (value) {
            $scope.isInvalidTitle = value;
        };

        $scope.setIsInvalidUrl = function (value) {
            $scope.isInvalidUrl = value;
        };

        $scope.type = function () {
            return TuMediaBrochuresService.getType();
        };

        $scope.setDisplayTypesValidation = function (value) {
            TuMediaBrochuresService.displayTypesValid = value;
        };

        $scope.getDisplayTypesValidation = function () {
            return TuMediaBrochuresService.displayTypesValid;
        };

        /**
         * Is edit mode?
         *
         * @returns {boolean}
         */
        $scope.isEditMode = function () {
            return $scope.selectedBrochure && $scope.selectedBrochure.id ? true : false;
        };

        controller.initWatches = function () {
            WatchService.create($scope, TuMediaBrochuresService.getSelectedBrochure, function (selectedBrochure) {
                $scope.isInvalidUrl = false;
                $scope.isInvalidTitle = false;
                $scope.selectedBrochureBeforeChanges = angular.copy(selectedBrochure);
                $scope.selectedBrochure = selectedBrochure;
            });

            WatchService.create($scope, TuMediaBrochuresService.getHasErrors, function (newValue, oldValue) {
                if (!angular.equals(oldValue, newValue)) {
                    $scope.hasErrors = newValue;
                }
            });

            WatchService.create($scope, TuMediaBrochuresService.getTriggerReset, function (newValue, oldValue) {
                if (!angular.equals(oldValue, newValue)) {
                    $scope.selectedBrochure = angular.copy($scope.selectedBrochureBeforeChanges);
                }
            });

            WatchService.create($scope, TuMediaBrochuresService.getHighlighted, function (newValue) {
                if (newValue) {
                    $scope.isHighlighted = newValue;
                    $timeout(function () {
                        TuMediaBrochuresService.setHighlighted(false);
                        $scope.isHighlighted = false;
                    }, 200);
                }
            });

            WatchService.create($scope, TuProfileFactory.getSelectedMediaTabId, function (tabId) {
                if (tabId === 4) {
                    // restore data on tab type switch
                    if (typeof $scope.selectedBrochureBeforeChanges !== 'undefined') {
                        $scope.selectedBrochure = angular.copy($scope.selectedBrochureBeforeChanges);
                    }
                    $timeout(function () {
                        TuMediaBrochuresService.setHighlighted(true);
                    }, 200);
                } else {
                    delete $scope.isInvalidTitle;
                    delete $scope.isInvalidUrl;
                    delete TuMediaBrochuresService.displayTypesValid;
                }
            });
        };

        controller.init = function () {
            controller.initWatches();
        };

        // listen to departments tab visibility changes
        WatchService.create($scope, TuProfileFactory.isMediaTabSelected, function (isActive) {
            // if not already initialized and tab is active
            if (!alreadyInitialised && isActive) {
                controller.init();
                alreadyInitialised = true;
            }
        });
    };

    angular
        .module('qsHub')
        .controller('TuMediaBrochuresSidebarController', [
            '$scope',
            '$timeout',
            'NotifierFactory',
            'TuProfileFactory',
            'TuMediaBrochuresService',
            'TuMediaService',
            'WatchService',
            'UrlService',
            'ObjectService',
            App.controllers.TuMediaBrochuresSidebar
        ]);

} (window.angular));
(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services: {}});

    App.services.TuMediaBrochuresService = function (
        $resource,
        $q,
        $log,
        constants,
        TuMediaService,
        TuMediaVideosService
    ) {
        var service = {
            triggerChange: 0
        };

        service.getBrochureModel = function () {
            return $resource(constants.api.institutions.url + '/v1/tu-profile/:id/brochure/:brochureId', null, {
                update: {method: 'PATCH'}
            });
        };

        service.getBrochuresOrderingModel = function () {
            return $resource(constants.api.institutions.url + '/v1/tu-profile/:id/brochuresOrder', null, {
                update: {method: 'PATCH'}
            });
        };

        /**
         * Save Brochure
         *
         * @param institutionId
         * @param brochure
         * @returns {*}
         */
        service.saveBrochure = function (institutionId, brochure) {
            var brochureModel = service.getBrochureModel(),
                brochureSubmit = service.formatBrochure(brochure);
            if (angular.isDefined(brochureSubmit.id) && brochureSubmit.id.length > 0) {
                // update brochure
                return brochureModel.update(
                    {id: institutionId, brochureId: brochure.id},
                    {
                        name: brochureSubmit.name,
                        url: brochureSubmit.url,
                        orderType: brochureSubmit.orderType,
                        assignedTo: brochureSubmit.assignedTo
                    }
                ).$promise.then(function (data) {
                    if (constants.dev) {
                        $log.log('success, got data: ', data);
                    }
                    return true;
                }, function (error) {
                    //@todo log error
                    if (constants.dev) {
                        $log.log('request failed' + error);
                    }
                    return false;
                });
            } else {
                // create new brochure
                return brochureModel.save(
                    {id: institutionId, brochureId: brochureSubmit.id},
                    {
                        name: brochureSubmit.name,
                        url: brochureSubmit.url,
                        orderType: brochureSubmit.orderType,
                        assignedTo: brochureSubmit.assignedTo
                    }
                ).$promise.then(function (data) {
                    if (constants.dev) {
                        $log.log('success, got data: ', data);
                    }
                    return data.insertedId;
                }, function (error) {
                    //@todo log error
                    if (constants.dev) {
                        $log.log('request failed' + error);
                    }
                    return false;
                });
            }
        };

        /**
         * Save brochure order.
         *
         * @param {string} id - Institution TU profile mongo ID
         * @param {Object} brochures
         * @returns {Promise}
         */
        service.saveOrder = function (id, brochures) {
            var institutionModel = service.getBrochuresOrderingModel();

            return institutionModel.update(
                {id: id},
                {'brochures': TuMediaVideosService.formatForOrdering(brochures)}
            ).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        /**
         * Delete brochure.
         *
         * @param institutionId
         * @param brochure
         * @returns {*}
         */
        service.deleteBrochure = function (institutionId, brochure) {
            service.setHasErrors(false);
            var brochureModel = service.getBrochureModel();
            return brochureModel.delete(
                {id: institutionId, brochureId: brochure.id}
            ).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        /**
         * Set selected brochure.
         *
         * @param item
         */
        service.setSelectedBrochure = function (item) {
            service.selectedBrochure = item;
        };

        service.replaceByKey = function (key) {
            angular.forEach(service.getBrochureItems(), function(itemValue, itemKey) {
                if(itemValue.id === key) {
                    service.getBrochureItems()[itemKey] = service.getSelectedBrochure();
                }
            });
        };

        /**
         * Get selected brochure
         *
         * @returns {*}
         */
        service.getSelectedBrochure = function () {
            return service.selectedBrochure;
        };

        /**
         * Trigger filter.
         *
         * @param triggerChange
         */
        service.setTriggerChange = function (triggerChange) {
            service.triggerChange = triggerChange;
        };

        /**
         * Get trigger filter value
         *
         * @returns {*}
         */
        service.getTriggerChange = function () {
            return service.triggerChange;
        };

        /**
         * Trigger filter.
         *
         * @param triggerChange
         */
        service.setTriggerReset = function (triggerChange) {
            service.triggerChange = triggerChange;
        };

        /**
         * Get trigger filter value
         *
         * @returns {*}
         */
        service.getTriggerReset = function () {
            return service.triggerChange;
        };

        /**
         * Set brochure items.
         *
         * @param items
         * @param transform
         */
        service.setBrochureItems = function (items, transform) {
            if (!angular.isDefined(transform)) {
                transform = true;
            }
            if (transform) {
                var typeMaster = TuMediaService.typeOverview(),
                    typeUg = TuMediaService.typeUndergraduate(),
                    typePg = TuMediaService.typePostgraduate();

                angular.forEach(items, function (value, key) {
                    items[key][typeMaster] = false;
                    items[key][typeUg] = false;
                    items[key][typePg] = false;
                    angular.forEach(items[key].assignedTo, function (aValue) {
                        if (aValue === typeMaster) {
                            items[key][typeMaster] = true;
                        } else if (aValue === typeUg) {
                            items[key][typeUg] = true;
                        } else if (aValue === typePg) {
                            items[key][typePg] = true;
                        }
                    });
                    /**
                     * Set initial order
                     * @type {{master: number, ug: number, pg: number}}
                     */
                    if (items[key].orderType === null) {
                        items[key].orderType = {
                            'master': 0,
                            'ug': 0,
                            'pg': 0
                        };
                    }
                    var orderOverview = 0;
                    var orderUndergraduate = 0;
                    var orderPostgraduate = 0;
                    if (angular.isDefined(items[key].orderType)) {
                        if (angular.isDefined(items[key].orderType[typeMaster])) {
                            orderOverview = items[key].orderType[typeMaster];
                        }
                        if (angular.isDefined(items[key].orderType[typeUg])) {
                            orderUndergraduate = items[key].orderType[typeUg];
                        }
                        if (angular.isDefined(items[key].orderType[typePg])) {
                            orderPostgraduate = items[key].orderType[typePg];
                        }
                    }
                    items[key].orderType = {
                        'master': orderOverview,
                        'ug': orderUndergraduate,
                        'pg': orderPostgraduate
                    };
                    if (angular.isDefined(items[key]._id)) {
                        items[key].id = items[key]._id;
                    }
                    delete items[key].assignedTo;
                    delete items[key]._id;
                });
            }

            service.brochureItems = items;
        };

        /**
         * Get brochure items.
         *
         * @returns {*}
         */
        service.getBrochureItems = function () {
            return service.brochureItems;
        };

        /**
         * Get brochure item.
         */
        service.getItemBrochure = function () {
            var orderTypes = {};
            orderTypes[TuMediaService.typeOverview()] = 0;
            orderTypes[TuMediaService.typeUndergraduate()] = 0;
            orderTypes[TuMediaService.typePostgraduate()] = 0;
            return {
                'id': '',
                'name': '',
                'description': '',
                'master': true,
                'ug': true,
                'pg': true,
                'orderType': orderTypes,
                'url': '',
                'imageUrl': null
            };
        };

        /**
         * Reset brochure item.
         *
         * @param item
         * @returns {*}
         */
        service.resetItem = function (item) {
            item.master = true;
            item.ug = true;
            item.pg = true;
            item.url = '';
            item.name = '';
            item.imageUrl = null;

            return item;
        };

        /**
         * Format brochures.
         *
         * @param brochures
         * @returns {*}
         */
        service.formatBrochures = function (brochures) {
            angular.forEach(brochures, function (value, key) {
                brochures[key] = service.formatBrochure(value);
            });

            return brochures;
        };

        /**
         * Format to match db data.
         *
         * @param {Object} brochure
         * @returns {Object}
         */
        service.formatBrochure = function (brochure) {
            brochure.assignedTo = [];
            if (brochure.master) {
                brochure.assignedTo.push('master');
            }
            if (brochure.ug) {
                brochure.assignedTo.push('ug');
            }
            if (brochure.pg) {
                brochure.assignedTo.push('pg');
            }
            /**
             * Remove data we don't want to send.
             */
            if (angular.isDefined(brochure.id) && parseInt(brochure.id, 10) === 0) {
                delete brochure.id;
            }
            delete brochure.master;
            delete brochure.ug;
            delete brochure.pg;
            delete brochure.order;

            return brochure;
        };

        /**
         * Sets has errors flag.
         *
         * @param {boolean} hasErrors
         */
        service.setHasErrors = function (hasErrors) {
            service.hasErrors = hasErrors;
        };

        /**
         * Gets has errors flag.
         *
         * @returns {boolean|*}
         */
        service.getHasErrors = function () {
            return service.hasErrors;
        };

        /**
         * Sets is highlighted.
         *
         * @param {boolean} isHighlighted
         */
        service.setHighlighted = function (isHighlighted) {
            service.isHighlighted = isHighlighted;
        };

        /**
         * Gets is Highlighted.
         *
         * @returns {boolean|*}
         */
        service.getHighlighted = function () {
            return service.isHighlighted;
        };

        service.coreId = function (coreId) {
            service.coreId = coreId;
        };

        /**
         * Set type.
         *
         * @returns {*}
         */
        service.setType = function (type) {
            service.type = type;
        };

        /**
         * Get type.
         *
         * @returns {*}
         */
        service.getType = function () {
            return service.type;
        };

        return service;
    };

    angular
        .module('qsHub')
        .service('TuMediaBrochuresService', [
            '$resource',
            '$q',
            '$log',
            'constants',
            'TuMediaService',
            'TuMediaVideosService',
            App.services.TuMediaBrochuresService
        ]);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers: {}});

    App.controllers.TuMediaSocialMedias = function (
        $scope,
        constants,
        TuProfileFactory,
        TuMediaSocialMediasService,
        TuMediaService,
        WatchService
    ) {
        var controller = this;
        controller.devMode = constants.dev;
        TuMediaSocialMediasService.coreId = 0;
        $scope.socialMediaItems = TuMediaSocialMediasService.socialMediaItems();
        $scope.itemSocialMedia = {};
        $scope.displayLocationDeletePopup = true;
        $scope.type = $scope.socialMediaItems[0];

        TuMediaSocialMediasService.setType($scope.type);
        WatchService.create($scope, function () {
            return TuProfileFactory.getData();
        }, function (newValue) {
            if (newValue !== null) {
                TuMediaSocialMediasService.coreId = newValue.id;
                $scope.socialMediaItems = TuMediaSocialMediasService.processInput(newValue.socialMedia);
                TuMediaSocialMediasService.setSelectedSocialMedia(TuMediaSocialMediasService.flattenArray(newValue.socialMedia));
            }
        });

        WatchService.create($scope, TuMediaSocialMediasService.getSocialMediaItems, function (newValue, oldValue) {
            if (!angular.equals(oldValue, newValue)) {
                $scope.socialMediaItems = newValue;
            }
        });

        WatchService.create($scope, TuMediaSocialMediasService.getChanges, function (newValue) {
            if (angular.isDefined(newValue)) {
                $scope.socialMediaItems[$scope.type] = TuMediaSocialMediasService.flattenArray(newValue, true);
            }
        });

        /**
         * Filter change.
         *
         * @param type
         */
        $scope.filter = function (type) {
            return type;
        };

        /**
         * Select social media.
         *
         * @param {string} type
         */
        $scope.selectSocialMedia = function (type) {
            $scope.type = type;
            TuMediaSocialMediasService.setType(type);
            TuMediaSocialMediasService.setTriggerChange(Math.random()); // reset right column changes
            if (!($scope.socialMediaItems[$scope.type].master || $scope.socialMediaItems[$scope.type].ug || $scope.socialMediaItems[$scope.type].pg)) {
                TuMediaSocialMediasService.setHighlighted(true);
            }
        };

        $scope.selectedItem = function () {
            return TuMediaSocialMediasService.getSelectedSocialMedia();
        };

        $scope.typeOverview = function () {
            return TuMediaService.typeOverview();
        };

        $scope.typeUndergraduate = function () {
            return TuMediaService.typeUndergraduate();
        };

        $scope.typePostgraduate = function () {
            return TuMediaService.typePostgraduate();
        };
    };

    angular
        .module('qsHub')
        .controller('TuMediaSocialMediasController', [
            '$scope',
            'constants',
            'TuProfileFactory',
            'TuMediaSocialMediasService',
            'TuMediaService',
            'WatchService',
            App.controllers.TuMediaSocialMedias
        ]);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { controllers: {} });

    App.controllers.TuMediaSocialMediasSidebar = function (
        $scope,
        $timeout,
        NotifierFactory,
        TuProfileFactory,
        TuMediaSocialMediasService,
        WatchService,
        UrlService,
        InstitutionFactory,
        TuProfileService
    ) {
        var controller = this,
            alreadyInitialised = false;
        $scope.hasErrors = false;
        $scope.invalidCheckboxes = [];
        $scope.invalidFields = [];
        TuMediaSocialMediasService.displayTypesValid = false;

        controller.onKeyUp = function ($event, type, index) {
            if ($event.keyCode === 13) {
                return;
            }

            var url = $event.target.value;
            if (url && url.length > 5 && (url.indexOf('http') !== 0 || url.indexOf('http') === -1)) {
                url = UrlService.prependHttp(url);
                $scope.selectedSocialMedia[type][index].url = url;
            }
        };

        /**
         * Reset Invalid Url
         *
         * @params {Integer} index
         */
        $scope.resetInvalidField = function (index) {
            $scope.invalidFields[index] = false;
        };

        /**
         * Reset Invalid Checkboxes
         *
         * @params {Integer} index
         */
        $scope.resetInvalidCheckbox = function (index) {
            if ($scope.invalidCheckboxes[index]) {
                $scope.invalidCheckboxes[index] = false;
            }
            // reset all URL fields validations
            $scope.invalidFields = [];
        };

        /**
         * Resets the
         */
        controller.resetIfEmpty = function () {
            if ($scope.selectedSocialMedia[$scope.type()][0].display &&
                !$scope.selectedSocialMedia[$scope.type()][0].master &&
                !$scope.selectedSocialMedia[$scope.type()][0].ug &&
                !$scope.selectedSocialMedia[$scope.type()][0].pg &&
                $scope.selectedSocialMedia[$scope.type()][1].display &&
                !$scope.selectedSocialMedia[$scope.type()][1].master &&
                !$scope.selectedSocialMedia[$scope.type()][1].ug &&
                !$scope.selectedSocialMedia[$scope.type()][1].pg &&
                $scope.selectedSocialMedia[$scope.type()][2].display &&
                !$scope.selectedSocialMedia[$scope.type()][2].master &&
                !$scope.selectedSocialMedia[$scope.type()][2].ug &&
                !$scope.selectedSocialMedia[$scope.type()][2].pg) {
                $scope.clearSocialMediaForm();
            }
        };


        controller.urlPattern = UrlService.getPattern();
        /**
         * Save form.
         *
         * @returns {boolean}
         */
        $scope.saveSocialMediaForm = function () {
            $scope.invalidFields = [];
            var invalidItems = TuMediaSocialMediasService.socialMediaValidation(angular.copy($scope.selectedSocialMedia[$scope.type()]));
            if (typeof invalidItems === 'undefined') {
                invalidItems = TuMediaSocialMediasService.socialMediaFormValidation($scope.forms.formSocialMedia);
            }
            TuMediaSocialMediasService.displayTypesValid = false;
            TuMediaSocialMediasService.setHasErrors(false);
            if ($scope.submitInProgress || !$scope.forms.formSocialMedia || (invalidItems && invalidItems.length > 0)
            ) {
                $scope.invalidCheckboxes = [];
                $scope.hasErrors = true;
                angular.forEach(invalidItems, function (item) {
                    if (item.invalid) {
                        $scope.invalidCheckboxes[item.index] = item.invalid || false;
                    } else if (item.invalidUrl) {
                        $scope.invalidFields[item.index] = item.invalidUrl || false;
                    }
                });
                return false;
            }
            var socialMediaItem = TuMediaSocialMediasService.formatSocialMedia(
                angular.copy($scope.selectedSocialMedia[$scope.type()])
            );
            $scope.hasErrors = false;
            $scope.invalidFields = [];
            $scope.invalidCheckboxes = [];
            $scope.submitInProgress = true;
            var actionName = $scope.isEditMode($scope.type()) ? 'Updated' : 'Added';
            TuMediaSocialMediasService.saveSocialMedia(TuMediaSocialMediasService.coreId, socialMediaItem)
                .then(function (success) {
                    $scope.submitInProgress = false;
                    NotifierFactory.show(
                        success ? 'success' : 'error',
                        success ? 'Successfully!' : 'Failure!',
                        'Social Media ' + actionName + ' (' + $scope.type() + ')'
                    );
                    if (success) {
                        TuProfileService.cleanCacheKey(InstitutionFactory.getCoreId());
                        controller.resetIfEmpty();
                        $scope.selectedSocialMediaBeforeChanges = angular.copy($scope.selectedSocialMedia);
                        TuMediaSocialMediasService.setChanges(socialMediaItem);
                    }
                });
        };

        /**
         * Clear social media item.
         */
        $scope.clearSocialMediaForm = function () {
            var setValue = false;
            var setUrl = '';
            angular.forEach($scope.selectedSocialMedia[$scope.type()], function (value, key) {
                setValue = key === 0;
                $scope.selectedSocialMedia[$scope.type()][key].master = setValue;
                $scope.selectedSocialMedia[$scope.type()][key].ug = setValue;
                $scope.selectedSocialMedia[$scope.type()][key].pg = setValue;
                $scope.selectedSocialMedia[$scope.type()][key].url = setUrl;
            });
            $scope.invalidCheckboxes = [];
            $scope.invalidFields = [];
            $scope.checkCheckboxes();
        };

        /**
         * Get selected item.
         *
         * @returns {*}
         */
        $scope.getSelectedSocialMedia = function () {
            return TuMediaSocialMediasService.getSelectedSocialMedia();
        };

        /**
         * Get socialMedia items.
         *
         * @returns {*}
         */
        $scope.getSocialMediaItems = function () {
            return TuMediaSocialMediasService.getSocialMediaItems();
        };

        /**
         * Get has errors flag.
         *
         * @returns {boolean}
         */
        $scope.getHasErrors = function () {
            return TuMediaSocialMediasService.getHasErrors();
        };

        $scope.setHasErrors = function (value) {
            TuMediaSocialMediasService.setHasErrors(value);
        };

        $scope.type = function () {
            return TuMediaSocialMediasService.getType();
        };

        $scope.setDisplayTypesValidation = function (value) {
            TuMediaSocialMediasService.displayTypesValid = value;
        };

        $scope.getDisplayTypesValidation = function () {
            return TuMediaSocialMediasService.displayTypesValid;
        };

        /**
         * Check which items to hide
         */
        $scope.checkCheckboxes = function () {
            var checked = 0;
            var displayValue = true;
            var valueChanged = false;
            var prevValueChanged = false;
            angular.forEach($scope.selectedSocialMedia[$scope.type()], function (item, key) {
                if (item.master) {
                    checked++;
                    valueChanged = true;
                }
                if (item.ug) {
                    checked++;
                    valueChanged = true;
                }
                if (item.pg) {
                    checked++;
                    valueChanged = true;
                }
                if ((!valueChanged && !prevValueChanged && checked > 1) || (checked === 3 && !valueChanged)) {
                    displayValue = false;
                }
                $scope.selectedSocialMedia[$scope.type()][key].display = displayValue;
                if (!displayValue) {
                    delete $scope.invalidCheckboxes[key];
                    delete $scope.invalidFields[key];
                }
                prevValueChanged = valueChanged;
                valueChanged = false;
            });
        };

        $scope.checkboxChanged = function (selectedSocialMedia, index, itemChanged) {
            var checkedOnChanged = 0;
            if ($scope.selectedSocialMedia[$scope.type()][index].master === true) {
                checkedOnChanged++;
            }
            if ($scope.selectedSocialMedia[$scope.type()][index].ug === true) {
                checkedOnChanged++;
            }
            if ($scope.selectedSocialMedia[$scope.type()][index].pg === true) {
                checkedOnChanged++;
            }
            // if same URL assigned to all subtypes
            if (checkedOnChanged === 3) {
                // reset other URLs
                angular.forEach($scope.selectedSocialMedia[$scope.type()], function (value, key) {
                    if (key !== index) {
                        value.url = null;
                    }
                });
            }

            var uniqueItems = [];
            angular.forEach($scope.selectedSocialMedia[$scope.type()], function (totalItems, totalKey) {
                if ($scope.selectedSocialMedia[$scope.type()][totalKey].master && uniqueItems.indexOf('master') === -1) {
                    uniqueItems.push('master');
                }
                if ($scope.selectedSocialMedia[$scope.type()][totalKey].ug && uniqueItems.indexOf('ug') === -1) {
                    uniqueItems.push('ug');
                }
                if ($scope.selectedSocialMedia[$scope.type()][totalKey].pg && uniqueItems.indexOf('pg') === -1) {
                    uniqueItems.push('pg');
                }
            });
            var totalChecked = uniqueItems.length;

            var visibleCount = 0;
            angular.forEach($scope.selectedSocialMedia[$scope.type()], function (totalItems, totalKey) {
                if ($scope.selectedSocialMedia[$scope.type()][totalKey].display) {
                    visibleCount++;
                }
            });

            angular.forEach($scope.selectedSocialMedia[$scope.type()], function (items, key) {
                if (key !== index) {
                    if ($scope.selectedSocialMedia[$scope.type()][index][itemChanged] && $scope.selectedSocialMedia[$scope.type()][key][itemChanged]) {
                        if (!($scope.selectedSocialMedia[$scope.type()][key].master ||
                            $scope.selectedSocialMedia[$scope.type()][key].ug ||
                            $scope.selectedSocialMedia[$scope.type()][key].pg
                        )
                        ) {
                            $scope.selectedSocialMedia[$scope.type()][key].display = true;
                        }
                    }
                    if ($scope.selectedSocialMedia[$scope.type()][index][itemChanged]) { // checked
                        if ($scope.selectedSocialMedia[$scope.type()][key][itemChanged]) { // if there's another key with itemChanged changed, set it to false
                            $scope.selectedSocialMedia[$scope.type()][key][itemChanged] = !$scope.selectedSocialMedia[$scope.type()][index][itemChanged];
                            if (visibleCount === 3 && ((totalChecked < 2) || totalChecked === visibleCount)) {
                                $scope.selectedSocialMedia[$scope.type()][key].display = false;
                                delete $scope.invalidCheckboxes[key];
                                delete $scope.invalidFields[key];
                            }
                        } else { // hide the ones based on number of checked items
                            if (checkedOnChanged === totalChecked) { // all checked items are on current record
                                if (checkedOnChanged > 1 && checkedOnChanged < visibleCount) {
                                    $scope.selectedSocialMedia[$scope.type()][key].display = false;
                                    delete $scope.invalidCheckboxes[key];
                                    delete $scope.invalidFields[key];
                                    visibleCount--;
                                } else if (checkedOnChanged === 1) {
                                    angular.forEach($scope.selectedSocialMedia[$scope.type()], function (totalItems, totalKey) {
                                        $scope.selectedSocialMedia[$scope.type()][totalKey].display = true;
                                    });
                                }
                            } else { // some are not on current.
                                angular.forEach($scope.selectedSocialMedia[$scope.type()], function (totalItems, totalKey) {
                                    if (totalKey !== index) {
                                        if ((totalChecked === visibleCount || totalChecked < visibleCount) || totalChecked === 3) {
                                            if (!($scope.selectedSocialMedia[$scope.type()][totalKey].master ||
                                                $scope.selectedSocialMedia[$scope.type()][totalKey].ug ||
                                                $scope.selectedSocialMedia[$scope.type()][totalKey].pg
                                            )
                                            ) {
                                                $scope.selectedSocialMedia[$scope.type()][totalKey].display = false;
                                                delete $scope.invalidCheckboxes[key];
                                                delete $scope.invalidFields[key];
                                                visibleCount--;
                                            }
                                            if (totalChecked === 2 && checkedOnChanged !== totalChecked) {
                                                $scope.selectedSocialMedia[$scope.type()][totalKey].display = true;
                                            }
                                        }
                                    }
                                });
                            }
                        }
                        if (checkedOnChanged === 3) {
                            $scope.selectedSocialMedia[$scope.type()][key].display = false;
                            delete $scope.invalidCheckboxes[key];
                            delete $scope.invalidFields[key];
                        }
                    } else { // unchecked
                        if (totalChecked === 0) {
                            angular.forEach($scope.selectedSocialMedia[$scope.type()], function (totalItems, totalKey) {
                                $scope.selectedSocialMedia[$scope.type()][totalKey].display = true;
                            });
                        }
                        if (totalChecked === 1) {
                            angular.forEach($scope.selectedSocialMedia[$scope.type()], function (totalItems, totalKey) {
                                $scope.selectedSocialMedia[$scope.type()][totalKey].display = true;
                            });
                        }
                        if (totalChecked === 2) {
                            if (checkedOnChanged === totalChecked && checkedOnChanged > visibleCount) {
                                angular.forEach($scope.selectedSocialMedia[$scope.type()], function (totalItems, totalKey) {
                                    if (totalKey !== index && checkedOnChanged > visibleCount) {
                                        $scope.selectedSocialMedia[$scope.type()][totalKey].display = true;
                                        visibleCount++;
                                    }
                                });
                            } else {
                                if (checkedOnChanged !== totalChecked && totalChecked === 2 && visibleCount < 3 && checkedOnChanged > 0) {
                                    angular.forEach($scope.selectedSocialMedia[$scope.type()], function (totalItems, totalKey) {
                                        $scope.selectedSocialMedia[$scope.type()][totalKey].display = true;
                                        visibleCount++;
                                    });
                                }
                            }
                        }
                    }
                }
            });
        };

        /**
         * Is edit mode?
         *
         * @returns {boolean}
         */
        $scope.isEditMode = function (type) {
            return $scope.selectedSocialMedia && $scope.selectedSocialMedia[type] ? true : false;
        };

        controller.initWatches = function () {
            WatchService.create($scope, TuMediaSocialMediasService.getSelectedSocialMedia, function (selectedSocialMedia) {
                if (selectedSocialMedia) {
                    $scope.selectedSocialMediaBeforeChanges = selectedSocialMedia;
                    $scope.selectedSocialMedia = angular.copy(selectedSocialMedia);
                    $scope.checkCheckboxes();
                }
            });

            WatchService.create($scope, TuMediaSocialMediasService.getTriggerChange, function () {
                if (angular.isDefined($scope.selectedSocialMediaBeforeChanges)) {
                    $scope.selectedSocialMedia = angular.copy($scope.selectedSocialMediaBeforeChanges);
                    $scope.checkCheckboxes();
                }
                $scope.invalidFields = [];
                $scope.invalidCheckboxes = [];
            });

            //watch for social media type changes
            WatchService.create($scope, TuMediaSocialMediasService.getType, function (socialMediaType) {
                if (socialMediaType) {
                    controller.selectedSocialMediaUrl = TuMediaSocialMediasService.getSelectedSocialMediaUrl(socialMediaType);
                }
            });

            WatchService.create($scope, TuMediaSocialMediasService.getHighlighted, function (newValue) {
                if (newValue) {
                    $scope.isHighlighted = newValue;
                    $timeout(function () {
                        TuMediaSocialMediasService.setHighlighted(false);
                        $scope.isHighlighted = false;
                    }, 200);
                }
            });

            WatchService.create($scope, TuProfileFactory.getSelectedMediaTabId, function (tabId) {
                if (tabId === 3) {
                    // restore data on tab type switch
                    if (typeof $scope.selectedSocialMediaBeforeChanges !== 'undefined') {
                        $scope.selectedSocialMedia = angular.copy($scope.selectedSocialMediaBeforeChanges);
                    }
                    $timeout(function () {
                        TuMediaSocialMediasService.setHighlighted(true);
                    }, 200);
                } else {
                    $scope.invalidFields = [];
                    $scope.invalidCheckboxes = [];
                }
            });
        };

        controller.init = function () {
            controller.initWatches();
        };

        // listen to departments tab visibility changes
        WatchService.create($scope, TuProfileFactory.isMediaTabSelected, function (isActive) {
            // if not already initialized and tab is active
            if (!alreadyInitialised && isActive) {
                controller.init();
                alreadyInitialised = true;
            }
        });
    };

    angular
        .module('qsHub')
        .controller('TuMediaSocialMediasSidebarController', [
            '$scope',
            '$timeout',
            'NotifierFactory',
            'TuProfileFactory',
            'TuMediaSocialMediasService',
            'WatchService',
            'UrlService',
            'InstitutionFactory',
            'TuProfileService',
            App.controllers.TuMediaSocialMediasSidebar
        ]);

} (window.angular));
(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { services: {} });

    App.services.TuMediaSocialMediasService = function ($resource, $q, $log, constants, TuMediaService, UrlService) {
        var service = {};
        var imageUrl = '/images/media/media-social-media.png';
        var defaultName = '';
        var socialMediaItems = [
            'facebook',
            'youtube',
            'linkedin',
            'twitter',
            'instagram',
            'wechat',
            'sinaweibo',
            'other'
        ];

        service.getSocialMediaModel = function () {
            return $resource(constants.api.institutions.url + '/v1/tu-profile/:id/social-media/:socialMediaId', null, {
                update: { method: 'PATCH' }
            });
        };

        service.getInstitutionModel = function () {
            return $resource(constants.api.institutions.url + '/v1/tu-profile/:id', null, {
                update: { method: 'PATCH' }
            });
        };

        /**
         * Save SocialMedia
         *
         * @param institutionId
         * @param socialMedia
         * @returns {*}
         */
        service.saveSocialMedia = function (institutionId, socialMedia) {
            var socialMediaModel = service.getSocialMediaModel();

            return socialMediaModel.update(
                { id: institutionId, socialMediaId: service.getType() },
                socialMedia
            ).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        /**
         * Set selected socialMedia.
         *
         * @param {Object} item
         */
        service.setSelectedSocialMedia = function (item) {
            service.selectedSocialMedia = item;
        };

        /**
         * Get selected socialMedia
         *
         * @returns {Object}
         */
        service.getSelectedSocialMedia = function () {
            return service.selectedSocialMedia;
        };

        /**
         * Trigger change.
         *
         * @param triggerChange
         */
        service.setTriggerChange = function (triggerChange) {
            service.triggerChange = triggerChange;
        };

        /**
         * Get trigger change value
         *
         * @returns {boolean}
         */
        service.getTriggerChange = function () {
            return service.triggerChange;
        };

        /**
         * Get socialMedia items.
         *
         * @returns {Object}
         */
        service.getSocialMediaItems = function () {
            return service.socialMediaItems;
        };

        service.setSocialMediaItems = function (items) {
            service.socialMediaItems = items;
        };

        /**
         * Get socialMedia item.
         */
        service.getItemSocialMedia = function () {
            var orderTypes = {};
            orderTypes[TuMediaService.typeOverview()] = 0;
            orderTypes[TuMediaService.typeUndergraduate()] = 0;
            orderTypes[TuMediaService.typePostgraduate()] = 0;
            return {
                'id': '',
                'name': defaultName,
                'description': '',
                'master': true,
                'ug': true,
                'pg': true,
                'orderType': orderTypes,
                'url': '',
                'imageUrl': imageUrl
            };
        };

        /**
         * get selected social media url.
         *
         * @param {String} type
         * @returns {String}
         */
        service.getSelectedSocialMediaUrl = function (type) {
            switch (type) {
                case 'facebook':
                    return 'https://www.facebook.com/universityrankings';
                case 'youtube':
                    return 'https://www.youtube.com/topuniversities';
                case 'linkedin':
                    return 'https://www.linkedin.com/company/qs';
                case 'twitter':
                    return 'https://twitter.com/worlduniranking';
                case 'wechat':
                    return 'https://www.wechat.com/worlduniranking';
                case 'sinaweibo':
                    return 'https://www.weibo.com/worlduniranking';
                default:
                    return 'http://www.yourwebsite.com';
            }
        };

        /**
         * Reset socialMedia item.
         *
         * @returns {Object}
         * @param item
         */
        service.resetItem = function (item) {
            item.master = true;
            item.ug = true;
            item.pg = true;
            item.url = '';
            item.name = defaultName;
            item.imageUrl = imageUrl;

            return item;
        };

        /**
         * Format socialMedias.
         *
         * @param socialMedias
         * @returns {Object}
         */
        service.formatSocialMedias = function (socialMedias) {
            angular.forEach(socialMedias, function (value, key) {
                socialMedias[key] = service.formatSocialMedia(value);
            });

            return socialMedias;
        };

        /**
         * Validate Social Media data
         *
         * @param {Object} socialMediaItem
         * @returns {Object}
         */
        service.socialMediaValidation = function (socialMediaItem) {
            var invalidItems = [];
            // check if items reset
            if (socialMediaItem &&
                socialMediaItem.length === 3 &&
                socialMediaItem[0].display === true &&
                socialMediaItem[0].master === true &&
                socialMediaItem[0].ug === true &&
                socialMediaItem[0].pg === true &&
                (socialMediaItem[0].url &&
                !socialMediaItem[0].url.length)
            ) {
                return invalidItems;
            }

            var checkboxesChecked = 0;
            angular.forEach(socialMediaItem, function (item) {
                if (item.master) {
                    checkboxesChecked++;
                }
                if (item.ug) {
                    checkboxesChecked++;
                }
                if (item.pg) {
                    checkboxesChecked++;
                }
            });
            angular.forEach(socialMediaItem, function (item, key) {
                if ((item.url !== "") && !(item.master || item.pg || item.ug) && (checkboxesChecked === 3)) {
                    socialMediaItem[key].url = "";
                } else if ((item.url || item.url === undefined) && !(item.master || item.pg || item.ug) && (checkboxesChecked !== 3)) {
                    invalidItems.push({
                        invalid: true,
                        index: key
                    });
                } else if (!item.url  && (item.master || item.pg || item.ug)) {
                    invalidItems.push({
                        invalidUrl: true,
                        index: key
                    });
                }
                else if ((item.url && !(item.url.match(UrlService.getPattern()))) && (item.master || item.pg || item.ug)){
                    invalidItems.push({
                        invalidUrl: true,
                        index: key
                    });
                }
            });
            if (invalidItems.length > 0) {
                return invalidItems;
            }
        };

        service.socialMediaFormValidation = function (socialMediaItem) {
            var invalidItems = [];
            if (angular.isDefined(socialMediaItem)) {
                angular.forEach(socialMediaItem.$error.url, function (error) {
                    var key = error.$name.charAt(error.$name.length - 1);
                    invalidItems.push({
                        invalidUrl: true,
                        index: key
                    });
                });
            }
            return invalidItems;
        };

        /**
         * Format to match db data.
         *
         * @param {Object} socialMediaItem
         * @returns {Object}
         */
        service.formatSocialMedia = function (socialMediaItem) {
            var output = [];

            angular.forEach(socialMediaItem, function (item, key) {
                if ((item.master || item.pg || item.ug) && item.url !== "") { // item is set to profile, we'll save it
                    var tmpOutput = {};
                    tmpOutput.url = item.url;
                    tmpOutput.assignedTo = [];
                    if (item.master) {
                        tmpOutput.assignedTo.push('master');
                    }
                    if (item.ug) {
                        tmpOutput.assignedTo.push('ug');
                    }
                    if (item.pg) {
                        tmpOutput.assignedTo.push('pg');
                    }
                    output.push(tmpOutput);
                }
            });
            return output;
        };

        service.setChanges = function (changes) {
            service.changes = changes;
        };

        service.getChanges = function () {
            return service.changes;
        };

        /**
         * Sets has errors flag.
         *
         * @param {boolean} hasErrors
         */
        service.setHasErrors = function (hasErrors) {
            service.hasErrors = hasErrors;
        };

        /**
         * Gets has errors flag.
         *
         * @returns {boolean|*}
         */
        service.getHasErrors = function () {
            return service.hasErrors;
        };

        /**
         * Sets is highlighted.
         *
         * @param {boolean} isHighlighted
         */
        service.setHighlighted = function (isHighlighted) {
            service.isHighlighted = isHighlighted;
        };

        /**
         * Gets is Highlighted.
         *
         * @returns {boolean|*}
         */
        service.getHighlighted = function () {
            return service.isHighlighted;
        };

        service.coreId = function (coreId) {
            service.coreId = coreId;
        };

        /**
         * Set type.
         *
         * @returns {*}
         */
        service.setType = function (type) {
            service.type = type;
        };

        /**
         * Get type.
         *
         * @returns {*}
         */
        service.getType = function () {
            return service.type;
        };

        service.socialMediaItems = function () {
            return socialMediaItems;
        };

        service.getSocialMediaObject = function () {
            var socialMedia = {};
            angular.forEach(socialMediaItems, function (mediaType) {
                socialMedia[mediaType] = {};
                angular.forEach(TuMediaService.getTypes(), function (profileType) {
                    socialMedia[mediaType][profileType] = false;
                });
            });

            return socialMedia;
        };

        service.assignData = function (inputData, socialMedia) {
            angular.forEach(inputData, function (mediaType, mediaTypeValue) {
                angular.forEach(mediaType, function (objectData) {
                    if (angular.isDefined(objectData.assignedTo)) {
                        angular.forEach(objectData.assignedTo, function (assignedType) {
                            socialMedia[mediaTypeValue][assignedType] = true;
                        });
                    }
                });
            });

            return socialMedia;
        };

        service.processInput = function (inputData) {
            var socialMedia = service.getSocialMediaObject();
            socialMedia = service.assignData(inputData, socialMedia);

            return socialMedia;
        };

        /**
         * Flattens inputData for left column
         *
         * @param {Object} inputData
         * @param {boolean} forType
         * @returns {*}
         */
        service.flattenArray = function (inputData, forType) {
            if (angular.isUndefined(forType)) {
                forType = false;
            }
            var typeUg = TuMediaService.typeUndergraduate(),
                typePg = TuMediaService.typePostgraduate(),
                typeMaster = TuMediaService.typeOverview();

            if (forType) {
                var outputData = {};
                outputData[typeUg] = false;
                outputData[typePg] = false;
                outputData[typeMaster] = false;

                angular.forEach(inputData, function (inputDataValue, inputDataKey) {
                    if (angular.isDefined(inputDataValue.assignedTo)) {
                        angular.forEach(inputData[inputDataKey].assignedTo, function (assignedType) {
                            if (assignedType === typeMaster) {
                                outputData[typeMaster] = true;
                            } else if (assignedType === typeUg) {
                                outputData[typeUg] = true;
                            } else if (assignedType === typePg) {
                                outputData[typePg] = true;
                            }
                        });
                    }
                });

                inputData = outputData;
            } else {
                angular.forEach(socialMediaItems, function (mediaType) {
                    if (angular.isDefined(inputData) && angular.isDefined(inputData[mediaType])) {
                        angular.forEach(inputData[mediaType], function (objectData, dataKey) {
                            inputData[mediaType][dataKey][typeMaster] = false;
                            inputData[mediaType][dataKey][typeUg] = false;
                            inputData[mediaType][dataKey][typePg] = false;
                            inputData[mediaType][dataKey].display = true;
                            if (angular.isDefined(inputData[mediaType][dataKey].assignedTo)) {
                                angular.forEach(inputData[mediaType][dataKey].assignedTo, function (assignedType) {
                                    if (assignedType === typeMaster) {
                                        inputData[mediaType][dataKey][typeMaster] = true;
                                    } else if (assignedType === typeUg) {
                                        inputData[mediaType][dataKey][typeUg] = true;
                                    } else if (assignedType === typePg) {
                                        inputData[mediaType][dataKey][typePg] = true;
                                    }
                                });
                                delete (inputData[mediaType][dataKey].assignedTo);
                            }
                        });
                        if (inputData[mediaType].length === 0) {
                            inputData[mediaType][0] = service.getEmpty(true, true);
                            inputData[mediaType][1] = service.getEmpty(false, false);
                            inputData[mediaType][2] = service.getEmpty(false, false);
                        }
                        if (inputData[mediaType].length === 1) {
                            inputData[mediaType][1] = service.getEmpty(false, true);
                            inputData[mediaType][2] = service.getEmpty(false, false);
                        }
                        if (inputData[mediaType].length === 2) {
                            inputData[mediaType][2] = service.getEmpty(false, true);
                        }
                    } else {
                        if (angular.isUndefined(inputData) || angular.isArray(inputData)) {
                            inputData = {};
                        }
                        inputData[mediaType] = [];
                        inputData[mediaType].push(service.getEmpty(true, true));
                        inputData[mediaType].push(service.getEmpty(false, false));
                        inputData[mediaType].push(service.getEmpty(false, false));
                    }
                });
            }

            return inputData;
        };

        /**
         *
         * @param {boolean} initialValue
         * @param {boolean} displayValue
         * @returns {{url: string, display: boolean, master: boolean, ug: boolean, pg: boolean}}
         */
        service.getEmpty = function (initialValue, displayValue) {
            return {
                'url': '',
                'display': displayValue,
                'master': initialValue,
                'ug': initialValue,
                'pg': initialValue
            };
        };

        service.resetDataForType = function (data, type) {
            angular.forEach(data, function (dataValue, dataKey) {
                if (dataKey === type) {
                    angular.forEach(data[dataKey], function (dataSubValue, dataSubKey) {
                        data[dataKey][dataSubKey].master = false;
                        data[dataKey][dataSubKey].ug = false;
                        data[dataKey][dataSubKey].pg = false;
                        data[dataKey][dataSubKey].url = '';
                    });
                }
            });

            return data;
        };

        service.assignNewData = function (values, type, currentlySelected) {
            var outputValues = angular.copy(values);
            angular.forEach(values, function (objectData, valueKey) {
                if (angular.isDefined(objectData.assignedTo)) {
                    outputValues[valueKey][TuMediaService.typeOverview()] = false;
                    outputValues[valueKey][TuMediaService.typeUndergraduate()] = false;
                    outputValues[valueKey][TuMediaService.typePostgraduate()] = false;
                    angular.forEach(objectData.assignedTo, function (assignedType) {
                        outputValues[valueKey][assignedType] = true;
                    });
                    delete (outputValues[valueKey].assignedTo);
                }
            });
            if (outputValues.length === 1) {
                outputValues[1] = service.getEmpty(false, true);
                outputValues[2] = service.getEmpty(false, false);
            }
            if (outputValues.length === 2) {
                outputValues[2] = service.getEmpty(false, true);
            }
            var copySelected = angular.copy(currentlySelected);
            angular.forEach(copySelected, function (mediaType, mediaTypeKey) {
                if (mediaTypeKey === type) {
                    copySelected[mediaTypeKey] = outputValues;
                }
            });

            return copySelected;
        };

        service.applyUpdate = function (type, values) {
            var currentlySelected = service.getSelectedSocialMedia();
            var assignedData = service.assignNewData(values, type, currentlySelected);

            return assignedData;
        };

        return service;
    };

    angular
        .module('qsHub')
        .service('TuMediaSocialMediasService', [
            '$resource',
            '$q',
            '$log',
            'constants',
            'TuMediaService',
            'UrlService',
            App.services.TuMediaSocialMediasService
        ]);

} (window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers: {}});

    App.controllers.TuMediaImages = function (
        $scope,
        constants,
        Lightbox,
        TuProfileFactory,
        NotifierFactory,
        TuMediaImagesService,
        TuMediaService,
        ModalService,
        $filter,
        orderBy,
        AuthenticationService,
        WatchService
    ) {
        var controller = this,
            profileId;
        controller.devMode = constants.dev;
        TuMediaImagesService.coreId = 0;
        $scope.imageItems = [];
        $scope.itemImage = {};
        $scope.displayLocationDeletePopup = true;
        $scope.type = TuMediaService.typeOverview();
        // assign defaults for uploadedImagesCount per type
        $scope.uploadedImagesCount = {};
        $scope.uploadedImagesCount[TuMediaService.typeOverview()] = 0;
        $scope.uploadedImagesCount[TuMediaService.typeUndergraduate()] = 0;
        $scope.uploadedImagesCount[TuMediaService.typePostgraduate()] = 0;
        // maximum images allowed per type, displayed in view
        controller.maxImages = TuMediaImagesService.getMaxImages();
        controller.dropZoneImageInstance = null;
        TuMediaImagesService.setType(TuMediaService.typeOverview());
        $scope.isLoaded = false;

        /**
         * Assign profile data
         */
        WatchService.create($scope, function () {
            return TuProfileFactory.getData();
        }, function (newValue) {
            if (newValue !== null && !angular.equals({}, angular.copy(newValue))) {
                angular.copy(newValue.images, $scope.imageItems);
                TuMediaImagesService.coreId = newValue.id;
                TuMediaImagesService.setImageItems($scope.imageItems);
                controller.recalculateImages();
                $scope.filteredImageItems = $filter('filter')($scope.imageItems, {master: true});
                $scope.filteredImageItems = orderBy($scope.filteredImageItems, 'orderType.' + $scope.type, false);
                // set default
                $scope.filter($scope.type, true);
                profileId = TuProfileFactory.getId();
                /**
                 * DropZone configuration.
                 */
                $scope.imageConfig = {
                    // http://www.dropzonejs.com/#configuration-options
                    dropzone: {
                        url: controller.getUploadUrl(),
                        maxFilesize: 0.4,
                        parallelUploads: 1,
                        autoProcessQueue: true,
                        addRemoveLinks: false,
                        previewsContainer: null,
                        clickable: '.upload-image',
                        init : controller.dropZoneImageInit,
                        acceptedFiles: 'image/jpeg,image/pjpeg,image/jpeg,image/pjpeg',
                        dictFileTooBig: "Uploaded Image is greater than the limit. Please upload less than 400KB.",
                        dictMaxFilesExceeded: "Profiles have reached the limit. Please delete an image and try again",
                        dictInvalidFileType: "Uploaded image has incorrect format. Please upload images in jpg, jpeg format.",
                        headers: AuthenticationService.getAuthorizationHeader()
                    },
                    // http://www.dropzonejs.com/#event-list
                    eventHandlers: {
                        success : controller.handleImageSuccess,
                        error : controller.handleImageError
                    }
                };
                $scope.isLoaded = true;
                // If the instance was previously initialized, and institution is switched, reassign url
                if (controller.dropZoneImageInstance) {
                    controller.dropZoneImageInstance.options.url = controller.getUploadUrl();
                }
            }
        });

        /**
         * Assign image items on change
         */
        WatchService.create($scope, TuMediaImagesService.getImageItems, function (newValue, oldValue) {
            if (!angular.equals(oldValue, newValue)) {
                $scope.imageItems = newValue;
                controller.recalculateImages();
            }
        });

        /**
         * Re-filter array on trigger call.
         */
        WatchService.create($scope, TuMediaImagesService.getTriggerChange, function () {
            if (!$scope.isLoaded) {
                return false;
            }
            if (angular.isDefined($scope.selectedItemId()) && $scope.selectedItemId().length > 0) {
                TuMediaImagesService.replaceByKey($scope.selectedItemId());
            }
            var selectedImage = TuMediaImagesService.getSelectedImage();
            if (
                ($scope.type === 'ug' && selectedImage.ug) || 
                (!selectedImage.master && selectedImage.ug && !selectedImage.pg) || 
                (!selectedImage.master && selectedImage.ug && selectedImage.pg && ($scope.type === 'ug' || $scope.type === 'master'))
            ) {
                $scope.filter('ug');
            } else if (($scope.type === 'pg' && selectedImage.pg) || (!selectedImage.master && !selectedImage.ug && selectedImage.pg)) {
                $scope.filter('pg');
            } else {
                $scope.filter('master');
            }
            var assignedOrder = 1;
            // change images order
            for (var index = 0; index < $scope.filteredImageItems.length; index++) {
                if ($scope.filteredImageItems[index][$scope.type]) {
                    $scope.filteredImageItems[index].orderType[$scope.type] = assignedOrder;
                    assignedOrder++;
                }
            }
            $scope.item = TuMediaImagesService.getItemImage(0);
        });

        /**
         * Sortable options.
         */
        $scope.sortableOptions = {
            disabled: false,
            'ui-floating': false,
            start: function () {
                controller.initialList = [];
                angular.copy($scope.filteredImageItems, controller.initialList);
            },
            update: function (e, ui) {
                var validateItems = TuMediaService.validateTypes($scope.imageItems);
                if (true !== validateItems) {
                    ui.item.sortable.cancel();
                }
            },
            stop: function () {
                var validateItems = TuMediaService.validateTypes($scope.imageItems);
                if (true === validateItems) {
                    var assignedOrder = 1;
                    // change images order
                    for (var index = 0; index < $scope.filteredImageItems.length; index++) {
                        if ($scope.filteredImageItems[index][$scope.type]) {
                            $scope.filteredImageItems[index].orderType[$scope.type] = assignedOrder;
                            assignedOrder++;
                        }
                    }

                    $scope.sortableOptions.disabled = false;
                    TuMediaImagesService.saveOrder(TuMediaImagesService.coreId, $scope.imageItems)
                        .then(function (success) {
                            NotifierFactory.show(
                                success ? 'success' : 'error',
                                success ? 'Success!' : 'Failure!',
                                'Image Order Save'
                            );
                            TuMediaImagesService.setSelectedImage(TuMediaImagesService.getItemImage());
                        })
                        .finally(function () {
                            $scope.sortableOptions.disabled = false;
                        });
                } else {
                    NotifierFactory.show(
                        'error',
                        'Failure!',
                        'Invalid items in image list'
                    );
                    TuMediaImagesService.setSelectedImage(validateItems);
                    TuMediaImagesService.displayIsValidType = false;

                    return false;
                }
            }
        };

        /**
         * Filter change.
         *
         * @param {string} type
         * @param {boolean} setDefault
         */
        $scope.filter = function (type, setDefault) {
            if (angular.isUndefined(setDefault)) {
                setDefault = false;
            }
            var validateItems = TuMediaService.validateTypes($scope.imageItems);
            if (true === validateItems) {
                $scope.type = type;
                var params = {};
                params[type] = true;
                controller.recalculateImages();
                $scope.filteredImageItems = $filter('filter')($scope.imageItems, params);
                $scope.filteredImageItems = orderBy($scope.filteredImageItems, 'orderType.' + type, false);
                if (setDefault) {
                    var selectedImage = TuMediaImagesService.getItemImage();
                    if ($scope.uploadedImagesCount[$scope.type] > 0) {
                        selectedImage = $scope.filteredImageItems[0];
                    }
                    TuMediaImagesService.setSelectedImage(selectedImage);
                }
            } else {
                NotifierFactory.show(
                    'error',
                    'Failure!',
                    'Invalid items in image list'
                );
                TuMediaImagesService.setSelectedImage(validateItems);
                TuMediaImagesService.displayIsValidType = false;
                controller.recalculateImages();

                return false;
            }
        };

        /**
         * Assigns selected image.
         *
         * @param {Object} item
         */
        $scope.selectImage = function (item) {
            if (angular.isDefined(item) && item.id !== 'temporary' && $scope.tuIsAdvanced) {
                var validateItems = TuMediaService.validateTypes($scope.imageItems);
                if (true === validateItems) {
                    TuMediaImagesService.setHasErrors(false);
                    TuMediaImagesService.displayIsValidType = false;
                    $scope.itemImage = TuMediaImagesService.getItemImage();
                    if (angular.isDefined(item)) {
                        TuMediaImagesService.setSelectedImage(item);
                    } else {
                        TuMediaImagesService.setHasErrors(true);
                        TuMediaImagesService.setSelectedImage($scope.itemImage);
                    }
                    TuMediaImagesService.setTriggerReset(Math.random());
                } else {
                    NotifierFactory.show(
                        'error',
                        'Failure!',
                        'Invalid items in image list'
                    );
                    TuMediaImagesService.setSelectedImage(validateItems);
                    TuMediaImagesService.displayIsValidType = false;

                    return false;
                }
            }
        };

        /**
         * Returns selected image.
         *
         * @returns {Object}
         */
        $scope.selectedItem = function () {
            return TuMediaImagesService.getSelectedImage();
        };

        /**
         * Delete image action handler.
         *
         * @param item
         */
        $scope.deleteImage = function (item) {
            if (angular.isDefined($scope.item)) {
                $scope.item.thumbnailUrl = null;
            }
            if (angular.isDefined(item) && item.id !== 'temporary') {
                var itemName = angular.isDefined(item.name) && item.name !== null ? ': ' + item.name : '';
                var modalOptions = {
                    closeButtonText: 'Cancel',
                    actionButtonText: 'Yes',
                    headerText: 'Delete Image' + itemName,
                    bodyText: 'Are you sure you wish to delete this image?',
                };
                ModalService.show({}, modalOptions).then(function (result) {
                    TuMediaImagesService.deleteImage(TuMediaImagesService.coreId, item)
                        .then(function (success) {
                            $scope.submitInProgress = false;
                            NotifierFactory.show(
                                success ? 'success' : 'error',
                                success ? 'Successfully!' : 'Failure!',
                                'Image Deleted'
                            );
                            if (success) {
                                TuMediaImagesService.setSelectedImage(TuMediaImagesService.getItemImage());
                                $scope.imageItems.splice(TuMediaService.objectSearch($scope.imageItems, item.id), 1);
                                $scope.filteredImageItems.splice(TuMediaService.objectSearch($scope.filteredImageItems, item.id), 1);
                                var assignedOrder = 1;
                                for (var index = 0; index < $scope.filteredImageItems.length; index++) {
                                    if ($scope.filteredImageItems[index][$scope.type]) {
                                        $scope.filteredImageItems[index].orderType[$scope.type] = assignedOrder;
                                        assignedOrder++;
                                    }
                                }
                                $scope.filteredImageItems = orderBy($scope.filteredImageItems, 'orderType.' + $scope.type, false);
                                controller.recalculateImages();
                                TuProfileFactory.setTuMediaUpdated(true);
                            }
                        });
                });
            } else {
                TuMediaImagesService.setSelectedImage(TuMediaImagesService.getItemImage(0));
            }
        };

        /**
         * Returns overview type system name.
         *
         * @returns {string}
         */
        $scope.typeOverview = function () {
            return TuMediaService.typeOverview();
        };

        /**
         * Returns undergraduate type system name.
         *
         * @returns {string}
         */
        $scope.typeUndergraduate = function () {
            return TuMediaService.typeUndergraduate();
        };

        /**
         * Returns postgraduate type system name.
         *
         * @returns {string}
         */
        $scope.typePostgraduate = function () {
            return TuMediaService.typePostgraduate();
        };

        /**
         * Returns selected image id.
         *
         * @returns {string}
         */
        $scope.selectedItemId = function () {
            return TuMediaImagesService.getSelectedImage().id;
        };

        /**
         * Recalculates number of images for each type.
         */
        controller.recalculateImages = function () {
            $scope.uploadedImagesCount.master = 0;
            $scope.uploadedImagesCount.ug = 0;
            $scope.uploadedImagesCount.pg = 0;
            angular.forEach(TuMediaImagesService.getImageItems(), function (value) {
                if (value.master) {
                    $scope.uploadedImagesCount.master++;
                }
                if (value.ug) {
                    $scope.uploadedImagesCount.ug++;
                }
                if (value.pg) {
                    $scope.uploadedImagesCount.pg++;
                }
            });
            TuMediaImagesService.uploadedImagesCount = $scope.uploadedImagesCount;
            controller.setOptionsMaxFiles();
        };

        /**
         * DropZone init function.
         */
        controller.dropZoneImageInit = function () {
            controller.dropZoneImageInstance = this;
            controller.setOptionsMaxFiles();
        };

        /**
         * DropZone options url function.
         *
         * @returns {string}
         */
        controller.getUploadUrl = function () {
            controller.setOptionsMaxFiles();
            return constants.api.institutions.url + '/v1/tu-profile/' + profileId + '/images?tu=true';
        };

        controller.setOptionsMaxFiles = function () {
            if (controller.dropZoneImageInstance !== null) {
                controller.dropZoneImageInstance.options.maxFiles = TuMediaImagesService.getMaxImages() - $scope.uploadedImagesCount[$scope.type];
            }
        };

        /**
         * Uploading Image
         *
         * @params {Object} item
         */
        controller.uploadImage = function (item) {
            var imageItem = angular.copy(item);
            TuMediaImagesService.displayIsValidType = false;
            TuMediaImagesService.setHasErrors(false);
            var actionName = 'Added';
            if (imageItem.id !== 'temporary') {
                actionName = 'Updated';
            }
            if (!angular.isDefined(item.url) || !(angular.isDefined(item.url) && item.url.length > 0)) {
                TuMediaImagesService.setHasErrors(true);

                return false;
            }
            // upload is slow so we will display a notification, can be removed if we implement rabbitmq
            if (imageItem.id === 'temporary') {
                NotifierFactory.show(
                    'success',
                    'Image upload is processing, please wait...',
                    ''
                );
            }
            TuMediaImagesService.setImageUploadingInProgress(true);
            TuMediaImagesService.saveImage(TuMediaImagesService.coreId, imageItem)
                .then(function (result) {
                    TuMediaImagesService.setImageUploadingInProgress(false);
                    if (result) {
                        if (imageItem.id === 'temporary') {
                            item.id = result.insertedId;
                            item.thumbnailUrl = result.thumbnailUrl;
                            item.url = result.url;
                            TuMediaImagesService.getImageItems().unshift(item);
                        }
                        TuMediaImagesService.setSelectedImage(item);
                        TuMediaImagesService.setHasErrors(false);
                        $scope.selectedImageBeforeChanges = item;
                        TuMediaImagesService.setHighlighted(true);
                        TuMediaImagesService.displayIsValidType = false;
                    }
                    NotifierFactory.show(
                        result ? 'success' : 'error',
                        result ? 'Add a title, description and click Update' : 'Failure!',
                        result ? ' Image '+ actionName + ' Successfully!' : ' Image '+ actionName + 'Failure!'
                    );
                    /**
                     * Tell other controllers to apply filter on changes
                     */
                    TuMediaImagesService.setTriggerChange(Math.random());
                });
        };

        /**
         * DropZone success handler.
         *
         * @param file
         * @param response
         */
        controller.handleImageSuccess = function (file, response) {
            // remove previously uploaded image from dropzone instance
            controller.dropZoneImageInstance.removeAllFiles();
            var item = TuMediaImagesService.getItemImage(0);
            if (angular.isDefined(response.status) && response.status &&
                angular.isDefined(response.path) && response.path
            ) {
                TuMediaImagesService.setSelectedImage(item);
                if ($scope.uploadedImagesCount.master >= controller.maxImages) {
                    delete item.master;
                }
                if ($scope.uploadedImagesCount.ug >= controller.maxImages) {
                    delete item.ug;
                }
                if ($scope.uploadedImagesCount.pg >= controller.maxImages) {
                    delete item.pg;
                }
                if (item.master || item.ug || item.pg) {
                    item.id = 'temporary';
                    item.thumbnailUrl = response.path;
                    item.url = response.path;
                    $scope.item = item;
                    controller.setOptionsMaxFiles();
                    controller.uploadImage(item);
                }
                item.id = 'temporary';
                item.thumbnailUrl = response.path;
                item.url = response.path;
                $scope.item = item;
                controller.setOptionsMaxFiles();
            } else {
                var message = 'Bad response, please try again later';
                if (response !== null && response.hasOwnProperty('code')) {
                    message = response.message;
                }
                NotifierFactory.show(
                    'error',
                    message,
                    'Error!'
                );
            }
            $scope.$apply();
        };

        /**
         * DropZone error handler.
         *
         * @param file
         * @param response
         */
        controller.handleImageError = function (file, response) {
            // remove previously uploaded image from dropzone instance
            controller.dropZoneImageInstance.removeAllFiles();
            var message = response;
            if (response.error === 'Forbidden' || response.status === 'error') {
                message = response.message;
            } else if (file.accepted && file.status === 'error') {
                message = 'Upload error, please try again later';
            }

            controller.setOptionsMaxFiles();
            if (file.size > $scope.imageConfig.dropzone.maxFilesize * 1000 * 1000) {
                message = $scope.imageConfig.dropzone.dictFileTooBig;
            }
            NotifierFactory.show(
                'error',
                message
            );
            $scope.$apply();
        };

        $scope.isSelected = function () {
            return $scope.selectedItem() && $scope.selectedItem().id === '' ||
                $scope.isTemporary();
        };

        $scope.isTemporary = function () {
            return $scope.selectedItem() && $scope.selectedItem().id === 'temporary';
        };

        /**
         * get image uploading in progress
         *
         * @returns {boolean}
         */
        $scope.getImageUploadingInProgress = function () {
            return TuMediaImagesService.getImageUploadingInProgress();
        };

        $scope.openLightboxModal = function (index) {
            Lightbox.openModal($scope.filteredImageItems, index);
        };
    };

    angular
        .module('qsHub')
        .controller('TuMediaImagesController', [
            '$scope',
            'constants',
            'Lightbox',
            'TuProfileFactory',
            'NotifierFactory',
            'TuMediaImagesService',
            'TuMediaService',
            'ModalService',
            '$filter',
            'orderByFilter',
            'AuthenticationService',
            'WatchService',
            App.controllers.TuMediaImages
        ]);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers: {}});

    App.controllers.TuMediaImagesSidebar = function (
        $scope,
        $timeout,
        NotifierFactory,
        TuProfileFactory,
        TuMediaImagesService,
        TuMediaService,
        WatchService
    ) {
        var controller = this,
        alreadyInitialised = false;
        controller.uploadedImagesCount = {};
        $scope.hasErrors = true;
        TuMediaImagesService.displayIsValidType = false;
        TuMediaImagesService.setHasErrors(false);

        /**
         * Save form.
         *
         * @param item
         * @returns {boolean}
         */
        $scope.saveImageForm = function (item) {
            if (
                !$scope.forms.formImage ||
                !$scope.forms.formImage.$valid ||
                $scope.submitInProgress
            ) {
                return false;
            }
            var imageItem = angular.copy(item);
            TuMediaImagesService.displayIsValidType = false;
            TuMediaImagesService.setHasErrors(false);
            var actionName = 'Added';
            if (imageItem.id !== 'temporary') {
                actionName = 'Updated';
            }
            if (!TuMediaService.isValidType(item)) {
                TuMediaImagesService.displayIsValidType = true;

                return false;
            }
            if (!angular.isDefined(item.url) || !(angular.isDefined(item.url) && item.url.length > 0)) {
                $scope.setHasErrors(true);

                return false;
            }
            if (!$scope.forms.formImage || !$scope.forms.formImage.$valid
            ) {
                NotifierFactory.show(
                    'error',
                    'Error: Please fill in all required fields',
                    actionName + ' Image'
                );

                return false;
            }
            // upload is slow so we will display a notification, can be removed if we implement rabbitmq
            if (imageItem.id === 'temporary') {
                NotifierFactory.show(
                    'success',
                    'Image upload is processing, please wait...',
                    ''
                );
            }

            // Check if newly assigned checkboxes doesn't hit the limits
            var checkAssigned = controller.checkAssigned(imageItem, item);
            // To make sure everything goes smooth, re-assign the values from the function output
            imageItem = checkAssigned.imageItem;
            item = checkAssigned.item;
            if (!checkAssigned.actionAllowed) {
                return false;
            }
            $scope.submitInProgress = true;
            TuMediaImagesService.saveImage(TuMediaImagesService.coreId, imageItem)
                .then(function (success) {
                    $scope.submitInProgress = false;
                    NotifierFactory.show(
                        success ? 'success' : 'error',
                        success ? 'Successfully!' : 'Failure!',
                        ' Image '+ actionName
                    );
                    if (success) {
                        if (imageItem.id === 'temporary') {
                            item.id = success.insertedId;
                            item.thumbnailUrl = success.thumbnailUrl;
                            item.url = success.url;
                            TuMediaImagesService.getImageItems().unshift(item);
                        }
                        TuMediaImagesService.setSelectedImage(item);
                        TuMediaImagesService.setHasErrors(false);
                        $scope.selectedImageBeforeChanges = item;
                    }
                    /**
                     * Tell other controllers to apply filter on changes
                     */
                    TuMediaImagesService.setTriggerChange(Math.random());
                });
        };

        /**
         * Check if newly assigned tick boxes are not hitting the limits.
         *
         * @param {Object} imageItem
         * @param {Object} item
         * @returns {Object}
         */
        controller.checkAssigned = function (imageItem, item) {
            // is action allowed
            var actionAllowed = true;
            // Emulate result
            var result = null;
            if (imageItem.id === 'temporary') {
                result = angular.copy(TuMediaImagesService.getImageItems());
                result.unshift(item);
            } else {
                result = angular.copy(TuMediaImagesService.getImageItems());
                angular.forEach(result, function(itemValue, itemKey) {
                    if(itemValue.id === item.id) {
                        result[itemKey] = item;
                    }
                });
            }
            // Recalculate new usages
            controller.uploadedImagesCount.master = 0;
            controller.uploadedImagesCount.ug = 0;
            controller.uploadedImagesCount.pg = 0;
            angular.forEach(result, function (value) {
                if (value.master) {
                    controller.uploadedImagesCount.master++;
                }
                if (value.ug) {
                    controller.uploadedImagesCount.ug++;
                }
                if (value.pg) {
                    controller.uploadedImagesCount.pg++;
                }
            });
            // Check if new usages are allowed value
            if (item.master && controller.uploadedImagesCount.master > TuMediaImagesService.getMaxImages()) {
                item.master = false;
                imageItem.master = false;
                controller.assignError('overview');
            }
            if (item.ug && controller.uploadedImagesCount.ug > TuMediaImagesService.getMaxImages()) {
                item.ug = false;
                imageItem.ug = false;
                controller.assignError('undergraduate');
            }
            if (item.pg && controller.uploadedImagesCount.pg > TuMediaImagesService.getMaxImages()) {
                item.pg = false;
                imageItem.pg = false;
                controller.assignError('postgraduate');
            }
            if (!item.master && !item.ug && !item.pg) {
                actionAllowed = false;
            }

            return {actionAllowed: actionAllowed, imageItem: imageItem, item: item};
        };

        controller.assignError = function (type) {
            NotifierFactory.show(
                'error',
                'Please delete an image and try again',
                type + ' profile has reached the limit '
            );
        };

        /**
         * Clear item.
         *
         * @param item
         */
        $scope.clearImageForm = function (item) {
            if (angular.isDefined(item)) {
                $scope.itemImage = item;
            } else {
                $scope.itemImage = TuMediaImagesService.getItemImage();
            }

            if ($scope.itemImage.id === 'temporary') {
                TuMediaImagesService.setSelectedImage(TuMediaImagesService.getItemImage(0));
            } else {
                $scope.itemImage = TuMediaImagesService.resetItem($scope.itemImage);
            }
            $scope.setDisplayIsValidType(false);
        };

        /**
         * Get selected item.
         *
         * @returns {*}
         */
        $scope.getSelectedImage = function () {
            return TuMediaImagesService.getSelectedImage();
        };

        /**
         * Get image items.
         *
         * @returns {*}
         */
        $scope.getImageItems = function () {
            return TuMediaImagesService.getImageItems();
        };

        /**
         * Get has errors flag.
         *
         * @returns {boolean|*}
         */
        $scope.getHasErrors = function () {
            return TuMediaImagesService.getHasErrors();
        };

        $scope.setHasErrors = function (value) {
            TuMediaImagesService.setHasErrors(value);
        };

        $scope.type = function () {
            return TuMediaImagesService.getType();
        };

        $scope.setDisplayIsValidType = function (value) {
            TuMediaImagesService.displayIsValidType = value;
        };

        $scope.getDisplayIsValidType = function () {
            return TuMediaImagesService.displayIsValidType;
        };

        /**
         * Is edit mode?
         *
         * @returns {boolean}
         */
        $scope.isEditMode = function () {
            return $scope.selectedImage && $scope.selectedImage.id !== 'temporary' ? true : false;
        };
        
        /**
         * get image uploading in progress
         *
         * @returns {boolean}
         */
        $scope.getImageUploadingInProgress = function () {
            return TuMediaImagesService.getImageUploadingInProgress();
        };

        controller.getSelectedImageWatch = function (selectedImage) {
            $scope.selectedImageBeforeChanges = angular.copy(selectedImage);
            $scope.selectedImage = angular.copy(selectedImage);
        };

        controller.getHasErrorsWatch = function (newValue, oldValue) {
            if (!angular.equals(oldValue, newValue)) {
                $scope.hasErrors = newValue;
            }
        };

        controller.getTriggerResetWatch = function (newValue, oldValue) {
            if (!angular.equals(oldValue, newValue)) {
                $scope.selectedImage = angular.copy($scope.selectedImageBeforeChanges);
            }
        };

        controller.getHighlightedWatch = function (newValue) {
            if (newValue) {
                $scope.isHighlighted = newValue;
                $timeout(function () {
                    TuMediaImagesService.setHighlighted(false);
                    $scope.isHighlighted = false;
                },600);
            }
        };

        controller.getSelectedMediaTabIdWatch = function (tabId) {
            if (tabId === 1) {
                if (typeof $scope.selectedImageBeforeChanges !== 'undefined') {
                    $scope.selectedImage = angular.copy($scope.selectedImageBeforeChanges);
                }
                $timeout(function () {
                    TuMediaImagesService.setHighlighted(true);
                },200);
            }
        };

        controller.initWatches = function () {
            WatchService.create($scope, TuMediaImagesService.getSelectedImage, controller.getSelectedImageWatch, true);
            WatchService.create($scope, TuMediaImagesService.getHasErrors, controller.getHasErrorsWatch);
            WatchService.create($scope, TuMediaImagesService.getTriggerReset, controller.getTriggerResetWatch);
            WatchService.create($scope, TuMediaImagesService.getHighlighted, controller.getHighlightedWatch);
            WatchService.create($scope, TuProfileFactory.getSelectedMediaTabId, controller.getSelectedMediaTabIdWatch);
        };

        controller.init = function () {
            controller.initWatches();
        };

        // listen to media tab visibility changes
        WatchService.create($scope, TuProfileFactory.isMediaTabSelected, function (isActive) {
            // if not already initialized and tab is active
            if (!alreadyInitialised && isActive) {
                controller.init();
                alreadyInitialised = true;
            }
        });
    };

    angular
        .module('qsHub')
        .controller('TuMediaImagesSidebarController', [
            '$scope',
            '$timeout',
            'NotifierFactory',
            'TuProfileFactory',
            'TuMediaImagesService',
            'TuMediaService',
            'WatchService',
            App.controllers.TuMediaImagesSidebar
        ]);

}(window.angular));
(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services: {}});

    App.services.TuMediaImagesService = function ($resource, $q, $log, constants, TuMediaService) {
        var service = {
                triggerChange: 0
            },
            maxImages = 40;
        service.getImageModel = function () {
            return $resource(constants.api.institutions.url + '/v1/tu-profile/:id/image/:imageId', null, {
                update: {method: 'PATCH'}
            });
        };

        service.getImagesOrderingModel = function () {
            return $resource(constants.api.institutions.url + '/v1/tu-profile/:id/imagesOrder', null, {
                update: {method: 'PATCH'}
            });
        };

        /**
         * Save Image
         *
         * @param institutionId
         * @param image
         * @returns {*}
         */
        service.saveImage = function (institutionId, image) {
            var imageModel = service.getImageModel(),
                imageSubmit = service.formatImage(image);
            if (angular.isDefined(imageSubmit.id) && imageSubmit.id !== 'temporary') {
                // update image
                return imageModel.update(
                    {id: institutionId, imageId: image.id},
                    {
                        name: imageSubmit.name,
                        description: imageSubmit.description,
                        thumbnailUrl: imageSubmit.thumbnailUrl,
                        url: imageSubmit.url,
                        orderType: imageSubmit.orderType,
                        assignedTo: imageSubmit.assignedTo
                    }
                ).$promise.then(function (data) {
                    if (constants.dev) {
                        $log.log('success, got data: ', data);
                    }
                    return true;
                }, function (error) {
                    //@todo log error
                    if (constants.dev) {
                        $log.log('request failed' + error);
                    }
                    return false;
                });
            } else {
                // create new image
                return imageModel.save(
                    {id: institutionId},
                    {
                        name: imageSubmit.name,
                        description: imageSubmit.description,
                        url: imageSubmit.url,
                        orderType: imageSubmit.orderType,
                        assignedTo: imageSubmit.assignedTo
                    }
                ).$promise.then(function (data) {
                    if (constants.dev) {
                        $log.log('success, got data: ', data);
                    }
                    return data;
                }, function (error) {
                    //@todo log error
                    if (constants.dev) {
                        $log.log('request failed' + error);
                    }
                    return false;
                });
            }
        };

        /**
         * Save image order.
         *
         * @param {string} id - Institution TU profile mongo ID
         * @param {Object} images
         * @returns {Promise}
         */
        service.saveOrder = function (id, images) {
            var institutionModel = service.getImagesOrderingModel();
            return institutionModel.update(
                {id: id},
                {
                    'images': service.formatForOrdering(images)
                }
            ).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        service.formatForOrdering = function (items) {
            var formattedItems = [];
            angular.forEach(items, function (item) {
                formattedItems.push({
                    'id': item.id,
                    'orderType': item.orderType
                });
            });

            return formattedItems;
        };

        /**
         * Delete image.
         *
         * @param institutionId
         * @param image
         * @returns {*}
         */
        service.deleteImage = function (institutionId, image) {
            service.setHasErrors(false);
            var imageModel = service.getImageModel();
            return imageModel.delete(
                {id: institutionId, imageId: image.id}
            ).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        /**
         * Set Image Uploading in progress.
         *
         * @param {Boolean} value
         */
        service.setImageUploadingInProgress = function (value) {
            service.imageUploadingInProgress = value;
        };


        /**
         * Get Image Uploading in progress.
         *
         * @return {Boolean}
         */
        service.getImageUploadingInProgress = function () {
            return service.imageUploadingInProgress;
        };

        /**
         * Set selected image.
         *
         * @param item
         */
        service.setSelectedImage = function (item) {
            service.selectedImage = item;
        };

        /**
         * Get selected image
         *
         * @returns {*}
         */
        service.getSelectedImage = function () {
            return service.selectedImage;
        };

        /**
         * Trigger filter.
         *
         * @param triggerChange
         */
        service.setTriggerChange = function (triggerChange) {
            service.triggerChange = triggerChange;
        };

        /**
         * Get trigger filter value
         *
         * @returns {*}
         */
        service.getTriggerChange = function () {
            return service.triggerChange;
        };

        /**
         * Set image items.
         *
         * @param items
         * @param transform
         */
        service.setImageItems = function (items, transform) {
            if (!angular.isDefined(transform)) {
                transform = true;
            }
            if (transform) {
                angular.forEach(items, function (value, key) {
                    items[key][TuMediaService.typeOverview()] = false;
                    items[key][TuMediaService.typeUndergraduate()] = false;
                    items[key][TuMediaService.typePostgraduate()] = false;
                    angular.forEach(items[key].assignedTo, function (aValue) {
                        if (aValue === TuMediaService.typeOverview()) {
                            items[key][TuMediaService.typeOverview()] = true;
                        } else if (aValue === TuMediaService.typeUndergraduate()) {
                            items[key][TuMediaService.typeUndergraduate()] = true;
                        } else if (aValue === TuMediaService.typePostgraduate()) {
                            items[key][TuMediaService.typePostgraduate()] = true;
                        }
                    });
                    /**
                     * Set initial order
                     * @type {{master: number, ug: number, pg: number}}
                     */
                    if (items[key].orderType === null) {
                        items[key].orderType = {
                            'master': 0,
                            'ug': 0,
                            'pg': 0
                        };
                    }
                    var orderOverview = 0;
                    var orderUndergraduate = 0;
                    var orderPostgraduate = 0;
                    if (angular.isDefined(items[key].orderType)) {
                        if (angular.isDefined(items[key].orderType[TuMediaService.typeOverview()])) {
                            orderOverview = items[key].orderType[TuMediaService.typeOverview()];
                        }
                        if (angular.isDefined(items[key].orderType[TuMediaService.typeUndergraduate()])) {
                            orderUndergraduate = items[key].orderType[TuMediaService.typeUndergraduate()];
                        }
                        if (angular.isDefined(items[key].orderType[TuMediaService.typePostgraduate()])) {
                            orderPostgraduate = items[key].orderType[TuMediaService.typePostgraduate()];
                        }
                    }
                    items[key].orderType = {
                        'master': orderOverview,
                        'ug': orderUndergraduate,
                        'pg': orderPostgraduate
                    };
                    if (angular.isDefined(items[key]._id)) {
                        items[key].id = items[key]._id;
                    }
                    delete items[key].assignedTo;
                    delete items[key]._id;
                });
            }

            service.imageItems = items;
        };

        /**
         * Get image items.
         *
         * @returns {*}
         */
        service.getImageItems = function () {
            return service.imageItems;
        };

        /**
         * Get image item.
         * @param {int} defaultValue
         */
        service.getItemImage = function (defaultValue) {
            if (angular.isUndefined(defaultValue)) {
                defaultValue = 0;
            }
            var orderTypes = {};
            orderTypes[TuMediaService.typeOverview()] = defaultValue;
            orderTypes[TuMediaService.typeUndergraduate()] = defaultValue;
            orderTypes[TuMediaService.typePostgraduate()] = defaultValue;

            return {
                'id': '',
                'name': '',
                'description': '',
                'master': true,
                'ug': true,
                'pg': true,
                'orderType': orderTypes,
                'url': ''
            };
        };

        /**
         * Reset image item.
         *
         * @param item
         * @returns {*}
         */
        service.resetItem = function (item) {
            item.master = true;
            item.ug = true;
            item.pg = true;
            item.name = '';
            item.description = '';

            return item;
        };

        /**
         * Format images.
         *
         * @param images
         * @returns {*}
         */
        service.formatImages = function (images) {
            angular.forEach(images, function (value, key) {
                images[key] = service.formatImage(value);
            });

            return images;
        };

        /**
         * Format to match db data.
         *
         * @param {Object} image
         * @returns {Object}
         */
        service.formatImage = function (image) {
            if (image) {
                image.assignedTo = [];
                if (image.master) {
                    image.assignedTo.push('master');
                }
                if (image.ug) {
                    image.assignedTo.push('ug');
                }
                if (image.pg) {
                    image.assignedTo.push('pg');
                }
                // remove data we don't want to send.
                if (angular.isDefined(image.id) && parseInt(image.id, 10) === 0) {
                    delete image.id;
                }
                delete image.master;
                delete image.ug;
                delete image.pg;
                delete image.order;
            }

            return image;
        };

        /**
         * Sets has errors flag.
         *
         * @param {boolean} hasErrors
         */
        service.setHasErrors = function (hasErrors) {
            service.hasErrors = hasErrors;
        };

        /**
         * Gets has errors flag.
         *
         * @returns {boolean|*}
         */
        service.getHasErrors = function () {
            return service.hasErrors;
        };

        /**
         * Sets is highlighted.
         *
         * @param {boolean} isHighlighted
         */
        service.setHighlighted = function (isHighlighted) {
            service.isHighlighted = isHighlighted;
        };

        /**
         * Gets is Highlighted.
         *
         * @returns {boolean|*}
         */
        service.getHighlighted = function () {
            return service.isHighlighted;
        };

        service.coreId = function (coreId) {
            service.coreId = coreId;
        };

        /**
         * Set type.
         *
         * @returns {*}
         */
        service.setType = function (type) {
            service.type = type;
        };

        /**
         * Get type.
         *
         * @returns {*}
         */
        service.getType = function () {
            return service.type;
        };

        /**
         * Replace item by item id
         *
         * @param key
         */
        service.replaceByKey = function (key) {
            angular.forEach(service.getImageItems(), function(itemValue, itemKey) {
                if(itemValue.id === key) {
                    service.getImageItems()[itemKey] = service.getSelectedImage();
                }
            });
        };

        /**
         * Trigger filter.
         *
         * @param triggerChange
         */
        service.setTriggerReset = function (triggerChange) {
            service.triggerChange = triggerChange;
        };

        service.getTriggerReset = function () {
            return service.triggerChange;
        };

        /**
         * UploadedImagesCount object, contains {master:count, ug: count, pg: count}
         *
         * @param {Object} uploadedImagesCount
         */
        service.setUploadedImagesCount = function (uploadedImagesCount) {
            service.uploadedImagesCount = uploadedImagesCount;
        };

        service.getUploadedImagesCount = function () {
            return service.uploadedImagesCount;
        };

        service.getMaxImages = function () {
            return maxImages;
        };

        return service;
    };

    angular
        .module('qsHub')
        .service('TuMediaImagesService', [
            '$resource',
            '$q',
            '$log',
            'constants',
            'TuMediaService',
            App.services.TuMediaImagesService
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { services: {} });

    App.services.TmProfileMediaService = function() {
        var service = {
            types: {
                master: 'master'
            }
        };

        service.typeOverview = function() {
            return service.types.master;
        };



        /**
         * Get TU profile subtypes.
         *
         * @returns {array}
         */
        service.getTypes = function() {
            return [
                service.typeOverview()
            ];
        };

        service.isValidType = function(item) {
            var scopeTypes = service.getTypes(),
                valid = false;
            for (var typesIndex = 0; typesIndex < scopeTypes.length; typesIndex++) {
                if (item[scopeTypes[typesIndex]]) {
                    valid = true;
                }
            }

            return valid;
        };

        /**
         * Validate profile subtype.
         *
         * @param {Object} list
         * @returns {true|Object}
         */
        service.validateTypes = function(list) {
            for (var i = 0; i < list.length; i++) {
                // if invalid type
                if (!service.isValidType(list[i])) {
                    // return invalid item
                    return list[i];
                }
            }

            return true;
        };

        /**
         * Searches the object for a given value and returns the first corresponding key if successful.
         *
         * @param {Object} haystack
         * @param {String} needle
         * @param {null|String}column
         * @returns {boolean|int} - returns the key for needle if it is found in the array, FALSE otherwise.
         */
        service.objectSearch = function(haystack, needle, column) {
            column = column || 'id';
            for (var i = 0; i < haystack.length; i++) {
                if (haystack[i] && haystack[i][column] && haystack[i][column] === needle) {
                    return i;
                }
            }
            return false;
        };


        return service;
    };

    angular
        .module('qsHub')
        .service('TmMediaService', [
            App.services.TmProfileMediaService
        ]);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { controllers: {} });

    App.controllers.TmProfileMedia = function(
        $scope,
        constants,
        InstitutionFactory,
        TmProfileFactory,
        WatchService
    ) {
        var controller = this,
            alreadyInitialised = false;
        controller.devMode = constants.dev;
        controller.upgradeEmailsTo = constants.emails.upgradeTu;
        $scope.isMediaReloading = true;

        controller.isImagesTabActive = function () {
            var selectedMediaTabId = TmProfileFactory.getSelectedMediaSubTabId();
            return selectedMediaTabId === 1 || selectedMediaTabId === null;
        };

        controller.isVideosTabActive = function () {
            return TmProfileFactory.isMediaVideosSubTabSelected();
        };

        controller.isBrochuresTabActive = function () {
            return TmProfileFactory.isMediaBrochuresSubTabSelected();
        };

        controller.isSocialMediaTabActive = function () {
            return TmProfileFactory.isMediaSocialSubTabSelected();
        };

        controller.initWatches = function () {
            WatchService.create($scope, InstitutionFactory.isAdvanced, function (newValue, oldValue) {
                if (oldValue !== newValue) {
                    $scope.isAdvanced = newValue;
                }
            });
            
            // watch for active institution changes changes
            WatchService.create($scope, InstitutionFactory.getId, function (visible) {
                $scope.isMediaReloading = !visible;
            });
        };

        controller.isRightSidePanelActive = function () {
            // media tab is active
            if (TmProfileFactory.isMediaTabSelected()) {
                return true;
            }

            return false;
        };

        controller.init = function () {
            controller.initWatches();
        };

        // listen to departments tab visibility changes
        WatchService.create($scope, TmProfileFactory.isMediaTabSelected, function (isActive) {
            // if not already initialized and tab is active
            if (!alreadyInitialised && isActive) {
                controller.init();
                alreadyInitialised = true;
            }
        });
    };

    angular
        .module('qsHub')
        .controller('TmProfileMediaController', [
            '$scope',
            'constants',
            'InstitutionFactory',
            'TmProfileFactory',
            'WatchService',
            App.controllers.TmProfileMedia
        ]);

} (window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { controllers: {} });

    App.controllers.TmMediaCommonSidebar = function(
        $scope,
        TmProfileFactory,
        TmMediaVideosService,
        TmMediaBrochuresService,
        TmMediaSocialMediasService,
        TmMediaImagesService
    ) {
        if (angular.isUndefined($scope.tabId)) {
            TmProfileFactory.setSelectedMediaSubTabId(1);
        }

        $scope.setSelected = function(tabId) {
            if ($scope.tabId !== tabId) {
                $scope.tabId = tabId;
                TmProfileFactory.setSelectedMediaSubTabId(tabId);
                return true;
            } else {
                return false;
            }
        };

        $scope.getSelected = function() {
            return TmProfileFactory.getSelectedMediaSubTabId();
        };

        $scope.getGeneralCounter = function() {
            var totalCount = 0;
            var videoItems = TmMediaVideosService.getVideoItems();
            if (angular.isArray(videoItems)) {
                totalCount += videoItems.length;
            }
            var brochureItems = TmMediaBrochuresService.getBrochureItems();
            if (angular.isArray(brochureItems)) {
                totalCount += brochureItems.length;
            }
            var socialMediaItems = TmMediaSocialMediasService.getSocialMediaItems();
            if (angular.isArray(socialMediaItems)) {
                totalCount += socialMediaItems.length;
            }

            var imageItems = TmMediaImagesService.getImageItems();
            if (angular.isArray(imageItems)) {
                totalCount += imageItems.length;
            }

            return totalCount;
        };
    };

    angular
        .module('qsHub')
        .controller('TmMediaCommonSidebarController', [
            '$scope',
            'TmProfileFactory',
            'TmMediaVideosService',
            'TmMediaBrochuresService',
            'TmMediaSocialMediasService',
            'TmMediaImagesService',
            App.controllers.TmMediaCommonSidebar
        ]);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { controllers: {} });

    App.controllers.TmMediaImages = function (
        $scope,
        constants,
        Lightbox,
        TmProfileFactory,
        NotifierFactory,
        TmMediaImagesService,
        TmMediaService,
        ModalService,
        $filter,
        orderBy,
        AuthenticationService,
        WatchService
    ) {
        var controller = this,
            profileId;
        controller.devMode = constants.dev;
        TmMediaImagesService.coreId = 0;
        $scope.imageItems = [];
        $scope.itemImage = {};
        $scope.displayLocationDeletePopup = true;
        $scope.type = TmMediaService.typeOverview();
        // assign defaults for uploadedImagesCount per type
        $scope.uploadedImagesCount = {};
        $scope.uploadedImagesCount[TmMediaService.typeOverview()] = 0;
        // maximum images allowed per type, displayed in view
        controller.maxImages = TmMediaImagesService.getMaxImages();
        controller.dropZoneImageInstance = null;
        TmMediaImagesService.setType(TmMediaService.typeOverview());
        $scope.isLoaded = false;

        /**
         * Sortable options.
         */
        $scope.sortableOptions = {
            disabled: false,
            'ui-floating': false,
            start: function () {
                controller.initialList = [];
                angular.copy($scope.imageItems, controller.initialList);
            },
            update: function (e, ui) {
                var validateItems = true;
                if (true !== validateItems) {
                    ui.item.sortable.cancel();
                }
            },
            stop: function() {
                var validateItems = true;
                if (true === validateItems) {
                    var assignedOrder = 1;
                    // change images order
                    for (var index = 0; index < $scope.imageItems.length; index++) {
                            $scope.imageItems[index].orderType[$scope.type] = assignedOrder;
                            assignedOrder++;
                    }

                    $scope.sortableOptions.disabled = false;
                    TmMediaImagesService.saveOrder(TmMediaImagesService.coreId, $scope.imageItems)
                        .then(function(success) {
                            NotifierFactory.show(
                                success ? 'success' : 'error',
                                success ? 'Success!' : 'Failure!',
                                'Image Order Save'
                            );
                            TmMediaImagesService.setSelectedImage(TmMediaImagesService.getItemImage());
                        })
                        .finally(function() {
                            $scope.sortableOptions.disabled = false;
                            TmMediaImagesService.setImageItems($scope.imageItems);
                        });
                } else {
                    NotifierFactory.show(
                        'error',
                        'Failure!',
                        'Invalid items in image list'
                    );
                    TmMediaImagesService.setSelectedImage(validateItems);
                    TmMediaImagesService.displayIsValidType = false;

                    return false;
                }
            }
        };

        /**
         * Assigns selected image.
         *
         * @param {Object} item
         */
        $scope.selectImage = function (item) {
            if (angular.isDefined(item) && item.id !== 'temporary') {
                TmMediaImagesService.setHasErrors(false);
                TmMediaImagesService.displayIsValidType = false;
                $scope.itemImage = TmMediaImagesService.getItemImage();
                if (angular.isDefined(item)) {
                    TmMediaImagesService.setSelectedImage(item);
                } else {
                    TmMediaImagesService.setHasErrors(true);
                    TmMediaImagesService.setSelectedImage($scope.itemImage);
                }
            }
        };

        /**
         * Returns selected image.
         *
         * @returns {Object}
         */
        $scope.selectedItem = function () {
            return TmMediaImagesService.getSelectedImage();
        };

        /**
         * Delete image action handler.
         *
         * @param item
         */
        $scope.deleteImage = function (item) {
            if (TmMediaImagesService.getSubmitInProgress()) {
                return false;
            }
            if (angular.isDefined($scope.item)) {
                $scope.item.thumbnailUrl = null;
            }
            if (angular.isDefined(item) && item.id !== 'temporary') {
                var itemName = angular.isDefined(item.name) && item.name !== null ? ': ' + item.name : '';
                var modalOptions = {
                    closeButtonText: 'Cancel',
                    actionButtonText: 'Yes',
                    headerText: 'Delete Image' + itemName,
                    bodyText: 'Are you sure you wish to delete this image?',
                };
                ModalService.show({}, modalOptions).then(function () {
                    TmMediaImagesService.deleteImage(TmMediaImagesService.coreId, item)
                        .then(function (success) {
                            TmMediaImagesService.getSubmitInProgress(false);
                            NotifierFactory.show(
                                success ? 'success' : 'error',
                                success ? 'Successfully!' : 'Failure!',
                                'Image Deleted'
                            );
                            if (success) {
                                TmMediaImagesService.setSelectedImage(TmMediaImagesService.getItemImage());
                                $scope.imageItems.splice(TmMediaService.objectSearch($scope.imageItems, item.id), 1);
                                TmMediaImagesService.setImageItems($scope.imageItems);
                                var assignedOrder = 1;
                                for (var index = 0; index < $scope.imageItems.length; index++) {
                                    if ($scope.imageItems[index][$scope.type]) {
                                        assignedOrder++;
                                    }
                                }
                                controller.recalculateImages();
                            }
                        });
                });
            } else {
                TmMediaImagesService.setSelectedImage(TmMediaImagesService.getItemImage(0));
            }
        };

        /**
         * Returns selected image id.
         *
         * @returns {string}
         */
        $scope.selectedItemId = function () {
            if (TmMediaImagesService.getSelectedImage()) {
                return TmMediaImagesService.getSelectedImage().id;
            }
        };

        /**
         * Recalculates number of images for each type.
         */
        controller.recalculateImages = function () {
            $scope.imageItems = orderBy($scope.imageItems, 'orderType.' + $scope.type, false);
            $scope.uploadedImagesCount.master = 0;
            angular.forEach(TmMediaImagesService.getImageItems(), function () {
                $scope.uploadedImagesCount.master++;
            });
            TmMediaImagesService.uploadedImagesCount = $scope.uploadedImagesCount;
            controller.setOptionsMaxFiles();
        };

        /**
         * DropZone init function.
         */
        controller.dropZoneImageInit = function () {
            controller.dropZoneImageInstance = this;
            controller.setOptionsMaxFiles();
        };

        /**
         * DropZone options url function.
         *
         * @returns {string}
         */

        controller.getUploadUrl = function () {
            controller.setOptionsMaxFiles();
            return constants.api.institutions.url + '/v1/tm-profile/' + profileId + '/images';
        };

        controller.setOptionsMaxFiles = function () {
            if (controller.dropZoneImageInstance !== null) {
                controller.dropZoneImageInstance.options.maxFiles = TmMediaImagesService.getMaxImages() - $scope.uploadedImagesCount[$scope.type];
            }
        };

        /**
         * Uploading Image
         *
         * @params {Object} item
         */
        controller.uploadImage = function (item) {
            var imageItem = angular.copy(item);
            TmMediaImagesService.displayIsValidType = false;
            TmMediaImagesService.setHasErrors(false);
            var actionName = 'Added';
            if (imageItem.id !== 'temporary') {
                actionName = 'Updated';
            }
            if (!angular.isDefined(item.url) || !(angular.isDefined(item.url) && item.url.length > 0)) {
                TmMediaImagesService.setHasErrors(true);

                return false;
            }
            // upload is slow so we will display a notification, can be removed if we implement rabbitmq
            if (imageItem.id === 'temporary') {
                NotifierFactory.show(
                    'success',
                    'Image upload is processing, please wait...',
                    ''
                );
            }
            TmMediaImagesService.setImageUploadingInProgress(true);
            TmMediaImagesService.saveImage(TmProfileFactory.getId(), imageItem)
                .then(function (result) {
                    TmMediaImagesService.setImageUploadingInProgress(false);
                    if (result) {
                        if (imageItem.id === 'temporary') {
                            item.id = result.insertedId;
                            item.thumbnailUrl = result.thumbnailUrl;
                            item.url = result.url;
                            var imageItems = TmMediaImagesService.getImageItems();
                            imageItems.unshift(item);
                            TmMediaImagesService.setImageItems(angular.copy(imageItems));
                        }
                        TmMediaImagesService.setSelectedImage(item);
                        TmMediaImagesService.setHasErrors(false);
                        $scope.selectedImageBeforeChanges = item;
                        TmMediaImagesService.setHighlighted(true);
                        TmMediaImagesService.displayIsValidType = false;
                    }
                    NotifierFactory.show(
                        result ? 'success' : 'error',
                        result ? 'Add a title, description and click Update' : 'Failure!',
                        result ? ' Image '+ actionName + ' Successfully!' : ' Image '+ actionName + 'Failure!'
                    );
                    /**
                     * Tell other controllers to apply filter on changes
                     */
                    TmMediaImagesService.setTriggerChange(Math.random());
                });
        };

        /**
         * DropZone success handler.
         *
         * @param file
         * @param response
         */
        controller.handleImageSuccess = function (file, response) {
            // remove previously uploaded image from dropzone instance
            controller.dropZoneImageInstance.removeAllFiles();
            var item = TmMediaImagesService.getItemImage(0);
            if (angular.isDefined(response.status) && response.status &&
                angular.isDefined(response.path) && response.path
            ) {
                item.id = 'temporary';
                item.thumbnailUrl = response.path;
                item.url = response.path;
                $scope.item = item;
                controller.uploadImage(item);
                controller.setOptionsMaxFiles();
            } else {
                var message = 'Bad response, please try again later';
                if (response !== null && response.hasOwnProperty('code')) {
                    message = response.message;
                }
                NotifierFactory.show(
                    'error',
                    message,
                    'Error!'
                );
            }
            $scope.$apply();
        };

        /**
         * DropZone error handler.
         *
         * @param file
         * @param response
         */
        controller.handleImageError = function (file, response) {
            // remove previously uploaded image from dropzone instance
            controller.dropZoneImageInstance.removeAllFiles();
            var message = response;
            if (file.accepted && file.status === 'error') {
                message = 'Upload error, please try again later';
            }
            if (response.status === 'error') {
                message = response.message;
            }
            controller.setOptionsMaxFiles();
            if (file.size > $scope.imageConfig.dropzone.maxFilesize * 1000 * 1000) {
                message = $scope.imageConfig.dropzone.dictFileTooBig;
            }
            NotifierFactory.show(
                'error',
                message
            );
            $scope.$apply();
        };

        $scope.isSelected = function () {
            return $scope.selectedItem() && $scope.selectedItem().id === '' ||
                $scope.isTemporary();
        };

        $scope.isTemporary = function () {
            return $scope.selectedItem() && $scope.selectedItem().id === 'temporary';
        };

        /**
         * get image uploading in progress
         *
         * @returns {boolean}
         */
        $scope.getImageUploadingInProgress = function () {
            return TmMediaImagesService.getImageUploadingInProgress();
        };

        $scope.openLightboxModal = function (index) {
            Lightbox.openModal($scope.imageItems, index);
        };

        controller.initWatches = function () {
            /**
             * Assign profile data
             */
            WatchService.create($scope, TmProfileFactory.getData, function (profileData) {
                if (!profileData || !profileData.id || angular.equals({}, angular.copy(profileData))) {
                    return;
                }
                angular.copy(profileData.images, $scope.imageItems);
                TmMediaImagesService.coreId = profileData.id;
                TmMediaImagesService.setImageItems($scope.imageItems);
                controller.recalculateImages();
                $scope.imageItems = orderBy($scope.imageItems, 'orderType.' + $scope.type, false);
                TmMediaImagesService.setSelectedImage($scope.imageItems[0]);
                profileId = TmProfileFactory.getId();
                /**
                 * DropZone configuration.
                 */
                $scope.imageConfig = {
                    // http://www.dropzonejs.com/#configuration-options
                    dropzone: {
                        url: controller.getUploadUrl(),
                        maxFilesize: 0.4,
                        parallelUploads: 1,
                        autoProcessQueue: true,
                        addRemoveLinks: false,
                        previewsContainer: null,
                        clickable: '.upload-image',
                        init: controller.dropZoneImageInit,
                        acceptedFiles: 'image/jpeg,image/pjpeg,image/jpeg,image/pjpeg',
                        dictFileTooBig: "Uploaded Image is greater than the limit. Please upload less than 400KB.",
                        dictMaxFilesExceeded: "Profiles have reached the limit. Please delete an image and try again",
                        dictInvalidFileType: "Uploaded image has incorrect format. Please upload images in jpg, jpeg format.",
                        headers: AuthenticationService.getAuthorizationHeader()
                    },
                    // http://www.dropzonejs.com/#event-list
                    eventHandlers: {
                        success: controller.handleImageSuccess,
                        error: controller.handleImageError
                    }
                };
                $scope.isLoaded = true;
                // If the instance was previously initialized, and institution is switched, reassign url
                if (controller.dropZoneImageInstance) {
                    controller.dropZoneImageInstance.options.url = controller.getUploadUrl();
                }
            });

            /**
             * Assign image items on change
             */
            WatchService.create($scope, TmMediaImagesService.getImageItems, function (newValue, oldValue) {
                if (!angular.equals(oldValue, newValue)) {
                    $scope.imageItems = newValue;
                    controller.recalculateImages();
                }
            });

            /**
             * Re-filter array on trigger call.
             */
            WatchService.create($scope, TmMediaImagesService.getTriggerChange, function () {
                if (angular.isDefined($scope.selectedItemId()) && $scope.selectedItemId().length > 0) {
                    TmMediaImagesService.replaceByKey($scope.selectedItemId());
                }
                var assignedOrder = 1;
                // change images order
                for (var index = 0; index < $scope.imageItems.length; index++) {
                    if ($scope.imageItems[index][$scope.type]) {
                        $scope.imageItems[index].orderType[$scope.type] = assignedOrder;
                        assignedOrder++;
                    }
                }
                controller.recalculateImages();
                $scope.item = TmMediaImagesService.getItemImage(0);
            });
        };

        controller.init = function () {
            controller.initWatches();
        };

        controller.init();
    };

    angular
        .module('qsHub')
        .controller('TmMediaImagesController', [
            '$scope',
            'constants',
            'Lightbox',
            'TmProfileFactory',
            'NotifierFactory',
            'TmMediaImagesService',
            'TmMediaService',
            'ModalService',
            '$filter',
            'orderByFilter',
            'AuthenticationService',
            'WatchService',
            App.controllers.TmMediaImages
        ]);

} (window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { controllers: {} });

    App.controllers.TmMediaImagesSidebar = function(
        $scope,
        $timeout,
        NotifierFactory,
        TmProfileFactory,
        TmMediaImagesService,
        WatchService
    ) {
        var controller = this,
            alreadyInitialised = false;
        controller.uploadedImagesCount = {};
        $scope.hasErrors = true;
        TmMediaImagesService.displayIsValidType = false;
        TmMediaImagesService.setHasErrors(false);

        /**
         * Save form.
         *
         * @param item
         * @returns {boolean}
         */
        $scope.saveImageForm = function(item) {
            if (!$scope.forms.formImage ||
                !$scope.forms.formImage.$valid ||
                TmMediaImagesService.getSubmitInProgress()
            ) {
                return false;
            }
            var imageItem = angular.copy(item);
            TmMediaImagesService.setHasErrors(false);
            var actionName = 'Added';
            if (imageItem.id !== 'temporary') {
                actionName = 'Updated';
            }
            if (!angular.isDefined(item.url) || !(angular.isDefined(item.url) && item.url.length > 0)) {
                $scope.setHasErrors(true);

                return false;
            }
            if (!$scope.forms.formImage || !$scope.forms.formImage.$valid) {
                NotifierFactory.show(
                    'error',
                    'Error: Please fill in all required fields',
                    actionName + ' Image'
                );

                return false;
            }
            // upload is slow so we will display a notification, can be removed if we implement rabbitmq
            if (imageItem.id === 'temporary') {
                NotifierFactory.show(
                    'success',
                    'Image upload is processing, please wait...',
                    ''
                );
            }

            TmMediaImagesService.setSubmitInProgress(true);
            TmMediaImagesService.saveImage(TmMediaImagesService.coreId, imageItem)
                .then(function(success) {
                    TmMediaImagesService.setSubmitInProgress(false);
                    NotifierFactory.show(
                        success ? 'success' : 'error',
                        success ? 'Successfully!' : 'Failure!',
                        ' Image ' + actionName
                    );
                    if (success) {
                        TmMediaImagesService.setSelectedImage(item);
                        TmMediaImagesService.setHasErrors(false);
                        $scope.selectedImageBeforeChanges = item;
                    }
                    /**
                     * Tell other controllers to apply filter on changes
                     */
                    TmMediaImagesService.setTriggerChange(Math.random());
                });
        };

        /**
         * Check if newly assigned tick boxes are not hitting the limits.
         *
         * @param {Object} imageItem
         * @param {Object} item
         * @returns {Object}
         */
        controller.checkAssigned = function(imageItem, item) {
            // is action allowed
            var actionAllowed = true;
            // Emulate result
            var result = null;
            if (imageItem.id === 'temporary') {
                result = angular.copy(TmMediaImagesService.getImageItems());
                result.unshift(item);
            } else {
                result = angular.copy(TmMediaImagesService.getImageItems());
                angular.forEach(result, function(itemValue, itemKey) {
                    if (itemValue.id === item.id) {
                        result[itemKey] = item;
                    }
                });
            }
            // Recalculate new usages
            controller.uploadedImagesCount.master = 0;
            angular.forEach(result, function(value) {
                if (value.master) {
                    controller.uploadedImagesCount.master++;
                }
            });
            // Check if new usages are allowed value
            if (item.master && controller.uploadedImagesCount.master > TmMediaImagesService.getMaxImages()) {
                item.master = false;
                imageItem.master = false;
                controller.assignError('overview');
            }
            return { actionAllowed: actionAllowed, imageItem: imageItem, item: item };
        };

        controller.assignError = function(type) {
            NotifierFactory.show(
                'error',
                'Please delete an image and try again',
                type + ' profile has reached the limit '
            );
        };

        /**
         * Clear item.
         *
         * @param item
         */
        $scope.clearImageForm = function(item) {
            if (TmMediaImagesService.getSubmitInProgress()) {
                return false;
            }
            if (angular.isDefined(item)) {
                $scope.itemImage = item;
            } else {
                $scope.itemImage = TmMediaImagesService.getItemImage();
            }

            if ($scope.itemImage.id === 'temporary') {
                TmMediaImagesService.setSelectedImage(TmMediaImagesService.getItemImage(0));
            } else {
                $scope.itemImage = TmMediaImagesService.resetItem($scope.itemImage);
                TmMediaImagesService.setSelectedImage($scope.itemImage);
            }
            $scope.setDisplayIsValidType(false);
        };

        /**
         * Get selected item.
         *
         * @returns {*}
         */
        $scope.getSelectedImage = function() {
            return TmMediaImagesService.getSelectedImage();
        };

        /**
         * Get image items.
         *
         * @returns {*}
         */
        $scope.getImageItems = function() {
            return TmMediaImagesService.getImageItems();
        };

        /**
         * Get has errors flag.
         *
         * @returns {boolean|*}
         */
        $scope.getHasErrors = function() {
            return TmMediaImagesService.getHasErrors();
        };

        $scope.setHasErrors = function(value) {
            TmMediaImagesService.setHasErrors(value);
        };

        $scope.type = function() {
            return TmMediaImagesService.getType();
        };

        $scope.setDisplayIsValidType = function(value) {
            TmMediaImagesService.displayIsValidType = value;
        };

        $scope.getDisplayIsValidType = function() {
            return TmMediaImagesService.displayIsValidType;
        };

        $scope.submitInProgress = function ()
        {
            return TmMediaImagesService.getSubmitInProgress();
        };

        /**
         * Is edit mode?
         *
         * @returns {boolean}
         */
        $scope.isEditMode = function() {
            return $scope.selectedImage && $scope.selectedImage.id !== 'temporary' ? true : false;
        };

        /**
         * get image uploading in progress
         *
         * @returns {boolean}
         */
        $scope.getImageUploadingInProgress = function() {
            return TmMediaImagesService.getImageUploadingInProgress();
        };

        controller.initWatches = function() {
            WatchService.create($scope, TmMediaImagesService.getSelectedImage, function(selectedImage) {
                $scope.selectedImageBeforeChanges = angular.copy(selectedImage);
                $scope.selectedImage = angular.copy(selectedImage);
            });

            WatchService.create($scope, TmMediaImagesService.getHasErrors, function(newValue, oldValue) {
                if (!angular.equals(oldValue, newValue)) {
                    $scope.hasErrors = newValue;
                }
            });

            WatchService.create($scope, TmMediaImagesService.getTriggerReset, function(newValue, oldValue) {
                if (!angular.equals(oldValue, newValue)) {
                    $scope.selectedImage = angular.copy($scope.selectedImageBeforeChanges);
                }
            });

            WatchService.create($scope, TmMediaImagesService.getHighlighted, function(newValue) {
                if (newValue) {
                    $scope.isHighlighted = newValue;
                    $timeout(function() {
                        TmMediaImagesService.setHighlighted(false);
                        $scope.isHighlighted = false;
                    }, 200);
                }
            });

            WatchService.create($scope, TmProfileFactory.getSelectedMediaSubTabId, function(tabId) {
                if (tabId === 1) {
                    $timeout(function() {
                        TmMediaImagesService.setHighlighted(true);
                    }, 200);
                }
            });
        };

        controller.init = function() {
            controller.initWatches();
        };

        // listen to images tab visibility changes
        WatchService.create($scope, TmProfileFactory.isMediaTabSelected, function(isActive) {
            // if not already initialized and tab is active
            if (!alreadyInitialised && isActive) {
                controller.init();
                alreadyInitialised = true;
            }
        });
    };

    angular
        .module('qsHub')
        .controller('TmMediaImagesSidebarController', [
            '$scope',
            '$timeout',
            'NotifierFactory',
            'TmProfileFactory',
            'TmMediaImagesService',
            'WatchService',
            App.controllers.TmMediaImagesSidebar
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { services: {} });

    App.services.TmMediaImages = function($resource, $q, $log, constants, TmMediaService) {
        var serviceMediaImages = this;
        var service = {},
            maxImages = 40;
        serviceMediaImages.triggerChange = 0;
        serviceMediaImages.submitInProgress = false;

        service.getImageModel = function() {
            return $resource(constants.api.institutions.url + '/v1/tm-profile/:id/image/:imageId', null, {
                update: { method: 'PATCH' }
            });
        };

        service.getImagesOrderingModel = function() {
            return $resource(constants.api.institutions.url + '/v1/tm-profile/:id/imagesOrder', null, {
                update: { method: 'PATCH' }
            });
        };

        /**
         * Save Image
         *
         * @param institutionId
         * @param image
         * @returns {*}
         */
        service.saveImage = function(institutionId, image) {
            var imageModel = service.getImageModel(),
                imageSubmit = service.formatImage(image);
            if (angular.isDefined(imageSubmit.id) && imageSubmit.id !== 'temporary') {
                // update image
                return imageModel.update({ id: institutionId, imageId: image.id }, {
                    name: imageSubmit.name,
                    description: imageSubmit.description,
                    thumbnailUrl: imageSubmit.thumbnailUrl,
                    url: imageSubmit.url,
                    orderType: imageSubmit.orderType,
                    assignedTo: imageSubmit.assignedTo
                }).$promise.then(function(data) {
                    if (constants.dev) {
                        $log.log('success, got data: ', data);
                    }
                    return true;
                }, function(error) {
                    //@todo log error
                    if (constants.dev) {
                        $log.log('request failed' + error);
                    }
                    return false;
                });
            } else {
                // create new image
                return imageModel.save({ id: institutionId }, {
                    name: imageSubmit.name,
                    description: imageSubmit.description,
                    url: imageSubmit.url,
                    assignedTo: imageSubmit.assignedTo
                }).$promise.then(function(data) {
                    if (constants.dev) {
                        $log.log('success, got data: ', data);
                    }
                    return data;
                }, function(error) {
                    //@todo log error
                    if (constants.dev) {
                        $log.log('request failed' + error);
                    }
                    return false;
                });
            }
        };

        /**
         * Save image order.
         *
         * @param {string} id - Institution TU profile mongo ID
         * @param {Object} images
         * @returns {Promise}
         */
        service.saveOrder = function(id, images) {
            var institutionModel = service.getImagesOrderingModel();
            return institutionModel.update({ id: id }, {
                'images': service.formatForOrdering(images)
            }).$promise.then(function(data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function(error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        service.formatForOrdering = function(items) {
            var formattedItems = [];
            angular.forEach(items, function(item) {
                formattedItems.push({
                    'id': item.id,
                    'orderType': item.orderType
                });
            });

            return formattedItems;
        };

        /**
         * Delete image.
         *
         * @param institutionId
         * @param image
         * @returns {*}
         */
        service.deleteImage = function(institutionId, image) {
            service.setHasErrors(false);
            var imageModel = service.getImageModel();
            return imageModel.delete({ id: institutionId, imageId: image.id }).$promise.then(function(data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function(error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        /**
         * Set Image Uploading in progress.
         *
         * @param {Boolean} value
         */
        service.setImageUploadingInProgress = function(value) {
            serviceMediaImages.imageUploadingInProgress = value;
        };


        /**
         * Get Image Uploading in progress.
         *
         * @return {Boolean}
         */
        service.getImageUploadingInProgress = function() {
            return serviceMediaImages.imageUploadingInProgress;
        };

        /**
         * Set selected image.
         *
         * @param item
         */
        service.setSelectedImage = function(item) {
            serviceMediaImages.selectedImage = item;
        };

        /**
         * Get selected image
         *
         * @returns {*}
         */
        service.getSelectedImage = function() {
            return serviceMediaImages.selectedImage;
        };

        /**
         * Trigger filter.
         *
         * @param triggerChange
         */
        service.setTriggerChange = function(triggerChange) {
            serviceMediaImages.triggerChange = triggerChange;
        };

        /**
         * Get trigger filter value
         *
         * @returns {*}
         */
        service.getTriggerChange = function() {
            return serviceMediaImages.triggerChange;
        };

        /**
         * Set image items.
         *
         * @param items
         * @param transform
         */
        service.setImageItems = function(items, transform) {
            if (!angular.isDefined(transform)) {
                transform = true;
            }
            if (transform) {
                angular.forEach(items, function(value, key) {
                    items[key][TmMediaService.typeOverview()] = false;
                    angular.forEach(items[key].assignedTo, function(aValue) {
                        if (aValue === TmMediaService.typeOverview()) {
                            items[key][TmMediaService.typeOverview()] = true;
                        }
                    });
                    /**
                     * Set initial order
                     * @type {{master: number, ug: number, pg: number}}
                     */
                    if (items[key].orderType === null) {
                        items[key].orderType = {
                            'master': 0
                        };
                    }
                    var orderOverview = 0;
                    if (angular.isDefined(items[key].orderType)) {
                        if (angular.isDefined(items[key].orderType[TmMediaService.typeOverview()])) {
                            orderOverview = items[key].orderType[TmMediaService.typeOverview()];
                        }
                    }
                    items[key].orderType = {
                        'master': orderOverview
                    };
                    if (angular.isDefined(items[key]._id)) {
                        items[key].id = items[key]._id;
                    }
                    delete items[key].assignedTo;
                    delete items[key]._id;
                });
            }

            serviceMediaImages.imageItems = items;
        };

        /**
         * Get image items.
         *
         * @returns {*}
         */
        service.getImageItems = function() {
            return serviceMediaImages.imageItems;
        };

        /**
         * Get image item.
         * @param {int} defaultValue
         */
        service.getItemImage = function(defaultValue) {
            if (angular.isUndefined(defaultValue)) {
                defaultValue = 0;
            }
            var orderTypes = {};
            orderTypes[TmMediaService.typeOverview()] = defaultValue;

            return {
                'id': '',
                'name': '',
                'description': '',
                'master': true,
                'orderType': orderTypes,
                'url': ''
            };
        };

        /**
         * Reset image item.
         *
         * @param item
         * @returns {*}
         */
        service.resetItem = function(item) {
            item.master = true;
            item.name = '';
            item.description = '';

            return item;
        };

        /**
         * Format images.
         *
         * @param images
         * @returns {*}
         */
        service.formatImages = function(images) {
            angular.forEach(images, function(value, key) {
                images[key] = service.formatImage(value);
            });

            return images;
        };

        /**
         * Format to match db data.
         *
         * @param {Object} image
         * @returns {Object}
         */
        service.formatImage = function(image) {
            image.assignedTo = [];
            if (image.master) {
                image.assignedTo.push('master');
            }
            // remove data we don't want to send.
            if (angular.isDefined(image.id) && parseInt(image.id, 10) === 0) {
                delete image.id;
            }
            delete image.master;
            delete image.order;

            return image;
        };

        /**
         * Sets has errors flag.
         *
         * @param {boolean} hasErrors
         */
        service.setHasErrors = function(hasErrors) {
            serviceMediaImages.hasErrors = hasErrors;
        };

        /**
         * Gets has errors flag.
         *
         * @returns {boolean|*}
         */
        service.getHasErrors = function() {
            return serviceMediaImages.hasErrors;
        };

        /**
         * Sets is highlighted.
         *
         * @param {boolean} isHighlighted
         */
        service.setHighlighted = function(isHighlighted) {
            serviceMediaImages.isHighlighted = isHighlighted;
        };

        /**
         * Gets is Highlighted.
         *
         * @returns {boolean|*}
         */
        service.getHighlighted = function() {
            return serviceMediaImages.isHighlighted;
        };

        service.coreId = function(coreId) {
            serviceMediaImages.coreId = coreId;
        };

        /**
         * Set type.
         *
         * @returns {*}
         */
        service.setType = function(type) {
            serviceMediaImages.type = type;
        };

        /**
         * Get type.
         *
         * @returns {*}
         */
        service.getType = function() {
            return serviceMediaImages.type;
        };

        /**
         * Replace item by item id
         *
         * @param key
         */
        service.replaceByKey = function(key) {
            var imageItems = [];
            angular.copy(service.getImageItems(), imageItems);
            angular.forEach(imageItems, function(itemValue, itemKey) {
                if (itemValue.id === key) {
                    imageItems[itemKey] = service.getSelectedImage();
                }
            });
            service.setImageItems(imageItems);
        };

        /**
         * Trigger filter.
         *
         * @param triggerChange
         */
        service.setTriggerReset = function(triggerChange) {
            serviceMediaImages.triggerChange = triggerChange;
        };

        service.getTriggerReset = function() {
            return serviceMediaImages.triggerChange;
        };

        /**
         * Submit is in progress.
         *
         * @param submitInProgress
         */
        service.setSubmitInProgress = function(submitInProgress) {
            serviceMediaImages.submitInProgress = submitInProgress;
        };

        service.getSubmitInProgress = function() {
            return serviceMediaImages.submitInProgress;
        };

        /**
         * UploadedImagesCount object, contains {master:count, ug: count, pg: count}
         *
         * @param {Object} uploadedImagesCount
         */
        service.setUploadedImagesCount = function(uploadedImagesCount) {
            serviceMediaImages.uploadedImagesCount = uploadedImagesCount;
        };

        service.getUploadedImagesCount = function() {
            return serviceMediaImages.uploadedImagesCount;
        };

        service.getMaxImages = function() {
            return maxImages;
        };

        return service;
    };

    angular
        .module('qsHub')
        .service('TmMediaImagesService', [
            '$resource',
            '$q',
            '$log',
            'constants',
            'TmMediaService',
            App.services.TmMediaImages
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { controllers: {} });

    App.controllers.TmMediaSocialMedias = function(
        $scope,
        constants,
        TmProfileFactory,
        TmMediaSocialMediasService,
        TmMediaService,
        WatchService
    ) {
        var controller = this;
        controller.devMode = constants.dev;
        TmMediaSocialMediasService.coreId = 0;
        $scope.socialMediaItems = TmMediaSocialMediasService.socialMediaItems();
        $scope.itemSocialMedia = {};
        $scope.displayLocationDeletePopup = true;
        $scope.type = $scope.socialMediaItems[0];

        TmMediaSocialMediasService.setType($scope.type);
        WatchService.create($scope, TmProfileFactory.getData, function(newValue) {
            if (newValue !== null) {
                TmMediaSocialMediasService.coreId = newValue.id;
                $scope.socialMediaItems = TmMediaSocialMediasService.processInput(newValue.socialMedia);
                TmMediaSocialMediasService.setSelectedSocialMedia(TmMediaSocialMediasService.flattenArray(newValue.socialMedia));
            }
        });

        WatchService.create($scope, TmMediaSocialMediasService.getSocialMediaItems, function(newValue, oldValue) {
            if (!angular.equals(oldValue, newValue)) {
                $scope.socialMediaItems = newValue;
            }
        });

        WatchService.create($scope, TmMediaSocialMediasService.getChanges, function(newValue) {
            if (angular.isDefined(newValue)) {
                $scope.socialMediaItems[$scope.type] = TmMediaSocialMediasService.flattenArray(newValue, true);
            }
        });

        /**
         * Filter change.
         *
         * @param type
         */
        $scope.filter = function(type) {
            return type;
        };

        /**
         * Select social media.
         *
         * @param {string} type
         */
        $scope.selectSocialMedia = function(type) {
            $scope.type = type;
            TmMediaSocialMediasService.setType(type);
            TmMediaSocialMediasService.setTriggerChange(Math.random()); // reset right column changes
            if (!($scope.socialMediaItems[$scope.type].master || $scope.socialMediaItems[$scope.type].ug || $scope.socialMediaItems[$scope.type].pg)) {
                TmMediaSocialMediasService.setHighlighted(true);
            }
        };

        $scope.selectedItem = function() {
            return TmMediaSocialMediasService.getSelectedSocialMedia();
        };

        $scope.typeOverview = function() {
            return TmMediaService.typeOverview();
        };

        $scope.typeUndergraduate = function() {
            return TmMediaService.typeUndergraduate();
        };

        $scope.typePostgraduate = function() {
            return TmMediaService.typePostgraduate();
        };
    };

    angular
        .module('qsHub')
        .controller('TmMediaSocialMediasController', [
            '$scope',
            'constants',
            'TmProfileFactory',
            'TmMediaSocialMediasService',
            'TmMediaService',
            'WatchService',
            App.controllers.TmMediaSocialMedias
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { controllers: {} });

    App.controllers.TmMediaSocialMediasSidebar = function(
        $scope,
        $timeout,
        NotifierFactory,
        TmProfileFactory,
        TmMediaSocialMediasService,
        WatchService,
        UrlService
    ) {
        var controller = this,
            alreadyInitialised = false;
        $scope.hasErrors = false;
        // $scope.invalidCheckboxes = [];
        $scope.invalidFields = [];
        TmMediaSocialMediasService.displayTypesValid = false;

        controller.onKeyUp = function ($event, type, index) {
            if ($event.keyCode === 13) {
                return;
            }
            var url = $event.target.value;
            if (url && url.length > 5 && (url.indexOf('http') !== 0 || url.indexOf('http') === -1)) {
                url = UrlService.prependHttp(url);
                $scope.selectedSocialMedia[type][index].url = url;
            }
        };

        /**
         * Reset Invalid Url
         *
         * @params {Integer} index
         */
        $scope.resetInvalidField = function(index) {
            $scope.invalidFields[index] = false;
        };

        /**
         * Reset Invalid Checkboxes
         *
         * @params {Integer} index
         */
        $scope.resetInvalidCheckbox = function(index) {
            if ($scope.invalidCheckboxes[index]) {
                $scope.invalidCheckboxes[index] = false;
            }
        };


        controller.urlPattern = UrlService.getPattern();

        /**
         * Save form.
         *
         * @returns {boolean}
         */
        $scope.saveSocialMediaForm = function() {
            $scope.invalidFields = [];
            var invalidItems = [];
            var socialMediaItem = TmMediaSocialMediasService.formatSocialMedia(
                angular.copy($scope.selectedSocialMedia[$scope.type()])
            );
            if (angular.isArray(socialMediaItem) && socialMediaItem.length > 0) {
                invalidItems = TmMediaSocialMediasService.socialMediaValidation(angular.copy($scope.selectedSocialMedia[$scope.type()]));
                if (typeof invalidItems === 'undefined') {
                    invalidItems = TmMediaSocialMediasService.socialMediaFormValidation($scope.forms.formSocialMedia);
                }
            }
            TmMediaSocialMediasService.displayTypesValid = false;
            TmMediaSocialMediasService.setHasErrors(false);

            if ($scope.submitInProgress || !$scope.forms.formSocialMedia || !$scope.forms.formSocialMedia.$valid || (invalidItems && invalidItems.length > 0)) {
                // $scope.invalidCheckboxes = [];
                $scope.hasErrors = true;
                angular.forEach(invalidItems, function(item) {
                    if (item.invalid) {
                        $scope.invalidCheckboxes[item.index] = item.invalid || false;
                    } else if (item.invalidUrl) {
                        $scope.invalidFields[item.index] = item.invalidUrl || false;
                    }
                });
                return false;
            }

            $scope.hasErrors = false;
            $scope.invalidFields = [];
            // $scope.invalidCheckboxes = [];
            $scope.submitInProgress = true;
            var actionName = $scope.isEditMode($scope.type()) ? 'Updated' : 'Added';
            TmMediaSocialMediasService.saveSocialMedia(TmMediaSocialMediasService.coreId, socialMediaItem)
                .then(function(success) {
                    $scope.submitInProgress = false;
                    NotifierFactory.show(
                        success ? 'success' : 'error',
                        success ? 'Successfully!' : 'Failure!',
                        'Social Media ' + actionName + ' (' + $scope.type() + ')'
                    );
                    if (success) {
                        $scope.selectedSocialMediaBeforeChanges = angular.copy($scope.selectedSocialMedia);
                        TmMediaSocialMediasService.setChanges(socialMediaItem);
                    }
                });
        };

        /**
         * Clear social media item.
         */
        $scope.clearSocialMediaForm = function() {
            $scope.selectedSocialMedia[$scope.type()][0].master = true;
            $scope.selectedSocialMedia[$scope.type()][0].url = '';
            $scope.invalidFields = [];
        };

        /**
         * Get selected item.
         *
         * @returns {*}
         */
        $scope.getSelectedSocialMedia = function() {
            return TmMediaSocialMediasService.getSelectedSocialMedia();
        };

        /**
         * Get socialMedia items.
         *
         * @returns {*}
         */
        $scope.getSocialMediaItems = function() {
            return TmMediaSocialMediasService.getSocialMediaItems();
        };

        /**
         * Get has errors flag.
         *
         * @returns {boolean}
         */
        $scope.getHasErrors = function() {
            return TmMediaSocialMediasService.getHasErrors();
        };

        $scope.setHasErrors = function(value) {
            TmMediaSocialMediasService.setHasErrors(value);
        };

        $scope.type = function() {
            return TmMediaSocialMediasService.getType();
        };

        $scope.setDisplayTypesValidation = function(value) {
            TmMediaSocialMediasService.displayTypesValid = value;
        };

        $scope.getDisplayTypesValidation = function() {
            return TmMediaSocialMediasService.displayTypesValid;
        };

        /**
         * Is edit mode?
         *
         * @returns {boolean}
         */
        $scope.isEditMode = function(type) {
            return $scope.selectedSocialMedia && $scope.selectedSocialMedia[type] ? true : false;
        };

        controller.initWatches = function() {
            WatchService.create($scope, TmMediaSocialMediasService.getSelectedSocialMedia, function(selectedSocialMedia) {
                if (selectedSocialMedia) {
                    $scope.selectedSocialMediaBeforeChanges = selectedSocialMedia;
                    $scope.selectedSocialMedia = angular.copy(selectedSocialMedia);
                    // $scope.checkCheckboxes();
                }
            });

            WatchService.create($scope, TmMediaSocialMediasService.getTriggerChange, function() {
                if (angular.isDefined($scope.selectedSocialMediaBeforeChanges)) {
                    $scope.selectedSocialMedia = angular.copy($scope.selectedSocialMediaBeforeChanges);
                    // $scope.checkCheckboxes();
                }
                $scope.invalidFields = [];
                // $scope.invalidCheckboxes = [];
            });

            //watch for social media type changes
            WatchService.create($scope, TmMediaSocialMediasService.getType, function(socialMediaType) {
                if (socialMediaType) {
                    controller.selectedSocialMediaUrl = TmMediaSocialMediasService.getSelectedSocialMediaUrl(socialMediaType);
                }
            });

            WatchService.create($scope, TmMediaSocialMediasService.getHighlighted, function(newValue) {
                if (newValue) {
                    $scope.isHighlighted = newValue;
                    $timeout(function() {
                        TmMediaSocialMediasService.setHighlighted(false);
                        $scope.isHighlighted = false;
                    }, 200);
                }
            });

            WatchService.create($scope, TmProfileFactory.getSelectedMediaSubTabId, function(tabId) {
                if (tabId === 3) {
                    $timeout(function() {
                        TmMediaSocialMediasService.setHighlighted(true);
                    }, 200);
                } else {
                    $scope.invalidFields = [];
                    // $scope.invalidCheckboxes = [];
                }
            });
        };

        controller.init = function() {
            controller.initWatches();
        };

        // listen to social media tab visibility changes
        WatchService.create($scope, TmProfileFactory.isMediaTabSelected, function(isActive) {
            // if not already initialized and tab is active
            if (!alreadyInitialised && isActive) {
                controller.init();
                alreadyInitialised = true;
            }
        });
    };

    angular
        .module('qsHub')
        .controller('TmMediaSocialMediasSidebar', [
            '$scope',
            '$timeout',
            'NotifierFactory',
            'TmProfileFactory',
            'TmMediaSocialMediasService',
            'WatchService',
            'UrlService',
            App.controllers.TmMediaSocialMediasSidebar
        ]);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { services: {} });

    App.services.TmMediaSocialMediasService = function ($resource, $q, $log, constants, TmMediaService, UrlService) {
        var service = {};
        var imageUrl = '/images/media/media-social-media.png';
        var defaultName = '';
        var socialMediaItems = [
            'facebook',
            'youtube',
            'linkedin',
            'twitter',
            'other'
        ];

        service.getSocialMediaModel = function () {
            return $resource(constants.api.institutions.url + '/v1/tm-profile/:id/social-media/:socialMediaId', null, {
                update: { method: 'PATCH' }
            });
        };

        service.getInstitutionModel = function () {
            return $resource(constants.api.institutions.url + '/v1/tm-profile/:id', null, {
                update: { method: 'PATCH' }
            });
        };

        /**
         * Save SocialMedia
         *
         * @param institutionId
         * @param socialMedia
         * @returns {*}
         */
        service.saveSocialMedia = function (institutionId, socialMedia) {
            var socialMediaModel = service.getSocialMediaModel();

            return socialMediaModel.update({ id: institutionId, socialMediaId: service.getType() },
                socialMedia
            ).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        /**
         * Set selected socialMedia.
         *
         * @param {Object} item
         */
        service.setSelectedSocialMedia = function (item) {
            service.selectedSocialMedia = item;
        };

        /**
         * Get selected socialMedia
         *
         * @returns {Object}
         */
        service.getSelectedSocialMedia = function () {
            return service.selectedSocialMedia;
        };

        /**
         * Trigger change.
         *
         * @param triggerChange
         */
        service.setTriggerChange = function (triggerChange) {
            service.triggerChange = triggerChange;
        };

        /**
         * Get trigger change value
         *
         * @returns {boolean}
         */
        service.getTriggerChange = function () {
            return service.triggerChange;
        };

        /**
         * Get socialMedia items.
         *
         * @returns {Object}
         */
        service.getSocialMediaItems = function () {
            return service.socialMediaItems;
        };

        service.setSocialMediaItems = function (items) {
            service.socialMediaItems = items;
        };

        /**
         * Get socialMedia item.
         */
        service.getItemSocialMedia = function () {
            return {
                'id': '',
                'name': defaultName,
                'description': '',
                'master': true,
                'url': '',
                'imageUrl': imageUrl
            };
        };

        /**
         * get selected social media url.
         *
         * @param {String} type
         * @returns {String}
         */
        service.getSelectedSocialMediaUrl = function (type) {
            switch (type) {
                case 'facebook':
                    return 'https://www.facebook.com/universityrankings';
                case 'youtube':
                    return 'https://www.youtube.com/topuniversities';
                case 'linkedin':
                    return 'https://www.linkedin.com/company/qs';
                case 'twitter':
                    return 'https://twitter.com/worlduniranking';
                default:
                    return 'http://www.yourwebsite.com';
            }
        };

        /**
         * Reset socialMedia item.
         *
         * @returns {Object}
         * @param item
         */
        service.resetItem = function (item) {
            item.master = true;
            item.url = '';
            item.name = defaultName;
            item.imageUrl = imageUrl;

            return item;
        };

        /**
         * Format socialMedias.
         *
         * @param socialMedias
         * @returns {Object}
         */
        service.formatSocialMedias = function (socialMedias) {
            angular.forEach(socialMedias, function (value, key) {
                socialMedias[key] = service.formatSocialMedia(value);
            });

            return socialMedias;
        };

        /**
         * Validate Social Media data
         *
         * @param {Object} socialMediaItem
         * @returns {Object}
         */
        service.socialMediaValidation = function (socialMediaItem) {
            var invalidItems = [];
            angular.forEach(socialMediaItem, function (item, key) {
                if ((item.url !== "") && !(item.master)) {
                    socialMediaItem[key].url = "";
                } else if ((item.url !== "") && !(item.master)) {
                    invalidItems.push({
                        invalid: true,
                        index: key
                    });
                } else if (((item.url === "") || (item.url === undefined)) && (item.master)) {
                    invalidItems.push({
                        invalidUrl: true,
                        index: key
                    });
                }
                else if (!(item.url.match(UrlService.getPattern())) && (item.master || item.pg || item.ug)){
                    invalidItems.push({
                        invalidUrl: true,
                        index: key
                    });
                }

            });
            if (invalidItems.length > 0) {
                return invalidItems;
            }
        };

        service.socialMediaFormValidation = function (socialMediaItem) {
            var invalidItems = [];
            if (angular.isDefined(socialMediaItem)) {
                angular.forEach(socialMediaItem.$error.url, function (error) {
                    var key = error.$name.charAt(error.$name.length - 1);
                    invalidItems.push({
                        invalidUrl: true,
                        index: key
                    });
                });
            }

            return invalidItems;
        };

        /**
         * Format to match db data.
         *
         * @param {Object} socialMediaItem
         * @returns {Object}
         */
        service.formatSocialMedia = function (socialMediaItem) {
            var output = [];

            angular.forEach(socialMediaItem, function (item, key) {
                if ((item.master) && item.url !== "") { // item is set to profile, we'll save it
                    var tmpOutput = {};
                    tmpOutput.url = item.url;
                    tmpOutput.assignedTo = [];
                    if (item.master) {
                        tmpOutput.assignedTo.push('master');
                    }
                    output.push(tmpOutput);
                }
            });
            return output;
        };

        service.setChanges = function (changes) {
            service.changes = changes;
        };

        service.getChanges = function () {
            return service.changes;
        };

        /**
         * Sets has errors flag.
         *
         * @param {boolean} hasErrors
         */
        service.setHasErrors = function (hasErrors) {
            service.hasErrors = hasErrors;
        };

        /**
         * Gets has errors flag.
         *
         * @returns {boolean|*}
         */
        service.getHasErrors = function () {
            return service.hasErrors;
        };

        /**
         * Sets is highlighted.
         *
         * @param {boolean} isHighlighted
         */
        service.setHighlighted = function (isHighlighted) {
            service.isHighlighted = isHighlighted;
        };

        /**
         * Gets is Highlighted.
         *
         * @returns {boolean|*}
         */
        service.getHighlighted = function () {
            return service.isHighlighted;
        };

        service.coreId = function (coreId) {
            service.coreId = coreId;
        };

        /**
         * Set type.
         *
         * @returns {*}
         */
        service.setType = function (type) {
            service.type = type;
        };

        /**
         * Get type.
         *
         * @returns {*}
         */
        service.getType = function () {
            return service.type;
        };

        service.socialMediaItems = function () {
            return socialMediaItems;
        };

        service.getSocialMediaObject = function () {
            var socialMedia = {};
            angular.forEach(socialMediaItems, function (mediaType) {
                socialMedia[mediaType] = {};
                angular.forEach(TmMediaService.getTypes(), function (profileType) {
                    socialMedia[mediaType][profileType] = false;
                });
            });

            return socialMedia;
        };

        service.assignData = function (inputData, socialMedia) {
            angular.forEach(inputData, function (mediaType, mediaTypeValue) {
                angular.forEach(mediaType, function () {
                    socialMedia[mediaTypeValue][TmMediaService.typeOverview()] = true;
                });
            });

            return socialMedia;
        };

        service.processInput = function (inputData) {
            var socialMedia = service.getSocialMediaObject();
            socialMedia = service.assignData(inputData, socialMedia);

            return socialMedia;
        };

        /**
         * Flattens inputData for left column
         *
         * @param {Object} inputData
         * @param {boolean} forType
         * @returns {*}
         */
        service.flattenArray = function (inputData, forType) {
            if (angular.isUndefined(forType)) {
                forType = false;
            }
            var typeMaster = TmMediaService.typeOverview();

            if (forType) {
                var outputData = {};

                angular.forEach(inputData, function (inputDataValue, inputDataKey) {
                    if (angular.isDefined(inputDataValue.assignedTo)) {
                        angular.forEach(inputData[inputDataKey].assignedTo, function (assignedType) {
                            if (assignedType === typeMaster) {
                                outputData[typeMaster] = true;
                            }
                        });
                    }
                });

                inputData = outputData;
            } else {
                angular.forEach(socialMediaItems, function (mediaType) {
                    if (angular.isDefined(inputData) && angular.isDefined(inputData[mediaType])) {
                        angular.forEach(inputData[mediaType], function (objectData, dataKey) {
                            inputData[mediaType][dataKey][typeMaster] = true;
                            inputData[mediaType][dataKey].display = true;
                            if (angular.isDefined(inputData[mediaType][dataKey].assignedTo)) {
                                angular.forEach(inputData[mediaType][dataKey].assignedTo, function (assignedType) {
                                    if (assignedType === typeMaster) {
                                        inputData[mediaType][dataKey][typeMaster] = true;
                                    }
                                });
                                delete (inputData[mediaType][dataKey].assignedTo);
                            }
                        });
                        if (inputData[mediaType].length === 0) {
                            inputData[mediaType] = [];
                            inputData[mediaType].push(service.getEmpty(true, true));
                        }
                    } else {
                        if (angular.isUndefined(inputData) || angular.isArray(inputData)) {
                            inputData = {};
                        }
                        inputData[mediaType] = [];
                        inputData[mediaType].push(service.getEmpty(true, true));
                    }
                });
            }

            return inputData;
        };

        /**
         *
         * @param {boolean} initialValue
         * @param {boolean} displayValue
         * @returns {{url: string, display: boolean, master: boolean, ug: boolean, pg: boolean}}
         */
        service.getEmpty = function (initialValue, displayValue) {
            return {
                'url': '',
                'display': displayValue,
                'master': initialValue
            };
        };

        service.resetDataForType = function (data, type) {
            angular.forEach(data, function (dataValue, dataKey) {
                if (dataKey === type) {
                    angular.forEach(data[dataKey], function (dataSubValue, dataSubKey) {
                        data[dataKey][dataSubKey].master = false;
                        data[dataKey][dataSubKey].url = '';
                    });
                }
            });

            return data;
        };

        service.assignNewData = function (values, type, currentlySelected) {
            var outputValues = angular.copy(values);
            angular.forEach(values, function (objectData, valueKey) {
                if (angular.isDefined(objectData.assignedTo)) {
                    outputValues[valueKey][TmMediaService.typeOverview()] = false;
                    outputValues[valueKey][TmMediaService.typeUndergraduate()] = false;
                    outputValues[valueKey][TmMediaService.typePostgraduate()] = false;
                    angular.forEach(objectData.assignedTo, function (assignedType) {
                        outputValues[valueKey][assignedType] = true;
                    });
                    delete (outputValues[valueKey].assignedTo);
                }
            });
            var copySelected = angular.copy(currentlySelected);
            angular.forEach(copySelected, function (mediaType, mediaTypeKey) {
                if (mediaTypeKey === type) {
                    copySelected[mediaTypeKey] = outputValues;
                }
            });

            return copySelected;
        };

        service.applyUpdate = function (type, values) {
            var currentlySelected = service.getSelectedSocialMedia();

            return service.assignNewData(values, type, currentlySelected);
        };

        return service;
    };

    angular
        .module('qsHub')
        .service('TmMediaSocialMediasService', [
            '$resource',
            '$q',
            '$log',
            'constants',
            'TmMediaService',
            'UrlService',
            App.services.TmMediaSocialMediasService
        ]);

} (window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers: {}});

    App.controllers.TmMediaBrochures = function (
        $scope,
        constants,
        TmProfileFactory,
        NotifierFactory,
        TmMediaBrochuresService,
        TmMediaService,
        ModalService,
        $filter,
        orderBy,
        WatchService
    ) {
        var controller = this;
        controller.devMode = constants.dev;
        TmMediaBrochuresService.coreId = 0;
        $scope.brochureItems = [];
        $scope.itemBrochure = {};
        $scope.displayLocationDeletePopup = true;
        $scope.type = TmMediaService.typeOverview();

        TmMediaBrochuresService.setType(TmMediaService.typeOverview());

        WatchService.create($scope, TmProfileFactory.getData, function (newValue) {
            if (newValue !== null) {
                TmMediaBrochuresService.setSelectedBrochure(TmMediaBrochuresService.getItemBrochure());
                angular.copy(newValue.brochures, $scope.brochureItems);
                TmMediaBrochuresService.coreId = newValue.id;
                TmMediaBrochuresService.setBrochureItems($scope.brochureItems);
                $scope.filteredBrochureItems = $filter('filter')($scope.brochureItems, {master: true});
                $scope.filteredBrochureItems = orderBy($scope.filteredBrochureItems, 'orderType.' + $scope.type, false);
            }
        });

        WatchService.create($scope, TmMediaBrochuresService.getBrochureItems, function (newValue, oldValue) {
            if (!angular.equals(oldValue, newValue)) {
                $scope.brochureItems = newValue;
            }
        });

        WatchService.create($scope, TmMediaBrochuresService.getTriggerChange, function () {
            if (angular.isDefined($scope.selectedItemId()) && $scope.selectedItemId().length > 0) {
                TmMediaBrochuresService.replaceByKey($scope.selectedItemId());
            }
            var selectedBrochure = TmMediaBrochuresService.getSelectedBrochure();
            $scope.filter('master');
        });

        $scope.sortableOptions = {
            disabled: false,
            'ui-floating': false,
            start: function () {
                controller.initialList = [];
                angular.copy($scope.filteredBrochureItems, controller.initialList);
            },
            update: function (e, ui) {
                var validateItems = TmMediaService.validateTypes($scope.brochureItems);
                if (true !== validateItems) {
                    ui.item.sortable.cancel();
                }
            },
            stop: function () {
                var validateItems = TmMediaService.validateTypes($scope.brochureItems);
                if (true === validateItems) {
                    var assignedOrder = 1;
                    // change brochures order
                    for (var index = 0; index < $scope.filteredBrochureItems.length; index++) {
                        if ($scope.filteredBrochureItems[index][$scope.type]) {
                            $scope.filteredBrochureItems[index].orderType[$scope.type] = assignedOrder;
                            assignedOrder++;
                        }
                    }

                    $scope.sortableOptions.disabled = false;
                    TmMediaBrochuresService.saveOrder(TmMediaBrochuresService.coreId, $scope.brochureItems)
                        .then(function (success) {
                            NotifierFactory.show(
                                success ? 'success' : 'error',
                                success ? 'Success!' : 'Failure!',
                                'Brochure Order Save'
                            );
                            TmMediaBrochuresService.setSelectedBrochure(TmMediaBrochuresService.getItemBrochure());
                        })
                        .finally(function () {
                            $scope.sortableOptions.disabled = false;
                        });
                } else {
                    NotifierFactory.show(
                        'error',
                        'Failure!',
                        'Invalid items in brochure list'
                    );
                    TmMediaBrochuresService.setSelectedBrochure(validateItems);
                    TmMediaBrochuresService.displayTypesValid = true;

                    return false;
                }
            }
        };

        /**
         * Filter change.
         *
         * @param type
         */
        $scope.filter = function (type) {
            var validateItems = TmMediaService.validateTypes($scope.brochureItems);
            if (true === validateItems) {
                $scope.type = type;
                var params = {};
                params[type] = true;
                $scope.filteredBrochureItems = $filter('filter')($scope.brochureItems, params);
                $scope.filteredBrochureItems = orderBy($scope.filteredBrochureItems, 'orderType.' + type, false);
            } else {
                NotifierFactory.show(
                    'error',
                    'Failure!',
                    'Invalid items in brochure list'
                );
                TmMediaBrochuresService.setSelectedBrochure(validateItems);
                TmMediaBrochuresService.displayTypesValid = true;

                return false;
            }
        };

        /**
         * Select brochure.
         *
         * @param {Object} item
         */
        $scope.selectBrochure = function (item) {
            item = angular.copy(item);
            var validateItems = TmMediaService.validateTypes($scope.brochureItems);
            if (true === validateItems) {
                TmMediaBrochuresService.setHasErrors(false);
                TmMediaBrochuresService.displayTypesValid = false;
                $scope.itemBrochure = TmMediaBrochuresService.getItemBrochure();
                if (angular.isDefined(item)) {
                    TmMediaBrochuresService.setSelectedBrochure(item);
                } else {
                    TmMediaBrochuresService.setHasErrors(false);
                    TmMediaBrochuresService.setHighlighted(true);
                    TmMediaBrochuresService.setSelectedBrochure($scope.itemBrochure);
                }
                TmMediaBrochuresService.setTriggerReset(Math.random());
            } else {
                NotifierFactory.show(
                    'error',
                    'Failure!',
                    'Invalid items in brochure list'
                );
                TmMediaBrochuresService.setSelectedBrochure(validateItems);
                TmMediaBrochuresService.displayTypesValid = true;

                return false;
            }
        };

        $scope.selectedItem = function () {
            return TmMediaBrochuresService.getSelectedBrochure();
        };

        $scope.selectedItemId = function () {
            return TmMediaBrochuresService.getSelectedBrochure().id;
        };

        /**
         * Delete brochure.
         *
         * @param item
         */
        $scope.deleteBrochure = function (item) {
            var itemName = angular.isDefined(item.name) && item.name !== null ? ': ' + item.name : '';
            var modalOptions = {
                closeButtonText: 'Cancel',
                actionButtonText: 'Yes',
                headerText: 'Delete Brochure' + itemName + ' ?',
                bodyText: 'Are you sure you wish to delete this brochure?',
            };
            ModalService.show({}, modalOptions).then(function (result) {
                TmMediaBrochuresService.deleteBrochure(TmMediaBrochuresService.coreId, item)
                    .then(function (success) {
                        $scope.submitInProgress = false;
                        NotifierFactory.show(
                            success ? 'success' : 'error',
                            success ? 'Success!' : 'Failure!',
                            'Delete Brochure'
                        );
                        if (success) {
                            TmMediaBrochuresService.setSelectedBrochure(TmMediaBrochuresService.getItemBrochure());
                            $scope.brochureItems.splice(TmMediaService.objectSearch($scope.brochureItems, item.id), 1);
                            $scope.filteredBrochureItems.splice(TmMediaService.objectSearch($scope.filteredBrochureItems, item.id), 1);


                            var assignedOrder = 1;
                            for (var index = 0; index < $scope.filteredBrochureItems.length; index++) {
                                if ($scope.filteredBrochureItems[index][$scope.type]) {
                                    $scope.filteredBrochureItems[index].orderType[$scope.type] = assignedOrder;
                                    assignedOrder++;
                                }
                            }
                            $scope.filteredBrochureItems = orderBy($scope.filteredBrochureItems, 'orderType.' + $scope.type, false);
                        }
                    });
            });
        };

        $scope.typeOverview = function () {
            return TmMediaService.typeOverview();
        };

    };

    angular
        .module('qsHub')
        .controller('TmMediaBrochuresController', [
            '$scope',
            'constants',
            'TmProfileFactory',
            'NotifierFactory',
            'TmMediaBrochuresService',
            'TmMediaService',
            'ModalService',
            '$filter',
            'orderByFilter',
            'WatchService',
            App.controllers.TmMediaBrochures
        ]);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { controllers: {} });

    App.controllers.TmMediaBrochuresSidebar = function (
        $scope,
        $timeout,
        NotifierFactory,
        TmProfileFactory,
        TmMediaBrochuresService,
        TmMediaService,
        WatchService,
        UrlService,
        ObjectService
    ) {
        var controller = this,
            alreadyInitialised = false;
        $scope.hasErrors = true;
        TmMediaBrochuresService.displayTypesValid = false;
        TmMediaBrochuresService.setHasErrors(false);

        controller.onKeyUp = function ($event) {
            if ($event.keyCode === 13) {
                return;
            }
            var url = $event.target.value,
                variableName = $event.target.getAttribute('ng-model');

            if (url && url.length > 5 && (url.indexOf('http') !== 0 || url.indexOf('http') === -1)) {
                url = UrlService.prependHttp(url);
                if (variableName) {
                    ObjectService.set($scope, variableName, url);
                }
            }
        };

        controller.urlPattern = UrlService.getPattern();

        /**
         * Save brochure form.
         *
         * @param item
         * @returns {boolean}
         */
        $scope.saveBrochureForm = function (item) {
            var brochureItem = angular.copy(item);
            TmMediaBrochuresService.displayTypesValid = false;
            TmMediaBrochuresService.setHasErrors(false);
            $scope.isInvalidTitle = false;
            $scope.isInvalidUrl = false;
            var actionName = 'Added';
            if (brochureItem.id.length > 0) {
                actionName = 'Updated';
            }
            if (!TmMediaService.isValidType(item)) {
                TmMediaBrochuresService.displayTypesValid = true;
            }
            if (!angular.isDefined(item.name) || !(angular.isDefined(item.name) && item.name.length > 0)) {
                $scope.isInvalidTitle = true;
            }
            if (!angular.isDefined(item.url) || !(angular.isDefined(item.url) && item.url.length > 0)) {
                $scope.isInvalidUrl = true;
            }
            if ($scope.isInvalidTitle || $scope.isInvalidUrl || TmMediaBrochuresService.displayTypesValid) {
                $scope.setHasErrors(true);
                return false;
            }
            if (!(item.url.match(controller.urlPattern))) {
                $scope.isInvalidUrl = true;
                $scope.setHasErrors(true);
                return false;
            }
            if ($scope.submitInProgress || !$scope.forms.formBrochure || !$scope.forms.formBrochure.$valid
            ) {
                NotifierFactory.show(
                    'error',
                    'Error: Please fill in all required fields',
                    actionName + ' Brochure'
                );

                return false;
            }
            $scope.submitInProgress = true;
            TmMediaBrochuresService.saveBrochure(TmMediaBrochuresService.coreId, brochureItem)
                .then(function (success) {
                    $scope.submitInProgress = false;
                    NotifierFactory.show(
                        success ? 'success' : 'error',
                        success ? 'Successfully!' : 'Failure!',
                        'Brochure ' + actionName
                    );
                    if (success) {
                        if (brochureItem.id.length === 0) {
                            item.id = success;
                            TmMediaBrochuresService.getBrochureItems().unshift(item);
                            TmMediaBrochuresService.setSelectedBrochure(item);
                            $scope.selectedBrochure = angular.copy(TmMediaBrochuresService.getSelectedBrochure());
                        }

                        TmMediaBrochuresService.setSelectedBrochure(item);
                        TmMediaBrochuresService.setHasErrors(false);
                        $scope.selectedBrochureBeforeChanges = item;
                    }
                    /**
                     * Tell other controllers to apply filter on changes
                     */
                    TmMediaBrochuresService.setTriggerChange(Math.random());
                });
        };

        /**
         * Clear item.
         *
         * @param item
         */
        $scope.clearBrochureForm = function (item) {
            if (angular.isDefined(item)) {
                $scope.itemBrochure = item;
            } else {
                $scope.itemBrochure = TmMediaBrochuresService.getItemBrochure();
            }

            $scope.itemBrochure = TmMediaBrochuresService.resetItem($scope.itemBrochure);
            TmMediaBrochuresService.setSelectedBrochure($scope.itemBrochure);
            $scope.setDisplayTypesValidation(false);
            delete $scope.isInvalidTitle;
            delete $scope.isInvalidUrl;
        };

        /**
         * Get selected item.
         *
         * @returns {*}
         */
        $scope.getSelectedBrochure = function () {
            return TmMediaBrochuresService.getSelectedBrochure();
        };

        /**
         * Get brochure items.
         *
         * @returns {*}
         */
        $scope.getBrochureItems = function () {
            return TmMediaBrochuresService.getBrochureItems();
        };

        /**
         * Get has errors flag.
         *
         * @returns {boolean|*}
         */
        $scope.getHasErrors = function () {
            return TmMediaBrochuresService.getHasErrors();
        };

        $scope.setHasErrors = function (value) {
            TmMediaBrochuresService.setHasErrors(value);
        };

        $scope.setIsInvalidTitle = function (value) {
            $scope.isInvalidTitle = value;
        };

        $scope.setIsInvalidUrl = function (value) {
            $scope.isInvalidUrl = value;
        };

        $scope.type = function () {
            return TmMediaBrochuresService.getType();
        };

        $scope.setDisplayTypesValidation = function (value) {
            TmMediaBrochuresService.displayTypesValid = value;
        };

        $scope.getDisplayTypesValidation = function () {
            return TmMediaBrochuresService.displayTypesValid;
        };

        /**
         * Is edit mode?
         *
         * @returns {boolean}
         */
        $scope.isEditMode = function () {
            return $scope.selectedBrochure && $scope.selectedBrochure.id ? true : false;
        };

        controller.initWatches = function () {
            WatchService.create($scope, TmMediaBrochuresService.getSelectedBrochure, function (selectedBrochure) {
                $scope.isInvalidUrl = false;
                $scope.isInvalidTitle = false;
                $scope.selectedBrochureBeforeChanges = angular.copy(selectedBrochure);
                $scope.selectedBrochure = selectedBrochure;
            });

            WatchService.create($scope, TmMediaBrochuresService.getHasErrors, function (newValue, oldValue) {
                if (!angular.equals(oldValue, newValue)) {
                    $scope.hasErrors = newValue;
                }
            });

            WatchService.create($scope, TmMediaBrochuresService.getTriggerReset, function (newValue, oldValue) {
                if (!angular.equals(oldValue, newValue)) {
                    $scope.selectedBrochure = angular.copy($scope.selectedBrochureBeforeChanges);
                }
            });

            WatchService.create($scope, TmMediaBrochuresService.getHighlighted, function (newValue) {
                if (newValue) {
                    $scope.isHighlighted = newValue;
                    $timeout(function () {
                        TmMediaBrochuresService.setHighlighted(false);
                        $scope.isHighlighted = false;
                    }, 200);
                }
            });

            WatchService.create($scope, TmProfileFactory.getSelectedMediaSubTabId, function (tabId) {
                if (tabId === 4) {
                    $timeout(function () {
                        TmMediaBrochuresService.setHighlighted(true);
                    }, 200);
                } else {
                    delete $scope.isInvalidTitle;
                    delete $scope.isInvalidUrl;
                    delete TmMediaBrochuresService.displayTypesValid;
                }
            });

        };

        controller.init = function () {
            controller.initWatches();
        };

        // listen to departments tab visibility changes
        WatchService.create($scope, TmProfileFactory.isMediaTabSelected, function (isActive) {
            // if not already initialized and tab is active
            if (!alreadyInitialised && isActive) {
                controller.init();
                alreadyInitialised = true;
            }
        });
    };

    angular
        .module('qsHub')
        .controller('TmMediaBrochuresSidebarController', [
            '$scope',
            '$timeout',
            'NotifierFactory',
            'TmProfileFactory',
            'TmMediaBrochuresService',
            'TmMediaService',
            'WatchService',
            'UrlService',
            'ObjectService',
            App.controllers.TmMediaBrochuresSidebar
        ]);

} (window.angular));
(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services: {}});

    App.services.TmMediaBrochuresService = function (
        $resource,
        $log,
        constants,
        TmMediaService,
        TmMediaVideosService
    ) {
        var service = {};
        service.triggerChange = 0;

        service.getBrochureModel = function () {
            return $resource(constants.api.institutions.url + '/v1/tm-profile/:id/brochure/:brochureId', null, {
                update: {method: 'PATCH'}
            });
        };

        service.getBrochuresOrderingModel = function () {
            return $resource(constants.api.institutions.url + '/v1/tm-profile/:id/brochuresOrder', null, {
                update: {method: 'PATCH'}
            });
        };

        /**
         * Save Brochure
         *
         * @param institutionId
         * @param brochure
         * @returns {*}
         */
        service.saveBrochure = function (institutionId, brochure) {
            var brochureModel = service.getBrochureModel(),
                brochureSubmit = service.formatBrochure(brochure);
            if (angular.isDefined(brochureSubmit.id) && brochureSubmit.id.length > 0) {
                // update brochure
                return brochureModel.update(
                    {id: institutionId, brochureId: brochure.id},
                    {
                        name: brochureSubmit.name,
                        url: brochureSubmit.url,
                        orderType: brochureSubmit.orderType,
                        assignedTo: brochureSubmit.assignedTo
                    }
                ).$promise.then(function (data) {
                    if (constants.dev) {
                        $log.log('success, got data: ', data);
                    }
                    return true;
                }, function (error) {
                    //@todo log error
                    if (constants.dev) {
                        $log.log('request failed' + error);
                    }
                    return false;
                });
            } else {
                // create new brochure
                return brochureModel.save(
                    {id: institutionId, brochureId: brochureSubmit.id},
                    {
                        name: brochureSubmit.name,
                        url: brochureSubmit.url,
                        orderType: brochureSubmit.orderType,
                        assignedTo: brochureSubmit.assignedTo
                    }
                ).$promise.then(function (data) {
                    if (constants.dev) {
                        $log.log('success, got data: ', data);
                    }
                    return data.insertedId;
                }, function (error) {
                    //@todo log error
                    if (constants.dev) {
                        $log.log('request failed' + error);
                    }
                    return false;
                });
            }
        };

        /**
         * Save brochure order.
         *
         * @param {string} id - Institution TU profile mongo ID
         * @param {Object} brochures
         * @returns {Promise}
         */
        service.saveOrder = function (id, brochures) {
            var institutionModel = service.getBrochuresOrderingModel();

            return institutionModel.update(
                {id: id},
                {'brochures': TmMediaVideosService.formatForOrdering(brochures)}
            ).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        /**
         * Delete brochure.
         *
         * @param institutionId
         * @param brochure
         * @returns {*}
         */
        service.deleteBrochure = function (institutionId, brochure) {
            service.setHasErrors(false);
            var brochureModel = service.getBrochureModel();
            return brochureModel.delete(
                {id: institutionId, brochureId: brochure.id}
            ).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        /**
         * Set selected brochure.
         *
         * @param item
         */
        service.setSelectedBrochure = function (item) {
            service.selectedBrochure = item;
        };

        service.replaceByKey = function (key) {
            angular.forEach(service.getBrochureItems(), function(itemValue, itemKey) {
                if(itemValue.id === key) {
                    service.getBrochureItems()[itemKey] = service.getSelectedBrochure();
                }
            });
        };

        /**
         * Get selected brochure
         *
         * @returns {*}
         */
        service.getSelectedBrochure = function () {
            return service.selectedBrochure;
        };

        /**
         * Trigger filter.
         *
         * @param triggerChange
         */
        service.setTriggerChange = function (triggerChange) {
            service.triggerChange = triggerChange;
        };

        /**
         * Get trigger filter value
         *
         * @returns {*}
         */
        service.getTriggerChange = function () {
            return service.triggerChange;
        };

        /**
         * Trigger filter.
         *
         * @param triggerChange
         */
        service.setTriggerReset = function (triggerChange) {
            service.triggerChange = triggerChange;
        };

        /**
         * Get trigger filter value
         *
         * @returns {*}
         */
        service.getTriggerReset = function () {
            return service.triggerChange;
        };

        /**
         * Set brochure items.
         *
         * @param items
         * @param transform
         */
        service.setBrochureItems = function (items, transform) {
            if (!angular.isDefined(transform)) {
                transform = true;
            }
            if (transform) {
                var typeMaster = TmMediaService.typeOverview();

                angular.forEach(items, function (value, key) {
                    items[key][typeMaster] = true;
                    angular.forEach(items[key].assignedTo, function (aValue) {
                        if (aValue === typeMaster) {
                            items[key][typeMaster] = true;
                        }
                    });
                    /**
                     * Set initial order
                     * @type {{master: number, ug: number, pg: number}}
                     */
                    if (items[key].orderType === null) {
                        items[key].orderType = {
                            'master': 0
                        };
                    }
                    var orderOverview = 0;
                    if (angular.isDefined(items[key].orderType)) {
                        if (angular.isDefined(items[key].orderType[typeMaster])) {
                            orderOverview = items[key].orderType[typeMaster];
                        }
                    }
                    items[key].orderType = {
                        'master': orderOverview
                    };
                    if (angular.isDefined(items[key]._id)) {
                        items[key].id = items[key]._id;
                    }
                    delete items[key].assignedTo;
                    delete items[key]._id;
                });
            }

            service.brochureItems = items;
        };

        /**
         * Get brochure items.
         *
         * @returns {*}
         */
        service.getBrochureItems = function () {
            return service.brochureItems;
        };

        /**
         * Get brochure item.
         */
        service.getItemBrochure = function () {
            var orderTypes = {};
            orderTypes[TmMediaService.typeOverview()] = 0;
            return {
                'id': '',
                'name': '',
                'description': '',
                'master': true,
                'orderType': orderTypes,
                'url': '',
                'imageUrl': null
            };
        };

        /**
         * Reset brochure item.
         *
         * @param item
         * @returns {*}
         */
        service.resetItem = function (item) {
            item.master = true;
            item.url = '';
            item.name = '';
            item.imageUrl = null;

            return item;
        };

        /**
         * Format brochures.
         *
         * @param brochures
         * @returns {*}
         */
        service.formatBrochures = function (brochures) {
            angular.forEach(brochures, function (value, key) {
                brochures[key] = service.formatBrochure(value);
            });

            return brochures;
        };

        /**
         * Format to match db data.
         *
         * @param {Object} brochure
         * @returns {Object}
         */
        service.formatBrochure = function (brochure) {
            brochure.assignedTo = [];
            if (brochure.master) {
                brochure.assignedTo.push('master');
            }
            /**
             * Remove data we don't want to send.
             */
            if (angular.isDefined(brochure.id) && parseInt(brochure.id, 10) === 0) {
                delete brochure.id;
            }
            delete brochure.master;
            delete brochure.order;

            return brochure;
        };

        /**
         * Sets has errors flag.
         *
         * @param {boolean} hasErrors
         */
        service.setHasErrors = function (hasErrors) {
            service.hasErrors = hasErrors;
        };

        /**
         * Gets has errors flag.
         *
         * @returns {boolean|*}
         */
        service.getHasErrors = function () {
            return service.hasErrors;
        };

        /**
         * Sets is highlighted.
         *
         * @param {boolean} isHighlighted
         */
        service.setHighlighted = function (isHighlighted) {
            service.isHighlighted = isHighlighted;
        };

        /**
         * Gets is Highlighted.
         *
         * @returns {boolean|*}
         */
        service.getHighlighted = function () {
            return service.isHighlighted;
        };

        service.coreId = function (coreId) {
            service.coreId = coreId;
        };

        /**
         * Set type.
         *
         * @returns {*}
         */
        service.setType = function (type) {
            service.type = type;
        };

        /**
         * Get type.
         *
         * @returns {*}
         */
        service.getType = function () {
            return service.type;
        };

        return service;
    };

    angular
        .module('qsHub')
        .service('TmMediaBrochuresService', [
            '$resource',
            '$log',
            'constants',
            'TmMediaService',
            'TmMediaVideosService',
            App.services.TmMediaBrochuresService
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    angular
        .module('qsHub')
        .controller('TuProfilePublishController', [
            '$scope',
            'constants',
            'TuProfilePublishService',
            'InstitutionFactory',
            'TuProfileFactory',
            'TuProfileHistoryLogFactory',
            'UserFactory',
            'WebSocketsService',
            'WatchService',
            'ModalService',
            'StarsClientService',
            'CoursesService',
            'MatchingToolFactory',
            Controller
        ]);

    function Controller(
        $scope,
        constants,
        PublishService,
        InstitutionFactory,
        TuProfileFactory,
        TuProfileHistoryLogFactory,
        UserFactory,
        WebSocketsService,
        WatchService,
        ModalService,
        StarsClientService,
        CoursesService,
        MatchingToolFactory
    ) {
        var controller = this,
            alreadyInitialised = false,
            coursesPublishStatusSubscriptions = [],
            coursePublishStatus = [],
            totalToLoad= 3,
            leftToLoad = angular.copy(totalToLoad);

        controller.devMode = constants.dev;
        controller.subscribedTo = [];
        controller.totalCoursesToPublish = 0;
        controller.coursesLeftToPublish = 0;
        controller.failedCourses = 0;

        $scope.institutionData = {};
        $scope.sections = {};
        $scope.institutionId = null;

        controller.isLoading = function () {
            return leftToLoad !== 0;
        };

        /**
         * If the program publishing status should display
         *
         * @returns {boolean}
         */
        $scope.displayProgramStatus = function (section) {
            return !UserFactory.isClient() &&
                section.hasOwnProperty('programs') &&
                section.programs.hasOwnProperty('total') &&
                section.programs.total > 0 &&
                section.hasOwnProperty('status') &&
                (section.status === 'failure' || section.status === 'success');
        };

        /**
         * Actions to do when publishing is triggered.
         *
         * @param {Object} section
         */
        $scope.handlePublish = function (section) {
            controller.totalCoursesToPublish = 0;
            section.publishDisabled = true;
            section.status = constants.publishStatus.pending;
            section.statusMessage = '';
            PublishService.publish($scope.institutionId, section.type).then(function (response) {
                controller.publishCallback(section, response);
            });
        };

        function processCourseStatus(courseId, status, section)
        {
            // check if
            // + everything is already published
            // + or pending / in progress
            // + or already processed
            if (!controller.coursesLeftToPublish ||
                [constants.publishStatus.pending, constants.publishStatus.progress].indexOf(status) !== -1 ||
               (
                    coursePublishStatus[courseId] &&
                    [constants.publishStatus.success, constants.publishStatus.failure].indexOf(coursePublishStatus[courseId]) !== -1
                )
            ) {
                return;
            }
            controller.coursesLeftToPublish--;
            coursePublishStatus[courseId] = status;

            if (status === constants.publishStatus.failure) {
                controller.failedCourses++;
            }
            if (controller.coursesLeftToPublish === 0) {
                section.status = controller.failedCourses ? constants.publishStatus.failure : constants.publishStatus.success;
                if (section.status === constants.publishStatus.success) {
                    MatchingToolFactory.reloadDatagrid(true);
                }
                section.publishDisabled = false;
                // Reload history log when all courses processed
                TuProfileHistoryLogFactory.setReload(true);
                return true;
            }
        }

        /**
         * Publishing callback.
         *
         * @param {Object} section
         * @param {Object} response
         * @returns {boolean}
         */
        controller.publishCallback = function (section, response) {
            if (section.type === 'courses') {
                if (!response) {
                    section.status = constants.publishStatus.failure;
                    section.publishDisabled = false;
                    return false;
                } else if (Object.keys(response).length === 0) {
                    section.statusMessage = 'No courses were updated for mass publish';
                    section.status = constants.publishStatus.failure;
                    section.publishDisabled = false;
                    return false;
                }

                controller.totalCoursesToPublish = Object.keys(response).length;
                controller.coursesLeftToPublish = Object.keys(response).length;
                controller.failedCourses = 0;
                coursePublishStatus = [];
                MatchingToolFactory.reloadDatagrid(true);
                angular.forEach(response, function (data, courseId) {
                    coursePublishStatus[courseId] = constants.publishStatus.pending;
                    if (coursesPublishStatusSubscriptions.indexOf(courseId) !== -1) {
                        return;
                    }
                    coursesPublishStatusSubscriptions.push(courseId);

                    WebSocketsService.subscribe(courseId, 'PublishStatus' + courseId, function (result) {
                        processCourseStatus(courseId, result.status, section);
                    }, function (subscribed) {
                       if (!subscribed) {
                           section.statusMessage = 'Can not retrieve publish status';
                           section.status = constants.publishStatus.failure;
                           section.publishDisabled = false;
                           return false;
                       }
                    });
                });
                // get statuses from server to avoid race condition (if subscribed after feed was processed)
                if (controller.coursesLeftToPublish && coursesPublishStatusSubscriptions.length) {
                    CoursesService.getPublishStatus(coursesPublishStatusSubscriptions).then(function (results) {
                        if (!controller.coursesLeftToPublish || !results || !results.results) {
                            return;
                        }
                        angular.forEach(results.results, function (status, courseId) {
                            if (status === constants.publishStatus.success || status === constants.publishStatus.failure) {
                                processCourseStatus(courseId, status, section);
                            }
                        });
                    });
                }
            } else {
                if (!response || !angular.isDefined(response.feedId)) {
                    section.status = constants.publishStatus.failure;
                    return false;
                }
                PublishService.setStatus(constants.publishStatus.pending);
                section.feedId = response.feedId;
                if (section.type !== 'courses') {
                    section.status = constants.publishStatus.pending;
                }
            }
        };

        /**
         * Subscribe to all available publishes
         */
        controller.publishSubscribe = function() {
            angular.forEach($scope.sections, function (section) {
                var subscribeType = $scope.institutionId + section.type;
                if (controller.subscribedTo.indexOf(subscribeType) === -1) {
                    controller.subscribedTo.push(subscribeType);
                    WebSocketsService.subscribe(subscribeType, 'PublishStatus' + subscribeType, function (response) {
                        controller.sectionPublishStatusCallback(section, response);
                    });
                }
            });
        };

        controller.sectionPublishStatusCallback = function (section, response) {
            section.publishDisabled = true;
            var status = response && response.status || constants.publishStatus.failure;
            section.status = status;
            switch (status) {
                case constants.publishStatus.failure:
                    PublishService.getPublishStatus($scope.institutionId).then(function (response) {
                        controller.publishStatusCallback(response);
                        section.publishDisabled = false;
                        if (response.statusMessage) {
                            section.statusMessage = PublishService.convertErrorMessage(
                                response.statusMessage,
                                UserFactory.isClient()
                            );
                        }
                        TuProfileHistoryLogFactory.setReload(true);
                    });
                    break;
                case constants.publishStatus.success:
                    PublishService.getPublishStatus($scope.institutionId).then(function (response) {
                        controller.publishStatusCallback(response);
                        section.publishDisabled = false;
                        section.statusMessage = response.statusMessage;
                        // Update nodeId
                        section.viewDisabled = response[section.type] ? !response[section.type].nodeId : true;
                        if (response && response[section.type]) {
                            var env = section.type.indexOf('China') !== -1 ? constants.drupal.tuChina.url : constants.drupal.tu.url;
                            section.url = env + '/node/' + response[section.type].nodeId;
                        }
                        TuProfileHistoryLogFactory.setReload(true);
                        if (section.type === 'master') {
                            controller.updateStarsSection(response);
                        }
                    });
                    break;
                default:
                    break;
            }
        };

        /**
         * Updates stars section based on master section
         *
         * @param {array} response
         */
        controller.updateStarsSection = function (response) {
            for (var i = 0; i < $scope.sections.length; i++) {
                if ($scope.sections[i].type === 'stars') {
                    $scope.sections[i].viewDisabled = !response.nodeId;
                    $scope.sections[i].url = constants.drupal.tu.url + '/node/' + response.nodeId;
                }
            }
        };

        function filterStatus(courses, statuses) {
            var count = 0;
            angular.forEach(courses, function (status) {
                if (statuses.indexOf(status) !== -1) {
                    count++;
                }
            });

            return count;
        }

        function leftToPublishIds(courses) {
            var ids = [];
            angular.forEach(courses, function (status, key) {
                if (['pending'].indexOf(status) !== -1) {
                    ids.push(key);
                }
            });

            return ids;
        }

        /**
         * Handle publish statuses response.
         *
         * @param {Promise.Object} publishStatus
         */
        controller.publishStatusCallback = function (publishStatus) {
            angular.forEach($scope.sections, function(section) {
                section.publishDisabled = section.type !== 'courses';
                if (angular.isDefined(publishStatus[section.type])) {
                    section.status = publishStatus[section.type].status;
                    if (publishStatus[section.type].hasOwnProperty('programs')) {
                        section.programs = publishStatus[section.type].programs;
                        section.data = publishStatus[section.type].programs.data;
                    }
                    if (publishStatus[section.type].hasOwnProperty('courses')) {
                        controller.totalCoursesToPublish = Object.keys(publishStatus[section.type].courses).length;
                        controller.coursesLeftToPublish = filterStatus(publishStatus[section.type].courses, ['pending']);
                        controller.failedCourses = filterStatus(publishStatus[section.type].courses, ['failure']);
                        if (controller.coursesLeftToPublish > 0) {
                            angular.forEach(leftToPublishIds(publishStatus[section.type].courses), function (courseId) {
                                WebSocketsService.subscribe(courseId, 'PublishStatus' + courseId, function (result) {
                                    processCourseStatus(courseId, result.status, section);
                                }, function (subscribed) {
                                    if (!subscribed) {
                                        section.statusMessage = 'Can not retrieve publish status';
                                        section.status = constants.publishStatus.failure;
                                        section.publishDisabled = false;
                                        return false;
                                    }
                                });
                            });
                        }
                    }
                    if (section.status.length === 0 ||
                        section.status === constants.publishStatus.success ||
                        section.status === constants.publishStatus.failure
                    ) {
                        section.publishDisabled = false;
                    }
                    section.statusMessage = PublishService.convertErrorMessage(
                        publishStatus[section.type].message,
                        UserFactory.isClient()
                    );
                }
            });
        };

        /**
         * View programs that failed publishing in popup.
         *
         * @param size
         * @param type
         * @param data
         */
        $scope.viewPrograms = function (size, type, data) {
            var modalOptions = {
                closeButtonText: 'Close',
                headerText: type.toUpperCase() + ' ' + 'Program/s that failed to publish:',
                data: data
            };
            var modalDefaults = {
                backdrop: true,
                keyboard: true,
                modalFade: true,
                size: size,
                templateUrl: '/scripts/components/modal/viewPrograms.html'
            };
            ModalService.show(modalDefaults, modalOptions);
        };

        function getDataWatch(newValue) {
            if (newValue && !angular.equals({}, newValue)) {
                leftToLoad = angular.copy(totalToLoad);
                $scope.institutionData = newValue;
                $scope.institutionId = newValue.id;
                leftToLoad--;

                // check if stars data exists
                if (!UserFactory.isClient()) {
                    StarsClientService.get(newValue.coreId).then(function (data) {
                        $scope.sections = PublishService.getSections($scope.institutionData, !!data, true);
                        controller.publishSubscribe($scope.sections);
                        leftToLoad--;
                    });
                } else {
                    $scope.sections = PublishService.getSections($scope.institutionData, false, !UserFactory.isClient());
                    controller.publishSubscribe($scope.sections);
                    leftToLoad--;
                }

                // get publishing statuses
                PublishService.getPublishStatus($scope.institutionId).then(function (response) {
                    controller.publishStatusCallback(response);
                    leftToLoad--;
                });
            }
        }

        /**
         * Initialize watches.
         */
        controller.initWatches = function () {
            // watch for active institution changes
            WatchService.create($scope, InstitutionFactory.getData, getDataWatch);
        };

        /**
         * @constructor
         */
        controller.init = function () {
            controller.initWatches();
        };

        // listen to publish tab visibility changes
        WatchService.create($scope, TuProfileFactory.isPublishTabSelected, function (isActive) {
            // if not already initialized and tab is active
            if (!alreadyInitialised && isActive) {
                controller.init();
                alreadyInitialised = true;
            }
        });
    }

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { controllers: {} });

    App.controllers.tuProfilePublishLogs = function (
        $scope,
        PublishService,
        InstitutionFactory,
        TuProfileFactory,
        TuProfileHistoryLogFactory,
        TuProfilePublishHistoryLogFactory,
        WatchService
    ) {
        var controller = this,
            alreadyInitialised = false,
            logsPerPage = 5,
            publishLogInitLimit = 10;
        controller.isLoadMoreHistoryLogsInProgress = false;
        controller.updatePublishLogs = function () {
            if (InstitutionFactory.getId()) {
                PublishService.getPublishLogs(InstitutionFactory.getId(), TuProfilePublishHistoryLogFactory.getPublishLogLimit()).then(function (response) {
                    $scope.publishLogs = response;
                    TuProfilePublishHistoryLogFactory.setLogs(response);
                });
            }
        };

        $scope.fetchingPublishLog = function () {
            return false;
        };
        controller.getPublishStatusMapped = function (status) {
            return PublishService.getPublishStatusMapped(status);
        };
        controller.getHistoryLogsDataWatch = function (data) {
            controller.publishLogs = data;
        };

        controller.isMoreLogsAvailable = function() {
            if (controller.publishLogs && controller.publishLogs.totalMatching) {
                return TuProfilePublishHistoryLogFactory.getPublishLogLimit() < controller.publishLogs.totalMatching;
            }
            return false;
        };

        controller.isHistoryLogsLoadMoreInProgressWatch = function (isInProgress) {
            controller.isLoadMoreHistoryLogsInProgress = isInProgress;
        };

        controller.handleLoadMoreHistoryLogs = function() {
            TuProfilePublishHistoryLogFactory.loadMore();
        };

        controller.isLoadMoreRequestWatch = function (loadMore) {
            if (loadMore) {
                TuProfilePublishHistoryLogFactory.loadMore(false);
                TuProfilePublishHistoryLogFactory.setLoadMoreInProgress(false);
                var limit = TuProfilePublishHistoryLogFactory.getPublishLogLimit() + logsPerPage;

                PublishService.getPublishLogs(InstitutionFactory.getId(), limit).then(function (response) {
                    $scope.publishLogs = response;
                    TuProfilePublishHistoryLogFactory.setLogs(response);
                    TuProfilePublishHistoryLogFactory.setLoadMoreInProgress(false);
                    TuProfilePublishHistoryLogFactory.setPublishLogLimit(limit);
                });
            }
        };
        controller.initWatches = function () {
            // watch for active institution changes
            WatchService.create($scope, InstitutionFactory.getData, function (newValue) {
                if (newValue) {
                    controller.updatePublishLogs();
                }
            });

            WatchService.create($scope, TuProfileHistoryLogFactory.isReloadRequired, function (newValue) {
                if (newValue) {
                    TuProfileHistoryLogFactory.setReload(false);
                    controller.updatePublishLogs();
                }
            });

            WatchService.create($scope, PublishService.getStatus, function (value) {
                if(value){
                    controller.updatePublishLogs();
                }
            });
            // watch for history logs data changes
            WatchService.create($scope, TuProfilePublishHistoryLogFactory.getLogs, controller.getHistoryLogsDataWatch);
            // watch for history logs loading progress
            WatchService.create($scope, TuProfilePublishHistoryLogFactory.isLoadMoreInProgress, controller.isHistoryLogsLoadMoreInProgressWatch);
            // watch for load more request
            WatchService.create($scope, TuProfilePublishHistoryLogFactory.isLoadMoreRequest, controller.isLoadMoreRequestWatch);
        };

        /**
         * @constructor
         */
        controller.init = function () {
            controller.initWatches();
        };

        // listen to public tab visibility changes
        WatchService.create($scope, TuProfileFactory.isPublishTabSelected, function (isActive) {
            // if not already initialized and tab is active
            if (!alreadyInitialised && isActive) {
                controller.init();
                alreadyInitialised = true;
                //set initial publish logs limit
                TuProfilePublishHistoryLogFactory.setPublishLogLimit(publishLogInitLimit);
            }
        });
    };

    angular
        .module('qsHub')
        .controller('TuProfilePublishLogsController', [
            '$scope',
            'TuProfilePublishService',
            'InstitutionFactory',
            'TuProfileFactory',
            'TuProfileHistoryLogFactory',
            'TuProfilePublishHistoryLogFactory',
            'WatchService',
            App.controllers.tuProfilePublishLogs
        ]);

} (window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { controllers: {} });

    App.controllers.tuProfileResubscribe = function (
        $scope,
        TuProfileResubscribeService,
        InstitutionFactory,
        TuProfileFactory,
        UserFactory,
        NotifierFactory,
        WatchService
    ) {
        var controller = this,
            alreadyInitialised = false,
            displayResubscribeBannerDays = 31;

        controller.profileId = '';
        controller.expiresDate = '';
        controller.isAdvanced = false;
        controller.displayResubscribeForm = false;
        controller.inProgress = false;
        /**
         * Data used in resubscribe form
         * @type {{comments: string, name: string, email: string, to: string, subject: string}}
         */
        controller.formData = {
            'comments': '',
            'name': '',
            'email': '',
            'to': 'tusupport@qs.com',
            'subject': 'I would like more information about Resubscribing an Advance Profile'
        };

        /**
         * Re-init form data
         */
        controller.initFormData = function () {
            controller.formData.comments = '';
        };

        /**
         * Resubscribe button click
         */
        controller.resubscribeClick = function () {
            controller.initFormData();
            controller.displayResubscribeForm = !controller.displayResubscribeForm;
        };

        /**
         * Cancel button click
         */
        controller.cancelClick = function () {
            controller.initFormData();
            controller.resubscribeClick();
        };

        controller.createCallback = function (response) {
            if (response) {
                controller.initFormData();
                controller.resubscribeClick();
            }
            controller.inProgress = false;
            NotifierFactory.show(
                response ? 'success' : 'error',
                response ? 'Request sent successfully!' : 'Request sending failed!',
                'Resubscribe Request'
            );
        };

        /**
         * Submit form button click
         */
        controller.submitClick = function () {
            controller.inProgress = true;
            TuProfileResubscribeService.create(
                controller.profileId,
                controller.formData.comments,
                controller.formData.name,
                controller.formData.email
            ).then(controller.createCallback);
        };

        /**
         * Check if we should show the resubscribe button
         * Button should display when subscription expires in less than 1 month
         *
         * @returns {boolean}
         */
        controller.showResubscribeButton = function () {
            // calculate if subscription expires in less than one month
            if (controller.expiresDate) {
                var expiresDate, todayDate, daysLeft, leftMs;
                expiresDate = new Date(parseInt(controller.expiresDate, 10));
                todayDate = new Date();
                leftMs = expiresDate.getTime() - todayDate.getTime();
                daysLeft = Math.ceil(Math.abs(leftMs)) / (1000 * 3600 * 24);

                return leftMs > 0 ? daysLeft < displayResubscribeBannerDays : false;
            }

            return false;
        };

        /**
         * Init watches
         */
        controller.initWatches = function () {
            // watch for active institution changes
            WatchService.create($scope, TuProfileFactory.getData, function (newValue) {
                controller.profileId = newValue && newValue.id ? newValue.id : '';
            });
            WatchService.create($scope, InstitutionFactory.getData, function (newValue) {
                if (newValue && !angular.equals({}, newValue)) {
                    controller.isAdvanced = InstitutionFactory.isTuAdvanced();
                    controller.expiresDate = angular.isDefined(newValue.subscriptions.tu) ? newValue.subscriptions.tu.endDate : '';
                    controller.formData.name = UserFactory.getFullName();
                    controller.formData.email = UserFactory.getUserName();
                }
            });
        };

        /**
         * @constructor
         */
        controller.init = function () {
            controller.initWatches();
        };

        /**
         * listen to publish tab visibility changes
         */
        WatchService.create($scope, TuProfileFactory.isPublishTabSelected, function (isActive) {
            // if not already initialized and tab is active
            if (!alreadyInitialised && isActive) {
                controller.init();
                alreadyInitialised = true;
            }
        });
    };

    angular
        .module('qsHub')
        .controller('TuProfileResubscribeController', [
            '$scope',
            'TuProfileResubscribeService',
            'InstitutionFactory',
            'TuProfileFactory',
            'UserFactory',
            'NotifierFactory',
            'WatchService',
            App.controllers.tuProfileResubscribe
        ]);

} (window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    App.services.TuProfileResubscribeService = function (
        $resource,
        $log,
        constants
    ) {
        var service = {
            deferred : {}
        };

        /**
         * Get resubscribe API endpoint.
         *
         * @returns {$resource}
         */
        service.getResubscribeModel = function () {
            return $resource(constants.api.institutions.url + '/v1/tu-profile/:id/resubscribe-request');
        };

        /**
         * Send resubscribe email.
         *
         * @param {String} profileId
         * @param {String} comments
         * @param {String} fromName
         * @param {String} fromEmail
         * @returns {Promise}
         */
        service.create = function(profileId, comments, fromName, fromEmail) {
            comments = (typeof comments === 'undefined') ? '' : comments;
            fromName = (typeof fromName === 'undefined') ? '' : fromName;
            fromEmail = (typeof fromEmail === 'undefined') ? '' : fromEmail;
            return service.getResubscribeModel().save(
                {id: profileId},
                {
                    comments: comments,
                    name: fromName,
                    email: fromEmail
                }
            ).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }

                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }

                return false;
            });
        };

        return service;
    };

    angular
        .module('qsHub')
        .service('TuProfileResubscribeService', [
            '$resource',
            '$log',
            'constants',
            App.services.TuProfileResubscribeService
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    App.services.TuProfilePublishService = function (
        $resource,
        $q,
        $log,
        constants
    ) {
        var service = {
            deferred : {},
            isStatus: null,
            publishStatus: {
                failure : 'failure',
                success: 'success',
                progress: 'progress',
                pending: 'pending'
            }
        };

        /**
         * Get departments upgrade API endpoint.
         *
         * @returns {$resource}
         */
        service.getPublishModel = function () {
            return $resource(constants.api.institutions.url + '/v1/tu-feed/publish/:id/:type');
        };

        service.getPublishStatusModel = function () {
            return $resource(constants.api.institutions.url + '/v1/tu-feed/publish-status/:id');
        };

        service.getLogsModel = function () {
            return $resource(constants.api.institutions.url + '/v1/tu-feed/publish-logs/:id?sorting[createdAt]=desc&fieldName=type,status,createdAt,createdByFullName');
        };

        service.getNodeId = function (institutionData, nodeType) {
            return institutionData &&
            institutionData.nids &&
            institutionData.nids[nodeType] ? institutionData.nids[nodeType] : null;
        };

        /**
         *
         * @param {bool} qsUser
         * @param {Object} institutionData
         * @returns {Array}
         */
        service.getSections = function (institutionData, hasStars, qsUser) {
            var tuUrl = constants.drupal.tu.url,
                tuChinaUrl = constants.drupal.tuChina.url,
                masterNid = service.getNodeId(institutionData, 'master'),
                ugNid = service.getNodeId(institutionData, 'ug'),
                pgNid = service.getNodeId(institutionData, 'pg'),
                masterChinaNid = service.getNodeId(institutionData, 'masterChina'),
                ugChinaNid = service.getNodeId(institutionData, 'ugChina'),
                pgChinaNid = service.getNodeId(institutionData, 'pgChina'),
                institutionCoreId = institutionData.coreId,
                sections = [
                {
                    type: 'master',
                    name: 'Overview',
                    url: tuUrl + '/node/' + masterNid,
                    feedPreviewUrl: constants.api.institutions.url + '/v1/tu-feed/core-id/' + institutionCoreId + '/master',
                    viewDisabled: !masterNid
                },
                {
                    type: 'ug',
                    name: 'Undergraduate',
                    url: tuUrl + '/node/' + ugNid,
                    feedPreviewUrl: constants.api.institutions.url + '/v1/tu-feed/core-id/' + institutionCoreId + '/ug',
                    viewDisabled: !ugNid
                },
                {
                    type: 'pg',
                    name: 'Postgraduate',
                    url: tuUrl + '/node/' + pgNid,
                    feedPreviewUrl: constants.api.institutions.url + '/v1/tu-feed/core-id/' + institutionCoreId + '/pg',
                    viewDisabled: !pgNid
                },
                {
                    type: 'masterChina',
                    name: 'Overview (China)',
                    url: tuChinaUrl + '/node/' + masterChinaNid,
                    feedPreviewUrl: constants.api.institutions.url + '/v1/tu-feed/core-id/' + institutionCoreId + '/masterChina',
                    viewDisabled: !masterChinaNid
                },
                {
                    type: 'ugChina',
                    name: 'Undergraduate (China)',
                    url: tuChinaUrl + '/node/' + ugChinaNid,
                    feedPreviewUrl: constants.api.institutions.url + '/v1/tu-feed/core-id/' + institutionCoreId + '/ugChina',
                    viewDisabled: !ugChinaNid
                },
                {
                    type: 'pgChina',
                    name: 'Postgraduate (China)',
                    url: tuChinaUrl + '/node/' + pgChinaNid,
                    feedPreviewUrl: constants.api.institutions.url + '/v1/tu-feed/core-id/' + institutionCoreId + '/pgChina',
                    viewDisabled: !pgChinaNid
                }
            ];
            if (hasStars) {
                sections.push({
                    type: 'stars',
                    name: 'QS Stars',
                    publishDisabled: false,
                    viewDisabled: !masterNid,
                    url: tuUrl + '/node/' + masterNid
                });
                sections.push({
                    type: 'starsChina',
                    name: 'QS Stars (China)',
                    publishDisabled: false,
                    viewDisabled: !masterChinaNid,
                    url: tuChinaUrl + '/node/' + masterChinaNid
                });
            }
            if (qsUser) {
                sections.push({
                    type: 'courses',
                    name: 'Matching Tool',
                    publishDisabled: false,
                    viewDisabled: true,
                    url: tuUrl + '/node/' + masterNid
                });
            }

            return sections;
        };

        /**
         *
         * @param {String} institutionId
         * @param {String} profileType
         * @returns {Promise.*}
         */
        service.publish = function (institutionId, profileType) {
            if (!institutionId || !profileType) {
                var deferred = $q.defer();
                deferred.resolve(false);
                return deferred.promise;
            }

            return service.getPublishModel().get(
                {
                    id: institutionId,
                    type: profileType
                }
            ).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return data.toJSON();
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        /**
         * Get publish logs.
         *
         * @param {String} institutionId
         * @returns {Promise.*}
         */
        service.getPublishLogs = function (institutionId, limit) {
            if (!institutionId) {
                var deferred = $q.defer();
                deferred.resolve(false);
                return deferred.promise;
            }
            limit = limit || 10;
            return service.getLogsModel().get({
                id: institutionId,
                limit: limit
            }).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return data;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        service.getPublishStatus = function (institutionId) {
            return service.getPublishStatusModel().get(
                {
                    id: institutionId
                }
            ).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return data;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        service.convertErrorMessage = function (errorMessage, isClient) {
            if (!isClient) {
                return errorMessage;
            }
            if (
                errorMessage.indexOf('Could not resolve host') !== -1 ||
                errorMessage.indexOf('Gateway Time-out') !== -1 ||
                errorMessage.indexOf('Connection timed out') !== -1
            ) {
                return 'Unable to publish to Topuniversities.com due to technical reasons. Please try again later.';
            }
            switch (errorMessage) {
                case '':
                case null:
                    return null;
                case 'Missing basic overview':
                case 'Missing advanced overview':
                    return 'Missing overview';
                default:
                    return 'Please contact tuonlinesupport@qs.com';
            }
        };
        /**
         * Get publish status mapping
         *
         * @param {string} status
         * @returns {string}
         */
        service.getPublishStatusMapped = function (status) {
            switch (status) {
                case service.publishStatus.failure:
                    return 'Failed';
                case service.publishStatus.progress:
                    return 'Progress';
                case service.publishStatus.pending:
                    return 'Pending';
                default:
                    return 'Published';
            }
        };

        service.setStatus = function (status) {
            service.isStatus = status;
        };
        service.getStatus = function() {
            return service.isStatus;
        };

        return service;
    };

    angular
        .module('qsHub')
        .service('TuProfilePublishService', [
            '$resource',
            '$q',
            '$log',
            'constants',
            App.services.TuProfilePublishService
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { factories: {} });

    App.factories.tuProfilePublishHistoryLog = function() {
        var loadMoreInProgress = false,
            loadMore = false,
            logs = [],
            limit;

        return {
            setLogs: function (data) {
                logs = data;
            },
            getLogs: function () {
                return logs;
            },
            setPublishLogLimit: function (logLimit) {
                limit = logLimit;
            },
            getPublishLogLimit: function () {
                return limit || 10;
            },
            isLoadMoreInProgress: function () {
                return loadMoreInProgress;
            },
            setLoadMoreInProgress: function (inProgress) {
                loadMoreInProgress = inProgress;
            },
            loadMore: function (more) {
                loadMore = typeof more === 'undefined' ? true : more;
            },
            isLoadMoreRequest: function () {
                return loadMore;
            }
        };
    };

    angular
        .module('qsHub')
        .factory('TuProfilePublishHistoryLogFactory', [
            App.factories.tuProfilePublishHistoryLog
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { controllers: {} });

    App.controllers.tmProfile = function(
        $scope,
        $rootScope,
        $location,
        $state,
        constants,
        TmProfileService,
        NotifierFactory,
        TmProfileFactory,
        InstitutionFactory,
        UserFactory,
        TmProfileProgramsService,
        TmProfileOverviewService,
        TmProfileOverviewHistoryLogFactory,
        WatchService
    ) {
        var controller = this;
        controller.devMode = constants.dev;
        controller.showFaqHistory = false;
        controller.showOverviewHistory = false;
        controller.isLoadMoreHistoryLogsInProgress = false;
        controller.upgradeInProgress = false;
        controller.maxFaqItems = TmProfileOverviewService.maxFaqItems;

        $scope.tmIsAdvanced = false;
        $scope.upgradeEmailsTo = constants.emails.upgradeTm;
        $scope.institutionDataLoaded = false;
        $scope.profileDataLoaded = false;
        $scope.tmProfile = {};
        $scope.upgradeRequest = {};
        $scope.userData = UserFactory.getData();
        $scope.upgradeProgramRequest = {};
        $scope.deleteRequest = {};
        $scope.tmProfileBeforeChanges = {};
        $scope.forms = {};
        $scope.showUpgradeForm = false;
        $scope.showHistory = false;
        $scope.showDeleteForm = false;
        $scope.showProgramSubscribeForm = false;

        /**
         * Is all data loaded?
         *
         * @returns {boolean}
         */
        controller.loadInProgress = function() {
            return $scope.institutionDataLoaded && $scope.profileDataLoaded ? false : true;
        };

        // load TU profile data
        controller.loadTmProfileData = function(coreId) {
            TmProfileService.getTmProfileData(coreId).then(function(data) {
                $scope.profileDataLoaded = true;
                $scope.tmProfile = data;
                TmProfileFactory.setData(data);
                $scope.tmProfileBeforeChanges = angular.copy(data);

                $scope.$broadcast(constants.events.institutionTmProfileChanges);
            });
        };

        controller.isRightSidePanelActive = function() {
            // publish tab is active
            if (TmProfileFactory.isPublishTabSelected()) {
                TmProfileFactory.setUpgradeFormVisibility(false);
                return true;
            }
            // upgrade form is visible
            if (
                ($scope.showUpgradeForm || $scope.showUpgradeProgramRequest) &&
                (
                    TmProfileFactory.isOverviewTabSelected() ||
                    TmProfileFactory.isProgramsTabSelected() ||
                    TmProfileFactory.isMediaTabSelected()
                )
            ) {
                return true;
            }
            // overview tab and history block is active
            if (TmProfileFactory.isOverviewTabSelected() && controller.showOverviewHistory) {
                TmProfileFactory.setUpgradeFormVisibility(false);
                return true;
            }

            // FAQ history logs are open under overview tab
            if (TmProfileFactory.isOverviewTabSelected() && controller.showFaqHistory) {
                TmProfileFactory.setUpgradeFormVisibility(false);
                return true;
            }

            // programs tab is active and edit or add form is active
            if (TmProfileFactory.isProgramsTabSelected() && ($scope.showProgramEditForm || $scope.showProgramAddForm)) {
                return true;
            }

            // media tab is active
            if (TmProfileFactory.isMediaTabSelected()) {
                TmProfileFactory.setUpgradeFormVisibility(false);
                return true;
            }
            if (TmProfileFactory.isProgramsTabSelected() && $scope.showDeleteForm) {
                return true;
            }
            return false;
        };

        $scope.isMediaTabActive = function() {
            return TmProfileFactory.isMediaTabSelected();
        };

        $scope.isPublishTabActive = function () {
            return TmProfileFactory.isPublishTabSelected();
        };

        /**
         * Toggle upgrade form.
         */
        controller.toggleUpgradeForm = function() {
            TmProfileFactory.setUpgradeFormVisibility();
            if (TmProfileFactory.isUpgradeFormVisible($scope.activeTab)) {
                TmProfileOverviewHistoryLogFactory.setOverviewVisible(false);
            }
        };

        /**
         * Toggle upgrade form.
         */
        controller.toggleUpgradeProgramForm = function() {
            TmProfileFactory.setProgramUpgradeFormVisibility();
        };

        controller.handleCloseDeleteForm = function () {
            TmProfileFactory.setProgramEditFormVisibility(true);
        };

        controller.handleSendDeleteRequestClick = function () {
            controller.deleteRequestInProgress = true;
            TmProfileProgramsService.deleteRequest(
                $scope.deleteRequest.program.id,
                $scope.deleteRequest.comments
            ).then(controller.deleteCallback);
        };

        controller.deleteCallback = function (response) {
            controller.deleteRequestInProgress = false;

            // show notification about program submit status
            NotifierFactory.show(
                response ? 'success' : 'error',
                response ? 'Deleted successfully!' : 'Delete failed!',
                'Delete Program'
            );

            if (response) {
                // close edit mode
                TmProfileFactory.setProgramEditFormVisibility(false);
                // reload datagrid or remove program in it
                TmProfileFactory.announceProgramDeletion($scope.deleteRequest.program.id);
            }
        };

        controller.handleProgramUpgradeRequestClick = function() {
            TmProfileProgramsService.upgrade(
                TmProfileFactory.getProgram().id,
                $scope.upgradeRequest.comments
            ).then(controller.upgradeCallback);
        };

        controller.upgradeCallback = function (success) {
            NotifierFactory.show(
                success ? 'success' : 'error',
                success ? 'Upgrade request successfully sent!' : 'Upgrade request failed!',
                'Basic Program'
            );
            TmProfileFactory.setProgramUpgradeFormVisibility(false);
        };

        controller.handleLoadMoreHistoryLogs = function() {
            TmProfileOverviewHistoryLogFactory.loadMore();
        };

        controller.closeHistoryLogs = function () {
            TmProfileOverviewHistoryLogFactory.closeAll();
            controller.overviewLog = {};
        };

        controller.isMoreLogsAvailable = function() {
            if (controller.overviewLog && controller.overviewLog.totalMatching) {
                return TmProfileOverviewHistoryLogFactory.getLimit() < controller.overviewLog.totalMatching;
            }
            return false;
        };

        controller.isFaqHistoryLogsVisibleWatch = function (isVisible, wasVisible) {
            isVisible = isVisible || false;
            controller.showFaqHistory = isVisible;
            if (!isVisible && wasVisible) {
                controller.overviewLog = {};
                TmProfileOverviewHistoryLogFactory.setLimit(null);
            }
        };

        controller.isOverviewHistoryLogsVisibleWatch = function (isVisible, wasVisible) {
            isVisible = isVisible || false;
            controller.showOverviewHistory = isVisible;
            if (!isVisible && wasVisible) {
                controller.overviewLog = {};
                TmProfileOverviewHistoryLogFactory.setLimit(null);
            }
        };

        controller.getHistoryLogsDataWatch = function (data) {
            controller.overviewLog = data;
        };

        controller.isHistoryLogsLoadMoreInProgressWatch = function (isInProgress) {
            controller.isLoadMoreHistoryLogsInProgress = isInProgress;
        };

        controller.activeTabWatch = function (tabId) {
            // store active tab id
            TmProfileFactory.setSelectedTabId(tabId);
            // close all left side panels
            TmProfileFactory.closeRightSidePanels();
           // controller.overviewLog = null;
            //TmProfileOverviewHistoryLogFactory.setLimit(1);
        };

        controller.getCoreIdWatch = function (coreId) {
            if (coreId) {
                // close all left side panels
                TmProfileFactory.closeRightSidePanels();

                if (InstitutionFactory.isEmpty()) {
                    return false;
                }
                $scope.institutionDataLoaded = true;
                $scope.institutionId = InstitutionFactory.getId();
                $scope.institutionData = InstitutionFactory.getData();
                $scope.tmIsAdvanced = InstitutionFactory.isTmAdvanced();
                // check if TM is not enabled, redirect qs user to default client page.
                if (!InstitutionFactory.isTmSubscribed() && !UserFactory.isClient()) {
                    $location.path(constants.defaultClientPage);

                    return false;
                }

                controller.showProgramStasTab = InstitutionFactory.isAdvancedProgram();
                if (InstitutionFactory.isTmSubscribed() && !UserFactory.noAccess($state.current.name)) {
                    controller.loadTmProfileData(InstitutionFactory.getCoreId());
                } else {
                    // if user doesn't have access, empty previously loaded data
                    $scope.profileDataLoaded = true;
                    TmProfileFactory.setData(null);
                }
            }
        };

        controller.initWatches = function () {
            // watch for delete form visibility changes
            WatchService.create($scope, function () {
                return TmProfileFactory.isProgramUpgradeFormVisible($scope.activeTab);
            }, function(newValue, oldValue) {
                if (newValue !== oldValue) {
                    $scope.upgradeRequest = {
                        comments: null,
                        program: TmProfileFactory.getProgram(),
                        name: UserFactory.getFullName(),
                        email: UserFactory.getUserName()
                    };
                    $scope.showUpgradeProgramRequest = newValue;
                }
            });

            // watch for upgrade form visibility changes
            WatchService.create($scope, function () {
                return TmProfileFactory.isUpgradeFormVisible($scope.activeTab);
            }, function(newValue, oldValue) {
                if (newValue !== oldValue) {
                    $scope.upgradeRequest = {
                        name: UserFactory.getFullName(),
                        email: UserFactory.getUserName(),
                        comments: null
                    };
                    $scope.showUpgradeForm = newValue;
                }
            });

            // watch for delete form visibility changes
            WatchService.create($scope, function () {
                return TmProfileFactory.isDeleteFormVisible($scope.activeTab);
            }, function(newValue, oldValue) {
                if (newValue !== oldValue) {
                    $scope.deleteRequest = {
                        comments: null,
                        program: TmProfileFactory.getProgram(),
                        name: UserFactory.getFullName(),
                        email: UserFactory.getUserName()
                    };
                    $scope.showDeleteForm = newValue;
                }
            });

            // watch for program edit form visibility changes
            WatchService.create($scope, function () {
                return TmProfileFactory.isProgramEditFormVisible($scope.activeTab);
            }, function(newValue, oldValue) {
                if (newValue !== oldValue) {
                    $scope.showProgramEditForm = newValue;
                }
            });

            // watch for program add form visibility changes
            WatchService.create($scope, function () {
                return TmProfileFactory.isProgramAddFormVisible($scope.activeTab);
            }, function(newValue, oldValue) {
                if (newValue !== oldValue) {
                    TmProfileFactory.setReadOnly(false);
                    $scope.showProgramAddForm = newValue;
                }
            });

            // listen to active profile tab changes
            WatchService.create($scope, 'activeTab', controller.activeTabWatch);
            // watch for overview history logs visibility changes
            WatchService.create($scope, TmProfileOverviewHistoryLogFactory.isFaqVisible, controller.isFaqHistoryLogsVisibleWatch);
            // watch for FAQ history logs visibility changes
            WatchService.create($scope, TmProfileOverviewHistoryLogFactory.isOverviewVisible, controller.isOverviewHistoryLogsVisibleWatch);
            // watch for history logs data changes
            WatchService.create($scope, TmProfileOverviewHistoryLogFactory.getLogs, controller.getHistoryLogsDataWatch);
            // watch for history logs loading progress
            WatchService.create($scope, TmProfileOverviewHistoryLogFactory.isLoadMoreInProgress, controller.isHistoryLogsLoadMoreInProgressWatch);
            // watch for institution changes
            WatchService.create($scope, InstitutionFactory.getCoreId, controller.getCoreIdWatch);
            WatchService.create($scope, InstitutionFactory.isTmAdvanced, function (newValue) {
                $scope.tmIsAdvanced = newValue;
            });

            WatchService.create($scope, function () {
                return $state.$current.name;
            }, function (newValue, oldValue) {
                if (newValue !== oldValue) {
                    controller.initActiveTab();
                }
            });

            $rootScope.$on(constants.events.logout, controller.destruct);
        };

        controller.initActiveTab = function () {
            switch ($state.current.name) {
                case 'clients.profiles.tm.programs':
                    $scope.activeTab = TmProfileFactory.getProfileTabs().programs;
                    break;
                case 'clients.profiles.tm.program-stats':
                    $scope.activeTab = TmProfileFactory.getProfileTabs().programStats;
                    break;
                case 'clients.profiles.tm.media':
                    $scope.activeTab = TmProfileFactory.getProfileTabs().media;
                    break;
                case 'clients.profiles.tm.publish':
                    $scope.activeTab = TmProfileFactory.getProfileTabs().publish;
                    break;
                case 'clients.profiles.tm.overview':
                /* falls through */
                default:
                    $scope.activeTab = TmProfileFactory.getProfileTabs().overview;
                    break;
            }
        };

        controller.destruct = function() {
            TmProfileFactory.reset();
            $scope.showDepartmentEditForm = false;
            $scope.showDepartmentAddForm = false;
            $scope.showProgramEditForm = false;
            $scope.showDProgramAddForm = false;
        };

        controller.$onInit = function () {
            controller.initWatches();
            TmProfileFactory.setProgramUpgradeFormVisibility(false);
            controller.initActiveTab();
        };
    };

    angular
        .module('qsHub')
        .controller('TmProfileController', [
            '$scope',
            '$rootScope',
            '$location',
            '$state',
            'constants',
            'TmProfileService',
            'NotifierFactory',
            'TmProfileFactory',
            'InstitutionFactory',
            'UserFactory',
            'TmProfileProgramsService',
            'TmProfileOverviewService',
            'TmProfileOverviewHistoryLogFactory',
            'WatchService',
            App.controllers.tmProfile
        ]);

}(window.angular));

(function (angular, moment) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { services: {} });

    App.services.TmProfile = function (
        $resource,
        $q,
        $log,
        constants,
        InstitutionsService,
        InstitutionFactory
    ) {
        var service = {
            deferred: {},
            status: {
                approved: 'approved',
                pendingDeletion: 'pending-deletion',
                editApproved: 'edit-approved',
                pendingApprove: 'requested',
                pendingEdit: 'pending-edit'
            },
            cache: {},
            programTypeBm: 'bm'
        };

        service.getProfileModel = function () {
            return $resource(constants.api.institutions.url + '/v1/tm-profile/core-id/:id', null, {
                update: {
                    method: 'PATCH'
                },
                get: {
                    cancellable: true
                }
            });
        };

        /**
         * Get TM profile upgrade requests end point.
         *
         * @returns {$resource}
         */
        service.getUpgradeRequestModel = function () {
            return $resource(constants.api.institutions.url + '/v1/tm-profile/:id/upgrade-request');
        };

        service.getProgramTypesList = function () {
            return [
                { value: 'omba', label: 'Online MBA/Distance learning' },
                { value: 'pt', label: 'Part time MBA' },
                { value: 'ft', label: 'Full time MBA' },
                { value: 'emba', label: 'Executive MBA' },
                { value: service.programTypeBm, label: 'Business Masters' }
            ];
        };

        service.getProductsList = function (unassigned) {
            var list = [
                { value: 'topmba', label: 'TopMBA' },
                { value: 'leap', label: 'Leap' }
            ];
            if (unassigned) {
                list.push({ value: '', label: 'Unassigned'});
            }

            return list;
        };

        service.getDeliveryMethodsList = function () {
            return [
                { value: 'fo', label: 'Fully-Online' },
                { value: 'bl', label: 'Blended' },
                { value: 'oc', label: 'On-Campus' }
            ];
        };

        service.isBmProgram = function (program) {
            return program && program.type === service.programTypeBm;
        };

        service.getFeesRangesList = function () {
            return [
                { value: '', label: 'N/A' },
                { value: '<20000 USD', label: '<20000 USD' },
                { value: '20000 - 30000 USD', label: '20000 - 30000 USD' },
                { value: '30000 - 40000 USD', label: '30000 - 40000 USD' },
                { value: '40000 - 50000 USD', label: '40000 - 50000 USD' },
                { value: '50000 - 60000 USD', label: '50000 - 60000 USD' },
                { value: '60000 - 70000 USD', label: '60000 - 70000 USD' },
                { value: '70000 - 80000 USD', label: '70000 - 80000 USD' },
                { value: '80000 - 90000 USD', label: '80000 - 90000 USD' },
                { value: '90000 - 100000 USD', label: '90000 - 100000 USD' },
                { value: '100000 - 110000 USD', label: '100000 - 110000 USD' },
                { value: '110000 - 120000 USD', label: '110000 - 120000 USD' },
                { value: '>120000 USD', label: '>120000 USD' }
            ];
        };

        service.getLengthList = function () {
            var list = [{ value: '', label: 'N/A' }];
            for (var i = 1; i <= 40; i++) {
                list.push({
                    value: i + ' months', label: i + ' months'
                });
            }
            return list;
        };

        service.getPercentList = function () {
            var list = [{ value: '', label: 'N/A' }];
            for (var i = 0; i <= 100; i++) {
                list.push({
                    value: i, label: i + ' %'
                });
            }

            return list;
        };

        service.getYearList = function (startYear, endYear) {
            var list = [{ value: '', label: 'N/A' }];
            for (var i = startYear; i <= endYear; i++) {
                list.push({
                    value: i, label: i + ' years'
                });
            }

            return list;
        };

        service.getMonthsList = function () {
            var count = 0,
                months = [],
                name;
            while (count < 12) {
                name = moment().month(count++).format("MMMM");
                months.push({ value: name, label: name });
            }
            return months;
        };

        service.getAccreditationsList = function () {
            return [
                { value: '', label: 'N/A' },
                { value: 'AACSB', label: 'AACSB' },
                { value: 'ACBSP', label: 'ACBSP' },
                { value: 'ACQUIN', label: 'ACQUIN' },
                { value: 'AMBA', label: 'AMBA' },
                { value: 'BPS', label: 'BPS' },
                { value: 'CIM', label: 'CIM' },
                { value: 'CIPD', label: 'CIPD' },
                { value: 'EPAS', label: 'EPAS' },
                { value: 'EQUIS', label: 'EQUIS' },
                { value: 'FIBAA', label: 'FIBAA' },
                { value: 'IACBE', label: 'IACBE' },
                { value: 'ISO', label: 'ISO' },
                { value: 'MSA', label: 'MSA' },
                { value: 'NASPAA', label: 'NASPAA' },
                { value: 'NEASC', label: 'NEASC' },
                { value: 'NVAO', label: 'NVAO' },
                { value: 'SIMT', label: 'SIMT' },
                { value: 'ZEVA', label: 'ZEVA' }
            ];
        };

        service.getProgramStatusList = function () {
            return [
                { value: 'requested', label: 'Requested (New)' },
                { value: 'pending-edit', label: 'Pending (Edit)' },
                { value: 'pending-deletion', label: 'Pending (Deletion)' },
                { value: service.status.approved, label: 'Approved' },
                { value: 'edit-approved', label: 'Edited-Approved' },
            ];
        };


        service.isProgramApproved = function (status) {
            return status === service.status.approved;
        };

        service.isProgramPendingDeletion = function (status) {
            return status === service.status.pendingDeletion;
        };

        service.isProgramPendingApprove = function (status) {
            return status === service.status.pendingApprove;
        };

        service.isProgramPendingEdit = function (status) {
            return status === service.status.pendingEdit;
        };

        service.isProgramEditApproved = function (status) {
            return status === service.status.editApproved;
        };

        service.getCampusesKeyValuePairs = function (useCache, coreId) {
            useCache = useCache || false;
            coreId = coreId || InstitutionFactory.getCoreId();
            var campusList = [],
                cacheKey = 'getCampusesList:' + coreId,
                deferred = null;

            if (useCache && service.cache[cacheKey]) {
                deferred = $q.defer();
                deferred.resolve(service.cache[cacheKey]);
                return deferred.promise;
            }

            return service.getCampuses(useCache, coreId).then(function (campuses) {
                angular.forEach(campuses, function (campus) {
                    campusList.push({
                        value: campus.id,
                        label: campus.name
                    });
                });
                return campusList;
            });
        };

        service.clearCampusesKeyValuePairsCache = function (coreId) {
            coreId = coreId || InstitutionFactory.getCoreId();
            var cacheKey = 'getCampusesList:' + coreId;

            if (service.cache[cacheKey]) {
                delete service.cache[cacheKey];
            }
        };


        service.clearCache = function () {
            service.cache = [];
        };

        service.setCampusesCache = function (campuses, coreId) {
           var cacheKey = 'getCampuses:' + coreId;
           service.cache[cacheKey] = campuses;
        };

        service.getCampuses = function (useCache, coreId) {
            useCache = useCache || false;
            var campuses = [],
                cacheKey = 'getCampuses:' + coreId,
                deferred = null;
            if (useCache) {
                deferred = $q.defer();
                if (service.cache[cacheKey]) {
                    deferred.resolve(service.cache[cacheKey]);
                    return deferred.promise;
                } else if (InstitutionFactory.getCoreId() === coreId) {
                    service.cache[cacheKey] = InstitutionFactory.getCampusData();
                    deferred.resolve(service.cache[cacheKey]);
                    return deferred.promise;
                }
            }
            return InstitutionsService.getInstitutionData(coreId, ['campus']).then(function (data) {
                if (data.results && data.results[0] && data.results[0].campus) {
                    campuses = data.results[0].campus;
                    service.cache[cacheKey] = campuses;
                    if (InstitutionFactory.getCoreId() === coreId) {
                        InstitutionFactory.setCampuses(campuses);
                    }
                }

                return campuses;
            });
        };

        service.getTmProfileData = function (coreId) {
            if (!coreId) {
                var deferred = $q.defer();
                deferred.resolve(false);
                return deferred.promise;
            }
            if (constants.dev) {
                var startTime = new Date().getTime(),
                    endTime;
            }

            return service.getProfileModel().get({
                id: coreId
            }).$promise.then(function (data) {
                if (constants.dev) {
                    endTime = new Date().getTime();
                    $log.log('success, got data: ', data, 'in ' + (endTime - startTime) + ' ms');
                }
                return data.toJSON();
            }, function (error) {
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        service.getSpecialisationsList = function () {
            // process already running
            if (!service.deferred.getSpecialisationsList) {
                service.deferred.getSpecialisationsList = $q.defer();
            } else {
                return service.deferred.getSpecialisationsList.promise;
            }
            var deferred = service.deferred.getSpecialisationsList;

            // check if data exists in cache
            if (service.specialisationsList) {
                deferred.resolve(service.specialisationsList);
            } else {
                var Api = $resource(constants.api.institutions.url + '/v1/list/specialisations');
                Api.get(function (data) {
                    if (constants.dev) {
                        $log.log('success, got data: ', data);
                    }
                    service.specialisationsList = data.results;
                    deferred.resolve(service.specialisationsList);
                    deferred = null;
                }, function (error) {
                    //@todo log error
                    if (constants.dev) {
                        $log.log('request failed' + error);
                    }
                    service.specialisationsList = [];
                    deferred.resolve(service.specialisationsList);
                    deferred = null;
                });
            }

            return deferred.promise;
        };

        /**
         * Send TM profile upgrade request.
         *
         * @param {String} tuProfileId
         * @param {String|null} comments
         * @returns {Promise}
         */
        service.sendUpgradeRequest = function (tmProfileId, comments) {
            if (!tmProfileId) {
                throw 'Missing Required';
            }

            var Api = service.getUpgradeRequestModel();
            return Api.save({ id: tmProfileId }, {
                comments: comments
            }).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }

                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        return service;
    };

    angular
        .module('qsHub')
        .service('TmProfileService', [
            '$resource',
            '$q',
            '$log',
            'constants',
            'InstitutionsService',
            'InstitutionFactory',
            App.services.TmProfile
        ]);

} (window.angular, window.moment));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { factories: {} });

    App.factories.TmProfile = function(
        TmProfileOverviewHistoryLogFactory
    ) {
        var data = {},
            program = {},
            backend = false,
            readOnly = false,
            selectedTabId = null,
            selectedMediaSubTabId = null,
            upgradeFormVisible = false,
            deletedProgramId = null,
            directoryVal = false,
            updatedProgramId = null,
            programAddFormVisible = false,
            programEditFormVisible = false,
            programSubscribeFormVisible = false,
            deleteFormVisible = false,
            programUpgradeFormVisible = false,
            programBeforeChanges = {},
            newProgram = false,
            programsDatagridReload = false,
            programsDatagridReloadUseCache = false,
            isUpgraded = false,
            profileTabs = {
                overview: 0,
                programs: 1,
                media: 2,
                publish: 3,
                programStats: 4
            },
            mediaTabs = {
                images: 1,
                video: 2,
                social: 3,
                brochures: 4
            },
            activeOverviewSubTabs = {
                'overview': true,
                'faq': false
            };

        return {
            setData: function(newData) {
                data = newData;
            },
            getData: function() {
                return data;
            },
            getProfileTabs : function () {
                return profileTabs;
            },
            getMediaTabs : function () {
                return mediaTabs;
            },
            setBackend: function (isBackend) {
                backend = isBackend;
            },
            isBackend: function () {
                return !!backend;
            },
            setReadOnly: function (isReadOnly) {
                readOnly = isReadOnly;
            },
            isReadOnly: function () {
                return !!readOnly;
            },
            reset: function() {
                data = {};
                program = {};
                newProgram = false;
                selectedMediaSubTabId = null;
                selectedTabId = null;
            },
            getId: function() {
                return data !== null && data.id || '';
            },
            getProgram: function() {
                return program;
            },
            setProgram: function (selectedProgram) {
                program = selectedProgram;
            },
            getProgramId: function () {
                return program && program.id ? program.id : null;
            },
            setSelectedTabId: function(newSelectedTabId) {
                selectedTabId = newSelectedTabId;
            },
            isProgramsTabSelected: function() {
                return (typeof selectedTabId !== 'undefined') && selectedTabId === profileTabs.programs;
            },
            isStatsTabSelected: function() {
                return (typeof selectedTabId !== 'undefined') && selectedTabId === profileTabs.programStats;
            },
            isPublishTabSelected: function() {
                return (typeof selectedTabId !== 'undefined') && selectedTabId === profileTabs.publish;
            },
            isOverviewTabSelected: function() {
                return (typeof selectedTabId !== 'undefined') && selectedTabId === profileTabs.overview;
            },
            isMediaTabSelected: function() {
                return (typeof selectedTabId !== 'undefined') && selectedTabId === profileTabs.media;
            },
            getOverviewTabId: function() {
                return profileTabs.overview;
            },
            setActiveOverviewSubTabs : function (newSubTabs) {
                if (angular.isDefined(newSubTabs)) {
                    if (angular.equals(0, newSubTabs)) {
                        activeOverviewSubTabs.overview = true;
                    }
                    if (angular.equals(1, newSubTabs)) {
                        activeOverviewSubTabs.overview = false;
                    }
                }
            },
            getActiveOverviewSubTabs : function () {
                return activeOverviewSubTabs;
            },
            isMediaImagesSubTabSelected: function() {
                return (typeof selectedMediaSubTabId !== 'undefined') && selectedMediaSubTabId === mediaTabs.images;
            },
            isMediaVideosSubTabSelected: function() {
                return (typeof selectedMediaSubTabId !== 'undefined') && selectedMediaSubTabId === mediaTabs.video;
            },
            isMediaSocialSubTabSelected: function() {
                return (typeof selectedMediaSubTabId !== 'undefined') && selectedMediaSubTabId === mediaTabs.social;
            },
            isMediaBrochuresSubTabSelected: function() {
                return (typeof selectedMediaSubTabId !== 'undefined') && selectedMediaSubTabId === mediaTabs.brochures;
            },
            getSelectedMediaSubTabId: function() {
                return selectedMediaSubTabId;
            },
            setSelectedMediaSubTabId: function(newSelectedSubTabId) {
                selectedMediaSubTabId = newSelectedSubTabId;
            },
            setMediaImagesSubTabSelected: function() {
                selectedMediaSubTabId = mediaTabs.images;
            },
            announceNewProgram: function (newProgramId) {
                newProgram = newProgramId;
            },
            hasNewProgram: function () {
                return newProgram;
            },
            announceProgramDeletion: function (newProgramId) {
                deletedProgramId = newProgramId;
            },
            hasDeletedProgram: function () {
                return !!deletedProgramId;
            },
            clearDeletedProgram: function () {
                deletedProgramId = null;
            },
            announceProgramUpdate: function (newProgramId) {
                updatedProgramId = newProgramId;
            },
            hasUpdatedProgram: function () {
                return !!updatedProgramId;
            },
            clearProgramUpdate: function () {
                updatedProgramId = null;
            },
            isRightSidePanelActive: function () {
                return programEditFormVisible || programAddFormVisible || deleteFormVisible || programUpgradeFormVisible;
            },
            setProgramBeforeChanges: function (programInstance) {
                programBeforeChanges = programInstance;
            },
            getProgramBeforeChanges: function () {
                return programBeforeChanges;
            },
            setUpgradeSubscription: function (val) {
                isUpgraded = val;
            },
            isUpgradedSubscriptions: function () {
                return isUpgraded;
            },

            /**
             * Set program edit form visibility.
             *
             * @param {boolean|null} visibility
             */
            setProgramEditFormVisibility: function (visibility) {
                if (typeof visibility === 'undefined') {
                    visibility = !programEditFormVisible;
                }
                programEditFormVisible = visibility;

                programAddFormVisible = false;
                programSubscribeFormVisible = false;
                upgradeFormVisible = false;
                deleteFormVisible = false;
                programUpgradeFormVisible = false;
                TmProfileOverviewHistoryLogFactory.closeAll();
            },
            isProgramEditFormVisible: function (activeTab) {
                if (typeof activeTab === 'undefined') {
                    return false;
                }
                return (activeTab === profileTabs.programs) && programEditFormVisible;
            },
            getProgramEditFormVisibility: function () {
                return programEditFormVisible;
            },
            /**
             * Set new program form visibility.
             *
             * @param {boolean|null} visibility
             */
            setProgramAddFormVisibility: function(visibility) {
                if (typeof visibility === 'undefined') {
                    visibility = !programAddFormVisible;
                }
                programAddFormVisible = visibility;

                programEditFormVisible = false;
                programSubscribeFormVisible = false;
                upgradeFormVisible = false;
                deleteFormVisible = false;
                programUpgradeFormVisible = false;
                TmProfileOverviewHistoryLogFactory.closeAll();
            },
            /**
             * Is new program form visible?
             *
             * @param {Integer} activeTab
             * @returns {boolean}
             */
            isProgramAddFormVisible: function(activeTab) {
                if (typeof activeTab === 'undefined') {
                    return false;
                }

                return (activeTab === profileTabs.programs) && programAddFormVisible;
            },

            /**
             * Set subscribe form visibility.
             *
             * @param {boolean|null} visibility
             */
            setProgramSubscribeFormVisibility: function(visibility) {
                if (typeof visibility === 'undefined') {
                    visibility = !programSubscribeFormVisible;
                }
                programSubscribeFormVisible = visibility;
            },

            getProgramSubscribeFormVisibility: function() {
                return programSubscribeFormVisible;
            },

            /**
             * Get new program form visibility.
             *
             * @param {boolean|null} visibility
             */
            getProgramAddFormVisibility: function() {
                return programAddFormVisible;
            },
            setUpgradeFormVisibility: function(visibility) {
                if (typeof visibility === 'undefined') {
                    visibility = !upgradeFormVisible;
                }
                upgradeFormVisible = visibility;

                programEditFormVisible = false;
                programAddFormVisible = false;
                deleteFormVisible = false;
                programUpgradeFormVisible = false;
                //TmProfileOverviewHistoryLogFactory.closeAll();

            },
            isUpgradeFormVisible: function(activeTab) {
                if (typeof activeTab === 'undefined') {
                    return false;
                }

                return (
                    activeTab === profileTabs.programs ||
                    activeTab === profileTabs.media ||
                    activeTab === profileTabs.overview
                 ) && upgradeFormVisible;
            },
            setDeleteFormVisibility: function(visibility) {
                if (typeof visibility === 'undefined') {
                    visibility = !deleteFormVisible;
                }
                deleteFormVisible = visibility;

                programEditFormVisible = false;
                programAddFormVisible = false;
                upgradeFormVisible = false;
                programUpgradeFormVisible = false;
                TmProfileOverviewHistoryLogFactory.closeAll();
            },
            isDeleteFormVisible: function(activeTab) {
                if (typeof activeTab === 'undefined') {
                    return false;
                }

                return activeTab === profileTabs.programs && deleteFormVisible;
            },
            setProgramUpgradeFormVisibility: function(visibility) {
                if (typeof visibility === 'undefined') {
                    visibility = !programUpgradeFormVisible;
                }
                programUpgradeFormVisible = visibility;

                programEditFormVisible = false;
                programAddFormVisible = false;
                upgradeFormVisible = false;
                deleteFormVisible = false;
                TmProfileOverviewHistoryLogFactory.closeAll();
            },
            isProgramUpgradeFormVisible: function(activeTab) {
                if (typeof activeTab === 'undefined') {
                    return false;
                }

                return activeTab === profileTabs.programs && programUpgradeFormVisible;
            },
            closeRightSidePanels: function () {
                // close history logs
                TmProfileOverviewHistoryLogFactory.closeAll();
                // close upgrade form
                upgradeFormVisible = false;
                // close program edit form
                programEditFormVisible = false;
                // close program add form
                programAddFormVisible = false;
            },
            resetProgramsDatagridReload: function () {
                programsDatagridReload = false;
                programsDatagridReloadUseCache = false;
            },
            requestProgramsDatagridReload: function(useCache) {
                programsDatagridReloadUseCache = useCache || false;
                programsDatagridReload = true;
            },
            programsDatagridReloadUseCache: function () {
                return programsDatagridReloadUseCache;
            },
            isProgramsDatagridReload: function () {
                return programsDatagridReload;
            },
            setDirectory: function(newVal){
                directoryVal = newVal;
            },
            isDirectory: function(){
                return directoryVal;
            }
        };
    };

    angular
        .module('qsHub')
        .factory('TmProfileFactory', [
            'TmProfileOverviewHistoryLogFactory',
            App.factories.TmProfile
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {factories:{}});

    App.factories.TmProfileHistoryLog = function () {
        var data = {},
            limit = 1,
            profileType = null,
            inProgress = false,
            loadMoreInProgress = false,
            visible = false,
            reload = false,
            isAdvanced = false,
            triggeredBy = null;

        return {
            getData: function () {
                return data;
            },
            setData: function (newData) {
                data = newData;
            },
            setLogLimit: function (logLimit) {
                if (!logLimit || logLimit < 1 || typeof logLimit === 'undefined') {
                    logLimit = 1;
                }
                limit = logLimit;
            },
            getLogLimit: function () {
                return limit;
            },
            setProfileType: function (newProfileType) {
                profileType = newProfileType;
            },
            getProfileType: function () {
                return profileType;
            },
            isInProgress: function () {
                return inProgress;
            },
            setInProgress: function (status) {
                inProgress = status;
            },
            isLoadMoreInProgress: function () {
                return loadMoreInProgress;
            },
            setLoadMoreInProgress: function (status) {
                loadMoreInProgress = status;
            },
            isVisible: function () {
                return visible;
            },
            setTriggeredBy: function (trigger) {
                triggeredBy = trigger;
            },
            shouldBeVisible: function (trigger) {
                if (triggeredBy === trigger) {
                    triggeredBy = null;
                    return false;
                }
                return true;
            },
            resetTriggeredBy: function () {
                triggeredBy = '';
            },
            setAdvanced: function (advanced) {
                isAdvanced = advanced;
            },
            isAdvanced: function () {
                return isAdvanced;
            },
            setReload: function(triggerReload) {
                reload = triggerReload;
            },
            isReloadRequired: function() {
                return reload;
            }
        };
    };

    angular
        .module('qsHub')
        .factory('TmProfileHistoryLogFactory', [
            '$resource',
            '$q',
            '$log',
            'constants',
            App.factories.TmProfileHistoryLog
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers:{}});

    App.controllers.tmProfileResubscribe = function (
        $scope,
        TmProfileResubscribeService,
        InstitutionFactory,
        TmProfileFactory,
        UserFactory,
        NotifierFactory,
        WatchService
    ) {
        var controller = this,
            alreadyInitialised = false,
            displayResubscribeBannerDays = 31;

        controller.profileId = '';
        controller.expiresDate = '';
        controller.isAdvanced = false;
        controller.displayResubscribeForm = false;
        controller.inProgress = false;
        /**
         * Data used in resubscribe form
         * @type {{comments: string, name: string, email: string, to: string, subject: string}}
         */
        controller.formData = {
            'comments': '',
            'name': '',
            'email': '',
            'to': 'tmsupport@qs.com',
            'subject': 'I would like more information about Resubscribing an Advance Profile'
        };

        /**
         * Re-init form data
         */
        controller.initFormData = function () {
            controller.formData.comments = '';
        };

        /**
         * Resubscribe button click
         */
        controller.resubscribeClick = function () {
            controller.initFormData();
            controller.displayResubscribeForm = !controller.displayResubscribeForm;
        };

        /**
         * Cancel button click
         */
        controller.cancelClick = function() {
            controller.initFormData();
            controller.resubscribeClick();
        };

        controller.createCallback = function(response) {
            if (response) {
                controller.initFormData();
                controller.resubscribeClick();
            }
            controller.inProgress = false;
            NotifierFactory.show(
                response ? 'success' : 'error',
                response ? 'Request sent successfully!' : 'Request sending failed!',
                'Resubscribe Request'
            );
        };

        /**
         * Submit form button click
         */
        controller.submitClick = function () {
            controller.inProgress = true;
            TmProfileResubscribeService.create(
                controller.profileId,
                controller.formData.comments,
                controller.formData.name,
                controller.formData.email
            ).then(controller.createCallback);
        };

        /**
         * Check if we should show the resubscribe button
         * Button should display when subscription expires in less than 1 month
         *
         * @returns {boolean}
         */
        controller.showResubscribeButton = function () {
            // calculate if subscription expires in less than one month
            if (controller.expiresDate) {
                var expiresDate, todayDate, daysLeft, leftMs;
                expiresDate = new Date(parseInt(controller.expiresDate, 10));
                todayDate = new Date();
                leftMs = expiresDate.getTime() - todayDate.getTime();
                daysLeft = Math.ceil(Math.abs(leftMs)) / (1000 * 3600 * 24);

                return leftMs > 0 ? daysLeft < displayResubscribeBannerDays : false;
            }

            return false;
        };

        /**
         * Init watches
         */
        controller.initWatches = function () {
            // watch for active institution changes
            WatchService.create($scope, TmProfileFactory.getData, function (newValue) {
                if (newValue !== null) {
                    controller.profileId = newValue.id;
                }
            });
            WatchService.create($scope, InstitutionFactory.getData, function (newValue) {
                controller.isAdvanced = InstitutionFactory.isTmAdvanced();
                if (newValue !== null && !angular.equals({}, newValue)) {
                    controller.expiresDate = angular.isDefined(newValue.subscriptions.tm) ? newValue.subscriptions.tm.endDate : '';
                }
                controller.formData.name = UserFactory.getFullName();
                controller.formData.email = UserFactory.getUserName();
            });
        };

        /**
         * @constructor
         */
        controller.init = function () {
            controller.initWatches();
        };

        /**
         * listen to publish tab visibility changes
         */
        WatchService.create($scope, TmProfileFactory.isPublishTabSelected, function (isActive) {
            // if not already initialized and tab is active
            if (!alreadyInitialised && isActive) {
                controller.init();
                alreadyInitialised = true;
            }
        });
    };

    angular
        .module('qsHub')
        .controller('TmProfileResubscribeController', [
            '$scope',
            'TmProfileResubscribeService',
            'InstitutionFactory',
            'TmProfileFactory',
            'UserFactory',
            'NotifierFactory',
            'WatchService',
            App.controllers.tmProfileResubscribe
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    App.services.TmProfileResubscribeService = function (
        $resource,
        $log,
        constants
    ) {
        var service = {
            deferred : {}
        };

        /**
         * Get resubscribe API endpoint.
         *
         * @returns {$resource}
         */
        service.getResubscribeModel = function () {
            return $resource(constants.api.institutions.url + '/v1/tm-profile/:id/resubscribe-request');
        };

        /**
         * Send resubscribe email.
         *
         * @param {String} profileId
         * @param {String} comments
         * @param {String} fromName
         * @param {String} fromEmail
         * @returns {Promise}
         */
        service.create = function(profileId, comments, fromName, fromEmail) {
            comments = (typeof comments === 'undefined') ? '' : comments;
            fromName = (typeof fromName === 'undefined') ? '' : fromName;
            fromEmail = (typeof fromEmail === 'undefined') ? '' : fromEmail;
            return service.getResubscribeModel().save(
                {id: profileId},
                {
                    comments: comments,
                    name: fromName,
                    email: fromEmail
                }
            ).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }

                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }

                return false;
            });
        };

        return service;
    };

    angular
        .module('qsHub')
        .service('TmProfileResubscribeService', [
            '$resource',
            '$log',
            'constants',
            App.services.TmProfileResubscribeService
        ]);

}(window.angular));

(function (angular, moment) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { controllers: {} });

    App.controllers.tmProfilePrograms = function (
        $scope,
        $rootScope,
        $state,
        constants,
        uiGridConstants,
        TmProfileFactory,
        InstitutionFactory,
        UserFactory,
        TmProfileProgramsService,
        TmProfileService,
        InstitutionsService,
        InstitutionsListService,
        WatchService,
        GridService,
        TmDirectoryService,
        $timeout
    ) {
        var controller = this,
            alreadyInitialised = false,
            filterTimeoutMilliseconds = constants.datagridInputDelay;
        controller.isDatagridReloading = true;
        controller.isDatagridRendered = false;
        controller.devMode = constants.dev;
        controller.programsPackage = [
            { value: 'false', label: 'Basic' },
            { value: 'true', label: 'Advanced' }
        ];
        $scope.isAdvanced = false;
        controller.institution = {};

        controller.paginationOptions = GridService.getColumnsFilters(
            constants.gridNameSpaces.programsTmGridFilters,
            []
        );

        controller.columnsVisibility = GridService.getColumnsVisibility(
            constants.gridNameSpaces.programsTmGridVisibility
        );

        controller.handleDatagridRowClick = function (program) {
            TmProfileFactory.setProgramUpgradeFormVisibility(false);
            if ($scope.selectedProgramId === program.id) {
                $scope.selectedProgramId = null;
                TmProfileFactory.setProgramEditFormVisibility(false);
                TmProfileFactory.setProgram({});
            } else {
                $scope.selectedProgramId = program.id;
                TmProfileFactory.setProgramEditFormVisibility(true);
                if (Array.isArray(program.stats) && program.stats.length < 1) {
                    program.stats = {};
                }
                TmProfileFactory.setProgram(program);
                TmProfileFactory.setReadOnly(
                    TmProfileService.isProgramPendingDeletion(program.status) || program.advanced
                );
            }
        };

        controller.handleDatagridFiltersChanges = function (grid) {
            GridService.resetConditions($scope.gridApi.grid.columns);
            angular.forEach($scope.gridApi.grid.columns, function (column) {
                if (typeof column.filters !== 'undefined' && column.filters[0].term !== 'undefined') {
                    if (
                        column.filters[0].type === uiGridConstants.filter.SELECT &&
                        typeof column.filters[0].term === 'object' &&
                        column.filters[0].term !== null &&
                        typeof column.filters[0].term.value !== 'undefined'
                    ) {
                        column.filters[0].term = column.filters[0].term.value;
                    } else if (column.filters[0].term !== null &&
                        typeof column.filters[0].term !== 'undefined') {
                        GridService.applyFilters(column);
                    }

                    if (column.filters[0].type === uiGridConstants.filter.SELECT) {
                        column.filters[0].condition = uiGridConstants.filter.EXACT;
                    }
                }

                var filterColumn = column.filters[0].column ? column.filters[0].column : column.field;
                if (typeof column.filters !== 'undefined' && column.filters[0].term !== 'undefined') {
                    controller.paginationOptions['filter[' + filterColumn + ']'] = column.filters[0].term;
                }

                if (filterColumn === 'productsAsString') {
                    var products = '',
                        separator = ', ',
                        count = column.filters[0].term.length - 1,
                        prods = TmDirectoryService.getOrderProductsFilter(column.filters[0].term);
                    if (prods.length > 0) {
                        for (var i = 0; i < prods.length; i++) {
                            if (count === i) {
                                separator = '';
                            }
                            products += prods[i].charAt(0).toUpperCase() + prods[i].slice(1) + separator;
                        }
                        controller.paginationOptions['filter[' + filterColumn + ']'] = '=' + products;
                    }
                } else {
                    controller.paginationOptions['filter[' + filterColumn + ']'] = column.filters[0].term;
                }
            });

            GridService.storeColumnsFilters(
                constants.gridNameSpaces.programsTmGridFilters,
                controller.paginationOptions
            );

            var extraParams = getExtraParams();
            if (angular.isDefined($scope.filterTimeout)) {
                $timeout.cancel($scope.filterTimeout);
            }

            $scope.filterTimeout = $timeout(function () {
                controller.reloadDatagrid(extraParams);
                filterTimeoutMilliseconds = constants.datagridInputDelay;
            }, filterTimeoutMilliseconds);
        };

        function getExtraParams() {
            var extraParams = {};
            angular.forEach(controller.paginationOptions, function (option, key) {
                if (angular.isDefined(option) &&
                    (typeof option === 'string' &&
                    option !== '') ||
                    typeof option === 'boolean'
                ) {
                    if (key === 'filter[coreId]') {
                        extraParams['filter[coreIdAsString]'] = option;
                    } else {
                        extraParams[key] = option;
                    }
                }
            });

            return extraParams;
        }

        controller.isClient = function () {
            return UserFactory.isClient();
        };

        controller.reloadDatagrid = function (extraParams) {
            var coreId = InstitutionFactory.getCoreId();
            if (!coreId) {
                return false;
            }
            controller.isDatagridReloading = true;
            return TmProfileProgramsService.getAllProgramsByCoreId(coreId, extraParams).then(controller.handleProgramsListResponse);
        };

        /**
         * Actions to do when add program button is clicked.
         */
        controller.handleAddProgramClick = function () {
            $scope.selectedProgramId = null;
            TmProfileFactory.setProgramAddFormVisibility();
            TmProfileFactory.setProgram({});
        };

        controller.getProgramsToDelete = function () {
            var programsIds = [];
            if ($scope.programsToDelete) {
                angular.forEach($scope.programsToDelete, function (remove, id) {
                    if (remove) {
                        programsIds.push(id);
                    }
                });
            }

            return programsIds;
        };

        controller.handleProgramsListResponse = function (response) {
            controller.isDatagridReloading = false;
            if (angular.isDefined($scope.gridOptions)) {
                $scope.gridOptions.data = response;
                $scope.gridOptions.minRowsToShow = response && response.length < 50 ? response.length : 50;
                $scope.gridOptions.totalItems = response.length;
            }
            // if the program is selected, set program from response
            if ($scope.selectedProgramId) {
                angular.forEach($scope.gridOptions.data, function (item) {
                    if (angular.equals(item.id, $scope.selectedProgramId)) {
                        TmProfileFactory.setProgram(item);
                        TmProfileFactory.setReadOnly(!!item.advanced);
                    }
                });
            }
        };

        controller.linkSubscriptions = function (coreId) {
            InstitutionsService.getInstitutionData(coreId).then(function (data) {
                var institutionData = data && data.results ? data.results[0] : null;
                if (institutionData && institutionData.linkedWithAdvancedPrograms) {
                    InstitutionsListService.getAllSubscriptions(coreId).then(function (res) {
                        res.forEach(function (item) {
                            controller.institution = angular.copy(institutionData);
                            if (item >= institutionData.subscriptions.tm.endDate) {
                                controller.institution.subscriptions.tm.endDate = item;
                            }
                            InstitutionsListService.saveSubscription(controller.institution).then(function (success) {
                                if (success) {
                                    // check if subscription data is in the past or in the future
                                    if (controller.institution.subscriptions) {
                                        angular.forEach(controller.institution.subscriptions, function (subscription) {
                                            if (subscription.subscribed &&
                                                subscription.advanced &&
                                                subscription.endDate < moment().format('x')
                                            ) {
                                                subscription.advanced = false;
                                            }
                                            if (subscription.subscribed &&
                                                subscription.advanced &&
                                                subscription.startDate > moment().format('x')
                                            ) {
                                                subscription.advanced = false;
                                            }
                                        });
                                    }
                                    // reset institution edit instance
                                    $scope.institutionBeforeChanges = angular.copy(controller.institution);
                                    InstitutionsListService.appendSubscriptionsData([controller.institution]);
                                    // check if current active institution is same as edited one
                                    var currentCoreId = controller.institution.coreId;
                                    if (currentCoreId) {
                                        var activeCoreId = InstitutionFactory.getCoreId();
                                        if (activeCoreId && currentCoreId === activeCoreId) {
                                            InstitutionFactory.setSubscriptions(angular.copy(controller.institution.subscriptions));
                                        }
                                    }

                                }
                            });
                        });
                    });
                }
            });
        };


        /**
         * Actions to do when program is deleted.
         * @param {Boolean} deleted
         */
        controller.hasDeletedProgramsWatch = function (deleted) {
            if (deleted) {
                // reload datagrid
                controller.initDataGrid(false);
                // reset deletion trigger
                TmProfileFactory.clearDeletedProgram();
                // unselect selected program
                $scope.selectedProgramId = null;
            }
        };

        /**
         * Actions to do when program is updated.
         * @param {Boolean} updated
         */
        controller.hasUpdatedProgramWatch = function (updated) {
            if (updated) {
                // reload datagrid
                controller.initDataGrid(false);
                // reset update trigger
                TmProfileFactory.clearProgramUpdate();
            }
        };

        /**
         * Actions to do when right hand panel visibility changes.
         * @param {Boolean} active
         */
        controller.isRightSidePanelActiveWatch = function (active) {
            if (!active) {
                $scope.selectedProgramId = null;
            }
        };

        /**
         * Actions to do on Core ID changes.
         * @param {Number|null} coreId
         */
        controller.getCoreIdWatch = function (coreId) {
            if (coreId) {
                $scope.selectedProgramId = null;
                // use cache as institution data should be loaded already
                controller.initDataGrid(true);
                controller.linkSubscriptions(coreId);
            }
        };

        /**
         * Actions to do when new program is added.
         * @param {Boolean} added
         */
        controller.hasNewProgramWatch = function (added) {
            if (added) {
                $scope.selectedProgramId = added;
                controller.initDataGrid(false);
            }
        };

        /**
         * Actions to do when new program is upgraded.
         * @param {Boolean} added
         */
        controller.hasNewProgramUpgradeWatch = function (upgraded) {
            if (upgraded) {
                var core_id = InstitutionFactory.getCoreId();
                if (core_id) {
                    controller.linkSubscriptions(core_id);
                }
            }
        };

        /**
         * Actions to do when datagrid reload is requested.
         * @param {Boolean} reload
         */
        controller.isProgramsDatagridReloadWatch = function (reload) {
            if (reload) {
                controller.initDataGrid(TmProfileFactory.programsDatagridReloadUseCache());
                TmProfileFactory.resetProgramsDatagridReload();
            }
        };

        controller.handleGridVisibleChanges = function () {
            var columnsVisibility = GridService.getColumnsVisibility(constants.gridNameSpaces.programsTmGridVisibility),
            reload = false;
            if (columnsVisibility) {
                angular.forEach($scope.gridOptions.columnDefs, function (column, key) {
                    if (columnsVisibility[column.field] === true &&
                        column.visible === false
                    ) {
                        if (typeof  $scope.gridOptions.columnDefs[key].filter === 'undefined') {
                            $scope.gridOptions.columnDefs[key].filter = {};
                        }
                        if (typeof  $scope.gridOptions.columnDefs[key].filter !== 'undefined' &&
                            $scope.gridOptions.columnDefs[key].filter.hasOwnProperty('term') &&
                            $scope.gridOptions.columnDefs[key].filter.term) {
                            reload = true;
                        }
                        $scope.gridOptions.columnDefs[key].filter.term = null;
                    }
                });
            }

            controller.columnsVisibility = GridService.getGridColumnsVisibility($scope.gridOptions.columnDefs);
            GridService.storeColumnsVisibility(
                constants.gridNameSpaces.programsTmGridVisibility,
                controller.columnsVisibility
            );
            if (reload) {
                controller.reloadDatagrid();
            }
        };

        controller.initWatches = function () {
            // listen to core ID changes
            WatchService.create($scope, InstitutionFactory.getCoreId, controller.getCoreIdWatch);
            // listen to deleted programs announcements
            WatchService.create($scope, TmProfileFactory.hasDeletedProgram, controller.hasDeletedProgramsWatch);
            // listen to programs updates announcements
            WatchService.create($scope, TmProfileFactory.hasUpdatedProgram, controller.hasUpdatedProgramWatch);
            // listen to datagrid reload requests
            WatchService.create($scope, TmProfileFactory.isProgramsDatagridReload, controller.isProgramsDatagridReloadWatch);
            // listen to right side panel visibility changes
            WatchService.create($scope, TmProfileFactory.isRightSidePanelActive, controller.isRightSidePanelActiveWatch);
            // listen to new programs
            WatchService.create($scope, TmProfileFactory.hasNewProgram, controller.hasNewProgramWatch);
            // listen to new program upgrade
            WatchService.create($scope, TmProfileFactory.isUpgradedSubscriptions, controller.hasNewProgramUpgradeWatch);

            $rootScope.$on(constants.events.logout, controller.destruct);
        };

        controller.initDataGrid = function (useCache) {
            var selectTemplate = '/scripts/shared/ui-grid/templates/selectFilterHeaderTemplate.html',
                selectCountCellTemplate = '/scripts/shared/ui-grid/templates/selectCountCellTemplate.html',
                selectCellTemplate = '/scripts/shared/ui-grid/templates/selectCellTemplate.html',
                coreId = InstitutionFactory.getCoreId();
            if (!coreId) {
                return false;
            }
            controller.isDatagridReloading = true;
            var multiSelectTemplate = '/scripts/shared/ui-grid/templates/multiSelectFilterHeaderTemplate.html';
            TmProfileService.getCampusesKeyValuePairs(useCache).then(function (campusesList) {
                controller.campusesList = campusesList;
                $scope.gridOptions = {
                    enableSorting: true,
                    exporterMenuCsv: false,
                    enableGridMenu: true,
                    showGridFooter: true,
                    selectedItems: $scope.selectedProgramId,
                    enableFiltering: true,
                    useExternalFiltering: true,
                    enableColumnResize: true,
                    enableFullRowSelection: true,
                    enableRowSelection: true,
                    multiSelect: false,
                    enableRowHeaderSelection: false,
                    rowTemplate: '/scripts/components/profiles/tm/programs/datagrid/rowTemplate.html',
                    columnDefs: [{
                        displayName: 'Advanced',
                        field: 'advanced',
                        enableSorting: true,
                        enableFiltering: true,
                        enableColumnResizing: false,
                        cellTemplate: '/scripts/components/profiles/tm/programs/datagrid/advancedCellTemplate.html',
                        maxWidth: 108,
                        filter: {
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: controller.programsPackage,
                            term: GridService.getFilterByField(controller.paginationOptions, 'advanced')
                        },
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'advanced', true),
                        filterHeaderTemplate: selectTemplate
                    },
                    {
                        displayName: 'Core ID',
                        field: 'coreId',
                        maxWidth: 120,
                        enableHiding: !controller.isClient(),
                        visible: controller.isClient()? false : GridService.getVisibilityByField(controller.columnsVisibility, 'coreId', true),
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'coreId', ''),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        displayName: 'Program Name',
                        field: 'name',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'name', true),
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'name', ''),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    }, {
                        displayName: 'Program Type',
                        field: 'type',
                        filter: {
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: TmProfileService.getProgramTypesList(),
                            term: GridService.getFilterByField(controller.paginationOptions, 'type')
                        },
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'type', true),
                        filterHeaderTemplate: selectTemplate,
                        cellTemplate: selectCellTemplate
                    }, {
                        enableFiltering: true, // @todo allow filtering by campus
                        displayName: 'Campus',
                        field: 'campus',
                        filter: {
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: controller.campusesList,
                            term: GridService.getFilterByField(controller.paginationOptions, 'campus')
                        },
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'campus', true),
                        filterHeaderTemplate: selectTemplate,
                        cellTemplate: '/scripts/components/tmDirectory/datagrid/campusCellTemplate.html'
                    },
                    {
                        displayName: 'Products',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'productsAsString', true),
                        filter: {
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: TmProfileService.getProductsList(true),
                            term: TmDirectoryService.getMultiSelectTerm(
                                GridService.getFilterByField(controller.paginationOptions, 'productsAsString')
                            )
                        },
                        field: 'productsAsString',
                        maxWidth: 130,
                        filterHeaderTemplate: multiSelectTemplate
                    },
                    {
                        displayName: 'Status',
                        filter: {
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: TmProfileService.getProgramStatusList(),
                            term: GridService.getFilterByField(controller.paginationOptions, 'status'),
                            condition: uiGridConstants.filter.EXACT
                        },
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'status', true),
                        field: 'status',
                        maxWidth: 130,
                        filterHeaderTemplate: selectTemplate,
                        cellTemplate: selectCellTemplate
                    },
                    {
                        displayName: 'Has Campus',
                        field: 'campus',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'campus', false),
                        filter: {
                            column: 'hasCampus',
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: [
                                { value: true, label: 'Yes' },
                                { value: false, label: 'No' },
                            ],
                            searchEnabled: true,
                            term: GridService.getFilterByField(controller.paginationOptions, 'hasCampus')
                        },
                        filterHeaderTemplate: selectTemplate,
                        cellTemplate: selectCountCellTemplate
                    }],
                    onRegisterApi: function (gridApi) {
                        var columnsVisibilityBeforeChanges = angular.copy($scope.gridOptions.columnDefs);
                        $scope.gridApi = gridApi;
                        gridApi.selection.on.rowSelectionChanged($scope, function (row) {
                            controller.handleDatagridRowClick(row.entity);
                        });
                        gridApi.core.on.rowsRendered($scope, function () {
                            controller.isDatagridRendered = true;
                        });
                        gridApi.core.on.filterChanged($scope, function () {
                            controller.handleDatagridFiltersChanges(this.grid);
                        });
                        gridApi.core.on.rowsVisibleChanged($scope, controller.handleGridVisibleChanges);
                        // reset columns visibility
                        GridService.resetExtend(columnsVisibilityBeforeChanges, $scope.gridOptions.columnDefs);
                    }
                };
                if (InstitutionFactory.isTmSubscribed() && !UserFactory.noAccess($state.current.name)) {
                    var extraParams = getExtraParams();
                    TmProfileProgramsService.getAllProgramsByCoreId(coreId, extraParams).then(controller.handleProgramsListResponse);
                }
            });
        };

        controller.destruct = function () {
            alreadyInitialised = false;
        };
        controller.init = function () {
            controller.initWatches();
        };

        // listen to departments tab visibility changes
        WatchService.create($scope, TmProfileFactory.isProgramsTabSelected, function (isActive, wasActive) {
            // if not already initialized and tab is active
            if (!alreadyInitialised && isActive) {
                controller.init();
                alreadyInitialised = true;
            } else if (alreadyInitialised && isActive && !wasActive) {
                $scope.selectedProgramId = null;
            }
        });
    };

    angular
        .module('qsHub')
        .controller('TmProfileProgramsController', [
            '$scope',
            '$rootScope',
            '$state',
            'constants',
            'uiGridConstants',
            'TmProfileFactory',
            'InstitutionFactory',
            'UserFactory',
            'TmProfileProgramsService',
            'TmProfileService',
            'InstitutionsService',
            'InstitutionsListService',
            'WatchService',
            'UiGridService',
            'TmDirectoryService',
            '$timeout',
            App.controllers.tmProfilePrograms
        ]);

} (window.angular, window.moment));

(function(angular, moment) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { services: {} });

    App.services.TmProfileProgramsService = function(
        $resource,
        $q,
        $log,
        constants,
        RequestsQueueService
    ) {
        var service = {
            deferred: {},
            hasErrors: false
        };

        /**
         * Get programs list API endpoint.
         *
         * @returns {$resource}
         */
        service.getProgramsListModel = function(extraParams) {
            if (!extraParams) {
                extraParams = '';
            }
            return $resource(
                constants.api.institutions.url + '/v1/list/tm-programs?filter[parentInstitutionCoreId]==:coreId&page=1&limit=500&sorting[createdAt]=desc',
                extraParams,
                {
                    get: {
                        cancellable: true
                    }
                }
            );
        };

        /**
         * Get programs update API endpoint.
         *
         * @returns {$resource}
         */
        service.getUpdateModel = function() {
            return $resource(constants.api.institutions.url + '/v1/tm-program/:id', null, {
                update: { method: 'PATCH' }
            });
        };

        /**
         * Get programs create API endpoint.
         *
         * @returns {$resource}
         */
        service.getCreateModel = function() {
            return $resource(constants.api.institutions.url + '/v1/tm-program');
        };

        /**
         * Get programs downgrade API endpoint.
         *
         * @returns {$resource}
         */
        service.getDowngradeModel = function () {
            return $resource(constants.api.institutions.url + '/v1/tm-program/:id/downgrade');
        };

        /**
         * Get programs upngrade API endpoint.
         *
         * @returns {$resource}
         */
        service.getUpgradeModel = function () {
            return $resource(constants.api.institutions.url + '/v1/tm-program/:id/upgrade', null, {
                update: { method: 'PATCH' }
            });
        };

        /**
         * Sets has errors flag.
         *
         * @param {boolean} hasErrors
         */
        service.setHasErrors = function(hasErrors) {
            service.hasErrors = hasErrors;
        };

        /**
         * Gets has errors flag.
         *
         * @returns {boolean|*}
         */
        service.getHasErrors = function() {
            return service.hasErrors;
        };

        /**
         * Update program.
         *
         * @param {Object} program
         * @returns {Promise}
         */
        service.update = function(program) {
            // @todo validate data
            return service.getUpdateModel().update({id: program.id}, program).$promise.then(function(data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function(error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        /**
         * Delete program.
         *
         * @param {Number} id
         * @returns {Promise}
         */
        service.delete = function(id) {
            // @todo validate data
            return service.getUpdateModel().delete({id: id}).$promise.then(function(data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function(error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        service.deleteRequest = function(id, comments) {
            // @todo validate data
            return service.getUpdateModel().delete({id: id, comments: comments}).$promise.then(function(data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function(error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        /**
         * Create program.
         *
         * @param {Object} program
         * @returns {Promise}
         */
        service.create = function(program) {
            // @todo validate data
            return service.getCreateModel().save(null, program).$promise.then(function(data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return data;
            }, function(error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        /**
         * Downgrade advanced program.
         *
         * @param {Number} id  - program ID
         * @returns {Promise}
         */
        service.downgrade = function(id) {
            // @todo validate data
            return service.getDowngradeModel().get({id: id}).$promise.then(function(data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function(error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        /**
         * Upgrade to advanced program.
         *
         * @param {String} id
         * @param {String|null} comments
         * @param {String|null} startDate
         * @param {String|null} endDate
         * @returns {Promise.<TResult>}
         */
        service.upgrade = function(id, comments, startDate, endDate) {
            var dataRequest = {};
            if (startDate) {
                dataRequest.startDate = startDate;
            }
            if (endDate) {
                dataRequest.endDate = endDate;
            }
            if (comments) {
                dataRequest.comments = comments;
            }
            // @todo validate data
            return service.getUpgradeModel().update({id: id}, dataRequest).$promise.then(function(data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return data.insertedCoreId ? data.insertedCoreId : true;
            }, function(error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };


        /**
         * Get all programs by parent institution Core Id
         *
         * @param {Number} coreId
         * @returns {Promise.<TResult>}
         */
        service.getAllProgramsByCoreId = function(coreId, extraParams, dashboard) {
            if (constants.dev) {
                var startTime = new Date().getTime(),
                    endTime;
            }
            return service.getProgramsListModel(extraParams).get({
                coreId: coreId
            }).$promise.then(function(data) {
                if (constants.dev) {
                    endTime = new Date().getTime();
                    $log.log('success, got data: ', data, 'in ' + (endTime - startTime) + ' ms');
                }
                if (!!dashboard) {
                    return data;
                }

                return (data.results);
            }, function(error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return [];
            });
        };

        /**
         * Get programs list.
         * @todo check if used
         * @param {Object} params
         * @returns {Promise}
         */
        service.getAllPrograms = function(params) {
            var coreId = params.parameters().coreId;
            if (constants.dev) {
                var startTime = new Date().getTime(),
                    endTime;
            }

            // cancel currently running requests
            RequestsQueueService.cancelAll('getAllPrograms');

            var Api = service.getProgramsListModel().get(params.url(), { coreId: coreId });

            // add new request to the queue
            RequestsQueueService.add(Api, 'getAllPrograms');

            return Api.$promise.then(function(data) {
                if (constants.dev) {
                    endTime = new Date().getTime();
                    $log.log('success, got data: ', data, 'in ' + (endTime - startTime) + ' ms');
                }
                // cancel currently running requests
                RequestsQueueService.cancelAll('getAllPrograms');

                params.total(data.totalFiltered);
                params.totalEntries = data.totalMatching;

                return (data.results);
            }, function(error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return [];
            });
        };

        service.getTmpCampusId = function () {
            return 'tmp_' + moment();
        };

        service.isTmpCampusId = function (id) {
            return id.indexOf('tmp_') === 0;
        };

        return service;
    };

    angular
        .module('qsHub')
        .service('TmProfileProgramsService', [
            '$resource',
            '$q',
            '$log',
            'constants',
            'RequestsQueueService',
            App.services.TmProfileProgramsService
        ]);

}(window.angular, window.moment));

(function (angular, moment) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { controllers: {} });

    App.controllers.tmProfileProgramForm = function (
        $scope,
        $state,
        $timeout,
        constants,
        NotifierFactory,
        CampusesService,
        TmProfileFactory,
        InstitutionFactory,
        TmProfileProgramsService,
        UserFactory,
        InstitutionsListService,
        TmProfileService,
        LoginService,
        ModalService,
        TmProfileProgramValidationService,
        WatchService,
        TmDirectoryService,
        UiSelectService,
        TimeService,
        TextService
    ) {
        var controller = this,
            alreadyInitialised = false,
            isCampusUpdated = false;

        controller.devMode = constants.dev;
        controller.programDescriptionWordLimit = 500;
        controller.validationProgramDescriptionWordLimit =
            'Program description has exceeded the limit of ' +
            controller.programDescriptionWordLimit +
            ' words. Please reduce number of words.';
        controller.typesList = TmProfileService.getProgramTypesList();
        controller.productsList = TmProfileService.getProductsList();
        controller.typesArray = [];
        controller.deliveryMethodsArray = [];
        controller.feesRangesList = TmProfileService.getFeesRangesList();
        controller.monthsList = TmProfileService.getMonthsList();
        controller.accreditationsList = TmProfileService.getAccreditationsList();
        controller.lengthList = TmProfileService.getLengthList();
        controller.percentList = TmProfileService.getPercentList();
        controller.yearList1850 = TmProfileService.getYearList(18, 50);
        controller.yearList020 = TmProfileService.getYearList(0, 20);
        controller.offerScholarshipsList = UiSelectService.getYesNoOptions();
        controller.deliveryMethodsList = TmProfileService.getDeliveryMethodsList();
        controller.isNewCampus = false;
        controller.isEditCampus = false;
        controller.campusSubmitInProgress = false;
        controller.programSubmitInProgress = false;
        controller.downgradeInProgress = false;
        controller.upgradeInProgress = false;
        controller.addInProgress = false;
        controller.deleteInProgress = false;
        controller.editInProgress = false;
        controller.forms = {};
        controller.newCampus = {
            autoGenerate: false
        };
        controller.oldCampus = {};
        controller.newProgram = {};
        controller.program = {};
        controller.oldProgramCampuses = [];
        controller.isBackend = false;
        controller.isReadOnly = false;
        controller.subscription = {};
        controller.institution = null;
        controller.programCampuses = [];
        controller.isDirectory = false;
        controller.submitted = false;
        controller.campusSubmitted = false;
        controller.campusesWithChanges = [];
        controller.campusesFullList = [];
        controller.isSchoolUser = null;
        controller.tmSupportEmail = constants.support.tm;
        controller.specialisationsArray = [];
        $scope.showResubscribeBanner = false;
        $scope.showSubscriptionForm = false;
        $scope.showEdiProgramForm = true;
        controller.descriptionWords = 0;
        controller.textEditorOptions = {
            disableDragAndDrop: true,
            styleWithSpan: false,
            dialogsInBody: true,
            minHeight: 280,
            maxHeight: 280,
            toolbar: [],
            popover: {
                link: []
            }
        };
        controller.textEditorOptions = angular.copy(controller.textEditorOptions);

        /**
         * Count words in the string.
         *
         * @param {string} text
         */
        controller.countWords = function (text) {
            return TextService.countWords(text);
        };

        $scope.datePickerTm = {
            date: {
                startDate: null,
                endDate: null
            },
            options: {
                ranges: {
                    '6 months from today': [moment(), moment().add(6, 'months')],
                    '12 months from today': [moment(), moment().add(12, 'months')]
                },
                eventHandlers: {
                    'apply.daterangepicker': function (ev) {
                        $scope.handleDateRangePickerApplyClick(ev);
                    }
                },
                opens: 'left',
                alwaysShowCalendars: true
            }
        };

        $scope.handleDateRangePickerApplyClick = function (element) {
            if (!element.model.startDate) {
                element.model.startDate = moment();
            }
            if (!element.model.endDate) {
                element.model.endDate = moment();
            }
            controller.subscription.startDate = element.model.startDate.format('x');
            controller.subscription.endDate = element.model.endDate.format('x');
        };
        $scope.specialisationsList = [];
        controller.programHistory = {};
        controller.isDirectory = false;
        controller.submitted = false;
        controller.campusSubmitted = false;

        $scope.datePickerAppDead = {
            date: {
                startDate: null
            },
            options: {
                eventHandlers: {
                    'apply.daterangepicker': function (ev) {
                        $scope.handleDatePickerAppDeadClick(ev);
                    }
                },
                singleDatePicker: true,
                minDate: TimeService.now()
            }
        };

        $scope.handleDatePickerAppDeadClick = function (element) {
            if (!controller.isAddMode()) {
                if (!angular.isDefined(controller.program.stats) ||
                    controller.program.stats === null
                ) {
                    controller.program.stats = {};
                }
                controller.program.stats.applicationDeadline = TimeService.getInUnix(element.model,true);
            } else {
                controller.newProgram.stats.applicationDeadline = TimeService.getInUnix(element.model,true);
            }
        };

        controller.isAddMode = function () {
            // @todo replace with factory
            return !!$scope.showProgramAddForm;
        };

        controller.isEditMode = function () {
            // @todo replace with factory
            return !!$scope.showProgramEditForm;
        };

        controller.isValidName = function () {
            return TmProfileProgramValidationService.isValidName(
                controller.isAddMode() ? controller.forms.addProgramDetailsForm : controller.forms.editProgramDetailsForm,
                controller.isAddMode() ? controller.newProgram : controller.program
            );
        };

        controller.isValidType = function () {
            return TmProfileProgramValidationService.isValidType(
                controller.isAddMode() ? controller.forms.addProgramDetailsForm : controller.forms.editProgramDetailsForm,
                controller.isAddMode() ? controller.newProgram : controller.program
            );
        };

        controller.isValidDescription = function () {
            return TmProfileProgramValidationService.isValidDescription(
                controller.isAddMode() ? controller.newProgram : controller.program,
                controller.submitted
            );
        };

        controller.isValidCampusAssigned = function () {
            return !controller.submitted || TmProfileProgramValidationService.isValidCampusAssigned(
                controller.isAddMode() ? controller.forms.addProgramDetailsForm : controller.forms.editProgramDetailsForm,
                controller.isAddMode() ? controller.newProgram : controller.program
            );
        };

        controller.isValid = function (isAdvanced) {
            return controller.isValidDescription() && TmProfileProgramValidationService.isValid(
                controller.isAddMode() ? controller.forms.addProgramDetailsForm : controller.forms.editProgramDetailsForm,
                controller.isAddMode() ? controller.forms.addProgramStatsForm : controller.forms.editProgramStatsForm,
                controller.isAddMode() ? controller.newProgram : controller.program,
                isAdvanced
            );
        };

        controller.isValidStats = function () {
            return TmProfileProgramValidationService.isValidStats(
                controller.isAddMode() ? controller.forms.addProgramStatsForm : controller.forms.editProgramStatsForm,
                controller.isAddMode() ? controller.newProgram : controller.program
            );
        };

        controller.isValidDetails = function () {
            return controller.isValidDescription() && TmProfileProgramValidationService.isValidDetails(
                controller.isAddMode() ? controller.forms.addProgramDetailsForm : controller.forms.editProgramDetailsForm,
                controller.isAddMode() ? controller.newProgram : controller.program
            );
        };

        controller.isValidAddCampus = function () {
            return TmProfileProgramValidationService.isValidCampus(
                controller.forms.addCampusForm,
                controller.newCampus
            ) && controller.isValidState() &&
                controller.isValidPostcode() &&
                controller.isValidSecondAddress() &&
                controller.isValidLatitude() &&
                controller.isValidLongitude();
        };

        controller.isValidEditCampus = function () {
            return TmProfileProgramValidationService.isValidCampus(
                controller.forms.editCampusForm,
                controller.oldCampus
            ) && controller.isValidState() &&
                controller.isValidPostcode() &&
                controller.isValidSecondAddress() &&
                controller.isValidLatitude() &&
                controller.isValidLongitude();
        };

        controller.isValidAverageGmat = function () {
            return TmProfileProgramValidationService.isValidAverageGmat(
                controller.isAddMode() ? controller.forms.addProgramStatsForm : controller.forms.editProgramStatsForm,
                controller.isAddMode() ? controller.newProgram : controller.program
            );
        };

        controller.isValidClassSize = function () {
            return TmProfileProgramValidationService.isValidClassSize(
                controller.isAddMode() ? controller.forms.addProgramStatsForm : controller.forms.editProgramStatsForm,
                controller.isAddMode() ? controller.newProgram : controller.program
            );
        };

        controller.isValidAverageSalaryAfterGraduation = function () {
            return TmProfileProgramValidationService.isValidAverageSalaryAfterGraduation(
                controller.isAddMode() ? controller.forms.addProgramStatsForm : controller.forms.editProgramStatsForm,
                controller.isAddMode() ? controller.newProgram : controller.program
            );
        };

        controller.isValidCampusName = function () {

            return TmProfileProgramValidationService.isValidCampusName(
                controller.isEditCampus ? controller.forms.editCampusForm : controller.forms.addCampusForm,
                controller.isEditCampus ? controller.oldCampus : controller.newCampus
            );
        };

        controller.isValidCampusCountry = function () {
            return TmProfileProgramValidationService.isValidCampusCountry(
                controller.isEditCampus ? controller.forms.editCampusForm : controller.forms.addCampusForm,
                controller.isEditCampus ? controller.oldCampus : controller.newCampus
            );
        };

        controller.isValidCampusAddressLine1 = function () {
            
            return TmProfileProgramValidationService.isValidCampusAddressLine1(
                controller.isEditCampus ? controller.forms.editCampusForm : controller.forms.addCampusForm,
                controller.isEditCampus ? controller.oldCampus : controller.newCampus
            );
        };

        controller.isValidCampusCity = function () {
           
            return TmProfileProgramValidationService.isValidCampusCity(
                controller.isEditCampus ? controller.forms.editCampusForm : controller.forms.addCampusForm,
                controller.isEditCampus ? controller.oldCampus : controller.newCampus
            );
        };

        controller.isValidState = function () {
            return CampusesService.isValidAsciiCharacters(controller.isEditCampus ? controller.oldCampus.state : controller.newCampus.state);
        };

        controller.isValidPostcode = function () {
            return CampusesService.isValidAsciiCharacters(controller.isEditCampus ? controller.oldCampus.postcode : controller.newCampus.postcode);
        };

        controller.isValidSecondAddress = function () {
            return CampusesService.isValidAsciiCharacters(controller.isEditCampus ? controller.oldCampus.addressLine2 : controller.newCampus.addressLine2);
        };

        controller.isValidLatitude = function () {
            var latitude = controller.isEditCampus ? controller.oldCampus.latitude : controller.newCampus.latitude;
            return CampusesService.isValidLatitude(latitude ? latitude : 0);
        };

        controller.isValidLongitude = function () {
            var longitude = controller.isEditCampus ? controller.oldCampus.longitude : controller.newCampus.longitude;
            return CampusesService.isValidLongitude(longitude ? longitude : 0);
        };


        controller.isBmProgram = function () {
            return TmProfileService.isBmProgram(controller.program);
        };

        controller.setValid = function (fieldName, stats, campus) {
            controller.submitted = null;
            if (campus) {
                controller.campusSubmitted = null;
            }
            if (controller.isAddMode()) {
                if (fieldName) {
                    if (stats) {
                        controller.forms.addProgramStatsForm[fieldName].$setPristine();
                    } else if (campus) {
                        if (controller.isEditCampus) {
                            controller.forms.editCampusForm[fieldName].$setPristine();
                        } else {
                            controller.forms.addCampusForm[fieldName].$setPristine();
                        }
                    } else {
                        if (controller.forms.addProgramDetailsForm[fieldName]) {
                            controller.forms.addProgramDetailsForm[fieldName].$setPristine();
                        }
                    }
                } else {
                    if (controller.forms.addProgramDetailsForm) {
                        controller.forms.addProgramDetailsForm.$setPristine();
                    }
                    if (controller.forms.addProgramStatsForm) {
                        controller.forms.addProgramStatsForm.$setPristine();
                    }
                }
            } else {
                if (fieldName) {
                    if (stats) {
                        controller.forms.editProgramStatsForm[fieldName].$setPristine();
                    } else if (campus) {
                        if (controller.isEditCampus) {
                            controller.forms.editCampusForm[fieldName].$setPristine();
                        } else {
                            controller.forms.addCampusForm[fieldName].$setPristine();
                        }
                    } else {
                        if (controller.forms.editProgramDetailsForm[fieldName]) {
                            controller.forms.editProgramDetailsForm[fieldName].$setPristine();
                        }
                    }
                } else {
                    if (controller.forms.editProgramStatsForm) {
                        controller.forms.editProgramDetailsForm.$setPristine();
                    }
                    if (controller.forms.editProgramStatsForm) {
                        controller.forms.editProgramStatsForm.$setPristine();
                    }
                }
            }
        };

        /**
         * Actions to do when add campus button is clicked.
         */
        controller.handleAddCampusClick = function () {
            // toggle new campus status
            controller.isNewCampus = !controller.isNewCampus;
            controller.isEditCampus = false;
            // reset new campus
            controller.newCampus = {
                displayInFrontEnd: true
            };
            // reset forms validation
            if (controller.forms.addCampusForm) {
                controller.forms.addCampusForm.$setPristine();
                controller.forms.addCampusForm.name.$setPristine();
                controller.forms.addCampusForm.country.$setPristine();
                controller.forms.addCampusForm.addressLine1.$setPristine();
                controller.forms.addCampusForm.city.$setPristine();
            }
        };

        /**
         * Actions to do when cancel button is clicked in edit campus form.
         */
        controller.handleCancelCampusClick = function () {
            // toggle edit campus form
            controller.isEditCampus = !controller.isEditCampus;
        };

        /**
         * Actions to do when new campus is submitted.
         *
         * @returns {boolean}
         */
        controller.handleNewCampusSubmit = function () {
            controller.campusSubmitted = true;
            controller.forms.addCampusForm.$setDirty();
            controller.forms.addCampusForm.name.$setDirty();
            controller.forms.addCampusForm.country.$setDirty();
            controller.forms.addCampusForm.addressLine1.$setDirty();
            controller.forms.addCampusForm.city.$setDirty();

            // validate campus data
            if (controller.campusSubmitInProgress ||
                !controller.forms.addCampusForm ||
                !controller.isValidAddCampus()
            ) {
                return false;
            }
            controller.campusSubmitInProgress = true;

            controller.newCampus.id = TmProfileProgramsService.getTmpCampusId();
            if (controller.newCampus.primary || !controller.program.primaryCampusId) {
                // mark campus as primary
                controller.program.primaryCampusId = controller.newCampus.id;
                // mark all other programs as not primary
                angular.forEach(controller.programCampuses, function (campus) {
                    campus.primary = false;
                });
            }
            if (!controller.program.campus) {
                controller.program.campus = [];
            }
            // assign new program to program
            if (controller.isAddMode()) {
                controller.newProgram.campus.push(controller.newCampus.id);
            } else {
                controller.program.campus.push(controller.newCampus.id);
            }
            // store new campus in program campuses list
            controller.programCampuses.push(controller.newCampus);
            // add new campus to campuses list
            controller.campusesList.push({
                value: controller.newCampus.id,
                label: controller.newCampus.name
            });
            // close add campus form
            controller.handleAddCampusClick();

            controller.campusSubmitInProgress = false;
        };

        controller.isEmpty = function (value) {
            return typeof value === 'undefined' || value === '';
        };

        /**
         * Actions to do when campus is updated.
         *
         * @returns {boolean}
         */
        controller.handleUpdateCampus = function () {
            controller.campusSubmitted = true;
            controller.forms.editCampusForm.$setDirty();
            controller.forms.editCampusForm.name.$setDirty();
            controller.forms.editCampusForm.country.$setDirty();
            controller.forms.editCampusForm.addressLine1.$setDirty();
            controller.forms.editCampusForm.city.$setDirty();

            // validate campus data
            if (controller.campusSubmitInProgress ||
                !controller.forms.editCampusForm ||
                !controller.isValidEditCampus()
            ) {
                return false;
            }
            controller.campusSubmitInProgress = true;
            if (controller.oldCampus.primary) {
                controller.program.primaryCampusId = controller.oldCampus.id;
            }
            var i = 0, total = controller.programCampuses.length;
            for (i; i < total; i++) {
                if (controller.programCampuses[i].id === controller.oldCampus.id) {
                    controller.programCampuses[i] = angular.copy(controller.oldCampus);
                    break;
                }
            }
            i = 0;
            total = controller.campusesList.length;
            for (i; i < total; i++) {
                if (controller.campusesList[i].value === controller.oldCampus.id) {
                    controller.campusesList[i] = {
                        value: controller.oldCampus.id,
                        label: controller.oldCampus.name,
                        disabled: true
                    };
                    break;
                }
            }
            isCampusUpdated = true;
            // close add campus form
            controller.handleCancelCampusClick();
            controller.campusSubmitInProgress = false;
        };

        /**
         * Is campus form visible?
         *
         * @returns {boolean|*}
         */
        controller.isCampusFormVisible = function () {
            return controller.isNewCampus;
        };

        /**
         * Is edit campus form visible?
         *
         * @returns {boolean|*}
         */
        controller.isEditCampusFormVisible = function () {
            return controller.isEditCampus;
        };

        controller.showStatistics = function () {
            return !controller.isValidStats() && controller.submitted;
        };

        controller.showDetails = function () {
            return !controller.isValidDetails() && controller.submitted;
        };

        controller.showCampus = function () {
            return !controller.isValidCampusAssigned() && controller.submitted;
        };

        /**
         * Actions to do when new program create button is clicked.
         *
         * @returns {boolean}
         */
        controller.handleProgramCreateClick = function () {
            var validateDescriptionOnSubmit = controller.validateDescriptionOnSubmit(controller.newProgram.description);
            if(validateDescriptionOnSubmit !== undefined &&
                !validateDescriptionOnSubmit

            ) {
                return false;
            }
            controller.submitted = true;
            controller.forms.addProgramDetailsForm.$setDirty();
            controller.forms.addProgramDetailsForm.name.$setDirty();
            controller.forms.addProgramDetailsForm.type.$setDirty();
            controller.forms.addProgramStatsForm.$setDirty();
            controller.forms.addProgramStatsForm.avgGmat.$setDirty();
            controller.forms.addProgramStatsForm.classSize.$setDirty();
            controller.forms.addProgramStatsForm.avgSalaryAfterGraduation.$setDirty();

            // validate program data
            if (controller.programSubmitInProgress ||
                !controller.forms.addProgramDetailsForm ||
                !controller.forms.addProgramStatsForm ||
                !controller.isValid()
            ) {
                return false;
            }
            controller.programSubmitInProgress = true;
            controller.newProgram.parentInstitutionCoreId = InstitutionFactory.getCoreId();
            controller.newProgram.primaryCampusId = controller.program.primaryCampusId;
            // create new program
            TmProfileProgramsService.create(controller.newProgram).then(controller.createCallback);
        };

        /**
         * Actions to do when create request is finished.
         *
         * @param {Object} response
         */
        controller.createCallback = function (response) {
            if (response && response.insertedId) {
                controller.totalProcessedCampuses = 0;
                controller.createdProgram = angular.copy(controller.newProgram);
                var institutionId = controller.institutionId || InstitutionFactory.getId(),
                    hasTmp = false,
                    campusData,
                    campusId,
                    newCampusId,
                    isTmp;
                controller.createdProgram.id = response.insertedId;
                if (angular.isDefined(controller.createdProgram.stats.applicationDeadline)) {
                    controller.createdProgram.stats.applicationDeadline = TimeService.getInUnix(TimeService.formatInUnix(controller.createdProgram.stats.applicationDeadline), true);
                }
                // create/update campuses
                angular.forEach(controller.programCampuses, function (campus) {
                    campusData = angular.copy(campus);
                    campusId = angular.copy(campus.id);
                    isTmp = TmProfileProgramsService.isTmpCampusId(campusId);
                    if (isTmp) {
                        hasTmp = true;
                        delete campusData.id;
                        campusData.primary = false;
                    }
                    if (isTmp || controller.campusesWithChanges.indexOf(campusId) !== -1) {
                        InstitutionsListService.saveCampus(institutionId, campusData).then(function (response) {
                            controller.totalProcessedCampuses++;
                            if (isTmp) {
                                newCampusId = response.insertedId;

                                // delete temporary ID
                                delete controller.createdProgram.campus[
                                    controller.createdProgram.campus.indexOf(campusId)
                                ];
                                // assign new real campus ID
                                controller.createdProgram.campus.push(newCampusId);
                                if (controller.createdProgram.primaryCampusId === campusId) {
                                    controller.createdProgram.primaryCampusId = newCampusId;
                                }

                                angular.forEach(controller.campusesList, function (item) {
                                    if (item.value === campus.id) {
                                        item.value = newCampusId;
                                    }
                                });
                                campus.id = newCampusId;
                                TmProfileService.clearCache();
                                InstitutionFactory.addCampus(campus);
                            }
                            // if all program campuses were updated/created
                            if (controller.programCampuses.length === controller.totalProcessedCampuses) {
                                // filter out empty campuses
                                controller.createdProgram.campus = controller.createdProgram.campus.filter(function (item) {
                                    return item !== null;
                                });
                                // update program
                                controller.createdProgram.skipStatus = true;
                                TmProfileProgramsService.update(controller.createdProgram).then(controller.newProgramUpdateCallback);
                            }
                        });
                    } else {
                        controller.totalProcessedCampuses++;
                    }
                });
                if (!hasTmp && !controller.campusesWithChanges.length) {
                    controller.newProgramUpdateCallback(true);
                    // update program
                    //TmProfileProgramsService.update(controller.createdProgram).then(controller.newProgramUpdateCallback);
                }
            } else {
                controller.newProgramUpdateCallback(false);
            }
        };

        controller.newProgramUpdateCallback = function (success) {
            controller.programSubmitInProgress = false;
            controller.setValid();
            // show notification about program submit status
            NotifierFactory.show(
                success ? 'success' : 'error',
                success ? (UserFactory.isClient() ? 'Request sent' : 'Created successfully!') : 'Saving failed!',
                'New Program'
            );
            if (success) {
                // reset add form fields
                controller.initForms();
                // announce that new program was added
                TmProfileFactory.announceNewProgram(controller.createdProgram.id);
                TmProfileFactory.setProgramEditFormVisibility(true);
                // set new program as active
                TmProfileFactory.setProgram(controller.createdProgram);
            }
        };

        /**
         * Actions to do when program update button is clicked.
         *
         * @returns {boolean}
         */
        controller.handleProgramEditClick = function () {
            var validateDescriptionOnSubmit = controller.validateDescriptionOnSubmit(controller.program.description);
            if(validateDescriptionOnSubmit !== undefined &&
                !validateDescriptionOnSubmit

            ) {
                return false;
            }
            controller.submitted = true;
            controller.forms.editProgramDetailsForm.$setDirty();
            controller.forms.editProgramDetailsForm.name.$setDirty();
            controller.forms.editProgramStatsForm.$setDirty();
            controller.forms.editProgramStatsForm.avgGmat.$setDirty();
            controller.forms.editProgramStatsForm.classSize.$setDirty();
            controller.forms.editProgramStatsForm.avgSalaryAfterGraduation.$setDirty();

            // validate program data
            if (controller.editInProgress ||
                !controller.forms.editProgramDetailsForm ||
                !controller.forms.editProgramStatsForm ||
                !controller.isValid()
            ) {
                return false;
            }
            if (controller.program && controller.program.stats && angular.isDefined(controller.program.stats.applicationDeadline)) {
                controller.program.stats.applicationDeadline = TimeService.getInUnix(TimeService.formatInUnix(controller.program.stats.applicationDeadline), true);
            }
            controller.editInProgress = true;
            controller.totalProcessedCampuses = 0;
            var institutionId = controller.institutionId || InstitutionFactory.getId(),
                hasTmp = false;
            angular.forEach(controller.programCampuses, function (campus) {
                var campusData = angular.copy(campus),
                    isTmp = TmProfileProgramsService.isTmpCampusId(campus.id);
                if (isTmp) {
                    hasTmp = true;
                    delete campusData.id;
                    campusData.primary = false;
                }
                if (isTmp || controller.campusesWithChanges.indexOf(campus.id) !== -1) {
                    InstitutionsListService.saveCampus(institutionId, campusData).then(function (response) {
                        controller.totalProcessedCampuses++;
                        if (TmProfileProgramsService.isTmpCampusId(campus.id)) {
                            // delete temporary ID
                            delete controller.program.campus[controller.program.campus.indexOf(campus.id)];
                            // assign real new campus ID
                            controller.program.campus.push(response.insertedId);
                            if (controller.program.primaryCampusId === campus.id) {
                                controller.program.primaryCampusId = response.insertedId;
                            }
                        }
                        // if all program campuses were updated/created
                        if (controller.programCampuses.length === controller.totalProcessedCampuses) {
                            // filter out empty campuses
                            controller.program.campus = controller.program.campus.filter(function (item) {
                                return item !== null;
                            });
                            // update program
                            TmProfileProgramsService.update(controller.program).then(controller.updateCallback);
                        }
                    });
                } else {
                    controller.totalProcessedCampuses++;
                }
            });
            if (!hasTmp && !controller.campusesWithChanges.length) {
                // update program
                TmProfileProgramsService.update(controller.program).then(controller.updateCallback);
            }
        };

        /**
         * Actions to do when update request is finished.
         *
         * @param {Object} response
         */
        controller.updateCallback = function (response) {
            // show notification about program submit status
            NotifierFactory.show(
                response ? 'success' : 'error',
                response ? (UserFactory.isClient() ? 'Request sent successfully!' : 'Approved successfully!') : 'Update failed!',
                'Edit Program'
            );

            if (response) {
                // close edit mode
                TmProfileFactory.setProgramEditFormVisibility(false);
                // reload datagrid or update program in it
                TmProfileFactory.announceProgramUpdate(controller.program.id);
            }
            controller.editInProgress = false;
        };

        controller.validateDescriptionOnSubmit = function (description) {
            if (controller.countWords(description) > controller.programDescriptionWordLimit) {
                NotifierFactory.show(
                    'error',
                    controller.validationProgramDescriptionWordLimit,
                    'Saving failed!'
                );

                return false;
            }
        };

        controller.handleDeleteClick = function () {
            if (UserFactory.isClient()) {
                TmProfileFactory.setDeleteFormVisibility();
            } else {
                var modalOptions = {
                    closeButtonText: 'Cancel',
                    actionButtonText: 'Yes',
                    actionButtonClass: 'danger',
                    headerText: 'Program Deletion',
                    bodyText: 'Are you sure you wish to delete "' + controller.program.name + '" program?',
                };
                ModalService.show({}, modalOptions).then(function () {
                    controller.deleteInProgress = true;
                    TmProfileProgramsService.delete(controller.program.id).then(controller.deleteCallback);
                });
            }
        };

        controller.deleteCallback = function (response) {
            controller.deleteInProgress = false;

            // show notification about program submit status
            NotifierFactory.show(
                response ? 'success' : 'error',
                response ? 'Deleted successfully!' : 'Delete failed!',
                'Delete Program'
            );

            if (response) {
                // close edit mode
                TmProfileFactory.setProgramEditFormVisibility(false);
                // reload datagrid or remove program in it
                TmProfileFactory.announceProgramDeletion(controller.program.id);
            }
        };

        /**
         * Actions to do when add program is clicked.
         */
        controller.closeAddProgramForm = function () {
            TmProfileFactory.setProgramAddFormVisibility(false);
        };

        /**
         * Actions to do when edit program is clicked.
         */
        controller.closeEditProgramForm = function () {
            TmProfileFactory.setProgramEditFormVisibility(false);
            $scope.showEdiProgramForm = true;
            $scope.showSubscriptionForm = false;
            TmProfileFactory.setProgram({});
            controller.subscription.startDate = null;
            controller.subscription.endDate = null;
        };

        /**
         * Actions to do when edit program is clicked.
         */
        controller.closeSubscribeProgramForm = function () {
            TmProfileFactory.setProgramEditFormVisibility(true);
            $scope.showSubscriptionForm = false;
            $scope.showEdiProgramForm = true;
            controller.subscription.startDate = null;
            controller.subscription.endDate = null;
        };

        controller.allowUpgrade = function () {
            return !controller.program.advanced && !controller.isReadOnly;
        };

        controller.allowDowngrade = function () {
            return !UserFactory.isClient() && controller.program.advanced;
        };

        controller.allowDelete = function () {
            return !controller.program.advanced && (
                (UserFactory.isClient() && !controller.isReadOnly) || !UserFactory.isClient()
            );
        };

        controller.allowUpdate = function () {
            return !controller.isReadOnly;
        };

        controller.allowLoginAs = function () {
            return controller.program.advanced && controller.isProgramActive;
        };

        controller.isAllowedToLogin = function () {
            return (UserFactory.isClient() &&
                UserFactory.isAllowedToLogin(controller.program.institutionCoreId)) ||
                !UserFactory.isClient();
        };

        controller.handleLoginAsClick = function () {
            LoginService.getTuProfilesRedirect({ coreId: controller.program.institutionCoreId });
            controller.closeEditProgramForm();
        };

        controller.isUndefined = function (value) {
            return (typeof value === 'undefined');
        };

        controller.getEditTitle = function () {
            return UserFactory.isClient() ?
                (controller.program.advanced ? 'Request to Edit Advanced Program' : 'Request to Edit Basic Program') :
                (controller.program.advanced ? 'Edit Advanced Program' : 'Edit Basic Program');
        };

        controller.getSubscriptionTitle = function () {
            return UserFactory.isClient() ?
                (controller.program.advanced ? 'Request to Edit Advanced Program' : 'Request to Edit Basic Program') :
                (controller.program.advanced ? 'Edit Advanced Program' : 'Upgrade to Advanced Program');
        };

        controller.getEditButtonTitle = function () {
            return UserFactory.isClient() ? 'Send' : 'Update';
        };

        controller.getDeleteButtonTitle = function () {
            return UserFactory.isClient() ?
                (controller.program.advanced ? 'Request to Delete Advanced Program' : 'Request to Delete Basic Program') : 'Delete';
        };

        controller.getUpgradeButtonTitle = function () {
            return UserFactory.isClient() ? 'Request to Upgrade to Advanced Program' : 'Upgrade to Advanced Program';
        };

        controller.getAllSubscriptionsCallback = function (tmSubscription) {
            var modalOptions = {
                closeButtonText: 'Cancel',
                headerText: 'Current Subscription(s)',
                institutionCoreId: controller.program.institutionCoreId,
                tmSubscription: tmSubscription,
                showContent: function () {
                    return !!(tmSubscription &&
                        tmSubscription.startDate &&
                        tmSubscription.advanced &&
                        tmSubscription.subscribed);
                },
                completeDowngradeClick: function () {
                    this.close();
                    if (
                        modalOptions.tmSubscription &&
                        modalOptions.tmSubscription.startDate &&
                        modalOptions.tmSubscription.advanced &&
                        modalOptions.tmSubscription.subscribed
                    ) {
                        $state.go('staff.institutions.list', { coreId: controller.program.institutionCoreId });
                    } else {
                        TmProfileProgramsService.downgrade(controller.program.id).then(controller.downgradeCallback);
                    }
                }
            }, modalDefaults = {
                backdrop: true,
                keyboard: true,
                modalFade: true,
                templateUrl: '/scripts/components/profiles/tm/programs/modalDowngradeProgramView.html'
            };
            ModalService.show(modalDefaults, modalOptions);
            controller.downgradeInProgress = false;
        };

        controller.handleDowngradeClick = function () {
            // disable downgrade button while loading subscriptions
            controller.downgradeInProgress = true;
            // load program subscriptions
            InstitutionsListService.getTmSubscriptions(controller.program.institutionCoreId).then(controller.getAllSubscriptionsCallback);
        };

        controller.downgradeCallback = function (success) {
            controller.downgradeInProgress = false;
            // get campuses full details
            TmProfileService.getCampuses(
                false,
                InstitutionFactory.getCoreId()
            ).then(function (campuses) {
                controller.campusesFullList = campuses;
                controller.programCampuses = [];
                if (controller.program.campus && controller.campusesList) {
                    var total = campuses.length,
                        i;
                    angular.forEach(controller.program.campus, function (campusId) {
                        i = 0;
                        for (i; i < total; i++) {
                            if (campusId === campuses[i].id) {
                                controller.programCampuses.push(campuses[i]);
                                break;
                            }
                        }
                    });
                }

                TmProfileService.clearCampusesKeyValuePairsCache(InstitutionFactory.getCoreId());
                TmProfileService.getCampusesKeyValuePairs(
                    true,
                    InstitutionFactory.getCoreId()
                ).then(function (campusesList) {
                    controller.campusesList = campusesList;
                }).then(function () {
                    // reload data grid
                    TmProfileFactory.requestProgramsDatagridReload(true);
                }).then(function () {
                    NotifierFactory.show(
                        success ? 'success' : 'error',
                        success ? 'Downgraded successfully!' : 'Downgrade failed!',
                        'Advanced Program'
                    );
                });
            });
        };

        /**
         * Program Details Popup
         */
        controller.getProgramDetails = function () {
            var modalOptions = {
                closeButtonText: 'Close',
                headerText: 'Program Overview',
                programDetails: controller.program,
                specialisationsArray: controller.specialisationsArray,
                typesArray: controller.typesArray,
                deliveryMethods: controller.deliveryMethodsArray
            }, modalDefaults = {
                backdrop: true,
                keyboard: true,
                modalFade: true,
                templateUrl: '/scripts/components/profiles/tm/programs/modalAdvancedProgramDetails.html'
            };
            ModalService.show(modalDefaults, modalOptions);
        };

        controller.handleUpgradeClick = function () {
            if (UserFactory.isClient()) {
                TmProfileFactory.setProgramUpgradeFormVisibility(true);
            } else {
                TmProfileFactory.setProgramSubscribeFormVisibility(true);
            }
        };

        /**
         * Actions to do when save button is clicked in subscription panel.
         */
        controller.handleUpgradeSubmit = function () {
            TmProfileProgramsService.upgrade(
                controller.program.id,
                null,
                controller.subscription.startDate,
                controller.subscription.endDate
            ).then(controller.upgradeCallback);
        };

        controller.upgradeCallback = function (success) {
            controller.upgradeInProgress = false;
            // show edit mode
            TmProfileFactory.setProgramSubscribeFormVisibility(false);
            // mark program as advanced for QS users
            if (success) {
                TmProfileFactory.setReadOnly(true);
                controller.program.advanced = true;
                if (!isNaN(success)) {
                    controller.program.institutionCoreId = success;
                }
                TmProfileFactory.setProgram(controller.program);
                TmProfileFactory.setUpgradeSubscription(true);
                // reload datagrid
                TmProfileFactory.requestProgramsDatagridReload();
            }
            NotifierFactory.show(
                success ? 'success' : 'error',
                success ? 'Upgraded successfully!' : 'Upgrade failed!',
                'Basic Program'
            );
        };

        /**
         * Handle campus edit click.
         *
         * @param {Number} id
         * @returns {boolean}
         */
        controller.editCampus = function (id) {
            if (typeof id === 'undefined' || !controller.programCampuses.length || !controller.programCampuses[id]) {
                return false;
            }
            controller.oldProgramCampuses = angular.copy(controller.programCampuses);
            controller.oldCampus = controller.oldProgramCampuses[id];
            controller.oldCampus.primary = controller.oldCampus.id === controller.program.primaryCampusId;
            controller.isEditCampus = true;
            controller.isNewCampus = false;
            controller.campusesWithChanges.push(controller.programCampuses[id].id);
        };

        controller.isProgramSubmitDisabled = function () {
            return controller.isEditCampus ||
                controller.isNewCampus ||
                controller.editInProgress ||
                controller.addInProgress;
        };

        controller.getCampusById = function (campusId) {
            var total = controller.campusesFullList.length,
                i = 0;
            for (i; i < total; i++) {
                if (campusId === controller.campusesFullList[i].id) {
                    return controller.campusesFullList[i];
                }
            }
        };

        controller.clearTmpCampuses = function (campusId) {
            angular.forEach(controller.campusesList, function (campus, key) {
                if (TmProfileProgramsService.isTmpCampusId(campus.value)) {
                    if (campusId) {
                        if (campusId === campus.value) {
                            controller.campusesList[key] = null;
                        }
                    } else {
                        controller.campusesList[key] = null;
                    }
                }
            });
            if (controller.campusesList) {
                controller.campusesList = controller.campusesList.filter(function (item) {
                    return !!item;
                });
            }
        };

        controller.resetCampusList = function (campusId) {
            var campusObject;
            angular.forEach(controller.campusesList, function (campus) {
                if (campusId) {
                    if (campusId === campus.value) {
                        campusObject = controller.getCampusById(campus.value);
                        campus.label = campusObject.name;
                        campus.disabled = false;
                    }
                } else {
                    campusObject = controller.getCampusById(campus.value);
                    campus.label = campusObject.name;
                    campus.disabled = false;
                }
            });
        };

        controller.newProgramCampusWatch = function (selectedCampusesIds) {
            if (controller.isAddMode()) {
                controller.programCampusWatch(selectedCampusesIds);
            }
        };

        controller.existingProgramCampusWatch = function (selectedCampusesIds) {
            if (controller.isEditMode()) {
                controller.programCampusWatch(selectedCampusesIds);
            }
        };

        controller.programCampusWatch = function (selectedCampusesIds) {
            var existingIds = [],
                index,
                campus;
            if (selectedCampusesIds && selectedCampusesIds.length) {
                // walk threw already assigned campuses
                angular.forEach(controller.programCampuses, function (campus, index) {
                    // if its in the submitted list
                    if (selectedCampusesIds.indexOf(campus.id) !== -1) {
                        existingIds.push(campus.id);
                    } else {
                        if (controller.program.primaryCampusId === controller.programCampuses[index].id) {
                            controller.program.primaryCampusId = null;
                        }
                        controller.programCampuses[index] = null;
                        controller.clearTmpCampuses(campus.id);
                        if (!TmProfileProgramsService.isTmpCampusId(campus.id)) {
                            controller.resetCampusList(campus.id);
                        }
                    }
                });
                controller.programCampuses = controller.programCampuses.filter(function (item) {
                    return !!item;
                });

                // walk threw selected campuses ids
                angular.forEach(selectedCampusesIds, function (campusId) {
                    index = existingIds.indexOf(campusId);
                    campus = controller.getCampusById(campusId);
                    // if campus is not already assigned
                    if (campus && index === -1) {
                        controller.programCampuses.push(campus);
                    }
                });
                // remove deleted campuses
                controller.programCampuses = controller.programCampuses.filter(function (item) {
                    return !!item;
                });

                var totalAssignedCampuses = controller.programCampuses.length;
                // if assigned cmapuses list is not empty & no promary campus
                if (totalAssignedCampuses && !controller.program.primaryCampusId) {
                    // mark last campus as primary
                    controller.program.primaryCampusId = controller.programCampuses[totalAssignedCampuses - 1].id;
                }
            } else {
                // clear program campuses list
                controller.programCampuses = [];
                controller.program.primaryCampusId = null;
                controller.clearTmpCampuses();
                controller.resetCampusList();
            }
            controller.isEditCampus = false;
        };

        controller.programHistoryCallback = function (data) {
            if (!angular.equals('{}', data)) {
                controller.programHistory = data;
                // make arrays comma separated string
                angular.forEach(controller.programHistory, function (item, key) {
                    if (angular.isArray(item)) {
                        if (key === 'specialisations') {
                            var humanReadable = [];
                            angular.forEach(item, function (itemValue) {
                                angular.forEach(controller.specialisationsList, function (specListItem) {
                                    if (angular.equals(specListItem.handle, itemValue)) {
                                        humanReadable.push(specListItem.name);
                                    }
                                });
                            });
                            if (item.length === 0) {
                                humanReadable.push('No previous value');
                            }
                            controller.programHistory[key] = humanReadable.join(', ');
                        } else {
                            controller.programHistory[key] = item.join(', ');
                            if (item.length === 0) {
                                controller.programHistory[key] = 'No previous value';
                            }
                        }
                    }
                    if (angular.isObject(item)) {
                        angular.forEach(item, function (subItem, subKey) {
                            if (angular.isArray(subItem)) {
                                controller.programHistory[key][subKey] = subItem.join(', ');
                                if (subItem.length === 0) {
                                    controller.programHistory[key][subKey] = 'No previous value';
                                }
                            } else if (angular.isString(subItem) && subItem.length === 0) {
                                controller.programHistory[key][subKey] = 'No previous value';
                            }
                        });
                    }
                    // transform shorthands to human readable format
                    if (key === 'type') {
                        angular.forEach(controller.typesList, function (typeListItem) {
                            if (angular.equals(typeListItem.value, item)) {
                                controller.programHistory[key] = typeListItem.label;
                            }
                        });
                    }
                });
            }
        };

        controller.loadProgramCampuses = function () {
            // get institution campuses id & name pairs
            TmProfileService.getCampusesKeyValuePairs(
                controller.isDirectory || controller.programInstitutionCoreId === InstitutionFactory.getCoreId() && !isCampusUpdated,
                controller.programInstitutionCoreId
            ).then(function (campusesList) {
                isCampusUpdated = false;
                controller.campusesList = campusesList;
                var exists;
                angular.forEach(controller.program.campus, function (assignedCampus, key) {
                    exists = false;
                    angular.forEach(controller.campusesList, function (availableCampus) {
                        if (availableCampus.value === assignedCampus) {
                            availableCampus.disabled = true;
                            exists = true;
                        }
                    });
                    if (!exists) {
                        delete controller.program.campus[key];
                    }
                });
                if (controller.program.campus) {
                    controller.program.campus = controller.program.campus.filter(function (item) {
                        return typeof item !== 'undefined';
                    });
                }
            }).then(function () {
                // get campuses full details
                TmProfileService.getCampuses(
                    controller.isDirectory || controller.programInstitutionCoreId === InstitutionFactory.getCoreId(),
                    controller.programInstitutionCoreId
                ).then(function (campuses) {
                    controller.campusesFullList = campuses;
                    controller.programCampuses = [];
                    if (controller.program.campus && controller.campusesList) {
                        var total = campuses.length,
                            i;
                        angular.forEach(controller.program.campus, function (campusId) {
                            i = 0;
                            for (i; i < total; i++) {
                                if (campusId === campuses[i].id) {
                                    controller.programCampuses.push(campuses[i]);
                                    break;
                                }
                            }
                        });
                    }
                });
            });
        };

        /**
         * Action to do when active program changes.
         * @param {Object} program
         */
        controller.getProgramWatch = function (program) {
            controller.isEditCampus = false;
            controller.isNewCampus = false;
            controller.isProgramActive = false;
            if (!program ||
                (!controller.isAddMode() && !controller.isEditMode()) ||
                (controller.isEditMode() && typeof (program) !== 'undefined' && angular.equals({}, program))
            ) {
                return;
            }
            var params = {
                'filter[coreId]' : '=' + program.institutionCoreId,
                'filter[active]' : true
            };
            InstitutionsListService.getAll(params).then(function (response) {
                controller.isProgramActive = response && response.results && response.results.length ? true : false;
            });
            controller.program = angular.copy(program);
            controller.descriptionWords = TextService.countWords(program.description);
            if (Array.isArray(controller.program.stats) && controller.program.stats.length < 1) {
                controller.program.stats = {};
            }
            controller.isProgramAdvanced = program.advanced;
            controller.programInstitutionCoreId = controller.program.advanced ? controller.program.institutionCoreId : (
                controller.program.parentInstitutionCoreId || InstitutionFactory.getCoreId()
            );
            if (!controller.isProgramAdvanced) {
                controller.loadProgramCampuses();
            }

            if (controller.program.coreId && controller.isSchoolUser === false) {
                TmDirectoryService.getProgramHistory(controller.program.coreId).then(
                    controller.programHistoryCallback
                );
            }
        };

        controller.changeValidateRange = function (targetVar, target, min, max) {
            if (controller[targetVar].stats === null) {
                controller[targetVar].stats = {};
            }
            if (!controller[targetVar].stats.hasOwnProperty(target) ||
                typeof controller[targetVar].stats[target] === 'undefined'
            ) {
                controller[targetVar].stats[target] = min;
            }

            if (controller[targetVar].stats[target] < min) {
                controller[targetVar].stats[target] = min;
            }
            if (controller[targetVar].stats[target] > max) {
                controller[targetVar].stats[target] = max;
            }
        };

        controller.formVisibilityWatch = function (isVisible, wasVisible) {
            if (isVisible && !wasVisible) {
                controller.initForms();
                controller.setValid();
                controller.program.primaryCampusId = null;
            }
        };

        /**
         * Get cordinates add campus
         */
        controller.autoGenerateCordinates = function () {
            if (controller.newCampus.autoGenerate) {
                CampusesService.getCoordinatesByCampus(controller.newCampus).then(
                    controller.getCoordinatesByCampusCallback
                );
            }
        };

        /**
         * Get coordinates callback.
         *
         * @param {Array }response
         */
        controller.getCoordinatesByCampusCallback = function (response) {
            if (response) {
                if (angular.isArray(response) && !angular.isObject(response[0]) && !angular.isObject(response[1])) {
                    controller.newCampus.latitude = response[0];
                    controller.newCampus.longitude = response[1];
                    controller.coordinatesUpdated('success', 'Coordinates updated!');
                } else {
                    controller.coordinatesUpdated('warning', 'Address not found!');
                }
            } else {
                controller.coordinatesUpdated('error', 'Error requesting coordinates!');
            }
        };

        /**
         * Get cordinates edit campus
         */
        controller.autoGenerateCordinatesEdit = function () {
            if (controller.oldCampus.autoGenerate) {
                CampusesService.getCoordinatesByCampus(controller.oldCampus).then(
                    controller.getCoordinatesByEditCampusCallback
                );
            }
        };

        /**
         * Get coordinates callback.
         *
         * @param {Array }response
         */
        controller.getCoordinatesByEditCampusCallback = function (response) {
            if (response) {
                if (angular.isArray(response) && !angular.isObject(response[0]) && !angular.isObject(response[1])) {
                    controller.oldCampus.latitude = response[0];
                    controller.oldCampus.longitude = response[1];
                    controller.coordinatesUpdated('success', 'Coordinates updated!');
                } else {
                    controller.coordinatesUpdated('warning', 'Address not found!');
                }
            } else {
                controller.coordinatesUpdated('error', 'Error requesting coordinates!');
            }
        };

        /**
         * Displays a message based on success
         *
         * @param {boolean} success
         * @param {string} message
         */
        controller.coordinatesUpdated = function (success, message) {
            NotifierFactory.show(
                success,
                message,
                'Address Search'
            );
        };


        controller.initForms = function () {
            controller.newProgram = {
                parentInstitutionCoreId: null,
                campus: [],
                specialisations: [],
                stats : {},
                products: []
            };
            controller.newCampus = {};
        };

        controller.initWatches = function () {
            // listen to new program form visibility changes
            WatchService.create($scope, TmProfileFactory.getProgramAddFormVisibility, controller.formVisibilityWatch);
            // listen to program edit form visibility changes
            WatchService.create($scope, TmProfileFactory.getProgramEditFormVisibility, controller.formVisibilityWatch);

            WatchService.create($scope, TmProfileFactory.getProgramSubscribeFormVisibility, function (isVisible, wasVisible) {
                if (isVisible && !wasVisible) {
                    $scope.showEdiProgramForm = false;
                    $scope.showSubscriptionForm = true;
                } else {
                    $scope.showEdiProgramForm = true;
                    $scope.showSubscriptionForm = false;
                }
            });
            // listen to active program changes
            WatchService.create($scope, TmProfileFactory.getProgram, controller.getProgramWatch);

            WatchService.create($scope, TmProfileFactory.isReadOnly, function (isReadOnly) {
                controller.isReadOnly = isReadOnly;
                $timeout(function() {
                    controller.editor.summernote(controller.isReadOnly ? 'disable' : 'enable');
                });
            });

            WatchService.create($scope, TmProfileFactory.isDirectory, function (isDirectory) {
                controller.isDirectory = isDirectory;
            });
            // listen to new program campuses changes
            WatchService.create($scope, function () {
                return controller.newProgram.campus;
            }, controller.newProgramCampusWatch);

            WatchService.create($scope, function () {
                return controller.program.campus;
            }, controller.existingProgramCampusWatch);

            //listen to auto generate switch state
            WatchService.create($scope, function () {
                return controller.newCampus.autoGenerate;
            }, controller.autoGenerateCordinates);

            //listen to auto generate switch state on edit form
            WatchService.create($scope, function () { return controller.oldCampus.autoGenerate; }, function (newValue, oldValue) {
                if (typeof (newValue) !== "undefined" && typeof (oldValue) !== "undefined") {
                    controller.autoGenerateCordinatesEdit();
                }
            });

        };

        controller.init = function () {
            controller.initWatches();

            // load specialisations list
            TmProfileService.getSpecialisationsList().then(function (list) {
                controller.specialisationsList = list;
                angular.forEach(controller.specialisationsList, function(specialisation) {
                    controller.specialisationsArray[specialisation.handle] = specialisation.name;
                });
            });

            // Make array of types
            angular.forEach(controller.typesList, function (type) {
                controller.typesArray[type.value] = type.label;
            });

            // Make array of types
            angular.forEach(controller.deliveryMethodsList, function (type) {
                controller.deliveryMethodsArray[type.value] = type.label;
            });

            // load countries list
            InstitutionsListService.getCountries().then(function (list) {
                controller.countryList = list;
            });

            controller.isSchoolUser = UserFactory.isClient();
        };

        // listen to programs tab visibility changes
        WatchService.create($scope, TmProfileFactory.isProgramsTabSelected, function (isActive) {
            if (!isActive) {
                return false;
            }
            // if not already initialized and tab is active
            if (!alreadyInitialised && isActive) {
                controller.init();
                alreadyInitialised = true;
            }
            controller.initForms();
        });

        WatchService.create($scope, TmProfileFactory.isBackend, function (isBackend) {
            controller.isBackend = isBackend;
            if (!alreadyInitialised && isBackend) {
                controller.init();
                controller.initForms();
                alreadyInitialised = true;
            }
        });
    };

    angular
        .module('qsHub')
        .controller('TmProfileProgramFormController', [
            '$scope',
            '$state',
            '$timeout',
            'constants',
            'NotifierFactory',
            'CampusesService',
            'TmProfileFactory',
            'InstitutionFactory',
            'TmProfileProgramsService',
            'UserFactory',
            'InstitutionsListService',
            'TmProfileService',
            'LoginService',
            'ModalService',
            'TmProfileProgramValidationService',
            'WatchService',
            'TmDirectoryService',
            'UiSelectService',
            'TimeService',
            'TextService',
            App.controllers.tmProfileProgramForm
        ]);

} (window.angular, window.moment));

(function(angular) {
    "use strict";

    function TmProgramValidation(
        TextService,
        CampusesService
    ) {
        var service = {};

        function isNotEmpty (form, programObject, fieldName) {
            return !!(
                form && (form.$pristine || (form[fieldName] && form[fieldName].$pristine)) || (
                    programObject && programObject[fieldName] && programObject[fieldName].trim().length
                )
            );
        }

        function isFloat(n) {
            return Number(n) === n && n % 1 !== 0;
        }

        function isValidStatsRange(form, programObject, fieldName, min, max) {
            if (form && (form.$pristine || (form[fieldName] && form[fieldName].$pristine))) {
                return true;
            } else if (programObject &&
                programObject.stats &&
                programObject.stats[fieldName]
            ) {
                var value = programObject.stats[fieldName];
                if (isNaN(value) || isFloat(value) || String(value).indexOf('.') !== -1) {
                    return false;
                }
                value = parseInt(value, 10);
                return value >= min && value <= max;
            }
            return true;
        }

        service.isValidName = function (form, programObject) {
            return isNotEmpty(form, programObject, 'name');
        };

        service.isValidCampusName = function (form, programObject) {
            if (!CampusesService.isValidAsciiCharacters(programObject.name)) {
                return false;
            }

            return isNotEmpty(form, programObject, 'name');
        };

        service.isValidCampusCountry = function (form, programObject) {
            return isNotEmpty(form, programObject, 'country');
        };

        service.isValidCampusAddressLine1 = function (form, programObject) {
            if (!CampusesService.isValidAsciiCharacters(programObject.addressLine1)) {
                return false;
            }

            return isNotEmpty(form, programObject, 'addressLine1');
        };

        service.isValidCampusCity = function (form, programObject) {
            if (!CampusesService.isValidAsciiCharacters(programObject.city)) {
                return false;
            }
            return isNotEmpty(form, programObject, 'city');
        };

        service.isValidType = function (form, programObject) {
            return isNotEmpty(form, programObject, 'type');
        };

        service.isValidDescription = function (programObject, submitted) {
            var description = programObject.description ? TextService.cleanHtmlTags(programObject.description) : '';
            return (
                !submitted ||
                (submitted && description.length > 0)
            );
        };

        service.isValidCampusAssigned = function (form, programObject) {
            return form && form.$pristine || (programObject && programObject.campus && programObject.campus.length);
        };

        service.isValidAverageGmat = function (form, programObject) {
            return isValidStatsRange(form, programObject, 'avgGmat', 400, 800);
        };

        service.isValidClassSize = function (form, programObject) {
            return isValidStatsRange(form, programObject, 'classSize', 1, 2000);
        };

        service.isValidAverageSalaryAfterGraduation = function (form, programObject) {
            return isValidStatsRange(form, programObject, 'avgSalaryAfterGraduation', 0, 300000);
        };

        service.isValid = function (form, statsForm, programObject, isAdvanced) {
            isAdvanced = typeof isAdvanced === 'undefined' ? false: isAdvanced;
            return service.isValidDetails(form, programObject) &&
                service.isValidStats(statsForm, programObject) &&
                (
                    (!isAdvanced && service.isValidCampusAssigned(form, programObject)) ||
                    isAdvanced
                );
        };

        service.isValidDetails = function (form, programObject) {
            return service.isValidName(form, programObject) &&
                service.isValidType(form, programObject);
        };

        service.isValidStats = function (form, programObject) {
            return service.isValidAverageGmat(form, programObject) &&
                service.isValidClassSize(form, programObject) &&
                service.isValidAverageSalaryAfterGraduation(form, programObject);
        };

        service.isValidCampus = function (form, programObject) {
            return service.isValidCampusName(form, programObject) &&
                service.isValidCampusCountry(form, programObject) &&
                service.isValidCampusAddressLine1(form, programObject) &&
                service.isValidCampusCity(form, programObject);
        };

        return service;
    }

    angular
        .module('qsHub')
        .service('TmProfileProgramValidationService', [
            'TextService',
            'CampusesService',
            TmProgramValidation
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers:{}});

    App.controllers.tmProfilePublish = function (
        $scope,
        constants,
        PublishService,
        InstitutionFactory,
        TmProfileFactory,
        TmProfileHistoryLogFactory,
        UserFactory,
        WebSocketsService,
        WatchService,
        ModalService
    ) {
        var controller = this,
            alreadyInitialised = false;

        controller.devMode = constants.dev;
        controller.subscribedTo = [];
        $scope.institutionData = {};
        $scope.sections = {};
        $scope.institutionId = null;

        /**
         * Actions to do when publishing is triggered.
         *
         * @param {object} section
         * @param {string} publishProgramsBasic
         * @param {string} publishProgramsAdvanced
         */
        $scope.handlePublish = function (section, publishProgramsBasic, publishProgramsAdvanced) {
            section.publishDisabled = true;
            section.status = constants.publishStatus.pending;
            section.statusMessage = '';
            var publishBasicPrograms = publishProgramsBasic ? 'yes' : 'no';
            var publishAdvancedPrograms = publishProgramsAdvanced ? 'yes' : 'no';
            PublishService.publish($scope.institutionId, publishBasicPrograms, publishAdvancedPrograms).then(function (response) {
                controller.publishCallback(section, response);
            });
        };

        /**
         * If the program publishing status should display
         *
         * @returns {boolean}
         */
        $scope.displayProgramStatus = function (section) {
            return !UserFactory.isClient() &&
                ((section.hasOwnProperty('advancedPrograms') &&
                section.advancedPrograms.hasOwnProperty('total') &&
                section.advancedPrograms.total > 0 ) ||
                (section.hasOwnProperty('simplePrograms') &&
                section.simplePrograms.hasOwnProperty('total') &&
                section.simplePrograms.total > 0 )) &&
                section.hasOwnProperty('status') &&
                (section.status === 'failure' || section.status === 'success');
        };

        /**
         * View programs that failed publishing in popup.
         *
         * @param size
         * @param type
         * @param data
         */
        $scope.viewPrograms = function (size, type, data) {
            var modalOptions = {
                closeButtonText: 'Close',
                headerText: type.toUpperCase() + ' ' + 'Program/s that failed to publish:',
                data: data
            };
            var modalDefaults = {
                backdrop: true,
                keyboard: true,
                modalFade: true,
                size: size,
                templateUrl: '/scripts/components/modal/viewPrograms.html'
            };
            ModalService.show(modalDefaults, modalOptions);
        };

        /**
         * Returns whether to disable the profile or not based on type
         *
         * @returns {Boolean}
         */
        controller.isProfileEnabled = function () {
            return !InstitutionFactory.isTmEnabled();
        };

        /**
         * Publishing callback.
         *
         * @param {Object} section
         * @param {Object} response
         * @returns {boolean}
         */
        controller.publishCallback = function (section, response) {
            if (!response || !angular.isDefined(response.feedId)) {
                section.status = constants.publishStatus.failure;

                return false;
            }
            PublishService.setStatus(constants.publishStatus.pending);
            section.feedId = response.feedId;
            section.status = constants.publishStatus.pending;
        };

        /**
         * Check is section is for TM
         *
         * @param {string} type
         * @returns {Boolean}
         */
        $scope.isTm = function (type) {
            return type === 'tm';
        };

        /**
         * Check if institution has advanced programs to publish or not
         *
         * @returns {Boolean}
         */
        $scope.institutionHasAdvancedPrograms = function() {
            return $scope.institutionData.typeId === constants.typeId.topLevelInstitutionId ||
                    $scope.institutionData.typeId === constants.typeId.clientDepartmentId;
        };


        /**
         * Subscribe to all available publishes.
         *
         */
        controller.publishSubscribe = function() {
            angular.forEach($scope.sections, function (section) {
                var subscribeType = $scope.institutionId + 'tm';
                if (controller.subscribedTo.indexOf(subscribeType) === -1) {
                    controller.subscribedTo.push(subscribeType);
                    WebSocketsService.subscribe(subscribeType, 'PublishStatus' + subscribeType, function (response) {
                        controller.sectionPublishStatusCallback(section, response);
                    });
                }
            });
        };

        /**
         * Actions to do when status changes
         *
         * @param {Object} section
         * @param {Object} response
         */
        controller.sectionPublishStatusCallback = function (section, response) {
            section.publishDisabled = true;
            var status = response && response.status || constants.publishStatus.failure;
            section.status = status;
            switch (status) {
                case constants.publishStatus.failure:
                    getPublishStatus();
                    break;
                case constants.publishStatus.success:
                    getPublishStatus();
                    section.publishDisabled = false;
                    section.statusMessage = response.statusMessage;
                    TmProfileHistoryLogFactory.setReload(true);
                    break;
                default:
                    break;
            }
            if (status === constants.publishStatus.success) {
                // Update nodeId
                section.viewDisabled = !response.nodeId;
                section.url = constants.drupal.tm.url + '/node/' + response.nodeId;
            }
        };

        /**
         * Handle publish statuses response.
         *
         * @param {Promise.Object} publishStatus
         */
        controller.publishStatusCallback = function (publishStatus) {
            angular.forEach($scope.sections, function(section) {
                section.publishDisabled = true;
                if (publishStatus.hasOwnProperty('tm') && angular.isDefined(publishStatus.tm)) {
                    section.status = publishStatus.tm.status;
                    section.statusMessage = publishStatus.tm.message;
                    if (publishStatus.tm.hasOwnProperty('advancedPrograms')) {
                        section.advancedPrograms = publishStatus.tm.advancedPrograms;
                        section.advancedPrograms.data = publishStatus.tm.advancedPrograms.data;
                    }
                    if (publishStatus.tm.hasOwnProperty('simplePrograms')) {
                        section.simplePrograms = publishStatus.tm.simplePrograms;
                        section.simplePrograms.data = publishStatus.tm.simplePrograms.data;
                    }
                    if (section.status.length === 0 ||
                        section.status === constants.publishStatus.success ||
                        section.status === constants.publishStatus.failure
                    ) {
                        section.publishDisabled = false;
                    }
                }
            });
        };

        /**
         * Initialize watches.
         */
        controller.initWatches = function () {
            // watch for active institution changes
            WatchService.create($scope, InstitutionFactory.getData, function (newValue) {
                if (newValue && !angular.equals({}, newValue)) {
                    $scope.institutionData = newValue;
                    $scope.institutionId = newValue.id;
                    $scope.sections = PublishService.getSections(!UserFactory.isClient(), $scope.institutionData);
                    controller.publishSubscribe();
                    // get publishing statuses
                    getPublishStatus();
                }
            });
        };

        function getPublishStatus () {
            PublishService.getPublishStatus($scope.institutionId).then(function (response) {
                controller.publishStatusCallback(response);
            });
        }

        /**
         * @constructor
         */
        controller.init = function () {
            controller.initWatches();
        };

        // listen to publish tab visibility changes
        WatchService.create($scope, TmProfileFactory.isPublishTabSelected, function (isActive) {
            // if not already initialized and tab is active
            if (!alreadyInitialised && isActive) {
                controller.init();
                alreadyInitialised = true;
            }
        });
    };

    angular
        .module('qsHub')
        .controller('TmProfilePublishController', [
            '$scope',
            'constants',
            'TmProfilePublishService',
            'InstitutionFactory',
            'TmProfileFactory',
            'TmProfileHistoryLogFactory',
            'UserFactory',
            'WebSocketsService',
            'WatchService',
            'ModalService',
            App.controllers.tmProfilePublish
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers:{}});

    App.controllers.tmProfilePublishLogs = function (
        $scope,
        $state,
        PublishService,
        InstitutionFactory,
        TmProfileFactory,
        TmProfileHistoryLogFactory,
        UserFactory,
        TmProfilePublishHistoryLogFactory,
        WatchService
    ) {
        var controller = this,
            alreadyInitialised = false,
            logsPerPage = 5,
            publishLogInitLimit = 10;
        controller.isLoadMoreHistoryLogsInProgress = false;

        /**
         * Get TM logs.
         */
        controller.updatePublishLogs = function () {
            if (InstitutionFactory.getId()) {
                PublishService.getPublishLogs(InstitutionFactory.getId()).then(function (response) {
                    $scope.publishLogs = response;
                    TmProfilePublishHistoryLogFactory.setTmPublishLogs(response);
                });
            }
        };

        /**
         * History logs data watch.
         *
         * @param data
         */
        controller.getHistoryLogsDataWatch = function (data) {
            controller.publishLogs = data;
        };

        /**
         * More logs available.
         *
         * @returns {boolean}
         */
        controller.isMoreLogsAvailable = function() {
            if (controller.publishLogs && controller.publishLogs.totalMatching) {
                return TmProfilePublishHistoryLogFactory.getTmPublishLogLimit() < controller.publishLogs.totalMatching;
            }
            return false;
        };

        /**
         * History logs load more in progress.
         *
         * @param isInProgress
         */
        controller.isHistoryLogsLoadMoreInProgressWatch = function (isInProgress) {
            controller.isLoadMoreHistoryLogsInProgress = isInProgress;
        };

        /**
         * Load more history logs.
         */
        controller.handleLoadMoreHistoryLogs = function() {
            TmProfilePublishHistoryLogFactory.loadMore();
        };

        controller.isLoadMoreRequestWatch = function (loadMore) {
            if (loadMore) {
                TmProfilePublishHistoryLogFactory.loadMore(false);
                TmProfilePublishHistoryLogFactory.setTmLoadMoreInProgress(false);
                var limit = TmProfilePublishHistoryLogFactory.getTmPublishLogLimit() + logsPerPage;

                PublishService.getPublishLogs(InstitutionFactory.getId(), limit).then(function (response) {
                    $scope.publishLogs = response;
                    TmProfilePublishHistoryLogFactory.setTmPublishLogs(response);
                    TmProfilePublishHistoryLogFactory.setTmLoadMoreInProgress(false);
                    TmProfilePublishHistoryLogFactory.setTmPublishLogLimit(limit);
                });
            }
        };
        /**
         * Get status of fetching TM logs
         */
        $scope.fetchingPublishLog = function () {
            return false;
        };

        /**
         * Init watches
         */
        controller.initWatches = function () {
            // watch for active institution changes
            WatchService.create($scope, InstitutionFactory.getData, function (newValue) {
                if (newValue) {
                    if (InstitutionFactory.isTmSubscribed() && !UserFactory.noAccess($state.current.name)) {
                        controller.updatePublishLogs();
                    } else {
                        $scope.publishLogs = null;
                    }
                }
            });

            /**
             * Reload required.
             */
            WatchService.create($scope, TmProfileHistoryLogFactory.isReloadRequired, function (newValue) {
                if (newValue) {
                    if (InstitutionFactory.isTmSubscribed() && !UserFactory.noAccess($state.current.name)) {
                        TmProfileHistoryLogFactory.setReload(false);
                        controller.updatePublishLogs();
                    } else {
                        $scope.publishLogs = null;
                    }
                }
            });

            /**
             * Publish service get status.
             */
            WatchService.create($scope, PublishService.getStatus, function(value) {
                if(value){
                    controller.updatePublishLogs();
                }
            });

            // watch for history logs data changes
            WatchService.create($scope, TmProfilePublishHistoryLogFactory.getTmPublishLogs, controller.getHistoryLogsDataWatch);
            // watch for history logs loading progress
            WatchService.create($scope, TmProfilePublishHistoryLogFactory.isTmLoadMoreInProgress, controller.isHistoryLogsLoadMoreInProgressWatch);

            WatchService.create($scope, TmProfilePublishHistoryLogFactory.isTmLoadMoreRequest, controller.isLoadMoreRequestWatch);
        
        };

        /**
         * @constructor
         */
        controller.init = function () {
            controller.initWatches();
        };

        /**
         * Listen to public tab visibility changes.
         */
        WatchService.create($scope, TmProfileFactory.isPublishTabSelected, function (isActive) {
            // if not already initialized and tab is active
            if (!alreadyInitialised && isActive) {
                controller.init();
                alreadyInitialised = true;
                //set initial publish logs limit
                TmProfilePublishHistoryLogFactory.setTmPublishLogLimit(publishLogInitLimit);
            }
        });
    };

    angular
        .module('qsHub')
        .controller('TmProfilePublishLogsController', [
            '$scope',
            '$state',
            'TmProfilePublishService',
            'InstitutionFactory',
            'TmProfileFactory',
            'TmProfileHistoryLogFactory',
            'UserFactory',
            'TmProfilePublishHistoryLogFactory',
            'WatchService',
            App.controllers.tmProfilePublishLogs
        ]);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { services: {} });

    App.services.TmProfilePublishService = function (
        $resource,
        $q,
        $log,
        constants,
        InstitutionService
    ) {
        var service = {
            deferred: {},
            isStatus: null
        };

        /**
         * Get publish TM publish API endpoint.
         *
         * @returns {$resource}
         */
        service.getPublishModel = function () {
            return $resource(constants.api.institutions.url + '/v1/tm-feed/publish/:id/include-basic/:basic/include-advanced/:advanced');
        };

        /**
         * Get publish status TM API endpoint.
         *
         * @returns {$resource}
         */
        service.getPublishStatusModel = function () {
            return $resource(constants.api.institutions.url + '/v1/tm-feed/publish-status/:id');
        };

        /**
         * Get publish logs TM API endpoint.
         *
         * @returns {$resource}
         */
        service.getLogsModel = function () {
            return $resource(constants.api.institutions.url + '/v1/tm-feed/publish-logs/:id?sorting[createdAt]=desc&fieldName=type,status,createdAt,createdByFullName&');
        };

        /**
         * Gets the node id for the current institution
         *
         * @param {object} institutionData
         * @param {string} nodeType
         * @returns {unresolved}
         */
        service.getNodeId = function (institutionData, nodeType) {
            return institutionData &&
                institutionData.nids &&
                institutionData.nids[nodeType] ? institutionData.nids[nodeType] : null;
        };

        /**
         *
         * @param {bool} qsUser
         * @param {Object} institutionData
         * @returns {Array}
         */
        service.getSections = function (qsUser, institutionData) {
            qsUser = qsUser || false;
            var tmUrl = constants.drupal.tm.url,
                nodeId = service.getNodeId(institutionData, 'tm'),
                institutionCoreId = institutionData.coreId,
                sections = [
                    {
                        type: institutionData.typeId !== constants.typeId.advancedProgramId ? 'tm' : 'advanced-program-profile',
                        name: 'MBA',
                        url: tmUrl + '/node/' + nodeId,
                        feedPreviewUrl: constants.api.institutions.url + '/v1/tm-feed/core-id/' + institutionCoreId,
                        viewDisabled: !InstitutionService.isTmViewingEnabled(institutionData),
                        publishProgramsBasic: true,
                        publishProgramsAdvanced: false
                    }
                ];
            return sections;
        };

        /**
         *
         * @param {String} institutionId
         * @param publishBasicPrograms
         * @param publishAdvancedPrograms
         * @returns {Promise.*}
         */
        service.publish = function (institutionId, publishBasicPrograms, publishAdvancedPrograms) {
            if (!institutionId || !publishBasicPrograms || !publishAdvancedPrograms) {
                var deferred = $q.defer();
                deferred.resolve(false);
                return deferred.promise;
            }

            return service.getPublishModel().get(
                {
                    id: institutionId,
                    basic: publishBasicPrograms,
                    advanced: publishAdvancedPrograms
                }
            ).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return data.toJSON();
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        /**
         * Get publish logs.
         *
         * @param {String} institutionId
         * @returns {Promise.*}
         */
        service.getPublishLogs = function (institutionId, limit) {
            if (!institutionId) {
                var deferred = $q.defer();
                deferred.resolve(false);
                return deferred.promise;
            }
            limit = limit || 10;
            return service.getLogsModel().get({
                id: institutionId,
                limit: limit
            }).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return data;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        service.getPublishStatus = function (institutionId) {
            return service.getPublishStatusModel().get(
                {
                    id: institutionId
                }
            ).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return data;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        service.setStatus = function (statusValue) {
            service.isStatus = statusValue;
        };
        
        service.getStatus = function() {
            return service.isStatus;
        };

        return service;
    };

    angular
        .module('qsHub')
        .service('TmProfilePublishService', [
            '$resource',
            '$q',
            '$log',
            'constants',
            'InstitutionService',
            App.services.TmProfilePublishService
        ]);

} (window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { factories: {} });

    App.factories.tmProfilePublishHistoryLog = function() {
        var loadMoreInProgress = false,
            loadMore = false,
            logs = [],
            limit;

        return {
            setTmPublishLogs: function (data) {
                logs = data;
            },
            getTmPublishLogs: function () {
                return logs;
            },
            setTmPublishLogLimit: function (logLimit) {
                limit = logLimit;
            },
            getTmPublishLogLimit: function () {
                return limit || 10;
            },
            isTmLoadMoreInProgress: function () {
                return loadMoreInProgress;
            },
            setTmLoadMoreInProgress: function (inProgress) {
                loadMoreInProgress = inProgress;
            },
            loadMore: function (more) {
                loadMore = typeof more === 'undefined' ? true : more;
            },
            isTmLoadMoreRequest: function () {
                return loadMore;
            }
        };
    };

    angular
        .module('qsHub')
        .factory('TmProfilePublishHistoryLogFactory', [
            App.factories.tmProfilePublishHistoryLog
        ]);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { controllers: {} });

    App.controllers.tmProfileProgramStats = function (
        $scope,
        constants,
        NotifierFactory,
        TmProfileFactory,
        InstitutionFactory,
        UserFactory,
        TmProfileService,
        TmProfileProgramStatsService,
        TmProfileProgramValidationService,
        WatchService,
        UiSelectService,
        TimeService
    ) {
        var controller = this,
            alreadyInitialised = false;

        controller.devMode = constants.dev;
        controller.feesRangesList = TmProfileService.getFeesRangesList();
        controller.monthsList = TmProfileService.getMonthsList();
        controller.accreditationsList = TmProfileService.getAccreditationsList();
        controller.lengthList = TmProfileService.getLengthList();
        controller.isSchoolUser = UserFactory.isClient();
        controller.forms = {};
        controller.editInProgress = false;
        controller.percentList = TmProfileService.getPercentList();
        controller.yearList1850 = TmProfileService.getYearList(18, 50);
        controller.yearList020 = TmProfileService.getYearList(0, 20);
        controller.offerScholarshipsList = UiSelectService.getYesNoOptions();
        controller.deliveryMethodsList = TmProfileService.getDeliveryMethodsList();

        $scope.stats = {};
        $scope.program = {
            specialisations: []
        };
        $scope.datePickerAppDead = {
            date: {
                startDate: null
            },
            options: {
                eventHandlers: {
                    'apply.daterangepicker': function (ev) {
                        $scope.handleDatePickerAppDeadClick(ev);
                    }
                },
                singleDatePicker: true,
                minDate: TimeService.now()
            }
        };

        $scope.handleDatePickerAppDeadClick = function (element) {
            $scope.stats.applicationDeadline = element.model.format('x');
        };

        controller.getEditButtonTitle = function () {
            return UserFactory.isClient() ? 'Request Edit Advanced Program' : 'Update';
        };

        controller.isBmProgram = function () {
            return TmProfileService.isBmProgram($scope.program);
        };

        controller.handleUpdateClick = function () {
            controller.forms.addProgramStatsForm.$setDirty();
            controller.forms.addProgramStatsForm.avgGmat.$setDirty();
            controller.forms.addProgramStatsForm.classSize.$setDirty();
            controller.forms.addProgramStatsForm.avgSalaryAfterGraduation.$setDirty();

            // validate program data
            if (controller.editInProgress ||
                !controller.forms.addProgramStatsForm ||
                !controller.isValid()
            ) {
                return false;
            }
            if (angular.isDefined($scope.stats.applicationDeadline)) {
                $scope.stats.applicationDeadline = TimeService.getInUnix(TimeService.formatInUnix($scope.stats.applicationDeadline), true);
            }
            controller.editInProgress = true;
            controller.programStatisticObject = {
                'stats': $scope.stats,
                'specialisations': $scope.program.specialisations
            };
            TmProfileProgramStatsService.update($scope.programId, controller.programStatisticObject).then(controller.updateCallback);
        };

        controller.isValid = function () {
            return controller.isValidAverageGmat() &&
                controller.isValidClassSize() &&
                controller.isValidAverageSalaryAfterGraduation();
        };

        controller.setValid = function (fieldName, stats) {
            if (controller.forms.addProgramStatsForm) {
                if (fieldName) {
                    if (stats) {
                        controller.forms.addProgramStatsForm[fieldName].$setPristine();
                    }
                } else {
                    controller.forms.addProgramStatsForm.$setPristine();
                }
            }
        };

        controller.updateCallback = function (response) {
            NotifierFactory.show(
                response ? 'success' : 'error',
                response ? 'Updated successfully!' : 'Update failed!',
                'Edit Program'
            );
            controller.editInProgress = false;
        };

        controller.isValidAverageGmat = function () {
            return TmProfileProgramValidationService.isValidAverageGmat(
                controller.forms.addProgramStatsForm,
                $scope.program
            );
        };

        controller.isValidClassSize = function () {
            return TmProfileProgramValidationService.isValidClassSize(
                controller.forms.addProgramStatsForm,
                $scope.program
            );
        };

        controller.isValidAverageSalaryAfterGraduation = function () {
            return TmProfileProgramValidationService.isValidAverageSalaryAfterGraduation(
                controller.forms.addProgramStatsForm,
                $scope.program
            );
        };

        controller.getCoreIdWatch = function (coreId) {
            if (!coreId) {
                return false;
            }
            TmProfileProgramStatsService.getStats(coreId).then(function (data) {
                $scope.program = data;
                $scope.stats = data.stats === null || (Array.isArray(data.stats) && data.stats.length < 1) ? {} : data.stats;
                if (data.hasOwnProperty('specialisations') &&
                    angular.isArray(data.specialisations) &&
                    data.specialisations.length > 0
                ) {
                    $scope.program.specialisations = data.specialisations;
                }
                $scope.programId = data.id;
                angular.forEach(TmProfileService.getProgramStatusList(), function (item) {
                    if (item.value === data.status) {
                        data.status = item.label;
                    }
                });
            });
        };

        controller.initWatches = function () {
            WatchService.create($scope, InstitutionFactory.getCoreId, controller.getCoreIdWatch);
        };

        controller.init = function () {
            controller.initWatches();

            // load specialisations list
            TmProfileService.getSpecialisationsList().then(function (list) {
                controller.specialisationsList = list;
            });
        };

        // listen to programs statistics tab visibility changes
        WatchService.create($scope, TmProfileFactory.isStatsTabSelected, function (isActive) {
            // if not already initialized and tab is active
            if (!alreadyInitialised && isActive) {
                controller.init();
                alreadyInitialised = true;
            }
        });
    };

    angular
        .module('qsHub')
        .controller('TmProfileProgramStatsController', [
            '$scope',
            'constants',
            'NotifierFactory',
            'TmProfileFactory',
            'InstitutionFactory',
            'UserFactory',
            'TmProfileService',
            'TmProfileProgramStatsService',
            'TmProfileProgramValidationService',
            'WatchService',
            'UiSelectService',
            'TimeService',
            App.controllers.tmProfileProgramStats
        ]);

} (window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { services: {} });

    App.services.TmProfileProgramsStatsService = function(
        $resource,
        $log,
        constants
    ) {
        var service = {};

        service.getFetchModel = function() {
            return $resource(
                constants.api.institutions.url + '/v1/list/tm-programs?filter[institutionCoreId]==:coreId&page=1&limit=1',
                {},
                {
                    get: {
                        cancellable: true
                    }
                }
            );
        };

        /**
         * Get programs create API endpoint.
         *
         * @returns {$resource}
         */
        service.getStatsModel = function() {
            return $resource(constants.api.institutions.url + '/v1/tm-program/:id/stats', null, {
                update: { method: 'PATCH' }
            });
        };

        /**
         * Get program statistics by its institution Core Id.
         *
         * @param {Number} coreId
         * @returns {Promise.<TResult>}
         */
        service.getStats = function (coreId) {
            if (!coreId) {
                throw 'Missing Required';
            }
            return service.getFetchModel().get({coreId: coreId}).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return data.results && data.results.length ? {
                        id: data.results[0].id,
                        type: data.results[0].type,
                        stats: data.results[0].stats,
                        specialisations: data.results[0].specialisations,
                        coreId: data.results[0].coreId,
                        institutionCoreId: data.results[0].institutionCoreId,
                        nodeId: data.results[0].nodeId,
                        status: data.results[0].status
                } : {};
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return {};
            });
        };

        /**
         * Update program statistics.
         *
         * @param {String} id
         * @param {Object} stats
         * @returns {Promise.<TResult>}
         */
        service.update = function (id, stats) {
            if (!id) {
                throw 'Missing Required';
            }
            return service.getStatsModel().update({id: id}, stats).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        return service;
    };

    angular
        .module('qsHub')
        .service('TmProfileProgramStatsService', [
            '$resource',
            '$log',
            'constants',
            App.services.TmProfileProgramsStatsService
        ]);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { controllers: {} });

    App.controllers.tmDirectory = function (
        $scope,
        $timeout,
        constants,
        uiGridConstants,
        uiGridExporterConstants,
        uiGridExporterService,
        TmProfileService,
        TmDirectoryService,
        TmProfileProgramsService,
        InstitutionsListService,
        TmDirectoryFactory,
        TmProfileFactory,
        NotifierFactory,
        InstitutionFactory,
        GridService,
        TimeService
    ) {
        var controller = this;
        var selectValues = {},
            lastSelectValues = {},
            filterChangesInProgress = false;
        var filterTimeoutMilliseconds = constants.datagridInputDelay;
        controller.isDatagridRendered = false;
        controller.showInfoBlock = false;
        controller.showProgramEditForm = false;
        controller.editInProgress = false;
        controller.defaultPaginationOptions = {
            page: 1,
            limit: 25
        };
        controller.isParentActive = false;
        controller.paginationOptions = GridService.getColumnsFilters(
            constants.gridNameSpaces.tmDirectoryGridFilters,
            []
        );
        controller.columnsVisibility = GridService.getColumnsVisibility(
            constants.gridNameSpaces.tmDirectoryGridVisibility
        );
        controller.sendEmailToClient = true;
        controller.programStatus = null;
        controller.TmProfileProgramFormController = null;
        $scope.selectedProgramId = null;

        controller.closeEditForm = function () {
            TmDirectoryFactory.setEditMode(false);
            controller.showInfoBlock = false;
            controller.toggleDatagridSettings();
            $scope.selectedProgramId = null;
            TmProfileFactory.setDirectory(false);
        };

        controller.handleDeleteAndPublishClick = function () {
            TmDirectoryService.delete(
                $scope.selectedProgramId,
                true,
                controller.sendEmailToClient
            ).then(function (success) {
                // close right side panel
                controller.closeEditForm();
                // show notification
                NotifierFactory.show(
                    success ? 'success' : 'error',
                    success ? 'Deleted successfully! Publishing is in the queue.' : 'Deletion failed!',
                    'Program Deletion & Publishing'
                );
                // reload datagrid
                controller.reloadDatagrid();
            });
        };

        controller.getFormTitle = function () {
            if (TmProfileService.isProgramPendingApprove(controller.programStatus)) {
                return 'Requested (New)';
            }
            if (TmProfileService.isProgramPendingEdit(controller.programStatus)) {
                return 'Requested (Edit)';
            }
            if (TmProfileService.isProgramPendingDeletion(controller.programStatus)) {
                return 'Pending (Deletion)';
            }
            return 'Edit Program';
        };

        controller.handleCancelDeletionClick = function () {
            TmDirectoryService.revertStatus(
                $scope.selectedProgramId,
                controller.sendEmailToClient
            ).then(function (success) {
                // close right side panel
                controller.closeEditForm();
                // show notification
                NotifierFactory.show(
                    success ? 'success' : 'error',
                    success ? 'Status reverted successfully!' : 'Status revert failed!',
                    'Program Status Revert'
                );
                // reload datagrid
                controller.reloadDatagrid();
            });
        };

        controller.enableUpdatePublishButton = function (TmProfileProgramFormController) {
            return TmProfileProgramFormController.program && TmProfileProgramFormController.program.products && TmProfileProgramFormController.program.products.indexOf('topmba') !== -1;
        };

        controller.handleProgramApproveClick = function (TmProfileProgramFormController, publish) {
            if (publish && (!controller.isParentActive || !controller.enableUpdatePublishButton(TmProfileProgramFormController))) {
                return false;
            }
            controller.publish = publish || false;

            controller.TmProfileProgramFormController = TmProfileProgramFormController;
            var validateDescriptionOnSubmit = controller.TmProfileProgramFormController.validateDescriptionOnSubmit(controller.TmProfileProgramFormController.program.description);
            if(validateDescriptionOnSubmit !== undefined &&
                !validateDescriptionOnSubmit
            ) {
                return false;
            }
            controller.TmProfileProgramFormController.submitted = true;
            controller.TmProfileProgramFormController.forms.editProgramDetailsForm.$setDirty();
            controller.TmProfileProgramFormController.forms.editProgramDetailsForm.name.$setDirty();
            controller.TmProfileProgramFormController.forms.editProgramDetailsForm.type.$setDirty();
            controller.TmProfileProgramFormController.forms.editProgramStatsForm.$setDirty();
            controller.TmProfileProgramFormController.forms.editProgramStatsForm.avgGmat.$setDirty();
            controller.TmProfileProgramFormController.forms.editProgramStatsForm.classSize.$setDirty();
            controller.TmProfileProgramFormController.forms.editProgramStatsForm.avgSalaryAfterGraduation.$setDirty();

            // validate program data
            if (controller.editInProgress ||
                !controller.TmProfileProgramFormController.forms.editProgramDetailsForm ||
                !controller.TmProfileProgramFormController.forms.editProgramStatsForm ||
                !controller.TmProfileProgramFormController.isValid(controller.isProgramAdvanced)
            ) {
                return false;
            }
            controller.editInProgress = true;
            controller.totalProcessedCampuses = 0;

            if (!!controller.TmProfileProgramFormController.program.stats && angular.isDefined(controller.TmProfileProgramFormController.program.stats.applicationDeadline)) {
                controller.TmProfileProgramFormController.program.stats.applicationDeadline = TimeService.getInUnix(TimeService.formatInUnix(controller.TmProfileProgramFormController.program.stats.applicationDeadline), true);
            }

            if (controller.isAdvancedProgram) {
                // update program
                TmDirectoryService.approve(
                    controller.TmProfileProgramFormController.program,
                    controller.publish,
                    controller.sendEmailToClient
                ).then(controller.approveCallback);
            } else {
                var institutionCoreId = controller.TmProfileProgramFormController.programInstitutionCoreId,
                    hasTmp = false;
                angular.forEach(controller.TmProfileProgramFormController.programCampuses, function (campus) {
                    var campusData = angular.copy(campus),
                        isTmp = TmProfileProgramsService.isTmpCampusId(campus.id);
                    if (isTmp) {
                        hasTmp = true;
                        delete campusData.id;
                    }
                    if (isTmp || controller.TmProfileProgramFormController.campusesWithChanges.indexOf(campus.id) !== -1) {
                        InstitutionsListService.saveCampus(institutionCoreId, campusData).then(function (response) {
                            controller.totalProcessedCampuses++;
                            if (TmProfileProgramsService.isTmpCampusId(campus.id)) {
                                // delete temporary ID
                                delete controller.TmProfileProgramFormController.program.campus[controller.TmProfileProgramFormController.program.campus.indexOf(campus.id)];
                                // assign real new campus ID
                                controller.TmProfileProgramFormController.program.campus.push(response.insertedId);
                                if (controller.TmProfileProgramFormController.program.primaryCampusId === campus.id) {
                                    controller.TmProfileProgramFormController.program.primaryCampusId = response.insertedId;
                                }
                                if (institutionCoreId === InstitutionFactory.getCoreId()) {
                                    campus.id = response.insertedId;
                                    InstitutionFactory.addCampus(campus);
                                }
                            }
                            // if all program campuses were updated/created
                            if (controller.TmProfileProgramFormController.programCampuses.length === controller.totalProcessedCampuses) {
                                // filter out empty campuses
                                controller.TmProfileProgramFormController.program.campus = controller.TmProfileProgramFormController.program.campus.filter(function (item) {
                                    return item !== null;
                                });
                                // update program
                                TmDirectoryService.approve(
                                    controller.TmProfileProgramFormController.program,
                                    publish,
                                    controller.sendEmailToClient
                                ).then(controller.approveCallback);
                            }
                        });
                    } else {
                        controller.totalProcessedCampuses++;
                    }
                });
                if (!hasTmp && !controller.TmProfileProgramFormController.campusesWithChanges.length) {
                    // update program
                    TmDirectoryService.approve(
                        controller.TmProfileProgramFormController.program,
                        controller.publish,
                        controller.sendEmailToClient
                    ).then(controller.approveCallback);
                }
            }
        };

        /**
         * Actions to do when update request is finished.
         *
         * @param {Object} response
         */
        controller.approveCallback = function (response) {
            // close right side panel
            controller.closeEditForm();
            var succeed = 'success',
                publishMessage = 'Approved successfully! Publishing is in the queue.';
            if (controller.publish && angular.isObject(response)) {
                var publishSuccess = response.published;
                succeed = publishSuccess ? succeed : 'warning';
                publishMessage = publishSuccess ? publishMessage : 'Edit approved successfully, but unable to publish. Parent institution needs to be published first';
            }
            // show notification about program submit status
            NotifierFactory.show(
                response ? succeed : 'error',
                response ? (
                    controller.publish ? publishMessage : 'Approved successfully!'
                ) : 'Approving failed!',
                controller.publish ? 'Program Approve & Publishing' : 'Program Approve',
                succeed === 'warning'
            );
            controller.editInProgress = false;
            // reload datagrid
            controller.reloadDatagrid();
            if (response) {
                TmProfileService.clearCache();
            }
        };

        controller.reloadDatagrid = function () {
            controller.getPage();
        };

        controller.handleDatagridRowClick = function (program) {
            if (TmProfileService.isProgramPendingApprove(program.status) || TmProfileService.isProgramPendingEdit(program.status)) {
                controller.approveButtonTitle = 'Approve';
            }
            else {
                controller.approveButtonTitle = 'Update';
            }
            TmProfileFactory.setDirectory(true);
            if (controller.TmProfileProgramFormController) {
                controller.TmProfileProgramFormController.submitted = false;
            }
            // another program selected
            if ($scope.selectedProgramId && program.id !== $scope.selectedProgramId) {
                TmDirectoryFactory.setEditMode(true);
            } else if (!$scope.selectedProgramId && program.id) {
                TmDirectoryFactory.setEditMode(true);
                $scope.showProgramAddForm = false;
                $scope.showProgramEditForm = true;
            } else {
                TmDirectoryFactory.setEditMode(false);
                $scope.showProgramAddForm = false;
                $scope.showProgramEditForm = false;
            }
            controller.showInfoBlock = TmDirectoryFactory.isEditMode();

            controller.toggleDatagridSettings();
            $scope.selectedProgramId = controller.showInfoBlock ? program.id : null;
            controller.programStatus = program.status;
            controller.isProgramAdvanced = program.advanced;
            controller.showProgramEditForm = controller.showInfoBlock;
            controller.pendingDeletion = TmProfileService.isProgramPendingDeletion(program.status);
            controller.approved = TmProfileService.isProgramApproved(program.status) ||
                TmProfileService.isProgramEditApproved(program.status);
            controller.readOnly = controller.pendingDeletion;
            controller.sendEmailToClient = !controller.approved;
            if (Array.isArray(program.stats) && program.stats.length < 1) {
                program.stats = {};
            }
            // Allow publish basic start
            controller.isParentActive = false;
            controller.isParentActiveLoading = true;
            if ((!program.advanced || !program.hasOwnProperty('advanced')) &&
                (program.hasOwnProperty('parentInstitutionCoreId') && program.parentInstitutionCoreId > 0)
            ) {
                InstitutionsListService.getAll({
                    'filter[coreId]': '=' + program.parentInstitutionCoreId,
                    'columns[]': 'active',
                    'limit': 1
                }).then(function (response) {
                    if (response && response.results && response.results.length === 1 && response.results[0].hasOwnProperty('active') && response.results[0].active) {
                        controller.isParentActive = true;
                    }
                    controller.isParentActiveLoading = false;
                });
            } else {
                controller.isParentActive = true;
                controller.isParentActiveLoading = false;
            }
            // Allow publish basic end
            TmProfileFactory.setProgram(angular.copy(program));
            TmProfileFactory.setReadOnly(controller.readOnly);
        };

        controller.toggleDatagridSettings = function () {
            // toggle grid menu
            $scope.gridOptions.enableGridMenu = !controller.showInfoBlock;
            // toggle columns resize
            $scope.gridOptions.enableColumnResizing = !controller.showInfoBlock;
            // toggle grid pagination
            $scope.gridOptions.enablePaginationControls = !controller.showInfoBlock;
        };

        controller.getSortColumn = function (column) {
            return (column.name || column.field).replace('AsString', '');
        };

        controller.handleGridFiltersChanges = function () {
            filterChangesInProgress = true;
            if($scope.gridApi.pagination.getPage() > 1){
                $scope.gridApi.pagination.seek(1);
            }
            var sortKeys = [],
                latestSortKey,
                filterColumn;
            angular.forEach($scope.gridApi.grid.columns, function (column) {
                if (typeof column.filters !== 'undefined' && typeof column.filters[0].term !== 'undefined') {
                    if (
                        column.filters[0].type === uiGridConstants.filter.SELECT &&
                        typeof column.filters[0].term === 'object' &&
                        column.filters[0].term !== null &&
                        typeof column.filters[0].term.value !== 'undefined'
                    ) {
                        column.filters[0].term = column.filters[0].term.value;
                    }
                }
                if (typeof column.sort.priority !== 'undefined') {
                    sortKeys[column.sort.priority] = controller.getSortColumn(column);
                }
            });

            if (sortKeys.length > 1) {
                latestSortKey = sortKeys.pop();
                angular.forEach($scope.gridApi.grid.columns, function (column) {
                    if (typeof column.sort.priority !== 'undefined' &&
                        controller.getSortColumn(column) !== latestSortKey
                    ) {
                        column.sort = {};
                    }
                });
            }

            controller.paginationOptions = angular.copy(controller.defaultPaginationOptions);
            angular.forEach($scope.gridApi.grid.columns, function (column) {
                if (
                    typeof column.filters !== 'undefined' &&
                    column.filters[0].term !== 'undefined' &&
                    column.filters[0].term !== ''
                ) {
                    filterColumn = column.filters[0].column ? column.filters[0].column : column.field;
                    if (filterColumn === 'productsAsString') {
                        var products = '',
                            separator = ', ',
                            count =  column.filters[0].term ? column.filters[0].term.length - 1 : 0,
                            prods = TmDirectoryService.getOrderProductsFilter(column.filters[0].term);
                        if (prods.length > 0) {
                            for (var i = 0; i < prods.length; i++) {
                                if (count === i) {
                                    separator = '';
                                }
                                products += prods[i].charAt(0).toUpperCase() + prods[i].slice(1) + separator;
                            }
                            controller.paginationOptions['filter[' + filterColumn + ']'] = '=' + products;
                        }
                    } else {
                        controller.paginationOptions['filter[' + filterColumn + ']'] = column.filters[0].term;
                    }
                }
                if (typeof column.sort.direction !== 'undefined') {
                    controller.paginationOptions['sorting[' + controller.getSortColumn(column) + ']'] = column.sort.direction;
                }
            });

            GridService.storeColumnsFilters(
                constants.gridNameSpaces.tmDirectoryGridFilters,
                controller.paginationOptions
            );

            if (angular.isDefined($scope.filterTimeout)) {
                $timeout.cancel($scope.filterTimeout);
            }

            GridService.generateSelectValues($scope.gridApi.grid.columns, selectValues);

            if (!angular.equals(selectValues, lastSelectValues)) {
                filterTimeoutMilliseconds = constants.datagridSelectDelay;
            }

            $scope.filterTimeout = $timeout(function () {
                controller.getPage();
                filterChangesInProgress = false;
                lastSelectValues = angular.copy(selectValues);
                filterTimeoutMilliseconds = constants.datagridInputDelay;
            }, filterTimeoutMilliseconds);
        };

        controller.getPage = function () {
            return TmDirectoryService.getAllPrograms(
                angular.merge({}, controller.paginationOptions, controller.defaultPaginationOptions)
            ).then(function (response) {
                if (!response) {
                    $scope.$destroy();
                }
                if (!$scope.gridOptions) {
                    $scope.gridOptions = {};
                }
                $scope.gridOptions.totalItems = response.totalMatching;
                $scope.gridOptions.data = response.results;
                $scope.gridOptions.minRowsToShow = response.totalFiltered && response.totalFiltered.length < 25 ? response.totalFiltered : 25;
            });
        };

        var selectTemplate = '/scripts/shared/ui-grid/templates/selectFilterHeaderTemplate.html',
            selectCellTemplate = '/scripts/shared/ui-grid/templates/selectCellTemplate.html',
            multiSelectTemplate = '/scripts/shared/ui-grid/templates/multiSelectFilterHeaderTemplate.html';
        controller.initDataGrid = function () {
            controller.getPage().then(function () {
                $scope.gridOptions = angular.extend({}, $scope.gridOptions, {
                    enableSorting: true,
                    exporterMenuCsv: false,
                    exporterMenuAllData: false,
                    exporterMenuPdf: false,
                    enableGridMenu: true,
                    showGridFooter: false,
                    selectedItems: $scope.selectedProgramId,
                    enableFiltering: true,
                    useExternalFiltering: true,
                    enableColumnResize: true,
                    enableFullRowSelection: true,
                    enableRowSelection: true,
                    multiSelect: false,
                    enableRowHeaderSelection: false,
                    paginationPageSizes: [25, 50, 100],
                    paginationPageSize: 25,
                    useExternalPagination: true,
                    useExternalSorting: true,
                    gridMenuCustomItems: [
                        {
                            title: ' Export visible data as csv',
                            action: exportVisible,
                            order: 209
                        },
                        {
                            title: ' Export all data as csv',
                            action: exportAll,
                            order: 210
                        }
                    ],
                    rowTemplate: '/scripts/components/profiles/tm/programs/datagrid/rowTemplate.html',
                    columnDefs: [
                        {
                            displayName: 'ID',
                            visible: GridService.getVisibilityByField(controller.columnsVisibility, 'coreIdAsString', true),
                            field: 'coreIdAsString',
                            maxWidth: 80,
                            filter: {
                                term: GridService.getFilterByField(controller.paginationOptions, 'coreIdAsString', ''),
                                condition: uiGridConstants.filter.CONTAINS
                            }
                        },
                        {
                            displayName: 'Name',
                            visible: GridService.getVisibilityByField(controller.columnsVisibility, 'name', true),
                            field: 'name',
                            name: 'nameLowerCase',
                            filter: {
                                term: GridService.getFilterByField(controller.paginationOptions, 'name', ''),
                                condition: uiGridConstants.filter.CONTAINS
                            }
                        },
                        {
                            displayName: 'Type',
                            visible: GridService.getVisibilityByField(controller.columnsVisibility, 'type', true),
                            field: 'type',
                            filter: {
                                type: uiGridConstants.filter.SELECT,
                                selectOptions: TmProfileService.getProgramTypesList(),
                                term: GridService.getFilterByField(controller.paginationOptions, 'type')
                            },
                            filterHeaderTemplate: selectTemplate,
                            cellTemplate: selectCellTemplate
                        },
                        {
                            displayName: 'Advanced',
                            visible: GridService.getVisibilityByField(controller.columnsVisibility, 'advanced', true),
                            field: 'advanced',
                            filter: {
                                type: uiGridConstants.filter.SELECT,
                                selectOptions: [
                                    { value: true, label: 'Yes' },
                                    { value: false, label: 'No' }
                                ],
                                term: GridService.getFilterByField(controller.paginationOptions, 'advanced')
                            },
                            maxWidth: 100,
                            filterHeaderTemplate: selectTemplate,
                            cellTemplate: selectCellTemplate
                        },
                        {
                            displayName: 'Institution ID',
                            visible: GridService.getVisibilityByField(controller.columnsVisibility, 'institutionCoreIdAsString', true),
                            field: 'institutionCoreIdAsString',
                            filter: {
                                term: GridService.getFilterByField(controller.paginationOptions, 'institutionCoreIdAsString'),
                                condition: uiGridConstants.filter.CONTAINS
                            }
                        },
                        {
                            displayName: 'Institution Name',
                            visible: GridService.getVisibilityByField(controller.columnsVisibility, 'institutionName', true),
                            field: 'institutionName',
                            name: 'institutionNameLowerCase',
                            filter: {
                                term: GridService.getFilterByField(controller.paginationOptions, 'institutionName'),
                                condition: uiGridConstants.filter.CONTAINS
                            }
                        },
                        {
                            displayName: 'Parent Institution ID',
                            visible: GridService.getVisibilityByField(controller.columnsVisibility, 'parentInstitutionCoreIdAsString', true),
                            field: 'parentInstitutionCoreIdAsString',
                            filter: {
                                term: GridService.getFilterByField(controller.paginationOptions, 'parentInstitutionCoreIdAsString'),
                                condition: uiGridConstants.filter.CONTAINS
                            }
                        },
                        {
                            displayName: 'Parent Institution Name',
                            visible: GridService.getVisibilityByField(controller.columnsVisibility, 'parentInstitutionName', true),
                            field: 'parentInstitutionName',
                            name: 'parentInstitutionNameLowerCase',
                            filter: {
                                term: GridService.getFilterByField(controller.paginationOptions, 'parentInstitutionName'),
                                condition: uiGridConstants.filter.CONTAINS
                            }
                        },
                        {
                            displayName: 'Products',
                            visible: GridService.getVisibilityByField(controller.columnsVisibility, 'productsAsString', true),
                            filter: {
                                type: uiGridConstants.filter.SELECT,
                                selectOptions: TmProfileService.getProductsList(true),
                                term: TmDirectoryService.getMultiSelectTerm(
                                    GridService.getFilterByField(controller.paginationOptions, 'productsAsString')
                                )
                            },
                            field: 'productsAsString',
                            maxWidth: 130,
                            filterHeaderTemplate: multiSelectTemplate
                        },
                        {
                            displayName: 'Status',
                            visible: GridService.getVisibilityByField(controller.columnsVisibility, 'status', true),
                            filter: {
                                type: uiGridConstants.filter.SELECT,
                                selectOptions: TmProfileService.getProgramStatusList(),
                                term: GridService.getFilterByField(controller.paginationOptions, 'status')
                            },
                            field: 'status',
                            maxWidth: 130,
                            filterHeaderTemplate: selectTemplate,
                            cellTemplate: selectCellTemplate
                        }
                    ],
                    onRegisterApi: function (gridApi) {
                        var columnsVisibilityBeforeChanges = angular.copy($scope.gridOptions.columnDefs);
                        GridService.addHeaderClass($scope.gridOptions.columnDefs);
                        $scope.gridApi = gridApi;
                        gridApi.selection.on.rowSelectionChanged($scope, function (row) {
                            controller.handleDatagridRowClick(row.entity);
                        });
                        gridApi.core.on.rowsRendered($scope, function () {
                            $timeout(function(){
                                controller.isDatagridRendered = true;
                            },200);
                            GridService.generateSelectValues($scope.gridApi.grid.columns, selectValues);
                            GridService.generateSelectValues($scope.gridApi.grid.columns, lastSelectValues);
                        });
                        // actions to do on active page changes
                        gridApi.pagination.on.paginationChanged($scope, function (newPage, limit) {
                            controller.defaultPaginationOptions.page = newPage;
                            controller.defaultPaginationOptions.limit = limit;
                            if(!filterChangesInProgress) { 
                                controller.getPage();
                            }
                        });
                        // actions to do on filters changes
                        $scope.gridApi.core.on.filterChanged($scope, controller.handleGridFiltersChanges);
                        // actions to do on visible columns changes
                        $scope.gridApi.core.on.rowsVisibleChanged($scope, controller.handleGridVisibleChanges);
                        // actions to do on sort order changes
                        $scope.gridApi.core.on.sortChanged($scope, controller.handleGridFiltersChanges);
                        // reset columns visibility
                        GridService.resetExtend(columnsVisibilityBeforeChanges, $scope.gridOptions.columnDefs);
                    }
                });
            });
        };

        function exportVisible() {
            var results = $scope.gridOptions.data;
            results = TmDirectoryService.formatCsvResults(results);
            if (typeof results === 'undefined') {
                return false;
            }
            var gridApi = $scope.gridApi,
                gridOptions = $scope.gridOptions,
                exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                    gridApi.grid,
                    uiGridExporterConstants.VISIBLE
                );

            var exportData = GridService.getCsvData(gridApi.grid.columns, results);
            var csvContent = uiGridExporterService.formatAsCsv(
                exportColumnHeaders,
                exportData,
                gridOptions.exporterCsvColumnSeparator
            );
            uiGridExporterService.downloadFile(
                'tm-directory-export' + TimeService.now() + '.csv',
                csvContent,
                gridOptions.exporterOlderExcelCompatibility
            );
        }

        function exportAll() {
            TmDirectoryService.getAllPrograms(
                angular.merge({}, controller.paginationOptions, {
                    page: 1,
                    limit: 250000
                })
            ).then(function (response) {
                if (typeof response.results === 'undefined') {
                    return false;
                }
                response = TmDirectoryService.formatCsvResults(response.results);
                var gridApi = $scope.gridApi,
                    gridOptions = $scope.gridOptions,
                    exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                        gridApi.grid,
                        uiGridExporterConstants.VISIBLE
                    );

                var exportData = GridService.getCsvData(gridApi.grid.columns, response);
                var csvContent = uiGridExporterService.formatAsCsv(
                    exportColumnHeaders,
                    exportData,
                    gridOptions.exporterCsvColumnSeparator
                );
                uiGridExporterService.downloadFile(
                    'tm-directory-full-export' + TimeService.now() + '.csv',
                    csvContent,
                    gridOptions.exporterOlderExcelCompatibility
                );
            });
        }

        controller.handleGridVisibleChanges = function () {
            var columnsVisibility = GridService.getColumnsVisibility(constants.gridNameSpaces.tmDirectoryGridVisibility),
            reload = false;

            if (columnsVisibility) {
                angular.forEach($scope.gridOptions.columnDefs, function (column, key) {
                    if ((columnsVisibility[column.field] === true || typeof columnsVisibility[column.field] === 'undefined') &&
                        column.visible === false
                    ) {
                        if (typeof $scope.gridOptions.columnDefs[key].filter === 'undefined') {
                            $scope.gridOptions.columnDefs[key].filter = {};
                        }
                        if (typeof  $scope.gridOptions.columnDefs[key].filter !== 'undefined' &&
                            $scope.gridOptions.columnDefs[key].filter.hasOwnProperty('term') &&
                            ($scope.gridOptions.columnDefs[key].filter.term || $scope.gridOptions.columnDefs[key].filter.term === false)) {
                            reload = true;
                        }
                        $scope.gridOptions.columnDefs[key].filter.term = null;
                        controller.paginationOptions['filter[' + column.field + ']'] = null;
                    }
                });
            }

            controller.columnsVisibility = GridService.getGridColumnsVisibility($scope.gridOptions.columnDefs);
            GridService.storeColumnsVisibility(
                constants.gridNameSpaces.tmDirectoryGridVisibility,
                controller.columnsVisibility
            );
            if (reload) {
                controller.reloadDatagrid();
            }
        };

        controller.init = function () {
            controller.initDataGrid();
            TmProfileFactory.setBackend(true);
        };

        controller.init();
    };

    angular
        .module('qsHub')
        .controller('TmDirectoryController', [
            '$scope',
            '$timeout',
            'constants',
            'uiGridConstants',
            'uiGridExporterConstants',
            'uiGridExporterService',
            'TmProfileService',
            'TmDirectoryService',
            'TmProfileProgramsService',
            'InstitutionsListService',
            'TmDirectoryFactory',
            'TmProfileFactory',
            'NotifierFactory',
            'InstitutionFactory',
            'UiGridService',
            'TimeService',
            App.controllers.tmDirectory
        ]);

} (window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    App.services.tmDirectory = function (
        $resource,
        $q,
        $log,
        constants,
        TimeService,
        TmProfileService
    ) {
        var service = {
            tmProgramHistory: null,
            deferred : {
                getTmProgramHistory: null
            }
        };

        /**
         * Get programs list API endpoint.
         *
         * @returns {$resource}
         */
        service.getProgramsListModel = function () {
            return $resource(constants.api.institutions.url + '/v1/list/tm-programs', null, {
                get: {
                    cancellable : true
                }
            });
        };

        service.getProgramStatusRevertModel = function () {
            return $resource(constants.api.institutions.url + '/v1/admin/tm-program/:id/revert-status');
        };

        service.getUpdateModel = function() {
            return $resource(constants.api.institutions.url + '/v1/admin/tm-program/:id', null, {
                update: { method: 'PATCH' }
            });
        };

        service.getProgramHistoryModel = function () {
            return $resource(constants.api.institutions.url + '/v1/tm-program-history/:id');
        };

        service.revertStatus = function (id, sendEmailToClient) {
            if (constants.dev) {
                var startTime = new Date().getTime(),
                    endTime;
            }
            return service.getProgramStatusRevertModel().get({
                id: id,
                sendEmailToClient: sendEmailToClient
            }).$promise.then(function (data) {
                if (constants.dev) {
                    endTime = new Date().getTime();
                    $log.log('success, got data: ', data, 'in ' + (endTime - startTime) + ' ms');
                }
                return data;
            }, function (error) {
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        service.delete = function(id, publish, sendEmailToClient) {
            publish = publish || false;
            sendEmailToClient = sendEmailToClient || false;
            // @todo validate data
            return service.getUpdateModel().delete({
                id: id,
                publish: publish,
                sendEmailToClient: sendEmailToClient
            }).$promise.then(function(data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function(error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        service.approve = function(program, publish, sendEmailToClient) {
            publish = publish || false;
            sendEmailToClient = sendEmailToClient || false;
            // @todo validate data
            return service.getUpdateModel().update({
                id: program.id,
                publish: publish,
                sendEmailToClient: sendEmailToClient
            }, program).$promise.then(function(data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                if (publish && angular.isDefined(data.published)) {
                    return {
                        published : data.published
                    };
                }
                return true;
            }, function(error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        service.getAllPrograms = function (params) {
            if (constants.dev) {
                var startTime = new Date().getTime(),
                    endTime;
            }
            var hasSorting = false;
            if (params) {
                for (var key in params) {
                    if (key.indexOf('sorting') !== -1) {
                        hasSorting = true;
                        break;
                    } else if (key.indexOf('filter[status]') !== -1 && params[key]) {
                        params[key] =  '=' + params[key];
                    }
                }
            }
            if (!hasSorting) {
                params['sorting[createdAt]'] = 'desc';
            }
            return service.getProgramsListModel().get(params).$promise.then(function (data) {
                if (constants.dev) {
                    endTime = new Date().getTime();
                    $log.log('success, got data: ', data, 'in ' + (endTime - startTime) + ' ms');
                }

                return data;
            }, function (error) {
                if (constants.dev) {
                    $log.log('request failed' + error);
                }

                return false;
            });
        };

        /**
         * Format data on export.
         *
         * @param data
         * @returns {*}
         */
        
        service.formatCsvResults = function (data) {
            data = angular.copy(data);
            angular.forEach(data, function (column, dataKey) {
                angular.forEach(column, function (item, key) {
                    if (key === 'status') {
                        data[dataKey][key] = getStatus(item);
                    } else if(key === 'type' && data[dataKey][key]) {
                        data[dataKey][key] = getType(item);
                    } else if(key === 'advanced') {
                        data[dataKey][key] =  data[dataKey][key] ? 'Yes' : 'No';
                    }
                });
            });

            return data;
        };

        function getStatus(status) {
            var programStatusList = TmProfileService.getProgramStatusList(),
                total = programStatusList.length,
                i = 0;
            for (i; i < total; i++) {
                 if (programStatusList[i].value === status) {
                     return programStatusList[i].label;
               }
            }
            return null;
        }

        function getType(type) {
            var programTypesList = TmProfileService.getProgramTypesList(),
                total = programTypesList.length,
                i = 0;
            for (i; i < total; i++) {
                 if (programTypesList[i].value === type) {
                     return programTypesList[i].label;
               }
            }
            return null;
        }

        service.getProgramHistory = function (id) {
            // process already running
            if (!service.deferred.getTmProgramHistory) {
                service.deferred.getTmProgramHistory = $q.defer();
            } else {
                return service.deferred.getTmProgramHistory.promise;
            }
            var deferred = service.deferred.getTmProgramHistory;

            if (service.tmProgramHistory) {
                deferred.resolve(service.tmProgramHistory);
            } else {
                service.getProgramHistoryModel().get(
                    {id: id}, function (data) {
                    if (constants.dev) {
                        $log.log('success, got data: ', data);
                    }
                    service.tmProgramHistory = data;
                    deferred.resolve(service.tmProgramHistory);
                    // reset data
                    deferred = null;
                    service.tmProgramHistory = null;
                    service.deferred.getTmProgramHistory = null;
                }, function (error) {
                    //@todo log error
                    if (constants.dev) {
                        $log.log('request failed' + error);
                    }
                    service.tmProgramHistory = [];
                    deferred.resolve(service.tmProgramHistory);
                    // reset data
                    deferred = null;
                    service.tmProgramHistory = null;
                    service.deferred.getTmProgramHistory = null;
                });
            }

            return deferred.promise;
        };

        service.getMultiSelectTerm = function(filter) {
            var filters = filter && (typeof filter === 'string') ? filter.replace('=', '').split(', ') : [],
                items = [],
                i = 0;
            for (i; i < filters.length; i++) {
                items.push({
                    label: filters[i],
                    value: filters[i].toLowerCase()
                });
            }

            return items;
        };

        service.getOrderProductsFilter = function(products) {
            var productsResult = [];
            angular.forEach(products, function (product) {
                productsResult.push(product.value);
            });

            return productsResult.sort();
        };

        return service;
    };

    angular
        .module('qsHub')
        .service('TmDirectoryService', [
            '$resource',
            '$q',
            '$log',
            'constants',
            'TimeService',
            'TmProfileService',
            App.services.tmDirectory
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {factories:{}});

    App.factories.TmDirectory = function () {
        var editMode;
        
        return {
            isEditMode: function () {
                return !!editMode;
            },
            setEditMode: function (enabled) {
                editMode = enabled;
            }
        };
    };

    angular
        .module('qsHub')
        .factory('TmDirectoryFactory', [
            App.factories.TmDirectory
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers:{}});

    App.controllers.contactsList = function (
        $scope,
        constants,
        ContactsService,
        InstitutionsUsersFactory,
        InstitutionFactory,
        WatchService
    ) {
        var controller = this;
        controller.devMode = constants.dev;

        controller.getCoreIdWatch = function (coreId) {
            if (coreId) {
                ContactsService.getDepartmentsCoreIds(coreId).then(function (departmentsCoreIds) {
                    departmentsCoreIds.push(coreId);
                    InstitutionsUsersFactory.setRequestFilters({
                        'filter[primaryInstitutionCoreId][]': departmentsCoreIds
                    });
                });
            }
        };

        controller.initWatches = function () {
            // listen to active institution changes
            WatchService.create($scope, InstitutionFactory.getCoreId, controller.getCoreIdWatch);
        };

        /**
         * Class constructor.
         */
        controller.$onInit = function () {
            controller.initWatches();
        };
    };

    angular
        .module('qsHub')
        .controller('ContactsListController', [
            '$scope',
            'constants',
            'ContactsService',
            'InstitutionsUsersFactory',
            'InstitutionFactory',
            'WatchService',
            App.controllers.contactsList
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    App.services.Contacts = function (
        $log,
        constants,
        RequestsQueueService,
        InstitutionsListService
    ) {
        var service = {};

        /**
         * Get institution departments core IDs.
         *
         * @param {int} parentInstitutionCoreId
         * @returns {Promise.<Array>}
         */
        service.getDepartmentsCoreIds = function (parentInstitutionCoreId) {
            if (constants.dev) {
                var startTime = new Date().getTime(), endTime;
            }

            // cancel currently running requests
            RequestsQueueService.cancelAll('getDepartmentsCoreIds');

            var Api = InstitutionsListService.getInstitutionListModel().get({
                'filter[parentCoreId]': '=' + parentInstitutionCoreId,
                'filter[typeId][]': [constants.typeId.clientDepartmentId, constants.typeId.advancedProgramId],
                'columns[]': 'coreId'
            });

            // add new request to the queue
            RequestsQueueService.add(Api, 'getDepartmentsCoreIds');

            return Api.$promise.then(function (data) {
                if (constants.dev) {
                    endTime = new Date().getTime();
                    $log.log('success, got data: ', data, 'in ' + (endTime - startTime) + ' ms');
                }

                // cancel currently running requests
                RequestsQueueService.cancelAll('getDepartmentsCoreIds');
                return service.formatList(data.results);
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                } else {
                    return [];
                }
            });
        };

        service.formatList = function (list) {
            var formattedList = [];
            angular.forEach(list, function (value) {
                if (value && value.coreId) {
                    formattedList.push(value.coreId);
                }
            });
            return formattedList;
        };

        return service;
    };

    angular
        .module('qsHub')
        .service('ContactsService', [
            '$log',
            'constants',
            'RequestsQueueService',
            'InstitutionsListService',
            App.services.Contacts
        ]);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers:{}});

    App.controllers.institutionsUsersListClone = function (
        $scope,
        $timeout,
        constants,
        uiGridConstants,
        InstitutionsUsersListService,
        UsersListService,
        NotifierFactory,
        InstitutionsUsersListFactory,
        InstitutionsUsersFactory,
        WatchService,
        UserTypesService,
        PasswordService,
        GridService,
        UserFactory,
        TimeService,
        DateRangeFixerService,
        ModalService
    ) {
        var controller = this;
        var prevLastLoginAtDateFilter = null,
        filterChangesInProgress = false;
        controller.devMode = constants.dev;
        controller.defaultFilters = {
            lastLoginAtRange: {
                startDate: null,
                endDate: null
            }
        };
        controller.showDatagrid = false;
        controller.grid = {};
        controller.defaultPaginationOptions = {
            page: 1,
            limit: constants.datagrid.contacts.defaultRowsNumber
        };
        controller.paginationOptions = GridService.getColumnsFilters(
            constants.gridNameSpaces.contactsGridFilters,
            []
        );
        controller.columnsVisibility = GridService.getColumnsVisibility(
            constants.gridNameSpaces.contactsGridVisibility
        );
        controller.isDatagridReloading = false;
        controller.isDatagridRendered = false;
        controller.rolesBeforeChanges = [];

        $scope.user = {};
        $scope.newUser = {};
        $scope.newUserPanelNotClosed = false;
        $scope.userBeforeChanges = {};
        $scope.forms = {};
        $scope.filterActive = true;
        $scope.disabledInstitutionsUserListSubTabs = {
            'personalDetails' : false,
            'permissions' : true,
            'institutions' : true
        };
        $scope.filters = null;
        $scope.columnsBeforeHide = [];
        $scope.visible = {};

        controller.hasWriteAccess = UserFactory.hasInstitutionsUsersAccess();
        controller.userTypes = UserTypesService.getUserTypes();
        controller.initSelectedItem = function () {
            $scope.selectedItem = {};
            $scope.selectedItem.selectedOptionType = controller.userTypes[1];
        };
        controller.initSelectedItem();
        controller.contactTypesList = [];

        InstitutionsUsersListService.getContactTypes().then(function(contactTypes) {
            controller.contactTypes = contactTypes;
            angular.forEach(contactTypes, function (item) {
                controller.contactTypesList.push({
                    value: item.handle,
                    label: item.name
                });
            });
        });
        InstitutionsUsersListService.getRoles().then(function(rolesList) {
            controller.rolesList = rolesList;
        });
        InstitutionsUsersListService.getSections().then(function(sectionsList) {
            controller.sectionsList = sectionsList;
        });

        controller.getColumnNumber = function (columnName) {
            var key = 0,
                total = controller.grid.options.columnDefs.length;
            for (key; key < total; key++) {
                if (controller.grid.options.columnDefs[key] &&
                    controller.grid.options.columnDefs[key].field === columnName
                ) {
                    return key;
                }
            }
            return null;
        };

        $scope.handleLastLoginAtDateRange = function (event) {

            //Handle selecting today only / one day date range

            if (DateRangeFixerService.sameDayAndNoModel(event, $scope.filters.lastLoginAtRange, prevLastLoginAtDateFilter)) {
                event.model.startDate = prevLastLoginAtDateFilter.startDate;
                event.model.endDate = prevLastLoginAtDateFilter.endDate;
                $scope.filters.lastLoginAtRange = {
                    startDate: prevLastLoginAtDateFilter.startDate,
                    endDate: prevLastLoginAtDateFilter.endDate
                };
            } else if (DateRangeFixerService.todayRangeAndNoModel(event, prevLastLoginAtDateFilter)) {
                event.model.startDate = TimeService.getStartOfToday();
                event.model.endDate = TimeService.getEndOfToday();
                $scope.filters.lastLoginAtRange = {
                    startDate: event.model.startDate.toISOString(),
                    endDate: event.model.endDate.toISOString()
                };
            }

            if (event.model.startDate && event.model.endDate) {
                controller.grid.options.columnDefs[controller.getColumnNumber('lastLoginAt')].filters[0].term = typeof event.model.startDate === 'object' ? event.model.startDate.format('x') : event.model.startDate;
                controller.grid.options.columnDefs[controller.getColumnNumber('lastLoginAt')].filters[1].term = typeof event.model.endDate === 'object' ? event.model.endDate.format('x') : event.model.endDate;
            }

            prevLastLoginAtDateFilter = angular.copy({
                startDate: event.model.startDate,
                endDate: event.model.endDate
            });
        };

        controller.handleGridVisibleChanges = function () {
            var columnsVisibility = GridService.getColumnsVisibility(constants.gridNameSpaces.contactsGridVisibility),
                reload = false;

            if (columnsVisibility && $scope.filterActive) {
                angular.forEach(controller.grid.options.columnDefs, function(column, key) {
                    if (columnsVisibility[column.field] === true &&
                        column.visible === false
                    ) {
                        if (typeof  controller.grid.options.columnDefs[key].filter === 'undefined') {
                            controller.grid.options.columnDefs[key].filter = {};
                        }
                        if (typeof  controller.grid.options.columnDefs[key].filter !== 'undefined' &&
                            controller.grid.options.columnDefs[key].filter.hasOwnProperty('term') &&
                            controller.grid.options.columnDefs[key].filter.term) {
                            reload = true;
                        }
                        controller.grid.options.columnDefs[key].filter.term = null;
                        controller.paginationOptions['filter[' + controller.convertVisibleColumnsField(column.field) + ']'] = null;
                        if (column.field === 'lastLoginAt') {
                            delete controller.grid.options.columnDefs[key].filters[0].term;
                            $scope.filters.lastLoginAtRange = {
                                startDate: null,
                                endDate: null
                            };
                            controller.paginationOptions['filter[' + column.field + ']'] = $scope.filters.lastLoginAtRange;
                        }
                    }
                });
            }
            if ($scope.filterActive) {
                controller.columnsVisibility = GridService.getGridColumnsVisibility(controller.grid.options.columnDefs);
                GridService.storeColumnsVisibility(
                    constants.gridNameSpaces.contactsGridVisibility,
                    controller.columnsVisibility
                );
            }
            if (reload) {
                controller.getPage();
            }
        };

        controller.handleGridFiltersChanges = function () {
            filterChangesInProgress = true;
            if($scope.gridApi.pagination.getPage() > 1){
                $scope.gridApi.pagination.seek(1);
            }
            controller.paginationOptions = angular.copy(controller.defaultPaginationOptions);
            var filterColumn, sortColumn, sortKeys = [];

            angular.forEach($scope.gridApi.grid.columns, function (column) {
                if (typeof column.filters !== 'undefined' && column.filters[0].term !== 'undefined') {
                    if (
                        column.filters[0].type === uiGridConstants.filter.SELECT &&
                        typeof column.filters[0].term === 'object' &&
                        column.filters[0].term !== null &&
                        typeof column.filters[0].term.value !== 'undefined'
                    ) {
                        column.filters[0].term = column.filters[0].term.value;
                    }
                }
                if (typeof column.sort.priority !== 'undefined') {
                    sortKeys[column.sort.priority] = column.name;
                }
            });
            if (sortKeys.length > 1) {
                var latestSortKey = sortKeys.pop();
                angular.forEach($scope.gridApi.grid.columns, function (column) {
                    if (typeof column.sort.priority !== 'undefined' && column.name !== latestSortKey) {
                        column.sort = {};
                    }
                });
            }

            angular.forEach ($scope.gridApi.grid.columns, function(column) {
                filterColumn = column.filters[0].column ? column.filters[0].column : column.field;
                if (column.field === 'lastLoginAt') {
                    if (
                        typeof column.filters !== 'undefined' &&
                        typeof column.filters[0].term !== 'undefined' &&
                        typeof column.filters[1].term !== 'undefined'
                    ) {
                        controller.paginationOptions['filter[' + filterColumn + ']'] = {
                            startDate: column.filters[0].term && TimeService.isMoment(column.filters[0].term) ? column.filters[0].term.format('x') : column.filters[0].term,
                            endDate: column.filters[1].term && TimeService.isMoment(column.filters[1].term) ? column.filters[1].term.format('x') : column.filters[1].term
                        };
                    } else {
                        controller.paginationOptions['filter[' + filterColumn + ']'] = null;
                        $scope.filters.lastLoginAtRange = {
                            startDate: null,
                            endDate: null
                        };
                    }
                } else if ((column.field === 'contactTypesAsString' ||
                    column.field === 'contactTypes.handle') &&
                    typeof column.filters !== 'undefined' &&
                    column.filters[0].term !== 'undefined'
                ) {
                    controller.paginationOptions['filter[contactTypes.handle]'] = column.filters[0].term;
                } else if (typeof column.filters !== 'undefined' && typeof column.filters[0].term !== 'undefined') {
                    controller.paginationOptions['filter[' + filterColumn + ']'] = column.filters[0].term;
                }
                if (typeof column.sort.direction !== 'undefined') {
                    sortColumn = column.sort.column ? column.sort.column : column.field;
                    controller.paginationOptions['sorting[' + sortColumn + ']'] = column.sort.direction;
                }
            });

            if (controller.paginationOptions["filter[lastLoginAt]"] === null) {
                controller.paginationOptions["filter[lastLoginAt]"] = $scope.filters.lastLoginAtRange;
            }
            if (controller.paginationOptions["filter[lastLoginAt]"].startDate === null) {
                controller.paginationOptions["filter[lastLoginAt]"].endDate = null;
                $scope.filters.lastLoginAtRange = {
                    startDate: null,
                    endDate: null
                };
            }
            angular.forEach(controller.paginationOptions, function (value, key) {
                if (key === 'filter[contactTypesAsString]') {
                    controller.paginationOptions['filter[contactTypes.handle]'] = value;
                    delete controller.paginationOptions['filter[contactTypesAsString]'];
                }
            });
            GridService.storeColumnsFilters(
                constants.gridNameSpaces.contactsGridFilters,
                controller.paginationOptions
            );

            if (angular.isDefined($scope.filterTimeout)) {
                $timeout.cancel($scope.filterTimeout);
            }
            $scope.filterTimeout = $timeout(function () {
                controller.getPage();
                filterChangesInProgress = false;
            }, constants.datagridInputDelay);
        };

        /**
         * Actions to do when datagrid row is clicked.
         *
         * @param {object} user
         */
        $scope.handleDatagridRowClick = function (user) {
            PasswordService.setHasErrors(true);
            $scope.disabledInstitutionsUserListSubTabs.permissions = false;
            $scope.disabledInstitutionsUserListSubTabs.institutions = false;
            if ($scope.selectedUserId === user.id) {
                $scope.selectedUserId = null;
                $scope.showInfoBlock = false;
                $scope.filterActive = true;
                InstitutionsUsersListFactory.resetActiveUserId();
                controller.toggleColumns(false);
            } else { //edit mode
                $scope.selectedUserId = user.id;
                $scope.showInfoBlock = true;
                $scope.filterActive = false;

                $scope.user = angular.copy(user);
                $scope.userBeforeChanges = user;
                $scope.user = UsersListService.updateRolesWithAll($scope.user, controller.rolesList);
                $scope.user = UsersListService.updateSectionsAndPages($scope.user, controller.rolesList);

                controller.rolesBeforeChanges = [];
                for (var role in user.roles) {
                    if (user.roles[role]) {
                        controller.rolesBeforeChanges[role] = true;
                    }
                }

                InstitutionsUsersListFactory.setActiveUserId($scope.selectedUserId);
                controller.toggleColumns(true);
            }
        };

        controller.updateInDatagrid = function ()
        {
            controller.reloadDatagrid();
        };

        /**
         * Toggle datagrid columns visibility.
         *
         * @param {boolean} hide
         */
        controller.toggleColumns = function (hide) {
            if (!controller.grid.options) {
                return;
            }
            if (hide) {
                angular.forEach(controller.grid.options.columnDefs, function (column) {
                    if (angular.isDefined(column.visible) && column.visible === true) {
                        $scope.columnsBeforeHide.push(column);
                    }
                    $scope.visible[column.field] = column.visible;
                });
                // hide all columns except name
                angular.forEach(controller.grid.options.columnDefs, function (column) {
                    column.visible = column.field === 'email';
                });
                $scope.filterActive = false;
            } else {
                // show columns visible before hide
                angular.forEach($scope.columnsBeforeHide, function (column) {
                    column.visible = true;
                });
                $scope.columnsBeforeHide = [];

                $scope.$broadcast(constants.events.closingInstitutionsUsers);
                $scope.filterActive = true;
            }

            controller.grid.options.enableGridMenu = !hide;
            controller.grid.options.enableColumnMenus = !hide;
            controller.grid.options.enableColumnResizing = !hide;
            controller.grid.options.enablePaginationControls = !hide;
        };

        /**
         * Reset user form.
         */
        $scope.handleResetClick = function () {
            $scope.user = angular.copy($scope.userBeforeChanges);
        };

        /**
         * Actions to do when deactivation is triggered.
         *
         * @param {Object} user
         * @returns {boolean}
         */
        $scope.handleDeactivateClick = function (user) {
            if (!user || !user.id || $scope.deactivateInProgress) {
                return false;
            }
            var modalOptions = {
                closeButtonText: 'Cancel',
                actionButtonText: 'Yes',
                actionButtonClass: 'danger',
                headerText: 'Deactivation',
                bodyText: 'Are you sure you wish to deactivate contact?'
            };
            ModalService.show({}, modalOptions).then(function () {
                $scope.deactivateInProgress = true;
                InstitutionsUsersListService.deactivate(user.id).then(controller.deactivationCallback);
            });
        };

        /**
         * Handle activate click
         *
         * @param {object} user
         * @returns {Boolean}
         */
        $scope.handleActivateClick = function (user) {
            if (!user.id || $scope.activateInProgress) {
                return false;
            }
            $scope.activateInProgress = true;
            InstitutionsUsersListService.activate(user.id).then(controller.activationCallback);
        };

        controller.deactivationCallback = function (success)
        {
            $scope.user.active = success ? false : true;
            if (success) {
                $scope.userBeforeChanges.active = $scope.user.active;
                // update user data in datagrid
                controller.updateInDatagrid($scope.userBeforeChanges);
            }
            $scope.deactivateInProgress = false;

            NotifierFactory.show(
                success ? 'success' : 'error',
                success ? 'Deactivated successfully!' : 'Deactivation failed!',
                'Deactivation'
            );
        };

        controller.activationCallback = function (success)
        {
            $scope.user.active = success ? true : false;
            if (success) {
                $scope.userBeforeChanges.active = $scope.user.active;
                // update user data in datagrid
                controller.updateInDatagrid($scope.userBeforeChanges);
            }
            $scope.activateInProgress = false;

            NotifierFactory.show(
                success ? 'success' : 'error',
                success ? 'Activated successfully!' : 'Activation failed!',
                'Activation'
            );
        };

        $scope.handleNewUserResponse = function (response) {
            if (response.hasOwnProperty('insertedId') && response.insertedId.length > 0) {
                $scope.user.id = response.insertedId;
                $scope.user.active = true;
                angular.copy($scope.user, $scope.userBeforeChanges);
                // switch tab after user add
                $scope.activeTab = 1;
                $scope.disabledInstitutionsUserListSubTabs.permissions = false;
                $scope.disabledInstitutionsUserListSubTabs.institutions = false;
                angular.copy($scope.user, $scope.newUser);
                $scope.selectedUserId = $scope.user.id;
                $scope.newUserPanelNotClosed = true;
                // reload datagrid
                controller.reloadDatagrid();
            }
        };

        /**
         * Store user data.
         */
        $scope.handlePersonalDetailsSubmit = function () {
            $scope.forms.personalDetailsForm.firstname.$setDirty();
            $scope.forms.personalDetailsForm.lastname.$setDirty();
            $scope.forms.personalDetailsForm.email.$setDirty();
            PasswordService.setHasErrors(
                $scope.user.id ||
                !!$scope.forms.personalDetailsForm.password.$viewValue ? true : false
            );
            var newUser = typeof $scope.user.id === 'undefined';
            if (
                $scope.personalDetailsSubmitInProgress ||
                !$scope.forms.personalDetailsForm ||
                !$scope.forms.personalDetailsForm.$valid||
                !PasswordService.isValid($scope.forms.personalDetailsForm, $scope.user) ||
                !PasswordService.getHasErrors()
            ) {
                return false;
            }

            // if is new user and the password is not set or setting password for existing user
            if (
                (newUser && !PasswordService.isPasswordValid($scope.user.password)) ||
                (!newUser && typeof $scope.user.password !== 'undefined' && !PasswordService.isPasswordValid($scope.user.password))
            ) {
                NotifierFactory.show(
                    'error',
                    'Please set a valid password'
                );

                return false;
            }
            $scope.personalDetailsSubmitInProgress = true;
            InstitutionsUsersListService.savePersonalDetails($scope.user).then(function (response) {
                var message = 'Personal Details',
                    success = false;
                if (response) {
                    success = !response.error;
                    if (!success) {
                        if (response.hasOwnProperty('message') && response.message.length) {
                            message = response.message;
                        }
                    } else {
                        $scope.user.primaryInstitutionCoreId = null;
                        $scope.user.fullname = InstitutionsUsersListService.getFullName($scope.user);
                        if (response.hasOwnProperty('insertedId') && response.insertedId.length) {
                            $scope.user.id = response.insertedId;
                            $scope.user.active = true;
                            angular.copy($scope.user, $scope.userBeforeChanges);
                            // switch tab after user add
                            $scope.activeTab = 1;
                            $scope.disabledInstitutionsUserListSubTabs.permissions = false;
                            $scope.disabledInstitutionsUserListSubTabs.institutions = false;
                            angular.copy($scope.user, $scope.newUser);
                            $scope.selectedUserId = $scope.user.id;
                        }
                        //reset personalDetailsForm
                        controller.resetPersonalDetailsForm();
                    }

                    if (success && $scope.user.contactTypes) {
                        InstitutionsUsersListService.getContactTypes().then(function(contactTypesList) {
                            if (!contactTypesList) {
                                return false;
                            }
                            var contactTypeNames = [],
                                contactTypeName = null;

                            for (var contactType in $scope.user.contactTypes) {
                                if ($scope.user.contactTypes[contactType].assigned) {
                                    contactTypeName = UsersListService.getContactTypeNameByHandle(
                                        contactType, contactTypesList
                                    );
                                    contactTypeNames.push(contactTypeName);
                                }
                            }
                            $scope.user.contactTypesAsString = contactTypeNames.join(', ');
                        });
                    }
                    if (success) {
                        delete $scope.user.password;
                        // update original user object with changes
                        $scope.userBeforeChanges = angular.copy($scope.user);
                        // reload  datagrid
                        controller.reloadDatagrid();
                    }
                    $scope.personalDetailsSubmitInProgress = false;
                    NotifierFactory.show(
                        success ? 'success' : 'error',
                        success ? 'Saved successfully!' : 'Saving failed!',
                        message
                    );


                }
            });
        };

        $scope.handlePermissionsSubmit = function () {
            $scope.permissionsSubmitInProgress = true;
            InstitutionsUsersListService.savePermissions($scope.user).then(function (success) {
                $scope.permissionsSubmitInProgress = false;
                $scope.userBeforeChanges = angular.copy($scope.user);
                controller.rolesBeforeChanges = [];
                for (var role in $scope.user.roles) {
                    if ($scope.user.roles[role]) {
                        controller.rolesBeforeChanges[role] = true;
                    }
                }
                if (success) {
                    controller.updateInDatagrid($scope.user);
                }
                NotifierFactory.show(
                    success ? 'success' : 'error',
                    success ? 'Saved successfully!' : 'Saving failed!',
                    'Permissions'
                );
            });
        };

        $scope.handlePrimaryContactTypeClick = function (handle) {
            $scope.user.contactTypes[handle].assigned = true;
        };

        $scope.handleContactTypeClick = function (handle) {
            if ($scope.user.contactTypes[handle] &&
                $scope.user.contactTypes[handle].primary &&
                !$scope.user.contactTypes[handle].assigned
            ) {
                $scope.user.contactTypes[handle].primary = false;
            }
        };

        $scope.handleAssignedContactTypeClick = function (handle) {
            if ($scope.user.contactTypes[handle].assigned === false) {
                $scope.user.contactTypes[handle].primary = false;
            }
        };

        $scope.handleRoleClick = function (clickedRole) {
            if ($scope.user.roles.custom || typeof clickedRole === 'undefined') {
                return;
            }

            $scope.user.accessTo = {};
            var clickedRoleAssigned = $scope.user.roles[clickedRole.roleHandle],
                parentRoleClicked = clickedRole.all,
                childrenRolesHandles = UsersListService.getChildrenRoles(clickedRole.groupHandle, controller.rolesList),
                parentRoleHandle = null;

            // if role is assigned
            if (clickedRoleAssigned) {
                // if its parent role
                if (parentRoleClicked) {
                    // assign children roles to a user
                    if (childrenRolesHandles) {
                        angular.forEach(childrenRolesHandles, function (childrenRoleHandle) {
                            $scope.user.roles[childrenRoleHandle] = true;
                        });
                    }
                } else {
                    if (childrenRolesHandles) {
                        // Select parent if all children were selected
                        var allDepartments = true;
                        angular.forEach(childrenRolesHandles, function (childrenRoleHandle) {
                            if ($scope.user.roles[childrenRoleHandle] === false ||
                                $scope.user.roles[childrenRoleHandle] === undefined
                            ) {
                                allDepartments = false;
                                return;
                            }
                        });
                        if (allDepartments) {
                            parentRoleHandle = UsersListService.getParentRoleHandle(clickedRole.groupHandle, controller.rolesList);
                            if (parentRoleHandle) {
                                $scope.user.roles[parentRoleHandle] = true;
                            }
                        }
                    }
                }
            } else {
                if (!parentRoleClicked) {
                    // Uncheck parent if it was selected when unselecting one children
                    parentRoleHandle = UsersListService.getParentRoleHandle(clickedRole.groupHandle, controller.rolesList);
                    if (parentRoleHandle) {
                        $scope.user.roles[parentRoleHandle] = false;
                    }
                } else {
                    // check if all children where selected to select the parent
                    if (childrenRolesHandles) {
                        angular.forEach(childrenRolesHandles, function (childrenRoleHandle) {
                            $scope.user.roles[childrenRoleHandle] = false;
                        });
                    }
                }
            }
            // Update sections and pages based on the new roles
            $scope.user = UsersListService.updateSectionsAndPages($scope.user, controller.rolesList);
        };

        $scope.activateCustomRole = function () {
            if (!angular.isDefined($scope.user.roles)) {
                $scope.user.roles = {};
            }
            $scope.user.roles.custom = true;
        };

        /**
         * Actions to do when column is clicked under visible columns dropdown.
         */
        $scope.handleColumnClick = function () {
            var columnsVisibility = {};
            angular.forEach($scope.columns, function (column) {
                columnsVisibility[column.field] = column.show;
            });
            InstitutionsUsersListService.storeColumnsVisibility(columnsVisibility);
        };

        controller.handleAddInstitutionsUserClick = function () {
            controller.resetPersonalDetailsForm();
            $scope.selectedUserId = null;
            $scope.user = {};
            InstitutionsUsersListFactory.resetActiveUserId();
            // set personal details tab as active
            $scope.activeTab = 0;
            $scope.disabledInstitutionsUserListSubTabs.permissions = true;
            $scope.disabledInstitutionsUserListSubTabs.institutions = true;
            // trigger edit mode
            controller.toggleColumns(true);
            $scope.showInfoBlock = true;
        };
        controller.resetPersonalDetailsForm = function () {
            //reset personalDetailsForm
            if ($scope.forms.personalDetailsForm) {
                $scope.forms.personalDetailsForm.$setPristine();
            }
        };

        controller.handleEditCloseClick = function () {
            controller.toggleColumns(false);
            $scope.showInfoBlock = false;
            $scope.selectedUserId = null;
            $scope.activeTab = 0;
            $scope.filterActive = true;
        };

        /**
         * Apply datagrid columns visibility from browser cache.
         */
        controller.loadDatagridColumnsVisibility = function () {
            var columnsVisibility = InstitutionsUsersListService.getColumnsVisibility();
            if (columnsVisibility) {
                angular.forEach($scope.columns, function (column) {
                    if (columnsVisibility[column.field] !== 'undefined') {
                        column.show = columnsVisibility[column.field] ? true : false;
                    }
                });
            }
        };

        controller.reloadDatagrid = function () {
            controller.getPage();
        };

        controller.getPage = function() {
            controller.isDatagridReloading = true;
            return InstitutionsUsersListService.getAllUsers(
                angular.merge({}, controller.paginationOptions, controller.defaultPaginationOptions, $scope.filters)
            ).then(function(response) {
                controller.grid.options.totalItems = response.totalMatching;
                controller.grid.options.data = response.results;
                controller.grid.options.minRowsToShow = response.totalFiltered;
                controller.isDatagridReloading = false;
                controller.showDatagrid = true;
            });
        };

        controller.reloadDatagrid = function () {
            controller.getPage();
        };

        controller.initDataGridOptions = function () {
            var selectTemplate = '/scripts/shared/ui-grid/templates/selectFilterHeaderTemplate.html',
                selectCellTemplate = '/scripts/shared/ui-grid/templates/selectCellTemplate.html',
                dateCellTemplate = '/scripts/shared/ui-grid/templates/dateCellTemplate.html';
            controller.grid.options = {
                data: [],
                enableSorting: true,
                exporterMenuCsv: false,
                enableGridMenu: true,
                showGridFooter: false,
                selectedItems: $scope.selectedUserId,
                enableFiltering: true,
                enableColumnResize: true,
                enableFullRowSelection: true,
                enableRowSelection: true,
                multiSelect: false,
                enableRowHeaderSelection: false,
                paginationPageSizes: [constants.datagrid.contacts.defaultRowsNumber, 50, 100],
                paginationPageSize: constants.datagrid.contacts.defaultRowsNumber,
                useExternalPagination: true,
                useExternalSorting: true,
                useExternalFiltering: true,
                rowTemplate: '/scripts/components/users/list/institutions/datagrid/rowTemplate.html',
                columnDefs: [
                    {
                        displayName: 'Email',
                        field: 'email',
                        visible: true, //GridService.getVisibilityByField(controller.columnsVisibility, 'email', true),
                        cellFilter: 'lowercase',
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'email', ''),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        displayName: 'Full Name',
                        field: 'fullname',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'fullname', true),
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'fullname', ''),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        displayName: 'Position',
                        field: 'position',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'position', true),
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'position', ''),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        displayName: 'Phone',
                        field: 'phone',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'phone', true),
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'phone', ''),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        displayName: 'Primary Institution Core Id',
                        field: 'primaryInstitutionCoreIdAsString',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'primaryInstitutionCoreIdAsString', true),
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'primaryInstitutionCoreIdAsString', ''),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        displayName: 'Primary Institution',
                        field: 'primaryInstitutionName',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'primaryInstitutionName', true),
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'primaryInstitutionName', ''),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        displayName: 'Contact Types',
                        filter: {
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: controller.contactTypesList,
                            term: GridService.getFilterByField(controller.paginationOptions, 'contactTypes.handle', null)
                        },
                        field: 'contactTypesAsString',
                        enableSorting: false,
                        maxWidth: 130,
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'contactTypes.handle', true),
                        filterHeaderTemplate: selectTemplate
                    },
                    {
                        displayName: 'Last Login',
                        field: 'lastLoginAt',
                        filterCellFiltered: true,
                        filters:[
                            {
                                visible: true,
                                condition: uiGridConstants.filter.GREATER_THAN
                            },
                            {
                                condition: uiGridConstants.filter.LESS_THAN
                            }
                        ],
                        filter : {
                            term: GridService.getFilterByField(controller.paginationOptions, 'lastLoginAt', ''),
                            applyTextFilter: controller.applyDateFilter('lastLoginAt', '')
                        },
                        cellTemplate: dateCellTemplate,
                        filterHeaderTemplate: '/scripts/components/users/list/institutions/datagrid/dateFilterHeaderTemplate.html',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'lastLoginAt', true)
                    },
                    {
                        displayName: 'Active',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'active', true),
                        field: 'active',
                        filter: {
                            column: 'active',
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: [
                                { value: true, label: 'Yes' },
                                { value: false, label: 'No' }
                            ],
                            term: GridService.getFilterByField(controller.paginationOptions, 'active')
                        },
                        maxWidth: 80,
                        filterHeaderTemplate: selectTemplate,
                        cellTemplate: selectCellTemplate
                    }
                ],
                onRegisterApi : function (gridApi) {
                    $scope.gridApi = gridApi;
                    gridApi.selection.on.rowSelectionChanged($scope, function (row) {
                        $scope.handleDatagridRowClick(row.entity);
                    });
                    gridApi.core.on.rowsRendered($scope, function () {
                        controller.isDatagridRendered = true;
                    });
                    gridApi.core.on.filterChanged($scope, controller.handleGridFiltersChanges);
                    gridApi.core.on.rowsVisibleChanged($scope, controller.handleGridVisibleChanges);

                    gridApi.pagination.on.paginationChanged($scope, function (newPage, limit) {
                        controller.defaultPaginationOptions.page = newPage;
                        controller.defaultPaginationOptions.limit = limit;
                        if(!filterChangesInProgress) { 
                            controller.getPage();
                        }
                    });
                    $scope.gridApi.core.on.sortChanged($scope, controller.handleGridFiltersChanges);
                }
            };
            // This is needed to add 'x' icon in date field when prepopulating dates
            var lastLogin = GridService.getFilterByField(controller.paginationOptions, 'lastLoginAt', '');
            if (lastLogin !== null && controller.paginationOptions["filter[lastLoginAt]"] !== null) {
                if (angular.isDefined(lastLogin) &&
                    angular.isDefined(controller.paginationOptions["filter[lastLoginAt]"]) &&
                    angular.isDefined(controller.paginationOptions["filter[lastLoginAt]"].startDate) &&
                    angular.isDefined(controller.paginationOptions["filter[lastLoginAt]"].endDate)
                ) {
                    controller.grid.options.columnDefs[7].filters[0].term = controller.paginationOptions["filter[lastLoginAt]"].startDate;
                    controller.grid.options.columnDefs[7].filters[1].term = controller.paginationOptions["filter[lastLoginAt]"].endDate;
                }
            }
        };

        /**
         * Populates input text field for date field
         *
         * @param {string} filterName
         * @param {mixed} defaultValue
         */
        controller.applyDateFilter = function (filterName, defaultValue) {
            var filter = GridService.getFilterByField(controller.paginationOptions, filterName, defaultValue);
            if (angular.isDefined(filter) &&
                filter !== null
            ) {
                if (angular.isDefined(filter.startDate) &&
                    angular.isDefined(filter.endDate)
                ) {
                    if (filter.startDate !== null &&
                        filter.startDate !== null
                    ) {
                        controller.defaultFilters.lastLoginAtRange = {
                            startDate: parseInt(filter.startDate, 10),
                            endDate: parseInt(filter.endDate, 10)
                        };
                    } else {
                        controller.defaultFilters.lastLoginAtRange = {
                            startDate: null,
                            endDate: null
                        };
                    }
                }
            }
        };

        controller.initDataGrid = function () {
            controller.getPage().then(function() {
                InstitutionsUsersListService.getContactTypesFilter(true).then(function (options) {
                    var list = [];
                    angular.forEach(options, function (option) {
                        list.push({
                            value: option.id,
                            label: option.title
                        });
                    });
                    controller.grid.options.columnDefs[6].filter.selectOptions = list;
                });
            });
        };

        controller.convertVisibleColumnsField = function (value) {
            switch (value) {
                case 'contactTypesAsString':
                    return 'contactTypes.handle';
                default:
                    return value;
            }
        };        

        controller.isValidFirstName = function () {
            return PasswordService.isValidFirstName($scope.forms.personalDetailsForm, $scope.user);
        };

        controller.isValidLastName = function () {
            return PasswordService.isValidLastName($scope.forms.personalDetailsForm, $scope.user);
        };

        controller.isValidEmail = function () {
            return PasswordService.isValidEmail($scope.forms.personalDetailsForm, $scope.user);
        };

        controller.setValid = function (fieldName) {
            return PasswordService.setValid($scope.forms.personalDetailsForm, fieldName);
        };

        controller.initWatches = function () {
            // listen to active institution changes
            WatchService.create($scope,
                InstitutionsUsersFactory.getRequestFilters,
                function (filters) {
                    if (filters) {
                        $scope.filters = angular.extend({}, controller.defaultFilters, filters);
                        // reset request filters
                        InstitutionsUsersFactory.resetRequestFilters();
                        // initialise datagrid
                        controller.getPage();
                    }
                }
            );

            // listen to active profile tab changes
            WatchService.create($scope, 'activeTab', function (newValue) {
                InstitutionsUsersListFactory.setSelectedTabId(newValue);
            });

            $scope.$on(constants.events.institutionsUserPrimaryInstitutionChanges, function () {
                $scope.userBeforeChanges = angular.copy($scope.user);
                controller.updateInDatagrid($scope.user);
            });
        };

        /**
         * Class constructor.
         */
        controller.$onInit = function () {
            controller.initDataGridOptions();
            controller.initWatches();
            controller.loadDatagridColumnsVisibility();
        };
    };

    angular
        .module('qsHub')
        .controller('InstitutionsUsersListCloneController', [
            '$scope',
            '$timeout',
            'constants',
            'uiGridConstants',
            'InstitutionsUsersListService',
            'UsersListService',
            'NotifierFactory',
            'InstitutionsUsersListFactory',
            'InstitutionsUsersFactory',
            'WatchService',
            'UserTypesService',
            'PasswordService',
            'UiGridService',
            'UserFactory',
            'TimeService',
            'DateRangeFixerService',
            'ModalService',
            App.controllers.institutionsUsersListClone
        ]);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services: {}});

    App.services.Password = function (
        $resource,
        $log,
        constants
    ) {
        var service = {};

        service.getPasswordModel = function () {
            return $resource(constants.api.usersPermissions.url + '/v1/password-reset/:requestId', null, {
                update:  { method: 'PATCH' }
            });
        };

        service.create = function (email) {
            var CreateModel = service.getPasswordModel();
            return CreateModel.save(
                {email: email}
            ).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return data;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        service.update = function (id, password) {
            var CreateModel = service.getPasswordModel();
            return CreateModel.update(
                {requestId: id},
                {password: password}
            ).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return data;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        service.getPasswordValidator = function () {
            return [
                {name: 'Minimum 8 characters', status: false}, // 0
                {name: 'Maximum 32 characters', status: false}, // 1
                {name: 'Lowercase letter (a-z)', status: false}, // 2
                {name: 'Uppercase letter (A-Z)', status: false}, // 3
                {name: 'Number (0-9)', status: false}, // 4
                {name: 'Special characters', status: false}, // 5
                {name: 'Both passwords match', status: false} // 6
            ];
        };

        service.validatePassword = function (password) {
            if (!angular.isDefined(password) || password === null) {
                return false;
            }
            var validationItems = service.getPasswordValidator().splice(
                0, service.getPasswordValidator().length - 1
            ), pattern = null;
            // 0
            if (password.length > 7
            ) {
                validationItems[0].status = true;
            }
            // 1
            if (password.length < 33 &&
                password.length > 0
            ) {
                validationItems[1].status = true;
            }
            // 2
            pattern = new RegExp(/(?=.*?[a-z])/);
            if (pattern.test(password)
            ) {
                validationItems[2].status = true;
            }
            // 3
            pattern = new RegExp(/(?=.*?[A-Z])/);
            if (pattern.test(password)
            ) {
                validationItems[3].status = true;
            }
            // 4
            pattern = new RegExp(/(?=.*?[0-9])/);
            if (pattern.test(password)
            ) {
                validationItems[4].status = true;
            }
            // 5
            pattern = new RegExp(/(?=.*?[\s\!\"\#\$\%\&\'\(\)\*\+\,\-\.\/\:\;<\=\>\?\@\[\\\]\^\_\`\{\|\}\~\£\¬\€])/);
            if (pattern.test(password)
            ) {
                validationItems[5].status = true;
            }

            return validationItems;
        };

        service.isPasswordValid = function (password) {
            if (angular.isUndefined(password) || password === null || password.length === 0) {
                return false;
            }
            var validationItems = service.validatePassword(password), invalidCount = 0;
            angular.forEach(validationItems, function (item) {
                if (!item.status) {
                    invalidCount++;
                }
            });

            return invalidCount === 0;
        };
        
        /**
         * Sets has errors flag.
         *
         * @param {boolean} hasErrors
         */
        service.setHasErrors = function (hasErrors) {
            service.hasErrors = hasErrors;
        };

        /**
         * Gets has errors flag.
         *
         * @returns {boolean|*}
         */
        service.getHasErrors = function () {
            return service.hasErrors;
        };

        function isNotEmpty (form, userObj, fieldName) {
            return !!(
                form &&
                (
                    form.$pristine ||
                    (form[fieldName] && form[fieldName].$pristine)
                ) ||
                (userObj && userObj[fieldName] && userObj[fieldName].trim().length)
            );
        }

        service.isValidFirstName = function (form, userObj, fieldName) {
            fieldName = fieldName || 'firstname';
            return isNotEmpty(form, userObj, fieldName);
        };

        service.isValidLastName = function (form, userObj, fieldName) {
            fieldName = fieldName || 'lastname';
            return isNotEmpty(form, userObj, fieldName);
        };

        service.isValidEmail = function (form, userObj) {
            return isNotEmpty(form, userObj, 'email');
        };

        service.isValidRequestInfoEmail = function (form, userObj,fieldName) {
            return isNotEmpty(form, userObj, fieldName);
        };

        service.isValid = function (form, userObj) {
            return service.isValidFirstName(form, userObj) &&
                service.isValidLastName(form, userObj) &&
                service.isValidEmail(form, userObj);
        };

        service.setValid = function (form, fieldName) {
            if (fieldName) {
                if (form && form[fieldName]) {
                    form[fieldName].$setPristine();
                }
            } else {
                form.$setPristine();
            }
        };

        return service;
    };

    angular
        .module('qsHub')
        .service('PasswordService', [
            '$resource',
            '$log',
            'constants',
            App.services.Password
        ]);

}(window.angular));

(function(angular, socketIo) {
    'use strict';

    angular
        .module('qsHub')
        .factory('WebSocketsService', [
            '$rootScope',
            '$q',
            '$localStorage',
            'constants',
            WebSockets
        ]);

    function WebSockets(
        $rootScope,
        $q,
        $localStorage,
        constants
    ) {
        var service = this,
            subscribeEvent = 'subscribe',
            successStatus = 'ok',
            hostname = constants.webSockets.host,
            socket;
        if (constants.webSockets.port && !isNaN(constants.webSockets.port)) {
            hostname += ':' + constants.webSockets.port ;
        }

        function getSocket() {
            if (!socket) {
                socket = socketIo.connect(hostname, {
                    query: {
                        token: $localStorage.currentUser.token
                    }
                });
            }

            return socket;
        }

        /**
         * Listen to event messages.
         *
         * @param {String} eventName
         * @param {Function|null} callback
         */
        function listen(eventName, callback) {
            getSocket().on(eventName, function (a) {
                if (callback) {
                    var args = arguments;
                    $rootScope.$apply(function () {
                        callback.apply(getSocket(), args);
                    });
                }
            });
        }

        /**
         * Send event message.
         *
         * @param {String} eventName
         * @param {mixed} data
         * @param {Function|null} callback
         */
        service.send = function (eventName, data, callback) {
            getSocket().emit(eventName, data, function () {
                if (callback && typeof callback === 'function') {
                    var args = arguments;
                    $rootScope.$apply(function () {
                        callback.apply(getSocket(), args);
                    });
                }
            });
        };

        /**
         * Subscribe to a room.
         *
         * @param {String} room
         * @param {String|null} eventName
         * @param {Function|null} callback
         */
        service.subscribe = function (room, eventName, callback, subscribeCallback) {
            service.send(subscribeEvent, room, function (subscribed) {
                if (subscribed === successStatus) {
                    if (eventName) {
                        listen(eventName, callback);
                    }
                    subscribed = true;
                } else if (typeof subscribeCallback === 'function') {
                    subscribed = false;
                }
                if (typeof subscribeCallback === 'function') {
                    subscribeCallback(subscribed);
                }
            });
        };

        service.addToQueue = function (queue, message, messageOptions, queueOptions) {
            var deferred = $q.defer();
            service.send('addToQueue', {
                queue: queue,
                message: message,
                messageOptions: messageOptions,
                queueOptions: queueOptions
            }, function (added) {
                deferred.resolve(added === successStatus);
            });

            return deferred.promise;
        };


        function addMessageToQueue(deferred, queue, messages, messageOptions, queueOptions, leftToProcess)
        {
            var message = messages[leftToProcess - 1];
            service.send('addToQueue', {
                queue: queue,
                message: message,
                messageOptions: messageOptions,
                queueOptions: queueOptions
            }, function () {
                leftToProcess--;
                deferred.notify(leftToProcess);
                if (leftToProcess === 0) {
                    deferred.resolve(true);
                } else {
                    addMessageToQueue(deferred, queue, messages, messageOptions, queueOptions, leftToProcess);
                }
            });
        }

        service.addManyToQueue = function (queue, messages, messageOptions, queueOptions) {
            var deferred = $q.defer();

            addMessageToQueue(deferred, queue, messages, messageOptions, queueOptions, messages.length);

            return deferred.promise;
        };

        service.setItem = function (key, value) {
            getSocket().emit('setItem', {
                key: key,
                value: value
            });
        };

        service.watchItem = function (item, callback) {
            getSocket().emit(subscribeEvent, item, function (status) {
                if (status === successStatus) {
                    getSocket().on('item', function (response) {
                        callback(response);
                    });
                    getSocket().emit('getItem', item);
                }
            });
        };

        service.unSubscribe = function (eventName) {
            socket.removeListener(eventName);
        };

        return service;
    }
}(window.angular, io));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers:{}});

    App.controllers.masterPassword = function (
        $interval,
        MasterPasswordService
    ) {
        var controller = this,
            timer;

        controller.masterPassword = {
            loaded: false, // master password was retrieved
            ttl: 0, // master password time to live
            enabled: false,
            success: false,
            expired: false,
            password: null,
            from: null,
            to: null,
            currentDate: new Date().getTime()
        };

        controller.timeLeftPercentage = function () {
            return controller.isExpired() ? 0 : Math.round(
                controller.secondsLeft() * 100 / controller.masterPassword.ttl
            );
        };

        controller.getTime = function () {
            return MasterPasswordService.getTime();
        };

        controller.secondsLeft = function () {
            return !controller.masterPassword.to ? null : controller.masterPassword.to - controller.getTime();
        };

        controller.isExpired = function () {
            return controller.secondsLeft() <= 0;
        };

        controller.getMasterPasswordCallback = function (response) {
            controller.masterPassword.loaded = true;
            if (
                response &&
                response.hasOwnProperty('password') &&
                response.hasOwnProperty('createdAt') &&
                response.hasOwnProperty('enabled') &&
                response.hasOwnProperty('ttl')
            ) {
                controller.masterPassword.password = atob(response.password);
                controller.masterPassword.enabled = response.enabled;
                controller.masterPassword.ttl = response.ttl * 1000;
                controller.masterPassword.from = new Date(response.createdAt);
                controller.masterPassword.to = new Date(response.createdAt + controller.masterPassword.ttl);
                controller.masterPassword.success = true;
                controller.initTimers();
            }
        };

        controller.updateTime = function () {
            if (controller.isExpired()) {
                $interval.cancel(timer);
            }
        };

        controller.initTimers = function () {
            if (!controller.isExpired()) {
                // force view digest for timers to get updated
                timer = $interval(controller.updateTime, 1000);
            }
        };

        controller.getMasterPassword = function () {
            // get master password data from API
            MasterPasswordService.get().then(controller.getMasterPasswordCallback);
        };

        controller.init = function () {
            controller.getMasterPassword();
        };

        controller.init();
    };

    angular
        .module('qsHub')
        .controller('MasterPasswordController', [
            '$interval',
            'MasterPasswordService',
            App.controllers.masterPassword
        ]);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services: {}});

    App.services.MasterPassword = function (
        $resource,
        $log,
        constants
    ) {
        var service = {};

        service.getMasterPasswordModel = function () {
            return $resource(constants.api.usersPermissions.url + '/v1/master-password');
        };

        service.getTime = function () {
            return new Date().getTime();
        };

        service.get = function () {
            return service.getMasterPasswordModel().get({
                'limit': 1
            }).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return data;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return [];
            });
        };

        return service;
    };

    angular
        .module('qsHub')
        .service('MasterPasswordService', [
            '$resource',
            '$log',
            'constants',
            App.services.MasterPassword
        ]);

}(window.angular));

(function(angular) {
    'use strict';

    var App = App || {};
    App = angular.extend({}, App, {directives:{}});

    App.directives.selectOnClick = function ($window) {
        return {
            restrict: 'A',
            link: function (scope, element) {
                element.on('click', function () {
                    if (!$window.getSelection().toString() && element[0].type === 'text') {
                        this.setSelectionRange(0, this.value.length);
                    }
                });
            }
        };
    };

    angular
        .module('qsHub')
        .directive('selectOnClick', ['$window', App.directives.selectOnClick]);

}(window.angular));

(function(angular) {
    'use strict';

    angular
        .module('qsHub.messages')
        .config(config, [
            '$stateProvider',
            'errors'
        ]);

    function config(
        $stateProvider,
        errors
    ) {
        $stateProvider
            .state('staff.iu.messages', {
                url: '/messages',
                templateUrl: '/scripts/modules/messages/components/qsUsersMessagesCenter/qsUsersMessagesCenterView.html',
                controller: 'Messages.QsUsersMessagesCenterController',
                controllerAs: 'QsUsersMessagesCenterController',
                data: {
                    pageTitle: 'Messages',
                    label: 'Messages',
                    name: 'Messages'
                },
                resolve: {
                    security: [
                        '$q' , 'UserFactory', 'AuthenticationService',
                        function ($q, UserFactory, AuthenticationService) {
                            UserFactory.setData(AuthenticationService.getUserData());
                            if (!UserFactory.hasData()) {
                                return $q.reject(errors[401]);
                            } else if (!UserFactory.hasIuAccess()) {
                                return $q.reject(errors[403]);
                            }
                        }
                    ]
                }
            }).state('clients.statistics.messages', {
                url: '/messages',
                 templateUrl: '/scripts/modules/messages/components/institutionUserMessagesCenter/institutionUserMessagesCenterView.html',
                 controller: 'Messages.InstitutionUsersMessagesCenterController',
                 controllerAs: 'InstitutionUsersMessagesCenterController',
                data: {
                    pageTitle: 'Messages',
                    label: 'Messages',
                    name: 'Messages'
                },
                resolve: {
                    security: [
                        '$q' , 'UserFactory', 'AuthenticationService',
                        function ($q, UserFactory, AuthenticationService) {
                            UserFactory.setData(AuthenticationService.getUserData());
                            if (!UserFactory.hasData()) {
                                return $q.reject(errors[401]);
                            } else if (!UserFactory.hasStatisticsAccess()) {
                                return $q.reject(errors[403]);
                            }
                        }
                    ]
                }
            });
    }

}(window.angular));

(function(angular) {
    'use strict';

    var gridNamespaces = {
        filters: {
            institutionUsersMessagesCenter : ' institutionUsersMessagesCenterFilters',
        },
        visibility: {
            institutionUsersMessagesCenter : 'institutionUsersMessagesCenterdVisibility',
        }
    };
    var acceptedFiles = [
        'application/pdf',
        'application/x-pdf',
        'application/doc',
        'application/docx',
        'application/xlsx',
        'application/xls',
        'application/msword',
        'application/vnd.ms-excel',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.oasis.opendocument.text',
        'application/vnd.oasis.opendocument.presentation',
        'application/vnd.oasis.opendocument.spreadsheet',
        'application/pptx',
        'application/ppt',
        'application/odt',
        'application/odp',
        'application/ods',
        'application/txt',
        'application/csv',
        'text/csv',
        'text/plain',
        'image/jpg',
        'image/jpeg',
        'image/pjpeg',
        'image/gif',
        'image/png'
    ], acceptedFilesExtensions = [
        'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx',
        'odt', 'odp', 'ods',
        'pdf', 'txt', 'csv',
        'jpg', 'jpeg', 'gif', 'png'
    ];

    angular
        .module('qsHub.messages')
        .constant('messages.gridNamespaces', gridNamespaces)
        .constant('messages.acceptedFiles', acceptedFiles)
        .constant('messages.acceptedFilesExtensions', acceptedFilesExtensions);

}(window.angular));

(function (angular) {
    'use strict';

    angular.module('qsHub.messages').service('MessagesService', [
        '$resource',
        'constants',
        'UserFactory',
        'RequestsQueueService',
        MessagesService
    ]);

    function MessagesService($resource, constants, UserFactory, RequestsQueueService) {
        var svc = {};

        svc.getMessage = getMessage;
        svc.getMessages = getMessages;
        svc.deleteMessage = deleteMessage;
        svc.sendMessage = sendMessage;
        svc.markAsRead = markAsRead;
        svc.reply = reply;
        svc.shareMessage = shareMessage;
        svc.shareReply = shareReply;
        svc.isDeletable = isDeletable;
        svc.isUnread = isUnread;
        svc.isNotSameUserType = isNotSameUserType;

        function getMessage(messageId) {
            return getMessagesModel().getMessage({messageId: messageId}).$promise.then(function (data) {
                return data;
            }, function (error) {
                return error;
            });
        }

        function getMessagesModel() {
            return $resource(constants.api.messages.url, {messageId: '@messageId'}, {
                getMessage: {method: 'GET', url: constants.api.messages.url + '/v1/message/:messageId'},
                get: {
                    isArray: false,
                    cancellable: true,
                    url: constants.api.messages.url + '/v1/list'
                },
                create: {method: 'POST', url: constants.api.messages.url + '/v1/message'},
                delete: {method: 'DELETE', url: constants.api.messages.url + '/v1/message/:messageId'},
                markAsRead: {method: 'GET', url: constants.api.messages.url + '/v1/message/:messageId/read'},
                reply: {method: 'POST', url: constants.api.messages.url + '/v1/message/:messageId/reply'},
                shareMessage: {method: 'POST', url: constants.api.messages.url + '/v1/message/:messageId/share'},
                shareReply: {
                    method: 'POST',
                    url: constants.api.messages.url + '/v1/message/:messageId/reply/:replyId/share'
                }
            });
        }

        function getMessages(filter) {
            var key = 'MessagesService:getMessages',
                Api;
            RequestsQueueService.cancelAll(key);
            Api = getMessagesModel().get(filter);
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                return data;
            }, function () {
                return false;
            });
        }

        function deleteMessage(messageId) {
            return getMessagesModel().delete({messageId: messageId}).$promise.then(function () {
                return true;
            }, function (error) {
                return error;
            });
        }

        function sendMessage(messageObject) {
            return getMessagesModel().create(messageObject).$promise.then(function (response) {
                return response && response.error && response.message ? response.message : true;
            }, function () {
                return false;
            });
        }

        function markAsRead(messageId) {
            return getMessagesModel().markAsRead({messageId: messageId}).$promise.then(function () {
                return true;
            }, function (error) {
                return error;
            });
        }

        function reply(messageId, messageObject) {
            return getMessagesModel().reply({messageId: messageId}, messageObject).$promise.then(function (response) {
                return response && response.error && response.message ? response.message : true;
            }, function (error) {
                return error;
            });
        }

        function shareMessage(messageId, usersArray) {
            return getMessagesModel().shareMessage({messageId: messageId}, usersArray).$promise.then(function (data) {
                return data && data.error && data.message ? data.message : data;
            }, function (error) {
                return error;
            });
        }

        function shareReply(messageId, replyId, usersArray) {
            return getMessagesModel().shareReply({
                messageId: messageId,
                replyId: replyId
            }, usersArray).$promise.then(function (data) {
                return data && data.error && data.message ? data.message : data;
            }, function (error) {
                return error;
            });
        }

        function isDeletable(message) {
            return message.createdBy === UserFactory.getCoreId() && !message.replies && message.unread;
        }

        function isUnread(message) {
            return message.replies === null ?
                message.unread &&
                message.createdBy !== UserFactory.getCoreId() &&
                svc.isNotSameUserType(message) :
                message.replies[0].unread &&
                message.replies[0].createdBy !== UserFactory.getCoreId() &&
                svc.isNotSameUserType(message.replies[0]);
        }

        function isNotSameUserType(message) {
            return (message.client !== UserFactory.getData().isClient);
        }

        svc.getQuestionName = function (value, list) {
            var total = list.length,
                i = 0;
            for (i; i < total; i++) {
                if (list[i].value === value) {

                    return list[i].label;
                }
            }

            return null;
        };

        svc.getRankingName = function (value, list) {
            var total = list.length,
                i = 0;
            for (i; i < total; i++) {
                if (list[i].value === value) {

                    return list[i].label;
                }
            }

            return null;
        };

        return svc;
    }

}(window.angular));

(function (angular) {
    'use strict';

    angular.module('qsHub.messages').component('statisticsMessages', {
        templateUrl: '/scripts/modules/messages/components/statisticsMessages/statisticsMessagesComponentView.html',
        controller: 'Messages.StatisticsMessagesController'
    });

}(window.angular));

(function (angular) {
    'use strict';

    angular
        .module('qsHub.messages')
        .controller('Messages.StatisticsMessagesController', [
            '$scope',
            'MessagesService',
            'UserFactory',
            '$state',
            '$stateParams',
            'WatchService',
            'StatisticsDashboardFactory',
            'StatisticsSubmissionsFactory',
            'InstitutionFactory',
            'TimeService',
            'NotifierFactory',
            'StatisticsRightPanelFactory',
            'RankingsService',
            'ModalService',
            StatisticsMessages
        ]);

    function StatisticsMessages($scope,
                                MessagesService,
                                UserFactory,
                                $state,
                                $stateParams,
                                WatchService,
                                StatisticsDashboardFactory,
                                StatisticsSubmissionsFactory,
                                InstitutionFactory,
                                TimeService,
                                NotifierFactory,
                                StatisticsRightPanelFactory,
                                RankingsService,
                                ModalService) {

        var vm = this,
            defaultState = 'listMessages',
            watchesInitialized = false,
            messagesReload = false,
            logsToDisplayByDefault = 10,
            logsToDisplayIncrement = 5;

        vm.logs = [];
        vm.logsToDisplay = angular.copy(logsToDisplayByDefault);

        vm.goToNewMessage = goToNewMessage;
        vm.handleLoadMoreHistoryLogs = handleLoadMoreHistoryLogs;
        vm.deleteMessage = deleteMessage;
        vm.expandMessage = expandMessage;
        vm.isUnread = isUnread;
        vm.handleMessageSent = handleMessageSent;
        vm.isDeleteAllowed = isDeleteAllowed;
        vm.markAsRead = markAsRead;
        vm.canSendMessage = canSendMessage;
        vm.reply = reply;
        vm.showMessagesCenter = showMessagesCenter;
        vm.showVariationLogMessages = showVariationLogMessages;
        vm.isMoreLogsAvailable = isMoreLogsAvailable;

        function init() {
            vm.loading = true;
            vm.state = angular.copy(defaultState);
            WatchService.create($scope, StatisticsRightPanelFactory.isMessagesTabActive, messagesTabWatch);
        }

        function coreIdWatch(coreId) {
            if (!coreId) {
                return false;
            }

            getMessages();
        }

        function questionHandleWatch(handle) {
            if (!handle) {
                return false;
            }
            vm.messageId = typeof $stateParams.messageId === 'undefined' ? null : $stateParams.messageId;
            vm.replyId = typeof $stateParams.replyId === 'undefined' ? null : $stateParams.replyId;
            reloadMessages();
        }

        function messageSentWatch(isSent) {
            if(isSent) {
                MessagesService.sendMessage(StatisticsSubmissionsFactory.getVariationLogMessage());
                reloadMessages();
                StatisticsSubmissionsFactory.setMessageSent(false);
            }

        }

        function messagesTabWatch (active) {
            if (!active) {
                return false;
            }
            initWatches();

            if (!vm.messages || messagesReload) {
                getMessages();
            }
        }

        function initWatches() {
            if (watchesInitialized) {
                return false;
            }
            WatchService.create($scope, InstitutionFactory.getCoreId, coreIdWatch);
            WatchService.create($scope, StatisticsDashboardFactory.getActiveQuestionHandle, questionHandleWatch);
            WatchService.create($scope, StatisticsSubmissionsFactory.isMessageSent, messageSentWatch);
            watchesInitialized = true;
        }

        function highlightMessage() {
            if (vm.messages && vm.messageId) {
                var i = 0,
                    total = vm.messages.length,
                    found = false;
                for (i; i < total; i++) {
                    if (vm.messages[i].id === vm.messageId) {
                        found = true;
                        expandMessage(vm.messages[i], false);
                        break;
                    }
                }
                if (isMoreLogsAvailable() && (!found  || (found && i > vm.logsToDisplay))) {
                    loadMore();
                }
            }
        }

        function getMessages() {
            if (!StatisticsDashboardFactory.getActiveQuestionHandle() || !InstitutionFactory.getCoreId()) {
                return false;
            }
            if (!StatisticsRightPanelFactory.isMessagesTabActive()) {
                messagesReload = true;
                return false;
            }
            messagesReload = false;
            vm.loading = true;

            return MessagesService.getMessages(getFilters()).then(function (data) {
                if (data === false) {
                    return false;
                }
                vm.messages = isSendDateToday(data.results);
                vm.loading = false;
                vm.logs = data;
                highlightMessage();
            });
        }

        function getFilters()
        {
            return {
                limit : 50,
                page: 1,
                'filter[source.question]' : '=' + StatisticsDashboardFactory.getActiveQuestionHandle(),
                'sorting[modifiedAt]': 'desc',
                'filter[institutionCoreId]' : InstitutionFactory.getCoreId()
            };
        }

        function goToNewMessage() {
            vm.state = 'newMessage';
        }

        function isMoreLogsAvailable() {
            if (vm.logs && vm.logs.totalFiltered) {
                return vm.logsToDisplay < vm.logs.totalFiltered;
            }
            return false;
        }

        function handleLoadMoreHistoryLogs() {
            vm.logsToDisplay += logsToDisplayIncrement;
        }

        function loadMore() {
            handleLoadMoreHistoryLogs();
            highlightMessage();
        }

        function deleteMessage(message) {
            if (!vm.isDeleteAllowed(message)) {
                return;
            }
            var modalOptions = {
                closeButtonText: 'Cancel',
                actionButtonText: 'Yes',
                actionButtonClass: 'danger',
                headerText: 'Message Deletion',
                bodyText: 'Are you sure you wish to delete message?'
            };
            ModalService.show({}, modalOptions).then(function () {
                MessagesService.deleteMessage(message.id).then(function (result) {
                    if (result === true) {
                        NotifierFactory.show('success', 'Message Deleted Successfully');
                        reloadMessages();
                    } else {
                        NotifierFactory.show('error', 'Failed to delete message', 'Error');
                    }
                });
            });
        }

        function expandMessage(clickedMessage, reset) {
            reset = typeof reset === 'undefined' ? true : false;
            if (reset && vm.messageId) {
                delete $stateParams.messageId;
                vm.messageId = null;
                delete $stateParams.replyId;
                vm.replyId = null;
                $state.transitionTo($state.current, $stateParams, {notify: false});
            }

            vm.messages.forEach(function (message) {
                if (clickedMessage.id === message.id) {
                    message.open = !message.open;
                    if (message.open && MessagesService.isUnread(message)) {
                        markAsRead(clickedMessage);
                    }
                    if (message.source && message.source.question) {
                        RankingsService.getQuestionName(message.source.question).then(function (name) {
                            message.question = name;
                        });
                    }
                } else {
                    message.open = false;
                }
            });
        }

        function isUnread(message) {
            return MessagesService.isUnread(message);
        }

        function handleMessageSent() {
            var questionHandle = StatisticsDashboardFactory.getActiveQuestionHandle();
            if (questionHandle === 'qs_employment_rate_auto' ||
                questionHandle === 'employers_connection_on_campus' ||
                questionHandle === 'employer_partnerships_question' ||
                questionHandle === 'faculty_staff' ||
                questionHandle === 'overall_international_students' ||
                questionHandle === 'international_faculty_staff' ||
                questionHandle === 'staff_with_phd' ||
                questionHandle === 'exchange_students_inbound' ||
                questionHandle === 'exchange_students_outbound' ||

                questionHandle === 'undergraduate_exchange_students_inbound' ||
                questionHandle === 'undergraduate_exchange_students_outbound' ||
                questionHandle === 'graduate_postgraduate_exchange_students_inbound' ||
                questionHandle === 'graduate_postgraduate_exchange_students_outbound' ||

                questionHandle === 'undergraduate_students' ||
                questionHandle === 'graduate_postgraduate_students' ||
                questionHandle === 'overall_students') {
                StatisticsSubmissionsFactory.setExplainationProvidedQuestion(StatisticsDashboardFactory.getActiveQuestionHandle());
            }
            reloadMessages();
        }

        function reloadMessages() {
            resetDefaults();
            getMessages();
        }

        function resetDefaults() {
            vm.logsToDisplay = angular.copy(logsToDisplayByDefault);
            vm.state = angular.copy(defaultState);
            vm.replyMessage = null;
        }

        function markAsRead(message) {
            MessagesService.markAsRead(message.id).then(function (result) {
                if (result === true) {
                    if (message.replies !== null &&
                        message.replies.length > 0
                    ) {
                        message.replies[0].unread = false;
                    } else {
                        message.unread = false;
                    }
                }
            });
        }

        function reply(message) {
            vm.state = 'reply';
            vm.replyMessage = message;
        }

        function canSendMessage() {
            return UserFactory.isClient();
        }

        function showMessagesCenter() {
            return !UserFactory.isClient();
        }

        function showVariationLogMessages(subject) {
            if(UserFactory.isClient() && subject === 'Variation log') {
                return false;
            }
            else {
                return true;
            }
        }

        function isDeleteAllowed(message) {
            return MessagesService.isDeletable(message);
        }

        function markIfMessageDateToday(message) {
            if (TimeService.isToday(parseInt(message.createdAt/1000))) {
                message.today = true;
            }
        }

        function isSendDateToday(data) {
            if (data) {
                data.forEach(function (message) {
                    markIfMessageDateToday(message);
                    if (message.replies) {
                        message.replies.forEach(function (reply) {
                            markIfMessageDateToday(reply);
                        });
                    }
                });
            }

            return data;
        }

        vm.$onInit = init;
    }

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { factories: {} });

    App.factories.statisticsMessages = function() {
        var isFirstLoad = true;

        return {
            setIsFirstLoad: function (value) {
                isFirstLoad = value;
            },
            getIsFirstLoad: function () {
                return isFirstLoad;
            }
        };
    };

    angular
        .module('qsHub.messages')
        .factory('StatisticsMessagesFactory', [
            App.factories.statisticsMessages
        ]);

}(window.angular));

(function (angular) {

  'use strict';

  angular.module('qsHub.messages').component('sendMessage', {
      templateUrl: '/scripts/modules/messages/components/sendMessage/sendMessageView.html',
      bindings: {
          state: '=',
          messageSentHandler: '&',
          replyMessage: '=',
          showReplies: '='
      },
      bindToController: true,
      controller: 'Messages.SendMessageController'
  });

}(window.angular));




(function (angular) {
    "use strict";

    var singleMessage = {
        bindings: {
            message: "=",
            messageSentHandler: "&",
            messageDeletedHandler: "&",
            state: "=",
            type: "@",
            repliesLimit: '='
        },
        bindToController: true,
        templateUrl: '/scripts/modules/messages/components/singleMessage/singleMessageComponentView.html',
        controller: 'Messages.SingleMessageController'
    };

    angular.module('qsHub.messages').component('singleMessage', singleMessage);

}(window.angular));

(function (angular) {
    "use strict";

    angular.module('qsHub.messages')
        .controller('Messages.SingleMessageController', [
            '$scope',
            'WatchService',
            'UserFactory',
            'MessagesService',
            'NotifierFactory',
            'QsUsersListService',
            '$state',
            '$location',
            'RankingsService',
            'ModalService',
            SingleMessageController
        ]);

    function SingleMessageController($scope,
                                     WatchService,
                                     UserFactory,
                                     MessagesService,
                                     NotifierFactory,
                                     QsUsersListService,
                                     $state,
                                     $location,
                                     RankingsService,
                                     ModalService) {
        var vm = this;

        vm.isDeleteDisabled = isDeleteDisabled;
        vm.deleteMessage = deleteMessage;
        vm.goToSubmissionsPage = goToSubmissionsPage;
        vm.loadMoreReplies = loadMoreReplies;

        var limit = 4;
        var lastMessageId = null;
        vm.repliesLimit = vm.repliesLimit || limit;
        vm.repliesPerPage = 5;

        function init() {
            WatchService.create($scope, '$ctrl.message', getLatestMessage);

            if (!UserFactory.isClient()) {
                var filters = {
                    limit: 10000,
                    'sorting[email]': 'asc',
                    'columns[]': 'email',
                    'filter[accessTo]': 'admin_statistics.statistics'
                };
                QsUsersListService.getAllUsers(filters).then(function (result) {
                    var qsUsers = [];
                    if (result && result.results && result.results.length) {
                        result.results.forEach(function (user) {
                            qsUsers.push({email: user.email});
                        });
                    }
                    vm.qsUserOptions = qsUsers;
                });
            }
        }

        function getLatestMessage() {
            if (!vm.message) {
                return false;
            }

            if (lastMessageId !== vm.message.id) {
                //new message opened reset limit
                vm.repliesLimit = limit;
            }

            if (vm.message.replies) {
                vm.latestMessage = vm.message.replies[0];
                vm.messageIsReply = true;
            } else {
                vm.latestMessage = vm.message;
                vm.messageIsReply = false;
            }

            if (vm.message.source && vm.message.source.question) {
                RankingsService.getQuestionName(vm.message.source.question).then(function (name) {
                    vm.message.question = name;
                });
            }

            lastMessageId = angular.copy(vm.message.id);
        }

        function loadMoreReplies() {
            vm.repliesLimit += vm.repliesPerPage;
        }

        function deleteMessage(messageId) {
            var modalOptions = {
                closeButtonText: 'Cancel',
                actionButtonText: 'Yes',
                actionButtonClass: 'danger',
                headerText: 'Message Deletion',
                bodyText: 'Are you sure you wish to delete message?'
            };
            ModalService.show({}, modalOptions).then(function () {
                MessagesService.deleteMessage(messageId).then(function (result) {
                    if (result === true) {
                        NotifierFactory.show('success', 'Message Deleted Successfully');
                        if (vm.messageDeletedHandler) {
                            vm.messageDeletedHandler();
                        }
                        vm.message = null;
                    } else {
                        NotifierFactory.show('error', result || 'Failed to delete message', 'Error');
                    }
                });
            });
        }

        function isDeleteDisabled() {
            return !MessagesService.isDeletable(vm.message);
        }

        function goToSubmissionsPage(message) {
            var rankingHandle = message.source.ranking === 'ovs' ? 'all' : message.source.ranking;
            var path = 'clients.statistics.data-submission.' + rankingHandle;
            if (message.source.ranking === 'ger') {
                switch (message.source.question) {
                    case 'employer_partnerships_question':
                        path = 'clients.statistics.data-submission.ger.employers-partnerships';
                        break;
                     case 'number_of_students':
                     case 'number_of_students_auto':
                     case 'qs_employment_rate_auto':
                     case 'evidence_when_shared':
                     case 'evidence_which_year_conducted':
                     case 'evidence_link_or_screenshot':
                     case 'number_of_students_entrepreneurship':
                     case 'number_of_students_entrepreneurship_auto':
                        path = 'clients.statistics.data-submission.ger.employment-rate';
                        break;  
                    case 'employers_connection_online':
                    case 'employers_connection_on_campus':
                        path = 'clients.statistics.data-submission.ger.employers-connections';
                        break;
                }
            }
            $location.path($state.href(path).replace('#', '')).search({
                coreId: message.institutionCoreId,
                messageId: message.id,
                question: message.source.question
            });
            $location.replace();
        }

        vm.$onInit = init;

    }

})(window.angular);

(function (angular) {
    "use strict";

    angular
        .module('qsHub.messages')
        .controller('Messages.SendMessageController', [
            '$scope',
            'MessagesService',
            'NotifierFactory',
            '$state',
            '$location',
            '$timeout',
            'constants',
            'messages.acceptedFiles',
            'messages.acceptedFilesExtensions',
            'UserFactory',
            'ModalService',
            'InstitutionFactory',
            'WatchService',
            'StatisticsDashboardFactory',
            'AuthenticationService',
            'TextService',
            '$filter',
            '$templateCache',
            SendMessage
        ]);

    function SendMessage($scope,
                         MessagesService,
                         NotifierFactory,
                         $state,
                         $location,
                         $timeout,
                         constants,
                         acceptedFiles,
                         acceptedFilesExtensions,
                         UserFactory,
                         ModalService,
                         InstitutionFactory,
                         WatchService,
                         StatisticsDashboardFactory,
                         AuthenticationService,
                         TextService,
                         $filter,
                         $templateCache) {
        var vm = this,
            defaultSubject = 'Submission note',
            defaultMessage = {
                subject: defaultSubject,
                message: '',
                source: {
                    ranking: $state.$current.data.rankings,
                    question: $location.search().question
                }
            };

        vm.forms = {};
        vm.uploadEnabled = true;
        vm.displayRemoveLink = false;
        vm.dropZoneInstance = null;
        vm.sendNewMessage = sendNewMessage;
        vm.cancelNewMessage = cancelNewMessage;
        vm.isInvalidMessage = isInvalidMessage;
        vm.isInvalidSubject = isInvalidSubject;
        vm.resetValidation = resetValidation;
        vm.userFullName = UserFactory.getFullName();
        vm.acceptedFiles = acceptedFiles.join(',');
        vm.handleClipboardPaste = handleClipboardPaste;
        vm.messageWords = 0;
        vm.maxWords = 100;
        vm.validationMessageWordLimit =
            'Word count limit of ' +
            vm.maxWords +
            ' is exceeded.';
        vm.uploadConfig = {
            // http://www.dropzonejs.com/#configuration-options
            dropzone: {
                url: constants.api.messages.url + '/v1/message-upload',
                maxFiles: 1,
                maxFilesize: 10,
                uploadMultiple: false,
                parallelUploads: 1,
                autoProcessQueue: true,
                addRemoveLinks: false,
                acceptedFiles: vm.acceptedFiles,
                headers: AuthenticationService.getAuthorizationHeader(),
                thumbnailWidth: 200,
                thumbnailHeight: 200,
                paramName: "file",
                previewTemplate: $templateCache.get('/scripts/modules/rankings/components/rankingsInformation/editRankingsInfo/rankingInfoForm/previewTemplateView.html'),
                init: function(){
                    this.on("addedfile", function (file) { 
                        $timeout(function() {
                            vm.uploadEnabled = false;
                            vm.uploadInProgress = true;
                            });
                         });
                    }
            },
            // http://www.dropzonejs.com/#event-list
            eventHandlers: {
                success: handleFileUploaded,
                error: handleUploadError,
                removedfile: handleRemovedFile
            }
        };

        function handleUploadError(file) {
            vm.uploadInProgress = false;
            if (!file) {
                var errorMessage = errorMessage ? errorMessage.message : 'File upload failed!';
                vm.removeDropZoneUploadedFile();
                NotifierFactory.show(
                    'error',
                    errorMessage,
                    'File upload'
                );
                return;
            }
            file.rejectExtension = function () {
                NotifierFactory.show(
                    'error',
                    'Allowed types: ' + acceptedFilesExtensions.join(','),
                    'File type is unacceptable.'
                );
                vm.removeDropZoneUploadedFile();
                handleRemovedFile();
                $scope.$apply();
            };
            file.rejectSize = function (globalAccept) {
                globalAccept = (typeof globalAccept === 'undefined') ? true : globalAccept;
                if (!globalAccept) {
                    NotifierFactory.show(
                        'error',
                        'Please upload less than 10MB.',
                        'Uploaded File is greater than the limit.'
                    );
                    vm.removeDropZoneUploadedFile();
                    handleRemovedFile();
                    $scope.$apply();
                }
            };
            if (vm.dropZoneInstance.files.length === 1) {
                vm.dropZoneInstance.globalAccept = false;
                vm.dropZoneInstance.localAccept = false;
            }
            if (!vm.dropZoneInstance.globalAccept && !vm.dropZoneInstance.localAccept && vm.dropZoneInstance.files.length === 1) {
                vm.dropZoneInstance.isValid = true;
                vm.dropZoneInstance.localAccept = true;
            }
            if (vm.dropZoneInstance.files.length > 1 && vm.dropZoneInstance.globalAccept) {
                vm.dropZoneInstance.isValid = false;
                vm.dropZoneInstance.globalAccept = true;
                vm.dropZoneInstance.removeFile(vm.dropZoneInstance.files[1]);
                $scope.$apply();
            }
            if (file.size > 10485760) {
                vm.dropZoneInstance.isValid = false;
                file.rejectSize(vm.dropZoneInstance.globalAccept);
            }
            var allowedExtensionsArray = vm.acceptedFiles.split(',');
            if (allowedExtensionsArray.indexOf(file.type) === -1) {
                vm.dropZoneInstance.isValid = false;
                file.rejectExtension();
            }
            if (vm.dropZoneInstance.isValid && file.status === 'error') {
                vm.removeDropZoneUploadedFile();
                NotifierFactory.show(
                    'error',
                    'File upload failed!',
                    'File upload'
                );
                return;
            }
            if (vm.dropZoneInstance.isValid) {
                // hide upload area
                vm.uploadEnabled = false;
                $scope.$apply();
            }
        }

        function handleFileUploaded(file, response) {
            vm.uploadInProgress = false;
            $scope.$apply(function () {
                if (!response || !response.status) {
                    handleUploadError();
                } else {
                    NotifierFactory.show('success', 'File uploaded successfully');
                    vm.imageUrl = response.path;
                    vm.displayRemoveLink = true;
                    vm.uploadEnabled = false;
                }
            });
        }

        function handleRemovedFile() {
            vm.uploadInProgress = false;
            $timeout(function () {
                vm.uploadEnabled = true;
                vm.displayRemoveLink = false;
                vm.imageUrl=null;
            });
        }

        /**
         * Remove Dropzone files
         */
        vm.removeDropZoneUploadedFile = function () {
            if (!vm.dropZoneInstance) {
                return false;
            }
            if (vm.dropZoneInstance.files && vm.dropZoneInstance.files.length > 0) {
                vm.dropZoneInstance.removeFile(vm.dropZoneInstance.files[0]);
            } else {
                vm.dropZoneInstance.removeAllFiles();
            }
        };


        function isInvalidMessage() {
            return !!(vm.submitted && !TextService.countWords(vm.newMessage.message));
        }

        vm.isMessageCountInvalid = function () {
            return TextService.countWords(vm.newMessage.message) > vm.maxWords;
        };

        function isInvalidSubject() {
            return !!(vm.submitted && (!vm.newMessage.subject || !vm.newMessage.subject.length));
        }

        function resetValidation() {
            vm.submitted = false;
        }

        function init() {
            vm.newMessage = angular.copy(defaultMessage);
            vm.textEditorBasicOptions = {
                disableDragAndDrop: true,
                placeholder: 'Add message',
                styleWithSpan: false,
                dialogsInBody: true,
                minHeight: 180,
                maxHeight: 280,
                toolbar: [
                    ['style', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript']],
                    ['insert', ['link', 'unlink']],
                    ['alignment', ['ul', 'ol']]
                ],
                popover: {
                    link: []
                }
            };
            initWatches();
        }

        function initWatches() {
            WatchService.create($scope, InstitutionFactory.getCoreId, coreIdWatch);
            WatchService.create($scope, StatisticsDashboardFactory.getActiveQuestionHandle, questionHandleUpdate);
        }

        function coreIdWatch(coreId) {
            vm.newMessage.institutionCoreId = defaultMessage.institutionCoreId = coreId;
            vm.newMessage.institutionName = defaultMessage.institutionName = InstitutionFactory.getDisplayName();
        }

        function questionHandleUpdate(questionHandle) {
            defaultMessage.source.question = vm.newMessage.source.question = questionHandle;
        }

        /**
         * Count words in the string.
         *
         * @param {string} text
         */
        vm.countWords = function (text) {
            return TextService.countWords(text);
        };

        function sendNewMessage() {
            vm.submitted = true;
            if (vm.newMessageForm.$valid && !!TextService.countWords(vm.newMessage.message)) {
                /**
                 * Validation 1:
                 * Number of words in message is exceeding the limit of messageWordsLimit
                 */
                if (vm.isMessageCountInvalid()) {
                    NotifierFactory.show(
                        'error',
                        vm.validationMessageWordLimit,
                        'Saving failed!'
                    );

                    return false;
                }
                vm.sendingMessage = true;
                vm.newMessage.attachment = vm.imageUrl;

                if (vm.state === 'reply') {
                    MessagesService.reply(vm.replyMessage.id, vm.newMessage).then(handleSendMessage);
                } else {
                    MessagesService.sendMessage(vm.newMessage).then(handleSendMessage);
                }
            }
        }
        /**
         * Clean Tags from new message input
         */
        function handleClipboardPaste() {
            $timeout(function () {
                vm.newMessage.message = $filter('htmlToPlaintext')(TextService.clearAttributes(vm.newMessage.message));
            }, 50);
        }

        function handleSendMessage(result) {
            if (result === true) {
                vm.removeDropZoneUploadedFile();
                vm.newMessage = angular.copy(defaultMessage);
                NotifierFactory.show('success', 'Message Sent Successfully');
                if (vm.messageSentHandler) {
                    vm.messageSentHandler();
                }
                vm.state = 'listMessages';
            } else {
                NotifierFactory.show('error', result || 'Message failed to send', 'Error');
                vm.state = 'listMessages';
            }
            vm.submitted = false;
            vm.sendingMessage = false;
        }

        function cancelNewMessage() {
            if (vm.newMessage.message || vm.newMessage.subject !== defaultSubject) {
                var modalOptions = {
                    closeButtonText: 'Cancel',
                    actionButtonText: 'Ok',
                    headerText: 'Discard message',
                    bodyText: 'All changes will be discarded'
                };
                ModalService.show({}, modalOptions).then(function () {
                    vm.state = 'listMessages';
                    vm.newMessage = angular.copy(defaultMessage);
                    vm.resetValidation();
                });
            } else {
                vm.state = 'listMessages';
                vm.newMessage = angular.copy(defaultMessage);
                vm.resetValidation();
            }
            vm.dropZoneInstance.removeAllFiles();
        }

        vm.$onInit = init;
    }

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.rankings')
        .controller('Messages.QsUsersMessagesCenterController', [
            '$scope',
            '$filter',
            'constants',
            '$timeout',
            'uiGridConstants',
            'uiGridExporterConstants',
            'uiGridExporterService',
            'TimeService',
            'ProgressReportByRankingService',
            'QsUsersMessagesCenterService',
            'MessagesService',
            'UiGridService',
            'WatchService',
            'DateRangeFixerService',
            'RankingsService',
            QsUsersMessagesCenter
        ]);

    function QsUsersMessagesCenter($scope,
                                   $filter,
                                   constants,
                                   $timeout,
                                   uiGridConstants,
                                   uiGridExporterConstants,
                                   uiGridExporterService,
                                   TimeService,
                                   ProgressReportByRankingService,
                                   QsUsersMessagesCenterService,
                                   MessagesService,
                                   GridService,
                                   WatchService,
                                   DateRangeFixerService,
                                   RankingsService) {
        var controller = this;
        var lastCreatedAtDateFilter = null;
        var selectValues = {},
            lastSelectValues = {},
            filterChangesInProgress = false;
        var filterTimeoutMilliseconds = constants.datagridInputDelay;
        controller.initialLoaded = false;
        controller.loading = false;

        controller.isDatagridReloading = false;
        controller.isRendered = false;
        controller.gridApi = null;
        controller.columnsVisibility = GridService.getColumnsVisibility(
            constants.gridNameSpaces.qsUserMessagesCenterVisibility
        );
        controller.defaultPaginationOptions = {
            page: 1,
            limit: 25
        };
        controller.defaultFilters = {
            createdAtRange: {
                startDate: null,
                endDate: null
            }
        };
        controller.paginationOptions = GridService.getColumnsFilters(
            constants.gridNameSpaces.qsUserMessagesCenterFilters,
            ['createdAtRange']
        );
        controller.createdAtField = 'createdAt';

        controller.gridOptions = {
            data: []
        };
        controller.rankingsList = [];
        controller.questionsList = [];
        controller.state = 'listMessages';
        controller.repliesLimit = null;

        controller.isLoading = function () {
            return !controller.rankingsList.length || !controller.questionsList.length || !controller.isRendered || !controller.initialLoaded || controller.loading;
        };

        controller.isDatagridVisible = function () {
            return controller.rankingsList.length && !controller.isDatagridReloading;
        };

        /**
         * Populates input text field for date field
         *
         * @param {string} filterName
         * @param {mixed} defaultValue
         */
        controller.applyDateFilter = function (filterName, defaultValue) {
            var filter = GridService.getFilterByField(controller.paginationOptions, filterName, defaultValue);
            if (angular.isDefined(filter) &&
                filter !== null
            ) {
                if (angular.isDefined(filter.startDate) &&
                    angular.isDefined(filter.endDate)
                ) {
                    if (filter.startDate !== null &&
                        filter.startDate !== null
                    ) {
                        controller.defaultFilters[filterName + 'Range'] = {
                            startDate: parseInt(filter.startDate, 10),
                            endDate: parseInt(filter.endDate, 10)
                        };
                    } else {
                        controller.defaultFilters[filterName + 'Range'] = {
                            startDate: null,
                            endDate: null
                        };
                    }
                }
            }
        };

        controller.formatCsvResults = function (data) {
            var results = [];

            angular.forEach(data, function (column) {
                results.push({
                    replies: typeof column.replies !== 'undefined' ?
                        (column.replies !== null ? column.replies.length + 1 : 0)
                        : null,
                    createdByFullName: column.createdByFullName,
                    institutionCoreIdAsString: column.institutionCoreIdAsString,
                    institutionName: column.institutionName,
                    subject: column.subject,
                    message: $filter('htmlToPlaintext')(column.message),
                    createdAt: TimeService.format(column.createdAt, "YYYY-MM-DD HH:mm:ss"),
                    "source.question": MessagesService.getQuestionName(column.source.question, controller.questionsList),
                    "source.ranking": MessagesService.getRankingName(column.source.ranking, controller.rankingsList)
                });
            });

            return results;
        };

        controller.exportVisibleRows = function () {
            var results = [];

            angular.forEach(controller.gridApi.core.getVisibleRows(controller.gridApi.grid), function (column) {
                results.push(column.entity);
            });
            results = controller.formatCsvResults(results);
            if (typeof results === 'undefined') {
                return false;
            }
            var gridApi = controller.gridApi,
                columns = gridApi.grid.columns.slice(0),
                i = 0,
                total = columns.length;
            for (i; i < total; i++) {
                if (columns[i].field === 'replies') {
                    columns[i].displayName = 'Replies';
                    break;
                }
            }
            var gridOptions = controller.gridOptions,
                exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                    gridApi.grid,
                    uiGridExporterConstants.VISIBLE
                ),
                exportData = GridService.getCsvData(columns, results),
                csvContent = uiGridExporterService.formatAsCsv(
                    exportColumnHeaders,
                    exportData,
                    gridOptions.exporterCsvColumnSeparator
                );

            uiGridExporterService.downloadFile(
                'qs-users-messages-center-' + TimeService.now() + '.csv',
                csvContent,
                gridOptions.exporterOlderExcelCompatibility
            );
        };

        controller.exportAllData = function () {
            return QsUsersMessagesCenterService.getData(angular.merge({}, controller.paginationOptions, {
                'filter[export]' : true
            })).then(function (response) {
                if (response && response.results) {
                    var results = controller.formatCsvResults(response.results),
                        gridApi = controller.gridApi,
                        gridOptions = controller.gridOptions,
                        exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                            gridApi.grid,
                            uiGridExporterConstants.VISIBLE
                        ),
                        i = 0,
                        total = exportColumnHeaders.length;
                    for (i; i < total; i++) {
                        if (exportColumnHeaders[i].name === 'replies') {
                            exportColumnHeaders.splice(i, 1);
                            break;
                        }
                    }
                    var columns = gridApi.grid.columns.slice(0);
                    i = 0;
                    total = columns.length;
                    for (i; i < total; i++) {
                        if (columns[i].field === 'replies') {
                            columns.splice(i, 1);
                            break;
                        }
                    }
                    var exportData = GridService.getCsvData(columns, results),
                        csvContent = uiGridExporterService.formatAsCsv(
                            exportColumnHeaders,
                            exportData,
                            gridOptions.exporterCsvColumnSeparator
                        );
                    uiGridExporterService.downloadFile(
                        'qs-users-messages-center-' + TimeService.now() + '-full.csv',
                        csvContent,
                        gridOptions.exporterOlderExcelCompatibility
                    );
                }
            });
        };

        controller.handleDatagridRowClick = function (row) {
            controller.state = 'listMessages';
            controller.selectedRowId = controller.selectedRowId === row.id ? null : row.id;
            controller.activeMessage = row;
            if (controller.selectedRowId && controller.isUnread(row)) {
                controller.markAsRead(row);
            }
        };

        controller.initDatagridOptions = function () {
            var multiSelectTemplate = '/scripts/shared/ui-grid/templates/multiSelectFilterHeaderTemplate.html',
                selectCellTemplate = '/scripts/shared/ui-grid/templates/selectCellTemplate.html',
                dateCellTemplate = '/scripts/modules/messages/datagrid/dateCellTemplate.html',
                repliesCellTemplate = '/scripts/modules/messages/datagrid/repliesCellTemplateView.html',
                fullNameCellTemplate = '/scripts/modules/messages/datagrid/fullNameCellTemplateView.html',
                messageCellTemplate = '/scripts/modules/messages/datagrid/messageCellTemplateView.html',
                createdAtHeaderTemplate = '/scripts/modules/messages/datagrid/createdAtFilterHeaderTemplate.html';

            controller.gridOptions = angular.extend({}, controller.gridOptions, {
                appScopeProvider: controller,
                enableSorting: true,
                exporterMenuCsv: false,
                exporterMenuAllData: false,
                exporterMenuPdf: false,
                useExternalFiltering: true,
                enableGridMenu: true,
                showGridFooter: false,
                enableFiltering: true,
                enableColumnResize: true,
                enableFullRowSelection: true,
                enableRowSelection: true,
                multiSelect: false,
                enableRowHeaderSelection: false,
                paginationPageSizes: [25, 50, 100],
                paginationPageSize: 25,
                useExternalPagination: true,
                useExternalSorting: true,
                rowTemplate: '/scripts/modules/messages/datagrid/rowTemplate.html',
                gridMenuCustomItems: [
                    {
                        title: ' Export visible data as csv',
                        action: function () {
                            controller.exportVisibleRows();
                        },
                        order: 209
                    },
                    {
                        templateUrl: '/scripts/modules/messages/datagrid/exportAllMenuItem.html',
                        title: ' Export all data as csv',
                        action: function () {
                            controller.exportAllData();
                        },
                        order: 210
                    }
                ],
                columnDefs: [
                    {
                        displayName: '',
                        field: 'replies',
                        enableFiltering: false,
                        enableSorting: false,
                        cellTemplate: repliesCellTemplate,
                        width: 45,
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'replies', true)
                    },
                    {
                        displayName: 'Full Name',
                        cellTemplate: fullNameCellTemplate,
                        field: 'createdByFullName',
                        filterCellFiltered: false,
                        enableFiltering: false,
                        enableSorting: false,
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'createdByFullName', true),
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'createdByFullName', ''),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        displayName: 'Core Id',
                        field: 'institutionCoreIdAsString',
                        filterCellFiltered: false,
                        enableFiltering: true,
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'institutionCoreIdAsString', true),
                        sort: {
                            column: 'institutionCoreId',
                        },
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'institutionCoreIdAsString', ''),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        displayName: 'Institution',
                        field: 'institutionName',
                        filterCellFiltered: false,
                        enableFiltering: true,
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'institutionName', true),
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'institutionName', ''),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        displayName: 'Subject',
                        field: 'subject',
                        filterCellFiltered: false,
                        enableFiltering: true,
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'subject', true),
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'subject', ''),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        displayName: 'Message',
                        field: 'message',
                        cellTemplate: messageCellTemplate,
                        filterCellFiltered: false,
                        enableFiltering: false,
                        enableSorting: false,
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'message', true),
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'message', ''),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        displayName: 'Date',
                        field: controller.createdAtField,
                        enableFiltering: true,
                        filterCellFiltered: true,
                        enableSorting: false,
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, controller.createdAtField, true),
                        filters: [
                            {
                                visible: true,
                                condition: uiGridConstants.filter.GREATER_THAN
                            },
                            {
                                condition: uiGridConstants.filter.LESS_THAN
                            }
                        ],
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, controller.createdAtField, ''),
                            applyTextFilter: controller.applyDateFilter(controller.createdAtField, '')
                        },
                        cellTemplate: dateCellTemplate,
                        filterHeaderTemplate: createdAtHeaderTemplate,
                    },
                    {
                        displayName: 'Question',
                        field: 'source.question',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'source.question', true),
                        filter: {
                            field: 'source.question',
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: controller.questionsList,
                            searchEnabled: true,
                            term: GridService.getFilterByField(controller.paginationOptions, 'source.question', [], true),
                            condition: function (searchTerms, cellValue) {
                                if (angular.isDefined(searchTerms) && searchTerms.length > 0) {
                                    var searchTermsArray = [];
                                    angular.forEach(searchTerms, function (searchTerm) {
                                        searchTermsArray.push(searchTerm.value);
                                    });
                                    return searchTermsArray.indexOf(cellValue) !== -1;
                                } else {
                                    return true;
                                }
                            }
                        },
                        filterHeaderTemplate: multiSelectTemplate,
                        cellTemplate: selectCellTemplate
                    },
                    {
                        displayName: 'Ranking',
                        field: 'source.ranking',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'source.ranking'),
                        filter: {
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: controller.rankingsList,
                            searchEnabled: true,
                            term: GridService.getFilterByField(controller.paginationOptions, 'source.ranking', [], true),
                            condition: function (searchTerms, cellValue) {
                                if (angular.isDefined(searchTerms) && searchTerms.length > 0) {
                                    var searchTermsArray = [];
                                    angular.forEach(searchTerms, function (searchTerm) {
                                        searchTermsArray.push(searchTerm.value);
                                    });
                                    return searchTermsArray.indexOf(cellValue) !== -1;
                                } else {
                                    return true;
                                }
                            }
                        },
                        filterHeaderTemplate: multiSelectTemplate,
                        cellTemplate: selectCellTemplate
                    },
                ],
                onRegisterApi: function (gridApi) {
                    controller.gridApi = gridApi;

                    gridApi.selection.on.rowSelectionChanged($scope, function (row) {
                        controller.handleDatagridRowClick(row.entity);
                    });

                    gridApi.core.on.rowsRendered($scope, function () {
                        controller.isRendered = true;
                        GridService.generateSelectValues(controller.gridApi.grid.columns, selectValues);
                        GridService.generateSelectValues(controller.gridApi.grid.columns, lastSelectValues);
                    });

                    gridApi.core.on.sortChanged($scope, controller.handleGridFiltersChanges);
                    gridApi.core.on.filterChanged($scope, controller.handleGridFiltersChanges);
                    gridApi.core.on.rowsVisibleChanged($scope, controller.handleGridVisibleChanges);
                    gridApi.pagination.on.paginationChanged($scope, function (newPage, limit) {
                        controller.defaultPaginationOptions.page = newPage;
                        controller.defaultPaginationOptions.limit = limit;
                        if(!filterChangesInProgress) { 
                            controller.getPage();
                        }
                    });
                }
            });

            var createdAt = GridService.getFilterByField(controller.paginationOptions, 'createdAt', '');
            if (createdAt !== null && controller.paginationOptions["filter[createdAt]"] !== null) {
                if (angular.isDefined(createdAt) &&
                    angular.isDefined(controller.paginationOptions["filter[createdAt]"]) &&
                    angular.isDefined(controller.paginationOptions["filter[createdAt]"].startDate) &&
                    angular.isDefined(controller.paginationOptions["filter[createdAt]"].endDate)
                ) {
                    controller.gridOptions.columnDefs[controller.getColumnNumber('createdAt')].filters[0].term = controller.paginationOptions["filter[createdAt]"].startDate;
                    controller.gridOptions.columnDefs[controller.getColumnNumber('createdAt')].filters[1].term = controller.paginationOptions["filter[createdAt]"].endDate;
                }
            }
        };

        controller.initDatagrid = function () {
            controller.filters = angular.extend({}, controller.defaultFilters);
        };

        controller.getColumnNumber = function (columnName) {
            var key = 0,
                total = controller.gridOptions.columnDefs.length;
            for (key; key < total; key++) {
                if (controller.gridOptions.columnDefs[key] &&
                    controller.gridOptions.columnDefs[key].field === columnName
                ) {
                    return key;
                }
            }

            return null;
        };

        controller.handleCreatedAtDateRange = function (event) {

            if (DateRangeFixerService.sameDayAndNoModel(event, controller.filters.createdAtRange, lastCreatedAtDateFilter)) {
                event.model.startDate = lastCreatedAtDateFilter.startDate;
                event.model.endDate = lastCreatedAtDateFilter.endDate;
                controller.filters.createdAtRange = {
                    startDate: lastCreatedAtDateFilter.startDate,
                    endDate: lastCreatedAtDateFilter.endDate
                };
            } else if (DateRangeFixerService.todayRangeAndNoModel(event, lastCreatedAtDateFilter)) {
                event.model.startDate = TimeService.getStartOfToday();
                event.model.endDate = TimeService.getEndOfToday();
                controller.filters.createdAtRange = {
                    startDate: event.model.startDate.toISOString(),
                    endDate: event.model.endDate.toISOString()
                };
            }

            if (event.model.startDate && event.model.endDate) {
                controller.gridOptions.columnDefs[controller.getColumnNumber('createdAt')].filters[0].term = typeof event.model.startDate === 'object' ? event.model.startDate.format('x') : event.model.startDate;
                controller.gridOptions.columnDefs[controller.getColumnNumber('createdAt')].filters[1].term = typeof event.model.endDate === 'object' ? event.model.endDate.format('x') : event.model.endDate;
            }

            lastCreatedAtDateFilter = angular.copy({
                startDate: event.model.startDate,
                endDate: event.model.endDate
            });
        };

        controller.handleGridFiltersChanges = function () {
            controller.paginationOptions = angular.copy(controller.defaultPaginationOptions);
            var filterColumn, sortColumn, sortKeys = [];
            filterChangesInProgress = true;
            if(controller.gridApi.pagination.getPage() > 1){
                controller.gridApi.pagination.seek(1);
            }

            if (sortKeys.length > 1) {
                var latestSortKey = sortKeys.pop();
                angular.forEach(controller.gridApi.grid.columns, function (column) {
                    if (typeof column.sort.priority !== 'undefined' && column.name !== latestSortKey) {
                        column.sort = {};
                    }
                });
            }

            angular.forEach(controller.gridApi.grid.columns, function (column) {
                filterColumn = column.filters[0].column ? column.filters[0].column : column.field;
                if (column.field === 'createdAt') {
                    if (
                        typeof column.filters !== 'undefined' &&
                        typeof column.filters[0].term !== 'undefined' &&
                        typeof column.filters[1].term !== 'undefined'
                    ) {
                        controller.paginationOptions['filter[' + filterColumn + ']'] = {
                            startDate: column.filters[0].term && isNaN(column.filters[0].term) && typeof column.filters[0].term === 'object' ?
                                column.filters[0].term.format('x') : column.filters[0].term,
                            endDate: column.filters[1].term && isNaN(column.filters[1].term) && typeof column.filters[1].term === 'object' ?
                                column.filters[1].term.format('x') : column.filters[1].term
                        };
                    } else {
                        controller.paginationOptions['filter[' + filterColumn + ']'] = null;
                    }
                } else if (typeof column.filters !== 'undefined' &&
                    typeof column.filters[0].term !== 'undefined' &&
                    column.filters[0].term !== ''
                ) {
                    if (column.filter.type && column.filter.type === uiGridConstants.filter.SELECT) {
                        var filters = [];
                        angular.forEach(column.filters[0].term, function (term) {
                            filters.push(term);
                        });
                        if (filters.length) {
                            controller.paginationOptions['filter[' + filterColumn + '][]'] = filters;
                        }
                    } else {
                        controller.paginationOptions['filter[' + filterColumn + ']'] = column.filters[0].term;
                    }
                }
                if (typeof column.sort.direction !== 'undefined') {
                    sortColumn = column.sort.column ? column.sort.column : column.field;
                    controller.paginationOptions['sorting[' + sortColumn + ']'] = column.sort.direction;
                }
            });

            if (controller.paginationOptions["filter[createdAt]"] === null) {
                controller.paginationOptions["filter[createdAt]"] = controller.filters.createdAtRange;
            }

            if (controller.paginationOptions["filter[createdAt]"].startDate === null) {
                controller.paginationOptions["filter[createdAt]"].endDate = null;
                controller.filters.createdAtRange = {
                    startDate: null,
                    endDate: null
                };
            }

            GridService.storeColumnsFilters(
                constants.gridNameSpaces.qsUserMessagesCenterFilters,
                controller.paginationOptions
            );

            if (angular.isDefined($scope.filterTimeout)) {
                $timeout.cancel($scope.filterTimeout);
            }

            GridService.generateSelectValues(controller.gridApi.grid.columns, selectValues);

            if (!angular.equals(selectValues, lastSelectValues)) {
                filterTimeoutMilliseconds = constants.datagridSelectDelay;
            }

            $scope.filterTimeout = $timeout(function () {
                controller.searching = true;
                controller.getPage().then(function () {
                    controller.searching = false;
                });
                filterChangesInProgress = false;
                lastSelectValues = angular.copy(selectValues);
                filterTimeoutMilliseconds = constants.datagridInputDelay;
            }, filterTimeoutMilliseconds);
        };

        controller.handleGridVisibleChanges = function () {
            var columnsVisibility = GridService.getColumnsVisibility(
                constants.gridNameSpaces.qsUserMessagesCenterVisibility
                ),
                reload = false;

            if (columnsVisibility) {
                angular.forEach(controller.gridOptions.columnDefs, function (column, key) {
                    if (columnsVisibility[column.field] === true &&
                        column.visible === false
                    ) {
                        if (typeof  controller.gridOptions.columnDefs[key].filter === 'undefined') {
                            controller.gridOptions.columnDefs[key].filter = {};
                        }
                        if (typeof controller.gridOptions.columnDefs[key].filter !== 'undefined' &&
                            controller.gridOptions.columnDefs[key].filter.hasOwnProperty('term') &&
                            controller.gridOptions.columnDefs[key].filter.term) {
                            controller.paginationOptions['filter[' + column.field + ']'] = null;
                            if(column.field === 'source.question'){
                                controller.paginationOptions['filter[source.question][]'] = null;
                            }
                            if(column.field === 'source.ranking'){
                                controller.paginationOptions['filter[source.ranking][]'] = null;
                            }
                            reload = true;
                        }
                        controller.gridOptions.columnDefs[key].filter.term = null;
                        if (column.field === 'createdAt') {
                            delete controller.gridOptions.columnDefs[key].filters[0].term;
                            controller.filters.createdAtRange = {
                                startDate: null,
                                endDate: null
                            };
                        }
                    }
                });
            }
            if (controller.gridOptions) {
               controller.columnsVisibility = GridService.getGridColumnsVisibility(controller.gridOptions.columnDefs);
                    GridService.storeColumnsVisibility(
                        constants.gridNameSpaces.qsUserMessagesCenterVisibility,
                        controller.columnsVisibility
                    );
            }
            if (reload) {
                controller.getPage();
            }
        };
        
        controller.isUnread = function (message) {
            return MessagesService.isUnread(message);
        };

        controller.markAsRead = function (message) {
            MessagesService.markAsRead(message.id).then(function (result) {
                if (result === true) {
                    if (message.replies !== null &&
                        message.replies.length > 0
                    ) {
                        message.replies[0].unread = false;
                    } else {
                        message.unread = false;
                    }
                }
            });
        };

        controller.messageDeletedHandler = function () {
            controller.getPage();
        };

        controller.messageSentHandler = function () {
            controller.getPage();
            if (controller.selectedRowId) {
                MessagesService.getMessage(controller.selectedRowId).then(function (data) {
                    controller.activeMessage = data;
                    controller.state = 'listMessages';
                });
            }

        };

        controller.getPage = function () {
            controller.loading = true;
            if (!controller.rankingsList.length || !controller.questionsList.length) {
                return false;
            }

            controller.isDatagridReloading = true;
            var options = angular.copy(controller.paginationOptions),
                terms;
            if (options && options['filter[source.ranking][]']) {
                terms = [];
                angular.forEach(options['filter[source.ranking][]'], function (item) {
                    terms.push(item.value);
                });
                options['filter[source.ranking][]'] = terms;
            }
            if (options && options['filter[source.question][]']) {
                terms = [];
                angular.forEach(options['filter[source.question][]'], function (item) {
                    terms.push(item.value);
                });
                options['filter[source.question][]'] = terms;
            }

            return QsUsersMessagesCenterService.getData(
                angular.merge({}, options, controller.defaultPaginationOptions)
            ).then(function (response) {
                controller.gridOptions.totalItems = response.totalMatching;
                controller.gridOptions.data = response.results;
                controller.gridOptions.minRowsToShow = response.totalFiltered;
                controller.isDatagridReloading = false;
                controller.isRendered = true;
                controller.initialLoaded = true;
                controller.loading = false;
            });
        };

        controller.initLists = function () {
            ProgressReportByRankingService.getRankingsList(true).then(function (list) {
                angular.forEach(list, function (item) {
                    controller.rankingsList.push({
                        value: item.value,
                        label: item.label
                    });
                });
                controller.getPage();
            });

            RankingsService.getQuestions().then(function (list) {
                angular.forEach(list, function (item) {
                    controller.questionsList.push({
                        value: item.handle,
                        label: item.name
                    });
                });
                controller.getPage();
            });
        };

        controller.initWatches = function () {
            WatchService.create($scope, 'QsUsersMessagesCenterController.activeMessage', function (activeMessage) {
                if (!activeMessage) {
                    controller.handleDatagridRowClick({});
                }
            });
        };

        controller.closeMessage = function () {
            controller.activeMessage = null;
        };

        controller.init = function () {
            controller.initWatches();
            controller.initDatagridOptions();
            controller.initLists();
            controller.initDatagrid();
        };

        controller.init();
    }

}(window.angular));

(function (angular) {
    "use strict";

    function QsUsersMessagesCenter($resource,
                                   $log,
                                   constants) {
        var service = {};

        /**
         * Get answers list API endpoint.
         *
         * @returns {$resource}
         */
        service.getModel = function () {
            return $resource(constants.api.messages.url + '/v1/list', {}, {
                get: {
                    isArray: false,
                    cancellable: true
                }
            });
        };

        service.getData = function (filters) {
            if (constants.dev) {
                var startTime = new Date().getTime(), endTime;
            }

            var hasSorting = false,
                allowedFilters = [
                    'limit',
                    'page'
                ];
            if (filters) {
                for (var key in filters) {
                    if (key.indexOf('sorting') !== -1) {
                        hasSorting = true;
                        break;
                    } else if (key.indexOf('filter') !== 0 && allowedFilters.indexOf(key) === -1) {
                        delete filters[key];
                    }
                }
            }
            if (!hasSorting) {
                filters['sorting[modifiedAt]'] = 'desc';
            }

            return service.getModel().get(filters).$promise.then(function (data) {
                if (constants.dev) {
                    endTime = new Date().getTime();
                    $log.log('success, got data: ', data, 'in ' + (endTime - startTime) + ' ms');
                }

                return data;
            }, function (error) {
                if (constants.dev) {
                    $log.log('request failed' + error);
                }

                return [];
            });
        };

        return service;
    }

    angular
        .module('qsHub.rankings')
        .service('QsUsersMessagesCenterService', [
            '$resource',
            '$log',
            'constants',
            QsUsersMessagesCenter
        ]);

}(window.angular));


(function (angular) {
    "use strict";

    angular
        .module('qsHub.messages')
        .controller('Messages.InstitutionUsersMessagesCenterController', [
            '$scope',
            '$timeout',
            '$filter',
            'messages.gridNamespaces',
            'WatchService',
            'uiGridConstants',
            'UiGridService',
            'uiGridExporterConstants',
            'uiGridExporterService',
            'constants',
            'ProgressReportByRankingService',
            'InstitutionUsersMessagesCenterService',
            'InstitutionFactory',
            'MessagesService',
            'TimeService',
            'DateRangeFixerService',
            'RankingsService',
            InstitutionUsersMessagesCenter
        ]);

    function InstitutionUsersMessagesCenter($scope,
                                            $timeout,
                                            $filter,
                                            gridNamespaces,
                                            WatchService,
                                            uiGridConstants,
                                            GridService,
                                            uiGridExporterConstants,
                                            uiGridExporterService,
                                            constants,
                                            ProgressReportByRankingService,
                                            InstitutionUsersMessagesCenterService,
                                            InstitutionFactory,
                                            MessagesService,
                                            TimeService,
                                            DateRangeFixerService,
                                            RankingsService) {
        var controller = this;
        var lastCreatedAtDateFilter = null,
        filterChangesInProgress = false;
        controller.initialLoaded = false;
        controller.loading = false;
        controller.devMode = constants.dev;
        controller.defaultFilters = {
            createdAtRange: {
                startDate: null,
                endDate: null
            }
        };
        controller.grid = {
            options: {
                data: []
            }
        };
        controller.defaultPaginationOptions = {
            page: 1,
            limit: constants.datagrid.contacts.defaultRowsNumber
        };
        controller.isDatagridReloading = false;
        controller.isRendered = false;
        controller.gridApi = null;
        controller.filters = null;
        controller.institutionCoreId = InstitutionFactory.getCoreId();
        controller.paginationOptions = GridService.getColumnsFilters(
            gridNamespaces.filters.institutionUsersMessagesCenter,
            ['createdAtRange']
        );
        controller.columnsVisibility = GridService.getColumnsVisibility(
            gridNamespaces.visibility.institutionUsersMessagesCenter
        );
        controller.rankingsList = [];
        controller.questionsList = [];
        controller.state = 'listMessages';
        controller.repliesLimit = null;

        controller.isLoading = function () {
            return !controller.rankingsList.length || !controller.questionsList.length || !controller.isRendered || !controller.initialLoaded || controller.loading;
        };

        controller.isDatagridVisible = function () {
            return controller.rankingsList.length && !controller.isDatagridReloading;
        };

        controller.handleCreatedAtDateRange = function (event) {

            if (DateRangeFixerService.sameDayAndNoModel(event, $scope.filters.createdAtRange, lastCreatedAtDateFilter)) {
                event.model.startDate = lastCreatedAtDateFilter.startDate;
                event.model.endDate = lastCreatedAtDateFilter.endDate;
                $scope.filters.createdAtRange = {
                    startDate: lastCreatedAtDateFilter.startDate,
                    endDate: lastCreatedAtDateFilter.endDate
                };
            } else if (DateRangeFixerService.todayRangeAndNoModel(event, lastCreatedAtDateFilter)) {
                event.model.startDate = TimeService.getStartOfToday();
                event.model.endDate = TimeService.getEndOfToday();
                $scope.filters.createdAtRange = {
                    startDate: event.model.startDate.toISOString(),
                    endDate: event.model.endDate.toISOString()
                };
            }

            if (event.model.startDate && event.model.endDate) {
                controller.grid.options.columnDefs[controller.getColumnNumber('createdAt')].filters[0].term = typeof event.model.startDate === 'object' ? event.model.startDate.format('x') : event.model.startDate;
                controller.grid.options.columnDefs[controller.getColumnNumber('createdAt')].filters[1].term = typeof event.model.endDate === 'object' ? event.model.endDate.format('x') : event.model.endDate;
            }

            lastCreatedAtDateFilter = angular.copy({
                startDate: event.model.startDate,
                endDate: event.model.endDate
            });
        };

        controller.handleDatagridRowClick = function (row) {
            controller.state = 'listMessages';
            controller.selectedRowId = controller.selectedRowId === row.id ? null : row.id;
            controller.activeMessage = row;
            if (controller.selectedRowId && controller.isUnread(row)) {
                controller.markAsRead(row);
            }
        };

        controller.formatCsvResults = function (data) {
            var results = [];

            angular.forEach(data, function (column) {
                results.push({
                    replies: typeof column.replies !== 'undefined' ?
                        (column.replies !== null ? column.replies.length + 1 : 0)
                        : null,
                    createdByFullName: column.createdByFullName,
                    subject: column.subject,
                    message: $filter('htmlToPlaintext')(column.message),
                    createdAt: TimeService.format(column.createdAt, "YYYY-MM-DD HH:mm:ss"),
                    "source.question": MessagesService.getQuestionName(column.source.question, controller.questionsList),
                    "source.ranking": MessagesService.getRankingName(column.source.ranking, controller.rankingsList)
                });
            });

            return results;
        };

        controller.exportVisibleRows = function () {
            var results = [];

            angular.forEach(controller.gridApi.core.getVisibleRows(controller.gridApi.grid), function (column) {
                results.push(column.entity);
            });
            results = controller.formatCsvResults(results);
            if (typeof results === 'undefined') {
                return false;
            }
            var gridApi = controller.gridApi,
                columns = gridApi.grid.columns.slice(0),
                i = 0,
                total = columns.length;
            for (i; i < total; i++) {
                if (columns[i].field === 'replies') {
                    columns[i].displayName = 'Replies';
                    break;
                }
            }
            var gridOptions = controller.grid.options,
                exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                    gridApi.grid,
                    uiGridExporterConstants.VISIBLE
                ),
                exportData = GridService.getCsvData(columns, results),
                csvContent = uiGridExporterService.formatAsCsv(
                    exportColumnHeaders,
                    exportData,
                    gridOptions.exporterCsvColumnSeparator
                );

            uiGridExporterService.downloadFile(
                'qs-users-messages-center-' + TimeService.now() + '.csv',
                csvContent,
                gridOptions.exporterOlderExcelCompatibility
            );
        };

        controller.exportAllData = function () {
            return InstitutionUsersMessagesCenterService.getData(angular.merge({}, controller.paginationOptions, {
                'filter[export]': true
            })).then(function (response) {
                if (response && response.results) {
                    var results = controller.formatCsvResults(response.results),
                        gridApi = controller.gridApi,
                        gridOptions = controller.grid.options,
                        exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                            gridApi.grid,
                            uiGridExporterConstants.VISIBLE
                        ),
                        i = 0,
                        total = exportColumnHeaders.length;
                    for (i; i < total; i++) {
                        if (exportColumnHeaders[i].name === 'replies') {
                            exportColumnHeaders.splice(i, 1);
                            break;
                        }
                    }
                    var columns = gridApi.grid.columns.slice(0);
                    i = 0;
                    total = columns.length;
                    for (i; i < total; i++) {
                        if (columns[i].field === 'replies') {
                            columns.splice(i, 1);
                            break;
                        }
                    }
                    var exportData = GridService.getCsvData(columns, results),
                        csvContent = uiGridExporterService.formatAsCsv(
                            exportColumnHeaders,
                            exportData,
                            gridOptions.exporterCsvColumnSeparator
                        );
                    uiGridExporterService.downloadFile(
                        'qs-users-messages-center-' + TimeService.now() + '-full.csv',
                        csvContent,
                        gridOptions.exporterOlderExcelCompatibility
                    );
                }
            });
        };

        controller.initDataGridOptions = function () {
            var multiSelectTemplate = '/scripts/shared/ui-grid/templates/multiSelectFilterHeaderTemplate.html',
                selectCellTemplate = '/scripts/shared/ui-grid/templates/selectCellTemplate.html',
                dateCellTemplate = '/scripts/modules/messages/datagrid/dateCellTemplate.html',
                repliesCellTemplate = '/scripts/modules/messages/datagrid/repliesCellTemplateView.html',
                fullNameCellTemplate = '/scripts/modules/messages/datagrid/fullNameCellTemplateView.html',
                messageCellTemplate = '/scripts/modules/messages/datagrid/messageCellTemplateView.html',
                createdAtHeaderTemplate = '/scripts/modules/messages/datagrid/createdAtFilterHeaderTemplate.html';
            controller.grid.options = {
                appScopeProvider: controller,
                data: [],
                enableSorting: true,
                enableGridMenu: true,
                showGridFooter: false,
                selectedItems: $scope.selectedUserId,
                enableFiltering: true,
                enableColumnResize: true,
                enableFullRowSelection: true,
                enableRowSelection: true,
                multiSelect: false,
                enableRowHeaderSelection: false,
                paginationPageSizes: [constants.datagrid.contacts.defaultRowsNumber, 50, 100],
                paginationPageSize: constants.datagrid.contacts.defaultRowsNumber,
                useExternalPagination: true,
                useExternalSorting: true,
                useExternalFiltering: true,
                exporterMenuCsv: false,
                exporterMenuAllData: false,
                exporterMenuPdf: false,
                gridMenuCustomItems: [
                    {
                        title: ' Export visible data as csv',
                        action: function () {
                            controller.exportVisibleRows();
                        },
                        order: 209
                    },
                    {
                        title: ' Export all data as csv',
                        action: function () {
                            controller.exportAllData();
                        },
                        order: 210
                    }
                ],
                rowTemplate: '/scripts/modules/messages/datagrid/rowTemplate.html',
                columnDefs: [
                    {
                        displayName: '',
                        field: 'replies',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'replies', true),
                        enableFiltering: false,
                        enableSorting: false,
                        cellTemplate: repliesCellTemplate,
                        width: 45
                    },
                    {
                        displayName: 'Full Name',
                        enableFiltering: false,
                        enableSorting: false,
                        field: 'createdByFullName',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'createdByFullName', true),
                        filterCellFiltered: false,
                        cellTemplate: fullNameCellTemplate
                    },
                    {
                        displayName: 'Subject',
                        field: 'subject',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'subject', true),
                        filterCellFiltered: false,
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'subject', ''),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        displayName: 'Message',
                        field: 'message',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'message', true),
                        filterCellFiltered: false,
                        enableFiltering: false,
                        enableSorting: false,
                        cellTemplate: messageCellTemplate
                    },
                    {
                        displayName: 'Date',
                        enableFiltering: false,
                        enableSorting: false,
                        type: 'date',
                        field: 'createdAt',
                        cellFilter: 'date:\'medium\' : \'UTC\'',
                        filterCellFiltered: true,
                        filters: [
                            {
                                visible: true,
                                condition: uiGridConstants.filter.GREATER_THAN
                            },
                            {
                                condition: uiGridConstants.filter.LESS_THAN
                            }
                        ],
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'createdAt', ''),
                            applyTextFilter: controller.applyDateFilter('createdAt', '')
                        },
                        cellTemplate: dateCellTemplate,
                        filterHeaderTemplate: createdAtHeaderTemplate,
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'createdAt', true)
                    },
                    {
                        displayName: 'Question',
                        field: 'source.question',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'source.question', true),
                        filter: {
                            column: 'source.question',
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: controller.questionsList,
                            searchEnabled: true,
                            term: GridService.getFilterByField(controller.paginationOptions, 'source.question', [], true),
                            condition: function (searchTerms, cellValue) {
                                if (angular.isDefined(searchTerms) && searchTerms.length > 0) {
                                    var searchTermsArray = [];
                                    angular.forEach(searchTerms, function (searchTerm) {
                                        searchTermsArray.push(searchTerm.value);
                                    });
                                    return searchTermsArray.indexOf(cellValue) !== -1;
                                } else {
                                    return true;
                                }
                            }
                        },
                        filterHeaderTemplate: multiSelectTemplate,
                        cellTemplate: selectCellTemplate
                    },
                    {
                        displayName: 'Ranking',
                        field: 'source.ranking',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'source.ranking', true),
                        filter: {
                            column: 'source.ranking',
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: controller.rankingsList,
                            searchEnabled: true,
                            term: GridService.getFilterByField(controller.paginationOptions, 'source.ranking', [], true),
                            condition: function (searchTerms, cellValue) {
                                if (angular.isDefined(searchTerms) && searchTerms.length > 0) {
                                    var searchTermsArray = [];
                                    angular.forEach(searchTerms, function (searchTerm) {
                                        searchTermsArray.push(searchTerm.value);
                                    });
                                    return searchTermsArray.indexOf(cellValue) !== -1;
                                } else {
                                    return true;
                                }
                            }
                        },
                        filterHeaderTemplate: multiSelectTemplate,
                        cellTemplate: selectCellTemplate
                    },
                ],
                onRegisterApi: function (gridApi) {
                    var columnsVisibilityBeforeChanges = angular.copy(controller.grid.options.columnDefs);
                    controller.gridApi = gridApi;
                    gridApi.selection.on.rowSelectionChanged($scope, function (row) {
                        controller.handleDatagridRowClick(row.entity);
                    });
                    gridApi.core.on.rowsRendered($scope, function () {
                        controller.isDatagridRendered = true;
                    });
                    gridApi.core.on.filterChanged($scope, controller.handleGridFiltersChanges);
                    gridApi.core.on.rowsVisibleChanged($scope, controller.handleGridVisibleChanges);

                    gridApi.pagination.on.paginationChanged($scope, function (newPage, limit) {
                        controller.defaultPaginationOptions.page = newPage;
                        controller.defaultPaginationOptions.limit = limit;
                        if(!filterChangesInProgress) { 
                            controller.getPage();
                        }
                    });
                    controller.gridApi.core.on.sortChanged($scope, controller.handleGridFiltersChanges);
                    // reset columns visibility
                    GridService.resetExtend(
                        columnsVisibilityBeforeChanges,
                        controller.grid.options.columnDefs,
                        controller.filters
                    );
                }
            };

            var createdAt = GridService.getFilterByField(controller.paginationOptions, 'createdAt', '');
            if (createdAt !== null && controller.paginationOptions["filter[createdAt]"] !== null) {
                if (angular.isDefined(createdAt) &&
                    angular.isDefined(controller.paginationOptions["filter[createdAt]"]) &&
                    angular.isDefined(controller.paginationOptions["filter[createdAt]"].startDate) &&
                    angular.isDefined(controller.paginationOptions["filter[createdAt]"].endDate)
                ) {
                    controller.grid.options.columnDefs[controller.getColumnNumber('createdAt')].filters[0].term = controller.paginationOptions["filter[createdAt]"].startDate;
                    controller.grid.options.columnDefs[controller.getColumnNumber('createdAt')].filters[1].term = controller.paginationOptions["filter[createdAt]"].endDate;
                }
            }

            controller.filters = angular.extend({}, controller.defaultFilters);
        };

        controller.messageDeletedHandler = function () {
            controller.getPage();
        };

        controller.messageSentHandler = function () {
            controller.getPage();
            if (controller.selectedRowId) {
                MessagesService.getMessage(controller.selectedRowId).then(function (data) {
                    controller.activeMessage = data;
                    controller.state = 'listMessages';
                });
            }
        };

        controller.isUnread = function (message) {
            return MessagesService.isUnread(message);
        };

        /**
         * Mark message as read
         *
         * @param {type} message
         * @returns {undefined}
         */
        controller.markAsRead = function (message) {
            MessagesService.markAsRead(message.id).then(function (result) {
                if (result === true) {
                    if (message.replies !== null &&
                        message.replies.length > 0
                    ) {
                        message.replies[0].unread = false;
                    } else {
                        message.unread = false;
                    }
                }
            });
        };

        controller.getColumnNumber = function (columnName) {
            var key = 0,
                total = controller.grid.options.columnDefs.length;
            for (key; key < total; key++) {
                if (controller.grid.options.columnDefs[key] &&
                    controller.grid.options.columnDefs[key].field === columnName
                ) {
                    return key;
                }
            }
            return null;
        };

        controller.handleGridFiltersChanges = function () {
            controller.paginationOptions = angular.copy(controller.defaultPaginationOptions);
            var filterColumn, sortColumn, sortKeys = [];
            filterChangesInProgress = true;
            if(controller.gridApi.pagination.getPage() > 1){
                controller.gridApi.pagination.seek(1);
            }

            if (sortKeys.length > 1) {
                var latestSortKey = sortKeys.pop();
                angular.forEach(controller.gridApi.grid.columns, function (column) {
                    if (typeof column.sort.priority !== 'undefined' && column.name !== latestSortKey) {
                        column.sort = {};
                    }
                });
            }

            angular.forEach(controller.gridApi.grid.columns, function (column) {
                filterColumn = column.filters[0].column ? column.filters[0].column : column.field;
                if (column.field === 'createdAt') {
                    if (
                        typeof column.filters !== 'undefined' &&
                        typeof column.filters[0].term !== 'undefined' &&
                        typeof column.filters[1].term !== 'undefined'
                    ) {
                        controller.paginationOptions['filter[' + filterColumn + ']'] = {
                            startDate: column.filters[0].term && isNaN(column.filters[0].term) ?
                                column.filters[0].term.format('x') : column.filters[0].term,
                            endDate: column.filters[1].term && isNaN(column.filters[1].term) ?
                                column.filters[1].term.format('x') : column.filters[1].term
                        };
                    } else {
                        controller.paginationOptions['filter[' + filterColumn + ']'] = null;
                    }
                } else if (typeof column.filters !== 'undefined' &&
                    typeof column.filters[0].term !== 'undefined' &&
                    column.filters[0].term !== ''
                ) {
                    if (column.filter.type && column.filter.type === uiGridConstants.filter.SELECT) {
                        var filters = [];
                        angular.forEach(column.filters[0].term, function (term) {
                            filters.push(term);
                        });
                        if (filters.length) {
                            controller.paginationOptions['filter[' + filterColumn + '][]'] = filters;
                        }
                    } else {
                        controller.paginationOptions['filter[' + filterColumn + ']'] = column.filters[0].term;
                    }
                }
                if (typeof column.sort.direction !== 'undefined') {
                    sortColumn = column.sort.column ? column.sort.column : column.field;
                    controller.paginationOptions['sorting[' + sortColumn + ']'] = column.sort.direction;
                }
            });

            if (controller.paginationOptions["filter[createdAt]"] === null) {
                controller.paginationOptions["filter[createdAt]"] = controller.filters.createdAtRange;
            }
            if (controller.paginationOptions["filter[createdAt]"].startDate === null) {
                controller.paginationOptions["filter[createdAt]"].endDate = null;
                controller.filters.createdAtRange = {
                    startDate: null,
                    endDate: null
                };
            }

            GridService.storeColumnsFilters(
                gridNamespaces.filters.institutionUsersMessagesCenter,
                controller.paginationOptions
            );

            if (angular.isDefined($scope.filterTimeout)) {
                $timeout.cancel($scope.filterTimeout);
            }
            $scope.filterTimeout = $timeout(function () {
                controller.searching = true;
                controller.getPage().then(function () {
                    controller.searching = false;
                });
                filterChangesInProgress = false;
            }, constants.datagridInputDelay);
        };

        controller.handleGridVisibleChanges = function () {
            var columnsVisibility = GridService.getColumnsVisibility(
                gridNamespaces.visibility.institutionUsersMessagesCenter
                ),
                reload = false;

            if (columnsVisibility) {
                angular.forEach(controller.grid.options.columnDefs, function (column, key) {
                    if (columnsVisibility[column.field] === true &&
                        column.visible === false
                    ) {
                        if (typeof  controller.grid.options.columnDefs[key].filter === 'undefined') {
                            controller.grid.options.columnDefs[key].filter = {};
                        }
                        if (typeof  controller.grid.options.columnDefs[key].filter !== 'undefined' &&
                            controller.grid.options.columnDefs[key].filter.hasOwnProperty('term') &&
                            controller.grid.options.columnDefs[key].filter.term) {
                            reload = true;
                        }
                        controller.grid.options.columnDefs[key].filter.term = null;
                        controller.paginationOptions['filter[' + column.field + ']'] = null;
                        if(column.field === 'source.question') {
                            controller.paginationOptions['filter[source.question][]'] = null;
                        }
                        if(column.field === 'source.ranking') {
                            controller.paginationOptions['filter[source.ranking][]'] = null;
                        }
                        if (column.field === 'createdAt') {
                            delete controller.grid.options.columnDefs[key].filters[0].term;
                            controller.filters.createdAtRange = {
                                startDate: null,
                                endDate: null
                            };
                        }
                    }
                });
            }
            if (controller.grid.options) {
                controller.columnsVisibility = GridService.getGridColumnsVisibility(controller.grid.options.columnDefs);
                if(!controller.selectedRowId){
                    controller.saveStateVisibility();
                }
            }
            if (reload) {
                controller.getPage();
            }
        };

        controller.saveStateVisibility = function () {
            var visibility = angular.copy(controller.grid.options.columnDefs);
            GridService.storeColumnsVisibility(
                gridNamespaces.visibility.institutionUsersMessagesCenter,
                GridService.getGridColumnsVisibility(visibility)
            );
        };

        /**
         * Populates input text field for date field
         *
         * @param {string} filterName
         * @param {mixed} defaultValue
         */
        controller.applyDateFilter = function (filterName, defaultValue) {
            var filter = GridService.getFilterByField(controller.paginationOptions, filterName, defaultValue);
            if (angular.isDefined(filter) &&
                filter !== null
            ) {
                if (angular.isDefined(filter.startDate) &&
                    angular.isDefined(filter.endDate)
                ) {
                    if (filter.startDate !== null &&
                        filter.startDate !== null
                    ) {
                        controller.defaultFilters[filterName + 'Range'] = {
                            startDate: parseInt(filter.startDate, 10),
                            endDate: parseInt(filter.endDate, 10)
                        };
                    } else {
                        controller.defaultFilters[filterName + 'Range'] = {
                            startDate: null,
                            endDate: null
                        };
                    }
                }
            }
        };

        controller.getPage = function () {
            controller.loading = true;
            if (!controller.rankingsList.length || !controller.questionsList.length ||
                controller.institutionCoreId === null
            ) {
                return false;
            }
            controller.paginationOptions['filter[institutionCoreId]'] = controller.institutionCoreId;
            controller.isDatagridReloading = true;
            var options = angular.copy(controller.paginationOptions),
                terms;
            if (options && options['filter[source.ranking][]']) {
                terms = [];
                angular.forEach(options['filter[source.ranking][]'], function (item) {
                    terms.push(item.value);
                });
                options['filter[source.ranking][]'] = terms;
            }
            if (options && options['filter[source.question][]']) {
                terms = [];
                angular.forEach(options['filter[source.question][]'], function (item) {
                    terms.push(item.value);
                });
                options['filter[source.question][]'] = terms;
            }

            return InstitutionUsersMessagesCenterService.getData(
                angular.merge({}, options, controller.defaultPaginationOptions)
            ).then(function (response) {
                controller.grid.options.totalItems = response.totalMatching;
                controller.grid.options.data = response.results;
                controller.grid.options.minRowsToShow = response.totalFiltered;
                controller.isDatagridReloading = false;
                controller.isRendered = true;
                controller.initialLoaded = true;
                controller.loading = false;
            });
        };

        controller.initLists = function () {
            ProgressReportByRankingService.getRankingsList(true).then(function (list) {
                angular.forEach(list, function (item) {
                    controller.rankingsList.push({
                        value: item.value,
                        label: item.label
                    });
                });
                controller.getPage();
            });

            RankingsService.getQuestions().then(function (list) {
                angular.forEach(list, function (item) {
                    controller.questionsList.push({
                        value: item.handle,
                        label: item.name
                    });
                });
                controller.getPage();
            });
        };

        controller.coreIdWatch = function (institutionCoreId) {
            controller.selectedRowId = null;
            controller.activeMessage = null;
            controller.state = 'listMessages';
            if (institutionCoreId) {
                if (controller.gridApi.pagination.getPage() > 1) {
                    controller.gridApi.pagination.seek(1);
                }
                controller.institutionCoreId = institutionCoreId;
                controller.getPage();
            }

        };

        controller.initWatches = function () {
            WatchService.create($scope, InstitutionFactory.getCoreId, controller.coreIdWatch);
            WatchService.create($scope, 'InstitutionUsersMessagesCenterController.activeMessage', function (activeMessage) {
                if (!activeMessage) {
                    controller.handleDatagridRowClick({});
                }
            });
        };

        controller.closeMessage = function () {
            controller.activeMessage = null;
        };

        controller.init = function () {
            controller.initWatches();
            controller.initDataGridOptions();
            controller.initLists();
        };

        controller.init();
    }

}(window.angular));

(function(angular) {
    "use strict";

    angular
        .module('qsHub.messages')
        .service('InstitutionUsersMessagesCenterService', [
            '$resource',
            '$log',
            'constants',
            'RequestsQueueService',
            InstitutionUsersMessagesCenter
        ]);

    function InstitutionUsersMessagesCenter (
        $resource,
        $log,
        constants,
        RequestsQueueService
    ) {
        var service = {};

        /**
         * Get answers list API endpoint.
         *
         * @returns {$resource}
         */
        service.getModel = function () {
              return $resource(constants.api.messages.url + '/v1/list', {}, {
                get: {
                    isArray: false,
                    cancellable : true
                }
            });
        };

        service.getData = function (filters) {
            if (constants.dev) {
                var startTime = new Date().getTime(), endTime;
            }

            // cancel currently running requests
            RequestsQueueService.cancelAll('getAllInstitutionUserMessages');
            var hasSorting = false,
                 allowedFilters = [
                     'limit',
                     'page'
                 ];
            if (filters) {
                for (var key in filters) {
                    if (key.indexOf('sorting') !== -1) {
                        hasSorting = true;
                        break;
                    } else if (key.indexOf('filter') !== 0 && allowedFilters.indexOf(key) === -1) {
                        delete filters[key];
                    }
                }
            }
            if (!hasSorting) {
               filters['sorting[modifiedAt]'] = 'desc';
            }
            var Api = service.getModel().get(filters);

            // add new request to the queue
            RequestsQueueService.add(Api, 'getAllInstitutionUserMessages');

            return Api.$promise.then(function (data) {
                if (constants.dev) {
                    endTime = new Date().getTime();
                    $log.log('success, got data: ', data, 'in ' + (endTime - startTime) + ' ms');
                }

                // cancel currently running requests
                RequestsQueueService.cancelAll('getAllInstitutionUserMessages');
                return data;
            }, function (error) {
                // log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return [];
            });
           
        };

        return service;
    }

}(window.angular));

(function(angular){
  'use strict';

  angular.module('qsHub.messages').component('shareButton', {
    controller: 'Messages.ShareButtonController',
    bindings: {
      messageSentHandler: '&',
      parentMessage: '=',
      thisMessage: '=',
      users: '='
    },
    bindToController: true,
    templateUrl: '/scripts/modules/messages/components/shareButton/shareButtonView.html'
  });

}(window.angular));

(function (angular) {
    "use strict";

    angular.module('qsHub.messages').controller('Messages.ShareButtonController', [
        '$scope',
        'MessagesService',
        'NotifierFactory',
        'WatchService',
        ShareButtonController
    ]);

    function ShareButtonController($scope, MessagesService, NotifierFactory, WatchService) {
        var vm = this, firstRun = true;

        vm.selectedUsers = [];
        vm.isOpen = false;

        vm.sendShare = sendShare;

        function init() {
            WatchService.create($scope, '$ctrl.parentMessage', handleMessageChange);
        }

        function handleMessageChange() {
            if (firstRun) {
                firstRun = false;
            } else {
                vm.selectedUsers = [];
            }
        }


        function sendShare() {
            if (vm.parentMessage.id === vm.thisMessage.id) {
                //if parent id and this id are the same its the original message
                MessagesService.shareMessage(vm.parentMessage.id, {to: vm.selectedUsers}).then(handleShareMessage);
            } else {
                //if ids dont match it must be a reply
                MessagesService.shareReply(vm.parentMessage.id, vm.thisMessage.id, {to: vm.selectedUsers}).then(handleShareMessage);
            }
        }

        function handleShareMessage(data) {
            if (data.result === true) {
                NotifierFactory.show('success', 'Message Shared');
                vm.isOpen = false;
                vm.selectedUsers = [];
                if (vm.messageSentHandler) {
                    vm.messageSentHandler();
                }
            } else {
                NotifierFactory.show('error', data.error || 'Failed to share message', 'Error');
            }
        }

        vm.$onInit = init;
    }

}(window.angular));

(function(angular){
    "use strict";

    var oldMessage = {
        bindings: {
            message: "=",
            qsUserOptions: "=",
            parentMessage: "=",
            messageSentHandler: "&",
            state: '='
        },
        bindToController: true,
        templateUrl: '/scripts/modules/messages/components/singleMessage/oldMessage/oldMessageView.html'
    };

    angular.module('qsHub.messages').component('oldMessage', oldMessage);

})(window.angular);
(function (angular) {
    'use strict';

    angular
        .module('qsHub.qa')
        .config(config, [
            '$stateProvider',
            'errors'
        ]);

    function config(
        $stateProvider,
        errors
    ) {
        $stateProvider
            .state('qa', {
                abstract: true,
                url: '/qa',
                templateUrl: '/scripts/layouts/main.html',
                data: {
                    label: 'QA'
                }
            })
            .state('qa.publish-config', {
                url: '/publish-config',
                templateUrl: '/scripts/modules/qa/components/publishConfig/publishConfigView.html',
                controller: 'Qa.PublishConfigController',
                controllerAs: 'PublishConfigController',
                data: {
                    pageTitle: 'Publish Config',
                    label: 'Publish Config'
                },
                resolve: {
                    security: [
                        '$q', 'UserFactory', 'AuthenticationService', 'constants',
                        function ($q, UserFactory, AuthenticationService, constants) {
                            UserFactory.setData(AuthenticationService.getUserData());
                            if (UserFactory.isClient()) {
                                return $q.reject(errors[401]);
                            } else if (constants.env === 'live') {
                                return $q.reject(errors[403]);
                            }
                        }
                    ]
                }
            });
    }

}(window.angular));

(function(angular) {
    "use strict";

    angular
        .module('qsHub.qa')
        .controller('Qa.PublishConfigController', [
            'constants',
            'PublishConfigService',
            Controller
        ]);

    function Controller(
        constants,
        PublishConfigService
    ) {
        var controller = this;

        controller.fetchInProgress = true;
        controller.backend = {};
        controller.ui = {
            tu : constants.drupal.tu.url,
            tuChina: constants.drupal.tuChina.url,
            tm : constants.drupal.tm.url
        };

        PublishConfigService.get().then(function (data) {
            controller.backend.profiles = {
                tu : data.profiles.tu,
                tuChina : data.profiles.tuChina,
                tm : data.profiles.tm
            };
            getRankingsPublishConfig();
        });

        function getRankingsPublishConfig() {
            PublishConfigService.getRankingsPublishConfig().then(function (data) {
                controller.backend.rankings = {
                    tu : data.rankings.tu,
                    tm : data.rankings.tm
                };
                controller.fetchInProgress = false;
            });
        }
        
    }

}(window.angular));

(function(angular) {
    "use strict";

    angular
        .module('qsHub.qa')
        .service('PublishConfigService', [
            '$resource',
            'constants',
            'RequestsQueueService',
            Service
        ]);

    function Service (
        $resource,
        constants,
        RequestsQueueService
    ) {
        var service = {};

        function getModel() {
              return $resource(constants.api.institutions.url + '/v1/config/publish', {}, {
                get: {
                    isArray: false,
                    cancellable : true
                }
            });
        }

        function getRankingsModel() {
            return $resource(constants.api.rankings.url + '/v1/config/publish', {}, {
              get: {
                  isArray: false,
                  cancellable : true
              }
          });
      }

        service.get = function () {
            var key = 'PublishConfig:get';

            // cancel currently running requests
            RequestsQueueService.cancelAll(key);
            var Api = getModel().get();

            // add new request to the queue
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                return data;
            }, function () {
                return [];
            });
        };

        service.getRankingsPublishConfig = function () {
            var key = 'RankingsPublishConfig:get';

            // cancel currently running requests
            RequestsQueueService.cancelAll(key);
            var Api = getRankingsModel().get();

            // add new request to the queue
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                return data;
            }, function () {
                return [];
            });
        };

        return service;
    }

}(window.angular));

(function(angular) {
    'use strict';

    angular
        .module('qsHub.members-db')
        .config(config, [
            '$stateProvider',
            'errors'
        ]);

    function config(
        $stateProvider,
        errors
    ) {
        $stateProvider
            .state('staff.members-db', {
                abstract: true,
                template: '<div ui-view></div>',
                data: {
                    label: 'Members DB'
                }
            }).state('staff.members-db.leads-export', {
                url: '/members-db/leads-export',
                templateUrl: '/scripts/modules/membersDb/components/leadsExport/leadsExportView.html',
                controller: 'MembersDb.LeadsExportController',
                controllerAs: 'LeadsExportController',
                data: {
                    pageTitle: 'School Leads Export',
                    label: 'School Leads Export',
                    name: 'School Leads Export'
                },
                resolve: {
                    security: [
                        '$q', 'UserFactory', 'AuthenticationService',
                        function ($q, UserFactory, AuthenticationService) {
                            UserFactory.setData(AuthenticationService.getUserData());
                            if (!UserFactory.hasData()) {
                                return $q.reject(errors[401]);
                            } else if (!UserFactory.hasLeadsExportAccess()) {
                                return $q.reject(errors[403]);
                            }
                        }
                    ]
                }
            }).state('staff.members-db.matching-tool-leads-export', {
                url: '/members-db/matching-tool-leads-export',
                templateUrl: '/scripts/modules/membersDb/components/matchingToolLeadsExport/matchingToolLeadsExportView.html',
                controller: 'MembersDb.MatchingToolLeadsExportController',
                controllerAs: 'MatchingToolLeadsExportController',
                data: {
                    pageTitle: 'Matching Tool Leads Export (Until 11/02/2021)',
                    label: 'Matching Tool Leads Export (Until 11/02/2021)',
                    name: 'Matching Tool Leads Export (Until 11/02/2021)'
                },
                resolve: {
                    security: [
                        '$q', 'UserFactory', 'AuthenticationService',
                        function ($q, UserFactory, AuthenticationService) {
                            UserFactory.setData(AuthenticationService.getUserData());
                            if (!UserFactory.hasData()) {
                                return $q.reject(errors[401]);
                            } else if (!UserFactory.hasLeadsExportAccess()) {
                                return $q.reject(errors[403]);
                            }
                        }
                    ]
                }
            }).state('staff.members-db.matching-tool-leads-export-new', {
                url: '/members-db/matching-tool-leads-export-new',
                templateUrl: '/scripts/modules/membersDb/components/matchingToolLeadsExportDynamoDB/matchingToolLeadsExportDynamoDBView.html',
                controller: 'MembersDb.MatchingToolLeadsExportDynamoDBController',
                controllerAs: 'MatchingToolLeadsExportDynamoDBController',
                data: {
                    pageTitle: 'Matching Tool Leads Export (From 12/07/2021)',
                    label: 'Matching Tool Leads Export (From 12/07/2021)',
                    name: 'Matching Tool Leads Export (From 12/07/2021)'
                },
                resolve: {
                    security: [
                        '$q', 'UserFactory', 'AuthenticationService',
                        function ($q, UserFactory, AuthenticationService) {
                            UserFactory.setData(AuthenticationService.getUserData());
                            if (!UserFactory.hasData()) {
                                return $q.reject(errors[401]);
                            } else if (!UserFactory.hasLeadsExportAccess()) {
                                return $q.reject(errors[403]);
                            }
                        }
                    ]
                }
            }).state('staff.members-db.student-guide-export', {
                url: '/members-db/student-guide-export',
                templateUrl: '/scripts/modules/membersDb/components/studentGuideExport/StudentGuideExportView.html',
                controller: 'MembersDb.StudentGuideExportController',
                controllerAs: 'StudentGuideExportController',
                data: {
                    pageTitle: 'Student Guide Export',
                    label: 'Student Guide Export',
                    name: 'Student Guide Export'
                },
                resolve: {
                    security: [
                        '$q', 'UserFactory', 'AuthenticationService',
                        function ($q, UserFactory, AuthenticationService) {
                            UserFactory.setData(AuthenticationService.getUserData());
                            if (!UserFactory.hasData()) {
                                return $q.reject(errors[401]);
                            } else if (!UserFactory.hasLeadsExportAccess()) {
                                return $q.reject(errors[403]);
                            }
                        }
                    ]
                }
            });
    }
}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.members-db')
        .controller('MembersDb.LeadsExportController', [
            '$scope',
            '$timeout',
            'InstitutionsListService',
            'LeadsExportService',
            'TimeService',
            'uiGridConstants',
            'UiGridService',
            'uiGridExporterService',
            'uiGridExporterConstants',
            'DateRangeFixerService',
            Controller
        ]);

    function Controller(
        $scope,
        $timeout,
        InstitutionsListService,
        LeadsExportService,
        TimeService,
        uiGridConstants,
        GridService,
        uiGridExporterService,
        uiGridExporterConstants,
        DateRangeFixerService
    ) {
        var controller = this,
            gridApiInstance = null,
            columnsChecked = false,
            subscriptionStartDateFilter = null,
            defaultPaginationOptions = {
                page: 1,
                limit: 25
            };

        controller.institutionsDropdownList = [];
        controller.searchInProgress = false;
        controller.selectedWebsite = null;
        controller.isRowsRendered = false;
        controller.selectedInstitution = null;
        controller.fetchInProgress = false;
        controller.noResults = false;
        controller.retryAttempts = 0;

        controller.fetchEnabled = function () {
            return  (controller.days ||controller.dateFilter.startDate &&  controller.dateFilter.endDate) &&
                controller.selectedWebsite && controller.selectedWebsite.value &&
                !controller.fetchInProgress;
        };

        controller.handleSearchInstitution = function (searchPhrase) {
            controller.searchInProgress = true;
            if (!searchPhrase || searchPhrase.indexOf('/') !== -1) {
                controller.institutionsDropdownList = [];
                controller.searchInProgress = false;
                return;
            }

            InstitutionsListService.searchInstitutions(searchPhrase).then(function (data) {
                controller.institutionsDropdownList = data;
                controller.searchInProgress = false;
            });
        };

        controller.handleFetch = function (isPaginationChanged) {
            if (!controller.fetchEnabled()) {
                return false;
            }
            controller.fetchInProgress = true;
            controller.noResults = false;
            if (controller.gridOptions &&  controller.gridOptions.data) {
                if (!isPaginationChanged){
                    controller.gridOptions.data = null;
                    columnsChecked = false;
                }
            }
            LeadsExportService.search(
                controller.selectedInstitution ? controller.selectedInstitution.coreId : null,
                controller.selectedWebsite.value,
                controller.days,
                controller.dateFilter.endDate,
                controller.dateFilter.startDate,
                defaultPaginationOptions
            ).then(function (data) {
                controller.fetchInProgress = false;
                if (data && data.results && data.results.length > 0) {
                    if(controller.gridOptions) {
                        controller.gridOptions.minRowsToShow = data.results.length;
                        controller.gridOptions.totalItems = data.pages.totalResults;
                    }
                    
                    initDatagrid(data);
                }
                else {
                    controller.noResults = true;
                }
            });
        };

        function initDatagrid (data) {

            controller.gridOptions = {
                data: data.results,
                minRowsToShow: data.results.length,
                totalItems: data.pages.totalResults,
                appScopeProvider: controller,
                enableSorting: false,
                useExternalFiltering: false,
                enableGridMenu: true,
                showGridFooter: true,
                enableFiltering: true,
                enableColumnResize: true,
                enableFullRowSelection: false,
                enableRowSelection: false,
                multiSelect: false,
                enableRowHeaderSelection: false,
                exporterMenuCsv: false,
                exporterMenuAllData: false,
                exporterMenuSelectedData: false,
                exporterMenuPdf: false,
                paginationPageSizes: [25, 50, 100, 200],
                paginationPageSize: 25,
                useExternalPagination: true,
                gridMenuCustomItems: [
                    {
                        title: ' Export visible data as csv',
                        action: exportVisible,
                        order: 209
                    },
                    {
                        title: ' Export all data as csv',
                        action: exportAll,
                        order: 210
                    }
                ],
                onRegisterApi: function (gridApi) {
                    gridApiInstance = gridApi;
                    gridApi.pagination.on.paginationChanged($scope, function (newPage, limit) {
                        defaultPaginationOptions.page = newPage;
                        defaultPaginationOptions.limit = limit;
                        controller.handleFetch(true);
                    });
                    gridApi.core.on.rowsRendered($scope, function () {
                        if (!columnsChecked) {
                            angular.forEach(controller.gridOptions.columnDefs, function (item) {
                                item.visible = ['firstname', 'lastname', 'gender', 'country_residence', 'date_of_birth', 'core_id_int'].indexOf(item.name) !== -1;
                                if (item.name === 'firstname') {
                                    item.displayName = 'First Name';
                                }
                                if (item.name === 'lastname') {
                                    item.displayName = 'Last Name'; 
                                }
                                if (item.name === 'gender') {
                                    item.filterHeaderTemplate = '/scripts/shared/ui-grid/templates/selectFilterHeaderTemplate.html';
                                    item.filter =  {
                                        column: 'gender',
                                        type: uiGridConstants.filter.SELECT,
                                        selectOptions: [
                                            { value: 'Male', label: 'Male' },
                                            { value: 'Female', label: 'Female' }
                                        ],
                                    }; 
                                   gridApiInstance.grid.columns[3].filters[0].condition = uiGridConstants.filter.EXACT;
                                   gridApiInstance.grid.columns[3].filters[0].bindToField = true;
                                }
                                if (item.name === 'intended_study_level_sta') {
                                    item.cellFilter = 'strReplace: "_": " " | capitalize';
                                }
                                if (item.name === 'desired_study_level' || item.name === 'institution_name') {
                                    item.visible = false;
                                    item.enableHiding = false;
                                }
                                if (item.name === 'core_id_int') {
                                    item.displayName = 'Core Id';
                                }
                            });
                            controller.gridOptions.columnDefs.push({
                                displayName: 'Desired Study Level',
                                field: 'desired_study_level',
                                cellFilter: 'strReplace: "_": " " | capitalize',
                                visible: false
                            });
                            var institutionNameColumn = {
                                displayName: 'Institution Name',
                                field: 'institution_name',
                                visible: true
                            };
                            controller.gridOptions.columnDefs.splice(1, 0, institutionNameColumn);
                            gridApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
                            $timeout(function () {
                                columnsChecked = true;
                            },200);
                        }
                    });
                }
            };
            $timeout(function () {
                controller.isRowsRendered = true;
            });
        }

        function triggerExport (data) {
            var results = LeadsExportService.formatCsvResults(data);
            if (typeof results === 'undefined') {
                return false;
            }
            var gridApi = gridApiInstance,
                gridOptions = controller.gridOptions,
                exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                    gridApi.grid,
                    uiGridExporterConstants.VISIBLE
                );

            var exportData = GridService.getCsvData(gridApi.grid.columns, results);
            var csvContent = uiGridExporterService.formatAsCsv(
                exportColumnHeaders,
                exportData,
                gridOptions.exporterCsvColumnSeparator
            );
            uiGridExporterService.downloadFile(
                'leads-export' + TimeService.now() + '.csv',
                csvContent,
                gridOptions.exporterOlderExcelCompatibility
            );
        }

        function exportVisible() {
            var results = gridApiInstance.grid.renderContainers.body.renderedRows;
            results = LeadsExportService.formatVisibleCsvResults(results);
            if (typeof results === 'undefined') {
                return false;
            }
            var gridApi = gridApiInstance,
                gridOptions = controller.gridOptions,
                exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                    gridApi.grid,
                    uiGridExporterConstants.VISIBLE
                );

            var exportData = GridService.getCsvData(gridApi.grid.columns, results);
            var csvContent = uiGridExporterService.formatAsCsv(
                exportColumnHeaders,
                exportData,
                gridOptions.exporterCsvColumnSeparator
            );
            uiGridExporterService.downloadFile(
                'leads-export' + TimeService.now() + '.csv',
                csvContent,
                gridOptions.exporterOlderExcelCompatibility
            );
        }

        function exportAll() {
            LeadsExportService.search(
                controller.selectedInstitution ? controller.selectedInstitution.coreId : null,
                controller.selectedWebsite.value,
                controller.days,
                controller.dateFilter.endDate,
                controller.dateFilter.startDate, {
                    page: 1,
                    limit: 500
                }
            ).then(function (data) {
                if (data && data.results && data.results.length > 0) {
                    var exportPaginationOptions = {
                        page: 1,
                        limit: 500
                    };
                    var totalPages = parseInt(data.pages.totalPages) - 1,
                    results = data.results;
                    if (totalPages > 0) {
                        var remainingPagesToExport = totalPages,
                        pageNumber =2;
                        exportCallback(remainingPagesToExport, exportPaginationOptions, results, pageNumber);
                    } else {
                        triggerExport(results);
                    }
                }
            });
        }

        function exportCallback(remainingPagesToExport, exportPaginationOptions, results, pageNumber) {
            exportPaginationOptions.page = pageNumber;
            LeadsExportService.search(
                controller.selectedInstitution ? controller.selectedInstitution.coreId : null,
                controller.selectedWebsite.value,
                controller.days,
                controller.dateFilter.endDate,
                controller.dateFilter.startDate,
                exportPaginationOptions, true
            ).then(function (data) {
                if (!data.results && controller.retryAttempts <= 3) {
                    controller.retryAttempts++;
                    exportCallback(remainingPagesToExport, exportPaginationOptions, results, pageNumber);
                } else {
                    controller.retryAttempts = 0;
                    remainingPagesToExport--;
                    pageNumber++;
                    if (data.results) {
                        results = results.concat(data.results);
                    }
                    if (remainingPagesToExport !== 0) {
                        exportCallback(remainingPagesToExport, exportPaginationOptions, results, pageNumber);
                    }
                }

                if (remainingPagesToExport === 0) {
                    triggerExport(results);
                }
            });
        }

        controller.showLoadBar = function () {
            return controller.gridOptions && !columnsChecked;
        };

        controller.datePicker = {
            date: {
                startDate: null,
                endDate: null
            },
            options: {
                ranges: {
                    'in last 6 months': [TimeService.sub(6, 'months'), TimeService.now()],
                    'in last 12 months': [TimeService.sub(12, 'months'), TimeService.now()],
                    'this year': [TimeService.startOfYear(), TimeService.getEndOfToday()]
                },
                eventHandlers: {
                    'apply.daterangepicker': function (ev) {
                        controller.handleDateRangePickerApplyClick(ev);
                    }
                },
                opens: 'center',
                alwaysShowCalendars: true
            }
        };

        controller.dateFilter = {
            startDate: null,
            endDate: null,
        };

        controller.handleDateRangePickerApplyClick = function (event) {
            if (DateRangeFixerService.sameDayAndNoModel(event, controller.dateFilter, subscriptionStartDateFilter) || 
                typeof  event.model.startDate === 'undefined') {
                    event.model.startDate = subscriptionStartDateFilter.startDate;
                    event.model.endDate = subscriptionStartDateFilter.endDate;
                    controller.dateFilter = {
                        startDate: subscriptionStartDateFilter.startDate,
                        endDate: subscriptionStartDateFilter.endDate
                    };
            } else if (DateRangeFixerService.todayRangeAndNoModel(event, subscriptionStartDateFilter)) {
                event.model.startDate = TimeService.getStartOfToday();
                event.model.endDate = TimeService.getEndOfToday();
                controller.dateFilter = {
                    startDate: event.model.startDate.toISOString(),
                    endDate: event.model.endDate.toISOString()
                };
            }
            controller.dateFilter.startDate = TimeService.getInUnix(TimeService.getMomentObject(controller.dateFilter.startDate), true);
            subscriptionStartDateFilter = angular.copy(controller.dateFilter);
        };

        function init() {
            LeadsExportService.getWebsites().then(function (data) {
                controller.websitesList = data;
            });
        }

        init();
    }

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.stars')
        .service('LeadsExportService', [
            '$q',
            '$resource',
            'constants',
            'RequestsQueueService',
            'TimeService',
            Service
        ]);

    function Service(
        $q,
        $resource,
        constants,
        RequestsQueueService,
        TimeService
    ) {
        var service = {};

        function getModel() {
            return $resource(constants.api.usersPermissions.url, {}, {
                search: {
                    method: 'GET',
                    url:  constants.api.usersPermissions.url + '/v1/members-db/leads-export',
                    isArray: false,
                    cancellable: true
                },
                matchingToolLeadsExportSearch: {
                    method: 'GET',
                    url: constants.api.usersPermissions.url + '/v1/members-db/matching-tool-leads-export',
                    isArray: false,
                    cancellable: true
                },
                matchingToolLeadsExportDynamoDbSearch: {
                    method: 'GET',
                    url: constants.api.usersPermissions.url + '/v1/members-db/matching-tool-leads-export-dynamoDb',
                    isArray: false,
                    cancellable: true
                }
            });
        }

        service.getWebsites = function () {
            var deferred = $q.defer();
            deferred.resolve(
                [
                    {
                        value: 1,
                        label: 'TopUniversities.com'
                    },
                    {
                        value: 2,
                        label: 'TopMba.com'
                    }
                ]
            );

            return deferred.promise;
        };

        service.search = function (institutionCoreId, website, days, to, from, paginationOptions, isExport) {
            var key = 'LeadsExportService::search',
                Api;


            if (!website) {
                throw 'Missing Required';
            }
            if(!isExport){
            RequestsQueueService.cancelAll(key);
            }
            
            Api = getModel().search({
                institutionCoreId: institutionCoreId,
                website: website,
                days: days,
                to: TimeService.format(to, 'YYYY-MM-DD'),
                from: TimeService.format(from, 'YYYY-MM-DD'),
                page : paginationOptions.page,
                limit : paginationOptions.limit
            });
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                return data;
            }, function () {
                return false;
            });
        };

        service.formatCsvResults = function (data) {
            data = angular.copy(data);
            angular.forEach(data, function (column, dataKey) {
                angular.forEach(column, function (item, key) {
                    if (data[dataKey][key] && (key === 'intended_study_level_sta' || key === 'desired_study_level' ||
                        key === 'grad_study_mode')) {
                            data[dataKey][key] = data[dataKey][key].replace('_', ' ');
                    } else if (key === 'datecreated_mem' || key === 'datecreated_int' ||
                        key === 'datecreated_mtool' || key === 'datecreated_mem' ||
                        key === 'date_of_birth') {
                            data[dataKey][key] = TimeService.format(item, "MMM DD, YYYY");
                    }
                });
            });

            return data;
        };

        service.formatVisibleCsvResults = function (data) {
            var filteredRows = [];
             angular.forEach(data, function (row, key) {
                 filteredRows.push(row.entity);
             });

             return  service.formatCsvResults(filteredRows);
         };

        service.matchingToolLeadsExportSearch = function (institutionCoreId, days, to, from, paginationOptions, isExport) {
            var key = 'LeadsExportService::matchingToolLeadsExportDynamoDbSearch',
                Api;
            if (!isExport) {
                RequestsQueueService.cancelAll(key);
            }

            Api = getModel().matchingToolLeadsExportDynamoDbSearch({
                institutionCoreId: institutionCoreId,
                days: days,
                to: TimeService.format(to, 'YYYY-MM-DD'),
                from: TimeService.format(from, 'YYYY-MM-DD'),
                page: paginationOptions.page,
                limit: paginationOptions.limit,
                isOld: true
            });
            RequestsQueueService.add(Api, key);
            return Api.$promise.then(function (data) {
                return data;
            }, function () {
                return false;
            });
        };

        service.matchingToolLeadsExportDynamoDbSearch = function (institutionCoreId, days, to, from, paginationOptions, isExport) {
            var key = 'LeadsExportService::matchingToolLeadsExportDynamoDbSearch',
                Api;
            if (!isExport) {
                RequestsQueueService.cancelAll(key);
            }

            Api = getModel().matchingToolLeadsExportDynamoDbSearch({
                institutionCoreId: institutionCoreId,
                days: days,
                to: TimeService.format(to, 'YYYY-MM-DD'),
                from: TimeService.format(from, 'YYYY-MM-DD'),
                page: paginationOptions.page,
                limit: paginationOptions.limit
            });
            RequestsQueueService.add(Api, key);
            return Api.$promise.then(function (data) {
                return data;
            }, function () {
                return false;
            });
        };

        return service;
        }

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.members-db')
        .controller('MembersDb.MatchingToolLeadsExportController', [
            '$scope',
            '$timeout',
            'InstitutionsListService',
            'LeadsExportService',
            'TimeService',
            'uiGridConstants',
            'UiGridService',
            'uiGridExporterService',
            'uiGridExporterConstants',
            'DateRangeFixerService',
            Controller
        ]);

    function Controller(
        $scope,
        $timeout,
        InstitutionsListService,
        LeadsExportService,
        TimeService,
        uiGridConstants,
        GridService,
        uiGridExporterService,
        uiGridExporterConstants,
        DateRangeFixerService
    ) {
        var controller = this,
            gridApiInstance = null,
            columnsChecked = false,
            subscriptionStartDateFilter = null,
            defaultPaginationOptions = {
                page: 1,
                limit: 25
            };
        controller.institutionsDropdownList = [];
        controller.searchInProgress = false;
        controller.isRowsRendered = false;
        controller.selectedInstitution = null;
        controller.fetchInProgress = false;
        controller.noResults = false;
        controller.retryAttempts = 0;
        controller.fetchEnabled = function () {
            return (controller.days || controller.dateFilter.startDate && controller.dateFilter.endDate) &&
                !controller.fetchInProgress;
        };
        controller.handleSearchInstitution = function (searchPhrase) {
            controller.searchInProgress = true;
            if (!searchPhrase || searchPhrase.indexOf('/') !== -1) {
                controller.institutionsDropdownList = [];
                controller.searchInProgress = false;
                return;
            }
            InstitutionsListService.searchInstitutions(searchPhrase).then(function (data) {
                controller.institutionsDropdownList = data;
                controller.searchInProgress = false;
            });
        };

        function triggerExport (data) {
            var results = LeadsExportService.formatCsvResults(data);
            if (typeof results === 'undefined') {
                return false;
            }
            var gridApi = gridApiInstance,
                gridOptions = controller.gridOptions,
                exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                    gridApi.grid,
                    uiGridExporterConstants.VISIBLE
                );

            var exportData = GridService.getCsvData(gridApi.grid.columns, results);
            var csvContent = uiGridExporterService.formatAsCsv(
                exportColumnHeaders,
                exportData,
                gridOptions.exporterCsvColumnSeparator
            );
            uiGridExporterService.downloadFile(
                'leads-export' + TimeService.now() + '.csv',
                csvContent,
                gridOptions.exporterOlderExcelCompatibility
            );
        }
        controller.handleFetch = function (isPaginationChanged) {
            if (!controller.fetchEnabled()) {
                return false;
            }
            controller.noResults = false;
            controller.fetchInProgress = true;
            if (controller.gridOptions && controller.gridOptions.data) {
                if (!isPaginationChanged){
                    controller.gridOptions.data = null;
                    columnsChecked = false;
                }
            }
            var institutionCoreId = controller.selectedInstitution ? controller.selectedInstitution.coreId : null; 
            LeadsExportService.matchingToolLeadsExportSearch(
                institutionCoreId,
                controller.days,
                controller.dateFilter.endDate,
                controller.dateFilter.startDate,
                defaultPaginationOptions
            ).then(function (data) {
                controller.fetchInProgress = false;
                if (data && data.results && data.results.length > 0) {
                    if(controller.gridOptions) {
                        controller.gridOptions.minRowsToShow = data.results.length;
                        controller.gridOptions.totalItems = data.totalResults;
                    }
                    
                    initDataGrid(data);
                 }
                else {
                    controller.noResults = true;
                }
            });
        };

        function initDataGrid(data) {
            controller.gridOptions = {
                data: data.results,
                minRowsToShow: data.results.length,
                totalItems: data.totalResults,
                appScopeProvider: controller,
                enableSorting: false,
                useExternalFiltering: false,
                enableGridMenu: true,
                showGridFooter: true,
                enableFiltering: true,
                enableColumnResize: true,
                enableFullRowSelection: false,
                enableRowSelection: false,
                multiSelect: false,
                enableRowHeaderSelection: false,
                exporterMenuCsv: false,
                exporterMenuAllData: false,
                exporterMenuSelectedData: false,
                exporterMenuPdf: false,
                paginationPageSizes: [25, 50, 100, 200],
                paginationPageSize: 25,
                useExternalPagination: true,
                gridMenuCustomItems: [
                    {
                        title: ' Export visible data as csv',
                        action: exportVisible,
                        order: 209
                    },
                    {
                        title: ' Export all data as csv',
                        action: exportAll,
                        order: 210
                    }
                ],
                onRegisterApi: function (gridApi) {
                    gridApiInstance = gridApi;
                    gridApi.pagination.on.paginationChanged($scope, function (newPage, limit) {
                        defaultPaginationOptions.page = newPage;
                        defaultPaginationOptions.limit = limit;
                        controller.handleFetch(true);
                    });
                    gridApi.core.on.rowsRendered($scope, function () {
                        if (!columnsChecked) {
                            angular.forEach(controller.gridOptions.columnDefs, function (item) {
                                item.visible = ['core_id_mtool', 'program_id_mtool', 'program_name', 'program_link'].indexOf(item.name) !== -1;
                                if (item.name === 'core_id_mtool') {
                                    item.displayName = 'Core ID';
                                }
                                if (item.name === 'program_id_mtool') {
                                    item.displayName = 'Program ID';
                                }
                                if (item.name === 'program_name') {
                                    item.displayName = 'Program Name';
                                }
                                if (item.name === 'program_link') {
                                    item.displayName = 'Link';
                                }
                                if (item.name === 'lastname') {
                                    item.displayName = 'Last Name';
                                }
                                if (item.name === 'firstname') {
                                    item.displayName = 'First Name';
                                }
                                if (item.name === 'filters' || item.name === 'intended_study_level_sta' || item.name === 'institution_name') {
                                    item.visible = false;
                                    item.enableHiding = false;
                                }
                            });
                            controller.gridOptions.columnDefs.push({
                                displayName: 'Intended Study Level Sta',
                                field: 'intended_study_level_sta',
                                cellFilter: 'strReplace: "_": " " | capitalize',
                                visible: false
                            });
                            var institutionNameColumn = {
                                displayName: 'Institution Name',
                                field: 'institution_name',
                                visible: true
                            };
                            controller.gridOptions.columnDefs.splice(1, 0, institutionNameColumn);
                            gridApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
                            $timeout(function () {
                                columnsChecked = true;
                            },200);
                        }
                       
                    });
                }
            };
            $timeout(function() {
                controller.isRowsRendered = true;
            });
        }

        function exportVisible() {
            var results = gridApiInstance.grid.renderContainers.body.renderedRows;
            results = LeadsExportService.formatVisibleCsvResults(results);
            if (typeof results === 'undefined') {
                return false;
            }
            var gridApi = gridApiInstance,
                gridOptions = controller.gridOptions,
                exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                    gridApi.grid,
                    uiGridExporterConstants.VISIBLE
                );
            var exportData = GridService.getCsvData(gridApi.grid.columns, results);
            var csvContent = uiGridExporterService.formatAsCsv(
                exportColumnHeaders,
                exportData,
                gridOptions.exporterCsvColumnSeparator
            );
            uiGridExporterService.downloadFile(
                'matching-tool-leads-export' + TimeService.now() + '.csv',
                csvContent,
                gridOptions.exporterOlderExcelCompatibility
            );
        }

        function exportAll() {
            var institutionCoreId = controller.selectedInstitution ? controller.selectedInstitution.coreId : null; 
            LeadsExportService.matchingToolLeadsExportSearch(
                institutionCoreId,
                controller.days,
                controller.dateFilter.endDate,
                controller.dateFilter.startDate,{
                    page: 1,
                    limit: 500
                }, true
            ).then(function (data) {
                if (data && data.results && data.results.length > 0) {
                    var exportPaginationOptions = {
                        page: 1,
                        limit: 500
                    };
                    var results = data.results,
                     totalPages = parseInt(data.totalPages) - 1;
                    if (totalPages > 0) {
                        var remainingPagesToExport = totalPages,
                        pageNumber =2;
                        exportCallback(remainingPagesToExport, exportPaginationOptions, results, pageNumber);
                    } else {
                        triggerExport(results);
                    }
                }
            });
        }

        function exportCallback(remainingPagesToExport, exportPaginationOptions, results, pageNumber) {
            exportPaginationOptions.page = pageNumber;
            LeadsExportService.matchingToolLeadsExportSearch(
                controller.selectedInstitution ? controller.selectedInstitution.coreId : null,
                controller.days,
                controller.dateFilter.endDate,
                controller.dateFilter.startDate,
                exportPaginationOptions, true
            ).then(function (data) {
                if (!data.results && controller.retryAttempts <= 3) {
                    controller.retryAttempts++;
                    exportCallback(remainingPagesToExport, exportPaginationOptions, results, pageNumber);
                } else {
                    controller.retryAttempts = 0;
                    remainingPagesToExport--;
                    pageNumber++;
                    if (data.results) {
                        results = results.concat(data.results);
                    }
                    if (remainingPagesToExport !== 0) {
                        exportCallback(remainingPagesToExport, exportPaginationOptions, results, pageNumber);
                    }
                }

                if (remainingPagesToExport === 0) {
                    triggerExport(results);
                }
            });
        }


        // function exportAll() {
        //     var results = controller.gridOptions.data;
        //     results = LeadsExportService.formatCsvResults(results);
        //     if (typeof results === 'undefined') {
        //         return false;
        //     }
        //     var gridApi = gridApiInstance,
        //         gridOptions = controller.gridOptions,
        //         exportColumnHeaders = uiGridExporterService.getColumnHeaders(
        //             gridApi.grid,
        //             uiGridExporterConstants.VISIBLE
        //         );
        //     var exportData = GridService.getCsvData(gridApi.grid.columns, results);
        //     var csvContent = uiGridExporterService.formatAsCsv(
        //         exportColumnHeaders,
        //         exportData,
        //         gridOptions.exporterCsvColumnSeparator
        //     );
        //     uiGridExporterService.downloadFile(
        //         'matching-tool-leads-export' + TimeService.now() + '.csv',
        //         csvContent,
        //         gridOptions.exporterOlderExcelCompatibility
        //     );
        // }

        controller.showLoadBar = function () {
            return controller.gridOptions && !columnsChecked;
        };

        controller.datePicker = {
            date: {
                startDate: null,
                endDate: null
            },
            options: {
                ranges: {
                    'in last 6 months': [TimeService.sub(6, 'months'), TimeService.now()],
                    'in last 12 months': [TimeService.sub(12, 'months'), TimeService.now()],
                    'this year': [TimeService.startOfYear(), TimeService.getEndOfToday()]
                },
                eventHandlers: {
                    'apply.daterangepicker': function (ev) {
                        controller.handleDateRangePickerApplyClick(ev);
                    }
                },
                opens: 'center',
                alwaysShowCalendars: true,
                maxDate: new Date("02/11/2021")
            }
        };

        controller.dateFilter = {
            startDate: null,
            endDate: null
        };

        controller.handleDateRangePickerApplyClick = function (event) {
            if (DateRangeFixerService.sameDayAndNoModel(event, controller.dateFilter, subscriptionStartDateFilter) ||
                typeof  event.model.startDate === 'undefined') {
                event.model.startDate = subscriptionStartDateFilter.startDate;
                event.model.endDate = subscriptionStartDateFilter.endDate;
                controller.dateFilter = {
                    startDate: subscriptionStartDateFilter.startDate,
                    endDate: subscriptionStartDateFilter.endDate
                };
            } else if (DateRangeFixerService.todayRangeAndNoModel(event, subscriptionStartDateFilter)) {
                event.model.startDate = TimeService.getStartOfToday();
                event.model.endDate = TimeService.getEndOfToday();
                controller.dateFilter = {
                    startDate: event.model.startDate.toISOString(),
                    endDate: event.model.endDate.toISOString()
                };
            }
            subscriptionStartDateFilter = angular.copy(controller.dateFilter);
        };
    }
}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.members-db')
        .controller('MembersDb.MatchingToolLeadsExportDynamoDBController', [
            '$scope',
            '$timeout',
            'InstitutionsListService',
            'LeadsExportService',
            'TimeService',
            'uiGridConstants',
            'UiGridService',
            'uiGridExporterService',
            'uiGridExporterConstants',
            'DateRangeFixerService',
            Controller
        ]);

    function Controller(
        $scope,
        $timeout,
        InstitutionsListService,
        LeadsExportService,
        TimeService,
        uiGridConstants,
        GridService,
        uiGridExporterService,
        uiGridExporterConstants,
        DateRangeFixerService
    ) {
        var controller = this,
            gridApiInstance = null,
            columnsChecked = false,
            subscriptionStartDateFilter = null,
            defaultPaginationOptions = {
                page: 1,
                limit: 25
            };
        controller.institutionsDropdownList = [];
        controller.searchInProgress = false;
        controller.isRowsRendered = false;
        controller.selectedInstitution = null;
        controller.fetchInProgress = false;
        controller.noResults = false;
        controller.retryAttempts = 0;
        controller.fetchEnabled = function () {
            return (controller.days || controller.dateFilter.startDate && controller.dateFilter.endDate) &&
                !controller.fetchInProgress;
        };
        controller.handleSearchInstitution = function (searchPhrase) {
            controller.searchInProgress = true;
            if (!searchPhrase || searchPhrase.indexOf('/') !== -1) {
                controller.institutionsDropdownList = [];
                controller.searchInProgress = false;
                return;
            }
            InstitutionsListService.searchInstitutions(searchPhrase).then(function (data) {
                controller.institutionsDropdownList = data;
                controller.searchInProgress = false;
            });
        };

        function triggerExport (data) {
            var results = LeadsExportService.formatCsvResults(data);
            if (typeof results === 'undefined') {
                return false;
            }
            var gridApi = gridApiInstance,
                gridOptions = controller.gridOptions,
                exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                    gridApi.grid,
                    uiGridExporterConstants.VISIBLE
                );

            var exportData = GridService.getCsvData(gridApi.grid.columns, results);
            var csvContent = uiGridExporterService.formatAsCsv(
                exportColumnHeaders,
                exportData,
                gridOptions.exporterCsvColumnSeparator
            );
            uiGridExporterService.downloadFile(
                'leads-export' + TimeService.now() + '.csv',
                csvContent,
                gridOptions.exporterOlderExcelCompatibility
            );
        }
        controller.handleFetch = function (isPaginationChanged) {
            if (!controller.fetchEnabled()) {
                return false;
            }
            controller.noResults = false;
            controller.fetchInProgress = true;
            if (controller.gridOptions && controller.gridOptions.data) {
                if (!isPaginationChanged){
                    controller.gridOptions.data = null;
                    columnsChecked = false;
                }
            }
            var institutionCoreId = controller.selectedInstitution ? controller.selectedInstitution.coreId : null; 
            LeadsExportService.matchingToolLeadsExportDynamoDbSearch(
                institutionCoreId,
                controller.days,
                controller.dateFilter.endDate,
                controller.dateFilter.startDate,
                defaultPaginationOptions
            ).then(function (data) {
                controller.fetchInProgress = false;
                if (data && data.results && data.results.length > 0) {
                    if(controller.gridOptions) {
                        controller.gridOptions.minRowsToShow = data.results.length;
                        controller.gridOptions.totalItems = data.totalResults;
                    }
                    
                    initDataGrid(data);
                 }
                else {
                    controller.noResults = true;
                }
            });
        };

        function initDataGrid(data) {
            controller.gridOptions = {
                data: data.results,
                minRowsToShow: data.results.length,
                totalItems: data.totalResults,
                appScopeProvider: controller,
                enableSorting: false,
                useExternalFiltering: false,
                enableGridMenu: true,
                showGridFooter: true,
                enableFiltering: true,
                enableColumnResize: true,
                enableFullRowSelection: false,
                enableRowSelection: false,
                multiSelect: false,
                enableRowHeaderSelection: false,
                exporterMenuCsv: false,
                exporterMenuAllData: false,
                exporterMenuSelectedData: false,
                exporterMenuPdf: false,
                paginationPageSizes: [25, 50, 100, 200],
                paginationPageSize: 25,
                useExternalPagination: true,
                gridMenuCustomItems: [
                    {
                        title: ' Export visible data as csv',
                        action: exportVisible,
                        order: 209
                    },
                    {
                        title: ' Export all data as csv',
                        action: exportAll,
                        order: 210
                    }
                ],
                onRegisterApi: function (gridApi) {
                    gridApiInstance = gridApi;
                    gridApi.pagination.on.paginationChanged($scope, function (newPage, limit) {
                        defaultPaginationOptions.page = newPage;
                        defaultPaginationOptions.limit = limit;
                        controller.handleFetch(true);
                    });
                    gridApi.core.on.rowsRendered($scope, function () {
                        if (!columnsChecked) {
                            angular.forEach(controller.gridOptions.columnDefs, function (item) {
                                item.visible = ['core_id_mtool', 'program_id_mtool', 'program_name', 'program_link'].indexOf(item.name) !== -1;
                                if (item.name === 'core_id_mtool') {
                                    item.displayName = 'Core ID';
                                }
                                if (item.name === 'program_id_mtool') {
                                    item.displayName = 'Program ID';
                                }
                                if (item.name === 'program_name') {
                                    item.displayName = 'Program Name';
                                }
                                if (item.name === 'program_link') {
                                    item.displayName = 'Link';
                                }
                                if (item.name === 'lastname') {
                                    item.displayName = 'Last Name';
                                }
                                if (item.name === 'firstname') {
                                    item.displayName = 'First Name';
                                }
                                if (item.name === 'filters' || item.name === 'intended_study_level_sta' || item.name === 'institution_name') {
                                    item.visible = false;
                                    item.enableHiding = false;
                                }
                            });
                            controller.gridOptions.columnDefs.push({
                                displayName: 'Intended Study Level Sta',
                                field: 'intended_study_level_sta',
                                cellFilter: 'strReplace: "_": " " | capitalize',
                                visible: false
                            });
                            var institutionNameColumn = {
                                displayName: 'Institution Name',
                                field: 'institution_name',
                                visible: true
                            };
                            controller.gridOptions.columnDefs.splice(1, 0, institutionNameColumn);
                            gridApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
                            $timeout(function () {
                                columnsChecked = true;
                            },200);
                        }
                       
                    });
                }
            };
            $timeout(function() {
                controller.isRowsRendered = true;
            });
        }

        function exportVisible() {
            var results = gridApiInstance.grid.renderContainers.body.renderedRows;
            results = LeadsExportService.formatVisibleCsvResults(results);
            if (typeof results === 'undefined') {
                return false;
            }
            var gridApi = gridApiInstance,
                gridOptions = controller.gridOptions,
                exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                    gridApi.grid,
                    uiGridExporterConstants.VISIBLE
                );
            var exportData = GridService.getCsvData(gridApi.grid.columns, results);
            var csvContent = uiGridExporterService.formatAsCsv(
                exportColumnHeaders,
                exportData,
                gridOptions.exporterCsvColumnSeparator
            );
            uiGridExporterService.downloadFile(
                'matching-tool-leads-export' + TimeService.now() + '.csv',
                csvContent,
                gridOptions.exporterOlderExcelCompatibility
            );
        }

        function exportAll() {
            var institutionCoreId = controller.selectedInstitution ? controller.selectedInstitution.coreId : null; 
            LeadsExportService.matchingToolLeadsExportDynamoDbSearch(
                institutionCoreId,
                controller.days,
                controller.dateFilter.endDate,
                controller.dateFilter.startDate, {
                    page: 1,
                    limit: 500
                },true
            ).then(function (data) {
                if (data && data.results && data.results.length > 0) {
                    var exportPaginationOptions = {
                        page: 1,
                        limit: 500
                    };
                    var results = data.results,
                    totalPages = parseInt(data.totalPages) - 1;
                    if (totalPages > 0) {
                        var remainingPagesToExport = totalPages,
                        pageNumber =2;
                        exportCallback(remainingPagesToExport, exportPaginationOptions, results, pageNumber);
                    } else {
                        triggerExport(results);
                    }
                }
            });
        }

        function exportCallback(remainingPagesToExport, exportPaginationOptions, results, pageNumber) {
            exportPaginationOptions.page = pageNumber;
            LeadsExportService.matchingToolLeadsExportDynamoDbSearch(
                controller.selectedInstitution ? controller.selectedInstitution.coreId : null,
                controller.days,
                controller.dateFilter.endDate,
                controller.dateFilter.startDate,
                exportPaginationOptions, true
            ).then(function (data) {
                if (!data.results && controller.retryAttempts <= 3) {
                    controller.retryAttempts++;
                    exportCallback(remainingPagesToExport, exportPaginationOptions, results, pageNumber);
                } else {
                    controller.retryAttempts = 0;
                    remainingPagesToExport--;
                    pageNumber++;
                    if (data.results) {
                        results = results.concat(data.results);
                    }
                    if (remainingPagesToExport !== 0) {
                        exportCallback(remainingPagesToExport, exportPaginationOptions, results, pageNumber);
                    }
                }

                if (remainingPagesToExport === 0) {
                    triggerExport(results);
                }
            });
        }


        // function exportAll() {
        //     var results = controller.gridOptions.data;
        //     results = LeadsExportService.formatCsvResults(results);
        //     if (typeof results === 'undefined') {
        //         return false;
        //     }
        //     var gridApi = gridApiInstance,
        //         gridOptions = controller.gridOptions,
        //         exportColumnHeaders = uiGridExporterService.getColumnHeaders(
        //             gridApi.grid,
        //             uiGridExporterConstants.VISIBLE
        //         );
        //     var exportData = GridService.getCsvData(gridApi.grid.columns, results);
        //     var csvContent = uiGridExporterService.formatAsCsv(
        //         exportColumnHeaders,
        //         exportData,
        //         gridOptions.exporterCsvColumnSeparator
        //     );
        //     uiGridExporterService.downloadFile(
        //         'matching-tool-leads-export' + TimeService.now() + '.csv',
        //         csvContent,
        //         gridOptions.exporterOlderExcelCompatibility
        //     );
        // }

        controller.showLoadBar = function () {
            return controller.gridOptions && !columnsChecked;
        };

        controller.datePicker = {
            date: {
                startDate: null,
                endDate: null
            },
            options: {
                ranges: {
                    'in last 6 months': [TimeService.sub(6, 'months'), TimeService.now()],
                    'in last 12 months': [TimeService.sub(12, 'months'), TimeService.now()],
                    'this year': [TimeService.startOfYear(), TimeService.getEndOfToday()]
                },
                eventHandlers: {
                    'apply.daterangepicker': function (ev) {
                        controller.handleDateRangePickerApplyClick(ev);
                    }
                },
                opens: 'center',
                alwaysShowCalendars: true,
                minDate: new Date("07/12/2021")
            }
        };

        controller.dateFilter = {
            startDate: null,
            endDate: null
        };

        controller.handleDateRangePickerApplyClick = function (event) {
            if (DateRangeFixerService.sameDayAndNoModel(event, controller.dateFilter, subscriptionStartDateFilter) ||
                typeof  event.model.startDate === 'undefined') {
                event.model.startDate = subscriptionStartDateFilter.startDate;
                event.model.endDate = subscriptionStartDateFilter.endDate;
                controller.dateFilter = {
                    startDate: subscriptionStartDateFilter.startDate,
                    endDate: subscriptionStartDateFilter.endDate
                };
            } else if (DateRangeFixerService.todayRangeAndNoModel(event, subscriptionStartDateFilter)) {
                event.model.startDate = TimeService.getStartOfToday();
                event.model.endDate = TimeService.getEndOfToday();
                controller.dateFilter = {
                    startDate: event.model.startDate.toISOString(),
                    endDate: event.model.endDate.toISOString()
                };
            }
            subscriptionStartDateFilter = angular.copy(controller.dateFilter);
        };
    }
}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.members-db')
        .controller('MembersDb.StudentGuideExportController', [
            '$scope',
            '$timeout',
            'StudentGuideExportService',
            'TimeService',
            'uiGridConstants',
            'UiGridService',
            'uiGridExporterService',
            'uiGridExporterConstants',
            'DateRangeFixerService',
            Controller
        ]);

    function Controller(
        $scope,
        $timeout,
        StudentGuideExportService,
        TimeService,
        uiGridConstants,
        GridService,
        uiGridExporterService,
        uiGridExporterConstants,
        DateRangeFixerService
    ) {
        var controller = this,
            gridApiInstance = null,
            subscriptionStartDateFilter = null;
        controller.searchInProgress = false;
        controller.selectedInstitution = null;
        controller.fetchInProgress = false;
        controller.noResults = false;
        controller.fetchEnabled = function () {
            return (controller.days || controller.dateFilter.startDate && controller.dateFilter.endDate) &&
                !controller.fetchInProgress;
        };

        StudentGuideExportService.listStudentGuides().then(function (data) {
            if (data) {
                controller.studentsGuideDropDown = data;
            }
        });

        controller.handleFetch = function () {
            if (!controller.fetchEnabled()) {
                return false;
            }
            controller.noResults = false;
            controller.fetchInProgress = true;
            if (controller.gridOptions && controller.gridOptions.data) {
                controller.gridOptions.data = [];
            }
            var startDate, endDate = null;
            if (controller.days) {
                var today = TimeService.getStartOfToday();
                endDate = angular.copy(today);
                startDate = today.subtract(controller.days, "days");
            } else {
                startDate = controller.dateFilter.startDate;
                endDate = controller.dateFilter.endDate;
            }
            StudentGuideExportService.search(
                controller.studentGuide,
                endDate,
                startDate
            ).then(function (data) {
                $timeout(function () {
                    controller.fetchInProgress = false;
                },200);
                if (data && data.length > 0 && data[0] !== 'NOT FOUND') {
                    initDataGrid(data);
                }
                else {
                    controller.noResults = true;
                }
            });
        };

        function initDataGrid(data) {
            controller.gridOptions = {
                data: data,
                totalItems: data.length,
                appScopeProvider: controller,
                enableSorting: false,
                useExternalFiltering: false,
                enableGridMenu: true,
                showGridFooter: true,
                enableFiltering: true,
                enableColumnResize: true,
                enableFullRowSelection: false,
                enableRowSelection: false,
                multiSelect: false,
                enableRowHeaderSelection: false,
                exporterMenuCsv: false,
                exporterMenuAllData: false,
                exporterMenuSelectedData: false,
                exporterMenuPdf: false,
                paginationPageSizes: [25, 50, 100, 200],
                paginationPageSize: 25,
                useExternalPagination: false,
                gridMenuCustomItems: [
                    {
                        title: ' Export visible data as csv',
                        action: exportVisible,
                        order: 209
                    },
                    {
                        title: ' Export all data as csv',
                        action: exportAll,
                        order: 210
                    }
                ],
                columnDefs: [
                    {
                        displayName: 'First name',
                        field: 'firstname',
                    },
                    {
                        displayName: 'Last name',
                        field: 'lastname',
                    },
                    {
                        displayName: 'Email address',
                        field: 'email_address',
                    },
                    {
                        displayName: '3rd party opt-in',
                        field: 'third_party_opt_in',
                        visible: false,
                        enableHiding: false,
                    },
                    {
                        displayName: 'Intended study level',
                        field: 'intended_study_level',
                    },
                    {
                        displayName: 'Intended subject',
                        field: 'intended_subject_area',
                    },
                    {
                        displayName: 'Intended study destination',
                        field: 'intended_region_study',
                    },
                    {
                        displayName: 'Country of residence',
                        field: 'country_residence',
                    },
                    {
                        displayName: 'Mobile country code',
                        field: 'mobile_country_code',
                        cellFilter : 'uppercase'
                    },
                    {
                        displayName: 'Mobile number',
                        field: 'telephone',
                    }
                ],
                onRegisterApi: function (gridApi) {
                    gridApiInstance = gridApi;
                }
            };
        }

        function exportVisible() {
            var results = gridApiInstance.grid.renderContainers.body.renderedRows;
            results = StudentGuideExportService.formatVisibleCsvResults(results);
            if (typeof results === 'undefined') {
                return false;
            }
            var gridApi = gridApiInstance,
                gridOptions = controller.gridOptions,
                exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                    gridApi.grid,
                    uiGridExporterConstants.VISIBLE
                );
            var exportData = GridService.getCsvData(gridApi.grid.columns, results);
            var csvContent = uiGridExporterService.formatAsCsv(
                exportColumnHeaders,
                exportData,
                gridOptions.exporterCsvColumnSeparator
            );
            uiGridExporterService.downloadFile(
                'matching-tool-leads-export' + TimeService.now() + '.csv',
                csvContent,
                gridOptions.exporterOlderExcelCompatibility
            );
        }

        function exportAll() {
            var results = controller.gridOptions.data;
            results = StudentGuideExportService.formatCsvResults(results);
            if (typeof results === 'undefined') {
                return false;
            }
            var gridApi = gridApiInstance,
                gridOptions = controller.gridOptions,
                exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                    gridApi.grid,
                    uiGridExporterConstants.VISIBLE
                );
            var exportData = GridService.getCsvData(gridApi.grid.columns, results);
            var csvContent = uiGridExporterService.formatAsCsv(
                exportColumnHeaders,
                exportData,
                gridOptions.exporterCsvColumnSeparator
            );
            uiGridExporterService.downloadFile(
                'matching-tool-leads-export' + TimeService.now() + '.csv',
                csvContent,
                gridOptions.exporterOlderExcelCompatibility
            );
        }

        controller.datePicker = {
            date: {
                startDate: null,
                endDate: null
            },
            options: {
                ranges: {
                    'in last 6 months': [TimeService.sub(6, 'months'), TimeService.now()],
                    'in last 12 months': [TimeService.sub(12, 'months'), TimeService.now()],
                    'this year': [TimeService.startOfYear(), TimeService.getEndOfToday()]
                },
                eventHandlers: {
                    'apply.daterangepicker': function (ev) {
                        controller.handleDateRangePickerApplyClick(ev);
                    }
                },
                opens: 'center',
                alwaysShowCalendars: true
            }
        };

        controller.dateFilter = {
            startDate: null,
            endDate: null
        };

        controller.handleDateRangePickerApplyClick = function (event) {
            if (DateRangeFixerService.sameDayAndNoModel(event, controller.dateFilter, subscriptionStartDateFilter) ||
                typeof  event.model.startDate === 'undefined') {
                event.model.startDate = subscriptionStartDateFilter.startDate;
                event.model.endDate = subscriptionStartDateFilter.endDate;
                controller.dateFilter = {
                    startDate: subscriptionStartDateFilter.startDate,
                    endDate: subscriptionStartDateFilter.endDate
                };
            } else if (DateRangeFixerService.todayRangeAndNoModel(event, subscriptionStartDateFilter)) {
                event.model.startDate = TimeService.getStartOfToday();
                event.model.endDate = TimeService.getEndOfToday();
                controller.dateFilter = {
                    startDate: event.model.startDate.toISOString(),
                    endDate: event.model.endDate.toISOString()
                };
            }
            controller.dateFilter.startDate = TimeService.getInUnix(TimeService.getMomentObject(controller.dateFilter.startDate), true);
            subscriptionStartDateFilter = angular.copy(controller.dateFilter);
        };
    }
}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.stars')
        .service('StudentGuideExportService', [
            '$q',
            '$resource',
            'constants',
            'RequestsQueueService',
            'TimeService',
            Service
        ]);

    function Service(
        $q,
        $resource,
        constants,
        RequestsQueueService,
        TimeService
    ) {
        var service = {};

        function getModel() {
            return $resource(constants.api.usersPermissions.url, {}, {
                search: {
                    method: 'GET',
                    url:  constants.api.usersPermissions.url + '/v1/members-db/student-guide-downloads',
                    isArray: true,
                    cancellable: true
                },
                listStudentGuides: {
                    method: 'GET',
                    url: constants.api.usersPermissions.url + '/v1/list/student-guides?&sorting[longName]=asc',
                    isArray: false,
                    cancellable: true
                }
            });
        }

        service.search = function (guideName, to, from) {
            var key = 'studentGuideExportService::search',
                Api;
           
            RequestsQueueService.cancelAll(key);
            Api = getModel().search({
                hubGuideName: guideName,
                endDate: TimeService.gmtFormat(to, 'YYYY-MM-DD'),
                startDate: TimeService.gmtFormat(from, 'YYYY-MM-DD')
            });
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                return data;
            }, function () {
                return false;
            });
        };

        service.listStudentGuides = function () {
            var key = 'studentGuideExportService::listStudentGuides',
                Api = getModel().listStudentGuides();
            RequestsQueueService.cancelAll(key);
            Api = getModel().listStudentGuides();

            // add new request to the queue
            RequestsQueueService.add(Api, key);

            var list = [];
            
            return Api.$promise.then(function (data) {
               if (data && data.results) {
                    angular.forEach(data.results, function (item) {
                        list.push({
                            value : item.name,
                            label : item.longName
                        });
                    });
                }

                return list;
            }, function () {
                return false;
            });
        };

        service.formatCsvResults = function (data) {
            data = angular.copy(data);
            angular.forEach(data, function (column, dataKey) {
                angular.forEach(column, function (item, key) {
                    if (data[dataKey][key] && (key === 'intended_study_level_sta' || key === 'desired_study_level' ||
                        key === 'grad_study_mode')) {
                            data[dataKey][key] = data[dataKey][key].replace('_', ' ');
                    } else if (key === 'datecreated_mem' || key === 'datecreated_int' ||
                        key === 'datecreated_mtool' || key === 'datecreated_mem' ||
                        key === 'date_of_birth') {
                            data[dataKey][key] = TimeService.format(item, "MMM DD, YYYY");
                    } else if(key === 'mobile_country_code') {
                        data[dataKey][key] =  data[dataKey][key].toUpperCase();
                    }
                });
            });

            return data;
        };

        service.formatVisibleCsvResults = function (data) {
            var filteredRows = [];
             angular.forEach(data, function (row, datakey) {
                if (typeof row.entity.mobile_country_code !== 'undefined' &&
                    typeof row.entity.mobile_country_code !== null) {
                    row.entity.mobile_country_code = row.entity.mobile_country_code.toUpperCase();
                }
                filteredRows.push(row.entity);
             });

             return  service.formatCsvResults(filteredRows);
        };

        return service;
    }

}(window.angular));


(function (angular) {
    'use strict';

    var App = App || {};

    App.config = function ($stateProvider,
                           errors) {
        $stateProvider
            .state('staff.iu', {
                url: '/iu',
                abstract: true,
                template: '<div ui-view></div>',
                data: {
                    label: 'IU Data'
                }
            }).state('staff.iu.rankings-dashboard', {
            url: '/rankings-dashboard',
            templateUrl: '/scripts/modules/rankings/components/rankingsDashboard/rankingsDashboardView.html',
            controller: 'Rankings.RankingsDashboardController',
            controllerAs: 'RankingsDashboardController',
            data: {
                pageTitle: 'Rankings Dashboard',
                label: 'Rankings Dashboard',
                name: 'Rankings Dashboard'
            },
            resolve: {
                security: [
                    '$q', 'UserFactory', 'AuthenticationService',
                    function ($q, UserFactory, AuthenticationService) {
                        UserFactory.setData(AuthenticationService.getUserData());
                        if (!UserFactory.hasData()) {
                            return $q.reject(errors[401]);
                        } else if (!UserFactory.hasIuAccess()) {
                            return $q.reject(errors[403]);
                        }
                    }
                ]
            }
        }).state('staff.iu.rankings-information', {
            url: '/rankings-information',
            templateUrl: '/scripts/modules/rankings/components/rankingsInformation/rankingsInformationView.html',
            controller: 'Rankings.RankingsInformationController',
            controllerAs: 'RankingsInformationController',
            data: {
                pageTitle: 'Rankings Information',
                label: 'Rankings Information',
                name: 'Rankings Information'
            },
            resolve: {
                security: [
                    '$q', 'UserFactory', 'AuthenticationService',
                    function ($q, UserFactory, AuthenticationService) {
                        UserFactory.setData(AuthenticationService.getUserData());
                        if (!UserFactory.hasData()) {
                            return $q.reject(errors[401]);
                        } else if (!UserFactory.hasIuAccess()) {
                            return $q.reject(errors[403]);
                        }
                    }
                ]
            }
        }).state('staff.iu.rankings-information.info', {
            url: '/info',
            data: {
                label: 'Edit Rankings Info'
            }
        }).state('staff.iu.rankings-information.definitions', {
            url: '/definitions',
            data: {
                label: 'Edit Definitions'
            }
        }).state('staff.iu.progress-reports', {
            abstract: true,
            template: '<div ui-view></div>',
            data: {
                label: 'Progress Reports'
            }
        }).state('staff.iu.progress-reports.ranking', {
            url: '/progress-reports/ranking',
            templateUrl: '/scripts/modules/rankings/components/progressReports/byRanking/byRankingView.html',
            controller: 'Rankings.ProgressReportByRankingController',
            controllerAs: 'ProgressReportByRankingController',
            data: {
                pageTitle: 'By Ranking',
                label: 'By Ranking',
                name: 'By Ranking'
            },
            resolve: {
                security: [
                    '$q', 'UserFactory', 'AuthenticationService',
                    function ($q, UserFactory, AuthenticationService) {
                        UserFactory.setData(AuthenticationService.getUserData());
                        if (!UserFactory.hasData()) {
                            return $q.reject(errors[401]);
                        } else if (!UserFactory.hasIuAccess()) {
                            return $q.reject(errors[403]);
                        }
                    }
                ]
            }
        }).state('staff.iu.progress-reports.country', {
            url: '/progress-reports/country',
            templateUrl: '/scripts/modules/rankings/components/progressReports/byCountry/byCountryView.html',
            controller: 'Rankings.ProgressReportByCountryController',
            controllerAs: 'ProgressReportByCountryController',
            data: {
                pageTitle: 'By Country',
                label: 'By Country',
                name: 'By Country'
            },
            resolve: {
                security: [
                    '$q', 'UserFactory', 'AuthenticationService',
                    function ($q, UserFactory, AuthenticationService) {
                        UserFactory.setData(AuthenticationService.getUserData());
                        if (!UserFactory.hasData()) {
                            return $q.reject(errors[401]);
                        } else if (!UserFactory.hasIuAccess()) {
                            return $q.reject(errors[403]);
                        }
                    }
                ]
            }
        }).state('staff.iu.progress-reports.institution', {
            url: '/progress-reports/institution',
            templateUrl: '/scripts/modules/rankings/components/progressReports/byInstitution/byInstitutionView.html',
            controller: 'Rankings.ProgressReportByInstitutionController',
            controllerAs: 'ProgressReportByInstitutionController',
            data: {
                pageTitle: 'By Institution',
                label: 'By Institution',
                name: 'By Institution'
            },
            resolve: {
                security: [
                    '$q', 'UserFactory', 'AuthenticationService',
                    function ($q, UserFactory, AuthenticationService) {
                        UserFactory.setData(AuthenticationService.getUserData());
                        if (!UserFactory.hasData()) {
                            return $q.reject(errors[401]);
                        } else if (!UserFactory.hasIuAccess()) {
                            return $q.reject(errors[403]);
                        }
                    }
                ]
            }
        }).state('clients.statistics', {
            url: '/statistics',
            abstract: true,
            template: '<div ui-view></div>',
            data: {
                label: 'Statistics'
            }
        }).state('clients.statistics.data-submission', {
            url: '/data-submission',
            templateUrl: '/scripts/modules/rankings/components/statisticsDashboard/statisticsDashboardView.html',
            controller: 'Rankings.StatisticsDashboardController',
            controllerAs: 'StatisticsDashboardController',
            data: {
                pageTitle: 'Data submission',
                label: 'Data submission',
                name: 'Data submission'
            },
            resolve: {
                security: [
                    '$q', 'UserFactory', 'AuthenticationService',
                    function ($q, UserFactory, AuthenticationService) {
                        UserFactory.setData(AuthenticationService.getUserData());
                        if (!UserFactory.hasData()) {
                            return $q.reject(errors[401]);
                        } else if (!UserFactory.hasStatisticsAccess()) {
                            return $q.reject(errors[403]);
                        }
                    }
                ]
            }
        }).state('clients.statistics.data-submission.all', {
            url: '/all?question&messageId&replyId',
            templateUrl: '/scripts/modules/rankings/components/statisticsSubmissions/statisticsSubmissionsView.html',
            controller: 'Rankings.StatisticsSubmissionsController',
            controllerAs: 'StatisticsSubmissionsController',
            data: {
                rankings: 'all',
                label: 'Overall Statistics'
            }
        }).state('clients.statistics.data-submission.esg', {
            url: '/esg?question&messageId&replyId',
            templateUrl: '/scripts/modules/rankings/components/statisticsSubmissions/statisticsSubmissionsView.html',
            controller: 'Rankings.StatisticsSubmissionsController',
            controllerAs: 'StatisticsSubmissionsController',
            data: {
                rankings: 'esg',
                label: 'Sustainability'
            }
        }).state('clients.statistics.data-submission.aw', {
            url: '/aw?question&messageId&replyId',
            templateUrl: '/scripts/modules/rankings/components/statisticsSubmissions/statisticsSubmissionsView.html',
            controller: 'Rankings.StatisticsSubmissionsController',
            controllerAs: 'StatisticsSubmissionsController',
            data: {
                rankings: 'aw',
                label: 'QS World University Rankings: Arab Region'
            }
        }).state('clients.statistics.data-submission.aur', {
            url: '/aur?question&messageId&replyId',
            templateUrl: '/scripts/modules/rankings/components/statisticsSubmissions/statisticsSubmissionsView.html',
            controller: 'Rankings.StatisticsSubmissionsController',
            controllerAs: 'StatisticsSubmissionsController',
            data: {
                rankings: 'aur',
                label: 'QS World University Rankings: Asia'
            }
        }).state('clients.statistics.data-submission.eeca', {
            url: '/eeca?question&messageId&replyId',
            templateUrl: '/scripts/modules/rankings/components/statisticsSubmissions/statisticsSubmissionsView.html',
            controller: 'Rankings.StatisticsSubmissionsController',
            controllerAs: 'StatisticsSubmissionsController',
            data: {
                rankings: 'eeca',
                label: 'QS World University Rankings: Europe'
            }
        }).state('clients.statistics.data-submission.lat', {
            url: '/lat?question&messageId&replyId',
            templateUrl: '/scripts/modules/rankings/components/statisticsSubmissions/statisticsSubmissionsView.html',
            controller: 'Rankings.StatisticsSubmissionsController',
            controllerAs: 'StatisticsSubmissionsController',
            data: {
                rankings: 'lat',
                label: 'QS World University Rankings: Latin America'
            }
        }).state('clients.statistics.data-submission.ger', {
            url: '/ger?question&messageId&replyId',
            templateUrl: '/scripts/modules/rankings/components/statisticsSubmissions/gerView.html',
            controller: 'Rankings.GerController',
            controllerAs: 'GerController',
            params: {
                messageId: null
            },
            data: {
                rankings: 'ger',
                label: 'QS World University Rankings: Graduate Employability'
            },
            resolve: {
                security: [
                    '$q', 'UserFactory',
                    function ($q, UserFactory) {
                        if (UserFactory.isClient()) {
                            return $q.reject(errors[403]);
                        } 
                    }
                ]
            }
        }).state('clients.statistics.data-submission.ger.employers-connections', {
            url: '/employers-connections',
        }).state('clients.statistics.data-submission.ger.employers-partnerships', {
            url: '/employers-partnerships',
        }).state('clients.statistics.data-submission.ger.employment-rate', {
            url: '/employment-rate',
        }).state('clients.statistics.data-submission.ger.faculty-student-data', {
            url: '/faculty-student-data',
        }).state('clients.statistics.data-submission.wur', {
            url: '/wur?question&messageId&replyId',
            templateUrl: '/scripts/modules/rankings/components/statisticsSubmissions/statisticsSubmissionsView.html',
            controller: 'Rankings.StatisticsSubmissionsController',
            controllerAs: 'StatisticsSubmissionsController',
            data: {
                rankings: 'wur',
                label: 'QS World University Rankings'
            }
        }).state('clients.statistics.data-submission.brics', {
            url: '/brics?question&messageId&replyId',
            templateUrl: '/scripts/modules/rankings/components/statisticsSubmissions/statisticsSubmissionsView.html',
            controller: 'Rankings.StatisticsSubmissionsController',
            controllerAs: 'StatisticsSubmissionsController',
            data: {
                rankings: 'brics',
                label: 'QS World University Rankings: BRICS'
            },
            resolve: {
                security: [
                    '$q', 'UserFactory', 'AuthenticationService',
                    function ($q, UserFactory, AuthenticationService) {
                        UserFactory.setData(AuthenticationService.getUserData());
                            return $q.reject(errors[403]);
                    }
                ]
            }
        }).state('clients.statistics.nominations', {
            abstract: true,
            template: '<div ui-view></div>',
            data: {
                label: 'Contact Lists'
            }
        }).state('clients.statistics.nominations.upload', {
            url: '/nominations/upload',
            templateUrl: '/scripts/modules/rankings/components/nominations/components/client/nominationsListClientUpload/nominationsListClientUploadView.html',
            controller: 'Rankings.NominationsListClientUploadController',
            controllerAs: 'NominationsListClientUploadController',
            data: {
                pageTitle: 'Upload',
                label: 'Upload',
                name: 'Upload'
            },
            resolve: {
                security: [
                    '$q', 'UserFactory', 'AuthenticationService',
                    function ($q, UserFactory, AuthenticationService) {
                        UserFactory.setData(AuthenticationService.getUserData());
                        if (!UserFactory.hasData()) {
                            return $q.reject(errors[401]);
                        } else if (!UserFactory.hasStatisticsAccess()) {
                            return $q.reject(errors[403]);
                        }
                    }
                ]
            }
        }).state('clients.statistics.nominations.lists', {
            url: '/nominations/lists',
            templateUrl: '/scripts/modules/rankings/components/nominations/components/nominationsListDatagrid/nominationsListDatagridView.html',
            controller: 'Rankings.NominationsListDatagridController',
            controllerAs: 'NominationsListDatagridController',
            data: {
                pageTitle: 'Lists',
                label: 'Lists',
                name: 'Lists'
            },
            resolve: {
                security: [
                    '$q', 'UserFactory', 'AuthenticationService',
                    function ($q, UserFactory, AuthenticationService) {
                        UserFactory.setData(AuthenticationService.getUserData());
                        if (!UserFactory.hasData()) {
                            return $q.reject(errors[401]);
                        } else if (!UserFactory.hasStatisticsAccess()) {
                            return $q.reject(errors[403]);
                        }
                    }
                ]
            }
        }).state('staff.iu.id-checker', {
            url: '/id-checker',
            templateUrl: '/scripts/modules/rankings/components/idChecker/idCheckerView.html',
            controller: 'Rankings.IdCheckerController',
            controllerAs: 'IdCheckerController',
            data: {
                label: 'Rankings ID Checker'
            },
            resolve: {
                security: [
                    '$q', 'UserFactory', 'AuthenticationService',
                    function ($q) {
                        return $q.reject(errors[403]);
                    }
                ]
            }
        }).state('staff.iu.rankings-scores', {
            url: '/rankings-scores',
            templateUrl: '/scripts/modules/rankings/components/rankingsScores/rankingsScoresView.html',
            controller: 'Rankings.RankingsScoresController',
            controllerAs: 'RankingsScoresCtrl',
            data: {
                label: 'Rankings Scores'
            },
            resolve: {
                security: [
                    '$q', 'UserFactory', 'AuthenticationService',
                    function ($q) {
                        return $q.reject(errors[403]);
                    }
                ]
            }
        }).state('staff.iu.classifications-publish', {
            url: '/classifications-publish',
            templateUrl: '/scripts/modules/rankings/components/classificationsPublish/classificationsPublishView.html',
            controller: 'Rankings.ClassificationsPublishController',
            controllerAs: 'ClassificationsPublishController',
            data: {
                pageTitle: 'Classifications',
                label: 'Classifications',
                name: 'Rankings Classifications'
            },
            resolve: {
                security: [
                    '$q', 'UserFactory', 'AuthenticationService',
                    function ($q, UserFactory, AuthenticationService) {
                        UserFactory.setData(AuthenticationService.getUserData());
                        if (!UserFactory.hasData()) {
                            return $q.reject(errors[401]);
                        } else if (!UserFactory.hasIuAccess()) {
                            return $q.reject(errors[403]);
                        }
                    }
                ]
            }
        }).state('staff.iu.classifications-overview', {
            url: '/classifications-overview',
            templateUrl: '/scripts/modules/rankings/components/classificationsOverview/classificationsOverviewView.html',
            controller: 'Rankings.ClassificationsOverviewController',
            controllerAs: 'ClassificationsOverviewController',
            data: {
                pageTitle: 'Classifications Overview',
                label: 'Classifications Overview',
                name: 'Classifications Overview'
            },
            resolve: {
                security: [
                    '$q' , 'UserFactory', 'AuthenticationService',
                    function ($q, UserFactory, AuthenticationService) {
                        UserFactory.setData(AuthenticationService.getUserData());
                        if (!UserFactory.hasData()) {
                            return $q.reject(errors[401]);
                        } else if (!UserFactory.hasIuAccess()) {
                            return $q.reject(errors[403]);
                        }
                    }
                ]
            }
        }).state('staff.iu.nominations', {
            abstract: true,
            template: '<div ui-view></div>',
            data: {
                label: 'Academic and Employer Contacts'
            }
        }).state('staff.iu.nominations.reports', {
            url: '/nominations/uploaded-reports',
            templateUrl: '/scripts/modules/rankings/components/nominations/components/qs/nominationsListReportsDatagrid/nominationsListReportsDatagridView.html',
            controller: 'Rankings.NominationsListReportsDatagridController',
            controllerAs: 'NominationsListReportsDatagridController',
            data: {
                pageTitle: 'Uploaded Reports',
                label: 'Uploaded Reports',
                name: 'Uploaded Reports'
            },
            resolve: {
                security: [
                    '$q', 'UserFactory', 'AuthenticationService',
                    function ($q, UserFactory, AuthenticationService) {
                        UserFactory.setData(AuthenticationService.getUserData());
                        if (!UserFactory.hasData()) {
                            return $q.reject(errors[401]);
                        } else if (!UserFactory.hasIuAccess()) {
                            return $q.reject(errors[403]);
                        }
                    }
                ]
            }
        }).state('staff.iu.nominations.lists', {
            url: '/nominations/lists',
            templateUrl: '/scripts/modules/rankings/components/nominations/components/nominationsListDatagrid/nominationsListDatagridView.html',
            controller: 'Rankings.NominationsListDatagridController',
            controllerAs: 'NominationsListDatagridController',
            params: {
                institutionCoreId: null,
                fileType: null
            },
            data: {
                pageTitle: 'Lists',
                label: 'Lists',
                name: 'Lists',
            },
            resolve: {
                security: [
                    '$q', 'UserFactory', 'AuthenticationService',
                    function ($q, UserFactory, AuthenticationService) {
                        UserFactory.setData(AuthenticationService.getUserData());
                        if (!UserFactory.hasData()) {
                            return $q.reject(errors[401]);
                        } else if (!UserFactory.hasIuAccess()) {
                            return $q.reject(errors[403]);
                        }
                    }
                ]
            }
        }).state('staff.iu.rankings-html', {
            url: '/rankings-html',
            templateUrl: '/scripts/modules/rankings/components/rankingsHtml/rankingsHtmlView.html',
            controller: 'Rankings.HtmlController',
            controllerAs: 'RankingsHtmlController',
            data: {
                pageTitle: 'Rankings HTML',
                label: 'Rankings HTML',
                name: 'Rankings HTML'
            },
            resolve: {
                security: [
                    '$q', 'UserFactory', 'AuthenticationService',
                    function ($q, UserFactory, AuthenticationService) {
                        UserFactory.setData(AuthenticationService.getUserData());
                        if (!UserFactory.hasData()) {
                            return $q.reject(errors[401]);
                        } else if (!UserFactory.hasPRAccess()) {
                            return $q.reject(errors[403]);
                        }
                    }
                ]
            }
        }).state('staff.iu.bulk-ger-upload', {
            url: '/bulk-ger-upload',
            templateUrl: '/scripts/modules/rankings/components/bulkGerUpload/bulkGerUpload.html',
            controller: 'Rankings.BulkGerUploadController',
            controllerAs: 'BulkGerUploadController',
            data: {
                pageTitle: 'Rankings Bulk GER upload',
                label: 'Rankings Bulk GER upload',
                name: 'Rankings Bulk GER upload'
            },
            resolve: {
                security: [
                    '$q', 'UserFactory', 'AuthenticationService',
                    function ($q, UserFactory, AuthenticationService) {
                        UserFactory.setData(AuthenticationService.getUserData());
                        if (!UserFactory.hasData()) {
                            return $q.reject(errors[401]);
                        } else if (!UserFactory.hasPRAccess()) {
                            return $q.reject(errors[403]);
                        }
                    }
                ]
            }
        }).state('staff.iu.scopus', {
            abstract: true,
            template: '<div ui-view></div>',
            data: {
                label: 'Scopus'
            }
        }).state('staff.iu.scopus.manage-ids', {
            url: '/scopus/manage-ids',
            templateUrl: '/scripts/modules/rankings/components/scopus/components/manageIds/manageIdsView.html',
            controller: 'rankings.ManageIdsController',
            controllerAs: 'ManageIdsController',
            data: {
                pageTitle: "Manage ID's",
                label: "Manage ID's",
                name: "Manage ID's"
            },
            resolve: {
                security: [
                    '$q', 'UserFactory', 'AuthenticationService',
                    function ($q, UserFactory, AuthenticationService) {
                        UserFactory.setData(AuthenticationService.getUserData());
                        if (!UserFactory.hasData()) {
                            return $q.reject(errors[401]);
                        } else if (!UserFactory.hasStatisticsAccess()) {
                            return $q.reject(errors[403]);
                        }
                    }
                ]
            }
        }).state('staff.iu.scopus.statistics', {
            url: '/scopus/statistics',
            templateUrl: '/scripts/modules/rankings/components/scopus/components/statistics/scopusStatisticsView.html',
            controller: 'rankings.ScopusStatisticsController',
            controllerAs: 'ScopusStatisticsController',
            data: {
                pageTitle: 'Statistics',
                label: 'Statistics',
                name: 'Statistics'
            },
            resolve: {
                security: [
                    '$q', 'UserFactory', 'AuthenticationService',
                    function ($q, UserFactory, AuthenticationService) {
                        UserFactory.setData(AuthenticationService.getUserData());
                        if (!UserFactory.hasData()) {
                            return $q.reject(errors[401]);
                        } else if (!UserFactory.hasStatisticsAccess()) {
                            return $q.reject(errors[403]);
                        }
                    }
                ]
            }
        });
    };

    angular
        .module('qsHub.rankings')
        .config(App.config, [
            '$stateProvider',
            'errors'
        ]);

}(window.angular));

(function(angular) {
    'use strict';

    var languages = {//https://www.loc.gov/standards/iso639-2/php/code_list.php
        'chi' : 'Chinese (Mandarin)',
        'spa' : 'Spanish',
        'fre' : 'French',
        'ara' : 'Arabic',
        'jap' : 'Japanese',
        'ita' : 'Italian',
        'kor' : 'Korean',
        'por' : 'Portuguese / Brazilian',
        'rus' : 'Russian',
        'eng' : 'English'
    }, sources = {
        'cnt' : 'CNT',
        'direct': 'Direct',
        'nav': 'Nav',
        'pyr': 'PYR',
        'qsiu': 'QSIU',
        'rav': 'Rav',
        'website': 'Website',
        'wol': 'Wol'
    }, currencies = {
        'usd': 'USD',
        'eur': 'EUR',
        'gbp': 'GBP',
        'cad': 'CAD'
    },
    gridNamespaces = {
        filters: {
            rankingProgressReport : 'rankingProgressReportFilters',
            countryProgressReport: 'countryProgressReportFilters',
            institutionProgressReport: 'institutionProgressReportFilters',
            classificationsOverview: 'classificationsOverviewFilters'
        },
        visibility: {
            rankingProgressReport : 'rankingProgressReportVisibility',
            countryProgressReport: 'countryProgressReportVisibility',
            institutionProgressReport : 'institutionProgressReportVisibility',
            classificationsOverview: 'classificationsOverviewVisibility'
        }
    },
    persistentDates = {
        progressReports: 'progressReports'
    },
    persistentProgressReports = {
        byCountryRanking: 'byCountryRanking',
        byInstitutionRanking: 'byInstitutionRanking',
        byInstitutionCountry: 'byInstitutionCountry'
    },
    broadcast = {
        saveCompleted: 'saveCompleted'
    },
    sp = {
        'Arts & Humanities': {
            'VH' : 66.30,
            'HI' : 33.15,
            'MD' : 16.575
        },
        'Engineering' : {
            'VH' : 1260.38,
            'HI' : 630.19,
            'MD' : 315.095
        },
        'Life Sciences & Medicine' : {
            'VH' : 2554.42,
            'HI' : 1277.21,
            'MD' : 638.605
        },
        'Natural Sciences' : {
            'VH' : 1495.64,
            'HI' : 747.82,
            'MD' : 373.91
        },
        'Social Sciences' : {
            'VH' : 351.70,
            'HI' : 175.85,
            'MD' : 87.925
        }
    };

    angular
        .module('qsHub.rankings')
        .constant('languages', languages)
        .constant('rankingsSources', sources)
        .constant('currencies', currencies)
        .constant('gridNamespaces', gridNamespaces)
        .constant('persistentDates', persistentDates)
        .constant('persistentProgressReports', persistentProgressReports)
        .constant('sp', sp)
        .constant('broadcast', broadcast);

}(window.angular));

(function (angular) {
    'use strict';

    angular.module('qsHub.rankings').service('RankingsService', [
        '$resource',
        '$q',
        '$log',
        'constants',
        'RequestsQueueService',
        RankingsService
    ]);

    function RankingsService($resource, $q, $log, constants, RequestsQueueService) {
        var svc = {
            cache : {}
        };

        var questionsUpdated = false;

        svc.getQuestions = getQuestions;
        svc.getQuestionName = getQuestionName;
        svc.setQuestionsUpdated = setQuestionsUpdated;

        function getModel() {
            return $resource(constants.api.rankings.url, {}, {
                getQuestions: {
                    method: 'GET',
                    url: constants.api.rankings.url + '/v1/questions',
                    isArray: false,
                    cancellable: true,
                }
            });
        }

        function getQuestionName(handle) {
            return getQuestions().then(function (questions) {
                var total = questions.length,
                    i = 0;
                for (i; i < total; i++) {
                    if (questions[i].handle === handle) {
                        return questions[i].name;
                    }
                }
            });
        }

        function setQuestionsUpdated(){
            questionsUpdated = true;
        }

        function getQuestions(useCache) {
            if (typeof useCache === 'undefined' && !questionsUpdated) {
                useCache = true;
            }
            var cacheKey = 'RankingsService:getQuestions',
                deferred = $q.defer(),
                Api;

            if (useCache && svc.cache[cacheKey]) {
                deferred.resolve(svc.cache[cacheKey]);
                return deferred.promise;
            }

            RequestsQueueService.cancelAll(cacheKey);
            Api = getModel().getQuestions(function (data) {
                data = data.results;
                svc.cache[cacheKey] = data;
                deferred.resolve(data);
                deferred = null;
            }, function (error) {
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                deferred.resolve([]);
                deferred = null;
            });
            RequestsQueueService.add(Api, cacheKey);

            questionsUpdated = false;

            return deferred.promise;
        }

        return svc;
    }

}(window.angular));

(function(angular) {
    "use strict";

    angular
        .module('qsHub.rankings')
        .controller('Rankings.RankingsDashboardController', [
            '$scope',
            'RankingsDashboardFactory',
            'StatisticsDashboardFactory',
            'InstitutionsListService',
            'WatchService',
            'RankingsDashboardService',
            'RequestsQueueService',
            'RankingsService',
            RankingsDashboard
        ]);

    function RankingsDashboard(
        $scope,
        RankingsDashboardFactory,
        StatisticsDashboardFactory,
        InstitutionsListService,
        WatchService,
        RankingsDashboardService,
        RequestsQueueService,
        RankingsService
    ) {
        var controller = this;

        controller.rankings = [];
        controller.rankingsDataTypes = StatisticsDashboardFactory.getTypes();
        controller.isPersonnel = false;
        controller.isGerPersonnel = false;
        controller.isFinancial = false;
        controller.isNumeric = false;
        controller.selectedItem = null;

        controller.handleReloadDatagrid = function ( ) {
            if (!RankingsDashboardFactory.isLoading()) {
                RankingsDashboardFactory.setIsLoading(true);
                RankingsDashboardFactory.reloadDatagrid(true);
            }
        };

        controller.handleClearFilters = function ( ) {
            RequestsQueueService.cancelAll();
            controller.selectedItem = {
                ranking: null,
                dataType: null
            };
            RankingsDashboardFactory.setFilters(null);
            RequestsQueueService.cancelAll('getRankingsAnswersByQuestions');
            RankingsDashboardFactory.setIsLoading(false);
        };

        controller.isPersonnelVisible = function () {
            if (controller.selectedItem.ranking && controller.selectedItem.ranking.handle) {
                return RankingsDashboardService.getQuestionsHandles(
                    StatisticsDashboardFactory.getQuestions(),
                    controller.selectedItem.ranking.handle,
                    StatisticsDashboardFactory.getTypes().personal
                ).length > 0;
            } else {
                return true;
            }
        };

        controller.isGerPersonnelVisible = function () {
            if (controller.selectedItem.ranking && controller.selectedItem.ranking.handle) {
                return RankingsDashboardService.getQuestionsHandles(
                    StatisticsDashboardFactory.getQuestions(),
                    controller.selectedItem.ranking.handle,
                    StatisticsDashboardFactory.getTypes().gerPersonal
                ).length > 0;
            } else {
                return true;
            }
        };

        controller.isEmployementRatePersonnelVisible = function () {
            if (controller.selectedItem.ranking && controller.selectedItem.ranking.handle) {
                return RankingsDashboardService.getQuestionsHandles(
                    StatisticsDashboardFactory.getQuestions(),
                    'ger',
                    StatisticsDashboardFactory.getTypes().gerPersonal
                ).length > 0 && controller.selectedItem.ranking.handle !== 'ger' && controller.selectedItem.ranking.handle !== 'esg';
            } else {
                return true;
            }
        };

        controller.isEmployementRateNumericVisible = function () {
            if (controller.selectedItem.ranking && controller.selectedItem.ranking.handle) {
                return RankingsDashboardService.getQuestionsHandles(
                    StatisticsDashboardFactory.getQuestions(),
                    'ger',
                    StatisticsDashboardFactory.getTypes().total
                ).length > 0 && controller.selectedItem.ranking.handle !== 'ger' && controller.selectedItem.ranking.handle !== 'esg';
            } else {
                return true;
            }
        };

        controller.isFinancialVisible = function () {
            if (controller.selectedItem.ranking && controller.selectedItem.ranking.handle) {
                return RankingsDashboardService.getQuestionsHandles(
                    StatisticsDashboardFactory.getQuestions(),
                    controller.selectedItem.ranking.handle,
                    StatisticsDashboardFactory.getTypes().financial
                ).length > 0;
            } else {
                return true;
            }
        };

        controller.isNumericVisible = function () {
            if (controller.selectedItem.ranking && controller.selectedItem.ranking.handle) {
                return RankingsDashboardService.getQuestionsHandles(
                    StatisticsDashboardFactory.getQuestions(),
                    controller.selectedItem.ranking.handle,
                    StatisticsDashboardFactory.getTypes().total
                ).length > 0;
            } else {
                return true;
            }
        };

        /**
        * Removes junk data from object.
        *
        * @param value
        * @returns {*}
        */
        controller.clearValues = function (value) {
            var allowedKeys = [
                'id',
                'name',
                'handle',
                'type'
            ], returnValue = {};
            if (value.ranking !== null && typeof value.ranking === 'object') {
              returnValue.ranking = {};
              angular.forEach(allowedKeys, function (key) {
                returnValue.ranking[key] = value.ranking[key];
              });
            }
            if (value.hasOwnProperty('dataType')) {
              returnValue.dataType = value.dataType;
            }

            return returnValue;
        };

        controller.selectedItemWatch = function (newValue, oldValue) {
            if (newValue.ranking && oldValue.ranking && newValue.ranking.id !== oldValue.ranking.id) {
                controller.handleClearFilters();
                controller.selectedItem.ranking = newValue.ranking;
            } else {
                newValue = controller.clearValues(newValue);
                if (newValue.dataType) {
                    RankingsDashboardFactory.setIsLoading(true);
                    RankingsDashboardFactory.setFilters(newValue);
                }
            }
            controller.isFinancial = RankingsDashboardFactory.isFinancial();
            controller.isPersonnel = RankingsDashboardFactory.isPersonnel();
            controller.isGerPersonnel = RankingsDashboardFactory.isGerPersonnel();
            controller.isNumeric = RankingsDashboardFactory.isNumeric();
        };

        controller.initWatches = function () {
            WatchService.create($scope, 'RankingsDashboardController.selectedItem', controller.selectedItemWatch, true);
            WatchService.create($scope, RankingsDashboardFactory.isLoading, function (value) {
                controller.isLoading = value;
            });
        };

        controller.loadQuestions = function () {
            RankingsService.getQuestions().then(function (response) {
                if (!response || !response.length) {
                    return false;
                }
                StatisticsDashboardFactory.setQuestions(response);
                StatisticsDashboardFactory.setQuestionsIncludingSubQuestions(response);
            });
        };

        controller.loadSubscriptions = function () {
            InstitutionsListService.getSubscriptions().then(function (response) {
                if (!response || !response.length) {
                    return false;
                }
                controller.rankings = [];
                angular.forEach(response, function (item) {
                    if (item.handle !== 'stars') {
                        controller.rankings.push(item);
                    }
                });
            });
        };

        controller.loadCountries = function () {
            InstitutionsListService.getCountries().then(function (response) {
                if (!response || !response.length) {
                    return false;
                }
                RankingsDashboardFactory.setCountries(response);
            });
        };

        controller.init = function () {
            controller.handleClearFilters();
            controller.initWatches();
            controller.loadQuestions();
            controller.loadSubscriptions();
            controller.loadCountries();
        };

        controller.init();
    }

}(window.angular));

(function(angular) {
    "use strict";

    angular
        .module('qsHub.rankings')
        .service('RankingsDashboardService', [
            '$resource',
            '$log',
            '$q',
            'constants',
            'RequestsQueueService',
            'RankingsDashboardFactory',
            'StatisticsDashboardFactory',
            RankingsDashboardService
        ]);

    function RankingsDashboardService(
        $resource,
        $log,
        $q,
        constants,
        RequestsQueueService,
        RankingsDashboardFactory,
        StatisticsDashboardFactory
    ) {
        var service = {
            deferred : {}
        };

        /**
         * Get answers list API endpoint.
         *
         * @returns {$resource}
         */
        service.getRankingsQuestionsModel = function () {
            return $resource(constants.api.rankings.url + '/v1/questions', {}, {
                get: {
                    cancellable : true
                }
            });
        };

        /**
         * Get questions list API endpoint.
         *
         * @returns {$resource}
         */
        service.getRankingsAnswersModel = function () {
            return $resource(constants.api.rankings.url + '/v1/answers', {}, {
                get: {
                    cancellable : true
                }
            });
        };

        service.getQuestionsHandles = function (questions, rankingHandle, questionType) {
            var questionsHandles = [];
            angular.forEach(questions, function (item) {
                if (item.type === questionType && item.rankings.indexOf(rankingHandle) !== -1) {
                    questionsHandles.push(item.handle);
                }
            });

            return questionsHandles;
        };

        service.getCsvData = function (columns, rows) {
            var data = [],
                cellValue,
                extractedRow,
                answerKey;
            rows.forEach(function (row) {
                extractedRow = [];
                columns.forEach(function (gridCol) {
                    if (gridCol.visible &&
                        gridCol.colDef.exporterSuppressExport !== true
                    ) {
                        cellValue = '';
                        if (row.hasOwnProperty('answers')) {
                            // Personnel type
                            angular.forEach(row.answers, function(answer, key) {
                                if (answer && answer.hasOwnProperty('value')) {
                                    answerKey = 'answers.' + key;
                                    row[answerKey] = answer.value;
                                } else {
                                    // Financial type / Numeric type
                                    if (key === 'value') {
                                        answerKey = 'answers.valueAsString';
                                        row[answerKey] = answer;
                                    }
                                    if (key === 'currency') {
                                        answerKey = 'answers.currency';
                                        row[answerKey] = answer;
                                    }
                                }
                            });
                        }
                        if (gridCol.colDef.field && row[gridCol.colDef.field]) {
                            cellValue = row[gridCol.colDef.field];
                        }
                        var extractedField = {
                            value: cellValue
                        };
                        extractedRow.push(extractedField);
                    }
                });
                data.push(extractedRow);
            });

            return data;
        };

        service.getRankingsAnswersByQuestions = function (filters) {
            RequestsQueueService.cancelAll('getRankingsAnswersByQuestions');
            var tempFilters = RankingsDashboardFactory.getFilters();
            if(filters['filter[ranking]']=== 'ger' && filters['filter[ranking]'] !== tempFilters.ranking.handle){
                filters['filter[ranking]'] = tempFilters.ranking.handle;
            }

            var Api = service.getRankingsAnswersModel().get(filters);
            RequestsQueueService.add(Api, 'getRankingsAnswersByQuestions');

            return Api.$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return data;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return [];
            });
        };

        service.getMultiSelectTerm = function(terms) {
            var items = [],
                i = 0;
            if (terms && terms.length) {
                for (i; i < terms.length; i++) {
                    items.push({
                        label: terms[i].charAt(0).toUpperCase() + terms[i].slice(1),
                        value: terms[i]
                    });
                }
            }

            return items;
        };

        /**
         * Format data on export.
         *
         * @param data
         * @param TimeService
         * @returns {*}
         */
        service.formatCsvResults = function (data, TimeService) {
            data = angular.copy(data);
            angular.forEach(data, function (column, dataKey) {
                angular.forEach(column, function (item, key) {
                    if (key === 'status') {
                        data[dataKey][key] = item ? data[dataKey][key].charAt(0).toUpperCase() + data[dataKey][key].slice(1) : null;
                    } else if (key === 'questionHandle') {
                        data[dataKey][key] = getQuestionName(item);
                    } else if (key === 'institutionCountryCode') {
                        data[dataKey][key] = getCountryName(item);
                    } else if (key === 'previouslySubmitted') {
                        data[dataKey][key] = item ? 'Yes' : item === false ? 'No' : '';
                    } else if (key === 'createdAt') {
                        data[dataKey][key] = TimeService.format(item, "MMM DD, YYYY HH:mm:ss");
                    } else if (key === 'answers' && item && typeof item.fte !== 'undefined' && typeof item.fte.estimate !== 'undefined') {
                        data[dataKey]['answers.fte.estimate'] = item.fte.estimate ? 'Yes' : 'No';
                    } else if (key === 'answers' && item && typeof item.url !== 'undefined' && typeof item.url.publiclyAvailable !== 'undefined') {
                        data[dataKey]['answers.url.publiclyAvailable'] = item.url.publiclyAvailable === true ? 'Yes' : (item.url.publiclyAvailable === false ? 'No' : '');
                    }else if (key === 'answers' && item && typeof item.value !== 'undefined' && typeof item.estimate !== 'undefined') {
                        data[dataKey]['answers.value'] = item.value;
                        data[dataKey]['answers.estimate'] = item.estimate ? 'Yes' : 'No';
                    } else if (key === 'answers' && item && typeof item.totalGraduatedStudents !== 'undefined' && typeof item.totalGraduatedStudents.estimate !== 'undefined') {
                        data[dataKey]['answers.totalGraduatedStudents.estimate'] = item.totalGraduatedStudents.estimate ? 'Yes' : 'No';
                    }
                    if (key === 'answers' && item && typeof item.url !== 'undefined' && typeof item.url.notAvailable !== 'undefined') {
                        data[dataKey]['answers.url.notAvailable'] = item.url.notAvailable === true ? 'Yes' : (item.url.notAvailable === false ? 'No' : '');
                    }
                    if (key === 'answers' && item && typeof item.notAvailable !== 'undefined') {
                        data[dataKey]['answers.notAvailable'] = item.notAvailable === true ? 'Yes' : (item.notAvailable === false ? 'No' : '');
                    }
                    if (key === 'answers' && item && typeof item.checked !== 'undefined' && typeof item.checked.value !== 'undefined') {
                        data[dataKey]['answers.checked.value'] = item.checked.value === true ? 'Yes' : (item.checked.value === 'false' ? 'No' : '');
                    }
                });
            });

            return data;
        };

        service.getQuestionValue = function (value,list) {
            var total = list.length,
            i = 0;
            for (i; i < total; i++) {
                if (list[i].handle === value) {
                   if (value === 'other'){
                       return 'Other (See Messages)';
                   }
                    return list[i].label;
                }
            }
            return value;
        };

        service.getGerPath = function (questionHandle) {
            var path = 'clients.statistics.data-submission.ger.employers-connections';
            if (questionHandle) {
                switch (questionHandle) {
                    case 'employer_partnerships_question':
                        path = 'clients.statistics.data-submission.ger.employers-partnerships';
                        break;
                    case 'number_of_students':
                    case 'number_of_students_auto':
                    case 'qs_employment_rate_auto':
                    case 'evidence_when_shared':
                    case 'evidence_which_year_conducted':
                    case 'evidence_link_or_screenshot':
                    case 'number_of_students_entrepreneurship':
                    case 'number_of_students_entrepreneurship_auto':
                        path = 'clients.statistics.data-submission.ger.employment-rate';
                        break;
                    case 'employers_connection_online':
                    case 'employers_connection_on_campus':
                        path = 'clients.statistics.data-submission.ger.employers-connections';
                        break;
                }
            }
            return path;
        };

        function getCountryName(institutionCountryCode) {
            var list = RankingsDashboardFactory.getCountries(),
                total = list.length,
                i = 0;
            for (i; i < total; i++) {
                 if (list[i].countryCode === institutionCountryCode) {
                     return list[i].name;
               }
            }
            return null;
        }

        function getQuestionName(handle) {
            var list = StatisticsDashboardFactory.getQuestionsIncludingSubQuestions(),
                total = list.length,
                i = 0;
            for (i; i < total; i++) {
                if (list[i].handle === handle) {
                    return list[i].name;
                }
            }
            return null;
        }

        return service;
    }
}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { factories: {} });

    App.factories.rankingsDashboard = function (
        StatisticsDashboardFactory
    ) {
        var filters = [],
            countries = [],
            reload = false,
            isLoading = false;

        return {
            setFilters: function (data) {
                filters = data;
            },
            getFilters: function () {
                return filters;
            },
            isFinancial: function () {
                return filters && filters.dataType === StatisticsDashboardFactory.getTypes().financial;
            },
            isPersonnel: function () {
                return filters && filters.dataType === StatisticsDashboardFactory.getTypes().personal;
            },
            isGerPersonnel: function () {
                return filters && filters.dataType === StatisticsDashboardFactory.getTypes().gerPersonal;
            },
            isNumeric: function () {
                return filters && filters.dataType === StatisticsDashboardFactory.getTypes().total;
            },
            setCountries: function (data) {
                countries = data;
            },
            getCountries: function () {
                return countries;
            },
            getRanking: function () {
                return filters && filters.ranking && filters.ranking.handle ? filters.ranking.handle : null;
            },
            reloadDatagrid: function (data) {
                reload = !!data;
            },
            setIsLoading: function (loading) {
                isLoading = loading;
            },
            isLoading: function () {
                return isLoading;
            },
            isDatagridReload: function () {
                return reload;
            }
        };
    };

    angular
        .module('qsHub.rankings')
        .factory('RankingsDashboardFactory', [
            'StatisticsDashboardFactory',
            App.factories.rankingsDashboard
        ]);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {
        components:{}
    });

    App.components.rankingsFinancialDatagrid = {
        templateUrl: '/scripts/modules/rankings/components/rankingsDashboard/rankingsFinancialDatagrid/rankingsFinancialDatagridView.html',
        controller: 'Rankings.RankingsFinancialDatagridController'
    };

    angular.module('qsHub.rankings').component('rankingsFinancialDatagrid', App.components.rankingsFinancialDatagrid);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers: {}});
    App.controllers.RankingsFinancialDatagrid = function ($scope,
                                                          $state,
                                                          $location,
                                                          $timeout,
                                                          constants,
                                                          uiGridConstants,
                                                          uiGridExporterConstants,
                                                          WatchService,
                                                          RankingsDashboardService,
                                                          uiGridExporterService,
                                                          GridService,
                                                          StatisticsHistoryService,
                                                          TimeService,
                                                          RankingsDashboardFactory,
                                                          StatisticsDashboardFactory,
                                                          DateRangeFixerService) {
        var controller = this;
        var lastCreatedAtDateFilter = null;
        var selectValues = {},
            lastSelectValues = {},
            filterChangesInProgress = false;
        var filterTimeoutMilliseconds = constants.datagridInputDelay;
        controller.questions = [];
        controller.isReloading = false;
        controller.isRendered = false;
        controller.gridApi = null;
        controller.reload = true;
        controller.createdAtField = 'createdAt';
        controller.questionHandleField = 'questionHandle';
        controller.filters = null;
        controller.defaultPaginationOptions = {
            page: 1,
            limit: constants.datagrid.contacts.defaultRowsNumber
        };
        controller.paginationOptions = GridService.getColumnsFilters(
            constants.gridNameSpaces.rankDashFinGridFilters,
            ['createdAt']
        );
        controller.columnsVisibility = GridService.getColumnsVisibility(
            constants.gridNameSpaces.rankDashFinGridVisibility
        );
        controller.types = StatisticsDashboardFactory.getTypes();
        controller.status = StatisticsDashboardFactory.getStatuses();
        $scope.gridOptions = null;
        controller.questionsHandles = [];
        controller.defaultFilters = {
            createdAt: {
                startDate: null,
                endDate: null
            }
        };

        controller.exportAll = function () {
            RankingsDashboardService.getRankingsAnswersByQuestions(
                angular.merge(
                    {
                        'filter[ranking]': controller.rankingHandle,
                        'filter[questionHandle][]': controller.questionsHandles
                    },
                    {
                        'filter[export]': true
                    }
                )
            ).then(function (response) {
                if (typeof response.results === 'undefined') {
                    return false;
                }
                response = RankingsDashboardService.formatCsvResults(response.results, TimeService);
                var gridApi = controller.gridApi,
                    gridOptions = $scope.gridOptions,
                    exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                        gridApi.grid,
                        uiGridExporterConstants.VISIBLE
                    ),
                    exportData = RankingsDashboardService.getCsvData(
                        gridApi.grid.columns,
                        response
                    ),
                    csvContent = uiGridExporterService.formatAsCsv(
                        exportColumnHeaders,
                        exportData,
                        gridOptions.exporterCsvColumnSeparator
                    );
                uiGridExporterService.downloadFile(
                    'Financial-data-' + RankingsDashboardFactory.getRanking() + '-' + TimeService.now() + '.csv',
                    csvContent,
                    gridOptions.exporterOlderExcelCompatibility
                );
            });
        };

        controller.exportVisible = function () {
            var results = $scope.gridOptions.data;
            results = RankingsDashboardService.formatCsvResults(results, TimeService);
            if (typeof results === 'undefined') {
                return false;
            }
            var gridApi = controller.gridApi,
                gridOptions = $scope.gridOptions,
                exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                    gridApi.grid,
                    uiGridExporterConstants.VISIBLE
                ),
                exportData = RankingsDashboardService.getCsvData(
                    gridApi.grid.columns,
                    results
                ),
                csvContent = uiGridExporterService.formatAsCsv(
                    exportColumnHeaders,
                    exportData,
                    gridOptions.exporterCsvColumnSeparator
                );

            uiGridExporterService.downloadFile(
                'Financial-data-' + RankingsDashboardFactory.getRanking() + '-' + TimeService.now() + '.csv',
                csvContent,
                gridOptions.exporterOlderExcelCompatibility
            );
        };

        controller.getPage = function () {
            // Avoid to reload (show/hide) the grid so sorting works
            if (controller.reload) {
                controller.isReloading = true;
                controller.isRendered = false;
            }
            RankingsDashboardFactory.setIsLoading(true);
            controller.reload = true;

            if (!('filter[questionHandle][]' in controller.paginationOptions) ||
                ('filter[questionHandle][]' in controller.paginationOptions &&
                controller.paginationOptions['filter[questionHandle][]'].length === 0)
            ) {
                controller.questionsHandles = RankingsDashboardService.getQuestionsHandles(
                    controller.questions,
                    controller.rankingHandle,
                    controller.types.financial
                );
            } else {
                controller.questionsHandles = [];
            }

            return RankingsDashboardService.getRankingsAnswersByQuestions(
                angular.merge({
                    'filter[ranking]': controller.rankingHandle,
                    'filter[questionHandle][]': controller.questionsHandles
                }, controller.paginationOptions, controller.defaultPaginationOptions)
            ).then(function (response) {
                if (response) {
                    controller.generateQuestionsList();
                    if (controller.defaultPaginationOptions.page > 1 && response.totalFiltered === 0 && response.totalMatching > 0) {
                        controller.defaultPaginationOptions.page = 1;
                        controller.gridApi.pagination.seek(1);
                        controller.getPage();
                    }
                    $scope.gridOptions.data = response.results;
                    $scope.gridOptions.minRowsToShow = response.results && response.results.length < $scope.gridOptions.paginationPageSize ? response.results.length : $scope.gridOptions.paginationPageSize;
                    $scope.gridOptions.totalItems = response.totalMatching;
                    if (!angular.equals([], response)) { // if not cancelled
                        $timeout(function () {
                            RankingsDashboardFactory.setIsLoading(false);
                        }, 50);
                    }
                } else {
                    $scope.gridOptions.data = [];
                }
                controller.isReloading = false;
            });
        };

        controller.handleGridFiltersChanges = function () {
            var filterColumn,
                gridApi = controller.gridApi;
            filterChangesInProgress = true;
            if(gridApi.pagination.getPage() > 1){
                gridApi.pagination.seek(1);
            }

            controller.paginationOptions = angular.copy(controller.defaultPaginationOptions);
            angular.forEach(gridApi.grid.columns, function (column) {
                filterColumn = column.filters[0].column ? column.filters[0].column : column.field;
                if (
                    typeof column.filters !== 'undefined' &&
                    column.filters[0].term !== 'undefined' &&
                    column.filters[0].term !== ''
                ) {
                    if (filterColumn === 'status' ||
                        filterColumn === 'institutionCountryCode' ||
                        filterColumn === 'questionHandle'
                    ) {
                        var arrayFilter = [];
                        angular.forEach(column.filters[0].term, function (term) {
                            arrayFilter.push(term.value);
                        });
                        controller.paginationOptions['filter[' + filterColumn + '][]'] = arrayFilter;
                    } else if (filterColumn === 'createdAt') {
                        if (typeof column.filters !== 'undefined' &&
                            typeof column.filters[0].term !== 'undefined' &&
                            typeof column.filters[1].term !== 'undefined') {
                            controller.paginationOptions['filter[' + filterColumn + ']'] = {
                                startDate: column.filters[0].term && isNaN(column.filters[0].term) && typeof column.filters[0].term === 'object' ?
                                    column.filters[0].term.format('x') : column.filters[0].term,
                                endDate: column.filters[1].term && isNaN(column.filters[1].term) && typeof column.filters[1].term === 'object' ?
                                    column.filters[1].term.format('x') : column.filters[1].term
                            };
                        } else {
                            $scope.gridOptions.columnDefs[controller.getColumnNumber(controller.createdAtField)].filters[0].term = null;
                            $scope.gridOptions.columnDefs[controller.getColumnNumber(controller.createdAtField)].filters[1].term = null;
                        }
                    } else {
                        controller.paginationOptions['filter[' + filterColumn + ']'] = column.filters[0].term;
                    }
                }
                if (typeof column.sort.direction !== 'undefined') {
                    controller.paginationOptions['sorting[' + filterColumn.replace('AsString', '') + ']'] = column.sort.direction;
                    controller.reload = false;
                }
            });

            if (controller.paginationOptions["filter[createdAt]"] === null || controller.paginationOptions["filter[createdAt]"] === undefined) {
                controller.paginationOptions["filter[createdAt]"] = controller.defaultFilters.createdAt;
            }
            if (controller.paginationOptions["filter[createdAt]"].startDate === null || !angular.isDefined(controller.paginationOptions["filter[createdAt]"].startDate)) {
                controller.paginationOptions["filter[createdAt]"].endDate = null;
                controller.filters.createdAt = {
                    startDate: null,
                    endDate: null
                };
            }
            GridService.storeColumnsFilters(
                constants.gridNameSpaces.rankDashFinGridFilters,
                controller.paginationOptions
            );

            if (angular.isDefined($scope.filterTimeout)) {
                $timeout.cancel($scope.filterTimeout);
            }

            GridService.generateSelectValues(controller.gridApi.grid.columns, selectValues);

            if (!angular.equals(selectValues, lastSelectValues)) {
                filterTimeoutMilliseconds = constants.datagridSelectDelay;
            }

            $scope.filterTimeout = $timeout(function () {
                controller.reloadDatagrid();
                filterChangesInProgress = false;
                lastSelectValues = angular.copy(selectValues);
                filterTimeoutMilliseconds = constants.datagridInputDelay;
            }, filterTimeoutMilliseconds);
        };

        function removeFilter(field) {
            switch (field) {
                case 'questionHandle':
                    controller.paginationOptions['filter[questionHandle][]'] = [];
                    return;
                case 'institutionCountryCode':
                    controller.paginationOptions['filter[institutionCountryCode][]'] = null;
                    return;
                case 'status':
                    controller.paginationOptions['filter[status][]'] = null;
                    return;
                default:
                    controller.paginationOptions['filter[' + field + ']'] = null;
                    return;
            }
        }

        controller.handleGridVisibleChanges = function () {
            var columnsVisibility = GridService.getColumnsVisibility(constants.gridNameSpaces.rankDashFinGridVisibility),
            reload = false;

            if (columnsVisibility) {
                angular.forEach($scope.gridOptions.columnDefs, function (column, key) {
                    if (columnsVisibility[column.field] === true &&
                        column.visible === false
                    ) {
                        if (typeof $scope.gridOptions.columnDefs[key].filter === 'undefined') {
                            $scope.gridOptions.columnDefs[key].filter = {};
                        }
                        if (typeof  $scope.gridOptions.columnDefs[key].filter !== 'undefined' &&
                            $scope.gridOptions.columnDefs[key].filter.hasOwnProperty('term') &&
                            $scope.gridOptions.columnDefs[key].filter.term) {
                                removeFilter(column.field);
                                reload = true;
                        }
                        $scope.gridOptions.columnDefs[key].filter.term = null;
                        if (column.field === 'createdAt') {
                            delete    $scope.gridOptions.columnDefs[key].filters[0].term;
                            controller.filters.createdAt = {
                                startDate: null,
                                endDate: null
                            };
                        }
                    }
                });
                GridService.storeColumnsFilters(
                    constants.gridNameSpaces.rankDashFinGridFilters,
                    controller.paginationOptions
                );
            }

            if ($scope.gridOptions) {
                controller.columnsVisibility = GridService.getGridColumnsVisibility($scope.gridOptions.columnDefs);
                GridService.storeColumnsVisibility(
                    constants.gridNameSpaces.rankDashFinGridVisibility,
                    controller.columnsVisibility
                );
            }
            if (reload) {
                controller.reloadDatagrid();
            }
        };

        controller.handleDatagridRowClick = function (row) {
            var ranking = RankingsDashboardFactory.getRanking(),
            path = 'clients.statistics.data-submission.' + ranking;
            if (ranking === 'ger') {
                path = RankingsDashboardService.getGerPath(row.questionHandle);
            }
            return $location.path(
                $state.href(path).replace('#', '')
            ).search({
                coreId: row.institutionCoreId,
                question: row.questionHandle
            });
        };

        controller.reloadDatagrid = function () {
            controller.getPage();
        };

        /**
         * Populates input text field for date field
         *
         * @param {string} filterName
         * @param {mixed} defaultValue
         */
        controller.applyDateFilter = function (filterName, defaultValue) {
            var filter = GridService.getFilterByField(controller.paginationOptions, filterName, defaultValue);
            if (angular.isDefined(filter) &&
                filter !== null
            ) {
                if (angular.isDefined(filter.startDate) &&
                    angular.isDefined(filter.endDate)
                ) {
                    if (filter.startDate !== null &&
                        filter.startDate !== null
                    ) {
                        controller.defaultFilters[filterName] = {
                            startDate: parseInt(filter.startDate, 10),
                            endDate: parseInt(filter.endDate, 10)
                        };
                    } else {
                        controller.defaultFilters[filterName] = {
                            startDate: null,
                            endDate: null
                        };
                    }
                }
            }
        };

        controller.handleCreatedAtDateRange = function (event) {

            if (DateRangeFixerService.sameDayAndNoModel(event, controller.filters.createdAt, lastCreatedAtDateFilter)) {
                event.model.startDate = lastCreatedAtDateFilter.startDate;
                event.model.endDate = lastCreatedAtDateFilter.endDate;
                controller.filters.createdAt = {
                    startDate: lastCreatedAtDateFilter.startDate,
                    endDate: lastCreatedAtDateFilter.endDate
                };
            } else if (DateRangeFixerService.todayRangeAndNoModel(event, lastCreatedAtDateFilter)) {
                event.model.startDate = TimeService.getStartOfToday();
                event.model.endDate = TimeService.getEndOfToday();
                controller.filters.createdAt = {
                    startDate: event.model.startDate.toISOString(),
                    endDate: event.model.endDate.toISOString()
                };
            }

            if (event === null || typeof event === 'undefined') {
                $scope.gridOptions.columnDefs[controller.getColumnNumber(controller.createdAtField)].filters[0].term = null;
                $scope.gridOptions.columnDefs[controller.getColumnNumber(controller.createdAtField)].filters[1].term = null;
            } else if (event.model.startDate && event.model.endDate) {
                $scope.gridOptions.columnDefs[controller.getColumnNumber(controller.createdAtField)].filters[0].term = typeof event.model.startDate === 'object' ? event.model.startDate.format('x') : event.model.startDate;
                $scope.gridOptions.columnDefs[controller.getColumnNumber(controller.createdAtField)].filters[1].term = typeof event.model.endDate === 'object' ? event.model.endDate.format('x') : event.model.endDate;
            }

            lastCreatedAtDateFilter = angular.copy({
                startDate: event.model.startDate,
                endDate: event.model.endDate
            });
        };

        /**
         * getColumnNumber
         * @param  {string} columnName
         */
        controller.getColumnNumber = function (columnName) {
            var key = 0,
                total = $scope.gridOptions.columnDefs.length;
            for (key; key < total; key++) {
                if ($scope.gridOptions.columnDefs[key] &&
                    $scope.gridOptions.columnDefs[key].field === columnName
                ) {
                    return key;
                }
            }
            return null;
        };

        controller.generateQuestionsList = function () {
            var questionsList = [];
            angular.forEach(controller.questions, function (item) {
                if (item.type === controller.types.financial &&
                    item.rankings.indexOf(controller.rankingHandle) !== -1
                ) {
                    if(controller.rankingHandle === 'esg') {
                        if (item.handle === 'students_receive_scholarship_covering_hundred_percent') {
                            if (!item.name.includes(' (AI 3)')) {
                                item.name = item.name + ' (AI 3)';
                            }
                        }
                        if (item.handle === 'students_receive_scholarship_covering_fifty_percent') {
                            if (!item.name.includes(' (AI 4)')) {
                                item.name = item.name + ' (AI 4)';
                            }
                        }
                    }
                    questionsList.push({
                        value: item.handle,
                        label: item.name
                    });
                }
            });
            controller.questionsList = questionsList;
            if ($scope.gridOptions) {
                $scope.gridOptions.columnDefs[controller.getColumnNumber(controller.questionHandleField)].filter.selectOptions = controller.questionsList;
            }
        };

        controller.initDataGrid = function () {
            var countriesList = [];
            angular.forEach(RankingsDashboardFactory.getCountries(), function (item) {
                countriesList.push({
                    value: item.countryCode,
                    label: item.name
                });
            });
            var statusList = [];
            angular.forEach(controller.status, function (item) {
                statusList.push({
                    value: item,
                    label: item.charAt(0).toUpperCase() + item.slice(1)
                });
            });
            controller.generateQuestionsList();

            var multiSelectTemplate = '/scripts/shared/ui-grid/templates/multiSelectFilterHeaderTemplate.html',
                financialCellTemplate = '/scripts/modules/rankings/components/rankingsDashboard/datagrid/financialCellTemplate.html',
                currencyCellTemplate = '/scripts/modules/rankings/components/rankingsDashboard/datagrid/currencyCellTemplate.html',
                selectCellTemplate = '/scripts/shared/ui-grid/templates/selectCellTemplate.html',
                selectTemplate = '/scripts/shared/ui-grid/templates/selectFilterHeaderTemplate.html',
                statusCellTemplate = '/scripts/modules/rankings/components/rankingsDashboard/datagrid/statusCellTemplate.html',
                questionCellTemplate = '/scripts/modules/rankings/components/rankingsDashboard/datagrid/questionCellTemplate.html',
                dateCellTemplate = '/scripts/shared/ui-grid/templates/dateCellTemplate.html';

            $scope.gridOptions = {
                appScopeProvider: controller,
                enableSorting: true,
                exporterMenuCsv: false,
                exporterMenuAllData: false,
                exporterMenuPdf: false,
                enableGridMenu: true,
                exporterSuppressColumns: ['login'],
                useExternalFiltering: true,
                showGridFooter: true,
                selectedItems: $scope.selectedRankingId,
                enableFiltering: true,
                enableColumnResize: true,
                enableFullRowSelection: true,
                enableRowSelection: true,
                multiSelect: false,
                enableRowHeaderSelection: false,
                paginationPageSizes: [constants.datagrid.contacts.defaultRowsNumber, 50, 100],
                paginationPageSize: constants.datagrid.contacts.defaultRowsNumber,
                useExternalPagination: true,
                useExternalSorting: true,
                gridMenuCustomItems: [
                    {
                        title: ' Export visible data as csv',
                        action: controller.exportVisible,
                        order: 209
                    },
                    {
                        title: ' Export all data as csv',
                        action: controller.exportAll,
                        order: 210,
                        templateUrl: '/scripts/modules/rankings/components/rankingsDashboard/datagrid/exportAllMenuItem.html',
                    }
                ],
                rowTemplate: '/scripts/modules/rankings/components/rankingsDashboard/datagrid/rowTemplate.html',
                columnDefs: [
                    {
                        displayName: 'Institution Name',
                        field: 'institutionName',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'institutionName', true),
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'institutionName', '')
                        }
                    },
                    {
                        displayName: 'Core ID',
                        field: 'institutionCoreIdAsString',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'institutionCoreIdAsString', true),
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'institutionCoreIdAsString', '')
                        }
                    },
                    {
                        displayName: 'Country',
                        field: 'institutionCountryCode',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'institutionCountryCode', true),
                        filter: {
                            column: 'institutionCountryCode',
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: countriesList,
                            searchEnabled: true,
                            term: RankingsDashboardService.getMultiSelectTerm(
                                GridService.getFilterByField(controller.paginationOptions, 'institutionCountryCode', [], true)
                            )
                        },
                        filterHeaderTemplate: multiSelectTemplate,
                        cellTemplate: selectCellTemplate
                    },
                    {
                        displayName: 'Question',
                        field: 'questionHandle',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'questionHandle', true),
                        filter: {
                            column: 'questionHandle',
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: controller.questionsList,
                            searchEnabled: true,
                            term: RankingsDashboardService.getMultiSelectTerm(
                                GridService.getFilterByField(controller.paginationOptions, 'questionHandle', [], true)
                            )
                        },
                        filterHeaderTemplate: multiSelectTemplate,
                        cellTemplate: selectCellTemplate
                    },
                    {
                        displayName: 'Average Amount',
                        field: 'answers.valueAsString',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'answers.valueAsString', true),
                        filter: {
                            column: 'answers.valueAsString',
                            term: GridService.getFilterByField(controller.paginationOptions, 'answers.valueAsString', '')
                        },
                        cellTemplate: financialCellTemplate
                    },
                    {
                        displayName: 'Currency',
                        field: 'answers.currency',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'answers.currency', true),
                        filter: {
                            column: 'answers.currency',
                            term: GridService.getFilterByField(controller.paginationOptions, 'answers.currency', '')
                        },
                        cellTemplate: currencyCellTemplate
                    },
                    {
                        displayName: 'N/A',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'answers.notAvailable', true),
                        field: 'answers.notAvailable',
                        filter: {
                            column: 'answers.notAvailable',
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: [
                                {value: true, label: 'Yes'},
                                {value: false, label: 'No'}
                            ],
                            term: GridService.getFilterByField(controller.paginationOptions, 'answers.notAvailable')
                        },
                        maxWidth: 80,
                        filterHeaderTemplate: selectTemplate,
                        cellTemplate: selectCellTemplate
                    },
                    {
                        displayName: 'Status',
                        field: 'status',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'status', true),
                        filter: {
                            column: 'status',
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: statusList,
                            searchEnabled: true,
                            term: RankingsDashboardService.getMultiSelectTerm(
                                GridService.getFilterByField(controller.paginationOptions, 'status', [], true)
                            )
                        },
                        filterHeaderTemplate: multiSelectTemplate,
                        cellTemplate: statusCellTemplate
                    },
                    {
                        displayName: 'Date Added',
                        field: controller.createdAtField,
                        filterCellFiltered: true,
                        filters: [
                            {
                                visible: true,
                                condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL
                            },
                            {
                                condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL
                            }
                        ],
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'createdAt', [], true),
                            applyTextFilter: controller.applyDateFilter(controller.createdAtField, '')
                        },
                        cellTemplate: dateCellTemplate,
                        filterHeaderTemplate: '/scripts/components/profiles/tu/departments/datagrid/createdAtFilterHeaderTemplateWithDropDown.html',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'createdAt', true)
                    },
                ],
                onRegisterApi: function (gridApi) {
                    controller.gridApi = gridApi;
                    gridApi.selection.on.rowSelectionChanged($scope, function (row) {
                        controller.handleDatagridRowClick(row.entity);
                    });

                    gridApi.core.on.rowsRendered($scope, function () {
                        $timeout(function() {
                            controller.isRendered = true;
                        },250);
                        GridService.generateSelectValues(controller.gridApi.grid.columns, selectValues);
                        GridService.generateSelectValues(controller.gridApi.grid.columns, lastSelectValues);
                    });
                    gridApi.pagination.on.paginationChanged($scope, function (newPage, limit) {
                        controller.defaultPaginationOptions.page = newPage;
                        controller.defaultPaginationOptions.limit = limit;
                        if(!filterChangesInProgress) { 
                            controller.reloadDatagrid();
                        }
                    });
                    gridApi.core.on.sortChanged($scope, controller.handleGridFiltersChanges);
                    gridApi.core.on.filterChanged($scope, controller.handleGridFiltersChanges);
                    gridApi.core.on.rowsVisibleChanged($scope, controller.handleGridVisibleChanges);
                }
            };
            datePickersFixes();
            controller.filters = angular.extend({}, controller.defaultFilters);
        };

        function datePickersFixes() {
            // This is needed to add 'x' icon in date field when prepopulating dates
            var createdAtField = GridService.getFilterByField(controller.paginationOptions, 'createdAt', '');
            if (createdAtField !== null && controller.paginationOptions["filter[createdAt]"] !== null) {
                if (angular.isDefined(createdAtField) &&
                    angular.isDefined(controller.paginationOptions["filter[createdAt]"]) &&
                    angular.isDefined(controller.paginationOptions["filter[createdAt]"].startDate) &&
                    angular.isDefined(controller.paginationOptions["filter[createdAt]"].endDate)
                ) {
                    $scope.gridOptions.columnDefs[getColumnNumber('createdAt')].filters[0].term = controller.paginationOptions["filter[createdAt]"].startDate;
                    $scope.gridOptions.columnDefs[getColumnNumber('createdAt')].filters[1].term = controller.paginationOptions["filter[createdAt]"].endDate;
                }
            }
        }

        function getColumnNumber(columnName) {
            var key = 0,
                total = $scope.gridOptions.columnDefs.length;
            for (key; key < total; key++) {
                if ($scope.gridOptions.columnDefs[key] &&
                    $scope.gridOptions.columnDefs[key].field === columnName
                ) {
                    return key;
                }
            }

            return null;
        }

        controller.getQuestionName = function (questionHandle) {
            return StatisticsHistoryService.getQuestionName(questionHandle, controller.questions);
        };

        controller.questionsWatch = function (questions) {
            if (!questions || !questions.length) {
                return;
            }
            controller.questions = questions;
            controller.initDataGrid();
            controller.getPage();
        };

        controller.resetGridFilters = function (filters) {
            // reset filtering
            if (filters !== null && filters.dataType === null && controller.gridApi) {
                GridService.resetConditions();
                angular.forEach(controller.gridApi.grid.columns, function (column) {
                    delete(column.colDef.filter.term);
                });
            }
        };

        controller.filtersWatch = function (filters) {
            controller.resetGridFilters(filters);
            if (!filters || !filters.ranking || !filters.dataType || !RankingsDashboardFactory.isFinancial()) {
                return;
            }
            RankingsDashboardFactory.reloadDatagrid(false);
            controller.rankingHandle = filters.ranking.handle;

            if (controller.gridApi) {
                controller.reloadDatagrid();
            } else {
                WatchService.create($scope, StatisticsDashboardFactory.getQuestions, controller.questionsWatch);
            }
        };

        controller.datagridReloadWatch = function (reload) {
            if (!reload) {
                return false;
            }
            RankingsDashboardFactory.setIsLoading(true);
            controller.filtersWatch(RankingsDashboardFactory.getFilters());
        };

        controller.initWatches = function () {
            WatchService.create($scope, RankingsDashboardFactory.getFilters, controller.filtersWatch, true);
            WatchService.create($scope, RankingsDashboardFactory.isDatagridReload, controller.datagridReloadWatch);
        };

        controller.$onInit = function () {
            controller.initWatches();
        };
    };

    angular
        .module('qsHub.rankings')
        .controller('Rankings.RankingsFinancialDatagridController', [
            '$scope',
            '$state',
            '$location',
            '$timeout',
            'constants',
            'uiGridConstants',
            'uiGridExporterConstants',
            'WatchService',
            'RankingsDashboardService',
            'uiGridExporterService',
            'UiGridService',
            'StatisticsHistoryService',
            'TimeService',
            'RankingsDashboardFactory',
            'StatisticsDashboardFactory',
            'DateRangeFixerService',
            App.controllers.RankingsFinancialDatagrid
        ]);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {
        components:{}
    });

    App.components.rankingsPersonnelGerDatagrid = {
        templateUrl: '/scripts/modules/rankings/components/rankingsDashboard/rankingsPersonnelGerDatagrid/rankingsPersonnelGerDatagridView.html',
        controller: 'Rankings.RankingsPersonnelGerDatagridController'
    };

    angular.module('qsHub.rankings').component('rankingsPersonnelGerDatagrid', App.components.rankingsPersonnelGerDatagrid);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers: {}});
    App.controllers.RankingsPersonnelGerDatagrid = function ($scope,
                                                          $state,
                                                          $location,
                                                          $timeout,
                                                          constants,
                                                          uiGridConstants,
                                                          uiGridExporterConstants,
                                                          WatchService,
                                                          RankingsDashboardService,
                                                          uiGridExporterService,
                                                          GridService,
                                                          StatisticsHistoryService,
                                                          TimeService,
                                                          RankingsDashboardFactory,
                                                          StatisticsDashboardFactory,
                                                          DateRangeFixerService) {
        var controller = this;
        var lastCreatedAtDateFilter = null;
        var selectValues = {},
            lastSelectValues = {};
        var filterTimeoutMilliseconds = constants.datagridInputDelay;
        controller.questions = [];
        controller.isReloading = false;
        controller.isRendered = false;
        controller.gridApi = null;
        controller.reload = true;
        controller.createdAtField = 'createdAt';
        controller.questionHandleField = 'questionHandle';
        controller.filters = null;
        controller.defaultPaginationOptions = {
            page: 1,
            limit: constants.datagrid.contacts.defaultRowsNumber
        };
        controller.paginationOptions = GridService.getColumnsFilters(
            constants.gridNameSpaces.rankDashPerGridFilters,
            ['createdAt']
        );
        controller.columnsVisibility = GridService.getColumnsVisibility(
            constants.gridNameSpaces.rankDashPerGridVisibility
        );
        controller.types = StatisticsDashboardFactory.getTypes();
        controller.status = StatisticsDashboardFactory.getStatuses();
        $scope.gridOptions = null;
        controller.questionsHandles = [];
        controller.defaultFilters = {
            createdAt: {
                startDate: null,
                endDate: null
            }
        };
        controller.exportAll = function () {
            RankingsDashboardService.getRankingsAnswersByQuestions(
                angular.merge(
                    {
                        'filter[ranking]': controller.rankingHandle,
                        'filter[questionHandle][]': controller.questionsHandles
                    },
                    {
                        'filter[export]': true
                    }
                )
            ).then(function (response) {
                if (typeof response.results === 'undefined') {
                    return false;
                }
                response = RankingsDashboardService.formatCsvResults(response.results, TimeService);
                var gridApi = controller.gridApi,
                    gridOptions = $scope.gridOptions,
                    exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                        gridApi.grid,
                        uiGridExporterConstants.VISIBLE
                    ),
                    exportData = RankingsDashboardService.getCsvData(
                        gridApi.grid.columns,
                        response
                    ),
                    csvContent = uiGridExporterService.formatAsCsv(
                        exportColumnHeaders,
                        exportData,
                        gridOptions.exporterCsvColumnSeparator
                    );
                uiGridExporterService.downloadFile(
                    'Personnel-data-' + RankingsDashboardFactory.getRanking() + '-' + TimeService.now() + '.csv',
                    csvContent,
                    gridOptions.exporterOlderExcelCompatibility
                );
            });
        };

        controller.exportVisible = function () {
            var results = $scope.gridOptions.data;
            results = RankingsDashboardService.formatCsvResults(results, TimeService);
            if (typeof results === 'undefined') {
                return false;
            }
            var gridApi = controller.gridApi,
                gridOptions = $scope.gridOptions,
                exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                    gridApi.grid,
                    uiGridExporterConstants.VISIBLE
                ),
                exportData = RankingsDashboardService.getCsvData(
                    gridApi.grid.columns,
                    results
                ),
                csvContent = uiGridExporterService.formatAsCsv(
                    exportColumnHeaders,
                    exportData,
                    gridOptions.exporterCsvColumnSeparator
                );

            uiGridExporterService.downloadFile(
                'Personnel-data-' + RankingsDashboardFactory.getRanking() + '-' + TimeService.now() + '.csv',
                csvContent,
                gridOptions.exporterOlderExcelCompatibility
            );
        };

        controller.getPage = function () {
            // Avoid to reload (show/hide) the grid so sorting works
            if (controller.reload) {
                controller.isReloading = true;
                controller.isRendered = false;
            }
            RankingsDashboardFactory.setIsLoading(true);
            controller.reload = true;

            if (!('filter[questionHandle][]' in controller.paginationOptions) ||
                ('filter[questionHandle][]' in controller.paginationOptions &&
                controller.paginationOptions['filter[questionHandle][]'].length === 0)
            ) {
                controller.questionsHandles = RankingsDashboardService.getQuestionsHandles(
                    controller.questions,
                    controller.rankingHandle,
                    controller.types.gerPersonal
                );
            } else {
                controller.questionsHandles = [];
            }

            return RankingsDashboardService.getRankingsAnswersByQuestions(
                angular.merge({
                    'filter[ranking]': controller.rankingHandle,
                    'filter[questionHandle][]': controller.questionsHandles
                }, controller.paginationOptions, controller.defaultPaginationOptions)
            ).then(function (response) {
                if (response) {
                    controller.generateQuestionsList();
                    if (controller.defaultPaginationOptions.page > 1 && response.totalFiltered === 0 && response.totalMatching > 0) {
                        controller.defaultPaginationOptions.page = 1;
                        controller.gridApi.pagination.seek(1);
                        controller.getPage();
                    }
                    $scope.gridOptions.data = response.results;
                    $scope.gridOptions.minRowsToShow = response.results && response.results.length < $scope.gridOptions.paginationPageSize ? response.results.length : $scope.gridOptions.paginationPageSize;
                    $scope.gridOptions.totalItems = response.totalMatching;
                    if (!angular.equals([], response)) { // if not cancelled
                        $timeout(function () {
                            RankingsDashboardFactory.setIsLoading(false);
                        }, 50);
                    }
                } else {
                    $scope.gridOptions.data = [];
                }
                controller.isReloading = false;
            });
        };


        controller.handleGridFiltersChanges = function () {
            var filterColumn,
                gridApi = controller.gridApi,
                sortKeys = [];
            controller.paginationOptions = angular.copy(controller.defaultPaginationOptions);

            angular.forEach(controller.gridApi.grid.columns, function (column) {
                if (typeof column.filters !== 'undefined' && column.filters[0].term !== 'undefined') {
                    if (
                        column.filters[0].type === uiGridConstants.filter.SELECT &&
                        typeof column.filters[0].term === 'object' &&
                        column.filters[0].term !== null &&
                        typeof column.filters[0].term.value !== 'undefined'
                    ) {
                        column.filters[0].term = column.filters[0].term.value;
                    }
                }
                if (typeof column.sort.priority !== 'undefined') {
                    sortKeys[column.sort.priority] = column.name;
                }
            });
            if (sortKeys.length > 1) {
                var latestSortKey = sortKeys.pop();
                angular.forEach(controller.gridApi.grid.columns, function (column) {
                    if (typeof column.sort.priority !== 'undefined' && column.name !== latestSortKey) {
                        column.sort = {};
                    }
                });
            }

            angular.forEach(gridApi.grid.columns, function (column) {
                filterColumn = column.filters[0].column ? column.filters[0].column : column.field;
                if (
                    typeof column.filters !== 'undefined' &&
                    column.filters[0].term !== 'undefined' &&
                    column.filters[0].term !== ''
                ) {
                    if (filterColumn === 'status' ||
                        filterColumn === 'institutionCountryCode' ||
                        filterColumn === 'questionHandle'
                    ) {
                        var arrayFilter = [];
                        angular.forEach(column.filters[0].term, function (term) {
                            arrayFilter.push(term.value);
                        });
                        controller.paginationOptions['filter[' + filterColumn + '][]'] = arrayFilter;
                    } else if (filterColumn === 'createdAt') {
                        if (typeof column.filters !== 'undefined' &&
                            typeof column.filters[0].term !== 'undefined' &&
                            typeof column.filters[1].term !== 'undefined') {
                            controller.paginationOptions['filter[' + filterColumn + ']'] = {
                                startDate: column.filters[0].term && isNaN(column.filters[0].term) && typeof column.filters[0].term === 'object' ?
                                    column.filters[0].term.format('x') : column.filters[0].term,
                                endDate: column.filters[1].term && isNaN(column.filters[1].term) && typeof column.filters[1].term === 'object' ?
                                    column.filters[1].term.format('x') : column.filters[1].term
                            };
                        } else {
                            $scope.gridOptions.columnDefs[controller.getColumnNumber(controller.createdAtField)].filters[0].term = null;
                            $scope.gridOptions.columnDefs[controller.getColumnNumber(controller.createdAtField)].filters[1].term = null;
                        }
                    } else {
                        controller.paginationOptions['filter[' + filterColumn + ']'] = column.filters[0].term;
                    }
                }
                if (typeof column.sort.direction !== 'undefined') {
                    controller.paginationOptions['sorting[' + filterColumn.replace('AsString', '') + ']'] = column.sort.direction;
                    controller.reload = false;
                }
            });
            if (controller.paginationOptions["filter[createdAt]"] === null || controller.paginationOptions["filter[createdAt]"] === undefined) {
                controller.paginationOptions["filter[createdAt]"] = controller.defaultFilters.createdAt;
            }
            if (controller.paginationOptions["filter[createdAt]"].startDate === null || !angular.isDefined(controller.paginationOptions["filter[createdAt]"].startDate)) {
                controller.paginationOptions["filter[createdAt]"].endDate = null;
                controller.filters.createdAt = {
                    startDate: null,
                    endDate: null
                };
            }
            GridService.storeColumnsFilters(
                constants.gridNameSpaces.rankDashPerGridFilters,
                controller.paginationOptions
            );

            if (angular.isDefined($scope.filterTimeout)) {
                $timeout.cancel($scope.filterTimeout);
            }

            GridService.generateSelectValues(controller.gridApi.grid.columns, selectValues);

            if (!angular.equals(selectValues, lastSelectValues)) {
                filterTimeoutMilliseconds = constants.datagridSelectDelay;
            }

            $scope.filterTimeout = $timeout(function () {
                controller.reloadDatagrid();
                lastSelectValues = angular.copy(selectValues);
                filterTimeoutMilliseconds = constants.datagridInputDelay;
            }, filterTimeoutMilliseconds);

        };

        controller.getColumnfield = function (fieldName) {
            return fieldName.indexOf('answers') !== -1 ? fieldName + '.valueAsString' : fieldName;
        };

        controller.handleGridVisibleChanges = function () {
            var columnsVisibility = GridService.getColumnsVisibility(constants.gridNameSpaces.rankDashPerGridVisibility);

            if (columnsVisibility) {
                angular.forEach($scope.gridOptions.columnDefs, function (column, key) {
                    if (columnsVisibility[column.field] === true &&
                        column.visible === false
                    ) {
                        $scope.gridOptions.columnDefs[key].filter.term = null;
                        controller.paginationOptions['filter[' + controller.getColumnfield(column.field) + ']'] = null;
                    }
                });
                GridService.storeColumnsFilters(
                    constants.gridNameSpaces.rankDashPerGridFilters,
                    controller.paginationOptions
                );
            }

            if ($scope.gridOptions) {
                controller.columnsVisibility = GridService.getGridColumnsVisibility($scope.gridOptions.columnDefs);
                GridService.storeColumnsVisibility(
                    constants.gridNameSpaces.rankDashPerGridVisibility,
                    controller.columnsVisibility
                );
            }
        };

        controller.handleDatagridRowClick = function (row) {
            var ranking = RankingsDashboardFactory.getRanking(),
            path = 'clients.statistics.data-submission.' + ranking;
            if (ranking === 'ger') {
                path = RankingsDashboardService.getGerPath(row.questionHandle);
            }
            return $location.path(
                $state.href(path).replace('#', '')
            ).search({
                coreId: row.institutionCoreId,
                question: row.questionHandle
            });
        };

        controller.reloadDatagrid = function () {
            controller.getPage();
        };

        controller.getValue = function (dataObject) {
            return dataObject ? dataObject[Object.keys(dataObject)[0]] : {value: null, estimate: null};
        };
        /**
         * Populates input text field for date field
         *
         * @param {string} filterName
         * @param {mixed} defaultValue
         */
        controller.applyDateFilter = function (filterName, defaultValue) {
            var filter = GridService.getFilterByField(controller.paginationOptions, filterName, defaultValue);
            if (angular.isDefined(filter) &&
                filter !== null
            ) {
                if (angular.isDefined(filter.startDate) &&
                    angular.isDefined(filter.endDate)
                ) {
                    if (filter.startDate !== null &&
                        filter.startDate !== null
                    ) {
                        controller.defaultFilters[filterName] = {
                            startDate: parseInt(filter.startDate, 10),
                            endDate: parseInt(filter.endDate, 10)
                        };
                    } else {
                        controller.defaultFilters[filterName] = {
                            startDate: null,
                            endDate: null
                        };
                    }
                }
            }
        };

        controller.handleCreatedAtDateRange = function (event) {

            if (DateRangeFixerService.sameDayAndNoModel(event, controller.filters.createdAt, lastCreatedAtDateFilter)) {
                event.model.startDate = lastCreatedAtDateFilter.startDate;
                event.model.endDate = lastCreatedAtDateFilter.endDate;
                controller.filters.createdAt = {
                    startDate: lastCreatedAtDateFilter.startDate,
                    endDate: lastCreatedAtDateFilter.endDate
                };
            } else if (DateRangeFixerService.todayRangeAndNoModel(event, lastCreatedAtDateFilter)) {
                event.model.startDate = TimeService.getStartOfToday();
                event.model.endDate = TimeService.getEndOfToday();
                controller.filters.createdAt = {
                    startDate: event.model.startDate.toISOString(),
                    endDate: event.model.endDate.toISOString()
                };
            }

            if (event === null || typeof event === 'undefined') {
                $scope.gridOptions.columnDefs[controller.getColumnNumber(controller.createdAtField)].filters[0].term = null;
                $scope.gridOptions.columnDefs[controller.getColumnNumber(controller.createdAtField)].filters[1].term = null;
            } else if (event.model.startDate && event.model.endDate) {
                $scope.gridOptions.columnDefs[controller.getColumnNumber(controller.createdAtField)].filters[0].term = typeof event.model.startDate === 'object' ? event.model.startDate.format('x') : event.model.startDate;
                $scope.gridOptions.columnDefs[controller.getColumnNumber(controller.createdAtField)].filters[1].term = typeof event.model.endDate === 'object' ? event.model.endDate.format('x') : event.model.endDate;
            }

            lastCreatedAtDateFilter = angular.copy(controller.filters.createdAt);
        };

        /**
         * getColumnNumber
         * @param  {string} columnName
         */
        controller.getColumnNumber = function (columnName) {
            var key = 0,
                total = $scope.gridOptions.columnDefs.length;
            for (key; key < total; key++) {
                if ($scope.gridOptions.columnDefs[key] &&
                    $scope.gridOptions.columnDefs[key].field === columnName
                ) {
                    return key;
                }
            }
            return null;
        };

        controller.generateQuestionsList = function () {
            var questionsList = [];
            angular.forEach(controller.questions, function (item) {
                if (item.type === controller.types.gerPersonal &&
                    item.rankings.indexOf(controller.rankingHandle) !== -1
                ) {
                    questionsList.push({
                        value: item.handle,
                        label: item.name
                    });
                }
            });
            controller.questionsList = questionsList;
            if ($scope.gridOptions) {
                $scope.gridOptions.columnDefs[controller.getColumnNumber(controller.questionHandleField)].filter.selectOptions = controller.questionsList;
            }
        };

        controller.initDataGrid = function () {
            var countriesList = [];
            angular.forEach(RankingsDashboardFactory.getCountries(), function (item) {
                countriesList.push({
                    value: item.countryCode,
                    label: item.name
                });
            });
            var statusList = [];
            angular.forEach(controller.status, function (item) {
                statusList.push({
                    value: item,
                    label: item.charAt(0).toUpperCase() + item.slice(1)
                });
            });
            controller.generateQuestionsList();

            var multiSelectTemplate = '/scripts/shared/ui-grid/templates/multiSelectFilterHeaderTemplate.html',
                selectCellTemplate = '/scripts/shared/ui-grid/templates/selectCellTemplate.html',
                statusCellTemplate = '/scripts/modules/rankings/components/rankingsDashboard/datagrid/statusCellTemplate.html',
                personnelCellTemplate = '/scripts/modules/rankings/components/rankingsDashboard/datagrid/personnelCellTemplate.html',
                dateCellTemplate = '/scripts/shared/ui-grid/templates/dateCellTemplate.html',
                selectTemplate = '/scripts/shared/ui-grid/templates/selectFilterHeaderTemplate.html';
            $scope.gridOptions = {
                appScopeProvider: controller,
                enableSorting: true,
                exporterMenuCsv: false,
                exporterMenuAllData: false,
                exporterMenuPdf: false,
                exporterSuppressColumns: ['login'],
                useExternalFiltering: true,
                enableGridMenu: true,
                showGridFooter: true,
                enableFiltering: true,
                enableColumnResize: true,
                enableFullRowSelection: true,
                enableRowSelection: true,
                multiSelect: false,
                enableRowHeaderSelection: false,
                paginationPageSizes: [constants.datagrid.contacts.defaultRowsNumber, 50, 100],
                paginationPageSize: constants.datagrid.contacts.defaultRowsNumber,
                useExternalPagination: true,
                useExternalSorting: true,
                gridMenuCustomItems: [
                    {
                        title: ' Export visible data as csv',
                        action: controller.exportVisible,
                        order: 209
                    },
                    {
                        title: ' Export all data as csv',
                        action: controller.exportAll,
                        order: 210,
                        templateUrl: '/scripts/modules/rankings/components/rankingsDashboard/datagrid/exportAllMenuItem.html'
                    }
                ],
                rowTemplate: '/scripts/modules/rankings/components/rankingsDashboard/datagrid/rowTemplate.html',
                columnDefs: [
                    {
                        displayName: 'Institution Name',
                        field: 'institutionName',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'institutionName', true),
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'institutionName', '')
                        }
                    },
                    {
                        displayName: 'Core ID',
                        field: 'institutionCoreIdAsString',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'institutionCoreIdAsString', true),
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'institutionCoreIdAsString', '')
                        }
                    },
                    {
                        displayName: 'Country',
                        field: 'institutionCountryCode',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'institutionCountryCode', true),
                        filter: {
                            column: 'institutionCountryCode',
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: countriesList,
                            searchEnabled: true,
                            term: RankingsDashboardService.getMultiSelectTerm(
                                GridService.getFilterByField(controller.paginationOptions, 'institutionCountryCode', [], true)
                            )
                        },
                        filterHeaderTemplate: multiSelectTemplate,
                        cellTemplate: selectCellTemplate
                    },
                    {
                        displayName: 'Question',
                        field: 'questionHandle',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'questionHandle', true),
                        filter: {
                            column: 'questionHandle',
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: controller.questionsList,
                            searchEnabled: true,
                            term: RankingsDashboardService.getMultiSelectTerm(
                                GridService.getFilterByField(controller.paginationOptions, 'questionHandle', [], true)
                            )
                        },
                        filterHeaderTemplate: multiSelectTemplate,
                        cellTemplate: selectCellTemplate
                    },
                    {
                        displayName: 'Total graduated students',
                        field: 'answers.totalGraduatedStudents',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'answers.totalGraduatedStudents', true),
                        filter: {
                            column: 'answers.totalGraduatedStudents.valueAsString',
                            term: GridService.getFilterByField(controller.paginationOptions, 'answers.totalGraduatedStudents.valueAsString', '')
                        },
                        cellTemplate: personnelCellTemplate,
                        filterHeaderTemplate: '/scripts/shared/ui-grid/templates/dateFilterHeaderTemplate.html',
                    },
                    {
                        displayName: 'Total respondents',
                        field: 'answers.totalRespondents',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'answers.totalRespondents', true),
                        filter: {
                            column: 'answers.totalRespondents.valueAsString',
                            term: GridService.getFilterByField(controller.paginationOptions, 'answers.totalRespondents.valueAsString', '')
                        },
                        cellTemplate: personnelCellTemplate
                    },
                    {
                        displayName: 'Employed FT and/or PT',
                        field: 'answers.employed',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'answers.employed', true),
                        filter: {
                            column: 'answers.employed.valueAsString',
                            term: GridService.getFilterByField(controller.paginationOptions, 'answers.employed.valueAsString', '')
                        },
                        cellTemplate: personnelCellTemplate
                    },
                    {
                        displayName: 'Unemployed but seeking employment',
                        field: 'answers.unemployed',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'answers.unemployed', true),
                        filter: {
                            column: 'answers.unemployed.valueAsString',
                            term: GridService.getFilterByField(controller.paginationOptions, 'answers.unemployed.valueAsString', '')
                        },
                        cellTemplate: personnelCellTemplate
                    },
                    {
                        displayName: 'In FT further study',
                        field: 'answers.inFurtherStudy',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'answers.inFurtherStudy', true),
                        filter: {
                            column: 'answers.inFurtherStudy.valueAsString',
                            term: GridService.getFilterByField(controller.paginationOptions, 'answers.inFurtherStudy.valueAsString', '')
                        },
                        cellTemplate: personnelCellTemplate
                    },
                    {
                        displayName: 'Unavailable for work',
                        field: 'answers.unavailable',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'answers.unavailable', true),
                        filter: {
                            column: 'answers.unavailable.valueAsString',
                            term: GridService.getFilterByField(controller.paginationOptions, 'answers.unavailable.valueAsString', '')
                        },
                        cellTemplate: personnelCellTemplate
                    },
                    {
                        displayName: 'Auto Total respondents',
                        field: 'answers.totalRespondentsAuto',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'answers.totalRespondentsAuto', true),
                        filter: {
                            column: 'answers.totalRespondentsAuto.valueAsString',
                            term: GridService.getFilterByField(controller.paginationOptions, 'answers.totalRespondentsAuto.valueAsString', '')
                        },
                        cellTemplate: personnelCellTemplate
                    },
                    {
                        displayName: 'Auto Employed FT and/or PT',
                        field: 'answers.employedAuto',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'answers.employedAuto', true),
                        filter: {
                            column: 'answers.employedAuto.valueAsString',
                            term: GridService.getFilterByField(controller.paginationOptions, 'answers.employedAuto.valueAsString', '')
                        },
                        cellTemplate: personnelCellTemplate
                    },
                    {
                        displayName: 'Auto Unemployed but seeking employment',
                        field: 'answers.unemployedAuto',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'answers.unemployedAuto', true),
                        filter: {
                            column: 'answers.unemployedAuto.valueAsString',
                            term: GridService.getFilterByField(controller.paginationOptions, 'answers.unemployedAuto.valueAsString', '')
                        },
                        cellTemplate: personnelCellTemplate
                    },
                    {
                        displayName: 'Auto In FT further study',
                        field: 'answers.inFurtherStudyAuto',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'answers.inFurtherStudyAuto', true),
                        filter: {
                            column: 'answers.inFurtherStudyAuto.valueAsString',
                            term: GridService.getFilterByField(controller.paginationOptions, 'answers.inFurtherStudyAuto.valueAsString', '')
                        },
                        cellTemplate: personnelCellTemplate
                    },
                    {
                        displayName: 'Auto Unavailable for work',
                        field: 'answers.unavailableAuto',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'answers.unavailableAuto', true),
                        filter: {
                            column: 'answers.unavailableAuto.valueAsString',
                            term: GridService.getFilterByField(controller.paginationOptions, 'answers.unavailableAuto.valueAsString', '')
                        },
                        cellTemplate: personnelCellTemplate
                    },
                    {
                        displayName: 'Estimate',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'answers.totalGraduatedStudents.estimate', true),
                        field: 'answers.totalGraduatedStudents.estimate',
                        filter: {
                            column: 'answers.totalGraduatedStudents.estimate',
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: [
                                {value: true, label: 'Yes'},
                                {value: false, label: 'No'}
                            ],
                            term: GridService.getFilterByField(controller.paginationOptions, 'answers.totalGraduatedStudents.estimate')
                        },
                        maxWidth: 80,
                        filterHeaderTemplate: selectTemplate,
                        cellTemplate: selectCellTemplate
                    },
                    {
                        displayName: 'Status',
                        field: 'status',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'status', true),
                        filter: {
                            column: 'status',
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: statusList,
                            searchEnabled: true,
                            term: RankingsDashboardService.getMultiSelectTerm(
                                GridService.getFilterByField(controller.paginationOptions, 'status', [], true)
                            )
                        },
                        filterHeaderTemplate: multiSelectTemplate,
                        cellTemplate: statusCellTemplate
                    },
                    {
                        displayName: 'Date Added',
                        field: controller.createdAtField,
                        filterCellFiltered: true,
                        filters: [
                            {
                                visible: true,
                                condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL
                            },
                            {
                                condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL
                            }
                        ],
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'createdAt', [], true),
                            applyTextFilter: controller.applyDateFilter(controller.createdAtField, '')
                        },
                        cellTemplate: dateCellTemplate,
                        filterHeaderTemplate: '/scripts/components/profiles/tu/departments/datagrid/createdAtFilterHeaderTemplateWithDropDown.html',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'createdAt', true)
                    }
                ],
                onRegisterApi: function (gridApi) {
                    controller.gridApi = gridApi;
                    gridApi.selection.on.rowSelectionChanged($scope, function (row) {
                        controller.handleDatagridRowClick(row.entity);
                    });

                    gridApi.core.on.rowsRendered($scope, function () {
                        $timeout(function() {
                            controller.isRendered = true;
                        },250);
                        GridService.generateSelectValues(controller.gridApi.grid.columns, selectValues);
                        GridService.generateSelectValues(controller.gridApi.grid.columns, lastSelectValues);
                    });

                    gridApi.pagination.on.paginationChanged($scope, function (newPage, limit) {
                        controller.defaultPaginationOptions.page = newPage;
                        controller.defaultPaginationOptions.limit = limit;
                        controller.reloadDatagrid();
                    });
                    gridApi.core.on.sortChanged($scope, controller.handleGridFiltersChanges);
                    gridApi.core.on.filterChanged($scope, controller.handleGridFiltersChanges);
                    gridApi.core.on.rowsVisibleChanged($scope, controller.handleGridVisibleChanges);
                }
            };
            datePickersFixes();
            controller.filters = angular.extend({}, controller.defaultFilters);
        };

        function datePickersFixes() {
            // This is needed to add 'x' icon in date field when prepopulating dates
            var createdAtField = GridService.getFilterByField(controller.paginationOptions, 'createdAt', '');
            if (createdAtField !== null && controller.paginationOptions["filter[createdAt]"] !== null) {
                if (angular.isDefined(createdAtField) &&
                    angular.isDefined(controller.paginationOptions["filter[createdAt]"]) &&
                    angular.isDefined(controller.paginationOptions["filter[createdAt]"].startDate) &&
                    angular.isDefined(controller.paginationOptions["filter[createdAt]"].endDate)
                ) {
                    $scope.gridOptions.columnDefs[getColumnNumber('createdAt')].filters[0].term = controller.paginationOptions["filter[createdAt]"].startDate;
                    $scope.gridOptions.columnDefs[getColumnNumber('createdAt')].filters[1].term = controller.paginationOptions["filter[createdAt]"].endDate;
                }
            }
        }

        function getColumnNumber(columnName) {
            var key = 0,
                total = $scope.gridOptions.columnDefs.length;
            for (key; key < total; key++) {
                if ($scope.gridOptions.columnDefs[key] &&
                    $scope.gridOptions.columnDefs[key].field === columnName
                ) {
                    return key;
                }
            }

            return null;
        }

        controller.getQuestionName = function (questionHandle) {
            return StatisticsHistoryService.getQuestionName(questionHandle, controller.questions);
        };

        controller.questionsWatch = function (questions) {
            if (!questions || !questions.length) {
                return;
            }
            controller.questions = questions;
            controller.initDataGrid();
            controller.getPage();
        };

        controller.resetGridFilters = function (filters) {
            // reset filtering
            if (filters !== null && filters.dataType === null && controller.gridApi) {
                GridService.resetConditions();
                angular.forEach(controller.gridApi.grid.columns, function (column) {
                    delete(column.colDef.filter.term);
                });
            }
        };

        controller.filtersWatch = function (filters) {
            controller.resetGridFilters(filters);
            if (!filters || !filters.ranking || !filters.dataType || !RankingsDashboardFactory.isGerPersonnel()) {
                return;
            }
            RankingsDashboardFactory.reloadDatagrid(false);
            controller.rankingHandle = filters.dataType === "gerPersonal" ? 'ger' : filters.ranking.handle;

            if (controller.gridApi) {
                controller.reloadDatagrid();
            } else {
                WatchService.create($scope, StatisticsDashboardFactory.getQuestions, controller.questionsWatch);
            }
        };

        controller.datagridReloadWatch = function (reload) {
            if (!reload) {
                return false;
            }
            RankingsDashboardFactory.setIsLoading(true);
            controller.filtersWatch(RankingsDashboardFactory.getFilters());
        };

        controller.initWatches = function () {
            WatchService.create($scope, RankingsDashboardFactory.getFilters, controller.filtersWatch, true);
            WatchService.create($scope, RankingsDashboardFactory.isDatagridReload, controller.datagridReloadWatch);
        };

        controller.$onInit = function () {
            controller.initWatches();
        };
    };

    angular
        .module('qsHub.rankings')
        .controller('Rankings.RankingsPersonnelGerDatagridController', [
            '$scope',
            '$state',
            '$location',
            '$timeout',
            'constants',
            'uiGridConstants',
            'uiGridExporterConstants',
            'WatchService',
            'RankingsDashboardService',
            'uiGridExporterService',
            'UiGridService',
            'StatisticsHistoryService',
            'TimeService',
            'RankingsDashboardFactory',
            'StatisticsDashboardFactory',
            'DateRangeFixerService',
            App.controllers.RankingsPersonnelGerDatagrid
        ]);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {
        components:{}
    });

    App.components.rankingsPersonnelDatagrid = {
        templateUrl: '/scripts/modules/rankings/components/rankingsDashboard/rankingsPersonnelDatagrid/rankingsPersonnelDatagridView.html',
        controller: 'Rankings.RankingsPersonnelDatagridController'
    };

    angular.module('qsHub.rankings').component('rankingsPersonnelDatagrid', App.components.rankingsPersonnelDatagrid);

}(window.angular));

(function (angular) {
    "use strict";
    var App = App || {};
    App = angular.extend({}, App, {controllers: {}});
    App.controllers.RankingsPersonnelDatagrid = function ($scope,
                                                          $state,
                                                          $location,
                                                          $timeout,
                                                          constants,
                                                          uiGridConstants,
                                                          uiGridExporterConstants,
                                                          WatchService,
                                                          RankingsDashboardService,
                                                          uiGridExporterService,
                                                          GridService,
                                                          StatisticsHistoryService,
                                                          TimeService,
                                                          RankingsDashboardFactory,
                                                          StatisticsDashboardFactory,
                                                          DateRangeFixerService) {
        var controller = this;
        var lastCreatedAtDateFilter = null;
        var selectValues = {},
            lastSelectValues = {},
            filterChangesInProgress = false;
        var filterTimeoutMilliseconds = constants.datagridInputDelay;
        controller.questions = [];
        controller.questionsIncludingSubQuestions = [];
        controller.isReloading = false;
        controller.isRendered = false;
        controller.gridApi = null;
        controller.reload = true;
        controller.createdAtField = 'createdAt';
        controller.questionHandleField = 'questionHandle';
        controller.filters = null;
        controller.defaultPaginationOptions = {
            page: 1,
            limit: constants.datagrid.contacts.defaultRowsNumber
        };
        controller.paginationOptions = GridService.getColumnsFilters(
            constants.gridNameSpaces.rankDashPerGridFilters,
            ['createdAt']
        );
        controller.columnsVisibility = GridService.getColumnsVisibility(
            constants.gridNameSpaces.rankDashPerGridVisibility
        );
        controller.types = StatisticsDashboardFactory.getTypes();
        controller.status = StatisticsDashboardFactory.getStatuses();
        $scope.gridOptions = null;
        controller.questionsHandles = [];
        controller.defaultFilters = {
            createdAt: {
                startDate: null,
                endDate: null
            }
        };
        controller.exportAll = function () {
            RankingsDashboardService.getRankingsAnswersByQuestions(
                angular.merge(
                    {
                        'filter[ranking]': controller.rankingHandle,
                        'filter[questionHandle][]': controller.questionsHandles
                    },
                    controller.paginationOptions,
                    {
                        'filter[export]': true
                    }
                )
            ).then(function (response) {
                if (typeof response.results === 'undefined') {
                    return false;
                }
                if (controller.rankingHandle === 'esg') {
                    angular.forEach(response.results, function (row) {
                        if (row.questionHandle === "report_carbon_emissions" || 
                            row.questionHandle === "carbon_redduction_target" ||
                            row.questionHandle === "institution_publicaly_committed_to_reaching_net_zero") {
                            setSubQuestions(row, response, 'url', '', true);
                        }
                        if (row.questionHandle === "carbon_emissions_in_kilograms" ||
                            row.questionHandle === "same_ffigure_for_base_line") {
                            setSubQuestions(row, response, 'url', 'fte');
                        }
                        if (row.questionHandle === "climate_science_environmental_sustainability" ||
                            row.questionHandle === "cuurent_equality_diversity_and_inclusionn" ||
                            row.questionHandle === "university_student_union" || 
                            row.questionHandle === "anti_bribery_policy" || 
                            row.questionHandle === "publish_financial_reports") {
                            setSubQuestions(row, response, 'url', 'checked');
                        }
                        if (row.questionHandle === "presence_of_research_center") {
                            setSubQuestions(row, response, 'evidence', 'checked');
                        }
                        if (row.questionHandle === "instituion_provide_dedicated_training_on_social_impacts") {
                            setSubQuestions(row, response, 'evidence', 'checked');
                        }
                        if (row.questionHandle === "institution_provide_dedicated_training_on_environmental_aspects") {
                            setSubQuestions(row, response, 'evidence', 'checked');
                        }
                        if (row.questionHandle === "institution_have_an_assessment_tool_for_assessing") {
                            setSubQuestions(row, response, 'evidence', 'checked');
                        }
                        if (row.questionHandle === "institution_formed_sustainability_committee") {
                            setSubQuestions(row, response, 'evidence', 'checked');
                        }
                        if (row.questionHandle === "support_services_for_disabilities" ||
                            row.questionHandle === "health_and_wellbeing_services" ||
                            row.questionHandle === "equality_diversity_and_inclusionn_comittee" ||
                            row.questionHandle === "holistic_ethical_organisational_culture") {
                            setSubQuestions(row, response, 'evidence', 'checked', false, true);
                        }
                        if (row.questionHandle === "manage_or_deliver_outreach_projects" ||
                            row.questionHandle === "does_institution_have_dedicated_staff_member") {
                            setSubQuestions(row, response, 'evidence', '', true);
                        }
                        if (row.questionHandle === "enery_generated_in_campus" ||
                            row.questionHandle === "campus_building_footprint" ||
                            row.questionHandle === "proportion_of_papers_publihsed"
                        ) {
                            setSubQuestions(row, response, '', 'fte', true);
                        }
                    });
                }
                response = RankingsDashboardService.formatCsvResults(response.results, TimeService);
                var gridApi = controller.gridApi,
                    gridOptions = $scope.gridOptions,
                    exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                        gridApi.grid,
                        uiGridExporterConstants.VISIBLE
                    ),
                    exportData = RankingsDashboardService.getCsvData(
                        gridApi.grid.columns,
                        response
                    ),
                    csvContent = uiGridExporterService.formatAsCsv(
                        exportColumnHeaders,
                        exportData,
                        gridOptions.exporterCsvColumnSeparator
                    );
                uiGridExporterService.downloadFile(
                    'Personnel-data-' + RankingsDashboardFactory.getRanking() + '-' + TimeService.now() + '.csv',
                    csvContent,
                    gridOptions.exporterOlderExcelCompatibility
                );
            });
        };
        controller.exportVisible = function () {
            var results = $scope.gridOptions.data;
            results = RankingsDashboardService.formatCsvResults(results, TimeService);
            if (typeof results === 'undefined') {
                return false;
            }
            var gridApi = controller.gridApi,
                gridOptions = $scope.gridOptions,
                exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                    gridApi.grid,
                    uiGridExporterConstants.VISIBLE
                ),
                exportData = RankingsDashboardService.getCsvData(
                    gridApi.grid.columns,
                    results
                ),
                csvContent = uiGridExporterService.formatAsCsv(
                    exportColumnHeaders,
                    exportData,
                    gridOptions.exporterCsvColumnSeparator
                );
            uiGridExporterService.downloadFile(
                'Personnel-data-' + RankingsDashboardFactory.getRanking() + '-' + TimeService.now() + '.csv',
                csvContent,
                gridOptions.exporterOlderExcelCompatibility
            );
        };
        function setSubQuestions(row, response, evidenceField, valueField,questionExist, isAnsWithEvidence) {
            var item = angular.copy(row);
            var index = 1;
            angular.forEach(row.answers, function (ans, key) {
                if (key !== 'url') {
                    if (ans.label === 'Evidence') {
                        if(!isAnsWithEvidence) {
                            row.answers[evidenceField] = {
                                value: ans.value,
                                valueAsString: ans.valueAsString
                            };
                        }
                        
                    } else if(questionExist){
                        row.answers[valueField] = {
                            value: ans.value,
                            valueAsString: ans.valueAsString
                        };
                    }
                     else {
                        item.answers = {};
                        item.questionHandle = key;
                        item.answers[valueField] = {
                            value: ans.value,
                            valueAsString: ans.valueAsString
                        };
                        if (ans.url) {
                            if(isAnsWithEvidence) {
                                item.answers.url = {
                                    value: ans.url,
                                    valueAsString: ans.url
                                };
                            }
                            else {
                                row.answers.url = {
                                    value: ans.url,
                                    valueAsString: ans.url
                                };
                            }
                        }
                        if(key === 'reasonableAccommodation') {
                            if(row.answers.reasonableAccommodationUrl){
                                item.answers.url = {
                                    value: row.answers.reasonableAccommodationUrl.value,
                                    valueAsString: row.answers.reasonableAccommodationUrl.valueAsString
                                };
                            }
                        }
                        if(key === 'existenceOfAntiDiscrimination') {
                            if(row.answers.existenceOfAntiDiscrimination){
                                item.answers.evidence = {
                                    value: row.answers.existenceOfAntiDiscrimination.secondUrl,
                                    valueAsString: row.answers.existenceOfAntiDiscrimination.secondUrl
                                };
                            }
                        }
                        if(key === 'hoursOfTrainingSA') {
                            if(row.answers.hoursOfTrainingSA){
                                item.answers.fte = {
                                    value: row.answers.hoursOfTrainingSA.value,
                                    valueAsString: row.answers.hoursOfTrainingSA.valueAsString
                                };
                            }
                        }
                        if(key === 'hoursOfTrainingEA') {
                            if(row.answers.hoursOfTrainingEA){
                                item.answers.fte = {
                                    value: row.answers.hoursOfTrainingEA.value,
                                    valueAsString: row.answers.hoursOfTrainingEA.valueAsString
                                };
                            }
                        }
                        if(ans.text) {
                            item.answers.evidence = {
                                value: ans.text,
                                valueAsString: ans.text
                            };
                        }
                            if (row.questionHandle === 'carbon_emissions_in_kilograms') {
                                if (ans.label === 'Total Scope 1 & 2 emissions in tCO2e') {
                                    ans.label = ans.label + ' (ES 7.1.1)';
                                }
                                if (ans.label === 'If you also report on Scope 3 emissions, please list your estimate here, in tCO2e') {
                                    ans.label = ans.label + ' (ES 7.1.2)';
                                }
                            }
                            if (row.questionHandle === 'same_ffigure_for_base_line') {
                                if (ans.label === 'Baseline year') {
                                    ans.label = ans.label + ' (ES 9.1.1)';
                                }
                                if (ans.label === 'Total Scope 1 & 2 for the baseline year, in tco2e') {
                                    ans.label = ans.label + ' (ES 9.1.2)';
                                }
                            }
                            if (row.questionHandle === 'institution_provide_dedicated_training_on_environmental_aspects') {
                              
                                if (ans.label === 'How many hours of training on these Environmental aspects per employee?') {
                                    ans.label = ans.label + ' (ES 10.1)';
                                }
                                if (ans.label === 'Students') {
                                    ans.label = ans.label + ' (ES 10.2)';
                                }
                                if (ans.label === 'Staff') {
                                    ans.label = ans.label + ' (ES 10.3)';
                                }
                                if (ans.label === 'Both') {
                                    ans.label = ans.label + ' (ES 10.4)';
                                }
                            }

                            if (row.questionHandle === 'institution_have_an_assessment_tool_for_assessing') {
                              
                                if (ans.label === 'If yes, is this tool Sulitest TASK?') {
                                    ans.label = ans.label + ' (ES 11.1)';
                                }
                               
                            }
                            if (row.questionHandle === 'climate_science_environmental_sustainability') {
                                if (ans.label === "They lead to the award of an officially recognised qualification that specifically refers to climate science and environmental sustainability (e.g., MSc in Climate Change: Science, Society and Solutions at the University of Manchester in the UK; BSc in Climate Science and Adaptation at the University of Newcastle, Australia)") {
                                    ans.label = "They lead to the award of an officially recognised qualification that specifically refers to climate science and environmental sustainability (EE 3.3)";
                                } else if (ans.label === 'We offer these courses and they are linked to officially recognised credits (e.g., European Credit Transfer and Accumulation System - ECTS, in Europe)') {
                                    ans.label = ans.label + ' (EE 3.2)';
                                } else {
                                    ans.label = 'Tick if applicable (EE 3.1)';
                                }
                            }
                            if (row.questionHandle === 'presence_of_research_center') {
                                if (ans.label === 'The Research Centre has dedicated FTE staff.') {
                                    ans.label = ans.label + ' (ER 3.2)';
                                } else if (ans.label === 'This Research Centre contributes (through curriculum, teaching and supervision) to the teaching of undergraduate programmes and/or postgraduate programmes.') {
                                    ans.label = ans.label + ' (ER 3.3)';
                                } else {
                                    ans.label = 'Tick if applicable (ER 3.1)';
                                }
                                // ans.label = ans.label + ' (ER 3.' + index.toString() + ')';
                                // index = index + 1;
                            }
                            if (row.questionHandle === 'cuurent_equality_diversity_and_inclusionn') {
                                if (ans.label === 'We have a current EDI policy or equivalent') {
                                    ans.label = ans.label + ' (EQ 5.1)';
                                }
                                if (ans.label === 'Age') {
                                    ans.label = ans.label + ' (EQ 5.2)';
                                }
                                if (ans.label === 'Gender or gender reassignment') {
                                    ans.label = ans.label + ' (EQ 5.3)';
                                }
                                if (ans.label === 'Disability') {
                                    ans.label = ans.label + ' (EQ 5.4)';
                                }
                                if (ans.label === 'Race') {
                                    ans.label = ans.label + ' (EQ 5.5)';
                                }
                                if (ans.label === 'Religion or belief') {
                                    ans.label = ans.label + ' (EQ 5.6)';
                                }
                                if (ans.label === 'Sexual orientation') {
                                    ans.label = ans.label + ' (EQ 5.7)';
                                }
                                if (ans.label === 'Marriage and civil partnership') {
                                    ans.label = ans.label + ' (EQ 5.8)';
                                }
                                if (ans.label === 'Refugee and asylum seekers') {
                                    ans.label = ans.label + ' (EQ 5.9)';
                                }
                                if (ans.label === 'Pregnancy and maternity') {
                                    ans.label = ans.label + ' (EQ 5.10)';
                                }
                            }
                            if (row.questionHandle === 'support_services_for_disabilities') {
                                if (ans.label === 'Existence of Disability Support Office') {
                                    ans.label = ans.label + ' (EQ 7.1)';
                                } else if (ans.label === 'Campus is easily accessible by people with disabilities') {
                                    ans.label = ans.label + ' (EQ 7.2)';
                                } else if (ans.label === 'Access schemes for people with disabilities such as mentoring or other targeted support') {
                                    ans.label = ans.label + ' (EQ 7.3)';
                                } else if (ans.label === 'Our university offers on-campus accommodation') {
                                    ans.label = ans.label + ' (EQ 7.4)';
                                } else if (ans.label === 'We have a reasonable accommodation policy or strategy for people with disabilities, including adequate funding') {
                                    ans.label = ans.label + ' (EQ 7.5)';
                                } else {
                                    ans.label = 'Campus is easily accessible by people with disabilities (EQ 7.2)';
                                }
                            }
                            if (row.questionHandle === 'instituion_provide_dedicated_training_on_social_impacts') {
                              
                                if (ans.label === 'How many hours of training on these Social aspects per employee?') {
                                    ans.label = ans.label + ' (EQ 8.1)';
                                }
                                if (ans.label === 'Students') {
                                    ans.label = ans.label + ' (EQ 8.2)';
                                }
                                if (ans.label === 'Staff') {
                                    ans.label = ans.label + ' (EQ 8.3)';
                                }
                                if (ans.label === 'Both') {
                                    ans.label = ans.label + ' (EQ 8.4)';
                                }
                            }
                            if (row.questionHandle === 'manage_or_deliver_outreach_projects') {
                                ans.label = ans.label + ' (KE 2.' + index.toString() + ')';
                                index = index + 1;
                            }
                            if (row.questionHandle === 'health_and_wellbeing_services') {
                                if (ans.label === 'Provision of healthy and affordable food choices for all on campus') {
                                    ans.label = ans.label + ' (HW 2.1)';
                                }
                                if (ans.label === 'Access to physical health-care services including information and education services') {
                                    ans.label = ans.label + ' (HW 2.2)';
                                }
                                if (ans.label === 'Access to sexual and reproductive health-care services including information and education services') {
                                    ans.label = ans.label + ' (HW 2.3)';
                                }
                                if (ans.label === 'Access to mental health support for both staff and students') {
                                    ans.label = ans.label + ' (HW 2.4)';
                                }
                            }
                            if (row.questionHandle === 'equality_diversity_and_inclusionn_comittee') {
                                if (ans.label === 'Existence of committee, office or officer') {
                                    ans.label = ans.label + ' (GG 1.7.1)';
                                }
                                if (ans.label === 'Existence of anti-discrimination and anti-harassment') {
                                    ans.label = 'Existence of anti-discrimination and anti-harassment policies (GG 1.7.2)';
                                }
                                // ans.label = ans.label + ' (GG 1.7.' + index.toString() + ')';
                                // index = index + 1;
                            }
                            if (row.questionHandle === 'anti_bribery_policy') {
                                if (ans.label === 'Existence of anti-bribery and corruption policy or equivalent') {
                                    ans.label = ans.label + ' (GG 1.5.1)';
                                }
                                if (ans.label === 'This policy has been reviewed in the last 3 years, i.e. on or after 2020') {
                                    ans.label = ans.label + ' (GG 1.5.2)';
                                }
                            }
                            if (row.questionHandle === 'holistic_ethical_organisational_culture') {
                                if (ans.label === 'Our organisation develops clear ethical values (e.g., diversity, honesty, respect, fairness) and these are enshrined in a publicly available strategic document.') {
                                    ans.label = ans.label + ' (GG 1.1)';
                                }
                                if (ans.label === 'Our university provides training based on those values at all levels of the organisation.') {
                                    ans.label = ans.label + ' (GG 1.2)';
                                }
                                if (ans.label === 'There is an office for ethical compliance within our institution, with a designated official with oversight on ethical matters across the institution.') {
                                    ans.label = ans.label + ' (GG 1.3)';
                                }
                                if (ans.label === 'Our organisation has an internal reporting system to assure the confidentiality of whistleblowers or a grievance procedure for staff concerning an employment matter') {
                                    ans.label = ans.label + ' (GG 1.4)';
                                }
                            }
                            if (row.questionHandle === 'university_student_union') {
                                if (ans.label === 'Our university has a recognised student union that represents both undergraduate and postgraduate students at university level') {
                                    ans.label = ans.label + ' (GG 5.1)';
                                }
                                if (ans.label === 'This student union is connected/affiliated to a wider national student union body') {
                                    ans.label = ans.label + ' (GG 5.2)';
                                }
                                if (ans.label === 'The student union elects its leadership, allowing students to vote.') {
                                    ans.label = ans.label + ' (GG 5.3)';
                                }
                            }
                            if (row.questionHandle === 'publish_financial_reports') {
                                if (ans.label === 'Income') {
                                    ans.label = ans.label + ' (GG 4.1.1)';
                                }
                                if (ans.label === 'Expenditure') {
                                    ans.label = ans.label + ' (GG 4.1.2)';
                                }
                                if (ans.label === 'Borrowing') {
                                    ans.label = ans.label + ' (GG 4.1.3)';
                                }
                                if (ans.label === 'Surplus') {
                                    ans.label = ans.label + ' (GG 4.1.4)';
                                }
                            }
                            if (row.questionHandle === 'institution_formed_sustainability_committee') {
                                if (ans.label === 'executiveLeadership') {
                                    ans.label = ans.label + ' (GG 8.1)';
                                }
                               
                            }
                           if ((row.questionHandle === 'institution_have_an_assessment_tool_for_assessing' && item.questionHandle === 'checked') ||
                               (row.questionHandle === 'instituion_provide_dedicated_training_on_social_impacts' && item.questionHandle === 'checked') ||
                               (row.questionHandle === 'institution_provide_dedicated_training_on_environmental_aspects' && item.questionHandle === 'checked') ||
                               (row.questionHandle === 'institution_formed_sustainability_committee' && item.questionHandle === 'checked')) {
                               // console.log(item)
                           }
                            else {
                                response.results.push(angular.copy(item));
                                controller.questionsIncludingSubQuestions =  StatisticsDashboardFactory.getQuestionsIncludingSubQuestions();
                                controller.questionsIncludingSubQuestions.push({name:ans.label,handle:key});
                                StatisticsDashboardFactory.setQuestionsIncludingSubQuestions(controller.questionsIncludingSubQuestions);
        
                                    controller.questionsList.push({
                                        value: key,
                                        label: ans.label
                                    });
                            }
                        
                       
                    }
                }
            });
        }
        controller.getPage = function () {
            // Avoid to reload (show/hide) the grid so sorting works
            if (controller.reload) {
                controller.isReloading = true;
                controller.isRendered = false;
            }
            controller.questionsIncludingSubQuestions = StatisticsDashboardFactory.getQuestions();
            StatisticsDashboardFactory.setQuestionsIncludingSubQuestions(controller.questionsIncludingSubQuestions);
            RankingsDashboardFactory.setIsLoading(true);
            controller.reload = true;
            if (!('filter[questionHandle][]' in controller.paginationOptions) ||
                ('filter[questionHandle][]' in controller.paginationOptions &&
                controller.paginationOptions['filter[questionHandle][]'].length === 0)
            ) {
                controller.questionsHandles = RankingsDashboardService.getQuestionsHandles(
                    controller.questions,
                    controller.rankingHandle,
                    controller.types.personal
                );
            } else {
                controller.questionsHandles = [];
            }
            if($scope.gridOptions && $scope.gridOptions.columnDefs) {
                if (controller.rankingHandle !== 'esg') {
                    $scope.gridOptions.columnDefs = getColumnDefs();
                    datePickersFixes();
                }
                else {
                    $scope.gridOptions.columnDefs = getEsgColumnDefs();
                    datePickersFixes();
                }
            }
           
            return RankingsDashboardService.getRankingsAnswersByQuestions(
                angular.merge({
                    'filter[ranking]': controller.rankingHandle,
                    'filter[questionHandle][]': controller.questionsHandles
                }, controller.paginationOptions, controller.defaultPaginationOptions)
            ).then(function (response) {
                if (response) {
                    controller.generateQuestionsList();
                    if (controller.defaultPaginationOptions.page > 1 && response.totalFiltered === 0 && response.totalMatching > 0) {
                        controller.defaultPaginationOptions.page = 1;
                        controller.gridApi.pagination.seek(1);
                        controller.getPage();
                    }
                    if (controller.rankingHandle === 'esg') {
                        angular.forEach(response.results, function (row) {
                            if (row.questionHandle === "report_carbon_emissions" || 
                                row.questionHandle === "carbon_redduction_target" ||
                                row.questionHandle === "institution_publicaly_committed_to_reaching_net_zero") {
                                setSubQuestions(row, response, 'url', '', true);
                            }
                            if (row.questionHandle === "carbon_emissions_in_kilograms" ||
                                row.questionHandle === "same_ffigure_for_base_line") {
                                setSubQuestions(row, response, 'url', 'fte');
                            }
                            if (row.questionHandle === "climate_science_environmental_sustainability" ||
                                row.questionHandle === "cuurent_equality_diversity_and_inclusionn" ||
                                row.questionHandle === "university_student_union" || 
                                row.questionHandle === "anti_bribery_policy" || 
                                row.questionHandle === "publish_financial_reports") {
                                setSubQuestions(row, response, 'url', 'checked');
                            }
                            if (row.questionHandle === "presence_of_research_center") {
                                setSubQuestions(row, response, 'evidence', 'checked');
                            }
                            if (row.questionHandle === "instituion_provide_dedicated_training_on_social_impacts") {
                                setSubQuestions(row, response, 'evidence', 'checked');
                            }
                            if (row.questionHandle === "institution_provide_dedicated_training_on_environmental_aspects") {
                                setSubQuestions(row, response, 'evidence', 'checked');
                            }
                            if (row.questionHandle === "institution_have_an_assessment_tool_for_assessing") {
                                setSubQuestions(row, response, 'evidence', 'checked');
                            }
                            if (row.questionHandle === "institution_formed_sustainability_committee") {
                                setSubQuestions(row, response, 'evidence', 'checked');
                            }
                            if (row.questionHandle === "support_services_for_disabilities" ||
                                row.questionHandle === "health_and_wellbeing_services" ||
                                row.questionHandle === "equality_diversity_and_inclusionn_comittee" ||
                                row.questionHandle === "holistic_ethical_organisational_culture") {
                                setSubQuestions(row, response, 'evidence', 'checked', false, true);
                            }
                            if (row.questionHandle === "manage_or_deliver_outreach_projects" ||
                                row.questionHandle === "does_institution_have_dedicated_staff_member") {
                                setSubQuestions(row, response, 'evidence', '', true);
                            }
                            if (row.questionHandle === "enery_generated_in_campus" ||
                                row.questionHandle === "campus_building_footprint" ||
                                row.questionHandle === "proportion_of_papers_publihsed"
                            ) {
                                setSubQuestions(row, response, '', 'fte', true);
                            }
                        });
                    }
                    if ($scope.gridOptions) {
                        $scope.gridOptions.columnDefs[controller.getColumnNumber(controller.questionHandleField)].filter.selectOptions = controller.questionsList;
                    }
                    $scope.gridOptions.data = response.results;
                    $scope.gridOptions.minRowsToShow = response.results && response.results.length < $scope.gridOptions.paginationPageSize ? response.results.length : $scope.gridOptions.paginationPageSize;
                    $scope.gridOptions.totalItems = response.totalMatching;
                    if (!angular.equals([], response)) { // if not cancelled
                        $timeout(function () {
                            RankingsDashboardFactory.setIsLoading(false);
                        }, 50);
                    }
                } else {
                    $scope.gridOptions.data = [];
                }
                controller.isReloading = false;
            });
        };
        controller.handleGridFiltersChanges = function () {
            var filterColumn,
                gridApi = controller.gridApi,
                sortKeys = [];
            filterChangesInProgress = true;
            if(controller.gridApi.pagination.getPage() > 1){
                controller.gridApi.pagination.seek(1);
            }
            controller.paginationOptions = angular.copy(controller.defaultPaginationOptions);
            angular.forEach(controller.gridApi.grid.columns, function (column) {
                if (typeof column.filters !== 'undefined' && column.filters[0].term !== 'undefined') {
                    if (
                        column.filters[0].type === uiGridConstants.filter.SELECT &&
                        typeof column.filters[0].term === 'object' &&
                        column.filters[0].term !== null &&
                        typeof column.filters[0].term.value !== 'undefined'
                    ) {
                        column.filters[0].term = column.filters[0].term.value;
                    }
                }
                if (typeof column.sort.priority !== 'undefined') {
                    sortKeys[column.sort.priority] = column.name;
                }
            });
            if (sortKeys.length > 1) {
                var latestSortKey = sortKeys.pop();
                angular.forEach(controller.gridApi.grid.columns, function (column) {
                    if (typeof column.sort.priority !== 'undefined' && column.name !== latestSortKey) {
                        column.sort = {};
                    }
                });
            }
            angular.forEach(gridApi.grid.columns, function (column) {
                filterColumn = column.filters[0].column ? column.filters[0].column : column.field;
                if (
                    typeof column.filters !== 'undefined' &&
                    column.filters[0].term !== 'undefined' &&
                    column.filters[0].term !== ''
                ) {
                    if (filterColumn === 'status' ||
                        filterColumn === 'institutionCountryCode' ||
                        filterColumn === 'questionHandle'
                    ) {
                        var arrayFilter = [];
                        angular.forEach(column.filters[0].term, function (term) {
                            arrayFilter.push(term.value);
                        });
                        controller.paginationOptions['filter[' + filterColumn + '][]'] = arrayFilter;
                    } else if (filterColumn === 'createdAt') {
                        if (typeof column.filters !== 'undefined' &&
                            typeof column.filters[0].term !== 'undefined' &&
                            typeof column.filters[1].term !== 'undefined') {
                            controller.paginationOptions['filter[' + filterColumn + ']'] = {
                                startDate: column.filters[0].term && isNaN(column.filters[0].term) && typeof column.filters[0].term === 'object' ?
                                    column.filters[0].term.format('x') : column.filters[0].term,
                                endDate: column.filters[1].term && isNaN(column.filters[1].term) && typeof column.filters[1].term === 'object' ?
                                    column.filters[1].term.format('x') : column.filters[1].term
                            };
                        } else {
                            $scope.gridOptions.columnDefs[controller.getColumnNumber(controller.createdAtField)].filters[0].term = null;
                            $scope.gridOptions.columnDefs[controller.getColumnNumber(controller.createdAtField)].filters[1].term = null;
                        }
                    } else {
                        controller.paginationOptions['filter[' + filterColumn + ']'] = column.filters[0].term;
                    }
                }
                if (typeof column.sort.direction !== 'undefined') {
                    controller.paginationOptions['sorting[' + filterColumn.replace('AsString', '') + ']'] = column.sort.direction;
                    controller.reload = false;
                }
            });
            if (controller.paginationOptions["filter[createdAt]"] === null || controller.paginationOptions["filter[createdAt]"] === undefined) {
                controller.paginationOptions["filter[createdAt]"] = controller.defaultFilters.createdAt;
            }
            if (controller.paginationOptions["filter[createdAt]"].startDate === null || !angular.isDefined(controller.paginationOptions["filter[createdAt]"].startDate)) {
                controller.paginationOptions["filter[createdAt]"].endDate = null;
                controller.filters.createdAt = {
                    startDate: null,
                    endDate: null
                };
            }
            GridService.storeColumnsFilters(
                constants.gridNameSpaces.rankDashPerGridFilters,
                controller.paginationOptions
            );
            if (angular.isDefined($scope.filterTimeout)) {
                $timeout.cancel($scope.filterTimeout);
            }
            GridService.generateSelectValues(controller.gridApi.grid.columns, selectValues);
            if (!angular.equals(selectValues, lastSelectValues)) {
                filterTimeoutMilliseconds = constants.datagridSelectDelay;
            }
            $scope.filterTimeout = $timeout(function () {
                controller.reloadDatagrid();
                filterChangesInProgress = false;
                lastSelectValues = angular.copy(selectValues);
                filterTimeoutMilliseconds = constants.datagridInputDelay;
            }, filterTimeoutMilliseconds);
        };
        controller.getColumnfield = function (fieldName) {
            return fieldName.indexOf('answers') !== -1 ? fieldName + '.valueAsString' : fieldName;
        };
        function removeFilter(field) {
            switch (field) {
                case 'questionHandle':
                    controller.paginationOptions['filter[questionHandle][]'] = [];
                    return;
                case 'answers.ft':
                    controller.paginationOptions['filter[answers.ft.valueAsString]'] = null;
                    return;
                case 'answers.pt':
                    controller.paginationOptions['filter[answers.pt.valueAsString]'] = null;
                    return;
                case 'answers.hc':
                    controller.paginationOptions['filter[answers.hc.valueAsString]'] = null;
                    return;
                case 'answers.fte':
                    controller.paginationOptions['filter[answers.fte.valueAsString]'] = null;
                    return;
                case 'answers.url':
                    controller.paginationOptions['filter[answers.url.valueAsString]'] = null;
                    return;
                case 'institutionCountryCode':
                    controller.paginationOptions['filter[institutionCountryCode][]'] = null;
                    return;
                case 'status':
                    controller.paginationOptions['filter[status][]'] = null;
                    return;
                default:
                    controller.paginationOptions['filter[' + field + ']'] = null;
                    return;
            }
        }
        controller.handleGridVisibleChanges = function () {
            var columnsVisibility = GridService.getColumnsVisibility(constants.gridNameSpaces.rankDashPerGridVisibility),
            reload = false;
            if (columnsVisibility) {
                angular.forEach($scope.gridOptions.columnDefs, function (column, key) {
                    if (columnsVisibility[column.field] === true &&
                        column.visible === false
                    ) {
                        if (typeof $scope.gridOptions.columnDefs[key].filter === 'undefined') {
                            $scope.gridOptions.columnDefs[key].filter = {};
                        }
                        if (typeof  $scope.gridOptions.columnDefs[key].filter !== 'undefined' &&
                            $scope.gridOptions.columnDefs[key].filter.hasOwnProperty('term') &&
                            $scope.gridOptions.columnDefs[key].filter.term) {
                            removeFilter(column.field);
                            reload = true;
                        }
                        $scope.gridOptions.columnDefs[key].filter.term = null;
                        if (column.field === 'createdAt') {
                            delete    $scope.gridOptions.columnDefs[key].filters[0].term;
                            controller.filters.createdAt = {
                                startDate: null,
                                endDate: null
                            };
                        }
                    }
                });
                GridService.storeColumnsFilters(
                    constants.gridNameSpaces.rankDashPerGridFilters,
                    controller.paginationOptions
                );
            }
            if ($scope.gridOptions) {
                controller.columnsVisibility = GridService.getGridColumnsVisibility($scope.gridOptions.columnDefs);
                GridService.storeColumnsVisibility(
                    constants.gridNameSpaces.rankDashPerGridVisibility,
                    controller.columnsVisibility
                );
            }
            if (reload) {
                controller.reloadDatagrid();
            }
        };
        controller.handleDatagridRowClick = function (row) {
            var ranking = RankingsDashboardFactory.getRanking(),
            path = 'clients.statistics.data-submission.' + ranking;
            if (ranking === 'ger') {
                path = RankingsDashboardService.getGerPath(row.questionHandle);
            }
            return $location.path(
                $state.href(path).replace('#', '')
            ).search({
                coreId: row.institutionCoreId,
                question: row.questionHandle
            });
        };
        function getColumnDefs(){
            var countriesList = [];
            angular.forEach(RankingsDashboardFactory.getCountries(), function (item) {
                countriesList.push({
                    value: item.countryCode,
                    label: item.name
                });
            });
            var statusList = [];
            angular.forEach(controller.status, function (item) {
                statusList.push({
                    value: item,
                    label: item.charAt(0).toUpperCase() + item.slice(1)
                });
            });
            var multiSelectTemplate = '/scripts/shared/ui-grid/templates/multiSelectFilterHeaderTemplate.html',
            selectCellTemplate = '/scripts/shared/ui-grid/templates/selectCellTemplate.html',
            statusCellTemplate = '/scripts/modules/rankings/components/rankingsDashboard/datagrid/statusCellTemplate.html',
            personnelCellTemplate = '/scripts/modules/rankings/components/rankingsDashboard/datagrid/personnelCellTemplate.html',
            dateCellTemplate = '/scripts/shared/ui-grid/templates/dateCellTemplate.html',
            selectTemplate = '/scripts/shared/ui-grid/templates/selectFilterHeaderTemplate.html';
            return [
                {
                    displayName: 'Institution Name',
                    field: 'institutionName',
                    visible: GridService.getVisibilityByField(controller.columnsVisibility, 'institutionName', true),
                    filter: {
                        term: GridService.getFilterByField(controller.paginationOptions, 'institutionName', '')
                    }
                },
                {
                    displayName: 'Core ID',
                    field: 'institutionCoreIdAsString',
                    visible: GridService.getVisibilityByField(controller.columnsVisibility, 'institutionCoreIdAsString', true),
                    filter: {
                        term: GridService.getFilterByField(controller.paginationOptions, 'institutionCoreIdAsString', '')
                    }
                },
                {
                    displayName: 'Country',
                    field: 'institutionCountryCode',
                    visible: GridService.getVisibilityByField(controller.columnsVisibility, 'institutionCountryCode', true),
                    filter: {
                        column: 'institutionCountryCode',
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: countriesList,
                        searchEnabled: true,
                        term: RankingsDashboardService.getMultiSelectTerm(
                            GridService.getFilterByField(controller.paginationOptions, 'institutionCountryCode', [], true)
                        )
                    },
                    filterHeaderTemplate: multiSelectTemplate,
                    cellTemplate: selectCellTemplate
                },
                {
                    displayName: 'Question',
                    field: 'questionHandle',
                    visible: GridService.getVisibilityByField(controller.columnsVisibility, 'questionHandle', true),
                    filter: {
                        column: 'questionHandle',
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: controller.questionsList,
                        searchEnabled: true,
                        term: RankingsDashboardService.getMultiSelectTerm(
                            GridService.getFilterByField(controller.paginationOptions, 'questionHandle', [], true)
                        )
                    },
                    filterHeaderTemplate: multiSelectTemplate,
                    cellTemplate: selectCellTemplate
                },
                {
                    displayName: 'Full Time',
                    field: 'answers.ft',
                    visible:GridService.getVisibilityByField(controller.columnsVisibility, 'answers.ft', true),
                    filter: {
                        column: 'answers.ft.valueAsString',
                        term: GridService.getFilterByField(controller.paginationOptions, 'answers.ft.valueAsString', '')
                    },
                    cellTemplate: personnelCellTemplate
                },
                {
                    displayName: 'Part Time',
                    field: 'answers.pt',
                    visible:GridService.getVisibilityByField(controller.columnsVisibility, 'answers.pt', true),
                    filter: {
                        column: 'answers.pt.valueAsString',
                        term: GridService.getFilterByField(controller.paginationOptions, 'answers.pt.valueAsString', '')
                    },
                    cellTemplate: personnelCellTemplate
                },
                {
                    displayName: 'Head Count',
                    field: 'answers.hc',
                    visible:GridService.getVisibilityByField(controller.columnsVisibility, 'answers.hc', true),
                    filter: {
                        column: 'answers.hc.valueAsString',
                        term: GridService.getFilterByField(controller.paginationOptions, 'answers.hc.valueAsString', '')
                    },
                    cellTemplate: personnelCellTemplate
                },
                {
                    displayName: 'Full Time Equivalent',
                    field: 'answers.fte',
                    visible:GridService.getVisibilityByField(controller.columnsVisibility, 'answers.fte', true),
                    filter: {
                        column: 'answers.fte.valueAsString',
                        term: GridService.getFilterByField(controller.paginationOptions, 'answers.fte.valueAsString', '')
                    },
                    cellTemplate: personnelCellTemplate
                },
                {
                    displayName: 'FTE Estimate',
                    visible: GridService.getVisibilityByField(controller.columnsVisibility, 'answers.fte.estimate', true),
                    field: 'answers.fte.estimate',
                    filter: {
                        column: 'answers.fte.estimate',
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: [
                            {value: true, label: 'Yes'},
                            {value: false, label: 'No'}
                        ],
                        term: GridService.getFilterByField(controller.paginationOptions, 'answers.fte.estimate')
                    },
                    maxWidth: 80,
                    filterHeaderTemplate: selectTemplate,
                    cellTemplate: selectCellTemplate
                },
                {
                    displayName: 'URL',
                    field: 'answers.url',
                    visible: GridService.getVisibilityByField(controller.columnsVisibility, 'answers.url', true),
                    filter: {
                        column: 'answers.url.valueAsString',
                        term: GridService.getFilterByField(controller.paginationOptions, 'answers.url.valueAsString', '')
                    },
                    cellTemplate: personnelCellTemplate
                },
                {
                    displayName: 'Available',
                    visible: GridService.getVisibilityByField(controller.columnsVisibility, 'answers.url.publiclyAvailable', true),
                    field: 'answers.url.publiclyAvailable',
                    filter: {
                        column: 'answers.url.publiclyAvailable',
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: [
                            {value: true, label: 'Yes'},
                            {value: false, label: 'No'}
                        ],
                        term: GridService.getFilterByField(controller.paginationOptions, 'answers.url.publiclyAvailable')
                    },
                    maxWidth: 80,
                    filterHeaderTemplate: selectTemplate,
                    cellTemplate: selectCellTemplate
                },
                {
                    displayName: 'N/A',
                    visible: GridService.getVisibilityByField(controller.columnsVisibility, 'answers.url.notAvailable', true),
                    field: 'answers.url.notAvailable',
                    filter: {
                        column: 'answers.url.notAvailable',
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: [
                            {value: true, label: 'Yes'},
                            {value: false, label: 'No'}
                        ],
                        term: GridService.getFilterByField(controller.paginationOptions, 'answers.url.notAvailable')
                    },
                    maxWidth: 80,
                    filterHeaderTemplate: selectTemplate,
                    cellTemplate: selectCellTemplate
                },
                {
                    displayName: 'Status',
                    field: 'status',
                    visible: GridService.getVisibilityByField(controller.columnsVisibility, 'status', true),
                    filter: {
                        column: 'status',
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: statusList,
                        searchEnabled: true,
                        term: RankingsDashboardService.getMultiSelectTerm(
                            GridService.getFilterByField(controller.paginationOptions, 'status', [], true)
                        )
                    },
                    filterHeaderTemplate: multiSelectTemplate,
                    cellTemplate: statusCellTemplate
                },
                {
                    displayName: 'Date Added',
                    field: controller.createdAtField,
                    filterCellFiltered: true,
                    filters: [
                        {
                            visible: true,
                            condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL
                        },
                        {
                            condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL
                        }
                    ],
                    filter: {
                        term: GridService.getFilterByField(controller.paginationOptions, 'createdAt', [], true),
                        applyTextFilter: controller.applyDateFilter(controller.createdAtField, '')
                    },
                    cellTemplate: dateCellTemplate,
                    filterHeaderTemplate: '/scripts/components/profiles/tu/departments/datagrid/createdAtFilterHeaderTemplateWithDropDown.html',
                    visible: GridService.getVisibilityByField(controller.columnsVisibility, 'createdAt', true)
                }
            ];
        }
        function getEsgColumnDefs(){
            var countriesList = [];
            angular.forEach(RankingsDashboardFactory.getCountries(), function (item) {
                countriesList.push({
                    value: item.countryCode,
                    label: item.name
                });
            });
            var statusList = [];
            angular.forEach(controller.status, function (item) {
                statusList.push({
                    value: item,
                    label: item.charAt(0).toUpperCase() + item.slice(1)
                });
            });
            var multiSelectTemplate = '/scripts/shared/ui-grid/templates/multiSelectFilterHeaderTemplate.html',
            selectCellTemplate = '/scripts/shared/ui-grid/templates/selectCellTemplate.html',
            statusCellTemplate = '/scripts/modules/rankings/components/rankingsDashboard/datagrid/statusCellTemplate.html',
            personnelCellTemplate = '/scripts/modules/rankings/components/rankingsDashboard/datagrid/personnelCellTemplate.html',
            dateCellTemplate = '/scripts/shared/ui-grid/templates/dateCellTemplate.html',
            selectTemplate = '/scripts/shared/ui-grid/templates/selectFilterHeaderTemplate.html';
            return [
                {
                    displayName: 'Institution Name',
                    field: 'institutionName',
                    visible: GridService.getVisibilityByField(controller.columnsVisibility, 'institutionName', true),
                    filter: {
                        term: GridService.getFilterByField(controller.paginationOptions, 'institutionName', '')
                    }
                },
                {
                    displayName: 'Core ID',
                    field: 'institutionCoreIdAsString',
                    visible: GridService.getVisibilityByField(controller.columnsVisibility, 'institutionCoreIdAsString', true),
                    filter: {
                        term: GridService.getFilterByField(controller.paginationOptions, 'institutionCoreIdAsString', '')
                    }
                },
                {
                    displayName: 'Country',
                    field: 'institutionCountryCode',
                    visible: GridService.getVisibilityByField(controller.columnsVisibility, 'institutionCountryCode', true),
                    filter: {
                        column: 'institutionCountryCode',
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: countriesList,
                        searchEnabled: true,
                        term: RankingsDashboardService.getMultiSelectTerm(
                            GridService.getFilterByField(controller.paginationOptions, 'institutionCountryCode', [], true)
                        )
                    },
                    filterHeaderTemplate: multiSelectTemplate,
                    cellTemplate: selectCellTemplate
                },
                {
                    displayName: 'Question',
                    field: 'questionHandle',
                    visible: GridService.getVisibilityByField(controller.columnsVisibility, 'questionHandle', true),
                    filter: {
                        column: 'questionHandle',
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: controller.questionsList,
                        searchEnabled: true,
                        term: RankingsDashboardService.getMultiSelectTerm(
                            GridService.getFilterByField(controller.paginationOptions, 'questionHandle', [], true)
                        )
                    },
                    filterHeaderTemplate: multiSelectTemplate,
                    cellTemplate: selectCellTemplate
                },
                {
                    displayName: 'Total Number',
                    field: 'answers.fte',
                    visible:GridService.getVisibilityByField(controller.columnsVisibility, 'answers.fte', true),
                    filter: {
                        column: 'answers.fte.valueAsString',
                        term: GridService.getFilterByField(controller.paginationOptions, 'answers.fte.valueAsString', '')
                    },
                    cellTemplate: personnelCellTemplate
                },
                {
                    displayName: 'Estimate',
                    visible: GridService.getVisibilityByField(controller.columnsVisibility, 'answers.fte.estimate', true),
                    field: 'answers.fte.estimate',
                    filter: {
                        column: 'answers.fte.estimate',
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: [
                            {value: true, label: 'Yes'},
                            {value: false, label: 'No'}
                        ],
                        term: GridService.getFilterByField(controller.paginationOptions, 'answers.fte.estimate')
                    },
                    maxWidth: 80,
                    filterHeaderTemplate: selectTemplate,
                    cellTemplate: selectCellTemplate
                },
                {
                    displayName: 'URL',
                    field: 'answers.url',
                    visible: GridService.getVisibilityByField(controller.columnsVisibility, 'answers.url', true),
                    filter: {
                        column: 'answers.url.valueAsString',
                        term: GridService.getFilterByField(controller.paginationOptions, 'answers.url.valueAsString', '')
                    },
                    cellTemplate: personnelCellTemplate
                },
                {
                    displayName: 'Evidence',
                    field: 'answers.evidence',
                    enableFiltering: false,
                    visible: GridService.getVisibilityByField(controller.columnsVisibility, 'answers.evidence', true),
                    filter: {
                        column: 'answers.evidence.valueAsString',
                        term: GridService.getFilterByField(controller.paginationOptions, 'answers.evidence.valueAsString', '')
                    },
                    cellTemplate: personnelCellTemplate
                },
                
                {
                    displayName: 'Checked',
                    visible: GridService.getVisibilityByField(controller.columnsVisibility, 'answers.checked', true),
                    field: 'answers.checked.value',
                    enableFiltering: false,
                    filter: {
                        column: 'answers.checked',
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: [
                            {value: true, label: 'Yes'},
                            {value: "false", label: 'No'}
                        ],
                        term: GridService.getFilterByField(controller.paginationOptions, 'answers.checked')
                    },
                    maxWidth: 80,
                    cellTemplate: selectCellTemplate
                },
                {
                    displayName: 'Available',
                    visible: GridService.getVisibilityByField(controller.columnsVisibility, 'answers.url.publiclyAvailable', true),
                    field: 'answers.url.publiclyAvailable',
                    filter: {
                        column: 'answers.url.publiclyAvailable',
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: [
                            {value: true, label: 'Yes'},
                            {value: false, label: 'No'}
                        ],
                        term: GridService.getFilterByField(controller.paginationOptions, 'answers.url.publiclyAvailable')
                    },
                    maxWidth: 80,
                    filterHeaderTemplate: selectTemplate,
                    cellTemplate: selectCellTemplate
                },
                {
                    displayName: 'N/A',
                    visible: GridService.getVisibilityByField(controller.columnsVisibility, 'answers.url.notAvailable', true),
                    field: 'answers.url.notAvailable',
                    filter: {
                        column: 'answers.url.notAvailable',
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: [
                            {value: true, label: 'Yes'},
                            {value: false, label: 'No'}
                        ],
                        term: GridService.getFilterByField(controller.paginationOptions, 'answers.url.notAvailable')
                    },
                    maxWidth: 80,
                    filterHeaderTemplate: selectTemplate,
                    cellTemplate: selectCellTemplate
                },
                {
                    displayName: 'Status',
                    field: 'status',
                    visible: GridService.getVisibilityByField(controller.columnsVisibility, 'status', true),
                    filter: {
                        column: 'status',
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: statusList,
                        searchEnabled: true,
                        term: RankingsDashboardService.getMultiSelectTerm(
                            GridService.getFilterByField(controller.paginationOptions, 'status', [], true)
                        )
                    },
                    filterHeaderTemplate: multiSelectTemplate,
                    cellTemplate: statusCellTemplate
                },
                {
                    displayName: 'Date Added',
                    field: controller.createdAtField,
                    filterCellFiltered: true,
                    filters: [
                        {
                            visible: true,
                            condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL
                        },
                        {
                            condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL
                        }
                    ],
                    filter: {
                        term: GridService.getFilterByField(controller.paginationOptions, 'createdAt', [], true),
                        applyTextFilter: controller.applyDateFilter(controller.createdAtField, '')
                    },
                    cellTemplate: dateCellTemplate,
                    filterHeaderTemplate: '/scripts/components/profiles/tu/departments/datagrid/createdAtFilterHeaderTemplateWithDropDown.html',
                    visible: GridService.getVisibilityByField(controller.columnsVisibility, 'createdAt', true)
                }
            ];
        }
        controller.reloadDatagrid = function () {
            controller.getPage();
        };
        controller.getValue = function (dataObject) {
            return dataObject ? dataObject[Object.keys(dataObject)[0]] : {value: null, estimate: null};
        };
        /**
         * Populates input text field for date field
         *
         * @param {string} filterName
         * @param {mixed} defaultValue
         */
        controller.applyDateFilter = function (filterName, defaultValue) {
            var filter = GridService.getFilterByField(controller.paginationOptions, filterName, defaultValue);
            if (angular.isDefined(filter) &&
                filter !== null
            ) {
                if (angular.isDefined(filter.startDate) &&
                    angular.isDefined(filter.endDate)
                ) {
                    if (filter.startDate !== null &&
                        filter.startDate !== null
                    ) {
                        controller.defaultFilters[filterName] = {
                            startDate: parseInt(filter.startDate, 10),
                            endDate: parseInt(filter.endDate, 10)
                        };
                    } else {
                        controller.defaultFilters[filterName] = {
                            startDate: null,
                            endDate: null
                        };
                    }
                }
            }
        };
        controller.handleCreatedAtDateRange = function (event) {
            if (DateRangeFixerService.sameDayAndNoModel(event, controller.filters.createdAt, lastCreatedAtDateFilter)) {
                event.model.startDate = lastCreatedAtDateFilter.startDate;
                event.model.endDate = lastCreatedAtDateFilter.endDate;
                controller.filters.createdAt = {
                    startDate: lastCreatedAtDateFilter.startDate,
                    endDate: lastCreatedAtDateFilter.endDate
                };
            } else if (DateRangeFixerService.todayRangeAndNoModel(event, lastCreatedAtDateFilter)) {
                event.model.startDate = TimeService.getStartOfToday();
                event.model.endDate = TimeService.getEndOfToday();
                controller.filters.createdAt = {
                    startDate: event.model.startDate.toISOString(),
                    endDate: event.model.endDate.toISOString()
                };
            }
            if (event === null || typeof event === 'undefined') {
                $scope.gridOptions.columnDefs[controller.getColumnNumber(controller.createdAtField)].filters[0].term = null;
                $scope.gridOptions.columnDefs[controller.getColumnNumber(controller.createdAtField)].filters[1].term = null;
            } else if (event.model.startDate && event.model.endDate) {
                $scope.gridOptions.columnDefs[controller.getColumnNumber(controller.createdAtField)].filters[0].term = typeof event.model.startDate === 'object' ? event.model.startDate.format('x') : event.model.startDate;
                $scope.gridOptions.columnDefs[controller.getColumnNumber(controller.createdAtField)].filters[1].term = typeof event.model.endDate === 'object' ? event.model.endDate.format('x') : event.model.endDate;
            }
            lastCreatedAtDateFilter = angular.copy({
                startDate: event.model.startDate,
                endDate: event.model.endDate
            });
        };
        /**
         * getColumnNumber
         * @param  {string} columnName
         */
        controller.getColumnNumber = function (columnName) {
            var key = 0,
                total = $scope.gridOptions.columnDefs.length;
            for (key; key < total; key++) {
                if ($scope.gridOptions.columnDefs[key] &&
                    $scope.gridOptions.columnDefs[key].field === columnName
                ) {
                    return key;
                }
            }
            return null;
        };
        controller.generateQuestionsList = function () {
            var questionsList = [];
            angular.forEach(controller.questions, function (item) {
                if (item.type === controller.types.personal &&
                    item.rankings.indexOf(controller.rankingHandle) !== -1
                ) {
                    if (controller.rankingHandle === 'esg') {
                        if (item.handle === 'link_to_your_institution_sustainability_climate_policy') {
                            if (!item.name.includes(' (ES 4)')) {
                                item.name = item.name + ' (ES 4)';
                            }
                        }
                        if (item.handle === 'link_to_your_institution_sustainable_procurement') {
                            if (!item.name.includes(' (ES 4.1)')) {
                                item.name = item.name + ' (ES 4.1)';
                            }
                        }
                        if (item.handle === 'link_to_your_institution_sustainable_investment_policy') {
                            if (!item.name.includes(' (ES 4.2)')) {
                                item.name = item.name + ' (ES 4.2)';
                            }
                        }
                        if (item.handle === 'link_to_student_led_Society') {
                            if (!item.name.includes(' (ES 5.1)')) {
                                item.name = item.name + ' (ES 5.1)';
                            }
                        }
                        if (item.handle === 'report_carbon_emissions') {
                            if (!item.name.includes(' (ES 7)')) {
                                item.name = item.name + ' (ES 7)';
                            }
                        }
                        if (item.handle === 'carbon_emissions_in_kilograms') {
                            if (!item.name.includes(' (ES 7.1)')) {
                                item.name = item.name + ' (ES 7.1)';
                            }
                        }
                        if (item.handle === 'same_ffigure_for_base_line') {
                            if (!item.name.includes(' (ES 9.1)')) {
                                item.name = item.name + ' (ES 9.1)';
                            }
                        }
                        if (item.handle === 'carbon_redduction_target') {
                            if (!item.name.includes(' (ES 9.2)')) {
                                item.name = item.name + ' (ES 9.2)';
                            }
                        }
                        if (item.handle === 'institution_provide_dedicated_training_on_environmental_aspects') {
                            if (!item.name.includes(' (ES 10)')) {
                                item.name = item.name + ' (ES 10)';
                            }
                        }
                        if (item.handle === 'institution_have_an_assessment_tool_for_assessing') {
                            if (!item.name.includes(' (ES 11)')) {
                                item.name = item.name + ' (ES 11)';
                            }
                        }
                        if (item.handle === 'enery_generated_in_campus') {
                            if (!item.name.includes(' (ES 8.1)')) {
                                item.name = item.name + ' (ES 8.1)';
                            }
                        }
                        if (item.handle === 'campus_building_footprint') {
                            if (!item.name.includes(' (ES 7.2)')) {
                                item.name = item.name + ' (ES 7.2)';
                            }
                        }
                        if (item.handle === 'institution_publicaly_committed_to_reaching_net_zero') {
                            if (!item.name.includes(' (ES 6.1)')) {
                                item.name = item.name + ' (ES 6.1)';
                            }
                        }
                        if (item.handle === 'climate_science_environmental_sustainability') {
                            if(item.name === "They lead to the award of an officially recognised qualification that specifically refers to climate science and environmental sustainability (e.g., MSc in Climate Change: Science, Society and Solutions at the University of Manchester in the UK; BSc in Climate Science and Adaptation at the University of Newcastle, Australia)") {
                                item.name = "They lead to the award of an officially recognised qualification that specifically refers to climate science and environmental sustainability";
                            }
                            if (!item.name.includes(' (EE 3)')) {
                                item.name = item.name + ' (EE 3)';
                            }
                        }
                        if (item.handle === 'presence_of_research_center') {
                            if (!item.name.includes(' (ER 3)')) {
                                item.name = item.name + ' (ER 3)';
                            }
                        }
                        if (item.handle === 'faculty_staff_male') {
                            if (!item.name.includes(' (EQ 3.2)')) {
                                item.name = item.name + ' (EQ 3.2)';
                            }
                        }
                        if (item.handle === 'faculty_staff_female') {
                            if (!item.name.includes(' (EQ 3.1)')) {
                                item.name = item.name + ' (EQ 3.1)';
                            }
                        }
                        if (item.handle === 'faculty_staff_other') {
                            if (!item.name.includes(' (EQ 3.1)')) {
                                item.name = item.name + ' (EQ 3.1)';
                            }
                        }
                        if (item.handle === 'students_female') {
                            if (!item.name.includes(' (EQ 2.1)')) {
                                item.name = item.name + ' (EQ 2.1)';
                            }
                        }
                        if (item.handle === 'students_other') {
                            if (!item.name.includes(' (EQ 2.1)')) {
                                item.name = item.name + ' (EQ 2.1)';
                            }
                        }
                        if (item.handle === 'number_of_members_in_your_leadership_team') {
                            if (!item.name.includes(' (EQ 4.1)')) {
                                item.name = item.name + ' (EQ 4.1)';
                            }
                        }
                        if (item.handle === 'number_of_your_university_senior_leadership_team_male') {
                            if (!item.name.includes(' (EQ 4.2)')) {
                                item.name = item.name + ' (EQ 4.2)';
                            }
                        }
                        if (item.handle === 'cuurent_equality_diversity_and_inclusionn') {
                            if (!item.name.includes(' (EQ 5)')) {
                                item.name = item.name + ' (EQ 5)';
                            }
                        }
                        if (item.handle === 'support_services_for_disabilities') {
                            if (!item.name.includes(' (EQ 7)')) {
                                item.name = item.name + ' (EQ 7)';
                            }
                        }
                        if (item.handle === 'instituion_provide_dedicated_training_on_social_impacts') {
                            if (!item.name.includes(' (EQ 8)')) {
                                item.name = item.name + ' (EQ 8)';
                            }
                        }
                        if (item.handle === 'manage_or_deliver_outreach_projects') {
                            if (!item.name.includes(' (KE 2)')) {
                                item.name = item.name + ' (KE 2)';
                            }
                        }
                        if (item.handle === 'health_and_wellbeing_services') {
                            if (!item.name.includes(' (HW 2)')) {
                                item.name = item.name + ' (HW 2)';
                            }
                        }
                        if (item.handle === 'equality_diversity_and_inclusionn_comittee') {
                            if (!item.name.includes(' (GG 1.7)')) {
                                item.name = item.name + ' (GG 1.7)';
                            }
                        }
                        if (item.handle === 'anti_bribery_policy') {
                            if (!item.name.includes(' (GG 1.5)')) {
                                item.name = item.name + ' (GG 1.5)';
                            }
                        }
                        if (item.handle === 'holistic_ethical_organisational_culture') {
                            if (!item.name.includes(' (GG 1)')) {
                                item.name = item.name + ' (GG 1)';
                            }
                        }
                        if (item.handle === 'university_student_union') {
                            if (!item.name.includes(' (GG 5)')) {
                                item.name = item.name + ' (GG 5)';
                            }
                        }
                        if (item.handle === 'publish_financial_reports') {
                            if (!item.name.includes(' (GG 4)')) {
                                item.name = item.name + ' (GG 4)';
                            }
                        }
                        if (item.handle === 'link_to_your_university_last_governance_meeting') {
                            if (!item.name.includes(' (GG 7.1)')) {
                                item.name = item.name + ' (GG 7.1)';
                            }
                        }
                        if (item.handle === 'institution_formed_sustainability_committee') {
                            if (!item.name.includes(' (GG 8)')) {
                                item.name = item.name + ' (GG 8)';
                            }
                        }
                        if (item.handle === 'institution_formed_sustainability_committee') {
                            if (!item.name.includes(' (GG 8)')) {
                                item.name = item.name + ' (GG 8)';
                            }
                        }
                        if (item.handle === 'does_institution_have_dedicated_staff_member') {
                            if (!item.name.includes(' (GG 3.1)')) {
                                item.name = item.name + ' (GG 3.1)';
                            }
                        }
                        if (item.handle === 'does_your_university_governing_body_have_student_representation') {
                            if (!item.name.includes(' (GG 6.1)')) {
                                item.name = item.name + ' (GG 6.1)';
                            }
                        }
                        if (item.handle === 'your_institution_water_consumption') {
                            if (!item.name.includes(' (AI 1)')) {
                                item.name = item.name + ' (AI 1)';
                            }
                        }
                        if (item.handle === 'your_institution_energy_consumption') {
                            if (!item.name.includes(' (AI 2)')) {
                                item.name = item.name + ' (AI 2)';
                            }
                        }
                        if (item.handle === 'students_receive_scholarship_covering_hundred_percent') {
                            if (!item.name.includes(' (AI 3)')) {
                                item.name = item.name + ' (AI 3)';
                            }
                        }
                        if (item.handle === 'students_receive_scholarship_covering_fifty_percent') {
                            if (!item.name.includes(' (AI 4)')) {
                                item.name = item.name + ' (AI 4)';
                            }
                        }
                    }
                    questionsList.push({
                        value: item.handle,
                        label: item.name
                    });
                }
            });
            controller.questionsList = questionsList;
            if ($scope.gridOptions) {
                $scope.gridOptions.columnDefs[controller.getColumnNumber(controller.questionHandleField)].filter.selectOptions = controller.questionsList;
            }
        };
        controller.initDataGrid = function () {
            var countriesList = [];
            angular.forEach(RankingsDashboardFactory.getCountries(), function (item) {
                countriesList.push({
                    value: item.countryCode,
                    label: item.name
                });
            });
            var statusList = [];
            angular.forEach(controller.status, function (item) {
                statusList.push({
                    value: item,
                    label: item.charAt(0).toUpperCase() + item.slice(1)
                });
            });
            controller.generateQuestionsList();
            var multiSelectTemplate = '/scripts/shared/ui-grid/templates/multiSelectFilterHeaderTemplate.html',
                selectCellTemplate = '/scripts/shared/ui-grid/templates/selectCellTemplate.html',
                statusCellTemplate = '/scripts/modules/rankings/components/rankingsDashboard/datagrid/statusCellTemplate.html',
                personnelCellTemplate = '/scripts/modules/rankings/components/rankingsDashboard/datagrid/personnelCellTemplate.html',
                dateCellTemplate = '/scripts/shared/ui-grid/templates/dateCellTemplate.html',
                selectTemplate = '/scripts/shared/ui-grid/templates/selectFilterHeaderTemplate.html';
            $scope.gridOptions = {
                appScopeProvider: controller,
                enableSorting: true,
                exporterMenuCsv: false,
                exporterMenuAllData: false,
                exporterMenuPdf: false,
                exporterSuppressColumns: ['login'],
                useExternalFiltering: true,
                enableGridMenu: true,
                showGridFooter: true,
                enableFiltering: true,
                enableColumnResize: true,
                enableFullRowSelection: true,
                enableRowSelection: true,
                multiSelect: false,
                enableRowHeaderSelection: false,
                paginationPageSizes: [constants.datagrid.contacts.defaultRowsNumber, 50, 100],
                paginationPageSize: constants.datagrid.contacts.defaultRowsNumber,
                useExternalPagination: true,
                useExternalSorting: true,
                gridMenuCustomItems: [
                    {
                        title: ' Export visible data as csv',
                        action: controller.exportVisible,
                        order: 209
                    },
                    {
                        title: ' Export all data as csv',
                        action: controller.exportAll,
                        order: 210,
                        templateUrl: '/scripts/modules/rankings/components/rankingsDashboard/datagrid/exportAllMenuItem.html',
                    }
                ],
                rowTemplate: '/scripts/modules/rankings/components/rankingsDashboard/datagrid/rowTemplate.html',
                columnDefs: [
                    {
                        displayName: 'Institution Name',
                        field: 'institutionName',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'institutionName', true),
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'institutionName', '')
                        }
                    },
                    {
                        displayName: 'Core ID',
                        field: 'institutionCoreIdAsString',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'institutionCoreIdAsString', true),
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'institutionCoreIdAsString', '')
                        }
                    },
                    {
                        displayName: 'Country',
                        field: 'institutionCountryCode',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'institutionCountryCode', true),
                        filter: {
                            column: 'institutionCountryCode',
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: countriesList,
                            searchEnabled: true,
                            term: RankingsDashboardService.getMultiSelectTerm(
                                GridService.getFilterByField(controller.paginationOptions, 'institutionCountryCode', [], true)
                            )
                        },
                        filterHeaderTemplate: multiSelectTemplate,
                        cellTemplate: selectCellTemplate
                    },
                    {
                        displayName: 'Question',
                        field: 'questionHandle',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'questionHandle', true),
                        filter: {
                            column: 'questionHandle',
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: controller.questionsList,
                            searchEnabled: true,
                            term: RankingsDashboardService.getMultiSelectTerm(
                                GridService.getFilterByField(controller.paginationOptions, 'questionHandle', [], true)
                            )
                        },
                        filterHeaderTemplate: multiSelectTemplate,
                        cellTemplate: selectCellTemplate
                    },
                    {
                        displayName: 'Full Time',
                        field: 'answers.ft',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'answers.ft', true),
                        filter: {
                            column: 'answers.ft.valueAsString',
                            term: GridService.getFilterByField(controller.paginationOptions, 'answers.ft.valueAsString', '')
                        },
                        cellTemplate: personnelCellTemplate
                    },
                    {
                        displayName: 'Part Time',
                        field: 'answers.pt',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'answers.pt', true),
                        filter: {
                            column: 'answers.pt.valueAsString',
                            term: GridService.getFilterByField(controller.paginationOptions, 'answers.pt.valueAsString', '')
                        },
                        cellTemplate: personnelCellTemplate
                    },
                    {
                        displayName: 'Head Count',
                        field: 'answers.hc',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'answers.hc', true),
                        filter: {
                            column: 'answers.hc.valueAsString',
                            term: GridService.getFilterByField(controller.paginationOptions, 'answers.hc.valueAsString', '')
                        },
                        cellTemplate: personnelCellTemplate
                    },
                    {
                        displayName: 'Full Time Equivalent',
                        field: 'answers.fte',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'answers.fte', true),
                        filter: {
                            column: 'answers.fte.valueAsString',
                            term: GridService.getFilterByField(controller.paginationOptions, 'answers.fte.valueAsString', '')
                        },
                        cellTemplate: personnelCellTemplate
                    },
                    {
                        displayName: 'FTE Estimate',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'answers.fte.estimate', true),
                        field: 'answers.fte.estimate',
                        filter: {
                            column: 'answers.fte.estimate',
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: [
                                {value: true, label: 'Yes'},
                                {value: false, label: 'No'}
                            ],
                            term: GridService.getFilterByField(controller.paginationOptions, 'answers.fte.estimate')
                        },
                        maxWidth: 80,
                        filterHeaderTemplate: selectTemplate,
                        cellTemplate: selectCellTemplate
                    },
                    {
                        displayName: 'URL',
                        field: 'answers.url',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'answers.url', true),
                        filter: {
                            column: 'answers.url.valueAsString',
                            term: GridService.getFilterByField(controller.paginationOptions, 'answers.url.valueAsString', '')
                        },
                        cellTemplate: personnelCellTemplate
                    },
                    {
                        displayName: 'Available',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'answers.url.publiclyAvailable', true),
                        field: 'answers.url.publiclyAvailable',
                        filter: {
                            column: 'answers.url.publiclyAvailable',
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: [
                                {value: true, label: 'Yes'},
                                {value: false, label: 'No'}
                            ],
                            term: GridService.getFilterByField(controller.paginationOptions, 'answers.url.publiclyAvailable')
                        },
                        maxWidth: 80,
                        filterHeaderTemplate: selectTemplate,
                        cellTemplate: selectCellTemplate
                    },
                    {
                        displayName: 'N/A',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'answers.url.notAvailable', true),
                        field: 'answers.url.notAvailable',
                        filter: {
                            column: 'answers.url.notAvailable',
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: [
                                {value: true, label: 'Yes'},
                                {value: false, label: 'No'}
                            ],
                            term: GridService.getFilterByField(controller.paginationOptions, 'answers.url.notAvailable')
                        },
                        maxWidth: 80,
                        filterHeaderTemplate: selectTemplate,
                        cellTemplate: selectCellTemplate
                    },
                    // {
                    //     displayName: 'Previously submitted data',
                    //     visible: GridService.getVisibilityByField(controller.columnsVisibility, 'previouslySubmitted', true),
                    //     field: 'previouslySubmitted',
                    //     filter: {
                    //         column: 'previouslySubmitted',
                    //         type: uiGridConstants.filter.SELECT,
                    //         selectOptions: [
                    //             {value: true, label: 'Yes'},
                    //             {value: false, label: 'No'}
                    //         ],
                    //         term: GridService.getFilterByField(controller.paginationOptions, 'previouslySubmitted')
                    //     },
                    //     maxWidth: 80,
                    //     filterHeaderTemplate: selectTemplate,
                    //     cellTemplate: selectCellTemplate
                    // },
                    {
                        displayName: 'Status',
                        field: 'status',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'status', true),
                        filter: {
                            column: 'status',
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: statusList,
                            searchEnabled: true,
                            term: RankingsDashboardService.getMultiSelectTerm(
                                GridService.getFilterByField(controller.paginationOptions, 'status', [], true)
                            )
                        },
                        filterHeaderTemplate: multiSelectTemplate,
                        cellTemplate: statusCellTemplate
                    },
                    {
                        displayName: 'Date Added',
                        field: controller.createdAtField,
                        filterCellFiltered: true,
                        filters: [
                            {
                                visible: true,
                                condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL
                            },
                            {
                                condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL
                            }
                        ],
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'createdAt', [], true),
                            applyTextFilter: controller.applyDateFilter(controller.createdAtField, '')
                        },
                        cellTemplate: dateCellTemplate,
                        filterHeaderTemplate: '/scripts/components/profiles/tu/departments/datagrid/createdAtFilterHeaderTemplateWithDropDown.html',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'createdAt', true)
                    }
                ],
                onRegisterApi: function (gridApi) {
                    controller.gridApi = gridApi;
                    gridApi.selection.on.rowSelectionChanged($scope, function (row) {
                        controller.handleDatagridRowClick(row.entity);
                    });
                    gridApi.core.on.rowsRendered($scope, function () {
                        $timeout(function() {
                            controller.isRendered = true;
                        },250);
                        GridService.generateSelectValues(controller.gridApi.grid.columns, selectValues);
                        GridService.generateSelectValues(controller.gridApi.grid.columns, lastSelectValues);
                    });
                    gridApi.pagination.on.paginationChanged($scope, function (newPage, limit) {
                        controller.defaultPaginationOptions.page = newPage;
                        controller.defaultPaginationOptions.limit = limit;
                        if(!filterChangesInProgress) { 
                            controller.reloadDatagrid();
                        }
                    });
                    gridApi.core.on.sortChanged($scope, controller.handleGridFiltersChanges);
                    gridApi.core.on.filterChanged($scope, controller.handleGridFiltersChanges);
                    gridApi.core.on.rowsVisibleChanged($scope, controller.handleGridVisibleChanges);
                }
            };
            datePickersFixes();
            controller.filters = angular.extend({}, controller.defaultFilters);
        };
        function datePickersFixes() {
            // This is needed to add 'x' icon in date field when prepopulating dates
            var createdAtField = GridService.getFilterByField(controller.paginationOptions, 'createdAt', '');
            if (createdAtField !== null && controller.paginationOptions["filter[createdAt]"] !== null) {
                if (angular.isDefined(createdAtField) &&
                    angular.isDefined(controller.paginationOptions["filter[createdAt]"]) &&
                    angular.isDefined(controller.paginationOptions["filter[createdAt]"].startDate) &&
                    angular.isDefined(controller.paginationOptions["filter[createdAt]"].endDate)
                ) {
                    $scope.gridOptions.columnDefs[getColumnNumber('createdAt')].filters[0].term = controller.paginationOptions["filter[createdAt]"].startDate;
                    $scope.gridOptions.columnDefs[getColumnNumber('createdAt')].filters[1].term = controller.paginationOptions["filter[createdAt]"].endDate;
                }
            }
        }
        function getColumnNumber(columnName) {
            var key = 0,
                total = $scope.gridOptions.columnDefs.length;
            for (key; key < total; key++) {
                if ($scope.gridOptions.columnDefs[key] &&
                    $scope.gridOptions.columnDefs[key].field === columnName
                ) {
                    return key;
                }
            }
            return null;
        }
        controller.getQuestionName = function (questionHandle) {
            return StatisticsHistoryService.getQuestionName(questionHandle, controller.questions);
        };
        controller.questionsWatch = function (questions) {
            if (!questions || !questions.length) {
                return;
            }
            controller.questions = questions;
            
            controller.initDataGrid();
            controller.getPage();
        };
        controller.resetGridFilters = function (filters) {
            // reset filtering
            if (filters !== null && filters.dataType === null && controller.gridApi) {
                GridService.resetConditions();
                angular.forEach(controller.gridApi.grid.columns, function (column) {
                    delete(column.colDef.filter.term);
                });
            }
        };
        controller.filtersWatch = function (filters) {
            controller.resetGridFilters(filters);
            if (!filters || !filters.ranking || !filters.dataType || !RankingsDashboardFactory.isPersonnel()) {
                return;
            }
            RankingsDashboardFactory.reloadDatagrid(false);
            controller.rankingHandle = filters.ranking.handle;
            if (controller.gridApi) {
                controller.reloadDatagrid();
            } else {
                WatchService.create($scope, StatisticsDashboardFactory.getQuestions, controller.questionsWatch);
            }
        };
        controller.datagridReloadWatch = function (reload) {
            if (!reload) {
                return false;
            }
            RankingsDashboardFactory.setIsLoading(true);
            controller.filtersWatch(RankingsDashboardFactory.getFilters());
        };
        controller.initWatches = function () {
            WatchService.create($scope, RankingsDashboardFactory.getFilters, controller.filtersWatch, true);
            WatchService.create($scope, RankingsDashboardFactory.isDatagridReload, controller.datagridReloadWatch);
        };
        controller.$onInit = function () {
            controller.initWatches();
        };
    };
    angular
        .module('qsHub.rankings')
        .controller('Rankings.RankingsPersonnelDatagridController', [
            '$scope',
            '$state',
            '$location',
            '$timeout',
            'constants',
            'uiGridConstants',
            'uiGridExporterConstants',
            'WatchService',
            'RankingsDashboardService',
            'uiGridExporterService',
            'UiGridService',
            'StatisticsHistoryService',
            'TimeService',
            'RankingsDashboardFactory',
            'StatisticsDashboardFactory',
            'DateRangeFixerService',
            App.controllers.RankingsPersonnelDatagrid
        ]);
}(window.angular));
(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {
        components:{}
    });

    App.components.rankingsNumericDatagrid = {
        templateUrl: '/scripts/modules/rankings/components/rankingsDashboard/rankingsNumericDatagrid/rankingsNumericDatagridView.html',
        controller: 'Rankings.RankingsNumericDatagridController'
    };

    angular.module('qsHub.rankings').component('rankingsNumericDatagrid', App.components.rankingsNumericDatagrid);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers: {}});
    App.controllers.RankingsNumericDatagrid = function (
        $scope,
        $state,
        $location,
        $timeout,
        constants,
        uiGridConstants,
        uiGridExporterConstants,
        WatchService,
        RankingsDashboardService,
        uiGridExporterService,
        GridService,
        StatisticsHistoryService,
        TimeService,
        RankingsDashboardFactory,
        StatisticsDashboardFactory,
        DateRangeFixerService,
        GerEmploymentRateService
    ) {
        var controller = this;
        var lastCreatedAtDateFilter = null;
        var selectValues = {},
            lastSelectValues = {},
            surveySharedWithStudentsOptions = [],
            filterChangesInProgress = false;
        var filterTimeoutMilliseconds = constants.datagridInputDelay;
        controller.questions = [];
        controller.isReloading = false;
        controller.isRendered = false;
        controller.gridApi = null;
        controller.reload = true;
        controller.createdAtField = 'createdAt';
        controller.questionHandleField = 'questionHandle';
        controller.filters = null;
        controller.defaultPaginationOptions = {
            page: 1,
            limit: constants.datagrid.contacts.defaultRowsNumber
        };
        controller.paginationOptions = GridService.getColumnsFilters(
            constants.gridNameSpaces.rankDashNumGridFilters,
            ['createdAt']
        );
        controller.columnsVisibility = GridService.getColumnsVisibility(
            constants.gridNameSpaces.rankDashNumGridVisibility
        );
        controller.types = StatisticsDashboardFactory.getTypes();
        controller.status = StatisticsDashboardFactory.getStatuses();
        $scope.gridOptions = null;
        controller.questionsHandles = [
            'qs_employment_rate_auto',
            'evidence_when_shared',
            'evidence_which_year_conducted',
            'number_of_students_entrepreneurship',
            'number_of_students_entrepreneurship_auto'
        ];
        controller.defaultFilters = {
            createdAt: {
                startDate: null,
                endDate: null
            }
        };

        controller.exportAll = function () {
            RankingsDashboardService.getRankingsAnswersByQuestions(
                angular.merge(
                    {
                        'filter[ranking]': controller.rankingHandle,
                        'filter[questionHandle][]': controller.questionsHandles
                    },
                    {
                        'filter[export]': true
                    }
                )
            ).then(function (response) {
                if (typeof response.results === 'undefined') {
                    return false;
                }
                response.results = convertData(angular.copy(response.results));
                response = RankingsDashboardService.formatCsvResults(response.results, TimeService);
                var gridApi = controller.gridApi,
                    gridOptions = $scope.gridOptions,
                    exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                        gridApi.grid,
                        uiGridExporterConstants.VISIBLE
                    ),
                    exportData = RankingsDashboardService.getCsvData(
                        gridApi.grid.columns,
                        response
                    ),
                    csvContent = uiGridExporterService.formatAsCsv(
                        exportColumnHeaders,
                        exportData,
                        gridOptions.exporterCsvColumnSeparator
                    );

                uiGridExporterService.downloadFile(
                    'Numeric-data-' + RankingsDashboardFactory.getRanking() + '-' + TimeService.now() + '.csv',
                    csvContent,
                    gridOptions.exporterOlderExcelCompatibility
                );
            });
        };

        controller.exportVisible = function () {
            var results = $scope.gridOptions.data;
            results = RankingsDashboardService.formatCsvResults(results, TimeService);
            if (typeof results === 'undefined') {
                return false;
            }
            var gridApi = controller.gridApi,
                gridOptions = $scope.gridOptions,
                exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                    gridApi.grid,
                    uiGridExporterConstants.VISIBLE
                ),
                exportData = RankingsDashboardService.getCsvData(
                    gridApi.grid.columns,
                    results
                ),
                csvContent = uiGridExporterService.formatAsCsv(
                    exportColumnHeaders,
                    exportData,
                    gridOptions.exporterCsvColumnSeparator
                );
            uiGridExporterService.downloadFile(
                'Numeric-data-' + RankingsDashboardFactory.getRanking() + '-' + TimeService.now() + '.csv',
                csvContent,
                gridOptions.exporterOlderExcelCompatibility
            );
        };

        function convertData(data) {
            if (!data) {
                return;
            }
            var i = 0,
                total = data.length,
                generic;
            for (i; i < total; i++) {
                generic = data[i].answers[Object.keys(data[i].answers)[0]];
                data[i].answers = {
                    value: generic ? generic.valueAsString : '',
                    estimate : generic ? generic.estimate : false
                };
            }

            return data;
        }

        controller.getPage = function () {
            // Avoid to reload (show/hide) the grid so sorting works
            if (controller.reload) {
                controller.isReloading = true;
                controller.isRendered = false;
            }
            RankingsDashboardFactory.setIsLoading(true);
            controller.reload = true;

            if (!('filter[questionHandle][]' in controller.paginationOptions) ||
                ('filter[questionHandle][]' in controller.paginationOptions &&
                controller.paginationOptions['filter[questionHandle][]'].length === 0)
            ) {
                controller.questionsHandles = RankingsDashboardService.getQuestionsHandles(
                    controller.questions,
                    controller.rankingHandle,
                    controller.types.total
                );
            } else {
                controller.questionsHandles = [];
            }

            for (var i = 0; i < controller.questionsHandles.length; i++) {
                if (controller.questionsHandles[i] === 'employment_rate') {
                    controller.questionsHandles.splice(i, 1);
                    continue;
                }
                if (controller.questionsHandles[i] === 'evidence_link_or_screenshot') {
                    controller.questionsHandles.splice(i, 1);
                }
            }
            if ('filter[answers.estimate]' in controller.paginationOptions &&
                controller.paginationOptions['filter[answers.estimate]'] &&
                (typeof controller.paginationOptions['filter[answers.estimate]'].value === 'boolean' ||
                controller.paginationOptions['filter[answers.estimate]'].value === 'na')
            ) {
                controller.paginationOptions['filter[answers.estimate]'] =
                  controller.paginationOptions['filter[answers.estimate]'].value;
            }
            return RankingsDashboardService.getRankingsAnswersByQuestions(
                angular.merge({
                    'filter[ranking]': controller.rankingHandle,
                    'filter[questionHandle][]': controller.questionsHandles
                }, controller.paginationOptions, controller.defaultPaginationOptions)
            ).then(function (response) {
                if (response) {
                    controller.generateQuestionsList();
                    if (controller.defaultPaginationOptions.page > 1 && response.totalFiltered === 0 && response.totalMatching > 0) {
                        controller.defaultPaginationOptions.page = 1;
                        controller.gridApi.pagination.seek(1);
                        controller.getPage();
                    }
                    response.results = convertData(angular.copy(response.results));
                    $scope.gridOptions.data = response.results;
                    $scope.gridOptions.minRowsToShow = response.results && response.results.length < $scope.gridOptions.paginationPageSize ? response.results.length : $scope.gridOptions.paginationPageSize;
                    $scope.gridOptions.totalItems = response.totalMatching;
                    if (!angular.equals([], response)) { // if not cancelled
                        $timeout(function() {
                            RankingsDashboardFactory.setIsLoading(false);
                        }, 50);
                    }
                } else {
                    $scope.gridOptions.data = [];
                }
                controller.isReloading = false;
            });
        };

        controller.handleGridFiltersChanges = function () {
            var filterColumn,
                gridApi = controller.gridApi;
            filterChangesInProgress = true;
            if(gridApi.pagination.getPage() > 1){
                gridApi.pagination.seek(1);
            }

            controller.paginationOptions = angular.copy(controller.defaultPaginationOptions);
            angular.forEach(gridApi.grid.columns, function (column) {
                filterColumn = column.filters[0].column ? column.filters[0].column : column.field;
                if (
                    typeof column.filters !== 'undefined' &&
                    column.filters[0].term !== 'undefined' &&
                    column.filters[0].term !== ''
                ) {
                    if (filterColumn === 'status' ||
                        filterColumn === 'institutionCountryCode' ||
                        filterColumn === 'questionHandle'
                    ) {
                        var arrayFilter = [];
                        angular.forEach(column.filters[0].term, function (term) {
                            arrayFilter.push(term.value);
                        });
                        controller.paginationOptions['filter[' + filterColumn + '][]'] = arrayFilter;
                    } else if (filterColumn === 'createdAt') {
                        if (typeof column.filters !== 'undefined' &&
                            typeof column.filters[0].term !== 'undefined' &&
                            typeof column.filters[1].term !== 'undefined') {
                            controller.paginationOptions['filter[' + filterColumn + ']'] = {
                                startDate: column.filters[0].term && isNaN(column.filters[0].term) && typeof column.filters[0].term === 'object' ?
                                    column.filters[0].term.format('x') : column.filters[0].term,
                                endDate: column.filters[1].term && isNaN(column.filters[1].term) && typeof column.filters[1].term === 'object' ?
                                    column.filters[1].term.format('x') : column.filters[1].term
                            };
                        } else {
                            $scope.gridOptions.columnDefs[controller.getColumnNumber(controller.createdAtField)].filters[0].term = null;
                            $scope.gridOptions.columnDefs[controller.getColumnNumber(controller.createdAtField)].filters[1].term = null;
                        }
                    } else {
                        controller.paginationOptions['filter[' + filterColumn + ']'] = column.filters[0].term;
                    }
                }
                if (typeof column.sort.direction !== 'undefined') {
                    controller.paginationOptions['sorting[' + filterColumn.replace('AsString', '') + ']'] = column.sort.direction;
                    controller.reload = false;
                }
            });

            if (controller.paginationOptions["filter[createdAt]"] === null || controller.paginationOptions["filter[createdAt]"] === undefined) {
                controller.paginationOptions["filter[createdAt]"] = controller.defaultFilters.createdAt;
            }
            if (controller.paginationOptions["filter[createdAt]"].startDate === null || !angular.isDefined(controller.paginationOptions["filter[createdAt]"].startDate)) {
                controller.paginationOptions["filter[createdAt]"].endDate = null;
                controller.filters.createdAt = {
                    startDate: null,
                    endDate: null
                };
            }
            GridService.storeColumnsFilters(
                constants.gridNameSpaces.rankDashNumGridFilters,
                controller.paginationOptions
            );

            if (angular.isDefined($scope.filterTimeout)) {
                $timeout.cancel($scope.filterTimeout);
            }

            GridService.generateSelectValues(controller.gridApi.grid.columns, selectValues);

            if (!angular.equals(selectValues, lastSelectValues)) {
                filterTimeoutMilliseconds = constants.datagridSelectDelay;
            }

            $scope.filterTimeout = $timeout(function () {
                controller.reloadDatagrid();
                filterChangesInProgress = false;
                lastSelectValues = angular.copy(selectValues);
                filterTimeoutMilliseconds = constants.datagridInputDelay;
            }, filterTimeoutMilliseconds);
        };

        function removeFilter(field) {
            switch (field) {
                case 'questionHandle':
                    controller.paginationOptions['filter[questionHandle][]'] = [];
                    return;
                case 'institutionCountryCode':
                    controller.paginationOptions['filter[institutionCountryCode][]'] = null;
                    return;
                case 'status':
                    controller.paginationOptions['filter[status][]'] = null;
                    return;
                default:
                    controller.paginationOptions['filter[' + field + ']'] = null;
                    return;
            }
        }

        controller.handleGridVisibleChanges = function () {
            var columnsVisibility = GridService.getColumnsVisibility(constants.gridNameSpaces.rankDashNumGridVisibility),
            reload = false;

            if (columnsVisibility) {
                angular.forEach($scope.gridOptions.columnDefs, function (column, key) {
                    if (columnsVisibility[column.field] === true &&
                        column.visible === false
                    ) {
                        if (typeof $scope.gridOptions.columnDefs[key].filter === 'undefined') {
                            $scope.gridOptions.columnDefs[key].filter = {};
                        }
                        if (typeof  $scope.gridOptions.columnDefs[key].filter !== 'undefined' &&
                            $scope.gridOptions.columnDefs[key].filter.hasOwnProperty('term') &&
                            $scope.gridOptions.columnDefs[key].filter.term) {
                                removeFilter(column.field);
                                reload = true;
                        }
                        $scope.gridOptions.columnDefs[key].filter.term = null;
                        if (column.field === 'createdAt') {
                            delete    $scope.gridOptions.columnDefs[key].filters[0].term;
                            controller.filters.createdAt = {
                                startDate: null,
                                endDate: null
                            };
                        }
                    }
                });
                GridService.storeColumnsFilters(
                    constants.gridNameSpaces.rankDashNumGridFilters,
                    controller.paginationOptions
                );
            }

            if ($scope.gridOptions) {
                controller.columnsVisibility = GridService.getGridColumnsVisibility($scope.gridOptions.columnDefs);
                GridService.storeColumnsVisibility(
                    constants.gridNameSpaces.rankDashNumGridVisibility,
                    controller.columnsVisibility
                );
            }
            if (reload) {
                controller.reloadDatagrid();
            }
        };

        controller.handleDatagridRowClick = function (row) {
            var ranking = RankingsDashboardFactory.getRanking(),
                path = 'clients.statistics.data-submission.' + ranking;
            if (ranking === 'ger') {
               path = RankingsDashboardService.getGerPath(row.questionHandle);
            }

            return $location.path(
                $state.href(path).replace('#', '')
            ).search({
                coreId: row.institutionCoreId,
                question: row.questionHandle
            });
        };

        controller.reloadDatagrid = function () {
            controller.getPage();
        };

        /**
         * Populates input text field for date field
         *
         * @param {string} filterName
         * @param {mixed} defaultValue
         */
        controller.applyDateFilter = function (filterName, defaultValue) {
            var filter = GridService.getFilterByField(controller.paginationOptions, filterName, defaultValue);
            if (angular.isDefined(filter) &&
                filter !== null
            ) {
                if (angular.isDefined(filter.startDate) &&
                    angular.isDefined(filter.endDate)
                ) {
                    if (filter.startDate !== null &&
                        filter.startDate !== null
                    ) {
                        controller.defaultFilters[filterName] = {
                            startDate: parseInt(filter.startDate, 10),
                            endDate: parseInt(filter.endDate, 10)
                        };
                    } else {
                        controller.defaultFilters[filterName] = {
                            startDate: null,
                            endDate: null
                        };
                    }
                }
            }
        };

        controller.handleCreatedAtDateRange = function (event) {

            if (DateRangeFixerService.sameDayAndNoModel(event, controller.filters.createdAt, lastCreatedAtDateFilter)) {
                event.model.startDate = lastCreatedAtDateFilter.startDate;
                event.model.endDate = lastCreatedAtDateFilter.endDate;
                controller.filters.createdAt = {
                    startDate: lastCreatedAtDateFilter.startDate,
                    endDate: lastCreatedAtDateFilter.endDate
                };
            } else if (DateRangeFixerService.todayRangeAndNoModel(event, lastCreatedAtDateFilter)) {
                event.model.startDate = TimeService.getStartOfToday();
                event.model.endDate = TimeService.getEndOfToday();
                controller.filters.createdAt = {
                    startDate: event.model.startDate.toISOString(),
                    endDate: event.model.endDate.toISOString()
                };
            }

            if (event === null || typeof event === 'undefined') {
                $scope.gridOptions.columnDefs[controller.getColumnNumber(controller.createdAtField)].filters[0].term = null;
                $scope.gridOptions.columnDefs[controller.getColumnNumber(controller.createdAtField)].filters[1].term = null;
            } else if (event.model.startDate && event.model.endDate) {
                $scope.gridOptions.columnDefs[controller.getColumnNumber(controller.createdAtField)].filters[0].term = typeof event.model.startDate === 'object' ? event.model.startDate.format('x') : event.model.startDate;
                $scope.gridOptions.columnDefs[controller.getColumnNumber(controller.createdAtField)].filters[1].term = typeof event.model.endDate === 'object' ? event.model.endDate.format('x') : event.model.endDate;
            }

            lastCreatedAtDateFilter = angular.copy({
                startDate: event.model.startDate,
                endDate: event.model.endDate
            });
        };

        /**
         * getColumnNumber
         * @param  {string} columnName
         */
        controller.getColumnNumber = function (columnName) {
            var key = 0,
                total = $scope.gridOptions.columnDefs.length;
            for (key; key < total; key++) {
                if ($scope.gridOptions.columnDefs[key] &&
                    $scope.gridOptions.columnDefs[key].field === columnName
                ) {
                    return key;
                }
            }
            return null;
        };

        controller.generateQuestionsList = function () {
            var questionsList = [];
            angular.forEach(controller.questions, function (item) {
                if (item.type === controller.types.total &&
                    item.rankings.indexOf(controller.rankingHandle) !== -1
                ) {
                    questionsList.push({
                        value: item.handle,
                        label: item.name
                    });
                }
            });
            controller.questionsList = questionsList;
            if ($scope.gridOptions) {
                $scope.gridOptions.columnDefs[controller.getColumnNumber(controller.questionHandleField)].filter.selectOptions = controller.questionsList;
            }
        };

        controller.initDataGrid = function () {
            var countriesList = [];
            angular.forEach(RankingsDashboardFactory.getCountries(), function (item) {
                countriesList.push({
                    value: item.countryCode,
                    label: item.name
                });
            });
            var statusList = [];
            angular.forEach(controller.status, function (item) {
                statusList.push({
                    value: item,
                    label: item.charAt(0).toUpperCase() + item.slice(1)
                });
            });
            controller.generateQuestionsList();

            var multiSelectTemplate = '/scripts/shared/ui-grid/templates/multiSelectFilterHeaderTemplate.html',
                selectCellTemplate = '/scripts/shared/ui-grid/templates/selectCellTemplate.html',
                statusCellTemplate = '/scripts/modules/rankings/components/rankingsDashboard/datagrid/statusCellTemplate.html',
                questionCellTemplate = '/scripts/modules/rankings/components/rankingsDashboard/datagrid/questionCellTemplate.html',
                numericCellTemplate = '/scripts/modules/rankings/components/rankingsDashboard/datagrid/numericCellTemplate.html',
                dateCellTemplate = '/scripts/shared/ui-grid/templates/dateCellTemplate.html',
                selectTemplate = '/scripts/shared/ui-grid/templates/selectFilterHeaderTemplate.html';

            $scope.gridOptions = {
                appScopeProvider: controller,
                enableSorting: true,
                exporterMenuCsv: false,
                exporterMenuAllData: false,
                exporterMenuPdf: false,
                enableGridMenu: true,
                exporterSuppressColumns: ['login'],
                useExternalFiltering: true,
                showGridFooter: true,
                //selectedItems: $scope.selectedRankingId,
                enableFiltering: true,
                enableColumnResize: true,
                enableFullRowSelection: true,
                enableRowSelection: true,
                multiSelect: false,
                enableRowHeaderSelection: false,
                paginationPageSizes: [constants.datagrid.contacts.defaultRowsNumber, 50, 100],
                paginationPageSize: constants.datagrid.contacts.defaultRowsNumber,
                useExternalPagination: true,
                useExternalSorting: true,
                gridMenuCustomItems: [
                    {
                        title: ' Export visible data as csv',
                        action: controller.exportVisible,
                        order: 209
                    },
                    {
                        title: ' Export all data as csv',
                        action: controller.exportAll,
                        order: 210,
                        templateUrl: '/scripts/modules/rankings/components/rankingsDashboard/datagrid/exportAllMenuItem.html',
                    }
                ],
                rowTemplate: '/scripts/modules/rankings/components/rankingsDashboard/datagrid/rowTemplate.html',
                columnDefs: [
                    {
                        displayName: 'Institution Name',
                        field: 'institutionName',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'institutionName', true),
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'institutionName', '')
                        }
                    },
                    {
                        displayName: 'Core ID',
                        field: 'institutionCoreIdAsString',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'institutionCoreIdAsString', true),
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'institutionCoreIdAsString', '')
                        }
                    },
                    {
                        displayName: 'Country',
                        field: 'institutionCountryCode',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'institutionCountryCode', true),
                        filter: {
                            column: 'institutionCountryCode',
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: countriesList,
                            searchEnabled: true,
                            term: RankingsDashboardService.getMultiSelectTerm(
                                GridService.getFilterByField(controller.paginationOptions, 'institutionCountryCode', [], true)
                            )
                        },
                        filterHeaderTemplate: multiSelectTemplate,
                        cellTemplate: selectCellTemplate
                    },
                    {
                        displayName: 'Question',
                        field: 'questionHandle',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'questionHandle', true),
                        filter: {
                            column: 'questionHandle',
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: controller.questionsList,
                            searchEnabled: true,
                            term: RankingsDashboardService.getMultiSelectTerm(
                                GridService.getFilterByField(controller.paginationOptions, 'questionHandle', [], true)
                            )
                        },
                        filterHeaderTemplate: multiSelectTemplate,
                        cellTemplate: selectCellTemplate
                    },
                    {
                        displayName: 'Value',
                        field: 'answers.value',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'answers.value', true),
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'answers.value', '')
                        },
                        cellTemplate: numericCellTemplate
                    },
                    {
                        displayName: 'Estimate',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'answers.estimate', true),
                        field: 'answers.estimate',
                        filter: {
                            column: 'answers.estimate',
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: [
                                {value: true, label: 'Yes'},
                                {value: false, label: 'No'},
                                {value: 'na', label: 'N/A'}
                            ],
                            term: GridService.getFilterByField(controller.paginationOptions, 'answers.estimate')
                        },
                        maxWidth: 80,
                        filterHeaderTemplate: selectTemplate,
                        cellTemplate: selectCellTemplate
                    },
                    {
                        displayName: 'Status',
                        field: 'status',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'status', true),
                        filter: {
                            column: 'status',
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: statusList,
                            searchEnabled: true,
                            term: RankingsDashboardService.getMultiSelectTerm(
                                GridService.getFilterByField(controller.paginationOptions, 'status', [], true)
                            )
                        },
                        filterHeaderTemplate: multiSelectTemplate,
                        cellTemplate: statusCellTemplate
                    },
                    {
                        displayName: 'Date Added',
                        field: controller.createdAtField,
                        filterCellFiltered: true,
                        filters: [
                            {
                                visible: true,
                                condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL
                            },
                            {
                                condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL
                            }
                        ],
                        filter : {
                            term: GridService.getFilterByField(controller.paginationOptions, 'createdAt',[], true),
                            applyTextFilter: controller.applyDateFilter(controller.createdAtField, '')
                        },
                        cellTemplate: dateCellTemplate,
                        filterHeaderTemplate: '/scripts/components/profiles/tu/departments/datagrid/createdAtFilterHeaderTemplateWithDropDown.html',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'createdAt', true)
                    },
                ],
                onRegisterApi: function (gridApi) {
                    controller.gridApi = gridApi;
                    gridApi.selection.on.rowSelectionChanged($scope, function (row) {
                        controller.handleDatagridRowClick(row.entity);
                    });

                    gridApi.core.on.rowsRendered($scope, function () {
                        $timeout(function() {
                            controller.isRendered = true;
                        },250);
                        GridService.generateSelectValues(controller.gridApi.grid.columns, selectValues);
                        GridService.generateSelectValues(controller.gridApi.grid.columns, lastSelectValues);
                    });
                    gridApi.pagination.on.paginationChanged($scope, function (newPage, limit) {
                        controller.defaultPaginationOptions.page = newPage;
                        controller.defaultPaginationOptions.limit = limit;
                        if(!filterChangesInProgress) {
                            controller.reloadDatagrid();
                        }
                    });
                    gridApi.core.on.sortChanged($scope, controller.handleGridFiltersChanges);
                    gridApi.core.on.filterChanged($scope, controller.handleGridFiltersChanges);
                    gridApi.core.on.rowsVisibleChanged($scope, controller.handleGridVisibleChanges);
                }
            };
            datePickersFixes();
            controller.filters = angular.extend({}, controller.defaultFilters);
        };

        function datePickersFixes() {
            // This is needed to add 'x' icon in date field when prepopulating dates
            var createdAtField = GridService.getFilterByField(controller.paginationOptions, 'createdAt', '');
            if (createdAtField !== null && controller.paginationOptions["filter[createdAt]"] !== null) {
                if (angular.isDefined(createdAtField) &&
                    angular.isDefined(controller.paginationOptions["filter[createdAt]"]) &&
                    angular.isDefined(controller.paginationOptions["filter[createdAt]"].startDate) &&
                    angular.isDefined(controller.paginationOptions["filter[createdAt]"].endDate)
                ) {
                    $scope.gridOptions.columnDefs[getColumnNumber('createdAt')].filters[0].term = controller.paginationOptions["filter[createdAt]"].startDate;
                    $scope.gridOptions.columnDefs[getColumnNumber('createdAt')].filters[1].term = controller.paginationOptions["filter[createdAt]"].endDate;
                }
            }
        }

        function getColumnNumber(columnName) {
            var key = 0,
                total = $scope.gridOptions.columnDefs.length;
            for (key; key < total; key++) {
                if ($scope.gridOptions.columnDefs[key] &&
                    $scope.gridOptions.columnDefs[key].field === columnName
                ) {
                    return key;
                }
            }

            return null;
        }

        controller.getQuestionName = function (questionHandle) {
            return StatisticsHistoryService.getQuestionName(questionHandle, controller.questions);
        };

        controller.getQuestionValue = function (questionHandle,value ) {
            if(questionHandle === 'evidence_when_shared') {
                return  RankingsDashboardService.getQuestionValue(value,surveySharedWithStudentsOptions);
            }
            return value;
        };

        controller.questionsWatch = function (questions) {
            if (!questions || !questions.length) {
                return;
            }
            controller.questions = questions;
            controller.initDataGrid();
            controller.getPage();
        };

        controller.resetGridFilters = function (filters) {
            // reset filtering
            if (filters !== null && filters.dataType === null && controller.gridApi) {
                GridService.resetConditions();
                angular.forEach(controller.gridApi.grid.columns, function (column) {
                    delete(column.colDef.filter.term);
                });
            }
        };

        controller.filtersWatch = function (filters) {
            controller.resetGridFilters(filters);
            if (!filters || !filters.ranking || !filters.dataType || !RankingsDashboardFactory.isNumeric()) {
                return;
            }
            RankingsDashboardFactory.reloadDatagrid(false);
            // controller.rankingHandle = filters.ranking.handle;
            controller.rankingHandle = filters.dataType === "total" ? 'ger' : filters.ranking.handle;

            if (controller.gridApi) {
                controller.reloadDatagrid();
            } else {
                WatchService.create($scope, StatisticsDashboardFactory.getQuestions, controller.questionsWatch);
            }
        };

        controller.datagridReloadWatch = function (reload) {
            if (!reload) {
                return false;
            }
            RankingsDashboardFactory.setIsLoading(true);
            controller.filtersWatch(RankingsDashboardFactory.getFilters());
        };

        controller.initWatches = function () {
            WatchService.create($scope, RankingsDashboardFactory.getFilters, controller.filtersWatch, true);
            WatchService.create($scope, RankingsDashboardFactory.isDatagridReload, controller.datagridReloadWatch);
        };

        function initLists () {
            GerEmploymentRateService.getSurveySharedWithStudentsOptions().then(function (data) {
                surveySharedWithStudentsOptions = data;
            });
        }

        controller.$onInit = function () {
            controller.initWatches();
            initLists();
        };
    };

    angular
        .module('qsHub.rankings')
        .controller('Rankings.RankingsNumericDatagridController', [
            '$scope',
            '$state',
            '$location',
            '$timeout',
            'constants',
            'uiGridConstants',
            'uiGridExporterConstants',
            'WatchService',
            'RankingsDashboardService',
            'uiGridExporterService',
            'UiGridService',
            'StatisticsHistoryService',
            'TimeService',
            'RankingsDashboardFactory',
            'StatisticsDashboardFactory',
            'DateRangeFixerService',
            'GerEmploymentRateService',
            App.controllers.RankingsNumericDatagrid
        ]);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers: {}});

    App.controllers.rankingsInformation = function (
        $scope,
        $state,
        InstitutionsListService,
        StatisticsDashboardFactory,
        WatchService
    ) {
        var controller = this;
        controller.rankingsInformationTabs = {
            info: {
                tabId: 0,
                state: 'staff.iu.rankings-information.info'
            },
            definitions: {
                tabId: 1,
                state: 'staff.iu.rankings-information.definitions'
            }
        };

        controller.loadSubscriptions = function () {
            InstitutionsListService.getSubscriptions().then(function (response) {
                StatisticsDashboardFactory.setSubscriptions(response);
            });
        };

        controller.initActiveTab = function () {
            switch ($state.current.name) {
                case controller.rankingsInformationTabs.info.state:
                    $scope.activeTab = controller.rankingsInformationTabs.info.tabId;
                    break;
                case controller.rankingsInformationTabs.definitions.state:
                    $scope.activeTab = controller.rankingsInformationTabs.definitions.tabId;
                    break;
                default:
                    if ($state.current.name === 'staff.iu.rankings-information') {
                        $scope.activeTab = controller.rankingsInformationTabs.info.tabId;
                        $state.go(controller.rankingsInformationTabs.info.state);
                    }
                    break;
            }
        };

        controller.initWatches = function () {
            WatchService.create($scope, 'activeTab', function (newValue, oldValue) {
                if (newValue === oldValue) {
                    controller.initActiveTab();
                }
            });

            WatchService.create($scope, function () {
                return $state.$current.name;
            }, function (newValue, oldValue) {
                if (newValue !== oldValue) {
                    controller.initActiveTab();
                }
            });
        };

        controller.init = function () {
            controller.initWatches();
            controller.loadSubscriptions();
        };
        controller.init();
    };

    angular
        .module('qsHub.rankings')
        .controller('Rankings.RankingsInformationController', [
            '$scope',
            '$state',
            'InstitutionsListService',
            'StatisticsDashboardFactory',
            'WatchService',
            App.controllers.rankingsInformation
        ]);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {
        components:{}
    });

    App.components.editRankingsInfo = {
        templateUrl: '/scripts/modules/rankings/components/rankingsInformation/editRankingsInfo/editRankingsInfoView.html',
        controller: 'Rankings.EditRankingsInfoController'
    };

    angular.module('qsHub.rankings').component('editRankingsInfo', App.components.editRankingsInfo);

}(window.angular));

(function (angular, moment) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers:{}});
    App.controllers.EditRankingsInfo = function (
        $scope,
        WatchService,
        RankingInfoFormService,
        StatisticsDashboardFactory
    ) {
        var controller = this;
        controller.statisticsAvailable = [];
        controller.isDataLoading = true;

        controller.isLoading = function () {
            return controller.isDataLoading;
        };

        controller.subscriptionsWatch = function (data) {
            controller.isDataLoading = true;
            if (!data || !data.length) {
                return;
            }
            // fetch rankings info
            RankingInfoFormService.getData().then(function (infoData) {
                StatisticsDashboardFactory.setRankingsData(infoData.results);
                var subscriptions = StatisticsDashboardFactory.getSubscriptions();
                angular.forEach(infoData.results, function (item) {
                    item.name = RankingInfoFormService.getRankingAbbreviation(
                        item.handle,
                        subscriptions
                    );
                    if (item.deadline) {
                        item.deadline = moment(item.deadline);
                    } else {
                        item.deadline = '';
                    }
                    controller.statisticsAvailable.push(item);
                });
                controller.isDataLoading = false;
            });
        };

        controller.initWatches = function () {
            WatchService.create($scope, StatisticsDashboardFactory.getSubscriptions, controller.subscriptionsWatch);
        };

        controller.$onInit = function () {
            controller.initWatches();
        };
    };

    angular
        .module('qsHub.rankings')
        .controller('Rankings.EditRankingsInfoController', [
            '$scope',
            'WatchService',
            'RankingInfoFormService',
            'StatisticsDashboardFactory',
            App.controllers.EditRankingsInfo
        ]);

}(window.angular, window.moment));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {
        components:{}
    });

    App.components.rankingInfoForm = {
        templateUrl: '/scripts/modules/rankings/components/rankingsInformation/editRankingsInfo/rankingInfoForm/rankingInfoFormView.html',
        controller: 'Rankings.RankingInfoFormController',
        bindings: {
            info: '<'
        }
    };

    angular.module('qsHub.rankings').component('rankingInfoForm', App.components.rankingInfoForm);

}(window.angular));

(function (angular, moment) {
    "use strict";

    angular
        .module('qsHub.rankings')
        .controller('Rankings.RankingInfoFormController', [
            '$scope',
            '$timeout',
            '$templateCache',
            'languages',
            'constants',
            'WatchService',
            'RankingInfoFormService',
            'NotifierFactory',
            'AuthenticationService',
            'TimeService',
            'TextService',
            'UserFactory',
            '$state',
            RankingInfoForm
        ]);

    function RankingInfoForm(
        $scope,
        $timeout,
        $templateCache,
        languages,
        constants,
        WatchService,
        RankingInfoFormService,
        NotifierFactory,
        AuthenticationService,
        TimeService,
        TextService,
        UserFactory,
        $state
    ) {
        var controller = this;
        controller.forms = {};
        controller.rankingId = controller.info.id;
        controller.defaultGuideline = {
            version: null,
            url: null,
            language: null
        };
        controller.guideline = angular.copy(controller.defaultGuideline);
        controller.isDescriptionEmpty = false;
        controller.descriptionWords = 0;
        controller.maxSize = 1;
        controller.textEditorOptions = {
            disableDragAndDrop: true,
            styleWithSpan: false,
            dialogsInBody: true,
            minHeight: 280,
            maxHeight: 280,
            toolbar: [
                ['style', ['bold', 'italic', 'underline', 'superscript', 'subscript', 'strikethrough']],
                ['alignment', ['ul', 'ol']],
                ['insert', ['link', 'unlink']],
                ['edit', ['undo', 'redo']],
                ['view', ['codeview']]
            ],
            popover: {
                link: []
            }
        };

        controller.handleClipboardPaste = function (modelName) {
            $timeout(function() {
                controller.info[modelName] = TextService.clearAttributes(controller.info[modelName]);
            }, 65);
        };

        controller.datePickerOptions = {
            minDate: moment(),
            singleDatePicker: true,
        };

        controller.uploadEnabled = true;
        controller.displayRemoveLink = false;
        controller.maxWords = 200;
        controller.submitted = false;
        controller.languages = [];
        controller.dropZoneInstance = null;

        controller.handleUploadError = function (error) {
            var errorMessage = error && error.xhr !== undefined && error.xhr.responseText ? JSON.parse(error.xhr.responseText) : false;
            errorMessage = errorMessage ? errorMessage.message : 'File upload failed. Only PDFs are accepted & up to ' + controller.maxSize + 'MB in size.';
           if(error.status !== 'canceled'){
               if(controller.dropZoneInstance.files.length > 0){
                controller.dropZoneInstance.removeFile(this.files[0]);
               }
               $scope.$apply(function() {
                //controller.displayRemoveLink = false;
                //controller.generalSubmitDisabled = false;
                NotifierFactory.show(
                    'error',
                    errorMessage,
                    'Guideline upload'
                );
            });
           }
        };

        controller.mustUploadGuidelines = function(){
            return controller.isImageBoxEmpty && controller.guideline.language && controller.submitted;
        };

        controller.handlePdfUploaded = function (file, response) {
            $scope.$apply(function() {
                if (!response || !response.status) {
                    controller.handleUploadError();
                } else {
                    //controller.generalSubmitDisabled = false;
                    controller.isImageBoxEmpty = false;
                    controller.guideline.url = response.path;
                    //console.log(controller.guideline);
                    controller.displayRemoveLink = true;
                     NotifierFactory.show(
                    'success',
                    'File successfully uploaded',
                    'Guideline upload'
                );
                }
            });
        };

        controller.handleRemovedFile = function () {
            $timeout(function() {
                controller.uploadEnabled = true;
                controller.displayRemoveLink = false;
            });
        };

        controller.uploadConfig = {
            // http://www.dropzonejs.com/#configuration-options
            dropzone: {
                url: constants.api.rankings.url + '/v1/guideline-upload',
                maxFiles: 1,
                maxFilesize: controller.maxSize,
                uploadMultiple: false,
                parallelUploads: 1,
                autoProcessQueue: true,
                addRemoveLinks: true,
                acceptedFiles: 'application/pdf, application/x-pdf',
                headers: AuthenticationService.getAuthorizationHeader(),
                thumbnailWidth: 200,
                thumbnailHeight: 200,
                paramName: "file",
                previewTemplate: $templateCache.get('/scripts/modules/rankings/components/rankingsInformation/editRankingsInfo/rankingInfoForm/previewTemplateView.html'),
                init: function(){
                    this.on("addedfile", function (file) {
                        $timeout(function() {
                            controller.uploadEnabled = false;
                            });
                         });
                    }
            },
            // http://www.dropzonejs.com/#event-list
            eventHandlers: {
                success: controller.handlePdfUploaded,
                error: controller.handleUploadError,
                removedfile: controller.handleRemovedFile
            }
        };

        controller.resetGuideline = function () {
            controller.guideline.language = null;
            controller.displayRemoveLink = false;
            controller.guideline.version = null;
            controller.uploadEnabled = true;
            controller.dropZoneInstance.removeAllFiles();
        };

        controller.handleAdd = function () {
            controller.submitted = true;
            controller.isDescriptionEmpty = false;
            controller.validatePdfVersion();
            controller.validateImageBox();
            if (typeof (controller.info.definition) !== "undefined" &&
                TextService.cleanHtmlTags(controller.info.definition).length === 0 ||
                controller.info.definition === ""
            ) {
                controller.isDescriptionEmpty = true;
            }

            if (TimeService.isTodayOrLater(controller.info.deadline)) {
                controller.invalidDeadLine = false;
            } else {
                controller.invalidDeadLine = true;
            }

            if (!controller.isValid() ||
                controller.isDescriptionEmpty ||
                controller.isImageBoxEmpty ||
                controller.isVersionEmpty ||
                controller.invalidDeadLine
            ) {
                return false;
            }

            if (controller.guideline &&
                !angular.equals(controller.guideline, controller.defaultGuideline) &&
                controller.guideline.url &&
                controller.guideline.version &&
                controller.guideline.language) {
                if (!controller.info.guidelines) {
                    controller.info.guidelines = [];
                }
                controller.guideline.createdByFullName = UserFactory.getFullName();
                controller.guideline.url = controller.guideline.url.replace('https://', '');
                controller.guideline.createdAt = TimeService.nowUnix();
                controller.guideline.isNotSaved = true;
                controller.info.guidelines.push(angular.copy(controller.guideline));
            }
            controller.info.deadline = TimeService.getInUnix(controller.info.deadline, true);

            RankingInfoFormService.update(
                controller.rankingId,
                controller.info
            ).then(function (response) {
                var success = !(!response || response.error);
                NotifierFactory.show(
                    success ? 'success' : 'error',
                    success ? 'Updated successfully!' : (response && response.error && response.message ? response.message : 'Oops, failed'),
                    'Ranking Information'
                );
                if (success) {
                    if (controller.info.guidelines) {
                        controller.info.guidelines[controller.info.guidelines.length - 1].isNotSaved = false;
                    }
                    controller.info.deadline = moment(controller.info.deadline);
                    controller.resetGuideline();
                }
            });
        };
        /**
         * validate Image uploaded
         */
        controller.validateImageBox = function () {
            controller.isImageBoxEmpty = false;
            if (controller.guideline &&
                !angular.equals(controller.guideline, controller.defaultGuideline) &&
                typeof controller.guideline.language !== "undefined" &&
                controller.guideline.language
            ) {
                if (typeof (controller.guideline.url) === "undefined" ||
                    !controller.guideline.url || controller.dropZoneInstance.files.length === 0
                ) {
                    controller.isImageBoxEmpty = true;
                }
            }
        };
        /**
         * validate PDF version being chosen
         */
        controller.validatePdfVersion = function () {
            controller.isVersionEmpty = false;
            if (controller.guideline &&
                !angular.equals(controller.guideline, controller.defaultGuideline) &&
                typeof controller.guideline.language !== "undefined" &&
                controller.guideline.language
            ) {
                if (typeof (controller.guideline.version) === "undefined" ||
                    !controller.guideline.version
                ) {
                    controller.isVersionEmpty = true;
                }
            }
        };
        controller.isValid = function () {
            if (!controller.submitted) {
                return true;
            }
            // validate max number of words
            if (TextService.countWords(controller.info.definition) > controller.maxWords) {
                NotifierFactory.show(
                    'error',
                    'Definition description has exceeded the limit of ' +
                    controller.maxWords +
                    ' words. Please reduce number of words.',
                    'Saving failed!'
                );

                return false;
            }

            return controller.rankingId;
        };

        controller.handleDescriptionFocus = function () {
            controller.submitted = false;
        };

        controller.isDescriptionInvalid = function (description) {
            return controller.descriptionWords > controller.maxWords;
        };

        controller.setIsDescriptionEmpty = function (value) {
            controller.isDescriptionEmpty = value;
        };
        controller.setInvalidDescription = function (descLength) {
            return descLength ? controller.setIsDescriptionEmpty(false) : controller.setIsDescriptionEmpty(true);
        };

        /**
         * Count words in the string.
         *
         * @param {string} text
         */
        $scope.countWords = function (text) {
            return TextService.countWords(text);
        };

        controller.clearDateErrors = function () {
            if (controller.invalidDeadLine) {
                controller.invalidDeadLine = !TimeService.isTodayOrLater(controller.info.deadline);
            }
        };

        controller.removeValidation = function () {
            controller.invalidDeadLine = false;
            controller.isDescriptionEmpty = false;
            controller.isVersionEmpty = false;
        };

        controller.initWatches = function () {
            WatchService.create($scope, function () {
                return $state.$current.name;
            }, function (newValue, oldValue) {
                if (newValue !== oldValue) {
                   controller.removeValidation();
                }
            });

            WatchService.create($scope, '$ctrl.info', function (info) {
                if (info.open) {
                    controller.removeValidation();
                }
            }, true);

            WatchService.create($scope, function () {
                return controller.info.deadline;
            }, controller.clearDateErrors);
        };

        controller.$onInit = function () {
            controller.initWatches();
            angular.forEach(languages, function (value, key) {
                controller.languages.push({
                    value: value,
                    label: key
                });
            });
        };
    }

}(window.angular, window.moment));

(function(angular, moment) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    App.services.RankingInfoForm = function (
        $resource,
        $log,
        constants
    ) {
        var service = {};

        service.getRankingsModel = function () {
            return $resource(constants.api.rankings.url + '/v1/rankings');
        };

        service.getCategoriesModel = function () {
            return $resource(constants.api.rankings.url + '/v1/categories');
        };

        service.getRankingsUpdateModel = function () {
            return $resource(constants.api.rankings.url + '/v1/rankings/:rankingId', null, {
                update: { method: 'PATCH' }
            });
        };

        service.update = function (rankingId, data) {
            return service.getRankingsUpdateModel().update({
                rankingId: rankingId
            }, data).$promise.then(function (response) {
                if (constants.dev) {
                    $log.log('success, got data: ', response);
                }
                return response;
            }, function (error) {
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        service.getData = function () {
            return service.getRankingsModel().get().$promise.then(function (response) {
                if (constants.dev) {
                    $log.log('success, got data: ', response);
                }
                return response;
            }, function (error) {
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        service.getCategories = function () {
            return service.getCategoriesModel().get().$promise.then(function (response) {
                if (constants.dev) {
                    $log.log('success, got data: ', response);
                }
                return response;
            }, function (error) {
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        service.getRankingName = function (rankingHandle, rankings) {
            var total = rankings.length,
                i = 0;
            for (i; i < total; i++) {
                if (rankings[i].handle === rankingHandle) {
                    return rankings[i].name;
                }
            }
        };

        service.getRankingAbbreviation = function (rankingHandle, rankings) {
            var total = rankings.length,
                i = 0;
            for (i; i < total; i++) {
                if (rankings[i].handle === rankingHandle) {
                    return rankings[i].abbreviation;
                }
            }
        };

        return service;
    };

    angular
        .module('qsHub.rankings')
        .service('RankingInfoFormService', [
            '$resource',
            '$log',
            'constants',
            App.services.RankingInfoForm
        ]);

}(window.angular, window.moment));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {
        components: {}
    });

    App.components.editRankingsDefinitions = {
        templateUrl: '/scripts/modules/rankings/components/rankingsInformation/editRankingsDefinitions/editRankingsDefinitionsView.html',
        controller: 'Rankings.EditRankingsDefinitionsController'
    };

    angular.module('qsHub.rankings').component('editRankingsDefinitions', App.components.editRankingsDefinitions);

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.rankings')
        .controller('Rankings.EditRankingsDefinitionsController', [
            '$scope',
            '$timeout',
            'constants',
            'WatchService',
            'EditRankingsDefinitionsService',
            'StatisticsDashboardFactory',
            'NotifierFactory',
            'LocalStorageService',
            'RankingsService',
            'TextService',
            EditRankingsDefinitions
        ]);

    function EditRankingsDefinitions(
        $scope,
        $timeout,
        constants,
        WatchService,
        RankingsDefinitionsEditService,
        StatisticsDashboardFactory,
        NotifierFactory,
        LocalStorageService,
        RankingsService,
        TextService
    ) {
        var controller = this,
            gerCategories = [
                'employment_rate',
                'employer_partnerships',
                'employers_connections'
            ];

        controller.forms = {};
        controller.categoriesWithQuestions = {};
        controller.selectDefinition = '';
        controller.searchResults = [];

        controller.handleClipboardPaste = function (categoryHandle, questionHandle) {
            $timeout(function() {
                if (questionHandle) {
                    controller.categoriesWithQuestions[categoryHandle].questions[questionHandle].definition =  TextService.clearAttributes(
                        controller.categoriesWithQuestions[categoryHandle].questions[questionHandle].definition
                    );
                } else if (categoryHandle) {
                    controller.categoriesWithQuestions[categoryHandle].definition =  TextService.clearAttributes(
                        controller.categoriesWithQuestions[categoryHandle].definition
                    );
                }
            }, 50);
        };

        controller.configCategoryTextEditor = {
            disableDragAndDrop: true,
            styleWithSpan: false,
            height: 100,
            minHeight: 100,
            maxHeight: 100,
            toolbar: [
                ['style', ['bold', 'italic', 'underline', 'superscript', 'subscript', 'strikethrough']],
                ['alignment', ['ul', 'ol']],
                ['edit', ['undo', 'redo']],
                ['view', ['codeview']]
            ],
            popover: {
                link: []
            }
        };

        controller.initSelectedFilters = function (clear) {
            clear = typeof clear !== 'undefined' ? clear : false;
            var savedSelectedItem = LocalStorageService.getObject(
                constants.gridNameSpaces.editDefinitions
            );
            if (typeof savedSelectedItem !== 'undefined' && !clear) {
                $scope.selectedItem = savedSelectedItem;
            } else {
                $scope.selectedItem = {
                    ranking: null,
                    category: null,
                    question: null
                };
            }
        };

        controller.toggleEditCategory = function (categoryHandle, swap) {
            swap = (typeof swap === 'undefined') ? !controller.categoriesWithQuestions[categoryHandle].disable : swap;
            controller.categoriesWithQuestions[categoryHandle].disable = swap;

            if (controller.categoriesWithQuestions[categoryHandle].disable) {
                controller.categoriesWithQuestions[categoryHandle].definition = controller.definitionsBeforeChanges[categoryHandle].definition;
            }
        };

        controller.toggleEditQuestion = function (categoryHandle, questionHandle, swap) {
            swap = (typeof swap === 'undefined') ? !controller.categoriesWithQuestions[categoryHandle].questions[questionHandle].disable : swap;
            controller.categoriesWithQuestions[categoryHandle].questions[questionHandle].disable = swap;

            if (controller.categoriesWithQuestions[categoryHandle].questions[questionHandle].disable) {
                controller.categoriesWithQuestions[categoryHandle].questions[questionHandle].definition = controller.definitionsBeforeChanges[categoryHandle].questions[questionHandle].definition;
            }
        };

        controller.isCategoryVisible = function (categoryHandle) {
            return typeof $scope.selectedItem.category !== 'undefined' && $scope.selectedItem.category !== null &&
                $scope.selectedItem.category.handle === categoryHandle;
        };

        controller.isQuestionVisible = function (categoryHandle, questionHandle) {
            if (typeof $scope.selectedItem.category !== 'undefined' && $scope.selectedItem.category === null) {
                if (typeof $scope.selectedItem.question !== 'undefined' && $scope.selectedItem.question !== null) {
                    return $scope.selectedItem.question.handle === questionHandle;
                } else if (typeof $scope.selectedItem.question === 'undefined' || $scope.selectedItem.question === null) {
                    return false;
                }
            } else if (typeof $scope.selectedItem.category !== 'undefined' && $scope.selectedItem.category !== null) {
                if (typeof $scope.selectedItem.question !== 'undefined' && $scope.selectedItem.question !== null) {
                    return $scope.selectedItem.question.handle === questionHandle;
                } else if (typeof $scope.selectedItem.question === 'undefined' || $scope.selectedItem.question === null) {
                    return $scope.selectedItem.category.handle === categoryHandle;
                }
            }
        };

        controller.handleSearchCategoryQuestionClick = function (searchValue) {
            var value = angular.copy(searchValue);
            if (searchValue.type === 'category') {
                $scope.selectedItem.question = null;
                $scope.selectedItem.ranking = null;
                delete(value.searchName);
                delete(value.type);
                $scope.selectedItem.category = value;
            } else {
                $scope.selectedItem.category = null;
                $scope.selectedItem.ranking = null;
                delete(value.searchName);
                delete(value.type);
                $scope.selectedItem.question = value;
            }
        };

        controller.handleCategorySubmit = function (categoryHandle) {
            if (!controller.forms.definitions || !controller.forms.definitions.$valid
            ) {
                return false;
            }

            controller.basicDetailsSubmitInProgress = true;
            RankingsDefinitionsEditService.saveCategory(
                controller.categoriesWithQuestions[categoryHandle].id, {
                    definition: controller.categoriesWithQuestions[categoryHandle].definition
                }
            ).then(function (status) {
                controller.categorySubmitCallback(status, categoryHandle);
            });
        };

        controller.categorySubmitCallback = function (status, categoryHandle) {
            NotifierFactory.show(
                status ? 'success' : 'error',
                status ? 'Updated successfully!' : 'Update failed!',
                'Category Definition'
            );
            controller.definitionsBeforeChanges = angular.copy(controller.categoriesWithQuestions);
            controller.toggleEditCategory(categoryHandle);
        };

        controller.handleQuestionSubmit = function (categoryHandle, questionHandle) {
            if (!controller.forms.definitions || !controller.forms.definitions.$valid
            ) {
                return false;
            }

            controller.basicDetailsSubmitInProgress = true;
            RankingsDefinitionsEditService.saveQuestion(
                controller.categoriesWithQuestions[categoryHandle].questions[questionHandle].id, {
                    definition: controller.categoriesWithQuestions[categoryHandle].questions[questionHandle].definition
                }
            ).then(function (status) {
                controller.questionSubmitCallback(status, categoryHandle, questionHandle);
                RankingsService.setQuestionsUpdated();
            });
        };

        controller.questionSubmitCallback = function (status, categoryHandle, questionHandle) {
            NotifierFactory.show(
                status ? 'success' : 'error',
                status ? 'Updated successfully!' : 'Update failed!',
                'Question Definition'
            );
            controller.definitionsBeforeChanges = angular.copy(controller.categoriesWithQuestions);
            controller.toggleEditQuestion(categoryHandle, questionHandle);
        };

        controller.resetDisable = function () {
            angular.forEach(controller.categoriesWithQuestions, function (category) {
                category.disable = true;
                if (category.hasOwnProperty('questions') && !angular.equals({}, category.questions)) {
                    angular.forEach(category.questions, function (question) {
                        question.disable = true;
                    });
                }
            });
        };

        controller.clearFilters = function () {
            controller.initSelectedFilters(true);
            controller.rankingsFiltered = controller.rankings;
            controller.categoriesFiltered = controller.categories;
            controller.questionsFiltered = controller.questions;
            controller.resetDisable();
        };

        controller.hiddenRankings = function (ranking) {
            return ranking.handle === 'all';
        };

        controller.getRankingsDataWatch = function (newValue) {
            if (newValue && newValue.length > 0) {
                var subscriptions = StatisticsDashboardFactory.getSubscriptions();
                if (subscriptions) {
                    controller.rankingsDataWatch();
                    // filter rankings, exclude stars
                    var rankingsFiltered = [];
                    angular.forEach(newValue, function (ranking) {
                        ranking.name = RankingsDefinitionsEditService.getRankingAbbreviation(
                            ranking.handle,
                            subscriptions
                        );
                        rankingsFiltered.push(ranking);
                    });
                    controller.rankingsFiltered = controller.rankings = rankingsFiltered;
                    controller.rankingsWithHandles = [];
                    angular.forEach(controller.rankings, function (data) {
                        controller.rankingsWithHandles[data.handle] = data.name;
                    });
                    RankingsDefinitionsEditService.getCategories().then(function (categories) {
                        if (categories) {
                            controller.categories = [];
                            angular.forEach(categories, function (category) {
                                if (category.handle !== 'graduate_employment_rate' &&
                                    category.handle !== 'employers_connection_with_graduates' &&
                                    category.handle !== 'partnership_with_employers_internships_work_placements'
                                ) {
                                    controller.categories.push(angular.copy(category));
                                    category.type = 'category';
                                    category.searchName = category.name + ' (Category)';
                                    controller.searchResults.push(category);
                                }
                            });
                            controller.categoriesFiltered = controller.categories;
                            RankingsService.getQuestions().then(function (questions) {
                                if (questions) {
                                    for (var i = questions.length - 1; i >= 0; i--) {
                                        if (gerCategories.indexOf(questions[i].categoryHandle) !== -1 &&
                                            gerCategories.indexOf(questions[i].handle) === -1
                                        ) {
                                            questions.splice(i, 1);
                                        }
                                    }
                                    controller.questions = angular.copy(questions);
                                    controller.questionsFiltered = controller.questions;
                                    controller.initCategoriesWithQuestions();
                                    angular.forEach(controller.questions, function (question) {
                                        if (gerCategories.indexOf(question.handle) === -1) {
                                            question.type = 'question';
                                            question.searchName = question.name + ' (Question)';
                                            controller.searchResults.push(question);
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
            }
        };

        controller.selectedItemWatch = function (newValue, oldValue) {
            if (!angular.equals(newValue, oldValue)) {
                LocalStorageService.storeObject(
                    constants.gridNameSpaces.editDefinitions,
                    newValue
                );
            }
            controller.search = null;
            var filteredData = RankingsDefinitionsEditService.filterCategoriesAndQuestions(
                newValue,
                oldValue,
                controller.categoriesWithQuestions,
                controller.categories,
                controller.questions
            );
            controller.categoriesFiltered = filteredData[0] === undefined ? controller.categories : filteredData[0];
            controller.questionsFiltered = filteredData[1] === undefined ? controller.questions : filteredData[1];

            //find parent category, clear question if present and does not exist in chosen category
            if(controller.questionsFiltered && controller.questionsFiltered.length > 0 && $scope.selectedItem && $scope.selectedItem.question){
                var found = false;
                for (var i = 0; i < controller.questionsFiltered.length; i++) {

                    if ($scope.selectedItem.question.handle === controller.questionsFiltered[i].handle) {
                        for(var x = 0; x < controller.categoriesFiltered.length; x++){
                            if(controller.categoriesFiltered[x].handle === $scope.selectedItem.question.categoryHandle){
                                controller.parentCategoryName = controller.categoriesFiltered[x].name;
                            }
                        }
                        found = true;
                    }
                }
                if(!found){
                   $scope.selectedItem.question = null;
                }
            }
        };

        controller.initCategoriesWithQuestions = function () {
            var rankings = [],
                resultRankings = [],
                resultRankingsQuestion = [];

            for (var i = 0; i < controller.categories.length; i++) {
                controller.categoriesWithQuestions[controller.categories[i].handle] = {
                    id: controller.categories[i].id,
                    categoryName: controller.categories[i].name,
                    categoryHandle: controller.categories[i].handle,
                    definition: controller.categories[i].definition,
                    tooltip: controller.categories[i].tooltip,
                    disable: true
                };
                controller.categoriesWithQuestions[controller.categories[i].handle].questions = {};
                rankings = [];
                resultRankings = [];
                for (var j = 0; j < controller.questions.length; j++) {
                    if (controller.categories[i].handle === controller.questions[j].categoryHandle) {
                        resultRankingsQuestion = [];
                        for (var l = 0; l < controller.questions[j].rankings.length; l++) {
                            resultRankingsQuestion.push({
                                handle: controller.questions[j].rankings[l],
                                name: controller.rankingsWithHandles[controller.questions[j].rankings[l]]
                            });
                        }
                        controller.categoriesWithQuestions[controller.categories[i].handle].questions[controller.questions[j].handle] = {
                            id: controller.questions[j].id,
                            questionName: controller.questions[j].name,
                            questionHandle: controller.questions[j].handle,
                            definition: controller.questions[j].definition,
                            tooltip: controller.questions[j].tooltip,
                            rankings: resultRankingsQuestion,
                            categoryHandle: controller.questions[j].categoryHandle,
                            disable: true
                        };
                        rankings = rankings.concat(controller.questions[j].rankings);
                    }
                }
                rankings = rankings.filter(function (elem, index, self) {
                    return index === self.indexOf(elem);
                });
                if (gerCategories.indexOf(controller.categories[i].handle) !== -1) {
                   rankings = ['ger'];
                }
                for (var k = 0; k < rankings.length; k++) {
                    resultRankings.push({
                        handle: rankings[k],
                        name: controller.rankingsWithHandles[rankings[k]]
                    });
                }
                controller.categoriesWithQuestions[controller.categories[i].handle].rankings = resultRankings;
            }

            controller.definitionsBeforeChanges = angular.copy(controller.categoriesWithQuestions);
            controller.selectedItemDataWatch();
            WatchService.create($scope, 'selectedItem', controller.selectedItemWatch, true);
        };

        controller.showQuestions = function () {
            return !($scope.selectedItem && $scope.selectedItem.ranking && $scope.selectedItem.ranking.handle === 'ger') &&
                !($scope.selectedItem && $scope.selectedItem.category && gerCategories.indexOf($scope.selectedItem.category.handle) !== -1);
        };

        controller.initWatches = function () {
           controller.selectedItemDataWatch = WatchService.create($scope, 'selectedItem', controller.selectedItemWatch, true);
            controller.rankingsDataWatch = WatchService.create($scope, StatisticsDashboardFactory.getRankingsData, controller.getRankingsDataWatch);
        };

        controller.$onInit = function () {
            controller.initSelectedFilters();
            controller.initWatches();
        };
    }

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services: {}});

    App.services.EditRankingsDefinitionsService = function ($resource,
                                                            $log,
                                                            constants) {
        var service = {
            deferred: {}
        };

        /**
         * Basic api get
         *
         * @param {$resource} api
         * @returns {array|object}
         */
        service.basicGet = function (api) {
            if (constants.dev) {
                var startTime = new Date().getTime(), endTime;
            }

            return api.get().$promise.then(function (data) {
                if (constants.dev) {
                    endTime = new Date().getTime();
                    $log.log('success, got data: ', data, 'in ' + (endTime - startTime) + ' ms');
                }

                return data.results;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return [];
            });
        };

        /**
         * Get categories update API endpoint.
         *
         * @returns {$resource}
         */
        service.getCategoryUpdateModel = function () {
            return $resource(constants.api.rankings.url + '/v1/categories/:id', null, {
                update: {method: 'PATCH'}
            });
        };

        /**
         * Get categories update API endpoint.
         *
         * @returns {$resource}
         */
        service.getQuestionUpdateModel = function () {
            return $resource(constants.api.rankings.url + '/v1/questions/:id', null, {
                update: {method: 'PATCH'}
            });
        };

        /**
         * Get categories list API endpoint.
         *
         * @returns {$resource}
         */
        service.getRankingsModel = function () {
            return $resource(constants.api.rankings.url + '/v1/rankings');
        };

        /**
         * Get categories list API endpoint.
         *
         * @returns {$resource}
         */
        service.getCategoriesModel = function () {
            return $resource(constants.api.rankings.url + '/v1/categories');
        };

        /**
         * Get questions list API endpoint.
         *
         * @returns {$resource}
         */
        service.getQuestionsModel = function () {
            return $resource(constants.api.rankings.url + '/v1/questions');
        };

        service.getRankings = function () {
            return service.basicGet(service.getRankingsModel());
        };

        service.getCategories = function () {
            return service.basicGet(service.getCategoriesModel());
        };

        /**
         * Save Category data.
         *
         * @param {string} categoryId
         * @param {object} categoryData
         * @returns {unresolved}
         */
        service.saveCategory = function (categoryId, categoryData) {
            var categoryUpdateModel = service.getCategoryUpdateModel();
            return categoryUpdateModel.update(
                {id: categoryId},
                categoryData
            ).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        /**
         * Save Category data.
         *
         * @param {string} questionId
         * @param {object} questionData
         * @returns {unresolved}
         */
        service.saveQuestion = function (questionId, questionData) {
            var questionUpdateModel = service.getQuestionUpdateModel();
            return questionUpdateModel.update(
                {id: questionId},
                questionData
            ).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        service.getRankingName = function (rankingHandle, rankings) {
            var total = rankings.length,
                i = 0;
            for (i; i < total; i++) {
                if (rankings[i].handle === rankingHandle) {
                    return rankings[i].name;
                }
            }
        };

        service.getRankingAbbreviation = function (rankingHandle, rankings) {
            var total = rankings.length,
                i = 0;
            for (i; i < total; i++) {
                if (rankings[i].handle === rankingHandle) {
                    return rankings[i].abbreviation;
                }
            }
        };

        /**
         * @todo rename for chema
         * @param newFilters
         * @param rankingNew
         * @param originalItems
         * @param categoriesFiltered
         * @param questionsFiltered
         */
        service.getNewFilters = function (newFilters, rankingNew, originalItems, categoriesFiltered, questionsFiltered) {
            categoriesFiltered = [];
            questionsFiltered = [];
            angular.forEach(originalItems, function (value) {
                angular.forEach(value.rankings, function (ranking) {
                    if (ranking.handle === newFilters.ranking.handle) {
                        categoriesFiltered.push({
                            handle: value.categoryHandle,
                            name: value.categoryName
                        });
                        angular.forEach(value.questions, function (question) {
                            if (question.rankings.indexOf(rankingNew.handle) !== -1) {
                                questionsFiltered.push({
                                    handle: question.questionHandle,
                                    name: question.questionName
                                });
                            }
                        });
                    }
                });
            });

            return [categoriesFiltered, questionsFiltered];
        };

        service.filterCategoriesAndQuestions = function (
            newFilters,
            oldFilters,
            categoriesWithQuestions,
            categories,
            questions
        ) {
            var rankingNew = angular.isObject(newFilters.ranking) ? newFilters.ranking : null,
                rankingOld = angular.isObject(oldFilters.ranking) ? oldFilters.ranking : null,
                categoryNew = angular.isObject(newFilters.category) ? newFilters.category : null,
                categoryOld = angular.isObject(oldFilters.category) ? oldFilters.category : null,
                questionNew = angular.isObject(newFilters.question) ? newFilters.question : null,
                originalItems = angular.copy(categoriesWithQuestions),
                categoriesFiltered,
                questionsFiltered;

            if (rankingNew !== null) { // ranking is set
                if (!angular.equals(rankingNew, rankingOld)) { // ranking changed
                    newFilters.category = null;
                    newFilters.question = null;
                    
                    return service.getNewFilters(newFilters, rankingNew, originalItems, categoriesFiltered, questionsFiltered);
                } else {
                    if (categoryNew !== null) { // category is set, update question list
                        if (!angular.equals(categoryNew, categoryOld)) { // category changed, reset selected question
                            newFilters.question = null;
                        }
                        questionsFiltered = [];
                        categoriesFiltered = [];
                        angular.forEach(originalItems, function (value) {
                            angular.forEach(value.rankings, function (ranking) {
                                if (ranking.handle === newFilters.ranking.handle) {
                                    categoriesFiltered.push({
                                        handle: value.categoryHandle,
                                        name: value.categoryName
                                    });
                                    angular.forEach(value.questions, function (question) {
                                        angular.forEach(question.rankings, function (rank) {
                                            if (rank.handle.indexOf(newFilters.ranking.handle) !== -1) {
                                                if (categoryNew !== null) {
                                                    if (question.categoryHandle === categoryNew.handle) {
                                                        questionsFiltered.push({
                                                            handle: question.questionHandle,
                                                            name: question.questionName
                                                        });
                                                    }
                                                }
                                            }
                                        });
                                    });
                                }
                            });
                        });
                    } else { // category not set, copy over all questions
                        if (!angular.equals(categoryNew, categoryOld)) {
                            newFilters.category = null;
                            newFilters.question = null;
                        }
                        return service.getNewFilters(newFilters, rankingNew, originalItems, categoriesFiltered, questionsFiltered);
                    }
                }
            } else { // ranking not set
                if (categoryNew !== null) { // category is set, update the question list only
                    questionsFiltered = [];
                    if (typeof originalItems[categoryNew.handle] === 'undefined') {
                        return false;
                    }
                    angular.forEach(originalItems[categoryNew.handle].questions, function (value) {
                        questionsFiltered.push({
                            handle: value.questionHandle,
                            name: value.questionName
                        });
                    });
                } else {
                    if (categoryNew === null) {
                        newFilters.category = null;
                        categoriesFiltered = angular.copy(categories);
                    }
                    if (questionNew === null) {
                        newFilters.question = null;
                        questionsFiltered = angular.copy(questions);
                    }
                }
            }

            return [categoriesFiltered, questionsFiltered];
        };

        return service;
    };

    angular
        .module('qsHub.rankings')
        .service('EditRankingsDefinitionsService', [
            '$resource',
            '$log',
            'constants',
            App.services.EditRankingsDefinitionsService
        ]);

}(window.angular));

(function (angular) {
    "use strict";

    var rankingsScoresController = function (TimeService,
                                             RankingsScoresService,
                                             $q,
                                             $scope,
                                             NotifierFactory,
                                             $anchorScroll,
                                             $location,
                                             $window,
                                             WatchService,
                                             RankingsScoresFactory) {
        var controller = this;

        var logLimit = 10, limit = 10, lastFilters = null;

        controller.reset = reset;
        controller.getScoresInfo = getScoresInfo;
        controller.deleteIndicatorCallback = deleteIndicatorCallback;
        controller.updateIndicatorCallback = updateIndicatorCallback;
        controller.deleteSubjectCallback = deleteSubjectCallback;
        controller.updateSubjectCallback = updateSubjectCallback;
        controller.filtersComplete = filtersComplete;
        controller.handleSiteChange = handleSiteChange;
        controller.addIndicator = addIndicator;
        controller.addSubject = addSubject;
        controller.cloneLastYearData = cloneLastYearData;
        controller.importCompleteCallback = importCompleteCallback;
        controller.closeSidebarCallback = closeSidebarCallback;
        controller.loadMorePublishLogs = loadMorePublishLogs;
        controller.publishButtonLabel = publishButtonLabel;

        var indicatorFieldTemplate = '/scripts/modules/rankings/components/rankingsScores/cellTemplates/indicatorCellTemplate.html',
            weightingTemplate = '/scripts/modules/rankings/components/rankingsScores/cellTemplates/weightingTemplate.html';

        var defaultColumnDefs = [
            {
                displayName: 'Indicator',
                field: 'indicatorId',
                enableFiltering: false,
                enableSorting: false,
                cellTemplate: indicatorFieldTemplate,
                width: '40%'
            },
            {
                displayName: 'Description',
                field: 'description',
                enableFiltering: false,
                enableSorting: false,
                width: '40%'
            },
            {
                displayName: 'Default Weighting',
                field: 'value',
                enableFiltering: false,
                enableSorting: false,
                cellTemplate: weightingTemplate,
                width: '20%'
            }];

        var altColumnDefs = [
            {
                displayName: 'Indicator',
                field: 'indicatorId',
                enableFiltering: false,
                enableSorting: false,
                cellTemplate: indicatorFieldTemplate,
                width: '50%'
            },
            {
                displayName: 'Description',
                field: 'description',
                enableFiltering: false,
                enableSorting: false,
                width: '50%'
            }];

        function initWatches () {
            WatchService.create($scope, RankingsScoresFactory.isRightPanelResetRequired, function (value) {
                if(value){
                    resetSidePanel();
                }
            });
            WatchService.create($scope, RankingsScoresFactory.isPublishButtonClicked, function (newValue) {
                if (newValue) {
                    RankingsScoresFactory.setPublishButtonClickd(false);
                    controller.getScoresInfo();
                }
            });
        }

        function init() {
            initWatches();
            initDatagridOptions();
            controller.loaded = false;
            controller.filters = {};

            controller.filters = {
                year: null,
                ranking: null,
                site: null
            };

            controller.subjects = [];
            controller.subjectGridOptions = {};
            controller.ranking = 'scores';
            $q.all([getScoresRankings(), getScoresIndicators()]).then(function (results) {
                controller.rankingsOptions = results[0];
                controller.indicators = results[1];
                controller.loaded = true;
            });

            controller.siteOptions = [
                {label: 'TopUniversities Rankings', key: 'tu'},
                {label: 'TopMBA Rankings', key: 'tm'}
            ];
            controller.yearOptions = getYearOptions();
            initSubjectDatagridOptions();
        }

        function getScoresRankings() {
            return RankingsScoresService.getScoresRankings().then(function (result) {
                return result.results;
            });
        }

        function getScoresIndicators() {
            return RankingsScoresService.getScoresIndicators().then(function (result) {
                return result.results;
            });
        }

        function getPublishLogs(loadMore) {
            controller.hidePublishLoadMore = false;
            if(!loadMore){
                controller.gettingPublishLogs = true;
            }
            return RankingsScoresService.getPublishLogs(getLogsFilters()).then(function (result) {
                if (result !== 'error') {
                    controller.publishLogs = result.results;
                    if (result.totalFiltered === result.totalMatching) {
                        controller.hidePublishLoadMore = true;
                    }
                } else {
                    NotifierFactory.show(
                        'error',
                        'Failed to get publish logs',
                        'Rankings scores'
                    );
                }
                if(!loadMore){
                    controller.gettingPublishLogs = false;
                }
            });
        }

        function loadMorePublishLogs(){
            logLimit += limit;
            controller.loadingMorePublishLogs = true;
            getPublishLogs(true).then(function(){
                controller.loadingMorePublishLogs = false;
            });
        }

        function getSubjectsInfo() {
            return RankingsScoresService.getSubjectsInfo(getSubjectFilters()).then(function (result) {
                return result.results;
            });
        }

        function getSubjects() {
            return RankingsScoresService.getSubjects().then(function (result) {
                return result.results;
            });
        }

        function getSubjectFilters() {
            return {
                'filter[year]': '=' + controller.filters.year,
                'filter[rankingId]': '=' + controller.filters.ranking,
                'sorting[modifiedAt]': 'desc'
            };
        }

        function disableYears() {
            for (var i = 0; i < controller.yearOptions.length; i++) {
                controller.yearOptions[i].disabled = false;
                if (controller.filters.ranking === 19 && controller.yearOptions[i].key > 2018) {
                    controller.yearOptions[i].disabled = true;
                    if (controller.filters.year > 2018) {
                        controller.filters.year = null;
                    }
                }
                else if(controller.yearOptions[i].key < 2019 &&
                        (controller.filters.ranking === 34 || controller.filters.ranking === 35)) {
                            controller.yearOptions[i].disabled = true;
                            if (controller.filters.year < 2019) {
                                controller.filters.year = null;
                            }
                }
                else if(controller.yearOptions[i].key < 2020 &&
                    (controller.filters.ranking === 48)) {
                        controller.yearOptions[i].disabled = true;
                        if (controller.filters.year < 2020) {
                            controller.filters.year = null;
                        }
                }
            }
        }

        function getScoresInfo() {
            if (controller.filters.ranking) {
                    disableYears();
            }
            if (filtersComplete()) {
                if(!angular.equals(lastFilters,controller.filters)){
                    resetSidePanel();
                }
                logLimit = limit;
                controller.loadingResults = true;
                controller.hidePublishLoadMore = false;
                getPublishLogs();
                // Default values
                controller.hasSubjects = false;
                controller.hasWeightings = true;
                controller.gridOptions.columnDefs = defaultColumnDefs;
                if (rankingHasSubjects(controller.filters.ranking)) {
                    loadSubjects();
                    controller.gridOptions.columnDefs = altColumnDefs;
                    controller.hasWeightings = false;
                    controller.hasSubjects = true;
                } else if(rankingHasNoWeight(controller.filters.ranking)) {
                    controller.gridOptions.columnDefs = altColumnDefs;
                    controller.hasWeightings = false;
                }
                lastFilters = angular.copy(controller.filters);
                return RankingsScoresService.getScoresInfo(getScoresFilters()).then(function (result) {

                    controller.gridOptions.data = matchIndicatorIdsToNames(result.results);
                    if (result.results.length) {
                        controller.noData = false;
                    } else {
                        controller.noData = true;
                    }
                    controller.indicators = markTakenIndicators(controller.gridOptions.data);
                    setTotalPercentage(controller.gridOptions.data);
                    controller.loadingResults = false;
                });
            }
        }

        function rankingHasSubjects(rankingId){
            for(var i = 0; i < controller.rankingsOptions.length; i++){
                if(controller.rankingsOptions[i].coreId === rankingId){
                    return  controller.rankingsOptions[i].rankingTypeId === 1;
                }
            }
        }

        function rankingHasNoWeight(rankingId){
            for(var i = 0; i < controller.rankingsOptions.length; i++){
                if(controller.rankingsOptions[i].coreId === rankingId){
                    return  controller.rankingsOptions[i].rankingTypeId === 3;
                }
            }
        }

        function loadSubjects() {
            controller.loadingSubjects = true;
            $q.all([getSubjectsInfo(), getSubjects()]).then(function (results) {
                controller.subjects = getLockedSubjects(results[1], results[0]);
                controller.subjectGridOptions.data = prepareSubjectData(results[0]);
                controller.loadingSubjects = false;
                RankingsScoresFactory.setSubjects(controller.subjectGridOptions.data);
            });
        }

        function getLockedSubjects(subjectOptions, currentSubjects) {
            var subjectOptionsCopy = angular.copy(subjectOptions);
            for (var i = 0; i < currentSubjects.length; i++) {
                for (var x = 0; x < subjectOptionsCopy.length; x++) {
                    if (currentSubjects[i].subjectId === subjectOptionsCopy[x].coreId) {
                        subjectOptionsCopy[x].locked = true;
                        break;
                    }
                }
            }

            return subjectOptionsCopy;
        }

        function setTotalPercentage(results) {

            var total = 0;
            results.forEach(function (result) {
                total += result.value;

            });
            controller.total = total.toFixed(2);
        }

        function markTakenIndicators(results) {
            for (var i = 0; i < controller.indicators.length; i++) {
                controller.indicators[i].locked = false;
            }
            results.forEach(function (result) {
                for (var i = 0; i < controller.indicators.length; i++) {
                    if (result.indicatorId === controller.indicators[i].coreId) {
                        controller.indicators[i].locked = true;
                        break;
                    }
                }
            });

            return controller.indicators;
        }

        function handleSiteChange() {
            if (controller.filters.site && controller.filters.ranking) {
                if (!rankingBelongsToSite(controller.filters.site.key, controller.filters.ranking)) {
                    controller.filters.ranking = null;
                    controller.selectedRow = null;
                }
            }
        }

        function rankingBelongsToSite(site, rankingCoreId) {
            for (var i = 0; i < controller.rankingsOptions.length; i++) {
                if (controller.rankingsOptions[i].coreId === rankingCoreId) {
                    for (var x = 0; x < controller.rankingsOptions[i].belongsTo.length; x++) {
                        if (controller.rankingsOptions[i].belongsTo[x] === site) {
                            return true;
                        }
                    }
                }
            }
            return false;
        }

        function getScoresFilters() {
            return {
                'filter[rankingId]': '=' + controller.filters.ranking,
                'filter[year]': '=' + controller.filters.year,
                'filter[deleted]': 'false',
                'sorting[modifiedAt]': 'desc'
            };
        }

        function getLogsFilters() {
            return {
                'filter[rankingId]': '=' + controller.filters.ranking,
                'filter[year]': '=' + controller.filters.year,
                'sorting[createdAt]': 'desc',
                'limit': logLimit
            };
        }

        function matchIndicatorIdsToNames(data, dataKey) {
            dataKey = dataKey || 'indicatorId';
            var copiedData = angular.copy(data);
            if (copiedData && copiedData.length) {
                for (var i = 0; i < copiedData.length; i++) {
                    for (var x = 0; x < controller.indicators.length; x++) {
                        if (copiedData[i][dataKey] === controller.indicators[x].coreId) {
                            copiedData[i].indicatorName = controller.indicators[x].name;
                            copiedData[i].indicatorAcronym = controller.indicators[x].acronym;
                            copiedData[i].indicatorCombinedName = controller.indicators[x].name + ' (' + controller.indicators[x].acronym + ')';
                            break;
                        }
                    }
                }
            }
            
            return copiedData;
        }

        function prepareSubjectData(data) {
            var copiedData = angular.copy(data);
            for (var i = 0; i < copiedData.length; i++) {
                for (var x = 0; x < controller.subjects.length; x++) {
                    if (copiedData[i].subjectId === controller.subjects[x].coreId) {
                        copiedData[i].subjectName = controller.subjects[x].name + ' (' + controller.subjects[x].acronym + ')';
                        copiedData[i].acronym = controller.subjects[x].acronym;
                        for (var key in copiedData[i].subjectIndicators) {
                            if (copiedData[i].subjectIndicators.hasOwnProperty(key)) {
                                for (var n = 0; n < controller.indicators.length; n++) {
                                    if (controller.indicators[n].coreId === parseInt(key)) {
                                        copiedData[i].subjectIndicators[key].acronym = controller.indicators[n].acronym;
                                        copiedData[i].subjectIndicators[key].name = controller.indicators[n].name;
                                    }
                                }
                            }
                        }
                        break;
                    }
                }
            }
            return copiedData;
        }

        function reset() {
            controller.filters = {};
            controller.hidePublishLoadMore = false;
            logLimit = limit;
            resetSidePanel();
            disableYears();
        }

        function handleDatagridRowClick(entity) {
            controller.selectedRow = entity;
            controller.selectedSubject = null;
            controller.selectedRowId = entity.id;
            RankingsScoresFactory.setResetRightPanel(false);
        }

        function deleteIndicatorCallback(id) {
            removeRowFromResults(id, controller.gridOptions.data);
            setTotalPercentage(controller.gridOptions.data);
            controller.indicators = markTakenIndicators(controller.gridOptions.data);
            if (rankingHasSubjects(controller.filters.ranking)) {
                loadSubjects();
            }
        }

        function updateIndicatorCallback(id) {
            controller.loaded = false;
            getScoresIndicators().then(function (results) {
                controller.indicators = results;
            }).then(function () {
                getScoresInfo().then(function(){
                    for(var i = 0; i < controller.gridOptions.data.length; i++){
                        if(controller.gridOptions.data[i].id===controller.selectedRowId || id){
                            handleDatagridRowClick(controller.gridOptions.data[i]);
                            break;
                        }
                    }
                    controller.loaded = true;
                });
            });
        }

        function deleteSubjectCallback(id) {
            removeRowFromResults(id, controller.subjectGridOptions.data);
            loadSubjects();
        }

        function updateSubjectCallback() {
            loadSubjects();
        }

        function removeRowFromResults(id, data) {
            for (var i = 0; i < data.length; i++) {
                if (data[i].id === id) {
                    data.splice(i, 1);
                }
            }
        }

        function addIndicator() {
            controller.selectedRow = {};
            controller.selectedRowId = null;
            controller.selectedSubject = null;
            scrollToSidebar();
            RankingsScoresFactory.setResetRightPanel(false);
        }

        function getYearOptions() {
            var now = TimeService.now().add(1, 'year');
            var yearOptions = [];

            yearOptions.push(angular.copy({label: now.year(), key: now.year()}));

            for (var i = 0; i < 4; i++) {
                var year = angular.copy(now.subtract(1, 'years'));
                yearOptions.push(angular.copy({label: year.year(), key: year.year()}));
            }

            return yearOptions;

        }

        function filtersComplete() {
            return controller.filters.site && controller.filters.ranking && controller.filters.year;
        }

        function initDatagridOptions() {

            controller.gridOptions = angular.extend({}, controller.gridOptions, {
                appScopeProvider: controller,
                enableSorting: true,
                exporterMenuCsv: false,
                exporterMenuAllData: false,
                exporterMenuPdf: false,
                useExternalFiltering: true,
                enableGridMenu: true,
                showGridFooter: false,
                enableFiltering: false,
                enableColumnResize: true,
                enableFullRowSelection: true,
                enableRowSelection: true,
                multiSelect: false,
                enableRowHeaderSelection: false,
                useExternalSorting: true,
                enablePaginationControls: false,
                rowTemplate: '/scripts/shared/ui-grid/templates/rowTemplate.html',
                columnDefs: [],
                onRegisterApi: function (gridApi) {
                    controller.gridApi = gridApi;

                    gridApi.selection.on.rowSelectionChanged($scope, function (row) {
                        handleDatagridRowClick(row.entity);
                    });

                }
            });

        }

        function initSubjectDatagridOptions() {

            var indicatorsTemplate = '/scripts/modules/rankings/components/rankingsScores/cellTemplates/subjectIndicatorsTemplate.html';

            controller.subjectGridOptions = angular.extend({}, controller.subjectGridOptions, {
                appScopeProvider: controller,
                data: controller.subjects,
                enableSorting: true,
                exporterMenuCsv: false,
                exporterMenuAllData: false,
                exporterMenuPdf: false,
                useExternalFiltering: false,
                enableGridMenu: true,
                showGridFooter: false,
                enableFiltering: true,
                enableColumnResize: true,
                enableFullRowSelection: true,
                enableRowSelection: true,
                multiSelect: false,
                enableRowHeaderSelection: false,
                useExternalSorting: false,
                enablePaginationControls: true,
                paginationPageSizes: [10, 25, 50, 100],
                paginationPageSize: 10,
                rowTemplate: '/scripts/shared/ui-grid/templates/rowTemplate.html',
                columnDefs: [
                    {
                        displayName: 'Subject',
                        field: 'subjectName',
                        enableFiltering: true,
                        enableSorting: false,
                        width: '50%'
                    },
                    {
                        displayName: 'Indicators',
                        field: 'subjectIndicators',
                        enableFiltering: false,
                        enableSorting: false,
                        cellTemplate: indicatorsTemplate,
                        width: '50%'
                    }
                ],
                onRegisterApi: function (gridApi) {
                    controller.gridApi = gridApi;

                    gridApi.selection.on.rowSelectionChanged($scope, function (row) {
                        handleSubjectDatagridRowClick(row.entity);
                    });
                }
            });
        }

        function handleSubjectDatagridRowClick(entity) {
            controller.selectedSubject = entity;
            controller.selectedRow = null;
            controller.selectedRowId = entity.id;
            RankingsScoresFactory.setResetRightPanel(false);
        }

        function addSubject() {
            controller.selectedSubject = {};
            controller.selectedRowId = null;
            controller.selectedRow = null;
            scrollToSidebar();
            RankingsScoresFactory.setResetRightPanel(false);
        }

        function cloneLastYearData() {
            var filters = {
                rankingId: controller.filters.ranking,
                year: controller.filters.year
            };
            controller.loadingResults = true;
            RankingsScoresService.cloneLastYearData(filters).then(function (result) {
                if (result !== 'error') {
                    NotifierFactory.show(
                        'success',
                        'Indicators copied successfully from year ' + result.year,
                        'Rankings scores'
                    );
                    getScoresInfo();
                } else {
                    NotifierFactory.show(
                        'error',
                        'Clone failed',
                        'Rankings scores'
                    );
                    controller.loadingResults = false;
                }

            });
        }

        function resetSidePanel() {
            controller.selectedSubject = null;
            controller.selectedRow = null;
        }

        function scrollToSidebar() {
            if ($window.innerWidth < 992) {
                $location.hash('edit-sidebar');
                $anchorScroll();
            }
        }

        function importCompleteCallback() {
            getPublishLogs();
        }

        function closeSidebarCallback(){
            controller.selectedRowId = null;
        }

        function publishButtonLabel(){
            return controller.hasSubjects ? 'Publish all subjects to mobile app': 'Publish to mobile app';
        }

        init();
    };

    angular.module('qsHub.rankings').controller('Rankings.RankingsScoresController', [
        'TimeService',
        'RankingsScoresService',
        '$q',
        '$scope',
        'NotifierFactory',
        '$anchorScroll',
        '$location',
        '$window',
        'WatchService',
        'RankingsScoresFactory',
        rankingsScoresController
    ]);

}(window.angular));
(function (angular) {
    "use strict";

    angular.module('qsHub.rankings').service('RankingsScoresService', [
        '$resource',
        'constants',
        'RequestsQueueService',
        'RankingsScoresFactory',
        rankingsScoresService
    ]);

    function rankingsScoresService(
        $resource,
        constants,
        RequestsQueueService,
        RankingsScoresFactory
    ) {
        var svc = {};

        svc.getScoresIndicators = getScoresIndicators;
        svc.getScoresRankings = getScoresRankings;
        svc.getScoresInfo = getScoresInfo;
        svc.deleteScore = deleteScore;
        svc.editScore = editScore;
        svc.createScore = createScore;
        svc.getPublishLogs = getPublishLogs;
        svc.getPublishLogDetails = getPublishLogDetails;
        svc.getPublishPreview = getPublishPreview;
        svc.getSubjectsInfo = getSubjectsInfo;
        svc.getSubjects = getSubjects;
        svc.editScoreSubject = editScoreSubject;
        svc.createScoreSubject = createScoreSubject;
        svc.deleteScoreSubject = deleteScoreSubject;
        svc.publishImportData = publishImportData;
        svc.savePublishData = savePublishData;
        svc.cancelPublish = cancelPublish;
        svc.isPublishRunning = isPublishRunning;
        svc.cloneLastYearData = cloneLastYearData;
        svc.getPublishLogFeed = getPublishLogFeed;
        svc.getScoresDrupalFormat = getScoresDrupalFormat;
        svc.sendPublishInfoToQSRankingsApp = sendPublishInfoToQSRankingsApp;

        function getScoresModel() {
            return $resource(constants.api.messages.url, {
                scoreId: '@scoreId',
                logId: '@logId',
                scoreSubjectId: '@scoreSubjectId',
                publishId: '@publishId'
            }, {
                getScoresRankings: {method: 'GET', url: constants.api.rankings.url + '/v1/rankings-scores/rankings'},
                getScoresIndicators: {
                    method: 'GET',
                    url: constants.api.rankings.url + '/v1/rankings-scores/indicators'
                },
                getScoresInfo: {method: 'GET', url: constants.api.rankings.url + '/v1/rankings-scores/info'},
                getPublishLogs: {method: 'GET', url: constants.api.rankings.url + '/v1/rankings-scores/publish-log'},
                getPublishLogDetails: {
                    method: 'GET',
                    params: {
                        'columns[]': ['status', 'name', 'modifiedAt', 'id', 'feed']
                    },
                    url: constants.api.rankings.url + '/v1/rankings-scores/publish-log-details/search'
                },
                getPublishPreview: {
                    method: 'GET',
                    url: constants.api.rankings.url + '/v1/rankings-scores/tmp',
                    params: {
                        'columns[]': ['id', 'institutionCoreId', 'institutionName', 'rankingsFacultyId', 'rankingIndicatorId', 'rank',
                            'score', 'displayRank', 'displayScore', 'type'],
                        'filter[type]':'scores'
                    }
                },
                getScoresDrupalFormat: {
                    method: 'GET',
                    url: constants.api.rankings.url + '/v1/rankings-scores/tmp/drupal/:site/:rankingId/:year/:subject',
                    params: {
                    }
                },
                getSubjectsInfo: {method: 'GET', url: constants.api.rankings.url + '/v1/rankings-scores/info-subjects'},
                getSubjects: {method: 'GET', url: constants.api.rankings.url + '/v1/rankings-scores/subjects'},
                delete: {method: 'DELETE', url: constants.api.rankings.url + '/v1/rankings-scores/info/:scoreId'},
                update: {method: 'PUT', url: constants.api.rankings.url + '/v1/rankings-scores/info/:scoreId'},
                create: {method: 'POST', url: constants.api.rankings.url + '/v1/rankings-scores/info'},
                editScoreSubject: {
                    method: 'PUT',
                    url: constants.api.rankings.url + '/v1/rankings-scores/info-subjects/:scoreSubjectId'
                },
                createScoreSubject: {
                    method: 'POST',
                    url: constants.api.rankings.url + '/v1/rankings-scores/info-subjects'
                },
                deleteScoreSubject: {
                    method: 'DELETE',
                    url: constants.api.rankings.url + '/v1/rankings-scores/info-subjects/:scoreSubjectId'
                },
                publishImportData: {
                    method: 'GET',
                    url: constants.api.rankings.url + '/v1/rankings-scores/publish'
                },
                savePublishData: {
                    method: 'GET',
                    url: constants.api.rankings.url + '/v1/rankings-scores/save'
                },
                cancelPublish: {
                    method: 'GET',
                    url: constants.api.rankings.url + '/v1/rankings-scores/publish/cancel/:publishId'
                },
                isPublishRunning: {
                    method: 'GET',
                    url: constants.api.rankings.url + '/v1/rankings-scores/publish-log'
                },
                cloneLastYearData: {
                    method: 'GET',
                    url: constants.api.rankings.url + '/v1/rankings-scores/info/copy'
                },
                getPublishLogFeed: {
                    method: 'GET',
                    url: constants.api.rankings.url + '/v1/rankings-scores/publish-log-details/:logId'
                },
                search: {
                    method: 'GET',
                    url: constants.api.rankings.url + '/v1/list/scores',
                    cancellable : true
                },
                sendPublishInfoToQSRankingsApp: {
                    method: 'GET',
                    url: constants.api.rankings.url + '/v1/rankings-app/publish-alert'
                },
            });
        }

        svc.search = function (filters) {
            var key = 'RankingsScoresService:search',
                Api;
            RequestsQueueService.cancelAll(key);
            Api = getScoresModel().search(filters);
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                return data;
            }, function () {
                return false;
            });
        };

        function getScoresIndicators() {
            return getScoresModel().getScoresIndicators().$promise.then(function (result) {
                return result;
            }, function (error) {
                return error;
            });
        }

        function getScoresRankings() {
            return getScoresModel().getScoresRankings().$promise.then(function (result) {
                return result;
            }, function (error) {
                return error;
            });
        }

        function getScoresInfo(filters) {
            RankingsScoresFactory.setRankingsScoresDataChange(true);
            return getScoresModel().getScoresInfo(filters).$promise.then(function (result) {
                return result;
            }, function (error) {
                return error;
            });
        }

        function deleteScore(scoreId) {
            RankingsScoresFactory.setRankingsScoresDataChange(true);
            return getScoresModel().delete({scoreId: scoreId}).$promise.then(function (result) {
                return result;
            }, function (error) {
                return error;
            });
        }

        function editScore(scoreId, scoreObject) {
            
            return getScoresModel().update({scoreId: scoreId}, scoreObject).$promise.then(function (result) {
                return result;
            }, function (error) {
                return 'error';
            });
        }

        function createScore(scoreObject) {
            return getScoresModel().create(scoreObject).$promise.then(function (result) {
                return result;
            }, function (error) {
                return 'error';
            });
        }

        function getPublishLogs(filters) {
            return getScoresModel().getPublishLogs(filters).$promise.then(function (result) {
                return result;
            }, function (error) {
                return 'error';
            });
        }

        function getPublishLogDetails(filters) {
            return getScoresModel().getPublishLogDetails(filters).$promise.then(function (result) {
                return result;
            }, function (error) {
                return 'error';
            });
        }

        function getPublishPreview(filters) {
            return getScoresModel().getPublishPreview(filters).$promise.then(function (result) {
                return result;
            }, function (error) {
                return 'error';
            });
        }

        function getSubjectsInfo(filters) {
            return getScoresModel().getSubjectsInfo(filters).$promise.then(function (result) {
                return result;
            }, function () {
                return 'error';
            });
        }

        function getSubjects(filters) {
            RankingsScoresFactory.setRankingsScoresDataChange(true);
            return getScoresModel().getSubjects(filters).$promise.then(function (result) {
                return result;
            }, function () {
                return 'error';
            });
        }

        function editScoreSubject(id, subjectObject) {
            return getScoresModel().editScoreSubject({scoreSubjectId: id}, subjectObject).$promise.then(function (result) {
                return result;
            }, function () {
                return 'error';
            });
        }

        function createScoreSubject(subjectObject) {
            return getScoresModel().createScoreSubject(subjectObject).$promise.then(function (result) {
                return result;
            }, function () {
                return 'error';
            });
        }

        function deleteScoreSubject(id) {
            RankingsScoresFactory.setRankingsScoresDataChange(true);
            return getScoresModel().deleteScoreSubject({scoreSubjectId: id}).$promise.then(function (result) {
                return result;
            }, function () {
                return 'error';
            });
        }

        function publishImportData(filters) {
            return getScoresModel().publishImportData(filters).$promise.then(function (result) {
                return result;
            }, function () {
                return 'error';
            });
        }

        function savePublishData(filters) {
            return getScoresModel().savePublishData(filters).$promise.then(function (result) {
                return result;
            }, function (error) {
                return 'error';
            });
        }

        function cancelPublish(publishId) {
            return getScoresModel().cancelPublish({publishId: publishId}).$promise.then(function (result) {
                return result;
            }, function (error) {
                return 'error';
            });
        }

        function isPublishRunning(filters) {
            return getScoresModel().isPublishRunning(filters).$promise.then(function (result) {
                return result;
            }, function (error) {
                return 'error';
            });
        }

        function cloneLastYearData(filters) {
            return getScoresModel().cloneLastYearData(filters).$promise.then(function (result) {
                return result;
            }, function (error) {
                return 'error';
            });
        }

        function getPublishLogFeed(logId) {
            return getScoresModel().getPublishLogFeed({logId: logId}).$promise.then(function (result) {
                return result;
            }, function (error) {
                return 'error';
            });
        }

        function getScoresDrupalFormat(site, rankingId, year, subject) {
            return getScoresModel().getScoresDrupalFormat({site: site, rankingId: rankingId, year: year, subject: subject}).$promise.then(function (result) {
                return result;
            }, function (error) {
                return 'error';
            });
        }

        function sendPublishInfoToQSRankingsApp(filters) {
            return getScoresModel().sendPublishInfoToQSRankingsApp(filters).$promise.then(function (result) {
                return result;
            }, function (error) {
                return 'error';
            });
        }

        return svc;
    }

})(window.angular);
(function(angular){
    "use strict";

    angular.module('qsHub.rankings').filter('rankingScoresFilter', [rankingScoresFilter]);

    function rankingScoresFilter(){
        return function(options, site){
            if(options&&site){
                var filteredOptions = [];
                for(var i = 0; i < options.length; i++){
                    for(var x = 0; x < options[i].belongsTo.length; x++){
                        if(options[i].belongsTo[x]===site.key){
                            filteredOptions.push(options[i]);
                            break;
                        }
                    }
                }
                return filteredOptions;
            } else{
                return options;
            }
        };
    }

})(window.angular);
(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { factories: {} });

    App.factories.rankingsScores = function() {
        var isRequired = false,
            subjects = null,
            isDataChange = false,
            publishButtonClickd = false;

        return {
            setResetRightPanel: function (value) {
                isRequired = value;
            },
            
            isRightPanelResetRequired: function () {
               return isRequired ;
            },

            setSubjects: function (data) {
                subjects = data;
            },

            getSubjects: function () {
                return subjects;
            },

            setRankingsScoresDataChange: function (value) {
                isDataChange = value;
            },

            isRankingsScoresDataChange: function () {
                return isDataChange;
            },

            setPublishButtonClickd: function (value) {
                publishButtonClickd = value;
            },

            isPublishButtonClicked: function () {
                return publishButtonClickd;
            },
        };
    };

    angular
        .module('qsHub.rankings')
        .factory('RankingsScoresFactory', [
            App.factories.rankingsScores
        ]);

}(window.angular));

(function (angular) {

  'use strict';

  angular.module('qsHub').component('cookiesNotice', {
      templateUrl: '/scripts/shared/cookiesNotice/cookiesNoticeView.html',
      bindToController: true,
      controller: 'CookiesNoticeController'
  });

}(window.angular));




(function (angular) {
    "use strict";

    angular
        .module('qsHub')
        .controller('CookiesNoticeController', [
            '$cookies',
            'TimeService',
            Controller
        ]);

    function Controller(
        $cookies,
        TimeService
    ) {
        var controller = this,
            key = 'cookiesPolicy';

        controller.visible = !$cookies.get(key);
        controller.accept = function () {
            $cookies.put(key, true, {
                expires:TimeService.add(1, 'y').toDate()
            });
            controller.visible = false;
        };
    }

}(window.angular));

(function (angular) {
    "use strict";

    var publishLogsComponent = {
        bindings: {
            logs: "=",
            filters: "=",
            ranking: "=",
            buttonLabel: "<" 
        },
        bindToController: true,
        templateUrl: '/scripts/shared/publishLogs/publishLogsView.html',
        controller: 'publishLogsController'
    };

    angular.module('qsHub').component('publishLogs', publishLogsComponent);

}(window.angular));

(function (angular) {
    "use strict";

    var publishLogsController = function(
        $scope,
        constants,
        ClassificationsPublishService,
        RankingsScoresService,
        ModalService,
        $filter,
        NotifierFactory,
        uiGridConstants,
        UserFactory,
        RankingsScoresFactory,
        TimeService
    ) {
        var controller = this,
            publishLog = null,
            publishLogsId,
            gettingMoreDetails = false,
            classifications = 'classifications',
            rankingsClassifications = 'Classifications Rankings',
            rankingsScores = 'Rankings scores',
            publishLogsFailedToGet = 'Failed to get publish logs',
            feedFailedToGet = 'Failed to get feed',
            error = 'error',
            feedNotExist = 'Feed does not exist';

        controller.getMoreDetails = getMoreDetails;
        controller.getRowFeed = getRowFeed;
        controller.downloadParentFeed = downloadParentFeed;
        controller.isSameDate = isSameDate;

        controller.handlePublish = handlePublish;
        controller.isLatestLogSuccess = isLatestLogSuccess;

        function downloadParentFeed() {
            getParentLogId().then(function (logIds) {
                if (logIds !== 'error' && angular.isArray(logIds)) {
                    angular.forEach(logIds, function(logId) {
                        RankingsScoresService.getPublishLogFeed(logId).then(function (result) {
                            if (result !== 'error') {
                                startDownload(result, logId + '-feed');
                            } else {
                                NotifierFactory.show(
                                    error,
                                    feedNotExist,
                                    rankingsScores
                                );
                            }
                        });
                    });
                }
            });
        }

        function handlePublish(rankingId, year, site, isRankingsAppPublished) {
            if (isRankingsAppPublished) {
                return false;
            }
            RankingsScoresService.sendPublishInfoToQSRankingsApp({'limit' : 1, 'rankingId': rankingId, 'year': year, site: site}).then(function (result) {
                NotifierFactory.show(
                    result.success ? 'success' : 'error',
                    result.message ? result.message : 'There is some error',
                    'QS Rankings App'
                );
                RankingsScoresFactory.setPublishButtonClickd(true);
            });
        }

        function getParentLogId() {
            var parentFilters = {
                limit: 100000,
                'filter[publishLogId]': publishLog.id,
                'filter[type]': 'set',
                'filter[data.rankingIndicatorId]': 'overall',
                'sorting[modifiedAt]': 'desc'
            };
            return RankingsScoresService.getPublishLogDetails(parentFilters).then(function (result) {
                if (result !== error) {
                    if (result.results[0]) {
                        var publishSets = [], countMissingFeeds = 0;
                        angular.forEach(result.results, function (publishSet) {
                            if (!angular.equals(publishSet.feed, null)) {
                                publishSets.push(publishSet.id);
                            } else {
                                countMissingFeeds++;
                            }
                        });
                        if (countMissingFeeds > 0) {
                            NotifierFactory.show(
                                error,
                                countMissingFeeds === 1 ? feedNotExist : countMissingFeeds + ' set feeds does not exist',
                                rankingsScores
                            );
                        }

                        return publishSets;
                    } else {
                        NotifierFactory.show(
                            error,
                            publishLogsFailedToGet,
                            rankingsScores
                        );
                        return error;
                    }
                } else {
                    NotifierFactory.show(
                        error,
                        publishLogsFailedToGet,
                        rankingsScores
                    );
                    ModalService.close();

                    return error;
                }
            });
        }

        function getRowFeed(entity) {
            if (entity.status === 'pending' ||
                entity.status === 'progress' ||
                entity.status === 'cancelled' ||
                !entity.id ||
                !entity.feed
            ) {
                NotifierFactory.show(
                    error,
                    feedNotExist,
                    isClassificationsRanking() ? rankingsClassifications : rankingsScores
                );
            } else {
                var Service = isClassificationsRanking() ?
                        ClassificationsPublishService : RankingsScoresService;

                Service.getPublishLogFeed(entity.id).then(function (result) {
                    if (result !== error) {
                        startDownload(result, entity.id + '-feed');
                        NotifierFactory.show(
                            'success',
                            'Feed downloaded correctly',
                            isClassificationsRanking() ? rankingsClassifications : rankingsScores
                        );
                    } else {
                        NotifierFactory.show(
                            error,
                            feedFailedToGet,
                            isClassificationsRanking() ? rankingsClassifications : rankingsScores
                        );
                    }
                });
            }
        }

        function startDownload(result, fileName) {
            var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(result));
            var downloadAnchorNode = document.createElement('a');
            downloadAnchorNode.setAttribute("href", dataStr);
            downloadAnchorNode.setAttribute("download", fileName + ".json");
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
        }

        function isClassificationsRanking()
        {
            return controller.ranking === classifications;
        }

        function getPage(Service, filters, searchOptions, gridOptions, publishLogsId) {
            filters = filters || {};
            searchOptions = searchOptions || {};
            gridOptions = gridOptions || {};
            controller.loadingResults = true;
            Service.getPublishLogDetails(angular.merge({}, filters, searchOptions), publishLogsId).then(function (result) {
                if (result !== error) {
                    gridOptions.data = result.results;
                    gridOptions.totalItems = result.totalMatching;
                } else {
                    NotifierFactory.show(
                        error,
                        publishLogsFailedToGet,
                        isClassificationsRanking() ? rankingsClassifications : rankingsScores
                    );
                    ModalService.close();
                }
                controller.loadingResults = false;
            });
        }

        function getGridOptions() {
            var filters = {
                limit: constants.datagrid.rankings.defaultRowsNumber,
                page: 1
            };
            var statusCellTemplate = '/scripts/shared/publishLogs/cellTemplates/statusCellTemplate.html',
                publishedDateTemplate = '/scripts/shared/publishLogs/cellTemplates/publishedDateCellTemplate.html',
                selectFilterTemplate = '/scripts/shared/ui-grid/templates/selectFilterHeaderTemplate.html';

            var gridOptions = {
                appScopeProvider: controller,
                data: [],
                enableSorting: true,
                exporterMenuCsv: false,
                exporterMenuAllData: false,
                exporterMenuPdf: false,
                enableGridMenu: true,
                showGridFooter: false,
                enableFiltering: true,
                enableColumnResize: true,
                enableFullRowSelection: true,
                enableRowSelection: true,
                multiSelect: false,
                enableRowHeaderSelection: false,
                useExternalSorting: true,
                useExternalPagination: true,
                enablePaginationControls: true,
                useExternalFiltering: true,
                paginationPageSizes: [constants.datagrid.rankings.defaultRowsNumber, 50, 100],
                paginationPageSize: constants.datagrid.rankings.defaultRowsNumber,
                rowHeight: 40,
                rowTemplate: '/scripts/modules/messages/datagrid/rowTemplate.html',
                columnDefs: [
                    {
                        displayName: 'Name',
                        field: 'name',
                        enableFiltering: true,
                        enableSorting: false,
                        width: '60%',
                        filter: {
                            term: null,
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        displayName: 'Status',
                        field: 'status',
                        enableFiltering: true,
                        filter: {
                            column: 'status',
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: [
                                {value: 'cancelled', label: 'Cancelled'},
                                {value: 'pending', label: 'Pending'},
                                {value: 'failure', label: 'Failure'},
                                {value: 'success', label: 'Success'}
                            ],
                            term: null
                        },
                        enableSorting: false,
                        width: '15%',
                        headerCellTemplate: '/scripts/shared/publishLogs/cellTemplates/statusHeaderCellTemplate.html',
                        cellTemplate: statusCellTemplate,
                        filterHeaderTemplate: selectFilterTemplate
                    },
                    {
                        displayName: 'Published',
                        field: 'modifiedAt',
                        enableFiltering: false,
                        enableSorting: false,
                        cellTemplate: publishedDateTemplate,
                        width: '25%'
                    }
                ],
                onRegisterApi: function (gridApi) {
                    controller.gridApi = gridApi;
                    gridApi.pagination.on.paginationChanged($scope, function (newPage, limit) {
                        filters.page = newPage;
                        filters.limit = limit;
                    });
                }
            };

            return gridOptions;
        }

        function getMoreDetails(log) {
            var Service = isClassificationsRanking() ?
                ClassificationsPublishService : RankingsScoresService;
            publishLog = log;
            publishLogsId = publishLog.id;

            var modalOptions = {}, modalDefaults = {
                bindToController: true,
                controllerAs: '$ctrl',
                controller: function (publishLogsId) {
                    var controller = this;
                    var gridOptions = getGridOptions();
                    var filters = {
                        limit: constants.datagrid.rankings.defaultRowsNumber,
                        page: 1
                    }, searchOptions = isClassificationsRanking() ? {
                        'sorting[modifiedAt]': 'desc'
                    } : {
                        'filter[publishLogId]': publishLogsId,
                        'filter[type]': 'scores',
                        'filter[data.rankingIndicatorId]': 'overall',
                        'sorting[modifiedAt]': 'desc'
                    };
                    controller.headerText = 'Published by ' + log.createdByFullName + ' on ' + $filter('date')(log.createdAt, 'mediumDate');
                    gridOptions.paginationPageSize = constants.datagrid.rankings.defaultRowsNumber;
                    gridOptions.enablePaginationControls = true;
                    gridOptions.useExternalPagination = true;
                    controller.gridOptions = gridOptions;
                    controller.isClassification = isClassificationsRanking();
                    if (!isClassificationsRanking()) {
                        controller.downloadParentFeed = downloadParentFeed;
                    }
                    controller.gridOptions.onRegisterApi = function(gridApi){
                        gridApi.pagination.on.paginationChanged($scope, function (newPage, limit) {
                            filters.page = newPage;
                            filters.limit = limit;
                            getPage(Service, filters, searchOptions, gridOptions, publishLogsId);
                        });
                        gridApi.core.on.filterChanged($scope, function () {
                            var filterColumn;
                            angular.forEach(gridApi.grid.columns, function (column) {
                                filterColumn = column.filters[0].column ? column.filters[0].column : column.field;
                                if (typeof column.filters !== 'undefined' && column.filters[0].term !== 'undefined') {
                                    if (
                                        column.filters[0].type === uiGridConstants.filter.SELECT &&
                                        typeof column.filters[0].term === 'object' &&
                                        column.filters[0].term !== null &&
                                        typeof column.filters[0].term.value !== 'undefined'
                                    ) {
                                        column.filters[0].term = column.filters[0].term.value;
                                    }
                                    searchOptions['filter[' + filterColumn + ']'] = column.filters[0].term;
                                }
                            });
                            getPage(Service, filters, searchOptions, gridOptions, publishLogsId);
                        });
                    };
                    getPage(Service, filters, searchOptions, gridOptions, publishLogsId);
                },
                resolve: {
                    publishLogsId: function () {
                        return publishLogsId;
                    }
                },
                size: 'full-screen',
                backdrop: true,
                keyboard: true,
                modalFade: true,
                templateUrl: '/scripts/shared/publishLogs/moreInfoModal.html'
            };
            ModalService.show(modalDefaults, modalOptions);
        }

        function isSameDate (parent, child) {
            return TimeService.isSameDay(parent, child);
        }

        function isLatestLogSuccess() {
            return typeof controller.logs !== 'undefined' &&
            typeof controller.logs[0] !== 'undefined' &&
            typeof controller.logs[0].logs !== 'undefined' &&
            typeof controller.logs[0].logs[0] !== 'undefined' &&
            typeof controller.logs[0].logs[0].status !== 'undefined' &&
            controller.logs[0].logs[0].status === 'success' &&
            UserFactory.isGlobalAdmin();
        }
    };

    angular.module('qsHub').controller('publishLogsController', [
        '$scope',
        'constants',
        'ClassificationsPublishService',
        'RankingsScoresService',
        'ModalService',
        '$filter',
        'NotifierFactory',
        'uiGridConstants',
        'UserFactory',
        'RankingsScoresFactory',
        'TimeService',
        publishLogsController
    ]);
}(window.angular));

(function(angular){
    "use strict";

    var editSubjectComponent = {
        bindings: {
            subject: '=',
            year: '=',
            rankingId: '=',
            subjectOptions: '=',
            indicatorOptions: '=',
            deleteCallback: '&',
            updateCallback: '&',
            closeCallback: '&'
        },
        templateUrl: '/scripts/modules/rankings/components/rankingsScores/directives/editSubject/editSubjectView.html',
        bindToController: true,
        controller: 'Rankings.EditSubjectController'
    };

    angular.module('qsHub.rankings').component('editSubject', editSubjectComponent);

}(window.angular));
(function (angular) {
    "use strict";

    var editSubjectController = function (WatchService, $scope, RankingsScoresService, NotifierFactory, ModalService) {
        var controller = this;

        controller.save = saveSubject;
        controller.delete = deleteSubject;
        controller.fieldIsInvalid = fieldIsInvalid;
        controller.closeEditor = closeEditor;
        controller.hasTotalError = hasTotalError;

        function init() {

            controller.indicators = {};

            WatchService.create($scope, '$ctrl.subject', function (newVal) {
                if (isEmpty(newVal)) {
                    controller.submitted = false;
                }
                controller.newSubject = angular.copy(controller.subject);

                assignValuesToIndicators();
            }, true);

            WatchService.create($scope, '$ctrl.indicatorOptions', assignValuesToIndicators, true);
        }

        function assignValuesToIndicators() {
            if (controller.subject) {
                controller.indicatorOptions.forEach(function (indicator) {
                    if (controller.subject.id && controller.subject.subjectIndicators[indicator.indicatorId]) {
                        controller.indicators[indicator.indicatorId] = {value: controller.subject.subjectIndicators[indicator.indicatorId].value};
                    } else {
                        controller.indicators[indicator.indicatorId] = {value: null};
                    }
                });
            }
        }

        function saveSubject() {
            var payload = {
                subjectId: controller.newSubject.subjectId,
                rankingId: controller.rankingId,
                year: controller.year,
                subjectIndicators: controller.indicators
            };
            controller.submitted = true;
            if (controller.subjectForm.$valid && controller.total === 100) {
                if (controller.newSubject.id) {
                    RankingsScoresService.editScoreSubject(controller.newSubject.id, payload).then(function (result) {
                        if (result === 'error') {
                            NotifierFactory.show('error', 'Subject failed to save');
                        } else {
                            NotifierFactory.show('success', 'Subject updated successfully');
                            if (controller.updateCallback) {
                                controller.updateCallback();
                            }
                        }
                    });
                } else {

                    RankingsScoresService.createScoreSubject(payload).then(function (result) {
                        if (result === 'error') {
                            NotifierFactory.show('error', 'Subject failed to save');
                        } else {
                            NotifierFactory.show('success', 'Subject updated successfully');
                            if (controller.updateCallback) {
                                controller.updateCallback();
                            }
                        }
                    });
                }
            }

        }

        function deleteSubject() {

            var modalOptions = {
                handleNoClick: function () {
                    this.close();
                },
                handleYesClick: function () {

                    RankingsScoresService.deleteScoreSubject(controller.newSubject.id).then(function (result) {
                        if (result === 'error') {
                            NotifierFactory.show('error', 'Failed to delete');
                        } else {
                            NotifierFactory.show('success', 'Subject deleted');
                            if (controller.deleteCallback) {
                                controller.deleteCallback({id: controller.newSubject.id});
                            }
                            closeEditor();
                        }
                    });
                    this.close();
                },
                headerText: 'Subject Deletion',
                bodyText: 'Are you sure you want to delete this record?'
            }, modalDefaults = {
                backdrop: true,
                keyboard: true,
                modalFade: true,
                templateUrl: '/scripts/shared/areYouSure/deleteAreYouSure.html'
            };
            ModalService.show(modalDefaults, modalOptions);
        }

        function fieldIsInvalid(name) {
            if (controller.subjectForm[name]) {
                return controller.subjectForm[name].$invalid && (controller.subjectForm[name].$dirty || controller.submitted);
            }
        }

        function closeEditor() {
            controller.subject = null;
            if (controller.closeCallback) {
                controller.closeCallback();
            }
        }

        function hasTotalError() {
            return controller.total !== 100 && controller.submitted;
        }

        function isEmpty(obj) {
            for (var prop in obj) {
                if (obj.hasOwnProperty(prop)) {
                    return false;
                }
            }

            return JSON.stringify(obj) === JSON.stringify({});
        }

        init();
    };

    angular.module('qsHub.rankings').controller('Rankings.EditSubjectController', [
        'WatchService',
        '$scope',
        'RankingsScoresService',
        'NotifierFactory',
        'ModalService',
        editSubjectController
    ]);

}(window.angular));
(function (angular) {
    "use strict";

    var publishRankingsHistory = {
        bindings: {
            year: "=",
            rankingId: '=',
            site: '=',
            importCompleteCallback: '&'
        },
        bindToController: true,
        templateUrl: '/scripts/modules/rankings/components/rankingsScores/directives/publishRankingsHistory/publishRankingsHistory.html',
        controller: 'Rankings.PublishRankingsHistoryController'
    };

    angular.module('qsHub.rankings').component('publishRankingsHistory', publishRankingsHistory);

}(window.angular));
(function (angular) {
    "use strict";

    var publishRankingsHistory = function ($scope,
                                           constants,
                                           AuthenticationService,
                                           $timeout,
                                           NotifierFactory,
                                           ModalService,
                                           WatchService,
                                           RankingsScoresService,
                                           WebSocketsService,
                                           uiGridConstants,
                                           uiGridExporterService,
                                           uiGridExporterConstants,
                                           GridService,
                                           TimeService,
                                           RankingsScoresFactory) {
        var controller = this;

        var id = 0, data = [], publishLogsId, firstCallbackRun = true, firstRunId = null, rankingsCoreIds = {
            'bestStudentCities': [14],
            'wurFtMba': [18, 19, 20, 21, 22, 23],
            'wurMastersIn': [24, 25, 26]
        },
            rankingsScores = 'Rankings scores',
            success = 'success',
            error = 'error',
            feedNotExist = 'Feed does not exist',
            publishLogsFailedToGet = 'Failed to get publish logs',
            columnsChecked = false,
            gridApiDrupalPreviewInstance = null;

        controller.gridOptionsDrupalPreview = null;
        controller.back = back;
        controller.stageOne = stageOne;
        controller.validateAndSave = validateAndSave;
        controller.previewScores = previewScores;
        controller.publish = publish;
        controller.reimport = reimport;
        controller.cancelPublish = cancelPublish;
        controller.viewFailedRecords = viewFailedRecords;
        controller.publishFailedRecords = publishFailedRecords;
        controller.getRowFeed = getRowFeed;
        controller.resetSidePanel = resetSidePanel;
        controller.displayRepublish = false;
        controller.isPending = false;

        function init() {
            watchForFilterChanges();
            isPublishRunning();
            createUploadConfig();
            controller.dataGridOptions = getDataGrid();
            controller.dropZoneInstance = null;
            controller.forms = {};
            controller.showStepTwoButton = false;
            controller.stage = 1;
            controller.percentage = 0;
        }

        function resetSidePanel () {
            RankingsScoresFactory.setResetRightPanel(true);
        }

        function watchForFilterChanges() {
            var firstRun = true;
            WatchService.groupWatch($scope, ['$ctrl.rankingId', '$ctrl.year'], function (newVal, oldVal) {

                if (firstRun) {
                    firstRun = false;
                } else {
                    reset();
                }
            });
            WatchService.create($scope, RankingsScoresFactory.isRankingsScoresDataChange, function (newValue) {
                if (newValue) {
                    RankingsScoresFactory.setRankingsScoresDataChange(false);
                    reset();
                }
            });
        }

        function stageOne() {
            controller.showStepTwoButton = false;
            controller.stage = 1;
            controller.percentage = 0;
            controller.isPending = false;
            publishLogsId = null;
        }

        function reset() {
            controller.subjectsList = null;
            controller.subject = null;
            reimport();
            isPublishRunning().then(function (result) {
                if (!result) {
                    controller.stage = 1;
                    controller.publishStatus = 'pending';
                }
            });
        }

        function isPublishRunning() {
            return RankingsScoresService.isPublishRunning({'filter[status][]': ['pending', 'progress', 'failure', 'success', 'cancelled'], 'limit' : 1, 'filter[site]': controller.site, 'filter[rankingId]': '=' + controller.rankingId, 'filter[year]': '=' + controller.year, 'sorting[createdAt]': 'desc'}).then(function (result) {
                if (result !== error) {
                    if (result.results.length === 1) {
                        if (['failure'].indexOf(result.results[0].logs[0].status) !== -1) {
                            publishLogsId = result.results[0].logs[0].id;
                            controller.publishStatus = 'failure';
                            controller.percentage = 100;
                            controller.total = result.results[0].logs[0].scoresTotal;
                            controller.totalProgress = result.results[0].logs[0].scoresPublished;
                            controller.stage = 4;
                            subscribeToWebsockets();
                            return true;
                        } else if(['success', 'cancelled'].indexOf(result.results[0].logs[0].status) !== -1) {
                            publishLogsId = null;
                            controller.stage = 1;
                            return true;
                        }  else if(['pending'].indexOf(result.results[0].logs[0].status) !== -1) {
                            publishLogsId = result.results[0].logs[0].id;
                            subscribeToWebsockets();
                            controller.stage = 3;
                            controller.isPending = true;
                            return true;
                        } else {
                            publishLogsId = result.results[0].logs[0].id;
                            subscribeToWebsockets();
                            controller.stage = 4;
                            return true;
                        }
                    } else if (result.results.length > 1) {
                        NotifierFactory.show(
                            error,
                            'Publishing error, please contact administrator',
                            rankingsScores
                        );
                        return false;
                    } else if (result.results.length === 0) {
                        return false;
                    }
                }
            });
        }

        controller.handleUploadError = function (error) {
            controller.uploading = false;
            var errorMessage = error && error.xhr !== undefined && error.xhr.responseText ? JSON.parse(error.xhr.responseText) : false;
            errorMessage = errorMessage ? errorMessage.message : 'File upload failed. Only CSV are accepted';

            if (error.status === 'canceled') {
                errorMessage = 'File upload cancelled';
            }

            if (error.status === 'error') {
                errorMessage = 'File upload error';
            }

            NotifierFactory.show(
                error,
                errorMessage,
                rankingsScores + ' CSV file'
            );
            controller.dropZoneInstance.removeAllFiles();
        };

        function initDrupalPreviewDataGrid(data) {
            var cellTemplate = "/scripts/modules/rankings/components/rankingsScores/directives/publishRankingsHistory/cellTemplate.html";
            controller.gridOptionsDrupalPreview = {
                data: data,
                totalItems: data.length,
                appScopeProvider: controller,
                enableSorting: false,
                exporterMenuCsv: false,
                exporterMenuAllData: false,
                exporterMenuPdf: false,
                enableGridMenu: true,
                useExternalFiltering: false,
                showGridFooter: true,
                enableFiltering: true,
                enableColumnResize: true,
                enableFullRowSelection: false,
                enableRowSelection: false,
                multiSelect: false,
                enableRowHeaderSelection: false,
                paginationPageSizes: [
                    {label: '25', value: 25},
                    {label: '50', value: 50},
                ],
                paginationPageSize: 25,
                useExternalSorting: true,
                enableHorizontalScrollbar: 1,
                paginationTemplate: "/scripts/modules/rankings/components/rankingsScores/directives/publishRankingsHistory/paginationTemplate.html",
                rowTemplate: '/scripts/shared/ui-grid/templates/rowTemplate.html',
                gridMenuCustomItems: [
                    {
                        title: ' Export all data as csv',
                        action: exportAll,
                        order: 210
                    }
                ],
                onRegisterApi: function (gridApiDrupalPreview) {
                    gridApiDrupalPreviewInstance = gridApiDrupalPreview;
                    controller.gridOptionsDrupalPreview.columnDefs = this.columnDefs;
                    controller.gridApiDrupalPreview = gridApiDrupalPreview;
                    gridApiDrupalPreview.core.on.rowsRendered($scope, function () {
                        if (!columnsChecked) {
                            angular.forEach(controller.gridOptionsDrupalPreview.columnDefs, function (item) {
                                item.width = "*";
                                item.minWidth = "80";
                                if (item.name === 'displayRank') {
                                    item.width = "*";
                                    item.minWidth = "120";
                                }
                                if (item.name === 'rank') {
                                    item.width = "*";
                                    item.minWidth = "50";
                                }
                                if (item.name === 'overall') {
                                    item.width = "*";
                                    item.minWidth = "100";
                                }
                                if (item.name !== 'rank') {
                                    item.visible = true;
                                } else {
                                    item.visible = false;
                                }
                                if (item && item.name && (item.name.length <= 3 || (item.name.includes("rank") && (item.name !== "displayRank" && item.name !== "rank")))) {
                                    if (item.name.includes("rank")) {
                                        var str = item.name.split("_");
                                        item.displayName = str[0].toUpperCase()+" Rank";
                                    }
                                    else {
                                        item.displayName = item.name.toUpperCase();
                                    }
                                }
                                if (item.name === 'name' || item.name === 'coreId' || item.name === 'displayScore') {
                                    item.visible = false;
                                    item.enableHiding = false;                                    
                                }
                                item.cellTemplate = cellTemplate;
                            });
                            if (controller.gridOptionsDrupalPreview.columnDefs) {
                                controller.gridOptionsDrupalPreview.columnDefs.unshift({
                                    field: 'name',
                                    displayName: 'Name',
                                    width: "*",
                                    minWidth: "400",
                                    visible: true,
                                    cellTemplate : "/scripts/modules/rankings/components/rankingsScores/directives/publishRankingsHistory/nameColumnTemplate.html"
                                });
                                controller.gridOptionsDrupalPreview.columnDefs.unshift({
                                    field: 'coreId',
                                    displayName: 'Core ID',
                                    width: "*",
                                    minWidth: "120",
                                    visible: true,
                                });
                                controller.gridOptionsDrupalPreview.columnDefs.push({
                                    field: 'displayScore',
                                    displayName: 'Display Score',
                                    width: "*",
                                    minWidth: "120",
                                    visible: true,
                                });
                            }
                            gridApiDrupalPreview.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
                            columnsChecked = true;
                        }
                    });
                }
            };
            $timeout(function () {
                controller.isRowsRendered = true;
            });
        }

        function exportAll() {
                if (typeof controller.gridOptionsDrupalPreview === 'undefined' || 
                    typeof controller.gridOptionsDrupalPreview.data === 'undefined' ||
                    !controller.gridOptionsDrupalPreview.data.length) {
                    return false;
                }
                var gridApi = gridApiDrupalPreviewInstance,
                    gridOptions = controller.gridOptionsDrupalPreview,
                    exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                        gridApi.grid,
                        uiGridExporterConstants.VISIBLE
                    );

                var exportData = GridService.getCsvData(gridApi.grid.columns, controller.gridOptionsDrupalPreview.data);
                var csvContent = uiGridExporterService.formatAsCsv(
                    exportColumnHeaders,
                    exportData,
                    gridOptions.exporterCsvColumnSeparator
                );
                uiGridExporterService.downloadFile(
                    'ranking-data-full' + TimeService.now() + '.csv',
                    csvContent,
                    gridOptions.exporterOlderExcelCompatibility
                );
        }

        controller.handleFileUploaded = function (file, response) {
            if (!response || !response.previewData || !response.insertedCount) {
                controller.handleUploadError();
            } else {
                controller.fetchInProgress = true;
                data = angular.copy(assignId(response.previewData));
                controller.dataGridOptions = getDataGrid();
                controller.dataGridOptions.data = assignId(response.previewData);
                controller.stage++;
                controller.dropZoneInstance.removeAllFiles();
                NotifierFactory.show(
                    success,
                    'File successfully uploaded',
                    rankingsScores
                );
                controller.subjectsList = controller.rankingId === 4 ? RankingsScoresFactory.getSubjects() : [];
                controller.subject = controller.subjectsList.length ? controller.subjectsList[0] : {acronym : 'overall'};
                controller.gridOptionsDrupalPreview = null;
                getScoresDrupalFormat();
            }
        };

        function getScoresDrupalFormat() {
            controller.noResults = false;
            RankingsScoresService.getScoresDrupalFormat(controller.site, controller.rankingId, controller.year,controller.subject.acronym.toLowerCase()).then(function (data) {
                controller.fetchInProgress = false;
                if (data && data !== 'error' && data.results.length > 0) {
                    columnsChecked = false;
                    controller.gridOptionsDrupalPreview = null;
                    $timeout(function () { 
                        initDrupalPreviewDataGrid(data.results);
                        controller.gridOptionsDrupalPreview.paginationPageSizes.push({label: 'ALL', value: data.results.length});
                    });
                    
                } else {
                    NotifierFactory.show(
                        'error',
                        'Failed to preview rankings data in frontend format',
                        'Rankings Data Preview'
                    );
                    controller.noResults = true;
                }
            });
        }

        controller.handleSubjectChange = function () {
            controller.noResults = true;
            controller.fetchInProgress = true;
            if (controller.gridApiDrupalPreview && controller.gridApiDrupalPreview.pagination.getPage() > 1) {
                controller.gridApiDrupalPreview.pagination.seek(1);
            }
            getScoresDrupalFormat();
        };

        function previewScores() {

            var modalOptions = {}, modalDefaults = {
                bindToController: true,
                controllerAs: '$ctrl',
                controller: function (publishLogsId, RankingsScoresService) {
                    var controller = this;
                    var gridOptions = getDataGrid();
                    var filters = {
                        limit: constants.datagrid.rankings.defaultRowsNumber,
                        page: 1
                    };
                    controller.headerText = 'Publish Data Preview';
                    gridOptions.paginationPageSize = constants.datagrid.rankings.defaultRowsNumber;
                    gridOptions.enablePaginationControls = true;
                    gridOptions.useExternalPagination = true;
                    controller.gridOptions = gridOptions;
                    controller.gridOptions.onRegisterApi = function(gridApi) {
                        gridApi.pagination.on.paginationChanged($scope, function (newPage, limit) {
                            filters.page = newPage;
                            filters.limit = limit;
                            getPage();
                        });
                    };

                    getPage();

                    function getPage() {
                        controller.loadingResults = true;
                        RankingsScoresService.getPublishPreview(filters).then(function (result) {
                            if (result !== error) {
                                gridOptions.data = result.results;
                                gridOptions.totalItems = result.totalMatching;
                            } else {
                                NotifierFactory.show(
                                    error,
                                    publishLogsFailedToGet,
                                    rankingsScores
                                );
                            }
                            controller.loadingResults = false;
                        });
                    }
                },
                resolve: {
                    publishLogsId: function () {
                        return publishLogsId;
                    }
                },
                size: 'full-screen',
                backdrop: true,
                keyboard: true,
                modalFade: true,
                templateUrl: '/scripts/modules/rankings/components/rankingsScores/directives/publishRankingsHistory/previewDataModal.html'
            };
            ModalService.show(modalDefaults, modalOptions);

        }

        function back() {
            if (controller.stage > 1) {
                controller.stage--;
                if (controller.stage === 1) {
                    publishLogsId = null;
                    controller.gridOptionsDrupalPreview = null;
                    if (controller.gridApiDrupalPreview && controller.gridApiDrupalPreview.pagination.getPage() > 1) {
                        controller.gridApiDrupalPreview.pagination.seek(1);
                    }
                }
            }
        }

        function assignId(array) {
            array.forEach(function (item) {
                item.id = id++;
            });
            return array;
        }

        function publish() {
            controller.publishStatus = 'pending';
            controller.publishing = true;
            var filters = {rankingId: controller.rankingId, year: controller.year, publishId: publishLogsId};
            controller.stage++;
            RankingsScoresService.publishImportData(filters).then(function (result) {
                if (result === error) {
                    NotifierFactory.show(
                        error,
                        'Data publish failed',
                        rankingsScores
                    );
                    controller.percentage = null;
                    controller.publishStatus = 'failure';
                }
                controller.publishing = false;
            });
        }

        function subscribeToWebsockets() {
            firstCallbackRun = true;
            WebSocketsService.subscribe(publishLogsId, 'PublishStatusScores' + publishLogsId, function (response) {
                sectionPublishStatusCallback(response);
            });
        }

        function sectionPublishStatusCallback(response) {
            if (firstCallbackRun) {
                controller.importCompleteCallback();
                firstCallbackRun = false;
                firstRunId = response.id;
            }
            if (controller.publishStatus !== 'cancelled') {
                if (angular.equals(response.id, firstRunId)) {
                    controller.displayRepublish = false;
                } else {
                    controller.displayRepublish = true;
                }
                controller.total = response.total;
                controller.failed = response.failed;
                controller.published = response.published;
                controller.publishStatus = 'progress';

                calculatePercentage(controller.total, controller.failed + controller.published);

                controller.totalProgress = controller.failed + controller.published;

                if (controller.total === controller.failed + controller.published) {
                    if (controller.failed !== 0) {
                        controller.publishStatus = 'failure';
                    } else {
                        controller.publishStatus = 'success';
                    }
                    controller.importCompleteCallback();
                }
            }
        }

        function calculatePercentage(total, completed) {
            controller.percentage = Math.floor((completed / total) * 100);
        }

        controller.handleCheckFile = function () {
            controller.uploading = true;
            controller.dropZoneInstance.processQueue();
        };

        controller.handleFileAdded = function (file) {
            controller.currentFile = file;
            $scope.$apply(function () {
                controller.showStepTwoButton = true;
                controller.uploading = false;
                if (controller.dropZoneInstance.files.length > 1) {
                    controller.dropZoneInstance.removeAllFiles();
                    controller.dropZoneInstance.addFile(file);
                }
            });
        };

        controller.handleFileRemoved = function () {
            $timeout(function () {
                controller.uploading = false;
                if (controller.dropZoneInstance.files.length === 0) {
                    controller.showStepTwoButton = false;
                }
            });
        };

        function reimport() {
            controller.stage = 1;
            controller.publishStatus = 'pending';
            controller.percentage = 0;
            controller.totalProgress = 0;
            controller.total = 0;
            publishLogsId = null;
        }

        function validateAndSave() {
            if (controller.noResults) {
                NotifierFactory.show(
                    'error',
                    'Could not preview data, please go back and upload the file again',
                    'Rankings Data Preview'
                );

                return false;
            }
            var filters = {rankingId: controller.rankingId, year: controller.year, site: controller.site};
            if (!publishLogsId) {
                controller.loading = true;
                RankingsScoresService.savePublishData(filters).then(function (result) {
                    if (result !== error && result.hasOwnProperty('publishLogsId')) {
                        NotifierFactory.show(
                            success,
                            'Data Saved',
                            rankingsScores
                        );
                        publishLogsId = result.publishLogsId;
                        controller.importCompleteCallback();
                        subscribeToWebsockets();
                        controller.stage++;
                    } else {
                        NotifierFactory.show(
                            error,
                            'Failed to save data',
                            rankingsScores
                        );
                    }
                    controller.loading = false;
                });
            } else {
                controller.stage++;
            }
        }

        function getFailedPage(filters, searchOptions, gridOptions) {
            filters = filters || {};
            searchOptions = searchOptions || {};
            gridOptions = gridOptions || {};
            controller.loadingResults = true;
            RankingsScoresService.getPublishLogDetails(angular.merge({}, filters, searchOptions)).then(function (result) {
                if (result !== error) {
                    gridOptions.data = result.results;
                    gridOptions.totalItems = result.totalMatching;
                } else {
                    NotifierFactory.show(
                        error,
                        publishLogsFailedToGet,
                        rankingsScores
                    );
                    ModalService.close();
                }
                controller.loadingFailedRecords = false;
                controller.loadingResults = false;
            });
        }

        function viewFailedRecords() {
            controller.loadingFailedRecords = true;
            var modalOptions = {}, modalDefaults = {
                bindToController: true,
                controllerAs: '$ctrl',
                controller: function (publishLogsId) {
                    var controller = this;
                    var gridOptions = getFailedImportDataGrid();
                    var filters = {
                        limit: constants.datagrid.rankings.defaultRowsNumber,
                        page: 1
                    }, searchOptions = {
                        'filter[publishLogId]': publishLogsId,
                        'filter[type]': 'scores',
                        'filter[status]': 'failure',
                        'filter[data.rankingIndicatorId]': 'overall'
                    };
                    controller.headerText = 'Failed Import Results';
                    gridOptions.paginationPageSize = constants.datagrid.rankings.defaultRowsNumber;
                    gridOptions.enablePaginationControls = true;
                    gridOptions.useExternalPagination = true;
                    controller.gridOptions = gridOptions;
                    controller.downloadParentFeed = downloadParentFeed;
                    controller.gridOptions.onRegisterApi = function(gridApi) {
                        gridApi.pagination.on.paginationChanged($scope, function (newPage, limit) {
                            filters.page = newPage;
                            filters.limit = limit;
                            getFailedPage(filters, searchOptions, gridOptions);
                        });
                        gridApi.core.on.filterChanged($scope, function () {
                            var filterColumn;
                            angular.forEach(gridApi.grid.columns, function (column) {
                                filterColumn = column.filters[0].column ? column.filters[0].column : column.field;
                                if (typeof column.filters !== 'undefined' && column.filters[0].term !== 'undefined') {
                                    if (
                                        column.filters[0].type === uiGridConstants.filter.SELECT &&
                                        typeof column.filters[0].term === 'object' &&
                                        column.filters[0].term !== null &&
                                        typeof column.filters[0].term.value !== 'undefined'
                                    ) {
                                        column.filters[0].term = column.filters[0].term.value;
                                    }
                                    searchOptions['filter[' + filterColumn + ']'] = column.filters[0].term;
                                }
                            });
                            getFailedPage(filters, searchOptions, gridOptions);
                        });
                    };
                    getFailedPage(filters, searchOptions, gridOptions);
                },
                resolve: {
                    publishLogsId: function () {
                        return publishLogsId;
                    }
                },
                size: 'full-screen',
                backdrop: true,
                keyboard: true,
                modalFade: true,
                templateUrl: '/scripts/shared/publishLogs/moreInfoModal.html'
            };
            ModalService.show(modalDefaults, modalOptions);
        }

        function publishFailedRecords() {
            controller.publishStatus = 'pending';
            controller.percentage = 0;
            controller.totalProgress = 0;
            controller.publishing = true;
            var filters = {rankingId: controller.rankingId, year: controller.year, publishId: publishLogsId, rePublish: true};
            RankingsScoresService.publishImportData(filters).then(function (result) {
                if (result === error) {
                    NotifierFactory.show(
                        error,
                        'Data publish failed',
                        rankingsScores
                    );
                    controller.percentage = null;
                    controller.publishStatus = 'failure';
                }
                controller.publishing = false;
            });
        }

        function cancelPublish() {
            var modalOptions = {
                headerText: 'Cancel publish',
                bodyText: 'Are you sure you want to cancel the publish?',
                actionButtonClass: 'danger',
                closeButtonText: 'No',
                actionButtonText: 'Yes'
            }, modalDefaults = {
                backdrop: true,
                keyboard: true,
                modalFade: true
            };
            ModalService.show(modalDefaults, modalOptions).then(continueCancel);

            function continueCancel() {
                controller.cancelling = true;
                RankingsScoresService.cancelPublish(publishLogsId).then(function (result) {
                    if (result.result === success) {
                        controller.percent = null;
                        controller.publishStatus = 'cancelled';
                        NotifierFactory.show(
                            success,
                            'Publish Cancelled',
                            rankingsScores
                        );
                    } else {
                        NotifierFactory.show(
                            error,
                            'Cancel Failed',
                            rankingsScores
                        );
                    }
                    controller.cancelling = false;
                    controller.importCompleteCallback();
                });
            }

        }

        function getUploadUrl() {
            return constants.api.rankings.url + '/v1/rankings-scores/upload/' + controller.site + '/' + controller.rankingId + '/'  + controller.year;
        }

        function createUploadConfig() {
            controller.uploadConfig = {
                // http://www.dropzonejs.com/#configuration-options
                dropzone: {
                    url: getUploadUrl,
                    maxFiles: 1,
                    // maxFilesize: controller.maxSize,
                    uploadMultiple: false,
                    parallelUploads: 1,
                    autoProcessQueue: false,
                    addRemoveLinks: true,
                    createImageThumbnails: false,
                    acceptedFiles: '.csv',
                    headers: AuthenticationService.getAuthorizationHeader(),
                    thumbnailWidth: 200,
                    thumbnailHeight: 200,
                    paramName: "file",
                    clickable: '.upload-file'
                },
                // http://www.dropzonejs.com/#event-list
                eventHandlers: {
                    success: controller.handleFileUploaded,
                    addedfile: controller.handleFileAdded,
                    removedfile: controller.handleFileRemoved,
                    error: controller.handleUploadError
                }
            };
        }

        function getRowFeed(entity) {
            if (!entity.feed) {
                NotifierFactory.show(
                    error,
                    feedNotExist,
                    rankingsScores
                );
            } else {
                startDownload(entity.feed, entity.id + '-feed');
            }
        }

        function getFilters() {
            return {
                limit: constants.datagrid.rankings.defaultRowsNumber,
                'filter[publishLogId]': publishLogsId,
                'filter[type]': 'scores',
                'sorting[createdAt]': 'desc'
            };
        }

        function downloadParentFeed() {
            getParentLogId().then(function (logIds) {
                if (logIds !== error && angular.isArray(logIds)) {
                    angular.forEach(logIds, function(logId) {
                        RankingsScoresService.getPublishLogFeed(logId).then(function (result) {
                            if (result !== error) {
                                startDownload(result, logId + '-feed');
                            } else {
                                NotifierFactory.show(
                                    error,
                                    feedNotExist,
                                    rankingsScores
                                );
                            }
                        });
                    });
                }
            });
        }

        function getParentLogId() {
            var parentFilters = angular.copy(getFilters());
            parentFilters['filter[type]'] = 'set';
            parentFilters.limit = 100000;

            return RankingsScoresService.getPublishLogDetails(parentFilters).then(function (result) {
                if (result !== error) {
                    if (result.results[0]) {
                        var publishSets = [], countMissingFeeds = 0;
                        angular.forEach(result.results, function (publishSet) {
                            if (!angular.equals(publishSet.feed, null)) {
                                publishSets.push(publishSet.id);
                            } else {
                                countMissingFeeds++;
                            }
                        });
                        if (countMissingFeeds > 0) {
                            NotifierFactory.show(
                                error,
                                countMissingFeeds === 1 ? feedNotExist : countMissingFeeds + ' set feeds does not exist',
                                rankingsScores
                            );
                        }

                        return publishSets;
                    } else {
                        NotifierFactory.show(
                            error,
                            publishLogsFailedToGet,
                            rankingsScores
                        );
                        return error;
                    }

                } else {
                    NotifierFactory.show(
                        error,
                        publishLogsFailedToGet,
                        rankingsScores
                    );
                    ModalService.close();

                    return error;
                }
            });
        }

        function startDownload(result, fileName) {
            var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(result));
            var downloadAnchorNode = document.createElement('a');
            downloadAnchorNode.setAttribute("href", dataStr);
            downloadAnchorNode.setAttribute("download", fileName + ".json");
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
        }

        function getFailedImportDataGrid() {
            var filters = {
                limit: constants.datagrid.rankings.defaultRowsNumber,
                page: 1
            };
            var statusCellTemplate = '/scripts/shared/publishLogs/cellTemplates/statusCellTemplate.html',
                publishedDateTemplate = '/scripts/shared/publishLogs/cellTemplates/publishedDateCellTemplate.html',
                selectFilterTemplate = '/scripts/shared/ui-grid/templates/selectFilterHeaderTemplate.html';

            var gridOptions = {
                appScopeProvider: controller,
                data: [],
                enableSorting: true,
                exporterMenuCsv: false,
                exporterMenuAllData: false,
                exporterMenuPdf: false,
                enableGridMenu: true,
                showGridFooter: false,
                enableFiltering: true,
                enableColumnResize: true,
                enableFullRowSelection: true,
                enableRowSelection: true,
                multiSelect: false,
                enableRowHeaderSelection: false,
                useExternalSorting: true,
                useExternalPagination: true,
                enablePaginationControls: true,
                useExternalFiltering: true,
                paginationPageSizes: [constants.datagrid.rankings.defaultRowsNumber, 50, 100],
                paginationPageSize: constants.datagrid.rankings.defaultRowsNumber,
                rowHeight: 40,
                rowTemplate: '/scripts/modules/messages/datagrid/rowTemplate.html',
                columnDefs: [
                    {
                        displayName: 'Name',
                        field: 'name',
                        enableFiltering: true,
                        enableSorting: false,
                        width: '60%',
                        filter: {
                            term: null,
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        displayName: 'Status',
                        field: 'status',
                        enableFiltering: true,
                        filter: {
                            column: 'status',
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: [
                                {value: 'cancelled', label: 'Cancelled'},
                                {value: 'pending', label: 'Pending'},
                                {value: 'failure', label: 'Failure'},
                                {value: 'success', label: 'Success'}
                            ],
                            term: {value: 'failure', label: 'Failure'}
                        },
                        enableSorting: false,
                        width: '15%',
                        cellTemplate: statusCellTemplate,
                        filterHeaderTemplate: selectFilterTemplate
                    },
                    {
                        displayName: 'Published',
                        field: 'modifiedAt',
                        enableFiltering: false,
                        enableSorting: false,
                        cellTemplate: publishedDateTemplate,
                        width: '25%'
                    }
                ],
                onRegisterApi: function (gridApi) {
                    controller.gridApi = gridApi;
                    gridApi.pagination.on.paginationChanged($scope, function (newPage, limit) {
                        filters.page = newPage;
                        filters.limit = limit;
                    });
                }
            };

            return gridOptions;
        }

        function getColumnDefinitions() {
            var defaultColumns = [
                {
                    displayName: 'Institution ID',
                    field: 'institutionCoreId',
                    enableFiltering: false,
                    enableSorting: false,
                    width: 120
                },
                {
                    displayName: 'Profile',
                    field: 'profile',
                    enableFiltering: false,
                    enableSorting: false,
                    width: 100
                },
                {
                    displayName: 'School Name',
                    field: 'institutionName',
                    enableFiltering: false,
                    enableSorting: false,
                    width: 150
                },
                {
                    displayName: 'Faculty ID',
                    field: 'rankingsFacultyId',
                    enableFiltering: false,
                    enableSorting: false,
                    width: 120
                },
                {
                    displayName: 'Indicator ID',
                    field: 'rankingIndicatorId',
                    enableFiltering: false,
                    enableSorting: false,
                    width: 120
                },
                {
                    displayName: 'Rank',
                    field: 'rank',
                    enableFiltering: false,
                    enableSorting: false,
                    width: 100
                },
                {
                    displayName: 'Score',
                    field: 'score',
                    enableFiltering: false,
                    enableSorting: false,
                    width: 100
                },
                {
                    displayName: 'Display Rank',
                    field: 'displayRank',
                    enableFiltering: false,
                    enableSorting: false,
                    width: 100
                },
                {
                    displayName: 'Display Score',
                    field: 'displayScore',
                    enableFiltering: false,
                    enableSorting: false,
                    width: 100
                },
                {
                    displayName: 'Country Overwrite',
                    field: 'countryOverwrite',
                    enableFiltering: false,
                    enableSorting: false,
                    width: 120
                }
            ];

            // 1. Rename Institution ID column to City Id for given rankings core ids
            // 2. Rename School Name column to City for given rankings core ids
            // 3. Hide profile column for given rankings core ids
            if (rankingsCoreIds.bestStudentCities.concat(rankingsCoreIds.wurFtMba, rankingsCoreIds.wurMastersIn)
                    .indexOf(controller.rankingId) !== -1) {
                // 1.
                renameColumn(defaultColumns, 'field', 'institutionCoreId', 'displayName', 'City ID');
                // 2.
                renameColumn(defaultColumns, 'field', 'institutionName', 'displayName', 'City');
                // 3.
                removeColumn(defaultColumns, 'field', 'profile');
            }

            // 1. Rename Institution ID column to Program Core Id for given rankings core ids
            // 2. Add columns Location and Parent Core ID for given rankings core ids
            if (rankingsCoreIds.wurFtMba.concat(rankingsCoreIds.wurMastersIn)
                    .indexOf(controller.rankingId) !== -1) {
                // 1.
                renameColumn(defaultColumns, 'field', 'institutionCoreId', 'displayName', 'Program Core ID');
                // 2.
                defaultColumns.push({
                    displayName: 'Location',
                    field: 'location',
                    enableFiltering: false,
                    enableSorting: false,
                    width: 100
                });
                defaultColumns.push({
                    displayName: 'Parent Core ID',
                    field: 'parentCoreId',
                    enableFiltering: false,
                    enableSorting: false,
                    width: 100
                });
            }

            // recalculate widths
            recalculateWidths(defaultColumns);

            return defaultColumns;
        }

        /**
         * Remove column by displayName value.
         *
         * @param defaultColumns
         * @param displayName
         * @param displayValue
         * @returns {*}
         */
        function removeColumn(defaultColumns, displayName, displayValue) {
            angular.forEach(defaultColumns, function (column, key) {
                if (column[displayName] === displayValue) {
                    defaultColumns.splice(key, 1);
                }
            });

            return defaultColumns;
        }

        /**
         * Rename column renameKey to renameValue, searching the column by displayName and displayValue.
         *
         * @param defaultColumns
         * @param displayName
         * @param displayValue
         * @param renameKey
         * @param renameValue
         * @returns {*}
         */
        function renameColumn(defaultColumns, displayName, displayValue, renameKey, renameValue) {
            angular.forEach(defaultColumns, function (column, key) {
                if (column[displayName] === displayValue) {
                    defaultColumns[key][renameKey] = renameValue;
                }
            });

            return defaultColumns;
        }

        /**
         * Recalculate columns widths to % based the column count.
         *
         * @param defaultColumns
         */
        function recalculateWidths(defaultColumns)
        {
            angular.forEach(defaultColumns, function (column, key) {
                defaultColumns[key].width = 100/defaultColumns.length + '%';
            });
        }

        function getDataGrid() {
            var dataGridOptions = {
                data: [],
                enableSorting: true,
                exporterMenuCsv: false,
                exporterMenuAllData: false,
                exporterMenuPdf: false,
                useExternalFiltering: true,
                enableGridMenu: true,
                showGridFooter: false,
                enableFiltering: false,
                enableColumnResize: true,
                enableFullRowSelection: true,
                enableRowSelection: false,
                multiSelect: false,
                enableRowHeaderSelection: false,
                useExternalSorting: true,
                enablePaginationControls: false,
                paginationPageSize: constants.datagrid.rankingsPreview.defaultRowsNumber,
                paginationPageSizes: [constants.datagrid.rankingsPreview.defaultRowsNumber, 10, 25, 50, 100],
                rowTemplate: '/scripts/modules/messages/datagrid/rowTemplate.html',
                columnDefs: getColumnDefinitions(),
                onRegisterApi: function (gridApi) {
                    controller.gridApi = gridApi;
                }
            };

            return angular.copy(dataGridOptions);
        }

        init();
    };

    angular.module('qsHub.rankings').controller('Rankings.PublishRankingsHistoryController', [
        '$scope',
        'constants',
        'AuthenticationService',
        '$timeout',
        'NotifierFactory',
        'ModalService',
        'WatchService',
        'RankingsScoresService',
        'WebSocketsService',
        'uiGridConstants',
        'uiGridExporterService',
        'uiGridExporterConstants',
        'UiGridService',
        'TimeService',
        'RankingsScoresFactory',
        publishRankingsHistory]);

}(window.angular));

(function (angular) {
    "use strict";
    var totalIndicatorValues = {
        bindings: {
            indicators: '=',
            total: '='
        },
        template: '<input type="text" ng-model="$ctrl.total" disabled class="form-control"></input>',
        bindToController: true,
        controller: function (WatchService, $scope) {
            var controller = this;

            WatchService.create($scope, '$ctrl.indicators', function () {
                var total = 0;
                for (var property in controller.indicators) {
                    if (controller.indicators.hasOwnProperty(property)) {
                        if (!isNaN(controller.indicators[property].value)) {
                            total += controller.indicators[property].value;
                        }
                    }
                }
                controller.total = total;
            }, true);
        }
    };

    angular.module('qsHub.rankings').component('totalIndicatorValues', totalIndicatorValues);
}(window.angular));
(function (angular) {
    "use strict";

    angular.module('qsHub.rankings').controller('Rankings.EditScoresIndicatorController', [
        'ModalService',
        'RankingsScoresService',
        'WatchService',
        '$scope',
        'NotifierFactory',
        Controller
    ]);

    function Controller(
        ModalService,
        RankingsScoresService,
        WatchService,
        $scope,
        NotifierFactory
    ) {
        var controller = this;

        controller.deleteScore = deleteScore;
        controller.save = save;
        controller.closeEditor = closeEditor;
        controller.fieldIsInvalid = fieldIsInvalid;
        controller.valueIsRequired = valueIsRequired;

        controller.addFormVisible = false;

        controller.toggleAddForm =  function () {
            controller.addFormVisible = !controller.addFormVisible;
        };

        function init() {
            controller.submitted = false;
            controller.scoreData = {
                indicatorId: null,
                rankingId: null,
                description: null,
                value: null,
                year: null,
            };

            WatchService.create($scope, '$ctrl.scoreIndicator', function () {
                if (controller.scoreIndicator) {
                    controller.newScoreForm.$setPristine();
                    controller.submitted = false;
                    controller.scoreData = angular.copy(controller.scoreIndicator);
                    controller.scoreData.year = angular.copy(controller.year);
                    controller.scoreData.rankingId = angular.copy(controller.rankingId);
                    delete controller.scoreData.deleted;
                    delete controller.scoreData.indicatorName;
                }
            }, true);
        }

        function fieldIsInvalid(name) {
            if (controller.newScoreForm[name]) {
                return controller.newScoreForm[name].$invalid && (controller.newScoreForm[name].$dirty || controller.submitted);
            }
        }

        function isAcronymUnique(acronym) {
            var total = controller.indicatorOptions.length,
                i = 0;
            for (i; i < total; i++) {
                if (controller.indicatorOptions[i].acronym === acronym) {
                    return false;
                }
            }
            return true;
        }

        function save() {
            controller.submitted = true;
            controller.newScoreForm.acronym.$setValidity('unique', true);

            if (controller.newScoreForm.$valid) {
                // check if new indicator acronym is unique
                if (controller.addFormVisible && !isAcronymUnique(controller.scoreData.acronym)) {
                    controller.newScoreForm.acronym.$setValidity('unique', false);
                    return false;
                }
                controller.saving = true;
                if (controller.scoreData.id) {
                    RankingsScoresService.editScore(controller.scoreData.id, controller.scoreData).then(function (result) {
                        if (result !== 'error') {
                            NotifierFactory.show('success', 'Updated Successfully');
                            if (controller.updateCallback) {
                                controller.updateCallback();
                            }
                            controller.submitted = false;
                            controller.newScoreForm.$setPristine();
                        } else {
                            NotifierFactory.show('error', 'Failed to update');
                        }
                        controller.saving = false;
                    });
                } else {
                    RankingsScoresService.createScore(controller.scoreData).then(function (result) {
                        if (result !== 'error') {
                            controller.addFormVisible = false;
                            NotifierFactory.show('success', 'Created Successfully');
                            if (controller.updateCallback) {
                                controller.updateCallback({id:result.insertedId ? result.insertedId : result.modifiedId});
                            }
                            controller.scoreData.id = result.insertedId ? result.insertedId : result.modifiedId;
                        } else {
                            NotifierFactory.show('error', 'Failed to save record');
                        }
                        controller.saving = false;
                    });
                }
            }

        }

        function deleteScore() {
            var modalOptions = {
                handleNoClick: function () {
                    this.close();
                },
                handleYesClick: function () {
                    RankingsScoresService.deleteScore(controller.scoreData.id).then(function (result) {
                        if (result !== 'error') {
                            if (controller.deleteCallback) {
                                controller.deleteCallback({id: controller.scoreData.id});
                            }
                            NotifierFactory.show('success', 'Deleted Successfully');
                            closeEditor();
                        } else{
                            NotifierFactory.show('error', 'Failed to delete record');
                        }
                    });
                    this.close();
                },
                headerText: 'Indicator Deletion',
                bodyText: 'Are you sure you want to delete this record?'
            }, modalDefaults = {
                backdrop: true,
                keyboard: true,
                modalFade: true,
                templateUrl: '/scripts/shared/areYouSure/deleteAreYouSure.html'
            };
            ModalService.show(modalDefaults, modalOptions);
        }

        function closeEditor() {
            controller.scoreIndicator = null;
            if(controller.closeCallback){
                controller.closeCallback();
            }
        }

        function valueIsRequired(){
            return controller.newScoreForm.value.$error.required && controller.submitted;
        }

        controller.$onInit = init;
    }
})(window.angular);

(function (angular) {
    "use strict";

    var editScoresIndicator = {

        bindings: {
            hasWeightings: '=',
            scoreIndicator: "=",
            indicatorOptions: '=',
            rankingId: '=',
            year: '=',
            deleteCallback: '&',
            updateCallback: '&',
            closeCallback: '&'
        },
        bindToController: true,
        templateUrl: '/scripts/modules/rankings/components/rankingsScores/directives/editScoresIndicator/editScoresIndicatorView.html',
        controller: 'Rankings.EditScoresIndicatorController'
    };

    angular.module('qsHub.rankings').component('editScoresIndicator', editScoresIndicator);

})(window.angular);
(function(angular) {
    "use strict";

    angular
        .module('qsHub.rankings')
        .controller('Rankings.StatisticsDashboardController', [
            'StatisticsDashboardFactory',
            'InstitutionsListService',
            'RankingInfoFormService',
            'RankingsService',
            StatisticsDashboard
        ]);

    function StatisticsDashboard(
        StatisticsDashboardFactory,
        InstitutionsListService,
        RankingInfoFormService,
        RankingsService
    ) {
        var controller = this;
        controller.loaded = 0;

        controller.isLoading = function () {
            return controller.loaded !== 3;
        };

        controller.isEnquiryFormActive = function () {
            return StatisticsDashboardFactory.isEnquiryFormActive();
        };

        controller.institutionDataWatch = function (data) {
            if (!data || !data.length) {
                return;
            }
        };

        controller.loadQuestions = function () {
            RankingsService.getQuestions().then(function (response) {
                StatisticsDashboardFactory.setQuestions(response);
                controller.loaded++;
            });
        };

        controller.loadSubscriptions = function () {
            InstitutionsListService.getSubscriptions().then(function (response) {
                StatisticsDashboardFactory.setSubscriptions(response);
                controller.loaded++;
            });
        };

        controller.loadRankingInfo = function () {
            RankingInfoFormService.getData().then(function (response) {
                // if the question list is already loaded, do not assign it again.
                if (angular.equals(StatisticsDashboardFactory.getQuestions(), response)) {
                    return false;
                }
                StatisticsDashboardFactory.setRankingsData(response.results || []);
                controller.loaded++;
            });
        };

        controller.init = function () {
            controller.loadQuestions();
            controller.loadSubscriptions();
            controller.loadRankingInfo();
        };
        controller.init();
    }

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { factories: {} });

    App.factories.statisticsDashboard = function() {
        var questions = {},
            questionsIncludingSubQuestions = {},
            question = null,
            activeQuestionHandle,
            status = {
                accepted: 'accepted',
                rejected: 'rejected',
                pending: 'pending',
                queried: 'queried'
            },
            type = {
                personal: 'personal',
                gerPersonal: 'gerPersonal',
                financial: 'financial',
                total: 'total',
                environmentalImpact: 'environmental_impact',
                socialImpact: 'social_impact',
                governance: 'governance',
                additionalInformation: 'additional_information'
            },
            subscriptions = [],
            rankingsData = null,
            enquiryForm = null,
            gerQuestionHandle = null;

        return {
            setQuestions: function (data) {
                questions = data;
            },
            getQuestions: function () {
                return questions;
            },
            setQuestionsIncludingSubQuestions: function (data) {
                questionsIncludingSubQuestions = data;
            },
            getQuestionsIncludingSubQuestions: function () {
                return questionsIncludingSubQuestions;
            },
            setActiveQuestion: function (data) {
                question = data;
                activeQuestionHandle = data && data.handle ? data.handle : null;
            },
            setActiveGerQuestionHandle: function (data) {
                gerQuestionHandle = data;
            },
            getActiveGerQuestionHandle: function () {
                return gerQuestionHandle;
            },
            getActiveQuestion: function () {
                return question;
            },
            getActiveQuestionHandle: function () {
                return activeQuestionHandle;
            },
            getStatuses: function () {
                return status;
            },
            getTypes: function () {
                return type;
            },
            setSubscriptions: function (data) {
                subscriptions = data;
            },
            getSubscriptions: function () {
                return subscriptions;
            },
            toggleEnquiryForm: function (rankingType) {
                if (!rankingType || enquiryForm === rankingType) {
                    enquiryForm = null;
                } else {
                    enquiryForm = rankingType;
                }
            },
            isEnquiryFormActive: function () {
                return !!enquiryForm;
            },
            getEnquiryFormTrigger: function () {
                return enquiryForm;
            },
            getRankingsData: function () {
                return rankingsData;
            },
            setRankingsData: function (data) {
                rankingsData = data;
            }
        };
    };

    angular
        .module('qsHub.rankings')
        .factory('StatisticsDashboardFactory', [
            App.factories.statisticsDashboard
        ]);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {
        components:{}
    });

    App.components.statisticsRightPanel = {
        templateUrl: '/scripts/modules/rankings/components/statisticsDashboard/statisticsRightPanel/statisticsRightPanelView.html',
        controller: 'Rankings.StatisticsRightPanelController'
    };

    angular.module('qsHub.rankings').component('statisticsRightPanel', App.components.statisticsRightPanel);

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.rankings')
        .controller('Rankings.StatisticsRightPanelController', [
            '$scope',
            'WatchService',
            'StatisticsDashboardFactory',
            'StatisticsHistoryService',
            'UserFactory',
            'StatisticsRightPanelFactory',
            '$stateParams',
            StatisticsRightPanel
        ]);

    function StatisticsRightPanel(
        $scope,
        WatchService,
        StatisticsDashboardFactory,
        StatisticsHistoryService,
        UserFactory,
        StatisticsRightPanelFactory,
        $stateParams
    ) {
        var controller = this;
        controller.isClient = UserFactory.isClient();

        controller.activeQuestionName = null;
        controller.isClientUser = false;
        controller.questions = false;

        controller.activeQuestionHandleWatch = function (questionHandle) {
            if (!questionHandle) {
                return;
            }
            controller.activeQuestionName = StatisticsHistoryService.getQuestionName(
                questionHandle,
                controller.questions
            );
        };

        controller.questionsWatch = function (questions) {
            if (!questions || !questions.length) {
                return;
            }
            controller.questions = questions;
            WatchService.create($scope, StatisticsDashboardFactory.getActiveQuestionHandle, controller.activeQuestionHandleWatch);
        };

        controller.activeTabWatch = function (activeTabId) {
            if (isNaN(activeTabId)) {
                return false;
            }
            StatisticsRightPanelFactory.setActiveTabId(activeTabId);
        };

        controller.initWatches = function () {
            WatchService.create($scope, StatisticsDashboardFactory.getQuestions, controller.questionsWatch);
            // listen to active tab changes
            WatchService.create($scope, '$ctrl.activeTab', controller.activeTabWatch);
        };

        controller.$onInit = function () {
            controller.activeTab = $stateParams.messageId ? StatisticsRightPanelFactory.getMessagesTab() : (
                controller.isClient ? StatisticsRightPanelFactory.getDefinitionTab() :StatisticsRightPanelFactory.getHistoryTab()
            );
            controller.initWatches();
            controller.isClientUser = UserFactory.isClient();
        };
    }

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { factories: {} });

    App.factories.statisticsRightPanel = function() {
        var activeTabId,
            tabs = {
                definition : 0,
                history : 1,
                messages : 2,
                notes : 3,
            };

        return {
            setActiveTabId: function (data) {
                activeTabId = data;
            },
            isHistoryTabActive: function () {
                return !!(activeTabId && activeTabId === tabs.history);
            },
            isDefinitionTabActive: function () {
                return !!(typeof activeTabId !== 'undefined' && activeTabId === tabs.definition);
            },
            isMessagesTabActive: function () {
                return !!(activeTabId && activeTabId ===  tabs.messages);
            },
            isNotesTabActive: function () {
                return !!(activeTabId && activeTabId ===  tabs.notes);
            },
            getMessagesTab: function () {
                return tabs.messages;
            },
            getDefinitionTab: function () {
                return tabs.definition;
            },
            getHistoryTab: function () {
                return tabs.history;
            }
        };
    };

    angular
        .module('qsHub.rankings')
        .factory('StatisticsRightPanelFactory', [
            App.factories.statisticsRightPanel
        ]);

}(window.angular));

(function (angular) {
  "use strict";

  var App = App || {};
  App = angular.extend({}, App, {
    components: {}
  });

  App.components.statisticsHistory = {
    templateUrl: '/scripts/modules/rankings/components/statisticsDashboard/statisticsHistory/statisticsHistoryView.html',
    controller: 'Rankings.StatisticsHistoryController'
  };

  angular.module('qsHub.rankings').component('statisticsHistory', App.components.statisticsHistory);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers:{}});
    App.controllers.StatisticsHistory = function (
        $scope,
        $timeout,
        WatchService,
        StatisticsHistoryService,
        RankingsDashboardService,
        InstitutionFactory,
        StatisticsDashboardFactory,
        StatisticsRightPanelFactory,
        StatisticsHistoryFactory,
        GerEmploymentRateService
    ) {
        var controller = this,
            surveySharedWithStudentsOptions;
        controller.logs = [];
        controller.loadingInProgress = false;
        controller.activeQuestionType = null;
        controller.logsToDisplayByDefault = 10;
        controller.logsToDisplayIncrement = 5;
        controller.logsToDisplay = angular.copy(controller.logsToDisplayByDefault);
        controller.status = StatisticsDashboardFactory.getStatuses();
        controller.type = StatisticsDashboardFactory.getTypes();

        controller.isLoading = function () {
            return controller.loadingInProgress;
        };

        controller.load = function (useCache) {
            var coreId = InstitutionFactory.getCoreId(),
                questionHandle = StatisticsDashboardFactory.getActiveQuestionHandle();
            if (!coreId || !questionHandle) {
                return false;
            }
            controller.loadingInProgress = true;
            StatisticsHistoryService.load(coreId, questionHandle, useCache).then(function (logs) {
                if (logs === false) {
                    return false;
                }
                logs = angular.copy(logs);
                controller.hasSource = true;
                // Check if question is ger employment rate
                if (controller.gerQuestionsHandles.indexOf(questionHandle) !== -1) {
                    controller.gerQuestionHandle = questionHandle;
                    controller.hasSource = hasSource(questionHandle);
                } else {
                    controller.gerQuestionHandle = null;
                }
                if(questionHandle === 'employers_connections' || questionHandle === 'employer_partnerships'){
                    controller.hasSource = false;
                }
                controller.logs = logs;
                angular.forEach( controller.logs.results, function (log) {
                   log.source = StatisticsHistoryService.getSourceLabel(log.source);
                   if (!!log.answers && log.answers.whenShared && log.answers.whenShared.value) {
                        log.answers.whenShared.value = RankingsDashboardService.getQuestionValue(
                            log.answers.whenShared.value,
                            surveySharedWithStudentsOptions
                        );
                   }
                });
                $timeout(function () {
                    controller.loadingInProgress = false;
                });
            });
        };

        controller.isUrlField = function (type, key) {
            return type === 'url' && (key === "climate_action_plan_url" ||
                key === "link_to_your_institution_official_climate_action" ||
                key === "link_to_your_institution_sustainable_procurement" ||
                key === "link_to_your_institution_sustainability_climate_policy" ||
                key === "link_to_your_institution_sustainable_investment_policy" ||
                key === "link_to_your_institution_sustainable_policy_on_donations" ||
                key === "link_to_research_centre_with_specific_focus" ||
                key === "link_to_department_with_degree_courses" ||
                key === "link_to_student_led_Society" ||
                key === "link_to_your_university_last_governance_meeting" ||
                key === "does_your_institute_have_an_ethics_committee" ||
                key === "does_your_university_governing_body_have_student_representation" ||
                key === "does_your_organisation_have_and_actively_implement_an_equality" ||
                key === "link_nstitution_disability_office");
        };


        controller.isSustainabilityQuestion = function (questionHandle) {
            return questionHandle === 'cuurent_equality_diversity_and_inclusionn' ||
                    questionHandle === 'institution_provide_dedicated_training_on_environmental_aspects' ||
                    questionHandle === 'institution_have_an_assessment_tool_for_assessing' ||
                    questionHandle === 'instituion_provide_dedicated_training_on_social_impacts' ||
                    questionHandle === 'institution_formed_sustainability_committee' ||
                    questionHandle === 'support_services_for_disabilities' ||
                    questionHandle === 'manage_or_deliver_outreach_projects' ||
                    questionHandle === 'does_institution_have_dedicated_staff_member' ||
                    questionHandle === 'equality_diversity_and_inclusionn_comittee' ||
                    questionHandle === 'anti_bribery_policy' ||
                    questionHandle === 'proportion_of_papers_publihsed' ||
                    questionHandle === 'holistic_ethical_organisational_culture' ||
                    questionHandle === 'university_student_union' ||
                    questionHandle === 'publish_financial_reports' ||
                    questionHandle === 'presence_of_research_center' ||
                    questionHandle === 'climate_science_environmental_sustainability' ||
                    questionHandle === 'report_carbon_emissions' ||
                    questionHandle === 'carbon_emissions_in_kilograms' ||
                    questionHandle === 'same_ffigure_for_base_line' ||
                    questionHandle === 'carbon_redduction_target' ||
                    questionHandle === 'enery_generated_in_campus' ||
                    questionHandle === 'campus_building_footprint' ||
                    questionHandle === 'institution_publicaly_committed_to_reaching_net_zero' ||
                    questionHandle === 'health_and_wellbeing_services';
        };


        function hasSource(questionHandle) {
           
            switch(questionHandle) {
                case 'number_of_students':
                case 'evidence_when_shared':
                case 'evidence_which_year_conducted':
                case 'evidence_link_or_screenshot':
                case 'number_of_students_entrepreneurship':
                case 'median_grad_salary':
                    return true;
                case 'number_of_students_auto':
                case 'qs_employment_rate_auto':
                case 'number_of_students_entrepreneurship_auto':
                    return false;
            }
        }

        controller.isMoreLogsAvailable = function() {
            if (controller.logs && controller.logs.totalFiltered) {
                return controller.logsToDisplay < controller.logs.totalFiltered;
            }
            return false;
        };

        controller.handleLoadMoreHistoryLogs = function () {
            controller.logsToDisplay = controller.logsToDisplay + controller.logsToDisplayIncrement;
        };

        controller.activeQuestionWatch = function (question) {
            if (!question || angular.equals(question, {})) {
                return false;
            }
            controller.activeQuestionType = question.type;
            controller.load();
        };

        controller.coreIdWatch = function (institutionCoreId, oldInstitutionCoreId) {
            if (!institutionCoreId || (oldInstitutionCoreId && oldInstitutionCoreId === institutionCoreId)) {
                return false;
            }
            controller.load();
        };

        function gerQuestionHandleWatch(newHandle, oldHandle) {
            if (newHandle && newHandle !== oldHandle) {
                controller.load();
            }
        }

        controller.initWatches = function () {
            WatchService.create($scope, InstitutionFactory.getCoreId, controller.coreIdWatch);
            WatchService.create($scope, StatisticsDashboardFactory.getActiveQuestion, controller.activeQuestionWatch, true);
            WatchService.create($scope, StatisticsHistoryFactory.isReloadRequired, function (newValue) {
                if (newValue) {
                    StatisticsHistoryFactory.setReload(false);
                    controller.load(false);
                }
            });
            //WatchService.create($scope, StatisticsDashboardFactory.getActiveGerQuestionHandle, gerQuestionHandleWatch);
        };

        controller.$onInit = function () {
            var tabWatch = WatchService.create($scope, StatisticsRightPanelFactory.isHistoryTabActive, function (active) {
                if (active) {
                    controller.initWatches();
                    tabWatch();
                }
            });
            GerEmploymentRateService.getAnswersHandles().then(function(data) {
                controller.gerQuestionsHandles = [];
                for (var i = 0; i < data.length; i++) {
                    controller.gerQuestionsHandles.push(data[i].handle);
                }
            });
            GerEmploymentRateService.getSurveySharedWithStudentsOptions().then(function (data) {
                surveySharedWithStudentsOptions = data;
            });
        };
    };

    angular
        .module('qsHub.rankings')
        .controller('Rankings.StatisticsHistoryController', [
            '$scope',
            '$timeout',
            'WatchService',
            'StatisticsHistoryService',
            'RankingsDashboardService',
            'InstitutionFactory',
            'StatisticsDashboardFactory',
            'StatisticsRightPanelFactory',
            'StatisticsHistoryFactory',
            'GerEmploymentRateService',
            App.controllers.StatisticsHistory
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    angular
        .module('qsHub.rankings')
        .service('StatisticsHistoryService', [
            '$resource',
            '$log',
            '$q',
            'constants',
            'rankingsSources',
            'RequestsQueueService',
            StatisticsHistory
        ]);

     function StatisticsHistory(
        $resource,
        $log,
        $q,
        constants,
        sources,
        RequestsQueueService
    ) {
        var service = {
            cache : {}
        };

        service.getHistoryModel = function () {
            return $resource(constants.api.rankings.url + '/v1/history?filter[institutionCoreId]==:institutionCoreId&filter[questionHandle]==:questionHandle&sorting[createdAt]=desc&limit=50', {}, {
                get: {
                    isArray:false,
                    cancellable : true
                }
            });
        };

        service.clearCache = function () {
            service.cache = {};
        };

        service.load = function (institutionCoreId, questionHandle, useCache) {
            if (typeof useCache === 'undefined') {
                useCache = true;
            }
            var cacheKey = 'StatisticsHistory:load',
                dataKey = cacheKey + ':' + institutionCoreId + ':' + questionHandle,
                deferred = $q.defer(),
                Api;

            if (useCache && service.cache[dataKey]) {
                deferred.resolve(service.cache[dataKey]);
                return deferred.promise;
            }
            RequestsQueueService.cancelAll(cacheKey);

            Api = service.getHistoryModel().get({
                institutionCoreId: institutionCoreId,
                questionHandle: questionHandle
            });
            RequestsQueueService.add(Api, cacheKey);

            Api.$promise.then(function (response) {
                if (constants.dev) {
                    $log.log('success, got data: ', response);
                }
                service.cache[dataKey] = response;
                deferred.resolve(response);
                deferred = null;
            }, function (error) {
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                deferred.resolve(false);
                deferred = null;
            });

            return deferred.promise;
        };

        service.getQuestionType = function (questionHandle, questions) {
            var total = questions.length,
                i = 0;
            for (i; i < total; i++) {
                if (questions[i].handle === questionHandle) {
                    return questions[i].type;
                }
            }
        };

        service.getQuestionName = function (questionHandle, questions) {
            var total = questions.length,
                i = 0;
            for (i; i < total; i++) {
                if (questions[i].handle === questionHandle) {
                    return questions[i].name;
                }
            }
        };

        service.getSourceLabel = function (sourceValue) {
            var source;
            for (source in sources) {
                if (source === sourceValue) {
                    return sources[source];
                }
            }
        };

        return service;
    }

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { factories: {} });

    App.factories.StatisticsHistory = function() {
        var reload=false;

        return {
            setReload: function (value) {
                reload = value;
            },
           
            isReloadRequired: function () {
                return reload;
            }
        };
    };

    angular
        .module('qsHub.rankings')
        .factory('StatisticsHistoryFactory', [
            App.factories.StatisticsHistory
        ]);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {
        components:{}
    });

    App.components.statisticsLanguages = {
        controller: 'Rankings.StatisticsLanguagesController',
        templateUrl: '/scripts/modules/rankings/components/statisticsDashboard/statisticsLanguages/statisticsLanguagesView.html',
        bindings: {
            rankingHandle: '<'
        }
    };

    angular.module('qsHub.rankings').component('statisticsLanguages', App.components.statisticsLanguages);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers:{}});
    App.controllers.StatisticsLanguages = function (
        $scope,
        languages,
        WatchService,
        StatisticsLanguagesService,
        StatisticsDashboardFactory
    ) {
        var controller = this;
        controller.languages = languages;
        controller.guidelines = {};
        controller.hasGuidelines = false;

        controller.getRankingsDataWatch = function (newValue) {
            if (newValue) {
                controller.guidelines = StatisticsLanguagesService.processGuidelines(newValue, controller.rankingHandle);
                controller.hasGuidelines = Object.keys(controller.guidelines).length;
            }
        };

        controller.initWatches = function () {
            controller.rankingsDataWatch = WatchService.create($scope, StatisticsDashboardFactory.getRankingsData, controller.getRankingsDataWatch);
        };

        controller.$onInit = function () {
            controller.initWatches();
        };
    };

    angular
        .module('qsHub.rankings')
        .controller('Rankings.StatisticsLanguagesController', [
            '$scope',
            'languages',
            'WatchService',
            'StatisticsLanguagesService',
            'StatisticsDashboardFactory',
            App.controllers.StatisticsLanguages
        ]);

}(window.angular));

(function(angular, moment) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    App.services.StatisticsLanguages = function () {
        var service = {};

        service.getLatestGuidelines = function (guidelines, latestGuidelines) {
            var guidelinesPerLanguage = {};
            angular.forEach(guidelines, function (guideline) {
                if (!guidelinesPerLanguage[guideline.language]) {
                    guidelinesPerLanguage[guideline.language] = [];
                }
                guidelinesPerLanguage[guideline.language][guideline.createdAt] = guideline;
            });
            angular.forEach(guidelinesPerLanguage , function (guidelines, language) {
                latestGuidelines[language] = guidelines[Object.keys(guidelines).sort().pop()];
            });
        };

        service.processGuidelines = function (data, rankingHandle) {
            var total = data.length,
                item = null,
                latestGuidelines = {},
                i = 0;
            for (i; i < total; i++) {
                item = data[i];
                if (item.handle === rankingHandle) {
                    if (item.guidelines) {
                        service.getLatestGuidelines(item.guidelines, latestGuidelines);
                    }
                    break;
                }
            }
            return latestGuidelines;
        };

        return service;
    };

    angular
        .module('qsHub.rankings')
        .service('StatisticsLanguagesService', [
            App.services.StatisticsLanguages
        ]);

}(window.angular, window.moment));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {
        components:{}
    });

    App.components.statisticsDefinition = {
        templateUrl: '/scripts/modules/rankings/components/statisticsDashboard/statisticsDefinition/statisticsDefinitionView.html',
        controller: 'Rankings.StatisticsDefinitionController'
    };

    angular.module('qsHub.rankings').component('statisticsDefinition', App.components.statisticsDefinition);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers:{}});
    App.controllers.StatisticsDefinition = function (
        $scope,
        WatchService,
        StatisticsDashboardFactory,
        StatisticsRightPanelFactory
    ) {
        var controller = this;
        controller.logs = [];
        controller.loadingInProgress = false;
        controller.definition = null;

        controller.isLoading = function () {
            return controller.loadingInProgress;
        };

        controller.activeQuestionWatch = function (question) {
            if (!question || angular.equals(question, {})) {
                return false;
            }

            controller.loadingInProgress = true;
            controller.definition = question.definition;
            // if(question.handle === 'median_grad_salary'){
            //     controller.definition = 'Please provide the median starting salary a graduate can expect within 12 months of their graduation, excluding any bonuses.';
            // }
            if(question.handle === 'number_of_students' || question.handle === 'median_grad_salary' || question.handle === 'number_of_students_auto' || question.handle === 'qs_employment_rate_auto'){
                controller.definition = 'The graduate employment rate is the percentage of graduates who go on to paid (non-voluntary) work within 15 months of finishing their degree. We consider any mode of employment (full-time or part-time), even if unknown.';
            }
            controller.loadingInProgress = false;
        };

        controller.initWatches = function () {
            WatchService.create($scope, StatisticsDashboardFactory.getActiveQuestion, controller.activeQuestionWatch, true);
        };

        controller.$onInit = function () {
            var tabWatch = WatchService.create($scope, StatisticsRightPanelFactory.isDefinitionTabActive, function (active) {
                if (active) {
                    controller.initWatches();
                    tabWatch();
                }
            });
        };
    };

    angular
        .module('qsHub.rankings')
        .controller('Rankings.StatisticsDefinitionController', [
            '$scope',
            'WatchService',
            'StatisticsDashboardFactory',
            'StatisticsRightPanelFactory',
            App.controllers.StatisticsDefinition
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    App.services.StatisticsDefinition = function () {
        var service = {};

        service.getQuestionDefinition = function (questionHandle, questions) {
            var total = questions.length,
                i = 0;
            for (i; i < total; i++) {
                if (questions[i].handle === questionHandle) {
                    return questions[i].definition;
                }
            }
        };

        return service;
    };

    angular
        .module('qsHub.rankings')
        .service('StatisticsDefinitionService', [
            App.services.StatisticsDefinition
        ]);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {
        components:{}
    });

    App.components.statisticsNotes = {
        templateUrl: '/scripts/modules/rankings/components/statisticsDashboard/statisticsNotes/statisticsNotesView.html',
        controller: 'Rankings.StatisticsNotesController'
    };

    angular.module('qsHub.rankings').component('statisticsNotes', App.components.statisticsNotes);

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.rankings')
        .controller('Rankings.StatisticsNotesController', [
            '$scope',
            'WatchService',
            'StatisticsNotesService',
            'InstitutionFactory',
            'StatisticsDashboardFactory',
            'StatisticsNotesFactory',
            'UserFactory',
            'StatisticsRightPanelFactory',
            StatisticsNotes
        ]);

    function StatisticsNotes(
        $scope,
        WatchService,
        StatisticsNotesService,
        InstitutionFactory,
        StatisticsDashboardFactory,
        StatisticsNotesFactory,
        UserFactory,
        StatisticsRightPanelFactory
    ) {
        var controller = this;
        controller.notes = [];
        controller.loadingInProgress = false;
        controller.notesToDisplayByDefault = 10;
        controller.notesToDisplayIncrement = 5;
        controller.notesToDisplay = angular.copy(controller.notesToDisplayByDefault);
        controller.isClient = UserFactory.isClient();
        controller.options = {
            limit: controller.notesToDisplayByDefault,
            page: 1
        };

        controller.expand = function (index) {
            angular.forEach(controller.notes.results, function (note, key) {
                note.open =  !!(key === index && !note.open);
            });
        };

        controller.isLoading = function () {
            return controller.loadingInProgress;
        };

        controller.handleNewNoteClick = function () {
            StatisticsNotesFactory.showAddForm();
        };

        controller.isAddFormVisible = function () {
            return StatisticsNotesFactory.isAddFormVisible();
        };

        controller.load = function () {
            if (!InstitutionFactory.getCoreId() || !StatisticsDashboardFactory.getActiveQuestionHandle()) {
                return false;
            }
            controller.loadingInProgress = true;
            StatisticsNotesService.load(
                InstitutionFactory.getCoreId(),
                StatisticsDashboardFactory.getActiveQuestionHandle(),
                controller.options
            ).then(function (notes) {
                controller.notes = notes;
                controller.loadingInProgress = false;
            });
        };

        controller.isMoreAvailable = function() {
            if (controller.notes && controller.notes.totalMatching) {
                return controller.notesToDisplay < controller.notes.totalMatching;
            }
            return false;
        };

        controller.handleLoadMore = function () {
            controller.notesToDisplay = controller.notesToDisplay + controller.notesToDisplayIncrement;
            controller.options.limit = controller.notesToDisplay;
            controller.load();
        };

        controller.coreIdWatch = function (institutionCoreId, oldInstitutionCoreId) {
            StatisticsNotesFactory.closeAddForm();
            if (institutionCoreId && institutionCoreId !== oldInstitutionCoreId) {
                controller.resetNotesOptions();
                controller.load();
            }
        };

        controller.newNoteWatch = function (has) {
            if (has) {
                controller.resetNotesOptions();
                controller.load();
                StatisticsNotesFactory.resetNewNoteAnnouncements();
            }
        };

        controller.resetNotesOptions = function () {
            controller.options.limit = controller.notesToDisplayByDefault;
            controller.notesToDisplay = controller.notesToDisplayByDefault;
        };

        controller.activeQuestionWatch = function (question) {
            StatisticsNotesFactory.closeAddForm();
            if (!question || angular.equals(question, {})) {
                return false;
            }
            controller.resetNotesOptions();
            controller.load();
        };

        controller.initWatches = function () {
            WatchService.create($scope, InstitutionFactory.getCoreId, controller.coreIdWatch);
            WatchService.create($scope, StatisticsNotesFactory.hasNewNote, controller.newNoteWatch);
            WatchService.create($scope, StatisticsDashboardFactory.getActiveQuestion, controller.activeQuestionWatch, true);
        };

        controller.$onInit = function () {
            var tabWatch = WatchService.create($scope, StatisticsRightPanelFactory.isNotesTabActive, function (is) {
                if (is) {
                    controller.initWatches();
                    tabWatch();
                }
            });

        };
    }

}(window.angular));

(function(angular) {
    "use strict";

    angular
        .module('qsHub.rankings')
        .service('StatisticsNotesService', [
            '$resource',
            '$log',
            'constants',
            StatisticsNotes
        ]);

    function StatisticsNotes(
        $resource,
        $log,
        constants
    ) {
        var service = {};

        service.getModel = function () {
            return $resource(constants.api.rankings.url + '/v1/notes?filter[institutionCoreId]==:institutionCoreId&filter[questionHandle]==:questionHandle&sorting[createdAt]=desc', {}, {
                get: {
                    isArray:false,
                    cancellable : true
                }
            });
        };

        service.load = function (institutionCoreId, questionHandle, options) {
            return service.getModel().get(angular.extend({
                institutionCoreId: institutionCoreId,
                questionHandle: questionHandle
            }, options)).$promise.then(function (response) {
                if (constants.dev) {
                    $log.log('success, got data: ', response);
                }
                return response;
            }, function (error) {
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        service.getQuestionType = function (questionHandle, questions) {
            var total = questions.length,
                i = 0;
            for (i; i < total; i++) {
                if (questions[i].handle === questionHandle) {
                    return questions[i].type;
                }
            }
        };

        service.getQuestionName = function (questionHandle, questions) {
            var total = questions.length,
                i = 0;
            for (i; i < total; i++) {
                if (questions[i].handle === questionHandle) {
                    return questions[i].name;
                }
            }
        };

        return service;
    }

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { factories: {} });

    App.factories.statisticsNotes = function() {
        var showAddForm = false,
            newNoteId = null;

        return {
            isAddFormVisible: function () {
                return showAddForm;
            },
            closeAddForm: function () {
                showAddForm = false;
            },
            showAddForm: function () {
                showAddForm = true;
            },
            announceNewNote: function (noteId) {
                newNoteId = noteId;
            },
            resetNewNoteAnnouncements: function () {
                newNoteId = null;
            },
            hasNewNote: function () {
                return !!newNoteId;
            },
        };
    };

    angular
        .module('qsHub.rankings')
        .factory('StatisticsNotesFactory', [
            App.factories.statisticsNotes
        ]);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {
        components:{}
    });

    App.components.statisticsAddNote = {
        templateUrl: '/scripts/modules/rankings/components/statisticsDashboard/statisticsAddNote/statisticsAddNoteView.html',
        controller: 'Rankings.StatisticsAddNoteController'
    };

    angular.module('qsHub.rankings').component('statisticsAddNote', App.components.statisticsAddNote);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers:{}});
    App.controllers.StatisticsAddNote = function (
        StatisticsAddNoteService,
        StatisticsDashboardFactory,
        InstitutionFactory,
        StatisticsNotesFactory,
        NotifierFactory,
        TextService
    ) {
        var controller = this;
        controller.note = '';
        controller.noteWords = 0;
        controller.loadingInProgress = false;
        controller.textEditorOptions = {
            disableDragAndDrop: true,
            styleWithSpan: false,
            dialogsInBody: true,
            minHeight: 280,
            maxHeight: 280,
            toolbar: [
                ['alignment', ['ul', 'ol']],
                ['edit', ['undo', 'redo']]
            ],
            popover: {
                link: []
            }
        };
        controller.maxWords = 200;
        controller.submitted = false;

        controller.isLoading = function () {
            return controller.loadingInProgress;
        };

        controller.handleAdd = function () {
            controller.submitted = true;
            if (!controller.isValid()) {
                return false;
            }
            StatisticsAddNoteService.save(
                InstitutionFactory.getCoreId(),
                StatisticsDashboardFactory.getActiveQuestionHandle(),
                controller.note
            ).then(function (response) {
                var success = !(!response || response.error || !response.insertedId);
                NotifierFactory.show(
                    success ? 'success' : 'error',
                    success ? 'Created successfully!' : (response && response.error && response.message ? response.message : 'Oops, failed'),
                    'Creating Note'
                );
                if (success) {
                    controller.submitted = false;
                    controller.note = null;
                    StatisticsNotesFactory.closeAddForm();
                    StatisticsNotesFactory.announceNewNote(response.insertedId);
                }
            });
        };

        controller.isValid = function () {
            if (!controller.submitted) {
                return true;
            }
            return controller.note && controller.noteWords > 0 && controller.noteWords < controller.maxWords;
        };

        controller.handleNoteFocus = function () {
            controller.submitted = false;
        };

        controller.handleCancel = function () {
            controller.note = null;
            StatisticsNotesFactory.closeAddForm();
        };
        /**
         * Count words in the string.
         *
         * @param {string} text
         */
        controller.countWords = function (text) {
            return TextService.countWords(text);
        };

        controller.initWatches = function () {};

        controller.$onInit = function () {
            controller.initWatches();
        };
    };

    angular
        .module('qsHub.rankings')
        .controller('Rankings.StatisticsAddNoteController', [
            'StatisticsAddNoteService',
            'StatisticsDashboardFactory',
            'InstitutionFactory',
            'StatisticsNotesFactory',
            'NotifierFactory',
            'TextService',
            App.controllers.StatisticsAddNote
        ]);

}(window.angular));

(function(angular, moment) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    App.services.StatisticsAddNote = function (
        $resource,
        $log,
        constants
    ) {
        var service = {};

        service.getNotesModel = function () {
            return $resource(constants.api.rankings.url + '/v1/notes');
        };

        service.save = function (institutionCoreId, questionHandle, note) {
            return service.getNotesModel().save({
                institutionCoreId: institutionCoreId,
                questionHandle: questionHandle,
                text: note
            }).$promise.then(function (response) {
                if (constants.dev) {
                    $log.log('success, got data: ', response);
                }
                return response;
            }, function (error) {
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        return service;
    };

    angular
        .module('qsHub.rankings')
        .service('StatisticsAddNoteService', [
            '$resource',
            '$log',
            'constants',
            App.services.StatisticsAddNote
        ]);

}(window.angular, window.moment));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {
        components:{}
    });

    App.components.statisticsAvailable = {
        templateUrl: '/scripts/modules/rankings/components/statisticsDashboard/statisticsAvailable/statisticsAvailableView.html',
        controller: 'Rankings.StatisticsAvailableController'
    };

    angular.module('qsHub.rankings').component('statisticsAvailable', App.components.statisticsAvailable);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers:{}});
    App.controllers.StatisticsAvailable = function (
        $scope,
        WatchService,
        InstitutionFactory,
        StatisticsDashboardFactory,
        UserFactory
    ) {
        var controller = this;
        controller.statisticsAvailable = [];
        controller.statisticsUnSubscribed = [];

        /**
         * Toggles enquiry form visibility
         *
         * @param type
         */
        controller.toggleEnquiryForm = function (rankingHandle) {
            StatisticsDashboardFactory.toggleEnquiryForm(rankingHandle);
        };

        controller.institutionDataWatch = function (data) {
            if (!data || angular.equals({}, data)) {
                return;
            }
            controller.statisticsUnSubscribed = [];
            var subscribedTo = InstitutionFactory.getSubscriptions();
            angular.forEach(controller.statisticsAvailable, function (item) {
                if (!subscribedTo[item.handle] || !subscribedTo[item.handle].subscribed) {
                    if ((item.handle === 'ger')) {
                        return;
                    }
                    controller.statisticsUnSubscribed.push(item);
                }
            });
        };

        controller.subscriptionsWatch = function (data) {
            if (!data || !data.length) {
                return;
            }
            angular.forEach(StatisticsDashboardFactory.getSubscriptions(), function (item) {
                if (item.handle !== 'all' && item.handle !== 'stars') {
                    controller.statisticsAvailable.push(item);
                }
            });
            WatchService.create($scope, InstitutionFactory.getData, controller.institutionDataWatch);
        };

        controller.initWatches = function () {
            WatchService.create($scope, StatisticsDashboardFactory.getSubscriptions, controller.subscriptionsWatch);
        };

        controller.$onInit = function () {
            controller.initWatches();
            controller.toggleEnquiryForm();
        };
    };

    angular
        .module('qsHub.rankings')
        .controller('Rankings.StatisticsAvailableController', [
            '$scope',
            'WatchService',
            'InstitutionFactory',
            'StatisticsDashboardFactory',
            'UserFactory',
            App.controllers.StatisticsAvailable
        ]);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {
        components:{}
    });

    App.components.statisticsSubscribed = {
        templateUrl: '/scripts/modules/rankings/components/statisticsDashboard/statisticsSubscribed/statisticsSubscribedView.html',
        controller: 'Rankings.StatisticsSubscribedController'
    };

    angular.module('qsHub.rankings').component('statisticsSubscribed', App.components.statisticsSubscribed);

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.rankings')
        .controller('Rankings.StatisticsSubscribedController', [
            '$scope',
            'WatchService',
            'InstitutionFactory',
            'StatisticsDashboardFactory',
            'StatisticsSubscribedService',
            'StatisticsSubmissionsFactory',
            'UserFactory',
            '$rootScope',
            StatisticsSubscribed
        ]);

    function StatisticsSubscribed(
        $scope,
        WatchService,
        InstitutionFactory,
        StatisticsDashboardFactory,
        StatisticsSubscribedService,
        StatisticsSubmissionsFactory,
        UserFactory,
        $rootScope
    ) {
        var controller = this;
        controller.statisticsAvailable = [];
        controller.statisticsSubscribed = [];
        controller.totals = [];
        controller.deadlines = {};

        controller.institutionDataWatch = function (data) {
            if (!data || angular.equals({}, data)) {
                return;
            }
            controller.statisticsSubscribed = [];
            var subscribedTo = InstitutionFactory.getSubscriptions();
            angular.forEach(controller.statisticsAvailable, function (item) {
                if (subscribedTo[item.handle] && subscribedTo[item.handle].subscribed) {
                    if ((item.handle === 'ger')) {
                        return;
                    }
                    controller.statisticsSubscribed.push(item);
                }
            });
        };

        controller.subscriptionsWatch = function (data) {
            if (!data || !data.length) {
                return;
            }
            angular.forEach(StatisticsDashboardFactory.getSubscriptions(), function (item) {
                if (item.handle !== 'all' && item.handle !== 'stars') {
                    controller.statisticsAvailable.push(item);
                }
            });
            WatchService.create($scope, InstitutionFactory.getData, controller.institutionDataWatch);
        };

        controller.coreIdWatch = function (institutionCoreId) {
            if (!institutionCoreId) {
                return;
            }
            StatisticsSubscribedService.getTotals(institutionCoreId).then(function (data) {
                controller.totals = data;
            });
        };

        controller.rankingsDataWatch = function (rankingsData) {
            angular.forEach(rankingsData, function (item) {
                controller.deadlines[item.handle] = item.deadline;
            });
        };

        var routeWatcher = $rootScope.$on('$stateChangeSuccess', function (event, toState) {
            if (toState.name === 'clients.statistics.data-submission' && StatisticsSubmissionsFactory.hasChanges()) {
                controller.coreIdWatch(InstitutionFactory.getCoreId());
            }
        });

        controller.initWatches = function () {
            WatchService.create($scope, StatisticsDashboardFactory.getSubscriptions, controller.subscriptionsWatch);
            WatchService.create($scope, InstitutionFactory.getCoreId, controller.coreIdWatch);
            WatchService.create($scope, StatisticsDashboardFactory.getRankingsData, controller.rankingsDataWatch);
        };

        controller.$onInit = function () {
            controller.initWatches();
            controller.statuses = StatisticsDashboardFactory.getStatuses();
        };

        controller.$onDestroy = function () {
            routeWatcher();
        };
    }

}(window.angular));

(function(angular, moment) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    App.services.StatisticsSubscribed = function (
        $resource,
        $log,
        constants
    ) {
        var service = {};

        service.getTotalsModel = function () {
            return $resource(constants.api.rankings.url + '/v1/answers/totals?filter[institutionCoreId]=:institutionCoreId', {}, {
                get: {
                    isArray:false,
                    cancellable : true
                }
            });
        };

        service.getTotals = function (institutionCoreId) {
            return service.getTotalsModel().get({
                institutionCoreId: institutionCoreId
            }).$promise.then(function (response) {
                if (constants.dev) {
                    $log.log('success, got data: ', response);
                }
                return response;
            }, function (error) {
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        return service;
    };

    angular
        .module('qsHub.rankings')
        .service('StatisticsSubscribedService', [
            '$resource',
            '$log',
            'constants',
            App.services.StatisticsSubscribed
        ]);

}(window.angular, window.moment));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {
        components:{}
    });

    App.components.statisticsInfoIcons = {
        templateUrl: '/scripts/modules/rankings/components/statisticsDashboard/statisticsInfoIcons/statisticsInfoIconsView.html',
    };

    angular.module('qsHub.rankings').component('statisticsInfoIcons', App.components.statisticsInfoIcons);

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {
        components:{}
    });

    App.components.enquiryForm = {
        templateUrl: '/scripts/modules/rankings/components/statisticsDashboard/enquiryForm/enquiryFormView.html',
        controller: 'enquiryFormController'
    };

    angular.module('qsHub.rankings').component('statisticsEnquiryForm', App.components.enquiryForm);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers: {}});
    App.controllers.EnquiryForm = function (
        $scope,
        UserFactory,
        InstitutionFactory,
        WatchService,
        StatisticsDashboardFactory,
        EnquiryFormService,
        NotifierFactory,
        TextService
    ) {
        var controller = this;

        controller.subscriptions = [];
        controller.subscriptionsKeyValue = [];
        controller.institutionSubscriptions = [];
        controller.rankings = {};
        controller.invalidRankingSelection = false;
        controller.messageWords = 0;
        controller.submitted = false;
        controller.messageWordLimit = 100;
        controller.validationMessageWordLimit =
            'Word count limit of ' +
            controller.messageWordLimit +
            ' is exceeded.';
        controller.maxWords = 100;
        controller.enquiryFormData = {
            email: null,
            rankings: {},
            comments: null,
            coreId: null,
            position: null,
            name: null
        };
        controller.enquiryFormDataCopy = {};
        controller.excludedHandles = [
            'all',
            'stars'
        ];

        controller.isDataLoading = {
            subscriptions: true,
            institutionSubscriptions: true,
            coreId: true,
            sendRequest: false
        };

        controller.isLoading = function () {
            return controller.isDataLoading.subscriptions ||
                controller.isDataLoading.institutionSubscriptions ||
                controller.isDataLoading.coreId ||
                controller.isDataLoading.sendRequest;
        };

        controller.closeForm = function () {
            StatisticsDashboardFactory.toggleEnquiryForm(null);
            controller.resetForm();
        };

        controller.initRankings = function () {
            controller.rankings = {};
            angular.forEach(controller.subscriptions, function (item) {
                controller.rankings[item.handle] = item.name;
                if (controller.institutionSubscriptions.hasOwnProperty(item.handle)) {
                    delete(controller.rankings[item.handle]);
                }
            });
        };

        controller.isLoadingChanged = function (isDataLoading) {
            if (isDataLoading === false) {
                controller.initRankings();
            }
        };

        controller.resetForm = function () {
            controller.enquiryFormData.rankings = {};
            controller.enquiryFormData.comments = null;
            controller.invalidRankingSelection = false;
            controller.submitted = false;
        };

        controller.isInvalidRankings = function() {
            return controller.invalidRankingSelection;
        };

        controller.rankingsChanged = function () {
            controller.invalidRankingSelection = false;
        };


        /**
         * Count words in the string.
         *
         * @param {string} text
         */
        controller.countWords = function (text) {
            return TextService.countWords(text);
        };

        controller.isInvalidMessage = function () {
            return controller.submitted && !TextService.countWords(controller.enquiryFormData.comments);
        };
        controller.isMessageCountInvalid = function () {
            return TextService.countWords(controller.enquiryFormData.comments) > controller.messageWordLimit;
        };
        controller.enquiryFormTriggerWatch = function (rankingHandle) {
            if (rankingHandle) {
                controller.enquiryFormData.rankings = {};
                controller.enquiryFormData.rankings[rankingHandle] = true;
                controller.invalidRankingSelection = false;
            } else {
                controller.resetForm();
            }
        };

        controller.sendEnquiry = function () {
            controller.submitted = true;
            if (!controller.countWords(controller.enquiryFormData.comments)) {
                return false;
            }

            /**
             * Validation 1:
             * Number of words in message is exceeding the limit of messageWordsLimit
             */
            if (controller.isMessageCountInvalid()) {
                NotifierFactory.show(
                    'error',
                    controller.validationMessageWordLimit,
                    'Saving failed!'
                );

                return false;
            }
            angular.copy(controller.enquiryFormData, controller.enquiryFormDataCopy);
            controller.isDataLoading.sendRequest = true;
            // remove false values
            angular.forEach(controller.enquiryFormData.rankings, function (ranking, key) {
                if (!ranking) {
                    delete(controller.enquiryFormData.rankings[key]);
                }
            });
            // validate if any rankings checked
            if (angular.equals({}, controller.enquiryFormData.rankings)) {
                controller.invalidRankingSelection = true;
                controller.isDataLoading.sendRequest = false;

                return false;
            }
            // populate rankings with names
            angular.forEach(controller.enquiryFormData.rankings, function(ranking, key) {
                controller.enquiryFormData.rankings[key] = controller.subscriptionsKeyValue[key];
            });

            // send enquiry
            EnquiryFormService.sendEnquiry(controller.enquiryFormData).then(function (success) {
                NotifierFactory.show(
                    success ? 'success' : 'error',
                    success ? 'Enquiry sent successfully!' : 'Processing enquiry failed!',
                    'Enquiry'
                );
                if (success) {
                    controller.closeForm();
                } else {
                    angular.copy(controller.enquiryFormDataCopy, controller.enquiryFormData);
                }
                controller.isDataLoading.sendRequest = false;
            });
        };

        controller.getCoreIdWatch = function (value) {
            controller.isDataLoading.coreId = true;
            if (value) {
                controller.resetForm();
                controller.enquiryFormData.coreId = value;
                controller.isDataLoading.coreId = false;
            }
        };

        controller.subscriptionsWatch = function (data) {
            if (!data || !data.length) {
                return;
            }
            angular.forEach(StatisticsDashboardFactory.getSubscriptions(), function (item) {
                if (controller.excludedHandles.indexOf(item.handle) === -1) {
                    controller.subscriptions.push(item);
                    controller.subscriptionsKeyValue[item.handle] = item.name;
                }
            });
            controller.isDataLoading.subscriptions = false;
        };

        controller.institutionSubscriptionsWatch = function (value) {
            controller.institutionSubscriptions = [];
            controller.isDataLoading.institutionSubscriptions = true;
            if (value) {
                angular.forEach(value, function (subscription, key) {
                    if (subscription.subscribed) {
                        controller.institutionSubscriptions[key] = true;
                    }
                });
                controller.isDataLoading.institutionSubscriptions = false;
                controller.initRankings();
            }
        };

        controller.initWatches = function () {
            WatchService.create($scope, InstitutionFactory.getCoreId, controller.getCoreIdWatch);
            WatchService.create($scope, InstitutionFactory.getSubscriptions, controller.institutionSubscriptionsWatch);
            WatchService.create($scope, StatisticsDashboardFactory.getEnquiryFormTrigger, controller.enquiryFormTriggerWatch);
            WatchService.create($scope, StatisticsDashboardFactory.getSubscriptions, controller.subscriptionsWatch);
            WatchService.create($scope, controller.isLoading, controller.isLoadingChanged);
        };

        controller.init = function () {
            controller.enquiryFormData.email = UserFactory.getUserName();
            controller.enquiryFormData.name = (UserFactory.getFirstName() + ' ' + UserFactory.getLastName()).trim();
            controller.enquiryFormData.position = UserFactory.getPosition();
        };

        controller.$onInit = function () {
            controller.init();
            controller.initWatches();
        };
    };

    angular
        .module('qsHub.rankings')
        .controller('enquiryFormController', [
            '$scope',
            'UserFactory',
            'InstitutionFactory',
            'WatchService',
            'StatisticsDashboardFactory',
            'EnquiryFormService',
            'NotifierFactory',
            'TextService',
            App.controllers.EnquiryForm
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    App.services.EnquiryFormService = function (
        $resource,
        $log,
        constants
    ) {
        var service = {};

        /**
         * Get tu profile upgrade requests end point.
         *
         * @returns {$resource}
         */
        service.getSendEnquiryModel = function () {
            return $resource(constants.api.rankings.url + '/v1/enquire');
        };

        /**
         * Send enquiry.
         *
         * @param {String} enquiryData
         * @returns {Promise}
         */
        service.sendEnquiry = function (enquiryData) {
            var Api = service.getSendEnquiryModel();
            return Api.save(enquiryData).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }

                return true;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }

                return false;
            });
        };

        return service;
    };

    angular
        .module('qsHub.rankings')
        .service('EnquiryFormService', [
            '$resource',
            '$log',
            'constants',
            App.services.EnquiryFormService
        ]);

}(window.angular));

(function(angular) {
    'use strict';

    /**
     * Directive to validate data submission for rankings.
     */
   var validateDataSubmission = function (broadcast, StatisticsValidationService,StatisticsSubmissionsFactory,WatchService,StatisticsSubmissionsService, $location, $timeout, MessagesService, InstitutionFactory) {
        return {
            require: ['^form'],
            restrict: 'A',
            scope: {
                validateDataSubmissionValue: '@',
                validateDataSubmissionValueType:'@',
                validateDataSubmissionOriginal: '@',
                validateDataSubmissionRules: '@',
                validateDataSubmissionFt: '=',
                validateDataSubmissionPt: '=',
                validateDataSubmissionHc: '@',
                validateDataSubmissionQuestion: '@',
                validateDataSubmissionQuestionHandle: '@',
                validateDataSubmissionAnswers: '=',
                validateDataSubmissionAnswersBeforeChanges: '='
            },
            link: function($scope, $elem, attrs, ctrl) {
                var validationMessages = {
                    required :'This field is mandatory',
                    rule: 'Please enter a valid positive numeric value',
                    percentageDiff: 'The value entered is significantly different from the previous entry. If correct, please provide an explanation in the messages box <i class="fa fa-arrow-right" aria-hidden="true"></i>',
                    floatDecimal: 'Please enter a numeric value with up to 2 decimal places',
                    hcMismatch: 'Headcount stands for the sum of Full Time + Part Time',
                    maxLimit: 'Please enter a number less than or equal to 195',
                    minPercentage: 'Please enter a number greater than or equal to 0',
                    maxPercentage: 'Please enter a number less than or equal to 100',
                    staffWithPhdVSFacultyStaff : 'Staff with PhD can not be greater than Faculty Staff',
                    facultyStaffVSStudentsOverall : 'Faculty Staff can not be greater than Students Overall',
                    postgraduateStudentsIsEmpty :'If Undergraduate Students is provided then Graduate/Postgraduate Students should be provided at least one of the headers (FT, PT, HC or FTE) should be filled else, please provide an explanation in the messages box <i class="fa fa-arrow-right" aria-hidden="true"></i>',
                    undergraduateStudentsIsEmpty:'If Graduate/Postgraduate Students is provided then Undergraduate Students should be provided at least one of the headers (FT, PT, HC or FTE) should be filled else, please provide an explanation in the messages box <i class="fa fa-arrow-right" aria-hidden="true"></i>',
                    sumOfUgAndPgInternational:'The sum of Undergraduate International Students and Postgraduate International Students should be equal to Overall International Students',
                    sumOfUgAndPgInboundOverall:'The sum of Undergraduate Exchange Students - Inbound and Postgraduate Inbound Exchange Students should be equal to Overall Exchange Students - Inbound',
                    sumOfUgAndPgOutboundOverall:'The sum of Undergraduate Exchange Students - Outbound and Postgraduate Outbound Exchange Students should be equal to Overall Exchange Students - Outbound',
                    sumOfUgAndPgOverall:'The sum of Undergraduate Overall Students and Postgraduate Overall Students should be equal to Overall Students',
                    sumOfAllFacultyStaff:'The sum of Faculty Staff Male, Faculty Staff Female and Faculty Staff Other should be equal to Faculty Staff',
                    sumOfAllStudents:'The sum of Students Male, Students Female and Students Other should be equal to Students-Overall'
                }, localValue;

                $scope.$on(broadcast.saveCompleted, function () {
                    // remove all validation popups and styles
                    $elem.context.parentElement.className = $elem.context.parentElement.className.replace(' has-error', '');
                    $elem.context.parentElement.className = $elem.context.parentElement.className.replace(' has-warning', '');
                    $($elem).data('bs.popover').options.content = '';
                    $($elem).popover('hide');
                });

                function isValidHeadCount() {
                    if((!$scope.validateDataSubmissionFt  && parseInt($scope.validateDataSubmissionFt) !== 0) ||
                        (!$scope.validateDataSubmissionPt  && parseInt($scope.validateDataSubmissionPt) !== 0) ||
                        (!$scope.validateDataSubmissionValue  && parseInt($scope.validateDataSubmissionValue) !== 0)){
                        return true;
                    }
                    var ftParsed = parseInt($scope.validateDataSubmissionFt, 10),
                        ptParsed = parseInt($scope.validateDataSubmissionPt, 10),
                        submissionValueParsed = parseInt($scope.validateDataSubmissionValue, 10),
                        ftValue = angular.isNumber(ftParsed) && !isNaN(ftParsed) ? ftParsed : 0,
                        ptValue = angular.isNumber(ptParsed) && !isNaN(ptParsed) ? ptParsed : 0,
                        submissionValue = angular.isNumber(submissionValueParsed) && !isNaN(submissionValueParsed) ? submissionValueParsed : 0,
                        ftPtSum = ftValue + ptValue;
                    return ftPtSum === submissionValue;
                }

                var addClass = function ($elem, valid, rule, ctrl, isFloatDecimal, errorMessage) {
                    $($elem).data('bs.popover').options.content = '';
                    $($elem).popover('hide');
                    var type = rule.hcMismatch ? 'hcMismatch' : 'percentageDiff';
                    if (rule.hasOwnProperty('rule') && rule.rule === 'float') {
                        type = 'rule';
                        if (!valid && !isFloatDecimal) {
                            type = 'floatDecimal';
                        }
                        if (rule.hasOwnProperty('nullable') && !rule.nullable) {
                            type = 'rule';
                        }
                    }
                    if (rule.hasOwnProperty('rule') && rule.rule === 'maxLimit') {
                        type = 'rule';
                        if (!valid && !isFloatDecimal) {
                            type = 'maxLimit';
                        }
                    }
                    if (rule.hasOwnProperty('rule') && rule.rule === 'maxPercentage') {
                        type = 'rule';
                        if (!valid && !isFloatDecimal) {
                            type = 'maxPercentage';
                        }
                    }
                    if (rule.hasOwnProperty('rule') && rule.rule === 'comparision') {
                        type = rule.ruleType;
                    }
                    if (rule.hasOwnProperty('rule') && rule.rule === 'required') {
                        type = rule.rule;
                    }
                    if (!angular.isDefined(ctrl[0].invalidItems)) {
                        ctrl[0].invalidItems = {};
                        ctrl[0].invalidIndicators = {};
                        StatisticsSubmissionsFactory.resetInvalidIndicators();
                    }
                    if (valid) {
                        $elem.context.parentElement.className = $elem.context.parentElement.className.replace(' has-error', '');
                        $elem.context.parentElement.className = $elem.context.parentElement.className.replace(' has-warning', '');
                       // if (rule.type === 'error') {
                            delete(ctrl[0].invalidItems[$elem.context.$$hashKey]);
                            if ($('#pt_column_' + $scope.validateDataSubmissionQuestionHandle).parent().prop('className').indexOf(' has-error') === -1 &&
                                $('#ft_column_' + $scope.validateDataSubmissionQuestionHandle).parent().prop('className').indexOf(' has-error') === -1 &&
                                $('#fte_column_' + $scope.validateDataSubmissionQuestionHandle).parent().prop('className').indexOf(' has-error') === -1) {
                                    delete(ctrl[0].invalidIndicators[$scope.validateDataSubmissionQuestionHandle]);
                                    StatisticsSubmissionsFactory.setInvalidIndicators($scope.validateDataSubmissionQuestionHandle, false);

                            } 
                       // }
                    } else {
                        var hcValid = true;
                        if ($elem.context.parentElement.className.indexOf(' has-' + rule.type) === -1) {
                            $elem.context.parentElement.className += ' has-' + rule.type;
                        }
                        if (rule.hasOwnProperty('hcMismatchWarning') && rule.hcMismatchWarning === true && ($scope.validateDataSubmissionValueType !== 'pt' && $scope.validateDataSubmissionValueType !== 'ft')) {
                            if ($elem.context.name.indexOf('hc_column') !== -1) {
                                hcValid = isValidHeadCount();
                            }
                        }
                        
                        if(errorMessage) {
                            $($elem).data('bs.popover').options.content = errorMessage;
                        }
                        else {
                            $($elem).data('bs.popover').options.content = validationMessages[type];
                        }
                        if (hcValid === false) {
                            $($elem).data('bs.popover').options.content += '<br>' + validationMessages.hcMismatch;
                            $elem.context.parentElement.className += ' has-error';
                            ctrl[0].invalidItems[$elem.context.$$hashKey] = !valid;
                            ctrl[0].invalidIndicators[$scope.validateDataSubmissionQuestionHandle] = !valid;
                            StatisticsSubmissionsFactory.setInvalidIndicators($scope.validateDataSubmissionQuestionHandle, !valid);
                        }
                        $($elem).popover('toggle');
                       
                        if (rule.type === 'error') {
                            ctrl[0].invalidItems[$elem.context.$$hashKey] = !valid;
                            ctrl[0].invalidIndicators[$scope.validateDataSubmissionQuestionHandle] = !valid;
                            StatisticsSubmissionsFactory.setInvalidIndicators($scope.validateDataSubmissionQuestionHandle, !valid);
                            
                        }
                    }
                };

                // init element popover
                $($elem).popover({
                    trigger: 'hover',
                    html: true,
                    content: '',
                    container: '',
                    placement: 'bottom'
                });

                var changeObjStructure = function (obj) {
                    var newObj = {};
                    if (obj.ft) {
                        newObj.ft = {
                            value: obj.ft
                        };
                    }
                    if (obj.pt) {
                        newObj.pt = {
                            value: obj.pt
                        };
                    }
                    if (obj.hc) {
                        newObj.hc = {
                            value: obj.hc
                        };
                    }
                    if (obj.fte) {
                        newObj.fte = {
                            value: obj.fte
                        };
                    }

                    return newObj;
                };

                function isValidRatioVarianceCheck(currentValue, previousValue, rule, isvalid, isSwapped, ratioMessage) {
                    if(!isvalid){

                        return isvalid;
                    }
                    var previousRatio = null,
                    currentRatio = null,
                    resultRatio = null,
                    valid = true;
                    // console.log('currentValue==',currentValue)
                    // console.log('previousValue==',previousValue)
                    // console.log('ratioMessage==',ratioMessage)
                    // console.log('validateDataSubmissionAnswersBeforeChanges==',$scope.validateDataSubmissionAnswersBeforeChanges)
                   // console.log('validateDataSubmissionAnswers==',$scope.validateDataSubmissionAnswers)
                    if (!StatisticsSubmissionsService.isEmpty(currentValue) && !StatisticsSubmissionsService.isEmpty(previousValue)) {
                        if (isSwapped) {
                            previousRatio = StatisticsSubmissionsService.getRatio($scope.validateDataSubmissionAnswersBeforeChanges, previousValue, isSwapped);
                            currentRatio = StatisticsSubmissionsService.getRatio($scope.validateDataSubmissionAnswers, currentValue, isSwapped);
                        }
                        else{
                            previousRatio = StatisticsSubmissionsService.getRatio(previousValue, $scope.validateDataSubmissionAnswersBeforeChanges);
                            currentRatio = StatisticsSubmissionsService.getRatio(currentValue, $scope.validateDataSubmissionAnswers);
                        }
                        if (previousRatio && currentRatio) {
                            resultRatio = rule.ratioVarianceCheck === 5? ((currentRatio - previousRatio) / previousRatio) * 100 : (currentRatio - previousRatio);
                            resultRatio = resultRatio < 0 ? (resultRatio *= -1) : resultRatio;
                            // console.log('currentRatio==',currentRatio)
                            // console.log('previousRatio==',previousRatio)
                            // console.log('resultRatio*****',resultRatio)
                            // console.log('ratioVarianceCheck------',rule.ratioVarianceCheck)
                            // console.log('Result------',resultRatio < rule.ratioVarianceCheck)
                            // console.log('++++++++++++++++++++++++++++++++++++++++++++++++++')
                            valid = resultRatio < rule.ratioVarianceCheck;
                            if ($scope.validateDataSubmissionQuestion === 'true') {
                                valid = true;
                            }
                            var errorMessage =ratioMessage+ ' is significantly different from the previous entry. If correct, please provide an explanation in the messages box <i class="fa fa-arrow-right" aria-hidden="true"></i>';
                            
                            addClass($elem, valid, rule, ctrl, false, errorMessage);
                        }
                    }
                    return valid;
                }

                $elem.on('keyup', function () {
                    var valid = false, runUpdateMessage = false;
                    if ($scope.validateDataSubmissionValueType === 'pt' || $scope.validateDataSubmissionValueType === 'ft' || $scope.validateDataSubmissionValueType === 'hc') {
                        if ($scope.validateDataSubmissionAnswers && ($scope.validateDataSubmissionFt || $scope.validateDataSubmissionPt)) {
                            var ftParsed = parseInt($scope.validateDataSubmissionFt, 10),
                                ptParsed = parseInt($scope.validateDataSubmissionPt, 10),
                                ftValue = angular.isNumber(ftParsed) && !isNaN(ftParsed) ? ftParsed : 0,
                                ptValue = angular.isNumber(ptParsed) && !isNaN(ptParsed) ? ptParsed : 0;

                            if ($scope.validateDataSubmissionValueType === 'hc') {
                                $scope.validateDataSubmissionValue = $scope.validateDataSubmissionAnswers.hc;
                            } else {
                               
                                if($scope.validateDataSubmissionValueType+'_column_'+$location.search().question ===  $elem.context.id) {
                                   $scope.validateDataSubmissionAnswers.hc = ftValue + ptValue;
                                   
                                }
                            }
                        }

                    }

                    // Set compare values
                    if ($scope.validateDataSubmissionQuestionHandle === 'overall_students') {
                        if (!$scope.validateDataSubmissionAnswers.pt &&
                            !$scope.validateDataSubmissionAnswers.ft &&
                            !$scope.validateDataSubmissionAnswers.hc &&
                            !$scope.validateDataSubmissionAnswers.fte) {
                            StatisticsSubmissionsFactory.setOverallStudentsCurrentEmpty(true);
                            StatisticsSubmissionsFactory.setOverallStudentsCurrentAnswers({});
                        } else {
                            StatisticsSubmissionsFactory.setOverallStudentsCurrentEmpty(false);
                            StatisticsSubmissionsFactory.setOverallStudentsCurrentAnswers(changeObjStructure($scope.validateDataSubmissionAnswers));
                        }
                    }
                    if ($scope.validateDataSubmissionQuestionHandle === 'faculty_staff') {
                        if (!$scope.validateDataSubmissionAnswers.pt &&
                            !$scope.validateDataSubmissionAnswers.ft &&
                            !$scope.validateDataSubmissionAnswers.hc &&
                            !$scope.validateDataSubmissionAnswers.fte) {
                            StatisticsSubmissionsFactory.setFacultyStaffCurrentEmpty(true);
                            StatisticsSubmissionsFactory.setFacultyStaffCurrentAnswers({});
                        } else {
                            StatisticsSubmissionsFactory.setFacultyStaffCurrentEmpty(false);
                            StatisticsSubmissionsFactory.setFacultyStaffCurrentAnswers( changeObjStructure($scope.validateDataSubmissionAnswers));
                        }
                    }
                    if ($scope.validateDataSubmissionQuestionHandle === 'staff_with_phd') {
                        StatisticsSubmissionsFactory.setStaffWithPhdCurrentAnswers(changeObjStructure($scope.validateDataSubmissionAnswers));
                    }
                    // if ($scope.validateDataSubmissionQuestionHandle === 'undergraduate_international_students' ||
                    //     $scope.validateDataSubmissionQuestionHandle === 'graduate_postgraduate_international_students' ||
                    //     $scope.validateDataSubmissionQuestionHandle === 'overall_international_students') {
                    //     // StatisticsSubmissionsFactory.setCurrentAnswer($scope.validateDataSubmissionQuestionHandle, changeObjStructure($scope.validateDataSubmissionAnswers));
                    // }
                    StatisticsSubmissionsFactory.setCurrentAnswer($scope.validateDataSubmissionQuestionHandle, changeObjStructure($scope.validateDataSubmissionAnswers));
                    if ($scope.validateDataSubmissionQuestionHandle === 'undergraduate_students') {
                        StatisticsSubmissionsFactory.setUgStudentsCurrentAnswers(changeObjStructure($scope.validateDataSubmissionAnswers));
                        setStudentsOverAll();
                    }
                    if ($scope.validateDataSubmissionQuestionHandle === 'graduate_postgraduate_students') {
                        StatisticsSubmissionsFactory.setPgStudentsCurrentAnswers(changeObjStructure($scope.validateDataSubmissionAnswers));
                        setStudentsOverAll();
                    }
                    if ($scope.validateDataSubmissionQuestionHandle === 'undergraduate_international_students' || $scope.validateDataSubmissionQuestionHandle === 'graduate_postgraduate_international_students') {
                        setStudentsOverAllInternational();
                    }

                    if ($scope.validateDataSubmissionQuestionHandle === 'undergraduate_exchange_students_inbound' || $scope.validateDataSubmissionQuestionHandle === 'graduate_postgraduate_exchange_students_inbound') {
                        setOverAll('exchange_students_inbound', 'graduate_postgraduate_exchange_students_inbound', 'undergraduate_exchange_students_inbound');
                    }

                    if ($scope.validateDataSubmissionQuestionHandle === 'undergraduate_exchange_students_outbound' || $scope.validateDataSubmissionQuestionHandle === 'graduate_postgraduate_exchange_students_outbound') {
                        setOverAll('exchange_students_outbound', 'graduate_postgraduate_exchange_students_outbound', 'undergraduate_exchange_students_outbound');
                    }

                    if (typeof $scope.validateDataSubmissionRules === 'string') {
                        $scope.validateDataSubmissionRules = JSON.parse($scope.validateDataSubmissionRules);
                    }
                    if (angular.isDefined($scope.validateDataSubmissionValue)) {
                        localValue = $scope.validateDataSubmissionValue;
                    }
                    angular.forEach($scope.validateDataSubmissionRules, function (rule) {
                        if (rule.type === "error" && !$scope.validateDataSubmissionValue){
                            if ($elem.context.parentElement.classList.contains('has-error')) {
                                $elem.context.parentElement.className = $elem.context.parentElement.className.replace(' has-error', '');
                                delete(ctrl[0].invalidItems[$elem.context.$$hashKey]);
                                $($elem).popover('hide');
                            }
                            if(rule.rule === 'required') {
                                addClass(
                                    $elem,
                                    false,
                                    rule,
                                    ctrl
                                );
                            }
                            $('input[name="' + $scope.validateDataSubmissionHc + '"]').triggerHandler('keyup');
                            return;
                        }
                        // validate float
                        if (rule.hasOwnProperty('rule') && rule.rule === 'float') {
                            var isValidFloat = StatisticsValidationService.isValidFloat(localValue, rule),
                                isValidDecimals = StatisticsValidationService.isValidDecimals(localValue, rule);
                            addClass(
                                $elem,
                                isValidFloat,
                                rule,
                                ctrl,
                                isValidDecimals
                            );
                            valid = isValidFloat && isValidDecimals;
                        }
                         // validate max limit
                        if (valid && rule.hasOwnProperty('rule') && rule.rule === 'maxLimit') {
                            localValue = parseFloat(localValue);
                            valid = localValue <= rule.value;
                            addClass(
                                $elem,
                                valid,
                                rule,
                                ctrl
                            );
                        }
                         // validate max percentage
                        if (valid && rule.hasOwnProperty('rule') && rule.rule === 'maxPercentage') {
                            localValue = parseFloat(localValue);
                            valid = localValue <= rule.value;
                            addClass(
                                $elem,
                                valid,
                                rule,
                                ctrl
                            );
                        }
                        // validate ratio variance check
                         if ((valid) && rule.hasOwnProperty('ratioVarianceCheck') && rule.ratioVarianceCheck > 0) {
                            if ($scope.validateDataSubmissionAnswersBeforeChanges.pt ||
                                $scope.validateDataSubmissionAnswersBeforeChanges.ft ||
                                $scope.validateDataSubmissionAnswersBeforeChanges.hc ||
                                $scope.validateDataSubmissionAnswersBeforeChanges.fte) {
                                var currentValue = null,
                                    validRatioCheck = true,
                                    previousValue = null,
                                    ratioMessage = null;
                                if ($scope.validateDataSubmissionQuestionHandle === 'staff_with_phd' ||
                                    $scope.validateDataSubmissionQuestionHandle === 'international_faculty_staff') {
                                    // Staff with PhD to Overall Faculty Staff (SwP) 5%
                                    // Overall International Faculty to Overall Students (IFR) 0.03%
                                    rule.ratioVarianceCheck = $scope.validateDataSubmissionQuestionHandle === 'staff_with_phd' ? 5 : 0.03;
                                    ratioMessage = $scope.validateDataSubmissionQuestionHandle === 'staff_with_phd' ? 'The Staff with PhD ratio' : 'The International faculty ratio';
                                    currentValue = StatisticsSubmissionsFactory.getCurrentAnswer('faculty_staff');
                                    previousValue = StatisticsSubmissionsFactory.getPreviousAnswer('faculty_staff');
                                    valid = validRatioCheck = isValidRatioVarianceCheck(currentValue, previousValue, rule, validRatioCheck, false, ratioMessage);
                                }
                                else  {
                                    if ($scope.validateDataSubmissionQuestionHandle === 'faculty_staff') {
                                        // Staff with PhD to Overall Faculty Staff (SwP) 5%
                                        currentValue = StatisticsSubmissionsFactory.getCurrentAnswer('staff_with_phd');
                                        previousValue = StatisticsSubmissionsFactory.getPreviousAnswer('staff_with_phd');
                                        ratioMessage = 'The Staff with PhD ratio';
                                        valid = validRatioCheck = isValidRatioVarianceCheck(currentValue, previousValue, rule, validRatioCheck, true, ratioMessage);

                                        // Overall International Faculty to Overall Students (IFR) 0.03%
                                        currentValue = StatisticsSubmissionsFactory.getCurrentAnswer('international_faculty_staff');
                                        previousValue = StatisticsSubmissionsFactory.getPreviousAnswer('international_faculty_staff');
                                        rule.ratioVarianceCheck = 0.03;
                                        ratioMessage = 'The International faculty ratio';
                                        valid = validRatioCheck = isValidRatioVarianceCheck(currentValue, previousValue, rule, validRatioCheck, true, ratioMessage);
                                    } 
                                    // StatisticsSubmissionsService.isEmpty(StatisticsSubmissionsFactory.getCurrentAnswer('overall_students'))
                                    // StatisticsSubmissionsService.isEmpty(StatisticsSubmissionsFactory.getPreviousAnswer('overall_students'))
                                    if (StatisticsSubmissionsFactory.isOverallStudentsCurrentEmpty() || StatisticsSubmissionsFactory.isOverallStudentsPreviousIsEmpty()) {
                                        
                                        // if($scope.validateDataSubmissionQuestionHandle === 'overall_international_students'){
                                        //     currentValue = StatisticsSubmissionsService.getUgAndPgStudentsCurrentAnswers(false, {current: true});
                                        //     previousValue = StatisticsSubmissionsService.getUgAndPgStudentsCurrentAnswers(false, {current: false});
                                        // }
                                        // else if($scope.validateDataSubmissionQuestionHandle === 'exchange_students_inbound'){
                                        //     currentValue = StatisticsSubmissionsService.getUgAndPgStudentsCurrentAnswers(false, false, {current: true});
                                        //     previousValue = StatisticsSubmissionsService.getUgAndPgStudentsCurrentAnswers(false, false, {current: false});
                                        // }
                                        // else if($scope.validateDataSubmissionQuestionHandle === 'exchange_students_outbound'){
                                        //     currentValue = StatisticsSubmissionsService.getUgAndPgStudentsCurrentAnswers(false, false, false,{current: true});
                                        //     previousValue = StatisticsSubmissionsService.getUgAndPgStudentsCurrentAnswers(false, false, false,{current: false});
                                        // }
                                        // else {
                                            currentValue = StatisticsSubmissionsService.getUgAndPgStudentsCurrentAnswers({current: true});
                                            previousValue = StatisticsSubmissionsService.getUgAndPgStudentsCurrentAnswers({current: false});
                                       // }

                                    } else {
                                        currentValue = StatisticsSubmissionsFactory.getOverallStudentsCurrentAnswers();
                                    }

                                    previousValue = StatisticsSubmissionsFactory.isOverallStudentsPreviousIsEmpty() || StatisticsSubmissionsFactory.isOverallStudentsCurrentEmpty() ?
                                                    previousValue : StatisticsSubmissionsFactory.getOverallStudentsAnswers();
                                    if ($scope.validateDataSubmissionQuestionHandle === 'overall_students') {
                                        // Overall Faculty to Overall Students (FSR) 5%
                                        currentValue = StatisticsSubmissionsFactory.getCurrentAnswer('faculty_staff');
                                        previousValue = StatisticsSubmissionsFactory.getPreviousAnswer('faculty_staff');
                                        rule.ratioVarianceCheck = 5;
                                        ratioMessage = 'The Faculty student ratio';
                                        valid = validRatioCheck = isValidRatioVarianceCheck(currentValue, previousValue, rule, validRatioCheck, true, ratioMessage);
                                         // Overall International Students to Overall Students (ISR) 3%
                                        if (StatisticsSubmissionsService.isEmpty(StatisticsSubmissionsFactory.getCurrentAnswer('overall_international_students')) ||
                                            StatisticsSubmissionsService.isEmpty(StatisticsSubmissionsFactory.getPreviousAnswer('overall_international_students'))) {
                                            currentValue = StatisticsSubmissionsService.getUgAndPgStudentsCurrentAnswers(false, {
                                                current: true
                                            });
                                            previousValue = StatisticsSubmissionsService.getUgAndPgStudentsCurrentAnswers(false, {
                                                current: false
                                            });
                                        } else {
                                            currentValue = StatisticsSubmissionsFactory.getCurrentAnswer('overall_international_students');
                                            previousValue = StatisticsSubmissionsFactory.getPreviousAnswer('overall_international_students');

                                        }
                                        rule.ratioVarianceCheck = 0.03;
                                        ratioMessage = 'The International students ratio';
                                        valid = validRatioCheck = isValidRatioVarianceCheck(currentValue, previousValue, rule, validRatioCheck, true, ratioMessage);
                                        // Overall Inbound Exchange Students to Overall Students (IBX) 1%
                                        if (StatisticsSubmissionsService.isEmpty(StatisticsSubmissionsFactory.getCurrentAnswer('exchange_students_inbound')) ||
                                            StatisticsSubmissionsService.isEmpty(StatisticsSubmissionsFactory.getPreviousAnswer('exchange_students_inbound'))) {
                                            currentValue = StatisticsSubmissionsService.getUgAndPgStudentsCurrentAnswers(false, false, {
                                                current: true
                                            });
                                            previousValue = StatisticsSubmissionsService.getUgAndPgStudentsCurrentAnswers(false, false, {
                                                current: false
                                            });
                                        } else {
                                            currentValue = StatisticsSubmissionsFactory.getCurrentAnswer('exchange_students_inbound');
                                            previousValue = StatisticsSubmissionsFactory.getPreviousAnswer('exchange_students_inbound');

                                        }
                                        rule.ratioVarianceCheck = 0.01;
                                        ratioMessage = 'The Inbound exchange students ratio';
                                        valid = validRatioCheck = isValidRatioVarianceCheck(currentValue, previousValue, rule, validRatioCheck, true, ratioMessage);
                                        // Overall Outbound Exchange Students to Overall Students (OBX) 1%
                                        if (StatisticsSubmissionsService.isEmpty(StatisticsSubmissionsFactory.getCurrentAnswer('exchange_students_outbound')) ||
                                            StatisticsSubmissionsService.isEmpty(StatisticsSubmissionsFactory.getPreviousAnswer('exchange_students_outbound'))) {
                                            currentValue = StatisticsSubmissionsService.getUgAndPgStudentsCurrentAnswers(false, false, false, {
                                                current: true
                                            });
                                            previousValue = StatisticsSubmissionsService.getUgAndPgStudentsCurrentAnswers(false, false, false, {
                                                current: false
                                            });
                                        } else {
                                            currentValue = StatisticsSubmissionsFactory.getCurrentAnswer('exchange_students_outbound');
                                            previousValue = StatisticsSubmissionsFactory.getPreviousAnswer('exchange_students_outbound');

                                        }
                                        rule.ratioVarianceCheck = 0.01;
                                        ratioMessage = 'The Outbound exchange students ratio';
                                        valid = validRatioCheck = isValidRatioVarianceCheck(currentValue, previousValue, rule, validRatioCheck, true, ratioMessage);
                                    } 
                                    if ($scope.validateDataSubmissionQuestionHandle === 'faculty_staff') {
                                        rule.ratioVarianceCheck = 5;
                                        ratioMessage = 'The Faculty student ratio';
                                        valid = validRatioCheck = isValidRatioVarianceCheck(currentValue, previousValue, rule, validRatioCheck, false, ratioMessage);
                                    }
                                    if ($scope.validateDataSubmissionQuestionHandle === 'staff_with_phd') {
                                        rule.ratioVarianceCheck = 5;
                                        ratioMessage = 'The Staff with PhD ratio';
                                        valid = validRatioCheck = isValidRatioVarianceCheck(currentValue, previousValue, rule, validRatioCheck, false, ratioMessage);
                                    }
                                    if ($scope.validateDataSubmissionQuestionHandle === 'international_faculty_staff') {
                                        rule.ratioVarianceCheck = 0.03;
                                        ratioMessage = 'The International faculty ratio';
                                        valid = validRatioCheck = isValidRatioVarianceCheck(currentValue, previousValue, rule, validRatioCheck, false, ratioMessage);
                                    }
                                    if ($scope.validateDataSubmissionQuestionHandle === 'overall_international_students') {
                                        rule.ratioVarianceCheck = 0.03;
                                        ratioMessage = 'The International students ratio';
                                        valid = validRatioCheck = isValidRatioVarianceCheck(currentValue, previousValue, rule, validRatioCheck, false, ratioMessage);
                                    }
                                    if ($scope.validateDataSubmissionQuestionHandle === 'exchange_students_inbound') {
                                        rule.ratioVarianceCheck = 0.01;
                                        ratioMessage = 'The Inbound exchange students ratio';
                                        valid = validRatioCheck = isValidRatioVarianceCheck(currentValue, previousValue, rule, validRatioCheck, false, ratioMessage);
                                    }
                                    if ($scope.validateDataSubmissionQuestionHandle === 'exchange_students_outbound') {
                                        rule.ratioVarianceCheck = 0.01;
                                        ratioMessage = 'The Outbound exchange students ratio';
                                        valid = validRatioCheck = isValidRatioVarianceCheck(currentValue, previousValue, rule, validRatioCheck, false, ratioMessage);
                                    }
                                }
                            }
                        }
                        // validate percentage if float validation passed
                        if (valid && rule.hasOwnProperty('percentageDiff') && rule.percentageDiff > 0) {
                            if ($scope.validateDataSubmissionOriginal > 0) {
                                localValue = parseFloat(localValue);
                                var compareValue = parseInt(localValue);
                                var upper = parseInt($scope.validateDataSubmissionOriginal) * (1 + (rule.percentageDiff / 100)),
                                    lower = parseInt($scope.validateDataSubmissionOriginal) * (1 - (rule.percentageDiff / 100));
                                valid = !(compareValue > upper || compareValue < lower);
                            }
                            if ($scope.validateDataSubmissionQuestion === 'true') {
                                valid = true;

                            }
                            addClass($elem, valid, rule, ctrl);
                        }
                        if (valid && rule.hcMismatch && ($scope.validateDataSubmissionValueType !== 'ft' && $scope.validateDataSubmissionValueType !== 'pt')) {
                            valid = isValidHeadCount();
                            addClass($elem, valid, rule, ctrl);
                        } else if (angular.isDefined($scope.validateDataSubmissionHc)) {
                           runUpdateMessage = true;
                        }
                    });
                    if(StatisticsSubmissionsFactory.getCurrentRanking() !== 'esg' && StatisticsSubmissionsFactory.getCurrentRanking() !== 'ger') {
                        StatisticsSubmissionsService.handleComparision($scope.validateDataSubmissionQuestionHandle);
                        StatisticsSubmissionsService.handleSum($scope.validateDataSubmissionQuestionHandle);
                    }
                    if (runUpdateMessage) {
                       $('input[name="' + $scope.validateDataSubmissionHc + '"]').triggerHandler('keyup');
                    }
                });

                function overallStudentsEmptyWatch(value) {
                    if ($scope.validateDataSubmissionQuestionHandle === 'graduate_postgraduate_students' ||
                        $scope.validateDataSubmissionQuestionHandle === 'undergraduate_students') {
                            if (typeof $scope.validateDataSubmissionRules === 'string') {
                                $scope.validateDataSubmissionRules = JSON.parse($scope.validateDataSubmissionRules);
                            }
                            $scope.validateDataSubmissionRules[1].type =  value ? 'error' : 'warning';
                    }
                }

                function getExplainationProvidedQuestionWatch(value, oldValue) {
                   
                    if ($scope.validateDataSubmissionQuestionHandle === 'faculty_staff' ||
                        $scope.validateDataSubmissionQuestionHandle === 'overall_students' ||
                        $scope.validateDataSubmissionQuestionHandle === 'overall_international_students' ||
                        $scope.validateDataSubmissionQuestionHandle === 'international_faculty_staff' ||
                        $scope.validateDataSubmissionQuestionHandle === 'staff_with_phd' ||
                        $scope.validateDataSubmissionQuestionHandle === 'exchange_students_inbound' ||
                        $scope.validateDataSubmissionQuestionHandle === 'exchange_students_outbound' ||
                        $scope.validateDataSubmissionQuestionHandle === 'undergraduate_exchange_students_inbound' ||
                        $scope.validateDataSubmissionQuestionHandle === 'undergraduate_exchange_students_outbound' ||
                        $scope.validateDataSubmissionQuestionHandle === 'graduate_postgraduate_exchange_students_inbound' ||
                        $scope.validateDataSubmissionQuestionHandle === 'graduate_postgraduate_exchange_students_outbound' ||
                        $scope.validateDataSubmissionQuestionHandle === 'employers_connection_on_campus' ||
                        $scope.validateDataSubmissionQuestionHandle === 'employer_partnerships_question' ||
                        $scope.validateDataSubmissionQuestionHandle === 'graduate_postgraduate_students' ||
                        $scope.validateDataSubmissionQuestionHandle === 'undergraduate_students') {
                        if (StatisticsSubmissionsFactory.isExplainationProvided($scope.validateDataSubmissionQuestionHandle)) {
                            var rule = {
                                type: 'error'
                            };
                            if ($($elem).data('bs.popover').options.content !== validationMessages.staffWithPhdVSFacultyStaff &&
                                $($elem).data('bs.popover').options.content !== validationMessages.facultyStaffVSStudentsOverall && 
                                $($elem).data('bs.popover').options.content !== validationMessages.hcMismatch && 
                                $($elem).data('bs.popover').options.content !== validationMessages.sumOfUgAndPgInternational && 
                                $($elem).data('bs.popover').options.content !== validationMessages.sumOfUgAndPgInboundOverall && 
                                $($elem).data('bs.popover').options.content !== validationMessages.sumOfUgAndPgOutboundOverall && 
                                $($elem).data('bs.popover').options.content !== validationMessages.sumOfUgAndPgOverall && 
                                $($elem).data('bs.popover').options.content !== validationMessages.sumOfAllFacultyStaff && 
                                $($elem).data('bs.popover').options.content !== validationMessages.sumOfAllStudents) {
                                    
                                    if($($elem).data('bs.popover').options.content) {
                                        var newMessage = {
                                            institutionCoreId: InstitutionFactory.getCoreId(),
                                            institutionName: InstitutionFactory.getDisplayName(),
                                            message:'The user submission note provided below is for the following displayed variation message: <br>' +  $($elem).data('bs.popover').options.content,
                                            source: {
                                                question: $scope.validateDataSubmissionQuestionHandle,
                                                ranking: StatisticsSubmissionsFactory.getCurrentRanking()
                                            },
                                            subject: 'Variation log'
                                        };
                                       // MessagesService.sendMessage(newMessage);
                                        StatisticsSubmissionsFactory.setVariationLogMessage(newMessage);
                                        StatisticsSubmissionsFactory.setMessageSent(true);
                                    }
                                   
                                addClass($elem, true, rule, ctrl);
                            }
                        }
                    }
                }

                function overallStudentsAnswersCurrentWatch (value) {
                    $scope.overallStudentsAnswersCurrent = value;
                }

                function setStudentsOverAll() {
                    var overallStudentsOverall = StatisticsSubmissionsFactory.getCurrentAnswer('overall_students'),
                    pgStudentsOverall = StatisticsSubmissionsFactory.getCurrentAnswer('graduate_postgraduate_students'),
                    ugStudentsOverall = StatisticsSubmissionsFactory.getCurrentAnswer('undergraduate_students'),
                    ugAndPgOverall = {};

                    angular.forEach(['ft', 'pt', 'hc', 'fte'], function (fieldName) {
                        if ((pgStudentsOverall && pgStudentsOverall[fieldName]) || (ugStudentsOverall && ugStudentsOverall[fieldName])) {
                            ugAndPgOverall[fieldName] = {
                                value: (pgStudentsOverall && pgStudentsOverall[fieldName] ? parseFloat(pgStudentsOverall[fieldName].value) : 0) + (ugStudentsOverall && ugStudentsOverall[fieldName] ? parseFloat(ugStudentsOverall[fieldName].value) : 0)
                            };
                        }
                        if(fieldName === 'hc') {
                            if (!ugAndPgOverall[fieldName] || (ugAndPgOverall[fieldName] && !ugAndPgOverall[fieldName].value)) {
                                    overallStudentsOverall['ft'] = overallStudentsOverall['ft']? overallStudentsOverall['ft']:{};
                                    overallStudentsOverall['pt'] = overallStudentsOverall['pt']? overallStudentsOverall['pt']:{};
                                    overallStudentsOverall['hc'] = overallStudentsOverall['hc']? overallStudentsOverall['hc']:{};
                                    ugAndPgOverall['hc'] = ugAndPgOverall['hc']? ugAndPgOverall['hc']:{};

                                    var ftValue = overallStudentsOverall['ft'].value? overallStudentsOverall['ft'].value : 0;
                                    var ptValue = overallStudentsOverall['pt'].value? overallStudentsOverall['pt'].value : 0;

                                    var ftParsed = parseInt(ftValue, 10),
                                    ptParsed = parseInt(ptValue, 10),
                                    ftValue = angular.isNumber(ftParsed) && !isNaN(ftParsed) ? ftParsed : 0,
                                    ptValue = angular.isNumber(ptParsed) && !isNaN(ptParsed) ? ptParsed : 0;
                                    ugAndPgOverall['hc'].value = ftValue + ptValue;
                                    ugAndPgOverall['hc'].valueAsString =  ugAndPgOverall['hc'].value.toString();
                            }
                        }

                        if (ugAndPgOverall[fieldName] && ugAndPgOverall[fieldName].value) {
                            if (overallStudentsOverall && overallStudentsOverall[fieldName] && overallStudentsOverall[fieldName].value) {
                                if (parseFloat(overallStudentsOverall[fieldName].value).toFixed(2) !== ugAndPgOverall[fieldName].value.toFixed(2)) {
                                    overallStudentsOverall[fieldName].value = ugAndPgOverall[fieldName].value;
                                    overallStudentsOverall[fieldName].valueAsString = ugAndPgOverall[fieldName].value.toString();
                                   $timeout(function() {
                                        StatisticsSubmissionsFactory.setCurrentAnswer('overall_students', overallStudentsOverall);
                                        StatisticsSubmissionsFactory.setOverallStudentsCurrentAnswers(overallStudentsOverall);
                                    });
                                }

                            } else {
                                overallStudentsOverall[fieldName] = {};
                                overallStudentsOverall[fieldName].value = ugAndPgOverall[fieldName].value;
                                overallStudentsOverall[fieldName].valueAsString = ugAndPgOverall[fieldName].value.toString();
                                $timeout(function() {
                                    StatisticsSubmissionsFactory.setCurrentAnswer('overall_students', overallStudentsOverall);
                                    StatisticsSubmissionsFactory.setOverallStudentsCurrentAnswers(overallStudentsOverall);
                                });
                            }
                        } 
                        // else {
                        //     // service.removeValidationErrors('sumOfUgAndPgOverall', fieldName, $scope.validateDataSubmissionQuestionHandle);
                        // }
                    });
                }

                function  setStudentsOverAllInternational () {
                    var overallStudentsInternational = StatisticsSubmissionsFactory.getCurrentAnswer('overall_international_students'),
                        pgStudentsInternational = StatisticsSubmissionsFactory.getCurrentAnswer('graduate_postgraduate_international_students'),
                        ugStudentsInternational = StatisticsSubmissionsFactory.getCurrentAnswer('undergraduate_international_students'),
                        ugAndPgInternational = {};

                    angular.forEach(['ft', 'pt', 'hc', 'fte'], function (fieldName) {
                        if ((pgStudentsInternational && pgStudentsInternational[fieldName]) || (ugStudentsInternational && ugStudentsInternational[fieldName])) {
                            ugAndPgInternational[fieldName] = {
                                value: (pgStudentsInternational && pgStudentsInternational[fieldName] ? parseFloat(pgStudentsInternational[fieldName].value) : 0) + (ugStudentsInternational && ugStudentsInternational[fieldName] ? parseFloat(ugStudentsInternational[fieldName].value) : 0)
                            };
                        }
                        if(fieldName === 'hc') {
                            if (!ugAndPgInternational[fieldName] || (ugAndPgInternational[fieldName] && !ugAndPgInternational[fieldName].value)) {
                                overallStudentsInternational['ft'] = overallStudentsInternational['ft']? overallStudentsInternational['ft']:{};
                                overallStudentsInternational['pt'] = overallStudentsInternational['pt']? overallStudentsInternational['pt']:{};
                                overallStudentsInternational['hc'] = overallStudentsInternational['hc']? overallStudentsInternational['hc']:{};
                                ugAndPgInternational['hc'] = ugAndPgInternational['hc']? ugAndPgInternational['hc']:{};

                                    var ftValue = overallStudentsInternational['ft'].value? overallStudentsInternational['ft'].value : 0;
                                    var ptValue = overallStudentsInternational['pt'].value? overallStudentsInternational['pt'].value : 0;

                                    var ftParsed = parseInt(ftValue, 10),
                                    ptParsed = parseInt(ptValue, 10),
                                    ftValue = angular.isNumber(ftParsed) && !isNaN(ftParsed) ? ftParsed : 0,
                                    ptValue = angular.isNumber(ptParsed) && !isNaN(ptParsed) ? ptParsed : 0;
                                    ugAndPgInternational['hc'].value = ftValue + ptValue;
                                    ugAndPgInternational['hc'].valueAsString =  ugAndPgInternational['hc'].value.toString();
                            }
                        }

                        if (ugAndPgInternational[fieldName] && ugAndPgInternational[fieldName].value) {
                            if (overallStudentsInternational[fieldName] && overallStudentsInternational[fieldName].value) {
                                if (parseFloat(overallStudentsInternational[fieldName].value).toFixed(2) !== ugAndPgInternational[fieldName].value.toFixed(2)) {
                                    overallStudentsInternational[fieldName].value = ugAndPgInternational[fieldName].value;
                                    overallStudentsInternational[fieldName].valueAsString = ugAndPgInternational[fieldName].value.toString();
                                    $timeout(function() {
                                        StatisticsSubmissionsFactory.setCurrentAnswer('overall_international_students', overallStudentsInternational);
                                        StatisticsSubmissionsFactory.setInternationalOverallStudentsCurrentAnswers(overallStudentsInternational);
                                    });
                                }

                            } else {
                                overallStudentsInternational[fieldName] = {};
                                overallStudentsInternational[fieldName].value = ugAndPgInternational[fieldName].value;
                                overallStudentsInternational[fieldName].valueAsString = ugAndPgInternational[fieldName].value.toString();
                                $timeout(function() {
                                    StatisticsSubmissionsFactory.setCurrentAnswer('overall_international_students', overallStudentsInternational);
                                    StatisticsSubmissionsFactory.setInternationalOverallStudentsCurrentAnswers(overallStudentsInternational);
                                });
                            }
                        } 
                        // else {
                        //     // service.removeValidationErrors('sumOfUgAndPgInternational', fieldName, questionHandle);
                        // }
                    });
                }

                function setOverAll(overAll, pg, ug) {
                    var overAllValue = StatisticsSubmissionsFactory.getCurrentAnswer(overAll),
                        pgValue = StatisticsSubmissionsFactory.getCurrentAnswer(pg),
                        ugValue = StatisticsSubmissionsFactory.getCurrentAnswer(ug),
                        ugAndPgValue = {};

                    angular.forEach(['ft', 'pt', 'hc', 'fte'], function (fieldName) {
                        if ((pgValue && pgValue[fieldName]) || (ugValue && ugValue[fieldName])) {
                            ugAndPgValue[fieldName] = {
                                value: (pgValue && pgValue[fieldName] ? parseFloat(pgValue[fieldName].value) : 0) + (ugValue && ugValue[fieldName] ? parseFloat(ugValue[fieldName].value) : 0)
                            };
                        }
                        if(fieldName === 'hc') {
                            if (!ugAndPgValue[fieldName] || (ugAndPgValue[fieldName] && !ugAndPgValue[fieldName].value)) {
                                    overAllValue['ft'] = overAllValue['ft']? overAllValue['ft']:{};
                                    overAllValue['pt'] = overAllValue['pt']? overAllValue['pt']:{};
                                    overAllValue['hc'] = overAllValue['hc']? overAllValue['hc']:{};
                                    ugAndPgValue['hc'] = ugAndPgValue['hc']? ugAndPgValue['hc']:{};

                                    var ftValue = overAllValue['ft'].value? overAllValue['ft'].value : 0;
                                    var ptValue = overAllValue['pt'].value? overAllValue['pt'].value : 0;

                                    var ftParsed = parseInt(ftValue, 10),
                                    ptParsed = parseInt(ptValue, 10),
                                    ftValue = angular.isNumber(ftParsed) && !isNaN(ftParsed) ? ftParsed : 0,
                                    ptValue = angular.isNumber(ptParsed) && !isNaN(ptParsed) ? ptParsed : 0;
                                    ugAndPgValue['hc'].value = ftValue + ptValue;
                                    ugAndPgValue['hc'].valueAsString =  ugAndPgValue['hc'].value.toString();
                            }
                        }
                       
                        if (ugAndPgValue[fieldName] && ugAndPgValue[fieldName].value) {
                            if (overAllValue[fieldName] && overAllValue[fieldName].value) {
                                if (parseFloat(overAllValue[fieldName].value).toFixed(2) !== ugAndPgValue[fieldName].value.toFixed(2)) {
                                    overAllValue[fieldName].value = ugAndPgValue[fieldName].value;
                                    overAllValue[fieldName].valueAsString = ugAndPgValue[fieldName].value.toString();
                                    $timeout(function() {
                                        StatisticsSubmissionsFactory.setCurrentAnswer(overAll, overAllValue);
                                        if (overAll === 'exchange_students_inbound') {
                                            StatisticsSubmissionsFactory.setInboundOverallStudentsCurrentAnswers(overAllValue);
                                        }
                                        if (overAll === 'exchange_students_outbound') {
                                            StatisticsSubmissionsFactory.setOutboundOverallStudentsCurrentAnswers(overAllValue);
                                        }
                                    });


                                }

                            } else {
                                overAllValue[fieldName] = {};
                                overAllValue[fieldName].value = ugAndPgValue[fieldName].value;
                                overAllValue[fieldName].valueAsString = ugAndPgValue[fieldName].value.toString();
                                $timeout(function() {
                                    StatisticsSubmissionsFactory.setCurrentAnswer(overAll, overAllValue);
                                    if (overAll === 'exchange_students_inbound') {
                                        StatisticsSubmissionsFactory.setInboundOverallStudentsCurrentAnswers(overAllValue);
                                    }
                                    if (overAll === 'exchange_students_outbound') {
                                        StatisticsSubmissionsFactory.setOutboundOverallStudentsCurrentAnswers(overAllValue);
                                    }
                                });
                            }
                        }
                    });
                }

                WatchService.create($scope, StatisticsSubmissionsFactory.isOverallStudentsEmpty, overallStudentsEmptyWatch);
                WatchService.create($scope, StatisticsSubmissionsFactory.getOverallStudentsAnswersCurrent, overallStudentsAnswersCurrentWatch);
                WatchService.create($scope, StatisticsSubmissionsFactory.getExplainationProvidedQuestion, getExplainationProvidedQuestionWatch, true);
            }
        };
    };

    angular
        .module('qsHub.rankings')
        .directive('validateDataSubmission', [
            'broadcast',
            'StatisticsValidationService',
            'StatisticsSubmissionsFactory',
            'WatchService',
            'StatisticsSubmissionsService',
            '$location',
            '$timeout',
            'MessagesService',
            'InstitutionFactory',
            validateDataSubmission
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    var StatisticsValidation = function () {
        var service = {};

        function isFloat (value) {
            return Number(value) === value && value % 1 !== 0;
        }

        function isInt (value) {
            return Number(value) === value && value % 1 === 0;
        }

        function isNullable(rules) {
            return rules.hasOwnProperty('nullable') && rules.nullable;
        }

        function validateFormat(value) {
            return /^\d+(\.\d{1,2})?$/.test(value);
        }

        function validateFixed(value) {
            if (isFloat(value)) {
                return value && value.toFixed(2) === value;
            }
        }

        service.isValidDecimals = function (value) {
            return validateFixed && validateFormat(value);
        };

        service.isValidFloat = function (value, rules) {
            var floatValue = parseFloat(value);

            if (floatValue < 0) {
                return false;
            }

            if (floatValue) {
                if (!validateFormat(value)) {
                    return false;
                }
                // check if valid numeric value
                if (floatValue && !(isFloat(floatValue) || isInt(floatValue))) {
                    return false;
                }
                if (!isNullable(rules) && value.length === 0) {
                    return false;
                }
            } else {
                if (value.length === 0 && isNullable(rules)) {
                    return true;
                }

                return service.isValidDecimals(value);
            }
            return true;
        };

        return service;
    };

    angular
        .module('qsHub.rankings')
        .service('StatisticsValidationService', [
            StatisticsValidation
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    angular
        .module('qsHub.rankings')
        .controller('Rankings.StatisticsSubmissionsController', [
            '$state',
            '$filter',
            '$scope',
            '$location',
            '$stateParams',
            'broadcast',
            'WatchService',
            'StatisticsDashboardFactory',
            'InstitutionFactory',
            'StatisticsSubmissionsFactory',
            'RankingInfoFormService',
            'RankingsDashboardService',
            'StatisticsSubmissionsService',
            'NotifierFactory',
            'UserFactory',
            'UnsavedChangesFactory',
            'StatisticsHistoryFactory',
            'StatisticsHistoryService',
            '$timeout',
            'ClassificationsService',
            'ModalService',
            'TextService',
            StatisticsSubmissions
        ]);

    function StatisticsSubmissions(
        $state,
        $filter,
        $scope,
        $location,
        $stateParams,
        broadcast,
        WatchService,
        StatisticsDashboardFactory,
        InstitutionFactory,
        StatisticsSubmissionsFactory,
        RankingInfoFormService,
        RankingsDashboardService,
        StatisticsSubmissionsService,
        NotifierFactory,
        UserFactory,
        UnsavedChangesFactory,
        StatisticsHistoryFactory,
        StatisticsHistoryService,
        $timeout,
        ClassificationsService,
        ModalService,
        TextService
    ) {
        var controller = this;
        controller.subscribedStatistics = [];
        controller.statisticsAvailable = [];
        controller.rankingHandle = $state.$current.data.rankings;
        StatisticsSubmissionsFactory.setCurrentRanking($state.$current.data.rankings);
        controller.questions = {};
        controller.questionsHandles = [];
        controller.type = StatisticsDashboardFactory.getTypes();
        controller.validationMessages = StatisticsSubmissionsFactory.getValidationMessages();
        controller.answers = [];
        controller.loading = false;
        controller.questionId = null;
        controller.forms = {};
        controller.submitInProgress = false;
        controller.isClient = UserFactory.isClient();
        controller.dataToload = 4;
        controller.isTuSubscribed=false;
        controller.isComparisionInvalid = false;
        controller.sustainabilityHeaders = [{
            name: 'Environmental Impact',
            handle: 'environmental_impact',
            open: true
        }, {
            name: 'Social Impact',
            handle: 'social_impact',
            open: true
        }, {
            name: 'Governance',
            handle: 'governance',
            open: true
        }, {
            name: 'Additional Information',
            handle: 'additional_information',
            open: true
        }];

        controller.isLoading = function () {
            return controller.loading;
        };

        controller.getRankingsDataWatch = function (newValue) {
            controller.loading = true;
            if (newValue) {
                StatisticsSubmissionsFactory.resetInvalidIndicatorsData();
                StatisticsSubmissionsFactory.resetValidationErrors();
                controller.rankingsDataWatch();
                var i = 0,
                    item = null,
                    total = newValue.length;

                for (i; i < total; i++) {
                    item = newValue[i];
                    if (item.handle === controller.rankingHandle) {
                        controller.rankingDefinition = item.definition;
                        break;
                    }
                }
                controller.loading = false;
            }
        };

        controller.isValid = function () {
            if (controller.forms && controller.forms.statistics &&
                angular.isDefined(controller.forms.statistics.invalidItems) &&
                !angular.equals({}, controller.forms.statistics.invalidItems)
            ) {
                // NotifierFactory.show(
                //     'error',
                //     'Invalid data in the form',
                //     'Data Submission'
                // );
                UnsavedChangesFactory.setSaveError();
                return true;
            }

            return true;
        };

        controller.isSavingDisabled = function () {
            return !!controller.submitInProgress;
        };

        controller.saveCallback = function (response) {
            controller.submitInProgress = false;
            StatisticsHistoryFactory.setReload(true);
            StatisticsSubmissionsFactory.resetQuestionsOfChangedStatuses();
            var saveDataBeforeFlush = StatisticsSubmissionsFactory.getData();
            StatisticsSubmissionsFactory.resetData();
            UnsavedChangesFactory.resetChanges();
            if (controller.forms && controller.forms.statistics) {
                controller.forms.statistics.$setPristine();
            }
            if(!controller.formHasErrors) {
                // controller.loadAnswers(InstitutionFactory.getCoreId());
                $scope.$broadcast(broadcast.saveCompleted, true);
            }
            controller.loadAnswers(InstitutionFactory.getCoreId());
            // $scope.$broadcast(broadcast.saveCompleted, true);
            if ((response && ((response.insertCount || response.modifiedCount) && controller.formHasErrors) )) {
                StatisticsSubmissionsFactory.setHasChanges(true);
               
                NotifierFactory.show(
                    'error',
                    'Some of the data was not saved, please fix the errors for values highlighted in red and save again.',
                    'Data submission errors'
                );
                UnsavedChangesFactory.setIsSaved();
                StatisticsHistoryService.clearCache();
                StatisticsSubmissionsFactory.resetExplanationRequiredQuestions();
                calculateClassificationSizeField(saveDataBeforeFlush);
                StatisticsSubmissionsFactory.setEmploymentRateModified(false);
            }
            else if ((response && (response.insertCount || response.modifiedCount)) || StatisticsSubmissionsFactory.getEmploymentRateModified()) {
                StatisticsSubmissionsFactory.setHasChanges(true);
               
                NotifierFactory.show(
                    'success',
                    'Data submitted successfully',
                    'Data submission'
                );
                UnsavedChangesFactory.setIsSaved();
                StatisticsHistoryService.clearCache();
                StatisticsSubmissionsFactory.resetExplanationRequiredQuestions();
                calculateClassificationSizeField(saveDataBeforeFlush);
                StatisticsSubmissionsFactory.setEmploymentRateModified(false);
            } else if (response === false) {
                StatisticsSubmissionsFactory.setEmploymentRateModified(false);
                UnsavedChangesFactory.setSaveError();
                NotifierFactory.show(
                    'error',
                    'Error occurred, please try again later',
                    'Data Submission'
                );

            }
            $timeout(function() {
                UnsavedChangesFactory.clearIsSaved();
            });

        };
        
        controller.handleSaveClick = function () {
            if (controller.isClient && UnsavedChangesFactory.hasChanges()) {
                controller.isValid();
                // if (!controller.isValid()) {
                //     // return;
                // }
                var modalOptions = {
                    closeButtonText: 'Cancel',
                    actionButtonText: 'OK',
                    actionButtonClass: 'primary',
                    headerText: 'Data Submission',
                    bodyText: 'Unedited indicators will not be submitted. If you would like to submit unedited indicators as a new data, please follow the instructions above the table.'
                };
                ModalService.show({}, modalOptions).then(function () {
                    controller.handleSave();
                });
                
            }
            else {
                controller.handleSave();
            }
         };

         function compareValues(firstValue, secondValue, ruleType, secondRound, questionHandle) {
            if(!firstValue || (!firstValue.ft && !firstValue.pt && !firstValue.hc && !firstValue.fte)){
                return ;
            }
            var ugAndPg = StatisticsSubmissionsService.getUgAndPgStudentsCurrentAnswers({current: true}),
            validationErrorObj = {
                field: 'ft',
                rule: ruleType
            };
            if (ruleType === 'facultyStaffVSStudentsOverall') {
                if (StatisticsSubmissionsFactory.isOverallStudentsCurrentEmpty()) {
                    secondValue = ugAndPg;
                } else {
                    if ((!secondValue.fte && !secondValue.hc && !secondValue.pt && secondValue.ft) ||
                        (!secondValue.fte && !secondValue.hc && secondValue.pt && !secondValue.ft)) {
                        secondValue = ugAndPg;
                    }
                }
            }
            
            if (secondValue && (secondValue.ft || secondValue.pt || secondValue.hc || secondValue.fte)) {
                if (firstValue.fte && secondValue.fte) {
                    var firstFteParsed = parseInt(firstValue.fte.value, 10),
                        secondFteParsed = parseInt(secondValue.fte.value, 10),
                        firstFteValue = angular.isNumber(firstFteParsed) && !isNaN(firstFteParsed) ? firstFteParsed : 0,
                        secondFteValue = angular.isNumber(secondFteParsed) && !isNaN(secondFteParsed) ? secondFteParsed : 0;
                        validationErrorObj.field = 'fte';
                    if (firstFteValue > secondFteValue) {
                        controller.isComparisionInvalid = true;
                        controller.validationMessage = ruleType;
                        StatisticsSubmissionsService.handleSetValidation(ruleType, questionHandle);
                    } else {
                        StatisticsSubmissionsService.handleRemoveValidation(ruleType, questionHandle);
                    }

                } else if (firstValue.ft && firstValue.pt &&
                    secondValue.ft &&
                    secondValue.pt) {
                        var fFt = parseInt(firstValue.ft.value, 10),
                        fPt = parseInt(firstValue.pt.value, 10),
                        sFt = parseInt(secondValue.ft.value, 10),
                        sPt = parseInt(secondValue.pt.value, 10),
                        fFtValue = angular.isNumber(fFt) && !isNaN(fFt) ? fFt : 0,
                        fPtValue = angular.isNumber(fPt) && !isNaN(fPt) ? fPt : 0,
                        sFtValue = angular.isNumber(sFt) && !isNaN(sFt) ? sFt : 0,
                        sPtValue = angular.isNumber(sPt) && !isNaN(sPt) ? sPt : 0,
                        fCalculated = (fFtValue + fPtValue) / 3,
                        sCalculated = (sFtValue + sPtValue) / 3;
                        validationErrorObj.field = 'pt';
                        if (fCalculated > sCalculated) {
                            controller.isComparisionInvalid = true;
                            controller.validationMessage = ruleType;
                            StatisticsSubmissionsService.handleSetValidation(ruleType, questionHandle);
                        }else {
                            StatisticsSubmissionsService.handleRemoveValidation(ruleType, questionHandle);
                        }
                        
                } else if (firstValue.hc && secondValue.hc) {
                    var firstHcParsed = parseInt(firstValue.hc.value, 10),
                    secondHcParsed = parseInt(secondValue.hc.value, 10),
                    firstHcValue = angular.isNumber(firstHcParsed) && !isNaN(firstHcParsed) ? firstHcParsed : 0,
                    secondHcValue = angular.isNumber(secondHcParsed) && !isNaN(secondHcParsed) ? secondHcParsed : 0;
                    validationErrorObj.field = 'hc';
                    if (firstHcValue > secondHcValue) {
                        controller.isComparisionInvalid = true;
                        controller.validationMessage = ruleType;
                        StatisticsSubmissionsService.handleSetValidation(ruleType, questionHandle);
                    }else {
                        StatisticsSubmissionsService.handleRemoveValidation(ruleType, questionHandle);
                    }
                }
                else if (ruleType === 'facultyStaffVSStudentsOverall' && !secondRound){
                   compareValues(firstValue, ugAndPg, ruleType, true, questionHandle);
                } else {
                    StatisticsSubmissionsService.handleRemoveValidation(ruleType, questionHandle);
                }
            }
        }

        function handleComparision() {
            var overallStudents = StatisticsSubmissionsFactory.getCurrentAnswer('overall_students'),
                facultyStaff = StatisticsSubmissionsFactory.getCurrentAnswer('faculty_staff'),
                staffWithPHD = StatisticsSubmissionsFactory.getCurrentAnswer('staff_with_phd'),
                pgStudents = StatisticsSubmissionsFactory.getCurrentAnswer('graduate_postgraduate_students'),
                ugStudents = StatisticsSubmissionsFactory.getCurrentAnswer('undergraduate_students');

            angular.forEach(StatisticsSubmissionsFactory.getCompareableQuestions(), function (questionHandle) {
                // angular.forEach(['ft', 'pt', 'hc', 'fte'], function (fieldName) {
                    if (questionHandle === 'faculty_staff' || questionHandle === 'overall_students') {
                        compareValues(facultyStaff, overallStudents, 'facultyStaffVSStudentsOverall', false, questionHandle);
                        if (questionHandle === 'faculty_staff' &&
                            controller.rankingHandle !== 'wur' && controller.rankingHandle !== 'eeca' && controller.rankingHandle !== 'ger' && controller.rankingHandle !== 'esg') {
                            compareValues(staffWithPHD, facultyStaff, 'staffWithPhdVSFacultyStaff', false, questionHandle);
                        }
                    }
                    if (questionHandle === 'undergraduate_students' || questionHandle === 'graduate_postgraduate_students') {
                        compareValues(facultyStaff, overallStudents, 'facultyStaffVSStudentsOverall', false, questionHandle);
                    }
                    if (questionHandle === 'staff_with_phd' &&
                        controller.rankingHandle !== 'wur' && controller.rankingHandle !== 'eeca' && controller.rankingHandle !== 'ger' && controller.rankingHandle !== 'esg') {
                        compareValues(staffWithPHD, facultyStaff, 'staffWithPhdVSFacultyStaff', false, questionHandle);
                    }
                // });
                if (questionHandle === 'undergraduate_students') {
                    if (!StatisticsSubmissionsFactory.isExplainationProvided(questionHandle)) {
                        if (StatisticsSubmissionsService.isEmpty(ugStudents)) {
                            if (StatisticsSubmissionsService.isNotEmpty(pgStudents)) {
                                controller.validationMessage = 'undergraduateStudentsIsEmpty';
                                controller.isComparisionInvalid = true;
                                StatisticsSubmissionsService.setValidationErrors('undergraduateStudentsIsEmpty', 'ft', questionHandle, 2);
                               StatisticsSubmissionsService.setValidationErrors('undergraduateStudentsIsEmpty', 'ft', 'graduate_postgraduate_students', 2);
                            }
                        } else {
                            StatisticsSubmissionsService.removeValidationErrors('undergraduateStudentsIsEmpty', 'ft', questionHandle, 2);
                            StatisticsSubmissionsService.removeValidationErrors('undergraduateStudentsIsEmpty', 'ft', 'graduate_postgraduate_students', 2);
                        }
                        
                    }
                }
                if (questionHandle === 'graduate_postgraduate_students') {
                    if (!StatisticsSubmissionsFactory.isExplainationProvided(questionHandle)) {
                        if (StatisticsSubmissionsService.isEmpty(pgStudents)) {
                            if (StatisticsSubmissionsService.isNotEmpty(ugStudents)) {
                                controller.validationMessage = 'postgraduateStudentsIsEmpty';
                                controller.isComparisionInvalid = true;
                                StatisticsSubmissionsService.setValidationErrors('postgraduateStudentsIsEmpty', 'ft', questionHandle, 2);
                                StatisticsSubmissionsService.setValidationErrors('postgraduateStudentsIsEmpty', 'ft', 'undergraduate_students', 2);
                            }
                        } else {
                            StatisticsSubmissionsService.removeValidationErrors('postgraduateStudentsIsEmpty', 'ft', questionHandle, 2);
                            StatisticsSubmissionsService.removeValidationErrors('postgraduateStudentsIsEmpty', 'ft', 'undergraduate_students', 2);
                        }
                    }
                    
                }
            });
        }

        function setValidationErrors(rule, fieldName, overall) {
            if (overall) {
                angular.forEach(['undergraduate_students', 'graduate_postgraduate_students', 'overall_students'], function (questionHandle) {
                    StatisticsSubmissionsService.setValidationErrors(rule, fieldName, questionHandle);
                });
            } else {
                if(rule === 'sumOfUgAndPgInternational') {
                    angular.forEach(['overall_international_students', 'graduate_postgraduate_international_students', 'undergraduate_international_students'], function (questionHandle) {
                        StatisticsSubmissionsService.setValidationErrors(rule, fieldName, questionHandle);
                    });
                }
                else if(rule === 'sumOfUgAndPgInboundOverall') {
                    angular.forEach(['exchange_students_inbound', 'graduate_postgraduate_exchange_students_inbound', 'undergraduate_exchange_students_inbound'], function (questionHandle) {
                        StatisticsSubmissionsService.setValidationErrors(rule, fieldName, questionHandle);
                    });
                }
                else if(rule === 'sumOfUgAndPgOutboundOverall') {
                    angular.forEach(['exchange_students_outbound', 'graduate_postgraduate_exchange_students_outbound', 'undergraduate_exchange_students_outbound'], function (questionHandle) {
                        StatisticsSubmissionsService.setValidationErrors(rule, fieldName, questionHandle);
                    });
                }
                else if(rule === 'sumOfAllFacultyStaff') {
                    angular.forEach(['faculty_staff_female', 'faculty_staff_other'], function (questionHandle) {
                        StatisticsSubmissionsService.setValidationErrors(rule, fieldName, questionHandle);
                    });
                }
                else if(rule === 'sumOfAllStudents') {
                    angular.forEach(['students_female', 'students_other'], function (questionHandle) {
                        StatisticsSubmissionsService.setValidationErrors(rule, fieldName, questionHandle);
                    });
                }
              
            }
        }
        function removeValidationErrors(rule, fieldName, overall) {
            if (overall) {
                angular.forEach(['undergraduate_students', 'graduate_postgraduate_students', 'overall_students'], function (questionHandle) {
                    StatisticsSubmissionsService.removeValidationErrors(rule, fieldName, questionHandle);
                });
            } else {
                if(rule === 'sumOfUgAndPgInternational') {
                    angular.forEach(['overall_international_students', 'graduate_postgraduate_international_students', 'undergraduate_international_students'], function (questionHandle) {
                        StatisticsSubmissionsService.removeValidationErrors(rule, fieldName, questionHandle);
                    });
                }
                else if(rule === 'sumOfUgAndPgInboundOverall') {
                    angular.forEach(['exchange_students_inbound', 'graduate_postgraduate_exchange_students_inbound', 'undergraduate_exchange_students_inbound'], function (questionHandle) {
                        StatisticsSubmissionsService.removeValidationErrors(rule, fieldName, questionHandle);
                    });
                }
                else if(rule === 'sumOfUgAndPgOutboundOverall') {
                    angular.forEach(['exchange_students_outbound', 'graduate_postgraduate_exchange_students_outbound', 'undergraduate_exchange_students_outbound'], function (questionHandle) {
                        StatisticsSubmissionsService.removeValidationErrors(rule, fieldName, questionHandle);
                    });
                }
                else if(rule === 'sumOfAllFacultyStaff') {
                    angular.forEach(['faculty_staff_female', 'faculty_staff_other'], function (questionHandle) {
                        StatisticsSubmissionsService.removeValidationErrors(rule, fieldName, questionHandle);
                    });
                }
                else if(rule === 'sumOfAllStudents') {
                    angular.forEach(['students_female', 'students_other'], function (questionHandle) {
                        StatisticsSubmissionsService.removeValidationErrors(rule, fieldName, questionHandle);
                    });
                }
               
            }
        }
        
        function handleSum() {
            var overallStudentsOverall = StatisticsSubmissionsFactory.getCurrentAnswer('overall_students'),
            pgStudentsOverall = StatisticsSubmissionsFactory.getCurrentAnswer('graduate_postgraduate_students'),
            ugStudentsOverall = StatisticsSubmissionsFactory.getCurrentAnswer('undergraduate_students'),
            overallStudentsInternational = StatisticsSubmissionsFactory.getCurrentAnswer('overall_international_students'),
            pgStudentsInternational = StatisticsSubmissionsFactory.getCurrentAnswer('graduate_postgraduate_international_students'),
            ugStudentsInternational = StatisticsSubmissionsFactory.getCurrentAnswer('undergraduate_international_students'),

            overallStudentsInbound = StatisticsSubmissionsFactory.getCurrentAnswer('exchange_students_inbound'),
            pgStudentsInbound = StatisticsSubmissionsFactory.getCurrentAnswer('graduate_postgraduate_exchange_students_inbound'),
            ugStudentsInbound = StatisticsSubmissionsFactory.getCurrentAnswer('undergraduate_exchange_students_inbound'),

            overallStudentsOutbound = StatisticsSubmissionsFactory.getCurrentAnswer('exchange_students_outbound'),
            pgStudentsOutbound = StatisticsSubmissionsFactory.getCurrentAnswer('graduate_postgraduate_exchange_students_outbound'),
            ugStudentsOutbound = StatisticsSubmissionsFactory.getCurrentAnswer('undergraduate_exchange_students_outbound'),

            facultyStaff = StatisticsSubmissionsFactory.getCurrentAnswer('faculty_staff'),
            facultyStaffMale = StatisticsSubmissionsFactory.getCurrentAnswer('faculty_staff_male'),
            facultyStaffFemale = StatisticsSubmissionsFactory.getCurrentAnswer('faculty_staff_female'),
            facultyStaffOther = StatisticsSubmissionsFactory.getCurrentAnswer('faculty_staff_other'),

            studentsMale = StatisticsSubmissionsFactory.getCurrentAnswer('number_of_male_students'),
            studentsFemale = StatisticsSubmissionsFactory.getCurrentAnswer('students_female'),
            studentsOther = StatisticsSubmissionsFactory.getCurrentAnswer('students_other'),


            ugAndPgInternational = {},
            ugAndPgOverall = {},
            ugAndPgInboundOverall = {},
            ugAndPgOutboundOverall = {},
            facultyStaffAll = {},
            studentsAll = {};
           
            angular.forEach(['ft', 'pt', 'hc', 'fte'], function (fieldName) {
                if (pgStudentsOverall[fieldName] || ugStudentsOverall[fieldName]) {
                    ugAndPgOverall[fieldName] = {
                        value: (pgStudentsOverall[fieldName] ? parseFloat(pgStudentsOverall[fieldName].value) : 0) + (ugStudentsOverall[fieldName] ? parseFloat(ugStudentsOverall[fieldName].value) : 0)
                    };
                }
                if (pgStudentsInternational[fieldName] || ugStudentsInternational[fieldName]) {
                    ugAndPgInternational[fieldName] = {
                        value: (pgStudentsInternational[fieldName] ? parseFloat(pgStudentsInternational[fieldName].value) : 0) + (ugStudentsInternational[fieldName] ? parseFloat(ugStudentsInternational[fieldName].value) : 0)
                    };
                }
                if (pgStudentsInbound[fieldName] || ugStudentsInbound[fieldName]) {
                    ugAndPgInboundOverall[fieldName] = {
                        value: (pgStudentsInbound[fieldName] ? parseFloat(pgStudentsInbound[fieldName].value) : 0) + (ugStudentsInbound[fieldName] ? parseFloat(ugStudentsInbound[fieldName].value) : 0)
                    };
                }
                if (pgStudentsOutbound[fieldName] || ugStudentsOutbound[fieldName]) {
                    ugAndPgOutboundOverall[fieldName] = {
                        value: (pgStudentsOutbound[fieldName] ? parseFloat(pgStudentsOutbound[fieldName].value) : 0) + (ugStudentsOutbound[fieldName] ? parseFloat(ugStudentsOutbound[fieldName].value) : 0)
                    };
                }

                if (facultyStaffMale[fieldName] || facultyStaffFemale[fieldName] || facultyStaffOther[fieldName]) {
                    facultyStaffAll[fieldName] = {
                        value: (facultyStaffMale[fieldName] ? parseFloat(facultyStaffMale[fieldName].value) : 0) + (facultyStaffFemale[fieldName] ? parseFloat(facultyStaffFemale[fieldName].value) : 0) + (facultyStaffOther[fieldName] ? parseFloat(facultyStaffOther[fieldName].value) : 0)
                    };
                }

                if (studentsMale[fieldName] || studentsFemale[fieldName] || studentsOther[fieldName]) {
                    studentsAll[fieldName] = {
                        value: (studentsMale[fieldName] ? parseFloat(studentsMale[fieldName].value) : 0) + (studentsFemale[fieldName] ? parseFloat(studentsFemale[fieldName].value) : 0) + (studentsOther[fieldName] ? parseFloat(studentsOther[fieldName].value) : 0)
                    };
                }


                if (ugAndPgOverall[fieldName] && ugAndPgOverall[fieldName].value) {
                    if (overallStudentsOverall[fieldName] && overallStudentsOverall[fieldName].value) {
                        if (parseFloat(overallStudentsOverall[fieldName].value).toFixed(2) !== ugAndPgOverall[fieldName].value.toFixed(2)) {
                            controller.validationMessage = 'sumOfUgAndPgOverall';
                            controller.isComparisionInvalid = true;
                            setValidationErrors('sumOfUgAndPgOverall', fieldName, true);
                        } else {
                            removeValidationErrors('sumOfUgAndPgOverall', fieldName, true);
                        }

                    } else {
                        controller.validationMessage = 'sumOfUgAndPgOverall';
                        controller.isComparisionInvalid = true;
                        setValidationErrors('sumOfUgAndPgOverall', fieldName, true);
                    }
                } else {
                    removeValidationErrors('sumOfUgAndPgOverall', fieldName, true);
                }
                if (ugAndPgInternational[fieldName] && ugAndPgInternational[fieldName].value) {
                    if (overallStudentsInternational[fieldName] && overallStudentsInternational[fieldName].value) {
                        if (parseFloat(overallStudentsInternational[fieldName].value).toFixed(2) !== ugAndPgInternational[fieldName].value.toFixed(2)) {
                            controller.validationMessage = 'sumOfUgAndPgInternational';
                            controller.isComparisionInvalid = true;
                            setValidationErrors('sumOfUgAndPgInternational', fieldName);
                        } else {
                            removeValidationErrors('sumOfUgAndPgInternational', fieldName);
                        }

                    } else {
                        controller.validationMessage = 'sumOfUgAndPgInternational';
                        controller.isComparisionInvalid = true;
                        setValidationErrors('sumOfUgAndPgInternational', fieldName);
                    }
                } else {
                    removeValidationErrors('sumOfUgAndPgInternational', fieldName);
                }

                if (ugAndPgInboundOverall[fieldName] && ugAndPgInboundOverall[fieldName].value) {
                    if (overallStudentsInbound[fieldName] && overallStudentsInbound[fieldName].value) {
                        if (parseFloat(overallStudentsInbound[fieldName].value).toFixed(2) !== ugAndPgInboundOverall[fieldName].value.toFixed(2)) {
                            controller.validationMessage = 'sumOfUgAndPgInboundOverall';
                            controller.isComparisionInvalid = true;
                            setValidationErrors('sumOfUgAndPgInboundOverall', fieldName);
                        } else {
                            removeValidationErrors('sumOfUgAndPgInboundOverall', fieldName);
                        }

                    } else {
                        controller.validationMessage = 'sumOfUgAndPgInboundOverall';
                        controller.isComparisionInvalid = true;
                        setValidationErrors('sumOfUgAndPgInboundOverall', fieldName);
                    }
                } else {
                    removeValidationErrors('sumOfUgAndPgInboundOverall', fieldName);
                }
                if (ugAndPgOutboundOverall[fieldName] && ugAndPgOutboundOverall[fieldName].value) {
                    if (overallStudentsOutbound[fieldName] && overallStudentsOutbound[fieldName].value) {
                        if (parseFloat(overallStudentsOutbound[fieldName].value).toFixed(2) !== ugAndPgOutboundOverall[fieldName].value.toFixed(2)) {
                            controller.validationMessage = 'sumOfUgAndPgOutboundOverall';
                            controller.isComparisionInvalid = true;
                            setValidationErrors('sumOfUgAndPgOutboundOverall', fieldName);
                        } else {
                            removeValidationErrors('sumOfUgAndPgOutboundOverall', fieldName);
                        }

                    } else {
                        controller.validationMessage = 'sumOfUgAndPgOutboundOverall';
                        controller.isComparisionInvalid = true;
                        setValidationErrors('sumOfUgAndPgOutboundOverall', fieldName);
                    }
                } else {
                    removeValidationErrors('sumOfUgAndPgOutboundOverall', fieldName);
                }

                if (facultyStaffAll[fieldName] && facultyStaffAll[fieldName].value) {
                    if (facultyStaff[fieldName] && facultyStaff[fieldName].value) {
                        if (parseFloat(facultyStaff[fieldName].value).toFixed(2) !== facultyStaffAll[fieldName].value.toFixed(2)) {
                            controller.validationMessage = 'sumOfAllFacultyStaff';
                            controller.isComparisionInvalid = true;
                            setValidationErrors('sumOfAllFacultyStaff', fieldName);
                        } else {
                            removeValidationErrors('sumOfAllFacultyStaff', fieldName);
                        }

                    } else {
                        controller.validationMessage = 'sumOfAllFacultyStaff';
                        controller.isComparisionInvalid = true;
                        setValidationErrors('sumOfAllFacultyStaff', fieldName);
                    }
                } else {
                    removeValidationErrors('sumOfAllFacultyStaff', fieldName);
                }

                if (studentsAll[fieldName] && studentsAll[fieldName].value) {
                    if (overallStudentsOverall[fieldName] && overallStudentsOverall[fieldName].value) {
                        if (parseFloat(overallStudentsOverall[fieldName].value).toFixed(2) !== studentsAll[fieldName].value.toFixed(2)) {
                            controller.validationMessage = 'sumOfAllStudents';
                            controller.isComparisionInvalid = true;
                            setValidationErrors('sumOfAllStudents', fieldName);
                        } else {
                            removeValidationErrors('sumOfAllStudents', fieldName);
                        }

                    } else {
                        controller.validationMessage = 'sumOfAllStudents';
                        controller.isComparisionInvalid = true;
                        setValidationErrors('sumOfAllStudents', fieldName);
                    }
                } else {
                    removeValidationErrors('sumOfAllStudents', fieldName);
                }
                
            });
        }

        

        function isGreater(firstValue, secondValue){
            var isGreater = false;
            if(!firstValue || (!firstValue.ft && !firstValue.pt && !firstValue.hc && !firstValue.fte)){
                isGreater = true;
            }
            if (secondValue && (secondValue.ft || secondValue.pt || secondValue.hc || secondValue.fte)) {
                angular.forEach(['ft','pt','hc','fte'],function(field){
                    if (firstValue[field] && secondValue[field]) {
                        var firstFteParsed = parseInt(firstValue[field].value, 10),
                            secondFteParsed = parseInt(secondValue[field].value, 10),
                            firstFteValue = angular.isNumber(firstFteParsed) && !isNaN(firstFteParsed) ? firstFteParsed : 0,
                            secondFteValue = angular.isNumber(secondFteParsed) && !isNaN(secondFteParsed) ? secondFteParsed : 0;
                        if (firstFteValue > secondFteValue) {
                            isGreater = true;
                        }
                    }
                });
            }
            else{
                isGreater = true;  
            }

            return isGreater;
        }

        controller.handleOverallVisibilityForClient = function () {
            if (controller.rankingHandle === 'all' && controller.isClient) {
                return true;
            }
        };

        controller.handleCategoryVisibilityForClient = function(categoryHandle,question){
            if (categoryHandle === 'esg' || categoryHandle === 'sustainability' || categoryHandle === 'degree_options') {
                return false;
            }
            if (question && question.rankings && question.rankings.length === 1 && question.rankings[0] === 'all') {
                return false;
            }

            return true;
        };

        controller.handleSave = function () {
            if (!controller.isValid()) {
                return;
            }
            if (controller.rankingHandle !== 'esg') {
                handleComparision();
                handleSum();
                if (controller.isComparisionInvalid) {
                    NotifierFactory.show(
                        'error',
                        controller.validationMessages[controller.validationMessage],
                        'Data Submission'
                    );
                    UnsavedChangesFactory.setSaveError();
                    controller.isComparisionInvalid = false;

                    // return;
                }
            }
           
            controller.submitInProgress = true;
            controller.formHasErrors = false;
            StatisticsSubmissionsFactory.requestData();
            var dataWatch = WatchService.create($scope, StatisticsSubmissionsFactory.getData, function (data) {
                // if (!data || data.length !== controller.questionsHandles.length) {
                if (!data) {
                   
                    return false;
                }
                dataWatch();
                StatisticsSubmissionsFactory.resetInvalidIndicatorsData();
                var questionHandle = null,
                previouslySubmittedValues = null;

                if (controller.validationErrors.indexOf('undergraduateStudentsIsEmpty') !== -1 ||
                    controller.validationMessage === 'undergraduateStudentsIsEmpty') {
                    StatisticsSubmissionsService.setValidationErrors('undergraduateStudentsIsEmpty', 'ft', 'undergraduate_students', 2);
                    StatisticsSubmissionsService.setValidationErrors('undergraduateStudentsIsEmpty', 'ft', 'graduate_postgraduate_students', 2);
                    StatisticsSubmissionsFactory.setInvalidIndicators('overall_students', 2);
                } else if (controller.validationErrors.indexOf('postgraduateStudentsIsEmpty') !== -1 ||
                    controller.validationMessage === 'postgraduateStudentsIsEmpty') {
                    StatisticsSubmissionsService.setValidationErrors('postgraduateStudentsIsEmpty', 'ft', 'undergraduate_students', 2);
                    StatisticsSubmissionsService.setValidationErrors('postgraduateStudentsIsEmpty', 'ft', 'graduate_postgraduate_students', 2);
                    StatisticsSubmissionsFactory.setInvalidIndicators('overall_students', 2);
                } else {
                    if (StatisticsSubmissionsFactory.getInvalidIndicator('overall_students') &&
                        StatisticsSubmissionsFactory.getInvalidIndicator('overall_students') === 2) {
                        StatisticsSubmissionsFactory.setInvalidIndicators('overall_students', false);
                    }
                }
                if (controller.validationErrors.indexOf('facultyStaffVSStudentsOverall') !== -1 ||
                    controller.validationMessage === 'facultyStaffVSStudentsOverall') {
                    StatisticsSubmissionsService.handleSetValidation('facultyStaffVSStudentsOverall', 'faculty_staff');
                    StatisticsSubmissionsService.handleSetValidation('facultyStaffVSStudentsOverall', 'overall_students');
                } else {
                    StatisticsSubmissionsService.handleRemoveValidation('facultyStaffVSStudentsOverall', 'overall_students');
                    StatisticsSubmissionsService.handleRemoveValidation('facultyStaffVSStudentsOverall', 'faculty_staff');
                }

                if (controller.validationErrors.indexOf('staffWithPhdVSFacultyStaff') !== -1 ||
                    controller.validationMessage === 'staffWithPhdVSFacultyStaff') {
                    StatisticsSubmissionsService.handleSetValidation('staffWithPhdVSFacultyStaff', 'faculty_staff');
                    StatisticsSubmissionsService.handleSetValidation('staffWithPhdVSFacultyStaff', 'staff_with_phd');
                } else {
                    StatisticsSubmissionsService.handleRemoveValidation('staffWithPhdVSFacultyStaff', 'staff_with_phd');
                    StatisticsSubmissionsService.handleRemoveValidation('staffWithPhdVSFacultyStaff', 'faculty_staff');
                }
                $timeout(function() {
                    angular.forEach(data, function (key) {
                        if(key.question.handle === 'does_institution_have_dedicated_staff_member'  ) {
                            if(key.answers && key.answers.institutionDedicatedStaffMember && key.answers.institutionDedicatedStaffMember.value) {
                                key.answers.institutionDedicatedStaffMember.value = TextService.clearAttributes(key.answers.institutionDedicatedStaffMember.value);
                                key.answers.institutionDedicatedStaffMember.valueAsString = key.answers.institutionDedicatedStaffMember.value;
                            }
                        }
                        if(key.question.handle === 'health_and_wellbeing_services'  ) {
                            if(key.answers && key.answers.healthyAndAffordableFoodText && key.answers.healthyAndAffordableFoodText.value) {
                                key.answers.healthyAndAffordableFoodText.value = TextService.clearAttributes( key.answers.healthyAndAffordableFoodText.value);
                                key.answers.healthyAndAffordableFoodText.valueAsString = key.answers.healthyAndAffordableFoodText.value;
                            }
                        }
                        if(key.question.handle === 'presence_of_research_center' ) {
                            if(key.answers && key.answers.presenceOfSearchText && key.answers.presenceOfSearchText.value) {
                                key.answers.presenceOfSearchText.value = TextService.clearAttributes( key.answers.presenceOfSearchText.value);
                                key.answers.presenceOfSearchText.valueAsString = key.answers.presenceOfSearchText.value;
                            }
                        }
                        if(key.question.handle === 'support_services_for_disabilities' ) {
                            if(key.answers && key.answers.campusAccessibilityText && key.answers.campusAccessibilityText.value) {
                                key.answers.campusAccessibilityText.value = TextService.clearAttributes( key.answers.campusAccessibilityText.value);
                                key.answers.campusAccessibilityText.valueAsString = key.answers.campusAccessibilityText.value;
                            }
                        }
                       
                        if(key.question.handle === 'manage_or_deliver_outreach_projects' ) {
                            if(key.answers && key.answers.manageOrDeliverOutrechProjects && key.answers.manageOrDeliverOutrechProjects.value) {
                                key.answers.manageOrDeliverOutrechProjects.value = TextService.clearAttributes( key.answers.manageOrDeliverOutrechProjects.value);
                                key.answers.manageOrDeliverOutrechProjects.valueAsString = key.answers.manageOrDeliverOutrechProjects.value;
                            }
                           
                        }
                        
                        questionHandle = key.question.handle;
                        // previouslySubmittedValues = key.previouslySubmitted === true ? angular.copy(StatisticsSubmissionsFactory.getPreviouslySubmittedValues(questionHandle)) : {};
                        // previouslySubmittedValues = previouslySubmittedValues ? previouslySubmittedValues.answers : {};
                        // key.answers = key.previouslySubmitted === true ? previouslySubmittedValues : key.answers;
                        key.isSelected = key.question.id === controller.questionId ? true : false;
                        key.autoAccept = null;
    
                        if (questionHandle === 'undergraduate_students' ||
                            questionHandle === 'graduate_postgraduate_students' ||
                            questionHandle === 'overall_students') {
                            if (StatisticsSubmissionsFactory.getValidationErrors().indexOf('sumOfUgAndPgOverall') !== -1) {
                                key.isSelected = false;
                            }
    
                        }
    
                        if (questionHandle === 'undergraduate_international_students' ||
                            questionHandle === 'graduate_postgraduate_international_students' ||
                            questionHandle === 'overall_international_students') {
                            if (StatisticsSubmissionsFactory.getValidationErrors().indexOf('sumOfUgAndPgInternational') !== -1) {
                                key.isSelected = false;
                            }
                        }

                        if (questionHandle === 'undergraduate_exchange_students_inbound' ||
                            questionHandle === 'graduate_postgraduate_exchange_students_inbound' ||
                            questionHandle === 'exchange_students_inbound') {
                            if (StatisticsSubmissionsFactory.getValidationErrors().indexOf('sumOfUgAndPgInboundOverall') !== -1) {
                                key.isSelected = false;
                            }
                        }

                        if (questionHandle === 'undergraduate_exchange_students_outbound' ||
                            questionHandle === 'graduate_postgraduate_exchange_students_outbound' ||
                            questionHandle === 'exchange_students_outbound') {
                            if (StatisticsSubmissionsFactory.getValidationErrors().indexOf('sumOfUgAndPgOutboundOverall') !== -1) {
                                key.isSelected = false;
                            }
                        }
    
                        if(questionHandle === 'faculty_staff_male'){
                            if(!isGreater(StatisticsSubmissionsFactory.getCurrentAnswer('faculty_staff_male'),StatisticsSubmissionsFactory.getCurrentAnswer('faculty_staff'))){
                                if(!StatisticsSubmissionsFactory.isStatusManualyChanged(questionHandle)){
                                    if(key.status !=='queried' && key.status !=='rejected' && key.status !=='pending') {
                                        key.status = 'accepted';
                                        key.autoAccept = true;
                                    }
                                }
                            }
                        }
                        if( questionHandle === 'number_of_male_students'){
                            if(!isGreater(StatisticsSubmissionsFactory.getCurrentAnswer('number_of_male_students'),StatisticsSubmissionsFactory.getCurrentAnswer('overall_students'))){
                                if(key.status !=='queried' && key.status !=='rejected' && key.status !=='pending') {
                                    key.status = 'accepted';
                                    key.autoAccept = true;
                                }
                            }
                        }

                        if (questionHandle === 'faculty_staff' ||
                            questionHandle === 'faculty_staff_female' ||
                            questionHandle === 'faculty_staff_other' ||
                            questionHandle === 'overall_international_students' ||
                            questionHandle === 'international_faculty_staff' ||
                            questionHandle === 'staff_with_phd' ||
                            questionHandle === 'exchange_students_inbound' ||
                            questionHandle === 'exchange_students_outbound' ||
                            questionHandle === 'overall_students'||
                            questionHandle === 'students_other'||
                            questionHandle === 'students_female') {
                            if (!StatisticsSubmissionsFactory.isStatusManualyChanged(questionHandle) && isAnswerChanged(questionHandle, key)) {
                                if (!StatisticsSubmissionsFactory.getInvalidIndicator(questionHandle)) {
                                    if (StatisticsSubmissionsFactory.isExplainationProvided(questionHandle)) {
                                        key.status = 'pending';
                                    } else {
                                        if(key.status !=='queried' && key.status !=='rejected' && key.status !=='pending') {
                                            key.status = 'accepted';
                                            key.autoAccept = true;
                                        }
                                    }
                                }
                            }
                        }
                        if (StatisticsSubmissionsFactory.isOverallStudentsEmpty() &&
                            (questionHandle === 'undergraduate_students' ||
                                questionHandle === 'graduate_postgraduate_students')) {
                            if (!StatisticsSubmissionsFactory.isStatusManualyChanged(questionHandle) && isAnswerChanged(questionHandle, key)) {
                                if (!StatisticsSubmissionsFactory.getInvalidIndicator(questionHandle)) {
                                    if (StatisticsSubmissionsFactory.isExplainationProvided(questionHandle)) {
                                        key.status = 'pending';
                                    } else {
                                        if(key.status !=='queried' && key.status !=='rejected' && key.status !=='pending') {
                                            key.status = 'accepted';
                                            key.autoAccept = true;
                                        }
                                    }
                                }
                            }
                        }
                       
                        if(StatisticsSubmissionsFactory.getInvalidIndicator(questionHandle)) {
                            StatisticsSubmissionsFactory.setInvalidIndicatorsData(questionHandle, angular.copy(key));
                             previouslySubmittedValues = StatisticsSubmissionsFactory.getPreviousAnswer(questionHandle);
                             key.answers = previouslySubmittedValues ? previouslySubmittedValues : {};
                            controller.formHasErrors = true;
                            key.isSelected = false;
                        }
                    });
                    StatisticsSubmissionsService.update(InstitutionFactory.getCoreId(), {
                        data: data,
                        institutionCountryCode: InstitutionFactory.getCountryCode(),
                        institutionName: InstitutionFactory.getDisplayName()
                    }).then(controller.saveCallback);
                });
            }, true);
        };

        function isAnswerChanged (hanlde, currentData) {
            // if(controller.isClient) {
            //    // return false;
            // }
            var previousData = StatisticsSubmissionsFactory.getPreviouslySubmittedValues(hanlde) ? angular.copy(StatisticsSubmissionsFactory.getPreviouslySubmittedValues(hanlde)) : {};
            currentData = currentData ? angular.copy(currentData) : {};
            if(!currentData.answers){
                currentData.answers = {};
            }
            if(!previousData.answers){
                previousData.answers = {};
            }
            if(!currentData.answers.pt){
                currentData.answers.pt = {};
            }
            if(!currentData.answers.ft){
                currentData.answers.ft = {};
            }
            if(!currentData.answers.hc){
                currentData.answers.hc = {};
            }
            if(!currentData.answers.fte){
                currentData.answers.fte = {};
            }
            if(!previousData.answers.pt){
                previousData.answers.pt = {};
            }
            if(!previousData.answers.ft){
                previousData.answers.ft = {};
            }
            if(!previousData.answers.hc){
                previousData.answers.hc = {};
            }
            if(!previousData.answers.fte){
                previousData.answers.fte = {};
            }

            if (currentData.source !== previousData.source && (currentData.source || previousData.source)) {
                return true;
            }
            if (currentData.status !== previousData.status && (currentData.status || previousData.status)) {
                return true;
            }
            if (currentData.previouslySubmitted !== previousData.previouslySubmitted && (currentData.previouslySubmitted || previousData.previouslySubmitted)) {
                return true;
            }
            if (currentData.answers.pt.value !== previousData.answers.pt.value && (currentData.answers.pt.value !== null && previousData.answers.pt !== {})) {
                return true;
            }
            if (currentData.answers.ft.value !== previousData.answers.ft.value && (currentData.answers.ft.value !== null && previousData.answers.ft !== {})) {
                return true;
            }
            if (currentData.answers.fte.value !== previousData.answers.fte.value && (currentData.answers.fte.value !== null && previousData.answers.fte !== {})) {
                return true;
            }
            if (currentData.answers.hc.value !== previousData.answers.hc.value && (currentData.answers.hc.value !== null && previousData.answers.hc !== {})) {
                return true;
            }

            if (currentData.answers.pt.estimate !== previousData.answers.pt.estimate && (currentData.answers.pt.estimate !== false && previousData.answers.pt !== {})) {
                return true;
            }
            if (currentData.answers.ft.estimate !== previousData.answers.ft.estimate && (currentData.answers.ft.estimate !== false && previousData.answers.ft !== {})) {
                return true;
            }
            if (currentData.answers.fte.estimate !== previousData.answers.fte.estimate && (currentData.answers.fte.estimate !== false && previousData.answers.fte !== {})) {
                return true;
            }
            if (currentData.answers.hc.estimate !== previousData.answers.hc.estimate && (currentData.answers.hc.estimate !== false && previousData.answers.hc !== {})) {
                return true;
            }

            return false;
        }

        function calculateClassificationSizeField(statsData)
        {
            if (statsData.length) {
                ClassificationsService.get({'filter[institutionCoreId]=': InstitutionFactory.getCoreId(), page: 1, limit: 1}
                ).then(function (response) {
                    if (response && response.results && response.results[0] !== undefined) {
                        var classificationData = response.results[0],
                            simplifyFteData = [];
                        angular.forEach(statsData, function (value) {
                            if (value.question.handle === 'undergraduate_students' ||
                                value.question.handle === 'graduate_postgraduate_students' ||
                                value.question.handle === 'overall_students') {
                                    simplifyFteData[value.question.handle] = value.answers.fte.value;
                            }
                        });

                        var sizeCount = simplifyFteData.overall_students;
                        if (simplifyFteData.graduate_postgraduate_students !== "" &&
                            simplifyFteData.graduate_postgraduate_students !== null &&
                            simplifyFteData.undergraduate_students !== "" &&
                            simplifyFteData.undergraduate_students !== null
                        ) {
                            sizeCount = simplifyFteData.graduate_postgraduate_students + simplifyFteData.undergraduate_students;
                        }

                        if (sizeCount >= 30000) {
                            classificationData.size = 'XL';
                        } else if(sizeCount >= 12000){
                            classificationData.size = 'L';
                        } else if(sizeCount >= 5000){
                            classificationData.size = 'M';
                        } else if(sizeCount || sizeCount === 0) {
                            classificationData.size = 'S';
                        } else{
                            classificationData.size = '';
                        }
                        ClassificationsService.calculateResearchIntensity(classificationData);
                        ClassificationsService.update(classificationData.id, classificationData);
                    }
                });
            }
        }
        controller.institutionDataWatch = function (data) {
            if (!data || angular.equals({}, data)) {
                return;
            }
            controller.subscribedStatistics = [];
            var subscribedTo = InstitutionFactory.getSubscriptions();
            controller.isTuSubscribed = !!(subscribedTo.tu && subscribedTo.tu.subscribed && subscribedTo.tu.advanced);
            angular.forEach(controller.statisticsAvailable, function (item) {
                if (subscribedTo[item.handle] && subscribedTo[item.handle].subscribed) {
                    var selectItem = {
                        value : item.handle,
                        label : item.abbreviation
                    };
                    if ((item.handle === 'ger')) {
                        return;
                    }
                    controller.subscribedStatistics.push(selectItem);
                }
            });
            controller.dataToload--;
        };

        controller.loadAnswers = function (institutionCoreId) {

            RankingsDashboardService.getRankingsAnswersByQuestions({
                    'filter[institutionCoreId]': institutionCoreId,
                    'filter[questionHandle][]': controller.questionsHandles
                }).then(function (data) {
                    // StatisticsSubmissionsFactory.resetCurrentAnswer();
                    // StatisticsSubmissionsFactory.resetPreviousAnswer();
                    var i = 0,
                        k = 0,
                        total = data.results.length,
                        item = null,
                        ugAndPgStudentsAnswers = {};
                    for (i; i < total; i++) {
                        item = data.results[i].answers;
                        if (data.results[i].questionHandle === 'overall_students') {
                            if (!item.ft && !item.pt && !item.hc && !item.fte) {
                                StatisticsSubmissionsFactory.setOverallStudentsPreviousIsEmpty(true);
                                StatisticsSubmissionsFactory.setOverallStudentsIsEmpty(true);
                                StatisticsSubmissionsFactory.setOverallStudentsAnswers(null);
                                StatisticsSubmissionsFactory.setOverallStudentsCurrentEmpty(true);
                            StatisticsSubmissionsFactory.setOverallStudentsCurrentAnswers(null);
                            } else {
                                StatisticsSubmissionsFactory.setOverallStudentsPreviousIsEmpty(false);
                                StatisticsSubmissionsFactory.setOverallStudentsIsEmpty(false);
                                StatisticsSubmissionsFactory.setOverallStudentsAnswers(angular.copy(item) );
                                StatisticsSubmissionsFactory.setOverallStudentsCurrentEmpty(false);
                            StatisticsSubmissionsFactory.setOverallStudentsCurrentAnswers(item);
                            }

                        }
                        if (data.results[i].questionHandle === 'faculty_staff') {
                            if (!item.ft && !item.pt && !item.hc && !item.fte) {
                                StatisticsSubmissionsFactory.setFacultyStaffEmpty(true);
                                StatisticsSubmissionsFactory.setFacultyStaffAnswers(null);
                                StatisticsSubmissionsFactory.setFacultyStaffCurrentEmpty(true);
                            StatisticsSubmissionsFactory.setFacultyStaffCurrentAnswers(null);
                            } else {
                                StatisticsSubmissionsFactory.setFacultyStaffEmpty(false);
                                StatisticsSubmissionsFactory.setFacultyStaffAnswers(item);
                                StatisticsSubmissionsFactory.setFacultyStaffCurrentEmpty(false);
                            StatisticsSubmissionsFactory.setFacultyStaffCurrentAnswers(item);
                            }

                        }
                        if (data.results[i].questionHandle === 'staff_with_phd') {
                            StatisticsSubmissionsFactory.setStaffWithPhdCurrentAnswers(item);
                        }
                        if(StatisticsSubmissionsFactory.getInvalidIndicator(data.results[i].questionHandle)) {
                            var invalidData = StatisticsSubmissionsFactory.getInvalidIndicatorsData(data.results[i].questionHandle);
                            if (invalidData) {
                                data.results[i].answers = item = invalidData.answers;
                                data.results[i].status = invalidData.status;
                                data.results[i].source = invalidData.source;
                            }
                        }
                        else {
                            StatisticsSubmissionsFactory.setCurrentAnswer(data.results[i].questionHandle, item);
                            StatisticsSubmissionsFactory.setPreviousAnswer(data.results[i].questionHandle, item);
                        }
                    }
                    for (k; k < total; k++) {
                        item = data.results[k].answers;
                        if ((data.results[k].questionHandle === 'graduate_postgraduate_students' || data.results[k].questionHandle === 'undergraduate_students')) {
                            if (item.ft) {
                                ugAndPgStudentsAnswers.ft = {
                                    value: (ugAndPgStudentsAnswers.ft ? ugAndPgStudentsAnswers.ft.value : 0) + item.ft.value
                                };
                            }
                            if (item.pt) {
                                ugAndPgStudentsAnswers.pt = {
                                    value: (ugAndPgStudentsAnswers.pt ? ugAndPgStudentsAnswers.pt.value : 0) + item.pt.value
                                };
                            }
                            if (item.hc) {
                                ugAndPgStudentsAnswers.hc = {
                                    value: (ugAndPgStudentsAnswers.hc ? ugAndPgStudentsAnswers.hc.value : 0) + item.hc.value
                                };
                            }
                            if (item.fte) {
                                ugAndPgStudentsAnswers.fte = {
                                    value: (ugAndPgStudentsAnswers.fte ? ugAndPgStudentsAnswers.fte.value : 0) + item.fte.value
                                };
                            }
                            if (data.results[k].questionHandle === 'graduate_postgraduate_students') {
                                StatisticsSubmissionsFactory.setPgStudentsCurrentAnswers(item);
                            } else {
                                StatisticsSubmissionsFactory.setUgStudentsCurrentAnswers(item);
                            }

                            // ugAndPgStudentsAnswers.ft = ugAndPgStudentsAnswers.ft ? ugAndPgStudentsAnswers.ft : 0 + (item.ft ? item.ft.value : 0);
                            // ugAndPgStudentsAnswers.pt = ugAndPgStudentsAnswers.pt ? ugAndPgStudentsAnswers.pt : 0 + (item.pt ? item.pt.value : 0);
                            // ugAndPgStudentsAnswers.hc = ugAndPgStudentsAnswers.hc ? ugAndPgStudentsAnswers.hc : 0 + (item.hc ? item.hc.value : 0);
                            // ugAndPgStudentsAnswers.fte = ugAndPgStudentsAnswers.fte ? ugAndPgStudentsAnswers.fte : 0 + (item.fte ? item.fte.value : 0);
                            data.results[k].isOverallStudentsEmpty = StatisticsSubmissionsFactory.isOverallStudentsEmpty();
                        }
                    }
                    StatisticsSubmissionsFactory.setUgAndPgStudentsAnswers(ugAndPgStudentsAnswers);
                    StatisticsSubmissionsFactory.setAnswers(data.results);
                    controller.dataToload--;
                });
        };

        controller.coreIdWatch = function (institutionCoreId) {
            if (institutionCoreId && controller.questionsHandles.length) {
                StatisticsSubmissionsFactory.resetInvalidIndicators();
                StatisticsSubmissionsFactory.resetInvalidIndicatorsData();
                StatisticsSubmissionsFactory.resetValidationErrors();
                StatisticsSubmissionsFactory.resetAllAnswers();
                controller.loadAnswers(institutionCoreId);
            }
        };

        /**
         * Listen to available subscriptions.
         *
         * @param {Object} data
         */
        controller.subscriptionsWatch = function (data) {
            if (!data || !data.length) {
                return;
            }
            angular.forEach(StatisticsDashboardFactory.getSubscriptions(), function (item) {
                if (item.handle !== 'stars') {
                    controller.statisticsAvailable.push(item);
                }
            });
            controller.dataToload--;
            // listen to institution subscriptions
            WatchService.create($scope, InstitutionFactory.getData, controller.institutionDataWatch);
        };

        controller.preselectQuestion = function (questionId, questionHandle) {
            StatisticsSubmissionsFactory.setActiveQuestionId(questionId);
            if ($stateParams.question !== questionHandle) {
                $state.transitionTo($state.current, angular.extend({
                    question: questionHandle
                }, $stateParams), {notify: false});
            }
        };

        controller.parseRankingQuestions = function (questions) {
            controller.questionsHandles = [];
            controller.questions = {
                'faculty_staff': [],
                'students_undergraduate': [],
                'students_graduate_postgraduate': [],
                'students_overall': [],
                'student_demographics': [],
                'degree_options': [],
                'additional_statistics': [],
                'sustainability': [],
                'esg': [],
                'equality': [],
                'knowledge_exchange':[],
                'environmental_institutions': [],
                'environmental_education':[],
                'environmental_research':[],
                'health_and_wellbeing':[],
                'good_governance': [],
                'additional_information': [],
                'tuition_fees': [],
                'internationalisation': [],
                'graduate_employment_rate': [],
                'employers_connection_with_graduates': [],
                'partnership_with_employers_internships_work_placements': [],
                'career_service_support': [],
                'employers_connections': [],
                'employer_partnerships': [],
                'employment_rate': []
            };
            var preselectFirst = true,
                questionHandle = $location.search().question,
                esgItem = null;

            angular.forEach(questions, function (item) {
                if (item.rankings.indexOf(controller.rankingHandle) !== -1) {
                    if (!controller.questions[item.categoryHandle]) {
                        controller.questions[item.categoryHandle] = [];
                    }
                    controller.questions[item.categoryHandle].push(item);
                    controller.questionsHandles.push(item.handle);

                    if (controller.rankingHandle === 'esg') {
                        //Esg
                        if (item.categoryHandle === 'faculty_staff' && item.handle !== "international_faculty_staff") {
                            esgItem = angular.copy(item);
                            esgItem.type = "social_impact";
                            esgItem.categoryHandle = "equality";
                            esgItem.order = -2;
                            controller.questions.equality.push(esgItem);
                        }
                        if (item.categoryHandle === "student_demographics" && (
                                item.handle === "total_students_nationalities" ||
                                item.handle === "students_female" ||
                                item.handle === "students_other")) {
                            esgItem = angular.copy(item);
                            esgItem.type = "social_impact";
                            // esgItem.order = 5;
                            esgItem.categoryHandle = "equality";
                            controller.questions.equality.push(esgItem);
                        }
                        if (item.categoryHandle === "esg" && (
                                item.handle === "number_of_members_in_your_leadership_team" ||
                                item.handle === "support_services_for_disabilities" ||
                                item.handle === "cuurent_equality_diversity_and_inclusionn" ||
                                item.handle === "instituion_provide_dedicated_training_on_social_impacts" ||
                                item.handle === "number_of_your_university_senior_leadership_team_male")) {
                            esgItem = angular.copy(item);
                            esgItem.type = "social_impact";
                            esgItem.order = 6;
                            esgItem.categoryHandle = "equality";
                            controller.questions.equality.push(esgItem);
                        }

                        if (item.categoryHandle === "esg" && (
                                item.handle === "manage_or_deliver_outreach_projects")) {
                            esgItem = angular.copy(item);
                            esgItem.type = "social_impact";
                            esgItem.categoryHandle = "knowledge_exchange";
                            controller.questions.knowledge_exchange.push(esgItem);
                        }

                        if (item.categoryHandle === "esg" && (
                                item.handle === "health_and_wellbeing_services")) {
                            esgItem = angular.copy(item);
                            esgItem.type = "social_impact";
                            esgItem.categoryHandle = "health_and_wellbeing";
                            controller.questions.health_and_wellbeing.push(esgItem);
                        }
                        if (item.categoryHandle === "esg" && (
                                item.handle === "link_to_your_institution_sustainable_procurement" ||
                                item.handle === "link_to_your_institution_sustainability_climate_policy" ||
                                item.handle === "link_to_your_institution_sustainable_investment_policy" ||
                                item.handle === "report_carbon_emissions" ||
                                item.handle === "carbon_emissions_in_kilograms" ||
                                item.handle === "same_ffigure_for_base_line" ||
                                item.handle === "carbon_redduction_target" ||
                                item.handle === "enery_generated_in_campus" ||
                                item.handle === "campus_building_footprint" ||
                                item.handle === "institution_publicaly_committed_to_reaching_net_zero" ||
                                item.handle === "institution_provide_dedicated_training_on_environmental_aspects" ||
                                item.handle === "institution_have_an_assessment_tool_for_assessing" ||
                                item.handle === "link_to_student_led_Society")) {
                            esgItem = angular.copy(item);
                            esgItem.type = "environmental_impact";
                            esgItem.categoryHandle = "environmental_institutions";
                            controller.questions.environmental_institutions.push(esgItem);
                        }
                        if (item.categoryHandle === "esg" && (
                            item.handle === "climate_science_environmental_sustainability")) {
                            esgItem = angular.copy(item);
                            esgItem.type = "environmental_impact";
                            esgItem.categoryHandle = "environmental_education";
                            controller.questions.environmental_education.push(esgItem);
                        }
                        if (item.categoryHandle === "esg" && (
                            item.handle === "presence_of_research_center")) {
                            esgItem = angular.copy(item);
                            esgItem.type = "environmental_impact";
                            esgItem.categoryHandle = "environmental_research";
                            controller.questions.environmental_research.push(esgItem);
                        }
                        if (item.categoryHandle === "esg" && (
                                item.handle === "link_to_your_university_last_governance_meeting" ||
                                item.handle === "does_your_university_governing_body_have_student_representation" ||
                                item.handle === "equality_diversity_and_inclusionn_comittee" ||
                                item.handle === "anti_bribery_policy" ||
                                item.handle === "proportion_of_papers_publihsed" ||
                                item.handle === "holistic_ethical_organisational_culture" ||
                                item.handle === "university_student_union" ||
                                item.handle === "institution_formed_sustainability_committee" ||
                                item.handle === "does_institution_have_dedicated_staff_member" ||
                                item.handle === "publish_financial_reports")) {
                            esgItem = angular.copy(item);
                            esgItem.type = "governance";
                            esgItem.categoryHandle = "good_governance";
                            controller.questions.good_governance.push(esgItem);
                        }

                        if (item.categoryHandle === "esg" && (
                                item.handle === "your_institution_water_consumption" ||
                                item.handle === "your_institution_energy_consumption" ||
                                item.handle === "your_institution_carbon_emissions")) {
                            esgItem = angular.copy(item);
                            esgItem.type = "additional_information";
                            esgItem.categoryHandle = "additional_information";
                            controller.questions.additional_information.push(esgItem);
                        }

                        if (item.categoryHandle === "tuition_fees" && (
                                item.handle === "students_receive_scholarship_covering_hundred_percent" ||
                                item.handle === "students_receive_scholarship_covering_fifty_percent")) {
                            esgItem = angular.copy(item);
                            esgItem.subType = "additional_information";
                            esgItem.isFinancialQuestion = true;
                            esgItem.order = 20;
                            esgItem.categoryHandle = "additional_information";
                            controller.questions.additional_information.push(esgItem);
                        }
                        //End Esg
                    }
                   
                    if (preselectFirst) {
                        controller.preselectQuestion(item.id, item.handle);
                        preselectFirst = false;
                    }
                }
            });

            

            if (questionHandle) {
                var question = StatisticsSubmissionsService.getQuestionByHandle(questions, questionHandle);
                if (question && question.id) {
                    StatisticsSubmissionsFactory.setActiveQuestionId(question.id);
                    if ($location.search().question !== questionHandle) {
                        $state.transitionTo($state.current, {question: questionHandle}, {notify: false});
                    }
                }
            }
            angular.forEach(controller.questions, function(value, key) {
                controller.questions[key] = $filter('orderBy')(value, 'order', false);
            });

        };

        controller.noRankingsAccess = function () {
            var noRankingsAccess = true;
            angular.forEach(controller.subscribedStatistics, function (statistic) {
                if (statistic.value === controller.rankingHandle) {
                    noRankingsAccess = false;
                }
            });

            return controller.isClient && noRankingsAccess;
        };

        controller.handleNoRankingAccessClose = function () {
            controller.allowRanking = !controller.isClient;
        };

        controller.noSubscribedToRankings = function () {
            var noRankingsAccess = true;
            if (!controller.allowRanking) {
                angular.forEach(controller.subscribedStatistics, function (statistic) {
                    if (statistic.value === controller.rankingHandle) {
                        noRankingsAccess = false;
                    }
                });
            } else {
                noRankingsAccess = false;
            }

            return noRankingsAccess;
        };

        controller.isLoading = function () {
            if (controller.dataToload < 0) {
                return false;
            }
            return controller.dataToload !== 0;
        };

        controller.loadCategories = function () {
            RankingInfoFormService.getCategories().then(function (data) {
                StatisticsSubmissionsFactory.setCategories(data.results || []);
            });
        };

        /**
         * Listen to available questions.
         *
         * @param {Object} questions
         */
        controller.questionsWatch = function (questions) {
            if (!questions || !questions.length) {
                return;
            }
            controller.parseRankingQuestions(questions);
            controller.dataToload--;
            // listen to institution changes
            WatchService.create($scope, InstitutionFactory.getCoreId, controller.coreIdWatch);
        };

        controller.activeQuestionIdWatch = function (questionId) {
            controller.questionId = questionId;
        };

        controller.isFormDirtyWatch = function (dirty) {
            UnsavedChangesFactory.setChanges(dirty);
        };

        controller.validationErrorsWatch = function (errors) {
            controller.validationErrors = angular.copy(errors);
        };

        controller.initWatches = function () {
            // listen to available subscriptions
            WatchService.create($scope, StatisticsDashboardFactory.getSubscriptions, controller.subscriptionsWatch);
            // listen to available question
            WatchService.create($scope, StatisticsDashboardFactory.getQuestions, controller.questionsWatch);
            WatchService.create($scope, StatisticsSubmissionsFactory.getActiveQuestionId, controller.activeQuestionIdWatch);
            WatchService.create($scope, StatisticsSubmissionsFactory.getValidationErrors, controller.validationErrorsWatch, true);
            WatchService.create($scope, 'StatisticsSubmissionsController.forms.statistics.$dirty', controller.isFormDirtyWatch);
            controller.rankingsDataWatch = WatchService.create($scope, StatisticsDashboardFactory.getRankingsData, controller.getRankingsDataWatch);
        };

        controller.init = function () {
            UnsavedChangesFactory.setSaveFunction(controller.handleSave, $scope);
            StatisticsSubmissionsFactory.setHasChanges(false);
            controller.initWatches();
            controller.loadCategories();
            StatisticsDashboardFactory.toggleEnquiryForm(null);
        };

        controller.init();
    }

}(window.angular));

(function(angular) {
    "use strict";

    angular
        .module('qsHub.rankings')
        .factory('StatisticsSubmissionsFactory', [
            StatisticsSubmissions
        ]);

    function StatisticsSubmissions () {
        var answers = [],
            activeQuestionId = null,
            variationLogMessage = null,
            categories = null,
            overallStudentsAnswers = null,
            ugAndPgStudentsAnswers = null,
            facultyStaffAnswers = null,
            ugStudentsCurrentAnswers = null,
            pgStudentsCurrentAnswers = null,
            overallStudentsCurrentAnswers = null,
            overallInternationalStudentsCurrentAnswers = null,
            overAllInboundStudents = null,
            overAllOutboundStudents = null,
            staffWithPhdCurrentAnswers = null,
            facultyStaffCurrentAnswers = null,
            isDataRequest = false,
            isEmploymentRateModified = false,
            data = [],
            explanationRequiredQuestions = [],
            questionsOfChangesStatuses = [],
            validationErrors=[],
            filteredValidationErrors=[],
            hasChanges = false,
            reloadDatagrid = false,
            overallStudentsEmpty = false,
            ugAndPgStudentsEmpty = false,
            facultyStaffEmpty = false,
            overallStudentsCurrentEmpty = false,
            facultyStaffCurrentEmpty = false,
            overallStudentsPreviousIsEmpty = false,
            messageSent = false,
            currentRanking = null,
            previouslySubmittedValues = {},
            currentAnswers = {},
            previousAnswers = {},
            invalidIndicatorsData = {},
            gerPreviousAnswers = {},
            invalidIndicators = {},
            compareableQuestions = ['faculty_staff','overall_students','undergraduate_students','graduate_postgraduate_students','staff_with_phd'],
            isNotInOtherRankingQuestions = ['climate_science_environmental_sustainability','number_of_members_in_your_leadership_team','number_of_your_university_senior_leadership_team_male','presence_of_research_center', 'report_carbon_emissions', 'carbon_emissions_in_kilograms', 'same_ffigure_for_base_line', 'carbon_redduction_target', 'link_to_your_institution_sustainable_procurement','link_to_your_institution_sustainability_climate_policy', 'campus_building_footprint','enery_generated_in_campus', 'link_to_your_institution_sustainable_investment_policy', 'institution_publicaly_committed_to_reaching_net_zero', 'link_to_student_led_Society','cuurent_equality_diversity_and_inclusionn','institution_formed_sustainability_committee','instituion_provide_dedicated_training_on_social_impacts','institution_have_an_assessment_tool_for_assessing','institution_provide_dedicated_training_on_environmental_aspects','support_services_for_disabilities','does_institution_have_dedicated_staff_member','manage_or_deliver_outreach_projects','health_and_wellbeing_services','equality_diversity_and_inclusionn_comittee','proportion_of_papers_publihsed','holistic_ethical_organisational_culture','university_student_union','publish_financial_reports','anti_bribery_policy','link_to_your_university_last_governance_meeting','does_your_university_governing_body_have_student_representation','your_institution_water_consumption','your_institution_energy_consumption','your_institution_carbon_emissions'],
            manualyHighlightQuestions = ['overall_students', 'undergraduate_students', 'graduate_postgraduate_students', 'overall_international_students', 'graduate_postgraduate_international_students', 'undergraduate_international_students','undergraduate_exchange_students_inbound','graduate_postgraduate_exchange_students_inbound','exchange_students_inbound','exchange_students_outbound','graduate_postgraduate_exchange_students_outbound','undergraduate_exchange_students_outbound'],
            validationMessages = {
                staffWithPhdVSFacultyStaff : 'Staff with PhD can not be greater than Faculty Staff',
                facultyStaffVSStudentsOverall : 'Faculty Staff can not be greater than Students Overall',
                postgraduateStudentsIsEmpty :'If Undergraduate Students is provided then Graduate/Postgraduate Students should be provided. At least one of the headers (FT, PT, HC or FTE) should be filled else, please provide an explanation in the messages box.',
                undergraduateStudentsIsEmpty : 'If Graduate/Postgraduate Students is provided then Undergraduate Students should be provided. At least one of the headers (FT, PT, HC or FTE) should be filled else, please provide an explanation in the messages box.',
                sumOfUgAndPgInternational:'The sum of Undergraduate International Students and Postgraduate International Students should be equal to Overall International Students',
                sumOfUgAndPgInboundOverall:'The sum of Undergraduate Exchange Students - Inbound and Postgraduate Inbound Exchange Students should be equal to Overall Exchange Students - Inbound',
                sumOfUgAndPgOutboundOverall:'The sum of Undergraduate Exchange Students - Outbound and Postgraduate Outbound Exchange Students should be equal to Overall Exchange Students - Outbound',
                sumOfUgAndPgOverall:'The sum of Undergraduate Overall Students and Postgraduate Overall Students should be equal to Overall Students',
                sumOfAllFacultyStaff:'The sum of Faculty Staff Male, Faculty Staff Female and Faculty Staff Other should be equal to Faculty Staff',
                sumOfAllStudents:'The sum of Students Male, Students Female and Students Other should be equal to Students-Overall'
            };


        return {
            resetAllAnswers: function () {
                overallStudentsAnswers = null;
                ugAndPgStudentsAnswers = null;
                facultyStaffAnswers = null;
                ugStudentsCurrentAnswers = null;
                pgStudentsCurrentAnswers = null;
                overallStudentsCurrentAnswers = null;
                overallInternationalStudentsCurrentAnswers = null;
                overAllInboundStudents = null;
                overAllOutboundStudents = null;
                staffWithPhdCurrentAnswers = null;
                facultyStaffCurrentAnswers = null;
                overallStudentsEmpty = false;
                ugAndPgStudentsEmpty = false;
                facultyStaffEmpty = false;
                overallStudentsCurrentEmpty = false;
                facultyStaffCurrentEmpty = false;
                overallStudentsPreviousIsEmpty = false;
                currentAnswers = {};
                previousAnswers = {};
                gerPreviousAnswers = {};
            },
            getIsNotInOtherRankingQuestions: function () {
                return isNotInOtherRankingQuestions;
            },
            setAnswers: function (value) {
                answers = value;
            },
            getAnswers: function () {
                return answers;
            },
            setPreviouslySubmittedValues: function (key, value) {
                previouslySubmittedValues[key] = value;
            },
            getPreviouslySubmittedValues: function (key) {
                return previouslySubmittedValues[key];
            },
            setEmploymentRateModified: function (value) {
                isEmploymentRateModified = value;
            },
            getEmploymentRateModified: function () {
                return isEmploymentRateModified;
            },
            resetPreviouslySubmittedValues: function () {
                 previouslySubmittedValues = {};
            },
            setActiveQuestionId: function (value) {
                activeQuestionId = value;
            },
            getActiveQuestionId: function () {
                return activeQuestionId;
            },
            setCategories: function (value) {
                categories = value;
            },
            getCategories: function () {
                return categories;
            },
            setUgStudentsCurrentAnswers: function (value) {
                ugStudentsCurrentAnswers = value;
            },
            getUgStudentsCurrentAnswers: function () {
                return ugStudentsCurrentAnswers;
            },
            setPgStudentsCurrentAnswers: function (value) {
                pgStudentsCurrentAnswers = value;
            },
            getPgStudentsCurrentAnswers: function () {
                return pgStudentsCurrentAnswers;
            },
            setUgAndPgStudentsAnswers: function (value) {
                ugAndPgStudentsAnswers = value;
            },
            getUgAndPgStudentsAnswers: function () {
                return ugAndPgStudentsAnswers;
            },
            setOverallStudentsCurrentAnswers: function (value) {
                overallStudentsCurrentAnswers = value;
            },
            getOverallStudentsCurrentAnswers: function () {
                return overallStudentsCurrentAnswers;
            },
            setInternationalOverallStudentsCurrentAnswers: function (value) {
                overallInternationalStudentsCurrentAnswers = value;
            },
            getInternationalOverallStudentsCurrentAnswers: function () {
                return overallInternationalStudentsCurrentAnswers;
            },
            setInboundOverallStudentsCurrentAnswers: function (value) {
                overAllInboundStudents = value;
            },
            getInboundOverallStudentsCurrentAnswers: function () {
                return overAllInboundStudents;
            },
            setOutboundOverallStudentsCurrentAnswers: function (value) {
                overAllOutboundStudents = value;
            },
            getOutboundOverallStudentsCurrentAnswers: function () {
                return overAllOutboundStudents;
            },
            setFacultyStaffCurrentAnswers: function (value) {
                facultyStaffCurrentAnswers = value;
            },
            getFacultyStaffCurrentAnswers: function () {
                return facultyStaffCurrentAnswers;
            },
            setStaffWithPhdCurrentAnswers: function (value) {
                staffWithPhdCurrentAnswers = value;
            },
            getStaffWithPhdCurrentAnswers: function () {
                return staffWithPhdCurrentAnswers;
            },
            setOverallStudentsAnswers: function (value) {
                overallStudentsAnswers = value;
            },
            getOverallStudentsAnswers: function () {
                return overallStudentsAnswers;
            },
            setFacultyStaffEmpty: function (value) {
                facultyStaffEmpty = value;
            },
            isFacultyStaffEmpty: function () {
                return facultyStaffEmpty;
            },
            setOverallStudentsCurrentEmpty: function (value) {
                overallStudentsCurrentEmpty = value;
            },
            isOverallStudentsCurrentEmpty: function () {
                return overallStudentsCurrentEmpty;
            },
            setFacultyStaffCurrentEmpty: function (value) {
                facultyStaffCurrentEmpty = value;
            },
            isFacultyStaffCurrentEmpty: function () {
                return facultyStaffCurrentEmpty;
            },
            setFacultyStaffAnswers: function (value) {
                facultyStaffAnswers = value;
            },
            getFacultyStaffAnswers: function () {
                return facultyStaffAnswers;
            },
            setOverallStudentsIsEmpty: function (value) {
                overallStudentsEmpty = value;
            },
            isOverallStudentsEmpty: function () {
                return overallStudentsEmpty;
            },
            setOverallStudentsPreviousIsEmpty: function (value) {
                overallStudentsPreviousIsEmpty = value;
            },
            isOverallStudentsPreviousIsEmpty: function () {
                return overallStudentsPreviousIsEmpty;
            },
            setExplainationProvidedQuestion: function (value) {
                if(!explanationRequiredQuestions.includes(value)) {
                    explanationRequiredQuestions.push(value);
                }
            },
            getExplainationProvidedQuestion: function (value) {
                return explanationRequiredQuestions;
            },
            isExplainationProvided: function (value) {
                return explanationRequiredQuestions.includes(value);
            },
            resetExplanationRequiredQuestions: function () {
                explanationRequiredQuestions = [];
            },
            isDataRequest: function () {
                return isDataRequest;
            },
            requestData: function () {
                data = [];
                isDataRequest = true;
            },
            resetData: function () {
                data = [];
                isDataRequest = false;
            },
            broadcastData: function (value) {
                data.push(value);
            },
            getData: function () {
                return data;
            },
            hasChanges: function () {
                return hasChanges;
            },
            setHasChanges: function (has) {
                hasChanges = has;
            },
            setDatagridReload: function (value) {
                reloadDatagrid = value;
            },
            isDatagridReload: function () {
                return reloadDatagrid;
            },
            getCompareableQuestions: function () {
                return compareableQuestions;
            },
            getManualyHighlightQuestions: function () {
                return manualyHighlightQuestions;
            },
            getValidationMessages: function () {
                return validationMessages;
            },
            setValidationErrors: function (error) {
                var index;
                validationErrors.some(function (elem, i) {
                    return elem.field === error.field && elem.rule === error.rule ? (index = i ===0 ? '0' : i, true) : false;
                });
                if (!index) {
                   
                    validationErrors.push(angular.copy( error));
                     
                    angular.forEach(validationErrors, function (fieldName) {
                        if(filteredValidationErrors.indexOf(fieldName.rule) === -1) {
                            filteredValidationErrors.push(angular.copy(fieldName.rule) );
                        }
                    });
                }
            },
            removeValidationErrors: function (error) {
                // var tempArray = filteredValidationErrors;
                var index;
                validationErrors.some(function (elem, i) {
                    return elem.field === error.field && elem.rule === error.rule ? (index = i, true) : false;
                });
                if (index || index === 0) {
                    validationErrors.splice(index, 1);
                    filteredValidationErrors =[];
                    angular.forEach(validationErrors, function (fieldName) {
                        if(filteredValidationErrors.indexOf(fieldName.rule) === -1) {
                            filteredValidationErrors.push(angular.copy(fieldName.rule));
                        }
                    });
                   
                }
                // Old logic
                // if (index || index === 0) {
                //     // validationErrors.splice(index, 1);
                //       //tempArray = [];
                //      angular.forEach(validationErrors, function (fieldName) {
                //         // tempArray.push(fieldName.rule);
                //         var secondIndex = filteredValidationErrors.indexOf(fieldName.rule);
                //         console.log('secondIndex=',secondIndex)
                //          if(secondIndex !== -1) {
                //              filteredValidationErrors.splice(secondIndex, 1);
                //          }
                //      });
                //      validationErrors.splice(index, 1);
                //  }
                // End Old logic
                //   filteredValidationErrors = tempArray; 
            },
            getValidationErrors: function () {
                return filteredValidationErrors;
            },
            getValidationErrorsWithFields: function () {
                return validationErrors;
            },
            resetValidationErrors: function () {
                validationErrors = [];
                filteredValidationErrors = [];
            },
            removeFilteredValidationErrors: function (error) {
                var index = filteredValidationErrors.indexOf(error);
                if (index || index === 0) {
                    filteredValidationErrors.splice(index, 1);
                }
            },
            setFilteredValidationErrors: function (error) {
                filteredValidationErrors.push(angular.copy(error));
            },
            setCurrentAnswer: function (questionHandle, answer) {
                currentAnswers[questionHandle] = answer;
            },
            getCurrentAnswer: function (questionHandle) {
                return currentAnswers[questionHandle]? currentAnswers[questionHandle] : {};
            },
            resetCurrentAnswer: function () {
                currentAnswers = {};
            },
            setPreviousAnswer: function (questionHandle, answer) {
                previousAnswers[questionHandle] = angular.copy(answer);
            },
            getPreviousAnswer: function (questionHandle) {
                return previousAnswers[questionHandle];
            },
            resetPreviousAnswer: function () {
                previousAnswers = {};
            },
            getAllCurrentAnswers: function () {
                return currentAnswers;
            },
            setCurrentRanking : function (ranking) {
                currentRanking = ranking;
            },
            getCurrentRanking : function () {
                return currentRanking;
            },
            setQuestionsOfChangedStatuses : function (questionHandle) {
                if (questionsOfChangesStatuses.indexOf(questionHandle) === -1) {
                    questionsOfChangesStatuses.push(angular.copy(questionHandle));
                }
            },
            getQuestionsOfChangedStatuses : function () {
                return questionsOfChangesStatuses;
            },
            isStatusManualyChanged : function (questionHandle) {
                return questionsOfChangesStatuses.indexOf(questionHandle) !== -1;
            },
            resetQuestionsOfChangedStatuses : function () {
                questionsOfChangesStatuses = [];
            },
            setInvalidIndicatorsData : function (handle, data) {
                invalidIndicatorsData[handle] = data;
            },
            getInvalidIndicatorsData: function (handle) {
                return invalidIndicatorsData[handle];
            },
            resetInvalidIndicatorsData: function () {
                invalidIndicatorsData = {};
            },
            setInvalidIndicators : function (handle, val) {
                invalidIndicators[handle] = val;
            },
            getInvalidIndicator: function (handle) {
                return invalidIndicators[handle];
            },
            resetInvalidIndicators: function () {
                invalidIndicators = {};
            },
            setGerPreviousAnswers : function (data) {
                gerPreviousAnswers = data;
            },
            getGerPreviousAnswers: function () {
                return gerPreviousAnswers;
            },
            resetGerPreviousAnswers: function () {
                gerPreviousAnswers = {};
            },
            isMessageSent:function () {
                return messageSent;
            },
            setMessageSent:function (value) {
                messageSent = value;
            },
            setVariationLogMessage: function (value) {
                variationLogMessage = value;
            },
            getVariationLogMessage: function () {
                return variationLogMessage;
            }
        };
    }

}(window.angular));

(function(angular, moment) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    App.services.StatisticsSubmissions = function (
        $resource,
        $log,
        constants,
        StatisticsSubmissionsFactory
    ) {
        var service = {};

        service.getModel = function () {
            return $resource(constants.api.rankings.url + '/v1/answers/institution/:institutionCoreId', null, {
                update: { method: 'PATCH' }
            });
        };

        service.update = function (institutionCoreId, data) {
            return service.getModel().update({
                institutionCoreId: institutionCoreId
            },data).$promise.then(function (response) {
                if (constants.dev) {
                    $log.log('success, got data: ', response);
                }
                return response;
            }, function (error) {
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        service.getQuestionByHandle = function (questions, questionHandle) {
            var i = 0,
                total = questions.length;
            for (i; i < total; i++) {
                if (questions[i].handle === questionHandle) {
                    return questions[i];
                }
            }
            return null;
        };

        service.setValidationErrors = function (rule, field, questionHandle, second) {
             second = second ? second : 3;
             StatisticsSubmissionsFactory.setInvalidIndicators(questionHandle, second);
            // if (StatisticsSubmissionsFactory.getValidationErrors().indexOf(rule) === -1) {
                StatisticsSubmissionsFactory.setValidationErrors({
                    field: field,
                    rule: rule
                });
            // }
        };
        service.removeValidationErrors = function (rule, field, questionHandle, second) {
            second = second ? second : 3;
            // if (StatisticsSubmissionsFactory.getValidationErrors().indexOf(rule) !== -1) {
                StatisticsSubmissionsFactory.removeValidationErrors({
                    field: field,
                    rule: rule
                });
             
            // }
              if (StatisticsSubmissionsFactory.getValidationErrors().indexOf(rule) === -1) {
                if (StatisticsSubmissionsFactory.getInvalidIndicator(questionHandle) && StatisticsSubmissionsFactory.getInvalidIndicator(questionHandle) === second) {
                    StatisticsSubmissionsFactory.setInvalidIndicators(questionHandle, false);
                }
            }
        };

        service.getRatio = function (cValue, answer, isSwapped) {
            var ratio = 0;
            if (cValue.fte && answer.fte) {
                if (isSwapped) {
                    ratio = parseFloat(answer.fte.value) / parseFloat(cValue.fte);
                } else {
                    ratio = parseFloat(answer.fte) / parseFloat(cValue.fte.value);
                }

            } else if (cValue.ft && cValue.pt &&
                answer.ft &&
                answer.pt) {
                    if(isSwapped){
                        ratio = ((parseFloat(answer.ft.value) + parseFloat(answer.pt.value)) / 3) / ((parseFloat(cValue.ft) + parseFloat(cValue.pt)) / 3);
                    }
                    else {
                        ratio = ((parseFloat(answer.ft) + parseFloat(answer.pt)) / 3) / ((parseFloat(cValue.ft.value) + parseFloat(cValue.pt.value)) / 3);
                    }
            } else if (cValue.hc && answer.hc) {
                if(isSwapped){
                    ratio = parseFloat(answer.hc.value) / parseFloat(cValue.hc);
                }
                else {
                    ratio = parseFloat(answer.hc) / parseFloat(cValue.hc.value);
                }
            }

            return angular.isNumber(ratio) && !isNaN(ratio) ? ratio : 0;
        };

        service.handleSum = function (questionHandle) {
            var overallStudentsOverall = StatisticsSubmissionsFactory.getCurrentAnswer('overall_students'),
                pgStudentsOverall = StatisticsSubmissionsFactory.getCurrentAnswer('graduate_postgraduate_students'),
                ugStudentsOverall = StatisticsSubmissionsFactory.getCurrentAnswer('undergraduate_students'),
                overallStudentsInternational = StatisticsSubmissionsFactory.getCurrentAnswer('overall_international_students'),
                pgStudentsInternational = StatisticsSubmissionsFactory.getCurrentAnswer('graduate_postgraduate_international_students'),
                ugStudentsInternational = StatisticsSubmissionsFactory.getCurrentAnswer('undergraduate_international_students'),

                overallStudentsInbound = StatisticsSubmissionsFactory.getCurrentAnswer('exchange_students_inbound'),
                pgStudentsInbound = StatisticsSubmissionsFactory.getCurrentAnswer('graduate_postgraduate_exchange_students_inbound'),
                ugStudentsInbound = StatisticsSubmissionsFactory.getCurrentAnswer('undergraduate_exchange_students_inbound'),

                overallStudentsOutbound = StatisticsSubmissionsFactory.getCurrentAnswer('exchange_students_outbound'),
                pgStudentsOutbound = StatisticsSubmissionsFactory.getCurrentAnswer('graduate_postgraduate_exchange_students_outbound'),
                ugStudentsOutbound = StatisticsSubmissionsFactory.getCurrentAnswer('undergraduate_exchange_students_outbound'),

                facultyStaff = StatisticsSubmissionsFactory.getCurrentAnswer('faculty_staff'),
                facultyStaffMale = StatisticsSubmissionsFactory.getCurrentAnswer('faculty_staff_male'),
                facultyStaffFemale = StatisticsSubmissionsFactory.getCurrentAnswer('faculty_staff_female'),
                facultyStaffOther = StatisticsSubmissionsFactory.getCurrentAnswer('faculty_staff_other'),

                studentsMale = StatisticsSubmissionsFactory.getCurrentAnswer('number_of_male_students'),
                studentsFemale = StatisticsSubmissionsFactory.getCurrentAnswer('students_female'),
                studentsOther = StatisticsSubmissionsFactory.getCurrentAnswer('students_other'),

                ugAndPgInternational = {},
                ugAndPgOverall = {},
                ugAndPgInboundOverall = {},
                ugAndPgOutboundOverall = {},
                facultyStaffAll = {},
                studentsAll = {};

            angular.forEach(['ft', 'pt', 'hc', 'fte'], function (fieldName) {
                if ((pgStudentsOverall && pgStudentsOverall[fieldName]) || (ugStudentsOverall && ugStudentsOverall[fieldName])) {
                    ugAndPgOverall[fieldName] = {
                        value: (pgStudentsOverall && pgStudentsOverall[fieldName] ? parseFloat(pgStudentsOverall[fieldName].value) : 0) + (ugStudentsOverall && ugStudentsOverall[fieldName] ? parseFloat(ugStudentsOverall[fieldName].value) : 0)
                    };
                }
                if ((pgStudentsInternational && pgStudentsInternational[fieldName]) || (ugStudentsInternational && ugStudentsInternational[fieldName])) {
                    ugAndPgInternational[fieldName] = {
                        value: (pgStudentsInternational && pgStudentsInternational[fieldName] ? parseFloat(pgStudentsInternational[fieldName].value) : 0) + (ugStudentsInternational && ugStudentsInternational[fieldName] ? parseFloat(ugStudentsInternational[fieldName].value) : 0)
                    };
                }
                if (pgStudentsInbound[fieldName] || ugStudentsInbound[fieldName]) {
                    ugAndPgInboundOverall[fieldName] = {
                        value: (pgStudentsInbound[fieldName] ? parseFloat(pgStudentsInbound[fieldName].value) : 0) + (ugStudentsInbound[fieldName] ? parseFloat(ugStudentsInbound[fieldName].value) : 0)
                    };
                }
                if (pgStudentsOutbound[fieldName] || ugStudentsOutbound[fieldName]) {
                    ugAndPgOutboundOverall[fieldName] = {
                        value: (pgStudentsOutbound[fieldName] ? parseFloat(pgStudentsOutbound[fieldName].value) : 0) + (ugStudentsOutbound[fieldName] ? parseFloat(ugStudentsOutbound[fieldName].value) : 0)
                    };
                }
                if (facultyStaffMale[fieldName] || facultyStaffFemale[fieldName] || facultyStaffOther[fieldName]) {
                    facultyStaffAll[fieldName] = {
                        value: (facultyStaffMale[fieldName] ? parseFloat(facultyStaffMale[fieldName].value) : 0) + (facultyStaffFemale[fieldName] ? parseFloat(facultyStaffFemale[fieldName].value) : 0) + (facultyStaffOther[fieldName] ? parseFloat(facultyStaffOther[fieldName].value) : 0)
                    };
                }
                if (studentsMale[fieldName] || studentsFemale[fieldName] || studentsOther[fieldName]) {
                    studentsAll[fieldName] = {
                        value: (studentsMale[fieldName] ? parseFloat(studentsMale[fieldName].value) : 0) + (studentsFemale[fieldName] ? parseFloat(studentsFemale[fieldName].value) : 0) + (studentsOther[fieldName] ? parseFloat(studentsOther[fieldName].value) : 0)
                    };
                }

                if (questionHandle === 'undergraduate_students' ||
                    questionHandle === 'graduate_postgraduate_students' ||
                    questionHandle === 'overall_students') {
                    if (ugAndPgOverall[fieldName] && ugAndPgOverall[fieldName].value) {
                        if (overallStudentsOverall && overallStudentsOverall[fieldName] && overallStudentsOverall[fieldName].value) {
                            if (parseFloat(overallStudentsOverall[fieldName].value).toFixed(2) !== ugAndPgOverall[fieldName].value.toFixed(2)) {
                                service.setValidationErrors('sumOfUgAndPgOverall', fieldName, questionHandle);
                            } else {
                                service.removeValidationErrors('sumOfUgAndPgOverall', fieldName, questionHandle);
                            }

                        } else {
                            service.setValidationErrors('sumOfUgAndPgOverall', fieldName, questionHandle);
                        }
                    } else {
                        service.removeValidationErrors('sumOfUgAndPgOverall', fieldName, questionHandle);
                    }
                }

                if (questionHandle === 'undergraduate_international_students' ||
                    questionHandle === 'graduate_postgraduate_international_students' ||
                    questionHandle === 'overall_international_students') {
                    if (ugAndPgInternational[fieldName] && ugAndPgInternational[fieldName].value) {
                        if (overallStudentsInternational[fieldName] && overallStudentsInternational[fieldName].value) {
                            if (parseFloat(overallStudentsInternational[fieldName].value).toFixed(2) !== ugAndPgInternational[fieldName].value.toFixed(2)) {
                                service.setValidationErrors('sumOfUgAndPgInternational', fieldName, questionHandle);
                            } else {
                                service.removeValidationErrors('sumOfUgAndPgInternational', fieldName, questionHandle);
                            }

                        } else {
                            service.setValidationErrors('sumOfUgAndPgInternational', fieldName, questionHandle);
                        }
                    } else {
                        service.removeValidationErrors('sumOfUgAndPgInternational', fieldName, questionHandle);
                    }
                }

                if (questionHandle === 'undergraduate_exchange_students_inbound' ||
                    questionHandle === 'graduate_postgraduate_exchange_students_inbound' ||
                    questionHandle === 'exchange_students_inbound') {
                    if (ugAndPgInboundOverall[fieldName] && ugAndPgInboundOverall[fieldName].value) {
                        if (overallStudentsInbound[fieldName] && overallStudentsInbound[fieldName].value) {
                            if (parseFloat(overallStudentsInbound[fieldName].value).toFixed(2) !== ugAndPgInboundOverall[fieldName].value.toFixed(2)) {
                                service.setValidationErrors('sumOfUgAndPgInboundOverall', fieldName, questionHandle);
                            } else {
                                service.removeValidationErrors('sumOfUgAndPgInboundOverall', fieldName, questionHandle);
                            }

                        } else {
                            service.setValidationErrors('sumOfUgAndPgInboundOverall', fieldName, questionHandle);
                        }
                    } else {
                        service.removeValidationErrors('sumOfUgAndPgInboundOverall', fieldName, questionHandle);
                    }
                }
                if (questionHandle === 'undergraduate_exchange_students_outbound' ||
                    questionHandle === 'graduate_postgraduate_exchange_students_outbound' ||
                    questionHandle === 'exchange_students_outbound') {
                    if (ugAndPgOutboundOverall[fieldName] && ugAndPgOutboundOverall[fieldName].value) {
                        if (overallStudentsOutbound[fieldName] && overallStudentsOutbound[fieldName].value) {
                            if (parseFloat(overallStudentsOutbound[fieldName].value).toFixed(2) !== ugAndPgOutboundOverall[fieldName].value.toFixed(2)) {
                                service.setValidationErrors('sumOfUgAndPgOutboundOverall', fieldName, questionHandle);
                            } else {
                                service.removeValidationErrors('sumOfUgAndPgOutboundOverall', fieldName, questionHandle);
                            }

                        } else {
                            service.setValidationErrors('sumOfUgAndPgOutboundOverall', fieldName, questionHandle);
                        }
                    } else {
                        service.removeValidationErrors('sumOfUgAndPgOutboundOverall', fieldName, questionHandle);
                    }
                }

                if (questionHandle === 'faculty_staff_female' ||
                    questionHandle === 'faculty_staff_other') {
                    if (facultyStaffAll[fieldName] && facultyStaffAll[fieldName].value) {
                        if (facultyStaff[fieldName] && facultyStaff[fieldName].value) {
                            if (parseFloat(facultyStaff[fieldName].value).toFixed(2) !== facultyStaffAll[fieldName].value.toFixed(2)) {
                                service.setValidationErrors('sumOfAllFacultyStaff', fieldName, questionHandle);
                            } else {
                                service.removeValidationErrors('sumOfAllFacultyStaff', fieldName, questionHandle);
                            }

                        } else {
                            service.setValidationErrors('sumOfAllFacultyStaff', fieldName, questionHandle);
                        }
                    } else {
                        service.removeValidationErrors('sumOfAllFacultyStaff', fieldName, questionHandle);
                    }

                }

                if (questionHandle === 'students_female' ||
                    questionHandle === 'students_other') {
                        if (studentsAll[fieldName] && studentsAll[fieldName].value) {
                            if (overallStudentsOverall[fieldName] && overallStudentsOverall[fieldName].value) {
                                if (parseFloat(overallStudentsOverall[fieldName].value).toFixed(2) !== studentsAll[fieldName].value.toFixed(2)) {
                                    service.setValidationErrors('sumOfAllStudents', fieldName, questionHandle);
                                } else {
                                    service.removeValidationErrors('sumOfAllStudents', fieldName, questionHandle);
                                }
        
                            } else {
                                service.setValidationErrors('sumOfAllStudents', fieldName, questionHandle);
                            }
                        } else {
                            service.removeValidationErrors('sumOfAllStudents', fieldName, questionHandle);
                        }
                    }

            });
        };

        service.isEmpty = function (item) {

            return item ? !item.ft && !item.pt && !item.hc && !item.fte : true;
        };

        service.isNotEmpty = function (item) {

            return item && (item.ft || item.pt || item.hc || item.fte);
        };

        service.handleComparision = function (questionHandle) {
            var overallStudents = StatisticsSubmissionsFactory.getCurrentAnswer('overall_students'),
                facultyStaff = StatisticsSubmissionsFactory.getCurrentAnswer('faculty_staff'),
                staffWithPHD = StatisticsSubmissionsFactory.getCurrentAnswer('staff_with_phd'),
                pgStudents = StatisticsSubmissionsFactory.getCurrentAnswer('graduate_postgraduate_students'),
                ugStudents = StatisticsSubmissionsFactory.getCurrentAnswer('undergraduate_students'),
                rankingHandle = StatisticsSubmissionsFactory.getCurrentRanking();

            if (questionHandle === 'faculty_staff' || questionHandle === 'overall_students') {
                service.compareValues(facultyStaff, overallStudents, 'facultyStaffVSStudentsOverall', false, questionHandle);
                if (questionHandle === 'faculty_staff' &&
                    rankingHandle !== 'wur' && rankingHandle !== 'eeca' && rankingHandle !== 'ger' && rankingHandle !== 'esg') {
                    service.compareValues(staffWithPHD, facultyStaff, 'staffWithPhdVSFacultyStaff', false, questionHandle);
                }
            }
            if (questionHandle === 'staff_with_phd' &&
                rankingHandle !== 'wur' && rankingHandle !== 'eeca' && rankingHandle !== 'ger' && rankingHandle !== 'esg') {
                service.compareValues(staffWithPHD, facultyStaff, 'staffWithPhdVSFacultyStaff', false, questionHandle);
            }
            if (questionHandle === 'undergraduate_students' || questionHandle === 'graduate_postgraduate_students') {
                service.compareValues(facultyStaff, overallStudents, 'facultyStaffVSStudentsOverall', false, questionHandle);

                if (questionHandle === 'undergraduate_students') {
                    if (service.isEmpty(ugStudents)) {
                        if (service.isNotEmpty(pgStudents)) {
                            service.setValidationErrors('undergraduateStudentsIsEmpty', 'ft', 'undergraduate_students', 2);
                        }
                        // else{
                        //    // service.removeValidationErrors('undergraduateStudentsIsEmpty', 'ft','undergraduate_students', 2);
                        // }
                    } else {
                        service.removeValidationErrors('undergraduateStudentsIsEmpty', 'ft','undergraduate_students', 2);
                    }
                }
                if (questionHandle === 'graduate_postgraduate_students') {
                    if (service.isEmpty(pgStudents)) {
                        if (service.isNotEmpty(ugStudents)) {
                            service.setValidationErrors('postgraduateStudentsIsEmpty', 'ft','graduate_postgraduate_students',2);
                        }
            //             else{
            //  //               service.removeValidationErrors('postgraduateStudentsIsEmpty', 'ft','graduate_postgraduate_students',2);
            //             }
                    } else {
                        service.removeValidationErrors('postgraduateStudentsIsEmpty', 'ft','graduate_postgraduate_students',2);
                    }
                }
            }
        };

        service.compareValues = function (firstValue, secondValue, ruleType, secondRound, questionHandle) {
            if (!firstValue || (!firstValue.ft && !firstValue.pt && !firstValue.hc && !firstValue.fte)) {
                if (StatisticsSubmissionsFactory.getValidationErrors().indexOf(ruleType) !== -1) {
                    StatisticsSubmissionsFactory.removeFilteredValidationErrors(ruleType);
                }
                return;
            }
            var ugAndPg = {};
            if(questionHandle === 'overall_international_students'){
                ugAndPg = service.getUgAndPgStudentsCurrentAnswers(false, {current: true});
            }
            else if(questionHandle === 'exchange_students_inbound'){
                ugAndPg = service.getUgAndPgStudentsCurrentAnswers(false, false, {current: true});
            }
            else if(questionHandle === 'exchange_students_outbound'){
                ugAndPg = service.getUgAndPgStudentsCurrentAnswers(false, false, false,{current: true});
            }
            else {
                ugAndPg = service.getUgAndPgStudentsCurrentAnswers({current: true});
            }
            
            if (ruleType === 'facultyStaffVSStudentsOverall') {
                if (StatisticsSubmissionsFactory.isOverallStudentsCurrentEmpty()) {
                    secondValue = ugAndPg;
                } else {
                    if ((!secondValue.fte && !secondValue.hc && !secondValue.pt && secondValue.ft) ||
                        (!secondValue.fte && !secondValue.hc && secondValue.pt && !secondValue.ft)) {
                        secondValue = ugAndPg;
                    }
                }
            }

            if (secondValue && (secondValue.ft || secondValue.pt || secondValue.hc || secondValue.fte)) {
                if (firstValue.fte && secondValue.fte) {
                    var firstFteParsed = parseInt(firstValue.fte.value, 10),
                        secondFteParsed = parseInt(secondValue.fte.value, 10),
                        firstFteValue = angular.isNumber(firstFteParsed) && !isNaN(firstFteParsed) ? firstFteParsed : 0,
                        secondFteValue = angular.isNumber(secondFteParsed) && !isNaN(secondFteParsed) ? secondFteParsed : 0;
                    if (firstFteValue > secondFteValue) {
                        service.handleSetValidation(ruleType, questionHandle);

                    } else {
                        service.handleRemoveValidation(ruleType, questionHandle);
                    }

                } else if (firstValue.ft && firstValue.pt &&
                    secondValue.ft &&
                    secondValue.pt) {
                    var fFt = parseInt(firstValue.ft.value, 10),
                        fPt = parseInt(firstValue.pt.value, 10),
                        sFt = parseInt(secondValue.ft.value, 10),
                        sPt = parseInt(secondValue.pt.value, 10),
                        fFtValue = angular.isNumber(fFt) && !isNaN(fFt) ? fFt : 0,
                        fPtValue = angular.isNumber(fPt) && !isNaN(fPt) ? fPt : 0,
                        sFtValue = angular.isNumber(sFt) && !isNaN(sFt) ? sFt : 0,
                        sPtValue = angular.isNumber(sPt) && !isNaN(sPt) ? sPt : 0,
                        fCalculated = (fFtValue + fPtValue) / 3,
                        sCalculated = (sFtValue + sPtValue) / 3;
                    if (fCalculated > sCalculated) {
                        service.handleSetValidation(ruleType, questionHandle);
                    } else {
                        service.handleRemoveValidation(ruleType, questionHandle);
                    }
                } else if (firstValue.hc && secondValue.hc) {
                    var firstHcParsed = parseInt(firstValue.hc.value, 10),
                        secondHcParsed = parseInt(secondValue.hc.value, 10),
                        firstHcValue = angular.isNumber(firstHcParsed) && !isNaN(firstHcParsed) ? firstHcParsed : 0,
                        secondHcValue = angular.isNumber(secondHcParsed) && !isNaN(secondHcParsed) ? secondHcParsed : 0;
                    if (firstHcValue > secondHcValue) {
                        service.handleSetValidation(ruleType, questionHandle);

                    } else {
                        service.handleRemoveValidation(ruleType, questionHandle);
                    }
                } else if (ruleType === 'facultyStaffVSStudentsOverall' && !secondRound) {

                    service.compareValues(firstValue, ugAndPg, ruleType, true, questionHandle);
                } else {
                    service.handleRemoveValidation(ruleType, questionHandle);
                }
            }
        };

        service.handleSetValidation = function (ruleType, questionHandle) {
            var tempValue = ruleType === 'staffWithPhdVSFacultyStaff' ? 4:1;
            StatisticsSubmissionsFactory.setInvalidIndicators(questionHandle, tempValue);
            if (StatisticsSubmissionsFactory.getValidationErrors().indexOf(ruleType) === -1) {
                StatisticsSubmissionsFactory.setFilteredValidationErrors(ruleType);
            }
        };

        service.handleRemoveValidation = function (ruleType, questionHandle) {
            var tempValue = ruleType === 'staffWithPhdVSFacultyStaff' ? 4:1;
            if (StatisticsSubmissionsFactory.getInvalidIndicator(questionHandle) && StatisticsSubmissionsFactory.getInvalidIndicator(questionHandle) === tempValue) {
                StatisticsSubmissionsFactory.setInvalidIndicators(questionHandle, false);
            }
            if (StatisticsSubmissionsFactory.getValidationErrors().indexOf(ruleType) !== -1) {
                StatisticsSubmissionsFactory.removeFilteredValidationErrors(ruleType);
            }
        };

        service.getUgAndPgStudentsCurrentAnswers = function (ugPg, ugPgInt, IB, OB) {
            var ugAndPgStudentsSum = {}, ug = {},pg = {};
            if(ugPg){
                if(ugPg.current){
                    ug = StatisticsSubmissionsFactory.getCurrentAnswer('undergraduate_students');
                    pg = StatisticsSubmissionsFactory.getCurrentAnswer('graduate_postgraduate_students');
                }
                else {
                    ug = StatisticsSubmissionsFactory.getPreviousAnswer('undergraduate_students');
                    pg = StatisticsSubmissionsFactory.getPreviousAnswer('graduate_postgraduate_students');
                }
            }
            if(ugPgInt){
                if(ugPgInt.current){
                    ug = StatisticsSubmissionsFactory.getCurrentAnswer('undergraduate_international_students');
                    pg = StatisticsSubmissionsFactory.getCurrentAnswer('graduate_postgraduate_international_students');
                }
                else {
                    ug = StatisticsSubmissionsFactory.getPreviousAnswer('undergraduate_international_students');
                    pg = StatisticsSubmissionsFactory.getPreviousAnswer('graduate_postgraduate_international_students');
                }
            }
            if(IB){
                if(IB.current){
                    ug = StatisticsSubmissionsFactory.getCurrentAnswer('undergraduate_exchange_students_inbound');
                    pg = StatisticsSubmissionsFactory.getCurrentAnswer('graduate_postgraduate_exchange_students_inbound');
                }
                else {
                    ug = StatisticsSubmissionsFactory.getPreviousAnswer('undergraduate_exchange_students_inbound');
                    pg = StatisticsSubmissionsFactory.getPreviousAnswer('graduate_postgraduate_exchange_students_inbound');
                }
            }
            if(OB){
                if(OB.current){
                    ug = StatisticsSubmissionsFactory.getCurrentAnswer('undergraduate_exchange_students_outbound');
                    pg = StatisticsSubmissionsFactory.getCurrentAnswer('graduate_postgraduate_exchange_students_outbound');
                }
                else {
                    ug = StatisticsSubmissionsFactory.getPreviousAnswer('undergraduate_exchange_students_outbound');
                    pg = StatisticsSubmissionsFactory.getPreviousAnswer('graduate_postgraduate_exchange_students_outbound');
                }
            }
            
            if ((ug && ug.ft) || (pg && pg.ft)) {
                ugAndPgStudentsSum.ft = {
                    value: (ug && ug.ft ?
                        parseFloat(ug.ft.value) : 0) + (pg && pg.ft ? parseFloat(pg.ft.value) : 0)
                };
            }
            if ((ug && ug.pt) || (pg && pg.pt)) {
                ugAndPgStudentsSum.pt = {
                    value: (ug && ug.pt ?
                        parseFloat(ug.pt.value) : 0) + (pg && pg.pt ? parseFloat(pg.pt.value) : 0)
                };
            }
            if ((ug && ug.hc) || (pg && pg.hc)) {
                ugAndPgStudentsSum.hc = {
                    value: (ug && ug.hc ?
                        parseFloat(ug.hc.value) : 0) + (pg && pg.hc ? parseFloat(pg.hc.value) : 0)
                };
            }
            if ((ug && ug.fte) || (pg && pg.fte)) {
                ugAndPgStudentsSum.fte = {
                    value: (ug && ug.fte ?
                        parseFloat(ug.fte.value) : 0) + (pg && pg.fte ? parseFloat(pg.fte.value) : 0)
                };
            }

            return ugAndPgStudentsSum;
        };

        return service;
    };

    angular
        .module('qsHub.rankings')
        .service('StatisticsSubmissionsService', [
            '$resource',
            '$log',
            'constants',
            'StatisticsSubmissionsFactory',
            App.services.StatisticsSubmissions
        ]);

}(window.angular, window.moment));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {
        components:{}
    });

    App.components.statisticsPersonalQuestion = {
        templateUrl: '/scripts/modules/rankings/components/statisticsSubmissions/statisticsPersonalQuestion/statisticsPersonalQuestionView.html',
        controller: 'Rankings.StatisticsPersonalQuestionController',
        bindings: {
            question: '<',
            ranking:'<'
        }
    };

    angular.module('qsHub.rankings').component('statisticsPersonalQuestion', App.components.statisticsPersonalQuestion);

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.rankings')
        .controller('Rankings.StatisticsPersonalQuestionController', [
            'constants',
            '$timeout',
            '$scope',
            '$location',
            'broadcast',
            'WatchService',
            'StatisticsSubmissionsFactory',
            'StatisticsDashboardFactory',
            'UserFactory',
            'UrlService',
            'ObjectService',
            'AuthenticationService',
            'TextService',
            '$state',
            'NotifierFactory',
            StatisticsPersonalQuestion
        ]);

    function StatisticsPersonalQuestion(constants,
        $timeout,
        $scope,
        $location,
        broadcast,
        WatchService,
        StatisticsSubmissionsFactory,
        StatisticsDashboardFactory,
        UserFactory,
        UrlService,
        ObjectService,
        AuthenticationService,
        TextService,
        $state,
        NotifierFactory) {
        var controller = this,
        acceptedFiles = [
            'application/csv',
            'text/csv',
            'application/doc',
            'application/docx',
            'application/xlsx',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/xls',
            'application/vnd.ms-excel'
        ];
        controller.id = null;
        controller.answersInit = {
            ft: null,
            pt: null,
            hc: null,
            fte: null,
            estimate: null
        };
        controller.answers = controller.answersBeforeChanges = angular.copy(controller.answersInit);
        controller.status = null;
        controller.statusList = StatisticsDashboardFactory.getStatuses();
        controller.isNotInOtherRankingQuestions = StatisticsSubmissionsFactory.getIsNotInOtherRankingQuestions();
        controller.hasFocus = false;
        controller.isClient = UserFactory.isClient();
        controller.maxSize = 10;
        controller.uploadInProgress = false;
        controller.uploadEnabled = true;
        controller.dropZoneInstance = null;
        controller.descriptionWords = 0;
        controller.textEditorOptions = {
            disableDragAndDrop: true,
            styleWithSpan: false,
            dialogsInBody: true,
            minHeight: 100,
            maxHeight: 280,
            toolbar: [
                ['style', ['bold', 'italic', 'underline', 'superscript', 'subscript', 'strikethrough']],
                ['alignment', ['ul', 'ol']],
                ['insert', ['link', 'unlink']],
                ['edit', ['undo', 'redo']]
            ],
            popover: {
                link: []
            }
        };
        controller.config = {
            dropzone: {
                url: constants.api.rankings.url + '/v1/all-ranking/nationalities-upload',
                maxFilesize: controller.maxSize,
                maxFiles: 1,
                uploadMultiple: false,
                parallelUploads: 1,
                autoProcessQueue: true,
                addRemoveLinks: false,
                acceptedFiles:  acceptedFiles.join(','),
                previewsContainer: false,
                headers: AuthenticationService.getAuthorizationHeader(),
                paramName: 'file',
                init: function () {
                    controller.dropZoneInstance = this;
                },
            },
            eventHandlers: {
                addedfile: handleFileAdded,
                success: handleUploadSuccess,
                error: handleUploadError,
            }
        };

        function handleFileAdded() {
            $timeout(function() {
                controller.uploadInProgress = true;
                controller.uploadEnabled = false;
            });
        }

        function handleUploadError() {
            NotifierFactory.show(
                'error',
                'Can\'t upload document at the moment. Please try again later.',
                'Evidence Upload'
            );
            $timeout(function () {
                controller.handleRemove();
            });
        }

        function handleUploadSuccess(instance, response) {
            if (response.status && response.status && response.path) {
                $timeout(function () {
                    controller.upload = response;
                    controller.upload.name = instance.name;
                    controller.uploadInProgress = false;
                    controller.answers.fileUrl = response.path;
                });
            } else {
                handleUploadError();
            }
        }

        controller.isNotInOtherRanking = function (handle) {
            if (controller.ranking !== 'esg') {
                return true;
            } else {
                return controller.isNotInOtherRankingQuestions.indexOf(handle) === -1;
            }
        };

        controller.handleClipboardPaste = function (modelName) {
            $timeout(function() {
                controller.answers[modelName] = TextService.clearAttributes(controller.answers[modelName]);
            }, 65);
        };

        controller.handleRemove = function () {
            controller.uploadEnabled = true;
            if (controller.dropZoneInstance) {
                controller.dropZoneInstance.removeAllFiles();
            }
            controller.upload = null;
            controller.uploadInProgress = false;
        };

        controller.setInvalidDescription = function (descLength) {
            return descLength ? controller.setIsDescriptionEmpty(false) : controller.setIsDescriptionEmpty(true);
        };

        controller.setIsDescriptionEmpty = function (value) {
            controller.isDescriptionEmpty = value;
        };

        $scope.countWords = function (text) {
            return TextService.countWords(text);
        };

        controller.hasAnswers = function () {
                return (controller.answers.ft !== null && typeof controller.answers.ft !== 'undefined' && controller.answers.ft !== null && controller.answers.ft.toString().length > 0) ||
                (controller.answers.pt !== null && typeof controller.answers.pt !== 'undefined' && controller.answers.pt !== null && controller.answers.pt.toString().length > 0) ||
                (controller.answers.hc !== null && typeof controller.answers.hc !== 'undefined' && controller.answers.hc !== null && controller.answers.hc.toString().length > 0) ||
                (controller.answers.url !== null && typeof controller.answers.url !== 'undefined' && controller.answers.url !== null && controller.answers.url.toString().length > 0) ||
                (controller.answers.fileUrl !== null && typeof controller.answers.fileUrl !== 'undefined' && controller.answers.fileUrl !== null && controller.answers.fileUrl.toString().length > 0) ||
                (controller.answers.checked !== null && typeof controller.answers.checked !== 'undefined' && controller.answers.checked !== null && controller.answers.checked.toString().length > 0) ||
                (controller.answers.fte !== null && typeof controller.answers.fte !== 'undefined' && controller.answers.fte !== null && controller.answers.fte.toString().length > 0) || controller || 
                (controller.answers.currentEdi !== null && typeof controller.answers.currentEdi !== 'undefined' && controller.answers.currentEdi !== null && controller.answers.currentEdi) ||
                (controller.answers.currentEdiUrl !== null && typeof controller.answers.currentEdiUrl !== 'undefined' && controller.answers.currentEdiUrl !== null && controller.answers.currentEdiUrl.toString().length > 0) ||
                (controller.answers.age !== null && typeof controller.answers.age !== 'undefined' && controller.answers.age !== null && controller.answers.age) ||
                
                (controller.answers.executiveLeadership !== null && typeof controller.answers.executiveLeadership !== 'undefined' && controller.answers.executiveLeadership !== null && controller.answers.executiveLeadership) ||
                (controller.answers.isForStudentsSI !== null && typeof controller.answers.isForStudentsSI !== 'undefined' && controller.answers.isForStudentsSI !== null && controller.answers.isForStudentsSI) ||
                (controller.answers.isForStaffSI !== null && typeof controller.answers.isForStaffSI !== 'undefined' && controller.answers.isForStaffSI !== null && controller.answers.isForStaffSI) ||
                (controller.answers.isForbothSI !== null && typeof controller.answers.isForbothSI !== 'undefined' && controller.answers.isForbothSI !== null && controller.answers.isForbothSI) ||
                (controller.answers.isForStudentsEA !== null && typeof controller.answers.isForStudentsEA !== 'undefined' && controller.answers.isForStudentsEA !== null && controller.answers.isForStudentsEA) ||
                (controller.answers.isForStaffEA !== null && typeof controller.answers.isForStaffEA !== 'undefined' && controller.answers.isForStaffEA !== null && controller.answers.isForStaffEA) ||
                (controller.answers.isForbothEA !== null && typeof controller.answers.isForbothEA !== 'undefined' && controller.answers.isForbothEA !== null && controller.answers.isForbothEA) ||
                (controller.answers.formedSustainabilityCommitteeEvd !== null && typeof controller.answers.formedSustainabilityCommitteeEvd !== 'undefined' && controller.answers.formedSustainabilityCommitteeEvd !== null && controller.answers.formedSustainabilityCommitteeEvd) ||
                (controller.answers.dedicatedTrainingSocialAspectsEvd !== null && typeof controller.answers.dedicatedTrainingSocialAspectsEvd !== 'undefined' && controller.answers.dedicatedTrainingSocialAspectsEvd !== null && controller.answers.dedicatedTrainingSocialAspectsEvd) ||
                (controller.answers.dedicatedTrainingEnvironmentalAspectsEvd !== null && typeof controller.answers.dedicatedTrainingEnvironmentalAspectsEvd !== 'undefined' && controller.answers.dedicatedTrainingEnvironmentalAspectsEvd !== null && controller.answers.dedicatedTrainingEnvironmentalAspectsEvd) ||
                (controller.answers.assessmentToolEv !== null && typeof controller.answers.assessmentToolEv !== 'undefined' && controller.answers.assessmentToolEv !== null && controller.answers.assessmentToolEv) ||
                (controller.answers.genderReassignment !== null && typeof controller.answers.genderReassignment !== 'undefined' && controller.answers.genderReassignment !== null && controller.answers.genderReassignment) ||
                (controller.answers.disability !== null && typeof controller.answers.disability !== 'undefined' && controller.answers.disability !== null && controller.answers.disability) ||
                (controller.answers.race !== null && typeof controller.answers.race !== 'undefined' && controller.answers.race !== null && controller.answers.race) ||
                (controller.answers.religionOrBelief !== null && typeof controller.answers.religionOrBelief !== 'undefined' && controller.answers.religionOrBelief !== null && controller.answers.religionOrBelief) ||
                (controller.answers.sexualOrientation !== null && typeof controller.answers.sexualOrientation !== 'undefined' && controller.answers.sexualOrientation !== null && controller.answers.sexualOrientation) ||
                (controller.answers.marriageAndCivilPatnership !== null && typeof controller.answers.marriageAndCivilPatnership !== 'undefined' && controller.answers.marriageAndCivilPatnership !== null && controller.answers.marriageAndCivilPatnership) ||
                (controller.answers.refugeeAndAsylumSeekers !== null && typeof controller.answers.refugeeAndAsylumSeekers !== 'undefined' && controller.answers.refugeeAndAsylumSeekers !== null && controller.answers.refugeeAndAsylumSeekers) ||
                (controller.answers.pregnancyAndMaternity !== null && typeof controller.answers.pregnancyAndMaternity !== 'undefined' && controller.answers.pregnancyAndMaternity !== null && controller.answers.pregnancyAndMaternity) || 
                (controller.answers.existenceOfDisabilitySupport !== null && typeof controller.answers.existenceOfDisabilitySupport !== 'undefined' && controller.answers.existenceOfDisabilitySupport !== null && controller.answers.existenceOfDisabilitySupport) ||
                (controller.answers.existenceOfDisabilitySupportUrl !== null && typeof controller.answers.existenceOfDisabilitySupportUrl !== 'undefined' && controller.answers.existenceOfDisabilitySupportUrl !== null && controller.answers.existenceOfDisabilitySupportUrl.toString().length > 0) ||
                (controller.answers.campusAccessibility !== null && typeof controller.answers.campusAccessibility !== 'undefined' && controller.answers.campusAccessibility !== null && controller.answers.campusAccessibility) ||
                (controller.answers.campusAccessibilityUrl !== null && typeof controller.answers.campusAccessibilityUrl !== 'undefined' && controller.answers.campusAccessibilityUrl !== null && controller.answers.campusAccessibilityUrl.toString().length > 0) ||
                (controller.answers.campusAccessibilityText !== null && typeof controller.answers.campusAccessibilityText !== 'undefined' && controller.answers.campusAccessibilityText !== null && controller.answers.campusAccessibilityText.toString().length > 0) ||
                (controller.answers.accessScheme !== null && typeof controller.answers.accessScheme !== 'undefined' && controller.answers.accessScheme !== null && controller.answers.accessScheme) ||
                (controller.answers.accessSchemeUrl !== null && typeof controller.answers.accessSchemeUrl !== 'undefined' && controller.answers.accessSchemeUrl !== null && controller.answers.accessSchemeUrl.toString().length > 0) ||
                (controller.answers.universityAccomodation !== null && typeof controller.answers.universityAccomodation !== 'undefined' && controller.answers.universityAccomodation !== null && controller.answers.universityAccomodation) ||
                (controller.answers.reasonableAccommodation !== null && typeof controller.answers.reasonableAccommodation !== 'undefined' && controller.answers.reasonableAccommodation !== null && controller.answers.reasonableAccommodation) ||
                (controller.answers.reasonableAccommodationUrl !== null && typeof controller.answers.reasonableAccommodationUrl !== 'undefined' && controller.answers.reasonableAccommodationUrl !== null && controller.answers.reasonableAccommodationUrl.toString().length > 0) ||
                (controller.answers.manageOrDeliverOutrechProjects !== null && typeof controller.answers.manageOrDeliverOutrechProjects !== 'undefined' && controller.answers.manageOrDeliverOutrechProjects !== null && controller.answers.manageOrDeliverOutrechProjects.toString().length > 0) ||
                (controller.answers.institutionDedicatedStaffMember !== null && typeof controller.answers.institutionDedicatedStaffMember !== 'undefined' && controller.answers.institutionDedicatedStaffMember !== null && controller.answers.institutionDedicatedStaffMember.toString().length > 0) ||
                
                (controller.answers.healthyAndAffordableFood !== null && typeof controller.answers.healthyAndAffordableFood !== 'undefined' && controller.answers.healthyAndAffordableFood !== null && controller.answers.healthyAndAffordableFood) ||
                (controller.answers.healthyAndAffordableFoodText !== null && typeof controller.answers.healthyAndAffordableFoodText !== 'undefined' && controller.answers.healthyAndAffordableFoodText !== null && controller.answers.healthyAndAffordableFoodText.toString().length > 0) ||
                (controller.answers.physicalHealth !== null && typeof controller.answers.physicalHealth !== 'undefined' && controller.answers.physicalHealth !== null && controller.answers.physicalHealth) ||
                (controller.answers.physicalHealthUrl !== null && typeof controller.answers.physicalHealthUrl !== 'undefined' && controller.answers.physicalHealthUrl !== null && controller.answers.physicalHealthUrl.toString().length > 0) ||
                (controller.answers.reproductiveHealthCare !== null && typeof controller.answers.reproductiveHealthCare !== 'undefined' && controller.answers.reproductiveHealthCare !== null && controller.answers.reproductiveHealthCare) ||
                (controller.answers.reproductiveHealthCareUrl !== null && typeof controller.answers.reproductiveHealthCareUrl !== 'undefined' && controller.answers.reproductiveHealthCareUrl !== null && controller.answers.reproductiveHealthCareUrl.toString().length > 0) ||
                (controller.answers.mentalHealth !== null && typeof controller.answers.mentalHealth !== 'undefined' && controller.answers.mentalHealth !== null && controller.answers.mentalHealth) ||
                (controller.answers.mentalHealthUrl !== null && typeof controller.answers.mentalHealthUrl !== 'undefined' && controller.answers.mentalHealthUrl !== null && controller.answers.mentalHealthUrl.toString().length > 0) || controller
                
                (controller.answers.existenceOfCommiteeOffice !== null && typeof controller.answers.existenceOfCommiteeOffice !== 'undefined' && controller.answers.existenceOfCommiteeOffice !== null && controller.answers.existenceOfCommiteeOffice) ||
                (controller.answers.existenceOfCommiteeOfficeUrl !== null && typeof controller.answers.existenceOfCommiteeOfficeUrl !== 'undefined' && controller.answers.existenceOfCommiteeOfficeUrl !== null && controller.answers.existenceOfCommiteeOfficeUrl.toString().length > 0) ||
                (controller.answers.existenceOfAntiDiscrimination !== null && typeof controller.answers.existenceOfAntiDiscrimination !== 'undefined' && controller.answers.existenceOfAntiDiscrimination !== null && controller.answers.existenceOfAntiDiscrimination) ||
                (controller.answers.existenceOfAntiDiscriminationUrl !== null && typeof controller.answers.existenceOfAntiDiscriminationUrl !== 'undefined' && controller.answers.existenceOfAntiDiscriminationUrl !== null && controller.answers.existenceOfAntiDiscriminationUrl.toString().length > 0) ||
                (controller.answers.existenceOfAntiDiscriminationSecondUrl !== null && typeof controller.answers.existenceOfAntiDiscriminationSecondUrl !== 'undefined' && controller.answers.existenceOfAntiDiscriminationSecondUrl !== null && controller.answers.existenceOfAntiDiscriminationSecondUrl.toString().length > 0);
        };
        controller.isActive = function () {
            return controller.hasFocus;
        };
        controller.handleFocus = function () {
            StatisticsSubmissionsFactory.setActiveQuestionId(controller.question.id);
            controller.hasFocus = true;
            StatisticsDashboardFactory.setActiveQuestion(controller.question);
            if ($location.search().question !== controller.question.handle) {
                $state.transitionTo($state.current, {
                    question: controller.question.handle
                }, {
                    notify: false
                });
            }
        };
        controller.isAllowedPreviouslySubmit = function (question) {
            if (question.type === 'personal') {
                if (question.handle === 'undergraduate_international_students' ||
                    question.handle === 'graduate_postgraduate_international_students' ||
                    question.handle === 'overall_international_students' ||
                    question.handle === 'undergraduate_exchange_students_inbound' ||
                    question.handle === 'undergraduate_exchange_students_outbound' ||
                    question.handle === 'graduate_postgraduate_exchange_students_inbound' ||
                    question.handle === 'graduate_postgraduate_exchange_students_outbound' ||
                    question.handle === 'exchange_students_inbound' ||
                    question.handle === 'exchange_students_outbound') {
                    return true;
                }
            }
            return false;
        };
        controller.activeQuestionIdWatch = function (questionId) {
            if (controller.question.handle === 'overall_students') {
                if (controller.isAnswersInitialized && controller.answers && !controller.answers.ft &&
                    !controller.answers.pt &&
                    !controller.answers.hc &&
                    !controller.answers.fte) {
                        StatisticsSubmissionsFactory.setOverallStudentsIsEmpty(true);
                }
                else {
                    StatisticsSubmissionsFactory.setOverallStudentsIsEmpty(false);
                }
               
            }
            
            if (questionId) {
                if (questionId !== controller.question.id) {
                    controller.hasFocus = false;
                } else {
                    controller.handleFocus();
                }
            } else {
                controller.hasFocus = false;
            }
            WatchService.create($scope, StatisticsSubmissionsFactory.getExplainationProvidedQuestion, controller.explanationProvidedWatch, true);
        };
        controller.resetAnswer = function () {
            controller.id = null;
            controller.answers = {};
            controller.source = null;
            controller.status = null;
            controller.previouslySubmitted = null;
        };
        controller.isUrlField = function () {
            return controller.question.questionType === 'url';
        };
        controller.isFileField = function () {
            return controller.question.handle === 'total_students_nationalities';
        };
        controller.isPubliclyAvailableQuestion = function () {
            return controller.question.handle === 'link_to_your_institution_official_climate_action';
        };
        controller.isNotAvailableQuestion = function () {
            return controller.question.handle === 'does_your_institute_have_an_ethics_committee' ||
            controller.question.handle === 'does_your_university_governing_body_have_student_representation' ||
            controller.question.handle === 'does_your_organisation_have_and_actively_implement_an_equality';
        };
        controller.isSingleNumericField = function () {
            return controller.question.questionType === 'numeric';
        };
        controller.isCheckBoxField = function () {
            return controller.question.questionType === 'checkbox';
        };
        controller.isAdditionalStatisticQuestion = function () {
            return controller.question.categoryHandle === "additional_statistics";
        };
        controller.isUrlAndCheckBoxField = function () {
            return controller.question.handle === 'link_to_your_institution_sustainable_procurement' || controller.question.handle === 'link_to_your_institution_sustainability_climate_policy' ||
            controller.question.handle === 'link_to_your_institution_sustainable_investment_policy' ||
            controller.question.handle === 'does_your_university_governing_body_have_student_representation' ||
            controller.question.handle === 'link_to_your_university_last_governance_meeting' ||
            controller.question.handle === 'link_to_student_led_Society';
        };
        controller.isSustaiabilityField = function () {
            return controller.question.questionType === 'sustainability';
        };
        controller.answersWatch = function (data) {
            if (!data || !data.length) {
                controller.resetAnswer();
                controller.answers = angular.copy(controller.answersInit);
                controller.answersBeforeChanges = angular.copy(controller.answers);
                return;
            }
            if (!StatisticsSubmissionsFactory.getInvalidIndicatorsData(controller.question.handle)) {
                controller.resetAnswer();
                var i = 0,
                    total = data.length,
                    item = null;
                for (i; i < total; i++) {
                    if (data[i].questionHandle === controller.question.handle) {
                        item = data[i].answers;
                        if ((controller.question.handle === 'graduate_postgraduate_students' || controller.question.handle === 'undergraduate_students')) {
                            if (data[i].isOverallStudentsEmpty) {
                                controller.question.validators[1].type = 'error';
                            } else {
                                controller.question.validators[1].type = 'warning';
                            }
                        }
                        controller.id = data[i].id;
                        controller.answers.ft = item !== null && item.ft ? item.ft.value : null;
                        controller.answers.pt = item !== null && item.pt ? item.pt.value : null;
                        controller.answers.hc = item !== null && item.hc ? item.hc.value : null;
                        controller.answers.fte = item !== null && item.fte ? item.fte.value : null;
                        controller.answers.tsn = item !== null && item.tsn ? item.tsn.value : null;
                        controller.answers.url = item !== null && item.url ? item.url.value : null;
                        controller.answers.fileUrl = item !== null && item.fileUrl ? item.fileUrl.value : null;
                        controller.answers.publiclyAvailable = item !== null && item.url ? item.url.publiclyAvailable : null;
                        controller.answers.notAvailable = item !== null && item.url ? item.url.notAvailable : null;
                        controller.answers.estimate = item !== null && item.fte ? item.fte.estimate : null;

                        if (controller.question.handle === 'link_to_your_institution_sustainable_investment_policy' ||
                            controller.question.handle === 'link_to_your_institution_sustainable_procurement'||
                            controller.question.handle === 'link_to_your_institution_sustainability_climate_policy'||
                            controller.question.handle === 'link_to_student_led_Society'||
                            controller.question.handle === 'presence_of_research_center'||
                            controller.question.handle === 'climate_science_environmental_sustainability'||
                            controller.question.handle === 'institution_formed_sustainability_committee'||
                            controller.question.handle === 'instituion_provide_dedicated_training_on_social_impacts'||
                            controller.question.handle === 'institution_provide_dedicated_training_on_environmental_aspects'||
                            controller.question.handle === 'institution_have_an_assessment_tool_for_assessing'||
                            controller.question.handle === 'manage_or_deliver_outreach_projects'||
                            controller.question.handle === 'does_institution_have_dedicated_staff_member'||
                            controller.question.handle === 'does_your_university_governing_body_have_student_representation'||
                            controller.question.handle === 'link_to_your_university_last_governance_meeting'){
                            controller.answers.checked = item !== null && item.checked ? item.checked.value : null;
                        }
                        else {
                            controller.answers.checked = item !== null && item.checkBox ? item.checkBox.value : null;
                        }
                        
                        controller.source = data[i].source;
                        controller.answersBeforeChanges = angular.copy(controller.answers);
                        controller.status = data[i].status;
                        controller.previouslySubmitted = false;

                        controller.answers.currentEdi = item !== null && item.currentEdi ? item.currentEdi.value : null;
                        controller.answers.currentEdiUrl = item !== null && item.currentEdi ? item.currentEdi.url : null;
                        controller.answers.age = item !== null && item.age ? item.age.value : null;
                        controller.answers.executiveLeadership = item !== null && item.executiveLeadership ? item.executiveLeadership.value : null;
                        controller.answers.formedSustainabilityCommitteeEvd = item !== null && item.formedSustainabilityCommitteeEvd ? item.formedSustainabilityCommitteeEvd.value : null;
                        controller.answers.isForStudentsSI = item !== null && item.isForStudentsSI ? item.isForStudentsSI.value : null;
                        controller.answers.isForStaffSI = item !== null && item.isForStaffSI ? item.isForStaffSI.value : null;
                        controller.answers.isForbothSI = item !== null && item.isForbothSI ? item.isForbothSI.value : null;
                        controller.answers.dedicatedTrainingSocialAspectsEvd = item !== null && item.dedicatedTrainingSocialAspectsEvd ? item.dedicatedTrainingSocialAspectsEvd.value : null;
                        controller.answers.isForStudentsEA = item !== null && item.isForStudentsEA ? item.isForStudentsEA.value : null;
                        controller.answers.isForStaffEA = item !== null && item.isForStaffEA ? item.isForStaffEA.value : null;
                        controller.answers.isForbothEA = item !== null && item.isForbothEA ? item.isForbothEA.value : null;
                        controller.answers.dedicatedTrainingEnvironmentalAspectsEvd = item !== null && item.dedicatedTrainingEnvironmentalAspectsEvd ? item.dedicatedTrainingEnvironmentalAspectsEvd.value : null;
                        controller.answers.assessmentToolEv = item !== null && item.assessmentToolEv ? item.assessmentToolEv.value : null;
                        controller.answers.genderReassignment = item !== null && item.genderReassignment ? item.genderReassignment.value : null;
                        controller.answers.disability = item !== null && item.disability ? item.disability.value : null;
                        controller.answers.race = item !== null && item.race ? item.race.value : null;
                        controller.answers.religionOrBelief = item !== null && item.religionOrBelief ? item.religionOrBelief.value : null;
                        controller.answers.sexualOrientation = item !== null && item.sexualOrientation ? item.sexualOrientation.value : null;
                        controller.answers.marriageAndCivilPatnership = item !== null && item.marriageAndCivilPatnership ? item.marriageAndCivilPatnership.value : null;
                        controller.answers.refugeeAndAsylumSeekers = item !== null && item.refugeeAndAsylumSeekers ? item.refugeeAndAsylumSeekers.value : null;
                        controller.answers.pregnancyAndMaternity = item !== null && item.pregnancyAndMaternity ? item.pregnancyAndMaternity.value : null;
                        controller.answers.existenceOfDisabilitySupport = item !== null && item.existenceOfDisabilitySupport ? item.existenceOfDisabilitySupport.value : null;
                        controller.answers.existenceOfDisabilitySupportUrl = item !== null && item.existenceOfDisabilitySupport ? item.existenceOfDisabilitySupport.url : null;
                        controller.answers.campusAccessibility = item !== null && item.campusAccessibility ? item.campusAccessibility.value : null;
                        controller.answers.campusAccessibilityText = item !== null && item.campusAccessibility ? item.campusAccessibility.text : null;
                        controller.answers.accessScheme = item !== null && item.accessScheme ? item.accessScheme.value : null;
                        controller.answers.accessSchemeUrl = item !== null && item.accessScheme ? item.accessScheme.url : null;
                        controller.answers.universityAccomodation = item !== null && item.universityAccomodation ? item.universityAccomodation.value : null;
                        controller.answers.reasonableAccommodation = item !== null && item.reasonableAccommodation ? item.reasonableAccommodation.value : null;
                        controller.answers.reasonableAccommodationUrl = item !== null && item.reasonableAccommodationUrl ? item.reasonableAccommodationUrl.value : null;
                        controller.answers.manageOrDeliverOutrechProjects = item !== null && item.manageOrDeliverOutrechProjects ? item.manageOrDeliverOutrechProjects.value : null;
                        controller.answers.institutionDedicatedStaffMember = item !== null && item.institutionDedicatedStaffMember ? item.institutionDedicatedStaffMember.value : null;
                        controller.answers.healthyAndAffordableFood = item !== null && item.healthyAndAffordableFood ? item.healthyAndAffordableFood.value : null;
                        controller.answers.healthyAndAffordableFoodText = item !== null && item.healthyAndAffordableFood ? item.healthyAndAffordableFood.text : null;
                        controller.answers.physicalHealth = item !== null && item.physicalHealth ? item.physicalHealth.value : null;
                        controller.answers.physicalHealthUrl = item !== null && item.physicalHealth ? item.physicalHealth.url : null;
                        controller.answers.reproductiveHealthCare = item !== null && item.reproductiveHealthCare ? item.reproductiveHealthCare.value : null;
                        controller.answers.reproductiveHealthCareUrl = item !== null && item.reproductiveHealthCare ? item.reproductiveHealthCare.url : null;
                        controller.answers.mentalHealth = item !== null && item.mentalHealth ? item.mentalHealth.value : null;
                        controller.answers.mentalHealthUrl = item !== null && item.mentalHealth ? item.mentalHealth.url : null;
                        controller.answers.existenceOfCommiteeOffice = item !== null && item.existenceOfCommiteeOffice ? item.existenceOfCommiteeOffice.value : null;
                        controller.answers.existenceOfCommiteeOfficeUrl = item !== null && item.existenceOfCommiteeOffice ? item.existenceOfCommiteeOffice.url : null;
                        controller.answers.existenceOfAntiDiscrimination = item !== null && item.existenceOfAntiDiscrimination ? item.existenceOfAntiDiscrimination.value : null;
                        controller.answers.existenceOfAntiDiscriminationUrl = item !== null && item.existenceOfAntiDiscrimination ? item.existenceOfAntiDiscrimination.url : null;
                        controller.answers.existenceOfAntiDiscriminationSecondUrl = item !== null && item.existenceOfAntiDiscrimination ? item.existenceOfAntiDiscrimination.secondUrl : null;
                        controller.answers.existenceOfAntiBribery = item !== null && item.existenceOfAntiBribery ? item.existenceOfAntiBribery.value : null;
                        controller.answers.existenceOfAntiBriberyUrl = item !== null && item.existenceOfAntiBribery ? item.existenceOfAntiBribery.url : null;
                        controller.answers.policyReviewed = item !== null && item.policyReviewed ? item.policyReviewed.value : null;
                        controller.answers.papersPublishedPercentage = item !== null && item.papersPublishedPercentage ? item.papersPublishedPercentage.value : null;
                        controller.answers.hoursOfTrainingSA = item !== null && item.hoursOfTrainingSA ? item.hoursOfTrainingSA.value : null;
                        controller.answers.hoursOfTrainingEA = item !== null && item.hoursOfTrainingEA ? item.hoursOfTrainingEA.value : null;
                        controller.answers.ethicalValues = item !== null && item.ethicalValues ? item.ethicalValues.value : null;
                        controller.answers.ethicalValuesUrl = item !== null && item.ethicalValues ? item.ethicalValues.url : null;
                        controller.answers.providesTraning = item !== null && item.providesTraning ? item.providesTraning.value : null;
                        controller.answers.providesTraningUrl = item !== null && item.providesTraning ? item.providesTraning.url : null;
                        controller.answers.ethicalCompliance = item !== null && item.ethicalCompliance ? item.ethicalCompliance.value : null;
                        controller.answers.ethicalComplianceUrl = item !== null && item.ethicalCompliance ? item.ethicalCompliance.url : null;
                        controller.answers.internalReporting = item !== null && item.internalReporting ? item.internalReporting.value : null;
                        controller.answers.internalReportingUrl = item !== null && item.internalReporting ? item.internalReporting.url : null;
                        controller.answers.representUgAndPgStudent = item !== null && item.representUgAndPgStudent ? item.representUgAndPgStudent.value : null;
                        controller.answers.studentUnionConnected = item !== null && item.studentUnionConnected ? item.studentUnionConnected.value : null;
                        controller.answers.studentUnionAdheres = item !== null && item.studentUnionAdheres ? item.studentUnionAdheres.value : null;
                        controller.answers.UniversityStudentUnionUrl = item !== null && item.UniversityStudentUnionUrl ? item.UniversityStudentUnionUrl.value : null;
                        controller.answers.incomeAndExpenditure = item !== null && item.incomeAndExpenditure ? item.incomeAndExpenditure.value : null;
                        controller.answers.financialPosition = item !== null && item.financialPosition ? item.financialPosition.value : null;
                        controller.answers.cashFlowInformation = item !== null && item.cashFlowInformation ? item.cashFlowInformation.value : null;
                        controller.answers.yearForecast = item !== null && item.yearForecast ? item.yearForecast.value : null;
                        controller.answers.financialReportsUrl = item !== null && item.financialReportsUrl ? item.financialReportsUrl.value : null;
                        controller.answers.dedicatedFteStaff = item !== null && item.dedicatedFteStaff ? item.dedicatedFteStaff.value : null;
                        controller.answers.isSuitestTask = item !== null && item.isSuitestTask ? item.isSuitestTask.value : null;
                        controller.answers.researchCenterContribute = item !== null && item.researchCenterContribute ? item.researchCenterContribute.value : null;
                        controller.answers.presenceOfSearchText = item !== null && item.presenceOfSearchText ? item.presenceOfSearchText.value : null;
                        controller.answers.lastConductedSurvey = item !== null && item.lastConductedSurvey ? item.lastConductedSurvey.value : null;
                        controller.answers.emissionsScope = item !== null && item.emissionsScope ? item.emissionsScope.value : null;
                        controller.answers.reportOnScope = item !== null && item.reportOnScope ? item.reportOnScope.value : null;
                        controller.answers.carbonEmissionUrl = item !== null && item.carbonEmissionUrl ? item.carbonEmissionUrl.value : null;
                        controller.answers.officiallyRecognised = item !== null && item.officiallyRecognised ? item.officiallyRecognised.value : null;
                        controller.answers.recognisedQualification = item !== null && item.recognisedQualification ? item.recognisedQualification.value : null;
                        controller.answers.climateScienceUrl = item !== null && item.climateScienceUrl ? item.climateScienceUrl.value : null;
                        controller.answers.baseLineYear = item !== null && item.baseLineYear ? item.baseLineYear.value : null;
                        controller.answers.totalScopeEmission = item !== null && item.totalScopeEmission ? item.totalScopeEmission.value : null;
                        controller.answers.carbonReductionUrl = item !== null && item.carbonReductionUrl ? item.carbonReductionUrl.value : null;
                        controller.answers.energyGenerated = item !== null && item.energyGenerated ? item.energyGenerated.value : null;
                        controller.answers.buildingFootPrints = item !== null && item.buildingFootPrints ? item.buildingFootPrints.value : null;
                        controller.answers.publicalyComittedUrl = item !== null && item.publicalyComittedUrl ? item.publicalyComittedUrl.value : null;
                        if (controller.question.handle === 'undergraduate_international_students' ||
                            controller.question.handle === 'graduate_postgraduate_international_students' ||
                            controller.question.handle === 'overall_international_students' ||
                            controller.question.handle === 'undergraduate_exchange_students_inbound' ||
                            controller.question.handle === 'undergraduate_exchange_students_outbound' ||
                            controller.question.handle === 'graduate_postgraduate_exchange_students_inbound' ||
                            controller.question.handle === 'graduate_postgraduate_exchange_students_outbound' ||
                            controller.question.handle === 'exchange_students_inbound' ||
                            controller.question.handle === 'faculty_staff' ||
                            controller.question.handle === 'international_faculty_staff' ||
                            controller.question.handle === 'staff_with_phd' ||
                            controller.question.handle === 'exchange_students_inbound' ||
                            controller.question.handle === 'overall_students' ||
                            controller.question.handle === 'undergraduate_students' ||
                            controller.question.handle === 'graduate_postgraduate_students' ||
                            controller.question.handle === 'exchange_students_outbound') {
                            StatisticsSubmissionsFactory.setPreviouslySubmittedValues(
                                angular.copy(controller.question.handle),
                                angular.copy(data[i]));
                        }
                        break;
                    }
                }
            }
            if(controller.question.handle=== 'overall_students') {
                WatchService.create($scope, StatisticsSubmissionsFactory.getOverallStudentsCurrentAnswers, controller.overallStudentsCurrentAnswersWatch, true);
            }
            if(controller.question.handle=== 'overall_international_students') {
                WatchService.create($scope, StatisticsSubmissionsFactory.getInternationalOverallStudentsCurrentAnswers, controller.overallInternationalStudentsCurrentAnswersWatch, true);
            }
            if(controller.question.handle=== 'exchange_students_inbound') {
                WatchService.create($scope, StatisticsSubmissionsFactory.getInboundOverallStudentsCurrentAnswers, controller.inboundOverallStudentsCurrentAnswersWatch, true);
            }
            if(controller.question.handle=== 'exchange_students_outbound') {
                WatchService.create($scope, StatisticsSubmissionsFactory.getOutboundOverallStudentsCurrentAnswers, controller.outboundOverallStudentsCurrentAnswersWatch, true);
            }
         
            controller.isAnswersInitialized = true;
        };
        controller.onKeyUp = function ($event) {
            if ($event.keyCode === 13) {
                return;
            }
            var url = $event.target.value,
                variableName = $event.target.getAttribute('ng-model');
            if (url && url.length > 5 && (url.indexOf('http') !== 0 || url.indexOf('http') === -1)) {
                url = UrlService.prependHttp(url);
                if (variableName) {
                    if (variableName.includes('$ctrl')) {
                        variableName = variableName.replace('$ctrl.', '');
                    }
                    ObjectService.set(controller, variableName, url);
                }
            }
        };
        $scope.$on(broadcast.saveCompleted, function () {
            controller.answersBeforeChanges = angular.copy(controller.answers);
        });
        controller.dataRequestWatch = function (request) {
            if (request) {
                StatisticsSubmissionsFactory.broadcastData({
                    question: controller.question,
                    answerId: controller.id,
                    answers: {
                        isUrlIndicator : controller.question.questionType && controller.question.questionType === 'url'? true : false,
                        ft: {
                            value: controller.answers.ft || controller.answers.ft === 0 ? parseFloat(controller.answers.ft) : controller.answers.ft,
                            valueAsString: controller.answers.ft || controller.answers.ft === 0 ? controller.answers.ft.toString() : controller.answers.ft,
                            estimate: false
                        },
                        pt: {
                            value: controller.answers.pt || controller.answers.pt === 0 ? parseFloat(controller.answers.pt) : controller.answers.pt,
                            valueAsString: controller.answers.pt || controller.answers.pt === 0 ? controller.answers.pt.toString() : controller.answers.pt,
                            estimate: false
                        },
                        hc: {
                            value: controller.answers.hc || controller.answers.hc === 0 ? parseFloat(controller.answers.hc) : controller.answers.hc,
                            valueAsString: controller.answers.hc || controller.answers.hc === 0 ? controller.answers.hc.toString() : controller.answers.hc,
                            estimate: false
                        },
                        fte: {
                            value: controller.answers.fte || controller.answers.fte === 0 ? parseFloat(controller.answers.fte) : controller.answers.fte,
                            valueAsString: controller.answers.fte || controller.answers.fte === 0 ? controller.answers.fte.toString() : controller.answers.fte,
                            estimate: controller.answers.estimate? controller.answers.estimate : false
                        },
                        tsn: {
                            value: controller.answers.tsn || controller.answers.tsn === 0 ? parseFloat(controller.answers.tsn) : controller.answers.tsn,
                            valueAsString: controller.answers.tsn || controller.answers.tsn === 0 ? controller.answers.tsn.toString() : controller.answers.tsn,
                            estimate: false
                        },
                        url: {
                            value: controller.answers.url,
                            valueAsString: controller.answers.url,
                            estimate: false,
                            publiclyAvailable :  controller.answers.publiclyAvailable,
                            notAvailable :  controller.answers.notAvailable
                        },
                        checked: {
                            value: controller.answers.checked ? controller.answers.checked : (controller.answers.checked === false ? 'false' : controller.answers.checked),
                            valueAsString: controller.answers.checked === true ? 'YES' : (controller.answers.checked === false || controller.answers.checked === 'false' ? 'NO' : controller.answers.checked),
                        },
                        fileUrl: {
                            value: controller.answers.fileUrl,
                            valueAsString: controller.answers.fileUrl,
                        },
                        currentEdi: {
                            value: controller.answers.currentEdi ? controller.answers.currentEdi : (controller.answers.currentEdi === false ? 'false' : controller.answers.currentEdi),
                            url: controller.answers.currentEdiUrl,
                            valueAsString: controller.answers.currentEdi === true ? 'YES' : (controller.answers.currentEdi === false || controller.answers.currentEdi === 'false' ? 'NO' : controller.answers.currentEdi),
                            label: (controller.answers.currentEdi === true || controller.answers.currentEdi === false  || controller.answers.currentEdi === 'false') ? 'We have a current EDI policy or equivalent' : controller.answers.currentEdi
                        },
                    
                        executiveLeadership: {
                            value: controller.answers.executiveLeadership ? controller.answers.executiveLeadership : (controller.answers.executiveLeadership === false ? 'false' : controller.answers.executiveLeadership),
                            valueAsString: controller.answers.executiveLeadership === true ? 'YES' : (controller.answers.executiveLeadership === false || controller.answers.executiveLeadership === 'false' ? 'NO' : controller.answers.executiveLeadership),
                            label: (controller.answers.executiveLeadership === true || controller.answers.executiveLeadership === false  || controller.answers.executiveLeadership === 'false') ? 'Does a member of your executive leadership team sit on this committee? (GG 8.1)' : controller.answers.executiveLeadership
                        },
                        formedSustainabilityCommitteeEvd: {
                            value: controller.answers.formedSustainabilityCommitteeEvd,
                            valueAsString: controller.answers.formedSustainabilityCommitteeEvd,
                            label: controller.answers.formedSustainabilityCommitteeEvd?  'Evidence' : controller.answers.formedSustainabilityCommitteeEvd,
                        },
                        isForStudentsSI: {
                            value: controller.answers.isForStudentsSI ? controller.answers.isForStudentsSI : (controller.answers.isForStudentsSI === false ? 'false' : controller.answers.isForStudentsSI),
                            valueAsString: controller.answers.isForStudentsSI === true ? 'YES' : (controller.answers.isForStudentsSI === false || controller.answers.isForStudentsSI === 'false' ? 'NO' : controller.answers.isForStudentsSI),
                            label: (controller.answers.isForStudentsSI === true || controller.answers.isForStudentsSI === false  || controller.answers.isForStudentsSI === 'false') ? 'Students' : controller.answers.isForStudentsSI
                        },
                        isForStaffSI: {
                            value: controller.answers.isForStaffSI ? controller.answers.isForStaffSI : (controller.answers.isForStaffSI === false ? 'false' : controller.answers.isForStaffSI),
                            valueAsString: controller.answers.isForStaffSI === true ? 'YES' : (controller.answers.isForStaffSI === false || controller.answers.isForStaffSI === 'false' ? 'NO' : controller.answers.isForStaffSI),
                            label: (controller.answers.isForStaffSI === true || controller.answers.isForStaffSI === false  || controller.answers.isForStaffSI === 'false') ? 'Staff Students' : controller.answers.isForStaffSI
                        },
                        isForbothSI: {
                            value: controller.answers.isForbothSI ? controller.answers.isForbothSI : (controller.answers.isForbothSI === false ? 'false' : controller.answers.isForbothSI),
                            valueAsString: controller.answers.isForbothSI === true ? 'YES' : (controller.answers.isForbothSI === false || controller.answers.isForbothSI === 'false' ? 'NO' : controller.answers.isForbothSI),
                            label: (controller.answers.isForbothSI === true || controller.answers.isForbothSI === false  || controller.answers.isForbothSI === 'false') ? 'Both' : controller.answers.isForbothSI
                        },
                        dedicatedTrainingSocialAspectsEvd: {
                            value: controller.answers.dedicatedTrainingSocialAspectsEvd,
                            valueAsString: controller.answers.dedicatedTrainingSocialAspectsEvd,
                            label: controller.answers.dedicatedTrainingSocialAspectsEvd?  'Evidence' : controller.answers.dedicatedTrainingSocialAspectsEvd,
                        },
                        isForStudentsEA: {
                            value: controller.answers.isForStudentsEA ? controller.answers.isForStudentsEA : (controller.answers.isForStudentsEA === false ? 'false' : controller.answers.isForStudentsEA),
                            valueAsString: controller.answers.isForStudentsEA === true ? 'YES' : (controller.answers.isForStudentsEA === false || controller.answers.isForStudentsEA === 'false' ? 'NO' : controller.answers.isForStudentsEA),
                            label: (controller.answers.isForStudentsEA === true || controller.answers.isForStudentsEA === false  || controller.answers.isForStudentsEA === 'false') ? 'Students' : controller.answers.isForStudentsEA
                        },
                        isForStaffEA: {
                            value: controller.answers.isForStaffEA ? controller.answers.isForStaffEA : (controller.answers.isForStaffEA === false ? 'false' : controller.answers.isForStaffEA),
                            valueAsString: controller.answers.isForStaffEA === true ? 'YES' : (controller.answers.isForStaffEA === false || controller.answers.isForStaffEA === 'false' ? 'NO' : controller.answers.isForStaffEA),
                            label: (controller.answers.isForStaffEA === true || controller.answers.isForStaffEA === false  || controller.answers.isForStaffEA === 'false') ? 'Staff' : controller.answers.isForStaffEA
                        },
                        isForbothEA: {
                            value: controller.answers.isForbothEA ? controller.answers.isForbothEA : (controller.answers.isForbothEA === false ? 'false' : controller.answers.isForbothEA),
                            valueAsString: controller.answers.isForbothEA === true ? 'YES' : (controller.answers.isForbothEA === false || controller.answers.isForbothEA === 'false' ? 'NO' : controller.answers.isForbothEA),
                            label: (controller.answers.isForbothEA === true || controller.answers.isForbothEA === false  || controller.answers.isForbothEA === 'false') ? 'Both' : controller.answers.isForbothEA
                        },
                        dedicatedTrainingEnvironmentalAspectsEvd: {
                            value: controller.answers.dedicatedTrainingEnvironmentalAspectsEvd,
                            valueAsString: controller.answers.dedicatedTrainingEnvironmentalAspectsEvd,
                            label: controller.answers.dedicatedTrainingEnvironmentalAspectsEvd?  'Evidence' : controller.answers.dedicatedTrainingEnvironmentalAspectsEvd,
                        },
                        age: {
                            value: controller.answers.age ? controller.answers.age : (controller.answers.age === false ? 'false' : controller.answers.age),
                            valueAsString: controller.answers.age === true ? 'YES' : (controller.answers.age === false || controller.answers.age === 'false' ? 'NO' : controller.answers.age),
                            label: (controller.answers.age === true || controller.answers.age === false  || controller.answers.age === 'false') ? 'Age' : controller.answers.age
                        },
                        genderReassignment: {
                            value: controller.answers.genderReassignment ? controller.answers.genderReassignment : (controller.answers.genderReassignment === false ? 'false' : controller.answers.genderReassignment),
                            valueAsString: controller.answers.genderReassignment === true ? 'YES' : (controller.answers.genderReassignment === false || controller.answers.genderReassignment === 'false' ? 'NO' : controller.answers.genderReassignment),
                            label: (controller.answers.genderReassignment === true || controller.answers.genderReassignment === false  || controller.answers.genderReassignment === 'false') ? 'Gender or gender reassignment' : controller.answers.genderReassignment
                        },
                        disability: {
                            value: controller.answers.disability ? controller.answers.disability : (controller.answers.disability === false ? 'false' : controller.answers.disability),
                            valueAsString: controller.answers.disability === true ? 'YES' : (controller.answers.disability === false || controller.answers.disability === 'false' ? 'NO' : controller.answers.disability),
                            label: (controller.answers.disability === true || controller.answers.disability === false  || controller.answers.disability === 'false') ? 'Disability' : controller.answers.disability
                        },
                        race: {
                            value: controller.answers.race ? controller.answers.race : (controller.answers.race === false ? 'false' : controller.answers.race),
                            valueAsString: controller.answers.race === true ? 'YES' : (controller.answers.race === false || controller.answers.race === 'false' ? 'NO' : controller.answers.race),
                            label: (controller.answers.race === true || controller.answers.race === false  || controller.answers.race === 'false') ? 'Race' : controller.answers.race
                        },
                        religionOrBelief: {
                            value: controller.answers.religionOrBelief ? controller.answers.religionOrBelief : (controller.answers.religionOrBelief === false ? 'false' : controller.answers.religionOrBelief),
                            valueAsString: controller.answers.religionOrBelief === true ? 'YES' : (controller.answers.religionOrBelief === false || controller.answers.religionOrBelief === 'false' ? 'NO' : controller.answers.religionOrBelief),
                            label: (controller.answers.religionOrBelief === true || controller.answers.religionOrBelief === false  || controller.answers.religionOrBelief === 'false') ? 'Religion or belief' : controller.answers.religionOrBelief
                        },
                        sexualOrientation: {
                            value: controller.answers.sexualOrientation ? controller.answers.sexualOrientation : (controller.answers.sexualOrientation === false ? 'false' : controller.answers.sexualOrientation),
                            valueAsString: controller.answers.sexualOrientation === true ? 'YES' : (controller.answers.sexualOrientation === false || controller.answers.sexualOrientation === 'false' ? 'NO' : controller.answers.sexualOrientation),
                            label: (controller.answers.sexualOrientation === true || controller.answers.sexualOrientation === false  || controller.answers.sexualOrientation === 'false') ? 'Sexual orientation' : controller.answers.sexualOrientation
                        },
                        marriageAndCivilPatnership: {
                            value: controller.answers.marriageAndCivilPatnership ? controller.answers.marriageAndCivilPatnership : (controller.answers.marriageAndCivilPatnership === false ? 'false' : controller.answers.marriageAndCivilPatnership),
                            valueAsString: controller.answers.marriageAndCivilPatnership === true ? 'YES' : (controller.answers.marriageAndCivilPatnership === false || controller.answers.marriageAndCivilPatnership === 'false' ? 'NO' : controller.answers.marriageAndCivilPatnership),
                            label: (controller.answers.marriageAndCivilPatnership === true || controller.answers.marriageAndCivilPatnership === false  || controller.answers.marriageAndCivilPatnership === 'false') ? 'Marriage and civil partnership' : controller.answers.marriageAndCivilPatnership
                        },
                    
                        refugeeAndAsylumSeekers: {
                            value: controller.answers.refugeeAndAsylumSeekers ? controller.answers.refugeeAndAsylumSeekers : (controller.answers.refugeeAndAsylumSeekers === false ? 'false' : controller.answers.refugeeAndAsylumSeekers),
                            valueAsString: controller.answers.refugeeAndAsylumSeekers === true ? 'YES' : (controller.answers.refugeeAndAsylumSeekers === false || controller.answers.refugeeAndAsylumSeekers === 'false' ? 'NO' : controller.answers.refugeeAndAsylumSeekers),
                            label: (controller.answers.refugeeAndAsylumSeekers === true || controller.answers.refugeeAndAsylumSeekers === false  || controller.answers.refugeeAndAsylumSeekers === 'false') ? 'Refugee and asylum seekers' : controller.answers.refugeeAndAsylumSeekers
                        },
                        pregnancyAndMaternity: {
                            value: controller.answers.pregnancyAndMaternity ? controller.answers.pregnancyAndMaternity : (controller.answers.pregnancyAndMaternity === false ? 'false' : controller.answers.pregnancyAndMaternity),
                            valueAsString: controller.answers.pregnancyAndMaternity === true ? 'YES' : (controller.answers.pregnancyAndMaternity === false || controller.answers.pregnancyAndMaternity === 'false' ? 'NO' : controller.answers.pregnancyAndMaternity),
                            label: (controller.answers.pregnancyAndMaternity === true || controller.answers.pregnancyAndMaternity === false  || controller.answers.pregnancyAndMaternity === 'false') ? 'Pregnancy and maternity' : controller.answers.pregnancyAndMaternity
                        },
                        existenceOfDisabilitySupport: {
                            value: controller.answers.existenceOfDisabilitySupport ? controller.answers.existenceOfDisabilitySupport : (controller.answers.existenceOfDisabilitySupport === false ? 'false' : controller.answers.existenceOfDisabilitySupport),
                            url: controller.answers.existenceOfDisabilitySupportUrl,
                            valueAsString: controller.answers.existenceOfDisabilitySupport === true ? 'YES' : (controller.answers.existenceOfDisabilitySupport === false || controller.answers.existenceOfDisabilitySupport === 'false' ? 'NO' : controller.answers.existenceOfDisabilitySupport),
                            label: (controller.answers.existenceOfDisabilitySupport === true || controller.answers.existenceOfDisabilitySupport === false  || controller.answers.existenceOfDisabilitySupport === 'false') ? 'Existence of Disability Support Office' : controller.answers.existenceOfDisabilitySupport
                        },
                    
                        campusAccessibility: {
                            value: controller.answers.campusAccessibility ? controller.answers.campusAccessibility : (controller.answers.campusAccessibility === false ? 'false' : controller.answers.campusAccessibility),
                            text: controller.answers.campusAccessibilityText,
                            valueAsString: controller.answers.campusAccessibility === true ? 'YES' : (controller.answers.campusAccessibility === false || controller.answers.campusAccessibility === 'false' ? 'NO' : controller.answers.campusAccessibility),
                            label: (controller.answers.campusAccessibility === true || controller.answers.campusAccessibility === false  || controller.answers.campusAccessibility === 'false') ? 'Campus is easily accessible by people with disabilities' : controller.answers.campusAccessibility
                        },
                    
                        accessScheme: {
                            value: controller.answers.accessScheme ? controller.answers.accessScheme : (controller.answers.accessScheme === false ? 'false' : controller.answers.accessScheme),
                            url: controller.answers.accessSchemeUrl,
                            valueAsString: controller.answers.accessScheme === true ? 'YES' : (controller.answers.accessScheme === false || controller.answers.accessScheme === 'false' ? 'NO' : controller.answers.accessScheme),
                            label: (controller.answers.accessScheme === true || controller.answers.accessScheme === false  || controller.answers.accessScheme === 'false') ? 'Access schemes for people with disabilities such as mentoring or other targeted support' : controller.answers.accessScheme
                        },
                        universityAccomodation: {
                            value: controller.answers.universityAccomodation ? controller.answers.universityAccomodation : (controller.answers.universityAccomodation === false ? 'false' : controller.answers.universityAccomodation),
                            valueAsString: controller.answers.universityAccomodation === true ? 'YES' : (controller.answers.universityAccomodation === false || controller.answers.universityAccomodation === 'false' ? 'NO' : controller.answers.universityAccomodation),
                            label: (controller.answers.universityAccomodation === true || controller.answers.universityAccomodation === false  || controller.answers.universityAccomodation === 'false') ? 'Our university offers on-campus accommodation' : controller.answers.universityAccomodation
                        },
                    
                        reasonableAccommodation: {
                            value: controller.answers.reasonableAccommodation ? controller.answers.reasonableAccommodation : (controller.answers.reasonableAccommodation === false ? 'false' : controller.answers.reasonableAccommodation),
                            valueAsString: controller.answers.reasonableAccommodation === true ? 'YES' : (controller.answers.reasonableAccommodation === false || controller.answers.reasonableAccommodation === 'false' ? 'NO' : controller.answers.reasonableAccommodation),
                            label: (controller.answers.reasonableAccommodation === true || controller.answers.reasonableAccommodation === false  || controller.answers.reasonableAccommodation === 'false') ? 'We have a reasonable accommodation policy or strategy for people with disabilities, including adequate funding' : controller.answers.reasonableAccommodation
                        },
                        reasonableAccommodationUrl: {
                            value: controller.answers.reasonableAccommodationUrl,
                            valueAsString: controller.answers.reasonableAccommodationUrl,
                            label: controller.answers.reasonableAccommodationUrl ?'Evidence': controller.answers.reasonableAccommodationUrl,
                        },
                        manageOrDeliverOutrechProjects: {
                            value: controller.answers.manageOrDeliverOutrechProjects,
                            valueAsString: controller.answers.manageOrDeliverOutrechProjects,
                            label: controller.answers.manageOrDeliverOutrechProjects?  'Evidence' : controller.answers.manageOrDeliverOutrechProjects,
                        },
                        institutionDedicatedStaffMember: {
                            value: controller.answers.institutionDedicatedStaffMember,
                            valueAsString: controller.answers.institutionDedicatedStaffMember,
                            label: controller.answers.institutionDedicatedStaffMember?  'Evidence' : controller.answers.institutionDedicatedStaffMember,
                        },
                    
                        healthyAndAffordableFood: {
                            value: controller.answers.healthyAndAffordableFood ? controller.answers.healthyAndAffordableFood : (controller.answers.healthyAndAffordableFood === false ? 'false' : controller.answers.healthyAndAffordableFood),
                            text: controller.answers.healthyAndAffordableFoodText,
                            valueAsString: controller.answers.healthyAndAffordableFood === true ? 'YES' : (controller.answers.healthyAndAffordableFood === false || controller.answers.healthyAndAffordableFood === 'false' ? 'NO' : controller.answers.healthyAndAffordableFood),
                            label: (controller.answers.healthyAndAffordableFood === true || controller.answers.healthyAndAffordableFood === false  || controller.answers.healthyAndAffordableFood === 'false') ? 'Provision of healthy and affordable food choices for all on campus' : controller.answers.healthyAndAffordableFood
                        },
                        physicalHealth: {
                            value: controller.answers.physicalHealth ? controller.answers.physicalHealth : (controller.answers.physicalHealth === false ? 'false' : controller.answers.physicalHealth),
                            url: controller.answers.physicalHealthUrl,
                            valueAsString: controller.answers.physicalHealth === true ? 'YES' : (controller.answers.physicalHealth === false || controller.answers.physicalHealth === 'false' ? 'NO' : controller.answers.physicalHealth),
                            label: (controller.answers.physicalHealth === true || controller.answers.physicalHealth === false  || controller.answers.physicalHealth === 'false') ? 'Access to physical health-care services including information and education services' : controller.answers.physicalHealth
                        },
                        reproductiveHealthCare: {
                            value: controller.answers.reproductiveHealthCare ? controller.answers.reproductiveHealthCare : (controller.answers.reproductiveHealthCare === false ? 'false' : controller.answers.reproductiveHealthCare),
                            url: controller.answers.reproductiveHealthCareUrl,
                            valueAsString: controller.answers.reproductiveHealthCare === true ? 'YES' : (controller.answers.reproductiveHealthCare === false || controller.answers.reproductiveHealthCare === 'false' ? 'NO' : controller.answers.reproductiveHealthCare),
                            label: (controller.answers.reproductiveHealthCare === true || controller.answers.reproductiveHealthCare === false  || controller.answers.reproductiveHealthCare === 'false') ? 'Access to sexual and reproductive health-care services including information and education services' : controller.answers.reproductiveHealthCare
                        },
                        mentalHealth: {
                            value: controller.answers.mentalHealth ? controller.answers.mentalHealth : (controller.answers.mentalHealth === false ? 'false' : controller.answers.mentalHealth),
                            url: controller.answers.mentalHealthUrl,
                            valueAsString: controller.answers.mentalHealth === true ? 'YES' : (controller.answers.mentalHealth === false || controller.answers.mentalHealth === 'false' ? 'NO' : controller.answers.mentalHealth),
                            label: (controller.answers.mentalHealth === true || controller.answers.mentalHealth === false  || controller.answers.mentalHealth === 'false') ? 'Access to mental health support for both staff and students' : controller.answers.mentalHealth
                        },
                        existenceOfCommiteeOffice: {
                            value: controller.answers.existenceOfCommiteeOffice ? controller.answers.existenceOfCommiteeOffice : (controller.answers.existenceOfCommiteeOffice === false ? 'false' : controller.answers.existenceOfCommiteeOffice),
                            url: controller.answers.existenceOfCommiteeOfficeUrl,
                            valueAsString: controller.answers.existenceOfCommiteeOffice === true ? 'YES' : (controller.answers.existenceOfCommiteeOffice === false || controller.answers.existenceOfCommiteeOffice === 'false' ? 'NO' : controller.answers.existenceOfCommiteeOffice),
                            label: (controller.answers.existenceOfCommiteeOffice === true || controller.answers.existenceOfCommiteeOffice === false  || controller.answers.existenceOfCommiteeOffice === 'false') ? 'Existence of committee, office or officer' : controller.answers.existenceOfCommiteeOffice
                        },
                        existenceOfAntiDiscrimination: {
                            value: controller.answers.existenceOfAntiDiscrimination ? controller.answers.existenceOfAntiDiscrimination : (controller.answers.existenceOfAntiDiscrimination === false ? 'false' : controller.answers.existenceOfAntiDiscrimination),
                            url: controller.answers.existenceOfAntiDiscriminationUrl,
                            secondUrl: controller.answers.existenceOfAntiDiscriminationSecondUrl,
                            valueAsString: controller.answers.existenceOfAntiDiscrimination === true ? 'YES' : (controller.answers.existenceOfAntiDiscrimination === false || controller.answers.existenceOfAntiDiscrimination === 'false' ? 'NO' : controller.answers.existenceOfAntiDiscrimination),
                            label: (controller.answers.existenceOfAntiDiscrimination === true || controller.answers.existenceOfAntiDiscrimination === false  || controller.answers.existenceOfAntiDiscrimination === 'false') ? 'Existence of anti-discrimination and anti-harassment' : controller.answers.existenceOfAntiDiscrimination
                        },
                    
                    
                        existenceOfAntiBribery: {
                            value: controller.answers.existenceOfAntiBribery ? controller.answers.existenceOfAntiBribery : (controller.answers.existenceOfAntiBribery === false ? 'false' : controller.answers.existenceOfAntiBribery),
                            url: controller.answers.existenceOfAntiBriberyUrl,
                            valueAsString: controller.answers.existenceOfAntiBribery === true ? 'YES' : (controller.answers.existenceOfAntiBribery === false || controller.answers.existenceOfAntiBribery === 'false' ? 'NO' : controller.answers.existenceOfAntiBribery),
                            label: (controller.answers.existenceOfAntiBribery === true || controller.answers.existenceOfAntiBribery === false  || controller.answers.existenceOfAntiBribery === 'false') ? 'Existence of anti-bribery and corruption policy or equivalent' : controller.answers.existenceOfAntiBribery
                        },
                        policyReviewed: {
                            value: controller.answers.policyReviewed ? controller.answers.policyReviewed : (controller.answers.policyReviewed === false ? 'false' : controller.answers.policyReviewed),
                            valueAsString: controller.answers.policyReviewed === true ? 'YES' : (controller.answers.policyReviewed === false || controller.answers.policyReviewed === 'false' ? 'NO' : controller.answers.policyReviewed),
                            label: (controller.answers.policyReviewed === true || controller.answers.policyReviewed === false  || controller.answers.policyReviewed === 'false') ? 'This policy has been reviewed in the last 3 years, i.e. on or after 2020' : controller.answers.policyReviewed
                        },
                        papersPublishedPercentage: {
                            value: controller.answers.papersPublishedPercentage || controller.answers.papersPublishedPercentage === 0 ? parseFloat(controller.answers.papersPublishedPercentage) : controller.answers.papersPublishedPercentage,
                            valueAsString: controller.answers.papersPublishedPercentage || controller.answers.papersPublishedPercentage === 0 ? controller.answers.papersPublishedPercentage.toString() : controller.answers.papersPublishedPercentage,
                            label: controller.answers.papersPublishedPercentage || controller.answers.papersPublishedPercentage === 0 ? 'Percentage' : controller.answers.papersPublishedPercentage,
                        },
                        hoursOfTrainingSA: {
                            value: controller.answers.hoursOfTrainingSA || controller.answers.hoursOfTrainingSA === 0 ? parseFloat(controller.answers.hoursOfTrainingSA) : controller.answers.hoursOfTrainingSA,
                            valueAsString: controller.answers.hoursOfTrainingSA || controller.answers.hoursOfTrainingSA === 0 ? controller.answers.hoursOfTrainingSA.toString() : controller.answers.hoursOfTrainingSA,
                            label: controller.answers.hoursOfTrainingSA || controller.answers.hoursOfTrainingSA === 0 ? 'How many hours of training on these Social aspects per employee?' : controller.answers.hoursOfTrainingSA,
                        },
                        hoursOfTrainingEA: {
                            value: controller.answers.hoursOfTrainingEA || controller.answers.hoursOfTrainingEA === 0 ? parseFloat(controller.answers.hoursOfTrainingEA) : controller.answers.hoursOfTrainingEA,
                            valueAsString: controller.answers.hoursOfTrainingEA || controller.answers.hoursOfTrainingEA === 0 ? controller.answers.hoursOfTrainingEA.toString() : controller.answers.hoursOfTrainingEA,
                            label: controller.answers.hoursOfTrainingEA || controller.answers.hoursOfTrainingEA === 0 ? 'How many hours of training on these Environmental aspects per employee?' : controller.answers.hoursOfTrainingEA,
                        },
                        ethicalValues: {
                            value: controller.answers.ethicalValues ? controller.answers.ethicalValues : (controller.answers.ethicalValues === false ? 'false' : controller.answers.ethicalValues),
                            url: controller.answers.ethicalValuesUrl,
                            valueAsString: controller.answers.ethicalValues === true ? 'YES' : (controller.answers.ethicalValues === false || controller.answers.ethicalValues === 'false' ? 'NO' : controller.answers.ethicalValues),
                            label: (controller.answers.ethicalValues === true || controller.answers.ethicalValues === false  || controller.answers.ethicalValues === 'false') ? 'Our organisation develops clear ethical values (e.g., diversity, honesty, respect, fairness) and these are enshrined in a publicly available strategic document.' : controller.answers.ethicalValues
                        },
                        providesTraning: {
                            value: controller.answers.providesTraning ? controller.answers.providesTraning : (controller.answers.providesTraning === false ? 'false' : controller.answers.providesTraning),
                            url: controller.answers.providesTraningUrl,
                            valueAsString: controller.answers.providesTraning === true ? 'YES' : (controller.answers.providesTraning === false || controller.answers.providesTraning === 'false' ? 'NO' : controller.answers.providesTraning),
                            label: (controller.answers.providesTraning === true || controller.answers.providesTraning === false  || controller.answers.providesTraning === 'false') ? 'Our university provides training based on those values at all levels of the organisation.' : controller.answers.providesTraning
                        },
                        ethicalCompliance: {
                            value: controller.answers.ethicalCompliance ? controller.answers.ethicalCompliance : (controller.answers.ethicalCompliance === false ? 'false' : controller.answers.ethicalCompliance),
                            url: controller.answers.ethicalComplianceUrl,
                            valueAsString: controller.answers.ethicalCompliance === true ? 'YES' : (controller.answers.ethicalCompliance === false || controller.answers.ethicalCompliance === 'false' ? 'NO' : controller.answers.ethicalCompliance),
                            label: (controller.answers.ethicalCompliance === true || controller.answers.ethicalCompliance === false  || controller.answers.ethicalCompliance === 'false') ? 'There is an office for ethical compliance within our institution, with a designated official with oversight on ethical matters across the institution.' : controller.answers.ethicalCompliance
                        },
                        internalReporting: {
                            value: controller.answers.internalReporting ? controller.answers.internalReporting : (controller.answers.internalReporting === false ? 'false' : controller.answers.internalReporting),
                            url: controller.answers.internalReportingUrl,
                            valueAsString: controller.answers.internalReporting === true ? 'YES' : (controller.answers.internalReporting === false || controller.answers.internalReporting === 'false' ? 'NO' : controller.answers.internalReporting),
                            label: (controller.answers.internalReporting === true || controller.answers.internalReporting === false  || controller.answers.internalReporting === 'false') ? 'Our organisation has an internal reporting system to assure the confidentiality of whistleblowers or a grievance procedure for staff concerning an employment matter' : controller.answers.internalReporting
                        },
                        representUgAndPgStudent: {
                            value: controller.answers.representUgAndPgStudent ? controller.answers.representUgAndPgStudent : (controller.answers.representUgAndPgStudent === false ? 'false' : controller.answers.representUgAndPgStudent),
                            valueAsString: controller.answers.representUgAndPgStudent === true ? 'YES' : (controller.answers.representUgAndPgStudent === false || controller.answers.representUgAndPgStudent === 'false' ? 'NO' : controller.answers.representUgAndPgStudent),
                            label: (controller.answers.representUgAndPgStudent === true || controller.answers.representUgAndPgStudent === false  || controller.answers.representUgAndPgStudent === 'false') ? 'Our university has a recognised student union that represents both undergraduate and postgraduate students at university level' : controller.answers.representUgAndPgStudent
                        },
                        studentUnionConnected: {
                            value: controller.answers.studentUnionConnected ? controller.answers.studentUnionConnected : (controller.answers.studentUnionConnected === false ? 'false' : controller.answers.studentUnionConnected),
                            valueAsString: controller.answers.studentUnionConnected === true ? 'YES' : (controller.answers.studentUnionConnected === false || controller.answers.studentUnionConnected === 'false' ? 'NO' : controller.answers.studentUnionConnected),
                            label: (controller.answers.studentUnionConnected === true || controller.answers.studentUnionConnected === false  || controller.answers.studentUnionConnected === 'false') ? 'This student union is connected/affiliated to a wider national student union body' : controller.answers.studentUnionConnected
                        },
                        studentUnionAdheres: {
                            value: controller.answers.studentUnionAdheres ? controller.answers.studentUnionAdheres : (controller.answers.studentUnionAdheres === false ? 'false' : controller.answers.studentUnionAdheres),
                            valueAsString: controller.answers.studentUnionAdheres === true ? 'YES' : (controller.answers.studentUnionAdheres === false || controller.answers.studentUnionAdheres === 'false' ? 'NO' : controller.answers.studentUnionAdheres),
                            label: (controller.answers.studentUnionAdheres === true || controller.answers.studentUnionAdheres === false  || controller.answers.studentUnionAdheres === 'false') ? "The student union elects its leadership, allowing students to vote." : controller.answers.studentUnionAdheres
                        },
                        UniversityStudentUnionUrl: {
                            value: controller.answers.UniversityStudentUnionUrl,
                            valueAsString: controller.answers.UniversityStudentUnionUrl,
                            label: controller.answers.UniversityStudentUnionUrl? 'Evidence': controller.answers.UniversityStudentUnionUrl,
                        },
                    
                        incomeAndExpenditure: {
                            value: controller.answers.incomeAndExpenditure ? controller.answers.incomeAndExpenditure : (controller.answers.incomeAndExpenditure === false ? 'false' : controller.answers.incomeAndExpenditure),
                            valueAsString: controller.answers.incomeAndExpenditure === true ? 'YES' : (controller.answers.incomeAndExpenditure === false || controller.answers.incomeAndExpenditure === 'false' ? 'NO' : controller.answers.incomeAndExpenditure),
                            label: (controller.answers.incomeAndExpenditure === true || controller.answers.incomeAndExpenditure === false  || controller.answers.incomeAndExpenditure === 'false') ? 'Income' : controller.answers.incomeAndExpenditure
                        },
                        financialPosition: {
                            value: controller.answers.financialPosition ? controller.answers.financialPosition : (controller.answers.financialPosition === false ? 'false' : controller.answers.financialPosition),
                            valueAsString: controller.answers.financialPosition === true ? 'YES' : (controller.answers.financialPosition === false || controller.answers.financialPosition === 'false' ? 'NO' : controller.answers.financialPosition),
                            label: (controller.answers.financialPosition === true || controller.answers.financialPosition === false  || controller.answers.financialPosition === 'false') ? 'Expenditure' : controller.answers.financialPosition
                        },
                        cashFlowInformation: {
                            value: controller.answers.cashFlowInformation ? controller.answers.cashFlowInformation : (controller.answers.cashFlowInformation === false ? 'false' : controller.answers.cashFlowInformation),
                            valueAsString: controller.answers.cashFlowInformation === true ? 'YES' : (controller.answers.cashFlowInformation === false || controller.answers.cashFlowInformation === 'false' ? 'NO' : controller.answers.cashFlowInformation),
                            label: (controller.answers.cashFlowInformation === true || controller.answers.cashFlowInformation === false  || controller.answers.cashFlowInformation === 'false') ? 'Borrowing' : controller.answers.cashFlowInformation
                        },
                        yearForecast: {
                            value: controller.answers.yearForecast ? controller.answers.yearForecast : (controller.answers.yearForecast === false ? 'false' : controller.answers.yearForecast),
                            valueAsString: controller.answers.yearForecast === true ? 'YES' : (controller.answers.yearForecast === false || controller.answers.yearForecast === 'false' ? 'NO' : controller.answers.yearForecast),
                            label: (controller.answers.yearForecast === true || controller.answers.yearForecast === false  || controller.answers.yearForecast === 'false') ? 'Surplus' : controller.answers.yearForecast
                        },
                        financialReportsUrl: {
                            value: controller.answers.financialReportsUrl,
                            valueAsString: controller.answers.financialReportsUrl,
                            label: controller.answers.financialReportsUrl? 'Evidence': controller.answers.financialReportsUrl,
                        },
                    
                        dedicatedFteStaff: {
                            value: controller.answers.dedicatedFteStaff ? controller.answers.dedicatedFteStaff : (controller.answers.dedicatedFteStaff === false ? 'false' : controller.answers.dedicatedFteStaff),
                            valueAsString: controller.answers.dedicatedFteStaff === true ? 'YES' : (controller.answers.dedicatedFteStaff === false || controller.answers.dedicatedFteStaff === 'false' ? 'NO' : controller.answers.dedicatedFteStaff),
                            label: (controller.answers.dedicatedFteStaff === true || controller.answers.dedicatedFteStaff === false  || controller.answers.dedicatedFteStaff === 'false') ? 'The Research Centre has dedicated FTE staff.' : controller.answers.dedicatedFteStaff
                        },
                        isSuitestTask: {
                            value: controller.answers.isSuitestTask ? controller.answers.isSuitestTask : (controller.answers.isSuitestTask === false ? 'false' : controller.answers.isSuitestTask),
                            valueAsString: controller.answers.isSuitestTask === true ? 'YES' : (controller.answers.isSuitestTask === false || controller.answers.isSuitestTask === 'false' ? 'NO' : controller.answers.isSuitestTask),
                            label: (controller.answers.isSuitestTask === true || controller.answers.isSuitestTask === false  || controller.answers.isSuitestTask === 'false') ? 'If yes, is this tool Sulitest TASK?' : controller.answers.isSuitestTask
                        },
                        researchCenterContribute: {
                            value: controller.answers.researchCenterContribute ? controller.answers.researchCenterContribute : (controller.answers.researchCenterContribute === false ? 'false' : controller.answers.researchCenterContribute),
                            valueAsString: controller.answers.researchCenterContribute === true ? 'YES' : (controller.answers.researchCenterContribute === false || controller.answers.researchCenterContribute === 'false' ? 'NO' : controller.answers.researchCenterContribute),
                            label: (controller.answers.researchCenterContribute === true || controller.answers.researchCenterContribute === false  || controller.answers.researchCenterContribute === 'false') ? 'This Research Centre contributes (through curriculum, teaching and supervision) to the teaching of undergraduate programmes and/or postgraduate programmes.' : controller.answers.researchCenterContribute
                        },
                        presenceOfSearchText: {
                            value: controller.answers.presenceOfSearchText,
                            valueAsString: controller.answers.presenceOfSearchText,
                            label: controller.answers.presenceOfSearchText ? 'Evidence' : controller.answers.presenceOfSearchText,
                        },
                        assessmentToolEv: {
                            value: controller.answers.assessmentToolEv,
                            valueAsString: controller.answers.assessmentToolEv,
                            label: controller.answers.assessmentToolEv ? 'Evidence' : controller.answers.assessmentToolEv,
                        },
                        lastConductedSurvey: {
                            value: controller.answers.lastConductedSurvey ,
                            valueAsString: controller.answers.lastConductedSurvey ,
                            label: controller.answers.lastConductedSurvey? 'Evidence': controller.answers.lastConductedSurvey
                        },
                        emissionsScope: {
                            value: controller.answers.emissionsScope || controller.answers.emissionsScope === 0 ? parseFloat(controller.answers.emissionsScope) : controller.answers.emissionsScope,
                            valueAsString: controller.answers.emissionsScope || controller.answers.emissionsScope === 0 ? controller.answers.emissionsScope.toString() : controller.answers.emissionsScope,
                            label: controller.answers.emissionsScope || controller.answers.emissionsScope === 0 ? 'Total Scope 1 & 2 emissions in tCO2e' : controller.answers.emissionsScope,
                        },
                    
                        reportOnScope: {
                            value: controller.answers.reportOnScope || controller.answers.reportOnScope === 0 ? parseFloat(controller.answers.reportOnScope) : controller.answers.reportOnScope,
                            valueAsString: controller.answers.reportOnScope || controller.answers.reportOnScope === 0 ? controller.answers.reportOnScope.toString() : controller.answers.reportOnScope,
                            label: controller.answers.reportOnScope || controller.answers.reportOnScope === 0 ? 'If you also report on Scope 3 emissions, please list your estimate here, in tCO2e' : controller.answers.reportOnScope,
                        },
                        carbonEmissionUrl: {
                            value: controller.answers.carbonEmissionUrl,
                            valueAsString: controller.answers.carbonEmissionUrl,
                            label: controller.answers.carbonEmissionUrl ? 'Evidence': controller.answers.carbonEmissionUrl,
                        },
                        officiallyRecognised: {
                            value: controller.answers.officiallyRecognised ? controller.answers.officiallyRecognised : (controller.answers.officiallyRecognised === false ? 'false' : controller.answers.officiallyRecognised),
                            valueAsString: controller.answers.officiallyRecognised === true ? 'YES' : (controller.answers.officiallyRecognised === false || controller.answers.officiallyRecognised === 'false' ? 'NO' : controller.answers.officiallyRecognised),
                            label: (controller.answers.officiallyRecognised === true || controller.answers.officiallyRecognised === false  || controller.answers.officiallyRecognised === 'false') ? 'We offer these courses and they are linked to officially recognised credits (e.g., European Credit Transfer and Accumulation System - ECTS, in Europe)' : controller.answers.officiallyRecognised
                        },
                    
                        recognisedQualification: {
                            value: controller.answers.recognisedQualification ? controller.answers.recognisedQualification : (controller.answers.recognisedQualification === false ? 'false' : controller.answers.recognisedQualification),
                            valueAsString: controller.answers.recognisedQualification === true ? 'YES' : (controller.answers.recognisedQualification === false || controller.answers.recognisedQualification === 'false' ? 'NO' : controller.answers.recognisedQualification),
                            label: (controller.answers.recognisedQualification === true || controller.answers.recognisedQualification === false  || controller.answers.recognisedQualification === 'false') ? 'They lead to the award of an officially recognised qualification that specifically refers to climate science and environmental sustainability (e.g., MSc in Climate Change: Science, Society and Solutions at the University of Manchester in the UK; BSc in Climate Science and Adaptation at the University of Newcastle, Australia)' : controller.answers.recognisedQualification
                        },
                        climateScienceUrl: {
                            value: controller.answers.climateScienceUrl,
                            valueAsString: controller.answers.climateScienceUrl,
                            label: controller.answers.climateScienceUrl ? 'Evidence': controller.answers.climateScienceUrl,
                        },
                        baseLineYear: {
                            value: controller.answers.baseLineYear || controller.answers.baseLineYear === 0 ? parseFloat(controller.answers.baseLineYear) : controller.answers.baseLineYear,
                            valueAsString: controller.answers.baseLineYear || controller.answers.baseLineYear === 0 ? controller.answers.baseLineYear.toString() : controller.answers.baseLineYear,
                            label: controller.answers.baseLineYear || controller.answers.baseLineYear === 0 ? 'Baseline year' : controller.answers.baseLineYear,
                        },
                        totalScopeEmission: {
                            value: controller.answers.totalScopeEmission || controller.answers.totalScopeEmission === 0 ? parseFloat(controller.answers.totalScopeEmission) : controller.answers.totalScopeEmission,
                            valueAsString: controller.answers.totalScopeEmission || controller.answers.totalScopeEmission === 0 ? controller.answers.totalScopeEmission.toString() : controller.answers.totalScopeEmission,
                            label: controller.answers.totalScopeEmission || controller.answers.totalScopeEmission === 0 ? 'Total Scope 1 & 2 for the baseline year, in tco2e' : controller.answers.totalScopeEmission,
                        },
                        carbonReductionUrl: {
                            value: controller.answers.carbonReductionUrl,
                            valueAsString: controller.answers.carbonReductionUrl,
                            label: controller.answers.carbonReductionUrl ? 'Evidence': controller.answers.carbonReductionUrl,
                        },
                        energyGenerated: {
                            value: controller.answers.energyGenerated || controller.answers.energyGenerated === 0 ? parseFloat(controller.answers.energyGenerated) : controller.answers.energyGenerated,
                            valueAsString: controller.answers.energyGenerated || controller.answers.energyGenerated === 0 ? controller.answers.energyGenerated.toString() : controller.answers.energyGenerated,
                            label: controller.answers.energyGenerated || controller.answers.energyGenerated === 0 ? 'Total energy from renewables': controller.answers.energyGenerated,
                        },
                        buildingFootPrints: {
                            value: controller.answers.buildingFootPrints || controller.answers.buildingFootPrints === 0 ? parseFloat(controller.answers.buildingFootPrints) : controller.answers.buildingFootPrints,
                            valueAsString: controller.answers.buildingFootPrints || controller.answers.buildingFootPrints === 0 ? controller.answers.buildingFootPrints.toString() : controller.answers.buildingFootPrints,
                            label: controller.answers.buildingFootPrints || controller.answers.buildingFootPrints === 0 ? 'Total campus building footprint in square meters': controller.answers.buildingFootPrints,
                        },
                        publicalyComittedUrl: {
                            value: controller.answers.publicalyComittedUrl,
                            valueAsString: controller.answers.publicalyComittedUrl,
                            label: controller.answers.publicalyComittedUrl ? 'Evidence': controller.answers.publicalyComittedUrl,
                        },
                    },
                    status: controller.status,
                    source: controller.source,
                    previouslySubmitted : controller.previouslySubmitted
                });
            }
        };
        controller.explanationProvidedWatch = function(questionHandles) {
            controller.question.isExplanationProvided = false;
            if (questionHandles && questionHandles.length >0) {
                controller.question.isExplanationProvided = questionHandles.includes(controller.question.handle);
            }
        };
        function isSumOfUgAndPgOverallQuestion(handle) {
            return handle === 'overall_students' || handle === 'graduate_postgraduate_students' || handle === 'undergraduate_students';
        }
        function isSumOfUgAndPgInternationalQuestion(handle) {
            return handle === 'overall_international_students' || handle === 'graduate_postgraduate_international_students' || handle === 'undergraduate_international_students';
        }
        function isSumOfUgAndPgInboundQuestion(handle) {
            return handle === 'undergraduate_exchange_students_inbound' || handle === 'graduate_postgraduate_exchange_students_inbound' || handle === 'exchange_students_inbound';
        }
        function isSumOfUgAndPgOutboundQuestion(handle) {
            return handle === 'exchange_students_outbound' || handle === 'graduate_postgraduate_exchange_students_outbound' || handle === 'undergraduate_exchange_students_outbound';
        }
        function isSumOfAllFacultyStaff(handle) {
            return handle === 'faculty_staff' || handle === 'faculty_staff_male' || handle === 'faculty_staff_female' || handle === 'faculty_staff_other';
        }
        function isSumOfAllStudents(handle) {
            return handle === 'overall_students' || handle === 'number_of_male_students' || handle === 'students_female' || handle === 'students_other';
        }
        controller.getClassForFte = function () {
            if (controller.ranking === 'esg' && controller.question.handle === 'total_students_nationalities') {
                return 'd-none';
            }
            var fteClass = controller.ranking === 'esg' || controller.question.handle === 'first_generation_leners_percentage' ? 'col-xs-4' : 'col-xs-1 fte-column';
            fteClass = controller.isInvalidField('fte') ? fteClass + ' has-manual-error' : fteClass;
            return fteClass;
        };
        controller.isSingleBox = function () {
            return controller.question.handle === 'student_retention_rate_percentage' || 
            controller.question.handle === 'grad_completion_rate_percentage' || 
            controller.question.handle === 'student_continuation_rate_percentage' || 
            controller.question.handle === 'total_students_nationalities';
        };
        controller.isInvalidField = function (field) {
            // if (controller.question.handle !== 'overall_students') {
            //     return;
            // }
            if (StatisticsSubmissionsFactory.getManualyHighlightQuestions().indexOf(controller.question.handle) !== -1) {
                var isInvalid = false;
                if (isSumOfUgAndPgOverallQuestion(controller.question.handle)) {
                    angular.forEach(StatisticsSubmissionsFactory.getValidationErrorsWithFields(), function (elem) {
                        if (elem.field === field && elem.rule === 'sumOfUgAndPgOverall') {
                            isInvalid = true;
                        }
                    });
                }
                if (isSumOfUgAndPgInternationalQuestion(controller.question.handle)) {
                    angular.forEach(StatisticsSubmissionsFactory.getValidationErrorsWithFields(), function (elem) {
                        if (elem.field === field && elem.rule === 'sumOfUgAndPgInternational') {
                            isInvalid = true;
                        }
                    });
                }
                if (isSumOfUgAndPgInboundQuestion(controller.question.handle)) {
                    angular.forEach(StatisticsSubmissionsFactory.getValidationErrorsWithFields(), function (elem) {
                        if (elem.field === field && elem.rule === 'sumOfUgAndPgInboundOverall') {
                            isInvalid = true;
                        }
                    });
                }
                if (isSumOfUgAndPgOutboundQuestion(controller.question.handle)) {
                    angular.forEach(StatisticsSubmissionsFactory.getValidationErrorsWithFields(), function (elem) {
                        if (elem.field === field && elem.rule === 'sumOfUgAndPgOutboundOverall') {
                            isInvalid = true;
                        }
                    });
                }
                if (isSumOfAllFacultyStaff(controller.question.handle)) {
                    angular.forEach(StatisticsSubmissionsFactory.getValidationErrorsWithFields(), function (elem) {
                        if (elem.field === field && elem.rule === 'sumOfAllFacultyStaff') {
                            isInvalid = true;
                        }
                    });
                }
                if (isSumOfAllStudents(controller.question.handle)) {
                    angular.forEach(StatisticsSubmissionsFactory.getValidationErrorsWithFields(), function (elem) {
                        if (elem.field === field && elem.rule === 'sumOfAllStudents') {
                            isInvalid = true;
                        }
                    });
                }
                return isInvalid;
            }
        };
        controller.overallStudentsCurrentAnswersWatch = function (answers, oldAnswers) {
            if (answers) {
                if (controller.question.handle === 'overall_students' && !angular.equals(answers, oldAnswers) && $location.search().question !== 'overall_students') {
                    controller.answers.ft = answers.ft ? answers.ft.value : null;
                    controller.answers.pt = answers.pt ? answers.pt.value : null;
                    controller.answers.hc = answers.hc ? answers.hc.value : null;
                    controller.answers.fte = answers.fte ? answers.fte.value : null;
                    $timeout(function() {
                        $('#ft_column_overall_students').triggerHandler('keyup');
                        $('#pt_column_overall_students').triggerHandler('keyup');
                        $('#fte_column_overall_students').triggerHandler('keyup');
                    });
                }
            }
        };
        controller.overallInternationalStudentsCurrentAnswersWatch = function (answers, oldAnswers) {
            if (answers) {
                if (controller.question.handle === 'overall_international_students' && !angular.equals(answers, oldAnswers) && $location.search().question !== 'overall_international_students') {
                    controller.answers.ft = answers.ft ? answers.ft.value : null;
                    controller.answers.pt = answers.pt ? answers.pt.value : null;
                    controller.answers.hc = answers.hc ? answers.hc.value : null;
                    controller.answers.fte = answers.fte ? answers.fte.value : null;
                    $timeout(function() {
                        $('#ft_column_overall_international_students').triggerHandler('keyup');
                        $('#pt_column_overall_international_students').triggerHandler('keyup');
                        $('#fte_column_overall_international_students').triggerHandler('keyup');
                    });
                }
            }
        };
        controller.inboundOverallStudentsCurrentAnswersWatch = function (answers, oldAnswers) {
            if (answers) {
                if (controller.question.handle === 'exchange_students_inbound' && !angular.equals(answers, oldAnswers) && $location.search().question !== 'exchange_students_inbound') {
                    controller.answers.ft = answers.ft ? answers.ft.value : null;
                    controller.answers.pt = answers.pt ? answers.pt.value : null;
                    controller.answers.hc = answers.hc ? answers.hc.value : null;
                    controller.answers.fte = answers.fte ? answers.fte.value : null;
                    $timeout(function() {
                        $('#ft_column_exchange_students_inbound').triggerHandler('keyup');
                        $('#pt_column_exchange_students_inbound').triggerHandler('keyup');
                        $('#fte_column_exchange_students_inbound').triggerHandler('keyup');
                    });
                }
            }
        };
        controller.outboundOverallStudentsCurrentAnswersWatch = function (answers, oldAnswers) {
            if (answers) {
                if (controller.question.handle === 'exchange_students_outbound' && !angular.equals(answers, oldAnswers) && $location.search().question !== 'exchange_students_outbound') {
                    controller.answers.ft = answers.ft ? answers.ft.value : null;
                    controller.answers.pt = answers.pt ? answers.pt.value : null;
                    controller.answers.hc = answers.hc ? answers.hc.value : null;
                    controller.answers.fte = answers.fte ? answers.fte.value : null;
                    $timeout(function() {
                        $('#ft_column_exchange_students_outbound').triggerHandler('keyup');
                        $('#pt_column_exchange_students_outbound').triggerHandler('keyup');
                        $('#fte_column_exchange_students_outbound').triggerHandler('keyup');
                    });
                }
            }
        };
        controller.initWatches = function () {
            WatchService.create($scope, StatisticsSubmissionsFactory.getAnswers, controller.answersWatch);
            WatchService.create($scope, StatisticsSubmissionsFactory.getActiveQuestionId, controller.activeQuestionIdWatch);
            WatchService.create($scope, StatisticsSubmissionsFactory.isDataRequest, controller.dataRequestWatch);
        };
        controller.$onInit = function () {
            controller.initWatches();
        };
    }
}(window.angular));
(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {
        components:{}
    });

    App.components.statisticsPersonalHeader = {
        templateUrl: '/scripts/modules/rankings/components/statisticsSubmissions/statisticsPersonalHeader/statisticsPersonalHeaderView.html',
        controller: 'Rankings.StatisticsPersonalHeaderController',
        bindings: {
            categoryHandle: '<',
            ranking:'<'
        }
    };

    angular.module('qsHub.rankings').component('statisticsPersonalHeader', App.components.statisticsPersonalHeader);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers:{}});
    App.controllers.StatisticsPersonalHeader = function (
        $scope,
        $sce,
        WatchService,
        UserFactory,
        StatisticsSubmissionsFactory
    ) {
        var controller = this;
        controller.isClient = UserFactory.isClient();

        controller.categoriesWatch = function (categories) {
            if (!categories) {
                return false;
            }
            var i = 0,
                total = categories.length;
            for (i; i < total; i++) {
                if (categories[i].handle === controller.categoryHandle) {
                    controller.categoryName = categories[i].name;
                    controller.categoryDefinition = categories[i].definition.length ?
                        $sce.getTrustedHtml(categories[i].definition) : null;
                    if (controller.categoryDefinition && !controller.categoryDefinition.length) {
                        controller.categoryDefinition = null;
                    }
                    break;
                }
            }
        };

        controller.initWatches = function () {
            WatchService.create($scope, StatisticsSubmissionsFactory.getCategories, controller.categoriesWatch);
        };

        controller.$onInit = function () {
            controller.initWatches();
        };

        controller.getFieldClass = function (field) {
            var fieldClass = 'col-xs-4';
            if (field === 'esgURL') {
                if (controller.categoryHandle === 'environmental_institutions' || controller.categoryHandle === 'good_governance' ||
                controller.categoryHandle === 'knowledge_exchange' || 
                controller.categoryHandle === 'health_and_wellbeing') {
                    fieldClass = 'col-xs-5';
                }
            }

            return fieldClass;
        };
    };

    angular
        .module('qsHub.rankings')
        .controller('Rankings.StatisticsPersonalHeaderController', [
            '$scope',
            '$sce',
            'WatchService',
            'UserFactory',
            'StatisticsSubmissionsFactory',
            App.controllers.StatisticsPersonalHeader
        ]);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {
        components:{}
    });

    App.components.statisticsNumericQuestion = {
        templateUrl: '/scripts/modules/rankings/components/statisticsSubmissions/statisticsNumericQuestion/statisticsNumericQuestionView.html',
        controller: 'Rankings.StatisticsNumericQuestionController',
        bindings: {
            question: '<'
        }
    };

    angular.module('qsHub.rankings').component('statisticsNumericQuestion', App.components.statisticsNumericQuestion);

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.rankings')
        .controller('Rankings.StatisticsNumericQuestionController', [
            '$scope',
            '$location',
            'broadcast',
            'WatchService',
            'StatisticsSubmissionsFactory',
            'StatisticsDashboardFactory',
            'UserFactory',
            '$state',
            StatisticsNumericQuestion
        ]);

    function StatisticsNumericQuestion($scope,
                                       $location,
                                       broadcast,
                                       WatchService,
                                       StatisticsSubmissionsFactory,
                                       StatisticsDashboardFactory,
                                       UserFactory,
                                       $state) {
        var controller = this;
        controller.answers = controller.answerBeforeChanges = {
            ft: null,
            pt: null,
            hc: null,
            fte: null,
            estimate: null,
        };
        controller.status = null;
        controller.statusList = StatisticsDashboardFactory.getStatuses();
        controller.hasFocus = false;
        controller.isClient = UserFactory.isClient();

        controller.hasAnswers = function () {
            return controller.answer !== null &&
                typeof controller.answer !== 'undefined' &&
                controller.answer.toString().length > 0;
        };

        controller.isActive = function () {
            return controller.hasFocus;
        };

        controller.handleFocus = function () {
            StatisticsSubmissionsFactory.setActiveQuestionId(controller.question.id);
            controller.hasFocus = true;
            StatisticsDashboardFactory.setActiveQuestion(controller.question);
            if ($location.search().question !== controller.question.handle) {
                $state.transitionTo($state.current, {question: controller.question.handle}, {notify: false});
            }
        };

        controller.activeQuestionIdWatch = function (questionId) {
            if (questionId) {
                if (questionId !== controller.question.id) {
                    controller.hasFocus = false;
                } else {
                    controller.handleFocus();
                }
            } else {
                controller.hasFocus = false;
            }
        };

        controller.resetAnswer = function () {
            controller.id = null;
            controller.answer = null;
            controller.source = null;
            controller.status = null;
        };

        controller.answersWatch = function (data) {
            if (!data || !data.length) {
                controller.resetAnswer();
                controller.answerBeforeChanges = angular.copy(controller.answer);
                return;
            }
            if (!StatisticsSubmissionsFactory.getInvalidIndicatorsData(controller.question.handle)) {
                controller.resetAnswer();
                var i = 0,
                    total = data.length,
                    item = null;
                for (i; i < total; i++) {
                    if (data[i].questionHandle === controller.question.handle) {
                        item = data[i].answers;
                        controller.id = data[i].id;
                        controller.answer = item.value ? item.value : null;
                        controller.source = data[i].source;
                        controller.status = data[i].status;
                        controller.answerBeforeChanges = angular.copy(controller.answer);
                        break;
                    }
                }
            }
        };

        $scope.$on(broadcast.saveCompleted, function () {
            controller.answerBeforeChanges = angular.copy(controller.answer);
        });

        controller.dataRequestWatch = function (request) {
            if (request) {
                StatisticsSubmissionsFactory.broadcastData({
                    question: controller.question,
                    answerId: controller.id,
                    answers: {
                        value: controller.answer || controller.answer === 0 ? parseInt(controller.answer, 10) : controller.answer,
                        valueAsString: controller.answer || controller.answer === 0 ? controller.answer.toString() : controller.answer,
                        estimate: false
                    },
                    status: controller.status,
                    source: controller.source
                });
            }
        };

        controller.initWatches = function () {
            WatchService.create($scope, StatisticsSubmissionsFactory.getAnswers, controller.answersWatch);
            WatchService.create($scope, StatisticsSubmissionsFactory.getActiveQuestionId, controller.activeQuestionIdWatch);
            WatchService.create($scope, StatisticsSubmissionsFactory.isDataRequest, controller.dataRequestWatch);
        };

        controller.$onInit = function () {
            controller.initWatches();
        };
    }

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {
        components:{}
    });

    App.components.statisticsNumericHeader = {
        templateUrl: '/scripts/modules/rankings/components/statisticsSubmissions/statisticsNumericHeader/statisticsNumericHeaderView.html',
        controller: 'Rankings.StatisticsNumericHeaderController',
        bindings: {
            categoryHandle: '<'
        }
    };

    angular.module('qsHub.rankings').component('statisticsNumericHeader', App.components.statisticsNumericHeader);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers:{}});
    App.controllers.StatisticsNumericHeader = function (
        $scope,
        $sce,
        WatchService,
        UserFactory,
        StatisticsSubmissionsFactory
    ) {
        var controller = this;
        controller.isClient = UserFactory.isClient();

        controller.categoriesWatch = function (categories) {
            if (!categories) {
                return false;
            }
            var i = 0,
                total = categories.length;
            for (i; i < total; i++) {
                if (categories[i].handle === controller.categoryHandle) {
                    controller.categoryName = categories[i].name;
                    controller.categoryDefinition = categories[i].definition.length ?
                        $sce.getTrustedHtml(categories[i].definition) : null;
                    if (controller.categoryDefinition && !controller.categoryDefinition.length) {
                        controller.categoryDefinition = null;
                    }
                    break;
                }
            }
        };

        controller.initWatches = function () {
            WatchService.create($scope, StatisticsSubmissionsFactory.getCategories, controller.categoriesWatch);
        };

        controller.$onInit = function () {
            controller.initWatches();
        };
    };

    angular
        .module('qsHub.rankings')
        .controller('Rankings.StatisticsNumericHeaderController', [
            '$scope',
            '$sce',
            'WatchService',
            'UserFactory',
            'StatisticsSubmissionsFactory',
            App.controllers.StatisticsNumericHeader
        ]);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {
        components:{}
    });

    App.components.statisticsFinancialQuestion = {
        templateUrl: '/scripts/modules/rankings/components/statisticsSubmissions/statisticsFinancialQuestion/statisticsFinancialQuestionView.html',
        controller: 'Rankings.StatisticsFinancialQuestionController',
        bindings: {
            question: '<',
            ranking:'<'
        }
    };

    angular.module('qsHub.rankings').component('statisticsFinancialQuestion', App.components.statisticsFinancialQuestion);

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.rankings')
        .controller('Rankings.StatisticsFinancialQuestionController', [
            '$scope',
            '$location',
            'broadcast',
            'currencies',
            'WatchService',
            'StatisticsSubmissionsFactory',
            'StatisticsDashboardFactory',
            'UserFactory',
            '$state',
            StatisticsFinancialQuestion
        ]);

    function StatisticsFinancialQuestion(
        $scope,
        $location,
        broadcast,
        currencies,
        WatchService,
        StatisticsSubmissionsFactory,
        StatisticsDashboardFactory,
        UserFactory,
        $state
    ) {
        var controller = this;
        controller.status = null;
        controller.source = null;
        controller.statusList = StatisticsDashboardFactory.getStatuses();
        controller.hasFocus = false;
        controller.currencyList = [];
        controller.isClient = UserFactory.isClient();

        controller.hasAnswers = function () {
            return controller.answer !== null &&
                typeof controller.answer !== 'undefined' &&
                controller.answer.toString().length > 0 &&
                controller.selectedCurrency;
        };

        controller.hasAnswersWithoutCurrency = function () {
            if (controller.question.handle === 'students_receive_scholarship_covering_hundred_percent' || controller.question.handle === 'students_receive_scholarship_covering_fifty_percent') {
                return controller.answer !== null &&
                    typeof controller.answer !== 'undefined' &&
                    controller.answer.toString().length > 0;
            }
            return false;
        };

        controller.isActive = function () {
            return controller.hasFocus;
        };

        controller.handleFocus = function () {
            StatisticsSubmissionsFactory.setActiveQuestionId(controller.question.id);
            controller.hasFocus = true;
            StatisticsDashboardFactory.setActiveQuestion(controller.question);
            if ($location.search().question !== controller.question.handle) {
                $state.transitionTo($state.current, {
                    question: controller.question.handle
                }, {
                    notify: false
                });
            }
        };

        controller.setSelectedCurrency = function () {
            angular.forEach(controller.currencyList, function (currency) {
                if (currency.value === controller.currency) {
                    controller.selectedCurrency = currency;
                }
            });
        };

        controller.activeQuestionIdWatch = function (questionId) {
            if (questionId) {
                if (questionId !== controller.question.id) {
                    controller.hasFocus = false;
                } else {
                    controller.handleFocus();
                }
            } else {
                controller.hasFocus = false;
            }
        };

        controller.resetAnswer = function () {
            controller.id = null;
            controller.answer = null;
            controller.currency = null;
            controller.notAvailable = null;
            controller.source = null;
            controller.status = null;
            controller.selectedCurrency = null;
        };

        controller.answersWatch = function (data) {
            if (!data || !data.length) {
                controller.resetAnswer();
                controller.answerBeforeChanges = angular.copy(controller.answer);
                return;
            }
            if (!StatisticsSubmissionsFactory.getInvalidIndicatorsData(controller.question.handle)) {
                controller.resetAnswer();
                var i = 0,
                    total = data.length,
                    item = null;
                for (i; i < total; i++) {
                    if (data[i].questionHandle === controller.question.handle) {
                        item = data[i].answers;
                        controller.id = data[i].id;
                        controller.answer = typeof item.value !== 'undefined' ? item.value : null;
                        controller.answerBeforeChanges = angular.copy(controller.answer);
                        controller.currency = item.currency ? item.currency : null;
                        controller.notAvailable = item.notAvailable ? item.notAvailable : null;
                        controller.setSelectedCurrency();
                        controller.source = data[i].source;
                        controller.status = data[i].status;
                        break;
                    }
                }
            }
        };

        $scope.$on(broadcast.saveCompleted, function () {
            controller.answerBeforeChanges = angular.copy(controller.answer);
        });

        controller.getCurrency = function () {
            return angular.isDefined(controller.selectedCurrency) &&
                controller.selectedCurrency && angular.isDefined(controller.selectedCurrency.value) ?
                controller.selectedCurrency.value : null;
        };

        controller.dataRequestWatch = function (request) {
            if (request) {
                StatisticsSubmissionsFactory.broadcastData({
                    question: controller.question,
                    answerId: controller.id,
                    answers: {
                        value: controller.answer || controller.answer === 0 ? parseFloat(controller.answer) : controller.answer,
                        valueAsString: controller.answer || controller.answer === 0 ? controller.answer.toString() : controller.answer,
                        currency: controller.getCurrency(),
                        notAvailable : controller.notAvailable,
                        isNotAvailableIndicator : controller.question.handle === 'students_receive_scholarship_covering_hundred_percent' || controller.question.handle === 'students_receive_scholarship_covering_fifty_percent' ? true : false,
                        estimate: false
                    },
                    status: controller.status,
                    source: controller.source
                });
            }
        };

        controller.initWatches = function () {
            WatchService.create($scope, StatisticsSubmissionsFactory.getAnswers, controller.answersWatch);
            WatchService.create($scope, StatisticsSubmissionsFactory.getActiveQuestionId, controller.activeQuestionIdWatch);
            WatchService.create($scope, StatisticsSubmissionsFactory.isDataRequest, controller.dataRequestWatch);
        };

        controller.$onInit = function () {
            controller.initWatches();
            angular.forEach(currencies, function (label, value) {
                controller.currencyList.push({
                    value: value,
                    label: label
                });
            });
        };
    }

}(window.angular));
(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {
        components:{}
    });

    App.components.statisticsFinancialHeader = {
        templateUrl: '/scripts/modules/rankings/components/statisticsSubmissions/statisticsFinancialHeader/statisticsFinancialHeaderView.html',
        controller: 'Rankings.StatisticsFinancialHeaderController',
        bindings: {
            categoryHandle: '<'
        }
    };

    angular.module('qsHub.rankings').component('statisticsFinancialHeader', App.components.statisticsFinancialHeader);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers:{}});
    App.controllers.StatisticsFinancialHeader = function (
        $scope,
        $sce,
        WatchService,
        UserFactory,
        StatisticsSubmissionsFactory
    ) {
        var controller = this;
        controller.isClient = UserFactory.isClient();

        controller.categoriesWatch = function (categories) {
            if (!categories) {
                return false;
            }
            var i = 0,
                total = categories.length;
            for (i; i < total; i++) {
                if (categories[i].handle === controller.categoryHandle) {
                    controller.categoryName = categories[i].name;
                    controller.categoryDefinition = categories[i].definition.length ?
                        $sce.getTrustedHtml(categories[i].definition) : null;
                    if (controller.categoryDefinition && !controller.categoryDefinition.length) {
                        controller.categoryDefinition = null;
                    }
                    break;
                }
            }
        };

        controller.initWatches = function () {
            WatchService.create($scope, StatisticsSubmissionsFactory.getCategories, controller.categoriesWatch);
        };

        controller.$onInit = function () {
            controller.initWatches();
        };
    };

    angular
        .module('qsHub.rankings')
        .controller('Rankings.StatisticsFinancialHeaderController', [
            '$scope',
            '$sce',
            'WatchService',
            'UserFactory',
            'StatisticsSubmissionsFactory',
            App.controllers.StatisticsFinancialHeader
        ]);

}(window.angular));

(function(angular) {
    'use strict';

    var App = App || {};
    App = angular.extend({}, App, { directives: {} });

    App.directives.statisticsSource = function(rankingsSources) {
        return {
            restrict: 'E',
            scope: {
                source: '=',
                enabled: '='
            },
            templateUrl: '/scripts/modules/rankings/components/statisticsSubmissions/statisticsSource/statisticsSourceView.html',
            link: function($scope, element, $attrs) {
                $scope.sourceList = [];
                $scope.selectedSource = {};
                $scope.selectedSource.selectedItem = null;
                angular.forEach(rankingsSources, function (label, value) {
                    $scope.sourceList.push({
                        value: value,
                        label: label
                    });
                });
                $scope.handleOptionClick = function (item) {
                    $scope.source = item && item.value ? item.value : null;
                };
                $scope.$watch('source', function (source) {
                    if (source) {
                        angular.forEach($scope.sourceList, function (item) {
                            if (item.value === source) {
                                $scope.selectedSource.selectedItem = item;
                            }
                        });
                    } else {
                        $scope.selectedSource.selectedItem = null;
                    }
                });
            }
        };
    };

    angular
        .module('qsHub.rankings')
        .directive('statisticsSource', ['rankingsSources', App.directives.statisticsSource]);

}(window.angular));

(function(angular) {
    'use strict';

    var App = App || {};
    App = angular.extend({}, App, { directives: {} });

    App.directives.statisticsStatusPopup = function () {
        return {
            restrict: 'E',
            controller: 'Rankings.StatisticsStatusPopupController as StatisticsStatusPopupController',
            templateUrl: '/scripts/modules/rankings/components/statisticsSubmissions/statisticsStatusPopup/statisticsStatusPopupView.html',
            scope: {
                status: '=',
                enabled: '='
            }
        };
    };

    angular
        .module('qsHub.rankings')
        .directive('statisticsStatusPopup', [App.directives.statisticsStatusPopup]);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers:{}});
    App.controllers.StatisticsStatusPopup = function (
        $scope,
        StatisticsDashboardFactory,
        UserFactory,
        WatchService,
        UnsavedChangesFactory,
        StatisticsSubmissionsFactory
    ) {
        var controller = this;
        controller.statusList = StatisticsDashboardFactory.getStatuses();
        controller.isClient = UserFactory.isClient();
        $scope.handleStatus = function (status) {
            StatisticsSubmissionsFactory.setQuestionsOfChangedStatuses(StatisticsDashboardFactory.getActiveQuestion().handle);
            if ($scope.status !== status) {
                UnsavedChangesFactory.setChanges(true);
            }
            $scope.status = status;
            controller.isOpen = false;
        };
        WatchService.create($scope, 'enabled', function (newValue, oldValue) {
            if (!angular.equals(newValue, oldValue)) {
                controller.isOpen = false;
            }
        });
    };

    angular
        .module('qsHub.rankings')
        .controller('Rankings.StatisticsStatusPopupController', [
            '$scope',
            'StatisticsDashboardFactory',
            'UserFactory',
            'WatchService',
            'UnsavedChangesFactory',
            'StatisticsSubmissionsFactory',
            App.controllers.StatisticsStatusPopup
        ]);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers:{}});
    App.controllers.ProgressReportByRanking = function (
        $scope,
        $location,
        $state,
        gridNamespaces,
        uiGridConstants,
        uiGridExporterConstants,
        uiGridExporterService,
        WatchService,
        GridService,
        TimeService,
        ProgressReportByRankingService,
        RankingsDashboardService,
        LocalStorageService,
        persistentDates
    ) {
        var controller = this;
        controller.isDatagridReloading = false;
        controller.isRendered = false;
        controller.gridApi = null;

        controller.paginationOptions = GridService.getColumnsFilters(
            gridNamespaces.filters.rankingProgressReport,
            []
        );
        controller.columnsVisibility = GridService.getColumnsVisibility(
            gridNamespaces.visibility.rankingProgressReport
        );

        controller.persistentDate = LocalStorageService.getObject(
            persistentDates.progressReports
        );

        controller.datePicker = {
            date: {
                startDate: angular.isDefined(controller.persistentDate) ? TimeService.getMomentObject(controller.persistentDate.startDate) : TimeService.startOfYear(),
                endDate: angular.isDefined(controller.persistentDate) ? TimeService.getMomentObject(controller.persistentDate.endDate, true) : TimeService.getEndOfToday()
            },
            options: {
                ranges: {
                    'in last 6 months': [TimeService.sub(6, 'months'), TimeService.now()],
                    'in last 12 months': [TimeService.sub(12, 'months'), TimeService.now()],
                    'this year': [TimeService.startOfYear(), TimeService.getEndOfToday()]
                },
                eventHandlers: {
                    'apply.daterangepicker': function (ev) {
                        controller.handleDateRangePickerApplyClick(ev);
                    }
                },
                opens: 'center',
                alwaysShowCalendars: true
            }
        };
        controller.dateFilter = {
            startDate: TimeService.getInUnix(controller.datePicker.date.startDate),
            endDate: TimeService.getInUnix(controller.datePicker.date.endDate)
        };

        controller.handleDateRangePickerApplyClick = function (element) {
            if (!element.model.startDate) {
                element.model.startDate = TimeService.now();
            }
            if (!element.model.endDate) {
                element.model.endDate = TimeService.now();
            }

            controller.dateFilter.startDate = TimeService.getInUnix(element.model.startDate);
            controller.dateFilter.endDate = TimeService.getInUnix(element.model.endDate);
            LocalStorageService.storeObject(persistentDates.progressReports, {
                startDate : controller.dateFilter.startDate,
                endDate : controller.dateFilter.endDate
            });
            controller.getPage();
        };

        controller.handleDatagridRowClick = function (row) {
            return $location.path(
                $state.href('staff.iu.progress-reports.country').replace('#', '')
            ).search({
                ranking: row.ranking,
                question: row.questionHandle
            });
        };

        controller.getPage = function () {
            controller.isDatagridReloading = true;
            return ProgressReportByRankingService.getData(controller.dateFilter).then(function (response) {
                $scope.gridOptions.totalItems = response.length;
                $scope.gridOptions.data = response;
                $scope.gridOptions.minRowsToShow = response.length;
                controller.isDatagridReloading = false;
                controller.showDatagrid = true;
            });
        };

        controller.exportCsv = function (visible) {
            if (visible) {
                $scope.filteredRows = [];
                angular.forEach(controller.gridApi.core.getVisibleRows(controller.gridApi.grid), function (column) {
                    $scope.filteredRows.push(column.entity);
                });
            }
            var results = visible ? $scope.filteredRows : $scope.gridOptions.data;
            if (typeof results === 'undefined') {
                return false;
            }
            var gridApi = controller.gridApi,
                gridOptions = $scope.gridOptions,
                exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                    gridApi.grid,
                    uiGridExporterConstants.VISIBLE
                ),
                exportData = GridService.getCsvData(
                    gridApi.grid.columns,
                    results
                ),
                csvContent = uiGridExporterService.formatAsCsv(
                    exportColumnHeaders,
                    exportData,
                    gridOptions.exporterCsvColumnSeparator
                );

            uiGridExporterService.downloadFile(
                'progress-report-by-ranking-' + TimeService.now() + '.csv',
                csvContent,
                gridOptions.exporterOlderExcelCompatibility
            );
        };

        controller.initDataGrid = function () {
            var multiSelectTemplate = '/scripts/shared/ui-grid/templates/multiSelectFilterHeaderTemplate.html',
                selectCellTemplate = '/scripts/shared/ui-grid/templates/selectCellTemplate.html',
                percentageCellTemplate = '/scripts/shared/ui-grid/templates/percentageCellTemplate.html';
            $scope.gridOptions = {
                appScopeProvider: controller,
                enableSorting: true,
                exporterMenuCsv: false,
                exporterMenuAllData: false,
                exporterMenuPdf: false,
                useExternalFiltering: false,
                enableGridMenu: true,
                showGridFooter: true,
                enableFiltering: true,
                enableColumnResize: true,
                enableFullRowSelection: true,
                enableRowSelection: true,
                multiSelect: false,
                enableRowHeaderSelection: false,
                useExternalPagination: false,
                useExternalSorting: false,
                gridMenuCustomItems: [
                    {
                        title: ' Export visible data as csv',
                        action: function () {
                            controller.exportCsv(true);
                        },
                        order: 209
                    },
                    {
                        title: ' Export all data as csv',
                        action: function () {
                            controller.exportCsv(false);
                        },
                        order: 210
                    }
                ],
                rowTemplate: '/scripts/shared/ui-grid/templates/rowTemplate.html',
                columnDefs: [
                    {
                        displayName: 'Ranking',
                        field: 'ranking',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'ranking'),
                        filter: {
                            column: 'ranking',
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: controller.rankingsList,
                            searchEnabled: true,
                            term: RankingsDashboardService.getMultiSelectTerm(
                                GridService.getFilterByField(controller.paginationOptions, 'ranking', [], true)
                            ),
                            condition: function(searchTerms, cellValue) {
                                if (angular.isDefined(searchTerms) && searchTerms.length > 0) {
                                    var searchTermsArray = [];
                                    angular.forEach(searchTerms, function (searchTerm) {
                                        searchTermsArray.push(searchTerm.value);
                                    });
                                    return searchTermsArray.indexOf(cellValue) !== -1;
                                } else {
                                    return true;
                                }
                            }
                        },
                        filterHeaderTemplate: multiSelectTemplate,
                        cellTemplate: selectCellTemplate
                    },
                    {
                        displayName: 'Institutions',
                        field: 'institutions',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'institutions'),
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'institutions', '')
                        }
                    },
                    {
                        displayName: 'Total number of entries',
                        field: 'total',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'total'),
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'total', '')
                        }
                    },
                    {
                        displayName: 'Empty (%)',
                        field: 'empty',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'empty'),
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'empty', '')
                        },
                        cellTemplate: percentageCellTemplate
                    },
                    {
                        displayName: 'Pending (%)',
                        field: 'pending',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'pending'),
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'pending', '')
                        },
                        cellTemplate: percentageCellTemplate
                    },
                    {
                        displayName: 'Queried (%)',
                        field: 'queried',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'queried'),
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'queried', '')
                        },
                        cellTemplate: percentageCellTemplate
                    },
                    {
                        displayName: 'Accepted (%)',
                        field: 'accepted',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'accepted'),
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'accepted', '')
                        },
                        cellTemplate: percentageCellTemplate
                    },
                    {
                        displayName: 'Rejected (%)',
                        field: 'rejected',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'rejected'),
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'rejected', '')
                        },
                        cellTemplate: percentageCellTemplate
                    }
                ],
                onRegisterApi: function (gridApi) {
                    controller.gridApi = gridApi;

                    gridApi.selection.on.rowSelectionChanged($scope, function (row) {
                        controller.handleDatagridRowClick(row.entity);
                    });

                    gridApi.core.on.rowsRendered($scope, function () {
                        controller.isRendered = true;
                        controller.handleGridFiltersChanges();
                    });

                    gridApi.core.on.sortChanged($scope, controller.handleGridFiltersChanges);
                    gridApi.core.on.filterChanged($scope, controller.handleGridFiltersChanges);
                }
            };
        };

        controller.handleGridFiltersChanges = function () {
            angular.forEach(controller.gridApi.grid.columns, function (column) {
                if (typeof column.filters !== 'undefined' && column.filters[0].term !== 'undefined') {
                    if (column.field === 'ranking') {
                        var arrayFilter = [];
                        angular.forEach(column.filters[0].term, function (term) {
                            arrayFilter.push(term.value);
                        });
                        controller.paginationOptions['filter[' + column.field + '][]'] = arrayFilter;
                    }
                }
                if (column.filters[0].term !== null && typeof column.filters[0].term !== 'undefined') {
                    GridService.applyFilters(column);
                }
                var filterColumn = column.filters[0].column ? column.filters[0].column : column.field;
                if (typeof column.filters !== 'undefined' && column.filters[0].term !== 'undefined') {
                    controller.paginationOptions['filter[' + filterColumn + ']'] = column.filters[0].term;
                }
            });
            GridService.storeColumnsFilters(
                gridNamespaces.filters.rankingProgressReport,
                controller.paginationOptions
            );
        };

        controller.initWatches = function () {
            WatchService.create($scope, 'gridOptions.columnDefs', function (newValue, oldValue) {
                if (newValue !== oldValue) {
                    controller.columnsVisibility = GridService.getGridColumnsVisibility(newValue);
                    var reload = false;
                    GridService.storeColumnsVisibility(
                        gridNamespaces.visibility.rankingProgressReport,
                        controller.columnsVisibility
                    );
                    // Clear filter when column is hidden
                    if (angular.isDefined(newValue) &&
                        angular.isDefined(oldValue)
                    ) {
                        for (var i = 0; i < newValue.length; i++) {
                            if (newValue[i].visible === false &&
                                (oldValue[i].visible === true || typeof oldValue[i].visible === 'undefined') &&
                                angular.isDefined($scope.gridOptions.columnDefs[i].filter)
                            ) {
                                if ($scope.gridOptions.columnDefs[i].filter.hasOwnProperty('term') &&
                                    $scope.gridOptions.columnDefs[i].filter.term) {
                                    controller.paginationOptions['filter[' + $scope.gridOptions.columnDefs[i].field + ']'] = null;
                                    reload = true;
                                }
                                $scope.gridOptions.columnDefs[i].filter.term = null;
                            }
                        }
                        if (reload) {
                            controller.getPage();
                        }
                    }
                }
            }, true);
        };

        controller.init = function () {
            ProgressReportByRankingService.getRankingsList(false).then(function (list) {
                controller.rankingsList = list? list : [];
                controller.initDataGrid();
                controller.getPage();
                controller.initWatches();
            });
        };

        controller.init();
    };

    angular
        .module('qsHub.rankings')
        .controller('Rankings.ProgressReportByRankingController', [
            '$scope',
            '$location',
            '$state',
            'gridNamespaces',
            'uiGridConstants',
            'uiGridExporterConstants',
            'uiGridExporterService',
            'WatchService',
            'UiGridService',
            'TimeService',
            'ProgressReportByRankingService',
            'RankingsDashboardService',
            'LocalStorageService',
            'persistentDates',
            App.controllers.ProgressReportByRanking
        ]);
}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    App.services.ProgressReportByRanking = function (
        $resource,
        $log,
        constants
    ) {
        var service = {};

        /**
         * Get answers list API endpoint.
         *
         * @returns {$resource}
         */
        service.getModel = function () {
            return $resource(constants.api.rankings.url + '/v1/progress-report/ranking', {}, {
                get: {
                    isArray: true,
                    cancellable : true
                }
            });
        };

        service.getRankingsModel = function () {
            return $resource(constants.api.institutions.url + '/v1/list/subscriptions?filter[type]=statistics&sorting[abbreviation]=asc', {}, {
                get: {
                    isArray: false,
                    cancellable : true
                }
            });
        };

        service.getData = function (datFilter) {
            if (constants.dev) {
                var startTime = new Date().getTime(), endTime;
            }

            return service.getModel().get(datFilter).$promise.then(function (data) {
                if (constants.dev) {
                    endTime = new Date().getTime();
                    $log.log('success, got data: ', data, 'in ' + (endTime - startTime) + ' ms');
                }

                return data;
            }, function (error) {
                if (constants.dev) {
                    $log.log('request failed' + error);
                }

                return [];
            });
        };

        service.getRankingsList = function (includeAll) {
            if (constants.dev) {
                var startTime = new Date().getTime(),
                    endTime;
            }
            includeAll = typeof includeAll === 'undefined' ? true : includeAll;
            return service.getRankingsModel().get().$promise.then(function (data) {
                var list = [];
                if (constants.dev) {
                    endTime = new Date().getTime();
                    $log.log('success, got data: ', data, 'in ' + (endTime - startTime) + ' ms');
                }

                if (data && data.results) {
                    angular.forEach(data.results, function (item) {
                        if (item.handle === 'stars' || (!includeAll  && item.handle === 'all')) {
                            return;
                        }
                        if (item.handle === 'all') {
                            item.handle = 'ovs';
                        }
                        list.push({
                            value : item.handle,
                            label : item.abbreviation && item.abbreviation.length ? item.abbreviation : item.name
                        });
                    });
                }
                return list;
            }, function (error) {
                if (constants.dev) {
                    $log.log('request failed' + error);
                }

                return false;
            });
        };

        return service;
    };

    angular
        .module('qsHub.rankings')
        .service('ProgressReportByRankingService', [
            '$resource',
            '$log',
            'constants',
            App.services.ProgressReportByRanking
        ]);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers:{}});
    App.controllers.ProgressReportByCountry = function (
        $scope,
        $location,
        $state,
        $timeout,
        gridNamespaces,
        uiGridConstants,
        uiGridExporterConstants,
        WatchService,
        GridService,
        TimeService,
        ProgressReportByCountryService,
        InstitutionsListService,
        uiGridExporterService,
        RankingsDashboardService,
        LocalStorageService,
        persistentDates,
        persistentProgressReports
    ) {
        var controller = this;
        controller.isDatagridReloading = false;
        controller.isRendered = false;
        controller.gridApi = null;
        controller.isgridRendering = false;
        controller.columnsVisibility = GridService.getColumnsVisibility(
            gridNamespaces.visibility.countryProgressReport
        );
        controller.selectedItem = {};
        controller.countryList = [];
        controller.persistentDate = LocalStorageService.getObject(
            persistentDates.progressReports
        );

        if (!$location.search().ranking) {
            if (LocalStorageService.getObject(persistentProgressReports.byCountryRanking)) {
                $location.search({'ranking': LocalStorageService.getObject(persistentProgressReports.byCountryRanking)});
            } else if(LocalStorageService.getObject(persistentProgressReports.byCountryRanking)) {
                $location.search({'ranking': LocalStorageService.getObject(persistentProgressReports.byInstitutionRanking)});
            }
        } else {
            LocalStorageService.storeObject(persistentProgressReports.byCountryRanking, $location.search().ranking);
        }

        controller.datePicker = {
            date: {
                startDate: angular.isDefined(controller.persistentDate) ? TimeService.getMomentObject(controller.persistentDate.startDate) : TimeService.startOfYear(),
                endDate: angular.isDefined(controller.persistentDate) ? TimeService.getMomentObject(controller.persistentDate.endDate, true) : TimeService.getEndOfToday()
            },
            options: {
                ranges: {
                    'in last 6 months': [TimeService.sub(6, 'months'), TimeService.now()],
                    'in last 12 months': [TimeService.sub(12, 'months'), TimeService.now()],
                    'this year': [TimeService.startOfYear(), TimeService.getEndOfToday()]
                },
                eventHandlers: {
                    'apply.daterangepicker': function (ev) {
                        controller.handleDateRangePickerApplyClick(ev);
                    }
                },
                opens: 'center',
                alwaysShowCalendars: true
            }
        };
        controller.dateFilter = {
            startDate: TimeService.getInUnix(controller.datePicker.date.startDate),
            endDate: TimeService.getInUnix(controller.datePicker.date.endDate)
        };
        controller.paginationOptions = GridService.getColumnsFilters(
            gridNamespaces.filters.countryProgressReport,
            []
        );

        controller.handleDateRangePickerApplyClick = function (element) {
            if (!element.model.startDate) {
                element.model.startDate = TimeService.now();
            }
            if (!element.model.endDate) {
                element.model.endDate = TimeService.now();
            }

            controller.dateFilter.startDate = TimeService.getInUnix(element.model.startDate);
            controller.dateFilter.endDate = TimeService.getInUnix(element.model.endDate);
            LocalStorageService.storeObject(persistentDates.progressReports, {
                startDate : controller.dateFilter.startDate,
                endDate : controller.dateFilter.endDate
            });
            controller.getPage();
        };

        controller.handleDatagridRowClick = function (row) {
            return $location.path(
                $state.href('staff.iu.progress-reports.institution').replace('#', '')
            ).search({
                ranking: controller.selectedItem.ranking.handle,
                country: row._id
            });
        };

        controller.getPage = function () {
            controller.isDatagridReloading = true;
            controller.isgridRendering = true;
            controller.selectedRanking = [];
            controller.selectedRanking['filter[ranking]'] = controller.selectedItem.ranking.handle;
            return ProgressReportByCountryService.getData(angular.merge(controller.dateFilter, controller.selectedRanking)).then(function (response) {
                $scope.gridOptions.columnDefs[0].filter.selectOptions = [];
                angular.forEach(response, function (country) {
                    $scope.gridOptions.columnDefs[0].filter.selectOptions.push({'label': country.countryName, 'value': country._id});
                });
                $scope.gridOptions.totalItems = response.length;
                $scope.gridOptions.data = response;
                $scope.gridOptions.minRowsToShow = response.length;
                controller.isDatagridReloading = false;
                controller.showDatagrid = true;
                $timeout(function(){
                    controller.isgridRendering = false;
                },300);
            });
        };

        controller.exportVisible = function () {
            var visibleRows = controller.gridApi.core.getVisibleRows(controller.gridApi.grid), results = [];
            visibleRows.forEach(function(visibleRow) {
                results.push(visibleRow.entity);
            });
            if (angular.equals([], results)) {
                return false;
            }
            var gridApi = controller.gridApi,
                gridOptions = $scope.gridOptions,
                exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                    gridApi.grid,
                    uiGridExporterConstants.VISIBLE
                ),
                exportData = RankingsDashboardService.getCsvData(
                    gridApi.grid.columns,
                    results
                ),
                csvContent = uiGridExporterService.formatAsCsv(
                    exportColumnHeaders,
                    exportData,
                    gridOptions.exporterCsvColumnSeparator
                );

            uiGridExporterService.downloadFile(
                'Ranking-report-by-country-' + controller.selectedItem.ranking.handle + '-visible-' + TimeService.now() + '.csv',
                csvContent,
                gridOptions.exporterOlderExcelCompatibility
            );
        };

        controller.exportAll = function () {
            var results = $scope.gridOptions.data;
            if (typeof results === 'undefined') {
                return false;
            }
            var gridApi = controller.gridApi,
                gridOptions = $scope.gridOptions,
                exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                    gridApi.grid,
                    uiGridExporterConstants.VISIBLE
                ),
                exportData = RankingsDashboardService.getCsvData(
                    gridApi.grid.columns,
                    results
                ),
                csvContent = uiGridExporterService.formatAsCsv(
                    exportColumnHeaders,
                    exportData,
                    gridOptions.exporterCsvColumnSeparator
                );

            uiGridExporterService.downloadFile(
                'Ranking-report-by-country-' + controller.selectedItem.ranking.handle + '-all-' + TimeService.now() + '.csv',
                csvContent,
                gridOptions.exporterOlderExcelCompatibility
            );
        };

        controller.initDataGrid = function () {
            var multiSelectTemplate = '/scripts/shared/ui-grid/templates/multiSelectFilterHeaderTemplate.html',
                percentageCellTemplate = '/scripts/shared/ui-grid/templates/percentageCellTemplate.html';
            $scope.gridOptions = {
                appScopeProvider: controller,
                enableSorting: true,
                exporterMenuCsv: false,
                exporterMenuAllData: false,
                exporterMenuPdf: false,
                useExternalFiltering: false,
                enableGridMenu: true,
                showGridFooter: true,
                enableFiltering: true,
                enableColumnResize: true,
                enableFullRowSelection: true,
                enableRowSelection: true,
                multiSelect: false,
                enableRowHeaderSelection: false,
                useExternalPagination: false,
                useExternalSorting: false,
                gridMenuCustomItems: [
                    {
                        title: ' Export visible data as csv',
                        action: controller.exportVisible,
                        order: 209
                    },
                    {
                        title: ' Export all data as csv',
                        action: controller.exportAll,
                        order: 210
                    }
                ],
                rowTemplate: '/scripts/shared/ui-grid/templates/rowTemplate.html',
                columnDefs: [
                    {
                        displayName: 'Country',
                        field: 'countryName',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'countryName'),
                        filter: {
                            column: 'countryName',
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: controller.countryList,
                            searchEnabled: true,
                            term: RankingsDashboardService.getMultiSelectTerm(
                                GridService.getFilterByField(controller.paginationOptions, 'countryName', [], true)
                            ),
                            condition: function(searchTerms, cellValue) {
                                if (angular.isDefined(searchTerms) && searchTerms.length > 0) {
                                    var searchTermsArray = [];
                                    angular.forEach(searchTerms, function (searchTerm) {
                                        searchTermsArray.push(searchTerm.label);
                                    });
                                    return searchTermsArray.indexOf(cellValue) !== -1;
                                } else {
                                    return true;
                                }
                            }
                        },
                        filterHeaderTemplate: multiSelectTemplate
                    },
                    {
                        displayName: 'Institutions',
                        field: 'institutionCount',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'institutionCount'),
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'institutionCount', '')
                        }
                    },
                    {
                        displayName: 'Total number of entries',
                        field: 'total',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'total'),
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'total', '')
                        }
                    },
                    {
                        displayName: 'Empty (%)',
                        field: 'empty',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'empty'),
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'empty', '')
                        },
                        cellTemplate: percentageCellTemplate
                    },
                    {
                        displayName: 'Pending (%)',
                        field: 'pending',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'pending'),
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'pending', '')
                        },
                        cellTemplate: percentageCellTemplate
                    },
                    {
                        displayName: 'Queried (%)',
                        field: 'queried',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'queried'),
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'queried', '')
                        },
                        cellTemplate: percentageCellTemplate
                    },
                    {
                        displayName: 'Accepted (%)',
                        field: 'accepted',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'accepted'),
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'accepted', '')
                        },
                        cellTemplate: percentageCellTemplate
                    },
                    {
                        displayName: 'Rejected (%)',
                        field: 'rejected',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'rejected'),
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'rejected', '')
                        },
                        cellTemplate: percentageCellTemplate
                    }
                ],
                onRegisterApi: function (gridApi) {
                    controller.gridApi = gridApi;

                    gridApi.selection.on.rowSelectionChanged($scope, function (row) {
                        controller.handleDatagridRowClick(row.entity);
                    });

                    gridApi.core.on.rowsRendered($scope, function () {
                        controller.isRendered = true;
                        controller.handleGridFiltersChanges();
                    });

                    gridApi.core.on.sortChanged($scope, controller.handleGridFiltersChanges);
                    gridApi.core.on.filterChanged($scope, controller.handleGridFiltersChanges);
                }
            };
        };

        controller.loadSubscriptions = function () {
            InstitutionsListService.getSubscriptions().then(function (response) {
                if (!response || !response.length) {
                    return false;
                }
                controller.rankingsList = [];
                var selectedRanking = $location.search().ranking;
                angular.forEach(response, function (item) {
                    if (item.handle !== 'stars' && item.handle !== 'all') {
                        if (typeof selectedRanking === 'undefined') {
                            if (angular.equals({}, controller.selectedItem)) {
                                controller.selectedItem.ranking = item;
                                $location.search({'institution': controller.selectedItem.ranking.handle});
                            }
                        } else {
                            if (angular.equals(item.handle, selectedRanking)) {
                                controller.selectedItem.ranking = item;
                            }
                        }
                        controller.rankingsList.push(item);
                    }
                });
                controller.initDataGrid();
                controller.getPage();
            });
        };

        controller.selectedRankingChanged = function () {
            controller.gridApi.grid.clearAllFilters();
            LocalStorageService.storeObject(persistentProgressReports.byCountryRanking, controller.selectedItem.ranking.handle);
            $location.search({'ranking': controller.selectedItem.ranking.handle});
            controller.getPage();
        };

        controller.initWatches = function () {
            WatchService.create($scope, 'gridOptions.columnDefs', function (newValue, oldValue) {
                if (newValue !== oldValue) {
                    controller.columnsVisibility = GridService.getGridColumnsVisibility(newValue);
                    var reload = false;
                    GridService.storeColumnsVisibility(
                        gridNamespaces.visibility.countryProgressReport,
                        controller.columnsVisibility
                    );
                    // Clear filter when column is hidden
                    if (angular.isDefined(newValue) &&
                        angular.isDefined(oldValue)
                    ) {
                        for (var i = 0; i < newValue.length; i++) {
                            if (newValue[i].visible === false &&
                                (oldValue[i].visible === true || typeof oldValue[i].visible === 'undefined') &&
                                angular.isDefined($scope.gridOptions.columnDefs[i].filter)
                            ) {
                                if ($scope.gridOptions.columnDefs[i].filter.hasOwnProperty('term') &&
                                    $scope.gridOptions.columnDefs[i].filter.term) {
                                    controller.paginationOptions['filter[' + $scope.gridOptions.columnDefs[i].field + ']'] = null;
                                    reload = true;
                                }
                                $scope.gridOptions.columnDefs[i].filter.term = null;
                            }
                        }
                        if (reload) {
                            controller.getPage();
                        }
                    }
                }
            }, true);
        };

        controller.handleGridFiltersChanges = function () {
            angular.forEach(controller.gridApi.grid.columns, function (column) {
                if (typeof column.filters !== 'undefined' && column.filters[0].term !== 'undefined') {
                    if (column.field === 'countryName') {
                        var arrayFilter = [];
                        angular.forEach(column.filters[0].term, function (term) {
                            arrayFilter.push(term.label);
                        });
                        controller.paginationOptions['filter[' + column.field + '][]'] = arrayFilter;
                    }
                }
                if (column.filters[0].term !== null && typeof column.filters[0].term !== 'undefined') {
                    GridService.applyFilters(column);
                }
                var filterColumn = column.filters[0].column ? column.filters[0].column : column.field;
                if (typeof column.filters !== 'undefined' && column.filters[0].term !== 'undefined') {
                    controller.paginationOptions['filter[' + filterColumn + ']'] = column.filters[0].term;
                }
            });

            GridService.storeColumnsFilters(
                gridNamespaces.filters.countryProgressReport,
                controller.paginationOptions
            );
        };

        controller.init = function () {
            controller.loadSubscriptions();
            controller.initWatches();
        };

        controller.init();
    };

    angular
        .module('qsHub.rankings')
        .controller('Rankings.ProgressReportByCountryController', [
            '$scope',
            '$location',
            '$state',
            '$timeout',
            'gridNamespaces',
            'uiGridConstants',
            'uiGridExporterConstants',
            'WatchService',
            'UiGridService',
            'TimeService',
            'ProgressReportByCountryService',
            'InstitutionsListService',
            'uiGridExporterService',
            'RankingsDashboardService',
            'LocalStorageService',
            'persistentDates',
            'persistentProgressReports',
            App.controllers.ProgressReportByCountry
        ]);
}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    App.services.ProgressReportByCountry = function (
        $resource,
        $log,
        constants
    ) {
        var service = {};

        /**
         * Get answers list API endpoint.
         *
         * @returns {$resource}
         */
        service.getModel = function () {
            return $resource(constants.api.rankings.url + '/v1/progress-report/country', {}, {
                get: {
                    isArray: true,
                    cancellable : true
                }
            });
        };

        service.getInstitutionsModel = function () {
            return $resource(constants.api.institutions.url + '/v1/list/subscriptions?filter[type]=statistics', {}, {
                get: {
                    isArray: false,
                    cancellable : true
                }
            });
        };

        service.getData = function (datFilter) {
            if (constants.dev) {
                var startTime = new Date().getTime(), endTime;
            }

            return service.getModel().get(datFilter).$promise.then(function (data) {
                if (constants.dev) {
                    endTime = new Date().getTime();
                    $log.log('success, got data: ', data, 'in ' + (endTime - startTime) + ' ms');
                }

                return data;
            }, function (error) {
                if (constants.dev) {
                    $log.log('request failed' + error);
                }

                return [];
            });
        };

        service.getRankingsList = function () {
            if (constants.dev) {
                var startTime = new Date().getTime(),
                    endTime;
            }
            return service.getInstitutionsModel().get().$promise.then(function (data) {
                var list = [];
                if (constants.dev) {
                    endTime = new Date().getTime();
                    $log.log('success, got data: ', data, 'in ' + (endTime - startTime) + ' ms');
                }

                if (data && data.results) {
                    angular.forEach(data.results, function (item) {
                        if (item.handle !== 'all' && item.handle !== 'stars') {
                            list.push({
                                value : item.handle,
                                label : item.abbreviation
                            });
                        }
                    });
                }
                return list;
            }, function (error) {
                if (constants.dev) {
                    $log.log('request failed' + error);
                }

                return false;
            });
        };

        return service;
    };

    angular
        .module('qsHub.rankings')
        .service('ProgressReportByCountryService', [
            '$resource',
            '$log',
            'constants',
            App.services.ProgressReportByCountry
        ]);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers:{}});
    App.controllers.ProgressReportByInstitution = function (
        $scope,
        $location,
        $state,
        $timeout,
        gridNamespaces,
        uiGridConstants,
        uiGridExporterConstants,
        uiGridExporterService,
        WatchService,
        GridService,
        TimeService,
        ProgressReportByRankingService,
        ProgressReportByInstitutionService,
        InstitutionsListService,
        LocalStorageService,
        persistentDates,
        DateRangeFixerService,
        persistentProgressReports,
        RankingsDashboardFactory
    ) {
        var controller = this;
        controller.isDatagridReloading = false;
        controller.isRendered = true;
        controller.isgridRendering = false;
        controller.selectCellTemplate = '/scripts/shared/ui-grid/templates/selectCellTemplate.html';
        controller.selectTemplate = '/scripts/shared/ui-grid/templates/selectFilterHeaderTemplate.html';

        controller.columnsVisibility = GridService.getColumnsVisibility(
            gridNamespaces.visibility.institutionProgressReport
        );

        controller.persistentDate = LocalStorageService.getObject(
            persistentDates.progressReports
        );

        controller.checkPersistent = function() {
            if (!$location.search().ranking) {
                if (LocalStorageService.getObject(persistentProgressReports.byInstitutionRanking)) {
                    if (!$location.search().country) {
                        var tmpCountryFilter = LocalStorageService.getObject(persistentProgressReports.byInstitutionCountry);
                       
                        if (tmpCountryFilter && typeof tmpCountryFilter === 'string') {
                            $location.search({'ranking': LocalStorageService.getObject(persistentProgressReports.byInstitutionRanking), 'country': tmpCountryFilter});
                        } else {
                            controller.countryFilter = tmpCountryFilter;
                            $location.search({'ranking': LocalStorageService.getObject(persistentProgressReports.byInstitutionRanking)});
                        }
                    } else {
                        LocalStorageService.storeObject(persistentProgressReports.byInstitutionCountry, $location.search().country);
                    }
                } else if (LocalStorageService.getObject(persistentProgressReports.byCountryRanking)) {
                    $location.search({'ranking': LocalStorageService.getObject(persistentProgressReports.byCountryRanking)});
                }
            } else if (!$location.search().country) {
                controller.countryFilter = LocalStorageService.getObject(persistentProgressReports.byInstitutionCountry);
            }
             else {
                LocalStorageService.storeObject(persistentProgressReports.byInstitutionRanking, $location.search().ranking);
                if ($location.search().country) {
                    LocalStorageService.storeObject(persistentProgressReports.byInstitutionCountry, $location.search().country);
                }
            }
        };

        controller.datePicker = {
            date: {
                startDate: angular.isDefined(controller.persistentDate) ? TimeService.getMomentObject(controller.persistentDate.startDate) : TimeService.startOfYear(),
                endDate: angular.isDefined(controller.persistentDate) ? TimeService.getMomentObject(controller.persistentDate.endDate, true) : TimeService.getEndOfToday()
            },
            options: {
                ranges: {
                    'in last 6 months': [TimeService.sub(6, 'months'), TimeService.now()],
                    'in last 12 months': [TimeService.sub(12, 'months'), TimeService.now()],
                    'this year': [TimeService.startOfYear(), TimeService.getEndOfToday()]
                },
                eventHandlers: {
                    'apply.daterangepicker': function (ev) {
                        controller.handleDateRangePickerApplyClick(ev);
                    }
                },
                opens: 'center',
                alwaysShowCalendars: true
            }
        };
        controller.dateFilter = {
            startDate: TimeService.getInUnix(controller.datePicker.date.startDate),
            endDate: TimeService.getInUnix(controller.datePicker.date.endDate)
        };
        controller.countryList = [];
        controller.rankingsList = [];
        controller.rankingFilter = null;
        controller.countryFilter = [];
        controller.defaultPaginationOptions = {
            page: 1,
            limit: 250000
        };
        controller.lastCreatedAt = 'lastCreatedAt';
        controller.paginationOptions = GridService.getColumnsFilters(
            gridNamespaces.filters.institutionProgressReport
            [controller.lastCreatedAt]
        );
        controller.gridOptions = {
            data: []
        };
        controller.filters = {
            lastCreatedAt: {
                startDate: null,
                endDate: null
            }
        };
        controller.defaultFilters = {
            lastCreatedAt: {
                startDate: null,
                endDate: null
            }
        };
        var lastCreatedAtDateFilter = null;

        controller.isDatagridVisible = function () {
            return !controller.isDatagridReloading &&
                controller.gridOptions &&
                controller.isRendered &&
                controller.dateFilter.startDate &&
                controller.dateFilter.endDate &&
                controller.rankingFilter;
        };

        controller.isLoading = function () {
            return !controller.rankingsList.length || !controller.countryList.length;
        };

        controller.handleDatagridRowClick = function (row) {
            return $location.path(
                $state.href('clients.statistics.data-submission.' + controller.rankingFilter.value).replace('#', '')
            ).search({coreId: row.coreId});
        };

        controller.handleDateRangePickerApplyClick = function (element) {
            if (!element.model.startDate) {
                element.model.startDate = TimeService.now();
            }
            if (!element.model.endDate) {
                element.model.endDate = TimeService.now();
            }

            controller.dateFilter.startDate = TimeService.getInUnix(element.model.startDate);
            controller.dateFilter.endDate = TimeService.getInUnix(element.model.endDate);
            LocalStorageService.storeObject(persistentDates.progressReports, {
                startDate : controller.dateFilter.startDate,
                endDate : controller.dateFilter.endDate
            });
        };

        controller.addCommonColumns = function (columnDefs, addInternational) {
            addInternational = (typeof addInternational === 'undefined') ? true : addInternational;
            columnDefs.push({
                displayName: 'Faculty staff',
                field: 'faculty_staff',
                visible: GridService.getVisibilityByField(controller.columnsVisibility, 'faculty_staff'),
                filter: {
                    column: 'faculty_staff',
                    type: uiGridConstants.filter.SELECT,
                    selectOptions: [
                        {value: 1, label: '1'},
                        {value: 0, label: '0'}
                    ],
                    term: GridService.getFilterByField(controller.paginationOptions, 'faculty_staff')
                },
                filterHeaderTemplate:  controller.selectTemplate,
                cellTemplate:  controller.selectCellTemplate
            });
            if (addInternational) {
                columnDefs.push({
                    displayName: 'International Faculty staff',
                    field: 'international_faculty_staff',
                    enableFiltering: true,
                    visible: GridService.getVisibilityByField(controller.columnsVisibility, 'international_faculty_staff'),
                    filter: {
                        column: 'international_faculty_staff',
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: [
                            {value: 1, label: '1'},
                            {value: 0, label: '0'}
                        ],
                        term: GridService.getFilterByField(controller.paginationOptions, 'international_faculty_staff')
                    },
                    filterHeaderTemplate:  controller.selectTemplate,
                    cellTemplate:  controller.selectCellTemplate
                });
            }
            columnDefs.push({
                displayName: 'Student',
                field: 'student',
                enableFiltering: true,
                visible: GridService.getVisibilityByField(controller.columnsVisibility, 'student'),
                filter: {
                    column: 'student',
                    type: uiGridConstants.filter.SELECT,
                    selectOptions: [
                        {value: 1, label: '1'},
                        {value: 0, label: '0'}
                    ],
                    term: GridService.getFilterByField(controller.paginationOptions, 'student')
                },
                filterHeaderTemplate:  controller.selectTemplate,
                cellTemplate:  controller.selectCellTemplate
            });
            if (addInternational) {
                columnDefs.push({
                    displayName: 'International student',
                    field: 'international_student',
                    enableFiltering: true,
                    visible: GridService.getVisibilityByField(controller.columnsVisibility, 'international_student'),
                    filter: {
                        column: 'international_student',
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: [
                            {value: 1, label: '1'},
                            {value: 0, label: '0'}
                        ],
                        term: GridService.getFilterByField(controller.paginationOptions, 'international_student')
                    },
                    filterHeaderTemplate:  controller.selectTemplate,
                    cellTemplate:  controller.selectCellTemplate
                });
            }
        };

        controller.addGerColumns = function (columnDefs) {
            columnDefs.push({
                displayName: "Employer Connections",
                field: 'employers_connection',
                enableFiltering: true,
                visible: GridService.getVisibilityByField(controller.columnsVisibility, 'employers_connection'),
                filter: {
                    column: 'employers_connection',
                    type: uiGridConstants.filter.SELECT,
                    selectOptions: [
                        {value: 1, label: '1'},
                        {value: 0, label: '0'}
                    ],
                    term: GridService.getFilterByField(controller.paginationOptions, 'employers_connection')
                },
                filterHeaderTemplate:  controller.selectTemplate,
                cellTemplate:  controller.selectCellTemplate
            });
            columnDefs.push({
                displayName: 'Employer Partnerships',
                field: 'employer_partnerships',
                enableFiltering: true,
                visible: GridService.getVisibilityByField(controller.columnsVisibility, 'employer_partnerships'),
                filter: {
                    column: 'employer_partnerships',
                    type: uiGridConstants.filter.SELECT,
                    selectOptions: [
                        {value: 1, label: '1'},
                        {value: 0, label: '0'}
                    ],
                    term: GridService.getFilterByField(controller.paginationOptions, 'employer_partnerships')
                },
                filterHeaderTemplate:  controller.selectTemplate,
                cellTemplate:  controller.selectCellTemplate
            });
            columnDefs.push({
                displayName: 'Employment Statistics',
                field: 'employment_rate',
                enableFiltering: true,
                visible: GridService.getVisibilityByField(controller.columnsVisibility, 'employment_rate'),
                filter: {
                    column: 'employment_rate',
                    type: uiGridConstants.filter.SELECT,
                    selectOptions: [
                        {value: 1, label: '1'},
                        {value: 0, label: '0'}
                    ],
                    term: GridService.getFilterByField(controller.paginationOptions, 'employment_rate')
                },
                filterHeaderTemplate:  controller.selectTemplate,
                cellTemplate:  controller.selectCellTemplate
            });
        };

        controller.addPhdStaffColumn = function (columnDefs) {
            columnDefs.push({
                displayName: 'Staff with PhD',
                field: 'staff_with_phd',
                enableFiltering: true,
                visible: GridService.getVisibilityByField(controller.columnsVisibility, 'staff_with_phd'),
                filter: {
                    column: 'staff_with_phd',
                    type: uiGridConstants.filter.SELECT,
                    selectOptions: [
                        {value: 1, label: '1'},
                        {value: 0, label: '0'}
                    ],
                    term: GridService.getFilterByField(controller.paginationOptions, 'staff_with_phd')
                },
                filterHeaderTemplate:  controller.selectTemplate,
                cellTemplate:  controller.selectCellTemplate
            });
        };

        controller.addExchangeStudentsColumns = function (columnDefs) {
            columnDefs.push({
                displayName: 'Inbound exchange students',
                field: 'inbound_exchange_students',
                enableFiltering: true,
                visible: GridService.getVisibilityByField(controller.columnsVisibility, 'inbound_exchange_students'),
                filter: {
                    column: 'inbound_exchange_students',
                    type: uiGridConstants.filter.SELECT,
                    selectOptions: [
                        {value: 1, label: '1'},
                        {value: 0, label: '0'}
                    ],
                    term: GridService.getFilterByField(controller.paginationOptions, 'inbound_exchange_students')
                },
                filterHeaderTemplate:  controller.selectTemplate,
                cellTemplate:  controller.selectCellTemplate
            });
            columnDefs.push({
                displayName: 'Outbound exchange students',
                field: 'outbound_exchange_students',
                enableFiltering: true,
                visible: GridService.getVisibilityByField(controller.columnsVisibility, 'outbound_exchange_students'),
                filter: {
                    column: 'outbound_exchange_students',
                    type: uiGridConstants.filter.SELECT,
                    selectOptions: [
                        {value: 1, label: '1'},
                        {value: 0, label: '0'}
                    ],
                    term: GridService.getFilterByField(controller.paginationOptions, 'outbound_exchange_students')
                },
                filterHeaderTemplate:  controller.selectTemplate,
                cellTemplate:  controller.selectCellTemplate
            });
        };

        controller.formatZeroValues = function (data) {
            for (var i = 0; i < data.length; i++) {
                for(var key in data[i]) {
                    if(data[i].hasOwnProperty(key) && data[i][key] === 0) {
                        data[i][key] = '0';
                    }
                }
            }

            return data;
        };

        controller.exportCsv = function (visible) {
            var filteredRows = [];
            if (visible) {
                angular.forEach(controller.gridApi.core.getVisibleRows(controller.gridApi.grid), function (column) {
                    filteredRows.push(column.entity);
                });
            }
            var results = visible ? filteredRows : controller.gridOptions.data;
            results = ProgressReportByInstitutionService.formatCsvResults(results, TimeService);
            results = controller.formatZeroValues(results);
            if (typeof results === 'undefined') {
                return false;
            }
            var gridApi = controller.gridApi,
                gridOptions = controller.gridOptions,
                exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                    gridApi.grid,
                    uiGridExporterConstants.VISIBLE
                ),
                exportData = GridService.getCsvData(
                    gridApi.grid.columns,
                    results
                ),
                csvContent = uiGridExporterService.formatAsCsv(
                    exportColumnHeaders,
                    exportData,
                    gridOptions.exporterCsvColumnSeparator
                );

            uiGridExporterService.downloadFile(
                'progress-report-by-ranking-' + TimeService.now() + '.csv',
                csvContent,
                gridOptions.exporterOlderExcelCompatibility
            );
        };

        controller.initDatagrid = function () {
            var percentageCellTemplate = '/scripts/shared/ui-grid/templates/percentageCellTemplate.html',
                dateCellTemplate = '/scripts/shared/ui-grid/templates/dateCellTemplateLastCreatedAt.html',
                lastCreatedAtTemplate = '/scripts/modules/rankings/components/progressReports/byInstitution/datagrid/lastCreatedAtFilterHeaderTemplate.html',
                columnDefs = [
                    {
                        displayName: 'Institution Name',
                        field: 'name',
                        width: 300,
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'name'),
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'name', '')
                        }
                    },
                    {
                        displayName: 'Core ID',
                        field: 'coreId',
                        width: 100,
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'coreId'),
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'coreId', '')
                        }
                    },
                    {
                        displayName: 'Country',
                        field: 'countryCode',
                        visible: false,
                        enableFiltering: false,
                        filter: {
                            column: 'countryCode',
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: controller.countryList,
                            searchEnabled: true
                        },
                        cellTemplate: controller.selectCellTemplate
                    },
                    {
                        displayName: 'Total number of entries',
                        field: 'total',
                        width: 100,
                        enableFiltering: true,
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'total'),
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'total', '')
                        }
                    },
                    {
                        displayName: 'Empty',
                        field: 'empty',
                        width: 100,
                        enableFiltering: true,
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'empty'),
                        cellTemplate: percentageCellTemplate,
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'empty', '')
                        }
                    },
                    {
                        displayName: 'Pending',
                        field: 'pending',
                        width: 100,
                        enableFiltering: true,
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'pending'),
                        cellTemplate: percentageCellTemplate,
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'pending', '')
                        }
                    },
                    {
                        displayName: 'Queried',
                        field: 'queried',
                        width: 100,
                        enableFiltering: true,
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'queried'),
                        cellTemplate: percentageCellTemplate,
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'queried', '')
                        }
                    },
                    {
                        displayName: 'Accepted',
                        field: 'accepted',
                        width: 100,
                        enableFiltering: true,
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'accepted'),
                        cellTemplate: percentageCellTemplate,
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'accepted', '')
                        }
                    },
                    {
                        displayName: 'Rejected',
                        field: 'rejected',
                        width: 100,
                        enableFiltering: true,
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'rejected'),
                        cellTemplate: percentageCellTemplate,
                        filter: {
                            term: GridService.getFilterByField(controller.paginationOptions, 'rejected', '')
                        }
                    }
                ];
            switch (controller.rankingFilter.value) {
                case 'wur':
                    controller.addCommonColumns(columnDefs);
                    break;
                case 'aw':
                case 'brics':
                case 'eeca':
                    controller.addCommonColumns(columnDefs);
                    controller.addPhdStaffColumn(columnDefs);
                    break;
                case 'aur':
                    controller.addCommonColumns(columnDefs);
                    controller.addPhdStaffColumn(columnDefs);
                    controller.addExchangeStudentsColumns(columnDefs);
                    break;
                case 'lat':
                    controller.addCommonColumns(columnDefs, false);
                    controller.addPhdStaffColumn(columnDefs);
                    break;
                case 'ger':
                    controller.addGerColumns(columnDefs);
                    break;
            }

            columnDefs.push({
                displayName: 'Last Submission',
                    type: 'date',
                    field: controller.lastCreatedAt,
                    filterCellFiltered: false,
                    enableFiltering: false,
                    filters: [
                    {
                        visible: true,
                        condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL
                    },
                    {
                        condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL
                    }
                ],
                    filter : {
                    term: GridService.getFilterByField(controller.paginationOptions, controller.lastCreatedAt, ''),
                        applyTextFilter: controller.applyDateFilter(controller.lastCreatedAt, '')
                },
                filterHeaderTemplate: lastCreatedAtTemplate,
                    visible: GridService.getVisibilityByField(controller.columnsVisibility, controller.lastCreatedAt),
                    cellTemplate: dateCellTemplate
            });
            columnDefs.push({
                displayName: 'Submitted By',
                    field: 'lastCreatedByFullName',
                enableFiltering: true,
                visible: GridService.getVisibilityByField(controller.columnsVisibility, 'lastCreatedByFullName'),
                filter: {
                    term: GridService.getFilterByField(controller.paginationOptions, 'lastCreatedByFullName', '')
                }

            });

            controller.gridOptions = angular.extend({}, controller.gridOptions, {
                appScopeProvider: controller,
                enableSorting: true,
                exporterMenuCsv: false,
                exporterMenuAllData: false,
                exporterMenuPdf: false,
                useExternalFiltering: false,
                enableGridMenu: true,
                showGridFooter: true,
                enableFiltering: true,
                enableColumnResize: true,
                enableFullRowSelection: true,
                enableRowSelection: true,
                multiSelect: false,
                enableRowHeaderSelection: false,
                useExternalPagination: false,
                useExternalSorting: false,
                rowTemplate: '/scripts/shared/ui-grid/templates/rowTemplate.html',
                gridMenuCustomItems: [
                    {
                        title: ' Export visible data as csv',
                        action: function () {
                            controller.exportCsv(true);
                        },
                        order: 209
                    },
                    {
                        title: ' Export all data as csv',
                        action: function () {
                            controller.exportCsv(false);
                        },
                        order: 210
                    }
                ],
                columnDefs: columnDefs,
                onRegisterApi: function (gridApi) {
                    controller.gridApi = gridApi;

                    gridApi.selection.on.rowSelectionChanged($scope, function (row) {
                        controller.handleDatagridRowClick(row.entity);
                    });

                    gridApi.core.on.rowsRendered($scope, function () {
                        controller.isRendered = true;
                        controller.handleGridFiltersChanges();
                    });

                    gridApi.core.on.sortChanged($scope, controller.handleGridFiltersChanges);
                    gridApi.core.on.filterChanged($scope, controller.handleGridFiltersChanges);
                }
            });

            var createdAt = GridService.getFilterByField(controller.paginationOptions, controller.lastCreatedAt, '');
            if (createdAt !== null && controller.paginationOptions["filter[lastCreatedAt]"] !== null) {
                if (angular.isDefined(createdAt) &&
                    angular.isDefined(controller.paginationOptions["filter[lastCreatedAt]"]) &&
                    angular.isDefined(controller.paginationOptions["filter[lastCreatedAt]"].startDate) &&
                    angular.isDefined(controller.paginationOptions["filter[lastCreatedAt]"].endDate)
                ) {
                    controller.gridOptions.columnDefs[controller.getColumnNumber(controller.lastCreatedAt)].filters[0].term = controller.paginationOptions["filter[lastCreatedAt]"].startDate;
                    controller.gridOptions.columnDefs[controller.getColumnNumber(controller.lastCreatedAt)].filters[1].term = controller.paginationOptions["filter[lastCreatedAt]"].endDate;
                }
            }
            controller.filters = angular.extend({}, controller.defaultFilters);
        };

        /**
         * Populates input text field for date field
         *
         * @param {string} filterName
         * @param {mixed} defaultValue
         */
        controller.applyDateFilter = function (filterName, defaultValue) {
            var filter = GridService.getFilterByField(controller.paginationOptions, filterName, defaultValue);
            if (angular.isDefined(filter) &&
                filter !== null
            ) {
                if (angular.isDefined(filter.startDate) &&
                    angular.isDefined(filter.endDate)
                ) {
                    if (filter.startDate !== null &&
                        filter.startDate !== null
                    ) {
                        controller.defaultFilters[filterName] = {
                            startDate: parseInt(filter.startDate, 10),
                            endDate: parseInt(filter.endDate, 10)
                        };
                    } else {
                        controller.defaultFilters[filterName] = {
                            startDate: null,
                            endDate: null
                        };
                    }
                }
            }
        };

        controller.handleGridFiltersChanges = function () {
            angular.forEach(controller.gridApi.grid.columns, function (column) {
                if (typeof column.filters !== 'undefined' && column.filters[0].term !== 'undefined') {
                    if (
                        column.filters[0].type === uiGridConstants.filter.SELECT &&
                        typeof column.filters[0].term === 'object' &&
                        column.filters[0].term !== null &&
                        typeof column.filters[0].term.value !== 'undefined'
                    ) {
                        column.filters[0].term = column.filters[0].term.value;
                    }
                }
            });
            angular.forEach(controller.gridApi.grid.columns, function (column) {
                if (column.filters[0].term !== null && typeof column.filters[0].term !== 'undefined') {
                    GridService.applyFilters(column);
                }
                var filterColumn = column.filters[0].column ? column.filters[0].column : column.field;
                if (filterColumn === controller.lastCreatedAt
                ) {
                    if (typeof column.filters !== 'undefined' &&
                        typeof column.filters[0].term !== 'undefined' &&
                        typeof column.filters[1].term !== 'undefined') {
                        controller.paginationOptions['filter[' + filterColumn + ']'] = {
                            startDate: column.filters[0].term && isNaN(column.filters[0].term) ?
                                column.filters[0].term.format('x') : column.filters[0].term,
                            endDate: column.filters[1].term && isNaN(column.filters[1].term) ?
                                column.filters[1].term.format('x') : column.filters[1].term
                        };
                    } else {
                        controller.paginationOptions['filter[' + filterColumn + ']'] = {};
                        controller.filters.lastCreatedAt = {};
                    }
                } else if (typeof column.filters !== 'undefined' && column.filters[0].term !== 'undefined') {
                    controller.paginationOptions['filter[' + filterColumn + ']'] = column.filters[0].term;
                }
            });

            if (controller.paginationOptions["filter[lastCreatedAt]"] === null || controller.paginationOptions["filter[lastCreatedAt]"] === undefined) {
                controller.paginationOptions["filter[lastCreatedAt]"] = controller.defaultFilters.lastCreatedAt;
            }
            if (controller.paginationOptions["filter[lastCreatedAt]"].startDate === null || !angular.isDefined(controller.paginationOptions["filter[lastCreatedAt]"].startDate)) {
                controller.paginationOptions["filter[lastCreatedAt]"].endDate = null;
                controller.filters.lastCreatedAt = {
                    startDate: null,
                    endDate: null
                };
            }
            GridService.storeColumnsFilters(
                gridNamespaces.filters.institutionProgressReport,
                controller.paginationOptions
            );
        };

        controller.getPage = function () {
            controller.isDatagridReloading = true;
            controller.isgridRendering = true;
            controller.paginationOptions = GridService.getColumnsFilters(
                gridNamespaces.filters.institutionProgressReport,
                [controller.lastCreatedAt]
            );
            controller.paginationOptions.ranking = controller.rankingFilter.value;
            controller.paginationOptions['country[]'] = [];
            if (controller.countryFilter && controller.countryFilter.length) {
                angular.forEach(controller.countryFilter, function(country){
                    controller.paginationOptions['country[]'].push(country.value);
                });
            }
            controller.paginationOptions.startDate = controller.dateFilter.startDate;
            controller.paginationOptions.endDate = controller.dateFilter.endDate;

            return ProgressReportByInstitutionService.getData(
                angular.merge({}, controller.paginationOptions, controller.defaultPaginationOptions)
            ).then(function (response) {
                controller.gridOptions.totalItems = response.totalMatching;
                controller.gridOptions.data = response.results;
                controller.gridOptions.minRowsToShow = response.totalFiltered;
                controller.isDatagridReloading = false;
                controller.initDatagrid();
                $timeout(function(){
                    controller.isgridRendering = false;
                },300);
            });
        };

        controller.handleCountryFilterChanges = function () {
            if (controller.countryFilter) {
                LocalStorageService.storeObject(persistentProgressReports.byInstitutionCountry, controller.countryFilter);
                $location.search('country', typeof controller.countryFilter !== 'string' && controller.countryFilter.length === 1 ? controller.countryFilter[0].value : null);
            }
        };

        controller.handleRankingFilterChanges = function () {
            if (controller.rankingFilter && controller.rankingFilter.value) {
                LocalStorageService.storeObject(persistentProgressReports.byInstitutionRanking, controller.rankingFilter.value);
                $location.search('ranking', controller.rankingFilter.value);
            }
        };

        controller.handleCreatedAtDateRange = function (event) {
            if (DateRangeFixerService.sameDayAndNoModel(event, controller.filters.lastCreatedAt, lastCreatedAtDateFilter)) {
                event.model.startDate = lastCreatedAtDateFilter.startDate;
                event.model.endDate = lastCreatedAtDateFilter.endDate;
                controller.filters.lastCreatedAt = {
                    startDate: lastCreatedAtDateFilter.startDate,
                    endDate: lastCreatedAtDateFilter.endDate
                };
            } else if (DateRangeFixerService.todayRangeAndNoModel(event, lastCreatedAtDateFilter)) {
                event.model.startDate = TimeService.getStartOfToday();
                event.model.endDate = TimeService.getEndOfToday();
                controller.filters.lastCreatedAt = {
                    startDate: event.model.startDate.toISOString(),
                    endDate: event.model.endDate.toISOString()
                };
            }

            if (event.model.startDate && event.model.endDate) {
                controller.gridOptions.columnDefs[controller.getColumnNumber(controller.lastCreatedAt)].filters[0].term = typeof event.model.startDate === 'object' ? event.model.startDate.format('x') : event.model.startDate;
                controller.gridOptions.columnDefs[controller.getColumnNumber(controller.lastCreatedAt)].filters[1].term = typeof event.model.endDate === 'object' ? event.model.endDate.format('x') : event.model.endDate;
            }

            lastCreatedAtDateFilter = angular.copy({
                startDate: event.model.startDate,
                endDate: event.model.endDate
            });
        };

        controller.getColumnNumber = function (columnName) {
            var key = 0,
                total = controller.gridOptions.columnDefs.length;
            for (key; key < total; key++) {
                if (controller.gridOptions.columnDefs[key] &&
                    controller.gridOptions.columnDefs[key].field === columnName
                ) {
                    return key;
                }
            }

            return null;
        };

        controller.filtersWatch = function (newValue, oldValue) {
            if (newValue) {
                if (oldValue) {
                    if (newValue.value && newValue.value === oldValue.value) {
                        return false;
                    } else if ((newValue.startDate && angular.equals(newValue, oldValue))) {
                        return false;
                    } else if (newValue === oldValue) {
                        return false;
                    }
                }
            } else {
                return false;
            }

            if (controller.dateFilter.startDate &&
                controller.dateFilter.endDate &&
                controller.rankingFilter) {
                controller.getPage();
            }
        };

        controller.initRankings = function () {
            ProgressReportByRankingService.getRankingsList().then(function (list) {
                angular.forEach(list, function (item) {
                    if (item.value !== 'all') {
                        controller.rankingsList.push(item);
                    }
                });
                var activeRanking = $location.search().ranking,
                    i = 0,
                    total = list.length;
                if (activeRanking) {
                    for (i; i< total; i++) {
                        if (list[i].value === activeRanking) {
                            controller.rankingFilter = list[i];
                            break;
                        }
                    }
                }
            });
        };

        controller.initCountries = function () {
            InstitutionsListService.getCountries().then(function (list) {
                var activeCountry = $location.search().country,
                    option;
                angular.forEach(list, function (item) {
                    option = {
                        value: item.countryCode,
                        label: item.name
                    };
                    controller.countryList.push(option);
                    if (activeCountry && option.value === activeCountry.toUpperCase()) {
                        controller.countryFilter.push(option);
                    }
                });
                RankingsDashboardFactory.setCountries(list);
            });
        };

        controller.visibilityWatch = function (newValue, oldValue) {
            if (controller.isRendered === true) {
                if (newValue !== oldValue) {
                    controller.columnsVisibility = GridService.getGridColumnsVisibility(newValue);
                    var reload = false;
                    GridService.storeColumnsVisibility(
                        gridNamespaces.visibility.institutionProgressReport,
                        controller.columnsVisibility
                    );
                    // Clear filter when column is hidden
                    if (angular.isDefined(newValue) &&
                        angular.isDefined(oldValue)
                    ) {
                        for (var i = 0; i < newValue.length; i++) {
                            if ((newValue[i] && newValue[i].hasOwnProperty('visible') && newValue[i].visible === false) &&
                                (oldValue[i] && oldValue[i].hasOwnProperty('visible') && 
                                (oldValue[i].visible === true || typeof oldValue[i].visible === 'undefined')) &&
                                angular.isDefined(controller.gridOptions.columnDefs[i].filter)
                            ) {
                                if (controller.gridOptions.columnDefs[i].filter.hasOwnProperty('term') &&
                                    (controller.gridOptions.columnDefs[i].filter.term ||  controller.gridOptions.columnDefs[i].filter.term === 0)) {
                                    controller.paginationOptions['filter[' + controller.gridOptions.columnDefs[i].field + ']'] = null;
                                    reload = true;
                                }
                                controller.gridOptions.columnDefs[i].filter.term = null;
                            }
                        }
                        if (reload) {
                            controller.getPage();
                        }
                    }
                }
            }
        };

        controller.initWatches = function () {
            WatchService.create($scope, 'ProgressReportByInstitutionController.countryFilter', controller.filtersWatch, true);
            WatchService.create($scope, 'ProgressReportByInstitutionController.rankingFilter', controller.filtersWatch, true);
            WatchService.create($scope, 'ProgressReportByInstitutionController.dateFilter', controller.filtersWatch, true);
            WatchService.create($scope, function () {
                return controller.gridOptions.columnDefs;
            }, controller.visibilityWatch, true);
        };

        controller.init = function () {
            controller.initWatches();
            controller.initRankings();
            controller.initCountries();
            controller.checkPersistent();
        };

        controller.init();
    };

    angular
        .module('qsHub.rankings')
        .controller('Rankings.ProgressReportByInstitutionController', [
            '$scope',
            '$location',
            '$state',
            '$timeout',
            'gridNamespaces',
            'uiGridConstants',
            'uiGridExporterConstants',
            'uiGridExporterService',
            'WatchService',
            'UiGridService',
            'TimeService',
            'ProgressReportByRankingService',
            'ProgressReportByInstitutionService',
            'InstitutionsListService',
            'LocalStorageService',
            'persistentDates',
            'DateRangeFixerService',
            'persistentProgressReports',
            'RankingsDashboardFactory',
            App.controllers.ProgressReportByInstitution
        ]);
}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    App.services.ProgressReportByInstitution = function (
        $resource,
        $log,
        constants,
        RankingsDashboardFactory
    ) {
        var service = {
            country : {}
        };

        /**
         * Get answers list API endpoint.
         *
         * @returns {$resource}
         */
        service.getModel = function () {
            return $resource(constants.api.rankings.url + '/v2/progress-report/institution', {}, {
                get: {
                    isArray: false,
                    cancellable : true
                }
            });
        };


        service.getData = function (filters) {
            if (constants.dev) {
                var startTime = new Date().getTime(), endTime;
            }

            return service.getModel().get(filters).$promise.then(function (data) {
                if (constants.dev) {
                    endTime = new Date().getTime();
                    $log.log('success, got data: ', data, 'in ' + (endTime - startTime) + ' ms');
                }

                return data;
            }, function (error) {
                if (constants.dev) {
                    $log.log('request failed' + error);
                }

                return [];
            });
        };

        /**
         * Format data on export.
         *
         * @param data
         * @param TimeService
         * @returns {*}
         */
        service.formatCsvResults = function (data, TimeService) {
            data = angular.copy(data);
            angular.forEach(data, function (column, dataKey) {
                angular.forEach(column, function (item, key) {
                    if(key === 'countryCode') {
                        if (!!service.country.countryCode && service.country.countryCode === data[dataKey][key]) {
                            data[dataKey][key] = service.country.countryName;
                        } else {
                            data[dataKey][key] = getCountryName(item);
                        }
                    } else if (key === 'lastCreatedAt' && !!item) {
                        data[dataKey][key] = TimeService.format(item, "MMM DD, YYYY HH:mm:ss");
                    }
                });
            });

            return data;
        };


        function getCountryName(institutionCountryCode) {
            var list = RankingsDashboardFactory.getCountries(),
                total = list.length,
                i = 0;
            for (i; i < total; i++) {
                 if (list[i].countryCode === institutionCountryCode) {
                    service.country.countryCode = institutionCountryCode;
                    service.country.countryName = list[i].name;
                    return list[i].name;
               }
            }
            return null;
        }

        return service;
    };

    angular
        .module('qsHub.rankings')
        .service('ProgressReportByInstitutionService', [
            '$resource',
            '$log',
            'constants',
            'RankingsDashboardFactory',
            App.services.ProgressReportByInstitution
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    angular
        .module('qsHub.rankings')
        .controller('Rankings.IdCheckerController', [
            'TextService',
            'IdCheckerService',
            IdChecker
        ]);

    function IdChecker(
        TextService,
        IdCheckerService
    ) {
        var controller = this,
            inProgress = false;
        controller.ids = null;
        controller.counter = null;
        controller.isDisabled = isDisabled;
        controller.handleClick = handleClick;
        controller.results = null;
        controller.tmPrograms = false;
        controller.textEditorOptions = {
            disableDragAndDrop: true,
            styleWithSpan: false,
            dialogsInBody: true,
            minHeight: 280,
            maxHeight: 280,
            toolbar: [],
            popover: {
                link: []
            }
        };

        function isDisabled () {
            return !!(inProgress || !controller.ids || !controller.ids.length);
        }

        function handleClick () {
            inProgress = true;
            controller.results = null;
            var ids = TextService.cleanHtmlTags(controller.ids).split(',');
            controller.counter = ids.length;
            IdCheckerService.check(ids, controller.tmPrograms).then(function (results) {
                controller.results = results;
                inProgress = false;
            });
        }
    }

}(window.angular));

(function(angular) {
    "use strict";

    angular
        .module('qsHub.rankings')
        .service('IdCheckerService', [
            '$resource',
            '$log',
            '$q',
            'constants',
            'RequestsQueueService',
            IdChecker
        ]);

    function IdChecker(
        $resource,
        $log,
        $q,
        constants,
        RequestsQueueService
    ) {
        var service = {};

        /**
         * Get answers list API endpoint.
         *
         * @returns {$resource}
         */
        service.getModel = function () {
            return $resource(constants.api.institutions.url + '/v1/admin/rankings-id-checker', null, {
                check:  {
                    method: 'POST',
                    cancellable : true
                }
            });
        };

        service.check = function (ids, tmPrograms) {
            var key = 'check';
            RequestsQueueService.cancelAll(key);

            var Api = service.getModel().check({ids: ids, tmPrograms: tmPrograms});
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return data;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return [];
            });
        };

        return service;
    }

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.rankings')
        .controller('Rankings.ClassificationsOverviewController', [
            '$scope',
            '$timeout',
            'gridNamespaces',
            'UiGridService',
            'uiGridExporterService',
            'uiGridExporterConstants',
            'ClassificationsService',
            'TimeService',
            'ClassificationsOverviewService',
            'WatchService',
            'uiGridConstants',
            StatisticsSubmissions
        ]);

    function StatisticsSubmissions($scope,
                                   $timeout,
                                   gridNamespaces,
                                   GridService,
                                   uiGridExporterService,
                                   uiGridExporterConstants,
                                   ClassificationsService,
                                   TimeService,
                                   ClassificationsOverviewService,
                                   WatchService,
                                   uiGridConstants) {
        var controller = this,
            filterTimeout,
            defaultPaginationOptions = {
                page: 1,
                limit: 25,
                'sorting[modifiedAt]': 'desc'
            },
            columnsVisibility = GridService.getColumnsVisibility(gridNamespaces.visibility.classificationsOverview),
            paginationOptions = GridService.getColumnsFilters(
                gridNamespaces.filters.classificationsOverview,
                []
            ),
            addId = 0,
            prevLastLoginAtDateFilter = null,
            prevModifiedAtFilter = null,
            filterChangesInProgress = false;

        controller.defaultFilters = {
            lastPublishedRange: {
                startDate: null,
                endDate: null
            },
            modifiedAtRange : {
                startDate: null,
                endDate: null
            }
        };
        controller.filters = angular.extend({}, controller.defaultFilters);

        $scope.gridOptions = null;
        controller.reloadRequest = false;
        controller.selectedRowId = null;
        controller.selectedRow = null;
        controller.add = add;
        controller.isRightSidePanelActive = isRightSidePanelActive;
        controller.isLoading = false;

        function add() {
            controller.searchResults = [];
            controller.institution = null;
            if (controller.selectedRowId === addId) {
                controller.selectedRowId = null;
                controller.selectedRow = null;
            } else {
                controller.selectedRowId = addId;
                controller.selectedRow = {};
            }
            ClassificationsService.setIsAddFormVisbile(controller.selectedRowId === addId);
        }

        function load() {
            controller.isLoading = true;
            var filters = angular.merge(paginationOptions, defaultPaginationOptions);
            delete filters.lastPublishedRange;
            
            return ClassificationsService.get(
                angular.merge(paginationOptions, defaultPaginationOptions)
            ).then(function (response) {
                if (response) {
                    $scope.gridOptions.data = response.results;
                    $scope.gridOptions.minRowsToShow = response.results && response.results.length < $scope.gridOptions.paginationPageSize ? response.results.length : $scope.gridOptions.paginationPageSize;
                    $scope.gridOptions.totalItems = response.totalMatching;
                } else {
                    $scope.gridOptions.data = [];
                }
                controller.isLoading = false;
            });
        }

        function exportVisible() {
            var results = $scope.gridOptions.data;
            results = ClassificationsOverviewService.formatCsvResults(results, TimeService);
            if (typeof results === 'undefined') {
                return false;
            }
            var gridApi = controller.gridApi,
                gridOptions = $scope.gridOptions,
                exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                    gridApi.grid,
                    uiGridExporterConstants.VISIBLE
                ),
                exportData = GridService.getCsvData(
                    gridApi.grid.columns,
                    results
                ),
                csvContent = uiGridExporterService.formatAsCsv(
                    exportColumnHeaders,
                    exportData,
                    gridOptions.exporterCsvColumnSeparator
                );

            uiGridExporterService.downloadFile(
                'Classifications-overview' + TimeService.now() + '.csv',
                csvContent,
                gridOptions.exporterOlderExcelCompatibility
            );
        }

        function exportAll() {
            ClassificationsService.get(
                angular.merge(paginationOptions, {page: 1, limit: 250000})
            ).then(function (response) {
                if (typeof response.results === 'undefined') {
                    return false;
                }
                response = ClassificationsOverviewService.formatCsvResults(response.results, TimeService);
                var gridApi = controller.gridApi,
                    gridOptions = $scope.gridOptions,
                    exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                        gridApi.grid,
                        uiGridExporterConstants.VISIBLE
                    ),
                    exportData = GridService.getCsvData(
                        gridApi.grid.columns,
                        response
                    ),
                    csvContent = uiGridExporterService.formatAsCsv(
                        exportColumnHeaders,
                        exportData,
                        gridOptions.exporterCsvColumnSeparator
                    );
                uiGridExporterService.downloadFile(
                    'Classifications-overview' + TimeService.now() + '.csv',
                    csvContent,
                    gridOptions.exporterOlderExcelCompatibility
                );
            });
        }

        controller.handleLastLoginAtDateRange = function (event) {
            //Handle selecting today only / one day date range
            var sameDay = null;
            var modelIsSet = !!(controller.filters.lastPublishedRange.startDate && controller.filters.lastPublishedRange.endDate);
            var eventModelIsSet = !!(event.model.startDate && event.model.endDate);

            if (prevLastLoginAtDateFilter && prevLastLoginAtDateFilter.startDate && prevLastLoginAtDateFilter.endDate) {
                sameDay = TimeService.isSameDay(prevLastLoginAtDateFilter.startDate, prevLastLoginAtDateFilter.endDate);
            }

            if (sameDay && !eventModelIsSet && !modelIsSet) {

                event.model.startDate = prevLastLoginAtDateFilter.startDate;
                event.model.endDate = prevLastLoginAtDateFilter.endDate;
                controller.filters.lastPublishedRange = {
                    startDate: prevLastLoginAtDateFilter.startDate,
                    endDate: prevLastLoginAtDateFilter.endDate
                };
            } else if (!eventModelIsSet && ((prevLastLoginAtDateFilter && sameDay) || !prevLastLoginAtDateFilter)) {
                event.model.startDate = TimeService.getStartOfToday();
                event.model.endDate = TimeService.getEndOfToday();
                controller.filters.lastPublishedRange = {
                    startDate: event.model.startDate.toISOString(),
                    endDate: event.model.endDate.toISOString()
                };
            }
            if (event.model.startDate && event.model.endDate) {
                $scope.gridOptions.columnDefs[controller.getColumnNumber('lastPublished')].filters[0].term = event.model.startDate.format('x');
                $scope.gridOptions.columnDefs[controller.getColumnNumber('lastPublished')].filters[1].term = event.model.endDate.format('x');
            }
        };

        controller.handleModifiedAtRange = function (event) {
            //Handle selecting today only / one day date range
            var sameDay = null;
            var modelIsSet = !!(controller.filters.modifiedAtRange.startDate && controller.filters.modifiedAtRange.endDate);
            var eventModelIsSet = !!(event.model.startDate && event.model.endDate);

            if (prevModifiedAtFilter && prevModifiedAtFilter.startDate && prevModifiedAtFilter.endDate) {
                sameDay = TimeService.isSameDay(prevModifiedAtFilter.startDate, prevModifiedAtFilter.endDate);
            }

            if (sameDay && !eventModelIsSet && !modelIsSet) {

                event.model.startDate = prevModifiedAtFilter.startDate;
                event.model.endDate = prevModifiedAtFilter.endDate;
                controller.filters.modifiedAtRange = {
                    startDate: prevModifiedAtFilter.startDate,
                    endDate: prevModifiedAtFilter.endDate
                };
            } else if (!eventModelIsSet && ((prevModifiedAtFilter && sameDay) || !prevModifiedAtFilter)) {
                event.model.startDate = TimeService.getStartOfToday();
                event.model.endDate = TimeService.getEndOfToday();
                controller.filters.modifiedAtRange = {
                    startDate: event.model.startDate.toISOString(),
                    endDate: event.model.endDate.toISOString()
                };
            }
            if (event.model.startDate && event.model.endDate) {
                $scope.gridOptions.columnDefs[controller.getColumnNumber('modifiedAt')].filters[0].term = event.model.startDate.format('x');
                $scope.gridOptions.columnDefs[controller.getColumnNumber('modifiedAt')].filters[1].term = event.model.endDate.format('x');
            }
        };

        controller.getColumnNumber = function (columnName) {
            var key = 0,
                total = $scope.gridOptions.columnDefs.length;
            for (key; key < total; key++) {
                if ($scope.gridOptions.columnDefs[key] &&
                    $scope.gridOptions.columnDefs[key].field === columnName
                ) {
                    return key;
                }
            }
            return null;
        };

        function initDataGrid() {
            var statusCellTemplate = '/scripts/modules/rankings/components/classificationsOverview/datagrid/statusCellTemplate.html',
                dateCellTemplate = '/scripts/shared/ui-grid/templates/dateFilterCellTemplate.html',
                dateFilterHeaderTemplate = '/scripts/shared/ui-grid/templates/dateFilterHeaderTemplate.html',
                selectTemplate = '/scripts/shared/ui-grid/templates/selectFilterHeaderTemplate.html';
            $scope.gridOptions = {
                appScopeProvider: controller,
                enableSorting: false,
                exporterMenuCsv: false,
                exporterMenuAllData: false,
                exporterMenuPdf: false,
                enableGridMenu: true,
                useExternalFiltering: true,
                showGridFooter: true,
                //selectedItems: $scope.selectedRowId,
                enableFiltering: true,
                enableColumnResize: true,
                enableFullRowSelection: true,
                enableRowSelection: true,
                multiSelect: false,
                enableRowHeaderSelection: false,
                paginationPageSizes: [25, 50, 100],
                paginationPageSize: 25,
                useExternalPagination: true,
                useExternalSorting: true,
                enableHorizontalScrollbar: 1,
                gridMenuCustomItems: [
                    {
                        title: ' Export visible data as csv',
                        action: exportVisible,
                        order: 209
                    },
                    {
                        title: ' Export all data as csv',
                        action: exportAll,
                        order: 210
                    }
                ],
                rowTemplate: '/scripts/shared/ui-grid/templates/rowTemplate.html',
                columnDefs: [
                    {
                        displayName: 'Institution ID',
                        field: 'institutionCoreId',
                        width: '*',
                        minWidth: "100",
                        visible: GridService.getVisibilityByField(columnsVisibility, 'institutionCoreId', true),
                        filter: {
                            term: GridService.getFilterByField(paginationOptions, 'institutionCoreIdAsString', ''),
                            column: 'institutionCoreIdAsString'
                        }
                    },
                    {
                        displayName: 'Institution Name',
                        field: 'institutionName',
                        width: '*',
                        minWidth: "150",
                        visible: GridService.getVisibilityByField(columnsVisibility, 'institutionName', true),
                        filter: {
                            term: GridService.getFilterByField(paginationOptions, 'institutionName', '')
                        }
                    },
                    {
                        displayName: 'Size',
                        field: 'size',
                        width: '*',
                        minWidth: "80",
                        visible: GridService.getVisibilityByField(columnsVisibility, 'size', true),
                        filter: {
                            term: GridService.getFilterByField(paginationOptions, 'size', '')
                        }
                    },
                    {
                        displayName: 'Subject Range',
                        field: 'subjectRange',
                        width: '*',
                        minWidth: "100",
                        visible: GridService.getVisibilityByField(columnsVisibility, 'subjectRange', true),
                        filter: {
                            term: GridService.getFilterByField(paginationOptions, 'subjectRange', '')
                        }
                    },
                    {
                        displayName: 'Age',
                        field: 'age',
                        width: '*',
                        minWidth: "80",
                        visible: GridService.getVisibilityByField(columnsVisibility, 'age', true),
                        filter: {
                            term: GridService.getFilterByField(paginationOptions, 'ageAsString', ''),
                            column: 'ageAsString'
                        }
                    },
                    {
                        displayName: 'Research Intensity',
                        field: 'researchIntensity',
                        width: '*',
                        minWidth: "100",
                        visible: GridService.getVisibilityByField(columnsVisibility, 'researchIntensity', true),
                        filter: {
                            term: GridService.getFilterByField(paginationOptions, 'researchIntensity', '')
                        }
                    },
                    {
                        displayName: 'Tuition Fees Domestic Undergrad',
                        field: 'domesticUgTuitionFees',
                        width: '*',
                        minWidth: "110",
                        visible: GridService.getVisibilityByField(columnsVisibility, 'domesticUgTuitionFees', true),
                        filter: {
                            term: GridService.getFilterByField(paginationOptions, 'domesticUgTuitionFees', '')
                        }
                    },
                    {
                        displayName: 'Tuition Fees Domestic Postgrad',
                        field: 'domesticPgTuitionFees',
                        width: '*',
                        minWidth: "110",
                        visible: GridService.getVisibilityByField(columnsVisibility, 'domesticPgTuitionFees', true),
                        filter: {
                            term: GridService.getFilterByField(paginationOptions, 'domesticPgTuitionFees', '')
                        }
                    },
                    {
                        displayName: 'Tuition Fees International Undergrad',
                        field: 'internationalUgTuitionFees',
                        width: '*',
                        minWidth: "110",
                        visible: GridService.getVisibilityByField(columnsVisibility, 'internationalUgTuitionFees', true),
                        filter: {
                            term: GridService.getFilterByField(paginationOptions, 'internationalUgTuitionFees', '')
                        }
                    },
                    {
                        displayName: 'Tuition Fees International Postgrad',
                        field: 'internationalPgTuitionFees',
                        width: '*',
                        minWidth: "110",
                        visible: GridService.getVisibilityByField(columnsVisibility, 'internationalPgTuitionFees', true),
                        filter: {
                            term: GridService.getFilterByField(paginationOptions, 'internationalPgTuitionFees', '')
                        }
                    },
                    {
                        displayName: 'Status',
                        field: 'status',
                        width: '*',
                        minWidth: "80",
                        visible: GridService.getVisibilityByField(columnsVisibility, 'status', true),
                        filter: {
                            term: GridService.getFilterByField(paginationOptions, 'status', '')
                        }
                    },
                    {
                        displayName: 'Number of Academic Faculty Staff',
                        field: 'faculty',
                        width: '*',
                        minWidth: "110",
                        visible: GridService.getVisibilityByField(columnsVisibility, 'faculty', true),
                        filter: {
                            term: GridService.getFilterByField(paginationOptions, 'facultyAsString', ''),
                            column: 'facultyAsString'
                        }
                    },
                    {
                        displayName: 'Number of Academic Faculty Staff with PhD',
                        field: 'facultyPhd',
                        width: '*',
                        minWidth: "140",
                        visible: GridService.getVisibilityByField(columnsVisibility, 'facultyPhd', true),
                        filter: {
                            term: GridService.getFilterByField(paginationOptions, 'facultyPhdAsString', ''),
                            column: 'facultyPhdAsString'
                        }
                    },
                    {
                        displayName: 'Number of International Academic Faculty Staff / Year',
                        field: 'facultyInternational',
                        width: '*',
                        minWidth: "120",
                        visible: GridService.getVisibilityByField(columnsVisibility, 'facultyInternational', true),
                        filter: {
                            column: 'facultyInternationalAsString',
                            term: GridService.getFilterByField(paginationOptions, 'facultyInternationalAsString', '')
                        }
                    },
                    {
                        displayName: 'Number of Students',
                        field: 'students',
                        width: '*',
                        minWidth: "110",
                        visible: GridService.getVisibilityByField(columnsVisibility, 'students', true),
                        filter: {
                            column: 'studentsAsString',
                            term: GridService.getFilterByField(paginationOptions, 'studentsAsString', '')
                        }
                    },
                    {
                        displayName: 'Number of Undergraduate Students',
                        field: 'studentsUg',
                        width: '*',
                        minWidth: "130",
                        visible: GridService.getVisibilityByField(columnsVisibility, 'studentsUg', true),
                        filter: {
                            column: 'studentsUgAsString',
                            term: GridService.getFilterByField(paginationOptions, 'studentsUgAsString', '')
                        }
                    },
                    {
                        displayName: 'Number of Postgraduate Students',
                        field: 'studentsPg',
                        width: '*',
                        minWidth: "110",
                        visible: GridService.getVisibilityByField(columnsVisibility, 'studentsPg', true),
                        filter: {
                            column: 'studentsPgAsString',
                            term: GridService.getFilterByField(paginationOptions, 'studentsPgAsString', '')
                        }
                    },
                    {
                        displayName: 'Number of International Students',
                        field: 'studentsInternational',
                        width: '*',
                        minWidth: "120",
                        visible: GridService.getVisibilityByField(columnsVisibility, 'studentsInternational', true),
                        filter: {
                            column: 'studentsInternationalAsString',
                            term: GridService.getFilterByField(paginationOptions, 'studentsInternationalAsString', '')
                        }
                    },
                    {
                        displayName: 'Number of International Undergraduate Students',
                        field: 'studentsUgInternational',
                        width: '*',
                        minWidth: "130",
                        visible: GridService.getVisibilityByField(columnsVisibility, 'studentsUgInternational', true),
                        filter: {
                            column: 'studentsUgInternationalAsString',
                            term: GridService.getFilterByField(paginationOptions, 'studentsUgInternationalAsString', '')
                        }
                    },
                    {
                        displayName: 'Number of International Postgraduate Students',
                        field: 'studentsPgInternational',
                        width: '*',
                        minWidth: "120",
                        visible: GridService.getVisibilityByField(columnsVisibility, 'studentsPgInternational', true),
                        filter: {
                            column: 'studentsPgInternationalAsString',
                            term: GridService.getFilterByField(paginationOptions, 'studentsPgInternationalAsString', '')
                        }
                    },
                    {
                        displayName: 'Student / Faculty ratio',
                        field: 'studentFacultyRatio',
                        width: '*',
                        minWidth: "100",
                        visible: GridService.getVisibilityByField(columnsVisibility, 'studentFacultyRatio', true),
                        enableFiltering: false
                    },
                    {
                        displayName: 'Domestic Students Percentage',
                        field: 'domesticStudentsPercentage',
                        width: '*',
                        minWidth: "100",
                        visible: GridService.getVisibilityByField(columnsVisibility, 'domesticStudentsPercentage', true),
                        enableFiltering: false
                    },
                    {
                        displayName: 'Gender mix',
                        field: 'genderMix',
                        width: '*',
                        minWidth: "80",
                        visible: GridService.getVisibilityByField(columnsVisibility, 'genderMix', true),
                        filter: {
                            column: 'genderMixAsString',
                            term: GridService.getFilterByField(paginationOptions, 'genderMixAsString', '')
                        }
                    },
                    {
                        displayName: 'Graduation rate',
                        field: 'graduationRate',
                        width: '*',
                        minWidth: "110",
                        visible: GridService.getVisibilityByField(columnsVisibility, 'graduationRate', true),
                        filter: {
                            column: 'graduationRateAsString',
                            term: GridService.getFilterByField(paginationOptions, 'graduationRateAsString', '')
                        }
                    },
                    {
                        displayName: 'Last Published',
                        field: 'lastPublished',
                        width: '*',
                        minWidth: "110",
                        cellTemplate: dateCellTemplate,
                        visible: GridService.getVisibilityByField(columnsVisibility, 'lastPublished', true),
                        filters: [
                            {
                                visible: true,
                                condition: uiGridConstants.filter.GREATER_THAN,
                                term: GridService.getFilterByField(paginationOptions, 'lastPublished', null)
                            },
                            {
                                condition: uiGridConstants.filter.LESS_THAN
                            }
                        ],
                        filter: {
                            term: GridService.getFilterByField(paginationOptions, 'lastPublished', ''),
                            applyTextFilter: applyDateFilter('lastPublished', '')
                        },
                        filterHeaderTemplate: '/scripts/modules/rankings/components/classificationsOverview/datagrid/lastPublishedFilterHeaderTemplate.html'
                    },
                    {
                        displayName: 'Published',
                        field: 'published',
                        width: '*',
                        minWidth: "110",
                        visible: GridService.getVisibilityByField(columnsVisibility, 'published', true),
                        filter: {
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: [
                                {value: true, label: 'Published'},
                                {value: false, label: 'Unpublished'}
                            ],
                            term: GridService.getFilterByField(paginationOptions, 'published')
                        },
                        filterHeaderTemplate: selectTemplate,
                        cellTemplate: statusCellTemplate
                    },
                    {
                        displayName: 'Last Updated',
                        field: 'modifiedAt',
                        width: '*',
                        minWidth: "110",
                        cellTemplate: dateCellTemplate,
                        visible: GridService.getVisibilityByField(columnsVisibility, 'modifiedAt', true),
                        filters: [
                            {
                                visible: true,
                                condition: uiGridConstants.filter.GREATER_THAN,
                                term: GridService.getFilterByField(paginationOptions, 'modifiedAt', null)
                            },
                            {
                                condition: uiGridConstants.filter.LESS_THAN
                            }
                        ],
                        filter: {
                            term: GridService.getFilterByField(paginationOptions, 'modifiedAt', ''),
                            applyTextFilter: applyDateFilter('modifiedAt', '')
                        },
                        filterHeaderTemplate: dateFilterHeaderTemplate
                    }
                ],
                onRegisterApi: function (gridApi) {
                    controller.gridApi = gridApi;

                    gridApi.selection.on.rowSelectionChanged($scope, function (row) {
                        handleDatagridRowClick(row.entity);
                    });
                    gridApi.pagination.on.paginationChanged($scope, function (newPage, limit) {
                        defaultPaginationOptions.page = newPage;
                        defaultPaginationOptions.limit = limit;
                        if(!filterChangesInProgress) { 
                            load();
                        }
                    });
                    $scope.$watch('ClassificationsOverviewController.gridApi.grid.isScrollingHorizontally', gridScrollWatch);
                    gridApi.core.on.filterChanged($scope, handleGridFiltersChanges);
                    gridApi.core.on.rowsVisibleChanged($scope, handleGridVisibleChanges);
                }
            };
            datePickersFixes();
        }

        function datePickersFixes() {
            // This is needed to add 'x' icon in date field when prepopulating dates
            var modifiedAtField = GridService.getFilterByField(paginationOptions, 'modifiedAt', '');
            if (modifiedAtField !== null && paginationOptions["filter[modifiedAt]"] !== null) {
                if (angular.isDefined(modifiedAtField) &&
                    angular.isDefined(paginationOptions["filter[modifiedAt]"]) &&
                    angular.isDefined(paginationOptions["filter[modifiedAt]"].startDate) &&
                    angular.isDefined(paginationOptions["filter[modifiedAt]"].endDate)
                ) {
                    $scope.gridOptions.columnDefs[getColumnNumber('modifiedAt')].filters[0].term = paginationOptions["filter[modifiedAt]"].startDate;
                    $scope.gridOptions.columnDefs[getColumnNumber('modifiedAt')].filters[1].term = paginationOptions["filter[modifiedAt]"].endDate;
                }
            }

            var lastPublishedField = GridService.getFilterByField(paginationOptions, 'lastPublished', '');
            if (lastPublishedField !== null && paginationOptions["filter[lastPublished]"] !== null) {
                if (angular.isDefined(lastPublishedField) &&
                    angular.isDefined(paginationOptions["filter[lastPublished]"]) &&
                    angular.isDefined(paginationOptions["filter[lastPublished]"].startDate) &&
                    angular.isDefined(paginationOptions["filter[lastPublished]"].endDate)
                ) {
                    $scope.gridOptions.columnDefs[getColumnNumber('lastPublished')].filters[0].term = paginationOptions["filter[lastPublished]"].startDate;
                    $scope.gridOptions.columnDefs[getColumnNumber('lastPublished')].filters[1].term = paginationOptions["filter[lastPublished]"].endDate;
                }
            }
        }

        function getColumnNumber(columnName) {
            var key = 0,
                total = $scope.gridOptions.columnDefs.length;
            for (key; key < total; key++) {
                if ($scope.gridOptions.columnDefs[key] &&
                    $scope.gridOptions.columnDefs[key].field === columnName
                ) {
                    return key;
                }
            }

            return null;
        }

        function gridScrollWatch (newValue) {
            if (newValue) {
                var uiSelectController = angular.element('.ui-grid-filter-select.open').controller('uiSelect');
                if (uiSelectController && uiSelectController.open) {
                    uiSelectController.close(true);
                    uiSelectController.clickTriggeredSelect = false;
                }
            }
        }

        function handleDatagridRowClick(row) {
            controller.searchResults = [];
            controller.institution = (controller.selectedRowId === row.id) ? controller.institution : null;
            controller.selectedRowId = (controller.selectedRowId === row.id) ? null : row.id;
            controller.selectedRow = controller.selectedRowId ? angular.copy(row) : null;
        }

        /**
         * Populates input text field for date field
         *
         * @param {string} filterName
         * @param {mixed} defaultValue
         */

        function applyDateFilter(filterName, defaultValue) {
            var filter = GridService.getFilterByField(paginationOptions, filterName, defaultValue);
            if (angular.isDefined(filter) &&
                filter !== null
            ) {
                if (angular.isDefined(filter.startDate) &&
                    angular.isDefined(filter.endDate)
                ) {
                    if (filter.startDate !== null &&
                        filter.startDate !== null
                    ) {
                        controller.filters[filterName + 'Range'] = {
                            startDate: parseInt(filter.startDate, 10),
                            endDate: parseInt(filter.endDate, 10)
                        };
                    } else {
                        controller.filters[filterName + 'Range'] = {
                            startDate: null,
                            endDate: null
                        };
                    }
                }
            }
        }

        function resetSelectedRowCount () {
            if (controller.gridApi.grid.selection.selectedCount === 1) {
                controller.gridApi.selection.clearSelectedRows();
            }
        }

        function removeFilter(field) {
            switch (field) {
                case 'age':
                    paginationOptions['filter[ageAsString]'] = null;
                    return;
                case 'faculty':
                    paginationOptions['filter[facultyAsString]'] = null;
                    return;
                case 'facultyPhd':
                    paginationOptions['filter[facultyPhdAsString]'] = null;
                    return;
                case 'facultyInternational':
                    paginationOptions['filter[facultyInternationalAsString]'] = null;
                    return;
                case 'students':
                    paginationOptions['filter[studentsAsString]'] = null;
                    return;
                case 'studentsUg':
                    paginationOptions['filter[studentsUgAsString]'] = null;
                    return;
                case 'studentsPg':
                    paginationOptions['filter[studentsPgAsString]'] = null;
                    return;
                case 'studentsInternational':
                    paginationOptions['filter[studentsInternationalAsString]'] = null;
                    return;
                case 'studentsUgInternational':
                    paginationOptions['filter[studentsUgInternationalAsString]'] = null;
                    return;
                case 'studentsPgInternational':
                    paginationOptions['filter[studentsPgInternationalAsString]'] = null;
                    return;
                case 'genderMix':
                    paginationOptions['filter[genderMixAsString]'] = null;
                    return;
                case 'graduationRate':
                    paginationOptions['filter[graduationRateAsString]'] = null;
                    return;
                case 'institutionCoreId':
                    paginationOptions['filter[institutionCoreIdAsString]'] = null;
                    return;
                default:
                    paginationOptions['filter[' + field + ']'] = null;
                    return;
            }
        }

        function handleGridFiltersChanges() {
            filterChangesInProgress = true;
            if(controller.gridApi.pagination.getPage() > 1){
                controller.gridApi.pagination.seek(1);
            }
            angular.forEach(controller.gridApi.grid.columns, function (column) {
                var filterColumn = column.filters[0].column ? column.filters[0].column : column.field,
                    deleteFilter = false;

                if (column.field === 'lastPublished' ||
                    column.field === 'modifiedAt') {
                    if (
                        typeof column.filters !== 'undefined' &&
                        typeof column.filters[0].term !== 'undefined' &&
                        typeof column.filters[1].term !== 'undefined'
                    ) {
                        paginationOptions['filter[' + filterColumn + ']'] = {
                            startDate: column.filters[0].term && isNaN(column.filters[0].term) ?
                                column.filters[0].term.format('x') : column.filters[0].term,
                            endDate: column.filters[1].term && isNaN(column.filters[1].term) ?
                                column.filters[1].term.format('x') : column.filters[1].term
                        };
                    } else {
                        paginationOptions['filter[' + filterColumn + ']'] = null;
                    }
                } else {
                    if (
                        column.filters[0].type === uiGridConstants.filter.SELECT &&
                        typeof column.filters[0].term === 'object' &&
                        column.filters[0].term !== null &&
                        typeof column.filters[0].term.value !== 'undefined'
                    ) {
                        column.filters[0].term = column.filters[0].term.value;
                    }
                    if (typeof column.filters !== 'undefined' &&
                        column.filters[0].term !== null &&
                        typeof column.filters[0].term !== 'undefined'
                    ) {
                        GridService.applyFilters(column);
                        if (column.filters[0].term === '') {
                            deleteFilter = true;
                        } else {
                            paginationOptions['filter[' + filterColumn + ']'] = column.filters[0].term;
                        }
                    } else {
                        deleteFilter = true;
                    }

                    if (deleteFilter && typeof paginationOptions['filter[' + filterColumn + ']'] !== 'undefined') {
                        delete paginationOptions['filter[' + filterColumn + ']'];
                    }
                }

            });
            dateFilters('modifiedAt');
            dateFilters('lastPublished');
           
            GridService.storeColumnsFilters(
                gridNamespaces.filters.classificationsOverview,
                paginationOptions
            );
            if (angular.isDefined(filterTimeout)) {
                $timeout.cancel(filterTimeout);
            }
            filterTimeout = $timeout(function () {
                load();
                filterChangesInProgress = false;
            }, 500);
        }

        function dateFilters(field) {
            if (paginationOptions['filter[' + field + ']'] === null) {
                paginationOptions['filter[' + field + ']'] = {
                    startDate: null,
                    endDate: null
                };
            }
            if (paginationOptions['filter[' + field + ']'].startDate === null) {
                paginationOptions['filter[' + field + ']'].endDate = null;
                controller.filters[field + 'Range'] = {
                    startDate: null,
                    endDate: null
                };
            }
        }

        function handleGridVisibleChanges () {
            if (!controller.selectedRowId) {
                resetSelectedRowCount();
            }
            var storedColumnsVisibility = GridService.getColumnsVisibility(gridNamespaces.visibility.classificationsOverview),
            reload = false;
            if (storedColumnsVisibility && !controller.selectedRowId) {
                angular.forEach($scope.gridOptions.columnDefs, function (column, key) {
                    if ((storedColumnsVisibility[column.field] === true || typeof storedColumnsVisibility[column.field] === 'undefined') && 
                        column.visible === false
                    ) {
                        if (typeof  $scope.gridOptions.columnDefs[key].filter === 'undefined') {
                            $scope.gridOptions.columnDefs[key].filter = {};
                        }
                        if (typeof  $scope.gridOptions.columnDefs[key].filter !== 'undefined' &&
                            $scope.gridOptions.columnDefs[key].filter.hasOwnProperty('term') &&
                            $scope.gridOptions.columnDefs[key].filter.term) {
                            removeFilter(column.field);
                            reload = true;
                        }
                        $scope.gridOptions.columnDefs[key].filter.term = null;
                        if (column.field === 'lastPublished') {
                            delete  $scope.gridOptions.columnDefs[key].filters[0].term;
                            controller.filters.lastPublishedRange = {
                                startDate: null,
                                endDate: null
                            };
                        }
                        if (column.field === 'modifiedAt') {
                            delete  $scope.gridOptions.columnDefs[key].filters[0].term;
                            controller.filters.modifiedAtRange = {
                                startDate: null,
                                endDate: null
                            };
                        }
                    }
                });
            }

            if ($scope.gridOptions) {
                columnsVisibility = GridService.getGridColumnsVisibility($scope.gridOptions.columnDefs);
                if(!isRightSidePanelActive()){
                    GridService.storeColumnsVisibility(
                        gridNamespaces.visibility.classificationsOverview,
                        columnsVisibility
                    );
                }
            }
            if (reload) {
                load();
            }
        }

        function isRightSidePanelActive() {
            return !!(controller.selectedRowId || controller.selectedRowId === addId);
        }

        function reloadRequestWatch(reload) {
            if (reload) {
                load().then(function () {
                    controller.reloadRequest = false;
                });
            }
        }

        function initWatches() {
            WatchService.create($scope, 'ClassificationsOverviewController.reloadRequest', reloadRequestWatch);
        }

        function init() {
            initWatches();
            initDataGrid();
            load();
        }

        init();
    }

}(window.angular));

(function (angular) {
    'use strict';

    angular.module('qsHub.rankings').service('ClassificationsOverviewService', [
        ClassificationsOverview
    ]);

    function ClassificationsOverview() {
        var service = {};

        service.formatCsvResults = function (data, TimeService) {
            data = angular.copy(data);
            angular.forEach(data, function (column, dataKey) {
                angular.forEach(column, function (item, key) {
                    if (key === 'createdAt' || key === 'modifiedAt' || key === 'lastPublished') {
                        data[dataKey][key] = TimeService.format(item, "MMM DD, YYYY HH:mm:ss");
                    }
                });
            });

            return data;
        };

        return service;
    }

}(window.angular));

(function (angular) {
    "use strict";

    angular.module('qsHub.rankings').component('classification', {
        templateUrl: '/scripts/modules/rankings/components/classification/classificationView.html',
        controller: 'Rankings.ClassificationController',
        bindings: {
            data: '=',
            selected: '=',
            reload: '=',
            searchResults: '=',
            institution: '='
        }
    });

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.rankings')
        .controller('Rankings.ClassificationController', [
            'InstitutionSwitchService',
            'ClassificationsService',
            'NotifierFactory',
            '$scope',
            'WatchService',
            'WebSocketsService',
            'SharedProfileService',
            'TimeService',
            'constants',
            'TimeService',
            Classification
        ]);

    function Classification(
        InstitutionSwitchService,
        ClassificationsService,
        NotifierFactory,
        $scope,
        WatchService,
        WebSocketsService,
        SharedProfileService,
        TimeService,
        constants
    ) {
        var controller = this;
        controller.isPublishInProgress = false;
        controller.isUpdateInProgress = false;
        controller.searchInProgress = false;
        controller.searchResults = [];
        controller.close = close;
        controller.update = update;
        controller.add = add;
        controller.publish = publish;
        controller.searchInstitution = searchInstitution;
        controller.handleInstitutionChanges = handleInstitutionChanges;
        controller.getDeleteTooltip = getDeleteTooltip;

        function close() {
            controller.selected = null;
            controller.isPublishInProgress = false;
            controller.isUpdateInProgress = false;
            controller.searchInProgress = false;
            controller.institution = null;
        }

        function handleInstitutionChanges() {
            controller.isInstitutionEmpty = false;
            if (!!controller.institution) {
                ClassificationsService.get(
                    {'filter[institutionCoreId]': controller.institution.coreId, page: 1, limit: 1}
                ).then(function (response) {
                    if (response.results[0] !== undefined) {
                        controller.data = response.results[0];
                        controller.selected = response.results[0].id;
                    }
                    else {
                        SharedProfileService.getSharedProfile(controller.institution.coreId, false, true).then( function (result) {
                            controller.data.age = null;
                            if (result) {
                                if (TimeService.isValidYear(result.foundationYear)) {
                                    controller.data.age = ClassificationsService.getAge(TimeService.getCurrentYear(), result.foundationYear);
                                }
                                else {
                                    controller.data.age = null;
                                }
                            }
                        });
                    }
                });
                controller.data.institutionName = controller.institution.name.replace(' [' + controller.institution.coreId + ']', '');
                controller.data.institutionCoreId = controller.institution.coreId;
            } else {
                controller.data.age = null;
                controller.searchResults = [];
            }
        }

        function update() {
            controller.isUpdateInProgress = true;
            ClassificationsService.update(controller.selected, controller.data).then(function (Response) {
                if (Response.isError()) {
                    NotifierFactory.show(
                        'error',
                        Response.hasErrorMessage() ? Response.getErrorMessage() : 'Update failed!',
                        'Classification'
                    );
                } else if (Response.isSuccess()) {
                    controller.data.modifiedAt = TimeService.nowUnix();
                    NotifierFactory.show(
                        'success',
                        'Updated successfully!',
                        'Classification'
                    );
                    controller.reload = true;
                }
                controller.isUpdateInProgress = false;
            });
        }

        function add() {
            if (!controller.data.institutionCoreId) {
                controller.isInstitutionEmpty = true;
                NotifierFactory.show(
                    'error',
                    'Some fields don\'t pass validation. Please correct the fields and try again',
                    'Classification'
                );

                return;
            }
            controller.isUpdateInProgress = true;
            ClassificationsService.create(controller.data).then(function (Response) {
                if (Response.isError()) {
                    NotifierFactory.show(
                        'error',
                        Response.hasErrorMessage() ? Response.getErrorMessage() : 'Creating failed!',
                        'Classification'
                    );
                } else {
                    NotifierFactory.show(
                        'success',
                        'Created successfully!',
                        'Classification'
                    );
                    controller.selected = Response.getInsertId();
                    controller.data.id = Response.getInsertId();
                    controller.reload = true;
                    controller.institution = null;
                }
                controller.isUpdateInProgress = false;
            });
        }

        function subscribePublishStatus(publishLogsId) {
            var roomName = publishLogsId + 'classifications';
            var eventName = 'publishRankingsStatus' + roomName;

            WebSocketsService.subscribe(roomName, eventName, function (result) {
                if (result.status === constants.publishStatus.failure) {
                    NotifierFactory.show(
                        'error',
                        'Classifications publishing failed', //get uni name
                        'Classifications Publish'
                    );
                    controller.publishInProgress = false;
                } else if (result.status === constants.publishStatus.success) {
                    NotifierFactory.show(
                        'success',
                        'Classifications successfully published', //get uni name
                        'Classifications Publish'
                    );
                    controller.reload = true;
                    controller.isPublishInProgress = false;
                }
            }, function (subscribed) {
                if (!subscribed) {
                    NotifierFactory.show(
                        'error',
                        'Experiencing issues with publishing.',
                        'Course Publish'
                    );
                    controller.isPublishInProgress = false;
                }
            });
        }

        function publish(publishDelete, isChina) {
            if (!controller.data.lastPublished && publishDelete) {
                return;
            }
            if (!controller.data.hasDrupalProfile) {
                return;
            }
            
            if (!!isChina) {
                isChina = "&lang=cn";
            } else {
                isChina = "";
            }
            if (!publishDelete) {
                publishDelete = false;
            }
            controller.isPublishInProgress = true;
            ClassificationsService.individualPublish(controller.data.id, publishDelete, isChina).then(function (response) {
                if (response.status &&
                    response.status === 'success'
                ) {
                    NotifierFactory.show(
                        'success',
                        'Classification publishing added to the queue successfully!',
                        'Classification Publishing'
                    );
                    subscribePublishStatus(response.publishLogId);
                } else {
                    NotifierFactory.show(
                        'error',
                        'Something went wrong with publishing, please try again',
                        'Classification Publishing'
                    );
                }
                controller.isPublishInProgress = false;
            });
        }

        function searchInstitution(searchPhrase) {
            if (!searchPhrase || !searchPhrase.length) {
                return false;
            }
            controller.searchInProgress = true;
            InstitutionSwitchService.searchInstitutions(searchPhrase).then(function (results) {
                controller.searchResults = results;
                controller.searchInProgress = false;
            });
        }

        function isAddFormVisbile() {
            controller.isAddFormVisbile = true;
            controller.searchResults = [];
            controller.institution = null;
        }

        function getDeleteTooltip() {
            return controller.data && controller.data.lastPublished ?
                'Unpublishing will send empty data to drupal' :
                'Ranking Classification hasn\'t been published before';
        }

        function initWatches() {
            WatchService.create($scope, ClassificationsService.isAddFormVisbile, isAddFormVisbile);
        }

        function init() {
            initWatches();
        }

        init();
    }

}(window.angular));

(function (angular) {
    'use strict';

    angular.module('qsHub.rankings').service('ClassificationsService', [
        '$resource',
        '$q',
        '$log',
        'constants',
        'RequestsQueueService',
        'ResponseFactory',
        'sp',
        ClassificationsService
    ]);

    function ClassificationsService($resource, $q, $log, constants, RequestsQueueService, ResponseFactory,sp) {
        var service = {
            addFormVisible: false
        };

        function getModel() {
            return $resource(constants.api.rankings.url, {}, {
                get: {
                    method: 'GET',
                    url: constants.api.rankings.url + '/v1/classifications',
                    isArray: false,
                    cancellable: true,
                },
                update: {method: 'PATCH', url: constants.api.rankings.url + '/v1/classification/:id'},
                create: {method: 'POST', url: constants.api.rankings.url + '/v1/classification'}
            });
        }

        function getPublishModel(id, publishDelete, isChina) {
            return $resource(constants.api.rankings.url, {}, {
                get: {
                    method: 'GET',
                    url: constants.api.rankings.url + '/v1/classifications-individual-publishing/publish/' + id + '?delete=' + publishDelete + isChina
                }
            });
        }

        service.individualPublish = function(id, publishDelete, isChina) {
            var deferred = $q.defer();

            getPublishModel(id, publishDelete, isChina).get({}, function (data) {
                deferred.resolve(data);
                deferred = null;
            }, function (error) {
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                deferred.resolve([]);
                deferred = null;
            });

            return deferred.promise;
        };

        service.get = function (filters) {
            var cacheKey = 'ClassificationsService:get',
                deferred = $q.defer(),
                Api;

            RequestsQueueService.cancelAll(cacheKey);

            Api = getModel().get(filters, function (data) {
                if (data.results) {
                    angular.forEach(data.results, function (item) {
                        item.domesticStudentsPercentage = service.calculateDomesticStudentsPercentage(item);
                        item.studentFacultyRatio = service.calculateStudentFacultyRatio(item);
                    });
                }
                deferred.resolve(data);
                deferred = null;
            }, function (error) {
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                deferred.resolve([]);
                deferred = null;
            });
            RequestsQueueService.add(Api, cacheKey);

            return deferred.promise;
        };

        service.handleSuccessResponse = function (result) {
            var response = new ResponseFactory();
            if (result && result.error && result.message) {
                response.setErrorMessage(result.message);
            } else {
                if (typeof result.modifiedCount !== 'undefined') {
                    if (result.modifiedCount) {
                        response.setSuccess();
                    }
                } else {
                    response.setSuccess();
                    if (typeof result.insertedId !== 'undefined') {
                        response.setInsertId(result.insertedId);
                    }
                }
            }
            return response;
        };

        service.handleErrorResponse = function () {
            var response = new ResponseFactory();
            response.setError();
            return response;
        };

        service.update = function (id, data) {
            return getModel().update({id: id}, data).$promise.then(service.handleSuccessResponse, service.handleErrorResponse);
        };

        service.create = function (data) {
            return getModel().create(data).$promise.then(service.handleSuccessResponse, service.handleErrorResponse);
        };

        service.calculateDomesticStudentsPercentage = function (item)
        {
            return item.students && item.studentsInternational ? (((item.students - item.studentsInternational) / item.students) * 100).toFixed(2) : null;
        };

        service.calculateStudentFacultyRatio = function (item)
        {
            return item.faculty && item.students ? Math.round(item.students / item.faculty) : null;
        };

        service.setIsAddFormVisbile = function(visible) {
            service.addFormVisible = visible;
        };

        service.isAddFormVisbile = function() {
            return service.addFormVisible;
        };

        service.getAge = function (currentYear, foundationYear) {
            if (foundationYear === 0) {
                return null;
            }
            var difference = currentYear - parseInt(foundationYear);
            if (difference < 10) {
                return 1;
            }
            else if (difference >= 10 && difference <= 25) {
                return 2;
            }
            else if (difference > 25 && difference <= 50) {
                return 3;
            }
            else if (difference > 50 && difference <= 100) {
                return 4;
            }
            else if (difference > 100) {
                return 5;
            }
        };

        service.calculateResearchIntensity = function (data, subjectAreas) {
            if (data.subjectRange === 'CO') {
                if (data.size === 'XL') {
                    data.researchIntensity = data.papersCount >= 7000 ?  'VH' :  data.papersCount >= 2000 ? 'HI' : data.papersCount >= 400 ? 'MD' : 'LO' ;
                } else if (data.size === 'L') {
                    data.researchIntensity = data.papersCount >= 5000 ?  'VH' :  data.papersCount >= 1500 ? 'HI' : data.papersCount >= 250 ? 'MD' : 'LO' ;
                } else if (data.size === 'M') {
                    data.researchIntensity = data.papersCount >= 2500 ?  'VH' :  data.papersCount >= 750 ? 'HI' : data.papersCount >= 100 ? 'MD' : 'LO' ;
                } else if (data.size === 'S') {
                    data.researchIntensity = data.papersCount >= 1250 ?  'VH' :  data.papersCount >= 400 ? 'HI' : data.papersCount >= 50 ? 'MD' : 'LO' ;
                }
            } else if (data.subjectRange === 'FC') {
                if (data.size === 'XL') {
                    data.researchIntensity = data.papersCount >= 13000 ?  'VH' :  data.papersCount >= 4000 ? 'HI' : data.papersCount >= 750 ? 'MD' : 'LO' ;
                } else if (data.size === 'L') {
                    data.researchIntensity = data.papersCount >= 10000 ?  'VH' :  data.papersCount >= 3000 ? 'HI' : data.papersCount >= 500 ? 'MD' : 'LO' ;
                } else if (data.size === 'M') {
                    data.researchIntensity = data.papersCount >= 5000 ?  'VH' :  data.papersCount >= 1500 ? 'HI' : data.papersCount >= 250 ? 'MD' : 'LO' ;
                } else if (data.size === 'S') {
                    data.researchIntensity = data.papersCount >= 2500 ?  'VH' :  data.papersCount >= 750 ? 'HI' : data.papersCount >= 100 ? 'MD' : 'LO' ;
                }
            } else if (data.subjectRange === 'FO') {
                if (data.size === 'XL') {
                    data.researchIntensity = data.papersCount >= 3500 ?  'VH' :  data.papersCount >= 1000 ? 'HI' : data.papersCount >= 150 ? 'MD' : 'LO' ;
                } else if (data.size === 'L') {
                    data.researchIntensity = data.papersCount >= 2500 ?  'VH' :  data.papersCount >= 750 ? 'HI' : data.papersCount >= 100 ? 'MD' : 'LO' ;
                } else if (data.size === 'M') {
                    data.researchIntensity = data.papersCount >= 1250 ?  'VH' :  data.papersCount >= 400 ? 'HI' : data.papersCount >= 50 ? 'MD' : 'LO' ;
                } else if (data.size === 'S') {
                    data.researchIntensity = data.papersCount >= 650 ?  'VH' :  data.papersCount >=  200 ? 'HI' : data.papersCount >= 50 ? 'MD' : 'LO' ;
                }
            } else if (data.subjectRange === 'SP' && !!subjectAreas) {
                if (!!subjectAreas['Arts & Humanities']) {
                    data.researchIntensity = data.papersCount >= sp['Arts & Humanities'].VH ? 'VH' : data.papersCount >= sp['Arts & Humanities'].HI ? 'HI' : data.papersCount >= sp['Arts & Humanities'].MD ? 'MD' : 'LO';
                } else if (!!subjectAreas.Engineering) {
                    data.researchIntensity = data.papersCount >= sp.Engineering.VH ?  'VH' :  data.papersCount >= sp.Engineering.HI ? 'HI' : data.papersCount >= sp.Engineering.MD  ? 'MD' : 'LO';
                } else if (!!subjectAreas['Life Sciences & Medicine'] || !!subjectAreas['Medical School']) {
                    data.researchIntensity = data.papersCount >= sp['Life Sciences & Medicine'].VH ?  'VH' :  data.papersCount >= sp['Life Sciences & Medicine'].HI ? 'HI' : data.papersCount >= sp['Life Sciences & Medicine'].MD ? 'MD' : 'LO';
                } else if (!!subjectAreas['Natural Sciences']) {
                    data.researchIntensity = data.papersCount >= sp['Natural Sciences'].VH ?  'VH' :  data.papersCount >= sp['Natural Sciences'].HI ? 'HI' : data.papersCount >= sp['Natural Sciences'].MD ? 'MD' : 'LO';
                } else if (!!subjectAreas['Social Sciences'] || !!subjectAreas['Business & Management']) {
                    data.researchIntensity = data.papersCount >= sp['Social Sciences'].VH ?  'VH' :  data.papersCount >= sp['Social Sciences'].HI ? 'HI' : data.papersCount >= sp['Social Sciences'].MD ? 'MD' : 'LO' ;
                }
            }

            return data;
        };

        return service;
    }

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.rankings')
        .controller('Rankings.HtmlController', [
            'constants',
            '$window',
            '$interpolate',
            '$templateCache',
            '$timeout',
            'RankingsScoresService',
            'RankingsHtmlService',
            'TimeService',
            'uiGridExporterService',
            'uiGridExporterConstants',
            'uiGridConstants',
            'InstitutionsListService',
            'NotifierFactory',
            'UserFactory',
            'UiGridService',
            Controller
        ]);

    function Controller(
        constants,
        $window,
        $interpolate,
        $templateCache,
        $timeout,
        RankingsScoresService,
        RankingsHtmlService,
        TimeService,
        uiGridExporterService,
        uiGridExporterConstants,
        uiGridConstants,
        InstitutionsListService,
        NotifierFactory,
        UserFactory,
        GridService
    ) {
        var controller = this,
            rankingIndicators = [],
            indicatorsList = [],
            subjectsList = [],
            dataToLoad = 5,
            tuRankings = [],
            tmRankings = [],
            gridApiInstances = [],
            countriesList = [];
        controller.showSubjects = false;
        controller.selectedItem = {};
        controller.rankingsList = [];
        controller.yearsList = [];
        controller.subjectsList = [];
        controller.dataLaoded = false;
        InstitutionsListService.getCountries().then(function (list) {
            angular.forEach(list, function (data) {
                countriesList.push({
                    value: data.countryCode,
                    label: data.name
                });
            });
        });

        controller.rangeList = [
            {
                value: 'all',
                label: 'All'
            }
        ];
        controller.websitesList = [
            {
                value: 'tu',
                label: 'TopUniversities'
            }
        ];

        controller.loading = function() {
            return dataToLoad > 0;
        };

        controller.selectedWebsiteChanged = function () {
            controller.rankingsList = controller.selectedItem.website && controller.selectedItem.website.value === 'tu' ? tuRankings : tmRankings;
            controller.dataLaoded = false;
            controller.showPublishBtn = false;
            controller.gridOptions = [];
        };

        controller.selectedRankingChanged = function () {
            controller.showSubjects = controller.selectedItem.ranking && (controller.selectedItem.ranking.value === 4 || controller.selectedItem.ranking.value === 5);
            fetchIndicators();
            fetchSubjects();
            controller.dataLaoded = false;
            controller.showPublishBtn = false;
            controller.gridOptions = [];
        };

        controller.selectedSubjectChanged = function () {
            controller.dataLaoded = false;
            controller.gridOptions = [];
        };

        controller.selectedRangeChanged = function () {
            controller.dataLaoded = false;
            controller.gridOptions = [];
        };

        controller.handleSubjectChanges = function () {
            controller.dataLaoded = false;
            controller.gridOptions = [];
        };

        controller.selectedYearChanged = function () {
            fetchIndicators();
            fetchSubjects();
            controller.dataLaoded = false;
            controller.showPublishBtn = false;
            controller.gridOptions = [];
        };

        controller.isFetchDisabled = function() {
            return !controller.selectedItem.ranking || !controller.selectedItem.year || !controller.selectedItem.range ||
                    (controller.showSubjects && (!controller.subjectsList.length || !controller.selectedItem.subject ||
                    (controller.selectedItem.subject && !controller.selectedItem.subject.length)));
        };

        controller.generateTable = function () {
            dataToLoad = 1;
            var params = {
                'filter[year]' : controller.selectedItem.year.value,
                'filter[rankingId]' : controller.selectedItem.ranking.value,
                'filter[site]' : controller.selectedItem.website.value,
                'filter[top]': controller.selectedItem.range.value,
                'limit' : 2000
            };
            if (controller.showSubjects) {
                var subjects = [];
                angular.forEach(controller.selectedItem.subject, function (item) {
                    subjects.push(item.value);
                });
                params['filter[subject][]'] = subjects;
            } else {
                params['sorting[scores.overall.rank]'] = 'asc';
            }
            RankingsScoresService.search(params).then(function (data) {
                initDatagrid(data.results, data.totalFiltered);
                $timeout(function () {
                    dataToLoad--;
                },200);
            });
        };

        function initDatagrid(data, total) {
            var columnDefs = [
                {
                    displayName: 'Rank',
                    field: 'scores.overall.rank',
                    visible:false
                },
                {
                    displayName: 'Display Rank',
                    field: 'scores.overall.displayRank'
                },
                {
                    displayName: 'Overall Score',
                    field: 'scores.overall.score'
                },
                {
                    displayName: 'Institution',
                    field: 'institutionName'
                },
                {
                    displayName: 'Country',
                    field: 'institutionCountryCode',
                    filter: {
                        column: 'institutionCountryCode',
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: countriesList,
                        searchEnabled: true,
                        bindToField: true,
                        condition: function (searchTerms, cellValue) {
                            if (angular.isDefined(searchTerms) && searchTerms.length > 0) {
                                var searchTermsArray = [];
                                angular.forEach(searchTerms, function (searchTerm) {
                                    searchTermsArray.push(searchTerm);
                                });
                                return searchTermsArray.indexOf(cellValue) !== -1;
                            } else {
                                return true;
                            }
                        }
                    },
                    filterHeaderTemplate: '/scripts/shared/ui-grid/templates/multiSelectFilterHeaderTemplate.html',
                    cellTemplate: '/scripts/shared/ui-grid/templates/selectCellTemplate.html'
                },
                {
                    displayName: 'Link',
                    field: 'drupalUrl'
                }
            ];

            angular.forEach(rankingIndicators, function (item) {
                columnDefs.push({
                    displayName: item.label,
                    field: 'scores.' + item.value + '.displayScore'
                });
            });

            controller.gridOptions = [];
            if (controller.showSubjects) {
                var subjectData,
                    subjectId;
                angular.forEach(controller.selectedItem.subject, function (item) {
                    subjectId = item.value;
                    subjectData = data[subjectId].results;
                    angular.forEach(subjectData, function (row) {
                        row.scores = row.scores[subjectId];
                    });
                    addUrl(subjectData);
                    addDatagrid(subjectData, columnDefs, subjectData.totalFiltered, item.label);
                });
            } else {
                addUrl(data);
                addDatagrid(data, columnDefs, total);
            }

            controller.dataLaoded = false;
            controller.showPublishBtn = false;
            if (total > 0) {
                controller.dataLaoded = true;
                controller.disablePublishButton = false;
                if (controller.showSubjects) {
                    angular.forEach(controller.selectedItem.subject, function (item) {
                        angular.forEach(data[item.value].results, function (value) {
                            if (!!value.isRankingsAppPublished && !controller.disablePublishButton) {
                                controller.disablePublishButton = true;
                            }
                        });
                    });
                } else {
                    angular.forEach(data, function (value) {
                        if (!!value.isRankingsAppPublished && !controller.disablePublishButton) {
                            controller.disablePublishButton = true;
                        }
                    });
                }
                controller.showPublishBtn = true;
            }
        }

        function addUrl(data) {
            angular.forEach(data, function (item) {
                item.drupalUrl = controller.selectedItem.website.value === 'tu' ?
                    (item.nids.master ? constants.drupal.tu.url + '/node/' + item.nids.master + '/' + encodeURI(item.institutionName) : null) :
                    (item.nids.tm ? constants.drupal.tm.url + '/node/' + item.nids.tm + '/' + encodeURI(item.institutionName) : null);
                item.drupalUrl = addProtocol(item.drupalUrl);
            });
        }

        function addProtocol(url) {
            if (url && url.indexOf('http') === -1) {
                url = 'https:' + url;
                url = url.replace(/%20/g,"+");
            }

            return url;
        }

        function addDatagrid(data, columnDefs, total, label) {
            controller.gridOptions.push({
                appScopeProvider: controller,
                data: data,
                label: label || null,
                totalItems: total,
                enableSorting: false,
                useExternalFiltering: false,
                enableGridMenu: true,
                showGridFooter: true,
                enableFiltering: true,
                enableColumnResize: true,
                enableFullRowSelection: true,
                enableRowSelection: true,
                multiSelect: false,
                enableRowHeaderSelection: false,
                exporterMenuCsv: true,
                exporterMenuAllData: false,
                exporterMenuSelectedData: false,
                exporterMenuPdf: false,
                paginationPageSizes: [25, 50, 100, 200],
                paginationPageSize: 25,
                useExternalPagination: false,
                columnDefs: columnDefs,
                gridMenuCustomItems: [
                    {
                        title: 'Export all data as csv',
                        action: function () {
                            exportAll(label, data);
                        },
                        order: 208
                    },
                    {
                        title: 'View all data as HTML',
                        action: function () {
                            exportAllAsHtml(label, data);
                        },
                        order: 209
                    },
                    {
                        title: 'Save all data as HTML',
                        action: function () {
                            exportAllAsHtml(label, data, true);
                        },
                        order: 210
                    }
                ],
                onRegisterApi: function (gridApi) {
                    gridApiInstances[label] = gridApi;
                }
            });
        }

        function exportAll(gridId, results) {
            if (!results.length) {
                return false;
            }
            var gridApi = gridApiInstances[gridId],
                gridOptions = controller.gridOptions,
                exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                    gridApi.grid,
                    uiGridExporterConstants.VISIBLE
                );

            var exportData = GridService.getCsvData(gridApi.grid.columns, results);
            var csvContent = uiGridExporterService.formatAsCsv(
                exportColumnHeaders,
                exportData,
                gridOptions.exporterCsvColumnSeparator
            );
            uiGridExporterService.downloadFile(
                'rankings-html-full-export' + TimeService.now() + '.csv',
                csvContent,
                gridOptions.exporterOlderExcelCompatibility
            );
        }

        function exportAllAsHtml(gridId, results, downloadFile) {
            var gridApiInstance = gridApiInstances[gridId],
                exportData = RankingsHtmlService.formatCsvData(gridApiInstance.grid.columns, results),
                exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                    gridApiInstance.grid,
                    uiGridExporterConstants.VISIBLE
                ),
                downloadButton = "";
            var tableTitle = controller.selectedItem.ranking.label + ' ' + controller.selectedItem.year.label;
            if (controller.showSubjects) {
                tableTitle += ' - ' + gridId;
            }
            var tableHeader = '';
            angular.forEach(exportColumnHeaders, function (column) {
                tableHeader += '<th>' + column.displayName + '</th>';
            });
            var tableBody = '';
            angular.forEach(exportData, function (columns) {
                tableBody += '<tr>';
                angular.forEach(columns, function (column) {
                    if (column.url) {
                        tableBody += '<td><a href="' + decodeURIComponent(column.url) + '" target="_blank">' + column.value + '</a></td>';
                    }
                    else if (column.value === null) {
                        tableBody += '<td></td>';
                    }
                    else {
                        tableBody += '<td>' + column.value + '</td>';
                    }
                });
                tableBody += '</tr>';
            });
            if(!downloadFile) {
                downloadButton = $templateCache.get('/scripts/modules/rankings/components/rankingsHtml/downloadButtonTemplate.html');
            }
            var html = $interpolate($templateCache.get('/scripts/modules/rankings/components/rankingsHtml/tableTemplateView.html'))({
                button : downloadButton,
                tableTitle : tableTitle,
                header : tableHeader,
                body : tableBody,
                currentYear : TimeService.getCurrentYear()
            });
            if (downloadFile) {
                var blob = new Blob([html], { type: 'text/html' }),
                anchor = document.createElement('a');
                anchor.download = "Rankings";
                anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);
                anchor.dataset.downloadurl = ['text/html', anchor.download, anchor.href].join(':');
                $timeout(function() {
                    document.body.appendChild(anchor);
                    anchor.click();
                    anchor.remove();
                });
            }
            else {
                var newWindow = $window.open('');
                newWindow.document.write(html);
                newWindow.document.close();
            }
        }

        function fetchIndicators() {
            if (!controller.selectedItem.ranking || !controller.selectedItem.year) {
                return false;
            }

            dataToLoad = 1;
            RankingsScoresService.getScoresInfo({
                'filter[rankingId]': '=' + controller.selectedItem.ranking.value,
                'filter[year]': '=' + controller.selectedItem.year.value,
                'filter[deleted]': false,
                'columns[]': 'indicatorId'
            }).then(function (result) {
                var ids = [];
                angular.forEach(result.results, function (item) {
                    ids.push(item.indicatorId);
                });
                rankingIndicators = [];
                angular.forEach(indicatorsList, function (item) {
                    if (ids.indexOf(item.coreId) !== -1) {
                        rankingIndicators.push({
                            value: item.acronym,
                            label: item.name
                        });
                    }
                });
                dataToLoad--;
            });
        }

        function fetchSubjects() {
            if (!controller.showSubjects || !controller.selectedItem.year || !controller.selectedItem.year.value) {
                return false;
            }
            dataToLoad = 1;
            RankingsScoresService.getSubjectsInfo({
                'filter[rankingId]': '=' + controller.selectedItem.ranking.value,
                'filter[year]': '=' + controller.selectedItem.year.value,
                'filter[deleted]': false,
                'columns[]': 'subjectId'
            }).then(function (result) {
                var ids = [];
                angular.forEach(result.results, function (item) {
                    ids.push(item.subjectId);
                });
                controller.subjectsList = [];
                angular.forEach(subjectsList, function (item) {
                    if (ids.indexOf(item.coreId) !== -1) {
                        controller.subjectsList.push({
                            value: item.acronym,
                            label: item.name
                        });
                    }
                });
                dataToLoad--;
            });
        }

        function initRankingsList() {
            RankingsScoresService.getScoresRankings().then(function (list) {
                angular.forEach(list.results, function (item) {
                    if ((item.belongsTo.indexOf('tu') !== -1 && [1, 2].indexOf(item.rankingTypeId) !== -1 &&
                        item.coreId !== 5) || [24, 25, 26].indexOf(item.coreId) !== -1 ) {
                        tuRankings.push({
                            value: item.coreId,
                            label: item.name
                        });
                    }
                });
                dataToLoad--;
            });
            controller.selectedWebsiteChanged();
        }

        function initYearsList() {
            var currentYear = TimeService.getCurrentYear() + 1,
                yearsBack = 2,
                year = angular.copy(currentYear);

            for (year; year > currentYear - yearsBack; year--) {
                controller.yearsList.push({
                    value: year,
                    label: year
                });
            }
            dataToLoad--;
        }

        function initRangeList() {
            var ranges = [5,10,15,20,25,30,35,40,45,50, 100, 150, 200];
            angular.forEach(ranges, function (range) {
                controller.rangeList.push({
                    value: range,
                    label: 'Top ' + range
                });
            });
            dataToLoad--;
        }

        function fetchIndicatorsList() {
            RankingsScoresService.getScoresIndicators().then(function (result) {
                indicatorsList = result.results;
                dataToLoad--;
            });
        }

        function fetchSubjectsList() {
            RankingsScoresService.getSubjects().then(function (result) {
                subjectsList = result.results;
                dataToLoad--;
            });
        }

        controller.handlePublish = function(rankingId, year, website) {
            RankingsScoresService.sendPublishInfoToQSRankingsApp({'limit' : 1, 'rankingId': rankingId, 'year': year, site: website}).then(function (result) {
                if(result.success) {
                    controller.generateTable();
                }
                NotifierFactory.show(
                    result.success ? 'success' : 'error',
                    result.message ? result.message : 'There is some error',
                    'QS Rankings App'
                );
            });
        };

        controller.isAdmin = function() {
            return UserFactory.isGlobalAdmin();
        };


        function init() {
            initRankingsList();
            initYearsList();
            initRangeList();
            fetchIndicatorsList();
            fetchSubjectsList();
        }

        controller.$onInit = init();
    }

}(window.angular));

(function (angular) {
    'use strict';

    angular.module('qsHub.rankings').service('RankingsHtmlService', [
        'RequestsQueueService',
        RankingsHtmlService
    ]);

    function RankingsHtmlService() {
        var svc = {};

        svc.formatCsvData = formatCsvData;

        function formatCsvData(columns, rows) {
            var data = [],
            cellValue,
            extractedRow,
            column,
            columnParts,
            node,
            key,
            total;

        rows.forEach(function (row) {
            extractedRow = [];
            var url = row.drupalUrl? row.drupalUrl.replace(/%20/g,"+") : null;
            row.drupalUrl = url;
            columns.forEach(function (gridCol) {
                if (gridCol.visible &&
                    gridCol.colDef.exporterSuppressExport !== true
                ) {
                    cellValue = '';

                    column = gridCol.colDef.field;
                    var extractedField = {};
                    if (column) {
                        if (row[column]) {
                            if (column === 'institutionName') {
                                extractedField.url = url? url : null;
                            }
                            cellValue = row[column];
                            
                        } else if (column.indexOf('.') !== -1) {
                            columnParts = column.split('.');
                            total = columnParts.length;
                            node = null;
                            for (var i = 0; i < total; i++) {
                                key = columnParts[i];
                                if (i === 0) {
                                    if (typeof row[key] === 'undefined') {
                                        break;
                                    }
                                    node = row[key];
                                } else if (i === total - 1) {
                                    if (typeof node[key] === 'undefined') {
                                        break;
                                    }
                                    if (key === 'institutionName') {
                                        extractedField.label = key;
                                    }
                                    cellValue = node[key];
                                } else {
                                    if (typeof node[key] === 'undefined') {
                                        break;
                                    }
                                    node = node[key];
                                }
                            }

                        }
                    }
                    extractedField.value = cellValue;
                    extractedRow.push(extractedField);
                }
            });

            data.push(extractedRow);
        });

        return data;
        }

        return svc;
    }

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.rankings')
        .controller('Rankings.BulkGerUploadController', [
            'constants',
            '$scope',
            'NotifierFactory',
            'AuthenticationService',
            '$timeout',
            Controller
        ]);

    function Controller(
        constants,
        $scope,
        NotifierFactory,
        AuthenticationService,
        $timeout
    ) {
        var vm = this,
            dataToLoad = 1;

        vm.dropZoneInstance = null;
        vm.forms = {};
        vm.showBulkUploadButton = false;
        vm.isLoading = false;
        vm.isFileErrors = false;
        vm.isColumnErrors = false;

        vm.handleUploadError = function (error) {
            if (error.status === 'canceled') {
                $scope.$apply(function() {
                    vm.isLoading = false;
                    vm.dropZoneInstance.removeAllFiles();
                    NotifierFactory.show(
                        'warning',
                        'Upload was canceled succesfully',
                        'Rankings GER Bulk Upload'
                    );
                });

                return;
            }
            var errorMessage = error && error.xhr !== undefined && error.xhr.responseText ? JSON.parse(error.xhr.responseText) : false;
            errorMessage = errorMessage ? errorMessage.message : 'File upload failed. Only CSV are accepted';
            $scope.$apply(function() {
                vm.isLoading = false;
                vm.dropZoneInstance.removeAllFiles();
                NotifierFactory.show(
                    'error',
                    'Something is wrong with the file',
                    'Rankings GER Bulk Upload'
                );

            });
        };

        vm.handleFileAdded = function (file) {
           $scope.$apply(function() {
                vm.showBulkUploadButton = true;
                if (vm.dropZoneInstance.files.length > 1) {
                    vm.dropZoneInstance.removeAllFiles();
                    vm.dropZoneInstance.addFile(file);
                }
            });
        };

        vm.handleCheckFile = function () {
            vm.isLoading = true;
            vm.showBulkUploadButton = false;
            vm.dropZoneInstance.processQueue();
        };

        vm.handleFileRemoved = function () {
            $timeout(function() {
                if (vm.dropZoneInstance.files.length === 0) {
                    vm.showBulkUploadButton = false;
                }
            });
        };

        vm.handleFileUploaded = function (file, response) {
            $scope.$apply(function() {
                vm.showBulkUploadButton = false;
                vm.isFileErrors = false;
                vm.isColumnErrors = false;
                if (!response) {
                    vm.handleUploadError();
                } else {
                    if (!angular.isDefined(response.matchedCount) ||
                        !angular.isDefined(response.modifiedCount) ||
                        !angular.isDefined(response.insertCount)
                    ) {
                        NotifierFactory.show(
                            'error',
                            'Something is wrong with the file, please check errors',
                            'Rankings GER Bulk Upload'
                        );
                        if (response.errorMessage) {
                            vm.isColumnErrors = true;
                        } else {
                            vm.isFileErrors = true;
                        }
                    } else {
                        NotifierFactory.show(
                            'success',
                            'Data was uploaded correctly',
                            'Rankings GER Bulk Upload'
                        );
                    }
                    vm.fileErrors = response;
                    vm.isLoading = false;
                    vm.dropZoneInstance.removeAllFiles();
                }
            });
        };

        vm.uploadConfig = {
            // http://www.dropzonejs.com/#configuration-options
            dropzone: {
                init: function () {
                    vm.dropZoneInstance = this;
                },
                url: constants.api.rankings.url + '/v1/ger/bulk-upload',
                maxFiles: 1,
               // maxFilesize: vm.maxSize,
                uploadMultiple: false,
                parallelUploads: 1,
                autoProcessQueue: false,
                addRemoveLinks: true,
                createImageThumbnails: false,
                acceptedFiles: '.csv',
                headers: AuthenticationService.getAuthorizationHeader(),
                paramName: "file"
            },
            // http://www.dropzonejs.com/#event-list
            eventHandlers: {
                success: vm.handleFileUploaded,
                addedfile: vm.handleFileAdded,
                removedfile: vm.handleFileRemoved,
                error: vm.handleUploadError
            }
        };

        vm.loading = function() {
            return dataToLoad > 0;
        };

        function init() {}

        vm.$onInit = init();
    }

}(window.angular));

(function (angular) {
    'use strict';

    angular.module('qsHub.rankings').service('RankingsBulkGerUpload', [
        'RequestsQueueService',
        RankingsBulkGerUpload
    ]);

    function RankingsBulkGerUpload() {
        
    }

}(window.angular));

(function(angular) {
    'use strict';

    var gridNamespaces = {
            filters: {
                scopusList: 'scopusListFilters',
            },
            visibility: {
                scopusList: 'scopusListVisibility',
            }
        },
        constants = {
            datagrid: {
                defaultRowsNumber: 25,
                datagridSelectDelay: 1000
            }
        };

    angular
        .module('qsHub.rankings')
        .constant('Scopus.gridNamespaces', gridNamespaces)
        .constant('Scopus.constants', constants);

}(window.angular));
(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    App.services.ManageIdsService = function (
        $resource,
        $log,
        $q,
        $state,
        constants,
        RequestsQueueService,
        TimeService
    ) {
        var service = {
            deferred : {}
        };

        function getModel() {
            return $resource(constants.api.stars.url, {}, {
                search: {
                    method: 'GET',
                    url: constants.api.institutions.url + '/v1/admin/list/scopus',
                    isArray: false,
                    cancellable: true
                },
                getUniqueCoreIdsAndNames: {
                    method: 'GET',
                    url: constants.api.institutions.url + '/v1/admin/scopus/export',
                    isArray: false,
                    cancellable: true
                },
                submitScopusIds: {
                    method: 'POST',
                    url: constants.api.institutions.url + '/v1/admin/scopus',
                    isArray: false,
                    cancellable: true
                },
                update: {
                    method: 'PATCH',
                    url: constants.api.institutions.url + '/v1/admin/scopus/:scopusId',
                    isArray: false,
                    cancellable: true
                },
                delete: {
                    method: 'POST',
                    url: constants.api.institutions.url + '/v1/admin/scopus/delete',
                    isArray: false,
                    cancellable: true
                }
            });
        }

        service.search = function (filters) {
            var key = 'ManageIdsService::search',
                Api;
            RequestsQueueService.cancelAll(key);
            Api = getModel().search(filters);
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                return data;
            }, function () {
                return false;
            });
        };

        service.getUniqueCoreIdsAndNames = function () {
            var key = 'ManageIdsService::getUniqueCoreIdsAndNames',
                Api;
            RequestsQueueService.cancelAll(key);
            Api = getModel().getUniqueCoreIdsAndNames();
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                return data;
            }, function () {
                return false;
            });
        };

        service.submitScopusIds = function (data) {
            var key = 'ManageIdsService::submitScopusIds',
                Api;

            RequestsQueueService.cancelAll(key);
            Api = getModel().submitScopusIds(data);
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (response) {
                return response;
            }, function (err) {
                return err.status && err.status === 406 ? err.data.message : false;
            });
        };

        service.update = function (scopusId, data) {
            data = angular.extend({}, data);
            return getModel().update({
                scopusId: scopusId
            }, data).$promise.then(function (response) {
                return response;
            }, function (err) {
                return err.status && err.status === 406 ? err.data : (err.status && err.status === 404 && err.data  && err.data.error === "Not Found" ? err.data : false);
            });
        };

        service.delete = function (ids) {
            if (!ids.length) {
                throw 'Missing Required';
            }
            var key = 'ManageIdsService:delete',
                Api;
            RequestsQueueService.cancelAll(key);
            Api = getModel().delete({}, ids);
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                return data;
            }, function () {
                return false;
            });
        };

        service.formatCsvResults = function (data) {
            data = angular.copy(data);
            angular.forEach(data, function (column, dataKey) {
                angular.forEach(column, function (item, key) {
                    if (key === 'filterType') {
                        data[dataKey][key] = item ? data[dataKey][key].charAt(0).toUpperCase() + data[dataKey][key].slice(1) : null;
                    }
                    else if (key === 'createdAt') {
                        data[dataKey][key] = TimeService.format(item, "MMM DD, YYYY HH:mm:ss");
                    }
                    else if (key === 'modifiedAt') {
                        data[dataKey][key] = TimeService.format(item, "MMM DD, YYYY HH:mm:ss");
                    }
                });
            });

            return data;
        };

        service.formatVisibleCsvResults = function (data) {
            var filteredRows = [];
             angular.forEach(data, function (row, Key) {
                 filteredRows.push(row.entity);
             });

             return  service.formatCsvResults(filteredRows);
        };

        service.isClientSection = function () {
            return $state.current.name !== 'staff.iu.nominations.lists';
        };

        return service;
    };

    angular
        .module('qsHub.rankings')
        .service('ManageIdsService', [
            '$resource',
            '$log',
            '$q',
            '$state',
            'constants',
            'RequestsQueueService',
            'TimeService',
            App.services.ManageIdsService
        ]);

}(window.angular));
(function(angular) {
    "use strict";

    angular
        .module('qsHub.rankings')
        .factory('ManageIdsFactory', [
            Factory
        ]);

    function Factory () {
        var reload = null,
            logsLimit = null,
            reloadGrid = null,
            selectedItem = null,
            addMode = false,
            subscribedTo = [];
        return {
            getIsReloadGrid: function () {
                return reloadGrid;
            },
            setReloadGrid:  function (value) {
                reloadGrid = value;
            },
            requestLogsReload:  function () {
                reload = true;
            },
            isLogsReloadRequest: function () {
                return !!reload;
            },
            resetLogsReloadRequest: function () {
                reload = null;
            },
            setLogsLimit: function (limit) {
                logsLimit = limit;
            },
            getLogsLimit: function () {
                return logsLimit;
            },
            setSubscribedTo: function (newKey) {
                subscribedTo.push(newKey);
            },
            getSubscribedTo: function () {
                return subscribedTo;
            },
            setSelectedItem: function (item) {
                selectedItem = item;
            },
            getSelectedItem: function () {
                return selectedItem;
            },
            setAddMode: function (value) {
                addMode = value;
            },
            isAddMode: function () {
                return addMode;
            }
        };
    }

}(window.angular));

(function (angular) {
    "use strict";
    angular
        .module('qsHub.rankings')
        .controller('rankings.ManageIdsController', [
            '$scope',
            'WatchService',
            'InstitutionSwitchService',
            'ManageIdsFactory',
            ManageIds
        ]);
    function ManageIds(
        $scope,
        WatchService,
        InstitutionSwitchService,
        ManageIdsFactory
    ) {
        var vm = this;

        var isAddMode = false,
            selectedItem = null;

        vm.institutionsDropdownList = [];

        vm.handleSearchInstitution = function (searchPhrase) {
            vm.searchInProgress = true;
            if (!searchPhrase || searchPhrase.indexOf('/') !== -1) {
                vm.institutionsDropdownList = [];
                vm.searchInProgress = false;
                return;
            }

            InstitutionSwitchService.searchInstitutions(searchPhrase).then(function (data) {
                vm.institutionsDropdownList = data;
                vm.searchInProgress = false;
            });
        };

        vm.isRightSidePanelActive = function () {
            return selectedItem !== null || isAddMode;
        };

        vm.handleAdd = function () {
            ManageIdsFactory.setAddMode(true);
        };

        function selectedItemWatch (item) {
            selectedItem = item;
        }

        function addModeWatch (value) {
            isAddMode = value;
        }

        function initWatches () {
            WatchService.create($scope, ManageIdsFactory.getSelectedItem, selectedItemWatch);
            WatchService.create($scope, ManageIdsFactory.isAddMode, addModeWatch);
        }
      
        function init() {
            initWatches();
            ManageIdsFactory.setSelectedItem(null);
            ManageIdsFactory.setAddMode(false);
        }
        init();
    }
}(window.angular));
(function (angular) {

    'use strict';
  
    angular.module('qsHub.rankings').component('scopusAddEdit', {
        templateUrl: '/scripts/modules/rankings/components/scopus/components/manageIds/scopusAddEdit/scopusAddEditView.html',
        bindToController: true,
        controller: 'rankings.scopusAddEditController',
        bindings: {
            selectedInstitution: '=',
        }
    });
  
  }(window.angular));
  
(function (angular) {
    "use strict";
    angular
        .module('qsHub.rankings')
        .controller('rankings.scopusAddEditController', [
            '$scope',
            'WatchService',
            'ManageIdsFactory',
            'ManageIdsService',
            'NotifierFactory',
            'CampusesService',
            'ModalService',
            ScopusAddEdit
        ]);
    function ScopusAddEdit(
        $scope,
        WatchService,
        ManageIdsFactory,
        ManageIdsService,
        NotifierFactory,
        CampusesService,
        ModalService
    ) {
        var vm = this,
            emptyScopus = {
                scopusId : null,
                scopusName : null,
                isAlreadyExist : false
            },
            OldScopus = {},
            scopusIdsFetchInProgress = false;

        vm.selectedItem = null;
        vm.submitInProgress = false;
        vm.scopusData = {
            scopus : []
        };

        vm.addNewItem = function () {
            vm.scopusData.scopus.push(angular.copy(emptyScopus));
        };

        vm.removeItem = function (index) {
            vm.scopusData.scopus.splice(index, 1);
        };

        vm.handleSave = function (ignoreExisting) {
            if (vm.scopusForm && vm.scopusForm.scopusId) {
                vm.scopusForm.scopusId.$setDirty();
            }
            if (isScopusIdExistForSameInstitution() || !isValidForm()) {
                NotifierFactory.show(
                    'error',
                    'Invalid data in the form',
                    'Scopus submission'
                );

                return;
            }
            vm.submitInProgress = true;
            vm.scopusData.ignoreExisting = ignoreExisting;
            ManageIdsService.submitScopusIds(vm.scopusData).then(function (response) {
                if (response && response.insertedCount) {
                    NotifierFactory.show(
                        'success',
                        'Data was submitted successfully',
                        'Scopus submission'
                    );
                    ManageIdsFactory.setReloadGrid(true);
                    vm.closeRightSidePanel();
                } else {
                    if (response.alreadyExist && response.alreadyExist[0].scopusId !== 0) {
                        handleAlreadyExist(response.alreadyExist, vm.handleSave);
                    } else {
                        NotifierFactory.show(
                            'error',
                            'Error occurred, please try again later',
                            'Scopus submission'
                        );
                    }
                }
                vm.submitInProgress = false;
            });
        };

        vm.handleUpdate = function (ignoreExisting) {
            if (!isValidForm()) {
                NotifierFactory.show(
                    'error',
                    'Invalid data in the form',
                    'Scopus submission'
                );

                return;
            }

            var updatedData = {
                scopusName: vm.scopusData.scopus[0].scopusName
            };
            if (vm.scopusForm.scopusId.$dirty) {
                updatedData.scopusId = vm.scopusData.scopus[0].scopusId;
                if (updatedData.scopusId !== OldScopus.scopusId) {
                    if (isScopusIdExistForSameInstitution()) {
                        NotifierFactory.show(
                            'error',
                            'Invalid data in the form',
                            'Scopus submission'
                        );
                        return;
                    }
                }
            }
            vm.submitInProgress = true;

            updatedData.ignoreExisting = ignoreExisting;
            ManageIdsService.update(vm.scopusData.scopus[0].id, updatedData).then(function (response) {
                if (response && response.modifiedCount) {
                    NotifierFactory.show(
                        'success',
                        'Data was updated successfully',
                        'Scopus submission'
                    );
                    ManageIdsFactory.setReloadGrid(true);
                    vm.closeRightSidePanel();
                } else {
                    var errMessage = 'Error occurred, please try again later';
                    if (response.error === "Not Found") {
                        errMessage = response.message;
                    }
                    if (response.alreadyExist) {
                        handleAlreadyExist(response.alreadyExist, vm.handleUpdate);
                    } else {
                        NotifierFactory.show(
                            'error',
                            'Error occurred, please try again later',
                            'Scopus submission'
                        );
                    }
                    // NotifierFactory.show(
                    //     'error',
                    //     errMessage,
                    //     'Scopus submission'
                    // );
                    // if (response.error === "Not Acceptable") {
                    //     checkExistingScopusIds(response.message.split(','));
                    // }
                }
                vm.submitInProgress = false;
            });
        };

        vm.closeRightSidePanel = function () {
            ManageIdsFactory.setAddMode(false);
            ManageIdsFactory.setSelectedItem(null);
        };

        vm.isValidScopusId = function (value) {
            return value !== null ? Number.isInteger(value) : (vm.scopusForm && (vm.scopusForm.$pristine || (vm.scopusForm.scopusId && vm.scopusForm.scopusId.$pristine)));
        };

        vm.isValidScopusName =  function (value) {
            return !CampusesService.isValidAsciiCharacters(value) ? false : true;
        };

        vm.buttonDisabled = function () {
            return vm.submitInProgress || (vm.scopusForm && !vm.scopusForm.$dirty);
        };

        vm.showLoadBar = function () {
            return scopusIdsFetchInProgress;
        };

        function handleAlreadyExist(data, callbackFnc) {
            var modalOptions = {
                    closeButtonText: 'Decline',
                    actionButtonText: 'Confirm',
                    actionButtonClass: 'btn-primary',
                    headerText: "Scopus ID's",
                    close: function () {
                        this.close();
                    },
                    confirm: function () {
                        this.close();
                        callbackFnc(true);
                    },
                    isLoading: function () {
                        return vm.isChecking;
                    },
                    getAlreadyAssignedScopusIds: function () {
                        vm.isChecking = true;
                        checkExistingScopusIds(data);
                        return vm.scopusData.scopus;
                    }
                },
                modalDefaults = {
                    backdrop: true,
                    keyboard: true,
                    modalFade: true,
                    templateUrl: '/scripts/modules/rankings/components/scopus/components/manageIds/scopusAddEdit/duplicateScopusDialogView.html'
                };
            ModalService.show(modalDefaults, modalOptions);
        }

        function checkExistingScopusIds (alreadyMappedScopusIds) {
            for (var i = 0; i < vm.scopusData.scopus.length; i++) {
                vm.scopusData.scopus[i].isAlreadyExist = false;
                if (vm.scopusData.scopus[i] && vm.scopusData.scopus[i].scopusId) {
                    vm.scopusData.scopus[i].coreIds = [];
                    for (var k =0; k < alreadyMappedScopusIds.length; k++) {
                        if(vm.scopusData.scopus[i].scopusId.toString() === alreadyMappedScopusIds[k].scopusId.toString()) {
                            vm.scopusData.scopus[i].isAlreadyExistInOther = true;
                            vm.scopusData.scopus[i].coreIds.push(alreadyMappedScopusIds[k].coreId.toString());
                        }
                    }
                    vm.scopusData.scopus[i].coreIds = vm.scopusData.scopus[i].coreIds.toString();
                }
            }
            vm.isChecking = false;
        }

        function isScopusIdExistForSameInstitution () {
            var isExist = false;
            for (var i = 0; i < vm.scopusData.scopus.length; i++) {
                vm.scopusData.scopus[i].isAlreadyExist = false;
                if (vm.scopusData.scopus[i] && vm.scopusData.scopus[i].scopusId &&  vm.selectedInstitutionScopusIds &&  vm.selectedInstitutionScopusIds.length) {
                    for (var k =0; k <  vm.selectedInstitutionScopusIds.length; k++) {
                        if(vm.scopusData.scopus[i].scopusId.toString() === vm.selectedInstitutionScopusIds[k].scopusId.toString()) {
                            vm.scopusData.scopus[i].isAlreadyExist = true;
                            isExist = true;
                        }
                    }
                }
            }

            return isExist;
        }

        function isValidForm () {
            var isValid = true,
            scopusForm = angular.element("#scopusForm")[0];

            for (var i = 0; i < scopusForm.length; i++) {
                if (scopusForm[i].tagName === "INPUT") {
                    if (scopusForm[i].parentElement.classList.contains("has-errors")) {
                        isValid = false;
                        break;
                    }
                }
            }

            return isValid;
        }

        function selectedScopusWatch (newValue) {
            var newScopus = {};
            newScopus.scopusId = newValue.scopusId ? newValue.scopusId : null;
            newScopus.scopusName = newValue.scopusName ? newValue.scopusName : null;
            if (angular.equals(newScopus, OldScopus)) {
                vm.scopusForm.$setPristine();
            }
            else {
                vm.scopusForm.$setDirty();
            }
        }

        function selectedItemWatch (item) {
            if (item) {
                vm.selectedItem = item;
                vm.scopusData.coreId = item.coreId;
                vm.scopusData.institutionName = item.institutionName;
                vm.scopusData.scopus = [];
                vm.scopusData.scopus.push(item);
                OldScopus.scopusId = item.scopusId ? item.scopusId : null;
                OldScopus.scopusName = item.scopusName ? item.scopusName : null;
                WatchService.create($scope, '$ctrl.scopusData.scopus[0]', selectedScopusWatch, true);
            }
            else {
                vm.scopusData.coreId = vm.selectedInstitution.coreId;
                vm.scopusData.institutionName = vm.selectedInstitution.name;
                vm.scopusData.scopus = [];
                vm.scopusData.scopus.push(angular.copy(emptyScopus));
            }
            var params = {
                'filter[coreId]' : '=' + vm.scopusData.coreId,
                'sorting[modifiedAt]' : 'desc',
                'columns[]' : 'scopusId'
            };
            scopusIdsFetchInProgress = true;
            vm.selectedInstitutionScopusIds = [];
            ManageIdsService.search(params).then(function (data) {
                vm.selectedInstitutionScopusIds = data.results;
                scopusIdsFetchInProgress = false;
            });
        }

        function initWatches () {
            WatchService.create($scope, ManageIdsFactory.getSelectedItem, selectedItemWatch);
        }
      
        function init() {
            initWatches();
        }
        init();
    }
}(window.angular));
(function (angular) {

    'use strict';
  
    angular.module('qsHub.rankings').component('scopusList', {
        templateUrl: '/scripts/modules/rankings/components/scopus/components/manageIds/scopusList/scopusListView.html',
        bindToController: true,
        controller: 'rankings.ScopusListController',
        bindings: {
            selectedInstitution: '=',
        }
    });
  
  }(window.angular));
  
(function (angular) {
    "use strict";
    angular
        .module('qsHub.rankings')
        .controller('rankings.ScopusListController', [
            '$scope',
            '$timeout',
            'WatchService',
            'TimeService',
            'uiGridConstants',
            'UiGridService',
            'uiGridExporterService',
            'uiGridExporterConstants',
            'Scopus.constants',
            'Scopus.gridNamespaces',
            'ManageIdsService',
            'StarsInstitutionsListService',
            'ManageIdsFactory',
            'ModalService',
            'NotifierFactory',
            'DateRangeFixerService',
            ScopusList
        ]);
    function ScopusList(
        $scope,
        $timeout,
        WatchService,
        TimeService,
        uiGridConstants,
        GridService,
        uiGridExporterService,
        uiGridExporterConstants,
        scopusConstants,
        gridNamespaces,
        ManageIdsService,
        StarsInstitutionsListService,
        ManageIdsFactory,
        ModalService,
        NotifierFactory,
        DateRangeFixerService
    ) {
        var vm = this,
            lastModifiedAtDateFilter = null,
            gridApiInstance = null,
            filtersTimeout,
            isRendered = false,
            deleteInProgress = false,
            filterChangesInProgress = false,
            firstLoad = true,
            isColumnsHidden = false,
            columnsVisibility = GridService.getColumnsVisibility(
                gridNamespaces.visibility.scopusList
            ),
            columnsBeforeHide = [],
            paginationOptions = GridService.getColumnsFilters(
                gridNamespaces.filters.scopusList
            ),
            defaultPaginationOptions = {
                page: 1,
                limit: scopusConstants.datagrid.defaultRowsNumber,
                'sorting[createdAt]': 'desc'
            };
        vm.noResults = false;
        vm.itemsToDelete = {};
        vm.isRowsRendered = false;
        vm.fetchInProgress = false;
        vm.showInfoBlock = true;
        vm.selectedRowId = null;
        vm.defaultFilters = {
            modifiedAt: {
                startDate: null,
                endDate: null
            }
        };
        vm.filters = null;

        function handleFetch () {
            vm.fetchInProgress = true;
            vm.noResults = false;
            if (vm.gridOptions) {
                vm.gridOptions.data = null;
            }
            ManageIdsService.search(
                angular.merge({}, paginationOptions, defaultPaginationOptions)
                ).then(function (data) {
                    $timeout(function () {
                        vm.fetchInProgress = false;
                    },250);
                    if (!data.results || (data.results && data.results.length < 1)) {
                        vm.noResults = true;
                    }
                    vm.gridOptions.totalItems = data.totalMatching;
                    vm.gridOptions.data = data.results;
                    vm.gridOptions.minRowsToShow = data.totalFiltered;
            });
        }

        function reloadGridRequestWatch (reload) {
            if (reload) {
                handleFetch();
                ManageIdsFactory.setReloadGrid(false);
            }
        }

        function getIdsForDeletion() {
            var list = [];
            angular.forEach(vm.itemsToDelete, function (remove, id) {
                if (remove) {
                    list.push(id);
                }
            });
            return list;
        }

        vm.isDeleteButtonDisabled = function () {
            return deleteInProgress || !getIdsForDeletion().length;
        };

        vm.handleDeleteClick = function () {
            if (vm.isDeleteButtonDisabled()) {
                return false;
            }
            var modalOptions = {
                handleNoClick: function () {
                    this.close();
                },
                handleYesClick: function () {
                    deleteInProgress = true;
                    ManageIdsService.delete(getIdsForDeletion()).then(function (success) {
                        deleteInProgress = false;
                        if (success) {
                            deleteFromDatagrid();
                            vm.itemsToDelete = {};
                        }
                        NotifierFactory.show(
                            success ? 'success' : 'error',
                            success ? 'Successfully!' : 'Failed!',
                            'Scopus Deleted'
                        );
                    });
                    this.close();
                },
                headerText: 'Scopus Deletion',
                bodyText: 'Are you sure you want to delete the scopus from the scopus List?'
            }, modalDefaults = {
                backdrop: true,
                keyboard: true,
                modalFade: true,
                templateUrl: '/scripts/shared/areYouSure/deleteAreYouSure.html'
            };
            ModalService.show(modalDefaults, modalOptions);
        };

        function deleteFromDatagrid() {
            var i = 0,
                rows = vm.gridOptions.data,
                total = rows.length,
                toDelete = getIdsForDeletion();
            for (i; i < total; i++) {
                if (rows[i] && rows[i].id && toDelete.indexOf(rows[i].id) !== -1) {
                    if (rows[i].id === vm.selectedRowId) {
                        vm.selectedRowId = null;
                    }
                    delete vm.gridOptions.data[i];
                }
            }
        }

        function initDatagrid () {
            var deleteCellTemplate = '/scripts/shared/ui-grid/templates/deleteCellTemplate.html',
                deleteHeaderCellTemplate = '/scripts/shared/ui-grid/templates/deleteHeaderCellTemplate.html',
                dateCellTemplate = '/scripts/shared/ui-grid/templates/dateCellTemplate.html',
                rowTemplate = '/scripts/shared/ui-grid/templates/rowTemplate.html';

            vm.gridOptions = {
                appScopeProvider: vm,
                enableSorting: false,
                useExternalFiltering: true,
                enableGridMenu: true,
                showGridFooter: true,
                enableFiltering: true,
                enableColumnResize: true,
                enableFullRowSelection: true,
                enableRowSelection: true,
                multiSelect: false,
                enableRowHeaderSelection: false,
                rowTemplate: rowTemplate,
                exporterMenuCsv: false,
                exporterMenuAllData: false,
                exporterMenuSelectedData: false,
                exporterMenuPdf: false,
                paginationPageSizes: [25, 50, 100, 200],
                paginationPageSize: 25,
                useExternalPagination: true,
                gridMenuCustomItems: [
                    {
                        title: ' Export visible data as csv',
                        action: exportVisible,
                        order: 209
                    },
                    {
                        title: ' Export all data as csv',
                        action: exportAll,
                        order: 210
                    },
                    {
                        title: 'Export unique Core IDs and university names',
                        action: exportUniqueCoreIdsAndNames,
                        order: 211
                    }
                ],
                columnDefs: [
                    {
                        displayName: 'Delete',
                        field: 'deleted',
                        visible:true,
                        enableSorting: false,
                        enableColumnResizing: false,
                        headerCellClass: 'text-center v-align',
                        headerCellTemplate: deleteHeaderCellTemplate,
                        cellTemplate: deleteCellTemplate,
                        maxWidth: 80,
                        minWidth: 80
                    },
                    {
                        displayName: 'Scopus ID',
                        field: 'scopusId',
                        visible: GridService.getVisibilityByField(columnsVisibility, 'scopusId', true),
                        filter: {
                            term: GridService.getFilterByField(paginationOptions, 'scopusId', '', false),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        displayName: 'Core ID',
                        field: 'coreId',
                        visible: GridService.getVisibilityByField(columnsVisibility, 'coreId', true),
                        filter: {
                            term: GridService.getFilterByField(paginationOptions, 'coreId', '', false),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        displayName: 'Institution',
                        field: 'institutionName',
                        visible: GridService.getVisibilityByField(columnsVisibility, 'institutionName', true),
                        filter: {
                            term: GridService.getFilterByField(paginationOptions, 'institutionName', '', false),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        displayName: 'Scopus Name',
                        field: 'scopusName',
                        visible: GridService.getVisibilityByField(columnsVisibility, 'scopusName', true),
                        filter: {
                            term: GridService.getFilterByField(paginationOptions, 'scopusName', '', false),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        displayName: 'Updated At',
                        field: 'modifiedAt',
                        filterCellFiltered: true,
                        filters: [
                            {
                                visible: true,
                                condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL
                            },
                            {
                                condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL
                            }
                        ],
                        filter: {
                            term: GridService.getFilterByField(paginationOptions, 'modifiedAt', [], true),
                            applyTextFilter: applyDateFilter('modifiedAt', '')
                        },
                        cellTemplate: dateCellTemplate,
                        filterHeaderTemplate: '/scripts/components/profiles/tu/departments/datagrid/modifiedAtFilterHeaderTemplate.html',
                        visible: GridService.getVisibilityByField(columnsVisibility, 'modifiedAt', true),
                    },
                    {
                        displayName: 'Updated By',
                        field: 'modifiedByFullName',
                        visible: GridService.getVisibilityByField(columnsVisibility, 'modifiedByFullName', true),
                        filter: {
                            term: GridService.getFilterByField(paginationOptions, 'modifiedByFullName', '', false),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    }
                ],
                onRegisterApi: function (gridApi) {
                    gridApiInstance = gridApi;
                    gridApi.core.on.rowsRendered($scope, function () {
                        isRendered = true;
                    });

                    gridApi.selection.on.rowSelectionChanged($scope, function (row) {
                         handleDatagridRowClick(row.entity);
                    });

                    gridApi.pagination.on.paginationChanged($scope, function (newPage, limit) {
                        defaultPaginationOptions.page = newPage;
                        defaultPaginationOptions.limit = limit;
                        if(!filterChangesInProgress) {
                            handleFetch();
                        }
                    });

                    gridApi.core.on.filterChanged($scope, handleGridFiltersChanges);
                    gridApi.core.on.rowsVisibleChanged($scope, handleGridVisibleChanges);
                }
            };
            datePickersFixes();
            vm.filters = angular.extend({}, vm.defaultFilters);
            storeColumnsVisibility();
        }

        function datePickersFixes() {
            // This is needed to add 'x' icon in date field when prepopulating dates
            var modifiedAtField = GridService.getFilterByField(paginationOptions, 'modifiedAt', '');
            if (modifiedAtField !== null && paginationOptions["filter[modifiedAt]"] !== null) {
                if (angular.isDefined(modifiedAtField) &&
                    angular.isDefined(paginationOptions["filter[modifiedAt]"]) &&
                    angular.isDefined(paginationOptions["filter[modifiedAt]"].startDate) &&
                    angular.isDefined(paginationOptions["filter[modifiedAt]"].endDate)
                ) {
                    vm.gridOptions.columnDefs[getColumnNumber('modifiedAt')].filters[0].term = paginationOptions["filter[modifiedAt]"].startDate;
                    vm.gridOptions.columnDefs[getColumnNumber('modifiedAt')].filters[1].term = paginationOptions["filter[modifiedAt]"].endDate;
                }
            }
        }

         /**
         * Populates input text field for date field
         *
         * @param {string} filterName
         * @param {mixed} defaultValue
         */
          function applyDateFilter (filterName, defaultValue) {
            var filter = GridService.getFilterByField(paginationOptions, filterName, defaultValue);
            if (angular.isDefined(filter) &&
                filter !== null
            ) {
                if (angular.isDefined(filter.startDate) &&
                    angular.isDefined(filter.endDate)
                ) {
                    if (filter.startDate !== null &&
                        filter.startDate !== null
                    ) {
                        vm.defaultFilters[filterName] = {
                            startDate: parseInt(filter.startDate, 10),
                            endDate: parseInt(filter.endDate, 10)
                        };
                    } else {
                        vm.defaultFilters[filterName] = {
                            startDate: null,
                            endDate: null
                        };
                    }
                }
            }
        }

         /**
          * getColumnNumber
          * @param  {string} columnName
          */
        function getColumnNumber(columnName) {
             var key = 0,
                 total = vm.gridOptions.columnDefs.length;
             for (key; key < total; key++) {
                 if (vm.gridOptions.columnDefs[key] &&
                     vm.gridOptions.columnDefs[key].field === columnName
                 ) {
                     return key;
                 }
             }
             return null;
        }

        function storeColumnsVisibility() {
            if (!vm.gridOptions) {
                return false;
            }
            angular.forEach(vm.gridOptions.columnDefs, function (column) {
                if (angular.isDefined(column.visible) && column.visible === true) {
                    columnsBeforeHide.push(column);
                }
            });
        }

        function toggleColumns (hide) {
            if (!vm.gridOptions) {
                return;
            }
            if (hide && !isColumnsHidden) {
                isColumnsHidden = true;
                columnsBeforeHide = angular.copy(vm.gridOptions.columnDefs);
                // hide all columns except institutions
                angular.forEach(vm.gridOptions.columnDefs, function (column) {
                    column.visible = column.field === 'coreId' || column.field === 'scopusId';
                });
            } else {
                if (ManageIdsFactory.isAddMode() || vm.selectedRowId) {
                    return;
                }
                isColumnsHidden = false;
                var columns = [];
                // show columns visible before hide
                angular.forEach(columnsBeforeHide, function (column) {
                    if (column.visible) {
                        columns.push(column.field);
                    }
                });
                angular.forEach(vm.gridOptions.columnDefs, function (column) {
                    if (columns.indexOf(column.field) !== -1) {
                        column.visible = true;
                    }
                });
            }

            vm.gridOptions.enableGridMenu = !hide;
            vm.gridOptions.enableColumnMenus = !hide;
            vm.gridOptions.enableColumnResizing = !hide;
            vm.gridOptions.enablePaginationControls = !hide;
            vm.showInfoBlock = !hide;
        }

        function selectedRowWatch (selectedRow, oldSelectedRow) {
            if ((selectedRow && oldSelectedRow) || (selectedRow === null && oldSelectedRow  === null)) {
                return;
            }
            vm.selectedRowId = selectedRow ? selectedRow.id : null;
            toggleColumns(selectedRow !== null);
        }

        function handleDatagridRowClick(row) {
            vm.selectedRowId = (vm.selectedRowId === row.id) ? null : row.id;
            vm.selectedItem = vm.selectedRowId ? angular.copy(row) : null;
            ManageIdsFactory.setSelectedItem(vm.selectedItem);
            ManageIdsFactory.setAddMode(false);
        }

        function exportVisible() {
            var results = gridApiInstance.grid.renderContainers.body.renderedRows;
            results = ManageIdsService.formatVisibleCsvResults(results);
            if (typeof results === 'undefined') {
                return false;
            }
            var gridApi = gridApiInstance,
                gridOptions = vm.gridOptions,
                exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                    gridApi.grid,
                    uiGridExporterConstants.VISIBLE
                );
            exportColumnHeaders = StarsInstitutionsListService.removeDeleteColumnHeader(exportColumnHeaders);
            var columns = StarsInstitutionsListService.removeDeleteColumn(gridApi.grid.columns);
            var exportData = GridService.getCsvData(columns, results);
            var csvContent = uiGridExporterService.formatAsCsv(
                exportColumnHeaders,
                exportData,
                gridOptions.exporterCsvColumnSeparator
            );
            uiGridExporterService.downloadFile(
                'scopus-list' + TimeService.now() + '.csv',
                csvContent,
                gridOptions.exporterOlderExcelCompatibility
            );
        }

        function exportAll() {
            ManageIdsService.search(
                angular.merge({}, paginationOptions, {
                    page: 1,
                    limit: 250000,
                    export: true,
                    'sorting[createdAt]': 'desc'
                })
            ).then(function (response) {
                if (typeof response.results === 'undefined') {
                    return false;
                }
                response = ManageIdsService.formatCsvResults(response.results);
                var gridApi = gridApiInstance,
                    gridOptions = vm.gridOptions,
                    exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                        gridApi.grid,
                        uiGridExporterConstants.VISIBLE
                    );
                exportColumnHeaders = StarsInstitutionsListService.removeDeleteColumnHeader(exportColumnHeaders);
                var columns = StarsInstitutionsListService.removeDeleteColumn(gridApi.grid.columns);
                var exportData = GridService.getCsvData(columns, response);
                var csvContent = uiGridExporterService.formatAsCsv(
                    exportColumnHeaders,
                    exportData,
                    gridOptions.exporterCsvColumnSeparator
                );
                uiGridExporterService.downloadFile(
                    'scopus-list-all' + TimeService.now() + '.csv',
                    csvContent,
                    gridOptions.exporterOlderExcelCompatibility
                );
            });
        }

        function exportUniqueCoreIdsAndNames () {
            ManageIdsService.getUniqueCoreIdsAndNames().then(function (response) {
                if (typeof response.results === 'undefined') {
                    return false;
                }
                var columns =[
                    {
                        name : "_id",
                        displayName : "Core ID",
                        visible : true,
                        colDef : {
                            field : "_id"
                        }
                    },
                    {
                        name : "institutionName",
                        displayName : "Institution Name",
                        visible : true,
                        colDef : {
                            field : "institutionName"
                        }
                    },
                ];
                var gridOptions = vm.gridOptions;
                var exportData = GridService.getCsvData(columns, response.results);
                var csvContent = uiGridExporterService.formatAsCsv(
                    columns,
                    exportData,
                    gridOptions.exporterCsvColumnSeparator
                );
                uiGridExporterService.downloadFile(
                    'unique-core-ids-and-university-names' + TimeService.now() + '.csv',
                    csvContent,
                    gridOptions.exporterOlderExcelCompatibility
                );
            });
        }

        function handleGridVisibleChanges () {
            if (!vm.selectedRowId) {
                if (gridApiInstance.grid.selection.selectedCount === 1) {
                    gridApiInstance.selection.clearSelectedRows();
                }
            }
           
            var columnsVisibility = GridService.getColumnsVisibility(gridNamespaces.visibility.scopusList),
            reload = false;
           
            if (columnsVisibility && !vm.selectedRowId) {
                angular.forEach(vm.gridOptions.columnDefs, function (column, key) {
                    if (columnsVisibility[column.field] === true &&
                        column.visible === false
                    ) {
                        if (typeof  vm.gridOptions.columnDefs[key].filter === 'undefined') {
                            vm.gridOptions.columnDefs[key].filter = {};
                        }
                        // decide whether reload or no.
                        if ((angular.isDefined(vm.gridOptions.columnDefs[key].filter) &&
                            vm.gridOptions.columnDefs[key].filter.hasOwnProperty('term') &&
                            typeof vm.gridOptions.columnDefs[key].filter.term !== 'undefined' &&
                            vm.gridOptions.columnDefs[key].filter.term !== null &&
                            vm.gridOptions.columnDefs[key].filter.term.toString().length > 0) ||
                            ((angular.isDefined(vm.gridOptions.columnDefs[key].filters) &&
                            vm.gridOptions.columnDefs[key].filters[0].hasOwnProperty('term') &&
                                vm.gridOptions.columnDefs[key].filters[0].term &&
                                vm.gridOptions.columnDefs[key].filters[0].term.length > 0
                            ) || (
                                angular.isDefined(vm.gridOptions.columnDefs[key].filters) &&
                                vm.gridOptions.columnDefs[key].filters[1].hasOwnProperty('term') &&
                                vm.gridOptions.columnDefs[key].filters[1].term &&
                                vm.gridOptions.columnDefs[key].filters[1].term.length > 0
                            ))) {
                                paginationOptions['filter[' + column.field + ']'] = null;
                                reload = true;
                        }
                        vm.gridOptions.columnDefs[key].filter.term = null;
                        if (column.field === 'modifiedAt') {
                            delete    vm.gridOptions.columnDefs[key].filters[0].term;
                            vm.filters.modifiedAt = {
                                startDate: null,
                                endDate: null
                            };
                        }
                    }
                });
            }
            if (vm.gridOptions) {
                vm.columnsVisibility = GridService.getGridColumnsVisibility(vm.gridOptions.columnDefs);
                if(!vm.selectedRowId && !ManageIdsFactory.isAddMode()) {
                    GridService.storeColumnsVisibility(
                        gridNamespaces.visibility.scopusList,
                        vm.columnsVisibility
                    );
                }
            }
            if (reload) {
                handleFetch();
            }
        }

        function handleGridFiltersChanges() {
            var filterColumn = null,
            deleteFilter = false;
            filterChangesInProgress = true;
            if(gridApiInstance.pagination.getPage() > 1){
                gridApiInstance.pagination.seek(1);
            }
            angular.forEach(gridApiInstance.grid.columns, function (column) {
                filterColumn = column.filters[0].column ? column.filters[0].column : column.field;
                    deleteFilter = false;
                    if (column.filters[0].type === uiGridConstants.filter.SELECT &&
                        typeof column.filters[0].term === 'object' &&
                        column.filters[0].term !== null &&
                        typeof column.filters[0].term.value !== 'undefined'
                    )   {
                        column.filters[0].term = column.filters[0].term.value;
                    }
                    if (typeof column.filters !== 'undefined' &&
                        column.filters[0].term !== null &&
                        typeof column.filters[0].term !== 'undefined'
                    ) {
                        GridService.applyFilters(column);
                        if (column.filters[0].term === '') {
                            deleteFilter = true;
                        } else {
                            paginationOptions['filter[' + filterColumn + ']'] = column.filters[0].valuePrefix && !paginationOptions['filter[' + filterColumn + ']']? column.filters[0].valuePrefix + column.filters[0].term : column.filters[0].term;
                        }
                    } else {
                        deleteFilter = true;
                    }
                    if (deleteFilter && typeof paginationOptions['filter[' + filterColumn + ']'] !== 'undefined') {
                        delete paginationOptions['filter[' + filterColumn + ']'];
                    }
            });
            angular.forEach(gridApiInstance.grid.columns, function (column) {
                filterColumn = column.filters[0].column ? column.filters[0].column : column.field;
                if (
                    typeof column.filters !== 'undefined' &&
                    column.filters[0].term !== 'undefined' &&
                    column.filters[0].term !== ''
                ) {
                    if (filterColumn === 'modifiedAt') {
                        if (typeof column.filters !== 'undefined' &&
                            typeof column.filters[0].term !== 'undefined' &&
                            typeof column.filters[1].term !== 'undefined') {
                            paginationOptions['filter[' + filterColumn + ']'] = {
                                startDate: column.filters[0].term && isNaN(column.filters[0].term) && typeof column.filters[0].term === 'object' ?
                                    column.filters[0].term.format('x') : column.filters[0].term,
                                endDate: column.filters[1].term && isNaN(column.filters[1].term) && typeof column.filters[1].term === 'object' ?
                                    column.filters[1].term.format('x') : column.filters[1].term
                            };
                        } else {
                           vm.gridOptions.columnDefs[getColumnNumber('modifiedAt')].filters[0].term = null;
                           vm.gridOptions.columnDefs[getColumnNumber('modifiedAt')].filters[1].term = null;
                        }
                    } 
                }
            });
            if (paginationOptions["filter[modifiedAt]"] === null || paginationOptions["filter[modifiedAt]"] === undefined) {
                paginationOptions["filter[modifiedAt]"] = vm.defaultFilters.modifiedAt;
            }
            if (paginationOptions["filter[modifiedAt]"].startDate === null || !angular.isDefined(paginationOptions["filter[modifiedAt]"].startDate)) {
                paginationOptions["filter[modifiedAt]"].endDate = null;
                vm.filters.modifiedAt = {
                    startDate: null,
                    endDate: null
                };
            }
            GridService.storeColumnsFilters(
                gridNamespaces.filters.scopusList,
                paginationOptions
            );
            if (angular.isDefined(filtersTimeout)) {
                $timeout.cancel(filtersTimeout);
            }
            filtersTimeout = $timeout(function () {
                handleFetch();
                filterChangesInProgress = false;
            }, scopusConstants.datagrid.datagridSelectDelay);
        }

        vm.handleModifiedAtDateRange = function (event) {
            if (DateRangeFixerService.sameDayAndNoModel(event, vm.filters.modifiedAt, lastModifiedAtDateFilter)) {
                event.model.startDate = lastModifiedAtDateFilter.startDate;
                event.model.endDate = lastModifiedAtDateFilter.endDate;
                vm.filters.modifiedAt = {
                    startDate: lastModifiedAtDateFilter.startDate,
                    endDate: lastModifiedAtDateFilter.endDate
                };
            } else if (DateRangeFixerService.todayRangeAndNoModel(event, lastModifiedAtDateFilter)) {
                event.model.startDate = TimeService.getStartOfToday();
                event.model.endDate = TimeService.getEndOfToday();
                vm.filters.modifiedAt = {
                    startDate: event.model.startDate.toISOString(),
                    endDate: event.model.endDate.toISOString()
                };
            }

            if (event === null || typeof event === 'undefined') {
                vm.gridOptions.columnDefs[getColumnNumber('modifiedAt')].filters[0].term = null;
                vm.gridOptions.columnDefs[getColumnNumber('modifiedAt')].filters[1].term = null;
            } else if (event.model.startDate && event.model.endDate) {
                vm.gridOptions.columnDefs[getColumnNumber('modifiedAt')].filters[0].term = typeof event.model.startDate === 'object' ? event.model.startDate.format('x') : event.model.startDate;
                vm.gridOptions.columnDefs[getColumnNumber('modifiedAt')].filters[1].term = typeof event.model.endDate === 'object' ? event.model.endDate.format('x') : event.model.endDate;
            }

            lastModifiedAtDateFilter = angular.copy({
                startDate: event.model.startDate,
                endDate: event.model.endDate
            });
        };

        vm.showLoadBar = function () {
            return !isRendered || vm.fetchInProgress;
        };

        function initWatches () {
            WatchService.create($scope, '$ctrl.selectedInstitution', selectedInstitutionWatch);
            WatchService.create($scope, ManageIdsFactory.getSelectedItem, selectedRowWatch);
            WatchService.create($scope, ManageIdsFactory.getIsReloadGrid, reloadGridRequestWatch);
            WatchService.create($scope, ManageIdsFactory.isAddMode, function (value) {
                if(!vm.selectedRowId) {
                    toggleColumns(value);
                }
            });
        }

        function selectedInstitutionWatch(selectedInstitution) {
            if (selectedInstitution) {
                paginationOptions['filter[coreId]'] = '=' + vm.selectedInstitution.coreId.toString();
            }
            if (!selectedInstitution && !firstLoad) {
                delete paginationOptions['filter[coreId]'];
            }
            if (gridApiInstance && gridApiInstance.pagination.getPage() > 1) {
                gridApiInstance.pagination.seek(1);
            }
            $timeout(function () {
                if (!firstLoad) {
                    initDatagrid();
                }
                handleFetch();
            });
            firstLoad = false;
        }

        function init() {
            initDatagrid();
            initWatches();
        }

        init();
    }
}(window.angular));
(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    App.services.ScopusStatisticsService = function (
        $resource,
        $log,
        $q,
        $state,
        constants,
        RequestsQueueService,
        TimeService
    ) {
        var service = {
            deferred : {}
        };

        function getModel() {
            return $resource(constants.api.stars.url, {}, {
                getScopusStatistics: {
                    method: 'GET',
                    url: constants.api.institutions.url + '/v1/admin/scopus/statistics',
                    isArray: false,
                    cancellable: true
                },
                publish: {
                    method: 'GET',
                    url: constants.api.institutions.url + '/v1/admin/scopus/publish',
                    isArray: false,
                    cancellable: true
                },
                getLogs: {
                    method: 'GET',
                    url:  constants.api.institutions.url + '/v1/admin/scopus/publish-log?sorting[createdAt]=desc',
                    isArray: false,
                    cancellable: true
                },
            });
        }

        service.getScopusStatistics = function () {
            var key = 'ScopusStatisticsService::getScopusStatistics',
                Api;
            RequestsQueueService.cancelAll(key);
            Api = getModel().getScopusStatistics();
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                return data;
            }, function () {
                return false;
            });
        };

        service.publish = function (data) {
            var key = 'ScopusStatisticsService::publish',
                Api;

            RequestsQueueService.cancelAll(key);
            Api = getModel().publish(data);
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (response) {
                return response;
            }, function (err) {
                return false;
            });
        };

        service.getGroupedLogs = function (params) {
            var key = 'ScopusStatisticsService::getLogs',
                Api;

            RequestsQueueService.cancelAll(key);
            Api = getModel().getLogs(params);
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                return data;
            }, function () {
                return false;
            });
        };

        return service;
    };

    angular
        .module('qsHub.rankings')
        .service('ScopusStatisticsService', [
            '$resource',
            '$log',
            '$q',
            '$state',
            'constants',
            'RequestsQueueService',
            'TimeService',
            App.services.ScopusStatisticsService
        ]);

}(window.angular));
(function(angular) {
    "use strict";

    angular
        .module('qsHub.rankings')
        .factory('ScopusStatisticsFactory', [
            Factory
        ]);

        function Factory () {
            var reload = null,
                logsLimit = null,
                publishStatus = null,
                subscribedTo = [];
            return {
                requestLogsReload:  function() {
                    reload = true;
                },
                isLogsReloadRequest: function () {
                    return !!reload;
                },
                resetLogsReloadRequest: function () {
                    reload = null;
                },
                setLogsLimit: function (limit) {
                    logsLimit = limit;
                },
                getLogsLimit: function () {
                    return logsLimit;
                },
                setSubscribedTo: function (newKey) {
                    subscribedTo.push(newKey);
                },
                getSubscribedTo: function () {
                    return subscribedTo;
                },
                setPublishStatus: function (value) {
                    publishStatus = value;

                },
                getPublishStatus: function () {
                    return publishStatus;
                    
                }
            };
        }

}(window.angular));

(function (angular) {
    "use strict";
    angular
        .module('qsHub.rankings')
        .controller('rankings.ScopusStatisticsController', [
            '$scope',
            '$timeout',
            'WatchService',
            'ScopusStatisticsService',
            'ScopusStatisticsFactory',
            'NotifierFactory',
            ScopusStatistics
        ]);
    function ScopusStatistics(
        $scope,
        $timeout,
        WatchService,
        ScopusStatisticsService,
        ScopusStatisticsFactory,
        NotifierFactory
    ) {
        var vm = this;
        vm.publishing = true;

        vm.handlePublish = function () {
            vm.publishStatus = 'pending';
            vm.publishing = true;
            ScopusStatisticsService.publish().then(function (result) {
                if (!result.insertedId) {
                    NotifierFactory.show(
                        'error',
                        'Can\'t process scopus statistics publish at the moment. Please try again later.',
                        'Scopus Statistics'
                    );
                    vm.publishStatus = 'failure';
                }
                else {
                    ScopusStatisticsFactory.requestLogsReload();
                }
                vm.publishing = false;
            });
        };

        function getScopusStatistics () {
            vm.fetchInProgress = true;
            ScopusStatisticsService.getScopusStatistics().then(function (data) {
                    vm.fetchInProgress = false;
                    vm.statistics = data;
            });
        }

        function initWatches () {
            WatchService.create($scope, ScopusStatisticsFactory.getPublishStatus, function (value) {
                vm.publishStatus = value;
                $timeout(function () {
                    vm.publishing = ['success', 'failure'].indexOf(vm.publishStatus) === -1 && !!vm.publishStatus;
                });
            });
        }
      
        function init() {
            initWatches();
            getScopusStatistics();
        }
        init();
    }
}(window.angular));
(function (angular) {
    "use strict";
    angular
        .module('qsHub.rankings')
        .controller('rankings.ScopusStatisticsPublishLogsController', [
            '$scope',
            'WatchService',
            'WebSocketsService',
            'ScopusStatisticsService',
            'ScopusStatisticsFactory',
            'NotifierFactory',
            ScopusStatisticsPublishLogs
        ]);
    function ScopusStatisticsPublishLogs(
        $scope,
        WatchService,
        WebSocketsService,
        ScopusStatisticsService,
        ScopusStatisticsFactory,
        NotifierFactory
    ) {
        var vm = this,
            limit = null,
            totalMatching = 0,
            totalFiltered = 0,
            logsList = null,
            fetchInProgress = true,
            logsPerLoad = 10,
            defaultFilters = {
                limit : null
            },
            room = 'PublishStatusScopus';

        function fetchLogs() {
            fetchInProgress = true;
            ScopusStatisticsService.getGroupedLogs(defaultFilters).then(function (logs) {
                logsList = logs.results;
                vm.logs = getLimitedLogs();
                totalMatching = logs.totalMatching;
                totalFiltered = logs.totalFiltered;
                vm.logsLength = totalMatching;
                fetchInProgress = false;
            });
        }

        function subscribeToWebsocket() {
            if (ScopusStatisticsFactory.getSubscribedTo().indexOf(room) === -1) {
                WebSocketsService.subscribe(room, room, function (result) {
                    var success = result && result.status && result.status === 'success';
                    ScopusStatisticsFactory.setPublishStatus(result.status);
                    if (success) {
                        ScopusStatisticsFactory.requestLogsReload();
                        NotifierFactory.show(
                            'success',
                            'Scopus statistics are published successfully!',
                            'Scopus Statistics'
                        );
                    } else if (result.status !== 'progress' && result.status !== 'pending') {
                        NotifierFactory.show(
                            'error',
                            'Can\'t process scopus statistics publish at the moment. Please try again later.',
                            'Scopus Statistics'
                        );
                    }
                }, function (subscribed) {
                    if (subscribed) {
                        ScopusStatisticsFactory.setSubscribedTo(room);
                    }
                });
            }
        }

        function getLimitedLogs() {
            var limited = {},
                total = 1;
            angular.forEach(logsList, function (logs, dateKey) {
                angular.forEach(logs.logs, function (log, key) {
                    if (total <= limit) {
                        if (typeof limited[dateKey] === 'undefined') {
                            limited[dateKey] = [];
                        }
                        limited[dateKey][key] = log;
                        total++;
                    } else {
                        return false;
                    }
                });
            });
            if (typeof(logsList) !== 'undefined' &&
                typeof(logsList[0]) !== 'undefined' &&
                typeof(logsList[0].logs) !== 'undefined' &&
                typeof(logsList[0].logs[0]) !== 'undefined' &&
                logsList[0].logs[0].status !== 'undefined'
            ) {
                ScopusStatisticsFactory.setPublishStatus(logsList[0].logs[0].status);
            }

            return limited;
        }

        vm.getLogClassColor = function(status) {
            switch (status) {
                case 'success':
                    return 'text-success';
                case 'failure':
                    return 'text-danger';
                case 'progress':
                    return 'text-info';
                case 'pending':
                    return 'text-info';
            }
        };

        vm.getLogClassFaIcon = function(status) {
            switch (status) {
                case 'success':
                    return 'fa-check';
                case 'failure':
                    return 'fa-exclamation';
                case 'progress':
                    return 'fa-spinner fa-spin';
                case 'pending':
                    return 'fa-clock-o';
            }
        };

        vm.isMoreLogsAvailable = function () {
            return limit < totalMatching;
        };

        vm.handleLoadMore = function () {
            limit = limit + logsPerLoad;
            defaultFilters.limit = limit;
            fetchLogs();
            ScopusStatisticsFactory.setLogsLimit(limit);
        };

        vm.isFetchInProgress = function () {
            return fetchInProgress;
        };

        function logsReloadRequestWatch(is) {
            if (is) {
                ScopusStatisticsFactory.resetLogsReloadRequest();
                fetchLogs();
            }
        }

        function initWatches() {
            WatchService.create($scope, ScopusStatisticsFactory.isLogsReloadRequest, logsReloadRequestWatch);
        }

        vm.$onInit = function () {
            limit = ScopusStatisticsFactory.getLogsLimit() || logsPerLoad;
            ScopusStatisticsFactory.setPublishStatus(null);
            defaultFilters.limit = limit;
            initWatches();
            fetchLogs();
            subscribeToWebsocket();
        };
    }

}(window.angular));
(function (angular) {

    'use strict';
  
    angular.module('qsHub.rankings').component('scopusStatisticsPublishLogs', {
        templateUrl: '/scripts/modules/rankings/components/scopus/components/statistics/scopusStatisticsPublishLogs/scopusStatisticsPublishLogsView.html',
        bindToController: true,
        controller: 'rankings.ScopusStatisticsPublishLogsController'
    });
  
  }(window.angular));
  
(function (angular) {
    "use strict";

    angular
        .module('qsHub.rankings')
        .controller('Rankings.ClassificationsPublishController', [
            '$scope',
            'constants',
            'NotifierFactory',
            'AuthenticationService',
            'ClassificationsPublishFactory',
            'WatchService',
            '$timeout',
            'ClassificationsService',
            'ClassificationsPublishService',
            ClassificationsPublish
        ]);

    function ClassificationsPublish(
        $scope,
        constants,
        NotifierFactory,
        AuthenticationService,
        ClassificationsPublishFactory,
        WatchService,
        $timeout,
        ClassificationsService,
        ClassificationsPublishService
    ) {
        var vm = this,
            logLimit = 10,
            limit = 10;

        vm.dropZoneInstance = null;
        vm.uploadEnabled = true;
        vm.forms = {};
        vm.showStepTwoButton = false;
        vm.defaultFilters = {
            limit: logLimit,
            'sorting[createdAt]': 'desc',
            individual: 'false'
        };
        vm.showLoadMoreButton = true;

        var statusCellTemplate = '/scripts/shared/publishLogs/cellTemplates/statusCellTemplate.html',
            publishedDateTemplate = '/scripts/shared/publishLogs/cellTemplates/publishedDateCellTemplate.html';
        vm.gridOptions = {
            appScopeProvider: vm,
            enableSorting: true,
            exporterMenuCsv: false,
            exporterMenuAllData: false,
            exporterMenuPdf: false,
            enableGridMenu: true,
            showGridFooter: false,
            enableFiltering: true,
            enableColumnResize: true,
            enableFullRowSelection: true,
            enableRowSelection: true,
            multiSelect: false,
            enableRowHeaderSelection: false,
            useExternalSorting: true,
            enablePaginationControls: true,
            paginationPageSizes: [25, 50, 100],
            paginationPageSize: 25,
            rowHeight: 40,
            rowTemplate: '/scripts/shared/publishLogs/cellTemplates/rowTemplate.html',
            columnDefs: [
                {
                    displayName: 'Message',
                    field: 'name',
                    enableFiltering: true,
                    enableSorting: false,
                    width: '60%'
                },
                {
                    displayName: 'Status',
                    field: 'status',
                    enableFiltering: true,
                    enableSorting: false,
                    cellTemplate: statusCellTemplate,
                    headerCellTemplate: '/scripts/shared/publishLogs/cellTemplates/statusHeaderCellTemplate.html',
                    width: '10%'
                },
                {
                    displayName: 'Published',
                    field: 'modifiedAt',
                    enableFiltering: false,
                    enableSorting: false,
                    cellTemplate: publishedDateTemplate,
                    width: '30%'
                }
            ]
        };

        vm.getRowFeed = function (entity) {
            if (entity.status === 'pending' || entity.status === 'progress' || entity.status === 'cancelled' || !entity.id) {
                NotifierFactory.show(
                    'error',
                    'Feed does not exist',
                    'Classifications Rankings'
                );
            } else {
                ClassificationsPublishService.getPublishLogFeed(entity.id).then(function (result) {
                    if (result !== 'error') {
                        vm.startDownload(result, entity.id + '-feed');
                        NotifierFactory.show(
                            'success',
                            'Feed downloaded correclty',
                            'Classifications Rankings'
                        );
                    } else {
                        NotifierFactory.show(
                            'error',
                            'Failed to get feed',
                            'Classifications Rankings'
                        );
                    }
                });
            }
        };

        vm.startDownload = function(result, fileName) {
            var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(result));
            var downloadAnchorNode = document.createElement('a');
            downloadAnchorNode.setAttribute("href", dataStr);
            downloadAnchorNode.setAttribute("download", fileName + ".json");
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
        };

        vm.showLoadMoreLogsButton = function() {
            return !vm.loadingMorePublishLogs &&
                    vm.logs &&
                    vm.logs.length !== 0 &&
                    vm.gridOptions.data &&
                    vm.showLoadMoreButton;
        };

        vm.getNumberOfLogs = function(logs) {
            var count = 0,
                i = 0;
            for (i = 0; i < logs.length; i++) {
                count = count + logs[i].logs.length;
            }

            return count;
        };

        vm.loadMorePublishLogs = function () {
            vm.defaultFilters.limit += limit;
            vm.loadingMorePublishLogs = true;
            ClassificationsPublishService.getPublishLogs(vm.defaultFilters).then(function(data) {
                vm.loadingMorePublishLogs = false;
                vm.showLoadMoreButton = true;
                if (data.totalFiltered === data.totalMatching) {
                    vm.showLoadMoreButton = false;
                }
                vm.logs = data.results;
                vm.gridOptions.data = data.results;
            });
        };

        vm.handleUploadError = function (error) {
            if (error.status === 'canceled') {
                $scope.$apply(function() {
                    ClassificationsPublishFactory.setIsLoading(false);
                    vm.showStepTwoButton = false;
                    vm.dropZoneInstance.removeAllFiles();
                    NotifierFactory.show(
                        'warning',
                        'Upload was canceled succesfully',
                        'Ranking Classification CSV file'
                    );
                });

                return;
            }
            var errorMessage = error && error.xhr !== undefined && error.xhr.responseText ? JSON.parse(error.xhr.responseText) : false;
            errorMessage = errorMessage ? errorMessage.message : 'File upload failed. Only CSV are accepted';
            $scope.$apply(function() {
                ClassificationsPublishFactory.setIsLoading(false);
                vm.showStepTwoButton = false;
                vm.dropZoneInstance.removeAllFiles();
                NotifierFactory.show(
                    'error',
                    errorMessage,
                    'Ranking Classification CSV file'
                );
            });
        };

        vm.handleFileUploaded = function (file, response) {
            $scope.$apply(function() {
                if (!response || !response.previewData || !response.insertedCount) {
                    vm.handleUploadError();
                } else {
                    vm.data = response;
                    vm.calculateExtraValues(response.previewData);
                    ClassificationsPublishFactory.setCurrentStep('preview');
                    vm.showStepTwoButton = false;
                    vm.dropZoneInstance.removeAllFiles();
                    NotifierFactory.show(
                        'success',
                        'File successfully validated',
                        'Classifications Rankings'
                    );
                }
            });
        };

        vm.calculateExtraValues = function (data) {
            angular.forEach(data, function (item, key) {
                vm.data.previewData[key].studentFacultyRatio = ClassificationsService.calculateStudentFacultyRatio(item);
                vm.data.previewData[key].domesticStudentsPercentage = ClassificationsService.calculateDomesticStudentsPercentage(item);
            });
        };

        vm.handleCheckFile = function () {
            ClassificationsPublishFactory.setIsLoading(true);
            vm.dropZoneInstance.processQueue();
        };

        vm.handleFileAdded = function (file) {
           $scope.$apply(function() {
                vm.showStepTwoButton = true;
                vm.uploadEnabled = false;
                if (vm.dropZoneInstance.files.length > 1) {
                    vm.dropZoneInstance.removeAllFiles();
                    vm.dropZoneInstance.addFile(file);
                }
            });
        };

        vm.handleFileRemoved = function () {
            $timeout(function() {
                vm.uploadEnabled = true;
                if (vm.dropZoneInstance.files.length === 0)  {
                    vm.showStepTwoButton = false;
                }
            });
        };

        vm.uploadConfig = {
            // http://www.dropzonejs.com/#configuration-options
            dropzone: {
                init: function () {
                    vm.dropZoneInstance = this;
                },
                url: constants.api.rankings.url + '/v1/classifications-publish/check',
                maxFiles: 1,
               // maxFilesize: vm.maxSize,
                uploadMultiple: false,
                parallelUploads: 1,
                autoProcessQueue: false,
                addRemoveLinks: true,
                createImageThumbnails: false,
                acceptedFiles: '.csv',
                headers: AuthenticationService.getAuthorizationHeader(),
                thumbnailWidth: 200,
                thumbnailHeight: 200,
                paramName: "file"
            },
            // http://www.dropzonejs.com/#event-list
            eventHandlers: {
                success: vm.handleFileUploaded,
                addedfile: vm.handleFileAdded,
                removedfile: vm.handleFileRemoved,
                error: vm.handleUploadError
            }
        };

        vm.isLoading = function () {
            return ClassificationsPublishFactory.getIsLoading();
        };

        vm.showPreview = function () {
            return ClassificationsPublishFactory.getIsStepPreview();
        };

        vm.showPrePublish = function () {
            return ClassificationsPublishFactory.getIsStepPrePublish();
        };

        vm.showPublish = function () {
            return ClassificationsPublishFactory.getIsStepPublish();
        };

        vm.showUpload = function () {
            return ClassificationsPublishFactory.getIsStepUpload();
        };

        vm.getCurrentState = function() {
            return ClassificationsPublishFactory.getCurrentStep(true);
        };

        vm.publishingHasStatus = function (status) {
            if (vm.logs &&
                vm.logs.length > 0
            ) {
                if (vm.logs[0].logs &&
                    vm.logs[0].logs.length > 0
                ) {
                   return vm.logs[0].logs[0].status === status &&
                          !vm.logs[0].logs[0].isIndividualPublish;
                }
            }

            return false;
        };

        vm.initWatches = function () {
            WatchService.create($scope, ClassificationsPublishFactory.isUpdateLogs, function(newValue) {
                if (newValue) {
                    ClassificationsPublishService.getPublishLogs(vm.defaultFilters).then(function(data) {
                        vm.logs = data.results;
                        vm.gridOptions.data = data.results;
                        vm.showLoadMoreButton = true;
                        if (data.totalFiltered === data.totalMatching) {
                            vm.showLoadMoreButton = false;
                        }
                    });
                    ClassificationsPublishFactory.setUpdateLogs(false);
                }
            });
        };

        vm.init = function () {
            ClassificationsPublishFactory.setCurrentStep('upload');
            ClassificationsPublishFactory.setSaveData(true);
            ClassificationsPublishService.getPublishLogs(vm.defaultFilters).then(function(data) {
                vm.logs = data.results;
                vm.gridOptions.data = data.results;
                vm.ranking = 'classifications';
                ClassificationsPublishFactory.setGridOptionsLogs(vm.gridOptions);
                if (vm.publishingHasStatus('progress') || vm.publishingHasStatus('failure') || vm.publishingHasStatus('pending')) {
                    ClassificationsPublishFactory.setPublishLogId(vm.logs[0].logs[0].id);
                    vm.section.status = vm.logs[0].logs[0].status;
                    vm.section.progress = 0;
                    ClassificationsPublishFactory.setPublishInProgress(true);
                    vm.section.totalScores = vm.logs[0].logs[0].scoresTotal;
                    vm.section.totalPublished = vm.logs[0].logs[0].scoresPublished;
                    vm.section.totalFailed = vm.logs[0].logs[0].scoresFailed;
                    if ((vm.section.totalPublished + vm.section.totalFailed) > 0) {
                        vm.section.progress = parseInt((vm.section.totalPublished + vm.section.totalFailed) / vm.section.totalScores * 100, 10);
                    }
                    ClassificationsPublishFactory.setCurrentStep('publish');
                    if (['failure'].indexOf(vm.logs[0].logs[0].status) !== -1) {
                        vm.section.progress = 100;
                    }
                }
            });
            vm.initWatches();
        };

        vm.init();
    }

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { factories: {} });

    App.factories.classificationsPublish = function () {
        var currentStep = 'upload',
            previewLoading = false,
            saveData = true,
            publishLogId = null,
            gridOptions = {},
            gridOptionsLogs = {},
            publishInProgress = false,
            updateLogs = false;

        return {
            setCurrentStep: function(step) {
                currentStep = step;
            },
            getCurrentStep: function(numeric) {
                if (!numeric) {
                    return currentStep;
                }
                switch(currentStep) {
                    case 'upload':
                        return 0;
                    case 'preview':
                        return 1;
                    case 'prePublish':
                        return 2;
                    case 'publish':
                        return 3;
                }
            },
            getIsStepUpload: function() {
                return currentStep === 'upload';
            },
            getIsStepPreview: function() {
                return currentStep === 'preview';
            },
            getIsStepPrePublish: function() {
                return currentStep === 'prePublish';
            },
            getIsStepPublish: function() {
                return currentStep === 'publish';
            },
            getIsLoading: function() {
                return previewLoading;
            },
            setIsLoading: function(loading) {
                previewLoading = loading;
            },
            getSaveData: function() {
                return saveData;
            },
            setSaveData: function(save) {
                saveData = save;
            },
            getPublishLogId: function() {
                return publishLogId;
            },
            setPublishLogId: function(newPublishLogId) {
                publishLogId = newPublishLogId;
            },
            getGridOptions: function() {
                return gridOptions;
            },
            setGridOptions: function(newGridOptions) {
                gridOptions = newGridOptions;
            },
            getGridOptionsLogs: function() {
                return gridOptionsLogs;
            },
            setGridOptionsLogs: function(newGridOptions) {
                gridOptionsLogs = newGridOptions;
            },
            setPublishInProgress: function(newPublishInProgress) {
                publishInProgress = newPublishInProgress;
            },
            isPublishInProgress: function() {
                return publishInProgress;
            },
            setUpdateLogs: function(newUpdateLogs) {
                updateLogs = newUpdateLogs;
            },
            isUpdateLogs: function() {
                return updateLogs;
            }
        };
    };

    angular
        .module('qsHub.rankings')
        .factory('ClassificationsPublishFactory', [
            App.factories.classificationsPublish
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    App.services.ClassificationsPublishService = function (
        $resource,
        $log,
        $q,
        constants
    ) {
        var service = {
            deferred : {}
        };

        service.getPublishLogModel = function () {
            return $resource(constants.api.rankings.url, {}, {
                get: {
                    method: 'GET',
                    url: constants.api.rankings.url + '/v1/rankings-classifications/publish-log'
                }
            });
        };

        service.getPublishLogDetailsModel = function (id) {
            return $resource(constants.api.rankings.url, {}, {
                get: {
                    method: 'GET',
                    url: constants.api.rankings.url + '/v1/rankings-classifications/publish-log/details/' + id
                }
            });
        };

        service.getPublishLogFeedModal = function (id) {
            return $resource(constants.api.rankings.url, {}, {
                get: {
                    method: 'GET',
                    url: constants.api.rankings.url + '/v1/classification/feed/' + id
                }
            });
        };

        service.getPublishLogFeed = function(id) {
            var deferred = $q.defer();

            service.getPublishLogFeedModal(id).get({}, function (data) {
                deferred.resolve(data);
                deferred = null;
            }, function (error) {
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                deferred.resolve('error');
                deferred = null;
            });

            return deferred.promise;
        };

        service.getPublishLogs = function(filters) {
            var deferred = $q.defer();

            service.getPublishLogModel().get(filters, function (data) {
                deferred.resolve(data);
                deferred = null;
            }, function (error) {
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                deferred.resolve('error');
                deferred = null;
            });

            return deferred.promise;
        };

        service.getPublishLogDetails = function(filters, id) {
            var deferred = $q.defer();

            service.getPublishLogDetailsModel(id).get(filters, function (data) {
                deferred.resolve(data);
                deferred = null;
            }, function (error) {
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                deferred.resolve('error');
                deferred = null;
            });

            return deferred.promise;
        };

        return service;
    };

    angular
        .module('qsHub.rankings')
        .service('ClassificationsPublishService', [
            '$resource',
            '$log',
            '$q',
            'constants',
            App.services.ClassificationsPublishService
        ]);

}(window.angular));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {
        components:{}
    });

    App.components.classificationsPublishPreView = {
        templateUrl: '/scripts/modules/rankings/components/classificationsPublish/rankingsPreview/classificationsPublishPreViewView.html',
        controller: 'Rankings.ClassificationsPublishPreViewController',
        bindings: {
            data: '=',
            section: '='
        }
    };

    angular.module('qsHub.rankings').component('classificationsPublishPreView', App.components.classificationsPublishPreView);

}(window.angular));

(function (angular, moment) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers:{}});
    App.controllers.ClassificationsPublishPreView = function (
        $scope,
        WatchService,
        ClassificationsPublishPreViewService,
        ClassificationsPublishFactory,
        WebSocketsService
    ) {
        var vm = this;
        vm.forms = {};
        vm.section = {};

        vm.gridOptions = {
            appScopeProvider: vm,
            enableSorting: false,
            exporterMenuCsv: false,
            exporterMenuAllData: false,
            exporterMenuPdf: false,
            enableGridMenu: true,
            useExternalFiltering: false,
            showGridFooter: false,
            enableFiltering: false,
            enableColumnResize: false,
            enableFullRowSelection: false,
            enableRowSelection: false,
            multiSelect: false,
            enableRowHeaderSelection: false,
            useExternalPagination: false,
            useExternalSorting: false,
            paginationPageSizes: [25, 50, 100],
            enableHorizontalScrollbar: 1,
            rowTemplate: '/scripts/shared/ui-grid/templates/rowTemplate.html',
            columnDefs: [
                {
                    displayName: 'Institution ID',
                    field: 'institutionCoreId',
                    width: '*',
                    minWidth: "120"
                },
                {
                    displayName: 'Institution Name',
                    field: 'institutionName',
                    minWidth: "150",
                    width: '*'
                },
                {
                    displayName: 'Size',
                    field: 'size',
                    width: '*',
                    minWidth: "60"
                },
                {
                    displayName: 'Subject Range',
                    field: 'subjectRange',
                    width: '*',
                    minWidth: "120"
                },
                {
                    displayName: 'Age',
                    field: 'age',
                    width: '*',
                    minWidth: "60"
                },
                {
                    displayName: 'Research Intensity',
                    field: 'researchIntensity',
                    width: '*',
                    minWidth: "160"
                },
                {
                    displayName: 'Tuition Fees Domestic Undergrad',
                    field: 'domesticUgTuitionFees',
                    width: '*',
                    minWidth: "230"
                },
                {
                    displayName: 'Tuition Fees Domestic Postgrad',
                    field: 'domesticPgTuitionFees',
                    width: '*',
                    minWidth: "230"
                },
                {
                    displayName: 'Tuition Fees International Undergrad',
                    field: 'internationalUgTuitionFees',
                    width: '*',
                    minWidth: "260"
                },
                {
                    displayName: 'Tuition Fees International Postgrad',
                    field: 'internationalPgTuitionFees',
                    width: '*',
                    minWidth: "260"
                },
                {
                    displayName: 'Status',
                    field: 'status',
                    width: '*',
                    minWidth: "70"
                },
                {
                    displayName: 'Number of Academic Faculty Staff',
                    field: 'faculty',
                    width: '*',
                    minWidth: "250"
                },
                {
                    displayName: 'Number of Academic Faculty Staff with PhD',
                    field: 'facultyPhd',
                    width: '*',
                    minWidth: "290"
                },
                {
                    displayName: 'Number of International Academic Faculty Staff / Year',
                    field: 'facultyInternational',
                    width: '*',
                    minWidth: "330"
                },
                {
                    displayName: 'Number of Students',
                    field: 'students',
                    width: '*',
                    minWidth: "230"
                },
                {
                    displayName: 'Number of Undergraduate Students',
                    field: 'studentsUg',
                    width: '*',
                    minWidth: "250"
                },
                {
                    displayName: 'Number of Postgraduate Students',
                    field: 'studentsPg',
                    width: '*',
                    minWidth: "250"
                },
                {
                    displayName: 'Number of International Students',
                    field: 'studentsInternational',
                    width: '*',
                    minWidth: "250"
                },
                {
                    displayName: 'Number of International Undergraduate Students',
                    field: 'studentsUgInternational',
                    width: '*',
                    minWidth: "300"
                },
                {
                    displayName: 'Number of International Postgraduate Students',
                    field: 'studentsPgInternational',
                    width: '*',
                    minWidth: "320"
                },
                {
                    displayName: 'Student / Faculty ratio',
                    field: 'studentFacultyRatio',
                    width: '*',
                    minWidth: "190"
                },
                {
                    displayName: 'Domestic Students Percentage',
                    field: 'domesticStudentsPercentage',
                    width: '*',
                    minWidth: "220"
                },
                {
                    displayName: 'Gender mix',
                    field: 'genderMix',
                    width: '*',
                    minWidth: "130"
                },
                {
                    displayName: 'Graduation rate',
                    field: 'graduationRate',
                    width: '*',
                    minWidth: "150"
                }
            ],
            onRegisterApi: function (gridApi) {
                vm.gridApi = gridApi;
            }
        };

        vm.loadDataGrid = function (newValue) {
            if (angular.isDefined(newValue)) {
                ClassificationsPublishFactory.setGridOptions(vm.gridOptions);
                vm.gridOptions.data = newValue.previewData;
                ClassificationsPublishFactory.setIsLoading(false);
            }
        };

        vm.handleBackToStepUpload = function () {
            ClassificationsPublishFactory.setCurrentStep('upload');
            ClassificationsPublishFactory.setSaveData(true);
        };

        vm.prePublishCallback = function (response) {
            if (response) {
                ClassificationsPublishFactory.setCurrentStep('prePublish');
            } else {
                ClassificationsPublishFactory.setCurrentStep('preview');
            }
            ClassificationsPublishFactory.setIsLoading(false);
            ClassificationsPublishFactory.setSaveData(false);
        };

        vm.handleGoToPrePublishStep = function () {
            if (ClassificationsPublishFactory.getSaveData()) {
                ClassificationsPublishFactory.setCurrentStep('');
                ClassificationsPublishFactory.setIsLoading(true);
                ClassificationsPublishPreViewService.updateRankingsClassificationOverview().then(function(response) {
                    vm.prePublishCallback(response);
                    vm.publishSubscribe(response.publishLogId);
                    ClassificationsPublishFactory.setPublishLogId(response.publishLogId);
                    vm.section = {
                        status : 'pending', //constants.publishStatus.pending;
                        statusMessage : '',
                        name : 'Rankings Classifications',
                        progress : 0,
                        totalScores : response.modifiedCount + response.insertedCount,
                        totalPublished: 0
                    };
                });
            } else {
                vm.prePublishCallback(true);
            }
        };

        /**
         * Subscribes to room.
         *
         * @param {type} publishLogsId
         */
        vm.publishSubscribe = function (publishLogsId) {
            if (!publishLogsId) {
                return;
            }
            var roomName = publishLogsId + 'classifications';
            var eventName = 'publishRankingsStatus' + roomName;

            WebSocketsService.subscribe(roomName, eventName, function (response) {
                vm.publishStatusCallback(response);
            });
        };

        /**
         * Actions to do when status changes
         *
         * @param {Object} response
         */
        vm.publishStatusCallback = function (response) {
            vm.section.status = response.status;
            vm.section.totalPublished = response.totalPublished;
            vm.section.progress = response.progress;
            vm.section.percent = Math.round(parseFloat(response.progress));
        };

        vm.initWatches = function () {
            WatchService.create($scope, function() {
                return vm.data;
            }, vm.loadDataGrid);

            WatchService.create($scope, ClassificationsPublishFactory.isPublishInProgress, function() {
                vm.publishSubscribe(ClassificationsPublishFactory.getPublishLogId());
            });
        };

        vm.$onInit = function () {
            vm.initWatches();
        };
    };

    angular
        .module('qsHub.rankings')
        .controller('Rankings.ClassificationsPublishPreViewController', [
            '$scope',
            'WatchService',
            'ClassificationsPublishPreViewService',
            'ClassificationsPublishFactory',
            'WebSocketsService',
            App.controllers.ClassificationsPublishPreView
        ]);

}(window.angular, window.moment));

(function(angular, moment) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    App.services.ClassificationsPublishPreView = function (
        $resource,
        $log,
        constants,
        $q
    ) {
        var service = {};

        service.getModel = function () {
            return $resource(constants.api.rankings.url, {}, {
                get: {
                    method: 'GET',
                    url: constants.api.rankings.url + '/v1/classifications-publish/save'
                }
            });
        };

        service.updateRankingsClassificationOverview = function () {
            var deferred = $q.defer();

            service.getModel().get({}, function (data) {
                deferred.resolve(data);
                deferred = null;
            }, function (error) {
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                deferred.resolve([]);
                deferred = null;
            });

            return deferred.promise;
        };

        return service;
    };

    angular
        .module('qsHub.rankings')
        .service('ClassificationsPublishPreViewService', [
            '$resource',
            '$log',
            'constants',
            '$q',
            App.services.ClassificationsPublishPreView
        ]);

}(window.angular, window.moment));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {
        components:{}
    });

    App.components.classificationsPublishPrePublish = {
        templateUrl: '/scripts/modules/rankings/components/classificationsPublish/rankingsPrePublish/classificationsPublishPrePublishView.html',
        controller: 'Rankings.ClassificationsPublishPrePublishController',
        bindings: {
            section: '=',
            data: '='
        }
    };

    angular.module('qsHub.rankings').component('classificationsPublishPrePublish', App.components.classificationsPublishPrePublish);

}(window.angular));

(function (angular, moment) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers:{}});
    App.controllers.ClassificationsPublishPrePublish = function (
        $scope,
        ClassificationsPublishPrePublishService,
        ClassificationsPublishFactory,
        ModalService
    ) {
        var vm = this;
        vm.forms = {};
        vm.previewData = [];

        /**
         * Publishing callback.
         *
         * @param {Object} response
         * @returns {boolean}
         */
        vm.publishCallback = function (response) {
            if (!response) {
                ClassificationsPublishFactory.setIsLoading(false);
                $scope.section.status = 'failure';//constants.publishStatus.failure;
                return false;
            }
            ClassificationsPublishFactory.setUpdateLogs(true);
        };

        vm.handleBackToStepPreview = function() {
            ClassificationsPublishFactory.setCurrentStep('preview');
        };

        vm.handlePublishClick = function() {
            ClassificationsPublishFactory.setCurrentStep('publish');
            ClassificationsPublishPrePublishService.publish(
                    ClassificationsPublishFactory.getPublishLogId()
            ).then(function (response) {
                vm.publishCallback(response);
            });
        };

        vm.handlePreviewClassificationsClick = function() {
            var modalOptions = {}, modalDefaults = {
                bindToController: true,
                controllerAs: '$ctrl',
                controller: function () {
                    var controller = this;
                    controller.headerText = 'Publish Data Preview';
                    var gridOptions = angular.extend({}, ClassificationsPublishFactory.getGridOptions());
                    gridOptions.paginationPageSize = 25;
                    gridOptions.enablePaginationControls = true;
                    gridOptions.data = vm.data.allData;
                    controller.gridOptions = gridOptions;
                },
                size: 'full-screen',
                backdrop: true,
                keyboard: true,
                modalFade: true,
                templateUrl: '/scripts/modules/rankings/components/rankingsScores/directives/publishRankingsHistory/previewDataModal.html'
            };
            ModalService.show(modalDefaults, modalOptions);
        };

        vm.initWatches = function () {};

        vm.$onInit = function () {
            vm.initWatches();
        };
    };

    angular
        .module('qsHub.rankings')
        .controller('Rankings.ClassificationsPublishPrePublishController', [
            '$scope',
            'ClassificationsPublishPrePublishService',
            'ClassificationsPublishFactory',
            'ModalService',
            App.controllers.ClassificationsPublishPrePublish
        ]);

}(window.angular, window.moment));

(function(angular, moment) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    App.services.ClassificationsPublishPrePublish = function (
        $resource,
        $log,
        constants,
        $q
    ) {
        var service = {};

        service.getModel = function (publishLogId, rePublish) {
            return $resource(constants.api.rankings.url, {'rePublish': rePublish}, {
                get: {
                    method: 'GET',
                    url: constants.api.rankings.url + '/v1/classifications-publishing/publish/' + publishLogId
                }
            });
        };

        service.publish = function(publishLogId, rePublish) {
            rePublish = rePublish || false;
            var deferred = $q.defer();
            service.getModel(publishLogId, rePublish).get({}, function (data) {
                deferred.resolve(data);
                deferred = null;
            }, function (error) {
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                deferred.resolve([]);
                deferred = null;
            });

            return deferred.promise;
        };

        return service;
    };

    angular
        .module('qsHub.rankings')
        .service('ClassificationsPublishPrePublishService', [
            '$resource',
            '$log',
            'constants',
            '$q',
            App.services.ClassificationsPublishPrePublish
        ]);

}(window.angular, window.moment));

(function (angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {
        components:{}
    });

    App.components.classificationsPublishPublish = {
        templateUrl: '/scripts/modules/rankings/components/classificationsPublish/rankingsPublish/classificationsPublishPublishView.html',
        controller: 'Rankings.ClassificationsPublishPublishController',
        bindings: {
            section: '=',
            logs: '='
        }
    };

    angular.module('qsHub.rankings').component('classificationsPublishPublish', App.components.classificationsPublishPublish);

}(window.angular));

(function (angular, moment) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {controllers:{}});
    App.controllers.ClassificationsPublishPublish = function (
        $scope,
        WatchService,
        ClassificationsPublishPublishService,
        NotifierFactory,
        ClassificationsPublishFactory,
        ModalService,
        ClassificationsPublishPrePublishService,
        WebSocketsService
    ) {
        var vm = this;
        vm.forms = {};
        vm.defaultFilters = {
            limit: 10000,
            'sorting[createdAt]': 'asc'
        };

        vm.cancelPublishing = function () {
            var modalOptions = {
                headerText: 'Cancel publish',
                bodyText: 'Are you sure you want to cancel the publish?',
                actionButtonClass: 'danger',
                closeButtonText: 'No',
                actionButtonText: 'Yes'
            }, modalDefaults = {
                backdrop: true,
                keyboard: true,
                modalFade: true
            };
            ModalService.show(modalDefaults, modalOptions).then(vm.cancelPublishingCallback);
        };

        vm.cancelPublishingCallback = function() {
            ClassificationsPublishPublishService.cancelPublishing(
                ClassificationsPublishFactory.getPublishLogId()
            ).then(function (response) {
                if (response.status === 'success') {
                    var roomName = ClassificationsPublishFactory.getPublishLogId() + 'classifications';
                    var eventName = 'publishRankingsStatus' + roomName;
                    WebSocketsService.unSubscribe(eventName);
                    vm.section.status = 'cancelled';
                    ClassificationsPublishFactory.setPublishInProgress(false);
                    ClassificationsPublishFactory.setUpdateLogs(true);
                    NotifierFactory.show(
                        response.status,
                        'Publish cancelled successfully',
                        'Cancel Publish'
                    );
                } else {
                    vm.section.status = 'failure';
                    NotifierFactory.show(
                        'error',
                        'Something went wrong while cancelling publish',
                        'Cancel Publish'
                    );
                }
            });
        };

        vm.publishingIsInProgress = function () {
            return vm.section.status === 'progress';
        };

        vm.publishingIsPending = function () {
            return vm.section.status === 'pending';
        };

        vm.publishingIsFinished = function () {
            return vm.section.status === 'failure' ||
                   vm.section.status === 'cancelled' ||
                   vm.section.status === 'success';
        };

        vm.goStepUpload = function () {
            ClassificationsPublishFactory.setCurrentStep('upload');
            ClassificationsPublishFactory.setSaveData(true);
        };

        vm.viewFeedRecords = function() {
            ClassificationsPublishPublishService.getFeedsView(
                vm.defaultFilters,
                ClassificationsPublishFactory.getPublishLogId()
            ).then(function (response) {
                vm.feedsView = [];
                if (response.results) {
                    vm.feedsView = response.results;
                } else {
                    NotifierFactory.show(
                        'error',
                        'Something went wrong while fetching feeds log',
                        'View Logs Publishing'
                    );
                }
                vm.viewFeedRecordsCallback();
            });
        };

        vm.viewFailedRecords = function() {
            var filters = {
                limit: 100000,
                'filter[status]': 'failure',
                'sorting[modifiedAt]': 'asc'
            };
            ClassificationsPublishPublishService.getFeedsView(
                filters,
                ClassificationsPublishFactory.getPublishLogId()
            ).then(function (response) {
                vm.feedsView = [];
                if (response.results) {
                    vm.feedsView = response.results;
                } else {
                    NotifierFactory.show(
                        'error',
                        'Something went wrong while fetching feeds log',
                        'View Logs Publishing'
                    );
                }
                vm.viewFeedRecordsCallback();
            });
        };

        vm.viewFeedRecordsCallback = function () {
            var modalOptions = {}, modalDefaults = {
                bindToController: true,
                controllerAs: '$ctrl',
                controller: function () {
                    var controller = this;
                    controller.headerText = 'Publish Data Preview';
                    var gridOptions = angular.extend({}, ClassificationsPublishFactory.getGridOptionsLogs());
                    gridOptions.paginationPageSize = 25;
                    gridOptions.enablePaginationControls = true;
                    gridOptions.data = vm.feedsView;
                    controller.gridOptions = gridOptions;
                },
                size: 'full-screen',
                backdrop: true,
                keyboard: true,
                modalFade: true,
                templateUrl: '/scripts/modules/rankings/components/rankingsScores/directives/publishRankingsHistory/previewDataModal.html'
            };
            ModalService.show(modalDefaults, modalOptions);
        };

        vm.publishFailedRecords = function() {
            vm.section.status = 'pending';
            vm.section.percent = 0;
            ClassificationsPublishPrePublishService.publish(
                ClassificationsPublishFactory.getPublishLogId(),
                true
            ).then(function (response) {
                vm.publishCallback(response);
            });
        };

        vm.publishCallback = function (response) {
            if (!response) {
                ClassificationsPublishFactory.setIsLoading(false);
                $scope.section.status = 'failure';
                return false;
            }
            ClassificationsPublishFactory.setUpdateLogs(true);
        };

        vm.initWatches = function () {
            WatchService.create($scope, vm.publishingIsFinished, function(newValue, oldValue) {
                if (newValue !== oldValue) {
                    ClassificationsPublishFactory.setPublishInProgress(false);
                    // update logs
                    ClassificationsPublishFactory.setUpdateLogs(true);
                }
            });
        };

        vm.$onInit = function () {
            vm.initWatches();
        };
    };

    angular
        .module('qsHub.rankings')
        .controller('Rankings.ClassificationsPublishPublishController', [
            '$scope',
            'WatchService',
            'ClassificationsPublishPublishService',
            'NotifierFactory',
            'ClassificationsPublishFactory',
            'ModalService',
            'ClassificationsPublishPrePublishService',
            'WebSocketsService',
            App.controllers.ClassificationsPublishPublish
        ]);

}(window.angular, window.moment));

(function(angular, moment) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    App.services.ClassificationsPublishPublish = function (
        $resource,
        $log,
        constants,
        $q
    ) {
        var service = {};

        service.getCancelModel = function (publishLogId) {
            return $resource(constants.api.rankings.url, {}, {
                get: {
                    method: 'GET',
                    url: constants.api.rankings.url + '/v1/classifications-publishing/cancel/' + publishLogId
                }
            });
        };

        service.getFeedsViewModel = function (publishLogId) {
            return $resource(constants.api.rankings.url, {}, {
                get: {
                    method: 'GET',
                    url: constants.api.rankings.url + '/v1/rankings-classifications/publish-log/details/' + publishLogId
                }
            });
        };

        service.cancelPublishing = function(publishLogId) {
            var deferred = $q.defer();

            service.getCancelModel(publishLogId).get({}, function (data) {
                deferred.resolve(data);
                deferred = null;
            }, function (error) {
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                deferred.resolve([]);
                deferred = null;
            });

            return deferred.promise;
        };

        service.getFeedsView = function (filters, publishLogId) {
            var deferred = $q.defer();

            service.getFeedsViewModel(publishLogId).get(filters, function (data) {
                deferred.resolve(data);
                deferred = null;
            }, function (error) {
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                deferred.resolve([]);
                deferred = null;
            });

            return deferred.promise;
        };

        return service;
    };

    angular
        .module('qsHub.rankings')
        .service('ClassificationsPublishPublishService', [
            '$resource',
            '$log',
            'constants',
            '$q',
            App.services.ClassificationsPublishPublish
        ]);

}(window.angular, window.moment));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    App.services.NominationsService = function (
        $resource,
        $log,
        $q,
        $state,
        constants,
        RequestsQueueService,
        TimeService,
        InstitutionsListService
    ) {
        var service = {
            deferred : {}
        };

        function getModel() {
            return $resource(constants.api.stars.url, {}, {
                getAcademicFiles: {
                    method: 'GET',
                    url: constants.api.institutions.url + '/v1/list/nomination/academic',
                    isArray: false,
                    cancellable: true
                },
                getEmployerFiles: {
                    method: 'GET',
                    url: constants.api.institutions.url + '/v1/list/nomination/employer',
                    isArray: false,
                    cancellable: true
                },
                getLogs: {
                    method: 'GET',
                    url:  constants.api.institutions.url + '/v1/list/nomination/history?sorting[createdAt]=desc',
                    isArray: false,
                    cancellable: true
                },
                getAll: {
                    method: 'GET',
                    url: constants.api.institutions.url + '/v1/list/nomination/history?sorting[createdAt]=desc',
                    isArray: false,
                    cancellable: true
                },
                getLatest: {
                    method: 'GET',
                    url: constants.api.institutions.url + '/v1/list/nomination/latest/history?sorting[createdAt]=desc',
                    isArray: false,
                    cancellable: true
                },
                deleteLogs: {
                    method: 'POST',
                    url: constants.api.institutions.url + '/v1/admin/nominations/delete',
                    isArray: false,
                    cancellable: true
                }
            });
        }

        service.search = function (filters) {
            if (filters && !filters['sorting[modifiedAt]']) {
                filters['sorting[modifiedAt]'] = 'desc';
            }
            if (filters && filters['filter[modifiedAt]'] && filters['filter[modifiedAt]'].startDate === null) {
                filters['filter[modifiedAt]'] = null;
            }
            var key = filters.fileType === 'academic'? 'NominationsService::getAcademicFiles' : 'NominationsService::getEmployerFiles',
                Api;

            RequestsQueueService.cancelAll(key);
            if (filters.fileType === 'academic') {
                Api = getModel().getAcademicFiles(filters);
            }
            else {
                Api = getModel().getEmployerFiles(filters);
            }
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                return data;
            }, function () {
                return false;
            });
        };

        service.deleteLogs = function (ids) {
            if (!ids.length) {
                throw 'Missing Required';
            }
            var key = 'NominationsService:deleteLogs',
                Api;
            RequestsQueueService.cancelAll(key);
            Api = getModel().deleteLogs({}, ids);
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                return data;
            }, function () {
                return false;
            });
        };

        service.searchReports = function (filters) {
            var key = 'NominationsService::searchReports',
                Api;

            RequestsQueueService.cancelAll(key);
            Api = getModel().getAll(filters);
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                return data;
            }, function () {
                return false;
            });
        };

        service.searchLatestReports = function (filters) {
            var key = 'NominationsService::searchLatestReports',
                Api;

            RequestsQueueService.cancelAll(key);
            Api = getModel().getLatest(filters);
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                return data;
            }, function () {
                return false;
            });
        };

        service.getGroupedLogs = function (params) {
                var key = 'NominationsService::getLogs',
                    Api;

                RequestsQueueService.cancelAll(key);
                Api = getModel().getLogs(params);
                RequestsQueueService.add(Api, key);

                return Api.$promise.then(function (data) {
                    var groupedLogs = {},
                        key;
                    angular.forEach(data.results, function (log) {
                        key = TimeService.format(log.createdAt, 'YYYY:MM:DD');
                        if (typeof groupedLogs[key] === 'undefined') {
                            groupedLogs[key] = [];
                        }
                        groupedLogs[key].push(log);
                    });
                    data.grouped = groupedLogs;

                    return data;
                }, function () {
                    return false;
                });
        };

        service.getFileTypes = function () {
            var deferred = $q.defer(),
                list = [{
                    label : 'Academic',
                    handle : 'academic'
                },
                {
                    label : 'Employer',
                    handle : 'employer'
                }];

            deferred.resolve(list);

            return deferred.promise;
        };

        service.formatCsvResults = function (data) {
            data = angular.copy(data);
            angular.forEach(data, function (column, dataKey) {
                angular.forEach(column, function (item, key) {
                    if (key === 'filterType') {
                        data[dataKey][key] = item ? data[dataKey][key].charAt(0).toUpperCase() + data[dataKey][key].slice(1) : null;
                    } 
                    else if (key === 'countryCode') {
                        data[dataKey][key] = InstitutionsListService.getCountryName(item);
                    } 
                    else if (key === 'numberOfContacts' && item === 0) {
                        data[dataKey][key] = '0';
                    }
                    else if (key === 'createdAt' || key === 'modifiedAt')  {
                        data[dataKey][key] = TimeService.format(item, "MMM DD, YYYY HH:mm:ss");
                    }
                });
            });

            return data;
        };

        service.formatVisibleCsvResults = function (data) {
            var filteredRows = [];
             angular.forEach(data, function (row, Key) {
                 filteredRows.push(row.entity);
             });

             return  service.formatCsvResults(filteredRows);
        };

        service.isClientSection = function () {
            return $state.current.name !== 'staff.iu.nominations.lists';
        };

        return service;
    };

    angular
        .module('qsHub.rankings')
        .service('NominationsService', [
            '$resource',
            '$log',
            '$q',
            '$state',
            'constants',
            'RequestsQueueService',
            'TimeService',
            'InstitutionsListService',
            App.services.NominationsService
        ]);

}(window.angular));

(function(angular) {
    "use strict";

    angular
        .module('qsHub.rankings')
        .factory('NominationsFactory', [
            Factory
        ]);

    function Factory () {
        var reload = null,
            logsLimit = null;
            
        return {
            requestLogsReload:  function() {
                reload = true;
            },
            isLogsReloadRequest: function () {
                return !!reload;
            },
            resetLogsReloadRequest: function () {
                reload = null;
            },
            setLogsLimit: function (limit) {
                logsLimit = limit;
            },
            getLogsLimit: function () {
                return logsLimit;
            }
        };
    }

}(window.angular));

(function(angular) {
    'use strict';

    var gridNamespaces = {
            filters: {
                nominationsListReports: 'nominationsListReportsFilters',
                nominationsListAcademic: 'nominationsListAcademicFilters',
                nominationsListEmployer: 'nominationsListEmployerFilters',
                nominationsListAcademicClient: 'nominationsListAcademicClientFilters',
                nominationsListEmployerClient: 'nominationsListEmployerClientFilters'
            },
            visibility: {
                nominationsListReports: 'nominationsListReportsVisibility',
                nominationsListAcademic: 'nominationsListAcademicVisibility',
                nominationsListEmployer: 'nominationsListEmployerVisibility',
                nominationsListAcademicClient: 'nominationsListAcademicClientVisibility',
                nominationsListEmployerClient: 'nominationsListEmployerClientVisibility'
            }
        },
        constants = {
            datagrid: {
                defaultRowsNumber: 25,
                datagridSelectDelay: 1000
            }
        };

    angular
        .module('qsHub.rankings')
        .constant('Nominations.gridNamespaces', gridNamespaces)
        .constant('Nominations.constants', constants);

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.rankings')
        .controller('Rankings.NominationsListClientUploadController', [
            '$scope',
            'constants',
            'NotifierFactory',
            'AuthenticationService',
            'WatchService',
            'InstitutionFactory',
            'NominationsFactory',
            '$timeout',
            '$templateCache',
            '$sce',
            NominationsListClientUpload
        ]);

    function NominationsListClientUpload(
        $scope,
        constants,
        NotifierFactory,
        AuthenticationService,
        WatchService,
        InstitutionFactory,
        NominationsFactory,
        $timeout,
        $templateCache,
        $sce
    ) {
        var vm = this;

        vm.dropZoneInstance = null;
        vm.forms = {};
        vm.fileType = null;
        vm.maxSize = 10;
        vm.uploadEnabled = true;
        vm.fileTypes = [
            {
                name : 'Academic',
                handle : 'academic'
            },
            {
                name : 'Employer',
                handle : 'employer'
            }
        ];
        vm.isFileErrors = false;
        vm.isColumnErrors = false;

        vm.showUpload = function () {
            return vm.fileType !== null;
        };

        vm.generateUrl = function() {
            vm.isGdprChecked = false;
            vm.url = constants.api.institutions.url + '/v1/nomination-lists/upload/'+ InstitutionFactory.getCoreId() +'/' + vm.fileType.handle;
            if (vm.dropZoneInstance.files.length) {
                vm.dropZoneInstance.removeAllFiles();
                vm.uploadEnabled = true;
            }
        };

        vm.handleUploadError = function (error) {
            vm.uploadInprogress = false;
            vm.isFileErrors = false;
            vm.isColumnErrors = false;
            if (error) {
                if (error.status === 'canceled') {
                    $scope.$apply(function() {
                        vm.dropZoneInstance.removeAllFiles();
                        NotifierFactory.show(
                            'warning',
                            'Upload was canceled succesfully',
                            'Nominations list CSV file'
                        );
                    });

                    return;
                }

                var errorMessage = error && error.xhr !== undefined && error.xhr.responseText ? JSON.parse(error.xhr.responseText) : false;
                errorMessage = errorMessage ? errorMessage.message : 'File upload failed. Only CSV are accepted & up to ' + vm.maxSize + 'MB in size.';
                vm.errorMessage = vm.errorMessage ? vm.errorMessage : errorMessage;
                $scope.$apply(function() {
                    vm.dropZoneInstance.removeAllFiles();
                    NotifierFactory.show(
                        'error',
                        errorMessage,
                        'Nominations list CSV file'
                    );
                    reset();
                });
            }
        };

        vm.handleFileRemoved = function () {
            $timeout(function() {
                vm.uploadEnabled = true;
            });
        };

        vm.handleFileUploaded = function (file, response) {
            vm.uploadInprogress = false;
            vm.errorMessage = null;
            vm.isFileErrors = false;
            vm.isColumnErrors = false;
            if (!response) {
                vm.handleUploadError();
            } else {
                if (!angular.isDefined(response.insertedCount) ||
                    !angular.isDefined(response.removeCount)
                ) {
                    NotifierFactory.show(
                        'error',
                        'Something is wrong with the file, please check errors',
                        'Nomination List Upload'
                    );
                    if (response.errorMessage) {
                        vm.isColumnErrors = true;
                    } else {
                        vm.isFileErrors = true;
                    }
                    vm.fileErrors = response;
                } else {
                    if(response.errors && response.errors.length > 0) {
                        vm.fileErrors = response.errors;
                        vm.isFileErrors = true;
                        NotifierFactory.show(
                            'error',
                            'Rows with errors will not be uploaded, please check error details below',
                            'Nomination List Upload'
                        );
                    }
                    else {
                        $scope.$apply(function() {
                            vm.data = response;
                                NotifierFactory.show(
                                'success',
                                'File successfully uploaded',
                                'Nomination List Upload'
                            );
                        });
                        vm.fileErrors = response;
                    }
                    NominationsFactory.requestLogsReload();
                    
                }
                
            }
            reset();
        };

        vm.handleCheckFile = function () {
            vm.uploadInprogress = true;
            vm.dropZoneInstance.options.url = vm.url + '?isGdprChecked=' + vm.isGdprChecked;
            vm.dropZoneInstance.processQueue();
        };

        vm.getErrorMessage = function (value, column, row) {
            var message;

            message = value === '' && column === '' ?
                'Row number <strong> ' + row + ' </strong>' :
                '<strong> "' + value + '" </strong> in column <strong> "' + column + '" </strong> and row number <strong> "' + row + '" </strong>';

            return $sce.trustAsHtml(message);
        };

        function reset () {
            vm.fileType = null;
            vm.uploadEnabled = true;
            vm.isGdprChecked = false;
            if (vm.dropZoneInstance && vm.dropZoneInstance.files.length) {
                vm.dropZoneInstance.removeAllFiles();
            }
        }

        vm.uploadConfig = {
            // http://www.dropzonejs.com/#configuration-options
            dropzone: {
                url: constants.api.institutions.url,
                maxFiles: 1,
                maxFilesize: vm.maxSize,
                uploadMultiple: false,
                parallelUploads: 1,
                autoProcessQueue: false,
                addRemoveLinks: true,
                acceptedFiles: '.csv',
                headers: AuthenticationService.getAuthorizationHeader(),
                thumbnailWidth: 200,
                thumbnailHeight: 200,
                paramName: "file",
                previewTemplate: $templateCache.get('/scripts/modules/rankings/components/rankingsInformation/editRankingsInfo/rankingInfoForm/previewTemplateView.html'),
                init: function(){
                    this.on("addedfile", function (file) {
                        $timeout(function() {
                            vm.errorMessage = null;
                            vm.uploadEnabled = false;
                            });
                         });
                    }
            },
            // http://www.dropzonejs.com/#event-list
            eventHandlers: {
                success: vm.handleFileUploaded,
                error: vm.handleUploadError,
                removedfile: vm.handleFileRemoved
            }
        };

        function initWatches () {
            WatchService.create($scope, InstitutionFactory.getCoreId, function (newValue) {
                if (newValue) {
                    reset();
                }
            });
        }

        function init () {
            initWatches();
        }

        init();
    }

}(window.angular));

(function (angular) {

    'use strict';
  
    angular.module('qsHub.rankings').component('nominationsListClientUploadLogs', {
        templateUrl: '/scripts/modules/rankings/components/nominations/components/client/nominationsListClientUpload/nominationsListClientUploadLogs/nominationsListClientUploadLogsView.html',
        bindToController: true,
        controller: 'Rankings.NominationsListClientUploadLogsController',
        bindings: {
            data: '='
        }
    });
  
  }(window.angular));
  
  
  
  
(function (angular) {
    "use strict";

    angular
        .module('qsHub.rankings')
        .controller('Rankings.NominationsListClientUploadLogsController', [
            '$scope',
            'NotifierFactory',
            'WatchService',
            'NominationsService',
            'WebSocketsService',
            'NominationsFactory',
            'InstitutionFactory',
            'ModalService',
            'UserFactory',
            NominationsListClientUploadLogs
        ]);

    function NominationsListClientUploadLogs(
        $scope,
        NotifierFactory,
        WatchService,
        NominationsService,
        WebSocketsService,
        NominationsFactory,
        InstitutionFactory,
        ModalService,
        UserFactory
    ) {
        var vm = this,
            limit = null,
            totalMatching = 0,
            totalFiltered = 0,
            fetchInProgress = true,
            deleteInProgress = false,
            logsPerLoad = 10,
            defaultFilters = {
                limit : null
            };
        vm.itemsToDelete = {};
        vm.logsList = null;

        function fetchLogs() {
            fetchInProgress = true;
            NominationsService.getGroupedLogs(defaultFilters).then(function (logs) {
                vm.logsList = logs.grouped && Object.keys(logs.grouped).length ? logs.grouped : null;
                vm.logs = getLimitedLogs();
                totalMatching = logs.totalMatching;
                totalFiltered = logs.totalFiltered;
                fetchInProgress = false;
            });
        }

        function getLimitedLogs() {
            var limited = {},
                total = 1;
            angular.forEach( vm.logsList, function (logs, dateKey) {
                angular.forEach(logs, function (log, key) {
                    if (total <= limit) {
                        if (typeof limited[dateKey] === 'undefined') {
                            limited[dateKey] = [];
                        }
                        limited[dateKey][key] = log;
                        total++;
                    } else {
                        return false;
                    }
                });
            });
            return limited;
        }

        vm.isMoreLogsAvailable = function () {
            return limit < totalMatching;
        };

        vm.handleLoadMore = function () {
            limit = limit + logsPerLoad;
            defaultFilters.limit = limit;
            fetchLogs();
            NominationsFactory.setLogsLimit(limit);
        };

        vm.isFetchInProgress = function () {
            return fetchInProgress;
        };

        function logsReloadRequestWatch(is) {
            if (is) {
                NominationsFactory.resetLogsReloadRequest();
                fetchLogs();
            }
        }

        function institutionCoreIdWatch(coreId) {
            if (coreId) {
                defaultFilters['filter[institutionCoreIdAsString]'] = '=' + InstitutionFactory.getCoreId();
                fetchLogs();
            }
        }

        function initWatches() {
            WatchService.create($scope, NominationsFactory.isLogsReloadRequest, logsReloadRequestWatch);
            WatchService.create($scope, InstitutionFactory.getCoreId, institutionCoreIdWatch);
        }

        function getIdsForDeletion() {
            var list = [];
            angular.forEach(vm.itemsToDelete, function (remove, id) {
                if (remove) {
                    list.push(id);
                }
            });
            return list;
        }

        vm.isClient = function () {
            return UserFactory.isClient();
        };

        vm.isDeleteButtonDisabled = function () {
            return deleteInProgress || !getIdsForDeletion().length || UserFactory.isClient();
        };

        vm.handleDeleteClick = function () {
            var modalOptions = {
                handleNoClick: function () {
                    this.close();
                },
                handleYesClick: function () {
                    deleteInProgress = true;
                    NominationsService.deleteLogs(getIdsForDeletion()).then(function (success) {
                        deleteInProgress = false;
                        if (success) {
                            NominationsFactory.requestLogsReload();
                            vm.itemsToDelete = {};
                        }
                        NotifierFactory.show(
                            success ? 'success' : 'error',
                            success ? 'Successfully!' : 'Failed!',
                            'Nomination Lists Upload Logs Deleted!'
                        );
                    });
                    this.close();
                },
                headerText: 'Nomination Lists History Logs Deletion',
                bodyText: 'Are you sure you want to delete the uploaded file history from Nomination Lists?'
            }, modalDefaults = {
                backdrop: true,
                keyboard: true,
                modalFade: true,
                templateUrl: '/scripts/shared/areYouSure/deleteAreYouSure.html'
            };
            ModalService.show(modalDefaults, modalOptions);
        };

        vm.$onInit = function () {
            limit = NominationsFactory.getLogsLimit() || logsPerLoad;
            defaultFilters.limit = limit;
            initWatches();
        };
    }

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.rankings')
        .controller('Rankings.NominationsListDatagridController', [
            '$scope',
            '$timeout',
            '$stateParams',
            'WatchService',
            'TimeService',
            'uiGridConstants',
            'UiGridService',
            'uiGridExporterService',
            'uiGridExporterConstants',
            'NominationsService',
            'Nominations.constants',
            'Nominations.gridNamespaces',
            'InstitutionFactory',
            'DateRangeFixerService',
            'NotifierFactory',
            NominationsListDatagrid
        ]);

    function NominationsListDatagrid(
        $scope,
        $timeout,
        $stateParams,
        WatchService,
        TimeService,
        uiGridConstants,
        GridService,
        uiGridExporterService,
        uiGridExporterConstants,
        NominationsService,
        nominationsConstants,
        gridNamespaces,
        InstitutionFactory,
        DateRangeFixerService,
        NotifierFactory
    ) {
        var vm = this,
            gridApiInstance = null,
            filtersTimeout,
            isRendered = false,
            filterChangesInProgress = false,
            columnsVisibility = {},
            paginationOptions = {},
            defaultPaginationOptions = {
                page: 1,
                limit: nominationsConstants.datagrid.defaultRowsNumber
            },
            defaultFilters = {
                modifiedAtRange: {
                    startDate: null,
                    endDate: null
                }
            },
            lastModifiedAtDateFilter = null;

        vm.noResults = false;
        vm.fileType = null;
        vm.filters = {};
        vm.isRowsRendered = false;
        vm.fetchInProgress = false;
        vm.isFileTypeChange = false;

        vm.handleFetch = function () {
            vm.fetchInProgress = true;
            vm.noResults = false;
            vm.isFiltersExist = false;
            checkFilters();

            if (vm.gridOptions &&  vm.gridOptions.data) {
                vm.gridOptions.data = [];
            }
            if(NominationsService.isClientSection()) {
                paginationOptions['filter[institutionCoreIdAsString]'] = '=' + InstitutionFactory.getCoreId().toString();
            }
            paginationOptions.fileType = vm.fileType.handle;
            NominationsService.search(
                angular.merge({}, paginationOptions, defaultPaginationOptions)
                ).then(function (data) {
                    vm.isFileTypeChange = false;
                    $timeout(function () {
                        vm.fetchInProgress = false;
                    },200);
                    setVisibilityAndFilters();
                    initDatagrid(data);
                    if (typeof data.results === 'undefined') {
                        NotifierFactory.show(
                            'error',
                            'There is a problem loading data, please try again later',
                            'Error'
                        );
                    }
                    if (!data.results || (data.results && data.results.length < 1)) {
                        vm.noResults = true;
                    }
                    if (gridApiInstance) {
                        gridApiInstance.grid.options.totalItems =  data.totalMatching;
                        gridApiInstance.grid.options.minRowsToShow =  data.totalFiltered;
                    }
            });
        };

        vm.handleFileTypeChanges = function () {
            vm.isFileTypeChange = true;
            setVisibilityAndFilters();
            vm.filters.fileType = vm.fileType.handle;
            paginationOptions.fileType = vm.fileType.handle;
            if (vm.gridOptions &&  vm.gridOptions.data) {
                vm.gridOptions = null;
            }
        };

        function setVisibilityAndFilters () {
            if (vm.fileType && vm.fileType.handle === 'academic') {
                if (NominationsService.isClientSection()) {
                    columnsVisibility = GridService.getColumnsVisibility(gridNamespaces.visibility.nominationsListAcademicClient);
                    paginationOptions = GridService.getColumnsFilters(gridNamespaces.filters.nominationsListAcademicClient);
                }
                else {
                    columnsVisibility = GridService.getColumnsVisibility(gridNamespaces.visibility.nominationsListAcademic);
                    paginationOptions = GridService.getColumnsFilters(gridNamespaces.filters.nominationsListAcademic);
                }
            }
            else if (vm.fileType) {
                if (NominationsService.isClientSection()) {
                    columnsVisibility = GridService.getColumnsVisibility(gridNamespaces.visibility.nominationsListEmployerClient);
                    paginationOptions = GridService.getColumnsFilters(gridNamespaces.filters.nominationsListEmployerClient);
                }
                else {
                    columnsVisibility = GridService.getColumnsVisibility(gridNamespaces.visibility.nominationsListEmployer);
                    paginationOptions = GridService.getColumnsFilters(gridNamespaces.filters.nominationsListEmployer);
                }

            }
        }

        function checkFilters () {
            angular.forEach(paginationOptions, function (value,key) {
                if (key !== 'fileType' && value !== null) {
                    vm.isFiltersExist = NominationsService.isClientSection() && key === 'filter[institutionCoreIdAsString]' ? false : true;
                }
            });
        }

        function reset () {
            vm.fileType = null;
            vm.noResults = false;
            if (vm.gridOptions &&  vm.gridOptions.data) {
                vm.gridOptions = null;
            }
        }

        function initDatagrid (data) {
            var dateFilterTemplate = '/scripts/shared/ui-grid/templates/dateFilterHeaderTemplate.html';

            vm.gridOptions = {
                data: data.results,
                totalItems: data.totalMatching,
                minRowsToShow: data.totalFiltered,
                appScopeProvider: vm,
                enableSorting: true,
                useExternalFiltering: true,
                enableGridMenu: true,
                showGridFooter: true,
                enableFiltering: true,
                enableColumnResize: true,
                enableFullRowSelection: false,
                enableRowSelection: false,
                multiSelect: false,
                enableRowHeaderSelection: false,
                exporterMenuCsv: false,
                exporterMenuAllData: false,
                exporterMenuSelectedData: false,
                exporterMenuPdf: false,
                paginationPageSizes: [25, 50, 100, 200],
                paginationPageSize: 25,
                useExternalPagination: true,
                useExternalSorting: true,
                gridMenuCustomItems: [
                    {
                        title: ' Export visible data as csv',
                        action: exportVisible,
                        order: 209
                    },
                    {
                        title: ' Export all data as csv',
                        action: exportAll,
                        order: 210
                    }
                ],
                columnDefs: [
                    {
                        displayName: 'Core ID',
                        field: 'institutionCoreIdAsString',
                        visible: !NominationsService.isClientSection(),
                        enableHiding: false,
                        enableColumnMenu: false,
                        enableSorting: false,
                        filter: {
                            term: GridService.getFilterByField(paginationOptions, 'institutionCoreIdAsString', '', false),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        field: 'institutionName',
                        visible: !NominationsService.isClientSection(),
                        enableHiding: false,
                        enableColumnMenu: false,
                        enableSorting: false,
                        filter: {
                            term: GridService.getFilterByField(paginationOptions, 'institutionName', '', false),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        field: 'source',
                        enableSorting: false,
                        visible: GridService.getVisibilityByField(columnsVisibility, 'source', true),
                        filter: {
                            term: GridService.getFilterByField(paginationOptions, 'source', '', false),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        field: 'title',
                        enableSorting: false,
                        visible: GridService.getVisibilityByField(columnsVisibility, 'title', true),
                        filter: {
                            term: GridService.getFilterByField(paginationOptions, 'title', '', false),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        field: 'firstName',
                        enableSorting: false,
                        visible: GridService.getVisibilityByField(columnsVisibility, 'firstName', true),
                        filter: {
                            term: GridService.getFilterByField(paginationOptions, 'firstName', '', false),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        field: 'lastName',
                        enableSorting: false,
                        visible: GridService.getVisibilityByField(columnsVisibility, 'lastName', true),
                        filter: {
                            term: GridService.getFilterByField(paginationOptions, 'lastName', '', false),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        field: 'position',
                        enableSorting: false,
                        visible: vm.filters.fileType === 'employer' && GridService.getVisibilityByField(columnsVisibility, 'position', true),
                        enableHiding: vm.filters.fileType === 'employer',
                        filter: {
                            term: GridService.getFilterByField(paginationOptions, 'position', '', false),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        field: 'industry',
                        enableSorting: false,
                        visible: vm.filters.fileType === 'employer' && GridService.getVisibilityByField(columnsVisibility, 'industry', true),
                        enableHiding: vm.filters.fileType === 'employer',
                        filter: {
                            term: GridService.getFilterByField(paginationOptions, 'industry', '', false),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        field: 'companyName',
                        enableSorting: false,
                        visible: vm.filters.fileType === 'employer' && GridService.getVisibilityByField(columnsVisibility, 'companyName', true),
                        enableHiding: vm.filters.fileType === 'employer',
                        filter: {
                            term: GridService.getFilterByField(paginationOptions, 'companyName', '', false),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        field: 'jobTitle',
                        enableSorting: false,
                        visible: vm.filters.fileType === 'academic' && GridService.getVisibilityByField(columnsVisibility, 'jobTitle', true),
                        enableHiding: vm.filters.fileType === 'academic',
                        filter: {
                            term: GridService.getFilterByField(paginationOptions, 'jobTitle', '', false),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        field: 'department',
                        enableSorting: false,
                        visible: vm.filters.fileType === 'academic' && GridService.getVisibilityByField(columnsVisibility, 'department', true),
                        enableHiding: vm.filters.fileType === 'academic',
                        filter: {
                            term: GridService.getFilterByField(paginationOptions, 'department', '', false),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        field: 'institution',
                        enableSorting: false,
                        visible: vm.filters.fileType === 'academic' && GridService.getVisibilityByField(columnsVisibility, 'institution', true),
                        enableHiding: vm.filters.fileType === 'academic',
                        filter: {
                            term: GridService.getFilterByField(paginationOptions, 'institution', '', false),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        field: 'subject',
                        enableSorting: false,
                        visible: GridService.getVisibilityByField(columnsVisibility, 'subject', true),
                        filter: {
                            term: GridService.getFilterByField(paginationOptions, 'subject', '', false),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        field: 'location',
                        displayName:'Country or Territory',
                        enableSorting: false,
                        visible: GridService.getVisibilityByField(columnsVisibility, 'location', true),
                        filter: {
                            term: GridService.getFilterByField(paginationOptions, 'location', '', false),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        field: 'email',
                        enableSorting: false,
                        visible: GridService.getVisibilityByField(columnsVisibility, 'email', true),
                        filter: {
                            term: GridService.getFilterByField(paginationOptions, 'email', '', false),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        displayName: 'Phone (Optional)',
                        enableSorting: false,
                        visible: GridService.getVisibilityByField(columnsVisibility, 'phone', true),
                        field: 'phone',
                        filter: {
                            term: GridService.getFilterByField(paginationOptions, 'phone', '', false),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        displayName: 'Uploaded Date',
                        field: 'modifiedAt',
                        enableFiltering: true,
                        filterCellFiltered: true,
                        enableSorting: true,
                        type: 'date',
                        cellFilter: 'date:\'medium\' : \'UTC\'',
                        sort: { direction: paginationOptions['sorting[modifiedAt]']},
                        filters: [
                            {
                                visible: true,
                                condition: uiGridConstants.filter.GREATER_THAN
                            },
                            {
                                condition: uiGridConstants.filter.LESS_THAN
                            }
                        ],
                        filter: {
                            term: GridService.getFilterByField(paginationOptions, 'modifiedAt', '', false),
                            applyTextFilter: applyDateFilter('modifiedAt', '')
                        },
                        filterHeaderTemplate: dateFilterTemplate,
                        visible: GridService.getVisibilityByField(columnsVisibility, 'modifiedAt', true)
                    }
                ],
                onRegisterApi: function (gridApi) {
                    gridApiInstance = gridApi;
                    gridApi.core.on.rowsRendered($scope, function () {
                        isRendered = true;
                    });

                    gridApi.pagination.on.paginationChanged($scope, function (newPage, limit) {
                        defaultPaginationOptions.page = newPage;
                        defaultPaginationOptions.limit = limit;
                        if(!filterChangesInProgress) {
                            vm.handleFetch();
                        }
                    });

                    gridApi.core.on.filterChanged($scope, handleGridFiltersChanges);
                    gridApi.core.on.rowsVisibleChanged($scope, handleGridVisibleChanges);
                    gridApi.core.on.sortChanged($scope, handleGridFiltersChanges);
                }
            };
            datePickersFixes();
            vm.filters = angular.merge({}, vm.filters, defaultFilters);
            $timeout(function () {
                vm.isRowsRendered = true;
            });
        }

        function datePickersFixes() {
            // This is needed to add 'x' icon in date field when prepopulating dates
            var modifiedAtField = GridService.getFilterByField(paginationOptions, 'modifiedAt', '');
            if (modifiedAtField !== null && paginationOptions["filter[modifiedAt]"] !== null) {
                if (angular.isDefined(modifiedAtField) &&
                    angular.isDefined(paginationOptions["filter[modifiedAt]"]) &&
                    angular.isDefined(paginationOptions["filter[modifiedAt]"].startDate) &&
                    angular.isDefined(paginationOptions["filter[modifiedAt]"].endDate)
                ) {
                    vm.gridOptions.columnDefs[getColumnNumber('modifiedAt')].filters[0].term = paginationOptions["filter[modifiedAt]"].startDate;
                    vm.gridOptions.columnDefs[getColumnNumber('modifiedAt')].filters[1].term = paginationOptions["filter[modifiedAt]"].endDate;
                }
            }
        }

        function getDateField(field) {
            var total = vm.gridOptions.columnDefs.length,
                i = 0;
            for (i; i < total; i++) {
                if (vm.gridOptions.columnDefs[i].type === 'date' &&
                    vm.gridOptions.columnDefs[i].field === field
                ) {
                    return vm.gridOptions.columnDefs[i];
                }
            }
        }

        function applyDateFilter(filterName, defaultValue) {
            var filter = GridService.getFilterByField(paginationOptions, filterName, defaultValue);
            if (angular.isDefined(filter) &&
                filter !== null
            ) {
                if (angular.isDefined(filter.startDate) &&
                    angular.isDefined(filter.endDate)
                ) {
                    if (filter.startDate !== null &&
                        filter.startDate !== null
                    ) {
                        defaultFilters[filterName + 'Range'] = {
                            startDate: parseInt(filter.startDate, 10),
                            endDate: parseInt(filter.endDate, 10)
                        };
                    } else {
                        defaultFilters[filterName + 'Range'] = {
                            startDate: null,
                            endDate: null
                        };
                    }
                }
            }
        }

        function exportVisible() {
            var results = gridApiInstance.grid.renderContainers.body.renderedRows;
            results = NominationsService.formatVisibleCsvResults(results);
            if (typeof results === 'undefined') {
                return false;
            }
            var gridApi = gridApiInstance,
                gridOptions = vm.gridOptions,
                exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                    gridApi.grid,
                    uiGridExporterConstants.VISIBLE
                );

            var exportData = GridService.getCsvData(gridApi.grid.columns, results);
            var csvContent = uiGridExporterService.formatAsCsv(
                exportColumnHeaders,
                exportData,
                gridOptions.exporterCsvColumnSeparator
            );
            uiGridExporterService.downloadFile(
                'nominations-list-'+paginationOptions.fileType+'-' + TimeService.now() + '.csv',
                csvContent,
                gridOptions.exporterOlderExcelCompatibility
            );
        }

        function exportAll() {
            if(NominationsService.isClientSection()) {
                paginationOptions['filter[institutionCoreIdAsString]'] = '=' + InstitutionFactory.getCoreId();
            }
            paginationOptions.fileType = vm.fileType.handle;
            NominationsService.search(
                angular.merge({}, paginationOptions, {
                    page: 1,
                    limit: 2147483647,
                    export: true
                })
            ).then(function (response) {
                if (typeof response.results === 'undefined') {
                    NotifierFactory.show(
                        'error',
                        'There is a problem in exporting data, please try again later',
                        'Error'
                    );
                    return false;
                }
                response = NominationsService.formatCsvResults(response.results);
                var gridApi = gridApiInstance,
                    gridOptions = vm.gridOptions,
                    exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                        gridApi.grid,
                        uiGridExporterConstants.VISIBLE
                    );

                var exportData = GridService.getCsvData(gridApi.grid.columns, response);
                var csvContent = uiGridExporterService.formatAsCsv(
                    exportColumnHeaders,
                    exportData,
                    gridOptions.exporterCsvColumnSeparator
                );
                uiGridExporterService.downloadFile(
                    'nominations-list-'+paginationOptions.fileType+'-all-' + TimeService.now() + '.csv',
                    csvContent,
                    gridOptions.exporterOlderExcelCompatibility
                );
            });
        }

        function handleGridVisibleChanges () {
            if (gridApiInstance.grid.selection.selectedCount === 1) {
                gridApiInstance.selection.clearSelectedRows();
            }
            var columnsVisibility = null,
            reload = false;
            if (vm.fileType && vm.fileType.handle === 'academic') {
                if (NominationsService.isClientSection()) {
                    columnsVisibility = GridService.getColumnsVisibility(gridNamespaces.visibility.nominationsListAcademicClient);
                }
                else {
                    columnsVisibility = GridService.getColumnsVisibility(gridNamespaces.visibility.nominationsListAcademic);
                }
            }
            else if (vm.fileType) {
                if (NominationsService.isClientSection()) {
                    columnsVisibility = GridService.getColumnsVisibility(gridNamespaces.visibility.nominationsListEmployerClient);
                }
                else {
                    columnsVisibility = GridService.getColumnsVisibility(gridNamespaces.visibility.nominationsListEmployer);
                }

            }
            if (columnsVisibility) {
                angular.forEach(vm.gridOptions.columnDefs, function (column, key) {
                    if (columnsVisibility[column.field] === true &&
                        column.visible === false
                    ) {
                        if (typeof  vm.gridOptions.columnDefs[key].filter === 'undefined') {
                            vm.gridOptions.columnDefs[key].filter = {};
                        }
                        // decide whether reload or no.
                        if ((angular.isDefined(vm.gridOptions.columnDefs[key].filter) &&
                            vm.gridOptions.columnDefs[key].filter.hasOwnProperty('term') &&
                            typeof vm.gridOptions.columnDefs[key].filter.term !== 'undefined' &&
                            vm.gridOptions.columnDefs[key].filter.term !== null &&
                            vm.gridOptions.columnDefs[key].filter.term.toString().length > 0) ||
                            ((angular.isDefined(vm.gridOptions.columnDefs[key].filters) &&
                            vm.gridOptions.columnDefs[key].filters[0].hasOwnProperty('term') &&
                                vm.gridOptions.columnDefs[key].filters[0].term &&
                                vm.gridOptions.columnDefs[key].filters[0].term.length > 0
                            ) || (
                                angular.isDefined(vm.gridOptions.columnDefs[key].filters) &&
                                vm.gridOptions.columnDefs[key].filters[1].hasOwnProperty('term') &&
                                vm.gridOptions.columnDefs[key].filters[1].term &&
                                vm.gridOptions.columnDefs[key].filters[1].term.length > 0
                            ))) {
                                paginationOptions['filter[' + column.field + ']'] = null;
                                reload = true;
                        }
                        vm.gridOptions.columnDefs[key].filter.term = null;
                    }
                });
            }
            if (vm.gridOptions) {
                vm.columnsVisibility = GridService.getGridColumnsVisibility(vm.gridOptions.columnDefs);
                if (vm.fileType && vm.fileType.handle === 'academic') {
                    if (NominationsService.isClientSection()) {
                        GridService.storeColumnsVisibility(
                            gridNamespaces.visibility.nominationsListAcademicClient,
                            vm.columnsVisibility
                        );
                    }
                    else {
                        GridService.storeColumnsVisibility(
                            gridNamespaces.visibility.nominationsListAcademic,
                            vm.columnsVisibility
                        );
                    }
                }
                else if (vm.fileType) {
                    if (NominationsService.isClientSection()) {
                        GridService.storeColumnsVisibility(
                            gridNamespaces.visibility.nominationsListEmployerClient,
                            vm.columnsVisibility
                        );
                    }
                    else {
                        GridService.storeColumnsVisibility(
                            gridNamespaces.visibility.nominationsListEmployer,
                            vm.columnsVisibility
                        );
                    }
                }
            }
            if (reload) {
                vm.handleFetch();
            }
        }


        function handleGridFiltersChanges() {
            filterChangesInProgress = true;
            if(gridApiInstance.pagination.getPage() > 1){
                gridApiInstance.pagination.seek(1);
            }
            var filterColumn, sortColumn, sortKeys = [];
            angular.forEach(gridApiInstance.grid.columns, function (column) {
                if (typeof column.filters !== 'undefined' && column.filters[0].term !== 'undefined') {
                    if (
                        column.filters[0].type === uiGridConstants.filter.SELECT &&
                        typeof column.filters[0].term === 'object' &&
                        column.filters[0].term !== null &&
                        typeof column.filters[0].term.value !== 'undefined'
                    ) {
                        column.filters[0].term = column.filters[0].term.value;
                    }
                }
                if (typeof column.sort.priority !== 'undefined') {
                    sortKeys[column.sort.priority] = column.name;
                }
            });
            if (sortKeys.length > 1) {
                var latestSortKey = sortKeys.pop();
                angular.forEach(gridApiInstance.grid.columns, function (column) {
                    if (typeof column.sort.priority !== 'undefined' && column.name !== latestSortKey) {
                        column.sort = {};
                    }
                });
            }
            angular.forEach(gridApiInstance.grid.columns, function (column) {
                filterColumn = column.filters[0].column ? column.filters[0].column : column.field;
                var deleteFilter = false;

                if (column.field === 'modifiedAt') {
                    sortColumn = column.sort.column ? column.sort.column : column.field;
                    paginationOptions['sorting[' + sortColumn + ']'] = column.sort.direction;
                    if (typeof column.filters !== 'undefined' &&
                        typeof column.filters[0].term !== 'undefined' &&
                        typeof column.filters[1].term !== 'undefined'
                    ) {
                        paginationOptions['filter[' + filterColumn + ']'] = {
                            startDate: column.filters[0].term && isNaN(column.filters[0].term) && typeof column.filters[0].term === 'object' ?
                                column.filters[0].term.format('x') : column.filters[0].term,
                            endDate: column.filters[1].term && isNaN(column.filters[1].term) && typeof column.filters[1].term === 'object' ?
                                column.filters[1].term.format('x') : column.filters[1].term
                        };
                    } else {
                        paginationOptions['filter[' + filterColumn + ']'] = null;
                    }
                } else {
                    if (column.filters[0].type === uiGridConstants.filter.SELECT &&
                        typeof column.filters[0].term === 'object' &&
                        column.filters[0].term !== null &&
                        typeof column.filters[0].term.value !== 'undefined'
                    ) {
                        column.filters[0].term = column.filters[0].term.value;
                    }
                    if (typeof column.filters !== 'undefined' &&
                        column.filters[0].term !== null &&
                        typeof column.filters[0].term !== 'undefined'
                    ) {
                        GridService.applyFilters(column);
                        if (column.filters[0].term === '') {
                            deleteFilter = true;
                        } else {
                            paginationOptions['filter[' + filterColumn + ']'] = column.filters[0].valuePrefix && !paginationOptions['filter[' + filterColumn + ']']? column.filters[0].valuePrefix + column.filters[0].term : column.filters[0].term;
                        }
                    } else {
                        deleteFilter = true;
                    }

                    if (deleteFilter && typeof paginationOptions['filter[' + filterColumn + ']'] !== 'undefined') {
                        delete paginationOptions['filter[' + filterColumn + ']'];
                    }
                }
            });
            dateFilters('modifiedAt');
            storeColumnFilters();

            if (angular.isDefined(filtersTimeout)) {
                $timeout.cancel(filtersTimeout);
            }
            filtersTimeout = $timeout(function () {
                vm.handleFetch();
                filterChangesInProgress = false;
            }, nominationsConstants.datagrid.datagridSelectDelay);
        }

        function getColumnNumber(columnName) {
            var key = 0,
                total = vm.gridOptions.columnDefs.length;
            for (key; key < total; key++) {
                if (vm.gridOptions.columnDefs[key] &&
                    vm.gridOptions.columnDefs[key].field === columnName
                ) {
                    return key;
                }
            }

            return null;
        }

        vm.handleModifiedAtRange = function (event) {
            if (DateRangeFixerService.sameDayAndNoModel(event, vm.filters.modifiedAtRange, lastModifiedAtDateFilter)) {
                event.model.startDate = lastModifiedAtDateFilter.startDate;
                event.model.endDate = lastModifiedAtDateFilter.endDate;
                vm.filters.modifiedAtRange = {
                    startDate: lastModifiedAtDateFilter.startDate,
                    endDate: lastModifiedAtDateFilter.endDate
                };
            } else if (DateRangeFixerService.todayRangeAndNoModel(event, lastModifiedAtDateFilter)) {
                event.model.startDate = TimeService.getStartOfToday();
                event.model.endDate = TimeService.getEndOfToday();
                vm.filters.modifiedAtRange = {
                    startDate: event.model.startDate.toISOString(),
                    endDate: event.model.endDate.toISOString()
                };
            }
            var modifiedAtField = getDateField('modifiedAt');
            if (modifiedAtField) {
                if (event === null || typeof event === 'undefined') {
                    modifiedAtField.filters[0].term = null;
                    modifiedAtField.filters[1].term = null;
                } else if (event.model.startDate && event.model.endDate) {
                    modifiedAtField.filters[0].term = typeof event.model.startDate === 'object' ? event.model.startDate.format('x') : event.model.startDate;
                    modifiedAtField.filters[1].term = typeof event.model.endDate === 'object' ? event.model.endDate.format('x') : event.model.endDate;
                }
            }
            lastModifiedAtDateFilter = angular.copy({
                startDate: event.model.startDate,
                endDate: event.model.endDate
            });
        };

        function dateFilters(field) {
            if (paginationOptions['filter[' + field + ']'] === null) {
                paginationOptions['filter[' + field + ']'] = {
                    startDate: null,
                    endDate: null
                };
            }
            if (paginationOptions['filter[' + field + ']'].startDate === null) {
                paginationOptions['filter[' + field + ']'].endDate = null;
                vm.filters[field + 'Range'] = {
                    startDate: null,
                    endDate: null
                };
            }
        }

        vm.showLoadBar = function () {
            return !isRendered || vm.fetchInProgress;
        };

        function storeColumnFilters () {
            if (vm.fileType.handle === 'academic') {
                if (NominationsService.isClientSection()) {
                    GridService.storeColumnsFilters(
                        gridNamespaces.filters.nominationsListAcademicClient,
                        paginationOptions
                    );
                }
                else {
                    GridService.storeColumnsFilters(
                        gridNamespaces.filters.nominationsListAcademic,
                        paginationOptions
                    );
                }
            }
            else {
                if (NominationsService.isClientSection()) {
                    GridService.storeColumnsFilters(
                        gridNamespaces.filters.nominationsListEmployerClient,
                        paginationOptions
                    );
                }
                else {
                    GridService.storeColumnsFilters(
                        gridNamespaces.filters.nominationsListEmployer,
                        paginationOptions
                    );
                }
            }
        }

        function getFileType(handle) {
            var total = vm.fileTypes.length,
                i = 0;
            for (i; i < total; i++) {
                if (vm.fileTypes[i].handle === handle) {
                    return vm.fileTypes[i];
                }
            }
            return null;
        }

        vm.initWatches = function () {
            WatchService.create($scope, InstitutionFactory.getCoreId, institutionCoreIdWatch);
        };

        function institutionCoreIdWatch(coreId) {
            if (coreId && NominationsService.isClientSection()) {
                paginationOptions['filter[institutionCoreIdAsString]'] = '=' + InstitutionFactory.getCoreId().toString();
                reset();
            }
        }

        function init() {
            NominationsService.getFileTypes().then(function (types) {
                vm.fileTypes = types;
                if ($stateParams && $stateParams.institutionCoreId) {
                    vm.filters = $stateParams;
                    vm.fileType = getFileType($stateParams.fileType);
                    vm.isFileTypeChange = true;
                    setVisibilityAndFilters();
                    paginationOptions['filter[institutionCoreIdAsString]'] = '=' + $stateParams.institutionCoreId.toString();
                    storeColumnFilters();
                    paginationOptions.fileType = $stateParams.fileType;
                    vm.handleFetch();
                }
            });
            vm.initWatches();
        }

        init();
    }

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.rankings')
        .controller('Rankings.NominationsListReportsDatagridController', [
            '$scope',
            '$timeout',
            '$state',
            'constants',
            'WatchService',
            'NominationsFactory',
            'NominationsService',
            'uiGridConstants',
            'UiGridService',
            'uiGridExporterService',
            'uiGridExporterConstants',
            'TimeService',
            'DateRangeFixerService',
            'Nominations.constants',
            'Nominations.gridNamespaces',
            'InstitutionsListService',
            'InstitutionsListFactory',
            NominationsListReportsDatagrid
        ]);

    function NominationsListReportsDatagrid(
        $scope,
        $timeout,
        $state,
        constants,
        WatchService,
        NominationsFactory,
        NominationsService,
        uiGridConstants,
        GridService,
        uiGridExporterService,
        uiGridExporterConstants,
        TimeService,
        DateRangeFixerService,
        nominationsConstants,
        gridNamespaces,
        InstitutionsListService,
        InstitutionsListFactory
    ) {
        var vm = this,
            gridApiInstance = null,
            filtersTimeout,
            loadingData = false,
            isRendered = false,
            filterChangesInProgress = false,
            columnsFilters = GridService.getColumnsFilters(gridNamespaces.filters.nominationsListReports, []),
            columnsVisibility = GridService.getColumnsVisibility(
                gridNamespaces.visibility.nominationsListReports
            ),
            defaultFilters = {
                createdAtRange: {
                    startDate: null,
                    endDate: null
                }
            },
            paginationOptions = GridService.getColumnsFilters(
                gridNamespaces.filters.nominationsListReports
            ),
            defaultPaginationOptions = {
                page: 1,
                limit: nominationsConstants.datagrid.defaultRowsNumber,
                'sorting[createdAt]': 'desc'
            },
            lastCreatedAtDateFilter = null;

            vm.filters = null;
            vm.countriesList = [];

        function exportVisible() {
            var results = vm.gridOptions.data;
            results = NominationsService.formatCsvResults(results);
            if (typeof results === 'undefined') {
                return false;
            }
            var gridApi = gridApiInstance,
                gridOptions = vm.gridOptions,
                exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                    gridApi.grid,
                    uiGridExporterConstants.VISIBLE
                );

            var exportData = GridService.getCsvData(gridApi.grid.columns, results);
            var csvContent = uiGridExporterService.formatAsCsv(
                exportColumnHeaders,
                exportData,
                gridOptions.exporterCsvColumnSeparator
            );
            uiGridExporterService.downloadFile(
                'nominations-reports' + TimeService.now() + '.csv',
                csvContent,
                gridOptions.exporterOlderExcelCompatibility
            );
        }

        function exportAll() {
            NominationsService.searchLatestReports(
                angular.merge({}, paginationOptions, {
                    page: 1,
                    limit: 250000,
                    export: true
                })
            ).then(function (response) {
                if (typeof response.results === 'undefined') {
                    return false;
                }
                response = NominationsService.formatCsvResults(response.results);
                var gridApi = gridApiInstance,
                    gridOptions = vm.gridOptions,
                    exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                        gridApi.grid,
                        uiGridExporterConstants.VISIBLE
                    );

                var exportData = GridService.getCsvData(gridApi.grid.columns, response);
                var csvContent = uiGridExporterService.formatAsCsv(
                    exportColumnHeaders,
                    exportData,
                    gridOptions.exporterCsvColumnSeparator
                );
                uiGridExporterService.downloadFile(
                    'nominations-reports-full' + TimeService.now() + '.csv',
                    csvContent,
                    gridOptions.exporterOlderExcelCompatibility
                );
            });
        }

        function applyDateFilter(filterName, defaultValue) {
            var filter = GridService.getFilterByField(paginationOptions, filterName, defaultValue);
            if (angular.isDefined(filter) &&
                filter !== null
            ) {
                if (angular.isDefined(filter.startDate) &&
                    angular.isDefined(filter.endDate)
                ) {
                    if (filter.startDate !== null &&
                        filter.startDate !== null
                    ) {
                        defaultFilters[filterName + 'Range'] = {
                            startDate: parseInt(filter.startDate, 10),
                            endDate: parseInt(filter.endDate, 10)
                        };
                    } else {
                        defaultFilters[filterName + 'Range'] = {
                            startDate: null,
                            endDate: null
                        };
                    }
                }
            }
        }

        function initDatagridOptions () {
            var rowTemplate = '/scripts/shared/ui-grid/templates/rowTemplate.html',
                dateCellTemplate = '/scripts/modules/messages/datagrid/dateCellTemplate.html',
                dateFilterTemplate = '/scripts/shared/ui-grid/templates/dateFilterHeaderTemplate.html',
                selectTemplate = '/scripts/shared/ui-grid/templates/selectFilterHeaderTemplate.html',
                selectCellTemplate = '/scripts/shared/ui-grid/templates/selectCellTemplate.html',
                multiSelectTemplate = '/scripts/shared/ui-grid/templates/multiSelectFilterHeaderTemplate.html';

                vm.gridOptions = {
                appScopeProvider: vm,
                enableSorting: false,
                useExternalFiltering: true,
                enableGridMenu: true,
                showGridFooter: true,
                enableFiltering: true,
                enableColumnResize: true,
                enableFullRowSelection: true,
                enableRowSelection: true,
                multiSelect: false,
                enableRowHeaderSelection: false,
                rowTemplate: rowTemplate,
                exporterMenuCsv: false,
                exporterMenuAllData: false,
                exporterMenuSelectedData: false,
                exporterMenuPdf: false,
                paginationPageSizes: [25, 50, 100, 200],
                paginationPageSize: 25,
                useExternalPagination: true,
                gridMenuCustomItems: [
                    {
                        title: ' Export visible data as csv',
                        action: exportVisible,
                        order: 209
                    },
                    {
                        title: ' Export all data as csv',
                        action: exportAll,
                        order: 210
                    }
                ],
                columnDefs: [
                    {
                        displayName: 'Core ID',
                        field: 'institutionCoreIdAsString',
                        visible: GridService.getVisibilityByField(columnsVisibility, 'institutionCoreIdAsString', true),
                        filter: {
                            term: GridService.getFilterByField(columnsFilters, 'institutionCoreIdAsString', '', false),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        displayName: 'Country',
                        field: 'countryCode',
                        visible: GridService.getVisibilityByField(columnsVisibility, 'countryCode'),
                        filter: {
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: vm.countriesList,
                            term: GridService.getFilterByField(columnsFilters, 'countryCode',[], true),
                            searchEnabled: true,
                            bindToField: true,
                            condition: function (searchTerms, cellValue) {
                                if (angular.isDefined(searchTerms) && searchTerms.length > 0) {
                                    var searchTermsArray = [];
                                    angular.forEach(searchTerms, function (searchTerm) {
                                        searchTermsArray.push(searchTerm.value);
                                    });
                                    return searchTermsArray.indexOf(cellValue) !== -1;
                                } else {
                                    return true;
                                }
                            }
                        },
                        filterHeaderTemplate: multiSelectTemplate,
                        cellTemplate: selectCellTemplate
                    },
                    {
                        displayName: 'Institution Name',
                        field: 'institutionName',
                        visible: GridService.getVisibilityByField(columnsVisibility, 'institutionName', true),
                        filter: {
                            term: GridService.getFilterByField(columnsFilters, 'institutionName', '', false),
                            condition: uiGridConstants.filter.CONTAINS
                        },
                        minWidth: 320
                    },
                    {
                        displayName: 'File Type',
                        field: 'filterType',
                        filter: {
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: [
                                {value: 'academic', label: 'Academic'},
                                {value: 'employer', label: 'Employer'},
                            ],
                            term: GridService.getFilterByField(columnsFilters, 'filterType')
                        },
                        filterHeaderTemplate: selectTemplate,
                        visible: GridService.getVisibilityByField(columnsVisibility, 'filterType', true),
                        cellFilter: 'capitaliseFirstLetter',
                    },
                    {
                        displayName: 'Uploaded Date',
                        field: 'createdAt',
                        enableFiltering: true,
                        filterCellFiltered: true,
                        enableSorting: false,
                        type: 'date',
                        cellFilter: 'date:\'medium\' : \'UTC\'',
                        filters: [
                            {
                                visible: true,
                                condition: uiGridConstants.filter.GREATER_THAN
                            },
                            {
                                condition: uiGridConstants.filter.LESS_THAN
                            }
                        ],
                        filter: {
                            term: GridService.getFilterByField(columnsFilters, 'createdAt', '', false),
                            applyTextFilter: applyDateFilter('createdAt', '')
                        },
                        //cellTemplate: dateCellTemplate,
                        filterHeaderTemplate: dateFilterTemplate,
                        visible: GridService.getVisibilityByField(columnsVisibility, 'createdAt', true)
                    },
                    {
                        displayName: 'Uploaded By',
                        field: 'createdByFullName',
                        visible: GridService.getVisibilityByField(columnsVisibility, 'createdByFullName', true),
                        filter: {
                            term: GridService.getFilterByField(columnsFilters, 'createdByFullName', ''),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        displayName: 'Number of Contacts',
                        field: 'numberOfContacts',
                        visible: GridService.getVisibilityByField(columnsVisibility, 'numberOfContacts', true),
                        filter: {
                            term: GridService.getFilterByField(columnsFilters, 'numberOfContacts', ''),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    }
                ],
                onRegisterApi: function (gridApi) {
                    gridApiInstance = gridApi;
                    gridApi.core.on.rowsRendered($scope, function () {
                        isRendered = true;
                    });

                    gridApi.selection.on.rowSelectionChanged($scope, function (row) {
                         handleDatagridRowClick(row.entity);
                    });

                    gridApi.pagination.on.paginationChanged($scope, function (newPage, limit) {
                        defaultPaginationOptions.page = newPage;
                        defaultPaginationOptions.limit = limit;
                        if(!filterChangesInProgress) {
                            getPage();
                        }
                    });

                    gridApi.core.on.filterChanged($scope, handleGridFiltersChanges);
                    gridApi.core.on.rowsVisibleChanged($scope, handleGridVisibleChanges);
                    getPage();
                }
            };

            datePickersFixes();
            vm.filters = angular.extend({}, defaultFilters);
        }

        function getColumnNumber(columnName) {
            var key = 0,
                total = vm.gridOptions.columnDefs.length;
            for (key; key < total; key++) {
                if (vm.gridOptions.columnDefs[key] &&
                    vm.gridOptions.columnDefs[key].field === columnName
                ) {
                    return key;
                }
            }

            return null;
        }

        function datePickersFixes() {
            // This is needed to add 'x' icon in date field when prepopulating dates
            var createdAtField = GridService.getFilterByField(paginationOptions, 'createdAt', '');
            if (createdAtField !== null && paginationOptions["filter[createdAt]"] !== null) {
                if (angular.isDefined(createdAtField) &&
                    angular.isDefined(paginationOptions["filter[createdAt]"]) &&
                    angular.isDefined(paginationOptions["filter[createdAt]"].startDate) &&
                    angular.isDefined(paginationOptions["filter[createdAt]"].endDate)
                ) {
                    vm.gridOptions.columnDefs[getColumnNumber('createdAt')].filters[0].term = paginationOptions["filter[createdAt]"].startDate;
                    vm.gridOptions.columnDefs[getColumnNumber('createdAt')].filters[1].term = paginationOptions["filter[createdAt]"].endDate;
                }
            }
        }

        function handleGridVisibleChanges () {
            if (!vm.selectedRowId) {
                if (gridApiInstance.grid.selection.selectedCount === 1) {
                    gridApiInstance.selection.clearSelectedRows();
                }
            }
            var columnsVisibility = GridService.getColumnsVisibility(gridNamespaces.visibility.nominationsListReports),
            reload = false;
            if (columnsVisibility && !vm.selectedRowId) {
                angular.forEach(vm.gridOptions.columnDefs, function (column, key) {
                    if (columnsVisibility[column.field] === true &&
                        column.visible === false
                    ) {
                        if (typeof  vm.gridOptions.columnDefs[key].filter === 'undefined') {
                            vm.gridOptions.columnDefs[key].filter = {};
                        }
                        // decide whether reload or no.
                        if ((angular.isDefined(vm.gridOptions.columnDefs[key].filter) &&
                            vm.gridOptions.columnDefs[key].filter.hasOwnProperty('term') &&
                            typeof vm.gridOptions.columnDefs[key].filter.term !== 'undefined' &&
                            vm.gridOptions.columnDefs[key].filter.term !== null &&
                            vm.gridOptions.columnDefs[key].filter.term.toString().length > 0) ||
                            ((angular.isDefined(vm.gridOptions.columnDefs[key].filters) &&
                            vm.gridOptions.columnDefs[key].filters[0].hasOwnProperty('term') &&
                                vm.gridOptions.columnDefs[key].filters[0].term &&
                                vm.gridOptions.columnDefs[key].filters[0].term.length > 0
                            ) || (
                                angular.isDefined(vm.gridOptions.columnDefs[key].filters) &&
                                vm.gridOptions.columnDefs[key].filters[1].hasOwnProperty('term') &&
                                vm.gridOptions.columnDefs[key].filters[1].term &&
                                vm.gridOptions.columnDefs[key].filters[1].term.length > 0
                            ))) {
                                if(column.field === 'countryCode'){
                                    paginationOptions['filter[countryCode][]'] = null;
                                }
                                paginationOptions['filter[' + column.field + ']'] = null;
                                reload = true;
                        }
                        vm.gridOptions.columnDefs[key].filter.term = null;
                        columnsFilters['filter[' + column.field + ']'] = null;
                        if (column.field === 'createdAt') {
                            delete  vm.gridOptions.columnDefs[key].filters[0].term;
                            delete  vm.gridOptions.columnDefs[key].filters[1].term;
                            vm.filters.createdAtRange = columnsFilters['filter[createdAt]'] = {
                                startDate: null,
                                endDate: null
                            };
                        }
                    }
                });
            }
            if (vm.gridOptions) {
                vm.columnsVisibility = GridService.getGridColumnsVisibility(vm.gridOptions.columnDefs);
                if(!vm.selectedRowId) {
                    GridService.storeColumnsVisibility(
                        gridNamespaces.visibility.nominationsListReports,
                        vm.columnsVisibility
                    );
                }
            }
            if (reload) {
                getPage();
            }
        }

        function handleGridFiltersChanges() {
            filterChangesInProgress = true;
            if(gridApiInstance.pagination.getPage() > 1){
                gridApiInstance.pagination.seek(1);
            }
            angular.forEach(gridApiInstance.grid.columns, function (column) {
                var filterColumn = column.filters[0].column ? column.filters[0].column : column.field,
                    deleteFilter = false;

                if (column.field === 'createdAt') {
                    if (typeof column.filters !== 'undefined' &&
                        typeof column.filters[0].term !== 'undefined' &&
                        typeof column.filters[1].term !== 'undefined'
                    ) {
                        paginationOptions['filter[' + filterColumn + ']'] = {
                            startDate: column.filters[0].term && isNaN(column.filters[0].term) && typeof column.filters[0].term === 'object' ?
                                column.filters[0].term.format('x') : column.filters[0].term,
                            endDate: column.filters[1].term && isNaN(column.filters[1].term) && typeof column.filters[1].term === 'object' ?
                                column.filters[1].term.format('x') : column.filters[1].term
                        };
                    } else {
                        paginationOptions['filter[' + filterColumn + ']'] = null;
                    }
                } else {
                    if (column.filters[0].type === uiGridConstants.filter.SELECT &&
                        typeof column.filters[0].term === 'object' &&
                        column.filters[0].term !== null &&
                        typeof column.filters[0].term.value !== 'undefined'
                    ) {
                        column.filters[0].term = column.filters[0].term.value;
                    }
                    if (typeof column.filters !== 'undefined' &&
                        column.filters[0].term !== null &&
                        typeof column.filters[0].term !== 'undefined'
                    ) {
                        GridService.applyFilters(column);
                        if (column.filters[0].term === '') {
                            deleteFilter = true;
                        } else {
                            if(filterColumn=== 'countryCode'){
                                paginationOptions['filter[' + filterColumn + '][]'] = column.filters[0].valuePrefix && !paginationOptions['filter[' + filterColumn + ']']? column.filters[0].valuePrefix + column.filters[0].term : column.filters[0].term;
                            }
                            else {
                                paginationOptions['filter[' + filterColumn + ']'] = column.filters[0].valuePrefix && !paginationOptions['filter[' + filterColumn + ']']? column.filters[0].valuePrefix + column.filters[0].term : column.filters[0].term;
                            }
                        }
                    } else {
                        deleteFilter = true;
                    }
                    if (deleteFilter && (typeof paginationOptions['filter[' + filterColumn + ']'] !== 'undefined' ||
                         typeof paginationOptions['filter[' + filterColumn + '][]'] !== 'undefined')) {
                        if(filterColumn=== 'countryCode'){ 
                            delete  paginationOptions['filter[' + filterColumn + '][]'];
                        }
                        delete paginationOptions['filter[' + filterColumn + ']'];
                    }
                }

            });
            dateFilters('createdAt');

            GridService.storeColumnsFilters(
                gridNamespaces.filters.nominationsListReports,
                paginationOptions
            );

            if (angular.isDefined(filtersTimeout)) {
                $timeout.cancel(filtersTimeout);
            }
            filtersTimeout = $timeout(function () {
                getPage();
                filterChangesInProgress = false;
            }, constants.datagridSelectDelay);
        }

        function dateFilters(field) {
            if (paginationOptions['filter[' + field + ']'] === null) {
                paginationOptions['filter[' + field + ']'] = {
                    startDate: null,
                    endDate: null
                };
            }
            if (paginationOptions['filter[' + field + ']'].startDate === null) {
                paginationOptions['filter[' + field + ']'].endDate = null;
                vm.filters[field + 'Range'] = {
                    startDate: null,
                    endDate: null
                };
            }
        }

        function getPage() {
            loadingData = true;
            return NominationsService.searchLatestReports(
                angular.merge({}, paginationOptions, defaultPaginationOptions)
            ).then(function (response) {
                vm.gridOptions.totalItems = response.totalMatching;
                vm.gridOptions.data = response.results;
                vm.gridOptions.minRowsToShow = response.totalFiltered;
                $timeout(function () {
                    loadingData = false;
                },200);
            });
        }

        function handleDatagridRowClick(row) {
            var path = 'staff.iu.nominations.lists',
            params = {
                institutionCoreId: row.institutionCoreId,
                fileType: row.filterType
            };
            $state.go(path,params);
            vm.selectedRowId = (vm.selectedRowId === row.id) ? null : row.id;
            vm.selectedItem = angular.copy(row);
        }

        function getDateField(field) {
            var total = vm.gridOptions.columnDefs.length,
                i = 0;
            for (i; i < total; i++) {
                if (vm.gridOptions.columnDefs[i].type === 'date' &&
                    vm.gridOptions.columnDefs[i].field === field
                ) {
                    return vm.gridOptions.columnDefs[i];
                }
            }
        }

        vm.handleCreatedAtRange = function (event) {
            if (DateRangeFixerService.sameDayAndNoModel(event, vm.filters.createdAtRange, lastCreatedAtDateFilter)) {
                event.model.startDate = lastCreatedAtDateFilter.startDate;
                event.model.endDate = lastCreatedAtDateFilter.endDate;
                vm.filters.createdAtRange = {
                    startDate: lastCreatedAtDateFilter.startDate,
                    endDate: lastCreatedAtDateFilter.endDate
                };
            } else if (DateRangeFixerService.todayRangeAndNoModel(event, lastCreatedAtDateFilter)) {
                event.model.startDate = TimeService.getStartOfToday();
                event.model.endDate = TimeService.getEndOfToday();
                vm.filters.createdAtRange = {
                    startDate: event.model.startDate.toISOString(),
                    endDate: event.model.endDate.toISOString()
                };
            }
            var createdAtField = getDateField('createdAt');
            if (createdAtField) {
                if (event === null || typeof event === 'undefined') {
                    createdAtField.filters[0].term = null;
                    createdAtField.filters[1].term = null;
                } else if (event.model.startDate && event.model.endDate) {
                    createdAtField.filters[0].term = typeof event.model.startDate === 'object' ? event.model.startDate.format('x') : event.model.startDate;
                    createdAtField.filters[1].term = typeof event.model.endDate === 'object' ? event.model.endDate.format('x') : event.model.endDate;
                }
            }
            lastCreatedAtDateFilter = angular.copy({
                startDate: event.model.startDate,
                endDate: event.model.endDate
            });
        };

        vm.showLoadBar = function () {
            return !isRendered || loadingData;
        };

        function initWatches() {
        }

        function init() {
            initWatches();
            initDatagridOptions();
            InstitutionsListService.getCountries().then(function (results) {
                angular.forEach(results, function (data) {
                    vm.countriesList.push({
                        value: data.countryCode,
                        label: data.name
                    });
                });
                InstitutionsListFactory.setCountries(vm.countriesList);
            });
        }

        init();
    }

}(window.angular));

(function(angular) {
    "use strict";

    angular
        .module('qsHub')
        .factory('ResponseFactory', [
            Response
        ]);

    function Response () {
        return Instance;

        function Instance () {
            var error = null,
                success = null,
                errorMessage = null,
                insertId = null,
                factory = this;

            factory.isError = function () {
                return error === true;
            };

            factory.isSuccess = function () {
                return success === true;
            };

            factory.hasErrorMessage = function () {
                return !!(errorMessage && errorMessage.length);
            };

            factory.setError = function () {
                error = true;
                success = false;
            };

            factory.setSuccess = function () {
                success = true;
                error = false;
            };

            factory.setErrorMessage = function (value) {
                error = true;
                success = false;
                errorMessage = value;
            };

            factory.getErrorMessage = function () {
                return errorMessage;
            };

            factory.setInsertId = function (newId) {
                insertId = newId;
            };

            factory.getInsertId = function () {
                return insertId;
            };
        }
    }

}(window.angular));

(function (angular) {
    'use strict';

    angular
        .module('qsHub')
        .directive('upgradeBanner', Directive);

    function Directive() {
        return {
            restrict: 'A',
            scope: {
                basicProfile: '&',
                tmProfile:'&',
                upgradeClick: '&',
                infoBlockClass: '=',
                buttonsBlockClass: '='
            },
            templateUrl: '/scripts/shared/upgradeBanner/upgradeBannerView.html',
            link: function (scope) {
                scope.getInfoBlockClass = function () {
                    if (scope.infoBlockClass) {
                        return scope.infoBlockClass;
                    }
                };
                scope.getButtonsBlockClass = function () {
                    if (scope.buttonsBlockClass) {
                        return scope.buttonsBlockClass;
                    }
                };
            }
        };
    }

}(window.angular));

(function (angular) {
    'use strict';

    angular
        .module('qsHub.profiles')
        .config(config, [
            '$stateProvider',
            'errors'
        ]);

    function config(
        $stateProvider,
        errors
    ) {
        $stateProvider
            .state('staff.institutions.tu-programs-url-checker', {
                url: '/institutions/tu-programs/url-checker',
                templateUrl: '/scripts/modules/profiles/components/tu-programs-url-checker/tuProgramsUrlCheckerView.html',
                controller: 'TuProgramsUrlCheckerController',
                controllerAs: 'TuProgramsUrlCheckerController',
                data: {
                    pageTitle: 'TU Programs URL Checker',
                    label: 'TU Programs URL Checker'
                },
                resolve: {
                    security: [
                        '$q', 'UserFactory', 'AuthenticationService', 'TuProfileFactory',
                        function ($q, UserFactory, AuthenticationService, TuProfileFactory) {
                            UserFactory.setData(AuthenticationService.getUserData());
                            TuProfileFactory.setIsProgramOverview(true);
                            if (!UserFactory.hasTuProgramsOverviewAccess()) {
                                return $q.reject(errors[401]);
                            } else if (!UserFactory.hasTuProgramsOverviewAccess()) {
                                return $q.reject(errors[403]);
                            }
                            return $q.reject(errors[403]);
                        }
                    ]
                }
            }).state('staff.institutions.courses', {
                url: '/institutions/courses',
                templateUrl: '/scripts/modules/profiles/components/courses/coursesOverview/coursesOverviewView.html',
                data: {
                    pageTitle: 'Matching Tool Overview',
                    label: 'Matching Tool Overview'
                },
                resolve: {
                    security: [
                        '$q', 'UserFactory', 'AuthenticationService', 'TuProfileFactory',
                        function ($q, UserFactory, AuthenticationService, TuProfileFactory) {
                            UserFactory.setData(AuthenticationService.getUserData());
                            TuProfileFactory.setIsProgramOverview(true);
                            if (!UserFactory.hasTuProgramsOverviewAccess()) {
                                return $q.reject(errors[401]);
                            } else if (!UserFactory.hasTuProgramsOverviewAccess()) {
                                return $q.reject(errors[403]);
                            }
                            return $q.reject(errors[403]);
                        }
                    ]
                }
            }).state('clients.profiles.tu.entryRequirements', {
                url: '/entry-requirements',
                data: {
                    label: 'Entry Requirements'
                }
            });
    }

}(window.angular));

(function(angular) {
    'use strict';

    var gridNamespaces = {
        filters: {
            coursesOverview : 'coursesOverviewFilters',
            matchingTool : 'matchingToolFilters'
        },
        visibility: {
            coursesOverview : 'coursesOverviewVisibility',
            matchingTool : 'matchingToolVisibility'
        }
    };

    angular
        .module('qsHub.profiles')
        .constant('profiles.gridNamespaces', gridNamespaces);

}(window.angular));

(function (angular) {
    "use strict";

    angular.module('qsHub.profiles').directive('publishField', ['UserFactory', function (UserFactory) {
        return {
            restrict: 'C',
            transclude: true,
            templateUrl: '/scripts/modules/profiles/components/publishField/publishFieldView.html',
            link: function ($scope, $element, attrs, ngModelCtrl, transclude) {
                $scope.isQs = !UserFactory.isClient();
                $element.prepend(transclude());
            }
        };
    }]);

}(window.angular));

(function(angular) {
    "use strict";

    angular
        .module('qsHub.profiles')
        .controller('TuProgramsUrlCheckerController', [
            '$q',
            '$timeout',
            '$state',
            'TuProgramsUrlCheckerService',
            'WebSocketsService',
            'uiGridExporterService',
            'UrlService',
            'UserFactory',
            'TimeService',
            TuProgramsUrlChecker
        ]);

    function TuProgramsUrlChecker(
        $q,
        $timeout,
        $state,
        TuProgramsUrlCheckerService,
        WebSocketsService,
        uiGridExporterService,
        UrlService,
        UserFactory,
        TimeService
    ) {
        var controller = this,
            inProgress = false,
            leftToProcess,
            programs,
            room = 'urlChecker',
            originalState,
            hasFailures,
            includeAdvancedProfiles = false;

        controller.download = function () {
            var exportData = [];
            angular.forEach(controller.failedPrograms, function (item) {
                exportData.push([
                    {value: item.institutionCoreId},
                    {value: item.name},
                    {value: item.url},
                    {value: item.code}
                ]);
            });

            var csvContent = uiGridExporterService.formatAsCsv([
                {displayName: 'Institution Core ID'},
                {displayName: 'Name'},
                {displayName: 'Url'},
                {displayName: 'HTTP Code'}
            ], exportData);
            uiGridExporterService.downloadFile(
                'tu-programs-with-invalid-urls.csv',
                csvContent
            );
        };

        controller.processedRecords = function processedRecords() {
            return controller.total - leftToProcess;
        };

        function reset() {
            programs = {};
            controller.failedPrograms = [];
            controller.total = 0;
            controller.error = null;
            leftToProcess = 0;
            controller.info = {
                load: '',
                preparing: '',
                waiting: '',
                progress: '',
                invalid: '',
                completed: '',
            };
            hasFailures = false;
        }

        function addStartMessage() {
            controller.info.load = 'Starting the process...';
        }

        function setProgressMessage() {
            controller.info.progress = leftToProcess + ' urls left to check out of ' + controller.total;
        }

        function setInvalidMessage() {
            controller.info.invalid = controller.failedPrograms.length + ' invalid URLs';
        }

        function setCompleteMessage() {
            controller.info.completed = 'Completed';
        }

        function setPrepareMessage() {
            controller.info.preparing = 'Preparing for scheduling programs URLs validation...';
        }

        function setWaitingMessage() {
            controller.info.waiting = 'Waiting for results....';
        }

        function triggerCheck() {
            var uniqueId = UserFactory.getUserName() + ':' + TimeService.nowUnix();
            // subscribe to checks results
            addStartMessage();
            subscribe(uniqueId).then(function (subscribed) {
                if (subscribed) {
                    setPrepareMessage();

                    // request url checks
                    TuProgramsUrlCheckerService.validateTuPrograms(uniqueId, controller.includeAdvancedProfiles).then(function () {
                        setWaitingMessage();

                    });
                } else {
                    controller.error = 'Can\'t connect to URL checker';
                }
            });
        }

        function getErrorMessage(errorCode) {
            if (!errorCode) {
                return null;
            }
            switch (errorCode) {
                case 'ENOTFOUND':
                    return 'Domain not found';
                case 'EHOSTUNREACH':
                    return 'Loads too long, skipping';
                default:
                    return errorCode;
            }
        }

        function subscribe(uniqueId) {
            var deferred = $q.defer(),
                processed = false;
            WebSocketsService.subscribe(room, 'isValid' + uniqueId, function (result) {
                if (!processed) {
                    controller.total = result.total;
                    leftToProcess = result.total;
                    processed = true;
                }

                leftToProcess--;
                if (!result.success) {
                    controller.failedPrograms.push({
                        code: result.code || getErrorMessage(result.error),
                        url: UrlService.prependHttp(result.url),
                        name: result.name,
                        institutionCoreId: result.institutionCoreId
                    });
                    hasFailures = true;
                }
                setProgressMessage();

                if (leftToProcess === 0) {
                    inProgress = false;
                    setInvalidMessage();
                    setCompleteMessage();
                }
            }, function (subscribed) {
                return deferred.resolve(subscribed);
            });

            return deferred.promise;
        }

        controller.handleClick = function () {
            inProgress = true;
            reset();
            triggerCheck();
        };

        controller.isDisabled = function () {
            return inProgress;
        };

        controller.showFailures = function () {
            return hasFailures;
        };

        function loadLogs() {
            TuProgramsUrlCheckerService.getLogs().then(function (results) {
                controller.logs = results;
                if (results.length) {
                    loadLatest();
                }
            });
        }

        function loadLatest() {
            addStartMessage();

            inProgress = true;
            TuProgramsUrlCheckerService.getLatest().then(function (results) {
                if (!results || typeof results.lastGroup === 'undefined') {
                    inProgress = false;
                    setCompleteMessage();
                    return false;
                }
                controller.includeAdvancedProfiles = results && results.advanced;
                if (!results.count) {
                    leftToProcess = results.count;
                    inProgress = false;
                } else {
                    leftToProcess = results.pending + results.inProgress;
                }
                controller.total = results.total;
                controller.failedPrograms = [];
                setPrepareMessage();
                setWaitingMessage();
                setProgressMessage();
                angular.forEach(results.lastGroup, function (result) {
                    if (result.status === 'success' && !result.valid) {
                        hasFailures = true;
                        controller.failedPrograms.push({
                            code: result.httpCode || getErrorMessage(result.errorMessage),
                            url: UrlService.prependHttp(result.url),
                            name: result.name,
                            institutionCoreId: result.institutionCoreId
                        });
                        setInvalidMessage();
                    }
                });
                if (results.success +  results.failure === results.total || !results.count) {
                    setCompleteMessage();
                    inProgress = false;
                } else if ($state.current.name === originalState) {
                    $timeout(loadLatest, 5 * 1000);
                }
            });
        }

        function init() {
            reset();
            loadLogs();
            originalState = $state.current.name;
        }

        init();
    }

}(window.angular));

(function(angular) {
    "use strict";

    angular
        .module('qsHub.profiles')
        .service('TuProgramsUrlCheckerService', [
            '$resource',
            'constants',
            'RequestsQueueService',
            TuProgramsUrlChecker
        ]);

    function TuProgramsUrlChecker (
        $resource,
        constants,
        RequestsQueueService
    ) {
        var service = {};

        /**
         * Get answers list API endpoint.
         *
         * @returns {$resource}
         */
        service.getModel = function () {
            return $resource(constants.api.institutions.url + '/v1/admin/list/tu-programs/url', {}, {
                get: {
                    isArray: false,
                    cancellable : true
                },
                getLogs: {
                    method: 'GET',
                    url: constants.api.institutions.url + '/v1/admin/url-checker/logs',
                    isArray: false,
                    cancellable: true
                },
                getLatest: {
                    method: 'GET',
                    url: constants.api.institutions.url + '/v1/admin/url-checker/latest',
                    isArray: false,
                    cancellable: true
                },
                validateTuPrograms: {
                    method: 'GET',
                    url: constants.api.institutions.url + '/v1/admin/url-checker/validate-tu-programs',
                    isArray: false,
                    cancellable: true
                }
            });
        };

        service.get = function () {
            var key = 'TuProgramsUrlChecker:get';

            // cancel currently running requests
            RequestsQueueService.cancelAll(key);
            var Api = service.getModel().get();

            // add new request to the queue
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                return data;
            }, function () {
                return [];
            });
        };

        service.getLogs = function () {
            var key = 'TuProgramsUrlChecker:getLogs';

            // cancel currently running requests
            RequestsQueueService.cancelAll(key);
            var Api = service.getModel().getLogs();

            // add new request to the queue
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                return data.results;
            }, function () {
                return [];
            });
        };

        service.getLatest = function () {
            var key = 'TuProgramsUrlChecker:getLatest';

            // cancel currently running requests
            RequestsQueueService.cancelAll(key);
            var Api = service.getModel().getLatest();

            // add new request to the queue
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                return data.results;
            }, function () {
                return [];
            });
        };

        service.validateTuPrograms = function (uniqueId, includeAdvancedProfiles) {
            var key = 'TuProgramsUrlChecker:validateTuPrograms',
            adv = includeAdvancedProfiles || false;

            // cancel currently running requests
            RequestsQueueService.cancelAll(key);
            var Api = service.getModel().validateTuPrograms({
                uniqueId: uniqueId,
                includeAdvanced: adv
            });

            // add new request to the queue
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                return data.results;
            }, function () {
                return [];
            });
        };

        return service;
    }

}(window.angular));

(function (angular) {

  'use strict';

  angular.module('qsHub.profiles').component('profileUpgradeForm', {
      templateUrl: '/scripts/modules/profiles/components/profileUpgradeForm/profileUpgradeFormView.html',
      bindings: {
          toggleUpgradeForm: '&',
      },
      bindToController: true
  });

}(window.angular));




(function(angular) {
    'use strict';

    angular
        .module('qsHub.stars')
        .config(config, [
            '$stateProvider',
            'errors'
        ]);

    function config(
        $stateProvider,
        errors
    ) {
        $stateProvider
            .state('clients.statistics.stars', {
                url: '/stars',
                templateUrl: '/scripts/modules/stars/components/clients/starsClientsView.html',
                controller: 'Stars.ClientsController',
                controllerAs: 'ClientStarsController',
                data: {
                    pageTitle: 'QS Stars',
                    label: 'QS Stars',
                    name: 'QS Stars'
                },
                resolve: {
                    security: [
                        '$q' , 'UserFactory', 'AuthenticationService',
                        function ($q, UserFactory, AuthenticationService) {
                            UserFactory.setData(AuthenticationService.getUserData());
                            if (!UserFactory.hasData()) {
                                return $q.reject(errors[401]);
                            } else if (!UserFactory.hasClientStarsAccess()) {
                                return $q.reject(errors[403]);
                            }
                        }
                    ]
                }
            })
            .state('staff.qs-stars', {
                abstract: true,
                template: '<div ui-view></div>',
                data: {
                    label: 'QS Stars'
                }
            }).state('staff.qs-stars.institutions-list', {
                url: '/qs-stars/institutions-list',
                templateUrl: '/scripts/modules/stars/components/institutionsList/starsInstitutionsListView.html',
                controller: 'stars.InstitutionsListController',
                controllerAs: 'StarsInstitutionsListController',
                data: {
                    pageTitle: 'Institutions List',
                    label: 'Institutions List',
                    name: 'Institutions List'
                },
                resolve: {
                    security: [
                        '$q', 'UserFactory', 'AuthenticationService',
                        function ($q, UserFactory, AuthenticationService) {
                            UserFactory.setData(AuthenticationService.getUserData());
                            if (!UserFactory.hasData()) {
                                return $q.reject(errors[401]);
                            } else if (!UserFactory.hasStarsListAccess()) {
                                return $q.reject(errors[403]);
                            }
                        }
                    ]
                }
            }).state('staff.qs-stars.upload', {
                abstract: true,
                template: '<div ui-view></div>',
                data: {
                    pageTitle: 'Upload Badges & Documents',
                    label: 'Upload Badges & Documents',
                    name: 'Badges & Documents'
                },
            }).state('staff.qs-stars.upload.start', {
                url: '/qs-stars/badges-and-documents',
                templateUrl: '/scripts/modules/stars/components/filesUpload/starsFilesUploadView.html',
                data: {
                    pageTitle: 'Upload Badges & Documents',
                    label: 'Upload Badges & Documents',
                    name: 'Upload Badges & Documents'
                },
                resolve: {
                    security: [
                        '$q', 'UserFactory', 'AuthenticationService',
                        function ($q, UserFactory, AuthenticationService) {
                            UserFactory.setData(AuthenticationService.getUserData());
                            if (!UserFactory.hasData()) {
                                return $q.reject(errors[401]);
                            } else if (!UserFactory.hasBadgesAndDocumentsAccess()) {
                                return $q.reject(errors[403]);
                            }
                        }
                    ]
                }
            }).state('staff.qs-stars.upload.badges-preview', {
                url: '/qs-stars/badges-and-documents/badges-preview',
                templateUrl: '/scripts/modules/stars/components/filesUpload/starsFilesUploadBadgesPreviewView.html',
                data: {
                    pageTitle: 'Badges Preview',
                    label: 'Badges Preview',
                    name: 'Badges Preview'
                },
                params: {
                    type: null,
                    tmpFile: null
                },
                resolve: {
                    security: [
                        '$q', 'UserFactory', 'AuthenticationService',
                        function ($q, UserFactory, AuthenticationService) {
                            UserFactory.setData(AuthenticationService.getUserData());
                            if (!UserFactory.hasData()) {
                                return $q.reject(errors[401]);
                            } else if (!UserFactory.hasBadgesAndDocumentsAccess()) {
                                return $q.reject(errors[403]);
                            }
                        }
                    ]
                }
            }).state('staff.qs-stars.badges-overview', {
                url: '/qs-stars/badges-overview',
                templateUrl: '/scripts/modules/stars/components/badgesOverview/starsBadgesOverviewPageView.html',
                data: {
                    pageTitle: 'Badges Overview',
                    label: 'Badges Overview',
                    name: 'Badges Overview'
                },
                params: {
                    type: null,
                    tmpFile: null
                },
                resolve: {
                    security: [
                        '$q', 'UserFactory', 'AuthenticationService',
                        function ($q, UserFactory, AuthenticationService) {
                            UserFactory.setData(AuthenticationService.getUserData());
                            if (!UserFactory.hasData()) {
                                return $q.reject(errors[401]);
                            } else if (!UserFactory.hasBadgesOverviewAccess()) {
                                return $q.reject(errors[403]);
                            }
                        }
                    ]
                }
            }).state('staff.qs-stars.publish-to-site', {
                url: '/qs-stars/publish-to-site',
                templateUrl: '/scripts/modules/stars/components/publishToSite/publishToSitePageView.html',
                data: {
                    pageTitle: 'Publish to Site',
                    label: 'Publish to Site',
                    name: 'Publish to Site'
                },
                resolve: {
                    security: [
                        '$q', 'UserFactory', 'AuthenticationService',
                        function ($q, UserFactory, AuthenticationService) {
                            UserFactory.setData(AuthenticationService.getUserData());
                            if (!UserFactory.hasData()) {
                                return $q.reject(errors[401]);
                            } 
                        }
                    ]
                }
            });
    }

}(window.angular));

(function(angular) {
    'use strict';

    var gridNamespaces = {
            filters: {
                institutionList : 'stars::institutionList::filters',
                badgesOverview : 'stars::badgesOverview::filters',
            },
            visibility: {
                institutionList : 'stars::institutionList::visibility',
                badgesOverview : 'stars::badgesOverview::visibility',
            }
        },
        constants = {
            datagrid: {
                defaultRowsNumber: 25
            },
            institutionType: {
                'uni': 'university',
                'bs': 'businessSchool'
            },
            handleFile: {
                methodology: {
                    uni: 'methodology_uni',
                    bs: 'methodology_bs'
                }
            },
            versions: {
                v4: 4.2,
                v5: 5
            }
            
        };

    angular
        .module('qsHub.stars')
        .constant('stars.gridNamespaces', gridNamespaces)
        .constant('stars.constants', constants);

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.stars')
        .service('StarsBadgesService', [
            '$q',
            '$resource',
            'constants',
            'RequestsQueueService',
            'TimeService',
            Service
        ]);

    function Service(
        $q,
        $resource,
        constants,
        RequestsQueueService,
        TimeService
    ) {
        var service = {};

        service.getTypes = function () {
            var deferred = $q.defer();
            deferred.resolve(
                [
                    {
                        value: 'university_subject',
                        label: 'University subject'
                    },
                    {
                        value: 'university_broad_subject',
                        label: 'University broad subject'
                    },
                    {
                        value: 'university_category',
                        label: 'University category'
                    },
                    {
                        value: 'university_overall',
                        label: 'University overall'
                    },
                    {
                        value: 'university_academic_development',
                        label: 'University Academic Development'
                    },
                    {
                        value: 'businessSchool_subject',
                        label: 'Business school subject'
                    },
                    {
                        value: 'businessSchool_category',
                        label: 'Business school category'
                    },
                    {
                        value: 'businessSchool_overall',
                        label: 'Business school overall'
                    },
                ]
            );

            return deferred.promise;
        };

        function getModel() {
            return $resource(constants.api.stars.url, {}, {
                getFilesList: {
                    method: 'POST',
                    url:  constants.api.stars.url + '/v1/badges-preview',
                    isArray: false,
                    cancellable: true
                },
                save: {
                    method: 'POST',
                    url:  constants.api.stars.url + '/v1/badges-save',
                    isArray: false,
                    cancellable: true
                },
                getLogs: {
                    method: 'GET',
                    url:  constants.api.stars.url + '/v1/badges-logs?sorting[createdAt]=desc',
                    isArray: false,
                    cancellable: true
                },
                getList: {
                    method: 'GET',
                    url:  constants.api.stars.url + '/v1/list/badges',
                    isArray: false,
                    cancellable: true
                },
            });
        }

        service.getGroupedLogs = function (params) {
            var key = 'StarsBadgesService::getLogs',
                Api;

            RequestsQueueService.cancelAll(key);
            Api = getModel().getLogs(params);
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                var groupedLogs = {},
                    key;
                angular.forEach(data.results, function (log) {
                    key = TimeService.format(log.createdAt, 'YYYY:MM:DD');
                    if (typeof groupedLogs[key] === 'undefined') {
                        groupedLogs[key] = [];
                    }
                    groupedLogs[key].push(log);
                });
                data.grouped = groupedLogs;

                return data;
            }, function () {
                return false;
            });
        };

        service.getFilesList = function (type, tmpFile) {
            var key = 'StarsBadgesService::getFilesList',
                Api;

            RequestsQueueService.cancelAll(key);
            Api = getModel().getFilesList({
                type : type,
                tmpFile : tmpFile
            });
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                return data;
            }, function () {
                return false;
            });
        };

        service.getList = function (filters) {
            var key = 'StarsBadgesService::getList',
                Api;

            RequestsQueueService.cancelAll(key);
            Api = getModel().getList(filters);
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                return data;
            }, function () {
                return false;
            });
        };

        service.save = function (type, tmpFile) {
            var key = 'StarsBadgesService::save',
                Api;

            RequestsQueueService.cancelAll(key);
            Api = getModel().save({
                type: type,
                tmpFile: tmpFile
            });
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                return data;
            }, function () {
                return false;
            });
        };

        return service;
    }

}(window.angular));


(function(angular) {
    "use strict";

    angular
        .module('qsHub.stars')
        .factory('BadgesFactory', [
            Factory
        ]);

    function Factory () {
        var reload = null,
            logsLimit = null,
            subscribedTo = [];
        return {
            requestLogsReload:  function() {
                reload = true;
            },
            isLogsReloadRequest: function () {
                return !!reload;
            },
            resetLogsReloadRequest: function () {
                reload = null;
            },
            setLogsLimit: function (limit) {
                logsLimit = limit;
            },
            getLogsLimit: function () {
                return logsLimit;
            },
            setSubscribedTo: function (newKey) {
                subscribedTo.push(newKey);
            },
            getSubscribedTo: function () {
                return subscribedTo;
            }
        };
    }

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.stars')
        .service('StarsInstitutionsListService', [
            '$resource',
            '$q',
            'RequestsQueueService',
            'constants',
            'TimeService',
            'InstitutionsSubscriptionsService',
            'stars.constants',
            Service
        ]);

    function Service(
        $resource,
        $q,
        RequestsQueueService,
        constants,
        TimeService,
        InstitutionsSubscriptionsService,
        starsConstants
    ) {
        var service = {
            cache: {}
        };

        function getModel() {
            return $resource(constants.api.stars.url, {}, {
                getAll: {
                    method: 'GET',
                    url: constants.api.stars.url + '/v1/list/all',
                    isArray: false,
                    cancellable: true
                },
                getAllUniversitySubjects: {
                    method: 'GET',
                    url: constants.api.stars.url + '/v1/list/subjects/university',
                    isArray: false,
                    cancellable: true
                },
                getAllUniversityBroadSubjects: {
                    method: 'GET',
                    url: constants.api.stars.url + '/v1/list/broad/subjects/university',
                    isArray: false,
                    cancellable: true
                },
                getAllBusinessSchoolSubjects: {
                    method: 'GET',
                    url: constants.api.stars.url + '/v1/list/subjects/business-school',
                    isArray: false,
                    cancellable: true
                },
                getAllUniversitySections: {
                    method: 'GET',
                    url: constants.api.stars.url + '/v1/list/university/sections/:version',
                    isArray: false,
                    cancellable: true
                },
                getAllBusinessSchoolSections: {
                    method: 'GET',
                    url: constants.api.stars.url + '/v1/list/business-school/sections/:version',
                    isArray: false,
                    cancellable: true
                },
                unsubscribe: {
                    method: 'POST',
                    url: constants.api.stars.url + '/v1/unsubscribe',
                    isArray: false,
                    cancellable: true
                },
                getUniCategories: {
                    method: 'GET',
                    url: constants.api.stars.url + '/v1/list/categories/university/:version?sorting[order]=asc',
                    isArray: false,
                    cancellable: true
                },
                getBusinessCategories: {
                    method: 'GET',
                    url: constants.api.stars.url + '/v1/list/categories/business-school',
                    isArray: false,
                    cancellable: true
                },
                update: {
                    method: 'PATCH',
                    url: constants.api.stars.url + '/v1/scores/institution/:institutionCoreId',
                    isArray: false,
                    cancellable: true
                },
                submitToInstitution: {
                    method: 'PUT',
                    url: constants.api.stars.url + '/v1/client/:institutionCoreId',
                    isArray: false,
                    cancellable: true
                }
            });
        }

        service.update = function (institutionCoreId, data) {
            data = angular.extend({}, data);
            return getModel().update({
                institutionCoreId: institutionCoreId
            }, data).$promise.then(function (response) {
                return response;
            }, function () {
                return false;
            });
        };

        service.submitToInstitution = function (institutionCoreId, data) {
            var key = 'StarsInstitutionsListService::submitToInstitution',
                Api;

            RequestsQueueService.cancelAll(key);
            Api = getModel().submitToInstitution({
                institutionCoreId: institutionCoreId
            }, data);
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (response) {
                return response;
            }, function () {
                return false;
            });
        };

        service.getAllBusinessSchoolSections = function (useCache, version) {
            var key = 'StarsInstitutionsListService::getAllBusinessSchoolSections',
                Api,
                deferred = $q.defer();

            if (typeof useCache === 'undefined') {
                useCache = true;
            }

            if (useCache && service.cache[key]) {
                deferred.resolve(service.cache[key]);
                return deferred.promise;
            }

            RequestsQueueService.cancelAll(key);
            Api = getModel().getAllBusinessSchoolSections({
                version: version
            });
            RequestsQueueService.add(Api, key);

            return Api.$promise;
        };

        service.getAllUniversitySections = function (useCache, version) {
            var key = 'StarsInstitutionsListService::getAllUniversitySections',
                Api,
                deferred = $q.defer();

            if (typeof useCache === 'undefined') {
                useCache = true;
            }

            if (useCache && service.cache[key]) {
                deferred.resolve(service.cache[key]);
                return deferred.promise;
            }

            RequestsQueueService.cancelAll(key);
            Api = getModel().getAllUniversitySections({
                version: version
            });
            RequestsQueueService.add(Api, key);

            return Api.$promise;
        };

        service.getAllBusinessSchoolSubjects = function (useCache) {
            var key = 'StarsInstitutionsListService::getAllBusinessSchoolSubjects',
                Api,
                list = [],
                deferred = $q.defer();

            if (typeof useCache === 'undefined') {
                useCache = true;
            }

            if (useCache && service.cache[key]) {
                deferred.resolve(service.cache[key]);
                return deferred.promise;
            }

            RequestsQueueService.cancelAll(key);
            Api = getModel().getAllBusinessSchoolSubjects();
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (result) {
                if (result && result.results) {
                    angular.forEach(result.results, function (item) {
                        list.push({
                            value: item.handle,
                            label: item.name
                        });
                    });
                    service.cache[key] = list;
                }
                return list;
            }, function () {
                return false;
            });
        };

        service.getAllUniversitySubjects = function (useCache) {
            var key = 'StarsInstitutionsListService::getAllUniversitySubjects',
                Api,
                list = [],
                deferred = $q.defer();

            if (typeof useCache === 'undefined') {
                useCache = true;
            }

            if (useCache && service.cache[key]) {
                deferred.resolve(service.cache[key]);
                return deferred.promise;
            }

            RequestsQueueService.cancelAll(key);
            Api = getModel().getAllUniversitySubjects();
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (result) {
                if (result && result.results) {
                    angular.forEach(result.results, function (item) {
                        list.push({
                            value: item.handle,
                            label: item.name
                        });
                    });
                    service.cache[key] = list;
                }
                return list;
            }, function () {
                return false;
            });
        };

        service.getAllUniversityBroadSubjects = function () {
            var key = 'StarsInstitutionsListService::getAllUniversityBroadSubjects',
                Api,
                list = [];

            RequestsQueueService.cancelAll(key);
            Api = getModel().getAllUniversityBroadSubjects();
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (result) {
                if (result && result.results) {
                    angular.forEach(result.results, function (item) {
                        list.push({
                            value: item.handle,
                            label: item.name
                        });
                    });
                }
                return list;
            }, function () {
                return false;
            });
        };

        service.search = function (filters) {
            var key = 'StarsInstitutionsListService::search',
                Api;
            if (filters &&
                filters['filter[answers.0.totalStarsRatingAsString]'] &&
                filters['filter[answers.0.totalStarsRatingAsString]'].endsWith(',')) {
                    delete  filters['filter[answers.0.totalStarsRatingAsString]'];
            }

            RequestsQueueService.cancelAll(key);
            Api = getModel().getAll(filters);
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                return data;
            }, function () {
                return false;
            });
        };

        service.getSubscriptions = function (institutionCoreId) {
            var list = [];
            return InstitutionsSubscriptionsService.getLog(institutionCoreId, 'stars', 100).then(function (result) {
                if (result && result.results) {
                    angular.forEach(result.results, function (item) {
                        list.push({
                            value: item.id,
                            label: TimeService.format(item.startDate, "MMM DD, YYYY") + ' - ' + TimeService.format(item.endDate, "MMM DD, YYYY") + ' (v' + item.version.toFixed(1) + ')'
                        });
                    });
                }
                return list;
            }, function () {
                return list;
            });
        };

        service.delete = function (ids) {
            if (!ids.length) {
                throw 'Missing Required';
            }
            var key = 'StarsInstitutionsListService:delete',
                Api;
            RequestsQueueService.cancelAll(key);
            Api = getModel().unsubscribe({}, ids);
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                return data;
            }, function () {
                return false;
            });
        };

        service.getUniCategories = function (useCache, version) {
            var key = 'StarsInstitutionsListService::getUniCategories',
                Api,
                deferred = $q.defer();

            if (typeof useCache === 'undefined') {
                useCache = true;
            }

            if (useCache && service.cache[key]) {
                deferred.resolve(service.cache[key]);

                return deferred.promise;
            }

            RequestsQueueService.cancelAll(key);
            Api = getModel().getUniCategories({
                version: version
            });
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                service.cache[key] = data;

                return data;
            }, function () {
                return false;
            });
        };

        service.getBusinessCategories = function () {
            var key = 'StarsInstitutionsListService::getBusinessCategories',
                Api;
    
            RequestsQueueService.cancelAll(key);
            Api = getModel().getBusinessCategories();
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                return data;
            }, function () {
                return false;
            });
        };

        function getStars(count) {
            var stars = '',
                i = 0;
            for (i; i < count; i++) {
                stars += ' <i class="fa fa-star"></i>';
            }

            return stars;
        }

        service.arrayMove = function (arr, oldIndex, newIndex) {
            if (!angular.isArray(arr) || typeof oldIndex === 'undefined') {
                return null;
            }
            if (newIndex >= arr.length) {
                var k = newIndex - arr.length + 1;
                while (k--) {
                    arr.push(undefined);
                }
            }
            arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
        };

        service.getStarsList = function (excludeZero) {
            var deferred = $q.defer(),
                list = [{
                        value: 0,
                        label: 'Zero stars'
                    },
                    {
                        value: 1,
                        label: getStars(1)
                    },
                    {
                        value: 2,
                        label: getStars(2)
                    },
                    {
                        value: 3,
                        label: getStars(3)
                    },
                    {
                        value: 4,
                        label: getStars(4)
                    },
                    {
                        value: 5,
                        label: getStars(5)
                    },
                    {
                        value: 6,
                        label: getStars(5) + ' <i class="fa fa-plus"></i>'
                    }
                ];
            if (excludeZero) {
                list.splice(0, 1);
            }
            deferred.resolve(list);

            return deferred.promise;
        };

        service.getBusinessSchoolSections = function () {
            var deferred = $q.defer();
            deferred.resolve(
                [{
                        handle: 'school_criteria',
                        name: 'School Criteria',
                        open: true,
                        categoriesHandles: [
                            'facilities',
                            'internationalization_and_diversity',
                            'teaching_and_student_quality'
                        ],
                        template: 'starsSchoolCriteriaBusinessView.html'
                    },
                    {
                        handle: 'output_criteria',
                        name: 'Output Criteria',
                        open: false,
                        categoriesHandles: [
                            'employability',
                            'research',
                            'innovation_and_entrepreneurship',
                            'engagement'
                        ],
                        template: 'starsOutputCriteriaBusinessView.html'
                    },
                    {
                        handle: 'specialist_criteria',
                        name: 'Specialist Criteria',
                        open: false,
                        categoriesHandles: [
                            'rankings_and_public_evaluations',
                            'program_strength'
                        ],
                        template: 'starsSpecialistCriteriaBusinessView.html'
                    },
                    {
                        handle: 'upload_certificate_and_development_roadmap',
                        name: 'Upload Certificate and Development Roadmap',
                        open: false,
                        template: 'upload.html'
                    }
                ]
            );

            return deferred.promise;
        };

        service.getUniversitySections = function () {
            var deferred = $q.defer();
            deferred.resolve(
                [{
                        handle: 'core_criteria',
                        name: 'Core Criteria',
                        open: true,
                        categoriesHandles: [
                            'teaching',
                            'employability',
                            'research',
                            'internationalization'
                        ],
                        template: 'starsCoreCriteriaView.html'
                    },
                    {
                        handle: 'learning_environment',
                        name: 'Learning Environment',
                        open: false,
                        categoriesHandles: [
                            'facilities',
                            'online_distance_learning'
                        ],
                        template: 'starsLearnEnvironmentView.html'
                    },
                    {
                        handle: 'advanced_criteria',
                        name: 'Advanced Criteria',
                        open: false,
                        categoriesHandles: [
                            'arts_and_culture',
                            'innovation',
                            'social_responsibility',
                            'inclusiveness'
                        ],
                        template: 'starsAdvancedCriteriaView.html'
                    },
                    {
                        handle: 'specialist_criteria',
                        name: 'Specialist Criteria',
                        open: false,
                        categoriesHandles: [
                            'subject_rankings',
                            'program_strength'
                        ],
                        template: 'starsSpecialistCriteriaView.html'
                    },
                    {
                        handle: 'upload_certificate_and_development_roadmap',
                        name: 'Upload Certificate and Development Roadmap',
                        open: false,
                        template: 'upload.html'
                    }
                ]
            );

            return deferred.promise;
        };

        service.getSectionHandles = function (sections) {
            if (!angular.isArray(sections)) {
                return [];
            }
            var i = 0,
                total = sections.length,
                handles = [];

            for (i; i < total; i++) {
                handles.push(sections[i].handle);
            }

            return handles;
        };

        service.formatCsvResults = function (data) {
            data = angular.copy(data);
            angular.forEach(data, function (column, dataKey) {
                angular.forEach(column, function (item, key) {
                    if (key === 'answers' && item && item.length) {
                        data[dataKey]['answers.0.totalStarsRating'] = item[0].totalStarsRating ? item[0].totalStarsRating : 'Not Evaluated';
                        data[dataKey]['answers.0.overallStarsRating'] = getOverallStarsRating(item[0].overallStarsRating);
                    } else if (key === 'answers' && (!item || item === null || !item.length) ) {
                        data[dataKey]['answers.0.totalStarsRating'] = 'Not Evaluated';
                    } else if (key === 'institutionType' && data[dataKey][key]) {
                        data[dataKey][key] = data[dataKey][key] === 'businessSchool' ? 'Business School' :
                        (data[dataKey][key] === 'university' ? 'University' : data[dataKey][key]);
                    } else if (key === 'modifiedAt') {
                        data[dataKey][key] = TimeService.format(item, "MMM DD, YYYY HH:mm:ss");
                    } else if (key === 'subscriptionStartDate' || key === 'subscriptionEndDate' ||
                        key === 'lastPublished' || key === 'lastSubmit') {
                        data[dataKey][key] = TimeService.format(item, "MMM DD, YYYY");
                    }  else if (key === 'version') {
                        data[dataKey][key] = item === 4.2 ? 'v4.2' : 'v5.0';
                    } 
                });
            });

            return data;
        };

        function getOverallStarsRating(value) {
            var overallStarsRating = 'Not Evaluated';
            if (angular.isDefined(value)) {
                overallStarsRating = value > 1 ? value + ' stars' : value + ' star';
                overallStarsRating = overallStarsRating.replace("6", "5+");
            }
            return overallStarsRating;
        }

        service.removeDeleteColumnHeader = function (exportColumnHeaders) {
            if(!angular.isArray(exportColumnHeaders)) {
                return null;
            }
            // filter out delete column
            var i = 0,
                total = exportColumnHeaders.length;
            for (i; i < total; i++) {
                if (exportColumnHeaders[i].name === 'deleted') {
                    exportColumnHeaders.splice(i, 1);
                    break;
                }
            }
            return exportColumnHeaders;
        };

        service.removeDeleteColumn = function (columns) {
            if(!angular.isArray(columns)) {
                return false;
            }
            columns = columns.slice(0);
            var i = 0,
                total = columns.length;
            for (i; i < total; i++) {
                if (columns[i].field === 'deleted') {
                    columns.splice(i, 1);
                    break;
                }
            }
            return columns;
        };

        service.isValueExistInDropdown = function (value, list, isSubjectList) {
            var isValueExist = false,
                listValue = null;
            angular.forEach(list, function (object) {
                listValue = isSubjectList ? object.value : object.handle;
                if (listValue === value) {
                    isValueExist = true;
                }
            });
            return isValueExist;
        };

        service.orderUniversitySections = function (uniSections) {
            var universitySections = [];
            angular.forEach(uniSections, function (value) {
                switch (value.handle) {
                    case 'core_criteria':
                        universitySections[0] = value;
                        return;
                    case 'learning_environment':
                        universitySections[1] = value;
                        return;
                    case 'advanced_criteria':
                        universitySections[2] = value;
                        return;
                    case 'specialist_criteria':
                        universitySections[3] = value;
                        return;
                    case 'upload_certificate_and_development_roadmap':
                        universitySections[4] = value;
                        return;
                }
            });

            return universitySections;
        };
        
        service.orderBusinessSchoolSections = function (businessSections) {
            var businessSchoolSections = [];
            angular.forEach(businessSections, function (value) {
                switch (value.handle) {
                    case 'school_criteria':
                        businessSchoolSections[0] = value;
                        return;
                    case 'output_criteria':
                        businessSchoolSections[1] = value;
                        return;
                    case 'specialist_criteria':
                        businessSchoolSections[2] = value;
                        return;
                    case 'upload_certificate_and_development_roadmap':
                        businessSchoolSections[3] = value;
                        return;
                }
            });

            return businessSchoolSections;
        };

        service.getSubscriptionVersionId = function (subscription) {
            return subscription ? (subscription.value === 'minicore' ? starsConstants.versions.v4 : subscription.label.split("(v")[1].split(")")[0]) : null;
        };

        return service;
    }

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.stars')
        .controller('stars.InstitutionsListController', [
            '$scope',
            'starsEditFactory',
            'WatchService',
            QsStarsInstitutionsList
        ]);

    function QsStarsInstitutionsList($scope, starsEditFactory, WatchService) {
        var controller = this;
        controller.selectedItemId = null;
        controller.selectedItem = null;

        controller.isRightSidePanelActive = function () {
            return controller.selectedItemId !== null;
        };

        function selectedIdWatch(id) {
            if (!id) {
                return false;
            }
            controller.selectedItemId = id;
        }

        WatchService.create($scope, starsEditFactory.getSelectedId, selectedIdWatch);
    }

}(window.angular));

(function (angular) {

  'use strict';

  angular.module('qsHub.stars').component('starsBadgesSteps', {
      templateUrl: '/scripts/modules/stars/components/badgesSteps/starsBadgesStepsView.html',
      bindToController: true,
      controller: 'Stars.BadgesStepsController'
  });

}(window.angular));




(function (angular) {
    "use strict";

    angular
        .module('qsHub.stars')
        .controller('Stars.BadgesStepsController', [
            '$state',
            Controller
        ]);

    function Controller(
        $state
    ) {
        var controller = this;

        controller.isActive = function (stepNumber) {
            return (stepNumber === 1 && $state.current.name === 'staff.qs-stars.upload.start') ||
                (stepNumber === 2 && $state.current.name === 'staff.qs-stars.upload.badges-preview');
        };

        controller.$onInit = function () {};
    }

}(window.angular));

(function (angular) {

  'use strict';

  angular.module('qsHub.stars').component('starsBadgesUpload', {
      templateUrl: '/scripts/modules/stars/components/badgesUpload/starsBadgesUploadView.html',
      bindToController: true,
      controller: 'Stars.BadgesUploadController'
  });

}(window.angular));




(function (angular) {
    "use strict";

    angular
        .module('qsHub.stars')
        .controller('Stars.BadgesUploadController', [
            '$timeout',
            '$state',
            'constants',
            'AuthenticationService',
            'StarsBadgesService',
            'NotifierFactory',
            BadgesUpload
        ]);

    function BadgesUpload(
        $timeout,
        $state,
        constants,
        AuthenticationService,
        StarsBadgesService,
        NotifierFactory
    ) {
        var controller = this;

        controller.maxSize = 40;
        controller.uploadEnabled = false;
        controller.type = null;
        controller.tmpFile = null;

        controller.config = {
            dropzone: {
                url: constants.api.stars.url + '/v1/badges-upload',
                maxFilesize: controller.maxSize,
                maxFiles: 1,
                uploadMultiple: false,
                parallelUploads: 1,
                autoProcessQueue: true,
                addRemoveLinks: false,
                acceptedFiles:  '.zip',
                previewsContainer: '.dropzone-previews',
                headers: AuthenticationService.getAuthorizationHeader(),
                thumbnailWidth: 200,
                thumbnailHeight: 200,
                paramName: "file",
                init: function () {
                    controller.dropZoneInstance = this;
                },
            },
            eventHandlers: {
                success: handleUploadSuccess,
                error: handleUploadError,
            }
        };

        function handleUploadSuccess(instance, response) {
            if (response.status && response.status && response.path) {
                $state.go('staff.qs-stars.upload.badges-preview', {
                    type: controller.type.value,
                    tmpFile: response.path
                });
            } else {
                $timeout(function () {
                    controller.uploadEnabled = true;
                    controller.dropZoneInstance.removeAllFiles();
                });

                NotifierFactory.show(
                    'error',
                    'Can\'t upload badges at the moment. Please try again later.',
                    'Badges Upload'
                );
            }
        }

        function handleUploadError (error) {
            var errorMessage = error && error.xhr !== undefined && error.xhr.responseText ? JSON.parse(error.xhr.responseText) : false;
            errorMessage = errorMessage ? errorMessage.message : 'File upload failed. Only ZIP files are accepted';
            $timeout(function() {
                controller.uploadEnabled = true;
                controller.dropZoneInstance.removeAllFiles();
                NotifierFactory.show(
                    'error',
                    errorMessage,
                    'Badges Upload'
                );
            });
        }

        controller.handleTypeChanges = function () {
            controller.uploadEnabled = !!controller.type.value;
        };

        controller.$onInit = function () {
            StarsBadgesService.getTypes().then(function (items) {
                controller.list = items;
            });
        };
    }

}(window.angular));

(function (angular) {

  'use strict';

  angular.module('qsHub.stars').component('starsBadgesPreview', {
      templateUrl: '/scripts/modules/stars/components/badgesPreview/starsBadgesPreviewView.html',
      bindToController: true,
      controller: 'Stars.BadgesPreviewController'
  });

}(window.angular));




(function (angular) {
    "use strict";

    angular
        .module('qsHub.stars')
        .controller('Stars.BadgesPreviewController', [
            '$state',
            '$scope',
            '$stateParams',
            'stars.constants',
            'StarsBadgesService',
            'NotifierFactory',
            'BadgesFactory',
            'WebSocketsService',
            Controller
        ]);

    function Controller(
        $state,
        $scope,
        $stateParams,
        starsConstants,
        StarsBadgesService,
        NotifierFactory,
        BadgesFactory,
        WebSocketsService
    ) {
        var controller = this,
            gridApiInstance = null,
            loadingData = false,
            isRendered = false,
            room = 'SaveBadgesOutcome',
            subscribedTo = [];

        controller.allowNext = function () {
            return isRendered && !loadingData;
        };

        controller.showLoadBar = function () {
            return !isRendered || loadingData;
        };

        controller.back = function () {
            $state.go('staff.qs-stars.upload.start');
        };

        controller.next = function () {
            NotifierFactory.show(
                'warning',
                'Badges are being processed. You can navigate away from the page.',
                'Badges Upload'
            );

            StarsBadgesService.save(
                controller.type,
                controller.tmpFile
            ).then(function (response) {
                var key = room + response.logId;
                if (BadgesFactory.getSubscribedTo().indexOf(key) === -1) {
                    WebSocketsService.subscribe(key, 'getOutcome' + response.logId, function (result) {
                        var success = result && result.result && result.result.success;
                        if (success) {
                            BadgesFactory.requestLogsReload();
                            NotifierFactory.show(
                                'success',
                                'Badges are saved successfully!',
                                'Badges Upload'
                            );
                        } else {
                            NotifierFactory.show(
                                'error',
                                'Can\'t process badges at the moment. Please try again later.',
                                'Badges Upload'
                            );
                        }
                    }, function (subscribed) {
                        if (subscribed) {
                            BadgesFactory.setSubscribedTo(key);
                            $state.go('staff.qs-stars.upload.start');
                        } else {
                            NotifierFactory.show(
                                'error',
                                'Can\'t process badges at the moment. Please try again later.',
                                'Badges Upload'
                            );
                        }
                    });
                }
            });
        };

        function getPage() {
            loadingData = true;
            return StarsBadgesService.getFilesList(
                controller.type, controller.tmpFile
            ).then(function (response) {
                controller.gridOptions.totalItems = response.total;
                controller.gridOptions.data = response.results;
                controller.gridOptions.minRowsToShow = response.total;
                loadingData = false;

                angular.forEach(controller.gridOptions.data, function (item) {
                    item.fileType = null;
                    if (item.type === starsConstants.institutionType.uni) {
                        item.fileType = 'University';
                    }
                    if (item.type === starsConstants.institutionType.bs) {
                        item.fileType = 'Business School';
                    }
                    item.category = item.category === 'overall' ? item.category : (
                        item.group ? item.group + ': ' + item.category : null);
                });
            });
        }

        function initDatagridOptions () {
            var starsCellTemplate = '/scripts/modules/stars/components/badgesPreview/starsCellTemplate.html';

            controller.gridOptions = {
                appScopeProvider: controller,
                enableSorting: false,
                enableGridMenu: false,
                showGridFooter: true,
                enableFiltering: false,
                enableColumnResize: false,
                enableFullRowSelection: false,
                enableRowSelection: false,
                multiSelect: false,
                enableRowHeaderSelection: false,
                enableColumnMenus: false,
                columnDefs: [
                    {
                        displayName: 'Name',
                        field: 'file',
                    },
                    {
                        displayName: 'Stars Rank',
                        field: 'stars',
                        cellTemplate: starsCellTemplate,
                    },
                    {
                        displayName: 'Type',
                        field: 'fileType',
                    },
                    {
                        displayName: 'Category / Subject',
                        field: 'category',
                        cellFilter: 'strReplace: "_": " " | capitalize'
                    },
                    {
                        displayName: 'Size',
                        field: 'size',
                    }
                ],
                onRegisterApi: function (gridApi) {
                    gridApiInstance = gridApi;
                    gridApi.core.on.rowsRendered($scope, function () {
                        isRendered = true;
                    });

                    getPage();
                }
            };
        }

        controller.getEmptyArray = function (length) {
            return length ? new Array(parseInt(length, 10)) :  [];
        };

        controller.$onInit = function () {
            if (!$stateParams.type || !$stateParams.tmpFile) {
                return controller.back();
            }
            controller.type = $stateParams.type;
            controller.tmpFile = $stateParams.tmpFile;
            initDatagridOptions();
        };
    }

}(window.angular));

(function (angular) {

  'use strict';

  angular.module('qsHub.stars').component('starsDatagrid', {
      templateUrl: '/scripts/modules/stars/components/starsDatagrid/starsDatagridView.html',
      bindToController: true,
      controller: 'Stars.DatagridController',
      bindings: {
          selectedRowId: '=',
          selectedItem: '='
      }
  });

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.stars')
        .controller('Stars.DatagridController', [
            '$scope',
            '$timeout',
            'constants',
            'stars.constants',
            'stars.gridNamespaces',
            'uiGridConstants',
            'UiGridService',
            'uiGridExporterService',
            'uiGridExporterConstants',
            'StarsInstitutionsListService',
            'NotifierFactory',
            'TimeService',
            'WatchService',
            'StarsDatagridFactory',
            'starsEditFactory',
            'DateRangeFixerService',
            'UserFactory',
            'ModalService',
            'UnsavedChangesFactory',
            Controller
        ]);

    function Controller(
        $scope,
        $timeout,
        constants,
        starsConstants,
        gridNamespaces,
        uiGridConstants,
        GridService,
        uiGridExporterService,
        uiGridExporterConstants,
        StarsInstitutionsListService,
        NotifierFactory,
        TimeService,
        WatchService,
        StarsDatagridFactory,
        StarsEditFactory,
        DateRangeFixerService,
        UserFactory,
        ModalService,
        UnsavedChangesFactory
    ) {
        var controller = this,
            gridApiInstance = null,
            filtersTimeout,
            loadingData = false,
            isRendered = false,
            deleteInProgress = false,
            filterChangesInProgress = false,
            columnsBeforeHide = [],
            selectValues = {},
            lastSelectValues = {},
            columnsFilters = GridService.getColumnsFilters(gridNamespaces.filters.institutionList, []),
            columnsVisibility = GridService.getColumnsVisibility(
                gridNamespaces.visibility.institutionList
            ),
            defaultFilters = {
                subscriptionStartDateRange: {
                    startDate: null,
                    endDate: null
                },
                subscriptionEndDateRange: {
                    startDate: null,
                    endDate: null
                },
                modifiedAtRange: {
                    startDate: null,
                    endDate: null
                },
                lastPublishedRange: {
                    startDate: null,
                    endDate: null
                },
                lastSubmitRange: {
                    startDate: null,
                    endDate: null
                }
            },
            paginationOptions = GridService.getColumnsFilters(
                gridNamespaces.filters.institutionList
            ),
            defaultPaginationOptions = {
                page: 1,
                limit: starsConstants.datagrid.defaultRowsNumber,
                'sorting[createdAt]': 'desc'
            },
            starsList = null,
            lastUpdatedAtDateFilter = null,
            subscriptionStartDateFilter = null,
            subscriptionEndDateFilter = null,
            lastPublishedDateFilter = null,
            lastSubmitDateFilter = null,
            handles = StarsEditFactory.getHandles();

        controller.filters = null;
        controller.itemsToDelete = {};
        controller.showInfoBlock = true;

        function exportVisible() {
            var results = controller.gridOptions.data;
            results = StarsInstitutionsListService.formatCsvResults(results);
            if (typeof results === 'undefined') {
                return false;
            }
            var gridApi = gridApiInstance,
                gridOptions = controller.gridOptions,
                exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                    gridApi.grid,
                    uiGridExporterConstants.VISIBLE
                );

            exportColumnHeaders = StarsInstitutionsListService.removeDeleteColumnHeader(exportColumnHeaders);
            var columns = StarsInstitutionsListService.removeDeleteColumn(gridApi.grid.columns);
            var exportData = GridService.getCsvData(columns, results);
            var csvContent = uiGridExporterService.formatAsCsv(
                exportColumnHeaders,
                exportData,
                gridOptions.exporterCsvColumnSeparator
            );
            uiGridExporterService.downloadFile(
                'qs-stars-overview' + TimeService.now() + '.csv',
                csvContent,
                gridOptions.exporterOlderExcelCompatibility
            );
        }

        function exportAll() {
            StarsInstitutionsListService.search(
                angular.merge({}, paginationOptions, {
                    page: 1,
                    limit: 250000,
                    export: true
                })
            ).then(function (response) {
                if (typeof response.results === 'undefined') {
                    return false;
                }
                response = StarsInstitutionsListService.formatCsvResults(response.results);
                var gridApi = gridApiInstance,
                    gridOptions = controller.gridOptions,
                    exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                        gridApi.grid,
                        uiGridExporterConstants.VISIBLE
                    );

                exportColumnHeaders = StarsInstitutionsListService.removeDeleteColumnHeader(exportColumnHeaders);
                var columns = StarsInstitutionsListService.removeDeleteColumn(gridApi.grid.columns);
                var exportData = GridService.getCsvData(columns, response);
                var csvContent = uiGridExporterService.formatAsCsv(
                    exportColumnHeaders,
                    exportData,
                    gridOptions.exporterCsvColumnSeparator
                );
                uiGridExporterService.downloadFile(
                    'qs-stars-full-overview' + TimeService.now() + '.csv',
                    csvContent,
                    gridOptions.exporterOlderExcelCompatibility
                );
            });
        }

        function applyDateFilter(filterName, defaultValue) {
            var filter = GridService.getFilterByField(paginationOptions, filterName, defaultValue);
            if (angular.isDefined(filter) &&
                filter !== null
            ) {
                if (angular.isDefined(filter.startDate) &&
                    angular.isDefined(filter.endDate)
                ) {
                    if (filter.startDate !== null &&
                        filter.startDate !== null
                    ) {
                        defaultFilters[filterName + 'Range'] = {
                            startDate: parseInt(filter.startDate, 10),
                            endDate: parseInt(filter.endDate, 10)
                        };
                    } else {
                        defaultFilters[filterName + 'Range'] = {
                            startDate: null,
                            endDate: null
                        };
                    }
                }
            }
        }

        function initDatagridOptions () {
            var selectTemplate = '/scripts/shared/ui-grid/templates/selectFilterHeaderTemplate.html',
                selectCellTemplate = '/scripts/shared/ui-grid/templates/selectCellTemplate.html',
                ratingTotalCellTemplate = '/scripts/modules/stars/components/starsDatagrid/ratingTotalCellTemplate.html',
                institutionTypeCellTemplate = '/scripts/modules/stars/components/starsDatagrid/institutionTypeCellTemplate.html',
                versionCellTemplate = '/scripts/modules/stars/components/starsDatagrid/versionCellTemplate.html',
                deleteCellTemplate = '/scripts/shared/ui-grid/templates/deleteCellTemplate.html',
                deleteHeaderCellTemplate = '/scripts/shared/ui-grid/templates/deleteHeaderCellTemplate.html',
                rowTemplate = '/scripts/shared/ui-grid/templates/rowTemplate.html',
                dateCellTemplate = '/scripts/modules/messages/datagrid/dateCellTemplate.html',
                dateFilterTemplate = '/scripts/shared/ui-grid/templates/dateFilterHeaderTemplate.html',
                statusCellTemplate = '/scripts/modules/rankings/components/classificationsOverview/datagrid/statusCellTemplate.html';

            controller.gridOptions = {
                appScopeProvider: controller,
                enableSorting: false,
                useExternalFiltering: true,
                enableGridMenu: true,
                showGridFooter: true,
                enableFiltering: true,
                enableColumnResize: true,
                enableFullRowSelection: true,
                enableRowSelection: true,
                multiSelect: false,
                enableRowHeaderSelection: false,
                rowTemplate: rowTemplate,
                exporterMenuCsv: false,
                exporterMenuAllData: false,
                exporterMenuSelectedData: false,
                exporterMenuPdf: false,
                paginationPageSizes: [25, 50, 100, 200],
                paginationPageSize: 25,
                useExternalPagination: true,
                gridMenuCustomItems: [
                    {
                        title: ' Export visible data as csv',
                        action: exportVisible,
                        order: 209
                    },
                    {
                        title: ' Export all data as csv',
                        action: exportAll,
                        order: 210
                    }
                ],
                columnDefs: [
                    {
                        displayName: 'Delete',
                        field: 'deleted',
                        visible:true,
                        enableSorting: false,
                        enableColumnResizing: false,
                        headerCellClass: 'text-center v-align',
                        headerCellTemplate: deleteHeaderCellTemplate,
                        cellTemplate: deleteCellTemplate,
                        maxWidth: 80,
                        minWidth: 80
                    },
                    {
                        displayName: 'Institution',
                        field: 'institutionName',
                        visible: true,
                        enableHiding: false,
                        enableColumnMenu: false,
                        filter: {
                            term: GridService.getFilterByField(columnsFilters, 'institutionName', '', false),
                            condition: uiGridConstants.filter.CONTAINS
                        },
                        minWidth: 320
                    },
                    {
                        displayName: 'Core Id',
                        field: 'institutionCoreIdAsString',
                        visible:true,
                        enableHiding: false,
                        enableColumnMenu: false,
                        filter: {
                            term: GridService.getFilterByField(columnsFilters, 'institutionCoreIdAsString', '', false),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        displayName: 'Institution Type',
                        field: 'institutionType',
                        visible: GridService.getVisibilityByField(columnsVisibility, 'institutionType', true),
                        filter: {
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: [
                                { value: 'businessSchool', label: 'Business School' },
                                { value: 'university', label: 'University' }
                            ],
                            searchEnabled: true,
                            term: GridService.getFilterByField(columnsFilters, 'institutionType')
                        },
                        filterHeaderTemplate: selectTemplate,
                        cellTemplate: institutionTypeCellTemplate
                    },
                    {
                        displayName: 'Rating Total',
                        cellTemplate: ratingTotalCellTemplate,
                        enableFiltering: true,
                        filterCellFiltered: true,
                        enableSorting: false,
                        field: 'answers.0.totalStarsRating',
                        visible: GridService.getVisibilityByField(columnsVisibility, 'answers.0.totalStarsRating', true),
                        filter: {
                            column: 'answers.0.totalStarsRatingAsString',
                            term: GridService.getFilterByField(columnsFilters, 'answers.0.totalStarsRatingAsString', '', false),
                            condition: uiGridConstants.filter.CONTAINS
                        },
                        maxWidth: 120
                    },
                    {
                        displayName: 'Rating',
                        cellTemplate: selectCellTemplate,
                        filterHeaderTemplate: selectTemplate,
                        field: 'answers.0.overallStarsRating',
                        visible: GridService.getVisibilityByField(columnsVisibility, 'answers.0.overallStarsRating', true),
                        filter: {
                            valuePrefix: '=',
                            column: 'answers.0.overallStarsRatingAsString',
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: starsList,
                            term: GridService.getFilterByField(columnsFilters, 'answers.0.overallStarsRatingAsString', '', false),
                            condition: uiGridConstants.filter.CONTAINS
                        },
                        minWidth: 140
                    },
                    {
                        displayName: 'Version',
                        field: 'version',
                        visible: GridService.getVisibilityByField(columnsVisibility, 'version', true),
                        filter: {
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: [
                                { value: 4.2, label: 'v4.2' },
                                { value: 5.0, label: 'v5.0' }
                            ],
                            searchEnabled: true,
                            term: GridService.getFilterByField(columnsFilters, 'version')
                        },
                        filterHeaderTemplate: selectTemplate,
                        cellTemplate: versionCellTemplate,
                    },
                    {
                        displayName: 'Subscription Start Date',
                        field: 'subscriptionStartDate',
                        type: 'date',
                        enableFiltering: true,
                        filterCellFiltered: true,
                        enableSorting: false,
                        cellFilter: 'date:\'longDate\' : \'UTC\'',
                        filters: [
                            {
                                visible: true,
                                condition: uiGridConstants.filter.GREATER_THAN
                            },
                            {
                                condition: uiGridConstants.filter.LESS_THAN
                            }
                        ],
                        filter: {
                            term: GridService.getFilterByField(columnsFilters, 'subscriptionStartDate', '', false),
                            applyTextFilter: applyDateFilter('subscriptionStartDate', '')
                        },
                        filterHeaderTemplate: dateFilterTemplate,
                        visible: GridService.getVisibilityByField(columnsVisibility, 'subscriptionStartDate', true)
                    },
                    {
                        displayName: 'Subscription End Date',
                        field: 'subscriptionEndDate',
                        type: 'date',
                        enableFiltering: true,
                        filterCellFiltered: true,
                        enableSorting: false,
                        cellFilter: 'date:\'longDate\' : \'UTC\'',
                        filters: [
                            {
                                visible: true,
                                condition: uiGridConstants.filter.GREATER_THAN
                            },
                            {
                                condition: uiGridConstants.filter.LESS_THAN
                            }
                        ],
                        filter: {
                            term: GridService.getFilterByField(columnsFilters, 'subscriptionEndDate', '', false),
                            applyTextFilter: applyDateFilter('subscriptionEndDate', '')
                        },
                        filterHeaderTemplate: dateFilterTemplate,
                        visible: GridService.getVisibilityByField(columnsVisibility, 'subscriptionEndDate', true)
                    },
                    {
                        displayName: 'Last Updated',
                        field: 'modifiedAt',
                        enableFiltering: true,
                        filterCellFiltered: true,
                        enableSorting: false,
                        type: 'date',
                        cellFilter: 'date:\'medium\' : \'UTC\'',
                        filters: [
                            {
                                visible: true,
                                condition: uiGridConstants.filter.GREATER_THAN
                            },
                            {
                                condition: uiGridConstants.filter.LESS_THAN
                            }
                        ],
                        filter: {
                            term: GridService.getFilterByField(columnsFilters, 'modifiedAt', '', false),
                            applyTextFilter: applyDateFilter('modifiedAt', '')
                        },
                        cellTemplate: dateCellTemplate,
                        filterHeaderTemplate: dateFilterTemplate,
                        visible: GridService.getVisibilityByField(columnsVisibility, 'modifiedAt', true)
                    },
                    {
                        displayName: 'Updated By',
                        field: 'modifiedByFullName',
                        visible: GridService.getVisibilityByField(columnsVisibility, 'modifiedByFullName', true),
                        filter: {
                            term: GridService.getFilterByField(columnsFilters, 'modifiedByFullName', ''),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        displayName: 'Last Published to TU',
                        field: 'lastPublished',
                        type: 'date',
                        enableFiltering: true,
                        filterCellFiltered: true,
                        enableSorting: false,
                        cellFilter: 'date:\'medium\' : \'UTC\'',
                        filters: [
                            {
                                visible: true,
                                condition: uiGridConstants.filter.GREATER_THAN
                            },
                            {
                                condition: uiGridConstants.filter.LESS_THAN
                            }
                        ],
                        filter: {
                            term: GridService.getFilterByField(columnsFilters, 'lastPublished', '', false),
                            applyTextFilter: applyDateFilter('lastPublished', '')
                        },
                        filterHeaderTemplate: dateFilterTemplate,
                        visible: GridService.getVisibilityByField(columnsVisibility, 'lastPublished', true)
                    },
                    {
                        displayName: 'Last Submitted to Client',
                        field: 'lastSubmit',
                        enableFiltering: true,
                        filterCellFiltered: true,
                        enableSorting: false,
                        type: 'date',
                        cellFilter: 'date:\'medium\' : \'UTC\'',
                        filters: [
                            {
                                visible: true,
                                condition: uiGridConstants.filter.GREATER_THAN
                            },
                            {
                                condition: uiGridConstants.filter.LESS_THAN
                            }
                        ],
                        filter: {
                            term: GridService.getFilterByField(columnsFilters, 'lastSubmit', '', false),
                            applyTextFilter: applyDateFilter('lastSubmit', '')
                        },
                        filterHeaderTemplate: dateFilterTemplate,
                        visible: GridService.getVisibilityByField(columnsVisibility, 'lastSubmit', true)
                    },
                    {
                        displayName: 'Published',
                        field: 'published',
                        filter: {
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: [
                                {value: 'Yes', label: 'Yes'},
                                {value: 'No', label: 'No'},
                                {value: 'Unpublished', label: 'Unpublished'}
                            ],
                            term: GridService.getFilterByField(paginationOptions, 'published')
                        },
                        filterHeaderTemplate: selectTemplate,
                        visible: GridService.getVisibilityByField(columnsVisibility, 'published', true)
                    }
                ],
                onRegisterApi: function (gridApi) {
                    gridApiInstance = gridApi;
                    gridApi.core.on.rowsRendered($scope, function () {
                        isRendered = true;

                        GridService.generateSelectValues(gridApiInstance.grid.columns, selectValues);
                        GridService.generateSelectValues(gridApiInstance.grid.columns, lastSelectValues);
                    });

                    gridApi.selection.on.rowSelectionChanged($scope, function (row) {
                         handleDatagridRowClick(row.entity);
                    });

                    gridApi.pagination.on.paginationChanged($scope, function (newPage, limit) {
                        defaultPaginationOptions.page = newPage;
                        defaultPaginationOptions.limit = limit;
                        if(!filterChangesInProgress) {
                            getPage();
                        }
                    });

                    gridApi.core.on.filterChanged($scope, handleGridFiltersChanges);
                    gridApi.core.on.rowsVisibleChanged($scope, handleGridVisibleChanges);
                    getPage();
                }
            };
            if (UserFactory.isStarsAnalyst()) {
                controller.gridOptions.columnDefs.splice(0, 1);
            }

            datePickersFixes();
            controller.filters = angular.extend({}, defaultFilters);
            storeColumnsVisibility();
        }

        function getColumnNumber(columnName) {
            var key = 0,
                total = controller.gridOptions.columnDefs.length;
            for (key; key < total; key++) {
                if (controller.gridOptions.columnDefs[key] &&
                    controller.gridOptions.columnDefs[key].field === columnName
                ) {
                    return key;
                }
            }

            return null;
        }

        function datePickersFixes() {
            // This is needed to add 'x' icon in date field when prepopulating dates
            var modifiedAtField = GridService.getFilterByField(paginationOptions, 'modifiedAt', '');
            if (modifiedAtField !== null && paginationOptions["filter[modifiedAt]"] !== null) {
                if (angular.isDefined(modifiedAtField) &&
                    angular.isDefined(paginationOptions["filter[modifiedAt]"]) &&
                    angular.isDefined(paginationOptions["filter[modifiedAt]"].startDate) &&
                    angular.isDefined(paginationOptions["filter[modifiedAt]"].endDate)
                ) {
                    controller.gridOptions.columnDefs[getColumnNumber('modifiedAt')].filters[0].term = paginationOptions["filter[modifiedAt]"].startDate;
                    controller.gridOptions.columnDefs[getColumnNumber('modifiedAt')].filters[1].term = paginationOptions["filter[modifiedAt]"].endDate;
                }
            }

            var subscriptionStartDateField = GridService.getFilterByField(paginationOptions, 'subscriptionStartDate', '');
            if (subscriptionStartDateField !== null && paginationOptions["filter[subscriptionStartDate]"] !== null) {
                if (angular.isDefined(subscriptionStartDateField) &&
                    angular.isDefined(paginationOptions["filter[subscriptionStartDate]"]) &&
                    angular.isDefined(paginationOptions["filter[subscriptionStartDate]"].startDate) &&
                    angular.isDefined(paginationOptions["filter[subscriptionStartDate]"].endDate)
                ) {
                    controller.gridOptions.columnDefs[getColumnNumber('subscriptionStartDate')].filters[0].term = paginationOptions["filter[subscriptionStartDate]"].startDate;
                    controller.gridOptions.columnDefs[getColumnNumber('subscriptionStartDate')].filters[1].term = paginationOptions["filter[subscriptionStartDate]"].endDate;
                }
            }

            var subscriptionEndDateField = GridService.getFilterByField(paginationOptions, 'subscriptionEndDate', '');
            if (subscriptionEndDateField !== null && paginationOptions["filter[subscriptionEndDate]"] !== null) {
                if (angular.isDefined(subscriptionEndDateField) &&
                    angular.isDefined(paginationOptions["filter[subscriptionEndDate]"]) &&
                    angular.isDefined(paginationOptions["filter[subscriptionEndDate]"].startDate) &&
                    angular.isDefined(paginationOptions["filter[subscriptionEndDate]"].endDate)
                ) {
                    controller.gridOptions.columnDefs[getColumnNumber('subscriptionEndDate')].filters[0].term = paginationOptions["filter[subscriptionEndDate]"].startDate;
                    controller.gridOptions.columnDefs[getColumnNumber('subscriptionEndDate')].filters[1].term = paginationOptions["filter[subscriptionEndDate]"].endDate;
                }
            }

            var lastPublishedField = GridService.getFilterByField(paginationOptions, 'lastPublished', '');
            if (lastPublishedField !== null && paginationOptions["filter[lastPublished]"] !== null) {
                if (angular.isDefined(lastPublishedField) &&
                    angular.isDefined(paginationOptions["filter[lastPublished]"]) &&
                    angular.isDefined(paginationOptions["filter[lastPublished]"].startDate) &&
                    angular.isDefined(paginationOptions["filter[lastPublished]"].endDate)
                ) {
                    controller.gridOptions.columnDefs[getColumnNumber('lastPublished')].filters[0].term = paginationOptions["filter[lastPublished]"].startDate;
                    controller.gridOptions.columnDefs[getColumnNumber('lastPublished')].filters[1].term = paginationOptions["filter[lastPublished]"].endDate;
                }
            }

            var lastSubmitField = GridService.getFilterByField(paginationOptions, 'lastSubmit', '');
            if (lastSubmitField !== null && paginationOptions["filter[lastSubmit]"] !== null) {
                if (angular.isDefined(lastSubmitField) &&
                    angular.isDefined(paginationOptions["filter[lastSubmit]"]) &&
                    angular.isDefined(paginationOptions["filter[lastSubmit]"].startDate) &&
                    angular.isDefined(paginationOptions["filter[lastSubmit]"].endDate)
                ) {
                    controller.gridOptions.columnDefs[getColumnNumber('lastSubmit')].filters[0].term = paginationOptions["filter[lastSubmit]"].startDate;
                    controller.gridOptions.columnDefs[getColumnNumber('lastSubmit')].filters[1].term = paginationOptions["filter[lastSubmit]"].endDate;
                }
            }
        }

        function storeColumnsVisibility() {
            if (!controller.gridOptions) {
                return false;
            }
            angular.forEach(controller.gridOptions.columnDefs, function (column) {
                if (angular.isDefined(column.visible) && column.visible === true) {
                    columnsBeforeHide.push(column);
                }
            });
        }

        function handleGridVisibleChanges () {
            if (!controller.selectedRowId) {
                if (gridApiInstance.grid.selection.selectedCount === 1) {
                    gridApiInstance.selection.clearSelectedRows();
                }
            }
            var columnsVisibility = GridService.getColumnsVisibility(gridNamespaces.visibility.institutionList),
            reload = false;
            if (columnsVisibility && !controller.selectedRowId) {
                angular.forEach(controller.gridOptions.columnDefs, function (column, key) {
                    if (columnsVisibility[column.field] === true &&
                        column.visible === false
                    ) {
                        if (typeof  controller.gridOptions.columnDefs[key].filter === 'undefined') {
                            controller.gridOptions.columnDefs[key].filter = {};
                        }
                        // decide whether reload or no.
                        if ((angular.isDefined(controller.gridOptions.columnDefs[key].filter) &&
                            controller.gridOptions.columnDefs[key].filter.hasOwnProperty('term') &&
                            typeof controller.gridOptions.columnDefs[key].filter.term !== 'undefined' &&
                            controller.gridOptions.columnDefs[key].filter.term !== null &&
                            controller.gridOptions.columnDefs[key].filter.term.toString().length > 0) ||
                            ((angular.isDefined(controller.gridOptions.columnDefs[key].filters) &&
                            controller.gridOptions.columnDefs[key].filters[0].hasOwnProperty('term') &&
                                controller.gridOptions.columnDefs[key].filters[0].term &&
                                controller.gridOptions.columnDefs[key].filters[0].term.length > 0
                            ) || (
                                angular.isDefined(controller.gridOptions.columnDefs[key].filters) &&
                                controller.gridOptions.columnDefs[key].filters[1].hasOwnProperty('term') &&
                                controller.gridOptions.columnDefs[key].filters[1].term &&
                                controller.gridOptions.columnDefs[key].filters[1].term.length > 0
                            ))) {
                                paginationOptions['filter[' + column.field + ']'] = null;
                                reload = true;
                        }
                        controller.gridOptions.columnDefs[key].filter.term = null;
                        columnsFilters['filter[' + column.field + ']'] = null;
                        if (column.field === 'answers.0.overallStarsRating') {
                            controller.gridOptions.columnDefs[key].filter.term = null;
                            columnsFilters['filter[answers.0.overallStarsRatingAsString]'] = null;
                        }
                        if (column.field === 'answers.0.totalStarsRating') {
                            controller.gridOptions.columnDefs[key].filter.term = null;
                            columnsFilters['filter[answers.0.totalStarsRatingAsString]'] = null;
                        }
                        if (column.field === 'modifiedAt') {
                            delete  controller.gridOptions.columnDefs[key].filters[0].term;
                            delete  controller.gridOptions.columnDefs[key].filters[1].term;
                            controller.filters.modifiedAtRange = columnsFilters['filter[modifiedAt]'] = {
                                startDate: null,
                                endDate: null
                            };
                        }
                        else if (column.field === 'subscriptionEndDate') {
                            delete  controller.gridOptions.columnDefs[key].filters[0].term;
                            delete  controller.gridOptions.columnDefs[key].filters[1].term;
                            controller.filters.subscriptionEndDateRange = columnsFilters['filter[subscriptionEndDate]'] = {
                                startDate: null,
                                endDate: null
                            };
                        }
                        else if (column.field === 'subscriptionStartDate') {
                            controller.gridOptions.columnDefs[key].filters[0].term = null;
                            controller.gridOptions.columnDefs[key].filters[1].term = null;
                            controller.filters.subscriptionStartDateRange = columnsFilters['filter[subscriptionStartDate]'] = {
                                startDate: null,
                                endDate: null
                            };
                        }
                        else if (column.field === 'lastPublished') {
                            delete  controller.gridOptions.columnDefs[key].filters[0].term;
                            controller.filters.lastPublishedRange = {
                                startDate: null,
                                endDate: null
                            };
                        }
                        else if (column.field === 'lastSubmit') {
                            delete  controller.gridOptions.columnDefs[key].filters[0].term;
                            controller.filters.lastSubmitRange = {
                                startDate: null,
                                endDate: null
                            };
                        }
                    }
                });
            }
            if (controller.gridOptions) {
                controller.columnsVisibility = GridService.getGridColumnsVisibility(controller.gridOptions.columnDefs);
                if(!controller.selectedRowId) {
                    GridService.storeColumnsVisibility(
                        gridNamespaces.visibility.institutionList,
                        controller.columnsVisibility
                    );
                }
            }
            if (reload) {
                getPage();
            }
        }


        function handleGridFiltersChanges() {
            filterChangesInProgress = true;
            if(gridApiInstance.pagination.getPage() > 1){
                gridApiInstance.pagination.seek(1);
            }
            angular.forEach(gridApiInstance.grid.columns, function (column) {
                var filterColumn = column.filters[0].column ? column.filters[0].column : column.field,
                    deleteFilter = false;

                if (column.field === 'modifiedAt' ||
                    column.field === 'subscriptionEndDate' ||
                    column.field === 'subscriptionStartDate' ||
                    column.field === 'lastPublished' ||
                    column.field === 'lastSubmit'
                ) {
                    if (typeof column.filters !== 'undefined' &&
                        typeof column.filters[0].term !== 'undefined' &&
                        typeof column.filters[1].term !== 'undefined'
                    ) {
                        paginationOptions['filter[' + filterColumn + ']'] = {
                            startDate: column.filters[0].term && isNaN(column.filters[0].term) && typeof column.filters[0].term === 'object' ?
                                column.filters[0].term.format('x') : column.filters[0].term,
                            endDate: column.filters[1].term && isNaN(column.filters[1].term) && typeof column.filters[1].term === 'object' ?
                                column.filters[1].term.format('x') : column.filters[1].term
                        };
                    } else {
                        paginationOptions['filter[' + filterColumn + ']'] = null;
                    }
                } else {
                    if (column.filters[0].type === uiGridConstants.filter.SELECT &&
                        typeof column.filters[0].term === 'object' &&
                        column.filters[0].term !== null &&
                        typeof column.filters[0].term.value !== 'undefined'
                    ) {
                        column.filters[0].term = column.filters[0].term.value;
                    }
                    if (typeof column.filters !== 'undefined' &&
                        column.filters[0].term !== null &&
                        typeof column.filters[0].term !== 'undefined'
                    ) {
                        GridService.applyFilters(column);
                        if (column.filters[0].term === '') {
                            deleteFilter = true;
                        } else {
                            paginationOptions['filter[' + filterColumn + ']'] = column.filters[0].valuePrefix && !paginationOptions['filter[' + filterColumn + ']']? column.filters[0].valuePrefix + column.filters[0].term : column.filters[0].term;
                        }
                    } else {
                        deleteFilter = true;
                    }

                    if (deleteFilter && typeof paginationOptions['filter[' + filterColumn + ']'] !== 'undefined') {
                        delete paginationOptions['filter[' + filterColumn + ']'];
                    }
                }

            });
            dateFilters('modifiedAt');
            dateFilters('subscriptionStartDate');
            dateFilters('subscriptionEndDate');
            dateFilters('lastPublished');
            dateFilters('lastSubmit');

            GridService.storeColumnsFilters(
                gridNamespaces.filters.institutionList,
                paginationOptions
            );
            GridService.generateSelectValues(gridApiInstance.grid.columns, selectValues);

            if (angular.isDefined(filtersTimeout)) {
                $timeout.cancel(filtersTimeout);
            }
            filtersTimeout = $timeout(function () {
                getPage();
                filterChangesInProgress = false;
                lastSelectValues = angular.copy(selectValues);
            }, constants.datagridSelectDelay);
        }

        function dateFilters(field) {
            if (paginationOptions['filter[' + field + ']'] === null) {
                paginationOptions['filter[' + field + ']'] = {
                    startDate: null,
                    endDate: null
                };
            }
            if (paginationOptions['filter[' + field + ']'].startDate === null) {
                paginationOptions['filter[' + field + ']'].endDate = null;
                controller.filters[field + 'Range'] = {
                    startDate: null,
                    endDate: null
                };
            }
        }

        function toggleColumns(hide) {
            if (!controller.gridOptions) {
                return;
            }
            if (hide) {
                columnsBeforeHide = angular.copy(controller.gridOptions.columnDefs);
                // hide all columns except institutions
                angular.forEach(controller.gridOptions.columnDefs, function (column) {
                    column.visible = column.field === 'institutionCoreIdAsString';
                });
            } else {
                var columns = [];
                // show columns visible before hide
                angular.forEach(columnsBeforeHide, function (column) {
                    if (column.visible) {
                        columns.push(column.field);
                    }
                });
                angular.forEach(controller.gridOptions.columnDefs, function (column) {
                    if (columns.indexOf(column.field) !== -1) {
                        column.visible = true;
                    }
                });
            }

            controller.gridOptions.enableGridMenu = !hide;
            controller.gridOptions.enableColumnMenus = !hide;
            controller.gridOptions.enableColumnResizing = !hide;
            controller.gridOptions.enablePaginationControls = !hide;
            controller.showInfoBlock = !hide;
        }

        function getPage() {
            loadingData = true;
            return StarsInstitutionsListService.search(
                angular.merge({}, paginationOptions, defaultPaginationOptions)
            ).then(function (response) {
                controller.gridOptions.totalItems = response.totalMatching;
                controller.gridOptions.data = response.results;
                controller.gridOptions.minRowsToShow = response.totalFiltered;
                controller.institutionsToDelete = {};
                $timeout(function () {
                    loadingData = false;
                },200);
            });
        }

        function handleDatagridRowClick(row) {
            if(row.answers && row.answers[0] && row.answers[0].starsPerCategory) {
                if (row.answers[0].scores === undefined) {
                    row.answers[0].scores = {};
                }
                angular.forEach(handles, function(value, key) { 
                    if (row.answers[0].starsPerCategory.hasOwnProperty(value)) {
                        if (row.answers[0].scores[value] === undefined) {
                            row.answers[0].scores[value] = {};
                        }
                        //Fix for QCR-5336
                        // if (row.answers[0].starsPerCategory[value] || row.answers[0].starsPerCategory[value] === 0) {
                        //     row.answers[0].scores[value].show = true;
                        // }
                    }
                    //Fix for QCR-5336
                    // else {
                    //     if (row.answers[0].scores[value]) {
                    //         row.answers[0].scores[value].show = false;
                    //     }
                    // }
                });
            }
            if (UnsavedChangesFactory.hasChanges()) {
                var modalOptions = {
                    handleNoClick: function () {
                        // close modal window
                        ModalService.close();
                        UnsavedChangesFactory.resetChanges();
                        setSelectedItem(row);
                    },
                    handleYesClick: function () {
                        // close modal window
                        ModalService.close();
                        UnsavedChangesFactory.runSaveFunction();
                        setSelectedItem(row);
                    }
                }, modalDefaults = {
                    backdrop: 'static',
                    keyboard: false,
                    modalFade: true,
                    templateUrl: '/scripts/shared/unsavedChanges/unsavedChangesView.html'
                };
                ModalService.show(modalDefaults, modalOptions);
            }
            else {
                setSelectedItem(row);
            }
           
        }

        function setSelectedItem(row) {
            if (row.answers) {
                StarsEditFactory.setSelectedItemAnswers(angular.copy(row.answers));
            }
            controller.selectedRowId = (controller.selectedRowId === row.id) ? null : row.id;
            controller.selectedItem = angular.copy(row);
        }

        function getDateField(field) {
            var total = controller.gridOptions.columnDefs.length,
                i = 0;
            for (i; i < total; i++) {
                if (controller.gridOptions.columnDefs[i].type === 'date' &&
                    controller.gridOptions.columnDefs[i].field === field
                ) {
                    return controller.gridOptions.columnDefs[i];
                }
            }
        }

        controller.handleSubscriptionStartDateRange = function (event) {
            if (DateRangeFixerService.sameDayAndNoModel(event, controller.filters.subscriptionStartDateRange, subscriptionStartDateFilter)) {
                event.model.startDate = subscriptionStartDateFilter.startDate;
                event.model.endDate = subscriptionStartDateFilter.endDate;
                controller.filters.subscriptionStartDateRange = {
                    startDate: subscriptionStartDateFilter.startDate,
                    endDate: subscriptionStartDateFilter.endDate
                };
            } else if (DateRangeFixerService.todayRangeAndNoModel(event, subscriptionStartDateFilter)) {
                event.model.startDate = TimeService.getStartOfToday();
                event.model.endDate = TimeService.getEndOfToday();
                controller.filters.subscriptionStartDateRange = {
                    startDate: event.model.startDate.toISOString(),
                    endDate: event.model.endDate.toISOString()
                };
            }
            var subscriptionStartDateField = getDateField('subscriptionStartDate'),
                subscriptionStartDateRange = {
                    startDate: event.model.startDate,
                    endDate: event.model.endDate
                };

            if (subscriptionStartDateField) {
                if (event === null || typeof event === 'undefined') {
                    subscriptionStartDateField.filters[0].term = null;
                    subscriptionStartDateField.filters[1].term = null;
                } else if (event.model.startDate && event.model.endDate) {
                    subscriptionStartDateField.filters[0].term = typeof event.model.startDate === 'object' ? event.model.startDate.format('x') : event.model.startDate;
                    subscriptionStartDateField.filters[1].term = typeof event.model.endDate === 'object' ? event.model.endDate.format('x') : event.model.endDate;
                }
            }
            subscriptionStartDateFilter = angular.copy(subscriptionStartDateRange);
        };

        controller.handleSubscriptionEndDateRange = function (event) {
            if (DateRangeFixerService.sameDayAndNoModel(event, controller.filters.subscriptionEndDateRange, subscriptionEndDateFilter)) {
                event.model.startDate = subscriptionEndDateFilter.startDate;
                event.model.endDate = subscriptionEndDateFilter.endDate;
                controller.filters.subscriptionEndDateRange = {
                    startDate: subscriptionEndDateFilter.startDate,
                    endDate: subscriptionEndDateFilter.endDate
                };
            } else if (DateRangeFixerService.todayRangeAndNoModel(event, subscriptionEndDateFilter)) {
                event.model.startDate = TimeService.getStartOfToday();
                event.model.endDate = TimeService.getEndOfToday();
                controller.filters.subscriptionEndDateRange = {
                    startDate: event.model.startDate.toISOString(),
                    endDate: event.model.endDate.toISOString()
                };
            }
            var subscriptionEndDateField = getDateField('subscriptionEndDate');
            if (subscriptionEndDateField) {
                if (event === null || typeof event === 'undefined') {
                    subscriptionEndDateField.filters[0].term = null;
                    subscriptionEndDateField.filters[1].term = null;
                } else if (event.model.startDate && event.model.endDate) {
                    subscriptionEndDateField.filters[0].term = typeof event.model.startDate === 'object' ? event.model.startDate.format('x') : event.model.startDate;
                    subscriptionEndDateField.filters[1].term = typeof event.model.endDate === 'object' ? event.model.endDate.format('x') : event.model.endDate;
                }
            }
            subscriptionEndDateFilter = angular.copy({
                startDate: event.model.startDate,
                endDate: event.model.endDate
            });
        };

        controller.handleModifiedAtRange = function (event) {
            if (DateRangeFixerService.sameDayAndNoModel(event, controller.filters.modifiedAtRange, lastUpdatedAtDateFilter)) {
                event.model.startDate = lastUpdatedAtDateFilter.startDate;
                event.model.endDate = lastUpdatedAtDateFilter.endDate;
                controller.filters.modifiedAtRange = {
                    startDate: lastUpdatedAtDateFilter.startDate,
                    endDate: lastUpdatedAtDateFilter.endDate
                };
            } else if (DateRangeFixerService.todayRangeAndNoModel(event, lastUpdatedAtDateFilter)) {
                event.model.startDate = TimeService.getStartOfToday();
                event.model.endDate = TimeService.getEndOfToday();
                controller.filters.modifiedAtRange = {
                    startDate: event.model.startDate.toISOString(),
                    endDate: event.model.endDate.toISOString()
                };
            }
            var updatedAtField = getDateField('modifiedAt');
            if (updatedAtField) {
                if (event === null || typeof event === 'undefined') {
                    updatedAtField.filters[0].term = null;
                    updatedAtField.filters[1].term = null;
                } else if (event.model.startDate && event.model.endDate) {
                    updatedAtField.filters[0].term = typeof event.model.startDate === 'object' ? event.model.startDate.format('x') : event.model.startDate;
                    updatedAtField.filters[1].term = typeof event.model.endDate === 'object' ? event.model.endDate.format('x') : event.model.endDate;
                }
            }
            lastUpdatedAtDateFilter = angular.copy({
                startDate: event.model.startDate,
                endDate: event.model.endDate
            });
        };

        controller.handleLastSubmitRange = function (event) {
            if (DateRangeFixerService.sameDayAndNoModel(event, controller.filters.lastSubmitRange, lastSubmitDateFilter)) {
                event.model.startDate = lastSubmitDateFilter.startDate;
                event.model.endDate = lastSubmitDateFilter.endDate;
                controller.filters.lastSubmitRange = {
                    startDate: lastSubmitDateFilter.startDate,
                    endDate: lastSubmitDateFilter.endDate
                };
            } else if (DateRangeFixerService.todayRangeAndNoModel(event, lastSubmitDateFilter)) {
                event.model.startDate = TimeService.getStartOfToday();
                event.model.endDate = TimeService.getEndOfToday();
                controller.filters.lastSubmitRange = {
                    startDate: event.model.startDate.toISOString(),
                    endDate: event.model.endDate.toISOString()
                };
            }
            var lastSubmitDateField = getDateField('lastSubmit');
            if (lastSubmitDateField) {
                if (event === null || typeof event === 'undefined') {
                    lastSubmitDateField.filters[0].term = null;
                    lastSubmitDateField.filters[1].term = null;
                } else if (event.model.startDate && event.model.endDate) {
                    lastSubmitDateField.filters[0].term = typeof event.model.startDate === 'object' ? event.model.startDate.format('x') : event.model.startDate;
                    lastSubmitDateField.filters[1].term = typeof event.model.endDate === 'object' ? event.model.endDate.format('x') : event.model.endDate;
                }
            }
            lastSubmitDateFilter = angular.copy(controller.filters.lastSubmitRange);
        };

        controller.handleLastPublishedRange = function (event) {
            if (DateRangeFixerService.sameDayAndNoModel(event, controller.filters.lastPublishedRange, lastPublishedDateFilter)) {
                event.model.startDate = lastPublishedDateFilter.startDate;
                event.model.endDate = lastPublishedDateFilter.endDate;
                controller.filters.lastPublishedRange = {
                    startDate: lastPublishedDateFilter.startDate,
                    endDate: lastPublishedDateFilter.endDate
                };
            } else if (DateRangeFixerService.todayRangeAndNoModel(event, lastPublishedDateFilter)) {
                event.model.startDate = TimeService.getStartOfToday();
                event.model.endDate = TimeService.getEndOfToday();
                controller.filters.lastPublishedRange = {
                    startDate: event.model.startDate.toISOString(),
                    endDate: event.model.endDate.toISOString()
                };
            }
            var lastPublishedDateField = getDateField('lastPublished');
            if (lastPublishedDateField) {
                if (event === null || typeof event === 'undefined') {
                    lastPublishedDateField.filters[0].term = null;
                    lastPublishedDateField.filters[1].term = null;
                } else if (event.model.startDate && event.model.endDate) {
                    lastPublishedDateField.filters[0].term = typeof event.model.startDate === 'object' ? event.model.startDate.format('x') : event.model.startDate;
                    lastPublishedDateField.filters[1].term = typeof event.model.endDate === 'object' ? event.model.endDate.format('x') : event.model.endDate;
                }
            }
            lastPublishedDateFilter = angular.copy(controller.filters.lastPublishedRange);
        };

        function getIdsForDeletion() {
            var list = [];
            angular.forEach(controller.itemsToDelete, function (remove, id) {
                if (remove) {
                    list.push(id);
                }
            });
            return list;
        }

        controller.isDeleteButtonDisabled = function () {
            return deleteInProgress || !getIdsForDeletion().length || UserFactory.isStarsAnalyst();
        };

        function deleteFromDatagrid() {
            var i = 0,
                rows = controller.gridOptions.data,
                total = rows.length,
                toDelete = getIdsForDeletion();
            for (i; i < total; i++) {
                if (rows[i] && rows[i].id && toDelete.indexOf(rows[i].id) !== -1) {
                    if (rows[i].id === controller.selectedRowId) {
                        controller.selectedRowId = null;
                    }
                    controller.gridOptions.data.splice(i, 1);
                    toDelete.splice(i, 1);
                    if (!toDelete.length) {
                        break;
                    }
                }
            }
        }

        controller.handleDeleteClick = function () {
            if (controller.isDeleteButtonDisabled()) {
                return false;
            }
            var modalOptions = {
                handleNoClick: function () {
                    this.close();
                },
                handleYesClick: function () {
                    deleteInProgress = true;
                    StarsInstitutionsListService.delete(getIdsForDeletion()).then(function (success) {
                        deleteInProgress = false;
                        if (success) {
                            deleteFromDatagrid();
                            controller.itemsToDelete = {};
                        }
                        NotifierFactory.show(
                            success ? 'success' : 'error',
                            success ? 'Successfully!' : 'Failed!',
                            'Institutions Deleted'
                        );
                    });
                    this.close();
                },
                headerText :'Institution Deletion',
                bodyText: 'Are you sure you want to delete the institution/s from the Stars Institution List?'
            }, modalDefaults = {
                backdrop: true,
                keyboard: true,
                modalFade: true,
                templateUrl: '/scripts/shared/areYouSure/deleteAreYouSure.html'
            };
            ModalService.show(modalDefaults, modalOptions);
        };

        controller.showLoadBar = function () {
            return !isRendered || loadingData;
        };

        controller.getEmptyArray = function (length) {
            return new Array(angular.isDefined(length) ? length : 0);
        };

        function reloadGridRequestWatch (reload) {
            if (reload) {
                getPage();
                StarsDatagridFactory.setReloadGrid(false);
            }
        }

        function selectedRowIdWatch(selectedRowId, oldSelectedRowId) {
            if ((selectedRowId && oldSelectedRowId) || (selectedRowId === null && oldSelectedRowId  === null)) {
                return;
            }
            toggleColumns(selectedRowId !== null);
        }

        function selectedIdWatch(id) {
            if (id) {
                controller.selectedRowId = id;
            }
        }

        function initWatches() {
            WatchService.create($scope, StarsDatagridFactory.getIsReloadGrid, reloadGridRequestWatch);
            WatchService.create($scope, '$ctrl.selectedRowId', selectedRowIdWatch);
            WatchService.create($scope, StarsEditFactory.getSelectedId, selectedIdWatch);
        }

        controller.$onInit = function () {
            StarsInstitutionsListService.getStarsList().then(function (list) {
                starsList = list;
                starsList.unshift({
                    value: "null",
                    label: 'No stars'
                });
                initDatagridOptions();
            });
            initWatches();
        };
    }

}(window.angular));

(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, { factories: {} });

    App.factories.starsDatagrid = function () {
        var reloadGrid = false;

        return {
            getIsReloadGrid: function() {
                return reloadGrid;
            },
            setReloadGrid: function(newValue) {
                reloadGrid = newValue;
            }
        };
    };

    angular
        .module('qsHub.rankings')
        .factory('StarsDatagridFactory', [
            App.factories.starsDatagrid
        ]);

}(window.angular));

(function (angular) {

  'use strict';

  angular.module('qsHub.stars').component('starsSearchDropdown', {
      templateUrl: '/scripts/modules/stars/components/searchDropdown/starsSearchDropdownView.html',
      bindToController: true,
      controller: 'Stars.SearchDropdownController'
  });

}(window.angular));




(function (angular) {
    "use strict";

    angular
        .module('qsHub.stars')
        .controller('Stars.SearchDropdownController', [
            'StarsSearchDropdownService',
            'ModalService',
            'StarsDatagridFactory',
            'NotifierFactory',
            Controller
        ]);

    function Controller(
        StarsSearchDropdownService,
        ModalService,
        StarsDatagridFactory,
        NotifierFactory
    ) {
        var controller = this;
        controller.institutionsDropdownList = [];
        controller.searchInProgress = false;

        controller.handleSearchInstitution = function (searchPhrase) {
            controller.searchInProgress = true;
            if (!searchPhrase ||
                searchPhrase.indexOf('/') !== -1
            ) {
                controller.institutionsDropdownList = [];
                controller.searchInProgress = false;
                return;
            }
            StarsSearchDropdownService.searchInstitutions(searchPhrase).then(function (data) {
                controller.institutionsDropdownList = data;
                controller.searchInProgress = false;
            });
        };

        controller.handleAddInstitution = function (institution) {
            var institutionName = institution.institutionName.replace(' [' + institution.institutionCoreId + ']', ''),
                modalOptions = {
                    closeButtonText: 'Cancel',
                    actionButtonText: 'Yes',
                    actionButtonClass: 'primary',
                    headerText: 'Add Institution "' + institutionName + '"',
                    bodyText: 'Are you sure you want to add this institution to the list?'
                };
            ModalService.show({}, modalOptions).then(function () {
                // Handle yes
                controller.starsSelectedInstitution = null;
                // call endpoint that will add
                controller.searchInProgress = true;
                StarsSearchDropdownService.addRecord({
                    institutionName : institutionName,
                    institutionCoreId : institution.institutionCoreId,
                    institutionTypeId: institution.institutionTypeId,
                    institutionType: institution.institutionType
                }).then(function (data) {
                    // refresh datagrid
                    var success = data.insertedCount > 0 || data.modifiedCount > 0;
                    if (success) {
                        StarsDatagridFactory.setReloadGrid(true);
                    }
                    NotifierFactory.show(
                        success ? 'success' : 'error',
                        success ? 'Stars institution added successfully!' : 'Stars institution add failed!',
                        'Stars Institutions'
                    );
                    controller.searchInProgress = false;
                });
            },
            function() {
                // Handle no
                controller.institutionsDropdownList = [];
                controller.starsSelectedInstitution = null;
            });
        };

        controller.$onInit = function () {
        };
    }

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.stars')
        .service('StarsSearchDropdownService', [
            '$resource',
            'RequestsQueueService',
            'constants',
            Service
        ]);

    function Service(
        $resource,
        RequestsQueueService,
        constants
    ) {
        var service = {};

        function getModel() {
            return $resource(constants.api.stars.url, {}, {
                searchInstitutions: {
                    method: 'GET',
                    url: constants.api.stars.url + '/v1/search/institutions/:searchPhrase?columns[0]=institutionCoreId',
                    isArray: true,
                    cancellable: true
                },
                addRecord: {
                    method: 'POST',
                    url: constants.api.stars.url + '/v1/record'
                }

            });
        }

        service.searchInstitutions = function (searchPhrase) {
            if (!searchPhrase) {
                throw 'Missing Required';
            }

            var key = 'starsSearchDropdownService::search',
                Api;

            RequestsQueueService.cancelAll(key);
            Api = getModel().searchInstitutions({searchPhrase: searchPhrase});
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                return data;
            }, function () {
                return false;
            });
        };

        service.addRecord = function (record) {
            var Api;

            Api = getModel().addRecord(record);

            return Api.$promise.then(function (data) {
                return data;
            }, function () {
                return false;
            });
        };

        return service;
    }

}(window.angular));


(function (angular) {
    'use strict';

    angular.module('qsHub.stars').component('starsRightSidePanel', {
        templateUrl: '/scripts/modules/stars/components/starsRightSidePanel/starsRightSidePanelView.html',
        bindToController: true,
        controller: 'stars.RightSidePanelController',
        bindings: {
            selectedItemId: '=',
            selectedItem: '='
        }
    });
    
}(window.angular));
    
(function (angular) {
    "use strict";

    angular
        .module('qsHub.stars')
        .controller('stars.RightSidePanelController', [
            Controller
        ]);

    function Controller() {
        var controller = this;

        controller.isRightSidePanelActive = function () {
            return controller.selectedItemId !== null;
        };

        controller.closeRightSidePanel = function () {
            controller.selectedItemId = null;
            controller.selectedItem = null;
        };
    }

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.stars')
        .controller('stars.editController', [
            '$scope',
            '$location',
            '$state',
            'stars.constants',
            'WatchService',
            'StarsInstitutionsListService',
            'starsEditFactory',
            'NotifierFactory',
            'UnsavedChangesFactory',
            'InstitutionFactory',
            'StarsDatagridFactory',
            'TimeService',
            'UserFactory',
            '$timeout',
            'InstitutionsListService',
            'ModalService',
            Controller
        ]);

    function Controller(
        $scope,
        $location,
        $state,
        starsConstants,
        WatchService,
        StarsInstitutionsListService,
        StarsEditFactory,
        NotifierFactory,
        UnsavedChangesFactory,
        InstitutionFactory,
        StarsDatagridFactory,
        TimeService,
        UserFactory,
        $timeout,
        InstitutionsListService,
        ModalService
    ) {
        var controller = this,
            sectionHandlesUnis = [],
            sectionHandlesBusiness = [],
            universitySections = [],
            businessSchoolSections = [],
            uniCategoryHandles = [],
            businessCategoryHandles = [],
            firstLoad = true,
            indicators = StarsEditFactory.getIndicators(),
            handles = StarsEditFactory.getHandles(),
            dropdowns = {
                employability : {
                    careerServiceSupport :{
                        name : 'career_service_support',
                        businessList :[],
                        universityList :[]
                    },
                    employerReputationCampusEmployerPresence :{
                        name : 'employer_reputation_campus_employer_presence',
                        businessList :[],
                        universityList :[]
                    }

                },
                facilities : {
                    itInfrastructure :{
                        name : 'it_infrastructure',
                        businessList :[],
                        universityList :[]
                    },
                    libraryFacilities :{
                        name : 'library_facilities',
                        businessList :[],
                        universityList :[]
                    }
                },
                programStrength : {
                    associatedQsSubjectArea :{
                        name : 'associated_qs_subject_area',
                        universityList : null,
                        businessList : null
                    }
                }
            };

        controller.sections = [];
        controller.historyLog = {};
        controller.isAllTabsExpanded = false;
        controller.subscriptionList = [];
        controller.starsList = [];
        controller.totalLoad = 5;
        controller.isLoading = true;
        controller.isSubmitInstitutionEnabled = false;
        controller.categories = {
            coreCriteria : [],
            learningEnvironment : [],
            advancedCriteria : [],
            specialistCriteria : [],
            businessSchoolCriteria: [],
            businessOutputCriteria: [],
            businessSpecialistCriteria: []
        };
        controller.isEditable = false;

        controller.handleRedirectClick = function () {
            if (!controller.noSubscriptionsAccess() &&
                angular.isDefined(controller.selectedItem) &&
                controller.selectedItem.institutionCoreId
            ) {
                InstitutionFactory.setActiveTab(true);
                $state.go('staff.institutions.list', {coreId: controller.selectedItem.institutionCoreId});
            }
        };

        controller.hasSubscription = function() {
            return controller.selectedItem &&
                   ((controller.selectedItem.answers &&
                   controller.selectedItem.answers[0]) ||
                   (controller.selectedItem.subscriptionStartDate &&
                   controller.selectedItem.subscriptionEndDate));
        };

        controller.noSubscriptionsAccess = function () {
            return !UserFactory.isStarsAdmin();
        };

        controller.toggleTabs = function () {
            controller.isAllTabsExpanded = !controller.isAllTabsExpanded;
            controller.sections.map(function (item){
                item.open = !!controller.isAllTabsExpanded;
            });
        };

        controller.handleSubscriptionChanges = function () {
            controller.isSubmitInstitutionEnabled = false;
            var selectedSubscriptionVersionId = StarsInstitutionsListService.getSubscriptionVersionId(controller.subscription);
            if(!angular.equals(controller.selectedItem.answers, StarsEditFactory.getSelectedItemAnswers())) {
                controller.selectedItem.answers = StarsEditFactory.getSelectedItemAnswers();
            }
            if (!controller.selectedItem.answers) {
                controller.selectedItem.answers = [];
            }
            if (!angular.isDefined(controller.selectedItem.answers[0]) ||
                !controller.selectedItem.answers[0].version
            ) {
                initContentPage(false, selectedSubscriptionVersionId, true);
                return;
            }
            // Don't do anything if already selected
            if(controller.selectedItem.answers[0].version === controller.subscription.value) {
                return;
            }
            controller.isSubmitInstitutionEnabled = false;
            var total = controller.selectedItem.answers.length,
                i = 0,
                item,
                exists = false,
                versionId;
            for (i; i < total; i++) {
                item = controller.selectedItem.answers[i];
                if (item.version === controller.subscription.value) {
                    if (i !== 0) {
                        versionId = item.versionId;
                        controller.selectedItem.version = versionId ? versionId : controller.selectedItem.version;
                        StarsInstitutionsListService.arrayMove(controller.selectedItem.answers, i, 0);
                        exists = true;
                        break;
                    }
                }
            }
            if (!exists) {
                controller.selectedItem.answers.unshift({
                    version: controller.subscription.value
                });
            }
            if (!versionId || versionId !== selectedSubscriptionVersionId) {
                versionId = selectedSubscriptionVersionId;
                controller.selectedItem.version = versionId;
            }
            initContentPage(false, versionId, true);
            StarsEditFactory.setSelectedItemAnswers(angular.copy(controller.selectedItem.answers));
            StarsEditFactory.setValidateRequested(true);
        };

        controller.handleViewAsClient = function () {
            return $location.path(
                $state.href('clients.statistics.stars').replace('#', '')
            ).search({
                coreId: controller.selectedItem.institutionCoreId
            });
        };

        function fetchStarsList() {
            controller.stars = null;
            StarsInstitutionsListService.getStarsList().then(function (list) {
                controller.starsList = list;
                controller.totalLoad--;
            });
        }

        function fetchSubscriptions() {
            controller.subscription = null;
            StarsInstitutionsListService.getSubscriptions(controller.selectedItem.institutionCoreId).then(function (list) {
                controller.subscriptionList = list;
                controller.subscription = controller.subscriptionList[0];
                if (controller.selectedItem.answers &&
                    controller.selectedItem.answers[0] &&
                    controller.selectedItem.answers[0].version
                ) {
                    var total = 0,
                        i = 0,
                        j = 0,
                        totalAnswers = controller.selectedItem.answers.length,
                        miniCore = false,
                        labelMiniCore = 'Mini Core';
                        if (controller.subscriptionList.length === 0 && controller.selectedItem.hasOwnProperty('subscriptionStartDate') && controller.selectedItem.subscriptionStartDate > 0 && controller.selectedItem.hasOwnProperty('subscriptionEndDate') && controller.selectedItem.subscriptionEndDate) {
                            labelMiniCore = TimeService.format(controller.selectedItem.subscriptionStartDate, "MMM DD, YYYY") + ' - ' + TimeService.format(controller.selectedItem.subscriptionEndDate, "MMM DD, YYYY");
                        }

                        for (j; j < totalAnswers; j++) {
                            if (controller.selectedItem.answers[j].version === 'minicore') {
                                miniCore = true;
                            }
                        }
                        if (miniCore) {
                            list.unshift({
                                value: 'minicore',
                                label: labelMiniCore
                            });
                        }
                        total = list.length;
                        for (i; i < total; i++) {
                            if (list[i].value === controller.selectedItem.answers[0].version) {
                                controller.subscription = list[i];
                                break;
                            }
                        }
                }
                controller.totalLoad--;
                if (controller.totalLoad <= 0) {
                    controller.isLoading = false;
                    $timeout(function(){
                        StarsEditFactory.setValidateRequested(true);
                    });
                }
            });
        }

        controller.getDataTemplateUrl = function () {
            if (!controller.selectedItem) {
                return null;
            }
            if (controller.selectedItem.institutionType === starsConstants.institutionType.uni) {
                return 'https://files-core.qs.com/stars/uni-tpl-v2.csv?1523982368434';
            } else {
                return 'https://files-core.qs.com/stars/bs-tpl.csv?15239824443434';
            }
        };

        function getCategoriesBySectionHandle(handle, type) {
            var categories = type === 'universities' ? universitySections : businessSchoolSections,
                i = 0,
                total = categories.length;
            for (i; i < total; i++) {
                if (categories[i].handle === handle) {
                    return categories[i].categoriesHandles;
                }
            }
        }

        function getCategoriesUnis(count, version) {
            return StarsInstitutionsListService.getUniCategories(false, version).then(function (response) {
                if (!response) {
                    return false;
                }
                controller.categories = {
                    coreCriteria : [],
                    learningEnvironment : [],
                    advancedCriteria : [],
                    specialistCriteria : [],
                    businessSchoolCriteria: [],
                    businessOutputCriteria: [],
                    businessSpecialistCriteria: []
                };
                var i = 0,
                    total = sectionHandlesUnis.length;
                for (i; i < total; i++) {
                    var categoriesHandles = getCategoriesBySectionHandle(sectionHandlesUnis[i], 'universities');
                    uniCategoryHandles = angular.copy(uniCategoryHandles.concat(categoriesHandles));
                     for (var j = 0; j < response.results.length; j++) {
                        switch(sectionHandlesUnis[i]) {
                            case 'core_criteria':
                                if (categoriesHandles.indexOf(response.results[j].handle) !== -1) {
                                    controller.categories.coreCriteria.push(response.results[j]);
                                    if (response.results[j].handle === handles.research) {
                                        for (var k = 0; k <= response.results[j].indicators.length; k++ ) {
                                            if(response.results[j].indicators[k] && response.results[j].indicators[k].handle) {
                                                indicators.university.research.push(response.results[j].indicators[k].handle);
                                            }
                                        }
                                    }
                                    if (response.results[j].handle === handles.employability) {
                                        for (var l = 0; l <= response.results[j].indicators.length; l++ ) {
                                            if(response.results[j].indicators[l] && response.results[j].indicators[l].handle) {
                                                indicators.university.employability.push(response.results[j].indicators[l].handle);
                                                if (response.results[j].indicators[l].handle === dropdowns.employability.careerServiceSupport.name &&
                                                    response.results[j].indicators[l].selectOptions &&
                                                    response.results[j].indicators[l].selectOptions.length) {
                                                        dropdowns.employability.careerServiceSupport.universityList =  response.results[j].indicators[l].selectOptions;
                                                }
                                                if (response.results[j].indicators[l].handle === dropdowns.employability.employerReputationCampusEmployerPresence.name &&
                                                    response.results[j].indicators[l].selectOptions &&
                                                    response.results[j].indicators[l].selectOptions.length) {
                                                        dropdowns.employability.employerReputationCampusEmployerPresence.universityList =  response.results[j].indicators[l].selectOptions;
                                                }
                                            }
                                        }
                                    }
                                }
                                break;
                            case 'learning_environment':
                                if (categoriesHandles.indexOf(response.results[j].handle) !== -1) {
                                    controller.categories.learningEnvironment.push(response.results[j]);
                                    if (response.results[j].handle === handles.facilities) {
                                        for (var m = 0; m <= response.results[j].indicators.length; m++ ) {
                                            if(response.results[j].indicators[m] && response.results[j].indicators[m].handle) {
                                                indicators.university.facilities.push(response.results[j].indicators[m].handle);
                                                if (response.results[j].indicators[m].handle === dropdowns.facilities.itInfrastructure.name &&
                                                    response.results[j].indicators[m].selectOptions &&
                                                    response.results[j].indicators[m].selectOptions.length) {
                                                        dropdowns.facilities.itInfrastructure.universityList =  response.results[j].indicators[m].selectOptions;
                                                }
                                                if (response.results[j].indicators[m].handle === dropdowns.facilities.libraryFacilities.name &&
                                                    response.results[j].indicators[m].selectOptions &&
                                                    response.results[j].indicators[m].selectOptions.length) {
                                                        dropdowns.facilities.libraryFacilities.universityList =  response.results[j].indicators[m].selectOptions;
                                                }
                                            }
                                        }
                                    }
                                }
                                break;
                            case 'advanced_criteria':
                                if (categoriesHandles.indexOf(response.results[j].handle) !== -1) {
                                    controller.categories.advancedCriteria.push(response.results[j]);
                                }
                                break;
                            case 'specialist_criteria':
                                if (categoriesHandles.indexOf(response.results[j].handle) !== -1) {
                                    controller.categories.specialistCriteria.push(response.results[j]);
                                    if (response.results[j].handle === handles.programStrength) {
                                        for (var n = 0; n <= response.results[j].indicators.length; n++ ) {
                                            if(response.results[j].indicators[n] && response.results[j].indicators[n].handle) {
                                                indicators.university.programStrength.push(response.results[j].indicators[n].handle);
                                                if (response.results[j].indicators[n].handle === dropdowns.programStrength.associatedQsSubjectArea.name &&
                                                    response.results[j].indicators[n].typeList) {
                                                        dropdowns.programStrength.associatedQsSubjectArea.universityList =  response.results[j].indicators[n].typeList;
                                                }
                                            }
                                        }
                                    }
                                }
                                break;
                        }
                    }
                }
                if (count) {
                    controller.totalLoad--;
                }
            });
        }

        function getCategoriesBussines(count) {
            return StarsInstitutionsListService.getBusinessCategories().then(function (response) {
                if (!response) {
                    return false;
                }
                $timeout(function() {
                    var i = 0,
                    total = sectionHandlesBusiness.length;
                    for (i; i < total; i++) {
                        var categoriesHandles = getCategoriesBySectionHandle(sectionHandlesBusiness[i], 'business');
                        businessCategoryHandles = angular.copy(businessCategoryHandles.concat(categoriesHandles));
                        for (var j = 0; j < response.results.length; j++) {
                            switch(sectionHandlesBusiness[i]) {
                                case 'school_criteria':
                                    if (categoriesHandles.indexOf(response.results[j].handle) !== -1) {
                                        controller.categories.businessSchoolCriteria.push(response.results[j]);
                                            if (response.results[j].handle === handles.facilities) {
                                                for (var k = 0; k <= response.results[j].indicators.length; k++ ) {
                                                    if(response.results[j].indicators[k] && response.results[j].indicators[k].handle) {
                                                        indicators.business.facilities.push(response.results[j].indicators[k].handle);
                                                        if (response.results[j].indicators[k].handle === dropdowns.facilities.itInfrastructure.name &&
                                                            response.results[j].indicators[k].selectOptions &&
                                                            response.results[j].indicators[k].selectOptions.length) {
                                                                dropdowns.facilities.itInfrastructure.businessList =  response.results[j].indicators[k].selectOptions;
                                                        }
                                                        if (response.results[j].indicators[k].handle === dropdowns.facilities.libraryFacilities.name &&
                                                            response.results[j].indicators[k].selectOptions &&
                                                            response.results[j].indicators[k].selectOptions.length) {
                                                                dropdowns.facilities.libraryFacilities.businessList =  response.results[j].indicators[k].selectOptions;
                                                        }
                                                    }
                                                }
                                            }
                                    }
                                    break;
                                case 'output_criteria':
                                    if (categoriesHandles.indexOf(response.results[j].handle) !== -1) {
                                        controller.categories.businessOutputCriteria.push(response.results[j]);
                                        if (response.results[j].handle === handles.research) {
                                            for (var l = 0; l <= response.results[j].indicators.length; l++ ) {
                                                if(response.results[j].indicators[l] && response.results[j].indicators[l].handle) {
                                                    indicators.business.research.push(response.results[j].indicators[l].handle);
                                                }
                                            }
                                        }
                                        if (response.results[j].handle === handles.employability) {
                                            for (var m = 0; m <= response.results[j].indicators.length; m++ ) {
                                                if(response.results[j].indicators[m] && response.results[j].indicators[m].handle) {
                                                    indicators.business.employability.push(response.results[j].indicators[m].handle);
                                                    if (response.results[j].indicators[m].handle === dropdowns.employability.careerServiceSupport.name &&
                                                        response.results[j].indicators[m].selectOptions &&
                                                        response.results[j].indicators[m].selectOptions.length) {
                                                            dropdowns.employability.careerServiceSupport.businessList =  response.results[j].indicators[m].selectOptions;
                                                    }
                                                    if (response.results[j].indicators[m].handle === dropdowns.employability.employerReputationCampusEmployerPresence.name &&
                                                        response.results[j].indicators[m].selectOptions &&
                                                        response.results[j].indicators[m].selectOptions.length) {
                                                            dropdowns.employability.employerReputationCampusEmployerPresence.businessList =  response.results[j].indicators[m].selectOptions;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    break;
                                case 'specialist_criteria':
                                    if (categoriesHandles.indexOf(response.results[j].handle) !== -1) {
                                        controller.categories.businessSpecialistCriteria.push(response.results[j]);
                                        if (response.results[j].handle === handles.programStrength) {
                                            for (var n = 0; n <= response.results[j].indicators.length; n++ ) {
                                                if(response.results[j].indicators[n] && response.results[j].indicators[n].handle) {
                                                    indicators.business.programStrength.push(response.results[j].indicators[n].handle);
                                                    if (response.results[j].indicators[n].handle === dropdowns.programStrength.associatedQsSubjectArea.name &&
                                                        response.results[j].indicators[n].typeList) {
                                                            dropdowns.programStrength.associatedQsSubjectArea.businessList =  response.results[j].indicators[n].typeList;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    break;
                            }
                        }
                    }
                    if (count) {
                        controller.totalLoad--;
                    }
                });
            });
        }

        controller.calculateStarRating = function () {
            var total = 0;
            if (controller.selectedItem !== null &&
                controller.selectedItem.answers &&
                controller.selectedItem.answers[0]
            ) {
                angular.forEach(controller.selectedItem.answers[0].scores, function(score, categoryHandle) {
                    angular.forEach(score, function(value, key) {
                        if (key !== "specialist_program" && key !== "name_specialist_program") {
                            if (controller.selectedItem.institutionType === starsConstants.institutionType.uni) {
                                if (uniCategoryHandles.indexOf(categoryHandle) === -1 ||
                                    categoryHandle === handles.facilities &&  indicators.university.facilities.indexOf(key) === -1 ||
                                    categoryHandle === handles.programStrength && indicators.university.programStrength.indexOf(key) === -1 ||
                                    categoryHandle === handles.research && indicators.university.research.indexOf(key) === -1 ||
                                    categoryHandle === handles.employability && indicators.university.employability.indexOf(key) === -1) {
                                    return;
                                }
                                if (value.value) {
                                    //Fix for QCR-5336
                                    // if (categoryHandle !== handles.research && categoryHandle !== handles.employability &&  value.type === undefined) {
                                    //     score.show = true;
                                    // }
                                    value.type = 'uniIndicator';
                                }
                            }
                            else {
                                if (businessCategoryHandles.indexOf(categoryHandle) === -1 ||
                                    categoryHandle === handles.facilities && indicators.business.facilities.indexOf(key) === -1 ||
                                    categoryHandle === handles.programStrength &&  indicators.business.programStrength.indexOf(key) === -1 ||
                                    categoryHandle === handles.research && indicators.business.research.indexOf(key) === -1 ||
                                    categoryHandle === handles.employability && indicators.business.employability.indexOf(key) === -1) {
                                    return;
                                }
                                if (value.value) {
                                    //Fix for QCR-5336
                                    // if (value.type === undefined) {
                                    //     score.show = true;
                                    // }
                                    value.type = 'businessIndicator';
                                }
                            }
                            var sum = parseInt(value.value);
                            sum = isNaN(sum) ? 0 : sum;
                            total = total + sum;
                        }
                    });
                });
            }

            return total;
        };

        function isMiniCoreVersion() {
            return controller.subscription && controller.subscription.value === 'minicore';
        }

        controller.isReadOnly = function () {
            return !controller.isEditable;
        };

        controller.isOnlyMiniCoreSubscription = function () {
            return  isMiniCoreVersion() && controller.subscriptionList.length === 1;
        };

        controller.isSubmitEnabled = function () {
            if (!controller.selectedItem ||
                !controller.selectedItem.answers ||
                !angular.isDefined(controller.selectedItem.answers[0])
            ) {
                return false;
            }

            return angular.isDefined(controller.selectedItem.modifiedAt) &&
                    controller.isSubmitInstitutionEnabled;
        };

        controller.handleClose = function () {
            controller.selectedItemId = null;
            controller.selectedItem = null;
        };

        controller.buttonsDisabled = function () {
            return controller.submitInProgress || controller.searchInProgress;
        };

        controller.handleSubmitToInstitution = function () {
            if (!controller.isSubmitEnabled() || controller.buttonsDisabled()) {
                return;
            }
            var modalOptions = {
                closeButtonText: 'No',
                actionButtonText: 'Yes',
                actionButtonClass: 'primary',
                headerText: 'Submit to institution',
                bodyText: 'Are you sure you want to submit? Submitted information will be available for client users. Yes, submit / No, cancel'
            };
            ModalService.show({}, modalOptions).then(function () {
                controller.searchInProgress = true;
                StarsInstitutionsListService.submitToInstitution(
                    controller.selectedItem.institutionCoreId, {
                        version: controller.selectedItem.answers[0].version,
                        versionId : controller.selectedItem.version
                    }
                ).then(function (response) {
                    if (response && (response.upsertedId || response.modifiedCount)) {
                        NotifierFactory.show(
                            'success',
                            'Data was submitted to University section successfully',
                            'Stars submission'
                        );
                        StarsDatagridFactory.setReloadGrid(true);
                        controller.isSubmitInstitutionEnabled = false;
                        $timeout(function() {
                            controller.selectedItemId = null;
                            controller.selectedItem = null;
                        });
                    } else {
                        NotifierFactory.show(
                            'error',
                            'Error occurred, please try again later',
                            'Stars submission'
                        );
                    }
                    controller.searchInProgress = false;
                });
            });
        };

        function isFormValid() {
            var isValid = true,
                starsEditForm = angular.element("#starsEditForm")[0];

            for (var i = 0; i < starsEditForm.length; i++) {
                if (starsEditForm[i].tagName === "INPUT" && starsEditForm[i].type === 'number') {
                    if (starsEditForm[i].parentElement.classList.contains("has-errors")) {
                        controller.openSection(starsEditForm[i].closest('.panel-default').getAttribute('heading'));
                        isValid = false;
                   }
                }
            }

            return isValid;
        }

        controller.openSection = function(section) {
            var total = controller.sections.length,
                i = 0;
            for (i; i < total; i++) {
                if (controller.sections[i].name === section) {
                    controller.sections[i].open = true;
                    return;
                }
            }
        };

        controller.handleSaveClick = function () {
            var previousItem = angular.copy(controller.selectedItem);
            if (controller.buttonsDisabled()) {

                return;
            }
            if (!isFormValid() || StarsEditFactory.isInvalidData()) {
                $timeout(function() {
                    if (previousItem.id !== controller.selectedItem.id) {
                        controller.selectedItem = previousItem;
                        controller.selectedItemId = previousItem.id;
                        StarsEditFactory.setSelectedId(previousItem.id);
                    }
                });
                NotifierFactory.show(
                    'error',
                    'Some fields don\'t pass validation. Please correct the fields and try again',
                    'Stars submission'
                );
                UnsavedChangesFactory.setSaveError();

                return;
            }
            setDropdownValues();
            controller.submitInProgress = true;
            var data = null;
            if (controller.selectedItem.answers) {
                data = controller.selectedItem.answers[0] ? controller.selectedItem.answers[0] : {};
                data.totalStarsRating = controller.calculateStarRating();
                data.totalStarsRatingAsString = data.totalStarsRating.toString();
                if (data.overallStarsRating) {
                    data.overallStarsRatingAsString = data.overallStarsRating.toString();
                }
                var selectedSubscriptionVersionId = StarsInstitutionsListService.getSubscriptionVersionId(controller.subscription);
                data.version = controller.subscription.value;
                data.versionId = controller.selectedItem.answers[0] && controller.selectedItem.answers[0].versionId ? controller.selectedItem.answers[0].versionId : selectedSubscriptionVersionId;
            } else {
                 NotifierFactory.show(
                    'error',
                    'Select some data to update',
                    'Stars submission'
                );
                UnsavedChangesFactory.setSaveError();
                controller.submitInProgress = false;

                return false;
            }
            StarsInstitutionsListService.update(
                controller.selectedItem.institutionCoreId,
                data
            ).then(saveCallback);
        };

        controller.getDateUpdated = function() {
            return controller.selectedItem.modifiedAt || controller.selectedItem.createdAt;
        };

        function setHistoryLog(isSaveCallback) {
            controller.historyLog = {};
            if (isSaveCallback) {
                controller.selectedItem.answers[0].modifiedAt = TimeService.nowUnix();
                controller.selectedItem.answers[0].modifiedByFullName = UserFactory.getFullName();
            }
            if (controller.selectedItem.modifiedAt) {
                controller.historyLog.modifiedAt = controller.selectedItem.answers[0].modifiedAt;
                controller.historyLog.modifiedByFullName = controller.selectedItem.answers[0].modifiedByFullName;
                controller.historyLog.isUpdatedByCsv = angular.copy(controller.selectedItem.answers[0].isUpdatedByCsv);
                controller.selectedItem.answers[0].isUpdatedByCsv = false;    
            }
        }

        function saveCallback(response) {
            controller.submitInProgress = false;
            StarsEditFactory.setSelectedItemAnswers(angular.copy(controller.selectedItem.answers));
            UnsavedChangesFactory.resetChanges();
            controller.forms.starsEdit.$setPristine();

            if (response && (response.insertCount || response.modifiedCount)) {
                NotifierFactory.show(
                    'success',
                    'Submission was updated successfully',
                    'Stars submission'
                );
                if (response.certificateUrl) {
                    controller.selectedItem.certificateUrl = response.certificateUrl;
                }
                if (response.developmentRoadmapUrl) {
                    controller.selectedItem.developmentRoadmapUrl = response.developmentRoadmapUrl;
                }

                controller.isSubmitInstitutionEnabled = true;
                UnsavedChangesFactory.setIsSaved();
                StarsDatagridFactory.setReloadGrid(true);
                // Update date update
                controller.selectedItem.modifiedAt = TimeService.nowUnix();
                setHistoryLog(true);
            } else {
                UnsavedChangesFactory.setSaveError();
                NotifierFactory.show(
                    'error',
                    'Error occurred, please try again later',
                    'Stars submission'
                );
            }

            StarsEditFactory.setSelectedItemAnswers(angular.copy(controller.selectedItem.answers));
            $timeout(function() {
                UnsavedChangesFactory.clearIsSaved();
            });
        }

        function selectedItemWatch(selectedItem) {
            if (!selectedItem || !controller.selectedItemId) {
                return;
            }
            if (!firstLoad) {
                controller.historyLog = {};
                initContentPage(true, null, true);
            }
            controller.isSubmitInstitutionEnabled = false;
            controller.isLoading = true;
            StarsEditFactory.setInstitutionId(selectedItem.id);
            controller.sections = selectedItem.institutionType === starsConstants.institutionType.uni ? universitySections : businessSchoolSections;
            fetchSubscriptions();
            fetchStarsList();
            fetchSubscribed(selectedItem.institutionCoreId);
            StarsEditFactory.setValidateRequested(true);
            if (!UnsavedChangesFactory.hasChanges()) {
                controller.forms.starsEdit.$setPristine();
            }
            firstLoad = false;
        }

        function setDropdownValues() {
            if (controller.selectedItem !== null &&
                controller.selectedItem.answers &&
                controller.selectedItem.answers[0]
            ) {
                var listType = controller.selectedItem.institutionType === starsConstants.institutionType.uni ? 'universityList' : 'businessList';
                angular.forEach( controller.selectedItem.answers[0].scores, function(value, key) {
                switch(key) {
                    case 'employability':
                         if (value[dropdowns.employability.careerServiceSupport.name] &&
                             value[dropdowns.employability.careerServiceSupport.name].dropdownValue) {
                                if (!StarsInstitutionsListService.isValueExistInDropdown(value[dropdowns.employability.careerServiceSupport.name].dropdownValue, dropdowns.employability.careerServiceSupport[listType])) {
                                    value[dropdowns.employability.careerServiceSupport.name].dropdownValue = null;
                                }
                        }
                        if (value[dropdowns.employability.employerReputationCampusEmployerPresence.name] &&
                             value[dropdowns.employability.employerReputationCampusEmployerPresence.name].dropdownValue) {
                                if (!StarsInstitutionsListService.isValueExistInDropdown(value[dropdowns.employability.employerReputationCampusEmployerPresence.name].dropdownValue, dropdowns.employability.employerReputationCampusEmployerPresence[listType])) {
                                    value[dropdowns.employability.employerReputationCampusEmployerPresence.name].dropdownValue = null;
                                }
                        }
                         break;
                    case 'facilities':
                        if (value[dropdowns.facilities.itInfrastructure.name] &&
                             value[dropdowns.facilities.itInfrastructure.name].dropdownValue) {
                                if (!StarsInstitutionsListService.isValueExistInDropdown(value[dropdowns.facilities.itInfrastructure.name].dropdownValue, dropdowns.facilities.itInfrastructure[listType])) {
                                    value[dropdowns.facilities.itInfrastructure.name].dropdownValue = null;
                                }

                        }
                        if (value[dropdowns.facilities.libraryFacilities.name] &&
                             value[dropdowns.facilities.libraryFacilities.name].dropdownValue) {
                                if (!StarsInstitutionsListService.isValueExistInDropdown(value[dropdowns.facilities.libraryFacilities.name].dropdownValue, dropdowns.facilities.libraryFacilities[listType])) {
                                    value[dropdowns.facilities.libraryFacilities.name].dropdownValue = null;
                                }
                        }
                        break;
                    case 'program_strength':
                         if (value[dropdowns.programStrength.associatedQsSubjectArea.name] &&
                             value[dropdowns.programStrength.associatedQsSubjectArea.name].dropdownValue) {
                                var isValueExist =  false;
                                if (dropdowns.programStrength.associatedQsSubjectArea[listType] === 'business_subjects') {
                                    isValueExist =StarsInstitutionsListService.isValueExistInDropdown(value[dropdowns.programStrength.associatedQsSubjectArea.name].dropdownValue, StarsEditFactory.getSubjectsBussinesList(),true);

                                }
                                 if (dropdowns.programStrength.associatedQsSubjectArea[listType] === 'broad_subjects') {
                                    isValueExist =StarsInstitutionsListService.isValueExistInDropdown(value[dropdowns.programStrength.associatedQsSubjectArea.name].dropdownValue, StarsEditFactory.getBroadSubjectsList(),true);
                                }
                                if (dropdowns.programStrength.associatedQsSubjectArea[listType] === 'subjects') {
                                    isValueExist =StarsInstitutionsListService.isValueExistInDropdown(value[dropdowns.programStrength.associatedQsSubjectArea.name].dropdownValue, StarsEditFactory.getSubjectsUniList(),true);
                                }
                                if (!isValueExist) {
                                    value[dropdowns.programStrength.associatedQsSubjectArea.name].dropdownValue = null;
                                }
                        }
                        break;
                     }
                 });

            }
        }

        function fetchSubscribed(coreId) {
            InstitutionsListService.getStarsSubscription(coreId).then(function (response) {
                if (response && response.length === 0) {
                    return false;
                }
                controller.isEditable = response.results[0].subscriptions.hasOwnProperty('stars') ? response.results[0].subscriptions.stars.subscribed : false;
            });
        }

        function fetchBroadSubjectsList(count) {
            StarsInstitutionsListService.getAllUniversityBroadSubjects().then(function (list) {
                controller.broadSubjectsList = list;
                StarsEditFactory.setBroadSubjectsList(controller.broadSubjectsList);
                if (count) {
                    controller.totalLoad--;
                }
            });
        }

        function initContentPage(count, versionId, isWatcherCall) {
            if (!controller.selectedItemId) {
                return;
            }
            if (!versionId) {
                versionId = controller.selectedItem &&
                            controller.selectedItem.answers &&
                            controller.selectedItem.answers[0] &&
                            controller.selectedItem.answers[0].versionId &&
                            controller.selectedItem.answers[0].version ?
                                controller.selectedItem.answers[0].versionId :
                                controller.selectedItem.version;
            }
            if (!versionId) {
                if (count) {
                    controller.totalLoad = 0;
                }
                return false;
            }
            if (controller.selectedItem && controller.selectedItem.institutionType === starsConstants.institutionType.uni) {
                StarsInstitutionsListService.getAllUniversitySections(false, versionId).then(function (uniSections) {
                    if (uniSections && uniSections.results) {
                        controller.sections = universitySections = StarsInstitutionsListService.orderUniversitySections(uniSections.results);
                        if (count) {
                            controller.totalLoad--;
                        }
                        sectionHandlesUnis = StarsInstitutionsListService.getSectionHandles(universitySections);
                        if (!isWatcherCall) {
                            WatchService.create($scope, '$ctrl.selectedItem', selectedItemWatch);
                        }
                        getCategoriesUnis(count, versionId);
                        fetchBroadSubjectsList(count);
                        if(controller.selectedItem.answers && controller.selectedItem.answers[0]) {
                            setHistoryLog(false);
                        }
                    }
                });

            }
            else if (controller.selectedItem && controller.selectedItem.institutionType === starsConstants.institutionType.bs) {
                StarsInstitutionsListService.getAllBusinessSchoolSections(false, versionId).then(function (businessSections) {
                    if (businessSections && businessSections.results) {
                        controller.sections = businessSchoolSections = StarsInstitutionsListService.orderBusinessSchoolSections(businessSections.results);
                        if (count) {
                            controller.totalLoad--;
                        }
                        sectionHandlesBusiness = StarsInstitutionsListService.getSectionHandles(businessSchoolSections);
                        if (!isWatcherCall) {
                            WatchService.create($scope, '$ctrl.selectedItem', selectedItemWatch);
                        }
                        getCategoriesBussines(count);
                        fetchBroadSubjectsList(count);
                        if(controller.selectedItem.answers && controller.selectedItem.answers[0]) {
                            setHistoryLog(false);
                        }
                    }
                });

            }
        }

        function isFormDirtyWatch(dirty) {
            UnsavedChangesFactory.setChanges(dirty);
        }

        function loadWatch(loadPending) {
            if (angular.isDefined(loadPending) && loadPending === 0) {
                controller.load();
                controller.isLoading = false;
            }
        }

        function initWatches() {
            WatchService.create($scope, '$ctrl.forms.starsEdit.$dirty', isFormDirtyWatch);
            controller.load = WatchService.create($scope, '$ctrl.totalLoad', loadWatch);
        }

        controller.$onInit = function () {
            initWatches();
            initContentPage(true, null, false);
            UnsavedChangesFactory.setSaveFunction(controller.handleSaveClick, $scope);
        };
    }

}(window.angular));

(function (angular) {
    'use strict';

    angular.module('qsHub.stars').component('starsEdit', {
        templateUrl: '/scripts/modules/stars/components/starsEdit/starsEditView.html',
        bindToController: true,
        controller: 'stars.editController',
        bindings: {
            selectedItemId: '=',
            selectedItem: '='
        }
    });

}(window.angular));

(function(angular) {
    "use strict";

    angular
        .module('qsHub.stars')
        .factory('starsEditFactory', [
            StarsEditFactory
        ]);

    function StarsEditFactory () {
        var institutionId = null,
            invalidData = false,
            invalidFields = [],
            subjectsUniList = false,
            subjectsBussinesList = false,
            broadSubjectsList = false,
            selectedId,
            subscriptionChange = false,
            validateRequested = false,
            SelectedItemAnswers = null,
            handles = {
                facilities : 'facilities',
                research : 'research',
                employability : 'employability',
                programStrength : 'program_strength'
            },
            indicators = {
                business : {
                    facilities : [],
                    programStrength : [],
                    research : [],
                    employability : []

                },
                university : {
                    facilities : [],
                    programStrength : [],
                    research : [],
                    employability : []

                }
            };

        return {
            getInstitutionId: function () {
                return institutionId;
            },
            setInstitutionId: function (newId) {
                institutionId = newId;
            },
            isInvalidData: function () {
                return invalidData;
            },
            setInvalidData: function (newValue) {
                invalidData = newValue;
            },
            getInvalidFields: function () {
                return invalidFields;
            },
            setInvalidFields: function (newValue) {
                invalidFields = newValue;
            },
            addInvalidField: function (field) {
                invalidFields.push(field);
            },
            setSubjectsUniList: function (newValue) {
                subjectsUniList = newValue;
            },
            getSubjectsUniList: function () {
                return subjectsUniList;
            },
            setSubjectsBussinesList: function (newValue) {
                subjectsBussinesList = newValue;
            },
            getSubjectsBussinesList: function () {
                return subjectsBussinesList;
            },
            setBroadSubjectsList: function (newValue) {
                broadSubjectsList = newValue;
            },
            getBroadSubjectsList: function () {
                return broadSubjectsList;
            },
            getSelectedId: function() {
                return selectedId;
            },
            setSelectedId: function(id) {
                selectedId = id;
            },
            isSubscriptionChange: function () {
                return subscriptionChange;
            },
            setSubscriptionChange: function (newValue) {
                subscriptionChange = newValue;
            },
            isValidateRequested: function () {
                return validateRequested;
            },
            setValidateRequested: function (newValue) {
                validateRequested = newValue;
            },
            getHandles: function () {
                return handles;
            },
            getIndicators: function () {
                return indicators;
            },
            setSelectedItemAnswers: function (item) {
                SelectedItemAnswers =  item;
            },
            getSelectedItemAnswers: function () {
                return SelectedItemAnswers;
            }
        };
    }

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.stars')
        .controller('stars.starsPerCategory', [
            '$scope',
            '$state',
            'WatchService',
            'StarsInstitutionsListService',
            'stars.constants',
            Controller
        ]);

    function Controller(
        $scope,
        $state,
        WatchService,
        StarsInstitutionsListService,
        starsConstants
    ) {
        var controller = this;

        controller.starsRatingList = [];
        controller.ratingHandles = [
            'subject_rankings',
            'program_strength'
        ];
        controller.subjectRankingsHandles = [
            'broad_subject_area_ranking',
            'subject_ranking'
        ];
        controller.programStrengthHandles = [
            'associated_qs_subject_area'
        ];

        function initRatingDisabled() {
            controller.selectedItem.isRatingDisabled = [];
            controller.selectedItem.isSubjectDisabled = [];
            var i = 0,
                total = controller.ratingHandles.length;
            for (i; i < total; i++) {
                controller.selectedItem.isRatingDisabled[controller.ratingHandles[i]] = false;
            }
        }

        function disableRating(categoryHandle) {
            controller.selectedItem.isSubjectDisabled = [];
            var i = 0,
                total;
            if (categoryHandle === 'subject_rankings') {
                total = controller.programStrengthHandles.length;
                for (i; i < total; i++) {
                    controller.selectedItem.isSubjectDisabled[controller.programStrengthHandles[i]] = true;
                }
            } else if (categoryHandle === 'program_strength') {
                total = controller.subjectRankingsHandles.length;
                for (i; i < total; i++) {
                    controller.selectedItem.isSubjectDisabled[controller.subjectRankingsHandles[i]] = true;
                }
            }
        }

        function disabledRatings(ratingHandles, categoryHandle) {
            var i = 0,
                total = ratingHandles.length;
            for (i; i < total; i++) {
                if (ratingHandles[i] !== categoryHandle) {
                    controller.selectedItem.isRatingDisabled[ratingHandles[i]] = true;
                }
            }
            disableRating(categoryHandle);
        }

        controller.ratingState = function(category) {
            if (controller.ratingHandles.indexOf(category) === -1 ||
                controller.selectedItem.institutionType !== starsConstants.institutionType.uni
            ) {
                return;
            }
            initRatingDisabled();
            if (controller.selectedItem.answers[0].starsPerCategory &&
                (controller.selectedItem.answers[0].starsPerCategory[category] ||
                controller.selectedItem.answers[0].starsPerCategory[category] === 0) &&
                controller.selectedItem.answers[0].starsPerCategory[category] !== undefined
            ) {
                disabledRatings(controller.ratingHandles, category);
            }
            if (controller.selectedItem.answers[0].scores.subject_rankings) {
                if (controller.selectedItem.answers[0].scores.subject_rankings.subject_ranking &&
                    controller.selectedItem.answers[0].scores.subject_rankings.subject_ranking.dropdownValue
                ) {
                    controller.selectedItem.isSubjectDisabled.broad_subject_area_ranking = true;
                }
                if (controller.selectedItem.answers[0].scores.subject_rankings.broad_subject_area_ranking &&
                    controller.selectedItem.answers[0].scores.subject_rankings.broad_subject_area_ranking.dropdownValue
                ) {
                    controller.selectedItem.isSubjectDisabled.subject_ranking = true;
                }
            }
        };

        function initDisabledRating() {
            controller.selectedItem.isRatingDisabled = [];
            var category = '';
            if (controller.selectedItem &&
                controller.selectedItem.answers &&
                controller.selectedItem.answers[0] &&
                controller.selectedItem.answers[0].starsPerCategory
            ) {
                if (angular.isDefined(controller.selectedItem.answers[0].starsPerCategory.subject_rankings)) {
                    category = 'subject_rankings';
                }
                if (angular.isDefined(controller.selectedItem.answers[0].starsPerCategory.program_strength)) {
                    category = 'program_strength';
                }
            }
            if (category !== '') {
                disabledRatings(controller.ratingHandles, category);
                if (controller.selectedItem.institutionType === starsConstants.institutionType.uni) {
                    if (controller.selectedItem.answers &&
                        controller.selectedItem.answers[0] &&
                        controller.selectedItem.answers[0].scores &&
                        controller.selectedItem.answers[0].scores.subject_rankings &&
                        controller.selectedItem.answers[0].scores.subject_rankings.subject_ranking &&
                        controller.selectedItem.answers[0].scores.subject_rankings.subject_ranking.dropdownValue
                    ) {
                        controller.selectedItem.isSubjectDisabled.broad_subject_area_ranking = true;
                    }
                    if (
                        controller.selectedItem.answers &&
                        controller.selectedItem.answers[0] &&
                        controller.selectedItem.answers[0].scores &&
                        controller.selectedItem.answers[0].scores.subject_rankings &&
                        controller.selectedItem.answers[0].scores.subject_rankings.broad_subject_area_ranking &&
                        controller.selectedItem.answers[0].scores.subject_rankings.broad_subject_area_ranking.dropdownValue
                    ) {
                        controller.selectedItem.isSubjectDisabled.subject_ranking = true;
                    }
                }
            }
        }

        controller.isEmptyValue = function(value) {
            return isNaN(parseInt(value));
        };

        controller.isClientSection = function () {
            return $state.current.controllerAs === 'ClientStarsController';
        };

        function getStarsList() {
            StarsInstitutionsListService.getStarsList().then(function (list) {
                controller.starsRatingList = list;
                if (controller.selectedItem.institutionType === starsConstants.institutionType.uni) {
                    var i = 0,
                        total = list.length;
                    for (i; i < total; i++) {
                        if (list[i].value === 6) {
                            controller.starsRatingList.splice(i, 1);
                            break;
                        }
                    }
                }
            });
        }

        function versionChangeWatch(newVersion, oldVersion) {
            if (newVersion !== oldVersion) {
                initDisabledRating();
            }
        }

        function initWatches() {
            WatchService.create($scope, '$ctrl.selectedItem.answers[0].version', versionChangeWatch);
        }

        controller.$onInit = function () {
            if(controller.category === 'online_distance_learning' &&  controller.isClientSection()) {
                var tmpCategory = controller.selectedItem.answers[0].starsPerCategory[
                    controller.category === 'online_distance_learning' ? 'online' : controller.category
                ];
                controller.onlineCategory = tmpCategory ? 'online' : 'online_distance_learning';
            }
            getStarsList();
            initWatches();
            initDisabledRating();
        };
    }

}(window.angular));

(function (angular) {

  'use strict';

  angular.module('qsHub.stars').component('starsPerCategory', {
        templateUrl: '/scripts/modules/stars/components/starsEdit/components/starsPerCategory/starsPerCategoryView.html',
        bindToController: true,
        controller: 'stars.starsPerCategory',
        bindings: {
            selectedItem: '<',
            category: '=',
            isReadOnly: '<'
        }
  });

}(window.angular));




(function (angular, JSON) {
    "use strict";

    angular
        .module('qsHub.stars')
        .controller('stars.scoresPerCategory', [
            '$scope',
            'WatchService',
            'starsEditFactory',
            '$timeout',
            '$state',
            'stars.constants',
            Controller
        ]);

    function Controller(
        $scope,
        WatchService,
        starsEditFactory,
        $timeout,
        $state,
        starsConstants
    ) {
        var controller = this;
        controller.validField = [];
        controller.checkBoxDisabled = [];
        controller.invalidNumberHandles = [];
        controller.isFirstload = true;
        controller.subjectsLists = {
            subjects : [],
            broad_subjects : []
        };
        controller.subjectHandles = [
            'broad_subject_area_ranking',
            'subject_ranking',
            'associated_qs_subject_area'
        ];
        controller.childErrorMessages = [];
        controller.isDependantChildren = [];

        controller.isEmptyValue = function(value) {
            return isNaN(parseInt(value));
        };

        controller.isEmptyFreeText = function(value) {
            return value === "" || !value;
        };

        controller.calculateFixTotal = function() {
            var total = 0,
                i = 0,
                length = controller.indicators.length,
                alreadyCalculated = false;
            for (i; i < length; i++) {
                if (controller.indicators[i].primarySelect ||
                    controller.indicators[i].primaryInput
                ) {
                    continue;
                }
                if (controller.indicators[i].name === 'total') {
                    alreadyCalculated = true;
                }
                total = total + controller.indicators[i].maxPoints;
            }
            if (alreadyCalculated) {
                return;
            }
            controller.indicators.push({
                name : 'total',
                points : total
            });
        };

        function initSubjectDisabled() {
            controller.selectedItem.isSubjectDisabled = [];
            var i = 0,
                total = controller.subjectHandles.length;
            for (i; i < total; i++) {
                controller.selectedItem.isSubjectDisabled[controller.subjectHandles[i]] = false;
            }
        }

        function disabledSubjects(categoryHandles, indicatorHandle) {
            var i = 0,
                total = categoryHandles.length;
            for (i; i < total; i++) {
                if (categoryHandles[i] !== indicatorHandle) {
                    controller.selectedItem.isSubjectDisabled[categoryHandles[i]] = true;
                    if (controller.selectedItem.answers[0].starsPerCategory &&
                        (controller.selectedItem.answers[0].starsPerCategory.program_strength ||
                        controller.selectedItem.answers[0].starsPerCategory.subject_rankings)
                    ) {
                        if (controller.selectedItem.answers[0].starsPerCategory.subject_rankings) {
                            controller.selectedItem.isSubjectDisabled.associated_qs_subject_area = true;
                        } else if (controller.selectedItem.answers[0].starsPerCategory.program_strength) {
                            controller.selectedItem.isSubjectDisabled.broad_subject_area_ranking = true;
                            controller.selectedItem.isSubjectDisabled.subject_ranking = true;
                            controller.selectedItem.isSubjectDisabled.associated_qs_subject_area = false;
                        }
                    }
                }
            }
        }

        controller.subjectsState = function(category, indicator) {
            initSubjectDisabled();
            if (!controller.selectedItem.answers[0].scores) {
                return;
            }
            if (controller.selectedItem.answers[0].scores[category] &&
                controller.selectedItem.answers[0].scores[category][indicator] &&
                controller.selectedItem.answers[0].scores[category][indicator].dropdownValue !== undefined
            ) {
                disabledSubjects(controller.subjectHandles, indicator);
            }
            if (controller.selectedItem.institutionType === starsConstants.institutionType.uni &&
                controller.selectedItem.answers[0].scores.subject_rankings
            ) {
                if (controller.selectedItem.answers[0].scores.subject_rankings.subject_ranking &&
                    controller.selectedItem.answers[0].scores.subject_rankings.subject_ranking.dropdownValue
                ) {
                    controller.selectedItem.isSubjectDisabled.broad_subject_area_ranking = true;
                }
                if (controller.selectedItem.answers[0].scores.subject_rankings.broad_subject_area_ranking &&
                    controller.selectedItem.answers[0].scores.subject_rankings.broad_subject_area_ranking.dropdownValue
                ) {
                    controller.selectedItem.isSubjectDisabled.subject_ranking = true;
                }
            }
        };

        function initUniDisabledSubjects() {
            var indicator = '';
            if (angular.isDefined(controller.selectedItem.answers[0].scores.subject_rankings)) {
                if (controller.selectedItem.answers[0].scores.subject_rankings.subject_ranking &&
                    controller.selectedItem.answers[0].scores.subject_rankings.subject_ranking.dropdownValue
                ) {
                    indicator = 'subject_ranking';
                }
                if (controller.selectedItem.answers[0].scores.subject_rankings.broad_subject_area_ranking &&
                    controller.selectedItem.answers[0].scores.subject_rankings.broad_subject_area_ranking.dropdownValue
                ) {
                    indicator = 'broad_subject_area_ranking';
                }
            }

            return indicator;
        }

        function initDisabledExclude() {
            /**
             * @todo make it dynamic
             */
            if (controller.selectedItem &&
                controller.selectedItem.answers &&
                controller.selectedItem.answers[0] &&
                controller.selectedItem.answers[0].scores &&
                controller.selectedItem.answers[0].scores.internationalization &&
                controller.selectedItem.answers[0].scores.internationalization.international_exchange_programs
            ) {
                var parent = controller.selectedItem.answers[0].scores.internationalization.international_exchange_programs,
                    handle = [];
                if (angular.isDefined(parent.value) && parent.value !== null) {
                    if ((angular.isDefined(parent.children.inbound_exchange_students) &&
                        parent.children.inbound_exchange_students !== null) ||
                        (angular.isDefined(parent.children.outbound_exchange_students) &&
                            parent.children.outbound_exchange_students !== null)
                    ) {
                        handle.push('short_term_outbound_exchange_programs');
                        $timeout (function() {
                            controller.isDependantChildren[handle[0]] = true;
                        });
                    }

                    if (angular.isDefined(parent.children.short_term_outbound_exchange_programs) &&
                        parent.children.short_term_outbound_exchange_programs !== null
                    ) {
                        handle.push('inbound_exchange_students');
                        handle.push('outbound_exchange_students');
                        $timeout (function() {
                            controller.isDependantChildren[handle[0]] = true;
                            controller.isDependantChildren[handle[1]] = true;
                        });
                    }
                }
            }
        }

        function initDisabledSubjects() {
            if (!controller.isFirstload) {
                return;
            }
            var indicator = '';
            if (controller.selectedItem &&
                controller.selectedItem.answers &&
                controller.selectedItem.answers[0] &&
                controller.selectedItem.answers[0].scores) {
                indicator = initUniDisabledSubjects();
            }
            if (indicator !== '') {
                controller.selectedItem.isSubjectDisabled = [];
                disabledSubjects(controller.subjectHandles, indicator);
            }
        }

        function setInvalidData(valid) {
            starsEditFactory.setInvalidData(!valid);
        }

        function removeInvalidField(handle, type) {
            var invalidFields = starsEditFactory.getInvalidFields(),
                i = 0,
                total = invalidFields.length;
            for (i; i < total; i++) {
                if (invalidFields[i].handle === handle && invalidFields[i].type === type) {
                    invalidFields.splice(i, 1);
                    starsEditFactory.setInvalidFields(invalidFields);
                    break;
                }
            }
        }

        controller.validateCategory = function (handle, category, maxPoints, notDependant, event) {
            if (event && !category) {
                return;
            }
            var categoryType = category ? category.type : null;
            removeInvalidField(handle, categoryType);
            
            if (!controller.isValidData(handle, category ? category : true, maxPoints, notDependant, event)) {
                starsEditFactory.addInvalidField({
                    handle : handle,
                    value: typeof category !== 'undefined' && typeof category.value !== 'undefined' ? category.value : false,
                    type: typeof category !== 'undefined' && typeof category.type !== 'undefined' ? category.type : false,
                });
            }
            if (starsEditFactory.getInvalidFields().length < 1) {
                starsEditFactory.setInvalidData(false);
            }
        };

        controller.validateCategoryChildren = function (parent, category, childHandle, parentMaxPoints, event) {
            if (event && (!category || (category && !category.children))) {
                return;
            }
            var parentHandle = parent.handle,
            children = parent.children;
            removeInvalidField(childHandle);
            if (!controller.isValidDataChildren(parentHandle, children, category, childHandle, parentMaxPoints, parent.checkBoxPoints ? parent.checkBoxPoints : false)) {
                starsEditFactory.addInvalidField({
                    handle: childHandle,
                    value: category.children[childHandle],
                    isChildren: true
                });
            }
            if (starsEditFactory.getInvalidFields().length < 1) {
                starsEditFactory.setInvalidData(false);
            }
        };

        controller.initDisabledCheckboxes = function (parent, checkboxList, category) {
            if (angular.isDefined(category)) {
                var total = category.value ? category.value : 0;
                if (total === parent.maxPoints) {
                    // Disable not assigned checkboxes
                    angular.forEach(checkboxList, function(checkbox) {
                        if (angular.isDefined(category.checkboxList)) {
                            if (!angular.isDefined(category.checkboxList[checkbox.handle]) ||
                                (angular.isDefined(category.checkboxList[checkbox.handle]) &&
                                !category.checkboxList[checkbox.handle].assigned)
                            ) {
                                controller.checkBoxDisabled[checkbox.handle] = true;
                            }
                        }
                    });
                }
            }
        };

        controller.getCheckBoxPoints = function (parent) {
            return parseInt(controller.selectedItem.institutionTypeId, 10) === 3 &&
                angular.isDefined(parent.clientDepartmentCheckbox) ?
                parent.clientDepartmentCheckbox.checkBoxPoints :
                parent.checkBoxPoints;
        };

        controller.validateCategoryCheckboxes = function (parent, checkboxList, category, childHandle) {
            var bonus = angular.isDefined(parent.bonus) ? parent.bonus : false,
                maxCheckBonus = bonus ? parent.maxCheckboxBonus : false,
                maxPoints = parent.maxPoints,
                pointsPerCheckbox = parent.checkBoxPoints,
                numAssigned = 0,
                totalCheckboxes = 0,
                total = category.value ? category.value : 0;

            if (parseInt(controller.selectedItem.institutionTypeId, 10) === 3 &&
                angular.isDefined(parent.clientDepartmentCheckbox)
            ) {
                bonus = parent.clientDepartmentCheckbox.bonus;
                pointsPerCheckbox = parent.clientDepartmentCheckbox.checkBoxPoints;
                maxCheckBonus = parent.clientDepartmentCheckbox.maxCheckboxBonus;
            }

            angular.forEach(category.checkboxList, function(checkbox) {
                if (checkbox.assigned) {
                    numAssigned++;
                }
            });

            if (numAssigned === 1 && category.checkboxList[childHandle].assigned) {
                total = !category.children ? 0 : total;
                controller.validField[parent.handle] = true;
            }
            if (parent.handle === "career_service_support") {
                setParentValue(parent.children, controller.selectedItem.answers[0].scores[controller.category][parent.handle], parent.checkBoxPoints, parent);
            }
            else {
                var isBonusApplied = false;
                if (category.checkboxList[childHandle].assigned) {
                    total = total + pointsPerCheckbox;
                    isBonusApplied = (numAssigned * pointsPerCheckbox) < total;
                } else {
                    isBonusApplied = (numAssigned * pointsPerCheckbox) < total;
                    total = total - pointsPerCheckbox;
                }
    
                if (bonus && maxCheckBonus) {
                    if (numAssigned === maxCheckBonus && !isBonusApplied) {
                        total = total + bonus;
                    } else if (numAssigned + 1 === maxCheckBonus && isBonusApplied) {
                        total = total - bonus;
                    }
                }
    
                if (total === maxPoints) {
                    // Disable not assigned checkboxes
                    angular.forEach(checkboxList, function(checkbox) {
                        if (!angular.isDefined(category.checkboxList[checkbox.handle]) ||
                            (angular.isDefined(category.checkboxList[checkbox.handle]) &&
                            !category.checkboxList[checkbox.handle].assigned)
                        ) {
                            controller.checkBoxDisabled[checkbox.handle] = true;
                        }
                    });
                } else {
                    // Enable not assigned checkboxes
                    angular.forEach(checkboxList, function(checkbox) {
                        controller.checkBoxDisabled[checkbox.handle] = false;
                    });
                }
    
                /**
                 * New logic introduce in v5
                 */
                if (angular.isDefined(parent.removeBonus)) {
                    var isLastCheckbox = false;
                    if (total > maxPoints) {
                        total = maxPoints;
                        isLastCheckbox = true;
                    }
                    if (!isLastCheckbox) {
                        angular.forEach(checkboxList, function () {
                            totalCheckboxes++;
                        });
                        if (numAssigned === totalCheckboxes && bonus) {
                            total = total + bonus;
                        } else if (
                            numAssigned === totalCheckboxes - 1 &&
                            bonus &&
                            total !== (maxPoints - pointsPerCheckbox) + bonus
                        ) {
                            total = total + bonus;
                        }
                    }
                }
                category.value = total > 0 ? total : null;
            }
        };

        controller.isCheckboxDisabled = function (parent, checkboxList, category, childHandle) {
            if (controller.isFirstload) {
                controller.initDisabledCheckboxes(parent, checkboxList, category, childHandle);
                controller.isFirstload = false;
            }
            return angular.isDefined(controller.checkBoxDisabled[childHandle]) ?
                    controller.checkBoxDisabled[childHandle] :
                    false;
        };

        controller.calculateCurrentTotal = function(answers) {
            var total = 0;
            angular.forEach(answers, function(value, key) {
                if (key !== "specialist_program" && key !== "name_specialist_program" && key !== 'show') {
                    var indicatorType = controller.selectedItem.institutionType === starsConstants.institutionType.uni? 'uniIndicator' : 'businessIndicator';
                    if(!value.type) {
                        value.type = indicatorType;
                    }
                    if (value.type === indicatorType) {
                        var sum = parseInt(value.value);
                        sum = isNaN(sum) ? 0 : sum;
                        total = total + sum;
                    }
                }
            });

            return total;
        };

        controller.isFieldValid = function(handle) {
            return angular.isDefined(controller.validField[handle]) ?
                    controller.validField[handle] : true;
        };

        controller.validateNumber = function(handle, value) {
            if(!value) {
                return;
            }
            if (!controller.isNumberValid(handle)) {
                controller.invalidNumberHandles.splice(controller.invalidNumberHandles.indexOf(handle), 1);
            }
            if (value !== null && !/^[0-9]\d*$/.test(value)) {
                controller.invalidNumberHandles.push(handle);
            }
        };

        controller.isNumberValid = function(handle) {
            return controller.invalidNumberHandles.indexOf(handle) === -1;
        };

        controller.isValidData = function(handle, category, maxPoints, notDependant, event) {
            var val = !angular.isDefined(category) || (event && event.keyCode === 8 && !category.value) ?
                        false :
                        category.value;

            // check if it is empty value
            if ( val === null || val === false) {
                controller.validField[handle] = true;
                setInvalidData(true);
                return true;
            }

            var valid = true,
                isInt = Number.isInteger(val);

            if (isInt) {
                val = parseInt(val);
                valid = !isNaN(val);
                valid = valid ? !(val > parseInt(maxPoints) || val < 0) : valid;
            } else {
                valid = false;
            }

            setInvalidData(valid);
            controller.validField[handle] = valid;

            if (notDependant) {
                // get children and reset values
                var scores = controller.selectedItem.answers[0].scores;
                angular.forEach(scores, function(value, key) {
                    if (angular.isDefined(value[handle]) &&
                        angular.isDefined(value[handle].children)
                    ) {
                        angular.forEach(value[handle].children, function(childValue, childKey) {
                            scores[key][handle].children[childKey] = null;
                            controller.childErrorMessages[handle+childKey] = '';
                            controller.validField[childKey] = true;
                        });
                    }
                });
            }

            return valid;
        };

        function setParentValue(children, parent, checkBoxPoints, parentModel) {
            var total = 0,
                val = 0,
                i = 0,
                numAssigned= 0,
                length = children.length,
                totalCheckboxes =0,
                maxCheckboxBonus =0,
                bonus =0,
                maxPoints =0,
                isCareerAdvisors = false;

            if (parent && parent.children) {
                isCareerAdvisors = 'career_advisors' in  parent.children;
            }
            if (parentModel || isCareerAdvisors) {
                totalCheckboxes = parentModel ? parentModel.checkboxList.length : 4;
                maxCheckboxBonus = parentModel ? parentModel.maxCheckboxBonus : 3;
                bonus = parentModel ? parentModel.bonus : 2;
                maxPoints = parentModel ? parentModel.maxPoints : 50;
            }
            if (checkBoxPoints && parent.checkboxList && (isCareerAdvisors || parentModel)) {
                angular.forEach(parent.checkboxList, function(value) {
                    if (value.assigned) {
                        numAssigned++;
                        total = total + checkBoxPoints;
                    }
                });
            }
            for (i; i < length; i++) {
                val = parent && parent.children ? parseInt(parent.children[children[i].handle]) : 0;
                val = isNaN(val) ? 0 : val;
                total = total + val;
            }
            if (val) {
                numAssigned++;
            }
            if (numAssigned >= maxCheckboxBonus && (parentModel || isCareerAdvisors)) {
                total = total + bonus;
            }
            if (isCareerAdvisors && val <= children[0].maxPoints && total > maxPoints) {
                total = total-bonus;
            }
            if (parentModel) {
                if (numAssigned >= maxCheckboxBonus && numAssigned === totalCheckboxes && total > parentModel.maxPoints) {
                    total = total - bonus;
                }
                controller.validField[parentModel.handle] = total <= parentModel.maxPoints;
            }
            parent.value = total > 0 ? total : null;
        }

        function disabledDependantChildren(child, disable) {
            var j = 0,
                lengthExclude = child.excludes.length;
            for (j; j < lengthExclude; j++) {
                controller.isDependantChildren[child.excludes[j]] = disable;
            }
        }

        function enableDependantChildren(child, parentChildren) {
            var j = 0,
                lengthExclude = child.includes.length;
            for (j; j < lengthExclude; j++) {
                if (parentChildren[child.includes[j]] !== null) {
                    return false;
                }
            }

            return true;
        }

        controller.isValidDataChildren = function (parentHandle, children, parent, currentChildHandle, parentMaxPoints, checkBoxPoints) {
            var val = !angular.isDefined(parent.children) ||
                      !angular.isDefined(parent.children[currentChildHandle]) ?
                        false :
                        parent.children[currentChildHandle];

            // check if it is empty value
            if (!val || val === null) {
                setParentValue(children, parent, checkBoxPoints);
                controller.validField[currentChildHandle] = true;
                controller.isValidData(parentHandle, parent, parentMaxPoints);
                var i = 0,
                    length = children.length;
                for (i; i < length; i++) {
                    if (children[i].handle === currentChildHandle) {
                        if (angular.isDefined(children[i].excludes)) {
                            if (angular.isDefined(children[i].includes)) {
                                disabledDependantChildren(children[i], !enableDependantChildren(children[i], parent.children));
                            } else {
                                disabledDependantChildren(children[i], false);
                            }
                        }
                    }
                }
                return true;
            }

            var currentTotalMaxPoints = 0,
                restrictedValues = false,
                j = 0,
                lengthChildren = children.length;
            for (j; j < lengthChildren; j++) {
                if (children[j].handle === currentChildHandle) {
                    currentTotalMaxPoints = children[j].maxPoints;
                    if (angular.isDefined(children[j].allowValues)) {
                        restrictedValues = children[j].allowValues;
                    }
                    /**
                     * New logic introduce in v5 to exclude input between children
                     */
                    if (angular.isDefined(children[j].excludes)) {
                        disabledDependantChildren(children[j],true);
                    }
                }
            }

            var valid = true,
                isInt = Number.isInteger(val);

            if (isInt) {
                val = parseInt(val);
                valid = !isNaN(val);
                setParentValue(children, parent, checkBoxPoints);
                valid = valid ? !(val > parseInt(currentTotalMaxPoints) || val < 0) : valid;
                controller.childErrorMessages[parentHandle+currentChildHandle] = '';
                if (!valid) {
                    controller.childErrorMessages[parentHandle+currentChildHandle] = "Value can't be decimal or larger than " + currentTotalMaxPoints;
                }
                if (valid && restrictedValues && restrictedValues.indexOf(val) === -1) {
                    valid = false;
                    controller.childErrorMessages[parentHandle+currentChildHandle] = 'Value must be ' + restrictedValues.join(", ");
                }
                controller.isValidData(parentHandle, parent, parentMaxPoints);
            } else {
                valid = false;
            }

            setInvalidData(valid);
            controller.validField[currentChildHandle] = valid;

            return valid;
        };

        function recalculateCategoryMaxPoints() {
            controller.indicators[controller.indicators.length - 1].points = 0;
            angular.forEach(controller.indicators, function (indicator) {
                if (indicator.maxPoints) {
                    var maxPoints =  indicator.handle === "employer_reputation_campus_employer_presence" ? 50 : (indicator.handle === "health_support_services" ? 10 : indicator.maxPoints);
                    controller.indicators[controller.indicators.length - 1].points =
                        controller.indicators[controller.indicators.length - 1].points  +
                        maxPoints;
                }
            });
        }

        controller.isClientSection = function () {
            return $state.current.controllerAs === 'ClientStarsController';
        };

        controller.handleSelectOptionsChange = function(category, indicator, subIndicator) {
            if (!indicator.hasSelectDependency) {
                return;
            }
            if (controller.selectedItem.answers[0].scores[category][indicator.handle].value) {
                if (controller.selectedItem.answers[0].scores[category][indicator.handle].value > subIndicator.maxPoints) {
                    controller.validField[indicator.handle] = false;
                } else {
                    controller.validField[indicator.handle] = true;
                }
                starsEditFactory.setInvalidData(!controller.validField[indicator.handle]);
            }
            indicator.maxPoints = subIndicator.maxPoints;
            recalculateCategoryMaxPoints();
        };

        function initSelectOptionsChanges() {
            if (!controller.selectedItem.answers) {
                return;
            }
            var i, total;
            angular.forEach(controller.indicators, function (indicator) {
                if (indicator.hasSelectDependency && indicator.selectOptions) {
                    i = 0;
                    total = indicator.selectOptions.length;
                    for (i; i < total; i++) {
                        if (controller.selectedItem &&
                            controller.selectedItem.answers &&
                            controller.selectedItem.answers[0] &&
                            controller.selectedItem.answers[0].scores &&
                            controller.selectedItem.answers[0].scores[controller.category] &&
                            controller.selectedItem.answers[0].scores[controller.category][indicator.handle] &&
                            controller.selectedItem.answers[0].scores[controller.category][indicator.handle].dropdownValue &&
                            indicator.selectOptions[i].handle === controller.selectedItem.answers[0].scores[controller.category][indicator.handle].dropdownValue
                        ) {
                            indicator.maxPoints = indicator.selectOptions[i].maxPoints;
                            recalculateCategoryMaxPoints();
                        }
                    }
                }
            });
        }

        function getSubjectsListCallback(subjectList) {
            if (subjectList) {
                controller.subjectsUnisList = subjectList;
                controller.subjectsLists.subjects = subjectList;
                controller.getSubjectsListWatch();
            }
        }

        function getBroadSubjectsListCallback(subjectList) {
            if (subjectList) {
                controller.subjectsBroadList = subjectList;
                controller.subjectsLists.broad_subjects = subjectList;
                controller.getBroadSubjectsListWatch();
            }
        }

        function getBusinessSubjectsListCallback(subjectList) {
            if (subjectList) {
                controller.subjectsBusinessList = subjectList;
                controller.subjectsLists.business_subjects = subjectList;
                controller.getBusinessSubjectsListWatch();
            }
        }

        function clearForm() {
            controller.isFirstload = true;
            controller.validField = [];
            controller.checkBoxDisabled = [];
            controller.childErrorMessages = [];
            controller.isDependantChildren = [];
        }

        function versionChangeWatch(newVersion, oldVersion) {
            if (newVersion !== oldVersion) {
                clearForm();
                starsEditFactory.setSubscriptionChange(true);
                initDisabledSubjects();
            }
        }

        function reValidate() {
            angular.forEach(controller.indicators, function (indicator) {
                if (typeof indicator.maxPoints !== 'undefined' && 
                    (controller.selectedItem && controller.selectedItem.answers && controller.selectedItem.answers[0] &&
                    controller.selectedItem.answers[0].scores && typeof controller.selectedItem.answers[0].scores[controller.category] !== 'undefined') &&
                    controller.selectedItem.answers[0].scores[controller.category][indicator.handle]) {
                        if (('value' in controller.selectedItem.answers[0].scores[controller.category][indicator.handle])) {
                            var value = controller.selectedItem.answers[0].scores[controller.category][indicator.handle].value;
                            controller.selectedItem.answers[0].scores[controller.category][indicator.handle].value = !angular.isDefined(value) ? null : value;
                            controller.validateCategory(indicator.handle, controller.selectedItem.answers[0].scores[controller.category][indicator.handle], indicator.maxPoints);
                        }
                }
            });
        }

        function validateRequested(value) {
            if (value) {
                $timeout(function () {
                    reValidate();
                    starsEditFactory.setValidateRequested(false);
                });
            }
        }

        function initWatches() {
            WatchService.create($scope, '$ctrl.selectedItem.answers[0].version', versionChangeWatch);
            WatchService.create($scope, starsEditFactory.isValidateRequested, validateRequested);
            if (controller.selectedItem.institutionType === starsConstants.institutionType.uni) {
                controller.getSubjectsListWatch = WatchService.create($scope, starsEditFactory.getSubjectsUniList, getSubjectsListCallback);
                controller.getBroadSubjectsListWatch = WatchService.create($scope, starsEditFactory.getBroadSubjectsList, getBroadSubjectsListCallback);
            } else {
                controller.getBusinessSubjectsListWatch = WatchService.create($scope, starsEditFactory.getSubjectsBussinesList, getBusinessSubjectsListCallback);
            }
        }

        controller.$onInit = function () {
            controller.calculateFixTotal();
            initWatches();
            initDisabledSubjects();
            initSelectOptionsChanges();
            initDisabledExclude();
            if (controller.selectedItem &&
                controller.selectedItem.answers &&
                controller.selectedItem.answers[0] &&
                controller.selectedItem.answers[0].starsPerCategory &&
                angular.isArray(controller.selectedItem.answers[0].starsPerCategory)
            ) {
                controller.selectedItem.answers[0].starsPerCategory = {};
            }
        };

        controller.$onChanges = function (changes) {
            if (!changes.selectedItem) {
                clearForm();
                return;
            }
            if (changes.selectedItem.previousValue.answers === null ||
                changes.selectedItem.currentValue.answers === null
            ) {
                clearForm();
                return;
            }
            if (JSON.stringify(changes.selectedItem.previousValue.answers[0]) !==
                JSON.stringify(changes.selectedItem.currentValue.answers[0])
            ) {
                clearForm();
            }
        };
    }

}(window.angular, window.JSON));

(function (angular) {

  'use strict';

  angular.module('qsHub.stars').component('scoresPerCategory', {
        templateUrl: '/scripts/modules/stars/components/starsEdit/components/scoresPerCategory/scoresPerCategoryView.html',
        bindToController: true,
        controller: 'stars.scoresPerCategory',
        bindings: {
            selectedItem: '<',
            indicators: '=',
            category: '=',
            isReadOnly: '<'
        }
  });

}(window.angular));




(function (angular) {
    "use strict";

    angular
        .module('qsHub.stars')
        .controller('stars.starsSections', [
            '$scope',
            'StarsInstitutionsListService',
            'starsEditFactory',
            'WatchService',
            'stars.constants',
            Controller
        ]);

    function Controller(
        $scope,
        StarsInstitutionsListService,
        starsEditFactory,
        WatchService,
        starsConstants
    ) {
        var controller = this;

        function fetchSubjectsList() {
            if (controller.selectedItem.institutionType === starsConstants.institutionType.uni) {
                StarsInstitutionsListService.getAllUniversitySubjects().then(function (list) {
                    if (!list) {
                        return false;
                    }
                    starsEditFactory.setSubjectsUniList(list);
                });
            } else {
                StarsInstitutionsListService.getAllBusinessSchoolSubjects().then(function (list) {
                    if (!list) {
                        return false;
                    }
                    starsEditFactory.setSubjectsBussinesList(list);
                });
            }
        }

        function fetchBroadSubjectsList() {
            StarsInstitutionsListService.getAllUniversityBroadSubjects().then(function (list) {
                starsEditFactory.setBroadSubjectsList(list);
            });
        }

        function handleSubjectsWatch(newValue) {
            if (newValue && newValue.institutionType) {
                fetchSubjectsList();
                controller.dataWatch();
            }
        }

        function initWatches() {
            controller.dataWatch = WatchService.create($scope, '$ctrl.selectedItem', handleSubjectsWatch);
        }

        controller.getVersion = function() {
            return StarsInstitutionsListService.getSubscriptionVersionId(controller.subscription);
        };

        controller.$onInit = function () {
            initWatches();
            fetchBroadSubjectsList();
        };
    }

}(window.angular));

(function (angular) {

  'use strict';

  angular.module('qsHub.stars').component('starsSections', {
        templateUrl: '/scripts/modules/stars/components/starsEdit/components/starsSections/starsSectionsView.html',
        bindToController: true,
        controller: 'stars.starsSections',
        bindings: {
            selectedItem: '<',
            sections: '=',
            categories: '=',
            isReadOnly: '<',
            subscription:'<'
        }
  });

}(window.angular));




(function (angular) {
    'use strict';

    angular.module('qsHub.stars').component('starsSpecialBadges', {
        templateUrl: '/scripts/modules/stars/components/starsSpecialBadges/starsSpecialBadgesView.html',
        bindToController: true,
        controller: 'stars.specialBadgesController',
        bindings: {
            selectedItem: '=',
            isReadOnly: '<'
        }
    });

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.stars')
        .controller('stars.specialBadgesController', [
            '$scope',
            'WatchService',
            'StarsInstitutionsListService',
            'starsEditFactory',
            'stars.constants',
            Controller
        ]);

    function Controller(
        $scope,
        WatchService,
        StarsInstitutionsListService,
        starsEditFactory,
        starsConstants
    ) {
        var controller = this,
            defaultSubjectList = [];

        controller.starsList = [];
        controller.subjectsList = [];
        controller.subject = null;
        controller.stars = null;
        controller.badges = [];

        controller.isAddButtonDisabled = function () {
            return !controller.subject || !controller.stars;
        };

        controller.handleAdd = function () {
            if (controller.isAddButtonDisabled()) {
                return false;
            }
            if (!controller.selectedItem.answers) {
                controller.selectedItem.answers = [];
            }
            if (!controller.selectedItem.answers[0]) {
                controller.selectedItem.answers.push({});
            }
            if (!controller.selectedItem.answers[0].starsPerSubject) {
                controller.selectedItem.answers[0].starsPerSubject = {};
            }
            controller.selectedItem.answers[0].starsPerSubject[controller.subject.value] = controller.stars.value;
            var i = 0,
                total = controller.subjectsList.length;
            for (i; i < total ; i++) {
                if (controller.subjectsList[i].value === controller.subject.value) {
                    controller.subjectsList[i].disabled = true;
                    controller.badges.push({
                        subjectHandle: controller.subject.value,
                        subject: controller.subject.label,
                        stars: controller.stars.label,
                        index: i
                    });
                    break;
                }
            }
            controller.subject = null;
            controller.stars = null;
        };

        function initBadges() {
            controller.badges=[];
            controller.subjectsList = angular.copy(defaultSubjectList);
            var i = 0,
                total = controller.subjectsList.length,
                badges = controller.selectedItem && controller.selectedItem.answers && controller.selectedItem.answers[0] ?
                            controller.selectedItem.answers[0].starsPerSubject : false;

            if (controller.selectedItem &&
                controller.selectedItem.answers &&
                controller.selectedItem.answers[0] &&
                typeof controller.selectedItem.answers[0].starsPerSubject !== 'undefined' &&
                controller.selectedItem.answers[0].starsPerSubject !== null &&
                controller.selectedItem.answers[0].starsPerSubject.length === 0
            ) {
                    controller.selectedItem.answers[0].starsPerSubject = {};
            }

            if (badges) {
                angular.forEach(badges, function (starsValue, subjectHandle) {
                    i = 0;
                    for (i; i < total ; i++) {
                        if (controller.subjectsList[i].value === subjectHandle) {
                            controller.subjectsList[i].disabled = true;
                            controller.badges.push({
                                subjectHandle: subjectHandle,
                                subject: getSubjectLabel(subjectHandle),
                                stars: getStarsLabel(starsValue),
                                index: i
                            });
                            break;
                        }
                    }
                });
            }
        }

        function getSubjectLabel(subjectHandle) {
            var i = 0,
                total = controller.subjectsList.length;

            for (i; i < total; i++) {
                if (controller.subjectsList[i].value === subjectHandle) {
                    return controller.subjectsList[i].label;
                }
            }
        }

        function getStarsLabel(starsValue) {
            var i = 0,
                total = controller.starsList.length;

            for (i; i < total; i++) {
                if (controller.starsList[i].value === starsValue) {
                    return controller.starsList[i].label;
                }
            }
        }

        controller.handleRemove = function (index) {
            controller.subjectsList[controller.badges[index].index].disabled = false;
            delete controller.selectedItem.answers[0].starsPerSubject[controller.badges[index].subjectHandle];
            controller.badges.splice(index, 1);
        };

        function fetchStarsList() {
            StarsInstitutionsListService.getStarsList(true).then(function (list) {
                controller.starsList = list;

                if (controller.selectedItem.institutionType === starsConstants.institutionType.uni) {
                    var i = 0,
                        total = list.length;
                    for (i; i < total; i++) {
                        if (list[i].value === 6) {
                            controller.starsList.splice(i, 1);
                            break;
                        }
                    }
                }
            });
        }

        function fetchSubjectsList() {
            if (controller.selectedItem.institutionType === starsConstants.institutionType.uni) {
                StarsInstitutionsListService.getAllUniversitySubjects().then(function (list) {
                    if (!list) {
                        return false;
                    }
                    controller.subjectsList = list;
                    defaultSubjectList = angular.copy(list);
                    starsEditFactory.setSubjectsUniList(controller.subjectsList);
                    initBadges();
                });
            } else {
                StarsInstitutionsListService.getAllBusinessSchoolSubjects().then(function (list) {
                    if (!list) {
                        return false;
                    }
                    controller.subjectsList = list;
                    defaultSubjectList = angular.copy(list);
                    starsEditFactory.setSubjectsBussinesList(controller.subjectsList);
                    initBadges();
                });
            }
        }

        function subscriptionChangeWatch(newValue) {
            if (newValue) {
                starsEditFactory.setSubscriptionChange(false);
            }
        }

        function selectedItemWatch(selectedItem) {
            if (!selectedItem) {
                return;
            }
            fetchStarsList();
            fetchSubjectsList();
        }

        function initWatches() {
            WatchService.create($scope, '$ctrl.selectedItem', selectedItemWatch);
            WatchService.create($scope, starsEditFactory.isSubscriptionChange, subscriptionChangeWatch);
        }

        controller.$onInit = function () {
            initWatches();
        };
    }

}(window.angular));

(function (angular) {
    'use strict';

    angular.module('qsHub.stars').component('starsCertificate', {
        templateUrl: '/scripts/modules/stars/components/starsCertificate/starsCertificateView.html',
        bindToController: true,
        controller: 'stars.CertificateController',
        bindings: {
            selectedItem: '=',
            isReadOnly: '<'
        }
    });

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.stars')
        .controller('stars.CertificateController', [
            '$timeout',
            'constants',
            'AuthenticationService',
            'NotifierFactory',
            'TimeService',
            Controller
        ]);

    function Controller(
        $timeout,
        constants,
        AuthenticationService,
        NotifierFactory,
        TimeService
    ) {
        var controller = this;

        controller.maxSize = 5;
        controller.uplooadInProgress = false;
        controller.config = {
            dropzone: {
                url: constants.api.stars.url + '/v1/certificate-upload',
                maxFilesize: controller.maxSize,
                maxFiles: 1,
                uploadMultiple: false,
                parallelUploads: 1,
                autoProcessQueue: true,
                addRemoveLinks: false,
                acceptedFiles:  '.pdf',
                previewsContainer: false,
                headers: AuthenticationService.getAuthorizationHeader(),
                paramName: "file",
                init: function () {
                    controller.dropZoneInstance = this;
                },
            },
            eventHandlers: {
                success: handleUploadSuccess,
                error: handleUploadError,
                addedfile: handleFileAdded
            }
        };
        controller.fileName = controller.selectedItem.institutionName.replace(new RegExp(' ', 'g'), '_') + '_Certificate_' + TimeService.getCurrentYear() + '.pdf';

        controller.handleRemove = function () {
            controller.selectedItem.answers[0].certificateUrl = null;
            controller.dropZoneInstance.removeAllFiles();
        };

        function handleFileAdded() {
            $timeout(function () {
                controller.uplooadInProgress = true;
            });
        }

        function handleUploadSuccess(instance, response) {
            if (response.status && response.status && response.path) {
                NotifierFactory.show(
                    'success',
                    'File successfully uploaded.',
                    'Certificate Upload'
                );
                if (!controller.selectedItem.answers) {
                    controller.selectedItem.answers = [];
                }
                if (!controller.selectedItem.answers[0]) {
                    controller.selectedItem.answers[0] = [];
                }
                $timeout(function () {
                    controller.selectedItem.answers[0].certificateName = controller.fileName;
                    controller.selectedItem.answers[0].certificateUrl = response.path;
                    controller.uplooadInProgress = false;
                });
            } else {
                handleUploadError();
            }
        }

        function handleUploadError() {
            NotifierFactory.show(
                'error',
                'File upload failed. Only PDFs are accepted & up to ' + controller.maxSize + 'MB in size',
                'Certificate Upload'
            );
            $timeout(function () {
                controller.uplooadInProgress = false;
            });
        }
    }


}(window.angular));

(function (angular) {
    'use strict';

    angular.module('qsHub.stars').component('starsDevelopmentRoadmap', {
        templateUrl: '/scripts/modules/stars/components/starsDevelopmentRoadmap/starsDevelopmentRoadmapView.html',
        bindToController: true,
        controller: 'stars.DevelopmentRoadmapController',
        bindings: {
            selectedItem: '=',
            isReadOnly: '<'
        }
    });

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.stars')
        .controller('stars.DevelopmentRoadmapController', [
            '$timeout',
            'constants',
            'AuthenticationService',
            'NotifierFactory',
            'TimeService',
            Controller
        ]);

    function Controller(
        $timeout,
        constants,
        AuthenticationService,
        NotifierFactory,
        TimeService
    ) {
        var controller = this;

        controller.maxSize = 5;
        controller.uplooadInProgress = false;
        controller.config = {
            dropzone: {
                url: constants.api.stars.url + '/v1/development-roadmap-upload',
                maxFilesize: controller.maxSize,
                maxFiles: 1,
                uploadMultiple: false,
                parallelUploads: 1,
                autoProcessQueue: true,
                addRemoveLinks: false,
                acceptedFiles:  '.pdf',
                previewsContainer: false,
                headers: AuthenticationService.getAuthorizationHeader(),
                paramName: "file",
                init: function () {
                    controller.dropZoneInstance = this;
                },
            },
            eventHandlers: {
                success: handleUploadSuccess,
                error: handleUploadError,
                addedfile: handleFileAdded
            }
        };
        controller.fileName = controller.selectedItem.institutionName.replace(new RegExp(' ', 'g'), '_') + '_Development_Roadmap_' + TimeService.getCurrentYear() + '.pdf';

        controller.handleRemove = function () {
            controller.selectedItem.answers[0].developmentRoadmapUrl = null;
            controller.dropZoneInstance.removeAllFiles();
        };

        function handleFileAdded() {
            $timeout(function () {
                controller.uplooadInProgress = true;
            });
        }

        function handleUploadSuccess(instance, response) {
            if (response.status && response.status && response.path) {
                NotifierFactory.show(
                    'success',
                    'File successfully uploaded.',
                    'Development Roadmap Upload'
                );
                if (!controller.selectedItem.answers) {
                    controller.selectedItem.answers = [];
                }
                if (!controller.selectedItem.answers[0]) {
                    controller.selectedItem.answers[0] = [];
                }
                $timeout(function () {
                    controller.selectedItem.answers[0].developmentRoadmapName = controller.fileName;
                    controller.selectedItem.answers[0].developmentRoadmapUrl = response.path;
                    controller.uplooadInProgress = false;
                });
            } else {
                handleUploadError();
            }
        }

        function handleUploadError() {
            NotifierFactory.show(
                'error',
                'File upload failed. Only PDFs are accepted & up to ' + controller.maxSize + 'MB in size',
                'Development Roadmap'
            );
            $timeout(function () {
                controller.uplooadInProgress = false;
            });
        }
    }


}(window.angular));

(function (angular) {

  'use strict';

  angular.module('qsHub.stars').component('starsCommonFiles', {
      templateUrl: '/scripts/modules/stars/components/commonFiles/starsCommonFilesView.html',
      bindToController: true,
      controller: 'Stars.CommonFilesController'
  });

}(window.angular));




(function (angular) {
    "use strict";

    angular
        .module('qsHub.stars')
        .controller('Stars.CommonFilesController', [
            '$timeout',
            'constants',
            'AuthenticationService',
            'StarsCommonFilesService',
            'NotifierFactory',
            Controller
        ]);

    function Controller(
        $timeout,
        constants,
        AuthenticationService,
        StarsCommonFilesService,
        NotifierFactory
    ) {
        var controller = this;

        controller.maxSize = 5;
        controller.uploadEnabled = true;
        controller.type = null;
        controller.tmpFile = null;
        controller.version = null;
        controller.documents = null;
        controller.toLoad = 2;
        controller.submitDisabled = true;
        controller.isUploadDisabled = function () {
            return !controller.type || !controller.version || !controller.uploadEnabled;
        };

        controller.handleRemove = function (index) {
            controller.documents.splice(index, 1);
            controller.submitDisabled = false;
        };

        controller.handleSave = function () {
            controller.toLoad = 1;
            StarsCommonFilesService.replace(controller.documents).then(function (data) {
                if (data) {
                    controller.documents = data;
                    controller.submitDisabled = true;
                    NotifierFactory.show(
                        'success',
                        'Documents uploaded successfully',
                        'Common Files Upload'
                    );
                } else {
                    NotifierFactory.show(
                        'error',
                        'Can\'t upload document at the moment. Please try again later.',
                        'Common Files Upload'
                    );
                }
                controller.toLoad = 0;
            });
        };

        controller.config = {
            dropzone: {
                url: constants.api.stars.url + '/v1/document-upload',
                maxFilesize: controller.maxSize,
                maxFiles: 1,
                uploadMultiple: false,
                parallelUploads: 1,
                autoProcessQueue: true,
                addRemoveLinks: false,
                acceptedFiles:  '.pdf',
                previewsContainer: false,
                headers: AuthenticationService.getAuthorizationHeader(),
                paramName: 'file',
                init: function () {
                    controller.dropZoneInstance = this;
                },
            },
            eventHandlers: {
                success: handleUploadSuccess,
                error: handleUploadError,
            }
        };

        function handleUploadSuccess(instance, response) {
            if (response.status && response.status && response.path) {
                var version = controller.version,
                    type = controller.type.value,
                    exists = false,
                    i = 0,
                    total = controller.documents.length;

                for (i; i < total; i++) {
                    if (controller.documents[i].type === type) {
                        exists = true;
                        break;
                    }
                }
                if (!exists) {
                    $timeout(function () {
                        controller.documents.push({
                            url: response.path,
                            version: version,
                            name: controller.type.label,
                            type: type,
                            createdAt: response.createdAt,
                            createdByFullName: response.createdByFullName
                        });
                        controller.type = null;
                        controller.version = null;
                    });
                } else {
                    $timeout(function () {
                        controller.documents[i] = {
                            url: response.path,
                            version: version,
                            name: controller.type.label,
                            type: type,
                            createdAt: response.createdAt,
                            createdByFullName: response.createdByFullName
                        };
                        controller.type = null;
                        controller.version = null;
                    });
                }
                controller.submitDisabled = false;
            } else {
                NotifierFactory.show(
                    'error',
                    'Can\'t upload document at the moment. Please try again later.',
                    'Common Files Upload'
                );
            }
            $timeout(function () {
                controller.uploadEnabled = true;
                controller.dropZoneInstance.removeAllFiles();
            });
        }

        function handleUploadError (error) {
            var errorMessage = error && error.xhr !== undefined && error.xhr.responseText ? JSON.parse(error.xhr.responseText) : false;
            errorMessage = errorMessage ? errorMessage.message : 'File upload failed. Only PDF files are accepted';
            $timeout(function() {
                controller.uploadEnabled = true;
                controller.dropZoneInstance.removeAllFiles();
                NotifierFactory.show(
                    'error',
                    errorMessage,
                    'Common Files Upload'
                );
            });
        }

        controller.$onInit = function () {
            StarsCommonFilesService.getTypes().then(function (items) {
                controller.list = items;
                controller.toLoad--;
            });
            StarsCommonFilesService.getFiles().then(function (items) {
                controller.documents = items;
                controller.toLoad--;
            });
        };
    }

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.stars')
        .service('StarsCommonFilesService', [
            '$q',
            '$resource',
            'constants',
            'RequestsQueueService',
            Service
        ]);

    function Service(
        $q,
        $resource,
        constants,
        RequestsQueueService
    ) {
        var service = {
            cache: {}
        };

        service.getTypes = function () {
            var deferred = $q.defer();
            deferred.resolve(
                [
                    {
                        value: 'guideline',
                        label: 'QS Stars brand guideline'
                    },
                    {
                        value: 'methodology_uni',
                        label: 'QS Stars full University methodology'
                    },
                    {
                        value: 'methodology_bs',
                        label: 'QS Stars full Business school methodology'
                    }
                ]
            );

            return deferred.promise;
        };

        function getModel() {
            return $resource(constants.api.stars.url, {}, {
                getFiles: {
                    method: 'GET',
                    url: constants.api.stars.url + '/v1/list/documents',
                    isArray: false,
                    cancellable: true
                },
                replace: {
                    method: 'PUT',
                    url: constants.api.stars.url + '/v1/documents',
                    isArray: false,
                    cancellable: true
                }
            });
        }

        service.getFiles = function (params, useCache) {
            useCache = useCache || false;
            params = params || {};
            var cacheKey = 'getDocuments:' + angular.toJson(params),
                deferred = null,
                key = 'StarsFilesUploadService:' + cacheKey,
                Api;

            if (useCache && service.cache[cacheKey]) {
                deferred = $q.defer();
                deferred.resolve(service.cache[cacheKey]);
                return deferred.promise;
            }

            RequestsQueueService.cancelAll(key);
            Api = getModel().getFiles(params);
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                data = data.results ? data.results : null;
                service.cache[cacheKey] = data;
                return data;
            }, function () {
                return false;
            });
        };

        service.replace = function (data) {
            if (!data ) {
                throw 'Missing Required';
            }

            var key = 'StarsFilesUploadService:update',
                Api;
            RequestsQueueService.cancelAll(key);
            Api = getModel().replace(data);
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (result) {
                return result.data;
            }, function () {
                return false;
            });
        };

        return service;
    }

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.stars')
        .controller('Stars.ClientsController', [
            '$scope',
            'stars.constants',
            'StarsClientService',
            'StarsInstitutionsListService',
            'WatchService',
            'InstitutionFactory',
            'InstitutionService',
            'UserFactory',
            'starsEditFactory',
            Controller
        ]);

    function Controller(
        $scope,
        starsConstants,
        StarsClientService,
        StarsInstitutionsListService,
        WatchService,
        InstitutionFactory,
        InstitutionService,
        UserFactory,
        StarsEditFactory
    ) {
        var controller = this,
            isLoading = true,
            starsList = null,
            sectionHandlesUnis = [],
            sectionHandlesBusiness = [],
            uniCategoryHandles = [],
            businessCategoryHandles = [],
            indicators = StarsEditFactory.getIndicators(),
            handles = StarsEditFactory.getHandles();

        controller.data = null;
        controller.isMiniCoreData = null;
        controller.isAllTabsExpanded = false;
        controller.sections = [];
        controller.hasStarsSubscription = true;

        controller.isLoading = function () {
            return isLoading || !starsList;
        };

        controller.isClient = function () {
            return UserFactory.isClient();
        };

        controller.noSubscribedToStars = function () {
            return !controller.hasStarsSubscription;
        };

        controller.getStars = function () {
            if (controller.data.answers[0] && controller.data.answers[0].overallStarsRating && starsList) {
                var i = 0,
                    total = starsList.length;
                for (i; i < total; i++) {
                    if (starsList[i].value === controller.data.answers[0].overallStarsRating) {
                        return starsList[i].label;
                    }
                }
            }
            return '';
        };

        controller.toggleTabs = function () {
            controller.isAllTabsExpanded = !controller.isAllTabsExpanded;
            controller.sections.map(function (item){
                item.open = !!controller.isAllTabsExpanded;
            });
        };

        function initCategory(category, handleSection) {
            if (!angular.isDefined(controller.data.answers[0].scores)) {
                controller.data.answers[0].scores = {};
            }
            controller.categories[handleSection].push(category);
        }

        function setDisplayedTabs() {
            var i = 0,
                total = controller.sections.length;
            for (i; i < total; i++) {
                if (controller.sections[i].handle === 'upload_certificate_and_development_roadmap') {
                    break;
                }
            }
            controller.sections.splice(i, 1);
        }

        function handleCoreIdWatch(coreId) {
            if (!coreId) {
                return;
            }
            controller.categories = {
                coreCriteria : [],
                learningEnvironment : [],
                advancedCriteria : [],
                specialistCriteria : [],
                businessSchoolCriteria: [],
                businessOutputCriteria: [],
                businessSpecialistCriteria: []
            };
            isLoading = true;

            /*var institutionData = InstitutionFactory.getData(); @todo uncomment if its option c for QCR-3606
            if (!institutionData ||
                !institutionData.subscriptions ||
                !institutionData.subscriptions.stars ||
                !institutionData.subscriptions.stars.subscribed ||
                !institutionData.subscriptions.stars.advanced
            ) {
                return InstitutionService.handle403();
            }*/

            StarsClientService.get(coreId).then(function (data) {
                controller.data = null;
                if (data) {
                    controller.data = angular.copy(data);
                    controller.data.answers = [data.answers];
                    controller.data.isClient = true;

                    if (data.institutionType === starsConstants.institutionType.uni) {
                        StarsInstitutionsListService.getAllUniversitySections(false, controller.data.version).then(function (sections) {
                            controller.sections = StarsInstitutionsListService.orderUniversitySections(sections.results);
                            setDisplayedTabs();
                            sectionHandlesUnis = StarsInstitutionsListService.getSectionHandles(controller.sections);
                            if (controller.data && controller.data.version) {
                                getCategoriesUnis(controller.data.version);
                            }
                        });
                    } else {
                        StarsInstitutionsListService.getAllBusinessSchoolSections(false, controller.data.version).then(function (data) {
                            controller.sections = StarsInstitutionsListService.orderBusinessSchoolSections(data.results);
                            setDisplayedTabs();
                            sectionHandlesBusiness = StarsInstitutionsListService.getSectionHandles(controller.sections);
                            getCategoriesBussines();
                        });
                    }
                    controller.isMiniCoreData =  data.version === 'minicore';
                }
                else {
                    controller.sections = [];
                }
                isLoading = false;
                var subscribedTo = InstitutionFactory.getSubscriptions();
                controller.hasStarsSubscription = true;

                if (!subscribedTo ||
                    !subscribedTo.stars ||
                    !subscribedTo.stars.subscribed ||
                    !subscribedTo.stars.advanced
                ) {
                    controller.hasStarsSubscription = false;
                }
            });
        }

        function setIndicatorType() {
            if (controller.data && controller.data.answers) {
                angular.forEach( controller.data.answers[0].scores, function(score, categoryHandle) {
                    angular.forEach(score, function(value, key) {
                        if (key !== "specialist_program" && key !== "name_specialist_program") {
                            if (controller.data.institutionType === starsConstants.institutionType.uni) {
                                if (uniCategoryHandles.indexOf(categoryHandle) === -1 ||
                                    categoryHandle === handles.facilities &&  indicators.university.facilities.indexOf(key) === -1 ||
                                    categoryHandle === handles.programStrength && indicators.university.programStrength.indexOf(key) === -1 ||
                                    categoryHandle === handles.research && indicators.university.research.indexOf(key) === -1 ||
                                    categoryHandle === handles.employability && indicators.university.employability.indexOf(key) === -1) {
                                    return;
                                }
                                if (value.value) {
                                    value.type = 'uniIndicator';
                                }
                            }
                            else {
                                if (businessCategoryHandles.indexOf(categoryHandle) === -1 ||
                                    categoryHandle === handles.facilities && indicators.business.facilities.indexOf(key) === -1 ||
                                    categoryHandle === handles.programStrength &&  indicators.business.programStrength.indexOf(key) === -1 ||
                                    categoryHandle === handles.research && indicators.business.research.indexOf(key) === -1 ||
                                    categoryHandle === handles.employability && indicators.business.employability.indexOf(key) === -1) {
                                    return;
                                }
                                if (value.value) {
                                    value.type = 'businessIndicator';
                                }
                            }
                        }
                    });
                });
            }
        }

        function getCategoriesBySectionHandle(handle) {
                var i = 0,
                    total = controller.sections.length;
            for (i; i < total; i++) {
                if (controller.sections[i].handle === handle) {
                    return controller.sections[i].categoriesHandles;
                }
            }
        }

        function getCategoriesUnis(version) {
            return StarsInstitutionsListService.getUniCategories(false, version).then(function (response) {
                if (!response) {
                    return false;
                }
                var i = 0,
                    total = sectionHandlesUnis.length;
                for (i; i < total; i++) {
                    var categoriesHandles = getCategoriesBySectionHandle(sectionHandlesUnis[i]);
                    if (categoriesHandles) {
                        uniCategoryHandles = angular.copy(uniCategoryHandles.concat(categoriesHandles));
                        for (var j = 0; j < response.results.length; j++) {
                           switch(sectionHandlesUnis[i]) {
                               case 'core_criteria':
                                   if (categoriesHandles.indexOf(response.results[j].handle) !== -1) {
                                       initCategory(response.results[j], 'coreCriteria');
                                       if (response.results[j].handle === handles.research) {
                                           for (var k = 0; k <= response.results[j].indicators.length; k++ ) {
                                               if(response.results[j].indicators[k] && response.results[j].indicators[k].handle) {
                                                   indicators.university.research.push(response.results[j].indicators[k].handle);
                                               }
                                           }
                                       }
                                       if (response.results[j].handle === handles.employability) {
                                           for (var l = 0; l <= response.results[j].indicators.length; l++ ) {
                                               if(response.results[j].indicators[l] && response.results[j].indicators[l].handle) {
                                                   indicators.university.employability.push(response.results[j].indicators[l].handle);
                                               }
                                           }
                                       }
                                   }
                                   break;
                               case 'learning_environment':
                                   if (categoriesHandles.indexOf(response.results[j].handle) !== -1) {
                                       initCategory(response.results[j], 'learningEnvironment');
                                       if (response.results[j].handle === handles.facilities) {
                                           for (var m = 0; m <= response.results[j].indicators.length; m++ ) {
                                               if(response.results[j].indicators[m] && response.results[j].indicators[m].handle) {
                                                   indicators.university.facilities.push(response.results[j].indicators[m].handle);
                                               }
                                           }
                                       }
                                   }
                                   break;
                               case 'advanced_criteria':
                                   if (categoriesHandles.indexOf(response.results[j].handle) !== -1) {
                                       initCategory(response.results[j], 'advancedCriteria');
                                   }
                                   break;
                               case 'specialist_criteria':
                                   if (categoriesHandles.indexOf(response.results[j].handle) !== -1) {
                                       initCategory(response.results[j], 'specialistCriteria');
                                       if (response.results[j].handle === handles.programStrength) {
                                           for (var n = 0; n <= response.results[j].indicators.length; n++ ) {
                                               if(response.results[j].indicators[n] && response.results[j].indicators[n].handle) {
                                                   indicators.university.programStrength.push(response.results[j].indicators[n].handle);
                                               }
                                           }
                                       }
                                   }
                                   break;
                           }
                       }
                    }
                }
                setIndicatorType();
            });
        }

        function getCategoriesBussines() {
            return StarsInstitutionsListService.getBusinessCategories().then(function (response) {
                if (!response) {
                    return false;
                }
                var i = 0,
                    total = sectionHandlesBusiness.length;
                for (i; i < total; i++) {
                    var categoriesHandles = getCategoriesBySectionHandle(sectionHandlesBusiness[i]);
                    businessCategoryHandles = angular.copy(businessCategoryHandles.concat(categoriesHandles));
                     for (var j = 0; j < response.results.length; j++) {
                        switch(sectionHandlesBusiness[i]) {
                            case 'school_criteria':
                                if (categoriesHandles.indexOf(response.results[j].handle) !== -1) {
                                    initCategory(response.results[j], 'businessSchoolCriteria');
                                    if (response.results[j].handle === handles.facilities) {
                                        for (var k = 0; k <= response.results[j].indicators.length; k++ ) {
                                            if(response.results[j].indicators[k] && response.results[j].indicators[k].handle) {
                                                indicators.business.facilities.push(response.results[j].indicators[k].handle);
                                            }
                                        }
                                    }
                                }
                                break;
                            case 'output_criteria':
                                if (categoriesHandles.indexOf(response.results[j].handle) !== -1) {
                                    initCategory(response.results[j], 'businessOutputCriteria');
                                    if (response.results[j].handle === handles.research) {
                                        for (var l = 0; l <= response.results[j].indicators.length; l++ ) {
                                            if(response.results[j].indicators[l] && response.results[j].indicators[l].handle) {
                                                indicators.business.research.push(response.results[j].indicators[l].handle);
                                            }
                                        }
                                    }
                                    if (response.results[j].handle === handles.employability) {
                                        for (var m = 0; m <= response.results[j].indicators.length; m++ ) {
                                            if(response.results[j].indicators[m] && response.results[j].indicators[m].handle) {
                                                indicators.business.employability.push(response.results[j].indicators[m].handle);
                                            }
                                        }
                                    }
                                }
                                break;
                            case 'specialist_criteria':
                                if (categoriesHandles.indexOf(response.results[j].handle) !== -1) {
                                    initCategory(response.results[j], 'businessSpecialistCriteria');
                                    if (response.results[j].handle === handles.programStrength) {
                                        for (var n = 0; n <= response.results[j].indicators.length; n++ ) {
                                            if(response.results[j].indicators[n] && response.results[j].indicators[n].handle) {
                                                indicators.business.programStrength.push(response.results[j].indicators[n].handle);
                                            }
                                        }
                                    }
                                }
                                break;
                        }
                    }
                }
                controller.totalLoad--;
                setIndicatorType();
            });
        }

        function initWatches() {
            // watch for core ID changes
            WatchService.create($scope, InstitutionFactory.getCoreId, handleCoreIdWatch);
        }

        function init() {
            initWatches();
            StarsInstitutionsListService.getStarsList().then(function (list) {
                starsList = list;
            });
        }

        init();
    }

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.stars')
        .service('StarsClientService', [
            '$resource',
            'RequestsQueueService',
            'StarsCommonFilesService',
            'constants',
            '$q',
            Service
        ]);

    function Service(
        $resource,
        RequestsQueueService,
        StarsCommonFilesService,
        constants,
        $q
    ) {
        var service = {};

        function getModel() {
            return $resource(constants.api.stars.url, {}, {
                get: {
                    method: 'GET',
                    url: constants.api.stars.url + '/v1/client/:id',
                    isArray: false,
                    cancellable: true
                }
            });
        }

        service.get = function (institutionCoreId) {
            if (!institutionCoreId) {
                throw 'Missing Required';
            }
            var key = 'StarsClientService:get',
                Api;
            RequestsQueueService.cancelAll(key);
            Api = getModel().get({id: institutionCoreId});
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                return data.id ? data : null;
            }, function () {
                return false;
            });
        };

        service.getLatestGuideline = function () {
            return StarsCommonFilesService.getFiles({
                'sorting[createdAt]' : 'desc',
                'filter[type]' : 'guideline',
                'limit' : 1,
                'columns': [
                    'name',
                    'url'
                ]
            }).then(function (documents) {
                return documents[0];
            });
        };

        service.getLatestRoadmap = function () {
            return StarsCommonFilesService.getFiles({
                'sorting[createdAt]' : 'desc',
                'filter[type]' : 'roadmap',
                'limit' : 1,
                'columns': [
                    'name',
                    'url'
                ]
            }).then(function (documents) {
                return documents[0];
            });
        };

        service.getLatestMethodology = function (type) {
            return StarsCommonFilesService.getFiles({
                'sorting[createdAt]' : 'desc',
                'filter[type]' : type,
                'limit' : 1,
                'columns': [
                    'name',
                    'url'
                ]
            }).then(function (documents) {
                return documents[0];
            });
        };

        return service;
    }

}(window.angular));


(function (angular) {

  'use strict';

  angular.module('qsHub.stars').component('starsClientDownloadFiles', {
      templateUrl: '/scripts/modules/stars/components/clients/starsClientDownloadFiles/starsClientDownloadFilesView.html',
      bindToController: true,
      controller: 'Stars.ClientDownloadFilesController',
      bindings: {
          data: '='
      }
  });

}(window.angular));




(function (angular) {
    "use strict";

    angular
        .module('qsHub.stars')
        .controller('Stars.ClientDownloadFilesController', [
            '$scope',
            'StarsClientService',
            'stars.constants',
            'WatchService',
            Controller
        ]);

    function Controller(
        $scope,
        StarsClientService,
        starsConstants,
        WatchService
    ) {
        var controller = this,
            leftToLoad = 3;

        controller.latestGuideline = null;
        controller.latestRoadmap = null;

        function countDownLoad() {
            if (leftToLoad !== 0) {
                leftToLoad--;
            }
        }

        controller.isLoading = function () {
            return leftToLoad !== 0;
        };

        controller.getFileName = function(url) {
            return url.substring(url.lastIndexOf("/") + 1, url.length);
        };

        controller.hasData = function () {
            return (controller.data && controller.data.answers[0] && controller.data.answers[0].certificateUrl) ||
                (controller.data && controller.data.answers[0] && controller.data.answers[0].developmentRoadmapUrl) ||
                controller.latestGuideline ||
                controller.latestRoadmap;
        };

        function getMethodologyHandle() {
            if (controller.data &&
                controller.data.institutionType
            ) {
                switch (controller.data.institutionType) {
                    case starsConstants.institutionType.uni:
                        return starsConstants.handleFile.methodology.uni;
                    case starsConstants.institutionType.bs:
                        return starsConstants.handleFile.methodology.bs;
                    default:
                        return '';
                }
            }

            return '';
        }

        function methodologyhandleWatch(type) {
            if (type) {
                StarsClientService.getLatestMethodology(getMethodologyHandle()).then(function (file) {
                    controller.latestMethodology = file;
                    countDownLoad();
                });
                controller.methodologyWatch();
            } else {
                countDownLoad();
            }
        }

        function initWatches() {
            controller.methodologyWatch = WatchService.create($scope, '$ctrl.data.institutionType', methodologyhandleWatch);
        }

        function init() {
            initWatches();
            StarsClientService.getLatestGuideline().then(function (file) {
                controller.latestGuideline = file;
                countDownLoad();
            });
            StarsClientService.getLatestRoadmap().then(function (file) {
                controller.latestRoadmap = file;
                countDownLoad();
            });
        }

        controller.$onInit = init();
    }

}(window.angular));

(function (angular) {

  'use strict';

  angular.module('qsHub.stars').component('starsClientDownloadBadges', {
      templateUrl: '/scripts/modules/stars/components/clients/starsClientDownloadBadges/starsClientDownloadBadgesView.html',
      bindToController: true,
      controller: 'Stars.ClientDownloadBadgesController',
      bindings: {
          data: '='
      }
  });

}(window.angular));




(function (angular) {
    "use strict";

    angular
        .module('qsHub.stars')
        .controller('Stars.ClientDownloadBadgesController', [
            Controller
        ]);

    function Controller() {
        var controller = this;


        controller.hasData = function () {
            return controller.data && controller.data.answers[0] && controller.data.answers[0].zip;
        };

        controller.isLoading = function () {
            return false;
        };

        function init() {

        }

        controller.$onInit = init();
    }

}(window.angular));

(function (angular) {
    'use strict';

    angular.module('qsHub.stars').component('starsResultsUpload', {
        templateUrl: '/scripts/modules/stars/components/starsResultsUpload/starsResultsUploadView.html',
        bindToController: true,
        controller: 'Stars.ResultsUploadController',
        bindings: {
            selectedItem: '=',
            selectedSubscription: '<',
            isReadOnly: '<'
        }
    });

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.stars')
        .controller('Stars.ResultsUploadController', [
            '$timeout',
            'constants',
            'AuthenticationService',
            'NotifierFactory',
            'stars.constants',
            'StarsInstitutionsListService',
            'starsEditFactory',
            Controller
        ]);

    function Controller(
        $timeout,
        constants,
        AuthenticationService,
        NotifierFactory,
        starsConstants,
        StarsInstitutionsListService,
        starsEditFactory
    ) {
        var controller = this,
            subscription;

        controller.config = {
            dropzone: {
                url: getUploadUrl,
                maxFilesize: controller.maxSize,
                maxFiles: 1,
                uploadMultiple: false,
                parallelUploads: 1,
                autoProcessQueue: true,
                addRemoveLinks: false,
                acceptedFiles:  '.csv',
                previewsContainer: false,
                headers: AuthenticationService.getAuthorizationHeader(),
                paramName: "file",
                init: function () {
                    controller.dropZoneInstance = this;
                },
            },
            eventHandlers: {
                success: handleUploadSuccess,
                error: handleUploadError,
                addedfile: handleFileAdded
            }
        };
        controller.maxSize = 5;
        controller.uplooadInProgress = false;

        function getUploadUrl() {
            return constants.api.stars.url + '/v1/results-upload?institutionType=' + controller.selectedItem.institutionType;
        }

        controller.handleRemove = function () {
            controller.selectedItem.answers[0].certificateUrl = null;
        };

        /**
         * Remove Dropzone files
         */
        controller.removeDropZoneUploadedFile = function () {
            if (!controller.dropZoneInstance) {
                return false;
            }
            if (controller.dropZoneInstance.files && controller.dropZoneInstance.files.length > 0) {
                controller.dropZoneInstance.removeFile(controller.dropZoneInstance.files[0]);
            } else {
                controller.dropZoneInstance.removeAllFiles();
            }
        };

        controller.getDataTemplateUrl = function () {
            if (!controller.selectedItem) {
                return null;
            }
            if (controller.selectedItem.institutionType === starsConstants.institutionType.uni) {
                if (controller.selectedSubscription) {
                    var selectedSubscriptionVersionId = StarsInstitutionsListService.getSubscriptionVersionId( controller.selectedSubscription);
                    var versionId = controller.selectedItem.answers &&
                                    controller.selectedItem.answers[0] &&
                                    controller.selectedItem.answers[0].versionId ? controller.selectedItem.answers[0].versionId : selectedSubscriptionVersionId;
                    return parseFloat(versionId) === starsConstants.versions.v5 ? 'https://files-core.qs.com/stars/uni-tpl-v5.csv?5580512056' : 'https://files-core.qs.com/stars/uni-tpl.csv?1523982368wewe';
                }
            } else {
                return 'https://files-core.qs.com/stars/bs-tpl.csv?1523982444wewe';
            }
        };

        function handleFileAdded() {
            $timeout(function () {
                controller.uplooadInProgress = true;
            });
        }

        function handleUploadSuccess(instance, response) {
            if (response.total && response.content) {
                NotifierFactory.show(
                    'success',
                    'File successfully uploaded.',
                    'Results Upload'
                );
                populateData(response.content);

                $timeout(function () {
                    controller.uplooadInProgress = false;
                    controller.uploadedFileName = instance.name;
                    subscription = controller.selectedSubscription;
                    controller.removeDropZoneUploadedFile();
                });
            } else {
                handleUploadError();
            }
        }

        controller.removeFile = function () {
            controller.uploadedFileName = null;
            subscription = null;
        };

        controller.showUpload = function () {
            if (subscription &&
                controller.selectedSubscription &&
                subscription.value !== controller.selectedSubscription.value
            ) {
                controller.removeFile();
            }
            return !controller.uploadedFileName;
        };

        function handleUploadError(error) {
            var errorMessage = error && error.xhr !== undefined && error.xhr.responseText ? JSON.parse(error.xhr.responseText) : false;
            errorMessage = errorMessage ? errorMessage.message : 'File upload failed. Only CSV files are accepted & up to ' + controller.maxSize + 'MB in size';

            NotifierFactory.show(
                'error',
                errorMessage,
                'Results Upload'
            );
            $timeout(function () {
                controller.uplooadInProgress = false;
                controller.removeDropZoneUploadedFile();
            });
        }

        var filterFloat = function(value) {
            if (/^(\-|\+)?([0-9]+(\.[0-9]+)?|Infinity)$/.test(value)) {
                return Number(value);
            }

            return NaN;
        };

        function cleanCheckboxes()
        {
            angular.forEach(controller.selectedItem.answers[0].scores, function(scores) {
                angular.forEach(scores, function (score) {
                    if (score.hasOwnProperty('checkboxList')) {
                        delete score.checkboxList;
                    }
                });
            });
        }

        function populateData(content)
        {
            if(!controller.selectedItem.answers) {
                controller.selectedItem.answers = [];
                controller.selectedItem.answers.push({scores:{}});
            }
            if(!controller.selectedItem.answers[0]) {
                controller.selectedItem.answers.push({scores:{}});
            }
            else if(!controller.selectedItem.answers[0].scores) {
                controller.selectedItem.answers[0].scores = {};
            }
            starsEditFactory.setValidateRequested(false);
            var dataStructure = {}, functionName,
            versionId = controller.selectedItem &&
                        controller.selectedItem.answers &&
                        controller.selectedItem.answers[0] &&
                        controller.selectedItem.answers[0].versionId &&
                        controller.selectedItem.answers[0].version ?
                        controller.selectedItem.answers[0].versionId :
                        controller.selectedItem.version;
            if (controller.selectedItem.institutionType === starsConstants.institutionType.uni) {
                functionName = StarsInstitutionsListService.getUniCategories(false, versionId);
            } else if (controller.selectedItem.institutionType === starsConstants.institutionType.bs) {
                functionName = StarsInstitutionsListService.getBusinessCategories();
            } else {
                return false;
            }
            functionName.then(function (response) {
                if (!response) {
                    return false;
                }
                angular.forEach(response.results, function(result) {
                    angular.forEach(result.indicators, function (indicator) {
                        if ((typeof content[indicator.handle] !== 'undefined' &&
                            (
                                (angular.isNumber(filterFloat(content[indicator.handle].numbers)) ||
                                angular.isNumber(filterFloat(content[indicator.handle].points)))
                            )) ||
                            (typeof content[result.handle + "." + indicator.handle] !== 'undefined' &&
                            (
                                (angular.isNumber(filterFloat(content[result.handle + "." + indicator.handle].numbers)) ||
                                angular.isNumber(filterFloat(content[result.handle + "." + indicator.handle].points)))
                            ))
                        ) {
                            if (!dataStructure[result.handle]) {
                                dataStructure[result.handle] = {};
                            }
                            if (!dataStructure[result.handle][indicator.handle]) {
                                dataStructure[result.handle][indicator.handle] = {};
                            }
                            var structureElement = {};
                            if (angular.isDefined(content[result.handle + "." + indicator.handle]) && angular.isNumber(filterFloat(content[result.handle + "." + indicator.handle].numbers)) && filterFloat(content[result.handle + "." + indicator.handle].numbers) >= 0) {
                                structureElement.number = filterFloat(content[result.handle + "." + indicator.handle].numbers);
                            } else if (content[indicator.handle] && angular.isNumber(filterFloat(content[indicator.handle].numbers)) && filterFloat(content[indicator.handle].numbers) >= 0) {
                                structureElement.number = filterFloat(content[indicator.handle].numbers);
                            }
                            if (angular.isDefined(content[result.handle + "." + indicator.handle]) && angular.isNumber(filterFloat(content[result.handle + "." + indicator.handle].points)) && filterFloat(content[result.handle + "." + indicator.handle].points) >= 0) {
                                structureElement.value = filterFloat(content[result.handle + "." + indicator.handle].points);
                            } else if (content[indicator.handle] && angular.isNumber(filterFloat(content[indicator.handle].points)) && filterFloat(content[indicator.handle].points) >= 0) {
                                structureElement.value = filterFloat(content[indicator.handle].points);
                            }
                            dataStructure[result.handle][indicator.handle] = structureElement;
                            dataStructure[result.handle].show = true;
                        }
                    });
                });
                controller.selectedItem.answers[0].scores = angular.copy(angular.merge({}, angular.copy(controller.selectedItem.answers[0].scores), dataStructure));
                controller.selectedItem.answers[0].isUpdatedByCsv = true;
                cleanCheckboxes();
                starsEditFactory.setValidateRequested(true);
            });
        }
    }

}(window.angular));

(function (angular) {

  'use strict';

  angular.module('qsHub.stars').component('starsBadgesLogs', {
      templateUrl: '/scripts/modules/stars/components/badgesLogs/starsBadgesLogsView.html',
      bindToController: true,
      controller: 'Stars.BadgesLogsController'
  });

}(window.angular));




(function (angular) {
    "use strict";

    angular
        .module('qsHub.stars')
        .controller('Stars.BadgesLogsController', [
            'StarsBadgesService',
            'BadgesFactory',
            'WatchService',
            '$scope',
            'WebSocketsService',
            'NotifierFactory',
            Controller
        ]);

    function Controller(
        StarsBadgesService,
        BadgesFactory,
        WatchService,
        $scope,
        WebSocketsService,
        NotifierFactory
    ) {
        var controller = this,
            limit = null,
            totalMatching = 0,
            totalFiltered = 0,
            logsList = null,
            fetchInProgress = true,
            logsPerLoad = 10,
            defaultFilters = {
                limit : null
            },
            room = 'SaveBadgesOutcome',
            subscribedTo = [];

        function fetchLogs() {
            fetchInProgress = true;
            StarsBadgesService.getGroupedLogs(defaultFilters).then(function (logs) {
                logsList = logs.grouped && Object.keys(logs.grouped).length ? logs.grouped : null;
                controller.logs = getLimitedLogs();
                totalMatching = logs.totalMatching;
                totalFiltered = logs.totalFiltered;
                fetchInProgress = false;
            });
        }

        function subscribeToWebsocket(id) {
            if (BadgesFactory.getSubscribedTo().indexOf(room + id) === -1) {
                WebSocketsService.subscribe(room + id, 'getOutcome' + id, function (result) {
                    var success = result && result.result && result.result.success;
                    if (success) {
                        BadgesFactory.requestLogsReload();
                        NotifierFactory.show(
                            'success',
                            'Badges are saved successfully!',
                            'Badges Upload'
                        );
                    } else {
                        NotifierFactory.show(
                            'error',
                            'Can\'t process badges at the moment. Please try again later.',
                            'Badges Upload'
                        );
                    }
                }, function (subscribed) {
                    if (subscribed) {
                        BadgesFactory.setSubscribedTo(room + id);
                    }
                });
            }
        }

        function getLimitedLogs() {
            var limited = {},
                total = 1;
            angular.forEach(logsList, function (logs, dateKey) {
                angular.forEach(logs, function (log, key) {
                    if (total <= limit) {
                        if (typeof limited[dateKey] === 'undefined') {
                            limited[dateKey] = [];
                        }
                        limited[dateKey][key] = log;
                        total++;
                        if (log.status === 'progress') {
                            subscribeToWebsocket(log.id);
                        }
                    } else {
                        return false;
                    }
                });
            });
            return limited;
        }

        controller.getLogClassColor = function(status) {
            switch (status) {
                case 'success':
                    return 'text-success';
                case 'failure':
                    return 'text-danger';
                case 'progress':
                    return 'text-info';
            }
        };

        controller.getLogClassFaIcon = function(status) {
            switch (status) {
                case 'success':
                    return 'fa-check';
                case 'failure':
                    return 'fa-exclamation';
                case 'progress':
                    return 'fa-spinner fa-spin';
            }
        };

        controller.isMoreLogsAvailable = function () {
            return limit < totalMatching;
        };

        controller.handleLoadMore = function () {
            limit = limit + logsPerLoad;
            defaultFilters.limit = limit;
            fetchLogs();
            BadgesFactory.setLogsLimit(limit);
        };

        controller.isFetchInProgress = function () {
            return fetchInProgress;
        };

        function logsReloadRequestWatch(is) {
            if (is) {
                BadgesFactory.resetLogsReloadRequest();
                fetchLogs();
            }
        }

        function initWatches() {
            WatchService.create($scope, BadgesFactory.isLogsReloadRequest, logsReloadRequestWatch);
        }

        controller.$onInit = function () {
            limit = BadgesFactory.getLogsLimit() || logsPerLoad;
            defaultFilters.limit = limit;
            initWatches();
            fetchLogs();
        };
    }

}(window.angular));

(function (angular) {

  'use strict';

  angular.module('qsHub.stars').component('starsBadgesOverview', {
      templateUrl: '/scripts/modules/stars/components/badgesOverview/starsBadgesOverviewView.html',
      bindToController: true,
      controller: 'Stars.BadgesOverviewController'
  });

}(window.angular));




(function (angular) {
    "use strict";

    angular
        .module('qsHub.stars')
        .controller('Stars.BadgesOverviewController', [
            '$scope',
            '$timeout',
            'constants',
            'StarsBadgesService',
            'uiGridConstants',
            'UiGridService',
            'stars.gridNamespaces',
            'stars.constants',
            'DateRangeFixerService',
            'TimeService',
            'StarsInstitutionsListService',
            Controller
        ]);

    function Controller(
        $scope,
        $timeout,
        constants,
        StarsBadgesService,
        uiGridConstants,
        GridService,
        gridNamespaces,
        starsConstants,
        DateRangeFixerService,
        TimeService,
        StarsInstitutionsListService
    ) {
        var controller = this,
            gridApiInstance = null,
            loadingData = false,
            isRendered = false,
            filterChangesInProgress = false,
            starsList = null,
            columnsFilters = GridService.getColumnsFilters(
                gridNamespaces.filters.badgesOverview, []
            ),
            columnsVisibility = GridService.getColumnsVisibility(gridNamespaces.visibility.badgesOverview, []),
            defaultFilters = {
                createdAtRange: {
                    startDate: null,
                    endDate: null
                }
            },
            paginationOptions = GridService.getColumnsFilters(
                gridNamespaces.filters.badgesOverview
            ),
            defaultPaginationOptions = {
                page: 1,
                limit: starsConstants.datagrid.defaultRowsNumber,
                'sorting[createdAt]': 'desc'
            },
            filtersTimeout,
            lastUpdatedAtDateFilter = null,
            selectValues = {},
            lastSelectValues = {};

        controller.filters = null;
        controller.showLoadBar = function () {
            return !isRendered || loadingData;
        };

        function getPage() {
            loadingData = true;
            if (paginationOptions['filter[category]']) {
                paginationOptions['filter[categoryAsText]'] = paginationOptions['filter[category]'];
                delete paginationOptions['filter[category]'];
            }
            return StarsBadgesService.getList(angular.merge({}, paginationOptions, defaultPaginationOptions)).then(function (response) {
                controller.gridOptions.totalItems = response.totalMatching;
                controller.gridOptions.data = response.results;
                controller.gridOptions.minRowsToShow = response.totalMatching;
                $timeout(function () {
                    loadingData = false;
                },200);
                var prefix;
                angular.forEach(controller.gridOptions.data, function (item) {
                    prefix = '';
                    if (
                        (item.type !== 'university_overall' && item.type.indexOf('university_') === 0) ||
                        (item.type !== 'businessSchool_overall' && item.type.indexOf('businessSchool_') === 0)
                    ) {
                        prefix = item.type.split('_')[1];
                    }
                    item.fileType = item.type.indexOf('university') === 0 ? 'University': 'Business School';
                    item.category = item.type.indexOf('overall') !== -1 ? 'Overall' : prefix + ': ' + item.category;
                });
            });
        }

        function applyDateFilter(filterName, defaultValue) {
            var filter = GridService.getFilterByField(paginationOptions, filterName, defaultValue);
            if (angular.isDefined(filter) &&
                filter !== null
            ) {
                if (angular.isDefined(filter.startDate) &&
                    angular.isDefined(filter.endDate)
                ) {
                    if (filter.startDate !== null &&
                        filter.startDate !== null
                    ) {
                        defaultFilters[filterName + 'Range'] = {
                            startDate: parseInt(filter.startDate, 10),
                            endDate: parseInt(filter.endDate, 10)
                        };
                    } else {
                        defaultFilters[filterName + 'Range'] = {
                            startDate: null,
                            endDate: null
                        };
                    }
                }
            }
        }

        function handleGridVisibleChanges() {
            var columnsVisibility = GridService.getColumnsVisibility(gridNamespaces.visibility.badgesOverview),
                reload = false,
                columnName;
            if (columnsVisibility && !controller.selectedRowId) {
                angular.forEach(controller.gridOptions.columnDefs, function (column, key) {
                    columnName = column.field === 'fileType' ? 'type' : column.field;
                    columnName = column.field === 'category' ? 'categoryAsText' : column.field;
                    if (columnsVisibility[columnName] === true && column.visible === false) {
                        if (typeof  controller.gridOptions.columnDefs[key].filter === 'undefined') {
                            controller.gridOptions.columnDefs[key].filter = {};
                        }
                        if (controller.gridOptions.columnDefs[key].filter.term !== null &&
                            controller.gridOptions.columnDefs[key].filter.term !== ''
                        ) {
                            reload = true;
                        }
                        controller.gridOptions.columnDefs[key].filter.term = null;
                        columnsFilters['filter[' + columnName + ']'] = null;

                        if (column.field === 'createdAt') {
                            if (controller.gridOptions.columnDefs[key].filters[0].term !== null &&
                                typeof  controller.gridOptions.columnDefs[key].filters[0].term !== 'undefined' &&
                                controller.gridOptions.columnDefs[key].filters[1].term !== null &&
                                typeof  controller.gridOptions.columnDefs[key].filters[1].term !== 'undefined'
                            ) {
                                reload = true;
                            }
                            delete  controller.gridOptions.columnDefs[key].filters[0].term;
                            controller.filters.createdAtRange = {
                                startDate: null,
                                endDate: null
                            };
                        }
                    }
                });
                GridService.storeColumnsFilters(gridNamespaces.filters.badgesOverview, columnsFilters);
            }
            if (controller.gridOptions) {
                var columnDefs = angular.copy(controller.gridOptions.columnDefs);
                angular.forEach(columnDefs, function (column, key) {
                    if (column.field === 'fileType') {
                        columnDefs[key].field = 'type';
                    }
                    if (column.field === 'category') {
                        columnDefs[key].field = 'categoryAsText';
                    }
                });
                controller.columnsVisibility = GridService.getGridColumnsVisibility(columnDefs);
                if(!controller.selectedRowId){
                    GridService.storeColumnsVisibility(
                        gridNamespaces.visibility.badgesOverview,
                        controller.columnsVisibility
                    );
                }
            }
            if (reload) {
                getPage();
            }
        }

        function handleGridFiltersChanges() {
            filterChangesInProgress = true;
            if(gridApiInstance.pagination.getPage() > 1){
                gridApiInstance.pagination.seek(1);
            }
            angular.forEach(gridApiInstance.grid.columns, function (column) {
                var filterColumn = column.filters[0].column ? column.filters[0].column : column.field,
                    deleteFilter = false;

                if (column.field === 'createdAt') {
                    if (typeof column.filters !== 'undefined' &&
                        typeof column.filters[0].term !== 'undefined' &&
                        typeof column.filters[1].term !== 'undefined'
                    ) {
                        paginationOptions['filter[' + filterColumn + ']'] = {
                            startDate: column.filters[0].term && isNaN(column.filters[0].term) ?
                                column.filters[0].term.format('x') : column.filters[0].term,
                            endDate: column.filters[1].term && isNaN(column.filters[1].term) ?
                                column.filters[1].term.format('x') : column.filters[1].term
                        };
                    } else {
                        paginationOptions['filter[' + filterColumn + ']'] = null;
                    }
                } else {
                    if (column.filters[0].type === uiGridConstants.filter.SELECT &&
                        typeof column.filters[0].term === 'object' &&
                        column.filters[0].term !== null &&
                        typeof column.filters[0].term.value !== 'undefined'
                    ) {
                        column.filters[0].term = column.filters[0].term.value;
                    }
                    if (typeof column.filters !== 'undefined' &&
                        column.filters[0].term !== null &&
                        typeof column.filters[0].term !== 'undefined'
                    ) {
                        GridService.applyFilters(column);
                        if (column.filters[0].term === '') {
                            deleteFilter = true;
                        } else {
                            filterColumn = filterColumn === 'fileType' ? 'type' : filterColumn;
                            filterColumn = filterColumn === 'category' ? 'categoryAsText' : filterColumn;
                            paginationOptions['filter[' + filterColumn + ']'] = column.filters[0].valuePrefix && !paginationOptions['filter[' + filterColumn + ']']? column.filters[0].valuePrefix + column.filters[0].term : column.filters[0].term;
                        }
                    } else {
                        deleteFilter = true;
                    }

                    filterColumn = filterColumn === 'fileType' ? 'type' : filterColumn;
                    filterColumn = filterColumn === 'category' ? 'categoryAsText' : filterColumn;
                    if (deleteFilter && typeof paginationOptions['filter[' + filterColumn + ']'] !== 'undefined') {
                        delete paginationOptions['filter[' + filterColumn + ']'];
                    }
                }

            });

            if (paginationOptions['filter[createdAt]'] === null) {
                paginationOptions['filter[createdAt]'] = {
                    startDate: null,
                    endDate: null
                };
            }
            if (paginationOptions['filter[createdAt]'].startDate === null) {
                paginationOptions['filter[createdAt]'].endDate = null;
                controller.filters.createdAtRange = {
                    startDate: null,
                    endDate: null
                };
            }

            GridService.storeColumnsFilters(
                gridNamespaces.filters.badgesOverview,
                paginationOptions
            );
            GridService.generateSelectValues(gridApiInstance.grid.columns, selectValues);

            if (angular.isDefined(filtersTimeout)) {
                $timeout.cancel(filtersTimeout);
            }
            filtersTimeout = $timeout(function () {
                getPage();
                filterChangesInProgress = false;
                lastSelectValues = angular.copy(selectValues);
            }, constants.datagridSelectDelay);
        }

        controller.handleCreatedAtRange = function (event) {
            if (DateRangeFixerService.sameDayAndNoModel(event, controller.filters.createdAtRange, lastUpdatedAtDateFilter)) {
                event.model.startDate = lastUpdatedAtDateFilter.startDate;
                event.model.endDate = lastUpdatedAtDateFilter.endDate;
                controller.filters.createdAtRange = {
                    startDate: lastUpdatedAtDateFilter.startDate,
                    endDate: lastUpdatedAtDateFilter.endDate
                };
            } else if (DateRangeFixerService.todayRangeAndNoModel(event, lastUpdatedAtDateFilter)) {
                event.model.startDate = TimeService.getStartOfToday();
                event.model.endDate = TimeService.getEndOfToday();
                controller.filters.createdAtRange = {
                    startDate: event.model.startDate.toISOString(),
                    endDate: event.model.endDate.toISOString()
                };
            }
            var updatedAtField = controller.gridOptions.columnDefs[4];
            if (updatedAtField) {
                if (event === null || typeof event === 'undefined') {
                    updatedAtField.filters[0].term = null;
                    updatedAtField.filters[1].term = null;
                } else if (event.model.startDate && event.model.endDate) {
                    updatedAtField.filters[0].term = typeof event.model.startDate === 'object' ? event.model.startDate.format('x') : event.model.startDate;
                    updatedAtField.filters[1].term = typeof event.model.endDate === 'object' ? event.model.endDate.format('x') : event.model.endDate;
                }
            }
            lastUpdatedAtDateFilter = angular.copy({
                startDate: event.model.startDate,
                endDate: event.model.endDate
            });
        };

        function initDatagridOptions () {
            var starsCellTemplate = '/scripts/modules/stars/components/badgesPreview/starsCellTemplate.html',
                selectTemplate = '/scripts/shared/ui-grid/templates/selectFilterHeaderTemplate.html';

            controller.gridOptions = {
                appScopeProvider: controller,
                enableSorting: false,
                enableGridMenu: true,
                showGridFooter: true,
                enableFiltering: true,
                enableColumnResize: false,
                enableFullRowSelection: false,
                enableRowSelection: false,
                multiSelect: false,
                enableRowHeaderSelection: false,
                enableColumnMenus: false,
                paginationPageSizes: [25, 50, 100, 200],
                paginationPageSize: 25,
                useExternalPagination: true,
                useExternalFiltering: true,
                columnDefs: [
                    {
                        displayName: 'Name',
                        field: 'name',
                        visible: GridService.getVisibilityByField(columnsVisibility, 'name', true),
                        filter: {
                            condition: uiGridConstants.filter.CONTAINS,
                            term: GridService.getFilterByField(paginationOptions, 'name')
                        }
                    },
                    {
                        displayName: 'Stars',
                        field: 'stars',
                        cellTemplate: starsCellTemplate,
                        filterHeaderTemplate: selectTemplate,
                        visible: GridService.getVisibilityByField(columnsVisibility, 'stars', true),
                        filter: {
                            valuePrefix: '=',
                            column: 'stars',
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: starsList,
                            term: GridService.getFilterByField(columnsFilters, 'stars', '', false),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        displayName: 'Type',
                        field: 'fileType',
                        visible: GridService.getVisibilityByField(columnsVisibility, 'type', true),
                        filter: {
                            condition: uiGridConstants.filter.CONTAINS,
                            term: GridService.getFilterByField(paginationOptions, 'type')
                        }
                    },
                    {
                        displayName: 'Category / Subject',
                        field: 'category',
                        cellFilter: 'strReplace: "_": " " | capitalize',
                        visible: GridService.getVisibilityByField(columnsVisibility, 'categoryAsText', true),
                        filter: {
                            condition: uiGridConstants.filter.CONTAINS,
                            term: GridService.getFilterByField(paginationOptions, 'categoryAsText')
                        }
                    },
                    {
                        displayName: 'Date uploaded',
                        field: 'createdAt',
                        filterCellFiltered: true,
                        enableSorting: false,
                        filters: [
                            {
                                visible: true,
                                condition: uiGridConstants.filter.GREATER_THAN
                            },
                            {
                                condition: uiGridConstants.filter.LESS_THAN
                            }
                        ],
                        filter: {
                            term: GridService.getFilterByField(columnsFilters, 'createdAt', '', false),
                            applyTextFilter: applyDateFilter('createdAt', '')
                        },
                        cellTemplate: '/scripts/shared/ui-grid/templates/dateCellTemplate.html',
                        filterHeaderTemplate: '/scripts/shared/ui-grid/templates/dateFilterHeaderTemplate.html',
                        visible: GridService.getVisibilityByField(columnsVisibility, 'createdAt', true)
                    },
                    {
                        displayName: 'Url',
                        field: 'url',
                        visible: GridService.getVisibilityByField(columnsVisibility, 'url', true),
                        filter: {
                            condition: uiGridConstants.filter.CONTAINS,
                            term: GridService.getFilterByField(paginationOptions, 'url')
                        },
                        minWidth: 200
                    }
                ],
                onRegisterApi: function (gridApi) {
                    gridApiInstance = gridApi;
                    gridApi.core.on.rowsRendered($scope, function () {
                        isRendered = true;
                        GridService.generateSelectValues(gridApiInstance.grid.columns, selectValues);
                        GridService.generateSelectValues(gridApiInstance.grid.columns, lastSelectValues);
                    });
                    gridApi.pagination.on.paginationChanged($scope, function (newPage, limit) {
                        defaultPaginationOptions.page = newPage;
                        defaultPaginationOptions.limit = limit;
                        if(!filterChangesInProgress) {
                            getPage();
                        }
                    });

                    gridApi.core.on.filterChanged($scope, handleGridFiltersChanges);
                    gridApi.core.on.rowsVisibleChanged($scope, handleGridVisibleChanges);

                    getPage();
                }
            };
            controller.filters = angular.extend({}, defaultFilters);
            var createdAt = GridService.getFilterByField(paginationOptions, 'createdAt', '');
            if (createdAt !== null && paginationOptions["filter[createdAt]"] !== null) {
                if (angular.isDefined(createdAt) &&
                    angular.isDefined(paginationOptions["filter[createdAt]"]) &&
                    angular.isDefined(paginationOptions["filter[createdAt]"].startDate) &&
                    angular.isDefined(paginationOptions["filter[createdAt]"].endDate)
                ) {
                    controller.gridOptions.columnDefs[4].filters[0].term = paginationOptions["filter[createdAt]"].startDate;
                    controller.gridOptions.columnDefs[4].filters[1].term = paginationOptions["filter[createdAt]"].endDate;
                }
            }
        }

        controller.getEmptyArray = function (length) {
            return length ? new Array(parseInt(length, 10)) : [];
        };

        controller.$onInit = function () {
            StarsInstitutionsListService.getStarsList(true).then(function (list) {
                starsList = list;
                initDatagridOptions();
            });
        };
    }

}(window.angular));

(function (angular) {

    'use strict';
  
    angular.module('qsHub.stars').component('publishToSite', {
        templateUrl: '/scripts/modules/stars/components/publishToSite/publishToSiteView.html',
        bindToController: true,
        controller: 'Stars.PublishToSiteController'
    });
  
  }(window.angular));
  
  
  
  
(function(angular) {
    "use strict";

    var App = App || {};
    App = angular.extend({}, App, {services:{}});

    App.services.PublishToSiteService = function (
        $resource,
        $q,
        $log,
        constants
    ) {
        var service = {
            deferred : {}
        };

        /**
         * Get departments upgrade API endpoint.
         *
         * @returns {$resource}
         */

        service.getLogsModel = function () {
            return $resource(constants.api.institutions.url + '/v1/tu-feed/publish-logs/:id?sorting[createdAt]=desc&filter[type]=stars&fieldName=type,status,createdAt,createdByFullName');
        };

        /**
         * Get publish logs.
         *
         * @param {String} institutionId
         * @returns {Promise.*}
         */
        service.getPublishLogs = function (institutionId, limit) {
            if (!institutionId) {
                var deferred = $q.defer();
                deferred.resolve(false);
                return deferred.promise;
            }
            limit = limit || 10;
            return service.getLogsModel().get({
                id: institutionId,
                limit: limit
            }).$promise.then(function (data) {
                if (constants.dev) {
                    $log.log('success, got data: ', data);
                }
                return data;
            }, function (error) {
                //@todo log error
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        return service;
    };

    angular
        .module('qsHub.stars')
        .service('PublishToSiteService', [
            '$resource',
            '$q',
            '$log',
            'constants',
            App.services.PublishToSiteService
        ]);

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.stars')
        .controller('Stars.PublishToSiteController', [
            '$scope',
            'constants',
            'InstitutionSwitchService',
            'TuProfilePublishService',
            'StarsClientService',
            'WebSocketsService',
            'InstitutionsService',
            'TuProfilePublishHistoryLogFactory',
            'WatchService',
            'PublishToSiteService',
            Controller
        ]);

    function Controller(
        $scope,
        constants,
        InstitutionSwitchService,
        PublishService,
        StarsClientService,
        WebSocketsService,
        InstitutionsService,
        TuProfilePublishHistoryLogFactory,
        WatchService,
        PublishToSiteService
    ) {
        var controller = this;

        controller.institution = {};
        controller.institutionsList = [];
        controller.searchInProgress = false;
        controller.subscribedTo = [];
        controller.isLoading = true;


        controller.searchInstitution = function (searchPhrase) {
            controller.searchInProgress = true;
            InstitutionSwitchService.searchInstitutions(searchPhrase).then(function (results) {
                controller.institutionsList = results;
                controller.searchInProgress = false;
            });
        };

        controller.handleSearchInstitutionClick = function (institution) {
            if (institution && institution.coreId) {
                controller.isStarsSubscription = false;
                controller.isLoading = true;
                StarsClientService.get(institution.coreId).then(function (data) {
                    if (data) {
                        InstitutionsService.getInstitutionData(institution.coreId).then(function (institutionData) {
                            controller.institution = institutionData.results[0];
                            controller.sections = PublishService.getSections(controller.institution, !!data, true).filter(function (sec) {
                                if (sec.type === 'stars' || sec.type === 'starsChina') {
                                    return sec;
                                }
                            });
                            controller.isStarsSubscription = true;
                            controller.publishSubscribe(controller.sections);
                            PublishService.getPublishStatus(controller.institution.id).then(function (response) {
                                controller.isLoading = false;
                                controller.publishStatusCallback(response);
                                PublishToSiteService.getPublishLogs(controller.institution.id, TuProfilePublishHistoryLogFactory.getPublishLogLimit()).then(function (response) {
                                    TuProfilePublishHistoryLogFactory.setLogs(response);
                                });
                            });

                        });
                    } else {
                        TuProfilePublishHistoryLogFactory.setLogs([]);
                        controller.isLoading = false;
                    }
                });
            }
        };

        /**
         * Actions to do when publishing is triggered.
         *
         * @param {Object} section
         */
        controller.handlePublish = function (section) {
            section.publishDisabled = true;
            section.status = constants.publishStatus.pending;
            section.statusMessage = '';
            PublishService.publish(controller.institution.id, section.type).then(function (response) {
                controller.publishCallback(section, response);
            });
        };

         /**
         * Publishing callback.
         *
         * @param {Object} section
         * @param {Object} response
         * @returns {boolean}
         */
        controller.publishCallback = function (section, response) {
            if (!response || !angular.isDefined(response.feedId)) {
                section.status = constants.publishStatus.failure;
                return false;
            }
            PublishService.setStatus(constants.publishStatus.pending);
            section.feedId = response.feedId;
            if (section.type !== 'courses') {
                section.status = constants.publishStatus.pending;
            }
        };

        controller.publishSubscribe = function() {
            angular.forEach(controller.sections, function (section) {
                var subscribeType = controller.institution.id + section.type;
                if (controller.subscribedTo.indexOf(subscribeType) === -1) {
                    controller.subscribedTo.push(subscribeType);
                    WebSocketsService.subscribe(subscribeType, 'PublishStatus' + subscribeType, function (response) {
                        controller.sectionPublishStatusCallback(section, response);
                    });
                }
            });
        };

        controller.sectionPublishStatusCallback = function (section, response) {
            section.publishDisabled = true;
            var status = response && response.status || constants.publishStatus.failure;
            section.status = status;
            switch (status) {
                case constants.publishStatus.failure:
                    PublishService.getPublishStatus(controller.institution.id).then(function (response) {
                        controller.publishStatusCallback(response);
                        section.publishDisabled = false;
                        if (response.statusMessage) {
                            section.statusMessage = PublishService.convertErrorMessage(
                                response.statusMessage,
                                false
                            );
                        }
                      //  TuProfileHistoryLogFactory.setReload(true);
                    });
                    break;
                case constants.publishStatus.success:
                    PublishService.getPublishStatus(controller.institution.id).then(function (response) {
                        controller.publishStatusCallback(response);
                        section.publishDisabled = false;
                        section.statusMessage = response.statusMessage;
                        // Update nodeId
                        section.viewDisabled = response[section.type] ? !response[section.type].nodeId : true;
                        if (response && response[section.type]) {
                            var env = section.type.indexOf('China') !== -1 ? constants.drupal.tuChina.url : constants.drupal.tu.url;
                            section.url = env + '/node/' + response[section.type].nodeId;
                        }
                     //   TuProfileHistoryLogFactory.setReload(true);
                    });
                    break;
                default:
                    break;
            }
        };

        controller.isLoadMoreRequestWatch = function (loadMore) {
            if (loadMore) {
                TuProfilePublishHistoryLogFactory.loadMore(false);
                TuProfilePublishHistoryLogFactory.setLoadMoreInProgress(true);
                var limit = TuProfilePublishHistoryLogFactory.getPublishLogLimit() + 5;

                PublishToSiteService.getPublishLogs(controller.institution.id, limit).then(function (response) {
                    TuProfilePublishHistoryLogFactory.setLogs(response);
                    TuProfilePublishHistoryLogFactory.setLoadMoreInProgress(false);
                    TuProfilePublishHistoryLogFactory.setPublishLogLimit(limit);
                });
            }
        };

         /**
         * Handle publish statuses response.
         *
         * @param {Promise.Object} publishStatus
         */
        controller.publishStatusCallback = function (publishStatus) {
            angular.forEach(controller.sections, function(section) {
                section.publishDisabled = section.type !== 'courses';
                if (angular.isDefined(publishStatus[section.type])) {
                    section.status = publishStatus[section.type].status;
                    if (section.status.length === 0 ||
                        section.status === constants.publishStatus.success ||
                        section.status === constants.publishStatus.failure
                    ) {
                        section.publishDisabled = false;
                    }
                    section.statusMessage = PublishService.convertErrorMessage(
                        publishStatus[section.type].message,
                        false
                    );
                }
            });
        };

        controller.$onInit = function () {
            // watch for load more request
            WatchService.create($scope, TuProfilePublishHistoryLogFactory.isLoadMoreRequest, controller.isLoadMoreRequestWatch);
           
        };
    }

}(window.angular));

(function (angular) {

    'use strict';
  
    angular.module('qsHub.stars').component('publishToSiteLogs', {
        templateUrl: '/scripts/modules/stars/components/publishToSite/publishToSiteLogs/publishToSiteLogsView.html',
        bindToController: true,
        controller: 'Stars.PublishToSiteLogsController'
    });
  
  }(window.angular));
  
  
  
  
(function (angular) {
    "use strict";

    angular
        .module('qsHub.stars')
        .controller('Stars.PublishToSiteLogsController', [
            '$scope',
            'constants',
            'InstitutionSwitchService',
            'TuProfilePublishService',
            'StarsClientService',
            'WebSocketsService',
            'InstitutionsService',
            'TuProfilePublishHistoryLogFactory',
            'WatchService',
            Controller
        ]);

    function Controller(
        $scope,
        constants,
        InstitutionSwitchService,
        PublishService,
        StarsClientService,
        WebSocketsService,
        InstitutionsService,
        TuProfilePublishHistoryLogFactory,
        WatchService
    ) {
        var controller = this;
        controller.isLoadMoreHistoryLogsInProgress = false;

        controller.fetchingPublishLog = function () {
            return false;
        };

        controller.getHistoryLogsDataWatch = function (data) {
            controller.publishLogs = data;
        };

        controller.isHistoryLogsLoadMoreInProgressWatch = function (isInProgress) {
            controller.isLoadMoreHistoryLogsInProgress = isInProgress;
        };

        controller.getPublishStatusMapped = function (status) {
            return PublishService.getPublishStatusMapped(status);
        };

        controller.isMoreLogsAvailable = function() {
            if (controller.publishLogs && controller.publishLogs.totalMatching) {
                return TuProfilePublishHistoryLogFactory.getPublishLogLimit() < controller.publishLogs.totalMatching;
            }
            return false;
        };
        controller.handleLoadMoreHistoryLogs = function() {
            TuProfilePublishHistoryLogFactory.loadMore();
        };

        

        controller.$onInit = function () {
            // watch for history logs data changes
            WatchService.create($scope, TuProfilePublishHistoryLogFactory.getLogs, controller.getHistoryLogsDataWatch);
            // watch for history logs loading progress
            WatchService.create($scope, TuProfilePublishHistoryLogFactory.isLoadMoreInProgress, controller.isHistoryLogsLoadMoreInProgressWatch);
            TuProfilePublishHistoryLogFactory.setPublishLogLimit(10);
        };
    }

}(window.angular));

(function (angular) {
    'use strict';

    angular.module('qsHub.profiles').service('CoursesService', [
        '$resource',
        '$q',
        'constants',
        'RequestsQueueService',
        CoursesService
    ]);

    function CoursesService(
        $resource,
        $q,
        constants,
        RequestsQueueService
    ) {
        var service = {
            cache : {},
            degree: {
                bachelor : 'bc',
                phd : 'phd',
                mba : 'mba',
                masters : 'masters',
                //professionalCertification : 'pc'
            },
            level: {
                undergrad: 'ug',
                postgrad: 'pg',
                professionalCertification : 'pc'
            }
        };

        function getAdminModel() {
            return $resource(constants.api.institutions.url, {}, {
                search: {
                    method: 'GET',
                    url: constants.api.institutions.url + '/v1/admin/list/courses',
                    isArray: false,
                    cancellable: true
                },
                getSubDegrees: {
                    method: 'GET',
                    url: constants.api.institutions.url + '/v1/admin/list/courses/sub-degrees?limit=5000&sorting[sortBy]=asc',
                    isArray: false,
                    cancellable: true
                },
                unpublish: {
                    method: 'GET',
                    url: constants.api.institutions.url + '/v1/admin/course/:id/unpublish',
                    isArray: true,
                    cancellable: true
                },
                getTotalsPerInstitution: {
                    method: 'GET',
                    url: constants.api.institutions.url + '/v1/admin/totals/courses',
                    isArray: false,
                    cancellable: true
                },
            });
        }

        function getModel() {
            return $resource(constants.api.institutions.url, {}, {
                search: {
                    method: 'GET',
                    url: constants.api.institutions.url + '/v1/list/courses/:institutionCoreId',
                    isArray: false,
                    cancellable: true
                },
                order: {
                    method: 'PATCH',
                    url: constants.api.institutions.url + '/v1/tu-profile/:id/coursesOrder',
                    isArray: false,
                    cancellable: true
                },
                replace: {
                    method: 'PATCH',
                    url: constants.api.institutions.url + '/v1/course/:id?publish=:publish',
                    isArray: false,
                    cancellable: true
                },
                delete: {
                    method: 'DELETE',
                    url: constants.api.institutions.url + '/v1/course/:id',
                    isArray: false,
                    cancellable: true
                },
                getPublishStatus: {
                    method: 'POST',
                    url: constants.api.institutions.url + '/v1/course/publish-status',
                    isArray: false,
                    cancellable: true
                },
                add: {
                    method: 'POST',
                    url: constants.api.institutions.url + '/v1/course?publish=:publish',
                    isArray: false,
                    cancellable: true
                },
                publish: {
                    method: 'GET',
                    url: constants.api.institutions.url + '/v1/course/:id/publish',
                    isArray: false,
                    cancellable: true
                }
            });
        }

        service.delete = function (id, data) {
            if (!id) {
                throw 'Missing Required';
            }
            var key = 'CoursesService:delete',
                Api;
            RequestsQueueService.cancelAll(key);
            Api = getModel().delete({
                id: id
            });
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                return data && data.modifiedCount === 1;
            }, function () {
                return false;
            });
        };

        service.replace = function (id, data, publish) {
            if (!id) {
                throw 'Missing Required';
            }
            var key = 'CoursesService:update',
                Api;
            RequestsQueueService.cancelAll(key);
            Api = getModel().replace({
                id: id,
                publish: publish
            }, data);
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                return data;
            }, function () {
                return false;
            });
        };

        service.add = function (data, publish) {
            var key = 'CoursesService:add',
                Api;
            RequestsQueueService.cancelAll(key);
            Api = getModel().add({
                publish: publish
            }, data);
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                return data;
            }, function () {
                return false;
            });
        };

        service.publish = function (id) {
            if (!id) {
                throw 'Missing Required';
            }
            var key = 'CoursesService:publish',
                Api;
            RequestsQueueService.cancelAll(key);
            Api = getModel().publish({
                id: id
            });
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function () {
                return true;
            }, function () {
                return false;
            });
        };

        service.unpublish = function (coreId, isDelete) {
            if (!coreId) {
                throw 'Missing Required';
            }
            if (!isDelete) {
                isDelete = false;
            }
            var key = 'CoursesService:unpublish',
                Api;
            RequestsQueueService.cancelAll(key);
            Api = getAdminModel().unpublish({
                id: coreId,
                delete: isDelete
            });
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function () {
                return true;
            }, function () {
                return false;
            });
        };

        service.search = function (filters) {
            var key = 'CoursesService:search',
                Api;
            RequestsQueueService.cancelAll(key);
            Api = getAdminModel().search(filters);
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                return data;
            }, function () {
                return false;
            });
        };

        service.getTotalsPerInstitution = function () {
            var key = 'CoursesService:getTotalsPerInstitution',
                Api;
            RequestsQueueService.cancelAll(key);
            Api = getAdminModel().getTotalsPerInstitution();
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                return data;
            }, function () {
                return false;
            });
        };

        service.getPublishStatus = function (coursesIds) {
            var key = 'CoursesService:getPublishStatus',
                Api;
            RequestsQueueService.cancelAll(key);
            Api = getModel().getPublishStatus(coursesIds);
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                return data;
            }, function () {
                return false;
            });
        };

        service.getByInstitutionId = function (institutionCoreId, filters) {
            if (!institutionCoreId) {
                throw 'Missing Required';
            }

            var key = 'CoursesService:getByInstitutionId' + institutionCoreId,
                Api;

            RequestsQueueService.cancelAll(key);
            Api = getModel().search(angular.merge({institutionCoreId: institutionCoreId}, filters));
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                return data;
            }, function () {
                return false;
            });
        };

        service.getSubjects = function (useCache) {
            useCache = useCache || false;
            var deferred = $q.defer(),
                key = 'CoursesService:subjects',
                Api;
            if (useCache && service.cache[key]) {
                deferred.resolve(service.cache[key]);
            } else {
                RequestsQueueService.cancelAll(key);
                Api = getAdminModel().getSubjects();
                RequestsQueueService.add(Api, key);

                Api.$promise.then(function (data) {
                    service.cache[key] = data.results;
                    return deferred.resolve(data.results);
                }, function () {
                    return deferred.resolve(false);
                });
            }

            return deferred.promise;
        };

        service.getSubDegrees = function (useCache) {
            useCache = useCache || false;
            var deferred = $q.defer(),
                key = 'CoursesService:getSubDegrees',
                Api;
            if (useCache && service.cache[key]) {
                deferred.resolve(service.cache[key]);
            } else {
                RequestsQueueService.cancelAll(key);
                Api = getAdminModel().getSubDegrees();
                RequestsQueueService.add(Api, key);

                Api.$promise.then(function (data) {
                    service.cache[key] = data.results;
                    return deferred.resolve(data.results);
                }, function () {
                    return deferred.resolve(false);
                });
            }

            return deferred.promise;
        };

        function filterSubDegrees(type, list) {
            var tmp = [];
            angular.forEach(list, function (item) {
                if (item.type === type) {
                    tmp.push(item);
                }
            });
            return tmp;
        }

        service.filterUgSubDegrees = function (list) {
            return filterSubDegrees(service.level.undergrad, list);
        };

        service.filterPgSubDegrees = function (list) {
            return filterSubDegrees(service.level.postgrad, list);
        };

        service.getDegrees = function () {
            var deferred = $q.defer();
            deferred.resolve(
                [
                    {
                        handle: service.degree.bachelor,
                        name: 'Bachelors'
                    },
                    {
                        handle: 'phd',
                        name: 'PhD'
                    },
                    {
                        handle: 'mba',
                        name: 'MBA'
                    },
                    {
                        handle: 'masters',
                        name: 'Masters'
                    },
                    /*{
                        handle: service.degree.professionalCertification,
                        name: 'Professional Certifications'
                    }*/
                ]
            );

            return deferred.promise;
        };

        service.getCurrencies = function () {
            var deferred = $q.defer();
            deferred.resolve(
                [
                    {
                        value: 'aud',
                        label: 'AUD'
                    },
                    {
                        value: 'cad',
                        label: 'CAD'
                    },
                    {
                        value: 'chf',
                        label: 'CHF'
                    },
                    {
                        value: 'dkk',
                        label: 'DKK'
                    },
                    {
                        value: 'eur',
                        label: 'EUR'
                    },
                    {
                        value: 'gbp',
                        label: 'GBP'
                    },
                    {
                        value: 'inr',
                        label: 'INR'
                    },
                    {
                        value: 'nzd',
                        label: 'NZD'
                    },
                    {
                        value: 'rub',
                        label: 'RUB'
                    },
                    {
                        value: 'sgd',
                        label: 'SGD'
                    },
                    {
                        value: 'sek',
                        label: 'SEK'
                    },
                    {
                        value: 'sol',
                        label: 'SOL'
                    },
                    {
                        value: 'usd',
                        label: 'USD'
                    }
                ]
            );

            return deferred.promise;
        };

        service.getMonths = function () {
            var deferred = $q.defer();
            deferred.resolve(
                [
                    {
                        value: 1,
                        label: 'January'
                    },
                    {
                        value: 2,
                        label: 'February'
                    },
                    {
                        value: 3,
                        label: 'March'
                    },
                    {
                        value: 4,
                        label: 'April'
                    },
                    {
                        value: 5,
                        label: 'May'
                    },
                    {
                        value: 6,
                        label: 'June'
                    },
                    {
                        value: 7,
                        label: 'July'
                    },
                    {
                        value: 8,
                        label: 'August'
                    },
                    {
                        value: 9,
                        label: 'September'
                    },
                    {
                        value: 10,
                        label: 'October'
                    },
                    {
                        value: 11,
                        label: 'November'
                    },
                    {
                        value: 12,
                        label: 'December'
                    }
                ]
            );

            return deferred.promise;
        };

        service.getDegreeLengthUnits = function () {
            var deferred = $q.defer();
            deferred.resolve(
                [
                    {
                        value: 'h',
                        label: 'Hours'
                    },
                    {
                        value: 'd',
                        label: 'Days'
                    },
                    {
                        value: 'm',
                        label: 'Months'
                    },
                    {
                        value: 'y',
                        label: 'Years'
                    }
                ]
            );

            return deferred.promise;
        };

        service.getTypes = function () {
            var deferred = $q.defer();
            deferred.resolve(
                [
                    {
                        handle: 'pt',
                        name: 'Part-time'
                    },
                    {
                        handle: 'ft',
                        name: 'Full-time'
                    },
                    {
                        handle: 'online',
                        name: 'Online'
                    }
                ]
            );

            return deferred.promise;
        };

        service.getTestTypes = function () {
            var deferred = $q.defer();
            deferred.resolve(
                [
                    {
                        value: 'stat',
                        label: 'STAT'
                    },
                    {
                        value: 'atar',
                        label: 'ATAR'
                    },
                    {
                        value: 'op',
                        label: 'OP'
                    },
                    {
                        value: 'qtac',
                        label: 'QTAC'
                    }
                ]
            );

            return deferred.promise;
        };

        service.getLevels = function () {
            var deferred = $q.defer();
            deferred.resolve(
                [
                    {
                        handle: service.level.undergrad,
                        name: 'Undergraduate'
                    },
                    {
                        handle: service.level.postgrad,
                        name: 'Postgraduate'
                    },
                    /*{
                        handle: service.level.professionalCertification,
                        name: 'Professional Certifications'
                    }*/
                ]
            );

            return deferred.promise;
        };

        service.mockPromise = function (value) {
            var deferred = $q.defer();
            deferred.resolve(value);

            return deferred.promise;
        };

        service.isUgLevel = function (value) {
            return value === service.level.undergrad;
        };

        service.isPgLevel = function (value) {
            return value === service.level.postgrad;
        };

        service.isPcLevel = function (value) {
            return value === service.level.professionalCertification;
        };

        service.isBcDegree = function (value) {
            return value === service.degree.bachelor;
        };

        service.isPhdDegree = function (value) {
            return value === service.degree.phd;
        };

        service.isMbaDegree = function (value) {
            return value === service.degree.mba;
        };

        service.isMastersDegree = function (value) {
            return value === service.degree.masters;
        };

        service.isPcDegree = function (value) {
            return value === service.degree.professionalCertification;
        };

        /**
         * Update Courses ordering API endpoint.
         *
         * @returns {$resource}
         */
        service.updateOrderModel = function () {
            return $resource(constants.api.institutions.url + '/v1/tu-profile/:id/coursesOrder', null, {
                update: {method:'PATCH'}
            });
        };

        /**
         * Update department(s) order.
         *
         * @param {array} ids
         * @returns {Promise}
         */
        service.updateOrder = function (profileId, orderedIds) {
            if (!profileId || !orderedIds || orderedIds.length < 0) {
                throw 'Missing Required';
            }

            var key = 'CoursesService:updateOrder',
                Api;
            RequestsQueueService.cancelAll(key);
            Api = getModel().order({id: profileId}, orderedIds);
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                return true;
            }, function () {
                return false;
            });
        };

        service.disableDegrees = function (degreesList, courseLevel) {
            angular.forEach(degreesList, function (option) {
                option.disabled = (service.isUgLevel(courseLevel) && option.value !== service.degree.bachelor) ||
                    (service.isPgLevel(courseLevel) && (
                        option.value === service.degree.bachelor ||
                        option.value === service.degree.professionalCertification
                    )) ||
                    (service.isPcLevel(courseLevel) && option.value !== service.degree.professionalCertification);
            });
        };

        service.getCsvData = function (data) {
            var csvData = [];
            angular.forEach(data, function (item) {
                var row = [{
                    value : item.institutionName
                },{
                    value : item.total 
                }];
                csvData.push(row);
            });

            return csvData;
        };

        service.getClonedCourse = function (course) {
            delete course.id;
            delete course.coreId;
            delete course.createdAt; 
            delete course.createdBy; 
            delete course.createdByFullName; 
            delete course.createdByUserName; 
            delete course.published; 
            delete course.unpublished; 

            return course;
        };

        return service;
    }

}(window.angular));

(function(angular) {
    "use strict";

    angular
        .module('qsHub.profiles')
        .controller('CoursesOverviewController', [
            '$timeout',
            '$scope',
            '$state',
            '$location',
            'profiles.gridNamespaces',
            'uiGridConstants',
            'UiGridService',
            'uiGridExporterService',
            'CoursesService',
            'TuProfileSubjectsService',
            'TimeService',
            CoursesOverviewController
        ]);

    function CoursesOverviewController(
        $timeout,
        $scope,
        $state,
        $location,
        gridNamespaces,
        uiGridConstants,
        GridService,
        uiGridExporterService,
        CoursesService,
        TuProfileSubjectsService,
        TimeService
    ) {
        var controller = this;
        controller.gridOptions = {
            data: []
        };
        controller.selectedRowId = null;

        var paginationOptions = GridService.getColumnsFilters(gridNamespaces.filters.coursesOverview),
            columnsVisibility = GridService.getColumnsVisibility(gridNamespaces.visibility.coursesOverview),
            defaultPaginationOptions = {
                page: 1,
                limit: 25
            },
            isRendered = false,
            filterTimeout = null,
            loading = false,
            GridApi = null,
            filterChangesInProgress = false,
            subjectsList = [],
            degreesList = [],
            typesList = [];
        controller.isLoading = function () {
            return loading;
        };

        function handleDatagridRowClick(row) {
            return $location.path(
                $state.href('clients.profiles.tu.matchingTool').replace('#', '')
            ).search({
                coreId: row.institutionCoreId,
                courseId: row.coreId
            });
        }

        function getPage() {
            loading = true;
            return CoursesService.search(
                angular.merge({}, paginationOptions, defaultPaginationOptions)
            ).then(function (response) {
                controller.gridOptions.totalItems = response.totalMatching;
                controller.gridOptions.data = response.results;
                controller.gridOptions.minRowsToShow = response.totalFiltered;
                loading = false;
            });
        }

        function handleGridFiltersChanges() {
            filterChangesInProgress = true;
            if(GridApi.pagination.getPage() > 1){
                GridApi.pagination.seek(1);
            }
            paginationOptions = angular.copy(defaultPaginationOptions);
            var filterColumn;

            angular.forEach(GridApi.grid.columns, function (column) {
                filterColumn = column.filters && column.filters[0].column ? column.filters[0].column : column.field;
                if (typeof column.filters !== 'undefined' &&
                    column.filters[0].term !== 'undefined' &&
                    column.filters[0].term !== ''
                ) {
                    if (
                        column.filters[0].type === uiGridConstants.filter.SELECT &&
                        typeof column.filters[0].term === 'object' &&
                        column.filters[0].term !== null &&
                        typeof column.filters[0].term.value !== 'undefined'
                    ) {
                        column.filters[0].term = column.filters[0].term.value;
                    }

                    if (column.filters[0].term !== null && typeof column.filters[0].term !== 'undefined') {
                        paginationOptions['filter[' + filterColumn + ']'] = column.filters[0].term;
                    }
                }
            });

            GridService.storeColumnsFilters(gridNamespaces.filters.coursesOverview, paginationOptions);

            if (filterTimeout !== null) {
                $timeout.cancel(filterTimeout);
            }
            filterTimeout = $timeout(function () {
                getPage();
                filterChangesInProgress = false;
            }, 1000);
        }

        function handleGridVisibleChanges () {
            var storedColumnsVisibility = GridService.getColumnsVisibility(gridNamespaces.visibility.coursesOverview),
            reload = false;
            if (storedColumnsVisibility ) {
                angular.forEach(controller.gridOptions.columnDefs, function (column, key) {
                    if (storedColumnsVisibility[column.field] === true &&
                        column.visible === false
                    ) {
                        if (typeof  controller.gridOptions.columnDefs[key].filter === 'undefined') {
                            controller.gridOptions.columnDefs[key].filter = {};
                        }
                        if (typeof  controller.gridOptions.columnDefs[key].filter !== 'undefined' &&
                            controller.gridOptions.columnDefs[key].filter.hasOwnProperty('term') &&
                            controller.gridOptions.columnDefs[key].filter.term) {
                            paginationOptions['filter[' + column.field + ']'] = null;
                            reload = true;
                        }
                        controller.gridOptions.columnDefs[key].filter.term = null;
                    }
                });
            }

            if (controller.gridOptions) {
                controller.columnsVisibility = GridService.getGridColumnsVisibility(controller.gridOptions.columnDefs);
                    GridService.storeColumnsVisibility(
                        gridNamespaces.visibility.coursesOverview,
                        controller.columnsVisibility
                    );
            }
            if (reload) {
                getPage();
            }
        }

        function initWatches() {}

        function initDatagridOptions () {
            var selectTemplate = '/scripts/shared/ui-grid/templates/selectFilterHeaderTemplate.html',
                selectCellTemplate = '/scripts/shared/ui-grid/templates/selectCellTemplate.html',
                rowTemplate = '/scripts/shared/ui-grid/templates/rowTemplate.html',
                selectCountCellTemplate = '/scripts/shared/ui-grid/templates/selectCountCellTemplate.html';

            controller.gridOptions = angular.extend({}, controller.gridOptions, {
                appScopeProvider: controller,
                enableSorting: false,
                exporterMenuCsv: false,
                exporterMenuAllData: false,
                exporterMenuPdf: false,
                useExternalFiltering: true,
                enableGridMenu: true,
                showGridFooter: false,
                enableFiltering: true,
                enableColumnResize: true,
                enableFullRowSelection: true,
                enableRowSelection: true,
                multiSelect: false,
                enableRowHeaderSelection: false,
                paginationPageSizes: [25, 50, 100],
                paginationPageSize: 25,
                useExternalPagination: true,
                useExternalSorting: true,
                rowTemplate: rowTemplate,
                gridMenuCustomItems: [
                    {
                        title: 'Export totals per Institution as csv',
                        action: exportTotalsPerInstitution,
                        order: 209
                    }
                ],
                columnDefs: [
                    {
                        displayName: 'Id',
                        field: 'coreId',
                        visible: GridService.getVisibilityByField(columnsVisibility, 'coreId', true),
                        filter: {
                            term: GridService.getFilterByField(paginationOptions, 'coreId', ''),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        displayName: 'Name',
                        field: 'name',
                        visible: GridService.getVisibilityByField(columnsVisibility, 'name', true),
                        filter: {
                            term: GridService.getFilterByField(paginationOptions, 'name', ''),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        displayName: 'Institution Name',
                        field: 'institutionName',
                        visible: GridService.getVisibilityByField(columnsVisibility, 'institutionName', true),
                        filter: {
                            term: GridService.getFilterByField(paginationOptions, 'institutionName', ''),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        displayName: 'Institution Core Id',
                        field: 'institutionCoreIdAsString',
                        visible: GridService.getVisibilityByField(columnsVisibility, 'institutionCoreIdAsString', true),
                        filter: {
                            term: GridService.getFilterByField(paginationOptions, 'institutionCoreIdAsString', ''),
                            condition: uiGridConstants.filter.CONTAINS
                        }
                    },
                    {
                        displayName: 'Subject',
                        field: 'subject',
                        visible: GridService.getVisibilityByField(columnsVisibility, 'subject', true),
                        filter: {
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: subjectsList,
                            searchEnabled: true,
                            term: GridService.getFilterByField(paginationOptions, 'subject')
                        },
                        filterHeaderTemplate: selectTemplate,
                        cellTemplate: selectCellTemplate
                    },
                    {
                        displayName: 'Degree',
                        field: 'degree',
                        visible: GridService.getVisibilityByField(columnsVisibility, 'degree', true),
                        filter: {
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: degreesList,
                            searchEnabled: true,
                            term: GridService.getFilterByField(paginationOptions, 'degree')
                        },
                        filterHeaderTemplate: selectTemplate,
                        cellTemplate: selectCellTemplate
                    },
                    {
                        displayName: 'Type',
                        field: 'type',
                        visible: GridService.getVisibilityByField(columnsVisibility, 'type', true),
                        filter: {
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: typesList,
                            searchEnabled: true,
                            term: GridService.getFilterByField(paginationOptions, 'type')
                        },
                        filterHeaderTemplate: selectTemplate,
                        cellTemplate: selectCellTemplate
                    },
                    {
                        displayName: 'Country',
                        field: 'country',
                        visible: false,
                        enableHiding :false,
                        filter: {
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: [
                                {
                                    value: 'AU',
                                    label: 'Australia'
                                },
                                {
                                    value: 'UK',
                                    label: 'United Kingdom'
                                },
                                {
                                    value: 'US',
                                    label: 'USA'
                                },
                            ],
                            searchEnabled: true,
                            term: GridService.getFilterByField(paginationOptions, 'country')
                        },
                        filterHeaderTemplate: selectTemplate,
                        cellTemplate: selectCellTemplate
                    },
                    {
                        visible: false,
                        displayName: 'Has Campus',
                        field: 'campus',
                        filter: {
                            column: 'hasCampus',
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: [
                                { value: true, label: 'Yes' },
                                { value: false, label: 'No' },
                            ],
                            searchEnabled: true,
                            term: GridService.getFilterByField(paginationOptions, 'campus')
                        },
                        filterHeaderTemplate: selectTemplate,
                        cellTemplate: selectCountCellTemplate
                    },
                ],
                onRegisterApi: function (gridApi) {
                    GridApi = gridApi;

                    gridApi.selection.on.rowSelectionChanged($scope, function (row) {
                        handleDatagridRowClick(row.entity);
                    });

                    gridApi.core.on.rowsRendered($scope, function () {
                        isRendered = true;
                    });

                    gridApi.core.on.filterChanged($scope, handleGridFiltersChanges);
                    gridApi.core.on.rowsVisibleChanged($scope, handleGridVisibleChanges);
                    gridApi.pagination.on.paginationChanged($scope, function (newPage, limit) {
                        defaultPaginationOptions.page = newPage;
                        defaultPaginationOptions.limit = limit;
                        if(!filterChangesInProgress) { 
                            getPage();
                        }
                    });
                }
            });
        }

        function exportTotalsPerInstitution () {
            CoursesService.getTotalsPerInstitution().then(function (data) {
                    var results = data.results;
                    if (typeof results === 'undefined') {
                        return false;
                    }
                    var gridOptions = controller.gridOptions,
                        columnHeaders = [{
                            name: "institutionName",
                            displayName : "Institution Name"
                        },{
                            name: "total",
                            displayName : "Total Number Of Courses"
                        }];
        
                    var csvData = CoursesService.getCsvData(results);
                    var csvContent = uiGridExporterService.formatAsCsv(
                        columnHeaders,
                        csvData,
                        gridOptions.exporterCsvColumnSeparator
                    );
                    uiGridExporterService.downloadFile(
                        'total-courses-per-institution' + TimeService.now() + '.csv',
                        csvContent,
                        gridOptions.exporterOlderExcelCompatibility
                    );
            });
        }

        function initLists() {
            CoursesService.getDegrees().then(function (items) {
                angular.forEach(items, function (item) {
                    degreesList.push({
                        value: item.handle,
                        label: item.name
                    });
                });
            });

            CoursesService.getTypes().then(function (items) {
                angular.forEach(items, function (item) {
                    typesList.push({
                        value: item.handle,
                        label: item.name
                    });
                });
            });

            TuProfileSubjectsService.getSubjects().then(function (items) {
                angular.forEach(items, function (data) {
                    angular.forEach(data, function (item) {
                        subjectsList.push({
                            value: item.handle,
                            label: item.name,
                            groupName: item.groupName
                        });
                    });
                });
                getPage();
            });
        }

        function init() {
            initWatches();
            initDatagridOptions();
            initLists();
        }

        init();
    }

}(window.angular));

(function (angular) {
    "use strict";

    angular.module('qsHub.profiles').component('courseEdit', {
        templateUrl: '/scripts/modules/profiles/components/courses/courseEdit/courseEditView.html',
        controller: 'CourseEditController',
        bindings: {
            courseData: '='
        },
        bindToController: true,
    });

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.profiles')
        .controller('CourseEditController', [
            '$scope',
            '$timeout',
            '$filter',
            'constants',
            'CoursesService',
            'WatchService',
            'WebSocketsService',
            'ModalService',
            'NotifierFactory',
            'MatchingToolFactory',
            'CourseEditFactory',
            'UnsavedChangesFactory',
            '$controller',
            'TuProfileFactory',
            'TextService',
            CourseEdit
        ]);

    function CourseEdit(
        $scope,
        $timeout,
        $filter,
        constants,
        CoursesService,
        WatchService,
        WebSocketsService,
        ModalService,
        NotifierFactory,
        MatchingToolFactory,
        CourseEditFactory,
        UnsavedChangesFactory,
        $controller,
        TuProfileFactory,
        TextService
    ) {
        var controller = this,
            beforeChanges,
            updatedImages = [],
            updatedBrochure = null,
            imagesChanged = false,
            brocureChanged = false,
            subscribedFeeds = CourseEditFactory.getSubscribedFeeds();
            controller.course= angular.copy(controller.courseData);

        // instantiate base course controller
        $controller('CoursesBaseController', { controller: controller });

        controller.deleteInProgress = false;
        controller.fetchInProgress = false;

        function processCourseData(course) {
            controller.tmp = {
                tuitionFees: {},
                degreeLength: {}
            };
            controller.forms.course.$setPristine();
            beforeChanges = angular.copy(course);
            if (beforeChanges.tuitionFees && beforeChanges.tuitionFees.internationalStudents) {
                beforeChanges.tuitionFees.internationalStudents = parseFloat(beforeChanges.tuitionFees.internationalStudents);
            }
            if (beforeChanges.tuitionFees && beforeChanges.tuitionFees.domesticStudents) {
                beforeChanges.tuitionFees.domesticStudents = parseFloat(beforeChanges.tuitionFees.domesticStudents);
            }
            // reset validation
            controller.resetValidation();

            var tmp,
                item;
            if (course.campus) {
                controller.tmp.campus = [];
                angular.forEach(course.campus, function (campus) {
                    item = controller.getSelectItem(campus, controller.campusList);
                    if (item) {
                        controller.tmp.campus.push(item);
                    }
                });
            }
            if (course.courseLevel) {
                controller.tmp.courseLevel = controller.getSelectItem(course.courseLevel, controller.levelsList);
                CoursesService.disableDegrees(controller.degreesList, course.courseLevel);
                controller.setDegreesSubtypeList(course.courseLevel);
            }
            if (course.type) {
                controller.tmp.type = controller.getSelectItem(course.type, controller.typesList);
            }
            if (course.degree) {
                controller.tmp.degree = controller.getSelectItem(course.degree, controller.degreesList);
            }
            if (course.degreeSubtype) {
                controller.tmp.degreeSubtype = controller.getSelectItem(course.degreeSubtype, controller.degreeSubtypeList);
            }
            if (course.subject) {
                controller.tmp.subject = controller.getSelectItem(course.subject, controller.subjectsList);
            }
            if (course.customSubjects) {
                tmp = [];
                angular.forEach(course.customSubjects, function (subject) {
                    item = controller.getSelectItem(subject, controller.customSubjectsList);
                    if (item) {
                        tmp.push(item);
                    }
                });
                controller.tmp.customSubjects = tmp;
            }
            if (course.tuitionFees) {
                if (course.tuitionFees.currency) {
                    controller.tmp.tuitionFees.currency = controller.getSelectItem(course.tuitionFees.currency, controller.currencyList);
                }
                if (course.tuitionFees.internationalStudents) {
                    course.tuitionFees.internationalStudents = parseFloat(course.tuitionFees.internationalStudents);
                }
                if (course.tuitionFees.domesticStudents) {
                    course.tuitionFees.domesticStudents = parseFloat(course.tuitionFees.domesticStudents);
                }
            }
            if (course.degreeLength) {
                if (course.degreeLength.unit) {
                    controller.tmp.degreeLength.unit = controller.getSelectItem(course.degreeLength.unit, controller.degreeLengthList);
                }
            }
            if (course.entryRequirements) {
                controller.tmp.entryRequirements = {
                    firstTestType: {},
                    secondTestType: {},
                    thirdTestType: {}
                };
                if (course.entryRequirements.firstTestType && course.entryRequirements.firstTestType.type) {
                    controller.tmp.entryRequirements.firstTestType.type = controller.getSelectItem(course.entryRequirements.firstTestType.type, controller.testTypeList);
                    controller.handleFirstTestTypeChanges();
                }
                if (course.entryRequirements.secondTestType && course.entryRequirements.secondTestType.type) {
                    controller.tmp.entryRequirements.secondTestType.type = controller.getSelectItem(course.entryRequirements.secondTestType.type, controller.testTypeList);
                    controller.handleSecondTestTypeChanges();
                }
                if (course.entryRequirements.thirdTestType && course.entryRequirements.thirdTestType.type) {
                    controller.tmp.entryRequirements.thirdTestType.type = controller.getSelectItem(course.entryRequirements.thirdTestType.type, controller.testTypeList);
                    controller.handleThirdTestTypeChanges();
                }
            }

            controller.tmp.name = course.name;
            controller.isNameEdit = false;
        }

        function isFormDirtyWatch(isDirty) {
            if (isDirty) {
                checkForChanges(controller.course);
            }
        }

        function checkForChanges(oldCourse, isGridEvent) {
            if (beforeChanges && oldCourse && beforeChanges.id === oldCourse.id) {
                var before = MatchingToolFactory.isAddProgramClick() ?
                    angular.copy(controller.course) : angular.copy(beforeChanges),
                    a = angular.copy(before),
                    b = angular.copy(oldCourse);

                // remove tags from rich text editor fields
                angular.forEach([
                    'otherInformation',
                    'description',
                    'modules',
                    'introduction',
                    'admissionProcess',
                    'scholarships'
                ], function (field) {
                    a[field] = $filter('htmlToPlaintext')(a[field]);
                    b[field] = $filter('htmlToPlaintext')(b[field]);
                });
                if (a.entryRequirements && a.entryRequirements.other) {
                    a.entryRequirements.other = $filter('htmlToPlaintext')(a.entryRequirements.other);
                }
                if (b.entryRequirements && b.entryRequirements.other) {
                    b.entryRequirements.other = $filter('htmlToPlaintext')(b.entryRequirements.other);
                }
                $timeout (function () {
                    var pristine = true;
                    if (angular.equals(a, b)) {
                        pristine = isGridEvent ? true : !selectValuesHasChanges();
                    } else {
                        pristine = false;
                    }
                    if (pristine) {
                        if (controller.forms.course) {
                            controller.forms.course.$setPristine();
                        }
                    } else {
                        UnsavedChangesFactory.setChanges(true);
                    }
                });
            }
        }

        function unsavedCourseWatch(show) {
            if (show) {
                courseWatch(controller.course, beforeChanges);
                MatchingToolFactory.setUnsavedChanges(false);
            }
        }

        function selectValuesHasChanges() {
            var tmp = angular.copy(controller.tmp);

            if (tmp.name) {
                if (tmp.name !== controller.course.name) {
                    return true;
                }
            }

            if (tmp.campus && tmp.campus.value) {
                if (tmp.campus.value !== controller.course.campus) {
                    return true;
                }
            }
            if (tmp.courseLevel && tmp.courseLevel.value && controller.course.courseLevel) {
                if (tmp.courseLevel.value !== controller.course.courseLevel) {
                    return true;
                }
            }
            if (tmp.degree && tmp.degree.value) {
                if (tmp.degree.value !== controller.course.degree) {
                    return true;
                }
            }
            if (tmp.degreeLength &&
                tmp.degreeLength.unit &&
                tmp.degreeLength.unit.value &&
                controller.course.degreeLength
            ) {
                if (tmp.degreeLength.unit.value !== controller.course.degreeLength.unit) {
                    return true;
                }
            }
            if (tmp.degreeSubtype && tmp.degreeSubtype.value) {
                if (tmp.degreeSubtype.value !== controller.course.degreeSubtype) {
                    return true;
                }
            }
            if (tmp.entryRequirements &&
                tmp.entryRequirements.firstTestType &&
                tmp.entryRequirements.firstTestType.type &&
                tmp.entryRequirements.firstTestType.type.value &&
                controller.course.firstTestType
            ) {
                if (tmp.firstTestType.type.value !== controller.course.firstTestType.value) {
                    return true;
                }
            }
            if (tmp.entryRequirements &&
                tmp.entryRequirements.secondTestType &&
                tmp.entryRequirements.secondTestType.type &&
                tmp.entryRequirements.secondTestType.type.value &&
                controller.course.secondTestType
            ) {
                if (tmp.secondTestType.type.value !== controller.course.secondTestType.value) {
                    return true;
                }
            }
            if (tmp.entryRequirements &&
                tmp.entryRequirements.thirdTestType &&
                tmp.entryRequirements.thirdTestType.type &&
                tmp.entryRequirements.thirdTestType.type.value &&
                controller.course.thirdTestType
            ) {
                if (tmp.thirdTestType.type.value !== controller.course.thirdTestType.value) {
                    return true;
                }
            }
            if (tmp.subject && tmp.subject.value) {
                if (tmp.subject.value !== controller.course.subject) {
                    return true;
                }
            }
            if (tmp.tuitionFees &&
                tmp.tuitionFees.currency &&
                tmp.tuitionFees.currency.value &&
                controller.course.tuitionFees
            ) {
                if (tmp.tuitionFees.currency.value !== controller.course.tuitionFees.currency) {
                    return true;
                }
            }
            if (tmp.type && tmp.type.value) {
                if (tmp.type.value !== controller.course.type) {
                    return true;
                }
            }
            if (tmp.customSubjects &&
                angular.isArray(tmp.customSubjects) &&
                tmp.customSubjects.length > 0
            ) {
                if (controller.course.customSubjects && tmp.customSubjects.length !== controller.course.customSubjects.length) {
                    return true;
                }
                for (var i = 0; i < tmp.customSubjects.length; i++) {
                    if (controller.course.customSubjects.indexOf(tmp.customSubjects[i].value) === -1) {
                        return true;
                    }
                }
            }

            return false;
        }

        function courseWatch(course, oldCourse) {
            if (!course) {
                return false;
            }

            if (!UnsavedChangesFactory.hasChanges()) {
                checkForChanges(oldCourse, true);
            }

            if (beforeChanges &&
                oldCourse &&
                (MatchingToolFactory.isAddProgramClick() ||
                course.id !== oldCourse.id) &&
                UnsavedChangesFactory.hasChanges()
            ) {
                var modalOptions = {
                    handleNoClick: function () {
                        // close modal window
                        ModalService.close();

                        UnsavedChangesFactory.clearIsSaved();
                        UnsavedChangesFactory.resetChanges();
                        updatedImages = [];
                        updatedBrochure = null;
                        imagesChanged = false;
                        brocureChanged = false;

                        MatchingToolFactory.updateInDatagrid(oldCourse.id, beforeChanges);
                        processCourseData(course);

                        if (!MatchingToolFactory.getUnsavedRowEntity()) {
                            if (MatchingToolFactory.isAddProgramClick() ||
                                MatchingToolFactory.isAddProgramClick() === 'true'
                            ) {
                                TuProfileFactory.setMatchingToolRightSidePanelActive(false);
                                if (MatchingToolFactory.isAddProgramClick() !== 'true') {
                                    MatchingToolFactory.setOpenAddMode(true);
                                }
                                MatchingToolFactory.setIsAddProgramClick(false);
                            }
                        }
                    },
                    handleYesClick: function () {
                        var updateCourse = MatchingToolFactory.isAddProgramClick() ?
                            course : oldCourse;
                        updateCourse.images = imagesChanged? updatedImages : beforeChanges.images;
                        updateCourse.brochure = brocureChanged? updatedBrochure : beforeChanges.brochure;
                        // close modal window
                        ModalService.close();
                        controller.handleUpdate(updateCourse.id, updateCourse).then(function (result) {
                            if (MatchingToolFactory.isAddProgramClick() ||
                                MatchingToolFactory.isAddProgramClick() === 'true'
                            ) {
                                if (result) {
                                    TuProfileFactory.setMatchingToolRightSidePanelActive(false);
                                    // Open add mode or do nothing (if "x" is pressed)
                                    if (MatchingToolFactory.isAddProgramClick() !== 'true') {
                                        MatchingToolFactory.setOpenAddMode(true);
                                    }
                                }
                            } else {
                                if (!controller.courseUpdated) {
                                    UnsavedChangesFactory.setChanges(false);
                                    MatchingToolFactory.setSelectRowId(updateCourse.id);
                                    MatchingToolFactory.setUnsavedRowEntity(updateCourse);
                                    MatchingToolFactory.setTriggerRowClick(true);
                                } else {
                                     // update changes in datagrid
                                    MatchingToolFactory.updateInDatagrid(updateCourse.id, updateCourse);
                                    processCourseData(course);
                                }
                            }
                            MatchingToolFactory.setIsAddProgramClick(false);
                        });
                    }
                }, modalDefaults = {
                    backdrop: 'static',
                    keyboard: false,
                    modalFade: true,
                    templateUrl: '/scripts/shared/unsavedChanges/unsavedChangesView.html'
                };
                ModalService.show(modalDefaults, modalOptions);
                MatchingToolFactory.setUnsavedChanges(false);
            } else if(!beforeChanges || (beforeChanges && beforeChanges.id === oldCourse.id)) {
                processCourseData(course);
            }
        }

        function courseDataWatch() {
            controller.course = angular.copy(controller.courseData);
        }

        function brochureWatch (newBrochure) {
            if (newBrochure !== undefined &&
                !angular.equals(newBrochure, beforeChanges.brochure) &&
                beforeChanges.id === controller.course.id) {
                    if (!updatedBrochure) {
                        UnsavedChangesFactory.setChanges(true);
                    }
                    brocureChanged = true;
                    updatedBrochure = newBrochure;
            }
            else if (!controller.forms.course.$dirty){
                $timeout(function() {
                    UnsavedChangesFactory.setChanges(false);
                });
            }
        }
        function imagesWatch (newImages) {
            if (newImages !== undefined &&
                !angular.equals(newImages, beforeChanges.images) &&
                beforeChanges.id === controller.course.id) {
                    if (!updatedImages.length) {
                        UnsavedChangesFactory.setChanges(true);
                    }
                    imagesChanged = true;
                    updatedImages = newImages;
            }
            else if (!controller.forms.course.$dirty){
                $timeout(function() {
                    UnsavedChangesFactory.setChanges(false);
                });
            }
        }

        controller.initWatches = function() {
            WatchService.create($scope, '$ctrl.course', courseWatch);
            WatchService.create($scope, '$ctrl.courseData', courseDataWatch);
            WatchService.create($scope, '$ctrl.forms.course.$dirty', isFormDirtyWatch);
            WatchService.create($scope, MatchingToolFactory.isUnsavedDialog, unsavedCourseWatch);
            WatchService.create($scope, '$ctrl.course.brochure', brochureWatch);
            WatchService.create($scope, '$ctrl.course.images',imagesWatch,true);
        };

        controller.handleDeleteClick = function () {
            var modalOptions = {
                closeButtonText: 'Cancel',
                actionButtonText: 'Yes',
                headerText: 'Course Delete & Unpublish',
                bodyText: 'Are you sure you wish to delete & unpublish this course?',
            };
            ModalService.show({}, modalOptions).then(function () {
                CoursesService.unpublish(controller.course.coreId, true).then(function (PublishSuccess) {
                    NotifierFactory.show(
                        PublishSuccess ? 'success' : 'error',
                        PublishSuccess ? 'Unpublish is in progress!' : 'Requesting unpublish failed!',
                        'Course Unpublish'
                    );
                    if (!PublishSuccess) {
                        controller.unpublishInProgress = false;
                    }
                    if (PublishSuccess) {
                        $timeout(function() {
                            MatchingToolFactory.reloadDatagrid(true);
                            NotifierFactory.show(
                                PublishSuccess ? 'success' : 'error',
                                PublishSuccess ? 'Successfully!' : 'Failed!',
                                'Course Deleted'
                            );
                        }, 2100);
                    }
                });
            });
        };

        controller.cloneCourse = function () {
            MatchingToolFactory.setOpenAddMode(true);
            var ClonedCourse= CoursesService.getClonedCourse(angular.copy(controller.course));
            MatchingToolFactory.setNewCourseData(ClonedCourse);
            MatchingToolFactory.setTempCourseData(controller.tmp);
            MatchingToolFactory.setIsCourseCloned(true);
        };

        controller.isCloneDisabled = function () {
            return UnsavedChangesFactory.hasChanges();
        };

        controller.toggleNameEdit = function () {
            controller.isNameEdit = !controller.isNameEdit;
            if (!controller.isNameEdit) {
                controller.tmp.name = angular.copy(controller.course.name);
            }
        };

        controller.isUpdateAndPublishDisabled = function () {
            return controller.updateInProgress || controller.publishInProgress;
        };

        function cleanEditorHtml() {
            controller.course.introduction = TextService.cleanHtmlTags(controller.course.introduction);
            controller.course.modules = TextService.cleanHtmlTags(controller.course.modules);
            controller.course.scholarships = TextService.cleanHtmlTags(controller.course.scholarships);
        }

        controller.handleUpdate = function (id, data, publish) {
            cleanEditorHtml();
            var updateInDatagrid = false,
            nameBeforeChanges = angular.copy(controller.course.name);
            controller.updateInProgress = true;
            controller.courseUpdated = false;

            if (controller.isNameEdit) {
                if (data) {
                    data.name = controller.tmp.name;
                } else {
                    controller.course.name = controller.tmp.name;
                }
            }

            // validate data
            if (!controller.isValid(data || controller.course)) {
                controller.updateInProgress = false;
                controller.publishInProgress = false;
                NotifierFactory.show(
                    'error',
                    'Some fields don\'t pass validation. Please correct the fields and try again',
                    'Course Update'
                );
                controller.course.name = nameBeforeChanges;
                return CoursesService.mockPromise(false);
            }

            return CoursesService.replace(
                id || controller.course.id,
                data || controller.course,
                publish || false
            ).then(function (success) {
                NotifierFactory.show(
                    success ? 'success' : 'error',
                    success ? 'Successfully updated!' : 'Update failed!',
                    'Course Update'
                );
                if (success) {
                    updatedImages = [];
                    updatedBrochure = null;
                    brocureChanged = false;
                    imagesChanged = false;
                    controller.courseUpdated = true;
                    controller.isNameEdit = false;
                    CourseEditFactory.setUpdate(true);
                    if (success.brochureUrl) {
                        if (!id) {
                            controller.course.brochure.url = success.brochureUrl;
                            CourseEditFactory.setBrochuresUpdate(true);
                        } else {
                            updateInDatagrid = true;
                            data.brochure.url = success.brochureUrl;
                        }
                    }
                    if (success.images) {
                        if (!id) {
                            controller.course.images = success.images;
                            CourseEditFactory.setImagesUpdate(true);
                        } else {
                            updateInDatagrid = true;
                            data.images = success.images;
                        }
                    }
                    if (updateInDatagrid) {
                        MatchingToolFactory.updateInDatagrid(id, data);
                    } else {
                        MatchingToolFactory.updateInDatagrid(controller.course.id, controller.course);
                        beforeChanges = angular.copy(controller.course);
                    }
                    if (controller.forms && controller.forms.course) {
                        controller.forms.course.$setPristine();
                    }
                    UnsavedChangesFactory.setChanges(false);
                    controller.updateInProgress = false;
                    controller.publishInProgress = false;
                    $timeout(function(){
                        MatchingToolFactory.reloadDatagrid(true);
                    });

                    return CoursesService.mockPromise(true);
                } else {
                    controller.courseUpdated = false;
                    controller.updateInProgress = false;
                    MatchingToolFactory.setIsAddProgramClick(false);
                    NotifierFactory.show(
                        'error',
                        'Some fields don\'t pass validation. Please correct the fields and try again',
                        'Course Update'
                    );
                    return CoursesService.mockPromise(false);
                }
            });
        };

        function saveUnsavedChanges() {
            controller.handleUpdate().then(function (result) {
                if (result) {
                    UnsavedChangesFactory.setIsSaved();
                } else {
                    UnsavedChangesFactory.setSaveError();
                    $timeout(function() {
                        angular.element('#mt-tab a').triggerHandler('click');
                    });
                }
            });
        }

        controller.handleUpdateAndPublish = function () {
            if (subscribedFeeds.indexOf(controller.course.id) !== -1) {
                controller.handleUpdate(null, null, true);
            } else {
                controller.updateInProgress = true;
                controller.publishInProgress = true;
                WebSocketsService.subscribe(controller.course.id, 'PublishStatus' + controller.course.id, function (result) {
                    if (result.status === constants.publishStatus.failure) {
                        NotifierFactory.show(
                            'error',
                            'Course "' + result.data.courseName + '" publishing failed',
                            'Course Publish'
                        );
                        controller.publishInProgress = false;
                    } else if (result.status === constants.publishStatus.success) {
                        NotifierFactory.show(
                            'success',
                            'Course "' + result.data.courseName + '" successfully published',
                            'Course Publish'
                        );
                        // update in the datagrid
                        if (TuProfileFactory.isMatchingToolRightSidePanelActive()) {
                            MatchingToolFactory.setIsSelectRow(true);
                            MatchingToolFactory.setRowToSelect(controller.course.id);
                        }
                        MatchingToolFactory.reloadDatagrid(true);
                        controller.publishInProgress = false;
                    }
                    controller.updateInProgress = false;
                }, function (subscribed) {
                    if (!subscribed) {
                        NotifierFactory.show(
                            'error',
                            'Experiencing issues with publishing. Please try again later',
                            'Course Publish'
                        );
                        controller.publishInProgress = false;
                    } else {
                        subscribedFeeds.push(controller.course.id);
                        CourseEditFactory.setSubscribedFeeds(subscribedFeeds);
                    }
                    controller.handleUpdate(null, null, subscribed);
                });
            }
        };

        controller.$onInit = function () {
            controller.initLists();
            if (!controller.isClient) {
                controller.sections.push({
                    name: 'Meta tags / SEO',
                    open: false,
                    template: 'seoTemplate.html'
                });
            }
            UnsavedChangesFactory.setSaveFunction(saveUnsavedChanges, $scope);
        };
    }

}(window.angular));

(function(angular) {
    "use strict";

    angular
        .module('qsHub.messages')
        .factory('CourseEditFactory', [
            CourseEdit
        ]);

    function CourseEdit() {
        var brochuresChanges = false,
            imagesChanges = false,
            courseChanges = false,
            subscribedFeeds = [];

        return {
            isBrochuresUpdate: function () {
                return brochuresChanges;
            },
            isImagesUpdate: function () {
                return imagesChanges;
            },
            setBrochuresUpdate: function (isUpdate) {
                brochuresChanges = isUpdate;
            },
            setImagesUpdate: function (isUpdate) {
                imagesChanges = isUpdate;
            },
            setUpdate: function (isUpdate) {
                courseChanges = isUpdate;
            },
            isUpdate: function () {
                return courseChanges;
            },
            setSubscribedFeeds : function (newSubscribedFeeds) {
                subscribedFeeds = newSubscribedFeeds;
            },
            getSubscribedFeeds : function () {
                return subscribedFeeds;
            }
        };
    }

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.profiles')
        .controller('CoursesBaseController', [
            'controller',
            'CoursesService',
            'CampusesService',
            'TuProfileSubjectsService',
            'InstitutionFactory',
            'UrlService',
            'TextService',
            'TuProfileFactory',
            '$timeout',
            'UserFactory',
            'MatchingToolFactory',
            'UnsavedChangesFactory',
            BaseCourse
        ]);

    function BaseCourse(
        controller,
        CoursesService,
        CampusesService,
        TuProfileSubjectsService,
        InstitutionFactory,
        UrlService,
        TextService,
        TuProfileFactory,
        $timeout,
        UserFactory,
        MatchingToolFactory,
        UnsavedChangesFactory
    ) {
        controller.sections = [
            {
                handle: 'overview',
                name: 'Program Overview',
                open: true,
                template: 'overviewTemplate.html'
            },
            {
                handle: 'description',
                name: 'Program Description',
                open: false,
                template: 'descriptionTemplate.html'
            },
            {
                handle: 'length',
                name: 'Program Length',
                open: false,
                template: 'lengthTemplate.html'
            },
            {
                handle: 'address',
                name: 'Program Address',
                open: false,
                template: 'addressTemplate.html'
            },
            {
                handle: 'tuitionFees',
                name: 'Tuition Fees',
                open: false,
                template: 'tuitionFeesTemplate.html'
            },
            {
                handle: 'admissionProcess',
                name: 'Admission Process',
                open: false,
                template: 'admissionProcessTemplate.html'
            },
            {
                handle: 'entryRequirements',
                name: 'Entry Requirements',
                open: false,
                template: 'entryRequirementsTemplate.html'
            },
            {
                handle: 'admissionContact',
                name: 'Program URL',
                open: false,
                template: 'admissionContactTemplate.html'
            },
            {
                handle: 'media',
                name: 'Media',
                open: false,
                template: 'mediaTemplate.html'
            }
        ];

        controller.textEditorOptions = {
            disableDragAndDrop: true,
            styleWithSpan: false,
            dialogsInBody: true,
            height: 280,
            //minHeight: 280,
            //maxHeight: 280,
            toolbar: [
                ['style', ['bold', 'italic', 'underline', 'superscript', 'subscript', 'strikethrough']],
                ['alignment', ['ul', 'ol']],
                ['edit', ['undo', 'redo']],
                ['view', ['codeview']]
            ],
            popover: {
                link: []
            }
        };
        controller.isNameEdit = false;
        controller.degreesList = [];
        controller.degreeLengthList = [];
        controller.degreeSubtypeList = [];
        controller.ugSubDegreesList = [];
        controller.pgSubDegreesList = [];
        controller.typesList = [];
        controller.levelsList = [];
        controller.subjectsList = [];
        controller.customSubjectsList = [];
        controller.campusList = [];
        controller.currencyList = [];
        controller.monthsList = [];
        controller.tmp = {
            tuitionFees: {},
            degreeLength: {}
        };
        controller.isClient = UserFactory.isClient();
        controller.isAllTabsExpanded = false;
        controller.updateInProgress = false;
        controller.publishInProgress = false;
        controller.minFirst = 0;
        controller.maxFirst = null;
        controller.minSecond = 0;
        controller.maxSecond = null;
        controller.minThird = 0;
        controller.maxThird = null;
        controller.courseFormName = controller.course.id? 'course' : 'newCourse';
        var leftToLoad = 10;

        controller.getSelectItem = function(value, list) {
            for (var i = 0; i < list.length; i++) {
                if (list[i].value === value) {
                    return list[i];
                }
            }
            return null;
        };

        function resetDegreeSubtype () {
            controller.course.degreeSubtype = null;
            controller.tmp.degreeSubtype = null;
        }

        controller.setDegreesSubtypeList = function(level) {
            if (CoursesService.isUgLevel(level)) {
                controller.degreeSubtypeList = controller.ugSubDegreesList;
            } else if (CoursesService.isPgLevel(level)) {
                controller.degreeSubtypeList = controller.pgSubDegreesList;
            } else {
                controller.degreeSubtypeList = [];
            }
        };

        controller.handleDegreeChanges = function () {
            controller.course.degree = controller.tmp.degree.value;
        };

        controller.handleLevelChanges = function () {
            controller.course.courseLevel = controller.tmp.courseLevel.value;
            resetDegreeSubtype();

            // disable depending dropdowns options
            CoursesService.disableDegrees(controller.degreesList, controller.course.courseLevel);

            if (CoursesService.isUgLevel(controller.course.courseLevel)) {
                controller.tmp.degree = controller.getSelectItem(CoursesService.degree.bachelor, controller.degreesList);
                controller.course.degree = angular.copy(controller.tmp.degree.value);
                controller.setDegreesSubtypeList(controller.course.courseLevel);
                controller.isInvalidDegree = false;
            } else if (CoursesService.isPcLevel(controller.course.courseLevel)) {
                controller.tmp.degree = controller.getSelectItem(CoursesService.degree.professionalCertification, controller.degreesList);
                controller.course.degree = angular.copy(controller.tmp.degree.value);
                controller.isInvalidDegree = false;
            } else if (CoursesService.isPgLevel(controller.course.courseLevel)) {
                controller.tmp.degree = null;
                controller.course.degree = null;
                controller.setDegreesSubtypeList(controller.course.courseLevel);
            }
        };

        controller.handleCampusChanges = function () {
            var values = [];
            angular.forEach(controller.tmp.campus, function (selectedItem) {
                values.push(selectedItem.value);
            });
            controller.course.campus = values;
        };

        controller.handleCampusRemoved = function (item) {
            if (controller.course.campus) {
                controller.course.campus.splice(controller.course.campus.indexOf(item.value), 1);
            }
        };

        controller.handleTypeChanges = function () {
            controller.course.type = controller.tmp.type.value;
        };

        controller.handleDegreeSubtypeChanges = function () {
            controller.course.degreeSubtype = controller.tmp.degreeSubtype ? controller.tmp.degreeSubtype.value : null;
        };

        controller.handleDegreeLengthNumericChanges = function () {
            if (!controller.course.degreeLength || angular.isArray(controller.course.degreeLength)) {
                controller.course.degreeLength = {};
            }
        };

        controller.handleDegreeLengthUnitChanges = function () {
            controller.isInvalidDegreeLengthUnit = false;
            if (!controller.course.degreeLength || angular.isArray(controller.course.degreeLength)) {
                controller.course.degreeLength = {};
            }
            if (controller.tmp.degreeLength && controller.tmp.degreeLength.unit) {
                controller.course.degreeLength.unit = controller.tmp.degreeLength.unit.value;
            }
        };

        controller.handleDegreeLengthCustomChanges = function () {
            if (!controller.course.degreeLength || angular.isArray(controller.course.degreeLength)) {
                controller.course.degreeLength = {};
            }
            controller.course.degreeLength.custom = controller.course.degreeLength.custom;

        };

        controller.handleFirstTestTypeChanges = function () {
            controller.course.entryRequirements.firstTestType.type = controller.tmp.entryRequirements.firstTestType.type.value;
            controller.minFirst = 0;
            controller.maxFirst = null;
            if (controller.course.entryRequirements.firstTestType.type === "stat") {
                controller.minFirst = 100;
                controller.maxFirst = 200;
            }
            if (controller.course.entryRequirements.firstTestType.type === "atar") {
                controller.maxFirst = 100;
            }
        };

        controller.handleSecondTestTypeChanges = function () {
            controller.course.entryRequirements.secondTestType.type = controller.tmp.entryRequirements.secondTestType.type.value;
            controller.minSecond = 0;
            controller.maxSecond = null;
            if (controller.course.entryRequirements.secondTestType.type === "stat") {
                controller.minSecond = 100;
                controller.maxSecond = 200;
            }
            if (controller.course.entryRequirements.secondTestType.type === "atar") {
                controller.maxSecond = 100;
            }
        };

        controller.handleThirdTestTypeChanges = function () {
            controller.course.entryRequirements.thirdTestType.type = controller.tmp.entryRequirements.thirdTestType.type.value;
            controller.minThird = 0;
            controller.maxThird = null;
            if (controller.course.entryRequirements.thirdTestType.type === "stat") {
                controller.minThird = 100;
                controller.maxThird = 200;
            }
            if (controller.course.entryRequirements.thirdTestType.type === "atar") {
                controller.maxThird = 100;
            }
        };

        controller.handleCurrencyChanges = function () {
            controller.course.tuitionFees.currency = controller.tmp.tuitionFees.currency.value;
        };

        controller.handleSubjectChanges = function () {
            controller.course.subject = controller.tmp.subject ? controller.tmp.subject.value : null;
            angular.forEach(controller.customSubjectsList, function (option) {
                option.disabled = option.value === controller.course.subject;
            });
        };

        controller.toggleTabs = function () {
            controller.isAllTabsExpanded = !controller.isAllTabsExpanded;
            controller.sections.map(function (item){
                item.open = !!controller.isAllTabsExpanded;
            });
        };

        controller.handleCustomSubjectsChanges = function () {
            var values = [];
            angular.forEach(controller.tmp.customSubjects, function (selectedItem) {
                values.push(selectedItem.value);
            });
            controller.course.customSubjects = values;
            angular.forEach(controller.subjectsList, function (option) {
                option.disabled = values.indexOf(option.value) !== -1;
            });
        };

        controller.handleCustomSubjectRemoved = function (item) {
            delete controller.course.customSubjects[controller.course.customSubjects.indexOf(item.value)];
        };

        controller.onKeyUp = function ($event) {
            if ($event.keyCode === 13) {
                return;
            }
            var url = $event.target.value;

            if (url && url.length > 5 && (url.indexOf('http') !== 0 || url.indexOf('http') === -1)) {
                controller.course.url = UrlService.prependHttp(url);
            }
        };

        controller.openSection = function(section) {
            var total = controller.sections.length,
                i = 0;
            for (i; i < total; i++) {
                if (controller.sections[i].handle === section) {
                    controller.sections[i].open = true;
                    return;
                }
            }
        };

        controller.resetValidation = function() {
            controller.isInvalidName = false;
            controller.isInvalidCourseLevel = false;
            controller.isInvalidType = false;
            controller.isInvalidDegree = false;
            controller.isInvalidSubject = false;
            controller.isInvalidDegreeLength = false;
            controller.isInvalidDegreeLengthUnit = false;
            controller.isInvalidDegreeCustomLength =false;
            controller.isInvalidCampus = false;
            controller.isInvalidTuitionFeesInternationalStudents = false;
            controller.isInvalidTuitionFeesCurrency = false;
            controller.isInvalidTuitionFeesDomesticStudents = false;
            controller.isInvalidAdmissionProcess = false;
            controller.isInvalidStartDate = false;
            controller.isInvalidSelectOneToPublish = false;
            controller.isInvalidUgAndPgEntryRequirements = false;
            controller.isInvalidUgEntryRequirements = false;
            controller.isInvalidPgEntryRequirements = false;
        };

        controller.handleKeyDown = function ($event) {
            if ($event.keyCode === 69) {
                $event.preventDefault();
                return false;
            }
            return true;
        };

        controller.handleInvalidNumber = function ($event) {
            if ($event.keyCode === 69 || $event.keyCode === 189 || $event.keyCode === 109) {
                $event.preventDefault();
                return false;
            }
            return true;
        };

        controller.isValidEntryReq = function(field) {
            if (typeof controller.course.entryRequirements[field] !== "undefined" &&
                typeof controller.course.entryRequirements[field].type !== "undefined" &&
                ( controller.course.entryRequirements[field].type === "op" ||
                controller.course.entryRequirements[field].type === "qtac" )
            ) {
                return false;
            }
            return typeof controller.course.entryRequirements[field] !== "undefined" &&
                controller.course.entryRequirements[field].value !== null &&
                controller.course.entryRequirements[field].maximumExpected !== null &&
                controller.course.entryRequirements[field].value >= controller.course.entryRequirements[field].maximumExpected;
        };

        controller.isValid = function(data) {
            if (!data) {
                return false;
            }
            controller.isInvalidName = !data.name || !data.name.length;
            controller.isInvalidCourseLevel = !data.courseLevel || !data.courseLevel.length;
            controller.isInvalidType = !data.type || !data.type.length;
            controller.isInvalidDegree = !data.degree || !data.degree.length;
            controller.isInvalidSubject = !data.subject || !data.subject.length;
            controller.isInvalidCampus = !data.campus || !data.campus.length || (controller.tmp && controller.tmp.campus.length === 0) ;
            controller.isInvalidTuitionFeesInternationalStudents = !data.tuitionFees  || (!data.tuitionFees.domesticStudents && !data.tuitionFees.internationalStudents);
            controller.isInvalidTuitionFeesCurrency = !data.tuitionFees || !data.tuitionFees.currency || !data.tuitionFees.currency.length;
            controller.isInvalidTuitionFeesDomesticStudents = !data.tuitionFees  || ( !data.tuitionFees.internationalStudents && !data.tuitionFees.domesticStudents);
            controller.isInvalidStartDate = !data.startDate || !data.startDate.length || data.startDate === '0';
            controller.isInvalidSelectOneToPublish = !(data.degreeLength && data.degreeLength.publish);
            if (data.degreeLength) {
                controller.isInvalidDegreeLength = !data.degreeLength.numeric &&
                                                    (controller.isInvalidSelectOneToPublish || data.degreeLength.publish === "numeric");
                controller.isInvalidDegreeLengthUnit =  (!data.degreeLength.unit || !data.degreeLength.unit.length) &&
                                                        (controller.isInvalidSelectOneToPublish || data.degreeLength.publish === "numeric");
                controller.isInvalidDegreeCustomLength = (!data.degreeLength.custom || !data.degreeLength.custom.length) &&
                                                         (controller.isInvalidSelectOneToPublish || data.degreeLength.publish === "custom");
            }
            else {
                controller.isInvalidDegreeLength = true;
                controller.isInvalidDegreeCustomLength = true;
                controller.isInvalidDegreeLengthUnit =  true;
            }
            if(data.courseLevel){
                controller.isInvalidUgAndPgEntryRequirements = controller.forms[controller.courseFormName] &&
                    (controller.forms[controller.courseFormName].hasOwnProperty('gpaMin') && controller.forms[controller.courseFormName].gpaMin.$invalid ||
                    controller.forms[controller.courseFormName].hasOwnProperty('gpaMax') && controller.forms[controller.courseFormName].gpaMax.$invalid ||
                    controller.forms[controller.courseFormName].hasOwnProperty('ieltsMin') && controller.forms[controller.courseFormName].ieltsMin.$invalid ||
                    controller.forms[controller.courseFormName].hasOwnProperty('ieltsMax') && controller.forms[controller.courseFormName].ieltsMax.$invalid ||
                    controller.forms[controller.courseFormName].hasOwnProperty('toeflMin') && controller.forms[controller.courseFormName].toeflMin.$invalid ||
                    controller.forms[controller.courseFormName].hasOwnProperty('toeflMax') && controller.forms[controller.courseFormName].toeflMax.$invalid ||
                    controller.forms[controller.courseFormName].hasOwnProperty('cambridgeMin') && controller.forms[controller.courseFormName].cambridgeMin.$invalid ||
                    controller.forms[controller.courseFormName].hasOwnProperty('cambridgeMax') && controller.forms[controller.courseFormName].cambridgeMax.$invalid ||
                    controller.forms[controller.courseFormName].hasOwnProperty('pteAcademicMin') && controller.forms[controller.courseFormName].pteAcademicMin.$invalid ||
                    controller.forms[controller.courseFormName].hasOwnProperty('pteAcademicMax') && controller.forms[controller.courseFormName].pteAcademicMax.$invalid ||
                    controller.isValidEntryReq('ielts') ||
                    controller.isValidEntryReq('toefl') ||
                    controller.isValidEntryReq('bachelorGpa'));
                if (data.courseLevel === 'ug') {
                    controller.isInvalidUgEntryRequirements = controller.forms[controller.courseFormName] &&
                        (controller.forms[controller.courseFormName].hasOwnProperty('satMin') && controller.forms[controller.courseFormName].satMin.$invalid ||
                        controller.forms[controller.courseFormName].hasOwnProperty('satMax') && controller.forms[controller.courseFormName].satMax.$invalid ||
                        controller.isValidEntryReq('sat') ||
                        controller.forms[controller.courseFormName].hasOwnProperty('firstTestMin') && controller.forms[controller.courseFormName].firstTestMin.$invalid ||
                        controller.forms[controller.courseFormName].hasOwnProperty('firstTestMax') && controller.forms[controller.courseFormName].firstTestMax.$invalid ||
                        controller.forms[controller.courseFormName].hasOwnProperty('secondTestMin') && controller.forms[controller.courseFormName].secondTestMin.$invalid ||
                        controller.forms[controller.courseFormName].hasOwnProperty('secondTestMax') && controller.forms[controller.courseFormName].secondTestMax.$invalid ||
                        controller.forms[controller.courseFormName].hasOwnProperty('thirdTestMin') && controller.forms[controller.courseFormName].thirdTestMin.$invalid ||
                        controller.forms[controller.courseFormName].hasOwnProperty('thirdTestMax') && controller.forms[controller.courseFormName].thirdTestMax.$invalid ||
                        controller.forms[controller.courseFormName].hasOwnProperty('aLevelsMin') && controller.forms[controller.courseFormName].aLevelsMin.$invalid ||
                        controller.forms[controller.courseFormName].hasOwnProperty('aLevelsMax') && controller.forms[controller.courseFormName].aLevelsMax.$invalid ||
                        controller.forms[controller.courseFormName].hasOwnProperty('ucasTariffMin') && controller.forms[controller.courseFormName].ucasTariffMin.$invalid ||
                        controller.forms[controller.courseFormName].hasOwnProperty('ucasTariffMax') && controller.forms[controller.courseFormName].ucasTariffMax.$invalid ||
                        controller.forms[controller.courseFormName].hasOwnProperty('asLevelsMin') && controller.forms[controller.courseFormName].asLevelsMin.$invalid ||
                        controller.forms[controller.courseFormName].hasOwnProperty('asLevelsMax') && controller.forms[controller.courseFormName].asLevelsMax.$invalid ||
                        controller.forms[controller.courseFormName].hasOwnProperty('sqaHighersMin') && controller.forms[controller.courseFormName].sqaHighersMin.$invalid ||
                        controller.forms[controller.courseFormName].hasOwnProperty('sqaHighersMax') && controller.forms[controller.courseFormName].sqaHighersMax.$invalid ||
                        controller.forms[controller.courseFormName].hasOwnProperty('sqaAdvancedMin') && controller.forms[controller.courseFormName].sqaAdvancedMin.$invalid ||
                        controller.forms[controller.courseFormName].hasOwnProperty('sqaAdvancedMax') && controller.forms[controller.courseFormName].sqaAdvancedMax.$invalid ||
                        controller.forms[controller.courseFormName].hasOwnProperty('btecQualificationsMin') && controller.forms[controller.courseFormName].btecQualificationsMin.$invalid ||
                        controller.forms[controller.courseFormName].hasOwnProperty('btecQualificationsMax') && controller.forms[controller.courseFormName].btecQualificationsMax.$invalid ||
                        controller.forms[controller.courseFormName].hasOwnProperty('ownAdmissionMin') && controller.forms[controller.courseFormName].ownAdmissionMin.$invalid ||
                        controller.forms[controller.courseFormName].hasOwnProperty('ownAdmissionMax') && controller.forms[controller.courseFormName].ownAdmissionMax.$invalid ||
                        controller.forms[controller.courseFormName].hasOwnProperty('internationalBaccalaureateMin') && controller.forms[controller.courseFormName].internationalBaccalaureateMin.$invalid ||
                        controller.forms[controller.courseFormName].hasOwnProperty('internationalBaccalaureateMax') && controller.forms[controller.courseFormName].internationalBaccalaureateMax.$invalid ||
                        controller.forms[controller.courseFormName].hasOwnProperty('yearTwelveScoresMin') && controller.forms[controller.courseFormName].yearTwelveScoresMin.$invalid ||
                        controller.forms[controller.courseFormName].hasOwnProperty('yearTwelveScoresMax') && controller.forms[controller.courseFormName].yearTwelveScoresMax.$invalid ||
                        controller.isValidEntryReq('firstTestType') ||
                        controller.isValidEntryReq('secondTestType') ||
                        controller.isValidEntryReq('thirdTestType'));
                }
                if (data.courseLevel === 'pg') {
                    controller.isInvalidPgEntryRequirements = controller.forms[controller.courseFormName] &&
                        (controller.forms[controller.courseFormName].hasOwnProperty('greMin') && controller.forms[controller.courseFormName].greMin.$invalid ||
                        controller.forms[controller.courseFormName].hasOwnProperty('greMax') && controller.forms[controller.courseFormName].greMax.$invalid ||
                        controller.forms[controller.courseFormName].hasOwnProperty('gmatMin') && controller.forms[controller.courseFormName].gmatMin.$invalid ||
                        controller.forms[controller.courseFormName].hasOwnProperty('gmatMax') && controller.forms[controller.courseFormName].gmatMax.$invalid ||
                        controller.forms[controller.courseFormName].hasOwnProperty('workExperienceMin') && controller.forms[controller.courseFormName].workExperienceMin.$invalid ||
                        controller.forms[controller.courseFormName].hasOwnProperty('workExperienceMax') && controller.forms[controller.courseFormName].workExperienceMax.$invalid ||
                        controller.forms[controller.courseFormName].hasOwnProperty('weightedAverageMin') && controller.forms[controller.courseFormName].weightedAverageMin.$invalid ||
                        controller.forms[controller.courseFormName].hasOwnProperty('weightedAverageMax') && controller.forms[controller.courseFormName].weightedAverageMax.$invalid);
                }
            }

            // expand sections with errors
            if (controller.isInvalidCourseLevel || controller.isInvalidType || controller.isInvalidDegree ||
                controller.isInvalidSubject
            ) {
                controller.openSection('overview');
            }
            if (controller.isInvalidDegreeLength || controller.isInvalidSelectOneToPublish) {
                controller.openSection('length');
            }
            if (controller.isInvalidDegreeLengthUnit) {
                controller.openSection('length');
            }
            if (controller.isInvalidDegreeCustomLength) {
                controller.openSection('length');
            }
            if (controller.isInvalidCampus) {
                controller.openSection('address');
            }
            if (controller.isInvalidTuitionFeesInternationalStudents || controller.isInvalidTuitionFeesCurrency ||
                controller.isInvalidTuitionFeesDomesticStudents
            ) {
                controller.openSection('tuitionFees');
            }
            if (controller.isInvalidStartDate) {
                controller.openSection('admissionProcess');
            }
            if (controller.isInvalidUgAndPgEntryRequirements || controller.isInvalidUgEntryRequirements ||
                controller.isInvalidPgEntryRequirements) {
                controller.openSection('entryRequirements');
            }
            if (controller.isInvalidName) {
                controller.isNameEdit = true;
            }

            return !controller.isInvalidName &&
                !controller.isInvalidCourseLevel &&
                !controller.isInvalidType &&
                !controller.isInvalidDegree &&
                !controller.isInvalidSubject &&
                !controller.isInvalidDegreeLength &&
                !controller.isInvalidDegreeCustomLength &&
                !controller.isInvalidDegreeLengthUnit &&
                !controller.isInvalidCampus &&
                !controller.isInvalidTuitionFeesInternationalStudents &&
                !controller.isInvalidTuitionFeesDomesticStudents &&
                !controller.isInvalidTuitionFeesCurrency &&
                !controller.isInvalidAdmissionProcess &&
                !controller.isInvalidStartDate &&
                !controller.isInvalidSelectOneToPublish &&
                !controller.isInvalidUgAndPgEntryRequirements &&
                !controller.isInvalidPgEntryRequirements &&
                !controller.isInvalidUgEntryRequirements;
        };

        controller.cancel = function () {
            MatchingToolFactory.setIsAddProgramClick('true');
            if (UnsavedChangesFactory.hasChanges()) {
                MatchingToolFactory.setUnsavedChanges(true);
            } else {
                TuProfileFactory.setMatchingToolRightSidePanelActive(false);
            }
        };

        controller.isUpdateAndPublishDisabled = function () {
            return controller.course.massPublish || controller.updateInProgress;
        };

        controller.handleClipboardPaste = function (field) {
            //@todo check if contains dot
            if (!field || !controller.course[field]) {
                return false;
            }
            $timeout(function () {
                controller.course[field] = TextService.clearAttributes(controller.course[field]);
            }, 50);
        };

        controller.ackLoad = function() {
            leftToLoad--;
            if (!controller.isLoading()) {
                controller.initWatches();
            }
        };

        controller.isLoading = function () {
            return leftToLoad !== 0;
        };

        controller.initLists = function() {
            CoursesService.getLevels().then(function (items) {
                angular.forEach(items, function (item) {
                    controller.levelsList.push({
                        value: item.handle,
                        label: item.name
                    });
                });
                controller.ackLoad();
            });

            CoursesService.getDegrees().then(function (items) {
                angular.forEach(items, function (item) {
                    controller.degreesList.push({
                        value: item.handle,
                        label: item.name
                    });
                });
                controller.ackLoad();
            });

            CoursesService.getTypes().then(function (items) {
                angular.forEach(items, function (item) {
                    controller.typesList.push({
                        value: item.handle,
                        label: item.name
                    });
                });
                controller.ackLoad();
            });

            TuProfileSubjectsService.getSubjects().then(function (items) {
                angular.forEach(items, function (data) {
                    angular.forEach(data, function (item) {
                        controller.subjectsList.push({
                            value: item.handle,
                            label: item.name,
                            groupName: item.groupName
                        });
                    });
                });
                controller.customSubjectsList = angular.copy(controller.subjectsList);
                controller.ackLoad();
            });

            CoursesService.getDegreeLengthUnits().then(function (list) {
                controller.degreeLengthList = list;
                controller.ackLoad();
            });

            CoursesService.getSubDegrees(true).then(function (list) {
                var items = CoursesService.filterUgSubDegrees(list);
                angular.forEach(items, function (item) {
                    controller.ugSubDegreesList.push({
                        value: item.handle,
                        label: item.name
                    });
                });

                items = CoursesService.filterPgSubDegrees(list);
                angular.forEach(items, function (item) {
                    controller.pgSubDegreesList.push({
                        value: item.handle,
                        label: item.name
                    });
                });

                controller.ackLoad();
            });

            CampusesService.getCampusList({
                totalEntries: null,
                total: function () {},
                parameters : function () {
                    return {
                        coreId: InstitutionFactory.getCoreId()
                    };
                }
            }).then(function (list) {
                angular.forEach(list, function (item) {
                    controller.campusList.push({
                        value: item.id,
                        label: item.name
                    });
                });
                controller.ackLoad();
            });

            CoursesService.getCurrencies().then(function (list) {
                controller.currencyList = list;
                controller.ackLoad();
            });

            CoursesService.getTestTypes().then(function (list) {
                controller.testTypeList = list;
                controller.ackLoad();
            });

            CoursesService.getMonths().then(function (list) {
                controller.monthsList = list;
                controller.ackLoad();
            });
        };
    }

}(window.angular));

(function (angular) {
    "use strict";

    angular.module('qsHub.profiles').component('courseAdd', {
        templateUrl: '/scripts/modules/profiles/components/courses/courseAdd/courseAddView.html',
        controller: 'CourseAddController',
        bindings: {
            courseData: '='
        },
        bindToController: true
    });

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.profiles')
        .controller('CourseAddController', [
            '$scope',
            '$timeout',
            'constants',
            'InstitutionFactory',
            'CoursesService',
            'WebSocketsService',
            'NotifierFactory',
            'MatchingToolFactory',
            'CourseEditFactory',
            'UnsavedChangesFactory',
            '$controller',
            'WatchService',
            'ModalService',
            'TuProfileFactory',
            CourseAdd
        ]);

    function CourseAdd(
        $scope,
        $timeout,
        constants,
        InstitutionFactory,
        CoursesService,
        WebSocketsService,
        NotifierFactory,
        MatchingToolFactory,
        CourseEditFactory,
        UnsavedChangesFactory,
        $controller,
        WatchService,
        ModalService,
        TuProfileFactory
    ) {
        var controller = this,
            beforeChanges = {
                tuitionFees: {},
                entryRequirements : {
                    firstTestType: {},
                    secondTestType: {},
                    thirdTestType: {}
                },
                brochure: null,
                images :[],
                introduction:""
            };
            controller.course= angular.copy(controller.courseData);

        // instantiate base course controller
        $controller('CoursesBaseController', { controller: controller });

        controller.deleteInProgress = false;
        controller.fetchInProgress = false;
        controller.isExternalEvent = false;

        function isFormDirtyWatch(isDirty) {
            if (isDirty) {
                if (JSON.stringify(beforeChanges) !==
                JSON.stringify(controller.course) &&
                !UnsavedChangesFactory.hasChanges()
            ) {
                UnsavedChangesFactory.setChanges(true);
            }
            }
        }

        function handleClonedCourse () {
            if(MatchingToolFactory.isAddProgramClick()){
                controller.course = angular.copy(MatchingToolFactory.getNewCourseData());
            }
            else {
                TuProfileFactory.setMatchingToolRightSidePanelActive(false);
                MatchingToolFactory.setIsAddProgramClick(false);
            }
            controller.tmp = null;
            MatchingToolFactory.setTempCourseData(null);
            MatchingToolFactory.setIsCourseCloned(false);
        }

        function courseWatch(show) {
            if (show) {
                var modalOptions = {
                    handleNoClick: function () {
                        // close modal window
                        MatchingToolFactory.setNewCourseData({
                            tuitionFees:{},
                            entryRequirements : {
                                firstTestType: {},
                                secondTestType: {},
                                thirdTestType: {}
                            },
                            brochure: null,
                            images :[]
                        });
                        controller.resetValidation();
                        UnsavedChangesFactory.resetChanges();
                        ModalService.close();
                        if (MatchingToolFactory.getUnsavedRowEntity()) {
                            MatchingToolFactory.setTriggerRowClick(true);
                        } else {
                            if(MatchingToolFactory.getIsCourseCloned()) { 
                                handleClonedCourse();
                               
                            }
                            else if (MatchingToolFactory.isAddProgramClick()) {
                                TuProfileFactory.setMatchingToolRightSidePanelActive(false);
                                MatchingToolFactory.setIsAddProgramClick(false);
                            }
                        }
                    },
                    handleYesClick: function () {
                        controller.handleAdd().then(function () {
                            // close modal window
                            ModalService.close();
                            if (MatchingToolFactory.getUnsavedRowEntity()) {
                                MatchingToolFactory.setTriggerRowClick(true);
                            }
                        });
                    }
                }, modalDefaults = {
                    backdrop: 'static',
                    keyboard: false,
                    modalFade: true,
                    templateUrl: '/scripts/shared/unsavedChanges/unsavedChangesView.html'
                };
                ModalService.show(modalDefaults, modalOptions);
                MatchingToolFactory.setUnsavedChanges(false);
            }
        }

        function brochureWatch (newBrochure) {
            if (newBrochure !== undefined && newBrochure !== null && !angular.equals(newBrochure, beforeChanges.brochure)) {
                UnsavedChangesFactory.setChanges(true);
                
            }
            else if (!controller.forms.newCourse.$dirty && !controller.tmp){
                UnsavedChangesFactory.setChanges(false);
            }
        }

        function imagesWatch (newImages) {
            if (newImages !== undefined && newImages !== null && !angular.equals(newImages, beforeChanges.images)) {
                UnsavedChangesFactory.setChanges(true);
            }
            else if (!controller.forms.newCourse.$dirty && !controller.tmp){
                UnsavedChangesFactory.setChanges(false);
            }
        }

        controller.initWatches = function() {
            WatchService.create($scope, MatchingToolFactory.isUnsavedDialog, courseWatch);
            WatchService.create($scope, '$ctrl.forms.newCourse.$dirty', isFormDirtyWatch);
            WatchService.create($scope, '$ctrl.course.brochure', brochureWatch);
            WatchService.create($scope, '$ctrl.course.images',imagesWatch,true);
        };

        controller.handleAdd = function (publish) {
            controller.resetValidation();
            // validate data
            if (!controller.isValid(controller.course)) {
                controller.updateInProgress = false;
                NotifierFactory.show(
                    'error',
                    'Some fields don\'t pass validation. Please correct the fields and try again',
                    'Course Update'
                );
                MatchingToolFactory.setUnsavedRowEntity(null);

                return CoursesService.mockPromise(false);
            }
            controller.updateInProgress = true;
            controller.course.institutionCoreId = InstitutionFactory.getCoreId();

            //@todo validation in https://jira5.qsu-dev.com:8443/browse/QCR-2958
            return CoursesService.add(
                controller.course,
                publish || false
            ).then(function (success) {
                NotifierFactory.show(
                    success ? 'success' : 'error',
                    success ? 'Successfully Added!' : 'Add failed!',
                    'Course Add'
                );
                if (success) {
                    if (success.brochureUrl) {
                        controller.course.brochure.url = success.brochureUrl;
                        CourseEditFactory.setBrochuresUpdate(true);
                    }
                    if (success.images) {
                        controller.course.images = success.images;
                        CourseEditFactory.setImagesUpdate(true);
                    }
                    if (success.insertedId) {
                        controller.course.id = success.insertedId;
                    }
                    if (publish) {
                        controller.handleAddAndPublish();
                    }
                    if (!controller.isExternalEvent && !MatchingToolFactory.getIsCourseCloned()) {
                        MatchingToolFactory.reloadDatagrid(true);
                    }
                    if (!MatchingToolFactory.getUnsavedRowEntity()) {
                        
                        if (!controller.isExternalEvent) {
                            MatchingToolFactory.setIsSelectRow(true);
                            MatchingToolFactory.setRowToSelect(controller.course.id);
                        }
                        controller.isExternalEvent = false;
                    } else {
                        MatchingToolFactory.setTriggerRowClick(true);
                    }
                    MatchingToolFactory.setNewCourseData({
                        tuitionFees:{},
                        entryRequirements : {
                            firstTestType: {},
                            secondTestType: {},
                            thirdTestType: {}
                        },
                        brochure: null,
                        images :[]
                    });
                    if(!MatchingToolFactory.getIsCourseCloned()) { 
                        MatchingToolFactory.setIsAddProgramClick(false);
                    }
                    if(MatchingToolFactory.getIsCourseCloned()) {
                        $timeout(function () {
                            MatchingToolFactory.reloadDatagrid(true);
                            handleClonedCourse();
                        });
                    }
                    controller.resetValidation();
                }
                if (controller.forms && controller.forms.newCourse) {
                    controller.forms.newCourse.$setPristine();
                    controller.forms.newCourse.$setUntouched();
                }
                UnsavedChangesFactory.setChanges(false);
                controller.updateInProgress = false;

                if (success) {
                    return CoursesService.mockPromise(true);
                }

                return CoursesService.mockPromise(false);
            });
        };

        function saveUnsavedChanges() {
            controller.isExternalEvent = true;
            controller.handleAdd().then(function (result) {
                if (result) {
                    UnsavedChangesFactory.setIsSaved();
                } else {
                    UnsavedChangesFactory.setSaveError();
                    $timeout(function() {
                        angular.element('#mt-tab a').triggerHandler('click');
                    });
                }
            });
        }

        controller.handleAddAndPublish = function () {
            WebSocketsService.subscribe(controller.course.id, 'PublishStatus' + controller.course.id, function (result) {
                if (result.status === constants.publishStatus.failure) {
                    NotifierFactory.show(
                        'error',
                        result.statusMessage || 'Publishing failed',
                        'Course Publish'
                    );
                } else if (result.status === constants.publishStatus.success) {
                    NotifierFactory.show(
                        'success',
                        result.statusMessage || 'Successfully published',
                        'Course Publish'
                    );
                }
                controller.updateInProgress = false;
            }, function (subscribed) {
                if (!subscribed) {
                    NotifierFactory.show(
                        'error',
                        'Experiencing issues with publishing. Please try again later',
                        'Course Publish'
                    );
                } else {
                    // call to publish course endpoint
                    CoursesService.publish(
                        controller.course.id
                    ).then(function (success) {
                        if (!success) {
                            NotifierFactory.show(
                                'error',
                                'Experiencing issues with publishing. Please try again later',
                                'Course Publish'
                            );
                        } else {
                            NotifierFactory.show(
                                'success',
                                'Successfully added to the publishing queue',
                                'Course Publish'
                            );
                        }
                    });
                }
            });
        };

        controller.$onInit = function () {
            controller.tmp = angular.copy(MatchingToolFactory.getTempCourseData());
            if(controller.tmp) {
                if (controller.tmp.courseLevel) {
                    controller.setDegreesSubtypeList(controller.tmp.courseLevel.value);
                }
                UnsavedChangesFactory.setChanges(true);
                MatchingToolFactory.setIsAddProgramClick(false);
            }
            controller.initLists();
            UnsavedChangesFactory.setSaveFunction(saveUnsavedChanges, $scope);
            MatchingToolFactory.setNewCourseData({
                tuitionFees:{},
                entryRequirements : {
                    firstTestType: {},
                    secondTestType: {},
                    thirdTestType: {}
                }
            });
        };
    }

}(window.angular));

(function(angular) {
    "use strict";

    angular
        .module('qsHub.messages')
        .factory('CourseAddFactory', [
            CourseAdd
        ]);

    function CourseAdd() {
        var brochuresChanges = false,
            imagesChanges = false;

        return {
            isBrochuresUpdate: function () {
                return brochuresChanges;
            },
            isImagesUpdate: function () {
                return imagesChanges;
            },
            setBrochuresUpdate: function (isUpdate) {
                brochuresChanges = isUpdate;
            },
            setImagesUpdate: function (isUpdate) {
                imagesChanges = isUpdate;
            }
        };
    }

}(window.angular));

(function (angular) {
    "use strict";

    angular.module('qsHub.profiles').component('fetchMetaTags', {
        templateUrl: '/scripts/modules/profiles/components/courses/fetchMetaTags/fetchMetaTagsView.html',
        controller: 'FetchMetaTagsController',
        bindings: {
            course: '=',
            fetchInProgress: '='
        },
        bindToController: true,
    });

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.profiles')
        .controller('FetchMetaTagsController', [
            'FetchMetaTagsService',
            'NotifierFactory',
            FetchMetaTags
        ]);

    function FetchMetaTags(
        FetchMetaTagsService,
        NotifierFactory
    ) {
        var controller = this;

        controller.isValidUrl = function () {
            return controller.course.url && controller.course.url.length > 0;
        };

        controller.handleFetchMetaTags = function () {
            if (controller.isFetchMetaTagsDisabled()) {
                return false;
            }
            controller.fetchInProgress = true;
            FetchMetaTagsService.fetch(controller.course.id, controller.course.url).then(function (response) {
                if (response.success) {
                    controller.course.seo = {
                        title: response.title,
                        keywords: response.keywords,
                        description: response.description
                    };
                }
                NotifierFactory.show(
                    response.success ? 'success' : 'error',
                    response.success ? 'Meta tags were successfully fetched' : 'Meta tags were unsuccessfully fetched',
                    'Meta tags'
                );
                controller.fetchInProgress = false;
            });
        };

        controller.isFetchMetaTagsDisabled = function () {
            return !controller.isValidUrl() || controller.fetchInProgress;
        };
    }

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.rankings')
        .service('FetchMetaTagsService', [
            '$q',
            'WebSocketsService',
            FetchMetaTags
        ]);

    function FetchMetaTags(
        $q,
        WebSocketsService
    ) {
        var service = {
            deferred: {}
        };

        service.fetch = function (id, url) {
            if (!id || !url || url.length < 3) {
                throw 'Missing Required';
            }
            var deferred = $q.defer();

            // subscribe to URLs checks
            WebSocketsService.subscribe('MetaTags', 'get', function (result) {
                if (result && result.success) {
                    deferred.resolve({
                        success: true,
                        title: result.title,
                        keywords: result.keywords,
                        description: result.description
                    });
                } else {
                    deferred.resolve({
                        success: false
                    });
                }
            }, function (subscribed) {
                if (!subscribed) {
                    deferred.resolve({
                        success: false,
                        error: 'Can\'t connect to URL checker'
                    });
                } else {
                    WebSocketsService.addToQueue('MetaTags', {
                        id: id,
                        url: url
                    }, {
                        expiration: 60000 * 60 * 24,
                        priority: 1
                    }, {
                        maxPriority: 10,
                        durable: true,
                        autoDelete: false
                    }).then(function (done) {
                        if (!done) {
                            deferred.resolve({
                                success: false,
                                error: 'Can\'t process ' + url
                            });
                        }
                    });
                }
            });

            return deferred.promise;
        };

        return service;
    }

}(window.angular));

(function (angular) {
    "use strict";

    angular.module('qsHub.profiles').component('courseImages', {
        templateUrl: '/scripts/modules/profiles/components/courses/courseImages/courseImagesView.html',
        controller: 'CourseImagesController',
        bindings: {
            course: '='
        },
        bindToController: true,
    });

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.profiles')
        .controller('CourseImagesController', [
            'constants',
            '$scope',
            'AuthenticationService',
            'WatchService',
            'NotifierFactory',
            'CourseEditFactory',
            CourseImages
        ]);

    function CourseImages(
        constants,
        $scope,
        AuthenticationService,
        WatchService,
        NotifierFactory,
        CourseEditFactory
    ) {
        var controller = this,
            maxFileSize = 0.4,
            maxFiles = 4,
            itemsBeforeChanges;

        controller.dropZoneInstance = null;
        controller.config = {
            // http://www.dropzonejs.com/#configuration-options
            dropzone: {
                url: getUploadUrl(),
                maxFilesize: maxFileSize,
                parallelUploads: 1,
                autoProcessQueue: true,
                addRemoveLinks: false,
                previewsContainer: null,
                clickable: '.upload-image',
                init: function () {
                    controller.dropZoneInstance = this;
                },
                acceptedFiles: 'image/jpeg,image/pjpeg,image/jpeg,image/pjpeg',
                dictFileTooBig: 'Uploaded file is greater than the limit. Please upload less than 400KB.',
                dictMaxFilesExceeded: 'Allowed files limit reached. Please delete a brochure and try again',
                dictInvalidFileType: 'Uploaded image has incorrect format. Please upload images in jpg, jpeg format.',
                headers: AuthenticationService.getAuthorizationHeader()
            },
            // http://www.dropzonejs.com/#event-list
            eventHandlers: {
                success: handleSuccess,
                error: handleError,
                addedfile: handleAddedFile
            }
        };

        function getUploadUrl() {
            return constants.api.institutions.url + '/v1/course/' + controller.course.id + '/image';
        }

        function handleAddedFile()
        {
            $scope.$apply(function() {
                controller.uploadInProgress = true;
            });
        }

        function handleSuccess(file, response) {
            if (!response || !response.status) {
                handleError(file, response);
                return;
            }
            $scope.$apply(function() {
                controller.items = !angular.isDefined(controller.items) || !controller.items ? [] : controller.items;
                controller.items.push({
                    url: response.path,
                    thumbnail: null
                });
                controller.course.images = angular.copy(controller.items);
                NotifierFactory.show(
                    'warning',
                    'File successfully uploaded. Don\'t forget to save changes.',
                    'Image upload'
                );
                controller.uploadInProgress = false;
            });
        }

        function handleError(file, response) {
            // remove previously uploaded image from dropzone instance
            controller.dropZoneInstance.removeAllFiles();
            var message = response;
            if (file.accepted && file.status === 'error') {
                message = 'Upload error, please try again later';
            }
            if (response.status === 'error') {
                message = response.message;
            }
            if (file.size > maxFileSize * 1000 * 1000) {
                message = controller.config.dropzone.dictFileTooBig;
            }
            NotifierFactory.show(
                'error',
                message
            );
            $scope.$apply(function() {
                controller.uploadInProgress = false;
            });
        }

        function setDefaults() {
            controller.items = [];
            controller.uploadInProgress = false;
            controller.uploadEnabled = true;
            controller.isEdit = false;
        }

        controller.isUploadDisabled = function () {
            return controller.uploadInProgress || (controller.items && controller.items.length >= maxFiles);
        };

        controller.handleDelete = function (index) {
            controller.items.splice(index, 1);
            controller.course.images.splice(index, 1);
        };

        function copyItems() {
            controller.items = controller.course.images ? angular.copy(controller.course.images) : [];
            itemsBeforeChanges = angular.copy(controller.course.images);
        }

        function courseWatch(course, oldCourse) {
            setDefaults();
            if (oldCourse && course.id !== oldCourse.id && itemsBeforeChanges) {
                // reset unsaved changes
                oldCourse.images = angular.copy(itemsBeforeChanges);
            }
            copyItems();
        }

        function updateWatch(is) {
            if (!is) {
                return false;
            }
            CourseEditFactory.setImagesUpdate(false);
            if (controller.course.images && controller.items) {
                copyItems();
            }
        }

        function initWatches() {
            WatchService.create($scope, '$ctrl.course', courseWatch);
            WatchService.create($scope, CourseEditFactory.isImagesUpdate, updateWatch);
        }

        controller.$onInit = function () {
            setDefaults();
            initWatches();
        };
    }

}(window.angular));

(function (angular) {
    "use strict";

    angular.module('qsHub.profiles').component('courseBrochures', {
        templateUrl: '/scripts/modules/profiles/components/courses/courseBrochures/courseBrochuresView.html',
        controller: 'CourseBrochuresController',
        bindings: {
            course: '='
        },
        bindToController: true,
    });

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.profiles')
        .controller('CourseBrochuresController', [
            '$scope',
            'constants',
            'AuthenticationService',
            'WatchService',
            'NotifierFactory',
            'CourseEditFactory',
            CourseBrochures
        ]);

    function CourseBrochures(
        $scope,
        constants,
        AuthenticationService,
        WatchService,
        NotifierFactory,
        CourseEditFactory
    ) {
        var controller = this,
            maxFileSize = 1,
            titleBeforeChanges = null,
            itemsBeforeChanges;

        controller.dropZoneInstance = null;
        controller.config = {
            // http://www.dropzonejs.com/#configuration-options
            dropzone: {
                url: getUploadUrl(),
                maxFilesize: maxFileSize,
                parallelUploads: 1,
                autoProcessQueue: true,
                addRemoveLinks: false,
                previewsContainer: null,
                clickable: '.upload-brochure',
                paramName: 'file',
                init: function () {
                    controller.dropZoneInstance = this;
                },
                acceptedFiles: 'application/pdf, application/x-pdf',
                dictFileTooBig: 'Uploaded file is greater than the limit. Please upload less than 1MB.',
                dictMaxFilesExceeded: 'Allowed files limit reached. Please delete a brochure and try again',
                dictInvalidFileType: 'Uploaded brochure has incorrect format. Please upload images in pdf format.',
                headers: AuthenticationService.getAuthorizationHeader()
            },
            // http://www.dropzonejs.com/#event-list
            eventHandlers: {
                success: handleSuccess,
                error: handleError
            }
        };

        function setDefaults() {
            controller.item = {};
            controller.uploadInProgress = false;
            controller.isEdit = false;
        }

        controller.handleEdit = function () {
            controller.isEdit = true;
            titleBeforeChanges = angular.copy(controller.course.brochure.title);
        };

        controller.handleCancel = function () {
            controller.isEdit = false;
            controller.course.brochure.title = angular.copy(titleBeforeChanges);
        };

        controller.isUploadDisabled = function () {
            return controller.isEdit || controller.uploadInProgress || (controller.item && controller.item.url);
        };

        controller.handleDelete = function () {
            controller.course.brochure = null;
            setDefaults();
        };

        function getUploadUrl() {
            return constants.api.institutions.url + '/v1/course/' + controller.course.id + '/brochure';
        }

        function handleSuccess(file, response) {
            controller.uploadInProgress = true;
            $scope.$apply(function() {
                if (!response || !response.status) {
                    handleError();
                } else {
                    controller.item = {
                        url: response.path,
                        title: null
                    };
                    controller.course.brochure = angular.copy(controller.item);
                    NotifierFactory.show(
                        'warning',
                        'File successfully uploaded. Don\'t forget to save changes.',
                        'Brochure upload'
                    );
                    controller.uploadInProgress = false;
                }
            });
        }

        function handleError(file, response) {
            // remove previously uploaded image from dropzone instance
            controller.dropZoneInstance.removeAllFiles();
            var message = response;
            if (file.accepted && file.status === 'error') {
                message = 'Upload error, please try again later';
            }
            if (response.status === 'error') {
                message = response.message;
            }
            if (file.size > maxFileSize * 1000 * 1000) {
                message = controller.config.dropzone.dictFileTooBig;
            }
            NotifierFactory.show(
                'error',
                message
            );
            $scope.$apply();
        }

        function courseWatch(course, oldCourse) {
            setDefaults();
            if (oldCourse && course.id !== oldCourse.id && itemsBeforeChanges) {
                // reset unsaved changes
                oldCourse.brochure = angular.copy(itemsBeforeChanges);
            }

            controller.item = angular.copy(course.brochure);
            itemsBeforeChanges = angular.copy(course.brochure);
        }

        function updateWatch(is) {
            if (!is) {
                return false;
            }
            controller.isEdit = false;
            CourseEditFactory.setUpdate(false);
            if (CourseEditFactory.isBrochuresUpdate()) {
                CourseEditFactory.setBrochuresUpdate(false);
                if (controller.course.brochure.url && controller.item) {
                    controller.item.url = controller.course.brochure.url;
                }
                if (controller.course.brochure.title && controller.item) {
                    controller.item.title = controller.course.brochure.title;
                }
            }
        }

        function initWatches() {
            WatchService.create($scope, '$ctrl.course', courseWatch);
            WatchService.create($scope, CourseEditFactory.isUpdate, updateWatch);
        }

        controller.$onInit = function () {
            setDefaults();
            initWatches();
        };
    }

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.rankings')
        .service('CourseBrochuresService', [
            'constants',
            '$resource',
            'RequestsQueueService',
            courseBrochures
        ]);

    function courseBrochures(
        constants,
        $resource,
        RequestsQueueService
    ) {
        var service = {
            deferred: {}
        };

        function getModel() {
            return $resource(constants.api.institutions.url, {}, {
                delete: {
                    method: 'DELETE',
                    url: constants.api.institutions.url + '/v1/course/:id/brochure',
                    isArray: false,
                    cancellable: true,
                },
            });
        }

        service.delete = function (id) {
            if (!id) {
                throw 'Missing Required';
            }
            var key = 'CourseBrochuresService:delete',
                Api;
            RequestsQueueService.cancelAll(key);
            Api = getModel().delete({
                id: id
            });
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                return data && data.modifiedCount === 1;
            }, function () {
                return false;
            });
        };

        service.isTemporary = function (url) {
            return url.indexOf('/tmp/') !== -1;
        };

        return service;
    }

}(window.angular));

(function (angular) {
    "use strict";

    angular.module('qsHub.profiles').component('courseUnpublish', {
        templateUrl: '/scripts/modules/profiles/components/courses/courseUnpublish/courseUnpublishView.html',
        controller: 'CourseUnpublishController',
        bindings: {
            isClient: '<',
            course: '='
        },
        bindToController: true,
    });

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.profiles')
        .controller('CourseUnpublishController', [
            '$q',
            'CoursesService',
            'WebSocketsService',
            'NotifierFactory',
            'MatchingToolFactory',
            'CourseUnpublishFactory',
            'ModalService',
            CourseUnpublish
        ]);

    function CourseUnpublish(
        $q,
        CoursesService,
        WebSocketsService,
        NotifierFactory,
        MatchingToolFactory,
        CourseUnpublishFactory,
        ModalService
    ) {
        var controller = this;

        controller.unpublishInProgress = false;

        controller.handleUnpublishClick = function () {
            ModalService.show({
                backdrop: 'static',
                keyboard: false,
                modalFade: true,
                templateUrl: '/scripts/modules/profiles/components/courses/courseUnpublish/courseUnpublishConfirmationView.html',
            }, {
                handleNoClick: handleNoClick,
                handleYesClick: handleYesClick
            });
        };

        function handleNoClick() {
            ModalService.close();
        }

        function handleYesClick() {
            controller.unpublishInProgress = true;
            //subscribe to the unpublish status via websockets
            subscribe().then(function (success) {
                if (!success) {
                    controller.unpublishInProgress = false;
                    return NotifierFactory.show(
                        'error',
                        'Can\'t subscribe to unpublish status!',
                        'Course Unpublish'
                    );
                }

                CoursesService.unpublish(controller.course.coreId).then(function (success) {
                    NotifierFactory.show(
                        success ? 'success' : 'error',
                        success ? 'Unpublish is in progress!' : 'Requesting unpublish failed!',
                        'Course Unpublish'
                    );
                    if (!success) {
                        controller.unpublishInProgress = false;
                    }
                });
            });
            handleNoClick();
        }

        function handleUnpublishStatus(response, room) {
            if (response && response.status) {
                var success = null;
                if (response.status === 'success') {
                    success = true;
                    MatchingToolFactory.reloadDatagrid(true);

                } else if (response.status === 'failure') {
                    success = false;
                }

                if (success !== null) {
                    controller.unpublishInProgress = false;
                    NotifierFactory.show(
                        success ? 'success' : 'error',
                        success ? '"Program has been successfully unpublished!' : 'Unpublish failed - ' + response.error || 'unknown',
                        'Course Unpublish'
                    );
                }
            }
        }

        function subscribe() {
            var deferred = $q.defer(),
                key = 'course' + controller.course.coreId;
            if (CourseUnpublishFactory.isSubscribed(key)) {
                deferred.resolve(true);
            } else {
                WebSocketsService.subscribe(
                    'course' + controller.course.coreId,
                    'unpublishStatus' + controller.course.coreId,
                    handleUnpublishStatus,
                    function (subscribed) {
                        CourseUnpublishFactory.setSubscribed(key, true);
                        if (!subscribed) {
                            return deferred.resolve(false);
                        } else {
                            return deferred.resolve(true);
                        }
                    });
            }

            return deferred.promise;
        }

        controller.isUnpublishAllowed = function () {
            return !controller.isClient && controller.course.published;
        };
    }

}(window.angular));

(function(angular) {
    "use strict";

    angular
        .module('qsHub.messages')
        .factory('CourseUnpublishFactory', [
            CourseUnpublish
        ]);

    function CourseUnpublish() {
        var subscriptions = [];

        return {
            setSubscribed: function (subscription, subscribed) {
                subscriptions[subscription] = subscribed;
            },
            isSubscribed: function (subscription) {
                return subscriptions && subscriptions[subscription];
            }
        };
    }

}(window.angular));

(function (angular) {
    'use strict';
    angular
        .module('qsHub').directive('scrollingCourse', [
        '$timeout',
        'WatchService',
        ScrollingCourse
    ]);

    function ScrollingCourse($timeout, WatchService) {
        return {
            restrict: 'A',
            link: function ($scope, $element, $attrs) {

                var timeout,
                    firstRun = true,
                    oldWindowScroll = 0,
                    oldElementHeight = 0;

                /**
                 * Actions to do when floatable element visibility changes.
                 *
                 * @param {int} elementHeight
                 * @returns {boolean}
                 */
                function handleVisibilityChanges() {
                    //set a timeout so we don't run calculations too many times
                    if (timeout) {
                        $timeout.cancel(timeout);
                    }
                    timeout = $timeout(function () {
                        // attach scroll listener
                        $(window).scroll(handleWindowScroll);
                        centerElement();
                    }, 200);

                }

                /**
                 * Actions to do when window scroll occurs.
                 */
                function handleWindowScroll() {
                    centerElement();
                }

                /**
                 * Center floatable element in the middle of the screen.
                 */
                function centerElement() {

                    var targetHeight = angular.element(document.querySelector($attrs.scrollingCourse)).height();
                    var elementHeight = $element.height(),
                        windowScroll = $(window).scrollTop(),
                        windowHeight = $(window).height(),
                        targetElementTopOffset = $($attrs.scrollingCourse).offset() ?
                            $($attrs.scrollingCourse).offset().top : 0;

                    var mobile = false;
                    var marginTop;
                    if (windowScroll > windowHeight) {
                        marginTop = (windowHeight - elementHeight) / 2 + windowScroll - targetElementTopOffset;
                    }
                    if (windowScroll < windowHeight) {
                        marginTop = (windowScroll - targetElementTopOffset) + ((windowHeight - elementHeight) / 2);
                    }

                    if ($(window).width() < 1200) {
                        mobile = true;
                    } else {
                        if (windowScroll === 0 || windowScroll < targetElementTopOffset) {
                            marginTop = 0;
                        }
                        if (targetHeight < (elementHeight + marginTop)) {
                            marginTop = targetHeight - elementHeight;
                        }
                        if (marginTop < 0 || targetHeight === elementHeight) {
                            marginTop = 0;
                        }
                    }

                    if (mobile) {
                        marginTop = 0;
                        $($element).css('position', 'static');
                    } else {
                        $($element).css('position', 'relative');
                    }

                    if (($scope.$eval($attrs.ngShow) || $scope.$eval($attrs.ngIf)) ||
                        ($scope.$eval($attrs.ngShow) === undefined  &&  $scope.$eval($attrs.ngIf) === undefined) ) {

                        if (elementHeight > windowHeight && oldWindowScroll !== windowScroll) {
                            //@todo refactor as its very bad implementation
                            var margin = 200,
                                cssSelector = $attrs.firstSelector,
                                cssSelector2 = $attrs.secondSelector,
                                topOfTitle = $(cssSelector).offset().top - margin,
                                bottomOfTitle = $(cssSelector).offset().top + $(cssSelector).outerHeight() - margin,
                                topOfBtnRow = $(cssSelector2).offset().top + margin,
                                bottomOfBtnRow = $(cssSelector2).offset().top + $(cssSelector2).outerHeight() + margin,
                                bottomOfScreen = $(window).scrollTop() + window.innerHeight,
                                topOfScreen = $(window).scrollTop();
                            if (windowScroll > oldWindowScroll && marginTop > 0) {
                                if (!((bottomOfScreen > topOfBtnRow) && (topOfScreen < bottomOfBtnRow))) {
                                    return;
                                }
                            } else {
                                if(!((bottomOfScreen > topOfTitle) && (topOfScreen < bottomOfTitle)) && oldElementHeight === elementHeight) {
                                    return;
                                }
                            }
                        }
                        oldWindowScroll = windowScroll;
                        oldElementHeight = elementHeight;
                        if (firstRun) {
                            $($element).css('top', marginTop + 'px');
                            firstRun = false;
                        } else {
                            $($element).stop().animate({
                                'top': marginTop + 'px'
                            }, 'slow');
                        }
                    }
                }

                var firstShowRun = true;

                WatchService.create($scope, $attrs.ngShow, function () {
                    if (firstShowRun) {
                        firstShowRun = false;
                    } else {
                        handleVisibilityChanges();
                    }

                });

                var firstIfRun = true;

                WatchService.create($scope, $attrs.ngIf, function () {
                    if (firstIfRun) {
                        firstIfRun = false;
                    } else {
                        handleVisibilityChanges();
                    }
                });

                // listen to element visibility changes
                $scope.$watchCollection(function () {
                    // if not visible will return 0
                    return $element.height();
                }, handleVisibilityChanges);
            }
        };
    }
}(window.angular));

(function (angular) {
    "use strict";

    angular.module('qsHub.profiles').component('entryRequirements', {
        templateUrl: '/scripts/modules/profiles/components/entryRequirements/entryRequirements/entryRequirementsView.html',
        controller: 'EntryRequirementsController',
        bindings: {
            type: '<',
            institutionId: '<'
        },
        bindToController: true,
    });

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.profiles')
        .controller('EntryRequirementsController', [
            '$scope',
            'EntryRequirementsFormService',
            'WatchService',
            Controller
        ]);

    function Controller(
        $scope,
        EntryRequirementsFormService,
        WatchService
    ) {
        var controller = this;

        function fetchData () {
            controller.fetchInProgress = true;
            EntryRequirementsFormService.search({
                'filter[institutionId]' : controller.institutionId,
                limit: 2
            }).then(function (result) {
                controller.ugData = null;
                controller.pgData = null;
                if (result && result.results) {
                    angular.forEach(result.results, function (item) {
                        if (item.type === 'ug') {
                            controller.ugData = item;
                        } else if (item.type === 'pg') {
                            controller.pgData = item;
                        }
                    });
                }
                controller.fetchInProgress = false;
            });
        }

        controller.$onInit = function () {
            WatchService.create($scope, '$ctrl.institutionId', function (id) {
                if (id) {
                    fetchData();
                }
            });
        };
    }

}(window.angular));

(function (angular) {
    "use strict";

    angular.module('qsHub.profiles').component('entryRequirementsForm', {
        templateUrl: '/scripts/modules/profiles/components/entryRequirements/entryRequirementsForm/entryRequirementsFormView.html',
        controller: 'EntryRequirementsFormController',
        bindings: {
            type: '<',
            institutionId: '<',
            data: '<'
        },
        bindToController: true,
    });

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.profiles')
        .controller('EntryRequirementsFormController', [
            'EntryRequirementsFormService',
            'NotifierFactory',
            Controller
        ]);

    function Controller(
        EntryRequirementsFormService,
        NotifierFactory
    ) {
        var controller = this,
            submitInProgress = false,
            submitted = false;

        controller.questions = [
            {
                handle: 'sat',
                name: 'SAT',
                min: 400,
                max: 1600
            },
            {
                handle: 'gmat',
                name: 'GMAT',
                min: 200,
                max: 800
            },
            {
                handle: 'gre',
                name: 'GRE',
                min: 260,
                max: 340
            },
            {
                handle: 'gpa',
                name: 'GPA',
                min: 0,
                max: 7
            },
            {
                handle: 'atar',
                name: 'ATAR',
                min: 0,
                max: 100
            },
            {
                handle: 'stat',
                name: 'STAT',
                min: 100,
                max: 200
            },
            {
                handle: 'ielts',
                name: 'IELTS',
                min: 0,
                max: 9
            },
            {
                handle: 'toefl',
                name: 'TOEFL',
                min: 0,
                max: 120
            }
        ];

        controller.submitDisabled = function () {
            return !!submitInProgress;
        };

        controller.getValidationError = function (item) {
            if (minMoreThanMax(item.handle)) {
                return 'Minimum required must be less than Maximum expected';
            }
            return 'Value must be between ' + item.min + ' and ' + item.max;
        };

        function isValid() {
            var i = 0,
                total = controller.questions.length,
                question,
                min,
                max;

            for (i; i < total; i++) {
                question = controller.questions[i];
                min = typeof controller.form[question.handle + '_minRequired'].$viewValue !== 'undefined' ? parseFloat(controller.form[question.handle + '_minRequired'].$viewValue) : null;
                max = typeof controller.form[question.handle + '_maxRequired'].$viewValue !== 'undefined' ? parseFloat(controller.form[question.handle + '_maxRequired'].$viewValue): null;
                if (min !== null && max !== null && min >= max) {
                    return false;
                }
                if (
                    (typeof question.min !== 'undefined' && min !== null && (
                        min < question.min ||
                        (typeof question.max !== 'undefined' && min > question.max)
                    ))
                ) {
                    return false;
                }
                if (
                    (typeof question.max !== 'undefined' && max !== null && (
                        max > question.max ||
                        (typeof question.min !== 'undefined' && max < question.min)
                    ))
                ) {
                    return false;
                }
                if (controller.form[question.handle + '_comment'].$viewValue !== undefined &&
                    controller.form[question.handle + '_comment'].$viewValue !== null &&
                    controller.form[question.handle + '_comment'].$viewValue.length > 255) {
                    return false;
                }
            }

            return true;
        }

        //Show error at comment field if characters length exceed 255
        controller.showCommentError = function (fieldName) {
            return submitted &&
                ((controller.form &&
                    controller.form[fieldName] &&
                    controller.form[fieldName].$viewValue !== undefined &&
                    controller.form[fieldName].$viewValue !== null &&
                    controller.form[fieldName].$viewValue.length > 255));
        };

        controller.handleSubmit = function () {
            submitted = true;
            if (submitInProgress || !isValid()) {
                return false;
            }
            submitInProgress = true;
            EntryRequirementsFormService.replace(
                controller.institutionId,
                controller.type,
                controller.data
            ).then(function (response) {
                var success = response.modifiedCount || response.upsertedId;

                NotifierFactory.show(
                    success ? 'success' : 'error',
                    success ? 'Entry Requirements were successfully updated' : 'Entry Requirements were unsuccessfully updated',
                    'Entry Requirements'
                );
                submitInProgress = false;
            });
        };

        controller.showError = function (fieldName) {
            return submitted && ((controller.form && controller.form[fieldName] && controller.form[fieldName].$invalid) || minMoreThanMax(fieldName));
        };

        function minMoreThanMax(handle) {
            handle = handle.replace('_minRequired', '').replace('_maxRequired', '');
            return controller.data !== null && typeof controller.data !== "undefined" &&
                controller.data[handle] !== null && typeof controller.data[handle] !== "undefined" &&
                controller.data[handle].minRequired !== null &&
                controller.data[handle].maxRequired !== null &&
                controller.data[handle].minRequired >= controller.data[handle].maxRequired;
        }

        controller.handleKeyDown = function ($event) {
            if ($event.keyCode === 69) {
                $event.preventDefault();
                return false;
            }
            return true;
        };

        controller.handleFocus = function () {
            submitted = false;
            controller.form.$setPristine();
        };

    }

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.rankings')
        .service('EntryRequirementsFormService', [
            'constants',
            '$resource',
            'RequestsQueueService',
            Service
        ]);

    function Service(
        constants,
        $resource,
        RequestsQueueService
    ) {
        var service = {};

        function getModel() {
            return $resource(constants.api.institutions.url, {}, {
                replace: {
                    method: 'PATCH',
                    url: constants.api.institutions.url + '/v1/entry-requirements/:institutionId/:type',
                    isArray: false,
                    cancellable: true
                },
                search: {
                    method: 'GET',
                    url: constants.api.institutions.url + '/v1/list/entry-requirements',
                    isArray: false,
                    cancellable: true
                },
            });
        }

        service.replace = function (institutionId, type, data) {
            if (!institutionId || !type || ['ug', 'pg'].indexOf(type) === -1) {
                throw 'Missing Required';
            }

            var key = 'EntryRequirementsFormService:save',
                Api;
            RequestsQueueService.cancelAll(key);
            Api = getModel().replace({
                institutionId: institutionId,
                type: type
            }, data);
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                return data;
            }, function () {
                return false;
            });
        };

        service.search = function (filters) {
            var key = 'EntryRequirementsFormService:search',
                Api;
            RequestsQueueService.cancelAll(key);
            Api = getModel().search(filters);
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                return data;
            }, function () {
                return false;
            });
        };

        return service;
    }

}(window.angular));

(function (angular) {
    "use strict";

    angular.module('qsHub.rankings').component('statsSubmissionsBackButton', {
        templateUrl: '/scripts/modules/rankings/components/statisticsSubmissions/statsSubmissionsBackButton/statsSubmissionsBackButtonView.html',
    });

}(window.angular));

(function (angular) {
    "use strict";

    angular.module('qsHub.rankings').component('statsSubmissionsRankingSelection', {
        templateUrl: '/scripts/modules/rankings/components/statisticsSubmissions/statsSubmissionsRankingSelection/statsSubmissionsRankingSelectionView.html',
        controller: 'Rankings.StatsSubmissionsRankingSelection',
        bindings: {
            subscribed: '<',
            available: '<'
        }
    });

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.rankings')
        .controller('Rankings.StatsSubmissionsRankingSelection', [
            '$state',
            '$scope',
            'StatisticsDashboardFactory',
            'UnsavedChangesFactory',
            'WatchService',
            'InstitutionFactory',
            Controller
        ]);

    function Controller(
        $state,
        $scope,
        StatisticsDashboardFactory,
        UnsavedChangesFactory,
        WatchService,
        InstitutionFactory
    ) {
        var controller = this,
            lastRanking = null,
            subscribedTo = null,
            rankingHandle = $state.$current.data.rankings;

        controller.handleChanges = function () {
            function resetRanking () {
                controller.rankingFilter = lastRanking;
            }
            if (controller.rankingFilter && controller.rankingFilter.value) {
                StatisticsDashboardFactory.setActiveQuestion(null);
                UnsavedChangesFactory.setErrorCallback(resetRanking);
                return $state.go('clients.statistics.data-submission.' + controller.rankingFilter.value);
            }
        };

        function populateSelected() {
            var i = 0,
                total = controller.available.length,
                item;
            for (i; i < total; i++) {
                item = controller.available[i];
                if (subscribedTo[item.handle] &&
                    subscribedTo[item.handle].subscribed &&
                    item.handle === rankingHandle
                ) {
                    controller.rankingFilter = {
                        value : item.handle,
                        label : item.abbreviation
                    };
                    break;
                }
            }
        }

        function getSubscriptionsWatch(data) {
            if (!data) {
                return false;
            }
            subscribedTo = data;
            populateSelected();
        }

        function rankingFilterWatch(newVal, oldVal) {
            lastRanking = oldVal;
        }

        function initWatches() {
            WatchService.create($scope, '$ctrl.rankingFilter', rankingFilterWatch, true);
            WatchService.create($scope, InstitutionFactory.getSubscriptions, getSubscriptionsWatch);
        }

        controller.$onInit = function () {
            initWatches();
        };
    }

}(window.angular));

(function (angular) {
    "use strict";

    angular.module('qsHub.rankings').component('statsSubmissionsNotSubscribed', {
        templateUrl: '/scripts/modules/rankings/components/statisticsSubmissions/statsSubmissionsNotSubscribed/statsSubmissionsNotSubscribedView.html',
        bindings: {
            isClient: '<',
            handleNoRankingAccessClose: '&'
        }
    });

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.rankings')
        .controller('Rankings.GerController', [
            '$state',
            '$scope',
            '$stateParams',
            '$location',
            '$timeout',
            '$filter',
            'broadcast',
            'StatisticsDashboardFactory',
            'StatisticsSubmissionsFactory',
            'StatisticsHistoryFactory',
            'UnsavedChangesFactory',
            'NotifierFactory',
            'InstitutionFactory',
            'UserFactory',
            'WatchService',
            'RankingInfoFormService',
            'StatisticsSubmissionsService',
            'ClassificationsService',
            'StatisticsHistoryService',
            'RankingsDashboardService',
            'ModalService',
            Controller
        ]);

    function Controller(
        $state,
        $scope,
        $stateParams,
        $location,
        $timeout,
        $filter,
        broadcast,
        StatisticsDashboardFactory,
        StatisticsSubmissionsFactory,
        StatisticsHistoryFactory,
        UnsavedChangesFactory,
        NotifierFactory,
        InstitutionFactory,
        UserFactory,
        WatchService,
        RankingInfoFormService,
        StatisticsSubmissionsService,
        ClassificationsService,
        StatisticsHistoryService,
        RankingsDashboardService,
        ModalService
    ) {
        var controller = this,
            cancelRankingsDataWatch,
            dataToLoad = 3,
            allowRanking = false,
            defaultTabQuestions = [
                'employer_partnerships_question',
                'number_of_students',
                'faculty_staff'
            ],
            questionHandles = {
                'employers_connection': 0,
                'employers_connection_online': 0,
                'employers_connection_on_campus': 0,
                'employer_partnerships': 0,
                'employer_partnerships_question': 0,
                'number_of_students': 1,
                'number_of_students_auto': 1,
                'median_grad_salary':1,
                'qs_employment_rate_auto': 1,
                'evidence_when_shared': 1,
                'evidence_which_year_conducted': 1,
                'evidence_link_or_screenshot': 1,
                'number_of_students_entrepreneurship': 1,
                'number_of_students_entrepreneurship_auto': 1,
                'faculty_staff':2,
                'overall_students':2
            };
        controller.questions = null;
        controller.subscribedStatistics = [];
        controller.statisticsAvailable = [];
        controller.forms = {};
        controller.isClient = UserFactory.isClient();
        controller.rankingHandle = $state.$current.data.rankings;
        StatisticsSubmissionsFactory.setCurrentRanking($state.$current.data.rankings);
        controller.submitInProgress = false;

        controller.showOverlay = function () {
            return dataToLoad !== 0 || noRankingsAccess() || notSubscribedToRankings();
        };

        controller.showLoadBar = function () {
            return dataToLoad !== 0;
        };

        controller.showNoAccess = function () {
            return dataToLoad === 0 && noRankingsAccess();
        };

        controller.handleNoRankingAccessClose = function () {
            allowRanking = !controller.isClient;
            controller.notSubscribedToRanking = false;
        };

        function noRankingsAccess() {
            var noRankingsAccess = true;
            angular.forEach(controller.subscribedStatistics, function (statistic) {
                if (statistic.value === controller.rankingHandle) {
                    noRankingsAccess = false;
                }
            });

            return controller.isClient && noRankingsAccess;
        }

        function notSubscribedToRankings() {
            var noRankingsAccess = true;
            if (!allowRanking) {
                angular.forEach(controller.subscribedStatistics, function (statistic) {
                    if (statistic.value === controller.rankingHandle) {
                        noRankingsAccess = false;
                    }
                });
            } else {
                noRankingsAccess = false;
            }

            return noRankingsAccess;
        }

        function rankingsDataWatch(newValue) {
            if (!newValue) {
                return;
            }

            cancelRankingsDataWatch();
            var i = 0,
                item = null,
                total = newValue.length;

            for (i; i < total; i++) {
                item = newValue[i];
                if (item.handle === 'ger') {
                    controller.rankingDefinition = item.definition;
                    break;
                }
            }
            dataToLoad--;
        }

        function institutionDataWatch(data, oldData) {
            if (!data || angular.equals({}, data)) {
                return;
            }
            // on institution switch OR if institution updated, increase dataToLoad as it needs to load the subscriptions below.
            if (data.coreId && oldData.coreId &&
                (!angular.equals(data.coreId, oldData.coreId) ||
                !angular.equals(data.modifiedAt, oldData.modifiedAt))) {
                dataToLoad++;
            }

            var subscribedTo = InstitutionFactory.getSubscriptions();
            controller.subscribedStatistics = [];
            angular.forEach(controller.statisticsAvailable, function (item) {
                if (subscribedTo[item.handle] && subscribedTo[item.handle].subscribed) {
                    controller.subscribedStatistics.push({
                        value : item.handle,
                        label : item.abbreviation
                    });
                }
            });
            $timeout(function() {
                controller.notSubscribedToRanking = dataToLoad === 0 && notSubscribedToRankings()? true : false;
            });
            dataToLoad--;
        }

        function subscriptionsWatch(data) {
            if (!data || !data.length) {
                return;
            }
            angular.forEach(StatisticsDashboardFactory.getSubscriptions(), function (item) {
                if (item.handle !== 'stars') {
                    controller.statisticsAvailable.push(item);
                }
            });
            dataToLoad--;
            // listen to institution subscriptions
            WatchService.create($scope, InstitutionFactory.getData, institutionDataWatch);
        }

        function activeTabWatch(newId, oldId) {
            if (typeof newId !== 'undefined' && newId !== oldId) {
                setActiveQuestion();
            }
        }

        function initActiveTab() {
            switch ($state.current.name) {
                // case 'clients.statistics.data-submission.ger.employers-partnerships':
                //     controller.activeTab = 0;
                //     break;
                case 'clients.statistics.data-submission.ger.employment-rate':
                    controller.activeTab = 1;
                    break;
                case 'clients.statistics.data-submission.ger.faculty-student-data':
                    controller.activeTab = 2;
                break;
                default:
                    case 'clients.statistics.data-submission.ger.employers-partnerships':
                    controller.activeTab = 0;
                    break;
                // default:
                // case 'clients.statistics.data-submission.ger.employers-connections':
                //     controller.activeTab = 0;
                //     break;
            }
            setActiveQuestion();
        }

        function setActiveQuestion() {
            var questionHandle = null;
            switch (controller.activeTab) {
                case 0:
                    questionHandle = getQuestionHandle();
                    if(StatisticsSubmissionsFactory.hasChanges()) {
                        StatisticsSubmissionsFactory.setDatagridReload(true);
                    }
                    StatisticsDashboardFactory.setActiveGerQuestionHandle(null);
                    break;
                case 1:
                    questionHandle = getQuestionHandle();
                    StatisticsDashboardFactory.setActiveGerQuestionHandle(null);
                    break;
                case 2:
                    questionHandle = getQuestionHandle();
                    break;
                case 3:
                    questionHandle = getQuestionHandle();
                    break;
            }
            StatisticsDashboardFactory.setActiveQuestion({
                handle: questionHandle,
                definition: getDefinition()
            });
        }

        function getQuestionHandle() {
            var questionHandle = $location.search().question ? $location.search().question : defaultTabQuestions[controller.activeTab];
            if ($location.search().question && questionHandles[$location.search().question] !== controller.activeTab) {
                questionHandle = defaultTabQuestions[controller.activeTab];
                $state.transitionTo($state.current, {question: questionHandle}, {notify: false});
            }

            return questionHandle;
        }

        function getDefinition() {
            if (typeof controller.categories !== 'undefined') {
                var categoryHandle = 'employer_partnerships';
                if (controller.activeTab === 1) {
                    categoryHandle = 'employment_rate';
                } else if (controller.activeTab === 2) {
                    categoryHandle = 'faculty_staff';
                }
                var definition = null;
                angular.forEach(controller.questions, function (question) {
                    if (question.handle === categoryHandle && question.handle !== "median_grad_salary") {
                        definition = question.definition;
                    }
                    if (question.categoryHandle === categoryHandle && question.handle === "median_grad_salary") {
                        definition = question.definition;
                    }
                });
               

                return definition;
            }

            return null;
        }

        function questionsWatch(data) {
            controller.questions = data;
            if (controller.questions) {
                controller.parseRankingQuestions(controller.questions);
                initActiveTab();
                // listen to institution changes
                WatchService.create($scope, InstitutionFactory.getCoreId, controller.coreIdWatch);
            }
        }

        function loadCategories() {
            RankingInfoFormService.getCategories().then(function (data) {
                var categories = {};
                angular.forEach(data.results, function (item) {
                    categories[item.handle] = item;
                });
                controller.categories = categories;
                // navigate away then navigate in fix for definition
                setActiveQuestion();
            });
        }

        controller.isValid = function () {
            if (controller.forms && controller.forms.statistics &&
                angular.isDefined(controller.forms.statistics.invalidItems) &&
                !angular.equals({}, controller.forms.statistics.invalidItems)
            ) {
                NotifierFactory.show(
                    'error',
                    'Invalid data in the form',
                    'Data Submission'
                );
                UnsavedChangesFactory.setSaveError();

                return false;
            }

            return true;
        };

        controller.parseRankingQuestions = function (questions) {
            controller.questionsHandles = [];
            controller.gerStatisticsQuestions = {
                'faculty_staff' : [],
                'students_overall' : []
            };
            var preselectFirst = true,
                questionHandle = $location.search().question;

            angular.forEach(questions, function (item) {
                if (item.rankings.indexOf(controller.rankingHandle) !== -1 && (item.categoryHandle === 'faculty_staff' || item.categoryHandle === 'students_overall')) {
                    if (!controller.gerStatisticsQuestions[item.categoryHandle]) {
                        controller.gerStatisticsQuestions[item.categoryHandle] = [];
                    }
                    controller.gerStatisticsQuestions[item.categoryHandle].push(item);
                    controller.questionsHandles.push(item.handle);
                    if (preselectFirst) {
                        controller.preselectQuestion(item.id, item.handle);
                        preselectFirst = false;
                    }
                }
            });

            if (questionHandle) {
                var question = StatisticsSubmissionsService.getQuestionByHandle(questions, questionHandle);
                if (question && question.id) {
                    StatisticsSubmissionsFactory.setActiveQuestionId(question.id);
                    if ($location.search().question !== questionHandle) {
                        $state.transitionTo($state.current, {question: questionHandle}, {notify: false});
                    }
                }
            }
            angular.forEach(controller.gerStatisticsQuestions, function(value, key) {
                controller.gerStatisticsQuestions[key] = $filter('orderBy')(value, 'order', false);
            });
        };

        controller.preselectQuestion = function (questionId, questionHandle) {
            StatisticsSubmissionsFactory.setActiveQuestionId(questionId);
            if ($stateParams.question !== questionHandle) {
                $state.transitionTo($state.current, angular.extend({
                    question: questionHandle
                }, $stateParams), {notify: false});
            }
        };

        controller.handleSaveClick = function () {
            if (controller.isClient && UnsavedChangesFactory.hasChanges()) {
                if (!controller.isValid()) {
                    return;
                }
                var modalOptions = {
                    closeButtonText: 'Cancel',
                    actionButtonText: 'OK',
                    actionButtonClass: 'primary',
                    headerText: 'Data Submission',
                    bodyText: 'Unedited indicators will not be submitted. If you would like to submit unedited indicators as a new data, please follow the instructions above the table.'
                };
                ModalService.show({}, modalOptions).then(function () {
                    controller.handleSave();
                });
                
            }
            else {
                controller.handleSave();
            }
         };
        
        controller.handleSave = function () {
            if (!controller.isValid()) {
                return;
            }
            controller.submitInProgress = true;
            StatisticsSubmissionsFactory.requestData();
            var dataWatch = WatchService.create($scope, StatisticsSubmissionsFactory.getData, function (data) {
                if (!data || data.length !== controller.questionsHandles.length) {
                    return false;
                }
                dataWatch();
                StatisticsSubmissionsService.update(InstitutionFactory.getCoreId(), {
                    data: data,
                    institutionCountryCode: InstitutionFactory.getCountryCode(),
                    institutionName: InstitutionFactory.getDisplayName()
                }).then(controller.saveCallback);
            }, true);
        };

        controller.isSavingDisabled = function () {
            return !!controller.submitInProgress;
        };

        controller.loadAnswers = function (institutionCoreId) {
            RankingsDashboardService.getRankingsAnswersByQuestions({
                'filter[institutionCoreId]' : institutionCoreId,
                'filter[questionHandle][]' : controller.questionsHandles
            }).then(function (data) {
                StatisticsSubmissionsFactory.setAnswers(data.results);
            });
        };

        controller.saveCallback = function (response) {
            controller.submitInProgress = false;
            StatisticsHistoryFactory.setReload(true);
            var saveDataBeforeFlush = StatisticsSubmissionsFactory.getData();
            StatisticsSubmissionsFactory.resetData();
            UnsavedChangesFactory.resetChanges();
            if (controller.forms && controller.forms.statistics) {
                controller.forms.statistics.$setPristine();
            }
            controller.loadAnswers(InstitutionFactory.getCoreId());
            $scope.$broadcast(broadcast.saveCompleted, true);
            if (response && (response.insertCount || response.modifiedCount)) {
                StatisticsSubmissionsFactory.setHasChanges(true);
               
                NotifierFactory.show(
                    'success',
                    'Data submitted successfully',
                    'Data submission  '
                );
                UnsavedChangesFactory.setIsSaved();
                StatisticsHistoryService.clearCache();
                calculateClassificationSizeField(saveDataBeforeFlush);
            } else if (response === false) {
                UnsavedChangesFactory.setSaveError();
                NotifierFactory.show(
                    'error',
                    'Error occurred, please try again later',
                    'Data Submission'
                );

            }
            $timeout(function() {
                UnsavedChangesFactory.clearIsSaved();
            });

        };

        function calculateClassificationSizeField(statsData)
        {
            if (statsData.length) {
                ClassificationsService.get({'filter[institutionCoreId]=': InstitutionFactory.getCoreId(), page: 1, limit: 1}
                ).then(function (response) {
                    if (response && response.results && response.results[0] !== undefined) {
                        var classificationData = response.results[0],
                            simplifyFteData = [];
                        angular.forEach(statsData, function (value) {
                            if (value.question.handle === 'undergraduate_students' ||
                                value.question.handle === 'graduate_postgraduate_students' ||
                                value.question.handle === 'overall_students') {
                                    simplifyFteData[value.question.handle] = value.answers.fte.value;
                            }
                        });

                        var sizeCount = simplifyFteData.overall_students;
                        if (simplifyFteData.graduate_postgraduate_students !== "" &&
                            simplifyFteData.graduate_postgraduate_students !== null &&
                            simplifyFteData.undergraduate_students !== "" &&
                            simplifyFteData.undergraduate_students !== null
                        ) {
                            sizeCount = simplifyFteData.graduate_postgraduate_students + simplifyFteData.undergraduate_students;
                        }

                        if (sizeCount >= 30000) {
                            classificationData.size = 'XL';
                        } else if(sizeCount >= 12000){
                            classificationData.size = 'L';
                        } else if(sizeCount >= 5000){
                            classificationData.size = 'M';
                        } else if(sizeCount || sizeCount === 0) {
                            classificationData.size = 'S';
                        } else{
                            classificationData.size = '';
                        }
                        ClassificationsService.calculateResearchIntensity(classificationData);
                        ClassificationsService.update(classificationData.id, classificationData);
                    }
                });
            }
        }

        controller.isFormDirtyWatch = function (dirty) {
            UnsavedChangesFactory.setChanges(dirty);
        };

        controller.coreIdWatch = function (institutionCoreId) {
            if (institutionCoreId && controller.questionsHandles.length) {
                controller.loadAnswers(institutionCoreId);
            }
        };

        function initWatches() {
            cancelRankingsDataWatch = WatchService.create($scope, StatisticsDashboardFactory.getRankingsData, rankingsDataWatch);
            WatchService.create($scope, StatisticsDashboardFactory.getSubscriptions, subscriptionsWatch);
            WatchService.create($scope, StatisticsDashboardFactory.getQuestions, questionsWatch);
            WatchService.create($scope, 'GerController.activeTab', activeTabWatch);
            WatchService.create($scope, 'GerController.forms.statistics.$dirty', controller.isFormDirtyWatch);
        }

        function init() {
            UnsavedChangesFactory.setSaveFunction(controller.handleSave, $scope);
            initActiveTab();
            initWatches();
            loadCategories();
        }

        init();
    }

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.rankings')
        .service('GerService', [
            '$log',
            '$resource',
            'constants',
            'RequestsQueueService',
            Service
        ]);

    function Service(
        $log,
        $resource,
        constants,
        RequestsQueueService
    ) {
        var service = {};

        function getModel() {
            return $resource(constants.api.stars.url, {}, {
                getEmployersConnections: {
                    method: 'GET',
                    url:  constants.api.rankings.url + '/v1/answers',
                    isArray: false,
                    cancellable: true
                },
                getEmployerPartnerships: {
                    method: 'GET',
                    url:  constants.api.rankings.url + '/v1/answers',
                    isArray: false,
                    cancellable: true
                },
                getEmploymentRate: {
                    method: 'GET',
                    url:  constants.api.rankings.url + '/v1/answers',
                    isArray: false,
                    cancellable: true
                },
                setEmployersConnections: {
                    method: 'PATCH',
                    url:  constants.api.rankings.url + '/v1/answers/institution/:id',
                    isArray: false,
                    cancellable: true
                },

            });
        }

        service.getEmployersConnections = function (filters) {
            var key = 'GerService::getEmployersConnections',
                Api;

            RequestsQueueService.cancelAll(key);
            Api = getModel().getEmployersConnections(filters);
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                return data;
            });
        };

        service.getEmploymentRate = function (filters) {
            var key = 'GerService::getEmploymentRate',
                Api;

            RequestsQueueService.cancelAll(key);
            Api = getModel().getEmploymentRate(filters);
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                return data;
            });
        };

        service.update = function (institutionCoreId, data) {
            if (!institutionCoreId) {
                throw 'Missing Required';
            }

            return getModel().setEmployersConnections({
                id: institutionCoreId
            }, data).$promise.then(function (response) {
                if (constants.dev) {
                    $log.log('success, got data: ', response);
                }
                return response;
            }, function (error) {
                if (constants.dev) {
                    $log.log('request failed' + error);
                }
                return false;
            });
        };

        service.getEmployerPartnerships = function (filters) {
            var key = 'GerService::getEmployerPartnerships',
                Api;

            RequestsQueueService.cancelAll(key);
            Api = getModel().getEmployerPartnerships(filters);
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                return data;
            });
        };

        return service;
    }

}(window.angular));


(function (angular) {
    "use strict";

    angular.module('qsHub.rankings').component('gerEmployersConnections', {
        templateUrl: '/scripts/modules/rankings/components/ger/employersConnections/gerEmployersConnectionsView.html',
        controller: 'Rankings.GerEmployersConnectionsController',
        bindings: {
            categories: '<',
            questions: '<'
        }
    });

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.rankings')
        .controller('Rankings.GerEmployersConnectionsController', [
            '$location',
            '$state',
            'UserFactory',
            Controller
        ]);

    function Controller(
        $location,
        $state,
        UserFactory
    ) {
        var controller = this;
        controller.activeQuestion = $location.search().question ? $location.search().question : 'employers_connection_online';
        controller.isClient = UserFactory.isClient();

        controller.$onInit = function () {
            if ($location.search().question !== controller.activeQuestion) {
                $state.transitionTo($state.current, {question: controller.activeQuestion}, {notify: false});
            }
        };
    }

}(window.angular));

(function (angular) {

  'use strict';

  angular.module('qsHub.stars').component('employersConnectionsDatagrid', {
      templateUrl: '/scripts/modules/rankings/components/ger/employersConnectionsDatagrid/employersConnectionsDatagridView.html',
      bindToController: true,
      controller: 'Rankings.EmployersConnectionsDatagrid',
      bindings: {
          categories: '<',
          newData: '=',
          values: '=',
          form:'='
      }
  });

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.stars')
        .controller('Rankings.EmployersConnectionsDatagrid', [
            '$scope',
            '$timeout',
            '$location',
            'GerService',
            'WatchService',
            'InstitutionFactory',
            'NotifierFactory',
            'UnsavedChangesFactory',
            'StatisticsSubmissionsFactory',
            'StatisticsHistoryFactory',
            'StatisticsHistoryService',
            'IsValidIntService',
            Controller
        ]);

    function Controller(
        $scope,
        $timeout,
        $location,
        GerService,
        WatchService,
        InstitutionFactory,
        NotifierFactory,
        UnsavedChangesFactory,
        StatisticsSubmissionsFactory,
        StatisticsHistoryFactory,
        StatisticsHistoryService,
        IsValidIntService
    ) {
        var controller = this,
            gridApiInstance = null,
            loadingData = false,
            isRendered = false,
            submitInProgress = false,
            employersConnection = 'Employers\' Connection',
            accepted = 'accepted',
            direct = 'direct',
            sourceFile = null,
            otherAnswers = {},
            questionHandles = ['employers_connections', 'employers_connection_online', 'employers_connection_on_campus'],
            institutionCoreIdInUrl = null;

        controller.showLoadBar = showLoadBar;
        controller.handleSaveClick = handleSaveClick;
        controller.isSavingDisabled = isSavingDisabled;

        function initDatagridOptions () {
            controller.gridOptions = {
                appScopeProvider: controller,
                enableSorting: false,
                useExternalFiltering: false,
                enableGridMenu: false,
                showGridFooter: true,
                enableFiltering: false,
                enableColumnResize: true,
                enableFullRowSelection: false,
                enableRowSelection: false,
                multiSelect: false,
                enableRowHeaderSelection: false,
                exporterMenuCsv: false,
                exporterMenuAllData: false,
                exporterMenuSelectedData: false,
                exporterMenuPdf: false,
                paginationPageSizes: [25, 50, 100, 200],
                paginationPageSize: 25,
                useExternalPagination: false,
                columnDefs: [
                    {
                        displayName: 'Company name',
                        field: 'companyName',
                        enableColumnMenu: false,
                    },
                    {
                        displayName: 'Date of event',
                        field: 'dateOfEvent',
                        enableColumnMenu: false,
                    },
                    {
                        displayName: 'Type of event (e.g. Law Career Fair, Employer Presentation)',
                        field: 'typeOfEvent',
                        enableColumnMenu: false,
                    },
                    {
                        displayName: 'Type (Online or on-campus)',
                        field: 'type',
                        enableColumnMenu: false,
                    },
                    {
                        displayName: 'Link to event',
                        field: 'linkToEvent',
                        enableColumnMenu: false,
                    },
                    {
                        displayName: 'Comments',
                        field: 'comments',
                        enableColumnMenu: false,
                    }
                ],
                onRegisterApi: function (gridApi) {
                    gridApiInstance = gridApi;
                    gridApi.core.on.rowsRendered($scope, function () {
                        isRendered = true;
                    });
                }
            };
        }

        function getPage(institutionCoreId) {
            loadingData = true;
            return GerService.getEmployersConnections({
                'filter[institutionCoreId]': institutionCoreId,
                'filter[questionHandle][]': questionHandles
            }).then(function (response) {
                assignValues(response);
                loadingData = false;
            });
        }

        function assignValues(response) {
            var tmpAnswers = [];
            if (response.total && response.hasOwnProperty('results') && angular.isArray(response.results) && response.results.length > 0) {
                angular.forEach(response.results, function (result) {
                    if (result.questionHandle === questionHandles[0]) {
                        controller.gridOptions.totalItems = result.answers.length;
                        controller.gridOptions.data = result.answers;
                        controller.gridOptions.minRowsToShow = result.answers.length;
                    } else {
                        tmpAnswers[result.questionHandle] = result;
                    }
                });
            } else {
                angular.forEach(questionHandles, function(handle) {
                    tmpAnswers[handle] = {
                        value: null,
                        valueAsString: null,
                        estimate: null
                    };
                });
                controller.gridOptions.data = [];
            }
            controller.values = tmpAnswers;
        }

        function showLoadBar() {
            return !isRendered || loadingData;
        }

        function coreIdWatch(institutionCoreId) {
            if (!institutionCoreId) {
                return false;
            }
            if (institutionCoreIdInUrl) {
                getPage(institutionCoreIdInUrl);
                controller.getCoreIdWatch();
                return;
            }
            getPage(institutionCoreId);
        }

        function newDataWatch(data) {
            if (data) {
                if (data.hasOwnProperty('handle')) {
                    if (data.hasOwnProperty('status') &&
                        otherAnswers[data.handle] &&
                        otherAnswers[data.handle].hasOwnProperty('status') &&
                        !angular.equals(null, otherAnswers[data.handle].status) &&
                        !angular.equals(otherAnswers[data.handle].status, data.status)) {
                        UnsavedChangesFactory.setChanges(true);
                        StatisticsSubmissionsFactory.setHasChanges(true);
                        UnsavedChangesFactory.setSaveFunction(controller.handleSaveClick, $scope);
                    }
                    otherAnswers[data.handle] = data;
                } else if (data.hasOwnProperty('content')) {
                    UnsavedChangesFactory.setChanges(true);
                    StatisticsSubmissionsFactory.setHasChanges(true);
                    UnsavedChangesFactory.setSaveFunction(controller.handleSaveClick, $scope);
                    controller.gridOptions.data = data.content;
                    sourceFile = data.hasOwnProperty('fileName') ? data.fileName : null;
                }
            }
        }

        function isValid() {
            if (controller.form && 
                angular.isDefined(controller.form.invalidItems) &&
                !angular.equals({}, controller.form.invalidItems) 
        ) {
            NotifierFactory.show(
                'error',
                'Invalid data in the form',
                'Data Submission'
            );
            UnsavedChangesFactory.setSaveError();

            return false;
        }
            var intValues = [];
            angular.forEach(getData(), function (item) {
                if (item.answers) {
                    if (typeof item.answers.employers_connection_online !== 'undefined') {
                        intValues.push(item.answers.employers_connection_online.value);
                    } else if (typeof item.answers.employers_connection_on_campus !== 'undefined') {
                        intValues.push(item.answers.employers_connection_on_campus.value);
                    }
                }
            });
            for (var i = 0; i < intValues.length; i++) {
                if (!IsValidIntService.isValid(intValues[i])) {
                    NotifierFactory.show(
                        'error',
                        'Invalid data in the form',
                        'Data Submission'
                    );
                    return false;
                }
            }
            return true;
        }

        function handleSaveClick() {
            submitInProgress = true;
            if (!isValid()) {
                $timeout(function() {
                    angular.element('.modal-dialog .modal-footer button.btn.btn-default').triggerHandler('click');
                });
                submitInProgress = false;
                return false;
            }
            UnsavedChangesFactory.resetChanges();

            GerService.update(InstitutionFactory.getCoreId(), {
                data: getData(),
                institutionCountryCode: InstitutionFactory.getCountryCode(),
                institutionName: InstitutionFactory.getDisplayName()
            }).then(saveCallback);
        }

        function getData() {
            var data = [], object = {};
            if (sourceFile) {
                object.answerId = null;
                object.status = accepted;
                object.source = direct;
                object.question = {};
                object.question.handle = questionHandles[0];
                object.question.definition = null;
                object.answers = controller.gridOptions.data;
                object.sourceFile = sourceFile;
                data.push(object);
            }

            angular.forEach(otherAnswers, function(answer) {
                object = {};
                object.answerId = typeof answer.answerId === 'undefined' ? null : answer.answerId;
                object.status = typeof answer.status === 'undefined' ? null : answer.status;
                object.source = typeof answer.source === 'undefined' ? null : answer.source;
                otherAnswers[answer.handle].status = object.status;
                otherAnswers[answer.handle].source = object.source;
                object.question = {};
                object.question.handle = answer.handle;
                object.question.definition = null;
                object.answers = {};
                object.answers[answer.handle] = {
                    value: answer.value || answer.value === 0 ? parseFloat(answer.value) : null,
                    valueAsString: answer.value || answer.value === 0 ? answer.value.toString() : null
                };
                if (answer.estimate) {
                    object.answers[answer.handle].estimate = answer.estimate;
                }
                data.push(object);
            });
            controller.values = otherAnswers;

            return data;
        }

        function isSavingDisabled() {
            return (!!submitInProgress && !showLoadBar());
        }

        function saveCallback(response) {
            submitInProgress = false;
            sourceFile = null;
            StatisticsHistoryFactory.setReload(true);
            UnsavedChangesFactory.resetChanges();
            StatisticsHistoryService.clearCache();
            if (response && response.hasOwnProperty('modifiedCount') && (response.modifiedCount > 0 || response.insertCount > 0)) {
                NotifierFactory.show(
                    'success',
                    'Updated successfully',
                    employersConnection
                );
                getPage(InstitutionFactory.getCoreId());
                UnsavedChangesFactory.setIsSaved();
                StatisticsSubmissionsFactory.resetExplanationRequiredQuestions();
            } else if (response === false) {
                NotifierFactory.show(
                    'error',
                    'Error occurred, please try again later',
                    employersConnection
                );
                UnsavedChangesFactory.setSaveError();
            }
            $timeout(function() {
                UnsavedChangesFactory.clearIsSaved();
                StatisticsSubmissionsFactory.setHasChanges(false);
                controller.form.$setPristine();
                angular.element('.modal-dialog .modal-footer button.btn.btn-default').triggerHandler('click');
            });
        }

        function dataGridReloadWatch (reload) {
            if(reload && sourceFile) {
                getPage(InstitutionFactory.getCoreId());
                controller.newData.uploadedFileName= null;
                StatisticsSubmissionsFactory.setHasChanges(false);
            }
            StatisticsSubmissionsFactory.setDatagridReload(false);
        }

        function isFormDirtyWatch(isDirty) {
            if (isDirty) {
                UnsavedChangesFactory.setSaveFunction(controller.handleSaveClick, $scope);
                UnsavedChangesFactory.setChanges(true);
                StatisticsSubmissionsFactory.setHasChanges(true);
            }
        }


        function initWatches() {
            controller.getCoreIdWatch = WatchService.create($scope, InstitutionFactory.getCoreId, coreIdWatch);
            WatchService.create($scope, '$ctrl.newData', newDataWatch);
            WatchService.create($scope, StatisticsSubmissionsFactory.isDatagridReload, dataGridReloadWatch);
            WatchService.create($scope,'$ctrl.form.$dirty', isFormDirtyWatch);
        }

        controller.$onInit = function () {
            initWatches();
            initDatagridOptions();
            institutionCoreIdInUrl = $location.search().coreId;
        };
    }

}(window.angular));

(function (angular) {
    "use strict";

    angular.module('qsHub.rankings').component('gerEmployerPartnerships', {
        templateUrl: '/scripts/modules/rankings/components/ger/employerPartnerships/gerEmployerPartnershipsView.html',
        controller: 'Rankings.GerEmployerPartnershipsController',
        bindings: {
            categories: '<',
            questions: '<'
        }
    });

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.rankings')
        .controller('Rankings.GerEmployerPartnershipsController', [
            '$location',
            '$state',
            'UserFactory',
            Controller
        ]);

    function Controller(
        $location,
        $state,
        UserFactory
    ) {
        var controller = this;
        controller.activeQuestion = $location.search().question ? $location.search().question : 'employer_partnerships_question';
        controller.isClient = UserFactory.isClient();

        controller.$onInit = function () {
            if ($location.search().question !== controller.activeQuestion) {
                $state.transitionTo($state.current, {question: controller.activeQuestion}, {notify: false});
            }
        };
    }

}(window.angular));

(function (angular) {

      'use strict';

      angular.module('qsHub.stars').component('employerPartnershipsDatagrid', {
          templateUrl: '/scripts/modules/rankings/components/ger/employerPartnershipsDatagrid/employerPartnershipsDatagridView.html',
          bindToController: true,
          controller: 'Rankings.EmployerPartnershipsDatagrid',
          bindings: {
              categories: '<',
              newData: '=',
              values: '=',
              form:'='
          }
      });

    }(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.stars')
        .controller('Rankings.EmployerPartnershipsDatagrid', [
            '$scope',
            '$timeout',
            '$location',
            'GerService',
            'WatchService',
            'InstitutionFactory',
            'NotifierFactory',
            'UnsavedChangesFactory',
            'StatisticsSubmissionsFactory',
            'StatisticsHistoryFactory',
            'StatisticsHistoryService',
            'IsValidIntService',
            Controller
        ]);

    function Controller(
        $scope,
        $timeout,
        $location,
        GerService,
        WatchService,
        InstitutionFactory,
        NotifierFactory,
        UnsavedChangesFactory,
        StatisticsSubmissionsFactory,
        StatisticsHistoryFactory,
        StatisticsHistoryService,
        IsValidIntService
    ) {
        var controller = this,
            gridApiInstance = null,
            loadingData = false,
            isRendered = false,
            submitInProgress = false,
            employerPartnerships = 'Employer Partnerships',
            accepted = 'accepted',
            direct = 'direct',
            sourceFile = null,
            otherAnswers = {},
            questionHandles = ['employer_partnerships', 'employer_partnerships_question'],
            institutionCoreIdInUrl = null;

        controller.showLoadBar = showLoadBar;
        controller.handleSaveClick = handleSaveClick;
        controller.isSavingDisabled = isSavingDisabled;

        function initDatagridOptions () {
            controller.gridOptions = {
                appScopeProvider: controller,
                enableSorting: false,
                useExternalFiltering: false,
                enableGridMenu: false,
                showGridFooter: true,
                enableFiltering: false,
                enableColumnResize: true,
                enableFullRowSelection: false,
                enableRowSelection: false,
                multiSelect: false,
                enableRowHeaderSelection: false,
                exporterMenuCsv: false,
                exporterMenuAllData: false,
                exporterMenuSelectedData: false,
                exporterMenuPdf: false,
                paginationPageSizes: [25, 50, 100, 200],
                paginationPageSize: 25,
                useExternalPagination: false,
                columnDefs: [
                    {
                        displayName: 'Company name',
                        field: 'companyName',
                        enableColumnMenu: false,
                    },
                    {
                        displayName: 'Number of internships offered to your students in the last reporting year',
                        field: 'numberOfInternships',
                        enableColumnMenu: false,
                    },
                    {
                        displayName: 'Number of work placements offered to your students in the last reporting year',
                        field: 'numberOfWorkPlacements',
                        enableColumnMenu: false,
                    },
                    {
                        displayName: 'Link to partnership webpage',
                        field: 'linkToPartnershipWebPage',
                        enableColumnMenu: false,
                    }
                ],
                onRegisterApi: function (gridApi) {
                    gridApiInstance = gridApi;
                    gridApi.core.on.rowsRendered($scope, function () {
                        isRendered = true;
                    });
                }
            };
        }

        function getPage(institutionCoreId) {
            loadingData = true;
            return GerService.getEmployerPartnerships({
                'filter[institutionCoreId]': institutionCoreId,
                'filter[questionHandle][]': questionHandles
            }).then(function (response) {
                assignValues(response);
                loadingData = false;
            });
        }

        function assignValues(response) {
            var tmpAnswers = [];
            if (response.total && response.hasOwnProperty('results') && angular.isArray(response.results) && response.results.length > 0) {
                angular.forEach(response.results, function (result) {
                    if (result.questionHandle === questionHandles[0]) {
                        controller.gridOptions.totalItems = result.answers.length;
                        controller.gridOptions.data = result.answers;
                        controller.gridOptions.minRowsToShow = result.answers.length;
                    } else {
                        tmpAnswers[result.questionHandle] = result;
                    }
                });
            } else {
                angular.forEach(questionHandles, function(handle) {
                    tmpAnswers[handle] = {
                        value: null,
                        valueAsString: null,
                        estimate: null
                    };
                });
            }
            controller.values = tmpAnswers;
        }

        function showLoadBar() {
            return !isRendered || loadingData;
        }

        function coreIdWatch(institutionCoreId) {
            if (!institutionCoreId) {
                return false;
            }
            if (institutionCoreIdInUrl) {
                getPage(institutionCoreIdInUrl);
                controller.getCoreIdWatch();
                return;
            }
            getPage(institutionCoreId);
        }

        function newDataWatch(data) {
            if (data) {
                if (data.hasOwnProperty('handle')) {
                    if (data.hasOwnProperty('status') &&
                        otherAnswers[data.handle] &&
                        otherAnswers[data.handle].hasOwnProperty('status') &&
                        !angular.equals(null, otherAnswers[data.handle].status) &&
                        !angular.equals(otherAnswers[data.handle].status, data.status)) {
                        UnsavedChangesFactory.setChanges(true);
                        StatisticsSubmissionsFactory.setHasChanges(true);
                        UnsavedChangesFactory.setSaveFunction(controller.handleSaveClick, $scope);
                    }
                    otherAnswers[data.handle] = data;
                } else if (data.hasOwnProperty('content')) {
                    controller.gridOptions.data = data.content;
                    sourceFile = data.hasOwnProperty('fileName') ? data.fileName : null;
                    UnsavedChangesFactory.setSaveFunction(controller.handleSaveClick, $scope);
                    UnsavedChangesFactory.setChanges(true);
                    StatisticsSubmissionsFactory.setHasChanges(true);
                }
            }
        }

        function isValid() {
            if (controller.form && 
                    angular.isDefined(controller.form.invalidItems) &&
                    !angular.equals({}, controller.form.invalidItems) 
            ) {
                NotifierFactory.show(
                    'error',
                    'Invalid data in the form',
                    'Data Submission'
                );
                UnsavedChangesFactory.setSaveError();

                return false;
            }
            var intValues = [];
            angular.forEach(getData(), function (item) {
                if (item.answers) {
                    if (typeof item.answers.employer_partnerships_question !== 'undefined') {
                        intValues.push(item.answers.employer_partnerships_question.value);
                    }
                }
            });
            for (var i = 0; i < intValues.length; i++) {
                if (!IsValidIntService.isValid(intValues[i])) {
                    NotifierFactory.show(
                        'error',
                        'Invalid data in the form',
                        'Data Submission'
                    );
                    return false;
                }
            }
            return true;
        }

        function handleSaveClick() {
            submitInProgress = true;
            if (!isValid()) {
                $timeout(function() {
                    angular.element('.modal-dialog .modal-footer button.btn.btn-default').triggerHandler('click');
                });
                submitInProgress = false;
                return false;
            }
            UnsavedChangesFactory.resetChanges();

            GerService.update(InstitutionFactory.getCoreId(), {
                data: getData(),
                institutionCountryCode: InstitutionFactory.getCountryCode(),
                institutionName: InstitutionFactory.getDisplayName()
            }).then(saveCallback);
        }

        function getData() {
            var data = [], object = {};
            if (sourceFile) {
                object.answerId = null;
                object.status = accepted;
                object.source = direct;
                object.question = {};
                object.question.handle = questionHandles[0];
                object.question.definition = null;
                object.answers = controller.gridOptions.data;
                object.sourceFile = sourceFile;
                data.push(object);
            }

            angular.forEach(otherAnswers, function(answer) {
                object = {};
                object.answerId = typeof answer.answerId === 'undefined' ? null : answer.answerId;
                object.status = typeof answer.status === 'undefined' ? null : answer.status;
                object.source = typeof answer.source === 'undefined' ? null : answer.source;
                otherAnswers[answer.handle].status = object.status;
                otherAnswers[answer.handle].source = object.source;
                object.question = {};
                object.question.handle = answer.handle;
                object.question.definition = null;
                object.answers = {};
                object.answers[answer.handle] = {
                    value: answer.value || answer.value === 0 ? parseFloat(answer.value) : null,
                    valueAsString: answer.value || answer.value === 0 ? answer.value.toString() : null
                };
                if (answer.estimate) {
                    object.answers[answer.handle].estimate = answer.estimate;
                }
                data.push(object);
            });
            controller.values = otherAnswers;

            return data;
        }

        function isSavingDisabled() {
            return (!!submitInProgress && !showLoadBar());
        }

        function saveCallback(response) {
            submitInProgress = false;
            sourceFile = null;
            StatisticsHistoryFactory.setReload(true);
            UnsavedChangesFactory.resetChanges();
            StatisticsHistoryService.clearCache();
            if (response && response.hasOwnProperty('modifiedCount') && (response.modifiedCount > 0 || response.insertCount > 0)) {
                NotifierFactory.show(
                    'success',
                    'Updated successfully',
                    employerPartnerships
                );
                getPage(InstitutionFactory.getCoreId());
                UnsavedChangesFactory.setIsSaved();
                StatisticsSubmissionsFactory.resetExplanationRequiredQuestions();
            } else if (response === false) {
                NotifierFactory.show(
                    'error',
                    'Error occurred, please try again later',
                    employerPartnerships
                );
                UnsavedChangesFactory.setSaveError();
            }
            $timeout(function() {
                UnsavedChangesFactory.clearIsSaved();
                StatisticsSubmissionsFactory.setHasChanges(false);
                controller.form.$setPristine();
                angular.element('.modal-dialog .modal-footer button.btn.btn-default').triggerHandler('click');
            });
        }

        function dataGridReloadWatch (reload) {
            if(reload && sourceFile) {
                getPage(InstitutionFactory.getCoreId());
                controller.newData.uploadedFileName= null;
                StatisticsSubmissionsFactory.setHasChanges(false);
            }
            StatisticsSubmissionsFactory.setDatagridReload(false);
        }
        function isFormDirtyWatch(isDirty) {
            if (isDirty) {
                UnsavedChangesFactory.setSaveFunction(controller.handleSaveClick, $scope);
                UnsavedChangesFactory.setChanges(true);
                StatisticsSubmissionsFactory.setHasChanges(true);
            }
        }

        function initWatches() {
            controller.getCoreIdWatch = WatchService.create($scope, InstitutionFactory.getCoreId, coreIdWatch);
            WatchService.create($scope, '$ctrl.newData', newDataWatch);
            WatchService.create($scope, StatisticsSubmissionsFactory.isDatagridReload, dataGridReloadWatch);
            WatchService.create($scope,'$ctrl.form.$dirty', isFormDirtyWatch);
        }

        controller.$onInit = function () {
            initWatches();
            initDatagridOptions();
            institutionCoreIdInUrl = $location.search().coreId;
        };
    }

}(window.angular));

(function (angular) {
    "use strict";

    angular.module('qsHub.rankings').component('gerEmploymentRate', {
        templateUrl: '/scripts/modules/rankings/components/ger/employmentRate/gerEmploymentRateView.html',
        controller: 'Rankings.GerEmploymentRateController',
        bindings: {
            categories: '<',
            questions: '<',
            isParentValid:'&',
            handleParentSave:'&',
            isOtherRanking:'<'
        }
    });

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.rankings')
        .controller('Rankings.GerEmploymentRateController', [
            'constants',
            '$timeout',
            '$scope',
            'UrlService',
            'ObjectService',
            'AuthenticationService',
            'NotifierFactory',
            'GerEmploymentRateService',
            'InstitutionFactory',
            'StatisticsDashboardFactory',
            'WatchService',
            'GerService',
            'IsValidFloatService',
            'IsValidIntService',
            'StatisticsHistoryFactory',
            'UnsavedChangesFactory',
            '$location',
            '$state',
            'StatisticsHistoryService',
            'StatisticsSubmissionsFactory',
            'UserFactory',
            'ModalService',
            Controller
        ]);

    function Controller(
        constants,
        $timeout,
        $scope,
        UrlService,
        ObjectService,
        AuthenticationService,
        NotifierFactory,
        GerEmploymentRateService,
        InstitutionFactory,
        StatisticsDashboardFactory,
        WatchService,
        GerService,
        IsValidFloatService,
        IsValidIntService,
        StatisticsHistoryFactory,
        UnsavedChangesFactory,
        $location,
        $state,
        StatisticsHistoryService,
        StatisticsSubmissionsFactory,
        UserFactory,
        ModalService
    ) {
        var controller = this,
            acceptedFiles = [
                'application/pdf',
                'application/x-pdf',
                'image/jpeg',
                'image/pjpeg',
                'image/jpeg',
                'image/pjpeg',
                'image/png',
                'application/csv',
                'text/csv',
                'application/doc',
                'application/docx',
                'application/xlsx',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/xls',
                'application/msword',
                'application/vnd.ms-excel'
            ],
            submitInProgress = false,
            activeQuestion ='',
            dataToLoad = 4,
            institutionCoreIdInUrl = null;

        controller.submitted = true; //Changes it to true, to trigger validation without clicking save button
        controller.maxSize = 10;
        controller.uploadEnabled = true;
        controller.dropZoneInstance = null;
        controller.config = {
            dropzone: {
                url: constants.api.rankings.url + '/v1/ger/employment-rate/evidence-upload',
                maxFilesize: controller.maxSize,
                maxFiles: 1,
                uploadMultiple: false,
                parallelUploads: 1,
                autoProcessQueue: true,
                addRemoveLinks: false,
                acceptedFiles:  acceptedFiles.join(','),
                previewsContainer: false,
                headers: AuthenticationService.getAuthorizationHeader(),
                paramName: 'file',
                init: function () {
                    controller.dropZoneInstance = this;
                },
            },
            eventHandlers: {
                addedfile: handleFileAdded,
                success: handleUploadSuccess,
                error: handleUploadError,
            }
        };
        controller.data = {};
        controller.uploadInProgress = false;
        controller.isEmploymentRateAutoValid = true;
        controller.isClient = UserFactory.isClient();
        controller.answerIds = {};
        controller.evidenceHeader = 'Employment Statistics evidence';
        if(!controller.isOtherRanking) {
            activeQuestion = 'number_of_students';
            controller.evidenceHeader = 'Evidence';
        }

        function handleFileAdded() {
            $timeout(function() {
                controller.uploadInProgress = true;
                controller.uploadEnabled = false;
            });
        }

        function handleUploadError() {
            NotifierFactory.show(
                'error',
                'Can\'t upload document at the moment. Please try again later.',
                'Evidence Upload'
            );
            $timeout(function () {
                controller.handleRemove();
            });
        }

        function handleUploadSuccess(instance, response) {
            if (response.status && response.status && response.path) {
                $timeout(function () {
                    controller.upload = response;
                    controller.upload.name = instance.name;
                    controller.uploadInProgress = false;
                    if (!controller.data.evidence) {
                        controller.data.evidence = {};
                    }
                    controller.data.evidence.url = response.path;
                });
            } else {
                handleUploadError();
            }
        }

        controller.handleRemove = function () {
            controller.uploadEnabled = true;
            if (controller.dropZoneInstance) {
                controller.dropZoneInstance.removeAllFiles();
            }
            controller.upload = null;
            controller.uploadInProgress = false;
        };

        function isValid() {
            var notificationHeader = controller.isOtherRanking? 'Data Submission' : 'Employment Statistics';
            var intFields = [
                    controller.data.numberOfStudents.totalGraduatedStudents,
                    controller.data.numberOfStudents.totalRespondents,
                    controller.data.numberOfStudents.employed,
                    controller.data.numberOfStudents.unemployed,
                    controller.data.numberOfStudents.inFurtherStudy,
                    controller.data.numberOfStudents.unavailable
                ],
                entrepreneurshipFields =[controller.data.numberOfStudents.entrepreneurship],
              //  medianGradSalary =[controller.data.medianGradSalary],
                floatFields = [],
                requiredFields = [
                    controller.data.numberOfStudents.totalGraduatedStudents,
                    controller.data.numberOfStudents.employed,
                    controller.data.numberOfStudents.unemployed
                ],
                greaterFields = [
                    controller.data.numberOfStudents.employed,
                    controller.data.numberOfStudents.unemployed,
                    controller.data.numberOfStudents.inFurtherStudy,
                    controller.data.numberOfStudents.unavailable,
                    controller.data.numberOfStudents.totalRespondents
                ],
                total = floatFields.length,
                i = 0,
                value;
            for (i; i < total; i++) {
                if (!IsValidFloatService.isValid(floatFields[i])) {
                    StatisticsSubmissionsFactory.setInvalidIndicators('number_of_students', true);
                    StatisticsSubmissionsFactory.setInvalidIndicators('qs_employment_rate_auto', true);
                    NotifierFactory.show(
                        'error',
                        'Invalid data for Employment statistics',
                        notificationHeader
                    );
                    return false;
                }
            }
            total = intFields.length;
            i = 0;
            for (i; i < total; i++) {
                if (!IsValidIntService.isValid(intFields[i])) {
                    StatisticsSubmissionsFactory.setInvalidIndicators('number_of_students', true);
                    StatisticsSubmissionsFactory.setInvalidIndicators('qs_employment_rate_auto', true);
                    NotifierFactory.show(
                        'error',
                        'Invalid data for Employment statistics',
                        notificationHeader
                    );
                    return false;
                }
            }
            // total = medianGradSalary.length;
            // i = 0;
            // for (i; i < total; i++) {
            //     if (!IsValidIntService.isValid(medianGradSalary[i])) {
            //         StatisticsSubmissionsFactory.setInvalidIndicators('median_grad_salary', true);
            //         NotifierFactory.show(
            //             'error',
            //             'Invalid data for Employment statistics',
            //             notificationHeader
            //         );
            //         return false;
            //     }
            // }
            total = entrepreneurshipFields.length;
            i = 0;
            for (i; i < total; i++) {
                if (!controller.isOtherRanking && !IsValidIntService.isValid(entrepreneurshipFields[i])) {
                    StatisticsSubmissionsFactory.setInvalidIndicators('number_of_students_entrepreneurship', true);
                    StatisticsSubmissionsFactory.setInvalidIndicators('number_of_students_entrepreneurship_auto', true);
                    NotifierFactory.show(
                        'error',
                        'Invalid data for Employment statistics',
                        notificationHeader
                    );
                    return false;
                }
                if (!controller.isOtherRanking && controller.isGreater(entrepreneurshipFields[i])) {
                    StatisticsSubmissionsFactory.setInvalidIndicators('number_of_students_entrepreneurship', true);
                    StatisticsSubmissionsFactory.setInvalidIndicators('number_of_students_entrepreneurship_auto', true);
                    NotifierFactory.show(
                        'error',
                        'Invalid data for Employment statistics',
                        notificationHeader
                    );
                    return false;
                }
            }
            if (!controller.isOtherRanking) {
                total = requiredFields.length;
                i = 0;
                for (i; i < total; i++) {
                    value = requiredFields[i];
                    if (isEmpty(value)) {
                        StatisticsSubmissionsFactory.setInvalidIndicators('number_of_students', true);
                        StatisticsSubmissionsFactory.setInvalidIndicators('qs_employment_rate_auto', true);
                        NotifierFactory.show(
                            'error',
                            'Invalid data for Employment statistics',
                            notificationHeader
                        );
                        return false;
                    }
                }
            }
            if (controller.isOtherRanking) {
                if (!isEmpty(controller.data.numberOfStudents.totalGraduatedStudents) ||
                    !isEmpty(controller.data.numberOfStudents.employed) ||
                    !isEmpty(controller.data.numberOfStudents.unemployed)) {
                    total = requiredFields.length;
                    i = 0;
                    for (i; i < total; i++) {
                        value = requiredFields[i];
                        if (isEmpty(value)) {
                            StatisticsSubmissionsFactory.setInvalidIndicators('number_of_students', true);
                            StatisticsSubmissionsFactory.setInvalidIndicators('qs_employment_rate_auto', true);
                            NotifierFactory.show(
                                'error',
                                'Invalid data for Employment statistics',
                                notificationHeader
                            );
                            return false;
                        }
                    }
                }

            }
            total = greaterFields.length;
            i = 0;
            for (i; i < total; i++) {
                if (controller.isGreater(greaterFields[i])) {
                    StatisticsSubmissionsFactory.setInvalidIndicators('number_of_students', true);
                    StatisticsSubmissionsFactory.setInvalidIndicators('qs_employment_rate_auto', true);
                    NotifierFactory.show(
                        'error',
                        'Invalid data for Employment statistics',
                        notificationHeader
                    );
                    return false;
                }
            }

            if (!controller.isEmploymentRateAutoValid) {
                StatisticsSubmissionsFactory.setInvalidIndicators('number_of_students', true);
                StatisticsSubmissionsFactory.setInvalidIndicators('qs_employment_rate_auto', true);
                NotifierFactory.show(
                    'error',
                    'Invalid data for Employment statistics',
                    notificationHeader
                );
                return false;
            }

            if( controller.isLessThanTwentyPercent(controller.data.numberOfStudents.totalRespondents)){
                NotifierFactory.show(
                    'warning',
                    'If Total Respondents is less than 20% of Total Graduates, such a survey is treated as not representative',
                    notificationHeader
                );
               // return false;
            }

            return true;
        }

        function isEmpty(value) {
            return typeof value === 'undefined' || value === '';
        }

        controller.hasAnswers = function (answerHandle) {
            
            switch (answerHandle) {
                case 'number_of_students':
                    if (typeof controller.data.numberOfStudents !== 'undefined') {
                        return (typeof controller.data.numberOfStudents.totalGraduatedStudents !== 'undefined' && controller.data.numberOfStudents.totalGraduatedStudents !== null &&  controller.data.numberOfStudents.totalGraduatedStudents.toString().length > 0) ||
                        (typeof controller.data.numberOfStudents.totalRespondents !== 'undefined' && controller.data.numberOfStudents.totalRespondents !== null &&  controller.data.numberOfStudents.totalRespondents.toString().length > 0) ||
                        (typeof controller.data.numberOfStudents.unemployed !== 'undefined' && controller.data.numberOfStudents.unemployed !== null && controller.data.numberOfStudents.unemployed.toString().length > 0) ||
                        (typeof controller.data.numberOfStudents.employed !== 'undefined' && controller.data.numberOfStudents.employed !== null && controller.data.numberOfStudents.employed.toString().length > 0) ||
                        (typeof controller.data.numberOfStudents.unavailable !== 'undefined' && controller.data.numberOfStudents.unavailable !== null && controller.data.numberOfStudents.unavailable.toString().length > 0) ||
                        (typeof controller.data.numberOfStudents.inFurtherStudy !== 'undefined' && controller.data.numberOfStudents.inFurtherStudy !== null && controller.data.numberOfStudents.inFurtherStudy.toString().length > 0);
                    }
                    break;

                case 'qs_employment_rate_auto':
                    if (typeof controller.data.percentages !== 'undefined') {
                        return (controller.data.percentages.employmentRate !== null && typeof controller.data.percentages.employmentRate !== 'undefined' && controller.data.percentages.employmentRate.toString().length > 0);
                    }
                    break;

                case 'evidence_when_shared':
                    return (controller.whenShared !== null && typeof controller.whenShared !== 'undefined' && controller.whenShared.toString().length > 0);

                case 'evidence_which_year_conducted':
                    return (controller.whichYearConducted !== null && typeof controller.whichYearConducted !== 'undefined' && controller.whichYearConducted.toString().length > 0);

                case 'evidence_link_or_screenshot':
                    if (typeof controller.data.evidence !== 'undefined') {
                        return (controller.data.evidence.url !== null && typeof controller.data.evidence.url !== 'undefined' && controller.data.evidence.url.toString().length > 0);
                    }
                    break;

                case 'number_of_students_entrepreneurship':
                    if (typeof controller.data.numberOfStudents !== 'undefined') {
                        return (controller.data.numberOfStudents.entrepreneurship !== null && typeof controller.data.numberOfStudents.entrepreneurship !== 'undefined' && controller.data.numberOfStudents.entrepreneurship.toString().length > 0);
                    }
                    break;
                case 'median_grad_salary':
                    if (typeof controller.data.medianGradSalary !== 'undefined') {
                        return (controller.data.medianGradSalary.salary !== null && typeof controller.data.medianGradSalary.salary !== 'undefined' && controller.data.medianGradSalary.salary.toString().length > 0);
                    }
                    break;

                case 'number_of_students_entrepreneurship_auto':
                    if (typeof controller.data.percentages !== 'undefined') {
                        return (controller.data.percentages.entrepreneurship !== null && typeof controller.data.percentages.entrepreneurship !== 'undefined' &&  controller.data.percentages.entrepreneurship.toString().length > 0);
                    }
                    break;
            }
        };

        function generateNumberOfstudents() {
            if (!controller.data.numberOfStudents) {
                return {};
            }
            return {
                status: controller.data.numberOfStudents.status ? controller.data.numberOfStudents.status : null,
                source: controller.data.numberOfStudents.source ? controller.data.numberOfStudents.source : null,
                answerId: getAnswerId('number_of_students'),
                question: {
                    handle: 'number_of_students'
                },
                answers: {
                    employed : {
                        value: controller.data.numberOfStudents.employed ||  controller.data.numberOfStudents.employed === 0 ? isInt(controller.data.numberOfStudents.employed) : null,
                        valueAsString: controller.data.numberOfStudents.employed || controller.data.numberOfStudents.employed === 0 ? controller.data.numberOfStudents.employed.toString() : null,
                        estimate: angular.isDefined(controller.data.numberOfStudents.estimate) ? controller.data.numberOfStudents.estimate : false
                    },
                    inFurtherStudy : {
                        value: controller.data.numberOfStudents.inFurtherStudy || controller.data.numberOfStudents.inFurtherStudy === 0 ? isInt(controller.data.numberOfStudents.inFurtherStudy) : null,
                        valueAsString: controller.data.numberOfStudents.inFurtherStudy || controller.data.numberOfStudents.inFurtherStudy === 0 ? controller.data.numberOfStudents.inFurtherStudy.toString() : null,
                        estimate: angular.isDefined(controller.data.numberOfStudents.estimate) ? controller.data.numberOfStudents.estimate : false
                    },
                    totalGraduatedStudents : {
                        value: controller.data.numberOfStudents.totalGraduatedStudents || controller.data.numberOfStudents.totalGraduatedStudents === 0 ? isInt(controller.data.numberOfStudents.totalGraduatedStudents) : null,
                        valueAsString: controller.data.numberOfStudents.totalGraduatedStudents || controller.data.numberOfStudents.totalGraduatedStudents === 0 ? controller.data.numberOfStudents.totalGraduatedStudents.toString() : null,
                        estimate: angular.isDefined(controller.data.numberOfStudents.estimate) ? controller.data.numberOfStudents.estimate : false
                    },
                    totalRespondents : {
                        value: controller.data.numberOfStudents.totalRespondents || controller.data.numberOfStudents.totalRespondents === 0 ? isInt(controller.data.numberOfStudents.totalRespondents) : null,
                        valueAsString: controller.data.numberOfStudents.totalRespondents || controller.data.numberOfStudents.totalRespondents === 0 ? controller.data.numberOfStudents.totalRespondents.toString() : null,
                        estimate: angular.isDefined(controller.data.numberOfStudents.estimate) ? controller.data.numberOfStudents.estimate : false
                    },
                    unavailable : {
                        value: controller.data.numberOfStudents.unavailable || controller.data.numberOfStudents.unavailable === 0 ? isInt(controller.data.numberOfStudents.unavailable) : null,
                        valueAsString: controller.data.numberOfStudents.unavailable || controller.data.numberOfStudents.unavailable === 0 ? controller.data.numberOfStudents.unavailable.toString() : null,
                        estimate: angular.isDefined(controller.data.numberOfStudents.estimate) ? controller.data.numberOfStudents.estimate : false
                    },
                    unemployed : {
                        value: controller.data.numberOfStudents.unemployed || controller.data.numberOfStudents.unemployed === 0 ? isInt(controller.data.numberOfStudents.unemployed) : null,
                        valueAsString: controller.data.numberOfStudents.unemployed || controller.data.numberOfStudents.unemployed === 0 ? controller.data.numberOfStudents.unemployed.toString() : null,
                        estimate: angular.isDefined(controller.data.numberOfStudents.estimate) ? controller.data.numberOfStudents.estimate : false
                    },
                    employedAuto : {
                        value: controller.data && controller.data.percentages && (controller.data.percentages.employed || controller.data.percentages.employed === 0) ? isInt(controller.data.percentages.employed) : null,
                        valueAsString: controller.data && controller.data.percentages && (controller.data.percentages.employed || controller.data.percentages.employed === 0) ? controller.data.percentages.employed.toString() : null,
                        estimate: false
                    },
                    inFurtherStudyAuto : {
                        value: controller.data && controller.data.percentages && (controller.data.percentages.inFurtherStudy || controller.data.percentages.inFurtherStudy === 0) ? isInt(controller.data.percentages.inFurtherStudy) : null,
                        valueAsString: controller.data && controller.data.percentages && (controller.data.percentages.inFurtherStudy || controller.data.percentages.inFurtherStudy === 0) ? controller.data.percentages.inFurtherStudy.toString() : null,
                        estimate: false
                    },
                    totalRespondentsAuto : {
                        value: controller.data && controller.data.percentages && (controller.data.percentages.totalRespondents || controller.data.percentages.totalRespondents === 0) ? isInt(controller.data.percentages.totalRespondents) : null,
                        valueAsString: controller.data && controller.data.percentages && (controller.data.percentages.totalRespondents || controller.data.percentages.totalRespondents === 0) ? controller.data.percentages.totalRespondents.toString() : null,
                        estimate: false
                    },
                    unavailableAuto : {
                        value: controller.data && controller.data.percentages && (controller.data.percentages.unavailable || controller.data.percentages.unavailable === 0) ? isInt(controller.data.percentages.unavailable) : null,
                        valueAsString: controller.data && controller.data.percentages && (controller.data.percentages.unavailable || controller.data.percentages.unavailable === 0) ? controller.data.percentages.unavailable.toString() : null,
                        estimate: false
                    },
                    unemployedAuto : {
                        value: controller.data && controller.data.percentages && (controller.data.percentages.unemployed || controller.data.percentages.unemployed === 0) ? isInt(controller.data.percentages.unemployed) : null,
                        valueAsString: controller.data && controller.data.percentages && (controller.data.percentages.unemployed || controller.data.percentages.unemployed === 0) ? controller.data.percentages.unemployed.toString() : null,
                        estimate: false
                    }
                }
            };
        }

        function isInt(n) {
            if (n % 1 === 0) {
                return parseInt(n);
            } else {
                return parseFloat(n);
            }
        }

        function generateQsEmploymentRateAuto() {
            if (!controller.data.percentages) {
                return {};
            }
            return {
                status: controller.data.numberOfStudents.status ? controller.data.numberOfStudents.status : null,
                source: controller.data.numberOfStudents.source ? controller.data.numberOfStudents.source : null,
                answerId: getAnswerId('qs_employment_rate_auto'),
                question: {
                    handle: 'qs_employment_rate_auto'
                },
                answers: {
                    employmentRate : {
                        value: controller.data.percentages.employmentRate || controller.data.percentages.employmentRate === 0 ? isInt(controller.data.percentages.employmentRate) : null,
                        valueAsString: controller.data.percentages.employmentRate || controller.data.percentages.employmentRate === 0 ? controller.data.percentages.employmentRate.toString() : null,
                        estimate: angular.isDefined(controller.data.numberOfStudents.estimate) ? controller.data.numberOfStudents.estimate : false
                    }
                }
            };
        }

        function generateMedianGradSalary() {
            if (!controller.data.medianGradSalary) {
                return {};
            }
            return {
                status: controller.data.medianGradSalary.status ? controller.data.medianGradSalary.status : null,
                source: controller.data.medianGradSalary.source ? controller.data.medianGradSalary.source : null,
                answerId: getAnswerId('median_grad_salary'),
                question: {
                    handle: 'median_grad_salary'
                },
                answers: {
                    salary : {
                        value: controller.data.medianGradSalary.salary || controller.data.medianGradSalary.salary === 0 ? isInt(controller.data.medianGradSalary.salary) : null,
                        valueAsString: controller.data.medianGradSalary.salary || controller.data.medianGradSalary.salary === 0 ? controller.data.medianGradSalary.salary.toString() : null,
                        estimate: angular.isDefined(controller.data.medianGradSalary.estimate) ? controller.data.medianGradSalary.estimate : false
                    }
                }
            };
        }

        function generateEvidenceWhenShared() {
            if (!controller.data.evidence) {
                return {};
            }
            return {
                status: controller.data.evidence.whenSharedStats && controller.data.evidence.whenSharedStats.status ? controller.data.evidence.whenSharedStats.status : null,
                source: controller.data.evidence.whenSharedStats && controller.data.evidence.whenSharedStats.source ? controller.data.evidence.whenSharedStats.source : null,
                answerId: getAnswerId('evidence_when_shared'),
                question: {
                    handle: 'evidence_when_shared'
                },
                answers: {
                    whenShared : {
                        value: controller.data.evidence.whenShared ? controller.data.evidence.whenShared : null,
                        valueAsString: controller.data.evidence.whenShared ? controller.data.evidence.whenShared.toString() : null,
                        estimate: angular.isDefined(controller.data.evidence.whenSharedEstimate) ? controller.data.evidence.whenSharedEstimate : false
                    }
                }
            };
        }

        function generateEvidenceWhichYearConducted() {
            if (!controller.data.evidence) {
                return {};
            }
            return {
                status: controller.data.evidence.whichYearConductedStats && controller.data.evidence.whichYearConductedStats.status ? controller.data.evidence.whichYearConductedStats.status : null,
                source: controller.data.evidence.whichYearConductedStats && controller.data.evidence.whichYearConductedStats.source ? controller.data.evidence.whichYearConductedStats.source : null,
                answerId: getAnswerId('evidence_which_year_conducted'),
                question: {
                    handle: 'evidence_which_year_conducted'
                },
                answers: {
                    whichYearConducted : {
                        value: controller.data.evidence.whichYearConducted ? parseInt(controller.data.evidence.whichYearConducted) : null,
                        valueAsString: controller.data.evidence.whichYearConducted ? controller.data.evidence.whichYearConducted.toString() : null,
                        estimate: angular.isDefined(controller.data.evidence.whichYearConductedEstimate) ? controller.data.evidence.whichYearConductedEstimate : false
                    }
                }
            };
        }

        function generateEvidenceLinkScreenshot() {
            if (!controller.data.evidence) {
                return {};
            }
            return {
                status: controller.data.evidence.urlStats && controller.data.evidence.urlStats.status ? controller.data.evidence.urlStats.status : null,
                source: controller.data.evidence.urlStats && controller.data.evidence.urlStats.source ? controller.data.evidence.urlStats.source : null,
                answerId: getAnswerId('evidence_link_or_screenshot'),
                question: {
                    handle: 'evidence_link_or_screenshot'
                },
                answers: {
                    url : {
                        value: controller.data.evidence.url ? controller.data.evidence.url : null,
                        valueAsString: controller.data.evidence.url ? controller.data.evidence.url.toString() : null,
                        estimate: angular.isDefined(controller.data.evidence.urlEstimate) ? controller.data.evidence.urlEstimate : false
                    }
                }
            };
        }

        function generateNumberStudentsEntrepreneurship() {
            if (!controller.data.numberOfStudents) {
                return {};
            }
            return {
                status: controller.data.numberOfStudents.entrepreneurshipStats && controller.data.numberOfStudents.entrepreneurshipStats.status ? controller.data.numberOfStudents.entrepreneurshipStats.status : null,
                source: controller.data.numberOfStudents.entrepreneurshipStats && controller.data.numberOfStudents.entrepreneurshipStats.source ? controller.data.numberOfStudents.entrepreneurshipStats.source : null,
                answerId: getAnswerId('number_of_students_entrepreneurship'),
                question: {
                    handle: 'number_of_students_entrepreneurship'
                },
                answers: {
                    entrepreneurship : {
                        value: controller.data.numberOfStudents.entrepreneurship || controller.data.numberOfStudents.entrepreneurship === 0 ? parseInt(controller.data.numberOfStudents.entrepreneurship) : null,
                        valueAsString: controller.data.numberOfStudents.entrepreneurship || controller.data.numberOfStudents.entrepreneurship === 0 ? controller.data.numberOfStudents.entrepreneurship.toString() : null,
                        estimate: angular.isDefined(controller.data.numberOfStudents.entrepreneurshipEstimate) ? controller.data.numberOfStudents.entrepreneurshipEstimate : false
                    }
                }
            };
        }

        function generateNumberStudentsEntrepreneurshipAuto() {
            if (!controller.data.percentages) {
                return {};
            }
            return {
                status: controller.data.numberOfStudents.entrepreneurshipStats && controller.data.numberOfStudents.entrepreneurshipStats.status ? controller.data.numberOfStudents.entrepreneurshipStats.status : null,
                source: controller.data.numberOfStudents.entrepreneurshipStats && controller.data.numberOfStudents.entrepreneurshipStats.source ? controller.data.numberOfStudents.entrepreneurshipStats.source : null,
                answerId: getAnswerId('number_of_students_entrepreneurship_auto'),
                question: {
                    handle: 'number_of_students_entrepreneurship_auto'
                },
                answers: {
                    entrepreneurshipAuto : {
                        value: controller.data.percentages.entrepreneurship || controller.data.percentages.entrepreneurship === 0 ? parseFloat(controller.data.percentages.entrepreneurship) : null,
                        valueAsString: controller.data.percentages.entrepreneurship || controller.data.percentages.entrepreneurship === 0 ? controller.data.percentages.entrepreneurship.toString() : null,
                        estimate: angular.isDefined(controller.data.numberOfStudents.entrepreneurshipEstimate) ? controller.data.numberOfStudents.entrepreneurshipEstimate : false
                    }
                }
            };
        }

        function getAnswerId(handle) {
            return typeof controller.answerIds[handle] !== 'undefined' ? controller.answerIds[handle] : null;
        }

        function generateAnswer(answerHandle) {
            switch (answerHandle) {
                case 'number_of_students':
                    return generateNumberOfstudents();
                case 'median_grad_salary':
                    return generateMedianGradSalary();
                case 'qs_employment_rate_auto':
                    return generateQsEmploymentRateAuto();
                case 'evidence_when_shared':
                    return generateEvidenceWhenShared();
                case 'evidence_which_year_conducted':
                    return generateEvidenceWhichYearConducted();
                case 'evidence_link_or_screenshot':
                    return generateEvidenceLinkScreenshot();
                case 'number_of_students_entrepreneurship':
                    return generateNumberStudentsEntrepreneurship();
                case 'number_of_students_entrepreneurship_auto':
                    return generateNumberStudentsEntrepreneurshipAuto();
            }
        }

        function generateAnswers() {
            var total = controller.answerHandles.length,
                i = 0,
                data = [];
            for (i; i < total; i++) {
                if (controller.answerHandles[i].handle !== 'number_of_students_auto') {
                    data.push(generateAnswer(controller.answerHandles[i].handle));
                }
            }

            return {
                data: data
            };
        }

        function getDataOfNumberOfStudents(data) {
            if (!data.numberOfStudents) {
                return {};
            }
            return {
                status: data.numberOfStudents.status ? data.numberOfStudents.status : null,
                source: data.numberOfStudents.source ? data.numberOfStudents.source : null,
                answerId: getAnswerId('number_of_students'),
                question: {
                    handle: 'number_of_students'
                },
                answers: {
                    employed: {
                        value: data.numberOfStudents.employed || data.numberOfStudents.employed === 0 ? isInt(data.numberOfStudents.employed) : null,
                        valueAsString: data.numberOfStudents.employed || data.numberOfStudents.employed === 0 ? data.numberOfStudents.employed.toString() : null,
                        estimate: angular.isDefined(data.numberOfStudents.estimate) ? data.numberOfStudents.estimate : false
                    },
                    inFurtherStudy: {
                        value: data.numberOfStudents.inFurtherStudy || data.numberOfStudents.inFurtherStudy === 0 ? isInt(data.numberOfStudents.inFurtherStudy) : null,
                        valueAsString: data.numberOfStudents.inFurtherStudy || data.numberOfStudents.inFurtherStudy === 0 ? data.numberOfStudents.inFurtherStudy.toString() : null,
                        estimate: angular.isDefined(data.numberOfStudents.estimate) ? data.numberOfStudents.estimate : false
                    },
                    totalGraduatedStudents: {
                        value: data.numberOfStudents.totalGraduatedStudents || data.numberOfStudents.totalGraduatedStudents === 0 ? isInt(data.numberOfStudents.totalGraduatedStudents) : null,
                        valueAsString: data.numberOfStudents.totalGraduatedStudents || data.numberOfStudents.totalGraduatedStudents === 0 ? data.numberOfStudents.totalGraduatedStudents.toString() : null,
                        estimate: angular.isDefined(data.numberOfStudents.estimate) ? data.numberOfStudents.estimate : false
                    },
                    totalRespondents: {
                        value: data.numberOfStudents.totalRespondents || data.numberOfStudents.totalRespondents === 0 ? isInt(data.numberOfStudents.totalRespondents) : null,
                        valueAsString: data.numberOfStudents.totalRespondents || data.numberOfStudents.totalRespondents === 0 ? data.numberOfStudents.totalRespondents.toString() : null,
                        estimate: angular.isDefined(data.numberOfStudents.estimate) ? data.numberOfStudents.estimate : false
                    },
                    unavailable: {
                        value: data.numberOfStudents.unavailable || data.numberOfStudents.unavailable === 0 ? isInt(data.numberOfStudents.unavailable) : null,
                        valueAsString: data.numberOfStudents.unavailable || data.numberOfStudents.unavailable === 0 ? data.numberOfStudents.unavailable.toString() : null,
                        estimate: angular.isDefined(data.numberOfStudents.estimate) ? data.numberOfStudents.estimate : false
                    },
                    unemployed: {
                        value: data.numberOfStudents.unemployed || data.numberOfStudents.unemployed === 0 ? isInt(data.numberOfStudents.unemployed) : null,
                        valueAsString: data.numberOfStudents.unemployed || data.numberOfStudents.unemployed === 0 ? data.numberOfStudents.unemployed.toString() : null,
                        estimate: angular.isDefined(data.numberOfStudents.estimate) ? data.numberOfStudents.estimate : false
                    },
                    employedAuto: {
                        value: data && data.percentages && (data.percentages.employed || data.percentages.employed === 0) ? isInt(data.percentages.employed) : null,
                        valueAsString: data && data.percentages && (data.percentages.employed || data.percentages.employed === 0) ? data.percentages.employed.toString() : null,
                        estimate: false
                    },
                    inFurtherStudyAuto: {
                        value: data && data.percentages && (data.percentages.inFurtherStudy || data.percentages.inFurtherStudy === 0) ? isInt(data.percentages.inFurtherStudy) : null,
                        valueAsString: data && data.percentages && (data.percentages.inFurtherStudy || data.percentages.inFurtherStudy === 0) ? data.percentages.inFurtherStudy.toString() : null,
                        estimate: false
                    },
                    totalRespondentsAuto: {
                        value: data && data.percentages && (data.percentages.totalRespondents || data.percentages.totalRespondents === 0) ? isInt(data.percentages.totalRespondents) : null,
                        valueAsString: data && data.percentages && (data.percentages.totalRespondents || data.percentages.totalRespondents === 0) ? data.percentages.totalRespondents.toString() : null,
                        estimate: false
                    },
                    unavailableAuto: {
                        value: data && data.percentages && (data.percentages.unavailable || data.percentages.unavailable === 0) ? isInt(data.percentages.unavailable) : null,
                        valueAsString: data && data.percentages && (data.percentages.unavailable || data.percentages.unavailable === 0) ? data.percentages.unavailable.toString() : null,
                        estimate: false
                    },
                    unemployedAuto: {
                        value: data && data.percentages && (data.percentages.unemployed || data.percentages.unemployed === 0) ? isInt(data.percentages.unemployed) : null,
                        valueAsString: data && data.percentages && (data.percentages.unemployed || data.percentages.unemployed === 0) ? data.percentages.unemployed.toString() : null,
                        estimate: false
                    }
                }
            };
        }

        function getDataOfQsEmploymentRateAuto(data) {
            if (!data.percentages) {
                return {};
            }
            return {
                status: data.numberOfStudents.status ? data.numberOfStudents.status : null,
                source: data.numberOfStudents.source ? data.numberOfStudents.source : null,
                answerId: getAnswerId('qs_employment_rate_auto'),
                question: {
                    handle: 'qs_employment_rate_auto'
                },
                answers: {
                    employmentRate : {
                        value: data.percentages.employmentRate || data.percentages.employmentRate === 0 ? isInt(data.percentages.employmentRate) : null,
                        valueAsString: data.percentages.employmentRate || data.percentages.employmentRate === 0 ? data.percentages.employmentRate.toString() : null,
                        estimate: angular.isDefined(data.numberOfStudents.estimate) ? data.numberOfStudents.estimate : false
                    }
                }
            };
        }

        function getDataOfMedianGradSalary(medianGradSalary) {
            if (!medianGradSalary) {
                return {};
            }
            return {
                status: medianGradSalary.status ? medianGradSalary.status : null,
                source: medianGradSalary.source ? medianGradSalary.source : null,
                answerId: getAnswerId('median_grad_salary'),
                question: {
                    handle: 'median_grad_salary'
                },
                answers: {
                    salary : {
                        value: medianGradSalary.salary || medianGradSalary.salary === 0 ? isInt(medianGradSalary.salary) : null,
                        valueAsString: medianGradSalary.salary || medianGradSalary.salary === 0 ? medianGradSalary.salary.toString() : null,
                        estimate: angular.isDefined(medianGradSalary.estimate) ? medianGradSalary.estimate : false
                    }
                }
            };
        }

        function getDataOfNumberStudentsEntrepreneurship(data) {
            if (!data.numberOfStudents) {
                return {};
            }
            return {
                status: data.numberOfStudents.entrepreneurshipStats && data.numberOfStudents.entrepreneurshipStats.status ? data.numberOfStudents.entrepreneurshipStats.status : null,
                source: data.numberOfStudents.entrepreneurshipStats && data.numberOfStudents.entrepreneurshipStats.source ? data.numberOfStudents.entrepreneurshipStats.source : null,
                answerId: getAnswerId('number_of_students_entrepreneurship'),
                question: {
                    handle: 'number_of_students_entrepreneurship'
                },
                answers: {
                    entrepreneurship : {
                        value: data.numberOfStudents.entrepreneurship || data.numberOfStudents.entrepreneurship === 0 ? parseInt(data.numberOfStudents.entrepreneurship) : null,
                        valueAsString: data.numberOfStudents.entrepreneurship || data.numberOfStudents.entrepreneurship === 0 ? data.numberOfStudents.entrepreneurship.toString() : null,
                        estimate: angular.isDefined(data.numberOfStudents.entrepreneurshipEstimate) ? data.numberOfStudents.entrepreneurshipEstimate : false
                    }
                }
            };
        }
        
        function getDataOfNumberStudentsEntrepreneurshipAuto(data) {
            if (!data.percentages) {
                return {};
            }
            return {
                status: data.numberOfStudents.entrepreneurshipStats && data.numberOfStudents.entrepreneurshipStats.status ? data.numberOfStudents.entrepreneurshipStats.status : null,
                source: data.numberOfStudents.entrepreneurshipStats && data.numberOfStudents.entrepreneurshipStats.source ? data.numberOfStudents.entrepreneurshipStats.source : null,
                answerId: getAnswerId('number_of_students_entrepreneurship_auto'),
                question: {
                    handle: 'number_of_students_entrepreneurship_auto'
                },
                answers: {
                    entrepreneurshipAuto : {
                        value: data.percentages.entrepreneurship || data.percentages.entrepreneurship === 0 ? parseFloat(data.percentages.entrepreneurship) : null,
                        valueAsString: data.percentages.entrepreneurship || data.percentages.entrepreneurship === 0 ? data.percentages.entrepreneurship.toString() : null,
                        estimate: angular.isDefined(data.numberOfStudents.entrepreneurshipEstimate) ? data.numberOfStudents.entrepreneurshipEstimate : false
                    }
                }
            };
        }

        controller.handleSaveClick = function () {
            submitInProgress = true;
            controller.submitted = true;
            if(controller.isOtherRanking && !controller.isParentValid()){
                submitInProgress = false;
                return false;
            }
            if(controller.isOtherRanking) {
                controller.handleParentSave();
             }
            if (!isValid()) {
                $timeout(function() {
                    angular.element('.modal-dialog .modal-footer button.btn.btn-default').triggerHandler('click');
                });
                submitInProgress = false;
                return false;
            }
            
            if (controller.isClient && UnsavedChangesFactory.hasChanges()) {
                    var modalOptions = {
                        handleNoClick: function () {
                            submitInProgress = false;
                            this.close();
                        },
                        handleYesClick: function () {
                            controller.handleSave();
                        }
                    }, modalDefaults = {
                        backdrop: 'static',
                        keyboard: false,
                        modalFade: true,
                        templateUrl: '/scripts/modules/rankings/components/statisticsSubmissions/unEdittedIndicatorsDialog.html'
                    };
                    ModalService.show(modalDefaults, modalOptions);
            }
            else {
                controller.handleSave();
            }
        };

        controller.handleSave = function (){
            var data = generateAnswers(),
            tempValues = null;
            // angular.forEach(data.data, function (key) {
            //     var questionHandle = key.question.handle;
            //     if (StatisticsSubmissionsFactory.getInvalidIndicator(questionHandle)) {
            //         tempValues = null;
            //         StatisticsSubmissionsFactory.setInvalidIndicatorsData(questionHandle, angular.copy(key));
            //         if (key.question.handle === 'number_of_students') {
            //             tempValues = getDataOfNumberOfStudents(StatisticsSubmissionsFactory.getGerPreviousAnswers()['numberOfStudents']);
            //         }
            //         if (key.question.handle === 'qs_employment_rate_auto') {
            //             tempValues = getDataOfQsEmploymentRateAuto(StatisticsSubmissionsFactory.getGerPreviousAnswers()['percentages']);
            //         }
            //         if (key.question.handle === 'median_grad_salary') {
            //             tempValues = getDataOfMedianGradSalary(StatisticsSubmissionsFactory.getGerPreviousAnswers()['medianGradSalary']);
            //         }
                 
            //         if (key.question.handle === 'number_of_students_entrepreneurship') {
            //             tempValues = getDataOfNumberStudentsEntrepreneurship(StatisticsSubmissionsFactory.getGerPreviousAnswers()['numberOfStudents']);
            //         }
            //         if (key.question.handle === 'number_of_students_entrepreneurship_auto') {
            //             tempValues = getDataOfNumberStudentsEntrepreneurshipAuto(StatisticsSubmissionsFactory.getGerPreviousAnswers()['numberOfStudents']);
            //         }
            //         key = tempValues ? tempValues : key;
            //     }
            // });
           
            UnsavedChangesFactory.resetChanges();
            GerEmploymentRateService.replace(
                InstitutionFactory.getCoreId(),
                InstitutionFactory.getDisplayName(),
                InstitutionFactory.getCountryCode(),
                data
            ).then(function (response) {
                StatisticsHistoryFactory.setReload(true);
                if (response && (response.insertCount || response.modifiedCount)) {
                    StatisticsSubmissionsFactory.setEmploymentRateModified(true);
                    if(!controller.isOtherRanking) {
                        NotifierFactory.show(
                            'success',
                            'Data saved successfully.',
                            'Employment Statistics'
                        );
                    }
                    UnsavedChangesFactory.setIsSaved();
                    StatisticsSubmissionsFactory.resetExplanationRequiredQuestions();
                    if (response.path) {
                        controller.data.evidence.url = response.path;
                    }
                    loadAnswers(InstitutionFactory.getCoreId());
                    controller.dataWatcher();
                    StatisticsHistoryService.clearCache();
                } else if (response === false) {
                    StatisticsSubmissionsFactory.setEmploymentRateModified(false);
                    UnsavedChangesFactory.setSaveError();
                    if(!controller.isOtherRanking) {
                        NotifierFactory.show(
                            'error',
                            'Can\'t save data at the moment. Please try again later.',
                            'Employment Statistics'
                        );
                    }
                }
                submitInProgress = false;
                $timeout(function() {
                    UnsavedChangesFactory.clearIsSaved();
                    angular.element('.modal-dialog .modal-footer button.btn.btn-default').triggerHandler('click');
                });
            });
        };

        controller.isMandatory = function (value) {
            if(controller.isOtherRanking) {
                if (controller.data && controller.data.numberOfStudents && ( !isEmpty(controller.data.numberOfStudents.totalGraduatedStudents) ||
                !isEmpty(controller.data.numberOfStudents.employed) ||
                !isEmpty(controller.data.numberOfStudents.unemployed))) {
                    return controller.submitted && isEmpty(value);
                }
                else {
                    return controller.submitted && isEmpty(value) && !controller.isOtherRanking;
                }
            }
            else {
                return controller.submitted && isEmpty(value) && !controller.isOtherRanking;
            }
        };

        controller.isGreater = function (value) {
            return controller.submitted &&
                controller.data &&
                controller.data.numberOfStudents && (
                (controller.data.numberOfStudents.totalGraduatedStudents && parseInt(controller.data.numberOfStudents.totalGraduatedStudents, 10) < parseInt(value, 10)) ||
                (controller.data.numberOfStudents.totalRespondents && parseInt(controller.data.numberOfStudents.totalRespondents, 10) < parseInt(value, 10))
            );
        };

        controller.isLessThanTwentyPercent = function (value) {
            return controller.submitted &&
                controller.data &&
                controller.data.numberOfStudents && (
                (controller.data.numberOfStudents.totalGraduatedStudents && parseInt(controller.data.numberOfStudents.totalGraduatedStudents, 10) * 0.20 > parseInt(value, 10))
            );
        };
        

        controller.onKeyUp = function ($event) {
            if ($event.keyCode === 13) {
                return;
            }
            var url = $event.target.value,
                variableName = $event.target.getAttribute('ng-model');

            if (url && url.length > 5 && (url.indexOf('http') !== 0 || url.indexOf('http') === -1)) {
                url = UrlService.prependHttp(url);
                if (variableName) {
                    if (variableName.includes('$ctrl')) {
                        variableName = variableName.replace('$ctrl.','');
                    }
                    ObjectService.set(controller, variableName, url);
                }
            }
        };

        controller.calculatePercentages = function () {
            if (controller.data) {
                GerEmploymentRateService.calculatePercentages(controller.data);
                if (parseInt(controller.beforeChangesEmploymentRate) > 0) {
                    var localValue = parseFloat(controller.data.percentages.employmentRate);
                    var compareValue = parseInt(localValue);
                    var upper = parseInt(controller.beforeChangesEmploymentRate) * (1 + (25 / 100)),
                        lower = parseInt(controller.beforeChangesEmploymentRate) * (1 - (25 / 100));
                    controller.isEmploymentRateAutoValid = !(compareValue > upper || compareValue < lower);
                    if (controller.isExplanationProvided) {
                        controller.isEmploymentRateAutoValid = true;

                    }
                }
            }
        };

        controller.isInvalidEmploymentRate = function () {
            return !controller.isEmploymentRateAutoValid;
        };

        controller.isSavingDisabled = function () {
            return controller.uploadInProgress || submitInProgress;
        };

        controller.isActive = function (questionHandle) {
            return activeQuestion === questionHandle;
        };

        controller.handleFocus = function (questionHandle) {
            if(controller.isOtherRanking) {
                StatisticsSubmissionsFactory.setActiveQuestionId(11111);
            }
            activeQuestion = questionHandle;
            var definition = null;
            angular.forEach(controller.questions, function (question) {
                if (question.handle === 'employment_rate') {
                    definition = question.definition;
                }
            });
            StatisticsDashboardFactory.setActiveQuestion({
                handle: questionHandle,
                definition: definition
            });

            if ($location.search().question !== questionHandle) {
                $state.transitionTo($state.current, {question: questionHandle}, {notify: false});
            }
        };

        controller.handleWhenSharedChanges = function () {
            if (controller.data && controller.data.evidence) {
                controller.data.evidence.whenShared = controller.whenShared ? controller.whenShared.handle : null;
            }
        };

        controller.handleWhichYearConductedChanges = function () {
            if (controller.data && controller.data.evidence) {
                controller.data.evidence.whichYearConducted = controller.whichYearConducted ? controller.whichYearConducted.handle : null;
            }
        };

        controller.showOverlay = function () {
            return dataToLoad !== 0;
        };

        function generateAnswersResponse(results) {
            controller.data.numberOfStudents = {
                status:null,
                entrepreneurshipStats: {
                    status:null
                }
            };
            controller.data.medianGradSalary = {
                status:null,
                source: null,
            };
            controller.data.percentages = {
                status:null,
                entrepreneurshipStats: {
                    status:null
                }
            };
            controller.data.evidence = {};
            var i = 0,
                total = results.length;
            controller.answerIds = {};
            for (i; i < total; i++) {
                if (results[i].answers) {
                    switch (results[i].questionHandle) {
                        case 'number_of_students':
                            generateNumberOfstudentsResponse(results[i].answers);
                            controller.data.numberOfStudents.status = results[i].status;
                            controller.data.numberOfStudents.source = results[i].source;
                            controller.answerIds.number_of_students = typeof results[i].id === 'undefined' ? null : results[i].id;
                            break;
                        case 'median_grad_salary':
                            generateMedianGradSalaryResponse(results[i].answers);
                            controller.data.medianGradSalary.status = results[i].status;
                            controller.data.medianGradSalary.source = results[i].source;
                            controller.answerIds.median_grad_salary = typeof results[i].id === 'undefined' ? null : results[i].id;
                            break;
                        case 'qs_employment_rate_auto':
                            generateQsEmploymentRateAutoResponse(results[i].answers);
                            controller.data.percentages.status = results[i].status;
                            controller.answerIds.qs_employment_rate_auto = typeof results[i].id === 'undefined' ? null : results[i].id;
                            break;
                        case 'evidence_when_shared':
                            generateEvidenceWhenSharedResponse(results[i].answers);
                            controller.data.evidence.whenSharedStats = {};
                            controller.data.evidence.whenSharedStats.status = results[i].status;
                            controller.data.evidence.whenSharedStats.source = results[i].source;
                            controller.answerIds.evidence_when_shared = typeof results[i].id === 'undefined' ? null : results[i].id;
                            break;
                        case 'evidence_which_year_conducted':
                            generateEvidenceWhichYearConductedResponse(results[i].answers);
                            controller.data.evidence.whichYearConductedStats = {};
                            controller.data.evidence.whichYearConductedStats.status = results[i].status;
                            controller.data.evidence.whichYearConductedStats.source = results[i].source;
                            controller.answerIds.evidence_which_year_conducted = typeof results[i].id === 'undefined' ? null : results[i].id;
                            break;
                        case 'evidence_link_or_screenshot':
                            generateEvidenceLinkScreenshotResponse(results[i].answers);
                            controller.data.evidence.urlStats = {};
                            controller.data.evidence.urlStats.status = results[i].status;
                            controller.data.evidence.urlStats.source = results[i].source;
                            controller.answerIds.evidence_link_or_screenshot = typeof results[i].id === 'undefined' ? null : results[i].id;
                            break;
                        case 'number_of_students_entrepreneurship':
                            generateNumberStudentsEntrepreneurshipResponse(results[i].answers);
                            controller.data.numberOfStudents.entrepreneurshipStats = {};
                            controller.data.numberOfStudents.entrepreneurshipStats.status = results[i].status;
                            controller.data.numberOfStudents.entrepreneurshipStats.source = results[i].source;
                            controller.answerIds.number_of_students_entrepreneurship = typeof results[i].id === 'undefined' ? null : results[i].id;
                            break;
                        case 'number_of_students_entrepreneurship_auto':
                            generateNumberStudentsEntrepreneurshipAutoResponse(results[i].answers);
                            controller.data.percentages.entrepreneurshipStats.status = results[i].status;
                            controller.data.percentages.entrepreneurshipStats.source = results[i].source;
                            controller.answerIds.number_of_students_entrepreneurship_auto = typeof results[i].id === 'undefined' ? null : results[i].id;
                            break;
                    }
                }
            }
            $timeout(function() {
                StatisticsSubmissionsFactory.setGerPreviousAnswers(angular.copy(controller.data));
                controller.dataWatcher = WatchService.create($scope, '$ctrl.data', dataWatch, true);
            });
        }

        function generateNumberStudentsEntrepreneurshipAutoResponse(answers) {
            if (answers.entrepreneurshipAuto && (answers.entrepreneurshipAuto.valueAsString || parseInt(answers.entrepreneurshipAuto.valueAsString) === 0)) {
                controller.data.percentages.entrepreneurship = answers.entrepreneurshipAuto.valueAsString;
                controller.data.percentages.entrepreneurshipEstimate = answers.entrepreneurshipAuto.estimate;
            }
        }

        function generateNumberStudentsEntrepreneurshipResponse(answers) {
            if (answers.entrepreneurship && (answers.entrepreneurship.value || answers.entrepreneurship.value === 0)) {
                controller.data.numberOfStudents.entrepreneurship = answers.entrepreneurship.value;
                controller.data.numberOfStudents.entrepreneurshipEstimate = answers.entrepreneurship.estimate;
            }
        }

        function generateEvidenceLinkScreenshotResponse(answers) {
            if (answers.url && answers.url.value) {
                controller.data.evidence.url = answers.url.value;
                controller.data.evidence.urlEstimate = answers.url.estimate;
            }
        }

        function generateEvidenceWhenSharedResponse(answers) {
            if (answers.whenShared && answers.whenShared.value) {
                controller.data.evidence.whenShared = answers.whenShared.value;
                controller.data.evidence.whenSharedEstimate = answers.whenShared.estimate;
            }
        }

        function generateEvidenceWhichYearConductedResponse(answers) {
            if (answers.whichYearConducted && answers.whichYearConducted.value) {
                controller.data.evidence.whichYearConducted = answers.whichYearConducted.value;
                controller.data.evidence.whichYearConductedEstimate = answers.whichYearConducted.estimate;
            }
        }

        function generateQsEmploymentRateAutoResponse(answers) {
            
            if (answers.employmentRate && (answers.employmentRate.valueAsString || parseInt(answers.employmentRate.valueAsString) === 0)) {
                controller.data.percentages.employmentRate = answers.employmentRate.valueAsString;
                controller.beforeChangesEmploymentRate = answers.employmentRate.valueAsString;
                controller.data.percentages.employmentRateEstimate = answers.employmentRate.estimate;
            }
        }

        function generateMedianGradSalaryResponse(answers){
            if (answers.salary && (answers.salary.value || answers.salary.value === 0)) {
                controller.data.medianGradSalary.salary = answers.salary.value;
                controller.data.medianGradSalary.estimate = answers.salary.estimate;
            }
        }

        function generateNumberOfstudentsResponse(answers) {
            if (answers.totalGraduatedStudents && (answers.totalGraduatedStudents.value || answers.totalGraduatedStudents.value === 0)) {
                controller.data.numberOfStudents.totalGraduatedStudents = answers.totalGraduatedStudents.value;
                controller.data.numberOfStudents.estimate = answers.totalGraduatedStudents.estimate;
            }
            if (answers.employed && (answers.employed.value || answers.employed.value === 0)) {
                controller.data.numberOfStudents.employed = answers.employed.value;
            }
            if (answers.inFurtherStudy && (answers.inFurtherStudy.value || answers.inFurtherStudy.value === 0)) {
                controller.data.numberOfStudents.inFurtherStudy = answers.inFurtherStudy.value;
            }
            if (answers.totalRespondents && (answers.totalRespondents.value || answers.totalRespondents.value === 0)) {
                controller.data.numberOfStudents.totalRespondents = answers.totalRespondents.value;
            }
            if (answers.unavailable && (answers.unavailable.value || answers.unavailable.value === 0)) {
                controller.data.numberOfStudents.unavailable = answers.unavailable.value;
            }
            if (answers.unemployed && (answers.unemployed.value || answers.unemployed.value === 0)) {
                controller.data.numberOfStudents.unemployed = answers.unemployed.value;
            }
            // Percentage (Auto) %: Total respondents
            if (answers.totalRespondentsAuto && (answers.totalRespondentsAuto.valueAsString || parseInt(answers.totalRespondentsAuto.valueAsString) === 0)) {
                controller.data.percentages.totalRespondents = answers.totalRespondentsAuto.valueAsString;
            }
            // Percentage (Auto) %: Employed FT and/or PT
            if (answers.employedAuto && (answers.employedAuto.valueAsString || parseInt(answers.employedAuto.valueAsString) === 0)) {
                controller.data.percentages.employed = answers.employedAuto.valueAsString;
            }
            // Percentage (Auto) %: Unemployed but seeking employment
            if (answers.unemployedAuto && (answers.unemployedAuto.valueAsString || parseInt(answers.unemployedAuto.valueAsString) === 0)) {
                controller.data.percentages.unemployed = answers.unemployedAuto.valueAsString;
            }
            // Percentage (Auto) %: In FT further study
            if (answers.inFurtherStudyAuto && (answers.inFurtherStudyAuto.valueAsString || parseInt(answers.inFurtherStudyAuto.valueAsString) === 0)) {
                controller.data.percentages.inFurtherStudy = answers.inFurtherStudyAuto.valueAsString;
            }
            // Percentage (Auto) %: Unavailable for work (e.g. military service)
            if (answers.unavailableAuto && (answers.unavailableAuto.valueAsString || parseInt(answers.unavailableAuto.valueAsString) === 0)) {
                controller.data.percentages.unavailable = answers.unavailableAuto.valueAsString;
            }
        }

        function loadAnswers(institutionCoreId) {
            var questionHandle = [],
                i = 0,
                total = controller.answerHandles? controller.answerHandles.length : 0;
            for (i; i < total; i++) {
                questionHandle.push(controller.answerHandles[i].handle);
            }

            GerService.getEmploymentRate({
                'filter[institutionCoreId]' : institutionCoreId,
                'filter[questionHandle][]' : questionHandle
            }).then(function (data) {
                var results = data && data.results ? data.results : {};
                generateAnswersResponse(results);
                controller.whenShared = null;
                controller.whichYearConducted = null;
                if (controller.data.evidence) {
                    var i,
                        total;
                    if (controller.data.evidence.whenShared) {
                        i = 0;
                        total = controller.surveySharedWithStudentsOptions.length;
                        for (i; i < total; i++) {
                            if (controller.surveySharedWithStudentsOptions[i].handle === controller.data.evidence.whenShared) {
                                controller.whenShared = controller.surveySharedWithStudentsOptions[i];
                                break;
                            }
                        }
                    }
                    if (controller.data.evidence.whichYearConducted) {
                        i = 0;
                        total = controller.yearsOptions.length;
                        for (i; i < total; i++) {
                            if (controller.yearsOptions[i].handle === controller.data.evidence.whichYearConducted) {
                                controller.whichYearConducted = controller.yearsOptions[i];
                                break;
                            }
                        }
                    }
                }
            });
        }

        function coreIdWatch(institutionCoreId, oldInstitutionCoreId) {
            if (institutionCoreId) {
                if (institutionCoreId && oldInstitutionCoreId && institutionCoreId !== oldInstitutionCoreId) {
                    dataToLoad = 1;
                }
                if(institutionCoreIdInUrl) {
                    loadAnswers(institutionCoreIdInUrl);
                    dataToLoad--;
                   controller.getCoreIdWatch();
                    return;
                }
                loadAnswers(institutionCoreId);
                dataToLoad--;
            }
        }

        function dataWatch(data, oldData) {
            if (data && !angular.equals(data,oldData)) {
                UnsavedChangesFactory.setSaveFunction(controller.handleSaveClick, $scope);
                UnsavedChangesFactory.setChanges(true);
            }
        }

        function activeQuestionWatch(val) {
            if (val) {
                activeQuestion = val.handle;
            }
        }

        function explanationProvidedWatch (questionHandles) {
            controller.isExplanationProvided = false;
            if (questionHandles && questionHandles.length >0) {
                controller.isExplanationProvided = questionHandles.includes(activeQuestion);
                controller.isEmploymentRateAutoValid = controller.isExplanationProvided;
            }
            
        }

        function initWatches() {
            controller.getCoreIdWatch = WatchService.create($scope, InstitutionFactory.getCoreId, coreIdWatch);
            WatchService.create($scope, StatisticsSubmissionsFactory.getExplainationProvidedQuestion, explanationProvidedWatch, true);
            if(controller.isOtherRanking) {
                WatchService.create($scope, StatisticsDashboardFactory.getActiveQuestion, activeQuestionWatch);
            }
        }

        controller.$onInit = function () {
            initWatches();
            GerEmploymentRateService.getNewSurveySharedWithStudentsOptions().then(function (data) {
                controller.surveySharedWithStudentsOptions = data;
                dataToLoad--;
            });
            GerEmploymentRateService.getYearsDropdownOptions().then(function (data) {
                controller.yearsOptions = data;
                dataToLoad--;
            });
            GerEmploymentRateService.getAnswersHandles().then(function (data) {
                controller.answerHandles = data;
                dataToLoad--;
            });
            activeQuestion = $location.search().question ? $location.search().question : activeQuestion;
            StatisticsDashboardFactory.setActiveGerQuestionHandle(activeQuestion);
            if ($location.search().question !== activeQuestion) {
                $state.transitionTo($state.current, {question: activeQuestion}, {notify: false});
            }
            institutionCoreIdInUrl = $location.search().coreId;
        };
    }

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.stars')
        .service('GerEmploymentRateService', [
            '$q',
            '$resource',
            'constants',
            'RequestsQueueService',
            'TimeService',
            Service
        ]);

    function Service(
        $q,
        $resource,
        constants,
        RequestsQueueService,
        TimeService
    ) {
        var service = {
            cache: {}
        };

        service.getAnswersHandles = function() {
            var deferred = $q.defer();
            deferred.resolve([
                {
                    handle: 'number_of_students'
                },
                {
                    handle: 'median_grad_salary'
                },
                {
                    handle: 'number_of_students_auto'
                },
                {
                    handle: 'qs_employment_rate_auto'
                },
                {
                    handle: 'evidence_when_shared'
                },
                {
                    handle: 'evidence_which_year_conducted'
                },
                {
                    handle: 'evidence_link_or_screenshot'
                },
                {
                    handle: 'number_of_students_entrepreneurship'
                },
                {
                    handle: 'number_of_students_entrepreneurship_auto'
                }
            ]);

            return deferred.promise;
        };

        service.getYearsDropdownOptions = function () {
            var deferred = $q.defer(),
                options = [],
                i = TimeService.getCurrentYear() - 10,
                total = TimeService.getCurrentYear();
            for (i; i <= total; i++) {
                options.push({
                    handle: i,
                    label: i
                });
            }
            deferred.resolve(options);

            return deferred.promise;
        };

        service.getSurveySharedWithStudentsOptions = function () {
            var deferred = $q.defer();
            deferred.resolve([
                 {
                    handle: 'priorToGraduation',
                    label: 'Prior to graduation'
                },
                {
                    handle: '6',
                    label: 'With 6 months'
                },
                {
                    handle: 'after6',
                    label: '6 months after graduation'
                },
                {
                    handle: 'after12',
                    label: '12 months after graduation'
                },
                {
                    handle: 'other',
                    label: 'Other (please describe in a message)'
                },
                {
                    handle: '6OrLess',
                    label: '6 months or less after graduation'
                },
                {
                    handle: '7To11',
                    label: '7-11 months after graduation'
                },
                {
                    handle: '12To15',
                    label: '12-15 months after graduation'
                },
                {
                    handle: 'other15',
                    label: 'more than 15 months after graduation (please describe in a message)'
                }
            ]);

            return deferred.promise;
        };
        service.getNewSurveySharedWithStudentsOptions = function () {
            var deferred = $q.defer();
            deferred.resolve([
                {
                    handle: 'priorToGraduation',
                    label: 'Prior to graduation'
                },
                {
                    handle: '6OrLess',
                    label: '6 months or less after graduation'
                },
                {
                    handle: '7To11',
                    label: '7-11 months after graduation'
                },
                {
                    handle: '12To15',
                    label: '12-15 months after graduation'
                },
                {
                    handle: 'other15',
                    label: 'more than 15 months after graduation (please describe in a message)'
                }
            ]);

            return deferred.promise;
        };

        function getModel() {
            return $resource(constants.api.rankings.url, {}, {
                update: {
                    method: 'PATCH',
                    url:  constants.api.rankings.url + '/v1/answers/institution/:id',
                    isArray: false,
                    cancellable: true
                }
            });
        }

        service.replace = function (institutionCoreId, institutionName, institutionCountryCode, data) {
            if (!institutionCoreId) {
                throw 'Missing Required';
            }

            var key = 'GerEmploymentRateService:replace',
                Api;
            RequestsQueueService.cancelAll(key);
            Api = getModel().update({
                id: institutionCoreId
            }, angular.extend({
                institutionName: institutionName,
                institutionCountryCode: institutionCountryCode
            }, data));
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (result) {
                return result;
            }, function () {
                return false;
            });
        };

        service.calculatePercentages = function (data) {
            var decimals = 1,
                total = data && data.numberOfStudents && data.numberOfStudents.totalRespondents ?
                    parseInt(data.numberOfStudents.totalRespondents, 10) : (
                        data && data.numberOfStudents && data.numberOfStudents.totalGraduatedStudents ? parseInt(data.numberOfStudents.totalGraduatedStudents, 10) : 0
                    );
            data.percentages.totalRespondents = null;
            data.percentages.employed = null;
            data.percentages.unemployed = null;
            data.percentages.inFurtherStudy = null;
            data.percentages.unavailable = null;
            data.percentages.employmentRate = null;
            data.percentages.entrepreneurship = null;
            if (data.numberOfStudents &&
                data.numberOfStudents.totalRespondents &&
                data.numberOfStudents.totalGraduatedStudents &&
                parseInt(data.numberOfStudents.totalRespondents, 10) &&
                parseInt(data.numberOfStudents.totalGraduatedStudents, 10)
            ) {
                data.percentages.totalRespondents = ((data.numberOfStudents.totalRespondents / data.numberOfStudents.totalGraduatedStudents) * 100).toFixed(decimals);
            }
            if (data.numberOfStudents &&
                total &&
                data.numberOfStudents.employed &&
                parseInt(data.numberOfStudents.employed, 10)
            ) {
                data.percentages.employed = ((parseInt(data.numberOfStudents.employed, 10) * 100) / total).toFixed(decimals);
            }
            if (data.numberOfStudents &&
                total &&
                data.numberOfStudents.unemployed &&
                parseInt(data.numberOfStudents.unemployed, 10)
            ) {
                data.percentages.unemployed = ((parseInt(data.numberOfStudents.unemployed, 10) * 100) / total).toFixed(decimals);
            }
            if (data.numberOfStudents &&
                total &&
                data.numberOfStudents.inFurtherStudy &&
                parseInt(data.numberOfStudents.inFurtherStudy, 10)
            ) {
                data.percentages.inFurtherStudy = ((parseInt(data.numberOfStudents.inFurtherStudy, 10) * 100) / total).toFixed(decimals);
            }
            if (data.numberOfStudents &&
                total &&
                data.numberOfStudents.unavailable &&
                parseInt(data.numberOfStudents.unavailable, 10)
            ) {
                data.percentages.unavailable = ((parseInt(data.numberOfStudents.unavailable, 10) * 100) / total).toFixed(decimals);
            }
            if (data.numberOfStudents &&
                data.numberOfStudents.employed &&
                data.numberOfStudents.unemployed &&
                parseInt(data.numberOfStudents.employed, 10) &&
                parseInt(data.numberOfStudents.unemployed, 10)
            ) {
                data.percentages.employmentRate = (parseInt(data.numberOfStudents.employed, 10) / (parseInt(data.numberOfStudents.employed, 10) + parseInt(data.numberOfStudents.unemployed, 10)) * 100).toFixed(decimals);
            }
            else if (data.numberOfStudents &&
                data.numberOfStudents.employed &&
                parseInt(data.numberOfStudents.employed, 10) 
            ) {
                data.percentages.employmentRate = (parseInt(data.numberOfStudents.employed, 10) / (parseInt(data.numberOfStudents.employed, 10)) * 100).toFixed(decimals);
            }
            if (data.numberOfStudents &&
                total &&
                data.numberOfStudents.entrepreneurship &&
                parseInt(data.numberOfStudents.entrepreneurship, 10)
            ) {
                data.percentages.entrepreneurship = ((parseInt(data.numberOfStudents.entrepreneurship, 10) * 100) / total).toFixed(decimals);
            }
        };

        return service;
    }

}(window.angular));

(function(angular) {
    'use strict';

    angular
        .module('qsHub')
        .directive('gerEmploymentRateLog', [Directive]);

    function Directive() {
        return {
            restrict: 'E',
            scope: {
                handle: '<',
                data: '<',
            },
            templateUrl: '/scripts/modules/rankings/components/ger/employmentRateLog/employmentRateLogView.html',
        };
    }
}(window.angular));



(function (angular) {
    "use strict";

    angular.module('qsHub.rankings').component('gerUpload', {
        templateUrl: '/scripts/modules/rankings/components/ger/upload/gerUploadView.html',
        controller: 'Rankings.GerUploadController',
        bindings: {
            uploadType: '<',
            uploadTypeName: '<',
            maxSize: '<',
            newData: '=',
            activeQuestion: '=',
            categories: '<',
            handle: '<',
            questions: '<'
        }
    });

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.rankings')
        .controller('Rankings.GerUploadController', [
            '$state',
            '$location',
            'constants',
            '$timeout',
            'AuthenticationService',
            'NotifierFactory',
            'TimeService',
            'StatisticsDashboardFactory',
            Controller
        ]);

    function Controller(
        $state,
        $location,
        constants,
        $timeout,
        AuthenticationService,
        NotifierFactory,
        TimeService,
        StatisticsDashboardFactory
    ) {
        var controller = this,
            timestamp = null,
            questionHandles = {
                'employers_connections': 0,
                'employer_partnerships': 1
            };
        controller.isActive = isActive;
        controller.handleFocus = handleFocus;
        controller.uploadInProgress = false;
        controller.newData = null;
        controller.config = {
            dropzone: {
                url: getUploadUrl,
                maxFilesize: controller.maxSize,
                maxFiles: 1,
                uploadMultiple: false,
                parallelUploads: 1,
                autoProcessQueue: true,
                addRemoveLinks: false,
                acceptedFiles:  '.csv',
                previewsContainer: false,
                headers: AuthenticationService.getAuthorizationHeader(),
                paramName: 'file',
                init: function () {
                    controller.dropZoneInstance = this;
                },
            },
            eventHandlers: {
                success: handleUploadSuccess,
                error: handleUploadError,
                addedfile: handleFileAdded
            }
        };

        controller.removeFile = function () {
            controller.uploadedFileName = null;
        };

        function handleFileAdded() {
            $timeout(function () {
                controller.uploadInProgress = true;
            });
        }

        function handleUploadSuccess(instance, response) {
            if (response.hasOwnProperty('total') && response.hasOwnProperty('content')) {
                NotifierFactory.show(
                    'success',
                    controller.uploadTypeName + ' data uploaded successfully.',
                    'File Upload'
                );
                populateData(response);

                $timeout(function () {
                    controller.uploadInProgress = false;
                    controller.uploadedFileName = instance.name;
                    removeDropZoneUploadedFile();
                });
            } else {
                handleUploadError();
            }
        }

        function handleUploadError(error) {
            var errorMessage = error && error.xhr !== undefined && error.xhr.responseText ? JSON.parse(error.xhr.responseText) : false;
            var message = controller.uploadTypeName + ' data uploaded unsuccessfully. Check the file type or size';
            errorMessage = errorMessage ? errorMessage.message.length > 0 ? errorMessage.message : message : message;

            NotifierFactory.show(
                'error',
                errorMessage,
                'File Upload'
            );
            $timeout(function () {
                controller.uploadInProgress = false;
                removeDropZoneUploadedFile();
            });
        }

        /**
         * Remove DropZone files
         */
        function removeDropZoneUploadedFile() {
            if (!controller.dropZoneInstance) {
                return false;
            }
            if (controller.dropZoneInstance.files && controller.dropZoneInstance.files.length > 0) {
                controller.dropZoneInstance.removeFile(controller.dropZoneInstance.files[0]);
            } else {
                controller.dropZoneInstance.removeAllFiles();
            }
        }

        function populateData(data) {
            controller.newData = data;
        }

        function getUploadUrl() {
            return constants.api.rankings.url + '/v1/ger/upload?type=' + controller.uploadType;
        }

        controller.showUpload = function () {
            return !controller.uploadedFileName;
        };

        controller.getDataTemplateUrl = function() {
            return 'https://files-core.qs.com/iu/ger/' + controller.uploadType + '-template.csv?' + timestamp;
        };

        function isActive(questionHandle) {
            return controller.activeQuestion === questionHandle;
        }

        function handleFocus(questionHandle) {
            controller.activeQuestion = questionHandle;
            if ($location.search().question !== controller.activeQuestion) {
                $state.transitionTo($state.current, {question: controller.activeQuestion}, {notify: false});
            }
            StatisticsDashboardFactory.setActiveQuestion({
                handle: questionHandle,
                definition: getDefinition(questionHandle)
            });
        }

        function getDefinition(questionHandle) {
            if (typeof controller.categories !== 'undefined') {
                var activeTab = questionHandles[questionHandle];
                var categoryHandle = 'employers_connections';
                if (activeTab === 1) {
                    categoryHandle = 'employer_partnerships';
                } else if (activeTab === 2) {
                    categoryHandle = 'employment_rate';
                }

                var definition = null;
                angular.forEach(controller.questions, function (question) {
                    if (question.handle === categoryHandle) {
                        definition = question.definition;
                    }
                });

                return definition;
            }

            return null;
        }

        controller.$onInit = function () {
            timestamp = TimeService.nowUnix();
        };
    }

}(window.angular));

(function (angular) {
    "use strict";

    angular.module('qsHub.rankings').component('gerQuestion', {
        templateUrl: '/scripts/modules/rankings/components/ger/question/gerQuestionView.html',
        controller: 'Rankings.GerQuestionController',
        bindings: {
            title: '<',
            handle: '<',
            values: '<',
            newData: '=',
            activeQuestion: '=',
            categories: '<',
            isClient: '<',
            questions: '<'
        }
    });

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.rankings')
        .controller('Rankings.GerQuestionController', [
            '$scope',
            '$location',
            '$state',
            '$timeout',
            'WatchService',
            'StatisticsDashboardFactory',
            'StatisticsSubmissionsFactory',
            Controller
        ]);

    function Controller($scope,
                        $location,
                        $state,
                        $timeout,
                        WatchService,
                        StatisticsDashboardFactory,
                        StatisticsSubmissionsFactory) {
        var controller = this,
            questionHandles = {
                'employers_connection_online': 0,
                'employers_connection_on_campus': 0,
                'employer_partnerships_question': 0
            };
        controller.data = {};

        controller.change = change;
        controller.hasAnswers = hasAnswers;
        controller.isActive = isActive;
        controller.handleFocus = handleFocus;
        controller.isExplanationProvided = false;

        function change() {
            if(!controller.current) {
                return;
            }
            if (!controller.newData) {
                controller.newData = {};
            }
            $timeout(function() {
                controller.newData = {
                    handle: controller.handle,
                    value: controller.current.value,
                    source: controller.current.source,
                    status: controller.current.status,
                    estimate: controller.current.estimate,
                    answerId: controller.current.answerId
                };
            });
        }

        function handleFocus(questionHandle) {
            controller.activeQuestion = questionHandle;
            if ($location.search().question !== controller.activeQuestion) {
                $state.transitionTo($state.current, {question: controller.activeQuestion}, {notify: false});
            }
            change();
            StatisticsDashboardFactory.setActiveQuestion({
                handle: questionHandle,
                definition: getDefinition(questionHandle)
            });
        }

        function getDefinition(questionHandle) {
            if (typeof controller.categories !== 'undefined') {
                var activeTab = questionHandles[questionHandle];
                var categoryHandle = 'employer_partnerships';
                if (activeTab === 1) {
                    categoryHandle = 'employment_rate';
                } else if (activeTab === 2) {
                    categoryHandle = 'faculty_staff';
                }

                var definition = null;
                angular.forEach(controller.questions, function (question) {
                    if (question.handle === categoryHandle) {
                        definition = question.definition;
                    }
                });

                return definition;
            }

            return null;
        }

        function valuesChange(values) {
            if (typeof values !== 'undefined') {
                if (typeof controller.current === 'undefined') {
                    controller.current = {};
                }
                controller.current.value = getValue(controller.current.value, values);
                controller.current.source = values[controller.handle] ? values[controller.handle].source : null;
                controller.current.status = values[controller.handle] ? values[controller.handle].status : null;
                controller.current.answerId = null;
                if (typeof values[controller.handle] !== "undefined") {
                    controller.current.answerId = values[controller.handle].id ? values[controller.handle].id : values[controller.handle].answerId;
                }
                if (typeof values[controller.handle] !== "undefined" &&
                    typeof values[controller.handle].answers !== "undefined" &&
                    values[controller.handle].answers.hasOwnProperty(controller.handle) &&
                    values[controller.handle].answers[controller.handle].hasOwnProperty('estimate') &&
                    values[controller.handle].answers[controller.handle].estimate) {
                    controller.current.estimate = values[controller.handle].answers[controller.handle].estimate;
                }
            }
            controller.answersBeforeChanges = angular.copy(controller.current);
        }

        function getValue(currentValue, values) {
            var ret = currentValue;
            if (values[controller.handle] && values[controller.handle].answers && values[controller.handle].answers[controller.handle]) {
                ret = values[controller.handle].answers[controller.handle].value;
            }
            if (values[controller.handle] && values[controller.handle].hasOwnProperty('value') && angular.equals(null, values[controller.handle].value)) {
                ret = null;
            }

            return ret;
        }

        function hasAnswers() {
            return (typeof  controller.current !== 'undefined' &&
                    typeof  controller.current.value !== 'undefined' &&
                    controller.current.value !== null &&
                    controller.current.value.toString().length > 0);
        }

        function isActive(questionHandle) {
            if(questionHandle === 'employers_connection_on_campus' || questionHandle === 'employer_partnerships_question') {
                controller.validators[1].type = 'error';
            }
            return controller.activeQuestion === questionHandle;
        }

        function explanationProvidedWatch (questionHandles) {
            controller.isExplanationProvided = false;
            if (questionHandles && questionHandles.length >0) {
                controller.isExplanationProvided = questionHandles.includes(controller.activeQuestion);
            }
        }

        function init() {
            WatchService.create($scope, '$ctrl.current.value', change);
            WatchService.create($scope, '$ctrl.current.source', change);
            WatchService.create($scope, '$ctrl.current.status', change);
            WatchService.create($scope, '$ctrl.values', valuesChange);
            WatchService.create($scope, StatisticsSubmissionsFactory.getExplainationProvidedQuestion, explanationProvidedWatch, true);
          
            controller.validators = [
                {
                    "type" : "error", 
                    "rule" : "float", 
                    "nullable" : false
                }, 
                {
                    "type" : "error", 
                    "rule" : "required"
                }, 
            ];
        }

        controller.$onInit = function () {
            init();
        };
    }

}(window.angular));

(function (angular) {
    "use strict";

    angular.module('qsHub.rankings').component('gerQuestionHeader', {
        templateUrl: '/scripts/modules/rankings/components/ger/questionHeader/gerQuestionHeaderView.html',
        bindings: {
            isClient: '<'
        }
    });

}(window.angular));

(function(angular) {
    'use strict';

    angular
        .module('qsHub.igauge')
        .config(config, [
            '$stateProvider',
            'errors'
        ]);

    function config(
        $stateProvider,
        errors
    ) {
        $stateProvider
            .state('staff.igauge', {
                abstract: true,
                template: '<div ui-view></div>',
                data: {
                    label: 'IGauge'
                }
            }).state('staff.igauge.igauge_list', {
                url: '/igauge/institutions-list',
                templateUrl: '/scripts/modules/iGauge/components/institutionsList/iGaugeInstitutionsListView.html',
                controller: 'IGauge.InstitutionListController',
                controllerAs: 'InstitutionListController',
                data: {
                    pageTitle: 'Institutions List',
                    label: 'Institutions List',
                    name: 'Institutions List'
                },
                resolve: {
                    security: [
                        '$q', 'UserFactory', 'AuthenticationService',
                        function ($q, UserFactory, AuthenticationService) {
                            UserFactory.setData(AuthenticationService.getUserData());
                            if (!UserFactory.hasData()) {
                                return $q.reject(errors[401]);
                            } else if (!UserFactory.hasIgaugeListAccess()) {
                                return $q.reject(errors[403]);
                            }
                        }
                    ]
                }
            }).state('staff.igauge.igauge_sync', {
                url: '/igauge/sync',
                templateUrl: '/scripts/modules/iGauge/components/sync/iGaugeSyncView.html',
                controller: 'IGauge.IGaugeSyncController',
                controllerAs: 'IGaugeSyncController',
                data: {
                    pageTitle: 'Sync',
                    label: 'Sync',
                    name: 'Sync'
                },
                resolve: {
                    security: [
                        '$q', 'UserFactory', 'AuthenticationService',
                        function ($q, UserFactory, AuthenticationService) {
                            UserFactory.setData(AuthenticationService.getUserData());
                            if (!UserFactory.hasData()) {
                                return $q.reject(errors[401]);
                            } else if (!UserFactory.hasIgaugeSyncAccess()) {
                                return $q.reject(errors[403]);
                            }
                        }
                    ]
                }
            });
    }
}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.igauge')
        .service('IGaugeService', [
            '$q',
            '$filter',
            '$resource',
            'constants',
            'RequestsQueueService',
            'TimeService',
            Service
        ]);

    function Service(
        $q,
        $filter,
        $resource,
        constants,
        RequestsQueueService,
        TimeService
    ) {
        var service = {};

        function getModel() {
            return $resource(constants.api.institutions.url, {}, {
                search: {
                    method: 'GET',
                    url:  constants.api.igauge.url + '/v1/igauge-rating/list/all',
                    isArray: false,
                    cancellable: true
                },
                getStates: {
                    method: 'GET',
                    url:  constants.api.igauge.url + '/v1/igauge-states/list',
                    isArray: false,
                    cancellable: true
                },
                individualPublish: {
                    method: 'GET',
                    url:  constants.api.igauge.url + '/v1/igauge-rating/individual/publish/:id',
                    isArray: false,
                    cancellable: true
                },
                syncData: {
                    method: 'GET',
                    url: constants.api.igauge.url + '/v1/igauge-rating/sync',
                    isArray: false,
                    cancellable: true
                },
                getLogs: {
                    method: 'GET',
                    url:  constants.api.igauge.url + '/v1/igauge-rating/list/logs?sorting[createdAt]=desc',
                    isArray: false,
                    cancellable: true
                },
            });
        }

        service.search = function (filters) {
            var key = 'IGaugeService::search',
                Api;
           
            RequestsQueueService.cancelAll(key);
            Api = getModel().search(filters);
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                return data;
            }, function () {
                return false;
            });
        };

        service.getStates = function (filters) {
            var key = 'IGaugeService::getStates',
                Api;

            RequestsQueueService.cancelAll(key);
            Api = getModel().getStates(filters);
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                return data;
            }, function () {
                return false;
            });
        };

        service.individualPublish = function (coreId) {
            var key = 'IGaugeService::individualPublish',
                Api;

            RequestsQueueService.cancelAll(key);
            Api = getModel().individualPublish({id:coreId});
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                return data;
            }, function () {
                return false;
            });
        };

        service.syncData = function (data) {
            var key = 'IGaugeService::syncData',
                Api;

            RequestsQueueService.cancelAll(key);
            Api = getModel().syncData(data);
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (response) {
                return response;
            }, function (err) {
                return false;
            });
        };

        service.getGroupedLogs = function (params) {
            var key = 'IGaugeService::getLogs',
                Api;

            RequestsQueueService.cancelAll(key);
            Api = getModel().getLogs(params);
            RequestsQueueService.add(Api, key);

            return Api.$promise.then(function (data) {
                return data;
            }, function () {
                return false;
            });
        };

        service.formatCsvResults = function (data) {
            data = angular.copy(data);
            angular.forEach(data, function (column, dataKey) {
                angular.forEach(column, function (item, key) {
                    if (key === 'publishedAt' || key === 'recievedFromIgauge') {
                        data[dataKey][key] = TimeService.format(item, "MMM DD, YYYY hh:mm:ss A");
                    }
                    if (key === 'isNew') {
                        data[dataKey][key] = item ? 'Yes' : 'No';
                    }
                    if (key === 'institutionType') {
                        data[dataKey][key] = item.charAt(0).toUpperCase()+ item.slice(1);
                    }
                    if (key === 'publishedStatus') {
                        data[dataKey][key] = $filter('publishedStatus')(item);
                    }
                    if (key === 'overallRating' ||
                        key === 'teachingAndLearning' ||
                        key === 'facultyQuality' ||
                        key === 'employability' ||
                        key === 'studentDiversity' ||
                        key === 'facilities' ||
                        key === 'socialResponsibilities' ||
                        key === 'accreditation' ||
                        key === 'artsAndCulture' ||
                        key === 'facultyDiversity' ||
                        key === 'entrepreneurship' ||
                        key === 'research' ||
                        key === 'innovation') {
                            data[dataKey][key] = $filter('ratings')(item);
                    }
                });
            });

            return data;
        };

        service.getInstitutionTypes = function () {
            var deferred = $q.defer();
            deferred.resolve(
                [
                    {
                        value: 'university',
                        label: 'University'
                    },
                    {
                        value: 'college',
                        label: 'College'
                    }
                ]
            );

            return deferred.promise;
        };

        service.getRatings = function () {
            var deferred = $q.defer();
            deferred.resolve(
                [
                    {
                        value: 4,
                        label: 'DIAMOND'
                    },
                    {
                        value: 3,
                        label: 'GOLD'
                    },
                    {
                        value: 2,
                        label: 'SILVER'
                    },
                    {
                        value: 1,
                        label: 'BRONZE'
                    },
                    {
                        value: 0,
                        label: 'PROVISIONAL'
                    },
                    {
                        value: -1,
                        label: 'EMPTY/DO NOT DISPLAY'
                    },
                    {
                        value: -2,
                        label: 'UNASSIGNED'
                    }
                ]
            );

            return deferred.promise;
        };

        service.getPublishedStatus = function () {
            var deferred = $q.defer();
            deferred.resolve(
                [
                    {
                        value: 'pending',
                        label: 'Pending'
                    },
                    {
                        value: 'failure',
                        label: 'Failure'
                    },
                    {
                        value: 'progress',
                        label: 'Progress'
                    },
                    {
                        value: 'success',
                        label: 'Success'
                    },
                    {
                        value: 'not-published',
                        label: 'Not Published Yet'
                    }
                ]
            );

            return deferred.promise;
        };

        service.removePublishColumnHeader = function (exportColumnHeaders) {
            if (!angular.isArray(exportColumnHeaders)) {
                return null;
            }
            // filter out publish column
            var i = 0,
                total = exportColumnHeaders.length;
            for (i; i < total; i++) {
                if (exportColumnHeaders[i].name === 'publish') {
                    exportColumnHeaders.splice(i, 1);
                    break;
                }
            }
            return exportColumnHeaders;
        };

        service.removePublishColumn = function (columns) {
            if (!angular.isArray(columns)) {
                return false;
            }
            columns = columns.slice(0);
            var i = 0,
                total = columns.length;
            for (i; i < total; i++) {
                if (columns[i].field === 'publish') {
                    columns.splice(i, 1);
                    break;
                }
            }
            return columns;
        };

        return service;
    }

}(window.angular));


(function(angular) {
    "use strict";

    angular
        .module('qsHub.igauge')
        .factory('IGaugeFactory', [
            Factory
        ]);

        function Factory () {
            var reload = null,
                logsLimit = null,
                syncStatus = null,
                subscribedTo = [],
                rightSidePanelActive = false;
            return {
                requestLogsReload:  function() {
                    reload = true;
                },
                isLogsReloadRequest: function () {
                    return !!reload;
                },
                resetLogsReloadRequest: function () {
                    reload = null;
                },
                setLogsLimit: function (limit) {
                    logsLimit = limit;
                },
                getLogsLimit: function () {
                    return logsLimit;
                },
                setSubscribedTo: function (newKey) {
                    subscribedTo.push(newKey);
                },
                getSubscribedTo: function () {
                    return subscribedTo;
                },
                setSyncStatus: function (value) {
                    syncStatus = value;

                },
                getSyncStatus: function () {
                    return syncStatus;
                    
                },
                setRightSidePanelActive: function (value) {
                    rightSidePanelActive = value;
                },
                isRightSidePanelActive: function (value) {
                    return rightSidePanelActive;
                }
            };
        }

}(window.angular));

(function(angular) {
    'use strict';

    var gridNamespaces = {
            filters: {
                institutionList : 'igauge::institutionList::filters',
            },
            visibility: {
                institutionList : 'igauge::institutionList::visibility',
            }
        },
        constants = {
            datagrid: {
                defaultRowsNumber: 25
            }
        };

    angular
        .module('qsHub.igauge')
        .constant('igauge.gridNamespaces', gridNamespaces)
        .constant('igauge.constants', constants);

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.igauge')
        .controller('IGauge.InstitutionListController', [
            '$scope',
            'IGaugeService',
            'IGaugeFactory',
            '$timeout',
            'constants',
            'igauge.constants',
            'igauge.gridNamespaces',
            'uiGridConstants',
            'UiGridService',
            'uiGridExporterService',
            'uiGridExporterConstants',
            'NotifierFactory',
            'TimeService',
            'WatchService',
            'ModalService',
            'DateRangeFixerService',
            'WebSocketsService',
            InstitutionsListController
        ]);

    function InstitutionsListController(
        $scope,
        IGaugeService,
        IGaugeFactory,
        $timeout,
        constants,
        igaugeConstants,
        gridNamespaces,
        uiGridConstants,
        GridService,
        uiGridExporterService,
        uiGridExporterConstants,
        NotifierFactory,
        TimeService,
        WatchService,
        ModalService,
        DateRangeFixerService,
        WebSocketsService
    ) {
        var controller = this,
            filtersTimeout,
            filterChangesInProgress = false,
            publishedAtFilter = null,
            RecievedFromIgaugeDateFilter = null,
            leftToLoad = 4,
            ratings = [],
            institutionTypes = [],
            states = [],
            overallRatings = [],
            publishedStatus = [],
            accreditationRatings =[],
            columnsBeforeHide = [],
            columnsFilters = GridService.getColumnsFilters(gridNamespaces.filters.institutionList, []),
            defaultFilters = {
                publishedAtRange: {
                    startDate: null,
                    endDate: null
                },
                recievedFromIgaugeRange: {
                    startDate: null,
                    endDate: null
                }
            },
            paginationOptions = GridService.getColumnsFilters(
                gridNamespaces.filters.institutionList
            ),
            defaultPaginationOptions = {
                page: 1,
                limit: igaugeConstants.datagrid.defaultRowsNumber,
                'sorting[createdAt]': 'desc'
            };
        controller.columnsVisibility = GridService.getColumnsVisibility(
            gridNamespaces.visibility.institutionList
        );
        controller.searchInProgress = false;
        controller.selectedInstitution = null;
        controller.fetchInProgress = false;
        controller.filters = null;
        controller.isPublishInProgress = false;
        controller.gridApiInstance = null;
        controller.selectedRowId = null;

        controller.handlePublishedAtRange = function (event) {
            if (DateRangeFixerService.sameDayAndNoModel(event, controller.filters.publishedAtRange, publishedAtFilter)) {
                event.model.startDate = publishedAtFilter.startDate;
                event.model.endDate = publishedAtFilter.endDate;
                controller.filters.publishedAtRange = {
                    startDate: publishedAtFilter.startDate,
                    endDate: publishedAtFilter.endDate
                };
            } else if (DateRangeFixerService.todayRangeAndNoModel(event, publishedAtFilter)) {
                event.model.startDate = TimeService.getStartOfToday();
                event.model.endDate = TimeService.getEndOfToday();
                controller.filters.publishedAtRange = {
                    startDate: event.model.startDate.toISOString(),
                    endDate: event.model.endDate.toISOString()
                };
            }
            var publishedAtDateField = getDateField('publishedAt');
            if (publishedAtDateField) {
                if (event === null || typeof event === 'undefined') {
                    publishedAtDateField.filters[0].term = null;
                    publishedAtDateField.filters[1].term = null;
                } else if (event.model.startDate && event.model.endDate) {
                    publishedAtDateField.filters[0].term = typeof event.model.startDate === 'object' ? event.model.startDate.format('x') : event.model.startDate;
                    publishedAtDateField.filters[1].term = typeof event.model.endDate === 'object' ? event.model.endDate.format('x') : event.model.endDate;
                }
            }
            publishedAtFilter = angular.copy(controller.filters.publishedAtRange);
        };

        controller.handleRecievedFromIgaugeRange = function (event) {
            if (DateRangeFixerService.sameDayAndNoModel(event, controller.filters.recievedFromIgaugeRange, RecievedFromIgaugeDateFilter)) {
                event.model.startDate = RecievedFromIgaugeDateFilter.startDate;
                event.model.endDate = RecievedFromIgaugeDateFilter.endDate;
                controller.filters.recievedFromIgaugeRange = {
                    startDate: RecievedFromIgaugeDateFilter.startDate,
                    endDate: RecievedFromIgaugeDateFilter.endDate
                };
            } else if (DateRangeFixerService.todayRangeAndNoModel(event, RecievedFromIgaugeDateFilter)) {
                event.model.startDate = TimeService.getStartOfToday();
                event.model.endDate = TimeService.getEndOfToday();
                controller.filters.recievedFromIgaugeRange = {
                    startDate: event.model.startDate.toISOString(),
                    endDate: event.model.endDate.toISOString()
                };
            }
            var recievedFromIgaugeDateField = getDateField('recievedFromIgauge');
            if (recievedFromIgaugeDateField) {
                if (event === null || typeof event === 'undefined') {
                    recievedFromIgaugeDateField.filters[0].term = null;
                    recievedFromIgaugeDateField.filters[1].term = null;
                } else if (event.model.startDate && event.model.endDate) {
                    recievedFromIgaugeDateField.filters[0].term = typeof event.model.startDate === 'object' ? event.model.startDate.format('x') : event.model.startDate;
                    recievedFromIgaugeDateField.filters[1].term = typeof event.model.endDate === 'object' ? event.model.endDate.format('x') : event.model.endDate;
                }
            }
            RecievedFromIgaugeDateFilter = angular.copy(controller.filters.recievedFromIgaugeRange);
        };

        controller.handlePublish = function (institution) {
            institution = institution ? institution : controller.selectedItem;
            controller.isPublishInProgress = true;
            IGaugeService.individualPublish(institution.coreId).then(function (response) {
                if (response.status &&
                    response.status === 'success'
                ) {
                    NotifierFactory.show(
                        'success',
                        institution.institutionName + ' publishing added to the queue successfully!',
                        'IGauge Ratings Publish'
                    );
                    subscribePublishStatus(response.publishLogId);
                } else {
                    NotifierFactory.show(
                        'error',
                        'Something went wrong with ' + institution.institutionName +' publishing, please try again',
                        'IGauge Ratings Publish'
                    );
                }
                controller.isPublishInProgress = false;
            });
        };

        controller.isRightSidePanelActive = function () {
            return controller.selectedRowId !== null;
        };

        function subscribePublishStatus(publishLogsId) {
            var roomName = publishLogsId + 'IGauge';
            var eventName = 'publishRatingsStatus' + roomName;

            WebSocketsService.subscribe(roomName, eventName, function (result) {
                if (result.status === constants.publishStatus.failure) {
                    NotifierFactory.show(
                        'error',
                        'Institution publishing failed',
                        'IGauge Ratings Publish'
                    );
                } else if (result.status === constants.publishStatus.success) {
                    NotifierFactory.show(
                        'success',
                        'Institution successfully published',
                        'IGauge Ratings Publish'
                    );
                }
                controller.isPublishInProgress = false;
                if (result.status && result.status !== constants.publishStatus.progress) {
                    controller.reloadRequest = true;
                }
            }, function (subscribed) {
                if (!subscribed) {
                   
                    NotifierFactory.show(
                        'error',
                        'Experiencing issues with publishing.',
                        'IGauge Ratings Publish'
                    );
                    controller.isPublishInProgress = false;
                }
            });
        }

        function datePickersFixes() {
            var publishedAtField = GridService.getFilterByField(paginationOptions, 'publishedAt', '');
            if (publishedAtField !== null && paginationOptions["filter[publishedAt]"] !== null) {
                if (angular.isDefined(publishedAtField) &&
                    angular.isDefined(paginationOptions["filter[publishedAt]"]) &&
                    angular.isDefined(paginationOptions["filter[publishedAt]"].startDate) &&
                    angular.isDefined(paginationOptions["filter[publishedAt]"].endDate)
                ) {
                    controller.gridOptions.columnDefs[getColumnNumber('publishedAt')].filters[0].term = paginationOptions["filter[publishedAt]"].startDate;
                    controller.gridOptions.columnDefs[getColumnNumber('publishedAt')].filters[1].term = paginationOptions["filter[publishedAt]"].endDate;
                }
            }

            var recievedFromIgaugeField = GridService.getFilterByField(paginationOptions, 'recievedFromIgauge', '');
            if (recievedFromIgaugeField !== null && paginationOptions["filter[recievedFromIgauge]"] !== null) {
                if (angular.isDefined(recievedFromIgaugeField) &&
                    angular.isDefined(paginationOptions["filter[recievedFromIgauge]"]) &&
                    angular.isDefined(paginationOptions["filter[recievedFromIgauge]"].startDate) &&
                    angular.isDefined(paginationOptions["filter[recievedFromIgauge]"].endDate)
                ) {
                    controller.gridOptions.columnDefs[getColumnNumber('recievedFromIgauge')].filters[0].term = paginationOptions["filter[recievedFromIgauge]"].startDate;
                    controller.gridOptions.columnDefs[getColumnNumber('recievedFromIgauge')].filters[1].term = paginationOptions["filter[recievedFromIgauge]"].endDate;
                }
            }
        }

        function handleFetch () {
           controller.fetchInProgress = true;
            if (controller.gridOptions && controller.gridOptions.data) {
                controller.gridOptions.data = [];
            }
            IGaugeService.search(angular.merge({}, paginationOptions, defaultPaginationOptions)).then(function (response) {
                $timeout(function () {
                    controller.fetchInProgress = false;
                },200);
                if (response ) {
                    controller.gridOptions.totalItems = response.totalMatching;
                    controller.gridOptions.data = response.results;
                    controller.gridOptions.minRowsToShow = response.totalFiltered;
                }
            });
        }

        function getDateField(field) {
            var total = controller.gridOptions.columnDefs.length,
                i = 0;
            for (i; i < total; i++) {
                if (controller.gridOptions.columnDefs[i].type === 'date' &&
                    controller.gridOptions.columnDefs[i].field === field
                ) {
                    return controller.gridOptions.columnDefs[i];
                }
            }
        }

        function getColumnNumber(columnName) {
            var key = 0,
                total = controller.gridOptions.columnDefs.length;
            for (key; key < total; key++) {
                if (controller.gridOptions.columnDefs[key] &&
                    controller.gridOptions.columnDefs[key].field === columnName
                ) {
                    return key;
                }
            }

            return null;
        }

        function initDataGrid() {
            var selectTemplate = '/scripts/shared/ui-grid/templates/selectFilterHeaderTemplate.html',
            dateFilterTemplate = '/scripts/shared/ui-grid/templates/dateFilterHeaderTemplate.html',
            publishCellTemplate = '/scripts/shared/ui-grid/templates/publishCellTemplate.html',
            YesNoCellTemplate = '/scripts/shared/ui-grid/templates/YesNoCellTemplate.html',
            rowTemplate = '/scripts/components/profiles/tu/matchingTool/datagrid/rowTemplate.html';
            controller.gridOptions = {
                appScopeProvider: controller,
                enableSorting: false,
                useExternalFiltering: true,
                enableGridMenu: true,
                showGridFooter: true,
                enableFiltering: true,
                enableColumnResize: true,
                enableFullRowSelection: true,
                enableRowSelection: true,
                multiSelect: false,
                enableRowHeaderSelection: false,
                rowTemplate: rowTemplate,
                exporterMenuCsv: false,
                exporterMenuAllData: false,
                exporterMenuSelectedData: false,
                exporterMenuPdf: false,
                paginationPageSizes: [25, 50, 100, 200],
                paginationPageSize: 25,
                useExternalPagination: true,
                gridMenuCustomItems: [
                    {
                        title: ' Export visible data as csv',
                        action: exportVisible,
                        order: 209
                    },
                    {
                        title: ' Export all data as csv',
                        action: exportAll,
                        order: 210
                    }
                ],
                columnDefs: [
                    {
                        displayName: 'Core ID',
                        field: 'coreId',
                        minWidth: 100,
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'coreId', true),
                        filter: {
                            term: GridService.getFilterByField(columnsFilters, 'coreId', '', false),
                            condition: uiGridConstants.filter.CONTAINS
                        },
                    },
                    {
                        displayName: 'Institute Type',
                        field: 'institutionType',
                        cellFilter : 'capitalize',
                        minWidth: 120,
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'institutionType', true),
                        filter: {
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: institutionTypes,
                            searchEnabled: true,
                            term: GridService.getFilterByField(columnsFilters, 'institutionType')
                        },
                        filterHeaderTemplate: selectTemplate
                    },
                    {
                        displayName: 'Institution Name',
                        field: 'institutionName',
                        minWidth: 120,
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'institutionName', true),
                        filter: {
                            term: GridService.getFilterByField(columnsFilters, 'institutionName', '', false),
                            condition: uiGridConstants.filter.CONTAINS
                        },
                    },
                    {
                        displayName: 'State or Union Territory',
                        field: 'state',
                        minWidth: 120,
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'state', true),
                        filter: {
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: states,
                            term: GridService.getFilterByField(columnsFilters, 'state'),
                            searchEnabled: true,
                        },
                        filterHeaderTemplate: selectTemplate
                    },
                    {
                        displayName: 'Is New',
                        field: 'isNew',
                        minWidth: 100,
                        maxWidth: 100,
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'isNew', true),
                        filter: {
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: [
                                {value: 'Yes', label: 'Yes'},
                                {value: 'No', label: 'No'}
                            ],
                            term: GridService.getFilterByField(paginationOptions, 'isNew')
                        },
                        filterHeaderTemplate: selectTemplate,
                        cellTemplate: YesNoCellTemplate,
                    },
                    {
                        displayName: 'Overall Rating',
                        field: 'overallRating',
                        width: '12%',
                        cellFilter: 'ratings',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'overallRating', true),
                        filter: {
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: overallRatings,
                            term: GridService.getFilterByField(columnsFilters, 'overallRating'),
                            searchEnabled: true,
                        },
                        filterHeaderTemplate: selectTemplate
                    },
                    {
                        displayName: 'Teaching and Learning',
                        field: 'teachingAndLearning',
                        width: '12%',
                        cellFilter: 'ratings',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'teachingAndLearning', true),
                        filter: {
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: ratings,
                            term: GridService.getFilterByField(columnsFilters, 'teachingAndLearning', '', false),
                            condition: uiGridConstants.filter.CONTAINS,
                            searchEnabled: true,
                        },
                        filterHeaderTemplate: selectTemplate
                    },
                    {
                        displayName: 'Faculty Quality',
                        field: 'facultyQuality',
                        width: '12%',
                        cellFilter: 'ratings',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'facultyQuality', true),
                        filter: {
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: ratings,
                            term: GridService.getFilterByField(columnsFilters, 'facultyQuality', '', false),
                            condition: uiGridConstants.filter.CONTAINS,
                            searchEnabled: true,
                        },
                        filterHeaderTemplate: selectTemplate
                    },
                    {
                        displayName: 'Employability',
                        field: 'employability',
                        width: '12%',
                        cellFilter: 'ratings',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'employability', true),
                        filter: {
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: ratings,
                            term: GridService.getFilterByField(columnsFilters, 'employability', '', false),
                            condition: uiGridConstants.filter.CONTAINS,
                            searchEnabled: true,
                        },
                        filterHeaderTemplate: selectTemplate
                    },
                    {
                        displayName: 'Student Diversity',
                        field: 'studentDiversity',
                        width: '12%',
                        cellFilter: 'ratings',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'studentDiversity', true),
                        filter: {
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: ratings,
                            term: GridService.getFilterByField(columnsFilters, 'studentDiversity', '', false),
                            condition: uiGridConstants.filter.CONTAINS,
                            searchEnabled: true,
                        },
                        filterHeaderTemplate: selectTemplate
                    },
                    {
                        displayName: 'Facilities',
                        field: 'facilities',
                        width: '12%',
                        cellFilter: 'ratings',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'facilities', true),
                        filter: {
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: ratings,
                            term: GridService.getFilterByField(columnsFilters, 'facilities', '', false),
                            condition: uiGridConstants.filter.CONTAINS,
                            searchEnabled: true,
                        },
                        filterHeaderTemplate: selectTemplate
                    },
                    {
                        displayName: 'Social Responsibilities',
                        field: 'socialResponsibilities',
                        width: '12%',
                        cellFilter: 'ratings',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'socialResponsibilities', true),
                        filter: {
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: ratings,
                            term: GridService.getFilterByField(columnsFilters, 'socialResponsibilities', '', false),
                            condition: uiGridConstants.filter.CONTAINS,
                            searchEnabled: true,
                        },
                        filterHeaderTemplate: selectTemplate
                    },
                    {
                        displayName: 'Accreditation',
                        field: 'accreditation',
                        width: '12%',
                        cellFilter: 'ratings',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'accreditation', true),
                        filter: {
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: accreditationRatings,
                            term: GridService.getFilterByField(columnsFilters, 'accreditation', '', false),
                            condition: uiGridConstants.filter.CONTAINS,
                            searchEnabled: true,
                        },
                        filterHeaderTemplate: selectTemplate
                    },
                    {
                        displayName: 'Arts and Culture',
                        field: 'artsAndCulture',
                        width: '12%',
                        cellFilter: 'ratings',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'artsAndCulture', true),
                        filter: {
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: ratings,
                            term: GridService.getFilterByField(columnsFilters, 'artsAndCulture', '', false),
                            condition: uiGridConstants.filter.CONTAINS,
                            searchEnabled: true,
                        },
                        filterHeaderTemplate: selectTemplate
                    },
                    {
                        displayName: 'Faculty Diversity',
                        field: 'facultyDiversity',
                        width: '12%',
                        cellFilter: 'ratings',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'facultyDiversity', true),
                        filter: {
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: ratings,
                            term: GridService.getFilterByField(columnsFilters, 'facultyDiversity', '', false),
                            condition: uiGridConstants.filter.CONTAINS,
                            searchEnabled: true,
                        },
                        filterHeaderTemplate: selectTemplate
                    },
                    {
                        displayName: 'Entrepreneurship',
                        field: 'entrepreneurship',
                        width: '12%',
                        cellFilter: 'ratings',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'entrepreneurship', true),
                        filter: {
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: ratings,
                            term: GridService.getFilterByField(columnsFilters, 'entrepreneurship', '', false),
                            condition: uiGridConstants.filter.CONTAINS,
                            searchEnabled: true,
                        },
                        filterHeaderTemplate: selectTemplate
                    },
                    {
                        displayName: 'Research',
                        field: 'research',
                        width: '12%',
                        cellFilter: 'ratings',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'research', true),
                        filter: {
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: ratings,
                            term: GridService.getFilterByField(columnsFilters, 'research', '', false),
                            condition: uiGridConstants.filter.CONTAINS,
                            searchEnabled: true,
                        },
                        filterHeaderTemplate: selectTemplate
                    },
                    {
                        displayName: 'Innovation',
                        field: 'innovation',
                        width: '12%',
                        cellFilter: 'ratings',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'innovation', true),
                        filter: {
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: ratings,
                            term: GridService.getFilterByField(columnsFilters, 'innovation', '', false),
                            condition: uiGridConstants.filter.CONTAINS,
                            searchEnabled: true,
                        },
                        filterHeaderTemplate: selectTemplate
                    },
                    {
                        displayName: 'Received from IGauge',
                        field: 'recievedFromIgauge',
                        type: 'date',
                        width: '12%',
                        enableFiltering: true,
                        filterCellFiltered: true,
                        enableSorting: false,
                        cellFilter: 'date:\'medium\' : \'UTC\'',
                        filters: [
                            {
                                visible: true,
                                condition: uiGridConstants.filter.GREATER_THAN
                            },
                            {
                                condition: uiGridConstants.filter.LESS_THAN
                            }
                        ],
                        filter: {
                            term: GridService.getFilterByField(columnsFilters, 'recievedFromIgauge', '', false),
                            applyTextFilter: applyDateFilter('recievedFromIgauge', '')
                        },
                        filterHeaderTemplate: dateFilterTemplate,
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'recievedFromIgauge', true)
                    },
                    {
                        displayName: 'Publish',
                        field: 'publish',
                        enableSorting: false,
                        enableColumnResizing: false,
                        cellTemplate: publishCellTemplate,
                        width: '12%',
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'publish', true)
                    },
                    {
                        displayName: 'Published Date',
                        field: 'publishedAt',
                        type: 'date',
                        width: '12%',
                        enableFiltering: true,
                        filterCellFiltered: true,
                        enableSorting: false,
                        cellFilter: 'date:\'medium\' : \'UTC\'',
                        filters: [
                            {
                                visible: true,
                                condition: uiGridConstants.filter.GREATER_THAN
                            },
                            {
                                condition: uiGridConstants.filter.LESS_THAN
                            }
                        ],
                        filter: {
                            term: GridService.getFilterByField(columnsFilters, 'publishedAt', '', false),
                            applyTextFilter: applyDateFilter('publishedAt', '')
                        },
                        filterHeaderTemplate: dateFilterTemplate,
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'publishedAt', true)
                    },
                    {
                        displayName: 'Publish Status',
                        field: 'publishedStatus',
                        cellFilter: 'publishedStatus',
                        minWidth:120,
                        visible: GridService.getVisibilityByField(controller.columnsVisibility, 'publishedStatus', true),
                        filter: {
                            type: uiGridConstants.filter.SELECT,
                            selectOptions: publishedStatus,
                            term: GridService.getFilterByField(columnsFilters, 'publishedStatus'),
                            searchEnabled: true,
                        },
                        filterHeaderTemplate: selectTemplate
                    }
                ],
                onRegisterApi: function (gridApi) {
                    controller.gridApiInstance = gridApi;
                    gridApi.pagination.on.paginationChanged($scope, function (newPage, limit) {
                        defaultPaginationOptions.page = newPage;
                        defaultPaginationOptions.limit = limit;
                        if(!filterChangesInProgress) {
                           handleFetch();
                        }
                    });

                    gridApi.selection.on.rowSelectionChanged($scope, function (row) {
                        handleDatagridRowClick(row.entity);
                    });

                    $scope.$watch('InstitutionListController.gridApiInstance.grid.isScrollingHorizontally', gridScrollWatch);
                    gridApi.core.on.filterChanged($scope, handleGridFiltersChanges);
                    gridApi.core.on.rowsVisibleChanged($scope, handleGridVisibleChanges);
                   handleFetch();
                }
            };
            datePickersFixes();
            controller.filters = angular.extend({}, defaultFilters);
            storeColumnsVisibility();
        }

        function handleDatagridRowClick(row) {
            controller.selectedRowId = controller.selectedRowId === row.id ? null : row.id;
            controller.selectedItem = row;
            if (!IGaugeFactory.isRightSidePanelActive()) {
                toggleColumns(controller.selectedRowId !== null);
            }
            IGaugeFactory.setRightSidePanelActive(controller.selectedRowId !== null);
        }

        function storeColumnsVisibility() {
            if (!controller.gridOptions) {
                return false;
            }
            angular.forEach(controller.gridOptions.columnDefs, function (column) {
                if (angular.isDefined(column.visible) && column.visible === true) {
                    columnsBeforeHide.push(column);
                }
            });
        }

        function toggleColumns(hide) {
            if (!controller.gridOptions) {
                return;
            }
            if (hide) {
                columnsBeforeHide = angular.copy(controller.gridOptions.columnDefs);
                // hide all columns except core Id
                angular.forEach(controller.gridOptions.columnDefs, function (column) {
                    column.visible = column.field === 'coreId' ||
                                    column.field === 'institutionType' ||
                                    column.field === 'institutionName' ||
                                    column.field === 'state' ||
                                    column.field === 'isNew' ||
                                    column.field === 'publishedStatus';
                });
            } else {
                var columns = [];
                // show columns visible before hide
                angular.forEach(columnsBeforeHide, function (column) {
                    if (column.visible === true) {
                        columns.push(column.field);
                    }
                });
                angular.forEach(controller.gridOptions.columnDefs, function (column) {
                    if (columns.indexOf(column.field) !== -1) {
                        column.visible = true;
                        controller.columnsVisibility[column.field] = true;
                    }
                });
            }
            controller.gridOptions.enablePaginationControls = !controller.selectedRowId;
        }

        function gridScrollWatch (newValue) {
            if (newValue) {
                var uiSelectController = angular.element('.ui-grid-filter-select.open').controller('uiSelect');
                if (uiSelectController && uiSelectController.open) {
                    uiSelectController.close(true);
                    uiSelectController.clickTriggeredSelect = false;
                }
            }
        }

        function applyDateFilter(filterName, defaultValue) {
            var filter = GridService.getFilterByField(paginationOptions, filterName, defaultValue);
            if (angular.isDefined(filter) &&
                filter !== null
            ) {
                if (angular.isDefined(filter.startDate) &&
                    angular.isDefined(filter.endDate)
                ) {
                    if (filter.startDate !== null &&
                        filter.startDate !== null
                    ) {
                        defaultFilters[filterName + 'Range'] = {
                            startDate: parseInt(filter.startDate, 10),
                            endDate: parseInt(filter.endDate, 10)
                        };
                    } else {
                        defaultFilters[filterName + 'Range'] = {
                            startDate: null,
                            endDate: null
                        };
                    }
                }
            }
        }

        function handleGridVisibleChanges () {
            if (controller.gridApiInstance.grid.selection.selectedCount === 1) {
                controller.gridApiInstance.selection.clearSelectedRows();
            }
            var columnsVisibility = GridService.getColumnsVisibility(gridNamespaces.visibility.institutionList),
            reload = false;
            if (columnsVisibility) {
                angular.forEach(controller.gridOptions.columnDefs, function (column, key) {
                    if (columnsVisibility[column.field] === true &&
                        column.visible === false
                    ) {
                        if (typeof  controller.gridOptions.columnDefs[key].filter === 'undefined') {
                            controller.gridOptions.columnDefs[key].filter = {};
                        }
                        // decide whether reload or no.
                        if ((angular.isDefined(controller.gridOptions.columnDefs[key].filter) &&
                            controller.gridOptions.columnDefs[key].filter.hasOwnProperty('term') &&
                            typeof controller.gridOptions.columnDefs[key].filter.term !== 'undefined' &&
                            controller.gridOptions.columnDefs[key].filter.term !== null &&
                            controller.gridOptions.columnDefs[key].filter.term.toString().length > 0) ||
                            ((angular.isDefined(controller.gridOptions.columnDefs[key].filters) &&
                            controller.gridOptions.columnDefs[key].filters[0].hasOwnProperty('term') &&
                                controller.gridOptions.columnDefs[key].filters[0].term &&
                                controller.gridOptions.columnDefs[key].filters[0].term.length > 0
                            ) || (
                                angular.isDefined(controller.gridOptions.columnDefs[key].filters) &&
                                controller.gridOptions.columnDefs[key].filters[1].hasOwnProperty('term') &&
                                controller.gridOptions.columnDefs[key].filters[1].term &&
                                controller.gridOptions.columnDefs[key].filters[1].term.length > 0
                            ))) {
                                paginationOptions['filter[' + column.field + ']'] = null;
                                reload = true;
                        }
                        controller.gridOptions.columnDefs[key].filter.term = null;
                        columnsFilters['filter[' + column.field + ']'] = null;
                        if (column.field === 'publishedAt') {
                            delete  controller.gridOptions.columnDefs[key].filters[0].term;
                            controller.filters.publishedAtRange = {
                                startDate: null,
                                endDate: null
                            };
                        }
                        else if (column.field === 'recievedFromIgauge') {
                            controller.gridOptions.columnDefs[key].filters[0].term = null;
                            controller.gridOptions.columnDefs[key].filters[1].term = null;
                            controller.filters.recievedFromIgaugeRange = columnsFilters['filter[recievedFromIgauge]'] = {
                                startDate: null,
                                endDate: null
                            };
                        }
                    }
                });
            }
            if (controller.gridOptions) {
                controller.columnsVisibility = GridService.getGridColumnsVisibility(controller.gridOptions.columnDefs);
                if (!controller.selectedRowId) {
                    GridService.storeColumnsVisibility(
                        gridNamespaces.visibility.institutionList,
                        controller.columnsVisibility
                    );
                }
            }
            if (reload) {
               handleFetch();
            }
        }

        function handleGridFiltersChanges() {
            filterChangesInProgress = true;
            if(controller.gridApiInstance.pagination.getPage() > 1){
                controller.gridApiInstance.pagination.seek(1);
            }
            angular.forEach(controller.gridApiInstance.grid.columns, function (column) {
                var filterColumn = column.filters[0].column ? column.filters[0].column : column.field,
                    deleteFilter = false;

                if (column.field === 'recievedFromIgauge' ||
                    column.field === 'publishedAt'
                ) {
                    if (typeof column.filters !== 'undefined' &&
                        typeof column.filters[0].term !== 'undefined' &&
                        typeof column.filters[1].term !== 'undefined'
                    ) {
                        paginationOptions['filter[' + filterColumn + ']'] = {
                            startDate: column.filters[0].term && isNaN(column.filters[0].term) && typeof column.filters[0].term === 'object' ?
                                column.filters[0].term.format('x') : column.filters[0].term,
                            endDate: column.filters[1].term && isNaN(column.filters[1].term) && typeof column.filters[1].term === 'object' ?
                                column.filters[1].term.format('x') : column.filters[1].term
                        };
                    } else {
                        paginationOptions['filter[' + filterColumn + ']'] = null;
                    }
                } else {
                    if (column.filters[0].type === uiGridConstants.filter.SELECT &&
                        typeof column.filters[0].term === 'object' &&
                        column.filters[0].term !== null &&
                        typeof column.filters[0].term.value !== 'undefined'
                    ) {
                        column.filters[0].term = column.filters[0].term.value;
                    }
                    if (typeof column.filters !== 'undefined' &&
                        column.filters[0].term !== null &&
                        typeof column.filters[0].term !== 'undefined'
                    ) {
                        GridService.applyFilters(column);
                        if (column.filters[0].term === '') {
                            deleteFilter = true;
                        } else {
                            paginationOptions['filter[' + filterColumn + ']'] = column.filters[0].valuePrefix && !paginationOptions['filter[' + filterColumn + ']']? column.filters[0].valuePrefix + column.filters[0].term : column.filters[0].term;
                        }
                    } else {
                        deleteFilter = true;
                    }

                    if (deleteFilter && typeof paginationOptions['filter[' + filterColumn + ']'] !== 'undefined') {
                        delete paginationOptions['filter[' + filterColumn + ']'];
                    }
                }

            });
            dateFilters('recievedFromIgauge');
            dateFilters('publishedAt');

            GridService.storeColumnsFilters(
                gridNamespaces.filters.institutionList,
                paginationOptions
            );
            storeColumnsVisibility();

            if (angular.isDefined(filtersTimeout)) {
                $timeout.cancel(filtersTimeout);
            }
            filtersTimeout = $timeout(function () {
                handleFetch();
                filterChangesInProgress = false;
            }, constants.datagridSelectDelay);
        }

        function dateFilters(field) {
            if (!paginationOptions['filter[' + field + ']']) {
                paginationOptions['filter[' + field + ']'] = {
                    startDate: null,
                    endDate: null
                };
            }
            if (paginationOptions['filter[' + field + ']'].startDate === null) {
                paginationOptions['filter[' + field + ']'].endDate = null;
                controller.filters[field + 'Range'] = {
                    startDate: null,
                    endDate: null
                };
            }
        }

        function exportVisible() {
            var results = controller.gridOptions.data;
            results = IGaugeService.formatCsvResults(results);
            if (typeof results === 'undefined') {
                return false;
            }
            var gridApi = controller.gridApiInstance,
                gridOptions = controller.gridOptions,
                exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                    gridApi.grid,
                    uiGridExporterConstants.VISIBLE
                );

            exportColumnHeaders = IGaugeService.removePublishColumnHeader(exportColumnHeaders);
            var columns = IGaugeService.removePublishColumn(gridApi.grid.columns);
            var exportData = GridService.getCsvData(columns, results);
            var csvContent = uiGridExporterService.formatAsCsv(
                exportColumnHeaders,
                exportData,
                gridOptions.exporterCsvColumnSeparator
            );
            uiGridExporterService.downloadFile(
                'iGauge-institutions-list-export-' + TimeService.now() + '.csv',
                csvContent,
                gridOptions.exporterOlderExcelCompatibility
            );
        }

        function exportAll() {
            IGaugeService.search(
                angular.merge({}, paginationOptions, {
                    page: 1,
                    limit: 250000,
                    export: true
                })
            ).then(function (response) {
                if (typeof response.results === 'undefined') {
                    return false;
                }
                response = IGaugeService.formatCsvResults(response.results);
                var gridApi = controller.gridApiInstance,
                    gridOptions = controller.gridOptions,
                    exportColumnHeaders = uiGridExporterService.getColumnHeaders(
                        gridApi.grid,
                        uiGridExporterConstants.VISIBLE
                    );

                exportColumnHeaders = IGaugeService.removePublishColumnHeader(exportColumnHeaders);
                var columns = IGaugeService.removePublishColumn(gridApi.grid.columns);
                var exportData = GridService.getCsvData(columns, response);
                var csvContent = uiGridExporterService.formatAsCsv(
                    exportColumnHeaders,
                    exportData,
                    gridOptions.exporterCsvColumnSeparator
                );
                uiGridExporterService.downloadFile(
                    'iGauge-institutions-list-all-export-' + TimeService.now() + '.csv',
                    csvContent,
                    gridOptions.exporterOlderExcelCompatibility
                );
            });
        }

        function ackLoad () {
            leftToLoad--;
            if (!isLoading()) {
                initDataGrid();
            }
        }

        function isLoading () {
            return leftToLoad !== 0;
        }

        function reloadRequestWatch (reload) {
            if (reload) {
                handleFetch();
                reset();
                controller.reloadRequest = false;
            }
        }

        function reset () {
            controller.selectedRowId = null;
            controller.selectedItem = null;
            toggleColumns(false);
            IGaugeFactory.setRightSidePanelActive(false);
        }

        function rightSidePanelActiveWatch (active, wasActive) {
            if (active === false && wasActive) {
                reset();
            }
        }

        function initWatches() {
            WatchService.create($scope, 'InstitutionListController.reloadRequest', reloadRequestWatch);
            WatchService.create($scope, IGaugeFactory.isRightSidePanelActive, rightSidePanelActiveWatch);
        }

        function init () {
            initWatches();
            IGaugeService.getInstitutionTypes().then(function (data) {
                institutionTypes = data;
                ackLoad();
            });

            IGaugeService.getRatings().then(function (data) {
                ratings = data;
                overallRatings = angular.copy(data);
                overallRatings.unshift({
                    value: 5,
                    label: 'DIAMOND+'
                });
                accreditationRatings =  [
                    {
                        value: 3,
                        label: 'GOLD'
                    },
                    {
                        value: 4,
                        label: 'DIAMOND'
                    }
                ];
                ackLoad();
            });

            IGaugeService.getStates().then(function (response) { 
                if (response && response.results) {
                    angular.forEach(response.results, function(value) {
                        states.push({
                                value: value.name,
                                label: value.name
                        });
                    });
                }
                ackLoad();
            });
            
            IGaugeService.getPublishedStatus().then(function (data) {
                publishedStatus = data;
                ackLoad();
            });
        }

        init();

    }
}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.igauge')
        .controller('IGauge.IGaugeRightSidePanelController', [
            '$scope',
            'IGaugeFactory',
            IGaugeRightSidePanelController
        ]);

    function IGaugeRightSidePanelController(
        $scope,
        IGaugeFactory
    ) {
        var vm = this;

        vm.closeRightSidePanel = function () {
            IGaugeFactory.setRightSidePanelActive(false);
        };

        vm.$onInit = function () {};

    }

}(window.angular));

(function (angular) {

    'use strict';
  
    angular.module('qsHub.igauge').component('igaugeRightSidePanel', {
        templateUrl: '/scripts/modules/iGauge/components/institutionsList/rightSidePanel/iGaugeRightSidePanelView.html',
        bindToController: true,
        controller: 'IGauge.IGaugeRightSidePanelController',
        bindings: {
            selectedItem: '=',
            handlePublish :'&'
        }
    });
  
  }(window.angular));
  
(function (angular) {
    "use strict";

    angular
        .module('qsHub.igauge')
        .controller('IGauge.IGaugeSyncController', [
            '$scope',
            '$timeout',
            'WatchService',
            'IGaugeService',
            'IGaugeFactory',
            'NotifierFactory',
            IGaugeSyncController
        ]);

    function IGaugeSyncController(
        $scope,
        $timeout,
        WatchService,
        IGaugeService,
        IGaugeFactory,
        NotifierFactory
    ) {
        var vm = this;

        vm.syncing = true;

        vm.handleSync = function () {
            vm.syncStatus = 'pending';
            vm.syncing = true;
            IGaugeService.syncData().then(function (result) {
                if (!result) {
                    NotifierFactory.show(
                        'error',
                        'Can\'t sync data at the moment. Please try again later.',
                        'IGauge Sync Data'
                    );
                    vm.syncStatus = 'failure';
                }
                else {
                    IGaugeFactory.requestLogsReload();
                }
                vm.syncing = false;
            });
        };

        function initWatches () {
            WatchService.create($scope, IGaugeFactory.getSyncStatus, function (value) {
                vm.syncStatus = value;
                $timeout(function () {
                    vm.syncing = ['success', 'failure'].indexOf(vm.syncStatus) === -1 && !!vm.syncStatus;
                });
            });
        }
      
        function init() {
            initWatches();
        }
        init();

    }

}(window.angular));

(function (angular) {
    "use strict";

    angular
        .module('qsHub.igauge')
        .controller('IGauge.IGaugeSyncLogsController', [
            '$scope',
            'WatchService',
            'WebSocketsService',
            'IGaugeService',
            'IGaugeFactory',
            'NotifierFactory',
            IGaugeSyncLogsController
        ]);

    function IGaugeSyncLogsController(
        $scope,
        WatchService,
        WebSocketsService,
        IGaugeService,
        IGaugeFactory,
        NotifierFactory
    ) {
        var vm = this,
        limit = null,
        totalMatching = 0,
        totalFiltered = 0,
        logsList = null,
        fetchInProgress = false,
        logsPerLoad = 10,
        defaultFilters = {
            limit : null
        },
        room = 'SyncStatusIGauge';

        function fetchLogs() {
            fetchInProgress = true;
            IGaugeService.getGroupedLogs(defaultFilters).then(function (logs) {
                logsList = logs.results;
                vm.logs = getLimitedLogs();
                totalMatching = logs.totalMatching;
                totalFiltered = logs.totalFiltered;
                vm.logsLength = totalMatching;
                fetchInProgress = false;
            });
        }

        function subscribeToWebsocket() {
            if (IGaugeFactory.getSubscribedTo().indexOf(room) === -1) {
                WebSocketsService.subscribe(room, room, function (result) {
                    var success = result && result.status && result.status === 'success';
                    IGaugeFactory.setSyncStatus(result.status);
                    if (success) {
                        IGaugeFactory.requestLogsReload();
                        NotifierFactory.show(
                            'success',
                            'IGauge data synced successfully!',
                            'IGauge Sync Data'
                        );
                    } else if (result.status !== 'progress' && result.status !== 'pending') {
                        NotifierFactory.show(
                            'error',
                            'Can\'t sync data at the moment. Please try again later.',
                            'IGauge Sync Data'
                        );
                    }
                }, function (subscribed) {
                    if (subscribed) {
                        IGaugeFactory.setSubscribedTo(room);
                    }
                });
            }
        }

        function getLimitedLogs() {
            var limited = {},
                total = 1;
            angular.forEach(logsList, function (logs, dateKey) {
                angular.forEach(logs.logs, function (log, key) {
                    if (total <= limit) {
                        if (typeof limited[dateKey] === 'undefined') {
                            limited[dateKey] = [];
                        }
                        limited[dateKey][key] = log;
                        total++;
                    } else {
                        return false;
                    }
                });
            });
            if (typeof(logsList) !== 'undefined' &&
                typeof(logsList[0]) !== 'undefined' &&
                typeof(logsList[0].logs) !== 'undefined' &&
                typeof(logsList[0].logs[0]) !== 'undefined' &&
                logsList[0].logs[0].status !== 'undefined'
            ) {
                IGaugeFactory.setSyncStatus(logsList[0].logs[0].status);
            }

            return limited;
        }

        vm.getLogClassColor = function(status) {
            switch (status) {
                case 'success':
                    return 'text-success';
                case 'failure':
                    return 'text-danger';
                case 'progress':
                    return 'text-info';
                case 'pending':
                    return 'text-info';
            }
        };

        vm.getLogClassFaIcon = function(status) {
            switch (status) {
                case 'success':
                    return 'fa-check';
                case 'failure':
                    return 'fa-exclamation';
                case 'progress':
                    return 'fa-spinner fa-spin';
                case 'pending':
                    return 'fa-clock-o';
            }
        };

        vm.isMoreLogsAvailable = function () {
            return limit < totalMatching;
        };

        vm.handleLoadMore = function () {
            limit = limit + logsPerLoad;
            defaultFilters.limit = limit;
            fetchLogs();
            IGaugeFactory.setLogsLimit(limit);
        };

        vm.isFetchInProgress = function () {
            return fetchInProgress;
        };

        function logsReloadRequestWatch(is) {
            if (is) {
                IGaugeFactory.resetLogsReloadRequest();
                fetchLogs();
            }
        }

        function initWatches() {
            WatchService.create($scope, IGaugeFactory.isLogsReloadRequest, logsReloadRequestWatch);
        }

        vm.$onInit = function () {
            limit = IGaugeFactory.getLogsLimit() || logsPerLoad;
            IGaugeFactory.setSyncStatus(null);
            defaultFilters.limit = limit;
            initWatches();
            fetchLogs();
            subscribeToWebsocket();
        };

    }

}(window.angular));

(function (angular) {

    'use strict';
  
    angular.module('qsHub.igauge').component('igaugeSyncLogs', {
        templateUrl: '/scripts/modules/iGauge/components/sync/iGaugeSyncLogs/iGaugeSyncLogsView.html',
        bindToController: true,
        controller: 'IGauge.IGaugeSyncLogsController'
    });
  
  }(window.angular));
  